import styled from '@emotion/styled';

export const ChangeLogWrapper = styled.div`

  .cursor-pointer {
    cursor: pointer;
  }

  .change-log-header-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: space-between;
  }

  .change-log-heading-container {
    display: flex;
  }

  .change-log-color {
    width: 16px;
    height: 32px;
    flex-grow: 0;
    border-radius: 4px;
    background-color: #cabdff;
    margin-right: 10px;
  }

  .change-log-heading {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    color: #1a1d1f;
  }

  .change-log-list-container {
    padding: 0 2rem;
  }
`;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '@chakra-ui/react';
import { ActionButton } from '../../../../ui-components/Confirm';

import { VisibilityContext } from './context';

interface IProps {
  onCancelClick?: () => void;
}

const VisibilityFooter: FC<IProps> = ({ onCancelClick }) => {
  const { t } = useTranslation();
  const { onClose: close } = useModalContext();
  const { handleSubmit, submitForm, fetchingData } =
    useContext(VisibilityContext);

  const onSubmitClick: typeof submitForm = async (data) => {
    await submitForm(data);
    close();
  };

  return (
    <>
      <ActionButton
        close={close}
        actionFn={onCancelClick}
        borderWidth='2px'
        variant='outline'
        borderRadius='10px'
        minW='160px'
        size='lg'
        isDisabled={fetchingData}
      >
        {t('cancel')}
      </ActionButton>

      <ActionButton
        actionFn={handleSubmit(onSubmitClick)}
        ml={3}
        variant='solid'
        colorScheme='blue'
        minW='160px'
        size='lg'
        isDisabled={fetchingData}
      >
        {t('apply')}
      </ActionButton>
    </>
  );
};

export default VisibilityFooter;

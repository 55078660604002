import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyState, Loader } from '../../../../sub-components';

interface IProps {
  isLoading?: boolean;
  isEmpty?: boolean;
}

const SendFormLoader: FC<IProps> = ({ isLoading, isEmpty, children }) => {
  const { t } = useTranslation(['form']);

  if (isLoading) {
    return (
      <div className='sop-list-loader'>
        <Loader size='lg' />
      </div>
    );
  }

  if (isEmpty) {
    return (
      <EmptyState
        image={'Search'}
        title={t('form:noMemberFound')}
        description={t('form:noMemberFoundHelp')}
        height='320px'
      />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default SendFormLoader;

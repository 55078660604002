import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { JSONContent } from 'delightree-editor';
import {
  dataConverter,
  filterData,
} from '../../../sub-components/ChapterEditor/SmartPages';

export const useSmartPageHandler = (
  content: JSONContent = {},
  smartPageEnabled: boolean
): JSONContent[][] => {
  return useMemo(() => {
    if (isEmpty(content) || !content) {
      return [];
    }
    const cardData = dataConverter(content, smartPageEnabled);
    return filterData(cardData);
  }, [content, smartPageEnabled]);
};

import { BaseTraining } from '../types/base-training.types';

export class TrainingBaseModal<T extends BaseTraining> {
  protected _data: T;

  constructor(props: T) {
    this._data = props;
  }

  get eid() {
    return this._data.eid;
  }

  get title() {
    return this._data.title?.trim();
  }

  get thumbnail() {
    return this._data.thumbnail;
  }

  get jsonData() {
    return this._data;
  }
}

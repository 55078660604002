import React, { FC } from 'react';

interface IProps {
  text: string;
  index: number;
}

const FormType: FC<IProps> = ({ text, index }) => {
  const colorSetHandler = (index: number) => {
    let colorObj = ['#cbecda', '#ffe8bb', '#b1e5fc'];
    return colorObj[index % 3];
  };

  return (
    <div>
      <span
        style={{
          backgroundColor: colorSetHandler(index),
          padding: '4px 6px',
          borderRadius: '4px',
          fontWeight: '600',
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default FormType;

import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockFour } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  dueDate: string;
  timeZone: string;
}

const TaskTimeLeft: FC<IProps> = ({ timeZone, dueDate }) => {
  const now = moment.utc(moment().tz(timeZone).format('LLL'), 'LLL');
  const taskDueDate = moment.utc(dueDate);

  if (taskDueDate.isBefore(now)) {
    return null;
  }

  return (
    <Flex
      p='5px 10px'
      bg='#83BF6E1A'
      color='#83BF6E'
      borderRadius='8px'
      h='fit-content'
      gap='5px'
      align='center'
    >
      <FontAwesomeIcon icon={faClockFour as IconProp} />
      <Text m={0}>{taskDueDate.from(now, true)}</Text>
    </Flex>
  );
};

export default TaskTimeLeft;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import TitleHeader from '../../../CardEditor/TitleHeader';
import { CustomTimePicker } from './CustomTimePicker';

interface IProps {
  isReadOnly?: boolean;
  isHidden?: boolean;
  deps?: string | string[];
}

const DueTimeSelect: FC<IProps> = ({ isReadOnly, isHidden, deps }) => {
  const { t } = useTranslation(['task']);

  if (isHidden) {
    return null;
  }

  return (
    <div>
      <TitleHeader
        title={t('task:setDueTime')}
        desc={t('task:setDueTimeDesc')}
      />
      <Box pt={3}>
        <CustomTimePicker
          isReadOnly={isReadOnly}
          name='dueTime'
          isSelected
          placeholderText={t('task:selectTime')}
          deps={deps}
          // onChange={() => callAll(field.onChange, resetField)('date')}
        />
      </Box>
    </div>
  );
};

export default DueTimeSelect;

import { IAssigneeUsersEntity } from 'pages/TasksDashboard/tasks.types';
import { LocationsEntity } from 'sop-commons/src/client';

export interface IAssigneeUsers {
  authRole: string;
  eid: string;
  name: string;
  profilePic: string;
  role: string;
  type: string;
  locations: Array<{
    eid: string;
    name: string;
  }>;
}

export const getLOFilteredUsersList = (
  descendantLocations: LocationsEntity[],
  assigneeUsers: IAssigneeUsersEntity[]
) => {
  console.log('getLOFilteredUsersList : ', {
    descendantLocations,
    assigneeUsers,
  });
  const filteredAssigneeUsers = assigneeUsers?.filter((user: any) => {
    return user?.locations?.some((location: any) => {
      return descendantLocations?.some((descendant) => {
        let flag = descendant?.eid === location?.eid;
        console.log({ flag, location, descendant });
        return flag;
      });
    });
  });
  return filteredAssigneeUsers || [];
};

import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../../ui-components';

import { modalConfig } from '../../../../../../sub-components/tasks/create-task/TaskSteps/AddFormChapter/modal.config';
import { ChapterContainer } from '../../../../../../sub-components/tasks/create-task/TaskSteps/AddFormChapter/chapter';
import { IFormInput } from '../../../../../../sub-components/tasks/create-task';
import { useChapterVisibilityCheck } from './chapter';

interface IProps {
  values: string[];
  onAddClick?: (values: IFormInput['contents']) => void;
}

type UseFormResource = (props: IProps) => void;

export const useChapterResource = (): UseFormResource => {
  const { t } = useTranslation(['task']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  const visibilityCheck = useChapterVisibilityCheck();

  return useCallback(({ values, onAddClick }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const _onAddClick = async (_values: IFormInput['contents']) => {
      await Promise.resolve();

      if (_values.some((it) => it?.sopData?.visibility === 'private')) {
        visibilityCheck({
          onAddClick: onAddClick,
          values: _values,
        });
      } else {
        onAddClick?.(_values);
      }
    };

    confirmRef.current = confirm({
      ...modalConfig,

      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader title={t('task:addChapterToTask')} color='#CABDFF' />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),

      content: <ChapterContainer values={values} onAddClick={_onAddClick} />,
    });
  }, []);
};

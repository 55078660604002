import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import {
  Controller,
  FieldPath,
  FieldPathValue,
  useFormContext,
  Validate,
} from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import TimePickerButton from './TimePickerButton';
import { IFormInput } from '../../task.types';
import { PickerWrapper } from './picker.styles';

interface IProps {
  isReadOnly?: boolean;
  isSelected?: boolean;
  placeholderText: string;
  onChange?: (date: Date | null) => void;
  name: FieldPath<IFormInput>;
  minTime?: Date;
  maxTime?: Date;
  validate?: Validate<FieldPathValue<IFormInput, FieldPath<IFormInput>>>;
  deps?: string | string[];
}

const CustomTimePicker: FC<IProps> = ({
  isReadOnly,
  name,
  placeholderText,
  isSelected,
  onChange,
  minTime,
  maxTime,
  validate,
  deps,
}) => {
  const { t } = useTranslation('task');
  const { control } = useFormContext<IFormInput>();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: t('validation.timeRequired'),
        validate: validate,
        deps: deps,
      }}
      render={({ field, fieldState }) => {
        return (
          <FormControl isInvalid={!!fieldState.error} isReadOnly={isReadOnly}>
            <PickerWrapper>
              <DatePicker
                readOnly={isReadOnly}
                selected={field.value as never}
                onChange={(date) => field.onChange(date)}
                onSelect={(date) => onChange?.(date)}
                customInput={
                  <TimePickerButton isSelected={isSelected && !!field.value} />
                }
                placeholderText={placeholderText}
                dateFormat='hh:mm a'
                showTimeSelect
                showTimeSelectOnly
                showTimeInput
                minTime={minTime}
                maxTime={maxTime}
              />
            </PickerWrapper>

            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default CustomTimePicker;

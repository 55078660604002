import { gql } from '@apollo/client';
import { IEmojiStructure } from 'delightree-editor';
import { UserTaskProgress } from 'types';
import { RepeatCycle, RepeatDetails } from '../../../create-task/task.types';

export interface TaskItem {
  type: string;
  eid: string;
  uid?: string;
  icon?: IEmojiStructure;
  title: string;
  isMultiPage?: boolean;
  createdAt: string;
  updatedAt: string;
  lastUpdated?: string;
  visibility?: string;
  createdBy?: string;
  category: string;
  thumbnail: string;
  status?: string;
  smartPageEnabled?: boolean;
  dateAdded: string;
  isAccessible?: boolean;
  isMandatory?: boolean;
}

export interface ITaskData {
  eid: string;
  title: string;
  description?: string;

  taskItems: TaskItem[];

  lastSession: {
    progress?: UserTaskProgress[];
    dueDate: string;
  };

  repeatCycle: RepeatCycle;
  isRepeating: boolean;
  repeatDetails: RepeatDetails<string>;
}

export interface TaskDetailResponse {
  TaskById: ITaskData;
}

export const TASK_DETAILS_QUERY = gql`
  query TaskDetails($eid: String!) {
    TaskById(eid: $eid) {
      eid
      title
      description
      taskItems

      lastSession {
        progress
        dueDate
      }

      repeatCycle
      isRepeating
      repeatDetails
    }
  }
`;

import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Switch } from '@chakra-ui/react';
import { IFormInput } from '../form.types';

interface IProps {
  currentIndex: number;
  isReadOnly?: boolean;
}

const QuestionHiddenField: FC<IProps> = ({ currentIndex, isReadOnly }) => {
  const { control } = useFormContext<IFormInput>();

  return (
    <React.Fragment>
      <Controller
        name={`questions.${currentIndex}.eid`}
        control={control}
        render={({ field }) => {
          return <Input size='lg' {...field} hidden isReadOnly={isReadOnly} />;
        }}
      />

      <Controller
        name={`questions.${currentIndex}.qType`}
        control={control}
        render={({ field }) => {
          return <Input size='lg' {...field} hidden isReadOnly={isReadOnly} />;
        }}
      />

      <Controller
        name={`questions.${currentIndex}.hasAnalytics`}
        render={({ field }) => {
          return (
            <Switch
              isChecked={field.value}
              onChange={field.onChange}
              hidden
              isReadOnly={isReadOnly}
            />
          );
        }}
      />
    </React.Fragment>
  );
};

export default QuestionHiddenField;

import React, { FC, useEffect, useRef } from 'react';
import { Box, Grid } from '@chakra-ui/react';

import { ListBaseProps } from '../chapert-view.types';
import ChapterGridItem from './ChapterGridItem';
import ListPagination from '../../../atoms/ListPagination';
import Loader from '../../../ui-components/Loader';
import EmptyChapter from '../ChapterListView/EmptyChapter';
import { SelectOption } from 'atoms';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';

interface IProps extends ListBaseProps {
  typeFilter: SelectOption<string> | null;
  checkboxSelectHandler: (
    type: 'single' | 'all',
    selectedValue: boolean,
    selectedId?: string,
    selectedType?: string
  ) => void;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
  selectedItems: {
    type: string;
    eid: string;
  }[];
}

const ChapterGridView: FC<IProps> = ({
  dataList,
  currentPage,
  perPage,
  itemCount = 0,
  onPageChange,
  loading,
  typeFilter,
  selectedItems,
  checkboxSelectHandler,
  onClickedHandler,
  getMenuData,
  onAddChapterClick,
  setHeight,
}) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const userObject = useReactiveVar(userObj);
  useEffect(() => {
    if (listRef.current && dataList?.length > 0) {
      setHeight(listRef.current.scrollHeight);
    }
  }, [dataList]);
  if (loading) {
    return (
      <div className='sop-list-loader'>
        <Loader />
      </div>
    );
  }

  if (!loading && dataList.length === 0) {
    return (
      <Box pl='8px' className='sop-list-loader'>
        <EmptyChapter onAddChapterClick={onAddChapterClick} />
      </Box>
    );
  }

  return (
    <Box pt='16px' w='full'>
      <Grid
        ref={listRef}
        templateColumns={{
          base: 'repeat(4, 1fr)',
          '2xl': 'repeat(5, 1fr)',
        }}
        gap={5}
      >
        {dataList.map((value) => {
          return (
            <ChapterGridItem
              key={value.eid}
              data={value}
              userObject={userObject}
              onClick={onClickedHandler}
              getMenuData={getMenuData}
              selectedItems={selectedItems}
              checkboxSelectHandler={checkboxSelectHandler}
            />
          );
        })}
      </Grid>
      {(typeFilter?.value !== 'subFolders' || !typeFilter) &&
        itemCount > 10 && (
          <ListPagination
            onPageChange={onPageChange}
            data={dataList}
            totalRegisters={itemCount || 0}
            page={currentPage || 1}
            registersPerPage={perPage}
          />
        )}
    </Box>
  );
};

export default ChapterGridView;

import { useEffect, useState } from 'react';
import { Menu, Progress, Row, Button, SubMenuProps, Steps } from 'antd';
import {
  PlusSquareOutlined,
  ArrowRightOutlined,
  CheckSquareFilled,
} from '@ant-design/icons';
import { useQuery, gql, useReactiveVar } from '@apollo/client';
import './OnboardingMenu.css';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { ReactComponent as MenuIconSvg } from '../../assets/images/menuIcon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { userObj } from 'sop-commons/src/client/clientFactory';
import {
  faLink,
  faTrashAlt,
  faChevronDown,
  faPlusCircle,
  faChevronUp,
  faCheckCircle,
  faMinusSquare,
  faSquareCheck as fasSquareCheck,
} from '@fortawesome/free-solid-svg-icons';
const { SubMenu } = Menu;
const { Step } = Steps;

import { faSquareCheck } from '@fortawesome/pro-light-svg-icons';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { ADMIN } from '../../utils/userRoles';
import { GET_USER } from '../../pages/Login/login.graphql';

type OnboardingMenuProps = {
  handleMenuClick?: any;
  menuStyle?: object;
  subMenuIcon?: any;
  menuMode?: any;
  menuDefaultSelectedKeys?: string[];
  menuDefaultOpenKeys?: string[];
  currentSelectedKeys?: string[];
  menuProgressBarPercent?: number;
  disableMenuItem?: boolean;
  handleSelectCallback?: any;
  subMenu1Disabled?: boolean;
  subMenu2Disabled?: boolean;
  getMenuItems?: any;
  sopRequestCategoryName?: any;
  isUserDataExist?: any;
  isOnboarded?: boolean;
  name?: string;
  isAdmin?: boolean;
  isSopList?: boolean;
  isRemoveContinue?: boolean;
};
const companyInfoMenu = ['Your Name', 'Your Role'];

export const OnboardingMenu = ({
  handleMenuClick,
  menuStyle,
  menuDefaultSelectedKeys,
  menuDefaultOpenKeys,
  menuMode,
  currentSelectedKeys,
  menuProgressBarPercent,
  disableMenuItem,
  handleSelectCallback,
  subMenu1Disabled,
  subMenu2Disabled,
  getMenuItems,
  sopRequestCategoryName = [],
  isUserDataExist,
  isOnboarded,
  name,
  // isAdmin = true,
  isRemoveContinue,
  subMenuIcon = (
    <FontAwesomeIcon
      icon={faSquareCheck}
      style={{ width: 16, height: 16, color: ' #BBBBBB' }}
    />
  ),
}: OnboardingMenuProps) => {
  const history = useHistory();
  const userData = useReactiveVar<any>(userObj);
  const { pathname } = useLocation();
  const [sopCategory, setSopCategory] = useState<any>();
  const [sopArray, setSopArray] = useState([] as object[]);
  const [subMenuExpanded, setSubMenuExpanded] = useState([]);
  const [indexOfVisitedCategory, setIndexOfVisitedCategory] = useState(0);
  const {
    loading: getUserLoading,
    error: getUserError,
    data: getUserData,
  } = useQuery(GET_USER);

  useEffect(() => {
    if (menuDefaultOpenKeys?.length) {
      setSubMenuExpanded(menuDefaultOpenKeys);
    }
  }, []);

  useEffect(() => {
    const { entity, account } = userData || {};
    const { category } = entity || {};
    setSopCategory(category);
    setIndexOfVisitedCategory(
      category?.findIndex(
        (category) => account?.onboardingStage === category?.name
      )
    );
    if (getMenuItems) {
      getMenuItems(category);
    }
    if (getUserError?.message === 'You must be authorized.') {
      history.push('/');
    }
  }, [userData]);

  const getMenuItem = (indexOfMenuItem: string) => {
    return sopCategory[indexOfMenuItem];
  };
  const handleSelect = (event: any) => {
    if (handleSelectCallback) {
      handleSelectCallback(getMenuItem(event?.key), event);
    }
  };

  const isDisabled = () => {
    if (!isOnboarded && !sopRequestCategoryName?.length) {
      return true;
    }
  };

  const user: any = useReactiveVar(userObj);
  const isAdmin = user?.authRole === ADMIN;
  const customDot = (dot, { status, index }) => {
    return (
      <div
        className='progress-dot'
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {status === 'finish' ? (
          <FontAwesomeIcon
            style={{ height: 20, width: 20 }}
            icon={faCheckCircle}
            color='#52B596'
          />
        ) : status === 'process' ? (
          <div
            style={{
              display: 'flex',
              borderRadius: 50,
              width: 20,
              height: 20,
              backgroundColor: '#52B596',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                marginBottom: 0,
                color: '#FFFFFF',
                fontSize: 12,
                fontWeight: 'bold',
              }}
            >
              {index + 1}
            </p>
          </div>
        ) : (
          <div
            style={{
              height: 20,
              width: 20,
              border: 'solid',
              borderRadius: 50,
              borderWidth: 3,
              borderColor: '#dddddd',
            }}
          />
        )}
      </div>
    );
  };
  const onOpenChange = (openKeys: string[]) => {
    setSubMenuExpanded(openKeys);
  };

  return (
    <Menu
      className='eventclass-onboarding-menu onboarding-menu'
      expandIcon={(props: SubMenuProps) => {
        if (props.isOpen) {
          return (
            <FontAwesomeIcon
              style={{
                height: 14,
                width: 16,
                color: '#999999',
              }}
              icon={faChevronUp}
            />
          );
        }
        return (
          <FontAwesomeIcon
            style={{ height: 14, width: 16, color: '#999999' }}
            icon={faChevronDown}
          />
        );
      }}
      onOpenChange={onOpenChange}
      onSelect={handleSelect}
      onClick={handleMenuClick}
      style={menuStyle}
      defaultSelectedKeys={menuDefaultSelectedKeys}
      defaultOpenKeys={menuDefaultOpenKeys}
      selectedKeys={currentSelectedKeys}
      mode={menuMode}
    >
      {!isOnboarded && (
        <>
          <p
            style={{
              fontWeight: 700,
              color: '#333B4F',
              fontSize: 14,
              marginLeft: 24,
              marginTop: 35,
            }}
          >
            Set up Delightree
          </p>
          <div>
            <Steps
              className='progress-bar'
              progressDot={customDot}
              labelPlacement='vertical'
              size='small'
              current={
                pathname?.includes('company-info')
                  ? 0
                  : pathname?.includes('sop-list')
                  ? 2
                  : 1
              }
            >
              <Step
                className='progress-bar'
                title={
                  <p
                    style={{
                      color: !pathname?.includes('company-info')
                        ? '#52B596'
                        : null,
                      fontSize: 10,
                      fontWeight: 'bold',
                      marginLeft: 18,
                    }}
                  >
                    INFO
                  </p>
                }
              />
              <Step
                className='progress-bar'
                title={
                  <p
                    style={{
                      color: pathname?.includes('sop-list') ? '#52B596' : null,
                      fontSize: 10,
                      fontWeight: 'bold',
                      marginLeft: 10,
                    }}
                  >
                    CATEGORIES
                  </p>
                }
              />
              <Step
                className='progress-bar'
                title={
                  <p
                    style={{ marginLeft: 10, fontSize: 10, fontWeight: 'bold' }}
                  >
                    FINISH
                  </p>
                }
              />
            </Steps>
          </div>
          <SubMenu
            className='eventclass-onboarding-submenu'
            onTitleClick={() =>
              history.push({
                pathname: '/onboarding/company-info',
              })
            }
            disabled={subMenu1Disabled}
            key='sub1'
            icon={
              userData?.name &&
              userData?.role &&
              !subMenuExpanded?.includes('sub1') ? (
                <FontAwesomeIcon
                  icon={fasSquareCheck}
                  style={{
                    width: 18,
                    height: 18,
                    color: '#3BA578',
                  }}
                />
              ) : subMenuExpanded?.includes('sub1') ? (
                <FontAwesomeIcon
                  icon={faMinusSquare}
                  style={{ width: 18, height: 18, color: '#3BA578' }}
                />
              ) : (
                subMenuIcon
              )
            }
            title={
              <p
                style={{
                  marginTop: 15,
                  color: '#000000',
                  fontWeight: '500',
                  fontSize: 15,
                }}
              >
                Company Info
              </p>
            }
          >
            {companyInfoMenu?.map((option, index) => (
              <Menu.Item
                className='eventclass-onboarding-company-info-menu-item onboarding-company-info-menu-item onboarding-sop-category-menu-item'
                onClick={() =>
                  history.push({
                    pathname: '/onboarding/company-info',
                  })
                }
                icon={
                  index === 0 ? (
                    userData?.name ? (
                      <FontAwesomeIcon
                        icon={fasSquareCheck}
                        style={{ width: 18, height: 18, color: '#3BA578' }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        style={{ width: 18, height: 18, color: ' #BBBBBB' }}
                      />
                    )
                  ) : userData?.role ? (
                    <FontAwesomeIcon
                      icon={fasSquareCheck}
                      style={{ width: 18, height: 18, color: '#3BA578' }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSquareCheck}
                      style={{ width: 18, height: 18, color: ' #BBBBBB' }}
                    />
                  )
                }
                key={index}
                disabled={
                  option === 'Your Role' ? (name ? false : true) : false
                }
              >
                {option}
              </Menu.Item>
            ))}
          </SubMenu>
        </>
      )}
      {isAdmin && (
        <>
          <SubMenu
            className='eventclass-onboarding-sop-category-submenu onboarding-sop-category-submenu'
            style={{ marginBottom: 10 }}
            onTitleClick={() => {
              // if (isOnboarded) {
              //   history.push({
              //     pathname: '/request-sop/new',
              //   });
              // } else {
              //   history.push({
              //     pathname: '/onboarding/add-sop',
              //   });
              // }
            }}
            disabled={subMenu2Disabled}
            key='sub2'
            icon={
              sopRequestCategoryName &&
              sopRequestCategoryName?.length &&
              !subMenuExpanded?.includes('sub2') ? (
                <FontAwesomeIcon
                  icon={fasSquareCheck}
                  style={{ width: 18, height: 18, color: '#3BA578' }}
                />
              ) : subMenuExpanded?.includes('sub2') ? (
                <FontAwesomeIcon
                  icon={faMinusSquare}
                  style={{ width: 18, height: 18, color: '#3BA578' }}
                />
              ) : (
                subMenuIcon
              )
            }
            title={
              <p
                style={{
                  marginTop: 15,
                  color: '#000000',
                  fontWeight: '500',
                  fontSize: 15,
                }}
              >
                SOP Categories
              </p>
            }
          >
            {sopCategory?.map((category: any, index: number) => (
              <Menu.Item
                className='eventclass-onboarding-sop-category-menu-item onboarding-sop-category-menu-item'
                onClick={() => {
                  if (isOnboarded) {
                    history.push({
                      pathname: '/request-sop/new',
                      state: { passedRouteCategory: category },
                    });
                  } else {
                    history.push({
                      pathname: '/onboarding/add-sop',
                      state: { passedRouteCategory: category },
                    });
                  }
                }}
                disabled={
                  indexOfVisitedCategory === -1
                    ? index > 0
                    : index > indexOfVisitedCategory
                }
                icon={
                  sopRequestCategoryName?.includes(category?.name) ? (
                    <FontAwesomeIcon
                      icon={fasSquareCheck}
                      style={{ width: 18, height: 18, color: '#3BA578' }}
                    />
                  ) : index < indexOfVisitedCategory ? (
                    <FontAwesomeIcon
                      icon={faSquareCheck}
                      style={{ width: 18, height: 18, color: '#BBBBBB' }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSquareCheck}
                      style={{ width: 17, height: 17, color: '#BBBBBB' }}
                    />
                  )
                }
                key={index}
              >
                {disableMenuItem ? <s>{category?.name}</s> : category?.name}
              </Menu.Item>
            ))}
          </SubMenu>
          {((!isRemoveContinue && sopRequestCategoryName?.length > 0) ||
            !isAdmin) && (
            <Row
              className='eventclass-continue-button'
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10,
                marginTop: 20,
                marginLeft: 15,
                marginRight: 35,
              }}
            >
              <Button
                onClick={() => {
                  if (isOnboarded) {
                    history?.replace('/request-sop/continue');
                  } else {
                    history?.replace('/onboarding/sop-list');
                  }
                }}
                type='text'
                style={{
                  alignSelf: 'stretch',
                  color: '#4D8AF0',
                  fontWeight: 700,
                  alignItems: 'space-between',
                  justifyContent: 'space-between',
                }}
                disabled={isDisabled()}
              >
                Finish
              </Button>
              <ArrowRightOutlined
                onClick={() => {
                  if (isOnboarded) {
                    history?.replace('/request-sop/continue');
                  } else {
                    history?.replace('/onboarding/sop-list');
                  }
                }}
                style={{ fontWeight: 700, color: '#4D8AF0' }}
              />
            </Row>
          )}
        </>
      )}
    </Menu>
  );
};

import { Link } from 'react-router-dom';
// import css
import './RoleLabel.css';

interface Props {
  colorCode?: string;
  title: string;
  style?: object;
}

const RoleLabel = ({ colorCode, title, style }: Props) => {
  return (
    <Link to={`/dashboard?roles=${title}`}>
      <div
        className='role-label-container'
        style={{
          borderColor: colorCode,
          color: colorCode,
          backgroundColor: `${colorCode}26`,
          ...style,
        }}
      >
        {title}
      </div>
    </Link>
  );
};

export default RoleLabel;

import { useEffect, useState } from 'react';
import BaseFilterComponent from './BaseFilterComponent';
import JobFilterComponent from './JobFilterComponent';
import LocationFilterComponent from './LocationFilterComponent';
import MemberFilterComponent from './MemberFilterComponent';
import CreatedByFilterComponent from './CreatedByFilterComponent';

type MainFilterContainerProps = {
  extraFilterData: any;
  setCheckedOptionsData: any;
  checkedOptionsData: any;
};

const MainFilterContainer = ({
  extraFilterData,
  checkedOptionsData,
  setCheckedOptionsData,
}: MainFilterContainerProps) => {
  const [pageName, setPageName] = useState('');
  const [sortedJobArray, setSortedJobArray] = useState([]);
  const [sortedLocationArray, setSortedLocationArray] = useState([]);
  const [sortedMemberArray, setSortedMemberArray] = useState([]);
  const [sortedCreatedByArray, setSortedCreatedByArray] = useState([]);

  //total filters
  extraFilterData = {
    ...extraFilterData,
    pageName,
    setPageName,
  };
  const { setTotalFilters, isPopoverOpen } = extraFilterData;

  const {
    checkedJobList,
    checkedLocationList,
    checkedMemberList,
    checkCreatedByList,
  } = checkedOptionsData;

  useEffect(() => {
    setPageName('base');
  }, [isPopoverOpen]);

  useEffect(() => {
    const totalFilterCount =
      checkedJobList.length +
      checkedLocationList.length +
      checkedMemberList.length +
      checkCreatedByList.length;
    setTotalFilters(totalFilterCount);
  }, [
    checkedJobList,
    checkedLocationList,
    checkedMemberList,
    checkCreatedByList,
  ]);

  if (pageName === 'job') {
    return (
      <JobFilterComponent
        extraFilterData={extraFilterData}
        sortedJobArray={sortedJobArray}
        setSortedJobArray={setSortedJobArray}
        setCheckedOptionsData={setCheckedOptionsData}
        checkedOptionsData={checkedOptionsData}
      />
    );
  }

  if (pageName === 'location') {
    return (
      <LocationFilterComponent
        extraFilterData={extraFilterData}
        sortedLocationArray={sortedLocationArray}
        setSortedLocationArray={setSortedLocationArray}
        setCheckedOptionsData={setCheckedOptionsData}
        checkedOptionsData={checkedOptionsData}
      />
    );
  }

  if (pageName === 'member') {
    return (
      <MemberFilterComponent
        extraFilterData={extraFilterData}
        sortedMemberArray={sortedMemberArray}
        setSortedMemberArray={setSortedMemberArray}
        setCheckedOptionsData={setCheckedOptionsData}
        checkedOptionsData={checkedOptionsData}
      />
    );
  }

  if (pageName === 'createdBy') {
    return (
      <CreatedByFilterComponent
        extraFilterData={extraFilterData}
        sortedCreatedByArray={sortedCreatedByArray}
        setSortedCreatedByArray={setSortedCreatedByArray}
        setCheckedOptionsData={setCheckedOptionsData}
        checkedOptionsData={checkedOptionsData}
      />
    );
  }

  return (
    <>
      <BaseFilterComponent
        extraFilterData={extraFilterData}
        setCheckedOptionsData={setCheckedOptionsData}
        checkedOptionsData={checkedOptionsData}
      />
    </>
  );
};

export default MainFilterContainer;

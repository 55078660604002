import React, { FC, useState } from 'react';
import { Box } from '@chakra-ui/react';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import finishFlagData from '../../assets/lotties/finish_flag.json';
import PrimaryButton from '../../atoms/PrimaryButton';
import { truncateString } from '../../utils';
import GenericModal from '../../ui-components/GenericModal';
import closeIcon from '../../assets/images/closeIcon.png';

interface IProps {
  title: string;
  onFinish?: () => void;
  retakeTraining?: () => Promise<void>;
}

const TrainingFinished: FC<IProps> = ({ title, onFinish, retakeTraining }) => {
  const { t } = useTranslation(['common', 'training']);
  const [retakeTrainingOpen, setRetakeTraining] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: finishFlagData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const onClose = () => {
    setRetakeTraining(false);
  };

  const onConfirm = async () => {
    try {
      setLoading(true);
      await retakeTraining?.();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setRetakeTraining(false);
    }
  };

  return (
    <Box
      height={'100%'}
      display='flex'
      flexDirection={'column'}
      alignItems={'center'}
      textAlign={'center'}
      overflowY={'auto'}
      padding={'16px'}
    >
      <div
        style={{
          width: '100%',
          margin: 'auto',
          // overflow: 'hidden',
        }}
      >
        <div
          style={{
            fontSize: 22,
            fontWeight: 'bold',
            color: '#000',
            width: '100%',
            padding: '0 16px 16px',
          }}
        >
          {t('training:progress_modal_congratulations')}!
        </div>
        <Lottie options={defaultUploadLottieOptions} height={240} width={240} />
        <div
          style={{
            fontSize: 16.8,
            fontWeight: '500',
            color: '#000',
            width: '100%',
            padding: '16px',
          }}
        >
          {t('training:progress_modal_have_finished')}{' '}
          <strong>{truncateString(title, 60)}</strong>
        </div>
      </div>

      <PrimaryButton
        type='button'
        size='lg'
        title={t('training:progress_modal_retake')}
        variant='outline'
        style={{
          borderColor: '#000000',
          marginBottom: 8,
          marginTop: 'auto',
        }}
        onClick={() => setRetakeTraining(true)}
      />
      <PrimaryButton
        type='submit'
        size='lg'
        title={t('training:progress_modal_finish')}
        variant='solid'
        colorScheme='blue'
        onClick={onFinish}
      />
      <GenericModal
        isOpen={retakeTrainingOpen}
        onClose={onClose}
        title={t('training:retake_confirm')}
        closeIcon={closeIcon}
        lineColor={'#b5e4ca'}
        isCentered={true}
        buttonsObject={[
          {
            mt: 6,
            type: 'button',
            size: 'lg',
            isLoading: false,
            disabled: false,
            title: t('common:cancel'),
            leftIcon: null,
            rightIcon: null,
            onClick: onClose,
            style: {
              width: '160px',
              backgroundColor: 'white',
              marginRight: '1rem',
            },
            variant: 'outline',
            colorScheme: 'blue',
            className: null,
            value: null,
          },
          {
            mt: 6,
            type: 'button',
            size: 'lg',
            isLoading: loading,
            disabled: false,
            title: t('common:ok_uppercase'),
            leftIcon: null,
            rightIcon: null,
            onClick: onConfirm,
            style: { width: '160px' },
            variant: 'solid',
            colorScheme: 'blue',
            className: null,
            value: null,
          },
        ]}
        buttonsPosition='right'
        paddingLeft={30}
        paddingRight={30}
        paddingTop={30}
        paddingBottom={20}
        modalSizes={{
          sm: '800px',
          md: '800px',
          base: '800px',
          xl: '800px',
          ['2xl']: '800px',
        }}
        modalMinSizes={{
          sm: '800px',
          md: '800px',
          base: '800px',
          xl: '800px',
          ['2xl']: '800px',
        }}
      >
        <div
          style={{
            marginTop: '13px',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '1.63',
            letterSpacing: '-0.16px',
            marginLeft: '20px',
            width: '93%',
          }}
        >
          {null}
          {/* Looks like you don&apos;t have visibility access to this deck / card. Please contact your manager to get access! */}
        </div>
      </GenericModal>
    </Box>
  );
};

export default TrainingFinished;

import React, { FC } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';

import { BoxHeader } from 'ui-components';

import GenericColorHeader from 'sub-components/GenericColorHeader';

import DeleteFormModalBody from './DeleteFormModalBody';

const DeleteFormModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  desc: string;
}> = ({ isOpen, desc, title, onClose }) => {
  const history = useHistory();
  const params: { id: string } = useParams();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl'>
      <ModalOverlay />
      <ModalContent p={4}>
        <ModalHeader pb={1}>
          <BoxHeader fontSize='18px' title={title} color='#CABDFF' />
          <ModalCloseButton m={4} />
        </ModalHeader>
        <ModalBody>
          <DeleteFormModalBody
            formId={params?.id}
            desc={desc}
            onDeleteSuccess={() => history.push('/forms')}
            onDeleteError={() => history.push('/forms')}
            onSheetGenerated={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeleteFormModal;

import { SelectOption } from 'atoms';
import { AuthRole } from 'authorization';

import { UpdateGroupVariable } from './create-channel.graphql';

type ChannelData = Pick<
  UpdateGroupVariable,
  'authRoles' | 'roles' | 'locationIds'
>;

export const mapAddToChannel = (
  groupOptions: SelectOption[] = [],
  addToChannel: string[] = [],
  autoAddMember: boolean
): ChannelData => {
  if (!autoAddMember) {
    return {
      roles: [],
      authRoles: [],
      locationIds: [],
    };
  }

  return groupOptions.reduce<ChannelData>(
    (previousValue, currentValue) => {
      if (addToChannel?.includes(currentValue.value)) {
        switch (currentValue.type) {
          case 'location':
            previousValue.locationIds.push(currentValue.value);
            break;
          case 'job':
            previousValue.roles.push(currentValue.value);
            break;
          case 'role':
            previousValue.authRoles.push(currentValue.value as AuthRole);
            break;
        }
      }

      return previousValue;
    },
    {
      roles: [],
      authRoles: [],
      locationIds: [],
    }
  );
};

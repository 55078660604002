import { FC } from "react";
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";

interface IProps {
  open: boolean;
  onClose: () => void;
  imageUrl?: string;
}

const ImageModal: FC<IProps> = ({ open, onClose, imageUrl }) => {
  return (
    <Modal isOpen={open} isCentered={true} onClose={onClose}>
      <ModalOverlay zIndex={1403} />
      <ModalContent
        style={{ borderRadius: "unset" }}
        containerProps={{
          zIndex: 1403,
        }}
      >
        <ModalCloseButton
          style={{
            outline: "none",
            boxShadow: "none",
          }}
          backgroundColor={"rgba(0, 0, 0, 0.3)"}
          borderRadius={"50%"}
          color={"white"}
        />
        <ModalBody style={{ padding: "0px" }}>
          <img src={imageUrl} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;

import React from 'react';
import editIcon from './resources/edit.svg';
import settingIcon from './resources/setting.svg';
import clearIcon from './resources/close.svg';
import { useChannelSetting } from '../../../../../../pages/Settings/ChatSetting/create-channel/useChannelSetting';

const GroupDetailsAction = ({ className, onCloseClick, group, user }) => {
  const updateSetting = useChannelSetting();

  const onEditClick = () => {
    return updateSetting({
      activeTab: 0,
      groupId: group?.guid,
      // reFetchData: getChatGroups,
      // onMessage: (userId: string) => setChatUserId(userId),
    });
  };

  const onSettingClick = () => {
    return updateSetting({
      activeTab: 2,
      groupId: group?.guid,
      // reFetchData: getChatGroups,
      // onMessage: (userId: string) => setChatUserId(userId),
    });
  };

  const isGroup = group?.guid?.includes('group_');

  const canEdit = group?.owner === user?.getUid();

  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      {isGroup && canEdit && (
        <div className={className} onClick={onEditClick}>
          <img src={editIcon} style={{ width: '20px', height: '20px' }} />
        </div>
      )}
      {isGroup && canEdit && (
        <div className={className} onClick={onSettingClick}>
          <img src={settingIcon} style={{ width: '20px', height: '20px' }} />
        </div>
      )}
      <div className={className} onClick={onCloseClick}>
        <img src={clearIcon} style={{ width: '20px', height: '20px' }} />
      </div>
    </div>
  );
};

export default GroupDetailsAction;

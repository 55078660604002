import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';

import { BoxHeader, useConfirm } from '../../../../../ui-components';
import { FormContainer } from './form';
import { IFormInput } from '../../task.types';
import { modalConfig } from './modal.config';

interface IProps {
  values: string[];
  onAddClick?: (values: IFormInput['contents']) => void;
}

type UseFormModal = (props: IProps) => void;
export const useFormModal = (): UseFormModal => {
  const { t } = useTranslation(['task']);
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ values, onAddClick }) => {
    confirm({
      ...modalConfig,

      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader title={t('task:addFormToTask')} color='#CABDFF' />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),

      content: <FormContainer values={values} onAddClick={onAddClick} />,
    });
  }, []);
};

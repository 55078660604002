import { gql } from '@apollo/client';
import { AuthRole } from '../../../../authorization';
import { UserStatus, UserType } from '../../../../types';

export interface UserDataResponse {
  userById: {
    name: string;
    profilePic?: string;
    phone?: string;
    locations: {
      name: string;
      eid: string;
    }[];
    role: string;
    authRole: AuthRole;
    eid: string;
    email: string;
    username: string;
    type: UserType;
    status: UserStatus;
    isDeleted: boolean;
  };
}

export const USER_DATA_QUERY = gql`
  query SelectedUserData($eid: String!) {
    userById(eid: $eid) {
      name
      profilePic
      phone
      locations {
        name
        eid
      }
      role
      authRole
      eid
      email
      username
      type
      status
      isDeleted
    }
  }
`;

export interface UpdateProfileVariable {
  input: {
    email: string;
    name: string;
    phone: string;
    profilePic: string;
    role: string;
  };
}

export const UPDATE_PROFILE = gql`
  mutation UpdateUser($input: UserUpdateInput) {
    updateUser(input: $input) {
      eid
    }
  }
`;

export interface UpdateLocationVariable {
  input: {
    eid: string;
    username: string;
    name: string;
    phone: string;
    profilePic: string;
    // role: string;
  };
}

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      name
      phone
      eid
    }
  }
`;

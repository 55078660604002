import { gql, QueryResult, useQuery } from '@apollo/client';
import { UserAnalytics } from '../../../../sub-components/tasks/performance';

export interface GraphEntity {
  name: string;
  completeRate: number;
}

export type ITaskPerformanceType = 'location' | 'role';

export interface TaskPerformanceVariable {
  taskId: string;
  type: ITaskPerformanceType;
  sessionId?: string;
}

export interface PerformanceResponse {
  TaskPerformance: Record<string, UserAnalytics>;
}

export const TASk_PERFORMANCE_QUERY = gql`
  query TaskPerformance(
    $taskId: String!
    $type: TaskPerformanceAnalyticsType!
    $sessionId: String
  ) {
    TaskPerformance(taskId: $taskId, type: $type, sessionId: $sessionId)
  }
`;

export const useTaskPerformance = (
  variables: TaskPerformanceVariable
): QueryResult<PerformanceResponse, TaskPerformanceVariable> => {
  return useQuery<PerformanceResponse, TaskPerformanceVariable>(
    TASk_PERFORMANCE_QUERY,
    {
      fetchPolicy: 'network-only',
      skip: !variables.taskId,
      variables: variables,
    }
  );
};

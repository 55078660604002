import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ApolloProvider, useApolloClient } from '@apollo/client';

import { UseConfirm, useConfirm } from '../../../../ui-components';
import VisibilityFooter from './VisibilityFooter';

import PreviewHeader from '../../component/PreviewHeader';
import VisibilityContent from './VisibilityContent';
import { VisibilityProvider } from './context';
import { FormVisibility, IVisibilityInput } from './visibility.types';

interface IProps {
  isEdit?: boolean;
  initialValues?: Partial<FormVisibility>;
  onApplyClick?: (values: IVisibilityInput) => PromiseLike<void>;
  onCancelClick?: () => void;
}

type UseVisibility = (props: IProps) => void;

export const useVisibility = (): UseVisibility => {
  const { t } = useTranslation(['common']);
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(
    (props) => {
      if (confirmRef.current) {
        confirmRef.current?.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <VisibilityProvider
                initialValue={props.isEdit ? props.initialValues : undefined}
                onApplyClick={props.onApplyClick}
              >
                {children}
              </VisibilityProvider>
            </ApolloProvider>
          );
        },
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <PreviewHeader
                title={t('common:setVisibility')}
                color='#CABDFF'
              />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: <VisibilityContent />,
        contentProps: {
          py: '10px',
          px: '6px',
          minW: '800px',
        },
        footer: <VisibilityFooter onCancelClick={props.onCancelClick} />,
      });
    },
    [confirm]
  );
};

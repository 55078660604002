import { Box } from '@chakra-ui/react';
import { Card } from 'antd';
import React, { FC } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { IChartEntity } from '../FormSummary';

interface IMultipleChoiceChart {
  multi: IChartEntity;
}

const MultipleChoiceChart: FC<IMultipleChoiceChart> = ({ multi }) => {
  const { t } = useTranslation(['form']);
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    if (percent > 0) {
      const RADIAN = Math.PI / 180;
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill='white'
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline='central'
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      );
    }
  };

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <ul
        style={{
          listStyle: 'none',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        {payload.map((entry, index) => (
          <li key={`item-${index}`}>
            <div
              style={{
                height: '10px',
                width: '10px',
                borderRadius: '50%',
                backgroundColor: `${entry?.color}`,
              }}
            />
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'black', borderRadius: '7px' }}>
          <p
            style={{ color: 'white', padding: '0.5rem 1rem' }}
          >{`${payload?.[0]?.payload?.originalValue}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card style={{ width: '100%' }}>
      <div>
        <p style={{ fontWeight: '600', fontSize: '18px' }}>
          {multi?.questionName}
        </p>
        {multi?.totalResponseCount && (
          <p>
            {t(
              multi?.totalResponseCount > 1 ? 'form:responses' : 'form:response'
            )}
          </p>
        )}
      </div>
      <Box overflow='auto' height='500px' maxWidth='100%'>
        <ResponsiveContainer width='100%'>
          <PieChart width={600} height={400}>
            <Pie
              data={multi?.questionResponseData}
              cx={200}
              cy={200}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={180}
              fill='#8884d8'
              dataKey='value'
              stroke=''
            >
              {multi?.questionResponseData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry?.color} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={renderLegend}
              layout='vertical'
              verticalAlign='middle'
              align='right'
              wrapperStyle={{
                right: '10%',
                top: 0,
                marginLeft: '1rem',
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Card>
  );
};

export default MultipleChoiceChart;

import { ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { dateFilterOptions } from '../data/dateFilterOptions';
import { IDateFilterOptionsProps } from '../types';

const DateFilterOptions: FC<IDateFilterOptionsProps> = ({
  internalSelectedDateFilter,
  filterSelectionHandler,
}) => {
  return (
    <Flex
      zIndex='2'
      bg='white'
      overflowY='auto'
      w='180px'
      flexDir='column'
      gap={2}
    >
      {dateFilterOptions?.map((option, index) => {
        return (
          <Flex
            justify='space-between'
            align='center'
            key={option?.id}
            _hover={{ bg: 'gray.100', borderRadius: '7px' }}
            cursor='pointer'
            borderRadius='7px'
            bg={
              internalSelectedDateFilter?.title === option?.title
                ? 'gray.100'
                : 'none'
            }
            p='5px'
            onClick={() => filterSelectionHandler(option)}
          >
            <Flex flexDir='column'>
              <Text as='span' fontWeight={500} m={0} p={0}>
                {option?.title}
              </Text>
              <Text
                as='span'
                fontSize='12px'
                color='rgba(111, 118, 126, 1)'
                m={0}
                p={0}
              >
                {option?.description}
              </Text>
            </Flex>
            {option?.title === 'Custom' && (
              <ChevronRightIcon
                fontSize='25px'
                color='rgba(111, 118, 126, 1)'
              />
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default DateFilterOptions;

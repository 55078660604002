import { Center, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

interface IProps {
  value: string;
}

const LocationCellComponent: FC<IProps> = ({ value }) => {
  return (
    <Flex
      borderRadius='7px'
      gap='5px'
      bg='#e0f4ea'
      align='center'
      p='5px 10px'
      width='fit-content'
    >
      <Center bg='#83bf6e' p='10px' borderRadius='7px' boxSize='25px'>
        <FontAwesomeIcon icon={faLocationDot as IconProp} color='white' />
      </Center>
      <Text>{value}</Text>
    </Flex>
  );
};

export default LocationCellComponent;

// InviteMemberModal.tsx
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { gql } from '@apollo/client';
import InviteUserForm, { AddTempLO } from './InviteUserForm';
import { IFormInput } from './invite.types';
import Header from './Header';
import ShareLinkTabs from './ShareLinkTabs';
import ChatGroups from './ChatGroups';
import TrainingPaths from './TrainingPaths';
import { AuthRole } from '../../authorization';
import { InvitedUser } from './invite.graphql';
import { useUserEntity } from 'hooks';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onActionCompleted?: (pathName: string, tabSelected: string) => void;
  /** Callback function to return added temporary LO */
  tempMemberAdd?: (member: AddTempLO) => void;
  shouldRefetch?: (data: Omit<InvitedUser, 'inviteUrl' | 'code'>) => void;
  inviteOnlyFor?: AuthRole;
  defaultValues?: Pick<IFormInput, 'location'>;
  /** Used to disable location select if passing default location value which cannot be changed. Example is inviting location owner for a specific location, there another location cannot be added */
  shouldDisableLocationSelect?: boolean;
  /** Used to hide the 'cross' icon from selected location chips. Specifically used when inviting location owner for a specific location and that location must not be removed as location owner is being added for that location only */
  shouldPreventLocationRemoval?: boolean;
  /** Boolean value to enable/disable `Invite & Setup Now` button */
  shouldDisableInviteSetupNowBtn?: boolean;
  /** If some other button(s) to show instead of default buttons */
  customActionBtn?: React.ReactNode;
  /** Used to disable all permission level radio buttons */
  disableAllPermissions?: boolean;
}

const GET_BRANCH = gql`
  query UI_IUN_Branch {
    branch {
      descendantBranches {
        name
        eid
        type
        role
        authRole
        email
        createdAt
        status
        isRoot
      }
      root {
        name
        eid
      }
    }
  }
`;

const InviteUserModal: FC<IProps> = ({
  isOpen,
  onClose,
  onActionCompleted,
  shouldRefetch,
  tempMemberAdd,
  inviteOnlyFor,
  defaultValues,
  shouldDisableLocationSelect,
  shouldPreventLocationRemoval,
  shouldDisableInviteSetupNowBtn,
  customActionBtn,
  disableAllPermissions,
}) => {
  const locations = useUserEntity((entity) => entity?.locations || []);

  const methods = useForm<IFormInput>({
    defaultValues: {
      currentStep: 'inviteForm',
      permissionLevel: inviteOnlyFor,
      linkSend: {
        email: false,
        phone: false,
      },
      ...defaultValues,
    },
  });

  const rootLocation = useMemo(() => {
    return locations
      ?.filter((loc) => loc?.isRoot)
      ?.map((loc) => ({ eid: loc?.eid, name: loc?.name }))?.[0];
  }, [locations]);

  useEffect(() => {
    if (defaultValues && defaultValues?.location?.length > 0) {
      methods?.reset({
        ...methods.getValues(),
        location: defaultValues?.location,
      });
    }
  }, [defaultValues]);

  const closeHandler = () => {
    methods.reset();
    onClose();
  };

  const currentStep = methods.watch('currentStep');

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeHandler}
      size='xl'
      isCentered={currentStep !== 'inviteForm'}
      scrollBehavior={currentStep !== 'inviteForm' ? 'inside' : undefined}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent padding='12px' w='full'>
        <FormProvider {...methods}>
          <Header />
          {currentStep === 'inviteForm' && (
            <InviteUserForm
              inviteOnlyFor={inviteOnlyFor}
              rootLocation={rootLocation}
              onClose={closeHandler}
              shouldRefetch={shouldRefetch}
              tempMemberAdd={tempMemberAdd}
              shouldDisableLocationSelect={shouldDisableLocationSelect}
              shouldPreventLocationRemoval={shouldPreventLocationRemoval}
              shouldDisableInviteSetupNowBtn={shouldDisableInviteSetupNowBtn}
              customActionBtn={customActionBtn}
              disableAllPermissions={disableAllPermissions}
            />
          )}

          {currentStep === 'shareTabs' && <ShareLinkTabs />}

          {currentStep === 'trainingPath' && (
            <TrainingPaths closeHandler={closeHandler} />
          )}

          {/*{currentStep === 'chatGroup' && (*/}
          {/*  <ChatGroups closeHandler={closeHandler} />*/}
          {/*)}*/}
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

InviteUserModal.displayName = 'ui-components/InviteUserNew/InviteUserModal';

export default InviteUserModal;

import React, { FC } from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';

interface IProps {}

const MemberNameSkeleton: FC<IProps> = () => {
  return (
    <Flex gap='12px' align='center'>
      <Skeleton boxSize='40px' borderRadius='full' />
      <Flex flexDirection='column' gap='8px'>
        <Skeleton height='10px' borderRadius='full' />
        <Flex gap='5px' align='center'>
          <Skeleton h='8px' w='40px' borderRadius='full' />
          <Skeleton boxSize='6px' borderRadius='full' />
          <Skeleton h='8px' w='60px' borderRadius='full' />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MemberNameSkeleton;

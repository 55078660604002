import gql from "graphql-tag";

export const ADD_CARD_RESPONSE = gql`
  mutation AddCardResponse($input: CardResponseAddInputInput) {
    AddCardResponse(input: $input) {
      cardType
      userId
      entityId
      cardId
      response
      responseDetails
      completed
      updatedAt
      createdAt
      eid
    }
  }
`;

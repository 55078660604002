import React, { FC, useMemo, useState } from 'react';
import { List } from '@chakra-ui/react';

import { SelectOption } from '../../../../../atoms';
import { ChatGroupEntity, GroupMemberEntity } from '../../../../../types';

import CMemberItem from './CMemberItem';

import { useJobRoleLocationList } from '../select-group';

import DataNotFound from './DataNotFound';
import { useMemberFilter } from './useMemberData';
import SearchAndFilter from './SearchAndFilter';

export interface CMLProps {
  group: ChatGroupEntity;
}

interface IProps extends CMLProps {
  members: GroupMemberEntity[];
  loading?: boolean;
  onActionClick: (data: GroupMemberEntity) => void;
  actionIcon: React.ReactElement;
}

const BaseChannelMemberList: FC<IProps> = ({
  members,
  loading,
  group,
  actionIcon,
  onActionClick,
}) => {
  const [filterBy, setFilterBy] = useState<SelectOption | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const _options = useJobRoleLocationList();

  const options = useMemo(() => {
    const details = group?.details;

    const check = [
      ...(details?.authRoles || []),
      ...(details?.roles || []),
      ...(details?.locationIds || []),
    ];

    if (check.length === 0) {
      return _options;
    }

    return _options.filter((op) => check.includes(op.value));
  }, [_options, group?.details]);

  const filteredMembers = useMemberFilter(members, filterBy, searchQuery);

  return (
    <React.Fragment>
      {members?.length > 0 && (
        <SearchAndFilter
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          options={options}
          filterBy={filterBy}
          onFilterChange={setFilterBy}
        />
      )}

      <DataNotFound
        isLoading={loading}
        searchQuery={searchQuery || filterBy?.value}
        dataLength={filteredMembers.length}
      >
        <List overflow='auto' spacing={5} minH='40vh' pb={2}>
          {filteredMembers.map((member) => (
            <CMemberItem
              key={member.uid}
              item={member}
              onActionClick={onActionClick}
              actionIcon={actionIcon}
            />
          ))}
        </List>
      </DataNotFound>
    </React.Fragment>
  );
};

export default BaseChannelMemberList;

import React, { ChangeEvent, FC } from 'react';
import { Button } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import { FileName, useFileName } from './file-name';

interface IProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isPreview?: boolean;
  value?: string[];
  onRemove?: () => void;
}

const AddVideo: FC<IProps> = ({
  value,
  onChange,
  isLoading,
  isDisabled,
  isPreview,
  onRemove,
}) => {
  const { t } = useTranslation('form');
  const fileName = useFileName(value);

  if (fileName) {
    return (
      <FileName
        icon={
          <FontAwesomeIcon
            icon={faVideo as IconProp}
            style={{ width: 20, height: 20 }}
          />
        }
        fileName={fileName}
        value={value}
        isDisabled={isDisabled}
        onRemove={onRemove}
      />
    );
  }

  if (isDisabled) {
    return (
      <div>
        <Button
          isDisabled={isDisabled}
          isLoading={isLoading}
          as='div'
          borderRadius='12px'
          h='48px'
          leftIcon={
            <FontAwesomeIcon
              icon={faVideo as IconProp}
              style={{ width: 20, height: 20 }}
            />
          }
          fontWeight='400'
          cursor='pointer'
        >
          {t('record_video')}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Button
        isLoading={isLoading}
        as='div'
        borderRadius='12px'
        h='48px'
        leftIcon={
          <FontAwesomeIcon
            icon={faVideo as IconProp}
            style={{ width: 20, height: 20 }}
          />
        }
        fontWeight='400'
        cursor='pointer'
        onClick={onChange as any}
      >
        {t('record_video')}
      </Button>
    </div>
  );

  // return (
  //   <div>
  //     <label htmlFor='addVideo'>
  //       <Button
  //         isLoading={isLoading}
  //         as='div'
  //         borderRadius='12px'
  //         h='48px'
  //         leftIcon={
  //           <FontAwesomeIcon
  //             icon={faVideo as IconProp}
  //             style={{ width: 20, height: 20 }}
  //           />
  //         }
  //         fontWeight='400'
  //         cursor='pointer'
  //       >
  //         Record Video
  //       </Button>
  //     </label>
  //
  //     {!isLoading ? (
  //       <input
  //         type='file'
  //         onChange={onChange}
  //         id='addVideo'
  //         style={{ display: 'none' }}
  //         accept='video/mp4,video/x-m4v,video/*'
  //       />
  //     ) : null}
  //   </div>
  // );
};

export default AddVideo;

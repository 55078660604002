import React, { FC } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import dragIcon from '../../assets/images/filled.png';
import { ReactComponent as EditIcon } from '../../assets/images/editIcon.svg';
import { ReactComponent as CloseIconDark } from '../../assets/images/closeIconDark.svg';
import { ReactComponent as PowerUpThunderIcon } from '../../assets/images/powerup-menu/powerUpThunderIcon.svg';
import flagIcon from '../../assets/images/flagIcon.png';
import './Milestone.scss';

const DragHandler = SortableHandle(() => (
  <div className='sel-card-left'>
    <div className='drag-icon'>
      <img src={dragIcon} alt='' />
    </div>
  </div>
));

export interface MilestoneProps {
  title?: string;
  card: any;
  index?: number;
  backgroundColor?: string;
  handleRemove?: (id: string) => void;
  handleMilestoneEdit?: (id: string) => void;
}

const Milestone: FC<MilestoneProps> = ({
  title,
  index,
  handleRemove,
  card,
  handleMilestoneEdit,
}) => {
  return (
    <div className='milestone-main-div'>
      <DragHandler />
      <div className='milestone-container'>
        <div className='milestone-flag-img-div'>
          <img className='flagImage' src={flagIcon} />
        </div>
        {title}
      </div>
      <div
        className='edit-milestone'
        style={{ paddingRight: '5px', cursor: 'pointer' }}
      >
        <EditIcon
          onClick={() =>
            handleMilestoneEdit?.(card?.eid || card?.tempEid || card?.tempId)
          }
        />
      </div>
      <div
        className='remove-milestone'
        style={{ paddingRight: '5px', cursor: 'pointer' }}
      >
        <CloseIconDark
          onClick={() =>
            handleRemove?.(
              card?.eid ||
                card?.tempEid ||
                card?.data?.tempEid ||
                card?.data?.tempId ||
                card.title + index
            )
          }
        />
      </div>
      <div>
        <PowerUpThunderIcon fill='#83BF6E' />
      </div>
    </div>
  );
};

export default Milestone;

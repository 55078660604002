import React, { FC } from 'react';
import { Box, Flex, IconButton, Spacer } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IFormInput } from '../task.types';

interface IProps {
  title: string;
}

const OpenStepHeader: FC<IProps> = ({ title }) => {
  const { setValue } = useFormContext<IFormInput>();

  return (
    <Flex align='center' gap='16px'>
      <Box h={8} w={4} minW={4} borderRadius={4} bg='#CABDFF' />
      <Box fontWeight='600' fontSize='16px' lineHeight='24px' color='#33383F'>
        {title}
      </Box>
      <Spacer />
      {/* <IconButton
        aria-label='action'
        borderRadius='full'
        bg='#BBBBBB'
        size='xs'
        alignSelf='center'
        icon={
          <FontAwesomeIcon size='lg' icon={faMinus as IconProp} color='white' />
        }
        onClick={() => setValue('currentStep', undefined)}
      /> */}
    </Flex>
  );
};

export default OpenStepHeader;

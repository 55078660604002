import React, { FC } from 'react';
import {
  CircularProgress,
  CircularProgressLabel,
  Text,
} from '@chakra-ui/react';

interface IProps {
  value?: number;
}

const TaskProgress: FC<IProps> = ({ value = 0 }) => {
  return (
    <CircularProgress
      value={value}
      size='42px'
      color={value > 79 ? 'green.400' : value > 39 ? 'orange.400' : 'red.400'}
    >
      <CircularProgressLabel>
        <Text as='b' fontSize='10px' color='#171725'>
          {value}%
        </Text>
      </CircularProgressLabel>
    </CircularProgress>
  );
};

export default TaskProgress;

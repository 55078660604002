import React, { FC, useMemo } from 'react';

import { ActionMenu } from '../../../../../ui-components';

import DarkEditIcon from '../../../../../assets/images/dark_edit.png';
import TrashIcon from '../../../../../assets/images/trash-red.svg';

interface IProps {
  isEdit?: boolean;
  clickedItem?: (name: string, value: string) => void;
}

const TaskMoreAction: FC<IProps> = ({ clickedItem, children, isEdit }) => {
  const menuData = useMemo(() => {
    if (isEdit) {
      return [
        {
          name: 'Change task phase',
          value: 'change-phase',
          icon: DarkEditIcon,
          textColor: '#1A1D1F',
        },
        {
          name: 'Delete task',
          value: 'delete',
          icon: TrashIcon,
          textColor: '#FF6A55',
        },
      ];
    }

    return [
      {
        name: 'Delete task',
        value: 'delete',
        icon: TrashIcon,
        textColor: '#FF6A55',
      },
    ];
  }, [isEdit]);

  return (
    <ActionMenu menuData={menuData} clickedItem={clickedItem}>
      {children}
    </ActionMenu>
  );
};

export default TaskMoreAction;

// @ts-ignore
import ShowMoreText from "react-show-more-text";
import { CSSProperties, FC, useState, useMemo } from "react";
import ImageModal from "./ImageModal";
import BackgroundImage from "./BackgroundImage";

interface CaptionProps {
  style?: CSSProperties;
  title?: string;
}

const ImageCaption: FC<CaptionProps> = ({ style, title }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const executeOnClick = (isExpanded: boolean) => {
    setIsExpanded(isExpanded);
  };

  return (
    <>
      {title && (
        <div
          className="image-bottom-gradient"
          style={{ ...style, overflowY: "auto" }}
        >
          <div className="image-gradient-child-container">
            <ShowMoreText
              /* Default options */
              lines={3}
              more={
                <span style={{ fontSize: "12px", opacity: "0.8" }}>
                  Show more
                </span>
              }
              less={
                <span style={{ fontSize: "12px", opacity: "0.8" }}>
                  Show less
                </span>
              }
              className="content-css"
              anchorClass="my-anchor-css-class"
              onClick={executeOnClick}
              expanded={isExpanded}
              truncatedEndingComponent={"... "}
            >
              <div>{title}</div>
            </ShowMoreText>
          </div>
        </div>
      )}
    </>
  );
};

interface SplitProps {
  image1?: string;
  image2?: string;
  onClick?: (image: string) => void;
  isHorizontal?: boolean;
}

const SplitImage: FC<SplitProps> = ({
  image1,
  image2,
  onClick,
  isHorizontal,
}) => {
  return (
    <div
      style={{
        flex: 1,
        zIndex: "100",
        display: "flex",
        flexDirection: isHorizontal ? "column" : "row",
        gap: 4,
      }}
    >
      <div style={{ flex: 1 }}>
        <BackgroundImage image={image1} onClick={onClick} />
      </div>
      <div style={{ flex: 1 }}>
        <BackgroundImage image={image2} onClick={onClick} />
      </div>
    </div>
  );
};

interface ImageProps {
  title?: string;
  style?: CSSProperties;
  content?: {
    attrs?: { layout?: string };
    image: string[];
  };
}

const Image: FC<ImageProps> = ({ content, title, style }) => {
  const [isImageModalOpened, setIsImageModalOpened] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>();
  // let image = content?.image[0];
  let imageLayout = content?.attrs?.layout as string;
  // if (image && image.match(/https:\/\/sop-uploads-staging.s3.amazonaws.com/g)) {
  //   image = image.replace(
  //     "https://sop-uploads-staging.s3.amazonaws.com",
  //     "https://d-sop-staging.imgix.net?auto=compress%2Cformat&q=25"
  //   );
  //   // image = `${image}?fit=clamp`;
  // }

  const onOpenImageModal = (image: string) => {
    setIsImageModalOpened(true);
    setSelectedImage(image);
  };

  const onCloseImageModal = () => {
    setIsImageModalOpened(false);
  };

  const openImage = (imageUrl: string) => {
    if (imageUrl) {
      onOpenImageModal(encodeURI(imageUrl));
    }
  };

  const images = useMemo(() => {
    if (content?.image === undefined || content?.image === null) {
      return [];
    }
    return Array.isArray(content?.image) ? content?.image : [content?.image];
  }, [content?.image]);

  return (
    <>
      {["full-image", "single-image"].includes(imageLayout) && (
        <div
          style={{
            position: "relative",
            height: "inherit",
            // overflow: "hidden",
          }}
        >
          <BackgroundImage image={images[0]} onClick={openImage} />

          <ImageCaption title={title} style={style} />
        </div>
      )}
      {imageLayout === "vertical-split" && (
        <div
          style={{
            position: "relative",
            height: "inherit",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <div style={{ flex: 1, zIndex: "100" }}>
            <BackgroundImage image={images[0]} onClick={openImage} />
          </div>
          <div style={{ flex: 1, zIndex: "100" }}>
            <BackgroundImage image={images[1]} onClick={openImage} />
          </div>
          <ImageCaption title={title} style={style} />
        </div>
      )}
      {imageLayout === "horizontal-split" && (
        <div
          style={{
            position: "relative",
            height: "inherit",
            overflow: "hidden",
            display: "flex",
            gap: 4,
          }}
        >
          <div style={{ flex: "1 1", zIndex: "100" }}>
            <BackgroundImage image={images[0]} onClick={openImage} />
          </div>
          <div style={{ flex: "1 1", zIndex: "100" }}>
            <BackgroundImage image={images[1]} onClick={openImage} />
          </div>
          <ImageCaption title={title} style={style} />
        </div>
      )}
      {imageLayout === "horizontal-split-second-column" && (
        <div
          style={{
            position: "relative",
            height: "inherit",
            overflow: "hidden",
            display: "flex",
            gap: 4,
          }}
        >
          <div style={{ flex: "1 1", zIndex: "100" }}>
            <BackgroundImage image={images[0]} onClick={openImage} />
          </div>
          <SplitImage
            image1={content?.image[1]}
            image2={content?.image[2]}
            onClick={openImage}
            isHorizontal
          />

          <ImageCaption title={title} style={style} />
        </div>
      )}

      {imageLayout === "horizontal-split-first-column" && (
        <div
          style={{
            position: "relative",
            height: "inherit",
            overflow: "hidden",
            display: "flex",
            gap: 4,
          }}
        >
          <SplitImage
            onClick={openImage}
            image1={images[0]}
            image2={images[1]}
            isHorizontal
          />
          <div style={{ flex: "1 1", zIndex: "100" }}>
            <BackgroundImage image={images[2]} onClick={openImage} />
          </div>
          <ImageCaption title={title} style={style} />
        </div>
      )}

      {imageLayout === "vertical-split-first-row" && (
        <div
          style={{
            position: "relative",
            height: "inherit",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <SplitImage
            image1={images[0]}
            image2={images[1]}
            onClick={openImage}
          />
          <div style={{ flex: 1, zIndex: "100" }}>
            <BackgroundImage image={images[2]} onClick={openImage} />
          </div>

          <ImageCaption title={title} style={style} />
        </div>
      )}

      {imageLayout === "vertical-split-second-row" && (
        <div
          style={{
            position: "relative",
            height: "inherit",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <div style={{ flex: 1, zIndex: "100" }}>
            <BackgroundImage image={images[0]} onClick={openImage} />
          </div>
          <SplitImage
            image1={images[1]}
            image2={images[2]}
            onClick={openImage}
          />

          <ImageCaption title={title} style={style} />
        </div>
      )}

      {imageLayout === "quadrant" && (
        <div
          style={{
            position: "relative",
            height: "inherit",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <SplitImage
            image1={images[0]}
            image2={images[1]}
            onClick={openImage}
          />
          <SplitImage
            image1={images[2]}
            image2={images[3]}
            onClick={openImage}
          />

          <ImageCaption title={title} style={style} />
        </div>
      )}

      {isImageModalOpened && (
        <ImageModal
          open={isImageModalOpened}
          onClose={onCloseImageModal}
          imageUrl={selectedImage}
        />
      )}
    </>
  );
};

export default Image;

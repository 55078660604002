// @ts-ignore
import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import {
  LAUNCHER_DASHBOARD,
  LAUNCHER_OPENING_TASKS,
  LAUNCHER_SETUP,
  LOCATIONS_PAGE,
} from '../appRoutes';
import { useUserEntity } from '../hooks';

export const TeamLocation: FC = () => {
  return <Redirect to={LOCATIONS_PAGE} />;
};

export const LauncherPage: FC = () => {
  return <Redirect to={LAUNCHER_DASHBOARD} />;
};

export const LauncherNotPublished: FC = () => {
  const haveLauncher = useUserEntity((entity) => !!entity?.launcher?.eid);
  return (
    <Redirect to={haveLauncher ? LAUNCHER_OPENING_TASKS : LAUNCHER_SETUP} />
  );
};

import { useHistory, useRouteMatch } from 'react-router-dom';
import * as H from 'history';
import { match } from 'react-router';

export const useCustomHistory = <T = H.LocationState>():
  | H.History<T>
  | undefined => {
  try {
    return useHistory<T>();
  } catch (e) {
    return undefined;
  }
};

export const useCustomRouteMatch = <
  Params extends { [K in keyof Params]?: string } = {}
>(): match<Params> | undefined => {
  try {
    return useRouteMatch<Params>();
  } catch (e) {
    return undefined;
  }
};

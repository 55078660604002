import { Box, Flex, Image, useToast } from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';
import { useUploadImage } from 'hooks';
import { ChapterFileUpload } from 'pages/Chapters/CreateChapterModal/common';
import DragDropComponent from 'pages/Chapters/CreateChapterModal/common/DragDropComponent';
import { FileDataEntity } from 'pages/Chapters/CreateChapterModal/create-chapter.graphql';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FolderIconUploadSkeleton from './FolderIconUploadSkeleton';
import { eventBus } from 'shared/eventEmit/eventBus';

type Progress = (progress: number) => void;

interface IProps {}

const FolderUploadContainer: FC<IProps> = () => {
  const { t } = useTranslation(['chapter']);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const [file, setFile] = useState<File>();
  const [fileEntity, setFileEntity] = useState<FileDataEntity>();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadImage = useUploadImage();

  useEffect(() => {
    eventBus.on('removeSelectedImageFile', removeSelectedImageFile);

    return () => {
      eventBus.off('removeSelectedImageFile', removeSelectedImageFile);
    };
  }, []);

  const removeSelectedImageFile = () => {
    setFile(undefined);
    setPreviewUrl(null);
  };

  const uploadFiles = async (fileData: File, uploadProgress?: Progress) => {
    if (!fileData) {
      return undefined;
    }

    return uploadImage(fileData, undefined, uploadProgress);
  };

  const onFileDrop = async (fileLists: File[]) => {
    const newFile = fileLists?.[0];
    setFile(newFile);
    setFileEntity({
      name: newFile?.name,
      fileSize: newFile?.size,
      type: newFile?.type,
      mimetype: newFile?.type,
      file: newFile,
    });
    const fileUrl = URL.createObjectURL(newFile);
    setPreviewUrl(fileUrl);
  };

  const selectedGalleryImageEmit = (value: string) => {
    eventBus.emit('setSelectedGalleryImage', value); // emit to FolderIconUpload compoent
  };

  const onCloseHandler = () => {
    eventBus.emit('onFolderIconUploadClose');
  };

  const uploadHandler = async () => {
    try {
      setIsUploading(true);
      const fileUrl = await uploadFiles(file!, (_value) => setProgress(_value));
      if (fileUrl) {
        toast({
          status: 'success',
          title: 'Icon uploaded successfully',
        });
        selectedGalleryImageEmit(fileUrl);
        onCloseHandler();
      }
      setIsUploading(false);
    } catch (e) {
      toast({
        status: 'error',
        title: 'Icon could not be uploaded',
      });
      // eslint-disable-next-line no-console
      console.log('Chapter file upload error', e);
      onCloseHandler();
      setIsUploading(false);
    }
  };
  return (
    <Flex direction='column' align='center' justify='center' w='full'>
      {!file && (
        <Box w='full'>
          {/* <DragDropComponent
            subTitle='Only accept images'
            accept={{
              'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
            }}
            onFileDrop={onFileDrop}
          /> */}
          <FolderIconUploadSkeleton onFileDrop={onFileDrop} />
        </Box>
      )}
      {file && previewUrl && (
        <Box w='full'>
          <Flex justify='center' align='center' mb={2}>
            <Image src={previewUrl} boxSize='250px' alt='Preview' />
          </Flex>
          <PrimaryButton
            title='Upload'
            variant='solid'
            colorScheme='blue'
            disabled={isUploading}
            isLoading={isUploading}
            onClick={uploadHandler}
          />
        </Box>
      )}
    </Flex>
  );
};

export default FolderUploadContainer;

import React, { ChangeEvent, useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useConfirm } from '../../../ui-components';
import MoveToComponent, { MoveToComponentRef } from './MoveToComponent';
import { MoveToCategoryFooter, MoveToCategoryHeader } from './MoveToCategory';
import { CategoryProvider } from './Context';
import { CategoryEntity } from 'sop-commons/src/client';
import { useHistory, useRouteMatch } from 'react-router-dom';

type IMoveToItem = (props: {
  category: string;
  data: CategoryEntity;
  onBackPress: () => void;
  onDeletePress?: (...args: any[]) => any | PromiseLike<any>;
  onMoveSuccess?: () => void;
  getChaptersLis?: () => void;
}) => void;

export const useMoveItemTo = (): IMoveToItem => {
  const { t } = useTranslation(['common', 'category']);
  const inputRef = useRef<MoveToComponentRef>(null);
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<typeof confirm>>();
  const apolloClient = useApolloClient();
  const history = useHistory();
  const { folderId, subFolderId } = useRouteMatch<{
    folderId?: string;
    subFolderId?: string;
  }>().params;

  const redirectHandler = (selectedFolderId: string) => {
    if (selectedFolderId && selectedFolderId !== folderId) {
      history.push(`/folders/${selectedFolderId}`);
    }
  };

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      inputRef.current?.onChange(event.target.value);
    },
    []
  );

  return useCallback(
    ({ category, data, onBackPress, onDeletePress, onMoveSuccess }) => {
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <CategoryProvider
                category={category}
                data={data}
                onMoveSuccess={onMoveSuccess}
              >
                {children}
              </CategoryProvider>
            </ApolloProvider>
          );
        },
        title: (
          <MoveToCategoryHeader
            onBackClick={() => {
              confirmRef.current?.destroy?.();
              setTimeout(onBackPress, 100);
            }}
            onClose={() => confirmRef.current?.destroy?.()}
            onChange={handleSearchChange}
          />
        ),
        content: (
          <MoveToComponent
            ref={inputRef}
            data={data}
            category={category}
            onDeletePress={onDeletePress}
            close={() => confirmRef.current?.destroy?.()}
          />
        ),
        isCentered: true,
        // scrollBehavior: 'inside',
        contentProps: {
          paddingTop: '12px',
          paddingBottom: '12px',
          minW: '600px',
        },
        bodyProps: {
          paddingX: 0,
        },
        footer: (
          <MoveToCategoryFooter
            onClose={() => {
              confirmRef.current?.destroy?.();
              onMoveSuccess?.();
            }}
            redirectHandler={redirectHandler}
          />
        ),
      });
    },
    [handleSearchChange]
  );
};

import styled from '@emotion/styled';

export const QuestionWiseTableWrapper = styled.div`
  flex: 1;
  //max-width: calc(80% - 32px);

  table {
    //display: block;
    table-layout: fixed;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    /* border-collapse: collapse; */
    /* overflow: hidden; */
    border-style: hidden;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #ddd;
    margin-top: 1rem;
  }

  th,
  tr,
  td {
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 15px;
    text-align: start;
  }

  th {
    text-align: start;
  }

  td {
    vertical-align: top;
  }
`;

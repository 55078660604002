import { LauncherContent, LauncherTask } from './fragment.graphql';

export class ValidationError extends Error {
  data: object | undefined;
  constructor(message: string, data?: object) {
    super(message);
    this.data = data;
  }
}

export interface ITitleEdit {
  // Helpers
  /**
   * set value `-2` for adding new phase title and positive index to edit phase title
   */
  sectionTitle?: number; // set value -2 to add new title
}

export interface BaseLTask {
  title: string;
  description?: string;
}

interface ILauncherTask extends Omit<LauncherTask, 'eid'> {
  eid?: string;

  // TODO this variable is used to check the data is local
  isLocal?: boolean;
}

interface ILauncherContent extends Omit<LauncherContent, 'tasks'> {
  tasks: ILauncherTask[];

  // TODO this variable is used to check the data is local
  isLocal?: boolean;
}

export interface BaseFormInput {
  eid?: string;
  published?: boolean;
  contents: ILauncherContent[];

  // TODO this is used to check the config published id required?
  publishRequired?: boolean;
}

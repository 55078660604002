export function editorDataValidator(content: any): boolean {
  let flag = true;
  if ('content' in content) {
    content?.content?.map((con: any) => {
      if (flag) {
        if (con?.type === 'paragraph' || con?.type === 'heading') {
          con?.content?.map((_c: any) => {
            if (_c?.text) {
              flag = false;
            }
          });
        } else if (
          con?.type === 'bulletList' ||
          con?.type === 'orderedList' ||
          con?.type === 'taskList'
        ) {
          con?.content?.map((_c: any) => {
            _c?.content?.map((_c1: any) => {
              _c1?.content?.map((_c2: any) => {
                if (_c2?.text) {
                  flag = false;
                }
              });
            });
          });
        } else if (con?.type === 'blockquote') {
          con?.content?.map((_c: any) => {
            _c?.content?.map((_c1: any) => {
              if (_c1?.text) {
                flag = false;
              }
            });
          });
        }
      }
    });
  }

  return flag;
}

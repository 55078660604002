import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { UserSignature } from '../../../../../types';
import { signatureConfigs } from '../../../../../configs';

interface IProps extends UserSignature {}

const AcknowledgeCell: FC<IProps> = ({ font, text }) => {
  const config = useMemo(() => {
    return signatureConfigs.find((value) => value.fontFamily === font);
  }, [font]);

  if (!text) {
    return <div>-</div>;
  }

  return (
    <Flex gap={3}>
      <Box userSelect='none' {...config}>
        {text}
      </Box>
    </Flex>
  );
};

export default AcknowledgeCell;

import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AnswerInputCard from './AnswerInputCard';
import PrimaryButton from '../../../atoms/PrimaryButton';
import { CardFormInput } from '../card-editor.types';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';

interface IProps {
  isReadOnly?: boolean;
  handleClickCorrect: (optionId: string) => void;
}

const QuizOptionList: FC<IProps> = ({ isReadOnly, handleClickCorrect }) => {
  const { t } = useTranslation('card');
  const { control, getValues, setValue } = useFormContext<CardFormInput>();
  const { fields, remove, append } = useFieldArray<CardFormInput, 'choices'>({
    control,
    name: 'choices',
  });

  const correctAnswers: string[] = useWatch<CardFormInput>({
    name: 'correctAnswer',
  });

  const addExtraOption = () => {
    append({
      choiceId: uuidv4(),
      text: '',
      inputId: `answerOption${uuidv4()}`,
    });
  };

  const handleOptionRemove = (optionId: string, index: number) => {
    const newAnswer = getValues('correctAnswer') || [];
    setValue(
      'correctAnswer',
      newAnswer.filter((id) => id !== optionId)
    );
    remove(index);
  };

  return (
    <div className='answersContainer'>
      {fields?.map((field, index) => {
        return (
          <AnswerInputCard
            key={field.id}
            inputId={field.inputId}
            optionId={field.choiceId}
            isReadOnly={isReadOnly}
            handleRemove={handleOptionRemove}
            handleClickCorrect={handleClickCorrect}
            isCorrect={correctAnswers?.includes(field.choiceId)}
            currentIndex={index}
            answerIndex={index + 1}
          />
        );
      })}

      <Controller
        name={'correctAnswer'}
        control={control}
        rules={{
          required: t('validation.correct_option'),
          validate: () => {
            if ((getValues('choices') || []).length < 2) {
              return t('validation.option_more');
            }
          },
        }}
        render={({ fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      {!isReadOnly && (
        <PrimaryButton
          type='button'
          size='lg'
          variant='solid'
          colorScheme='blue'
          title={t('add_option')}
          onClick={addExtraOption}
          className='addOptionsBtn'
        />
      )}
    </div>
  );
};

export default QuizOptionList;

import React, { FC, useContext } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PreviewContext } from './context';
import PreviewHeader from '../../../../sub-components/forms/component/PreviewHeader';
import { ViewTypeSelect } from '../../../Chapters/ChapterPreview';

interface IProps {}

const Header: FC<IProps> = () => {
  const { t } = useTranslation(['common']);
  const { viewMode, onModeChange } = useContext(PreviewContext);

  return (
    <Flex align='center'>
      <Box flex={1}>
        <PreviewHeader title={t('common:preview')} />
      </Box>
      <Flex flex={1} justify='center'>
        <ViewTypeSelect viewMode={viewMode} onModeChange={onModeChange} />
      </Flex>
      <Flex flex={1} justify='flex-end' gap='8px'>
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
    </Flex>
  );
};

export default Header;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import { match } from 'ts-pattern';

import { GroupMemberEntity } from '../../../../../types';
import { searchRegExp } from '../../../../../utils';
import { SelectOption } from '../../../../../atoms';

import { getGroupMembers } from '../useChatGroupData';

/**
 * TO IMPLEMENT INFINITE PAGINATION SCROLL FUNCTIONALITY, REFER TO FILE : infiniteScrollChat.txt
 */

export const useMemberData = (groupId: string) => {
  const [members, setMembers] = useState<GroupMemberEntity[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [memberLoading, setMemberLoading] = useState<boolean>(false);
  const [controller, setController] =
    useState<CometChat.GroupMembersRequest | null>(null);

  const fetchAllMembers = useCallback(
    (controller: CometChat.GroupMembersRequest) => {
      setMemberLoading(true);
      controller
        ?.fetchNext()
        ?.then((users) => {
          if (users?.length > 0) {
            setMembers((prevMembers) => [
              ...prevMembers,
              ...(users as unknown as GroupMemberEntity[]),
            ]);
            fetchAllMembers(controller);
          } else {
            setMemberLoading(false);
          }
        })
        .catch((error) => {
          console.error('Failed to fetch members:', error);
          setMemberLoading(false);
          setHasError(true);
        });
    },
    []
  );

  const initFetchController = useCallback(() => {
    if (groupId) {
      const newController = new CometChat.GroupMembersRequestBuilder(groupId)
        .setLimit(50)
        .setScopes([
          CometChat.GROUP_MEMBER_SCOPE.ADMIN,
          CometChat.GROUP_MEMBER_SCOPE.MODERATOR,
          CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT,
        ])
        .build();
      setController(newController);
      fetchAllMembers(newController);
    }
  }, [groupId]);

  useEffect(() => {
    initFetchController();
  }, [groupId, fetchAllMembers]);

  const fetchChatGroup = () => {
    initFetchController();
  };

  return {
    members,
    setMembers,
    memberLoading,
    reFetchData: fetchChatGroup,
  };
};

export const useMemberFilter = (
  members: GroupMemberEntity[],
  filterBy?: SelectOption | null,
  searchQuery?: string
): GroupMemberEntity[] => {
  return useMemo(() => {
    const matchResult = match(filterBy?.type)
      .with('location', () =>
        members.filter((m) =>
          m.metadata?.parents?.some((l) => l === filterBy?.label)
        )
      )
      .with('job', () =>
        members.filter((m) => m.metadata?.role === filterBy?.value)
      )
      .with('role', () =>
        members.filter((m) => m.metadata?.authRole === filterBy?.value)
      )
      .otherwise(() => members);

    if (searchQuery) {
      const reg = searchRegExp(searchQuery, 'gi');

      return matchResult.filter((value) => {
        return (
          value.name?.match(reg) ||
          value.metadata?.authRole?.match(reg) ||
          value.metadata?.role?.match(reg) ||
          value?.metadata?.parents?.some((loc) => loc?.match(reg))
        );
      });
    }

    return matchResult;
  }, [members, filterBy?.value, searchQuery]);
};

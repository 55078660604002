import React, { FC, ReactElement } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical,
  faCopy,
  faEye,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ReactComponent as DeleteIcon } from '../../../assets/images/trash-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit-black.svg';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

const Item: FC<{
  title: string;
  icon: ReactElement;
  onClick: () => void;
  color?: string;
}> = ({ title, icon, onClick, color = '#6F767E' }) => {
  return (
    <Flex
      gap='9px'
      borderRadius='5px'
      my={2}
      onClick={onClick}
      cursor='pointer'
    >
      {React.cloneElement(icon, {
        style: {
          width: 24,
          height: 24,
        },
      })}
      <Box
        as='span'
        fontSize='15px'
        fontWeight='600'
        lineHeight='24px'
        color={color}
      >
        {title}
      </Box>
    </Flex>
  );
};

interface IProps {
  onCopyClick: () => void;
  onDeleteClick: () => void;
  onEditClick: () => void;
  onVisibilityClick: () => void;
}

const SidebarAction: FC<IProps> = ({
  onCopyClick,
  onEditClick,
  onDeleteClick,
  onVisibilityClick,
}) => {
  const { t } = useTranslation('common');
  const { isOpen, onOpen, onClose } = useDisclosure();

  function onClick(callback: Function) {
    callback?.call(null);
    onClose();
  }

  return (
    <Box
      className='sidebar-action'
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        deployEvent(AmplitudeEventNames.OPEN_EDIT_POPOVER_FOLDER);
      }}
      visibility={isOpen ? 'visible' : 'hidden'}
      mr='-8px'
    >
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        // placement='right'
        strategy='fixed'
        placement='right-start'
        offset={[-10, 8]}
      >
        {/* @ts-ignore */}
        <PopoverTrigger>
          <Box ml={1} px={1} cursor='pointer'>
            <FontAwesomeIcon
              icon={faEllipsisVertical as IconProp}
              fontSize='18px'
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          w={'auto'}
          minW='240px'
          pl={6}
          pr={8}
          py={3}
          borderRadius='12px'
          shadow='md'
        >
          <PopoverArrow />
          <Item
            title={t('edit')}
            icon={<EditIcon />}
            color='#1A1D1F'
            onClick={onClick.bind(null, onEditClick)}
          />
          <Item
            title='Visibility'
            icon={<FontAwesomeIcon icon={faEye as IconProp} color='#1A1D1F' />}
            color='#1A1D1F'
            onClick={onClick.bind(null, onVisibilityClick)}
          />
          <Item
            title='Copy link'
            icon={<FontAwesomeIcon icon={faCopy as IconProp} color='#1A1D1F' />}
            color='#1A1D1F'
            onClick={onClick.bind(null, onCopyClick)}
          />
          <Item
            title={t('delete')}
            icon={<DeleteIcon />}
            color='#FF6A55'
            onClick={onClick.bind(null, onDeleteClick)}
          />
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default SidebarAction;

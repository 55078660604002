import { gql } from '@apollo/client';

export const UPDATE_SIGNS = gql`
  mutation UpdateUserSigns($input: UserUpdateInput) {
    updateUser(input: $input) {
      signatures {
        font
        text
      }
    }
  }
`;

export const GET_USER_SIGNS = gql`
  query Signatures($eid: String!) {
    userById(eid: $eid) {
      signatures {
        font
        text
      }
    }
  }
`;

import React, { useEffect, useMemo, useState } from 'react';
import { forwardRef, InputProps } from '@chakra-ui/react';
import { mergeRefs } from '@chakra-ui/react-utils';

import InputButton, { InputButtonProps } from './InputButton';
import { FormInput } from '../../../../atoms';

interface IProps extends InputProps {
  buttonProps?: InputButtonProps;
}

const CustomInput = forwardRef<IProps, 'div'>(
  ({ buttonProps, ...props }, ref) => {
    const inputRef = React.useRef<HTMLDivElement>(null);

    const [width, setWidth] = useState<string>();

    const id = inputRef.current?.id;

    const resize = useMemo(() => {
      return new ResizeObserver(function (entries) {
        let rect = entries[0]?.borderBoxSize?.[0];

        if (rect?.inlineSize) {
          setWidth(Math.round(rect.inlineSize + 4) + 'px');
        }
      });
    }, []);

    useEffect(() => {
      const elm = document.querySelector(
        `#${inputRef.current?.id} ~ .chakra-input__right-element`
      );

      if (elm) {
        resize.observe(elm);
      }

      return () => {
        if (elm) {
          resize.unobserve(elm);
        }
      };
    }, [id]);

    return (
      <FormInput
        {...props}
        ref={mergeRefs(inputRef, ref)}
        rightElementProps={{
          width: 'auto',
          pr: '6px',
          zIndex: '0 !important',
        }}
        paddingRight={width}
        rightIcon={<InputButton {...buttonProps} />}
      />
    );
  }
);

export default CustomInput;

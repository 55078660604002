import React, { useCallback } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useConfirm } from '../../../../ui-components';
import Header from './Header';
import PreviewContainer from './PreviewContainer';
import { PreviewProvider } from './context';

type UseTrainingPreview = (props: { trainingContents: any[] }) => void;

export const useTrainingPreview = (): UseTrainingPreview => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({ trainingContents }) => {
      confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <PreviewProvider data={trainingContents}>
                {children}
              </PreviewProvider>
            </ApolloProvider>
          );
        },
        title: <Header />,
        content: <PreviewContainer />,
        isCentered: false,
        contentProps: {
          padding: '24px',
          borderRadius: '20px',
          minW: '940px',
          bg: '#F4F4F4',
        },
        footer: <></>,
      });
    },
    [confirm]
  );
};

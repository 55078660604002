import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../../../atoms/PrimaryButton';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {}

const AddChapterButton: FC<IProps> = () => {
  const { t } = useTranslation(['chapter']);
  const [width, setWidth] = useState<number>();

  const resize = useMemo(() => {
    return new ResizeObserver(function (entries) {
      let rect = entries[0].contentRect;

      // current width & height
      let width = rect.width;

      setWidth(width + 4);
    });
  }, []);

  useEffect(() => {
    const elm = document.querySelector('#training-knowledge-base-parent-box');
    if (elm) {
      resize.observe(elm);
    }

    return () => {
      if (elm) {
        resize.unobserve(elm);
      }
    };
  }, []);

  return (
    <Box
      position='fixed'
      bottom='16px'
      right={{
        base: '64px',
        md: '43px',
        lg: '45px',
        xl: '64px',
      }}
    >
      <Tooltip
        label={t('chapter:createChapterBtnHint')}
        hasArrow
        p='8px 12px'
        borderRadius='8px'
        shouldWrapChildren
      >
        <PrimaryButton
          title={t('chapter:createNewChapter')}
          variant='solid'
          colorScheme='blue'
          size='lg'
          width={width || 'full'}
          onClick={() => {
            deployEvent(AmplitudeEventNames.TRAINING_CREATE_NEW_CHAPTER);
            window.open('/create-chapter?training-path-flow', '_blank');
          }}
        />
      </Tooltip>
    </Box>
  );
};

export default AddChapterButton;

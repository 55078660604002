import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import { IVisibility } from 'pages/Chapters/CreateChapterModal/UploadFile/UploadFile';
import { ICompareVisibilityType } from 'pages/Chapters/CreateEditSubFolder/components/helper/visibilityHelper';
import { ChapterModal } from 'pages/Chapters/modal';
import { StateCreator } from 'zustand';

export type ISelectedLocationData = {
  eid: string;
  label: string;
  name: string;
  value: string;
};

export type ISelectedRoleData = {
  color: string;
  eid: string;
  label: string;
  name: string;
  status: string;
  value: string;
};

export type ISelectedMemberData = {
  eid: string;
  label: string;
  name: string;
  value: string;
};

export interface ISelectedData {
  locations: ISelectedLocationData[];
  members: ISelectedMemberData[];
  roles: ISelectedRoleData[];
}

export type VisibilityScope = 'folder' | 'subFolder' | 'chapter';

export type OperationType = 'add' | 'edit' | 'view';

export interface IFolderHierarchyEntity {
  status?: ICompareVisibilityType;
  details: CustomCategoryEntity;
  visibilityData: IVisibility;
}

export interface IChapterHierarchyEntity {
  status?: ICompareVisibilityType;
  details: ChapterModal;
  visibilityData: IVisibility;
}

export interface IHierarchy {
  parentFolder?: IFolderHierarchyEntity;
  subFolder?: IFolderHierarchyEntity;
  chapter?: IChapterHierarchyEntity;
}

export interface VisibilityState {
  customSectionExpand: boolean;
  hierarchyData: IHierarchy | undefined;
  operationType: OperationType | undefined;
  /** Hierarchy data for any folder/subfolder coming from search which is otherwise not visible in sidebar */
  // restrictedHierarchyData: IHierarchy | undefined;
  sameAsParent: boolean;
  selectedData: ISelectedData;
  visibilityScope: VisibilityScope | undefined;
  visibilitySelection: IVisibility | undefined;
  reset: () => void;
  updateCustomSectionExpand: (value: boolean) => void;
  updateHierarchyData: (data: IHierarchy | undefined) => void;
  updateOperationType: (type: OperationType) => void;
  // updateRestrictedHierarchyData: (data: IHierarchy | undefined) => void;
  updateSameAsParent: (value: boolean) => void;
  updateSelectedData: (data: ISelectedData) => void;
  updateVisibilityScope: (scope: VisibilityScope) => void;
  updateVisibilitySelection: (data: IVisibility | undefined) => void;
}

export const visibilitySlice: StateCreator<VisibilityState> = (set) => ({
  customSectionExpand: false,
  hierarchyData: undefined,
  operationType: 'add',
  // restrictedHierarchyData: undefined,
  selectedData: {
    locations: [],
    members: [],
    roles: [],
  },
  sameAsParent: false,
  visibilityScope: undefined,
  visibilitySelection: undefined,
  reset: () =>
    set(() => ({
      customSectionExpand: false,
      hierarchyData: undefined,
      operationType: undefined,
      sameAsParent: false,
      selectedData: {
        locations: [],
        members: [],
        roles: [],
      },
      visibilityScope: undefined,
      visibilitySelection: undefined,
    })),
  updateCustomSectionExpand: (value: boolean) =>
    set(() => ({ customSectionExpand: value })),
  updateHierarchyData: (data: IHierarchy | undefined) =>
    set(() => ({ hierarchyData: data })),
  // updateRestrictedHierarchyData: (data: IHierarchy | undefined) =>
  //   set(() => ({ restrictedHierarchyData: data })),
  updateOperationType: (type: OperationType) =>
    set(() => ({ operationType: type })),
  updateSameAsParent: (value: boolean) => set(() => ({ sameAsParent: value })),
  updateSelectedData: (data: ISelectedData) =>
    set(() => ({ selectedData: data })),
  updateVisibilityScope: (scope: VisibilityScope) =>
    set(() => ({ visibilityScope: scope })),
  updateVisibilitySelection: (data: IVisibility | undefined) =>
    set(() => ({ visibilitySelection: data })),
});

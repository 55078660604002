import React, { FC } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { SelectOption } from '../common';
import { useAssigneesInfo } from './AssigneeHint';

interface IProps {
  members?: SelectOption[];
}

const AssigneeStepperDescription: FC<IProps> = ({ members = [] }) => {
  const { t } = useTranslation('task');

  const data = useAssigneesInfo(members);

  return (
    <>
      {data?.map((value) => {
        return (
          <Tooltip
            key={value?.i18nKey}
            label={<Trans t={t} {...value} />}
            hasArrow
            borderRadius='6px'
            padding='4px 8px'
          >
            <Box gap={1} isTruncated>
              <Trans t={t} {...value} />
            </Box>
          </Tooltip>
        );
      })}
    </>
  );
};

export default AssigneeStepperDescription;

import React, { FC } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

interface IProps extends Pick<ButtonProps, 'onClick'> {
  isSelected?: boolean;
}

const ButtonSelect: FC<IProps> = ({ children, isSelected, onClick }) => {
  return (
    <Button
      size='sm'
      fontSize='12px'
      fontWeight='400'
      color={!isSelected ? '#84818A' : undefined}
      colorScheme={isSelected ? 'blackAlpha' : undefined}
      onClick={isSelected ? undefined : onClick}
    >
      {children}
    </Button>
  );
};

export default ButtonSelect;

export const layouts = {
  MIXED_TOP_IMAGE_TEXT: "mixed-top-image-text",
  MIXED_BOTTOM_IMAGE_TEXT: "mixed-bottom-image-text",
  MIXED_VERTICAL_SPLIT_IMAGE_TEXT: "mixed-vertical-split-image-text",
  MIXED_HORIZONTAL_SPLIT_IMAGE_TEXT: "mixed-horizontal-split-image-text",
  MIXED_HORIZONTAL_SPLIT_SECOND_COLUMN_IMAGE_TEXT:
    "mixed-horizontal-split-second-column-image-text",
  MIXED_VERTICAL_SPLIT_SECOND_ROW_IMAGE_TEXT:
    "mixed-vertical-split-second-row-image-text",
  MIXED_QUADRANT_IMAGE_TEXT: "mixed-quadrant-image-text",
  IMAGE_SINGLE: "single-image",
  IMAGE_HORIZONTAL_SPLIT: "horizontal-split",
  IMAGE_VERTICAL_SPLIT: "vertical-split",
  IMAGE_HORIZONTAL_SPLIT_SECOND_COLUMN: "horizontal-split-second-column",
  IMAGE_HORIZONTAL_SPLIT_FIRST_COLUMN: "horizontal-split-first-column",
  IMAGE_VERTICAL_SPLIT_FIRST_ROW: "vertical-split-first-row",
  IMAGE_VERTICAL_SPLIT_SECOND_ROW: "vertical-split-second-row",
  IMAGE_QUADRANT: "quadrant",
};

export enum MixedLayout {
  MIXED_TOP_IMAGE_TEXT = "mixed-top-image-text",
  MIXED_BOTTOM_IMAGE_TEXT = "mixed-bottom-image-text",
  MIXED_VERTICAL_SPLIT_IMAGE_TEXT = "mixed-vertical-split-image-text",
  MIXED_HORIZONTAL_SPLIT_IMAGE_TEXT = "mixed-horizontal-split-image-text",
  MIXED_HORIZONTAL_SPLIT_SECOND_COLUMN_IMAGE_TEXT = "mixed-horizontal-split-second-column-image-text",
  MIXED_VERTICAL_SPLIT_SECOND_ROW_IMAGE_TEXT = "mixed-vertical-split-second-row-image-text",
  MIXED_QUADRANT_IMAGE_TEXT = "mixed-quadrant-image-text",
  IMAGE_SINGLE = "single-image",
  IMAGE_HORIZONTAL_SPLIT = "horizontal-split",
  IMAGE_VERTICAL_SPLIT = "vertical-split",
  IMAGE_HORIZONTAL_SPLIT_SECOND_COLUMN = "horizontal-split-second-column",
  IMAGE_HORIZONTAL_SPLIT_FIRST_COLUMN = "horizontal-split-first-column",
  IMAGE_VERTICAL_SPLIT_FIRST_ROW = "vertical-split-first-row",
  IMAGE_VERTICAL_SPLIT_SECOND_ROW = "vertical-split-second-row",
  IMAGE_QUADRANT = "quadrant",
}

export enum TextLayout {
  SIMPLE_TEXT = "simple-text",
  RICH_TEXT = "rich-text",
}

export enum OtherLayout {
  FORM = "form",
  ACTION = "action",
  CUSTOM_FORM = "formCard",
}

export enum QuizLayout {
  SINGLE_CHOICE = "single-choice",
  MULTI_CHOICE = "multi-choice",
}

export type AllLayout = MixedLayout | TextLayout | OtherLayout | QuizLayout;

// eslint-disable-next-line no-unused-vars
export const LayoutImageCount: { [key in MixedLayout]: number } = {
  [MixedLayout.MIXED_TOP_IMAGE_TEXT]: 1,
  [MixedLayout.MIXED_BOTTOM_IMAGE_TEXT]: 1,
  [MixedLayout.MIXED_VERTICAL_SPLIT_IMAGE_TEXT]: 2,
  [MixedLayout.MIXED_HORIZONTAL_SPLIT_IMAGE_TEXT]: 2,
  [MixedLayout.MIXED_HORIZONTAL_SPLIT_SECOND_COLUMN_IMAGE_TEXT]: 3,
  [MixedLayout.MIXED_VERTICAL_SPLIT_SECOND_ROW_IMAGE_TEXT]: 3,
  [MixedLayout.MIXED_QUADRANT_IMAGE_TEXT]: 4,
  [MixedLayout.IMAGE_SINGLE]: 1,
  [MixedLayout.IMAGE_HORIZONTAL_SPLIT]: 2,
  [MixedLayout.IMAGE_VERTICAL_SPLIT]: 2,
  [MixedLayout.IMAGE_HORIZONTAL_SPLIT_SECOND_COLUMN]: 3,
  [MixedLayout.IMAGE_HORIZONTAL_SPLIT_FIRST_COLUMN]: 3,
  [MixedLayout.IMAGE_VERTICAL_SPLIT_FIRST_ROW]: 3,
  [MixedLayout.IMAGE_VERTICAL_SPLIT_SECOND_ROW]: 3,
  [MixedLayout.IMAGE_QUADRANT]: 4,
};

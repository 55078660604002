import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertDialogCloseButton,
  Box,
  Flex,
  Spacer,
  Tooltip,
  useModalContext,
} from '@chakra-ui/react';
import {
  faArrowLeft,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  onViewDetail?: () => void;
}

const Header: FC<IProps> = ({ onViewDetail }) => {
  const { t } = useTranslation(['task']);
  const { onClose } = useModalContext();

  const onViewClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onViewDetail?.();
  };

  return (
    <Flex align='center' gap='12px' pos='relative'>
      <FontAwesomeIcon
        style={{
          cursor: 'pointer',
        }}
        icon={faArrowLeft as IconProp}
        onClick={onClose}
      />

      <Box fontSize='18px' fontWeight='600'>
        {t('task:taskDetails')}
      </Box>

      <Spacer />

      <Tooltip
        label={t('task:openDetails')}
        hasArrow
        padding='6px 10px'
        borderRadius='4px'
      >
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
          bg='gray.100'
          onClick={onViewClick}
        >
          <FontAwesomeIcon
            size='lg'
            icon={faArrowUpRightFromSquare as IconProp}
            color='#6F767E'
          />
        </AlertDialogCloseButton>
      </Tooltip>

      <AlertDialogCloseButton
        ml='4px'
        pos='relative'
        borderRadius='full'
        top='0'
        right='0'
        bg='gray.100'
      />
    </Flex>
  );
};

export default Header;

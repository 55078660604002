import styled from '@emotion/styled';

export const AssignPublishFormWrapper = styled.div`
    .label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: .5rem;
        display: inline-block;
        margin-bottom: 0.5rem;
    }

    .repeat-training-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;
import React, { FC, useContext } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import MobileNotch from '../../../../Chapters/ChapterPreview/MobileNotch';
import MobileHeader from '../../../../Chapters/ChapterPreview/MobileHeader';

import { CommonWrapper } from './common';
import { PreviewContext } from '../context';
import { CommonWrapperProps } from './common/CommonWrapper';

interface IProps extends CommonWrapperProps {
  title?: string;
}

const TrainingMobile: FC<IProps> = ({
  title,
  isPreview,
  value,
  isNotDesktop,
}) => {
  const { selectedIndex, trainingContents, onThumbnailClick } =
    useContext(PreviewContext);

  const totalCards = trainingContents?.length || 0;

  const decrement = () => {
    const next = selectedIndex - 1;
    onThumbnailClick?.(next);
    document.getElementById(`training-preview-thumb-${next}`)?.scrollIntoView({
      inline: 'center',
      block: 'center',
    });
  };

  const increment = () => {
    const next = selectedIndex + 1;
    onThumbnailClick?.(next);
    document.getElementById(`training-preview-thumb-${next}`)?.scrollIntoView({
      inline: 'center',
      block: 'center',
    });
  };

  return (
    <Box
      position='relative'
      borderRadius='44px'
      p='18px'
      bg='#151515'
      width='320px'
      maxW='400px'
      h='full'
      style={{
        aspectRatio: '9/19.5',
      }}
    >
      <>
        <IconButton
          display={selectedIndex === 0 ? 'none' : 'inline-flex'}
          position='absolute'
          left='0'
          transform='auto'
          translateX='calc(-100% - 20px)'
          translateY='calc(50vh - 160px)'
          borderRadius='full'
          boxShadow='md'
          aria-label={'back'}
          icon={<ChevronLeftIcon boxSize={7} />}
          onClick={decrement}
        />
        <IconButton
          display={selectedIndex + 1 === totalCards ? 'none' : 'inline-flex'}
          position='absolute'
          right='0'
          transform='auto'
          translateX='calc(100% + 20px)'
          translateY='calc(50vh - 160px)'
          borderRadius='full'
          boxShadow='md'
          aria-label={'next'}
          icon={<ChevronRightIcon boxSize={7} />}
          onClick={increment}
        />
      </>

      <Box
        pos='relative'
        borderRadius='24px'
        bg='#ffffff'
        overflow='hidden'
        h='full'
      >
        <MobileNotch />
        <Flex flexDir='column' overflow='auto' h='full'>
          <MobileHeader
            title={title}
            currentIndex={selectedIndex + 1}
            totalCards={totalCards}
          />
          <Box flex={1} p='16px' pt='10px' mb='36px'>
            <CommonWrapper
              value={value}
              isPreview={isPreview}
              isNotDesktop={isNotDesktop}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default TrainingMobile;

import React, { FC } from 'react';
import DashboardContainer from '../../sub-components/DashboardContainer';
import { AssigneePerformance } from '../../sub-components/tasks/performance';

interface IProps {}

const AssigneeTaskPerformance: FC<IProps> = () => {
  return (
    <DashboardContainer>
      <AssigneePerformance />
    </DashboardContainer>
  );
};

export default AssigneeTaskPerformance;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ContentItem from '../SearchModal/component/ContentItem';
import { UserDetailResponse } from './details.graphql';

import userIcon from '../../assets/images/userIcon.svg';
import forwardIcon from '../../assets/images/chevronForward.svg';
import phoneIcon from '../../assets/images/phoneIcon.svg';

interface IProps {
  data?: UserDetailResponse['userById'];
  onViewMember?: () => void;
}

const LocationDetails: FC<IProps> = ({ data, onViewMember }) => {
  const { t } = useTranslation(['header']);

  if (data?.type !== 'branch') {
    return null;
  }

  return (
    <>
      <ContentItem
        type={data.type}
        leftIcon={phoneIcon}
        title={data?.phone || '- -'}
      />
      <ContentItem
        type={data.type}
        leftIcon={userIcon}
        rightIcon={forwardIcon}
        title={t('header:view_member')}
        onClick={onViewMember}
      />
    </>
  );
};

export default LocationDetails;

import { useState, useEffect, FC, useMemo } from 'react';

import {
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Flex,
  Text,
  Box,
  useToast,
} from '@chakra-ui/react'; // import css
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  useReactiveVar,
  useMutation,
  gql,
  useQuery,
  useLazyQuery,
} from '@apollo/client';
import { roleObj } from '../DashboardMenu';
import PrimaryButton from '../../atoms/PrimaryButton';
import Dropdown, { SelectOption, Option } from '../../atoms/Dropdown';
import FormInput from '../../atoms/FormInput';
import SendInviteModal from '../SendInviteModal';

import AuthorizationLevel from '../../sub-components/AuthorizationLevel';

// import css
import './InviteUserModal.scss';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { loginUserBranchObj } from 'sub-components/Header';
import { WORKER } from 'utils/userRoles';
import { HQComp } from './LocationOption';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import AddRole from 'sub-components/SettingDataCenter/RolesList/AddRole';
import { GET_USER } from 'pages/Login/login.graphql';
import { AuthRole } from '../../authorization';
import { useTranslation } from 'react-i18next';
import {
  inviteMinWidth,
  inviteWidth,
  modalMinWidth,
  modalWidth,
} from './invite-user.styles';

const GET_BRANCH = gql`
  query UI_IUM_Branch {
    branch {
      descendantBranches {
        name
        eid
        type
        role
        authRole
        email
        createdAt
        status
        isRoot
      }
      root {
        name
        eid
      }
    }
  }
`;

const INVITE_USER = gql`
  mutation InviteUser($input: InviteUserAppInput) {
    inviteUser(input: $input) {
      inviteUrl
      name
      code
      eid
    }
  }
`;

interface Props {
  open: boolean;
  memberId?: string;
  onClose: () => void;
  authCodeUrl?: string;
  pendingInviteeName?: string;
  isAlreadyInvited?: boolean;
  pendingUserRefetch?: () => void;
  refetchLocations?: () => void;
  disableAuthChange?: boolean;
  defaultAuthRole?: AuthRole;
}

const InviteUserModal: FC<Props> = ({
  open,
  onClose,
  authCodeUrl,
  pendingInviteeName,
  isAlreadyInvited,
  pendingUserRefetch,
  memberId,
  refetchLocations,
  disableAuthChange,
  defaultAuthRole = WORKER,
}) => {
  const { t } = useTranslation(['common', 'invite', 'role']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });
  const [isLoading, setIsLoading] = useState(false);
  const roleColorByName = useReactiveVar<any>(roleObj);
  const branchList = useReactiveVar(loginUserBranchObj);
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState<any>('');
  const [isInvited, setIsInvited] = useState(isAlreadyInvited);
  const [inviteUrl, setInviteUrl] = useState<string>(authCodeUrl as string);
  const [inviteeName, setInviteeName] = useState(pendingInviteeName);
  const [invitedUserId, setInvitedUserId] = useState(memberId);
  const [selectedAuthRole, setSelectedAuthRole] = useState(defaultAuthRole);
  const [selectedBranch, setSelectedBranch] = useState<any>('');
  const [newRole, setNewRole] = useState(false);

  const [isSendLinkOpen, setIsSendLinkOpen] = useState(false);

  const { data } = useQuery(GET_BRANCH);

  const [getUser, { loading: userLoading }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      userObj(data?.user || {});
    },
  });

  const [inviteUser] = useMutation(INVITE_USER, {
    onCompleted: (response) => {
      setIsLoading(false);
      setInviteUrl(response?.inviteUser?.inviteUrl);
      setInviteeName(response?.inviteUser?.name);
      setInvitedUserId(response?.inviteUser?.eid);
      if (pendingUserRefetch) {
        pendingUserRefetch();
      }
      if (refetchLocations) {
        refetchLocations();
      }

      setIsInvited(true);
    },
    onError: (error) => {
      setIsLoading(false);
      toast({
        status: 'error',
        title: t('error'),
        description: error?.message,
      });
    },
  });

  useEffect(() => {
    if (data?.branch?.descendantBranches?.length) {
      loginUserBranchObj(data?.branch?.descendantBranches);
    }
  }, [data?.branch?.descendantBranches]);

  useEffect(() => {
    if (roleColorByName) {
      let roleData: any = [];
      Object.keys(roleColorByName)?.forEach((key) => {
        roleData.push(key);
      });
      // setSelectedRole(roleData[0]);
      const role = {
        label: roleData[0],
        value: roleData[0],
      };
      // setSelectedRole(role);

      setRoleList(roleData);
    }
    if (branchList?.length) {
      const branch: any = {
        label: branchList[0]?.name,
        value: branchList[0]?.eid,
      };
      if (branchList?.length === 1) {
        setSelectedBranch(branch);
      }
    }
  }, [roleColorByName, branchList]);
  // const onRoleChange = (value) => {
  //   setSelectedRole(value);
  // };
  const onFinish = (values: any) => {
    if (
      values &&
      values.name &&
      selectedRole &&
      selectedRole?.value &&
      selectedAuthRole &&
      selectedBranch &&
      selectedBranch?.value
    ) {
      setIsLoading(true);
      inviteUser({
        variables: {
          input: {
            name: values.name,
            role: selectedRole?.value,
            authRole: selectedAuthRole,
            branchIds: [selectedBranch?.value],
          },
        },
      });
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 20, color: 'white', marginLeft: 10 }}
      spin
    />
  );

  const getModalTitle = () => {
    if (isInvited) {
      return (
        <Flex align='center' fontWeight='bold'>
          {!isAlreadyInvited && (
            <FontAwesomeIcon
              icon={faArrowLeft as IconProp}
              style={{ fontSize: 15, cursor: 'pointer' }}
              color={'#333b4f'}
              onClick={() => {
                setIsInvited(false);
              }}
              // className='member-invite-user-plus-icon'
            />
          )}

          <div style={{ marginLeft: '15px' }}>
            <span style={{ color: '#d3765c' }}>Share link</span> has been
            generated!
          </div>
        </Flex>
      );
    } else {
      return 'Invite New Member';
    }
  };

  const onCopy = () => {
    deployEvent(AmplitudeEventNames.HOME_INVITE_BUTTON_COPY_QR_CODE);
    toast({
      status: 'success',
      title: t('invite:link_copied'),
    });
  };

  const getRoleList = () => {
    const list: any = [];
    roleList.map((item) => {
      list.push({
        label: item,
        value: item,
      });
    });
    list?.unshift({
      label: (
        <Flex gap={2} alignItems={'center'} color={'#2a85ff'}>
          <FontAwesomeIcon icon={faPlus as IconProp} />
          <Text as={'b'}>{t('role:create_role')}</Text>
        </Flex>
      ),
      value: 'Create new role',
    });
    return list;
  };

  const branches = useMemo(() => {
    //
    const root = data?.branch?.root;
    return branchList?.reduce<SelectOption[]>((list, item) => {
      if (
        selectedAuthRole === AuthRole.LOCATION_OWNER ||
        selectedAuthRole === AuthRole.WORKER
      ) {
        item.eid !== root?.eid &&
          list.push({
            label: item?.name,
            value: item?.eid,
            isHQ: item.eid === root?.eid,
          });
      } else {
        list.push({
          label: item?.name,
          value: item?.eid,
          isHQ: item.eid === root?.eid,
        });
      }
      return list;
    }, [] as SelectOption[]);
  }, [branchList, data?.branch?.root, selectedAuthRole]);

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty },
    reset,
  } = useForm();

  const changeIsInvited = () => {
    reset();
    setIsInvited(false);
  };

  const openSendLinkModal = () => {
    setIsSendLinkOpen(true);
  };

  const onCloseSendLinkModal = () => {
    setIsSendLinkOpen(false);
    onCloseHandler();
  };

  const onSelectAuthRole = (value: string) => {
    deployEvent(AmplitudeEventNames.HOME_INVITE_BUTTON_ACCESS_ROLE_SELECTION);
    setSelectedAuthRole(value);
  };

  const nameDeployHandler = () => {
    deployEvent(AmplitudeEventNames.HOME_INVITE_BUTTON_NAME);
  };

  const onCloseHandler = () => {
    deployEvent(AmplitudeEventNames.HOME_INVITE_BUTTON_CANCEL_BUTTON);
    onClose();
  };

  return (
    <>
      {!isSendLinkOpen && (
        <Modal isOpen={open} isCentered={true} onClose={onCloseHandler}>
          <ModalOverlay />
          {!isInvited && (
            <ModalContent width={inviteWidth} minWidth={inviteMinWidth}>
              <ModalHeader mt='20px' className='invite-user-header-container'>
                <Flex align='center' gap='10px'>
                  <Box h='32px' w='16px' borderRadius='4px' bg='#cabdff' />
                  <div className='modal-title'>{t('invite:invite_user')}</div>
                </Flex>
              </ModalHeader>
              <ModalCloseButton mt='30px' mr='15px' />
              <ModalBody className='invite-user-content-container'>
                <form
                  className='invite-user-form-container'
                  onSubmit={handleSubmit(onFinish)}
                >
                  <div>
                    <FormControl mt={3} isInvalid={!!errors?.name}>
                      <FormInput
                        id='name'
                        placeholder={t('name')}
                        size='lg'
                        {...register('name', {
                          required: t('common:validation.name_required'),
                          onBlur: () => nameDeployHandler(),
                          validate: (value: string) => {
                            if (!value.trimStart()) {
                              return t('invite:validation.invalid_name');
                            }
                          },
                        })}
                      />
                      <FormErrorMessage>
                        <span>{errors?.name?.message}</span>
                      </FormErrorMessage>
                    </FormControl>
                  </div>
                  <div style={{ marginTop: '15px' }}>
                    <AuthorizationLevel
                      onSelectAuthRole={onSelectAuthRole}
                      selectedAuthRole={selectedAuthRole}
                      isDisabled={disableAuthChange}
                    />
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <Dropdown
                      options={getRoleList()}
                      isLoading={userLoading}
                      isDisabled={userLoading}
                      value={selectedRole}
                      onChange={(value) => {
                        if (value?.value !== 'Create new role') {
                          deployEvent(
                            AmplitudeEventNames.HOME_INVITE_BUTTON_CUSTOM_ROLE_DROPDOWN
                          );
                          setSelectedRole(value);
                        } else {
                          setNewRole(true);
                        }
                      }}
                      placeholder={t('role:select_role')}
                      size='lg'
                      className='member-role-dropdown-container'
                      inputStyle={{
                        border: 'none',
                        borderColor: 'transparent',
                        borderRadius: '12px',
                        fontSize: '15px',
                        outline: 'none',
                      }}
                    />
                  </div>

                  <div style={{ marginTop: '14px' }}>
                    <Dropdown
                      options={branches}
                      value={selectedBranch}
                      isDisabled={branchList?.length < 2}
                      onChange={(value) => {
                        deployEvent(
                          AmplitudeEventNames.HOME_INVITE_BUTTON_LOCATION_DROPDOWN
                        );
                        setSelectedBranch(value);
                      }}
                      size='lg'
                      placeholder={t('location')}
                      className='member-role-dropdown-container'
                      inputStyle={{
                        border: 'none',
                        borderColor: 'transparent',
                        borderRadius: '12px',
                        fontSize: '15px',
                        outline: 'none',
                      }}
                      components={{
                        Option: ({ children, ...OProps }) => {
                          return (
                            <Option {...OProps}>
                              {children}
                              <HQComp
                                data={OProps.data}
                                isSelected={OProps.isSelected}
                              />
                            </Option>
                          );
                        },
                      }}
                    />
                  </div>

                  <Flex gap='20px' mb='30px' mt='20px' justify='flex-end'>
                    <PrimaryButton
                      type='button'
                      size='lg'
                      title={t('common:cancel')}
                      onClick={() => onCloseHandler()}
                      style={{
                        padding: '0px 37px',
                        maxWidth: '146px',
                      }}
                      variant='outline'
                      className='profile-button-update-container'
                    />
                    <PrimaryButton
                      type='submit'
                      size='lg'
                      isLoading={isLoading}
                      title={t('common:invite')}
                      style={{ maxWidth: '159px' }}
                      variant='solid'
                      colorScheme='blue'
                      disabled={!isDirty}
                      className='profile-button-save-container'
                    />
                  </Flex>
                </form>
              </ModalBody>
            </ModalContent>
          )}
          {isInvited && (
            <ModalContent width={modalWidth} minWidth={modalMinWidth}>
              <ModalBody className='invite-user-content-container'>
                <div className='invite-user-invited-link-container'>
                  <div>
                    <Flex align='center' gap='10px'>
                      <Box h='32px' w='16px' borderRadius='4px' bg='#cabdff' />
                      <div className='modal-title'>
                        {t('invite:invite_user')}
                      </div>
                    </Flex>
                    <div className='invite-user-ask-text'>
                      {t('invite:scan_invite_qr', {
                        name: inviteeName,
                      })}
                    </div>
                    <div className='invite-user-qr-code-mobile-container'>
                      <QRCode
                        value={inviteUrl}
                        style={{ width: '150px', height: '150px' }}
                      />
                      <CopyToClipboard text={inviteUrl} onCopy={onCopy}>
                        <div className='invite-user-send-link-text'>
                          {t('invite:copy_invite_link')}
                        </div>
                      </CopyToClipboard>
                    </div>
                    <div className='invite-user-buttons-container'>
                      <PrimaryButton
                        type='button'
                        size='lg'
                        isLoading={isLoading}
                        title={t('invite:send_link')}
                        onClick={() => openSendLinkModal()}
                        style={{ maxWidth: '236px' }}
                        variant='solid'
                        colorScheme='blue'
                        className='profile-button-save-container'
                      />
                      <PrimaryButton
                        type='button'
                        size='lg'
                        title={t('invite:invite_another')}
                        onClick={() => changeIsInvited()}
                        style={{
                          maxWidth: '236px',
                          marginTop: '13px',
                        }}
                        variant='outline'
                        className='profile-button-update-container'
                      />
                    </div>
                  </div>
                  <div className='invite-user-qr-code-container'>
                    <QRCode
                      value={inviteUrl}
                      style={{ width: '150px', height: '150px' }}
                    />
                    <CopyToClipboard text={inviteUrl} onCopy={onCopy}>
                      <div className='invite-user-send-link-text'>
                        {t('invite:copy_invite_link')}
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
              </ModalBody>
            </ModalContent>
          )}
        </Modal>
      )}

      {isSendLinkOpen && (
        <SendInviteModal
          open={isSendLinkOpen}
          onClose={onCloseSendLinkModal}
          inviteeName={inviteeName}
          memberId={invitedUserId}
        />
      )}

      <AddRole
        open={newRole}
        type='add'
        onClose={() => setNewRole(false)}
        zIndex={2002}
        onRoleCreate={(value: string) => {
          setSelectedRole({ label: value, value: value });
          getUser({ variables: {} });
        }}
      />
    </>
  );
};

export default InviteUserModal;

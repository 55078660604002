import React, { FC } from 'react';
import { Box, InputProps } from '@chakra-ui/react';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import SearchInput from '../../../../../atoms/SearchInput';
import { AlertBox } from '../../../../../atoms';

interface IProps {
  searchQuery?: string;
  onChange: InputProps['onChange'];
  haveForms?: boolean;
}

const MoveHeader: FC<IProps> = ({
  searchQuery,
  onChange,
  haveForms = true,
}) => {
  return (
    <div>
      <AlertBox icon={faArrowUpRight} fontSize='14px'>
        Moving to another category will remove these forms from the current
        category.
      </AlertBox>

      {haveForms && (
        <Box mt={3}>
          <SearchInput
            size='md'
            width='100%'
            hideShortcuts
            placeholder='Search forms'
            value={searchQuery}
            onChange={onChange}
          />
        </Box>
      )}
    </div>
  );
};

export default MoveHeader;

import {
  ContentsVariable,
  TaskVariable,
  UpdateLauncherInput,
  ValidationError,
} from '../common';
import { transformTask } from '../common/helpers';

import { IFormInput, ITaskItem } from './editor.types';

interface ErrorData {
  sectionIndex: number;
  task: ITaskItem;
}

export const finalDataValidate = (
  values: IFormInput,
  onError: (data: ErrorData) => void
): UpdateLauncherInput => {
  return {
    eid: values.eid,
    published: values.published,
    contents: values.contents.reduce<ContentsVariable[]>(
      (contents, content, sectionIndex) => {
        if (content.selected) {
          contents.push({
            eid: content.isLocal ? undefined : content.eid,
            category: content.category,
            selected: content.selected,
            tasks: content.tasks.reduce<TaskVariable[]>((tasks, task) => {
              if (task.selected) {
                if (!task.completed) {
                  onError({ sectionIndex, task });

                  throw new ValidationError('data validation failed', {
                    sectionIndex,
                    task,
                  });
                }

                tasks.push(transformTask(task, tasks.length, true));
              }

              return tasks;
            }, []),
          });
        }

        return contents;
      },
      []
    ),
  };
};

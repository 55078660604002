// @ts-ignore
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './Handbook.scss';
import { useHistory } from 'react-router';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Link, useRouteMatch } from 'react-router-dom';
import { userObj } from 'sop-commons/src/client/clientFactory';
import SearchInput from '../../atoms/SearchInput';
import PrimaryButton from '../../atoms/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faEye, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import Table from '../../sub-components/ChakraTable/Table';
// @ts-ignore
import { ReactComponent as EditIcon } from '../../assets/images/editIcon.svg';
import DashboardContainer from '../../sub-components/DashboardContainer';
import { getDeckById } from 'sop-commons/Query/Sop';
import VisibilityModal from './setVisibilityModal';
import CardEditorModal from '../../sub-components/CardEditorModal';
import { ADMIN, SUPER_ADMIN } from '../../utils/userRoles';
import DashboardDataContext, {
  IDashboardDataContext,
} from '../../sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
// @ts-ignore
import CloseIcon from '../../assets/images/closeIcon.png';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Image from '../../ui-components/Image/Image';
import {
  Box,
  Center,
  Flex,
  IconButton,
  Switch,
  useToast,
} from '@chakra-ui/react';
import { Authorize, AuthRole } from '../../authorization';
import {
  RemoveDeckQuery,
  RemoveDeckResponse,
  UpdateDeckStatus,
  UpdateDeckStatusResponse,
} from './handbook.graphql';
import { generateDeleteMessage, useCardDeckDelete } from '../../hooks';
import NoCardInDeck from './NoCardInDeck';
import { HandbookDeckRight } from './HandbookDeckRight';
import { useTranslation } from 'react-i18next';
import { DeckCardModal } from './deckCard.modal';
import { CardDetailModal } from '../../modal';
import { CardDetailEntity } from '../../types';

const DEFAULT_IMAGE =
  'https://sop-uploads-staging.s3.amazonaws.com/1654081545090_205_text-solid.png';

interface Param {
  category?: string;
  deckId: string;
}

const HandbookDeckView: FC = () => {
  const { t } = useTranslation(['common', 'deck', 'handbook']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });
  const dashboardCtx = useContext<IDashboardDataContext>(DashboardDataContext);
  const history = useHistory();
  const matchParams = useRouteMatch<Param>().params;

  const [visibilityType, setVisibilityType] = useState<'read' | 'write'>(
    'read'
  );
  const [cardSelected, setCardSelected] =
    useState<CardDetailModal<CardDetailEntity>>(null);
  const [title, setTitle] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isCardModalOpened, setIsCardModalOpened] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const userObject = useReactiveVar(userObj);
  const cardDeckDelete = useCardDeckDelete();
  const [deleteMessage, setDeleteMessage] = useState<string>();
  const [isActiveDeck, setIsActiveDeck] = useState(false);

  const selectedCategory = matchParams?.category;
  const deckId = matchParams?.deckId;

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchQuery(e?.target?.value);
  };

  const [
    getDeckCards,
    {
      data: deckCardsData,
      refetch: refetchCards,
      error: deckCardsError,
      loading,
    },
  ] = useLazyQuery(getDeckById, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onCompleted: (data) => {
      let _data = JSON.parse(JSON.stringify(data));
      setCategoryName(_data?.findDeckById?.category);
      setDeleteMessage(
        generateDeleteMessage('deck', _data?.findDeckById?.trainings, t)
      );
      setIsActiveDeck(_data?.findDeckById?.status === 'active');
    },
  });

  const [deleteDeck] = useMutation<RemoveDeckResponse>(RemoveDeckQuery, {
    onCompleted: (data) => {
      if (data?.removeDeck?.succeed) {
        toast({
          title: t('common:success'),
          status: 'success',
          description: t('deck:deck_remove_success_toast', { title: title }),
        });
        history.goBack();
      }
    },
  });

  const [changeDeckStatus] = useMutation<UpdateDeckStatusResponse>(
    UpdateDeckStatus,
    {
      onCompleted: (data) => {
        toast({
          title: t('common:success'),
          status: 'info',
          description: t('deck:deck_status_change_success_toast', {
            status: data?.updateDeck?.status,
          }),
        });
        refetchCards?.();
      },
    }
  );

  useEffect(() => {
    if (deckId) {
      getDeckCards({
        variables: {
          eid: deckId,
        },
      });
    }
  }, [deckId]);

  useEffect(() => {
    if (deckCardsError) {
      history.replace('/');
      dashboardCtx.errorMessageHandler(t('handbook:permission_needed_desc'));
      dashboardCtx.lineColorHandler('#ff6a55');
      dashboardCtx.modalTitleHandler(t('handbook:permission_needed'));
      dashboardCtx.closeIconHandler(CloseIcon);
      dashboardCtx.showErrorModalHandler();
    }
  }, [deckCardsError]);

  useEffect(() => {
    if (refetchCards) {
      refetchCards();
    }
  }, []);

  useEffect(() => {
    if (deckCardsData?.findDeckById) {
      setTitle(deckCardsData?.findDeckById?.title);
    }
  }, [deckCardsData]);

  const cardsData = useMemo(() => {
    return new DeckCardModal(deckCardsData?.findDeckById?.cards).data;
  }, [deckCardsData]);

  const onOpenModal = () => {
    setShowModal(true);
  };

  const getColumns = (isTablet) => {
    let tableColumns: any = [
      {
        Header: '',
        accessor: 'cardNumber' as const,
        width: '84px',
      },
      {
        Header: t('handbook:deck_section.card_title_heading'),
        accessor: 'cardTitle' as const,
        width: 'calc(75% - 84px)',
      },
      {
        Header: t('handbook:deck_section.views_title_heading'),
        accessor: 'views' as const,
        width: '15%',
      },
      {
        Header: t('handbook:deck_section.action_title_heading'),
        accessor: 'action' as const,
        width: '10%',
      },
    ];
    // if (userObject?.authRole !== WORKER) {
    //   tableColumns.push({
    //     Header: 'Action',
    //     accessor: 'action' as const,
    //   });
    // }
    return tableColumns;
  };

  const getTableData = (isTablet: boolean) => {
    let filteredData = cardsData;
    if (searchQuery) {
      filteredData = cardsData.filter((card) => {
        return card.title?.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }
    const tableData: any[] = filteredData?.map((item, index) => {
      return {
        cardNumber: (
          <Flex justify={'center'}>
            <Center
              width='29px'
              height='29px'
              mx={3}
              bg='#B5E4CA'
              style={{
                borderRadius: 8,
                fontSize: 12,
                fontWeight: '900',
              }}
            >
              {index + 1}
            </Center>
          </Flex>
        ),
        cardTitle: (
          <Flex
            align='self-start'
            justify='flex-start'
            cursor='pointer'
            onClick={() => setCardSelected(item)}
            gap='16px'
          >
            <Center
              bg='#F4F4F4'
              style={{
                width: 66,
                height: 63,
                minWidth: 66,
                borderRadius: 8,
              }}
            >
              <Image
                src={item?.thumbnail || DEFAULT_IMAGE}
                width={66}
                height={63}
                style={{
                  borderRadius: 8,
                  objectFit: 'cover',
                }}
              />
            </Center>
            <Box flex={1} overflow='hidden' pr={1}>
              <Box
                style={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  letterSpacing: '-0.15px',
                  fontWeight: '700',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                pr={5}
              >
                {item?.title}
              </Box>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: 12,
                  marginTop: 4,
                  fontWeight: '500',
                }}
              >
                {item.category}
              </div>
            </Box>
          </Flex>
        ),
        views: (
          <Flex height='100%' overflow='hidden' align={'flex-start'}>
            <Flex align={'center'}>
              <FontAwesomeIcon
                icon={faEye as IconProp}
                color={'#000000'}
                fontSize={15}
              />
              <Box color='#000000' ml='10px' fontSize='15px' fontWeight='600'>
                {item?.views}
              </Box>
            </Flex>
          </Flex>
        ),
        action: (
          <div
            onClick={() => {
              if (
                userObject?.type === 'user' &&
                (userObject?.authRole === SUPER_ADMIN ||
                  userObject?.authRole === ADMIN)
              ) {
                openCardModal(item);
              }
            }}
            style={{ height: '60px' }}
          >
            <EditIcon
              className={
                userObject?.type === 'user' &&
                (userObject?.authRole === SUPER_ADMIN ||
                  userObject?.authRole === ADMIN)
                  ? 'svg-handbook-enabled'
                  : 'svg-handbook-disabled'
              }
            />
          </div>
        ),
      };
    });
    return [...tableData];
  };

  const onGoBack = () => {
    if (selectedCategory) {
      history.replace(`/handbook/${selectedCategory}`);
    } else {
      history.replace(`/handbook`);
    }
  };

  const openCardModal = (cardItem) => {
    setIsCardModalOpened(true);
    setCardSelected(cardItem);
  };

  const closeCardModal = () => {
    setIsCardModalOpened(false);
  };

  const onCardSuccess = (selectedCard) => {
    if (selectedCard) {
      setCardSelected(selectedCard);
    }
    if (refetchCards) {
      refetchCards();
      // closeCardModal();
    }
  };

  const onCardDelete = () => {
    setIsCardModalOpened(false);
    if (refetchCards) {
      refetchCards();
    }
  };

  const onDeletePress = () => {
    if (!deckCardsData?.findDeckById?.eid) {
      return;
    }
    cardDeckDelete({
      title: title,
      message: deleteMessage,
      onDeletePress: () => {
        return deleteDeck({
          variables: {
            eid: deckId,
          },
        });
      },
    });
  };

  const onChangeStatus = (newStatus: boolean) => {
    setIsActiveDeck(newStatus);
    return changeDeckStatus({
      variables: {
        input: {
          eid: deckId,
          status: newStatus ? 'active' : 'inactive',
        },
      },
    });
  };

  const onAddClick = () => {
    if (categoryName && deckId) {
      history.push(`/${categoryName}/deck/edit/${deckId}`);
    }
  };

  return (
    <>
      <div className='handbook-title-wrapper'>
        <div className='handbook-title-container'>
          {t('handbook:deck_section.handbook_heading')}
        </div>
      </div>
      <div className='handbook-container'>
        <Flex align='center'>
          <Box flex={1} overflow='hidden'>
            <Box
              fontSize='20px'
              fontWeight='600'
              whiteSpace='nowrap'
              textOverflow='ellipsis'
              overflow='hidden'
            >
              <span onClick={onGoBack} style={{ cursor: 'pointer' }}>
                {selectedCategory
                  ? selectedCategory
                  : categoryName
                  ? categoryName
                  : t('common:handbook')}
              </span>{' '}
              <FontAwesomeIcon
                fontSize={16}
                icon={faChevronRight as IconProp}
              />{' '}
              {title}
            </Box>
            <Box fontSize='14px' fontWeight='600' color='#6F767E'>
              {cardsData?.length
                ? cardsData?.length > 1
                  ? t('handbook:deck_section.cards_count', {
                      count: cardsData?.length,
                    })
                  : t('handbook:deck_section.card_count', {
                      count: cardsData?.length,
                    })
                : ''}
            </Box>
          </Box>
          <Flex gap={3}>
            <Authorize
              permittedFor='user'
              permittedRoles={AuthRole.SUPER_ADMIN}
            >
              <IconButton
                aria-label={'delete'}
                bg='unset'
                borderRadius='full'
                icon={
                  <FontAwesomeIcon
                    icon={faTrashCan as IconProp}
                    color='#ff6a55'
                    size='lg'
                  />
                }
                onClick={onDeletePress}
              />
            </Authorize>

            <Flex align='center'>
              <div>
                <Switch
                  size='lg'
                  isChecked={isActiveDeck}
                  onChange={(e) => onChangeStatus(e?.target?.checked)}
                />
              </div>
              <Box fontSize='16px' fontWeight='600' color='#33383F' ml={2}>
                {t('handbook:deck_section.active_toggle_button')}
              </Box>
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={1} align='center' marginBlock='20px'>
          <SearchInput
            size='md'
            width='435px'
            hideShortcuts={true}
            placeholder={t('handbook:deck_section.search_placeholder')}
            onChange={handleQueryChange}
            value={searchQuery}
            className='settings-search-container'
          />

          <Flex gap='20px' align='flex-end' justify='flex-end' flex={1}>
            <PrimaryButton
              type='submit'
              size='md'
              title={t('handbook:deck_section.visibility_button')}
              onClick={() => {
                userObject?.type === 'user' &&
                (userObject?.authRole === SUPER_ADMIN ||
                  userObject?.authRole === ADMIN)
                  ? setVisibilityType('write')
                  : setVisibilityType('read');
                onOpenModal();
              }}
              style={{
                padding: '0px 37px',
                maxWidth: '126px',
              }}
              variant='outline'
            />
            <Authorize
              permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
              permittedFor='user'
            >
              <Link to={`/${categoryName}/deck/edit/${deckId}`}>
                <PrimaryButton
                  type='submit'
                  size='md'
                  title={t('handbook:deck_section.edit_deck_button')}
                  //onClick={onOpenModal}
                  style={{
                    padding: '0px 37px',
                    maxWidth: '126px',
                  }}
                  variant='outline'
                />
              </Link>
            </Authorize>

            <Authorize
              permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
              permittedFor='user'
            >
              <Link to={`/${categoryName}/deck/edit/${deckId}`}>
                <PrimaryButton
                  type='submit'
                  size='md'
                  title={t('handbook:deck_section.card_add_button')}
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faPlus as IconProp}
                      style={{ fontSize: 16 }}
                      color={'#fff'}
                    />
                  }
                  //onClick={onOpenModal}
                  style={{
                    padding: '0px 37px',
                    maxWidth: '126px',
                  }}
                  variant='solid'
                  colorScheme='blue'
                />
              </Link>
            </Authorize>

            {/* <Authorize permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}>
              <PrimaryButton
                type='submit'
                size='md'
                title='Set Visibility'
                onClick={() => {
                  setVisibilityType('write');
                  onOpenModal();
                }}
                style={{
                  padding: '0px 37px',
                  maxWidth: '126px',
                }}
                variant='outline'
              />
            </Authorize>

            <PrimaryButton
              type='submit'
              size='md'
              title='See Visibility'
              onClick={() => {
                setVisibilityType('read');
                onOpenModal();
              }}
              style={{
                padding: '0px 37px',
                maxWidth: '126px',
              }}
              variant='outline'
            /> */}

            {showModal && (
              <VisibilityModal
                isOpen={showModal}
                handleClose={() => {
                  setShowModal(!showModal);
                  if (deckId) {
                    getDeckCards({
                      variables: {
                        eid: deckId,
                      },
                    });
                  }
                }}
                cardCount={cardsData?.length}
                deckTitle={title}
                deckThumbnail={deckCardsData?.findDeckById?.thumbnail}
                modalType={visibilityType}
                visibilityData={deckCardsData?.findDeckById?.visibleTo}
              />
            )}
          </Flex>
        </Flex>
        <Flex justify='flex-start' gap='20px'>
          <Flex flex={1} direction='column'>
            <Box
              sx={{
                table: {
                  width: '100% !important',
                },
              }}
            >
              <Table
                colorScheme='blue'
                emptyData={{
                  content: (
                    <NoCardInDeck title={title} onAddClick={onAddClick} />
                  ),
                }}
                totalRegisters={getTableData(false).length}
                page={page}
                // Listen change page event and control the current page using state
                onPageChange={(page) => setPage(page)}
                columns={getColumns(false)}
                data={getTableData(false)}
                isLoading={loading}
                // onSort={onSort}
                //onViewsSort={onNameSort}
                // sort={sort}
                isHidePagination={true}
              />
            </Box>
          </Flex>
          <HandbookDeckRight
            cardSelected={cardSelected}
            isLoading={loading}
            totalCard={deckCardsData?.findDeckById?.cards?.length}
          />
        </Flex>
      </div>
      {isCardModalOpened && (
        <CardEditorModal
          open={isCardModalOpened}
          onClose={closeCardModal}
          onSubmit={onCardSuccess}
          onCardDelete={onCardDelete}
          isEdit={true}
          cardId={cardSelected?.eid}
          // isDeckView
        />
      )}
    </>
  );
};

const HandbookDeck: FC = () => {
  return (
    <DashboardContainer>
      <HandbookDeckView />
    </DashboardContainer>
  );
};

export default HandbookDeck;

import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { useIsReadonly } from './useIsReadonly';

interface IProps {
  isLoading?: boolean;
}

const ProfileActionButtons: FC<IProps> = ({ isLoading, children }) => {
  const isReadonly = useIsReadonly();

  if (isReadonly || isLoading) {
    return null;
  }

  return <Flex gap='10px'>{children}</Flex>;
};

export default ProfileActionButtons;

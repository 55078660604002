import React, { useCallback, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { UseConfirm, useConfirm } from '../../../ui-components/Confirm';
import PreviewHeader from '../../forms/component/PreviewHeader';
import { getPublishDate } from './validation/helper';
import { IFormInput } from './task.types';

interface IProps {
  isEdit?: boolean;
  startDateType?: IFormInput['startDateType'];
  startDate?: Date;
  startTime?: Date;
  onPublish: () => void;
}

type UsePublishConfirm = (props: IProps) => void;

export const usePublishConfirm = (): UsePublishConfirm => {
  const { t } = useTranslation(['common', 'task']);
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(
    ({ isEdit, startDateType, startDate, startTime, onPublish }) => {
      if (confirmRef.current) {
        confirmRef.current?.destroy();
      }

      const date = getPublishDate(startDateType!, startDate, startTime);

      confirmRef.current = confirm({
        title: (
          <PreviewHeader
            title={t(isEdit ? 'task:updateThisTask' : 'task:publishThisTask')}
            color='#CABDFF'
          />
        ),
        content: (
          <Box fontSize='16px' fontWeight='500' color='#6F767E'>
            <Trans
              t={t}
              i18nKey={isEdit ? 'task:updateConfMsg' : 'task:publishConfMsg'}
              values={{
                date: date.format('DD MMMM YYYY'),
                time: date.format('hh:mm A'),
              }}
            />
          </Box>
        ),

        isCentered: true,

        contentProps: {
          maxWidth: 600,
          padding: '16px',
        },

        cancelButtonProps: {
          size: 'lg',
          variant: 'outline',
          borderWidth: '2px',
          borderRadius: '7px',
          fontSize: '15px',
          paddingX: '32px',
        },
        okButtonProps: {
          size: 'lg',
          colorScheme: 'green',
          borderRadius: '7px',
          fontSize: '15px',
          paddingX: '32px',
        },

        okText: t(isEdit ? 'common:update' : 'common:publish'),

        onOK: onPublish,
      });
    },
    []
  );
};

import React, {
  FC,
  FunctionComponent,
  SVGProps,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Text,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Tooltip, TooltipProps } from 'antd';
import './ProgressTimeline.css';
import moment from 'moment';
import EmptyState from 'sub-components/EmptyState';
import PrimaryButton from 'atoms/PrimaryButton';
import { timelineDataRender } from './timelineDataRender';
import { cloneDeep } from '@apollo/client/utilities';
import { useQuery } from '@apollo/client';
import { getTrainingContentTinyData } from 'sub-components/TrackTrainingProgress/training-progress.graphql';
import { timelineDataConvert } from './TimelineDataConvert';
import { Loader } from 'sub-components';

interface IProps {
  timelineData: any[];
  onCloseClick: () => void;
  milestoneSuccessImg: string;
  userName?: string;
  openChatModal?: (selectedUserId: string) => void;
  showEmptyState?: boolean;
  userData?: any;
  remindUserChatModalHandler?: (selectedUserId: string) => void;
  isMyTimeline?: boolean;
  trainingName?: string;
  medalIcon?: string;
  isOpen: boolean;
  userId?: string;
}

const ToolTipWrapper: FC<TooltipProps> = ({ children, style, ...rest }) => {
  return (
    <Tooltip
      title={children}
      style={{ borderRadius: '4px', ...style }}
      {...rest}
    >
      <Text flex={1} noOfLines={1} pl='6px'>
        {children}
      </Text>
    </Tooltip>
  );
};

const ProgressTimeline: FC<IProps> = ({
  timelineData: _timelineData,
  onCloseClick,
  milestoneSuccessImg,
  userName,
  openChatModal,
  showEmptyState: _showEmptyState,
  userData,
  remindUserChatModalHandler,
  isMyTimeline,
  trainingName,
  medalIcon,
  isOpen,
  userId,
}) => {
  const { t } = useTranslation(['common', 'training']);
  const [sawLatest, setSawLatest] = useState(true);
  const [delayDate, setDelayDate] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [timelineData, setTimelineData] = useState(_timelineData);
  const [showEmptyState, setShowEmptyState] = useState(_showEmptyState);
  const renderedTimelineData = timelineDataRender({
    timelineData,
    userName,
    t,
    milestoneSuccessImg,
    medalIcon,
  });

  const { loading } = useQuery(getTrainingContentTinyData, {
    fetchPolicy: 'network-only',
    skip: !userId,
    variables: {
      eid: userData?.eid,
    },
    onCompleted: (response) => {
      if (userId) {
        const result = timelineDataConvert(
          {
            userProgress: userData?.rawUserProgress,
            ...userData,
            ...response?.trainingById,
          },
          userId
        );
        setTimelineData(result?.allMilestoneData);
        setShowEmptyState(result?.showEmptyState);
      }
    },
  });

  useEffect(() => {
    if (timelineData && timelineData?.length > 0) {
      let _timelineData = cloneDeep(timelineData);
      let flag = true;
      let unMatchedDate = '';
      let lastIndexedData = timelineData?.[timelineData?.length - 1];
      let isLatestTrainingPathSeen =
        lastIndexedData?.contentLastUpdate && lastIndexedData?.lastViewedAt
          ? new Date(lastIndexedData?.lastViewedAt) >=
            new Date(lastIndexedData?.contentLastUpdate)
          : true;
      for (let i = 0; i < _timelineData?.length - 1; i++) {
        if (
          !_timelineData?.[i]?.watchDetails?.didUserWatchedLatestContent &&
          !isLatestTrainingPathSeen
        ) {
          flag = false;
          unMatchedDate = _timelineData?.[i]?.watchDetails?.contentLastUpdated;
          break;
        }
      }
      if (!flag) {
        setSawLatest(false);
        setDelayDate(moment(unMatchedDate).format('DD MMMM'));
      } else {
        setSawLatest(true);
      }
      // let allContentLastUpdateDataArr: any[] = [];
      // let allLastViewedArr: any[] = [];
      // timelineData?.forEach((timeline: any) => {
      //   if (timeline?.eid) {
      //     setSelectedUserId(userData?.eid);
      //   }
      //   if (timeline?.contentLastUpdate) {
      //     allContentLastUpdateDataArr?.push(
      //       new Date(timeline?.contentLastUpdate)
      //     );
      //   }
      //   if (timeline?.lastViewedAt) {
      //     allLastViewedArr?.push(new Date(timeline?.lastViewedAt));
      //   }
      // });
      // let greatestContentLastUpdateDate = new Date(
      //   Math.max(...allContentLastUpdateDataArr)
      // );
      // let greatestAllLastViewedDate = new Date(Math.max(...allLastViewedArr));
      // if (greatestContentLastUpdateDate && greatestAllLastViewedDate) {
      //   if (greatestAllLastViewedDate >= greatestContentLastUpdateDate) {
      //     setSawLatest(true);
      //   } else {
      //     setSawLatest(false);
      //     setDelayDate(moment(greatestAllLastViewedDate).format('DD MMMM'));
      //   }
      // }
    }
  }, [timelineData]);

  const latestChangesHandler = () => {
    return (
      <div
        id='latestChanges'
        style={{
          backgroundColor: 'rgba(181, 228, 202, 0.5)',
          borderRadius: '10px',
          padding: '14px 10px',
          marginBottom: '2rem',
        }}
      >
        {/* @ts-ignore */}
        {t('training:seenLatestChanges', {
          userName: userName,
        })}
      </div>
    );
  };

  const noLatestChangesHandler = (date: string) => {
    return (
      <div
        id='noLatestChanges'
        style={{
          backgroundColor: 'rgba(202, 189, 255, 0.3)',
          borderRadius: '10px',
          padding: '14px 10px',
          marginBottom: '2rem',
        }}
      >
        <Trans
          t={t}
          i18nKey={
            isMyTimeline
              ? 'training:latestVersionWarningSelf'
              : 'training:latestVersionWarning'
          }
          values={{
            date: date,
          }}
          components={{
            strong: <strong />,
            send: (
              <strong
                style={{
                  color: '#8e59ff',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  openChatModal?.(userData?.eid);
                }}
              />
            ),
          }}
        />
      </div>
    );
  };

  const remindUserHandler = () => {
    remindUserChatModalHandler && remindUserChatModalHandler(userData);
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onCloseClick} size='md'>
      <DrawerContent p={6}>
        <DrawerBody>
          <Flex
            id='progress-header'
            align='center'
            gap='10px'
            style={{
              fontSize: 18,
              fontWeight: '600',
              marginBottom: '50px',
            }}
          >
            <Box h='32px' minW='16px' borderRadius='4px' bg='#B1E5FC' />
            <Flex style={{ flex: '1 1 auto' }} align='center'>
              <Trans
                t={t}
                i18nKey={
                  isMyTimeline
                    ? 'training:yourTrainingProgress'
                    : 'training:userNameProgress'
                }
                values={{
                  name: userData?.name || userName,
                  training: trainingName,
                }}
                components={{
                  training: <ToolTipWrapper zIndex={21474840} />,
                }}
              />
            </Flex>
            <Box cursor='pointer' ml='6px'>
              <FontAwesomeIcon
                onClick={onCloseClick}
                fontSize={18}
                icon={faTimes as IconProp}
                color={'#000000'}
              />
            </Box>
          </Flex>
          {!showEmptyState && !loading && (
            <>
              <>
                {sawLatest && latestChangesHandler()}
                {!sawLatest && noLatestChangesHandler(delayDate)}
              </>
              <div id='timeline' style={{ overflow: 'scroll' }}>
                {timelineData &&
                  timelineData.length > 0 &&
                  renderedTimelineData}
              </div>
            </>
          )}
          {loading && (
            <Center h={'full'}>
              <Loader size={'lg'} />
            </Center>
          )}
          {showEmptyState && (
            <EmptyState
              title={t(
                isMyTimeline
                  ? 'training:notStartedTrainingYou'
                  : 'training:notStartedTraining',
                {
                  userName: userName,
                }
              )}
              image='Progress'
              showButton={true}
              buttonProps={
                <Box
                  width='fit-content'
                  mt={4}
                  display={isMyTimeline ? 'none' : 'block'}
                >
                  <PrimaryButton
                    title={t('training:remindThem')}
                    colorScheme='blue'
                    variant='solid'
                    onClick={remindUserHandler}
                  />
                </Box>
              }
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ProgressTimeline;

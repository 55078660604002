import React, { FC } from 'react';
import {
  Box,
  Flex,
  useOutsideClick,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { DropDownWithImageWrapper } from './DropDownWithImage.styles';

import info from '../../../../assets/images/info.svg';
import Question from '../../../../sub-components/forms/from-preview/component/Question';
import { IFormResponseItemsEntity } from '../../forms-types';

type QuestionEntity = IFormResponseItemsEntity['form']['questions'][number] & {
  image: string;
  index: number;
};

interface IProps {
  questions: Omit<QuestionEntity, 'index'>[];
  selectedData?: QuestionEntity;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<QuestionEntity | undefined>
  >;
}

const DropDownWithImage: FC<IProps> = ({
  questions,
  selectedData,
  setSelectedItem,
}) => {
  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const methods = useForm();

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: false,
  });

  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  });

  const setSelectedItemHandler = (
    obj: Omit<QuestionEntity, 'index'>,
    index: number
  ) => {
    setSelectedItem({ ...obj, index });
  };

  const changeDropdownSelection = (type: 'backward' | 'forward') => {
    if (selectedData) {
      if (type === 'forward') {
        let obj = questions[selectedData.index! + 1];
        setSelectedItem({ ...obj, index: selectedData.index! + 1 });
      } else {
        let obj = questions[selectedData.index! - 1];
        setSelectedItem({ ...obj, index: selectedData.index! - 1 });
      }
    }
  };

  return (
    <DropDownWithImageWrapper>
      <Flex gap={4} w='full' align='center'>
        <Flex
          flex={1}
          onClick={onOpen}
          gap={4}
          bg='rgb(244, 244, 244)'
          height='3rem'
          borderRadius='12px'
          padding='1rem'
          cursor='pointer'
          maxW='calc(80% - 32px)'
          position='relative'
          alignItems='center'
        >
          <Flex gap={2} align='center' w='full'>
            <Box minWidth={'2%'} maxWidth={'2%'} mr='10px'>
              <img alt='question-icon' src={selectedData?.image} />
            </Box>
            <Box flex={1} fontWeight='600' isTruncated>
              {selectedData?.label}
            </Box>
            <FontAwesomeIcon
              fontSize={16}
              style={{ color: '#6f767e' }}
              icon={faChevronDown as IconProp}
            />
          </Flex>

          {isOpen && (
            <Flex
              ref={ref}
              position='absolute'
              flexDirection='column'
              bg='white'
              borderRadius='7px'
              top='55px'
              maxH='200px'
              overflowY='scroll'
              border='1px solid #dddddd'
              zIndex='1'
              left='0'
              w='full'
            >
              {questions?.map((obj, index) => (
                <div
                  key={index}
                  className='drop-option-container'
                  onClick={(event) => {
                    event.stopPropagation();
                    setSelectedItemHandler(obj, index);
                    onClose();
                  }}
                >
                  <img alt='icon' src={obj?.image} />
                  <strong>{obj?.label}</strong>
                </div>
              ))}
            </Flex>
          )}
        </Flex>

        <Box boxSize='24px'>
          {selectedData && (
            <Popover trigger='hover' placement='left'>
              {/* @ts-ignore */}
              <PopoverTrigger>
                <img alt='info' src={info} />
              </PopoverTrigger>
              <PopoverContent
                maxW='280px'
                fontWeight='initial'
                whiteSpace='pre-wrap'
              >
                <FormProvider {...methods}>
                  {/* @ts-ignore */}
                  <Question question={selectedData} isPreview isDisabled />
                </FormProvider>
              </PopoverContent>
            </Popover>
          )}
        </Box>

        <Flex gap={4} ml='auto'>
          {selectedData?.index! > 0 ? (
            <IconButton
              size='lg'
              aria-label='backward'
              icon={
                <FontAwesomeIcon
                  fontSize={16}
                  color='#6f767e'
                  icon={faChevronLeft as IconProp}
                />
              }
              onClick={() => changeDropdownSelection('backward')}
            />
          ) : (
            <Box boxSize='48px' />
          )}

          {selectedData?.index! + 1 < questions?.length ? (
            <IconButton
              size='lg'
              aria-label='forward'
              icon={
                <FontAwesomeIcon
                  fontSize={16}
                  color='#6f767e'
                  icon={faChevronRight as IconProp}
                />
              }
              onClick={() => changeDropdownSelection('forward')}
            />
          ) : (
            <Box boxSize='48px' />
          )}
        </Flex>
      </Flex>
    </DropDownWithImageWrapper>
  );
};

export default DropDownWithImage;

import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { searchRegExp } from 'utils';

import { ContainerWrapper } from '../common';
import PerformanceListHeader from './PerformanceListHeader';
import PerformanceList from './PerformanceList';
import { PerformanceEntity } from './performance.graphql';
import SingleUserChatModal from '../../../../../ui-components/SingleUserChatModal';
import { usePerformanceOverViewData } from './usePerformanceOverViewData';

interface IProps {}

const PerformanceListContainer: FC<IProps> = () => {
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [chatUserId, setChatUserId] = useState<string>();

  const { performanceEntities, loading } = usePerformanceOverViewData();

  const dataList = useMemo(() => {
    if (searchQuery) {
      const reg = searchRegExp(searchQuery, 'gi');
      return performanceEntities.filter((value) => {
        return (
          value.name?.match(reg) ||
          value.authRole?.match(reg) ||
          value.role?.match(reg) ||
          value?.locations?.[0]?.name?.match(reg)
        );
      });
    }

    return performanceEntities;
  }, [performanceEntities, searchQuery]);

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const clickedHandler = (clickedItem: string, member: PerformanceEntity) => {
    switch (clickedItem) {
      case 'message':
        return setChatUserId(member.eid);
      case 'open':
        return history.push('/tasks/performance/' + member.eid, {
          name: member.name,
        });
      default:
        console.log(clickedItem);
    }
  };

  return (
    <ContainerWrapper>
      <PerformanceListHeader searchQuery={searchQuery} onSearch={onSearch} />

      <PerformanceList
        dataList={dataList}
        loading={loading}
        currentPage={1}
        onPageChange={() => null}
        onClickedHandler={clickedHandler}
      />

      {chatUserId && (
        <SingleUserChatModal
          selectedUser={chatUserId}
          onClose={() => setChatUserId(undefined)}
        />
      )}
    </ContainerWrapper>
  );
};

export default PerformanceListContainer;

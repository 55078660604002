import { gql } from '@apollo/client';

export const CREATE_CARD_MUTATION = gql`
  mutation CreateCard($input: [CardAddInputInput]) {
    createCard(input: $input) {
      cardType
      content
      raw_content
      title
      thumbnail
      backgroundColor
      layout
      subTitle
      createdBy
      entityId
      status
      views
      updatedAt
      createdAt
      eid
      form {
        title
        description
        category
        eid
        questions
        status
        thumbnail
        visibility
        createdBy {
          eid
        }
      }
      translations
    }
  }
`;

export const UPDATE_CARD_MUTATION = gql`
  mutation UpdateCard($input: CardUpdateInputInput) {
    updateCard(input: $input) {
      cardType
      title
      thumbnail
      backgroundColor
      layout
      subTitle
      category
      createdBy
      entityId
      content
      raw_content
      status
      views
      media {
        playbackId
        assetId
        type
        rawUrl
        mediaUrl
        status
        meta
        _id
      }
      updatedAt
      createdAt
      eid
      translations
      defaultLanguage
    }
  }
`;

export const UPDATE_TRANS_CARD_MUTATION = gql`
  mutation UpdateCardTranslation($input: CardTranslationTypeInput) {
    UpdateCardTranslation(input: $input) {
      succeed
    }
  }
`;

export const UPDATED_CARD_DATA = gql`
  query GetUpdatedCard($eid: String!) {
    findCardById(eid: $eid) {
      cardType
      title
      thumbnail
      backgroundColor
      layout
      subTitle
      category
      createdBy
      entityId
      content
      raw_content
      status
      views
      media {
        playbackId
        assetId
        type
        rawUrl
        mediaUrl
        status
        meta
        _id
      }
      updatedAt
      createdAt
      eid
      translations
    }
  }
`;

export const RemoveCardQuery = gql`
  mutation RemoveCard($eid: String!) {
    removeCard(eid: $eid) {
      succeed
    }
  }
`;

export interface RemoveCardResponse {
  removeCard?: {
    succeed?: boolean;
  };
}

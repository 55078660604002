import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { Authorize, AuthRole } from '../../../authorization';

// @ts-ignore
import noImage from '../../../assets/images/placeholder/empty-deck.svg';
import { useFolderSubFolder } from 'hooks';
import { foldersListVar } from '../ChapterContainer/ChapterContainer';
import { useReactiveVar } from '@apollo/client';
import useCombinedStore from 'zustandStore/store';
import { getQueryParamValue } from 'utils/queryParams';

interface IProps {
  onAddChapterClick?: () => void;
}

const EmptyChapter: FC<IProps> = ({ onAddChapterClick }) => {
  const { t } = useTranslation(['handbook', 'sidebar', 'chapter']);
  const { hierarchyData } = useCombinedStore();
  const { folderId, subFolderId } = useRouteMatch<{
    folderId?: string;
    subFolderId?: string;
  }>().params;
  const { folderName } = useFolderSubFolder(
    subFolderId ? subFolderId : folderId
  );

  const nameValue = getQueryParamValue('name');
  const typeValue = getQueryParamValue('type');

  const foldersList = useReactiveVar(foldersListVar);

  return (
    <Flex direction='column' maxW='300px' gap='14px'>
      <Image src={noImage} maxW='300px' mx='auto' />
      <Flex direction='column' textAlign='center' gap='2px'>
        <Box fontSize='15px' fontWeight='700' color='#303030'>
          {/* @ts-ignore */}
          {t('handbook:category_no_item', {
            category: folderName || nameValue || t('sidebar:knowledge_base'),
          })}
        </Box>
        <Authorize
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
          permittedFor='user'
        >
          <Box fontSize='11px' fontWeight='500' color='#9E9E9E' px='12px'>
            {/* {t('chapter:addChapterToCategory')} */}
            {typeValue === 'subFolder' || subFolderId
              ? 'Add chapters to subfolder'
              : typeValue === 'folder' || folderId
              ? 'Add items to folder'
              : t('chapter:addChapterToCategory')}
          </Box>
        </Authorize>
      </Flex>
      <Authorize
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ]}
        permittedFor='user'
      >
        <Flex gap='10px' justify='center'>
          <Button
            leftIcon={<AddIcon />}
            w='max-content'
            px='16px'
            colorScheme='blue'
            maxW='full'
            fontSize='15px'
            onClick={onAddChapterClick}
          >
            {t('chapter:addNewChapter')}
          </Button>
        </Flex>
      </Authorize>
    </Flex>
  );
};

export default EmptyChapter;

import React, { FC } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGrid2 } from '@fortawesome/pro-regular-svg-icons';
import { eventBus } from 'shared/eventEmit/eventBus';
import { EventBusEmits } from 'shared/eventEmit/defaults/defaults';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

export interface ListPropsProps {
  viewType?: 'list' | 'grid';
}

const ListGridToggle: FC<ListPropsProps> = ({ viewType }) => {
  const onViewChangeEmit = (value: 'list' | 'grid' | undefined) => {
    eventBus.emit(
      EventBusEmits.chapterContainer.CHAPTER_LIST_GRID_TOGGLE,
      value
    );
  };

  const onPress = (
    value: ListPropsProps['viewType'],
    currentVal?: ListPropsProps['viewType']
  ) => {
    if (value === currentVal) {
      return;
    }
    if (value === 'grid') {
      deployEvent(AmplitudeEventNames.GRID_VIEW_TYPE);
    } else if (value === 'list') {
      deployEvent(AmplitudeEventNames.LIST_VIEW_TYPE);
    }
    onViewChangeEmit?.(value);
  };

  return (
    <Flex>
      <IconButton
        aria-label={'list'}
        icon={<FontAwesomeIcon icon={faListUl as IconProp} />}
        borderRightRadius='0'
        {...(viewType === 'list'
          ? {
              variant: 'solid',
              colorScheme: 'blue',
            }
          : {
              variant: 'outline',
              color: '#6F767E',
            })}
        onClick={() => onPress('list', viewType)}
      />
      <IconButton
        aria-label={'card'}
        icon={<FontAwesomeIcon icon={faGrid2 as IconProp} />}
        borderLeftRadius='0'
        {...(viewType === 'grid'
          ? {
              variant: 'solid',
              colorScheme: 'blue',
            }
          : {
              variant: 'outline',
              color: '#6F767E',
            })}
        onClick={() => onPress('grid', viewType)}
      />
    </Flex>
  );
};

export default ListGridToggle;

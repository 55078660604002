import { useReactiveVar } from '@apollo/client';
import { RepeatClockIcon } from '@chakra-ui/icons';
import { Center, Flex, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'atoms';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import React, { FC } from 'react';
import { eventBus } from 'shared/eventEmit';
import { HeaderColors } from 'shared/headerColors/header-colors';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import useCombinedStore from 'zustandStore/store';
import { IModeType } from '../Body/BodyComponent';
import ButtonFooter from '../Body/ButtonFooter';

interface IHeaderProps {
  mode: IModeType;
  selectedTaskData: CustomLauncherTask;
  onClose: () => void;
  onEdit?: () => void;
}

const Header: FC<IHeaderProps> = (props) => {
  const {
    taskDetails: { markAsUnfinishedLoading },
  } = useCombinedStore();
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const { mode, selectedTaskData, onClose, onEdit } = props;
  const markAsUnfinishedHandler = () => {
    eventBus.emit('markAsUnfinished');
  };
  return (
    <Flex align='center' justify='space-between'>
      <GenericColorHeader title='Task details' color={HeaderColors.Purple} />
      <Flex gap={2} w='50%' justify='flex-end'>
        {mode === 'view' && (
          <PrimaryButton
            isLoading={markAsUnfinishedLoading}
            disabled={markAsUnfinishedLoading}
            leftIcon={<RepeatClockIcon color='#6F767E' />}
            title='Mark as unfinished'
            style={{ width: 'fit-content' }}
            onClick={markAsUnfinishedHandler}
          />
        )}
        {mode === 'edit' && (
          <Tooltip label='Edit' hasArrow>
            <Center
              boxSize='36px'
              borderRadius='50%'
              bg='#EFEFEF'
              cursor='pointer'
              onClick={onEdit}
            >
              <FontAwesomeIcon
                icon={faPen as IconProp}
                color='#6F767E'
                fontSize='16px'
              />
            </Center>
          </Tooltip>
        )}
        {[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
          loggedInUserAuthRole
        ) && <ButtonFooter selectedTaskData={selectedTaskData} />}
        <Tooltip label='Close' hasArrow>
          <Center
            boxSize='36px'
            borderRadius='50%'
            bg='#EFEFEF'
            cursor='pointer'
            onClick={onClose}
          >
            <FontAwesomeIcon
              icon={faXmark as IconProp}
              color='#6F767E'
              fontSize='16px'
            />
          </Center>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Header;

import { gql } from '@apollo/client';
import { AuthRole } from 'authorization';
import { UserStatus, UserType } from 'types';

export interface SupervisorVariable {
  entityId: string;
  status: UserStatus[];
  type: UserType[];
  authRole?: AuthRole[];
}

export const getSupervisors = gql`
  query TaskSupervisors(
    $entityId: String!
    $type: [userTypeEnum]
    $status: [userStatusEnum]
    $authRole: [userAuthRoleEnum]
  ) {
    EntityUser(
      entityId: $entityId
      type: $type
      status: $status
      authRole: $authRole
    ) {
      eid
      name
      authRole
      profilePic
      locations {
        eid
        name
      }
      role
    }
  }
`;

export interface TaskSupervisorResponse {
  EntityUser: Array<{
    eid: string;
    name: string;
    authRole: AuthRole;
    profilePic?: string;
    locations?: {
      eid: string;
      name: string;
    }[];
    role?: string;
  }>;
}

import React, { forwardRef } from 'react';
import { Flex } from '@chakra-ui/react';
// import moment from 'moment/moment';

import {
  DateInput,
  DateInputButton,
  DateInputProps,
} from '../../../../../../Locations/Components/DateInput';

const LogTimeInput = forwardRef<never, DateInputProps>((props, ref) => {
  return (
    <DateInput
      customInput={
        <DateInputButton
          variant='outline'
          bg='white'
          paddingInline={4}
          sx={{
            '.chakra-button__icon': {
              color: '#6F767E',
            },
          }}
        >
          <Flex flex={1} />
        </DateInputButton>
      }
      placeholderText='Select time'
      showTimeSelect
      showTimeSelectOnly
      dateFormat='hh:mm a'
      // minTime={moment().startOf('day').toDate()}
      // maxTime={new Date()}
      timeIntervals={15}
      {...props}
      ref={ref}
    />
  );
});

LogTimeInput.displayName = 'LogTimeInput';

export default LogTimeInput;

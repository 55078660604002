import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { AllOpeningFilterBadgeList } from './FilterBadge/List';
import { IFilterBadgeProps } from './FilterBadge/Singleton/FilterBadge';
import { AllOpenHeading } from './Heading';

type IOpeningTaskProps = {
  showTitle?: boolean;
  selectedFilter: string;
  setSelectedFilter: React.Dispatch<React.SetStateAction<string>>;
  filterOptions: IFilterBadgeProps[];
};

const OpeningTask: FC<IOpeningTaskProps> = (props) => {
  const {
    filterOptions,
    showTitle = true,
    selectedFilter,
    setSelectedFilter,
  } = props;

  const handleFilterSelect = (value: string) => {
    setSelectedFilter(value);
  };

  const filterBadges = filterOptions.map((filter) => ({
    ...filter,
    selected: filter.value === selectedFilter,
    onSelected: handleFilterSelect,
  }));

  return (
    <Flex flexDir='column' gap={2}>
      {showTitle && <AllOpenHeading />}
      <AllOpeningFilterBadgeList list={filterBadges} />
    </Flex>
  );
};

export default OpeningTask;

import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import ActivityAvatar from './ActivityAvatar';
import { ActivityResult } from './activity-helper-new';
import { toArray } from '../../utils/utils';

interface IProps {
  authUserId?: string;
  index: number;
  value: ActivityResult;
  onUserClick?: (user?: ActivityResult['user']) => void;
  taskHandler?: (value?: ActivityResult) => void;
}

const FeedItem: FC<IProps> = ({
  value,
  index,
  authUserId,
  onUserClick,
  taskHandler,
}) => {
  const { t, i18n } = useTranslation(['common', 'activity']);

  const timeAgoString = useMemo(() => {
    let today = moment();
    if (moment(value.createdAt).isSame(today, 'day')) {
      return moment(value.createdAt).fromNow();
    } else {
      return moment(value.createdAt).format('hh:mm A');
    }
  }, [value.createdAt, i18n.language]);

  const isYou = authUserId === value?.nameUser?.eid;

  return (
    <Flex align='flex-start' gap='12px'>
      <ActivityAvatar index={index} user={value.nameUser as never} />
      <Box width='full' fontWeight='500'>
        <Flex wrap={'wrap'} columnGap='4px' fontSize='15px' color='#9A9FA5'>
          <Trans
            t={t}
            i18nKey={`${value.transPrefix}.${value.transKey}`}
            ns={'activity'}
            values={{
              name: isYou ? t('activity:you') : value?.nameUser?.name,
              wasWere: t(isYou ? 'activity:were' : 'activity:was'),
              hasHave: t(isYou ? 'activity:have' : 'activity:has'),
              training: value?.training?.title,
              form: value?.form?.title,
              location: toArray(value?.locations)[0]?.name || '****',
              user:
                value.userInstance?.eid === authUserId
                  ? t('activity:you')
                  : value.userInstance?.name,
              role: value?.role,
              cardName: value?.cardName,
              groupName: value?.groupName,
              deckName: value?.deckName,
            }}
            components={{
              name: (
                <Box
                  as='span'
                  fontWeight='700'
                  cursor='pointer'
                  color='#1A1D1F'
                  onClick={() => onUserClick?.(value?.nameUser)}
                />
              ),
              status: <Box as='span' color='#9A9FA5' />,
              task: (
                <Box
                  as='span'
                  color='black'
                  // cursor={
                  //   innerVal?.eventName.toLowerCase() ===
                  //   'DeckViewComplete'.toLowerCase()
                  //     ? 'pointer'
                  //     : 'default'
                  // }
                  onClick={() => taskHandler?.(value)}
                />
              ),
            }}
          />
        </Flex>
        <Box fontSize='12px' color='#999999'>
          {timeAgoString}
        </Box>
      </Box>
    </Flex>
  );
};

export default FeedItem;

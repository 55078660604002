import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { UseConfirm, useConfirm } from '../../../../../../../ui-components';
import ConversationLogView from './ConversationLogView';

interface IProps {
  noteId: string;
}

type ViewConversationLog = (props: IProps) => void;

export const useViewConversationLog = (): ViewConversationLog => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ noteId }) => {
    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      content: <ConversationLogView noteId={noteId} />,
      isCentered: true,
      contentProps: {
        maxWidth: '1000px',

        marginTop: 'auto',
        marginBottom: 'auto',
        containerProps: {
          // alignItems: 'start',
          paddingTop: '3.75rem',
          paddingBottom: '3.75rem',
        },
      },
      bodyProps: {
        padding: 8,
      },
      footer: null,
    });
  }, []);
};

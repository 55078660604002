import React, { useCallback, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { AlertDialogCloseButton, Box, Flex, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { CategoryEntity, userObj } from 'sop-commons/src/client';

import { BoxHeader, useConfirm } from 'ui-components';

import {
  RemoveCategoryQuery,
  RemoveCategoryResponse,
  RemoveCategoryVariable,
} from './add-category.graphql';
import { GET_USER } from '../../../pages/Login/login.graphql';
import { useMoveItemTo } from './useMoveItemTo';
import { GET_FOLDERS_LIST } from 'pages/Chapters/chapters.graphql';
import { getFoldersList } from 'shared/graphql/SharedGraphql';

type ICategoryDelete = (data: CategoryEntity) => void;

interface IProps {
  onMoveSuccess?: () => void;
  onDeleteSuccess?: () => void;
}

export const useCategoryDelete = ({
  onMoveSuccess,
  onDeleteSuccess,
}: Partial<IProps> = {}): ICategoryDelete => {
  const { t } = useTranslation(['common', 'category', 'chapter']);
  const toast = useToast({
    position: 'top-right',
  });
  const history = useHistory();
  const confirm = useConfirm();
  const moveItemTo = useMoveItemTo();
  const confirmRef = useRef<ReturnType<typeof confirm>>();
  const entityId = useReactiveVar(userObj)?.entityId;

  // const routeMatch = useRouteMatch<{ category: string }>();
  const { folderId, subFolderId } = useRouteMatch<{
    folderId?: string;
    subFolderId?: string;
  }>().params;

  // const selectedCategory = routeMatch?.params?.category;

  // const [fetchUserData] = useLazyQuery(GET_USER, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: (response) => {
  //     if (response?.user) {
  //       userObj(response.user);
  //       const TCat = (response.user?.entity?.category ||
  //         []) as CategoryEntity[];
  //       if (selectedCategory) {
  //         const status = TCat?.some((cat) => cat.name === selectedCategory);
  //         if (!status) {
  //           history.replace(
  //             TCat[0]?.name ? `/folders/${TCat[0].name}` : '/folders'
  //           );
  //         }
  //       }
  //     }
  //   },
  // });

  const onGetFoldersListSuccess = () => {
    toast({
      status: 'success',
      title: t('success'),
      description: t('chapter:folderSuccessRemoved'),
    });
    confirmRef?.current?.destroy?.();
    // onDeleteSuccess?.();
  };

  const { execute: fetchFoldersList } = getFoldersList(onGetFoldersListSuccess);

  const [deleteCategory] = useMutation<
    RemoveCategoryResponse,
    RemoveCategoryVariable
  >(RemoveCategoryQuery, {
    onCompleted: (response) => {
      setTimeout(() => {
        fetchFoldersList();
      }, 1000);
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('error'),
        description: 'Folder could not be removed',
      });
      confirmRef?.current?.destroy?.();
    },
  });

  const onCancelPress = useCallback(
    (data: CategoryEntity) => {
      moveItemTo({
        data: data,
        category: data.name,
        onBackPress: () => onDeletePress(data),
        onDeletePress: () => {
          if (folderId === data?.eid) {
            history.push('/folders');
          }
          return deleteCategory({
            variables: {
              eid: entityId,
              // category: data.name,
              categoryId: data.eid,
            },
          });
        },
        onMoveSuccess: onMoveSuccess,
      });
    },
    [deleteCategory, entityId, onMoveSuccess, folderId]
  );

  const onDeletePress = useCallback(
    (data: CategoryEntity) => {
      confirmRef.current = confirm({
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader
                // @ts-ignore
                title={t('category:delete_name', {
                  value: data.name,
                })}
                color='#CABDFF'
              />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
              onClick={(event) => {
                event.preventDefault();
                confirmRef.current?.destroy?.();
              }}
            />
          </Flex>
        ),
        content: (
          <Flex
            fontSize='14px'
            fontWeight={400}
            color='#33383F'
            bg='rgba(177, 229, 252, 0.4)'
            py='12px'
            px='16px'
            borderRadius='10px'
            gap='12px'
            align='center'
          >
            <FontAwesomeIcon icon={faTrashCan as IconProp} color='#2A85FF' />
            {/* {t('chapter:deleteFolderWarning')} */}
            Deleting this folder will delete all chapters & subfolders in this
            folder.
          </Flex>
        ),
        isCentered: true,
        contentProps: {
          padding: '15px',
          minW: '700px',
          borderRadius: '16px',
        },
        okButtonProps: {
          colorScheme: 'red',
          borderRadius: '10px',
        },
        cancelButtonProps: {
          colorScheme: 'blue',
          borderRadius: '10px',
        },
        cancelText: 'Move items to another folder',
        okText: t('category:delete_anyway'),
        onOK: () => {
          console.log('DATA : ', data);
          if (folderId === data?.eid) {
            history.push('/folders');
          }
          return deleteCategory({
            variables: {
              eid: entityId,
              // category: data.name,
              categoryId: data.eid,
            },
          });
        },
        onCancel: () => onCancelPress(data),
      });
    },
    [deleteCategory, onCancelPress, entityId, folderId]
  );

  return onDeletePress;
};

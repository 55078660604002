import React, { ChangeEvent, FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useUploadImage, useUploadToAws } from 'hooks';
import { QuestionType } from 'types';

import { Question } from '../../create-form/form.types';

import AddVideo from './AddVideo';
import AddImage from './AddImage';
import AddFile from './AddFile';
import { FormPreviewInput } from '../form-preview.types';

interface IProps {
  question: Question;
  currentIndex: number;
  isPreview?: boolean;
  isDisabled?: boolean;
}

interface CmpProps {
  qType: QuestionType;
  value?: string[];
  onChange: (value?: string) => void;
  isPreview?: boolean;
  isDisabled?: boolean;
  setName: (value: string) => void;
}

const wait = (data?: any) => {
  return new Promise((resolve) => {
    setTimeout(resolve, 3000, data);
  });
};

const VIDEO_URL =
  'https://sop-uploads-staging.s3.amazonaws.com/mja98k5r6mlbc25nmaek5pom/1665311150092_854_butterfly_flower.mp4';
const IMAGE_URL =
  'https://sop-uploads-staging.s3.amazonaws.com/mja98k5r6mlbc25nmaek5pom/1663851205026_582_mike-kenneally-tNALoIZhqVM-unsplash.jpg';

const UploadFieldComponent: FC<CmpProps> = ({
  qType,
  value,
  onChange,
  isPreview,
  isDisabled,
  setName,
}) => {
  const { t } = useTranslation('card');
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    status: isPreview ? 'info' : 'warning',
  });
  const [loading, setLoading] = useState(false);

  // TODO uncomment this for uploading image
  const uploadImage = useUploadImage();
  const uploadToAws = useUploadToAws();

  // TODO remove this for uploading image
  async function uploadSimulate(file: File | null, _qType: QuestionType) {
    await wait();
    return _qType === 'videoUpload' ? VIDEO_URL : IMAGE_URL;
  }

  const uploadFile = async (file: File) => {
    if (file?.type?.split('/')?.[0]?.toLowerCase() === 'video') {
      return await uploadToAws(file);
    } else {
      return await uploadImage(file);
    }
  };

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;

    if (fileList && fileList.length) {
      setLoading(true);
      try {
        if (isPreview) {
          const fakeUrl = await uploadSimulate(fileList[0], qType);
          onChange?.(fakeUrl);
        } else {
          const responseUrl = await uploadFile(fileList[0]);
          onChange?.(responseUrl || '');
          setName(fileList[0].name);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  async function onImageUpload(event: ChangeEvent<HTMLInputElement>) {
    // TODO handle this one

    // Upload file
    // set file url
    // set file name

    toast({
      title: t('waring_title'),
      description: t('capture_not_support'),
    });

    if (isPreview) {
      setLoading(true);
      try {
        const responseUrl = await uploadSimulate(null, qType);
        onChange?.(responseUrl);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }

  async function onVideoUpload(event: ChangeEvent<HTMLInputElement>) {
    // TODO handle this one

    // Upload file
    // set file url
    // set file name

    toast({
      title: t('waring_title'),
      description: t('video_record_not_support'),
    });

    if (isPreview) {
      setLoading(true);
      try {
        const responseUrl = await uploadSimulate(null, qType);
        onChange?.(responseUrl);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }

  if (qType === 'fileUpload') {
    return (
      <AddFile
        onChange={onChangeFile}
        isLoading={loading}
        isDisabled={isDisabled}
        value={value}
        onRemove={() => onChange?.(undefined)}
      />
    );
  }
  if (qType === 'imageUpload') {
    return (
      <AddImage
        onChange={onImageUpload}
        isLoading={loading}
        isDisabled={isDisabled}
        isPreview={isPreview}
        value={value}
        onRemove={() => onChange?.(undefined)}
      />
    );
  }
  if (qType === 'videoUpload') {
    return (
      <AddVideo
        onChange={onVideoUpload}
        isLoading={loading}
        isDisabled={isDisabled}
        isPreview={isPreview}
        value={value}
        onRemove={() => onChange?.(undefined)}
      />
    );
  }
  return null;
};

const UploadField: FC<IProps> = ({
  currentIndex,
  question,
  isPreview,
  isDisabled,
}) => {
  const { t } = useTranslation('form');
  const { control, setValue } = useFormContext<FormPreviewInput>();

  const setName = (value: string) => {
    setValue(`response.${currentIndex}.response`, value);
  };

  return (
    <Controller
      name={`response.${currentIndex}.responseUrl`}
      control={control}
      rules={{
        required: {
          value: question?.isRequired === true,
          message: t('validation.field_required'),
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <FormControl mt={3} isInvalid={!!fieldState.error}>
            <UploadFieldComponent
              value={field.value}
              onChange={field.onChange}
              qType={question.qType}
              isPreview={isPreview}
              isDisabled={isDisabled}
              setName={setName}
            />
            <FormErrorMessage>
              <span>{fieldState?.error?.message}</span>
            </FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default UploadField;

export const generateVideoThumbnail = <B extends boolean = false>(
  file: File | string,
  quality?: number,
  blob?: B
): Promise<(B extends true ? Blob : string) | null> => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.volume = 0;
    video.crossOrigin = 'anonymous';

    video.src = typeof file === 'string' ? file : URL.createObjectURL(file);

    video.onloadeddata = () => {
      let ctx = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      try {
        ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();

        if (blob) {
          canvas.toBlob(
            (blobData) => resolve(blobData as B extends true ? Blob : string),
            'image/jpeg',
            quality
          );
        } else {
          const url = canvas.toDataURL('image/jpeg', quality);
          return resolve(url as B extends true ? Blob : string);
        }
      } catch (e) {
        return resolve(null);
      }
    };
  });
};

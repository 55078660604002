import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import Editor from 'delightree-editor/dist/TiptapEditor';

import Loader from 'sub-components/Loader';

import { SOP_BY_ID_EXTERNAL, SopExternalDetail } from '../chapter.graphql';

import logo from '../../../../assets/images/logo.webp';

const ChapterPublicView: FC = () => {
  const params = useParams<{ chapterId: string }>();
  const [chapterData, setChapterData] = useState<SopExternalDetail>();
  const history = useHistory();

  const { loading } = useQuery<Record<'SopByIdExternal', SopExternalDetail>>(
    SOP_BY_ID_EXTERNAL,
    {
      fetchPolicy: 'network-only',
      variables: {
        eid: params.chapterId,
      },
      onCompleted: (response) => {
        if (response.SopByIdExternal.visibility === 'public') {
          setChapterData(response.SopByIdExternal);
        } else {
          history.replace('/');
        }
      },
    }
  );

  return (
    <Flex gap={5} flexDir='column' boxSize='full' bg='gray.100'>
      <Flex w='full' justify='flex-start' align='center' bg='white' p={4}>
        <Image src={logo} boxSize='50px' />
      </Flex>
      <Box flex={1} p={4} bg='white' borderRadius='7px' margin='32px 32px'>
        {loading ? (
          <Center h='full'>
            <Loader size='lg' />
          </Center>
        ) : (
          // @ts-ignore
          <Editor value={chapterData?.content?.[0]?.tiptap} readonly />
        )}
      </Box>
    </Flex>
  );
};

export default ChapterPublicView;

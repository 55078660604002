import React, { FC } from 'react';
import { Box, Center, Flex, Spacer } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { useUserEntity } from '../../../../hooks';

import PublishedHeader from './PublishedHeader';
import { IFormInput } from './editor.types';
import { useEditorContext } from './context';

interface IProps {}

const EditorHeader: FC<IProps> = () => {
  const { t } = useTranslation(['launcher']);
  const history = useHistory();
  const { initialising } = useEditorContext();

  const launchId = useWatch<IFormInput, 'eid'>({
    name: 'eid',
  });

  const published = useUserEntity((entity) => entity?.launcher?.published);

  const hideButton = initialising || launchId;

  return (
    <Flex gap={3}>
      {!hideButton && (
        <Center
          height='36px'
          alignSelf='start'
          onClick={history.goBack}
          cursor='pointer'
        >
          <ArrowBackIcon color='#000000' boxSize='25px' />
        </Center>
      )}
      <Box>
        <Box fontSize='24px' fontWeight='700' color='#272B30'>
          <Trans
            t={t}
            i18nKey={
              published
                ? 'launcher:editingTaskDetails'
                : 'launcher:addingTaskDetails'
            }
          />
        </Box>
        <Box fontSize='13px' fontWeight='400' color='#33383F'>
          Now add finer details to your tasks with ease.
        </Box>
      </Box>
      <Spacer />
      <PublishedHeader />
    </Flex>
  );
};

export default EditorHeader;

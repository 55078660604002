import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Center, Flex, List } from '@chakra-ui/react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { faChevronLeft, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import PrimaryButton from '../../../atoms/PrimaryButton';
import MemberItem from '../../../atoms/MemberItem';
import DashboardContainer from '../../../sub-components/DashboardContainer';
import { TRAINING_BY_ID_COMPLETE } from 'sop-commons/Query/Training';
import ManagerTrainingStatusList from './ManagerTrainingStatusList/ManagerTrainingStatusList';
import { userObj } from 'sop-commons/src/client';
import { TrainingData } from '../training.types';
import { useSearchParam } from '../../../hooks';
import Loader from '../../../ui-components/Loader';

const ManagerTrainingView: FC = () => {
  const { t } = useTranslation('training');
  const userObject = useReactiveVar(userObj);
  const history = useHistory();
  const { ref: pageReference } = useSearchParam<{ ref?: string }>();
  const params = useParams<{ id: string }>();
  const [trainingData, setTrainingData] = useState<any>(null);
  const [showPlayButton, setShowButton] = useState(false);

  const [trainingByIdData, { loading }] = useLazyQuery(
    TRAINING_BY_ID_COMPLETE,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data?.trainingById?.eid) {
          let isAnyQuizCard = false;
          let quizCardIds: any[] = [];
          let _item: TrainingData = JSON.parse(
            JSON.stringify(data?.trainingById)
          );
          _item?.trainingItems?.forEach((it) => {
            if (it?.cardType === 'quiz') {
              isAnyQuizCard = true;
              quizCardIds.push(it?.eid);
            }
            it.thumbnail =
              it?.thumbnail ||
              'https://sop-uploads-staging.s3.amazonaws.com/extras/1002.png';
            it.completed = false;
            let _overallProgress = [
              ...(_item?.overallProgress?.assignedUserProgress || []),
              ...(_item?.overallProgress?.nonAssignedUserProgress || []),
            ];
            _overallProgress?.map((progress) => {
              if (progress?.userId === userObject?.eid) {
                progress?.viewedContents?.forEach((content) => {
                  if (it?.uid === content?.uid) {
                    it.completed = true;
                  }
                });
              }
            });
          });
          // _item?.trainingItems?.push({
          //   eid: '',
          //   uid: '',
          //   type: 'finish',
          //   title: _item?.title,
          // });
          let isSupervisor = _item?.supervisors?.find(
            (supervisor) => supervisor === userObject?.eid
          );
          let isAssignedTo = _item?.assignedToUsers
            ?.filter((assigned) => assigned?.eid === userObject?.eid)
            ?.map((user) => user?.eid);
          if (
            (typeof isSupervisor === 'string' && isSupervisor) ||
            (Array.isArray(isSupervisor) && isSupervisor?.length > 0) ||
            (typeof isSupervisor === 'object' &&
              Object.keys(isSupervisor).length > 0)
          ) {
            setShowButton(false);
          }
          if (
            (typeof isAssignedTo === 'string' && isAssignedTo) ||
            (Array.isArray(isAssignedTo) && isAssignedTo?.length > 0) ||
            (typeof isAssignedTo === 'object' &&
              Object.keys(isAssignedTo).length > 0)
          ) {
            setShowButton(true);
          }
          console.log(_item);
          setTrainingData(_item);
          // if (shouldAllowSettingModalBooleanValue === 'parent') {
          //   setTrainingProgressModal(true);
          // }
        }
      },
    }
  );

  useEffect(() => {
    getTrainingById();
  }, []);

  const getTrainingById = () => {
    trainingByIdData({
      variables: {
        eid: params.id,
      },
    });
  };

  const onGoBack = () => {
    if (pageReference) {
      return history.goBack();
    }
    history.push('/training');
  };

  const trainingModalHandler = () => {
    return history.push('/training-play/' + params.id);
  };

  const getCount = () => {
    let count = 0;
    trainingData?.trainingItems?.map((content: any) => {
      if (content?.type === 'card' || content?.type === 'sop') {
        count++;
      }
    });
    return count;
  };

  return (
    <DashboardContainer>
      <div className='create-deck-container'>
        <Flex w='100%' justify='space-between' align='center'>
          <div>
            <div className='page-title-wrapper'>
              <div
                className='page-title-container'
                style={{ marginBottom: '0px' }}
              >
                {trainingData?.title}
              </div>
            </div>
            <div className='breadcrumb-container' onClick={onGoBack}>
              <FontAwesomeIcon
                icon={faChevronLeft as IconProp}
                // color={'#6f767e'}
              />

              <div style={{ marginLeft: '10px' }}>
                {pageReference ? t('view_back') : t('view_back_to')}
              </div>
            </div>
          </div>
          {showPlayButton && !loading && (
            <div style={{ display: 'flex' }}>
              <PrimaryButton
                title={t('view_play')!}
                size='lg'
                type='button'
                variant='solid'
                style={{
                  backgroundColor: '#2a85ff',
                  color: 'white',
                  width: '157.8px',
                }}
                leftIcon={<FontAwesomeIcon icon={faPlay as IconProp} />}
                onClick={trainingModalHandler}
              />
            </div>
          )}
        </Flex>

        <div className='edit-training-container'>
          {!loading ? (
            <>
              <div style={{ width: '100%' }}>
                <div className='training-search-container'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '16px',
                        height: '32px',
                        background: '#cabdff',
                        borderRadius: '4.00549px',
                      }}
                    ></div>
                    <div
                      style={{
                        fontWeight: '600',
                        lineHeight: '1.6',
                        fontSize: '20px',
                        marginLeft: '16px',
                        fontFamily: 'Inter',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                      }}
                    >
                      {t('view_items')}{' '}
                      {trainingData?.trainingItems?.length > 0 &&
                        `(${getCount()})`}
                    </div>
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <ManagerTrainingStatusList
                    trainingData={trainingData?.trainingItems}
                  />
                </div>
              </div>
              <div className='edit-training-right-side'>
                <div className='edit-training-right-side-title'>
                  {t('view_created_by')}
                </div>
                <List
                  spacing={3}
                  style={{
                    marginTop: '10px',
                    marginLeft: '2px',
                    width: '100%',
                  }}
                >
                  <MemberItem
                    name={trainingData?.createdBy?.name}
                    role={trainingData?.createdBy?.role}
                    authRole={trainingData?.createdBy?.authRole}
                    thumbnail={trainingData?.createdBy?.profilePic}
                    eid={trainingData?.createdBy?.eid}
                  />
                </List>

                <div
                  className='edit-training-right-side-title'
                  style={{ marginTop: '30px' }}
                >
                  {t('view_supervisors')}
                </div>
                <List
                  spacing={3}
                  style={{ marginTop: '10px', marginLeft: '2px' }}
                >
                  {trainingData?.supervisorsUsers?.map((supervisor) => {
                    return (
                      <MemberItem
                        key={supervisor?.eid}
                        name={supervisor?.name}
                        role={supervisor?.role}
                        authRole={supervisor?.authRole}
                        thumbnail={supervisor?.profilePic}
                        eid={supervisor?.eid}
                      />
                    );
                  })}
                </List>

                <div
                  className='edit-training-right-side-title'
                  style={{ marginTop: '30px' }}
                >
                  {t('view_assigned_to')}
                </div>
                <List
                  spacing={3}
                  style={{ marginTop: '10px', marginLeft: '2px' }}
                >
                  {trainingData?.assignedTo?.map((assignedTo: any) => {
                    return (
                      <MemberItem
                        key={assignedTo?.eid}
                        name={assignedTo?.name}
                        role={assignedTo?.role}
                        authRole={assignedTo?.authRole}
                        thumbnail={assignedTo?.profilePic}
                        eid={assignedTo?.eid}
                      />
                    );
                  })}
                </List>

                {trainingData?.assignedRoles?.length > 0 && (
                  <>
                    <div
                      className='edit-training-right-side-title'
                      style={{ marginTop: '30px' }}
                    >
                      {t('view_assigned_to_roles')}
                    </div>
                    <Flex gap='10px' wrap='wrap' mt='10px' ml='2px'>
                      {trainingData?.assignedRoles?.map((role) => {
                        return (
                          <Center
                            as='span'
                            key={role?.eid}
                            style={{
                              padding: '5px 10px',
                              backgroundColor: '#daf2e4',
                              borderRadius: '7px',
                              fontWeight: '600',
                              fontSize: '14px',
                            }}
                          >
                            {role?.name}
                          </Center>
                        );
                      })}
                    </Flex>
                  </>
                )}
                {trainingData?.assignedToLocations?.length > 0 && (
                  <>
                    <div
                      className='edit-training-right-side-title'
                      style={{ marginTop: '30px' }}
                    >
                      {t('view_assigned_to_locations')}
                    </div>
                    <div
                      style={{
                        marginTop: '10px',
                        marginLeft: '2px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: '10px',
                        rowGap: '10px',
                      }}
                    >
                      {trainingData?.assignedToLocations?.map((location) => {
                        return (
                          <Center
                            as='span'
                            key={location?.eid}
                            style={{
                              padding: '5px 10px',
                              backgroundColor: '#daf2e4',
                              borderRadius: '7px',
                              fontWeight: '600',
                              fontSize: '14px',
                            }}
                          >
                            {location?.name}
                          </Center>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <Center w='full' h='50vh'>
              <Loader />
            </Center>
          )}
        </div>
      </div>
    </DashboardContainer>
  );
};

export default ManagerTrainingView;

export const chatListStyle = (context) => {
  return {
    backgroundColor: `${context.theme.backgroundColor.white}`,
    zIndex: '1',
    width: '100%',
    flex: '1 1 0',
    order: '2',
    position: 'relative',
    background: '#FCFCFC',
    border: '1px solid #EFEFEF',
    borderRadius: '0px 0px 0px 0px',
    borderBottom: 'none',
  };
};

export const listWrapperStyle = () => {
  return {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'absolute',
    top: '0',
    transition: 'background .3s ease-out .1s',
    width: '100%',
    zIndex: '100',
    paddingTop: '16px',
  };
};

export const messageDateContainerStyle = () => {
  return {
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '35px',
  };
};

export const messageDateStyle = (context) => {
  return {
    padding: '8px 12px',
    borderRadius: '8px',
    border: 'solid 2px #efefef',
    backgroundColor: '#fcfcfc',
    fontSize: '13px',
    fontWeight: 'bold',
    color: '#1a1d1f',
    lineHeight: '1.85',
    letterSpacing: '-0.13px',
    // backgroundColor: `${context.theme.backgroundColor.secondary}`,
    // color: `${context.theme.color.primary}`,
    // borderRadius: '10px',
  };
};

export const decoratorMessageStyle = () => {
  return {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'absolute',
    width: '100%',
  };
};

export const decoratorMessageTxtStyle = (context) => {
  return {
    margin: '0',
    height: '36px',
    color: `${context.theme.color.secondary}`,
    fontSize: '20px!important',
    fontWeight: '600',
    lineHeight: '30px',
  };
};

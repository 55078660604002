import React, { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';

import FormInput from 'atoms/FormInput';
import { useTranslation } from 'react-i18next';
import { actionCardBackgroundColorList } from 'utils';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { CardType } from 'types';

interface ActionCardEditorProps {}

const ActionCardEditor: FC<ActionCardEditorProps> = () => {
  const { t } = useTranslation('card');
  const { control, setValue } = useFormContext<any>();

  console.log('CONTROL : ', control);

  setValue('cardType', {
    label: 'Action',
    transKey: 'card:cardType.action',
    value: CardType.Action,
  });

  return (
    <div>
      <TitleHeader
        title='Take action on'
        desc='You can ask your teams to complete a task in-person, for a more wholesome training!'
        titleStyle={{ marginTop: '30px' }}
        isRequired
      />
      <Controller
        name='title'
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={3} isInvalid={!!fieldState?.error}>
              <FormInput
                id='title'
                size='lg'
                {...field}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(event);
                  setValue('raw_content', event.target.value);
                }}
              />
              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
      <TitleHeader
        title='Action button'
        desc='You can ask your teams to complete a task in-person, for a more wholesome training!'
        titleStyle={{ marginTop: '30px' }}
        isRequired
      />
      <Controller
        name='actionButton'
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={3} isInvalid={!!fieldState?.error}>
              <FormInput
                id='actionButton'
                size='lg'
                {...field}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(event);
                }}
              />
              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </div>
  );
};

export default ActionCardEditor;

import { Flex, Image, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import EmptyImg from '../../assets/images/empty-state/task-graph-empty-state.svg';

const TaskCompletionEmpty: FC = () => {
  return (
    <Flex flexDir='column' align='center' justify='center' h='full' w='full'>
      <Image src={EmptyImg} w='180px' h='120px' />
      <Text color='#999999' fontWeight={400} fontSize='12px'>
        Not enough data
      </Text>
    </Flex>
  );
};

export default TaskCompletionEmpty;

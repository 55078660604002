import React, { FC } from 'react';
import { FormProvider as NativeProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validatior';

interface IProps {}

const FormProvider: FC<IProps> = ({ children }) => {
  const methods = useForm({
    resolver: yupResolver(schema as never),
    reValidateMode: 'onChange',
  });

  // @ts-ignore
  return <NativeProvider {...methods}>{children}</NativeProvider>;
};

export default FormProvider;

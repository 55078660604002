import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { cloneDeep } from '@apollo/client/utilities';
import { CometChat } from '@cometchat-pro/chat';
import { useTranslation } from 'react-i18next';

import { ChatGroupEntity } from '../../../../types';
import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';

import UpdateChannelForm from './UpdateChannelForm';
import EditMemberForm from './EditMemberForm';

interface IProps {
  initialState?: ChatGroupEntity;
  reFetchData?: (...args: any) => void;
  onGroupUpdated?: (group: CometChat.Group) => void;
}

type IUpdateChanel = (props: IProps) => void;

export const useUpdateChannel = (): IUpdateChanel => {
  const { t } = useTranslation(['common', 'setting']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const reFetchRef = useRef<any>();
  const stateRef = useRef<ChatGroupEntity>();
  const confirm = useConfirm();

  const apolloClient = useApolloClient();

  return useCallback(({ initialState, reFetchData, onGroupUpdated }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    reFetchRef.current = reFetchData;
    stateRef.current = cloneDeep(initialState);

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex justify='space-between'>
          <BoxHeader
            // @ts-ignore
            title={t('common:editValue', {
              value: stateRef.current!.name,
            })}
            fontSize='18px'
            color='#CABDFF'
            isTruncated
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <UpdateChannelForm
          initialValue={stateRef.current!}
          onGroupUpdated={callAll(onGroupUpdated, reFetchRef.current, () =>
            setTimeout(confirmRef.current!.destroy)
          )}
        />
      ),
      contentProps: {
        py: '12px',
        minWidth: '600px',
      },
      isCentered: true,
      footer: null,
    });
  }, []);
};

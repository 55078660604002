import { BaseModal } from 'modal';
import { ChapterItem } from '../chapter.types';
import { nFormatter } from '../../../utils/nFormatter';

export class ChapterModal extends BaseModal {
  private data: ChapterItem;
  constructor(props: ChapterItem) {
    super();
    this.data = props;
  }

  get eid(): string {
    return this.data.eid;
  }

  get isSelected() {
    return this.data.isSelected;
  }

  get title(): string {
    return this.data.title;
  }

  get category() {
    return this.data.category;
  }

  get categoryId() {
    return this.data.categoryId;
  }

  get thumbnail() {
    return this.data.thumbnail;
  }

  get createdBy() {
    return this.data.createdByUser;
  }

  get type() {
    return this.data.type;
  }

  get viewCount() {
    return nFormatter(this.data.viewCount);
  }

  get icon() {
    return this.data.icon;
  }

  get color() {
    return this.data.color;
  }

  get visibility() {
    return this.data.visibility;
  }

  get visibleTo() {
    //@ts-ignore
    return this.data.visibleTo;
  }

  get visibleToDetails() {
    //@ts-ignore
    return this.data.visibleToDetails;
  }

  get status() {
    return this.data.status;
  }

  get createdAt() {
    return this.data.createdAt;
  }

  get updatedAt() {
    return this.data.lastUpdated;
  }

  get trainings() {
    return this.data.trainings;
  }

  get sopType() {
    return this.data.sopType;
  }

  get fileType() {
    return this.data.files;
  }

  get mediaData() {
    return this.data.media;
  }

  get parentFolderId() {
    return this.data.parentFolderId;
  }
}

import moment from 'moment/moment';
import cronTime from 'cron-time-generator';
import { IFormInput } from './task.types';

const L = 'L' as unknown as number;

export const getRepeatString = (values: IFormInput): string => {
  const dueT = moment(values.dueTime);
  if (values.taskRunFor === 'once') {
    const dateD = moment(values.taskDueDate);

    switch (values.taskDue) {
      case 'nextWeek':
        // eslint-disable-next-line no-case-declarations
        let NextMon = moment().startOf('isoWeek').add(1, 'week').day('monday');

        return cronTime.everyYearIn(
          NextMon.get('month') + 1,
          NextMon.get('date'),
          dueT.get('hour'),
          dueT.get('minute')
        );
      case 'endOfMonth':
        return cronTime.everyMonthOn(L, 23, 59);
      case 'endOfYear':
        return cronTime.everyYearIn(12, 31, 23, 59);
      case 'date':
        return cronTime.everyYearIn(
          dateD.get('month') + 1,
          dateD.get('date'),
          dueT.get('hour'),
          dueT.get('minute')
        );
      default:
        return '';
    }
  }

  switch (values.repeatCycle) {
    case 'daily':
      return cronTime.everyDayAt(dueT.get('hour'), dueT.get('minute'));
    case 'weekly':
      return cronTime.onSpecificDaysAt(
        values.weekDays as string[],
        dueT.get('hour'),
        dueT.get('minute')
      );
    case 'monthly':
      return cronTime.everyMonthOn(
        values.repeatMonthly === 'lastDay'
          ? L
          : moment(values.repeatMonthly).get('date'),
        dueT.get('hour'),
        dueT.get('minute')
      );
    case 'yearly':
      return cronTime.everyYearIn(
        moment(values.repeatYearly).get('month') + 1,
        moment(values.repeatYearly).get('date'),
        dueT.get('hour'),
        dueT.get('minute')
      );
    case 'days':
      return cronTime.every(+values.noOfDays!).days(0, 0);
  }

  return '';
};

import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../../ui-components';

import { InfoFormValues } from '../ProfileInfoForm';
import { useDeleteConfirm } from '../../../../Teams/MemberDetails';
import {
  DELETE_MEMBER,
  SOFT_DELETE_MEMBER,
} from '../../../../Teams/InvitedMember/update-invite.graphql';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  userId: string;
  close?: Function;
  onDeleted?: (...args: any[]) => any | PromiseLike<any>;
}

const DeleteAccount: FC<IProps> = ({ userId, onDeleted, close }) => {
  const { t } = useTranslation(['common', 'profile']);
  const { getValues, watch } = useFormContext<InfoFormValues>();
  const toast = useToast({
    duration: 2000,
    position: 'top-right',
    isClosable: true,
  });

  const isDeleted = watch('isDeleted');

  const deleteConfirm = useDeleteConfirm();

  /**
   * For hard delete: `deleteUserPermanently`
   * For soft delete: `SoftDeleteUser`
   */
  const [hardDeleteUser] = useMutation(DELETE_MEMBER, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: 'Member will be deleted in some time',
      });
      onDeleted?.();
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('profile:memberDeleteError'),
      });
    },
  });

  const [softDeleteUser] = useMutation(SOFT_DELETE_MEMBER, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: t('common:success'),
        description: t('profile:memberDeleteSuccess'),
      });
      onDeleted?.();
    },
    onError: () => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('profile:memberDeleteError'),
      });
    },
  });

  const _onDeleteClick = async () => {
    return new Promise((resolve, reject) => {
      deleteConfirm({
        name: getValues('firstName').concat(' ', getValues('lastName')).trim(),
        onOkPress: async () => {
          deployEvent(AmplitudeEventNames.DELETE_MEMBER);
          const res =
            getValues('status') === 'active'
              ? await softDeleteUser({
                  variables: {
                    eid: userId,
                  },
                })
              : await hardDeleteUser({
                  variables: {
                    eid: userId,
                  },
                });
          if (res.errors) {
            return Promise.reject('Member deletion failed');
          }
          return resolve(true);
        },
        onCancelPress: () => reject(null),
      });
    });
  };

  return (
    <ActionButton
      minW='140px'
      mt={6}
      colorScheme='red'
      fontSize='15px'
      fontWeight='600'
      px={4}
      // isDisabled={isDeleted}
      actionFn={_onDeleteClick}
      close={close}
    >
      {t('common:delete')}
    </ActionButton>
  );
};

export default DeleteAccount;

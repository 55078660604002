import React, { FC, useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Authorize, AuthRole } from 'authorization';
import PrimaryButton from 'atoms/PrimaryButton';
import { ADD_LOCATION } from 'appRoutes';

import { TeamTabType, useActiveTeamTab } from './useActiveTeamTab';
import eventBus from './locations/AddLocationModal/eventBus';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const TitleTransKey: Record<
  NonNullable<TeamTabType>,
  'sidebar:jobs' | 'sidebar:locations' | 'sidebar:members'
> = {
  jobs: 'sidebar:jobs',
  locations: 'sidebar:locations',
  members: 'sidebar:members',
};

const ButtonTransKey: Record<
  NonNullable<TeamTabType>,
  'team:addNewMember' | 'team:addNewLocation' | 'team:addNewJob'
> = {
  jobs: 'team:addNewJob',
  locations: 'team:addNewLocation',
  members: 'team:addNewMember',
};

interface IProps {}

const TeamHeader: FC<IProps> = () => {
  const history = useHistory();
  const { t } = useTranslation(['sidebar', 'team']);
  const activeTeamTab = useActiveTeamTab();

  const onClick = useCallback(() => {
    switch (activeTeamTab) {
      case 'members':
        deployEvent(AmplitudeEventNames.ADD_NEW_MEMBER, {
          from_where: 'teams/members Header',
          add_member_funnel_id: ' ',
        });
        eventBus.emit('openAddMemberModal');
        return;
      case 'locations':
        history.push(ADD_LOCATION);
        // eventBus.emit('openAddLocationModal');
        return;
      case 'jobs':
        deployEvent(AmplitudeEventNames.JOB_CREATE_BUTTON, {
          from_where: 'teams/header',
          add_job_funnel_id: '',
        });
        eventBus.emit('openAddJobModal');
    }
  }, [activeTeamTab]);

  return (
    <Flex align='center' justify='space-between' mb='20px'>
      <Box fontSize='28px' fontWeight='700' color='#272B30'>
        {t(TitleTransKey[activeTeamTab as NonNullable<TeamTabType>])}
      </Box>
      <Authorize
        permittedRoles={
          activeTeamTab === 'members'
            ? [AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.MANAGER]
            : [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]
        }
        permittedFor='user'
      >
        <PrimaryButton
          leftIcon={<AddIcon />}
          title={t(ButtonTransKey[activeTeamTab as NonNullable<TeamTabType>])}
          width={'fit-content'}
          variant='outline'
          colorScheme='blue'
          onClick={onClick}
        />
      </Authorize>
    </Flex>
  );
};

TeamHeader.displayName = 'pages/Teams/TeamHeader';

export default TeamHeader;

import React, { FC } from 'react';
import { Box, Flex, Image, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IProps {
  title: string;
  image: string;
  imageLight: string;
  description?: string;
  onClick?: () => void;
}

const QuestionTypeOption: FC<IProps> = ({
  image,
  imageLight,
  title,
  description,
  onClick,
}) => {
  const { t } = useTranslation('form', { keyPrefix: 'questionType' });

  return (
    <Tooltip
      hasArrow
      /*// @ts-ignore*/
      label={t(description || title)}
      placement='top'
      borderRadius='6'
      px={4}
      py={2}
      maxW='180px'
      fontSize='12px'
      fontWeight='500'
      offset={[0, 20]}
      backgroundColor='blackAlpha.900'
      // backgroundColor='blackAlpha.800'
    >
      <Flex
        direction={'column'}
        align='center'
        cursor='pointer'
        onClick={onClick}
        _hover={{
          bg: 'blackAlpha.500',
        }}
        p={2}
        borderRadius='8px'
      >
        <Image src={imageLight} maxW='27px' width='14px' />
        <Box fontSize='12px' pt={1} whiteSpace='nowrap' color='white'>
          {/*// @ts-ignore*/}
          {t(title)}
        </Box>
      </Flex>
    </Tooltip>
  );
};

export default QuestionTypeOption;

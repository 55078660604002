import {
  Col,
  Row,
  Typography,
  Layout,
  Button,
  message,
  Upload,
  Divider,
} from 'antd';
import { useEffect, useState } from 'react';
import CustomScroll from 'react-custom-scroll';
import { OnboardingMenu } from '../ui-components/OnboardingMenu/OnboardingMenu';
import { TextInput } from '../ui-components/TextInput';
import {
  CloudUploadOutlined,
  LinkOutlined,
  CloseCircleFilled,
  PaperClipOutlined,
  DeleteFilled,
  DeleteTwoTone,
} from '@ant-design/icons';
import { ReactComponent as PdfSvg } from '../assets/images/pdf.svg';
import { ReactComponent as DocSvg } from '../assets/images/doc.svg';
import { ReactComponent as CsvSvg } from '../assets/images/csv.svg';
import { ReactComponent as DeleteSvg } from '../assets/images/Delete.svg';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import axios from 'axios';
import { UploadChangeParam } from 'antd/lib/upload';
import { Header } from '../ui-components/Header';
import './AddSop/AddSop.css';
import { useHistory } from 'react-router';
const { Title, Text } = Typography;
import { client, userObj } from 'sop-commons/src/client/clientFactory';
import {
  faCheckSquare,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_USER } from './Login/login.graphql';

const GET_SOP_REQUESTS = gql`
  query Query($page: Int, $perPage: Int) {
    SopRequestsPagination(page: $page, perPage: $perPage) {
      items {
        chapter
        files {
          filename
          mimetype
          uploadedBy
          uploadedAt
          url
          _id
        }
        links
        status
        category
        entity {
          name
          address
          profilePic
          roles {
            status
            name
          }
          bundles {
            name
            status
          }
          category {
            name
            status
          }
          updatedAt
          createdAt
          eid
        }
        updatedAt
        createdAt
        eid
      }
      count
    }
  }
`;

const REMOVE_SOP = gql`
  mutation RemoveSopRequestsMutation($eid: String!) {
    removeSopRequests(eid: $eid) {
      succeed
    }
  }
`;
interface file {
  name?: string;
  type?: string;
}
interface sop {
  category?: string;
  chapter?: string;
  files?: object[];
  links?: string[];
}

export const UPDATE_USER = gql`
  mutation UpdateUserMutation($input: UserUpdateInput) {
    updateUser(input: $input) {
      onboarded
      account {
        onboardingStage
      }
    }
  }
`;

export const UPDATE_ENTITY_ONBOARDING_FLAG = gql`
  mutation UpdateEntity($input: UpdateEntityInput) {
    updateEntityById(input: $input) {
      name
      onboarded
    }
  }
`;

interface Props {
  isOnboarded?: boolean;
}

export const SopList = ({ isOnboarded }: Props) => {
  const history = useHistory();
  const [subMenuStep, setSubMenuStep] = useState('0');
  const [chapterName, setChapterName] = useState('');
  const [fileLinks, setFileLinks] = useState<string[]>(['placeholder']);
  const [signedUrlData, setSignedUrlData] = useState<any>([]);
  const [file, setFile] = useState<any>();
  const [fileArray, setFileArray] = useState<any>([]);
  const [sopArray, setSopArray] = useState([] as object[]);
  const [currentCategory, setCurrentCategory] =
    useState<string>('Company Policies');
  const [menuCategoryData, setMenuCategoryData] =
    useState<any>('Company Policies');
  const [currentCategorySop, setCurrentCategorySop] = useState<any>([]);
  const [menuProgressPercent, setMenuProgressPercent] = useState<any>(50);
  const userObject = useReactiveVar<any>(userObj);
  const [updateEntityOnboardingStatus] = useMutation(
    UPDATE_ENTITY_ONBOARDING_FLAG
  );
  const [updateOnboardingStatus, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      const newUserObj = { ...userObject };
      newUserObj.onboarded = true;
      newUserObj.onboardedAt = new Date();
      userObj({ ...newUserObj });
      updateEntityOnboardingStatus({
        variables: {
          input: {
            eid: userObject?.entity?.eid,
            onboarded: true,
          },
        },
      });
      client.writeQuery({
        query: GET_USER,
        data: {
          user: { ...newUserObj, onboarded: true },
        },
      });
      history.push('/');
    },
  });

  const [
    getSopRequests,
    { loading: getsopLoading, error: getSopError, data: getSopData },
  ] = useLazyQuery(GET_SOP_REQUESTS, {
    onCompleted: (data) => {
      setSopArray(data?.SopRequestsPagination?.items);
    },
  });

  const [removeSop] = useMutation(REMOVE_SOP, {
    refetchQueries: [
      {
        query: GET_SOP_REQUESTS,
        variables: {
          page: 1,
          perPage: 20,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    getSopRequests({
      variables: {
        page: 1,
        perPage: 20,
      },
    });
  }, [getSopData]);

  useEffect(() => {
    const tempSopArray = getSopData?.SopRequestsPagination?.items?.filter(
      (sop: any, index: number) => sop?.category === currentCategory
    );
    setCurrentCategorySop(tempSopArray);
  }, [currentCategory, sopArray, getSopData]);

  //   const onInputChange = (e: any) => {
  //     setFile(e?.target?.files[0]);
  //     const fileName = e.target.files[0]?.name;
  //     uploadFile({
  //       variables: {
  //         getSignedUrlInput: [{ filename: fileName, mimetype: 'image/jpeg' }],
  //       },
  //     });
  //   };
  const renderFileIcon = (fileType: any) => {
    switch (fileType) {
      case 'application/pdf':
        return <PdfSvg style={{ fontSize: 50, alignSelf: 'center' }} />;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <DocSvg style={{ fontSize: 50 }} />;
      case 'text/csv':
        return <CsvSvg style={{ fontSize: 50 }} />;
      default:
        return <DocSvg style={{ fontSize: 50 }} />;
    }
  };

  const onClickMenuItem = (menuItem: any, event: any) => {
    setSubMenuStep(event?.key);
    setCurrentCategory(menuItem?.name);
  };

  return (
    // <CustomScroll>
    <Layout
      style={{
        backgroundColor: '#fbf7f0',
        height: '100%',
        minHeight: '100vh',
        overflowY: 'auto',
      }}
    >
      <Header isOnboarded={isOnboarded} disableSearch={!isOnboarded} />
      <div
        className='logo'
        style={{ marginBlock: 30, marginRight: 100, marginLeft: 100 }}
      />
      <Row
        style={{ marginBottom: 20 }}
        justify='center'
        className='app-main-container'
      >
        <Col
          span={5}
          style={{
            borderRadius: 10,
            maxHeight: '100%',
            minWidth: 290,
            maxWidth: 350.98,
          }}
        >
          <OnboardingMenu
            sopRequestCategoryName={getSopData?.SopRequestsPagination?.items?.map(
              (sopItem: any, index: number) => sopItem?.category
            )}
            getMenuItems={(data: any) => {
              setMenuCategoryData(data);
            }}
            menuProgressBarPercent={menuProgressPercent}
            menuStyle={{
              fontSize: 16,
              borderRadius: 15,
              backgroundColor: '#FFFFFF',
              minHeight: 533.5,
            }}
            handleSelectCallback={onClickMenuItem}
            menuDefaultSelectedKeys={[]}
            currentSelectedKeys={[]}
            menuDefaultOpenKeys={[]}
            menuMode='inline'
            isOnboarded={isOnboarded}
            isRemoveContinue
          />
        </Col>
        <Col span={18} style={{ maxWidth: 1360, marginLeft: 20 }}>
          <Col
            style={{
              justifyContent: 'flex-end',
            }}
          >
            {getSopData?.SopRequestsPagination?.items?.map(
              (sop: any, index: number) => {
                return (
                  <Row
                    key={index}
                    style={{
                      alignContent: 'center',
                      height: 62,
                      justifyContent: 'space-between',
                      marginBottom: 16,
                      backgroundColor: 'white',
                      padding: 20,
                      border: 'solid',
                      borderWidth: 1,
                      borderColor: '#dddddd',
                      borderRadius: 10,
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FontAwesomeIcon
                        onClick={() => {
                          history.push({
                            pathname: '/onboarding/add-sop',
                            state: { sop: sop, isEditRequest: true },
                          });
                        }}
                        style={{
                          color: '#333b4f',
                          width: 25,
                          height: 15,
                          marginRight: 15,
                        }}
                        icon={faChevronDown}
                      />
                      <FontAwesomeIcon
                        style={{
                          color: '#3ba578',
                          width: 22,
                          height: 22,
                          marginRight: 22,
                        }}
                        icon={faCheckSquare}
                      />
                      <Text
                        style={{
                          fontFamily: 'Open Sans',
                          fontWeight: 700,
                          fontSize: 18,
                          alignSelf: 'center',
                        }}
                      >
                        {sop?.chapter}
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Open Sans',
                          fontWeight: 500,
                          fontSize: 18,
                          color: '#999999',
                          alignSelf: 'center',
                          marginInline: 8,
                        }}
                      >
                        in
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Open Sans',
                          fontWeight: 500,
                          fontSize: 18,
                          alignSelf: 'center',
                        }}
                      >
                        {sop?.category}
                      </Text>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignSelf: 'center',
                      }}
                    >
                      {sop?.links?.length > 0 && (
                        <>
                          <LinkOutlined
                            style={{
                              marginLeft: 20,
                              marginRight: 5,
                              alignSelf: 'center',
                            }}
                          />
                          <Text style={{ alignSelf: 'center' }}>
                            {sop?.links?.length}
                          </Text>
                        </>
                      )}
                      {!!sop?.files?.length && (
                        <>
                          <PaperClipOutlined
                            style={{
                              marginLeft: 20,
                              marginRight: 5,
                              alignSelf: 'center',
                            }}
                          />
                          <Text style={{ alignSelf: 'center' }}>
                            {sop?.files?.length}
                          </Text>
                        </>
                      )}
                      <DeleteTwoTone
                        onClick={() => {
                          removeSop({
                            variables: {
                              eid: sop?.eid,
                            },
                          });
                        }}
                        twoToneColor={'#BBBBBB'}
                        style={{
                          fontSize: 20,
                          marginLeft: 20,
                          alignSelf: 'center',
                          color: '#BBBBBB',
                        }}
                      />
                    </div>
                  </Row>
                );
              }
            )}
            {getSopData?.SopRequestsPagination?.items?.length > 0 && (
              <Button
                onClick={() => {
                  if (isOnboarded) {
                    history.push('/');
                  } else {
                    updateOnboardingStatus({
                      variables: {
                        input: {
                          onboarded: true,
                        },
                      },
                    });
                  }
                }}
                type='primary'
                size='large'
                style={{
                  height: 46,
                  backgroundColor: '#333B4F',
                  color: 'white',
                  marginRight: 20,
                  borderRadius: 8,
                  marginBottom: 30,
                  borderWidth: 0,
                  fontWeight: 'bold',
                }}
                block={true}
              >
                {isOnboarded && <>Go To Dashboard</>}
                {!isOnboarded && <>Finish</>}
              </Button>
            )}
          </Col>
        </Col>
      </Row>
    </Layout>
    // </CustomScroll>
  );
};

import React, { FC, Fragment } from 'react';
import { Skeleton } from '@chakra-ui/react';

interface IProps {
  isLoading: boolean;
}

const TemplateCatSkeleton: FC<IProps> = ({ isLoading, children }) => {
  if (!isLoading) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <>
      <Skeleton
        height='38.5px'
        startColor='#efefef'
        endColor='red'
        borderRadius='8px'
      />
      <Skeleton
        height='38.5px'
        startColor='#efefef'
        endColor='red'
        borderRadius='8px'
      />
      <Skeleton
        height='38.5px'
        startColor='#efefef'
        endColor='red'
        borderRadius='8px'
      />
    </>
  );
};

export default TemplateCatSkeleton;

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserData } from 'sop-commons/src/client';
import { SelectOption } from 'atoms';
import { SortingTable } from '../../../../sub-components/ChakraTable/SortingTable';
import EmptyState from '../../../../sub-components/EmptyState';
import TrainingPathComponent from '../../../../sub-components/AddNewPathModal/TrainingModal';
import { TrainingPathModal } from '../modal';
import ChangeLog from '../../../TrainingDraft/ChangeLog';
import LocationPseudoMember from '../../LocationPseudoMemberModal';
import { TrainingData } from '../../training.types';
import { useServiceLayer } from './useServiceLayer';
import { useModelLayer } from './useModelLayer';

type PathShowValue = 'all' | 'supervisedByMe' | 'createdByMe';

interface IProps {
  showValue?: SelectOption<PathShowValue>;
  query?: string;
}

const TrainingPathList: FC<IProps> = ({ showValue, query }) => {
  const { t } = useTranslation(['common', 'training']);
  const [sortBy, setSortBy] = useState<string>('CREATEDAT_DESC');
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedTraining, setSelectedTraining] = useState<TrainingPathModal>();

  const [trainingPlayData, setTrainingPlayData] = useState<TrainingData>();
  const [pseudoTrainingData, setPseudoTrainingData] = useState({
    show: false,
    data: {} as UserData,
    item: {} as TrainingPathModal,
  });
  const {
    currentPage,
    dataList,
    hasNextPage,
    itemCount,
    loading,
    deleteTraining,
    updateTraining,
  } = useServiceLayer(
    query,
    selectedPage,
    sortBy,
    showValue,
    setTrainingPlayData
  );

  const updateSelectedTraining = (item: TrainingPathModal | undefined) => {
    setSelectedTraining(item);
  };

  const deleteTrainingHandler = (eid: string) => {
    deleteTraining({
      variables: {
        eid: eid,
      },
    });
  };

  const updateTrainingHandler = (
    eid: string,
    title: string,
    status: string
  ) => {
    updateTraining({
      variables: {
        input: {
          eid: eid,
          title: title,
          status: status === 'active' ? 'inactive' : 'active',
        },
      },
    });
  };

  const { columns, onPageChangeHandler, stateReducer } = useModelLayer(
    deleteTrainingHandler,
    setPseudoTrainingData,
    setSelectedPage,
    setSelectedTraining,
    setSortBy,
    updateTrainingHandler
  );

  return (
    <div style={{ marginBottom: '2rem' }}>
      <SortingTable
        background=''
        colorScheme='blue'
        customWrapperStyles={{ marginTop: '1rem' }}
        emptyData={{
          content: (
            <EmptyState
              image='TrainingDraft'
              title={t('training:trainingPathEmpty')}
              description={t('training:trainingPathEmptyDesc') as string}
            />
          ),
        }}
        page={currentPage || 1}
        onPageChange={onPageChangeHandler}
        columns={columns}
        data={dataList}
        isLoading={loading}
        totalRegisters={itemCount}
        stateReducer={stateReducer}
        options={{
          autoResetSortBy: false,
        }}
        isResponsive
        isHidePagination={
          !((currentPage === 1 && hasNextPage) || (currentPage || 1) > 1)
        }
      />

      {trainingPlayData?.eid && (
        <TrainingPathComponent
          open={!!trainingPlayData?.eid}
          onClose={() => setTrainingPlayData(undefined)}
          selectedTrainingData={trainingPlayData}
        />
      )}
      {selectedTraining && (
        <ChangeLog
          isReadOnly
          open={!!selectedTraining?.eid}
          onClose={(type) => updateSelectedTraining(undefined)}
          selectedDraftData={selectedTraining.jsonData}
        />
      )}
      <LocationPseudoMember
        isOpen={pseudoTrainingData.show}
        onClose={() =>
          setPseudoTrainingData({
            ...pseudoTrainingData,
            show: false,
          })
        }
        locationId={pseudoTrainingData.data.eid}
        selectedTrainingData={pseudoTrainingData.item}
      />
    </div>
  );
};

export default TrainingPathList;

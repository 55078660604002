import React, { FC, ReactNode } from 'react';
import moment from 'moment';
import { AbsoluteCenter, Box, Button, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import InviteUserModal from '../InviteUserModal';
import { NewActivityList } from './activity-helper-new';

const SampleData: NewActivityList[] = [
  {
    group: moment().format('YYYY-MM-DD'),
    data: [
      {
        transPrefix: 'feed',
        transKey: 'trainingCompleted',
        createdAt: moment().subtract(2, 'minute'),
        eid: '01',
        nameUser: {
          name: 'Lindsey Stroud',
          profilePic: '',
        } as never,
        training: {
          title: 'Fire safety',
        },
      } as never,
      {
        transPrefix: 'feed',
        transKey: 'trainingCompleted',
        createdAt: moment().subtract(21, 'minute'),
        eid: '02',
        nameUser: {
          name: 'Filomena',
          profilePic: '',
        } as never,
        training: {
          title: 'Healthcare & Precautions',
        },
      } as never,
      {
        transPrefix: 'feed',
        transKey: 'trainingCompleted',
        createdAt: moment().subtract(2, 'hour'),
        eid: '03',
        nameUser: {
          name: 'Harris Ellie',
          profilePic: '',
        } as never,
        training: {
          title: 'Healthcare & Precautions',
        },
      } as never,
    ],
  },
  {
    group: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    data: [
      {
        transPrefix: 'feed',
        transKey: 'trainingCompleted',
        createdAt: moment().subtract(1, 'day'),
        eid: '04',
        nameUser: {
          name: 'Jessika Dora',
          profilePic: '',
        } as never,
        training: {
          title: 'Covid Guidelines',
        },
        dateStr: moment().subtract(1, 'day'),
      } as never,
      {
        transPrefix: 'feed',
        transKey: 'trainingCompleted',
        createdAt: moment().subtract(1, 'day'),
        eid: '05',
        nameUser: {
          name: 'Filomena',
          profilePic: '',
        } as never,
        training: {
          title: 'Covid Guidelines',
        },
        dateStr: moment().subtract(1, 'day'),
      } as never,
    ],
  },
];

interface IProps {
  daysAgoHandler: (value: NewActivityList) => ReactNode;
  feedDataHandler: (value: NewActivityList) => ReactNode;
}

const InviteUserForActivity: FC<IProps> = ({
  daysAgoHandler,
  feedDataHandler,
}) => {
  const { t } = useTranslation('activity');
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <Box pos='relative' height='100%' overflowY='hidden'>
      <Box opacity='0.1' pointerEvents='none' userSelect='none'>
        {SampleData.map((value, index) => {
          return (
            <div key={index}>
              {daysAgoHandler(value)}
              {feedDataHandler(value)}
            </div>
          );
        })}
      </Box>
      <AbsoluteCenter>
        <Button
          leftIcon={
            <FontAwesomeIcon
              icon={faPlus as IconProp}
              style={{ fontSize: 16 }}
              color={'#2A85FF'}
            />
          }
          fontSize='15px'
          colorScheme='blue'
          variant='outline'
          bg='white'
          onClick={onToggle}
        >
          {t('invite_user')}
        </Button>
      </AbsoluteCenter>
      {isOpen && <InviteUserModal open={isOpen} onClose={onClose} />}
    </Box>
  );
};

export default InviteUserForActivity;

import React, { FC } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { IconsPlaceholder } from '../../../../../../ui-components/Search';
import { LaunchTaskSteps } from '../../../../../../pages/Home/components/LOInProgress/types';

interface IProps {
  step: LaunchTaskSteps;
}

const StepIcon: FC<IProps> = ({ step }) => {
  return (
    <>
      {step?.type === 'form' ? (
        <Image src={IconsPlaceholder['form']} boxSize='30px' minW='30px' />
      ) : step?.type !== 'text' ? (
        <Image src={IconsPlaceholder[step?.type]} boxSize='30px' minW='30px' />
      ) : (
        <Box boxSize='30px' minW='30px' />
      )}
    </>
  );
};

export default StepIcon;

import { gql } from '@apollo/client';
import { CardsEntity } from 'pages/Handbook/handbook.types';
import { BaseCardEntity, PageInfo } from 'types';

export interface ChapterVariable {
  page: number;
  perPage?: number;
  sort?: string;
  filter?: Record<string, string | boolean | number>;
}

export interface ChapterResponse {
  SopPagination?: {
    count?: number;
    items?: BaseCardEntity[];
    pageInfo?: PageInfo;
  };
}

export const GET_CHAPTERS_LIST_FOR_TRAINING = gql`
  query SopPagination(
    $page: Int
    $perPage: Int
    $sort: SortFindManySopInput
    $filter: FilterFindManySopInput
  ) {
    SopPagination(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        icon
        sopType
        media {
          mediaUrl
        }
        files {
          mimetype
          name
          url
        }
        thumbnail
        cardView {
          backgroundColor
          cardType
          category
          content
          createdAt
          createdBy
          createdByUser {
            eid
            email
            name
          }
          defaultLanguage
          eid
          entityId
          formId
          form {
            category
            contributorUsers {
              eid
              email
              name
            }
            contributors
            createdAt
            createdBy {
              eid
              email
              name
            }
            description
            eid
            entityId
            isTemplate
            lastUpdated
            lastUpdatedBy {
              eid
              email
              name
            }
            questions
            responseCount
            status
            thumbnail
            title
            updatedAt
          }
          isSmart
          lastUpdated
          layout
          lastViewed
          raw_content
          smartPageEnabled
          status
          subTitle
          thumbnail
          title
          trainingOnly
          translations
          updatedAt
          views
        }
        cards {
          backgroundColor
          cardType
          category
          content
          createdAt
          createdBy
          createdByUser {
            eid
            email
            name
          }
          defaultLanguage
          eid
          entityId
          formId
          form {
            category
            contributorUsers {
              eid
              email
              name
            }
            contributors
            createdAt
            createdBy {
              eid
              email
              name
            }
            description
            eid
            entityId
            isTemplate
            lastUpdated
            lastUpdatedBy {
              eid
              email
              name
            }
            questions
            responseCount
            status
            thumbnail
            title
            updatedAt
          }
          isSmart
          lastUpdated
          lastViewed
          layout
          raw_content
          smartPageEnabled
          status
          subTitle
          thumbnail
          title
          trainingOnly
          translations
          updatedAt
          views
        }
        title
        entityId
        status
        category
        thumbnail
        visibility
        content
        raw_content
        smartPageEnabled
        isMultiPage
        eid
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

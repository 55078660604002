import React, { FC } from 'react';
import { Box, Divider, Flex, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment/moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowsMaximize } from '@fortawesome/pro-light-svg-icons';

import { TaskItemEntity } from './task-item.types';
import { IconImage } from '../../../../../ui-components';

import todoStepIcon from '../../../../../assets/images/task-check-circle-blue.svg';

interface IProps {
  item: TaskItemEntity;
  onClick?: () => void;
  createdBy?: {
    name: string;
  };
}

const TaskStepItem: FC<IProps> = ({ item, onClick, createdBy }) => {
  const creator = item?.type === 'todo' ? createdBy?.name : item.createdBy;

  return (
    <>
      <Flex
        gap='10px'
        cursor={item?.type === 'todo' ? undefined : 'pointer'}
        onClick={item?.type === 'todo' ? undefined : onClick}
        sx={{
          '.eye-button-selected': {
            visibility: 'visible',
          },
        }}
        _hover={{
          '.eye-button': {
            visibility: 'visible',
          },
        }}
      >
        <IconImage
          icon={item?.icon}
          thumbnail={item?.type === 'todo' ? todoStepIcon : item.thumbnail}
          name={item.title}
          files={item.files}
          boxSize={48}
          borderRadius='6px'
        />

        <Flex flexDir='column' flex={1}>
          <Text fontWeight={600} fontSize='15px'>
            {item?.title}
          </Text>
          <Flex
            fontWeight={400}
            fontSize='12px'
            color='#6F767E'
            align='center'
            gap='5px'
          >
            <Text>Added on {moment(item?.dateAdded)?.format('DD MMM')}</Text>
            <Text>&bull;</Text>
            <Text isTruncated maxW='200px'>
              Created by {creator}
            </Text>
          </Flex>
        </Flex>

        <Box
          alignSelf='center'
          className='eye-button'
          visibility='hidden'
          cursor='pointer'
          minW='20px'
        >
          {item?.type === 'todo' ? null : (
            <Tooltip
              hasArrow
              label={`View ${
                item.type === 'chapter'
                  ? 'chapter'
                  : item.type === 'form'
                  ? 'form'
                  : 'chapter/form'
              } response`}
              placement='top'
            >
              <span>
                .
                <FontAwesomeIcon
                  icon={faArrowsMaximize as IconProp}
                  color='#504F54'
                  size='lg'
                />
              </span>
            </Tooltip>
          )}
        </Box>
      </Flex>
      <Box py='10px'>
        <Divider />
      </Box>
    </>
  );
};

export default TaskStepItem;

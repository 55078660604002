import React, { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { AssignedTrainingsEntity } from '../types';
import Image from '../../../../ui-components/Image';

import { getImage } from '../../../../utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 25px;

  .avatar-list {
    position: relative;
    display: flex;
    width: max-content;

    .training-item {
      border-radius: 7px;
      padding: 5px 10px;

      background: rgba(255, 188, 153, 0.4);
      max-width: 150px;
      overflow: hidden;
    }

    img {
      width: 25px;
      height: 25px;
      border-radius: 5px;
    }
  }

  .more-button {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
    border-radius: 7px;
    border: 1px solid #dddddd;
    padding: 5px 10px;
  }
`;

interface IProps {
  value: AssignedTrainingsEntity[];
  background?: string;
}

const MoreTrainingCell: FC<IProps> = ({ value, background }) => {
  const { t } = useTranslation('training');

  const [data, moreTrainings] = useMemo(() => {
    return [value.slice(0, 1), value.slice(1)];
  }, [value]);

  return (
    <Flex pl={'20px'} pr={'20px'}>
      <Wrapper>
        <Tooltip
          label={data.map((it) => it.title)?.join(', ')}
          hasArrow
          p='8px 12px'
          borderRadius='8px'
        >
          <div className='avatar-list'>
            {data.map((training) => {
              return (
                <Flex
                  className='training-item'
                  key={training.eid}
                  gap='7px'
                  align='center'
                  bg={background}
                >
                  <Image
                    width={25}
                    height={25}
                    src={getImage(training.profilePic, training.title)}
                  />
                  <Box
                    overflow='hidden'
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                    maxW='200px'
                    fontWeight='600'
                    fontSize='12px'
                  >
                    {training.title}
                  </Box>
                </Flex>
              );
            })}
          </div>
        </Tooltip>
        {moreTrainings.length > 0 && (
          <Tooltip
            label={moreTrainings.map((it) => it.title)?.join(', ')}
            hasArrow
            p='8px 12px'
            borderRadius='8px'
          >
            <div className='more-button'>
              <Trans
                t={t}
                i18nKey='count_more'
                values={{
                  value: moreTrainings.length,
                }}
              />
            </div>
          </Tooltip>
        )}
      </Wrapper>
    </Flex>
  );
};

MoreTrainingCell.defaultProps = {
  value: [],
};

export default MoreTrainingCell;

import React, { FC, useContext, useEffect } from 'react';
import { Box, Flex, Progress, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

import { getImage } from 'utils';

import TaskPlayDataContext from '../../store/task-play-context';

const ProgressBar = styled.div<{ progress: number }>`
  position: relative;
  height: 8px;
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => `${props.progress}%`};
    background-color: #2a85ff;
    border-radius: 10px;
  }
`;

const ProfileImage = styled.img<{ progress: number }>`
  position: absolute;
  top: -100%;
  left: ${(props) => `calc(${props.progress}% - 20px)`};
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid white;
  z-index: 1;
`;

const ProfileProgressBar: FC = () => {
  return (
    <Box position='relative' w='full' mb={4}>
      <ProfileImage src={getImage(undefined, 'User')} progress={100} />
      <ProgressBar progress={100} />
    </Box>
  );
};

const TaskPlayHeading: FC = () => {
  let taskPlayCtx = useContext(TaskPlayDataContext);
  const history = useHistory();
  useEffect(() => {
    console.log('TaskPlay Ctx : ', taskPlayCtx);
  }, [taskPlayCtx.taskPlayData]);
  return (
    <Flex flexDir='column' gap={4}>
      <Flex flexDir='column'>
        <Text fontWeight={600} fontSize='24px'>
          {taskPlayCtx.taskPlayData.title}
        </Text>
        <Text
          onClick={() => history.push('/tasks/my-tasks')}
          cursor='pointer'
          w='fit-content'
        >
          Back to Tasks
        </Text>
      </Flex>
      <ProfileProgressBar />
    </Flex>
  );
};

export default TaskPlayHeading;

import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { IMultiSelectListData } from 'sub-components/MultiSelectChipDropdown/MultiSelectChipDropdown';
import { AssignPublishTextWrapper } from './AssignPublishText.styles';
import threeStepInfo from '../../../../../../assets/images/threeStepInfo.svg';
import threeStepUser from '../../../../../../assets/images/threeStepUser.svg';
import threeStepVisible from '../../../../../../assets/images/threeStepVisible.svg';
import { initialText } from './initialText';
import { IThirdStepData } from '../assign-publish-types.ts/AssignPublishTypes';
import { useTranslation } from 'react-i18next';

interface IAssignPublishText {
  thirdStepSelections: IThirdStepData;
}

const AssignPublishText = ({ thirdStepSelections }: IAssignPublishText) => {
  const { t } = useTranslation('form');
  const [thirdStepSelectionsData, setThirdStepSelectionsData] =
    useState<IThirdStepData>({
      roles: [],
      members: [],
      locations: [],
      supervisors: [],
    });

  useEffect(() => {
    setThirdStepSelectionsData(thirdStepSelections);
  }, [thirdStepSelections]);

  const capitalizeFirstLetter = (letter: string) => {
    return letter.charAt(0).toUpperCase() + letter.slice(1);
  };

  const getRoleStrings = (roles: IMultiSelectListData[]) => {
    let combinedStrings = roles?.map((role) => role?.value)?.join(', ');

    let _selectedRoles: IMultiSelectListData[] = JSON.parse(
      JSON.stringify(roles)
    );
    let lastElement = _selectedRoles?.pop();
    combinedStrings =
      _selectedRoles
        .map((role) => capitalizeFirstLetter(role?.value))
        .join(', ') +
      ` ${t('or_text')} ` +
      capitalizeFirstLetter(lastElement?.value!);
    return combinedStrings;
  };

  const getLocationStrings = (locations: IMultiSelectListData[]) => {
    let combinedStrings = locations
      ?.map((location) => location?.value)
      ?.join(', ');
    let _selectedLocations: IMultiSelectListData[] = JSON.parse(
      JSON.stringify(locations)
    );
    let lastElement = _selectedLocations?.pop();
    combinedStrings =
      _selectedLocations
        ?.map((location) => capitalizeFirstLetter(location?.value))
        .join(', ') +
      ` ${t('or_text')} ` +
      capitalizeFirstLetter(lastElement?.value!);
    return combinedStrings;
  };

  const getMembersStrings = (members: IMultiSelectListData[]) => {
    let combinedStrings = members?.map((member) => member?.value)?.join(', ');
    let _selectedMembers: IMultiSelectListData[] = JSON.parse(
      JSON.stringify(members)
    );
    let lastElement = _selectedMembers?.pop();
    combinedStrings =
      _selectedMembers
        ?.map((member) => capitalizeFirstLetter(member?.value))
        .join(', ') +
      ` ${t('and_text')} ` +
      capitalizeFirstLetter(lastElement?.value!);
    return combinedStrings;
  };

  const getSupervisorsStrings = (supervisors: IMultiSelectListData[]) => {
    let combinedStrings = supervisors
      ?.map((supervisor) => supervisor?.value)
      ?.join(', ');
    let _selectedSupervisors: IMultiSelectListData[] = JSON.parse(
      JSON.stringify(supervisors)
    );
    let lastElement = _selectedSupervisors?.pop();
    combinedStrings =
      _selectedSupervisors
        ?.map((supervisor) => capitalizeFirstLetter(supervisor?.value))
        .join(', ') +
      ` ${t('and_text')} ` +
      capitalizeFirstLetter(lastElement?.value!);
    return combinedStrings;
  };

  const getRolesText = () => {
    let generatedString: any;
    if (thirdStepSelectionsData?.roles?.length === 1) {
      generatedString = (
        <>
          <span>{t('training_assign_members_text')} </span>
          <span style={{ fontWeight: '600' }}>
            {capitalizeFirstLetter(thirdStepSelectionsData?.roles?.[0]?.value)}
          </span>
          <span> {t('new_joinee_job_text')}</span>
        </>
      );
    } else {
      generatedString = (
        <>
          <span>{t('training_assign_members_text')} </span>
          <span style={{ fontWeight: '600' }}>
            {getRoleStrings(thirdStepSelectionsData?.roles)}
          </span>
          <span> {t('new_joinees_job_text')}</span>
        </>
      );
    }
    return generatedString;
  };

  const getLocationsText = () => {
    let generatedString: any;
    if (thirdStepSelectionsData?.locations?.length === 1) {
      generatedString = (
        <>
          <span>{t('training_assign_members_text')} </span>
          <span style={{ fontWeight: '600' }}>
            {capitalizeFirstLetter(
              thirdStepSelectionsData?.locations?.[0]?.value
            )}
            .
          </span>
          <span> {t('new_joinee_location_text')}</span>
        </>
      );
    } else {
      generatedString = (
        <>
          <span>{t('training_assign_members_text')} </span>
          <span style={{ fontWeight: '600' }}>
            {getLocationStrings(thirdStepSelectionsData?.locations)}.
          </span>
          <span> {t('new_joinees_location_text')}</span>
        </>
      );
    }
    return generatedString;
  };

  const getMembersText = () => {
    let generatedString: any;
    if (thirdStepSelectionsData?.members?.length === 1) {
      generatedString = (
        <>
          <span>{t('training_assign_to')} </span>
          <span style={{ fontWeight: '600' }}>
            {capitalizeFirstLetter(
              thirdStepSelectionsData?.members?.[0]?.value
            )}
            .
          </span>
        </>
      );
    } else {
      generatedString = (
        <>
          <span>{t('training_assign_to')} </span>
          <span style={{ fontWeight: '600' }}>
            {getMembersStrings(thirdStepSelectionsData?.members)}.
          </span>
        </>
      );
    }
    return generatedString;
  };

  const getSupervisorsText = () => {
    let generatedString: any;
    if (thirdStepSelectionsData?.supervisors?.length === 1) {
      generatedString = (
        <>
          <span style={{ fontWeight: '600' }}>
            {capitalizeFirstLetter(
              thirdStepSelectionsData?.supervisors?.[0]?.value
            )}
          </span>
          <span> {t('he_she_result')}</span>
        </>
      );
    } else if (thirdStepSelectionsData?.supervisors?.length > 1) {
      generatedString = (
        <>
          <span style={{ fontWeight: '600' }}>
            {getSupervisorsStrings(thirdStepSelectionsData?.supervisors)}
          </span>
          <span> {t('they_result')}</span>
        </>
      );
    } else {
      generatedString = <span>{t('supervisor_init_text')}</span>;
    }
    return generatedString;
  };

  return (
    <AssignPublishTextWrapper>
      <Card style={{ borderRadius: '7px', height: '100%' }}>
        {thirdStepSelectionsData?.roles &&
          thirdStepSelectionsData?.roles?.length === 0 &&
          thirdStepSelectionsData?.locations &&
          thirdStepSelectionsData?.locations?.length === 0 &&
          thirdStepSelectionsData?.members &&
          thirdStepSelectionsData?.members?.length === 0 && (
            <div className='assign-publish-text'>
              <img src={threeStepInfo} />
              <span>{t('assign_init_job_text')}</span>
            </div>
          )}
        {thirdStepSelectionsData?.roles &&
          thirdStepSelectionsData?.roles?.length > 0 && (
            <div className='assign-publish-text'>
              <img src={threeStepInfo} />
              <span>{getRolesText()}</span>
            </div>
          )}
        {thirdStepSelectionsData?.locations &&
          thirdStepSelectionsData?.locations?.length > 0 && (
            <div className='assign-publish-text'>
              <img src={threeStepInfo} />
              <span>{getLocationsText()}</span>
            </div>
          )}
        {thirdStepSelectionsData?.members &&
          thirdStepSelectionsData?.members?.length > 0 && (
            <div className='assign-publish-text'>
              <img src={threeStepInfo} />
              <span>{getMembersText()}</span>
            </div>
          )}
        <div className='assign-publish-text'>
          <img src={threeStepUser} />
          <span>{getSupervisorsText()}</span>
        </div>
        <div className='assign-publish-text'>
          <img src={threeStepVisible} />
          <span>{t('training_init_text')}</span>
        </div>
      </Card>
    </AssignPublishTextWrapper>
  );
};

export default AssignPublishText;

// @ts-ignore
import React, { ChangeEvent, FC, useState } from 'react';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { DEFAULT_FORM_COVER } from '../../../configs/default-image';
import Loader from '../../Loader';
import TrainingPathIcon from '../../../assets/images/training-path-icon.png';

const Wrapper = styled.div`
  display: flex;
  position: relative;

  label {
    position: relative;
    cursor: pointer;
  }

  img {
    width: 86px;
    height: 86px;
    border-radius: 7px;
    margin-right: 10px;
  }

  .empty-container {
    width: 86px;
    height: 86px;
    border: 1px dashed #b3b3b4;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

interface IProps {
  image?: string;
  isReadOnly?: boolean;
  onFileChange?: (event: FileList | null) => PromiseLike<void>;
}

const ThumbnailUploadField: FC<IProps> = ({
  image,
  onFileChange,
  isReadOnly,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true);
      await onFileChange?.(event.target.files);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Wrapper>
        {image ? <img src={image} /> : <div className='empty-container' />}
        <Loader
          size='lg'
          style={{
            position: 'absolute',
            top: '26px',
            left: '26px',
          }}
        />
      </Wrapper>
    );
  }

  if (isReadOnly) {
    return (
      <Wrapper>
        <div>
          <img src={image || DEFAULT_FORM_COVER} />
        </div>
      </Wrapper>
    );
  }

  if (!image) {
    return (
      <Wrapper>
        <label htmlFor='thumbnail'>
          <div className='empty-container '>
            <FontAwesomeIcon
              style={{ width: '20px', height: '20px' }}
              color='#B3B3B4'
              icon={faPlus as IconProp}
            />
          </div>
        </label>

        <input
          type='file'
          onChange={onImageChange}
          id='thumbnail'
          style={{ display: 'none' }}
          accept='image/*'
          disabled={isReadOnly}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <label htmlFor={'thumbnail'}>
        <img src={image} />
        <img
          src={TrainingPathIcon}
          style={{
            position: 'absolute',
            zIndex: '0',
            right: '-10px',
            top: '-8px',
            cursor: 'pointer',
            width: '31px',
            height: '31px',
          }}
        />
      </label>
      <input
        type='file'
        onChange={onImageChange}
        id={'thumbnail'}
        style={{ display: 'none' }}
        accept='image/*'
        disabled={isReadOnly}
      />
    </Wrapper>
  );
};

export default ThumbnailUploadField;

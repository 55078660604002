import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import {
  IAllSessionsForTask,
  IAllSessionsForTaskItemsEntity,
} from 'pages/TasksDashboard/tasks.types';
import React, { FC } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRight,
  faCircleHalfStroke,
} from '@fortawesome/pro-regular-svg-icons';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';

interface IProps {
  isOpen: boolean;
  allSessionsForTaskData: IAllSessionsForTask | undefined;
  selectedDateString: string;
  fetchSpecificSession: (sessionId: string) => void;
  onClose: () => void;
  setSessionId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDateString: React.Dispatch<React.SetStateAction<string>>;
}

const ProgressLine = () => {
  return (
    <div
      style={{
        width: '7px',
        height: '100%',
        // height: '50%',
        background: '#EFEFEF',
        position: 'absolute',
        top: '13px',
        // left: '50%',
        left: '37px',
        zIndex: '-1',
        // transform: 'translateX(-50%)',
      }}
    />
  );
};

const TaskTimeline: FC<IProps> = ({
  isOpen,
  allSessionsForTaskData,
  fetchSpecificSession,
  onClose,
  setSessionId,
  setSelectedDateString,
}) => {
  const userData = useReactiveVar(userObj);
  let _now = moment()?.tz(userData?.timezone);
  let now = moment.utc(
    _now?.format('DD-MMM-YYYY hh:mm a'),
    'DD-MMM-YYYY hh:mm a'
  );
  const iconStatusHandler = (item: IAllSessionsForTaskItemsEntity) => {
    if (moment?.utc(item?.startDate)?.isSame(now, 'day')) {
      return (
        <FontAwesomeIcon
          icon={faCircleHalfStroke as IconProp}
          color='#2A85FF'
          size='lg'
          style={{ marginTop: '5px' }}
        />
      );
    }
    if (item?.progressRate !== 100) {
      return (
        <FontAwesomeIcon
          icon={faCircleExclamation as IconProp}
          color='#FF6A55'
          size='lg'
          style={{ marginTop: '5px' }}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faCircleCheck as IconProp}
          size='lg'
          style={{ marginTop: '5px' }}
          color='#83BF6E'
        />
      );
    }
  };

  return (
    <Drawer isOpen={isOpen} placement='right' size='md' onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton right={7} top={4} />
        <DrawerHeader px={8}>
          <GenericColorHeader title='Task history' color='#CABDFF' />
        </DrawerHeader>
        <DrawerBody px={0}>
          <List position='relative' spacing={2}>
            {allSessionsForTaskData?.SessionPagination?.items?.map(
              (item, index) => (
                <ListItem
                  key={item?.eid}
                  display='flex'
                  gap={4}
                  px={8}
                  py={2}
                  alignItems='flex-start'
                  _hover={{
                    bg: '#EFEFEFB2',
                  }}
                >
                  {index > 0 && <ProgressLine />}
                  {iconStatusHandler(item)}
                  <Flex
                    justify='space-between'
                    w='full'
                    align='center'
                    cursor='pointer'
                    onClick={() => {
                      fetchSpecificSession(item?.eid);
                      setSelectedDateString(
                        moment?.utc(item?.dueDate)?.format('DD MMM')
                      );
                      onClose();
                    }}
                  >
                    <Flex flexDir='column' gap={1}>
                      <Text
                        m={0}
                        fontWeight={500}
                        color='#171725'
                        lineHeight='unset !important'
                      >
                        {moment?.utc(item?.dueDate)?.format('DD MMM YYYY')}
                      </Text>
                      <Text
                        m={0}
                        fontWeight={500}
                        fontSize='12px'
                        color='#92929D'
                        lineHeight='unset !important'
                      >
                        {moment?.utc(item?.dueDate)?.format('ddd')}
                      </Text>
                    </Flex>
                    <FontAwesomeIcon
                      icon={faArrowRight as IconProp}
                      size='lg'
                      color='#6F767E'
                    />
                  </Flex>
                </ListItem>
              )
            )}
          </List>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default TaskTimeline;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Image } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClock } from '@fortawesome/pro-regular-svg-icons';

import { ActionButton } from 'ui-components/Confirm';

import RetakeIcon from '../../assets/images/retake.svg';

interface IProps {
  viewCount: number;
  totalCount: number;
  onRetakeClick: () => void;
  onResumeClick: () => void;
  isLoading?: boolean;
}

const ActionButtonCell: FC<IProps> = ({
  viewCount,
  totalCount,
  onRetakeClick,
  onResumeClick,
  isLoading,
}) => {
  const { t } = useTranslation('training');
  return (
    <div style={{ cursor: 'pointer', marginBottom: '25px' }}>
      {viewCount === totalCount ? (
        <ActionButton
          type='button'
          size='md'
          width='full'
          actionFn={onRetakeClick}
          isLoading={isLoading}
          disabled={isLoading}
          leftIcon={<Image src={RetakeIcon} w='18px' h='18px' />}
        >
          {t('retake')}
        </ActionButton>
      ) : (
        <ActionButton
          type='button'
          size='md'
          width='full'
          actionFn={onResumeClick}
          isLoading={isLoading}
          disabled={isLoading}
          leftIcon={
            <FontAwesomeIcon
              icon={(viewCount > 0 ? faClock : faPlay) as IconProp}
            />
          }
        >
          {t(viewCount > 0 ? 'resume' : 'view_play')}
        </ActionButton>
      )}
    </div>
  );
};

export default ActionButtonCell;

import React, { FC } from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new';

import {
  AxisPercentageDomain,
  GraphLoader,
  toPercent,
} from 'sub-components/tasks/performance';

import { getToolTipConfig } from '../../../../sub-components/tasks/performance/common/graph';
import { GraphEntity } from './task-performance.graphql';

const getColor = (value: number): string => {
  return value > 65 ? '#83BF6E' : value > 34 ? '#2A85FF' : '#FF6A55';
};

const CustomBar = (props: any) => {
  const value = props.value;

  return <Rectangle {...props} fill={getColor(value)} />;
};

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor='end'
        fill='#2E2C34'
        transform='rotate(-90)'
      >
        {payload.value.length > 9
          ? `${payload.value.substring(0, 9)}...`
          : payload.value}
      </text>
    </g>
  );
};

interface IProps {
  height: number;
  isLoading: boolean;
  data?: GraphEntity[];
}

const RoleBarGraph: FC<IProps> = ({ isLoading, height, data }) => {
  return (
    <GraphLoader
      isLoading={isLoading}
      minHeight={height}
      isEmpty={!data?.length}
      emptyI18Key='task:empty.jobPerformData'
    >
      <ResponsiveContainer width='100%' height={height}>
        <BarChart
          data={data}
          barSize={24}
          barCategoryGap={16}
          margin={{
            bottom: 50,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray='3 3' />
          <XAxis
            axisLine={false}
            dataKey='name'
            height={40}
            interval={0}
            tick={<CustomizedAxisTick />}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={toPercent}
            domain={AxisPercentageDomain}
          />
          <Tooltip
            cursor={false}
            {...getToolTipConfig()}
            formatter={(value) => {
              return [`${value}% completed`, [] as any];
            }}
          />
          <Bar
            dataKey='completeRate'
            radius={4}
            fill='#5542F6'
            shape={CustomBar}
          />
        </BarChart>
      </ResponsiveContainer>
    </GraphLoader>
  );
};

export default RoleBarGraph;

export const capitalizeFirstLetter = (stringToModify: string): string => {
  if(stringToModify){
  return stringToModify.charAt(0).toUpperCase() + stringToModify.slice(1);
  }
  return "";
};

export const profileName = (stringToModify: string): string => {
  if(stringToModify){
    let nameArray = stringToModify.split(' ');
    if(nameArray?.length>=2){
      let name = nameArray[0].charAt(0)+nameArray[1].charAt(0)
      return name.toUpperCase();;
    } else if(stringToModify?.length === 1){
      return stringToModify.toUpperCase();
    } else if(stringToModify?.length >= 2){
      let name =  stringToModify.charAt(0)+stringToModify.charAt(1)
      return name.toUpperCase();
    }
    return "LA";
  }
};

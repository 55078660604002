import React, { FC } from 'react';
import { Center } from '@chakra-ui/react';
import Loader from '../../../../Loader';
import EmptyGraph, { EmptyGraphProps } from './EmptyGraph';

interface IProps extends EmptyGraphProps {
  isLoading?: boolean;
  isEmpty?: boolean;
  minHeight?: string | number;
}

const GraphLoader: FC<IProps> = ({
  children,
  isLoading,
  isEmpty,
  minHeight,
  graphType,
  emptyI18Key,
}) => {
  if (isLoading) {
    return (
      <Center h='full' minHeight={minHeight}>
        <Loader size='lg' />
      </Center>
    );
  }

  if (isEmpty) {
    return (
      <EmptyGraph
        graphType={graphType}
        emptyI18Key={emptyI18Key}
        minHeight={minHeight}
      />
    );
  }

  return <>{children}</>;
};

export default GraphLoader;

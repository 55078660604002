import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-duotone-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import {
  BoxHeader,
  ConfirmConfig,
  useConfirm,
  UseConfirm,
} from '../../../../ui-components';

type UsePublishConfirm = () => Promise<void>;

const modalConfig: Partial<ConfirmConfig> = {
  content: (
    <Flex flexDir='column' gap={5} pt={4}>
      <FontAwesomeIcon
        icon={faCircleInfo as IconProp}
        size='3x'
        style={{ color: '#8e59ff' }}
      />

      <Flex textAlign='center' color='#33383F'>
        Publishing launcher changes will affect locations that have not yet
        reached this task or future locations. However, ongoing tasks in current
        locations will remain unchanged
      </Flex>
    </Flex>
  ),

  contentProps: {
    padding: '12px 4px',
    minWidth: '480px',
  },

  cancelText: null,
  okText: 'Yes, Publish',
  okButtonProps: {
    flex: 1,
    ml: undefined,
  },
};

export const usePublishConfirm = (): UsePublishConfirm => {
  const { t } = useTranslation(['launcher']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const confirm = useConfirm();

  return useCallback(async () => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    await new Promise((resolve, reject) => {
      confirmRef.current = confirm({
        title: (
          <Flex justify='space-between' gap={3}>
            <BoxHeader title={t('launcher:publishChanges')} color='#CABDFF' />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
              onClick={() => reject('Publish changes cancelled')}
            />
          </Flex>
        ),
        isCentered: true,

        ...modalConfig,

        onOK: resolve,
      });
    });

    confirmRef.current?.destroy();
  }, []);
};

import { LocationsEntity } from 'sop-commons/src/client';
import { StateCreator } from 'zustand';

// Define the state and actions interfaces for the user slice
export interface EditChapterFileState {
  isInitialFetch: boolean;
  updateIsInitialFetch: (value: boolean) => void;
}

export const editChapterFileSlice: StateCreator<EditChapterFileState> = (
  set
) => ({
  isInitialFetch: false,
  updateIsInitialFetch: (value) => set(() => ({ isInitialFetch: value })),
});

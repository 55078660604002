import React, { FC } from 'react';
import { useUserDataSelector } from '../../../../hooks';
import EditorProvider from './EditorProvider';
import LauncherEditor from './LauncherEditor';

interface IProps {}

const LauncherEditorContainer: FC<IProps> = () => {
  const entityId = useUserDataSelector((state) => state.entity?.eid);

  if (!entityId) {
    return null;
  }

  return (
    <EditorProvider>
      <LauncherEditor />
    </EditorProvider>
  );
};

export default LauncherEditorContainer;

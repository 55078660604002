import { gql } from '@apollo/client';

export const ARCHIVE_CHAT = gql`
  mutation ARCHIVE_CHAT($input: UserUpdateInput) {
    updateUser(input: $input) {
      eid
      archivedChats {
        type
        uid
      }
    }
  }
`;

export const ARCHIVED_CHATS = gql`
  query ArchivedChats($eid: String!) {
    userById(eid: $eid) {
      archivedChats {
        type
        uid
      }
    }
  }
`;

export const UPDATE_GROUPS_NOTIFICATION = gql`
  mutation UpdateChatGroupNotification(
    $guid: String!
    $mute: Boolean!
    $pushNotification: Boolean!
    $emailNotification: Boolean!
    $smsNotification: Boolean!
  ) {
    UpdateChatGroupNotification(
      guid: $guid
      mute: $mute
      pushNotification: $pushNotification
      emailNotification: $emailNotification
      smsNotification: $smsNotification
    ) {
      eid
    }
  }
`;

export const USER_GROUPS_NOTIFICATION = gql`
  query UserChatGroupsNotifications {
    UserChatGroupsNotifications {
      guid
      eid
      emailNotification
      mute
      pushNotification
      smsNotification
      userId
    }
  }
`;

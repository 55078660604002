import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Flex,
  FormControl,
  Text,
  useOutsideClick,
  useToast,
} from '@chakra-ui/react';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faChevronDown,
  faClipboard,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import GenericColorHeader from '../../sub-components/GenericColorHeader';

import { IObjectDataEntity } from '../forms/FormResponse/QuestionWiseTable/question-wise.types';

import CardIcon from '../../assets/images/report/card.svg';
import DeckIcon from '../../assets/images/report/decks.svg';
import FormResponseIcon from '../../assets/images/report/formResponse.svg';
import TeamIcon from '../../assets/images/report/userGroup.svg';
import TrainingIcon from '../../assets/images/report/trainings.svg';
import DownloadIcon from '../../assets/images/report/download.svg';
import FormInput from '../../atoms/FormInput';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { REQUEST_REPORT, GET_REPORTS } from './reports.graphql';
import PrimaryButton from '../../atoms/PrimaryButton';
import { userObj } from 'sop-commons';
import SortingTable from 'sub-components/ChakraTable/SortingTable/SortingTable';
import moment from 'moment';
import TitleHeader from '../../sub-components/CardEditor/TitleHeader';
import EmptyState from '../../sub-components/EmptyState';

const Reports: FC = () => {
  const { t } = useTranslation(['common', 'report', 'setting']);
  const userData = useReactiveVar(userObj);
  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [open, setOpen] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [reportsListingData, setReportsListingData] = useState<{
    count: number;
    pageInfo: {
      currentPage: number;
      perPage: number;
      pageCount: number;
      itemCount: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    items: {
      reportName: string | null;
      reportType: string;
      entityId: string;
      requestedBy: string;
      status: string;
      reportUrl: string | null;
      startDate: string;
      endDate: string;
      notes: string;
      createdAt: string;
      updatedAt: string;
      eid: string;
    }[];
  }>({
    count: 0,
    pageInfo: {
      currentPage: 0,
      perPage: 0,
      pageCount: 0,
      itemCount: 0,
      hasNextPage: false,
      hasPreviousPage: false,
    },
    items: [],
  });
  const [selectedItem, setSelectedItem] = useState<IObjectDataEntity>({
    eid: '',
    img: '',
    value: '',
    transLabel: '',
    index: -1,
  });

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const options: IObjectDataEntity[] = [
    {
      eid: '1',
      img: TeamIcon,
      value: 'Team',
      transLabel: t('setting:teamManager'),
      index: 0,
    },
    {
      eid: '2',
      img: DeckIcon,
      value: 'Decks',
      transLabel: t('setting:chapters'),
      index: 1,
    },
    {
      eid: '2',
      img: CardIcon,
      value: 'Usage',
      transLabel: t('setting:usage'),
      index: 2,
    },
    {
      eid: '3',
      img: TrainingIcon,
      value: 'Trainings',
      transLabel: t('common:trainings'),
      index: 3,
    },
    {
      eid: '4',
      img: FormResponseIcon,
      value: 'Form Responses',
      transLabel: t('setting:formResponseUsers'),
      index: 4,
    },
  ];

  useOutsideClick({
    ref: ref,
    handler: () => setOpen(false),
  });

  useEffect(() => {
    setSelectedItem({
      eid: '1',
      img: TeamIcon,
      value: 'Team',
      transLabel: t('setting:teamManager'),
      index: 0,
    });
    getReports({
      variables: {
        page: 1,
        perPage: 20,
        filter: {
          requestedBy: userData?.eid,
        },
      },
    });
  }, []);

  const [getReports, { loading: getReportsLoading }] = useLazyQuery(
    GET_REPORTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setReportsListingData(data?.ReportPagination);
      },
    }
  );

  const [requestReport, { loading: reportRequestLoading }] = useMutation(
    REQUEST_REPORT,
    {
      onCompleted: (data) => {
        toast({
          status: 'success',
          title: t('report:report_requested'),
        });
        clearFields();
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t('report:report_req_fail'),
        });
      },
    }
  );

  const clearFields = () => {
    setSelectedItem({
      eid: '',
      img: '',
      value: '',
      transLabel: '',
      index: -1,
    });
    setAdditionalNotes('');
    setDateRange([null, null]);
  };

  const setSelectedItemHandler = (obj: IObjectDataEntity, index: number) => {
    setSelectedItem({ ...obj, index });
  };

  const requestReportHandler = () => {
    requestReport({
      variables: {
        input: {
          endDate: new Date(endDate).toISOString(),
          notes: additionalNotes,
          reportType: selectedItem?.value?.toLowerCase(),
          startDate: new Date(startDate).toISOString(),
        },
      },
    });
  };

  const getColumns = (isTablet: boolean) => {
    let tableColumns: Array<any> = [
      {
        Header: t('report:table.report_name'),
        accessor: 'reportName' as const,
        width: '25%',
        disableSortBy: true,
        Cell: ({ cell: { value } }: any) => <Text>{value}</Text>,
      },
      {
        Header: t('report:table.duration'),
        accessor: 'duration' as const,
        width: '16%',
        disableSortBy: true,
        Cell: ({ cell: { value } }: any) => <Text>{value}</Text>,
      },
      {
        Header: t('report:table.status'),
        accessor: 'status',
        width: '16%',
        disableSortBy: true,
        Cell: ({ cell: { value } }: any) => {
          return (
            <Text
              backgroundColor={
                value === 'pending'
                  ? '#ffd88d'
                  : value === 'approved'
                  ? 'rgba(181, 228, 202, 0.7)'
                  : value === 'rejected'
                  ? '#ff6a55'
                  : 'white'
              }
              width='fit-content'
              as='b'
              padding='.5rem'
              borderRadius='3px'
            >
              {value === 'pending'
                ? 'Pending'
                : value === 'approved'
                ? 'Approved'
                : value === 'rejected'
                ? 'Rejected'
                : 'N/A'}
            </Text>
          );
        },
      },
      {
        Header: t('report:table.generate_on'),
        accessor: 'generatedOn' as const,
        width: '16%',
        disableSortBy: true,
        Cell: ({ cell: { value } }: any) => <Text>{value}</Text>,
      },
      {
        Header: '',
        accessor: 'action' as const,
        width: '11%',
        disableSortBy: true,
        Cell: ({ cell: { value } }: any) =>
          value === 'approved' ? (
            <img src={DownloadIcon} style={{ cursor: 'pointer' }} />
          ) : null,
      },
    ];
    return tableColumns;
  };

  const getTableData = (isTablet: boolean) => {
    let tableData: any = [];
    if (reportsListingData?.items?.length > 0) {
      tableData = reportsListingData?.items.map((item, index: number) => {
        return {
          reportName: item?.reportName,
          duration: `${moment(item?.startDate).format(
            'DD MMM YYYY'
          )} - ${moment(item?.endDate).format('DD MMM YYYY')}`,
          status: item?.status,
          generatedOn: moment(item?.createdAt).format('DD MMM YYYY'),
          action: item?.status,
        };
      });
    }
    return [...tableData];
  };

  return (
    <>
      <Box paddingBottom='2rem'>
        <GenericColorHeader
          color='#cabdff'
          title={t('report:request_report')}
        />
      </Box>
      <Container maxW='full'>
        <Flex gap='8' flexDir='column'>
          <Box display='flex' gap='4'>
            <FormControl>
              <TitleHeader
                title={t('report:select_report_type')}
                isRequired
                desc={t('report:choose_what_analyse')}
              />
              <Box
                mt={1}
                className='drop-selected-container'
                onClick={() => setOpen(true)}
              >
                <img src={selectedItem?.img} />
                <strong>{selectedItem?.transLabel}</strong>
                <FontAwesomeIcon
                  fontSize={16}
                  style={{
                    color: '#6f767e',
                    position: 'absolute',
                    right: '20px',
                  }}
                  icon={faChevronDown as IconProp}
                />
                {open && (
                  <div className='drop-options-container' ref={ref}>
                    {options?.map((obj, index) => (
                      <div
                        key={index}
                        className='drop-option-container'
                        onClick={() => {
                          setSelectedItemHandler(obj, index);
                          setOpen(false);
                        }}
                      >
                        <img src={obj?.img} />
                        <strong>{obj?.transLabel}</strong>
                      </div>
                    ))}
                  </div>
                )}
              </Box>
            </FormControl>
            <FormControl>
              <TitleHeader
                title={t('report:select_duration')}
                isRequired
                desc={t('report:choose_duration')}
              />
              <Flex
                as='label'
                align='center'
                gap='.5rem'
                borderRadius='7px'
                p='.7rem'
                mt={1}
                bg='#f4f4f4'
                border='1px solid #f4f4f4'
                sx={{
                  '.react-datepicker__input-container input': {
                    width: '100%',
                  },
                }}
              >
                <FontAwesomeIcon icon={faCalendar as IconProp} />
                <DatePicker
                  style={{ outline: 'none', color: 'red' }}
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  isClearable={false}
                />
              </Flex>
            </FormControl>
          </Box>
          <FormControl>
            <TitleHeader
              title={t('report:additional_note')}
              desc={t('report:additional_note_dec')}
            />
            <Box h={1} />
            <FormInput
              id='name'
              placeholder={t('report:additional_note')}
              size='lg'
              type='text'
              value={additionalNotes}
              leftIcon={faClipboard as IconProp}
              onChange={(e) => setAdditionalNotes(e?.target?.value)}
            />
          </FormControl>
          <PrimaryButton
            title={t('report:request_btn')}
            isLoading={reportRequestLoading}
            disabled={!startDate || !endDate || !additionalNotes}
            variant={'solid'}
            style={{ backgroundColor: '#2a85ff', color: 'white' }}
            onClick={requestReportHandler}
          />
        </Flex>
      </Container>
      <Divider margin={'1rem 0rem'} />
      <Box>
        <GenericColorHeader
          color='#ffbc99'
          title={t('report:report_history')}
        />
        <SortingTable
          colorScheme='blue'
          customWrapperStyles={{ marginTop: '1rem' }}
          emptyData={{
            content: (
              <EmptyState
                image='Report'
                title={t('report:noReportGenerated')}
                description={t('report:canRequestReport')}
              />
            ),
          }}
          page={1}
          columns={getColumns(false)}
          data={getTableData(false)}
          isLoading={getReportsLoading}
          isHidePagination={true}
        />
      </Box>
    </>
  );
};

export default Reports;

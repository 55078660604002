import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import PreviewHeader from '../../../../sub-components/forms/component/PreviewHeader';

interface IProps {
  name: string;
}

const ProgressHeader: FC<IProps> = ({ name }) => {
  const { t } = useTranslation(['common', 'training']);

  return (
    <Flex align='center'>
      <Box flex={1}>
        <PreviewHeader
          // @ts-ignore
          title={t('training:userProgress', {
            name: name,
          })}
        />
      </Box>
      <Flex justify='flex-end' gap='8px'>
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
    </Flex>
  );
};

export default ProgressHeader;

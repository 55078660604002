import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import React, { FC, useEffect, useMemo } from 'react';
import { GlobalChatSetting } from '../../../GlobalChat';
import BaseSelectionSection from './BaseSelectionSection';
import AnyoneSelection from './AnyoneSelection';
import AuthRoleJobMembersSelection from './AuthRoleJobMembersSelection';
import AuthRoleSelection from './AuthRoleSelection';
import RoleSelection from './RoleSelection';

interface IProps {
  globalChatSetting: GlobalChatSetting;
  type: 'newChannel' | 'directMessage';
  savePreferences: () => void;
  setGlobalChatSetting: React.Dispatch<React.SetStateAction<GlobalChatSetting>>;
}

export const getPermissionText = (count: number) =>
  count === 1 ? 'has permission to' : 'have permission to';

export const formatList = (items: any) => {
  if (items?.length === 0) {
    return '-';
  }
  if (items?.length > 1) {
    return `${items?.slice(0, -1)?.join(', ')} and ${
      items?.[items?.length - 1]
    }`;
  }
  return items?.join('');
};

const CommonExpandAccordion: FC<IProps> = ({
  globalChatSetting,
  type,
  savePreferences,
  setGlobalChatSetting,
}) => {
  let typeSelectionData =
    type === 'directMessage'
      ? globalChatSetting?.whoDirectMessages
      : globalChatSetting?.whoCreateChannels;
  const chatConfigData = useUserDataSelector(
    (state) => state?.entity?.chatConfig
  );
  const updateMembersSelection = (values: string[]) => {
    if (type === 'newChannel') {
      setGlobalChatSetting({
        ...globalChatSetting,
        whoCreateChannels: {
          ...globalChatSetting?.whoCreateChannels,
          selectedOptions: {
            ...globalChatSetting?.whoCreateChannels?.selectedOptions,
            members: values,
          },
        },
      });
    } else {
      setGlobalChatSetting({
        ...globalChatSetting,
        whoDirectMessages: {
          ...globalChatSetting?.whoDirectMessages,
          selectedOptions: {
            ...globalChatSetting?.whoDirectMessages?.selectedOptions,
            members: values,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (type === 'directMessage') {
      setGlobalChatSetting({
        ...globalChatSetting,
        whoDirectMessages: {
          ...globalChatSetting?.whoDirectMessages,
          selectedOptions: {
            jobs:
              chatConfigData?.startDM?.roles?.length > 0
                ? chatConfigData?.startDM?.roles
                : undefined,
            members:
              chatConfigData?.startDM?.members?.length > 0
                ? chatConfigData?.startDM?.members
                : undefined,
            roles:
              chatConfigData?.startDM?.authRoles?.length > 0
                ? chatConfigData?.startDM?.authRoles
                : undefined,
          },
          selection: chatConfigData?.startDM?.restrictType,
          shouldSpecificMembers: chatConfigData?.startDM?.members?.length > 0,
        },
      });
    } else {
      setGlobalChatSetting({
        ...globalChatSetting,
        whoCreateChannels: {
          ...globalChatSetting?.whoCreateChannels,
          selectedOptions: {
            jobs:
              chatConfigData?.newChannel?.roles?.length > 0
                ? chatConfigData?.newChannel?.roles
                : undefined,
            members:
              chatConfigData?.newChannel?.members?.length > 0
                ? chatConfigData?.newChannel?.members
                : undefined,
            roles:
              chatConfigData?.newChannel?.authRoles?.length > 0
                ? chatConfigData?.newChannel?.authRoles
                : undefined,
          },
          selection: chatConfigData?.newChannel?.restrictType,
          shouldSpecificMembers:
            chatConfigData?.newChannel?.members?.length > 0,
        },
      });
    }
  }, [typeSelectionData.expanded]);

  const formattedAuthRoles = useMemo(() => {
    return typeSelectionData?.selectedOptions?.roles?.map((role) => {
      if (role === 'superadmin') {
        return 'super admins';
      } else if (role === 'admin') {
        return 'admins';
      } else if (role === 'locationOwner') {
        return 'location owners';
      } else if (role === 'worker') {
        return 'workers';
      }
      return role;
    });
  }, [typeSelectionData?.selectedOptions?.roles]);

  const selectionText = () => {
    if (typeSelectionData?.selection === 'anyone') {
      return <AnyoneSelection type={type} />;
    } else if (
      ((typeSelectionData?.selection === 'authRole' ||
        typeSelectionData?.selection === 'role') &&
        (formattedAuthRoles || [])?.length > 0 &&
        (typeSelectionData?.selectedOptions?.members || [])?.length > 0) ||
      ((typeSelectionData?.selectedOptions?.jobs || [])?.length > 0 &&
        (typeSelectionData?.selectedOptions?.members || [])?.length > 0)
    ) {
      return (
        <AuthRoleJobMembersSelection
          formattedAuthRoles={formattedAuthRoles}
          type={type}
          typeSelectionData={typeSelectionData}
        />
      );
    } else if (typeSelectionData?.selection === 'authRole') {
      return (
        <AuthRoleSelection
          formattedAuthRoles={formattedAuthRoles}
          type={type}
        />
      );
    } else if (typeSelectionData?.selection === 'role') {
      return (
        <RoleSelection typeSelectionData={typeSelectionData} type={type} />
      );
    }
    return (
      <Box display='block'>
        <Box as='span' color='rgba(111, 118, 126, 1)'>
          {type === 'newChannel'
            ? 'This permission controls creation of channels.'
            : 'This permission allows members for direct message conversations'}
        </Box>
      </Box>
    );
  };

  const descriptionText = () => {
    let description = '';
    if (type === 'newChannel') {
      description = 'This permission controls creation of channels.';
    } else if (type === 'directMessage') {
      description =
        'This permission allows members for direct message conversations.';
    }
    if (description) {
      return <Text color='rgba(111, 118, 126, 1)'>{description}</Text>;
    }
    return null;
  };

  const selectedOptionsText = () => {
    let selectedJobs = typeSelectionData?.selectedOptions?.jobs || [];
    let selectedMembers = typeSelectionData?.selectedOptions?.members || [];
    let selectedRoles = typeSelectionData?.selectedOptions?.roles || [];
    if (
      typeSelectionData?.expanded ||
      ((selectedJobs?.length === 0 ||
        selectedMembers?.length === 0 ||
        selectedRoles?.length === 0) &&
        !typeSelectionData?.selection)
    ) {
      return descriptionText();
    } else {
      return selectionText();
    }
  };

  return (
    <HStack justify='space-between' w='full'>
      <Flex flexDir='column' w='full'>
        <Flex
          w='full'
          justify='space-between'
          cursor='pointer'
          align='center'
          onClick={() => {
            if (type === 'newChannel') {
              setGlobalChatSetting({
                ...globalChatSetting,
                whoCreateChannels: {
                  ...globalChatSetting.whoCreateChannels,
                  expanded: !globalChatSetting.whoCreateChannels.expanded,
                },
              });
            } else {
              setGlobalChatSetting({
                ...globalChatSetting,
                whoDirectMessages: {
                  ...globalChatSetting.whoDirectMessages,
                  expanded: !globalChatSetting.whoDirectMessages.expanded,
                },
              });
            }
          }}
        >
          <Flex flexDir='column'>
            <Text fontWeight={500} fontSize='16px' color='rgba(26, 29, 31, 1)'>
              {type === 'newChannel'
                ? 'Who can create new channels?'
                : 'Who can start direct messages?'}
            </Text>
            {selectedOptionsText()}
          </Flex>
          {typeSelectionData.expanded ? (
            <ChevronUpIcon fontSize='25px' color='rgba(111, 118, 126, 1)' />
          ) : (
            <ChevronDownIcon fontSize='25px' color='rgba(111, 118, 126, 1)' />
          )}
        </Flex>
        {typeSelectionData.expanded && (
          <BaseSelectionSection
            selectedValues={typeSelectionData?.selectedOptions}
            selectionChange={(value) => {
              if (type === 'newChannel') {
                setGlobalChatSetting({
                  ...globalChatSetting,
                  whoCreateChannels: {
                    ...globalChatSetting?.whoCreateChannels,
                    selection: value,
                    shouldSpecificMembers: false,
                    selectedOptions: {
                      jobs: undefined,
                      members: undefined,
                      roles:
                        value === 'authRole'
                          ? [AuthRole.SUPER_ADMIN]
                          : undefined,
                    },
                  },
                });
              } else {
                setGlobalChatSetting({
                  ...globalChatSetting,
                  whoDirectMessages: {
                    ...globalChatSetting?.whoDirectMessages,
                    selection: value,
                    shouldSpecificMembers: false,
                    selectedOptions: {
                      jobs: undefined,
                      members: undefined,
                      roles:
                        value === 'authRole'
                          ? [AuthRole.SUPER_ADMIN]
                          : undefined,
                    },
                  },
                });
              }
            }}
            selectionValue={typeSelectionData?.selection}
            shouldSpecificMembers={typeSelectionData?.shouldSpecificMembers}
            options={typeSelectionData}
            savePreferences={savePreferences}
            updateMembersSelection={updateMembersSelection}
            updateSelectedValues={(_type, values) => {
              console.log({ _type, values });
              if (type === 'newChannel') {
                if (_type === 'role') {
                  setGlobalChatSetting({
                    ...globalChatSetting,
                    whoCreateChannels: {
                      ...globalChatSetting?.whoCreateChannels,
                      selectedOptions: {
                        ...globalChatSetting?.whoCreateChannels
                          ?.selectedOptions,
                        roles: values,
                      },
                    },
                  });
                } else if (_type === 'job') {
                  setGlobalChatSetting({
                    ...globalChatSetting,
                    whoCreateChannels: {
                      ...globalChatSetting?.whoCreateChannels,
                      selectedOptions: {
                        ...globalChatSetting?.whoCreateChannels
                          ?.selectedOptions,
                        jobs: values,
                      },
                    },
                  });
                }
              } else {
                if (_type === 'role') {
                  setGlobalChatSetting({
                    ...globalChatSetting,
                    whoDirectMessages: {
                      ...globalChatSetting?.whoDirectMessages,
                      selectedOptions: {
                        ...globalChatSetting?.whoDirectMessages
                          ?.selectedOptions,
                        roles: values,
                      },
                    },
                  });
                } else if (_type === 'job') {
                  setGlobalChatSetting({
                    ...globalChatSetting,
                    whoDirectMessages: {
                      ...globalChatSetting?.whoDirectMessages,
                      selectedOptions: {
                        ...globalChatSetting?.whoDirectMessages
                          ?.selectedOptions,
                        jobs: values,
                      },
                    },
                  });
                }
              }
            }}
            updateShouldSpecificMembers={() => {
              if (type === 'newChannel') {
                setGlobalChatSetting({
                  ...globalChatSetting,
                  whoCreateChannels: {
                    ...globalChatSetting?.whoCreateChannels,
                    shouldSpecificMembers:
                      !globalChatSetting?.whoCreateChannels
                        ?.shouldSpecificMembers,
                  },
                });
              } else {
                setGlobalChatSetting({
                  ...globalChatSetting,
                  whoDirectMessages: {
                    ...globalChatSetting?.whoDirectMessages,
                    shouldSpecificMembers:
                      !globalChatSetting?.whoDirectMessages
                        ?.shouldSpecificMembers,
                  },
                });
              }
            }}
          />
        )}
      </Flex>
    </HStack>
  );
};

export default CommonExpandAccordion;

import React, { FC } from 'react';
import {
  Flex,
  ModalCloseButton,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';
import { getRandomNumber } from '../../../../../../../utils';

const LogViewSkeleton: FC = () => {
  return (
    <Flex flexDir='column' minH='calc(100vh - 12rem)' gap='12px'>
      <ModalCloseButton top={8} right={8} />
      <Skeleton width='140px' height='30px' borderRadius='6px' />
      <SkeletonText noOfLines={getRandomNumber(1, 2)} skeletonHeight='32px' />

      <Flex flexDir='column' gap='10px' paddingBlock={3}>
        <Flex align='center' gap={2} fontWeight={500} py='3px'>
          <Skeleton h='15px' w='80px' />
          <Skeleton h='15px' w='100px' />
        </Flex>
        <Flex align='center' gap={2} fontWeight={500}>
          <Skeleton h='15px' w='90px' />
          <Skeleton h='29px' w='200px' borderRadius='5px' />
          <Skeleton h='15px' w='20px' />
          <Skeleton h='15px' w='50px' />
        </Flex>
      </Flex>

      <SkeletonText
        width='90%'
        noOfLines={getRandomNumber(5, 8)}
        skeletonHeight='14px'
      />
    </Flex>
  );
};

export default React.memo(LogViewSkeleton);

import { Center, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpFromBracket } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { fileToBase64 } from 'utils/utils';
import { ICropImgFilesData } from './DocumentsUploadComponent';

interface IProps {
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setCropImgFilesData: React.Dispatch<
    React.SetStateAction<ICropImgFilesData[]>
  >;
}

const DocumentSkeleton: FC<IProps> = ({ setFiles, setCropImgFilesData }) => {
  const onFileDrop = async (fileLists: File[]) => {
    setFiles(fileLists);
    let _cropImgFilesData: ICropImgFilesData[] = [];
    for (let i = 0; i < fileLists?.length; i++) {
      const base64String = await fileToBase64(fileLists[i]);
      _cropImgFilesData?.push({
        crop: { x: 0, y: 0 },
        croppedImageFile: fileLists?.[i],
        imageBase64Url: base64String,
        zoom: 1,
        croppedAreaPixels: {
          height: 0,
          width: 0,
          x: 0,
          y: 0,
        },
      });
    }
    setCropImgFilesData(_cropImgFilesData);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDrop,
    multiple: true,
    noClick: true,
    noKeyboard: true,
  });
  return (
    <Center w='full' flexDir='column' gap='10px'>
      <Text fontWeight={600} fontSize='24px'>
        Upload here
      </Text>
      <Flex fontWeight={600} fontSize='18px'>
        <Text color='#92929D'>Drop your file(s) here or &nbsp;</Text>
        <Text
          color='#2A85FF'
          _hover={{ textDecoration: 'underline' }}
          cursor='pointer'
          onClick={open}
        >
          browse
        </Text>
      </Flex>
      <Center
        border='1px dashed #3F3B5780'
        flexDir='column'
        borderRadius='12px'
        p={8}
        gap='10px'
        w='80%'
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <FontAwesomeIcon
          icon={faArrowUpFromBracket as IconProp}
          fontSize='24px'
        />
        <Text fontWeight={400} fontSize='18px' color='#212121'>
          Drag and drop documents here
        </Text>
        <Text fontWeight={400} fontSize='14px' color='#212121B2'>
          JPG, PNG, PDF, MP4
        </Text>
      </Center>
    </Center>
  );
};

export default DocumentSkeleton;

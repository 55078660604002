import React, { useState, FC, FormEvent, useMemo, ChangeEvent } from 'react';
import {
  Box,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import FormInput from 'atoms/FormInput';
import PrimaryButton from 'atoms/PrimaryButton';
import closeBtn from '../../assets/images/closeIcon.png';
import { userObj } from 'sop-commons/src/client/clientFactory';

export interface UserEntityCategory {
  name: string;
  status: string;
  icon: string;
}

interface IProps {
  open: boolean;
  onClose: () => void;
  from: string;
}

const AddDeck: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation(['common', 'handbook', 'deck', 'category']);
  const history = useHistory();
  const userObject = useReactiveVar(userObj);

  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const [deckName, setDeckName] = useState('');

  const categoryData = useMemo(() => {
    if (Array.isArray(userObject?.entity?.category)) {
      return userObject?.entity?.category.map((data) => {
        return {
          label: data.name,
          value: data.name,
        };
      });
    } else {
      return [];
    }
  }, [userObject?.entity?.category]);

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedCategory.value.length > 0) {
      navigateHandler(selectedCategory.value);
    }
  };

  const navigateHandler = (urlString: string) => {
    return history.push(
      `/deck/create?n=${deckName}&categoryName=${urlString}&from=${props.from}`
    );
  };

  const categoryChangeHandler = (e: SelectOption) => {
    setSelectedCategory(e);
  };

  const deckHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setDeckName(e.target.value);
  };

  function onModalClosed() {
    setDeckName('');
    setSelectedCategory('');
  }

  return (
    <Modal
      isOpen={props.open}
      isCentered={true}
      onClose={props.onClose}
      onCloseComplete={onModalClosed}
    >
      <ModalOverlay />
      <ModalContent
        width={{
          sm: '340px',
          md: '510px',
          base: '510px',
          xl: '510px',
          ['2xl']: '510px',
        }}
        minWidth={{
          sm: '341px',
          md: '341px',
          base: '341px',
          xl: '341px',
          ['2xl']: '341px',
        }}
        borderRadius={{
          sm: '16px',
          md: '16px',
          base: '16px',
          xl: '16px',
          ['2xl']: '16px',
        }}
      >
        <ModalHeader
          style={{
            paddingTop: '41px',
            paddingLeft: '50px',
            paddingRight: '50px',
            paddingBottom: '40px',
          }}
        >
          <Flex align='center' justify='space-between'>
            <Flex gap='10px'>
              <Box h='32px' w='16px' borderRadius='4px' bg='#b5e4ca' />
              <div className='modal-title'>{t('handbook:add_new_deck')}</div>
            </Flex>
            <div>
              <img
                src={closeBtn}
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'rgb(239, 239, 239)',
                  borderRadius: '100%',
                  height: '30px',
                  width: '30px',
                  padding: '5px',
                }}
                onClick={props.onClose}
              />
              {/* <ModalCloseButton
                style={{
                  outline: 'none',
                  boxShadow: 'none',
                }}
              /> */}
            </div>
          </Flex>
        </ModalHeader>
        <ModalBody
          style={{
            paddingLeft: '50px',
            paddingRight: '50px',
            paddingTop: '0',
            paddingBottom: '39px',
          }}
        >
          <form onSubmit={submitHandler}>
            <div>
              <FormControl mt={3}>
                <FormInput
                  id='name'
                  size='lg'
                  placeholder={t('deck:deck_name')}
                  value={deckName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    deckHandler(e);
                  }}
                />
              </FormControl>
            </div>
            <div style={{ marginTop: '13px' }}>
              <Dropdown
                placeholder={t('category:category_name')}
                value={selectedCategory}
                options={categoryData}
                onChange={categoryChangeHandler}
                size='lg'
                className='member-role-dropdown-container'
                inputStyle={{
                  border: 'none',
                  borderColor: 'transparent',
                  borderRadius: '12px',
                  fontSize: '15px',
                  outline: 'none',
                }}
              />
            </div>
            <PrimaryButton
              type='submit'
              size='lg'
              title={t('deck:add_cards')}
              style={{ width: '100%', marginTop: '22px' }}
              disabled={!selectedCategory.value}
              variant='solid'
              colorScheme='blue'
            />
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddDeck;

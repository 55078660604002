import React, { FC } from 'react';
import { IInProgressCard } from 'sub-components/LocationLaunchDetail/types/types';
import Horizontal from './Horizontal';
import Vertical from './Vertical';

const InProgressCard: FC<IInProgressCard> = (props) => {
  const {
    foundLocationData,
    topDetails,
    midDetails,
    orientation = 'vertical',
    callbackFn,
  } = props;
  if (orientation === 'vertical') {
    return <Vertical topDetails={topDetails} midDetails={midDetails} />;
  } else {
    return (
      <Horizontal
        foundLocationData={foundLocationData}
        topDetails={topDetails}
        midDetails={midDetails}
        callbackFn={callbackFn}
      />
    );
  }
};

InProgressCard.displayName =
  'LocationLaunchDashboard/InProgress/Singleton/InProgressCard';
export default InProgressCard;

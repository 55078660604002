import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { LocationMember } from '../../../types';
import { BoxHeader, UseConfirm, useConfirm } from '../../../ui-components';
import ModalContent from './ModalContent';

interface IProps {
  data: LocationMember;
  onSendMessage?: (value: LocationMember) => void;
}

type IUserDetailModal = (props: IProps) => void;

export const useUserDetailModal = (): IUserDetailModal => {
  const { t } = useTranslation(['header']);
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const apolloClient = useApolloClient();

  return useCallback(({ data, onSendMessage }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    const _onSendMessage = () => {
      onSendMessage?.(data);
      setTimeout(confirmRef.current?.destroy!, 1);
    };

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader color='#b5e4ca' title={t('header:userDetails')} />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: <ModalContent data={data} onSendMessage={_onSendMessage} />,
      contentProps: {
        borderRadius: '16px',
        padding: '12px 8px',
        minW: '500px',
      },
      isCentered: true,
      footer: null,
    });
  }, []);
};

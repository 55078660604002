import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { DropzoneOptions } from 'react-dropzone';

import { useUploadImage, useUploadToAws } from 'hooks';

import { FileDataEntity } from '../create-chapter.graphql';
import DragDropComponent from './DragDropComponent';
import FileItem from './FileItem';

type Progress = (progress: number) => void;

interface IProps {
  maxFileSize?: number;
  accept: DropzoneOptions['accept'];
  subTitle?: ReactNode;
  onFileChange?: (value: FileDataEntity) => void;
}

const ChapterFileUpload: FC<IProps> = ({
  maxFileSize,
  accept,
  subTitle,
  onFileChange,
}) => {
  const [file, setFile] = useState<FileDataEntity>();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadImage = useUploadImage();
  const uploadToAws = useUploadToAws();

  const uploadFiles = async (fileData: File, uploadProgress?: Progress) => {
    if (!fileData) {
      return undefined;
    }

    if (fileData?.type?.includes('video/')) {
      return uploadToAws(fileData, uploadProgress);
    } else {
      return uploadImage(fileData, undefined, uploadProgress);
    }
  };

  const isLargerFile = useMemo(() => {
    if (!file?.fileSize || !maxFileSize) {
      return false;
    }
    return file.fileSize > maxFileSize;
  }, [file]);

  const onFileDrop = async (fileLists: File[]) => {
    const newFile = fileLists?.[0];

    if (!newFile) {
      return null;
    }

    setProgress(0);
    setFile({
      name: newFile?.name,
      fileSize: newFile?.size,
      type: newFile?.type,
      mimetype: newFile?.type,
      file: newFile,
    });

    try {
      setIsUploading(true);
      const fileUrl = await uploadFiles(newFile!, (_value) =>
        setProgress(_value)
      );
      setIsUploading(false);
      onFileChange?.({
        name: newFile?.name,
        fileSize: newFile?.size,
        type: newFile?.type,
        mimetype: newFile?.type,
        url: fileUrl,
        file: newFile,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Chapter file upload error', e);
      setIsUploading(false);
    }
  };

  return (
    <Flex flexDir='column' width='full'>
      <Box display={file ? 'none' : undefined}>
        <DragDropComponent
          subTitle={subTitle}
          accept={accept}
          onFileDrop={onFileDrop}
        />
      </Box>

      <FileItem
        isUploading={isUploading}
        progress={progress}
        file={file!}
        isLargerFile={isLargerFile}
        onRemove={() => setFile(undefined)}
      />
    </Flex>
  );
};

export default ChapterFileUpload;

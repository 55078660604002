import { useEffect, useState } from 'react';
import { CometChat } from '@cometchat-pro/chat';

export const getGroupMembers = (
  groupId: string,
  limit = 20
): Promise<CometChat.GroupMember[]> => {
  return new CometChat.GroupMembersRequestBuilder(groupId)
    .setLimit(limit)
    .setScopes([
      CometChat.GROUP_MEMBER_SCOPE.ADMIN,
      CometChat.GROUP_MEMBER_SCOPE.MODERATOR,
      CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT,
    ])
    .build()
    .fetchNext();
};

export const useChatGroupData = (groupId: string, limit = 20) => {
  const [chatGroup, setChatGroup] = useState<CometChat.GroupMember[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchChatGroup = async () => {
    try {
      setLoading(true);

      const response = await getGroupMembers(groupId, limit);
      setChatGroup(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChatGroup().then(() => null);
  }, [groupId]);

  return {
    loading,
    groupMembers: chatGroup,
    refetch: fetchChatGroup,
  };
};

import { createContext, FC, useMemo, useState } from 'react';
import { SopType } from 'types';
import { ViewMode, ViewTypeProps } from '../../../Chapters/ChapterPreview';

interface PreviewContextProps extends ViewTypeProps {
  smartPageEnabled: boolean;
  onSmartPageChange?: (value: boolean) => void;
  trainingContents?: any[];
  selectedIndex: number;
  onThumbnailClick?: (index: number) => void;
  chapterIcon: string;
  iconType: string;
}

export const PreviewContext = createContext<PreviewContextProps>({
  viewMode: 'desktop',
  smartPageEnabled: false,
  selectedIndex: 0,
  chapterIcon: '',
  iconType: '',
});

interface PreviewProviderProps {
  data: any[];
}

export const PreviewProvider: FC<PreviewProviderProps> = ({
  children,
  data,
}) => {
  const [viewMode, setViewMode] = useState<ViewMode>('desktop');
  const [smartPageEnabled, setSmartPageEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [chapterIcon, setChapterIcon] = useState('');
  const [iconType, setIconType] = useState('');

  const onModeChange = (newValue: ViewMode) => {
    setViewMode(newValue);
  };

  const onSmartPageChange = (newValue: boolean) => {
    setSmartPageEnabled(newValue);
  };

  const cardList = useMemo(() => {
    if (data) {
      return data
        .map((value) => {
          setChapterIcon(value?.icon?.native);
          setIconType(value?.icon?.id);
          if (['document', 'video'].includes(value?.sopType as SopType)) {
            return [value];
          }
          if (
            ('smartPageEnabled' in value || 'isMultiPage' in value) &&
            value?.type === 'sop'
          ) {
            if (value.smartPageEnabled || value.isMultiPage) {
              return [...value.cards];
            }
            return [...value.cardView];
          }
          return [value];
        })
        .flat();
    }
    return [];
  }, [data]);

  const onThumbnailClick = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <PreviewContext.Provider
      value={{
        viewMode,
        onModeChange,
        smartPageEnabled,
        onSmartPageChange,
        trainingContents: cardList,
        selectedIndex,
        onThumbnailClick,
        chapterIcon,
        iconType,
      }}
    >
      {children}
    </PreviewContext.Provider>
  );
};

import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Question as QuestionData } from '../create-form/form.types';
import AcknowledgeInput from '../from-preview/component/Acknowledge/AcknowledgeInput';

interface IProps {
  question: QuestionData;
  currentIndex: number;
}

const Acknowledge: FC<IProps> = ({ question, currentIndex }) => {
  if (question.isArchieved) {
    return null;
  }

  return (
    <Box
      my='12px'
      p='16px 24px'
      borderRadius='10px'
      border='1px solid'
      borderColor='#dddddd'
      bg='#f9f9f9'
    >
      <Box
        fontWeight='500'
        fontSize='14px'
        lineHeight='24px'
        letterSpacing='-0.01em'
        color='#33383f'
        marginBottom='12px'
      >
        {question.label}
      </Box>

      <AcknowledgeInput currentIndex={currentIndex} />
    </Box>
  );
};

export default Acknowledge;

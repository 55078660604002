import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { QuizLayout } from 'sop-commons/src/utils/cardLayouts';
import Dropdown, { SelectOption } from '../../../atoms/Dropdown';

const OptionLabel: { [key in QuizLayout]: string } = {
  [QuizLayout.SINGLE_CHOICE]: 'single_choice',
  [QuizLayout.MULTI_CHOICE]: 'multi_choice',
};

const AnswerTypeList: SelectOption<QuizLayout>[] = [
  {
    label: 'single_choice',
    value: QuizLayout.SINGLE_CHOICE,
  },
  {
    label: 'multi_choice',
    value: QuizLayout.MULTI_CHOICE,
  },
];

interface IProps {
  value: QuizLayout;
  isReadOnly?: boolean;
  onChange?: (layout: QuizLayout) => void;
}

const AnswerTypeSelect: FC<IProps> = ({ value, isReadOnly, onChange }) => {
  const { t } = useTranslation('card');
  function _onChange(newValue: SelectOption<QuizLayout>) {
    //   setCardData({
    //     ...cardJsonFormat(
    //       CARD_TYPES.quiz,
    //       value?.value,
    //       entityId,
    //       '#fff',
    //       answerOptionsList,
    //       cardData?.title,
    //       cardData?.status
    //     ),
    //   });
    onChange?.(newValue.value);
  }

  return (
    <Dropdown
      options={AnswerTypeList}
      value={{ value, label: OptionLabel[value] }}
      onChange={_onChange}
      size='lg'
      isDisabled={isReadOnly}
      className='member-role-dropdown-container'
      getOptionLabel={(option) => t(option?.label || option)}
      inputStyle={{
        border: 'none',
        borderColor: 'transparent',
        borderRadius: '12px',
        fontSize: '15px',
        outline: 'none',
      }}
    />
  );
};

export default AnswerTypeSelect;

import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import React, { FC } from 'react';
import BodyComponent, {
  IModeType,
} from '../TaskDetailDrawer/Body/BodyComponent';
import { IRefetchType } from '../TaskDetailDrawer/task-detail-drawer.types';

interface IProps {
  mode: IModeType;
  selectedTaskData: CustomLauncherTask;
  onClose: () => void;
  refetchHandler: (type: IRefetchType) => void;
}

const TaskDetailBody: FC<IProps> = ({
  mode,
  selectedTaskData,
  refetchHandler,
  onClose,
}) => {
  return (
    <BodyComponent
      mode={mode}
      selectedTaskData={selectedTaskData}
      refetchHandler={refetchHandler}
      onClose={onClose}
    />
  );
};

export default TaskDetailBody;

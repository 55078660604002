import React, { CSSProperties, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Center, Flex } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Title } from '../../../../ui-components/common';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface IProps {
  userName?: string;
}

const BackButton: FC<IProps> = ({ userName }) => {
  const { t } = useTranslation(['common', 'task']);
  const locState = useLocation<{ name?: string; backTitleKey?: string }>()
    .state;

  const history = useHistory();

  return (
    <Flex gap={3}>
      <Center height='48px' onClick={history.goBack} cursor='pointer'>
        <ArrowBackIcon color='#272B30' boxSize='22px' />
      </Center>
      <Box overflow='hidden'>
        <Title fontSize='24px' style={wrapStyles}>
          {userName}
        </Title>
        <Box
          fontSize='15px'
          fontWeight='400'
          color='#6F767E'
          cursor='pointer'
          onClick={history.goBack}
        >
          {/*// @ts-ignore*/}
          {t(locState?.backTitleKey || 'task:backToAssignees')}
        </Box>
      </Box>
    </Flex>
  );
};

export default BackButton;

import { gql } from '@apollo/client';

export interface UpdatePasswordVariable {
  eid: string;
  password: string;
}

export interface UpdatePasswordResponse {
  changeUsersPassword: {
    succeed: boolean;
  };
}

export const UPDATE_PASSWORD_QUERY = gql`
  mutation ChangeUsersPassword($eid: String!, $password: String!) {
    changeUsersPassword(eid: $eid, password: $password) {
      succeed
    }
  }
`;

export interface ChangePassResponse {
  changePassword: {
    succeed: boolean;
  };
}
export interface ChangePassVariable {
  currentPassword: string;
  newPassword: string;
}

export const CHANGE_PASSWORD_QUERY = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      succeed
    }
  }
`;

import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import { TrainingData } from 'pages/Training/training.types';
import { ChangeLogTableWrapper } from 'pages/TrainingDraft/ChangeLog/ChangeLogList/ChangeLogTable/ChangeLogTable.styles';
import {
  IChangeEventEntity,
  IChangeLogLocationsEntity,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import React, { useEffect, useState } from 'react';
import { IPublishChangeDiffCheckTable } from '../PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import locationIcon from '../../../../assets/images/locationDefault.png';
import { Tooltip } from '@chakra-ui/react';
import { TFunction } from 'i18next';

interface ILocationComponent {
  changedData: IPublishChangeDiffCheckTable[];
  trainingData: TrainingData;
  initialTrainingData: TrainingData;
  thirdStepData: IThirdStepData;
  t: TFunction<('common' | 'training')[], undefined, ('common' | 'training')[]>;
}

interface ICombinedChangedTrainingData {
  changes: ICreateChangeLogChangesEntity;
  name: string;
  icon: string;
}

const LocationComponent = ({
  changedData,
  trainingData,
  initialTrainingData,
  thirdStepData,
  t,
}: ILocationComponent) => {
  const [contentDetails, setContentDetails] = useState<
    ICreateChangeLogChangesEntity[]
  >([]);

  useEffect(() => {
    let _contentDetails: ICreateChangeLogChangesEntity[] = [];
    let _locationsAdded: IChangeLogLocationsEntity[] = [];
    let _locationsRemoved: IChangeLogLocationsEntity[] = [];
    changedData?.map((change) => {
      if (
        change?.tableType === 'locationAdded' ||
        change?.tableType === 'locationRemoved'
      ) {
        change?.tableData?.changes?.map((_change) => {
          let foundLocation = thirdStepData?.locations?.find(
            (location) => location?.id === _change?.locationId
          );
          if (foundLocation) {
            if (change?.tableType === 'locationAdded') {
              _locationsAdded?.push({
                eid: foundLocation?.id,
                name: foundLocation?.value,
                profilePic: foundLocation?.url || '',
              });
            } else {
              _locationsRemoved?.push({
                eid: foundLocation?.id,
                name: foundLocation?.value,
                profilePic: foundLocation?.url || '',
              });
            }
          }
        });
        _contentDetails?.push({
          changeElement: 'location',
          changeEvent: change?.tableType,
          locations:
            change?.tableType === 'locationAdded'
              ? _locationsAdded
              : _locationsRemoved,
        });
      }
    });
    setContentDetails(_contentDetails);
  }, [trainingData?.contentDetails, initialTrainingData?.contentDetails]);

  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className='width-30'>{t('training:location_table_heading')}</th>
          <th>{t('common:update')}</th>
        </tr>
      </thead>
    );
  };

  const getTableBody = (contentDetails: ICreateChangeLogChangesEntity[]) => {
    return contentDetails?.map((content, index) => {
      return (
        <tr key={JSON.stringify(content) + index}>
          <td>{getChangeNameHandler(content?.changeEvent)}</td>
          <td>
            <div className='status-cell'>
              {updatedComparisonsHandler(
                content?.changeEvent,
                content?.locations || []
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  const getChangeNameHandler = (eventType: IChangeEventEntity) => {
    switch (eventType) {
      case 'locationAdded':
        return t('training:location_add_publish_change');
      case 'locationRemoved':
        return t('training:location_delete_publish_change');
    }
  };

  const getMoreLocationStrings = (locations: IChangeLogLocationsEntity[]) => {
    let strArr: string[] = [];
    locations?.map((location, index) => {
      if (index > 1) {
        strArr.push(location?.name);
      }
    });
    return strArr.join(', ');
  };

  const getMoreLocations = (
    locations: IChangeLogLocationsEntity[],
    type: 'add' | 'remove'
  ) => {
    let count = 0;
    locations?.map((location, index) => {
      if (index > 1) {
        count++;
      }
    });
    return (
      <Tooltip hasArrow label={getMoreLocationStrings(locations)}>
        <div
          className={
            type === 'add'
              ? 'status new-status cursor-pointer'
              : 'status old-status cursor-pointer'
          }
        >
          <span
            className={
              type === 'add'
                ? 'status-text new-status-text'
                : 'status-text old-status-text'
            }
          >
            +{count} more
          </span>
        </div>
      </Tooltip>
    );
  };

  const updatedComparisonsHandler = (
    eventType: IChangeEventEntity,
    locations: IChangeLogLocationsEntity[]
  ) => {
    switch (eventType) {
      case 'locationAdded':
        return (
          <>
            {locations?.map((location, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status new-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={locationIcon}
                        style={{
                          borderRadius: '5px',
                          backgroundColor: '#83bf6e',
                          padding: '5px',
                        }}
                      />
                    </div>
                    <span className='status-text new-status-text'>
                      {location?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {locations &&
              locations?.length > 2 &&
              getMoreLocations(locations, 'add')}
          </>
        );
      case 'locationRemoved':
        return (
          <>
            {locations?.map((location, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status old-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={locationIcon}
                        style={{
                          borderRadius: '5px',
                          backgroundColor: '#ff6a55',
                          padding: '5px',
                        }}
                      />
                    </div>
                    <span className='status-text old-status-text'>
                      {location?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {locations &&
              locations?.length > 2 &&
              getMoreLocations(locations, 'remove')}
          </>
        );
    }
  };

  return (
    <>
      {contentDetails?.length > 0 ? (
        <ChangeLogTableWrapper>
          <table>
            {getTableHeader()}
            {getTableBody(contentDetails)}
          </table>
        </ChangeLogTableWrapper>
      ) : null}
    </>
  );
};

export default LocationComponent;

import { IHandBookPagination } from 'pages/Training/CreateTrainingPath/CreateContainer/CardsList/CardsListGrid/CardsListGrid';
import { TrainingData } from 'pages/Training/training.types';
import React from 'react';
import { Training } from 'ui-components/ActivityFeed/activity-feed.types';
import {
  IChangeEventEntity,
  ICreateChangeLogChangesEntity,
} from '../training-draft-types/training-draft-types';

type ISupervisorAssigneeUserData = {
  authRole: string;
  eid: string;
  name: string;
  profilePic: string;
  role: string;
  type: string;
  checked?: boolean;
  locations: {
    name: string;
  };
};

type INewData = {
  title: string;
  thumbnail: string;
  thumbnailColor: string;
  description: string;
  supervisors: string[];
  assignedTo: string[];
  assignedToLocation: string[];
  assignedToRole: string[];
  contents: {
    eid: string;
    title: string;
    type: string;
    backgroundColor?: string | undefined | null;
  }[];
  status: string;
  eid?: string | undefined;
};

const titleEditedHandler = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  oldName: string,
  newName: string
) => {
  let _dataUpdation: ICreateChangeLogChangesEntity[] = JSON.parse(
    JSON.stringify(dataUpdation)
  );
  let flag = false;
  let isSame = false;
  if (oldName === newName) {
    isSame = true;
  }
  if (!isSame) {
    _dataUpdation.map((update) => {
      if (update?.changeEvent === 'titleEdited') {
        flag = true;
        if (update && update.title?.old && update.title.new) {
          update.title.old = oldName;
          update.title.new = newName;
        }
      }
    });
    if (!flag) {
      let tempObj: ICreateChangeLogChangesEntity = {
        changeElement: 'basic',
        changeEvent: 'titleEdited',
        title: {
          old: oldName,
          new: newName,
        },
      };
      setDataUpdation([...dataUpdation, tempObj]);
    } else {
      setDataUpdation(_dataUpdation);
    }
  }
};

const mileStoneEditedHandler = () => {};

const iconsUpdated = () => {};

const assigneeAdded = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  items: string[]
) => {
  let tempArr: ICreateChangeLogChangesEntity[] = [];
  if (items.length > 0) {
    for (let item of items) {
      tempArr.push({
        changeElement: 'assignee',
        changeEvent: 'assigneeAdded',
        userId: item,
      });
    }
  }
  setDataUpdation([...dataUpdation, ...tempArr]);
};

const assigneeRemoved = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  items: string[]
) => {
  let tempArr: ICreateChangeLogChangesEntity[] = [];
  if (items.length > 0) {
    for (let item of items) {
      tempArr.push({
        changeElement: 'assignee',
        changeEvent: 'assigneeRemoved',
        userId: item,
      });
    }
  }
  setDataUpdation([...dataUpdation, ...tempArr]);
};

const supervisorAdded = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  items: string[]
) => {
  let tempArr: ICreateChangeLogChangesEntity[] = [];
  if (items.length > 0) {
    for (let item of items) {
      tempArr.push({
        changeElement: 'supervisor',
        changeEvent: 'supervisorAdded',
        userId: item,
      });
    }
  }
  setDataUpdation([...dataUpdation, ...tempArr]);
};

const supervisorRemoved = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  items: string[]
) => {
  let tempArr: ICreateChangeLogChangesEntity[] = [];
  if (items.length > 0) {
    for (let item of items) {
      tempArr.push({
        changeElement: 'supervisor',
        changeEvent: 'supervisorRemoved',
        userId: item,
      });
    }
  }
  setDataUpdation([...dataUpdation, ...tempArr]);
};

const cardsCardDeckSortAdditionHandler = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  selectedData: IHandBookPagination
) => {
  if (selectedData?.type === 'card') {
    cardAdded(setDataUpdation, dataUpdation, selectedData);
  } else if (selectedData?.type === 'deck') {
    deckAdded(setDataUpdation, dataUpdation, selectedData);
  }
};

const cardsCardDeckSortRemovalHandler = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  selectedData: IHandBookPagination
) => {
  if (selectedData?.type === 'card') {
    cardRemoved(setDataUpdation, dataUpdation, selectedData);
  } else if (selectedData?.type === 'deck') {
    deckRemoved(setDataUpdation, dataUpdation, selectedData);
  }
};

const cardAdded = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  selectedData: IHandBookPagination
) => {
  let tempObj: ICreateChangeLogChangesEntity = {
    changeElement: 'card',
    changeEvent: 'cardAdded',
    contentId: selectedData?.eid,
  };
  setDataUpdation([...dataUpdation, tempObj]);
};

const cardRemoved = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  selectedData: IHandBookPagination
) => {
  let tempObj: ICreateChangeLogChangesEntity = {
    changeElement: 'card',
    changeEvent: 'cardRemoved',
    contentId: selectedData?.eid,
  };
  setDataUpdation([...dataUpdation, tempObj]);
};

const deckAdded = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  selectedData: IHandBookPagination
) => {
  let tempObj: ICreateChangeLogChangesEntity = {
    changeElement: 'deck',
    changeEvent: 'deckAdded',
    contentId: selectedData?.eid,
  };
  setDataUpdation([...dataUpdation, tempObj]);
};

const deckRemoved = (
  setDataUpdation: React.Dispatch<
    React.SetStateAction<ICreateChangeLogChangesEntity[]>
  >,
  dataUpdation: ICreateChangeLogChangesEntity[],
  selectedData: IHandBookPagination
) => {
  let tempObj: ICreateChangeLogChangesEntity = {
    changeElement: 'deck',
    changeEvent: 'deckRemoved',
    contentId: selectedData?.eid,
  };
  setDataUpdation([...dataUpdation, tempObj]);
};

const milestoneAdded = () => {};

const milestoneRemoved = () => {};

const orderChanged = () => {};

export const changeLogDataOperationHandler = (
  originalData: TrainingData,
  newData: INewData
) => {
  let changesArray: ICreateChangeLogChangesEntity[] = [];
  if (originalData?.title !== newData?.title) {
    changesArray?.push({
      changeElement: 'basic',
      changeEvent: 'titleEdited',
      title: {
        old: originalData?.title,
        new: newData?.title,
      },
    });
  }

  if (originalData?.thumbnail !== newData?.thumbnail) {
    changesArray?.push({
      changeElement: 'basic',
      changeEvent: 'iconsUpdated',
      icon: {
        old: originalData?.thumbnail,
        new: newData?.thumbnail,
      },
    });
  }
  if (
    (originalData?.supervisors && originalData?.supervisors?.length > 0) ||
    newData?.supervisors?.length > 0
    // ((originalData?.supervisors && originalData?.supervisors?.length > 0) && newData?.supervisors?.length === 0) ||
    // ((originalData?.supervisors &&
    //     originalData?.supervisors?.length > 0) &&
    //     newData?.supervisors?.length > 0)
  ) {
    let originalObject: any[] = [];
    let newObject: any[] = [];
    originalData?.supervisors?.map((sup) => {
      originalObject?.push({
        value: sup,
      });
    });
    newData?.supervisors?.map((sup) => {
      newObject?.push({
        value: sup,
      });
    });
    const { addedItems, removedItems } = addedRemovedGenerator(
      originalObject,
      newObject,
      'value'
    );
    if (addedItems?.length > 0) {
      addedItems?.map((add) => {
        changesArray?.push({
          changeElement: 'supervisor',
          changeEvent: 'supervisorAdded',
          userId: add?.value,
        });
      });
    }
    if (removedItems?.length > 0) {
      removedItems?.map((add) => {
        changesArray?.push({
          changeElement: 'supervisor',
          changeEvent: 'supervisorRemoved',
          userId: add?.value,
        });
      });
    }
  }

  if (
    (originalData?.assignedToLocations &&
      originalData?.assignedToLocations?.length > 0) ||
    newData?.assignedToLocation?.length > 0
    // (originalData?.assignedToLocations?.length === 0 && newData?.assignedToLocation?.length > 0) ||
    // ((originalData?.assignedToLocations && originalData?.assignedToLocations?.length > 0) && newData?.assignedToLocation?.length === 0) ||
    // ((originalData?.assignedToLocations && originalData?.assignedToLocations?.length > 0) && newData?.assignedToLocation?.length > 0)
  ) {
    let originalObject: any[] = [];
    let newObject: any[] = [];
    originalData?.assignedToLocations?.map((sup) => {
      originalObject?.push({
        value: sup?.eid,
      });
    });
    newData?.assignedToLocation?.map((sup) => {
      newObject?.push({
        value: sup,
      });
    });
    const { addedItems, removedItems } = addedRemovedGenerator(
      originalObject,
      newObject,
      'value'
    );
    if (addedItems?.length > 0) {
      addedItems?.map((add) => {
        changesArray?.push({
          changeElement: 'location',
          changeEvent: 'locationAdded',
          locationId: add?.value,
        });
      });
    }
    if (removedItems?.length > 0) {
      removedItems?.map((add) => {
        changesArray?.push({
          changeElement: 'location',
          changeEvent: 'locationRemoved',
          locationId: add?.value,
        });
      });
    }
  }

  if (
    originalData?.assignedRoles?.length > 0 ||
    newData?.assignedToRole?.length > 0
    // (originalData?.assignedRoles?.length === 0 &&
    //     newData?.assignedToRole?.length > 0) ||
    // ((originalData?.assignedRoles &&
    //     originalData?.assignedRoles?.length > 0) &&
    //     newData?.assignedToRole?.length === 0) ||
    // (originalData?.assignedRoles?.length > 0 && ((originalData?.assignedRoles &&
    //     originalData?.assignedRoles?.length > 0)))
  ) {
    let originalObject: any[] = [];
    let newObject: any[] = [];
    originalData?.assignedRoles?.map((sup) => {
      originalObject?.push({
        value: sup?.name,
      });
    });
    newData?.assignedToRole?.map((sup) => {
      newObject?.push({
        value: sup,
      });
    });
    const { addedItems, removedItems } = addedRemovedGenerator(
      originalObject,
      newObject,
      'value'
    );
    if (addedItems?.length > 0) {
      addedItems?.map((add) => {
        changesArray?.push({
          changeElement: 'role',
          changeEvent: 'roleAdded',
          roles: [add?.value],
        });
      });
    }
    if (removedItems?.length > 0) {
      removedItems?.map((add) => {
        changesArray?.push({
          changeElement: 'role',
          changeEvent: 'roleRemoved',
          roles: [add?.value],
        });
      });
    }
  }
  if (
    (originalData?.assignedTo && originalData?.assignedTo?.length > 0) ||
    newData?.assignedTo?.length > 0
    // (originalData?.assignedTo?.length === 0 &&
    //     newData?.assignedTo?.length > 0) ||
    // ((originalData?.assignedTo &&
    //     originalData?.assignedTo?.length > 0) &&
    //     newData?.assignedTo?.length > 0)
  ) {
    let originalObject: any[] = [];
    let newObject: any[] = [];
    originalData?.assignedTo?.map((sup) => {
      originalObject?.push({
        value: sup,
      });
    });
    newData?.assignedTo?.map((sup) => {
      newObject?.push({
        value: sup,
      });
    });
    const { addedItems, removedItems } = addedRemovedGenerator(
      originalObject,
      newObject,
      'value'
    );
    if (addedItems?.length > 0) {
      addedItems?.map((add) => {
        changesArray?.push({
          changeElement: 'assignee',
          changeEvent: 'assigneeAdded',
          userId: add?.value,
        });
      });
    }
    if (removedItems?.length > 0) {
      removedItems?.map((add) => {
        changesArray?.push({
          changeElement: 'assignee',
          changeEvent: 'assigneeRemoved',
          userId: add?.value,
        });
      });
    }
  }

  if (
    (originalData?.contentDetails &&
      originalData?.contentDetails?.length > 0) ||
    newData?.contents?.length > 0
    // (originalData?.contentDetails?.length === 0 &&
    //     newData?.contents?.length > 0) ||
    // ((originalData?.contentDetails &&
    //     originalData?.contentDetails?.length > 0) &&
    //     newData?.contents?.length > 0)
  ) {
    newData?.contents?.map((content) => {
      if (content?.type === 'milestone' && !content?.eid) {
        changesArray?.push({
          changeElement: 'milestone',
          changeEvent: 'milestoneAdded',
          title: {
            old: null,
            new: content?.title,
          },
        });
      }
    });
    const { addedItems, removedItems } = addedRemovedGenerator(
      originalData?.contentDetails,
      newData?.contents,
      '_eid'
    );
    if (addedItems?.length > 0) {
      addedItems?.map((add) => {
        if (add?.type === 'card' || add?.type === 'deck') {
          changesArray?.push({
            changeElement: add?.type === 'card' ? 'card' : 'deck',
            changeEvent: add?.type === 'card' ? 'cardAdded' : 'deckAdded',
            contentId: add?.eid,
          });
        }
      });
    }
    if (removedItems?.length > 0) {
      removedItems?.map((add) => {
        if (add?.type === 'milestone') {
          changesArray?.push({
            changeElement:
              add?.type === 'card'
                ? 'card'
                : add?.type === 'deck'
                ? 'deck'
                : 'milestone',
            changeEvent:
              add?.type === 'card'
                ? 'cardRemoved'
                : add?.type === 'deck'
                ? 'deckRemoved'
                : 'milestoneRemoved',
            contentId: add?.eid,
            title: {
              old: null,
              new: add?.title,
            },
          });
        } else {
          changesArray.push({
            changeElement:
              add?.type === 'card'
                ? 'card'
                : add?.type === 'deck'
                ? 'deck'
                : 'milestone',
            changeEvent:
              add?.type === 'card'
                ? 'cardRemoved'
                : add?.type === 'deck'
                ? 'deckRemoved'
                : 'milestoneRemoved',
            contentId: add?.eid,
          });
        }
      });
    }
  }
  let orderChanges = orderChangeGenerator(originalData, newData);
  if (orderChanges && orderChanges?.length > 0) {
    orderChanges?.map((order) => {
      if (order?.type !== 'milestone') {
        changesArray?.push({
          changeElement: order?.type,
          changeEvent: 'orderChanged',
          contentId: order?.eid,
        });
      } else {
        changesArray?.push({
          changeElement: order?.type,
          changeEvent: 'orderChanged',
          contentId: order?.eid,
          title: {
            old: order?.old ? order?.old : null,
            new: order?.new ? order?.new : order?.title ? order?.title : '',
          },
        });
      }
    });
  }
  let checkMilestoneChanged = checkMilestoneChangedHandler(
    originalData,
    newData
  );
  if (checkMilestoneChanged?.length > 0) {
    checkMilestoneChanged?.map((check) => {
      changesArray?.push({
        changeElement: 'milestone',
        changeEvent: 'mileStoneEdited',
        title: {
          old: check?.old,
          new: check?.new,
        },
        contentId: check?.eid,
      });
    });
  }

  let _changesArray: ICreateChangeLogChangesEntity[] = [];
  let addedRoles: string[] = [];
  let removedRoles: string[] = [];
  changesArray?.map((change) => {
    if (change?.changeEvent === 'roleAdded') {
      addedRoles?.push(change?.roles?.[0]!);
    } else if (change?.changeEvent === 'roleRemoved') {
      removedRoles?.push(change?.roles?.[0]!);
    } else {
      _changesArray?.push(change);
    }
  });
  if (addedRoles?.length > 0) {
    _changesArray?.push({
      changeElement: 'role',
      changeEvent: 'roleAdded',
      roles: addedRoles,
    });
  }
  if (removedRoles?.length > 0) {
    _changesArray?.push({
      changeElement: 'role',
      changeEvent: 'roleRemoved',
      roles: removedRoles,
    });
  }
  return _changesArray;
};

const checkMilestoneChangedHandler = (
  originalData: TrainingData,
  newData: INewData
) => {
  let changedMilestones: {
    eid: string;
    old: string;
    new: string;
  }[] = [];
  let originalContents = originalData?.contentDetails;
  let newContents = newData?.contents;
  for (let i in originalContents) {
    for (let j in newContents) {
      if (
        originalContents?.[+i]?.eid === newContents?.[+j]?.eid &&
        originalContents?.[+i]?.title !== newContents?.[+j]?.title &&
        originalContents?.[+i]?.type === 'milestone'
      ) {
        changedMilestones.push({
          eid: originalContents?.[+i]?.eid,
          old: originalContents?.[+i]?.title,
          new: newContents?.[+j]?.title,
        });
      }
    }
  }
  return changedMilestones;
};

const addedRemovedGenerator = (
  originalObject: any,
  newObject: any,
  queryKey: string
) => {
  let removedItems: any[] = [];
  let originalArray = originalObject;
  let newArray = newObject;

  originalArray?.filter((x: any) => {
    let xKey = queryKey;
    if (queryKey !== 'value') {
      xKey = Object?.keys(x)?.includes('eid') ? 'eid' : '_eid';
    }
    let flag = !newArray
      ?.map((newA: any) => {
        let newAKey = queryKey;
        if (queryKey !== 'value') {
          newAKey = Object?.keys(newA)?.includes('eid') ? 'eid' : '_eid';
        }
        return newA[newAKey];
      })
      ?.includes(x[xKey]);
    if (flag) {
      removedItems.push(x);
    }
  });
  let addedItems: any[] = [];
  newArray?.filter((x: any) => {
    let xKey = queryKey;
    if (queryKey !== 'value') {
      xKey = Object?.keys(x)?.includes('eid') ? 'eid' : '_eid';
    }
    let flag = !originalArray
      ?.map((orA: any) => {
        let newAKey = queryKey;
        if (queryKey !== 'value') {
          newAKey = Object?.keys(orA)?.includes('eid') ? 'eid' : '_eid';
        }
        return orA[newAKey];
      })
      ?.includes(x[xKey]);
    if (flag) {
      addedItems.push(x);
    }
  });
  return { addedItems, removedItems };
};

const orderChangeGenerator = (originalSequence: any, newSequence: any) => {
  let { arrOriginal, arrNew } = getSameElementsHandler(
    originalSequence,
    newSequence
  );
  return getChangedSequencesHandler(arrOriginal, arrNew);
};

const getSameElementsHandler = (originalSequence: any, newSequence: any) => {
  let arrOriginal: any[] = [];
  originalSequence?.contents?.map((one: any) => {
    newSequence?.contents?.map((two: any) => {
      if (one?.eid === two?.eid) {
        arrOriginal.push(one);
      }
    });
  });
  let arrNew: any[] = [];
  newSequence?.contents.map((one: any) => {
    originalSequence?.contents.map((two: any) => {
      if (one?.eid === two?.eid) {
        arrNew.push(one);
      }
    });
  });
  return { arrOriginal, arrNew };
};

const getChangedSequencesHandler = (arrOriginal: any, arrNew: any) => {
  let orderArr: any[] = [];
  arrOriginal?.map((a: any, aIndex: number) => {
    arrNew?.map((se: any, seIndex: number) => {
      if (aIndex === seIndex && a?.eid !== se?.eid) {
        orderArr.push(se);
      }
    });
  });
  return orderArr;
};

export const TrainingDraftMethods = {
  titleEditedHandler,
  mileStoneEditedHandler,
  iconsUpdated,
  supervisorAdded,
  supervisorRemoved,
  assigneeAdded,
  assigneeRemoved,
  cardsCardDeckSortAdditionHandler,
  cardsCardDeckSortRemovalHandler,
  milestoneAdded,
  milestoneRemoved,
  orderChanged,
  changeLogDataOperationHandler,
};

import { IVisibility } from 'pages/Chapters/CreateChapterModal/UploadFile/UploadFile';
import React from 'react';
import { AuthRole, UserData } from 'sop-commons/src/client';
import { ChapterEditorState } from 'zustandStore/slices/useChapterEditor';
import { KnowledgeBaseState } from 'zustandStore/slices/useKnowledgeBase';
import { VisibilityState } from 'zustandStore/slices/useVisibility';
import {
  IConditionType,
  ILocationData,
  IRoleData,
  IType,
  IUserData,
} from './set-see-modal-types';
import { ISetSeeDataContext } from './SetSeeModalStore/set-see-data-context';

export const dataSetHandler = (
  isCreate: boolean | undefined,
  visibilityData:
    | {
        condition?: IConditionType;
        locations?: string[] | undefined;
        roles?: string[] | undefined;
        users?: string[] | undefined;
      }
    | undefined,
  newSetVisibility: any,
  setSeeCtx: ISetSeeDataContext,
  userData: UserData,
  store: KnowledgeBaseState & ChapterEditorState & VisibilityState
) => {
  let passedData = !isCreate ? visibilityData : newSetVisibility;
  if (
    (!store?.customSectionExpand &&
      passedData?.locations?.length === 0 &&
      passedData?.roles?.length === 0 &&
      passedData?.users?.length === 0 &&
      setSeeCtx?.selectedLocationsList?.length === 0 &&
      setSeeCtx?.selectedRolesList?.length === 0 &&
      setSeeCtx?.selectedMembersList?.length === 0) ||
    store?.visibilitySelection?.visibility === 'public'
  ) {
    store?.updateCustomSectionExpand(false);
    if (store?.visibilitySelection) {
      store?.updateVisibilitySelection({
        ...store?.visibilitySelection,
        visibility: 'public',
      });
    }
    // setSeeCtx?.updateSelectionType('public');
    // setSeeCtx?.updateExpandAccordion('0');
  } else {
    store?.updateCustomSectionExpand(true);
    if (store?.visibilitySelection) {
      store?.updateVisibilitySelection({
        ...store?.visibilitySelection,
        visibility: 'private',
      });
    }
    // setSeeCtx?.updateSelectionType('private');
    // setSeeCtx?.updateExpandAccordion('1');
    if (
      passedData?.condition &&
      passedData?.locations?.length > 0 &&
      passedData?.roles?.length > 0
    ) {
      if (store?.visibilitySelection) {
        store?.updateVisibilitySelection({
          ...store?.visibilitySelection,
          condition: passedData?.condition,
        });
      }
      // setSeeCtx?.updateConditionType(passedData?.condition);
      setSeeCtx?.updateShowCondition(true);
    }
  }
  if (passedData?.locations?.length === 0 && passedData?.roles?.length > 0) {
    setSeeCtx?.updateSelectedFirstDropdown({ label: 'Role', value: 'Role' });
  }
  if (passedData?.users && passedData?.users?.length > 0) {
    let obj: { value: string }[] = [];
    for (let i in passedData?.users) {
      obj[+i] = {
        value: passedData?.users[i],
      };
    }
    const res = setSeeCtx?.membersList.filter((user) =>
      obj?.some((y) => {
        return y.value === user.eid;
      })
    );
    setSeeCtx?.updateSelectedMembersList(res);
  }

  if (
    userData?.authRole === AuthRole.LOCATION_OWNER &&
    passedData?.visibility !== 'public'
  ) {
    setSeeCtx?.updateSelectionType('private');
    setSeeCtx?.updateExpandAccordion('1');
  }

  if (passedData?.locations && passedData?.locations?.length > 0) {
    let objLocation: { value: string }[] = [];
    for (let i in passedData?.locations) {
      objLocation[+i] = {
        value: passedData?.locations[i],
      };
    }
    const resLocation = setSeeCtx?.locationsList.filter((location) =>
      objLocation?.some((y) => {
        return y.value === location.eid;
      })
    );
    setSeeCtx?.updateSelectedLocationsList(resLocation);
  }

  if (passedData?.roles && passedData?.roles?.length > 0) {
    let objRole: { value: string }[] = [];
    for (let i in passedData?.roles) {
      objRole[+i] = {
        value: passedData?.roles[i],
      };
    }
    const resRole = setSeeCtx?.rolesList.filter((role) =>
      objRole?.some((y) => {
        return y.value === role.name;
      })
    );
    setSeeCtx?.updateSelectedRolesList(resRole);
  }
};

export const roleListUpdateHandler = (
  userData: UserData,
  setSeeCtx: ISetSeeDataContext
) => {
  const roles = userData?.entity?.roles || [];
  let updatedRolesData: IRoleData[] = roles?.map<IRoleData>((role: any) => {
    return {
      ...role,
      label: role?.name,
      value: role?.name,
    };
  });
  setSeeCtx?.updateRolesList(updatedRolesData);
};

export const generateVisibilityObject = (
  setSeeCtx: ISetSeeDataContext,
  userData: UserData
) => {
  return {
    visibility: (setSeeCtx?.selectionType === 'public'
      ? 'public'
      : 'private') as IType,
    roles:
      setSeeCtx?.selectedRolesList?.length > 0
        ? setSeeCtx?.selectedRolesList?.map((role) => role?.name)
        : [],
    users:
      setSeeCtx?.selectedMembersList?.length > 0
        ? setSeeCtx?.selectedMembersList?.map((user) => user?.eid)
        : [],
    locations:
      setSeeCtx?.selectedLocationsList?.length > 0
        ? setSeeCtx?.selectedLocationsList?.map((location) => location?.eid)
        : userData?.authRole === AuthRole.LOCATION_OWNER
        ? setSeeCtx?.locationsList?.map((location) => location?.eid)
        : [],
    condition: (setSeeCtx?.showCondition &&
    setSeeCtx?.selectedLocationsList?.length > 0 &&
    setSeeCtx?.selectedRolesList?.length > 0
      ? setSeeCtx?.conditionType?.toLowerCase()
      : null) as IConditionType,
  };
};

export const locationUserRoleDataHandler = (
  _data: any,
  _users: any,
  userData: UserData,
  setSeeCtx: ISetSeeDataContext
) => {
  let _usersData = (_users?.userDescendants || [])?.map((user: IUserData) => {
    return {
      ...user,
      label: user.name,
      value: user.name,
    };
  });
  let _locationsData = [];
  if (userData?.authRole === AuthRole.LOCATION_OWNER) {
    let filteredLocationsArray = (
      _data?.branch?.descendantBranches || []
    ).filter((location: ILocationData) => {
      return (userData?.locations || []).find((userLocation) => {
        return userLocation.eid === location.eid;
      });
    });
    _locationsData = filteredLocationsArray;
  } else {
    _locationsData = (_data?.branch?.descendantBranches || [])?.map(
      (user: ILocationData) => {
        return {
          ...user,
          label: user.name,
          value: user.name,
        };
      }
    );
  }
  let _user = (_usersData || [])?.filter(
    (user: any) => user.eid !== userData.eid
  );
  if (_user && Array.isArray(_user) && _user?.length > 0) {
    _user = _user?.sort((a, b) => a?.name?.localeCompare(b?.name));
  }
  setSeeCtx?.updateMembersList(_user);
  setSeeCtx?.updateLocationsList(_locationsData);
  if (
    userData?.authRole === AuthRole.LOCATION_OWNER &&
    _locationsData?.length === 1
  ) {
    setSeeCtx?.updateSelectedLocationsList(_locationsData);
  }
};

export function areObjectsSimilar(
  obj1: IVisibility,
  obj2: IVisibility
): boolean {
  const arraysAreEqual = (arr1: string[], arr2: string[]) =>
    arr1?.length === arr2?.length &&
    arr1.every((value, index) => value === arr2[index]);

  if (
    !arraysAreEqual(obj1?.locations, obj2?.locations) ||
    !arraysAreEqual(obj1?.roles, obj2?.roles) ||
    !arraysAreEqual(obj1?.users, obj2?.users)
  ) {
    return false;
  }
  let obj1Condition =
    obj1?.locations?.length > 0 || obj1?.roles?.length > 0
      ? obj1?.condition
      : undefined;
  let obj2Condition =
    obj2?.locations?.length > 0 || obj2?.roles?.length > 0
      ? obj2?.condition
      : undefined;

  if (
    obj1Condition !== obj2Condition ||
    obj1?.visibility !== obj2?.visibility
  ) {
    return false;
  }

  return true;
}

import React, { FC, useState } from 'react';
import {
  Box,
  Flex,
  List,
  ListItem,
  Radio,
  RadioGroup,
  useModalContext,
} from '@chakra-ui/react';
import { ActionButton } from '../../../../../ui-components';
import { IContentItem } from '../editor.types';

interface IProps {
  categories: Pick<IContentItem, 'eid' | 'category'>[];
  initialValue?: string;
  onUpdate: (newCategory: string) => void | Promise<void>;
}

const ChangePhaseListContent: FC<IProps> = ({
  categories,
  initialValue = '',
  onUpdate,
}) => {
  const [value, setValue] = useState(initialValue);
  const { onClose } = useModalContext();

  return (
    <Flex flexDir='column' pb={3}>
      <RadioGroup value={value} onChange={setValue}>
        <List
          spacing={4}
          mb={4}
          sx={{
            '.chakra-radio__control': {
              _checked: {
                borderColor: 'blue.500',
                background: 'white',
                color: 'blue.500',
                _hover: {
                  background: 'white',
                },
              },
            },
          }}
        >
          {categories.map((phase) => {
            return (
              <ListItem key={phase.eid} borderBottom='1px solid #EFEFEF'>
                <Flex gap={3} pb={4} pr={2}>
                  <Box
                    flex={1}
                    isTruncated
                    color='#171725'
                    fontWeight={phase.eid === value ? 600 : 400}
                  >
                    {phase.category}
                  </Box>
                  <Radio value={phase.eid} />
                </Flex>
              </ListItem>
            );
          })}
        </List>
      </RadioGroup>

      <ActionButton
        size='lg'
        width='full'
        colorScheme='blue'
        fontSize='15px'
        actionFn={() => onUpdate?.(value)}
        close={onClose}
      >
        Update selection
      </ActionButton>
      <Box color='#6F767E' fontSize='13px' textAlign='center' mt={1}>
        The task will move to the new selected task phase
      </Box>
    </Flex>
  );
};

export default ChangePhaseListContent;

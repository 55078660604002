import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

interface IProps {}

const DelightreeTips: FC<IProps> = () => {
  const { t } = useTranslation('form');
  return (
    <Flex
      mt={5}
      gap={3}
      px={6}
      py={4}
      bg='rgba(177, 229, 252, 0.4)'
      borderRadius='10px'
    >
      <Box>
        <FontAwesomeIcon
          icon={faCircleExclamation as IconProp}
          color='#2A85FF'
          fontSize='24px'
        />
      </Box>
      <Box
        flex='1'
        fontSize='14px'
        fontWeight='400'
        lineHeight='26px'
        color='#33383F'
      >
        <Box fontWeight='700' color='#2A85FF'>
          {t('delightree_tip')}
        </Box>
        {t('delightree_tip_data')}
      </Box>
    </Flex>
  );
};

export default DelightreeTips;

import React, { FC } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface IProps extends BoxProps {}

const Title: FC<IProps> = ({ children, ...rest }) => {
  return (
    <Box
      fontStyle='normal'
      fontSize='28px'
      lineHeight='48px'
      fontWeight='700'
      color='#272B30'
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Title;

import moment from 'moment-timezone';

export const getUserTimezoneConvertedDate = (
  loggedInUserTimezone: string,
  date: string
) => {
  let _date = moment(moment?.utc(date), 'DD-MMM-YYYY hh:mm a')?.tz(
    loggedInUserTimezone
  );
  let convertedDate = moment?.utc(
    _date?.format('DD-MMM-YYYY hh:mm a'),
    'DD-MMM-YYYY hh:mm a'
  );
  return convertedDate;
};

export const getUserTimezoneConvertedNow = (loggedInUserTimezone: string) => {
  const now = moment?.utc(
    moment()?.tz(loggedInUserTimezone)?.format('DD-MMM-YYYY hh:mm a'),
    'DD-MMM-YYYY hh:mm a'
  );
  return now;
};

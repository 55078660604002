import { useEffect, useState } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import { PlusOutlined, CheckOutlined, RightOutlined } from '@ant-design/icons';
import { SeenUserList } from './SeenUserList';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { useReactiveVar } from '@apollo/client';

import { ReactComponent as BlueDoubleTick } from './resources/message-read.svg';
const SeenByUsers = ({
  messageId,
  receiptIcon,
  message,
  loggedInUserId,
  actionGenerated,
  deliverToUsers,
  groupController,
}) => {
  const [readUsers, setReadUsers] = useState([]);
  const [readUsersIdObj, setReadUsersIdObj] = useState({});
  const [deliverToUsersList, setDeliverToUsersList] = useState(deliverToUsers);
  const [isUserDialogOpened, setIsUserDialogOpened] = useState(false);
  const userObject = useReactiveVar(userObj);
  useEffect(() => {
    let listenerID = `${userObject?.eid}:${userObject?.entity?.eid}`;

    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onMessagesRead: (messageReceipt) => {
          if (
            !readUsersIdObj[messageReceipt?.sender?.uid] &&
            messageReceipt?.sender?.uid !== loggedInUserId &&
            // readUsers.indexOf(messageReceipt?.sender?.uid) === -1 &&
            readUsers.findIndex(
              (value) => value?.sender?.uid === messageReceipt?.sender?.uid
            ) === -1 &&
            messageReceipt?.receiptType === 'read'
          ) {
            readUsersIdObj[messageReceipt?.sender?.uid] =
              messageReceipt?.sender?.uid;
            readUsers.push(messageReceipt);
            setReadUsersIdObj(readUsersIdObj);
            setReadUsers([...readUsers]);
          }
        },
      })
    );

    CometChat.getMessageReceipts(messageId, message).then(
      (receipts) => {
        console.log('On Recipents fetched', receipts);
        // console.log('Message details fetched:', receipts, messageId, message);
        let receiptsWithoutLoginUser = [];
        receipts.forEach((data) => {
          if (
            data?.sender?.uid !== loggedInUserId &&
            receiptsWithoutLoginUser.indexOf(data?.sender?.uid) === -1 &&
            data?.receiptType === 'read'
          ) {
            receiptsWithoutLoginUser.push(data);
            readUsersIdObj[data?.sender?.uid] = data?.sender?.uid;
          }
        });
        setReadUsers(receiptsWithoutLoginUser);
        setReadUsersIdObj(readUsersIdObj);
      },
      (error) => {
        console.log('Error in getting messag details ', error);
      }
    );
    setDeliverToUsersList(deliverToUsers);
  }, []);

  let memberCount = message?.receiver?.membersCount - 1;
  if (
    message?.receiver?.owner === 'delightree-chatbot-staging' ||
    message?.receiver?.owner === 'delightree-chatbot'
  ) {
    memberCount = memberCount - 1;
  }

  return (
    <>
      <div
        className='seen-by-user-container'
        onClick={() => {
          if (readUsers?.length !== memberCount) {
            setIsUserDialogOpened(true);
          }
        }}
      >
        {readUsers?.length === memberCount && (
          <>
            {/* <img src={blueDoubleTick} /> */}
            <BlueDoubleTick />
          </>
        )}
        {readUsers?.length !== memberCount && (
          <>
            <CheckOutlined style={{ fontSize: '13px', marginRight: '4px' }} />
            {readUsers?.length}/{memberCount}
            <div className='seen-by-user-container-separator'></div>
          </>
        )}
      </div>
      {isUserDialogOpened && (
        <SeenUserList
          readReceipts={readUsers}
          actionGenerated={actionGenerated}
          close={() => setIsUserDialogOpened(false)}
          readUsersIdObj={readUsersIdObj}
          deliverToUsersList={deliverToUsersList}
          groupController={groupController}
          grouopId={message.receiverId}
          message={message}
        />
      )}
    </>
  );
};

export default SeenByUsers;

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { match } from 'ts-pattern';
import { chakraComponents } from 'chakra-react-select';

import { Trans, useTranslation } from 'react-i18next';

import { SearchInput, SelectOption } from '../../../../../atoms';
import Dropdown from '../../../../../atoms/Dropdown';

import { MemberInput } from '../create-channel.types';

import GroupOptionList from './GroupOptionList';
import { useJobLocationData } from './useJobLocationData';

interface IProps {}

const SelectGroupContent: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'setting', 'team', 'handbook']);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [filterBy, setFilterBy] = useState<SelectOption | null>(() => {
    return {
      label: t('common:all'),
      value: 'all',
    };
  });

  const { control } = useFormContext<MemberInput>();

  const { jobs, locations, roles } = useJobLocationData();

  const _options = useMemo(() => {
    return match(filterBy?.value)
      .with('job', () => jobs)
      .with('role', () => roles)
      .with('location', () => locations)
      .otherwise(() => [...roles, ...locations, ...jobs]);
  }, [jobs, locations, roles, filterBy?.value]);

  const options = useMemo(() => {
    if (searchQuery) {
      return _options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    return _options;
  }, [_options, searchQuery]);

  const _onFilterChange = useCallback(
    (option: SelectOption) => {
      if (option?.value === filterBy?.value) {
        setFilterBy({
          label: t('common:all'),
          value: 'all',
        });
      } else {
        setFilterBy(option);
      }
    },
    [filterBy]
  );

  return (
    <div>
      <Flex gap={3}>
        <Box flex={2}>
          <SearchInput
            hideShortcuts
            placeholder={t('setting:searchByNameJobOrLoc')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Box>

        <Dropdown
          placeholder={t('handbook:select_filter')}
          value={filterBy}
          onChange={_onFilterChange}
          options={[
            {
              label: t('common:all'),
              value: 'all',
            },
            {
              label: t('setting:roles'),
              value: 'role',
            },
            {
              label: t('team:jobs'),
              value: 'job',
            },
            {
              label: t('setting:locations'),
              value: 'location',
            },
          ]}
          selectStyles={{
            container: {
              flex: 1,
              minW: '180px',
            },
            menu: {
              zIndex: 2,
            },
            singleValue: {
              fontSize: '14px',
              color: '#6f767e',
              fontWeight: '600',
            },
            placeholder: {
              whiteSpace: 'nowrap',
            },
          }}
          components={{
            SingleValue: (valProps) => {
              return (
                <chakraComponents.SingleValue {...valProps}>
                  <Trans
                    t={t}
                    i18nKey='setting:showValue'
                    values={{
                      value: valProps.children,
                    }}
                  />
                </chakraComponents.SingleValue>
              );
            },
          }}
        />
      </Flex>
      <Controller
        control={control}
        name='addToChannel'
        render={({ field }) => {
          return (
            <GroupOptionList
              options={options}
              value={field.value}
              anyFilterActive={!!searchQuery || !!filterBy?.value}
              onChange={field.onChange}
            />
          );
        }}
      />
    </div>
  );
};

export default SelectGroupContent;

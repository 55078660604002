import React, { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';

interface IMainHeading {
  name: string;
  isLive?: boolean;
}

const MainSection: FC<IMainHeading> = ({ name, isLive }) => {
  if (isLive) {
    return (
      <Text
        color='#272B30'
        fontSize='40px'
        fontWeight={400}
        textAlign='center'
        lineHeight='52px !important'
      >
        Congratulations!
        <Box as='span' fontWeight={800} px={2}>
          {name}
        </Box>
        is live
      </Text>
    );
  }

  return (
    <Text
      color='#272B30'
      fontSize='40px'
      fontWeight={400}
      textAlign='center'
      lineHeight='52px !important'
    >
      Make
      <Box as='span' color='#2A85FF' fontWeight={800} px={2}>
        {name}
      </Box>
      live?
    </Text>
  );
};

export default MainSection;

import gql from "graphql-tag";

export const getAllTrainings = gql`
  query UserTraining(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
  ) {
    userTrainingNew(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        title
        entityId
        createdBy {
          email
          username
          name
          phone
          profilePic
          eid
        }
        lastUpdatedBy {
          name
          profilePic
          eid
        }
        status
        thumbnail
        thumbnailColor
        description
        visibility
        supervisors
        assignedTo
        userProgress
        updatedAt
        createdAt
        eid
        assignedToUsers {
          eid
          profilePic
          name
        }
        contentDetails
        trainingContentList
        trainingContentExpanded
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const getSingleTrainingData = gql`
  query TrainingById($eid: String!) {
    trainingById(eid: $eid) {
      title
      entityId
      status
      thumbnail
      description
      contents {
        type
        eid
        title
      }
      visibility
      supervisors
      assignedTo
      assignedToUsers {
        username
        name
        profilePic
        authRole
        role
        eid
        locations {
          name
        }
      }
      userProgress
      updatedAt
      createdAt
      eid
      contentDetails
      trainingContentList
      trainingContentExpanded
    }
  }
`;

export const updateUserTrainingProgress = gql`
  mutation UpdateUserTrainingProgress($input: UserTrainingProgressInput) {
    UpdateUserTrainingProgress(input: $input) {
      eid
      title
    }
  }
`;

export const reassignTraining = gql`
  mutation ReassignTraining($userId: String!, $trainingId: String!) {
    ReassignTraining(userId: $userId, trainingId: $trainingId) {
      eid
    }
  }
`;

export const TRAINING_BY_ID_COMPLETE = gql`
  query TrainingById($eid: String!) {
    trainingById(eid: $eid) {
      title
      entityId
      overallProgress
      externalUsers {
        eid
        name
      }
      externalUserProgress
      createdBy {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
        locations {
          eid
          name
        }
      }
      status
      thumbnail
      contentLastUpdate
      description
      contents {
        type
        eid
        title
        backgroundColor
      }
      visibility
      visibleTo {
        users
        locations
        roles
        _id
      }
      supervisors
      assignedTo
      assignedRoles
      assignedToLocations {
        eid
        name
      }
      assignedLocations
      userProgress
      updatedAt
      createdAt
      eid
      assignedToUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        assignedOn
        updatedAt
        createdAt
        eid
        locations {
          email
          username
          name
          phone
          profilePic
          onboarded
          isDemo
          onboardedAt
          authRole
          type
          role
          entityId
          loginCount
          lastLogin
          isDeleted
          isRoot
          isInvited
          status
          childrens
          updatedAt
          createdAt
          eid
        }
      }
      supervisorsUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
        locations {
          locations {
            email
            username
            name
            phone
            profilePic
            onboarded
            isDemo
            onboardedAt
            authRole
            type
            role
            entityId
            loginCount
            lastLogin
            isDeleted
            isRoot
            isInvited
            status
            childrens
            updatedAt
            createdAt
            eid
          }
        }
      }
      contentDetails
      trainingItems
      trainingContentList
      trainingContentExpanded
    }
  }
`;

export const TRAINING_BY_ID_COMPLETE_NEW = gql`
  query TrainingById($eid: String!) {
    trainingById(eid: $eid) {
      title
      entityId
      createdBy {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
        locations {
          eid
          name
        }
      }
      status
      thumbnail
      description
      contents {
        type
        eid
        title
        backgroundColor
      }
      visibility
      visibleTo {
        users
        locations
        roles
        _id
      }
      supervisors
      assignedTo
      assignedRoles
      assignedToLocations {
        eid
        name
      }
      assignedLocations
      userProgress
      updatedAt
      createdAt
      eid
      assignedToUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
        locations {
          email
          username
          name
          phone
          profilePic
          onboarded
          isDemo
          onboardedAt
          authRole
          type
          role
          entityId
          loginCount
          lastLogin
          isDeleted
          isRoot
          isInvited
          status
          childrens
          updatedAt
          createdAt
          eid
        }
      }
      supervisorsUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
        locations {
          email
          username
          name
          phone
          profilePic
          onboarded
          isDemo
          onboardedAt
          authRole
          type
          role
          entityId
          loginCount
          lastLogin
          isDeleted
          isRoot
          isInvited
          status
          childrens
          updatedAt
          createdAt
          eid
        }
      }
      contentDetails
      trainingContentList
      trainingContentExpanded
    }
  }
`;

export const getTrainingPathIcons = gql`
  query IconsList($iconsListPage2: Int, $iconsListPerPage2: Int) {
    iconsList(page: $iconsListPage2, perPage: $iconsListPerPage2) {
      items {
        url
        type
        label
        tags
        description
        backgroundColor
        updatedAt
        createdAt
        eid
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const getCardResponseById = gql`
  query CardResponseById($eids: [String!]) {
    cardResponseById(eids: $eids) {
      cardType
      userId
      entityId
      cardId
      response
      responseDetails
      completed
      updatedAt
      createdAt
      eid
    }
  }
`;

export const getWorkersForTrainingTracking = gql`
  query Query($userId: String!) {
    trackUserTraining(userId: $userId)
  }
`;

export const getSelectedUserTrainingDetails = gql`
  query UserTrainingDetails($userId: String!) {
    userTrainingDetails(userId: $userId) {
      title
      status
      thumbnail
      lastUpdated
      userProgress
      eid
      trainingContentList
    }
  }
`;

export const getAllTrainingsNew = gql`
  query UserTrainingNew(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
  ) {
    userTrainingNew(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        title
        entityId
        createdBy {
          email
          username
          name
          phone
          profilePic
          eid
        }
        lastUpdatedBy {
          name
          profilePic
          eid
        }
        status
        thumbnail
        thumbnailColor
        description
        visibility
        supervisors
        assignedTo
        userProgress
        updatedAt
        createdAt
        eid
        assignedToUsers {
          eid
          profilePic
          name
        }
        contentDetails
        trainingContentList
        trainingContentExpanded
      }
      pageInfo {
        currentPage
        hasNextPage
        pageCount
        itemCount
      }
    }
  }
`;

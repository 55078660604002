import { useReactiveVar } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { IVisibility } from 'pages/Chapters/CreateChapterModal/UploadFile/UploadFile';
import {
  anyCommonInBetween,
  isDuplicate,
} from 'pages/Chapters/CreateEditSubFolder/components/helper/membersCompare';
import {
  compareVisibilityHandler,
  ICompareVisibilityType,
} from 'pages/Chapters/CreateEditSubFolder/components/helper/visibilityHelper';
import { useEffect, useMemo } from 'react';
import { usersEntityObj } from 'sub-components/Header';
import { IVisibilityStatusInfoType } from '../VisibilityStatusInfo';
import { useVisibilityStatusInfoQuery } from './useVisibilityStatusInfoQuery';

interface IProps {
  hitApi?: boolean;
  locationsList: any[];
  membersList: any[];
  rolesList: any[];
  childDetails?: IVisibility;
  parentDetails?: IVisibility;
  categoryId?: string;
  type?: IVisibilityStatusInfoType;
}

export type IDetailedVisibility = Omit<IVisibility, 'locations' | 'users'> & {
  locations: any[];
  users: any[];
};

export function useVisibilityStatusInfoLogic({
  locationsList,
  membersList,
  rolesList,
  childDetails,
  parentDetails,
  categoryId,
  type,
  hitApi,
}: IProps) {
  const usersEntityData = useReactiveVar(usersEntityObj);
  const _queries = useVisibilityStatusInfoQuery({ categoryId });

  useEffect(() => {
    if (hitApi && (type === 'folder' || type === 'subFolder')) {
      _queries.getAggregatedVisibility();
    }
  }, [categoryId]);

  /**
   * The `dataConverter` function takes an object with locations and users, filters them based on their
   * IDs, and returns a new object with detailed information.
   * @param {IVisibility} object - The `dataConverter` function takes an object of type `IVisibility`
   * as input and converts it into an object of type `IDetailedVisibility`. The function extracts
   * locations and users based on their IDs from the provided object and returns a new object with the
   * found locations and users included.
   * @returns The `dataConverter` function is returning an object of type `IDetailedVisibility`. This
   * object includes the properties from the input `object` parameter, as well as updated `locations`
   * and `users` arrays based on the filtering logic applied to `locationsList` and `membersList`
   * respectively.
   */
  const dataConverter = (object: IVisibility): IDetailedVisibility => {
    let foundLocations: any[] = [];
    let foundUsers: any[] = [];
    let foundRoles: any[] = [];
    if (object?.locations?.length > 0) {
      foundLocations =
        locationsList?.filter((loc) => object?.locations?.includes(loc?.eid)) ||
        [];
    }
    if (object?.users?.length > 0) {
      foundUsers =
        membersList?.filter((mem) => object?.users?.includes(mem?.eid)) || [];
    }
    if (object?.roles?.length > 0) {
      const rolesSet = new Set(object.roles || []);
      rolesList.forEach((role) => {
        if (rolesSet.has(role.eid) || rolesSet.has(role.name)) {
          foundRoles.push(role.name);
        }
      });
    }
    return {
      ...object,
      locations: foundLocations,
      users: foundUsers,
      roles: foundRoles,
    };
  };

  let _aggregatedData = useMemo(() => {
    if (_queries.data?.CategoryVisibility) {
      let _categoryVisibilityData = cloneDeep(
        _queries.data?.CategoryVisibility
      );
      console.log({ _categoryVisibilityData });
      let andData = {
        locations: _categoryVisibilityData?.and?.locations || [],
        roles: _categoryVisibilityData?.and?.roles || [],
      };
      let orData = {
        locations: _categoryVisibilityData?.or?.locations || [],
        roles: _categoryVisibilityData?.or?.roles || [],
      };
      console.log({ andData, orData });
      let processedAndData = dataConverter(andData as IVisibility);
      let processedOrData = dataConverter(orData as IVisibility);
      return {
        ..._categoryVisibilityData,
        processedAndData,
        processedOrData,
      };
    }
  }, [locationsList, membersList, rolesList, _queries?.data]);

  let _childDetails = useMemo(() => {
    if (!childDetails) return;
    return dataConverter(childDetails);
  }, [childDetails, locationsList, membersList, rolesList]);

  let _parentDetails = useMemo(() => {
    if (!parentDetails) return;
    return dataConverter(parentDetails);
  }, [parentDetails, locationsList, membersList, rolesList]);

  let _status: ICompareVisibilityType = useMemo(() => {
    if (_aggregatedData) {
      console.log('aggregated list is present : ', _aggregatedData);
      let andLocations = _aggregatedData?.and?.locations || [];
      let andRoles = _aggregatedData?.and?.roles || [];
      let orLocations = _aggregatedData?.processedOrData?.locations || [];
      let orRoles = _aggregatedData?.processedOrData?.roles || [];
      let andAggregatedLength = andLocations?.length + andRoles?.length;
      let orAggregatedLength = orLocations?.length + orRoles?.length;
      if (
        andAggregatedLength === 0 &&
        orAggregatedLength === 0 &&
        _aggregatedData?.publicSop?.length === 0 &&
        _aggregatedData?.userVisibility?.length === 0
      ) {
        return 'parentChildSameVisibleSameLength';
      }
      /** No data present for 'and' and 'or' condition, making it public. */
      if (andAggregatedLength === 0 && orAggregatedLength === 0) {
        console.log('AND Aggregated = 0, OR Aggregated = 0');
        if (
          _aggregatedData?.userVisibility?.length > 0 &&
          parentDetails &&
          parentDetails?.users?.length > 0
        ) {
          if (
            _aggregatedData?.userVisibility?.length ===
            parentDetails?.users?.length
          ) {
            let isDuplicateFlag = isDuplicate(
              parentDetails?.users,
              _aggregatedData?.userVisibility
            );
            let anyCommonInBetweenFlag = anyCommonInBetween(
              parentDetails?.users,
              _aggregatedData?.userVisibility
            );
            if (isDuplicateFlag) {
              return 'parentChildSameVisibleSameLength';
            }
            if (anyCommonInBetweenFlag) {
              return 'parentChildSameLengthDifferentVisibility';
            }
          } else {
            if (
              parentDetails?.users?.length >
              _aggregatedData?.userVisibility?.length
            ) {
              return 'parentMoreVisibleThanChild';
            } else if (
              parentDetails?.users?.length <
              _aggregatedData?.userVisibility?.length
            ) {
              return 'parentLessVisibleThanChild';
            }
          }
        }
        /** Parent is public so all its children */
        if (parentDetails?.visibility === 'public') {
          return 'parentPublicChildPublic';
        } else {
          /** Parent is private but its all childrens are public */
          return 'parentLessVisibleThanChild';
        }
      } else if (andAggregatedLength === 0 && orAggregatedLength > 0) {
        /** Children have only 'or' condition data making it as 'or' condition */
        console.log('Aggregated data has only OR condition');
        const _subFolders = {
          eid: '',
          visibility: 'private',
          condition:
            _aggregatedData?.or?.locations?.length > 0 &&
            _aggregatedData?.or?.roles?.length > 0
              ? 'or'
              : undefined,
          jobs: _aggregatedData?.or?.roles || [],
          locations: _aggregatedData?.or?.locations || [],
          members: _aggregatedData?.userVisibility || [],
        };
        const folder = {
          eid: '',
          visibility: parentDetails?.visibility,
          condition:
            (parentDetails?.roles || [])?.length > 0 &&
            (parentDetails?.locations || [])?.length > 0
              ? parentDetails?.condition
              : undefined,
          jobs: parentDetails?.roles,
          locations: parentDetails?.locations,
          members: parentDetails?.users,
        };
        console.log(
          'Aggregated data has only OR condition : SUB FOLDER : ',
          _subFolders
        );
        console.log(
          'Aggregated data has only OR condition : PARENT FOLDER : ',
          folder
        );
        let visibilityConditionType = compareVisibilityHandler(
          folder,
          _subFolders,
          usersEntityData
        );
        return visibilityConditionType;
      } else if (andAggregatedLength > 0 && orAggregatedLength === 0) {
        /** Children have only 'and' condition data making it as 'and' condition */
        const _subFolders = {
          eid: '',
          visibility: 'private',
          condition:
            _aggregatedData?.and?.locations?.length > 0 &&
            _aggregatedData?.and?.roles?.length > 0
              ? 'and'
              : undefined,
          jobs: _aggregatedData?.and?.roles || [],
          locations: _aggregatedData?.and?.locations || [],
          members: _aggregatedData?.userVisibility || [],
        };
        const folder = {
          eid: '',
          visibility: parentDetails?.visibility,
          condition:
            (parentDetails?.roles || [])?.length > 0 &&
            (parentDetails?.locations || [])?.length > 0
              ? parentDetails?.condition
              : undefined,
          jobs: parentDetails?.roles,
          locations: parentDetails?.locations,
          members: parentDetails?.users,
        };
        console.log(
          'Aggregated data has only AND condition : SUB FOLDER : ',
          _subFolders
        );
        console.log(
          'Aggregated data has only AND condition : PARENT FOLDER : ',
          folder
        );
        let visibilityConditionType = compareVisibilityHandler(
          folder,
          _subFolders,
          usersEntityData
        );
        return visibilityConditionType;
      } else if (andAggregatedLength > 0 && orAggregatedLength > 0) {
        return 'parentLessVisibleThanChild';
      }
    } else {
      console.log('aggregated list is not present : ', {
        parentDetails,
        childDetails,
      });
      const folder = {
        eid: '',
        visibility: parentDetails?.visibility,
        condition:
          (parentDetails?.roles || [])?.length > 0 &&
          (parentDetails?.locations || [])?.length > 0
            ? parentDetails?.condition
            : undefined,
        jobs: parentDetails?.roles,
        locations: parentDetails?.locations,
        members: parentDetails?.users,
      };
      const _subFolders = {
        eid: '',
        visibility: childDetails?.visibility,
        condition:
          (childDetails?.roles || [])?.length > 0 &&
          (childDetails?.locations || [])?.length > 0
            ? childDetails?.condition
            : undefined,
        jobs: childDetails?.roles,
        locations: childDetails?.locations,
        members: childDetails?.users,
      };
      return compareVisibilityHandler(folder, _subFolders, usersEntityData);
    }
  }, [_aggregatedData, childDetails, parentDetails]);

  // console.log({
  //   initialParentDetails: parentDetails,
  //   initialChildDetails: childDetails,
  //   modifiedParentDetails: _parentDetails,
  //   modifiedChildDetails: _childDetails,
  // });

  return {
    dataConverter,
    _childDetails,
    _parentDetails,
    _status,
    loading: _queries.loading,
  };
}

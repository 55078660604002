import React, { FC, Fragment } from 'react';
import { Box, Center, Flex, Input, Switch } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import SectionEdit from './SectionEdit';
import { IFormInput } from '../form.types';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/trash.svg';

interface IProps {
  currentIndex: number;
  isReadOnly?: boolean;
  handleRemove: (index: number) => void;
}

const Section: FC<IProps> = ({ currentIndex, handleRemove, isReadOnly }) => {
  const { control } = useFormContext<IFormInput>();

  return (
    <Fragment>
      <Center
        gap={3}
        pt='12px'
        pb='12px'
        bg='#EEEEEE'
        borderRadius='7px'
        mt={6}
        px={4}
        _hover={{
          '.delete-section': {
            cursor: 'pointer',
            opacity: 1,
            visibility: 'visible',
          },
        }}
      >
        <FontAwesomeIcon
          icon={faListUl as IconProp}
          fontSize='20px'
          color='#6F767E'
        />
        <Controller
          name={`questions.${currentIndex}.label`}
          control={control}
          render={({ field }) => {
            return (
              <Box
                fontWeight='600'
                lineHeight='24px'
                color='#000000'
                textTransform={'uppercase'}
              >
                {field.value}
              </Box>
            );
          }}
        />

        {!isReadOnly ? <SectionEdit currentIndex={currentIndex} /> : null}

        {currentIndex > 0 && (
          <Box
            pos='absolute'
            className='delete-section'
            right={4}
            justifySelf='flex-end'
            visibility='hidden'
            opacity='0'
          >
            <DeleteIcon
              style={{ width: 16, height: 16 }}
              onClick={() => handleRemove(currentIndex)}
            />
          </Box>
        )}
      </Center>

      <Controller
        name={`questions.${currentIndex}.eid`}
        control={control}
        render={({ field }) => {
          return <Input size='lg' {...field} hidden isReadOnly={isReadOnly} />;
        }}
      />

      <Controller
        name={`questions.${currentIndex}.qType`}
        control={control}
        render={({ field }) => {
          return <Input size='lg' {...field} hidden isReadOnly={isReadOnly} />;
        }}
      />

      <Controller
        name={`questions.${currentIndex}.isRequired`}
        render={({ field }) => {
          return (
            <Switch
              isChecked={field.value}
              onChange={field.onChange}
              hidden
              isReadOnly={isReadOnly}
            />
          );
        }}
      />

      <Controller
        name={`questions.${currentIndex}.hasAnalytics`}
        render={({ field }) => {
          return (
            <Switch
              isChecked={field.value}
              onChange={field.onChange}
              hidden
              isReadOnly={isReadOnly}
            />
          );
        }}
      />
    </Fragment>
  );
};

export default Section;

import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import DashboardContainer from '../../sub-components/DashboardContainer';
import { SubmitFormComponent } from '../../sub-components/forms/submit-form';

interface IProps {}

const FormSubmit: FC<IProps> = () => {
  const id = useParams<{ id: string }>().id;
  return (
    <DashboardContainer>
      <SubmitFormComponent formId={id} />
    </DashboardContainer>
  );
};

export default FormSubmit;

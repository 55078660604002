import {
  faTimes,
  faCircleChevronLeft,
  faCircleChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import TrainingCard from 'sub-components/TrainingCard';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
// @ts-ignore
import { getCardById } from 'sop-commons/Query/Search';
import CarouselBar from './CarouselBar';

import './CardPreviewCarousel.scss';
import CardDeckInfoTooltip from 'sub-components/CardDeckInfoTooltip';
import { DeckResponse, getCardsByDeckId } from './card-carousel.graphql';
import { CardPreviewModal } from 'sub-components/CardDeckPreviewModal/cardPreview.modal';

interface IProps {
  deckSelected: any;
  onCloseClick: () => void;
}

const CardPreviewCarousel: FC<IProps> = ({ deckSelected, onCloseClick }) => {
  const { t } = useTranslation(['common', 'deck']);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [cardIds, setCardIds] = useState<string[]>([]);

  const { data } = useQuery<DeckResponse>(getCardsByDeckId, {
    variables: {
      eid: deckSelected?.deckId ? deckSelected?.deckId : deckSelected?.eid,
    },
    onCompleted: (response) => {
      const cards = response.findDeckById?.cards;
      if (cards) {
        let cardList = cards?.map((card) => card?.eid);
        setCardIds([...cardList]);
      }
    },
  });

  const [findCardById, { data: cardData }] = useLazyQuery(getCardById);

  // const onCardSuccess = () => {
  //   if (onSubmit) {
  //     onSubmit();
  //   }
  //   if (refetch) {
  //     refetch();
  //   }

  // if (selectedCard && isEdit) {
  //   setCardSelected(selectedCard);
  // }
  // if (refetchCards) {
  //   refetchCards();
  // }

  const cardDataResponse = useMemo(() => {
    if (cardData?.findCardById) {
      return new CardPreviewModal(cardData?.findCardById);
    }
    return {} as any;
  }, [cardData]);

  useEffect(() => {
    findCardById({
      variables: {
        eid: cardIds[selectedIndex],
      },
    });
  }, [cardIds[selectedIndex]]);

  const incrementIndex = () => {
    setSelectedIndex(selectedIndex + 1);
  };

  const decrementIndex = () => {
    setSelectedIndex(selectedIndex - 1);
  };

  const cardLength = data?.findDeckById?.cards?.length || 0;

  return (
    <Flex
      pos='absolute'
      style={{
        top: 0,
        right: 0,
        width: '30%',
        backgroundColor: 'rgba(255,255,255,1)',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        paddingTop: 50,
        boxShadow: '-2px -3px 5px -4px rgba(0,0,0,0.75)',
        overflowY: 'auto',
        zIndex: '1401',
      }}
      minW={{
        base: '320px',
        '2xl': '350px',
      }}
      p={{
        base: '20px',
        '2xl': '50px',
      }}
    >
      <Box pos='absolute' top='17px' right='50px'>
        {cardDataResponse && (
          <CardDeckInfoTooltip details={cardDataResponse} placement='left' />
        )}
      </Box>
      <div
        style={{
          position: 'absolute',
          top: 20,
          right: 30,
          cursor: 'pointer',
        }}
      >
        <FontAwesomeIcon
          onClick={onCloseClick}
          fontSize={18}
          icon={faTimes as IconProp}
          color={'#000000'}
        />
      </div>
      <div
        style={{
          width: 300,
          maxHeight: 800,
          display: 'flex',
          flexDirection: 'column',
          height: '-webkit-fill-available',
        }}
      >
        <Flex
          style={{
            // flex: 1,
            alignItems: 'center',
            fontSize: 18,
            fontWeight: '900',
          }}
        >
          <div
            style={{
              height: 32,
              width: 16,
              borderRadius: 4,
              backgroundColor: '#B1E5FC',
              marginRight: 20,
            }}
          />
          <div style={{ flex: '1 1 auto' }}>{t('preview')}</div>
        </Flex>

        <Flex
          style={{
            // height: 551,
            borderRadius: 10,
            marginTop: 24,
            overflow: 'hidden',
            flex: '1 1 auto',
            boxShadow: '0px 4px 22px rgb(0 0 0 / 9%)',
            // marginBottom: '40px',
            position: 'relative',
          }}
        >
          {selectedIndex !== 0 && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                fontSize: '30px',
                zIndex: '200',
              }}
              onClick={() => decrementIndex()}
            >
              <FontAwesomeIcon icon={faCircleChevronLeft as IconProp} />
            </div>
          )}

          <div style={{ height: '100%', width: '100%' }}>
            <TrainingCard content={cardDataResponse} isPreview={false} />
          </div>
          {selectedIndex !== cardLength - 1 && (
            <div
              style={{
                position: 'absolute',
                right: '0px',
                top: '50%',
                fontSize: '30px',
                zIndex: '200',
              }}
              onClick={() => incrementIndex()}
            >
              <FontAwesomeIcon icon={faCircleChevronRight as IconProp} />
            </div>
          )}
        </Flex>
        {cardLength > 1 && (
          <div className='carousel-footer-container'>
            <CarouselBar
              selectedIndex={selectedIndex + 1}
              totalLength={cardIds?.length}
            />
            <div className='card-preview-carousel-number-container'>
              {t('deck:ofCardCount', {
                value: selectedIndex + 1,
                total: cardLength,
              })}
            </div>
          </div>
        )}
      </div>
    </Flex>
  );
};

export default CardPreviewCarousel;

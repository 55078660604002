import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useActiveTeamTab } from './useActiveTeamTab';
import { MemberListContainer } from './members';
import { LocationListContainer } from './locations';
import { JobsListContainer } from './Jobs';

interface IProps {}

const TeamPgeContainer: FC<IProps> = () => {
  const activeTeamTab = useActiveTeamTab();
  return (
    <Flex flexDir='column' pt='20px' flex={1}>
      {activeTeamTab === 'members' && <MemberListContainer />}
      {activeTeamTab === 'locations' && <LocationListContainer />}
      {activeTeamTab === 'jobs' && <JobsListContainer />}
    </Flex>
  );
};

export default TeamPgeContainer;

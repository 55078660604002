import styled from '@emotion/styled';

export const CustomPathCarouselWrapper = styled.div`
    .modal-body {
        position: absolute;
        width: 98%;
        height: 99%;
        display: flex;
        flex-direction: column;
    }

    .arrow-icon {
        padding: '5px';
        background-color: black;
        color: white;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        position: relative;
        top: 50%;
        cursor: pointer;
    }
    
    .content-container {
        width: 20%;
        background-color: white;
        margin: auto;
        border-radius: 20px;
        z-index: 1;
    }

    .content-div {

    }

    .progress-div {
        padding: 2rem 0;
        width: 100%;
    }

    .training-progress-container {
        align-items: center;
        position: fixed;
        width: 100%;
        bottom: -10px;
    }

    .show-training-div {
        display: flex;
        column-gap: 10px;
        align-items: center;
    }

    .show-training-img {
        min-width: 30px;
        max-width: 30px;
        min-height: 27.6px;
        max-height: 27.6px;
    }

    .show-training-div span {
        font-size: 14px;
        font-weight: 600;
        color: white;
        margin-bottom: 2rem;
        cursor: pointer;
    }
`;
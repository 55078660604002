import React, { FC } from 'react';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { default as Card } from './index';
import styled from '@emotion/styled';

interface IProps {
  cardId: string;
  onClose: () => void;
}

const CardPreview = styled(Card)`
  position: relative;
  width: unset;
  top: unset;
  right: unset;
  padding: 24px 0 0;
  margin: 0 auto;
  box-shadow: unset;

  .container {
    overflow: hidden;
    .card-preview-container {
      //overflow: auto;
      margin-bottom: 24px;
    }
  }

  .info-wrapper {
    top: 29px;
    right: 0;
  }

  .edit,
  .close-wrapper {
    display: none;
  }
`;

const CardPreviewModal: FC<IProps> = ({ cardId, onClose }) => {
  return (
    <Modal
      isOpen
      onClose={onClose}
      closeOnOverlayClick={false}
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent maxH='calc(100% - 16px)' margin='auto'>
        <ModalCloseButton
          style={{
            marginTop: 16,
            marginRight: 20,
            outline: 'none',
            boxShadow: 'none',
          }}
        />
        <CardPreview cardSelected={{ eid: cardId }} onCloseClick={() => null} />
      </ModalContent>
    </Modal>
  );
};

export default CardPreviewModal;

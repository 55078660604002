import React, { FC, useMemo } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  LOCATION_OWNER_QUERY,
  LocationOwnerEntity,
  LocationOwnerResponse,
  LocationOwnerVariable,
} from '../perfor-detail.graphql';
import { LocationEntity } from '../../../../../pages/Training/TrainingList/types';
import PreviewHeader from '../../../../forms/component/PreviewHeader';

import { AuthRole } from '../../../../../authorization';
import { useLocationOwnerList } from './useLocationOwnerList';
import LocationOwnersListContainer from './LocationOwnersListContainer';

const filterOwners = (data: LocationOwnerEntity) => {
  return (
    data.authRole === AuthRole.LOCATION_OWNER &&
    ['active', 'inactive'].includes(data.status)
  );
};

interface IProps {
  locations?: LocationEntity[];
  openChat?: (userId: string) => void;
}

const LocationOwners: FC<IProps> = ({ locations, openChat }) => {
  const { t } = useTranslation(['common', 'task']);

  const viewLocationOwner = useLocationOwnerList();

  const location = useMemo(() => {
    return locations?.[0];
  }, [locations]);

  const { loading, data } = useQuery<
    LocationOwnerResponse,
    LocationOwnerVariable
  >(LOCATION_OWNER_QUERY, {
    fetchPolicy: 'network-only',
    skip: !location?.eid,
    variables: {
      locationId: location?.eid,
    },
  });

  const [ownerList, dataLength] = useMemo(() => {
    const _data = data?.locationUser?.filter(filterOwners) || [];
    return [_data.slice(0, 2), _data.length];
  }, [data]);

  const onViewAllClick = () => {
    viewLocationOwner({
      ownerList: data?.locationUser?.filter(filterOwners) || [],
      openChat: openChat,
    });
  };

  return (
    <Flex
      flexDir='column'
      minW='320px'
      flex={1}
      gap='20px'
      borderRadius='10px'
      bg='#FFFFFF'
      p='20px 16px'
    >
      <Flex justify='space-between' pr={3}>
        <PreviewHeader title={t('task:locationOwner')} fontSize='16px' />

        {dataLength > 2 && (
          <Button
            fontSize='14px'
            fontWeight='600'
            onClick={onViewAllClick}
            colorScheme='blue'
            variant='link'
          >
            {t('common:viewAll')}
          </Button>
        )}
      </Flex>

      <LocationOwnersListContainer
        data={ownerList}
        loading={loading}
        openChat={openChat}
      />
    </Flex>
  );
};

export default LocationOwners;

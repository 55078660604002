import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../../../atoms';
import SelectButton from './SelectButton';
import Content from './Content';

interface RefProps {
  blur: () => void;
  value?: SelectOption;
}

interface IProps {
  options?: SelectOption[];
}

const LocationSelectNew = forwardRef<RefProps, IProps>(({ options }, ref) => {
  const { t } = useTranslation(['location']);

  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: false,
  });

  const [width, setWidth] = useState<string>();

  const [value, setValue] = useState<SelectOption>();

  const resize = useMemo(() => {
    return new ResizeObserver(function (entries) {
      let rect = entries[0]?.borderBoxSize?.[0];

      if (rect?.inlineSize) {
        setWidth(rect.inlineSize + 'px');
      }
    });
  }, []);

  useEffect(() => {
    const elm = document.querySelector('#popover-trigger-select-location');
    if (elm) {
      resize.observe(elm);
    }

    return () => {
      if (elm) {
        resize.unobserve(elm);
      }
    };
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        blur: onClose,
        value: value,
      };
    },
    [onClose, value]
  );

  return (
    <div>
      <Popover
        isLazy
        lazyBehavior='unmount'
        // TODO: don't remove this id, this id is needed for calculating the width of containers
        id='select-location'
        // closeOnBlur={false}
        placement='bottom'
        autoFocus
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      >
        {/*// @ts-ignore  */}
        <PopoverTrigger>
          <SelectButton>
            {value?.label || t('location:selectLocation')}
          </SelectButton>
        </PopoverTrigger>
        <PopoverContent width={width} py={3} px={2}>
          <Content options={options} value={value} onChange={setValue} />
        </PopoverContent>
      </Popover>
    </div>
  );
});

LocationSelectNew.displayName = 'Location Select New';

export default LocationSelectNew;

import React, { FC } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { Skeleton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import PrimaryButton from '../../../atoms/PrimaryButton';
import { IFormInput, ITaskAction } from './task.types';
import { useCanPublishTask } from './common';
import TerminateButton from './delete/TerminateButton';

interface IProps {
  isLoading?: boolean;
  isEdit?: boolean;
  onCreatePress?: () => void;
  handleAction?: (type: ITaskAction) => void;
}

const HeaderActions: FC<IProps> = ({
  isLoading,
  isEdit,
  onCreatePress,
  handleAction,
}) => {
  const { t } = useTranslation(['common', 'task']);

  const { isDirty } = useFormState<IFormInput>();

  const isDisabled = useCanPublishTask();

  const status = useWatch<IFormInput, 'status'>({
    name: 'status',
  });

  if (!isEdit) {
    return (
      <PrimaryButton
        size='lg'
        colorScheme='green'
        style={{
          minWidth: 140,
        }}
        title={t('common:create')}
        onClick={onCreatePress}
        disabled={isDisabled || !isDirty}
      />
    );
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <Skeleton minW='140px' h={12} borderRadius={7} />
        <Skeleton minW='140px' h={12} borderRadius={7} />
      </React.Fragment>
    );
  }

  if (status === 'TASK_TERMINATED') {
    return (
      <React.Fragment>
        <PrimaryButton
          size='lg'
          colorScheme='red'
          style={{
            minWidth: 140,
          }}
          title={t('common:delete')}
          onClick={() => handleAction?.('softDelete')}
          // disabled={isDisabled || !isDirty}
        />

        <PrimaryButton
          size='lg'
          colorScheme='blue'
          style={{
            minWidth: 140,
          }}
          title={t('task:restart')}
          onClick={() => handleAction?.('restart')}
          // disabled={isDisabled || !isDirty}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TerminateButton handleAction={handleAction} />

      <PrimaryButton
        size='lg'
        colorScheme='green'
        style={{
          minWidth: 140,
        }}
        title={t('common:update')}
        onClick={onCreatePress}
        disabled={isDisabled || !isDirty}
      />
    </React.Fragment>
  );
};

export default HeaderActions;

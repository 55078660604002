import React, { FC, useEffect, useState } from 'react';
import { Row, Button, Typography, message, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { notification } from 'antd';
import '../Header/header.css';

import { ReactComponent as LogoSvg } from '../../assets/images/logo.svg';
import { useQuery, gql } from '@apollo/client';
import { useHistory } from 'react-router';
import profile from '../../assets/images/profile.png';
import delightreeLogo from '../../assets/images/delightreeLogo.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import shareIcon from '../../assets/images/share.svg';
import { useRouter } from '../../utils';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
import {
  authTokenVar,
  userObj,
  client,
} from 'sop-commons/src/client/clientFactory';
const { Text } = Typography;

import { bundleObj, roleObj } from '../DashboardMenu';
import { useIntercom } from 'react-use-intercom';
import { GET_USER } from '../../pages/Login/login.graphql';

type IProps = {
  buttonSize?: any;
  menuStyle?: object;
  subMenuIcon?: Node;
  menuMode?: any;
  menuDefaultSelectedKeys?: string[];
  menuDefaultOpenKeys?: string[];
  currentSelectedKeys?: string[];
  isOnboarded?: boolean;
  setHandlers?: any;
  disableSearch?: boolean;
  entityDetails?: any;
};

export const PublicHeader: FC<IProps> = ({
  buttonSize = 'large',
  entityDetails,
}) => {
  const router: any = useRouter();
  const { query } = router;
  const sopId = query?.eid;
  const history = useHistory();
  const { shutdown } = useIntercom();
  const {
    loading: getUserLoading,
    error: getUserError,
    data: getUserData,
  } = useQuery(GET_USER);

  useEffect(() => {
    userObj(getUserData?.user || {});
  }, [getUserData]);

  const [businessName, setBusinessName] = useState('');

  useEffect(() => {
    if (entityDetails?.name) {
      setBusinessName(entityDetails?.name);
    }
  }, [entityDetails?.name]);

  const onCopy = () => {
    openNotificationWithIcon();
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: 'Link copy to clipboard',
    });
  };

  return (
    <>
      <Row
        justify='center'
        style={{
          marginTop: 20,
          alignItems: 'flex-start',
        }}
        className='public-header-desktop-container'
      >
        <Col
          span={2}
          style={{
            maxHeight: '100%',
            minWidth: 260.98,
            maxWidth: 350.98,
          }}
        >
          <div className='eventclass-business-name business-name'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: 10,
                  alignSelf: 'center',
                }}
              >
                {entityDetails?.profilePic ? (
                  <img
                    src={entityDetails?.profilePic}
                    height={21.33}
                    width={21.33}
                    style={{ width: 21.33 }}
                  />
                ) : (
                  <LogoSvg />
                )}
              </div>

              <Text
                ellipsis={true}
                style={{
                  width: '100%',
                  marginRight: 10,
                }}
              >
                {businessName}
              </Text>
            </div>
          </div>
        </Col>
        <Col
          span={15}
          style={{
            maxWidth: 1360,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <CopyToClipboard
              text={`${process.env.REACT_APP_FRONTEND_URL}/public/${sopId}`}
              onCopy={onCopy}
            >
              <div
                style={{ cursor: 'pointer' }}
                className='share-login-container '
              >
                <img src={shareIcon} style={{ width: '14px', margin: 0 }} />
                <Text
                  ellipsis={true}
                  style={{
                    marginLeft: 15,
                    fontSize: 16,
                  }}
                >
                  Share
                </Text>
              </div>
            </CopyToClipboard>
            <Link to='/login'>
              <div
                style={{ cursor: 'pointer', marginLeft: '10px' }}
                className='share-login-container '
              >
                <FontAwesomeIcon
                  style={{
                    height: 14,
                    width: 14,
                  }}
                  icon={faSignIn}
                  color='rgba(0, 0, 0, 0.85)'
                />
                <Text
                  ellipsis={true}
                  style={{
                    marginLeft: 15,
                    fontSize: 16,
                  }}
                >
                  Login
                </Text>
              </div>
            </Link>
            <div style={{ marginLeft: '35px' }}>
              <img src={delightreeLogo} alt='logo' />
            </div>
          </div>
        </Col>
      </Row>
      <div className='public-header-mobile-container'>
        <div
          style={{ flex: '1 1 auto', display: 'flex', alignItems: 'center' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: 10,
              alignSelf: 'center',
            }}
          >
            {entityDetails?.profilePic ? (
              <img
                src={entityDetails?.profilePic}
                height={21.33}
                width={21.33}
                style={{ width: 21.33 }}
              />
            ) : (
              <LogoSvg />
            )}
          </div>

          <Text
            ellipsis={true}
            style={{
              fontSize: 16,
              marginRight: 10,
            }}
          >
            {businessName}
          </Text>
        </div>

        <CopyToClipboard
          text={`${process.env.REACT_APP_FRONTEND_URL}/public/${sopId}`}
          onCopy={onCopy}
        >
          <div style={{ cursor: 'pointer' }}>
            <img src={shareIcon} style={{ width: '16px', margin: 0 }} />
          </div>
        </CopyToClipboard>
      </div>
    </>
  );
};

import { Card, CardProps, Row } from 'antd';
import Dropdown from 'atoms/Dropdown';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import './ActivityFeed.scss';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'ui-components/InfiniteScroll';
import useInfiniteScroll from 'ui-components/InfiniteScroll/useInfiniteScroll';
import UserDetails from 'ui-components/UserDetails';
import {
  loginUserBranchObj,
  loginUserBranchRootObj,
} from 'sub-components/Header';
import {
  ActivityResponse,
  ActivityVariable,
  GET_USER_ACTIVITY,
} from './activity.graphql';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { ADMIN, MANAGER, SUPER_ADMIN, WORKER } from 'utils/userRoles';
import CardPreview from 'sub-components/CardPreview';
import { ActivityUser, Activity } from './activity-feed.types';
import { LocationsEntity } from 'sop-commons/src/client/user-data.types';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import NoActivityFeed from './NoActivityFeed';
import ActivitySkeleton from './ActivitySkeleton';
import {
  activityDataModifier,
  ActivityResult,
  NewActivityList,
} from './activity-helper-new';
import {
  DescentResponse,
  DescentVariable,
  GET_USER_COUNT,
} from './activity.graphql';
import DayAgoItem from './DayAgoItem';
import FeedItem from './FeedItem';
import { AuthRole } from 'authorization';
import { useUsersEntity } from 'hooks';
// import { usersEntityObj } from 'hooks/useUsersEntity';
import { usersEntityObj } from 'sub-components/Header';
import useCombinedStore from 'zustandStore/store';

interface ILocationDropdown {
  label: string;
  value: string;
  id: string;
}

// const GET_BRANCH = gql`
//   query DescendantUsers {
//     branch {
//       descendantBranches {
//         eid
//         name
//       }
//     }
//   }
// `;

interface IProps {
  setShowSingleUserChatModalHandler?: (value: any) => void;
  setSelectedUserHandler?: (user: any) => void;
  hideHeader?: boolean;
  showSpecificUser?: string;
  showSpecificLocation?: string;
}

const ActivityFeed: FC<IProps> = ({
  setShowSingleUserChatModalHandler,
  setSelectedUserHandler,
  hideHeader,
  showSpecificUser,
  showSpecificLocation,
}) => {
  const { t, i18n } = useTranslation(['common', 'activity', 'handbook']);
  const { usersEntityLoading } = useCombinedStore();
  const history = useHistory();
  const branchRootData: any = useReactiveVar(loginUserBranchRootObj);
  const entityUserData: any = useReactiveVar(usersEntityObj);
  // const { usersEntity } = useUsersEntity();
  // const entityUserData = usersEntity;
  const [activityFeedPageInfo, setActivityFeedPageInfo] = useState<any>([]);
  const [activityFeedPageCount, setActivityFeedPageCount] = useState(1);
  const [activityFeedLocation, setActivityFeedLocation] = useState<
    string[] | null
  >(null);
  // const [showSingleUserChatModal, setShowSingleUserChatModal] = useState(false);
  const [activityData, setActivityData] = useState<NewActivityList[]>([]);

  const [activityDataInitialList, setActivityDataInitialList] = useState<
    Activity[]
  >([]);

  const [cardSelected, setCardSelected] = useState(null);
  const [locationsDropdown, setLocationsDropdown] = useState<
    ILocationDropdown[]
  >([]);

  let branchList = useReactiveVar(loginUserBranchObj);
  const userData = useReactiveVar(userObj);

  useEffect(() => {
    locationsDataHandler();
    if (!userData?.authRole) return;
    if (showSpecificUser) {
      userActivityData({
        variables: {
          perPage: 10,
          page: activityFeedPageCount,
          filter: {
            users: [showSpecificUser],
          },
          sort: '_ID_DESC',
        },
      });
    } else if (showSpecificLocation) {
      userActivityData({
        variables: {
          perPage: 10,
          page: activityFeedPageCount,
          filter: {
            locations: [showSpecificLocation],
          },
          sort: '_ID_DESC',
        },
      });
    } else {
      if (
        userData?.authRole === AuthRole.SUPER_ADMIN ||
        userData?.authRole === AuthRole.ADMIN
      ) {
        userActivityData({
          variables: {
            perPage: 10,
            page: activityFeedPageCount,
            filter: {
              locations: null,
            },
            sort: '_ID_DESC',
          },
        });
      } else if (userData?.authRole === AuthRole.LOCATION_OWNER) {
        if (locationsDropdown?.length > 0) {
          let locationIds = locationsDropdown
            ?.filter((location) => {
              return location?.id !== '0';
            })
            .map((loc) => loc?.id);
          setActivityFeedLocation(locationIds);
          userActivityData({
            variables: {
              perPage: 10,
              page: activityFeedPageCount,
              filter: {
                locations: locationIds || null,
              },
              sort: '_ID_DESC',
            },
          });
        }
      } else {
        let loggedInUserLocationIds: string[] | null =
          userData?.locations?.map(
            (location: LocationsEntity) => location?.eid
          ) || null;
        setActivityFeedLocation(loggedInUserLocationIds);
        userActivityData({
          variables: {
            perPage: 10,
            page: activityFeedPageCount,
            filter: {
              locations: loggedInUserLocationIds || null,
              users: [userData?.eid],
            },
            sort: '_ID_DESC',
          },
        });
      }
    }
  }, [userData, locationsDropdown?.length, branchRootData]);

  const locationsDataHandler = () => {
    let _dropDownData: ILocationDropdown[] = [];
    if (userData?.authRole === SUPER_ADMIN || userData?.authRole === ADMIN) {
      if (
        userData?.entity?.locations &&
        userData?.entity?.locations?.length > 0
      ) {
        let tempData = userData?.entity?.locations?.map((val: any) => {
          return {
            label: val?.name,
            value: val?.name,
            id: val?.eid,
          };
        });
        tempData.unshift({
          label: 'All',
          value: 'All',
          id: '0',
        });
        _dropDownData = tempData;
      }
      setLocationsDropdown(_dropDownData);
    } else if (userData?.authRole === AuthRole.LOCATION_OWNER) {
      getDescendantBranches();
    } else {
      let tempData = userData?.locations?.map((location: any) => {
        return {
          label: location?.name,
          value: location?.name,
          id: location?.eid,
        };
      });
      if (tempData && Array.isArray(tempData)) {
        tempData.unshift({
          label: 'All',
          value: 'All',
          id: '0',
        });
        _dropDownData = tempData;
        setLocationsDropdown(_dropDownData);
      }
    }
  };

  const { data } = useQuery<DescentResponse, DescentVariable>(GET_USER_COUNT);

  const userCount = data?.userDescendants?.length;

  const listener = useCallback((lng: string) => {
    let tempInitialList: any[] = [];
    setActivityDataInitialList((prev: any[]) => {
      tempInitialList = prev;
      return prev;
    });
    const modifiedData = activityDataModifier(tempInitialList, userData);
    setActivityData(modifiedData);
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', listener);
    return () => i18n.off('languageChanged', listener);
  }, []);

  const [
    userActivityData,
    { loading: userActivityLoading, data: activityApiData },
  ] = useLazyQuery<ActivityResponse, ActivityVariable>(GET_USER_ACTIVITY, {
    fetchPolicy: 'network-only',
    // onCompleted: (data) => {
    //   setActivityDataInitialList([
    //     ...activityDataInitialList,
    //     ...data.userActivity.items,
    //   ]);
    //   let tempInitialList = [
    //     ...activityDataInitialList,
    //     ...data.userActivity.items,
    //   ];
    //   setActivityFeedPageInfo(data.userActivity.pageInfo);
    //   const modifiedData = activityDataModifier(tempInitialList, userData);
    //   setActivityData(modifiedData);
    // },
  });

  useEffect(() => {
    if (
      activityApiData &&
      activityApiData?.userActivity?.items?.length > 0 &&
      entityUserData?.length > 0
    ) {
      let _activityApiData: ActivityResponse = JSON.parse(
        JSON.stringify(activityApiData)
      );
      _activityApiData?.userActivity?.items?.forEach((item) => {
        // let foundUser = branchRootData?.descendantUsers?.some((ds: any) => ds?.eid === item?.user?.eid);
        let foundUser = entityUserData?.filter(
          (ds: any) => ds?.eid === item?.user?.eid
        )?.[0];
        if (foundUser) {
          item.user = foundUser;
        }
      });
      setActivityDataInitialList([
        ...activityDataInitialList,
        ..._activityApiData?.userActivity.items,
      ]);
      let tempInitialList = [
        ...activityDataInitialList,
        ..._activityApiData?.userActivity.items,
      ];
      setActivityFeedPageInfo(_activityApiData?.userActivity.pageInfo);
      const modifiedData = activityDataModifier(tempInitialList, userData);
      setActivityData(modifiedData);
    } else if (activityApiData?.userActivity?.items?.length === 0) {
      setActivityDataInitialList([]);
      setActivityData([]);
    }
  }, [activityApiData, entityUserData]);

  // const [getDescendantBranches] = useLazyQuery(GET_BRANCH, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: (data) => {
  //     let _locations: {
  //       eid: string;
  //       name: string;
  //     }[] = JSON.parse(JSON.stringify(data?.branch?.descendantBranches));
  //     let _dropdownLocations: ILocationDropdown[] = [];
  //     _locations?.map((location) => {
  //       _dropdownLocations.push({
  //         id: location?.eid,
  //         label: location?.name,
  //         value: location?.name,
  //       });
  //     });
  //     _dropdownLocations?.unshift({
  //       id: '0',
  //       label: 'All',
  //       value: 'All',
  //     });
  //     setLocationsDropdown(_dropdownLocations);
  //   },
  // });

  const getDescendantBranches = () => {
    if (!branchRootData?.descendantBranches) return;
    let _locations: {
      eid: string;
      name: string;
    }[] = JSON.parse(JSON.stringify(branchRootData?.descendantBranches));
    let _dropdownLocations: ILocationDropdown[] = [];
    _locations?.map((location) => {
      _dropdownLocations.push({
        id: location?.eid,
        label: location?.name,
        value: location?.name,
      });
    });
    _dropdownLocations?.unshift({
      id: '0',
      label: 'All',
      value: 'All',
    });
    setLocationsDropdown(_dropdownLocations);
  };

  useEffect(() => {
    if (branchRootData?.descendantBranches?.length > 0) {
      getDescendantBranches();
    }
  }, [branchRootData?.descendantBranches]);

  const [selectedFilter, setSelectedFilter] = useState({
    label: 'All',
    value: 'All',
    id: '0',
  });

  const [isUserDetailsModalOpened, setIsUserDetailsModalOpened] =
    useState(false);

  const loadMoreActivityFeedItems = () => {
    hasMoreActivityDataHandler();
  };

  const getUserActivityDataHandler = (
    //
    locations: string[] | null,
    page = 1,
    users: string[] = []
  ) => {
    if (showSpecificUser) {
      userActivityData({
        variables: {
          perPage: 10,
          page: page,
          filter: {
            users: [showSpecificUser],
          },
          sort: '_ID_DESC',
        },
      });
    } else if (showSpecificLocation) {
      userActivityData({
        variables: {
          perPage: 10,
          page: page,
          filter: {
            locations: [showSpecificLocation],
          },
          sort: '_ID_DESC',
        },
      });
    } else {
      if (users?.length === 0) {
        userActivityData({
          variables: {
            perPage: 10,
            page: page,
            filter: {
              locations: locations,
            },
            sort: '_ID_DESC',
          },
        });
      } else {
        userActivityData({
          variables: {
            perPage: 10,
            page: page,
            filter: {
              locations: locations,
              users: users,
            },
            sort: '_ID_DESC',
          },
        });
      }
    }
  };

  const newChangedLocationHandler = (value: any) => {
    setActivityDataInitialList([]);
    setActivityFeedPageCount(1);
    if ([SUPER_ADMIN, ADMIN].includes(userData?.authRole)) {
      let tempActivityData = JSON.parse(JSON.stringify(activityData));
      // tempActivityData[1].totalCount = 0;
      setActivityData(tempActivityData);
      if (value.id !== '0') {
        setActivityFeedLocation([value.id]);
        getUserActivityDataHandler([value.id]);
        // userActivityData({
        //   variables: {
        //     perPage: 10,
        //     page: 1,
        //     filter: {
        //       locations: [value.id]
        //     },
        //     sort: '_ID_DESC'
        //   }
        // })
      } else {
        getUserActivityDataHandler(null);
        // userActivityData({
        //   variables: {
        //     perPage: 10,
        //     page: 1,
        //     filter: {
        //       locations: null
        //     },
        //     sort: "_ID_DESC"
        //   }
        // })
      }
    } else if (userData?.authRole?.includes(MANAGER)) {
      let tempActivityData = JSON.parse(JSON.stringify(activityData));
      tempActivityData[1].totalCount = 0;
      setActivityData(tempActivityData);
      if (value.id !== '0') {
        setActivityFeedLocation([value.id]);
        getUserActivityDataHandler([value.id]);
        // userActivityData({
        //   variables: {
        //     perPage: 10,
        //     page: 1,
        //     filter: {
        //       locations: [value.id]
        //     },
        //     sort: '_ID_DESC'
        //   }
        // })
      } else {
        let allLocationIds =
          locationsDropdown
            ?.filter((location) => location?.id !== '0')
            ?.map((loc) => loc?.id) || [];
        setActivityFeedLocation(allLocationIds);
        getUserActivityDataHandler(allLocationIds);
        // userActivityData({
        //   variables: {
        //     perPage: 10,
        //     page: 1,
        //     filter: {
        //       locations: allLocationIds,
        //     },
        //     sort: '_ID_DESC',
        //   },
        // });
      }
    } else {
      let tempActivityData = JSON.parse(JSON.stringify(activityData));
      // tempActivityData[1].totalCount = 0;
      setActivityData(tempActivityData);
      if (value.id !== '0') {
        setActivityFeedLocation([value.id]);
        getUserActivityDataHandler([value.id], 1, [userData?.eid]);
        // userActivityData({
        //   variables: {
        //     perPage: 10,
        //     page: 1,
        //     filter: {
        //       locations: [value.id]
        //     },
        //     sort: '_ID_DESC'
        //   }
        // })
      } else {
        let allLocationIds: string[] =
          userData?.locations?.map((location: any) => location?.eid) || [];
        setActivityFeedLocation(allLocationIds);
        getUserActivityDataHandler(allLocationIds, 1, [userData?.eid]);
        // userActivityData({
        //   variables: {
        //     perPage: 10,
        //     page: 1,
        //     filter: {
        //       locations: allLocationIds
        //     },
        //     sort: '_ID_DESC'
        //   }
        // })
      }
    }
  };

  const hasMoreActivityDataHandler = () => {
    let currentPageCount = activityFeedPageCount;
    setActivityFeedPageCount(activityFeedPageCount + 1);
    if (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER].includes(
        userData?.authRole
      )
    ) {
      getUserActivityDataHandler(
        activityFeedLocation,
        currentPageCount + 1,
        []
      );
    } else {
      getUserActivityDataHandler(activityFeedLocation, currentPageCount + 1, [
        userData?.eid,
      ]);
    }
    // userActivityData({
    //   variables: {
    //     perPage: 10,
    //     page: currentPageCount + 1,
    //     filter: {
    //       locations: activityFeedLocation,
    //     },
    //     sort: '_ID_DESC',
    //   },
    // });
  };

  const [lastElementRef] = useInfiniteScroll(
    activityFeedPageInfo?.hasNextPage ? loadMoreActivityFeedItems : () => {},
    userActivityLoading
  );

  const [selectedUserData, setSelectedUserData] = useState<ActivityUser | null>(
    null
  );

  const openUserModalHandler = (user: ActivityUser) => {
    setSelectedUserData(user);
    setIsUserDetailsModalOpened(true);
    setSelectedUserHandler && setSelectedUserHandler(user);
  };

  const onCloseSearchModal = () => {
    setIsUserDetailsModalOpened(false);
  };

  const deckNavigatorHandler = (user?: ActivityResult) => {
    if (user?.eventName?.toLowerCase() === 'DeckViewComplete'.toLowerCase()) {
      history.push(
        `/${user?.meta?.deck?.category}/deck/${user?.meta?.deck?.eid}`
      );
    } else if (
      user?.eventName?.toLowerCase() === 'setCardBookmark'.toLowerCase()
    ) {
      setCardSelected(user?.meta?.card);
    } else {
      return;
    }
  };

  const daysAgoHandler = (value: NewActivityList) => {
    return <DayAgoItem value={value} />;
  };

  const onUserClick = (_value: any) => {
    deployEvent(AmplitudeEventNames.HOME_ACTIVITY_FEED_CLICK_ON_ACTIVITY);
    openUserModalHandler(_value);
    // setSelectedUser(value?.eid);
  };

  const feedDataHandler = (value: NewActivityList) => {
    return value.data.map((innerVal, index) => (
      <Box
        ref={innerVal?.isLastItem ? lastElementRef : null}
        key={index + 100}
        mb='16px'
      >
        <FeedItem
          authUserId={userData?.eid}
          index={index}
          value={innerVal}
          onUserClick={onUserClick}
          taskHandler={deckNavigatorHandler}
        />
      </Box>
    ));
  };

  const dropDownChangeHandler = (value: any) => {
    setSelectedFilter(value);
    // changedLocationHandler(value);
    newChangedLocationHandler(value);
    deployEvent(AmplitudeEventNames.HOME_ACTIVITY_FEED_LOCATION_DROPDOWN);
  };

  const dropdownDataHandler = () => {
    //
    // let _dropDownData: any = [];
    // if (userData?.authRole === SUPER_ADMIN || userData?.authRole === ADMIN) {
    //   if (branchList && branchList.length > 0) {
    //     let tempData = branchList.map((val: any) => {
    //       return {
    //         label: val.name,
    //         value: val.name,
    //         id: val.eid,
    //       };
    //     });
    //     tempData.unshift({
    //       label: 'All',
    //       value: 'All',
    //       id: '0',
    //     });
    //     _dropDownData = tempData;
    //   }
    // } else {
    //   let tempData = userData?.locations?.map((location: any) => {
    //     return {
    //       label: location?.name,
    //       value: location?.name,
    //       id: location?.eid,
    //     };
    //   });
    //   tempData.unshift({
    //     label: 'All',
    //     value: 'All',
    //     id: '0',
    //   });
    //   _dropDownData = tempData;
    // }
    return (
      <Dropdown
        options={locationsDropdown}
        value={selectedFilter}
        onChange={(value: any) => dropDownChangeHandler(value)}
        placeholder={t('handbook:select_filter')}
        size='sm'
        className='member-role-dropdown-container'
        inputStyle={{
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '12px',
          fontSize: '15px',
          outline: 'none',
          minWidth: '67px !important',
        }}
        isDisabled={false}
        selectStyles={{
          menu: {
            right: 0,
          },
          singleValue: {
            position: 'relative',
            transform: 'none',
            maxWidth: 'none',
            top: 'unset',
          },
          inputContainer: {
            position: 'absolute',
          },
          valueContainer: {
            paddingRight: 0,
          },
        }}
      />
    );
  };

  const sendMessageHandler = () => {
    // setShowSingleUserChatModal(true);
    setIsUserDetailsModalOpened(false);
    setShowSingleUserChatModalHandler &&
      setShowSingleUserChatModalHandler(true);
  };

  const cardProps = useMemo((): CardProps => {
    if (hideHeader) {
      return {
        bodyStyle: {
          padding: '16px 0',
        },
        bordered: false,
      };
    }
    return {
      bodyStyle: {
        padding: 21,
      },
    };
  }, [hideHeader]);

  return (
    <>
      <Card
        key={'1'}
        style={{
          height: '88vh',
          borderRadius: '8px',
        }}
        {...cardProps}
      >
        {!hideHeader && (
          <Row>
            <Row align='middle' style={{ marginBottom: '1rem', flex: 1 }}>
              <Row className='activity-feed-tag-container' />
              <Row className='activity-feed-title-container'>
                {t('activity:activity_feed')}
              </Row>
            </Row>
            {(((userData?.authRole === SUPER_ADMIN ||
              userData?.authRole === ADMIN) &&
              userData?.entity?.locations &&
              userData?.entity?.locations?.length > 1) ||
              (userData?.authRole === MANAGER &&
                locationsDropdown?.length > 1) ||
              (userData?.authRole === WORKER &&
                userData?.locations &&
                userData?.locations?.length > 1)) && (
              <div className='activity-dropdown-container'>
                {dropdownDataHandler()}
              </div>
            )}
          </Row>
        )}
        {activityData?.length ? (
          <div style={{ height: 'calc(100vh - 210px)', overflow: 'auto' }}>
            <InfiniteScroll
              hasMore={activityFeedPageInfo?.hasNextPage}
              loader={userActivityLoading}
              loaderType='text'
              loaderText={t('loading')}
              loaderTextPosition='center'
              loaderTextColor='#000000'
              loaderTextWeight={600}
              loaderTextSize='16px'
              loaderTextMarginBottom='20px'
            >
              {activityData?.map((value, index: number) => {
                return (
                  <div key={index}>
                    {daysAgoHandler(value)}
                    {feedDataHandler(value)}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        ) : (
          <Box height='calc(88vh - 110px)'>
            {userActivityLoading || usersEntityLoading ? (
              <ActivitySkeleton />
            ) : (
              <NoActivityFeed
                userCount={userCount}
                daysAgoHandler={daysAgoHandler}
                feedDataHandler={feedDataHandler}
              />
            )}
          </Box>
        )}
      </Card>
      {isUserDetailsModalOpened && (
        <UserDetails
          isOpen={isUserDetailsModalOpened}
          onClose={onCloseSearchModal}
          userData={selectedUserData}
          sendMessageHandler={sendMessageHandler}
        />
      )}

      {cardSelected && (
        <CardPreview
          cardSelected={cardSelected}
          onCloseClick={() => setCardSelected(null)}
        />
      )}
    </>
  );
};
export default ActivityFeed;

import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { searchRegExp } from 'utils';

import LocationOwnersList from './LocationOwnersList';
import { LocationOwnerEntity } from '../perfor-detail.graphql';
import SearchInput from '../../../../../atoms/SearchInput';

interface IProps {
  locations: LocationOwnerEntity[];
  onMessageClick?: (userId: string) => void;
}

const ModalContent: FC<IProps> = ({ locations, onMessageClick }) => {
  const { t } = useTranslation(['task']);

  const [searchQuery, setSearchQuery] = useState<string>();

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filterList = useMemo(() => {
    if (searchQuery) {
      const reg = searchRegExp(searchQuery, 'gi');
      return locations.filter((value) => {
        return (
          value.name?.match(reg) ||
          value.role?.match(reg) ||
          value.authRole?.match(reg) ||
          value.locations?.[0]?.name?.match(reg)
        );
      });
    }

    return locations;
  }, [locations, searchQuery]);

  return (
    <Flex flexDir='column' flex={1} gap='20px' overflow='hidden'>
      <SearchInput
        disabled={true}
        size='lg'
        hideShortcuts={true}
        placeholder={t('task:searchMembers')}
        onChange={onSearch}
        value={searchQuery}
      />

      <Box flex={1} overflow='auto'>
        <LocationOwnersList data={filterList} openChat={onMessageClick} />
      </Box>
    </Flex>
  );
};

export default ModalContent;

import { ReactComponent as QuizCorrectIcon } from "../assets/quizCorrectIcon.svg";
import { ReactComponent as QuizWrongIcon } from "../assets/quizWrongIcon.svg";
export const convertArrayToObject = (array) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item]: item,
    };
  }, initialValue);
};

const CorrectIcon = () => {
  return (
    <div className={`quiz-type-card-choice-icon correct`}>
      <QuizCorrectIcon />
    </div>
  );
};

const WrongIcon = () => {
  return (
    <div className={`quiz-type-card-choice-icon wrong`}>
      <QuizWrongIcon />
    </div>
  );
};
const Choice = ({
  choice,
  correctAnswer,
  layout,
  selectedAnswer,
  setSelectedAnswer,
  isCheckClicked,
}) => {
  const getIconClassName = () => {
    if (!isCheckClicked) {
      if (selectedAnswer.indexOf(choice.id) !== -1) {
        return (
          <div className={`quiz-type-card-choice-icon selected`}>
            <QuizCorrectIcon />
          </div>
        );
      }
      return (
        <div className={`quiz-type-card-choice-icon`}>
          <QuizCorrectIcon />
        </div>
      );
    } else {
      if (layout === "single-choice") {
        if (
          choice.id === correctAnswer
          // selectedAnswer[0] === correctAnswer
        ) {
          return <CorrectIcon />;
        } else if (
          choice.id === selectedAnswer[0] &&
          selectedAnswer[0] !== correctAnswer
        ) {
          return <WrongIcon />;
        }
      } else {
        const selectedObj = convertArrayToObject(selectedAnswer);

        if (correctAnswer.indexOf(choice.id) !== -1) {
          let isPresent = false;
          correctAnswer.forEach((item) => {
            if (selectedObj[item] === choice.id) {
              isPresent = true;
            }
          });
          // if (isPresent) {
          return <CorrectIcon />;
          // }
        } else if (
          correctAnswer.indexOf(choice.id) === -1 &&
          selectedObj[choice.id]
        ) {
          let isPresent = false;
          return <WrongIcon />;
          // correctAnswer.forEach((item) => {
          //   if (!selectedObj[item]) {
          //     isPresent = true;
          //   } else {
          //     isPresent = false;
          //   }
          // });
          // if (isPresent) {
          //   return <WrongIcon />;
          // }
        }
      }
    }
  };

  const getContainerClass = () => {
    if (!isCheckClicked) {
      return "quiz-type-card-choice-container";
    } else {
      if (layout === "single-choice") {
        if (choice.id === correctAnswer) {
          return `quiz-type-card-choice-container correct`;
        } else if (
          choice.id === selectedAnswer[0] &&
          selectedAnswer[0] !== correctAnswer
        ) {
          return `quiz-type-card-choice-container wrong`;
        }
        return "quiz-type-card-choice-container";
      } else {
        const selectedObj = convertArrayToObject(selectedAnswer);
        if (correctAnswer.indexOf(choice.id) !== -1) {
          let isPresent = false;
          correctAnswer.forEach((item) => {
            if (selectedObj[item] === choice.id) {
              isPresent = true;
            }
          });
          // if (isPresent) {
          // isPresent = false;
          return `quiz-type-card-choice-container correct`;
          // }
        } else if (
          correctAnswer.indexOf(choice.id) === -1 &&
          selectedObj[choice.id]
        ) {
          return `quiz-type-card-choice-container wrong`;
        }
        return "quiz-type-card-choice-container";
      }
    }
  };
  return (
    <div
      className={getContainerClass()}
      onClick={() => {
        if (!isCheckClicked) {
          if (layout === "single-choice" && correctAnswer) {
            setSelectedAnswer([choice.id]);
          } else if (
            layout === "multi-choice" &&
            correctAnswer?.length >= selectedAnswer?.length
          ) {
            if (
              selectedAnswer.indexOf(choice.id) === -1 &&
              correctAnswer?.length > selectedAnswer?.length
            ) {
              selectedAnswer.push(choice.id);
            } else if (selectedAnswer.indexOf(choice.id) !== -1) {
              const index = selectedAnswer.indexOf(choice.id);
              selectedAnswer.splice(index, 1);
            }
            setSelectedAnswer([...selectedAnswer]);
          }
        }
      }}
    >
      <div className="quiz-type-card-choice">
        <div style={{ flex: "1 1 auto" }}>{choice?.text}</div>
        {getIconClassName()}
        {/* <div className={getIconClassName()}>
          <QuizCorrectIcon />
        </div> */}
      </div>
    </div>
  );
};

export default Choice;

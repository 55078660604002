import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoText } from '../common';
import { useSchedulerInfo } from './useSchedulerInfo';

interface IProps {}

const SchedulerInfoText: FC<IProps> = () => {
  const { t } = useTranslation('task');

  const transObject = useSchedulerInfo();

  if (!transObject) {
    return null;
  }

  return (
    <InfoText>
      <Trans t={t} {...transObject} />
    </InfoText>
  );
};

export default SchedulerInfoText;

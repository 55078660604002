import React, { FC } from 'react';
import { Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import { IFilesEntity } from 'pages/LocationDetails/utils/location-details.types';

import { getImage } from '../../../../../../../utils';

interface IProps {
  details: IFilesEntity;
}

const DocumentUploadedBy: FC<IProps> = ({ details }) => {
  return (
    <Tooltip label={details?.name} hasArrow>
      <Flex
        align='center'
        p='2px 8px'
        gap='5px'
        borderRadius='5px'
        bg='#B1E5FC80'
        w='fit-content'
        cursor='pointer'
      >
        <Image
          src={getImage(
            details?.createdBy?.profilePic,
            details?.createdBy?.name
          )}
          boxSize='20px'
          borderRadius='5px'
        />
        <Text
          m={0}
          p={0}
          isTruncated
          maxW='70px'
          fontWeight={500}
          fontSize='12px'
        >
          {details?.createdBy?.name}
        </Text>
      </Flex>
    </Tooltip>
  );
};

export default DocumentUploadedBy;

import React, { FC, ReactNode } from 'react';
import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpFromBracket } from '@fortawesome/sharp-regular-svg-icons';

interface IProps {
  onFileDrop?: DropzoneOptions['onDrop'];
}

const FolderIconUploadSkeleton: FC<IProps> = ({ onFileDrop }) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
    },
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Flex flexDir='column' gap={4} align='center'>
      <Flex
        w='full'
        flexDir='column'
        gap={2}
        p={4}
        border='1px dashed #9A9FA5'
        borderRadius='11px'
      >
        <Box {...getRootProps()}>
          <input {...getInputProps()} />
          <Center h='full' flexDirection='column' gap={1}>
            <FontAwesomeIcon
              icon={faArrowUpFromBracket as IconProp}
              color='#212121'
              size='2x'
            />

            <Text mt={2} fontWeight={400} fontSize='16px' color='#212121'>
              Drag and drop images here
            </Text>
            <Text fontWeight={400} fontSize='12px' color='#212121B2'>
              JPG, PNG - Max size 5Mb
            </Text>
          </Center>
        </Box>
      </Flex>
      <Text fontSize='12px' fontWeight={400} color='#21212199'>
        Recommended size is 256x256px
      </Text>
    </Flex>
  );
};

export default FolderIconUploadSkeleton;

import React, { useEffect } from "react";
import { FC, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
// import Tiptap from "../elements/Tiptap";
// import SimpleText from "./SimpleText";
import NoContent from "../../elements/NoContent";
import MOUNTAIN_GIF from "../../assets/mountain_gif.gif";
import { useTranslation } from "react-i18next";
import { CardType } from "types";
import { Box, Image } from "@chakra-ui/react";

interface IProps {
  content: string;
  milestoneUrl?: string;
}
const Milestone: FC<IProps> = ({ content, milestoneUrl }) => {
  const { t } = useTranslation(["card", "common", "training"]);

  return (
    <>
      {/* PREVIEW DIV */}
      <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
        {/* GIF DIV */}
        <div
          style={{
            height: "600px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
            padding: "5px 10px",
          }}
        >
          <div
            style={{
              height: "600px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              overflowY: "scroll",
              gap: "2rem",
            }}
          >
            <p
              style={{ fontWeight: 700, textAlign: "center", fontSize: "20px" }}
            >
              {t("card:milestoneHeading")}
            </p>
            <Box maxH="60vh">
              <Image
                objectFit="contain"
                w="full"
                h="full"
                src={milestoneUrl || MOUNTAIN_GIF}
                alt="milestone-flag"
              />
            </Box>
            <div
              style={{
                fontSize: 16.8,
                fontWeight: "bold",
                color: "#000",
                width: "100%",
                padding: "0 16px 16px",
                textAlign: "center",
              }}
            >
              {content || "Milestone Name"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Milestone;

import * as Sentry from '@sentry/react';
import jwt_decode from 'jwt-decode';

Sentry.init({
  dsn:
    process.env.REACT_APP_DEPLOYMENT_TYPE === 'production'
      ? 'https://feab89a8e8f9c2389181554b4f446155@o1138388.ingest.us.sentry.io/4507174125240320'
      : 'https://c128824c585e7dfc21a29a048f70bc76@o1138388.ingest.us.sentry.io/4507174112722944',
  environment:
    process.env.REACT_APP_DEPLOYMENT_TYPE === 'production'
      ? 'production'
      : 'staging',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.metrics.metricsAggregatorIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.feedbackIntegration({
      autoInject: false,
      colorScheme: 'system',
      isNameRequired: true,
      isEmailRequired: true,
      useSentryUser: {
        email: 'email',
        name: 'fullName',
      },
      // themeLight: {
      //   // background: '#0062FF',
      //   background: 'white',
      //   foreground: '#0062FF',
      //   backgroundHover: 'white',
      //   border: '1px solid #0062FF',
      //   inputBackground: 'rgb(244, 244, 244)'
      // }
    }),
  ],
  beforeSend: (event) => {
    if (localStorage?.getItem('authToken')) {
      let userFeedback: any;
      const decodedToken: any = jwt_decode(localStorage?.getItem('authToken')!);
      if (decodedToken && decodedToken?.eid && decodedToken?.name) {
        userFeedback = {
          email: decodedToken?.email,
          fullName: decodedToken?.name,
          username: decodedToken?.name,
          id: decodedToken?.eid,
        };
        const feedback = {
          ...userFeedback,
          event_id: event.event_id,
        };
        Sentry.captureUserFeedback(feedback);
      }
    }
    return event;
  },
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'console' && breadcrumb.level === 'warning') {
      if (breadcrumb.message && breadcrumb.message.length > 500) {
        breadcrumb.message =
          breadcrumb.message.substring(0, 500) + '... [truncated]';
      }
    }
    return breadcrumb;
  },
  ignoreErrors: ['AbortError'],
  tracesSampleRate: 1,
  tracePropagationTargets:
    process.env.REACT_APP_DEPLOYMENT_TYPE === 'production'
      ? ['localhost', /^https:\/\/gql-api.delightree\.com\/graphql/]
      : ['localhost', /^https:\/\/api-staging.delightree\.com\/graphql/],
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
});

import styled from '@emotion/styled';

export const FormResponseContainerWrapper = styled.div`
  margin-bottom: 20px;

  .forms-title-container {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 2px;
    overflow: hidden;
  }

  .forms-path-title {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .forms-title-breadcrumb {
    max-width: max-content;
    display: flex;
    gap: 2px;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #6f767e;
  }

  .forms-title-breadcrumb:hover {
    color: #1a1d1f;
  }
`;

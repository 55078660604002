import findLastIndex from 'lodash/findLastIndex';

interface Sel {
  selected?: boolean;
}

export const selected = <T extends Sel>(it: T): boolean => it.selected === true;

export const lastSelectedIndex = <T extends Sel>(data: T[]): number => {
  return findLastIndex(data, (it) => it.selected === true);
};

export const nextSelectedIndex = <T extends Sel>(data: T[]): number => {
  return findLastIndex(data, (it) => it.selected === true) + 1;
};

export const depsFormKey = (sectionIndex: number, taskIndex: number) => {
  return `contents.${sectionIndex}.tasks.${taskIndex}.dependency` as const;
};

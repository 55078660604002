import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';

interface IProps {
  lastUpdated?: string;
  type: string;
}

const AllowedType = ['sop', 'training', 'form', 'task', 'category'];

const ModifiedDate: FC<IProps> = ({ lastUpdated, type }) => {
  if (!lastUpdated || !AllowedType.includes(type)) {
    return null;
  }

  return <Box className='search-item-modified'>- Modified {lastUpdated}</Box>;
};

export default ModifiedDate;

import React, { FC } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const NotAccessibleContainer: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      maxW='800px'
      width='full'
      mx='auto'
      p='24px'
      borderRadius='10px'
      backgroundColor='white'
      my='16px'
      {...props}
    >
      {children}
    </Box>
  );
};

export default NotAccessibleContainer;

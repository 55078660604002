import { ListPropsProps } from 'pages/Chapters/ListGridToggle';
import {
  IConditionType,
  IType,
} from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import React, { ReactNode } from 'react';
import {
  ILanguageSetting,
  ISupportedLocalesEntity,
} from 'ui-components/LanguageSetting/language-setting.types';

// export type IFormLandingTabs = 'all' | 'response';

export type ITrainingLandingMainTabs = 'tracking' | 'myTrainings';

export type ISelectedFolderData =
  | {
      eid: string;
      name: string;
      visibility: IType;
      visibleTo: {
        locations: string[];
        roles: string[];
        users: string[];
        condition?: IConditionType;
      };
      [k: string]: any;
    }
  | undefined;

export type ITrainingLandingSubTabs =
  | 'paths'
  | 'members'
  | 'public'
  | 'assignedToMe';

export interface INavigationPersistKnowledgeBaseEntity {
  selectedPage: number;
  selectedViewType: ListPropsProps['viewType'];
  selectedFolder: ISelectedFolderData;
}

export interface INavigationPersistMembersEntity {
  selectedPage: number;
}

export interface INavigationPersistFormsEntity {
  selectedTab: number;
  selectedPage: number;
}

export interface INavigationPersistTrainingsEntity {
  selectedMainTab: ITrainingLandingMainTabs;
  selectedSubTab: ITrainingLandingSubTabs;
}

export interface INavigationPersistData {
  knowledgeBase: INavigationPersistKnowledgeBaseEntity;
  members: INavigationPersistMembersEntity;
  forms: INavigationPersistFormsEntity;
  trainings: INavigationPersistTrainingsEntity;
}

export interface IDashboardDataContext {
  showErrorModal: boolean;
  errorMessage: string;
  modalTitle: string;
  CloseIcon: string;
  lineColor: string;
  preferredLanguages: ILanguageSetting['SupportedLocales'];
  selectedFolder: ISelectedFolderData;
  showHomePageEmptyState: boolean;
  navigationPersistData: INavigationPersistData;
  isLauncherInProgress: boolean;
  setIsLauncherInProgress: (data: boolean) => void;
  navigationPersistDataHandler: (data: INavigationPersistData) => void;
  showErrorModalHandler: () => void;
  hideErrorModalHandler: () => void;
  errorMessageHandler: (data: string) => void;
  modalTitleHandler: (data: string) => void;
  closeIconHandler: (data: ReactNode) => void;
  lineColorHandler: (data: string) => void;
  preferredLanguagesHandler: (
    data: ILanguageSetting['SupportedLocales']
  ) => void;
  selectedFolderHandler: (data: ISelectedFolderData) => void;
  showHomePageEmptyStateHandler: (data: boolean) => void;
  resetNavigationPersistDataHandler: () => void;
  navigationPersistFormPageUpdateHandler: (page: number) => void;
  navigationPersistFormTabUpdateHandler: (tabIndex: number) => void;
}

const DashboardDataContext = React.createContext<IDashboardDataContext>({
  showErrorModal: false,
  errorMessage: '',
  modalTitle: '',
  CloseIcon: '',
  lineColor: '',
  preferredLanguages: [],
  selectedFolder: {
    eid: '',
    name: '',
    visibility: 'public',
    visibleTo: {
      locations: [],
      roles: [],
      users: [],
    },
  },
  showHomePageEmptyState: true,
  navigationPersistData: {} as INavigationPersistData,
  isLauncherInProgress: false,
  setIsLauncherInProgress: (data: boolean) => {},
  navigationPersistDataHandler: (data) => {},
  showErrorModalHandler: () => {},
  hideErrorModalHandler: () => {},
  errorMessageHandler: (data) => {},
  modalTitleHandler: (data) => {},
  closeIconHandler: (data) => {},
  lineColorHandler: (data) => {},
  preferredLanguagesHandler: (data) => {},
  selectedFolderHandler: (data) => {},
  showHomePageEmptyStateHandler: (data) => {},
  resetNavigationPersistDataHandler: () => {},
  navigationPersistFormPageUpdateHandler: (data) => {},
  navigationPersistFormTabUpdateHandler: (data) => {},
});

export default DashboardDataContext as React.Context<IDashboardDataContext>;

import React, {
  ChangeEvent,
  FC,
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Center,
  Checkbox,
  Flex,
  HStack,
  Image as NativeImage,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { searchRegExp } from 'utils';
import { ActionButton, IconImage, RoleAndAuthRole } from 'ui-components';
import { Loader } from 'sub-components';

import { LocationContext } from './Context';
import { ILocationMember } from './delete-location.graphql';

import emptyIcon from '../../../assets/images/placeholder/empty-member.svg';
import { SelectOption } from 'atoms/Dropdown';
import EmptyState from 'sub-components/EmptyState';
import { ISelectedFilter } from 'sub-components/CustomDropdowns/AuthRoleFilter/AuthRoleFilter';

interface ItemProps {
  item: ILocationMember;
  onCheckChange: (eid: string) => void;
}

const MoveToItem: FC<ItemProps> = ({ item, onCheckChange }) => {
  return (
    <ListItem
      className='move-item'
      _notLast={{
        borderBottom: '1px solid #EFEFEF',
      }}
    >
      <HStack align='center' justify='space-between' pb='10px'>
        <HStack
          style={{
            flex: 1,
            paddingLeft: 6,
            paddingRight: 6,
            cursor: 'pointer',
            overflow: 'hidden',
            userSelect: 'none',
          }}
          onClick={() => onCheckChange(item.eid)}
        >
          <Flex align='stretch' flex='1' overflow='hidden' gap={3}>
            <IconImage
              name={item.name}
              thumbnail={item.profilePic}
              boxSize={36}
              borderRadius='8px'
              objectFit='cover'
            />

            <Box w='max-content'>
              <Box
                noOfLines={1}
                fontSize='14px'
                fontWeight='700'
                color='#1A1D1F'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
              >
                {item.name ?? 'N/A'}
              </Box>

              <RoleAndAuthRole
                role={item.role}
                authRole={item.authRole}
                defaultValue='-'
              />
            </Box>
          </Flex>
        </HStack>
        <Box>
          <Checkbox
            isChecked={item?.checked}
            onChange={() => onCheckChange(item.eid)}
          />
        </Box>
      </HStack>
    </ListItem>
  );
};

export interface MemberMoveToRef {
  onChange: (searchValue: string) => void;
  onFilterChange: (option: SelectOption<string> | undefined | null) => void;
}

interface IProps {
  locationId: string;
  locationName: string;
  onDeletePress?: (...args: any[]) => any | PromiseLike<any>;
  close?: () => void;
}

const MemberMoveTo = forwardRef<MemberMoveToRef, IProps>((props, ref) => {
  const { t } = useTranslation(['common', 'category', 'header', 'location']);

  const [allChecked, setAllChecked] = useState(false);
  const [selectedFilter, setSelectedFilter] =
    useState<ISelectedFilter>(undefined);

  const [searchQuery, setSearchQuery] = useState('');

  const {
    itemsList,
    setItemList,
    fetchData,
    fetchingData: loading,
    totalCount: count,
  } = useContext(LocationContext);

  useImperativeHandle(
    ref,
    () => {
      return {
        onChange: (searchValue: string) => {
          setSearchQuery(searchValue);
        },
        onFilterChange: (option: ISelectedFilter) => {
          if (selectedFilter?.value === option?.value) {
            setSelectedFilter(undefined);
          } else {
            setSelectedFilter(option);
          }
        },
      };
    },
    [fetchData, props.locationId]
  );

  const onCheckChange = (itemId: string) => {
    setItemList?.((prevState) => {
      const newState = [...prevState]?.map((value) => {
        if (value?.eid === itemId) {
          return {
            ...value,
            checked: !value?.checked,
          };
        }
        return value;
      });
      return [...newState];
    });
  };

  const filteredItems = useMemo(() => {
    if (!searchQuery && !selectedFilter) {
      return itemsList;
    } else {
      if (searchQuery && selectedFilter) {
        const reg = searchRegExp(searchQuery, 'gi');
        return itemsList.filter((value) => {
          return (
            (value.name?.match(reg) ||
              value.authRole?.match(reg) ||
              value.role?.match(reg)) &&
            value?.authRole === selectedFilter?.value
          );
        });
      } else if (searchQuery) {
        const reg = searchRegExp(searchQuery, 'gi');
        return itemsList.filter((value) => {
          return (
            value.name?.match(reg) ||
            value.authRole?.match(reg) ||
            value.role?.match(reg)
          );
        });
      } else {
        return itemsList?.filter((value) => {
          return value?.authRole === selectedFilter?.value;
        });
      }
    }
  }, [itemsList, searchQuery, selectedFilter]);

  const onAllCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
    const keys: string[] = filteredItems?.map((val) => val.eid) || [];
    let _setMembers: ILocationMember[] = JSON.parse(JSON.stringify(itemsList));
    _setMembers?.forEach((list) => {
      if (keys.includes(list.eid)) {
        list.checked = event.target.checked;
      }
    });
    setItemList?.(_setMembers);
  };

  const [allItemChecked, isIndeterminate] = useMemo(() => {
    const all = filteredItems?.every((val) => val.checked);
    const some = filteredItems?.some((val) => val.checked);
    return [all && !!filteredItems?.length, some && !all];
  }, [filteredItems]);

  if (count === 0 && !loading) {
    return (
      <Box maxH='calc(100vh - 280px)' overflow='auto'>
        <Flex direction='column' align='center' gap='12px' py='36px'>
          <NativeImage src={emptyIcon} />
          <Box fontSize='15px' fontWeight='700'>
            {t('location:locationNoMembers')}
          </Box>
          <ActionButton
            colorScheme='red'
            px='32px'
            actionFn={props.onDeletePress}
            close={props.close}
          >
            <Trans
              t={t}
              i18nKey='category:delete_name'
              values={{
                value: `“${props.locationName}”`,
              }}
            />
          </ActionButton>
        </Flex>
      </Box>
    );
  }

  return (
    <Box maxH='calc(100vh - 280px)' overflow='auto'>
      <List spacing={3}>
        {loading ? (
          <Center minH='200px'>
            <Loader size='lg' />
          </Center>
        ) : (
          <>
            {filteredItems?.length > 0 ? (
              <HStack align='center' justify='space-between'>
                <Text as='b' pl='6px'>
                  {t('common:select_all')}
                </Text>
                <Checkbox
                  isChecked={allChecked && allItemChecked}
                  isIndeterminate={allChecked && isIndeterminate}
                  onChange={onAllCheckChange}
                />
              </HStack>
            ) : (
              <EmptyState
                image={
                  (searchQuery && selectedFilter) || searchQuery
                    ? 'Search'
                    : selectedFilter
                    ? 'Filter'
                    : 'Job'
                }
                title={
                  (searchQuery && selectedFilter) || searchQuery
                    ? t('header:result_not_found')
                    : selectedFilter
                    ? `No ${selectedFilter?.label} found`
                    : t('common:no_data_found')
                }
              />
            )}
            {filteredItems?.map((member) => {
              return (
                <MoveToItem
                  key={member.eid}
                  item={member}
                  onCheckChange={onCheckChange}
                />
              );
            })}
          </>
        )}
      </List>
    </Box>
  );
});

MemberMoveTo.displayName = 'Member Move to';

export default MemberMoveTo;

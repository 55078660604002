import React, { FC, Fragment } from 'react';
import { Box, Flex, Skeleton } from '@chakra-ui/react';

interface IProps {
  isLoading?: boolean;
}

const TrainingPathSkeleton: FC<IProps> = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Fragment>
      {Array.from({ length: 3 }).map((_, index) => {
        return (
          <Flex py='20px' justify='center' key={index}>
            <Box width='15%' />
            <Box flex={1}>
              <Skeleton height='16px' borderRadius='full' mb={2} />
              <Skeleton height='16px' borderRadius='full' />
            </Box>
            <Box width='10%' />
            <Box flex={1}>
              <Skeleton height='16px' borderRadius='full' mb={2} />
              <Skeleton height='16px' borderRadius='full' />
            </Box>
            <Box width='15%' />
          </Flex>
        );
      })}
    </Fragment>
  );
};

export default TrainingPathSkeleton;

import { gql } from '@apollo/client';

export interface IMoveFormItem {
  eid: string;
  title: string;
  thumbnail: string;
  updatedAt: string;

  createdBy: {
    name: string;
  };
  lastUpdatedBy: {
    name: string;
  };
}

export interface MoveFormListResponse {
  FormsPagination?: {
    items: IMoveFormItem[];
  };
}

export const moveFormListGraphQL = gql`
  query MoveFormList(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyFormsInput
  ) {
    FormsPagination(page: $page, perPage: $perPage, filter: $filter) {
      count
      items {
        eid
        title
        thumbnail
        updatedAt

        createdBy {
          name
        }
        lastUpdatedBy {
          name
        }
      }
    }
  }
`;

export const MOVE_FORM_CATEGORY = gql`
  mutation ChangeFormCategoryBulk($categoryId: String!, $formIds: [String]) {
    ChangeFormCategoryBulk(categoryId: $categoryId, formIds: $formIds) {
      succeed
    }
  }
`;

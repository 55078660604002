import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { LocationFieldEntity } from 'sop-commons/src/client';

import { PrimaryButton } from '../../../atoms';
import { useUserDataQuery } from '../../../hooks';
import { Authorization, AuthRole } from '../../../authorization';

import { useAddLocationField } from './useAddLocationField';
import { useDeleteLocationField } from './useDeleteLocationField';
import LocationFieldList from './LocationFieldList';

interface IProps {}

const LocationSetting: FC<IProps> = () => {
  const { t } = useTranslation(['setting']);

  const addLocationField = useAddLocationField();
  const deleteLocationField = useDeleteLocationField();

  const [getUserData, { loading }] = useUserDataQuery();

  // React.useEffect(() => {
  //   getUserData();
  // }, []);

  const onAddNewClick = () => {
    addLocationField({
      onFieldAdded: () => getUserData(),
    });
  };

  const onClickedHandler = (
    action: 'edit' | 'delete',
    data: LocationFieldEntity
  ) => {
    switch (action) {
      case 'delete':
        return deleteLocationField({
          locationField: data,
          onFieldDeleted: () => getUserData(),
        });
      case 'edit':
        return addLocationField({
          locationField: data,
          onFieldAdded: () => getUserData(),
        });
    }
  };

  return (
    <div>
      <Authorization
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <Box pos='absolute' top='20px' right='32px'>
          <PrimaryButton
            variant='outline'
            colorScheme='blue'
            width='auto'
            title={t('setting:addNewField')}
            leftIcon={<AddIcon />}
            onClick={onAddNewClick}
          />
        </Box>
      </Authorization>

      <LocationFieldList
        isLoading={loading}
        onClickedHandler={onClickedHandler}
      />
    </div>
  );
};

export default LocationSetting;

import { Checkbox, useOutsideClick } from '@chakra-ui/react';
import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { MultiSelectChipDropdownWrapper } from './MultiSelectChipDropdown.styles';

import { getImage } from '../../utils';

interface IMultiSelectChipDropdown {
  dropDownData: {
    value: string;
    label: string;
    id: string;
    selected?: boolean;
  }[];
  placeholder: string;
  color: string;
  selectedDataHandler: (selectedData: IMultiSelectListData[]) => void;
  setThirdStepData: React.Dispatch<React.SetStateAction<IThirdStepData>>;
  type: 'role' | 'location' | 'member' | 'supervisor';
  thirdStepData: IThirdStepData;
  showImage: boolean;
  thirdStepSelections: IThirdStepData;
}

export interface IMultiSelectListData {
  value: string;
  label: string;
  id: string;
  selected?: boolean;
  url?: string;
  role?: string;
  location?: string;
  authRole?: string;
}

const MultiSelectChipDropdown = ({
  dropDownData,
  placeholder,
  color,
  type,
  thirdStepData,
  selectedDataHandler,
  setThirdStepData,
  showImage,
  thirdStepSelections,
}: IMultiSelectChipDropdown) => {
  const match = useRouteMatch<{ id?: string }>();
  const editDraftId =
    match?.params?.id && match?.params?.id !== 'draft' && match?.params?.id;
  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [list, setList] = useState<IMultiSelectListData[]>([]);
  const [open, setOpen] = useState(false);
  useOutsideClick({
    ref: ref,
    handler: () => setOpen(false),
  });

  const dropDownDataHandler = useCallback(() => {
    return dropDownData;
  }, [dropDownData?.length]);

  useEffect(() => {
    if (dropDownData?.length > 0) {
      let _data: IMultiSelectListData[] = JSON.parse(
        JSON.stringify(dropDownData)
      );
      setList(_data);
    }
  }, [dropDownDataHandler]);

  const thirdStepSelectionsDataHandler = useCallback(() => {
    return thirdStepSelections;
  }, [thirdStepSelections]);

  useEffect(() => {
    if (editDraftId && dropDownData?.length > 0) {
      let _data: IMultiSelectListData[] = JSON.parse(
        JSON.stringify(dropDownData)
      );
      if (type === 'location') {
        _data?.map((drop) => {
          thirdStepSelections?.locations?.map((location) => {
            if (drop?.id === location?.id) {
              drop.selected = true;
            }
          });
        });
        setList(_data);
        setThirdStepData((prevValue) => {
          return { ...prevValue, locations: _data };
        });
      } else if (type === 'member') {
        _data?.map((drop) => {
          thirdStepSelections?.members?.map((member) => {
            if (drop?.id === member?.id) {
              drop.selected = true;
            }
          });
        });
        setList(_data);
        setThirdStepData((prevValue) => {
          return { ...prevValue, members: _data };
        });
      } else if (type === 'role') {
        _data?.map((drop) => {
          thirdStepSelections?.roles?.map((role) => {
            if (drop?.value === role?.value) {
              drop.selected = true;
            }
          });
        });
        setList(_data);
        setThirdStepData((prevValue) => {
          return { ...prevValue, roles: _data };
        });
      } else if (type === 'supervisor') {
        _data?.map((drop) => {
          thirdStepSelections?.supervisors?.map((supervisor) => {
            if (drop?.id === supervisor?.id) {
              drop.selected = true;
            }
          });
        });
        setList(_data);
        setThirdStepData((prevValue) => {
          return { ...prevValue, supervisors: _data };
        });
      }
      // }
    }
  }, [dropDownDataHandler, thirdStepSelectionsDataHandler]);

  const checkboxChangeHandler = (checkedData: IMultiSelectListData) => {
    let _data: IMultiSelectListData[] = JSON.parse(JSON.stringify(list));
    _data?.map((_d) => {
      if (_d?.id === checkedData?.id) {
        _d.selected = !checkedData?.selected;
        if (_d.selected) {
          if (type === 'role') {
            deployEvent(
              AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_ASSIGN_ROLES_DROPDOWN_ADD
            );
          } else if (type === 'supervisor') {
            deployEvent(
              AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_SUPERVISORS_DROPDOWN_ADD
            );
          } else if (type === 'location') {
            deployEvent(
              AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_LOCATION_DROPDOWN_ADD
            );
          } else if (type === 'member') {
            deployEvent(
              AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_MEMBER_DROPDOWN_ADD
            );
          }
        } else {
          if (type === 'role') {
            deployEvent(
              AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_ASSIGN_ROLES_DROPDOWN_REMOVE
            );
          } else if (type === 'supervisor') {
            deployEvent(
              AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_SUPERVISORS_DROPDOWN_REMOVE
            );
          } else if (type === 'location') {
            deployEvent(
              AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_LOCATION_DROPDOWN_REMOVE
            );
          } else if (type === 'member') {
            deployEvent(
              AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_MEMBER_DROPDOWN_REMOVE
            );
          }
        }
      }
    });
    setList(_data);
    if (type === 'role') {
      setThirdStepData({ ...thirdStepData, roles: _data });
    } else if (type === 'location') {
      setThirdStepData({ ...thirdStepData, locations: _data });
    } else if (type === 'member') {
      setThirdStepData({ ...thirdStepData, members: _data });
    } else {
      setThirdStepData({ ...thirdStepData, supervisors: _data });
    }
    let filteredSelections = _data?.filter((_l) => _l?.selected);
    selectedDataHandler(filteredSelections);
  };

  const removeItemHandler = (dataList: IMultiSelectListData) => {
    let _dataList: IMultiSelectListData[] = [];
    if (type === 'location') {
      _dataList = JSON.parse(JSON.stringify(thirdStepData?.locations));
    } else if (type === 'member') {
      _dataList = JSON.parse(JSON.stringify(thirdStepData?.members));
    } else if (type === 'role') {
      _dataList = JSON.parse(JSON.stringify(thirdStepData?.roles));
    } else {
      _dataList = JSON.parse(JSON.stringify(thirdStepData?.supervisors));
    }
    _dataList?.map((list) => {
      if (list?.id === dataList?.id) {
        list.selected = false;
      }
    });
    setList(_dataList);
    if (type === 'location') {
      setThirdStepData({ ...thirdStepData, locations: _dataList });
    } else if (type === 'member') {
      setThirdStepData({ ...thirdStepData, members: _dataList });
    } else if (type === 'role') {
      setThirdStepData({ ...thirdStepData, roles: _dataList });
    } else {
      setThirdStepData({ ...thirdStepData, supervisors: _dataList });
    }
    let filteredSelections = _dataList?.filter((_l) => _l?.selected);
    selectedDataHandler(filteredSelections);
  };

  return (
    <MultiSelectChipDropdownWrapper>
      <div style={{ position: 'relative' }}>
        <div ref={ref} onClick={() => setOpen(true)}>
          <div style={{ position: 'relative' }}>
            <input
              style={{
                backgroundColor: 'rgb(244, 244, 244)',
                height: '40px',
                width: '100%',
                borderRadius: '10px',
                padding: '10px',
                outline: 'none',
                cursor: 'pointer',
              }}
              readOnly
              placeholder={placeholder}
            />
            <svg
              viewBox='0 0 24 24'
              focusable='false'
              style={{
                width: '22px',
                height: '22px',
                display: 'inline-block',
                lineHeight: '1em',
                flexShrink: '0',
                color: 'currentcolor',
                verticalAlign: 'middle',
                position: 'absolute',
                top: '9px',
                right: '11px',
              }}
            >
              <path
                fill='currentColor'
                d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'
              ></path>
            </svg>
          </div>
          {open && (
            <div className='multi-selection-container'>
              {list?.map((_d, index) => (
                <div key={index} className='multi-selection-list'>
                  <div
                    onClick={() => checkboxChangeHandler(_d)}
                    style={{ width: '100%' }}
                  >
                    <span>{_d?.value}</span>
                  </div>
                  <Checkbox
                    isChecked={
                      _d?.selected && typeof _d?.selected === 'boolean'
                        ? _d?.selected
                        : false
                    }
                    onChange={() => checkboxChangeHandler(_d)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
            flexWrap: 'wrap',
          }}
        >
          {list
            ?.filter((_l) => _l?.selected)
            ?.map((_list, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: color,
                  padding: '5px 10px',
                  borderRadius: '5px',
                  marginRight: '10px',
                  marginBottom: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {showImage && (
                  <img
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '5px',
                      marginRight: '5px',
                    }}
                    src={getImage(_list?.url, _list?.value)}
                  />
                )}
                <span style={{ fontWeight: '600' }}>{_list?.value}</span>
                <span
                  onClick={() => removeItemHandler(_list)}
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  &times;
                </span>
              </div>
            ))}
        </div>
      </div>
    </MultiSelectChipDropdownWrapper>
  );
};

export default MultiSelectChipDropdown;

import { gql } from '@apollo/client';
import { LauncherTaskStep } from '../common';
import { TASK_DEPENDENCY } from '../../../../types';

interface TemplateTask {
  _id: string;
  title: string;
  description: string;
  steps: LauncherTaskStep[];
  duration: number;
  docRequired: boolean;
  dependency: TASK_DEPENDENCY;
}

export interface TemplateContent {
  _id: string;
  category: string;
  tasks: TemplateTask[];
}

interface LauncherTemplate {
  name: 'big-leap' | 'quick-launch';
  contents: TemplateContent[];
}

export interface TemplateResponse {
  LauncherTemplatePagination: {
    items: Array<LauncherTemplate>;
  };
}

export const LAUNCHER_TEMPLATE = gql`
  query LauncherTemplate {
    LauncherTemplatePagination {
      items {
        name
        contents {
          _id
          category
          tasks {
            _id
            title
            description
            steps
            duration
            docRequired
            dependency
          }
        }
      }
    }
  }
`;

import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { AllOpeningFilterBadge } from '../Singleton';
import { IFilterBadgeProps } from '../Singleton/FilterBadge';

type IFilterBadgeListProps = {
  list: IFilterBadgeProps[];
};

const FilterBadgeList: FC<IFilterBadgeListProps> = ({ list }) => {
  return (
    <Flex gap={4}>
      {list?.map((_l, index) => (
        <AllOpeningFilterBadge key={index} {..._l} />
      ))}
    </Flex>
  );
};

export default FilterBadgeList;

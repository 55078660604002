import { Center, Image, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import EmptyImg from '../../../../assets/images/empty-state/my-task-home-empty-state.svg';

interface IProps {
  emptyDesc?: string;
}

const MyTasksEmpty: FC<IProps> = ({
  emptyDesc = 'Tasks will appear here once they are assigned to you',
}) => {
  return (
    <Center flexDir='column' gap='10px' p={6}>
      <Image src={EmptyImg} w='183px' h='82px' />
      <Text
        fontWeight={400}
        w='200px'
        whiteSpace='pre-wrap'
        textAlign='center'
        fontSize='12px'
        color='#999999'
      >
        {emptyDesc}
      </Text>
    </Center>
  );
};

export default MyTasksEmpty;

import { Flex, Spinner, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import React, { FC } from 'react';
import { OwnerOption } from 'pages/Locations/Components/LocationOwner';
import Loader from 'sub-components/Loader';

interface IProps {
  placeholder: string;
  loading?: boolean;
  isDisabled: boolean | undefined;
  value: OwnerOption;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectField: FC<IProps> = ({
  loading,
  placeholder,
  isDisabled,
  value,
  setShowList,
}) => {
  return (
    <Flex
      align='center'
      w='full'
      justify='space-between'
      bg='#f4f4f4'
      p='0.65rem 1rem'
      flex={1}
      pos='relative'
      overflow='hidden'
      flexWrap='wrap'
      h='40px'
      borderRadius='10px'
      cursor={!isDisabled && !loading ? 'pointer' : 'no-drop'}
      onClick={() => !isDisabled && !loading && setShowList(true)}
    >
      <Text
        color={value?.name && !isDisabled ? 'black' : '#A0AEC0'}
        marginInlineStart='0.125rem'
        marginInlineEnd='0.125rem'
        fontSize='16px'
      >
        {value?.name || placeholder}
      </Text>
      <Flex align='center' gap='10px'>
        {loading && <Spinner colorScheme='blackAlpha' thickness='2px' />}
        <FontAwesomeIcon icon={faChevronDown as IconProp} size='sm' />
      </Flex>
    </Flex>
  );
};

export default SelectField;

export const locationOrgHeaders = [
    {
        heading: 'Location',
        keyName: 'location',
    },
    {
        heading: 'Added On',
        keyName: 'addedOn',
    },
    {
        heading: 'Manager',
        keyName: 'manager',
    }
];
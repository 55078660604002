import React, { FC } from 'react';
import { Flex, List, ListItem, Skeleton, VStack } from '@chakra-ui/react';
import { getRandomNumber } from 'utils';

interface IProps {
  isLoaded?: boolean;
}

const ShareMemberSkeleton: FC<IProps> = ({ isLoaded, children }) => {
  if (isLoaded) {
    return <>{children}</>;
  }

  return (
    <List spacing={4} overflow='scroll' pl={0}>
      {Array.from({ length: getRandomNumber(2, 4) }).map((_, index) => {
        return (
          <ListItem key={`skeleton-${index}`}>
            <Flex gap={3} align='center'>
              <Skeleton boxSize='40px' borderRadius='full' />

              <VStack align='stretch' flex='1'>
                <Skeleton
                  h='13px'
                  borderRadius='full'
                  w={`${getRandomNumber(45, 65)}%`}
                />
                <Flex align='center' gap={1}>
                  <Skeleton w='16%' h='9px' borderRadius='full' />
                  <Skeleton boxSize='6px' borderRadius='full' />
                  <Skeleton w='20%' h='9px' borderRadius='full' />
                </Flex>
              </VStack>

              <Skeleton w='82px' h='30px' borderRadius='7px' />
            </Flex>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ShareMemberSkeleton;

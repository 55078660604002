import { Flex, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchInput from 'atoms/SearchInput';
import moment from 'moment';
import React, { FC, useContext, useMemo, useState } from 'react';
import { Column, SortingTable } from 'sub-components/ChakraTable/SortingTable';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import { SortByName } from 'utils/sorting';
import SupervisedTaskDetailsDataContext from '../../store/supervised-task-details-context';
import ImageText from 'pages/TasksDashboard/Components/sub-components/ImageText';
import IconText from 'pages/TasksDashboard/Components/sub-components/IconText';
import EmptyState from 'sub-components/EmptyState';
import { getImage } from '../../../../../utils';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { AuthRole } from 'authorization';
import { MessageIconButton } from 'atoms';
import { useHasChatAccess } from 'hooks/useChatRestriction';

const Wrapper = styled.div`
  table {
    width: 100% !important;
    background: #ffffff;
  }

  padding-bottom: 40px;

  //table > tbody > tr:first-child {
  //  border-top: 1px solid #efefef;
  //}
  //table > tbody > tr {
  //  border-bottom: 1px solid #efefef;
  //}
`;

interface TaskLocationItem {
  eid: string;
  progress: {
    completedTasks: number;
    totalTasks: number;
  };
  taskDoneBy: {
    name: string;
    profilePic: string;
  };
  assignedBy: {
    name: string;
    profilePic: string;
  };
  assignedOn: string;
  locationName: string;
  locationUserName: string;
  profilePic: string;
}

const TaskLocation: FC = () => {
  const hasChatAccess = useHasChatAccess();
  const supervisedCtx = useContext(SupervisedTaskDetailsDataContext);
  const userData = useReactiveVar(userObj);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const getLocationsData: TaskLocationItem[] = useMemo(() => {
    let _locationsData =
      supervisedCtx?.taskDetails?.lastSession?.assigneeUsersLocations || [];
    let locationsData: any[] = [];
    if (userData?.authRole === AuthRole.LOCATION_OWNER) {
      locationsData = (userData?.locations || [])?.reduce(
        (acc: any[], userData) => {
          const location = _locationsData.find((location) => {
            let flag = location.eid === userData.eid;
            return flag;
          });
          if (location) {
            acc.push(location);
          }
          return acc;
        },
        []
      );
    }
    let accumLocationsData =
      userData?.authRole === AuthRole.LOCATION_OWNER
        ? locationsData
        : _locationsData;
    let locationProgress =
      supervisedCtx?.taskDetails?.lastSession?.locationProgress || [];
    let locationsDataList: TaskLocationItem[] = [];
    accumLocationsData?.map((location) => {
      let foundLocationProgressData = locationProgress?.[location?.eid];
      let _foundProgressData =
        supervisedCtx?.taskDetails?.lastSession?.progress;
      if (Object.keys(foundLocationProgressData)?.length > 0) {
        locationsDataList?.push({
          eid: location?.eid,
          progress: {
            // completedTasks: foundLocationProgressData?.completed,
            completedTasks:
              _foundProgressData
                ?.filter((_found) => _found.userId === location?.eid)
                ?.map((user) => user?.completedTasks)?.[0]?.length || 0,
            totalTasks: supervisedCtx?.taskDetails?.lastSession?.itemCount,
          },
          assignedBy: {
            name: supervisedCtx?.taskDetails?.createdByUser?.name || '-',
            profilePic: getImage(
              supervisedCtx?.taskDetails?.createdByUser?.profilePic,
              supervisedCtx?.taskDetails?.createdByUser?.name
            ),
          },
          taskDoneBy: {
            name: '',
            profilePic: getImage(undefined, 'Name'),
          },
          assignedOn: moment(supervisedCtx?.taskDetails?.createdAt)?.format(
            'DD MMM'
          ),
          locationName: location?.name || '-',
          locationUserName: location?.username,
          profilePic: getImage(location?.profilePic, location?.name),
        });
      }
    });
    if (!searchQuery) {
      return locationsDataList;
    } else {
      return locationsDataList?.filter((location) =>
        location?.locationName
          ?.toLowerCase()
          ?.trim()
          ?.includes(searchQuery?.toLowerCase()?.trim())
      );
    }
  }, [supervisedCtx?.taskDetails, searchQuery]);

  const columns = useMemo((): Column<TaskLocationItem>[] => {
    return [
      {
        Header: 'Name',
        accessor: 'locationName',
        width: '30%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <Flex gap='10px'>
              <Image
                src={getImage(
                  row?.original?.profilePic,
                  row?.original?.locationName
                )}
                boxSize='40px'
                borderRadius='8px'
              />
              <Flex flexDir='column'>
                <Text fontWeight={600} fontSize='15px'>
                  {value}
                </Text>
                <Text
                  fontWeight={500}
                  fontSize='13px'
                  color='#6F767E'
                  marginTop='-5px'
                >
                  {row?.original?.locationUserName || '-'}
                </Text>
              </Flex>
            </Flex>
          );
        },
        sortType: SortByName,
      },
      {
        Header: 'Task done by',
        accessor: 'taskDoneBy',
        width: '20%',
        Cell: ({ cell: { value } }) => {
          return value?.name ? (
            <ImageText
              boldText
              backgroundColor='#CABDFF66'
              text={value?.name}
              img={
                <Image
                  src={value?.profilePic}
                  boxSize='20px'
                  borderRadius='5px'
                  marginRight='5px'
                />
              }
            />
          ) : (
            <Text>-</Text>
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Assigned on',
        accessor: 'assignedOn',
        width: '20%',
        Cell: ({ cell: { value, row } }) => {
          return <Text as='b'>{value || '-'}</Text>;
        },
        disableSortBy: true,
      },
      {
        Header: 'Assigned by',
        accessor: 'assignedBy',
        width: '20%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <ImageText
              boldText
              backgroundColor='#B1E5FC80'
              text={value?.name}
              img={
                <Image
                  src={value?.profilePic}
                  boxSize='20px'
                  borderRadius='5px'
                  marginRight='5px'
                />
              }
              isTruncated
              maxW='100px'
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: 'Progress',
        accessor: 'progress',
        width: '10%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <IconText
              icon={
                <FontAwesomeIcon
                  icon={faCircleCheck as IconProp}
                  color={
                    value?.completedTasks === value?.totalTasks
                      ? '#83BF6E'
                      : '#FF6A55'
                  }
                />
              }
              text={`${value?.completedTasks}/${value?.totalTasks}`}
              textColor={
                value?.completedTasks === value?.totalTasks
                  ? '#83BF6E'
                  : '#FF6A55'
              }
              bg={
                value?.completedTasks === value?.totalTasks
                  ? '#B5E4CA33'
                  : '#FF6A5512'
              }
              padding='5px 10px'
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'eid',
        width: '10%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <MessageIconButton
              onClick={() => setSelectedLocationId(row?.original?.eid)}
            />
          );
        },
        disableSortBy: true,
      },
    ];
  }, []);

  return (
    <>
      <Flex bg='white' p={6} borderRadius='8px' flexDir='column' gap='20px'>
        <GenericColorHeader title='Locations' color='#FFD88D' />
        <SearchInput
          placeholder='Search'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e?.target?.value)}
          hideShortcuts
          width={'400px'}
        />
        <Wrapper>
          <SortingTable
            colorScheme='blue'
            page={1}
            emptyData={{
              content: (
                <EmptyState
                  image={searchQuery ? 'Search' : 'Location'}
                  title={
                    searchQuery ? 'Location not foumd' : 'No Locations found'
                  }
                />
              ),
            }}
            onPageChange={() => {}}
            columns={columns}
            data={getLocationsData}
            isLoading={false}
            totalRegisters={10}
            isResponsive
            isHidePagination
            onRowSelectChange={() => {}}
          />
        </Wrapper>
        {selectedLocationId && (
          <SingleUserChatModal
            selectedUser={selectedLocationId}
            onClose={() => setSelectedLocationId('')}
          />
        )}
      </Flex>
    </>
  );
};

export default TaskLocation;

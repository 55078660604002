import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import {
  ApolloProvider,
  useApolloClient,
  useReactiveVar,
} from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LocationState } from 'history';
import { useTranslation } from 'react-i18next';

import { BoxHeader, UseConfirm, useConfirm } from '../../../ui-components';

import ModalContent from './ModalContent';
import { LocationPath } from './types';
import { foldersListVar } from '../ChapterContainer/ChapterContainer';
import { CategoryEntity } from 'sop-commons/src/client';
import DashboardDataProvider from '../../../sub-components/DashboardContainer/DashboardStore/DashboardData/DashboardDataProvider';

interface IProps {
  onChapterCreated?: () => void;
}

type ChapterCreate = (props: IProps) => void;

export const useCreateChapter = (): ChapterCreate => {
  const { t } = useTranslation(['chapter']);
  const confirm = useConfirm();
  const folderLists = useReactiveVar(foldersListVar);
  const apolloClient = useApolloClient();

  const history = useHistory();

  const { folderId, subFolderId } = useRouteMatch<{
    folderId?: string;
    subFolderId?: string;
  }>().params;

  let foundCategoryData = useMemo(() => {
    let foundParentData: CategoryEntity | undefined = undefined;
    let foundChildData: CategoryEntity | undefined = undefined;
    if (folderId && subFolderId) {
      folderLists?.forEach((list) => {
        list?.children?.forEach((ch) => {
          if (ch?.eid === subFolderId) {
            foundChildData = ch;
            foundParentData = list;
          }
        });
      });
    } else {
      folderLists?.forEach((list) => {
        if (list?.eid === folderId) {
          foundParentData = list;
        }
      });
    }
    return { foundParentData, foundChildData };
  }, [folderId, subFolderId, folderLists]);

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(
    ({ onChapterCreated } = {}) => {
      const pushTo = (location: LocationPath, state?: LocationState) => {
        history.push(location, state);
        confirmRef.current?.destroy();
      };

      if (confirmRef.current) {
        confirmRef.current?.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <DashboardDataProvider>{children}</DashboardDataProvider>
            </ApolloProvider>
          );
        },
        size: '3xl',
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader title={t('chapter:createChapter')} color='#B5E4CA' />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <DashboardDataProvider>
            <ModalContent
              categoryData={foundCategoryData}
              pushTo={pushTo}
              onChapterCreated={onChapterCreated}
            />
          </DashboardDataProvider>
        ),
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '16px',
        },
        isCentered: false,
        footer: null,
      });
    },
    [folderId, subFolderId, foundCategoryData]
  );
};

import React, { FC, useMemo } from 'react';
import {
  Box,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { UserResponse } from './perfor-detail.graphql';
import UserDetail from './UserDetail';
import UserDetailSkeleton from './UserDetailSkeleton';

interface IProps {
  loading?: boolean;
  data?: UserResponse['userById'];
  progressLoading?: boolean;
  progressValue?: number;
  openChat?: (userId: string) => void;
}

const UserDetailsContainer: FC<IProps> = ({
  data,
  progressValue,
  loading,
  progressLoading,
  openChat,
}) => {
  const { t } = useTranslation(['common', 'task']);

  const pValue = useMemo(() => {
    return progressValue || 0;
  }, [progressValue]);

  return (
    <Flex
      flex={2}
      borderRadius='10px'
      bg='#FFFFFF'
      p='20px 16px'
      gap='40px'
      justify='space-between'
    >
      <UserDetailSkeleton isLoaded={!!data || !loading}>
        <UserDetail data={data!} openChat={openChat} />
      </UserDetailSkeleton>

      <Center flexDir='column' gap='8px' px='12px'>
        <CircularProgress
          value={pValue}
          size='136px'
          color={
            pValue > 79 ? 'green.400' : pValue > 39 ? 'orange.400' : 'red.400'
          }
          thickness='5px'
          isIndeterminate={progressLoading}
        >
          <CircularProgressLabel>
            <Text as='b' fontSize='30px' verticalAlign='middle' color='#171725'>
              {pValue}%
            </Text>
          </CircularProgressLabel>
        </CircularProgress>

        <Box fontSize='13px' fontWeight='500' color='#6F767E'>
          {t('task:taskCompletionRate')}
        </Box>
      </Center>
    </Flex>
  );
};

export default UserDetailsContainer;

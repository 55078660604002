import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import './videocard.scss';

import FormInput from '../../../atoms/FormInput';
import TitleHeader from '../TitleHeader';
import { CardFormInput } from '../card-editor.types';
import Uploader from './Uploader';
import { VIMEO_REGEX, YOUTUBE_REGEX } from '../../../utils/constants';

const VideoCardEditor: FC = () => {
  const { t } = useTranslation('card');
  const { t: tc } = useTranslation('common');
  const { control, setValue, getValues } = useFormContext<CardFormInput>();
  // const [fileSize, setFileSize] = useState(0);
  // const [videoAddedBy, setVideoAddedBy] = useState('');
  // const [videoDateAdded, setVideoDateAdded] = useState('');
  const isReadOnly = useWatch<CardFormInput>({ name: 'isReadOnly' });
  const fileSize = useWatch<CardFormInput>({ name: 'videoFileSize' });
  const videoAddedBy = useWatch<CardFormInput>({ name: 'videoAddedBy' });
  const videoDateAdded = useWatch<CardFormInput>({ name: 'videoDateAdded' });

  return (
    <div className='videoCardContainer'>
      {/* <TitleHeader
        title={t('videoTitle')}
        desc={t('video_desc')}
        titleStyle={{ marginTop: '30px' }}
        isRequired
      /> */}

      <div>
        <TitleHeader
          title={t('videoTitle')}
          desc={t('title_desc')}
          titleStyle={{ marginTop: '30px' }}
          isRequired
        />

        <Controller
          name={'title'}
          control={control}
          rules={{
            required: t('validation.title_required'),
            validate: (value) => {
              if (value?.trim()?.length === 0) {
                return tc('validation.enter_title');
              }
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={3} isInvalid={!!fieldState?.error}>
                <FormInput
                  id='title'
                  size='lg'
                  placeholder={t('videoTitlePlaceholder')}
                  isDisabled={isReadOnly}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    setValue('raw_content', event.target.value);
                  }}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </div>
      <div>
        <TitleHeader
          title={t('caption')}
          desc={t('caption_desc')}
          titleStyle={{ marginTop: '30px' }}
        />

        <Controller
          name={'caption'}
          control={control}
          // rules={{
          //   required: t('validation.caption_required'),
          //   validate: (value) => {
          //     if (value?.trim()?.length === 0) {
          //       return t('validation.caption_enter');
          //     }
          //   },
          // }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={3} isInvalid={!!fieldState?.error}>
                <FormInput
                  id='caption'
                  size='lg'
                  isDisabled={isReadOnly}
                  {...field}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </div>

      <Controller
        name='videoUrl'
        control={control}
        rules={{
          required: t('validation.video_invalid_upload'),
          validate: (value) => {
            if (!value) {
              return t('validation.video_invalid_upload');
            }
            if (
              !(
                YOUTUBE_REGEX.test(value) ||
                VIMEO_REGEX.test(value) ||
                value?.includes('s3.amazonaws')
              )
            ) {
              return t('validation.video_invalid_url');
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <Uploader
              isInvalid={!!fieldState.error}
              fileSize={fileSize}
              videoAddedBy={videoAddedBy}
              videoDateAdded={videoDateAdded}
              thumbnail={getValues('thumbnail') || ''}
              errorMessage={fieldState.error?.message}
              value={field.value}
              onChange={(
                videoUrl: string,
                videoMetaData: {
                  fileSize: number;
                  dateAdded: string;
                  addedBy: string;
                }
              ) => {
                field.onChange(videoUrl);
                setValue('videoAddedBy', videoMetaData?.addedBy);
                setValue('videoDateAdded', videoMetaData?.dateAdded);
                setValue('videoFileSize', videoMetaData?.fileSize);
              }}
              onThumbnailChange={(newThumbnail) => {
                setValue('thumbnail', newThumbnail);
              }}
              isReadOnly={isReadOnly}
            />
          );
        }}
      />
    </div>
  );
};

export default VideoCardEditor;

import React, { FC, useMemo } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { Steps } from 'antd';

import { useUserDataSelector } from '../../../hooks';
import { shallowEqual } from '../../../utils';

import SideBar from '../../../assets/images/add-loc-stepper.png';
import StoreIcon from '../../../assets/images/store-icon.svg';
import { StepperWrapper } from './StepperWrapper';
import { IFormInput } from './add-location.types';

interface IProps {}

const LocationSidebar: FC<IProps> = () => {
  const entity = useUserDataSelector((state) => state.entity, shallowEqual);

  const currentTab = useWatch<IFormInput, 'currentTab'>({
    name: 'currentTab',
  });

  const stepList = useMemo(() => {
    const _steps = [
      {
        title: 'Basic information',
      },
      {
        title: 'Location details',
      },
      {
        title: 'Project Details',
      },
      {
        title: 'Add owners',
      },
    ];

    // if (currentTab === 3) {
    //   return [
    //     ..._steps,
    //     {
    //       title: 'Add owners',
    //     },
    //   ];
    // }

    return _steps;
  }, [currentTab]);

  return (
    <Flex pos='relative' width='280px' minW='280px' height='full' bg='#173C98'>
      <Image src={SideBar} w='full' />
      <Flex pos='absolute' boxSize='full' flexDir='column' px='24px' pt='48px'>
        <Flex flexDir='column' gap={4}>
          <Box fontSize='20px' fontWeight='600' color='white'>
            {entity?.name}
          </Box>

          <Image src={StoreIcon} boxSize='44px' mt='12px' />

          <Box fontSize='20px' fontWeight='600' color='white'>
            Launching a new location
          </Box>

          <Box fontSize='13px' fontWeight='600' color='white' opacity='0.6'>
            We’re excited to see your business thriving!
          </Box>

          <StepperWrapper>
            <Steps direction='vertical' current={currentTab}>
              {stepList.map((item, index) => {
                return <Steps.Step key={index} title={item.title} />;
              })}
            </Steps>
          </StepperWrapper>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LocationSidebar;

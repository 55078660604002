import React, { FC } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { faHexagonMinus } from '@fortawesome/pro-regular-svg-icons';

import { ITaskAction } from '../task.types';

interface IBProps {
  icon: IconDefinition;
  title: string;
  description: string;
  color: string;
  onClick?: () => void;
}

const CustomButton: FC<IBProps> = ({
  title,
  description,
  icon,
  color,
  onClick,
}) => {
  return (
    <Flex
      flexDir='column'
      gap={1}
      padding='12px 28px'
      cursor='pointer'
      onClick={onClick}
      _hover={{
        bg: '#EFEFEFB2',
      }}
    >
      <Flex gap={2} align='center'>
        <FontAwesomeIcon icon={icon as IconProp} size='lg' color={color} />
        <Box fontSize='15px' fontWeight='600' color={color}>
          {title}
        </Box>
      </Flex>
      <Box fontSize='12px' color='#6F767E'>
        {description}
      </Box>
    </Flex>
  );
};

interface IProps {
  handleAction?: (type: ITaskAction) => void;
}

const TerminateButton: FC<IProps> = ({ handleAction }) => {
  const { t } = useTranslation(['common', 'task']);

  return (
    <Popover placement='bottom-end'>
      {/*// @ts-ignore */}
      <PopoverTrigger>
        <Button
          size='lg'
          colorScheme='red'
          px={0}
          minW='unset'
          fontSize='15px'
          // onClick={onCreatePress}
          // disabled={isDisabled || !isDirty}
        >
          <Box px={4}>{t('task:terminate')}</Box>
          <Center h='full' borderLeft='2px solid white' px={4}>
            <FontAwesomeIcon icon={faCaretDown as IconProp} size='lg' />
          </Center>
        </Button>
      </PopoverTrigger>
      <PopoverContent boxShadow='lg' borderRadius='12px' _focus={{}}>
        <PopoverArrow />
        <PopoverBody px={0}>
          <CustomButton
            icon={faHexagonMinus}
            title={t('task:terminate')}
            color='#33383F'
            description='It can be restarted. Meanwhile, task history will be available to everyone.'
            onClick={() => handleAction?.('terminate')}
          />
          <CustomButton
            icon={faTrashCan}
            title={t('common:delete')}
            color='#FF6A55'
            description='This permanently deletes the task. Task and task history will be removed for everyone.'
            onClick={() => handleAction?.('softDelete')}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default TerminateButton;

import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Action, Location, LocationState, UnregisterCallback } from 'history';
import { AlertType, AsyncAlertEvent, PromptAction } from './alerts';

export interface AlertConfig {
  alertType: AlertType;
}

type BlockConfig<S = LocationState> = (
  location: Location<S>,
  action: Action
) => string | false | void | AlertConfig;

interface IProps {
  canShowPrompt: boolean;
  blockConfig?: string | BlockConfig | AlertConfig;
  actionHandler?: (action: PromptAction, data: any) => Promise<void> | void;
}

export const useBlockPrompt = (props?: IProps): void => {
  const blockRef = useRef<UnregisterCallback>();
  const history = useHistory();

  useEffect(() => {
    if (props?.actionHandler) {
      const listener = AsyncAlertEvent.listen(async (restArgs) => {
        await props.actionHandler?.(restArgs.action, restArgs.data);
      });

      return () => listener();
    }
  }, [props?.actionHandler]);

  useEffect(() => {
    if (props?.canShowPrompt) {
      blockRef.current = history.block((location, action) => {
        if (typeof props.blockConfig === 'function') {
          const res = props.blockConfig(location, action);

          if (typeof res === 'object') {
            return JSON.stringify(res);
          }
          return res;
        }

        if (typeof props.blockConfig === 'object') {
          return JSON.stringify(props.blockConfig);
        }

        return props.blockConfig;
      });

      return blockRef.current;
    } else {
      blockRef.current?.();
    }
  }, [props?.canShowPrompt]);
};

export { PromptAction };

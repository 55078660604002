import { useMemo } from 'react';
import { JSONContent } from 'delightree-editor';
import { toArray } from '../../../../utils/utils';
import { removeEmptyData } from '../../../../sub-components/ChapterEditor/SmartPages';

export function useSmartPreviewFormat(
  value?: JSONContent,
  selectedIndex: number = 0
): JSONContent | undefined {
  return useMemo(() => {
    const content = toArray(value)[selectedIndex];
    return {
      type: 'doc',
      content: removeEmptyData(toArray(content)),
    };
  }, [value, selectedIndex]);
}

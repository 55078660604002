import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faCloudArrowUp,
  faX,
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PrimaryButton from '../../../atoms/PrimaryButton';
import { useTrainingProgress } from './progress';
import { TrainingPlayContext } from './context';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { toArray } from 'utils/utils';

interface IProps {}

interface ILocationState {
  backToTitle: string;
}

const TrainingPlayHeader: FC<IProps> = () => {
  const history = useHistory();
  const trainingProgress = useTrainingProgress();
  const {
    loading,
    updating,
    trainingItems,
    userProgress,
    userData,
    trainingContents,
    selectedIndex,
    trainingData,
  } = useContext(TrainingPlayContext);
  const locState = useLocation<ILocationState>()?.state;

  const { t } = useTranslation(['common', 'training']);

  const rawContent = useMemo(() => {
    return trainingContents?.[selectedIndex];
  }, [trainingContents, selectedIndex]);

  const onBackPress = () => {
    if (locState?.backToTitle) {
      history.goBack();
    } else {
      history.replace('/training');
    }
  };

  const onProgress = useCallback(() => {
    trainingProgress({
      trainingItems: trainingItems,
      userProgress: userProgress,
      name: userData?.name as string,
      profilePic: userData?.profilePic,
      selectedTrainingId: rawContent?.sopId || rawContent?.uid,
    });
  }, [trainingItems, userProgress, userData, rawContent]);

  const downloadFileHandler = (rawContent: any) => {
    const file = toArray(rawContent?.files)[0];
    switch (rawContent?.sopType) {
      case 'video':
        window.open(rawContent?.media?.[0]?.mediaUrl, '_blank');
        break;
      case 'document':
        window.open(file?.url, '_blank');
        break;
    }
  };

  return (
    <Flex align='center' justify='space-between' gap='36px'>
      <Box overflow='hidden' fontWeight='600'>
        <Box
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow='ellipsis'
          fontSize='24px'
          color='#272B30'
        >
          {trainingData?.title}
        </Box>
        <Flex
          align='center'
          color='#6F767E'
          fontSize='15px'
          cursor='pointer'
          gap='8px'
          onClick={onBackPress}
        >
          <FontAwesomeIcon
            icon={faChevronLeft as IconProp}
            size='xs'
            color='#6F767E'
          />
          {locState?.backToTitle || t('training:back_to_training')}
        </Flex>
      </Box>
      <Flex gap='12px' align='center'>
        {updating && (
          <FontAwesomeIcon
            icon={faCloudArrowUp as IconProp}
            bounce
            color='#2a85ff'
          />
        )}
        {rawContent?.sopType && rawContent?.sopType !== 'text' && (
          <IconButton
            borderRadius='full'
            variant='solid'
            colorScheme='gray'
            bg='#FFFFFF'
            aria-label='more'
            icon={<FontAwesomeIcon icon={faArrowDownToLine as IconProp} />}
            onClick={() => downloadFileHandler(rawContent)}
          />
        )}
        <PrimaryButton
          variant='solid'
          colorScheme='gray'
          title={t('training:viewProgress')}
          disabled={loading || updating}
          style={{
            background: '#FFFFFF',
          }}
          onClick={onProgress}
        />
        <IconButton
          borderRadius='full'
          variant='solid'
          colorScheme='gray'
          bg='#FFFFFF'
          aria-label='more'
          icon={<FontAwesomeIcon icon={faX as IconProp} color='#5B5A64' />}
          onClick={() => history.goBack()}
        />
      </Flex>
    </Flex>
  );
};

export default TrainingPlayHeader;

// @ts-ignore
import React, { ChangeEvent, FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import HandbookCategory from './HandbookCategory';
import SearchInput from '../../atoms/SearchInput';
import AddCardDeckButton from './AddCardDeckButton';
import { SelectOption } from '../../atoms/Dropdown';

interface IProps {
  totalDeck: number;
  totalCard: number;
  isDeckOnly: boolean;
  onShowOnlyChange: (value: boolean) => void;
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  searchQuery: string;
  onSortChange: (value: SelectOption) => void;
  sortValue: SelectOption;
  onCardCreate: () => void;
  onDeckCreate: () => void;
  viewType?: 'list' | 'grid';
  onViewChange?: (value: 'list' | 'grid') => void;
}

const HandbookHeader: FC<IProps> = ({
  totalDeck,
  totalCard,
  isDeckOnly,
  onShowOnlyChange,
  onSearch,
  searchQuery,
  onSortChange,
  sortValue,
  onCardCreate,
  onDeckCreate,
  viewType,
  onViewChange,
}) => {
  const { t } = useTranslation();
  return (
    <Box>
      <HandbookCategory
        deckCount={totalDeck}
        cardCount={totalCard}
        isChecked={isDeckOnly}
        onChange={onShowOnlyChange}
      />
      <Flex justify='space-between' gap='12px'>
        <SearchInput
          disabled={true}
          size='md'
          width='350px'
          hideShortcuts={true}
          placeholder={t('search')}
          onChange={onSearch}
          value={searchQuery}
          className='handbook-search'
        />
        <AddCardDeckButton
          onCardPress={onCardCreate}
          onDeckPress={onDeckCreate}
          selectedSortValue={sortValue}
          setSelectedSortValue={onSortChange}
          viewType={viewType}
          onViewChange={onViewChange}
        />
      </Flex>
    </Box>
  );
};

export default HandbookHeader;

import { Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICompareVisibilityType } from 'pages/Chapters/CreateEditSubFolder/components/helper/visibilityHelper';
import { faCircleCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import { IDetailedVisibility } from '../helper/useVisibilityStatusInfoLogic';

interface IProps {
  status: ICompareVisibilityType;
  childDetails: IDetailedVisibility | undefined;
  parentDetails: IDetailedVisibility | undefined;
}

const TypeFolder: FC<IProps> = ({ status, childDetails, parentDetails }) => {
  console.log({ TypeFolder: status });
  switch (status) {
    case 'parentChildSameLengthDifferentVisibility':
      return (
        <Flex
          bg='#FFD88D80'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color='#FFAA00'
          />
          <Flex flexDir='column'>
            <Flex flexWrap='wrap' align='center'>
              <Text m={0} p={0}>
                Contents of this folder are
              </Text>
              <Text fontWeight={700} mx='5px' p={0}>
                visible to more members
              </Text>
              <Text m={0} p={0}>
                than those mentioned above, as defined in the individual
                visibilities of the subfolders and chapters.
              </Text>
              <Text>
                Since the parent folder is not visible to all those members,
                they will find the chapters and subfolders via Search.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      );
    case 'parentChildSameVisibleSameLength':
    case 'parentPublicChildPublic':
      return (
        <Flex
          bg='#B5E4CA99'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            style={{ marginTop: '5px' }}
          />
          <Text>
            The contents of this folder share the same visibility as mentioned
            above.
          </Text>
        </Flex>
      );
    case 'parentLessVisibleThanChild':
      return (
        <Flex
          bg='#FFD88D80'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color='#FFAA00'
          />
          <Flex flexDir='column'>
            <Flex flexWrap='wrap' align='center'>
              <Text m={0} p={0} mr='5px'>
                Contents of this folder are
              </Text>
              <Text fontWeight={700} p={0} mr='5px'>
                visible to more members
              </Text>
              <Text m={0} p={0}>
                than those mentioned above, as defined in the individual
                visibilities of the subfolders and chapters.
              </Text>
              <Text>
                Since the parent folder is not visible to all those members,
                they will find the chapters and subfolders via Search.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      );
    case 'parentMoreVisibleThanChild':
      return (
        <Flex
          bg='#B5E4CA99'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            style={{ marginTop: '5px' }}
          />
          <Flex align='center' flexDir='column'>
            <Text>
              Contents of this folder have varied visibility, but they all fall
              within the larger set mentioned above.
            </Text>
          </Flex>
        </Flex>
      );
    case 'parentChildNoIntersection':
      return (
        <Flex
          bg='#FFD88D80'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color='#FFAA00'
          />
          <Flex flexDir='column'>
            <Flex flexWrap='wrap' align='center'>
              <Text m={0} p={0} mr='5px'>
                Contents of this folder are
              </Text>
              <Text fontWeight={700} p={0} mr='5px'>
                visible to more members
              </Text>
              <Text m={0} p={0}>
                than those mentioned above, as defined in the individual
                visibilities of the subfolders and chapters.
              </Text>
              <Text>
                Since the parent folder is not visible to all those members,
                they will find the chapters and subfolders via Search.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      );
    default:
      return (
        <Flex
          bg='#FFD88D80'
          p='16px 20px'
          borderRadius='10px'
          align='baseLine'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color='#FFAA00'
          />
          <Flex flexDir='column'>
            <Flex flexWrap='wrap' align='center'>
              <Text m={0} p={0} mr='5px'>
                Contents of this folder are
              </Text>
              <Text fontWeight={700} p={0} mr='5px'>
                visible to more members
              </Text>
              <Text m={0} p={0}>
                than those mentioned above, as defined in the individual
                visibilities of the subfolders and chapters.
              </Text>
              <Text>
                Since the parent folder is not visible to all those members,
                they will find the chapters and subfolders via Search.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      );
  }
};

export default TypeFolder;

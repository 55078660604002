import React, { FC } from 'react';
import DashboardContainer from '../../sub-components/DashboardContainer';
import CreateFromTemplate from '../../sub-components/forms/create-from-template';

const CreateFormFromTemplate: FC = () => {
  return (
    <DashboardContainer>
      <CreateFromTemplate />
    </DashboardContainer>
  );
};

export default CreateFormFromTemplate;

import Editor from "delightree-editor";
import { FC, CSSProperties } from "react";

interface IProps {
  content: Array<any>;
  style?: CSSProperties;
  backgroundColor?: string;
}

const Tiptap: FC<IProps> = ({ content, style, backgroundColor }) => {
  return (
    <div
      style={{
        padding: "55px 34px",
        ...style,
        backgroundColor: backgroundColor,
      }}
    >
      <Editor readonly value={content[0]?.tiptap} />
    </div>
  );
};

export default Tiptap;

import { gql } from '@apollo/client';
import { TASK_DEPENDENCY } from '../../../../types';
import { LauncherConfigFragment, LauncherTaskStep } from './fragment.graphql';

export interface TaskVariable {
  eid?: string;
  title: string;
  description?: string;
  duration?: number;

  completed: boolean;

  dependency: TASK_DEPENDENCY;
  docRequired?: boolean;

  steps: LauncherTaskStep[];

  selected?: boolean;
}

export interface ContentsVariable {
  eid?: string;
  category: string;
  selected?: boolean;
  tasks: TaskVariable[];
}

export interface UpdateLauncherInput {
  eid: string;
  published?: boolean;
  contents: ContentsVariable[];
}

export const UPDATE_CONFIG_QUERY = gql`
  ${LauncherConfigFragment}
  mutation UpdateLauncherTasks($input: UpdateLauncherInputInput) {
    UpdateLauncherTasks(input: $input) {
      ...LauncherConfig
    }
  }
`;

// EventBus.ts
type EventListener = (data?: any) => void;

class EventBus {
  private events: { [key: string]: EventListener[] } = {};

  on(event: string, listener: EventListener) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);

    return () => this.off(event, listener);
  }

  off(event: string, listenerToRemove: EventListener): void {
    if (!this.events[event]) {
      return;
    }
    this.events[event] = this.events[event].filter(
      (listener) => listener !== listenerToRemove
    );
  }

  emit(event: string, data?: any): void {
    if (this.events[event]) {
      this.events[event].forEach((listener) => listener(data));
    }
  }
}

export const eventBus = new EventBus();

import React, { FC, useContext, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { PreviewContext } from './context';
import TrainingDesktop from './component/TrainingDesktop';
import TrainingTablet from './component/TrainingTablet';
import TrainingMobile from './component/TrainingMobile';

interface IProps {}

const PreviewComponent: FC<IProps> = () => {
  const { viewMode, trainingContents, selectedIndex, chapterIcon } =
    useContext(PreviewContext);

  const rawContent = useMemo(() => {
    return trainingContents?.[selectedIndex];
  }, [trainingContents, selectedIndex]);

  return (
    <Flex justify='center' overflow='hidden' height='full'>
      {viewMode === 'desktop' && (
        <TrainingDesktop value={rawContent} isPreview />
      )}
      {viewMode === 'tablet' && (
        <TrainingTablet value={rawContent} isPreview isNotDesktop />
      )}
      {viewMode === 'mobile' && (
        <TrainingMobile value={rawContent} isPreview isNotDesktop />
      )}
    </Flex>
  );
};

export default PreviewComponent;

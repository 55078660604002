import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMenu, IActionMenuData } from 'ui-components';
import { TrainingPathModal } from '../modal';
// @ts-ignore
import OpenIcon from 'assets/images/open.png';
// @ts-ignore
import ProgressIcon from 'assets/images/formResponse.svg';
// @ts-ignore
import ArchiveIcon from 'assets/images/archive.png';
// @ts-ignore
import DarkEditIcon from 'assets/images/edit-dark.svg';
// @ts-ignore
import DeleteIcon from 'assets/images/trash-red.svg';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { MANAGER, WORKER } from '../../../../utils/userRoles';
import { UserData } from 'sop-commons/src/client';
import { useHistory } from 'react-router-dom';
import { useTrainingDelete } from '../TrainingDelete';
import { Column } from 'sub-components/ChakraTable/SortingTable';
import PathName from 'pages/Training/Training/PathName';
import { NullShorting } from 'utils/sorting';
import Assignee from 'pages/Training/Training/Assignee';
import { CreatedBy, DateCell } from 'pages/Training/Training/TrainingComponent';
import { Box, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { TSortBy } from 'types';
import { match } from 'ts-pattern';
import { ActionType } from 'react-table';
import { toArray } from 'utils/utils';

type IMenuCallback = (menuData: TrainingPathModal) => IActionMenuData[];

function SortByAssignee(a: any, b: any, c: string) {
  const valA = a.values[c]?.length;
  const valB = b.values[c]?.length;
  return valB - valA;
}

export const useModelLayer = (
  deleteTrainingHandler: (eid: string) => void,
  setPseudoTrainingData: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      data: UserData;
      item: TrainingPathModal;
    }>
  >,
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>,
  setSelectedTraining: (
    value: React.SetStateAction<TrainingPathModal | undefined>
  ) => void,
  setSortBy: React.Dispatch<React.SetStateAction<string>>,
  updateTrainingHandler: (eid: string, title: string, status: string) => void
) => {
  const { t, i18n } = useTranslation(['common', 'training']);
  const history = useHistory();
  const loggedInUserData = useUserDataSelector((state) => state);
  const trainingDelete = useTrainingDelete();
  const getMenuData = useCallback<IMenuCallback>(
    (training) => {
      const openMenu = {
        name: t('common:open'),
        value: 'open',
        icon: OpenIcon,
      };
      if (loggedInUserData?.type === 'user') {
        const trackProgress = {
          name: t('training:track_progress'),
          value: 'track-progress',
          icon: ProgressIcon,
        };

        if (
          loggedInUserData?.authRole === AuthRole.SUPER_ADMIN ||
          training.creatorId === loggedInUserData?.eid ||
          training.supervisors?.includes(loggedInUserData?.eid)
        ) {
          return [
            trackProgress,
            {
              name: t('common:edit'),
              value: 'edit',
              icon: DarkEditIcon,
            },
            // {
            //   name: t('training:view_changelog'),
            //   value: 'view-changelog',
            //   icon: viewChangeLogIcon,
            // },
            {
              name: t(
                training.status === 'active'
                  ? 'training:mark_as_inactive'
                  : 'training:markActive'
              ),
              value:
                training.status === 'active' ? 'mark-inactive' : 'mark-active',
              icon: ArchiveIcon,
            },
            {
              type: 'divider',
              name: '',
              value: '',
              icon: '',
            },
            {
              name: t('common:delete'),
              value: 'delete',
              textColor: '#FF6A55',
              icon: DeleteIcon,
            },
          ];
        } else if (loggedInUserData?.authRole === AuthRole.ADMIN) {
          return [trackProgress];
        }
        return [];
      } else if (training.status === 'inactive') {
        return [];
      } else {
        return [openMenu];
      }
    },
    [loggedInUserData]
  );

  const openTraining = useCallback((item: TrainingPathModal) => {
    deployEvent(AmplitudeEventNames.TRAINING_OPEN_PATH);

    if (loggedInUserData?.type === 'branch') {
      return setPseudoTrainingData({
        data: loggedInUserData,
        show: true,
        item: item,
      });
    } else if (
      AuthRole.SUPER_ADMIN === loggedInUserData?.authRole ||
      item.creatorId === loggedInUserData?.eid
    ) {
      history.push(`/training/${item.eid}`);
    } else if (
      (AuthRole.LOCATION_OWNER === loggedInUserData?.authRole &&
        !item.assignedToUsers.some((it) => it.eid === loggedInUserData?.eid)) ||
      item?.supervisors.includes(loggedInUserData?.eid)
    ) {
      history.push(`/training/${item.eid}`);
    } else {
      history.push(`/view/${item.eid}`);
    }
  }, []);

  const clickedHandler = async (
    menuClicked: string,
    item: TrainingPathModal
  ) => {
    if (
      item.status === 'inactive' &&
      [MANAGER, WORKER].includes(loggedInUserData.authRole)
    ) {
      return;
    }

    if (menuClicked === 'edit') {
      deployEvent(AmplitudeEventNames.TRAINING_EDIT_PATH);
      history.push(`/training/${item.eid}/edit`);
    }
    if (menuClicked === 'open' || menuClicked === 'track-progress') {
      if (menuClicked === 'track-progress') {
        deployEvent(AmplitudeEventNames.TRAINING_TRACK_PROGRESS);
      }
      return openTraining(item);
    }
    if (menuClicked === 'view-changelog') {
      deployEvent(AmplitudeEventNames.TRAINING_VIEW_CHANGELOG);
      setSelectedTraining(item);
    }
    if (menuClicked === 'mark-inactive' || menuClicked === 'mark-active') {
      deployEvent(AmplitudeEventNames.TRAINING_STATUS_CHANGE);
      updateTrainingHandler(item.eid, item.title, item.status);
    }
    if (menuClicked === 'delete') {
      deployEvent(AmplitudeEventNames.TRAINING_DELETE);
      trainingDelete({
        status: item.status,
        onInactivePress: () => {
          return updateTrainingHandler(item.eid, item.title, 'inactive');
        },
        onDeletePress: () => {
          return deleteTrainingHandler(item.eid);
        },
      });
    }
  };
  const columns = useMemo((): Column<TrainingPathModal>[] => {
    return [
      {
        Header: t('training:path_name') as string,
        accessor: 'title',
        width: '32%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <PathName
              pathName={value}
              thumbnail={row?.original?.thumbnail}
              status={row?.original?.status}
              updatedAt={row?.original?.updatedAt}
              lastUpdatedBy={row?.original?.lastUpdatedBy}
              visibility={row?.original?.visibility}
              index={row.index}
              maxW='200px'
              onClick={() => clickedHandler('open', row?.original)}
            />
          );
        },
        sortType: NullShorting,
      },
      // {
      //   Header: t('training:chapters'),
      //   accessor: 'chapterCount', //
      //   width: '8%',
      //   Cell: ({ cell: { value, row } }) => {
      //     return <CardsCount count={value} status={row?.original?.status} />;
      //   },
      //   sortType: SortByNumber,
      // },
      {
        Header: t('training:assignee') as string,
        accessor: 'assignedToUsers',
        width: '10%',
        Cell: ({ cell: { value, row } }) => {
          return <Assignee assignees={value} status={row?.original?.status} />;
        },
        sortType: SortByAssignee,
      },
      {
        Header: t('training:created_by') as string,
        accessor: 'createdByName',
        width: '15%',
        Cell: ({ cell: { value, row } }) => {
          return <CreatedBy name={value} status={row?.original?.status} />;
        },
        sortType: NullShorting,
      },
      {
        Header: t('training:created_on') as string,
        accessor: 'createdOn',
        width: '15%',
        Cell: ({ cell: { value, row } }) => {
          return <DateCell date={value} status={row?.original?.status} />;
        },
        sortType: NullShorting,
      },
      {
        Header: t('training:last_activity') as string,
        accessor: 'updatedAt',
        width: '15%',
        Cell: ({ cell: { value, row } }) => {
          return <DateCell date={value} status={row?.original?.status} />;
        },
        sortType: NullShorting,
      },
      {
        Header: '',
        accessor: 'eid',
        width: '5%',
        Cell: ({ cell: { value, row } }) => {
          const status = row.original.status;
          return (
            <Box id={value} mb='25px'>
              {[WORKER, MANAGER].includes(loggedInUserData.authRole) &&
              status === 'inactive' ? (
                <Flex justify='center' cursor='pointer'>
                  <FontAwesomeIcon
                    icon={faEllipsis as IconProp}
                    size='2x'
                    color='#33383F'
                    style={{
                      opacity: '.5',
                      cursor: 'no-drop',
                    }}
                  />
                </Flex>
              ) : (
                <ActionMenu
                  menuData={getMenuData(row.original)}
                  arrowSize={10}
                  closeOnBlur={true}
                  offset={[5, 5]}
                  clickedItem={(_, clickedItem) => {
                    return clickedHandler(clickedItem, row.original);
                  }}
                >
                  <Flex justify='center' cursor='pointer'>
                    <FontAwesomeIcon
                      icon={faEllipsis as IconProp}
                      size='2x'
                      color='#33383F'
                      style={{
                        opacity: status === 'active' ? '1' : '.5',
                      }}
                    />
                  </Flex>
                </ActionMenu>
              )}
            </Box>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [i18n.language, getMenuData]);
  const onPageChangeHandler = (page: number) => {
    setSelectedPage(page);
  };

  const updateSortBy = (values: TSortBy) => {
    const newValue = match(values)
      .with({ id: 'title' }, (sel) => (sel.desc ? 'TITLE_ASC' : 'TITLE_DESC'))
      .with({ id: 'createdOn' }, (sel) =>
        sel.desc ? 'CREATEDAT_ASC' : 'CREATEDAT_DESC'
      )
      .with({ id: 'updatedAt' }, (sel) =>
        sel.desc ? 'UPDATEDAT_ASC' : 'UPDATEDAT_DESC'
      )
      .with({ id: 'createdByName' }, (sel) =>
        sel.desc ? 'CREATEDBY_ASC' : 'CREATEDBY_DESC'
      )
      .otherwise(() => 'CREATEDAT_DESC');
    setSortBy(newValue);
  };

  const stateReducer = <T extends unknown>(newState: T, action: ActionType) => {
    switch (action?.type) {
      case 'toggleSortBy':
        // @ts-ignore
        updateSortBy(toArray<TSortBy>(newState?.sortBy)[0]);
        break;
    }

    return newState;
  };
  return {
    columns,
    onPageChangeHandler,
    stateReducer,
  };
};

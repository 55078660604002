import React, { FC } from 'react';
import { useFormState } from 'react-hook-form';
import { Button } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { FormPreviewInput } from './form-preview.types';

interface IProps {
  loading: boolean;
}

const SubmitButton: FC<IProps> = ({ loading }) => {
  const { t } = useTranslation('common');
  const { isSubmitSuccessful, isSubmitting } = useFormState<FormPreviewInput>();

  if (isSubmitSuccessful) {
    return (
      <Button
        mt='auto'
        width='full'
        bg='#83bf6e'
        color='#fcfcfc'
        _hover={{
          bg: '#83bf6ecc',
          color: '#fcfcfc',
        }}
        _active={{
          bg: '#83bf6ecc',
        }}
        leftIcon={<CheckIcon />}
      >
        {t('submitted')}
      </Button>
    );
  }

  return (
    <Button
      mt='auto'
      width='full'
      colorScheme='blue'
      type='submit'
      isLoading={loading || isSubmitting}
    >
      {t('submit')}
    </Button>
  );
};

export default SubmitButton;

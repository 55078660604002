import React, { FC, useEffect, useState } from 'react';
import { Center, Flex, FlexProps, Progress } from '@chakra-ui/react';

// @ts-ignore
import pdfJS from '@bundled-es-modules/pdfjs-dist';
import PdfPage from './PdfPage';

const worker = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.2.228/pdf.worker.js';

pdfJS.GlobalWorkerOptions.workerSrc = worker;

interface IProps extends Pick<FlexProps, 'style'> {
  url: string;
}

const PdfViewer: FC<IProps> = ({ url, style }) => {
  const [pdf, setPdf] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    (async function () {
      try {
        setProgress(0);
        setLoading(true);
        const _pdf = pdfJS.getDocument(url);

        _pdf.onProgress = function (data: any) {
          setProgress(Math.round((data?.loaded / data?.total) * 100));
        };

        const pdfData = await _pdf.promise;
        setPdf(pdfData);
        setLoading(false);
      } catch (e) {
        console.log('file load failed');
        setLoading(false);
      }
    })();
  }, [url]);

  if (loading) {
    return (
      <Flex flex={1} flexDir='column' gap={2} bg='#f4f4f4' p={2} style={style}>
        <Center flex={1}>
          <Progress
            w='350px'
            height={2}
            borderRadius='4px'
            value={progress}
            colorScheme={progress >= 99 ? 'green' : 'blue'}
            isIndeterminate={progress < 1}
            hasStripe={loading}
            isAnimated={loading}
          />
        </Center>
      </Flex>
    );
  }

  if (!pdf) {
    return null;
  }

  return (
    <Flex
      width='full'
      flexDir='column'
      gap={2}
      bg='#f4f4f4'
      p={2}
      style={style}
    >
      {Array.from({ length: pdf.numPages }).map((_, index) => {
        return <PdfPage key={index} pageNumber={index + 1} pdf={pdf} />;
      })}
    </Flex>
  );
};

export default PdfViewer;

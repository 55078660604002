import { gql } from '@apollo/client';
import { UserStatus } from 'types';

export interface LocationResponse {
  branch: {
    descendantBranches: Array<{
      name: string;
      eid: string;
      status: string;
    }>;
    descendantUsers: Array<{
      name: string;
      eid: string;
      status: UserStatus;
    }>;
  };
}

export const getLocations = gql`
  query VisibilityLocationList {
    branch {
      descendantBranches {
        name
        eid
        status
      }
      descendantUsers {
        eid
        name
        status
      }
    }
  }
`;

import React, {
  FC,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import {
  OptionProps,
  Select,
  components,
  SelectInstance,
  MultiValue,
} from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getChakraSelectStyles } from '../../../../atoms/Dropdown/dropdown.styles';

interface SelectOption {
  id: string;
  label: string;
  value: string;
  url?: string;
}

interface OptionExtendedProps extends OptionProps<SelectOption, true> {
  selectedData: SelectOption[];
}

const Option: FC<OptionExtendedProps> = ({ selectedData, ...props }) => {
  const { data, children } = props;
  let selectedDataEids = selectedData?.map((data) => data?.id);
  const isSelected = selectedDataEids?.includes(data?.id);

  return (
    <components.Option {...props} isSelected={false}>
      <Flex align='center'>
        <FontAwesomeIcon
          icon={faLocationDot as IconProp}
          color='#999999'
          style={{
            backgroundColor: '#F4F4F4',
            borderRadius: '50%',
            padding: '8px',
            height: '17.2px',
            width: '17.7px',
          }}
        />
        <Text flex={1} noOfLines={1} px='8px'>
          {children}
        </Text>
        {isSelected && (
          <FontAwesomeIcon icon={faCheck as IconProp} color='#83bf6e' />
        )}
      </Flex>
    </components.Option>
  );
};

interface IProps {
  locations: SelectOption[];
  isMulti: true | undefined;
  value?: string[];
  onChange: (newValue: string[]) => void;
}

const LocationSelect = forwardRef<unknown, IProps>(
  ({ locations, isMulti, value = [], onChange }) => {
    const { t } = useTranslation(['common', 'form']);
    const ref = useRef<SelectInstance<SelectOption, true>>(null);
    const valueRef = useRef<MultiValue<SelectOption>>([]);
    const [innerValue, setInnerValue] = useState<MultiValue<SelectOption>>([]);

    const setValue = (newValues: MultiValue<SelectOption>) => {
      setInnerValue(newValues);
      valueRef.current = newValues;
    };

    const records = useMemo(() => {
      return locations?.reduce<{ [key: string]: SelectOption }>(
        (previousValue, currentValue) => {
          if (!previousValue[currentValue.id]) {
            previousValue[currentValue.id] = currentValue;
          }
          return previousValue;
        },
        {}
      );
    }, [locations]);

    const selectedData = useMemo(() => {
      return value
        ?.map((it) => {
          if (typeof it === 'object') {
            return it;
          }
          return records[it];
        })
        .filter(Boolean);
    }, [records, value]);

    const onMenuOpen = () => {
      console.log('ON MENU OPEN SELECTED DATA : ', selectedData);
      setValue(selectedData);
    };

    const handleSelectChange = (newValue: any) => {
      let _newValue = isMulti ? newValue : [newValue];
      setInnerValue(_newValue);
      if (onChange) {
        onChange(_newValue.map((val: any) => val.id));
      }
    };

    const getPlaceholderText = () => {
      if (!selectedData || selectedData.length === 0) {
        return <Text>{t('form:select_location')}</Text>;
      }

      if (selectedData.length === 1) {
        return (
          <Tooltip label={selectedData[0].label} hasArrow>
            <Text isTruncated maxW='300px' color='black' fontWeight={600}>
              {selectedData[0].label}
            </Text>
          </Tooltip>
        );
      }

      if (selectedData.length === 2) {
        return (
          <Tooltip
            label={selectedData?.map((data) => data?.label)?.join(', ')}
            hasArrow
          >
            <Flex fontWeight={600}>
              <Text
                isTruncated
                maxW='150px'
                color='black'
              >{`${selectedData[0].label}`}</Text>
              <Text isTruncated maxW='150px' color='black'>
                , {`${selectedData[1].label}`}
              </Text>
            </Flex>
          </Tooltip>
        );
      }

      return (
        <Tooltip
          label={selectedData?.map((data) => data?.label)?.join(', ')}
          hasArrow
        >
          <Flex fontWeight={600}>
            <Text isTruncated maxW='100px' color='black'>
              {`${selectedData[0].label}`}
            </Text>
            <Text isTruncated maxW='100px' color='black'>
              , {`${selectedData[1].label}`}
            </Text>
            <Text isTruncated maxW='100px' color='black'>
              &nbsp;{`and ${selectedData.length - 2} more`}
            </Text>
          </Flex>
        </Tooltip>
      );
    };

    return (
      <div>
        <Select<SelectOption, true>
          ref={ref}
          placeholder={getPlaceholderText()}
          chakraStyles={getChakraSelectStyles<SelectOption, true>({
            menuList: {
              padding: '12px',
            },
          })}
          onMenuOpen={onMenuOpen}
          options={locations}
          value={isMulti ? innerValue : innerValue[0]}
          isMulti={isMulti || undefined}
          onChange={handleSelectChange}
          styles={{
            option: (base, optionProps) => ({
              ...base,
              borderBottom: '1px solid #efefef',
              paddingLeft: '4px',
              borderRadius: optionProps?.isFocused ? 8 : 0,
              ':last-child': {
                borderBottom: 'none',
              },
            }),
          }}
          getOptionValue={(option) => option?.id}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          closeMenuOnSelect={false}
          components={{
            Option: (props) => (
              <Option {...props} selectedData={selectedData} />
            ),
          }}
        />
      </div>
    );
  }
);

LocationSelect.displayName = 'Profile/TC/IC/Location Select';

export default LocationSelect;

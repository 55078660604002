import React, { FC, useState } from 'react';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { IFormResponseItemsEntity } from 'pages/forms/forms-types';
import { useUserDataSelector } from '../../../../../hooks';
import { AmplitudeEvent, deployEvent } from '../../../../../shared';
import { IconImage } from '../../../../../ui-components';

import FormResponseModal from '../FormResponseModal';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';

interface IProps {
  formId: string;
  userDetails: IFormResponseItemsEntity;
  preventClick?: boolean;
}

const UserDetailsComponent: FC<IProps> = ({
  formId,
  userDetails,
  preventClick,
}) => {
  const userEid = useUserDataSelector((state) => state.eid);
  const [showModal, setShowModal] = useState(false);
  const [showChat, setShowChat] = useState(false);

  const createdBy = userDetails?.createdBy;

  const userName = createdBy?.eid === userEid ? 'You' : createdBy?.name;

  const onUserClick = () => {
    if (!preventClick) {
      deployEvent(AmplitudeEvent.FORM_RESPONSE_USER_SPECIF_RESPONSE);
      setShowModal(true);
    }
  };

  return (
    <>
      <Flex
        align='center'
        columnGap='5px'
        bg='#e0f5fe'
        p='5px 10px'
        borderRadius='7px'
        width='fit-content'
      >
        <IconImage
          name={createdBy?.name}
          thumbnail={createdBy?.profilePic}
          boxSize={25}
          borderRadius='7px'
        />
        <Tooltip
          label={
            createdBy?.role
              ? createdBy?.name + ', ' + createdBy?.role
              : createdBy?.name
          }
          placement='bottom'
        >
          <Flex align='center'>
            <Text
              as='b'
              onClick={onUserClick}
              cursor='pointer'
              color='#2A85FF'
              isTruncated
              maxWidth={'70px'}
            >
              {userName}
            </Text>
            {createdBy?.role && (
              <>
                <Text>,&nbsp;</Text>
                <Text isTruncated maxWidth={'60px'}>
                  {createdBy?.role}
                </Text>
              </>
            )}
          </Flex>
        </Tooltip>
      </Flex>
      {showModal && (
        <FormResponseModal
          isOpen={showModal}
          formId={formId}
          onClose={() => setShowModal(false)}
          details={userDetails}
          setShowChat={setShowChat}
        />
      )}
      {showChat && (
        <SingleUserChatModal
          selectedUser={createdBy?.eid}
          onClose={() => {
            setShowChat(false);
            setShowModal(true);
          }}
          usePortal
        />
      )}
    </>
  );
};

export default UserDetailsComponent;

import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';

import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../ui-components';
import TaskForm, { TaskFormProps } from './TaskForm';

interface IProps extends TaskFormProps {}

type LauncherTaskForm = (props: IProps) => void;

export const useLauncherTaskForm = (): LauncherTaskForm => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ index, initialValue, onUpdate }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex align='center'>
          <Box flex={1}>
            <BoxHeader
              fontSize='18px'
              title={initialValue?.title?.trim() ? 'Update task' : 'Add task'}
              color='#CABDFF'
            />
          </Box>
          <Flex justify='flex-end' gap='8px'>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        </Flex>
      ),

      content: (
        <TaskForm
          index={index}
          initialValue={initialValue}
          onUpdate={onUpdate}
          closeForm={() => confirmRef.current?.destroy()}
        />
      ),

      size: 'full',
      contentProps: {
        padding: '12px',
        borderRadius: 0,
        bg: '#FFFFFF',
        containerProps: {
          maxW: '675px',
          left: 'unset',
          right: 0,
        },
      },
      footer: null,
    });
  }, []);
};

import React, { FC, ReactNode, useMemo } from 'react';
import { Flex, IconButton, Link } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { truncateString } from 'utils';
import { toArray } from 'utils/utils';
import { FILENAME_REGEX } from 'utils/constants';

interface FileNameProps {
  fileName?: string;
  value?: string[];
  icon?: ReactNode;
  isDisabled?: boolean;
  onRemove?: () => void;
}

export const FileName: FC<FileNameProps> = ({
  value,
  fileName,
  icon,
  isDisabled,
  onRemove,
}) => {
  const _value = toArray(value);
  return (
    <Flex
      width='max-content'
      borderRadius='12px'
      h='48px'
      fontWeight='400'
      gap='8px'
      border='1px solid'
      borderColor='gray.200'
      align='center'
      px={3}
    >
      {icon}
      <Link target='_blank' href={_value[0]} title={fileName}>
        {truncateString(fileName, isDisabled ? 20 : 16)}
      </Link>
      {!isDisabled && (
        <IconButton
          ml={1}
          size='xs'
          aria-label={'remove'}
          icon={<CloseIcon />}
          onClick={() => onRemove?.()}
        />
      )}
    </Flex>
  );
};

export const useFileName = (value?: string[]): string | undefined => {
  const _value = toArray(value);
  return useMemo(() => {
    if (_value?.[0]) {
      return _value[0]?.split(FILENAME_REGEX).pop();
    }
    return undefined;
  }, [_value]);
};

import React, { CSSProperties, FC, useMemo } from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { IconImage } from '../../../../ui-components';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface IProps {
  name: string;
  profilePic?: string;
  email?: string;
  authRole?: string;
  status?: string;
  onClick?: () => void;
}

const MemberName: FC<IProps> = ({
  name,
  profilePic,
  email,
  authRole,
  onClick,
}) => {
  const calcAuthRole = useMemo(() => {
    return authRole?.split(/(?=[A-Z])/).join(' ');
  }, [authRole]);

  return (
    <Flex
      pos='relative'
      maxWidth='100%'
      width='max-content'
      pr='16px'
      gap='12px'
      cursor='pointer'
      onClick={onClick}
    >
      <Box>
        <IconImage
          name={name}
          thumbnail={profilePic}
          boxSize={60}
          borderRadius='5px'
          objectFit='cover'
        />
      </Box>
      <Flex direction='column' overflow='hidden'>
        <Tooltip label={name} hasArrow borderRadius='4px'>
          <Box isTruncated maxW='150px' fontSize='15px' fontWeight='600'>
            {name}
          </Box>
        </Tooltip>
        <Box
          fontSize='13px'
          isTruncated
          maxW='150px'
          fontWeight='600'
          style={wrapStyles}
          color='#9A9FA5'
        >
          {email}
        </Box>
        {authRole && (
          <Box
            textTransform='capitalize'
            fontSize='13px'
            fontWeight='600'
            style={wrapStyles}
            color='#6F767E'
          >
            {calcAuthRole}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default MemberName;

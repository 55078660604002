// import Editor from 'delightree-editor';
import axios from 'axios';
import { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';

interface props {
  editorValue: any;
  onChangeValue: any;
  businessId?: any;
}

const GET_SIGNED_URL = gql`
  mutation GetSignedUrlMutation($getSignedUrlInput: [FileDetailsInput]) {
    getSignedUrl(input: $getSignedUrlInput) {
      signedRequestUrl
      url
      mimetype
      s3_filename
      filename
    }
  }
`;

const EditorElement = ({ editorValue, onChangeValue, businessId }: props) => {
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);

  useEffect(() => {
    window.addEventListener('message', handler);
  });

  const handler = (event) => {
    let contentData = event?.data;
    if (contentData) {
      try {
        // setEventData(JSON.parse(event?.data));
      } catch (e) {
        console.error('Json Data', contentData);
      }
    }
  };
  const imageCallback = async (files, setImage) => {
    if (files && files.length) {
      let file = files[0];
      const { name, type } = file;
      const response = await getSignedUrl({
        variables: {
          getSignedUrlInput: [{ filename: name, mimetype: type }],
        },
      });
      if (response) {
        if (
          response &&
          response.data &&
          response.data.getSignedUrl &&
          response.data.getSignedUrl.length
        ) {
          const data = response.data.getSignedUrl[0];
          const signedRequestUrl = data.signedRequestUrl;
          const imageUrl = data.url;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          await (reader.onload = (event: any) => {
            let binaryString = event?.target!.result;
            let binary = atob((binaryString as string)!.split(',')[1]);
            let array = [];
            for (var j = 0; j < binary.length; j++) {
              array.push(binary.charCodeAt(j));
            }
            let blobData = new Blob([new Uint8Array(array)], {
              type: file?.type,
            });

            axios
              .put(signedRequestUrl, blobData)
              .then(() => {
                setImage(imageUrl);
              })
              .catch(function (error: any) {
                console.error(error);
              });
          });
        }
      }
    }
  };

  const bulkImageCallback = async (files, setBulkImages) => {
    if (files && files.length) {
      let bulkFileList = [];
      let fileList = [];
      let imageUrlList = [];
      if (files?.length) {
        Array.from(files).forEach((file: any) => {
          const { name, type } = file;
          bulkFileList.push({
            filename: name,
            mimetype: type,
          });
          fileList.push(file);
        });
      }

      const response = await getSignedUrl({
        variables: {
          getSignedUrlInput: bulkFileList,
        },
      });
      if (response) {
        if (
          response &&
          response.data &&
          response.data.getSignedUrl &&
          response.data.getSignedUrl.length
        ) {
          if (response?.data?.getSignedUrl?.length) {
            const ops = [];
            const signedUrlList = response?.data?.getSignedUrl;

            for (let i = 0; i <= signedUrlList.length - 1; i++) {
              const data = signedUrlList[i];
              const file = fileList[i];
              const signedRequestUrl = data.signedRequestUrl;
              const imageUrl = data.url;

              const reader = new FileReader();
              reader.readAsDataURL(file);
              await (reader.onload = (event: any) => {
                let binaryString = event?.target!.result;
                let binary = atob((binaryString as string)!.split(',')[1]);
                let array = [];
                for (var j = 0; j < binary.length; j++) {
                  array.push(binary.charCodeAt(j));
                }
                let blobData = new Blob([new Uint8Array(array)], {
                  type: file?.type,
                });
                axios
                  .put(signedRequestUrl, blobData)
                  .then((data) => {
                    console.log('Image Upload successful', data);
                    imageUrlList.push(imageUrl);

                    if (imageUrlList.length === signedUrlList?.length) {
                      setBulkImages(imageUrlList);
                    }
                  })
                  .catch(function (error: any) {
                    console.error(error);
                  });
              });
            }
            await axios.all(ops);
          }
        }
      }
    }
  };

  return (
    <div style={{ fontSize: '16px' }}>
      {/* <Editor
        readonly={false}
        onChange={onChangeValue}
        value={editorValue}
        imageCallback={imageCallback}
        businessId={businessId}
        bulkImageCallback={bulkImageCallback}
      /> */}
    </div>
  );
};

export default EditorElement;

import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import SearchItemImage from './SearchItemImage';
import StatusIcon from './StatusIcon';
import SearchTypeTitle from './SearchTypeTitle';
import SearchTitle from './SearchTitle';
import { BaseSearchModal } from 'modal';
import ModifiedDate from './ModifiedDate';

interface IProps {
  item: BaseSearchModal;
  onClick?: () => void;
}

const SearchItemContent: FC<IProps> = ({ item, onClick }) => {
  return (
    <Flex
      onClick={onClick}
      gap={4}
      cursor='pointer'
      flex='1 1 auto'
      align='center'
    >
      <Box>
        <Box w='50px' pos='relative'>
          <SearchItemImage item={item} type={item.type} />
          <StatusIcon type={item.type} />
        </Box>
      </Box>

      <Box>
        <Box w='max-content'>
          <div className='search-item-type-title'>
            <SearchTypeTitle item={item} type={item.type} />
          </div>
        </Box>
        <Flex align='baseline' gap='3px'>
          <Box className='search-item-title' noOfLines={1}>
            <SearchTitle item={item} type={item.type} />
          </Box>
          <ModifiedDate lastUpdated={item.meta?.lastUpdated} type={item.type} />
        </Flex>
      </Box>
    </Flex>
  );
};

export default SearchItemContent;

SearchItemContent.displayName = 'UiComponent/Search/SearchItemContent';

import React, { FC } from 'react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
} from '@chakra-ui/react';

import { QuestionType } from 'types';

import FormInput from '../../../../atoms/FormInput';

import { Question } from '../../create-form/form.types';
import { FormPreviewInput } from '../form-preview.types';
import { ReactComponent as CameraIcon } from '../../../../assets/images/camera.svg';
import CameraTooltip from './CameraTooltip';

interface IProps {
  qType: Question['qType'];
  data: Question['options'][number];
  field: ControllerRenderProps<any, any>;
  currentIndex: number;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  hasImageNote?: boolean;
}

const OtherOptionInput: FC<IProps> = ({
  qType,
  field,
  data,
  currentIndex,
  isReadOnly,
  isDisabled,
  hasImageNote,
}) => {
  const { t } = useTranslation('form');
  const { control } = useFormContext<FormPreviewInput>();

  const onChange = (
    _data: Question['options'][number],
    _field: ControllerRenderProps
  ) => {
    if (qType === QuestionType.MULTI_CHOICE) {
      _field.onChange([_data.eid || _data.label]);
    } else {
      let newValue: string[] = _field.value || [];
      if (_data?.eid || _data?.label) {
        if (newValue.includes(_data.eid || _data.label)) {
          newValue = newValue.filter((id) => id !== (_data.eid || _data.label));
        } else {
          newValue.push(_data.eid || _data.label);
        }
      }
      _field.onChange(newValue);
    }
  };

  const onInputChange = (event: any, callback: (...args: any) => void) => {
    callback(event);
    if (!field.value?.includes(data.eid || data.label)) {
      onChange(data, field);
    } else if (!event.target.value) {
      onChange(data, field);
    }
  };

  const Component = qType === QuestionType.MULTI_CHOICE ? Radio : Checkbox;

  if (data.isArchieved) {
    return null;
  }

  const isChecked = field.value?.includes(data.eid || data.label);

  const showCamera =
    qType === QuestionType.CHECKBOX && hasImageNote && isChecked;

  return (
    <Flex align='center' gap={1.5}>
      <Controller
        name={`response.${currentIndex}.otherResponse`}
        control={control}
        defaultValue=''
        rules={{
          required: {
            value: isChecked,
            message: t('validation.field_required'),
          },
        }}
        render={({ field: otherField, fieldState }) => {
          return (
            <FormControl
              isInvalid={!!fieldState.error}
              isReadOnly={isReadOnly}
              isDisabled={isDisabled}
            >
              <Flex gap={2}>
                <Component
                  {...field}
                  isChecked={isChecked}
                  value=''
                  onChange={() => onChange(data, field)}
                />
                <FormInput
                  placeholder={t('other')}
                  {...otherField}
                  onChange={(e) => onInputChange(e, otherField.onChange)}
                />
              </Flex>
              <FormErrorMessage pl={6}>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      {showCamera && (
        <CameraTooltip>
          <CameraIcon
            style={{ minWidth: 24, width: 24, height: 24, cursor: 'pointer' }}
          />
        </CameraTooltip>
      )}
    </Flex>
  );
};

export default OtherOptionInput;

import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
} from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  progressRate: number;
  color?: string;
  size?: string;
  icon: React.ReactNode;
  thickness?: string;
  showIndicator?: boolean;
}

const CircularProgressIcon: FC<IProps> = ({
  progressRate,
  color = '#0062FF',
  icon,
  size = '100px',
  thickness = '4px',
  showIndicator = false,
}) => {
  const indicatorSize = parseFloat(size) * 0.15 + 'px';
  const indicatorPosition = parseFloat(size) * 0.13 + 'px';
  return (
    <Flex id='c-p-i-div' position='relative'>
      {showIndicator && (
        <Box
          id='c-p-i-indicator'
          position='absolute'
          boxSize={indicatorSize}
          borderRadius='50%'
          bg='#FF6A55'
          top={indicatorPosition}
          right={indicatorPosition}
          zIndex='1'
          border='2px solid white'
        />
      )}
      <CircularProgress
        value={progressRate}
        color={color}
        size={size}
        thickness={thickness}
      >
        <CircularProgressLabel>{icon}</CircularProgressLabel>
      </CircularProgress>
    </Flex>
  );
};

export default CircularProgressIcon;

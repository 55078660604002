import { useState, useEffect, FC } from 'react';
import { Box, Flex, List, Text } from '@chakra-ui/react';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';

import './RecentSearchList.scss';
import RecentSearchListItem from './RecentSearchListItem';
import Loader from '../Loader';
import startSearchIcon from '../../assets/images/start-search.svg';
import { RecentSearchDataList, RecentSearchModal } from 'modal';
import { cloneDeep } from '@apollo/client/utilities';
import { RecentSearchData } from '../SearchModal/search-modal.types';
import { PageInfo } from '../../types';
import { CategoryEntity } from 'sop-commons/src/client';
import { GET_FOLDERS_LIST } from 'pages/Chapters/chapters.graphql';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { mapSearchDataToFolderList } from 'sub-components/SearchModal/helper';
import useStore from 'zustandStore/store';
import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';

export interface RecentSearchResponse {
  RecentSearch: {
    count: number;
    pageInfo: PageInfo;

    items: Array<RecentSearchData>;
  };
}

const GET_RECENT_RESULTS = gql`
  query RecentSearch(
    $page: Int
    $perPage: Int
    $filter: FilterFindManySearchInput
    $sort: SortFindManySearchInput
  ) {
    RecentSearch(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      items {
        viewedResult
        path
        type
        eid
        meta
        recordId
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const REMOVE_RECENT_SEARCH = gql`
  mutation RemoveRecentSearch($eid: String!) {
    RemoveRecentSearch(eid: $eid) {
      succeed
    }
  }
`;

interface IProps {
  onItemClick: (item: any) => void;
}

const RecentSearchList: FC<IProps> = ({ onItemClick }: any) => {
  const { t } = useTranslation('header');
  const { foldersList, updateFoldersList } = useStore();
  const [noResult, setNoResult] = useState(false);
  let categoriesList = useReactiveVar(foldersListVar);
  const [recentSearchList, setRecentSearchList] = useState<RecentSearchModal[]>(
    []
  );

  const { loading: foldersListLoading } = useQuery<
    { EntityCategories: CategoryEntity[] },
    never
  >(GET_FOLDERS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      foldersListVar(data?.EntityCategories || []);
      updateFoldersList(data?.EntityCategories || []);
    },
  });

  const { data, loading, refetch } = useQuery<RecentSearchResponse>(
    GET_RECENT_RESULTS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        page: null,
        perPage: null,
        filter: null,
        sort: '_ID_DESC',
      },
      onCompleted: (response) => {
        setNoResult(response?.RecentSearch?.count <= 0);
      },
    }
  );

  const [removeRecentSearch, { loading: removeLoader }] = useMutation(
    REMOVE_RECENT_SEARCH,
    {
      onCompleted: async ({ data }) => {
        await refetch();
      },
      onError: () => {},
    }
  );

  const onRemoveRecentSearchClick = (eid: any) => {
    return removeRecentSearch({
      variables: {
        eid: eid,
      },
    });
  };

  useEffect(() => {
    if (data) {
      let _searchItems: RecentSearchData[] = JSON.parse(
        JSON.stringify(data.RecentSearch.items)
      );
      _searchItems.forEach((item) => {
        if (
          item?.type === 'category' &&
          item?.meta &&
          !item?.meta?.categoryId
        ) {
          item.meta.categoryId = item.recordId;
        }
      });
      if (
        categoriesList &&
        categoriesList?.length > 0 &&
        data?.RecentSearch?.items?.length > 0
      ) {
        // const updatedSearchData = mapSearchDataToFolderList(
        //   _searchItems,
        //   categoriesList
        // );
        // let _categoriesList = cloneDeep(categoriesList);
        // _searchItems?.forEach((item) => {
        //   let foundCategory: any;
        //   _categoriesList?.forEach((cat) => {
        //     if (cat?.children?.length > 0) {
        //       cat?.children?.forEach((ch) => {
        //         if (ch?.eid === item?.meta?.categoryId) {
        //           foundCategory = ch;
        //           item.meta.parentId = cat?.eid;
        //           item.meta.parentName = cat?.name;
        //         }
        //       });
        //     } else {
        //       if (cat?.eid === item?.meta?.categoryId) {
        //         item.meta.parentId = cat?.eid;
        //         item.meta.parentName = cat?.name;
        //       }
        //     }
        //   });
        // });
        let allFoldersList: CustomCategoryEntity[] = [];
        categoriesList?.map((list) => {
          if (list?.children?.length > 0) {
            list?.children?.map((child) => {
              allFoldersList?.push({
                ...child,
                parentFolderId: list?.eid,
                parentFolderName: list?.name,
                type: 'subFolder',
              });
            });
            allFoldersList?.push({
              ...list,
              parentFolderId: undefined,
              parentFolderName: undefined,
              type: 'folder',
            });
          } else {
            allFoldersList?.push({
              ...list,
              parentFolderId: undefined,
              parentFolderName: undefined,
              type: 'folder',
            });
          }
        });
        _searchItems?.map((item) => {
          let flag = allFoldersList?.filter(
            (folder) => folder?.eid === item?.meta?.categoryId
          )?.[0];
          if (flag && flag?.parentFolderId) {
            item.meta.parentId = flag?.parentFolderId;
            item.meta.parentName = flag?.parentFolderName;
          }
        });
        setRecentSearchList(new RecentSearchDataList(_searchItems).data);
      }
      // setRecentSearchList(new RecentSearchDataList(_searchItems).data);
    }
  }, [categoriesList, data]);

  if (loading || removeLoader || foldersListLoading) {
    return (
      <div>
        <Text className='recent-search'>{t('recent_search')}</Text>
        <div className='sop-list-loader'>
          <Loader size='xl' />
        </div>
      </div>
    );
  }

  if (noResult) {
    return (
      <div>
        <Flex className='sop-list-loader' flexDir='column'>
          <img src={startSearchIcon} alt='' />
          <Box fontSize='18px' fontWeight='700' lineHeight='27px'>
            {t('noRecentResult')}
          </Box>
        </Flex>
      </div>
    );
  }

  return (
    <div>
      <Text className='recent-search' height={'unset'}>
        {t('recent_search')}
      </Text>
      <List>
        {recentSearchList?.map((item) => {
          return (
            <RecentSearchListItem
              item={item}
              key={item.eid}
              onClick={onItemClick}
              onRemoveRecentSearchClick={onRemoveRecentSearchClick}
            />
          );
        })}
      </List>
    </div>
  );
};

export default RecentSearchList;
RecentSearchList.displayName = 'SubComponent/RecentSearchList';

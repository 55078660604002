import { FC, useState } from "react";
import { Button } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Text from "../elements/Text";
import { ReactComponent as ActionCardImage } from "../assets/actionCardIcon.svg";
import { ADD_CARD_RESPONSE } from "../../../Mutation/Card";

interface IProps {
  content: any;
  backgroundColor?: string;
  isPreview?: boolean;
  cardId?: string;
  title?: string;
  subTitle?: string;
  shouldMoveToNextCard?: () => void;
  shouldSetFixedHeight?: boolean;
}

const ActionType: FC<IProps> = ({
  content,
  backgroundColor,
  isPreview,
  cardId,
  title,
  subTitle,
  shouldMoveToNextCard,
  shouldSetFixedHeight,
}) => {
  const [addCardResponse] = useMutation(ADD_CARD_RESPONSE);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onComplete = () => {
    setIsLoading(true);
    if (!isPreview) {
      addCardResponse({
        variables: {
          input: {
            cardType: "action",
            cardId: cardId,
            label: title,
            completed: true,
          },
        },
      }).then((response) => {
        setIsLoading(false);
        shouldMoveToNextCard?.();
        console.log("On response Successful", response);
      });
    } else {
      setIsLoading(false);
      shouldMoveToNextCard?.();
    }
  };
  return (
    <div
      className="action-type-card-container"
      style={{ minHeight: shouldSetFixedHeight ? "80vh" : "none" }}
    >
      <div
        style={{
          backgroundColor: backgroundColor,
        }}
        className="action-type-icon-background-container "
      >
        <ActionCardImage />
      </div>
      <div className="action-type-content-container">
        <div className="action-type-content-title-container">
          <Text textContent={content[0]} />
        </div>
        <div className="action-type-content-button-container">
          <Button
            width="full"
            size="lg"
            style={{
              borderRadius: "12px",
              fontSize: 15,
            }}
            variant="solid"
            colorScheme="blue"
            onClick={onComplete}
            isLoading={isLoading}
          >
            {/* @ts-ignore */}
            {subTitle || ""}
            {/* {t("done", {
              defaultValue: "Done",
            })} */}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ActionType;

ActionType.displayName = "ActionType";

import { toArray } from '../../../utils/utils';
import { SopType } from '../../../types';

export const trainingItemToPlayList = (trainingItems: any[]) => {
  return toArray(trainingItems)
    .map((value) => {
      if (['document', 'video'].includes(value?.sopType as SopType)) {
        return [value];
      }
      if ('smartPageEnabled' in value || 'isMultiPage' in value) {
        if (value.smartPageEnabled || value.isMultiPage) {
          return toArray(value.cards).map((card, index, arr) => {
            return {
              ...card,
              isLastCard: index + 1 === arr.length,
              sopId: value.eid,
              subType: value.type,
              files: value.files || [],
              media: value.media || [],
            };
          });
        }
        return toArray(value.cardView).map((card, index, arr) => {
          return {
            ...card,
            isLastCard: index + 1 === arr.length,
            sopId: value.eid,
            subType: value.type,
            files: value.files || [],
            media: value.media || [],
          };
        });
      }
      return value;
    })
    .flat()
    .concat({
      type: 'finish',
    });
};

import { createContext, FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useReactiveVar } from '@apollo/client';
import { SelectOption } from 'atoms/Dropdown';
import { userObj } from 'sop-commons/src/client';
import { toArray } from '../../../../../utils/utils';
import { getLocations, LocationResponse } from './member-filter.graphql';

export type IMoreAction = 'or' | 'and';

interface IContext {
  allOptions: SelectOption[];
  dataObject: Record<string, SelectOption[]>;
  operation?: IMoreAction | null;
  setOperation: (value?: IMoreAction) => void;
  filterBy?: SelectOption | null;
  setFilterBy: (value: SelectOption) => void;
  filterValue?: SelectOption | null;
  setFilterValue: (value: SelectOption) => void;
  otherFilterBy?: SelectOption | null;
  setOtherFilterBy: (value: SelectOption) => void;
  otherFilterValue?: SelectOption | null;
  setOtherFilterValue: (value: SelectOption) => void;
}

export const MemberContext = createContext<IContext>({
  allOptions: [],
  dataObject: {},
  setOperation: () => null,
  // firstFilterBy: '',
  setFilterBy: () => null,
  // firstFilter: '',
  setFilterValue: () => null,
  // secondFilterBy: '',
  setOtherFilterBy: () => null,
  // secondFilter: '',
  setOtherFilterValue: () => null,
});

export interface IFilter {
  initialValue?: {
    filterBy?: SelectOption;
    filterValue?: SelectOption;
    operation?: IMoreAction;
    otherFilterBy?: SelectOption;
    otherFilterValue?: SelectOption;
  };
}

export const FilterProvider: FC<IFilter> = ({ children, initialValue }) => {
  const { t, i18n } = useTranslation(['common', 'role']);
  const userObject = useReactiveVar(userObj);

  const [moreAction, setMoreAction] = useState<IMoreAction | undefined>(
    initialValue?.operation
  );
  const [firstFilterBy, setFirstFilterBy] = useState<
    SelectOption | null | undefined
  >(initialValue?.filterBy);
  const [firstFilter, setFirstFilter] = useState<
    SelectOption | null | undefined
  >(initialValue?.filterValue);
  const [secondFilterBy, setSecondFilterBy] = useState<
    SelectOption | null | undefined
  >(initialValue?.otherFilterBy);
  const [secondFilter, setSecondFilter] = useState<
    SelectOption | null | undefined
  >(initialValue?.otherFilterValue);

  const allOptions = useMemo(() => {
    return [
      { label: t('common:location'), value: 'location' },
      { label: t('role:job'), value: 'role' },
    ];
  }, [i18n.language]);

  const { data } = useQuery<LocationResponse>(getLocations, {
    fetchPolicy: 'network-only',
  });

  const locationList = useMemo(() => {
    return toArray(data?.branch?.descendantBranches).map((it) => {
      return {
        label: it.name,
        value: it.name,
      };
    });
  }, [data?.branch?.descendantBranches]);

  const roleList = useMemo(() => {
    return toArray(userObject?.entity?.roles).map((it) => {
      return {
        label: it.name,
        value: it.name,
      };
    });
  }, [userObject?.entity?.roles]);

  const dataObject = useMemo(() => {
    return {
      role: roleList,
      location: locationList,
    };
  }, [roleList, locationList]);

  const onFirstFilterByChange = (value: SelectOption) => {
    if (value?.value === secondFilterBy?.value) {
      setSecondFilterBy(null);
      setSecondFilter(null);
    }
    setFirstFilterBy(value);
  };

  return (
    <MemberContext.Provider
      value={{
        allOptions,
        dataObject,
        operation: moreAction,
        setOperation: (value) => setMoreAction(value),
        filterBy: firstFilterBy,
        setFilterBy: onFirstFilterByChange,
        filterValue: firstFilter,
        setFilterValue: (value) => setFirstFilter(value),
        otherFilterBy: secondFilterBy,
        setOtherFilterBy: (value) => setSecondFilterBy(value),
        otherFilterValue: secondFilter,
        setOtherFilterValue: (value) => setSecondFilter(value),
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

FilterProvider.defaultProps = {
  initialValue: {},
};

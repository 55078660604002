import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import { FC } from 'react';
import { IRefetchType } from '../task-detail-drawer.types';
import { BodyViewMode, BodyEditMode, BodySubmitMode } from './index';

export type IModeType = 'view' | 'edit' | 'submit';

interface IProps {
  mode: IModeType;
  selectedTaskData: CustomLauncherTask;
  onClose: () => void;
  refetchHandler: (type: IRefetchType) => void;
}

const BodyComponent: FC<IProps> = ({
  mode,
  selectedTaskData,
  onClose,
  refetchHandler,
}) => {
  switch (mode) {
    case 'view':
      return (
        <BodyViewMode
          selectedTaskData={selectedTaskData}
          onClose={onClose}
          refetchHandler={refetchHandler}
        />
      );
    case 'edit':
      return <BodyEditMode />;
    case 'submit':
      return (
        <BodySubmitMode
          selectedTaskData={selectedTaskData}
          onClose={onClose}
          refetchHandler={refetchHandler}
        />
      );
    default:
      return (
        <BodyViewMode
          selectedTaskData={selectedTaskData}
          onClose={onClose}
          refetchHandler={refetchHandler}
        />
      );
  }
};

export default BodyComponent;

import React, { FC } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons';

import icon from '../../../assets/images/delightreeIcon.svg';
import { useSearchParam } from '../../../hooks';
import ViewTypeSelect, { ViewMode, ViewTypeProps } from './ViewTypeSelect';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { useFormContext } from 'react-hook-form';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';

export type { ViewMode };

interface IProps extends ViewTypeProps {
  smartPage?: boolean;
  updateSmartPage?: (value: boolean) => void;
  onClose?: () => void;
}

const PreviewHeader: FC<IProps> = ({
  viewMode,
  smartPage,
  updateSmartPage,
  onModeChange,
  onClose,
}) => {
  const { t } = useTranslation(['chapter']);
  const { getValues, setValue, watch } = useFormContext<IFormInput>();
  const searchParam = useSearchParam<{ parent?: string }>();

  const switchValue = watch('smartPage');

  const onBackClick = async () => {
    if (searchParam?.parent) {
      window.open('', searchParam.parent);
    }
    window.close();
  };

  return (
    <Flex
      id='preview-header-flex-1'
      flexDirection='column'
      w='full'
      h='80px'
      bg='white'
    >
      {/* <Box flex={1}>
        <Image height='40px' width='46px' src={icon} />
      </Box> */}
      <Flex justify={'space-between'} align='center'>
        <GenericColorHeader title='Preview' color='#CABDFF' />
        <Box mr={'5rem'}>
          <ViewTypeSelect viewMode={viewMode} onModeChange={onModeChange} />
        </Box>
        <CloseIcon
          onClick={onClose}
          color='#6F767E'
          cursor='pointer'
          bg='#EFEFEF'
          borderRadius='50%'
          boxSize='30px'
          p={'7px'}
        />
      </Flex>
      <Flex
        flex={1}
        flexDirection='column'
        alignItems='center'
        justify='center'
        gap={4}
        mt={6}
      >
        <Flex gap='10px' align='center'>
          <Text fontWeight='500' fontSize='12px' color='#6F767E'>
            Smart Pages
          </Text>
          <Switch
            isChecked={switchValue}
            onChange={(e) => {
              deployEvent(AmplitudeEventNames.SMART_PAGES_TOGGLE);
              setValue('smartPage', e.target.checked);
              // updateSmartPage(e.target.checked);
            }}
          />
        </Flex>
      </Flex>
      {/* <Flex flex={1} justify='flex-end' gap='8px'>
        {['mobile', 'tablet'].includes(viewMode) && (
          <Center
            h={12}
            borderRadius='7px'
            border='1px solid #DDDDDD'
            px='16px'
            gap='12px'
          >
            <Box fontSize='14px' fontWeight='600' color='#6F767E'>
              {t('chapter:smartPages')}
            </Box>
            <Switch
              isChecked={smartPage}
              onChange={(e) => {
                deployEvent(AmplitudeEventNames.SMART_PAGES_TOGGLE);
                updateSmartPage(e.target.checked);
              }}
            />
          </Center>
        )}
        <Tooltip
          label={t('chapter:itWillCloseTab')}
          hasArrow
          padding='6px 10px'
          borderRadius='4px'
        >
          <Button
            variant='outline'
            borderWidth='2px'
            size='lg'
            fontSize='15px'
            leftIcon={<ArrowBackIcon boxSize={5} />}
            onClick={onBackClick}
          >
            {t('chapter:goBack')}
          </Button>
        </Tooltip>
      </Flex> */}
    </Flex>
  );
};

export default PreviewHeader;

import { gql } from '@apollo/client';

export interface VariableInput {
  input: {
    businessName: string;
    businessType: string;
    locationCount: string;
    phone: string;
    userEmail: string;
    userName: string;
  };
}

export interface Response {
  CreateDemoBusiness: {
    accessToken: string;
  };
}

export const CreateDemo = gql`
  mutation CreateDemoBusiness($input: CreateDemoTypeInput) {
    CreateDemoBusiness(input: $input) {
      accessToken
    }
  }
`;

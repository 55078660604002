import Axios, { AxiosResponse } from 'axios';
import { DEFAULT_CHUNK_SIZE } from './constants';

export type UploadPart = {
  ETag: string;
  PartNumber: number;
};

export const uploadMultipart = (
  file: File,
  urls: string[],
  FILE_CHUNK_SIZE = DEFAULT_CHUNK_SIZE,
  uploadProgress?: (progress: number) => void
): Promise<UploadPart[]> => {
  return new Promise<UploadPart[]>((resolve, reject) => {
    const fr = new FileReader();
    const progress: number[] = [];

    const totalSize = file.size;
    const _uploadProgress = (index: number, loaded: number) => {
      progress[index] = loaded;
      const totalLoaded = progress.reduce((a, v) => a + (v || 0), 0);
      uploadProgress?.(Math.floor((totalLoaded * 100) / totalSize));
    };

    fr.onload = async (readerEvent) => {
      // @ts-ignore
      const fileBuffer = Buffer.from(readerEvent.target.result);
      const axios = Axios.create();
      delete axios.defaults.headers.put['Content-Type'];

      const keys = Object.keys(urls);
      const promises: Promise<AxiosResponse<unknown, unknown>>[] = [];

      for (const indexStr of keys) {
        const index = parseInt(indexStr);
        const start = index * FILE_CHUNK_SIZE;
        const end = (index + 1) * FILE_CHUNK_SIZE;
        const blob =
          index < keys.length
            ? fileBuffer.slice(start, end)
            : fileBuffer.slice(start);
        promises.push(
          axios.put(urls[index], blob, {
            onUploadProgress: (progressEvent) => {
              _uploadProgress(index, progressEvent.loaded);
            },
          })
        );
      }
      const resParts = await Promise.all(promises);
      resolve(
        resParts.map((part, index) => {
          return {
            ETag: part.headers.etag?.substring(
              1,
              part.headers.etag?.length - 1
            ),
            PartNumber: index + 1,
          };
        })
      );
    };
    fr.onerror = reject;
    fr.readAsArrayBuffer(file);
  });
};

import { CardDetailModal } from '../../modal';

export class DeckCardModal {
  protected _data: CardDetailModal[] = [];
  constructor(props: any[] = []) {
    this._data = props.map((value) => new CardDetailModal(value));
  }

  get data() {
    return this._data;
  }

  get length() {
    return this._data.length;
  }
}

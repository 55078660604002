import React, { FC, useContext } from 'react';
import { Flex } from '@chakra-ui/react';
import { PreviewContext } from './context';
import { ThumbnailItem } from './thumbnail';

interface IProps {}

const PreviewThumbnailList: FC<IProps> = () => {
  const { selectedIndex, trainingContents, onThumbnailClick } =
    useContext(PreviewContext);

  return (
    <Flex
      flexDir='column'
      // p='18px 12px'
      // bg='white'
      borderRadius={8}
      gap={2}
      overflow='auto'
      align='center'
      maxH='full'
    >
      {trainingContents?.map((value, index) => {
        return (
          <ThumbnailItem
            id={`training-preview-thumb-${index}`}
            key={index}
            item={value}
            onClick={() => onThumbnailClick?.(index)}
            isSelected={selectedIndex === index}
          />
        );
      })}
    </Flex>
  );
};

export default PreviewThumbnailList;

import React, { FC } from 'react';
import {
  AlertDialogCloseButton,
  Flex,
  IconButton,
  Spacer,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { BoxHeader } from '../../../../ui-components';
import { useDeleteContext } from './context';

interface IProps {}

const DeleteCategoryTitle: FC<IProps> = () => {
  const { t } = useTranslation(['form']);
  const { category, formCount, action, actionHandler } = useDeleteContext();

  if (action === 'move') {
    return (
      <Flex align='center' gap={1}>
        <IconButton
          size='xs'
          aria-label='back'
          icon={<ArrowBackIcon boxSize='20px' />}
          variant='unstyled'
          onClick={() => actionHandler?.('backToMain')}
        />

        <Flex
          fontWeight='600'
          fontSize='20px'
          lineHeight='32px'
          color='#1A1D1F'
        >
          Move forms
        </Flex>

        <Spacer />

        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
    );
  }

  if (
    action === 'deleteConfirm' ||
    action === 'deleteForms' ||
    action === 'formDeletedSuccess' ||
    action === 'exportAndDelete' ||
    action === 'exportFormDeleting'
  ) {
    return (
      <Flex justify='space-between'>
        <BoxHeader
          color='#FFBC99'
          // @ts-ignore
          title={t('form:deleteForm', {
            count: formCount,
          })}
        />

        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
        />
      </Flex>
    );
  }

  return (
    <Flex justify='space-between'>
      <BoxHeader color='#FFBC99' title={`Delete "${category.name}"`} />

      <AlertDialogCloseButton
        pos='relative'
        borderRadius='full'
        top='0'
        right='0'
      />
    </Flex>
  );
};

export default DeleteCategoryTitle;

import React, { ChangeEvent, FC } from 'react';
import { Checkbox, Flex } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { QuestionType } from '../../../../types';
import { IFormInput } from '../form.types';
import CustomCheckBox from './CustomCheckBox';

interface IProps {
  questionType: QuestionType;
  currentIndex: number;
  questionIndex: number;
  isReadOnly?: boolean;
}

const WhiteList: QuestionType[] = [
  QuestionType.CHECKBOX,
  QuestionType.MULTI_CHOICE,
  QuestionType.DROPDOWN,
];

const AnswerSelection: FC<IProps> = ({
  questionType,
  questionIndex,
  currentIndex,
  isReadOnly,
}) => {
  const { control, getValues, setValue } = useFormContext<IFormInput>();

  if (!WhiteList.includes(questionType)) {
    return null;
  }

  const onChange = (val: ChangeEvent, callback: Function) => {
    const prevOptions = getValues(`questions.${questionIndex}.options`) || [];
    switch (questionType) {
      case QuestionType.MULTI_CHOICE:
      case QuestionType.DROPDOWN:
        prevOptions.forEach((_, index) => {
          setValue(
            `questions.${questionIndex}.options.${index}.isCorrect`,
            false
          );
        });
        break;
      default:
        break;
    }
    callback(val);
  };

  return (
    <Flex h={12} align='center'>
      <Controller
        name={`questions.${questionIndex}.options.${currentIndex}.isCorrect`}
        control={control}
        defaultValue={false}
        render={({ field }) => {
          if (questionType !== QuestionType.CHECKBOX) {
            return (
              <CustomCheckBox
                {...field}
                isChecked={field.value}
                value=''
                onChange={(event) => onChange(event, field.onChange)}
                isReadOnly={isReadOnly}
              />
            );
          }

          return (
            <Checkbox
              {...field}
              isChecked={field.value}
              value=''
              onChange={(event) => onChange(event, field.onChange)}
              isReadOnly={isReadOnly}
            />
          );
        }}
      />
    </Flex>
  );
};

export default AnswerSelection;

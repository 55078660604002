import { Text } from '@chakra-ui/react';
import imageProfile from '../../assets/images/person.jpg';

const MostUsedItem = ({ item, setSearchQuery, setSelectedType, onClose }) => {
  return (
    <div>
      <div className='recent-list-item'>
        <img className='image-card' src={imageProfile} />
        <div className='notification-icon'>
          <div className='cards-blank-solid-1'>
            <div className='Vector'></div>
          </div>
        </div>
      </div>

      <Text className='recent-search-item-title'>{item.viewedResult}</Text>
    </div>
  );
};

export default MostUsedItem;

import React, { FC, useMemo, useState } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';

import {
  faCircleBolt,
  faRocketLaunch,
  faScrewdriverWrench,
} from '@fortawesome/pro-light-svg-icons';

import { toArray } from '../../../../utils';

import TemplateTypeButton from './TemplateTypeButton';
import TemplateView from './TemplateView';
import StartFromScratch from './StartFromScratch';
import {
  LAUNCHER_TEMPLATE,
  TemplateResponse,
} from '../board-view/template.graphql';

interface IProps {}

type locationSelectedOptionProps = {
  selectedIndex: number;
};

const setLocationLauncherSelectedOptionToLocationStorage = (
  selectedIndex: locationSelectedOptionProps
) => {
  localStorage.setItem('launcherSelectedOption', JSON.stringify(selectedIndex));
};

const getSelectedLocationOption = () => {
  const selectedLauncherIndex =
    JSON.parse(localStorage.getItem('launcherSelectedOption')) || 0;
  return selectedLauncherIndex;
};

const ContentContainer: FC<IProps> = () => {
  const [selectedIndex, setSelectedIndex] = useState(
    getSelectedLocationOption()
  );

  const { data, loading } = useQuery<TemplateResponse>(LAUNCHER_TEMPLATE, {
    fetchPolicy: 'network-only',
  });

  const { quick, bigLeap } = useMemo(() => {
    const _data = toArray(data?.LauncherTemplatePagination?.items);

    const quick = _data.find((it) => it.name === 'quick-launch');
    const bigLeap = _data.find((it) => it.name === 'big-leap');

    return {
      quick: quick?.contents || [],
      bigLeap: bigLeap?.contents || [],
    };
  }, [data?.LauncherTemplatePagination?.items]);

  return (
    <Tabs
      width='full'
      variant='unstyled'
      orientation='vertical'
      onChange={setSelectedIndex}
      index={selectedIndex}
      gap='24px'
    >
      <TabList gap='18px'>
        <Tab px='0' py='0'>
          <TemplateTypeButton
            title='Quick Launch'
            caption='Your rapid and efficient small business launch checklist'
            icon={faCircleBolt}
            isActive={selectedIndex === 0}
            onClick={() =>
              setLocationLauncherSelectedOptionToLocationStorage(selectedIndex)
            }
          />
        </Tab>
        <Tab px='0' py='0'>
          <TemplateTypeButton
            title='Big Leap'
            caption='Your comprehensive guide to enterprise-level launches.'
            icon={faRocketLaunch}
            isActive={selectedIndex === 1}
            onClick={() =>
              setLocationLauncherSelectedOptionToLocationStorage(selectedIndex)
            }
          />
        </Tab>
        <Tab px='0' py='0'>
          <TemplateTypeButton
            title='Start from scratch'
            caption='Start building your launch task list.'
            icon={faScrewdriverWrench}
            isActive={selectedIndex === 2}
            onClick={() =>
              setLocationLauncherSelectedOptionToLocationStorage(selectedIndex)
            }
          />
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel padding='0 16px'>
          <TemplateView
            title='Quick Launch'
            contents={quick}
            initialising={loading}
          />
        </TabPanel>
        <TabPanel padding='0 16px'>
          <TemplateView
            title='Big Leap'
            contents={bigLeap}
            initialising={loading}
          />
        </TabPanel>
        <TabPanel padding='0 16px'>
          <StartFromScratch />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ContentContainer;

import styled from '@emotion/styled';

export const FormTemplateWrapper = styled.div`
  width: 100%;
  display: flex;
  row-gap: 0.5rem;
  column-gap: 3rem;

  .template-tabs-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    min-width: 250px;
  }

  .templates-list-div {
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 8px;
    //background-color: #FCFCFC;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: -0.15px;
    color: #6f767e;
  }

  .templates-list-div:hover,
  .active {
    background-color: #efefef;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    color: black;
  }

  .template-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    .template-list {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 2rem;
      row-gap: 2rem;
      overflow: auto;
    }
  }

  .template-item {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .template-item img {
    border-radius: 12px;
    min-width: 200px;
    cursor: pointer;
  }

  .template-item-text-container p {
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
  }

  .template-item-text-container span {
    font-size: 14px;
    font-weight: normal;
    color: #9a9fa5;
    cursor: pointer;
  }
`;

import * as yup from 'yup';
import { EndTypeData, IFormInput } from '../task.types';
import { endDateValidation, getMinDate } from './endDateValidation';

// Task end date details
export const endConditionValidate = yup.object().when('taskRunFor', {
  // @ts-ignore
  is: (val: IFormInput['taskRunFor']) => val === 'repeat',
  then: (schema) => {
    return schema.shape({
      endType: yup
        .string()
        .oneOf(EndTypeData, 'Please select correct option')
        .required('Please select when task will end'),

      endDate: yup.date().when(['endType'], {
        // @ts-ignore
        is: (endType: typeof EndTypeData[number]) => endType === 'date',
        then: () =>
          yup
            .date()
            .test('taskDueDiff', async (value, context) => {
              if (context?.parent?.endType === 'date') {
                const values: IFormInput | undefined = context.from?.[1]?.value;

                const response = endDateValidation(value!, values);

                if (response) {
                  const minDate = getMinDate(values);
                  return context.createError({
                    message: `End date must be later than ${minDate.format(
                      'D MMMM yyyy'
                    )}`,
                  });
                }
              }
              return true;
            })
            .required('Please select task end date'), // TODO TEST Required
        otherwise: () => yup.date(),
      }),

      repeatCount: yup.string().when('endCondition', {
        // @ts-ignore
        is: (val = {} as IFormInput['endCondition']) =>
          val.endType === 'repeat',
        then: () =>
          yup
            .string()
            .matches(/^[0-9]+$/, 'Please enter valid number')
            .test(
              'len',
              'Please enter value greater than 1',
              (val) => +val! >= 1
            )
            .required('Please enter repeat count value'),
        otherwise: () => yup.string(),
      }),
    });
  },
  otherwise: () => yup.object(),
});

// This function is for react-hook-form Controller
export const endDateValidate = (
  value: Date,
  values: IFormInput
): string | undefined => {
  if (values.endCondition?.endType === 'date') {
    const response = endDateValidation(value!, values);

    if (response) {
      const minDate = getMinDate(values);
      return `End date must be later than ${minDate.format('D MMMM yyyy')}`;
    }
  }
};

import React from 'react';
import { Alert, AlertTitle, Box, CloseButton } from '@chakra-ui/react';

import { ReactComponent as ArchiveIcon } from './resources/archive.svg';
import { ReactComponent as RestoreIcon } from './resources/restore.svg';

const ToastAlert = ({ title, id, isClosable, onClose, onUndo, icon }) => {
  const ids = id
    ? {
        root: `toast-${id}`,
        title: `toast-${id}-title`,
        undo: `toast-${id}-undo`,
        description: `toast-${id}-description`,
      }
    : undefined;

  return (
    <Alert
      id={ids?.root}
      alignItems='start'
      borderRadius='md'
      boxShadow='lg'
      paddingEnd={8}
      textAlign='start'
      width='auto'
      bg='white'
      gap='8px'
    >
      {icon && (
        <Box pt={0.5}>
          {icon === 'restore' && <RestoreIcon width='15px' height='18px' />}
          {icon === 'archive' && <ArchiveIcon width='15px' height='18px' />}
        </Box>
      )}
      <Box flex='1' maxWidth='100%'>
        {title && (
          <AlertTitle
            color='black'
            fontSize='13px'
            fontWeight='500'
            id={ids?.title}
          >
            {title}
          </AlertTitle>
        )}
        {onUndo && (
          <AlertTitle
            width='fit-content'
            color='#2A85FF'
            fontSize='12px'
            fontWeight='700'
            id={ids?.undo}
            onClick={onUndo}
            cursor='pointer'
            _hover={{
              textDecoration: 'underline',
            }}
          >
            UNDO
          </AlertTitle>
        )}
        {/*{description && (*/}
        {/*  <AlertDescription id={ids?.description} display="block">*/}
        {/*    {description}*/}
        {/*  </AlertDescription>*/}
        {/*)}*/}
      </Box>
      {isClosable && (
        <CloseButton
          size='sm'
          onClick={onClose}
          position='absolute'
          insetEnd={1}
          top='50%'
          transform='translateY(-50%)'
        />
      )}
    </Alert>
  );
};

export default ToastAlert;

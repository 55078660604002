import { gql } from '@apollo/client';

export const addFormResponse = gql`
  mutation AddFormResponse($input: FormResponseAddTypeInput) {
    addFormResponse(input: $input) {
      eid
      userId
    }
  }
`;

export interface FormSubmitResponse {
  addFormResponse: {
    eid: string;
    userId: string;
  };
}

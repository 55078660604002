import React, { FC, useContext, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Image,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import Dropdown, { SelectOption } from '../../../../atoms/Dropdown';
import { commonStyles } from './visibility.styles';
import MultiSelectCustom from './MultiSelectCustom';
import { IVisibilityInput } from './visibility.types';
import TrashIcon from '../../../../assets/images/trash.svg';
import { VisibilityContext } from './context';
import { useErrorKeys, usePlaceHolderKeys } from './useErrorKeys';

const ToggleButton: FC = () => {
  const { t } = useTranslation(['form', 'deck']);
  const { setValue, getValues } = useFormContext<IVisibilityInput>();

  const operation = useWatch<IVisibilityInput, 'operation'>({
    name: 'operation',
  });

  const onPress = (value: IVisibilityInput['operation']) => {
    if (value === getValues('operation') || !value) {
      return;
    }
    setValue('operation', value);
  };

  const onDeletePress = () => {
    setValue('operation', undefined);
    setValue('otherFilterBy', null);
    setValue('otherFilterValue', []);
  };

  if (!operation) {
    return (
      <Button
        width='fit-content'
        leftIcon={<AddIcon />}
        variant='outline'
        fontSize='15px'
        fontWeight='500'
        borderRadius='7px'
        onClick={() => onPress('or')}
      >
        {t('deck:visibility.add_another_condition')}
      </Button>
    );
  }

  return (
    <Flex align='center'>
      <Button
        borderRightRadius='0'
        borderLeftRadius='12px'
        variant='solid'
        textTransform='uppercase'
        fontSize='15px'
        fontWeight='600'
        colorScheme={operation === 'or' ? 'blue' : 'gray'}
        onClick={() => onPress('or')}
      >
        {t('form:or_text')}
      </Button>

      <Button
        borderRightRadius='12px'
        borderLeftRadius='0'
        variant='solid'
        textTransform='uppercase'
        fontSize='15px'
        fontWeight='600'
        colorScheme={operation === 'and' ? 'blue' : 'gray'}
        onClick={() => onPress('and')}
      >
        {t('form:and_text')}
      </Button>

      <Box flex={1} />

      {operation && (
        <IconButton
          aria-label={'delete'}
          variant='ghost'
          icon={<Image src={TrashIcon} w='22px' h='22px' />}
          onClick={onDeletePress}
        />
      )}
    </Flex>
  );
};

interface IProps {}

const OtherConditions: FC<IProps> = () => {
  const { t } = useTranslation(['deck', 'form']);
  const { control, getValues, setValue } = useFormContext<IVisibilityInput>();

  const { allOptions, dataObject } = useContext(VisibilityContext);

  const PlaceholderKeys = usePlaceHolderKeys();
  const ErrorKeys = useErrorKeys();

  const operation = useWatch<IVisibilityInput, 'operation'>({
    name: 'operation',
  });

  const filterBy = useWatch<IVisibilityInput, 'filterBy'>({
    name: 'filterBy',
  });

  const otherFilterBy = useWatch<IVisibilityInput, 'otherFilterBy'>({
    name: 'otherFilterBy',
  });

  const secondOptions = useMemo(() => {
    return allOptions.filter(
      (it) => it.value !== (filterBy?.value || filterBy)
    );
  }, [allOptions, filterBy]);

  const onChangeOtherFilterBy = <T extends SelectOption>(
    _value: T,
    callback: (value: T) => void
  ) => {
    if (getValues('otherFilterBy.value') !== _value.value) {
      setValue('otherFilterValue', []);
    }
    callback(_value);
  };

  return (
    <>
      <ToggleButton />

      {operation && (
        <Flex gap='32px'>
          <Box flex={1} maxW='calc(50% - 35px)'>
            <Controller
              control={control}
              name='otherFilterBy'
              rules={{
                required: t('form:validation.selectFilter'),
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <Dropdown
                      size='lg'
                      value={field.value}
                      onChange={(_val) =>
                        onChangeOtherFilterBy(_val, field.onChange)
                      }
                      options={secondOptions}
                      selectStyles={commonStyles}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>

          <Box fontSize='15px' lineHeight='48px' fontWeight='600'>
            {t('deck:visibility.is_text')}
          </Box>

          <Box flex={1} maxW='calc(50% - 35px)'>
            <Controller
              control={control}
              name='otherFilterValue'
              rules={{
                validate: (value) => {
                  if (!value?.length && getValues('otherFilterBy.value')) {
                    return ErrorKeys[getValues('otherFilterBy.value') as never];
                  }
                },
                deps: ['filterBy', 'otherFilterBy'],
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <MultiSelectCustom
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={
                        PlaceholderKeys[otherFilterBy?.value as never]
                      }
                      options={dataObject[otherFilterBy?.value as never]}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default OtherConditions;

import React from 'react';
import { forwardRef, Tab, TabProps } from '@chakra-ui/react';

const TabItem = forwardRef<TabProps, 'button'>((props, ref) => {
  return (
    <Tab
      ref={ref}
      mb='-1px'
      borderBottomWidth='3px'
      color='#6F767E'
      fontSize='15px'
      fontWeight='600'
      borderTopLeftRadius='8px'
      borderTopRightRadius='8px'
      {...props}
      _selected={{
        color: '#33383F',
        borderBottomColor: '#2A85FF',
        ...props._selected,
      }}
    />
  );
});

export default TabItem;

import React, { FC } from 'react';
import { Box, Center, chakra } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  QuestionEntity,
  QuestionType,
  ResponseEntity,
  UserListEntity,
} from '../../../../../types';

import { UserFormResponseWrapper } from './user-form-response.styles';
import FileComponent from '../FileComponent';
import ImageComponent from '../ImageComponent';
import LocationComponent from '../LocationComponent';
import ShortAnswerComponent from '../ShortAnswerComponent';
import VideoComponent from '../VideoComponent';
import MemberComponent from '../MemberComponent';
import Loader from '../../../../../sub-components/Loader';
import { AcknowledgeCell, CheckBoxCell, DropDownCell } from '../ResponseCell';
import LongAnswerComponent from '../LongAnswerComponent';
import FormReportExportFooter from '../FormResponseExportFooter';
import { GenerateSingleUserResponseSheet } from '../GenerateFormResponseSheetHandler/GenerateFormResponseSheet';

interface IProps {
  loading?: boolean;
  response?: Array<{ response: ResponseEntity; question: QuestionEntity }>;
  userList?: UserListEntity[];
  userName: string;
}

const UserFormResponse: FC<IProps> = ({
  loading,
  response,
  userList,
  userName,
}) => {
  const { t } = useTranslation(['card', 'form']);

  if (loading) {
    return (
      <UserFormResponseWrapper>
        <Center boxSize='full'>
          <Loader size='xl' />
        </Center>
      </UserFormResponseWrapper>
    );
  }

  return (
    <UserFormResponseWrapper>
      <Box className='user-form-table'>
        <chakra.table>
          <thead>
            <tr>
              <chakra.th width='300px'>{t('card:question')}</chakra.th>
              <th>{t('form:response')}</th>
            </tr>
          </thead>
          <tbody>
            {response?.map(({ response, question }) => {
              return (
                <tr key={response?.qid}>
                  <td style={{ minWidth: '300px', maxWidth: '300px' }}>
                    <strong>{question.label}</strong>
                  </td>
                  <td>
                    {response?.qType === 'dropDown' && (
                      <DropDownCell response={response} question={question} />
                    )}

                    {response?.qType === 'longText' && (
                      <LongAnswerComponent
                        longAnswer={{
                          answer: response?.response,
                          eid: response?.qid!,
                        }}
                        defaultLength={response?.response?.length}
                        noteImages={response?.noteImage}
                      />
                    )}
                    {response?.qType === 'shortText' && (
                      <ShortAnswerComponent
                        shortAnswer={response?.response || ''}
                        noteImages={response?.noteImage}
                      />
                    )}
                    {/*// @ts-ignore */}
                    {(response?.qType === 'singleChoice' ||
                      response?.qType === 'multiChoice') && (
                      <CheckBoxCell
                        response={response}
                        question={question}
                        isMultiChoice
                        showUnselectedOption
                      />
                    )}

                    {response?.qType === 'checkBox' && (
                      <CheckBoxCell
                        response={response}
                        question={question}
                        showUnselectedOption
                      />
                    )}

                    {response?.qType === 'fileUpload' && (
                      <FileComponent
                        files={response?.responseUrl}
                        noteText={response?.noteText}
                        noteImages={response?.noteImage}
                      />
                    )}
                    {response?.qType === 'imageUpload' && (
                      <ImageComponent
                        images={response?.responseUrl}
                        noteText={response?.noteText}
                      />
                    )}
                    {response?.qType === 'videoUpload' && (
                      <VideoComponent
                        videos={response?.responseUrl || []}
                        noteText={response?.noteText}
                      />
                    )}
                    {response?.qType === 'member' && (
                      <MemberComponent
                        memberId={response?.responseId?.[0] || ''}
                        userList={userList}
                      />
                    )}
                    {response?.qType === 'location' && (
                      <LocationComponent
                        location={response?.responseId?.[0] || ''}
                        userList={userList}
                      />
                    )}
                    {response?.qType === QuestionType.ACKNOWLEDGMENT && (
                      <AcknowledgeCell
                        text={response?.response || ''}
                        font={response?.font || ''}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </chakra.table>
      </Box>
      <FormReportExportFooter
        count={response?.length || 0}
        exportDataLoading={false}
        generageUserWiseReportHandler={() => {
          GenerateSingleUserResponseSheet(response!, { title: userName });
        }}
      />
    </UserFormResponseWrapper>
  );
};

export default UserFormResponse;

import React, { useCallback } from 'react';
import { useTheme } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useConfirm } from '../../../../../ui-components/Confirm';
import Header from './Header';
import ResponseContainer from './ResponseContainer';

interface IProps {
  formId: string;
  userId: string;
}

type TaskFormResponse = (props: IProps) => void;

export const useTaskFormResponse = (): TaskFormResponse => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const drawerConfig = useTheme().components?.Drawer;

  return useCallback(
    ({ formId, userId }) => {
      confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        styleConfig: drawerConfig,
        title: <Header />,
        content: <ResponseContainer formId={formId} userId={userId} />,
        contentProps: {
          maxWidth: '740px',
          containerProps: {
            justifyContent: 'flex-end',
          },
        },
        footer: null,
      });
    },
    [drawerConfig]
  );
};

import { BoxProps, TextProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import CommonLabel from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';
import CommonContainer from 'ui-components/CommonContainer';

export type IFilterBadgeProps = {
  label: string;
  value: string;
  selected?: boolean;
  onSelected?: (value: string) => void;

  /**
   * For a filter badge container, if want to pass custom css initially, can pass properties of ChakraUI `BoxProps`.
   */
  defaultContainerCSSProps?: BoxProps;
  /**
   * For a filter badge container, if want to pass custom css when selected, can pass properties of ChakraUI `BoxProps`.
   */
  activeContainerCSSProps?: BoxProps;
  /**
   * For a filter badge text, if want to pass custom css initially, can pass properties of ChakraUI `TextProps`.
   */
  defaultTextCSSProps?: TextProps;
  /**
   * For a filter badge text, if want to pass custom css when selected, can pass properties of ChakraUI `TextProps`.
   */
  activeTextCSSProps?: BoxProps;
};

const FilterBadge: FC<IFilterBadgeProps> = (props) => {
  const {
    label,
    value,
    selected,
    onSelected,
    defaultContainerCSSProps,
    activeContainerCSSProps,
    defaultTextCSSProps,
    activeTextCSSProps,
  } = props;

  // Define default styles
  const defaultStyles: BoxProps = {
    borderRadius: '8px',
    bg: '#F1F1F1',
    p: '8px 16px',
    w: 'fit-content',
    cursor: 'pointer',
    ...defaultContainerCSSProps, // Merge default props
  };

  // Define active styles
  const activeStyles: BoxProps = {
    ...defaultStyles, // Start with default styles
    bg: 'white',
    ...activeContainerCSSProps, // Override with active props
  };

  // Define default label styles
  const defaultLabelStyles: TextProps = {
    color: 'black',
    fontWeight: 600,
    ...defaultTextCSSProps, // Merge default text props
  };

  // Define active label styles
  const activeLabelStyles: TextProps = {
    color: '#6F767E',
    ...defaultLabelStyles, // Start with default label styles
    ...activeTextCSSProps, // Override with active text props
  };

  return (
    <CommonContainer
      {...(selected ? activeStyles : defaultStyles)}
      onClick={() => onSelected && onSelected(value)}
    >
      <CommonLabel
        label={label}
        {...(selected ? activeLabelStyles : defaultLabelStyles)}
      />
    </CommonContainer>
  );
};

export default FilterBadge;

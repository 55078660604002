import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { BoxHeader, useConfirm } from '../../../../../../ui-components';
import PhoneUpdateForm from './PhoneUpdateForm';

export default function usePhoneNumberUpdate() {
  const confirmRef = useRef(null);
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(() => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    const phoneNumber = userObj().phone;

    return new Promise((resolve) => {
      confirmRef.current = confirm({
        Wrapper: ({ children }) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <BoxHeader
            color='#CABDFF'
            boxWidth={3}
            title={
              !!phoneNumber
                ? 'Please confirm your mobile number'
                : 'Add your mobile number to get SMS alerts'
            }
            fontSize='16px'
          />
        ),
        content: <PhoneUpdateForm phoneNumber={phoneNumber} />,
        isCentered: true,
        afterClose: resolve,
        contentProps: {
          maxWidth: '524px',
          paddingTop: '4px',
          paddingBottom: '10px',
        },
        footer: null,
      });
    });
  }, []);
}

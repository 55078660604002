import React, { FC } from 'react';
import { Flex, Radio, Spacer, useRadioGroupContext } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  icon: IconProp;
  value: string;
}

const CustomCheck: FC<IProps> = ({ icon, value }) => {
  const group = useRadioGroupContext();

  let isChecked = false;
  if (group?.value !== null && value !== null) {
    isChecked = group.value === value;
  }

  return (
    <Flex
      align='center'
      fontSize='20px'
      fontWeight='600'
      lineHeight='24px'
      color='#333b4f'
      textTransform='capitalize'
      my='20px'
      opacity={isChecked ? 1 : 0.4}
    >
      <FontAwesomeIcon icon={icon} style={{ marginRight: 8 }} />
      {value} <Spacer />
      <Radio value={value} size='lg' />
    </Flex>
  );
};

export default CustomCheck;

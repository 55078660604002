import { StateCreator } from 'zustand';

// Define the state and actions interfaces for the user slice
export interface ChapterEditorState {
  data: any;
}

export const chapterEditorSlice: StateCreator<ChapterEditorState> = (set) => ({
  data: [],
});

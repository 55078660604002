import { Box, Flex, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IMyTask,
  INotRelevantUserDetails,
  ITaskListData,
  ITaskOverview,
} from 'pages/TasksDashboard/tasks.types';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import EmptyState from 'sub-components/EmptyState';
import NotRelevant from './NotRelevant';

interface IProps {
  data: IMyTask | undefined;
  isDrawerOpen: boolean;
  overviewStepData: ITaskOverview[];
  searchQuery: string;
  selectedUserId: string;
  taskByIdLoading: boolean;
  taskComponents: {
    overdue: FC<{
      data: IMyTask | undefined;
      taskOverviewData: ITaskOverview[];
      taskData: ITaskListData[];
    }>;
    dueToday: FC<{
      data: IMyTask | undefined;
      taskOverviewData: ITaskOverview[];
      taskData: ITaskListData[];
    }>;
    thisWeek: FC<{
      data: IMyTask | undefined;
      taskOverviewData: ITaskOverview[];
      taskData: ITaskListData[];
    }>;
    upcoming: FC<{
      data: IMyTask | undefined;
      taskOverviewData: ITaskOverview[];
      taskData: ITaskListData[];
    }>;
    totalTasks: FC<{
      data: IMyTask | undefined;
      taskOverviewData: ITaskOverview[];
      taskData: ITaskListData[];
    }>;
    completed: FC<{
      data: IMyTask | undefined;
      taskOverviewData: ITaskOverview[];
      taskData: ITaskListData[];
    }>;
  };
  taskData: ITaskListData[];
  getTaskOverviewToShow: () => ITaskOverview[];
  getUsersList: () => INotRelevantUserDetails[];
  onDrawerClose: () => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setSelectedUserId: React.Dispatch<React.SetStateAction<string>>;
  taskOverviewSelectionHandler: (task: ITaskOverview) => void;
  openNotRelevantDrawer: () => void;
}

const Listing: FC<IProps> = ({
  data,
  isDrawerOpen,
  overviewStepData,
  searchQuery,
  selectedUserId,
  taskByIdLoading,
  taskComponents,
  taskData,
  getTaskOverviewToShow,
  getUsersList,
  onDrawerClose,
  setSearchQuery,
  setSelectedUserId,
  taskOverviewSelectionHandler,
  openNotRelevantDrawer,
}) => {
  const componentRender = () => {
    const anySelectedAndCountPositive = overviewStepData.some(
      (item) => item.selected && item.count > 0
    );
    const allSelectedFalse = !overviewStepData.some((item) => item.selected);
    const anyCountGreaterThanZero = overviewStepData.some(
      (item) => item.count > 0
    );
    const allCountsZero = overviewStepData.every((item) => item.count === 0);
    const selectedWithZeroCount =
      !allSelectedFalse &&
      overviewStepData.some((item) => item.selected && item.count === 0);

    if (
      anySelectedAndCountPositive ||
      (allSelectedFalse && anyCountGreaterThanZero)
    ) {
      return getTaskOverviewToShow().map((task, index) => {
        const TaskComponent = taskComponents[task.id];
        return (
          <TaskComponent
            key={index}
            data={data}
            taskOverviewData={overviewStepData}
            taskData={taskData}
          />
        );
      });
    } else if (allSelectedFalse && allCountsZero) {
      return (
        <EmptyState
          image='MyTask'
          title='No task is assigned to you'
          description='Your tasks will appear here, once it is assigned to you'
        />
      );
    } else if (selectedWithZeroCount) {
      return (
        <EmptyState image='MyTask' title='Selected category has no task' />
      );
    }
  };

  return (
    <>
      <Flex gap={'20px'} align='center'>
        {overviewStepData?.map((task, index) => {
          return (
            task?.id !== 'upcoming' && (
              <Flex
                key={index}
                cursor='pointer'
                p={6}
                borderRadius='8px'
                border={task.selected ? '1px solid #2b85ff' : 'none'}
                bg='white'
                flexDir='column'
                w='266px'
                gap='10px'
                onClick={() => taskOverviewSelectionHandler(task)}
              >
                <Flex align='center' justify='space-between'>
                  <Flex gap='10px' align='center'>
                    <Box
                      h='20px'
                      w='12px'
                      bg={task?.color}
                      borderRadius='2px'
                    />
                    <Text>{task?.name}</Text>
                  </Flex>
                  {task.selected && (
                    <FontAwesomeIcon
                      icon={faCircleCheck as IconProp}
                      color='#2b85ff'
                    />
                  )}
                </Flex>
                <Text fontWeight={700} fontSize='20px'>
                  {task?.count} {task?.count === 1 ? 'task' : 'tasks'}
                </Text>
              </Flex>
            )
          );
        })}
      </Flex>
      {componentRender()}
      {isDrawerOpen && (
        <NotRelevant
          taskByIdLoading={taskByIdLoading}
          isDrawerOpen={isDrawerOpen}
          searchQuery={searchQuery}
          getUsersList={getUsersList}
          onDrawerClose={onDrawerClose}
          setSearchQuery={setSearchQuery}
          setSelectedUserId={setSelectedUserId}
        />
      )}
      {selectedUserId && (
        <SingleUserChatModal
          usePortal
          selectedUser={selectedUserId}
          onClose={() => {
            setSelectedUserId('');
            openNotRelevantDrawer();
          }}
        />
      )}
    </>
  );
};

export default Listing;

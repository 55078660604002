import React, { FC, useMemo, useState } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { searchRegExp, toArray } from '../../../../../utils';
import { Button } from '../../../../../atoms';
import SearchInput from '../../../../../atoms/SearchInput';
import { FormCategory } from '../../../../../types';
import { Authorize, AuthRole } from '../../../../../authorization';

import {
  CategoryResponse,
  getFormCategories,
} from '../../../../../sub-components/forms/form.graphql';
import {
  useCreateFormCategory,
  useDeleteFormCategory,
  useEditFormCategory,
} from '../../../../../sub-components/forms/category';

import FormCategoryList from './FormCategoryList';
import CategoryListLoader from './CategoryListLoader';

interface IProps {
  category: string[];
  onCategoryChange: (category: string[]) => void;
}

const CategoryListContainer: FC<IProps> = ({ category, onCategoryChange }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const createFormCategory = useCreateFormCategory();
  const editFormCategory = useEditFormCategory();
  const deleteFormCategory = useDeleteFormCategory();

  const { data, loading: loadingCategory } =
    useQuery<CategoryResponse>(getFormCategories);

  const _categoryList = useMemo(() => {
    return toArray(data?.EntityFormCategories);
  }, [data?.EntityFormCategories]);

  const categoryList = useMemo(() => {
    if (!searchQuery) {
      return _categoryList;
    }

    const reg = searchRegExp(searchQuery, 'gi');

    return _categoryList.filter((value) => {
      return value.name?.match(reg);
    });
  }, [_categoryList, searchQuery]);

  const onCreateClick = () => {
    createFormCategory({
      onCategoryCreated: () => null,
    });
  };

  const onDeleteClick = async (formCategory: FormCategory) => {
    await deleteFormCategory({
      category: formCategory,
      // onCategoryDeleted: onCategoryDeleted,
    });
  };

  const onEditClick = (formCategory: FormCategory) => {
    editFormCategory({
      category: formCategory,
      onDeleteClick: () => onDeleteClick?.(formCategory),
    });
  };

  return (
    <Flex flexDir='column'>
      <SearchInput
        placeholder='Search by filter name'
        hideShortcuts
        value={searchQuery}
        onChange={(e) => setSearchQuery(e?.target?.value)}
      />

      <Authorize
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <Button
          mt={3}
          variant='ghost'
          colorScheme='blue'
          fontSize='15px'
          borderRadius='6px'
          px='8px'
          justifyContent='start'
          leftIcon={
            <FontAwesomeIcon icon={faPlus as IconProp} fontSize='14px' />
          }
          onClick={onCreateClick}
        >
          <Box>Create new form category</Box>
        </Button>
      </Authorize>

      <Divider mt={2} mb={3} />

      <CategoryListLoader
        isLoading={loadingCategory}
        isEmpty={categoryList.length === 0}
        searchQuery={searchQuery}
      >
        <FormCategoryList
          categoryList={categoryList}
          value={category}
          onChange={onCategoryChange}
          onEditClick={onEditClick}
        />
      </CategoryListLoader>
    </Flex>
  );
};

export default CategoryListContainer;

import React, { FC, useEffect, useState } from 'react';
import {
  Center,
  Flex,
  Image,
  ModalBody,
  ModalFooter,
  Text,
} from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { faComments } from '@fortawesome/pro-solid-svg-icons';

import { AuthRole } from 'authorization';
import { AlertBox } from 'atoms';
import Loader from 'sub-components/Loader';
import { IFormInput } from './invite.types';
import ChapGroupEmpty from './ChapGroupEmpty';

import { getImage } from '../../utils';
import { ChatGroupEntity } from '../../types';
import { toArray } from '../../utils/utils';
import InviteFinishButton from './InviteFinishButton';

const GET_CHAT_GROUPS = gql`
  query Query {
    FetchEntityChatGroups
  }
`;

export function filterChatChannel(
  chatGroups: ChatGroupEntity[] = [],
  authRole: AuthRole,
  job: string,
  locations: string[]
): ChatGroupEntity[] {
  return toArray(chatGroups).filter((value) => {
    return (
      (value.details?.type === 'public' ||
        value.details?.roles?.includes(job) ||
        value.details?.authRoles?.includes(authRole as string) ||
        value.details?.locations?.some((l) => locations.includes(l.eid))) &&
      value.isDefault
    );
  });
}

interface IProps {
  reInviteUser?: boolean;
  closeHandler: () => void;
}

const ChatGroups: FC<IProps> = ({ closeHandler, reInviteUser }) => {
  const { t } = useTranslation(['common', 'invite']);
  const { getValues, setValue } = useFormContext<IFormInput>();
  const [groupData, setGroupData] = useState<ChatGroupEntity[]>([]);

  const [getChatGroups, { loading }] = useLazyQuery<
    Record<'FetchEntityChatGroups', ChatGroupEntity[]>
  >(GET_CHAT_GROUPS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const groupEntities = filterChatChannel(
        data?.FetchEntityChatGroups,
        getValues('permissionLevel')!,
        getValues('job.value'),
        toArray(getValues('location')).map((l) => l.id)
      );

      setGroupData(groupEntities);
    },
  });

  useEffect(() => {
    getChatGroups();
  }, []);

  if (loading) {
    return (
      <Center h='40vh'>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <ModalBody>
        <Flex minH='50vh' flexDir='column' gap={3}>
          <AlertBox icon={faComments}>
            <Trans
              t={t}
              i18nKey='invite:inviteChatGroupAssignHelp'
              values={{
                name: getValues('name'),
              }}
            />
          </AlertBox>

          <Flex flex={1} flexDir='column' overflow='auto' gap={4}>
            <ChapGroupEmpty haveItems={!!groupData?.length}>
              {groupData?.map((_data, index) => (
                <Flex key={index} align='center' gap='10px'>
                  <Image
                    src={getImage(_data?.icon, _data?.name)}
                    boxSize='56px'
                    borderRadius='8px'
                  />
                  <Flex flexDir='column'>
                    <Text fontWeight={700} fontSize='15px'>
                      {_data?.name}
                    </Text>
                    <Text fontWeight={500} fontSize='15px' color='#6F767E'>
                      You and {_data?.membersCount} members
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </ChapGroupEmpty>
          </Flex>
        </Flex>
      </ModalBody>

      <ModalFooter flexDir='column' gap={3}>
        <AlertBox
          w='full'
          bg='#E4DEFF99'
          paddingBlock='10px'
          childProps={{
            color: '#8E59FF',
          }}
        >
          <Trans
            t={t}
            i18nKey='invite:inviteChannelSettingHint'
            values={{
              name: getValues('name'),
            }}
          />
        </AlertBox>
        <InviteFinishButton
          reInviteUser={reInviteUser}
          closeHandler={closeHandler}
          onBackClick={() => setValue('currentStep', 'trainingPath')}
        />
      </ModalFooter>
    </>
  );
};

export default ChatGroups;

import React, { useEffect, useState } from 'react';
import { Row, Button, Typography, message, Col } from 'antd';
import { Link } from 'react-router-dom';
import { CometChat } from '@cometchat-pro/chat';
import './Header/header.css';
import {
  EditOutlined,
  LogoutOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { ReactComponent as LogoSvg } from '../assets/images/logo.svg';
import { useQuery, gql, useMutation, useReactiveVar } from '@apollo/client';
import { TextInput } from './TextInput';
import { useHistory } from 'react-router';
import profile from '../assets/images/profile.png';
import {
  authTokenVar,
  userObj,
  client,
  chatStatus,
} from 'sop-commons/src/client/clientFactory';
const { Text } = Typography;

import backIcon from '../assets/images/back.svg';
import { bundleObj, roleObj } from './DashboardMenu';
import SearchModal from './SearchModal';
import { useIntercom } from 'react-use-intercom';
import { WORKER } from 'utils/userRoles';
import { GET_USER } from '../pages/Login/login.graphql';
import { Flex } from '@chakra-ui/react';

const UPDATE_ENTITY_BY_ID = gql`
  mutation UpdateEntityByIdMutation($input: UpdateEntityInput) {
    updateEntityById(input: $input) {
      name
    }
  }
`;
type HeaderProps = {
  buttonSize?: any;
  menuStyle?: object;
  subMenuIcon?: Node;
  menuMode?: any;
  menuDefaultSelectedKeys?: string[];
  menuDefaultOpenKeys?: string[];
  currentSelectedKeys?: string[];
  isOnboarded?: boolean;
  setHandlers?: any;
  disableSearch?: boolean;
};

export const Header = ({
  buttonSize = 'large',
  isOnboarded,
  setHandlers,
  disableSearch,
}: HeaderProps) => {
  const history = useHistory();
  const { shutdown } = useIntercom();
  const inputRef = React.useRef<any>(null);
  const userObject = useReactiveVar(userObj);
  const [isSearchModalOpened, setIsSearchModalOpened] = useState(false);
  const {
    loading: getUserLoading,
    error: getUserError,
    data: getUserData,
  } = useQuery(GET_USER);

  useEffect(() => {
    userObj({ ...userObject, ...getUserData?.user });
  }, [getUserData]);

  const [updateEntityById, { loading, error, data }] = useMutation(
    UPDATE_ENTITY_BY_ID,
    {
      onCompleted: () => {
        setNameEditEnabled(false);
        message.success('Successfully changed business name');
      },
    }
  );

  const [nameEditEnabled, setNameEditEnabled] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      updateEntityById({
        variables: {
          input: {
            eid: getUserData?.user?.entity?.eid,
            name: businessName,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (setHandlers) {
      setHandlers({
        OPEN_SEARCH: (e: any) => {
          onOpenSearchModal();
        },
      });
    }
  }, []);

  useEffect(() => {
    if (getUserData?.user?.entity?.name) {
      setBusinessName(getUserData?.user?.entity?.name);
    }
  }, [getUserData?.user?.entity?.name]);

  const onOpenSearchModal = () => {
    setIsSearchModalOpened(true);
  };

  const onCloseSearchModal = () => {
    setIsSearchModalOpened(false);
  };

  const onClearData = () => {
    localStorage.clear();

    authTokenVar('');
    userObj({});
    chatStatus(false);
    bundleObj({});
    roleObj({});
    shutdown();
    client.writeQuery({
      query: GET_USER,
      data: {
        user: {},
      },
    });
    client.clearStore();
    window.location.reload();

    history.replace('/');
  };
  return (
    <>
      <Row
        justify='center'
        style={{
          marginTop: 20,
          alignItems: 'flex-start',
        }}
        className='app-main-container'
      >
        <Col
          span={5}
          style={{
            maxHeight: '100%',
            minWidth: 290,
            maxWidth: 350.98,
          }}
        >
          {!isOnboarded && (
            <div className='eventclass-business-name business-name'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: 10,
                    alignSelf: 'center',
                  }}
                >
                  {getUserData?.user?.entity?.profilePic ? (
                    <img
                      src={getUserData?.user?.entity?.profilePic}
                      height={21.33}
                      width={21.33}
                      style={{ width: 21.33 }}
                    />
                  ) : (
                    <LogoSvg />
                  )}
                </div>
                {!nameEditEnabled && (
                  <Text
                    ellipsis={true}
                    style={{
                      width: '100%',
                      marginRight: 10,
                      //minWidth: '195px',
                      //flex: '1 1 auto',
                    }}
                  >
                    {businessName}
                  </Text>
                )}
                {nameEditEnabled && (
                  <TextInput
                    className='eventclass-header-business-name-textinput'
                    handleKeyDown={handleKeyDown}
                    inputRef={inputRef}
                    style={{
                      //paddingRight: 5,
                      //width: '100%',
                      color: 'black',
                      borderBottom: 'solid',
                      borderBottomWidth: nameEditEnabled ? 1 : 0,
                      borderBottomColor: '#999999',
                    }}
                    disabled={!nameEditEnabled}
                    value={businessName}
                    bordered={false}
                    size='small'
                    onChange={(e: any) => {
                      setBusinessName(e.target.value);
                    }}
                    placeholder={''}
                  />
                )}
              </div>

              {getUserData?.user?.authRole !== WORKER && (
                <EditOutlined
                  onClick={() => {
                    inputRef?.current?.focus({
                      cursor: 'end',
                    });
                    setNameEditEnabled(!nameEditEnabled);
                  }}
                />
              )}
            </div>
          )}
          {isOnboarded && (
            <Link to='/dashboard'>
              <div
                className='eventclass-header-go-to-dashboard-button go-to-dashboard-button'
                style={{
                  display: 'flex',
                  height: 40,
                  paddingInline: 23,
                  alignItems: 'center',
                  backgroundColor: '#FFFFFF',
                  borderRadius: 5,
                  color: '#333b4f',
                  maxWidth: 350.98,
                }}
              >
                <img
                  src={backIcon}
                  style={{ width: '20px', marginRight: '20px' }}
                />
                Go to dashboard
              </div>
            </Link>
          )}
        </Col>
        <Col
          span={18}
          style={{
            maxWidth: 1360,
            marginLeft: 20,
          }}
        >
          <Flex
            style={{
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {!disableSearch && (
              <Button
                className='eventclass-header-search-button'
                style={{
                  borderWidth: 1,
                  backgroundColor: '#FFFFFF',
                  borderRadius: 5,
                  borderColor: '#dddddd',
                  marginRight: 10,
                }}
                type='primary'
                icon={<SearchOutlined style={{ color: '#333B4F' }} />}
                size={buttonSize}
                onClick={onOpenSearchModal}
              />
            )}

            <Button
              className='eventclass-header-logout-button'
              onClick={() => {
                console.log('logout getting called');
                CometChat.logout().then(
                  () => {
                    console.log('Logout completed successfully');
                    onClearData();
                  },
                  (error) => {
                    console.log('Logout failed with exception:', { error });
                    onClearData();
                  }
                );
              }}
              style={{
                borderWidth: 1,
                borderColor: '#dddddd',
                backgroundColor: '#FFFFFF',
                borderRadius: 5,
                marginRight: 10,
              }}
              type='primary'
              icon={<LogoutOutlined style={{ color: '#333B4F' }} />}
              size={buttonSize}
            />
            <div
              className='eventclass-header-profile-pic'
              style={{
                width: 40,
                height: 40,
                borderWidth: 1,
                borderColor: '#dddddd',
              }}
            >
              <img
                src={
                  getUserData?.user?.profilePic
                    ? getUserData?.user?.profilePic
                    : profile
                }
                className='profile-pic'
                width={40}
                height={40}
                style={{ maxHeight: '40px' }}
              />
            </div>
          </Flex>
        </Col>
      </Row>
      {isSearchModalOpened && (
        <SearchModal
          isOpen={isSearchModalOpened}
          onClose={onCloseSearchModal}
        />
      )}
    </>
  );
};

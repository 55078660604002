import React, { FC } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import noImage from '../../../../../assets/images/placeholder/empty-assignees.svg';

interface IProps {}

const AssigneeEmpty: FC<IProps> = () => {
  const { t } = useTranslation('task');

  return (
    <Flex direction='column' gap='14px'>
      <Image src={noImage} maxW='300px' mx='auto' />
      <Flex direction='column' textAlign='center' gap='4px'>
        <Box fontSize='15px' fontWeight='700' color='#303030'>
          {t('empty.memberAssigned')}
        </Box>

        <Box fontSize='11px' fontWeight='500' color='#9E9E9E' px='12px'>
          {t('empty.memberAssignedDesc')}
        </Box>
      </Flex>
    </Flex>
  );
};

export default AssigneeEmpty;

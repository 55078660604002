import { gql } from '@apollo/client';
import { LocationAnalytics } from './useLocationGraphData';
import { ILocationMember } from '../../../../Locations/locationTypes/location-types';
import { TrendAnalytic, IGraphRange } from '../../common';

export type { MemberVariable } from '../assignee/performance.graphql';

export interface LocationEntity {
  eid: string;
  name: string;

  profilePic?: string;
  username?: string;
  phone?: string;
  members: ILocationMember[];
  createdAt: string;
}

export interface LocPerformanceEntity
  extends LocationEntity,
    LocationAnalytics {
  completionRate: number;
}

export interface PerformanceResponse {
  EntityUser: LocationEntity[];

  TaskSupervisedLocationAnalytics: Record<string, LocationAnalytics>;
}

export const PERFORMANCE_LOCATION = gql`
  query PerformanceLocation(
    $entityId: String!
    $type: [userTypeEnum]
    $status: [userStatusEnum]
  ) {
    EntityUser(entityId: $entityId, type: $type, status: $status) {
      eid
      name
      email
      profilePic
      username
      phone
      members
      createdAt
    }

    TaskSupervisedLocationAnalytics
  }
`;

export interface CompletionRateResponse {
  TaskSupervisedLocationWiseTrend: Record<
    string,
    Record<number, TrendAnalytic>
  >;
}

export interface CompletionRateVariable extends IGraphRange {
  locationIds: string[];
}

export const LOCATION_COMPLETION_RATE_QUERY = gql`
  query TaskSupervisedLocationWiseTrend(
    $locationIds: [String!]!
    $type: AnalyticsTrendType!
    $startDate: Date
    $endDate: Date
  ) {
    TaskSupervisedLocationWiseTrend(
      locationIds: $locationIds
      type: $type
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

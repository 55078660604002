import React, { FC } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface IProps extends Pick<BoxProps, 'id' | 'color' | 'bg' | 'background'> {}

const StatusBadge: FC<IProps> = ({ children, ...rest }) => {
  return (
    <Box
      borderRadius='6px'
      fontSize='12px'
      fontWeight={600}
      p='5px 10px'
      whiteSpace='nowrap'
      {...rest}
    >
      {children}
    </Box>
  );
};

export default StatusBadge;

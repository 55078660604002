import { gql } from '@apollo/client';
import {
  QuestionEntity,
  ResponseEntity,
  UserListEntity,
} from '../../../../../types';

export interface UserFormResVariable {
  filter: {
    formId: string;
    userId?: string[];
    sessionId?: string;
  };
}

interface FormResponse {
  eid: string;
  formId: string;
  form: {
    eid: string;
    title: string;
    description?: string;
    questions: QuestionEntity[];
    userList: UserListEntity[];
  };
  response: ResponseEntity[];
}

export interface IUserFormResponse {
  FormResponse: {
    items: FormResponse[];
  };
}

export const USER_FORM_RESPONSE_QUERY = gql`
  query UserFormResponse($filter: FilterFindManyFormsResponseInput) {
    FormResponse(filter: $filter) {
      items {
        eid
        formId
        form {
          eid
          title
          description
          questions
          userList
        }
        response
      }
    }
  }
`;

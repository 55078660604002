import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ImageUpload from './ImageUpload';
import { CardFormInput } from '../card-editor.types';
import {
  LayoutImageCount,
  MixedLayout,
} from 'sop-commons/src/utils/cardLayouts';
import ThumbnailInput from './ThumbnailInput';

interface IProps {}

const ImageFieldComponent: FC<IProps> = () => {
  const { t } = useTranslation('card');
  const { control, getValues, setValue } = useFormContext<CardFormInput>();
  return (
    <>
      <Controller
        control={control}
        name='images'
        rules={{
          required: t('validation.media_required'),
          validate: (value) => {
            const layout = getValues('layout') as MixedLayout;
            const maxImage = LayoutImageCount[layout];
            if ((value?.length || 0) < maxImage) {
              return t('validation.media_must_image', {
                value: maxImage,
              });
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <ImageUpload
                images={field.value}
                onChange={(images, imageUrl) => {
                  field.onChange(images);
                  setValue('thumbnail', imageUrl);
                }}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <ThumbnailInput control={control} />
    </>
  );
};

export default ImageFieldComponent;

import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { CommonOptions } from '../../../GlobalChat';
import { formatList, getPermissionText } from './CommonExpandAccordion';

interface IProps {
  type: 'newChannel' | 'directMessage';
  typeSelectionData: CommonOptions;
}

const RoleSelection: FC<IProps> = ({ type, typeSelectionData }) => {
  let rolesOrJobs = formatList(typeSelectionData?.selectedOptions?.jobs || []);
  return (
    <Box display='block'>
      <Box as='span' color='rgba(111, 118, 126, 1)'>
        All members with the job
      </Box>
      <Box as='span'>&nbsp;</Box>
      <Box as='span' color='rgba(42, 133, 255, 1)' fontWeight={700}>
        {/* {typeSelectionData?.selectedOptions?.jobs?.join(', ')} */}
        {rolesOrJobs}
      </Box>
      <Box as='span'>&nbsp;</Box>
      <Box as='span' color='rgba(111, 118, 126, 1)'>
        {getPermissionText(0)}{' '}
        {type === 'newChannel' ? 'create a channel' : 'start direct message'}.
      </Box>
    </Box>
  );
};

export default RoleSelection;

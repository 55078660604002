import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { useSetCurrentURLToLocalStorage } from 'hooks';

interface IProps {
  title: string;
}

const SidebarSection: FC<IProps> = ({ title, children }) => {
  useSetCurrentURLToLocalStorage(window.location.href);

  return (
    <Box>
      <Box
        className='sidebar-section-title'
        fontSize='12px'
        fontWeight='600'
        color='#33383F'
        textTransform='uppercase'
        p='0 0 3px 12px'
      >
        {title}
      </Box>

      {children}
    </Box>
  );
};

export default SidebarSection;

import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import EmailListField from './BasicDetails/EmailListField';
import PhoneListField from './BasicDetails/PhonelListField';
import { BoxHeader } from '../../../ui-components';
import { useTranslation } from 'react-i18next';

interface IProps {}

const ContactDetails: FC<IProps> = () => {
  const { t } = useTranslation(['location']);

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <Flex>
        <Box mr={4} w={4} minW={4} borderRadius={4} bg='#FFBC99' />
        <Box>
          <Box
            fontWeight='600'
            fontSize='14px'
            lineHeight='24px'
            color='#1a1d1f'
          >
            {t('location:contactDetails')}
          </Box>
          <Box
            fontWeight='400'
            fontSize='11px'
            lineHeight='18px'
            color='#6F767E'
          >
            {t('location:contactDetailsDesc')}
          </Box>
        </Box>
      </Flex>

      <EmailListField />

      <PhoneListField />
    </Flex>
  );
};

export default ContactDetails;

import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faSortDown, faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { ILocationFilter } from '../FormResponseTable';

interface IProps {
  boldText?: boolean;
  locationsData: ILocationFilter[] | undefined;
  showFilters: boolean | undefined;
  getSelectedCountHandler: (type: 'user' | 'location') => number;
  locationFilterClickHandler: ((location: ILocationFilter) => void) | undefined;
  onFilterCloseHandler: () => void;
}

const LocationHeaderComponent: FC<IProps> = ({
  boldText = false,
  locationsData,
  showFilters,
  getSelectedCountHandler,
  locationFilterClickHandler,
  onFilterCloseHandler,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onMainFlexClick = () => {
    if (!isOpen && showFilters) {
      onOpen();
    }
  };
  return (
    <Flex gap='5px' onClick={onMainFlexClick} cursor='pointer'>
      <Flex justify='flex-start' gap='5px' w='50%'>
        <Text fontWeight={boldText ? 700 : 400}>Location</Text>
        {showFilters && getSelectedCountHandler('location') > 0 && (
          <Text>({getSelectedCountHandler('location')})</Text>
        )}
      </Flex>
      {showFilters && (
        <Flex justify='flex-end' w='50%'>
          <Menu
            isOpen={isOpen}
            onClose={() => {
              onClose();
              onFilterCloseHandler();
            }}
          >
            <MenuButton>
              <FontAwesomeIcon
                icon={faSortDown as IconProp}
                style={{ marginBottom: '2px' }}
                color='#6f767e'
              />
            </MenuButton>
            <Portal>
              <MenuList zIndex={9999} p={0} maxH='300px' overflowY='scroll'>
                {locationsData?.map((location) => (
                  <Flex
                    key={location?.eid}
                    align='center'
                    justify='space-between'
                    p={2}
                    my={2}
                    cursor='pointer'
                    onClick={() =>
                      locationFilterClickHandler &&
                      locationFilterClickHandler(location)
                    }
                  >
                    <Text>{location?.name}</Text>
                    {!location?.selected && (
                      <FontAwesomeIcon
                        icon={faSquare as IconProp}
                        color='#6F767E'
                        size='lg'
                      />
                    )}
                    {location?.selected && (
                      <FontAwesomeIcon
                        icon={faSquareCheck as IconProp}
                        color='#2a85ff'
                        size='lg'
                      />
                    )}
                  </Flex>
                ))}
              </MenuList>
            </Portal>
          </Menu>
        </Flex>
      )}
    </Flex>
  );
};

export default LocationHeaderComponent;

import { useReactiveVar } from '@apollo/client';

import { bundleObj } from '../DashboardMenu';
import BundleLabel from '../BundleLabel';

// import css
import '../SopRoles/SopRoles.css';
import { AddRolePopover } from 'ui-components/SopRoles/SopRoles';

interface Props {
  bundles?: any;
  style?: object;
  sopId?: string;
  chapter?: string;
  setSelectedBundles?: any;
  isAdmin?: boolean;
  refetchUser?: any;
  isPublic?: boolean;
  bundleWithColor?: any;
}
const SopBundles = ({
  bundles,
  style,
  sopId,
  chapter,
  setSelectedBundles,
  isAdmin,
  refetchUser,
  isPublic,
  bundleWithColor,
}: Props) => {
  const bundleColorByName = isPublic
    ? bundleWithColor
    : useReactiveVar<any>(bundleObj);
  return (
    <div style={style}>
      {/* <div className='sop-roles-header-text'>Bundles</div> */}
      <div className='sop-roles-header'>
        <div className='eventclass-sop-detail-bundle-popover sop-roles-header-text'>
          Bundles
        </div>
        {isAdmin && !isPublic && (
          <AddRolePopover
            type='bundle'
            options={bundleColorByName}
            selectedData={bundles}
            sopId={sopId}
            chapter={chapter}
            setSelectedData={setSelectedBundles}
            refetchUser={refetchUser}
          />
        )}
      </div>
      <div
        className='eventclass-sop-detail-bundle-tag'
        style={{ display: 'flex', flexWrap: 'wrap', margin: '10px 0' }}
      >
        {bundles &&
          !!bundles.length &&
          bundles.map((bundle: any, index: number) => {
            return (
              <BundleLabel
                title={bundle}
                colorCode={bundleColorByName[bundle]}
                key={index}
                style={{ marginRight: '12px' }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SopBundles;

import { FC, PropsWithChildren } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../theme';

interface Props {
  isMobile?: boolean;
  isTablet?: boolean;
  isSmallDesktop?: boolean;
  isDesktop?: boolean;
  isBigScreen?: boolean;
}

const Responsive: FC<Props> = ({
  isMobile,
  isTablet,
  isSmallDesktop,
  isDesktop,
  isBigScreen,
  children,
}: PropsWithChildren<Props>) => {
  const isMobileResponsive = useMediaQuery({
    minWidth: breakpoints.sm,
    maxWidth: breakpoints.md - 1,
    // query: `(min-width: ${breakpoints.sm})`,
  });

  const isTabletResponsive = useMediaQuery({
    minWidth: breakpoints.md,
    maxWidth: breakpoints.lg - 1,
  });
  const isSmallLaptopResponsive = useMediaQuery({
    minWidth: breakpoints.lg,
    maxWidth: breakpoints.xl - 1,
  });
  const isDesktopOrLaptopResponsive = useMediaQuery({
    minWidth: breakpoints.xl,
    maxWidth: breakpoints['2xl'] - 1,
  });

  const isBigScreenResponsive = useMediaQuery({
    minWidth: breakpoints['2xl'],
  });
  return (
    <>
      {isMobile && isMobile === isMobileResponsive && children}
      {isTablet && isTablet === isTabletResponsive && children}
      {isSmallDesktop && isSmallDesktop === isSmallLaptopResponsive && children}
      {isDesktop && isDesktop === isDesktopOrLaptopResponsive && children}
      {isBigScreen && isBigScreen === isBigScreenResponsive && children}
    </>
  );
};

export default Responsive;

import React, { FC } from 'react';
import { AuthRole, withAuthorization } from 'authorization';
import { LAUNCHER_DASHBOARD } from '../../../appRoutes';
import DashboardContainer from 'sub-components/DashboardContainer';
import { EditorForm } from './editor-form';

interface IProps {}

const LauncherEditor: FC<IProps> = () => {
  return (
    <DashboardContainer>
      <EditorForm />
    </DashboardContainer>
  );
};

export default LauncherEditor;

// export default withAuthorization({
//   permittedFor: ['user'],
//   permittedRoles: [AuthRole.SUPER_ADMIN, AuthRole.ADMIN],
//   redirectTo: LAUNCHER_URL,
// })(LauncherEditor);

import React, { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { FormName } from 'pages/forms/modules';

import { CommonListItem } from '../common';
import { TaskFormItem } from '../formChapter.graphql';

const colors = ['#B5E4CAB2', '#FFD88D99', '#B1E5FC', '#CABDFFB2'];

const FormType: React.FC<{ type: string; index: number }> = ({
  type,
  index,
}) => {
  const backgroundColor = colors[index % colors.length];
  return (
    <Box
      bg={backgroundColor}
      px={2}
      py={1}
      borderRadius='md'
      width='fit-content'
    >
      <Text as='b'>{type}</Text>
    </Box>
  );
};

interface IProps {
  data: TaskFormItem;
  index: number;
}

const FormListItem: FC<IProps> = ({ data, index }) => {
  return (
    <CommonListItem data={data}>
      <Flex flex={2} alignItems='center' overflow='hidden'>
        <FormName formData={data as never} />
      </Flex>
      <Box flex={1}>
        <FormType type={data.category} index={index} />
      </Box>
    </CommonListItem>
  );
};

export default FormListItem;

import React, { ChangeEventHandler, FC, useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import SearchInput from '../../../../../atoms/SearchInput';
import Dropdown, { SelectOption } from '../../../../../atoms/Dropdown';

export interface SearchFilterProps {
  searchQuery?: string;
  onSearch?: ChangeEventHandler<HTMLInputElement>;
  sortBy?: SelectOption | null;
  onSortChange?: (value: SelectOption | null) => void;
  placeholder?: string;
}

const SearchAndFilter: FC<SearchFilterProps> = ({
  searchQuery,
  onSearch,
  sortBy,
  onSortChange,
  placeholder,
}) => {
  const { t } = useTranslation(['common', 'handbook']);

  const _onSortChange = useCallback(
    (option: SelectOption) => {
      if (option?.value === sortBy?.value) {
        onSortChange?.(null);
      } else {
        onSortChange?.(option);
      }
    },
    [sortBy, onSortChange]
  );

  return (
    <Flex justify='space-between' gap='12px' mb='8px'>
      <SearchInput
        disabled={true}
        size='lg'
        width='350px'
        hideShortcuts={true}
        placeholder={placeholder || t('common:search')}
        onChange={onSearch}
        value={searchQuery}
      />
      <Flex gap='12px' display='none'>
        <Box width='210px' zIndex='100'>
          <Dropdown
            placeholder={t('handbook:select_filter')}
            value={sortBy}
            onChange={_onSortChange}
            options={[
              {
                label: t('handbook:filter.last_created'),
                value: 'Last Created',
              },
              {
                label: t('handbook:filter.alphabetically'),
                value: 'Alphabetically',
              },
            ]}
            selectStyles={{
              singleValue: {
                color: '#6f767e',
                fontWeight: '600',
              },
            }}
            components={{
              SingleValue: (valProps) => {
                return (
                  <chakraComponents.SingleValue {...valProps}>
                    {/* @ts-ignore */}
                    {t('handbook:sort_by', {
                      value: valProps.children,
                    })}
                  </chakraComponents.SingleValue>
                );
              },
            }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default SearchAndFilter;

import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ChangeLogListWrapper } from './ChangeLogList.styles';
import ChangeLogTable from './ChangeLogTable';
import InfoIcon from '../../../../assets/images/info.svg';
import PrimaryButton from 'atoms/PrimaryButton';
import { GET_TRAINING_CHANGE_LOG } from '../../training-draft-queries-mutations/training-draft-graphql';
import {
  IChangeLogData,
  IChangeLogLocationsEntity,
  IChangeLogTableChangesEntity,
} from '../../training-draft-types/training-draft-types';

import Loader from 'ui-components/Loader';
import { Center, Flex } from '@chakra-ui/react';

const ChangeLogList: FC = ({ selectedDraftData, onClose, isReadOnly }: any) => {
  const { t } = useTranslation('training');
  const { t: tc } = useTranslation('common');
  const [changeLogData, setChangeLogData] = useState<IChangeLogData[]>([]);
  useEffect(() => {
    if (selectedDraftData && selectedDraftData?.eid) {
      getTrainingChangeLog({
        variables: {
          trainingId: selectedDraftData?.eid,
        },
      });
    }
  }, [selectedDraftData]);

  const [getTrainingChangeLog, { loading: trainingChangeLogLoading }] =
    useLazyQuery(GET_TRAINING_CHANGE_LOG, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        let _trainingChangeLog = JSON.parse(
          JSON.stringify(data?.TrainingChangeLog)
        );
        _trainingChangeLog.forEach((change) => {
          change.convertedDate = moment(change.updatedAt).format(
            'MMM DD, YYYY'
          );
        });
        let ObjMap: any = {};
        _trainingChangeLog.forEach((element: any) => {
          var makeKey = element.convertedDate;
          if (!ObjMap[makeKey]) {
            ObjMap[makeKey] = [];
          }
          ObjMap[makeKey].push(element);
        });
        let createdObject: IChangeLogData[] = [];
        Object.entries(ObjMap).map((val: any) => {
          createdObject.push({
            date: val[0],
            data: val[1],
          });
        });
        let sortedCreatedObject: IChangeLogData[] = createdObject.sort(
          (a, b) =>
            +moment(b?.date).format('YYYYMMDD') -
            +moment(a?.date).format('YYYYMMDD')
        );
        let _sortedCreatedObject: IChangeLogData[] = [];
        sortedCreatedObject?.map((sorted) => {
          sorted?.data?.map((sortedData) => {
            let _locationsAddedArray: IChangeLogLocationsEntity[] = [];
            let _locationsRemovedArray: IChangeLogLocationsEntity[] = [];
            let _supervisorsRemovedArray: IChangeLogLocationsEntity[] = [];
            let _supervisorsAddedArray: IChangeLogLocationsEntity[] = [];
            let _assigneesRemovedArray: IChangeLogLocationsEntity[] = [];
            let _assigneesAddedArray: IChangeLogLocationsEntity[] = [];
            let _cardsRemovedArray: IChangeLogLocationsEntity[] = [];
            let _cardsAddedArray: IChangeLogLocationsEntity[] = [];
            let _decksRemovedArray: IChangeLogLocationsEntity[] = [];
            let _decksAddedArray: IChangeLogLocationsEntity[] = [];
            let _milestonesRemovedArray: IChangeLogLocationsEntity[] = [];
            let _milestonesAddedArray: IChangeLogLocationsEntity[] = [];
            let _changes: IChangeLogTableChangesEntity[] = [];
            sortedData?.changes?.map((change) => {
              if (change?.changeEvent === 'locationAdded') {
                _locationsAddedArray.push({
                  eid: change?.locationId,
                  name: change?.meta?.name,
                  profilePic: change?.meta?.profilePic,
                });
              } else if (change?.changeEvent === 'locationRemoved') {
                _locationsRemovedArray.push({
                  eid: change?.locationId,
                  name: change?.meta?.name,
                  profilePic: change?.meta?.profilePic,
                });
              } else if (change?.changeEvent === 'supervisorRemoved') {
                _supervisorsRemovedArray.push({
                  eid: change?.userId!,
                  name: change?.meta?.name,
                  profilePic: change?.meta?.profilePic,
                });
              } else if (change?.changeEvent === 'supervisorAdded') {
                _supervisorsAddedArray.push({
                  eid: change?.userId!,
                  name: change?.meta?.name,
                  profilePic: change?.meta?.profilePic,
                });
              } else if (change?.changeEvent === 'assigneeAdded') {
                _assigneesAddedArray.push({
                  eid: change?.userId!,
                  name: change?.meta?.name,
                  profilePic: change?.meta?.profilePic,
                });
              } else if (change?.changeEvent === 'assigneeRemoved') {
                _assigneesRemovedArray.push({
                  eid: change?.userId!,
                  name: change?.meta?.name,
                  profilePic: change?.meta?.profilePic,
                });
              } else if (change?.changeEvent === 'cardAdded') {
                _cardsAddedArray.push({
                  eid: change?.contentId,
                  name: change?.meta?.title,
                  profilePic: change?.meta?.profilePic,
                });
              } else if (change?.changeEvent === 'cardRemoved') {
                _cardsRemovedArray.push({
                  eid: change?.contentId,
                  name: change?.meta?.title,
                  profilePic: change?.meta?.profilePic,
                });
              } else if (change?.changeEvent === 'deckAdded') {
                _decksAddedArray.push({
                  eid: change?.contentId,
                  name: change?.meta?.title,
                  profilePic: change?.meta?.profilePic,
                });
              } else if (change?.changeEvent === 'deckRemoved') {
                _decksRemovedArray.push({
                  eid: change?.contentId,
                  name: change?.meta?.title,
                  profilePic: change?.meta?.profilePic,
                });
              } else if (change?.changeEvent === 'milestoneAdded') {
                _milestonesAddedArray.push({
                  eid: '',
                  name: change?.title?.new!,
                  profilePic: '',
                });
              } else if (change?.changeEvent === 'milestoneRemoved') {
                _milestonesRemovedArray.push({
                  eid: '',
                  name: change?.title?.new!,
                  profilePic: '',
                });
              } else {
                _changes.push(change);
              }
            });
            if (_locationsAddedArray.length > 0) {
              _changes.push({
                changeElement: 'location',
                changeEvent: 'locationAdded',
                locations: _locationsAddedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_locationsRemovedArray.length > 0) {
              _changes.push({
                changeElement: 'location',
                changeEvent: 'locationRemoved',
                locations: _locationsRemovedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_supervisorsRemovedArray.length > 0) {
              _changes.push({
                changeElement: 'supervisor',
                changeEvent: 'supervisorRemoved',
                supervisors: _supervisorsRemovedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_supervisorsAddedArray.length > 0) {
              _changes.push({
                changeElement: 'supervisor',
                changeEvent: 'supervisorAdded',
                supervisors: _supervisorsAddedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_assigneesAddedArray.length > 0) {
              _changes.push({
                changeElement: 'assignee',
                changeEvent: 'assigneeAdded',
                assignees: _assigneesAddedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_assigneesRemovedArray.length > 0) {
              _changes.push({
                changeElement: 'assignee',
                changeEvent: 'assigneeRemoved',
                assignees: _assigneesRemovedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_cardsAddedArray.length > 0) {
              _changes.push({
                changeElement: 'card',
                changeEvent: 'cardAdded',
                cards: _cardsAddedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_cardsRemovedArray.length > 0) {
              _changes.push({
                changeElement: 'card',
                changeEvent: 'cardRemoved',
                cards: _cardsRemovedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_decksAddedArray.length > 0) {
              _changes.push({
                changeElement: 'deck',
                changeEvent: 'deckAdded',
                decks: _decksAddedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_decksRemovedArray.length > 0) {
              _changes.push({
                changeElement: 'deck',
                changeEvent: 'deckRemoved',
                decks: _decksRemovedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_milestonesAddedArray.length > 0) {
              _changes.push({
                changeElement: 'milestone',
                changeEvent: 'milestoneAdded',
                milestones: _milestonesAddedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            if (_milestonesRemovedArray.length > 0) {
              _changes.push({
                changeElement: 'milestone',
                changeEvent: 'milestoneRemoved',
                milestones: _milestonesRemovedArray,
                comment: '',
                contentId: '',
                icon: { old: null, new: null },
                locationId: '',
                meta: {
                  name: '',
                  profilePic: '',
                  thumbnail: '',
                  title: '',
                  type: '',
                },
                milestone: { old: null, new: null },
                roles: [],
                title: { old: null, new: null },
                userId: null,
              });
            }
            sortedData.changes = _changes;
          });
        });
        setChangeLogData(sortedCreatedObject);
      },
    });

  return (
    <ChangeLogListWrapper>
      {changeLogData.map((log, index) => (
        <div
          key={index + log?.date}
          className='change-log-progress-table-container'
        >
          <div className='progress-bar-change-log-container'>
            {index + 1 < changeLogData.length && (
              <span className='progress-bar-change-log' />
            )}
          </div>
          <div className='log-table-container'>
            <div className='date-header'>
              <div className='log-table-progress-ball' />
              {t('changes_on_date', {
                date: log?.date,
              })}
            </div>
            <div>
              <ChangeLogTable data={log?.data} />
              {/* {log?.changes.map((change, index) => {
              })} */}
            </div>
          </div>
        </div>
      ))}
      {changeLogData?.length > 0 && (
        <div className='info-change-log'>
          <img src={InfoIcon} className='info-icon' />
          {/* <p>This path was originally created by <strong>{selectedDraftData?.createdBy?.name}.</strong>{!isReadOnly && ', are you sure you want to publish?'}</p> */}
          <p>
            {t(isReadOnly ? 'path_created_by' : 'path_created_by_sure', {
              userName: selectedDraftData?.createdBy?.name,
            })}
          </p>
        </div>
      )}
      <Center>{trainingChangeLogLoading && <Loader />}</Center>
      {changeLogData?.length === 0 && !trainingChangeLogLoading && (
        <Center fontWeight='600' fontSize='16px'>
          {t('no_logs_found')}
        </Center>
      )}
      <Flex justify='flex-end' gap='10px'>
        {isReadOnly && (
          <PrimaryButton
            title={tc('close')}
            onClick={() => onClose('close')}
            style={{
              width: '160px',
              height: '48px',
              color: 'black',
              backgroundColor: '#fcfcfc',
              border: 'solid 2px #efefef',
            }}
          />
        )}
        {!isReadOnly && (
          <PrimaryButton
            title={t('no_save_as_draft_button')}
            onClick={() => onClose('draft')}
            style={{
              width: '160px',
              height: '48px',
              color: 'black',
              backgroundColor: '#fcfcfc',
              border: 'solid 2px #efefef',
            }}
          />
        )}
        {!isReadOnly && (
          <PrimaryButton
            title={tc('yes')}
            onClick={() => onClose('publish')}
            style={{
              width: '160px',
              height: '48px',
              color: 'white',
              backgroundColor: '#2a85ff',
              border: 'solid 2px #efefef',
            }}
          />
        )}
      </Flex>
    </ChangeLogListWrapper>
  );
};

export default ChangeLogList;

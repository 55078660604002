import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlay, faFileZipper } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PdfIcon from '../assets/images/chapter-editor/pdf-file.svg';
import React from 'react';

/**
 * Get thumbnail for a given file
 * @param file - The file object received from input[type="file"].
 * @returns A promise that resolves to a React component or a Base64 string.
 */

export const getThumbnail = (
  file: File
): Promise<{ type: string; content: string | React.ReactNode }> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject('No file provided');
    }

    const fileType = file.type;
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    // If file is an image, return its Base64 string
    if (/image\/*/.test(fileType)) {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve({
          type: 'image',
          content: (
            <Image
              src={event?.target?.result as string}
              boxSize='40px'
              borderRadius='8px'
            />
          ),
        });
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    }
    // For PDFs
    else if (fileExtension === 'pdf') {
      resolve(
        {
          type: 'pdf',
          content: (
            <Center bg='#FFECEC' p={0} boxSize='48px' borderRadius='6px'>
              <Image src={PdfIcon} height='28px' width='28px' />
            </Center>
          ),
        }
        // <Box
        //   border='1px solid #6F767E'
        //   borderRadius='8px'
        //   w='fit-content'
        //   p={'5px 10px'}
        //   color='#6F767E'
        // >
        //   <FontAwesomeIcon icon={faFilePdf as IconProp} fontSize='20px' />
        // </Box>
      );
    }
    // For ZIP files
    else if (fileExtension === 'zip') {
      resolve({
        type: 'zip',
        content: (
          <Center
            border='1px solid #6F767E'
            color='#6F767E'
            boxSize='40px'
            borderRadius='8px'
          >
            <FontAwesomeIcon icon={faFileZipper as IconProp} fontSize='20px' />
          </Center>
        ),
      });
    } else if (fileExtension === 'mp4') {
      resolve({
        type: 'video',
        content: (
          <Center bg='black' boxSize='40px' borderRadius='8px'>
            <FontAwesomeIcon
              icon={faCirclePlay as IconProp}
              color='white'
              fontSize='20px'
            />
          </Center>
        ),
      });
    }
    // Add more file types if needed...
    else {
      resolve({
        type: 'na',
        content: <FontAwesomeIcon icon={faCircleExclamation as IconProp} />,
      });
    }
  });
};

import React, { FC, useState } from 'react';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Authorize, AuthRole } from '../../../authorization';
import { MessageButton } from '../../../atoms';
import { CHAT_URL } from '../../../appRoutes';

import InviteUserModal from 'ui-components/InviteUserNew/InviteUserModal';

import noImage from '../../../assets/images/placeholder/empty-member.svg';

interface IProps {
  shouldRefetch?: () => void;
}

const EmptyMembers: FC<IProps> = ({ shouldRefetch }) => {
  const { t } = useTranslation(['common', 'team']);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const history = useHistory();

  const onSendMessageClick = () => {
    history.push(CHAT_URL, {
      // chatUserId: '',
    });
  };

  const onInviteClick = () => {
    setShowInviteModal(true);
  };

  const onActionCompleted = () => {
    shouldRefetch && shouldRefetch();
  };

  return (
    <Flex direction='column' gap='14px'>
      <Image src={noImage} maxW='300px' mx='auto' />
      <Flex direction='column' textAlign='center' gap='4px'>
        <Authorize
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          permittedFor='user'
          renderNoAccess={() => {
            return (
              <Box fontSize='15px' fontWeight='700' color='#303030'>
                {t('team:noMemberPresentLocation')}
              </Box>
            );
          }}
        >
          <Box fontSize='15px' fontWeight='700' color='#303030'>
            {t('team:noMemberPresent')}
          </Box>
        </Authorize>

        <Authorize
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
          permittedFor='user'
        >
          <Box fontSize='11px' fontWeight='500' color='#9E9E9E' px='12px'>
            {t('team:noMemberPresentHelp')}
          </Box>
        </Authorize>

        <Authorize permittedFor='branch'>
          <Box fontSize='11px' fontWeight='500' color='#9E9E9E' px='12px'>
            {t('team:noMemberPresentLocHelp')}
          </Box>
        </Authorize>
      </Flex>
      <Authorize
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ]}
        permittedFor='user'
      >
        <Flex gap='10px' justify='center'>
          <Button
            leftIcon={<AddIcon />}
            w='max-content'
            px='16px'
            colorScheme='blue'
            maxW='full'
            fontSize='15px'
            minW='160px'
            onClick={onInviteClick}
          >
            {t('common:invite')}
          </Button>
        </Flex>
      </Authorize>

      <Authorize permittedFor='branch'>
        <Flex gap='10px' justify='center'>
          <MessageButton
            colorScheme='blue'
            leftIcon={<AddIcon />}
            onClick={onSendMessageClick}
          />
        </Flex>
      </Authorize>
      {showInviteModal && (
        <InviteUserModal
          isOpen={showInviteModal}
          onClose={() => setShowInviteModal(false)}
          onActionCompleted={onActionCompleted}
          shouldRefetch={shouldRefetch}
        />
      )}
    </Flex>
  );
};

export default EmptyMembers;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CSSObject,
  Divider,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import TitleHeader from '../../../CardEditor/TitleHeader';
import OnceContainer from './OnceContainer';
import MultiTimesContainer from './MultiTimesContainer';
import { IFormInput } from '../task.types';
import SchedulerInfoText from './SchedulerInfoText';

const getCheckedStyle = (isEdit?: boolean): CSSObject => {
  if (isEdit) {
    return {
      borderColor: '#6F767E',
      background: 'white',
      color: '#6F767E',
      _hover: {
        background: 'white',
      },
    };
  }

  return {
    borderColor: 'blue.500',
    background: 'white',
    color: 'blue.500',
    _hover: {
      background: 'white',
    },
  };
};

interface IProps {
  isEdit?: boolean;
}

const SchedulerForm: FC<IProps> = ({ isEdit }) => {
  const { t } = useTranslation(['task']);
  const { control } = useFormContext<IFormInput>();

  const taskRunFor = useWatch<IFormInput, 'taskRunFor'>({
    name: 'taskRunFor',
  });

  return (
    <Box pt='20px'>
      <TitleHeader title={t('task:howOftenNeedTask')} />

      <Controller
        control={control}
        name='taskRunFor'
        defaultValue='once'
        render={({ field, fieldState }) => {
          return (
            <FormControl my={3} isReadOnly={isEdit}>
              <RadioGroup {...field}>
                <Stack
                  spacing={6}
                  direction='row'
                  sx={{
                    '.chakra-radio': {
                      cursor: isEdit ? 'not-allowed' : undefined,
                    },
                    '.chakra-radio__label': {
                      color: '#6F767E',
                      fontSize: 14,
                      fontWeight: 500,
                      _checked: {
                        color: isEdit ? '#6F767E' : '#2A85FF',
                        fontWeight: 600,
                      },
                    },
                    '.chakra-radio__control': {
                      _checked: getCheckedStyle(isEdit),
                    },
                  }}
                >
                  <Radio value='once'>{t('task:runOnce')}</Radio>
                  <Radio value='repeat'>{t('task:repeatMultiple')}</Radio>
                </Stack>
              </RadioGroup>
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Divider pt={2} mb={3} />

      {taskRunFor === 'once' && <OnceContainer isEdit={isEdit} />}

      {taskRunFor === 'repeat' && <MultiTimesContainer isEdit={isEdit} />}

      <SchedulerInfoText />
    </Box>
  );
};

export default SchedulerForm;

import { TrainingPathItem } from '../types';
import { toArray } from '../../../../utils/utils';
import { TrainingPathModal } from './training-path';

export class TrainingPathListModal {
  protected _data: TrainingPathModal[];
  constructor(props: TrainingPathItem[] = []) {
    this._data = toArray(props).map((value) => new TrainingPathModal(value));
  }

  get data() {
    return this._data;
  }

  get length() {
    return this._data?.length || 0;
  }
}

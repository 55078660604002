import React, { FC } from 'react';
import { Box, CSSObject, Divider, Flex } from '@chakra-ui/react';

import { ReactComponent as DeleteIcon } from '../../../../assets/images/trash.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/images/copy.svg';

export const notLastStyle: CSSObject = {
  position: 'relative',
  ':after': {
    content: "''",
    position: 'absolute',
    right: '-16px',
    top: '50%',
    width: '1.5px',
    height: '20px',
    background: '#BEBEBE',
    transform: 'translateY(-10px)',
  },
};

export interface BQuesFooterProps {
  currentIndex: number;
  isReadOnly?: boolean;
  handleRemove: (index: number) => void;
  handleDuplicate: (index: number) => void;
}

const BaseQuestionFooter: FC<BQuesFooterProps> = ({
  currentIndex,
  isReadOnly,
  handleDuplicate,
  handleRemove,
  children,
}) => {
  return (
    <>
      <Box pt='20px' pb='16px'>
        <Divider position='absolute' left='0px' w='full' />
      </Box>
      <Flex justify='flex-end' align='center' gap={8}>
        <Flex gap='16px' _notLast={notLastStyle}>
          <CopyIcon
            cursor={!isReadOnly ? 'pointer' : 'no-drop'}
            onClick={() => !isReadOnly && handleDuplicate(currentIndex)}
          />
          <DeleteIcon
            cursor={!isReadOnly ? 'pointer' : 'no-drop'}
            onClick={() => !isReadOnly && handleRemove(currentIndex)}
          />
        </Flex>

        {children}
      </Flex>
    </>
  );
};

export default BaseQuestionFooter;

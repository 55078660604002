import React, { FC } from 'react';
import { Button, Center, Tooltip } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  certificateUrl?: string;
  isCompleted?: boolean;
}

const DownloadButton: FC<IProps> = ({ certificateUrl, isCompleted }) => {
  return (
    <Center w='full' marginBottom='25px'>
      <Tooltip
        hasArrow
        // label={t(
        //   isCompleted ? 'download_certificate' : 'download_training_certificate'
        // )}
        placement='bottom-start'
        borderRadius='4px'
      >
        {/*<DownloadIcon*/}
        {/*  cursor={isCompleted ? 'pointer' : 'no-drop'}*/}
        {/*  fontSize='large'*/}
        {/*  color={isCompleted ? '#2a85ff' : 'lightgray'}*/}
        {/*  onClick={() => {*/}
        {/*    isCompleted && window.open(certificateUrl);*/}
        {/*  }}*/}
        {/*/>*/}

        <Button
          fontSize='12px'
          fontWeight='600'
          variant='outline'
          size='sm'
          leftIcon={<FontAwesomeIcon icon={faArrowDownToLine as IconProp} />}
          onClick={() => {
            isCompleted && window.open(certificateUrl);
          }}
        >
          Download
        </Button>
      </Tooltip>
    </Center>
  );
};

export default DownloadButton;

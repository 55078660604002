import { FC } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import './ProfilePopup.scss';

import { ReactComponent as LogoutIcon } from '../../assets/images/logout.svg';
import { ReactComponent as SinglePersonIcon } from '../../assets/images/singlePerson.svg';
import { Link } from 'react-router-dom';
import { PROFILE_URL } from 'appRoutes';
import { CometChat } from '@cometchat-pro/chat';

interface IProps {
  logout: () => void;
}

const ProfilePopup: FC<IProps> = ({ children, logout }) => {
  const { t } = useTranslation();

  const onLogout = async () => {
    if (!CometChat.isInitialized()) {
      logout();
    }
    CometChat.logout().then(
      () => {
        console.log('Logout completed successfully');
        logout();
      },
      (error) => {
        console.log('Logout failed with exception:', { error });
        logout();
      }
    );
  };

  return (
    <Box>
      <Popover placement='bottom-start' isLazy>
        {/* @ts-ignore */}
        <PopoverTrigger>{children}</PopoverTrigger>
        <PopoverContent style={{ width: '207px', height: '112px' }}>
          <PopoverBody zIndex={'200'}>
            <Link to={PROFILE_URL}>
              <div className='profile-popup-item-container'>
                <SinglePersonIcon />
                <span className='profile-popup-item-title'>{t('profile')}</span>
              </div>
            </Link>

            <div className='profile-popup-item-container' onClick={onLogout}>
              <LogoutIcon />
              <span className='profile-popup-item-title'>{t('logout')}</span>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
export default ProfilePopup;

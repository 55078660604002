import { gql, useMutation, useQuery, useReactiveVar } from '@apollo/client';
// import Editor from 'delightree-editor';
import { Link } from 'react-router-dom';
import { notification, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { userObj } from 'sop-commons/src/client/clientFactory';
import Lottie from 'react-lottie';
import { faPen, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import editBroken from '../../assets/lotties/editorBroken.json';

import { dashboardRouteVar } from '../../ui-components/MainContainer/MainContainer';

// import css
import './SopContent.css';

// import icons
import backIcon from '../../assets/images/back.svg';
import shareIcon from '../../assets/images/share.svg';
import { ReactComponent as BookmarkSopSvg } from '../../assets/images/bookmarksopIcon.svg';

// import components
import SopRoles from '../SopRoles';
import SopBundles from '../SopBundles';
import Loader from '../Loader';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GET_BOOKMARKED_SOP } from 'ui-components/BookmarkHome/BookmarkHome';

import animationData from '../../assets/lotties/bookmarkLottie.json';
import BookmarkLabel from 'ui-components/BookmarkLabel';
import { UPDATE_SOP_VIEWS_MUTATION } from 'pages/SopDetail/SopDetail';

import EditorElement from '../EditorElement';
import { ADMIN } from 'utils/userRoles';

interface Props {
  sopId?: string;
  isPublic?: boolean;
  publicSopData?: any;
  publicLoading?: boolean;
  roleWithColor?: any;
  bundleWithColor?: any;
  scrollRef?: any;
}

// TODO SopById(eid: $eid) query

export const GET_SOP_BY_ID = gql`
  query ($eid: String!) {
    userById(eid: $eid) {
      eid
    }
  }
`;

export const SET_BOOKMARK = gql`
  mutation ($sopId: String!, $bookmark: Boolean!) {
    setSopBookmark(sopId: $sopId, bookmark: $bookmark) {
      bookmark
      sopId
      userId
    }
  }
`;

const EDIT_SOP = gql`
  mutation updateSop($input: SopUpdateInput) {
    updateSop(input: $input) {
      bundles
      chapter
      raw_content
      entity
      content
    }
  }
`;

const SopContent = ({
  sopId,
  refetchUser,
  isPublic,
  publicSopData,
  publicLoading,
  roleWithColor,
  bundleWithColor,
  scrollRef,
}: Props) => {
  const history = useHistory();
  const [selectedBundles, setSelectedBundles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const [isRequestInitiated, setIsRequestInitiated] = useState(false);
  const { loading, error, data, refetch } = useQuery(GET_SOP_BY_ID, {
    variables: {
      eid: sopId,
    },
    skip: isPublic,
    // fetchPolicy: 'network-only',
  });
  const [updateSop] = useMutation(EDIT_SOP);

  const sopData = isPublic ? publicSopData : data?.SopById;

  const [updateSopViews] = useMutation(UPDATE_SOP_VIEWS_MUTATION);

  const onCopy = () => {
    openNotificationWithIcon();
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: 'Link copy to clipboard',
    });
  };

  useEffect(() => {
    if (refetch && !loading) {
      refetch();
    }
  }, []);
  useEffect(() => {
    if (sopData) {
      updateSopViews({
        variables: {
          sopId: sopId,
        },
      });
    }

    if (sopData?.roles) {
      setSelectedRoles(sopData?.roles);
    }
    if (sopData?.bundles) {
      setSelectedBundles(sopData?.bundles);
    }
    if (sopData?.views?.bookmark) {
      setIsBookmarked(true);
    }
    if (sopData?.content) {
      localStorage.setItem(
        'delightree-id-desktop-edit',
        JSON.stringify(sopData.content)
      );
    }
    if (sopData?.raw_content) {
      localStorage.setItem(
        'delightree-id-desktop-text-edit',
        sopData.raw_content
      );
    }
  }, [sopData]);
  const user: any = useReactiveVar(userObj);
  let isAdmin = false;
  if (user?.authRole === ADMIN) {
    isAdmin = true;
  }

  // const backRoute = useReactiveVar<string>(dashboardRouteVar) || '/dashboard';
  const backRoute = () => {
    if (history.length === 2) {
      history.push('/dashboard');
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    window.addEventListener('message', handler);
  }, []);

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef?.current?.scrollToTop();
    }
  }, [sopId]);

  const handler = (event) => {
    let contentData = event?.data;

    if (contentData) {
      try {
        const data = JSON.parse(event?.data?.message);
        console.log('Content Data', data, data?.sopId, isPublic);
        if (data?.sopId && data?.isSopId) {
          if (isPublic) {
            history.push(`/public/${data?.sopId}`);
          } else {
            history.push(`/sop/${data?.sopId}`);
          }
        }
      } catch (e) {
        console.error('Json Data', contentData);
      }
    }
  };

  // const isBookmarked = sopData?.views?.bookmark;

  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: editBroken,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const onEdit = () => {
    setIsEditable(true);
  };

  const isEditContainsValue = () => {
    const localStorageData: string =
      localStorage.getItem('delightree-id-desktop-edit') || '';
    const editorValue: any = localStorageData
      ? JSON.parse(localStorageData)
      : undefined;
    const editorInText = localStorage.getItem(
      'delightree-id-desktop-text-edit'
    );
    let isDataPresent = false;
    if (editorInText) {
      isDataPresent = true;
    }
    if (!editorInText) {
      editorValue?.content.forEach((value: any) => {
        if (
          value.type === 'custom-google-form' ||
          value.type === 'chapter-link' ||
          value.type === 'resizable-image' ||
          value.type === 'resizable-video' ||
          value.type === 'custom-video' ||
          value.type === 'custom-type-form' ||
          value.type === 'custom-paper-form' ||
          value.type === 'image-gallery'
        ) {
          isDataPresent = true;
          localStorage.setItem('delightree-id-desktop-text-edit', value.type);
        }
      });
    }
    return isDataPresent;
  };

  const onSave = () => {
    setIsEditable(false);
    const localStorageData: string =
      localStorage.getItem('delightree-id-desktop-edit') || '';
    const editorValue: any = localStorageData
      ? JSON.parse(localStorageData)
      : undefined;

    if (isEditContainsValue()) {
      const editorInText = localStorage.getItem(
        'delightree-id-desktop-text-edit'
      );

      updateSop({
        variables: {
          input: {
            sopId: sopId,
            chapter: sopData?.chapter,
            content: editorValue,
            raw_content: editorInText,
          },
        },
      })
        .then(() => {
          notification.success({
            message: 'Sop Updated successfully!',
          });
        })
        .catch((error) => {
          console.error('Error in adding sop', error);
          notification.error({
            message: 'Error in creating sop',
          });
        });
    }
  };

  const onChangeValue = (data: any, dataInText: string) => {
    localStorage.setItem('delightree-id-desktop-edit', JSON.stringify(data));
    localStorage.setItem('delightree-id-desktop-text-edit', dataInText);
  };

  const localStorageData: string =
    localStorage.getItem('delightree-id-desktop-edit') || '';
  const editorValue: any = localStorageData
    ? JSON.parse(localStorageData)
    : undefined;

  return (
    <div style={{ marginRight: '8px' }}>
      {!isPublic && loading && (
        <div className='sop-list-loader'>
          <Loader />
        </div>
      )}
      {isPublic && publicLoading && (
        <div className='sop-list-loader'>
          <Loader />
        </div>
      )}
      {!loading && (
        <div>
          {sopData && (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {!isPublic && (
                  <div
                    className='eventclass-sop-detail-back-button'
                    style={{ cursor: 'pointer' }}
                    onClick={backRoute}
                  >
                    <img src={backIcon} />
                  </div>
                )}
                {isPublic && <div style={{ width: '26px', height: '19px' }} />}

                <div className='sop-content-header-text'>
                  {sopData?.chapter}
                </div>

                {!isPublic && (
                  <>
                    {/* {isEditable && (
                      <FontAwesomeIcon
                        icon={faFloppyDisk}
                        style={{
                          fontSize: 18,
                          marginRight: 24,
                          cursor: 'pointer',
                        }}
                        onClick={onSave}
                      />
                    )}
                    {!isEditable && (
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{
                          fontSize: 18,
                          marginRight: 24,
                          cursor: 'pointer',
                        }}
                        onClick={onEdit}
                      />
                    )} */}

                    <BookmarkLabel
                      sopId={sopId}
                      isBookmarked={isBookmarked}
                      setIsBookmarked={setIsBookmarked}
                    />

                    <CopyToClipboard
                      text={`${process.env.REACT_APP_FRONTEND_URL}/public/${sopId}`}
                      onCopy={onCopy}
                    >
                      <div style={{ cursor: 'pointer' }}>
                        <img src={shareIcon} />
                      </div>
                    </CopyToClipboard>
                  </>
                )}
              </div>
              <div className='sop-content-category-container'>
                <div>
                  in{' '}
                  <Link to={`/dashboard?category=${sopData?.category}`}>
                    <span className='sop-content-category-text'>
                      {sopData?.category}
                    </span>
                  </Link>
                </div>
              </div>
              <div className='eventclass-sop-content-container sop-content-doc-container'>
                <div style={{ width: '71%' }}>
                  {/* {!isEditable && <Editor readonly value={editorValue} />} */}
                  {isEditable && (
                    <EditorElement
                      editorValue={editorValue}
                      businessId={sopData?.entity}
                      onChangeValue={onChangeValue}
                    />
                  )}
                </div>
                <div
                  className='eventclass-sop-content-role-bundle-tags'
                  style={{ width: '32%', marginLeft: '90px' }}
                >
                  <SopRoles
                    roles={selectedRoles}
                    sopId={sopId}
                    chapter={sopData?.chapter}
                    setSelectedRoles={setSelectedRoles}
                    isAdmin={isAdmin}
                    isPublic={isPublic}
                    roleWithColor={roleWithColor}
                  />
                  <SopBundles
                    bundles={selectedBundles}
                    style={{ marginTop: '45px' }}
                    sopId={sopId}
                    chapter={sopData?.chapter}
                    setSelectedBundles={setSelectedBundles}
                    isAdmin={isAdmin}
                    refetchUser={refetchUser}
                    isPublic={isPublic}
                    bundleWithColor={bundleWithColor}
                  />
                </div>
              </div>
            </>
          )}
          {!sopData && !publicLoading && (
            <div
              style={{
                display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {!isPublic && (
                <div
                  className='eventclass-sop-detail-back-button'
                  style={{ cursor: 'pointer' }}
                  onClick={backRoute}
                >
                  <img src={backIcon} />
                </div>
              )}

              <div
                style={{
                  marginBottom: '20px',
                  fontSize: '30px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginTop: '30px',
                }}
              >
                Whoops! This page is not available
              </div>
              <Lottie
                options={defaultUploadLottieOptions}
                height={250}
                width={400}
                style={{ marginTop: '30px' }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SopContent;

import { match, P } from 'ts-pattern';

const Excel = [
  'vnd.ms-excel',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const Word = [
  'msword',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export type ChapterFileType = 'docs' | 'xlsx' | 'video' | 'pdf' | 'file';

export const getFileType = (fileType = ''): ChapterFileType => {
  return match<string[], ChapterFileType>(fileType?.split('/'))
    .with(
      ['application', P.when((value) => Word.includes(value))],
      () => 'docs'
    )
    .with(
      ['application', P.when((value) => Excel.includes(value))],
      () => 'xlsx'
    )
    .with(['video', P._], () => 'video')
    .with(['application', 'pdf'], () => 'pdf')
    .otherwise(() => 'file');
};

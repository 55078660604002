import styled from '@emotion/styled';

export const MultiSelectChipDropdownWrapper = styled.div`
    .multi-selection-container {

        position: absolute;
        max-height: 170px;
        min-height: 50px;
        width: 100%;
        background-color: white;
        z-index: 1;
        margin-top: 5px;
        border-radius: 5px;
        overflow-y: auto;
        border: 1px solid rgb(227 227 227);

        ::-webkit-scrollbar {
            width: 4px;
            height: 0px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 4px;
        }
        ::-webkit-scrollbar-thumb {
            background: #d5d5d5;
            border-radius: 4px;
        }
    }
    .multi-selection-list {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding-inline-start: 0.8rem;
        padding-inline-end: 0.8rem;
        transition-property: background-color,background-image,background-position;
        transition-duration: 50ms;
        transition-timing-function: cubic-bezier(0, 4, 0, 1, 1);
        width: 100%;
        font-size: 1rem;
        padding: 0.4rem 0.8rem;

        :hover {
            background-color: #f4f4f4;
        }
    }
`;
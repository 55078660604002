import React, { FC, useContext, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { PreviewContext } from '../../../../../../pages/Training/CreateTrainingPath/Preview/context';
import { CommonWrapper } from '../../../../../../pages/Training/CreateTrainingPath/Preview/component/common';
import { CommonWrapperProps } from '../../../../../../pages/Training/CreateTrainingPath/Preview/component/common/CommonWrapper';
import MobilePreviewNavigation from './MobilePreviewNavigation';

interface IProps extends CommonWrapperProps {}

const MobilePreview: FC<IProps> = ({ value, isPreview, isNotDesktop }) => {
  return (
    <Box position='relative' h='full'>
      <Box
        pos='relative'
        // borderRadius='24px'
        bg='#ffffff'
        overflow='hidden'
        h='full'
      >
        <Flex flexDir='column' overflow='auto' h='full'>
          {/*<MobileHeader*/}
          {/*  title={title}*/}
          {/*  currentIndex={selectedIndex + 1}*/}
          {/*  totalCards={totalCards}*/}
          {/*/>*/}
          <Box flex={1} p='16px' pt='10px' mb='36px'>
            <CommonWrapper
              value={value}
              isPreview={isPreview}
              isNotDesktop={isNotDesktop}
            />
          </Box>
        </Flex>
        <MobilePreviewNavigation />
      </Box>
    </Box>
  );
};

const MobilePreviewContainer: FC = () => {
  const { trainingContents, selectedIndex } = useContext(PreviewContext);

  const rawContent = useMemo(() => {
    return trainingContents?.[selectedIndex];
  }, [trainingContents, selectedIndex]);

  return <MobilePreview value={rawContent} isNotDesktop />;
};

export default MobilePreviewContainer;

import React, { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import {
  mapUserFormResponse,
  UserFormResponse,
} from '../../../../../pages/forms/FormResponse';
import {
  IUserFormResponse,
  USER_FORM_RESPONSE_QUERY,
  UserFormResVariable,
} from './response.graphql';
import { toArray } from '../../../../../utils/utils';

interface IProps {
  formId: string;
  userId: string;
}

const ResponseContainer: FC<IProps> = ({ formId, userId }) => {
  const { loading, data } = useQuery<IUserFormResponse, UserFormResVariable>(
    USER_FORM_RESPONSE_QUERY,
    {
      fetchPolicy: 'network-only',
      skip: !formId && !userId,
      variables: {
        filter: {
          formId: formId,
          userId: toArray(userId),
        },
      },
    }
  );

  const form = data?.FormResponse?.items[0]?.form;
  const response = data?.FormResponse?.items[0]?.response;

  const formResponse = useMemo(() => {
    return mapUserFormResponse(toArray(form?.questions), toArray(response));
  }, [form?.questions, response]);

  return (
    <Flex flexDir='column' height='full' gap='16px'>
      <Flex flexDir='column' gap='2px'>
        <Box fontSize='20px' fontWeight='700' color='#000000'>
          {form?.title}
        </Box>
        <Box fontSize='16px' fontWeight='400' color='#272B30'>
          {form?.description}
        </Box>
      </Flex>

      <UserFormResponse
        loading={loading}
        response={formResponse}
        userList={form?.userList}
      />
    </Flex>
  );
};

export default ResponseContainer;

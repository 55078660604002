import React, { CSSProperties, FC } from "react";
import { ReactComponent as CardPreviewEmpty } from "../assets/cardPreview.svg";
import { ReactComponent as ImagePreviewEmpty } from "../assets/imagePreview.svg";

interface IProps {
  backgroundColor?: string;
  style?: CSSProperties;
  type?: string;
}

const NoContent: FC<IProps> = ({ backgroundColor, style, type }) => {
  // const content = type === 'video' ? <ImagePreviewEmpty /> : <CardPreviewEmpty />;
  const content = <CardPreviewEmpty />;
  return (
    <div
      style={{
        backgroundColor: backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "inherit",
        ...style,
      }}
    >
      {content}
    </div>
  );
};

export default NoContent;

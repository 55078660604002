import React, { FC } from 'react';
import { Box, Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';

interface IProps {
  isLoaded?: boolean;
}

const UserDetailSkeleton: FC<IProps> = ({ isLoaded, children }) => {
  if (isLoaded) {
    return <>{children}</>;
  }

  return (
    <Flex flex={1} maxW='360px' flexDir='column' gap='16px'>
      <Flex gap={4} align='center'>
        <SkeletonCircle size='60px' borderRadius='10px' />

        <Flex flexDirection='column' gap='10px'>
          <Skeleton height='10px' borderRadius='full' />
          <Flex gap='5px' align='center'>
            <Skeleton h='8px' w='40px' borderRadius='full' />
            <Skeleton boxSize='6px' borderRadius='full' />
            <Skeleton h='8px' w='60px' borderRadius='full' />
            <Box w='30px' />
          </Flex>
        </Flex>
      </Flex>

      <Flex align='center' gap={2}>
        <Skeleton boxSize='16px' borderRadius='4px' />
        <Skeleton h='8px' w='100px' borderRadius='full' />
      </Flex>
    </Flex>
  );
};

export default UserDetailSkeleton;

import React, { FC } from 'react';
import { Box, Center, Flex, Spacer } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../ui-components';
import { IFormInput } from '../editor-form/editor.types';
import { LauncherConfig } from '../common';
import { useEditorContext } from '../editor-form';

interface IProps {
  onViewModeChange: (...args: any[]) => any | PromiseLike<any>;
}

const Header: FC<IProps> = ({ onViewModeChange }) => {
  const { t } = useTranslation(['launcher']);
  const history = useHistory<Record<'templatedData', LauncherConfig>>();

  const { initialising } = useEditorContext();

  const launchId = useWatch<IFormInput, 'eid'>({
    name: 'eid',
  });

  const title = history.location?.state?.templatedData?.title;

  const hideButton = initialising || launchId;

  return (
    <Flex align='center' mb={5} gap={2}>
      {!hideButton && (
        <Center
          height='36px'
          alignSelf='start'
          onClick={history.goBack}
          cursor='pointer'
        >
          <ArrowBackIcon color='#000000' boxSize='25px' />
        </Center>
      )}
      <Box>
        <Box fontSize='24px' fontWeight='700' color='#272B30'>
          Launcher task checklists
        </Box>
        <Box fontSize='15px' fontWeight='400' color='#6F767E'>
          {title}
        </Box>
      </Box>
      <Spacer />
      <ActionButton
        size='lg'
        width='fit-content'
        variant='outline'
        fontSize='15px'
        style={{
          backgroundColor: 'white',
          color: '#33383F',
          fontWeight: 500,
          minWidth: '220px',
        }}
        actionFn={onViewModeChange}
      >
        {t('launcher:openInEditorMode')}
      </ActionButton>
    </Flex>
  );
};

export default Header;

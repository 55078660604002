import React, { FC } from 'react';
import { Box, Center, Flex, Image as NativeImage } from '@chakra-ui/react';
import { HandbookData } from '../handbook.types';
import { HandbookImage } from './HandbookcardType';
import { CardType } from '../../../types';
import { CardModal, DeckModal } from '../../../modal';
import Image from '../../../ui-components/Image';
import logo from '../../../assets/images/ic_logo.png';

const colorSetHandler = (index: number) => {
  let colorObj = [
    '#8830b0',
    '#30048c',
    '#60b569',
    '#02ade5',
    '#c7c37b',
    '#cc86a9',
    '#8b78e6',
    '#2578ae',
  ];
  return colorObj[index % 8];
};

interface IconDisplayProps {
  cardType?: CardType;
}

const IconDisplay: FC<IconDisplayProps> = ({ cardType }) => {
  const icon = HandbookImage[cardType as never];

  if (!icon) {
    return null;
  }

  return (
    <Box pos='absolute' top='4px' left='4px'>
      <NativeImage src={icon} h='20px' w='20px' />
    </Box>
  );
};

interface IProps {
  index: number;
  title: string;
  item?: DeckModal | CardModal;
  thumbnail?: string;
  category?: string;
  status?: string;
  onClick?: (data?: DeckModal | CardModal) => void;
  isFromDeck?: boolean;
  cardTypeTitle?: string;
}

const HandbookName: FC<IProps> = ({
  item,
  index,
  thumbnail,
  title: _title,
  category,
  status,
  onClick,
  cardTypeTitle,
}) => {
  const title = _title?.trim();
  return (
    <Flex
      align='flex-start'
      style={{
        backgroundColor: status === 'active' ? 'none' : 'white',
        opacity: status === 'active' ? '1' : '.5',
        cursor: status === 'active' ? 'pointer' : 'no-drop',
      }}
      onClick={() => onClick?.(item)}
      gap='16px'
    >
      <Box pos='relative'>
        {thumbnail ? (
          <Center
            w='62px'
            h='62px'
            borderRadius='8px'
            fontSize='12px'
            fontWeight='900'
          >
            <Image
              width={62}
              height={62}
              src={thumbnail}
              style={{
                minWidth: 62,
                borderRadius: 8,
              }}
            />
          </Center>
        ) : (
          <Center
            style={{
              minWidth: 62,
              height: 62,
              borderRadius: 8,
              backgroundColor: colorSetHandler(index),
            }}
          >
            <span
              style={{
                fontSize: 'xx-large',
                fontWeight: '600',
                color: 'white',
              }}
            >
              {title?.split('')?.[0]?.toUpperCase()}
            </span>
          </Center>
        )}
        <IconDisplay cardType={item?.cardType} />
      </Box>

      <Flex
        direction='column'
        flex={1}
        overflow='hidden'
        pr={1}
        justify='center'
      >
        <Box maxWidth='100%' width='max-content'>
          <Box
            style={{
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: '500',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            color='#6F767E'
            pr={5}
          >
            {category}
          </Box>
          <Box
            style={{
              fontSize: '15px',
              lineHeight: '24px',
              letterSpacing: '-0.15px',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            pr={5}
          >
            {title}
          </Box>
        </Box>

        <div
          style={{
            fontSize: '12px',
            fontWeight: '500',
            letterSpacing: '0.112px',
            color: '#9A9FA5',
          }}
        >
          {cardTypeTitle}
        </div>
      </Flex>
    </Flex>
  );
};

export default HandbookName;

import {
  Box,
  Center,
  Image as ChakraImage,
  ResponsiveValue,
  Text,
} from '@chakra-ui/react';
import Image from '../../ui-components/Image/Image';
import React, { CSSProperties, FC } from 'react';
import DocIcon from '../../assets/images/chapter-editor/doc-file.svg';
import PdfIcon from '../../assets/images/chapter-editor/pdf-file.svg';
import ExcelIcon from '../../assets/images/chapter-editor/xls-file.svg';
// import { ReactComponent as QuizWrongIcon } from "../assets/quizWrongIcon.svg";
import { ISopMedia } from './chapter.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/pro-duotone-svg-icons';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import styled from '@emotion/styled';
import { getFileType } from './CreateChapterModal';
import { FileEntity, SopType } from 'types';
import { faFolder, faFolderUser } from '@fortawesome/pro-regular-svg-icons';

import { getImage } from '../../utils';

export type IChapterIconType = 'emoji' | 'icon' | 'folder';

const IconWrapper = styled.div`
  .video-icon .fa-primary {
    fill: black;
    opacity: 1;
  }
  .video-icon .fa-secondary {
    fill: white;
    opacity: 1;
  }
`;

interface IProps {
  height?: string;
  width?: string;
  minHeight?: string;
  maxHeight?: string;
  minWidth?: string;
  maxWidth?: string;
  boxSize?: string;
  borderRadius?: string;
  border?: string | undefined;
  fontSize?: string;
  folderColor?: string;
  type?: IChapterIconType;
  chapterLink?: string;
  chapterName: string;
  imgixWidth?: number;
  imageStyle?: CSSProperties;
  sopType?: SopType;
  padding?: string;
  fileType?: FileEntity[];
  chapterMediaDetails?: ISopMedia;
  playIconSize?: SizeProp | undefined;
  iconBoxSize?: { width: string; height: string };
  chapterFileThumbnail?: string;
  folderVisibility?: string;
}

const ChapterIconComponent: FC<IProps> = (props) => {
  const {
    minWidth = undefined,
    minHeight = undefined,
    height = undefined,
    width = undefined,
    borderRadius = '7px',
    fontSize = '40px',
    boxSize = undefined,
    type,
    chapterLink,
    folderColor = '#f6f6f6',
    chapterName,
    imgixWidth = 220,
    imageStyle,
    sopType,
    padding = undefined,
    fileType,
    chapterMediaDetails,
    playIconSize = '4x',
    iconBoxSize = { width: '64px', height: '64px' },
    chapterFileThumbnail,
    folderVisibility = 'public',
  } = props;
  const finalBorder = 'border' in props ? props.border : '1px solid #DDDDDD';

  const getIconHandler = (
    type: 'pdf' | 'excel' | 'doc',
    height: string,
    width: string
  ) => {
    if (type === 'doc') {
      // return <DocIcon style={{ height, width }} />
      return <ChakraImage src={DocIcon} height={height} width={width} />;
    } else if (type === 'excel') {
      // return <ExcelIcon style={{ height, width }} />
      return <ChakraImage src={ExcelIcon} height={height} width={width} />;
    } else if (type === 'pdf') {
      // return <PdfIcon style={{ height, width }} />
      return <ChakraImage src={PdfIcon} height={height} width={width} />;
    }
  };

  const iconRenderHandler = () => {
    if (sopType === 'text' || !sopType) {
      if (type === 'folder') {
        if (chapterFileThumbnail) {
          return (
            <ChakraImage
              minW={minWidth}
              minH={minHeight}
              height={height}
              width={width}
              src={chapterFileThumbnail}
              objectFit='contain'
              bg={folderColor}
              borderRadius={borderRadius}
              p='5px'
            />
          );
        }
        return (
          <Center
            border={finalBorder}
            minW={minWidth}
            minH={minHeight}
            height={height}
            width={width}
            borderRadius={borderRadius}
            p={padding}
          >
            <FontAwesomeIcon
              icon={
                (folderVisibility === 'private'
                  ? faFolderUser
                  : faFolder) as IconProp
              }
              fontSize={fontSize}
            />
          </Center>
        );
      } else if (type === 'icon') {
        return (
          <Box p={padding}>
            <Image src={chapterLink!} width={imgixWidth} style={imageStyle} />
          </Box>
        );
      } else {
        if (chapterLink) {
          return (
            <Center
              border={finalBorder}
              minW={minWidth}
              minH={minHeight}
              height={height}
              width={width}
              borderRadius={borderRadius}
              p={padding}
            >
              <Text fontSize={fontSize}>{chapterLink}</Text>
            </Center>
          );
        } else {
          return (
            <Box p={padding}>
              <Image
                src={getImage(chapterLink, chapterName)}
                width={imgixWidth}
                style={imageStyle}
              />
            </Box>
          );
        }
      }
    } else if (sopType === 'document') {
      if (chapterFileThumbnail) {
        return (
          <Center
            p={0}
            border={finalBorder}
            minW={minWidth}
            minH={minHeight}
            height={height}
            width={width}
            borderRadius={borderRadius}
            backgroundImage={`url("${chapterFileThumbnail}")`}
            backgroundSize='cover'
            backgroundRepeat='no-repeat'
            backgroundPosition='center'
          />
        );
      } else {
        if (!fileType?.[0]?.mimetype) {
          return (
            <Center
              bg='#E6F0FF'
              p={0}
              border={finalBorder}
              minW={minWidth}
              minH={minHeight}
              height={height}
              width={width}
              borderRadius={borderRadius}
            />
          );
        }

        switch (getFileType(fileType?.[0]?.mimetype)) {
          case 'pdf':
            return (
              <Center
                bg='#FFECEC'
                p={0}
                border={finalBorder}
                minW={minWidth}
                minH={minHeight}
                height={height}
                width={width}
                borderRadius={borderRadius}
              >
                {getIconHandler('pdf', iconBoxSize?.height, iconBoxSize?.width)}
              </Center>
            );
          case 'xlsx':
            return (
              <Center
                bg='#E6F4E1'
                p={0}
                border={finalBorder}
                minW={minWidth}
                minH={minHeight}
                height={height}
                width={width}
                borderRadius={borderRadius}
              >
                {getIconHandler(
                  'excel',
                  iconBoxSize?.height,
                  iconBoxSize?.width
                )}
              </Center>
            );

          case 'docs':
            return (
              <Center
                bg='#E6F0FF'
                p={0}
                border={finalBorder}
                minW={minWidth}
                minH={minHeight}
                height={height}
                width={width}
                borderRadius={borderRadius}
              >
                {getIconHandler('doc', iconBoxSize?.height, iconBoxSize?.width)}
              </Center>
            );
          default:
            return (
              <Center
                bg='#E6F0FF'
                p={0}
                border={finalBorder}
                minW={minWidth}
                minH={minHeight}
                height={height}
                width={width}
                borderRadius={borderRadius}
              />
            );
        }
      }
    } else if (sopType === 'video') {
      return (
        <IconWrapper>
          <Center
            p={0}
            border={finalBorder}
            minW={minWidth}
            minH={minHeight}
            height={height}
            width={width}
            borderRadius={borderRadius}
            backgroundImage={`url(${chapterMediaDetails?.thumbnail})`}
            backgroundSize='cover'
            backgroundRepeat='no-repeat'
            backgroundPosition='center'
          >
            <FontAwesomeIcon
              className='video-icon'
              icon={faCirclePlay as IconProp}
              size={playIconSize}
            />
          </Center>
        </IconWrapper>
      );
    } else {
      return <text>NO THING</text>;
    }
  };

  return iconRenderHandler();
};

export default ChapterIconComponent;

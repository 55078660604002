import React, { FC } from 'react';
import { Box, Flex, IconButton, Input, Text } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { QuestionType } from 'types';

import { Question as QuestionData } from '../create-form/form.types';
import LocationChoice from '../from-preview/component/LocationChoice';
import MemberChoice from '../from-preview/component/MemberChoice';
import DropDownChoice from '../from-preview/component/DropDownChoice';
import Options from '../from-preview/component/Options';
import FormTextAreaInput from '../from-preview/component/FormTextAreaInput';
import UploadField from '../from-preview/component/UploadField';
import FormTextInput from '../from-preview/component/FormTextInput';
import { IFormInput } from './submit-form.types';
import TitleHeader from '../../CardEditor/TitleHeader';
import { useQuestionNotes } from './useQuestionNotes';
import { DisabledNote } from '../../../configs';

interface IProps {
  question: QuestionData;
  currentIndex: number;
  isPreview?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const QuestionContainer: FC<IProps> = ({
  question,
  currentIndex,
  isPreview,
  isReadOnly,
  isDisabled,
}) => {
  const { control, getValues, setValue } = useFormContext<IFormInput>();

  const questionNotes = useQuestionNotes();

  const onAddNotes = () => {
    questionNotes({
      note: getValues(`response.${currentIndex}.noteText`),
      onNoteAdd: (newNote) => {
        setValue(`response.${currentIndex}.noteText`, newNote);
      },
    });
  };

  if (question.isArchieved) {
    return null;
  }

  const renderInput = () => {
    switch (question.qType) {
      case QuestionType.LOCATION:
        return (
          <LocationChoice
            isRequired={question.isRequired}
            currentIndex={currentIndex}
            isDisabled={isDisabled}
          />
        );
      case QuestionType.MEMBER:
        return (
          <MemberChoice
            isRequired={question.isRequired}
            currentIndex={currentIndex}
            isDisabled={isDisabled}
          />
        );
      case QuestionType.DROPDOWN:
        return (
          <DropDownChoice
            question={question}
            currentIndex={currentIndex}
            isDisabled={isDisabled}
          />
        );
      case QuestionType.MULTI_CHOICE:
      case QuestionType.CHECKBOX:
        return (
          <Options
            question={question}
            currentIndex={currentIndex}
            isDisabled={isDisabled}
            isPreview={isPreview}
          />
        );
      case QuestionType.LONG_TEXT:
        return (
          <FormTextAreaInput
            question={question}
            currentIndex={currentIndex}
            isDisabled={isDisabled}
          />
        );
      case QuestionType.VIDEO_UPLOAD:
      case QuestionType.IMAGE_UPLOAD:
      case QuestionType.FILE_UPLOAD:
        return (
          <UploadField
            question={question}
            currentIndex={currentIndex}
            isPreview={isPreview}
            isDisabled={isDisabled}
          />
        );
      default:
        return (
          <FormTextInput
            question={question}
            currentIndex={currentIndex}
            isReadOnly={isReadOnly}
            isDisabled={isDisabled}
          />
        );
    }
  };

  return (
    <Box
      my='12px'
      p='16px 24px'
      borderRadius='10px'
      border='1px solid'
      borderColor='#dddddd'
      bg='#f9f9f9'
    >
      <Controller
        name={`response.${currentIndex}.qid`}
        control={control}
        defaultValue={question.eid}
        render={({ field }) => {
          return <Input size='lg' {...field} hidden />;
        }}
      />

      <Flex gap={4}>
        <Box flex={1}>
          <TitleHeader
            title={question.label}
            desc={question.subTitle}
            titleStyle={{
              fontSize: '16px',
            }}
            descStyle={{
              fontSize: '12px',
              color: '#606365',
            }}
            isRequired={question.isRequired}
          />
        </Box>

        <Controller
          name={`response.${currentIndex}.noteText`}
          control={control}
          defaultValue=''
          render={({ field }) => {
            if (field.value || DisabledNote.includes(question.qType)) {
              return <React.Fragment />;
            }
            return (
              <IconButton
                size='sm'
                aria-label='note'
                icon={
                  <FontAwesomeIcon
                    icon={faPenToSquare as IconProp}
                    color='#6F767E'
                  />
                }
                onClick={onAddNotes}
              />
            );
          }}
        />
      </Flex>

      <div className=''>{renderInput()}</div>

      <Controller
        name={`response.${currentIndex}.noteText`}
        control={control}
        defaultValue=''
        render={({ field }) => {
          if (!field.value || DisabledNote.includes(question.qType)) {
            return <React.Fragment />;
          }
          return (
            <Flex
              mt={4}
              gap={2}
              borderRadius='10px'
              border='1px solid #EFEFEF'
              p='12px 4px 12px 12px'
              bg='#F4F4F4'
            >
              <Text
                flex={1}
                color='#6F767E'
                fontSize='13px'
                whiteSpace='pre-line'
                lineHeight='unset !important'
              >
                {field.value}
              </Text>
              <IconButton
                size='sm'
                aria-label='note'
                mt={-2}
                icon={
                  <FontAwesomeIcon
                    icon={faPenToSquare as IconProp}
                    color='#6F767E'
                  />
                }
                onClick={onAddNotes}
              />
            </Flex>
          );
        }}
      />
    </Box>
  );
};

export default QuestionContainer;

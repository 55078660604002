import React, { FC } from 'react';
import { Box, Flex, ListItem } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessageLines } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { GroupMemberEntity } from '../../../../../../types';
import {
  ActionButton,
  IconImage,
  RoleAndAuthRole,
} from '../../../../../../ui-components';

import { ReactComponent as DeleteIcon } from '../../../../../../assets/images/trash-red.svg';

interface IProps {
  item: GroupMemberEntity;
  isGroupOwner?: boolean;
  isMyself?: boolean;
  onActionClick: (action: string, data: GroupMemberEntity) => void;
}

const MemberItem: FC<IProps> = ({
  item,
  isGroupOwner,
  isMyself,
  onActionClick,
}) => {
  return (
    <ListItem>
      <Flex align='center' gap='6px'>
        <IconImage
          name={item?.name}
          thumbnail={item?.avatar}
          boxSize={40}
          borderRadius='50%'
          objectFit='cover'
        />

        <Flex flex={1} direction='column' overflow='hidden' align='start'>
          <Box fontWeight={500} color='#1A1D1F' isTruncated>
            {item?.name}
          </Box>
          <RoleAndAuthRole
            role={item?.metadata?.role}
            authRole={item?.metadata?.parents?.[0]}
            disableAuthRoleSplit
            defaultValue='-'
          />
        </Flex>

        <ActionButton
          padding='0'
          borderRadius='full'
          hidden={isMyself}
          size='sm'
          actionFn={() => onActionClick?.('message', item)}
          pos='unset'
          variant='ghost'
        >
          <FontAwesomeIcon
            icon={faMessageLines as IconProp}
            color='#6F767E'
            aria-hidden={true}
            focusable={false}
          />
        </ActionButton>

        <ActionButton
          padding='0'
          borderRadius='full'
          hidden={isGroupOwner}
          size='sm'
          actionFn={() => onActionClick?.('delete', item)}
          pos='unset'
          variant='ghost'
        >
          <DeleteIcon
            height={18}
            width={17}
            aria-hidden={true}
            focusable={false}
          />
        </ActionButton>
      </Flex>
    </ListItem>
  );
};

export default MemberItem;

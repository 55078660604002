import React, { FC } from 'react';
import { Box, Center, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import {
  FieldArrayWithId,
  UseFieldArrayInsert,
  UseFieldArrayMove,
  useFormContext,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagramSubtask } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import {
  faDiagramPredecessor,
  faEllipsisStroke,
} from '@fortawesome/pro-regular-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { PrimaryButton } from '../../../../../atoms';

import { IFormInput } from '../editor.types';
import TaskMoreAction from './TaskMoreAction';
import AddNewTaskAction from './AddNewTaskAction';

interface IProps {
  index: number;
  data: FieldArrayWithId<IFormInput, `contents.${number}.tasks`, 'id'>;
  isLast: boolean;
  moveTask: UseFieldArrayMove;
  addNewTask: UseFieldArrayInsert<IFormInput, `contents.${number}.tasks`>;
  actionHandler?: (name: string, value: string) => void;
}

const EditorTaskItem: FC<IProps> = ({
  data,
  index,
  isLast,
  moveTask,
  addNewTask,
  actionHandler,
}) => {
  const { t } = useTranslation(['launcher']);
  const { setValue } = useFormContext<IFormInput>();

  const haveDuration = !!data.duration;

  return (
    <Flex
      gap={3}
      py={4}
      px={5}
      bg='#FFFFFF'
      borderRadius='12px'
      _hover={{
        boxShadow: 'lg',
      }}
    >
      <Flex flex={1} flexDir='column' gap={1} pt={1}>
        <Box fontSize='15px' fontWeight='500' color='#33383F'>
          {data.title}
        </Box>
        {data.description && (
          <Box
            fontSize='12px'
            fontWeight='400'
            color='#6F767E'
            wordBreak='break-all'
          >
            {data.description}
          </Box>
        )}

        <Flex gap={3} mt={2}>
          {!haveDuration && (
            <PrimaryButton
              width='auto'
              colorScheme='blue'
              variant='link'
              title='Add details'
              style={{ fontSize: '14px', fontWeight: '400', padding: 0 }}
              onClick={() => setValue('taskEditable', data.tempEid)}
            />
          )}
          {!!data.steps?.length && (
            <Flex align='center' gap={3} color='#33383F' userSelect='none'>
              <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />
              <Box fontSize='12px' fontWeight='500' lineHeight='18px'>
                <Trans
                  t={t}
                  i18nKey='launcher:noOfSubtask'
                  count={data.steps.length}
                  values={{ value: data.steps.length }}
                />
              </Box>
            </Flex>
          )}
          {data.dependency === 'PREVIOUS_TASK' && (
            <Flex align='center' gap={3} color='#33383F' userSelect='none'>
              <FontAwesomeIcon icon={faDiagramPredecessor as IconProp} />
              <Box fontSize='12px' fontWeight='500' lineHeight='18px'>
                <Box as='span' color='#6F767E'>
                  Trigger after :&nbsp;
                </Box>
                Above task
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex flexDir='column' justify='space-between' align='flex-end'>
        <Flex gap={1}>
          <IconButton
            size='sm'
            aria-label={'alt'}
            variant='ghost'
            icon={
              <FontAwesomeIcon icon={faChevronUp as IconProp} color='#6F767E' />
            }
            isDisabled={index === 0}
            onClick={() => moveTask(index, index - 1)}
          />

          <IconButton
            size='sm'
            aria-label={'alt'}
            variant='ghost'
            icon={
              <FontAwesomeIcon
                icon={faChevronDown as IconProp}
                color='#6F767E'
              />
            }
            isDisabled={isLast}
            onClick={() => moveTask(index, index + 1)}
          />

          <AddNewTaskAction index={index} addNewTask={addNewTask} />

          <TaskMoreAction clickedItem={actionHandler}>
            <IconButton
              size='sm'
              aria-label={'alt'}
              variant='ghost'
              icon={
                <FontAwesomeIcon
                  fontSize='1.25rem'
                  icon={faEllipsisStroke as IconProp}
                  color='#6F767E'
                />
              }
            />
          </TaskMoreAction>
        </Flex>

        {haveDuration && (
          <Flex gap={3}>
            {data.isLocal && (
              <Tooltip
                label='Updates to this task will not be visible to anyone until it is published.'
                hasArrow
                borderRadius='6px'
                padding='10px'
                placement='bottom-end'
                maxW='185px'
                bg='#000000CC'
              >
                <Center cursor='pointer' boxSize='22px'>
                  <FontAwesomeIcon icon={faEyeSlash as IconProp} size='sm' />
                </Center>
              </Tooltip>
            )}

            <PrimaryButton
              width='auto'
              colorScheme='blue'
              variant='link'
              title='Edit details'
              style={{ fontSize: '14px', fontWeight: '400', padding: 0 }}
              onClick={() => setValue('taskEditable', data.tempEid)}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default EditorTaskItem;

import React, { FC, ImgHTMLAttributes, useMemo } from 'react';
import Imgix from 'react-imgix';
import { IKImage } from 'imagekitio-react';
import {
  IMGIX_REGX,
  SOP_UPLOAD_REGX,
  SOP_UPLOAD_URL,
} from '../../utils/constants';

interface Lqip {
  active?: boolean;
  quality?: number;
  threshold?: number;
  blur?: number;
  raw?: string;
}

interface Transformation {}

interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
  height: number;
  width: number;
  loading?: 'lazy';
  lqip?: Lqip;
  path?: string;
  src: string;
  queryParameters?: Record<string, string | number>;
  transformation?: Transformation[];
  transformationPosition?: 'path' | 'query';
}

const NativeImage: FC<IProps> = ({
  src,
  className,
  height,
  width,
  ...props
}) => {
  const styles = props?.style;
  return (
    <img
      alt=''
      {...props}
      style={{
        ...styles,
        width: styles?.width || width,
        height: styles?.height || height,
      }}
      src={src}
      className={className}
    />
  );
};

const Image: FC<IProps> = ({ src, ...rest }) => {
  const [imageUrl, isImgix, isImgekit] = useMemo(() => {
    if (src?.match(SOP_UPLOAD_REGX)) {
      return [src.replace(SOP_UPLOAD_URL, ''), false, true];
    }
    if (src?.match(IMGIX_REGX)) {
      return [src, true, false];
    }
    return [src, false, false];
  }, [src]);

  if (isImgekit) {
    return (
      <IKImage
        path={src.replace(SOP_UPLOAD_URL, '')}
        loading='lazy'
        lqip={{ active: true }}
        {...rest}
      />
    );
  }

  if (isImgix) {
    return <Imgix src={imageUrl} {...rest} />;
  }
  return <NativeImage src={imageUrl} {...rest} />;
};

export default Image;

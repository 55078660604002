import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Grid,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  useBoolean,
  usePopoverContext,
} from '@chakra-ui/react';
import { UserSignature } from '../../../../../types';
import { useUserData } from '../../../../../hooks';

import Signature from './Signature';
import CreateSignature from './CreateSignature';

interface IProps {
  defaultValue?: UserSignature;
  onChange?: (sign: UserSignature) => void;
  isPreview?: boolean;
}

const SignatureModalContent: FC<IProps> = ({
  defaultValue,
  onChange,
  isPreview,
}) => {
  const { onClose } = usePopoverContext();
  const [value, setValue] = useState<UserSignature | undefined>(defaultValue);
  const data = useUserData().signatures || [];
  const [createSign, action] = useBoolean(!data?.length);

  if (createSign) {
    return (
      <CreateSignature
        isPreview={isPreview}
        onChange={onChange}
        close={!data?.length ? onClose : action.off}
      />
    );
  }

  return (
    <React.Fragment>
      <PopoverHeader borderBottom='none'>
        <Flex justify='space-between' align='center'>
          <Box fontSize='16px' fontWeight='600' color='#1A1D1F'>
            Use Signature
          </Box>
          <PopoverCloseButton pos='relative' top='0' right='0' />
        </Flex>
      </PopoverHeader>

      <PopoverBody padding='12px 16px'>
        <Grid
          templateColumns='repeat(2, 1fr)'
          gap='6px'
          pt={3}
          alignItems='center'
        >
          {data?.map((sign, index) => (
            <Signature
              key={index}
              text={sign.text}
              font={sign.font}
              isSelected={
                sign.font === value?.font && sign.text === value?.text
              }
              onClick={() => setValue?.(sign)}
            />
          ))}
        </Grid>

        <Flex flexDir='column' gap={2} mt={3}>
          <Button
            w='full'
            fontSize='14px'
            fontWeight='600'
            variant='solid'
            colorScheme='blue'
            onClick={() => onChange?.(value!)}
            isDisabled={!value}
          >
            Use
          </Button>
          <Button
            w='full'
            fontSize='14px'
            fontWeight='600'
            variant='outline'
            onClick={action.on}
          >
            Create new signature
          </Button>
        </Flex>
      </PopoverBody>
    </React.Fragment>
  );
};

export default SignatureModalContent;

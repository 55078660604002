import React, { FC } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEarthAsia, faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';

import { VisibilityConfig } from '../../../../../../../types';

interface IProps {
  status: VisibilityConfig['visibility'];
}

const StatusIcon: FC<IProps> = ({ status }) => {
  if (status === 'private') {
    return (
      <FontAwesomeIcon
        icon={faUserGroup as IconProp}
        color='#6F767E'
        fontSize='11px'
        style={{
          backgroundColor: '#F0F0F0',
          padding: '4px',
          borderRadius: '50%',
        }}
      />
    );
  }

  if (status === 'restricted') {
    return (
      <FontAwesomeIcon
        icon={faLockKeyhole as IconProp}
        color='#6F767E'
        fontSize='11px'
        style={{
          backgroundColor: '#F0F0F0',
          padding: '4px',
          borderRadius: '50%',
        }}
      />
    );
  }

  return (
    <FontAwesomeIcon
      icon={faEarthAsia as IconProp}
      color='#33383F'
      fontSize='11px'
      style={{
        backgroundColor: '#F0F0F0',
        padding: '4px',
        borderRadius: '50%',
      }}
    />
  );
};

export default StatusIcon;

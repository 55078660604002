import {
  Flex,
  Box,
  Text,
  AlertDialogCloseButton,
  Image,
} from '@chakra-ui/react';
import React from 'react';
import PreviewHeader from 'sub-components/forms/component/PreviewHeader';
import LocalizationLoadingIcon from '../../assets/images/localization loading.svg';

const confirmObject = (
  onYesHandler: (languageCode?: string) => Promise<void>,
  onCloseBtnClickHandler: () => void,
  headerTranslation: string,
  updateLanguageAgree: string,
  commonNo: string,
  commonYes: string,
  languageCode?: string
) => {
  return {
    title: (
      <Flex gap='12px'>
        <Box flex={1}>
          <PreviewHeader title={headerTranslation} color='#cabdff' />
        </Box>
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
          onClick={(event) => {
            event.preventDefault();
            onCloseBtnClickHandler();
          }}
        />
      </Flex>
    ),
    content: (
      <Text fontSize='18px' fontWeight='500' color='#6f767e'>
        {updateLanguageAgree}
      </Text>
    ),
    isCentered: true,
    contentProps: {
      paddingTop: '12px',
      paddingBottom: '12px',
      minW: '600px',
    },
    okButtonProps: {
      colorScheme: 'blue',
      borderRadius: '10px',
    },
    cancelButtonProps: {
      backgroundColor: '#fcfcfc',
      color: 'black',
      border: '1px solid #efefef',
      borderRadius: '10px',
    },
    cancelText: commonNo,
    okText: commonYes,
    onOK: () => {
      return onYesHandler(languageCode);
    },
    onCancel: () => {
      onCloseBtnClickHandler();
    },
  };
};

const loadingObject = (updatingLanguageProgress: string) => {
  return {
    content: (
      <Flex
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        p={4}
      >
        <Image src={LocalizationLoadingIcon} />
        <Text w='70%' textAlign='center' fontSize='18px' color='#6f767e'>
          {updatingLanguageProgress}
        </Text>
      </Flex>
    ),
    isCentered: true,
    footer: <></>,
  };
};

export { confirmObject, loadingObject };

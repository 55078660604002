// @ts-ignore
import React, { FC, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FormEditResponse, getFormEditData } from './form.graphql';
import { CreateFormComponent, FormCreationRef } from './create-form';
import { toEditableForm } from './dataConvert';

interface IProps {}

const EditFormContainer: FC<IProps> = () => {
  const { t } = useTranslation('form');
  const formRef = useRef<FormCreationRef>(null);
  const formId = useParams<{ id: string }>()?.id;
  const history = useHistory();

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
  });

  const { loading } = useQuery<FormEditResponse>(getFormEditData, {
    variables: { eid: formId },
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const editableForm = toEditableForm(response.FormById);
      formRef.current?.initForm(editableForm, true);
    },
    onError: (error) => {
      toast({
        status: 'error',
        title: t('common:error'),
        description: error?.message,
      });
      history.goBack();
    },
  });

  return (
    <CreateFormComponent
      ref={formRef}
      showInitialize={loading}
      isEdit
      title={t('update_form')}
    />
  );
};

export default EditFormContainer;

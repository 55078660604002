import React, { FC } from 'react';
import {
  AlertDialogCloseButton,
  Center,
  Flex,
  useModalContext,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { ActionButton, BoxHeader, ConfirmConfig } from '../../../ui-components';
import AsyncAlertEvent, { PromptAction } from './async-alert-event';

interface IProps {
  callback: (ok: boolean) => void;
}

const Content: FC<IProps> = ({ callback }) => {
  const { onClose } = useModalContext();

  const onPublishClick = async () => {
    await AsyncAlertEvent.sendData({
      action: PromptAction.PUBLISH_NOW,
    });
    callback(false);
  };

  return (
    <Center flexDir='column' gap={5} pt={4}>
      <Center boxSize='56px' borderRadius='full' bg='#FFD88D80'>
        <FontAwesomeIcon
          icon={faTriangleExclamation as IconProp}
          color='#F4A305'
          fontSize='1.5em'
        />
      </Center>

      <Flex textAlign='center' color='#33383F'>
        Please save your changes by publishing them. Otherwise, they will be
        lost.
      </Flex>

      <Flex flexDir='column' w='full' gap={3}>
        <ActionButton
          fontSize='15px'
          actionFn={onPublishClick}
          close={onClose}
          colorScheme='blue'
        >
          Publish now
        </ActionButton>

        <ActionButton
          fontSize='15px'
          actionFn={() => callback(true)}
          close={onClose}
          colorScheme='red'
          variant='outline'
        >
          Discard
        </ActionButton>
      </Flex>
    </Center>
  );
};

export default function LauncherPublishAlert(
  callback: (ok: boolean) => void
): Partial<ConfirmConfig> {
  return {
    title: (
      <Flex justify='space-between' gap={3}>
        <BoxHeader title='You have some unpublished changes' color='#FFBC99' />
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
          onClick={() => callback(false)}
        />
      </Flex>
    ),

    content: <Content callback={callback} />,

    contentProps: {
      maxWidth: '540px',
    },
  };
}

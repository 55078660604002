import React, { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarCheck,
  faCalendarDay,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import { AmplitudeEvent, deployEvent } from '../../../../../shared';
import GenericColorHeader from '../../../../../sub-components/GenericColorHeader';

const TaskOverviewHeading: FC = () => {
  const history = useHistory();
  const params = useParams<{ id: string; sessionId: string }>();
  const editHandler = () => {
    if (!params?.id) return;
    deployEvent(AmplitudeEvent.TASK_EDIT);
    history.push(`/tasks/edit/${params?.id}`);
  };
  return (
    <Flex gap='10px' justify='space-between' align='center'>
      <GenericColorHeader title='Task overview' color='#FFD88D' />
      {/* <Box>
        <FontAwesomeIcon
          icon={faPen as IconProp}
          color='white'
          cursor='pointer'
          style={{
            backgroundColor: '#BBBBBB',
            borderRadius: '50%',
            padding: '7px',
          }}
          onClick={editHandler}
        />
      </Box> */}
    </Flex>
  );
};

export default TaskOverviewHeading;

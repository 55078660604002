/** @format */

import React, { FC, useState, useEffect, useMemo, useRef } from "react";
// @ts-ignore
import ShowMoreText from "react-show-more-text";
import "../style.scss";
import ReactPlayer from "react-player";
import ProgressBar from "@ramonak/react-progress-bar";
import { ReactComponent as PlayIcon } from "../assets/playIcon.svg";
import { ReactComponent as PauseIcon } from "../assets/pauseIcon.svg";

interface VideoProps {
  videoLink?: string;
  caption?: string;
  title?: string;
  media?: Array<any>;
  thumbnail?: string;
  pauseVideo?: boolean;
}

export const Video: FC<VideoProps> = ({
  videoLink,
  caption,
  title,
  media,
  thumbnail,
  pauseVideo,
}) => {
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const [videoPlayed, setVideoPlayed] = useState(0);
  const [isPauseButton, showPauseButton] = useState(false);
  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isPreviewEnded, setIsPreviewEnded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const executeOnClick = (isExpanded: boolean) => {
    setIsExpanded(isExpanded);
  };

  useEffect(() => {
    setVideoPlayed(0);
    setVideoDuration(null);
  }, [videoLink]);

  useEffect(() => {
    if (typeof pauseVideo === "boolean") {
      setIsVideoPlaying(!pauseVideo);
    }
  }, [pauseVideo]);

  const mediaData = media?.[0];
  const mediaUrl = mediaData?.mediaUrl || videoLink;

  const thumbnailVal = useMemo((): string => {
    return mediaData?.thumbnail || thumbnail || "https://picsum.photos/200/300";
  }, [mediaData?.thumbnail, thumbnail]);

  return (
    <div
      onMouseOver={() => {
        showPauseButton(true);
      }}
      onMouseLeave={() => showPauseButton(false)}
    >
      <ReactPlayer
        onClick={() => {
          if (isVideoPlaying) {
            setIsVideoPlaying(false);
          } else {
            setIsVideoPlaying(true);
          }
        }}
        ref={videoPlayerRef}
        style={{
          backgroundColor: "white",
        }}
        className="react-player"
        onProgress={(value) => {
          setVideoPlayed(value.played);
        }}
        onPause={() => setIsVideoPlaying(false)}
        onPlay={() => setIsVideoPlaying(true)}
        onEnded={() => {
          setIsPreviewEnded(false);
          videoPlayerRef?.current?.showPreview();
        }}
        onDuration={(value) => {
          setVideoDuration(value);
        }}
        height={"100%"}
        width="100%"
        config={{
          file: {
            attributes: {
              style: {
                width: "100%",
                height: "100%",
                objectFit: "cover",
              },
            },
          },
        }}
        onClickPreview={() => {
          setIsVideoPlaying(true);
          setIsPreviewEnded(true);
        }}
        playing={isVideoPlaying}
        playIcon={<PlayIcon />}
        light={thumbnailVal}
        url={mediaUrl}
      />
      {isPauseButton && isPreviewEnded && (
        <div
          onClick={() => setIsVideoPlaying(!isVideoPlaying)}
          style={{ position: "absolute", top: "41.4%", left: "33%" }}
        >
          {isVideoPlaying ? <PauseIcon /> : <PlayIcon />}
        </div>
      )}
      <div className="video-caption">
        <div style={{ zIndex: "100" }}>
          <div className="caption-title">{title}</div>
          <div className="caption-text-container">
            <div className="caption-text">
              <ShowMoreText
                /* Default options */
                lines={3}
                more={
                  <span style={{ fontSize: "12px", opacity: "0.8" }}>
                    Show more
                  </span>
                }
                less={
                  <span style={{ fontSize: "12px", opacity: "0.8" }}>
                    Show less
                  </span>
                }
                className="content-css"
                anchorClass="my-anchor-css-class"
                onClick={executeOnClick}
                expanded={isExpanded}
                truncatedEndingComponent={"... "}
              >
                {caption}
              </ShowMoreText>
            </div>

            {videoDuration && (
              <div className="caption-duration">
                {Math.floor(videoDuration / 60) +
                  ":" +
                  (videoDuration % 60
                    ? Math.ceil(((videoDuration % 60) * 20) / 20)
                    : "00")}
              </div>
            )}
          </div>
        </div>
      </div>
      <ProgressBar
        className="progress-bar-wrapper"
        //barContainerClassName="progress-bar-wrapper"
        isLabelVisible={false}
        completed={videoPlayed * 100}
        bgColor={"#ff6a55"}
        height={"4px"}
        width={"94%"}
        borderRadius={"12px"}
      />
    </div>
  );
};

import React, { FC } from 'react';
import { Button, ButtonProps, ThemeTypings } from '@chakra-ui/react';

type PaginationItemProps = {
  isCurrent?: boolean;
  page: number;
  onPageChange: (page: number) => void;
  colorScheme?: ThemeTypings['colorSchemes'];
  isEclipsed?: boolean;
};

const defaultConfig: ButtonProps = {
  size: 'sm',
  fontSize: '14px',
  fontWeight: 700,
  width: 4,
  height: '30px',
  minW: '30px',
};

const PaginationItem: FC<PaginationItemProps> = ({
  isCurrent = false,
  page,
  onPageChange,
  colorScheme,
  isEclipsed,
}) => {
  if (isEclipsed) {
    return (
      <Button
        {...defaultConfig}
        variant='ghost'
        color='gray.400'
        onClick={() => onPageChange(page)}
      >
        •••
      </Button>
    );
  }

  if (isCurrent) {
    return (
      <Button
        {...defaultConfig}
        colorScheme={colorScheme}
        disabled
        _disabled={{
          shadow: 'none',
        }}
      >
        {page}
      </Button>
    );
  }

  return (
    <Button
      {...defaultConfig}
      variant='ghost'
      onClick={() => onPageChange(page)}
    >
      {page}
    </Button>
  );
};

export default PaginationItem;

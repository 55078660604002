import { FC, useMemo } from "react";
import Image from "../elements/Image";
import Tiptap from "../elements/Tiptap";
import NoContent from "../elements/NoContent";

interface IProps {
  content: Array<any>;
  title?: string;
  isPreview?: boolean;
  shouldSetFixedHeight?: boolean;
}

const MixedType: FC<IProps> = ({ content = [], title = "", isPreview, shouldSetFixedHeight }) => {
  const _content = [...content];

  const hasNoData = useMemo(() => {
    if (isPreview) {
      const imgList =
        content.find((value) => value.type === "image")?.image || [];
      const tiptap = content.find((value) => value.type === "tiptap")?.tiptap;
      return !(
        imgList.length > 0 ||
        Boolean(tiptap?.content) ||
        title?.trim().length > 0
      );
    } else {
      return false;
    }
  }, [_content, isPreview, title]);

  if (hasNoData) {
    return <NoContent />;
  }

  return (
    <div
      style={{ height: "inherit", display: "flex", flexDirection: "column", minHeight: shouldSetFixedHeight ? '80vh' : 'none' }}
    >
      {content.map((item, key) => {
        if (item.type === "image") {
          return (
            <div style={{ height: "350px" }} key={key}>
              <Image content={item} />
            </div>
          );
        } else if (item.type === "tiptap") {
          return (
            <div style={{ flex: "1 1 auto" }} key={key}>
              <div className="mixed-card-title-container">{title}</div>
              <Tiptap content={[item]} style={{ padding: "5px 20px" }} />
            </div>
          );
        }
      })}
    </div>
  );
};

export default MixedType;

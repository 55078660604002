import { QuestionEntity, ResponseEntity } from '../../../../../types';
import { toArray } from '../../../../../utils/utils';
import { HiddenResponse } from '../../../../../configs';

interface UserResponse {
  response: ResponseEntity;
  question: QuestionEntity;
}

export const mapUserFormResponse = (
  questions: QuestionEntity[] = [],
  responses: ResponseEntity[] = []
): UserResponse[] => {
  const _responses = toArray(responses).reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue.qid] = currentValue;
      return previousValue;
    },
    {} as Record<string, ResponseEntity>
  );

  return toArray(questions).reduce<UserResponse[]>((acc, value) => {
    if (HiddenResponse.includes(value.qType)) {
      return acc;
    }

    acc.push({
      response: _responses[value?.eid],
      question: value,
    });

    return acc;
  }, []);
};

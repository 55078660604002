import React, { FC, useMemo } from 'react';
import EditorPreview, { ICON_TYPE, JSONContent } from 'delightree-editor';
import { Box } from '@chakra-ui/react';
import { useSmartPreviewFormat } from './hooks';
import MobileHeader from './MobileHeader';

interface IProps {
  title?: string;
  selectedIndex: number;
  value?: JSONContent;
  onItemClick?: (event: MouseEvent, node: any) => void;
  chapterIcon?: string;
  iconType?: string;
}

const ChapterTablet: FC<IProps> = ({
  title,
  value,
  selectedIndex,
  chapterIcon,
  onItemClick,
  iconType,
}) => {
  const prevData = useSmartPreviewFormat(value, selectedIndex);

  const props = useMemo(() => {
    if (value?.length > 1) {
      return {
        currentIndex: selectedIndex + 1,
        totalCards: value?.length,
      };
    }
    return {};
  }, [selectedIndex, value]);

  return (
    <Box
      borderRadius='48px'
      p='22px'
      border='4px solid #3b444b'
      bg='#151515'
      h='full'
      style={{
        aspectRatio: '3/4',
      }}
    >
      <Box
        pos='relative'
        borderRadius='24px'
        bg='#ffffff'
        overflow='auto'
        h='full'
      >
        <MobileHeader title={title} {...props} />
        <Box p='16px' pt='10px'>
          <EditorPreview
            readonly
            value={prevData}
            onItemClick={onItemClick}
            chapterIcon={chapterIcon}
            iconType={iconType === ICON_TYPE ? 'icon' : 'emoji'}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChapterTablet;

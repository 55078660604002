import styled from '@emotion/styled';

export const MemberItemWrapper = styled.div`
  padding: 12px 0;
  border-bottom: 1px solid #efefef;

  .member-avatar {
    width: 36px;
    height: 36px;
    border-radius: 5px;
  }
`;

import React, { FC, useMemo, useState } from 'react';
import { Center, Divider, Flex, List } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import { SelectOption } from '../../../../../../atoms';
import { ChatGroupEntity, GroupMemberEntity } from '../../../../../../types';
import { useUserDataSelector } from '../../../../../../hooks';

import DataNotFound from '../../channel-members/DataNotFound';
import SearchAndFilter from '../../channel-members/SearchAndFilter';
import { useMemberFilter } from '../../channel-members/useMemberData';
import { useJobRoleLocationList } from '../../select-group';
import { BaseGroupResponse } from '../../create-channel.graphql';

import MemberItem from './MemberItem';
import { useAddMember } from './useAddMember';

interface IProps {
  group: ChatGroupEntity;
  members: GroupMemberEntity[];
  loading?: boolean;
  onActionClick: (action: string, data: GroupMemberEntity) => void;
  onMemberUpdated: (data: BaseGroupResponse) => void;
}

const ChannelMemberList: FC<IProps> = ({
  group,
  members,
  loading,
  onActionClick,
  onMemberUpdated,
}) => {
  const [filterBy, setFilterBy] = useState<SelectOption | null>(null);
  const [searchQuery, setSearchQuery] = useState('');

  const authUserId = useUserDataSelector((state) => state.eid);

  const _options = useJobRoleLocationList();

  const options = useMemo(() => {
    const details = group?.details;

    const check = [
      ...(details?.authRoles || []),
      ...(details?.roles || []),
      ...(details?.locationIds || []),
    ];

    if (check.length === 0) {
      return _options;
    }

    return _options.filter((op) => check.includes(op.value));
  }, [_options, group?.details]);

  const filteredMembers = useMemberFilter(members, filterBy, searchQuery);

  const addMember = useAddMember();

  const onAddMemberClick = () => {
    addMember({
      initialState: group,
      onMemberUpdated: onMemberUpdated,
    });
  };

  return (
    <div>
      {members?.length > 0 && (
        <SearchAndFilter
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          options={options}
          filterBy={filterBy}
          onFilterChange={setFilterBy}
        />
      )}

      <Flex
        gap={3}
        align='center'
        fontWeight='600'
        color='#171725'
        cursor='pointer'
        width='max-content'
        _hover={{ opacity: 0.8 }}
        onClick={onAddMemberClick}
      >
        <Center bg='#B1E5FC' boxSize='40px' borderRadius='full'>
          <AddIcon />
        </Center>

        <span>Add members</span>
      </Flex>

      <Divider my={4} />

      <DataNotFound
        isLoading={loading}
        searchQuery={searchQuery || filterBy?.value}
        dataLength={filteredMembers.length}
      >
        <List overflow='auto' spacing={5} minH='40vh' pb={2}>
          {filteredMembers.map((member) => (
            <MemberItem
              key={member.uid}
              item={member}
              onActionClick={onActionClick}
              isGroupOwner={member.uid === group?.owner?.eid}
              isMyself={authUserId === member.uid}
            />
          ))}
        </List>
      </DataNotFound>
    </div>
  );
};

export default ChannelMemberList;

import loadingSpinner from '../../../assets/lotties/loadingSpinner.json';
import Lottie from 'react-lottie';
import React, { FC } from 'react';
import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

const TrainingPathFlowModal: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingSpinner,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size='lg'
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent borderRadius='20px' px={4} py={6}>
        <ModalBody>
          <Box display='flex' justifyContent='center'>
            <Center flexDir='column' w='80%'>
              <Lottie
                options={defaultUploadLottieOptions}
                height={100}
                width={100}
              />
              <Text as='b' mt={8} fontWeight='700' fontSize='20px'>
                You can close this tab now.{' '}
              </Text>
              <Text textAlign='center' fontWeight='400' fontSize='14px'>
                Your published chapter should show up in your training path
                workspace.
              </Text>
            </Center>
          </Box>
          {/* <ErrorContainer
              animationData={loadingSpinner}
              title={'You can close this tab now.'}
              description={'Your published chapter should show up in your training path workspace.'}
            /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TrainingPathFlowModal;

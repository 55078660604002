import { FC, useState } from "react";
import { Button } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Choice, { convertArrayToObject } from "../elements/Choice";
import { ADD_CARD_RESPONSE } from "../../../Mutation/Card";

export interface ChoiceItem {
  id: string;
  text: string;
  inputId: string;
}

export interface IQuizType {
  content: any;
  title: string;
  layout: string;
  cardId: string;
  isPreview?: boolean;
  isCheckClickedValue?: (value: boolean) => void;
  shouldMoveToNextCard?: () => void;
  shouldSetFixedHeight?: boolean;
}

const QuizType: FC<IQuizType> = ({
  content,
  title,
  layout,
  cardId,
  isPreview,
  isCheckClickedValue,
  shouldSetFixedHeight,
  shouldMoveToNextCard,
}) => {
  const { t } = useTranslation();
  const choices = (content[0]?.choices || []) as ChoiceItem[];
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [isCheckClicked, setIsCheckClicked] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [addCardResponse] = useMutation(ADD_CARD_RESPONSE);

  const onCheck = () => {
    setIsLoading(true);
    let isCorrect = false;
    const correctAnswer = content[0].correctAnswer;
    if (layout === "single-choice") {
      if (selectedAnswer[0] === correctAnswer) {
        setIsCorrect(true);
        isCorrect = true;
      } else if (selectedAnswer[0] !== correctAnswer) {
        setIsCorrect(false);
        isCorrect = false;
      }
    } else {
      const selectedObj = convertArrayToObject(selectedAnswer);
      let counter = 0;
      if (!Array.isArray(correctAnswer)) {
        setIsLoading(false);
        return;
      }
      correctAnswer.forEach((item: string) => {
        if (selectedObj[item]) {
          counter++;
        }
      });
      if (counter === correctAnswer.length) {
        setIsCorrect(true);
        isCorrect = true;
      } else {
        setIsCorrect(false);
        isCorrect = false;
      }
    }
    if (!isPreview) {
      addCardResponse({
        variables: {
          input: {
            cardType: "quiz",
            cardId: cardId,
            response: selectedAnswer,
            label: title,
            success: isCorrect,
          },
        },
      }).then((response) => {
        console.log("On response Successful", response);
        setIsLoading(false);
        setIsCheckClicked(true);
        if (isCheckClickedValue) {
          isCheckClickedValue(true);
        }
      });
    } else {
      setIsLoading(false);
      setIsCheckClicked(true);
      if (isCheckClickedValue) {
        isCheckClickedValue(true);
      }
    }
  };
  const onGotIt = () => {
    setIsCorrect(false);
    setIsCheckClicked(false);
    if (isCheckClickedValue) {
      isCheckClickedValue(false);
    }
    setSelectedAnswer([]);
    if (shouldMoveToNextCard) {
      shouldMoveToNextCard();
    }
  };

  const isDisabled = () => {
    if (layout === "single-choice") {
      return !selectedAnswer?.length;
    } else if (layout === "multi-choice") {
      return selectedAnswer?.length < content[0].correctAnswer?.length;
    }
  };

  return (
    <div
      className="quiz-type-card-container"
      style={{ minHeight: shouldSetFixedHeight ? "80vh" : "none" }}
    >
      <div className="quiz-type-card-question-container">
        <p
          style={{
            verticalAlign: "middle",
            display: "inline-block",
          }}
        >
          {title}
        </p>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        {choices?.map((choice, index) => {
          return (
            <Choice
              choice={choice}
              key={index}
              correctAnswer={content[0].correctAnswer}
              layout={layout}
              setSelectedAnswer={setSelectedAnswer}
              selectedAnswer={selectedAnswer}
              isCheckClicked={isCheckClicked}
            />
          );
        })}
      </div>

      <div className="action-type-content-button-container">
        {layout === "multi-choice" && (
          <div className="quiz-card-please-select-text">
            {/* @ts-ignore */}
            {t("training:pleaseSelectCorrect", {
              defaultValue: `Please select ${content[0].correctAnswer?.length} correct answers`,
              value: content[0].correctAnswer?.length,
            })}
          </div>
        )}
        {isCheckClicked && isCorrect && (
          <Button
            width="full"
            size="lg"
            style={{
              borderRadius: "12px",
              fontSize: 15,
            }}
            variant="solid"
            colorScheme="green"
            onClick={onGotIt}
          >
            {/* @ts-ignore */}
            {t("common:continue", { defaultValue: "Continue" })}
          </Button>
        )}
        {isCheckClicked && !isCorrect && (
          <Button
            width="full"
            size="lg"
            style={{
              borderRadius: "12px",
              fontSize: 15,
            }}
            variant="solid"
            colorScheme="red"
            onClick={onGotIt}
          >
            {/* @ts-ignore */}
            {t("common:gotIt", { defaultValue: "Got it" })}
          </Button>
        )}
        {!isCheckClicked && (
          <Button
            width="full"
            size="lg"
            style={{
              borderRadius: "12px",
              fontSize: 15,
            }}
            variant="solid"
            colorScheme="blue"
            onClick={onCheck}
            disabled={isDisabled()}
            isLoading={isLoading}
          >
            {/* @ts-ignore */}
            {t("common:check", { defaultValue: "Check" })}
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuizType;

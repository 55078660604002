import { LocationFieldEntity } from 'sop-commons/src/client';

export const CustomFieldTypeName: Record<
  LocationFieldEntity['fieldType'],
  string
> = {
  text: 'Textbox',
  date: 'Date',
  document: 'Attachment',
  dropDown: 'Dropdown',
  singleChoice: 'Radio',
};

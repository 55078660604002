import React, { FC, useCallback, useState } from 'react';
import { Center, FlexProps, Progress } from '@chakra-ui/react';

import { Document, Page, pdfjs } from 'react-pdf';
import { useResizeObserver } from '@wojtekmaj/react-hooks';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { DocumentCallback } from 'react-pdf/src/shared/types';

import { PdfViewerWrapper } from './pdf-viewer.styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const maxWidth = 800;

interface IProps extends Pick<FlexProps, 'style'> {
  url: string;
}

const NewPdfViewer: FC<IProps> = ({ url, style }) => {
  const [numPages, setNumPages] = useState<number>();
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  const [progress, setProgress] = useState(0);

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, {}, onResize);

  function onDocumentLoadSuccess(res: DocumentCallback): void {
    setNumPages(res.numPages);
  }

  function onError(error: Error) {
    console.log(error);
  }

  return (
    <PdfViewerWrapper style={style} ref={setContainerRef}>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onError}
        onLoadProgress={(event) => {
          setProgress(Math.round((event?.loaded / event?.total) * 100));
        }}
        externalLinkTarget='_blank'
        loading={
          <Center flex={1}>
            <Progress
              w='350px'
              height={2}
              borderRadius='4px'
              value={progress}
              colorScheme={progress >= 99 ? 'green' : 'blue'}
              isIndeterminate={progress < 1}
              hasStripe
              isAnimated
            />
          </Center>
        }
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={
              containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
            }
          />
        ))}
      </Document>
    </PdfViewerWrapper>
  );
};

export default NewPdfViewer;

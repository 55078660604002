import React, { FC, useMemo } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarLines } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  taskStatus?: string;
  formattedDueDate?: moment.Moment;
  formattedCompleteDate?: moment.Moment;
}

const DateBadge: FC<IProps> = ({
  taskStatus,
  formattedDueDate,
  formattedCompleteDate,
}) => {
  const [date, config] = useMemo(() => {
    switch (taskStatus) {
      case 'overdue':
        return [
          moment(formattedDueDate)?.format('MMM D'),
          {
            bg: 'rgba(255, 106, 85, 0.1)',
            color: 'rgba(255, 106, 85, 1)',
          },
        ];
      case 'inProgress':
      case 'locked':
        return [
          moment(formattedDueDate)?.format('MMM D'),
          {
            bg: 'rgba(244, 244, 244, 1)',
            color: 'rgba(39, 43, 48, 1)',
          },
        ];

      case 'complete':
        return [
          moment(formattedCompleteDate)?.format('MMM D'),
          {
            bg: 'rgba(181, 228, 202, 0.2)',
            color: 'rgba(131, 191, 110, 1)',
          },
        ];
      default:
        return [undefined, undefined];
    }
  }, [taskStatus, formattedDueDate, formattedCompleteDate]);

  if (config) {
    return (
      <Flex
        borderRadius='4px'
        p='10px 12px'
        width='94px'
        align='center'
        gap='5px'
        h='36px'
        fontWeight={500}
        {...config}
      >
        <FontAwesomeIcon icon={faCalendarLines as IconProp} />
        <Text>{date}</Text>
      </Flex>
    );
  }

  return <></>;
};

export default DateBadge;

import { Box, Flex, Text } from '@chakra-ui/react';
import PrimaryButton from 'atoms/PrimaryButton';
import dateFormat from 'dateformat';
import React, { FC } from 'react';
import { CardType } from 'types';
import Image from '../../../../ui-components/Image/Image';
import { ReactComponent as CloseIconDark } from '../../../../assets/images/closeIconDark.svg';
import { ReactComponent as EditIcon } from '../../../../assets/images/editIcon.svg';
import { ReactComponent as PowerUpThunderIcon } from '../../../../assets/images/powerup-menu/powerUpThunderIcon.svg';
import ActionIcons from '../ActionIcons';
import CardDeckInfo from '../CardDeckInfo';
import { cloneDeep } from '@apollo/client/utilities';
import ChapterIconComponent from 'pages/Chapters/ChapterIconComponent';
import { ICON_TYPE } from 'delightree-editor';
import { IconImage } from 'ui-components';
import { getImage } from '../../../../utils';

interface IProps {
  card: any;
  fromTraining?: boolean;
  fromDeck?: boolean;
  hoverFunctionalityText?: string;
  shouldShowHoverFunctionality?: boolean;
  type?: string;
  isEdit?: boolean;
  handleCardEdit?: (id: string) => void;
  handleRemove?: (id: string) => void;
  handleChange?: () => void;
  hoverClick?: (data: any, type: 'milestone' | 'formResponse') => void;
  handleCardClick?: (item: any) => void;
  previewHandler?: (data: any) => void;
  setCardSelected?: (data: any) => void;
  toggleSwitch?: (id: string, toggleValue: boolean) => void;
}

/**
 * <ChapterIconComponent
          type={card?.icon?.id === ICON_TYPE ? 'icon' : 'emoji'}
          sopType={card?.sopType}
          fileType={card?.fileType}
          border={undefined}
          height='60px'
          padding='.75rem'
          width='60px'
          minWidth='60px'
          borderRadius='10px'
          chapterMediaDetails={card?.mediaData?.[0]}
          chapterFileThumbnail={card?.thumbnail}
          chapterName={card.title}
          fontSize='60px'
          chapterLink={card?.icon?.native}
          imageStyle={{
            margin: 'auto',
            width: '60px',
            height: '100%',
            alignSelf: 'center',
            borderRadius: 10,
            objectFit: 'cover',
          }}
        />
 */

const CardDeckDetails: FC<IProps> = ({
  fromTraining,
  card,
  fromDeck,
  isEdit,
  type,
  shouldShowHoverFunctionality,
  hoverFunctionalityText,
  handleCardEdit,
  handleRemove,
  handleChange,
  setCardSelected,
  previewHandler,
  handleCardClick,
  hoverClick,
  toggleSwitch,
}) => {
  const getValue = (item: any, key: keyof any) => {
    if (item.cardType === CardType.CustomForm) {
      // @ts-ignore
      return item?.form?.[key] || item?.[key];
    }
    return item?.[key];
  };

  return (
    <div className='card-right' style={{ alignItems: 'center' }}>
      <div
        className='img-cover'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => {
          handleCardClick && handleCardClick(card);
          if (fromTraining) {
            let item = cloneDeep(card);
            let _item = {
              ...item,
              type,
            };
            setCardSelected?.(item);
          }
        }}
      >
        {card.type === 'sop' ? (
          <IconImage
            icon={card?.icon}
            name={card?.title}
            files={card?.files}
            thumbnail={card?.thumbnail}
            boxSize={66}
            iconFontSize='30px'
            borderRadius='6px'
          />
        ) : (
          // <ChapterIconComponent
          //   type={card?.icon?.id === ICON_TYPE ? 'icon' : 'emoji'}
          //   sopType={card?.sopType}
          //   fileType={card?.fileType}
          //   border={undefined}
          //   height='63px'
          //   width='66px'
          //   minWidth='66px'
          //   borderRadius='10px'
          //   chapterMediaDetails={card?.mediaData?.[0]}
          //   chapterFileThumbnail={card?.thumbnail}
          //   chapterName={card.title}
          //   fontSize='60px'
          //   chapterLink={card?.icon?.native}
          //   imageStyle={{
          //     width: '66px !important',
          //     height: '100%',
          //     alignSelf: 'center',
          //     borderRadius: 10,
          //     objectFit: 'cover',
          //   }}
          // />
          <Image
            src={getImage(getValue(card, 'thumbnail'))}
            width={66}
            height={63}
            style={{
              objectFit: 'cover',
              minWidth: '66px',
            }}
          />
        )}
        <div className='type-img-cover' />
      </div>
      <Flex
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'full'}
        flexWrap={'wrap'}
      >
        <Box id='card-deck-info' maxWidth={'40%'}>
          <CardDeckInfo card={card} fromTraining={fromTraining} type={type} />
        </Box>
        <Box id='action-icons' maxWidth={'60%'} width={'60%'}>
          <ActionIcons
            card={card}
            fromDeck={fromDeck}
            isEdit={isEdit}
            shouldShowHoverFunctionality={shouldShowHoverFunctionality}
            hoverFunctionalityText={hoverFunctionalityText}
            handleRemove={handleRemove}
            handleChange={handleChange}
            previewHandler={previewHandler}
            hoverClick={hoverClick}
            toggleSwitch={toggleSwitch}
          />
        </Box>
      </Flex>
      {/* <div
              className='card-info-cover'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div
                  className={
                    fromDeck ? 'card-text-elips-preview' : 'card-text-elips'
                  }
                >
                  {fromTraining && type && (
                    <p style={{ textTransform: 'capitalize' }}>{type}</p>
                  )}
                  {!fromTraining && (
                    <Text as='p' isTruncated>
                      {card?.category}
                    </Text>
                  )}

                  <span className='card-title'>{getValue(card, 'title')}</span>
                  <Text as={'b'} isTruncated maxWidth={'100px'}>{getValue(card, 'title')}</Text>
                  {!fromTraining && (
                    <p>
                      Last updated :{' '}
                      {dateFormat(card?.updatedAt, 'dd mmm yyyy')}
                    </p>
                  )}
                </div>
                <span className='card-title' style={{ marginLeft: '10px' }}>
                  {card?.type === 'deck' &&
                    ` (${card?.cards?.length || 0} card${
                      card?.cards?.length < 2 ? '' : 's'
                    })`}
                </span>
              </div>
              <Flex justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                <Flex flexWrap={'wrap'} justifyContent={'flex-end'} gap={2}>
                  {shouldShowHoverFunctionality && card?.cardType === 'formCard' && (
                    <div style={{ marginRight: '10px' }}>
                      <PrimaryButton
                        className='action-button'
                        size='sm'
                        title={'View form responses'}
                        variant='outline'
                        onClick={() => hoverClick?.(card, 'formResponse')}
                      />
                    </div>
                  )}
                  {shouldShowHoverFunctionality && (
                    // <div style={{ marginRight: '6rem' }}>
                    <div>
                      <PrimaryButton
                        className='action-button'
                        size='sm'
                        title={hoverFunctionalityText}
                        variant='outline'
                        onClick={() => hoverClick?.(card, 'milestone')}
                      />
                    </div>
                  )}
                </Flex>
                {shouldShowHoverFunctionality && card?.cardType === 'formCard' && <div
                  className='remove-card'
                  style={{ paddingRight: '5px', cursor: 'pointer' }}
                >
                  <CloseIconDark onClick={() => handleRemove?.(card?.eid)} />
                </div>}
              </Flex>
              {!fromDeck && !isEdit && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '15px' }}>
                    <PrimaryButton
                      title='Add to deck'
                      type='button'
                      variant='outline'
                      onClick={handleChange}
                    />
                  </div>
                  <div>
                    <img
                      src={previewIcon}
                      style={{ cursor: 'pointer' }}
                      onClick={() => previewHandler?.(card)}
                      alt='preview'
                    />
                  </div>
                </div>
              )}
              {!fromDeck && isEdit && <img src={closeIcon} alt='close' />}
            </div> */}
      {fromDeck && card?.cardType !== 'formCard' && (
        <>
          {type?.toLowerCase() === 'card' ? (
            <div
              id='edit-icon-card-deck-details'
              className='edit-card'
              style={{ paddingRight: '5px', cursor: 'pointer' }}
            >
              <div className='edit-icon-wrapper'>
                <EditIcon onClick={() => handleCardEdit?.(card?.eid)} />
              </div>
            </div>
          ) : (
            // <div
            //   className='edit-card'
            //   style={{ paddingRight: '5px', cursor: 'pointer' }}
            // >
            //   <EditIcon onClick={() => handleCardEdit?.(card?.eid)} />
            // </div>
            <div
              style={{
                paddingRight: '5px',
                height: '20px',
                width: '27px',
              }}
            />
          )}
          <div
            id='remove-card'
            className='remove-card'
            style={{ paddingRight: '5px', cursor: 'pointer' }}
          >
            <div className='close-icon-wrapper'>
              <CloseIconDark onClick={() => handleRemove?.(card?.eid)} />
            </div>
          </div>
          {card?.type === 'card' && (
            <div style={{ height: '20px', width: '20px' }}>
              <PowerUpThunderIcon fill='#FFC700' />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CardDeckDetails;

CardDeckDetails.displayName = 'CardDeckDetails';

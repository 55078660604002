import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../../ui-components';

import { InfoFormValues } from '../ProfileInfoForm';

interface IProps {
  onSubmit: (values: InfoFormValues) => Promise<void>;
}

const UpdateButton: FC<IProps> = ({ onSubmit }) => {
  const { t } = useTranslation(['common', 'profile', 'invite']);

  const { handleSubmit, watch } = useFormContext<InfoFormValues>();

  const isDeleted = watch('isDeleted');

  const _onSubmit = async (values: InfoFormValues) => {
    await onSubmit(values);
  };

  return (
    <ActionButton
      minW='140px'
      mt={6}
      colorScheme='blue'
      fontSize='15px'
      fontWeight='600'
      px={4}
      isDisabled={isDeleted}
      actionFn={handleSubmit(_onSubmit)}
    >
      {t('common:update')}
    </ActionButton>
  );
};

UpdateButton.displayName =
  'pages/Profile/TabComponents/InfoComponent/ActionButtons/UpdateButton';

export default UpdateButton;

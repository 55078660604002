import { IFormInput, RepeatDetails } from './task.types';
import { AddTaskInput } from './task.graphql';
import { toArray } from '../../../utils/utils';
import { getPublishDate } from './validation/helper';
import { getRepeatString } from './repeat-string';
import { getEndCondition } from './end-condition';

export const getRepeatDetails = (values: IFormInput): RepeatDetails<string> => {
  const result = {
    taskRunFor: values.taskRunFor,

    startDateType: values.startDateType,
    startDate: values.startDate?.toString(),
    startTime: values.startTime?.toString(),

    taskDue: values.taskDue,
    taskDueDate: values.taskDueDate?.toString(),
    dueTime: values.dueTime?.toString(),

    assignToType: values.assignToType,
  };

  if (values.taskRunFor === 'once') {
    return result;
  }

  return Object.assign(result, {
    repeatMonthly: values.repeatMonthly?.toString(),
    repeatYearly: values.repeatYearly?.toString(),

    noOfDays: values.noOfDays,
    weekDays: values.weekDays,

    repeatCycle: values.repeatCycle,
  });
};

export const dataTransformToSubmit = (values: IFormInput): AddTaskInput => {
  const startDate = getPublishDate(
    values.startDateType,
    values.startDate,
    values.startTime
  ).toISOString();

  const data: AddTaskInput = {
    title: values.title,
    description: values.description,

    thumbnail: undefined,

    // Schedule
    startDate: startDate,
    isRepeating: values.taskRunFor === 'repeat',

    repeatString: getRepeatString(values),

    endCondition: getEndCondition(values),

    contents: toArray(values.contents).map((val) => ({
      eid: val.eid,
      title: val.title,
      type: val.type,
      backgroundColor: val.backgroundColor,
      isMandatory: val.isMandatory,
    })),

    assignedLocationsType: values.assignedLocationsType,
    assignedToLocation: values.assignedToLocation || [],
    assignedToRole: values.assignedToRole || [],
    assignedToUsers: values.assignedToUsers || [],

    supervisors: values.supervisors || [],
  };

  if (values.taskRunFor === 'repeat') {
    data.repeatCycle = values.repeatCycle;

    if (values.repeatCycle === 'days') {
      data.hasCustomRepeat = true;
      data.customRepeat = {
        days: +values.noOfDays!,
        time: '23:59:59',
        type: 'EVERY_N_DAYS',
      };
    }
  }

  data.repeatDetails = getRepeatDetails(values);

  return data;
};

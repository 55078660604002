import { Box, Flex, FormControl, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormInput, PrimaryButton } from 'atoms';
import React, { ChangeEvent, FC } from 'react';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import Loader from 'sub-components/Loader';
import PowerUpUploadSkeleton from 'sub-components/PowerUpUploadSkeleton';
import { faGif } from '@fortawesome/pro-light-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { actionCardBackgroundColorList } from 'utils';
import { cloneDeep } from '@apollo/client/utilities';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { ICardData } from './MilestoneModal';
import { fileSize } from 'utils/fileSize';
import { getUrlFileSize } from 'utils/getUrlFileSize';
import { encodeSpaces } from 'utils/encodeSpaces';

interface IProps {
  cardData: any;
  isEdit: boolean;
  isUploading: boolean;
  file: string | File | null;
  nameChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  onClose: (operation: 'close' | 'save', data?: any) => void;
  onFileDrop: (fileLists: File[]) => Promise<void>;
  resetHandler: () => void;
  setCardData: React.Dispatch<React.SetStateAction<ICardData>>;
}

const MilestoneModalContent: FC<IProps> = ({
  cardData,
  isEdit,
  isUploading,
  file,
  nameChangeHandler,
  onClose,
  onFileDrop,
  resetHandler,
  setCardData,
}) => {
  console.log(cardData);
  const userData = useReactiveVar(userObj);
  const { t } = useTranslation(['card', 'common', 'training']);

  return (
    <Flex w='60%' mr='2rem' flexDir='column'>
      <GenericColorHeader
        color='#b5e4ca'
        title={
          isEdit
            ? t('training:editMilestoneHeading')
            : t('training:createMilestoneHeading')
        }
      />

      <Flex h='full' flexDir='column' justify='space-between' mt='1rem'>
        <Box>
          <Box>
            <Text fontSize='14px' p={0} fontWeight={600} mt='2rem'>
              {t('training:milestoneName')}
            </Text>
            <Box>
              <FormControl mt={3}>
                <FormInput
                  id='name'
                  placeholder=''
                  value={cardData?.mileStoneName}
                  size='lg'
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    nameChangeHandler(e);
                  }}
                />
              </FormControl>
            </Box>
          </Box>
          <Flex flexDir='column' mt='2rem'>
            <Box mb='1rem'>
              <TitleHeader title={t('card:addImageGif')} />
            </Box>
            {isUploading && (
              <Flex>
                <Loader />
              </Flex>
            )}
            {!(file instanceof File) &&
              typeof file !== 'string' &&
              !cardData?.milestoneUrl && (
                <PowerUpUploadSkeleton
                  accept={{
                    'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
                  }}
                  onFileDrop={onFileDrop}
                  onBlur={async (e) => {
                    let newValue = e.target.value;
                    console.log('on change new value : ', newValue);
                    let parsedNewValue = encodeSpaces(newValue);
                    const size = await getUrlFileSize(parsedNewValue);
                    setCardData({
                      ...cardData,
                      milestoneUrl: parsedNewValue,
                      milestoneFileSize: size,
                      milestoneAddedBy: userData?.name,
                      milestoneDateAdded: moment()?.toISOString(),
                    });
                  }}
                />
              )}
            {(cardData?.milestoneUrl || file instanceof File) && !isUploading && (
              <Flex justify='space-between' align='center' w='full'>
                {/* <Image src={milestoneUrl} boxSize='30px' borderRadius='8px' /> */}
                <Flex gap='10px' w='95%'>
                  <FontAwesomeIcon
                    icon={faGif as IconProp}
                    color='#6F767E'
                    size='2x'
                  />
                  <Flex flexDir='column'>
                    <Text isTruncated maxW='250px' fontWeight={600}>
                      {cardData?.milestoneUrl}
                    </Text>
                    <Flex gap='2px'>
                      <Text>{fileSize(cardData?.milestoneFileSize)},</Text>
                      <Text>{t('card:uploadedBy')}&nbsp;</Text>
                      <Text bg='#CABDFF66' isTruncated maxW='100px'>
                        {cardData?.milestoneAddedBy},{' '}
                      </Text>
                      <Text>
                        {moment(cardData?.milestoneDateAdded)?.fromNow(false)}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex w='5%'>
                  <FontAwesomeIcon
                    icon={faTrashCan as IconProp}
                    onClick={resetHandler}
                    color='#FF6A55'
                    fontSize='18px'
                    cursor='pointer'
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
          {/* <Box>
            <Text fontSize='14px' fontWeight={600} p={0} mt='2rem'>
              {t('training:milestoneBackgroundColor')}
            </Text>
            <Flex mt='1rem'>
              {actionCardBackgroundColorList?.map((item, index) => {
                let style = {};
                if (item === cardData?.backgroundColor) {
                  style = {
                    border: 'solid 1.5px #2a85ff',
                  };
                }

                const onChangeColor = () => {
                  // setSelectedColor(item);
                  cardData.backgroundColor = item;
                  console.log('Card Dara', cardData);
                  let newCardData = cloneDeep(cardData);
                  setCardData({ ...newCardData });
                };
                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor: item,
                      minWidth: '48.9px',
                      maxWidth: '48.9px',
                      minHeight: '48.9px',
                      maxHeight: '48.9px',
                      borderRadius: '58.9px',
                      boxShadow: '0 7px 22px 0 rgb(86 84 104 / 20%)',
                      marginRight: '11px',
                      cursor: 'pointer',
                      ...style,
                    }}
                    onClick={onChangeColor}
                  />
                );
              })}
            </Flex>
          </Box> */}
          <Box>
            <Text fontSize='14px' fontWeight={600} p={0} mt='2rem'>
              {t('training:whatIsMilestone')}
            </Text>
            <Text p={0} m={0} fontSize='14px'>
              {t('training:milestoneDescription')}
            </Text>
          </Box>
        </Box>
        <Flex justify='space-between' mt={4}>
          <PrimaryButton
            style={{ width: '40%' }}
            size='lg'
            title={t('common:cancel')}
            variant='outline'
            onClick={() => onClose('close', null)}
          />
          <PrimaryButton
            style={{ width: '40%' }}
            size='lg'
            title={isEdit ? t('common:update') : t('common:create')}
            variant='solid'
            colorScheme='blue'
            disabled={!cardData?.mileStoneName?.trim()}
            onClick={() => onClose('save', cardData)}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MilestoneModalContent;

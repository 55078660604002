import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { JSONContent } from 'delightree-editor';
import PreviewHeader, { ViewMode } from './PreviewHeader';

import ChapterDesktop from './ChapterDesktop';
import ChapterMobile from './ChapterMobile';
import ChapterTablet from './ChapterTablet';

import { IFormInput } from '../../../sub-components/ChapterEditor/chaptor.types';
import PreviewBottom from './PreviewBottom';
import { useSmartPageHandler } from './useSmartPageHandler';
import {
  UserDetailsComponent,
  UserDetailsRef,
} from '../../../sub-components/UserLocationDetails';
import { useSubmitForm } from '../../../sub-components/forms';
import { useFormContext } from 'react-hook-form';
import { ICustomFormInput } from '../ChapterView/ChapterView';

interface EventData {
  message: string;
  data: IFormInput;
}

interface IProps {
  onClose?: () => void;
}

const ChapterPreview: FC<IProps> = ({ onClose }) => {
  const useDetailRef = useRef<UserDetailsRef>(null);
  const { getValues, setValue } = useFormContext<ICustomFormInput>();
  const submitForm = useSubmitForm();
  const [chapterData, setChapterData] = useState<JSONContent>();
  const [title, setTitle] = useState<string>();
  // const [smartPageEnabled, setSmartPageEnabled] = useState<boolean>(
  //   localStorage.getItem('smartPageEnabled') === 'true'
  // );
  const [viewMode, setViewMode] = useState<ViewMode>('desktop');
  const [selectedIndex, setSelectedIndex] = useState(0);

  // useEffect(() => {
  //   window.opener?.postMessage(
  //     {
  //       message: 'chapterPreviewLoaded',
  //     },
  //     '*'
  //   );
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem('smartPageEnabled', String(smartPageEnabled));
  // }, [smartPageEnabled]);

  useEffect(() => {
    let data = getValues();
    if (data?.content) {
      setChapterData(data.content);
    }
    if ('smartPage' in data) {
      // setSmartPageEnabled(data?.smartPage === true);
      setValue('smartPage', data?.smartPage === true);
    }
    if ('title' in data) {
      setTitle(data?.title);
    }
    if (data?.content) {
      setChapterData(data?.content);
    }
  }, []);

  // useEffect(() => {
  //   function listener(event: MessageEvent<EventData>) {
  //     const message = event?.data?.message;
  //     const data = event?.data?.data;
  //     switch (message) {
  //       case 'isChapterPreviewReady':
  //         event.source?.postMessage({
  //           message: 'chapterPreviewIsReady',
  //         });
  //         break;
  //       case 'initChapterPreview':
  //         if (data?.content) {
  //           setChapterData(data.content);
  //         }
  //         if ('smartPage' in data) {
  //           setSmartPageEnabled(data?.smartPage === true);
  //         }
  //         if ('title' in data) {
  //           setTitle(data?.title);
  //         }
  //         break;
  //       case 'updateChapterPreview':
  //         if (data?.content) {
  //           setChapterData(data.content);
  //         }
  //         if ('smartPage' in data) {
  //           setSmartPageEnabled(data?.smartPage === true);
  //         }
  //         if ('title' in data) {
  //           setTitle(data?.title);
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   }

  //   window.addEventListener('message', listener);

  //   return () => {
  //     window.removeEventListener('message', listener);
  //   };
  // }, []);

  const smartPages = useSmartPageHandler(
    chapterData,
    getValues('smartPage') || false
  );

  // fall backing the selectedIndex to last index
  useEffect(() => {
    if (smartPages?.length < selectedIndex + 1) {
      const nextIndex = smartPages.length - 1;
      setSelectedIndex(nextIndex >= 0 ? nextIndex : 0);
    }
  }, [smartPages, selectedIndex]);

  const onItemClick = useCallback((event: MouseEvent, node: any) => {
    switch (node?.type?.name) {
      case 'member':
        useDetailRef.current?.openDetail('user', node.attrs);
        break;
      case 'location':
        useDetailRef.current?.openDetail('branch', node.attrs);
        break;
      case 'form':
        submitForm({
          formId: node.attrs?.eid,
          title: node.attrs?.label,
        });
        break;
      case 'chapter':
        window.open(`/chapters/view/${node.attrs?.eid}`, '_blank');
        break;
    }
  }, []);

  return (
    <Flex height='full' flexDir='column' gap='28px'>
      <Box mt={8}>
        <PreviewHeader
          viewMode={viewMode}
          onModeChange={setViewMode}
          smartPage={getValues('smartPage')}
          onClose={onClose}
          // updateSmartPage={setSmartPageEnabled}
        />
      </Box>
      <Flex justify='center' pb='16px' overflow={'scroll'} h='75vh'>
        {viewMode === 'desktop' && (
          <ChapterDesktop
            value={chapterData}
            onItemClick={onItemClick}
            chapterIcon={getValues('chapterIcon')}
            iconType={getValues('iconType')}
          />
        )}
        {viewMode === 'tablet' && (
          <ChapterTablet
            selectedIndex={selectedIndex}
            title={title}
            value={smartPages}
            onItemClick={onItemClick}
            chapterIcon={getValues('chapterIcon')}
            iconType={getValues('iconType')}
          />
        )}
        {viewMode === 'mobile' && (
          <ChapterMobile
            selectedIndex={selectedIndex}
            title={title}
            value={smartPages}
            onChange={setSelectedIndex}
            onItemClick={onItemClick}
            chapterIcon={getValues('chapterIcon')}
            iconType={getValues('iconType')}
          />
        )}
        <PreviewBottom
          viewMode={viewMode}
          smartPages={smartPages}
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
        />
      </Flex>
      <UserDetailsComponent ref={useDetailRef} />
    </Flex>
  );
};

export default ChapterPreview;

import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { valueToPercent } from '@chakra-ui/utils';
import { IPercentDetails } from 'sub-components/LocationLaunchDetail/types/types';

const formatNumber = new Intl.NumberFormat('en-IN', {
  maximumFractionDigits: 2,
}).format;

const PercentDetails: FC<IPercentDetails> = (props) => {
  const { completed, totalCount, orientation = 'vertical' } = props;

  const percent = valueToPercent(completed, 0, totalCount);

  const getLabel = () => {
    if (completed === 0) {
      return 'Yet to complete their 1st task';
    } else {
      return `${formatNumber(
        percent || 0
      )}% complete (${completed}/${totalCount})`;
    }
  };

  return (
    <Box
      fontWeight={500}
      fontSize={orientation === 'vertical' ? '12px' : '15px'}
      color='#A6B1BC'
      h='fit-content'
      w='fit-content'
    >
      {getLabel()}
    </Box>
  );
};

export default PercentDetails;

export const modalRowStyle = (theme) => {
  return {
    position: 'relative',
    // border: `1px solid ${theme.borderColor.primary}`,
    display: 'flex',
    width: '100%',
    fontSize: '14px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // borderBottom: `1px solid ${theme.borderColor.primary}`,
    cursor: 'pointer',
    paddingInline: '24px',

    // '&:not(:last-child)': {
    //   borderBottom: 'none',
    // },

    '&::after': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% - 48px)',
      bottom: 0,
      height: '1px',
      background: '#EFEFEF',
    },

    '&[aria-checked="true"]': {
      backgroundColor: '#2A85FF1F',
    },

    '&:hover': {
      backgroundColor: '#EFEFEF',
    },
  };
};

export const modalColumnStyle = () => {
  return {
    flex: 1,
    padding: '8px 0px',
    overflow: 'hidden',
    height: 'unset !important',
    display: 'flex',
    alignItems: 'center',
  };
};

export const avatarStyle = () => {
  return {
    display: 'inline-block',
    float: 'left',
    width: '36px',
    minWidth: '36px',
    height: '36px',
    marginRight: '8px',
  };
};

export const nameStyle = () => {
  return {
    //margin: '10px',
    // width: 'calc(100% - 50px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
};

export const selectionColumnStyle = () => {
  return {
    padding: '8px',
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
};

export const selectionBoxStyle = (inactiveStateImg, activeStateImg, theme) => {
  return {
    display: 'none',
    ' + label': {
      display: 'block',
      cursor: 'pointer',
      userSelect: 'none',
      padding: '8px',
      width: '100%',
      mask: `url(${inactiveStateImg}) center center no-repeat`,
      backgroundColor: `${theme.secondaryTextColor}`,
    },
    '&:checked + label': {
      width: '100%',
      mask: `url(${activeStateImg}) center center no-repeat`,
      backgroundColor: `${theme.secondaryTextColor}`,
    },
  };
};

export const roleStyle = () => {
  return {
    width: 'auto',
    height: '15px',
    flexGrow: 0,
    //margin: '10px 45px -15px -212px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.12px',
    textAlign: 'left',
    color: '#6f767e',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',

    '.name__role': {
      "&[data-role]:not([data-role=''])": {
        color: '#2a85ff',
      },
    },
  };
};

export const locationBullet = () => {
  return {
    width: '4px',
    height: '4px',
    flexGrow: '0',
    backgroundColor: '#354b76',
    borderRadius: '2px',
    marginLeft: '5px',
    marginRight: '5px',
  };
};

export const roleBox = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
  };
};

export const isChecked = () => {
  return {
    backgroundColor: '#2a85ff',
    borderRadius: '20px',
    padding: '2.5px 2.5px 1.9px 1.9px',
    width: '18px',
    height: '18px',
    flexGrow: '0',
  };
};

export const unChecked = () => {
  return {
    width: '18px',
    height: '18px',
    flexGrow: '0',
    padding: '2.5px 2.5px 1.9px 1.9px',
    borderRadius: '13.7px',
    border: 'solid 1.2px #c4c4c4',
  };
};

export const locationElipsis = () => {
  return {
    fontSize: 12,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // width: '76px',
    flex: 1,
    color: '#9a9fa5',
  };
};

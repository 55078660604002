import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Flex, FormControl } from '@chakra-ui/react';

import { ChannelConfig } from '../../../../../../types';

import RoleDropDown from './RoleDropDown';
import MemberSelection from './MemberSelection';
import { IFormInput } from '../../create-channel.types';

interface IProps {
  control: Control<IFormInput>;
  permission: ChannelConfig['inviteUser']['type'];
  prefix: keyof Omit<ChannelConfig, 'channelCantLeave'>;
}

const SelectRoleAndMember: FC<IProps> = ({ control, permission, prefix }) => {
  if (permission !== 'authRole') {
    return null;
  }

  return (
    <>
      <Controller
        control={control}
        name={`${prefix}.authRoles`}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState?.error}>
              <Flex flexDir='column' gap={4} paddingY='16px'>
                <RoleDropDown
                  error={fieldState.error}
                  value={field.value}
                  onChange={field.onChange}
                />
              </Flex>
            </FormControl>
          );
        }}
      />

      <MemberSelection prefix={prefix} control={control} />
    </>
  );
};

export default SelectRoleAndMember;

import React, { FC } from 'react';
import {
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';

import { BoxHeader } from '../../../../../ui-components';

import CategoryListContainer from './CategoryListContainer';
import TriggerButton from './TriggerButton';

interface IProps {
  category: string[];
  onCategoryChange: (category: string[]) => void;
}

const FormCategoryFilter: FC<IProps> = ({ category, onCategoryChange }) => {
  return (
    <Popover placement='bottom-end' isLazy lazyBehavior='unmount'>
      {/*// @ts-ignore */}
      <PopoverTrigger>
        <TriggerButton
          category={category}
          onCategoryChange={onCategoryChange}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader borderBottomWidth='0'>
          <Flex justify='space-between' alignItems='center'>
            <BoxHeader color='#CABDFF' title='Filters' fontSize='18px' />
            <PopoverCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        </PopoverHeader>
        <PopoverBody>
          <CategoryListContainer
            category={category}
            onCategoryChange={onCategoryChange}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default FormCategoryFilter;

import { useReactiveVar } from '@apollo/client';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import { useState, useEffect, useCallback } from 'react';
import { CategoryEntity } from 'sop-commons/src/client';
import useCombinedStore from 'zustandStore/store';
import { useUserData } from './useUserDataQuery';

export function useFolderSubFolder(eid?: string) {
  const [folderName, setFolderName] = useState('');
  const [subFolders, setSubFolders] = useState<CategoryEntity[]>([]);
  const [subFoldersPresent, setSubFoldersPresent] = useState(false);
  const [parentFolder, setParentFolder] = useState<CategoryEntity | undefined>(
    undefined
  );
  // const { foldersList: entities } = useCombinedStore();
  const entities = useReactiveVar(foldersListVar);

  const findEntity = useCallback(
    (
      entities: CategoryEntity[],
      eid: string,
      parent?: CategoryEntity
    ): CategoryEntity | undefined => {
      for (const entity of entities) {
        if (entity?.eid === eid) {
          setSubFolders(entity?.children || []);
          setSubFoldersPresent(
            entity?.children && entity?.children?.length > 0
          );
          if (parent) {
            setParentFolder(parent);
          }
          return entity;
        }
        const childResult = findEntity(entity?.children || [], eid, entity);
        if (childResult) {
          return childResult;
        }
      }
      return undefined;
    },
    []
  );

  const _allFoldersList = useCallback(
    (sort?: 'asc' | 'desc', shouldExcludeParentFolder?: boolean) => {
      let folders = entities ? [...entities] : [];

      if (shouldExcludeParentFolder && parentFolder) {
        folders = folders.filter((folder) => folder.eid !== parentFolder.eid);
      }

      if (sort) {
        folders.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore case
          const nameB = b.name.toUpperCase(); // ignore case
          if (nameA < nameB) {
            return sort === 'asc' ? -1 : 1;
          }
          if (nameA > nameB) {
            return sort === 'asc' ? 1 : -1;
          }
          return 0;
        });
      }

      return folders;
    },
    [entities, parentFolder]
  );

  const allSubFoldersList = useCallback(() => {
    const subFoldersList = entities
      ? entities.flatMap((entity) => entity.children || [])
      : [];
    return subFoldersList;
  }, [entities]);

  useEffect(() => {
    if (!eid || !entities || entities?.length === 0) {
      setFolderName('');
      setSubFolders([]);
      setSubFoldersPresent(false);
      setParentFolder(undefined);
      return;
    }

    const entity = findEntity(entities, eid);
    if (entity) {
      setFolderName(entity?.name);
    } else {
      setFolderName('');
      setSubFolders([]);
      setSubFoldersPresent(false);
      setParentFolder(undefined);
    }
  }, [eid, entities, findEntity]);

  const getAllFolderSubFolderList = () => {
    let arr: CustomCategoryEntity[] = [];
    entities?.forEach((list) => {
      if (list?.children?.length > 0) {
        list?.children?.forEach((ch) => {
          arr?.push({
            ...ch,
            parentFolderId: list?.eid,
            parentFolderName: list?.name,
            type: 'subFolder',
          });
        });
      }
      arr?.push({
        ...list,
        parentFolderId: undefined,
        parentFolderName: undefined,
        type: 'folder',
      });
    });
    return arr;
  };

  return {
    folderName,
    subFolders,
    subFoldersPresent,
    parentFolder,
    _allFoldersList,
    allSubFoldersList,
    getAllFolderSubFolderList,
  };
}

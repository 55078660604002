import React, { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import moment from 'moment';

import TitleHeader from '../../../../CardEditor/TitleHeader';
import CheckButton from '../CheckButton';
import { IFormInput } from '../../task.types';
import WeekDaysSelect from './WeekDaysSelect';
import DueTimeSelect from '../DueTimeSelect';
import { DayInputField } from '../Components';
import { CustomDatePicker } from '../CustomDatePicker';
import DayOnlyPicker from './DayOnlyPicker';

interface IProps {
  isEdit?: boolean;
}

const RepeatingSchedule: FC<IProps> = ({ isEdit }) => {
  const { t } = useTranslation(['task']);
  const { control, setValue } = useFormContext<IFormInput>();

  const repeatCycle = useWatch<IFormInput, 'repeatCycle'>({
    name: 'repeatCycle',
  });

  const resetField = (value: IFormInput['repeatCycle']) => {
    switch (value) {
      case 'daily':
        setValue('weekDays', undefined);
        setValue('repeatYearly', undefined);
        break;
      case 'weekly':
        // setValue('weekDays', undefined);
        setValue('repeatYearly', undefined);
        break;
      case 'monthly':
        setValue('weekDays', undefined);
        setValue('repeatYearly', undefined);

        break;
      case 'yearly':
        setValue('weekDays', undefined);
        break;
      case 'days':
        setValue('weekDays', undefined);
        setValue('repeatYearly', undefined);
        break;
    }
  };

  const { minDate, maxDate } = useMemo(() => {
    return {
      minDate: moment().startOf('year').toDate(),
      maxDate: moment().endOf('year').toDate(),
    };
  }, []);

  return (
    <div>
      <TitleHeader
        title={t('task:selectRepeatSch')}
        desc={t('task:selectRepeatSchDesc')}
      />

      <Controller
        control={control}
        name='repeatCycle'
        rules={{
          required: t('task:validation.repeatCycleRequired'),
          deps: ['endCondition.endDate'],
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              my={3}
              isInvalid={!!fieldState.error}
              isReadOnly={isEdit}
            >
              <Flex gap={2}>
                <CheckButton
                  isReadOnly={isEdit}
                  isSelected={field.value === 'daily'}
                  onClick={() => callAll(field.onChange, resetField)('daily')}
                >
                  <Trans t={t} i18nKey='task:daily' />
                </CheckButton>

                <CheckButton
                  isReadOnly={isEdit}
                  isSelected={field.value === 'weekly'}
                  onClick={() => callAll(field.onChange, resetField)('weekly')}
                >
                  <Trans t={t} i18nKey='task:weekly' />
                </CheckButton>

                <CheckButton
                  isReadOnly={isEdit}
                  isSelected={field.value === 'monthly'}
                  onClick={() => callAll(field.onChange, resetField)('monthly')}
                >
                  <Trans t={t} i18nKey='task:monthly' />
                </CheckButton>

                <CheckButton
                  isReadOnly={isEdit}
                  isSelected={field.value === 'yearly'}
                  onClick={() => callAll(field.onChange, resetField)('yearly')}
                >
                  <Trans t={t} i18nKey='task:yearly' />
                </CheckButton>

                <CheckButton
                  isReadOnly={isEdit}
                  isSelected={field.value === 'days'}
                  onClick={() => callAll(field.onChange, resetField)('days')}
                >
                  <Trans t={t} i18nKey='task:everyNoOfDays' />
                </CheckButton>
              </Flex>

              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <DayInputField
        isReadOnly={isEdit}
        name='noOfDays'
        visible={repeatCycle === 'days'}
        minValue={2}
        deps={['endCondition.endDate']}
      />

      {repeatCycle === 'yearly' && (
        <Box pb={3}>
          <CustomDatePicker
            readOnly={isEdit}
            name='repeatYearly'
            isSelected
            placeholderText={t('task:placeholder.chooseDate')}
            rules={{
              required: t('task:validation.repeatYearly'),
            }}
            showError
            minDate={minDate}
            maxDate={maxDate}
            dateFormat='d MMMM'
          />
        </Box>
      )}

      {repeatCycle === 'monthly' && (
        <Box pb={3}>
          <DayOnlyPicker isEdit={isEdit} />
        </Box>
      )}

      <WeekDaysSelect isEdit={isEdit} />

      <DueTimeSelect
        isReadOnly={isEdit}
        isHidden={
          !['daily', 'weekly', 'yearly', 'monthly'].includes(
            repeatCycle as never
          )
        }
      />
    </div>
  );
};

export default RepeatingSchedule;

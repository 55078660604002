import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import './TrainingPathTable.scss';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import moment from 'moment';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import MilestoneSuccess from '../../assets/images/milestone_success.png';
import SearchInput from 'atoms/SearchInput';
import ProgressTimeline from 'ui-components/ProgressTimeline';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { Box, Center, Flex, Tooltip, useToast } from '@chakra-ui/react';
import { getSingleTrainingData } from 'sop-commons/Query/Training';
import { REASSIGN_TRAINING_COMPLETE } from 'sop-commons/Mutation/Training';
import Responsive from '../../responsive/index';
import { getAllTrainings } from './training-path-table.graphql';
import { TrainingData } from '../../pages/Training/training.types';
import LocationPseudoMember from 'pages/Training/LocationPseudoMemberModal';
import TrainingPathSkeleton from './TrainingPathSkeleton';
import { useRetakeTraining } from '../../ui-components/Training';
import { TrainingResponse } from './training-path.types';
import PathName from '../../pages/Training/Training/PathName';
import { DownloadIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  DemoActionCell,
  DemoProgressCell,
  DemoStatusCell,
} from './DemoComponent';
import { SortingTable } from 'sub-components/ChakraTable/SortingTable';
import { PageInfo } from 'types';
import EmptyState from 'sub-components/EmptyState';
import { useHistory } from 'react-router-dom';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { BoxHeader } from '../../ui-components';

function filterCard(val: any) {
  return val?.type === 'card' || val?.type === 'sop';
}

interface TrainingPathTableProps {
  showBackNavigation: boolean;
  backNavigationText?: string;
  backNavigationDivStyle?: {};
  backNavigationSpanStyle?: {};
  backNavigationClickHandler?: () => void;
  heading: string;
  showHeading: boolean;
  searchQuery?: string;
  showSearchInput: boolean;
  showMainTitle: boolean;
  mainTitle?: string;
  shouldHideOnNoData?: boolean;
}

const TrainingPathTable: FC<TrainingPathTableProps> = (
  props: TrainingPathTableProps
) => {
  const { t } = useTranslation(['common', 'training']);
  const dashboardCtx = useContext(DashboardDataContext);
  const history = useHistory();
  const retakeTraining = useRetakeTraining();
  const [progressTimelineModal, setProgressTimelineModal] = useState(false);
  const [showRemindUserChatModal, setShowRemindUserChatModal] = useState(false);
  const [showPseudoMembersModal, setShowPseudoMembersModal] = useState(false);
  const [selectedTrainingData, setSelectedTrainingData] = useState<any>(null);
  const [quizCardDetails, setQuizCardDetails] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [progressData, setProgressData] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [userTrainingList, setUserTrainingList] = useState<any>([]);
  const ref = useRef(null);
  const userObject = useReactiveVar(userObj);
  const toast = useToast({
    position: 'top-right',
  });
  const [currentPageData, setCurrentPageData] = useState<PageInfo>({
    currentPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    itemCount: 1,
    pageCount: 1,
    perPage: 1,
  });

  const isDemoAccount = !!userObject?.entity?.isDemo;

  useEffect(() => {
    getUserTrainingHandler();
  }, []);

  useEffect(() => {
    if (props?.searchQuery || props?.searchQuery === '') {
      let currentPage = currentPageData?.currentPage;
      if (props?.searchQuery === '') {
        currentPage = 1;
        setCurrentPageData({ ...currentPageData, currentPage: 1 });
      }
      getUserTraining({
        variables: {
          page: currentPage,
          perPage: 10,
          filter: {
            assigned: true,
            status: 'active',
            query: props?.searchQuery,
          },
          sort: '_ID_DESC',
        },
      });
    }
  }, [props?.searchQuery]);

  const getUserTrainingHandler = (
    currentPage = currentPageData?.currentPage,
    query = props?.searchQuery
  ) => {
    getUserTraining({
      variables: {
        page: currentPage,
        perPage: 10,
        filter: {
          assigned: true,
          status: 'active',
          query: query,
        },
        sort: '_ID_DESC',
      },
    });
  };

  // const [singleTrainingHandler] = useLazyQuery(getSingleTrainingData, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: (data) => {
  //     let isAnyQuizCard = false;
  //     let quizCardIds: any[] = [];
  //     let _item: TrainingData = JSON.parse(JSON.stringify(data?.trainingById));
  //     _item?.trainingContentExpanded?.forEach((it) => {
  //       if (it?.cardType === 'quiz') {
  //         isAnyQuizCard = true;
  //         quizCardIds.push(it?.eid);
  //       }
  //       it.completed = false;
  //       _item?.userProgress?.map((progress) => {
  //         if (progress?.userId === userObject?.eid) {
  //           progress?.viewedContents?.forEach((content: any) => {
  //             if (it?.uid === content?.uid) {
  //               it.completed = true;
  //             }
  //           });
  //         }
  //       });
  //     });
  //     _item?.trainingContentExpanded?.push({
  //       eid: '',
  //       uid: '',
  //       type: 'finish',
  //       title: _item?.title,
  //     });
  //     setSelectedTrainingData(_item);

  //     let _userTrainingList = JSON.parse(JSON.stringify(userTrainingList));
  //     _userTrainingList.map((list: any) => {
  //       if (list?.eid === _item?.eid) {
  //         list.isRetakeLoading = false;
  //       }
  //     });
  //     setUserTrainingList(_userTrainingList);
  //   },
  //   onError: (error) => {
  //     let _userTrainingList = JSON.parse(JSON.stringify(userTrainingList));
  //     _userTrainingList.map((list: any) => {
  //       if (list?.eid === selectedTrainingData?.eid) {
  //         list.isRetakeLoading = false;
  //       }
  //     });
  //     setUserTrainingList(_userTrainingList);
  //   },
  // });

  const [ReassignTraining, { loading: reassignTrainingLoading }] = useMutation(
    REASSIGN_TRAINING_COMPLETE,
    {
      onCompleted: (response) => {
        const tempId = localStorage.getItem('retake-training');

        if (tempId === response?.ReassignTraining?.eid) {
          localStorage.removeItem('retake-training');
          localStorage.setItem(`demo-train-path-${tempId}`, 'retake');
        }

        toast({
          title: t('common:update'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        // window.location.reload();
        getUserTrainingHandler();
      },
      onError: (error) => {
        localStorage.removeItem('retake-training');
        let _userTrainingList = JSON.parse(JSON.stringify(userTrainingList));
        _userTrainingList?.forEach((list: any) => {
          if (list?.eid === selectedTrainingData?.eid) {
            list.isRetakeLoading = false;
          }
        });
        setUserTrainingList(_userTrainingList);
        toast({
          title: t('common:error'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const [
    getUserTraining,
    {
      data: userTrainingData,
      loading: userTrainingLoading,
      refetch: userTrainingRefetch,
    },
  ] = useLazyQuery<TrainingResponse>(getAllTrainings, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const data = response?.userTrainingNew;
      if (data?.items && data?.items?.length > 0) {
        dashboardCtx?.showHomePageEmptyStateHandler(false);
      }
      if (response?.userTrainingNew && response?.userTrainingNew?.pageInfo) {
        setCurrentPageData(response?.userTrainingNew?.pageInfo);
      }
      if (
        data &&
        data?.items?.[0] &&
        data?.items?.[0]?.assignedToUsers?.length
      ) {
        let _userTrainingItems = JSON.parse(JSON.stringify(data?.items));
        _userTrainingItems.forEach((item: any, index: number) => {
          let _assignedTo = item?.assignedToUsers?.filter(
            (_item: any) => _item?.eid === userObject?.eid
          );
          if ([0, 1].includes(index) && isDemoAccount) {
            item.isDemoAccount =
              isDemoAccount &&
              !localStorage.getItem(`demo-train-path-${item.eid}`);
          }
          if (_assignedTo.length > 0) {
            _assignedTo[0].assignedDate = moment(
              _assignedTo?.[0]?.assignedOn
            ).format('DD MMM YYYY');
            let _assignedToUsers = item?.assignedToUsers?.filter(
              (_item: any) => _item?.eid === userObject?.eid
            );
            let _userProgress = item?.userProgress?.filter(
              (_item: any) => _item?.userId === userObject?.eid
            );
            _assignedTo[0].totalViewedCount =
              _userProgress?.[0]?.viewedContents?.length;
            _assignedTo[0].startTime = _userProgress?.[0]?.startTime
              ? moment(_userProgress?.[0]?.startTime)?.format('DD MMM YYYY')
              : 'N/A';
            item.assignedTo = _assignedTo;
            item.assignedToUsers = _assignedToUsers;
            item.userProgress = _userProgress;
            item.isRetakeLoading = false;
          }
        });
        setUserTrainingList(_userTrainingItems);
        setSelectedTrainingData(null);
      } else {
        setUserTrainingList([]);
      }
    },
  });

  const getColumns = (isTablet: any) => {
    let tableColumns: any[] = [
      {
        Header: t('common:name'),
        accessor: 'pathName' as const,
        width: '41%',
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <PathName
              pathName={value}
              thumbnail={row?.original?.thumbnail}
              status={row?.original?.status}
              updatedAt={row?.original?.updatedAt}
              index={row.index}
              maxW='170px'
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('common:status'),
        accessor: 'trainingStatus' as const,
        width: '22%',
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <DemoStatusCell
              viewCount={value.viewCount}
              totalCount={value.totalCount}
              isDemoAccount={row?.original?.isDemoAccount}
              index={row?.original?.index}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('training:progress'),
        accessor: 'currentProgress' as const,
        width: '22%',
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <DemoProgressCell
              onClick={progressDataHandler}
              item={value.item}
              maxLength={value.maxLength}
              isDemoAccount={row?.original?.isDemoAccount}
              index={row?.original?.index}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('common:action'),
        accessor: 'action' as const,
        width: '15%',
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <DemoActionCell
              isDemoAccount={row?.original?.isDemoAccount}
              index={row?.original?.index}
              viewCount={value.viewCount}
              totalCount={value.totalCount}
              isLoading={row?.original?.isRetakeLoading}
              onResumeClick={() => {
                openTrainingModal(row?.original?.item);
              }}
              onRetakeClick={() => {
                confirmRetakeModalHandler(row?.original?.item);
              }}
            />
          );
        },
        disableSortBy: true,
      },
    ];
    if (!props?.showHeading) {
      tableColumns.push({
        Header: '',
        accessor: 'download' as const,
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <Center w='full' marginBottom='25px'>
              <Tooltip
                hasArrow
                label={
                  value?.flag || value?.totalViewedCount === value?.maxCount
                    ? t('training:download_certificate')
                    : t('training:download_training_certificate')
                }
                placement='bottom-start'
              >
                <DownloadIcon
                  cursor={
                    value?.flag || value?.totalViewedCount === value?.maxCount
                      ? 'pointer'
                      : 'no-drop'
                  }
                  fontSize='large'
                  color={
                    value?.flag || value?.totalViewedCount === value?.maxCount
                      ? '#2a85ff'
                      : 'lightgray'
                  }
                  onClick={() => {
                    (value?.flag ||
                      value?.totalViewedCount === value?.maxCount) &&
                      window.open(
                        `${process.env.REACT_APP_API_BASE_ENDPOINT}/certify/${value?.certificateId}`
                      );
                  }}
                />
              </Tooltip>
            </Center>
          );
        },
        disableSortBy: true,
      });
    }
    return tableColumns;
  };

  const progressTimelinehandler = () => {
    setProgressTimelineModal(false);
  };

  const onClose = () => {
    setShowRemindUserChatModal(false);
  };

  const progressDataHandler = (userData: any) => {
    let progressArray: any = [];
    let foundMilestone: any = [];
    if (
      userData &&
      userData?.userProgress &&
      userData?.userProgress?.length > 0
    ) {
      userData?.userProgress?.map((progress: any) => {
        foundMilestone = progress?.viewedContents?.filter(
          (content: any) => content?.type === 'milestone'
        );
        foundMilestone?.map((mile: any) => {
          let milestoneTitle = userData?.contents
            ?.filter((content: any) => {
              let res = content?.eid === mile?.uid;
              return res;
            })
            ?.map((dt: any) => dt?.title);
          mile.title = milestoneTitle?.[0];
        });
      });
      foundMilestone?.length > 0 &&
        foundMilestone?.map((milestone: any) => {
          progressArray.push({
            status:
              userData?.assignedTo?.[0]?.totalViewedCount ===
              userData?.contents?.length
                ? 'complete'
                : 'incomplete',
            date: moment(milestone?.viewedAt)?.format('DD MMM YYYY, hh:mm A'),
            name: userData?.assignedToUsers?.[0]?.name,
            milestoneName: milestone?.title,
          });
        });
    }
  };

  const getDownload = (item: any) => {
    let foundItem = item?.userProgress?.filter(
      (user: any) => user?.userId === userObject?.eid
    );
    const totalViewedCount =
      foundItem?.[0]?.viewedContents?.filter(filterCard)?.length || 0;
    const maxCount = item?.trainingItems.filter(filterCard).length;
    let isDownloadable = foundItem?.map(
      (user: any) => user?.firstCompletedAt
    )?.[0];
    return {
      flag: !!isDownloadable,
      certificateId: item?.userProgress?.[0]?.certificate,
      data: item,
      totalViewedCount,
      maxCount,
    };
  };

  const getTableData = (isTablet: any) => {
    let tableData: any = [];
    if (userTrainingList && userTrainingList?.length > 0) {
      tableData = userTrainingList?.map((item: any, index: number) => {
        const cardCount =
          item?.userProgress?.[0]?.viewedContents?.filter(filterCard)?.length ||
          0;
        const maxCount = item?.trainingItems.filter(filterCard).length;
        return {
          index: index,
          item: item,
          pathName: item?.title || '',
          thumbnail: item?.thumbnail,
          updatedAt: item?.updatedAt,
          status: item?.status,
          isDemoAccount: item?.isDemoAccount,
          currentProgress: {
            item: { ...item, totalViewedCount: cardCount },
            maxLength: maxCount,
          },
          trainingStatus: {
            viewCount: item?.assignedTo?.[0]?.totalViewedCount,
            totalCount: item?.trainingItems?.length,
          },
          action: {
            viewCount: item?.assignedTo?.[0]?.totalViewedCount,
            totalCount: item?.trainingItems?.length,
          },
          download: getDownload(item),
          isRetakeLoading: item?.isRetakeLoading,
        };
      });
    }
    return [...tableData];
  };

  const retakeTrainingHandler = (trainingData: any) => {
    let _userTrainingList = JSON.parse(JSON.stringify(userTrainingList));
    _userTrainingList?.forEach((list: any) => {
      if (list?.eid === trainingData?.eid) {
        list.isRetakeLoading = true;
      }
    });
    setUserTrainingList(_userTrainingList);
    if (trainingData?.isDemoAccount) {
      localStorage.setItem('retake-training', trainingData.eid);
    }
    return ReassignTraining({
      variables: {
        userId: userObject?.eid,
        trainingId: trainingData?.eid,
      },
    });
  };

  const confirmRetakeModalHandler = (trainingData: any) => {
    retakeTraining({
      title: t('training:retake_confirm'),
      onOk: () => retakeTrainingHandler(trainingData),
    });
  };

  const searchTrainingPathHandler = (str: string) => {
    // console.log('STRING VALUE : ', str);
    // if (str === '') {
    //   setTrainingPathData(copyTrainingPathData);
    // } else {
    //   let tempTrainingPathData = JSON.parse(JSON.stringify(trainingPathData));
    //   let filteredTrainingPathData =
    //     tempTrainingPathData.assignedToUsers.filter((userData) => {
    //       return (
    //         userData.name.toLowerCase().includes(query.toLowerCase()) ||
    //         userData.role.toLowerCase().includes(query.toLowerCase()) ||
    //         userData.authRole.toLowerCase().includes(query.toLowerCase())
    //       );
    //     });
    //   tempTrainingPathData.assignedToUsers = filteredTrainingPathData;
    //   setTrainingPathData(tempTrainingPathData);
    // }
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e?.target?.value);
    searchHandler(e?.target?.value);
  };

  const searchHandler = (query: any) => {
    searchTrainingPathHandler(query);
  };

  const openTrainingModal = (item: any) => {
    if (userObject?.type === 'user') {
      history.push('/training-play/' + item.eid);
    } else {
      setShowPseudoMembersModal(true);
      setSelectedTrainingData(item);
    }
  };

  // const getSingleTrainingHandler = (id = selectedId) => {
  //   singleTrainingHandler({
  //     variables: {
  //       eid: id,
  //     },
  //   });
  // };

  const renderComponent = () => (
    <Responsive isBigScreen isDesktop isSmallDesktop>
      <>
        <Box className='training-container'>
          {props?.showHeading && (
            <div className='training-search-container'>
              <BoxHeader
                title={props?.heading}
                fontSize='18px'
                color='#cabdff'
              />
              {userTrainingList && userTrainingList?.assignedTo && (
                <SearchInput
                  disabled={true}
                  size='md'
                  width='435px'
                  hideShortcuts={true}
                  placeholder={t('training:search_people_loc')}
                  onChange={handleQueryChange}
                  value={searchQuery}
                  className='training-search'
                  hideSearchIcon={undefined}
                />
              )}
            </div>
          )}
          <div ref={ref}>
            <TrainingPathSkeleton
              isLoading={!userTrainingList?.length && userTrainingLoading}
            />
            {!(!userTrainingList?.length && userTrainingLoading) ? (
              <SortingTable
                colorScheme='blue'
                emptyData={{
                  content: (
                    <EmptyState
                      image='TrainingPath'
                      title={t('training:nothingToLearn')}
                      description={t('training:howToStart')}
                    />
                  ),
                }}
                page={currentPageData?.currentPage}
                onPageChange={onPageChangeHandler}
                columns={getColumns(false)}
                data={getTableData(false)}
                isLoading={userTrainingLoading}
                totalRegisters={currentPageData?.itemCount}
                isResponsive
                isHidePagination={
                  (currentPageData?.currentPage === 1 &&
                    currentPageData?.hasNextPage) ||
                  currentPageData?.currentPage > 1
                    ? false
                    : true
                }
              />
            ) : null}
          </div>
          {progressTimelineModal && (
            <ProgressTimeline
              isOpen={progressTimelineModal}
              timelineData={progressData}
              onCloseClick={progressTimelinehandler}
              milestoneSuccessImg={MilestoneSuccess}
              userName={progressData?.[0]?.name}
            />
          )}
        </Box>
      </>
      {showRemindUserChatModal && (
        <SingleUserChatModal onClose={onClose} selectedUser={selectedUser} />
      )}
      <LocationPseudoMember
        isOpen={showPseudoMembersModal}
        onClose={() => setShowPseudoMembersModal(false)}
        locationId={userObject?.eid}
        selectedTrainingData={selectedTrainingData}
      />
    </Responsive>
  );

  const onPageChangeHandler = (pageData: number) => {
    setCurrentPageData({ ...currentPageData, currentPage: pageData });
    getUserTrainingHandler(pageData);
  };

  const renderData = () => {
    if (props?.shouldHideOnNoData) {
      if (userTrainingList?.length > 0) {
        return renderComponent();
      } else {
        return <></>;
      }
    } else {
      return renderComponent();
    }
  };

  return renderData();
};

export default TrainingPathTable;

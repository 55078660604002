import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { toArray } from '../../../../../../utils/utils';

import { DataNotFound } from '../common';

import { fetchTrainingQuery, TrainingResponse } from '../formChapter.graphql';
import TrainingListItem from './TrainingListItem';

import EmptyDataIcon from '../../../../../../assets/images/empty-state/task-chapter-empty-state.svg';

interface IProps {
  values: string[];
  searchQuery?: string;
}

const TrainingListContainer: FC<IProps> = ({ values = [], searchQuery }) => {
  const { data, loading } = useQuery<TrainingResponse>(fetchTrainingQuery, {
    fetchPolicy: 'network-only',
    variables: {
      perPage: values.length + 20,
      filter: {
        status: 'active',
        query: searchQuery,
      },
      sort: '_ID_DESC',
    },
  });

  const itemList = useMemo(() => {
    return toArray(data?.trackTrainingPagination?.items).filter((value) => {
      return !values?.includes(value.eid);
    });
  }, [data, values]);

  return (
    <Flex
      minH={{
        base: '260px',
        lg: '45vh',
      }}
      flex={1}
      flexDir='column'
      overflow='auto'
      position='relative'
      gap='1px'
    >
      <DataNotFound
        isLoading={loading}
        searchQuery={searchQuery}
        dataLength={itemList?.length}
        i18NotFoundKey='training:noTrainingsFound'
        i18NotFoundDescKey='training:noTrainingsFoundDesc'
        i18SearchNotFoundKey='training:noTrainingMatchFound'
        emptyImage={EmptyDataIcon}
      >
        {itemList.map((item, index) => (
          <TrainingListItem key={item.eid} data={item} index={index} />
        ))}
      </DataNotFound>
    </Flex>
  );
};

export default TrainingListContainer;

import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LocationState } from 'history';
import { useTranslation } from 'react-i18next';

import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';
import { ISelectedFolderData } from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { AddEditSubFolder } from '../components';

// import ModalContent from './ModalContent';
// import { LocationPath } from './types';

interface IProps {
  onSubFolderCreated?: () => void;
  folderDetails?: ISelectedFolderData;
}

type SubFolderCreate = (props: IProps) => void;

export const useSubFolderCreate = (): SubFolderCreate => {
  const { t } = useTranslation(['chapter']);
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  const history = useHistory();

  const category = useRouteMatch<{ category?: string }>().params?.category;

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(
    ({ onSubFolderCreated, folderDetails } = {}) => {
      //   const pushTo = (location: LocationPath, state?: LocationState) => {
      //     history.push(location, state);
      //     confirmRef.current?.destroy();
      //   };

      if (confirmRef.current) {
        confirmRef.current?.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        size: 'lg',
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader title='Add new subfolder' color='#B5E4CA' />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <AddEditSubFolder
            folderDetails={folderDetails}
            onSubFolderCreated={onSubFolderCreated}
          />
          //   <ModalContent
          //     category={category}
          //     pushTo={pushTo}
          //     onChapterCreated={onChapterCreated}
          //   />
        ),
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '16px',
        },
        isCentered: true,
        footer: null,
      });
    },
    [category]
  );
};

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Image,
  useToast,
  Progress,
} from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import TrainingCard from 'sub-components/TrainingCard';
import { CustomPathCarouselWrapper } from './CustomPathCarousel.styles';
// import milestoneDefault from '../../assets/images/mountain_gif.gif';
import milestoneDefault from '../../../assets/images/mountain_gif.gif';
import TrainingFinished from '../TrainingFinished';
import TrainingPathListItemModal from '../TrainingPathListItemModal';
import { UserProgressEntity } from 'pages/Training/training.types';
import { useMutation, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { UPDATE_TRAINING_COMPLETE } from 'sop-commons/Mutation/Training/index';
import { REASSIGN_TRAINING_COMPLETE } from 'sop-commons/Mutation/Training';
import { getSingleTrainingData } from 'sop-commons/Query/Training';
import ShowTrainingImg from '../../../assets/images/showTrainingImg.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
  enablePreview?: boolean;
  isOpen: boolean;
  onClose: () => void;
  selectedTrainingData: any;
  getSingleTrainingHandler?: () => void;
  quizCardDetails?: {
    cardId: string;
    cardType: string;
    completed: null | string | boolean;
    createdAt: string;
    eid: string;
    entityId: string;
    responseDetails: JSON;
    updatedAt: Date;
    userId: string;
    response: string[];
  }[];
  userId?: string;
  setShouldAllowSettingModalBooleanValue?: React.Dispatch<
    React.SetStateAction<string>
  >;
}

const CustomPathCarousel: FC<IProps> = ({
  enablePreview,
  isOpen,
  onClose,
  selectedTrainingData,
  getSingleTrainingHandler,
  quizCardDetails,
  userId,
  setShouldAllowSettingModalBooleanValue,
}) => {
  console.log('SELECTED TRAINING DATA', selectedTrainingData);
  const clickedCardIds = React.useRef(new Set<string>());
  const { t } = useTranslation(['common', 'training']);
  const userObject = useReactiveVar(userObj);
  const toast = useToast();

  const [activePage, setActivePage] = useState(1);
  const [allItems, setAllItems] = useState<any>([]);
  const [isQuizSelection, setIsQuizSelection] = useState(true);
  const [selectedItem, setSelectedItem] = useState<{
    index: number;
    data: any;
  }>({ index: -1, data: null });
  const [isOpenTrainingPathListItem, setIsOpenTrainingPathListItem] =
    useState(false);

  useEffect(() => {
    if (selectedItem?.data && !enablePreview) {
      updateTrainingProgressHandler(selectedItem?.data);
    }
  }, [selectedItem?.index]);

  useEffect(() => {
    // if (selectedTrainingData && selectedTrainingData?.trainingContentExpanded && selectedTrainingData?.trainingContentExpanded?.length > 0) {
    //     let _selectedTrainingData = JSON.parse(JSON.stringify(selectedTrainingData));
    //     let finishedArray = [
    //         ..._selectedTrainingData?.trainingContentExpanded,
    //         {
    //             type: 'finish',
    //             title: _selectedTrainingData?.title,
    //         },
    //     ];
    //     setAllItems(finishedArray);
    // }
    if (!enablePreview) {
      let _selectedTrainingData = JSON.parse(
        JSON.stringify(selectedTrainingData)
      );
      setSelectedItem({
        index: 0,
        data: _selectedTrainingData?.trainingContentExpanded?.[0],
      });

      if (selectedTrainingData && selectedTrainingData?.userProgress) {
        selectedTrainingData?.userProgress?.map(
          (progress: UserProgressEntity) => {
            if (progress?.userId === userObject?.eid) {
              if (
                progress?.viewedContents &&
                progress?.viewedContents?.length > 0
              ) {
                let index = -1;
                for (let i in selectedTrainingData?.trainingContentExpanded) {
                  if (
                    !selectedTrainingData?.trainingContentExpanded?.[i]
                      ?.completed
                  ) {
                    index = +i;
                    break;
                  }
                }
                if (index > -1) {
                  setActivePage(index + 1);
                  setSelectedItem({
                    index: index,
                    data: selectedTrainingData?.trainingContentExpanded?.[
                      index
                    ],
                  });
                } else {
                  setActivePage(1);
                  setSelectedItem({
                    index: 0,
                    data: selectedTrainingData?.trainingContentExpanded?.[0],
                  });
                }
              }
            }
          }
        );
      }
    } else {
      setSelectedItem({
        index: 0,
        data: selectedTrainingData?.contentDetails?.[0],
      });
    }
  }, []);

  const changeItemHandler = (selection: 'forward' | 'backward') => {
    if (selection === 'forward') {
      setSelectedItem((prevValue) => {
        return {
          index: prevValue.index + 1,
          data: !enablePreview
            ? selectedTrainingData?.trainingContentExpanded?.[
                prevValue.index + 1
              ]
            : selectedTrainingData?.contentDetails?.[prevValue.index + 1],
        };
      });
    } else {
      setSelectedItem((prevValue) => {
        return {
          index: prevValue.index - 1,
          data: !enablePreview
            ? selectedTrainingData?.trainingContentExpanded?.[
                prevValue.index - 1
              ]
            : selectedTrainingData?.contentDetails?.[prevValue.index - 1],
        };
      });
    }
  };

  const currentPage = (cPage: number) => {
    setActivePage(cPage);
  };

  const percentage = (x: any, y: any) => {
    return 100 / (y / x);
  };

  const openTrainingListing = () => {
    if (isOpenTrainingPathListItem) {
      setIsOpenTrainingPathListItem(false);
    } else {
      setIsOpenTrainingPathListItem(true);
    }
  };

  const onCloseTrainingPathListItem = () => {
    setIsOpenTrainingPathListItem(false);
  };

  const setActivePageHandler = () => {
    setActivePage(activePage + 1);
    setSelectedItem((prevValue) => {
      return {
        index: prevValue.index + 1,
        data: selectedTrainingData?.trainingContentExpanded?.[
          prevValue.index + 1
        ],
      };
    });
  };

  const onCloseHandler = () => {
    if (setShouldAllowSettingModalBooleanValue) {
      setShouldAllowSettingModalBooleanValue('child');
    }
    onClose();
  };

  const onFinish = () => {
    onClose();
  };

  const [updateTrainingProgress, { loading }] = useMutation(
    UPDATE_TRAINING_COMPLETE,
    {
      onCompleted: (data) => {
        if (setShouldAllowSettingModalBooleanValue) {
          setShouldAllowSettingModalBooleanValue('child');
        }
        getSingleTrainingHandler?.();
      },
      onError: () => {},
    }
  );

  const [retakeTrainingRequest] = useMutation(REASSIGN_TRAINING_COMPLETE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: getSingleTrainingData,
        variables: {
          eid: selectedItem.data?.eid,
        },
      },
    ],
    onCompleted: () => {
      currentPage(1);
      setSelectedItem({
        index: 0,
        data: selectedTrainingData?.trainingContentExpanded?.[0],
      });
      toast({
        title: t('common:update'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onError: () => {
      toast({
        title: t('common:error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });

  const updateTrainingProgressHandler = (data: any) => {
    console.log('updateTrainingProgressHandler : ', data);
    setIsQuizSelection(false);
    if (data?.cardType?.toLowerCase() !== 'quiz') {
      setIsQuizSelection(false);
      if (data?.type !== 'finish') {
        if (userObject?.type === 'branch') {
          updateTrainingProgress({
            variables: {
              input: {
                eid: selectedTrainingData?.eid,
                type: data?.type,
                contentId: data?.uid,
                userId: userId,
              },
            },
          });
        } else if (data?.subType === 'sop') {
          let parentAssociatedCardIds =
            selectedTrainingData?.trainingContentList?.filter(
              (list) => list?.eid === data?.parentSopId
            )?.[0];
          console.log('parentAssociatedCardIds : ', parentAssociatedCardIds);
          clickedCardIds.current.add(data?.eid);
          let flag = parentAssociatedCardIds?.cards?.every((card) =>
            clickedCardIds.current.has(card?.eid)
          );
          console.log('flag : ', flag);
          if (flag) {
            updateTrainingProgress({
              variables: {
                input: {
                  eid: selectedTrainingData?.eid,
                  type: 'sop',
                  contentId: data?.parentSopId,
                },
              },
            });
          }
        } else {
          updateTrainingProgress({
            variables: {
              input: {
                eid: selectedTrainingData?.eid,
                type: data?.type,
                contentId: data?.uid,
              },
            },
          });
        }
      }
    } else {
      if (data?.completed) {
        setIsQuizSelection(false);
        if (data?.type !== 'finish') {
          if (userObject?.type === 'branch') {
            updateTrainingProgress({
              variables: {
                input: {
                  eid: selectedTrainingData?.eid,
                  type: data?.type,
                  contentId: data?.uid,
                  userId: userId,
                },
              },
            });
          } else {
            updateTrainingProgress({
              variables: {
                input: {
                  eid: selectedTrainingData?.eid,
                  type: data?.type,
                  contentId: data?.uid,
                },
              },
            });
          }
        }
      } else {
        setIsQuizSelection(true);
      }
    }
  };

  const isCheckClickedValueHandler = (value: boolean) => {
    if (!enablePreview) {
      setIsQuizSelection(value);
      updateTrainingProgress({
        variables: {
          input: {
            eid: selectedTrainingData?.eid,
            type: selectedItem?.data?.type,
            contentId: selectedItem?.data?.uid,
          },
        },
      });
    }
  };

  const shouldMoveToNextCardHandler = () => {
    setActivePageHandler();
  };

  const selectedTrainingItem = (data: any, active: number) => {
    setSelectedItem({
      index: active,
      data: !enablePreview
        ? selectedTrainingData?.trainingContentExpanded?.[active]
        : selectedTrainingData?.contentDetails?.[active],
    });
  };
  console.log('Selected item : ', selectedItem);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'} id='training-play'>
      <ModalContent
        backgroundColor={'rgb(38, 50, 56, 0.95)'}
        borderRadius='none'
        scrollBehavior={'smooth'}
        overflowY='auto'
        padding={'1rem 0'}
      >
        <ModalCloseButton
          color={'white'}
          size='lg'
          position={'fixed'}
          zIndex={'9'}
        />
        <ModalBody>
          <CustomPathCarouselWrapper>
            <div className='modal-body'>
              {!(
                selectedItem?.index === 0 ||
                (!enablePreview && isQuizSelection)
              ) && (
                <div style={{ position: 'fixed', left: '30px', top: '50%' }}>
                  <ChevronLeftIcon
                    className='arrow-icon'
                    onClick={() => changeItemHandler('backward')}
                  />
                </div>
              )}
              {!(
                selectedItem?.index ===
                  (!enablePreview
                    ? selectedTrainingData?.trainingContentExpanded?.length - 1
                    : selectedTrainingData?.contentDetails?.length - 1) ||
                (!enablePreview &&
                  (isQuizSelection ||
                    selectedItem?.data?.cardType === 'action'))
              ) && (
                <div style={{ position: 'fixed', right: '30px', top: '50%' }}>
                  <ChevronRightIcon
                    className='arrow-icon'
                    onClick={() => changeItemHandler('forward')}
                  />
                </div>
              )}
              <div className='content-container'>
                <div className='content-div'>
                  {selectedItem?.data?.type === 'card' && (
                    <TrainingCard
                      content={selectedItem?.data}
                      shouldSetFixedHeight
                      shouldMoveToNextCard={shouldMoveToNextCardHandler}
                      isCheckClickedValue={isCheckClickedValueHandler}
                    />
                  )}
                  {selectedItem?.data?.type === 'milestone' && (
                    <div>
                      <Image
                        src={milestoneDefault}
                        boxSize='auto'
                        backgroundSize='cover'
                        style={{
                          objectFit: 'contain',
                          minHeight: '75vh',
                          borderRadius: '20px',
                        }}
                      />
                      <div
                        style={{
                          fontSize: 16.8,
                          fontWeight: 'bold',
                          color: '#000',
                          width: '100%',
                          padding: '0 16px 16px',
                        }}
                      >
                        {selectedItem?.data?.title}
                      </div>
                    </div>
                  )}
                  {selectedItem?.data?.type === 'finish' && (
                    <TrainingFinished
                      title={selectedItem.data?.title}
                      onFinish={onFinish}
                      retakeTraining={() => {
                        if (!enablePreview) {
                          return retakeTrainingRequest({
                            variables: {
                              userId: userObject?.eid,
                              trainingId: selectedTrainingData?.eid,
                            },
                          }) as any;
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              <div className='training-progress-container'>
                <div style={{ position: 'relative' }}>
                  <div className='progress-div'>
                    <Progress
                      value={percentage(
                        selectedItem?.index + 1,
                        !enablePreview
                          ? selectedTrainingData?.trainingContentExpanded
                              ?.length
                          : selectedTrainingData?.contentDetails?.length
                      )}
                      alignSelf='center'
                      borderRadius='2px'
                      bg='#b6b9ba'
                      flex={1}
                      h='3px'
                      sx={{
                        '> div': {
                          backgroundColor: '#68a16a',
                        },
                      }}
                    />
                  </div>
                  <div className='show-training-div'>
                    <span>
                      <img
                        src={ShowTrainingImg}
                        className='show-training-img'
                      />
                    </span>
                    <span onClick={() => openTrainingListing()}>
                      {t('training:progress_modal_show_training')}
                    </span>
                  </div>
                  <div style={{ position: 'absolute' }}>
                    {isOpenTrainingPathListItem && (
                      <TrainingPathListItemModal
                        open={isOpenTrainingPathListItem}
                        onClose={onCloseTrainingPathListItem}
                        getContentDetails={
                          !enablePreview
                            ? selectedTrainingData?.trainingContentExpanded
                            : selectedTrainingData?.contentDetails
                        }
                        selectedTrainingItem={selectedTrainingItem}
                        activePage={selectedItem?.index + 1}
                        isQuizSelection={isQuizSelection}
                        enablePreview={enablePreview}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CustomPathCarouselWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomPathCarousel;

import styled from '@emotion/styled';

export const RoleLocationEditModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .role-location-form-text-container {
    display: flex;
    flex-direction: row;
  }

  .role-location-assign-publish-form {
    width: 60%;
  }

  .role-location-assign-publish-text {
    width: 40%;
  }

  .role-location-form-text-button-container {
    display: flex;
    justify-content: flex-end;
  }

  .role-location-form-text-button {
    width: 160px;
    height: 48px;
    background-color: #2a85ff;
    padding: 12px 20px;
    color: white;
  }
`;

import React, { FC } from 'react';
import { Box, FormControl, FormErrorMessage, Radio } from '@chakra-ui/react';
import { Control, Controller, useWatch } from 'react-hook-form';

import { ChatGroupEntity } from '../../../../../types';

import BaseRadioGroup from './BaseRadioGroup';
import { SelectRoleAndMember } from './role-member';
import { IFormInput } from '../create-channel.types';
import ChannelCreator from './ChannelCreator';

interface IProps {
  control: Control<IFormInput>;
  owner: ChatGroupEntity['owner'];
  isDefaultChannel?: boolean;
}

const InviteRemoveMember: FC<IProps> = ({
  control,
  owner,
  isDefaultChannel,
}) => {
  const whoCanInvite = useWatch({
    control,
    name: 'inviteUser.type',
  });

  return (
    <Box>
      <Controller
        control={control}
        name='inviteUser.type'
        defaultValue='creator'
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState?.error}>
              <BaseRadioGroup value={field.value} onChange={field.onChange}>
                {!isDefaultChannel && (
                  <Radio value='anyone'>All channel members</Radio>
                )}
                <Radio value='creator'>
                  <ChannelCreator owner={owner} />
                </Radio>
                {!isDefaultChannel && (
                  <Radio value='authRole'>Specific members or group</Radio>
                )}
              </BaseRadioGroup>
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <SelectRoleAndMember
        prefix='inviteUser'
        permission={whoCanInvite}
        control={control}
      />
    </Box>
  );
};

export default InviteRemoveMember;

import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import PhaseListItem, { IPhaseListItem } from '../Singleton/PhaseListItem';

interface IPhaseList {
  list: IPhaseListItem[];
}

const Wrapper = styled.div`
  .container-with-blur {
    position: relative;
    overflow: hidden;
  }

  .container-with-blur::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 47%;
    background: linear-gradient(to top, white, transparent);
    filter: blur(5px);
  }
`;

const PhaseList: FC<IPhaseList> = ({ list = [] }) => {
  return (
    <Wrapper>
      <div className='container-with-blur'>
        <Flex flexDir='column' gap={4}>
          {list?.map((phase, index) => (
            <PhaseListItem key={index} title={phase.title} desc={phase.desc} />
          ))}
        </Flex>
      </div>
    </Wrapper>
  );
};

export default PhaseList;

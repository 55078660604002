import { gql } from '@apollo/client';
import {
  AssignedLocationsType,
  ContentType,
  EndCondition,
  RepeatDetails,
} from '../create-task/task.types';
import { TaskStatus } from '../../../types';

interface TaskContentItem {
  type: ContentType;
  eid: string;
  title: string;
  isMultiPage?: boolean;
  lastUpdated?: string;
  visibility?: string;
  category: string;
  thumbnail: string;
  status?: string;
  dateAdded: string;
  isMandatory?: boolean;
  isAccessible?: boolean;
}

interface SampleEntity {
  eid: string;
  name: string;
}

export interface TaskEditableResponse {
  TaskById: {
    eid: string;
    title: string;
    description: string;
    timezone: string;
    status: TaskStatus;

    // Schedule
    isRepeating: boolean;
    repeatString: string;
    repeatDetails: RepeatDetails<string>;
    endCondition: EndCondition<string>;
    startDate: string;

    taskContentExpanded: TaskContentItem[];

    assignedLocationsType: AssignedLocationsType;
    assignedToLocations: SampleEntity[];
    assignedTo: SampleEntity[];
    assignedRoles: SampleEntity[];

    // Supervisor
    supervisors: string[];
  };
}

export const TASK_FOR_EDIT_QUERY = gql`
  query TaskForEdit($eid: String!) {
    TaskById(eid: $eid) {
      eid
      title
      description
      timezone
      status

      isRepeating
      repeatString
      repeatDetails
      endCondition {
        endDate
        endType
        repeatCount
      }
      startDate

      taskContentExpanded

      assignedLocationsType
      assignedToLocations {
        eid
        name
      }
      assignedTo
      assignedRoles

      supervisors
    }
  }
`;

import { FC, useMemo } from "react";
import Tiptap from "../elements/Tiptap";
import SimpleText from "./SimpleText";

import NoContent from "../elements/NoContent";

interface IProps {
  layout: string;
  content: Array<any>;
  backgroundColor?: string;
  isPreview?: boolean;
  shouldSetFixedHeight?: boolean;
}
const TextType: FC<IProps> = ({
  layout,
  content,
  backgroundColor,
  isPreview,
  shouldSetFixedHeight
}) => {
  let style = {};
  if (isPreview) {
    style = {
      borderRadius: "15px",
    };
  }

  const contentChanged = isPreview
    ? Boolean(content?.[0]?.tiptap?.content)
    : false;

  const hasNoData = useMemo(() => {
    if (isPreview) {
      const hasText = content?.[0]?.text?.trim()?.length > 0;
      switch (layout) {
        case "simple-text":
          return !hasText;
        case "rich-text":
          return !(hasText || contentChanged);
        default:
          return false;
      }
    } else {
      return false;
    }
  }, [content?.[0]?.text, contentChanged, layout, isPreview]);

  if (hasNoData) {
    return <NoContent backgroundColor={backgroundColor} style={style} />;
  }

  return (
    <div
      style={{ backgroundColor: backgroundColor, minHeight: shouldSetFixedHeight ? '80vh' : 'none', height: "inherit", ...style }}
    >
      {layout === "simple-text" && (
        <SimpleText backgroundColor={backgroundColor} content={content} shouldSetFixedHeight={shouldSetFixedHeight} />
      )}
      {layout === "rich-text" && (
        <>
          {content?.[0]?.text && (
            <div className="mixed-card-title-container">{content?.[0].text}</div>
          )}
          <Tiptap
            content={content}
            style={{ paddingTop: 12 }}
            backgroundColor={backgroundColor}
          />
        </>
      )}
    </div>
  );
};

export default TextType;

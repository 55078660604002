import React, { FC } from 'react';
import { Center } from '@chakra-ui/react';

const BetaTag: FC = () => {
  return (
    <Center
      boxSize='20px'
      bg='#A80505'
      borderRadius='4px'
      color='#FFFFFF'
      fontSize='13px'
      fontWeight='600'
      lineHeight='20px'
    >
      β
    </Center>
  );
};

export default BetaTag;

import React, { FC, useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { ModalCloseButton, useModalContext } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';

import DeleteFormModalBody from '../../../../sub-components/forms/create-form/DeleteFormModalBody';

interface ContentProps {
  formId: string;
  onFormDeleted?: () => void;
}

const ModalContent: FC<ContentProps> = ({ formId, onFormDeleted }) => {
  const { t } = useTranslation('form');

  const { onClose } = useModalContext();

  return (
    <DeleteFormModalBody
      formId={formId}
      desc={t('deleteFormContent')}
      onDeleteSuccess={() => {
        onFormDeleted?.();
        onClose();
      }}
      onDeleteError={onClose}
      onSheetGenerated={onClose}
    />
  );
};

interface IProps {
  formId: string;
  onFormDeleted?: () => void;
}

type UseFormDelete = (props: IProps) => void;

export const useFormDelete = (): UseFormDelete => {
  const { t } = useTranslation('form');
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const apolloClient = useApolloClient();

  return useCallback(({ formId, onFormDeleted }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <>
          <BoxHeader fontSize='18px' title={t('deleteForm')} color='#CABDFF' />
          <ModalCloseButton m={4} />
        </>
      ),
      size: '2xl',
      content: <ModalContent formId={formId} onFormDeleted={onFormDeleted} />,
      contentProps: {
        p: 4,
      },
      footer: null,
    });
  }, []);
};

import gql from "graphql-tag";

export const REASSIGN_TRAINING_COMPLETE = gql`
  mutation ReassignTraining($userId: String!, $trainingId: String!) {
    ReassignTraining(userId: $userId, trainingId: $trainingId) {
      title
      entityId
      createdBy {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
      }
      status
      thumbnail
      description
      contents {
        type
        eid
        title
        backgroundColor
      }
      visibility
      visibleTo {
        users
        locations
        roles
        _id
      }
      supervisors
      assignedTo
      userProgress
      updatedAt
      createdAt
      eid
      assignedToUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
        locations {
          email
          username
          name
          phone
          profilePic
          onboarded
          isDemo
          onboardedAt
          authRole
          type
          role
          entityId
          loginCount
          lastLogin
          isDeleted
          isRoot
          isInvited
          status
          childrens
          updatedAt
          createdAt
          eid
        }
      }
      supervisorsUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
        locations {
          locations {
            email
            username
            name
            phone
            profilePic
            onboarded
            isDemo
            onboardedAt
            authRole
            type
            role
            entityId
            loginCount
            lastLogin
            isDeleted
            isRoot
            isInvited
            status
            childrens
            updatedAt
            createdAt
            eid
          }
        }
      }
      contentDetails
      trainingContentList
      trainingContentExpanded
      trainingItems
      overallProgress
    }
  }
`;

export const UPDATE_TRAINING_COMPLETE = gql`
  mutation UpdateUserTrainingProgress($input: UserTrainingProgressInput) {
    UpdateUserTrainingProgress(input: $input) {
      title
      entityId
      status
      thumbnail
      description
      visibility
      supervisors
      assignedTo
      userProgress
      updatedAt
      createdAt
      eid
      contentDetails
      trainingContentList
      trainingContentExpanded
    }
  }
`;

import React, { FC, useState } from 'react';
import { Box, IconButton, SkeletonCircle, Tooltip } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faWandMagicSparkles } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { getAvatar } from '../../../../utils';
import { useUploadImage } from '../../../../hooks';
import { ActionButton, Image } from '../../../../ui-components';

import { InfoFormValues } from './ProfileInfoForm';
import { useIsReadonly } from './useIsReadonly';

interface IProps {
  isLoading?: boolean;
  onMagicLinkPress?: (...args: any[]) => any | PromiseLike<any>;
}

const ProfileAvatarInput: FC<IProps> = ({ isLoading, onMagicLinkPress }) => {
  const { t } = useTranslation(['common', 'profile']);
  const [isUploading, setIsUploading] = useState(false);

  const { control, setValue, watch } = useFormContext<InfoFormValues>();

  const uploadImage = useUploadImage();

  const userStatus = watch('status');

  const isDeleted = useWatch<InfoFormValues, 'isDeleted'>({
    name: 'isDeleted',
  });

  const isReadonly = useIsReadonly();

  const onFileChange = async (files: FileList) => {
    try {
      setIsUploading(true);
      const imageUrl = await uploadImage(files?.[0] || null);
      setValue('profilePic', imageUrl);
      setIsUploading(false);
    } catch (e) {
      setIsUploading(false);
    }
  };

  const { getInputProps, open } = useDropzone({
    onDrop: async (acceptedFiles) => {
      await onFileChange(acceptedFiles as unknown as FileList);
    },
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  if (isLoading) {
    return <SkeletonCircle boxSize='178px' />;
  }

  if (isReadonly || isLoading) {
    return (
      <Controller
        control={control}
        name='profilePic'
        render={({ field }) => {
          return (
            <Image
              width={178}
              height={178}
              src={getAvatar({
                name: 'update-member-avatar',
                url: field.value,
              })}
              style={{
                borderRadius: 10,
                objectFit: 'cover',
                maxHeight: '178px',
              }}
            />
          );
        }}
      />
    );
  }

  return (
    <>
      <Box position='relative'>
        <IconButton
          position='absolute'
          right={0}
          transform='auto'
          translateX='30%'
          translateY='-30%'
          size='sm'
          boxShadow='sm'
          borderRadius='full'
          aria-label='edit'
          icon={<FontAwesomeIcon icon={faPen as IconProp} />}
          isDisabled={isDeleted}
          cursor='pointer'
          isLoading={isUploading}
          onClick={open}
        />
        <input {...getInputProps()} disabled={isDeleted} />
        <Controller
          control={control}
          name='profilePic'
          render={({ field }) => {
            return (
              <Image
                width={178}
                height={178}
                src={getAvatar({
                  name: 'update-member-avatar',
                  url: field.value,
                })}
                style={{
                  borderRadius: 10,
                  objectFit: 'cover',
                  maxHeight: '178px',
                }}
              />
            );
          }}
        />
      </Box>

      {onMagicLinkPress && userStatus !== 'pending' && (
        <Tooltip
          borderRadius='4px'
          padding='8px 10px'
          hasArrow
          label={t('profile:sendLinkForPassLess')}
        >
          <Box>
            <ActionButton
              w='full'
              variant='outline'
              colorScheme='blue'
              leftIcon={
                <FontAwesomeIcon icon={faWandMagicSparkles as IconProp} />
              }
              actionFn={() => onMagicLinkPress?.()}
              isDisabled={isDeleted}
            >
              {t('profile:sendMagicLink')}
            </ActionButton>
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default ProfileAvatarInput;

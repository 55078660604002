import { toArray } from '../../../../utils';
import { IContentItem, IFormInput } from './editor.types';
import { LauncherConfig, LauncherContent } from '../common';

const getActiveStep = (activeStep: number, totalLength?: number): number => {
  return totalLength === activeStep ? activeStep - 1 : activeStep;
};

export const getLauncherDefaultValues = (data?: LauncherConfig): IFormInput => {
  let activeStep = 0;
  let taskToBeEdit: string | undefined = undefined;

  const maxLength = data?.contents?.filter((it) => it.selected)?.length || 0;

  return {
    eid: data?.eid!,
    title: data?.title,
    published: data?.published,
    contents: toArray(data?.contents).map((content, index) => {
      let status = !!content?.tasks?.length;

      const _content: IContentItem = {
        ...content,
        tasks: toArray(content?.tasks).map((task) => {
          if (task.selected) {
            status = status && task.completed;

            if (!taskToBeEdit && !task.completed) {
              taskToBeEdit = task.eid;
            }
          }

          return {
            ...task,
            tempEid: task.eid,
            docRequired: task.docRequired!,
            updateStatus: task.completed ? 'finish' : 'wait',
          };
        }),
        updateStatus: status ? 'finish' : undefined,
      };

      if (status && activeStep === index && content.selected) {
        activeStep += 1;
      }

      return _content;
    }),

    publishRequired: false,
    taskEditable: taskToBeEdit,
    activeStep: getActiveStep(activeStep, maxLength),
  };
};

export const transformNewContent = (
  contents?: LauncherContent[]
): IFormInput['contents'] => {
  return toArray(contents).map((content) => {
    let status = !!content?.tasks?.length;

    const _content: IContentItem = {
      ...content,
      tasks: toArray(content?.tasks).map((task) => {
        status = status && task.completed;

        return {
          ...task,
          tempEid: task.eid,
          docRequired: task.docRequired!,
          updateStatus: task.completed ? 'finish' : 'wait',
        };
      }),
      updateStatus: status ? 'finish' : undefined,
    };

    return _content;
  });
};

export const transformTemplates = (
  contents?: LauncherContent[]
): IFormInput['contents'] => {
  return toArray(contents).map((content) => {
    const _content: IContentItem = {
      ...content,
      tasks: toArray(content?.tasks).map((task) => {
        return {
          tempEid: task.eid,
          ...task,
          docRequired: task.docRequired!,
        };
      }),
    };

    return _content;
  });
};

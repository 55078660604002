import React, { FC } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { IconsPlaceholder, Image } from '../../../ui-components';
import locationDefault from '../../../assets/images/locationDefault.png';

interface IProps {
  icon?: string;
  title?: string;
  subtitle?: string;
  className?: string;
  isLocation?: boolean;
}

const ContentHeader: FC<IProps> = ({
  icon,
  title,
  subtitle,
  className,
  isLocation,
}) => {
  return (
    <HStack spacing='15px' marginBottom='8px' className={className}>
      {isLocation && !icon ? (
        <Box
          style={{
            width: '80px',
            height: '80px',
            flexGrow: 0,
            margin: '0 16px 0 0',
            padding: '20px',
            borderRadius: '12px',
            backgroundColor: '#ffd88d',
          }}
        >
          <img
            style={{
              width: '40px',
              height: '40px',
              alignSelf: 'center',
              flexGrow: 0,
              objectFit: 'contain',
            }}
            src={locationDefault}
          />
        </Box>
      ) : (
        <Box>
          <Image
            width={80}
            height={80}
            className='img-card-user'
            src={icon || IconsPlaceholder['user']}
          />
        </Box>
      )}

      <VStack align='stretch' flex='1'>
        <Box fontSize='18px' fontWeight='600' color='#1a1d1f'>
          {title}
        </Box>
        <Box mt='0 !important'>
          <div className='labelCssUser' style={{ marginLeft: 0 }}>
            {subtitle}
          </div>
        </Box>
      </VStack>
    </HStack>
  );
};

export default ContentHeader;

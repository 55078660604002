import React, { useState, useEffect, FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useMutation, gql } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import { faEnvelope, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import scss
import './ForgotPassword.scss';

// import components
import AuthContainer from '../../sub-components/AuthContainer';
import FormInput from '../../atoms/FormInput';
import PrimaryButton from '../../atoms/PrimaryButton';

const FORGOT_PASSWORD_REQUEST = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      succeed
    }
  }
`;

const ForgotPassword: FC = () => {
  const { t } = useTranslation(['common', 'auth']);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  const history = useHistory();
  const [isEmailError, setIsEmailError] = useState(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid, dirtyFields, isDirty },
    getValues,
    watch,
  } = useForm();

  useEffect(() => {
    const subscription = watch((value: any, { name }: any) => {
      if (name === 'email') {
        setError(null);
        const validRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!value.email || !value.email.match(validRegex)) {
          setIsEmailError(true);
        } else {
          setIsEmailError(false);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [resetPassword] = useMutation(FORGOT_PASSWORD_REQUEST, {
    onError: (error) => {
      setIsLoading(false);
      setError(error);
    },
  });

  const onFinish = (values: any) => {
    setError(null);

    setIsLoading(true);
    resetPassword({
      variables: {
        email: values.email,
      },
    }).then((response) => {
      if (response?.data) {
        history.push(`/otp?email=${values.email}`);
      }
      setIsLoading(false);
    });
  };

  const getErrorMessage = () => {
    if (error?.message) {
      return error?.message;
    }
    return '';
  };

  const getRightIcon = () => {
    if (isDirty) {
      if (errors?.email || isEmailError || error?.message) {
        return (
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color={'#ff6a55'}
            style={{ cursor: 'pointer' }}
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            icon={faCheck as IconProp}
            color={'#83BF6E'}
            style={{ cursor: 'pointer' }}
          />
        );
      }
    }
  };
  return (
    <AuthContainer>
      <div>
        <div className='login-heading-container'>
          {t('auth:forgot_password')}
        </div>
        <Flex justify='center'>
          <div
            className='login-sub-heading-container'
            style={{ width: '260px', marginBottom: '20px' }}
          >
            {t('auth:receive_reset_link')}
          </div>
        </Flex>
        <div className='login-form-parent-container'>
          <form
            className='login-form-container'
            onSubmit={handleSubmit(onFinish)}
          >
            <FormControl isInvalid={!!errors?.email?.message}>
              <FormInput
                size='lg'
                leftIcon={faEnvelope as IconProp}
                id='email'
                type='email'
                placeholder={t('auth:your_email')}
                {...register('email', {
                  required: t('validation.email_required'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('validation.email_invalid'),
                  },
                })}
                error={errors?.email || isEmailError || error?.message}
                rightIcon={getRightIcon()}
              />

              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <PrimaryButton
              mt={4}
              type='submit'
              size='lg'
              isLoading={isLoading}
              disabled={!getValues().email}
              title={t('submit')}
              variant='solid'
              colorScheme='blue'
            />
            <div className='login-error-message'>{getErrorMessage()}</div>
          </form>
          <Link to='/login'>
            <div className='back-to-signin-container'>
              <div>
                <FontAwesomeIcon icon={faChevronLeft as IconProp} />
              </div>
              <div className='back-to-signin-text'>
                {t('auth:back_sign_in')}
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className='invite-already-member' />
    </AuthContainer>
  );
};

export default ForgotPassword;

import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { AuthRole, userObj } from 'sop-commons/src/client';

export const shouldRender = (value: number) => {
  const userData = useReactiveVar(userObj);
  if (
    ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN, AuthRole.LOCATION_OWNER]?.includes(
      userData?.authRole
    ) &&
      userData?.type === 'user') ||
    ((AuthRole.WORKER === userData?.authRole || userData?.type === 'branch') &&
      value > 0)
  ) {
    return true;
  }
  return false;
};

import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import {
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Box,
  ModalFooter,
} from '@chakra-ui/react';

import PrimaryButton from '../../../../../../atoms/PrimaryButton';
import { BoxHeader } from '../../../../../../ui-components';

import { CometChatContext } from '../../../util/CometChatContext';
import Translator from '../../../resources/localization/translator';

class CometChatConfirmDialog extends React.Component {
  static contextType = CometChatContext;

  render() {
    const confirmButtonText = this.props?.confirmButtonText
      ? this.props.confirmButtonText
      : Translator.translate('YES', this.context.language);
    const cancelButtonText = this.props?.cancelButtonText
      ? this.props.cancelButtonText
      : Translator.translate('NO', this.context.language);

    return (
      <Modal
        isOpen
        onClose={this.props.close}
        // closeOnOverlayClick={false}
        scrollBehavior='inside'
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          width={{
            sm: '340px',
            md: '642px',
          }}
          minWidth={{
            sm: '340px',
            md: '642px',
          }}
          padding='16px 12px'
        >
          <ModalHeader>
            <Flex justify='space-between' gap={4}>
              <BoxHeader title={this.props.title} color='#cabdff' />

              <ModalCloseButton position='initial' top='unset' right='unset' />
            </Flex>
          </ModalHeader>

          <ModalBody>
            <Box
              fontSize='17px'
              fontWeight='500'
              color='#6f767e'
              lineHeight='1.78'
              paddingBottom={2}
            >
              {this.props?.message}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent='flex-end' gap='20px'>
              <PrimaryButton
                type='button'
                size='lg'
                title={cancelButtonText}
                onClick={this.props.onClick}
                value='no'
                style={{
                  padding: '0px 37px',
                  maxWidth: '120px',
                }}
                variant='outline'
              />

              <PrimaryButton
                type='button'
                size='lg'
                title={confirmButtonText}
                onClick={this.props.onClick}
                style={{ maxWidth: '120px' }}
                value='yes'
                colorScheme='blue'
              />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

export { CometChatConfirmDialog };

import React, { FC } from 'react';
import { Flex, Spacer, useModalContext } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from 'ui-components/Confirm';
import { TrainingStatus } from '../types/common.types';

export interface TFooterProps {
  status: TrainingStatus;
  onDeletePress: (...args: any[]) => any | PromiseLike<any>;
  onInactivePress: (...args: any[]) => any | PromiseLike<any>;
}

const TrainingDeleteFooter: FC<TFooterProps> = ({
  onDeletePress,
  onInactivePress,
  status,
}) => {
  const { onClose } = useModalContext();
  const { t } = useTranslation(['common', 'training']);

  return (
    <Flex w='full' gap={3}>
      {status === 'active' && (
        <ActionButton
          size='lg'
          close={onClose}
          actionFn={onInactivePress}
          variant='outline'
          borderWidth='2px'
        >
          {t('training:mark_as_inactive')}
        </ActionButton>
      )}

      <Spacer />

      <ActionButton
        size='lg'
        close={onClose}
        variant='outline'
        borderWidth='2px'
      >
        {t('common:cancel')}
      </ActionButton>

      <ActionButton
        size='lg'
        close={onClose}
        actionFn={onDeletePress}
        variant='solid'
        colorScheme='red'
      >
        {t('common:delete')}
      </ActionButton>
    </Flex>
  );
};

export default TrainingDeleteFooter;

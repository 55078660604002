import React, { FC } from 'react';
import { GlobalChatSetting } from '../../../GlobalChat';
import CommonExpandAccordion from './CommonExpandAccordion';

interface IProps {
  globalChatSetting: GlobalChatSetting;
  savePreferences: () => void;
  setGlobalChatSetting: React.Dispatch<React.SetStateAction<GlobalChatSetting>>;
}

const StartDirectMessages: FC<IProps> = ({
  globalChatSetting,
  savePreferences,
  setGlobalChatSetting,
}) => {
  return (
    <CommonExpandAccordion
      globalChatSetting={globalChatSetting}
      type='directMessage'
      savePreferences={savePreferences}
      setGlobalChatSetting={setGlobalChatSetting}
    />
  );
};

export default StartDirectMessages;

import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { ActionButton } from '../../../ui-components/Confirm';
import doubleTick from '../../../assets/images/doubleTick.svg';

interface IProps<D extends object = {}> {
  selectedFlatRows: Row<D>[];
  onExportClick?: (...args: any[]) => any | PromiseLike<any>;
  transKey: 'setting:location_selected' | 'setting:member_selected';
}

const MemberExportButton = <D extends object = {}>({
  selectedFlatRows = [],
  onExportClick,
  transKey,
}: IProps<D>): React.ReactElement => {
  const { t } = useTranslation(['common', 'setting']);

  if (selectedFlatRows?.length === 0) {
    return <></>;
  }

  return (
    <Flex justify='space-between' pt={4}>
      <Flex align='center' gap={1}>
        <img src={doubleTick} alt='tick' />
        {/*// @ts-ignore */}
        {t(transKey, { count: selectedFlatRows?.length || 0 })}
      </Flex>

      <ActionButton
        width='auto'
        title={t('common:export')}
        size='md'
        colorScheme='blue'
        px='22px'
        actionFn={onExportClick}
      >
        {t('common:export')}
      </ActionButton>
    </Flex>
  );
};

export default MemberExportButton;

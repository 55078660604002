import { gql } from '@apollo/client';

export const GET_SUPERVISED_BY_ME_TASKS = gql`
  query GetSupervisedByMeTasks(
    $filter: FilterFindManyTaskInput
    $page: Int
    $perPage: Int
    $sort: SortFindManyTaskInput
  ) {
    TaskPagination(
      filter: $filter
      page: $page
      perPage: $perPage
      sort: $sort
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        assignedToUsers {
          eid
        }
        createdBy
        eid
        isRepeating
        lastActiveSession {
          isLastSession
          isRepeating
          maxDueDate
          assignees {
            completedAt
            completedPercent
            completedTasks {
              contentId
              isCompleted
              completedAt
            }
            isCompleted
            role
            userId
          }
          dueDate
          eid
          itemCount
          name
          startDate
          taskId
        }
        repeatCycle
        repeatDetails
        startDate
        status
        timezone
        title
        terminateDetails {
          date
          reason
          user
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query SUPERVISED_TASKS_ENTITY_USER($entityId: String!) {
    EntityUser(entityId: $entityId) {
      eid
      authRole
      email
      name
      phone
      type
      role
      status
      locations {
        eid
        name
      }
      username
      profilePic
      timezone
    }
  }
`;

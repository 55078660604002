import React, { FC } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { Loader } from '../../../../sub-components';

interface IProps {}

const PublishingLoader: FC<IProps> = () => {
  return (
    <Center flexDir='column' gap='20px' boxSize='full'>
      <Loader size='xl' />
      <Flex
        maxW='300px'
        fontSize='24px'
        fontWeight='600'
        textAlign='center'
        color='#33383F'
      >
        Publishing your launcher task checklist
      </Flex>
    </Center>
  );
};

export default PublishingLoader;

import React, { useMemo } from 'react';
import { forwardRef } from '@chakra-ui/system';
import {
  Button,
  ButtonProps,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface IButtonProps extends ButtonProps {
  error?: FieldError;
}

const SelectButton = forwardRef<IButtonProps, 'button'>(
  ({ error, ...props }, ref) => {
    const config = useMemo(() => {
      if (error) {
        return {
          color: '#FF6A55',
          borderColor: '#FF6A55',
        };
      }
    }, [error]);

    return (
      <FormControl isInvalid={!!error}>
        <Button
          width='full'
          fontSize='14px'
          fontWeight='500'
          borderRadius='12px'
          justifyContent='space-between'
          rightIcon={<ChevronDownIcon fontSize='20px' />}
          // variant='outline'
          // onClick={onClick}
          ref={ref}
          // isDisabled={disabled}
          {...config}
          {...props}
        />
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default SelectButton;

import React, { FC, useContext } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';

import CustomVisibility from './CustomVisibility';
import PublicVisibility from './PublicVisibility';
import VisibilityInfoText from './VisibilityInfoText';
import Loader from '../../../Loader';
import { VisibilityContext } from './context';
import { Authorize, AuthRole } from '../../../../authorization';

interface IProps {}

const VisibilityContent: FC<IProps> = () => {
  const { fetchingData } = useContext(VisibilityContext);

  return (
    <Box pos='relative'>
      <Authorize
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <>
          <PublicVisibility />
          <Flex height='1px' bg='#DDDDDD' my='20px' />
        </>
      </Authorize>

      <CustomVisibility />

      <VisibilityInfoText />

      {fetchingData && (
        <Center
          pos='absolute'
          top={0}
          boxSize='full'
          borderRadius='4px'
          bg='rgba(0, 0, 0, 0.25)'
          cursor='no-drop'
        >
          <Loader size='xl' />
        </Center>
      )}
    </Box>
  );
};

export default VisibilityContent;

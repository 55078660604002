import { FC } from 'react';
import { Spinner, SpinnerProps } from '@chakra-ui/react';

const Loader: FC<SpinnerProps> = ({ style, size, ...rest }) => {
  return (
    <Spinner
      thickness='4px'
      speed='0.65s'
      emptyColor='gray.200'
      color='blue.500'
      size={size}
      style={style}
      {...rest}
    />
  );
};

export default Loader;

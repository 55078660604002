import React, { FC, useEffect, useState } from 'react';
import { DeepPartial, useFormContext } from 'react-hook-form';

import {
  CardDefaultValues,
  CardFormInput,
} from '../CardEditor/card-editor.types';
import { cardJsonFormat } from '../../utils';
import {
  formContent,
  imageContent,
  milestoneContent,
  mixedContent,
  quizContent,
  textContent,
  videoContent,
} from '../CardEditor/jsonDataFormatter';
import TrainingCard from '../TrainingCard';

type ValueType = DeepPartial<CardFormInput>;

interface IProps {}

const TrainingCardContainer: FC<IProps> = () => {
  const { watch } = useFormContext<CardFormInput>();
  const [cardData, setCardData] = useState(() => {
    return {
      ...cardJsonFormat(
        CardDefaultValues.cardType?.value as string,
        CardDefaultValues.layout as string,
        CardDefaultValues.entityId as string,
        CardDefaultValues.backgroundColor as string,
        CardDefaultValues.choices?.map((choice) => ({
          id: choice?.choiceId,
          inputId: choice?.inputId,
          text: choice?.text,
        })),
        CardDefaultValues.title,
        CardDefaultValues.status
      ),
    };
  });

  useEffect(() => {
    const subscription = watch((value: ValueType, { name, type }) => {
      if (name === 'cardType') {
        setCardData({
          ...cardJsonFormat(
            value.cardType?.value as string,
            value.layout as string,
            value.entityId as string,
            value.backgroundColor as string,
            null,
            null,
            value.status
          ),
        });
        return;
      }

      const cardType = value.cardType?.value || '';

      setCardData((prevCardData: any) => {
        prevCardData.cardType = value?.cardType?.value;
        prevCardData.entityId = value?.entityId;
        prevCardData.backgroundColor = value?.backgroundColor;
        prevCardData.layout = value?.layout;
        prevCardData.raw_content = value?.raw_content;
        prevCardData.subTitle = value?.subTitle;
        prevCardData.thumbnail = value?.thumbnail;
        prevCardData.title = value?.title;
        prevCardData.handbookView = value?.handbookView;
        prevCardData.category = value?.category;

        switch (cardType) {
          case 'text':
            prevCardData.content = [
              textContent({
                title: value?.title,
                bodyJson: value?.bodyJson,
                layout: value?.layout,
              }),
            ];
            break;
          case 'image':
            prevCardData.content = [
              imageContent({
                images: value?.images as string[],
                layout: value?.layout,
              }),
            ];
            break;
          case 'action':
            prevCardData.content = [
              textContent({
                title: value?.title,
                layout: value?.layout,
              }),
            ];
            break;
          case 'quiz':
            prevCardData.content = [
              quizContent({
                layout: value?.layout,
                // @ts-ignore
                options: value?.choices,
                // @ts-ignore
                answers: value?.correctAnswer,
              }),
            ];
            break;
          case 'video':
            prevCardData.content = videoContent({
              videoUrl: value?.videoUrl,
              caption: value?.caption,
            });
            break;
          case 'milestone':
            prevCardData.content = milestoneContent({
              milestoneUrl: value?.milestoneUrl,
            });
            break;
          case 'form':
            prevCardData.content = formContent({
              title: value?.title,
              formUrl: value?.formLink,
            });
            break;
          case 'mixed':
            prevCardData.content = mixedContent({
              layout: value?.layout,
              images: (value?.images as string[]) || [],
              bodyJson: value?.bodyJson,
            });
            break;
          case 'formCard':
            // prevCardData.title = value.form?.title;
            // prevCardData.title = value.form?.title;
            prevCardData.content = [{ ...value.form }];
            break;
        }

        prevCardData.content = Array.isArray(prevCardData.content)
          ? prevCardData.content
          : [prevCardData.content];

        return {
          ...prevCardData,
        };
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return <TrainingCard content={cardData} isPreview />;
};

export default TrainingCardContainer;

TrainingCardContainer.displayName =
  'SubComponents/CardEditorModal/TrainingCardContainer';

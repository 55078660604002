import { gql } from '@apollo/client';
import { BaseTaskInput } from '../create-task';
import { EndCondition } from '../create-task/task.types';

export interface EditTaskInput extends BaseTaskInput {
  eid: string;

  endCondition?: EndCondition<string>;
}

export interface EditTaskVariable {
  input: EditTaskInput;
}

export const editTaskQuery = gql`
  mutation UpdateTask($input: UpdateTaskTypeInput) {
    UpdateTask(input: $input) {
      eid
    }
  }
`;

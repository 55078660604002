import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AccessibilityType } from 'types';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import SubmitForm from './SubmitForm';
import { useFormAccessibility } from './useFormAccessibility';
import { FormSubmitResponse } from '../from-preview/form-preview.graphql';

interface Params {
  formId: string;
  title: string;
  canOverride?: boolean;
  onSubmitted?: (
    returnedFormData?: FormSubmitResponse['addFormResponse']
  ) => void;
}

type UseSubmitForm = (props: Params) => void;

export const useSubmitForm = (): UseSubmitForm => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const formViewVisibilityConfig = useFormAccessibility();

  const onUnauthorised = (type: AccessibilityType) => {
    confirmRef.current?.update((prevConfig) => {
      return {
        isOpen: prevConfig.isOpen,
        close: prevConfig.close,
        theme: prevConfig.theme,
        zIndex: prevConfig.zIndex,
        ...formViewVisibilityConfig(type),
      };
    });
  };

  return useCallback(
    ({ formId, title, onSubmitted, canOverride }) => {
      const _onSubmitted = (
        returnedFormData?: FormSubmitResponse['addFormResponse']
      ) => {
        if (typeof onSubmitted === 'function') {
          confirmRef.current?.destroy();
          onSubmitted?.(returnedFormData);
        }
      };
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader title={title} color='#CABDFF' />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <SubmitForm
            formId={formId}
            onUnauthorised={onUnauthorised}
            onSubmitted={_onSubmitted}
            canOverride={canOverride}
          />
        ),
        contentProps: {
          py: '16px',
          px: '20px',
          maxW: '800px',
        },
        footer: null,
      });
    },
    [confirm]
  );
};

import { useMemo } from 'react';
import { toArray } from '../../../../../utils/utils';
import { AuthRole } from '../../../../../authorization';
import { useUserEntity } from '../../../../../hooks';
import { SelectOption } from '../../../../../atoms';
import { shallowEqual } from '../../../../../utils';

interface JobLocationData {
  locations: SelectOption[];
  roles: SelectOption[];
  jobs: SelectOption[];
}

export const useJobLocationData = (): JobLocationData => {
  const entity = useUserEntity(
    (en) => ({
      locations: en.locations,
      roles: en.roles,
    }),
    shallowEqual
  );

  const locations = useMemo(() => {
    return toArray(entity?.locations).map((location) => {
      return {
        label: location.name,
        value: location.eid,
        type: 'location',
      };
    });
  }, [entity?.locations]);

  const jobs = useMemo(() => {
    return toArray(entity?.roles).map((location) => {
      return {
        label: location.name,
        value: location.name,
        type: 'job',
      };
    });
  }, [entity?.roles]);

  const roles = useMemo(() => {
    return [
      {
        label: 'superadmins',
        value: AuthRole.SUPER_ADMIN,
        type: 'role',
      },
      {
        label: 'admins',
        value: AuthRole.ADMIN,
        type: 'role',
      },
      {
        label: 'location owners',
        value: AuthRole.LOCATION_OWNER,
        type: 'role',
      },
      {
        label: 'workers',
        value: AuthRole.WORKER,
        type: 'role',
      },
    ];
  }, []);

  return {
    locations: locations,
    roles: roles,
    jobs: jobs,
  };
};

export const useJobRoleLocationList = (): SelectOption[] => {
  const { locations, roles, jobs } = useJobLocationData();

  return useMemo(() => {
    return [...roles, ...jobs, ...locations];
  }, [roles, jobs, locations]);
};

import React, { forwardRef, SyntheticEvent } from 'react';
import { Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import {
  DateInput,
  DateInputButton,
  DateInputProps,
} from '../../../../../../Locations/Components/DateInput';

const LogDateInput = forwardRef<never, DateInputProps>((props, ref) => {
  const onChange = (date: Date | null, event: SyntheticEvent<never>) => {
    if (props.value) {
      props.onChange(date, event);
    } else {
      const _d = new Date();
      date?.setHours(
        _d.getHours(),
        _d.getMinutes(),
        _d.getSeconds(),
        _d.getMilliseconds()
      );
      props.onChange(date, event);
    }
  };

  return (
    <DateInput
      customInput={
        <DateInputButton
          variant='outline'
          bg='white'
          leftIcon={<FontAwesomeIcon icon={faCalendar as IconProp} />}
          paddingInline={4}
          sx={{
            '.chakra-button__icon': {
              color: '#6F767E',
            },
          }}
        >
          <Flex flex={1} />
        </DateInputButton>
      }
      minDate={undefined}
      maxDate={new Date()}
      {...props}
      onChange={onChange}
      ref={ref}
    />
  );
});

LogDateInput.displayName = 'LogDateInput';

export default LogDateInput;

import { Card, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { FORMS_ACTIVITY } from 'pages/forms/forms-graphql';
import { userObj } from 'sop-commons/src/client/clientFactory';
import moment from 'moment';
import useInfiniteScroll from 'ui-components/InfiniteScroll/useInfiniteScroll';
import InfiniteScroll from 'ui-components/InfiniteScroll';
import ActivityAvatar from 'ui-components/ActivityFeed/ActivityAvatar';
import { ActivityUser } from 'ui-components/ActivityFeed/activity-feed.types';
import EmptyFormFeed from './EmptyFormFeed';
import { Trans, useTranslation } from 'react-i18next';
import { ActivitySkeleton } from 'ui-components/ActivityFeed';

export interface Form {
  eventName: string;
  meta: {
    formId: string;
    form: {
      title: string;
      thumbnail: string;
      eid: string;
      createdAt: string;
    };
  };
  createdAt: string;
  eid: string;
  user: ActivityUser;
  name: string;
  status: string;
  task: string;
  dateStr: string;
  dateNum: number;
  dataIndex: number;
}

export type FormList = [string, Form[]];

export type FormData = [FormList[], { totalCount: number }];

const FormActivity: React.FC = () => {
  const { t } = useTranslation('form');
  const { t: tc } = useTranslation('common');
  const userData = useReactiveVar(userObj);
  const [formFeedPageInfo, setFormFeedPageInfo] = useState<any>([]);
  const [formFeedPageCount, setFormFeedPageCount] = useState(1);
  const [formFeedData, setFormFeedData] = useState<FormData>(
    [] as unknown as FormData
  );

  const [formDataInitialList, setFormDataInitialList] = useState<any>([]);

  const [getFormActivity, { loading: formFeedLoading }] = useLazyQuery(
    FORMS_ACTIVITY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setFormDataInitialList([
          ...formDataInitialList,
          ...data.formsActivity.items,
        ]);
        let tempInitialList = [
          ...formDataInitialList,
          ...data.formsActivity.items,
        ];
        setFormFeedPageInfo(data.formsActivity.pageInfo);
        formFeedModifyHandler(tempInitialList);
      },
    }
  );

  const formFeedModifyHandler = (_activityData: any) => {
    let ADD_FORM_RESPONSE = 'addFormResponse'.toLowerCase();
    let tempActivityData = [];
    let activityData = JSON.parse(JSON.stringify(_activityData));
    let countTotal = 0;
    activityData.map((item: any, dataIndex: number) => {
      let dateNum = 0;
      let dateStr = '';
      // let todayDate1 = moment().startOf('day');
      // let difference1 = todayDate1.diff(moment(item.createdAt), 'days', false);
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const utc1 = Date.UTC(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      const utc2 = Date.UTC(
        new Date(item.createdAt).getFullYear(),
        new Date(item.createdAt).getMonth(),
        new Date(item.createdAt).getDate()
      );
      let difference1 = Math.floor((utc1 - utc2) / _MS_PER_DAY);

      dateNum = difference1;
      if (difference1 === 0) {
        dateStr = tc('today');
      } else if (difference1 === 1) {
        dateStr = tc('yesterday');
      } else {
        dateStr = moment(item.createdAt).format('MMMM Do, YYYY');
      }
      if (item?.eventName) {
        if (item?.eventName.toLowerCase() === ADD_FORM_RESPONSE) {
          item.name = item?.user?.name;
          item.status = 'submitted';
          item.task = `response for ${item?.meta?.form?.title}`;
          if (item?.task?.length > 60) {
            item.task = item?.task?.substring(0, 60) + '...';
          }
        }
      }
      item.dateStr = dateStr;
      item.dateNum = dateNum;
      item.dataIndex = dataIndex;
      countTotal++;
    });
    let ObjMap: any = {};
    activityData.forEach((element: any) => {
      var makeKey = element.dateNum;
      if (!ObjMap[makeKey]) {
        ObjMap[makeKey] = [];
      }

      ObjMap[makeKey].push(element);
    });
    tempActivityData.push(Object.entries(ObjMap));
    tempActivityData.push({ totalCount: countTotal });
    setFormFeedData(tempActivityData as FormData);
  };

  useEffect(() => {
    getFormFeedDataHandler();
  }, []);

  const getFormFeedDataHandler = (page = 1) => {
    getFormActivity({
      variables: {
        perPage: 10,
        page: page,
        sort: '_ID_DESC',
      },
    });
  };

  const hasMoreActivityDataHandler = () => {
    let currentPageCount = formFeedPageCount;
    setFormFeedPageCount(formFeedPageCount + 1);
    getFormFeedDataHandler(currentPageCount + 1);
  };

  const loadMoreActivityFeedItems = () => {
    hasMoreActivityDataHandler();
  };

  const [lastElementRef] = useInfiniteScroll(
    formFeedPageInfo?.hasNextPage ? loadMoreActivityFeedItems : () => {},
    formFeedLoading
  );

  const daysAgoHandler = (value: FormList) => {
    return (
      <Flex mb='16px'>
        <span className='days-ago-text'>
          {+value[0] === 0
            ? tc('today')
            : +value[0] === 1
            ? tc('yesterday')
            : value[1][0]?.dateStr}
        </span>
        <Box flex={1} height='1px' bg='#EFEFEF' alignSelf='center' />
      </Flex>
    );
  };

  const getTimeAgo = (timeAgo: string) => {
    let today = moment();
    if (moment(timeAgo).isSame(today, 'day')) {
      return moment(timeAgo).fromNow();
    } else {
      return moment(timeAgo).format('hh:mm A');
    }
  };

  const feedDataHandler = (value: FormList) => {
    return value[1].map((innerVal, index) => (
      <Box
        ref={
          formFeedData?.[1]?.totalCount === innerVal?.dataIndex + 1
            ? lastElementRef
            : null
        }
        key={index + 100}
        mb='16px'
      >
        <Flex align='flex-start' gap='12px'>
          <ActivityAvatar index={index} user={innerVal.user} />
          <Box width='full' fontWeight='500'>
            <Flex wrap='wrap' columnGap='4px' fontSize='15px'>
              {userData?.eid !== innerVal?.user?.eid ? (
                <Trans
                  t={t}
                  // @ts-ignore
                  i18nKey='userResponse'
                  values={{
                    userName: innerVal?.name,
                    formName: innerVal?.meta?.form?.title,
                  }}
                  components={[
                    <Box key='userName' as='span' fontWeight='700' />,
                    <Box key='status' as='span' color='#9A9FA5' />,
                  ]}
                />
              ) : (
                <Trans
                  t={t}
                  i18nKey='youResponse'
                  values={{
                    formName: innerVal?.meta?.form?.title,
                  }}
                  components={[
                    <Box key='userName' as='span' fontWeight='700' />,
                    <Box key='status' as='span' color='#9A9FA5' />,
                  ]}
                />
              )}
              {/* <Box as='span' fontWeight='700'>
                {userData?.eid === innerVal?.user?.eid ? 'You' : innerVal?.name}
              </Box>
              <Box as='span' color='#9A9FA5'>
                {innerVal?.status}
              </Box>
              <Box as='span' color='black'>
                {innerVal?.task}
              </Box> */}
            </Flex>
            <Box fontSize='12px' color='#999999'>
              {getTimeAgo(innerVal.createdAt)}
            </Box>
          </Box>
        </Flex>
      </Box>
    ));
  };

  return (
    <>
      <Card
        key={'1'}
        style={{
          borderRadius: '8px',
        }}
        bodyStyle={{
          padding: 21,
        }}
      >
        <Row>
          <Row align='middle' style={{ marginBottom: '1rem', flex: 1 }}>
            <GenericColorHeader
              color='#d4c9ff'
              title={t('form_feed_heading')}
            />
          </Row>
        </Row>
        {formFeedData?.[1]?.totalCount ? (
          <div style={{ height: 'calc(100vh - 210px)', overflow: 'auto' }}>
            <InfiniteScroll
              hasMore={formFeedPageInfo?.hasNextPage}
              loader={formFeedLoading}
              loaderType='text'
              loaderText={tc('loading')}
              loaderTextPosition='center'
              loaderTextColor='#000000'
              loaderTextWeight={600}
              loaderTextSize='16px'
              loaderTextMarginBottom='20px'
            >
              {formFeedData?.[0]?.map((value, index: number) => (
                <div key={index}>
                  {daysAgoHandler(value)}
                  {feedDataHandler(value)}
                </div>
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <Box height='calc(100vh - 210px)'>
            {formFeedLoading ? (
              <ActivitySkeleton />
            ) : (
              <EmptyFormFeed
                daysAgoHandler={daysAgoHandler as never}
                feedDataHandler={feedDataHandler as never}
              />
            )}
          </Box>
        )}
      </Card>
    </>
  );
};

export default FormActivity;

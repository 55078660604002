import React, { forwardRef } from 'react';
import { Checkbox, Stack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { Authorize, AuthRole } from 'authorization';
import { toArray } from '../../../../utils/utils';

import { AssignToType, IFormInput } from '../task.types';

const Variable: Record<AssignToType, keyof IFormInput> = {
  job: 'assignedToRole',
  member: 'assignedToUsers',
  location: 'assignedToLocation',
};

interface IProps {
  value?: AssignToType[];
  onChange: (...event: any[]) => void;
}

const AssignToSelector = forwardRef<never, IProps>(
  ({ value = [], onChange }) => {
    const { setValue } = useFormContext<IFormInput>();

    const onClick = (_value: AssignToType) => {
      const temp = toArray(value).slice();
      if (temp?.includes(_value)) {
        setValue(Variable[_value], []);
        if (_value === 'location') {
          setValue('assignedLocationsType', undefined!);
        }

        return onChange?.(temp.filter((i) => i !== _value));
      }
      onChange?.(temp.concat(_value));
    };

    return (
      <Stack
        spacing={7}
        direction='row'
        sx={{
          '.chakra-checkbox__label': {
            fontSize: '14px',
            fontWeight: 500,
          },
        }}
      >
        <Checkbox
          size='lg'
          isChecked={value.includes('location')}
          onChange={() => onClick('location')}
        >
          Location(s)
        </Checkbox>

        <Checkbox
          size='lg'
          isChecked={value.includes('member')}
          onChange={() => onClick('member')}
        >
          Members
        </Checkbox>

        <Authorize
          permittedFor='user'
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        >
          <Checkbox
            size='lg'
            isChecked={value.includes('job')}
            onChange={() => onClick('job')}
          >
            Job(s)
          </Checkbox>
        </Authorize>
      </Stack>
    );
  }
);

AssignToSelector.displayName = 'AssignToSelector';

export default AssignToSelector;

import { SelectOption } from '../../../../../../../atoms';

export const NotesType: SelectOption<string>[] = [
  {
    value: 'Inbound call',
    label: 'Inbound call',
  },
  {
    value: 'Outbound call',
    label: 'Outbound call',
  },
  {
    value: 'Email',
    label: 'Email',
  },
  {
    value: 'Notes',
    label: 'Notes',
  },
  {
    value: 'Documents',
    label: 'Documents',
  },
  {
    value: 'Phone',
    label: 'Phone',
  },
  {
    value: 'Inbound text',
    label: 'Inbound text',
  },
  {
    value: 'Outbound text',
    label: 'Outbound text',
  },
  {
    value: 'In-person meeting',
    label: 'In-person meeting',
  },
];

export const NotesStatus: SelectOption<string>[] = [
  {
    value: 'Contacted',
    label: 'Contacted',
  },
  {
    value: 'Left message',
    label: 'Left message',
  },
  {
    value: 'Waiting',
    label: 'Waiting',
  },
];

import React, { FC } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../../ui-components';
import { Any } from '../../../../../types';

import { InfoFormValues } from '../ProfileInfoForm';
import {
  INVITED_USER_DETAILS,
  InvitedUserResponse,
} from '../../../../Teams/InvitedMember/update-invite.graphql';

import { useProfileUpdateMutation } from '../../../../Teams/MemberDetails/useUpdateMutation';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  userId: string;
  close?: Function;
  onUpdated?: (...args: Any[]) => Any | PromiseLike<Any>;
  onResendClick?: (...args: Any[]) => Any | PromiseLike<Any>;
}

const ResendInvite: FC<IProps> = ({
  userId,
  close,
  onResendClick,
  onUpdated,
}) => {
  const { t } = useTranslation(['common', 'profile', 'invite']);
  const client = useApolloClient();
  const { handleSubmit, watch } = useFormContext<InfoFormValues>();
  const isDirty = useFormState().isDirty;
  const isDeleted = watch('isDeleted');

  const toast = useToast({
    duration: 2000,
    position: 'top-right',
    isClosable: true,
  });

  const _onResendClick = async () => {
    deployEvent(AmplitudeEventNames.RESEND_INVITE_BUTTON);
    try {
      const response = await client.query<InvitedUserResponse>({
        query: INVITED_USER_DETAILS,
        fetchPolicy: 'network-only',
        variables: {
          eid: userId,
        },
      });

      if (response?.data?.FetchInviteDetails?.user) {
        return onResendClick?.(response?.data?.FetchInviteDetails?.user);
      }
    } catch (e) {
      toast({
        status: 'error',
        title: t('common:error'),
        description: 'Resend invitation failed!',
      });

      return Promise.reject('something went wrong');
    }
  };

  const onSubmit = useProfileUpdateMutation({
    userId: userId,
    onCompleted: (response) => {
      onUpdated?.();
      onResendClick?.(response.updateUserById);
    },
  });

  const _onSubmit = async (values: InfoFormValues) => {
    await onSubmit(values);
    if (close) {
      setTimeout(close);
    }
  };

  return (
    <ActionButton
      minW='140px'
      mt={6}
      colorScheme='blue'
      fontSize='15px'
      fontWeight='600'
      px={4}
      isDisabled={isDeleted}
      actionFn={isDirty ? handleSubmit(_onSubmit) : _onResendClick}
      close={isDirty ? undefined : close}
    >
      {t('invite:resendInvite')}
    </ActionButton>
  );
};

export default ResendInvite;

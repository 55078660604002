import React, { FC, useEffect, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BoxHeader } from '../../../../../../ui-components';
import { getCompleteRate } from 'sub-components/tasks/performance';
import {
  RoleBarGraph,
  useTaskPerformance,
} from '../../../../Components/graphs';
import { useLazyQuery } from '@apollo/client';
import {
  PerformanceResponse,
  TaskPerformanceVariable,
  TASk_PERFORMANCE_QUERY,
} from 'pages/TasksDashboard/Components/graphs/task-performance.graphql';

const Colors = ['#83BF6E', '#2A85FF', '#FF6A55'];

interface IProps {
  taskId: string;
  sessionId?: string;
}

const JobPerformance: FC<IProps> = ({ taskId, sessionId }) => {
  const { t } = useTranslation(['task']);
  // const { data, loading } = useTaskPerformance({
  //   taskId: taskId,
  //   type: 'role',
  //   sessionId: sessionId,
  // });

  const [getTaskPerformanceData, { data, loading }] = useLazyQuery<
    PerformanceResponse,
    TaskPerformanceVariable
  >(TASk_PERFORMANCE_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getTaskPerformanceData({
      variables: {
        taskId: taskId,
        type: 'role',
        ...(sessionId ? { sessionId: sessionId } : {}),
      },
    });
  }, [taskId, sessionId]);

  const graphData = useMemo(() => {
    const roleAnalytics = data?.TaskPerformance || {};

    return Object.entries(roleAnalytics).map(([roleName, value]) => {
      return {
        name: roleName,
        completeRate: getCompleteRate(value),
      };
    });
  }, [data]);

  return (
    <Flex
      flex={1}
      flexDir='column'
      borderRadius='10px'
      p={6}
      bg='white'
      gap='24px'
    >
      <Flex flexDir='column' gap='10px'>
        <BoxHeader
          title={t('task:jobPerformance')}
          color='#B5E4CA'
          fontSize='18px'
        />

        <Flex align='center' gap='10px'>
          <Flex gap={1}>
            {Colors.map((val) => (
              <Box key={val} boxSize='8px' borderRadius='full' bg={val} />
            ))}
          </Flex>

          <Box color='#2E2C34' fontSize='14px'>
            {t('task:percentageCompletion')}
          </Box>
        </Flex>
      </Flex>

      <RoleBarGraph isLoading={loading} height={360} data={graphData} />
    </Flex>
  );
};

export default JobPerformance;

import React, { FC, useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';
import { Trans, useTranslation } from 'react-i18next';

import { SearchInput, SelectOption } from '../../../../../atoms';
import Dropdown from '../../../../../atoms/Dropdown';

interface IProps {
  options: SelectOption[];
  searchQuery: string;
  onSearch: (value: string) => void;
  filterBy?: SelectOption | null;
  onFilterChange: (value: SelectOption | null) => void;
}

const SearchAndFilter: FC<IProps> = ({
  options,
  searchQuery,
  onSearch,
  filterBy,
  onFilterChange,
}) => {
  const { t } = useTranslation(['common', 'setting', 'team', 'handbook']);

  const _onFilterChange = useCallback(
    (option: SelectOption) => {
      if (option?.value === filterBy?.value) {
        onFilterChange(null);
      } else {
        onFilterChange(option);
      }
    },
    [filterBy]
  );

  return (
    <Flex gap={3} mb={4}>
      <Box flex={3}>
        <SearchInput
          hideShortcuts
          placeholder={t('setting:searchByNameLocJobRole')}
          value={searchQuery}
          onChange={(e) => onSearch(e.target.value)}
        />
      </Box>

      <Dropdown
        placeholder={t('setting:placeholder.groupType')}
        value={filterBy}
        onChange={_onFilterChange}
        options={options}
        selectStyles={{
          menu: {
            zIndex: 2,
            maxW: 'fit-content',
          },
          container: {
            flex: 2,
            minW: '180px',
          },
          singleValue: {
            fontSize: '14px',
            color: '#6f767e',
            fontWeight: '600',
          },
          placeholder: {
            whiteSpace: 'nowrap',
          },
        }}
        components={{
          SingleValue: (valProps) => {
            return (
              <chakraComponents.SingleValue {...valProps}>
                <Trans
                  t={t}
                  i18nKey='setting:groupTypeValue'
                  values={{
                    value: valProps.children,
                  }}
                />
              </chakraComponents.SingleValue>
            );
          },
        }}
      />
    </Flex>
  );
};

export default SearchAndFilter;

import React, { FC, useContext, useMemo } from 'react';
import FormDataContext from 'pages/forms/FormStore/form-data-context';
import locationIcon from '../../../../../assets/images/locationDefault.png';
import { ResponsiveDivWrapper } from '../FormResponseTable.styles';
import { UserListEntity } from '../../../../../types';

interface IProps {
  location: string;
  userList?: UserListEntity[];
}

const LocationComponent: FC<IProps> = ({ location, userList: _userList }) => {
  const responseByIdData = useContext(FormDataContext)?.formResponseByIdData;

  const userList = responseByIdData?.eid
    ? responseByIdData?.userList
    : _userList;

  const locationData = useMemo(() => {
    return userList?.find((user) => user.eid === location)?.name;
  }, [userList, location]);

  return (
    <ResponsiveDivWrapper>
      {locationData && (
        <div
          key={locationData}
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px 10px',
            borderRadius: '7px',
            backgroundColor: 'rgba(181, 228, 202, 0.5)',
            marginRight: '5px',
            width: 'fit-content',
          }}
        >
          <div className='user-status'>
            <img
              src={locationIcon}
              style={{
                borderRadius: '5px',
                backgroundColor: '#83bf6e',
                padding: '5px',
                height: '25px',
                width: '25px',
                minWidth: '25px',
                marginRight: '10px',
              }}
            />
          </div>
          <span
            style={{
              maxWidth: '200px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {locationData}
          </span>
        </div>
      )}
      {!locationData && (
        <div style={{ minWidth: '300px', maxWidth: '300px' }}>-</div>
      )}
    </ResponsiveDivWrapper>
  );
};

export default LocationComponent;

import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { IInProgressCardTop } from 'sub-components/LocationLaunchDetail/types/types';
import CommonLabel from '../CommonLabel';
import NameDetails from './NameDetails';
import PercentDetails from './PercentDetails';
import ProgressBar, { InProgressBar } from './ProgressBar';
import StatusBadge from './StatusBadge';

const InProgressCardTop: FC<IInProgressCardTop> = (props) => {
  const {
    locName,
    startedOnDate,
    status,
    completed,
    totalCount,
    orientation = 'vertical',
  } = props;
  return (
    <Flex flexDir='column' gap={2}>
      {orientation === 'vertical' ? (
        <Flex gap={4} justify='space-between' align='center'>
          <NameDetails locName={locName} startedOnDate={startedOnDate} />
          <StatusBadge
            label={status === 'onTrack' ? 'ON TRACK' : 'LAGGING'}
            labelProps={{
              color: status === 'onTrack' ? '#83BF6E' : '#FF6A55',
              fontWeight: 600,
              fontSize: '12px',
            }}
            boxProps={{
              bg: status === 'onTrack' ? '#B5E4CA4D' : '#FF6A5526',
            }}
          />
        </Flex>
      ) : (
        <CommonLabel label='Total tasks done' />
      )}
      <InProgressBar completed={completed} totalCount={totalCount} />
      <PercentDetails
        orientation={orientation}
        completed={completed}
        totalCount={totalCount}
      />
    </Flex>
  );
};

export default InProgressCardTop;

export const messageReactionsStyle = (
  props,
  reactionData,
  context,
  loggedInUser
) => {
  const uid = loggedInUser?.uid;
  let borderStyle = {};
  let hoveredBorderStyle = {};

  if (reactionData.hasOwnProperty(uid)) {
    borderStyle = {
      border: `1px solid ${context.theme.primaryColor}`,
    };

    hoveredBorderStyle = { ...borderStyle };
  } else {
    borderStyle = {
      border: '1px solid transparent',
    };

    hoveredBorderStyle = {
      border: `1px solid ${context.theme.borderColor.primary}`,
    };
  }

  return {
    fontSize: '11px',
    height: '28px',
    padding: '3px 6px 0px',
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'top',
    backgroundColor: '#e8e8e8',
    borderRadius: '28px',
    // margin: '4px 4px 0 0',
    // ...borderStyle,
    marginRight: '5px',
    marginTop: '4px',
    '.emoji-mart-emoji': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    '&:hover': {
      ...hoveredBorderStyle,
    },
  };
};

export const emojiButtonStyle = (img, context) => {
  return {
    outline: '0',
    border: '0',
    borderRadius: '4px',
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: 'transparent',
    marginBottom: '4px',
    i: {
      height: '22px',
      width: '22px',
      // mask: `url(${img}) center center no-repeat`,
      backgroundColor: `#6F767E`,
    },
  };
};

export const reactionCountStyle = (context) => {
  return {
    color: `${context.theme.color.primary}`,
    padding: '0 1px 0 3px',
    marginLeft: '0px',
  };
};

import moment from 'moment';
import { IFormInput } from '../task.types';

export const getPublishDate = (
  startDateType: IFormInput['startDateType'],
  startDate?: Date,
  startTime?: Date
): moment.Moment => {
  if (startDateType === 'date') {
    const st = moment(startTime);
    return moment(startDate)
      .set('hour', st.get('hour'))
      .set('minute', st.get('minute'));
  }

  return moment();
};

export const getTimeZone = (timezone?: string): string => {
  return timezone ? timezone : 'America/Los_Angeles';
};

export const defaultCSTTimezone = 'CST6CDT';

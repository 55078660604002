import { Flex } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';
import moment from 'moment';
import GenericColorHeader from '../../../sub-components/GenericColorHeader';
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const GET_MY_TASK_LOCATION_TREND = gql`
  query Query(
    $locationIds: [String!]!
    $type: AnalyticsTrendType!
    $startDate: Date
    $endDate: Date
  ) {
    TaskSupervisedLocationWiseTrend(
      locationIds: $locationIds
      type: $type
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

const MyWeeklyGraph: FC = () => {
  const userData = useReactiveVar(userObj);

  const [getMyLocationTrend, { data }] = useLazyQuery(
    GET_MY_TASK_LOCATION_TREND,
    {
      fetchPolicy: 'network-only',
    }
  );

  function transformData(_data: any) {
    let data: any = _data?.TaskSupervisedLocationWiseTrend;
    let enhancedDataArray: any[] = [];
    for (let locationId in data) {
      if (locationId !== 'average') {
        const user = userData?.locations?.find(
          (user) => user.eid === locationId
        );
        if (user) {
          for (let week in data[locationId]) {
            let enhancedData: any = {
              week: parseInt(week),
              date: moment(data?.[locationId]?.[week]?.start)?.format('DD MMM'),
            };
            enhancedData[user.name] = data[locationId][week].complete;
            enhancedDataArray.push(enhancedData);
          }
        }
      }
    }
    return enhancedDataArray;
  }

  function getFifthWeekAndCurrentDate() {
    const currentDate = moment();
    const fifthWeekDate = moment().subtract(5, 'weeks');

    return { currentDate, fifthWeekDate };
  }

  useEffect(() => {
    let { fifthWeekDate, currentDate } = getFifthWeekAndCurrentDate();
    getMyLocationTrend({
      variables: {
        locationIds: userData?.locations
          ?.filter((location) => location)
          ?.map((location) => location?.eid),
        type: 'weekly',
        startDate: fifthWeekDate?.toISOString(),
        endDate: currentDate?.toISOString(),
      },
    });
  }, [userData?.locations]);

  return (
    <Flex flexDir='column' bg='white' borderRadius='8px' p={4}>
      <GenericColorHeader
        color='#B5E4CA'
        title='My location performance: weekly'
      />
      <ResponsiveContainer height={350}>
        <LineChart data={transformData(data)}>
          <CartesianGrid vertical={false} strokeDasharray='3 3' />
          <XAxis dataKey='date' />
          <YAxis />
          {userData?.locations?.map((location, index) => (
            <Line
              key={index}
              type='linear'
              dataKey={location?.name}
              stroke={['#8884d8', '#82ca9d', '#ffc658'][index % 3]}
              strokeWidth='3px'
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Flex>
  );
};

export default MyWeeklyGraph;

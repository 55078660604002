import { Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Authorize, AuthRole } from 'authorization';
import { faArrowUpRight, faTrash } from '@fortawesome/pro-regular-svg-icons';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useBulkMove } from '../useBulkMove';
import { useBulkDelete } from '../useBulkDelete';
import { useRouteMatch } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { useFolderSubFolder } from 'hooks';
import { PrimaryButton } from 'atoms';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

interface IProps {
  selectedData: { eid: string; type: string }[];
  viewType: 'list' | 'grid' | undefined;
  getChaptersListHandler?: () => void;
}

const BulkMove: FC<IProps> = ({
  selectedData,
  viewType,
  getChaptersListHandler,
}) => {
  const { t } = useTranslation(['common', 'handbook', 'chapter']);
  const bulkMove = useBulkMove();
  const bulkDelete = useBulkDelete();
  const { folderId, subFolderId } = useRouteMatch<{
    folderId?: string;
    subFolderId?: string;
  }>().params;

  let foldersList = useReactiveVar(foldersListVar);
  const { getAllFolderSubFolderList } = useFolderSubFolder();

  const folderName = useMemo(() => {
    let list = getAllFolderSubFolderList();
    let found = list?.filter((l) => l?.eid === (subFolderId || folderId))?.[0];
    return found?.name;
  }, [folderId, subFolderId, foldersList]);

  return (
    <Authorize permittedFor='user' restrictedRoles={AuthRole.WORKER}>
      <Flex gap={2}>
        <PrimaryButton
          leftIcon={
            <FontAwesomeIcon icon={faTrash as IconProp} color='#FF6A55' />
          }
          title='Delete'
          disabled={selectedData?.length === 0}
          variant='outline'
          aria-label={'delete'}
          // onClick={bulkDeleteSopHandler}
          onClick={() => {
            deployEvent(AmplitudeEventNames.BULK_DELETE_CLICK);
            bulkDelete({
              selectedData: selectedData,
              name: folderName,
              getChaptersListHandler: getChaptersListHandler,
            });
          }}
          style={{ color: '#FF6A55' }}
        />

        <PrimaryButton
          leftIcon={
            <FontAwesomeIcon
              icon={faArrowUpRight as IconProp}
              color='#6F767E'
              size='lg'
            />
          }
          title='Move'
          variant='outline'
          aria-label={'move to'}
          disabled={selectedData?.length === 0 || !folderId}
          onClick={() => {
            deployEvent(AmplitudeEventNames.BULK_MOVE_CLICK);
            bulkMove({
              selectedData: selectedData,
              selectedFolder: subFolderId || folderId,
            });
          }}
          style={{ color: '#6F767E' }}
        />
      </Flex>
    </Authorize>
  );
};

export default BulkMove;

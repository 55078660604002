// import scss
import './SendInviteModal.scss';

import { useHistory } from 'react-router-dom';

import {
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Flex,
  Box,
  useToast,
} from '@chakra-ui/react';
import { useMutation, gql } from '@apollo/client';

import { useForm } from 'react-hook-form';

import FormInput from '../../atoms/FormInput';
import PrimaryButton from '../../atoms/PrimaryButton';
import { useState, useEffect, FC } from 'react';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import { useTranslation } from 'react-i18next';

let phoneRegex =
  /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/i;

let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

let combinePhoneAndEmailRegex =
  /(^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})|(^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d)$/i;

const SEND_INVITE = gql`
  mutation SendInvite($input: SendInviteInputInput) {
    sendInvite(input: $input) {
      succeed
    }
  }
`;

interface IProps {
  open: boolean;
  onClose: () => void;
  inviteeName?: string;
  memberId?: string;
}

const SendInviteModal: FC<IProps> = ({
  open,
  onClose,
  inviteeName,
  memberId,
}) => {
  const toast = useToast({
    position: 'top-right',
  });
  const { t } = useTranslation(['common', 'invite']);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    message: '',
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid, isDirty },
    watch,
  } = useForm();

  const [sendInvite] = useMutation(SEND_INVITE, {
    onCompleted: (response) => {
      deployEvent(AmplitudeEventNames.HOME_INVITE_BUTTON_SEND_EMAIL_NUMBER);
      setIsLoading(false);
      toast({
        status: 'success',
        title: t('success'),
        description: t('invite:invite_send_to_success'),
      });
      onClose();
      history.push({
        pathname: '/settings',
        state: {
          tabSelected: 'Members',
        },
      });
    },
    onError: (error) => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    const subscription = watch((value: any, { name }: any) => {
      if (name === 'contact') {
        console.log('error null');
        setError({ message: '' });
      }

      // if (name === 'email') {
      //   const validRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      //   if (!value.email || !value.email.match(validRegex)) {
      //     setIsEmailError(true);
      //   } else {
      //     setIsEmailError(false);
      //   }
      // }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onFinish = (values) => {
    if (values.contact) {
      let type = '';
      if (phoneRegex.test(values.contact)) {
        type = 'sms';
      }
      if (emailRegex.test(values.contact)) {
        type = 'email';
      }
      if (type) {
        setIsLoading(true);
        console.log('On Finish Values', values, memberId, type);
        sendInvite({
          variables: {
            input: {
              eid: memberId,
              type: type,
              contact: values.contact,
            },
          },
        });
      } else {
        error.message = 'Invalid Contact';
        setError(error);
      }
    }
  };

  return (
    <Modal isOpen={open} isCentered={true} onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent
        width={{
          sm: '340px',
          md: '684px',
          base: '740px',
          xl: '740px',
          ['2xl']: '740px',
        }}
      >
        <ModalHeader
          style={{ marginTop: 20 }}
          className='invite-user-header-container'
        >
          <Flex align='center' gap='10px'>
            <Box h='32px' w='16px' borderRadius='4px' bg='#cabdff' />
            <div className='modal-title'>
              {t('invite')} {inviteeName}
            </div>
          </Flex>
        </ModalHeader>
        <ModalBody className='invite-user-content-container'>
          <form
            className='invite-user-form-container'
            onSubmit={handleSubmit(onFinish)}
          >
            <div>
              <FormControl mt={3} isInvalid={!!errors?.contact}>
                <FormInput
                  id='contact'
                  placeholder={t('invite:email_phone')}
                  size='lg'
                  {...register('contact', {
                    required: t('invite:validation.contact_required'),
                    pattern: {
                      value: combinePhoneAndEmailRegex,
                      message: t('invite:validation.invalid_contact'),
                    },
                    // pattern: {
                    //   value: `${phoneRegex}|${emailRegex}`,
                    //   message: 'Invalid Contact detail',
                    // },
                    // pattern: {
                    //   value:
                    //     /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/,
                    //   message: 'Invalid Contact detail',
                    // },
                    // pattern: {
                    //   value:
                    //     /((\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20})|(^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,})$/,
                    //   message: 'Invalid Contact detail',
                    // },
                    // pattern: {
                    //   value:
                    //     /^([0-9]{9})|([A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3})$/i,
                    //   message: 'Invalid contact detail',
                  })}
                  error={errors?.contact || error?.message}
                />
                <FormErrorMessage>
                  {errors.contact && errors.contact.message}
                </FormErrorMessage>
              </FormControl>
            </div>
            <Flex mt='20px' mb='30px' gap='20px'>
              <PrimaryButton
                type='button'
                size='lg'
                title={t('cancel')}
                onClick={() => onClose()}
                style={{
                  padding: '0px 37px',
                  maxWidth: '146px',
                }}
                variant='outline'
                className='profile-button-update-container'
              />
              <PrimaryButton
                type='submit'
                size='lg'
                isLoading={isLoading}
                title={t('invite:send_link')}
                // onClick={onUpdateUser}
                style={{ maxWidth: '159px' }}
                variant='solid'
                colorScheme='blue'
                disabled={!isDirty}
                className='profile-button-save-container'
              />
            </Flex>
          </form>
        </ModalBody>

        <ModalCloseButton
          style={{
            marginTop: 30,
            marginRight: 15,
            outline: 'none',
            boxShadow: 'none',
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default SendInviteModal;

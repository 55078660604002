export const msgTimestampStyle = (context, props, loggedInUser) => {
  return {
    display: 'flex',
    // fontSize: '11px',

    textTransform: 'uppercase',
    color: `#9a9fa5`,
    fontSize: '13px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.23',
    letterSpacing: '-0.13px',
  };
};

export const iconStyle = (img, color) => {
  return {
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${color}`,
    display: 'inline-block',
    width: '24px',
    height: '24px',
  };
};

export const modalRowStyle = (context) => {
  return {
    // borderLeft: `1px solid ${context.theme.borderColor.primary}`,
    // borderRight: `1px solid ${context.theme.borderColor.primary}`,
    // borderBottom: `1px solid ${context.theme.borderColor.primary}`,
    display: 'flex',
    width: '100%',
    fontSize: '14px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    //padding: '8px',
    border: 'none',
    borderBottom: `1px solid ${context.theme.borderColor.primary}`,
  };
};

export const nameColumnStyle = (context, participantView) => {
  const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

  const widthProp = participantView
    ? {
        width: 'calc(100% - 180px)',
        [mq[1]]: {
          width: 'calc(100% - 140px)',
        },
        [mq[2]]: {
          width: 'calc(100% - 180px)',
        },
      }
    : {
        width: 'calc(100% - 260px)',
        [mq[1]]: {
          width: 'calc(100% - 220px)',
        },
        [mq[2]]: {
          width: 'calc(100% - 260px)',
        },
        [mq[3]]: {
          width: 'calc(100% - 240px)',
        },
      };

  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };
};

export const avatarStyle = (context, participantView) => {
  const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

  const marginProp = participantView
    ? {
        marginRight: '8px',
      }
    : {
        marginRight: '8px',
        [mq[1]]: {
          marginRight: '0',
        },
      };

  return {
    width: '36px',
    height: '36px',
    flexShrink: '0',
    ...marginProp,
  };
};

export const nameStyle = (context, participantView) => {
  const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

  const widthProp = participantView
    ? {
        width: '100%',
      }
    : {
        width: 'calc(100% - 50px)',
      };

  const displayProp = participantView
    ? {
        display: 'inline',
        [mq[1]]: {
          display: 'inline',
        },
      }
    : {
        display: 'inline',
        [mq[1]]: {
          display: 'none',
        },
      };

  return {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // ...widthProp,
    // ...displayProp,
  };
};

export const modalWrapperStyle = (context) => {
  const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

  return {
    minWidth: '350px',
    maxHeight: '98vh',
    //minHeight: '450px',
    width: '20%',
    overflow: 'auto',
    backgroundColor: `${context.theme.backgroundColor.white}`,
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1002',
    margin: '0 auto',
    boxShadow:
      'rgba(20, 20, 20, 0.2) 0 16px 32px, rgba(20, 20, 20, 0.04) 0 0 0 1px',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    [mq[1]]: {
      width: '100%',
      //height: '100%',
    },
    [mq[2]]: {
      width: '100%',
      //height: '100%',
    },
    [mq[3]]: {
      width: '100%',
      //height: '100%',
    },
  };
};

export const modalCloseStyle = (img, context) => {
  return {
    position: 'absolute',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    top: '35px',
    right: '30px',
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: `${context.theme.primaryColor}`,
    cursor: 'pointer',
  };
};

export const modalBodyStyle = () => {
  return {
    paddingLeft: '28px',
    paddingRight: '28px',
    paddingBottom: '28px',
    //height: '100%',
    width: '100%',
  };
};

export const modalCaptionStyle = (dir) => {
  const textAlignStyle =
    dir === 'rtl'
      ? {
          textAlign: 'right',
          paddingRight: '32px',
        }
      : {
          textAlign: 'left',
        };

  return {
    fontSize: '20px',
    marginBottom: '16px',
    fontWeight: 'bold',
    ...textAlignStyle,
    width: '100%',
  };
};

export const modalErrorStyle = (context) => {
  return {
    fontSize: '12px',
    color: `${context.theme.color.red}`,
    textAlign: 'center',
    padding: '8px 0',
    width: '100%',
    //height: '31px',
  };
};

export const modalListStyle = () => {
  return {
    width: '100%',
    // /height: 'calc(100% - 70px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  };
};

export const listHeaderStyle = (context) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 'bold',
    padding: '8px',
    width: '100%',
    // border: `1px solid ${context.theme.borderColor.primary}`,
    borderBottom: `1px solid ${context.theme.borderColor.primary}`,
  };
};

export const listStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    overflowY: 'auto',
  };
};

export const scopeColumnStyle = (context) => {
  const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

  return {
    width: '180px',
    marginRight: '8px',
    [mq[1]]: {
      width: '140px',
    },
    [mq[2]]: {
      width: '180px',
    },
    [mq[3]]: {
      width: '120px',
    },
  };
};

export const actionColumnStyle = (context) => {
  const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

  return {
    width: '70px',
    [mq[1]]: {
      width: '40px',
    },
    [mq[2]]: {
      width: '40px',
    },
  };
};

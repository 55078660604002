import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Image } from '@chakra-ui/react';

import SearchEmpty from '../../assets/images/Illustrations.svg';
import TrainingEmpty from '../../assets/images/empty-state/training-path-empty-state.svg';

interface IProps {
  search?: string;
  haveItems?: boolean;
}

const AssignTrainingEmpty: FC<IProps> = ({ search, haveItems, children }) => {
  const { t } = useTranslation(['training']);

  if (haveItems) {
    return <>{children}</>;
  }

  return (
    <Center
      flex={1}
      flexDir='column'
      maxW='250px'
      gap='8px'
      textAlign='center'
      alignSelf='center'
    >
      <Image src={search ? SearchEmpty : TrainingEmpty} boxSize='160px' />

      <Box fontSize='15px' fontWeight='700' color='#303030'>
        {t(search ? 'training:noTrainingsFound' : 'training:noTrainingCreated')}
      </Box>

      <Box fontSize='12px' fontWeight='500' color='#9E9E9E'>
        {t(
          search
            ? 'training:noTrainingsFoundDesc'
            : 'training:noTrainingCreatedDesc'
        )}
      </Box>
    </Center>
  );
};

export default AssignTrainingEmpty;

import React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  forwardRef,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
// import { useUserEntity } from '../hooks';

import { ReactComponent as MessageIcon } from '../assets/images/messageIcon.svg';

export const MessageButton = forwardRef<ButtonProps, 'button'>(
  ({ isDisabled, onClick, ...props }, ref) => {
    const { t } = useTranslation(['common']);
    // const isChatDisabled = useUserEntity((entity) => !entity?.features?.chat);

    // const _isDisabled = isDisabled || props.disabled || isChatDisabled;

    const _isDisabled = isDisabled || props.disabled;

    return (
      <Button
        ref={ref}
        fontSize='15px'
        iconSpacing='8px'
        {...props}
        isDisabled={_isDisabled}
        onClick={_isDisabled ? undefined : onClick}
      >
        {t('common:send_message')}
      </Button>
    );
  }
);

interface IProps extends Omit<IconButtonProps, 'aria-label'> {
  hasChatAccess?: boolean;
}

export const MessageIconButton = forwardRef<IProps, 'button'>(
  ({ isDisabled, hasChatAccess, onClick, ...props }, ref) => {
    const { t } = useTranslation(['common']);
    // const isChatDisabled = useUserEntity((entity) => !entity?.features?.chat);

    // const _isDisabled = isDisabled || props.disabled || isChatDisabled;

    const _isDisabled = isDisabled || props.disabled || !hasChatAccess;

    return (
      <Tooltip
        label={
          hasChatAccess
            ? t('common:send_message')
            : 'This member does not have permission to chat.'
        }
        hasArrow
        borderRadius='4px'
        padding='4px 8px'
      >
        <Box>
          <IconButton
            ref={ref}
            borderRadius='full'
            aria-label='message'
            variant='ghost'
            icon={<MessageIcon width={18} height={18} />}
            {...props}
            isDisabled={_isDisabled}
            onClick={_isDisabled ? undefined : onClick}
          />
        </Box>
      </Tooltip>
    );
  }
);

import React, { useEffect, FC, useState, useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ReactComponent as EditIcon } from '../../assets/images/editIcon.svg';

import TrainingCard from 'sub-components/TrainingCard';
// @ts-ignore
import { getCardById } from 'sop-commons/Query/Search';

import Loader from '../../sub-components/Loader';

import CardEditorModal from '../CardEditorModal';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { SUPER_ADMIN, ADMIN } from 'utils/userRoles';
import { CardPreviewWrapper } from './card-preview.styles';
import CardDeckInfoTooltip from 'sub-components/CardDeckInfoTooltip';
import { CardType } from '../../types';
import { CardPreviewModal } from 'sub-components/CardDeckPreviewModal/cardPreview.modal';

interface IProps {
  cardSelected: any;
  onCloseClick: () => void;
  onSubmit?: (...args: any) => void;
  disabledLayout?: CardType[];
  className?: string;
  disableEdit?: boolean;
  onCardDelete?: () => void;
}

const CardPreview: FC<IProps> = ({
  cardSelected,
  onCloseClick,
  onSubmit,
  disabledLayout,
  className,
  disableEdit,
  onCardDelete,
}) => {
  const { t } = useTranslation(['card']);
  const [isCardModalOpened, setIsCardModalOpened] = useState(false);
  const userObject = useReactiveVar(userObj);

  const {
    loading,
    data: response,
    refetch,
  } = useQuery(getCardById, {
    variables: {
      eid: cardSelected.recordId ? cardSelected.recordId : cardSelected.eid,
    },
  });

  const data = useMemo(() => {
    if (response?.findCardById) {
      return new CardPreviewModal(response?.findCardById);
    }
    return {} as any;
  }, [response]);

  useEffect(() => {
    refetch();
  }, []);

  const openCardModal = (isEditValue?: boolean) => {
    setIsCardModalOpened(true);
  };

  const closeCardModal = () => {
    setIsCardModalOpened(false);
  };

  const onCardSuccess = () => {
    if (onSubmit) {
      onSubmit();
    }
    if (refetch) {
      refetch();
    }

    // if (selectedCard && isEdit) {
    //   setCardSelected(selectedCard);
    // }
    // if (refetchCards) {
    //   refetchCards();
    //   // closeCardModal();
    // }
  };

  return (
    <CardPreviewWrapper className={className}>
      <div className='info-wrapper'>
        {data?.findCardById && (
          <CardDeckInfoTooltip details={data?.findCardById} placement='left' />
        )}
      </div>
      <div className='close-wrapper'>
        <FontAwesomeIcon
          onClick={onCloseClick}
          fontSize={18}
          icon={faTimes as IconProp}
          color={'#000000'}
        />
      </div>
      <div className='container'>
        <div className='preview-header'>
          <div className='box' />
          <div style={{ flex: '1 1 auto' }}>{t('card:card_preview')}</div>
          {!disableEdit
            ? data?.findCardById?.eid &&
              (userObject?.authRole === SUPER_ADMIN ||
                userObject?.authRole === ADMIN) &&
              userObject?.type === 'user' && (
                <div
                  className='edit'
                  onClick={() => openCardModal(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <EditIcon />
                </div>
              )
            : null}
        </div>

        <div className='card-preview-container'>
          {loading && (
            <div className='sop-list-loader'>
              <Loader size='xl' style={undefined} />
            </div>
          )}
          <TrainingCard content={data} isPreview={false} />
        </div>
      </div>
      {isCardModalOpened && (
        <CardEditorModal
          open={isCardModalOpened}
          onClose={closeCardModal}
          onSubmit={onCardSuccess}
          onCardDelete={onCardDelete}
          isEdit
          cardId={data?.findCardById?.eid}
          disabledLayout={disabledLayout}
        />
      )}
    </CardPreviewWrapper>
  );
};

export default CardPreview;

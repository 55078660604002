import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Image } from '@chakra-ui/react';

import noImage from '../../../../../assets/images/placeholder/empty-performance-list.svg';

interface IProps {
  title: string;
}

const EmptyJobMembers: FC<IProps> = ({ title }) => {
  return (
    <Flex direction='column' gap='14px'>
      <Image src={noImage} maxW='300px' mx='auto' />
      <Flex direction='column' textAlign='center' gap='4px'>
        <Box fontSize='12px' fontWeight='500' color='#999999' px='12px'>
          {title}
        </Box>
      </Flex>
    </Flex>
  );
};

export default EmptyJobMembers;

import React, { FC, useMemo } from 'react';
import EditorPreview, { JSONContent } from 'delightree-editor';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { smartCardGenerate } from '../../../sub-components/ChapterEditor/SmartPages';
import { ViewMode } from './PreviewHeader';

export interface JsonItem {
  title?: string;
  cardType: string;
  type?: string;
  content?: JSONContent;
  thumbnail: string;
  layout: string;
}

interface ItemProps {
  id: string;
  item: JsonItem;
  onClick: () => void;
  isSelected?: boolean;
  index: number;
}

const Item: FC<ItemProps> = ({ id, item, onClick, isSelected, index }) => {
  return (
    <Tooltip
      bg='white'
      boxSize='200px'
      overflow='hidden'
      color='black'
      label={<EditorPreview readonly value={item?.content?.[0]?.tiptap} />}
      // label={<Editor
      //   readonly
      //   value={item?.content?.[0]?.tiptap}
      // />}
      hasArrow
      placement='top'
      isDisabled={isSelected}
    >
      <Flex
        id={id}
        flexDir='column'
        justifyContent={'center'}
        minW='70px'
        width='70px'
        height='70px'
        alignItems='center'
        onClick={onClick}
        cursor='pointer'
        borderRadius='12px'
        bg='#fff'
        border='1.5px solid'
        borderColor={isSelected ? '#2A85FF' : '#EEEEEE'}
      >
        <Text as='b' fontWeight={700} fontSize='20px'>
          {index + 1}
        </Text>
        {/* <Image
          // @ts-ignore
          src={item?.thumbnail || CardImageJson[item.cardType]}
          width={140}
          height={110}
          style={{
            borderRadius: 12,
            objectFit: 'cover',
          }}
        /> */}

        {/* <Text textAlign='center' fontWeight='600'> */}
        {/* @ts-ignore */}
        {/* {CardJson[item.cardType]} */}
        {/* </Text> */}
      </Flex>
    </Tooltip>
  );
};

interface IProps {
  viewMode: ViewMode;
  smartPages?: JSONContent | JSONContent[];
  selectedIndex?: number;
  onChange: (value: number) => void;
}

const PreviewBottom: FC<IProps> = ({ smartPages, onChange, selectedIndex }) => {
  const pages = useMemo(() => {
    // @ts-ignore
    return smartCardGenerate(smartPages, undefined, true);
  }, [smartPages]);

  return (
    <Flex
      pos='absolute'
      bottom='20px'
      left={0}
      right={0}
      p='16px'
      zIndex='12'
      // bg='linear-gradient(179.35deg, rgba(255, 255, 255, 0) 0.56%, #FFFFFF 56.7%, #FFFFFF 99.44%)'
      bg='white'
      gap='10px'
      overflow='auto'
    >
      <Box flex={1} />
      {pages.map((page, index) => (
        <Flex key={index} gap='12px'>
          {page.hasHorizontal && (
            <Box
              borderRadius={4}
              width='26px'
              height='full'
              // bg='linear-gradient(180deg, #00DED7 0%, #00B1E8 100%)'
              bg='#B1E5FC'
            />
          )}
          <Item
            id={`preview-thumb-${index}`}
            item={page}
            isSelected={selectedIndex === index}
            onClick={() => onChange(index)}
            index={index}
          />
        </Flex>
      ))}
      <Box flex={1} />
    </Flex>
  );
};

const PreviewBottomWrapper: FC<IProps> = (props) => {
  if (props.viewMode === 'desktop') {
    return null;
  }

  if (!Array.isArray(props.smartPages)) {
    return null;
  }
  if (Array.isArray(props.smartPages) && props.smartPages.length <= 1) {
    return null;
  }
  return <PreviewBottom {...props} />;
};

export default PreviewBottomWrapper;

import React, { FC } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Loader } from 'sub-components';

import { IBaseMenuData } from './action-menu.types';

const LoaderComponent: FC<{ isLoading?: boolean }> = ({
  isLoading,
  children,
}) => {
  if (isLoading) {
    return (
      <Center boxSize='24px'>
        <Loader color='gray.500' thickness='2px' width='20px' height='20px' />
      </Center>
    );
  }

  return <>{children}</>;
};

interface IProps {
  menu: IBaseMenuData;
  isLoading?: boolean;
}

const ActionMenuItem: FC<IProps> = ({ menu, isLoading }) => {
  if (menu.type === 'divider') {
    return <Box w='full' h='1px' bg='#DDDDDD' />;
  }

  return (
    <>
      <LoaderComponent isLoading={isLoading}>
        {(menu?.normalIcon === 'img' || !menu?.normalIcon) && (
          <img
            alt='action-menu-icon'
            src={menu.icon as string}
            style={{
              height: '24px',
              width: '24px',
              marginLeft: 'unset',
            }}
          />
        )}
        {menu?.normalIcon === 'fontAwesome' && (
          <Center width='fit-content' boxSize='24px'>
            <FontAwesomeIcon
              icon={menu?.icon as never}
              fontSize='20px'
              color={menu?.iconColor}
            />
          </Center>
        )}
        {menu?.normalIcon === 'chakraUIIcon' && menu?.icon}
      </LoaderComponent>
      <span
        style={{
          fontSize: '15px',
          fontWeight: '600',
          lineHeight: '24px',
          cursor: isLoading ? 'not-allowed' : 'pointer',
          color: menu?.textColor || 'black',
          opacity: isLoading ? 0.6 : 1,
        }}
      >
        {menu.name}
      </span>
    </>
  );
};

export default ActionMenuItem;

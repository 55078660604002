import React, { FC, useMemo } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { callAll } from '@chakra-ui/utils';

import { AuthRole } from '../../../../authorization';
import { toArray } from '../../../../utils/utils';

import TitleHeader from '../../../CardEditor/TitleHeader';
import CheckButton from '../Scheduler/CheckButton';
import { IFormInput } from '../task.types';
import { InfoText, MemberSelection, SelectOption } from '../common';
import SupervisorHint from './SupervisorHint';
import CheckboxDropdown from 'sub-components/CustomDropdowns/CheckboxDropdown';
import SelectionComponent from 'sub-components/CustomDropdowns/CheckboxDropdown/SelectionComponent';
import { CustomSelectComponent } from 'sub-components/CustomDropdowns';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  members: SelectOption[];
  loading: boolean;
}

const SupervisorForm: FC<IProps> = ({ members, loading }) => {
  const { t } = useTranslation(['task']);
  const { control, setValue } = useFormContext<IFormInput>();

  const supervisorType = useWatch<IFormInput, 'supervisorType'>({
    name: 'supervisorType',
  });

  const supervisors = useWatch<IFormInput, 'supervisors'>({
    name: 'supervisors',
  });

  const resetField = (value: IFormInput['supervisorType']) => {
    if (value !== 'all') {
      setValue('supervisors', []);
    }
  };

  const filteredMember = useMemo(() => {
    return toArray(members).filter((_value) => {
      switch (supervisorType) {
        case 'superAdmins':
          return _value.authRole === AuthRole.SUPER_ADMIN;
        case 'admins':
          return _value.authRole === AuthRole.ADMIN;
        case 'superAdminsAndAdmins':
          return (
            _value.authRole === AuthRole.SUPER_ADMIN ||
            _value.authRole === AuthRole.ADMIN
          );

        case 'locationOwners':
          return _value.authRole === AuthRole.LOCATION_OWNER;
        default:
          return true;
      }
    });
  }, [supervisorType, members]);

  return (
    <Box pt='20px'>
      <Box
        borderRadius='10px'
        p='16px 24px'
        bg='rgba(202, 189, 255, 0.35)'
        mb={4}
      >
        <Trans t={t} i18nKey='task:supervisorRecommended' />
      </Box>

      {/*<Controller*/}
      {/*  control={control}*/}
      {/*  name='supervisorType'*/}
      {/*  render={({ field, fieldState }) => {*/}
      {/*    return (*/}
      {/*      <FormControl my={4}>*/}
      {/*        <Flex gap={2}>*/}
      {/*          <CheckButton*/}
      {/*            isSelected={field.value === 'all'}*/}
      {/*            onClick={() => callAll(field.onChange, resetField)('all')}*/}
      {/*          >*/}
      {/*            <Trans t={t} i18nKey='task:all' />*/}
      {/*          </CheckButton>*/}

      {/*          <CheckButton*/}
      {/*            isSelected={field.value === 'superAdmins'}*/}
      {/*            onClick={() =>*/}
      {/*              callAll(field.onChange, resetField)('superAdmins')*/}
      {/*            }*/}
      {/*          >*/}
      {/*            <Trans t={t} i18nKey='task:superAdmins' />*/}
      {/*          </CheckButton>*/}

      {/*          <CheckButton*/}
      {/*            isSelected={field.value === 'admins'}*/}
      {/*            onClick={() => callAll(field.onChange, resetField)('admins')}*/}
      {/*          >*/}
      {/*            <Trans t={t} i18nKey='task:admins' />*/}
      {/*          </CheckButton>*/}

      {/*          <CheckButton*/}
      {/*            isSelected={field.value === 'superAdminsAndAdmins'}*/}
      {/*            onClick={() =>*/}
      {/*              callAll(field.onChange, resetField)('superAdminsAndAdmins')*/}
      {/*            }*/}
      {/*          >*/}
      {/*            <Trans t={t} i18nKey='task:superAdminAndAdmins' />*/}
      {/*          </CheckButton>*/}

      {/*          <CheckButton*/}
      {/*            isSelected={field.value === 'locationOwners'}*/}
      {/*            onClick={() =>*/}
      {/*              callAll(field.onChange, resetField)('locationOwners')*/}
      {/*            }*/}
      {/*          >*/}
      {/*            <Trans t={t} i18nKey='task:locationOwners' />*/}
      {/*          </CheckButton>*/}
      {/*        </Flex>*/}

      {/*        <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>*/}
      {/*      </FormControl>*/}
      {/*    );*/}
      {/*  }}*/}
      {/*/>*/}

      <TitleHeader
        title={t('task:selectSupervisors')}
        desc={t('task:supervisorTips')}
      />

      <Controller
        control={control}
        name='supervisors'
        // rules={{
        //   required: t('task:validation.supervisorRequired'),
        // }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={2} isInvalid={!!fieldState?.error}>
              {/* <MemberSelection
                members={filteredMember}
                value={field.value}
                onChange={field.onChange}
                placeholder={t(
                  `task:supervisorPlace.${supervisorType || 'all'}`
                )}
                isLoading={loading}
              /> */}

              {/* <SelectionComponent
                members={filteredMember}
                value={field.value}
                onChange={field.onChange}
                headerText='Select supervisor(s)'
                showSearchField={true}
                showHeaderCloseBtn={true}
                showFilters={true}
                isSearchable={false}
              /> */}
              <CustomSelectComponent
                options={filteredMember}
                value={field.value}
                placeholder='Select supervisor(s)'
                showHeader
                headerTitle='Select supervisor(s)'
                headerBarColor={HeaderColors.Purple}
                showHeaderCloseIcon
                showSearchField
                showDivider
                bottomOutsideBtn={{
                  show: true,
                  title: 'Add selected supervisors',
                }}
                showFilter
                showSelectAll
                onChange={field.onChange}
              />
              {/* <CheckboxDropdown
                indicator='checkbox'
                options={filteredMember}
                value={field.value}
                onChange={field.onChange}
              /> */}

              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
              {supervisors && supervisors?.length > 0 && (
                <InfoText icon={faUserTie as IconDefinition}>
                  <Trans t={t} i18nKey='task:superAdminSupervisor' />
                </InfoText>
              )}
              <SupervisorHint members={members} />
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default SupervisorForm;

import { useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { SelectOption } from '../../../atoms/Dropdown';
import { useFolderSubFolder } from 'hooks';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';

export const useCategoryList = (): SelectOption[] => {
  const categories = useReactiveVar(userObj)?.entity?.category || [];
  return useMemo(() => {
    return categories.map((category) => {
      return {
        eid: category.eid,
        value: category.name,
        label: category.name,
        visibility: {
          visibleTo: category.visibleTo,
          visibility: category.visibility,
        },
      };
    });
  }, [categories]);
};

export const useSubFoldersList = (): SelectOption[] => {
  // const categories = useReactiveVar(userObj)?.entity?.category || [];
  const categories = useReactiveVar(foldersListVar) || [];
  const subFolders = useMemo(() => {
    let computedSubFolders: SelectOption[] = [];
    categories.forEach((category) => {
      if (category?.children?.length > 0) {
        category.children.forEach((child) => {
          computedSubFolders.push({
            ...child,
            label: child.name,
            value: child.name,
            parentId: category.eid,
          });
        });
      }
    });
    return computedSubFolders;
  }, [categories]);

  return subFolders;
};

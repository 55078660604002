import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import React, { FC, useEffect, useState } from 'react';
import CommonDrawer from 'ui-components/Drawer';
import useCombinedStore from 'zustandStore/store';
import BodyComponent, { IModeType } from './Body/BodyComponent';
import Header from './Header';
import { IRefetchType } from './task-detail-drawer.types';

/**
 * Props for TaskDetailDrawer component.
 */
interface ITaskDetailDrawerProps {
  /**
   * Indicates whether the drawer is open.
   */
  isOpen: boolean;

  /**
   * Function to call when the drawer needs to be closed.
   */
  onClose: () => void;

  selectedTaskData: CustomLauncherTask;
  mode: IModeType;
  refetchHandler: (type: IRefetchType) => void;
}

/**
 * TaskDetailDrawer component is a functional React component that renders a CommonDrawer.
 * It displays detailed information about a task and is controlled via props to show or hide the drawer.
 *
 * @param props - The properties passed to the component.
 * @param props.isOpen - Boolean indicating if the drawer is currently open.
 * @param props.onClose - Function to call when the drawer needs to be closed.
 * @param props.mode - Mode for the drawer. Defaults to view
 * @returns A rendered CommonDrawer component if isOpen is true, otherwise null.
 */
const TaskDetailDrawer: FC<ITaskDetailDrawerProps> = ({
  isOpen,
  selectedTaskData,
  mode,
  onClose,
  refetchHandler,
}) => {
  const {
    taskDetails: { reset },
  } = useCombinedStore();
  const [drawerMode, setDrawerMode] = useState<IModeType>('view');

  useEffect(() => {
    if (isOpen) {
      setDrawerMode(mode);
      reset();
    }
  }, [isOpen]);

  const onEdit = () => {
    setDrawerMode('edit');
  };

  return isOpen ? (
    <CommonDrawer
      isOpen={isOpen}
      onClose={onClose}
      showCloseBtn={false}
      size='md'
      padding='1rem'
      headerComp={
        <Header
          onClose={onClose}
          onEdit={onEdit}
          mode={mode}
          selectedTaskData={selectedTaskData}
        />
      }
      bodyComp={
        <BodyComponent
          mode={drawerMode}
          selectedTaskData={selectedTaskData}
          refetchHandler={refetchHandler}
          onClose={onClose}
        />
      }
    />
  ) : null;
};

TaskDetailDrawer.displayName =
  'LocationLaunchDetail/OpeningTask/TaskListing/TaskDetailDrawer';

export default TaskDetailDrawer;

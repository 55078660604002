import {
  gql,
  useLazyQuery,
  useMutation,
  useSubscription,
} from '@apollo/client';
// import css
import './NotificationList.css';

import Loader from '../Loader';
import NotificationItem from './NotificationItem';

import emptyNotificationImage from '../../assets/images/emptyNotification.png';
import { useEffect, useState } from 'react';
import { GET_NOTIFICATION_COUNT } from 'ui-components/MainContainer/MainContainer';
import NotificationTimeline from './NotificationTimeline';
import ShowMore from '../ShowMore';
import React from 'react';
import { groupNotificationData } from 'helper';

const LIMIT = 50;

export const onNotification = gql`
  subscription onNotification {
    onNotification
  }
`;

export const GET_NOTIFICATION = gql`
  query ($page: Int, $perPage: Int, $sort: SortFindManyNotificationInput) {
    notification(page: $page, perPage: $perPage, sort: $sort) {
      items {
        subject
        body
        type
        eid
        isSeen {
          status
        }
        activityId
        metaData
        createdAt
        formattedContent {
          subject
          body
        }
        sop {
          chapter
          entity
          category
          thumbnail_color
          thumbnail
        }
      }
      count
    }
  }
`;

const MARK_ALL_READ = gql`
  mutation {
    seenAllNotification {
      succeed
    }
  }
`;

interface Props {
  onNotificationCountRefetch?: any;
  refetchSopList?: any;
}

const NotificationList = ({
  onNotificationCountRefetch,
  refetchSopList,
}: Props) => {
  const [notificationList, setNotificationList] = useState([]);
  const [loadMoreList, setLoadMoreList] = useState([]);
  const [page, setPage] = useState(1);
  const { data: onNotificationSubscription } = useSubscription(onNotification);

  const [
    getNotifications,
    { loading, data, refetch: onNotificationRefetch },
  ]: any = useLazyQuery(GET_NOTIFICATION);

  const [markAsReadMutation] = useMutation(MARK_ALL_READ, {
    refetchQueries: [
      {
        query: GET_NOTIFICATION_COUNT,
      },
    ],
    onCompleted: () => {
      if (onNotificationRefetch) {
        onNotificationRefetch();
      }
    },
  });

  // const notificationList = data?.notification?.items;
  const isNotificationPresent = data?.notification?.count;

  const onMarkAllRead = () => {
    if (notificationList && notificationList.length) {
      const updatedNotificationList: any = notificationList.map(
        (notification: any) => {
          if (notification && notification.isSeen) {
            let updatedNotification = { ...notification };
            updatedNotification = {
              ...updatedNotification,
              isSeen: {
                ...updatedNotification.isSeen,
                status: true,
              },
            };
            return updatedNotification;
          }
        }
      );
      setNotificationList(updatedNotificationList);
    }
    markAsReadMutation({}).then(() => {});
  };

  const onShowMore = () => {
    let updatePage = page + 1;
    setPage(updatePage);
  };

  useEffect(() => {
    getNotifications({
      variables: {
        page: 1,
        perPage: LIMIT,
        sort: '_ID_DESC',
      },
      fetchPolicy: 'network-only',
    });
  }, []);

  useEffect(() => {
    if (data?.notification?.items) {
      setNotificationList(data?.notification?.items);
      let loadList: any = [];
      data?.notification?.items.forEach((item: any, index: any) => {
        if (index < 10 * page) {
          loadList.push(item);
        }
        setLoadMoreList(loadList);
      });
    }
  }, [data?.notification?.items]);

  useEffect(() => {
    if (onNotificationSubscription) {
      onNotificationRefetch();
      // onNotificationCountRefetch();
      refetchSopList();
    }
  }, [onNotificationSubscription]);

  useEffect(() => {
    let loadList: any = [];
    notificationList.forEach((item: any, index: any) => {
      if (index < 10 * page) {
        loadList.push(item);
      }
      setLoadMoreList(loadList);
    });
  }, [page]);
  const notificationData = groupNotificationData(loadMoreList);

  const { versiontimestampList } = notificationData;

  // const notificationList: any = data?.notification?.items || [];

  return (
    <div className='notification-list-container'>
      <div className='notification-list-header-container'>
        <div className='notification-header-text'>Notifications</div>
        {!!isNotificationPresent && (
          <div
            className='notification-header-mark-read-container'
            onClick={onMarkAllRead}
          >
            {/* <MarkReadSvg /> */}
            <span style={{ marginLeft: '6px' }}>MARK ALL AS READ</span>
          </div>
        )}
      </div>
      {/* <div style={{ position: 'sticky', top: '0' }}> */}
      {loading && !notificationList.length && (
        <div className='sop-list-loader'>
          <Loader />
        </div>
      )}
      {!loading && !!isNotificationPresent && (
        <>
          {versiontimestampList.map((title: any, index: number) => {
            let versionIndex = index;
            return (
              <>
                <NotificationTimeline title={title} key={versionIndex} />
                {notificationData[title] &&
                  !!notificationData[title].length &&
                  notificationData[title].map(
                    (notificationItem: any, index: number) => {
                      let style = {};
                      if (
                        page * 10 >= notificationList.length &&
                        versionIndex === versiontimestampList.length - 1 &&
                        index === notificationData[title].length - 1
                      ) {
                        style = {
                          position: 'sticky',
                          top: '0px',
                        };
                      }
                      return (
                        <div key={index} style={style}>
                          <NotificationItem
                            notificationItem={notificationItem}
                            key={index}
                            onNotificationCountRefetch={
                              onNotificationCountRefetch
                            }
                          />
                        </div>
                      );
                    }
                  )}
              </>
            );
          })}
          {page * 10 < notificationList.length && (
            <ShowMore
              title='Show More'
              onClick={onShowMore}
              style={{ position: 'sticky', top: '0px' }}
            />
          )}
        </>
      )}

      {!loading && !isNotificationPresent && (
        <div className='notification-empty-container'>
          <img src={emptyNotificationImage} />
          <div style={{ marginTop: '30px', fontSize: '18px' }}>
            No Notifications
          </div>
        </div>
      )}
      {/* </div> */}
    </div>
  );
};

export default NotificationList;

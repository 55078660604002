import React from 'react';
import { Box, Checkbox, HStack, ListItem, VStack } from '@chakra-ui/react';
import logo from '../../assets/images/ic_logo.png';
import './AddAssigneeListItem.scss';
import RoleAndAuthRole from '../../ui-components/role-authrole/RoleAndAuthRole';
import Image from '../../ui-components/Image/Image';

const AddAssigneeListItem = ({
  item,
  existingSupervisors,
  onCheckChange,
}: any) => {
  return (
    <ListItem>
      <HStack spacing='12px'>
        <HStack
          style={{
            flex: 1,
            paddingLeft: 6,
            paddingRight: 6,
            cursor: 'pointer',
            overflow: 'hidden',
            userSelect: 'none',
          }}
          onClick={() => onCheckChange(item.eid)}
        >
          <Box>
            <div className='member-list-item'>
              <Image
                height={50}
                width={50}
                className='memberImage'
                src={item?.profilePic || logo}
              />
            </div>
          </Box>

          <VStack spacing={-8} align='stretch' flex='1' overflow='hidden'>
            <Box w='max-content'>
              <Box className='supervisor-title'>
                {item?.name ? item?.name : 'N/A'}
              </Box>
            </Box>
            <Box style={{ marginTop: 'unset' }} overflow='hidden'>
              <RoleAndAuthRole
                authRole={item?.locations?.[0]?.name}
                role={item?.role}
                defaultValue='--'
              />
            </Box>
          </VStack>
        </HStack>

        <div>
          <Checkbox
            isChecked={item?.checked}
            onChange={() => onCheckChange(item.eid)}
          />
        </div>
      </HStack>
    </ListItem>
  );
};

export default AddAssigneeListItem;

import { gql } from '@apollo/client';

export interface UserDetailResponse {
  userById: {
    eid: string;
    profilePic?: string;
    phone: string;
    name: string;
    username?: string;
    email: string;
    type: 'branch' | 'user';
    role: string;
    authRole: string;
    locations: Array<{
      name: string;
    }>;
  };
}

export const USER_DETAIL = gql`
  query UserDetail($eid: String!) {
    userById(eid: $eid) {
      eid
      profilePic
      phone
      name
      username
      email
      type
      role
      authRole
      locations {
        name
      }
    }
  }
`;

import checkIcon from '../assets/images/checklist.png';
import menuIcon from '../assets/images/menu.png';
import notificationIcon from '../assets/images/notification.png';
import settingsIcon from '../assets/images/settings.png';
import sliderIcon from '../assets/images/slider.png';
import stepsIcon from '../assets/images/steps.png';
// export const iconObj: any = {
//   checklist: checkIcon,
//   menu: menuIcon,
//   notification: notificationIcon,
//   settings: settingsIcon,
//   slider: sliderIcon,
//   steps: stepsIcon,
// };

const getIconObj = () => {
  let iconObj: any = {
    checklist: checkIcon,
    menu: menuIcon,
    notification: notificationIcon,
    settings: settingsIcon,
    slider: sliderIcon,
    steps: stepsIcon,
  };
  for (let i = 0; i < 86; i++) {
    iconObj[
      `https://sop-staging-assets.s3.amazonaws.com/Group-${i + 1}.png`
    ] = `https://sop-staging-assets.s3.amazonaws.com/Group-${i + 1}.png`;
  }
  return iconObj;
};

export const iconObj: any = {
  checklist: checkIcon,
  menu: menuIcon,
  notification: notificationIcon,
  settings: settingsIcon,
  slider: sliderIcon,
  steps: stepsIcon,
};

export const iconList = [
  'checklist',
  'menu',
  'notification',
  'settings',
  'slider',
  'steps',
];

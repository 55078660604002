import { AddIcon } from '@chakra-ui/icons';
import { TFunction } from 'i18next';
import QuizIcon from '../../assets/images/quizSvg.svg';
import SearchIcon from '../../assets/images/searchSvg.svg';
import FlagIcon from '../../assets/images/flagSvg.svg';
import { ReactComponent as PowerupComponent } from '../../assets/images/powerup-menu/actionMenu.svg';
import { ReactComponent as VideoComponent } from '../../assets/images/powerup-menu/videoMenu.svg';
import { ReactComponent as FormComponent } from '../../assets/images/powerup-menu/formMenu.svg';
import { IActionMenuData } from 'ui-components/ActionMenu';

export const ActionMenuData = (
  t: TFunction<'training', undefined, 'training'>
): IActionMenuData[] => [
  {
    name: t('addChapters'),
    value: 'add-new-chapter',
    icon: <AddIcon w={5} h={5} color='#6F767E' marginRight='14px' />,
    normalIcon: 'chakraUIIcon',
    textColor: '#6F767E',
  },
  {
    type: 'divider',
    name: '',
    value: '',
    icon: '',
  },
  {
    name: t('powerup.actionMenu'),
    value: 'add-action',
    icon: <PowerupComponent fill='#6F767E' color='#6F767E' />,
    normalIcon: 'chakraUIIcon',
    textColor: '#6F767E',
  },
  {
    name: t('powerup.quizMenu'),
    value: 'create-quiz',
    icon: QuizIcon,
    normalIcon: 'img',
    textColor: '#6F767E',
  },
  {
    name: t('powerup.milestoneMenu'),
    value: 'add-milestone',
    icon: FlagIcon,
    normalIcon: 'img',
    textColor: '#6F767E',
  },
  {
    name: t('powerup.videoMenu'),
    value: 'add-video',
    icon: <VideoComponent fill='#6F767E' color='#6F767E' />,
    normalIcon: 'chakraUIIcon',
    textColor: '#6F767E',
  },
  {
    name: t('powerup.formMenu'),
    value: 'add-form',
    icon: <FormComponent fill='#6F767E' color='#6F767E' />,
    normalIcon: 'chakraUIIcon',
    textColor: '#6F767E',
  },
];

import { gql } from '@apollo/client';
import { EntityUser } from '../assignee';
import { TrendAnalytic, UserAnalytics, IGraphRange } from '../../common';

export type {
  MemberVariable,
  PerformanceEntity,
} from '../assignee/performance.graphql';

export interface PerformanceJobResponse {
  EntityUser: EntityUser[];

  TaskSupervisedRoleAnalytics: Record<string, UserAnalytics>;
}

export const PERFORMANCE_JOB = gql`
  query PerformanceJob(
    $entityId: String!
    $type: [userTypeEnum]
    $status: [userStatusEnum]
  ) {
    EntityUser(entityId: $entityId, type: $type, status: $status) {
      eid
      name
      email
      authRole
      profilePic
      role
      locations {
        eid
        name
      }
      createdAt
    }

    TaskSupervisedRoleAnalytics
  }
`;

export interface CompletionRateResponse {
  TaskSupervisedRoleWiseTrend: Record<string, Record<number, TrendAnalytic>>;
}

export interface CompletionRateVariable extends IGraphRange {}

export const JOB_COMPLETION_RATE_QUERY = gql`
  query TaskSupervisedRoleWiseTrend(
    $type: AnalyticsTrendType!
    $startDate: Date
    $endDate: Date
  ) {
    TaskSupervisedRoleWiseTrend(
      type: $type
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

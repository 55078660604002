import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useChatMessageCount, useUserDataSelector, useUserEntity } from 'hooks';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import CircularProgressIcon from 'sub-components/CircularProgressIcon';
import { faMessages } from '@fortawesome/pro-regular-svg-icons';
import { CHAT_URL } from 'appRoutes';
import { AuthRole } from 'authorization';

const UnreadMessages: FC = () => {
  const history = useHistory();
  const chatMessageCount = useChatMessageCount();
  const chatConfigData = useUserEntity((entity) => entity?.chatConfig);
  const loggedInUserData = useUserDataSelector((state) => state);
  const hasChatAccess =
    (chatConfigData?.restrictions &&
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
        loggedInUserData?.authRole
      )) ||
    !chatConfigData?.restrictions;
  return (
    <Tooltip
      label={!hasChatAccess ? 'You do not have permission to chat' : ''}
      hasArrow
    >
      <Flex
        gap='10px'
        opacity={hasChatAccess ? 1 : 0.5}
        cursor={hasChatAccess ? 'pointer' : 'no-drop'}
        onClick={() => hasChatAccess && history.push(CHAT_URL)}
      >
        <CircularProgressIcon
          progressRate={100}
          color='#8E59FF'
          showIndicator={chatMessageCount > 0 || hasChatAccess}
          icon={
            <FontAwesomeIcon icon={faMessages as IconProp} color='#8E59FF' />
          }
        />
        <Flex flexDirection='column' gap='10px'>
          <Text fontWeight={600} fontSize='16px'>
            Unread messages
          </Text>
          <Text fontWeight={700} fontSize='32px'>
            {hasChatAccess ? chatMessageCount : '00'}
          </Text>
          {!hasChatAccess && (
            <Text fontWeight={400} fontSize='14px' color='#696974'>
              Not available for you
            </Text>
          )}
        </Flex>
      </Flex>
    </Tooltip>
  );
};

export default UnreadMessages;

import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IFormInput } from '../invite.types';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import Header from '../Header';
import ShareLinkTabs from '../ShareLinkTabs';
import ChatGroups from '../ChatGroups';
import TrainingPaths from '../TrainingPaths';
import { InvitedUser } from '../../../pages/Teams/InvitedMember/update-invite.graphql';
import { toArray } from '../../../utils/utils';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  data?: InvitedUser;
}

const ReInviteUserModal: FC<IProps> = ({ isOpen, onClose, data }) => {
  const methods = useForm<IFormInput>({
    defaultValues: {
      currentStep: 'shareTabs',
      name: data?.name,
      invitedUserId: data?.eid,
      inviteUrl: `${process.env.REACT_APP_FRONTEND_URL}/invite?code=${data?.inviteDetails?.authCode}`,
      job: data?.role
        ? {
            value: data.role,
            label: data.role,
          }
        : undefined,
      permissionLevel: data?.authRole,
      email: data?.email,
      mobile: data?.phone,
      location: data?.locations
        ? data.locations.map((it) => ({
            label: it.name,
            value: it.eid,
            id: it.eid,
          }))
        : undefined,
      selectedTrainingPaths: toArray(data?.userInviteMeta?.trainings).map(
        (it) => it.eid
      ),
      linkSend: {
        email: false,
        phone: false,
      },
    },
  });

  const closeHandler = () => {
    methods.reset();
    onClose();
  };

  const currentStep = methods.watch('currentStep');

  if (!data) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeHandler}
      size='xl'
      isCentered
      scrollBehavior='inside'
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent padding='12px' w='full'>
        <FormProvider {...methods}>
          <Header reInviteUser />

          {currentStep === 'shareTabs' && <ShareLinkTabs reInviteUser />}

          {currentStep === 'trainingPath' && (
            <TrainingPaths
              initialTrainings={data?.userInviteMeta?.trainings}
              reInviteUser
              closeHandler={closeHandler}
            />
          )}

          {currentStep === 'chatGroup' && (
            <ChatGroups reInviteUser closeHandler={closeHandler} />
          )}
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

ReInviteUserModal.displayName =
  'ui-components/InviteUserNew/ReInviteUser/ReInviteUserModal';

export default ReInviteUserModal;

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  .table {
    max-width: min-content;
    background-color: white;
    border: none;

    ::-webkit-scrollbar {
      width: 1px;
    }

    ::-webkit-scrollbar:horizontal {
      height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: rgba(111, 118, 126, 0.25);
      border-radius: 5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(111, 118, 126, 0.25);
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(111, 118, 126, 0.25);
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 15px;
      border-bottom: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    .th {
      background-color: #f4f4f4;
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        //box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
    .th {
      background-color: #f4f4f4;
      height: 44px !important;
      display: flex !important;
      align-items: center !important;
      .tr {
        border-radius: 5px;
        th:first-of-type {
          padding: 0px 20px !important;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        .th:last-child {
          padding: 0px 20px !important;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    .td {
      display: flex !important;
      align-items: center !important;
    }
  }
`;

import React, { useState, useEffect, FC } from 'react';
import {
  Text,
  Box,
  Flex,
  Image,
  Stack,
  useToast,
  Center,
} from '@chakra-ui/react';
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import milestoneDefault from '../../assets/images/mountain_gif.gif';
import TrainingCard from 'sub-components/TrainingCard';
import { UPDATE_TRAINING_COMPLETE } from 'sop-commons/Mutation/Training/index';
import { REASSIGN_TRAINING_COMPLETE } from 'sop-commons/Mutation/Training';
import { useMutation, useReactiveVar } from '@apollo/client';
import TrainingFinished from './TrainingFinished';

import { getSingleTrainingData } from 'sop-commons/Query/Training';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { useTranslation } from 'react-i18next';

type ICustomCarousel = {
  title?: string;
  slides: any;
  currentPage: (page: number) => void;
  selectedTraining: any;
  removeSelectedImage: any;
  selectedTrainingData: any;
  activePage: number;
  setActivePageHandler: () => void;
  trainingId: string;
  isQuizSelection: boolean;
  setIsQuizSelection: React.Dispatch<React.SetStateAction<boolean>>;
  getSingleTrainingHandler?: (id?: string) => void;
  quizCardDetails: {
    cardId: string;
    cardType: string;
    completed: null | string | boolean;
    createdAt: string;
    eid: string;
    entityId: string;
    responseDetails: JSON;
    updatedAt: Date;
    userId: string;
    response: string[];
  }[];
  onFinish?: () => void;
  setShouldAllowSettingModalBooleanValue?: React.Dispatch<
    React.SetStateAction<string>
  >;
};

const CustomCarousel: FC<ICustomCarousel> = ({
  title,
  slides,
  currentPage,
  selectedTraining,
  removeSelectedImage,
  selectedTrainingData,
  activePage,
  setActivePageHandler,
  trainingId,
  isQuizSelection,
  setIsQuizSelection,
  getSingleTrainingHandler,
  quizCardDetails,
  onFinish,
  setShouldAllowSettingModalBooleanValue,
}) => {
  const { t } = useTranslation(['common', 'training']);
  const toast = useToast();
  const userObject = useReactiveVar(userObj);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [updateTrainingProgress, { loading }] = useMutation(
    UPDATE_TRAINING_COMPLETE,
    {
      onCompleted: (data) => {
        if (setShouldAllowSettingModalBooleanValue) {
          setShouldAllowSettingModalBooleanValue('child');
        }
        getSingleTrainingHandler?.();
      },
      onError: () => {},
    }
  );

  const [retakeTrainingRequest] = useMutation(REASSIGN_TRAINING_COMPLETE, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: getSingleTrainingData,
        variables: {
          eid: trainingId,
        },
      },
    ],
    onCompleted: () => {
      currentPage(1);
      toast({
        title: t('common:update'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onError: () => {
      toast({
        title: t('common:error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });

  const arrowStyles = {
    cursor: 'pointer',
    pos: 'absolute',
    top: '50%',
    w: '40px',
    mt: '-22px',
    p: '3px',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    transition: '0.6s ease',
    borderRadius: '70%',
    userSelect: 'none',
    bg: 'black',
    h: '40px',
    //   _hover: {
    //     opacity: 0.8,
    //     // bg: 'black',
    //   },
  };

  useEffect(() => {
    if (activePage !== null) {
      updateTrainingProgressHandler(slides?.[activePage - 1]);
      setCurrentSlide(activePage - 1);
    }
  }, [activePage]);

  const updateTrainingProgressHandler = (data: any) => {
    // console.log('updateTrainingProgressHandler -> quizCardDetails -> ', quizCardDetails);
    // console.log('updateTrainingProgressHandler -> data -> ', data);
    // let found = quizCardDetails.find(quiz => quiz.cardId === data.eid);
    // console.log('updateTrainingProgressHandler -> found -> ', found);
    if (data?.cardType?.toLowerCase() !== 'quiz') {
      setIsQuizSelection(false);
      updateTrainingProgress({
        variables: {
          input: {
            eid: trainingId,
            type: data?.type,
            contentId: data?.uid,
          },
        },
      });
    } else {
      if (data?.completed) {
        setIsQuizSelection(false);
        updateTrainingProgress({
          variables: {
            input: {
              eid: trainingId,
              type: data?.type,
              contentId: data?.uid,
            },
          },
        });
      } else {
        setIsQuizSelection(true);
      }
    }
  };

  const slidesCount = slides.length;

  const prevSlide = () => {
    removeSelectedImage(null);
    setCurrentSlide((s) => {
      const count = s === 0 ? 0 : s - 1;
      currentPage(count + 1);
      return count;
    });
  };

  const nextSlide = () => {
    removeSelectedImage(null);
    setCurrentSlide((s) => {
      const count = s === slidesCount ? slidesCount : s + 1;
      currentPage(count + 1);
      return count;
    });
  };

  const carouselStyle = {
    transition: 'all .5s',
    transform: `translateX(-${currentSlide * 100}%)`,
  };

  const isCheckClickedValueHandler = (value: boolean) => {
    setIsQuizSelection(value);
    updateTrainingProgress({
      variables: {
        input: {
          eid: trainingId,
          type: slides?.[activePage - 1]?.type,
          contentId: slides?.[activePage - 1]?.uid,
        },
      },
    });
  };

  const shouldMoveToNextCardHandler = () => {
    setActivePageHandler();
  };

  const slideList = [
    ...slides,
    {
      type: 'finish',
      title: title,
    },
  ];

  return (
    <Flex
      w='full'
      height='full'
      // bg={useColorModeValue('gray.200', 'gray.600')}
      p={10}
      alignItems='center'
      justifyContent='center'
      // backgroundColor='rgb(38, 50, 56, 0.95);'
    >
      {slides?.length > 0 && (
        <>
          {!(isQuizSelection || currentSlide === 0) && (
            <Text {...arrowStyles} left='50px' onClick={prevSlide}>
              <ChevronLeftIcon boxSize={9} />
            </Text>
          )}
          <Flex
            w='25%'
            h='100%'
            pos='relative'
            overflow='hidden'
            borderRadius='10px'
          >
            <Flex w='full' height='full' {...carouselStyle}>
              {slideList.map((slide: any, sid: any) => (
                <Box
                  key={`slide-${sid}`}
                  boxSize='full'
                  flex='none'
                  backgroundColor='white'
                  opacity={1}
                >
                  <Text
                    color='white'
                    fontSize='xs'
                    p='8px 12px'
                    pos='absolute'
                    top='0'
                  >
                    {/* {sid + 1} / {slidesCount} */}
                  </Text>
                  {/* {slide.type === 'milestone' ? (
                  <Image
                    src={milestoneDefault}
                    boxSize='full'
                    backgroundSize='cover'
                    style={{ objectFit: 'contain' }}
                  />
                ) : (
                  <Image
                    src={slide?.thumbnail}
                    boxSize='full'
                    backgroundSize='cover'
                    style={{ objectFit: 'contain' }}
                  />
                )} */}
                  {slide.type === 'card' && (
                    <TrainingCard
                      isActive={sid === currentSlide}
                      content={slide}
                      isPreview={false}
                      isCheckClickedValue={isCheckClickedValueHandler}
                      shouldMoveToNextCard={shouldMoveToNextCardHandler}
                    />
                  )}
                  {slide.type === 'milestone' && (
                    <Center h='100%' flexDir='column' overflowY='auto' p='12px'>
                      <Image
                        src={milestoneDefault}
                        boxSize='auto'
                        backgroundSize='cover'
                        style={{ objectFit: 'contain' }}
                      />
                      <div
                        style={{
                          fontSize: 16.8,
                          fontWeight: 'bold',
                          color: '#000',
                          width: '100%',
                          padding: '0 16px 16px',
                        }}
                      >
                        {slide?.title}
                      </div>
                    </Center>
                  )}
                  {slide.type === 'finish' && (
                    <TrainingFinished
                      title={slide.title}
                      onFinish={onFinish}
                      retakeTraining={() =>
                        retakeTrainingRequest({
                          variables: {
                            userId: userObject?.eid,
                            trainingId: trainingId,
                          },
                        }) as any
                      }
                    />
                  )}
                  <Stack
                    p='8px 12px'
                    pos='absolute'
                    bottom='24px'
                    textAlign='center'
                    w='full'
                    mb='8'
                    color='white'
                  >
                    {/* <Text fontSize='2xl'>{slide.label}</Text> */}
                    {/* <Text fontSize='lg'>{slide.description}</Text> */}
                  </Stack>
                </Box>
              ))}
            </Flex>
          </Flex>
          {!(
            isQuizSelection ||
            slides?.[currentSlide]?.cardType === 'action' ||
            slidesCount === currentSlide
          ) && (
            <Text {...arrowStyles} right='50px' onClick={nextSlide}>
              <ChevronRightIcon boxSize={9} />
            </Text>
          )}
        </>
      )}
      {slides?.length === 0 && (
        <div style={{ fontSize: 'large', color: 'white', fontWeight: '500' }}>
          {t('training:no_training_record')}
        </div>
      )}
    </Flex>
  );
};

export default CustomCarousel;

import { useMemo } from 'react';
import { ChatGroupEntity } from '../../../../../types';

export const useIsAutoAdd = (details?: ChatGroupEntity['details']): boolean => {
  return useMemo(() => {
    if (details?.type === 'public') {
      return true;
    }

    return (
      [
        ...(details?.authRoles || []),
        ...(details?.roles || []),
        ...(details?.locationIds || []),
      ].length > 0
    );
  }, [details]);
};

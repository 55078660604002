import { gql } from '@apollo/client';

export const getCardsByDeckId = gql`
  query FindCardsIdForDeck($eid: String!) {
    findDeckById(eid: $eid) {
      cards {
        eid
      }
    }
  }
`;

export interface DeckResponse {
  findDeckById?: {
    cards?: Array<{
      eid: string;
    }>;
  };
}

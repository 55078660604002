import React, { FC } from 'react';
import ProgressCell from '../../pages/Training/TrainingPath/ProgressCell';
import StatusCell from './StatusCell';
import ActionButtonCell from './ActionButtonCell';

interface IProps {
  onClick?: (...args: any) => void;
  item: any;
  maxLength: number;
  isDemoAccount?: boolean;
  index?: number;
}

export const DemoProgressCell: FC<IProps> = ({
  isDemoAccount,
  onClick,
  maxLength,
  index,
  item,
}) => {
  const viewedCount = item?.totalViewedCount;

  if (isDemoAccount && !viewedCount) {
    return (
      <ProgressCell
        onClick={onClick}
        item={{
          ...item,
          totalViewedCount:
            index === 0
              ? maxLength
              : index === 1
              ? Math.round(maxLength / 2)
              : viewedCount,
        }}
        maxLength={maxLength}
      />
    );
  }

  return <ProgressCell onClick={onClick} item={item} maxLength={maxLength} />;
};

interface StatusProps {
  viewCount: number;
  totalCount: number;
  isDemoAccount?: boolean;
  index?: number;
}

export const DemoStatusCell: FC<StatusProps> = ({
  viewCount,
  totalCount,
  isDemoAccount,
  index,
}) => {
  if (isDemoAccount && !viewCount) {
    return (
      <StatusCell
        viewCount={
          index === 0
            ? totalCount
            : index === 1
            ? Math.round(totalCount / 2)
            : viewCount
        }
        totalCount={totalCount}
      />
    );
  }

  return <StatusCell viewCount={viewCount} totalCount={totalCount} />;
};

interface ActionProps {
  viewCount: number;
  totalCount: number;
  onRetakeClick: () => void;
  onResumeClick: () => void;
  isLoading?: boolean;

  isDemoAccount?: boolean;
  index?: number;
}

export const DemoActionCell: FC<ActionProps> = ({
  viewCount,
  totalCount,
  onRetakeClick,
  onResumeClick,
  isLoading,
  isDemoAccount,
  index,
}) => {
  if (isDemoAccount && !viewCount) {
    return (
      <ActionButtonCell
        viewCount={
          index === 0
            ? totalCount
            : index === 1
            ? Math.round(totalCount / 2)
            : viewCount
        }
        totalCount={totalCount}
        onRetakeClick={onResumeClick}
        onResumeClick={onResumeClick}
        isLoading={isLoading}
      />
    );
  }

  return (
    <ActionButtonCell
      viewCount={viewCount}
      totalCount={totalCount}
      onRetakeClick={onRetakeClick}
      onResumeClick={onResumeClick}
      isLoading={isLoading}
    />
  );
};

import React, { FC } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';
import { EmptyState, Loader } from 'sub-components';

interface IProps {
  isLoading?: boolean;
  isEmpty?: boolean;
  haveQuery?: boolean;
}

const ConversationLoader: FC<IProps> = ({
  isLoading,
  isEmpty,
  haveQuery,
  children,
}) => {
  const authRole = useUserDataSelector((state) => state.authRole);

  if (isLoading) {
    return (
      <Center flex={1} flexDir='column' gap={4} minH='380px'>
        <Loader size='lg' />
        <Box fontSize='16px' color='#33383F' textAlign='center'>
          Loading your logs... <br />
          Sit tight, we&apos;re almost there! 📝
        </Box>
      </Center>
    );
  }

  if (isEmpty) {
    return (
      <Center flex={1} minH='380px'>
        <EmptyState
          image={haveQuery ? 'Search' : 'NoConversationLogs'}
          imageSize='180px'
          title={haveQuery ? 'No logs found' : 'No logs added'}
          description={
            haveQuery
              ? 'Oops! Try a different search to get results.'
              : authRole === AuthRole.SUPER_ADMIN
              ? 'Click on ‘Add’ button to add new logs.'
              : undefined
          }
        />
      </Center>
    );
  }

  return <>{children}</>;
};

export default ConversationLoader;

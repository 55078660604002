import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, useOutsideClick } from '@chakra-ui/react';
import { SelectOption } from 'atoms';
import React, { FC, useRef, useState } from 'react';

const filterOptions = [
  { label: 'My locations', value: 'myLocations' },
  { label: 'My state locations', value: 'myStateLocations' },
  { label: 'My city locations', value: 'myCityLocations' },
  { label: 'All franchise', value: 'allFranchise' },
  { label: 'All corporates', value: 'allCorporates' },
];

interface IProps {
  filterByCategory: SelectOption<string>[];
  showFilter?: boolean;
  locationFilterChangeHandler: (option: SelectOption<string>[]) => void;
}

const LocationFilter: FC<IProps> = ({
  filterByCategory,
  showFilter = false,
  locationFilterChangeHandler,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  useOutsideClick({
    ref: ref,
    handler: () => setShowDropdown(false),
  });
  if (!showFilter) {
    return null;
  }
  const filterLabel = () => {
    let corporateSelected = filterByCategory?.find(
      (cat) => cat?.value === 'allCorporates'
    );
    let franchiseSelected = filterByCategory?.find(
      (cat) => cat?.value === 'allFranchise'
    );
    if (corporateSelected && franchiseSelected) {
      return ': Both Types';
    } else {
      return `: ${filterByCategory?.[0]?.label}`;
    }
  };
  return (
    <Flex ref={ref} flexDir='column' position='relative'>
      <Flex
        borderRadius='10px'
        border={`2px solid ${
          filterByCategory?.length === 0
            ? 'rgba(239, 239, 239, 1)'
            : 'rgba(42, 133, 255, 1)'
        }`}
        // rgba(42, 133, 255, 1)
        h='42px'
        align='center'
        w='182px'
        justify='space-between'
        p='10px 12px'
        cursor='pointer'
        onClick={() => setShowDropdown(true)}
      >
        <Flex>
          <Text
            as='span'
            color={
              filterByCategory?.length === 0
                ? '#A0AEC0'
                : 'rgba(42, 133, 255, 1)'
            }
            fontWeight={filterByCategory?.length === 0 ? 500 : 600}
          >
            Filter
          </Text>
          {filterByCategory?.length > 0 && (
            <Text
              as='span'
              fontWeight={600}
              color='rgba(42, 133, 255, 1)'
              isTruncated
              maxW='100px'
            >
              {filterLabel()}
            </Text>
          )}
        </Flex>
        <ChevronDownIcon
          fontSize='20px'
          color={
            filterByCategory?.length === 0 ? 'black' : 'rgba(42, 133, 255, 1)'
          }
        />
      </Flex>
      {showDropdown && (
        <Flex
          bg='white'
          flexWrap='wrap'
          gap={4}
          position='absolute'
          w='376px'
          top='50px'
          zIndex={2}
          borderRadius='12px'
          border='1px solid rgba(230, 230, 230, 1)'
          boxShadow='8px -2px 24px 0px rgba(0, 0, 0, 0.1)'
          p={4}
        >
          {filterOptions?.map((option, index) => (
            <Flex
              key={index}
              p='10px 12px'
              borderRadius='10px'
              border={
                !filterByCategory?.find((cat) => cat?.value === option?.value)
                  ? '2px solid rgba(239, 239, 239, 1)'
                  : 'hidden'
              }
              h='40px'
              align='center'
              justify='center'
              cursor='pointer'
              onClick={() => {
                if (
                  !filterByCategory?.find((cat) => cat?.value === option?.value)
                ) {
                  locationFilterChangeHandler([option]);
                } else if (
                  filterByCategory?.find((cat) => cat?.value === option?.value)
                ) {
                  let filtered = filterByCategory?.filter(
                    (cat) => cat?.value !== option?.value
                  );
                  locationFilterChangeHandler(filtered);
                }
              }}
              bg={
                !filterByCategory?.find((cat) => cat?.value === option?.value)
                  ? 'white'
                  : 'rgba(42, 133, 255, 1)'
              }
            >
              <Text
                fontWeight={500}
                color={
                  !filterByCategory?.find((cat) => cat?.value === option?.value)
                    ? 'rgba(111, 118, 126, 1)'
                    : 'white'
                }
                p={0}
                m={0}
              >
                {option?.label}
              </Text>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default LocationFilter;

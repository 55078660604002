import React, { FC } from 'react';
import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { getRandomNumber } from '../../../../../../../utils';

const ProgressListSkeleton: FC<{ showButton?: boolean }> = ({ showButton }) => {
  return (
    <Flex gap={4} align='center'>
      <Skeleton boxSize='64px' borderRadius='8px' />
      <Flex flex={1} flexDir='column' justify='center' gap={1}>
        <Skeleton height='12px' width='20%' borderRadius='8px' />
        <Skeleton height='14px' width='50%' borderRadius='8px' />
      </Flex>
      <Box width='120px'>
        {showButton && (
          <Skeleton height='32px' width='111px' borderRadius='7px' />
        )}
      </Box>
    </Flex>
  );
};

interface IProps {
  isLoaded?: boolean;
}

const ProgressListLoader: FC<IProps> = ({ children, isLoaded }) => {
  if (isLoaded) {
    return <>{children}</>;
  }

  return (
    <Flex flexDir='column' gap={4}>
      {Array.from({ length: getRandomNumber(2, 6) }).map((_, i, arr) => (
        <ProgressListSkeleton key={i} showButton={arr.length % i === 1} />
      ))}
    </Flex>
  );
};

export default ProgressListLoader;

import { ChakraProvider, CSSReset, useDisclosure, Box } from '@chakra-ui/react';
import InviteUserModal from './InviteUserModal';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { theme } from 'theme';
import { ApolloProvider, useApolloClient } from '@apollo/client';

export const useInviteUser = (
  onActionCompleted: (pathName: string, tabSelected: string) => void
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const modalElement = (
      <ChakraProvider theme={theme}>
        <CSSReset />
        <Box>
          <ApolloProvider client={apolloClient}>
            <InviteUserModal
              isOpen={isOpen}
              onClose={onClose}
              onActionCompleted={onActionCompleted}
            />
          </ApolloProvider>
        </Box>
      </ChakraProvider>
    );
    const modalRoot = document.createElement('div');
    modalRef.current = modalRoot;
    document.body.appendChild(modalRoot);
    ReactDOM.render(modalElement, modalRoot);
    return () => {
      if (modalRef.current) {
        document.body.removeChild(modalRef.current);
        ReactDOM.unmountComponentAtNode(modalRef.current);
      }
    };
  }, [isOpen]);

  return { openInviteUserModal: onOpen };
};

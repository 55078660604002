import {
  Box,
  Center,
  ChakraProvider,
  extendTheme,
  Flex,
  Image,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ICropImgFilesData, IThumbnailProps } from './DocumentsUploadComponent';
import { faFileZipper, faPlay } from '@fortawesome/pro-solid-svg-icons';
import PdfIcon from '../../../../../../../assets/images/chapter-editor/pdf-file.svg';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import Cropper, { Point } from 'react-easy-crop';
import { fileToBase64 } from 'utils/utils';
import styled from '@emotion/styled';
import { getCroppedImg } from 'utils/image-crop';
import { cloneDeep } from '@apollo/client/utilities';

interface IProps {
  cropImgFilesData: ICropImgFilesData[];
  files: File[];
  thumbnails: IThumbnailProps[];
  selectedThumbnail: number;
  setCropImgFilesData: React.Dispatch<
    React.SetStateAction<ICropImgFilesData[]>
  >;
  setSelectedThumbnail: React.Dispatch<React.SetStateAction<number>>;
}

interface Crop {
  x: number;
  y: number;
  width: number;
  height: number;
}

const Wrapper = styled.div`
  .cropContainer {
    position: relative;
    width: 100%;
    height: 320px;
    background: #333;
  }

  .cropButton {
    flex-shrink: 0;
    margin-left: 16px;
  }

  .controls {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .sliderContainer {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .slider {
    padding: 22px 0px;
    margin-left: 16px;
  }
`;

const theme = extendTheme({
  components: {
    Slider: {
      parts: ['thumb'],
      baseStyle: {
        thumb: {
          borderColor: '#686868',
          borderWidth: '2px',
        },
      },
    },
  },
});

const DocumentThumbnailDetail: FC<IProps> = ({
  cropImgFilesData,
  files,
  thumbnails,
  selectedThumbnail,
  setCropImgFilesData,
  setSelectedThumbnail,
}) => {
  const [isFinished, setIsFinished] = useState(false);
  const [zoomValue, setZoomValue] = useState(1);

  useEffect(() => {
    console.log(cropImgFilesData?.[selectedThumbnail]?.zoom);
    setZoomValue(cropImgFilesData?.[selectedThumbnail]?.zoom || 1);
  }, [selectedThumbnail]);

  useEffect(() => {
    if (isFinished) {
      let _cropData: ICropImgFilesData[] = JSON.parse(
        JSON.stringify(cropImgFilesData)
      );
      _cropData[selectedThumbnail].zoom = zoomValue;
      setCropImgFilesData(_cropData);
    }
  }, [isFinished]);

  const handleChangeEnd = (value: number) => {
    setIsFinished(true);
  };

  const handleChangeStart = () => {
    setIsFinished(false);
  };

  const onCropComplete = async (croppedArea: any, croppedAreaPixels: any) => {
    try {
      let _cropData: ICropImgFilesData[] = cloneDeep(cropImgFilesData);
      let _base64File = await fileToBase64(files?.[selectedThumbnail]);
      const croppedImage = await getCroppedImg(
        _base64File,
        croppedAreaPixels,
        0,
        undefined,
        files?.[selectedThumbnail]?.name,
        files?.[selectedThumbnail]?.type
      );
      if (!croppedImage) return;
      _cropData?.forEach((_data, index) => {
        if (index === selectedThumbnail) {
          _data.croppedImageFile = croppedImage;
          _data.croppedAreaPixels = croppedAreaPixels;
        }
      });
      setCropImgFilesData(_cropData);
    } catch (e) {
      console.error(e);
    }
  };

  const cropChangeHandler = (location: Point) => {
    let _cropData: ICropImgFilesData[] = cloneDeep(cropImgFilesData);
    _cropData[selectedThumbnail].crop = location;
    setCropImgFilesData(_cropData);
  };

  const setZoomHandler = (zoom: number) => {
    setZoomValue(zoom);
  };

  return (
    <Flex flexDir='column'>
      <Flex
        boxSize='400px'
        flexDir='column'
        align='center'
        justify='center'
        w='full'
      >
        {cropImgFilesData?.length > 0 &&
          thumbnails?.[selectedThumbnail]?.type === 'image' && (
            <Flex
              id='image-cropper-1'
              flexDir='column'
              justify='space-between'
              h='full'
              w='full'
            >
              <Flex
                id='image-cropper-2'
                flexDir='column'
                gap='10px'
                w='full'
                h='90%'
                justify='space-between'
              >
                <Wrapper id='image-cropper-wrapper'>
                  <Box className='cropContainer'>
                    <Cropper
                      image={
                        cropImgFilesData?.[selectedThumbnail]?.imageBase64Url
                      }
                      crop={cropImgFilesData?.[selectedThumbnail]?.crop}
                      zoom={cropImgFilesData?.[selectedThumbnail]?.zoom}
                      aspect={1}
                      onCropChange={(location: Point) =>
                        cropChangeHandler(location)
                      }
                      onCropComplete={onCropComplete}
                      onZoomChange={(zoom: number) => setZoomHandler(zoom)}
                    />
                  </Box>
                </Wrapper>
                <Flex flexDir='column'>
                  <Text>Image scale</Text>
                  <ChakraProvider theme={theme}>
                    <Slider
                      aria-label='image-slider'
                      value={zoomValue}
                      min={1}
                      max={5}
                      step={0.1}
                      onChange={setZoomHandler}
                      onChangeStart={handleChangeStart}
                      onChangeEnd={handleChangeEnd}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb outline='#686868' border='1px solid black' />
                    </Slider>
                  </ChakraProvider>
                </Flex>
              </Flex>
            </Flex>
          )}
        {thumbnails?.[selectedThumbnail]?.type === 'zip' && (
          <Flex flexDir='column'>
            <Center color='#6F767E' boxSize='120px'>
              <FontAwesomeIcon
                icon={faFileZipper as IconProp}
                fontSize='100px'
              />
            </Center>
            <Text fontWeight={600}>{files?.[selectedThumbnail]?.name}</Text>
          </Flex>
        )}
        {thumbnails?.[selectedThumbnail]?.type === 'pdf' && (
          <Flex flexDir='column'>
            <Center
              bg='#FFECEC'
              p={0}
              boxSize='120px'
              borderRadius='6px'
              flexDir='column'
            >
              <Image
                src={PdfIcon}
                boxSize='120px'
                height='100px'
                width='100px'
              />
            </Center>
            <Text fontWeight={600}>{files?.[selectedThumbnail]?.name}</Text>
          </Flex>
        )}
        {thumbnails?.[selectedThumbnail]?.type === 'video' && (
          <Flex flexDir='column' w='full' align='center' justify='center'>
            <Center
              bg='black'
              w='full'
              alignItems='center'
              p={0}
              boxSize='120px'
              borderRadius='6px'
              flexDir='column'
            >
              <FontAwesomeIcon
                icon={faPlay as IconProp}
                fontSize='100px'
                color='white'
              />
            </Center>
            <Text fontWeight={600}>{files?.[selectedThumbnail]?.name}</Text>
          </Flex>
        )}
      </Flex>
      <Flex gap='10px'>
        {thumbnails.map((thumb, index) => (
          <Box
            onClick={() => setSelectedThumbnail(index)}
            key={index}
            cursor='pointer'
            position='relative'
          >
            {selectedThumbnail === index && (
              <FontAwesomeIcon
                icon={faCheck as IconProp}
                fontSize='15px'
                style={{
                  backgroundColor: 'white',
                  color: '#2A85FF',
                  borderRadius: '50%',
                  position: 'absolute',
                  top: '25%',
                  right: '25%',
                  padding: '2px 4px',
                }}
              />
            )}
            {thumb?.content}
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default DocumentThumbnailDetail;

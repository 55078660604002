import * as yup from 'yup';
import moment from 'moment/moment';
import { IFormInput, RepeatCycleData, TaskDueData } from '../task.types';
import { getMinDueDate, taskDueDateValidation } from './taskDueDateValidation';
import { endConditionValidate } from './endConditionValidate';
import { startTimeValidation } from './startTimeValidation';

const taskRunFor = yup
  .string()
  .oneOf(['once', 'repeat'], 'Please select valid input')
  .required('Please select task schedule type');

// Task Due Validation Rule

const taskDue = yup.string().when('taskRunFor', {
  // @ts-ignore
  is: (val: IFormInput['taskRunFor']) => val === 'once',
  then: () =>
    yup
      .string()
      .oneOf(TaskDueData, 'Please select when task will due')
      .test('taskDueDiff', async (value, context) => {
        const values = context.parent as IFormInput;

        const response = taskDueDateValidation(value!, values);

        if (response) {
          const minDueDate = getMinDueDate(values);

          if (minDueDate) {
            return context.createError({
              message: `Due date should be later ${minDueDate?.format('LLL')}`,
            });
          }

          return context.createError({
            message: 'Due date should be later than publish date',
          });
        }
        return true;
      })
      .required('Please select when task will due'),
  otherwise: () => yup.string(),
});

const taskDueDate = yup.date().when(['taskRunFor', 'taskDue'], {
  // @ts-ignore
  is: (val: IFormInput['taskRunFor'], taskDue: IFormInput['taskDue']) => {
    return val === 'once' && taskDue === 'date';
  },
  then: () => yup.date().required('Please select task due date'), // TODO TEST Required
  otherwise: () => yup.date(),
});

const dueTime = yup.date().when(['taskRunFor', 'taskDue', 'repeatCycle'], {
  // @ts-ignore
  is: (
    taskRunFor: IFormInput['taskRunFor'],
    taskDue: IFormInput['taskDue'],
    repeatCycle: IFormInput['repeatCycle']
  ) => {
    if (taskRunFor === 'repeat') {
      return !['days'].includes(repeatCycle!);
    }
    return ['nextWeek', 'date'].includes(taskDue);
  },
  then: () => yup.date().required('Please select task due time'),
  otherwise: () => yup.date(),
});

// validation Rule for Repeat Schedule
const repeatCycle = yup.string().when('taskRunFor', {
  // @ts-ignore
  is: (val: IFormInput['taskRunFor']) => val === 'repeat',
  then: () =>
    yup
      .string()
      .oneOf(RepeatCycleData, 'Please select repeating schedule')
      .required('Please select repeating schedule'),
  otherwise: () => yup.string(),
});

const weekDays = yup.array().when(['taskRunFor', 'repeatCycle'], {
  // @ts-ignore
  is: (
    val: IFormInput['taskRunFor'],
    repeatCycle: IFormInput['repeatCycle']
  ) => {
    return val === 'repeat' && repeatCycle === 'weekly';
  },
  then: () => {
    return yup
      .array()
      .of(yup.string())
      .min(1, 'Please select week days')
      .required('Please select week days');
  },
  otherwise: () => yup.array(),
});

const repeatMonthly = yup.mixed().when(['taskRunFor', 'repeatCycle'], {
  // @ts-ignore
  is: (
    val: IFormInput['taskRunFor'],
    repeatCycle: IFormInput['repeatCycle']
  ) => {
    return val === 'repeat' && repeatCycle === 'monthly';
  },
  then: () => {
    return yup
      .mixed()
      .test('repeatMonthly', 'Please select monthly repeat date', (value) => {
        if (value === 'lastDay') {
          return true;
        }
        return moment(value).isValid();
      })
      .required('Please select monthly repeat date');
  },
  otherwise: () => yup.mixed(),
});

const repeatYearly = yup.date().when(['taskRunFor', 'repeatCycle'], {
  // @ts-ignore
  is: (
    val: IFormInput['taskRunFor'],
    repeatCycle: IFormInput['repeatCycle']
  ) => {
    return val === 'repeat' && repeatCycle === 'yearly';
  },
  then: () => yup.date().required('Please select yearly repeat date'),
  otherwise: () => yup.date(),
});

const noOfDays = yup.string().when(['taskRunFor', 'repeatCycle'], {
  // @ts-ignore
  is: (
    val: IFormInput['taskRunFor'],
    repeatCycle: IFormInput['repeatCycle']
  ) => {
    return val === 'repeat' && repeatCycle === 'days';
  },
  then: () =>
    yup
      .string()
      .matches(/^[0-9]+$/, 'Please enter valid number')
      .test('len', 'Please enter value greater than 2', (val) => +val! >= 2)
      .required('Please enter no of days'),
  otherwise: () => yup.string(),
});

// Publish Date Validation Rule

const startDateType = yup
  .string()
  .oneOf(['now', 'date'], 'Please select when task will publish')
  .required('Please select when task will publish');

const startDate = yup.date().when('startDateType', {
  // @ts-ignore
  is: (val: IFormInput['startDateType']) => val === 'date',
  then: () => yup.date().required('Please select publish date'), // TODO TEST Required
  otherwise: () => yup.date(),
});

const startTime = yup.date().when('startDateType', {
  // @ts-ignore
  is: (val: IFormInput['startDateType']) => val === 'date',
  then: () =>
    yup
      .date()
      .test('minStartTime', async (value, context) => {
        const values = context.parent as IFormInput;

        const response = startTimeValidation(value!, values);
        if (response) {
          return context.createError({ message: response });
        }
        return true;
      })
      .required('Please select publish time'),
  otherwise: () => yup.date(),
});

export const ScheduleValidationRule = {
  taskRunFor: taskRunFor,

  // Task Due
  taskDue: taskDue,
  taskDueDate: taskDueDate,

  dueTime: dueTime,

  // Repeat Occurrence
  repeatCycle: repeatCycle,
  weekDays: weekDays,
  repeatMonthly: repeatMonthly,
  repeatYearly: repeatYearly,
  noOfDays: noOfDays,

  // Task end date details
  endCondition: endConditionValidate,

  // Publish
  startDateType: startDateType,
  startDate: startDate,
  startTime: startTime,
};

// This function is for react-hook-form Controller

export const taskDueValidation = (
  value: IFormInput['taskDue'],
  values: IFormInput
): string | undefined => {
  const response = taskDueDateValidation(value!, values);

  if (response) {
    const minDueDate = getMinDueDate(values);

    if (minDueDate) {
      return `Due date should be later ${minDueDate?.format('LLL')}`;
    }

    return 'Due date should be later than publish date';
  }
};

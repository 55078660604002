import React, { FC, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import SearchInput from '../../../../atoms/SearchInput';
import EmptyState from '../../../../sub-components/EmptyState';
import { IconImage } from '../../../../ui-components';

import { LocationsEntity } from '../team.types';
import locationIcon from '../../../../assets/images/location-green.svg';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  locations?: LocationsEntity[];
  memberName?: string;
  onClick: (location: LocationsEntity) => void;
}

const LocationListModal: FC<IProps> = ({
  isOpen,
  onClose,
  locations = [],
  memberName,
  onClick,
}) => {
  const { t } = useTranslation(['team']);
  const [searchQuery, setSearchQuery] = useState('');

  const locationList = useMemo(() => {
    if (searchQuery) {
      const reg = new RegExp(searchQuery, 'gi');
      return (
        locations?.filter((value) => {
          return value.name?.match(reg);
        }) || []
      );
    }

    return locations || [];
  }, [locations, searchQuery]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent py={3}>
        <ModalHeader>
          <Text isTruncated mr={8}>
            <Trans
              t={t}
              i18nKey='team:membersLocations'
              values={{
                member: memberName,
              }}
            />
          </Text>
          <ModalCloseButton top={6} right={6} />
        </ModalHeader>
        <ModalBody>
          <SearchInput
            placeholder='Search'
            hideShortcuts
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
          />
          {locationList?.length > 0 ? (
            <List minH='40vh' maxH='40vh' mt={4} overflowY='scroll' spacing={3}>
              {locationList?.map((location) => (
                <ListItem
                  key={location?.eid}
                  cursor='pointer'
                  onClick={() => onClick?.(location)}
                >
                  <Flex gap={'10px'} align='center'>
                    <IconImage
                      name=''
                      // @ts-ignore
                      thumbnail={location?.profilePic || locationIcon}
                      boxSize={40}
                      borderRadius='5px'
                      objectFit='cover'
                    />
                    <Flex flexDir='column'>
                      <Box
                        m={0}
                        fontWeight={700}
                        fontSize='15px'
                        color='#1A1D1F'
                        noOfLines={2}
                      >
                        {location?.name}
                      </Box>
                    </Flex>
                  </Flex>
                </ListItem>
              ))}
            </List>
          ) : (
            <Box mb={4}>
              <EmptyState image='Search' title={t('team:searchLocNotFound')} />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LocationListModal;

import { useState } from 'react';
import Lottie from 'react-lottie';
import { gql, useMutation } from '@apollo/client';
import { notification } from 'antd';

import { GET_BOOKMARKED_SOP } from 'ui-components/BookmarkHome/BookmarkHome';
import { GET_SOP_BY_ID } from 'ui-components/SopContent/SopContent';

import { ReactComponent as BookmarkSopSvg } from '../../assets/images/bookmarksopIcon.svg';

import animationData from '../../assets/lotties/bookmarkLottie.json';

interface Props {
  isBookmarked: boolean;
  sopId?: string;
  refetchData?: any;
  setIsBookmarked?: any;
  style?: any;
}

export const SET_BOOKMARK = gql`
  mutation ($sopId: String!, $bookmark: Boolean!) {
    setSopBookmark(sopId: $sopId, bookmark: $bookmark) {
      bookmark
      sopId
      userId
    }
  }
`;

const BookmarkLabel = ({
  isBookmarked,
  sopId,
  refetchData,
  setIsBookmarked,
  style,
}: Props) => {
  const [isRequestInitiated, setIsRequestInitiated] = useState(false);
  const [setBookmark] = useMutation(SET_BOOKMARK, {
    // refetchQueries: [
    //   // {
    //   //   query: GET_SOP_BY_ID,
    //   //   variables: {
    //   //     eid: sopId,
    //   //   },
    //   // },
    //   {
    //     query: GET_BOOKMARKED_SOP,
    //     variables: {
    //       input: {
    //         roles: [],
    //         bundles: [],
    //       },
    //     },
    //   },
    // ],
    onCompleted: () => {
      if (refetchData) {
        refetchData();
      }
    },
    awaitRefetchQueries: true,
  });
  const defaultUploadLottieOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  //   const isBookmarked = sopData?.views?.bookmark;

  let bookmarkStyle = {};
  if (!isBookmarked) {
    bookmarkStyle = {
      stroke: '#DB4C3F',
      fill: 'transparent',
    };
  } else {
    bookmarkStyle = {
      fill: '#DB4C3F',
    };
  }

  const onUpdateBookmark = () => {
    if (!isBookmarked) {
      setIsRequestInitiated(true);
    }
    setBookmark({
      variables: {
        sopId: sopId,
        bookmark: !isBookmarked,
      },
    }).then(() => {
      if (isBookmarked) {
        if (setIsBookmarked) {
          setIsBookmarked(false);
        }

        notification.success({
          message: 'Bookmark removed successfully',
        });
      } else {
        setTimeout(() => {
          setIsRequestInitiated(false);
        }, 500);
        if (setIsBookmarked) {
          setIsBookmarked(true);
        }

        notification.success({
          message: 'Bookmarked successfully',
        });
      }
    });
  };

  return (
    <>
      {isRequestInitiated && (
        <div
          className='eventclass-sop-detail-bookmark-label'
          style={{
            position: 'relative',
            marginRight: '25px',
            display: 'flex',
            cursor: 'pointer',
            width: '13px',
            height: '18px',
            bottom: '3px',
            ...style,
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: '-21px',
              right: '-14px',
            }}
          >
            <Lottie options={defaultUploadLottieOptions} width={40} />
          </div>
        </div>
      )}
      {!isRequestInitiated && (
        <div
          className='eventclass-sop-detail-bookmark-label'
          style={{
            marginRight: '24px',
            display: 'flex',
            cursor: 'pointer',
            ...style,
          }}
          onClick={onUpdateBookmark}
        >
          {!isRequestInitiated && <BookmarkSopSvg style={bookmarkStyle} />}
        </div>
      )}
    </>
  );
};

export default BookmarkLabel;

import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';
import { useUserDataSelector } from 'hooks';
import { FileWithEid } from 'hooks/useUploadMultipleFilesWithCancel';
import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import React, { FC } from 'react';
import { eventBus } from 'shared/eventEmit';
import { AuthRole } from 'sop-commons/src/client';
import useCombinedStore from 'zustandStore/store';

interface IProps {
  selectedTaskData: CustomLauncherTask;
  // markAsCompleteHandler: () => void;
}

const ButtonFooter: FC<IProps> = ({
  selectedTaskData,
  // markAsCompleteHandler,
}) => {
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const {
    taskDetails: { isUploading, loading, selectedFiles, selectedStep },
  } = useCombinedStore();
  if (selectedTaskData?.isCompleted) {
    return null;
    // return (
    //   <Flex w='full'>
    //     <Tooltip label='In development' hasArrow>
    //       <Box w='full'>
    //         <PrimaryButton
    //           title='Mark as unfinished?'
    //           variant='outline'
    //           disabled
    //         />
    //       </Box>
    //     </Tooltip>
    //   </Flex>
    // );
  }

  const btnDisableHandler = () => {
    if (
      selectedStep?.loading ||
      selectedTaskData?.steps?.some((step) => !step.isCompleted) ||
      loading ||
      isUploading
    ) {
      return true;
    }
    if (
      selectedTaskData?.docRequired &&
      selectedFiles?.every((file) => !file?.url)
    ) {
      return true;
    }
    return false;
  };

  const markAsCompleteHandler = () => {
    eventBus.emit('markTaskAsComplete');
  };

  return (
    <Flex
      flexDir='column'
      gap={2}
      w={
        loggedInUserAuthRole === AuthRole.LOCATION_OWNER
          ? 'full'
          : 'fit-content'
      }
    >
      <PrimaryButton
        title='Mark as finished'
        leftIcon={<CheckIcon />}
        disabled={btnDisableHandler()}
        isLoading={loading}
        variant='solid'
        colorScheme='blue'
        size={loggedInUserAuthRole === AuthRole.LOCATION_OWNER ? 'lg' : 'md'}
        style={{
          width:
            loggedInUserAuthRole === AuthRole.LOCATION_OWNER
              ? '100%'
              : 'fit-content',
        }}
        onClick={markAsCompleteHandler}
      />
      {/* <Tooltip label='In development' hasArrow>
        <Box>
          <PrimaryButton
            disabled
            title='Raise help query'
            variant='outline'
            size='lg'
          />
        </Box>
      </Tooltip> */}
    </Flex>
  );
};

export default ButtonFooter;

import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { IconImage } from '../../../../ui-components';
import { IFormItemsEntity } from 'pages/forms/forms-types';

interface IProps {
  formData: IFormItemsEntity;
  hideUpdatedBy?: boolean;
  latestResponseText?: boolean;
}

const FormName: FC<IProps> = ({
  formData,
  hideUpdatedBy = false,
  latestResponseText = false,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Flex mr='1rem' maxWidth='100%' gap='10px'>
      <div>
        <IconImage
          name={formData.title}
          thumbnail={formData.thumbnail}
          boxSize={60}
          borderRadius='6px'
        />
      </div>
      <Flex flexDir='column' overflow='hidden' justify='space-around'>
        <Box fontWeight='700' whiteSpace='pre-wrap'>
          {formData?.title}
        </Box>
        <span style={{ fontWeight: '500', fontSize: '12px', color: '#6F767E' }}>
          <Trans
            t={t}
            i18nKey={
              !latestResponseText
                ? 'common:last_updated_date'
                : 'common:latestResponse'
            }
            values={{
              date: moment(
                formData?.updatedAt ||
                  formData?.lastUpdated ||
                  formData?.createdAt
              ).format('DD MMM'),
            }}
          />
        </span>
        {!hideUpdatedBy && (
          <span
            style={{ fontWeight: '500', fontSize: '12px', color: '#6F767E' }}
          >
            {t('common:by_lower')}{' '}
            {formData?.lastUpdatedBy?.name || formData?.createdBy?.name}
          </span>
        )}
      </Flex>
    </Flex>
  );
};

export default FormName;

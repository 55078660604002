import { AxisDomain } from 'recharts/types/util/types';
import moment from 'moment';
import { AnalyticBase, IGraphRange, TrendAnalytic } from './performance.types';

export const toPercent = (decimal: number): string => `${decimal}%`;

export const AxisPercentageDomain: AxisDomain = [
  0,
  (dataMax: number): number => {
    return dataMax < 15 ? 25 : dataMax < 35 ? 50 : dataMax < 55 ? 75 : 100;
  },
];

export const getCompleteRate = (value: AnalyticBase): number => {
  return (
    Math.round((value.complete / (value.complete + value.inComplete)) * 100) ||
    0
  );
};

export const getGraphTooltipLabel = (
  value: TrendAnalytic,
  type: IGraphRange['type']
): string => {
  if (type === 'weekly') {
    return moment(value.end).format('wo').concat(' Weeks');
  } else {
    return moment(value.start).format('MMMM');
  }
};

export const getGraphLabel = (
  value: TrendAnalytic,
  type: IGraphRange['type']
): string => {
  if (type === 'weekly') {
    return moment(value.end).format('DD MMM');
  } else {
    return moment(value.start).format('MMM');
  }
};

export const getGraphMonthSample = (
  initialData = {}
): Array<{ [key: string]: string | number }> => {
  return moment.monthsShort().map((value) => {
    return {
      month: value,
      ...initialData,
    };
  });
};

export const getGraphWeekSample = (
  initialData = {},
  startDate: string,
  endDate: string
) => {
  let ST = moment(startDate);

  const weeks: number[] = [];

  weeks.push(ST.isoWeek());
  while (ST.isBefore(endDate)) {
    ST = ST.add(1, 'week');
    weeks.push(ST.isoWeek());
  }

  return Array.from({ length: weeks.length }).map((_, index) => {
    if (index === 0) {
      return {
        week: weeks[index],
        month: moment(startDate).format('DD MMM'),
        ...initialData,
      };
    }

    const DD = moment().weeks(weeks[index]).startOf('week').add(1, 'day');

    if (DD.isAfter(endDate)) {
      return {
        week: weeks[index],
        month: moment(endDate).format('DD MMM'),
        ...initialData,
      };
    }

    return {
      week: weeks[index],
      month: DD.format('DD MMM'),
      ...initialData,
    };
  });
};

// const getWeekSample = (date: moment.Moment) => {
//   return Array.from({ length: date.weeksInYear() }).map((_, value) => {
//     return {
//       month: moment().weeks(value).startOf('week').format('ddd DD MMM'),
//       completeRate: 0,
//     };
//   });
// };

import React, { FC, useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { FormInput } from '../../../../../atoms';
import { AddResourceButton, ResourceAction } from './resource';

interface IProps {
  isDisabled?: boolean;
  resourceHandler?: (action: ResourceAction) => void;
  onAddClick?: (value: string) => void;
}

const AddStepForm: FC<IProps> = ({
  isDisabled,
  onAddClick,
  resourceHandler,
}) => {
  const { t } = useTranslation(['common', 'task', 'launcher']);

  const [value, setValue] = useState('');

  const haveValue = value?.trim();

  return (
    <Flex gap={3}>
      <FormInput
        placeholder={t('task:placeholder.addATODOItem')}
        variant='outline'
        size='lg'
        isDisabled={isDisabled}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        rightElementProps={{
          width: '5rem',
        }}
        paddingRight={haveValue ? 20 : 4}
        rightIcon={
          haveValue ? (
            <Button
              fontSize='14px'
              leftIcon={<FontAwesomeIcon size='1x' icon={faPlus as IconProp} />}
              onClick={() => {
                onAddClick?.(haveValue);
                setValue('');
              }}
              colorScheme='blue'
              variant='ghost'
              paddingX='15px'
              borderRightRadius='12px'
              height='44px'
            >
              {t('common:add')}
            </Button>
          ) : undefined
        }
      />

      <AddResourceButton
        haveValue={!haveValue}
        isDisabled={isDisabled}
        handleClick={resourceHandler}
      />
    </Flex>
  );
};

export default AddStepForm;

import { useCallback, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { uploadMultipart } from '../utils/multipartUpload';
import { DEFAULT_CHUNK_SIZE } from '../utils/constants';

export const COMPLETE_MULTIPART = gql`
  mutation CompleteMultiPart($input: CompleteMultipartUploadInput) {
    CompleteMultiPart(input: $input) {
      succeed
    }
  }
`;

export const GET_SIGNED_URL_MULTIPART = gql`
  mutation GetSignedUrlMultiPart(
    $getSignedUrlMultiPartInput2: MultipartFileDetailsInput
  ) {
    getSignedUrlMultiPart(input: $getSignedUrlMultiPartInput2) {
      signedRequestUrls
      url
      mimetype
      s3_filename
      filename
      key
      uploadId
    }
  }
`;

type Progress = (progress: number) => void;

interface IProps {
  chunkSize: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useLargeFileUpload = ({ chunkSize = DEFAULT_CHUNK_SIZE }: IProps) => {
  const [getSignedUrlMultiPart] = useMutation(GET_SIGNED_URL_MULTIPART);
  const [completeMultiPartApiCall] = useMutation(COMPLETE_MULTIPART);
  const progressRef = useRef<number>(0);

  const uploadParts = useCallback(
    async (file: File, urls: string[], uploadProgress?: Progress) => {
      if (!file) {
        return;
      }
      return await uploadMultipart(file, urls, chunkSize, uploadProgress);
    },
    [chunkSize]
  );

  return useCallback(
    async (file: File | null, uploadProgress?: Progress) => {
      // Reset Upload Progress Value
      progressRef.current = 0;

      if (file && file.name && file.type) {
        return await getSignedUrlMultiPart({
          variables: {
            getSignedUrlMultiPartInput2: {
              mimetype: file.type,
              parts: Math.ceil(file?.size / chunkSize),
              filename: file.name,
            },
          },
        }).then(async (response) => {
          const videoUrl = response.data.getSignedUrlMultiPart.url;
          const partsArray = await uploadParts(
            file,
            response.data.getSignedUrlMultiPart.signedRequestUrls,
            (progressValue) => {
              progressRef.current = progressValue - 1;
              uploadProgress?.(progressRef.current);
            }
          );
          const { key, uploadId } = response.data.getSignedUrlMultiPart;

          await completeMultiPartApiCall({
            variables: {
              input: {
                key: key,
                uploadId: uploadId,
                parts: partsArray,
              },
            },
          });
          uploadProgress?.(progressRef.current + 1);

          return videoUrl as string;
        });
      } else {
        return undefined;
      }
    },
    [chunkSize]
  );
};

export default useLargeFileUpload;

import { gql } from '@apollo/client';
import { QuestionEntity, ResponseEntity } from '../../types';

export const FORMS_PAGINATION = gql`
  query FormsPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyFormsInput
    $sort: SortFindManyFormsInput
  ) {
    FormsPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        category
        contributors
        contributorUsers {
          email
          eid
          name
          profilePic
          username
        }
        createdAt
        createdBy {
          eid
          name
          username
        }
        description
        eid
        entityId
        isTemplate
        lastUpdatedBy {
          eid
          name
        }
        responseCount
        status
        title
        thumbnail
        # questions
        updatedAt
      }
    }
  }
`;

export const FORMS_RESPONSE = gql`
  query FormResponse(
    $page: Int
    $filter: FilterFindManyFormsResponseInput
    $perPage: Int
    $sort: SortFindManyFormsResponseInput
  ) {
    FormResponse(page: $page, filter: $filter, perPage: $perPage, sort: $sort) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        userId
        status
        formId
        response
        initialResponseAt
        completedAt
        updatedAt
        createdAt
        eid
        createdBy {
          eid
          authRole
          email
          name
          role
          profilePic
          locations {
            name
          }
        }
        form {
          questions
          userList
        }
      }
    }
  }
`;

export const FORM_BY_ID_RESPONSE = gql`
  query FormById($eid: String!, $override: Boolean) {
    FormById(eid: $eid, override: $override) {
      title
      description
      category
      thumbnail
      lastUpdatedBy {
        eid
        name
      }
      createdBy {
        eid
        name
      }
      latestResponse {
        createdAt
      }
      updatedAt
      createdAt
      eid
      userList
      contributors
      visibility
      visibleTo {
        condition
        locations
        roles
        users
      }
    }
  }
`;

export interface FormDataById {
  eid: string;
  title: string;
  description: string;
  category: string;
  thumbnail?: string;
  questions: QuestionEntity[];
}

export const FORM_BY_ID_RESPONSE_WITH_QUESTIONS = gql`
  query FormDataForModal($eid: String!) {
    FormById(eid: $eid) {
      eid
      title
      description
      category
      thumbnail
      questions
    }
  }
`;

export const FORMS_ACTIVITY = gql`
  query FormsActivity(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyFormsLogInput
    $sort: SortFindManyLogInput
  ) {
    formsActivity(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        eventType
        eventName
        payload
        userId
        entityId
        locations {
          name
          eid
        }
        users
        source
        comment
        meta
        visible
        createdAt
        updatedAt
        eid
        user {
          name
          profilePic
          eid
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export interface FormExportEntity {
  formId: string;
  response: ResponseEntity[];

  completedAt: string;

  createdBy: {
    name: string;
    eid: string;
    locations: Array<{
      eid: string;
      name: string;
    }>;
    profilePic: string;
  };
}

export const FORMS_RESPONSE_EXPORT = gql`
  query FormResponseExport(
    $formId: String!
    $sessionId: String
    $locations: [String]
    $optionsFilter: [String]
    $userIds: [String]
    $startDate: Date
    $endDate: Date
  ) {
    FormResponseExport(
      formId: $formId
      sessionId: $sessionId
      locations: $locations
      optionsFilter: $optionsFilter
      userIds: $userIds
      startDate: $startDate
      endDate: $endDate
    ) {
      formId
      response

      completedAt

      createdBy {
        name
        eid
        locations {
          eid
          name
        }
        profilePic
      }
    }
  }
`;

export const FORM_APPEARS_IN = gql`
  query FormAppearsIn($formId: String!) {
    FormAppearsIn(formId: $formId) {
      cards {
        title
      }
      decks {
        title
      }
      trainings {
        title
      }
    }
  }
`;

export const QUESTION_WISE_RESPONSE = gql`
  query QuestionWiseResponse(
    $formId: String!
    $qId: String!
    $page: Int
    $perPage: Int
    $query: String
    $locationIds: [String]
    $startDate: Date
    $endDate: Date
  ) {
    QuestionWiseResponse(
      formId: $formId
      qId: $qId
      page: $page
      perPage: $perPage
      query: $query
      locationIds: $locationIds
      startDate: $startDate
      endDate: $endDate
    ) {
      items {
        user
        response
        locations
        createdAt
        eid
      }
      count
      limit
      totalPages
      hasPrevPage
      hasNextPage
    }
  }
`;

export const FORM_ANALYTICS = gql`
  query Query($eid: String!) {
    FormAnalytics(eid: $eid)
  }
`;

export const GET_FORM_RESPONDENTS = gql`
  query FormRespondents($formId: String!) {
    FormRespondents(formId: $formId) {
      locations {
        eid
        name
      }
      users {
        eid
        name
      }
    }
  }
`;

import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, deployEvent } from '../../../../../shared';

import FormDataContext from 'pages/forms/FormStore/form-data-context';
import { FormResponseTabsListWrapper } from './FormResponseTabsList.styles';
import { IDateFilterRangeEntity } from 'ui-components/DateFilter/types';

type TabItem = {
  name: string;
  event: AmplitudeEvent;
};

interface IProps {
  dateRangeFilter: IDateFilterRangeEntity | undefined;
}

const FormResponseTabsList: FC<IProps> = ({ dateRangeFilter }) => {
  const { t } = useTranslation('form');
  const formCtx = useContext(FormDataContext);

  const tabList: TabItem[] = [
    {
      name: t('user_wise_response_heading'),
      event: AmplitudeEvent.FORM_RESPONSE_TAB_USER_WISE,
    },
    {
      name: t('question_wise_response_heading'),
      event: AmplitudeEvent.FORM_RESPONSE_TAB_QUESTION_WISE,
    },
    // { name: 'Graphs' },
    {
      name: t('summary_response_heading'),
      event: AmplitudeEvent.FORM_RESPONSE_TAB_SUMMARY,
    },
  ];

  const getClassHandler = (index: number) => {
    if (index === formCtx?.selectedTabIndex) {
      return 'form-response-item active';
    }
    return 'form-response-item';
  };

  const changeTabHandler = (index: number) => {
    formCtx.changeTabIndex(index);
  };

  const items = formCtx?.responseData?.items;

  if (!items?.length && !dateRangeFilter?.from && !formCtx?.searchString) {
    return null;
  }

  return (
    <FormResponseTabsListWrapper>
      {tabList?.map((tab, index) => {
        return (
          <div
            key={tab?.name}
            className={getClassHandler(index)}
            onClick={() => {
              deployEvent(tab.event);
              changeTabHandler(index);
            }}
          >
            {tab?.name}
          </div>
        );
      })}
    </FormResponseTabsListWrapper>
  );
};

export default FormResponseTabsList;

export const MIN_DATE = '2016-08-19T13:43:16.772Z';

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const YOUTUBE_REGEX =
  /^(http(s)??\:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+/; // eslint-disable-line

export const VIMEO_REGEX =
  /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/; // eslint-disable-line

const SOP_UPLOAD_REGX = /https:\/\/sop-uploads-(prod).s3.amazonaws.com/g;

const IMGIX_REGX = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.imgix.net\/)+/g;

const SOP_UPLOAD_URL = process.env.REACT_APP_IMAGE_URL!;

// const IMGIX_URL = process.env.REACT_APP_IMGIX_URL!;

export { SOP_UPLOAD_REGX, IMGIX_REGX, SOP_UPLOAD_URL };

export const DEFAULT_CHUNK_SIZE = 1048576 * 6;

export const FILENAME_REGEX = /(\/|_[0-9]{3}_)/;

export const EXT_RENAME_REGEX = /[.]\w+$/;

export const CALENDLY_LINK = process.env.REACT_APP_CALENDLY_LINK!;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;

export const AES_DECRYPTION_KEY = process.env.REACT_APP_AES_DECRYPTION_KEY!;

export const IMAGEKIT_URL_ENDPOINT = process.env.REACT_APP_IMAGEKIT_URL!;

export const localizationEnabled =
  process.env.REACT_APP_LOCALIZATION! === 'true';

import React, { FC, useMemo, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { match } from 'ts-pattern';

import ProgressLists from './ProgressLists';
import { LauncherLocation } from '../dashboard.graphql';
import { filterOptions } from './progress.data';

import { HeaderColors } from '../../../../shared/headerColors/header-colors';
import GenericColorHeader from '../../../../sub-components/GenericColorHeader';
import OpeningTask from '../../../../sub-components/LocationLaunchDetail/OpeningTask';
import moment from 'moment-timezone';
import { useUserDataSelector } from 'hooks';
import { calculateTimeDifference } from 'pages/Home/components/LOInProgress/helper';
import { cloneDeep } from '@apollo/client/utilities';
import {
  getUserTimezoneConvertedDate,
  getUserTimezoneConvertedNow,
} from 'pages/launcher/details/helper';
import { LauncherLocationDetail } from 'pages/launcher/details/types/location-launch-details.types';

interface IProps {
  isLoading?: boolean;
  locations: LauncherLocation[];
}

const ProgressListContainer: FC<IProps> = ({ locations, isLoading }) => {
  const loggedInUserTimezone = useUserDataSelector((state) => state.timezone);
  const _locations = useMemo(() => {
    let _locData: LauncherLocationDetail[] = cloneDeep(
      locations
    ) as LauncherLocationDetail[];
    _locData?.forEach((loc) => {
      loc?.tasks?.map((task) => {
        let taskDueDate = moment.utc(task?.dueDate);
        const now = getUserTimezoneConvertedNow(loggedInUserTimezone);
        let taskStartDate = getUserTimezoneConvertedDate(
          loggedInUserTimezone,
          task?.startDate
        );
        let taskCompleteDate = getUserTimezoneConvertedDate(
          loggedInUserTimezone,
          task?.completedAt
        );
        let diffInMilliseconds = taskDueDate?.diff(now);
        let duration = moment?.duration(diffInMilliseconds);
        let hours = Math?.floor(duration?.asHours());
        let minutes = Math?.floor(duration?.asMinutes()) - hours * 60;
        let durationString = calculateTimeDifference(now, taskDueDate);
        if (task?.completedAt) {
          task.formattedDueDate = taskDueDate;
          task.formattedStartDate = taskStartDate;
          task.formattedCompleteDate = taskCompleteDate;
          task.formattedDuration = durationString;
          task.taskStatus = 'complete';
        } else if (taskDueDate.isBefore(now)) {
          task.formattedDueDate = taskDueDate;
          task.formattedStartDate = taskStartDate;
          task.formattedCompleteDate = taskCompleteDate;
          task.formattedDuration = durationString;
          task.taskStatus = 'overdue';
        } else {
          task.formattedDueDate = taskDueDate;
          task.formattedStartDate = taskStartDate;
          task.formattedCompleteDate = taskCompleteDate;
          task.formattedDuration = durationString;
          task.taskStatus = 'inProgress';
        }
        task?.steps?.forEach((step) => {
          if (step?.completedAt) {
            let stepCompleteDate = getUserTimezoneConvertedDate(
              loggedInUserTimezone,
              step?.completedAt
            );
            step.formattedCompleteDate = stepCompleteDate;
          } else {
            step.formattedCompleteDate = undefined;
          }
        });
      });
    });
    return _locData;
  }, [locations]);

  const [selectedFilter, setSelectedFilter] = useState<string>(
    filterOptions[0].value
  );

  const filteredLocations = useMemo(() => {
    return _locations.filter((item) =>
      match(selectedFilter)
        .with('all', () => true)
        .with('lagging', () => item.isLauncherDue)
        .with('onTrack', () => !item.isLauncherDue)
        .otherwise(() => false)
    );
  }, [selectedFilter, _locations]);

  return (
    // @ts-ignore
    <Flex flexDir='column' gap={4}>
      <Flex align='center' justify='space-between'>
        <GenericColorHeader
          title='Locations in-progress'
          color={HeaderColors.Yellow}
        />
        <OpeningTask
          showTitle={false}
          filterOptions={filterOptions}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      </Flex>

      <ProgressLists
        locations={filteredLocations}
        isLoading={isLoading}
        isEmpty={!locations?.length}
      />
    </Flex>
  );
};

export default ProgressListContainer;

import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';

import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import { PasswordChangeProvider } from './context';
import ContentComponent from './ContentComponent';

interface IProps {
  userId: string;
  name: string;
}

type UsePasswordChange = (props: IProps) => void;

export const usePasswordChange = (): UsePasswordChange => {
  const { t } = useTranslation(['profile']);
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const closeModal = () => {
    confirmRef.current?.destroy();
  };

  return useCallback(
    ({ userId, name }) => {
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <PasswordChangeProvider userId={userId} closeModal={closeModal}>
                {children}
              </PasswordChangeProvider>
            </ApolloProvider>
          );
        },
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader
                // @ts-ignore
                title={t('profile:updatePassword', {
                  name: name,
                })}
                color='#CABDFF'
              />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        isCentered: true,
        content: <ContentComponent />,
        contentProps: {
          py: '16px',
          px: '20px',
          minW: '680px',
        },
        footer: null,
      });
    },
    [confirm]
  );
};

import React, { FC } from 'react';
import { Box, Center, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import EmptyIcon from '../../../assets/images/Illustrations.svg';

interface IProps {
  loading?: boolean;
  dataLength?: number;
}

const EmptyMemberList: FC<IProps> = ({ loading, dataLength = 0 }) => {
  const { t } = useTranslation(['location', 'header']);

  if (loading) {
    return null;
  }

  if (dataLength > 0) {
    return null;
  }

  return (
    <Center flexDir='column' px='20px' gap={2} h='full'>
      <Image src={EmptyIcon} maxW='160px' style={{ aspectRatio: '1/1' }} />
      <Box fontSize='18px' fontWeight='700' color='#303030'>
        {t('location:noAssigneeFound')}
      </Box>
      <Box fontSize='15px' fontWeight='500' color='#9E9E9E' textAlign='center'>
        {t('header:not_found_desc')}
      </Box>
    </Center>
  );
};

export default EmptyMemberList;

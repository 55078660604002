import React, { FC, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { capitalizeFirstLetter } from 'sop-commons/src/utils/stringUtils';

import { toArray } from '../../../utils/utils';
import { useUserDataSelector } from '../../../hooks';
import { MessageButton } from '../../../atoms';

import ContentHeader from './ContentHeader';
import ContentItem from './ContentItem';
import { USER_DETAIL } from './user-detail.graphql';

import locationIcon from '../../../assets/images/location.svg';
import shieldIcon from '../../../assets/images/shieldIcon.svg';
import mailIcon from '../../../assets/images/UI icon_mail_light.svg';

import SingleUserChatModal from '../../../ui-components/SingleUserChatModal';
import { RecentSearchModal } from '../../../modal';

const UserChatModal = styled(SingleUserChatModal)`
  position: absolute;
  min-height: calc(100vh - 4rem);
  width: unset;
  left: 0;
  border-radius: 16px;
`;

interface IProps {
  data: RecentSearchModal;
}

const UserDetails: FC<IProps> = ({ data }) => {
  const authUserId = useUserDataSelector((state) => state?.eid);
  const [showChat, setShowChat] = useState(false);

  const { data: response } = useQuery(USER_DETAIL, {
    variables: {
      eid: data.recordId || data.eid,
    },
  });

  const userData = response?.userById;

  const userEid = data.recordId || data.eid;

  const locationName = useMemo(() => {
    if (userData?.location) {
      return userData.location;
    }

    return toArray(userData?.locations)
      .map((it) => it.name)
      .join('\n');
  }, [userData]);

  const calcAuthRole = useMemo(() => {
    return data.meta.authRole?.split(/(?=[A-Z])/).join(' ');
  }, [data.meta.authRole]);

  return (
    <>
      <ContentHeader
        icon={data.meta.profilePic}
        title={data?.value || data?.meta?.name || 'N/A'}
        subtitle={capitalizeFirstLetter(data.meta.role || 'N/A')}
      />
      <div>
        <ContentItem
          type={data.type}
          leftIcon={mailIcon}
          title={data.meta.email || data.meta.username}
        />
        <ContentItem
          type={data.type}
          leftIcon={shieldIcon}
          title={calcAuthRole || '- -'}
          contentProps={{
            textTransform: 'capitalize',
          }}
        />
        <ContentItem
          type={data.type}
          leftIcon={locationIcon}
          title={locationName || '- -'}
          contentProps={{
            whiteSpace: 'pre-line',
          }}
        />
      </div>

      {authUserId !== userEid ? (
        <MessageButton
          w='full'
          size='lg'
          colorScheme='blue'
          margin='12px 0 8px'
          onClick={() => setShowChat(true)}
        />
      ) : null}

      {showChat && (
        <UserChatModal
          onClose={() => setShowChat(false)}
          selectedUser={data.recordId || data.eid}
        />
      )}
    </>
  );
};

export default UserDetails;

import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from 'atoms';

import { IFormInput } from '../AddLocation/add-location.types';
import { useLocationDelete } from '../LocationDelete';

interface IProps {
  onMoveSuccess?: () => void;
}

const DeleteLocation: FC<IProps> = ({ onMoveSuccess }) => {
  const history = useHistory();
  const { t } = useTranslation(['common']);

  const { getValues } = useFormContext<IFormInput>();
  const isHeadQuarter = useWatch<IFormInput, 'isRoot'>({
    name: 'isRoot',
  });

  const locationDelete = useLocationDelete({
    onMoveSuccess: onMoveSuccess,
    onDeleteSuccess: history.goBack,
  });

  if (isHeadQuarter) {
    return null;
  }

  return (
    <PrimaryButton
      size='lg'
      width='auto'
      colorScheme='red'
      style={{
        minWidth: '170px',
      }}
      title={t('common:delete')}
      onClick={() => locationDelete(getValues('rawLocation'))}
    />
  );
};

export default DeleteLocation;

import { CardFormInput, QuizOptionData } from './card-editor.types';

import {
  AllLayout,
  MixedLayout,
  QuizLayout,
  TextLayout,
} from 'sop-commons/src/utils/cardLayouts';
import {
  imageJsonFormat,
  mixedCardJsonFormat,
  quizChoiceJsonFormat,
  textJsonFormat,
} from '../../utils';
import { CardType } from '../../types';
import { ACTION_THUMBNAIL, QUIZ_THUMBNAIL } from '../../configs/default-image';

export const tiptapContent = (tipTapData = {}) => {
  return {
    type: 'tiptap',
    attrs: {},
    tiptap: tipTapData,
  };
};

export const textContent = ({
  title,
  bodyJson,
  layout,
}: {
  title?: string;
  bodyJson?: any;
  layout?: AllLayout;
}) => {
  if (layout !== TextLayout.RICH_TEXT) {
    return textJsonFormat(2, 'center', title);
  }
  const data = tiptapContent(bodyJson);
  // @ts-ignore
  data.text = title;
  return data;
};

export const imageContent = ({
  layout,
  images,
}: {
  layout?: AllLayout;
  images?: string[];
}) => {
  const data = imageJsonFormat(layout as AllLayout);
  data.image = images as never[];
  return data;
};

export const quizContent = ({
  layout,
  options = [],
  answers,
}: {
  layout?: AllLayout;
  options?: QuizOptionData[];
  answers?: string | string[];
}) => {
  const _answers = Array.isArray(answers) ? answers : [answers];
  const data = quizChoiceJsonFormat(
    layout,
    options?.map((value) => ({
      id: value.choiceId || value.id,
      text: value.text,
      inputId: value.inputId,
    }))
  );
  if (layout === QuizLayout.SINGLE_CHOICE) {
    data.correctAnswer = _answers?.[0] || '';
  } else {
    // @ts-ignore
    data.correctAnswer = _answers || [];
  }
  return data;
};

export const milestoneContent = ({
  milestoneUrl,
  milestoneAddedBy,
  milestoneDateAdded,
  milestoneFileSize,
}: {
  milestoneUrl?: string;
  milestoneAddedBy?: string;
  milestoneDateAdded?: string;
  milestoneFileSize?: number;
}) => {
  return [
    {
      type: 'milestone',
      url: milestoneUrl,
      addedBy: milestoneAddedBy,
      dateAdded: milestoneDateAdded,
      fileSize: milestoneFileSize,
    },
  ];
};

export const videoContent = ({
  caption,
  videoUrl,
  videoAddedBy,
  videoDateAdded,
  videoFileSize,
  videoAttrs = {},
  captionAttrs = {},
}: {
  caption?: string;
  videoUrl?: string;
  videoAddedBy?: string;
  videoDateAdded?: string;
  videoFileSize?: number;
  videoAttrs?: Record<string, any>;
  captionAttrs?: Record<string, any>;
}) => {
  return [
    {
      type: 'video',
      attrs: videoAttrs,
      video: videoUrl,
    },
    {
      type: 'caption',
      attrs: captionAttrs,
      caption: caption,
    },
    {
      type: 'videoMeta',
      addedBy: videoAddedBy,
      dateAdded: videoDateAdded,
      fileSize: videoFileSize,
    },
  ];
};

export const formContent = ({
  formUrl,
  title,
}: {
  formUrl?: string;
  title?: string;
}) => {
  return [
    {
      type: 'form',
      url: formUrl,
      text: title,
    },
  ];
};

export const mixedContent = ({
  layout,
  images,
  bodyJson,
}: {
  layout?: AllLayout;
  images?: string[];
  bodyJson?: any;
}) => {
  const data =
    mixedCardJsonFormat(layout as AllLayout) ||
    mixedCardJsonFormat(MixedLayout.MIXED_TOP_IMAGE_TEXT);

  if (layout === MixedLayout.MIXED_BOTTOM_IMAGE_TEXT) {
    // @ts-ignore
    data[0].tiptap = bodyJson;
    // @ts-ignore
    data[1].image = images || [];
  } else {
    // @ts-ignore
    data[0].image = images || [];
    // @ts-ignore
    data[1].tiptap = bodyJson;
  }
  return data;
};

// interface CardFormInput {
//   cardType: CardLayoutType;
//   entityId?: string;
//   backgroundColor?: string;
//   layout?: AllLayout;
//   // content: Content[];
//   raw_content?: string;
//   subTitle?: string;
//   thumbnail?: string;
//   title?: string;
//   handbookView?: boolean;
//   category?: string;
//
//   images?: string[];
//
//   status?: 'active' | 'inactive';
//
//   bodyJson?: JSON; // rich-text and mixed card
//
//   formLink?: string; // form-card
//
//   videoUrl?: string; // video-card
//   caption?: string; // video-card
//
//   choices?: QuizOptionData[]; // quiz-card
//   correctAnswer?: string[]; // quiz-card
// }

// export default function jsonDataFormatter({
//   cardType,
//   entityId,
//   backgroundColor,
//   layout,
//   raw_content,
//   subTitle,
//   thumbnail,
//   title,
//   handbookView,
//   category,
//   images,
//   status,
//   bodyJson,
//   formLink,
//   videoUrl,
//   caption,
//   choices,
//   correctAnswer,
// }: CardFormInput): any {
//   const cardData = getFormat(
//     cardType,
//     layout as string,
//     entityId as string,
//     backgroundColor as string,
//     title,
//     status
//   );
//
//   if (cardType === 'text') {
//     if (layout === TextLayout.SIMPLE_TEXT) {
//       cardData.content = [textJsonFormat(2, 'center'), title];
//     } else if (layout === 'rich-text') {
//       // const tiptap = tiptapJsonFormat();
//       // // @ts-ignore
//       // tiptap.text = title;
//       // cardData.content = [tiptap];
//       // cardData.raw_content = raw_content;
//     }
//     cardData.thumbnail =
//       'https://sop-uploads-staging.s3.amazonaws.com/extras/1002.png';
//   }
//
//   return cardData;
// }

export function contentDataConvert(cardType: CardType, value: CardFormInput) {
  const prevCardData: {
    content?: any[];
    thumbnail?: string;
    raw_content?: string;
  } = {
    content: [],
    thumbnail: value.thumbnail,
    raw_content: value.raw_content,
  };
  let images = value?.images as string[];
  switch (cardType) {
    case CardType.Text:
      prevCardData.content = [
        textContent({
          title: value?.title,
          bodyJson: value?.bodyJson,
          layout: value?.layout,
        }),
      ];
      prevCardData.thumbnail =
        'https://sop-uploads-staging.s3.amazonaws.com/extras/1002.png';
      if (value.layout === TextLayout.SIMPLE_TEXT) {
        prevCardData.raw_content = value.title;
      }
      break;
    case CardType.Image:
      prevCardData.content = [
        imageContent({
          images: value?.images as string[],
          layout: value?.layout,
        }),
      ];
      prevCardData.raw_content = value.title;
      if (!images.includes(prevCardData.thumbnail as string)) {
        prevCardData.thumbnail = images[0];
      }

      break;
    case CardType.Action:
      prevCardData.content = [
        textContent({
          title: value?.title,
          layout: value?.layout,
        }),
      ];
      prevCardData.thumbnail = ACTION_THUMBNAIL;
      prevCardData.raw_content = value.title;
      break;
    case CardType.Quiz:
      prevCardData.content = [
        quizContent({
          layout: value?.layout,
          // @ts-ignore
          options: value?.choices,
          // @ts-ignore
          answers: value?.correctAnswer,
        }),
      ];
      prevCardData.thumbnail = QUIZ_THUMBNAIL;
      prevCardData.raw_content = value.title;
      break;
    case CardType.Video:
      prevCardData.content = videoContent({
        videoUrl: value?.videoUrl,
        caption: value?.caption,
        videoAddedBy: value?.videoAddedBy,
        videoDateAdded: value?.videoDateAdded,
        videoFileSize: value?.videoFileSize,
      });
      prevCardData.raw_content = value.title;
      break;
    case CardType.Milestone:
      prevCardData.content = milestoneContent({
        milestoneUrl: value?.milestoneUrl,
        milestoneAddedBy: value?.milestoneAddedBy,
        milestoneDateAdded: value?.milestoneDateAdded,
        milestoneFileSize: value?.milestoneFileSize,
      });
      break;
    case CardType.Form:
      prevCardData.content = formContent({
        title: value?.title,
        formUrl: value?.formLink,
      });
      prevCardData.thumbnail =
        'https://sop-uploads-staging.s3.amazonaws.com/2l2kzw3m8pq70edbml58z9pr/1655217948967_197_Form Card Preview.png';
      prevCardData.raw_content = value.title;
      break;
    case CardType.Mixed:
      prevCardData.content = mixedContent({
        layout: value?.layout,
        images: value?.images as string[],
        bodyJson: value?.bodyJson,
      });
      prevCardData.raw_content = value.title;
      if (!images.includes(prevCardData.thumbnail as string)) {
        prevCardData.thumbnail = images[0];
      }
      break;
    case CardType.CustomForm:
      prevCardData.thumbnail = value.form?.thumbnail;
      prevCardData.raw_content = value.form?.title;
      break;
  }

  return prevCardData;
}

import DashboardSopList from '../../ui-components/DashboardSopList';
import { makeVar } from '@apollo/client';
import MainContainer from 'ui-components/MainContainer';

export const isMyRole = makeVar(false);

export const Dashboard = () => {
  return (
    <MainContainer>
      {/* <DashboardSopList /> */}
    </MainContainer>
  );
};

export default Dashboard;

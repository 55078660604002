import React, { FC } from 'react';

import { useSearchParam } from '../../../hooks';

import EditorProvider from './editor-form/EditorProvider';
import LauncherTask from './post-setup/LauncherTask';
import LauncherEditor from './editor-form/LauncherEditor';

type ViewMode = 'editor' | 'board';

const LauncherTaskContent: FC = () => {
  const viewMode = useSearchParam<{ viewMode: ViewMode }>().viewMode;

  if (viewMode === 'editor') {
    return <LauncherEditor />;
  }

  return <LauncherTask />;
};

const LauncherTaskSetup: FC = () => {
  return (
    <EditorProvider>
      <LauncherTaskContent />
    </EditorProvider>
  );
};

export default LauncherTaskSetup;

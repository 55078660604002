import React, { FC, isValidElement, ReactNode } from 'react';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { checkPermission } from './checkPermission';
import { AuthUser, Permission } from './authorization.types';

interface IProps extends Permission {
  renderNoAccess?: () => ReactNode;
  disabled?: boolean;
  children: ReactNode;
}

const Authorization: FC<IProps> = (props) => {
  const {
    permittedRoles,
    restrictedRoles,
    children,
    disabled,
    renderNoAccess,
    canAccess,
    permittedFor,
    restrictedFor,
  } = props;

  const authUser = useReactiveVar(userObj) as AuthUser;

  if (!isValidElement(children)) {
    return null;
  }

  if (!permittedRoles && !restrictedRoles && !permittedFor && !restrictedFor) {
    if (canAccess) {
      return canAccess(authUser)
        ? children
        : renderNoAccess
        ? (renderNoAccess() as never)
        : null;
    }
    return children;
  }

  const isPermitted = checkPermission(authUser, {
    permittedRoles: permittedRoles || [],
    restrictedRoles: restrictedRoles || [],
    permittedFor: permittedFor,
    restrictedFor: restrictedFor,
  });

  if (isPermitted) return children;

  if (canAccess) {
    return canAccess(authUser)
      ? children
      : renderNoAccess
      ? (renderNoAccess() as never)
      : null;
  }

  if (disabled) {
    return <div>{children}</div>;
  }

  return renderNoAccess ? (renderNoAccess() as never) : null;
};

export default Authorization;

import React, { forwardRef } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface IProps {
  isDisabled?: boolean;
  isFirstTask?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const TaskDependSelect = forwardRef<never, IProps>(
  ({ isDisabled, isFirstTask, value, onChange }) => {
    if (isFirstTask) {
      return (
        <Flex
          gap={3}
          opacity='0.4'
          border='1px solid #808080'
          color='#6F767E'
          height='48px'
          align='center'
          px={4}
          borderRadius='12px'
          cursor='not-allowed'
          userSelect='none'
        >
          <Flex flex={1} fontSize='14px'>
            Disabled as this is the first task
          </Flex>
          <ChevronDownIcon boxSize={7} />
        </Flex>
      );
    }

    return (
      <Flex gap={3}>
        <Button
          size='lg'
          fontSize='15px'
          variant='outline'
          disabled={isDisabled}
          colorScheme={value === 'PREVIOUS_TASK' ? 'blue' : 'gray'}
          bg={value === 'PREVIOUS_TASK' ? 'blue.50' : undefined}
          value='PREVIOUS_TASK'
          onClick={() => onChange?.('PREVIOUS_TASK')}
        >
          Previous task
        </Button>
        <Button
          size='lg'
          fontSize='15px'
          variant='outline'
          disabled={isDisabled}
          colorScheme={value === 'INDEPENDENT' ? 'blue' : 'gray'}
          bg={value === 'INDEPENDENT' ? 'blue.50' : undefined}
          value='INDEPENDENT'
          onClick={() => onChange?.('INDEPENDENT')}
        >
          Independently
        </Button>
      </Flex>
    );
  }
);

TaskDependSelect.displayName = 'TaskDependSelect';

export default TaskDependSelect;

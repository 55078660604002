import { FC } from "react";
import Text from "../elements/Text";

interface IProps {
  backgroundColor?: string;
  content: Array<any>;
  shouldSetFixedHeight?: boolean;
}

const SimpleText: FC<IProps> = ({ backgroundColor, content = [], shouldSetFixedHeight }) => {
  const textContent = content[0];
  return (
    <div
      style={{
        // backgroundColor: backgroundColor,
        minHeight: shouldSetFixedHeight ? '80vh' : 'none',
        height: "inherit",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 47px",
      }}
    >
      <Text textContent={textContent} />
    </div>
  );
};

export default SimpleText;

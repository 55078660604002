import React, { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex } from '@chakra-ui/react';
import { TASK_DETAILS_QUERY, TaskDetailResponse } from './task-details.graphql';
import TaskDetailsList from './TaskDetailsList';
import TaskScheduleDetails from './TaskScheduleDetails';
import Loader from '../../../../Loader';

interface IProps {
  taskId?: string;
  userId: string;
  onClick?: (type: string, eid: string) => void;
}

const TaskDetailsContainer: FC<IProps> = ({ taskId, userId, onClick }) => {
  const { data, loading } = useQuery<TaskDetailResponse>(TASK_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
    skip: !taskId && !userId,
    variables: {
      eid: taskId,
    },
  });

  const title = data?.TaskById?.title;
  const description = data?.TaskById?.description;

  const userProgress = useMemo(() => {
    return data?.TaskById?.lastSession?.progress?.find(
      (val) => val.userId === userId
    );
  }, [data?.TaskById, userId]);

  if (loading) {
    return (
      <Flex flex={1} flexDir='column' overflow='hidden'>
        <Loader size='xl' margin='auto' />
      </Flex>
    );
  }

  return (
    <Flex flex={1} flexDir='column' overflow='hidden'>
      <TaskScheduleDetails data={data?.TaskById} />

      <Flex flexDir='column' gap={2} mt='12px'>
        <Box fontSize='18px' fontWeight='600' color='#272B30'>
          {title}
        </Box>
        {description && <Box color='#6F767E'>{description}</Box>}
      </Flex>

      <TaskDetailsList
        taskItems={data?.TaskById?.taskItems}
        userProgress={userProgress}
        onClick={(data) => onClick?.(data.type, data.eid)}
      />
    </Flex>
  );
};

export default TaskDetailsContainer;

import React, { Component } from 'react';
import { Box, Flex, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import PropTypes from 'prop-types';

class ConfigComponent extends Component {
  render() {
    return (
      <Flex flexDir='column' gap={4}>
        <Flex flex={1} flexDir='column' gap={1}>
          <Box fontSize='16px' fontWeight='500' color='#1A1D1F'>
            {this.props.title}
          </Box>
          <Box color='#6F767E'>{this.props.description}</Box>
        </Flex>

        <RadioGroup
          isDisabled={this.props.isDisabled}
          onChange={this.props.onChange}
          value={this.props.value}
        >
          <Stack
            spacing={3}
            sx={{
              '.chakra-radio__label': {
                color: '#33383F',
                fontSize: 14,
                fontWeight: 400,
                _checked: {
                  color: '#2A85FF',
                  fontWeight: 500,
                },
              },
              '.chakra-radio__control': {
                _checked: {
                  borderColor: 'blue.500',
                  background: 'white',
                  color: 'blue.500',
                  _hover: {
                    background: 'white',
                  },
                  _disabled: {
                    borderColor: 'blue.500',
                    background: 'white',
                    color: 'blue.500',
                  },
                },
                _disabled: {
                  background: 'white',
                },
              },
            }}
          >
            <Radio value='on'>Send all notifications</Radio>
            <Radio value='off'>Off</Radio>
          </Stack>
        </RadioGroup>
      </Flex>
    );
  }
}

ConfigComponent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ConfigComponent;

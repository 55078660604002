import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ActivityUser } from './activity-feed.types';
import Image from '../Image/Image';

const colorSetHandler = (index: number) => {
  let colorObj = [
    '#8830b0',
    '#30048c',
    '#60b569',
    '#02ade5',
    '#c7c37b',
    '#cc86a9',
    '#8b78e6',
    '#2578ae',
  ];
  return colorObj[index % 8];
};

const NoImage = styled.div<{ color: string }>`
  height: 56px;
  min-width: 56px;
  background-color: ${({ color }) => color};
  border-radius: 8.1px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IActivityAvatar {
  index: number;
  user: ActivityUser;
}

const ActivityAvatar: FC<IActivityAvatar> = ({ index, user }) => {
  if (user?.profilePic) {
    return (
      <Image
        width={56}
        height={56}
        className='user-feed-img'
        src={user?.profilePic}
      />
    );
  }
  return (
    <NoImage color={colorSetHandler(index)}>
      {user?.name && (
        <span
          style={{
            fontSize: 'xx-large',
            fontWeight: '600',
            color: 'white',
          }}
        >
          {user?.name?.split('')[0].toUpperCase()}
        </span>
      )}
    </NoImage>
  );
};

export default ActivityAvatar;

import styled from '@emotion/styled';

export const PublishChangeDiffCheckModalWrapper = styled.div`
    .public-review-warning {
        background-color: rgba(202, 189, 255, 0.3);
        border-radius: 10px;
        padding: 1.5rem;
        font-size: 14px;
        margin-bottom: 2rem;
    }
`;

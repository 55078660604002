import { Col, Row, Layout, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { RightOutlined } from '@ant-design/icons';
// import components
import { Header } from '../../ui-components/Header';
import { DashboardMenu } from '../../ui-components/DashboardMenu';

import { useRouter } from '../../utils';
import SopContent from '../../ui-components/SopContent';
import { useEffect } from 'react';
import MainContainer from 'ui-components/MainContainer';

export const UPDATE_SOP_VIEWS_MUTATION = gql`
  mutation SetSopView($sopId: String!) {
    setSopView(sopId: $sopId) {
      chapter
      viewCount
      content
      raw_content
      entity
      category
    }
  }
`;

const SopDetail = () => {
  let history = useHistory();
  const router = useRouter();
  const { query }: any = router;
  const types: any = Object.keys(query);

  const sopId = query?.id;

  const [updateSopViews, { loading }] = useMutation(UPDATE_SOP_VIEWS_MUTATION);

  // useEffect(() => {
  //   updateSopViews({
  //     variables: {
  //       sopId: sopId,
  //     },
  //   });
  // }, []);

  return (
    <MainContainer disableDefaultMenuKeys>
      <SopContent sopId={sopId} />
    </MainContainer>
  );
};

export default SopDetail;

import {
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { useFolderSubFolder, useUserData } from 'hooks';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import DashboardDataContext, {
  ISelectedFolderData,
} from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { FormInput, PrimaryButton } from 'atoms';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  AddSubFolderResponse,
  AddSubFolderVariable,
  CREATE_SUBFOLDER,
  UpdateSubFolderResponse,
  UpdateSubFolderVariable,
  UPDATE_SUBFOLDER,
} from '../create-subfolder.graphql';
import FileVisibility from 'pages/Chapters/CreateChapterModal/FileVisibility';
import { IVisibility } from 'pages/Chapters/CreateChapterModal/UploadFile/UploadFile';
import { loginUserBranchRootObj, usersEntityObj } from 'sub-components/Header';
import {
  locationUserRoleHelper,
  rolesListSetHandler,
  selectedDataSetHandler,
} from 'pages/Chapters/EditChapterModal/helper';
import {
  compareVisibilityHandler,
  ICompareVisibilityType,
} from './helper/visibilityHelper';
import {
  FolderVisibilityArgs,
  FolderVisibilityQuery,
  FolderVisibilityResponse,
} from 'sub-components/Sidebar/category/add-category.graphql';
import VisibilityStatusInfo from 'ui-components/VisibilityStatusInfo';
import { GET_USER } from 'pages/Login/login.graphql';
import FolderIconUpload from 'sub-components/FolderIconUpload';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { GET_FOLDERS_LIST } from 'pages/Chapters/chapters.graphql';
import { getFoldersList } from 'shared/graphql/SharedGraphql';
import useCombinedStore from 'zustandStore/store';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface IProps {
  folderDetails: ISelectedFolderData;
  selectedFolder?: ISelectedFolderData;
  type?: 'add' | 'edit';
  onSubFolderCreated?: () => void;
}

type FormData = {
  subFolder: string;
};

const AddEditSubFolder: FC<IProps> = ({
  folderDetails,
  selectedFolder,
  type = 'add',
  onSubFolderCreated,
}) => {
  console.log('Add Edit Sub Folder : ', folderDetails);
  let userData = useUserData();
  const entityId = userData?.entityId;
  const userBranchRoot = useReactiveVar(loginUserBranchRootObj);
  const {
    hierarchyData,
    selectedData,
    visibilitySelection,
    updateSelectedData,
    updateVisibilitySelection,
  } = useCombinedStore();
  const { getAllFolderSubFolderList } = useFolderSubFolder();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const { onClose } = useModalContext();
  const [loading, setLoading] = useState(false);
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>();
  const [compareVisiblityType, setCompareVisibilityType] =
    useState<ICompareVisibilityType>(undefined);
  const nameWatch = useWatch<FormData, 'subFolder'>({
    control: control,
    name: 'subFolder',
  });
  const [membersList, setMembersList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [rolesList, setRolesList] = useState<any[]>([]);
  const [selectedFolderColor, setSelectedFolderColor] = useState('');
  const [selectedIconUrl, setSelectedIconUrl] = useState('');

  useEffect(() => {
    if (type === 'edit' && selectedFolder?.eid) {
      setValue('subFolder', selectedFolder?.name);
    }
  }, [selectedFolder?.eid, type]);

  useEffect(() => {
    updateVisibilitySelection({
      locations: folderDetails?.visibleTo?.locations || [],
      roles: folderDetails?.visibleTo?.roles || [],
      users: folderDetails?.visibleTo?.users || [],
      condition: folderDetails?.visibleTo?.condition,
      visibility: folderDetails?.visibility,
    });
  }, [folderDetails?.eid]);

  useEffect(() => {
    if (userBranchRoot) {
      let _data = JSON.parse(JSON.stringify({ branch: userBranchRoot }));
      locationUserRoleHelper(
        _data,
        userData,
        setMembersList,
        setLocationsList,
        selectedData,
        updateSelectedData
      );
    }
  }, [userBranchRoot]);

  useEffect(() => {
    rolesListSetHandler(userData, setRolesList);
  }, [userData]);

  const onSuccess = () => {
    setLoading(false);
    onSubFolderCreated?.();
    onClose();
  };

  const { execute } = getFoldersList(onSuccess);

  useEffect(() => {
    selectedDataSetHandler(
      membersList,
      locationsList,
      visibilitySelection!,
      rolesList,
      updateSelectedData
    );
  }, [locationsList, membersList, visibilitySelection]);

  const [createSubFolder] = useMutation<
    AddSubFolderResponse,
    AddSubFolderVariable
  >(CREATE_SUBFOLDER, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Sub Folder created successfully',
      });
      execute();
    },
    onError: () => {
      setLoading(false);
      toast({
        status: 'error',
        title: 'Sub Folder could not be created',
      });
      onClose();
    },
  });

  const [updateSubFolder, { loading: updatingSubFolder }] = useMutation<
    UpdateSubFolderResponse,
    UpdateSubFolderVariable
  >(UPDATE_SUBFOLDER, {
    onCompleted: () => {
      if (selectedFolder?.eid) {
        updateSubFolderVisibility({
          variables: {
            input: {
              categoryId: selectedFolder?.eid,
              visibility: visibilitySelection?.visibility || 'public',
              roles: visibilitySelection?.roles || [],
              users: visibilitySelection?.users || [],
              locations: visibilitySelection?.locations || [],
              copyVisibility: false,
              ...(visibilitySelection?.condition
                ? { condition: visibilitySelection.condition }
                : {}),
            },
          },
        });
      }
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Sub Folder could not be created',
      });
    },
  });

  const [
    updateSubFolderVisibility,
    { data: updateFolderVisibilityData, loading: updatingFolderVisibility },
  ] = useMutation<FolderVisibilityResponse, FolderVisibilityArgs>(
    FolderVisibilityQuery,
    {
      onCompleted: (data) => {
        toast({
          status: 'success',
          title: 'Sub Folder updated successfully',
        });
        setTimeout(() => {
          onSubFolderCreated?.();
          onClose();
        }, 1000);
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Sub Folder could not be updated',
        });
        onSubFolderCreated?.();
        onClose();
      },
    }
  );

  const onSubmit = (data: FormData) => {
    if (!folderDetails?.eid) return;
    if (type === 'add') {
      deployEvent(AmplitudeEventNames.ADD_SUB_FOLDER_FROM_MODAL);
      setLoading(true);
      createSubFolder({
        variables: {
          category: data.subFolder,
          eid: entityId,
          parent: folderDetails?.eid,
          visibility: visibilitySelection?.visibility,
          roles: visibilitySelection?.roles,
          users: visibilitySelection?.users,
          locations: visibilitySelection?.locations,
          ...(visibilitySelection?.condition
            ? { condition: visibilitySelection.condition }
            : {}),
          color: selectedFolderColor || '#f6f6f6',
          icon: selectedIconUrl,
          // ...(selectedFolderColor ? { color: selectedFolderColor } : {}),
          // ...(selectedIconUrl ? { icon: selectedIconUrl } : {}),
        },
      });
    } else {
      if (!selectedFolder?.eid) return;
      deployEvent(AmplitudeEventNames.RENAME_SUB_FOLDER_FROM_MODAL);
      updateSubFolder({
        variables: {
          categoryId: selectedFolder?.eid,
          parent: folderDetails?.eid,
          category: data.subFolder,
          icon: selectedIconUrl,
        },
      });
    }
  };

  // const clickHandler = () => {
  //   console.log('clickHandler');
  //   compareVisibilityHandler({}, {});
  // };

  const usersEntityData = useReactiveVar(usersEntityObj);

  // useEffect(() => {
  //   const _folder = {
  //     eid: folderDetails?.eid!,
  //     type: folderDetails?.visibility || 'public',
  //     condition: folderDetails?.visibleTo?.condition,
  //     jobs: folderDetails?.visibleTo?.roles,
  //     locations: folderDetails?.visibleTo?.locations,
  //     members: folderDetails?.visibleTo?.users,
  //   };
  //   const _subFolders = {
  //     eid: '',
  //     type: visibilitySelection?.visibility || 'public',
  //     condition: visibilitySelection?.condition || undefined,
  //     jobs: visibilitySelection?.roles,
  //     locations: visibilitySelection?.locations,
  //     members: visibilitySelection?.users,
  //   };
  //   console.log({ folderDetails, visibilitySelection, _folder, _subFolders })
  //   let visibilityConditionType = compareVisibilityHandler(
  //     _folder,
  //     _subFolders,
  //     usersEntityData
  //   );
  //   setCompareVisibilityType(visibilityConditionType);
  // }, [visibilitySelection, selectedData]);

  return (
    <>
      {/* <PrimaryButton title='Click' onClick={clickHandler} /> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDir='column' gap={4} w='full'>
          <Flex flexDir='column'>
            <TitleHeader title='Subfolder name' />
            <Flex align='center' gap='5px' mt={2}>
              <Controller
                name='subFolder'
                control={control}
                defaultValue=''
                rules={{
                  required: 'Sub Folder Name is required',
                  validate: (value) => {
                    if (
                      getAllFolderSubFolderList()?.some(
                        (list) => list?.name === value?.trim()
                      )
                    ) {
                      return `${value?.trim()} already exists`;
                    }
                  },
                }}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <Flex align='center' gap={2}>
                      <FolderIconUpload
                        selectedFolderColor={selectedFolderColor}
                        setSelectedFolderColor={setSelectedFolderColor}
                        selectedIconUrl={selectedIconUrl}
                        setSelectedIconUrl={setSelectedIconUrl}
                      />
                      <FormInput
                        {...field}
                        placeholder='Subfolder name'
                        size='lg'
                      />
                    </Flex>
                    <FormErrorMessage>
                      <span style={{ marginLeft: '60px' }}>
                        {fieldState.error?.message}
                      </span>
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            </Flex>
          </Flex>
          <Flex>
            <Text>in&nbsp;</Text>
            <Text fontWeight={700}>
              {hierarchyData?.parentFolder?.details?.name}
            </Text>
          </Flex>
          <FileVisibility
            isEditMode
            parentDataVisibility={{
              locations: folderDetails?.visibleTo?.locations || [],
              roles: folderDetails?.visibleTo?.roles || [],
              users: folderDetails?.visibleTo?.users || [],
              condition: folderDetails?.visibleTo?.condition,
              visibility: folderDetails?.visibility,
            }}
            parentFolderName={folderDetails?.name}
            viewDirection='top-bottom'
            type='subFolder'
            customElement={
              nameWatch ? (
                <Text fontWeight={700}>{nameWatch} visibility is set to -</Text>
              ) : (
                'This subfolder is -'
              )
            }
          />
          <VisibilityStatusInfo
            type='subFolder'
            parentDetails={{
              locations: folderDetails?.visibleTo?.locations || [],
              roles: folderDetails?.visibleTo?.roles || [],
              users: folderDetails?.visibleTo?.users || [],
              condition: folderDetails?.visibleTo?.condition,
              visibility: folderDetails?.visibility,
            }}
            childDetails={visibilitySelection}
            folderName={nameWatch}
            parentFolderName={folderDetails?.name}
          />
          <PrimaryButton
            title={type === 'add' ? 'Add subfolder' : 'Rename subfolder'}
            type='submit'
            variant='solid'
            colorScheme='blue'
            isLoading={loading || updatingSubFolder || updatingFolderVisibility}
            disabled={
              loading ||
              updatingSubFolder ||
              updatingFolderVisibility ||
              !nameWatch
            }
          />
        </Flex>
      </form>
    </>
  );
};

export default AddEditSubFolder;

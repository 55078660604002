import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';

import { useConfirm, UseConfirm } from '../../Confirm';

import TrainingContent from './TrainingContent';
import { InputVariable } from '../../../pages/Training/TrainingPlay/training-play.graphql';

interface IProps {
  trainingId: string;
  title: string;
  inputVariable?: InputVariable;
  onFinish?: () => Promise<void> | void;
  formAccessOverride?: boolean;
}

type UseTrainingViewProps = (props: IProps) => void;

export const useTrainingView = (): UseTrainingViewProps => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({ trainingId, title, inputVariable, onFinish, formAccessOverride }) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        content: (
          <TrainingContent
            trainingId={trainingId}
            title={title}
            inputVariable={inputVariable}
            onFinish={onFinish}
            formAccessOverride={formAccessOverride}
          />
        ),
        isCentered: true,
        contentProps: {
          py: '16px',
          px: '20px',
          maxW: '800px',
          marginTop: 'auto',
          marginBottom: 'auto',

          containerProps: {
            alignItems: 'start',
            paddingTop: '3.75rem',
            paddingBottom: '3.75rem',
          },
        },
        bodyProps: {
          px: 0,
        },
        footer: null,
      });
    },
    []
  );
};

import React, { ChangeEvent, FC, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import moment from 'moment/moment';

import ChapterPreview from './ChapterPreview';
import SearchInput from '../../../../../../atoms/SearchInput';
import { FooterComponent, FilterButton } from '../common';
import { TaskFormItem } from '../formChapter.graphql';
import { IFormInput } from '../../../task.types';
import ChapterListContainer from './ChapterListContainer';

interface IProps {
  values: string[];
  onAddClick?: (values: IFormInput['contents']) => void;
}

const ChapterContainer: FC<IProps> = ({ values, onAddClick }) => {
  const { t } = useTranslation('task');
  const [searchQuery, setSearchQuery] = useState<string>();

  const methods = useForm();

  const _onAddClick = () => {
    const taskStepSelection: Record<string, TaskFormItem> =
      methods.getValues('taskStepSelection');

    const values = Object.values(taskStepSelection).reduce((acc, value) => {
      if (value) {
        acc.push({
          type: 'sop',
          eid: value.eid,
          tempEid: value.eid,
          title: value.title,
          thumbnail: value.thumbnail,
          dateAdded: moment().format('D MMMM'),
          sopData: value,
        });
      }
      return acc;
    }, [] as IFormInput['contents']);

    return onAddClick?.(values);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Flex width='full' flex={1} gap='32px'>
      <FormProvider {...methods}>
        <Flex flexDir='column' overflow='hidden' flex={1} gap={4}>
          <Flex px={3} gap={4}>
            <SearchInput
              size='lg'
              hideShortcuts
              placeholder={t('searchInChapters')}
              onChange={onChange}
            />

            {/*<FilterButton />*/}
          </Flex>

          <ChapterListContainer searchQuery={searchQuery} values={values} />

          <FooterComponent onAddClick={_onAddClick} />
        </Flex>
        <ChapterPreview />
      </FormProvider>
    </Flex>
  );
};

export default ChapterContainer;

import { Center, Image, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import EmptyImg from '../../../assets/images/empty-state/track-members-home-empty-state.svg';

const TrackMembersEmpty: FC = () => {
  return (
    <Center flexDir='column' gap='10px' p={6}>
      <Image src={EmptyImg} w='183px' h='82px' />
      <Text
        fontWeight={400}
        w='200px'
        whiteSpace='pre-wrap'
        textAlign='center'
        fontSize='12px'
        color='#999999'
      >
        Member’s trainings will appear here
      </Text>
    </Center>
  );
};

export default TrackMembersEmpty;

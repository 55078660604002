import React, { FC } from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { getI18n } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const CustomHeader: FC<ReactDatePickerCustomHeaderProps> = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  return (
    <Flex gap={5} align='center' borderBottom='1px solid #E4E5E7' pb='16px'>
      <Box color='#0F2552' fontSize='15px' fontWeight='600'>
        {monthDate?.toLocaleString(getI18n().language, {
          month: 'long',
          year: 'numeric',
        })}
      </Box>
      <Spacer />
      <FontAwesomeIcon
        icon={faChevronLeft as IconProp}
        color='#848A95'
        onClick={prevMonthButtonDisabled ? undefined : decreaseMonth}
        style={{
          cursor: prevMonthButtonDisabled ? 'not-allowed' : 'pointer',
        }}
      />
      <FontAwesomeIcon
        icon={faChevronRight as IconProp}
        color='#848A95'
        onClick={nextMonthButtonDisabled ? undefined : increaseMonth}
        style={{
          cursor: nextMonthButtonDisabled ? 'not-allowed' : 'pointer',
        }}
      />
    </Flex>
  );
};

export default CustomHeader;

import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Input } from '@chakra-ui/react';
import { CardFormInput } from '../card-editor.types';

interface IProps {
  control: Control<CardFormInput>;
}

const ThumbnailInput: FC<IProps> = ({ control }) => {
  return (
    <Controller
      name={'thumbnail'}
      control={control}
      render={({ field }) => <Input id={'thumbnail'} {...field} hidden />}
    />
  );
};

export default ThumbnailInput;

import { UserData } from 'sop-commons/src/client/user-data.types';

export type MayArray<T> = T | T[];

export type AuthUserType = 'branch' | 'user';

export enum AuthRole {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  /**
   * @deprecated this is renamed to LOCATION_OWNER
   * */
  MANAGER = 'locationOwner',
  LOCATION_OWNER = 'locationOwner',
  WORKER = 'worker',
  LOCATION_USER = 'locationUser',
}

export type AuthRoles = AuthRole | AuthRole[];

export interface AuthUser extends Omit<UserData, 'authRole'> {
  authRole: AuthRoles;
}

export interface Permission {
  /**
   * available options
   *
   * 'user' | 'branch' | ['user'] | ['user', 'branch']
   */
  permittedFor?: AuthUserType | AuthUserType[];
  /**
   * available options
   *
   * 'user' | 'branch' | ['user'] | ['user', 'branch']
   */
  restrictedFor?: AuthUserType | AuthUserType[];

  permittedRoles?: AuthRoles;
  restrictedRoles?: AuthRoles;
  canAccess?: (authUser: AuthUser) => boolean;
}

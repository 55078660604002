import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';

import { useUserEntity } from '../../../../hooks';
import { LAUNCHER_OPENING_TASKS } from '../../../../appRoutes';
import DashboardContainer from '../../../../sub-components/DashboardContainer';

import ContentContainer from './ContentContainer';

const LauncherSetupContainer: FC = () => {
  const haveLauncher = useUserEntity((entity) => !!entity?.launcher?.eid);

  if (haveLauncher) {
    return <Redirect to={LAUNCHER_OPENING_TASKS} />;
  }

  return (
    <DashboardContainer>
      <Flex flexDir='column'>
        <Box fontSize='24px' fontWeight='700' color='#272B30'>
          Set up location launcher
        </Box>
        <Box fontSize='15px' fontWeight='400' color='#6F767E'>
          Select template which best suits your org or start building your
          launch task list
        </Box>
      </Flex>
      <Flex
        mt={6}
        flex={1}
        borderRadius='12px'
        bg='#FFFFFF'
        padding='40px 24px 40px 40px'
      >
        <ContentContainer />
      </Flex>
    </DashboardContainer>
  );
};

export default LauncherSetupContainer;

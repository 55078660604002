import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { toArray } from '../../../../../../../utils';
import { TrainingItem } from '../common';
import TrainingListItem from './TrainingListItem';

export interface TrainingResource {
  eid: string;
  trainingData?: TrainingItem;
}

interface IProps {
  values: TrainingResource[];
}

const TrainingListContainer: FC<IProps> = ({ values }) => {
  const itemList = useMemo(
    () => toArray(values).map((it) => it.trainingData!),
    [values]
  );

  return (
    <Flex
      flex={1}
      flexDir='column'
      overflow='auto'
      position='relative'
      gap='1px'
    >
      {itemList.map((item) => (
        <TrainingListItem key={item.eid} data={item} />
      ))}
    </Flex>
  );
};

export default TrainingListContainer;

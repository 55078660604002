import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { IconImage } from '../../../../../ui-components';
import { ChatGroupEntity } from '../../../../../types';

interface IProps {
  owner: ChatGroupEntity['owner'];
}

const ChannelCreator: FC<IProps> = ({ owner }) => {
  if (!owner) {
    return <>Creator of the channel</>;
  }

  return (
    <Flex gap='12px' align='center'>
      Creator of the channel
      <Flex
        bg='#B1E5FC99'
        p='4px 6px'
        gap='8px'
        color='#33383F'
        borderRadius='7px'
        align='center'
      >
        <IconImage
          name={owner.name}
          thumbnail={owner.profilePic}
          boxSize={20}
          borderRadius='3px'
        />

        <Box fontSize='12px' fontWeight='400'>
          <strong>{owner.name}</strong>, {owner.role}
        </Box>
      </Flex>
    </Flex>
  );
};

export default ChannelCreator;

import { useFolderSubFolder } from 'hooks';
import { useMemo, useState } from 'react';
import { ISelectedFolderData } from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';

interface IProps {
  folderDetails: ISelectedFolderData;
}

export function useMoveSubFolderLogic({ folderDetails }: IProps) {
  const [selectedFolder, setSelectedFolder] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { _allFoldersList } = useFolderSubFolder(folderDetails?.eid);
  const foldersList = useMemo(() => {
    let list = _allFoldersList('asc', true);
    if (searchValue) {
      list = list.filter((folder) =>
        folder.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return list;
  }, [searchValue, _allFoldersList]);
  return {
    foldersList,
    searchValue,
    selectedFolder,
    setSearchValue,
    setSelectedFolder,
  };
}

import React, { FC } from 'react';
import SkeletonSection from './SkeletonSection';
import { getRandomNumber } from '../../../../../utils';
import SkeletonAddButton from './SkeletonAddButton';

const LoadingSkeleton = React.memo(() => {
  return (
    <>
      <SkeletonSection length={getRandomNumber(2, 5)} />
      <SkeletonSection length={getRandomNumber(2, 5)} />
      <SkeletonAddButton width='300px' />
    </>
  );
});

LoadingSkeleton.displayName = 'LauncherConfigLoading Skeleton';

interface IProps {
  isLoaded?: boolean;
}

const LauncherConfigLoading: FC<IProps> = ({ isLoaded, children }) => {
  return isLoaded ? <>{children}</> : <LoadingSkeleton />;
};

LauncherConfigLoading.displayName =
  'pages/launcher/config-editor/post-setup/ConfigLoading';

export default LauncherConfigLoading;

import { avatarData } from '../assets/sampleAvatar/avatarData';

let caches: Record<string, string> = {};

interface Props {
  name: string;
  url?: string;
  defaultAvatar?: string;
}

export const clearAvatarCache = (): void => {
  caches = {};
};

export const getAvatar = ({ name, url, defaultAvatar }: Props): string => {
  // @ts-ignore
  if (!name && process?.env?.NODE_ENV === 'development') {
    throw 'Name field is required';
  }

  if (url) {
    return url;
  }

  if (defaultAvatar) {
    return defaultAvatar;
  }

  if (caches[name]) {
    return caches[name];
  }

  const newAvatar = avatarData[(Math.floor(Math.random() * 25) + 1) as never];

  caches[name] = newAvatar;

  return newAvatar;
};

import { LocationsEntity } from 'sop-commons/src/client';
import { StateCreator } from 'zustand';

// Define the state and actions interfaces for the user slice
export interface HomeState {
  selectedInProgressLocation: LocationsEntity | undefined;
  updateSelectedInProgressLocation: (
    location: LocationsEntity | undefined
  ) => void;
}

export const homeSlice: StateCreator<HomeState> = (set) => ({
  selectedInProgressLocation: undefined,
  updateSelectedInProgressLocation: (location) =>
    set(() => ({ selectedInProgressLocation: location })),
});

import isEmpty from 'lodash/isEmpty';
import { JSONContent } from 'delightree-editor';
import {
  AllLayout,
  MixedLayout,
  TextLayout,
} from 'sop-commons/src/utils/cardLayouts';
import { CardType } from '../../../types';
import { CardFormInput } from '../../CardEditor/card-editor.types';
import {
  formContent,
  imageContent,
  mixedContent,
  quizContent,
  textContent,
  videoContent,
} from '../../CardEditor/jsonDataFormatter';
import { toArray } from '../../../utils/utils';
import { CardTypeList } from '../../CardEditor/card-editor.data';
import {
  ACTION_THUMBNAIL,
  QUIZ_THUMBNAIL,
} from '../../../configs/default-image';

export function removeEmptyData<T extends JSONContent>(data: T | T[]): T[] {
  return toArray(data)
    .reduce<T[]>((previousValue, currentValue: T) => {
      if (currentValue.content || currentValue.text || currentValue.marks) {
        previousValue.push(currentValue);
      } else if (
        !isEmpty(currentValue?.attrs) &&
        !['heading', 'paragraph'].includes(currentValue.type as string)
      ) {
        previousValue.push(currentValue);
      }
      return previousValue;
    }, [])
    .filter((val) => !isEmpty(val));
}

const smartCardContent = (
  cardType: CardType,
  cc: JSONContent[],
  rawText?: string
) => {
  const defaultData: CardFormInput = {
    cardType: CardTypeList[0],
    content: [],
    layout: TextLayout.RICH_TEXT,
    translations: {},
    images: [],
    thumbnail: '',
  };
  const prevCardData: {
    content?: any[];
    thumbnail?: string;
    raw_content?: string;
    layout: AllLayout;
  } = {
    content: [],
    thumbnail: defaultData.thumbnail,
    raw_content: defaultData.raw_content,
    layout: TextLayout.RICH_TEXT,
  };
  let images = defaultData?.images as string[];
  switch (cardType) {
    case CardType.Text:
      prevCardData.layout = TextLayout.RICH_TEXT;
      prevCardData.content = [
        textContent({
          title: defaultData?.title,
          bodyJson: removeEmptyData(cc),
          layout: TextLayout.RICH_TEXT,
        }),
      ];
      prevCardData.thumbnail =
        'https://sop-uploads-staging.s3.amazonaws.com/extras/1002.png';
      // if (defaultData.layout === TextLayout.SIMPLE_TEXT) {
      //   prevCardData.raw_content = defaultData.title;
      // }
      if (rawText) {
        prevCardData.raw_content = rawText;
      }
      break;
    case CardType.Image:
      // eslint-disable-next-line no-case-declarations
      const imageList = toArray(toArray(cc)[0]?.attrs?.src);
      prevCardData.content = [
        imageContent({
          images: imageList,
          layout: MixedLayout.IMAGE_SINGLE,
        }),
      ];
      prevCardData.raw_content = defaultData.title;
      prevCardData.layout = MixedLayout.IMAGE_SINGLE;
      if (!imageList.includes(prevCardData.thumbnail as string)) {
        prevCardData.thumbnail = imageList[0];
      }

      break;
    case CardType.Action:
      prevCardData.content = [
        textContent({
          title: defaultData?.title,
          layout: defaultData?.layout,
        }),
      ];
      prevCardData.thumbnail = ACTION_THUMBNAIL;
      prevCardData.raw_content = defaultData.title;
      break;
    case CardType.Quiz:
      prevCardData.content = [
        quizContent({
          layout: defaultData?.layout,
          // @ts-ignore
          options: defaultData?.choices,
          // @ts-ignore
          answers: defaultData?.correctAnswer,
        }),
      ];
      prevCardData.thumbnail = QUIZ_THUMBNAIL;
      prevCardData.raw_content = defaultData.title;
      break;
    case CardType.Video:
      prevCardData.content = videoContent({
        videoUrl: toArray(cc)[0]?.attrs?.src,
        caption: '',
      });
      // prevCardData.thumbnail = generateVideoThumbnail(
      //   toArray(cc)[0]?.attrs?.src
      // );
      prevCardData.raw_content = defaultData.title;
      break;
    case CardType.Form:
      prevCardData.content = formContent({
        title: defaultData?.title,
        formUrl: defaultData?.formLink,
      });
      prevCardData.thumbnail =
        'https://sop-uploads-staging.s3.amazonaws.com/2l2kzw3m8pq70edbml58z9pr/1655217948967_197_Form Card Preview.png';
      prevCardData.raw_content = defaultData.title;
      break;
    case CardType.Mixed:
      prevCardData.content = mixedContent({
        layout: defaultData?.layout,
        images: defaultData?.images as string[],
        bodyJson: defaultData?.bodyJson,
      });
      prevCardData.raw_content = defaultData.title;
      if (!images.includes(prevCardData.thumbnail as string)) {
        prevCardData.thumbnail = images[0];
      }
      break;
    case CardType.CustomForm:
      prevCardData.thumbnail = defaultData.form?.thumbnail;
      prevCardData.raw_content = defaultData.form?.title;
      break;
  }

  return prevCardData;
};

export { smartCardContent };

import React, { CSSProperties, FC, useMemo } from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { Image } from '../../../../ui-components';
import locationIcon from '../../../../assets/images/location-purple.svg';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface IProps {
  name: string;
  thumbnail?: string;
  username?: string;
  onClick?: () => void;
}

const LocationName: FC<IProps> = ({ name, username, thumbnail, onClick }) => {
  const thumbnailUrl = useMemo(() => {
    if (thumbnail) {
      return thumbnail;
    }
    return locationIcon;
  }, [thumbnail, name]);

  return (
    <Flex
      pos='relative'
      maxWidth='100%'
      width='max-content'
      pr='16px'
      gap='12px'
      cursor={onClick ? 'pointer' : undefined}
      onClick={onClick}
    >
      {/* <Box>
        <Image
          width={48}
          height={48}
          src={thumbnailUrl}
          style={{
            borderRadius: 8,
            minWidth: 48,
            objectFit: 'cover',
          }}
        />
      </Box> */}
      <Flex
        direction='column'
        justify='space-between'
        overflow='hidden'
        py='2px'
      >
        <Tooltip label={name} hasArrow borderRadius='4px'>
          <Text
            // width='fit-content'
            color='#2A85FF'
            fontSize='14px'
            fontWeight='500'
            whiteSpace='pre-wrap'
          >
            {name}
          </Text>
        </Tooltip>
        {/* <Box
          fontSize='13px'
          fontWeight='600'
          style={wrapStyles}
          color='#9A9FA5'
        >
          {username}
        </Box> */}
      </Flex>
    </Flex>
  );
};

export default LocationName;

import { ApolloClient, gql } from '@apollo/client';
import { FormEditResponse } from '../form.graphql';

const IS_FORM_AVAILABLE = gql`
  query FormAvailabilityCheck($eid: String!, $override: Boolean) {
    FormById(eid: $eid, override: $override) {
      eid
      category
      categoryId
    }
  }
`;

export const fromAvailabilityCheck = async (
  client: ApolloClient<object>,
  formId?: string
): Promise<
  Pick<FormEditResponse['FormById'], 'eid' | 'category' | 'categoryId'>
> => {
  return client
    .query({
      query: IS_FORM_AVAILABLE,
      variables: {
        eid: formId,
        override: true,
      },
    })
    .then((response) => {
      if (response.errors) {
        return Promise.reject(response.errors);
      }
      return response.data?.FormById;
    });
};

import { useCallback, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import TrainingDeleteFooter, { TFooterProps } from './TrainingDeleteFooter';

interface IProps extends TFooterProps {}

type TrainingDelete = (props: IProps) => void;

export const useTrainingDelete = (): TrainingDelete => {
  const { t } = useTranslation(['common', 'training']);
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ onDeletePress, onInactivePress, status }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <BoxHeader
          title={t('training:deleteTrainingPathQues')}
          color='#CABDFF'
        />
      ),
      content: (
        <Box color='#6F767E' fontSize='16px' fontWeight='500'>
          <Box>{t('training:deleteTrainingMessage1')}</Box>
          <Box mt='2px'>{t('training:deleteTrainingMessage2')}</Box>
        </Box>
      ),
      isCentered: true,
      contentProps: {
        py: '16px',
        px: '24px',
        minW: '600px',
      },
      footer: (
        <TrainingDeleteFooter
          status={status}
          onDeletePress={onDeletePress}
          onInactivePress={onInactivePress}
        />
      ),
    });
  }, []);
};

import React, { FC, useCallback, useState } from 'react';
import { useBoolean } from '@chakra-ui/react';
import { FormProvider, get, useForm } from 'react-hook-form';
import { useApolloClient } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { useHistory, useLocation } from 'react-router-dom';

import { toArray } from 'utils';
import { getObject, updateObject } from 'utils/objectHelper';

import { useUserDataSelector } from '../../../../hooks';
import { eventBus } from '../../../../shared/eventEmit';

import {
  LauncherConfig,
  LAUNCHER_DATA,
  TaskVariable,
  UpdateLauncherInput,
  useConfigSave,
  useConfigSubmit,
  configDatabase,
} from '../common';
import {
  stepsMapWithId,
  transformTask,
  transformUpdateData,
} from '../common/helpers';

import { IContentItem, IFormInput, ITaskItem } from './editor.types';
import { EditorContext, EditorEvent } from './context';
import {
  getLauncherDefaultValues,
  transformTemplates,
} from './getLauncherDefaultValues';
import { depsFormKey, nextSelectedIndex, selected } from './helper';
import { finalDataValidate } from './finalDataValidate';
import PublishingLoader from './PublishingLoader';
import NavigationBlock from './NavigationBlock';
import { usePublishConfirm } from './usePublishConfirm';

interface IProps {}

const EditorProvider: FC<IProps> = ({ children }) => {
  const client = useApolloClient();
  const history = useHistory();

  const state =
    useLocation<Record<'config' | 'templatedData', LauncherConfig>>().state;

  const launcherEid = useUserDataSelector(
    (state) => state.entity?.launcher?.eid
  );

  const [initialising, action] = useBoolean(true);
  const [isPublishing, publishAction] = useBoolean(false);

  const methods = useForm<IFormInput>({
    defaultValues: async () => {
      action.on();
      if (state?.config) {
        setTimeout(action.off, 2000);
        history.replace(history.location.pathname);
        return getLauncherDefaultValues(state?.config);
      }

      if (launcherEid) {
        const res = await client.query<Record<'LauncherById', LauncherConfig>>({
          query: LAUNCHER_DATA,
          fetchPolicy: 'network-only',
          variables: {
            eid: launcherEid,
          },
        });
        setTimeout(action.off, 2000);
        return getLauncherDefaultValues(res.data?.LauncherById);
      }

      setTimeout(action.off, 2000);
      return Promise.resolve({
        activeStep: 0,
        ...state?.templatedData,
        contents: transformTemplates(state?.templatedData?.contents),
      });
    },
  });

  const confirmPublish = usePublishConfirm();
  const configSubmit = useConfigSubmit(methods);
  const configSave = useConfigSave(methods);

  const openTaskForm = useCallback((task: ITaskItem) => {
    if (methods.getValues('taskEditable') === task.tempEid) {
      eventBus.emit(task.isNew ? 'newLauncherTask' : task.tempEid + 'validate');
    } else {
      methods.setValue('taskEditable', task.tempEid);
      setTimeout(
        () =>
          eventBus.emit(
            task.isNew ? 'newLauncherTask' : task.tempEid + 'validate'
          ),
        1000
      );
    }
  }, []);

  const handleSubmit = (inputs?: UpdateLauncherInput) => {
    if (methods.getValues('published')) {
      return configSave(inputs);
    }
    return configSubmit(inputs);
  };

  // Return undefined if launcher is being created first time, so duplication update does not happen
  const emitHandler = useCallback(async (event: EditorEvent, data) => {
    // TODO: add logger in all else condition to track errors
    switch (event) {
      case EditorEvent.ADD_PHASE: {
        if (methods.getValues('contents').length === 1) {
          methods.setValue('activeStep', 0);
        }

        const inputs = transformUpdateData(methods.getValues());

        const nextIndex = nextSelectedIndex(inputs.contents);

        inputs.contents.splice(nextIndex, 0, {
          category: data as string,
          tasks: [],
        });

        const res = await handleSubmit(inputs);

        if (inputs.eid) {
          return [res.contents[nextIndex], nextIndex];
        }
        return undefined;
      }

      case EditorEvent.UPDATE_PHASE:
        {
          const inputs = transformUpdateData(methods.getValues());

          if (data?.index >= 0) {
            const path = ['contents', data.index, 'category'];
            updateObject(inputs, path, data.title);

            await handleSubmit(inputs);
          }
        }
        break;

      case EditorEvent.MOVE_PHASE:
        {
          if (data?.nextIndex >= 0) {
            methods.setValue('activeStep', -1);

            const hasNewTask = methods.getValues('newTaskAddress');

            let nextIndex = data.nextIndex;

            // Update the temporary address of new task, when moving section
            if (hasNewTask) {
              const split = hasNewTask!.split('.');
              const sectionIndex = +split[1];

              if (data.currentIndex === sectionIndex) {
                updateObject(split, [1], data?.nextIndex);
                methods.setValue('newTaskAddress', split.join('.') as never);
              } else if (data.nextIndex === sectionIndex) {
                updateObject(split, [1], data?.currentIndex);
                nextIndex = data?.currentIndex;
                methods.setValue('newTaskAddress', split.join('.') as never);
              } else {
                nextIndex = sectionIndex;
              }
            }

            setTimeout(() => methods.setValue('activeStep', nextIndex));
            await handleSubmit();
          }
        }
        break;

      case EditorEvent.DELETE_PHASE:
        {
          const inputs = transformUpdateData(methods.getValues());

          if (data >= 0) {
            inputs.contents.splice(data, 1);

            await handleSubmit(inputs);

            const hasNewTask = methods.getValues('newTaskAddress');

            // Update the temporary address of new task,
            // when deleting section index is <= the new task section index
            if (hasNewTask) {
              const split = hasNewTask!.split('.');
              const sectionIndex = +split[1];

              if (data < sectionIndex) {
                updateObject(split, [1], sectionIndex - 1);
                methods.setValue('newTaskAddress', split.join('.') as never);
              } else if (data === sectionIndex) {
                methods.setValue('newTaskAddress', undefined);
              }
            }

            const activeStep = methods.getValues('activeStep');

            if (activeStep === data) {
              methods.setValue('activeStep', -1);
            }

            if (inputs.contents.length === activeStep) {
              setTimeout(() => methods.setValue('activeStep', activeStep - 1));
            } else if (activeStep === data) {
              setTimeout(() => methods.setValue('activeStep', data));
            } else if (data < activeStep) {
              setTimeout(() => methods.setValue('activeStep', activeStep - 1));
            }

            return !!inputs.eid;
          }
        }
        break;

      case EditorEvent.ADD_FIRST_TASK:
        {
          const inputs = transformUpdateData(methods.getValues());

          const index = toArray(inputs?.contents).findIndex(
            (ct) => ct.eid === data?.categoryId
          );

          if (index !== -1) {
            inputs.contents[index].tasks.unshift({
              ...data.task,
              steps: stepsMapWithId(data.task?.steps),
            });

            const res = await handleSubmit(inputs);

            if (inputs.eid) {
              return res.contents[index].tasks[0];
            }
            return undefined;
          }
        }
        break;

      case EditorEvent.ADD_NEW_TASK:
        {
          const inputs = transformUpdateData(methods.getValues());

          const index = toArray(inputs?.contents).findIndex(
            (ct) => ct.eid === data?.categoryId
          );

          if (index !== -1 && data?.index >= 0) {
            inputs.contents[index].tasks.splice(data.index, 0, data.task);

            const res = await handleSubmit(inputs);

            if (inputs.eid) {
              return res.contents[index].tasks[data.index];
            }
            return undefined;
          }
        }
        break;

      case EditorEvent.UPDATE_TASK:
        {
          const inputs = transformUpdateData(methods.getValues(), {
            skipCheck: !data?.task?.eid,
          });

          const index = toArray(inputs?.contents).findIndex(
            (ct) => ct.eid === data?.categoryId
          );

          // If data is new then we will use data.index otherwise we will find the current task index
          // so other task not get updated. specially, when updating task is after new task form
          const taskIndex = data?.task?.eid
            ? toArray<TaskVariable>(
                getObject(inputs, ['contents', index, 'tasks'])
              ).findIndex((t) => t.eid === data?.task?.eid)
            : data.index;

          if (index !== -1 && taskIndex >= 0) {
            const path = ['contents', index, 'tasks', taskIndex];
            updateObject(inputs, path, data.task);

            const res = await handleSubmit(inputs);

            if (inputs.eid) {
              return get(res, path.join('.'));
            }
            return undefined;
          }
        }
        break;

      case EditorEvent.MOVE_TASK:
        {
          if (data?.nextIndex >= 0) {
            const hasNewTask = methods.getValues('newTaskAddress');

            // Update the temporary address of new task, when moving task
            if (hasNewTask) {
              const split = hasNewTask!.split('.');
              const taskIndex = +split[3];

              if (data.currentIndex === taskIndex) {
                updateObject(split, [3], data?.nextIndex);
                methods.setValue('newTaskAddress', split.join('.') as never);
              } else if (data.nextIndex === taskIndex) {
                updateObject(split, [3], data?.currentIndex);
                methods.setValue('newTaskAddress', split.join('.') as never);
              }
            }

            const depRes = methods.getValues([
              depsFormKey(data?.sectionIndex, data.nextIndex),
              depsFormKey(data?.sectionIndex, data.currentIndex),
            ]);

            if (depRes.includes('PREVIOUS_TASK')) {
              const _tasks = methods.getValues(
                `contents.${data.sectionIndex as number}.tasks`
              );

              updateObject(
                _tasks,
                [data.currentIndex, 'dependency'],
                'INDEPENDENT'
              );

              updateObject(
                _tasks,
                [data.nextIndex, 'dependency'],
                'INDEPENDENT'
              );

              methods.setValue(
                `contents.${data.sectionIndex as number}.tasks`,
                _tasks
              );
              await new Promise((resolve) => setTimeout(resolve));
            }

            await handleSubmit();
          }
        }
        break;

      case EditorEvent.CHANGE_TASK_PHASE:
        {
          const inputs = transformUpdateData(methods.getValues());

          const oldIndex = inputs.contents.findIndex(
            (v) => v.eid === data.categoryId
          );
          const index = inputs.contents.findIndex(
            (v) => v.eid === data.newCategoryId
          );

          const taskIndex = data?.taskIndex as number;

          if (index !== -1 && taskIndex >= 0) {
            const path = ['contents', index, 'tasks'];
            const oldPath = ['contents', oldIndex, 'tasks'];
            const tasks = get(inputs, path.join('.'));
            updateObject(inputs, path, [transformTask(data.task, 0), ...tasks]);

            const oldTasks = get(inputs, oldPath.join('.')) as TaskVariable[];

            if (oldTasks[taskIndex + 1]?.dependency === 'PREVIOUS_TASK') {
              updateObject(
                oldTasks,
                [taskIndex + 1, 'dependency'],
                'INDEPENDENT'
              );
            }

            updateObject(
              inputs,
              oldPath,
              // oldTasks.filter((it) => it.eid !== data?.task?.eid)
              oldTasks.filter((_, i) => i !== taskIndex)
            );

            await handleSubmit(inputs);

            const nextTaskKey = depsFormKey(oldIndex, taskIndex + 1);

            if (methods.getValues(nextTaskKey) === 'PREVIOUS_TASK') {
              const _tasks = methods.getValues(`contents.${oldIndex}.tasks`);

              updateObject(
                _tasks,
                [taskIndex + 1, 'dependency'],
                'INDEPENDENT'
              );

              methods.setValue(`contents.${oldIndex}.tasks`, _tasks);
            }

            if (inputs.eid) {
              methods.setValue(`contents.${index}.tasks`, [
                { ...data.task, dependency: 'INDEPENDENT' },
                ...methods.getValues(`contents.${index}.tasks`),
              ]);
            }
          }
        }
        break;

      case EditorEvent.DELETE_TASK:
        {
          const values = cloneDeep(methods.getValues());

          const index = values?.contents?.findIndex(
            (v) => v.eid === data?.categoryId
          );

          if (index !== -1 && data?.taskIndex >= 0) {
            const path = ['contents', index, 'tasks'];
            const tasks = get(
              values,
              path.join('.'),
              []
            ) as IContentItem['tasks'];

            const taskIndex = data?.task?.eid
              ? [...tasks].findIndex((it) => it.eid === data?.task?.eid)
              : (data?.taskIndex as number);

            if (tasks[taskIndex + 1]?.dependency === 'PREVIOUS_TASK') {
              updateObject(tasks, [taskIndex + 1, 'dependency'], 'INDEPENDENT');
            }

            updateObject(
              values,
              path,
              tasks.filter((it, index) => index !== taskIndex)
            );

            const inputs = transformUpdateData(values);

            await handleSubmit(inputs);

            const nextTaskKey = depsFormKey(index, taskIndex + 1);

            if (methods.getValues(nextTaskKey) === 'PREVIOUS_TASK') {
              methods.setValue(`contents.${index}.tasks`, tasks);
            }
          }
        }
        break;

      /**
       * @returns [LauncherTask, newCategoryId, newCategoryIndex]
       * @returns [LauncherTask]
       */
      case EditorEvent.SINGLE_TASK_UPDATE:
        {
          const values = cloneDeep(methods.getValues());

          const task = data?.data;

          // moving task to another category
          if (task?.newCategory !== task?.oldCategory) {
            const oldIndex = values.contents.findIndex(
              (v) => v.eid === task.oldCategory
            );
            const index = values.contents.findIndex(
              (v) => v.eid === task.newCategory
            );

            if (index !== -1) {
              values.contents[index].tasks.unshift(data.data);

              const path = ['contents', oldIndex, 'tasks'];
              const tasks = get(
                values,
                path.join('.')
              ) as IContentItem['tasks'];

              if (tasks[data?.index + 1]?.dependency === 'PREVIOUS_TASK') {
                updateObject(
                  tasks,
                  [data?.index + 1, 'dependency'],
                  'INDEPENDENT'
                );
              }

              updateObject(
                values,
                path,
                tasks.filter((it) => it.eid !== data?.data?.eid)
              );

              const res = await handleSubmit(transformUpdateData(values));

              const nextTaskKey = depsFormKey(index, data?.index + 1);

              if (methods.getValues(nextTaskKey) === 'PREVIOUS_TASK') {
                methods.setValue(`contents.${index}.tasks`, tasks);
                await new Promise((resolve) => setTimeout(resolve, 100));
              }

              return [
                res.contents[index].tasks[0],
                res.contents[index].eid,
                index,
              ];
            }
          } else {
            const index = toArray(values.contents).findIndex(
              (v) => v.eid === task.newCategory
            );

            if (index !== -1 && data?.index >= 0) {
              const path = ['contents', index, 'tasks', data.index];
              updateObject(values, path, data.data);

              const res = await handleSubmit(transformUpdateData(values));

              return [get(res, path.join('.'))];
            }
          }
        }
        break;

      case EditorEvent.SAVE_CHECKLIST:
        return await handleSubmit();

      case EditorEvent.PUBLISH_CONFIG: {
        if (methods.getValues('published')) {
          await confirmPublish();
        }

        publishAction.on();
        const values = cloneDeep(methods.getValues());

        const inputs = finalDataValidate(values, ({ sectionIndex, task }) => {
          methods.setValue('activeStep', sectionIndex);
          openTaskForm(task);
          publishAction.off();
        });

        // eslint-disable-next-line no-useless-catch
        try {
          inputs.published = true;
          const res = await configSubmit(inputs);
          methods.reset(getLauncherDefaultValues(res));
          await new Promise((resolve) => setTimeout(resolve, 500));
          await configDatabase.clearConfig();
          publishAction.off();
          return res;
        } catch (err) {
          publishAction.off();
          throw err;
        }
      }

      case EditorEvent.VALIDATE_ACTIVE_STEP: {
        const activeIndex = data as number;
        const contents = cloneDeep(methods.getValues(`contents`));

        const maxLength = contents.filter(selected)?.length;

        if (activeIndex >= 0 && maxLength > activeIndex) {
          const task = contents[activeIndex]?.tasks.find((i) => {
            if (!i.selected) {
              return false;
            }
            return !i.completed;
          });

          if (task) {
            openTaskForm(task);
            throw Error('all task is not completely filled');
          } else {
            if (contents[activeIndex].updateStatus !== 'finish') {
              updateObject(contents, [activeIndex, 'updateStatus'], 'finish');
              methods.setValue(`contents`, contents);
            }
          }
        }
        break;
      }

      case EditorEvent.UPDATE_PHASE_STATUS: {
        const activeIndex = data as number;
        const contents = cloneDeep(methods.getValues(`contents`));

        const maxLength = contents.filter(selected)?.length;

        if (activeIndex >= 0 && maxLength > activeIndex) {
          if (
            contents[activeIndex]?.tasks
              .filter(selected)
              .every((i) => i.completed)
          ) {
            updateObject(contents, [activeIndex, 'updateStatus'], 'finish');
          } else {
            updateObject(contents, [activeIndex, 'updateStatus'], 'wait');
          }

          methods.setValue(`contents`, contents);
        }
        break;
      }

      case EditorEvent.VALIDATE_FINISH: {
        const contents = cloneDeep(methods.getValues(`contents`));

        contents.forEach((content, sectionIndex) => {
          const task = content.tasks.find((t) => !t.completed);
          if (task) {
            methods.setValue('activeStep', sectionIndex);

            openTaskForm(task);

            throw Error('all task is not completely filled');
          }
        });
        break;
      }

      default:
        console.log(event, data);
    }
  }, []);

  return (
    <EditorContext.Provider
      value={{ initialising: initialising, emit: emitHandler }}
    >
      <FormProvider {...methods}>
        <NavigationBlock />
        {isPublishing ? (
          <PublishingLoader />
        ) : (
          <React.Fragment>{children}</React.Fragment>
        )}
      </FormProvider>
    </EditorContext.Provider>
  );
};

export default EditorProvider;

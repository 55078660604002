import { gql } from '@apollo/client';
import { AuthRole } from '../../../../authorization';

export const getContributors = gql`
  query AllowedContributorsList {
    AllowedContributorsList {
      eid
      name
      role
      type
      authRole
      status
      locations {
        eid
        name
      }
      profilePic
    }
  }
`;

export interface ContributorResponse {
  AllowedContributorsList: Array<{
    eid: string;
    name: string;
    type: 'user' | 'branch';
    role: string;
    authRole: AuthRole;
    status: 'active' | 'inactive';
    profilePic?: string;
    locations?: {
      eid: string;
      name: string;
    }[];
  }>;
}

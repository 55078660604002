import { gql } from '@apollo/client';
import { Question } from '../create-form/form.types';

export const getTemplate = gql`
  query FormTemplate($filter: FilterFindManyFormsInput, $perPage: Int) {
    FormsTemplatePagination(filter: $filter, perPage: $perPage) {
      count
      items {
        eid
        title
        description
        category
        thumbnail
        questions
      }
    }
  }
`;

export interface TemplateItem {
  eid: string;
  title: string;
  description: string;
  categoryId?: string;
  category: string;
  thumbnail: string;
  questions: Question[];
}

export interface TemplateResponse {
  FormsTemplatePagination: {
    count: number;
    items: TemplateItem[];
  };
}

import React from 'react';
import { Box } from '@chakra-ui/react';
import { HeaderGroup } from 'react-table';

import TableHeaderCell, { TableHeaderCellProps } from './TableHeaderCell';

interface TableHeaderProps<D extends object = {}> {
  headerGroups: HeaderGroup<D>[];
}

function TableHeader<D extends object = {}>({
  headerGroups,
}: TableHeaderProps<D>): React.ReactElement {
  return (
    <Box style={{ height: '66px' }} as='thead' className='table-header'>
      {headerGroups.map((headerGroup, index) => {
        return (
          <Box as='tr' {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column) => {
              return (
                <TableHeaderCell
                  key={column.id}
                  column={column as TableHeaderCellProps<D>['column']}
                />
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
}

export default TableHeader;

import styled from '@emotion/styled';

export const ReportsWrapper = styled.div`
  .ant-card {
    border-radius: 8px;
  }

  .react-datepicker-wrapper > .react-datepicker__input-container {
    input {
      outline: none;
      background-color: #f4f4f4;
    }
  }

  .drop-selected-container {
    display: flex;
    gap: 1rem;
    background-color: rgb(244, 244, 244);
    height: 3rem;
    border-radius: 12px;
    padding: 1rem;
    cursor: pointer;
    width: 100%;
    position: relative;
    align-items: center;
  }

  .drop-selected-container img {
    min-width: 15px;
    max-width: 15px;
  }

  .drop-options-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 7px;
    top: 55px;
    max-height: 200px;
    overflow-y: scroll;
    border: 1px solid #dddddd;
    z-index: 2;
    left: 0;
    width: inherit;

    ::-webkit-scrollbar {
      width: 4px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background: #d5d5d5;
      border-radius: 4px;
    }
  }

  .drop-option-container {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    padding: 1rem 1rem;
  }

  .drop-option-container img {
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
  }

  .drop-options-container :hover {
    background-color: #f4f4f4;
  }
`;

import React, { FC } from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';

interface IProps {
  width?: string;
}

const SkeletonAddButton: FC<IProps> = ({ width }) => {
  return (
    <Flex
      gap={3}
      p={4}
      height='54.5px'
      bg='#FFFFFF'
      align='center'
      borderRadius='12px'
      width={width}
      minWidth={width}
    >
      <Skeleton boxSize={4} borderRadius='full' />
      <Skeleton height='14px' borderRadius='full' width='100px' />
    </Flex>
  );
};

export default SkeletonAddButton;

import { gql, useQuery } from '@apollo/client';
import { UserStatus, UserType } from 'types';
import { AuthRole } from 'authorization';
import { useUserData } from 'hooks';
import { toArray } from 'utils/utils';
import { useCallback } from 'react';

export interface LocationOwnerEntity {
  eid: string;
  name: string;
  profilePic?: string;
  type: UserType;
  locations: Array<{
    eid: string;
    name: string;
  }>;
  role: string;
  authRole: AuthRole;
}

interface LocationOwnerResponse {
  EntityUser: LocationOwnerEntity[];
}

interface LocationOwnerVariable {
  status?: UserStatus[];
  entityId: string;
  type: UserType[];
  authRole: AuthRole[];
}

export const LOCATION_OWNER_QUERY = gql`
  query LocationOwners(
    $status: [userStatusEnum]
    $entityId: String!
    $type: [userTypeEnum]
    $authRole: [userAuthRoleEnum]
  ) {
    EntityUser(
      status: $status
      entityId: $entityId
      type: $type
      authRole: $authRole
    ) {
      eid
      name
      profilePic
      type
      locations {
        eid
        name
      }
      role
      authRole
    }
  }
`;

interface OwnerListData {
  loading: boolean;
  locationOwners: LocationOwnerEntity[];
  refetch: () => Promise<void>;
}

export const useLocationOwnerList = (): OwnerListData => {
  const entityId = useUserData()?.entityId;
  const { data, loading, refetch } = useQuery<
    LocationOwnerResponse,
    LocationOwnerVariable
  >(LOCATION_OWNER_QUERY, {
    fetchPolicy: 'network-only',
    skip: !entityId,
    variables: {
      entityId: entityId,
      type: ['user'],
      authRole: [AuthRole.LOCATION_OWNER],
      // status: ['active', 'inactive'],
    },
  });

  const _refetch = useCallback(async () => {
    await refetch({
      entityId: entityId,
      type: ['user'],
      authRole: [AuthRole.LOCATION_OWNER],
      // status: ['active', 'inactive'],
    });
  }, [entityId]);

  return {
    loading,
    locationOwners: toArray(data?.EntityUser),
    refetch: _refetch,
  };
};

import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IProps {
  viewCount: number;
  totalCount: number;
}

const StatusCell: FC<IProps> = ({ viewCount, totalCount }) => {
  const { t } = useTranslation('common');
  return (
    <Flex whiteSpace='pre-wrap' marginBottom='25px'>
      <Box
        as={'span'}
        fontSize='14px'
        fontWeight='600'
        p='5px 7px'
        borderRadius='4px'
        bg={viewCount === totalCount ? '#eafae4' : 'rgba(255, 188, 153, 0.2)'}
        color={viewCount === totalCount ? '#83bf6e' : '#ff6a55'}
      >
        {t(viewCount === totalCount ? 'completed' : 'pending')}
      </Box>
    </Flex>
  );
};

export default StatusCell;

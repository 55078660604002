import { Box } from '@chakra-ui/react';
import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import { IVisibility } from 'pages/Chapters/CreateChapterModal/UploadFile/UploadFile';
import React from 'react';
import { CategoryEntity } from 'sop-commons/src/client';
import VisibilityStatusInfo from 'ui-components/VisibilityStatusInfo';
import { IHierarchy, VisibilityScope } from 'zustandStore/slices/useVisibility';

export const parentDataRender = (
  visibilityScope: VisibilityScope | undefined,
  hierarchyData: IHierarchy | undefined,
  visibilitySelection: IVisibility | undefined
) => {
  switch (visibilityScope) {
    case 'folder':
      return null;
    case 'subFolder':
      return (
        <Box mt={2} w='full'>
          <VisibilityStatusInfo
            parentDetails={hierarchyData?.parentFolder?.visibilityData}
            childDetails={visibilitySelection}
            type='subFolder'
            folderName={hierarchyData?.subFolder?.details?.name || 'Subfolder'}
            parentFolderName={hierarchyData?.parentFolder?.details?.name}
          />
        </Box>
      );
    case 'chapter':
      return (
        <Box mt={2} w='full'>
          <VisibilityStatusInfo
            parentDetails={
              hierarchyData?.subFolder?.details
                ? hierarchyData?.subFolder?.visibilityData
                : hierarchyData?.parentFolder?.visibilityData
            }
            childDetails={visibilitySelection}
            type='chapter'
            folderName={hierarchyData?.chapter?.details?.title || ''}
            parentFolderName={
              hierarchyData?.subFolder?.details
                ? hierarchyData?.subFolder?.details?.name
                : hierarchyData?.parentFolder?.details?.name
            }
          />
        </Box>
      );
    default:
      return null;
  }
};

export const getAllFolderSubFolderList = (foldersList: CategoryEntity[]) => {
  let arr: CustomCategoryEntity[] = [];
  foldersList?.forEach((list) => {
    if (list?.children?.length > 0) {
      list?.children?.forEach((ch) => {
        arr?.push({
          ...ch,
          parentFolderId: list?.eid,
          parentFolderName: list?.name,
          type: 'subFolder',
        });
      });
    }
    arr?.push({
      ...list,
      parentFolderId: undefined,
      parentFolderName: undefined,
      type: 'folder',
    });
  });
  return arr;
};

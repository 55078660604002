export const modalContentWidth = {
  sm: '340px',
  md: '740px',
  base: '1136px',
  xl: '1136px',
  ['2xl']: '1136px',
};

export const modalContentMinWidth = {
  sm: '340px',
  md: '674px',
  base: '1136px',
  xl: '1136px',
  ['2xl']: '1136px',
};

import React, { FC } from 'react';
import { AccessibilityType } from 'types';
import { NotAccessibleBase } from 'ui-components';
import { Messages, MessagesDesc } from './message-keys';

interface IProps {
  type: AccessibilityType;
}

const FormNotAccessible: FC<IProps> = ({ type }) => {
  return (
    <NotAccessibleBase
      type={type}
      titleKey={Messages[type]}
      descKey={MessagesDesc[type]}
    />
  );
};

export default FormNotAccessible;

import {
  createContext,
  Dispatch,
  FC,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { QueryLazyOptions, useLazyQuery, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  ChaptersResponse,
  ChapterVariable,
  getChapters,
  moveCategoryItems,
  MoveItemResponse,
  MoveItemVariable,
} from './add-category.graphql';
import { SelectOption } from '../../../atoms/Dropdown';
import { toArray } from '../../../utils/utils';
import { CategoryEntity } from 'sop-commons/src/client';
import { cloneDeep } from '@apollo/client/utilities';
import { FileType, ISopMedia } from 'pages/Chapters/chapter.types';
import { IEmojiStructure } from 'delightree-editor';

interface IContext {
  itemsList: IItemList[];
  fetchData?: (options?: QueryLazyOptions<ChapterVariable>) => void;
  totalCount: number;
  fetchingData?: boolean;
  category?: string;
  setItemList?: Dispatch<SetStateAction<IItemList[]>>;
  refreshData?: () => void;
  onMoveClick?: (value: SelectOption[]) => Promise<unknown>;
  moving?: boolean;
  selectRef?: Ref<any>;
  selectedItems: IItemList[];
  updateSearchValue?: (value: string) => void;
}

export const CategoryContext = createContext<IContext>({
  itemsList: [],
  selectedItems: [],
  totalCount: 0,
});

export interface IItemList {
  eid: string;
  title: string;
  thumbnail: string;
  status: string;
  category: string;
  categoryId: string;
  sopType?: string;
  type: 'folder' | 'sop';
  checked: boolean;
  files?: FileType[];
  icon?: IEmojiStructure;
  media?: ISopMedia[];
  color?: string;
  visibility?: string;
}

interface IProps {
  category: string;
  data: CategoryEntity;
  onMoveSuccess?: () => void;
}

export const CategoryProvider: FC<IProps> = ({
  children,
  category,
  data: categoryData,
  onMoveSuccess,
}) => {
  const { t } = useTranslation(['common', 'category']);
  const toast = useToast({
    position: 'top-right',
  });
  const selectRef = useRef<any>(null);
  const refreshRef = useRef(false);
  const [itemsList, setItemList] = useState<IItemList[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const updateSearchValue = (value: string) => {
    setSearchValue(value);
  };

  const [totalCount, setTotalCount] = useState(0);

  const [fetchData, { loading, data }] = useLazyQuery<
    ChaptersResponse,
    ChapterVariable
  >(getChapters, {
    fetchPolicy: 'network-only',
    // variables: {
    //   perPage: 30,
    //   filter: {
    //     category: props.category,
    //   },
    // },
    onCompleted: (data) => {
      let sopItems: any[] = cloneDeep(data?.SopPagination?.items);
      sopItems?.forEach((item) => {
        item.type = 'sop';
        item.checked = false;
      });
      let categoryItems: IItemList[] = [];
      if (searchValue) {
        categoryData?.children?.filter((child) => {
          console.log('child name : ', child?.name?.toLowerCase()?.trim());
          console.log('search value : ', searchValue?.toLowerCase()?.trim());
          if (
            child?.name
              ?.toLowerCase()
              ?.trim()
              ?.includes(searchValue?.trim()?.toLowerCase())
          ) {
            categoryItems?.push({
              eid: child?.eid,
              title: child?.name,
              thumbnail: child?.icon,
              status: child?.status,
              category: child?.name,
              categoryId: child?.eid,
              type: 'folder',
              color: child?.color,
              visibility: child?.visibility,
              checked: false,
            });
          }
        });
      } else {
        categoryItems = categoryData?.children?.map((children) => {
          return {
            eid: children?.eid,
            title: children?.name,
            thumbnail: children?.icon,
            status: children?.status,
            category: children?.name,
            categoryId: children?.eid,
            type: 'folder',
            color: children?.color,
            visibility: children?.visibility,
            checked: false,
          };
        });
      }
      setTotalCount(sopItems?.length + categoryItems?.length);
      setItemList([...sopItems, ...categoryItems] || []);
      // if (refreshRef.current) {
      //   setItemList([...categoryData?.children, ...data?.SopPagination?.items] || []);
      // } else {
      //   setItemList((prevState) => {
      //     const filteredData = prevState?.filter((val) => val.checked) || [];
      //     const keys: string[] = filteredData?.map((val) => val.eid) || [];
      //     const items = (data?.SopPagination?.items || []).filter(
      //       (val) => !keys.includes(val.eid)
      //     );
      //     return [...filteredData, ...items];
      //   });
      // }
      refreshRef.current = false;
    },
  });

  const [moveToCategory, { loading: moving }] = useMutation<
    MoveItemResponse,
    MoveItemVariable
  >(moveCategoryItems, {
    onCompleted: () => {
      onMoveSuccess?.();
      selectRef?.current?.blur();
      let count = 0;
      setItemList((prevState) => {
        count = prevState.length;
        const filteredData = prevState?.filter((val) => !val.checked) || [];
        count -= filteredData.length;
        return [...filteredData];
      });

      toast({
        status: 'success',
        title: t('success'),
        description: t('category:item_success_moved', {
          count: count,
        }),
      });

      if (category) {
        fetchData({
          variables: {
            perPage: 30,
            filter: {
              category: category,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    fetchData({
      variables: {
        perPage: 30,
        filter: {
          category: category,
        },
      },
    });
  }, [category]);

  const refreshData = useCallback(() => {
    refreshRef.current = true;
    fetchData({
      variables: {
        perPage: 30,
        filter: {
          category: category,
        },
      },
    });
  }, [category]);

  const onMoveClick = useCallback(
    (_values: SelectOption[]) => {
      console.log('onMoveClick : _values : ', _values);
      const nextCategory = toArray(_values)[0]?.eid;

      const items = itemsList.reduce<string[]>(
        (previousValue, currentValue) => {
          if (currentValue.checked) {
            previousValue.push(currentValue.eid);
          }
          return previousValue;
        },
        []
      );

      return moveToCategory({
        variables: {
          input: {
            // category: nextCategory,
            categoryId: nextCategory,
            change: 'existing',
            items: items,
          },
        },
      });
    },
    [itemsList]
  );

  const selectedItems = useMemo(() => {
    return itemsList?.filter((value) => value.checked) || [];
  }, [itemsList]);

  // const count = data?.SopPagination?.count || 0;

  return (
    <CategoryContext.Provider
      value={{
        itemsList,
        setItemList,
        fetchData,
        updateSearchValue,
        totalCount: totalCount,
        fetchingData: loading,
        category,
        refreshData,
        onMoveClick,
        moving,
        selectRef,
        selectedItems,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

import { Center, Flex, Image, Text } from '@chakra-ui/react';
import RestrictionIcon from '../../assets/images/restrictionStair.svg';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { PrimaryButton } from 'atoms';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { AuthRole, userObj } from 'sop-commons/src/client';
import { UserEntityData } from 'shared/graphql/shared-types';

interface GetLocationUserVariable {
  locationId: string;
}

interface GetLocationUserResponse {
  locationUser: Array<{
    eid: string;
    name: string;
    authRole: AuthRole;
    role: string;
    status: string;
  }>;
}

const GET_LOCATION_USERS = gql`
  query LocationUser($locationId: String!) {
    locationUser(locationId: $locationId) {
      eid
      name
      authRole
      role
      status
    }
  }
`;

interface IProps {
  type: 'folder' | 'subFolder' | undefined;
}

const FolderSubFolderRestriction: FC<IProps> = ({ type }) => {
  const loggedInUserData = useReactiveVar(userObj);
  const [showChatModal, setShowChatModal] = useState(false);
  const [managerId, setManagerId] = useState('');

  const [getLocationUsers, { data, loading }] = useLazyQuery<
    GetLocationUserResponse,
    GetLocationUserVariable
  >(GET_LOCATION_USERS, {
    fetchPolicy: 'network-only',
  });
  //   let sortedUsersList = [...usersList].sort((a, b) =>
  //     a.name.localeCompare(b.name)
  //   );
  //   let filteredUsersList = sortedUsersList?.filter(
  //     (user) => user?.eid !== loggedInUserData?.eid && user?.status === 'active'
  //   );
  //   console.log('SORTED USERS LIST : ', filteredUsersList);
  //   let loggedInUserLocationIds =
  //     loggedInUserData?.locations?.map((loc) => loc?.eid) || [];
  //   let usersOfSameLocAsLoggedInUser: UserEntityData[] = [];
  //   filteredUsersList?.map((list) => {
  //     list?.locations?.map((loc) => {
  //       if (loggedInUserLocationIds?.includes(loc?.eid)) {
  //         usersOfSameLocAsLoggedInUser?.push(list);
  //         return;
  //       }
  //     });
  //   });
  //   console.log(
  //     'usersOfSameLocAsLoggedInUser : ',
  //     usersOfSameLocAsLoggedInUser
  //   );
  //   let locationOwners = usersOfSameLocAsLoggedInUser?.filter(
  //     (user) => user?.authRole === AuthRole.LOCATION_OWNER
  //   );
  //   let admins = usersOfSameLocAsLoggedInUser?.filter(
  //     (user) => user?.authRole === AuthRole.ADMIN
  //   );
  //   let superAdmins = usersOfSameLocAsLoggedInUser?.filter(
  //     (user) => user?.authRole === AuthRole.SUPER_ADMIN
  //   );
  //   let foundUserEid = '';
  //   if (
  //     loggedInUserData?.authRole === AuthRole.WORKER ||
  //     loggedInUserData?.type === 'branch'
  //   ) {
  //     foundUserEid =
  //       locationOwners?.[0]?.eid || admins?.[0]?.eid || superAdmins?.[0]?.eid;
  //   } else if (loggedInUserData?.authRole === AuthRole.LOCATION_OWNER) {
  //     foundUserEid = admins?.[0]?.eid || superAdmins?.[0]?.eid;
  //   } else if (loggedInUserData?.authRole === AuthRole.ADMIN) {
  //     foundUserEid = admins?.[0]?.eid || superAdmins?.[0]?.eid;
  //   } else if (loggedInUserData?.authRole === AuthRole.SUPER_ADMIN) {
  //     foundUserEid = superAdmins?.[0]?.eid;
  //   } else {
  //     foundUserEid = '';
  //   }
  //   console.log('FOUND USER EID : ', foundUserEid);
  //   // let foundSuperAdmin = sortedUsersList?.find(
  //   //   (user) =>
  //   //     user?.eid !== loggedInUserData?.eid &&
  //   //     user?.authRole === AuthRole.SUPER_ADMIN
  //   // );
  //   // console.log(foundSuperAdmin);
  //   // return foundSuperAdmin?.eid;
  //   return foundUserEid;
  // }, [usersList, loggedInUserData]);

  const getLocationUsersHandler = () => {
    let loggedInUserLocationId = loggedInUserData?.locations?.[0]?.eid;
    if (loggedInUserLocationId) {
      getLocationUsers({
        variables: {
          locationId: loggedInUserLocationId,
        },
      });
    }
  };

  useEffect(() => {
    if (data && data?.locationUser?.length > 0) {
      let filteredLocationUsers = data?.locationUser?.filter(
        (loc) => loc?.eid !== loggedInUserData?.eid
      );
      console.log(filteredLocationUsers);
      let locationOwners = filteredLocationUsers?.filter(
        (user) => user?.authRole === AuthRole.LOCATION_OWNER
      );
      let admins = filteredLocationUsers?.filter(
        (user) => user?.authRole === AuthRole.ADMIN
      );
      let superAdmins = filteredLocationUsers?.filter(
        (user) => user?.authRole === AuthRole.SUPER_ADMIN
      );
      let foundUserEid =
        locationOwners?.[0]?.eid ||
        admins?.[0]?.eid ||
        superAdmins?.[0]?.eid ||
        '';
      console.log({ locationOwners, admins, superAdmins });
      if (foundUserEid) {
        setShowChatModal(true);
        setManagerId(foundUserEid);
      }
    }
  }, [data]);

  return (
    <>
      <Center flexDir='column' gap={4}>
        <Image src={RestrictionIcon} boxSize='160px' />
        <Text fontWeight={700} fontSize='16px' textAlign='center'>
          Sorry, the {type === 'subFolder' ? 'subfolder' : 'folder'} you have
          requested does not exist.
        </Text>
        <Text
          fontWeight={400}
          fontSize='13px'
          color='#33383F'
          textAlign='center'
        >
          Either it has been deleted or you don&apos;t have access to view it.
          Please ensure you have the correct URL. If the problem persists,
          contact your manager to gain access.
        </Text>
        <PrimaryButton
          title='Contact manager'
          colorScheme='blue'
          variant='solid'
          disabled={loading}
          isLoading={loading}
          style={{ width: 'fit-content' }}
          onClick={() => {
            // setShowChatModal(true);
            getLocationUsersHandler();
          }}
        />
      </Center>
      {showChatModal && (
        <SingleUserChatModal
          usePortal
          selectedUser={managerId}
          onClose={() => setShowChatModal(false)}
        />
      )}
    </>
  );
};

export default FolderSubFolderRestriction;

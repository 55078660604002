import { useMutation } from '@apollo/client';
import { Box, Flex, Image, Input, Text, Textarea } from '@chakra-ui/react';
import SearchInput from 'atoms/SearchInput';
import React, { useState } from 'react';
import DashboardContainer from 'sub-components/DashboardContainer';
import botIcon from '../../assets/images/bot-icon.png';
import { SEARCH_DELIGHTBOT_SOP } from './search-delightbot.graphql';
import { ISearchGptSop } from './search-delightbot.types';
import PrimaryButton from 'atoms/PrimaryButton';

const SearchDelightbot = () => {
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');

  const questions = [
    { question: 'How many sick leaves do I get in a year?' },
    { question: 'If I move states, can I apply for a transfer?' },
    { question: "I didn't take a break, do I need to declare it, and where?" },
  ];

  const [searchBotMutation, { loading }] = useMutation<ISearchGptSop>(
    SEARCH_DELIGHTBOT_SOP,
    {
      onCompleted: (data) => {
        console.log('data : ', data?.SearchGptSop?.response);
        setResponse(data?.SearchGptSop?.response);
      },
      onError: (error) => {
        setResponse('Could not find any relevant data.');
      },
    }
  );

  const searchBotMutationHandler = () => {
    setResponse('');
    searchBotMutation({
      variables: {
        query: input?.trim(),
      },
    });
  };

  return (
    <DashboardContainer>
      <Flex w='full' flexDir='column'>
        <Text as='h1' fontWeight='bold'>
          Search with Delightbot
        </Text>
        <Box
          w='full'
          borderRadius={4}
          bg='white'
          h='80vh'
          p={8}
          overflow='scroll'
        >
          <Flex gap={4}>
            <Box w={4} bg='rgb(202, 189, 255)' borderRadius={4} />
            <SearchInput
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
                if (!e?.target?.value?.trim()) {
                  setResponse('');
                }
              }}
              hideShortcuts
              placeholder='Enter text to begin with...'
            />
            <Box w='fit-content'>
              <PrimaryButton
                title='Search'
                variant='solid'
                colorScheme='blue'
                onClick={searchBotMutationHandler}
                disabled={loading || !input?.trim()}
                isLoading={loading}
              />
            </Box>
          </Flex>
          <Textarea
            bg='rgb(244, 244, 244)'
            p={4}
            resize='none'
            variant='unstyled'
            overflow='scroll'
            isDisabled
            borderRadius={8}
            minH='35vh'
            maxH='35vh'
            mt={4}
            value={response}
          />
          <Box my='4'>
            <Text as='h2' fontWeight='bold'>
              What else can I help you with?
            </Text>
            <Text as='b' color='#6f767e'>
              Some common questions
            </Text>
            <Flex flexDir='column' gap={4} mt={4}>
              {questions?.map((question, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    setInput(question?.question);
                    setResponse('');
                  }}
                >
                  <Input
                    cursor='pointer'
                    p={4}
                    bg='rgb(244, 244, 244)'
                    value={question?.question}
                    variant='unstyled'
                    isDisabled
                    borderRadius={8}
                    fontWeight='bold'
                  />
                </Box>
              ))}
            </Flex>
          </Box>
        </Box>
      </Flex>
    </DashboardContainer>
  );
};

export default SearchDelightbot;

import { gql } from '@apollo/client';
import { FormCategory } from '../../../../types';

export interface AddCategoryResponse {
  AddFormCategory: FormCategory;
}

export const FORM_CATEGORY_CREATE = gql`
  mutation AddFormCategory($category: String) {
    AddFormCategory(category: $category) {
      eid
      name
      categoryType
      createdBy
      entityId
    }
  }
`;

import { ICompareVisibilityType } from './visibilityHelper';

export const parentORChildAND = (
  parent: any,
  child: any
): ICompareVisibilityType => {
  console.log({ PARENT_OR_CHILD_AND: { parent, child } });
  console.log({ PARENT_OR_CHILD_AND_FLAG: 'parentChildNoIntersection' });
  return 'parentChildNoIntersection';
};

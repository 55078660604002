import React from 'react';
import { Checkbox, CheckboxProps, forwardRef } from '@chakra-ui/react';

interface IProps extends CheckboxProps {
  indeterminate?: boolean;
}

const IndeterminateCheckbox = forwardRef<IProps, 'input'>(
  ({ indeterminate, checked, ...rest }, ref) => {
    const defaultRef = React.useRef<typeof Checkbox>();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          isIndeterminate={indeterminate}
          ref={resolvedRef}
          isChecked={checked}
          {...rest}
        />
      </>
    );
  }
);

IndeterminateCheckbox.displayName = 'Indeterminate Checkbox';

export default IndeterminateCheckbox;

import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import PrimaryButton from 'atoms/PrimaryButton';
import './GenericModal.scss';

interface ButtonsObject {
  mt: any;
  type: any;
  size: any;
  isLoading: any;
  disabled: any;
  title: any;
  leftIcon: any;
  rightIcon: any;
  onClick: any;
  style: any;
  variant: any;
  colorScheme: any;
  className: any;
  value: any;
  buttonData?: any;
}

interface GenericModalProps {
  isOpen: boolean;
  onClose: any;
  title: string;
  lineColor: string;
  closeIcon: any;
  isCentered: boolean;
  paddingLeft: number;
  paddingRight: number;
  paddingTop: number;
  paddingBottom: number;
  modalSizes: {
    sm: string;
    md: string;
    base: string;
    xl: string;
    ['2xl']: string;
  };
  modalMinSizes: {
    sm: string;
    md: string;
    base: string;
    xl: string;
    ['2xl']: string;
  };
  buttonsObject: ButtonsObject[];
  buttonsPosition: 'left' | 'center' | 'right';
  children: any;
}

const GenericModal = ({
  isOpen,
  onClose,
  title,
  closeIcon,
  lineColor,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  isCentered,
  buttonsObject,
  buttonsPosition,
  modalSizes,
  modalMinSizes,
  children,
}: GenericModalProps) => {
  const getButtonsPosition = () => {
    if (buttonsPosition === 'left') {
      return 'flex-start';
    } else if (buttonsPosition === 'center') {
      return 'center';
    } else {
      return 'flex-end';
    }
  };

  const getButtons = () => {
    let buttonsObjectValues = Object.values(buttonsObject);
    return buttonsObjectValues.map((button) => (
      <div key={button.title}>
        <div
          style={{
            display: 'flex',
            justifyContent: getButtonsPosition(),
            width: '100%',
          }}
        >
          <PrimaryButton
            mt={button.mt}
            type={button.type}
            size={button.size}
            isLoading={button.isLoading}
            disabled={button.disabled}
            title={button.title}
            leftIcon={button.leftIcon}
            rightIcon={button.rightIcon}
            onClick={() => button.onClick(button.buttonData)}
            style={button.style}
            variant={button.variant}
            colorScheme={button.colorScheme}
            className={button.className}
            value={button.value}
          />
        </div>
      </div>
    ));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={isCentered}>
      <ModalOverlay />
      <ModalContent
        style={{
          paddingTop: `${paddingTop}px`,
          paddingBottom: `${paddingBottom}px`,
          paddingLeft: `${paddingLeft}px`,
          paddingRight: `${paddingRight}px`,
        }}
        width={{
          sm: modalSizes.sm,
          md: modalSizes.md,
          base: modalSizes.base,
          xl: modalSizes.xl,
          ['2xl']: modalSizes['2xl'],
        }}
        minWidth={{
          sm: modalMinSizes.sm,
          md: modalMinSizes.md,
          base: modalMinSizes.base,
          xl: modalMinSizes.xl,
          ['2xl']: modalMinSizes['2xl'],
        }}
      >
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <div style={{ fontSize: '20px', fontWeight: '600' }}>{title}</div>
          <div>
            <img
              src={closeIcon}
              style={{ height: '20px', width: '20px', cursor: 'pointer' }}
            />
          </div>
        </div> */}
        <ModalBody>
          <div className='generic_top_modal'>
            <div className='generic_left_top_content'>
              <div
                style={{
                  background: `${lineColor}`,
                  borderRadius: '4px',
                  padding: '7px',
                  marginRight: '10px',
                }}
              ></div>
              <div className='generic_title_data'>
                <div className='generic_title'>{title}</div>
              </div>
            </div>
            <div>
              <span onClick={onClose}>
                <img
                  src={closeIcon}
                  alt='close'
                  title='close'
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </div>
          </div>
          {children}
        </ModalBody>

        <ModalFooter>{getButtons()}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GenericModal;

import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { Checkbox, useCheckboxGroupContext } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';

import { IMoveFormItem } from './move-form.graphql';

interface IProps {
  items: IMoveFormItem[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const AllFormChecked: FC<IProps> = ({ items, onChange }) => {
  const { value } = useCheckboxGroupContext();
  const [allChecked, setAllChecked] = useState(false);

  const [allItemChecked, isIndeterminate] = useMemo(() => {
    const all = items?.every((val) => value.includes(val.eid));
    const some = items?.some((val) => value.includes(val.eid));
    return [all && !!items?.length, some && !all];
  }, [items, value]);

  const onAllCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
  };

  return (
    <Checkbox
      isChecked={allChecked && allItemChecked}
      isIndeterminate={allChecked && isIndeterminate}
      onChange={callAll(onChange, onAllCheckChange)}
    />
  );
};

export default AllFormChecked;

import React, { FC, useMemo } from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import { getRandomNumber } from '../../../../utils';

interface IProps {
  isLoaded?: boolean;
}

const AccordionSkeleton: FC<IProps> = ({ children, isLoaded }) => {
  const count = useMemo(() => getRandomNumber(2, 8), []);

  if (isLoaded) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <Flex flexDir='column'>
      {Array.from({ length: count }).map((_, i) => (
        <Flex
          key={i}
          py={3}
          _notLast={{
            borderBottom: '1px solid #EEEEEE',
          }}
        >
          <Skeleton w='full' h='23px' borderRadius='8px' />
        </Flex>
      ))}
    </Flex>
  );
};

export default AccordionSkeleton;

import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera, faFile, faVideo } from '@fortawesome/pro-light-svg-icons';

import { QuestionType } from '../../../../types';

import { IFormInput } from '../form.types';

export const HelperTextFor: QuestionType[] = [
  QuestionType.VIDEO_UPLOAD,
  QuestionType.IMAGE_UPLOAD,
  QuestionType.FILE_UPLOAD,
];

const Component: FC<{ questionType: QuestionType }> = ({ questionType }) => {
  const { t } = useTranslation('form');

  const [icon, transKey] = useMemo(() => {
    return match(questionType)
      .with(QuestionType.IMAGE_UPLOAD, () => [faCamera, 'questionImageHelp'])
      .with(QuestionType.VIDEO_UPLOAD, () => [faVideo, 'questionVideoHelp'])
      .with(QuestionType.FILE_UPLOAD, () => [faFile, 'questionFileHelp'])
      .otherwise(() => [null, null]);
  }, [questionType]);

  if (!transKey) {
    return null;
  }

  return (
    <Flex
      mt={4}
      color='#8E59FF'
      minH='50px'
      borderRadius='8px'
      border='1px dashed #D2D2D2'
      bg='#FFFFFF'
      align='center'
      p='16px'
      gap='10px'
    >
      <FontAwesomeIcon icon={icon as IconProp} color='#8E59FF' />
      {/*// @ts-ignore */}
      <Box fontSize='12px'>{t(transKey)}</Box>
    </Flex>
  );
};

interface IProps {
  currentIndex: number;
}

const VideoImageHelper: FC<IProps> = ({ currentIndex }) => {
  const { control } = useFormContext<IFormInput>();

  const questionType: QuestionType = useWatch({
    control: control,
    name: `questions.${currentIndex}.qType`,
  });

  if (!HelperTextFor.includes(questionType)) {
    return null;
  }

  return <Component questionType={questionType} />;
};

export default VideoImageHelper;

import { createContext, useContext } from 'react';

export enum EditorEvent {
  ADD_PHASE = 'ADD_PHASE',
  UPDATE_PHASE = 'UPDATE_PHASE',
  MOVE_PHASE = 'MOVE_PHASE',
  DELETE_PHASE = 'DELETE_PHASE',

  ADD_FIRST_TASK = 'ADD_FIRST_TASK',
  ADD_NEW_TASK = 'ADD_NEW_TASK',
  UPDATE_TASK = 'UPDATE_TASK',
  MOVE_TASK = 'MOVE_TASK',
  CHANGE_TASK_PHASE = 'CHANGE_TASK_PHASE',
  DELETE_TASK = 'DELETE_TASK',
  SINGLE_TASK_UPDATE = 'SINGLE_TASK_UPDATE',

  SAVE_CHECKLIST = 'SAVE_CHECKLIST',
  PUBLISH_CONFIG = 'PUBLISH_CONFIG',

  VALIDATE_ACTIVE_STEP = 'VALIDATE_ACTIVE_STEP',
  UPDATE_PHASE_STATUS = 'UPDATE_PHASE_STATUS',
  VALIDATE_FINISH = 'VALIDATE_FINISH',
}

interface Context {
  initialising: boolean;
  emit: <T, U>(
    event: EditorEvent,
    data?: T
  ) => void | Promise<void> | Promise<U>;
}

export const EditorContext = createContext<Context>({} as Context);

export const useEditorContext = (): Context => {
  return useContext(EditorContext);
};

import React, { FC, ReactNode } from 'react';
import { Center } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { userObj } from 'sop-commons/src/client';
import NoFeed from '../../../../assets/images/no-feed.png';
import FillFormToSeeFeed from './FillFormToSeeFeed';
import { ActivityList } from '../../../../ui-components/ActivityFeed/activity-feed.types';

interface IProps {
  daysAgoHandler: (value: ActivityList) => ReactNode;
  feedDataHandler: (value: ActivityList) => ReactNode;
}

const EmptyFormFeed: FC<IProps> = ({ feedDataHandler, daysAgoHandler }) => {
  const { t } = useTranslation('activity');
  const isDemoAccount = useReactiveVar(userObj)?.entity?.isDemo;

  if (isDemoAccount) {
    return (
      <FillFormToSeeFeed
        feedDataHandler={feedDataHandler}
        daysAgoHandler={daysAgoHandler}
      />
    );
  }

  return (
    <Center h='full' flexDir='column'>
      <div>
        <img
          src={NoFeed}
          style={{ height: '120.69px', width: '160.63px' }}
          alt='no-activity'
        />
      </div>
      <div
        style={{
          fontSize: '16.4px',
          fontWeight: 'bold',
          lineHeight: '1.51',
        }}
      >
        {t('no_activity')}
      </div>
      <div
        style={{
          fontSize: '10.9px',
          fontWeight: '500',
          lineHeight: '1.51',
          color: '#9e9e9e',
        }}
      >
        {t('no_recent_activity')}
      </div>
    </Center>
  );
};

export default EmptyFormFeed;

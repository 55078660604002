import React, { forwardRef, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { UserSignature } from '../../../../../types';
import { signatureConfigs } from '../../../../../configs';

interface IProps {
  value: UserSignature;
  onClick?: () => void;
  isReadOnly?: boolean;
}

const SignatureValue = forwardRef<never, IProps>(
  ({ value, onClick, isReadOnly }, ref) => {
    const config = useMemo(() => {
      return signatureConfigs.find((it) => it.fontFamily === value?.font);
    }, [value]);

    if (!value) {
      return null;
    }

    return (
      <Box
        position='relative'
        marginRight='12px'
        whiteSpace='nowrap'
        color='#1A1D1F'
        {...config}
        userSelect='none'
      >
        {value?.text}

        {!isReadOnly && (
          <Flex
            position='absolute'
            cursor='pointer'
            top='-7px'
            right='-18px'
            alignSelf='center'
            borderRadius='full'
            onClick={onClick}
          >
            <FontAwesomeIcon icon={faCircleXmark as IconProp} />
          </Flex>
        )}
      </Box>
    );
  }
);

SignatureValue.displayName = 'SignatureTrigger';

export default SignatureValue;

import { useCallback } from 'react';
import { useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ConfirmConfig, confirmManager } from './ConfirmManager';

export type UseConfirm = (
  config?: Partial<ConfirmConfig>
) => ReturnType<typeof confirmManager>;

export function useConfirm(): UseConfirm {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return useCallback((config?: Partial<ConfirmConfig>) => {
    return confirmManager({
      theme,
      okText: t('ok'),
      cancelText: t('cancel'),
      zIndex: 2001,
      ...config,
    });
  }, []);
}

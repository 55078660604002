import {
  ApolloQueryResult,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import {
  UPDATE_TRANS_CARD_MUTATION,
  UPDATED_CARD_DATA,
} from '../card-editor.graphql';
import { useCallback } from 'react';
import { getUpdatedContent } from '../isContentChanged';
import { CardInputData } from '../card-editor.types';
import { toArray } from '../../../utils/utils';

type CardTranslateUpdate = (
  values: any,
  input: CardInputData
) => Promise<ApolloQueryResult<any>>;

export const useTranslatedCardUpdate = (): CardTranslateUpdate => {
  const apolloClient = useApolloClient();

  const [updateTranslatedCard] = useMutation(UPDATE_TRANS_CARD_MUTATION);

  return useCallback(async (values: any, input: CardInputData) => {
    const transList = await getUpdatedContent(values, toArray(input.content));
    if (transList) {
      await Promise.all(
        transList.map((transData) => {
          return updateTranslatedCard({
            variables: {
              input: {
                content: transData.content,
                eid: input.eid,
                languageCode: transData.languageCode,
                subTitle: transData.subTitle,
                raw_content: transData.raw_content,
                title: transData.title,
              },
            },
          });
        })
      ).catch((err) => {
        console.log(err);
      });
    }
    return await apolloClient
      .query({
        fetchPolicy: 'network-only',
        query: UPDATED_CARD_DATA,
        variables: {
          eid: input.eid,
        },
      })
      .then((response) => response?.data?.findCardById);
  }, []);
};

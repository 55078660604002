import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import PerformanceHeader from '../assignee/PerformanceHeader';
import LocationCompleteRateGraph from './LocationCompleteRateGraph';
import LocationPerformanceGraph from './LocationPerformanceGraph';
import ListContainer from './ListContainer';

interface IProps {}

const LocationTaskPerformance: FC<IProps> = () => {
  const { t } = useTranslation(['task']);

  return (
    <Flex flexDir='column' gap='20px'>
      <PerformanceHeader title={t('task:locationPerformance')} />

      <LocationPerformanceGraph />

      <LocationCompleteRateGraph />

      <ListContainer />
    </Flex>
  );
};

export default LocationTaskPerformance;

import { CaretDownOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { ReactComponent as AllSvg } from '../../assets/images/searchAll.svg';
import { ReactComponent as CategorySvg } from '../../assets/images/searchCategory.svg';
import { ReactComponent as ChaptersSvg } from '../../assets/images/searchChapters.svg';
import { ReactComponent as BundlesSvg } from '../../assets/images/searchBundles.svg';
import { ReactComponent as RoleSvg } from '../../assets/images/searchRole.svg';
const SearchTypeList = ({ selectedType, setSelectedType }) => {
  return (
    <Popover
      placement='bottomLeft'
      arrowPointAtCenter
      content={
        <div className='search-type-list-container'>
          <div
            className='search-type-item'
            style={{ color: selectedType === 'all' ? '#4d8af0' : '#333b4f' }}
            onClick={() => setSelectedType('all')}
          >
            <AllSvg
              style={{
                marginRight: '11px',
                fill: selectedType === 'all' ? '#4d8af0' : '#333b4f',
                stroke: selectedType === 'all' ? '#4d8af0' : '#333b4f',
              }}
            />
            All
          </div>
          <div
            className='search-type-item'
            style={{
              color: selectedType === 'category' ? '#4d8af0' : '#333b4f',
            }}
            onClick={() => setSelectedType('category')}
          >
            <CategorySvg
              style={{
                marginRight: '11px',
                fill: selectedType === 'category' ? '#4d8af0' : '#333b4f',
              }}
            />{' '}
            Category
          </div>
          <div
            className='search-type-item'
            style={{
              color: selectedType === 'chapter' ? '#4d8af0' : '#333b4f',
            }}
            onClick={() => setSelectedType('chapter')}
          >
            <ChaptersSvg
              style={{
                marginRight: '11px',
                fill: selectedType === 'chapter' ? '#4d8af0' : '#333b4f',
              }}
            />{' '}
            Chapters
          </div>
          <div
            className='search-type-item'
            style={{
              color: selectedType === 'bundles' ? '#4d8af0' : '#333b4f',
            }}
            onClick={() => setSelectedType('bundles')}
          >
            <BundlesSvg
              style={{
                marginRight: '11px',
                fill: selectedType === 'bundles' ? '#4d8af0' : '#333b4f',
              }}
            />{' '}
            Bundles
          </div>
          <div
            className='search-type-item'
            style={{ color: selectedType === 'roles' ? '#4d8af0' : '#333b4f' }}
            onClick={() => setSelectedType('roles')}
          >
            <RoleSvg
              style={{
                marginRight: '11px',
                fill: selectedType === 'roles' ? '#4d8af0' : '#333b4f',
              }}
            />{' '}
            Role
          </div>
        </div>
      }
      // trigger='click'
    >
      <div className='search-modal-type-list-container'>
        {selectedType}
        <CaretDownOutlined style={{ marginLeft: '3px' }} />
      </div>
    </Popover>
  );
};

export default SearchTypeList;

import styled from '@emotion/styled';

export const DateInputWrapper = styled.div<{ hideDays?: boolean }>`
  .react-datepicker {
    border-color: transparent;
    padding-inline: 24px;
    padding-bottom: 24px;
    //padding-top: 16px;
    padding-top: ${({ hideDays }) => (hideDays ? '24px' : '16px')};
    box-shadow: 9px 3px 25px 11px rgba(150, 150, 150, 0.11);
    border-radius: 16px;
  }

  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before {
    border-top-color: #ffffff;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: #ffffff;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-bottom-color: #ffffff;
  }

  .react-datepicker__header {
    background: #ffffff;
    padding-bottom: 14px;
    border-bottom: unset;

    display: ${({ hideDays }) => (hideDays ? 'none' : 'block')};
  }

  .react-datepicker__day-names {
    justify-content: space-evenly;
    padding-top: 16px;

    .react-datepicker__day-name {
      color: #7e818c;
      font-family: 'Inter', sans-serif;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: initial;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    gap: 12px;
  }

  .react-datepicker__day {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 500;

    :hover {
      border-radius: 100%;
    }
  }

  .react-datepicker__day-name {
    width: unset;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: #2a85ff;
    border-radius: 100%;
  }

  // TODO Time select css

  .react-datepicker--time-only {
    padding-inline: 16px;
    padding-bottom: 16px;
    padding-top: 10px;
    border-radius: 12px;
  }

  .react-datepicker__time-container {
    width: 100%;
  }

  .react-datepicker-time__header {
    color: #0f2552;
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
  }

  .react-datepicker__time {
    .react-datepicker__time-box {
      width: unset;
      font-family: 'Inter', sans-serif;
      font-size: 15px;
      font-weight: 500;
    }

    .react-datepicker__time-box {
      ul.react-datepicker__time-list {
        li.react-datepicker__time-list-item--selected {
          background: #2a85ff;

          :hover {
            background: #2a85ff;
          }
        }
      }
    }
  }

  .react-datepicker__input-time-container {
    font-family: 'Inter', sans-serif;
    border-top: 1px solid #e4e5e7;

    margin: 5px 0 0;
    padding-inline: 12px;
    padding-block: 10px;
  }
`;

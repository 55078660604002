import React, { FC, useCallback, useContext } from 'react';
import Lottie from 'react-lottie';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { truncateString } from '../../../utils';

import { useRetakeTraining } from '../../../ui-components/Training';
import { ActionButton } from '../../../ui-components/Confirm';

import finishFlagData from '../../../assets/lotties/finish_flag.json';
import { TrainingPlayContext } from './context';

interface IProps {}

const FinishTraining: FC<IProps> = () => {
  const { t } = useTranslation(['training']);
  const { confirmRetake, trainingData, onFinish } =
    useContext(TrainingPlayContext);
  const retakeTraining = useRetakeTraining();

  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: finishFlagData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const onRetakePress = useCallback(() => {
    return new Promise((resolve) => {
      retakeTraining({
        title: t('training:retake_confirm'),
        onOk: async () => {
          await confirmRetake();
          resolve('success');
        },
        onCancel: () => resolve(null),
      });
    });
  }, [confirmRetake]);

  return (
    <Flex
      height={'100%'}
      flexDirection={'column'}
      alignItems={'center'}
      textAlign={'center'}
      width='100%'
      maxW='962px'
      borderRadius='8px'
      p='32px'
      pt='28px'
      bg='#FFFFFF'
      overflow='auto'
    >
      <Box width='100%' margin='auto'>
        <div
          style={{
            fontSize: 22,
            fontWeight: 'bold',
            color: '#000',
            width: '100%',
            padding: '0 16px 12px',
          }}
        >
          {t('training:progress_modal_congratulations')}!
        </div>
        <Lottie options={defaultUploadLottieOptions} height={220} width={220} />
        <div
          style={{
            fontSize: 16.8,
            fontWeight: '500',
            color: '#000',
            width: '100%',
            padding: '16px',
          }}
        >
          {t('training:progress_modal_have_finished')}{' '}
          <strong>{truncateString(trainingData?.title, 60)}</strong>
        </div>
      </Box>

      <ActionButton
        type='button'
        size='lg'
        width='full'
        variant='outline'
        actionFn={onRetakePress}
        minH={12}
        style={{
          fontSize: 15,
          borderColor: '#000000',
          marginBottom: 12,
          marginTop: 'auto',
          maxWidth: '400px',
        }}
      >
        {t('training:progress_modal_retake')}
      </ActionButton>

      <ActionButton
        type='submit'
        size='lg'
        width='full'
        colorScheme='blue'
        actionFn={onFinish}
        minH={12}
        style={{
          fontSize: 15,
          maxWidth: '400px',
        }}
      >
        {t('training:progress_modal_finish')}
      </ActionButton>
    </Flex>
  );
};

export default FinishTraining;

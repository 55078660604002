import React, { CSSProperties, FC } from 'react';
import { Box, Center, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import ChapterIconComponent from './ChapterIconComponent';
import { ICON_TYPE, IEmojiStructure } from 'delightree-editor';
import { FileType, ISopMedia } from './chapter.types';
import { SopType } from 'types';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface IProps {
  title: string;
  folderName: string;
  minuteRead?: string;
  status?: string;
  onClick?: () => void;
  icon?: IEmojiStructure;
  sopType: SopType;
  fileType: FileType[];
  chapterMediaDetails?: ISopMedia;
  chapterThumbnail?: string;
  data?: any;
}

const ChapterName: FC<IProps> = ({
  folderName,
  title,
  minuteRead,
  onClick,
  icon,
  sopType,
  fileType,
  chapterMediaDetails,
  chapterThumbnail,
  data,
}) => {
  return (
    <Flex
      align='center'
      gap='10px'
      pr='16px'
      maxWidth='100%'
      width='max-content'
      cursor='pointer'
      onClick={onClick}
    >
      <ChapterIconComponent
        chapterName={title}
        sopType={sopType}
        fileType={fileType}
        chapterLink={icon?.native}
        chapterMediaDetails={chapterMediaDetails}
        chapterFileThumbnail={
          data?.type === 'folder'
            ? (data.icon as unknown as string)
            : data?.thumbnail
        }
        folderColor={data.color}
        width='50px'
        boxSize='50px'
        height='50px'
        fontSize='20px'
        playIconSize='2x'
        iconBoxSize={{ height: '40px', width: '40px' }}
        folderVisibility={data?.visibility}
        type={
          icon?.id === ICON_TYPE
            ? 'icon'
            : data?.type === 'folder'
            ? 'folder'
            : 'emoji'
        }
        // type={data?.icon?.id === ICON_TYPE ? 'icon' : data?.type === 'subFolder' ? 'folder' : 'emoji'}
        imageStyle={{
          margin: 'auto',
          width: '50px',
          height: '100%',
          alignSelf: 'center',
          borderRadius: 10,
          objectFit: 'cover',
        }}
      />
      {/* {icon?.native ? (
        <>
          <Center
            border='1px solid #DDDDDD'
            minW='64px'
            minH='64px'
            borderRadius='7px'
          >
            <Text fontSize='40px'>{icon.native}</Text>
          </Center>
        </>
      ) : (
        <Image
          src={getImage(undefined, title)}
          boxSize='64px'
          borderRadius='7px'
        />
      )} */}
      <Flex
        flex={1}
        direction='column'
        pos='relative'
        gap='4px'
        overflow='hidden'
      >
        <Box fontSize='12px' fontWeight='500' style={wrapStyles}>
          {folderName}
        </Box>
        <Tooltip label={title} hasArrow borderRadius='4px'>
          <Text fontSize='15px' fontWeight='600' isTruncated>
            {title}
          </Text>
        </Tooltip>
        {minuteRead && (
          <Box fontSize='12px' fontWeight='500' style={wrapStyles}>
            {minuteRead}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default ChapterName;

import { Box, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext, useState } from 'react';
import TrainingPathContext from '../TrainingPathStore/training-path-context';
import { moveArray, SortEnd } from 'pages/CreateDeck/card';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MilestoneModal from '../../CreateContainer/AddStructure/CreateTraining/MilestoneModal';
import CardEditorModal from 'sub-components/CardEditorModal';
import { cardJsonFormat } from 'utils';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { CardType } from 'types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from '@apollo/client/utilities';
import { CardModal } from 'modal';
import { FormProvider, useForm } from 'react-hook-form';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';
import { defaultContent } from 'sub-components/ChapterEditor/defaultData';
import PreviewModal from 'sub-components/ChapterEditor/PreviewModal';
import SortableList from './SortableList';
import { milestoneModalCloseHandler } from './helper';

const TrainingPathSelectedItems: FC = () => {
  const { t } = useTranslation('training');
  const history = useHistory();
  const trainingPathCtx = useContext(TrainingPathContext);
  const [milestoneModalOpened, setMilestoneModalOpened] = useState(false);
  const [milestoneType, setMilestoneType] = useState('');
  const [selectedMilestone, setSelectedMilestone] = useState<any>(null);
  const [isCardModalOpened, setIsCardModalOpened] = useState(false);
  const [editMilestoneData, setEditMilestoneData] = useState<any[]>([]);
  const [editCardData, setEditCardData] = useState<any[]>([]);
  const router = useRouteMatch<{ id: string }>();
  const userData = useReactiveVar(userObj);
  const [showPreview, setShowPreview] = useState(false);
  const [showPowerupCardPreview, setShowPowerupCardPreview] = useState(false);
  const methods = useForm<IFormInput>({
    defaultValues: {
      title: '',
      content: defaultContent,
      smartPage: false,
      chapterIcon: '',
    },
  });
  const isDraftEdit = !!router?.params?.id;

  const getNoDataContainerHandler = () => {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        border={'2px dashed #2a85ff'}
        backgroundColor={'rgba(42, 133, 255, 0.06)'}
        borderRadius={'7px'}
        padding={'40px 73px 35px 51px'}
      >
        <Text color='#2a85ff' fontSize={'16px'} fontWeight={'500'}>
          {t('added_items_empty_first_phrase')}{' '}
          <FontAwesomeIcon icon={faCirclePlus as IconProp} />{' '}
          {t('added_items_empty_second_phrase')}
        </Text>
      </Box>
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newList = moveArray(trainingPathCtx?.addedItems, oldIndex, newIndex);
    trainingPathCtx?.setAddedItemsHandler(newList);
  };

  const setCardSelectedHandler = (item: any) => {
    if (item?.type === 'card') {
      trainingPathCtx?.setSelectedCardHandler(item);
      trainingPathCtx?.setSelectedDeckHandler(null);
    } else if (item?.type === 'deck') {
      trainingPathCtx?.setSelectedCardHandler(null);
      trainingPathCtx?.setSelectedDeckHandler(item);
    }
  };
  const handleMilestoneHandler = (id: any) => {
    let removeCardIndex = -1;
    let _dragItemsList = cloneDeep(trainingPathCtx?.addedItems);
    for (let i = 0; i < _dragItemsList?.length; i++) {
      const card = _dragItemsList?.[i];
      if (card?.eid === id || card?.tempId === id) {
        removeCardIndex = i;
        break;
      }
    }
    _dragItemsList.splice(removeCardIndex, 1);
    trainingPathCtx?.setAddedItemsHandler(_dragItemsList);
  };

  const handleRemove = (id: string) => {
    let _dragItemsList = cloneDeep(trainingPathCtx?.addedItems);
    let removeCardIndex = -1;
    for (let i = 0; i < _dragItemsList?.length; i++) {
      const card = _dragItemsList?.[i];
      if (card?.eid === id) {
        removeCardIndex = i;
        break;
      }
    }
    _dragItemsList.splice(removeCardIndex, 1);
    trainingPathCtx?.setAddedItemsHandler(_dragItemsList);
  };

  const hoverClickHandler = (item: any, type: 'milestone' | 'formResponse') => {
    if (type === 'milestone') {
      deployEvent(
        AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_ADD_MILESTONE_BUTTON
      );
      setMilestoneModalOpened(true);
      setEditMilestoneData([]);
      setMilestoneType('hoverMenu');
      setSelectedMilestone(item);
    } else {
      const formId = item?.form?.eid;
      if (formId) {
        history.push(`/forms/response/${formId}`);
      }
    }
  };

  const handleCardEdit = (cardId: string) => {
    setIsCardModalOpened(true);
    let filterdData: any[] = trainingPathCtx?.addedItems?.filter(
      (data) => data?.eid === cardId && data?.type === 'card'
    );
    setEditCardData(filterdData);
  };

  const handleMilestoneEdit = (mileId: string) => {
    setMilestoneModalOpened(true);
    setMilestoneType('editMenu');
    let filteredData;
    if (!isNaN(+mileId)) {
      filteredData = trainingPathCtx?.addedItems?.filter(
        (data) => data?.tempId === mileId && data?.type === 'milestone'
      );
    } else {
      filteredData = trainingPathCtx?.addedItems?.filter(
        (data) => data?.eid === mileId && data?.type === 'milestone'
      );
    }
    console.log('FILTERED DATA : : : : : ', filteredData);
    setEditMilestoneData(filteredData);
  };

  const closeCardModal = () => {
    setEditCardData([]);
    setIsCardModalOpened(false);
  };

  const onCardDelete = () => {
    handleRemove(editCardData?.[0]?.eid);
    setTimeout(() => {
      setEditCardData([]);
      setIsCardModalOpened(false);
    });
  };

  const onCardSuccess = () => {};

  const returnedCardDataHandler = (returnedData: any) => {
    // let tempArr = cloneDeep(trainingPathCtx?.addedItems);
    // let _returnedData = cloneDeep(returnedData);
    // _returnedData.data.createCard[0].type = 'card';
    // tempArr.push(_returnedData?.data?.createCard[0]);
    // trainingPathCtx?.setAddedItemsHandler(tempArr);
  };

  const onCardEditSuccess = async (data: any) => {
    let tempArr = cloneDeep(trainingPathCtx?.addedItems);
    let promise = tempArr?.map((item: any) => {
      if (item?.eid === data?.eid) {
        item = { ...data };
        item.type = 'card';
        item.cards = [];
        item.added = true;
      }
      return item;
    });
    let tmpAry = await Promise.all(promise);
    trainingPathCtx?.setAddedItemsHandler(tmpAry);
  };

  const toggleSwitch = (id: string, toggleValue: boolean) => {
    let _addedItems = cloneDeep(trainingPathCtx?.addedItems);
    _addedItems?.map((item) => {
      if (item?.eid === id) {
        item.isMandatory = toggleValue;
      }
    });
    trainingPathCtx?.setAddedItemsHandler(_addedItems);
  };

  const handleCardClick = (item: any) => {
    if (item.type === 'sop') {
      methods?.setValue('title', item?.title, {
        shouldDirty: false,
      });
      methods?.setValue('content', item?.content?.[0]?.tiptap, {
        shouldDirty: false,
      });
      methods?.setValue('smartPage', item?.smartPageEnabled, {
        shouldDirty: false,
      });
      methods?.setValue('chapterIcon', item?.icon?.native);
      setShowPreview(true);
    } else {
      setShowPowerupCardPreview(true);
      let filterdData = trainingPathCtx?.addedItems?.filter(
        (data) => data?.eid === item?.eid && data?.type === 'card'
      );
      setEditCardData(filterdData);
    }
  };

  const milestoneModalCloseHandlerFn = (
    operation: 'close' | 'save',
    data?: any
  ) => {
    milestoneModalCloseHandler(
      operation,
      data,
      setMilestoneModalOpened,
      milestoneType,
      trainingPathCtx,
      userData,
      selectedMilestone
    );
  };

  const getDataContainerHandler = () => {
    return (
      <div>
        <SortableList
          onSortEnd={onSortEnd}
          toggleSwitch={toggleSwitch}
          handleMilestoneHandler={handleMilestoneHandler}
          handleMilestoneEdit={handleMilestoneEdit}
          handleRemove={handleRemove}
          setCardSelectedHandler={setCardSelectedHandler}
          hoverClickHandler={hoverClickHandler}
          handleCardEdit={handleCardEdit}
          handleCardClick={handleCardClick}
        />
        {milestoneModalOpened && ( //
          <MilestoneModal
            isOpen={milestoneModalOpened}
            onClose={milestoneModalCloseHandlerFn}
            editMilestoneData={editMilestoneData}
            isFromEdit={isDraftEdit}
          />
        )}
        {editCardData.length === 0 && isCardModalOpened && (
          <CardEditorModal
            open={isCardModalOpened}
            onClose={closeCardModal}
            onSubmit={onCardSuccess}
            isDeckView={false}
            otherDefaultCardType={'quiz'}
            otherDefaultCardInitialData={cardJsonFormat(
              'quiz',
              'single-choice',
              userData?.entity?.eid,
              '#ffffff',
              null,
              null,
              undefined
            )}
            returnedCardData={returnedCardDataHandler}
            isHide={false}
            fromTraining
          />
        )}
        {editCardData.length > 0 && isCardModalOpened && (
          <CardEditorModal
            open={isCardModalOpened}
            onClose={closeCardModal}
            isEdit={true}
            onSubmit={onCardEditSuccess}
            onCardDelete={onCardDelete}
            cardId={editCardData?.[0]?.eid}
            disabledLayout={[CardType.Quiz]}
            fromTraining
          />
        )}
        <FormProvider {...methods}>
          <PreviewModal
            open={showPreview}
            onClose={() => setShowPreview(false)}
          />
          {showPowerupCardPreview &&
            editCardData?.[0]?.cardType !== 'formCard' && (
              <CardEditorModal
                open={showPowerupCardPreview}
                onClose={() => setShowPowerupCardPreview(false)}
                isEdit={true}
                isHide
                onSubmit={onCardEditSuccess}
                onCardDelete={onCardDelete}
                cardId={editCardData?.[0]?.eid}
                disabledLayout={[CardType.Quiz]}
                isReadOnly
              />
            )}
        </FormProvider>
      </div>
    );
  };
  return trainingPathCtx.addedItems?.length === 0 ? (
    <div>{getNoDataContainerHandler()}</div>
  ) : (
    <div>{getDataContainerHandler()}</div>
  );
};

export default TrainingPathSelectedItems;

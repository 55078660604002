import React, { FC, Ref, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
  usePopoverContext,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import TitleHeader from '../../../CardEditor/TitleHeader';
import FormInput from '../../../../atoms/FormInput';
import { useFormContext } from 'react-hook-form';
import { IFormInput } from '../form.types';

interface FormProps {
  firstFieldRef?: Ref<any>;
  onCancel: () => void;
  onSave: () => void;
}

const Form: FC<FormProps> = ({ firstFieldRef, onCancel, onSave }) => {
  const { t } = useTranslation(['common', 'form']);
  const { isOpen } = usePopoverContext();
  const [hasError, setError] = useState<boolean>(false);

  useEffect(() => {
    !isOpen && setError(false);
  }, [isOpen]);

  function _onSave() {
    // @ts-ignore
    setError(!firstFieldRef?.current?.value?.trim().length);

    // @ts-ignore
    if (firstFieldRef?.current?.value?.trim()) {
      onSave();
    }
  }

  return (
    <Stack spacing={4}>
      <TitleHeader
        title={t('form:section_title')}
        // desc='This field will give title to your card.'
        // titleStyle={{ marginTop: '30px' }}
        isRequired
      />
      <FormControl mt={3} isInvalid={hasError}>
        <FormInput
          size='lg'
          ref={firstFieldRef}
          // value={state}
          // onChange={({ target }) => setState(target.value)}
          onChange={({ target }) => setError(!target.value?.trim().length)}
          // defaultValue={value}
        />
        <FormErrorMessage>
          <span>{t('form:validation.section_title_required')}</span>
        </FormErrorMessage>
      </FormControl>
      <ButtonGroup display='flex' justifyContent='flex-end'>
        <Button size='sm' variant='outline' onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button size='sm' colorScheme='blue' onClick={_onSave}>
          {t('save')}
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

interface IProps {
  currentIndex: number;
}

const SectionEdit: FC<IProps> = ({ currentIndex }) => {
  const { setValue, getValues } = useFormContext<IFormInput>();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);

  const _onOpen = () => {
    // changeValue(getValues(`questions.${currentIndex}.label`));

    if (firstFieldRef.current) {
      // @ts-ignore
      firstFieldRef.current.value = getValues(
        `questions.${currentIndex}.label`
      );
    }
    onOpen();
  };

  const _onSave = () => {
    // @ts-ignore
    setValue(`questions.${currentIndex}.label`, firstFieldRef.current?.value, {
      shouldDirty: true,
    });
    onClose();
  };

  const _onClose = () => {
    onClose();
  };

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={firstFieldRef}
      onOpen={_onOpen}
      onClose={_onClose}
      placement='bottom'
      closeOnBlur
    >
      {/* @ts-ignore */}
      <PopoverTrigger>
        <IconButton size='xs' icon={<EditIcon />} aria-label='' />
      </PopoverTrigger>
      <PopoverContent p={5}>
        <PopoverArrow />
        <Form
          firstFieldRef={firstFieldRef}
          onCancel={onClose}
          onSave={_onSave}
        />
      </PopoverContent>
    </Popover>
  );
};

export default SectionEdit;

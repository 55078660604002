import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Image } from '@chakra-ui/react';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SortingTable } from '../../../sub-components/ChakraTable/SortingTable';
import { DateCell } from '../../Training/Training/TrainingComponent';
import HandbookName from './HandbookName';
import { CardType } from '../../../types';
import FilledHorizontal from '../../../assets/images/filled_horizontal.png';
import TableActionMenu, {
  IActionMenuData,
} from '../../../ui-components/TableActionMenu';
import { Authorize, AuthRole } from '../../../authorization';
import { nFormatter } from '../../../utils/nFormatter';
import EmptyHandbook from '../EmptyHandbook';
import { CardModal, DeckModal } from '../../../modal';
import { CardTypeTrans } from '../../../sub-components/CardEditor/card-editor.data';

const Wrapper = styled.div`
  padding-top: 10px;

  table {
    width: 100% !important;
  }

  table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`;

interface TableColumn {
  eid: string;
  item: DeckModal | CardModal;
  title: string;
  thumbnail: string;
  status: string;
  category: string;
  cardType?: CardType;
  type: string;
  updatedAt: string;
  createdAt: string;
  views: string;
  isActive: boolean;
}

interface IProps {
  isLoading?: boolean;
  handBooks: Array<DeckModal | CardModal>;
  onItemClick?: (item?: DeckModal | CardModal) => void;
  onActionClick: (name: string, item: DeckModal | CardModal) => void;
  menuData?: Array<IActionMenuData>;
  onAddCardClick: () => void;
  onAddDeckClick: () => void;
}

const HandbookListView: FC<IProps> = ({
  isLoading,
  handBooks,
  onActionClick,
  onItemClick,
  menuData,
  onAddCardClick,
  onAddDeckClick,
}) => {
  const { t } = useTranslation(['common', 'handbook', 'card']);

  const getColumns = (isTablet: boolean) => {
    let tableColumns: Column<TableColumn>[] = [
      {
        Header: t('handbook:table.deck_card'),
        accessor: 'title',
        width: '39%',
        Cell: ({ cell: { value, row } }) => {
          const type = row?.original?.type;
          const cardTitle = t('handbook:cardType', {
            value: t(CardTypeTrans[row?.original?.cardType as CardType]),
          });
          return (
            <HandbookName
              item={row.original.item}
              title={value}
              thumbnail={row?.original?.thumbnail}
              category={row?.original?.category}
              index={row.index}
              status={row?.original?.status}
              cardTypeTitle={type === 'card' ? cardTitle : t('common:deck')}
              onClick={onItemClick}
            />
          );
        },
      },
      {
        Header: t('status'),
        accessor: 'status',
        width: '13%',
        Cell: ({ cell: { value } }) => {
          return (
            <Box
              mb='25px'
              fontSize='14px'
              fontWeight='600'
              lineHeight='24px'
              textAlign='center'
              width='max-content'
              px='10px'
              borderRadius='6px'
              bg={value === 'active' ? '#EAFAE4' : 'rgba(255, 188, 153, 0.2)'}
              color={value === 'active' ? '#83BF6E' : '#FF6A55'}
              textTransform='capitalize'
            >
              {value}
            </Box>
          );
        },
      },
      {
        Header: t('handbook:table.number_views'),
        accessor: 'views',
        width: '13%',
        Cell: ({ cell: { value } }) => {
          return (
            <Flex
              mb='25px'
              fontSize='15px'
              fontWeight='600'
              lineHeight='24px'
              textAlign='center'
              width='max-content'
              align='center'
              px='10px'
              color='#1A1D1F'
              gap='4px'
            >
              <FontAwesomeIcon icon={faEye as IconProp} color='#6F767E' />
              {value}
            </Flex>
          );
        },
      },
      {
        Header: t('created_on'),
        accessor: 'createdAt',
        width: '13%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <DateCell
              date={value}
              status={row?.original?.status}
              format='DD MMM'
            />
          );
        },
      },
      {
        Header: t('handbook:table.last_updated'),
        accessor: 'updatedAt',
        width: '12%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <DateCell
              date={value}
              status={row?.original?.status}
              format='DD MMM'
            />
          );
        },
      },
      {
        Header: '',
        accessor: 'eid',
        width: '10%',
        Cell: ({ cell: { row } }) => {
          return (
            <Box mb='25px'>
              <Authorize
                permittedRoles={[AuthRole.ADMIN, AuthRole.SUPER_ADMIN]}
                permittedFor='user'
              >
                <TableActionMenu
                  menuData={menuData}
                  arrowSize={10}
                  closeOnBlur={true}
                  offset={[50, 5]}
                  clickedItem={(_, clickedItem) => {
                    onActionClick?.(clickedItem, row?.original?.item);
                  }}
                >
                  <Image
                    src={FilledHorizontal}
                    style={{
                      opacity: row?.original?.status === 'active' ? '1' : '.5',
                    }}
                    alt='more-icon'
                    cursor='pointer'
                  />
                </TableActionMenu>
              </Authorize>
              <Authorize permittedRoles={[AuthRole.WORKER, AuthRole.MANAGER]}>
                <Image
                  src={FilledHorizontal}
                  style={{
                    opacity: row?.original?.status === 'active' ? '1' : '.5',
                  }}
                  alt='more-icon'
                  cursor='not-allowed'
                />
              </Authorize>
              <Authorize permittedFor={'branch'}>
                <Image
                  src={FilledHorizontal}
                  style={{
                    opacity: row?.original?.status === 'active' ? '1' : '.5',
                  }}
                  alt='more-icon'
                  cursor='not-allowed'
                />
              </Authorize>
            </Box>
          );
        },
      },
    ];
    return tableColumns;
  };

  const getTableData = (isTablet: boolean) => {
    return handBooks?.map<TableColumn>((item) => {
      return {
        item: item,
        eid: item.eid,
        title: item.title?.trim(),
        thumbnail: item.thumbnail,
        status: item.status,
        category: item.category,
        cardType: item.cardType,
        type: item.type,

        updatedAt: item.updatedAt,
        createdAt: item.createdAt,
        views: nFormatter(item.views, 2),
        isActive: item.status?.toLowerCase() === 'active',
      };
    });
  };

  return (
    <Wrapper>
      <SortingTable
        colorScheme='blue'
        emptyData={{
          text: 'No Data Found',
          content: (
            <EmptyHandbook
              onAddCardClick={onAddCardClick}
              onAddDeckClick={onAddDeckClick}
            />
          ),
        }}
        page={1}
        columns={getColumns(false)}
        data={getTableData(false)}
        isLoading={isLoading}
        isResponsive
        isHidePagination={true}
        options={{
          disableSortBy: true,
        }}
      />
    </Wrapper>
  );
};

export default HandbookListView;

import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { OtherFieldInput } from '../AddLocation/add-location.types';

interface IProps extends OtherFieldInput {}

const DeletedField: FC<IProps> = ({ options, value, files }) => {
  const innerValue = useMemo(() => {
    if (files?.length) {
      return files[0]?.name;
    }
    if (options?.length) {
      return options.join(', ');
    }

    return value;
  }, [options, value, files]);

  return (
    <Flex
      bg='gray.100'
      h='48px'
      w='100%'
      borderRadius='12px'
      alignSelf='flex-end'
      align='center'
      px={4}
      fontSize='15px'
      color='#999b9f'
    >
      {innerValue}
    </Flex>
  );
};

export default DeletedField;

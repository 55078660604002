import React, { FC } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { BoxHeader } from '../../../../ui-components';
import { IFormInput } from './create-channel.types';

interface IProps {}

const AddMemberHeader: FC<IProps> = () => {
  const { t } = useTranslation(['setting']);

  const groupName = useWatch<IFormInput, 'groupName'>({
    name: 'groupName',
  });

  return (
    <Flex justify='space-between'>
      <BoxHeader
        // @ts-ignore
        title={t('setting:addMembersToValue', {
          value: groupName.trim(),
        })}
        fontSize='18px'
        color='#CABDFF'
      />
      <AlertDialogCloseButton
        pos='relative'
        borderRadius='full'
        top='0'
        right='0'
      />
    </Flex>
  );
};

export default AddMemberHeader;

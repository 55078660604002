import styled from '@emotion/styled';

export const UserFormResponseWrapper = styled.div`
  flex: 1;

  .user-form-table {
    border-radius: 10px;
    border: 1px solid #eeeeee;
    height: min-content;
  }

  table {
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;

    width: 100%;
    max-width: 100%;
  }

  th,
  td {
    padding: 15px;
    border: 1px solid #eeeeee;
    text-align: start;
  }

  th {
    font-weight: 600;
  }
`;

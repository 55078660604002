import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Question as QuestionData } from '../../create-form/form.types';
import { ReactComponent as CameraIcon } from '../../../../assets/images/camera.svg';
import { QuestionType } from '../../../../types';
import CameraTooltip from './CameraTooltip';

interface IProps {
  question: QuestionData;
}

const ImageNotesInput: FC<IProps> = ({ question }) => {
  if (!question.hasImageNote || question.qType === QuestionType.CHECKBOX) {
    return null;
  }

  return (
    <Flex pt={3} mt={3} borderTop='1px solid #EFEFEF'>
      <CameraTooltip>
        <Flex align='center' gap={2} cursor='pointer'>
          <CameraIcon style={{ width: 24, height: 24 }} />
          <Box color='#6F767E' fontSize='13px' fontWeight='600'>
            Open Camera
          </Box>
        </Flex>
      </CameraTooltip>
    </Flex>
  );
};

export default ImageNotesInput;

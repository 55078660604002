import React, { FC, useEffect, useMemo } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { ChevronDownIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { BoxHeader } from '../../../../ui-components';
import TitleHeader from '../../../../sub-components/CardEditor/TitleHeader';
import { FormInput } from '../../../../atoms';

import { SelectOption } from 'sub-components/CustomDropdowns';

import { IFormInput } from '../../AddLocation/add-location.types';
import { DatePickerComponent } from 'sub-components/tasks/create-task/Scheduler/CustomDatePicker/CustomDatePicker';
import { usersEntityObj } from 'sub-components/Header';
import { AuthRole } from 'sop-commons/src/client';

interface IProps {
  isDisabled?: boolean;
  endDate: moment.Moment;
}

const ProjectStartAndSupervisor: FC<IProps> = ({ isDisabled, endDate }) => {
  const { t } = useTranslation(['location']);

  const usersEntityData = useReactiveVar(usersEntityObj);

  const filteredUsers = useMemo(() => {
    let _members: SelectOption[] = [];
    usersEntityData?.forEach((user) => {
      if (
        user?.type === 'user' &&
        user.status === 'active' &&
        (user.authRole === AuthRole.SUPER_ADMIN ||
          user?.authRole === AuthRole.ADMIN)
      ) {
        _members.push({
          label: user?.name,
          value: user?.eid,
          authRole: user?.authRole,
          eid: user?.eid,
          locations: user?.locations,
          profilePic: user?.profilePic,
          role: user?.role,
        });
      }
    });
    return _members;
  }, [usersEntityData]);

  const { control } = useFormContext<IFormInput>();

  const formatDate = (
    field: ControllerRenderProps<IFormInput, 'startDate'>
  ) => {
    return field.value ? moment(field.value).format('DD MMM YYYY') : '';
  };

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='16px'
        color='#FFBC99'
        title={t('location:projectStart')}
      />

      <Controller
        control={control}
        name='startDate'
        rules={{
          required: 'Estimated go-live date is required',
        }}
        render={({ field, fieldState }) => {
          return (
            // @ts-ignore
            <FormControl isDisabled={isDisabled} isInvalid={!!fieldState.error}>
              <TitleHeader
                title={t('location:estimateGoLiveDate')}
                desc={t('location:estimateGoLiveDateDesc')}
                isRequired
              />

              <Box h={2} />

              <FormInput
                size='md'
                placeholder='Select Go Live Date'
                isReadOnly
                variant='outline'
                {...field}
                value={formatDate(field)}
                rightIcon={
                  <DatePickerComponent
                    onChange={field.onChange}
                    placeholderText='Date'
                    selectedValue={field.value as any}
                    dateFormat='dd'
                    minDate={moment()?.startOf('day')?.toDate()}
                    // minDate={endDate?.toDate()}
                    isSelected={false}
                    customInput={
                      <ChevronDownIcon cursor='pointer' fontSize='25px' />
                    }
                  />
                }
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
      {/* <Controller
        control={control}
        name='supervisors'
        rules={{
          required: 'Supervisor(s) required',
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl>
              <TitleHeader
                title={t('location:selectSupervisor')}
                desc={t('location:selectSupervisorDesc')}
                isRequired
              />

              <Box h={2} />
              <Flex flexDir='column' pos='relative'>
                <CustomSelectComponent
                  options={filteredUsers}
                  value={field.value}
                  placeholder='Select supervisor(s)'
                  showHeader
                  headerTitle='Select supervisor(s)'
                  headerBarColor='#CABDFF'
                  showHeaderCloseIcon
                  showSearchField
                  showDivider
                  bottomOutsideBtn={{
                    show: true,
                    title: 'Add selected supervisors',
                  }}
                  showFilter
                  showSelectAll
                  onChange={(values: string[]) => {
                    console.log('values : ', values);
                    field.onChange(values);
                  }}
                  authRoleFilterOptions={[
                    {
                      label: 'Super Admins',
                      value: AuthRole.SUPER_ADMIN,
                    },
                    {
                      label: 'Admins',
                      value: AuthRole.ADMIN,
                    },
                  ]}
                  selectInputBg={
                    fieldState.error?.message
                      ? 'rgba(255, 188, 153, 0.3)'
                      : '#f4f4f4'
                  }
                />
                {fieldState.error?.message && (
                  <Text m={0} p={0} color='#ff6a55' pos='absolute' top='41px'>
                    {fieldState.error?.message}
                  </Text>
                )}
              </Flex>
            </FormControl>
          );
        }}
      /> */}
    </Flex>
  );
};

export default ProjectStartAndSupervisor;

import { ChannelConfigEntity } from '../../../../types';

export const cleanConfig = (
  config: ChannelConfigEntity
): ChannelConfigEntity => {
  if (config?.type === 'authRole') {
    return {
      type: config.type,
      roles: [],
      authRoles: config.authRoles,
      members: config.members,
    };
  }

  return {
    type: config.type,
    roles: [],
    authRoles: [],
    members: [],
  };
};

import { gql } from '@apollo/client';

export interface TimezoneLocation {
  eid: string;
  name: string;
  timezone: string;
}

export interface TimezoneResponse {
  fetchEntityLocations: TimezoneLocation[];
}

export const locationTimezoneQuery = gql`
  query FetchLocationsTimeZone($eid: String!) {
    fetchEntityLocations(eid: $eid) {
      eid
      name
      timezone
    }
  }
`;

import { Box, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { useTrainingPreview } from 'pages/Training/CreateTrainingPath/Preview';
import { PrimaryButton } from 'atoms';
import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import { cloneDeep } from '@apollo/client/utilities';

interface IProps {
  loadingTraining: boolean;
  trainingData: any;
  isPublishingChanges: boolean;
  createChangeLogLoading: boolean;
  isAnyChange: boolean;
  thirdStepFinalSelections: IThirdStepData;
  publishChangesHelperFn: () => void;
}

const EditTrainingHeading: FC<IProps> = ({
  loadingTraining,
  trainingData,
  isPublishingChanges,
  createChangeLogLoading,
  isAnyChange,
  thirdStepFinalSelections,
  publishChangesHelperFn,
}) => {
  const { t } = useTranslation(['training', 'common']);
  const trainingPreview = useTrainingPreview();
  const history = useHistory();
  const onGoBack = () => {
    history.goBack();
  };
  const getItemsHandler = (_trainingItems: any[]) => {
    let trainingItems = cloneDeep(_trainingItems);
    let _array: any[] = [];
    trainingItems?.map((item) => {
      if (item?.type === 'sop') {
        _array?.push(item?.data);
      } else {
        _array?.push(item);
      }
    });
    console.log('GET ITEMS HANDLER : ', _array);
    return _array;
  };
  return (
    <Flex
      id='edit-training-heading'
      width='100%'
      align='center'
      justify='space-between'
    >
      <div>
        <div className='page-title-wrapper'>
          <Box className='page-title-container' mb='0px'>
            <Text fontSize='28px' fontWeight={700} mt='10px'>
              {t('training:edit_training')}
            </Text>
          </Box>
        </div>
        <div className='breadcrumb-container' onClick={onGoBack}>
          <FontAwesomeIcon icon={faChevronLeft as IconProp} />
          <div style={{ marginLeft: '10px' }}>{t('training:view_back_to')}</div>
        </div>
      </div>
      <Flex gap='16px'>
        <PrimaryButton
          width='auto'
          title={t('training:preview_button')}
          size='lg'
          type='button'
          variant='solid'
          colorScheme='blue'
          disabled={loadingTraining}
          leftIcon={<FontAwesomeIcon icon={faPlay as IconProp} />}
          onClick={() => {
            console.log({ trainingContents: trainingData?.trainingItems });
            trainingPreview({
              trainingContents: getItemsHandler(trainingData?.trainingItems),
            });
          }}
        />
        <PrimaryButton
          width='auto'
          title={t('training:publish_changes')}
          size='lg'
          type='button'
          variant='solid'
          style={{
            backgroundColor: '#83bf6e',
            color: 'white',
          }}
          isLoading={isPublishingChanges || createChangeLogLoading}
          onClick={publishChangesHelperFn}
          disabled={
            loadingTraining ||
            isPublishingChanges ||
            !isAnyChange ||
            trainingData?.title?.trim()?.length < 1 ||
            createChangeLogLoading ||
            (thirdStepFinalSelections?.members?.length === 0 &&
              thirdStepFinalSelections?.locations?.length === 0 &&
              thirdStepFinalSelections?.roles?.length === 0)
          }
        />
      </Flex>
    </Flex>
  );
};

EditTrainingHeading.displayName = 'EditTraining/EditTrainingHeading';

export default EditTrainingHeading;

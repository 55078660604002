import React, { FC } from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export type ViewMode = 'desktop' | 'mobile' | 'tablet';

export interface ViewTypeProps {
  viewMode: ViewMode;
  onModeChange?: (newViewMode: ViewMode) => void;
}

const ViewTypeSelect: FC<ViewTypeProps> = ({ viewMode, onModeChange }) => {
  const { t } = useTranslation(['chapter']);

  return (
    <ButtonGroup variant='solid' colorScheme='gray' spacing='2'>
      <Button
        colorScheme={viewMode === 'mobile' ? 'blue' : 'gray'}
        {...(viewMode === 'mobile' ? {} : { bg: '#E9E9E9' })}
        onClick={() => onModeChange?.('mobile')}
      >
        {t('chapter:mobile')}
      </Button>
      <Button
        colorScheme={viewMode === 'desktop' ? 'blue' : 'gray'}
        {...(viewMode === 'desktop' ? {} : { bg: '#E9E9E9' })}
        onClick={() => onModeChange?.('desktop')}
      >
        {t('chapter:desktop')}
      </Button>
      <Button
        colorScheme={viewMode === 'tablet' ? 'blue' : 'gray'}
        {...(viewMode === 'tablet' ? {} : { bg: '#E9E9E9' })}
        onClick={() => onModeChange?.('tablet')}
      >
        {t('chapter:tablet')}
      </Button>
    </ButtonGroup>
  );
};

export default ViewTypeSelect;

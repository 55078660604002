import React, { FC } from 'react';
import CommonLabel from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';

const Heading: FC = () => {
  return (
    <CommonLabel
      label='All opening tasks'
      fontWeight={600}
      fontSize='20px'
      color='#111315'
    />
  );
};

export default Heading;

import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Image } from '@chakra-ui/react';
import moment from 'moment';
import statusIcon from '../../../assets/images/iPhoneStatusIcons.svg';

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-around;
  //align-items: center;
  background: white;
  padding-top: 10px;

  .notch {
    width: 220px;
    max-width: 40%;
    height: 34px;
    background-color: #151515;
    border-radius: 17px;
    align-items: center;
    justify-content: end;
    gap: 8px;

    .speaker {
      width: 42px;
      height: 6px;
      border-radius: 3px;
      background: #c8c8c8;
    }
    .sensor {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      margin-right: 14px;
      background: #012943;
    }
  }
`;

interface IProps {}

const MobileNotch: FC<IProps> = () => {
  const time = useMemo(() => {
    return moment().format('h:mm');
  }, []);

  return (
    <Wrapper>
      <Box fontWeight='600' flex={1} pl={2} pt={2}>
        {time}
      </Box>
      <Flex className='notch'>
        {/*<Box className='speaker' />*/}
        <Box className='sensor' />
      </Flex>
      <Flex flex={1} justifyContent='end' pt={2}>
        <Image src={statusIcon} height='15px' />
      </Flex>
    </Wrapper>
  );
};

export default MobileNotch;

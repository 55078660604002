import { FC } from 'react';
import { Prompt } from 'react-router-dom';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
  allowNavigation: boolean;
}

const DiscardPrompt: FC<IProps> = ({ allowNavigation }) => {
  const { control } = useFormContext();
  const { t } = useTranslation(['chapter']);
  const { isDirty } = useFormState({
    control: control,
  });

  /**
   * when={isDirty && !allowNavigation}
   */

  return (
    <Prompt message={t('chapter:discardChapterWarn')} when={!allowNavigation} />
  );
};

export default DiscardPrompt;

import React, { FC } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Radio,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { FormInput, PrimaryButton } from '../../../atoms';
import { IFormInput } from './location-setting.types';

interface IProps {
  currentIndex: number;
  onRemove?: () => void;
  length: number;
}

const FieldOptionInput: FC<IProps> = ({ currentIndex, onRemove, length }) => {
  const { t } = useTranslation('form');

  const { control } = useFormContext<IFormInput>();

  return (
    <Flex>
      <Flex h={10} align='center'>
        <Radio size='lg' value='' isReadOnly />
      </Flex>

      <Controller
        control={control}
        name={`options.${currentIndex}.name`}
        rules={{
          required: t('validation.option_required'),
          validate: (value) => {
            if (value?.trim()?.length === 0) {
              return t('validation.option_enter');
            }
          },
        }}
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl ml={3} isInvalid={!!fieldState.error}>
              <FormInput {...field} />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      {length > 1 && (
        <Flex h={10} align='center' pl={2}>
          <IconButton
            aria-label={'remove'}
            size='xs'
            icon={<CloseIcon boxSize='10px' />}
            onClick={onRemove}
          />
        </Flex>
      )}
    </Flex>
  );
};

const FieldOptions: FC = () => {
  const { t } = useTranslation('form');

  const fieldType = useWatch<IFormInput, 'fieldType.value'>({
    name: 'fieldType.value',
  });

  const { fields, remove, append } = useFieldArray<IFormInput, 'options'>({
    name: 'options',
  });

  if (!['dropDown', 'singleChoice'].includes(fieldType)) {
    return null;
  }

  return (
    <>
      {fields?.map((field, index, array) => {
        return (
          <FieldOptionInput
            currentIndex={index}
            key={field.id}
            onRemove={() => remove(index)}
            length={array.length}
          />
        );
      })}

      <PrimaryButton
        width='fit-content'
        variant='link'
        colorScheme='blue'
        title={t('add_option')}
        style={{
          fontSize: 14,
        }}
        onClick={() => {
          append({
            name: '',
          });
        }}
      />
    </>
  );
};

export default FieldOptions;

import React, { FC, useMemo } from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { MessageButton } from '../../atoms';
import { useUserDataSelector } from '../../hooks';
import { toArray } from '../../utils/utils';

import mailIcon from '../../assets/images/UI icon_mail_light.svg';
import shieldIcon from '../../assets/images/shieldIcon.svg';
import locationIcon from '../../assets/images/location.svg';

import { IUserDetail } from './userDetailTypes/userDetailTypes';
import './UserDetails.css';
import BoxHeader from '../BoxHeader';
import { capitalizeFirstLetter } from 'sop-commons/src/utils/stringUtils';
import ContentHeader from '../../sub-components/SearchModal/component/ContentHeader';
import ContentItem from '../../sub-components/SearchModal/component/ContentItem';
import { useHasChatAccess } from 'hooks/useChatRestriction';
import { AuthRole } from 'authorization';

const UserDetails: FC<IUserDetail> = ({
  onClose,
  isOpen,
  userData,
  sendMessageHandler,
}) => {
  const { t } = useTranslation(['common', 'header']);
  const userId = useUserDataSelector((state) => state?.eid);
  const chatAccess = useHasChatAccess();

  const locationName = useMemo(() => {
    if (userData?.location) {
      return userData.location;
    }

    return (
      toArray(userData?.locations)
        .map((it) => it.name)
        .join('\n') || 'N/A'
    );
  }, [userData]);

  const calcAuthRole = useMemo(() => {
    return userData?.authRole?.split(/(?=[A-Z])/).join(' ');
  }, [userData?.authRole]);

  const showUserDetailContainer = () => {
    return (
      <>
        <ContentHeader
          icon={userData?.profilePic}
          title={userData?.name || 'N/A'}
          subtitle={capitalizeFirstLetter(userData?.role || 'N/A')}
        />

        <div>
          <ContentItem
            leftIcon={mailIcon}
            title={userData?.email || userData?.username}
          />

          <ContentItem
            leftIcon={shieldIcon}
            title={calcAuthRole || '- -'}
            contentProps={{
              textTransform: 'capitalize',
            }}
          />

          <ContentItem
            leftIcon={locationIcon}
            title={locationName || '- -'}
            contentProps={{
              whiteSpace: 'pre-line',
            }}
          />
        </div>

        {userId !== userData?.eid && (
          <Tooltip
            label={
              chatAccess({
                authRole: userData?.authRole as AuthRole,
                eid: userData?.eid,
                role: userData?.role,
              })
                ? ''
                : 'This member does not have permission to chat.'
            }
            hasArrow
          >
            <Box mt='12px' pb='8px'>
              <MessageButton
                width='full'
                size='lg'
                colorScheme='blue'
                isDisabled={userData?.status !== 'active' || !chatAccess}
                onClick={sendMessageHandler}
              />
            </Box>
          </Tooltip>
        )}
      </>
    );
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        scrollBehavior='inside'
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          width={{
            sm: '340px',
            md: '740px',
            base: '740px',
            xl: '740px',
            ['2xl']: '740px',
          }}
          minWidth={{
            sm: '340px',
            md: '702px',
            base: '702px',
            xl: '702px',
            ['2xl']: '740px',
          }}
          borderRadius='16px'
          paddingLeft='12px'
          paddingRight='12px'
        >
          <ModalHeader style={{ marginTop: 20 }}>
            <BoxHeader color='#b5e4ca' title={t('header:member_details')} />
          </ModalHeader>
          <ModalCloseButton
            style={{
              marginTop: 30,
              marginRight: 20,
              outline: 'none',
              boxShadow: 'none',
              borderRadius: '50%',
              backgroundColor: '#efefef',
              color: '#6f767e',
            }}
          />
          <ModalBody pb='20px'>{showUserDetailContainer()}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserDetails;

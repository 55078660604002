import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import TemplateSubTasks from './TemplateSubTasks';

import { TemplateContent } from '../board-view/template.graphql'; // TODO

interface IProps {
  task: TemplateContent['tasks'][number];
}

const TemplateTaskItem: FC<IProps> = ({ task }) => {
  return (
    <Flex
      py={4}
      gap={3}
      _notLast={{
        borderBottom: '1px solid #EFEFEF',
      }}
      _first={{
        paddingTop: 0,
      }}
    >
      <Box pt='1.5px' alignSelf='start'>
        <FontAwesomeIcon
          icon={faCircleCheck as IconProp}
          color='#6F767E'
          size='lg'
        />
      </Box>
      <Flex flexDir='column' gap={1}>
        <Box fontSize='15px' fontWeight='400' color='#33383F' lineHeight='24px'>
          {task.title}
        </Box>

        {task.description && (
          <Box fontSize='12px' fontWeight='400' color='#6F767E'>
            {task.description}
          </Box>
        )}

        {task.steps.map((step, index) => (
          <TemplateSubTasks key={index} step={step} />
        ))}
      </Flex>
    </Flex>
  );
};

export default TemplateTaskItem;

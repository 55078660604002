import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Flex, useModalContext } from '@chakra-ui/react';
import { chakraComponents, components, OptionProps } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { useUserData } from 'hooks';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import { ActionButton } from 'ui-components';

import TitleHeader from '../../../../sub-components/CardEditor/TitleHeader';
import { CustomFieldTypeName } from './othe-field-data';
import { OtherFieldInput } from '../../AddLocation/add-location.types';

const Option: FC<OptionProps<SelectOption>> = (props) => {
  const { isSelected, data, children } = props;

  return (
    <components.Option {...props}>
      <Flex align='center' gap='8px' fontWeight='500'>
        <Box
          flex={1}
          fontSize='15px'
          color={isSelected ? 'white' : '#272B30'}
          noOfLines={1}
        >
          {children}
        </Box>
        <Box fontSize='12px' color={isSelected ? 'whitesmoke' : '#6F767E'}>
          {data.fieldType}
        </Box>
      </Flex>
    </components.Option>
  );
};

interface IProps {
  availableField?: string[];
  onFieldAdded?: (value: OtherFieldInput) => void;
}

const CustomFieldContent: FC<IProps> = ({ onFieldAdded, availableField }) => {
  const { t } = useTranslation(['setting', 'location']);
  const { onClose } = useModalContext();
  const entities = useUserData()?.entity?.locationFields || [];

  const [exclude, setExclude] = useState<string[]>([]);
  const [selectedValue, setSelectValue] = useState<SelectOption>();

  const options = useMemo(() => {
    return entities.reduce<SelectOption[]>((acc, entity) => {
      if (!exclude.includes(entity.eid)) {
        acc.push({
          value: entity.eid,
          label: entity.fieldName,
          fieldType: CustomFieldTypeName[entity.fieldType],
        });
      }

      return acc;
    }, []);
  }, [entities, exclude]);

  useEffect(() => {
    setExclude(availableField || []);
  }, [availableField]);

  const onSaveAndAddMore = async () => {
    if (onFieldAdded && selectedValue?.value) {
      onFieldAdded({
        fieldId: selectedValue.value,
        options: [],
        value: '',
        files: [],
      });

      setExclude((prevState) => {
        return [...prevState, selectedValue.value];
      });

      setTimeout(setSelectValue, 0, null);
    }
  };

  const onAddAndClose = async () => {
    if (onFieldAdded && selectedValue?.value) {
      onFieldAdded({
        fieldId: selectedValue.value,
        options: [],
        value: '',
        files: [],
      });
      setTimeout(onClose);
    }
  };

  return (
    <div>
      <TitleHeader title={t('setting:selectFieldType')} />
      <Box height={2} />
      <Dropdown
        placeholder={t('location:searchOrSelectFields')}
        value={selectedValue}
        onChange={(e) => setSelectValue(e)}
        options={options}
        selectStyles={{
          singleValue: {
            fontSize: '14px',
          },
          menuList: {
            padding: '10px',
          },
        }}
        components={{
          Option,
          SingleValue: (valProps) => {
            return (
              <chakraComponents.SingleValue {...valProps}>
                {valProps.children}{' '}
                <Box as='span' color='#6F767E'>
                  ({valProps.data?.fieldType})
                </Box>
              </chakraComponents.SingleValue>
            );
          },
        }}
      />

      <Flex justify='flex-end' gap={2} pt={4}>
        <ActionButton
          variant='outline'
          px='20px'
          fontSize='15px'
          fontWeight='600'
          borderRadius='5px'
          actionFn={onSaveAndAddMore}
          isDisabled={!selectedValue?.value}
        >
          {t('setting:saveAndAddMore')}
        </ActionButton>

        <ActionButton
          colorScheme='blue'
          px='20px'
          fontSize='15px'
          fontWeight='600'
          borderRadius='5px'
          actionFn={onAddAndClose}
          isDisabled={!selectedValue?.value}
        >
          {t('setting:addAndClose')}
        </ActionButton>
      </Flex>
    </div>
  );
};

export default CustomFieldContent;

import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import NotifyUserContent from './NotifyUserContent';

interface IProps {
  isEditMode?: boolean;
  notifyUsers?: boolean;
  onPublishClick?: (canNotify: boolean) => any | PromiseLike<any>;
}

type INotifyUserModal = (data: IProps) => void;

export const useNotifyUserModal = (): INotifyUserModal => {
  const { t } = useTranslation(['common', 'chapter']);
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ isEditMode, notifyUsers, onPublishClick }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <BoxHeader
          title={t(
            isEditMode
              ? 'chapter:updateChapterQues'
              : 'chapter:publishChapterQues'
          )}
          color='#CABDFF'
        />
      ),
      content: (
        <NotifyUserContent
          isEditMode={isEditMode}
          defaultChecked={notifyUsers}
          onPublishClick={onPublishClick}
        />
      ),
      isCentered: true,
      headerProps: {
        pb: 2,
      },
      contentProps: {
        minW: '642px',
        p: '16px 12px',
      },
      footer: null,
    });
  }, []);
};

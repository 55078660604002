import { createContext, FC } from 'react';
import { ApolloError, useMutation, useReactiveVar } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { userObj } from 'sop-commons/src/client';
import {
  CHANGE_PASSWORD_QUERY,
  ChangePassResponse,
  ChangePassVariable,
  UPDATE_PASSWORD_QUERY,
  UpdatePasswordResponse,
  UpdatePasswordVariable,
} from './password-change.graphql';

export interface IFormInput {
  currentPassword?: string;
  password: string;
  confirmPassword: string;
}

interface IPasswordChangeContext {
  onSubmit: (values: IFormInput) => void;
  loading?: boolean;
  userId?: string;
}

export const PasswordChangeContext = createContext<IPasswordChangeContext>({
  onSubmit: () => null,
});

interface IProps {
  userId?: string;
  closeModal?: () => void;
}

export const PasswordChangeProvider: FC<IProps> = ({
  children,
  userId,
  closeModal,
}) => {
  const { t } = useTranslation(['common', 'auth']);
  const methods = useForm<IFormInput>();

  const authUserId = useReactiveVar(userObj)?.eid;

  const toast = useToast({
    duration: 2000,
    position: 'top-right',
    isClosable: true,
  });

  const onCompleted = () => {
    toast({
      title: t('common:success'),
      description: t('auth:pass_update_success'),
      status: 'success',
    });
    closeModal && setTimeout(closeModal, 100);
  };

  const onError = (error: ApolloError) => {
    if (error?.message) {
      toast({
        title: t('common:error'),
        description: error.message,
        status: 'error',
      });
    }
  };

  const [updatePassword, { loading }] = useMutation<
    UpdatePasswordResponse,
    UpdatePasswordVariable
  >(UPDATE_PASSWORD_QUERY, {
    onCompleted: onCompleted,
    onError: onError,
  });

  const [changePassword, { loading: passwordChanging }] = useMutation<
    ChangePassResponse,
    ChangePassVariable
  >(CHANGE_PASSWORD_QUERY, {
    onCompleted: onCompleted,
    onError: onError,
  });

  const onSubmit = async (values: IFormInput) => {
    if (userId === authUserId) {
      return await changePassword({
        variables: {
          currentPassword: values.currentPassword as string,
          newPassword: values.password,
        },
      });
    }
    if (userId) {
      await updatePassword({
        variables: {
          eid: userId,
          password: values.password,
        },
      });
    }
  };

  return (
    <PasswordChangeContext.Provider
      value={{ onSubmit, loading: loading || passwordChanging, userId }}
    >
      <FormProvider {...methods}>{children as never}</FormProvider>
    </PasswordChangeContext.Provider>
  );
};

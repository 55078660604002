import { createContext, FC, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { ChatGroupEntity } from '../../../../../types';
import { updateObject } from '../../../../../utils/objectHelper';
import { toArray } from '../../../../../utils/utils';

import { CHANNEL_DETAILS_QUERY } from './update-channel.graphql';
import { useGetGroup } from './useGetGroup';

interface ChannelContext {
  channel?: ChatGroupEntity;
  loading?: boolean;
  refetch?: Function;
  groupId: string;
}

export const UpdateChannelContext = createContext<ChannelContext>(
  {} as ChannelContext
);

interface IProps {
  groupId: string;
}

export const UpdateChannelProvider: FC<IProps> = ({ children, groupId }) => {
  const [fetchChannel, { refetch, loading, data }] = useLazyQuery<
    Record<'FetchChatGroupDetails', ChatGroupEntity>
  >(CHANNEL_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const { fetchGroup, groupLoading, group } = useGetGroup();

  const channel = useMemo((): ChatGroupEntity | undefined => {
    const _channel = data?.FetchChatGroupDetails;
    if (!_channel) {
      return undefined;
    }
    return updateObject(
      Object.assign({}, cloneDeep(_channel), {
        hasJoined: group?.getHasJoined(),
      }),
      ['details', 'locationIds'],
      toArray(_channel?.details?.locations).map((it) => it.eid)
    );
  }, [data?.FetchChatGroupDetails, group]);

  useEffect(() => {
    if (groupId) {
      fetchChannel({
        variables: {
          guid: groupId,
        },
      });
      fetchGroup(groupId);
    }
  }, [groupId]);

  return (
    <UpdateChannelContext.Provider
      value={{
        channel,
        loading: loading || groupLoading,
        refetch,
        groupId,
      }}
    >
      {children}
    </UpdateChannelContext.Provider>
  );
};

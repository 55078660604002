import React, { FC } from 'react';
import DashboardContainer from '../../sub-components/DashboardContainer';
import { CreateFormComponent } from '../../sub-components/forms';

const CreateFormInternal: FC = () => {
  return <CreateFormComponent />;
};

const CreateForm: FC = () => {
  return (
    <DashboardContainer>
      <CreateFormInternal />
    </DashboardContainer>
  );
};

export default CreateForm;

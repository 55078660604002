import { createStore } from 'zustandStore';
import { immer } from 'zustandStore/middleware';

import { SelectOption } from '../../../atoms';
import { LocationStatus } from '../../../types';

import { LocationItem, LocationListVariable } from './locations.graphql';

import { LocationSort } from '../locations/locations.graphql';

export interface LocationState {
  queryInfo: {
    page: number;
    query?: string;
    sortBy?: LocationSort | SelectOption | null;
    filter: LocationListVariable['filter'];
  };

  messageToUser?: string;
  locationShare?: LocationItem;
  data: LocationItem[];
}

export interface LocationAction extends LocationState {
  setLocationData: (data: LocationItem[]) => void;
  changePage: (page: number) => void;
  onSearch: (query: string) => void;
  onSortChange: (sortBy: LocationState['queryInfo']['sortBy']) => void;
  updateFilter: (
    filterKey: keyof LocationState['queryInfo']['filter'],
    value: never
  ) => void;
  setMessageTo: (userId?: string) => void;
  setLocationShare: (location?: LocationItem) => void;
}

export const useLocationStore = createStore<LocationAction>();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createLocationStore = () => {
  return createStore<LocationAction>()(
    immer((set) => ({
      queryInfo: {
        page: 1,
        filter: {
          locationStatus: 'open' as LocationStatus,
        },
      },

      data: [] as LocationItem[],

      changePage: (newPage) => {
        set((state) => {
          state.queryInfo.page = newPage;
        });
      },
      onSearch: (query) => {
        set((state) => {
          if (state.queryInfo.page > 1) {
            state.queryInfo.page = 1;
          }
          // TODO: page cache here when page is > 1
          state.queryInfo.query = query;
        });
      },
      onSortChange: (sortBy) => {
        set((state) => {
          state.queryInfo.sortBy = sortBy;
        });
      },
      updateFilter: (filterKey, filterValue) => {
        set((state) => {
          state.queryInfo.filter[filterKey] = filterValue;
        });
      },

      setLocationData: (newData) => set({ data: newData || [] }),
      setMessageTo: (userId) => set({ messageToUser: userId }),
      setLocationShare: (location) => set({ locationShare: location }),
    }))
  );
};

import { gql } from '@apollo/client';

export const GET_CHAT_GROUPS = gql`
  query Query {
    FetchEntityChatGroups
  }
`;

export const DELETE_CHAT_GROUP = gql`
  mutation DeleteDefaultChatGroupByGuid($guid: String!) {
    deleteDefaultChatGroupByGuid(guid: $guid) {
      succeed
    }
  }
`;

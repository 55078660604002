import React, { FC, useState } from 'react';
import { Button, Checkbox, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FormInput } from '../../../../../atoms';
import { LauncherTaskStep } from '../../common';

interface IProps {
  data: LauncherTaskStep;
  onUpdateClick?: (value: LauncherTaskStep) => void;
}

const EditStepForm: FC<IProps> = ({ data, onUpdateClick }) => {
  const { t } = useTranslation(['common', 'task']);

  const [value, setValue] = useState(data.title);

  const haveValue = value?.trim();

  return (
    <Flex gap={3}>
      <Checkbox isReadOnly />
      <FormInput
        placeholder={t('task:placeholder.addATODOItem')}
        variant='outline'
        size='lg'
        value={value}
        onChange={(event) => setValue(event.target.value)}
        rightElementProps={{
          width: '5rem',
        }}
        paddingRight={haveValue ? 20 : 4}
        rightIcon={
          haveValue ? (
            <Button
              fontSize='14px'
              onClick={() => onUpdateClick?.({ ...data, title: value })}
              colorScheme='blue'
              variant='ghost'
              paddingX='15px'
              borderRightRadius='12px'
              height='44px'
            >
              {t('common:update')}
            </Button>
          ) : undefined
        }
      />
    </Flex>
  );
};

export default EditStepForm;

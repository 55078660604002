import { Box, Flex, Tab, TabList } from '@chakra-ui/react';
import SearchInput from 'atoms/SearchInput';
import { Authorize, AuthRole } from 'authorization';
import React, { FC } from 'react';
import { FormCategoryFilter } from './form-filter';

interface IProps {
  searchValue: string;
  searchHandler: (e: any) => void;
  category: string[];
  onCategoryChange: (category: string[]) => void;
}

const FormTabList: FC<IProps> = ({
  searchValue,
  searchHandler,
  category,
  onCategoryChange,
}) => {
  return (
    <TabList>
      <Flex justify='space-between' w='full'>
        <Flex>
          <Tab _selected={{ fontSize: '15px', fontWeight: 700 }}>All forms</Tab>
          <Authorize
            permittedFor={'user'}
            permittedRoles={[
              AuthRole.SUPER_ADMIN,
              AuthRole.ADMIN,
              AuthRole.LOCATION_OWNER,
            ]}
          >
            <Tab _selected={{ fontSize: '15px', fontWeight: 700 }}>
              Form responses
            </Tab>
          </Authorize>
        </Flex>
        <Flex gap={3}>
          <Box>
            <SearchInput
              value={searchValue}
              placeholder='Search forms'
              hideShortcuts
              onChange={searchHandler}
            />
          </Box>
          <FormCategoryFilter
            category={category}
            onCategoryChange={onCategoryChange}
          />
        </Flex>
      </Flex>
    </TabList>
  );
};

export default FormTabList;

import { CardTypeOption } from './card-editor.types';
import { CardType } from '../../types';

export const CardTypeList: Array<CardTypeOption> = [
  {
    label: 'Text',
    transKey: 'card:cardType.text',
    value: CardType.Text,
  },
  {
    label: 'Image',
    transKey: 'card:cardType.image',
    value: CardType.Image,
  },
  {
    label: 'Action',
    transKey: 'card:cardType.action',
    value: CardType.Action,
  },
  {
    label: 'Quiz',
    transKey: 'card:cardType.quiz',
    value: CardType.Quiz,
  },
  {
    label: 'Video',
    transKey: 'card:cardType.video',
    value: CardType.Video,
  },
  {
    label: 'Google Form',
    transKey: 'card:cardType.googleForm',
    value: CardType.Form,
  },
  {
    label: 'Form',
    transKey: 'card:cardType.form',
    value: CardType.CustomForm,
  },
  {
    label: 'Mixed',
    transKey: 'card:cardType.mixed',
    value: CardType.Mixed,
  },
  {
    label: 'Milestone',
    transKey: 'card:cardType.milestone',
    value: CardType.Milestone,
  },
];

export const CardTypeLabel: { [key in CardType]: string } = {
  [CardType.Text]: 'Text',
  [CardType.Image]: 'Image',
  [CardType.Action]: 'Action',
  [CardType.Quiz]: 'Quiz',
  [CardType.Video]: 'Video',
  [CardType.Form]: 'Google Form',
  [CardType.CustomForm]: 'Form',
  [CardType.Mixed]: 'Mixed',
  [CardType.Milestone]: 'Milestone',
};

type CartTypeTransKey =
  | 'card:cardType.text'
  | 'card:cardType.image'
  | 'card:cardType.action'
  | 'card:cardType.quiz'
  | 'card:cardType.video'
  | 'card:cardType.googleForm'
  | 'card:cardType.form'
  | 'card:cardType.mixed'
  | 'card:cardType.milestone';

export const CardTypeTrans: { [key in CardType]: CartTypeTransKey } = {
  [CardType.Text]: 'card:cardType.text',
  [CardType.Image]: 'card:cardType.image',
  [CardType.Action]: 'card:cardType.action',
  [CardType.Quiz]: 'card:cardType.quiz',
  [CardType.Video]: 'card:cardType.video',
  [CardType.Form]: 'card:cardType.googleForm',
  [CardType.CustomForm]: 'card:cardType.form',
  [CardType.Mixed]: 'card:cardType.mixed',
  [CardType.Milestone]: 'card:cardType.milestone',
};

import { useReactiveVar } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { usersEntityObj } from 'sub-components/Header';
import { CommonOptions } from '../../../GlobalChat';
import { formatList, getPermissionText } from './CommonExpandAccordion';

interface IProps {
  formattedAuthRoles: string[] | undefined;
  type: 'newChannel' | 'directMessage';
  typeSelectionData: CommonOptions;
}

const AuthRoleJobMembersSelection: FC<IProps> = ({
  formattedAuthRoles,
  type,
  typeSelectionData,
}) => {
  const entityMembers = useReactiveVar(usersEntityObj);
  let memberNames = entityMembers
    ?.filter((member) =>
      typeSelectionData?.selectedOptions?.members?.some(
        (m) => m === member?.eid
      )
    )
    ?.map((member) => member?.name);
  let members = formatList(memberNames);
  let rolesOrJobs = formatList(
    typeSelectionData?.selection === 'authRole'
      ? formattedAuthRoles
      : typeSelectionData?.selectedOptions?.jobs
  );
  let itemCount =
    (typeSelectionData?.selection === 'authRole'
      ? formattedAuthRoles
      : typeSelectionData?.selectedOptions?.jobs
    )?.length || 0;
  return (
    <Box display='block'>
      <Box as='span' color='rgba(42, 133, 255, 1)' fontWeight={700}>
        {members}
      </Box>
      <Box as='span'>&nbsp;</Box>
      <Box as='span' color='rgba(111, 118, 126, 1)'>
        and all members with{' '}
        {typeSelectionData?.selection === 'authRole' ? 'role' : 'job'}
      </Box>
      <Box as='span'>&nbsp;</Box>
      <Box as='span' color='rgba(42, 133, 255, 1)' fontWeight={700}>
        {/* {(typeSelectionData?.selection === 'authRole' ? formattedAuthRoles : typeSelectionData?.selectedOptions?.jobs)?.join(', ')} */}
        {rolesOrJobs}
      </Box>
      <Box as='span'>&nbsp;</Box>
      <Box as='span' color='rgba(111, 118, 126, 1)'>
        {getPermissionText(0)}{' '}
        {type === 'newChannel' ? 'create a channel' : 'start direct message'}.
      </Box>
    </Box>
  );
};

export default AuthRoleJobMembersSelection;

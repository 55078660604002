import { Card } from 'antd';
import React, { FC, useContext } from 'react';
import TrainingPathContext from '../../TrainingPathStore/training-path-context';
import { TrainingPathAssignTextWrapper } from './TrainingPathAssignText.styles';
import threeStepInfo from '../../../../../../assets/images/threeStepInfo.svg';
import threeStepUser from '../../../../../../assets/images/threeStepUser.svg';
import threeStepVisible from '../../../../../../assets/images/threeStepVisible.svg';
import { useTranslation } from 'react-i18next';

const TrainingPathAssignText: FC = () => {
  const { t } = useTranslation('form');
  const trainingPathCtx = useContext(TrainingPathContext);
  const capitalizeFirstLetter = (letter: string) => {
    return letter?.charAt(0)?.toUpperCase() + letter?.slice(1);
  };

  const getRoleStrings = (roles: string[]) => {
    let roleNames = roles?.map(
      (role) =>
        trainingPathCtx?.rolesList?.filter((_role) => _role?.id === role)[0]
          ?.value
    );
    let combinedStrings = roleNames?.map((role) => role)?.join(', ');

    let _selectedRoles: any[] = JSON.parse(JSON.stringify(roleNames));
    let lastElement = _selectedRoles?.pop();
    combinedStrings =
      _selectedRoles.map((role) => capitalizeFirstLetter(role)).join(', ') +
      ` ${t('or_text')} ` +
      capitalizeFirstLetter(lastElement);
    return combinedStrings;
  };

  const getLocationStrings = (locations: string[]) => {
    let locationNames = locations?.map(
      (location) =>
        trainingPathCtx?.locationsList?.filter(
          (_location) => _location?.id === location
        )[0]?.value
    );
    let combinedStrings = locationNames
      ?.map((location) => location)
      ?.join(', ');
    let _selectedLocations: any[] = JSON.parse(JSON.stringify(locationNames));
    let lastElement = _selectedLocations?.pop();
    combinedStrings =
      _selectedLocations
        ?.map((location) => capitalizeFirstLetter(location))
        .join(', ') +
      ` ${t('or_text')} ` +
      capitalizeFirstLetter(lastElement);
    return combinedStrings;
  };

  const getMembersStrings = (members: string[]) => {
    let memberNames = members?.map(
      (member) =>
        trainingPathCtx?.membersList?.filter(
          (_member) => _member?.id === member
        )[0]?.label
    );
    let combinedStrings = memberNames?.map((members) => members)?.join(', ');
    let _selectedMembers: any[] = JSON.parse(JSON.stringify(memberNames));
    let lastElement = _selectedMembers?.pop();
    combinedStrings =
      _selectedMembers
        ?.map((member) => capitalizeFirstLetter(member))
        .join(', ') +
      ` ${t('and_text')} ` +
      capitalizeFirstLetter(lastElement);
    return combinedStrings;
  };

  const getSupervisorsStrings = (supervisors: string[]) => {
    let supervisorNames = supervisors?.map(
      (supervisor) =>
        trainingPathCtx?.supervisorsList?.filter(
          (_superivsor) => _superivsor?.id === supervisor
        )[0]?.label
    );
    let combinedStrings = supervisorNames
      ?.map((supervisor) => supervisor)
      ?.join(', ');
    let _selectedSupervisors: any[] = JSON.parse(
      JSON.stringify(supervisorNames)
    );
    let lastElement = _selectedSupervisors?.pop();
    combinedStrings =
      _selectedSupervisors
        ?.map((supervisor) => capitalizeFirstLetter(supervisor))
        .join(', ') +
      ` ${t('and_text')} ` +
      capitalizeFirstLetter(lastElement);
    return combinedStrings;
  };

  const getRolesText = () => {
    let generatedString: any;
    if (trainingPathCtx?.selectedRoles?.length === 1) {
      generatedString = (
        <>
          <span>{t('training_assign_members_text')} </span>
          <span style={{ fontWeight: '600' }}>
            {capitalizeFirstLetter(
              trainingPathCtx?.rolesList?.filter(
                (_role) => _role?.id === trainingPathCtx?.selectedRoles?.[0]
              )[0]?.value
            )}
          </span>
          <span> {t('new_joinee_text')}</span>
        </>
      );
    } else {
      generatedString = (
        <>
          <span>{t('training_assign_members_text')} </span>
          <span style={{ fontWeight: '600' }}>
            {/* {getRoleStrings(thirdStepSelectionsData?.roles)} */}
            {getRoleStrings(trainingPathCtx?.selectedRoles)}
          </span>
          <span> {t('new_joinees_text')}</span>
        </>
      );
    }
    return generatedString;
  };

  const getLocationsText = () => {
    let generatedString: any;
    if (trainingPathCtx?.selectedLocations?.length === 1) {
      generatedString = (
        <>
          <span>{t('training_assign_members_text')} </span>
          <span style={{ fontWeight: '600' }}>
            {capitalizeFirstLetter(
              trainingPathCtx?.locationsList?.filter(
                (_role) => _role?.id === trainingPathCtx?.selectedLocations?.[0]
              )[0]?.value
            )}
            .
          </span>
          <span> {t('new_joinee_location_text')}</span>
        </>
      );
    } else {
      generatedString = (
        <>
          <span>{t('training_assign_members_text')} </span>
          <span style={{ fontWeight: '600' }}>
            {getLocationStrings(trainingPathCtx?.selectedLocations)}.
          </span>
          <span> {t('new_joinees_location_text')}</span>
        </>
      );
    }
    return generatedString;
  };

  const getMembersText = () => {
    let generatedString: any;
    if (trainingPathCtx?.selectedMembers?.length === 1) {
      generatedString = (
        <>
          <span>{t('training_assign_to')} </span>
          <span style={{ fontWeight: '600' }}>
            {capitalizeFirstLetter(
              trainingPathCtx?.membersList?.filter(
                (_members) =>
                  _members?.id === trainingPathCtx?.selectedMembers?.[0]
              )[0]?.label
            )}
            .
          </span>
        </>
      );
    } else {
      generatedString = (
        <>
          <span>{t('training_assign_to')} </span>
          <span style={{ fontWeight: '600' }}>
            {getMembersStrings(trainingPathCtx?.selectedMembers)}.
          </span>
        </>
      );
    }
    return generatedString;
  };

  const getSupervisorsText = () => {
    let generatedString: any;
    if (trainingPathCtx?.selectedSupervisors?.length === 1) {
      generatedString = (
        <>
          <span style={{ fontWeight: '600' }}>
            {capitalizeFirstLetter(
              trainingPathCtx?.supervisorsList?.filter(
                (_supervisor) =>
                  _supervisor?.id === trainingPathCtx?.selectedSupervisors?.[0]
              )[0]?.label
            )}
          </span>
          <span> {t('he_she_result')}</span>
        </>
      );
    } else if (trainingPathCtx?.selectedSupervisors?.length > 1) {
      generatedString = (
        <>
          <span style={{ fontWeight: '600' }}>
            {getSupervisorsStrings(trainingPathCtx?.selectedSupervisors)}
          </span>
          <span> {t('they_result')}</span>
        </>
      );
    } else {
      generatedString = <span>{t('supervisor_init_text')}</span>;
    }
    return generatedString;
  };
  return (
    <TrainingPathAssignTextWrapper>
      <Card style={{ borderRadius: '7px', height: '100%' }}>
        {trainingPathCtx?.selectedRoles?.length === 0 &&
          trainingPathCtx?.selectedLocations?.length === 0 &&
          trainingPathCtx?.selectedMembers?.length === 0 && (
            <div className='assign-publish-text'>
              <img src={threeStepInfo} />
              <span>{t('assign_init_text')}</span>
            </div>
          )}
        {trainingPathCtx?.selectedRoles?.length > 0 && (
          <div className='assign-publish-text'>
            <img src={threeStepInfo} />
            <span>{getRolesText()}</span>
          </div>
        )}
        {trainingPathCtx?.selectedLocations?.length > 0 && (
          <div className='assign-publish-text'>
            <img src={threeStepInfo} />
            <span>{getLocationsText()}</span>
          </div>
        )}
        {trainingPathCtx?.selectedMembers?.length > 0 && (
          <div className='assign-publish-text'>
            <img src={threeStepInfo} />
            <span>{getMembersText()}</span>
          </div>
        )}
        <div className='assign-publish-text'>
          <img src={threeStepUser} />
          <span>{t('superAdminSupervisor')}</span>
        </div>
        <div className='assign-publish-text'>
          <img src={threeStepUser} />
          <span>{getSupervisorsText()}</span>
        </div>
        <div className='assign-publish-text'>
          <img src={threeStepVisible} />
          <span>{t('training_init_text')}</span>
        </div>
      </Card>
    </TrainingPathAssignTextWrapper>
  );
};

export default TrainingPathAssignText;

import React, { useCallback } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';

import { useConfirm } from '../../ui-components';
import MembersList from '../SearchModal/location/MembersList';
import PreviewHeader from '../forms/component/PreviewHeader';

interface Params {
  eid: string;
  name: string;
}

type UserDetailReturn = (props: Params) => void;

export const useMemberList = (): UserDetailReturn => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(
    ({ eid, name }) => {
      confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <PreviewHeader title={name} color='#CABDFF' />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: <MembersList locationId={eid} />,
        contentProps: {
          py: '16px',
        },
        zIndex: 2005,
        footer: null,
      });
    },
    [confirm]
  );
};

import { PromptAction } from './promt-action';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Any = any;

interface AsyncEventData<D extends Any> {
  action: PromptAction;
  data?: D;
}

type EventListener<D extends Any = Any, R = Any> = (
  data: AsyncEventData<D>
) => Promise<R | void> | void;

const EVENT_NAME = 'Delightree-AlertEventEmitter';

class AlertEventEmitter {
  private events: { [key: string]: EventListener } = {};

  private on<T extends Any>(event: string, listener: EventListener<T>) {
    this.events[event] = listener;

    return () => this.off(event);
  }

  /**
   *
   * This listener is only for route change alert modal
   *
   * */
  listen<T extends Any>(listener: EventListener<T>) {
    return this.on(EVENT_NAME, listener);
  }

  private off(event: string) {
    delete this.events[event];
  }

  private async emit<T extends Any>(event: string, data: AsyncEventData<T>) {
    if (this.events[event]) {
      await this.events[event](data);
    }
  }

  async sendData<T extends Any>(data: AsyncEventData<T>) {
    await this.emit(EVENT_NAME, data);
  }
}

export const AsyncAlertEvent = new AlertEventEmitter();

export default AsyncAlertEvent;

export { PromptAction };

import moment from 'moment-timezone';
import { LauncherLocationDetailTask } from '../types/location-launch-details.types';
import {
  IGroupedLocationData,
  TaskStatusDetail,
  validateDate,
} from './getGroupedData';
import { getUserTimezoneConvertedNow } from './getUserTimezoneConvertedDate';

export interface CurrentTaskStatus {
  currentlyOn: TaskStatusDetail;
}

export const findCurrentTask = (
  groupedData: IGroupedLocationData[],
  loggedInUserTimezone: string
): CurrentTaskStatus | null => {
  const _currentDate = getUserTimezoneConvertedNow(loggedInUserTimezone);
  const currentDate = _currentDate?.startOf('day');
  let latestOverdueTask: LauncherLocationDetailTask | null = null;
  let upcomingTask: LauncherLocationDetailTask | null = null;

  for (const data of groupedData) {
    if (!data.tasks.length) continue;
    const validTasks = data.tasks.filter(
      (task) => validateDate(task.startDate) && validateDate(task.dueDate)
    );
    const sortedTasks = validTasks.sort((a, b) =>
      moment(a.startDate).diff(moment(b.startDate))
    );

    // First case: Find the task that is currently ongoing
    const currentTask = sortedTasks.find(
      (task) =>
        currentDate.isSameOrAfter(moment(task.startDate)) &&
        currentDate.isSameOrBefore(moment(task.dueDate))
    );

    if (currentTask) {
      return {
        currentlyOn: {
          category: data.category,
          title: currentTask.title,
          sinceWhen: currentTask?.startDate,
        },
      };
    }

    // Second case: Determine the latest overdue task
    latestOverdueTask = validTasks
      .filter((task) => currentDate.isAfter(moment(task.dueDate)))
      .reduce(
        (latest: LauncherLocationDetailTask | null, task) =>
          !latest || moment(task.dueDate).isAfter(moment(latest.dueDate))
            ? task
            : latest,
        latestOverdueTask
      );

    // Third case: Determine the closest upcoming task that has not been completed
    upcomingTask = validTasks
      .filter(
        (task) =>
          currentDate.isBefore(moment(task.startDate)) && !task.completedAt
      )
      .reduce(
        (next: LauncherLocationDetailTask | null, task) =>
          !next || moment(task.startDate).isBefore(moment(next.startDate))
            ? task
            : next,
        upcomingTask
      );
  }

  // Return the latest overdue task if no current task is found
  if (latestOverdueTask) {
    return {
      currentlyOn: {
        category: latestOverdueTask.category,
        title: latestOverdueTask.title,
        sinceWhen: latestOverdueTask.dueDate,
      },
    };
  }

  // Return the closest upcoming task if no overdue tasks are found
  if (upcomingTask) {
    return {
      currentlyOn: {
        category: upcomingTask.category,
        title: upcomingTask.title,
        sinceWhen: upcomingTask.startDate,
      },
    };
  }

  return null;
};

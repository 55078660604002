import { DeckModal } from './deck-modal';
import { CardModal } from './card-modal';
import { HandbookData } from '../pages/Handbook/handbook.types';
import { CardEntity, DeckEntity } from '../types';

export class HandbookModal {
  protected _data: Array<DeckModal | CardModal> = [];
  constructor(data: HandbookData[] = []) {
    this._data = data.map((value) => {
      if (value.type === 'deck') {
        return new DeckModal(value as DeckEntity);
      }
      return new CardModal(value as CardEntity);
    });
  }

  get data() {
    return this._data;
  }

  get length() {
    return this._data.length;
  }
}

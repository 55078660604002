import { MemberTrainingItem } from '../types';
import { toArray } from '../../../../utils/utils';
import { MemberTrainingModal } from './member-training';

export class MemberTrainingList {
  protected _data: MemberTrainingModal[];

  constructor(props: MemberTrainingItem[] = []) {
    this._data = toArray(props).map((value) => new MemberTrainingModal(value));
  }

  get data() {
    return this._data;
  }

  get length() {
    return this._data?.length || 0;
  }
}

import { BaseModal } from './base';
import { CardDetailEntity, CardEntity, CardType } from '../types';
import { BaseCardEntity } from '../types/base-card.types';
import { CardTypeTrans } from '../sub-components/CardEditor/card-editor.data';
import { CardModal } from './card-modal';

export class BaseCardModal<
  T extends BaseCardEntity = BaseCardEntity
> extends BaseModal {
  protected transData: T['translations'];
  protected data: Omit<T, 'translations'>;

  private _defaultTitle: string;
  _eid: string;

  constructor(data: BaseCardEntity) {
    super();
    this.transData = data.translations;
    // @ts-ignore
    this.data = data;
    this._defaultTitle = data.title;
    this._eid = data.eid;
  }

  get eid() {
    return this._eid;
  }

  get title() {
    if (this.transData && this.transData[this.language])
      return this.transData[this.language].title;
    return this.data.title;
  }

  get subTitle() {
    if (this.transData && this.transData?.[this.language])
      return this.transData[this.language].subTitle;
    return this.data.subTitle;
  }

  get content() {
    if (this.transData && this.transData?.[this.language])
      return this.transData[this.language].content;
    return this.data.content;
  }

  get raw_content(): string | undefined {
    if (this.transData && this.transData?.[this.language])
      return this.transData[this.language].raw_content;
    // @ts-ignore
    return this.data.raw_content;
  }

  get thumbnail() {
    if (!this.data.thumbnail) {
      // eslint-disable-next-line no-console
      console.warn(
        `thumbnail not available for ${this.type} ${this.data.title}`
      );
      return 'https://sop-uploads-staging.s3.amazonaws.com/extras/1002.png';
    }
    return this.data.thumbnail;
  }

  get cardType() {
    return this.data.cardType;
  }

  get createdBy() {
    return this.data.createdBy;
  }

  get status() {
    return this.data.status;
  }

  get category() {
    return this.data.category;
  }

  // TODO translation and date format
  get updatedAt() {
    return this.data.updatedAt;
  }

  // TODO translation and date format
  get createdAt() {
    return this.data.createdAt;
  }

  get views() {
    return this.data.views ?? 0;
  }

  get form() {
    return this.data.form;
  }

  get trainings() {
    // @ts-ignore
    return this.data.trainings;
  }

  get type() {
    return this.data.type;
  }
}

export class ChapterModal {
  protected _data: Array<any> = [];
  constructor(data: BaseCardEntity[] = []) {
    this._data = data.map((value) => {
      return new CardModal(value as CardEntity);
    });
  }

  get data() {
    return this._data;
  }

  get length() {
    return this._data.length;
  }
}

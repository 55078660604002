import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { Center } from '@chakra-ui/react';

import {
  SOP_ITEM_DETAILS,
  SopDetailResponse,
  UPDATE_VIEW,
} from '../../../pages/Chapters/ChapterView/chapter.graphql';

import { Loader } from '../../../sub-components';
import ChapterViewContainer from './ChapterViewContainer';

interface IProps {
  chapterId: string;
  title: string;
  onReadClick: () => Promise<void>;
  canOverride?: boolean;
}

const ChapterContent: FC<IProps> = ({
  chapterId,
  title,
  onReadClick,
  canOverride,
}) => {
  const { data, loading, refetch } = useQuery<SopDetailResponse>(
    SOP_ITEM_DETAILS,
    {
      fetchPolicy: 'network-only',
      variables: {
        eid: chapterId,
        override: canOverride,
      },
      onError: (error) => {
        // for (let err of error.graphQLErrors) {
        //   if (err.extensions.code === 'FORBIDDEN') {
        //     setUnauthorised('UNAUTHORISED');
        //     break;
        //   }
        //   if (err.extensions.code === 'BAD_USER_INPUT') {
        //     setUnauthorised('NOT_FOUND');
        //     break;
        //   }
        // }
      },
    }
  );

  if (loading) {
    return (
      <Center h='full' minHeight='50vh'>
        <Loader size='xl' />
      </Center>
    );
  }

  return (
    <ChapterViewContainer
      data={data?.SopById}
      title={title}
      onReadClick={onReadClick}
    />
  );
};

export default ChapterContent;

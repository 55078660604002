// @ts-ignore
import React, {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Switch,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ChevronDownIcon } from '@chakra-ui/icons';

import FormInput from '../../atoms/FormInput';
import PrimaryButton from '../../atoms/PrimaryButton';
import SearchInput from '../../atoms/SearchInput';
import TitleHeader from '../../sub-components/CardEditor/TitleHeader';
import DashboardContainer from '../../sub-components/DashboardContainer';
import './CreateDeck.scss';
import { ReactComponent as AddIcon } from '../../assets/images/addIcon.svg';
import { ReactComponent as EyeIcon } from '../../assets/images/eyeIcon.svg';
import AddIconImg from '../../assets/images/add.png';
import SearchIconImg from '../../assets/images/search.png';

import TableActionMenu from '../../ui-components/TableActionMenu';
import { ReactComponent as IIcon } from '../../assets/images/iIcon.svg';

import Card, { moveArray, SortableCardList, SortEnd } from './card';
import { getDeckById } from 'sop-commons/Query/Sop';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { truncateString } from '../../utils';
import VisibilityModal from '../Handbook/setVisibilityModal';
import ListPagination from '../../atoms/ListPagination';
import CardEditorModal from '../../sub-components/CardEditorModal';
import UpdateDeckAddCards from './UpdateDeckAddCards/UpdateDeckAddCards';
import CardDeckPreviewModal from '../../sub-components/CardDeckPreviewModal/CardDeckPreviewModal';
import {
  CARD_LIST_QUERY,
  CardListResponse,
  CREATE_DECK_MUTATION,
  FindEditDeckResponse,
  getDeckForEdit,
  UPDATE_DECK_MUTATION,
} from './create-deck.graphql';
import { useSearchParam } from '../../hooks';
import Loader from '../../sub-components/Loader';
import { CreatedDeckModal } from './created-deck.modal';
import { CardDetailModal } from '../../modal';

interface IProps {
  isEdit?: boolean;
}

interface IFormInput {
  deckName: string;
}

interface IParams {
  category?: string;
  deckId?: string;
}

const CreateDeck: FC<IProps> = ({ isEdit }) => {
  const { t } = useTranslation(['common', 'deck', 'handbook']);
  const creatingRef = useRef(false);
  const toast = useToast({
    position: 'top-right',
  });
  const {
    categoryName,
    n: queryStr,
    from: backToTitle,
  } = useSearchParam<{
    n: string;
    categoryName?: string;
    from?: string;
  }>();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isDirty },
    getValues,
    watch,
    reset,
  } = useForm<IFormInput>();
  const history = useHistory();
  const [sortedEids, setsortedEids] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedCards, setSelectedCards] = useState([]);
  const [newSetVisibility, setNewSetVisibility] = useState<{
    locations: string[];
    roles: string[];
    users: string[];
    condition?: string | null | undefined;
    visibility?: string;
  }>({ locations: [], roles: [], users: [], visibility: '', condition: '' });
  const [showModal, setShowModal] = useState(false);
  const [visiblity, setVisiblity] = useState('public');
  const [isCardDeckPreviewModalOpen, setIsCardDeckPreviewModalOpen] =
    useState(false);
  const [visibleTo, setVisibleTo] = useState({
    users: [],
    roles: [],
    locations: [],
    condition: '',
  });

  const [cardData, setCardData] = useState({
    title: '',
    category: '',
    visibility: '',
    description: '',
    thumbnail: '',
    entityId: '',
    cards: [],
  });
  const { category: selectedCategory, deckId } =
    useRouteMatch<IParams>().params || {};
  const [isLoading, setIsLoading] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [page, setPage] = useState(1);
  const [createDecks] = useMutation(CREATE_DECK_MUTATION);
  const [updateDecks] = useMutation(UPDATE_DECK_MUTATION);
  const userData = useReactiveVar(userObj);
  const [isCardModalOpened, setIsCardModalOpened] = useState(false);
  const entityId = userData?.entity?.eid;

  const [cardSelected, setCardSelected] = useState<any>(null);
  const [isMenuModalOpened, setIsMenuModalOpened] = useState(false);
  const [getDeckCards, { data: deckCardsData }] =
    useLazyQuery<FindEditDeckResponse>(getDeckForEdit, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (isEdit) {
      getDeckCards({
        variables: {
          eid: deckId,
        },
      });
    }
  }, [deckId]);

  const [getAllCards, { data, refetch, loading }] =
    useLazyQuery<CardListResponse>(CARD_LIST_QUERY, {
      fetchPolicy: 'cache-and-network',
    });

  const cardList = useMemo(() => {
    return new CreatedDeckModal(data?.cardsPagination?.items).data;
  }, [data?.cardsPagination]);

  const cards = cardList;

  const getAllCardsHandler = () => {
    let filter: Record<string, string> = {};
    if (query) {
      filter.query = query;
    }
    getAllCards({
      variables: {
        page: page,
        sort: '_ID_DESC',
        filter: { ...filter },
      },
    });
  };

  useEffect(() => {
    getAllCardsHandler();
  }, [query, page]);

  useEffect(() => {
    const subscription = watch((value: any, { name }: any) => {
      if (name === 'deckName') {
        setCardData({ ...cardData, title: value.deckName });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
    if (queryStr) {
      setValue('deckName', queryStr, {
        shouldDirty: true,
      });
    }
  }, []);

  useEffect(() => {
    if (data?.cardsPagination?.count) {
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }
  }, [data?.cardsPagination?.items, loading, page]);

  useEffect(() => {
    if (deckCardsData?.findDeckById?.cards && isEdit) {
      setNewSetVisibility(deckCardsData?.findDeckById?.visibleTo);
      const cards = deckCardsData?.findDeckById?.cards;
      const sortCardIds = [];
      const alreadySelectedCards = [];
      cards.forEach((card) => {
        sortCardIds.push(card.eid);
        alreadySelectedCards.push(card);
      });
      setsortedEids(sortCardIds);
      setSelectedCards(
        [...alreadySelectedCards].map((val) => new CardDetailModal(val))
      );
      if (deckCardsData?.findDeckById?.status === 'inactive') {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      if (!isDirty) {
        setValue('deckName', deckCardsData?.findDeckById?.title?.trim(), {
          shouldDirty: true,
        });
      }
    }
  }, [deckCardsData?.findDeckById?.cards?.length, deckId]);

  const onClickCreate = () => {
    openCardModal();
    // if (selectedCategory) {
    //   history.push(`/${selectedCategory}/card/create?deckView=true`);
    // } else {
    //   history.push('/card/create?deckView=true');
    // }
  };

  useEffect(() => {
    if (
      data?.cardsPagination?.pageInfo?.hasNextPage &&
      getSearchCardList()?.length === 0
    ) {
      setPage((prevValue) => prevValue + 1);
    }
  }, [selectedCards?.length]);

  const handleCheck = (id: string) => {
    let x = selectedCards;

    const card = cards.filter((card) => card.eid === id)[0];
    const newList = cards.filter((card) => card.eid !== id);

    if (card) {
      x.push(card);
      setSelectedCards(x);

      // setSortingCardList(x);
      sortedEids.push(id);
      setsortedEids(sortedEids);
      setToggleState(!toggleState);
    }
  };

  const handleRemove = (id: string) => {
    let tcards = cardList;
    setToggleState(!toggleState);
    console.log('Selceted cards', [...selectedCards], sortedEids);
    const selected = selectedCards;
    let removeCardIndex = -1;
    const card = selected.filter((card) => card.eid === id)[0];
    const newList = selected.filter((card) => card.eid !== id);
    for (let i = 0; i < selectedCards?.length; i++) {
      const card = selectedCards[i];
      if (card?.eid === id) {
        removeCardIndex = i;
        break;
      }
    }
    selected.splice(removeCardIndex, 1);
    const index = sortedEids.indexOf(id);
    sortedEids.splice(index, 1);

    console.log('New List', selected, sortedEids);
    // setSortingCardList([...selected]);
    setsortedEids([...sortedEids]);
    setSelectedCards([...selected]);
    setToggleState(!toggleState);
  };

  // const

  const onFinish = (values) => {
    if (creatingRef.current) {
      return;
    }

    const category = selectedCategory || categoryName;
    creatingRef.current = true;
    setIsLoading(true);
    if (values?.deckName && entityId && sortedEids?.length) {
      let input: Record<string, any> = {
        title: values?.deckName,
        thumbnail: selectedCards?.[0]?.thumbnail,
        entityId: entityId,
        cards: sortedEids,
      };
      if (category) {
        input.category = category;
      }
      if (!isEdit) {
        input.visibility = newSetVisibility.visibility
          ? newSetVisibility.visibility
          : 'public';
        // if (
        //   !newSetVisibility?.users?.length &&
        //   !newSetVisibility?.roles?.length &&
        //   !newSetVisibility?.locations?.length
        // ) {
        //   input.visibility = newSetVisibility.visibility ? newSetVisibility.visibility : 'public';
        // }
        input.visibleTo = {
          users: newSetVisibility?.users,
          roles: newSetVisibility?.roles,
          locations: newSetVisibility?.locations,
          condition: newSetVisibility?.condition,
        };
        if (!input?.visibleTo?.condition) {
          delete input.visibleTo.condition;
        }
      }
      if (isEdit) {
        delete input?.entityId;
        input.eid = deckId;
        if (isVisible) {
          input.status = 'active';
        } else {
          input.status = 'inactive';
        }
        updateDecks({
          variables: {
            input: {
              ...input,
            },
          },
        }).then((response) => {
          setIsLoading(false);
          creatingRef.current = false;
          reset();
          toast({
            title: t('deck:deck_update_success_toast'),
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          // history.goBack();
          if (!isEdit) {
            if (selectedCategory) {
              history.replace(`/handbook/${selectedCategory}`);
            } else {
              history.replace(`/handbook`);
            }
          } else {
            if (selectedCategory) {
              history.replace(`/${selectedCategory}/deck/${deckId}`);
            } else {
              history.replace(`/deck/${deckId}`);
            }
          }
        });
      } else {
        createDecks({
          variables: {
            input: {
              ...input,
            },
          },
        })
          .then(() => {
            setIsLoading(false);
            creatingRef.current = false;
            reset();
            toast({
              title: t('deck:deck_create_success_toast'),
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            if (selectedCategory) {
              history.replace(`/handbook/${selectedCategory}`);
            } else {
              history.replace(`/handbook`);
            }
            // history.replace('/handbook');
          })
          .catch((err) => {
            setIsLoading(false);
            creatingRef.current = false;
            console.log(err);
          });
      }
    } else {
      creatingRef.current = false;
      setIsLoading(false);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newList = moveArray(selectedCards, oldIndex, newIndex);
    setSelectedCards(newList);
    setsortedEids(newList.map((value) => value.eid));
  };

  const onSwitchClick = (e) => {
    setIsVisible(e.target.checked);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    const value = e.target.value;
    setQuery(value);
  };

  const getSearchCardList = () => {
    // if (query) {
    //   let x = cardList.filter((card) => {
    //     return (
    //       card.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
    //       sortedEids.indexOf(card.eid) === -1
    //     );
    //   });
    //   return x;
    // } else {
    let x = cardList?.filter((card) => {
      return sortedEids.indexOf(card.eid) === -1;
    });
    return x;
    // return cards;
    // }
  };

  const openCardModal = () => {
    setIsCardModalOpened(true);
  };

  const closeCardModal = () => {
    setIsCardModalOpened(false);
  };

  const onCardSuccess = (selectedCard) => {
    // if (selectedCard) {
    //   setCardSelected(selectedCard);
    // }
    if (refetch) {
      refetch();
    }
  };

  const onApply = (userToSend, locationToSend, roleToSend, visibility) => {
    if (visibility === true) {
      setVisiblity('public');
    } else {
      setVisiblity('private');
    }
    if (userToSend?.length) {
      visibleTo.users = userToSend;
    }
    if (locationToSend?.length) {
      visibleTo.locations = locationToSend;
    }
    if (roleToSend?.length) {
      visibleTo.roles = roleToSend;
    }
    setVisibleTo(visibleTo);
  };

  const onGoBack = () => history.goBack();

  const previewHandler = (card: any) => {
    setCardSelected(card);
    setIsCardDeckPreviewModalOpen(true);
  };

  const clickedItemHandler = (data: string) => {
    if (data === 'search-handbook') {
      setIsMenuModalOpened(true);
    } else {
      onClickCreate();
    }
  };

  const cardsToBeAddedHandler = (cards) => {
    cards.forEach((card) => {
      handleCheck(card.eid);
    });
  };

  const returnedCardDataHandler = (returnedData) => {
    let formattedReturnedData = returnedData.data.createCard[0];

    if (!formattedReturnedData?.eid) {
      return;
    }

    setSelectedCards((prevState = []) => {
      prevState.push(new CardDetailModal(formattedReturnedData));
      return [...prevState];
    });

    setsortedEids((prevState = []) => {
      prevState.push(formattedReturnedData.eid);
      return [...prevState];
    });
  };

  const cardDeckPreviewModalHandler = () => {
    setIsCardDeckPreviewModalOpen(false);
  };

  const title = useMemo(() => {
    let t: string = isEdit
      ? deckCardsData?.findDeckById?.title
      : selectedCategory;
    return truncateString(t?.trim(), 40);
  }, [isEdit, selectedCategory, deckCardsData?.findDeckById?.title]);

  const getTextAllSelect = () => {
    let str = t('deck:all_card_selected');
    return str;
  };

  const getSearchCardListHandler = () => {
    let _getSearchCardList: any[] = getSearchCardList();
    if (_getSearchCardList && _getSearchCardList?.length > 0) {
      return getSearchCardList()?.map((card) => (
        <Card
          key={card.eid}
          card={card}
          handleCheck={handleCheck}
          previewHandler={previewHandler}
          isEdit={isEdit}
          cardStyleType={'addCardsCreateDeck'}
        />
      ));
    } else if (loading) {
      return (
        <Center h='100%'>
          <Loader />
        </Center>
      );
    } else if (
      data?.cardsPagination?.items?.length > 0 &&
      _getSearchCardList &&
      _getSearchCardList?.length === 0
    ) {
      return (
        <Center h='100%' fontWeight='600' fontSize='16px'>
          {getTextAllSelect()}
        </Center>
      );
    } else {
      return (
        <Center h='100%' fontWeight='600' fontSize='16px'>
          {t('common:no_data_found')}
        </Center>
      );
    }
  };

  return (
    <DashboardContainer>
      <div className='create-deck-container'>
        <Flex w='100%' align='center' justify='space-between'>
          <div style={{ overflow: 'hidden' }}>
            <div className='page-title-wrapper'>
              <div
                className='page-title-container'
                style={{ marginBottom: '0px' }}
              >
                {t(isEdit ? 'deck:update_deck' : 'deck:create_deck')}
              </div>
            </div>
            <div className='breadcrumb-container' onClick={onGoBack}>
              <FontAwesomeIcon icon={faChevronLeft as IconProp} />
              <div style={{ marginLeft: '10px' }}>
                {t('common:back_to_value', { value: backToTitle || title })}
              </div>
            </div>
          </div>
          <Flex gap={4}>
            <PrimaryButton
              width={'146px'}
              title={t('cancel')}
              size='lg'
              type='button'
              variant='solid'
              style={{ backgroundColor: 'white' }}
              onClick={onGoBack}
            />
            <PrimaryButton
              width={'165px'}
              title={t(!isEdit ? 'publish' : 'update')}
              size='lg'
              type='button'
              variant='solid'
              style={{ backgroundColor: '#83bf6e', color: 'white' }}
              isLoading={isLoading}
              disabled={
                !sortedEids?.length || !isDirty || !getValues('deckName')
              }
              onClick={handleSubmit(onFinish)}
            />
          </Flex>
        </Flex>
        <form onSubmit={handleSubmit(onFinish)} className='deck-cover'>
          <Box className='deck-left-card' width={!isEdit ? '58%' : '100%'}>
            <Flex align='flex-end' width='100%' gap='16px'>
              <Box className='deck-name' flex={1}>
                <TitleHeader
                  title={t('deck:deck_name')}
                  desc={t('deck:deck_name_desc')}
                  titleStyle={{ marginTop: '30px' }}
                  isRequired
                />
                <FormControl mt={3} isInvalid={!!errors?.deckName}>
                  <FormInput
                    id='deckName'
                    placeholder={t('deck:enter_deck_name')}
                    size='lg'
                    {...register('deckName', {
                      required: t('deck:validation.deck_name_required'),
                      validate: (value) =>
                        value.trim().length !== 0 ||
                        t('validation.name_invalid'),
                    })}
                  />
                  <FormErrorMessage>
                    {errors?.deckName && errors?.deckName?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              {isEdit && (
                <Flex className='switch-cover' justify='flex-end' mb='10px'>
                  <Switch
                    size='lg'
                    isChecked={isVisible}
                    onChange={onSwitchClick}
                    style={{ marginRight: '10px' }}
                  />
                  <p>{t('active')}</p>
                  <Tooltip
                    hasArrow
                    label={t('deck:active_help_txt')}
                    placement='bottom-start'
                  >
                    <p style={{ marginRight: '10px', paddingTop: '4px' }}>
                      <IIcon />
                    </p>
                  </Tooltip>
                </Flex>
              )}
            </Flex>
            {!isEdit && (
              <Box mt='32px' className='add-card-cover'>
                <TitleHeader
                  title={t('deck:add_card_title')}
                  desc={t('deck:add_card_desc')}
                  isRequired
                />

                <div className='search-row'>
                  <SearchInput
                    size='lg'
                    width='100%'
                    hideShortcuts={true}
                    onChange={handleSearchChange}
                    placeholder={t('deck:search_in_card')}
                  />

                  <PrimaryButton
                    leftIcon={<AddIcon />}
                    title={t('deck:new_card')}
                    type='button'
                    variant='solid'
                    colorScheme='blue'
                    onClick={onClickCreate}
                    className='add-deck-btn'
                  />
                </div>
              </Box>
            )}
            {isEdit && (
              <>
                <Flex w='100%' justify='space-between' mt='30px'>
                  <Flex align='center' fontSize='20px' fontWeight='600'>
                    {t('deck:cards_in_deck')}
                  </Flex>
                  <Flex align='center' gap='20px'>
                    <TableActionMenu
                      menuData={[
                        {
                          name: t('handbook:add_new_card'),
                          value: 'add-new-card',
                          icon: AddIconImg,
                        },
                        {
                          name: t('deck:search_handbook'),
                          value: 'search-handbook',
                          icon: SearchIconImg,
                        },
                      ]}
                      arrowSize={10}
                      closeOnBlur={true}
                      offset={[-200, 10]}
                      clickedItem={(_, clickedItem) =>
                        clickedItemHandler(clickedItem)
                      }
                    >
                      <Center
                        px='20px'
                        bg='#2a85ff'
                        style={{
                          cursor: 'pointer',
                          height: '100%',
                          borderRadius: '7px',
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '15px',
                        }}
                      >
                        {t('handbook:add_card')}
                        <ChevronDownIcon w={6} h={6} />
                      </Center>
                    </TableActionMenu>
                    <PrimaryButton
                      width='auto'
                      leftIcon={<EyeIcon />}
                      title={t('deck:set_visibility')}
                      variant='solid'
                      size='lg'
                      style={{
                        backgroundColor: 'white',
                        color: '#2a85ff',
                        border: '2px solid #dddddd',
                      }}
                      onClick={() => setShowModal(true)}
                    />
                  </Flex>
                </Flex>
                <Flex gap={1}>
                  <Trans
                    t={t}
                    i18nKey='common:total_cards'
                    values={{ value: selectedCards.length }}
                    components={[
                      <div className='total-text' key='total-text' />,
                      <div className='no-of-cards' key='total-count' />,
                    ]}
                  />
                </Flex>
              </>
            )}

            <div className='card-list-cover'>
              {isEdit ? (
                <SortableCardList
                  useDragHandle
                  lockAxis='y'
                  items={selectedCards}
                  extraProps={{ fromDeck: true, handleRemove: handleRemove }}
                  onSortEnd={onSortEnd}
                />
              ) : (
                getSearchCardListHandler()
              )}
            </div>
            {!isEdit &&
              ((data?.cardsPagination?.pageInfo?.currentPage === 1 &&
                data?.cardsPagination?.pageInfo?.hasNextPage) ||
                data?.cardsPagination?.pageInfo?.currentPage > 1) && (
                <ListPagination
                  onPageChange={(page) => setPage(page)}
                  data={cardList}
                  totalRegisters={data?.cardsPagination?.count}
                  page={page}
                />
              )}
          </Box>

          {!isEdit && (
            <Box className='deck-right-card' w='58%'>
              <Flex
                align='center'
                justify='space-between'
                mb='20px'
                className='deck-cards-top'
              >
                <div className='title'>{getValues('deckName')}</div>
                <div className='visibility-check-cover'>
                  <div
                    className='switch-label-cover'
                    onClick={() => setShowModal(true)}
                  >
                    <EyeIcon />
                    <p>{t('deck:set_visibility')}</p>
                  </div>
                </div>
              </Flex>

              <div className='deck-cards-list'>
                <SortableCardList
                  useDragHandle
                  lockAxis='y'
                  items={selectedCards}
                  extraProps={{
                    handleRemove: handleRemove,
                    previewHandler: previewHandler,
                    fromDeck: true,
                    isEdit: true,
                    cardStyleType: 'addedCardsCreateDeck',
                  }}
                  onSortEnd={onSortEnd}
                />
              </div>
            </Box>
          )}
        </form>
      </div>
      {showModal && (
        <VisibilityModal
          isOpen={showModal}
          handleClose={() => {
            setShowModal(!showModal);
            if (isEdit) {
              getDeckCards({
                variables: {
                  eid: deckId,
                },
              });
            }
          }}
          setVisiblityData={!isEdit ? onApply : null}
          isCreate={!isEdit}
          // visibilityData={isEdit ? { visibleTo: deckCardsData?.findDeckById?.visibleTo, visiblity: deckCardsData?.findDeckById?.visibility } : { visibleTo, visiblity }}
          // visibilityData={isEdit ? deckCardsData?.findDeckById?.visibleTo : visibleTo}
          visibilityData={newSetVisibility}
          cardCount={selectedCards.length}
          deckTitle={getValues('deckName')}
          deckThumbnail={deckCardsData?.findDeckById?.thumbnail}
          modalType='write'
          setCreateVisibility={setNewSetVisibility}
          newSetVisibility={newSetVisibility}
        />
      )}
      {isCardModalOpened && (
        <CardEditorModal
          open={isCardModalOpened}
          onClose={closeCardModal}
          onSubmit={onCardSuccess}
          isDeckView
          returnedCardData={returnedCardDataHandler}
        />
      )}
      {isMenuModalOpened && (
        <UpdateDeckAddCards
          isOpen={isMenuModalOpened}
          onClose={() => setIsMenuModalOpened(false)}
          selectedCards={selectedCards}
          cardsToBeAdded={cardsToBeAddedHandler}
          previewHandler={previewHandler}
          title={t('deck:add_card_handbook')}
          searchPlaceholder={t('deck:search_in_card')}
        />
      )}
      {isCardDeckPreviewModalOpen && (
        <CardDeckPreviewModal
          isOpen={isCardDeckPreviewModalOpen}
          onClose={cardDeckPreviewModalHandler}
          selectedCard={cardSelected}
        />
      )}
    </DashboardContainer>
  );
};

export default CreateDeck;

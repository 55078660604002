import { IDateFilterOptions } from '../types';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

/**
 * Helper function to format the range descriptions
 */
const formatDescription = (from: moment.Moment, to: moment.Moment) =>
  `${from.format('DD MMMM')} - ${to.format('DD MMMM')}`;

/**
 * This week
 */
const thisWeekDateRange = (): IDateFilterOptions => {
  const from = moment().startOf('isoWeek');
  const to = moment().endOf('isoWeek').isAfter(moment())
    ? moment()
    : moment().endOf('isoWeek');
  return {
    id: uuidv4(),
    title: 'This week',
    description: formatDescription(from, to),
    range: { from, to },
    isReadOnly: true,
    uniqueValue: 'thisWeek',
  };
};

/**
 * This month
 */
const thisMonthDateRange = (): IDateFilterOptions => {
  const from = moment().startOf('month');
  const to = moment().endOf('month').isAfter(moment())
    ? moment()
    : moment().endOf('month');
  return {
    id: uuidv4(),
    title: 'This month',
    description: formatDescription(from, to),
    range: { from, to },
    isReadOnly: true,
    uniqueValue: 'thisMonth',
  };
};

/**
 * This quarter
 */
const thisQuarterDateRange = (): IDateFilterOptions => {
  const from = moment().startOf('quarter');
  const to = moment().endOf('quarter').isAfter(moment())
    ? moment()
    : moment().endOf('quarter');
  return {
    id: uuidv4(),
    title: 'This quarter',
    description: formatDescription(from, to),
    range: { from, to },
    isReadOnly: true,
    uniqueValue: 'thisQuarter',
  };
};

/**
 * This year
 */
const thisYearDateRange = (): IDateFilterOptions => {
  const from = moment().startOf('year');
  const to = moment().endOf('year').isAfter(moment())
    ? moment()
    : moment().endOf('year');
  return {
    id: uuidv4(),
    title: 'This year',
    description: formatDescription(from, to),
    range: { from, to },
    isReadOnly: true,
    uniqueValue: 'thisYear',
  };
};

/**
 * Last week
 */
const lastWeekDateRange = (): IDateFilterOptions => {
  const today = moment();
  const from = today.clone().subtract(1, 'week').startOf('isoWeek');
  const to = today.clone().subtract(1, 'week').endOf('isoWeek');
  return {
    id: uuidv4(),
    title: 'Last week',
    description: formatDescription(from, to),
    range: { from, to },
    isReadOnly: true,
    uniqueValue: 'lastWeek',
  };
};

/**
 * Last 30 days
 */
const getLastThirtyDaysRange = (): IDateFilterOptions => {
  const today = moment();
  const from = moment().subtract(29, 'days');
  return {
    id: uuidv4(),
    title: 'Last 30 days',
    description: formatDescription(from, today),
    range: { from, to: today },
    isReadOnly: true,
    uniqueValue: 'last30Days',
  };
};

/**
 * Last 3 months
 */
const getLastThreeMonthsRange = (): IDateFilterOptions => {
  const from = moment().subtract(3, 'months').startOf('month');
  const to = moment().subtract(1, 'month').endOf('month');
  return {
    id: uuidv4(),
    title: 'Last 3 months',
    description: formatDescription(from, to),
    range: { from, to },
    isReadOnly: true,
    uniqueValue: 'last3Months',
  };
};

/**
 * Last month
 */
const lastMonthDateRange = (): IDateFilterOptions => {
  const from = moment().subtract(1, 'month').startOf('month');
  const to = moment().subtract(1, 'month').endOf('month');
  return {
    id: uuidv4(),
    title: 'Last month',
    description: formatDescription(from, to),
    range: { from, to },
    isReadOnly: true,
    uniqueValue: 'lastMonth',
  };
};

/**
 * Last quarter
 */
const lastQuarterDateRange = (): IDateFilterOptions => {
  const from = moment().subtract(1, 'quarter').startOf('quarter');
  const to = moment().subtract(1, 'quarter').endOf('quarter');
  return {
    id: uuidv4(),
    title: 'Last quarter',
    description: formatDescription(from, to),
    range: { from, to },
    isReadOnly: true,
    uniqueValue: 'lastQuarter',
  };
};

/**
 * Last year
 */
const getLastYearRange = (): IDateFilterOptions => {
  const from = moment().subtract(1, 'year').startOf('day');
  const to = moment().subtract(1, 'day').endOf('day');
  return {
    id: uuidv4(),
    title: 'Last year',
    description: `${from.format("DD MMMM 'YY")} - ${to.format("DD MMMM 'YY")}`,
    range: { from, to },
    isReadOnly: true,
    uniqueValue: 'lastYear',
  };
};

const getCustomRange = (): IDateFilterOptions => {
  return {
    id: uuidv4(),
    title: 'Custom',
    description: 'Select your date picker',
    range: undefined,
    isReadOnly: false,
    uniqueValue: 'custom',
  };
};

/**
 * Exporting all date filter options
 */
export const dateFilterOptions: IDateFilterOptions[] = [
  thisWeekDateRange(),
  thisMonthDateRange(),
  thisQuarterDateRange(),
  thisYearDateRange(),
  lastWeekDateRange(),
  getLastThirtyDaysRange(),
  getLastThreeMonthsRange(),
  lastMonthDateRange(),
  lastQuarterDateRange(),
  getLastYearRange(),
  getCustomRange(),
];

export const modalWrapperStyle = (context) => {
  const mq = context.theme.breakPoints.map((x) => `@media ${x}`);
  return {
    minWidth: '335px',
    minHeight: '245px',
    width: '335px',
    // height: '245px',
    overflow: 'hidden',
    backgroundColor: `${context.theme.backgroundColor.white}`,
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '102',
    margin: '0 auto',
    boxShadow:
      'rgba(20, 20, 20, 0.2) 0 16px 32px, rgba(20, 20, 20, 0.04) 0 0 0 1px',
    borderRadius: '12px',
    display: 'block',
    [mq[0]]: {
      width: '100%',
      height: '100%',
    },
    [mq[1]]: {
      width: '100%',
      height: '100%',
    },
    [mq[2]]: {
      width: '100%',
      height: '100%',
    },
  };
};

export const modalCloseStyle = (img, context) => {
  return {
    position: 'absolute',
    borderRadius: '36px',
    backgroundColor: '#efefef',
    width: '36px',
    height: '36px',
    top: '20px',
    right: '16px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  };
};

export const threadCloseStyle = () => {
  return {
    borderRadius: '36px',
    backgroundColor: '#efefef',
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  };
};

export const modalBodyStyle = () => {
  return {
    padding: '24px',
    height: '100%',
    width: '100%',
  };
};

export const modalErrorStyle = (context) => {
  return {
    fontSize: '12px',
    color: `${context.theme.color.red}`,
    textAlign: 'center',
    margin: '8px 0',
    width: '100%',
  };
};

export const modalTableStyle = (props) => {
  return {
    borderCollapse: 'collapse',
    margin: '0',
    padding: '0',
    width: '100%',
    height: '90%',
    tr: {
      display: 'table',
      width: '100%',
      tableLayout: 'fixed',
    },
  };
};

export const tableCaptionStyle = () => {
  return {
    fontSize: '20px',
    marginBottom: '40px',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#1a1d1f',
    display: 'flex',
    alignItems: 'center',
  };
};

export const tableBodyStyle = () => {
  return {
    // height: 'calc(100% - 40px)',
    overflowY: 'auto',
    display: 'block',
    tr: {
      td: {
        padding: '8px 0',
        fontSize: '14px',
        marginBottom: '4px',
        input: {
          width: '100%',
          border: 'none',
          padding: '8px 16px',
          fontSize: '14px',
          outline: 'none',
        },
        select: {
          outline: 'none',
          padding: '8px 16px',
        },
      },
    },
  };
};

export const tableFootStyle = (context, state, img) => {
  const loadingState = state.creatingGroup
    ? {
        disabled: 'true',
        pointerEvents: 'none',
        background: `url(${img}) no-repeat right 10px center ${context.theme.primaryColor}`,
      }
    : {};

  const textMargin = state.creatingGroup ? { marginRight: '24px' } : {};

  return {
    display: 'inline-block',
    // marginTop: '10px',
    button: {
      cursor: 'pointer',
      padding: '8px 16px',
      backgroundColor: `${context.theme.primaryColor}`,
      borderRadius: '5px',
      color: `${context.theme.color.white}`,
      fontSize: '14px',
      outline: '0',
      border: '0',
      height: '48px',
      ...loadingState,
      span: {
        ...textMargin,
      },
    },
    tr: {
      border: 'none',
      td: {
        textAlign: 'center',
      },
    },
  };
};

export const inputStyle = (context) => {
  return {
    display: 'block',
    width: '100%',
    border: '0',
    backgroundColor: `#F4F4F4`,
    color: `${context.theme.color.helpText}`,
    fontSize: '15px',
    height: '48px',
    borderRadius: '16px',
  };
};

export const groupIconConatiner = () => {
  return {
    width: '48px',
    height: '48px',
    left: '0px',
    top: '0px',
    background: '#eeee',
    borderRadius: '8px',
    minWidth: '48px',
    marginRight: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
};

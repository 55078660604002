import { gql, useLazyQuery } from '@apollo/client';

const GET_AGGREGATED_VISIBILITY = gql`
  query Query($categoryId: String!) {
    CategoryVisibility(categoryId: $categoryId)
  }
`;

export interface IAggregateVisibilityVariables {
  categoryId: string;
}

export interface ICategoryVisibilityEntity {
  roles: string[];
  locations: string[];
}

export interface IAggregateVisibilityResponse {
  CategoryVisibility: {
    and: ICategoryVisibilityEntity;
    or: ICategoryVisibilityEntity;
    publicSop: string[];
    userVisibility: string[];
  };
}

interface IProps {
  categoryId?: string;
}

export function useVisibilityStatusInfoQuery({ categoryId }: IProps) {
  const [getAggregatedVisibility, { loading, data }] = useLazyQuery<
    IAggregateVisibilityResponse,
    IAggregateVisibilityVariables
  >(GET_AGGREGATED_VISIBILITY, {
    fetchPolicy: 'network-only',
    variables: {
      categoryId: categoryId!,
    },
  });
  return { getAggregatedVisibility, loading, data };
}

import styled from '@emotion/styled';

export const PickerWrapper = styled.div<{ hideDays?: boolean }>`
  .react-datepicker {
    border-color: transparent;
    padding-inline: 24px;
    padding-bottom: 24px;
    //padding-top: 16px;
    padding-top: ${({ hideDays }) => (hideDays ? '24px' : '16px')};
    box-shadow: 9px 3px 25px 11px rgba(150, 150, 150, 0.11);
    border-radius: 16px;
  }

  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before {
    border-top-color: #ffffff;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: #ffffff;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-bottom-color: #ffffff;
  }

  .react-datepicker__header {
    background: #ffffff;
    padding-bottom: 14px;
    border-bottom: unset;

    display: ${({ hideDays }) => (hideDays ? 'none' : 'block')};
  }

  .react-datepicker__day-names {
    justify-content: space-evenly;
    padding-top: 16px;

    .react-datepicker__day-name {
      color: #7e818c;
      font-family: 'Inter', sans-serif;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: initial;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    gap: 12px;
  }

  .react-datepicker__day {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 500;

    :hover {
      border-radius: 100%;
    }
  }

  .react-datepicker__day-name {
    width: unset;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background: #2a85ff;
    border-radius: 100%;
  }
`;

import { TFunction } from 'i18next';
import { userObj } from 'sop-commons/src/client';
import { AuthFunction, AuthRole } from 'authorization';
import { IActionMenuData } from 'ui-components';

import { IFormCreatedByEntity, IFormListEntity } from 'pages/forms/forms-types';

import AppearInComp from './AppearInComp';

import formResponseSvg from '../../../../assets/images/formResponse.svg';
import shareIconSvg from '../../../../assets/images/shareLight.svg';
import fillIcon from '../../../../assets/images/editIcon.svg';
import infoSvg from '../../../../assets/images/info.svg';
import archiveImage from '../../../../assets/images/archive.svg';
import editIcon from '../../../../assets/images/edit-square.svg';
import DeleteIcon from '../../../../assets/images/trash.svg';

type FormEntity = IFormListEntity['items'][number];

export const getMenuDataHelperFn = (
  createdBy: IFormCreatedByEntity,
  contributors: string[],
  appearsInSop: FormEntity['appearsInSop'],
  t: TFunction<'form', undefined, 'form'>,
  authorization: AuthFunction
): IActionMenuData[] => {
  const userObject = userObj();

  const isChatEnabled = userObject?.entity?.features?.chat;

  return [
    ...(userObject?.eid === createdBy?.eid ||
    contributors?.includes(userObject?.eid) ||
    (userObject?.authRole === AuthRole.SUPER_ADMIN &&
      userObject?.type === 'user')
      ? [
          {
            name: t('view_form_response'),
            value: 'view-form-response',
            icon: formResponseSvg,
          },
        ]
      : []),
    authorization(
      {
        permittedRoles: [
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.MANAGER,
          AuthRole.WORKER,
        ],
        permittedFor: ['user', 'branch'],
      },
      {
        name: t('fill_form'),
        value: 'fill-form',
        icon: fillIcon,
      },
      null
    ),
    ...(userObject?.eid === createdBy?.eid ||
    (userObject?.authRole === AuthRole.SUPER_ADMIN &&
      userObject?.type === 'user') ||
    (contributors.includes(userObject?.eid) &&
      userObject?.authRole !== AuthRole.WORKER)
      ? [
          {
            name: t('appears_in'),
            value: 'appear-in',
            icon: infoSvg,
            render: (props: any) => (
              <AppearInComp {...props} appearsInSop={appearsInSop} />
            ),
          } as IActionMenuData,
        ]
      : []),
    ...(userObject?.eid === createdBy?.eid ||
    contributors.includes(userObject?.eid) ||
    (userObject?.authRole === AuthRole.SUPER_ADMIN &&
      userObject?.type === 'user')
      ? [
          {
            name: t('edit_form'),
            value: 'edit-form',
            icon: editIcon,
          },
        ]
      : []),
    isChatEnabled && {
      name: t('send_form'),
      value: 'send-form',
      icon: shareIconSvg,
    },
    ...(userObject?.eid === createdBy?.eid ||
    (userObject?.authRole === AuthRole.SUPER_ADMIN &&
      userObject?.type === 'user')
      ? [
          {
            name: t('mark_as_inactive'),
            value: 'mark-inactive',
            icon: archiveImage,
          },
        ]
      : []),

    ...(userObject?.eid === createdBy?.eid ||
    (userObject?.authRole === AuthRole.SUPER_ADMIN &&
      userObject?.type === 'user')
      ? [
          {
            type: 'divider',
          },
          {
            name: t('deleteFormMenu'),
            value: 'delete',
            icon: DeleteIcon,
          },
        ]
      : []),
  ].filter((value) => !!value) as unknown as IActionMenuData[];
};

import { match } from 'ts-pattern';
import { AuthRole } from 'authorization';
import { useUserDataSelector, useUserEntity } from 'hooks';
import { shallowEqual } from 'utils';
import { toArray } from 'utils/utils';

export const useUserCanCreateChannel = (): boolean => {
  const chatConfig = useUserEntity(
    (entity) => entity?.chatConfig,
    shallowEqual
  );

  const authUser = useUserDataSelector(
    (state) => ({
      eid: state.eid,
      authRole: state.authRole,
      role: state.role,
    }),
    shallowEqual
  );

  if (chatConfig && !chatConfig?.restrictions) {
    const newChannel = chatConfig?.newChannel;
    return match(newChannel?.restrictType)
      .with(
        'role',
        () =>
          toArray(newChannel.roles).includes(authUser.role) ||
          toArray(newChannel.members).includes(authUser.eid)
      )
      .with(
        'authRole',
        () =>
          toArray(newChannel.authRoles).includes(authUser.authRole) ||
          toArray(newChannel.members).includes(authUser.eid)
      )
      .with('anyone', () => true)
      .otherwise(() => false);
  }

  return authUser.authRole === AuthRole.SUPER_ADMIN;
};

import React, { FC, useContext, useEffect, useState } from 'react';
import {
  ILocationData,
  IRoleData,
  ISecondDropdownData,
  IUserData,
} from '../set-see-modal-types';
import {
  Box,
  Flex,
  List,
  ListItem,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { cloneDeep } from '@apollo/client/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import SetSeeDataContext from '../SetSeeModalStore/set-see-data-context';
import { useReactiveVar } from '@apollo/client';
import { AuthRole, userObj } from 'sop-commons/src/client';
import {
  ISelectedLocationData,
  ISelectedMemberData,
  ISelectedRoleData,
} from 'zustandStore/slices/useVisibility';
import useCombinedStore from 'zustandStore/store';

const MultiCustomDropdown: FC<{
  options: ISecondDropdownData[];
  type: 'location' | 'role' | 'member';
  value: string[];
  onClick: (e: any) => void;
  createRole: () => void;
}> = ({ options, value, type, onClick, createRole }) => {
  const { visibilitySelection } = useCombinedStore();
  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const userData = useReactiveVar(userObj);
  const [open, setOpen] = useState(false);

  const setSeeCtx = useContext(SetSeeDataContext);

  useOutsideClick({
    ref: ref,
    handler: () => setOpen(false),
  });

  /**
   * The function `onClickHandler` checks if an item exists in an array, and either adds it if it
   * doesn't exist or removes it if it does.
   * @param {ISecondDropdownData} option - The `onClickHandler` function takes an `option` parameter of
   * type `ISecondDropdownData`. This parameter represents the data of the item that was clicked in a
   * dropdown menu. The function then performs logic based on whether the clicked item already exists
   * in an array called `value`.
   */
  const onClickHandler = (option: ISecondDropdownData) => {
    onClick(option);
    // if (value?.length > 0) {
    //   // clicked item does not exist in the array
    //   let flag = false;
    //   value?.map((val) => {
    //     if (val === option?.eid) {
    //       flag = true;
    //     }
    //   });
    //   if (!flag) {
    //     onClick([...value, option]);
    //   } else {
    //     let tempArr: any[] = [];
    //     value?.map((val) => {
    //       if (val !== option?.eid) {
    //         tempArr.push(val);
    //       }
    //     });
    //     onClick(tempArr);
    //   }

    //   // clicked item exists in the array
    // } else {
    //   let arr: any[] = [];
    //   arr.push(option);
    //   let str = option?.name;
    //   onClick(arr);
    // }
  };

  const getFlag = (option: ISecondDropdownData) => {
    // let flag = value?.some((val) => val?.name === option?.name);
    // return flag;
    let selectedIds =
      type === 'location'
        ? visibilitySelection?.locations
        : visibilitySelection?.roles;
    let flag =
      selectedIds?.includes(option?.eid) || selectedIds?.includes(option?.name);
    return flag;
  };

  const placeholderHandler = () => {
    let selectedIds =
      type === 'location'
        ? visibilitySelection?.locations
        : visibilitySelection?.roles;
    let filteredOptions = options?.filter(
      (option) =>
        selectedIds?.includes(option?.eid) ||
        selectedIds?.includes(option?.name)
    );
    return filteredOptions?.map((op) => op?.name)?.join(', ') || 'Select...';
  };

  const memberPlaceholderHandler = () => {
    return (
      setSeeCtx?.selectedMembersList?.map((mem) => mem?.name)?.join(', ') ||
      'Select...'
    );
  };

  return (
    <Box pos='relative' w='full'>
      <Box bg='white' p={2} borderRadius={'5px'}>
        <Text
          // color={placeholder === 'Select...' ? '#ccc' : 'black'}
          cursor={
            userData?.authRole === AuthRole.LOCATION_OWNER &&
            type === 'location' &&
            setSeeCtx?.locationsList?.length === 1
              ? 'not-allowed'
              : 'pointer'
          }
          color={
            userData?.authRole === AuthRole.LOCATION_OWNER &&
            type === 'location' &&
            setSeeCtx?.locationsList?.length === 1
              ? '#a4a6aa'
              : 'black'
          }
          isTruncated
          maxW={'calc(inherit - 50px)'}
          onClick={() => {
            if (
              userData?.authRole === AuthRole.LOCATION_OWNER &&
              type === 'location' &&
              setSeeCtx?.locationsList?.length === 1
            )
              return;
            setOpen(true);
          }}
        >
          {placeholderHandler()}
          {/* {type === 'location' && locationPlaceholderHandler()}
          {type === 'role' && rolePlaceholderHandler()}
          {type === 'member' && memberPlaceholderHandler()} */}
        </Text>
      </Box>
      {open && (
        <Box ref={ref} pos='absolute' w='inherit' top={'50px'} zIndex={999}>
          <List
            spacing={3}
            p={2}
            bg={'white'}
            borderRadius={'5px'}
            maxH={'200px'}
            overflowY={'scroll'}
          >
            {type === 'role' && (
              <Text
                as='b'
                color='rgb(42, 133, 255)'
                cursor={'pointer'}
                onClick={createRole}
              >
                + Create new job
              </Text>
            )}
            {options?.map((option, index) => (
              <ListItem
                key={JSON.stringify(option)}
                cursor={'pointer'}
                onClick={() => onClickHandler(option)}
              >
                <Flex alignItems={'center'} justifyContent={'space-between'}>
                  <Text>{option?.name}</Text>
                  {getFlag(option) && (
                    <FontAwesomeIcon
                      icon={faCheck as IconProp}
                      color={'rgb(131, 191, 110)'}
                    />
                  )}
                </Flex>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default MultiCustomDropdown;

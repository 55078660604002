import React, {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderUser } from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronRight,
  faFolder as faFolderSolid,
  faFolderUser as faFolderUserSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { CHAPTER_HOME } from '../../../appRoutes';

import AddCategory from './AddCategory';
import SidebarAction from './SidebarAction';
import { Authorize, AuthRole } from '../../../authorization';
import { useCategoryDelete } from './useCategoryDelete';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';

import { ReactComponent as HandbookIcon } from '../../../assets/images/sidebar/handbook.svg';
import { ReactComponent as ChevronIcon } from '../../../assets/images/cheveronIcon.svg'; // TODO
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useFolderSubFolder } from 'hooks';
import { AddCategoryRef } from './types';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface SubItemProps {
  isSelected?: boolean;
  hide?: boolean;
  className?: string;
  /** Category Status field will have either 'private' or 'public' status. For 'private', we will show 'faFolderUser' icon or else 'faFolder' icon */
  categoryStatus?: 'private' | 'public';
  title: string;
  onClick?: () => void;
  isCreateNew?: boolean;
  actionButton?: ReactNode;
  hideIcon?: boolean;
}

export const SubItem: FC<SubItemProps> = ({
  isSelected,
  hide,
  className,
  categoryStatus,
  title,
  onClick,
  actionButton,
  hideIcon,
}) => {
  const iconRender = () => {
    let status: any = faFolder;
    if (categoryStatus === 'private') {
      if (isSelected) {
        status = faFolderUserSolid;
      } else {
        status = faFolderUser;
      }
    } else {
      if (isSelected) {
        status = faFolderSolid;
      } else {
        status = faFolder;
      }
    }
    return status;
  };
  return (
    <Flex pos='relative' w='full' h='6vh' onClick={onClick}>
      {!hide && (
        <Box
          className='vertical-line'
          pos='absolute'
          h='full'
          border='1px solid #efefef'
        />
      )}
      <div
        style={{
          border: '2px solid #efefef',
          borderBottomLeftRadius: '10px',
          borderRight: 'none',
          height: '50%',
          width: '10px',
          borderTop: 'none',
        }}
      />
      <Flex
        className={className}
        flex={1}
        gap={2}
        align='center'
        style={{
          padding: '7px 12px',
          borderRadius: '12px',
          height: '100%',
          marginLeft: 1,
          overflow: 'hidden',
        }}
      >
        {!hideIcon && (
          <FontAwesomeIcon
            className='folder-icon'
            // icon={(isSelected ? faFolderSolid : faFolder) as IconProp}
            icon={iconRender()}
          />
        )}
        <Text as='span' isTruncated style={{ width: '100%' }}>
          {title}
        </Text>
        {!!actionButton && (
          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            {actionButton}
          </Authorize>
        )}
      </Flex>
    </Flex>
  );
};

interface IProps {
  isSelected: boolean;
  isExpand: boolean;
}

const SidebarItemHandbook: FC<IProps> = ({ isSelected, isExpand }) => {
  // @ts-ignore
  const { t } = useTranslation(['sidebar', 'category', 'chapter']);
  const history = useHistory();
  const dashboardCtx = useContext(DashboardDataContext);
  const addCategoryRef = useRef<AddCategoryRef>(null);
  const category = useReactiveVar(userObj)?.entity?.category || [];
  const categoryDelete = useCategoryDelete();

  const routeMatch = useRouteMatch<{ folderId: string }>();

  const selectedFolderId = routeMatch?.params?.folderId;
  const { folderName } = useFolderSubFolder(selectedFolderId);

  const [isExpanded, setIsExpanded] = useState(!!selectedFolderId || isExpand);
  const [openNewCategory, setOpenNewCategory] = useState(false);

  let navigationData = dashboardCtx?.navigationPersistData;
  let knowledgeBaseData = dashboardCtx?.navigationPersistData?.knowledgeBase;

  let className = 'sidebar-item-container';
  if (isSelected) {
    className = `sidebar-item-container selected`;
  }

  // useEffect(() => {
  //   if (selectedFolderId) {
  //     dashboardCtx.selectedFolderHandler({
  //       eid: selectedFolderId,
  //       name: folderName,
  //     });
  //   } else if (!selectedFolderId && dashboardCtx.selectedFolder) {
  //     dashboardCtx.selectedFolderHandler(dashboardCtx.selectedFolder);
  //   } else {
  //     dashboardCtx.selectedFolderHandler(undefined);
  //   }
  // }, [selectedFolderId, dashboardCtx.selectedFolder]);

  const resetKnowledgeBasePageCount = () => {
    dashboardCtx?.navigationPersistDataHandler({
      ...navigationData,
      knowledgeBase: { ...knowledgeBaseData, selectedPage: 1 },
    });
  };

  const onExpandStateChange = () => {
    deployEvent(`${AmplitudeEventNames.NAVIGATE_ROUTE}: /folders`);
    dashboardCtx.selectedFolderHandler(undefined);
    setIsExpanded((s) => !s);
    resetKnowledgeBasePageCount();
  };

  const closeHandler = () => {
    setOpenNewCategory(false);
  };

  const onDeletePress = (data: typeof category[number]) => {
    categoryDelete(data);
  };

  // const onEditPress = (data: typeof category[number]) => {
  //   addCategoryRef.current?.initialise({
  //     category: data.name,
  //   });
  //   setOpenNewCategory(true);
  // };

  return (
    <>
      <Link to={CHAPTER_HOME}>
        <div
          className={className}
          onClick={onExpandStateChange}
          style={{ width: '100%' }}
        >
          <HandbookIcon />
          <div className='sidebar-item-title-container'>
            {t('sidebar:knowledge_base')}
          </div>
          <Authorize
            canAccess={(authUser) => {
              return (
                category?.length > 0 ||
                [AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(
                  authUser?.authRole as AuthRole
                )
              );
            }}
          >
            <div className='sidebar-item-menu-container'>
              {/* <ChevronRightIcon fontSize='25px' /> */}
              <FontAwesomeIcon icon={faChevronRight as IconProp} size='lg' />
            </div>
          </Authorize>
        </div>
      </Link>
      {/* {isExpanded && (
        <div
          className='sidebar-item-list-container'
          style={{ marginBottom: '20px' }}
        >
          {category?.map((item, index: number) => {
            const getClassName = () => {
              if (selectedCategory === item?.name) {
                return `sidebar-item-list-item-container selected`;
              }
              return `sidebar-item-list-item-container`;
            };
            return (
              <Flex cursor='pointer' key={index}>
                <SubItem
                  isSelected={selectedCategory === item?.name}
                  onClick={() => {
                    history.push(`/folders/${item?.name}`);
                    dashboardCtx?.selectedFolderHandler(item?.name);
                    resetKnowledgeBasePageCount();
                  }}
                  title={item?.name}
                  className={getClassName()}
                  categoryStatus={item?.visibility}
                  // hide={!(index + 1 < category.length)}
                  actionButton={
                    <SidebarAction
                      onDeleteClick={() => onDeletePress(item)}
                      onEditClick={() => onEditPress(item)}
                    />
                  }
                />
              </Flex>
            );
          })}
          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <SubItem
              title={t('chapter:createNewFolder')}
              className='sidebar-item-list-item-create'
              hide
              isCreateNew
              onClick={() => setOpenNewCategory(true)}
            />
          </Authorize>
        </div>
      )} */}
      <Authorize
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <AddCategory
          type='folder'
          ref={addCategoryRef}
          open={openNewCategory}
          onClose={closeHandler}
        />
      </Authorize>
    </>
  );
};

export default SidebarItemHandbook;

import { gql } from '@apollo/client';
import { AssignedLocationsType, EndCondition, RepeatCycle } from './task.types';

export interface BaseTaskInput {
  title: string;
  description?: string;

  thumbnail?: string;

  repeatString: string;

  contents: Array<{
    backgroundColor?: string;
    eid?: string;
    isMandatory?: boolean;
    title: string;
    type: string; // TODO
  }>;

  assignedLocationsType: AssignedLocationsType;
  assignedToLocation: string[];
  assignedToRole: string[];
  assignedToUsers: string[];

  supervisors: string[];

  repeatDetails?: Record<string, any>;
}

export interface AddTaskInput extends BaseTaskInput {
  // Schedule
  startDate: string;

  isRepeating?: boolean;
  repeatCycle?: RepeatCycle;

  endCondition: EndCondition<string>;

  hasCustomRepeat?: boolean;
  customRepeat?: {
    days: number;
    time: string;
    type: 'EVERY_N_DAYS';
  };
}

export interface AddTaskVariable {
  input: AddTaskInput;
}

export const addTaskQuery = gql`
  mutation AddTask($input: AddTaskTypeInput) {
    AddTask(input: $input) {
      eid
    }
  }
`;

export interface ActionVariable {
  eid: string;
}

export const terminateTaskQuery = gql`
  mutation TerminateTask($eid: String!) {
    TerminateTask(eid: $eid) {
      succeed
    }
  }
`;

export const deleteTaskQuery = gql`
  mutation DeleteTaskSoft($eid: String!) {
    DeleteTaskSoft(eid: $eid) {
      succeed
    }
  }
`;

export const restartTaskQuery = gql`
  mutation ResumeTask($eid: String!) {
    ResumeTask(eid: $eid) {
      succeed
    }
  }
`;

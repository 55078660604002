import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { TaskDetails } from './TaskDetails';
import { Scheduler } from './Scheduler';
import { TaskSteps } from './TaskSteps';
import { TaskAssignees } from './Assignees';
import { TaskSupervisors } from './Supervisors';

interface IProps {
  onPublishClick: () => void;
  isEdit?: boolean;
}

const TaskCreationForm: FC<IProps> = ({ onPublishClick, isEdit }) => {
  return (
    <Flex flexDir='column' w='full' maxW='780' mx='auto' pt='32px' gap='12px'>
      <TaskDetails />

      <Scheduler isEdit={isEdit} />

      <TaskSteps />

      <TaskAssignees />

      <TaskSupervisors onPublishClick={onPublishClick} />
    </Flex>
  );
};

export default TaskCreationForm;

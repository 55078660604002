// @ts-ignore
import React, { FC, useState } from 'react';
import {
  IConditionType,
  IDropdownSelectionFormat,
  IExpandAccordion,
  ILocationData,
  IModalType,
  IRoleData,
  ISelectedFirstDropdown,
  ISelectedLocationData,
  ISelectedMemberData,
  ISelectedRoleData,
  IType,
  IUserData,
} from '../set-see-modal-types';
import SetSeeDataContext from './set-see-data-context';

const SetSeeDataProvider: FC = (props) => {
  const [modalType, setModalType] = useState<IModalType>('write');
  const [selectedFirstDropdown, setSelectedFirstDropdown] = useState<ISelectedFirstDropdown>({label: 'Location', value: 'Location'});
  const [selectionType, setSelectionType] = useState<IType>('public');
  const [locationsList, setLocationsList] = useState<ILocationData[]>([]);
  const [rolesList, setRolesList] = useState<IRoleData[]>([]);
  const [membersList, setMembersList] = useState<IUserData[]>([]);
  const [selectedLocationsList, setSelectedLocationsList] = useState<ISelectedLocationData[]>([]);
  const [selectedRolesList, setSelectedRolesList] = useState<ISelectedRoleData[]>([]);
  const [selectedMembersList, setSelectedMembersList] = useState<ISelectedMemberData[]>([]);
  const [conditionType, setConditionType] = useState<IConditionType>('or');
  const [showCondition, setShowCondition] = useState(false);
  const [dropdownSelectionFormat, setDropdownSelectionFormat] = useState<2 | 1>(1);
  const [expandAccordion, setExpandAccordion] = useState<IExpandAccordion>('0');

  const updateModalTypeHandler = (type: IModalType) => {
    setModalType(type);
  }

  const updateSelectedFirstDropdownHandler = (type: ISelectedFirstDropdown) => {
    setSelectedFirstDropdown(type);
  }

  const updateSelectionTypeHandler = (type: IType) => {
    setSelectionType(type);
  }

  const updateLocationsListHandler = (items: ILocationData[]) => {
    setLocationsList(items);
  }

  const updateRolesListHandler = (items: IRoleData[]) => {
    setRolesList(items);
  }

  const updateMembersListHandler = (items: IUserData[]) => {
    setMembersList(items);
  }

  const updateSelectedLocationsListHandler = (items: ISelectedLocationData[]) => {
    setSelectedLocationsList(items);
  }

  const updateSelectedRolesListHandler = (items: ISelectedRoleData[]) => {
    setSelectedRolesList(items);
  }

  const updateSelectedMembersListHandler = (items: ISelectedMemberData[]) => {
    setSelectedMembersList(items);
  }

  const updateConditionTypeHandler = (type: IConditionType) => {
    setConditionType(type);
  }

  const updateShowConditionHandler = (value: boolean) => {
    setShowCondition(value);
  }

  const updateDropDownSelectionFormatHandler = (value: IDropdownSelectionFormat) => {
    setDropdownSelectionFormat(value);
  }

  const updateExpandAccordionHandler = (value: IExpandAccordion) => {
    setExpandAccordion(value);
  }

  const setSeeContext = {
    modalType: modalType,
    selectedFirstDropdown: selectedFirstDropdown,
    selectionType: selectionType,
    locationsList: locationsList,
    rolesList: rolesList,
    membersList: membersList,
    selectedLocationsList: selectedLocationsList,
    selectedRolesList: selectedRolesList,
    selectedMembersList: selectedMembersList,
    conditionType: conditionType,
    showCondition: showCondition,
    dropdownSelectionFormat: dropdownSelectionFormat,
    expandAccordion: expandAccordion,
    updateModalType: updateModalTypeHandler,
    updateSelectedFirstDropdown: updateSelectedFirstDropdownHandler,
    updateSelectionType: updateSelectionTypeHandler,
    updateLocationsList: updateLocationsListHandler,
    updateRolesList: updateRolesListHandler,
    updateMembersList: updateMembersListHandler,
    updateSelectedLocationsList: updateSelectedLocationsListHandler,
    updateSelectedRolesList: updateSelectedRolesListHandler,
    updateSelectedMembersList: updateSelectedMembersListHandler,
    updateConditionType: updateConditionTypeHandler,
    updateShowCondition: updateShowConditionHandler,
    updateDropDownSelectionFormat: updateDropDownSelectionFormatHandler,
    updateExpandAccordion: updateExpandAccordionHandler
  };

  return (
    <SetSeeDataContext.Provider value={setSeeContext}>
      {props.children}
    </SetSeeDataContext.Provider>
  );
};

export default SetSeeDataProvider;

import React, { FC } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react';

import { TemplateContent } from '../board-view/template.graphql'; // TODO
import TemplateTaskItem from './TemplateTaskItem';

interface IProps {
  content: TemplateContent; // TODO
}

const TemplatePhase: FC<IProps> = ({ content }) => {
  return (
    <AccordionItem
      borderTopWidth='0'
      borderColor='#EEEEEE'
      borderBottomWidth='1px'
      _last={{
        borderBottomWidth: 0,
      }}
    >
      <AccordionButton borderRadius='12px' px={0} py={3} _hover={{}}>
        <Flex flex={1} flexDir='column' align='start'>
          <Box fontSize='15px' fontWeight='600' color='#272B30'>
            {content.category}{' '}
            <Box as='span' fontWeight='400'>
              ({content.tasks.length} tasks)
            </Box>
          </Box>
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel py={0} px={0}>
        {content.tasks.map((task) => (
          <TemplateTaskItem key={task._id} task={task} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default TemplatePhase;

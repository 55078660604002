import React, { forwardRef, useMemo } from 'react';
import {
  Flex,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Authorize, AuthRole } from '../../authorization';
import { toArray } from '../../utils/utils';
import { useUserEntity } from 'hooks';

interface PermissionLevelInputProps extends Omit<RadioGroupProps, 'children'> {
  disableAllPermissionLevels?: boolean;
}

const PermissionLevelInput = forwardRef<never, PermissionLevelInputProps>(
  (props) => {
    const { disableAllPermissionLevels, ...rest } = props;

    const { t } = useTranslation(['common', 'invite', 'role']);
    const locations = useUserEntity((entity) => entity?.locations || []);

    const isDisabled = useMemo(() => {
      return !toArray(locations).filter((it) => !it?.isRoot).length;
    }, [locations]);

    const finalIsDisabled = disableAllPermissionLevels || isDisabled;

    return (
      <RadioGroup {...rest}>
        <VStack alignItems='flex-start'>
          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.SUPER_ADMIN]}
          >
            <Radio
              isDisabled={disableAllPermissionLevels}
              gap={1}
              value={AuthRole.SUPER_ADMIN}
            >
              <Flex flexDirection='column'>
                <Text fontWeight='500'>
                  {t('invite:authLevel.super_admin')}
                </Text>
                <Text as='span' fontSize='14px' color='#6F767E'>
                  {t('invite:inviteSuperAdminDesc')}
                </Text>
              </Flex>
            </Radio>
          </Authorize>

          <Authorize
            permittedFor='user'
            permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          >
            <Radio
              isDisabled={disableAllPermissionLevels}
              gap={1}
              value={AuthRole.ADMIN}
            >
              <Flex flexDirection='column'>
                <Text fontWeight='500'>{t('invite:authLevel.admin')}</Text>
                <Text as='span' fontSize='14px' color='#6F767E'>
                  {t('invite:inviteAdminDesc')}
                </Text>
              </Flex>
            </Radio>
          </Authorize>

          <Radio
            isDisabled={finalIsDisabled}
            gap={1}
            value={AuthRole.LOCATION_OWNER}
          >
            <Flex flexDirection='column'>
              <Text fontWeight='500'>
                {t('invite:authLevel.locationOwner')}
              </Text>
              <Text as='span' fontSize='14px' color='#6F767E'>
                {t('invite:inviteLocationOwnerDesc')}
              </Text>
            </Flex>
          </Radio>

          <Radio isDisabled={finalIsDisabled} gap={1} value={AuthRole.WORKER}>
            <Flex flexDirection='column'>
              <Text fontWeight='500'>{t('invite:authLevel.worker')}</Text>
              <Text as='span' fontSize='14px' color='#6F767E'>
                {t('invite:inviteWorkerDesc')}
              </Text>
            </Flex>
          </Radio>
        </VStack>
      </RadioGroup>
    );
  }
);

PermissionLevelInput.displayName = 'Permission Level Input';
export default PermissionLevelInput;

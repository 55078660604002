import React, { FC } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { MemberItemWrapper } from './member-item.styles';
import logo from '../../../assets/images/ic_logo.png';
import { Member } from './member.types';
import RoleAndAuthRole from '../../../ui-components/role-authrole/RoleAndAuthRole';
import Image from '../../../ui-components/Image/Image';
import { IconImage } from '../../../ui-components';

interface IProps {
  data: Member;
}

const MemberItem: FC<IProps> = ({ data }) => {
  return (
    <MemberItemWrapper>
      <HStack spacing='10px'>
        <Box className='member-avatar'>
          <Image
            width={36}
            height={36}
            src={data.profilePic || logo}
            style={{
              borderRadius: 8,
            }}
          />
        </Box>

        <VStack align='stretch' flex='1' overflow='hidden'>
          <Box
            fontSize='14px'
            fontWeight='bold'
            color='#1a1d1f'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            overflow='hidden'
          >
            {data.name}
          </Box>
          <RoleAndAuthRole
            role={data.role}
            authRole={data.authRole}
            defaultValue='- -'
          />
        </VStack>
      </HStack>
    </MemberItemWrapper>
  );
};

export default MemberItem;

import React, { FC, useCallback } from 'react';
import { Center, Flex, Text } from '@chakra-ui/react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFileZipper } from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fileSize } from 'utils/fileSize';
import { getFileType } from 'utils/utils';
import { getFileIcon } from 'utils';
import { Image } from 'ui-components';

import { IFilesEntity } from 'pages/LocationDetails/utils/location-details.types';
import ParentWrapper from './ParentWrapper';

interface IProps {
  details: IFilesEntity;
}

const DocumentFileName: FC<IProps> = ({ details }) => {
  const getCommonFileDetails = () => {
    return (
      <Flex flexDir='column'>
        <Text
          fontWeight={500}
          fontSize='15px'
          color='#333B4F'
          isTruncated
          maxW='150px'
        >
          {details?.name}
        </Text>
        <Text fontWeight={400} fontSize='12px' color='#6F767E'>
          {fileSize(details?.fileSize)}
        </Text>
      </Flex>
    );
  };

  const renderFileIcon = useCallback(() => {
    const fileType = getFileType(details?.mimetype);
    switch (fileType) {
      case 'file':
        return (
          <ParentWrapper>
            <Center
              boxSize='42px'
              borderRadius='5px'
              border='1px solid #DBDBDB'
            >
              <FontAwesomeIcon
                icon={faFileZipper as IconProp}
                color='#6F767E'
                fontSize='20px'
              />
            </Center>
            {getCommonFileDetails()}
          </ParentWrapper>
        );
      case 'image':
        return (
          <ParentWrapper>
            <Image
              src={details?.url}
              width={42}
              height={42}
              style={{
                minWidth: '42px',
                borderRadius: '5px',
                objectFit: 'cover',
              }}
            />
            {getCommonFileDetails()}
          </ParentWrapper>
        );
      case 'docs':
      case 'pdf':
      case 'xlsx':
        return (
          <ParentWrapper>
            <Image
              src={getFileIcon(details?.mimetype)!}
              width={42}
              height={42}
              style={{
                minWidth: '42px',
                borderRadius: '5px',
                objectFit: 'cover',
              }}
            />
            {getCommonFileDetails()}
          </ParentWrapper>
        );
      case 'video':
        return (
          <ParentWrapper>
            <Center bg='black' boxSize='42px' borderRadius='5px'>
              <FontAwesomeIcon
                icon={faCirclePlay as IconProp}
                color='white'
                fontSize='20px'
              />
            </Center>
            {getCommonFileDetails()}
          </ParentWrapper>
        );
      default:
        return (
          <ParentWrapper>
            <Image
              src={getFileIcon('generic')!}
              width={42}
              height={42}
              style={{
                minWidth: '42px',
                borderRadius: '5px',
                objectFit: 'cover',
              }}
            />
            {getCommonFileDetails()}
          </ParentWrapper>
        );
    }
  }, [details?.mimetype]);

  return <Flex>{renderFileIcon()}</Flex>;
};

export default DocumentFileName;

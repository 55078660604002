import React, { FC, useMemo } from "react";
import { FormType as Form } from "../elements/Form";
import NoContent from "../elements/NoContent";

interface IProps {
  content?: any[];
  isPreview?: boolean;
  title?: string;
  shouldSetFixedHeight?: boolean;
}

const FormType: FC<IProps> = ({ content = [], isPreview, shouldSetFixedHeight }) => {
  const source = content?.[0]?.url;
  const hasNoData = useMemo(() => {
    if (isPreview) {
      return !(source?.trim()?.length > 0);
    } else {
      return false;
    }
  }, [isPreview, source]);

  if (hasNoData) {
    return <NoContent />;
  }

  return <Form content={content} shouldSetFixedHeight={shouldSetFixedHeight} />;
};

export default FormType;

import React, { FC } from 'react';
import { UseFieldArrayAppend } from 'react-hook-form';

import { LauncherTask, TaskVariable } from '../../common';
import { stepsMapWithId } from '../../common/helpers';

import EditorTaskForm from './EditorTaskForm';
import { IFormInput } from '../editor.types';
import { EditorEvent, useEditorContext } from '../context';

interface IProps {
  categoryId: string;
  addNewTask: UseFieldArrayAppend<IFormInput, `contents.${number}.tasks`>;
}

const EmptySection: FC<IProps> = ({ categoryId, addNewTask }) => {
  const { emit } = useEditorContext();
  return (
    <EditorTaskForm
      isFirstTask
      onSubmit={async (inputs) => {
        const values = await emit<
          { task: TaskVariable; categoryId: string },
          LauncherTask
        >(EditorEvent.ADD_FIRST_TASK, {
          categoryId: categoryId,
          task: {
            title: inputs.title,
            description: inputs.description,
            dependency: inputs.dependency,
            docRequired: inputs.docRequired,
            duration: Number(inputs.duration),

            steps: stepsMapWithId(inputs.steps),

            completed: true,
          },
        });
        if (values) {
          addNewTask({
            ...values,
            tempEid: values.eid,
            updateStatus: 'finish',
          });
        }
      }}
    />
  );
};

export default EmptySection;

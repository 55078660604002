import React, { FC } from 'react';
import styled from '@emotion/styled';
import {
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import whiteIcon from '../../assets/images/logo-white.svg';
import MobileExperience from './MobileExperience';

const Wrapper = styled.div`
  .mob-exp-button-con {
    position: fixed;
    height: 54px;
    bottom: 0;
    background-color: #8e59ff;
    color: white;
    padding: 0 16px;
    border-top-left-radius: 7px;

    font-family: 'Inter';
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
    user-select: none;

    .mob-exp-expand-icon {
      position: absolute;
      right: 0;
      top: -22px;
      height: 22px;
      width: 42px;
      background-color: #8e59ff;
      border-radius: 5px 6px 0 0;
    }
  }
`;

interface IProps {}

const MobileExperienceContainer: FC<IProps> = () => {
  const { t } = useTranslation('home');
  const { isOpen, onToggle, onClose } = useDisclosure();
  const right = useBreakpointValue({
    base: '40px',
    md: '19px',
    lg: '21px',
    xl: '40px',
  });

  return (
    <Wrapper>
      <Flex
        className='mob-exp-button-con'
        gap='8px'
        align='center'
        right={right}
        onClick={onToggle}
      >
        <Image w='24px' h='24px' src={whiteIcon} />
        <Text>{t('mobile_experience')}</Text>
        <Flex
          hidden={isOpen}
          align='center'
          justify='center'
          className='mob-exp-expand-icon'
        >
          <FontAwesomeIcon icon={faChevronUp as IconProp} />
        </Flex>
      </Flex>
      <MobileExperience isOpen={isOpen} onClose={onClose} marginRight={right} />
    </Wrapper>
  );
};

export default MobileExperienceContainer;

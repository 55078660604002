import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LocationState } from 'history';
import { useTranslation } from 'react-i18next';
import ChapterMoveContainer from './ChapterMoveContainer';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import { CustomCategoryEntity } from '../ChapterHeader/components/BulkMoveContainer';
import { ChapterModal } from '../modal';

// import ModalContent from './ModalContent';
// import { LocationPath } from './types';

interface IProps {
  chapterDetails?: ChapterModal;
}

type ChapterMove = (props: IProps) => void;

export const useChapterMove = (): ChapterMove => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  const history = useHistory();

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ chapterDetails } = {}) => {
    //   const pushTo = (location: LocationPath, state?: LocationState) => {
    //     history.push(location, state);
    //     confirmRef.current?.destroy();
    //   };

    const onCloseHandler = (data?: CustomCategoryEntity) => {
      let url = data?.parentFolderId
        ? `/folders/${data?.parentFolderId}/${data?.eid}`
        : data?.eid
        ? `/folders/${data?.eid}`
        : '/folders';
      setTimeout(() => {
        history.push(url);
        confirmRef.current?.destroy();
      }, 1000);
    };

    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      size: 'lg',
      title: (
        <Flex gap='12px' mx={8} my={6}>
          <Box flex={1}>
            <BoxHeader title={'Move chapter'} color='#B5E4CA' />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <ChapterMoveContainer
          chapterDetails={chapterDetails}
          onCloseHandler={onCloseHandler}
        />
      ),
      headerProps: {
        padding: 0,
      },
      bodyProps: {
        padding: 0,
      },
      contentProps: {
        borderRadius: '16px',
      },
      isCentered: false,
      footer: null,
    });
  }, []);
};

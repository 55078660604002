import { StateCreator } from 'zustand';

type IStatusType = 'INITIAL' | 'MID' | 'FINAL';

// Define the state and actions interfaces for the user slice
export interface SearchState {
  isValidated: boolean;
  selectedFolderId: string | undefined;
  statusType: IStatusType;
  updateIsValidated: (value: boolean) => void;
  updateSelectedFolderId: (value: string | undefined) => void;
  updateStatusType: (value: IStatusType) => void;
}

export const searchSlice: StateCreator<SearchState> = (set) => ({
  isValidated: false,
  selectedFolderId: undefined,
  statusType: 'INITIAL',
  updateIsValidated: (value: boolean) => set(() => ({ isValidated: value })),
  updateSelectedFolderId: (value: string | undefined) =>
    set(() => ({ selectedFolderId: value })),
  updateStatusType: (type: IStatusType) => set(() => ({ statusType: type })),
});

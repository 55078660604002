import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Title } from '../../../ui-components';
import { PrimaryButton } from '../../../atoms';
import { useUserCanCreateChannel } from './create-channel';

interface IProps {
  onAddNewClick?: () => void;
}

const ChatSettingHeader: FC<IProps> = ({ onAddNewClick }) => {
  const { t } = useTranslation(['setting']);
  const history = useHistory();
  const canCreateChannel = useUserCanCreateChannel();

  return (
    <Flex justify='space-between' alignItems='center'>
      <Flex gap={3}>
        <Center height='36px' onClick={history.goBack} cursor='pointer'>
          <ArrowBackIcon color='#272B30' boxSize='22px' />
        </Center>
        <Box>
          <Title fontSize='24px' lineHeight='36px'>
            Manage channel
          </Title>
          <Box
            fontSize='15px'
            fontWeight='400'
            color='#6F767E'
            cursor='pointer'
            onClick={history.goBack}
          >
            Back to chat settings
          </Box>
        </Box>
      </Flex>

      {canCreateChannel && (
        <PrimaryButton
          colorScheme='blue'
          width='auto'
          title={t('setting:createNewChannel')}
          leftIcon={<AddIcon />}
          onClick={onAddNewClick}
        />
      )}
    </Flex>
  );
};

export default ChatSettingHeader;

import { gql } from '@apollo/client';
import { AuthRole } from '../../../../../../authorization';
import { ResponseEntity } from '../../../../../../types';

export interface FormResponse {
  response: ResponseEntity[];
  completedAt: string;
  createdAt: string;
  createdBy: {
    eid: string;
    email: string;
    name: string;
    authRole: AuthRole;
    role: string;
    locations: Array<{
      eid: string;
      name: string;
    }>;
  };
  eid: string;
  form: {
    eid: string;
  };
  formId: string;
  initialResponseAt: string;
  locations: any[];
  sessionId: string;
  status: string;
  updatedAt: string;
  userId: string;
}

export const FORM_RESPONSE_BY_ID = gql`
  query FormResponseById($eid: String!) {
    FormResponseById(eid: $eid) {
      response
      completedAt
      createdAt
      createdBy {
        eid
        email
        name
        authRole
        role
        locations {
          eid
          name
        }
      }
      eid
      form {
        eid
      }
      formId
      initialResponseAt
      locations
      sessionId
      status
      updatedAt
      userId
    }
  }
`;

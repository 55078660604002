import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFlag,
  faCardsBlank,
  faUser,
  faSquareList,
} from '@fortawesome/pro-solid-svg-icons';

const Colors = {
  user: '#ff6a55',
  location: '#ff6a55',
  branch: '#8e59ff',
  card: '#83bf6e',
  deck: '#2a85ff',
  category: '#8e59ff',
  form: '#8e59ff',
  // training: '#8e59ff',
};

type ColorKey = keyof typeof Colors;

interface IProps {
  type?: ColorKey | string;
}

const StatusIconInner: FC<IProps> = ({ type }) => {
  if (['user', 'location'].includes(type as string)) {
    return (
      <FontAwesomeIcon icon={faUser as IconProp} color='white' fontSize={12} />
    );
  }

  if (type === 'branch') {
    return (
      <FontAwesomeIcon icon={faFlag as IconProp} color='white' fontSize={11} />
    );
  }

  if (type === 'card') {
    return (
      <div>
        <Box className='vector-card' m={0} />
      </div>
    );
  }

  if (type === 'deck') {
    return (
      <FontAwesomeIcon
        icon={faCardsBlank as IconProp}
        color='white'
        fontSize={12}
      />
    );
  }

  if (type === 'form') {
    return (
      <FontAwesomeIcon
        icon={faSquareList as IconProp}
        color='white'
        fontSize={12}
      />
    );
  }

  // if (type === 'training') {
  //   return (
  //     <FontAwesomeIcon icon={faFlag as IconProp} color='white' fontSize={11} />
  //   );
  // }

  // if (type === 'category') {
  //   return (
  //     <Flex direction='column' gap='1.3px'>
  //       <Flex gap='1.3px'>
  //         <Box width='5px' height='5px' bg='white' borderRadius='1' />
  //         <Box width='5px' height='5px' bg='white' borderRadius='1' />
  //       </Flex>
  //       <Flex gap='1.3px'>
  //         <Box width='5px' height='5px' bg='white' borderRadius='1' />
  //         <Box width='5px' height='5px' bg='white' borderRadius='1' />
  //       </Flex>
  //     </Flex>
  //   );
  // }

  return null;
};

const StatusIcon: FC<IProps> = ({ type }) => {
  if (!Colors[type as ColorKey] || type === 'category') {
    return null;
  }
  return (
    <Box className='status-icon-con' bg={Colors[type as ColorKey]}>
      <StatusIconInner type={type} />
    </Box>
  );
};

export default StatusIcon;

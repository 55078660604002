import React, { FC, useMemo } from "react";
import "../style.scss";
import { Video } from "../elements/Video";
import NoContent from "../elements/NoContent";

interface IVideoType {
  content: any;
  title: string;
  media: Array<any>;
  thumbnail?: string;
  pauseVideo?: boolean;
  isPreview?: boolean;
  shouldSetFixedHeight?: boolean;
}

export const VideoType: FC<IVideoType> = ({
  content,
  title,
  media,
  thumbnail,
  pauseVideo,
  isPreview,
  shouldSetFixedHeight
}) => {
  const videoLink = content?.[0]?.video;
  const caption = content?.[1]?.caption;

  const hasNoData = useMemo(() => {
    if (isPreview) {
      return !(
        videoLink?.length > 0 ||
        title?.trim().length > 0 ||
        caption?.trim().length > 0
      );
    } else {
      return false;
    }
  }, [videoLink, caption, isPreview, title]);

  if (hasNoData) {
    return <NoContent />;
  }

  return (
    <div className="player-wrapper" style={{ minHeight : shouldSetFixedHeight ? '80vh' : 'none' }}>
      <Video
        caption={caption}
        videoLink={videoLink}
        title={title}
        media={media}
        thumbnail={thumbnail}
        pauseVideo={pauseVideo}
      />
    </div>
  );
};

import React, { FC } from 'react';
import { Stack, ThemeTypings } from '@chakra-ui/react';
import PaginationItem from './PaginationItem';

type PaginationProps = {
  onPageChange?: (page: number) => void;
  currentPage: number;
  lastPage: number;
  nextPages: number[];
  previousPages: number[];
  siblingsCount: number;
  colorScheme?: ThemeTypings['colorSchemes'];
};

const Pagination: FC<PaginationProps> = ({
  currentPage,
  lastPage,
  previousPages,
  nextPages,
  siblingsCount,
  onPageChange = () => {},
  colorScheme = 'blue',
}) => (
  <Stack direction='row' justify='flex-end' align='center' spacing='6'>
    <Stack direction='row' spacing='4'>
      {currentPage > 1 + siblingsCount ? (
        <>
          <PaginationItem
            colorScheme={colorScheme}
            onPageChange={onPageChange}
            page={1}
          />
          {currentPage > 2 + siblingsCount ? (
            <PaginationItem
              page={currentPage - siblingsCount - 2}
              onPageChange={onPageChange}
              isEclipsed
            />
          ) : null}
        </>
      ) : null}

      {previousPages.length > 0
        ? previousPages.map((page) => (
            <PaginationItem
              colorScheme={colorScheme}
              onPageChange={onPageChange}
              page={page}
              key={page}
            />
          ))
        : null}

      <PaginationItem
        colorScheme={colorScheme}
        onPageChange={onPageChange}
        page={currentPage}
        isCurrent
      />

      {nextPages.length > 0
        ? nextPages.map((page) => (
            <PaginationItem
              colorScheme={colorScheme}
              onPageChange={onPageChange}
              page={page}
              key={page}
            />
          ))
        : null}

      {currentPage + siblingsCount < lastPage ? (
        <>
          {currentPage + 1 + siblingsCount < lastPage ? (
            <PaginationItem
              page={currentPage + siblingsCount + 2}
              onPageChange={onPageChange}
              isEclipsed
            />
          ) : null}
          <PaginationItem
            colorScheme={colorScheme}
            onPageChange={onPageChange}
            page={lastPage}
          />
        </>
      ) : null}
    </Stack>
  </Stack>
);

export default Pagination;

import styled from "@emotion/styled";

export const DropDownWithImageWrapper = styled.div`
  position: relative;

  img {
    min-width: 20px;
    max-width: 20px;
    cursor: pointer;
  }

  .drop-selected-container img {
    min-width: 15px;
    max-width: 15px;
  }

  .drop-options-container {

    ::-webkit-scrollbar {
      width: 4px;
      height: 0px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background: #d5d5d5;
      border-radius: 4px;
    }
  }

  .drop-option-container {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    padding: 1rem 1rem;
  }

  .drop-option-container img {
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
  }

  .drop-options-container :hover {
    background-color: #f4f4f4;
  }
`;
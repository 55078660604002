const Text = ({ textContent }) => {
  const text = textContent?.text;
  const textAlign = textContent?.attrs?.textAlign;
  const headingLevel = textContent?.attrs?.headingLevel;
  if (headingLevel === 1) {
    return (
      <h1 style={{ textAlign: textAlign, fontSize: "2em", fontWeight: "bold" }}>
        {text}
      </h1>
    );
  } else if (headingLevel === 2) {
    return (
      <h2
        style={{ textAlign: textAlign, fontSize: "1.5em", fontWeight: "bold" }}
      >
        {text}
      </h2>
    );
  } else if (headingLevel === 3) {
    return (
      <h3
        style={{ textAlign: textAlign, fontSize: "1.17em", fontWeight: "bold" }}
      >
        {text}
      </h3>
    );
  } else if (headingLevel === 4) {
    return (
      <h4 style={{ textAlign: textAlign, fontSize: "1em", fontWeight: "bold" }}>
        {text}
      </h4>
    );
  } else {
    return <span style={{ textAlign: textAlign }}>{text}</span>;
  }
};

export default Text;

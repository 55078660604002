export const sectionStyle = (props) => {
  const containerHeightProp = props.containerHeight
    ? {
        height: `calc(100% - ${props.containerHeight})`,
      }
    : {
        height: 'calc(100% - 20px)',
      };

  return {
    width: '100%',
    ...containerHeightProp,
  };
};

export const sectionHeaderStyle = (props) => {
  return {
    margin: '0',
    width: '100%',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '2.29',
    textTransform: 'capitalize',
    color: '#6f767e',
  };
};

export const sectionContentStyle = () => {
  return {
    width: '100%',
    margin: '6px 0',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 20px)',
  };
};

export const mediaBtnStyle = () => {
  return {
    borderRadius: '8px',
    // backgroundColor: 'rgba(20, 20, 20, 0.08)',
    width: '100%',
    padding: '2px',
    margin: '6px 0',
    clear: 'both',
  };
};

export const buttonStyle = (state, type) => {
  const activeBtn =
    state.messagetype === type
      ? {
          backgroundColor: '#efefef',
          color: '#1a1d1f',
        }
      : {};

  return {
    borderRadius: '8px',

    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // display: 'inline-block',
    width: '33.33%',
    float: 'left',
    fontSize: '15px',
    fontWeight: '600',
    lineHeight: '18px',
    padding: '5px',
    position: 'relative',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#6f767e',
    ...activeBtn,
    '&:before': {
      '`content`': '',
      position: 'absolute',
      display: 'block',
      width: '2px',
      height: '16px',
      backgroundColor: 'rgba(20, 20, 20, 0.12)',
      right: '-2px',
      top: '6px',
    },
    '&:last-of-type::before': {
      display: 'none',
    },
  };
};

export const mediaItemStyle = () => {
  return {
    height: 'calc(100% - 45px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '14px',
  };
};

export const itemStyle = (state, props, img, context) => {
  let itemTypeStyle = {};
  let bgColor = `${props.theme.backgroundColor.lightGrey}`;

  if (state.messagetype === 'image') {
    itemTypeStyle = {
      height: '120px',
      width: '120px',
      flex: 'none',

      // backgroundColor: bgColor,
      '> img': {
        display: 'block',
        width: '100%',
        height: '100%',
        // objectFit: 'contain',
        borderRadius: '5px',
      },
    };
  } else if (state.messagetype === 'video') {
    itemTypeStyle = {
      '> video': {
        height: '120px',
        width: '120px',
        margin: 'auto',
      },
    };
  } else if (state.messagetype === 'file') {
    itemTypeStyle = {
      backgroundColor: bgColor,
      '> a': {
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto',
        display: 'flex',
        padding: '8px',
        '&:hover, &:visited': {
          color: `${props.theme.secondaryTextColor}`,
        },
        '> i': {
          width: '30px',
          height: '24px',
          display: 'inline-block',
          mask: `url(${img}) left center no-repeat`,
          backgroundColor: `${context.theme.secondaryTextColor}`,
        },
        '> span': {
          fontSize: '13px',
          color: `${props.theme.secondaryTextColor}`,
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          textAlign: 'left',
          width: 'calc(100% - 30px)',
        },
      },
    };
  }

  return {
    margin: '0.5rem',
    textAlign: 'center',
    flex: '1 0 auto',
    ...itemTypeStyle,
    '@for $i from 1 through 36': {
      '&:nth-of-type(#{$i})': {
        maxWidth: '100%',
      },
    },
  };
};

import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { MessageIconButton } from '../../../../../atoms';
import { SortByNumber } from '../../../../../utils/sorting';

import { Column, SortingTable } from '../../../../ChakraTable/SortingTable';

import { TaskProgress } from '../../common';

import { LocPerformanceEntity } from './location-performance.graphql';
import {
  LocationMembers,
  LocationName,
} from 'pages/Teams/locations/Components';
import EmptyJobMembers from '../jobs/EmptyJobMembers';

interface IProps {
  dataList: LocPerformanceEntity[];
  onPageChange: (newPage: number) => void;
  loading: boolean;
  currentPage: number;
  itemCount?: number;
  onClickedHandler?: (clickedItem: string, data: LocPerformanceEntity) => void;
}

const PerformanceList: FC<IProps> = ({
  dataList,
  onPageChange,
  loading,
  itemCount,
  currentPage,
  onClickedHandler,
}) => {
  const { t, i18n } = useTranslation([
    'common',
    'team',
    'task',
    'role',
    'setting',
  ]);

  const columns = useMemo((): Column<LocPerformanceEntity>[] => {
    return [
      {
        Header: t('common:name'),
        accessor: 'name',
        width: '25%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <LocationName
              name={value}
              thumbnail={row.original.profilePic}
              username={row.original.username}
              onClick={() => {
                onClickedHandler?.('open', row.original);
              }}
            />
          );
        },
      },
      {
        Header: t('setting:locations_section.phone_table_heading'),
        accessor: 'phone',
        width: '18%',
        Cell: ({ cell: { value } }) => {
          return (
            <Flex
              align='center'
              textOverflow='ellipsis'
              overflow='hidden'
              whiteSpace='nowrap'
              color='#6F767E'
              fontSize='14px'
              fontWeight='600'
            >
              {value || '-'}
            </Flex>
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('setting:locations_section.members_table_heading'),
        accessor: 'members',
        width: 'calc(41% - 60px)',
        Cell: ({ cell: { value, row } }) => {
          return (
            <LocationMembers members={value} locationName={row.original.name} />
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('task:completionRate'),
        accessor: 'completionRate',
        width: '16%',
        Cell: ({ cell: { value } }) => {
          return <TaskProgress value={value} />;
        },
        sortType: SortByNumber,
      },
      {
        Header: '',
        accessor: 'eid',
        width: '60px',
        Cell: ({ cell: { value, row } }) => {
          return (
            <Flex justify='flex-end'>
              <MessageIconButton
                onClick={() => onClickedHandler?.('message', row.original)}
              />
            </Flex>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [t, i18n.language, onClickedHandler]);

  return (
    <SortingTable
      colorScheme='blue'
      emptyData={{
        content: (
          <EmptyJobMembers title={t('task:empty.performanceLocation')} />
        ),
      }}
      page={currentPage || 1}
      onPageChange={onPageChange}
      columns={columns}
      data={dataList}
      isLoading={loading}
      totalRegisters={itemCount}
      isResponsive
      isHidePagination={!(currentPage > 1 || (itemCount || 0) > 10)}
    />
  );
};

export default PerformanceList;

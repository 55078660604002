import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import {
  CometChatMessageActions,
  CometChatThreadedMessageReplyCount,
  CometChatReadReceipt,
} from '../../';
import { CometChatMessageReactions } from '../';

import { CometChatContext } from '../../../../util/CometChatContext';
import { checkMessageForExtensionsData } from '../../../../util/common';

import { theme } from '../../../../resources/theme';
import Translator from '../../../../resources/localization/translator';

import {
  messageContainerStyle,
  messageWrapperStyle,
  messageImgWrapper,
  messageInfoWrapperStyle,
  messageReactionsWrapperStyle,
} from './style';

class CometChatSenderStickerBubble extends React.Component {
  static contextType = CometChatContext;

  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const currentMessageStr = JSON.stringify(this.props.message);
    const nextMessageStr = JSON.stringify(nextProps.message);

    if (
      currentMessageStr !== nextMessageStr ||
      this.state.isHovering !== nextState.isHovering
    ) {
      return true;
    }
    return false;
  }

  handleMouseHover = () => {
    this.setState(this.toggleHoverState);
  };

  toggleHoverState = (state) => {
    return {
      isHovering: !state.isHovering,
    };
  };

  render() {
    let stickerData = null;
    let stickerImg = null;
    if (
      this.props.message.hasOwnProperty('data') &&
      this.props.message.data.hasOwnProperty('customData')
    ) {
      stickerData = this.props.message.data.customData;

      if (stickerData.hasOwnProperty('sticker_url')) {
        const stickerName = stickerData.hasOwnProperty('sticker_name')
          ? stickerData.sticker_name
          : Translator.translate('STICKER', this.context.language);
        stickerImg = <img src={stickerData.sticker_url} alt={stickerName} />;
      }
    }

    let messageReactions = null;
    const reactionsData = checkMessageForExtensionsData(
      this.props.message,
      'reactions'
    );
    if (reactionsData) {
      if (Object.keys(reactionsData).length) {
        messageReactions = (
          <div
            css={messageReactionsWrapperStyle()}
            className='message__reaction__wrapper'
          >
            <CometChatMessageReactions
              message={this.props.message}
              actionGenerated={this.props.actionGenerated}
            />
          </div>
        );
      }
    }

    let toolTipView = null;
    if (this.state.isHovering) {
      toolTipView = (
        <CometChatMessageActions
          message={this.props.message}
          actionGenerated={this.props.actionGenerated}
        />
      );
    }

    return (
      <div
        css={messageContainerStyle()}
        className='sender__message__container message__sticker'
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
      >
        {toolTipView}

        <div css={messageWrapperStyle()} className='message__wrapper'>
          <div
            css={messageImgWrapper(this.context)}
            className='message__img__wrapper'
          >
            {stickerImg}{' '}
          </div>
        </div>

        {messageReactions}

        <div css={messageInfoWrapperStyle()} className='message__info__wrapper'>
          <CometChatThreadedMessageReplyCount
            message={this.props.message}
            actionGenerated={this.props.actionGenerated}
          />
          <CometChatReadReceipt message={this.props.message} />
        </div>
      </div>
    );
  }
}

// Specifies the default values for props:
CometChatSenderStickerBubble.defaultProps = {
  theme: theme,
  actionGenerated: () => {},
};

CometChatSenderStickerBubble.propTypes = {
  theme: PropTypes.object,
  actionGenerated: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
};

export { CometChatSenderStickerBubble };

import styled from '@emotion/styled';

export const AssignPublishTextWrapper = styled.div`
    .assign-publish-text {
        background-color: rgba(177, 229, 252, 0.4);
        margin-bottom: 1rem;
        padding: 2rem;
        border-radius: 10px;
    }

    img {
        float: left;
        margin-right: 10px;
    }
`;
import { useCallback } from 'react';

import { IFormInput } from '../../../../../../../sub-components/tasks/create-task';
import { useResourceVisibility } from '../common';
import FormContainer from './FormContainer';
import { FormResource } from './FormListContainer';

interface IProps {
  values: FormResource[];
  onAddClick?: (values: IFormInput['contents']) => void;
  onCancelClick?: (...args: any[]) => Promise<void> | void;
}

type UseFormResource = (props: IProps) => void;

export const useFormVisibilityCheck = (): UseFormResource => {
  const resourceVisibility = useResourceVisibility();

  return useCallback(({ values, onAddClick, onCancelClick }) => {
    resourceVisibility({
      title: 'Confirm adding form',
      values: values,
      component: FormContainer,
      onAddClick: onAddClick,
      onCancelClick: onCancelClick,
    });
  }, []);
};

import { pdfjs } from 'react-pdf';
import { PDFPageProxy } from 'pdfjs-dist';
import { EXT_RENAME_REGEX } from './constants';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const getFileSource = async (source: string | File) => {
  if (typeof source === 'object') {
    return new Promise<{
      data: Uint8Array;
    }>((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = function () {
        const typedArray = new Uint8Array(this.result as ArrayBuffer);
        resolve({
          data: typedArray,
        });
      };
      fileReader.onerror = reject;
      //Step 3:Read the file as ArrayBuffer
      fileReader.readAsArrayBuffer(source);
    });
  }

  return {
    url: source,
  };
};

export const getFileName = (source: string | File): string => {
  if (typeof source === 'object') {
    return source.name?.replace(EXT_RENAME_REGEX, '-thumb.jpg');
  }

  const fileName = source?.split(/\/[0-9]+_/).pop();

  return fileName?.replace(EXT_RENAME_REGEX, '-thumb.jpg')!;
};

const makeThumbnail = async (
  page: PDFPageProxy,
  size: number
): Promise<string> => {
  // draw page to fit into input size canvas
  const desiredWidth = size;
  const viewPort = page.getViewport({ scale: 1 });

  const scale = desiredWidth / viewPort.width;
  const scaledViewport = page.getViewport({ scale: scale });

  const canvas = document.createElement('canvas');
  canvas.height = scaledViewport.height;
  canvas.width = scaledViewport.width;

  return await page
    .render({
      canvasContext: canvas.getContext('2d')!,
      viewport: scaledViewport,
    })
    .promise.then(function () {
      return canvas.toDataURL('image/jpeg');
    });
};

export const generatePdfThumbnail = async (
  source: string | File
): Promise<string> => {
  const fileData = await getFileSource(source);

  const pdfDocument = await pdfjs.getDocument(fileData).promise;

  return await pdfDocument.getPage(1).then(async (value) => {
    return await makeThumbnail(value, 400);
  });
};

export const generatePdfThumbnailFile = async (
  source: string | File
): Promise<Blob> => {
  const dataURL = await generatePdfThumbnail(source);
  const response = await fetch(dataURL);
  return await response.blob();
};

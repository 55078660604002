import { DeepPartial } from '@chakra-ui/react';
import { QuestionType } from 'types';
import { IFormInput } from './form.types';
import { DEFAULT_FORM_COVER } from '../../../configs/default-image';

export const initialData: DeepPartial<IFormInput> = {
  title: '',
  description: '',
  formCategory: {},
  thumbnail: DEFAULT_FORM_COVER,
  isReadOnly: false,
  questions: [
    // {
    //   qType: QuestionType.SECTION,
    //   options: [],
    //   label: 'Form Section',
    //   isRequired: false,
    //   hasAnalytics: false,
    // },
  ],
};

export const initialDataA: DeepPartial<IFormInput> = {
  thumbnail: '',
  title: 'New Form',
  description: 'New form description',
  formCategory: {},
  questions: [
    {
      qType: QuestionType.SECTION,
      options: [],
      label: 'Form Section',
      isRequired: false,
      hasAnalytics: false,
    },
    {
      qType: QuestionType.SHORT_TEXT,
      options: [],
      label: 'Short answer question?',
      isRequired: false,
      hasAnalytics: false,
    },
    {
      qType: QuestionType.LONG_TEXT,
      options: [],
      label: 'Paragraph answer question?',
      isRequired: false,
      hasAnalytics: false,
    },
    {
      qType: QuestionType.MULTI_CHOICE,
      options: [
        {
          label: 'A',
          subTitle: '',
          isCorrect: false,
          isDisabled: false,
        },
        {
          label: 'B',
          subTitle: '',
          isCorrect: false,
          isDisabled: false,
        },
        {
          label: 'C',
          subTitle: '',
          isCorrect: false,
          isDisabled: false,
        },
        {
          label: 'D',
          subTitle: '',
          isCorrect: false,
          isDisabled: false,
        },
      ],
      label: 'Multiple choice?',
      isRequired: false,
      hasAnalytics: false,
    },
    {
      qType: QuestionType.CHECKBOX,
      options: [
        {
          label: 'A',
          isCorrect: false,
          isDisabled: false,
          subTitle: '',
        },
        {
          label: 'B',
          isCorrect: false,
          isDisabled: false,
          subTitle: '',
        },
        {
          label: 'C',
          isCorrect: false,
          isDisabled: false,
          subTitle: '',
        },
        {
          label: 'D',
          isCorrect: false,
          isDisabled: false,
          subTitle: '',
        },
      ],
      label: 'Checkbox ?',
      isRequired: false,
      hasAnalytics: false,
    },
    {
      qType: QuestionType.DROPDOWN,
      options: [
        {
          label: 'A',
          isCorrect: false,
          isDisabled: false,
          subTitle: '',
        },
        {
          label: 'B',
          isCorrect: false,
          isDisabled: false,
          subTitle: '',
        },
        {
          label: 'C',
          isCorrect: false,
          isDisabled: false,
          subTitle: '',
        },
        {
          label: 'D',
          isCorrect: false,
          isDisabled: false,
          subTitle: '',
        },
      ],
      label: 'Dropdown check?',
      isRequired: false,
      hasAnalytics: false,
    },
    {
      qType: QuestionType.FILE_UPLOAD,
      options: [],
      label: 'File Upload?',
      isRequired: false,
      hasAnalytics: false,
    },
    {
      qType: QuestionType.LOCATION,
      options: [],
      label: 'From which location you belong?',
      isRequired: false,
      hasAnalytics: false,
    },
    {
      qType: QuestionType.MEMBER,
      options: [],
      label: 'Whom you are reporting?',
      isRequired: false,
      hasAnalytics: false,
    },
    {
      qType: QuestionType.VIDEO_UPLOAD,
      options: [],
      label: 'Introduction video?',
      isRequired: false,
      hasAnalytics: false,
    },
  ],
};

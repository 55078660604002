import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { Box } from '@chakra-ui/react';
import MyTrainingList from 'pages/Training/TrainingList/MyTrainingList';
import { FC, useEffect, useState } from 'react';
import { userObj } from 'sop-commons/src/client';
import {
  CertificateResponse,
  GET_USER_CERTIFICATES,
} from './certificate.graphql';
import CertificatesList from './CertificatesList';

interface ICertificate {
  userCertificates: {
    certificate: string;
    certificateUrl: string;
    firstCompletedAt: string;
    thumbnail: string;
    training: string;
    trainingId: string;
  }[];
}

interface IProps {
  userId: string;
}

const CertificateComponent: FC<IProps> = ({ userId }) => {
  const { data, loading } = useQuery<CertificateResponse>(
    GET_USER_CERTIFICATES,
    {
      fetchPolicy: 'network-only',
      variables: {
        userId: userId,
      },
      skip: !userId,
    }
  );

  const onPageChangeHandler = () => {};

  const dataList = data?.userCertificates || [];

  return (
    <Box
      id='my-training-list-parent-profile'
      bg='white'
      // p={8}
      borderRadius='7px'
    >
      <CertificatesList
        onPageChangeHandler={onPageChangeHandler}
        currentPage={1}
        dataList={dataList}
        loading={loading}
        itemCount={dataList.length}
        hasNextPage={false}
      />
    </Box>
  );
};

export default CertificateComponent;

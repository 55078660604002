import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUserDataSelector } from '../../../../hooks';
import { ActionButton } from '../../../../ui-components';

import TitleHeader from '../../../../sub-components/CardEditor/TitleHeader';
import { useMembersData } from '../../../../sub-components/tasks/create-task/Supervisors';
import { CustomSelectComponent } from '../../../../sub-components/CustomDropdowns';
import { SelectOption } from '../../../../sub-components/tasks/create-task/common';

import { SelectGroup } from './select-group';
import { IFormInput } from './create-channel.types';
import { useSubmitChannel } from './useSubmitChannel';

export interface AddMemberProps {
  onBackClick?: () => void;
  onNextClick?: (values: IFormInput) => void;
}

const AddMemberForm: FC<AddMemberProps> = ({ onNextClick }) => {
  const { t } = useTranslation(['common', 'setting', 'invite', 'task']);

  const { control, handleSubmit, watch } = useFormContext<IFormInput>();

  const { isDirty } = useFormState({
    control: control,
    name: ['assignedToUser', 'autoAddMember', 'addToChannel'],
  });

  const isPublic = watch('isPublic');

  const entityId = useUserDataSelector((state) => state?.entityId);

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const [members, setMembers] = useState<SelectOption[]>([]);

  const { getUsersData, loading } = useMembersData(setMembers);

  useEffect(() => {
    getUsersData({
      variables: {
        entityId: entityId,
        status: ['active', 'inactive'],
        type: ['user'],
      },
    });
  }, [entityId]);

  const createChannel = useSubmitChannel();

  const onSubmit = async (values: IFormInput) => {
    try {
      if (!values.isPublic) {
        return onNextClick?.(values);
      }

      await createChannel(values);
      toast({
        status: 'success',
        title: t('common:success'),
        // @ts-ignore
        description: t('setting:channelCreateSuccess', {
          name: values.groupName?.trim(),
        }),
      });

      onNextClick?.(values);
    } catch (e) {
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('setting:channelCreateError'),
      });
      return Promise.reject(e);
    }
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Box>
        <TitleHeader title={t('setting:addMembers')} />

        <Controller
          control={control}
          name='assignedToUser'
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={2} isInvalid={!!fieldState?.error}>
                <CustomSelectComponent
                  placeholder={t('task:placeholder.selectMembers')}
                  placeholderColor='#1A1D1F'
                  headerTitle={t('task:placeholder.selectMembers')}
                  bottomOutsideBtn={{
                    show: true,
                    title: t('task:placeholder.addSelectMembers'),
                  }}
                  headerBarColor='#CABDFF'
                  searchFieldPlaceholder={t('setting:searchByNameJobOrLoc')}
                  options={members}
                  showFilter
                  value={field.value}
                  showDivider
                  showSelectAll
                  showSearchField
                  showHeader
                  showHeaderCloseIcon
                  onChange={field.onChange}
                  loading={loading}
                />
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Box>

      <Flex
        flexDir='column'
        gap={4}
        padding='16px 20px'
        bg='#F7F7F7'
        borderRadius='10px'
      >
        <Controller
          control={control}
          name='autoAddMember'
          render={({ field }) => {
            return (
              <Checkbox
                color='#33383F'
                maxW='fit-content'
                isChecked={field.value}
                onChange={field.onChange}
                ref={field.ref}
              >
                <Box fontSize='14px'>
                  {t('setting:autoAddNewMemberToChannel')}
                </Box>
              </Checkbox>
            );
          }}
        />

        <SelectGroup />
      </Flex>

      <Flex pb={2} gap={3}>
        <ActionButton
          width='full'
          fontSize='15px'
          colorScheme='blue'
          isDisabled={!isDirty}
          actionFn={handleSubmit(onSubmit)}
        >
          {isPublic ? 'Create channel (2/2)' : 'Next (2/3)'}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default AddMemberForm;

import React, { FC } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';

export const ChapterViewContainer: FC<FlexProps> = (props) => {
  return (
    <Flex
      id='chapter-view-container' // this id is used to calculate the width of this container
      pos='relative'
      flex={1}
      flexDir='column'
      mt='8px'
      maxWidth='max(875px, 65%)'
      width='full'
      // h='full'
      bg='#FFFFFF'
      mx='auto'
      borderRadius='8px'
      p='24px 16px'
      gap='16px'
      paddingBottom='8px'
      {...props}
    />
  );
};

import { UserTrainingItem } from '../../sub-components/TrackTrainingProgress/user-training.types';
import moment from 'moment/moment';

export const timelineDataConvert = (
  _userData: UserTrainingItem,
  userId: string
) => {
  console.log('userData avi --- ', _userData);

  let tempUserData = JSON.parse(JSON.stringify(_userData));
  const totalViewRoot = tempUserData?.viewedCards;
  const processedUserData: any[] = [];
  let userProgressData = tempUserData?.userProgress?.find(
    // @ts-ignore
    (userProgress) => userProgress?.userId === userId
  );
  if (userProgressData && userProgressData?.viewedContents) {
    // @ts-ignore
    tempUserData.trainingContentTiny.forEach((card) => {
      let userViewedDate = '';
      const viewedContent = userProgressData?.viewedContents?.find(
        // @ts-ignore
        (content) => {
          if (content.uid === card.eid) {
            userViewedDate = content?.lastViewedAt;
            return true;
          }
        }
      );
      const newCard = {
        ...card,
        completed: !!viewedContent,
        status: !!viewedContent,
        watchDetails: {
          contentLastUpdated: card?.updatedAt,
          userLastWatched: userViewedDate,
          didUserWatchedLatestContent:
            new Date(userViewedDate) >= new Date(card?.updatedAt),
        },
      };
      processedUserData.push(newCard);
    });
  }
  let moments = (userProgressData?.viewedContents &&
    userProgressData?.viewedContents.map((content: any) =>
      moment(content?.viewedAt)
    )) || [moment()];
  let maxDate = moment.max(moments).format('DD MMM YYYY, hh:mm A');
  processedUserData?.push({
    status:
      userProgressData?.viewedContents?.length ===
      tempUserData?.trainingContentTiny?.length,
    date: maxDate,
    lastViewedAt: userProgressData?.lastViewed,
    contentLastUpdate: tempUserData?.contentLastUpdate,
  });

  // setProgressData({
  //   allMilestoneData: processedUserData,
  //   showEmptyState: !totalViewRoot,
  //   userData: tempUserData,
  //   trainingName: tempUserData?.title,
  // });
  // setProgressTimelineModal(true);

  return {
    allMilestoneData: processedUserData,
    showEmptyState: !totalViewRoot,
    userData: tempUserData,
  };
};

import React, { FC, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { userObj } from 'sop-commons/src/client';

import { IFormInput } from '../task.types';

import { MemberSelection, SelectOption } from '../common';
import TitleHeader from '../../../CardEditor/TitleHeader';
import { toArray } from '../../../../utils/utils';

interface IProps {}

const JobAssignee: FC<IProps> = () => {
  const { t } = useTranslation(['task']);

  const { control } = useFormContext<IFormInput>();
  const assignToType = useWatch<IFormInput, 'assignToType'>({
    name: 'assignToType',
  });

  const authUser = useReactiveVar(userObj);

  const roleList = useMemo((): SelectOption[] => {
    return toArray(authUser?.entity?.roles).map((it) => {
      return {
        label: it.name,
        value: it.name,
      };
    });
  }, [authUser?.entity?.roles]);

  if (!assignToType?.includes('job')) {
    return null;
  }

  return (
    <Box pt={4} pb={5} borderTop='1px solid #E9E9E9'>
      <TitleHeader title={t('task:assignJobs')} />

      <Controller
        control={control}
        name='assignedToRole'
        rules={{
          required: t('task:validation.jobRequired'),
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={2} isInvalid={!!fieldState?.error}>
              <MemberSelection
                placeholder={t('task:placeholder.selectJobs')}
                members={roleList}
                value={field.value}
                onChange={field.onChange}
                hideChipsImage
                chipsColor='rgba(255, 216, 141, 0.6)'
              />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default JobAssignee;

import styled from '@emotion/styled';

export const FormResponseTableWrapper = styled.div`
  flex: 1;

  /* table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    border-radius: 10px;
  }

  th,
  tr,
  td {
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 15px;
    text-align: start;
  }

  th {
    text-align: start;
  }

  td {
    vertical-align: top;
  }

  .responsive-div {
    min-width: 100px;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 1rem;
    overflow: hidden;
  }

  .no-response {
    margin-top: 16px;
    font-weight: 700;
    font-size: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
`;

export const ResponsiveDivWrapper = styled.div`
  min-width: 200px;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

import React, { FC } from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { UseFieldArrayInsert, useFormContext, useWatch } from 'react-hook-form';
import { match } from 'ts-pattern';
import { v4 as uuidv4 } from 'uuid';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

import { ActionMenu } from '../../../../../ui-components';

import { IFormInput } from '../editor.types';
import { eventBus } from '../../../../../shared/eventEmit';

interface IProps {
  index: number;
  addNewTask: UseFieldArrayInsert<IFormInput, `contents.${number}.tasks`>;
}

const AddNewTaskAction: FC<IProps> = ({ addNewTask, index }) => {
  const { setValue, getValues } = useFormContext<IFormInput>();

  const hasNewTask = useWatch<IFormInput, 'newTaskAddress'>({
    name: 'newTaskAddress',
  });

  // TODO more test is required there
  const onClick = () => {
    const currentIndex = getValues('activeStep');
    const nextIndex = +hasNewTask!.split('.')[1];

    if (currentIndex !== nextIndex) {
      setValue('activeStep', nextIndex);
      setTimeout(() => eventBus.emit('newLauncherTask'), 1000);
    } else {
      eventBus.emit('newLauncherTask');
    }
  };

  if (hasNewTask) {
    return (
      <Tooltip
        label='You have one unsaved task, please save the task before adding another one'
        hasArrow
        borderRadius='6px'
        padding='4px 8px'
        placement='top-end'
      >
        <IconButton
          size='sm'
          aria-label={'alt'}
          variant='ghost'
          icon={<AddIcon color='#6F767E' />}
          onClick={onClick}
        />
      </Tooltip>
    );
  }

  return (
    <ActionMenu
      menuData={[
        {
          name: 'Add new task above',
          value: 'add-above',
          normalIcon: 'fontAwesome',
          icon: faChevronUp,
          textColor: '#1A1D1F',
        },
        {
          name: 'Add new task below',
          value: 'add-below',
          normalIcon: 'fontAwesome',
          icon: faChevronDown,
          textColor: '#1A1D1F',
        },
      ]}
      clickedItem={(_, value) => {
        const activeIndex = getValues('activeStep');
        const nextIndex = match(value)
          .with('add-below', () => index + 1)
          .otherwise(() => index);

        if (nextIndex >= 0) {
          const tempEid = uuidv4();
          addNewTask(nextIndex, {
            tempEid: tempEid,
            title: '',
            steps: [],
            selected: true,
            isNew: true,
          } as never);

          setValue('taskEditable', tempEid);

          setValue(
            'newTaskAddress',
            `contents.${activeIndex}.tasks.${nextIndex}`
          );
        }
      }}
    >
      <IconButton
        size='sm'
        aria-label={'alt'}
        variant='ghost'
        icon={<AddIcon color='#6F767E' />}
      />
    </ActionMenu>
  );
};

export default AddNewTaskAction;

import { gql } from '@apollo/client';
import { UserStatus } from '../../../../../types';

export interface LocationResponse {
  branch: {
    descendantBranches: Array<{
      name: string;
      eid: string;
      status: string;
    }>;
    descendantUsers: Array<{
      name: string;
      eid: string;
      role: string;
      authRole: string;
      locations: {
        eid: string;
        name: string;
      };
      status: UserStatus;
    }>;
  };
}

export interface MembersResponse {
  userDescendants: Array<{
    authRole: string;
    eid: string;
    name: string;
    profilePic: string;
    role: string;
    type: string;
    username: string;
    status: string;
    locations: Array<{
      eid: string;
      name: string;
    }>;
  }>;
}

export const getLocations = gql`
  query T_TL_C_MF_LocationList {
    branch {
      descendantBranches {
        name
        eid
        status
      }
      descendantUsers {
        eid
        name
        role
        authRole
        status
      }
    }
  }
`;

export const getMembers = gql`
  query T_TL_C_MF_MemberUsers {
    userDescendants {
      authRole
      eid
      name
      profilePic
      role
      type
      username
      status
      locations {
        eid
        name
      }
    }
  }
`;

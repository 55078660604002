import { FC, Fragment } from 'react';
import { Flex, Spacer } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'atoms/PrimaryButton';

interface IProps {
  onCancel?: () => void;
  isEdit?: boolean;
  isLoading: boolean;
  isSaving: boolean;
  disabled?: boolean;
}

const CardEditorFooter: FC<IProps> = ({
  onCancel,
  isEdit,
  isLoading,
  isSaving,
  disabled,
}) => {
  const { t } = useTranslation(['common', 'card']);
  return (
    <Flex mt={8}>
      <PrimaryButton
        type='button'
        size='lg'
        title={t('cancel')}
        onClick={onCancel}
        style={{ maxWidth: '159px' }}
        variant='outline'
        className='profile-button-save-container'
        disabled={isLoading || isSaving}
      />
      <Spacer />
      <Flex>
        {isEdit ? (
          <PrimaryButton
            type='submit'
            size='lg'
            isLoading={isLoading}
            // disabled={!isValid}
            title={t('card:update_exit')}
            // onClick={onUpdateUser}
            style={{ maxWidth: '159px' }}
            variant='solid'
            colorScheme='blue'
            disabled={disabled}
            className='profile-button-save-container'
          />
        ) : (
          <Fragment>
            <PrimaryButton
              width={'auto'}
              type='submit'
              size='lg'
              isLoading={isLoading}
              title={t('card:save_exit')}
              style={{ marginRight: '16px' }}
              variant='outline'
              colorScheme='blue'
              disabled={disabled || isSaving}
              className='profile-button-save-container'
            />
            <PrimaryButton
              width={'auto'}
              type='submit'
              size='lg'
              isLoading={isSaving}
              title={t('card:save_add_other')}
              style={{}}
              variant='solid'
              colorScheme='blue'
              disabled={disabled || isLoading}
              className='profile-button-save-container'
              name={'addAnother'}
            />
          </Fragment>
        )}
      </Flex>
    </Flex>
  );
};

export default CardEditorFooter;

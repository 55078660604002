import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';

export interface IFilterBadge {
  badgeTxt: string;
  active?: boolean;
}

const Wrapper = styled.div<{ active: boolean }>`
  width: fit-content;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 2px 8px 4px 8px;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.active ? '#FFFFFF' : '#84818A')};
  background-color: ${(props) => (props.active ? '#84818A' : '#EBEAED')};
`;

const FilterBadge: FC<IFilterBadge> = (props) => {
  const { badgeTxt, active = false } = props;
  return <Wrapper active={active}>{badgeTxt}</Wrapper>;
};

export default FilterBadge;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { BoxHeader } from '../../../../../../ui-components';
import NotificationSettingsForm from './NotificationSettingsForm';

class NotificationSettings extends Component {
  render() {
    return (
      <Modal isOpen onClose={this.props.onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent minWidth='700px' padding='16px 12px'>
          <ModalHeader>
            <Flex align='center' justify='space-between' gap={3}>
              <BoxHeader color='#CABDFF' title='Notifications settings' />

              <ModalCloseButton
                position='relative'
                top='unset'
                right='unset'
                outline='none'
              />
            </Flex>
          </ModalHeader>

          <ModalBody py={4}>
            <NotificationSettingsForm groupId={this.props.groupId} />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
}

NotificationSettings.propTypes = {
  groupId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default NotificationSettings;

import React, { useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DeleteTrainingResponse,
  DELETE_TRAINING_QUERY,
  getTrackTrainings,
  getTrainingDetails,
  TrackTrainingsResponse,
  TrainingDetailResponse,
  TrainingUpdateVariable,
} from '../training.graphql';
import { SelectOption } from 'atoms';
import { UPDATE_TRAINING } from '../../training.graphql';
import { useCallback, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TrainingData } from 'pages/Training/training.types';
import { useUserDataSelector } from 'hooks';
import { PathShowValue } from '../TrackingContainer';
import { TrainingPathListModal } from '../modal';

export const useServiceLayer = (
  query: string | undefined,
  selectedPage: number,
  sortBy: string,
  showValue: SelectOption<PathShowValue> | undefined,
  setTrainingPlayData: React.Dispatch<
    React.SetStateAction<TrainingData | undefined>
  >
) => {
  const { t } = useTranslation(['common', 'training']);

  const loggedInUserData = useUserDataSelector((state) => {
    return {
      eid: state?.eid,
      authRole: state?.authRole,
      type: state?.type,
    };
  });
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const getOptions = useCallback(() => {
    const filter: Record<string, unknown> = {};

    switch (showValue?.value) {
      case 'createdByMe':
        filter.creator = true;
        break;
      case 'supervisedByMe':
        filter.supervisor = true;
        break;
    }

    if (query) {
      filter.query = query;
    }

    return {
      page: selectedPage,
      perPage: 10,
      filter: filter,
      sort: sortBy,
    };
  }, [
    loggedInUserData?.authRole,
    loggedInUserData?.type,
    query,
    selectedPage,
    showValue,
    sortBy,
  ]);

  useEffect(() => {
    fetchTraining({
      variables: getOptions(),
    });
  }, [getOptions]);

  const [fetchTraining, { data, loading }] =
    useLazyQuery<TrackTrainingsResponse>(getTrackTrainings, {
      fetchPolicy: 'network-only',
    });

  const dataList = useMemo(() => {
    return new TrainingPathListModal(data?.trackTrainingPagination?.items).data;
  }, [data]);

  const { currentPage, hasNextPage, itemCount } =
    data?.trackTrainingPagination?.pageInfo || {};

  const [updateTraining] = useMutation<never, TrainingUpdateVariable>(
    UPDATE_TRAINING,
    {
      refetchQueries: [
        {
          query: getTrackTrainings,
          variables: getOptions(),
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toast({
          title: t('training:training_path_update_success_toast'),
          status: 'success',
        });
      },
      onError: () => {
        toast({
          title: t('training:training_path_update_error_toast'),
          status: 'error',
        });
      },
    }
  );

  const [deleteTraining] = useMutation<DeleteTrainingResponse>(
    DELETE_TRAINING_QUERY,
    {
      refetchQueries: [
        {
          query: getTrackTrainings,
          variables: getOptions(),
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toast({
          title: t('training:trainingDeleteSuccess'),
          status: 'success',
        });
      },
      onError: () => {
        toast({
          title: t('training:trainingDeleteError'),
          status: 'error',
        });
      },
    }
  );

  return {
    currentPage,
    dataList,
    hasNextPage,
    itemCount,
    loading,
    deleteTraining,
    updateTraining,
  };
};

import { Box, Flex, useOutsideClick } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import { AuthRole } from 'authorization';
import { chakraComponents } from 'chakra-react-select';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ISelectedFilter = SelectOption | null | undefined;

export interface AuthRoleFilterOptions {
  label: string;
  value: AuthRole;
}
interface IProps {
  options: AuthRoleFilterOptions[];
  selectedFilter: ISelectedFilter;
  onFilterChange: (option: SelectOption) => void;
}

const Wrapper = styled.div`
  width: 100%;
  .auth-role-filter > [data-invalid='true'] {
    color: inherit;
    background-color: #f4f4f4;
  }
`;

const AuthRoleFilter: FC<IProps> = ({
  options,
  selectedFilter,
  onFilterChange,
}) => {
  const { t } = useTranslation(['handbook']);
  return (
    <Wrapper>
      <Dropdown
        className='auth-role-filter'
        placeholder='Filter by'
        value={selectedFilter}
        onChange={onFilterChange}
        options={options}
        selectStyles={{
          singleValue: {
            color: '#6f767e',
            fontWeight: '600',
          },
        }}
        components={{
          SingleValue: (valProps) => {
            return (
              <chakraComponents.SingleValue {...valProps}>
                {/* @ts-ignore */}
                {t('handbook:filterBy', {
                  value: valProps.children,
                })}
              </chakraComponents.SingleValue>
            );
          },
        }}
      />
    </Wrapper>
  );
};

export default AuthRoleFilter;

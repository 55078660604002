import React, { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Switch,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

import { QuestionType } from 'types';

import { IFormInput } from '../form.types';
import FormInput from '../../../../atoms/FormInput';
import AnswerSelection from './AnswerSelection';

interface IProps {
  currentIndex: number;
  questionIndex: number;
  isReadOnly?: boolean;
  onRemove?: () => void;
}

const OptionInput: FC<IProps> = ({
  questionIndex,
  currentIndex,
  isReadOnly,
  onRemove,
}) => {
  const { t } = useTranslation('form');
  const { control } = useFormContext<IFormInput>();

  const questionType: QuestionType = useWatch({
    control: control,
    name: `questions.${questionIndex}.qType`,
  });

  const isArchieved: boolean = useWatch({
    name: `questions.${questionIndex}.options.${currentIndex}.isArchieved`,
  });

  const isOther: boolean = useWatch({
    name: `questions.${questionIndex}.options.${currentIndex}.isOther`,
  });

  if (isArchieved) {
    return null;
  }

  return (
    <Flex mt={5}>
      <AnswerSelection
        questionType={questionType}
        currentIndex={currentIndex}
        questionIndex={questionIndex}
        isReadOnly={isReadOnly}
      />

      <Controller
        name={`questions.${questionIndex}.options.${currentIndex}.subTitle`}
        control={control}
        render={({ field }) => {
          return <Input size='lg' {...field} hidden isReadOnly={isReadOnly} />;
        }}
      />

      <Controller
        name={`questions.${questionIndex}.options.${currentIndex}.isDisabled`}
        render={({ field }) => {
          return (
            <Switch
              isChecked={field.value}
              onChange={field.onChange}
              hidden
              isReadOnly={isReadOnly}
            />
          );
        }}
      />

      <Controller
        name={`questions.${questionIndex}.options.${currentIndex}.label`}
        control={control}
        rules={{
          required: {
            value: !isOther,
            message: t('validation.option_required'),
          },
          validate: (value) => {
            if (value?.trim()?.length === 0 && !isOther) {
              return t('validation.option_enter');
            }
          },
          deps: [`questions.${questionIndex}.label`],
        }}
        defaultValue=''
        render={({ field, fieldState }) => {
          return (
            <FormControl
              ml={4}
              isInvalid={!!fieldState.error}
              isReadOnly={isReadOnly || isOther}
            >
              <FormInput
                size='lg'
                {...field}
                placeholder={isOther ? t('other') : undefined}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
      <Flex h={12} align='center' pl={2} mr={-3}>
        <IconButton
          aria-label={'remove'}
          size='xs'
          icon={<CloseIcon />}
          onClick={onRemove}
        />
      </Flex>
    </Flex>
  );
};

export default OptionInput;

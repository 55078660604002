import {
  Col,
  Row,
  Typography,
  Layout,
  Button,
  message,
  Upload,
  Divider,
  Spin,
  Popconfirm,
  notification,
} from 'antd';
import { useEffect, useState } from 'react';
import { OnboardingMenu } from '../../ui-components/OnboardingMenu/OnboardingMenu';
import { TextInput } from '../../ui-components/TextInput';
import {
  CloudUploadOutlined,
  LinkOutlined,
  CloseCircleFilled,
  PaperClipOutlined,
  DeleteFilled,
  DeleteTwoTone,
} from '@ant-design/icons';
import { ReactComponent as PdfSvg } from '../../assets/images/pdf.svg';
import { ReactComponent as DocSvg } from '../../assets/images/doc.svg';
import { ReactComponent as CsvSvg } from '../../assets/images/csv.svg';
import { ReactComponent as DeleteSvg } from '../assets/images/Delete.svg';
import { ReactComponent as AdditionalNotes } from '../../assets/images/AdditionalNotes.svg';
import { ReactComponent as FileLink } from '../../assets/images/FileLink.svg';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import axios from 'axios';
import { UploadChangeParam } from 'antd/lib/upload';
import { Header } from '../../ui-components/Header';
import './AddSop.css';
import { useHistory } from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/uploading-to-cloud.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faChevronDown,
  faPlusCircle,
  faCheckSquare,
  faImage,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { useDebouncedCallback } from 'use-debounce';
import {
  faFileImage,
  faFileVideo,
  faRedoAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { faLink } from '@fortawesome/pro-light-svg-icons';
import { UPDATE_ENTITY_ONBOARDING_FLAG, UPDATE_USER } from '../SopList';
import { userObj, client } from 'sop-commons/src/client/clientFactory';
import { GET_USER } from '../Login/login.graphql';

const { Title, Text } = Typography;

const GET_SIGNED_URL = gql`
  mutation GetSignedUrlMutation($getSignedUrlInput: [FileDetailsInput]) {
    getSignedUrl(input: $getSignedUrlInput) {
      signedRequestUrl
      url
      mimetype
      s3_filename
      filename
    }
  }
`;
const ADD_SOP_MUTATION = gql`
  mutation AddSopRequestsMutation($input: SopRequestsInput) {
    addSopRequests(input: $input) {
      chapter
      additionalData
      files {
        _id
        url
        uploadedAt
        uploadedBy
        mimetype
        filename
      }
      links
      status
      category
      createdBy {
        eid
        email
        name
        phone
        authRole
        profilePic
        role
        account {
          verification {
            verified
          }
        }
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      eid
    }
  }
`;
const UPDATE_SOP_REQUESTS_MUTATION = gql`
  mutation UpdateSopRequests($input: SopRequestsUpdateInput) {
    updateSopRequests(input: $input) {
      chapter
      files {
        filename
        mimetype
        uploadedBy
        url
        _id
      }
      links
      status
      additionalData
      category
      createdBy {
        email
        name
        eid
      }
      eid
      updatedAt
      createdAt
    }
  }
`;
const GET_SOP_REQUESTS = gql`
  query Query($page: Int, $perPage: Int) {
    SopRequestsPagination(page: $page, perPage: $perPage) {
      items {
        chapter
        files {
          filename
          mimetype
          uploadedBy
          uploadedAt
          url
          _id
        }
        links
        additionalData
        status
        category
        entity {
          name
          address
          profilePic
          roles {
            status
            name
          }
          bundles {
            name
            status
          }
          category {
            name
            status
          }
          updatedAt
          createdAt
          eid
        }
        updatedAt
        createdAt
        eid
      }
      count
    }
  }
`;

const REMOVE_SOP = gql`
  mutation RemoveSopRequestsMutation($eid: String!) {
    removeSopRequests(eid: $eid) {
      succeed
    }
  }
`;
interface file {
  name?: string;
  type?: string;
}
interface sop {
  category?: string;
  chapter?: string;
  files?: object[];
  links?: string[];
}
interface AddSopProps {
  location?: any;
  isOnboarded?: boolean;
}
interface RouteParams {
  passedRouteCategory?: any;
  sop?: sop;
}
const antIcon = (
  <LoadingOutlined style={{ fontSize: 45, color: '#000000' }} spin />
);

export const AddSop = ({ isOnboarded }: AddSopProps) => {
  const history = useHistory();
  const { location } = history;
  const { passedRouteCategory } = (location?.state as RouteParams) || {};
  const [subMenuStep, setSubMenuStep] = useState('0');
  const [chapterName, setChapterName] = useState('');
  const [fileLinks, setFileLinks] = useState<string[]>(['placeholder']);
  const [fileLinkError, setFileLinkError] = useState<number[]>([]);
  const [signedUrlData, setSignedUrlData] = useState<any>({});
  const [file, setFile] = useState<any>();
  const [fileArray, setFileArray] = useState<any>([]);
  const [sopArray, setSopArray] = useState([] as object[]);
  const [currentCategory, setCurrentCategory] =
    useState<string>('Company Policies');
  const [menuCategoryData, setMenuCategoryData] = useState<any>([]);
  const [currentCategorySop, setCurrentCategorySop] = useState<any>([]);
  const [menuProgressPercent, setMenuProgressPercent] = useState<any>(50);
  const [additionalNotes, setAdditionalNotes] = useState<any>('');
  const [isEditSopEnabled, setIsEditSopEnabled] = useState(false);
  const [uploadFailedFiles, setUploadFailedFilesArray] = useState<any>([]);
  const [editSopId, setEditSopId] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [fileUploadRetry, setFileUploadRetry] = useState(false);
  const [showDiscardDataPopUp, setShowDiscardDataPopUp] = useState(false);
  const userObject = useReactiveVar<any>(userObj);

  const {
    loading: getUserLoading,
    error: getUserError,
    data: getUserData,
  } = useQuery(GET_USER);

  const [
    getSopRequests,
    { loading: getsopLoading, error: getSopError, data: getSopData },
  ] = useLazyQuery(GET_SOP_REQUESTS, {
    onCompleted: (data) => {
      setSopArray(data?.SopRequestsPagination?.items);
    },
  });
  const { Dragger } = Upload;
  const [uploadFile] = useMutation(GET_SIGNED_URL, {
    onCompleted: (data) => {
      if (!fileUploadRetry) {
        let tempSignedUrlData = { ...signedUrlData };
        tempSignedUrlData[file?.uid] = data?.getSignedUrl?.[0];
        setSignedUrlData(tempSignedUrlData);
      }
    },
  });
  const [addSop, { loading: addSopLoading }] = useMutation(ADD_SOP_MUTATION, {
    refetchQueries: [
      {
        query: GET_SOP_REQUESTS,
        variables: {
          page: 1,
          perPage: 20,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      setSopArray(getSopData?.SopRequestsPagination?.items);
      message.success(`${chapterName} added successfully.`);
      if (showDiscardDataPopUp) {
        moveTonextCategory();
      }
      resetUpload();
    },
    onError: () => {
      message.error(`Not able to add chapter. Please try again.`);
    },
  });
  const [updateSopRequest, { loading: updateSopRequestLoading }] = useMutation(
    UPDATE_SOP_REQUESTS_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_SOP_REQUESTS,
          variables: {
            page: 1,
            perPage: 20,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        setSopArray(getSopData?.SopRequestsPagination?.items);
        message.success(`${chapterName} updated successfully.`);
        if (showDiscardDataPopUp) {
          moveTonextCategory();
        }
        resetUpload();
      },
      onError: () => {
        message.error(`Not able to update chapter. Please try again.`);
      },
    }
  );
  const [removeSop] = useMutation(REMOVE_SOP, {
    refetchQueries: [
      {
        query: GET_SOP_REQUESTS,
        variables: {
          page: 1,
          perPage: 20,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      message?.success('Chapter removed successfully');
    },
  });

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      const newUserObj = { ...userObject };
      newUserObj.account = {
        ...userObj?.account,
        onboardingStage: data?.updateUser?.account?.onboardingStage,
      };
      userObj(newUserObj);
    },
  });

  const onClickEdit = (editSop) => {
    let signedUrlData = {};
    setCurrentCategory(editSop?.category);
    setSubMenuStep(
      menuCategoryData
        ?.findIndex(
          (category: any, index: any) => category?.name === editSop?.category
        )
        ?.toString()
    );
    if (editSop?.files?.length > 0) {
      editSop?.files?.forEach((file) => {
        signedUrlData[file?._id] = file;
      });
    }
    setEditSopId(editSop?.eid);
    setIsEditSopEnabled(true);
    setChapterName(editSop?.chapter);
    setFileArray(editSop?.files);
    setFileLinks(
      editSop?.links?.length > 0
        ? [...editSop?.links, 'placeholder']
        : ['placeholder']
    );
    setAdditionalNotes(editSop?.additionalData);
    setSignedUrlData(signedUrlData);
    document
      .getElementById('header')
      .scrollIntoView({ block: 'start', behavior: 'smooth' });
  };
  useEffect(() => {
    const { entity, account } = userObject || {};
    const { category } = entity || {};
    setMenuCategoryData(category);
    if (account?.onboardingStage) {
      setCurrentCategory(account?.onboardingStage);
      setSubMenuStep(
        menuCategoryData
          ?.findIndex(
            (category: any, index: any) =>
              category?.name === account?.onboardingStage
          )
          ?.toString()
      );
    }
  }, [userObject]);

  useEffect(() => {
    if (passedRouteCategory) {
      setCurrentCategory(passedRouteCategory?.name);
      setSubMenuStep(
        menuCategoryData
          ?.findIndex(
            (category: any, index: any) =>
              category?.name === passedRouteCategory?.name
          )
          ?.toString()
      );
    }
    if (location?.state?.sop && location?.state?.isEditRequest) {
      const { sop } = (location?.state as RouteParams) || {};
      setCurrentCategory(sop?.category);
      setSubMenuStep(
        menuCategoryData
          ?.findIndex(
            (category: any, index: any) =>
              category?.name === passedRouteCategory?.name
          )
          ?.toString()
      );
      onClickEdit(sop);
    }
  }, [passedRouteCategory, location?.state?.sop, menuCategoryData]);

  useEffect(() => {
    getSopRequests({
      variables: {
        page: 1,
        perPage: 20,
      },
    });
  }, [getSopData]);

  useEffect(() => {
    const tempSopArray = getSopData?.SopRequestsPagination?.items?.filter(
      (sop: any, index: number) => sop?.category === currentCategory
    );
    setCurrentCategorySop(tempSopArray);
  }, [currentCategory, sopArray, getSopData]);

  const props = {
    multiple: true,
    method: 'put',
    action: (file) => {
      if (file?.size > 50000000) {
        message.error('Please upload file less than 50 MB');
      } else {
        setFile(file);
        setFileArray((prevFileArray) => [...prevFileArray, file]);
        const { name, type } = file;
        uploadFile({
          variables: {
            getSignedUrlInput: [{ filename: name, mimetype: type }],
          },
        });
      }
    },
    onChange(info: UploadChangeParam) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  const resetUpload = () => {
    setChapterName('');
    setFileArray([]);
    setFileLinks(['placeholder']);
    setSopArray([]);
    setSignedUrlData({});
    setAdditionalNotes('');
    setEditSopId('');
    setIsEditSopEnabled(false);
  };
  const handleRetryUpload = (file) => {
    const { name, type } = file;
    setFileUploadRetry(true);
    uploadFile({
      variables: {
        getSignedUrlInput: [{ filename: name, mimetype: type }],
      },
    }).then((data) => {
      const reader = new FileReader();
      reader.onload = readSucess;
      //reader.onloadend = addSopRequestCall;
      async function readSucess(event: any) {
        if (!file?.url) {
          let binaryString = event?.target!.result;
          let binary = atob((binaryString as string)!.split(',')[1]);
          let array = [];
          for (var j = 0; j < binary.length; j++) {
            array.push(binary.charCodeAt(j));
          }
          let blobData = new Blob([new Uint8Array(array)], {
            type: file?.type,
          });
          const fileUploadUrl = data?.data?.getSignedUrl?.[0]?.signedRequestUrl;
          await axios
            .put(fileUploadUrl, blobData)
            .then((res) => {
              const tempUploadFailedFiles = uploadFailedFiles?.filter(
                (failedFile: any) => failedFile?.uid !== file?.uid
              );
              setUploadFailedFilesArray(tempUploadFailedFiles);
              setFileUploadRetry(false);
            })
            .catch(function (error: any) {
              setUploadFailedFilesArray((prevUploadFailedFiles) => [
                ...prevUploadFailedFiles,
                file,
              ]);
            });
        }
        // convert image file to base64 string
      }
      if (!file?.url) {
        reader.readAsDataURL(file);
      }
    });
  };

  const addSopRequestCall = () => {
    if (isEditSopEnabled) {
      updateSopRequest({
        variables: {
          input: {
            chapter: chapterName,
            files: fileArray?.map((file: any, index: number) => ({
              filename: file?.name || file?.filename,
              mimetype: file?.type || file?.mimetype,
              uploadedBy: getUserData?.user?.eid,
              url: signedUrlData[file?.uid]
                ? signedUrlData[file?.uid]?.url
                : signedUrlData[file?._id]?.url,
            })),
            links: fileLinks.filter(
              (link, index) => link !== 'placeholder' && link.trim() !== ''
            ),
            additionalData: additionalNotes,
            eid: editSopId,
          },
        },
      });
    } else {
      if (uploadFailedFiles.length === 0) {
        const inputData = {
          chapter: chapterName,
          category: currentCategory,
          files: fileArray?.map((file: any, index: number) => ({
            filename: file?.name,
            mimetype: file?.type,
            uploadedBy: getUserData?.user?.eid,
            url: signedUrlData[file?.uid]
              ? signedUrlData[file?.uid]?.url
              : signedUrlData[file?._id]?.url,
          })),
          links: fileLinks.filter(
            (link, index) => link !== 'placeholder' && link.trim() !== ''
          ),
          createdBy: getUserData?.user?.eid,
          entity: getUserData?.user?.entity?.eid,
          additionalData: additionalNotes,
        };
        addSop({
          variables: {
            input: inputData,
          },
        });
      }
    }
    document
      .getElementById('header')
      .scrollIntoView({ block: 'end', behavior: 'smooth' });
  };
  const addSopRequestCallDebounce = useDebouncedCallback(() => {
    addSopRequestCall();
  }, 700);

  const addSopUploadFile = () => {
    if (fileLinkError?.length > 0) {
      message?.error('Please enter valid file link');
    } else {
      fileArray.forEach((file: any, index: number) => {
        const reader = new FileReader();
        reader.onload = readSucess;
        //reader.onloadend = addSopRequestCall;
        async function readSucess(event: any) {
          if (!file?.url) {
            let binaryString = event?.target!.result;
            let binary = atob((binaryString as string)!.split(',')[1]);
            let array = [];
            for (var j = 0; j < binary.length; j++) {
              array.push(binary.charCodeAt(j));
            }
            let blobData = new Blob([new Uint8Array(array)], {
              type: file?.type,
            });
            const fileUploadUrl = file?.uid
              ? signedUrlData[file?.uid]?.signedRequestUrl
              : signedUrlData[file?._id]?.signedRequestUrl;
            axios
              .put(fileUploadUrl, blobData)
              .then(async (res) => {
                //console.log('res', res);
              })
              .catch(function (error: any) {
                setUploadFailedFilesArray((prevUploadFailedFiles) => [
                  ...prevUploadFailedFiles,
                  file,
                ]);
                console.log('error in upload', error, file, uploadFailedFiles);
              });
          }
          // convert image file to base64 string
        }
        if (!file?.url) {
          reader.readAsDataURL(file);
        }
      });
      if (showDiscardDataPopUp) {
        setShowDiscardDataPopUp(false);
      }
      addSopRequestCallDebounce();
    }
  };
  const moveTonextCategory = () => {
    setPageLoading(true);
    setTimeout(() => {
      if (parseInt(subMenuStep) + 1 < menuCategoryData.length) {
        setSubMenuStep((parseInt(subMenuStep) + 1).toString());
        setCurrentCategory(menuCategoryData[parseInt(subMenuStep) + 1]?.name);
        setMenuProgressPercent((prevState: any) =>
          Math.ceil(prevState + 50 / menuCategoryData?.length)
        );
        updateUser({
          variables: {
            input: {
              onboardingStage:
                menuCategoryData[parseInt(subMenuStep) + 1]?.name,
            },
          },
        });
      } else {
        if (parseInt(subMenuStep) + 1 === menuCategoryData.length) {
          history?.replace('/onboarding/sop-list');
        } else {
          setSubMenuStep('0');
          setCurrentCategory(menuCategoryData[0]?.name);
        }
      }
      resetUpload();
      setPageLoading(false);
    }, 700);
  };
  const handleClick = () => {
    if (isDataNotPresent()) {
      setShowDiscardDataPopUp(false);
      moveTonextCategory();
    } else {
      setShowDiscardDataPopUp(true);
    }
  };
  const onClickConfirmKeep = () => {
    addSopUploadFile();
  };
  const onClickConfirmDiscard = () => {
    setShowDiscardDataPopUp(false);
    moveTonextCategory();
  };

  const renderFileIcon = (fileType: any) => {
    switch (fileType) {
      case 'application/pdf':
        return <PdfSvg style={{ fontSize: 50, alignSelf: 'center' }} />;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <DocSvg style={{ fontSize: 50 }} />;
      case 'text/csv':
        return <CsvSvg style={{ fontSize: 50 }} />;
      case 'video/mp4':
        return (
          <FontAwesomeIcon
            icon={faFileVideo}
            style={{ fontSize: 40 }}
            color={'#cb8777'}
          />
        );
      case 'image/png':
        return (
          <FontAwesomeIcon
            icon={faFileImage}
            style={{ fontSize: 40 }}
            color={'#4c6ef5'}
          />
        );
      default:
        return <DocSvg style={{ fontSize: 50 }} />;
    }
  };
  const getFileIconBackgroundColor = (fileType: any) => {
    switch (fileType) {
      case 'application/pdf':
        return 'rgba(134, 27, 27, 0.1)';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'rgba(17, 109, 188, 0.1)';
      case 'text/csv':
        return 'rgba(59, 165, 120, 0.1)';
      case 'video/mp4':
        return 'rgba(203, 135, 119, 0.1)';
      case 'image/png':
        return 'rgba(76, 110, 245, 0.1)';
      default:
        return 'rgba(17, 109, 188, 0.1)';
    }
  };

  const onClickFileRemove = (removeFile: any) => {
    const tempFileArray = fileArray.filter(
      (file: any, index: number) =>
        file?.uid !== removeFile?.uid || file?._id !== removeFile?._id
    );
    setFileArray(tempFileArray);
  };
  const onClickMenuItem = (menuItem: any, event: any) => {
    setSubMenuStep(event?.key);
    setCurrentCategory(menuItem?.name);
    resetUpload();
  };

  const isDataNotPresent = () => {
    let linkCount = 0;
    fileLinks?.forEach((link) => {
      if (link !== 'placeholder' && link.trim() !== '') {
        linkCount++;
      }
    });
    let isEmptyLink = linkCount === 0;
    if (!chapterName || (chapterName && isEmptyLink && !fileArray?.length)) {
      return true;
    }
  };
  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Layout
      style={{
        backgroundColor: '#fbf7f0',
        overflowY: 'auto',
        height: '100%',
        maxHeight: '100%',
      }}
    >
      {showDiscardDataPopUp && (
        <div
          style={{
            backgroundColor: '#FFFFFF',
            // height: 60,
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            right: 30,
            top: 20,
            zIndex: 99,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 64,
            boxShadow: '0px 0px 75px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: 10,
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h6 style={{ fontSize: 14, fontWeight: 'bold', marginTop: 0 }}>
              Discard this category
            </h6>
            <Button type='text'>
              <FontAwesomeIcon
                icon={faXmark}
                onClick={() => {
                  setShowDiscardDataPopUp(false);
                }}
                style={{ marginBottom: 5, marginRight: 3 }}
              />
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <h6 style={{ fontSize: 13, fontWeight: 500 }}>
              Discard & move to next category?
            </h6>
            <Button
              onClick={onClickConfirmKeep}
              type='text'
              style={{
                fontSize: 13,
                fontWeight: 'bold',
                color: '#4D8AF0',
                width: 50,
                paddingTop: 0,
                marginTop: 0,
                height: 0,
              }}
            >
              KEEP
            </Button>
            <Button
              onClick={onClickConfirmDiscard}
              type='text'
              style={{
                fontSize: 13,
                fontWeight: 'bold',
                color: '#C54039',
                paddingTop: 0,
                marginTop: 0,
                height: 0,
              }}
            >
              DISCARD
            </Button>
          </div>
        </div>
      )}
      <Header isOnboarded={isOnboarded} disableSearch={!isOnboarded} />
      <div
        id={'header'}
        className='logo'
        style={{ marginBlock: 30, marginRight: 100, marginLeft: 100 }}
      />
      <Row
        justify='center'
        style={{ marginBottom: 20 }}
        className='app-main-container'
      >
        <Col
          span={5}
          style={{
            borderRadius: 10,
            maxHeight: '100%',
            minWidth: 290,
            maxWidth: 350.98,
          }}
        >
          <OnboardingMenu
            sopRequestCategoryName={getSopData?.SopRequestsPagination?.items?.map(
              (sopItem: any, index: number) => sopItem?.category
            )}
            // getMenuItems={(data: any) => {
            //   setMenuCategoryData(data);
            // }}
            menuProgressBarPercent={menuProgressPercent}
            menuStyle={{
              minHeight: 533.5,
              fontSize: 16,
              borderRadius: 10,
              backgroundColor: '#FFFFFF',
            }}
            handleSelectCallback={onClickMenuItem}
            menuDefaultSelectedKeys={passedRouteCategory?.name ? [] : ['0']}
            currentSelectedKeys={[subMenuStep]}
            menuDefaultOpenKeys={['sub2']}
            menuMode='inline'
            isOnboarded={isOnboarded}
          />
        </Col>
        <Col
          span={18}
          style={{
            marginLeft: 20,
            maxWidth: 1360,
          }}
        >
          <div
            className='add-sop-file-upload'
            style={{
              borderRadius: 10,
              padding: 50,
              paddingTop: 37,
              backgroundColor: 'white',
              borderWidth: 0.7,
              border: 'solid',
              borderColor: '#dddddd',
            }}
          >
            {(addSopLoading || pageLoading || updateSopRequestLoading) && (
              <div
                style={{
                  display: 'flex',
                  marginTop: 10,
                  marginBottom: 40,
                  alignSelf: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spin size='large' indicator={antIcon} />
              </div>
            )}
            <Row justify='space-between'>
              <Col>
                <Title
                  style={{
                    fontFamily: 'Open Sans',
                    fontWeight: 'bolder',
                    fontSize: 28,
                  }}
                  level={2}
                >
                  {currentCategory}
                </Title>

                <Title
                  style={{
                    fontFamily: 'Open Sans',
                    fontWeight: 'lighter',
                    marginBottom: 70,
                    fontSize: 16,
                    color: '#777777',
                  }}
                  level={5}
                >
                  Upload your playbook, SOPs, training manuals or add links to
                  your files.
                </Title>
              </Col>
              <Button
                className='eventclass-add-sop-move-to-next-category-button'
                onClick={handleClick}
                style={{
                  marginTop: 32,
                  height: 40,
                  borderRadius: 5,
                  fontWeight: 600,
                  borderColor: '#4d8af0',
                  color: '#4d8af0',
                  backgroundColor: 'rgba(77, 138, 240, 0.1)',
                }}
                type='primary'
              >
                {parseInt(subMenuStep) + 1 === menuCategoryData?.length && (
                  <>Finish</>
                )}
                {parseInt(subMenuStep) + 1 < menuCategoryData?.length && (
                  <>Move to next category</>
                )}
              </Button>
            </Row>

            <Text
              style={{
                fontFamily: 'Open Sans',
                fontSize: 16,
                fontWeight: 600,
                color: '#999999',
              }}
            >
              Chapter Name
            </Text>
            <TextInput
              className='eventclass-add-sop-chapter-name-textinput'
              value={chapterName}
              handleKeyDown={null}
              style={{
                marginBottom: 70,
                fontFamily: 'Open Sans',
                fontSize: 25,
                paddingLeft: 0,
                paddingBlock: 20,
                borderBottom: 'solid',
                borderBottomWidth: 2.5,
                borderBottomColor: '#DDDDDD',
              }}
              bordered={false}
              size='large'
              onChange={(e: any) => setChapterName(e.target.value)}
              placeholder={
                'OSHA break waiver form”, “Employee uniform and code of conduct”.'
              }
            />
            <Text
              style={{
                color: '#4D8AF0',
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Upload your files
            </Text>
            <Col style={{ marginBottom: 20, marginTop: 10 }}>
              <Dragger
                className='eventclass-add-sop-upload'
                {...props}
                showUploadList={false}
                style={{
                  backgroundColor: 'white',
                  borderColor: 'black',
                  borderRadius: 10,
                }}
                height={316}
              >
                <p className='ant-upload-drag-icon'>
                  <Lottie
                    options={defaultUploadLottieOptions}
                    height={112}
                    width={112}
                  />
                </p>
                <p className='ant-upload-text'>
                  <strong>Choose file</strong> or <strong>drag here</strong>
                </p>
                <p className='ant-upload-hint'>SIZE LIMIT 50MB</p>
              </Dragger>
              <Row style={{ marginTop: 30 }}>
                {fileArray?.map((file: file, index: number) => {
                  return (
                    <Col
                      key={index}
                      style={{ marginInline: 15 }}
                      className='eventclass-add-sop-file'
                    >
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            height: 67,
                            width: 95,
                            backgroundColor: getFileIconBackgroundColor(
                              file?.type || file?.mimetype
                            ),
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 8,
                          }}
                        >
                          {renderFileIcon(file?.type || file?.mimetype)}
                          {uploadFailedFiles?.find(
                            (failedFile) => failedFile?.uid === file?.uid
                          ) && (
                            <div
                              style={{
                                position: 'absolute',
                                flexDirection: 'row',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                height: 67,
                                width: 95,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 8,
                                backgroundColor: 'rgba(224, 71, 71, 0.85)',
                              }}
                            >
                              <FontAwesomeIcon
                                onClick={() => {
                                  handleRetryUpload(file);
                                }}
                                style={{
                                  alignSelf: 'center',
                                  color: '#FFFFFF',
                                  width: 21,
                                  height: 21,
                                  marginLeft: 10,
                                }}
                                icon={faRedoAlt}
                              />
                            </div>
                          )}
                          <div
                            style={{
                              position: 'absolute',
                              top: -8,
                              right: -5,
                              fontSize: 15,
                            }}
                          >
                            <CloseCircleFilled
                              onClick={() => onClickFileRemove(file)}
                            />
                          </div>
                        </div>
                      </div>
                      <Text
                        style={{
                          fontWeight: 600,
                          fontSize: 14,
                          width: 95,
                          height: 67,
                          color: uploadFailedFiles?.find(
                            (failedFile) => failedFile?.uid === file?.uid
                          )
                            ? '#e04747'
                            : '#999999',
                        }}
                        ellipsis={true}
                      >
                        {file?.name || file?.filename}
                      </Text>
                    </Col>
                  );
                })}
              </Row>
              <Col style={{ marginTop: 40, marginBottom: 40 }}>
                <Row style={{ alignItems: 'center' }}>
                  <Text
                    style={{
                      fontSize: 16,
                      color: '#999999',
                      fontWeight: 400,
                    }}
                  >
                    Add link to your files
                  </Text>
                  <FontAwesomeIcon
                    style={{
                      color: '#DDDDDD',
                      width: 17,
                      height: 17,
                      marginLeft: 10,
                    }}
                    icon={faPlusCircle}
                  />
                </Row>
                {fileLinks.map((fileLink, index: number) => {
                  return (
                    <TextInput
                      className='eventclass-add-sop-file-link'
                      value={fileLink === 'placeholder' ? '' : fileLink}
                      handleKeyDown={null}
                      key={index}
                      prefix={
                        <FontAwesomeIcon
                          style={{
                            alignSelf: 'center',
                            width: 18,
                            height: 19,
                            marginRight: 28,
                            color: fileLinkError?.includes(index)
                              ? '#DB4C3F'
                              : '#999999',
                          }}
                          icon={faLink}
                        />
                      }
                      size={'large'}
                      style={{
                        marginBottom: 20,
                        fontFamily: 'Open Sans',
                        borderBottom: 'solid',
                        borderBottomWidth: 2.5,
                        borderWidth: 2.5,
                        borderColor: fileLinkError?.includes(index)
                          ? '#DB4C3F'
                          : '#DDDDDD',
                        paddingBottom: 18,
                        paddingTop: 30,
                        paddingLeft: 0,
                      }}
                      suffix={
                        fileLinks.length > 1 ? (
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            style={{
                              marginLeft: 10,
                              width: 21.7,
                              height: 21.7,
                              color: fileLinkError?.includes(index)
                                ? '#DB4C3F'
                                : '#999999',
                            }}
                            onClick={(e: any) => {
                              if (fileLinks.length > 1 && index > -1) {
                                const tempFileLink = [...fileLinks];
                                // const tempFileLinkError = [...fileLinkError];
                                // tempFileLinkError.filter(
                                //   (filelinkIndex) => filelinkIndex !== index
                                // );
                                tempFileLink.splice(index, 1);
                                setFileLinks(tempFileLink);
                                //setFileLinkError([...tempFileLinkError]);
                              }
                            }}
                          />
                        ) : (
                          <div />
                        )
                      }
                      onChange={(e: any) => {
                        const tempFileLink: any = [...fileLinks];
                        tempFileLink[index] = e?.target?.value;
                        setFileLinks([...tempFileLink]);
                        setTimeout(() => {
                          if (index + 1 === fileLinks.length) {
                            setFileLinks([...tempFileLink, 'placeholder']);
                          }
                        }, 0);
                      }}
                      onBlur={() => {
                        const linkRegex =
                          /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/;
                        let tempFileLinkError = fileLinkError?.filter(
                          (linkIndex) => linkIndex > fileLinks?.length - 1
                        );

                        fileLinks.forEach((link, index) => {
                          if (link !== 'placeholder') {
                            if (!linkRegex.test(link)) {
                              if (!tempFileLinkError?.includes(index)) {
                                tempFileLinkError.push(index);
                              }
                            } else {
                              tempFileLinkError = tempFileLinkError?.filter(
                                (filelinkIndex) => filelinkIndex !== index
                              );
                            }
                          }
                        });
                        setFileLinkError([...tempFileLinkError]);
                      }}
                      inputError={
                        fileLinkError?.includes(index)
                          ? 'Please enter valid link'
                          : ''
                      }
                      bordered={false}
                      placeholder={
                        'Link your docs, sheets, pdf, help center etc...'
                      }
                    />
                  );
                })}
                <div
                  style={{
                    marginTop: 87,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: '#999999',
                      fontWeight: 400,
                    }}
                  >
                    Additional Notes
                  </Text>
                  <TextInput
                    className='eventclass-add-sop-additional-notes'
                    value={additionalNotes}
                    handleKeyDown={null}
                    prefix={
                      <AdditionalNotes
                        style={{ marginRight: 30, height: 22.9, width: 19.7 }}
                      />
                    }
                    size={'large'}
                    style={{
                      fontSize: 25,
                      borderWidth: 2.5,
                      borderBottom: 'solid',
                      borderColor: '#DDDDDD',
                      paddingBottom: 18,
                      paddingTop: 30,
                      paddingLeft: 0,
                    }}
                    onChange={(e: any) => {
                      setAdditionalNotes(e?.target?.value);
                    }}
                    bordered={false}
                    placeholder={
                      'Add more instruction which you would like us to know...'
                    }
                  />
                </div>
              </Col>
            </Col>
            <Row
              style={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginBlock: 40,
              }}
            >
              <Col>
                <Button
                  className='eventclass-add-sop-move-to-next-category-button'
                  onClick={() => {
                    resetUpload();
                    document
                      .getElementById('header')
                      .scrollIntoView({ block: 'end', behavior: 'smooth' });
                  }}
                  style={{
                    height: 40,
                    borderRadius: 5,
                    fontWeight: 'bold',
                    borderColor: '#c54039',
                    color: '#c54039',
                    backgroundColor: 'rgba(197, 64, 57, 0.1)',
                    marginRight: 20,
                    width: 90,
                  }}
                  type='primary'
                >
                  Reset
                </Button>
              </Col>
              <Col>
                <Button
                  className='eventclass-add-sop-save-button'
                  disabled={isDataNotPresent() || fileLinkError?.length > 0}
                  onClick={addSopUploadFile}
                  style={{
                    backgroundColor:
                      isDataNotPresent() || fileLinkError?.length > 0
                        ? '#DDDDDD'
                        : '#4D8AF0',
                    color:
                      isDataNotPresent() || fileLinkError?.length > 0
                        ? '#999999'
                        : '#FFFFFF',
                    fontWeight: 'bold',
                    height: 40,
                    borderRadius: 5,
                  }}
                >
                  Save & Add New
                </Button>
              </Col>
            </Row>
          </div>
          <Col style={{ marginBottom: 65 }}>
            {currentCategorySop?.length > 0 && (
              <Divider
                type='horizontal'
                style={{
                  borderRadius: 12,
                  backgroundColor: 'white',
                  color: '#FFFFFF',
                  marginBottom: 61,
                  marginTop: 72,
                }}
              />
            )}
            {currentCategorySop?.length <= 0 && (
              <Text
                style={{
                  fontWeight: 'normal',
                  fontSize: 14,
                  color: '#FFFFFF',
                  lineHeight: 7,
                }}
              >
                **Even if you skip any categories, dont worry! You can always
                come back to these later.
              </Text>
            )}
            {getSopData?.SopRequestsPagination?.items?.map(
              (sop: any, index: number) => {
                return (
                  <Row
                    className='eventclass-add-sop-added-files add-sop-added-files '
                    key={index}
                    style={{
                      alignContent: 'center',
                      height: 62,
                      justifyContent: 'space-between',
                      alignItems: 'centert',
                      marginBottom: 16,
                      backgroundColor: 'white',
                      padding: 20,
                      borderRadius: 10,
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <FontAwesomeIcon
                        onClick={() => onClickEdit(sop)}
                        style={{
                          color: '#333b4f',
                          width: 25,
                          height: 15,
                          marginRight: 15,
                        }}
                        icon={faChevronDown}
                      />
                      <FontAwesomeIcon
                        style={{
                          color: '#3ba578',
                          width: 22,
                          height: 22,
                          marginRight: 22,
                        }}
                        icon={faCheckSquare}
                      />
                      <Text
                        style={{
                          fontFamily: 'Open Sans',
                          fontWeight: 700,
                          fontSize: 18,
                          alignSelf: 'center',
                        }}
                      >
                        {sop?.chapter}
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Open Sans',
                          fontWeight: 500,
                          fontSize: 18,
                          color: '#999999',
                          alignSelf: 'center',
                          marginInline: 8,
                        }}
                      >
                        in
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Open Sans',
                          fontWeight: 500,
                          fontSize: 18,
                          alignSelf: 'center',
                        }}
                      >
                        {sop?.category}
                      </Text>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {sop?.links?.length > 0 && (
                        <>
                          <LinkOutlined
                            style={{
                              marginLeft: 20,
                              marginRight: 5,
                              alignSelf: 'center',
                            }}
                          />
                          <Text style={{ alignSelf: 'center' }}>
                            {sop?.links?.length}
                          </Text>
                        </>
                      )}
                      {!!sop?.files?.length && (
                        <>
                          <PaperClipOutlined
                            style={{
                              marginLeft: 20,
                              marginRight: 5,
                              alignSelf: 'center',
                            }}
                          />
                          <Text style={{ alignSelf: 'center' }}>
                            {sop?.files?.length}
                          </Text>
                        </>
                      )}
                      <Popconfirm
                        title='Are you sure you want to delete this chapter?'
                        onConfirm={() => {
                          removeSop({
                            variables: {
                              eid: sop?.eid,
                            },
                          });
                        }}
                        overlayStyle={{ padding: 20 }}
                        placement='leftTop'
                        overlayInnerStyle={{ padding: 10 }}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button
                          type='text'
                          style={{
                            width: 20,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            style={{
                              fontSize: 16,
                              color: '#BBBBBB',
                              alignSelf: 'start',
                            }}
                          />
                        </Button>
                      </Popconfirm>
                    </div>
                  </Row>
                );
              }
            )}
            {getSopData?.SopRequestsPagination?.items?.length > 0 && (
              <Button
                onClick={() => {
                  if (isOnboarded) {
                    history.push('/');
                  } else {
                    history?.replace('/onboarding/sop-list');
                    // updateOnboardingStatus({
                    //   variables: {
                    //     input: {
                    //       onboarded: true,
                    //     },
                    //   },
                    // });
                  }
                }}
                type='primary'
                size='large'
                style={{
                  height: 46,
                  backgroundColor: '#333B4F',
                  color: 'white',
                  marginRight: 20,
                  borderRadius: 8,
                  marginBottom: 30,
                  borderWidth: 0,
                  fontWeight: 'bold',
                }}
                block={true}
              >
                {isOnboarded && <>Go To Dashboard</>}
                {!isOnboarded && <>Continue</>}
              </Button>
            )}
          </Col>
        </Col>
      </Row>
    </Layout>
  );
};

import React, { FC } from 'react';
import { MessagesDesc } from 'sub-components/forms';
import { AccessibilityType } from 'types';
import { NotAccessibleBase } from 'ui-components';
import { Messages } from './chapter-message.keys';

interface IProps {
  type: AccessibilityType;
}

const ChapterNotAccessible: FC<IProps> = ({ type }) => {
  return (
    <NotAccessibleBase
      type={type}
      titleKey={Messages[type]}
      descKey={MessagesDesc[type]}
    />
  );
};

export default ChapterNotAccessible;

import React, { FC } from 'react';
import Layout from 'atoms/Layout';
import { MixedLayout } from 'sop-commons/src/utils/cardLayouts';
import LayoutSelect from '../../ui-components/LayoutSelect/LayoutSelect';

import { ReactComponent as MixedSingleTopLayoutIcon } from '../../assets/images/mixedSingleTopLayout.svg';
import { ReactComponent as MixedSingleBottomLayoutIcon } from '../../assets/images/mixedSingleBottomLayout.svg';
import { ReactComponent as MixedHorizontalSplitLayoutIcon } from '../../assets/images/mixedHorizontalSplitLayout.svg';
import { ReactComponent as MixedVerticalSplitLayoutIcon } from '../../assets/images/mixedVerticalSplitLayout.svg';
import { ReactComponent as MixedHorizonalSecondColumnSplitLayoutIcon } from '../../assets/images/mixedHorizonalSecondColumnSplitLayout.svg';
import { ReactComponent as MixedVerticalSecondRowSplitLayoutIcon } from '../../assets/images/mixedVerticalSecondRowSplitLayout.svg';
import { ReactComponent as MixedQuadrantLayoutIcon } from '../../assets/images/mixedQuadrantLayout.svg';
import { useLayoutDisabled } from './hook';

interface IProps {
  layout: MixedLayout;
  onSelectLayout: (layout: MixedLayout) => void;
}

// setCardData({
//   ...cardJsonFormat(
//     cardData?.cardType,
//     layout,
//     entityId,
//     cardData?.backgroundColor,
//     null,
//     null,
//     cardData?.status
//   ),
// });

const MixedLayoutSelect: FC<IProps> = ({ layout, onSelectLayout }) => {
  const isDisabled = useLayoutDisabled();
  return (
    <LayoutSelect isDisabled={isDisabled}>
      <Layout
        isSelected={layout === MixedLayout.MIXED_TOP_IMAGE_TEXT}
        svgIcon={<MixedSingleTopLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.MIXED_TOP_IMAGE_TEXT);
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.MIXED_BOTTOM_IMAGE_TEXT}
        svgIcon={<MixedSingleBottomLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.MIXED_BOTTOM_IMAGE_TEXT);
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.MIXED_HORIZONTAL_SPLIT_IMAGE_TEXT}
        svgIcon={<MixedHorizontalSplitLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.MIXED_HORIZONTAL_SPLIT_IMAGE_TEXT);
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.MIXED_VERTICAL_SPLIT_IMAGE_TEXT}
        svgIcon={<MixedVerticalSplitLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.MIXED_VERTICAL_SPLIT_IMAGE_TEXT);
        }}
      />
      <Layout
        isSelected={
          layout === MixedLayout.MIXED_HORIZONTAL_SPLIT_SECOND_COLUMN_IMAGE_TEXT
        }
        svgIcon={<MixedHorizonalSecondColumnSplitLayoutIcon />}
        onSelect={() => {
          onSelectLayout(
            MixedLayout.MIXED_HORIZONTAL_SPLIT_SECOND_COLUMN_IMAGE_TEXT
          );
        }}
      />
      <Layout
        isSelected={
          layout === MixedLayout.MIXED_VERTICAL_SPLIT_SECOND_ROW_IMAGE_TEXT
        }
        svgIcon={<MixedVerticalSecondRowSplitLayoutIcon />}
        onSelect={() => {
          onSelectLayout(
            MixedLayout.MIXED_VERTICAL_SPLIT_SECOND_ROW_IMAGE_TEXT
          );
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.MIXED_QUADRANT_IMAGE_TEXT}
        svgIcon={<MixedQuadrantLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.MIXED_QUADRANT_IMAGE_TEXT);
        }}
      />
    </LayoutSelect>
  );
};

export default MixedLayoutSelect;

import React, { FC, useState } from 'react';
import { Divider, Flex, Spinner } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';

import { AuthRole } from 'sop-commons/src/client';
import { ActionButton } from '../../../../../../ui-components';

import { LaunchTaskSteps } from '../../../../../../pages/Home/components/LOInProgress/types';
import CommonLabel from '../../../../../LocationLaunchDashboard/InProgress/Singleton/CommonLabel';
import StepIcon from './StepIcon';
import { TrainingProgress } from './training-progress';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  padding-block: 4px;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    right: -16px;
    bottom: -0.5rem;
    left: -40px;

    border-radius: 5px;
    background: transparent;
    z-index: -1;
    transition: all 100ms ease-in-out;
  }

  &:hover::before {
    background: #f4f4f4;
  }
`;

interface IProps {
  step: LaunchTaskSteps;
  stepIndex: number;
  authRole: AuthRole;
  onClick: () => void;
  isLoading?: boolean;
  isSelected?: boolean;
  onViewFormResponse?: (step: LaunchTaskSteps) => Promise<void> | void;
}

const StepListItem: FC<IProps> = ({
  step,
  authRole,
  isLoading,
  onClick,
  isSelected,
  onViewFormResponse,
}) => {
  const [viewTraining, setTrainingView] = useState(false);

  const showLoader = isLoading && isSelected;

  const isDisabled =
    isLoading || (step.type === 'training' && step.isCompleted);

  return (
    <React.Fragment>
      <Wrapper>
        <Flex
          gap='5px'
          align='flex-start'
          cursor={isDisabled ? 'no-drop' : 'pointer'}
          onClick={isDisabled ? undefined : onClick}
        >
          {showLoader ? (
            <Spinner size='sm' mt='3px' mr='3px' />
          ) : !step.isCompleted ? (
            <FontAwesomeIcon
              icon={faSquare as IconProp}
              fontSize='20px'
              color='#6F767E'
              style={{ marginTop: '2px' }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faSquareCheck as IconProp}
              fontSize='20px'
              color='#2A85FF'
              style={{ marginTop: '2px' }}
            />
          )}

          <CommonLabel label={step?.title} fontSize='15px' color='#272B30' />
        </Flex>

        <Flex align='center' gap='10px'>
          {step?.type === 'form' &&
            step?.isCompleted &&
            [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(authRole) && (
              <ActionButton
                size='sm'
                width='full'
                disabled={isLoading}
                style={{
                  background: 'white',
                  fontSize: '13px',
                  fontWeight: '500',
                }}
                actionFn={() => onViewFormResponse?.(step)}
              >
                View response
              </ActionButton>
            )}

          {step?.type === 'training' &&
            step.trainingAssignee &&
            [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(authRole) && (
              <ActionButton
                size='sm'
                width='full'
                disabled={isLoading}
                style={{
                  background: 'white',
                  fontSize: '13px',
                  fontWeight: '500',
                }}
                actionFn={() => setTrainingView((s) => !s)}
              >
                {viewTraining ? 'Hide progress' : 'View progress'}
              </ActionButton>
            )}

          <StepIcon step={step} />
        </Flex>
      </Wrapper>
      <Divider my={2} />
      {viewTraining && (
        <TrainingProgress
          trainingId={step.stepId}
          userId={step.trainingAssignee}
        />
      )}
    </React.Fragment>
  );
};

export default StepListItem;

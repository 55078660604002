import React, { useCallback, useRef } from 'react';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { FileEntity } from 'types';

import UploadDocContent from './UploadDocContent';

interface IProps {
  onFileChange?: (file: FileEntity) => void;
}

type UseUploadDocument = (props: IProps) => void;

export const useUploadDocument = (): UseUploadDocument => {
  const { t } = useTranslation(['location']);
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const apolloClient = useApolloClient();

  return useCallback(({ onFileChange }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader
            fontSize='18px'
            title={t('location:addDocuments')}
            color='#CABDFF'
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      size: '2xl',
      content: (
        <UploadDocContent
          onFileChange={(file) => {
            onFileChange?.(file);
            confirmRef.current && setTimeout(confirmRef.current?.destroy);
          }}
        />
      ),
      contentProps: {
        pt: '12px',
        pb: '20px',
        px: '8px',
        borderRadius: '12px',
      },
      isCentered: true,
      footer: null,
    });
  }, []);
};

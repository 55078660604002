import { gql, useLazyQuery } from '@apollo/client';
import { UserTrainingItem } from '../../pages/Training/TrainingList/Component/user-training.types';

export interface WorkerTrackData {
  name: string;
  eid: string;
  type: string;
  role: string;
  authRole: string;
  email: string;
  createdAt: string;
  status: string;
  isExternal: boolean;
  profilePic: string;
  totalTrainingCount: number;
  completedTrainingCount: number;
  unfinishedTrainingCount: number;
  location?: string[];
}

export interface UserTrainingResponse {
  userTrainingDetails: Array<UserTrainingItem>;
}

export const getUsersTrainings = gql`
  query UserTrainingDetails($userId: String!, $userIds: [String]) {
    userTrainingDetails(userId: $userId) {
      contentLastUpdate
      eid
      lastUpdated
      status
      title
      thumbnail
      rawUserProgress(userIds: $userIds)
      contents {
        eid
        type
      }
    }
  }
`;

export const getTrainingContentTinyData = gql`
  query FindTrainingById($eid: String!) {
    trainingById(eid: $eid) {
      trainingContentTiny
    }
  }
`;

export const getAllTrainings = gql`
  query TrackTrainingProgressGetAllTrainings(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
  ) {
    userTrainingNew(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        title
        entityId
        createdBy {
          email
          username
          name
          phone
          profilePic
          eid
        }
        lastUpdatedBy {
          name
          profilePic
          eid
        }
        status
        thumbnail
        thumbnailColor
        description
        visibility
        updatedAt
        createdAt
        eid
        trainingContentList
        assignedToUsers {
          eid
          profilePic
          name
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

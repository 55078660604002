import React, { useCallback, useRef } from 'react';
import { Box } from '@chakra-ui/react';

import {
  BoxHeader,
  useConfirm,
  UseConfirm,
} from '../../../../../ui-components';

export const useTaskDelete = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback(
    (onDeleteClick?: (...args: any[]) => any | PromiseLike<any>) => {
      if (confirmRef.current) {
        confirmRef.current.destroy();
      }

      confirmRef.current = confirm({
        title: <BoxHeader title={'Are you sure?'} color='#CABDFF' />,
        content: (
          <Box fontSize='16px' fontWeight='500' color='#6F767E'>
            This will permanently delete this task and its contents.
          </Box>
        ),
        isCentered: true,
        size: '2xl',
        contentProps: {
          py: '12px',
          px: '8px',
          borderRadius: '10px',
        },

        okButtonProps: {
          size: 'lg',
          colorScheme: 'red',
          fontSize: '15px',
          minWidth: '150px',
        },
        cancelButtonProps: {
          size: 'lg',
          variant: 'outline',
          fontSize: '15px',
          minWidth: '150px',
        },

        okText: 'Yes, delete',
        onOK: onDeleteClick,
      });
    },
    []
  );
};

import { useWatch } from 'react-hook-form';
import { IFormInput, Stepper } from '../task.types';

export const useStepperIsOpen = (stepName: Stepper): boolean => {
  return (
    useWatch<IFormInput>({
      name: 'currentStep',
    }) === stepName
  );
};

export const useStepperIsCompleted = (stepName: Stepper): boolean => {
  return useWatch<IFormInput>({
    name: `completed.${stepName}`,
  }) as boolean;
};

export const useStepperIsFilled = (stepName: Stepper): boolean => {
  return useWatch<IFormInput>({
    name: `filled.${stepName}`,
  }) as boolean;
};

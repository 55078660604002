import React, { FC } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form';

import { TFuncKey } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';

import { FormInput, PrimaryButton } from '../../../../atoms';
import { ActionButton } from '../../../../ui-components';
import { ITitleEdit } from './base-launcher.types';

interface ITitleForm {
  isEdit?: boolean;
  title?: string;
  onClose?: () => void;
  onSubmit?: (value: string) => void | Promise<void>;
  addTitle?: TFuncKey<('launcher' | 'common')[], undefined>;
  formTitle?: TFuncKey<'launcher'[], undefined>;
}

export const TitleForm: FC<ITitleForm> = ({
  title,
  onClose,
  onSubmit,
  isEdit,
  addTitle = 'launcher:addNewPhase',
  formTitle,
}) => {
  const { t } = useTranslation(['common', 'launcher', 'task']);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      title: title,
    },
  });

  const _onSubmit = async (values: { title?: string }) => {
    await onSubmit?.(values.title!);
    onClose?.();
  };

  return (
    <Flex flexDir='column' py='8px' gap={3}>
      {!!formTitle && (
        <Box fontSize='15px' fontWeight='600' color='#1A1D1F' px={3}>
          {t(formTitle)}
        </Box>
      )}

      <Controller
        control={control}
        name={'title'}
        defaultValue=''
        rules={{
          required: t('task:validation.thisFieldRequired'),
          validate: (value) => {
            if (value?.trim()?.length === 0) {
              return t('common:validation.enter_title');
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState?.error}>
              <FormInput id='title' size='lg' autoFocus {...field} />
              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Flex gap={3}>
        {onClose && (
          <PrimaryButton
            size='lg'
            width='auto'
            variant='outline'
            title={t('common:close')}
            style={{
              minWidth: '120px',
            }}
            onClick={onClose}
          />
        )}
        <ActionButton
          size='lg'
          colorScheme='blue'
          flex={1}
          fontSize='15px'
          actionFn={handleSubmit(_onSubmit)}
        >
          <Trans t={t} i18nKey={isEdit ? 'common:update' : addTitle} />
        </ActionButton>
      </Flex>
    </Flex>
  );
};

interface IProps {
  index: number;
  title: string;
  onUpdate: (index: number, value: string) => void | Promise<void>;
}

const SectionTitleEditor: FC<IProps> = ({
  children,
  title,
  index,
  onUpdate,
}) => {
  const { setValue } = useFormContext<ITitleEdit>();

  const sectionTitle = useWatch<ITitleEdit, 'sectionTitle'>({
    name: 'sectionTitle',
  });

  return (
    <Popover
      isLazy
      isOpen={sectionTitle === index}
      placement='bottom-start'
      offset={[-28, 10]}
    >
      {/*// @ts-ignore */}
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent borderRadius='12px' width='440px' boxShadow='md'>
          <PopoverArrow />
          <PopoverBody px={4}>
            <TitleForm
              title={title}
              isEdit
              onSubmit={(value) => onUpdate?.(index, value)}
              onClose={() => setValue('sectionTitle', undefined)}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default SectionTitleEditor;

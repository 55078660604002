import moment from 'moment-timezone';

interface ITaskDate {
  taskId: string;
  startDate: string;
  dueDate: string;
}

export const getUniqueSessionTask = <T extends ITaskDate>(
  taskLists: T[],
  now: moment.Moment
): T[] => {
  const taskSessions = taskLists.reduce((acc, value) => {
    const prev = acc[value.taskId];

    const startDate = moment.utc(value.startDate);
    const dueDate = moment.utc(value.dueDate);

    if (now.isBetween(startDate, dueDate) || now.isAfter(dueDate)) {
      if (prev) {
        // const startCmp = moment(startDate.format('LLL'));
        // const prevStart = moment(prev.startDate).format('LLL');
        // acc[value.taskId] = startCmp.isAfter(prevStart) ? value : prev;

        acc[value.taskId] = startDate.isAfter(prev.startDate) ? value : prev;
      } else {
        acc[value.taskId] = value;
      }
    }

    return acc;
  }, {} as Record<string, T>);

  return Object.values(taskSessions);
};

// export function fileTOBlobData(file: File | Blob): Promise<Blob> {
//   // @ts-ignore
//   if (typeof file?.name === 'string') {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onload = (event) => {
//         let binaryString = event?.target!.result;
//         let binary = atob((binaryString as string)!.split(',')[1]);
//         let array = [];
//         for (let j = 0; j < binary.length; j++) {
//           array.push(binary.charCodeAt(j));
//         }
//         let blobData = new Blob([new Uint8Array(array)], {
//           type: file?.type,
//         });
//         resolve(blobData);
//       };
//       reader.onerror = () => reject(reader.result);
//       reader.readAsDataURL(file);
//     });
//   }
//   return Promise.resolve(file);
// }

export function fileTOBlobData(file: File | Blob): Promise<Blob> {
  // @ts-ignore
  if (typeof file?.name === 'string') {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event?.target?.result) {
          let blobData = new Blob([event.target.result as ArrayBuffer], {
            type: file.type,
          });
          resolve(blobData);
        } else {
          reject(new Error('File reading failed'));
        }
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  }
  return Promise.resolve(file);
}

// export function fileTOBlobData(file: File | Blob): Promise<Blob> {
//   const CHUNK_SIZE = 100 * 1024 * 1024; // 100 MB
//   const chunks = Math.ceil(file.size / CHUNK_SIZE);

//   let blobParts: ArrayBuffer[] = [];

//   return new Promise((resolve, reject) => {
//     const processChunk = (index: number) => {
//       const start = index * CHUNK_SIZE;
//       const end = Math.min(start + CHUNK_SIZE, file.size);
//       const blobSlice = file.slice(start, end);

//       const reader = new FileReader();

//       reader.onload = (event) => {
//         if (event.target?.result) {
//           blobParts.push(event.target.result as ArrayBuffer);

//           if (index < chunks - 1) {
//             // Process next chunk
//             processChunk(index + 1);
//           } else {
//             // All chunks processed, combine them into a single Blob
//             const combinedBlob = new Blob(blobParts, { type: file.type });
//             resolve(combinedBlob);
//           }
//         } else {
//           reject(new Error("File reading failed"));
//         }
//       };

//       reader.onerror = () => reject(reader.error);
//       reader.readAsArrayBuffer(blobSlice);
//     };

//     // Start processing from the first chunk
//     processChunk(0);
//   });
// }

import React, { FC } from 'react';
import { Box, Flex, Switch } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isCheckList } from 'utils/utils';
import { QuestionType } from 'types';

import { IFormInput } from '../form.types';
import BaseQuestionFooter, {
  notLastStyle,
  BQuesFooterProps,
} from './BaseQuestionFooter';

const DisabledImage = [
  QuestionType.IMAGE_UPLOAD,
  QuestionType.VIDEO_UPLOAD,
  QuestionType.LOCATION,
  QuestionType.MEMBER,
];

interface IProps extends BQuesFooterProps {}

const QuestionFooter: FC<IProps> = ({
  currentIndex,
  isReadOnly,
  handleDuplicate,
  handleRemove,
}) => {
  const { t } = useTranslation(['common', 'form']);
  const { control } = useFormContext<IFormInput>();

  const [formCategory, qType] = useWatch({
    control: control,
    name: ['formCategory.name', `questions.${currentIndex}.qType`],
  });

  return (
    <BaseQuestionFooter
      currentIndex={currentIndex}
      isReadOnly={isReadOnly}
      handleRemove={handleRemove}
      handleDuplicate={handleDuplicate}
    >
      <Controller
        name={`questions.${currentIndex}.hasImageNote`}
        render={({ field }) => {
          if (DisabledImage.includes(qType)) {
            return <React.Fragment />;
          }
          return (
            <Flex _notLast={notLastStyle}>
              <Box mr='8px' color='#33383f' fontWeight='600'>
                {t('form:enableAddingImage')}
              </Box>
              <Switch
                size='md'
                isChecked={field.value}
                onChange={field.onChange}
                isReadOnly={isReadOnly}
              />
            </Flex>
          );
        }}
      />

      <Controller
        name={`questions.${currentIndex}.isRequired`}
        render={({ field }) => {
          if (isCheckList(formCategory)) {
            return (
              <Switch
                size='md'
                isChecked={field.value}
                onChange={field.onChange}
                hidden
                isDisabled
              />
            );
          }
          return (
            <Flex _notLast={notLastStyle}>
              <Box mr='8px' color='#33383f' fontWeight='600'>
                {t('required')}
              </Box>
              <Switch
                size='md'
                isChecked={field.value}
                onChange={field.onChange}
                isReadOnly={isReadOnly}
                isDisabled={isCheckList(formCategory)}
              />
            </Flex>
          );
        }}
      />
    </BaseQuestionFooter>
  );
};

export default QuestionFooter;

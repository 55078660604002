import moment from 'moment';
import { IFormInput } from '../task.types';

export const startTimeValidation = (
  date: Date,
  values: IFormInput
): string | undefined => {
  const mDate = moment()
    .set('hour', date.getHours())
    .set('minute', date.getMinutes())
    .startOf('minute');

  const after = moment()
    .minute(Math.ceil((moment().minute() + 5) / 5) * 5)
    .format('hh:mm A');

  if (values.startDateType === 'now' && mDate.isBefore(moment())) {
    return `Minimum start time should be after ${after}`;
  }

  if (values.startDateType === 'date' && values.startDate) {
    if (moment(values.startDate).isSameOrBefore(moment(), 'day')) {
      const mDate = moment()
        .set('hour', date.getHours())
        .set('minute', date.getMinutes());
      if (mDate.isBefore(moment())) {
        return `Minimum start time should be after ${after}`;
      }
    }
  }

  return undefined;
};

// import components
import Loader from '../Loader';
import SearchListItem from './SearchListItem';
import Lottie from 'react-lottie';
import emptyCategory from '../../assets/lotties/emptyCategory.json';
// import css
import './SearchList.css';
const SearchList = ({ searchList, loading, onClose, searchQuery }) => {
  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyCategory,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div className='search-list-container'>
      {loading && (
        <div className='sop-list-loader'>
          <Loader />
        </div>
      )}
      {!loading &&
        !!searchList?.length &&
        searchList?.map((item: any, index: number) => {
          return (
            <SearchListItem
              key={index}
              item={item}
              onClose={onClose}
              searchQuery={searchQuery}
            />
          );
        })}
      {!loading && searchList?.length === 0 && (
        <div>
          <div className='search-empty-oh-crap-text'>
            Oh! crap, you’ve got nothing
          </div>
          <Lottie
            options={defaultUploadLottieOptions}
            height={200}
            width={400}
            style={{ marginTop: '30px' }}
          />
        </div>
      )}
    </div>
  );
};

export default SearchList;

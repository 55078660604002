import React from 'react';
import './InfiniteScroll.scss';
import { Spinner } from '@chakra-ui/react';

interface PropsInfinite {
  children?: React.ReactNode;
  loadMore?: () => void;
  hasMore: boolean;
  loader: boolean;
  loaderType: 'text' | 'spinner';
  loaderText?: string;
  loaderTextPosition?: 'left' | 'center' | 'right';
  loaderTextColor?: string;
  loaderTextWeight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
  loaderTextSize?: string;
  loaderTextMarginRight?: string;
  loaderTextMarginLeft?: string;
  loaderTextMarginTop?: string;
  loaderTextMarginBottom?: string;
  loaderSpinColor?: string;
  loaderSpinWidth?: string;
  loaderSpinSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  loaderSpinPosition?: 'left' | 'center' | 'right';
  loaderSpinTopMargin?: string;
  loaderSpinBottomMargin?: string;
  loaderSpinLeftMargin?: string;
  loaderSpinRightMargin?: string;
}

// eslint-disable-next-line react/display-name
const InfiniteScroll = (props: PropsInfinite) => {
  const getLoaderTextDivStyle = () => {
    return {
      display: 'flex',
      justifyContent: props.loaderTextPosition,
      alignItems: 'center',
    };
  };

  const getLoaderTextStyle = () => {
    return {
      color: props.loaderTextColor,
      fontWeight: props.loaderTextWeight,
      fontSize: props.loaderTextSize,
      marginRight: props.loaderTextMarginRight,
      marginLeft: props.loaderTextMarginLeft,
      marginTop: props.loaderTextMarginTop,
      marginBottom: props.loaderTextMarginBottom,
    };
  };

  const loaderHandler = () => {
    if (props.loaderType === 'text') {
      return (
        <div style={getLoaderTextDivStyle()}>
          <span style={getLoaderTextStyle()}>{props.loaderText}</span>
        </div>
      );
    } else {
      return (
        <div>
          <Spinner
            thickness={props.loaderSpinWidth}
            speed='0.65s'
            emptyColor='gray.200'
            color={props.loaderSpinColor}
            size={props.loaderSpinSize}
          />
        </div>
      );
    }
  };

  const childRenderHandler = () => {
    return props.children;
  };

  return (
    <div>
      <>
        {childRenderHandler()}
        {props.loader && loaderHandler()}
      </>
    </div>
  );
};

export default InfiniteScroll;

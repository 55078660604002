import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import EditorPreview, { ICON_TYPE, JSONContent } from 'delightree-editor';

interface IProps {
  value?: JSONContent;
  onItemClick?: (event: MouseEvent, node: any) => void;
  chapterIcon?: string;
  iconType?: string;
}

const ChapterDesktop: FC<IProps> = ({
  value,
  chapterIcon,
  iconType,
  onItemClick,
}) => {
  return (
    <Box
      width='100%'
      maxW='962px'
      borderRadius='8px'
      p='32px'
      pt='28px'
      bg='#FFFFFF'
      overflow='auto'
    >
      <Box>
        <EditorPreview
          readonly
          value={value}
          onItemClick={onItemClick}
          chapterIcon={chapterIcon}
          iconType={iconType === ICON_TYPE ? 'icon' : 'emoji'}
        />
      </Box>
    </Box>
  );
};

export default ChapterDesktop;

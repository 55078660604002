import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { IDateFilterPlaceholderProps } from '../types';

const DateFilterPlaceholder: FC<IDateFilterPlaceholderProps> = ({
  placeholder,
  leftIcon,
  rightIcon,
  selectedValue,
  onClick,
  resetFilterHandler,
}) => {
  return (
    <Flex
      align='center'
      justify='space-between'
      gap='5px'
      border={
        !selectedValue
          ? '1px solid rgba(239, 239, 239, 1)'
          : '1px solid rgba(42, 133, 255, 1)'
      }
      borderRadius='7px'
      bg='rgba(246, 246, 246, 1)'
      p='12px 14px'
      cursor='pointer'
      onClick={(e) => {
        e?.stopPropagation();
        onClick?.();
      }}
      minW='250px'
      maxW='fit-content'
      h='40px'
    >
      <Flex align='center' gap='5px'>
        {leftIcon || null}
        {!selectedValue ? (
          <Text fontWeight={400} fontSize='14px' color='rgba(26, 29, 31, 1)'>
            {placeholder}
          </Text>
        ) : (
          <Text fontWeight={600} fontSize='14px' color='rgba(42, 133, 255, 1)'>
            {selectedValue}
          </Text>
        )}
        {rightIcon || null}
      </Flex>
      <Flex>
        {!selectedValue ? (
          <ChevronDownIcon fontSize='20px' color='rgba(26, 29, 31, 1)' />
        ) : (
          <CloseIcon
            color='rgba(42, 133, 255, 1)'
            fontSize='11px'
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              resetFilterHandler();
            }}
            cursor='pointer'
          />
        )}
      </Flex>
    </Flex>
  );
};

export default DateFilterPlaceholder;

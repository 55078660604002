import { FC } from 'react';
import { Box, List, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Loader from '../Loader';
import SearchListItem from './SearchListItem';
import Illustrations from '../../assets/images/Illustrations.svg';
// import css
import './SearchList.scss';
import { BaseSearchModal, SearchModal } from 'modal';

interface IProps {
  searchList?: SearchModal[];
  loading: boolean;
  onSelectedItem: (item: BaseSearchModal) => void;
}

const SearchList: FC<IProps> = ({ searchList, loading, onSelectedItem }) => {
  const { t } = useTranslation('header');
  return (
    <div className='search-list-container'>
      {loading && (
        <div className='sop-list-loader'>
          <Loader size='xl' style={undefined} />
        </div>
      )}
      <List>
        {!loading &&
          !!searchList?.length &&
          searchList?.map((item) => {
            return (
              <SearchListItem
                key={item.eid}
                item={item}
                onSelectedItem={onSelectedItem}
              />
            );
          })}
      </List>
      {!loading && searchList?.length === 0 && (
        <Box maxWidth={'260px'} margin={'auto'}>
          <div className='imgCss'>
            <img src={Illustrations} alt='' />
          </div>
          <Text className='No-Search-Result'>{t('result_not_found')}</Text>
          <Text className='noResultFound'>{t('not_found_desc')}</Text>
        </Box>
      )}
    </div>
  );
};

export default SearchList;
SearchList.displayName = 'SubComponents/SearchList';

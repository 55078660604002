import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { TrainingData } from '../../pages/Training/training.types';
import RoleAndAuthRole from '../../ui-components/role-authrole/RoleAndAuthRole';
import { IconImage } from '../../ui-components';

const colorSetHandler = (index: number) => {
  let colorObj = [
    '#8830b0',
    '#30048c',
    '#60b569',
    '#02ade5',
    '#c7c37b',
    '#cc86a9',
    '#8b78e6',
    '#2578ae',
  ];
  return colorObj[index % 8];
};

interface IProps {
  index: number;
  pathName: string;
  item?: TrainingData;
  thumbnail?: string;
  status?: string;
  onClick?: (data?: TrainingData) => void;
  // visibility?: 'public' | 'private';
  authRole?: string;
  role?: string;
  address?: string | Record<string, string>;
}

const WorkerPathName: FC<IProps> = ({
  item,
  index,
  thumbnail,
  pathName,
  status,
  onClick,
  authRole,
  role,
  address,
}) => {
  const title = pathName?.trim();
  return (
    <Flex
      align='flex-start'
      style={{
        backgroundColor:
          status === 'active' || status === 'draft' ? 'none' : 'white',
        opacity: status === 'active' || status === 'draft' ? '1' : '.5',
        cursor:
          status === 'active' || status === 'draft' ? 'pointer' : 'no-drop',
      }}
      onClick={() => onClick?.(item)}
      gap='16px'
      alignItems={'center'}
    >
      {thumbnail ? (
        <IconImage
          name={title}
          thumbnail={thumbnail}
          boxSize={40}
          borderRadius='8px'
        />
      ) : (
        <Center
          style={{
            minWidth: 40,
            height: 40,
            borderRadius: 8,
            backgroundColor: colorSetHandler(index),
          }}
        >
          <span
            style={{
              fontSize: 'x-large',
              fontWeight: '600',
              color: 'white',
            }}
          >
            {title?.split('')?.[0]?.toUpperCase()}
          </span>
        </Center>
      )}

      <Flex gap='1px' direction='column' style={{ width: '80%' }}>
        <Box pos='relative' maxWidth='130px' width='max-content'>
          <Box
            style={{
              fontSize: '15px',
              letterSpacing: '-0.15px',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Box>
        </Box>

        <div
          style={{
            fontSize: '12px',
            fontWeight: '500',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#33383F',
          }}
        >
          {(address?.name || address)?.trim() || '--'}
        </div>
        {!authRole && !role ? null : (
          <RoleAndAuthRole authRole={authRole} role={role} defaultValue='N/A' />
        )}
      </Flex>
    </Flex>
  );
};

export default WorkerPathName;

import React, { FC } from 'react';
import { Question as QuestionData } from '../../../create-form/form.types';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@chakra-ui/react';
import { FormPreviewInput } from '../../form-preview.types';
import AcknowledgeInput from './AcknowledgeInput';

interface IProps {
  question: QuestionData;
  currentIndex: number;
  isPreview?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const Acknowledge: FC<IProps> = ({
  question,
  currentIndex,
  isPreview,
  isReadOnly,
  isDisabled,
}) => {
  const { control } = useFormContext<FormPreviewInput>();

  if (question.isArchieved) {
    return null;
  }
  return (
    <div className='custom-form-preview-question-con'>
      <Controller
        name={`response.${currentIndex}.qid`}
        control={control}
        defaultValue={question.eid}
        render={({ field }) => {
          return <Input size='lg' {...field} hidden />;
        }}
      />

      <div className='custom-form-preview-question-label-container'>
        <div className='custom-form-preview-question-acknowlege'>
          {question.label}
        </div>
      </div>

      <div className={`custom-form-${question.qType}`}>
        <AcknowledgeInput
          currentIndex={currentIndex}
          isPreview={isPreview}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default Acknowledge;

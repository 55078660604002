import React, { FC, useState } from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import ExperienceSideBar, { DefaultValue } from './ExperienceSideBar';
import { CloseIcon } from '@chakra-ui/icons';

import ExperienceVideo from './ExperienceVideo';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  marginRight?: string;
}

const MobileExperience: FC<IProps> = ({ isOpen, onClose, marginRight }) => {
  const [state, setState] = useState<string>(DefaultValue);

  const onChange = (newValue: string) => {
    setState(newValue);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />

      <ModalContent
        mt='auto'
        mr={marginRight}
        mb='54px'
        px='20px'
        py='22px'
        borderRadius='12px 12px 0 12px'
        containerProps={{
          justifyContent: 'flex-end',
        }}
        maxW='unset'
        w='auto'
      >
        <ModalCloseButton
          top={-2}
          right={-2}
          borderRadius='full'
          w={6}
          h={6}
          bg='black !important'
        >
          <CloseIcon h={2} w={2} color='white' />
        </ModalCloseButton>
        <Flex gap='16px'>
          <Box minW='180px'>
            <ExperienceSideBar value={state} onChange={onChange} />
          </Box>
          <ExperienceVideo state={state} />
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default MobileExperience;

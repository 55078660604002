import React, { FC } from 'react';

import {
  FormType,
  ActionType,
  ImageType,
  MixedType,
  QuizType,
  TextType,
  VideoType,
} from 'sop-commons/src/card-components';
import Milestone from 'sop-commons/src/card-components/layouts/newLayouts/Milestone';
import CustomFormType from './forms/from-preview/CustomFormType';

interface IProps {
  content: any;
  isPreview?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  isCheckClickedValue?: (value: boolean) => void;
  shouldMoveToNextCard?: () => void;
  shouldSetFixedHeight?: boolean;
}

const TrainingCard: FC<IProps> = ({
  content,
  isPreview,
  isDisabled,
  isCheckClickedValue,
  shouldMoveToNextCard,
  isActive,
  shouldSetFixedHeight,
}) => {
  let style = {};
  if (isPreview) {
    style = {
      borderRadius: '10px',
    };
  }

  const getCardLayoutAndContent = () => {
    if (content?.cardType === 'text') {
      return (
        <TextType
          content={content?.content}
          backgroundColor={
            content?.backgroundColor || content?.data?.backgroundColor
          }
          layout={content?.layout || content?.data?.layout}
          isPreview={isPreview}
          shouldSetFixedHeight={shouldSetFixedHeight}
        />
      );
    } else if (content?.cardType === 'image') {
      return (
        <ImageType
          content={content?.content}
          title={content?.title}
          layout={content?.layout}
          isPreview={isPreview}
          shouldSetFixedHeight={shouldSetFixedHeight}
        />
      );
    } else if (content?.cardType === 'video') {
      return (
        <VideoType
          pauseVideo={!isActive}
          content={content?.content}
          title={content?.title}
          media={content?.media}
          thumbnail={content?.thumbnail}
          isPreview={isPreview}
          shouldSetFixedHeight={shouldSetFixedHeight}
        />
      );
    } else if (content?.cardType === 'form') {
      return (
        <FormType
          content={content?.content}
          isPreview={isPreview}
          shouldSetFixedHeight={shouldSetFixedHeight}
        />
      );
    } else if (content?.cardType === 'action') {
      return (
        <ActionType
          content={content?.content}
          backgroundColor={content?.backgroundColor}
          cardId={content?.eid}
          isPreview={isPreview}
          title={content.title}
          subTitle={content.subTitle}
          shouldMoveToNextCard={shouldMoveToNextCard}
          shouldSetFixedHeight={shouldSetFixedHeight}
        />
      );
    } else if (content?.cardType === 'quiz') {
      return (
        <QuizType
          content={content?.content}
          title={content?.title}
          layout={content?.layout}
          cardId={content?.eid}
          isPreview={isPreview}
          isCheckClickedValue={isCheckClickedValue}
          shouldMoveToNextCard={shouldMoveToNextCard}
          shouldSetFixedHeight={shouldSetFixedHeight}
        />
      );
    } else if (content?.cardType === 'mixed') {
      return (
        <MixedType
          content={content?.content}
          title={content?.title}
          isPreview={isPreview}
          shouldSetFixedHeight={shouldSetFixedHeight}
        />
      );
    } else if (content?.cardType === 'formCard') {
      return (
        <CustomFormType
          content={content?.form || content?.content}
          title={content?.title}
          isPreview={isPreview}
          isDisabled={isDisabled}
          shouldMoveToNextCard={shouldMoveToNextCard}
          shouldSetFixedHeight={shouldSetFixedHeight}
        />
      );
    } else if (content?.cardType === 'milestone') {
      return (
        <Milestone
          content={content?.title}
          milestoneUrl={content?.content?.[0]?.url}
        />
      );
    }
  };
  return (
    <div
      className='card-preview-scroll'
      style={{ height: '100%', width: '100%', overflowY: 'auto', ...style }}
    >
      {getCardLayoutAndContent()}
    </div>
  );
};

export default TrainingCard;

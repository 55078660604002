import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ILastDone } from 'sub-components/LocationLaunchDetail/types/types';
import CommonLabel from '../../CommonLabel';

const LastDone: FC<ILastDone> = (props) => {
  const { lastDoneStep, lastDoneOn, orientation = 'vertical' } = props;

  return (
    <Flex
      flexDir='column'
      align={orientation === 'vertical' ? 'flex-end' : 'normal'}
    >
      {orientation === 'vertical' ? (
        <CommonLabel label='Last done' fontWeight={500} fontSize='12px' />
      ) : (
        <CommonLabel label='Last done task' />
      )}
      <CommonLabel
        label={lastDoneStep || '-'}
        fontWeight={500}
        color='#111315'
        isTruncated
        maxW='200px'
      />
      {orientation === 'vertical' ? (
        <CommonLabel
          label={`on ${lastDoneOn}`}
          fontWeight={500}
          fontSize='12px'
          color='#A6B1BC'
        />
      ) : lastDoneStep && lastDoneOn ? (
        <CommonLabel label={`on ${lastDoneOn}`} color='#A6B1BC' />
      ) : null}
    </Flex>
  );
};

export default LastDone;

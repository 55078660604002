// @ts-ignore
import React, { useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
// @ts-ignore
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Image from '../../ui-components/Image/Image';
// @ts-ignore
import { ReactComponent as CardNumber } from '../../assets/images/cardNumber.svg';
import HandbookCardAction from './HandbookCardAction';
import { CardType } from '../../types';
import { Authorize, AuthRole } from '../../authorization';
import { IActionMenuData } from '../../ui-components/TableActionMenu';
import { CardModal, DeckModal } from '../../modal';

const DEFAULT_IMG =
  'https://sop-uploads-staging.s3.amazonaws.com/mja98k5r6mlbc25nmaek5pom/1656598635410_505_video_card_preview.png';

interface IProps {
  item: DeckModal | CardModal;
  selectedCard?: string;
  onItemClick?: (item: DeckModal | CardModal) => void;
  onActionClick: (name: string, item: DeckModal | CardModal) => void;
  menuData?: Array<IActionMenuData>;
}

const getValue = (item: DeckModal | CardModal, key: 'thumbnail' | 'title') => {
  if (item.cardType === CardType.CustomForm) {
    return item?.form?.[key];
  }
  return item?.[key];
};

function HandbookCard({
  item,
  selectedCard,
  onItemClick,
  onActionClick,
  menuData,
}: IProps): React.ReactElement {
  const { t } = useTranslation(['common', 'common']);
  const [show, setShow] = useState(false);
  const [titleColor, dateColor] = useMemo(() => {
    if (item.type === 'card') {
      if (selectedCard === item.eid || item?.status === 'inactive') {
        return ['#0088F8', '#b7b7b7'];
      }
      return ['#000000', '#9A9FA5'];
    }
    return ['#000000', '#9A9FA5'];
  }, [selectedCard, item.eid, item.status]);

  const onClick = (outer?: boolean) => {
    if (outer && item.type === 'card') {
      return;
    }
    onItemClick?.(item);
  };

  const onMouseEnterHandler = () => {
    setShow(true);
  };

  const onMouseLeaveHandler = () => {
    setShow(false);
  };

  if (item instanceof DeckModal) {
    item.cardLength;
  }

  return (
    <Box
      className='handbook-card'
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <Box cursor='pointer'>
        <div className='handbook-card-container'>
          {item instanceof DeckModal && item?.cards?.length > 0 ? (
            <div
              className='handbook-deck-number-of-cards'
              onClick={() => onClick(true)}
            >
              <div className='handbook-deck-number'>
                {item?.cards?.length}{' '}
                {t(item?.cards?.length > 1 ? 'common:cards' : 'common:card')}
                <CardNumber style={{ marginTop: 8 }} />
              </div>
            </div>
          ) : null}
          <Box>
            <Authorize
              permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
              permittedFor='user'
            >
              {show && (
                <HandbookCardAction
                  menuData={menuData}
                  item={item}
                  onClick={onActionClick}
                />
              )}
            </Authorize>
            <Box
              id='image-preview'
              onClick={() => onClick(false)}
              height='180px'
              width='220px'
            >
              <Image
                src={getValue(item, 'thumbnail') || DEFAULT_IMG}
                height={180}
                width={220}
                style={{
                  width: '100%',
                  height: '100%',
                  alignSelf: 'center',
                  borderRadius: 12,
                  objectFit: 'cover',
                  filter: item?.status === 'inactive' ? 'grayscale(100%)' : '',
                }}
              />
            </Box>
          </Box>
        </div>

        <div
          className='handbook-deck-name'
          style={{ color: titleColor }}
          onClick={() => onClick(false)}
        >
          {getValue(item, 'title')}
        </div>
        <Box
          fontWeight='600'
          fontSize='14px'
          color={dateColor}
          onClick={() => onClick(false)}
        >
          {t('handbook:updated_on', {
            date: moment(item?.updatedAt)?.format('Do MMM'),
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default HandbookCard;

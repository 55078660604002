import styled from '@emotion/styled';

export const FormResponseTabsListWrapper = styled.div`
  flex: 1;
  display: flex;
  column-gap: 12px;

  .form-response-item {
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #fcfcfc;
    font-weight: 600;
    font-size: 15px;
    color: #6f767e;

    :hover,
    &.active {
      background-color: #efefef;
      color: #1a1d1f;
    }
  }
`;

import React, { useCallback, useRef } from 'react';

import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import { ChatGroupEntity, GroupMemberEntity } from '../../../../../types';

import {
  BoxHeader,
  useConfirm,
  UseConfirm,
} from '../../../../../ui-components';

import ChannelMemberList from './ChannelMemberList';

interface IProps {
  group: ChatGroupEntity;
  onMessage: (userId: string) => void;
}

type IChannelMembers = (props: IProps) => void;

export const useViewChannelMember = (): IChannelMembers => {
  const { t } = useTranslation(['setting']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  const apolloClient = useApolloClient();

  return useCallback(({ group, onMessage }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const _onSendMessage = (data: GroupMemberEntity) => {
      onMessage?.(data.uid);
      setTimeout(confirmRef.current!.destroy);
    };

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex align='center' gap={3} justify='space-between'>
          <BoxHeader
            color='#CABDFF'
            fontSize='18px'
            isTruncated
            // @ts-ignore
            title={t('setting:channelMemberWithCount', {
              channel: group.name,
              value: group.membersCount,
            })}
          />

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <ChannelMemberList group={group} onActionClick={_onSendMessage} />
      ),
      contentProps: {
        py: '12px',
        maxWidth: '800px',
      },
      bodyProps: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      },
      scrollBehavior: 'inside',
      isCentered: true,
      footer: null,
    });
  }, []);
};

import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { IFormInput } from './invite.types';
import { AuthRole } from '../../authorization';
import { SelectOption } from '../../atoms/Dropdown';
import { toArray } from '../../utils/utils';

interface IProps {
  /** Used to hide the 'cross' icon from selected location chips. Specifically used when inviting location owner for a specific location and that location must not be removed as location owner is being added for that location only */
  shouldPreventLocationRemoval?: boolean;
}

const LocationChips: FC<IProps> = ({ shouldPreventLocationRemoval }) => {
  const { setValue, getValues } = useFormContext<IFormInput>();
  const locationList = useWatch<IFormInput, 'location'>({
    name: 'location',
  });

  const permissionLevel = useWatch<IFormInput, 'permissionLevel'>({
    name: 'permissionLevel',
  });

  const isMultiLocation = permissionLevel === AuthRole.LOCATION_OWNER;

  const onRemove = (value: SelectOption) => {
    const newLocations = toArray(getValues('location')).filter(
      (it) => it.id !== (value.id || value)
    );
    setValue('location', newLocations, {
      shouldValidate: true,
    });
  };

  if (!isMultiLocation) {
    return null;
  }

  if (!Array.isArray(locationList)) {
    return null;
  }

  return (
    <Flex mt={3} wrap='wrap' gap={2}>
      {locationList?.map((location, index) => {
        return location.label ? (
          <Flex
            key={index}
            bg='#2A85FF'
            padding='8px 16px'
            borderRadius='full'
            alignItems='center'
            gap='8px'
          >
            <Text fontSize='13px' fontWeight='semibold' color='white'>
              {location.label}
            </Text>

            {!shouldPreventLocationRemoval && (
              <CloseIcon
                cursor='pointer'
                color='white'
                boxSize='10px'
                onClick={() => onRemove(location)}
              />
            )}
          </Flex>
        ) : null;
      })}
    </Flex>
  );
};

export default LocationChips;

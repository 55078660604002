import React, { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolder } from '@fortawesome/pro-regular-svg-icons';
import { faFolder as faFolderSolid } from '@fortawesome/pro-solid-svg-icons';

interface SubItemProps {
  isSelected?: boolean;
  hide?: boolean;
  title: string;
  onClick?: () => void;
  hideIcon?: boolean;
}

export const SubItem: FC<SubItemProps> = ({
  isSelected,
  hide,
  title,
  onClick,
  hideIcon,
}) => {
  let className = 'sidebar-item-list-item-container';
  if (isSelected) {
    className = `sidebar-item-list-item-container selected`;
  }

  return (
    <Flex pos='relative' w='full' h='6vh' onClick={onClick}>
      {!hide && (
        <Box
          className='vertical-line'
          pos='absolute'
          h='full'
          border='1px solid #efefef'
        />
      )}
      <div
        style={{
          border: '2px solid #efefef',
          borderBottomLeftRadius: '10px',
          borderRight: 'none',
          height: '50%',
          width: '10px',
          borderTop: 'none',
        }}
      />
      <Flex
        className={className}
        flex={1}
        gap={2}
        align='center'
        style={{
          padding: '7px 12px',
          borderRadius: '12px',
          height: '100%',
          marginLeft: 1,
          overflow: 'hidden',
        }}
      >
        {!hideIcon && (
          <FontAwesomeIcon
            className='folder-icon'
            icon={(isSelected ? faFolderSolid : faFolder) as IconProp}
          />
        )}
        <Text as='span' isTruncated style={{ width: '100%' }}>
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};

export default SubItem;

import styled from '@emotion/styled';

export const ChangeLogListWrapper = styled.div`
  .change-log-progress-table-container {
    display: flex;
    flex-direction: row;
  }

  .progress-bar-change-log-container {
    width: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
  }
  .progress-bar-change-log {
    width: 3px;
    background-color: #efefef;
    height: 100%;
  }

  .log-table-container {
    width: 98%;
  }

  .date-header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1rem;
    position: relative;
  }

  .log-table-progress-ball {
    width: 20px;
    height: 20px;
    background-color: #efefef;
    border-radius: 50%;
    position: absolute;
    left: -52px;
  }

  .info-icon svg path {
    fill: rgb(142, 89, 255);
  }

  .info-change-log {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 19px;
    margin: 21px 4px 39px 43px;
    padding: 16px 20px;
    border-radius: 10px;
    background-color: rgba(202, 189, 255, 0.3);
  }
`;

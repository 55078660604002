import { gql } from '@apollo/client';
import { QuestionEntity, ResponseEntity } from '../../../../types';

export const GET_CATEGORY_FORMS_COUNT = gql`
  query FormsPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyFormsInput
  ) {
    FormsPagination(page: $page, perPage: $perPage, filter: $filter) {
      count
    }
  }
`;

export const DELETE_CATEGORY_QUERY = gql`
  mutation DeleteCategoryWithForms($categoryId: String!) {
    DeleteCategoryWithForms(categoryId: $categoryId) {
      succeed
    }
  }
`;

export const BULK_FORM_DELETE = gql`
  mutation BulkDeleteForms($categoryId: String!, $formIds: [String]) {
    BulkDeleteForms(categoryId: $categoryId, formIds: $formIds) {
      succeed
    }
  }
`;

export interface ResponseExportEntity {
  formId: string;
  response: ResponseEntity[];

  completedAt: string;

  createdBy: {
    name: string;
    eid: string;
    locations: Array<{
      eid: string;
      name: string;
    }>;
    profilePic: string;
  };
}

export interface ResponseFormEntity {
  eid: string;
  title: string;
  questions: QuestionEntity[];
}

export interface CategoryResponseExport {
  FormCategoryResponseExport: {
    responses: ResponseExportEntity[];
    forms: ResponseFormEntity[];
  };
}

export const BULK_RESPONSE_EXPORT = gql`
  query FormCategoryResponseExport($categoryId: String!, $formIds: [String]) {
    FormCategoryResponseExport(categoryId: $categoryId, formIds: $formIds) {
      responses {
        formId
        response
        completedAt
        createdBy {
          eid
          name
          locations {
            eid
            name
          }
          profilePic
        }
      }
      forms {
        eid
        title
        questions
      }
    }
  }
`;

import React, { FC } from 'react';
import { Checkbox, Flex, Radio } from '@chakra-ui/react';
import { ControllerRenderProps } from 'react-hook-form';

import { QuestionType } from 'types';
import { Question } from '../../create-form/form.types';
import { ReactComponent as CameraIcon } from '../../../../assets/images/camera.svg';
import CameraTooltip from './CameraTooltip';

interface IProps {
  qType: Question['qType'];
  data: Question['options'][number];
  field: ControllerRenderProps<any, any>;
  isPreview?: boolean;
  hasImageNote?: boolean;
}

// const OptionInput: FC<IProps> = () => {
//   return (
//     <>
//       <FormControl
//         ml={4}
//         // isInvalid={!!fieldState.error}
//         // isReadOnly={isReadOnly}
//       >
//         <FormInput size='lg' />
//         <FormErrorMessage>
//           {/*<span>{fieldState.error?.message}</span>*/}
//         </FormErrorMessage>
//       </FormControl>
//     </>
//   );
// };

const OptionInput: FC<IProps> = ({
  data,
  qType,
  field,
  isPreview,
  hasImageNote,
}) => {
  const onChange = (
    _data: Question['options'][number],
    _field: ControllerRenderProps
  ) => {
    if (qType === QuestionType.MULTI_CHOICE) {
      _field.onChange([_data.eid || _data.label]);
    } else {
      let newValue: string[] = _field.value || [];
      if (_data?.eid || _data?.label) {
        if (newValue.includes(_data.eid || _data.label)) {
          newValue = newValue.filter((id) => id !== (_data.eid || _data.label));
        } else {
          newValue.push(_data.eid || _data.label);
        }
      }
      _field.onChange(newValue);
    }
  };

  if (data.isArchieved) {
    return null;
  }

  if (isPreview && !data.label?.trim()) {
    return null;
  }

  const isChecked = field.value?.includes(data.eid || data.label);

  if (qType === QuestionType.MULTI_CHOICE) {
    return (
      <Radio
        {...field}
        isChecked={isChecked}
        value=''
        onChange={() => onChange(data, field)}
      >
        {data.label}
      </Radio>
    );
  }

  return (
    <Flex align='center' justify='space-between' gap={1.5}>
      <Checkbox
        {...field}
        isChecked={isChecked}
        value=''
        onChange={() => onChange(data, field)}
      >
        {data.label}
      </Checkbox>
      {isChecked && hasImageNote && (
        <CameraTooltip>
          <CameraIcon
            style={{ minWidth: 24, width: 24, height: 24, cursor: 'pointer' }}
          />
        </CameraTooltip>
      )}
    </Flex>
  );
};

export default OptionInput;

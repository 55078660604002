import { gql } from '@apollo/client';
import { AuthRole } from '../../../../authorization';

interface LocationEntity {
  eid: string;
  name: string;
  members: Array<{
    eid: string;
    name: string;
    authRole: AuthRole;
    profilePic?: string;
    role?: string;
    locations?: Array<{
      eid: string;
      name: string;
    }>;
  }>;
}

export interface LocationUserResponse {
  fetchEntityLocations: LocationEntity[];
}

export const LOCATION_USER_QUERY = gql`
  query LocationUserAssignee($eid: String!) {
    fetchEntityLocations(eid: $eid) {
      eid
      name
      members
    }
  }
`;

import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  img?: any;
  text?: string;
  borderRadius?: string;
  padding?: string;
  backgroundColor?: string;
  textColor?: string;
  boldText?: boolean;
  isTruncated?: boolean;
  maxW?: string;
}

const ImageText: FC<IProps> = ({
  img,
  text,
  textColor,
  borderRadius,
  padding,
  backgroundColor,
  boldText,
  isTruncated,
  maxW,
}) => {
  return (
    <Flex
      bg={backgroundColor}
      p={padding || '5px 10px'}
      borderRadius={borderRadius || '8px'}
      align='center'
      justify='center'
      w='fit-content'
    >
      {img && img}
      <Text
        isTruncated={isTruncated}
        maxW={maxW ? maxW : 'none'}
        color={textColor || 'black'}
        style={{ fontWeight: boldText ? 600 : 400 }}
      >
        {text}
      </Text>
    </Flex>
  );
};

export default ImageText;

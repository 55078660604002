import React, { forwardRef, useMemo } from 'react';
import {
  Box,
  Center,
  Checkbox,
  CheckboxProps,
  Flex,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { getImage } from '../../utils';
import { InviteTrainingItem } from './TrainingPaths';

interface IProps {
  item: InviteTrainingItem;
  isChecked?: boolean;
  onChange?: CheckboxProps['onChange'];
}

const TrainingPathItem = forwardRef<never, IProps>(
  ({ item, isChecked, onChange }, ref) => {
    const [chapterName, chapters] = useMemo(() => {
      // const TC = (item?.trainingContentTiny || []).map((it) => it.title);
      const TC = (item?.trainingContentTiny || []).reduce<string[]>(
        (previousValue, currentValue) => {
          if (currentValue.type === 'sop' || currentValue.type === 'card') {
            previousValue.push(currentValue.title);
          }
          return previousValue;
        },
        []
      );

      if (TC.length > 1) {
        return [TC[0], TC.slice(1)];
      }

      return [TC[0], []];
    }, [item?.trainingContentTiny]);

    return (
      <Flex my={4} align='center' justify='space-between' gap={3} ref={ref}>
        <Flex flex={1} gap='10px' overflow='hidden'>
          <Image
            src={getImage(item?.thumbnail, item?.title)}
            boxSize='56px'
            borderRadius='8px'
          />
          <Box overflow='hidden'>
            <Box fontWeight={700} fontSize='15px' isTruncated>
              {item?.title}
            </Box>
            {chapterName && (
              <Flex gap={1} fontWeight={500} fontSize='15px' color='#6F767E'>
                <Box isTruncated>{chapterName}</Box>
                {!!chapters.length && <Box whiteSpace='nowrap'>and</Box>}
                {!!chapters.length && (
                  <Tooltip
                    label={chapters.join(', ')}
                    hasArrow
                    maxWidth='350px'
                    borderRadius='6px'
                    padding='4px 8px'
                    bg='blackAlpha.800'
                  >
                    <Box
                      whiteSpace='nowrap'
                      cursor='pointer'
                      textDecoration='underline'
                      textUnderlineOffset={4}
                    >
                      {chapters.length} chapters
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            )}
          </Box>
        </Flex>
        <Center width='20px'>
          <Checkbox
            colorScheme='green'
            isChecked={isChecked}
            onChange={onChange}
          />
        </Center>
      </Flex>
    );
  }
);

TrainingPathItem.displayName = 'TrainingPathItem';

export default TrainingPathItem;

import React, { FC } from 'react';
import { Checkbox, FormControl } from '@chakra-ui/react';
import { Control, Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardFormInput } from './card-editor.types';
import { SelectOption } from '../../atoms/Dropdown';

interface IProps {
  isDeckView?: boolean;
  control: Control<CardFormInput>;
}

const IsHandbookCheckbox: FC<IProps> = ({ isDeckView, control }) => {
  const { t } = useTranslation('card');
  const selectedCategory: SelectOption | undefined = useWatch<CardFormInput>({
    control: control,
    name: 'category',
  });

  if (!isDeckView) {
    return null;
  }

  return (
    <Controller
      name={'handbookView'}
      control={control}
      render={({ field }) => {
        return (
          <FormControl mt={5}>
            <Checkbox
              size='lg'
              className='create-card-check-box-container'
              onChange={field.onChange}
              isChecked={field.value}
            >
              {t('also_add_to', {
                category: selectedCategory?.value,
              })}
            </Checkbox>
          </FormControl>
        );
      }}
    />
  );
};

export default IsHandbookCheckbox;

import React, { forwardRef, useEffect } from 'react';
import { Center, CloseButton } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { AccessibilityType } from 'types';
import { Loader } from 'sub-components';
import { NotAccessibleContainer } from 'ui-components';

import ChapterHeader from './ChapterHeader';
import ChapterContent from './ChapterContent';
import ChapterFooter from './ChapterFooter';
import { SopDetailResponse } from './chapter.graphql';
import ChapterFileView from './ChapterFileView';
import ChapterNotAccessible from './ChapterNotAccessible';
import { ChapterViewContainer } from './components';
import { getFoldersList } from 'shared/graphql/SharedGraphql';
import { useFolderSubFolder } from 'hooks';

interface IProps {
  searchQuery?: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sopData?: SopDetailResponse['SopById'];
  showPreview: (value: ((prevState: boolean) => boolean) | boolean) => void;
  showVisibility: () => void;
  shareChapterHandler: () => void;
  onEditClick: () => void;
  onDeleteClick?: () => void;
  exportPDFHandler?: () => void;
  loading: boolean;
  onItemClick: (event: MouseEvent, node: any) => void;
  unAuthorised?: AccessibilityType;
}

const ChapterTextView = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { loading, unAuthorised, sopData } = props;
  const history = useHistory();

  const { parentFolder } = useFolderSubFolder(sopData?.categoryId);

  console.log('Parent Folder : ', parentFolder);

  const { execute: fetchFoldersList, loading: gettingFoldersList } =
    getFoldersList();

  useEffect(() => {
    fetchFoldersList();
  }, []);

  if (loading || gettingFoldersList) {
    return (
      <Center h='full'>
        <Loader size='xl' />
      </Center>
    );
  }

  if (unAuthorised) {
    return (
      <NotAccessibleContainer>
        <CloseButton ml='auto' onClick={history.goBack} />
        <ChapterNotAccessible type={unAuthorised} />
      </NotAccessibleContainer>
    );
  }

  const sopUser = sopData?.lastUpdatedByUser || sopData?.createdByUser;

  if (sopData?.sopType !== 'text') {
    const file = sopData?.files[0];
    return (
      <ChapterViewContainer>
        <ChapterHeader
          title={sopData?.title}
          category={sopData?.category}
          categoryId={sopData?.categoryId}
          treeData={parentFolder}
          sopFile={file}
          sopType={sopData?.sopType}
          searchQuery={props.searchQuery}
          onSearch={props.onSearch}
          creatorId={sopData?.createdByUser?.eid}
          setShowPreview={props.showPreview}
          showVisibility={props.showVisibility}
          shareChapterHandler={props.shareChapterHandler}
          onEditClick={props.onEditClick}
          onDeleteClick={props.onDeleteClick}
        />
        <ChapterFileView sopData={sopData} />
        <ChapterFooter
          name={sopUser?.name}
          lastUpdated={sopData?.lastUpdated}
        />
      </ChapterViewContainer>
    );
  }

  return (
    <ChapterViewContainer>
      <ChapterHeader
        sopType={sopData?.sopType}
        category={sopData?.category}
        categoryId={sopData?.categoryId}
        searchQuery={props.searchQuery}
        treeData={parentFolder}
        onSearch={props.onSearch}
        creatorId={sopData?.createdByUser?.eid}
        setShowPreview={props.showPreview}
        showVisibility={props.showVisibility}
        shareChapterHandler={props.shareChapterHandler}
        exportPDFHandler={props.exportPDFHandler}
        onEditClick={props.onEditClick}
        onDeleteClick={props.onDeleteClick}
      />
      <ChapterContent
        data={sopData}
        ref={ref}
        searchQuery={props.searchQuery}
        onItemClick={props.onItemClick}
      />
      <ChapterFooter name={sopUser?.name} lastUpdated={sopData?.lastUpdated} />
    </ChapterViewContainer>
  );
});

ChapterTextView.displayName = 'Chapters/ChapterView/ChapterTextView';

export default ChapterTextView;

import axios, { AxiosRequestConfig } from 'axios';
import { Content, IChapterInterface } from './chapter.types';
import React from 'react';
import { IEmojiStructure } from 'delightree-editor';

export const fetchData = async (
  query: string,
  setFetchedResult: (value: React.SetStateAction<string>) => void
) => {
  const data = JSON.stringify({
    model: 'text-davinci-003',
    prompt: query,
    temperature: 0,
    max_tokens: 1000,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    stream: true,
  });
  const config: AxiosRequestConfig = {
    method: 'post',
    url: 'https://api.openai.com/v1/completions',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
    },
    data: data,
    responseType: 'stream',
    onDownloadProgress: (progressEvent) => {
      const response = progressEvent.currentTarget.responseText;
      const lines = response
        ?.split('\n')
        .filter((line: any) => line.trim() !== '');
      const data = lines.map((line: any) => {
        const message = line.replace(/^data: /, '');
        if (message === '[DONE]') {
          return '';
        } else {
          try {
            const parsed = JSON.parse(message);
            return parsed.choices?.[0].text;
          } catch (error) {
            console.error(
              'Could not JSON parse stream message',
              message,
              error
            );
            return '';
          }
        }
      });
      let joinedData = `<br /><h1>${query}</h1>` + `<p>${data.join('')}</p>`;
      // setFetchedResult(data.join(''));
      setFetchedResult(joinedData);
    },
  };
  await axios(config);
};

export const removeTrailingEmptyParagraphs = (json: any) => {
  // Reverse the content array so we can iterate from the end
  const reversedContent = [...json.content].reverse();

  // Add paragraphs between consecutive tables
  for (let i = 0; i < reversedContent.length - 1; i++) {
    const currentNode = reversedContent[i];
    const nextNode = reversedContent[i + 1];

    if (currentNode.type === 'table' && nextNode.type === 'table') {
      // Insert a paragraph between the two tables
      reversedContent.splice(i + 1, 0, {
        type: 'paragraph',
        text: '',
      });
      i++;
    }
  }

  // Find the index of the last non-empty paragraph
  const lastNonEmptyIndex = reversedContent.findIndex((item) => {
    // Non-paragraph nodes are considered non-empty
    if (item.type !== 'paragraph') return true;

    // Paragraphs with no content property are considered empty
    if (!item.content || item.content.length === 0) return false;

    // If paragraph contains any custom node, it's considered non-empty
    const hasCustomNode = item.content.some(
      (node: any) => node.type !== 'text'
    );

    if (hasCustomNode) return true;

    // If paragraph contains only text nodes, then it's considered empty if all text nodes are empty
    const allTextNodesEmpty = item.content.every(
      (node: any) => node.text.trim() === ''
    );

    return !allTextNodesEmpty;
  });

  // If all paragraphs are empty, return an empty array
  if (lastNonEmptyIndex === -1) return [];

  // Otherwise, return all paragraphs up to and including the last non-empty one
  return {
    ...json,
    content: reversedContent.slice(lastNonEmptyIndex).reverse(),
  };
};

export const initialChapterData = (title: string) => {
  return {
    title: title,
    icon: {
      emoticons: [],
      id: '',
      keywords: [],
      name: '',
      native: '',
      shortcodes: '',
      unified: '',
    },
    notifyUsers: false,
    status: '',
    createdBy: '',
    entityId: '',
    category: '',
    categoryId: '',
    thumbnail: '',
    smartPageEnabled: false,
    eid: '',
    visibleTo: {
      users: [],
      condition: 'or' as 'or' | 'and',
      locations: [],
      roles: [],
    },
    content: [
      {
        text: title,
        type: 'tiptap',
        tiptap: {
          type: 'doc',
          content: [
            {
              type: 'heading',
              attrs: { level: 1 },
              content: [{ type: 'text', text: title }],
            },
            { type: 'paragraph' },
            { type: 'paragraph' },
            { type: 'paragraph' },
            { type: 'paragraph' },
            { type: 'paragraph' },
          ],
        },
      },
    ] as Content[],
  };
};

export const anyChange = (
  selectedEmoji: IEmojiStructure,
  editorData: IChapterInterface,
  editorTextContent: any,
  title: string
) => {
  console.log({
    selectedEmoji,
    editorData,
    id: {
      selectedEmoji: selectedEmoji?.id,
      editorData: editorData?.SopById?.icon?.id,
    },
    editorTextContent,
    title,
    JSON: {
      editorTextContent: JSON.stringify(editorTextContent),
      content: JSON.stringify(editorData?.SopById?.content?.[0]?.tiptap),
    },
  });
  if (
    selectedEmoji?.id !== editorData?.SopById?.icon?.id ||
    JSON.stringify(editorTextContent) !==
      JSON.stringify(editorData?.SopById?.content?.[0]?.tiptap) ||
    editorData?.SopById?.title !== title
  ) {
    return true;
  }
  return false;
};

import { gql } from '@apollo/client';

export interface CertificateItem {
  trainingId: string;
  training: string;
  thumbnail: string;
  certificate: string;
  firstCompletedAt: string;
  certificateUrl: string;
  trainingItems: number;
  viewedContents: number;
}

export interface CertificateResponse {
  userCertificates: CertificateItem[];
}

export const GET_USER_CERTIFICATES = gql`
  query userCertificates($userId: String!) {
    userCertificates(userId: $userId)
  }
`;

import { IFilterBadgeProps } from 'sub-components/LocationLaunchDetail/OpeningTask/Filter/FilterBadge/Singleton/FilterBadge';

/**
 * Default Badge Style
 */
const defaultBadgeStyle = {
  containerCSS: {
    width: 'fit-content',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    padding: '4px 8px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EBEAED',
    height: '24px',
  },
  textCSS: {
    fontWeight: '400',
    fontSize: '12px',
    color: '#84818A',
  },
};

/**
 * Active Badge Style
 */
const activeBadgeStyle = {
  containerCSS: {
    backgroundColor: '#84818A',
  },
  textCSS: {
    color: '#FFFFFF',
  },
};

/**
 * Filter options for the dashboard
 */
export const filterOptions: IFilterBadgeProps[] = [
  { label: 'all', value: 'all' },
  { label: 'lagging', value: 'lagging' },
  { label: 'on-track', value: 'onTrack' },
].map((value) => ({
  label: value?.label,
  value: value?.value,
  defaultContainerCSSProps: { ...defaultBadgeStyle.containerCSS },
  defaultTextCSSProps: { ...defaultBadgeStyle.textCSS },
  activeContainerCSSProps: { ...activeBadgeStyle.containerCSS },
  activeTextCSSProps: { ...activeBadgeStyle.textCSS },
}));

import { gql } from '@apollo/client';

export const TASK_RANKING_DATA = gql`
  query Query($entityId: String!) {
    TaskRankingOverview(entityId: $entityId)
  }
`;

export const GET_ALL_LOCATIONS = gql`
  query GET_ALL_LOCATIONS_ENTITY_USER(
    $entityId: String!
    $type: [userTypeEnum]
  ) {
    EntityUser(entityId: $entityId, type: $type) {
      eid
      name
    }
  }
`;

import styled from '@emotion/styled';

export const CardColorHeaderWrapper = styled.div`
    padding: 0 2rem 2rem 2rem;

    .color-header-container {
        display: flex;
        flex-direction: column;
    }

    .color-heading-main {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
    }

    .card-header-color {
        width: 16px;
        height: 32px;
        flex-grow: 0;
        border-radius: 4px;
        background-color: ${props => props.color};
        margin-right: 10px;
    }

    .card-header-heading {
        font-size: 18px;
        font-weight: 600;
    }
`;
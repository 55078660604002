import styled from '@emotion/styled';

export const Wrapper = styled.div<{ flag: boolean; isReadOnly: boolean }>`
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  .react-daterange-picker__wrapper {
    display: none;
  }
  .react-daterange-picker__calendar.react-daterange-picker__calendar--open {
    top: 10px !important;
    .react-calendar {
      border: none;
      line-height: 1.825em;
    }
    .react-calendar__month-view__days__day.react-calendar__tile abbr {
      position: relative;
      z-index: 1;
    }

    .react-calendar__month-view__days__day--weekend.react-calendar__tile abbr {
      position: relative;
      z-index: 1;
    }

    .react-calendar__month-view__days__day.react-calendar__tile--active {
      background-color: white;
      color: black;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 70%;
        background-color: #1087ff61;
        position: absolute;
        top: 13%;
        left: 0%;
        z-index: 1;
      }
    }

    .react-calendar__month-view__days__day--weekend.react-calendar__tile--active {
      background-color: white;
      color: black;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 70%;
        background-color: #1087ff61;
        position: absolute;
        top: 13%;
        left: 0%;
        z-index: 1;
      }
    }

    .react-calendar__month-view__days__day.react-calendar__tile--active:enabled:focus {
      background-color: white;
    }

    .react-calendar__month-view__days__day--weekend.react-calendar__tile--active:enabled:focus {
      background-color: white;
    }

    .react-calendar__month-view__days__day.react-calendar__tile:hover {
      background-color: white;
    }

    .react-calendar__month-view__days__day--weekend.react-calendar__tile:hover {
      background-color: white;
    }

    .react-calendar__month-view__days__day.react-calendar__tile--rangeStart {
      background-color: white;
      color: white;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 65%;
        height: 70%;
        background-color: #1087ff;
        border-radius: 50%;
        position: absolute;
        top: 13%;
        left: 17%;
        z-index: 1;
      }

      &::after {
        content: '';
        display: block;
        width: 65%;
        height: 70%;
        background-color: ${(props) => (props.flag ? '#1087ff61' : 'white')};
        position: absolute;
        top: 13%;
        left: 57%;
        z-index: 0;
      }
    }

    .react-calendar__month-view__days__day--weekend.react-calendar__tile--rangeStart {
      background-color: white;
      color: white;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 65%;
        height: 70%;
        background-color: #1087ff;
        border-radius: 50%;
        position: absolute;
        top: 13%;
        left: 17%;
        z-index: 1;
      }

      &::after {
        content: '';
        display: block;
        width: 65%;
        height: 70%;
        background-color: ${(props) => (props.flag ? '#1087ff61' : 'white')};
        position: absolute;
        top: 13%;
        left: 57%;
        z-index: 0;
      }
    }

    .react-calendar__month-view__days__day.react-calendar__tile--rangeEnd {
      background-color: white;
      color: white;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 65%;
        height: 70%;
        background-color: #1087ff;
        border-radius: 50%;
        position: absolute;
        top: 13%;
        left: 17%;
        z-index: 1;
      }

      &::after {
        content: '';
        display: block;
        width: 65%;
        height: 70%;
        background-color: ${(props) => (props.flag ? '#1087ff61' : 'white')};
        position: absolute;
        top: 13%;
        left: -10%;
        z-index: 0;
      }
    }

    .react-calendar__month-view__days__day--weekend.react-calendar__tile--rangeEnd {
      background-color: white;
      color: white;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 65%;
        height: 70%;
        background-color: #1087ff;
        border-radius: 50%;
        position: absolute;
        top: 13%;
        left: 17%;
        z-index: 1;
      }

      &::after {
        content: '';
        display: block;
        width: 65%;
        height: 70%;
        background-color: ${(props) => (props.flag ? '#1087ff61' : 'white')};
        position: absolute;
        top: 13%;
        left: -10%;
        z-index: 0;
      }
    }
    .react-calendar__navigation {
      margin-bottom: 0px;
    }
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
      border: 1px solid rgba(200, 200, 200, 1);
      border-radius: 5px;
      font-size: 20px;
      font-weight: 700;
      color: rgba(132, 138, 149, 1);
      min-width: 30px !important;
      max-width: 30px !important;
      min-height: 30px !important;
      max-height: 30px !important;
      &:hover {
        background-color: #e0e0e0; /* Change for hover state */
      }
      &:disabled {
        cursor: no-drop;
        background-color: #cccccc; /* Change for disabled state */
        color: #888; /* Change text color for disabled state */
      }
    }
    .react-calendar__navigation__label {
      min-height: 30px !important;
      max-height: 30px !important;
      pointer-events: ${(props) => (props?.isReadOnly ? 'none' : 'auto')};
    }
  }
`;

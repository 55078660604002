import { useMemo } from 'react';
import { JSONContent } from 'delightree-editor';
import { CardType } from '../../../../../types';
import { toArray } from '../../../../../utils/utils';

const extractContent = (data?: JSONContent) => {
  const a = toArray(data)[0];
  switch (a?.type) {
    case 'tiptap':
      return a?.tiptap?.content || a?.tiptap;
    case 'video':
      // eslint-disable-next-line no-case-declarations
      let d = Object.assign({}, a);
      if (!d.attrs) {
        d.attrs = {};
      }
      if (!d.attrs?.src) {
        d.attrs.src = d.video;
        d.attrs.width = '640px';
        d.attrs.height = '360px';
      }
      return d;
    case 'image':
      return toArray(toArray(data)[0]?.image).map((image) => {
        return {
          type: 'image',
          attrs: {
            src: image,
            alt: 'image',
            title: null,
          },
        };
      });
    default:
      return a;
  }
};

export const useTrainingPreview = (content?: JSONContent) => {
  return useMemo(() => {
    if (content?.cardType) {
      switch (content.cardType) {
        case CardType.Text:
          return {
            type: 'doc',
            content: toArray(extractContent(content.content)),
          };
        case CardType.Video:
          return {
            type: 'doc',
            content: toArray(extractContent(content.content)),
          };
        case CardType.Image:
          return {
            type: 'doc',
            content: toArray(extractContent(content.content)),
          };
        case CardType.Action:
          return {
            type: 'action',
            content: content?.content,
            backgroundColor: content.backgroundColor,
            eid: content.eid,
            subTitle: content.subTitle,
          };
        case CardType.Quiz:
          return {
            type: 'quiz',
            content: content?.content,
            backgroundColor: content.backgroundColor,
            eid: content.eid,
            layout: content.layout,
            title: content.title,
          };
        case CardType.CustomForm:
          return {
            type: CardType.CustomForm,
            content: content.form,
            title: content.title,
            isAccessible: content?.isAccessible ?? true,
          };
        default:
          return content;
      }
    }

    switch (content?.type) {
      case 'milestone':
        return {
          type: 'milestone',
          title: content.title,
          content: toArray(content.content),
        };
      case 'sop':
        return {
          type: 'doc',
          content: toArray(extractContent(content.content)),
        };
      default:
        return content;
    }
  }, [content]);
};

import React, { FC } from 'react';
import { Box, Center } from '@chakra-ui/react';
import Loader from '../../../Loader';

interface IProps {}

const ExportFormDeleting: FC<IProps> = () => {
  return (
    <Center
      flex={1}
      flexDirection='column'
      style={{
        aspectRatio: '2/1',
      }}
      gap={4}
    >
      <Loader size='lg' />
      <Box fontSize='16px' textAlign='center' maxW='418px' color='#33383F'>
        Please wait while we delete all <br /> form
      </Box>
    </Center>
  );
};

export default ExportFormDeleting;

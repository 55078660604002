import { useMemo } from 'react';
import { Permission, useAuthorization } from '../../../../authorization';

export interface DisablePermission {
  firstName?: Permission;
  lastName?: Permission;
  email: Permission;
  phoneNumber?: Permission;
  role: Permission;
  accessDetails: Permission;
  location: Permission;
}

export const useDisabledField = (permissions?: DisablePermission) => {
  const authorization = useAuthorization({});

  return useMemo(() => {
    return Object.keys(permissions || {}).reduce((acc, key) => {
      // @ts-ignore
      if (permissions?.[key]) {
        // @ts-ignore
        acc[key] = authorization(permissions?.[key], false, true);
      }

      return acc;
    }, {} as Record<keyof DisablePermission, boolean>);
  }, [authorization, permissions]);
};

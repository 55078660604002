import React, { FC, useMemo } from 'react';
import { usePopoverContext } from '@chakra-ui/react';

import { useUserDataSelector, useUserEntity } from '../../../../../hooks';
import { AuthRole } from '../../../../../authorization';
import { toArray } from '../../../../../utils/utils';
import { SelectOption } from '../../../../../atoms';
import { shallowEqual } from '../../../../../utils';

import LocationMemberType from './LocationMemberType';
import LocationListContainer from './LocationListContainer';
import { sortLocation } from './location.graphql';
import { AssignedLocationsType } from '../../task.types';

interface IProps {
  value?: string[];
  onChange?: (value: string[], selectedType: AssignedLocationsType) => void;
  locations: SelectOption[];
}

const InnerContainer: FC<IProps> = ({ value, onChange, locations }) => {
  const [selectedType, setSelectedType] =
    React.useState<AssignedLocationsType>();

  const onTypeChange = (_value: AssignedLocationsType) => {
    setSelectedType(_value);
  };

  const _onChange = (values: string[]) => {
    onChange?.(values, selectedType!);
  };

  if (selectedType) {
    return (
      <LocationListContainer
        selectedType={selectedType}
        value={value}
        onChange={_onChange}
        locations={locations}
        onBackPress={() => setSelectedType(undefined)}
      />
    );
  }

  return <LocationMemberType onClick={onTypeChange} />;
};

const LocationSelectContainer: FC<Omit<IProps, 'locations'>> = (props) => {
  const { isOpen } = usePopoverContext();
  const { authRole, ownerLocations } = useUserDataSelector(
    (state) => ({
      authRole: state.authRole,
      ownerLocations: state.locations,
    }),
    shallowEqual
  );

  const locations = useUserEntity((entity) => entity.locations, shallowEqual);

  const locationList = useMemo(() => {
    if (authRole === AuthRole.LOCATION_OWNER) {
      return toArray(ownerLocations)
        .map((it) => {
          return {
            label: it.name,
            value: it.eid,
          };
        })
        .sort(sortLocation);
    }

    return toArray(locations)
      .map((it) => {
        return {
          label: it.name,
          value: it.eid,
        };
      })
      .sort(sortLocation);
  }, [locations, authRole, ownerLocations]);

  if (!isOpen) {
    return null;
  }

  return <InnerContainer {...props} locations={locationList} />;
};

export default LocationSelectContainer;

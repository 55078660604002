import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faFilter } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import LocationAndJobs from './LocationAndJobs';
import MembersSelect from './MembersSelect';
import { IVisibilityInput } from './visibility.types';
import { Authorize, AuthRole } from '../../../../authorization';

interface IProps {}

const CustomVisibility: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'form']);

  const isChecked =
    useWatch<IVisibilityInput>({
      name: 'visibility',
    }) === 'private';

  return (
    <div>
      <Authorize
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <Flex pb='16px' align='center' gap={2} opacity={isChecked ? 1 : 0.4}>
          <FontAwesomeIcon
            style={{ fontSize: 18 }}
            icon={faFilter as IconProp}
            flip='horizontal'
            color='#1A1D1F'
          />
          <Box fontSize='20px' fontWeight='600' color='#333B4F'>
            {t('common:custom')}
          </Box>
          <Box flex={1} />
          <FontAwesomeIcon
            style={{ fontSize: 20 }}
            icon={faChevronDown as IconProp}
            color='#6F767E'
          />
        </Flex>
      </Authorize>

      {isChecked && (
        <>
          <LocationAndJobs />

          <Box pt='20px' fontSize='14px' fontWeight='400' color='#6F767E'>
            {t('form:individualCanSeeForm')}
          </Box>

          <MembersSelect />
        </>
      )}
    </div>
  );
};

export default CustomVisibility;

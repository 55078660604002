import React, { FC, useMemo } from 'react';
import { Center, CenterProps } from '@chakra-ui/react';

interface IProps extends Pick<CenterProps, 'mb'> {
  status: string;
}

const MemberStatus: FC<IProps> = ({ status, mb }) => {
  const [color, background] = useMemo(() => {
    switch (status) {
      case 'onboarded':
        return ['#83BF6E', 'rgba(181, 228, 202, 0.3)'];
      case 'pending':
        return ['#DEA615', 'rgba(255, 207, 85, 0.2)'];
      case 'active':
        return ['#2A85FF', 'rgba(177, 229, 252, 0.4)'];
      case 'inactive':
        return ['#6F767E', 'rgba(111, 118, 126, 0.15)'];
      default:
        return ['#6F767E', 'rgba(111, 118, 126, 0.15)']; // Inactive status
    }
  }, [status]);

  return (
    <Center
      color={color}
      background={background}
      fontSize='12px'
      fontWeight='600'
      p='3.5px 8px'
      borderRadius='6px'
      minH='22px'
      width='fit-content'
      minW='84px'
      textTransform='capitalize'
      mb={mb || '25px'}
    >
      {status}
    </Center>
  );
};

export default MemberStatus;

import React, { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { Flex, Text, Center, Image, Box } from '@chakra-ui/react';

import Loader from 'ui-components/Loader';
import { ITaskRanking } from './AdminSAGraphContainer';
import ConstantChartIcon from '../../../assets/images/task-dashboard-stat/constant-trend.svg';
import DecreaseChartIcon from '../../../assets/images/task-dashboard-stat/decrease-trend.svg';
import IncreaseChartIcon from '../../../assets/images/task-dashboard-stat/increase-trend.svg';
import ScrollableContainer from './ScrollableContainer';
import ScrollContainer from 'react-indiana-drag-scroll';

interface IRankingData {
  role?: string;
  completed: number;
  isGreatest: boolean;
  isLowest: boolean;
  complete: number;
  completedPercent: string;
  eid: string;
  incomplete: number;
  name?: string;
  total: number;
}

interface EnhancedLocRanking extends ITaskRanking {
  isGreatest: boolean;
  isLowest: boolean;
}

interface IProps {
  taskRankingData: ITaskRanking | undefined;
  loading: boolean;
}

type RankingDataObject = {
  maxLocation: any;
  minLocation: any;
  maxJobRole: any;
  minJobRole: any;
};

const LocationPerformanceGraph: FC<IProps> = ({ taskRankingData, loading }) => {
  const rankingData: IRankingData[] = useMemo(() => {
    const rankingDataObj: RankingDataObject = {};
    const finalRankingData: any[] = [];

    if (taskRankingData && taskRankingData?.TaskRankingOverview !== null) {
      if (
        taskRankingData &&
        taskRankingData?.TaskRankingOverview?.locRanking?.length > 0
      ) {
        let minimumPercent = 100;
        let maximumPercent = 0;

        const newData = taskRankingData?.TaskRankingOverview?.locRanking.map(
          (location) => {
            const completedPercent = Number(location.completedPercent);
            if (completedPercent > maximumPercent) {
              maximumPercent = completedPercent;
            }

            if (completedPercent < minimumPercent) {
              minimumPercent = completedPercent;
            }

            return { ...location, completedPercent };
          }
        );

        newData.map((item) => {
          if (item.completedPercent === maximumPercent) {
            rankingDataObj.maxLocation = item;
            rankingDataObj.maxLocation.isGreatest = true;
            rankingDataObj.maxLocation.isLowest = false;
          } else if (item.completedPercent === minimumPercent) {
            rankingDataObj.minLocation = item;
            rankingDataObj.minLocation.isGreatest = false;
            rankingDataObj.minLocation.isLowest = true;
          }
        });
      }

      if (
        taskRankingData &&
        taskRankingData?.TaskRankingOverview?.roleRanking !== null
      ) {
        let minimumPercent = 100;
        let maximumPercent = 0;

        const newData = Object.values(
          taskRankingData?.TaskRankingOverview?.roleRanking
        ).map((location) => {
          const completedPercent = Number(location.completedPercent);
          if (completedPercent > maximumPercent) {
            maximumPercent = completedPercent;
          }

          if (completedPercent < minimumPercent) {
            minimumPercent = completedPercent;
          }

          return { ...location, completedPercent };
        });

        newData.map((item) => {
          if (item.completedPercent === maximumPercent) {
            rankingDataObj.maxJobRole = item;
            rankingDataObj.maxJobRole.isGreatest = true;
            rankingDataObj.maxJobRole.isLowest = false;
            rankingDataObj.maxJobRole.name = item?.role;
          } else if (item.completedPercent === minimumPercent) {
            rankingDataObj.minJobRole = item;
            rankingDataObj.minJobRole.isGreatest = false;
            rankingDataObj.minJobRole.isLowest = true;
            rankingDataObj.minJobRole.name = item?.role;
          }
        });

        Object.values(rankingDataObj).forEach((item) => {
          finalRankingData.push(item);
        });
      }

      finalRankingData.sort((a, b) => b.completedPercent - a.completedPercent);

      return finalRankingData;
    }

    return [];
  }, [taskRankingData]);

  const getTextStatus = (location: IRankingData) => {
    if (
      (location?.isGreatest && location?.isLowest) ||
      (!location?.isGreatest && !location?.isLowest)
    ) {
      return '';
    }
    if (location?.isGreatest && !location?.role) {
      return 'Location with maximum completion rate';
    }
    if (location?.isLowest && !location.role) {
      return 'Location with minimum completion rate';
    }

    if (location?.isGreatest && location?.role) {
      return 'Job with maximum completion rate';
    }
    if (location?.isLowest && location.role) {
      return 'Job with minimum completion rate';
    }
    return '';
  };

  const getColorStatus = (location: IRankingData) => {
    if (
      (location?.isGreatest && location?.isLowest) ||
      (!location?.isGreatest && !location?.isLowest)
    ) {
      return '#2A85FF';
    }
    if (location?.isGreatest) {
      return '#83BF6E';
    }
    if (location?.isLowest) {
      return '#FF6A55';
    }
    return '#2A85FF';
  };

  const getGraphStatus = (location: IRankingData) => {
    if (
      (location?.isGreatest && location?.isLowest) ||
      (!location?.isGreatest && !location?.isLowest)
    ) {
      return <Image minW='80px' maxW='80px' src={ConstantChartIcon} />;
    }
    if (location?.isGreatest) {
      return <Image minW='80px' maxW='80px' src={IncreaseChartIcon} />;
    }
    if (location?.isLowest) {
      return (
        <Image
          minW='80px'
          maxW='80px'
          src={DecreaseChartIcon}
          transform='rotateY(180deg)'
        />
      );
    }
    return <Image minW='80px' maxW='80px' src={ConstantChartIcon} />;
  };

  {
    loading && (
      <Center>
        <Loader />
      </Center>
    );
  }

  return rankingData?.length > 0 ? (
    // <ScrollableContainer>
    <ScrollContainer horizontal>
      <Flex id='l-p-g-s-f-1' gap='20px' justifyContent={'space-between'}>
        {rankingData?.map((location, index) => (
          <Flex
            id='l-p-g-s-f-2'
            key={index}
            bg='white'
            borderRadius='7px'
            minW='270px'
            width={'20vw'}
            p={6}
          >
            <Flex
              id='l-p-g-s-f-3'
              w='full'
              flexDirection='column'
              gap='10px'
              justify='flex-end'
            >
              <Text
                fontWeight={500}
                fontSize='11px'
                color='#84818A'
                lineHeight={'15px !important'}
              >
                {getTextStatus(location)}
              </Text>
              <Flex justify='space-between'>
                <Flex flexDir='column'>
                  <Text
                    fontWeight={700}
                    fontSize='16px'
                    isTruncated
                    maxW='150px'
                  >
                    {location?.name}
                  </Text>
                  <Text
                    fontWeight={700}
                    fontSize='24px'
                    color={getColorStatus(location)}
                  >
                    {location?.completedPercent}
                  </Text>
                </Flex>
                {getGraphStatus(location)}
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </ScrollContainer>
  ) : // </ScrollableContainer>
  null;
};

export default LocationPerformanceGraph;

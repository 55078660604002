import { gql } from '@apollo/client';
import { PageInfo } from '../../../types';
import { MyTrainingItem, TrainingPathItem } from './types';
import { MemberTrainingItem } from './types/member-training.types';
import { TrainingStatus } from './types/common.types';
import { TrainingDetails } from './types/training-details.types';

export interface TrainingPathResponse {
  userTrainingNew: {
    count: number;
    items: TrainingPathItem[];
    pageInfo: PageInfo;
  };
}

export const getTrainingPath = gql`
  query TrainingPath(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
  ) {
    userTrainingNew(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        eid
        title
        thumbnail
        trainingContentList
        assignedToUsers {
          eid
          name
          profilePic
        }
        createdBy {
          eid
          name
        }
        createdAt
        updatedAt
        lastUpdatedBy {
          name
        }
        status
        visibility
        description
        supervisors
        assignedTo
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`;

export interface TrackTrainingsResponse {
  trackTrainingPagination: {
    count: number;
    items: TrainingPathItem[];
    pageInfo: PageInfo;
  };
}

export const getTrackTrainings = gql`
  query TrackTrainingPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
  ) {
    trackTrainingPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        eid
        title
        thumbnail
        assignedToUsers {
          eid
          name
          profilePic
        }
        createdBy {
          eid
          name
        }
        createdAt
        updatedAt
        lastUpdatedBy {
          name
        }
        status
        visibility
        description
        supervisors
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`;

export interface MyTrainingResponse {
  userTrainingNew: {
    count: number;
    items: MyTrainingItem[];
    pageInfo: PageInfo;
  };
}

export const getMyTrainings = gql`
  query MyTraining(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
    $userIds: [String]
  ) {
    userTrainingNew(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        eid
        title
        status
        thumbnail
        assignedTo
        userProgress(userIds: $userIds)
        updatedAt
        trainingContentTiny
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const reAssignTraining = gql`
  mutation ReassignTraining($userId: String!, $trainingId: String!) {
    ReassignTraining(userId: $userId, trainingId: $trainingId) {
      eid
    }
  }
`;

export interface TrackResponse {
  trackWorkersTraining?: Array<MemberTrainingItem>;
}

export const getAllTracks = gql`
  query TrackWorkerTraining {
    trackWorkersTraining
  }
`;

export interface DeleteTrainingResponse {
  DeleteTraining: {
    succeed: boolean;
  };
}

export const DELETE_TRAINING_QUERY = gql`
  mutation DeleteTraining($eid: String) {
    DeleteTraining(eid: $eid) {
      succeed
    }
  }
`;

export interface TrainingUpdateVariable {
  input: {
    eid: string;
    title: string;
    status: TrainingStatus;
  };
}

export interface TrainingDetailResponse {
  trainingById: TrainingDetails;
}

export const getTrainingDetails = gql`
  query TrainingById($eid: String!) {
    trainingById(eid: $eid) {
      title
      entityId
      status
      thumbnail
      description
      contents {
        type
        eid
        title
      }
      visibility
      supervisors
      assignedTo
      assignedToUsers {
        username
        name
        profilePic
        authRole
        role
        eid
        locations {
          name
        }
      }
      userProgress
      updatedAt
      createdAt
      eid
      contentDetails
      trainingContentList
      trainingContentExpanded
    }
  }
`;

import gql from "graphql-tag";

export const getSearchResults = gql`
  query Search($input: SearchQueryInput) {
    Search(input: $input) {
      eid
      path
      value
      meta
      type
    }
  }
`;

export const getRecentSearchResults = gql`
  query RecentSearch(
    $filter: FilterFindManySearchInput
    $sort: SortFindManySearchInput
  ) {
    RecentSearch(filter: $filter, sort: $sort) {
      items {
        path
        eid
        viewedResult
        searchQuery
        recordId
        type
        meta
      }
    }
  }
`;

export const addRecentSearchResults = gql`
  mutation RecentSearchAdd($input: AddSearchInput) {
    RecentSearchAdd(input: $input) {
      searchQuery
      viewedResult
      path
      updatedAt
      createdAt
      eid
      recordId
      type
    }
  }
`;

export const getCardById = gql`
  query FindCardById($eid: String!) {
    findCardById(eid: $eid) {
      form {
        eid
        title
      }
      cardType
      title
      media {
        playbackId
        assetId
        rawUrl
        type
        mediaUrl
        thumbnail
        status
        meta
        _id
      }
      thumbnail
      backgroundColor
      layout
      subTitle
      createdBy
      entityId
      raw_content
      content
      status
      views
      updatedAt
      createdAt
      eid
      category
      media {
        thumbnail
        mediaUrl
      }
      decks {
        title
        eid
      }
      formId
      form {
        eid
        title
        category
        thumbnail
        description
        questions
        createdBy {
          eid
        }
      }
      trainings {
        title
      }
      translations
      defaultLanguage
    }
  }
`;

export const removeRecentSearch = gql`
  mutation RemoveRecentSearch($eid: String!) {
    RemoveRecentSearch(eid: $eid) {
      succeed
    }
  }
`;

import React, { FC, useContext, useEffect, useState } from 'react';
import { Flex, Image } from '@chakra-ui/react';
import { useLazyQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons';

import { Button } from 'atoms';
import { Authorize, AuthRole } from 'authorization';
import { AmplitudeEvent } from 'shared';

import { FORM_BY_ID_RESPONSE } from 'pages/forms/forms-graphql';
import FormDataContext from 'pages/forms/FormStore/form-data-context';
import { IFormUpdatedBy } from '../../forms-types';
import FormResponseHeaderSkeleton from './FormResponseHeaderSkeleton';
import DarkEditIcon from '../../../../assets/images/edit-dark.svg';
import { FormVisibility } from '../../../../ui-components/Visibility/visibility.types';
import { useShareModal } from '../../../../ui-components';
import { useVisibility } from '../../../../sub-components/forms/create-form/visibility';
import { IVisibilityInput } from '../../../../sub-components/forms/create-form/visibility/visibility.types';
import { UPDATE_FORM_QUERY } from '../../../../sub-components/forms/form.graphql';
import { formVisibilityFormat } from '../../../../sub-components/forms/create-form/formDataFormat';
import DateFilter from 'ui-components/DateFilter';

import { getImage } from 'utils';
import { faCalendar } from '@fortawesome/pro-light-svg-icons';
import { IDateFilterRangeEntity } from 'ui-components/DateFilter/types';
import { useUserDataSelector } from 'hooks';

interface IProps extends FormVisibility {
  title: string;
  description: string;
  category: string;
  thumbnail: string;
  lastUpdatedBy: IFormUpdatedBy;
  latestResponse: {
    createdAt: string;
  };
  createdBy: IFormUpdatedBy;
  updatedAt: string;
  createdAt: string;
  eid: string;
}

const FormResponseHeader: FC = () => {
  const history = useHistory();
  const { t } = useTranslation(['common', 'form']);
  const params = useParams<{ id: string }>();
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const formCtx = useContext(FormDataContext);

  const [formData, setFormData] = useState<IProps>();

  const shareForm = useShareModal();
  const visibilitySetting = useVisibility();

  const [getFormByIdData, { loading, client }] = useLazyQuery(
    FORM_BY_ID_RESPONSE,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setFormData(data?.FormById);
        formCtx.updateFormResponseByIdData(data?.FormById);
      },
    }
  );

  useEffect(() => {
    if (params?.id) {
      getFormByIdData({
        variables: {
          eid: params?.id,
          ...(loggedInUserAuthRole === AuthRole.LOCATION_OWNER
            ? { override: true }
            : {}),
        },
      });
    }
  }, [params?.id]);

  const updateVisibility = async (_values: IVisibilityInput) => {
    await client?.mutate({
      mutation: UPDATE_FORM_QUERY,
      variables: {
        input: {
          eid: formData?.eid!,
          visibility: _values.visibility,
          visibleTo: formVisibilityFormat(_values),
        },
      },
    });
    getFormByIdData({
      variables: {
        eid: formData?.eid!,
      },
    });
  };

  const onClickHandler = (name: string) => {
    switch (name) {
      case 'edit-visibility':
        return visibilitySetting({
          isEdit: true,
          initialValues: {
            visibility: formData?.visibility!,
            visibleTo: formData?.visibleTo!,
          },
          onApplyClick: updateVisibility,
        });
    }
  };

  const onShareClick = () => {
    shareForm({
      formId: formData?.eid!,
      formName: formData?.title!,
      formCreatorId: formData?.createdBy?.eid!,
      visibility: formData?.visibility!,
      visibleTo: formData?.visibleTo!,
      onClickHandler: onClickHandler,
    });
  };

  return (
    <FormResponseHeaderSkeleton isLoaded={!loading}>
      <Flex columnGap='1rem'>
        <img
          alt='form-thumbnail'
          src={getImage(formData?.thumbnail, formData?.title)}
          style={{
            minWidth: '48px',
            maxWidth: '48px',
            minHeight: '48px',
            maxHeight: '48px',
            borderRadius: '4px',
          }}
        />
        <Flex flex={1} flexDir='column' gap='5px'>
          <span style={{ fontWeight: '700', fontSize: '15px' }}>
            {formData?.title || '-'}
          </span>
          <span
            style={{ fontWeight: '500', fontSize: '12px', color: '#6F767E' }}
          >
            {formData?.updatedAt ? (
              <Trans
                t={t}
                i18nKey='common:latestResponse'
                values={{
                  date: moment(formData?.latestResponse?.createdAt).format(
                    'LLL'
                  ),
                }}
              />
            ) : (
              '-'
            )}
          </span>
          {/* <span
            style={{ fontWeight: '500', fontSize: '12px', color: '#6F767E' }}
          >
            {formData?.updatedAt
              ? moment(formData?.updatedAt).format('hh:mm:ss A')
              : '-'}
          </span> */}
        </Flex>

        <Flex gap={3} alignSelf='center'>
          <Authorize
            permittedFor='user'
            permittedRoles={AuthRole.SUPER_ADMIN}
            canAccess={(authUser) => authUser?.eid === formData?.createdBy?.eid}
          >
            <Button
              variant='outline'
              leftIcon={<Image src={DarkEditIcon} boxSize='18px' />}
              disabled={!formData?.eid}
              style={{
                fontSize: '14px',
              }}
              onClick={() => {
                history.push(`/forms/edit/${formData?.eid}`);
              }}
              event={AmplitudeEvent.FORMS_RESPONSE_EDIT_FORM}
            >
              {t('form:edit_form')}
            </Button>
          </Authorize>

          <Button
            colorScheme='blue'
            leftIcon={<FontAwesomeIcon icon={faShareNodes as IconProp} />}
            disabled={!formData?.eid}
            style={{
              fontSize: '14px',
            }}
            onClick={onShareClick}
            event={AmplitudeEvent.FORMS_RESPONSE_SHARE}
          >
            {t('form:shareThisForm')}
          </Button>
        </Flex>
      </Flex>
    </FormResponseHeaderSkeleton>
  );
};

export default FormResponseHeader;

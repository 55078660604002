import React, { FC } from 'react';
import { Box, Center, Flex, Image, useModalContext } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../ui-components';
import trashImage from '../../../../assets/images/trash-red.svg';

import { useDeleteContext } from './context';

const DeleteFormCategoryForm: FC = () => {
  const { category, actionHandler, totalFormCount } = useDeleteContext();
  const { onClose } = useModalContext();
  const { t } = useTranslation(['common', 'form']);

  const haveForm = !!totalFormCount;

  return (
    <Flex flex={1} flexDirection='column' gap={4}>
      <Center flexDirection='column' gap={4} py={3}>
        <Image
          src={trashImage}
          boxSize='56px'
          p='16px'
          bg='#FF6A5533'
          borderRadius='full'
        />

        {haveForm ? (
          <Box textAlign='center' color='#33383F'>
            To delete this form category, either move all forms to a different
            category or delete all forms within it. Afterward, you can delete
            this category.
          </Box>
        ) : (
          <Box textAlign='center' color='#33383F'>
            Are you sure you want to delete “{category.name}” category?
          </Box>
        )}
      </Center>

      {haveForm ? (
        <Flex pb={2} gap={3}>
          <ActionButton
            flex={2}
            size='lg'
            fontSize='15px'
            colorScheme='blue'
            actionFn={() => actionHandler?.('move', { category })}
          >
            Move form(s) to another category
          </ActionButton>
          <ActionButton
            flex={1}
            size='lg'
            fontSize='15px'
            colorScheme='red'
            actionFn={() => actionHandler?.('deleteConfirm')}
          >
            Delete all forms
          </ActionButton>
        </Flex>
      ) : (
        <Flex pb={2} gap={3}>
          <ActionButton
            flex={1}
            size='lg'
            fontSize='15px'
            colorScheme='red'
            actionFn={() => actionHandler?.('delete')}
          >
            {t('common:delete')}
          </ActionButton>
        </Flex>
      )}
    </Flex>
  );
};

export default DeleteFormCategoryForm;

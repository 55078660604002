import { membersCompare } from './membersCompare';
import { ICompareVisibilityType } from './visibilityHelper';

export const parentAndConditionChildNoCondition = (
  parent: any,
  child: any
): ICompareVisibilityType => {
  console.log({ PARENT_AND_CHILD_NO: { parent, child } });
  let parentCombinations: any[] = [];
  let childANDCombinations: any[] = [...child?.locations, ...child?.jobs];
  parent?.locations?.forEach((location: any) => {
    parent?.jobs?.forEach((role: any) => {
      parentCombinations?.push(`${location} & ${role}`);
    });
  });

  child?.locations?.forEach((location: any) => {
    child?.jobs?.forEach((role: any) => {
      childANDCombinations?.push(`${location} & ${role}`);
    });
  });
  let commonCombinations = parentCombinations?.filter((element) =>
    childANDCombinations?.includes(element)
  );
  console.log({
    parentAndChildNo: {
      parentCombinations,
      childANDCombinations,
      commonCombinations,
    },
  });
  let flag: ICompareVisibilityType = 'parentChildNoIntersection';
  if (commonCombinations?.length > 0) {
    const childContainsAllOfParent = parentCombinations?.every((element) =>
      childANDCombinations?.includes(element)
    );
    const parentContainsAllOfChild = childANDCombinations?.every((element) =>
      parentCombinations?.includes(element)
    );
    // Both child and parent have exactly same elements
    if (childContainsAllOfParent && parentContainsAllOfChild) {
      flag = 'parentChildSameVisibleSameLength';
    }

    // Child has all parent elements but has its own elements as well making child > parent
    if (
      childContainsAllOfParent &&
      childANDCombinations?.length > parentCombinations?.length
    ) {
      flag = 'parentLessVisibleThanChild';
    }

    // Child contains fewer elements than parent making parent > child
    if (
      !childContainsAllOfParent &&
      childANDCombinations?.length < parentCombinations?.length
    ) {
      flag = 'parentMoreVisibleThanChild';
    }

    // Both parent and child have same elements but they have different elements
    if (
      !childContainsAllOfParent &&
      childANDCombinations?.length === parentCombinations?.length
    ) {
      console.log(
        'Child and parent have the same length, but they contain different elements.'
      );
      flag = 'parentChildSameLengthDifferentVisibility';
    } else if (
      !childContainsAllOfParent &&
      childANDCombinations?.length > parentCombinations?.length
    ) {
      console.log(
        'Child has more elements and does not contain all elements of the parent.'
      );
      flag = 'parentLessVisibleThanChild';
    }
  } else {
    console.log('NO COMMON INTERSECTION');
    flag = 'parentChildNoIntersection';
  }
  console.log('initial flag : ', flag);
  let finalFlag = membersCompare(
    flag,
    parent?.members || [],
    child?.members || []
  );
  console.log({ PARENT_AND_CHILD_NO_FLAG: finalFlag });
  return finalFlag;
};

import React, { FC } from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import Image from '../../../../../ui-components/Image';

interface IProps {
  value: string;
}

const RoleTableCell: FC<IProps> = ({ value }) => {
  return (
    <Tooltip label={value} hasArrow p='8px 12px' borderRadius='8px'>
      <Flex
        gap='7px'
        align='center'
        cursor='pointer'
        bg={'rgba(181, 228, 202, 0.6)'}
        padding='5px 10px'
        borderRadius='7px'
        width='fit-content'
        maxWidth='150px'
      >
        <Box
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          fontWeight='600'
          color='#000000'
        >
          {value}
        </Box>
      </Flex>
    </Tooltip>
  );
};

export default RoleTableCell;

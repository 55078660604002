import { gql } from '@apollo/client';
import { IEmojiStructure } from 'delightree-editor';
import { FileType, ISopMedia } from 'pages/Chapters/chapter.types';
import { IType } from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';
import { CategoryEntity } from 'sop-commons/src/client';

export interface AddCategoryResponse {
  addCategory: CategoryEntity;
}

export interface AddCategoryVariable {
  eid: string;
  category: string;
  icon?: String;
  color?: string;
  visibility?: IType;
  copyVisibility?: boolean;
  roles?: string[];
  users?: string[];
  locations?: string[];
  condition?: 'and' | 'or';
}

export const AddCategoryQuery = gql`
  mutation AddCategory(
    $eid: String!
    $category: String!
    $icon: String
    $color: String
    $visibility: EnumEntityCategoryVisibility
    $roles: [String]
    $users: [String]
    $locations: [String]
    $condition: EnumEntityCategoryVisibleToCondition
    $copyVisibility: Boolean
  ) {
    addCategory(
      eid: $eid
      category: $category
      icon: $icon
      color: $color
      visibility: $visibility
      roles: $roles
      users: $users
      locations: $locations
      condition: $condition
      copyVisibility: $copyVisibility
    ) {
      eid
      name
      icon
      status
      visibility
      visibleTo {
        condition
        locations
        roles
        users
      }
    }
  }
`;

export interface UpdateCategoryResponse {
  updateCategory: {
    succeed?: boolean;
  };
}

export interface UpdateCategoryVariable {
  categoryId: string;
  category: string;
  icon?: string;
  color?: string;
  // oldCategory?: string;
  // newCategory: string;
}

export const UpdateCategoryQuery = gql`
  mutation UpdateCategory(
    $categoryId: String!
    $category: String
    $icon: String
    $color: String
  ) {
    updateCategory(
      categoryId: $categoryId
      category: $category
      icon: $icon
      color: $color
    ) {
      succeed
    }
  }
`;

export interface RemoveCategoryResponse {
  deleteCategory: {
    // eid: string;
    // category: CategoryEntity[];
    succeed: boolean;
  };
  removeCategoryAnyway: {
    eid: string;
  };
}

export interface RemoveCategoryVariable {
  eid: string;
  // category: string;
  categoryId: string;
}

export const RemoveCategoryQuery = gql`
  mutation RemoveCategoryAnyway($eid: String!, $categoryId: String!) {
    removeCategoryAnyway(eid: $eid, categoryId: $categoryId) {
      eid
    }
  }
`;

export interface ResponseCardDeck {
  handbookPagination: {
    count: number;
    pageInfo: {
      itemCount: number;
    };
    items: {
      eid: string;
      type: string;
      title: string;
      thumbnail: string;
      status: string;
      checked?: boolean;
    }[];
  };
}

export interface CardDeckVariable {
  page?: number;
  perPage?: number;
  sort?: string;
  filter?: Record<string, string | boolean | number>;
}

export const getCardAndDeck = gql`
  query getCardAndDeck($filter: FilterFindManyhandbookInput, $perPage: Int) {
    handbookPagination(filter: $filter, perPage: $perPage) {
      count
      items {
        eid
        type
        title
        thumbnail
        status
      }
    }
  }
`;

export interface ChaptersResponse {
  SopPagination: {
    count: number;
    items: {
      eid: string;
      title: string;
      thumbnail: string;
      status: string;
      category: string;
      checked?: boolean;
      files?: FileType[];
      icon?: IEmojiStructure;
      media: ISopMedia[];
    }[];
  };
}

export interface ChapterVariable {
  perPage?: number;
  filter?: Record<string, string | boolean | number>;
}

export const getChapters = gql`
  query getChapters($filter: FilterFindManySopInput, $perPage: Int) {
    SopPagination(filter: $filter, perPage: $perPage) {
      count
      items {
        eid
        title
        sopType
        thumbnail
        status
        category
        files {
          name
          url
          type
          mimetype
          fileSize
        }
        icon
        media {
          playbackId
          assetId
          type
          rawUrl
          mediaUrl
          thumbnail
          status
          meta
          _id
        }
      }
    }
  }
`;

export interface HandbookMetaResponse {
  handbookMeta: Record<
    string,
    {
      deck: number;
      card: number;
    }
  >;
}

export const getHandbookMeta = gql`
  query HandbookMeta {
    handbookMeta
  }
`;

export interface MoveItemVariable {
  input: {
    categoryId: string;
    change: 'new' | 'existing';
    items: string[];
  };
}

export interface MoveItemResponse {
  moveItems: any;
}

export const moveCategoryItems = gql`
  mutation MoveItems($input: moveItemsTypeInput) {
    moveItems(input: $input) {
      succeed
    }
  }
`;

export interface FolderVisibilityArgs {
  input: {
    categoryId: string;
    copyVisibility: boolean;
    condition?: string;
    locations: string[];
    roles: string[];
    users: string[];
    visibility: string;
  };
}

export interface FolderVisibilityResponse {
  UpdateCategoryVisibility: {
    succeed: boolean;
  };
}

export const FolderVisibilityQuery = gql`
  mutation UpdateCategoryVisibility($input: EntityCategoryVisibilityTypeInput) {
    UpdateCategoryVisibility(input: $input) {
      succeed
    }
  }
`;

import React, { FC, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getMyTasks } from '../services/task-play.service';
import TaskPlayHeading from './TaskPlayHeading';
import TaskPlayComponent from './TaskPlayComponent';
import TaskPlayDataProvider from '../store/TaskPlayProvider';
import { Center, Flex } from '@chakra-ui/react';
import TaskPlayDataContext from '../store/task-play-context';
import { ITaskPlay, ITaskPlayEntity } from '../types/task-play.types';
import Loader from 'ui-components/Loader';

const TaskPlayRoot: FC = () => {
  const { taskId } = useParams<{ taskId: string }>();
  const taskPlayCtx = useContext(TaskPlayDataContext);
  const { execute, loading, error, data } = getMyTasks();

  useEffect(() => {
    execute({
      variables: {
        eid: taskId,
      },
    });
  }, []);

  useEffect(() => {
    taskPlayCtx.taskPlayDataHandler(
      data?.TaskById?.lastSession || ({} as ITaskPlayEntity)
    );
  }, [data]);

  return loading ? (
    <Center h='80vh'>
      <Loader />
    </Center>
  ) : (
    <Flex flexDir='column' w='full'>
      <TaskPlayHeading />
      <Flex align='center'>
        <TaskPlayComponent />
      </Flex>
    </Flex>
  );
};

const TaskPlay: FC = () => {
  return (
    <TaskPlayDataProvider>
      <TaskPlayRoot />
    </TaskPlayDataProvider>
  );
};

export default TaskPlay;

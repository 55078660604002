import React, { FC } from 'react';
import {
  Box,
  Checkbox,
  HStack,
  ListItem,
  useCheckboxGroupContext,
  VStack,
} from '@chakra-ui/react';

import { Image } from 'ui-components';

import { IconsPlaceholder } from '../../../../ui-components/Search';
import RoleAndAuthRole from '../../../../ui-components/role-authrole/RoleAndAuthRole';
import { MemberEntity } from './send-form.graphql';

interface IProps {
  item: MemberEntity;
}

const MemberItem: FC<IProps> = ({ item }) => {
  const { onChange } = useCheckboxGroupContext();
  return (
    <ListItem>
      <HStack align='center' justify='space-between'>
        <HStack
          style={{
            flex: 1,
            paddingLeft: 6,
            paddingRight: 6,
            cursor: 'pointer',
            overflow: 'hidden',
            userSelect: 'none',
          }}
          onClick={() => onChange(item.eid)}
        >
          <Box>
            <div className='member-list-item'>
              <Image
                className='memberImage'
                src={item?.profilePic || IconsPlaceholder['user']}
                width={50}
                height={50}
              />
            </div>
          </Box>

          <VStack spacing={-8} align='stretch' flex='1' overflow='hidden'>
            <Box w='max-content'>
              <Box className='supervisor-title'>
                {item?.name ? item?.name : 'N/A'}
              </Box>
            </Box>
            <Box
              style={{ marginTop: 'unset' }}
              overflow='hidden'
              maxW='max-content'
            >
              <RoleAndAuthRole
                authRole={item?.locations?.[0]?.name}
                role={item?.role}
                defaultValue='--'
              />
            </Box>
          </VStack>
        </HStack>
        <div>
          <Checkbox value={item.eid} />
        </div>
      </HStack>
    </ListItem>
  );
};

export default MemberItem;

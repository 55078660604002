import { useReactiveVar } from '@apollo/client';
import {
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'atoms';
import { useUserDataSelector } from 'hooks';
import RoleDropDown from 'pages/Settings/ChatSetting/create-channel/channel-config/role-member/RoleDropDown';
import JobDropDown from 'pages/Settings/ChatSetting/create-channel/channel-config/role-member/JobDropDown';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthRole } from 'sop-commons/src/client';
import { CustomSelectComponent } from 'sub-components/CustomDropdowns';
import { usersEntityObj } from 'sub-components/Header';
import { SelectOption } from 'sub-components/tasks/create-task/common';
import { useMembersData } from 'sub-components/tasks/create-task/Supervisors';
import { shallowEqual } from 'utils';
import {
  CommonOptions,
  CommonSelectionOptions,
  GlobalChatSetting,
  ISelection,
} from '../../../GlobalChat';
import { RestrictType } from '../Access';

interface IProps {
  options: CommonOptions;
  selectedValues: CommonSelectionOptions;
  selectionValue: RestrictType;
  shouldSpecificMembers: boolean;
  savePreferences: () => void;
  selectionChange: (value: RestrictType) => void;
  updateMembersSelection: (values: string[]) => void;
  updateSelectedValues: (type: ISelection, data: string[]) => void;
  updateShouldSpecificMembers: () => void;
}

const BaseSelectionSection: FC<IProps> = ({
  options,
  selectedValues,
  selectionValue,
  shouldSpecificMembers,
  savePreferences,
  selectionChange,
  updateMembersSelection,
  updateSelectedValues,
  updateShouldSpecificMembers,
}) => {
  const entityData = useReactiveVar(usersEntityObj);
  const { t } = useTranslation(['task', 'setting']);

  const members: SelectOption[] = useMemo(() => {
    return entityData
      ?.filter((entity) => {
        return (
          [
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
            AuthRole.WORKER,
          ].includes(entity?.authRole) && entity?.type === 'user'
        );
      })
      .map((entity) => {
        return {
          ...entity,
          label: entity?.name,
          value: entity?.eid,
        };
      });
  }, [entityData]);

  const btnDisableHandler = () => {
    console.log({ options });
    if (options?.loading) {
      return true;
    }
    if (
      options?.selection === 'authRole' &&
      (options?.selectedOptions?.roles?.length === 0 ||
        !options?.selectedOptions?.roles)
    ) {
      return true;
    }
    if (
      options?.selection === 'role' &&
      (options?.selectedOptions?.jobs?.length === 0 ||
        !options?.selectedOptions?.jobs)
    ) {
      return true;
    }
    return false;
  };

  return (
    <VStack align='flex-start'>
      <HStack>
        <RadioGroup
          onChange={(e) => selectionChange(e as RestrictType)}
          value={selectionValue}
        >
          <Stack spacing={5} direction='row'>
            <Radio value='authRole'>
              <Text
                color='rgba(111, 118, 126, 1)'
                fontWeight={400}
                fontSize='14px'
              >
                Select members via role
              </Text>
            </Radio>
            <Radio value='role'>
              <Text
                color='rgba(111, 118, 126, 1)'
                fontWeight={400}
                fontSize='14px'
              >
                Select members via job
              </Text>
            </Radio>
            <Radio value='anyone'>
              <Text
                color='rgba(111, 118, 126, 1)'
                fontWeight={400}
                fontSize='14px'
              >
                Everyone in the org
              </Text>
            </Radio>
          </Stack>
        </RadioGroup>
      </HStack>
      <Flex flexDir='column' w='full' gap={4}>
        {selectionValue === 'anyone' && (
          <Flex
            bg='rgba(255, 216, 141, 0.5)'
            borderRadius='10px'
            p='12px 16px'
            align='center'
            gap='5px'
            w='fit-content'
          >
            <FontAwesomeIcon
              icon={faTriangleExclamation as IconProp}
              color='rgba(245, 161, 0, 1)'
            />
            <Text>
              Giving everyone access to create chat channels can lead to
              communication issues, security risks, and organizational chaos
            </Text>
          </Flex>
        )}
        {selectionValue === 'authRole' && (
          <RoleDropDown
            value={selectedValues?.roles}
            onChange={(value) => updateSelectedValues('role', value)}
            customStyles={{ maxWidth: '620px' }}
          />
        )}
        {selectionValue === 'role' && (
          <JobDropDown
            value={selectedValues?.jobs}
            onChange={(value) => updateSelectedValues('job', value)}
            customStyles={{ maxWidth: '620px' }}
          />
        )}
        {selectionValue !== 'anyone' && !shouldSpecificMembers && (
          <Text
            textDecor='underline'
            color='rgba(42, 133, 255, 1)'
            cursor='pointer'
            onClick={() => updateShouldSpecificMembers()}
          >
            Would you like to select specific members also?
          </Text>
        )}
        {selectionValue !== 'anyone' && shouldSpecificMembers && (
          <Flex flexDir='column'>
            <Text color='rgba(111, 118, 126, 1)' fontWeight={500}>
              Members
            </Text>
            <CustomSelectComponent
              placeholder={t('task:placeholder.selectMembers')}
              headerTitle={t('task:placeholder.selectMembers')}
              bottomOutsideBtn={{
                show: true,
                title: t('task:placeholder.addSelectMembers'),
              }}
              headerBarColor='#CABDFF'
              searchFieldPlaceholder={t('setting:searchByNameJobOrLoc')}
              options={members}
              showFilter
              value={selectedValues?.members}
              showDivider
              showSelectAll
              showSearchField
              showHeader
              showHeaderCloseIcon
              onChange={(values) => updateMembersSelection(values)}
              customStyles={{ maxWidth: '620px' }}
            />
          </Flex>
        )}
      </Flex>
      <PrimaryButton
        title='Save'
        colorScheme='blue'
        variant='solid'
        style={{ width: 'fit-content' }}
        isLoading={options?.loading}
        disabled={btnDisableHandler()}
        onClick={savePreferences}
      />
    </VStack>
  );
};

export default BaseSelectionSection;

import React, { ReactNode, useEffect } from 'react';
import { Box, BoxProps, ThemeTypings } from '@chakra-ui/react';
import {
  useTable,
  useSortBy,
  UseTableOptions,
  useRowSelect,
  Row,
} from 'react-table';

import { usePagination } from '../usePagination';
import { NoContentProps } from '../NoContent';
import { Pagination } from '../Pagination';
import Loader from '../../../ui-components/Loader';
import TableHeader from './TableHeader';
import { Column } from './SortingTable.types';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { css, CSSObject } from '@emotion/react';

interface TableProps<T extends object = {}>
  extends Pick<BoxProps, 'background'> {
  columns: Column<T>[];
  data: T[];
  page: number;
  totalRegisters?: number;
  onPageChange?: (page: number) => void;
  colorScheme?: ThemeTypings['colorSchemes'];
  emptyData?: {
    text?: string;
    content?: ReactNode;
  };
  isLoading?: boolean;
  isHidePagination?: boolean;
  stateReducer?: UseTableOptions<T>['stateReducer'];
  isResponsive?: boolean;
  tableFrom?: string;
  options?: Record<string, any>;
  onRowSelectChange?: (data: Row<T>[]) => void;
  registersPerPage?: number;
  tableLayout?: 'auto' | 'fixed';
  showDivider?: boolean;
  customWrapperStyles?: CSSObject;
}

type WrapperProps = {
  customStyles?: CSSObject;
  showDivider?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  table {
    background-color: white;
    thead {
      background-color: #f4f4f4;
      height: 44px !important;
      tr {
        border-radius: 5px;
        th:first-of-type {
          padding: 0 20px !important;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }
        th:last-child {
          padding: 0 20px !important;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    tbody {
      tr {
        td:first-of-type {
          padding: 20px 0 20px 20px !important;
        }
        ${(props) =>
          props.showDivider &&
          `
          &:not(:last-child) {
            border-bottom: 1px solid #efefef;
          }
        `}
      }

      tr:hover {
        background: #efefef80;
      }
    }
  }
  ${(props) => props.customStyles};
`;

function SortingTable<T extends object = {}>({
  page,
  onPageChange,
  totalRegisters,
  data,
  columns,
  colorScheme = 'teal',
  emptyData,
  isLoading,
  isHidePagination = false,
  stateReducer,
  isResponsive,
  tableFrom,
  options,
  onRowSelectChange,
  registersPerPage,
  tableLayout = 'fixed',
  background,
  customWrapperStyles,
  showDivider,
}: TableProps<T>): React.ReactElement {
  const pagination = usePagination({
    totalRegisters: totalRegisters as number,
    registersPerPage: registersPerPage,
    page,
    items: data,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows,
  } = useTable(
    { columns, data: pagination.pageItems, stateReducer, ...options },
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    onRowSelectChange?.(selectedFlatRows);
  }, [selectedFlatRows, onRowSelectChange]);

  if (isLoading) {
    return (
      <div className='sop-list-loader'>
        <Loader />
      </div>
    );
  }

  if (!isLoading && data.length === 0) {
    return (
      <div className='sop-list-loader'>
        {emptyData?.content || emptyData?.text}
      </div>
    );
  }

  return (
    <Wrapper customStyles={customWrapperStyles} showDivider={showDivider}>
      <Box
        as='table'
        {...getTableProps()}
        borderRadius='8'
        w='full'
        h='100%'
        backgroundColor={background || '#fcfcfc'}
        style={{ tableLayout: tableLayout }}
      >
        <TableHeader headerGroups={headerGroups} />

        <Box as='tbody' {...getTableBodyProps()} className='table-body'>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Box
                as='tr'
                {...row.getRowProps()}
                key={row.id}
                className='table-row'
              >
                {row.cells.map((cell, index) => (
                  <React.Fragment key={cell.column.id + index}>
                    <Box
                      as='td'
                      {...cell.getCellProps()}
                      style={{
                        borderBottom: 'none',
                        padding: !tableFrom ? '20px 0px' : '0px',
                      }}
                    >
                      {cell.render('Cell')}
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            );
          })}
        </Box>
      </Box>
      {!isHidePagination && (
        <div
          className={
            isResponsive
              ? 'table-responsive-pagination-pagination'
              : 'fixed-pagination-btn'
          }
        >
          <Pagination
            {...pagination}
            colorScheme={colorScheme}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </Wrapper>
  );
}

export default SortingTable;

import React, { ComponentProps, FC } from 'react';
import UserDetails from './component/UserDetails';
import LocationDetails from './component/LocationDetails';
import { ClickEvent } from './search-modal.types';
import MembersList from './location/MembersList';

type AllCmpProps = ComponentProps<typeof UserDetails | typeof LocationDetails>;

const Components: Record<string, FC<AllCmpProps>> = {
  branch: LocationDetails,
  location: LocationDetails,
  user: UserDetails,
};

interface IProps {
  data: any;
  onShowMemberClick?: (data: string) => void;
  viewLocationMember?: string;
}

const ContentBody: FC<IProps> = ({
  data,
  viewLocationMember,
  onShowMemberClick,
}) => {
  const Component = Components[data?.type as string];

  function onContentClick(event: ClickEvent, data: any) {
    switch (event) {
      case 'showMembers':
        onShowMemberClick?.(data);
        break;
      default:
        break;
    }
  }

  if (viewLocationMember) {
    return <MembersList locationId={data.recordId || data.eid} />;
  }

  return <Component data={data} onClick={onContentClick} />;
};

export default ContentBody;

ContentBody.displayName = 'SubComponentSearchModalContentBody';

import React from 'react';
import { ITaskPlay, ITaskPlayEntity } from '../types/task-play.types';

export interface ITaskPlayDataContext {
  taskPlayData: ITaskPlayEntity;
  currentIndex: number;
  currentIndexHandler: (index: number) => void;
  taskPlayDataHandler: (data: ITaskPlayEntity) => void;
}

const TaskPlayDataContext = React.createContext<ITaskPlayDataContext>({
  taskPlayData: {} as ITaskPlayEntity,
  currentIndex: 0,
  currentIndexHandler: (index: number) => {},
  taskPlayDataHandler: (data: ITaskPlayEntity) => {},
});

export default TaskPlayDataContext as React.Context<ITaskPlayDataContext>;

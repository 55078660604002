import { cloneDeep } from '@apollo/client/utilities';

import { toArray } from '../../../../utils';

import { BaseFormInput } from './base-launcher.types';
import { NewLauncherInput } from './add-launcher.graphql';
import { stepsMapWithId } from './helpers';

type ContentTask = NewLauncherInput['contents'][number]['tasks'][number];
type ITaskItem = BaseFormInput['contents'][number]['tasks'][number];

const transformTask = (task: ITaskItem): ContentTask => {
  return {
    title: task.title,
    description: task.description,
    duration: task.duration,
    completed: task.completed,
    dependency: task.dependency,
    docRequired: task.docRequired,
    steps: stepsMapWithId(task.steps),
  };
};

export const transformCreateData = <T extends BaseFormInput>(
  rawValues: T,
  skipCheck?: boolean
): NewLauncherInput => {
  const values = cloneDeep(rawValues);

  return {
    title: '',
    published: values.published,
    contents: toArray(values.contents).map((content) => {
      return {
        category: content.category,
        tasks: toArray(content.tasks).reduce<ContentTask[]>((acc, task) => {
          if (task.eid || skipCheck) {
            acc.push(transformTask(task));
          }

          return acc;
        }, []),
      };
    }),
  };
};

import React, { FC } from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import { PathNameThumbnail } from '../../../Training/Training/PathName';

interface IProps {
  index: number;
  pathName: string;
  thumbnail?: string;
  visibility?: 'public' | 'private' | 'restricted';
  completedAt?: string;
}

const CertificateTrainingName: FC<IProps> = ({
  pathName,
  index,
  thumbnail,
  visibility,
  completedAt,
}) => {
  const { t } = useTranslation(['common', 'training']);
  const title = pathName?.trim();

  return (
    <Flex gap='16px'>
      <PathNameThumbnail title={title} thumbnail={thumbnail} index={index} />
      <Box flex={1} overflow='hidden' pr={1}>
        <Box pos='relative' maxWidth='100%' width='max-content'>
          <Tooltip label={title} hasArrow borderRadius='4px'>
            <Box
              style={{
                fontSize: '15px',
                lineHeight: '24px',
                letterSpacing: '-0.15px',
                fontWeight: 'bold',
                marginBottom: '4.5px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              pr={5}
            >
              {title}
            </Box>
          </Tooltip>
          {visibility === 'private' && (
            <Tooltip label={t('private')} hasArrow borderRadius='4px'>
              <Flex pos='absolute' top={0} right='4px' h='full' align='center'>
                <FontAwesomeIcon icon={faLockKeyhole as IconProp} />
              </Flex>
            </Tooltip>
          )}
        </Box>

        <div
          style={{
            fontSize: '12px',
            fontWeight: '500',
            letterSpacing: '0.112px',
            color: '#6f767e',
          }}
        >
          <div>
            <span>
              {t('training:completedOn', {
                date: moment(completedAt).format('DD MMM'),
              })}
            </span>
          </div>
        </div>
      </Box>
    </Flex>
  );
};

export default CertificateTrainingName;

import React, { FC, useState } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  useMergeRefs,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { gql, useApolloClient } from '@apollo/client';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';

import { FormInput } from 'atoms';
import { BoxHeader } from 'ui-components';
import { Loader } from 'sub-components';
import { GOOGLE_MAPS_API_KEY } from 'utils/constants';
import { toArray } from 'utils/utils';

import TitleHeader from 'sub-components/CardEditor/TitleHeader';

import { IFormInput } from '../AddLocation/add-location.types';

const TIMEZONE_QUERY = gql`
  query TimezoneQuery($lat: String!, $timestamp: String!, $long: String!) {
    GetTimeZoneForLocation(lat: $lat, timestamp: $timestamp, long: $long)
  }
`;

const getAddress = (data: Record<string, string>) => {
  const address: string[] = [];
  if (data.street_number) {
    address.push(data.street_number);
  }
  if (data.route) {
    address.push(data.route);
  }
  if (data.sublocality) {
    address.push(data.sublocality);
  }
  return address.join(', ');
};

interface TimeZoneData {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZone: string;
  timeZoneId: string;
  timeZoneName: string;
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface IProps {
  isDisabled?: boolean;
}

const Address: FC<IProps> = ({ isDisabled }) => {
  const { t } = useTranslation(['location']);

  const client = useApolloClient();

  const [isFetching, setIsFetching] = useState(false);

  const { control, setValue } = useFormContext<IFormInput>();

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected: async (place) => {
      const newAddress = toArray<AddressComponent>(
        place?.address_components
      ).reduce<Record<string, string>>((previousValue, currentValue) => {
        (currentValue.types || []).forEach((value) => {
          if (value !== 'political' && !previousValue[value]) {
            previousValue[value] = currentValue.long_name;
          }
        });

        return previousValue;
      }, {});

      setValue('state', newAddress.administrative_area_level_1);
      setValue('city', newAddress.locality);
      setValue('zipCode', newAddress.postal_code);
      setValue('address', getAddress(newAddress));
      setIsFetching(true);

      const location = place?.geometry?.location;

      try {
        const response = await client
          .query<Record<'GetTimeZoneForLocation', TimeZoneData>>({
            query: TIMEZONE_QUERY,
            fetchPolicy: 'network-only',
            variables: {
              lat: location?.lat()?.toString(),
              long: location?.lng()?.toString(),
              timestamp: Math.floor(Date.now() / 1000)?.toString(),
            },
          })
          .then((record) => record.data.GetTimeZoneForLocation);

        setValue('timezone', response.timeZoneId);
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    },
    options: {
      // types: ['(regions)'],
      types: ['address'],
    },
  });

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='16px'
        color='#CABDFF'
        title={t('location:address')}
      />

      <Grid templateColumns='repeat(3, 1fr)' gap='20px'>
        <Controller
          control={control}
          name='address'
          // rules={{
          //   required: t('location:validation.addressRequired'),
          //   validate: (value) => {
          //     if (value?.trim()?.length === 0) {
          //       return t('location:validation.addressInvalid');
          //     }
          //   },
          // }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isDisabled={isDisabled}
                gridColumn='1/3'
                isInvalid={!!fieldState.error}
                isReadOnly={isFetching}
              >
                <TitleHeader title={t('location:address')} />
                <Flex height={2} />
                <FormInput
                  size='lg'
                  variant='outline'
                  placeholder={t('location:placeholder.address')}
                  {...field}
                  ref={useMergeRefs(ref, field.ref)}
                  rightIcon={
                    isFetching ? (
                      <Loader size='sm' thickness='2px' />
                    ) : undefined
                  }
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='zipCode'
          rules={{
            required: {
              value: !isDisabled,
              message: t('location:validation.zipCodeRequired'),
            },
            validate: (value) => {
              if (value && value?.trim()?.length === 0) {
                return t('location:validation.zipCodeInvalid');
              }
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isDisabled={isDisabled}
                isInvalid={!!fieldState.error}
              >
                <TitleHeader title={t('location:zipCode')} isRequired />

                <FormInput
                  mt={2}
                  size='lg'
                  variant='outline'
                  placeholder={t('location:placeholder.addressZipCode')}
                  {...field}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='country'
          // rules={{
          //   required: {
          //     value: !isDisabled,
          //     message: t('location:validation.countryRequired'),
          //   },
          // }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={!!fieldState.error}>
                <TitleHeader title={t('location:country')} />

                <FormInput
                  mt={2}
                  size='lg'
                  variant='outline'
                  placeholder={t('location:placeholder.addressCountry')}
                  {...field}
                />

                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='city'
          rules={{
            required: {
              value: !isDisabled,
              message: t('location:validation.cityRequired'),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={!!fieldState.error}>
                <TitleHeader title={t('location:city')} isRequired />

                <FormInput
                  mt={2}
                  size='lg'
                  variant='outline'
                  placeholder={t('location:placeholder.addressCity')}
                  {...field}
                />

                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='state'
          rules={{
            required: {
              value: !isDisabled,
              message: t('location:validation.stateRequired'),
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={!!fieldState.error}>
                <TitleHeader title={t('location:state')} isRequired />

                <FormInput
                  mt={2}
                  size='lg'
                  variant='outline'
                  placeholder={t('location:placeholder.addressState')}
                  {...field}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          control={control}
          name='timezone'
          render={({ field, fieldState }) => {
            return (
              <FormControl
                gridColumn='1/4'
                isReadOnly
                isInvalid={!!fieldState.error}
              >
                <TitleHeader title={t('location:timezone')} />

                <FormInput mt={2} size='lg' variant='outline' {...field} />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Grid>
    </Flex>
  );
};

export default Address;

import React, { FC } from 'react';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { chatStatus } from 'sop-commons/src/client';

import { CometChatUI } from '../../cometchat-pro-react-ui-kit/CometChatWorkspace/src';
import { useUserDataSelector } from '../../hooks';
import Loader from '../../ui-components/Loader';

const ADD_LOG = gql`
  mutation AddLog($input: LogsInputInput) {
    addLog(input: $input) {
      succeed
    }
  }
`;

interface IProps {}

const ChatContent: FC<IProps> = () => {
  const { i18n } = useTranslation();
  const isChatLoggedIn = useReactiveVar(chatStatus);
  const entityId = useUserDataSelector((state) => state.entityId);
  const userId = useUserDataSelector((state) => state.eid);

  const locationState = useLocation<{ chatUserId?: string }>().state;

  const [addLog] = useMutation(ADD_LOG, {
    onCompleted: (data) => {},
    onError: () => {},
  });

  const onCreateGroupEventHandler = (data: any) => {
    addLog({
      variables: {
        input: {
          eventType: 'Mutation',
          eventName: 'ChatGroupCreated',
          payload: {
            title: data?.name,
          },
          userId: userId,
          source: 'CometChat',
        },
      },
    });
  };

  if (isChatLoggedIn) {
    return (
      <CometChatUI
        lang={i18n.language}
        businessId={entityId}
        onCreateGroupEvent={onCreateGroupEventHandler}
        chatWithUser={locationState?.chatUserId}
      />
    );
  }

  return (
    <div className='sop-list-loader'>
      <Loader />
    </div>
  );
};

export default ChatContent;

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { UseSortByColumnProps } from 'react-table';

import { ReactComponent as ChevronUpIcon } from '../../../assets/images/chevronUp.svg';
import { ReactComponent as ChevronDownIcon } from '../../../assets/images/chevronDown.svg';

const SortWrapper = styled.div<{ disabled: boolean }>`
  margin-left: 5px;
  display: ${({ disabled }) => (disabled ? 'none' : 'initial')};

  .sorted-active path {
    fill: #000;
  }

  svg {
    :first-of-type {
      margin-bottom: 1px;
    }
    :last-of-type {
      margin-top: 1px;
    }
  }
`;

interface IProps {
  column: UseSortByColumnProps<never>;
}

const TableHeaderSort: FC<IProps> = ({ column }) => {
  return (
    <SortWrapper disabled={!column.canSort}>
      <ChevronUpIcon
        className={
          column.isSorted && !column.isSortedDesc ? 'sorted-active' : ''
        }
      />
      <ChevronDownIcon
        className={
          column.isSorted && column.isSortedDesc ? 'sorted-active' : ''
        }
      />
    </SortWrapper>
  );
};

export default TableHeaderSort;

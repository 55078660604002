import styled from '@emotion/styled';

export const GenericColorHeaderWrapper = styled.div`
  display: flex;

  .color-div {
    background-color: ${(props) => props.color};
    border-radius: 4px;
    margin-right: 1rem;
    width: 16px;
    min-width: 16px;
  }

  .title-div {
    font-weight: 600;
    font-size: 18px;
    margin: 2px 0px;
  }
`;

import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useConfirm } from '../ui-components/Confirm';
import PreviewHeader from '../sub-components/forms/component/PreviewHeader';

type CardDeckDelete = (props: {
  title: string;
  message: string;
  onDeletePress: (...args: any[]) => any | PromiseLike<any>;
}) => void;

export const useCardDeckDelete = (): CardDeckDelete => {
  const { t } = useTranslation(['common', 'deck']);
  const confirm = useConfirm();

  return useCallback(
    ({ title, message, onDeletePress }) => {
      confirm({
        title: (
          <PreviewHeader
            title={t('deck:delete_name_question', {
              title: title,
            })}
            color='#cabdff'
            noOfLines={1}
          />
        ),
        content: (
          <Box pl={8} fontSize='16px' fontWeight={500} color='#6F767E'>
            {message}
          </Box>
        ),
        isCentered: true,
        contentProps: {
          paddingTop: '12px',
          paddingBottom: '12px',
          minW: '600px',
        },
        okButtonProps: {
          size: 'lg',
          colorScheme: 'red',
          w: '180px',
        },
        cancelButtonProps: {
          size: 'lg',
          w: '180px',
        },
        okText: t('common:delete'),
        onOK: onDeletePress,
      });
    },
    [confirm]
  );
};

export const generateDeleteMessage = (
  type: 'card' | 'deck',
  trainings: any[] = [],
  t: TFunction<
    ('common' | 'card' | 'deck')[],
    undefined,
    ('common' | 'card' | 'deck')[]
  >
) => {
  const TL = (trainings?.map((a) => a.title) || []) as string[];

  if (TL.length > 0) {
    const TList = TL.slice();

    const tName = TList.splice(0, 2);
    const tLength = TList.length;

    switch (type) {
      case 'card':
        if (tLength > 0) {
          return t('deck:remove.card_trainings', {
            training: tName,
            tLength: tLength,
          });
        } else {
          return t('deck:remove.card_training', {
            training: tName,
            tLength: tLength,
          });
        }
      case 'deck':
        if (tLength > 0) {
          return t('deck:remove.deck_trainings', {
            training: tName,
            tLength: tLength,
          });
        } else {
          return t('deck:remove.deck_training', {
            training: tName,
            tLength: tLength,
          });
        }
    }
  } else {
    switch (type) {
      case 'card':
        return t('deck:remove.card');

      case 'deck':
        return t('deck:remove.deck');
    }
  }
};

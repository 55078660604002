import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import BoxHeader from '../../../../../../ui-components/BoxHeader';
import { useConfirm, UseConfirm } from '../../../../../../ui-components';

const Content = () => {
  return (
    <Flex flexDir='column' align='center' gap='20px'>
      <Flex
        boxSize='50px'
        align='center'
        justify='center'
        borderRadius='full'
        bg='#FFD88D99'
      >
        <FontAwesomeIcon
          icon={faTriangleExclamation as IconProp}
          color='#FAB05B'
          size='lg'
        />
      </Flex>
      <Flex color='#33383F' textAlign='center'>
        {`If you uncheck this form, the form response will be lost, and you'll need to fill it out again.`}
      </Flex>
    </Flex>
  );
};

export const useUncheckConfirm = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback((onOkClick: () => Promise<void> | void) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }
    confirmRef.current = confirm({
      title: (
        <Flex align='center' gap={3} justify='space-between'>
          <BoxHeader title='Uncheck this step?' color='#FFBC99' />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: <Content />,
      isCentered: true,
      contentProps: {
        py: '8px',
        maxW: '500px',
      },
      cancelButtonProps: {
        flex: 1,
        fontSize: '15px',
        variant: 'outline',
      },
      cancelText: 'Yes, I want to fill again',
      okButtonProps: {
        flex: 1,
        fontSize: '15px',
      },
      okText: 'I don’t want to fill again',
      onCancel: onOkClick,
    });
  }, []);
};

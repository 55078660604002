import React, { FC, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../../atoms/SearchInput';
import MemberItem from './MemberItem';
import { LOCATION_MEMBER } from './member-graphql';
import { Member } from './member.types';
import Loader from '../../Loader';
import EmptyMemberList from './EmptyMemberList';

interface IProps {
  locationId?: string;
}

const MembersList: FC<IProps> = ({ locationId }) => {
  const { t } = useTranslation('header');
  const [searchQuery, setSearch] = useState<string>('');

  const { data, loading } = useQuery(LOCATION_MEMBER, {
    variables: {
      eid: locationId,
    },
    fetchPolicy: 'network-only',
  });

  const members: Member[] = data?.userById?.members || [];

  const listData = useMemo(() => {
    if (searchQuery) {
      const reg = new RegExp(searchQuery, 'gi');
      return members.filter((value) => {
        return (
          value.name?.match(reg) ||
          value.role?.match(reg) ||
          value.authRole?.match(reg) ||
          value.email?.match(reg) ||
          value.username?.match(reg)
        );
      });
    }
    return members;
  }, [searchQuery, members]);

  return (
    <div>
      <SearchInput
        size='lg'
        hideShortcuts
        placeholder={t('search_member')}
        value={searchQuery}
        onChange={(event) => setSearch(event.target.value)}
        width={undefined}
        className={undefined}
        disabled={undefined}
        autoFocus
      />
      <Box mt='8px' height={480} overflowY='auto'>
        {loading && (
          <Box display='flex' justifyContent='center' mt='20px'>
            <Loader size='xl' style={undefined} />
          </Box>
        )}
        <EmptyMemberList dataLength={listData?.length} loading={loading} />
        {listData.map((value) => {
          return <MemberItem key={value.eid} data={value} />;
        })}
      </Box>
    </div>
  );
};

export default MembersList;

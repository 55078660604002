import { AllLayout } from 'sop-commons/src/utils/cardLayouts';
import { BaseCardEntity } from './base-card.types';

export enum CardType {
  Text = 'text',
  Image = 'image',
  Action = 'action',
  Milestone = 'milestone',
  Quiz = 'quiz',
  Video = 'video',
  Form = 'form',
  CustomForm = 'formCard',
  Mixed = 'mixed',
}

export type CardStatus = 'active' | 'inactive';

export interface CardEntity extends BaseCardEntity {
  cardType: CardType;
  isMandatory?: boolean;
  addedBy?: string;
  dateAdded?: string;
  fileSize?: number;
  files?: any[];
}

export interface CardDetailEntity extends CardEntity {
  backgroundColor: string;
  layout: AllLayout;
  raw_content: string;

  media: Array<{
    playbackId: string;
    assetId: string;
    rawUrl: string;
    type: string;
    mediaUrl: string;
    thumbnail: string;
    status: string;
    meta: string;
    _id: string;
  }>;
}

import { gql } from '@apollo/client';
import { IEmojiStructure } from 'delightree-editor';

import {
  FileEntity,
  SopType,
  TrainingContType,
  VisibilityConfig,
} from '../../../../../types';

export interface TaskFormItem {
  eid: string;
  title: string;
  thumbnail?: string;
  category: string;
  updatedAt: string;
  lastUpdatedBy: {
    name: string;
  };
  createdBy: {
    name: string;
  };

  visibility: VisibilityConfig['visibility'];
}

export interface FormResponse {
  FormsPagination: {
    items: TaskFormItem[];
  };
}

export const fetchFormsQuery = gql`
  query FormsForTask(
    $filter: FilterFindManyFormsInput
    $sort: SortFindManyFormsInput
    $perPage: Int
  ) {
    FormsPagination(filter: $filter, sort: $sort, perPage: $perPage) {
      items {
        eid
        title
        description
        thumbnail
        category
        status
        questions
        updatedAt
        lastUpdatedBy {
          name
        }
        createdBy {
          name
        }

        visibility
      }
    }
  }
`;

export interface ChapterItem {
  eid: string;
  title: string;
  thumbnail?: string;
  category: string;
  icon: IEmojiStructure;
  type: SopType;
  files?: FileEntity[];
  lastUpdated: string;

  visibility: VisibilityConfig['visibility'];
}

export interface ChapterResponse {
  SopPagination: {
    items: ChapterItem[];
  };
}

export const fetchChapterQuery = gql`
  query ChaptersForTask(
    $filter: FilterFindManySopInput
    $sort: SortFindManySopInput
    $perPage: Int
  ) {
    SopPagination(filter: $filter, sort: $sort, perPage: $perPage) {
      items {
        eid
        title
        thumbnail
        category
        icon
        sopType

        lastUpdated

        smartPageEnabled
        isMultiPage

        files {
          mimetype
          url
          name
        }
        media {
          mediaUrl
        }

        content

        cards {
          eid
          title
          cardType
          content
          thumbnail
        }
        cardView {
          eid
          title
          cardType
          content
          thumbnail
        }

        visibility
      }
    }
  }
`;

export interface TrainingItem {
  eid: string;
  title: string;
  thumbnail?: string;

  updatedAt: string;

  lastUpdatedBy: {
    name: string;
  };

  contents: Array<{
    type: TrainingContType;
  }>;

  visibility: VisibilityConfig['visibility'];
}

export interface TrainingResponse {
  trackTrainingPagination: {
    items: TrainingItem[];
  };
}

export const fetchTrainingQuery = gql`
  query TrainingsForTask(
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
    $perPage: Int
  ) {
    trackTrainingPagination(filter: $filter, sort: $sort, perPage: $perPage) {
      items {
        eid
        title
        thumbnail

        updatedAt
        lastUpdatedBy {
          name
        }

        contents {
          type
        }

        visibility
      }
    }
  }
`;

import React, { FC } from 'react';
import { Box, Flex, useClipboard, useToast } from '@chakra-ui/react';
import { FormInput } from 'atoms';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { IFormInput } from './invite.types';
import { ActionButton } from '../Confirm';

const ShareByLinkTab: FC = () => {
  const { t } = useTranslation(['invite', 'location']);
  const { getValues } = useFormContext<IFormInput>();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const { onCopy } = useClipboard(getValues('inviteUrl')!);

  const onCopyClipboard = () => {
    deployEvent(AmplitudeEventNames.HOME_INVITE_BUTTON_COPY_QR_CODE);
    toast({
      status: 'success',
      title: t('invite:link_copied'),
    });
    onCopy();
  };

  return (
    <Flex flexDir='column'>
      <TitleHeader title={t('invite:linkToShare')} />
      <Box h={1.5} />
      <FormInput
        size='lg'
        isReadOnly
        variant='outline'
        value={getValues('inviteUrl')!}
        rightElementProps={{
          width: 'auto',
          pr: '6px',
        }}
        rightIcon={
          <ActionButton
            width='fit-content'
            variant='solid'
            colorScheme='blue'
            borderRadius='10px'
            height='36px'
            lineHeight='unset'
            size='sm'
            actionFn={onCopyClipboard}
          >
            {t('location:copy_link')}
          </ActionButton>
        }
      />
    </Flex>
  );
};

export default ShareByLinkTab;

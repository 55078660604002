import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';

import { FormCategory } from '../../../../types';
import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';

import AddFormCategoryForm from './AddFormCategoryForm';

interface IProps {
  onCategoryCreated: (category: FormCategory) => void;
}

type CreateFormCategory = (props: IProps) => void;

export const useCreateFormCategory = (): CreateFormCategory => {
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const apolloClient = useApolloClient();

  return useCallback(({ onCategoryCreated }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },

      title: (
        <Flex justify='space-between'>
          <BoxHeader color='#CABDFF' title='Create a new form category' />

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: <AddFormCategoryForm onCategoryCreated={onCategoryCreated} />,
      isCentered: true,
      footer: null,
      contentProps: {
        minWidth: '640px',
        borderRadius: '10px',
        padding: '16px 12px',
      },
    });
  }, []);
};

import styled from '@emotion/styled';
import { Flex } from '@chakra-ui/react';

export const PdfViewerWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  background-color: #f4f4f4;

  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &:has(.react-pdf__message--loading) {
      flex: 1;
    }

    .react-pdf__message--loading {
      display: flex;
      height: 100%;
    }
  }
`;

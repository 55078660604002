import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ChapterSort } from 'pages/Chapters/chapters.graphql';
import React, { useCallback, useRef, useState } from 'react';
import { ISelectedFolderData } from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { UseConfirm, useConfirm } from 'ui-components';
import { useMoveSubFolderLogic } from '.';
import { MoveSubFolder } from '../components';

interface IProps {
  folderDetails?: ISelectedFolderData;
  getVariables?: () => {
    page: number;
    perPage: number;
    filter: Record<string, any>;
    sort: ChapterSort;
  };
}

type SubFolderMove = (props: IProps) => void;

export const useMoveSubFolder = (): SubFolderMove => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  return useCallback(({ folderDetails, getVariables } = {}) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }
    const onMoveSubFolderCompleteHandler = () => {
      confirmRef.current?.destroy();
    };
    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      size: 'lg',
      isCentered: true,
      headerProps: {
        padding: 0,
      },
      bodyProps: {
        padding: 0,
      },
      contentProps: {
        borderRadius: '16px',
      },
      title: (
        <Flex p={8} gap={4} justify='space-between' flex={1}>
          <GenericColorHeader
            title={`Move ${folderDetails?.name} to another folder`}
            color='#B5E4CA'
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <MoveSubFolder
          folderDetails={folderDetails}
          getVariables={getVariables}
          onMoveSubFolderCompleteHandler={onMoveSubFolderCompleteHandler}
        />
      ),
      footer: null,
    });
  }, []);
};

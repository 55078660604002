import { gql } from '@apollo/client';
import { LocationInputs } from '../AddLocation/add-location.graphql';

export interface LocationUpdateInputs extends Omit<LocationInputs, 'password'> {
  eid: string;
}

export interface UpdateLocationVariable {
  input: LocationUpdateInputs;
}

export const UPDATE_LOCATION_QUERY = gql`
  mutation UpdateLocation($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      eid
    }
  }
`;

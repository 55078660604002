import React, { FC } from 'react';
import { Flex, ListItem, Spacer } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { SearchItemContent } from '../../ui-components/Search';
import { RecentSearchModal } from 'modal';

interface IProps {
  item: RecentSearchModal;
  onClick: (item: RecentSearchModal) => void;
  onRemoveRecentSearchClick: (id: string) => void;
}

const RecentSearchItem: FC<IProps> = ({
  item,
  onClick,
  onRemoveRecentSearchClick,
}) => {
  return (
    <ListItem
      py={3}
      px='32px'
      _hover={{
        bg: '#EFEFEFCC',
      }}
    >
      <Flex maxWidth='100%' columnGap='15px' align='center'>
        <SearchItemContent item={item} onClick={() => onClick(item)} />

        <Spacer />

        <div className='iconCss'>
          <CloseIcon
            w={3}
            h={3}
            color='#9a9fa5'
            onClick={() => onRemoveRecentSearchClick(item.eid)}
          />
        </div>
      </Flex>
    </ListItem>
  );
};

export default RecentSearchItem;
RecentSearchItem.displayName = 'SubComponent/RecentSearchListItem';

import React, { ChangeEvent, useCallback, useRef } from 'react';
import MoveMembersToAnotherRoleModal, {
  MoveToComponentRef,
} from './MoveMembersToAnotherRoleModal/MoveMembersToAnotherRoleModal';
import MoveToRoleFooter from './MoveMembersToAnotherRoleModal/MoveToRoleFooter';
import MoveToRoleHeader from './MoveMembersToAnotherRoleModal/MoveToRoleHeader';
import { useConfirm } from '../../../ui-components/Confirm';
import { IRoleData } from './roles-list.types';
import { ApolloProvider, useApolloClient } from '@apollo/client';

type IMoveToItem = (props: {
  roleData: IRoleData;
  onBackPress: () => void;
  onDeletePress?: (...args: any[]) => any | PromiseLike<any>;
}) => void;

export const useMoveItemTo = (): IMoveToItem => {
  const inputRef = useRef<MoveToComponentRef>(null);
  const confirmRef = useRef<any>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      inputRef.current?.onChange(event.target.value);
    },
    []
  );
  return useCallback(
    ({ roleData, onBackPress, onDeletePress }) => {
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        title: (
          <MoveToRoleHeader
            roleData={roleData}
            onBackClick={() => {
              confirmRef.current?.destroy?.();
              setTimeout(onBackPress, 100);
            }}
            onClose={() => {
              confirmRef.current?.destroy?.();
            }}
            onChange={handleSearchChange}
          />
        ),
        content: (
          <MoveMembersToAnotherRoleModal
            ref={inputRef}
            roleData={roleData}
            onDeletePress={onDeletePress}
            close={() => confirmRef.current?.destroy?.()}
          />
        ),
        isCentered: true,
        contentProps: {
          paddingTop: '12px',
          paddingBottom: '12px',
          minW: '600px',
        },
        footer: <MoveToRoleFooter roleData={roleData} />,
      });
    },
    [handleSearchChange]
  );
};

import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { IFormInput } from './editor.types';

interface IProps {
  maxIndex: number;
}

const StepFocus: React.FC<IProps> = ({ maxIndex }) => {
  const activeStep = useWatch<IFormInput, 'activeStep'>({
    name: 'activeStep',
  });

  useEffect(() => {
    const name =
      activeStep === maxIndex - 1 ? '.add-section' : '.ant-steps-item-active';

    const x = setTimeout(() => {
      document.querySelector(`.ant-steps-horizontal ${name}`)?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }, 50);

    return () => clearTimeout(x);
  }, [activeStep, maxIndex]);

  return null;
};

export default React.memo(StepFocus);

import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Text,
  List,
  HStack,
  Checkbox,
  Flex,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import SearchInput from 'atoms/SearchInput';
import { SelectOption } from 'atoms/Dropdown';
import { searchRegExp } from 'utils';

import EmptyState from 'sub-components/EmptyState';
import { AuthRoleFilter } from 'sub-components/CustomDropdowns';

import { IRoleData, IRoleMemberEntity } from '../roles-list.types';
import MoveToItem from './MoveToItem';
import MoveToRoleHeader from './MoveToRoleHeader';
import MoveToRoleFooter from './MoveToRoleFooter';

interface IProps {
  isOpen: boolean;
  roleData: IRoleData;
  jobHeading?: boolean;
  showData: {
    initialModal: boolean;
    itemsModal: boolean;
    noDataModal: boolean;
    selectedRole: IRoleData;
  };
  onClose: () => void;
  setShowData: React.Dispatch<
    React.SetStateAction<{
      initialModal: boolean;
      itemsModal: boolean;
      noDataModal: boolean;
      selectedRole: IRoleData;
    }>
  >;
  selectedAlignCenter?: boolean;
}

export interface MoveToComponentRef {
  onChange: (searchValue: string) => void;
}

const MoveMembersToAnotherRoleModal: FC<IProps> = ({
  isOpen,
  roleData,
  showData,
  jobHeading,
  selectedAlignCenter,
  onClose,
  setShowData,
}) => {
  const { t } = useTranslation(['common', 'header']);
  const [allChecked, setAllChecked] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<
    SelectOption | null | undefined
  >({ label: '', value: '' });
  // const [initialItemsList, setInitialItemsList] = useState<IRoleData>({} as IRoleData);
  // const [itemsList, setItemList] = useState<IRoleData>({} as IRoleData);

  // useEffect(() => {
  //   setInitialItemsList(roleData);
  //   setItemList(roleData);
  // }, []);

  const filteredMembers = useMemo(() => {
    if (!query && !selectedFilter?.value) {
      return roleData.members || [];
    } else {
      const reg = searchRegExp(query, 'gi');
      if (query && selectedFilter?.value) {
        return (
          roleData.members?.filter(
            (member) =>
              member?.name?.match(reg) &&
              member?.authRole === selectedFilter?.value
          ) || []
        );
      } else if (query) {
        return roleData?.members?.filter((member) => member?.name?.match(reg));
      } else {
        return roleData?.members?.filter(
          (member) => member?.authRole === selectedFilter?.value
        );
      }
    }
  }, [query, roleData.members, selectedFilter]);

  // const onCheckChange = (itemId: string) => {
  //   let _itemMembersList: IRoleMemberEntity[] = JSON.parse(
  //     JSON.stringify(itemsList?.members)
  //   );
  //   _itemMembersList?.map((member) => {
  //     if (member?.eid === itemId) {
  //       member.checked = !member.checked;
  //     }
  //   });
  //   setItemList({ ...itemsList, members: _itemMembersList });
  // };

  // const filteredItems: IRoleData = useMemo(() => {
  //   return itemsList;
  // }, [itemsList]);

  // const onAllCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   setAllChecked(event.target.checked);
  //   let _filteredItems: IRoleData = JSON.parse(JSON.stringify(itemsList));
  //   if (event.target.checked) {
  //     _filteredItems?.members?.map((member) => (member.checked = true));
  //   } else {
  //     _filteredItems?.members?.map((member) => (member.checked = false));
  //   }
  //   setItemList(_filteredItems);
  // };

  // const searchChangeHandler = (e: any) => {
  //   setAllChecked(false);
  //   if (!e?.target?.value) {
  //     setItemList(initialItemsList);
  //   } else {
  //     let _itemsList: IRoleData = JSON.parse(JSON.stringify(initialItemsList));
  //     let filteredData = initialItemsList?.members?.filter((member) =>
  //       member?.name
  //         ?.toLowerCase()
  //         .includes(e?.target?.value?.toLowerCase()?.trim())
  //     );
  //     _itemsList.members = filteredData;
  //     setItemList(_itemsList);
  //   }
  // };

  const onFilterChange = (option: SelectOption) => {
    if (option?.value === selectedFilter?.value) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(option);
    }
  };

  const onAllCheckChange = () => {
    setAllChecked((prevVal) => !prevVal);
  };

  useEffect(() => {
    if (allChecked) {
      let allMemberIds = filteredMembers?.map((member) => member?.eid);
      setSelectedMembers(allMemberIds);
    } else {
      setSelectedMembers([]);
    }
  }, [allChecked]);

  const onCheckChange = (eid: string) => {
    if (selectedMembers.includes(eid)) {
      setSelectedMembers((prevMembers) =>
        prevMembers.filter((id) => id !== eid)
      );
    } else {
      setSelectedMembers((prevMembers) => [...prevMembers, eid]);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl'>
      <ModalOverlay />
      <ModalContent p={8} gap={4}>
        <ModalHeader p={0}>
          <MoveToRoleHeader
            roleData={roleData}
            jobHeading={jobHeading}
            onClose={onClose}
            onBackClick={() =>
              setShowData({
                ...showData,
                initialModal: true,
                itemsModal: false,
                noDataModal: false,
              })
            }
            onChange={() => {}}
          />
          <ModalCloseButton m={6} />
        </ModalHeader>
        {jobHeading && (
          <Flex
            align='center'
            p={4}
            gap={'10px'}
            bg='rgba(177, 229, 252, 0.4)'
            borderRadius='8px'
          >
            <FontAwesomeIcon
              icon={faArrowUpRight as IconProp}
              color='#2b85ff'
              fontSize='20px'
            />
            <Text fontSize='14px' fontWeight='400'>
              {/* @ts-ignore */}
              {t('role:moveJobInfo')}
            </Text>
          </Flex>
        )}
        <Flex gap='10px' align='center'>
          <Box w='60%'>
            <SearchInput
              placeholder={t('header:search_member')}
              hideShortcuts
              onChange={(e) => setQuery(e?.target?.value)}
            />
          </Box>
          <Box w='40%'>
            <AuthRoleFilter
              onFilterChange={onFilterChange}
              selectedFilter={selectedFilter}
            />
          </Box>
        </Flex>
        <Box maxH='calc(100vh - 280px)' overflow='auto'>
          {filteredMembers?.length > 0 && (
            <List spacing={3}>
              <>
                <HStack
                  align='center'
                  justify='space-between'
                  cursor='pointer'
                  onClick={onAllCheckChange}
                  paddingX={2}
                >
                  <Text as='b' userSelect='none'>
                    {t('select_all')}
                  </Text>
                  {allChecked ? (
                    <FontAwesomeIcon
                      icon={faSquareCheck as IconProp}
                      color='#2A85FF'
                      size='lg'
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSquare as IconProp}
                      color='#999999'
                      size='lg'
                    />
                  )}

                  {/* <Checkbox
                    isChecked={allChecked}
                    onChange={onAllCheckChange}
                  /> */}
                </HStack>
                {filteredMembers?.map((member) => {
                  return (
                    <MoveToItem
                      key={member.eid}
                      item={member}
                      selectedMembers={selectedMembers}
                      onCheckChange={onCheckChange}
                    />
                  );
                })}
              </>
            </List>
          )}
          {filteredMembers?.length === 0 && (
            <EmptyState image='Search' title='Could not find members' />
          )}
        </Box>
        {filteredMembers?.length > 0 && (
          <MoveToRoleFooter
            roleData={roleData}
            members={selectedMembers}
            onClose={onClose}
            selectedAlignCenter={selectedAlignCenter}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

MoveMembersToAnotherRoleModal.displayName = 'Move to component';

export default MoveMembersToAnotherRoleModal;

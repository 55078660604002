import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LauncherTaskStep } from '../common';

interface IProps {
  step: LauncherTaskStep;
}

const TemplateSubTasks: FC<IProps> = ({ step }) => {
  return (
    <Flex mt={2} gap='10px'>
      <FontAwesomeIcon
        icon={faCircleCheck as IconProp}
        color='#6F767E'
        size='lg'
      />
      <Box fontSize='13px' fontWeight='400' color='#33383F'>
        {step.title}
      </Box>
    </Flex>
  );
};

export default TemplateSubTasks;

import React, { FC, useEffect, useRef } from 'react';
import { Flex } from '@chakra-ui/react';
import { eventBus } from '../../../../../shared/eventEmit';

import { ITaskItem } from '../editor.types';
import BaseTaskForm, { BaseTaskFormRef, ITaskInput } from './BaseTaskForm';

interface IProps {
  isFirstTask?: boolean;
  isEdit?: boolean;
  initialValue?: ITaskItem;
  onSubmit: (values: ITaskInput) => void | Promise<void>;
  onCollapse?: (values: ITaskInput, valueChanged?: boolean) => void;
  off?: () => void;
  actionHandler?: (name: string, value: string) => void;
}

const EditorTaskForm: FC<IProps> = ({
  isFirstTask,
  isEdit,
  initialValue,
  onCollapse,
  onSubmit,
  actionHandler,
}) => {
  const ref = useRef<BaseTaskFormRef>(null);

  useEffect(() => {
    if (initialValue?.isNew) {
      const remove = eventBus.on('newLauncherTask', () =>
        ref.current?.focusForm?.()
      );

      return () => remove();
    }
  }, [initialValue?.isNew]);

  useEffect(() => {
    if (initialValue?.tempEid) {
      const remove = eventBus.on(initialValue?.tempEid + 'validate', () =>
        ref.current?.validate?.()
      );

      return () => remove();
    }
  }, [initialValue?.tempEid]);

  return (
    <Flex flexDir='column' gap={5} p='36px' bg='#FFFFFF' borderRadius='12px'>
      <BaseTaskForm
        ref={ref}
        isEdit={isEdit}
        isFirstTask={isFirstTask}
        initialValue={initialValue}
        onCollapse={onCollapse}
        onSubmit={onSubmit}
        actionHandler={actionHandler}
      />
    </Flex>
  );
};

export default EditorTaskForm;

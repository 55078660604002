import React, { FC } from 'react';
import { Button, Center, Flex, List, ListItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PerformanceMemberName } from '../../../sub-components/tasks/performance/common';
import TaskProgress from '../../../sub-components/tasks/performance/common/TaskProgress';
import { PerformanceEntity } from '../../../sub-components/tasks/performance';

import EmptyJobMembers from '../../../sub-components/tasks/performance/overview/jobs/EmptyJobMembers';
import Loader from '../../../sub-components/Loader';

interface IProps {
  loading?: boolean;
  performanceList: PerformanceEntity[];
  clickedHandler: (user: PerformanceEntity) => void;
  onViewAllClick: () => void;
}

const PerformanceOverviewList: FC<IProps> = ({
  loading,
  clickedHandler,
  onViewAllClick,
  performanceList = [],
}) => {
  const { t } = useTranslation(['common', 'task']);

  if (loading) {
    return (
      <Center flex={1}>
        <Loader size='xl' />
      </Center>
    );
  }

  if (!performanceList?.length) {
    return (
      <Center flex={1}>
        <EmptyJobMembers title={t('task:empty.allTaskAssignee')} />
      </Center>
    );
  }

  return (
    <Flex gap={2} flexDirection='column'>
      <List spacing='8px'>
        {performanceList?.map((user, index) => (
          <ListItem
            cursor='pointer'
            _hover={{ bg: '#fbfbfb' }}
            key={index}
            onClick={() => clickedHandler?.(user)}
          >
            <Flex justify='space-between' py={2} gap={3}>
              <PerformanceMemberName
                name={user.name}
                role={user.role}
                profilePic={user.profilePic}
                locationName={user?.locations[0]?.name}
              />
              <TaskProgress value={user.completionRate} />
            </Flex>
          </ListItem>
        ))}
      </List>

      <Button
        w='full'
        fontSize='14px'
        fontWeight='600'
        onClick={onViewAllClick}
        bg='#FBFAFC'
        variant='outline'
        mt='12px'
      >
        {t('common:viewAll')}
      </Button>
    </Flex>
  );
};

export default PerformanceOverviewList;

export const listItem = (props) => {
  const selectedState =
    props.selectedConversation &&
    props.selectedConversation.conversationId ===
      props.conversation.conversationId
      ? {
          // backgroundColor: `${props.theme.backgroundColor.primary}`,
          backgroundColor: 'rgba(42, 133, 255, 0.1)',
        }
      : {};

  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'flex-start',
    gap: '16px',
    cursor: 'pointer',
    width: '100%',
    padding: '10px',
    position: 'relative',
    borderRadius: '12px',
    marginBlock: 6,
    ...selectedState,
    '&:hover': {
      backgroundColor: '#EFEFEF',
    },
  };
};

export const itemThumbnailStyle = () => {
  return {
    display: 'inline-block',
    width: '56px',
    height: '56px',
    flexShrink: '0',
  };
};

export const itemDetailStyle = () => {
  return {
    // width: 'calc(100% - 45px)',
    maxWidth: 'calc(100% - 72px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    flexGrow: '1',
    // marginLeft: '16px',
    // borderBottom: '1px solid rgba(20, 20, 20, 0.1)',
    flex: '1 1 auto',
    '&[dir=rtl]': {
      // paddingRight: '16px',
      paddingLeft: '0',
    },
    '&:hover': {
      // borderBottom: `1px solid transparent`,
    },
  };
};

export const itemRowStyle = () => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    gap: '6px',
  };
};

export const itemNameStyle = (props) => {
  return {
    fontSize: '15px',
    fontWeight: '700',
    display: 'block',
    // width: 'calc(100% - 70px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '22px',
    color: '#1A1D1F',
    maxWidth: '150px',
    flex: '1 1 auto',
    // width: '150px',
  };
};

export const itemLastMsgStyle = (props) => {
  return {
    flex: 1,
    margin: '0',
    fontSize: '14px',
    fontWeight: '500',
    // width: 'calc(100% - 50px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '20px',
    color: '#6f767e',
    maxWidth: '180px',
  };
};

export const itemLastMsgTimeStyle = (props) => {
  return {
    fontSize: '11px',
    //  width: '70px',
    textAlign: 'right',
    color: '#9a9fa5',
    minWidth: '48px',
    fontWeight: '600',
  };
};

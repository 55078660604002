import { TrainingData } from 'pages/Training/training.types';
import { ChangeLogTableWrapper } from 'pages/TrainingDraft/ChangeLog/ChangeLogList/ChangeLogTable/ChangeLogTable.styles';
import {
  IChangeEventEntity,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import React, { useEffect, useState } from 'react';
import { IPublishChangeDiffCheckTable } from '../PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import { TFunction } from 'i18next';
import { Tooltip } from '@chakra-ui/react';

interface IRoleComponent {
  changedData: IPublishChangeDiffCheckTable[];
  trainingData: TrainingData;
  initialTrainingData: TrainingData;
  t: TFunction<('common' | 'training')[], undefined, ('common' | 'training')[]>;
}

interface ICombinedChangedTrainingData {
  changes: ICreateChangeLogChangesEntity;
  name: string;
  icon: string;
}

const RoleComponent = ({
  changedData,
  trainingData,
  initialTrainingData,
  t,
}: IRoleComponent) => {
  const [contentDetails, setContentDetails] = useState<
    ICreateChangeLogChangesEntity[]
  >([]);
  useEffect(() => {
    let _contentDetails: ICreateChangeLogChangesEntity[] = [];
    changedData?.map((change) => {
      if (
        change?.tableType === 'roleRemoved' ||
        change?.tableType === 'roleAdded'
      ) {
        change?.tableData?.changes?.map((_change) => {
          _contentDetails.push({
            changeElement: _change?.changeElement,
            changeEvent: _change?.changeEvent,
            roles: _change?.roles,
          });
        });
        console.log('roles changedData : ', _contentDetails);
        setContentDetails(_contentDetails);
      }
    });
  }, [trainingData?.contentDetails, initialTrainingData?.contentDetails]);

  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className='width-30'>{t('training:roles_table_heading')}</th>
          <th>{t('common:update')}</th>
        </tr>
      </thead>
    );
  };

  const getTableBody = (contentDetails: ICreateChangeLogChangesEntity[]) => {
    return contentDetails?.map((content, index) => {
      return (
        <tr key={JSON.stringify(content) + index}>
          <td>{getChangeNameHandler(content?.changeEvent)}</td>
          <td>
            <div className='status-cell'>
              {updatedComparisonsHandler(
                content?.changeEvent,
                content?.roles || []
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  const getChangeNameHandler = (eventType: IChangeEventEntity) => {
    switch (eventType) {
      case 'roleAdded':
        return t('training:role_add_publish_change');
      case 'roleRemoved':
        return t('training:role_delete_publish_change');
    }
  };

  const getMoreRoleStrings = (roles: string[]) => {
    let strArr: string[] = [];
    roles?.map((role, index) => {
      if (index > 1) {
        strArr.push(role);
      }
    });
    return strArr.join(', ');
  };

  const getMoreRoles = (roles: string[], type: 'add' | 'remove') => {
    let count = 0;
    roles?.map((role, index) => {
      if (index > 1) {
        count++;
      }
    });
    return (
      <Tooltip hasArrow label={getMoreRoleStrings(roles)}>
        <div
          className={
            type === 'add'
              ? 'status new-status cursor-pointer'
              : 'status old-status cursor-pointer'
          }
        >
          <span
            className={
              type === 'add'
                ? 'status-text new-status-text'
                : 'status-text old-status-text'
            }
          >
            +{count} more
          </span>
        </div>
      </Tooltip>
    );
  };

  const updatedComparisonsHandler = (
    eventType: IChangeEventEntity,
    roles: string[]
  ) => {
    console.log('roles  updatedComparison : ', roles);
    switch (eventType) {
      case 'roleAdded':
        return (
          <>
            {roles?.map((role, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status new-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span className='status-text new-status-text'>{role}</span>
                  </div>
                )}
              </>
            ))}
            {roles && roles?.length > 2 && getMoreRoles(roles, 'add')}
          </>
        );
      case 'roleRemoved':
        return (
          <>
            {roles?.map((role, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status old-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span className='status-text old-status-text'>{role}</span>
                  </div>
                )}
              </>
            ))}
            {roles && roles?.length > 2 && getMoreRoles(roles, 'remove')}
          </>
        );
    }
  };

  return (
    <>
      {contentDetails?.length > 0 ? (
        <ChangeLogTableWrapper>
          <table>
            {getTableHeader()}
            {getTableBody(contentDetails)}
          </table>
        </ChangeLogTableWrapper>
      ) : null}
    </>
  );
};

export default RoleComponent;

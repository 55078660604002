import React, { FC } from 'react';
import {
  Box,
  Center,
  Flex,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';
import { getRandomNumber } from '../../../../../utils';

interface IProps {
  isLastItem?: boolean;
}

const TaskItemSkeleton: FC<IProps> = ({ isLastItem }) => {
  return (
    <Flex columnGap='32px'>
      <Flex flexDir='column' align='center'>
        <Center border='4px solid white' borderRadius='full'>
          <SkeletonCircle size='32px' />
        </Center>

        {!isLastItem && <Box flex={1} w={1} bg='white' />}
      </Flex>
      <Flex
        flex={1}
        gap={3}
        py={4}
        px={5}
        mb={4}
        bg='#FFFFFF'
        borderRadius='12px'
        _hover={{
          boxShadow: 'lg',
        }}
      >
        <Flex flexDir='column' flex={1}>
          <Skeleton
            height='12px'
            borderRadius='full'
            width={`${getRandomNumber(40, 60)}%`}
          />
          <SkeletonText mt='4' noOfLines={getRandomNumber(2, 4)} spacing='3' />
        </Flex>
        <Flex gap={5} px={2}>
          <SkeletonCircle size='16px' />
          <SkeletonCircle size='16px' />
          <SkeletonCircle size='16px' />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TaskItemSkeleton;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { truncateString } from 'utils';
import {
  faChevronLeft,
  faArrowUpRightFromSquare,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Authorize, AuthRole } from 'authorization';
import { Button, Flex, Text } from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';

interface LocationState {
  backToTitle?: string;
}

interface IProps {
  trainingPathData: any;
  onGoToClick: () => void;
  setTrainingProgressModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Heading: FC<IProps> = (props) => {
  const { trainingPathData, onGoToClick, setTrainingProgressModal } = props;
  const locState = useLocation<LocationState>()?.state;
  const history = useHistory();
  const { t } = useTranslation(['training']);
  return (
    <>
      <div className='training-title-container'>
        <div className='training-path-title'>
          {truncateString(trainingPathData?.title, 24)}
        </div>
        <div className='training-title-breadcrumb'>
          <FontAwesomeIcon icon={faChevronLeft as IconProp} />
          <span
            onClick={() =>
              locState?.backToTitle
                ? history?.goBack()
                : history?.push('/training')
            }
            style={{ cursor: 'pointer' }}
          >
            &nbsp;
            {locState?.backToTitle ?? t('training:back_to_training_path')}
          </span>
        </div>
      </div>
      {trainingPathData?.title && (
        <Authorize
          permittedRoles={[
            AuthRole.SUPER_ADMIN,
            AuthRole.ADMIN,
            AuthRole.LOCATION_OWNER,
          ]}
          permittedFor={['user']}
        >
          <Flex mt='7px' mb='24px' gap={2}>
            <Authorize
              permittedRoles={[AuthRole.SUPER_ADMIN]}
              permittedFor={['user']}
              canAccess={(authUser) => {
                return authUser?.eid === trainingPathData?.createdBy?.eid;
              }}
            >
              <Button
                size='md'
                title={`Go To ${trainingPathData?.title}`}
                colorScheme='blue'
                onClick={onGoToClick}
                rightIcon={
                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare as IconProp}
                    style={{ fontSize: 18 }}
                    color={'white'}
                  />
                }
              >
                <Text>
                  {/* @ts-ignore */}
                  {t('training:training_details_section.go_to_training', {
                    name: truncateString(
                      trainingPathData?.title,
                      20
                    )?.toLowerCase(),
                  })}
                </Text>
              </Button>
            </Authorize>
            <Authorize permittedRoles={[AuthRole.LOCATION_OWNER]}>
              <PrimaryButton
                size='md'
                title={t('training:view_play')}
                leftIcon={<FontAwesomeIcon icon={faPlay as IconProp} />}
                colorScheme={'blue'}
                style={{ width: 'fit-content' }}
                onClick={() =>
                  window.open(`/training-play/${trainingPathData?.eid}`)
                }
              />
            </Authorize>
          </Flex>
        </Authorize>
      )}
    </>
  );
};

export default Heading;

import { gql } from '@apollo/client';

export const GET_ENTITY_USERS = gql`
  query SHARED_DATA_EntityUser($entityId: String!) {
    EntityUser(entityId: $entityId) {
      eid
      authRole
      email
      name
      type
      role
      status
      locations {
        eid
        name
      }
      username
      profilePic
      timezone
    }
  }
`;

export const GET_FOLDER_BY_ID = gql`
  query EntityCategoryById($eid: String!) {
    EntityCategoryById(eid: $eid) {
      children {
        eid
        name
        status
        icon
        color
        createdBy
        createdByUser {
          eid
          name
          profilePic
        }
        visibility
        visibleTo {
          condition
          locations
          roles
          users
        }
      }
      eid
      name
      status
      icon
      color
      parent
      visibility
      visibleTo {
        condition
        locations
        roles
        users
      }
    }
  }
`;

import {
  createContext,
  Dispatch,
  FC,
  Ref,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { QueryLazyOptions, useLazyQuery, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from 'atoms';
import { toArray } from 'utils/utils';

import {
  getMembers,
  ILocationMember,
  LocationResponse,
  LocationVariable,
  moveLocationMember,
  MoveMemberResponse,
  MoveMemberVariable,
} from './delete-location.graphql';

interface IContext {
  itemsList: ILocationMember[];
  fetchData?: (options?: QueryLazyOptions<LocationVariable>) => void;
  totalCount: number;
  fetchingData?: boolean;
  locationId?: string;
  setItemList?: Dispatch<SetStateAction<ILocationMember[]>>;
  refreshData?: () => void;
  onMoveClick?: (value: SelectOption[]) => Promise<unknown>;
  moving?: boolean;
  selectRef?: Ref<any>;
  selectedItems: ILocationMember[];
}

export const LocationContext = createContext<IContext>({
  itemsList: [],
  selectedItems: [],
  totalCount: 0,
});

interface IProps {
  locationId: string;
  onMoveSuccess?: () => void;
}

export const LocationProvider: FC<IProps> = ({
  children,
  locationId,
  onMoveSuccess,
}) => {
  const { t } = useTranslation(['common', 'location']);
  const toast = useToast({
    position: 'top-right',
  });
  const selectRef = useRef<any>(null);
  const refreshRef = useRef(false);
  const [itemsList, setItemList] = useState<ILocationMember[]>([]);

  const [totalCount, setTotalCount] = useState(0);

  const [fetchData, { loading }] = useLazyQuery<
    LocationResponse,
    LocationVariable
  >(getMembers, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const _members = data.userById?.members || [];

      setTotalCount(_members.length);
      if (refreshRef.current) {
        setItemList(_members);
      } else {
        setItemList((prevState) => {
          const filteredData = prevState?.filter((val) => val.checked) || [];
          const keys: string[] = filteredData?.map((val) => val.eid) || [];
          const items = _members.filter((val) => !keys.includes(val.eid));
          return [...filteredData, ...items];
        });
      }
      refreshRef.current = false;
    },
  });

  const [moveToLocation, { loading: moving }] = useMutation<
    MoveMemberResponse,
    MoveMemberVariable
  >(moveLocationMember, {
    onCompleted: () => {
      onMoveSuccess?.();
      selectRef?.current?.blur();

      setItemList((prevState) => {
        const filteredData = prevState?.filter((val) => !val.checked) || [];
        return [...filteredData];
      });

      const nextLocation = toArray(selectRef?.current?.value)[0]?.label;

      toast({
        status: 'success',
        title: t('common:success'),
        description: nextLocation
          ? // @ts-ignore
            t('location:memberMovedToLocation', {
              location: nextLocation,
            })
          : t('location:userMovedSuccess'),
      });

      if (locationId) {
        fetchData({
          variables: {
            eid: locationId,
          },
        });
      }
    },
  });

  useEffect(() => {
    fetchData({
      variables: {
        eid: locationId,
      },
    });
  }, [locationId]);

  const refreshData = useCallback(() => {
    refreshRef.current = true;
    fetchData({
      variables: {
        eid: locationId,
      },
    });
  }, [locationId]);

  const onMoveClick = useCallback(
    (_values: SelectOption[]) => {
      const nextLocation = toArray(_values)[0]?.value;

      const items = itemsList.reduce<string[]>(
        (previousValue, currentValue) => {
          if (currentValue.checked) {
            previousValue.push(currentValue.eid);
          }
          return previousValue;
        },
        []
      );

      return moveToLocation({
        variables: {
          deleteLoc: locationId,
          moveToLoc: nextLocation,
          userIds: items,
        },
      });
    },
    [itemsList]
  );

  const selectedItems = useMemo(() => {
    return itemsList?.filter((value) => value.checked) || [];
  }, [itemsList]);

  return (
    <LocationContext.Provider
      value={{
        itemsList,
        setItemList,
        fetchData,
        totalCount: totalCount,
        fetchingData: loading,
        locationId: locationId,
        refreshData,
        onMoveClick,
        moving,
        selectRef,
        selectedItems,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

import { Card } from 'antd';
import React from 'react'
import { CardColorHeaderWrapper } from './CardColorHeader.styles';

interface ICardColorHeader {
    heading: string;
    color: string;
    children: React.ReactNode;
}

const CardColorHeader = ({heading, color, children}: ICardColorHeader) => {
  return (
    <CardColorHeaderWrapper color={color}>
        <Card style={{ borderRadius: '7px' }}>
            <div className='color-header-container'>
                <div className='color-heading-main'>
                    <span className='card-header-color'></span>
                    <span className='card-header-heading'>{heading}</span>
                </div>
                <div>{ children }</div>
            </div>
        </Card>
    </CardColorHeaderWrapper>
  )
}

export default CardColorHeader
import { Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useUserData } from 'hooks';
import React, { FC } from 'react';
import { AuthRole } from 'sop-commons/src/client';
import { UserAnalyticsOverviewType } from 'sub-components/AnalyticsBoard/AnalyticsBoard';
import { initialData } from 'sub-components/AnalyticsBoard/initialData';
import {
  ActiveMembersSection,
  FormsFilledSection,
  TasksCompletedSection,
  TrainingsFinishedSection,
} from './components';

const borderBaseStyle = '1px solid #F5F5F5';

export const wrapTextWithSpan = (text: string) =>
  text.split(' ').map((word, index) => <Text key={index}>{word} </Text>);

interface IProps {
  analyticsData: UserAnalyticsOverviewType['userAnalyticsOverview'] | undefined;
}

const Wrapper = styled.div`
  width: 50%;
  table {
    border-collapse: separate;
    border-spacing: 0;
    height: 100%;
    width: 100%;
  }
  th,
  td {
    border: 1px solid #f5f5f5;
    text-align: left;
  }
  table > tbody > tr {
    max-height: 151px;
  }
  table > tbody > tr > td {
    width: 250px;
    height: 151px;
    vertical-align: baseline;
  }
`;

const NumericStats: FC<IProps> = ({ analyticsData }) => {
  const userData = useUserData();
  return (
    <>
      {userData?.authRole !== AuthRole.WORKER && userData?.type !== 'branch' ? (
        <Wrapper>
          <table>
            <tbody>
              <tr>
                <td>
                  <ActiveMembersSection
                    analyticsData={
                      analyticsData || initialData['userAnalyticsOverview']
                    }
                  />
                </td>
                <td>
                  <TasksCompletedSection
                    analyticsData={
                      analyticsData || initialData['userAnalyticsOverview']
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <TrainingsFinishedSection
                    analyticsData={
                      analyticsData || initialData['userAnalyticsOverview']
                    }
                  />
                </td>
                <td>
                  <FormsFilledSection
                    analyticsData={
                      analyticsData || initialData['userAnalyticsOverview']
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Wrapper>
      ) : (
        // <Flex
        //   flexDirection='column'
        //   justify='space-between'
        //   w='45%'
        //   borderRight={borderBaseStyle}
        //   h='40vh'
        // >
        //   <Flex h='20vh'>
        //     <ActiveMembersSection
        //       style={{ borderRight: borderBaseStyle }}
        //       analyticsData={
        //         analyticsData || initialData['userAnalyticsOverview']
        //       }
        //     />
        //     <TasksCompletedSection
        //       style={{
        //         borderTop: borderBaseStyle,
        //       }}
        //       analyticsData={
        //         analyticsData || initialData['userAnalyticsOverview']
        //       }
        //     />
        //   </Flex>
        //   <Flex h='20vh'>
        //     <TrainingsFinishedSection
        //       style={{
        //         borderTop: borderBaseStyle,
        //         borderRight: borderBaseStyle,
        //       }}
        //       analyticsData={
        //         analyticsData || initialData['userAnalyticsOverview']
        //       }
        //     />
        //     <FormsFilledSection
        //       style={{ borderTop: borderBaseStyle }}
        //       analyticsData={
        //         analyticsData || initialData['userAnalyticsOverview']
        //       }
        //     />
        //   </Flex>
        // </Flex>
        <Flex flexDir='column' w='full'>
          <TasksCompletedSection
            style={{ borderBottom: borderBaseStyle }}
            analyticsData={
              analyticsData || initialData['userAnalyticsOverview']
            }
          />
          <TrainingsFinishedSection
            style={{ borderBottom: borderBaseStyle }}
            analyticsData={
              analyticsData || initialData['userAnalyticsOverview']
            }
          />
          <FormsFilledSection
            analyticsData={
              analyticsData || initialData['userAnalyticsOverview']
            }
          />
        </Flex>
      )}
    </>
  );
};

export default NumericStats;

import React, { FC, useMemo } from 'react';
import { Flex, Text, Tooltip } from '@chakra-ui/react';

import { toArray } from '../../../../../utils/utils';
import { ResponseEntity } from '../../../../../types';

import NotesText from './NotesText';
import NoteImageTag from './NoteImageTag';

interface IProps {
  response: ResponseEntity;
  question: any;
}

const DropDownCell: FC<IProps> = ({ question, response }) => {
  const dropdownData = useMemo(() => {
    return toArray(question?.options).find((opt) => {
      return opt.eid === response.responseId?.[0];
    })?.label!;
  }, [question, response]);

  return (
    <Flex flexDir='column' gap='5px'>
      {dropdownData?.length > 0 && (
        <Tooltip label={dropdownData} hasArrow>
          <Flex
            p='5px 10px'
            bg='#e0f5fe'
            borderRadius='7px'
            align='center'
            w='fit-content'
            maxW='100%'
            cursor='pointer'
          >
            <Text m={0} p={0} color='#272B30' isTruncated>
              {dropdownData}
            </Text>
          </Flex>
        </Tooltip>
      )}
      {!dropdownData && <div>-</div>}
      <NotesText noteText={response?.noteText} />
      <NoteImageTag noteImages={response?.noteImage} />
    </Flex>
  );
};

export default DropDownCell;

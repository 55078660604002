import { cloneDeep } from '@apollo/client/utilities';
import { CardModal, HandbookModal } from 'modal';
import { ChapterModal } from 'modal/chapter-modal';
import moment from 'moment';
import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import React from 'react';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { UserData } from 'sop-commons/src/client';
import { MILESTONE_THUMBNAIL } from '../../configs/default-image';
import { IMultiSelectListData } from 'sub-components/MultiSelectChipDropdown/MultiSelectChipDropdown';
import { IMilestoneModalClose, ISelectedIcon } from './index';

export const getTrainingDataHandler = (
  data: any,
  setTrainingData: React.Dispatch<any>,
  setSelectedIcon: React.Dispatch<React.SetStateAction<ISelectedIcon>>,
  setInitialTrainingData: React.Dispatch<any>,
  setContentById: React.Dispatch<any>,
  setThirdStepSelections: React.Dispatch<React.SetStateAction<IThirdStepData>>,
  setThirdStepFinalSelections: React.Dispatch<
    React.SetStateAction<IThirdStepData>
  >
) => {
  console.log('TRAINING BY ID DATA : : : : : ', data?.trainingById);
  let translatedTrainingContentDetails = new HandbookModal(
    data?.trainingById?.contentDetails
  )?.data;
  // data?.trainingById?.trainingItems?.map(item => new CardModal(item));
  data?.trainingById?.trainingItems?.forEach((item: any) => {
    if (item?.type === 'milestone') {
      item.content = [
        {
          url: item?.thumbnail || '',
          addedBy: item?.addedBy || '',
          dateAdded: item?.dateAdded || moment()?.toISOString(),
          fileSize: item?.fileSize || 0,
        },
      ];
    }
  });
  console.log('_TRAINING ITEMS : : : : : ', data?.trainingById?.trainingItems);
  let translatedTrainingItems = new ChapterModal(
    data?.trainingById?.trainingItems
  )?.data;
  console.log('TRANSLATED TRAINING ITEMS : : : : : ', translatedTrainingItems);
  let _trainingByIdData = cloneDeep(data?.trainingById);
  _trainingByIdData.contentDetails = translatedTrainingContentDetails || [];
  _trainingByIdData.trainingItems = translatedTrainingItems || [];
  setTrainingData(_trainingByIdData);
  setSelectedIcon({
    thumbnail: _trainingByIdData?.thumbnail,
    thumbnailColor: _trainingByIdData?.thumbnailColor,
  });
  setInitialTrainingData(_trainingByIdData);
  let contentById: any = {};
  _trainingByIdData?.contentDetails?.forEach((content: any) => {
    contentById[content.eid] = content;
  });
  setContentById({ ...contentById });

  /**
   * Setting Roles array
   */
  if (
    _trainingByIdData?.assignedRoles &&
    _trainingByIdData?.assignedRoles?.length > 0
  ) {
    let _rolesArray: IMultiSelectListData[] = [];
    _trainingByIdData?.assignedRoles?.map(
      (assign: { assignedOn: string; name: string; eid: string }) => {
        _rolesArray?.push({
          id: assign?.eid,
          label: assign?.name,
          value: assign?.name,
          selected: true,
          url: '',
        });
      }
    );
    // setThirdStepSelections({ ...thirdStepSelections, roles: _rolesArray });
    setThirdStepSelections((prevValue) => {
      return { ...prevValue, roles: _rolesArray };
    });
    setThirdStepFinalSelections((prevValue) => {
      return { ...prevValue, roles: _rolesArray };
    });
  }

  /**
   * Setting Supervisors array
   */
  if (
    _trainingByIdData?.supervisorsUsers &&
    _trainingByIdData?.supervisorsUsers?.length > 0
  ) {
    let _supervisorArray: IMultiSelectListData[] = [];
    _trainingByIdData?.supervisorsUsers?.map(
      (supervisor: {
        eid: string;
        name: string;
        profilePic: string;
        role: string;
        authRole?: string;
        locations: { name: string; eid: string }[];
      }) => {
        _supervisorArray?.push({
          id: supervisor?.eid,
          label: supervisor?.name,
          value: supervisor?.name,
          selected: true,
          url: supervisor?.profilePic,
          role: supervisor?.role,
          authRole: supervisor?.authRole,
          location: supervisor?.locations?.[0]?.name || 'N/A',
        });
      }
    );
    setThirdStepSelections((prevValue) => {
      return { ...prevValue, supervisors: _supervisorArray };
    });
    setThirdStepFinalSelections((prevValue) => {
      return { ...prevValue, supervisors: _supervisorArray };
    });
  }

  /**
   * Setting Locations array
   */
  if (
    _trainingByIdData?.assignedToLocations &&
    _trainingByIdData?.assignedToLocations?.length > 0
  ) {
    let _locationsArray: IMultiSelectListData[] = [];
    _trainingByIdData?.assignedToLocations?.map(
      (location: { eid: string; name: string; profilePic: string }) => {
        _locationsArray?.push({
          id: location?.eid,
          label: location?.name,
          value: location?.name,
          selected: true,
          url: '',
        });
      }
    );
    setThirdStepSelections((prevValue) => {
      return { ...prevValue, locations: _locationsArray };
    });
    setThirdStepFinalSelections((prevValue) => {
      return { ...prevValue, locations: _locationsArray };
    });
  }

  /**
   * Setting Members array
   */
  if (
    _trainingByIdData?.assignedTo &&
    _trainingByIdData?.assignedTo?.length > 0
  ) {
    let _membersArray: IMultiSelectListData[] = [];
    _trainingByIdData?.assignedTo?.map(
      (member: {
        eid: string;
        name: string;
        profilePic: string;
        authRole?: string;
        role: string;
        locations: { name: string; eid: string }[];
      }) => {
        console.log('ASSIGNED TO : ', member);
        _membersArray?.push({
          id: member?.eid,
          label: member?.name,
          value: member?.name,
          selected: true,
          url: member?.profilePic,
          role: member?.role,
          authRole: member?.authRole,
          location: member?.locations?.[0]?.name || 'N/A',
        });
      }
    );
    setThirdStepSelections((prevValue) => {
      return { ...prevValue, members: _membersArray };
    });
    setThirdStepFinalSelections((prevValue) => {
      return { ...prevValue, members: _membersArray };
    });
  }
};

export const publishChangesHandler = (
  trainingData: any,
  router: any,
  getValues: UseFormGetValues<FieldValues>,
  selectedIcon: ISelectedIcon,
  thirdStepSelections: IThirdStepData,
  initialTrainingData: any,
  setUpdatedTrainingInput: React.Dispatch<any>,
  publishHandler: (inputData: any) => void
) => {
  let assignedToIDs = trainingData?.assignedToUsers.map(
    (assign: any) => assign.eid
  );
  let contentsArr: any[] = [];
  trainingData?.trainingItems.map((content: any) => {
    if (content.type === 'milestone') {
      if (content.eid) {
        contentsArr.push({
          type: content.type,
          eid: content.eid,
          title: content.title,
          thumbnail: content?.content?.[0]?.url || MILESTONE_THUMBNAIL,
          addedBy: content?.content?.[0]?.addedBy || '',
          dateAdded:
            content?.content?.[0]?.dateAdded || moment()?.toISOString(),
          fileSize: content?.content?.[0]?.fileSize || 0,
          backgroundColor: content.backgroundColor || '#ffffff',
        });
      } else {
        contentsArr.push({
          type: content.type,
          title: content.title,
          thumbnail: content?.content?.[0]?.url || MILESTONE_THUMBNAIL,
          addedBy: content?.content?.[0]?.addedBy || '',
          dateAdded:
            content?.content?.[0]?.dateAdded || moment()?.toISOString(),
          fileSize: content?.content?.[0]?.fileSize || 0,
          backgroundColor: content.backgroundColor || '#ffffff',
        });
      }
    } else {
      contentsArr.push({
        type: content.type,
        eid: content.eid,
        title: content.title,
        isMandatory: content?.isMandatory,
      });
    }
  });
  let input: {
    title: string;
    thumbnail: string;
    thumbnailColor: string;
    description: string;
    supervisors: string[];
    assignedTo: string[];
    contents: any[];
    status: string;
    assignedToLocation?: string[];
    assignedToRole?: string[];
    eid?: string;
    visibility: string;
  } = {
    eid: router.query.id,
    title: getValues('name'),
    status: trainingData?.status,
    thumbnail: selectedIcon?.thumbnail,
    thumbnailColor: selectedIcon?.thumbnailColor,
    description: trainingData?.description,
    visibility: trainingData?.visibility,
    supervisors: thirdStepSelections?.supervisors?.map(
      (supervisor) => supervisor?.id
    ),
    assignedTo: thirdStepSelections?.members?.map((member) => member?.id),
    contents: contentsArr,
  };
  if (thirdStepSelections?.locations?.length > 0) {
    input.assignedToLocation = thirdStepSelections?.locations?.map(
      (location) => location?.id
    );
  } else {
    input.assignedToLocation = [];
  }
  if (thirdStepSelections?.roles?.length > 0) {
    input.assignedToRole = thirdStepSelections?.roles?.map(
      (role) => role?.value
    );
  } else {
    input.assignedToRole = [];
  }
  // setIsPublishChangeDiffCheckModal(true);
  let _initialTrainingData = JSON.parse(JSON.stringify(initialTrainingData));
  if (
    _initialTrainingData &&
    _initialTrainingData?.assignedTo &&
    _initialTrainingData?.assignedTo?.length > 0
  ) {
    let foundIds = _initialTrainingData?.assignedTo?.map(
      (assign: any) => assign?.eid
    );
    _initialTrainingData.assignedTo = foundIds;
  }
  // let _changeLogResponse = TrainingDraftMethods.changeLogDataOperationHandler(
  //   _initialTrainingData,
  //   input
  // );
  // setChangeLogResponse(_changeLogResponse);
  // let ObjMap: any = {};
  // _changeLogResponse.forEach((element: any) => {
  //   var makeKey = element.changeEvent;
  //   if (!ObjMap[makeKey]) {
  //     ObjMap[makeKey] = [];
  //   }
  //   ObjMap[makeKey].push(element);
  // });
  // let createdObject: IPublishChangeDiffCheckTable[] = [];
  // Object.entries(ObjMap).map((val: any) => {
  //   createdObject.push({
  //     tableType: val[0],
  //     tableData: { changes: val[1] },
  //   });
  // });
  // setChangedData(createdObject); /////
  console.log('PUBLISH : : : : : : ', input);
  setUpdatedTrainingInput(input);
  publishHandler(input);

  // updateTraining({
  //   variables: {
  //     input: input,
  //   },
  // });
};

export const milestoneModalCloseHandler = async (
  type: IMilestoneModalClose,
  setIsMilestoneModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  milestoneType: string,
  userData: UserData,
  trainingData: any,
  contentById: any,
  setTrainingData: React.Dispatch<any>,
  setContentById: React.Dispatch<any>,
  setCheckEditCardOrMilestone: React.Dispatch<React.SetStateAction<boolean>>,
  selectedMilestone: any,
  data?: any
) => {
  if (type === 'close') {
    setIsMilestoneModalOpen(false);
  } else {
    if (milestoneType === 'actionMenu') {
      let tempEid = Date.now();
      setIsMilestoneModalOpen(false);
      let _data = {
        eid: null,
        title: data.mileStoneName,
        content: [
          {
            url: data?.milestoneUrl || '',
            addedBy: data?.milestoneAddedBy || userData?.name,
            dateAdded: data?.milestoneDateAdded || moment()?.toISOString(),
            fileSize: data?.milestoneFileSize || 0,
          },
        ],
        type: 'milestone',
        tempEid: tempEid,
        backgroundColor: data.backgroundColor,
      };
      let tempArr = cloneDeep(trainingData);
      tempArr?.contents.push(_data);
      tempArr?.contentDetails.push({
        eid: null,
        title: data.mileStoneName,
        content: [
          {
            url: data?.milestoneUrl || '',
            addedBy: data?.milestoneAddedBy || userData?.name,
            dateAdded: data?.milestoneDateAdded || moment()?.toISOString(),
            fileSize: data?.milestoneFileSize || 0,
          },
        ],
        type: 'milestone',
        uid: null,
        tempEid: tempEid,
        backgroundColor: data.backgroundColor,
      });
      let translatedMilestone = new CardModal(_data);
      console.log('Translated Milestone : : : : : ', translatedMilestone);
      tempArr?.trainingItems?.push(translatedMilestone);
      let tempContentById = JSON.parse(JSON.stringify(contentById));
      tempContentById[tempEid] = data?.data?.createCard?.[0];
      setTrainingData(tempArr);
      setContentById(tempContentById);
      setIsMilestoneModalOpen(false);
    } else if (milestoneType === 'editMenu') {
      setIsMilestoneModalOpen(false);
      let tempArr = cloneDeep(trainingData);
      let _arr: any[] = [];
      let promise = tempArr?.trainingItems?.map((item: any) => {
        if (item?.type === 'milestone') {
          if (item?.eid === data?.tempEid || item?.tempId === data?.tempEid) {
            let _initialData = {
              backgroundColor: '#ffffff',
              cardType: 'milestone',
              content: [
                {
                  url: data?.milestoneUrl || '',
                  addedBy: data?.milestoneAddedBy || userData?.name,
                  dateAdded:
                    data?.milestoneDateAdded || moment()?.toISOString(),
                  fileSize: data?.milestoneFileSize || 0,
                },
              ],
              entityId: userData?.entityId,
              handbookView: true,
              layout: 'simple-text',
              raw_content: data?.mileStoneName,
              subTitle: '',
              title: data?.mileStoneName,
              tempId: data?.tempEid,
              type: 'milestone',
            };
            let translatedReturnedData = new CardModal(_initialData);
            _arr.push(translatedReturnedData);
          } else {
            _arr.push(item);
          }
        } else {
          _arr.push(item);
        }
      });
      await Promise.all(promise);
      tempArr.trainingItems = _arr;
      setTrainingData(tempArr);
      setCheckEditCardOrMilestone(true);
    } else {
      setIsMilestoneModalOpen(false);
      let tempEid = Date.now();
      let tempArr = cloneDeep(trainingData);
      let index = tempArr.contents.findIndex((arr: any) => {
        return arr.eid === selectedMilestone.eid;
      });
      tempArr?.contentDetails.splice(index + 1, 0, {
        eid: null,
        title: data.mileStoneName,
        type: 'milestone',
        uid: null,
        tempEid: tempEid,
        backgroundColor: data.backgroundColor,
      });
      tempArr?.contents.splice(index + 1, 0, {
        eid: null,
        title: data.mileStoneName,
        type: 'milestone',
        tempEid: tempEid,
        backgroundColor: data.backgroundColor,
      });
      setTrainingData(tempArr);
      let tempContentById = JSON.parse(JSON.stringify(contentById));
      tempContentById[tempEid] = data?.data?.createCard?.[0];
      setContentById(tempContentById);
    }
  }
};

export const sameSequenceCheck = (
  trainingData: any,
  initialTrainingData: any,
  thirdStepFinalSelections: IThirdStepData
) => {
  let flag = false;
  trainingData?.contents?.map((content: any, contentIndex: number) => {
    initialTrainingData?.contents?.map((initial: any, initialIndex: number) => {
      if (contentIndex === initialIndex && content?.eid !== initial?.eid) {
        flag = true;
        return;
      }
    });
    if (flag) {
      return;
    }
  });
  if (
    thirdStepFinalSelections?.supervisors?.length > 0 &&
    initialTrainingData?.supervisors?.length === 0
  ) {
    flag = true;
  } else {
    if (
      thirdStepFinalSelections?.supervisors?.length !==
      initialTrainingData?.supervisors?.length
    ) {
      flag = true;
    } else {
      thirdStepFinalSelections?.supervisors?.map(
        (content, contentIndex: number) => {
          initialTrainingData?.supervisors?.map(
            (initial: any, initialIndex: number) => {
              if (contentIndex === initialIndex && content?.id !== initial) {
                flag = true;
                return;
              }
            }
          );
          if (flag) {
            return;
          }
        }
      );
    }
  }
  if (
    trainingData?.assignedToUsers?.length !==
      initialTrainingData?.assignedToUsers?.length ||
    trainingData?.supervisorsUsers?.length !==
      initialTrainingData?.supervisorsUsers?.length
  ) {
    flag = true;
  }
  if (
    thirdStepFinalSelections?.members?.length > 0 &&
    initialTrainingData?.assignedTo?.length === 0
  ) {
    flag = true;
  } else {
    if (
      thirdStepFinalSelections?.members?.length !==
      initialTrainingData?.assignedTo?.length
    ) {
      flag = true;
    } else {
      thirdStepFinalSelections?.members?.map(
        (content, contentIndex: number) => {
          initialTrainingData?.assignedTo?.map(
            (initial: any, initialIndex: number) => {
              let found = initialTrainingData?.assignedTo?.some(
                (user: any) => user?.eid === content?.id
              );
              if (!found) {
                flag = true;
              }
            }
          );
          if (flag) {
            return;
          }
        }
      );
    }
  }
  if (
    thirdStepFinalSelections?.locations?.length > 0 &&
    initialTrainingData?.assignedLocations?.length === 0
  ) {
    flag = true;
  } else {
    if (
      thirdStepFinalSelections?.locations?.length !==
      initialTrainingData?.assignedLocations?.length
    ) {
      flag = true;
    } else {
      thirdStepFinalSelections?.locations?.map(
        (content, contentIndex: number) => {
          let found = initialTrainingData?.assignedLocations?.some(
            (location: any) => location?.eid === content?.id
          );
          if (!found) {
            flag = true;
          }
          if (flag) {
            return;
          }
        }
      );
    }
  }
  if (
    thirdStepFinalSelections?.roles?.length > 0 &&
    initialTrainingData?.assignedRoles?.length === 0
  ) {
    flag = true;
  } else {
    if (
      thirdStepFinalSelections?.roles?.length !==
      initialTrainingData?.assignedRoles?.length
    ) {
      flag = true;
    } else {
      thirdStepFinalSelections?.roles?.map((content, contentIndex: number) => {
        let found = initialTrainingData?.assignedRoles?.some(
          (role: any) => role?.name === content?.value
        );
        if (!found) {
          flag = true;
        }
        if (flag) {
          return;
        }
      });
    }
  }
  return flag;
};

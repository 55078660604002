import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import { AlertBox } from '../../../../../atoms';
import { ITaskItem } from '../../editor-form/editor.types';

interface IProps {
  nextTask?: ITaskItem;
}

const TaskDeleteContent: FC<IProps> = ({ nextTask }) => {
  const isLinked = nextTask?.dependency === 'PREVIOUS_TASK';

  if (isLinked) {
    return (
      <AlertBox icon={faTrashCan} fontSize='14px' childProps={{}}>
        <Box pb={2}>
          The task titled <strong>{`'${nextTask.title}'`}</strong> is linked to
          this task. Deleting this task will unlock {`'${nextTask.title}.'`}
        </Box>
        Deleting this task from the launcher will affect future locations
        created thereafter. However, ongoing tasks in current locations will
        remain unchanged
      </AlertBox>
    );
  }

  return (
    <AlertBox icon={faTrashCan} fontSize='14px' childProps={{}}>
      Deleting this task from the launcher will affect future locations created
      thereafter. However, ongoing tasks in current locations will remain
      unchanged
    </AlertBox>
  );
};

export default TaskDeleteContent;

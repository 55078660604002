import React, { FC } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { PopupModal } from 'react-calendly';
import { useTranslation } from 'react-i18next';
import { CALENDLY_LINK } from '../../utils/constants';
import noImage from '../../assets/images/placeholder/empty-report.svg';
import whiteIcon from '../../assets/images/logo-white.svg';

const DemoReports: FC = () => {
  const { t } = useTranslation('demo');
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Center w='full' height='calc(100vh - 280px)'>
      <Flex direction='column' gap='14px'>
        <Image src={noImage} maxW='360px' mx='auto' />
        <Flex direction='column' textAlign='center' gap='2px'>
          <Box fontSize='15px' fontWeight='700' color='#303030'>
            {t('noReportGenerated')}
          </Box>
          <Box fontSize='11px' fontWeight='500' color='#9E9E9E' px='12px'>
            {t('getInTouchWithUs')}
          </Box>
        </Flex>
        <Button
          leftIcon={<Image src={whiteIcon} w='20px' h='20px' />}
          w='max-content'
          minW='200px'
          px='16px'
          colorScheme='blue'
          mx='auto'
          maxW='full'
          fontSize='15px'
          onClick={onToggle}
        >
          {t('get_own_account')}
        </Button>
      </Flex>
      <PopupModal
        url={CALENDLY_LINK}
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        // prefill={this.props.prefill}
        onModalClose={onClose}
        open={isOpen}
        rootElement={document.getElementById('root') as HTMLElement}
      />
    </Center>
  );
};

export default DemoReports;

import { Flex, useOutsideClick } from '@chakra-ui/react';
import { IFormInput } from 'pages/Locations/AddLocation/add-location.types';
import { OwnerOption } from 'pages/Locations/Components/LocationOwner';
import React, { FC, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { MenuContainer, SelectField } from './components';

interface IProps {
  placeholder: string;
  isDisabled: boolean | undefined;
  showSearchField: boolean;
  loading?: boolean;
  searchFieldPlaceholder: string;
  options: OwnerOption[];
  value: OwnerOption;
  showInviteButton: boolean;
  onChange: (value: OwnerOption) => void;
  onInviteClick: () => void;
}

const CustomLocationOwnerSelect: FC<IProps> = ({
  placeholder = 'Search...',
  isDisabled,
  loading,
  showSearchField,
  searchFieldPlaceholder,
  options,
  value,
  showInviteButton,
  onChange,
  onInviteClick,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);
  const selectedOwners = useWatch<IFormInput, 'locationOwners'>({
    name: 'locationOwners',
  });

  useOutsideClick({
    ref: ref,
    handler: () => setShowList(false),
  });

  const handleOptionClick = (value: OwnerOption) => {
    onChange(value);
    setShowList(false);
  };

  const _options = useMemo(() => {
    const notIncl = selectedOwners?.reduce<string[]>((acc, val) => {
      if (val?.owner && val.owner?.value !== value?.value) {
        acc.push(val.owner.value);
      }
      return acc;
    }, []);

    return options.filter((val) => {
      return !notIncl?.includes(val.value);
    });
  }, [selectedOwners, value, options]);

  return (
    <Flex pos='relative' flexDir='column' ref={ref} w='full'>
      <SelectField
        setShowList={setShowList}
        placeholder={placeholder}
        value={value}
        loading={loading}
        isDisabled={isDisabled}
      />
      {showList && (
        <MenuContainer
          setShowList={setShowList}
          showSearchField={showSearchField}
          searchFieldPlaceholder={searchFieldPlaceholder}
          options={_options}
          handleOptionClick={handleOptionClick}
          selectedValue={value}
          showList={showList}
          showInviteButton={showInviteButton}
          onInviteClick={onInviteClick}
        />
      )}
    </Flex>
  );
};

export default CustomLocationOwnerSelect;

import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import ReadyLiveCard, { IReadyLiveCard } from '../Singleton/ReadyLiveCard';

/**
 * Props for the ReadyLiveCardList component.
 *
 * @property {IReadyLiveCard[]} list - An array of IReadyLiveCard objects representing each card's data.
 */

interface IProps {
  locations: IReadyLiveCard[];
  onClick?: (locationId: string) => void;
}

/**
 * ReadyLiveCardList component.
 * Renders a list of ReadyLiveCard components based on the provided array of IReadyLiveCard objects.
 *
 * This component is typically used to display a series of cards, each representing a location that is ready to go live.
 *
 * @param props - Properties passed to the component. Contains a list of IReadyLiveCard objects.
 * @returns The JSX code for rendering the list of ReadyLiveCard components.
 */

const ReadyLiveCardList: FC<IProps> = ({ locations, onClick }) => {
  return (
    <Flex gap={6} overflow='auto'>
      {locations?.map((loc) => (
        <ReadyLiveCard
          key={loc.eid}
          eid={loc.eid}
          locationName={loc.locationName}
          completedOn={loc.completedOn}
          onClick={() => onClick?.(loc.eid)}
        />
      ))}
    </Flex>
  );
};

export default ReadyLiveCardList;

export type { IReadyLiveCard };

import { Center, Flex, Spacer } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DecksCount from '../../assets/images/decksCount.png';
import ChangeVisibility from './ChangeVisibility';

interface IProps {
  trainingData: any;
  setTrainingData: React.Dispatch<any>;
}

const EditTrainingCountVisibilityContainer: FC<IProps> = ({
  trainingData,
  setTrainingData,
}) => {
  const { t } = useTranslation(['training', 'common']);
  const cardsDeckCount = () => {
    let count = 0;
    trainingData?.trainingItems?.map((_: any) => {
      count++;
    });
    // trainingData?.trainingItems?.map((content: any) => {
    //   if (content?.type === 'sop') {
    //     count++;
    //   }
    // });
    return count;
  };
  return (
    <Flex
      id='edit-training-count-visibility-container'
      align='center'
      margin='1.5rem 0'
    >
      <span
        style={{
          marginRight: '1rem',
          color: '#979EA6',
          fontSize: '14px',
          fontWeight: '600',
        }}
      >
        {t('training:total_count', {
          value: cardsDeckCount(),
        })}
      </span>
      <Center h='20px' w='21px' bg='#2A85FF' borderRadius='50%' mr='5px'>
        <img
          src={DecksCount}
          style={{ marginRight: '1px' }}
          alt='number-of-deck'
        />
      </Center>
      <span
        style={{
          marginRight: '10px',
          fontSize: '14px',
          fontWeight: '700',
        }}
      >
        {cardsDeckCount()}{' '}
        {cardsDeckCount() < 2
          ? t('training:singleItem')
          : t('training:multipleItems')}
      </span>

      <Spacer />
      <ChangeVisibility
        pathName={trainingData?.title}
        visibility={trainingData?.visibility}
        onChange={(newVisibility) => {
          setTrainingData((preValues: any) => {
            return {
              ...preValues,
              visibility: newVisibility,
            };
          });
        }}
      />
    </Flex>
  );
};

EditTrainingCountVisibilityContainer.displayName =
  'EditTraining/EditTrainingCountVisibilityContainer';

export default EditTrainingCountVisibilityContainer;

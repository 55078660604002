import Avatar1 from './Avatar-1.svg';
import Avatar2 from './Avatar-2.svg';
import Avatar3 from './Avatar-3.svg';
import Avatar4 from './Avatar-4.svg';
import Avatar5 from './Avatar-5.svg';
import Avatar6 from './Avatar-6.svg';
import Avatar7 from './Avatar-7.svg';
import Avatar8 from './Avatar-8.svg';
import Avatar9 from './Avatar-9.svg';
import Avatar10 from './Avatar-10.svg';
import Avatar11 from './Avatar-11.svg';
import Avatar12 from './Avatar-12.svg';
import Avatar13 from './Avatar-13.svg';
import Avatar14 from './Avatar-14.svg';
import Avatar15 from './Avatar-15.svg';
import Avatar16 from './Avatar-16.svg';
import Avatar17 from './Avatar-17.svg';
import Avatar18 from './Avatar-18.svg';
import Avatar19 from './Avatar-19.svg';
import Avatar20 from './Avatar-20.svg';
import Avatar21 from './Avatar-21.svg';
import Avatar22 from './Avatar-22.svg';
import Avatar23 from './Avatar-23.svg';
import Avatar24 from './Avatar-24.svg';
import Avatar25 from './Avatar-25.svg';
import Avatar26 from './Avatar-26.svg';
import Avatar27 from './Avatar-27.svg';

export const avatarData = {
  1: Avatar1,
  2: Avatar2,
  3: Avatar3,
  4: Avatar4,
  5: Avatar5,
  6: Avatar6,
  7: Avatar7,
  8: Avatar8,
  9: Avatar9,
  10: Avatar10,
  11: Avatar11,
  12: Avatar12,
  13: Avatar13,
  14: Avatar14,
  15: Avatar15,
  16: Avatar16,
  17: Avatar17,
  18: Avatar18,
  19: Avatar19,
  20: Avatar20,
  21: Avatar21,
  22: Avatar22,
  23: Avatar23,
  24: Avatar24,
  25: Avatar25,
  26: Avatar26,
  27: Avatar27,
};

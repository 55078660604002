import React, { FC, useCallback, useContext } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import MultiSelectCustom from './MultiSelectCustom';
import { VisibilityContext } from './context';
import { IVisibilityInput } from './visibility.types';
import { CustomSelectComponent } from 'sub-components/CustomDropdowns';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { SelectOption } from 'atoms';

const MembersSelect: FC = () => {
  const { t } = useTranslation(['common', 'deck']);
  const { control } = useFormContext<IVisibilityInput>();
  const { memberList } = useContext(VisibilityContext);
  const selectedMembers: SelectOption[] = useWatch<IVisibilityInput>({
    control: control,
    name: 'members',
  });

  const getSelectedMembers = useCallback(() => {
    return selectedMembers?.map((member) => member?.value);
  }, [selectedMembers]);

  const customMembersOpt = (memberIds: string[]) => {
    let foundMembers = memberList?.filter((member) =>
      memberIds?.includes(member?.value)
    );
    return foundMembers;
  };

  return (
    <Flex
      flexDir='column'
      gap='20px'
      bg='#F7F7F7'
      p='28px 28px'
      borderRadius='10px'
      mt='22px'
    >
      <Box fontSize='16px' fontWeight='600' color='#333B4F'>
        {t('deck:visibility.members_heading')}
      </Box>
      <Flex gap='32px' align='center'>
        <Box flex={1} maxW='calc(50% - 35px)'>
          <Flex
            borderRadius='12px'
            p='12px 16px'
            align='center'
            bg='#ffffff'
            cursor='pointer'
          >
            <Box fontSize='15px' fontWeight='500' color='#1A1D1F'>
              {t('deck:visibility.members_heading')}
            </Box>
          </Flex>
        </Box>
        <Box fontSize='15px' fontWeight='600'>
          {t('deck:visibility.is_text')}
        </Box>
        <Box flex={1} maxW='calc(50% - 35px)'>
          <Controller
            control={control}
            name='members'
            render={({ field }) => {
              return (
                // <MultiSelectCustom
                //   value={field.value}
                //   onChange={(value: any) => {
                //     console.log({ value });
                //     field.onChange(value);
                //   }}
                //   placeholder={t('common:selectMember')}
                //   options={memberList}
                // />
                <CustomSelectComponent
                  options={memberList}
                  value={getSelectedMembers()}
                  placeholder='Select...'
                  showHeader
                  headerTitle='Select member(s)'
                  headerBarColor={HeaderColors.Purple}
                  showSelectAll
                  showHeaderCloseIcon
                  showSearchField
                  showDivider
                  bottomOutsideBtn={{
                    show: true,
                    title: 'Add selected members',
                  }}
                  showFilter
                  onChange={(newValues: string[]) => {
                    let found = customMembersOpt(newValues);
                    field.onChange(found);
                  }}
                  showChips={false}
                  customWidth='600px'
                  selectInputBg='white'
                />
              );
            }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default MembersSelect;

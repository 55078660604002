import { TrainingPathItem } from '../types';
import { toArray } from '../../../../utils/utils';
import { TrainingContent } from '../types/training.content';
import { TrainingBaseModal } from './training-base';

function cardFilter<T extends TrainingContent>(value: T) {
  return value.type === 'card';
}

function chapterFilter<T extends TrainingContent>(value: T) {
  return value.type === 'sop';
}

export class TrainingPathModal extends TrainingBaseModal<TrainingPathItem> {
  protected _cardCount: number;
  protected _chapterCount: number;

  constructor(data: TrainingPathItem) {
    super(data);
    this._cardCount =
      toArray(data.trainingContentList).filter(cardFilter)?.length || 0;
    this._chapterCount =
      toArray(data.trainingContentList).filter(chapterFilter)?.length || 0;
  }

  get status() {
    return this._data.status;
  }

  get visibility() {
    return this._data.visibility;
  }

  get cardCount() {
    return this._cardCount;
  }

  get chapterCount() {
    return this._chapterCount;
  }
  get assignedToUsers() {
    return toArray(this._data.assignedToUsers);
  }

  get createdOn() {
    return this._data.createdAt;
  }
  get createdByName() {
    return this._data.createdBy?.name;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }
  get lastUpdatedBy() {
    return this._data.lastUpdatedBy;
  }
  get description() {
    return this._data.description;
  }
  get supervisors() {
    return toArray(this._data.supervisors);
  }

  get creatorId() {
    return this._data.createdBy.eid;
  }
}

import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import DashboardContainer from '../../sub-components/DashboardContainer';
import { ReportsWrapper } from './ReportsWrapper.styles';
import Reports from './Reports';
import { Authorize } from '../../authorization';
import DemoReports from './DemoReports';

const ReportsContainer: FC = () => {
  const { t } = useTranslation('sidebar');
  return (
    <DashboardContainer>
      <ReportsWrapper>
        <Box
          fontSize={{
            base: '20px',
            md: '28px',
          }}
          fontWeight='700'
          mb='16px'
        >
          {t('reports')}
        </Box>
        <Card>
          <Authorize canAccess={(authUser) => !authUser?.entity?.isDemo}>
            <Reports />
          </Authorize>
          <Authorize canAccess={(authUser) => authUser?.entity?.isDemo}>
            <DemoReports />
          </Authorize>
        </Card>
      </ReportsWrapper>
    </DashboardContainer>
  );
};

export default ReportsContainer;

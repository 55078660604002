import { LauncherLocation } from './dashboard.graphql';
import moment from 'moment';

export const getLastDoneTask = (tasks: LauncherLocation['tasks']) => {
  return tasks
    .filter((a) => a.completedAt)
    .sort((a, b) => Date.parse(a.completedAt) - Date.parse(b.completedAt))
    .at(-1);
};

export const getCompletedTask = (tasks: LauncherLocation['tasks']) => {
  return tasks
    .filter((a) => a.completedAt)
    .sort((a, b) => Date.parse(a.completedAt) - Date.parse(b.completedAt));
};

export const getRemainingTask = (tasks: LauncherLocation['tasks']) => {
  return tasks.filter((a) => !a.completedAt);
  // .sort((a, b) => Date.parse(a.completedAt) - Date.parse(b.completedAt));
};

export const getCurrentTask = (tasks: LauncherLocation['tasks']) => {
  const time = new Date().getTime();
  return getRemainingTask(tasks).reduce<
    LauncherLocation['tasks'][number] | undefined
  >((acc, value) => {
    if (Date.parse(value.startDate) > time) {
      return acc;
    }

    if (Date.parse(value.dueDate) > time) {
      if (!acc) {
        return value;
      }

      if (Date.parse(value.dueDate) < Date.parse(acc.dueDate)) {
        return value;
      }
    }

    return acc;
  }, undefined);
};

export const isLauncherDue = (tasks: LauncherLocation['tasks']) => {
  return tasks.some((it) => {
    return !it.completedAt && moment(it.dueDate).isBefore(undefined, 'minute');
  });
};

import React, { FC } from 'react';
import { Box, Center, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TFuncKey } from 'i18next';

import Loader from '../../../../../Loader';

import SearchEmpty from '../../../../../../assets/images/Illustrations.svg';

interface IProps {
  isLoading?: boolean;
  searchQuery?: string;
  dataLength?: number;
  i18SearchNotFoundKey?: TFuncKey<('form' | 'task' | 'training')[], undefined>;
  i18NotFoundKey: TFuncKey<('form' | 'task' | 'training')[], undefined>;
  i18NotFoundDescKey: TFuncKey<('task' | 'training')[], undefined>;
  emptyImage: string;
}

const DataNotFound: FC<IProps> = ({
  isLoading,
  searchQuery,
  dataLength = 0,
  i18SearchNotFoundKey,
  i18NotFoundKey,
  i18NotFoundDescKey,
  emptyImage,
  children,
}) => {
  const { t } = useTranslation(['task', 'header', 'form', 'training']);
  if (!isLoading && !!dataLength) {
    return <>{children}</>;
  }

  if (isLoading) {
    return (
      <Center pos='absolute' boxSize='full' borderRadius='10px'>
        <Loader size='lg' />
      </Center>
    );
  }

  return (
    <Center flexDir='column' flex={1} gap='8px'>
      <Image
        src={searchQuery ? SearchEmpty : emptyImage}
        boxSize={searchQuery ? '200px' : '180px'}
      />
      <Box fontSize='16px' fontWeight='700' color='#1A1D1F'>
        {t(
          searchQuery
            ? i18SearchNotFoundKey || 'header:result_not_found'
            : i18NotFoundKey
        )}
      </Box>

      <Box
        fontSize='14px'
        fontWeight='500'
        color='#6F767E'
        textAlign='center'
        maxW='260px'
      >
        {t(searchQuery ? 'training:noTrainingsFoundDesc' : i18NotFoundDescKey)}
      </Box>
    </Center>
  );
};

export default DataNotFound;

import xlsxPopulate from 'xlsx-populate';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { match, P } from 'ts-pattern';
import JSZip from 'jszip';

import {
  QuestionEntity,
  QuestionType,
  ResponseEntity,
  UserListEntity,
} from '../../../../../types';
import { toArray } from '../../../../../utils';
import { FormExportEntity } from '../../../forms-graphql';

function nextColumnName(letter: string): string {
  const arr = letter.split('');
  const next = arr.pop()!;
  if (next === 'Z') {
    if (arr.length === 0) {
      return 'AA';
    }
    return nextColumnName(arr.join('')).concat('A');
  } else {
    const nextChar = String.fromCharCode(next.charCodeAt(0) + 1);
    return arr.concat(nextChar).join('');
  }
}

const generateCharacterFromNumber = (number: number) => {
  let baseChar = 'A'.charCodeAt(0),
    letters = '';
  do {
    number -= 1;
    letters = String.fromCharCode(baseChar + (number % 26)) + letters;
    number = (number / 26) >> 0; // quick `floor`
  } while (number > 0);

  return letters;
};

const addHeadersToSheet = (
  sheetArg: xlsxPopulate.Sheet,
  headers: QuestionEntity[]
) => {
  let sheetHeader = headers.reduce<{ key?: string; label: string }[]>(
    (acc, value) => {
      if (value.qType === QuestionType.SECTION) {
        return acc;
      }
      return acc.concat([
        { key: value.eid, label: value.label },
        { label: 'Added Notes' },
      ]);
    },
    [{ label: 'Date' }, { label: 'User' }, { label: 'Location' }]
  );

  const cellKeys: Record<string, string> = {};

  sheetHeader.forEach((header, index) => {
    let cellNumber = generateCharacterFromNumber(index + 1);

    // Setting up column styles
    sheetArg
      .column(cellNumber)
      .width(25)
      .hidden(!header.key && header.label === 'Added Notes')
      .style('wrapText', true)
      .style('verticalAlignment', 'center');

    // Setting up excel header values
    sheetArg
      .cell(cellNumber + '1')
      .value(header.label)
      .style('bold', true)
      .style('verticalAlignment', 'center');

    header.key && (cellKeys[header.key] = cellNumber);
  });
  return cellKeys;
};

const getResponseValue = (
  response: ResponseEntity,
  _questions: Record<string, QuestionEntity>,
  userData: Record<string, UserListEntity>
) => {
  // @ts-ignore
  const richText = new xlsxPopulate.RichText();

  return match({
    ...response,
    qType:
      // @ts-ignore
      response.qType === 'singleChoice'
        ? QuestionType.MULTI_CHOICE
        : response.qType,
  })
    .with({ qType: QuestionType.SHORT_TEXT }, () => response.response)
    .with({ qType: QuestionType.LONG_TEXT }, () => response.response)

    .with(
      {
        qType: P.union(QuestionType.LOCATION, QuestionType.MEMBER),
      },
      () => userData[response.responseId?.[0]!]?.name
    )

    .with(
      {
        qType: P.union(
          QuestionType.MULTI_CHOICE,
          QuestionType.CHECKBOX,
          QuestionType.DROPDOWN
        ),
      },
      () => {
        if (_questions[response.qid]?.options) {
          _questions[response.qid]?.options?.forEach((op) => {
            if (response.responseId?.includes(op.eid)) {
              if (op.isOther) {
                richText.add(response.otherResponse || op.label);
              } else {
                richText.add(op.label);
              }
              richText.add('\r\n');
            }
          });
          return richText;
        }
        return '';
      }
    )

    .with(
      {
        qType: P.union(
          QuestionType.FILE_UPLOAD,
          QuestionType.VIDEO_UPLOAD,
          QuestionType.IMAGE_UPLOAD
        ),
      },
      () => {
        if (response.responseUrl?.length) {
          toArray(response.responseUrl).forEach((value) => {
            richText.add(value, { fontColor: '0000FF' });
            richText.add('\r\n');
          });

          return richText;
        }

        return '';
      }
    )

    .otherwise(() => undefined);
};

const getResponseNote = (
  response: ResponseEntity,
  _questions: Record<string, QuestionEntity>
) => {
  // @ts-ignore
  const richText = new xlsxPopulate.RichText();

  if (response.noteText) {
    richText.add(response.noteText);
    richText.add('\r\n');
  }

  if (response.qType === QuestionType.CHECKBOX) {
    const options = toArray(_questions[response.qid]?.options).reduce(
      (acc, value) => {
        acc[value.eid] = value.label;
        return acc;
      },
      {} as Record<string, string>
    );
    toArray(response.noteImage).forEach((value) => {
      if (options[value.optionId!]) {
        richText.add(options[value.optionId!] + '  ', { fontColor: '6F767E' });
      }
      richText.add(value.url, { fontColor: '0000FF' });
      richText.add('\r\n');
      richText.add('\r\n');
    });
  } else {
    toArray(response.noteImage).forEach((value) => {
      richText.add(value.url, { fontColor: '0000FF' });
      richText.add('\r\n');
    });
  }

  if (richText.text()) {
    return richText;
  } else {
    return undefined;
  }
};

const addSingleResponseToSheet = (
  sheet: xlsxPopulate.Sheet,
  responses: Array<{ response: ResponseEntity; question: QuestionEntity }>
) => {
  // Set up the headers with styles
  sheet
    .column('A')
    .width(30)
    .style('wrapText', true)
    .style('verticalAlignment', 'center');
  sheet
    .column('B')
    .width(40)
    .style('wrapText', true)
    .style('verticalAlignment', 'center');
  sheet
    .column('C')
    .width(50)
    .style('wrapText', true)
    .style('verticalAlignment', 'center');

  sheet
    .cell('A1')
    .value('Question')
    .style('bold', true)
    .style('verticalAlignment', 'center');
  sheet
    .cell('B1')
    .value('Response')
    .style('bold', true)
    .style('verticalAlignment', 'center');
  sheet
    .cell('C1')
    .value('Added Notes')
    .style('bold', true)
    .style('verticalAlignment', 'center');

  // Populate the data with styling considerations
  responses.forEach((entry, index) => {
    const rowIndex = index + 2; // Starting from row 2
    const question = entry?.question?.label;
    const responseValue = getResponseValue(
      entry?.response,
      { [entry?.question?.eid]: entry?.question },
      {}
    );
    const responseNote = getResponseNote(entry?.response, {
      [entry?.question?.eid]: entry?.question,
    });

    sheet.cell(`A${rowIndex}`).value(question);
    sheet.cell(`B${rowIndex}`).value(responseValue).style('wrapText', true);

    if (responseNote) {
      sheet.cell(`C${rowIndex}`).value(responseNote).style('wrapText', true);
    } else {
      sheet.cell(`C${rowIndex}`).value('-').style('wrapText', true);
    }
  });
};

const addDataToSheet = (
  sheetArg: xlsxPopulate.Sheet,
  questions: QuestionEntity[],
  responseData: FormExportEntity[], // TODO
  userList: UserListEntity[],
  cellKeys: Record<string, string>
) => {
  const _questions = questions.reduce((acc, currentValue) => {
    acc[currentValue.eid] = currentValue;
    return acc;
  }, {} as Record<string, QuestionEntity>);

  const userData = userList.reduce((acc, currentValue) => {
    acc[currentValue.name] = currentValue;
    return acc;
  }, {} as Record<string, UserListEntity>);

  responseData?.map((_da, rowIndex) => {
    const row = sheetArg.row(rowIndex + 2);

    row
      .cell('A')
      .value(moment(_da?.completedAt)?.format('YYYY-MM-DD, hh:mm:ss a'));

    row.cell('B').value(_da?.createdBy?.name);

    row.cell('C').value(_da?.createdBy?.locations?.[0]?.name || '-');

    _da?.response?.map((response) => {
      if (cellKeys[response.qid]) {
        row
          .cell(cellKeys[response.qid])
          .value(getResponseValue(response, _questions, userData));

        const responseNote = getResponseNote(response, _questions);

        if (responseNote) {
          row
            .cell(nextColumnName(cellKeys[response.qid]))
            .value(responseNote)
            .column()
            .hidden(false);
        }

        // sheetArg
        //   .cell(cellKeys[response.qid] + (rowIndex + 2))
        //   .value(getResponseValue(response, _questions, userData));
        // sheetArg
        //   .cell(nextColumnName(cellKeys[response.qid]) + (rowIndex + 2))
        //   .value(response.noteText);
      }
    });
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BaseFormResponseSheet = async (
  questions: QuestionEntity[],
  responseData: FormExportEntity[],
  context?: {
    title: string;
    userList: UserListEntity[];
  }
): Promise<Blob | undefined> => {
  if (questions && questions?.length > 0) {
    let workbook = await xlsxPopulate.fromBlankAsync();
    let sheet = workbook.sheet(0);
    const cellKeys = addHeadersToSheet(sheet, questions);
    addDataToSheet(
      sheet,
      questions,
      responseData,
      context?.userList || [],
      cellKeys
    );
    return (await workbook.outputAsync()) as never;
  }
};

const EXPORT_DATE_FORMAT = 'DD-MM-YYYY-hh-mm-ss-A';

export const GenerateFormResponseSheet = async (
  questions: QuestionEntity[],
  responseData: FormExportEntity[],
  context?: {
    title: string;
    userList: UserListEntity[];
  }
): Promise<void> => {
  if (responseData && responseData?.length > 0) {
    const res = await BaseFormResponseSheet(questions, responseData, context);

    if (res) {
      saveAs(
        res,
        `${context?.title}-${moment().format(EXPORT_DATE_FORMAT)}.xlsx`
      );
    }
  }
};

interface FormEntity {
  eid: string;
  title: string;
  questions: QuestionEntity[];
}

export const downloadMultiFormResponse = async (
  forms: FormEntity[] = [],
  responses: Record<string, FormExportEntity[]>,
  userList?: UserListEntity[]
): Promise<void> => {
  const zip = new JSZip();

  const FOLDER_NAME = moment().format(EXPORT_DATE_FORMAT);

  const exportZip = zip.folder(FOLDER_NAME);

  for (const form of forms) {
    const res = await BaseFormResponseSheet(
      form.questions,
      responses[form.eid],
      {
        title: form.title,
        userList: userList || [],
      }
    );

    if (res && exportZip) {
      exportZip.file(
        `${form.title}-${moment().format('DD-MM-YYYY-hh-mm-ss-A')}.xlsx`,
        res
      );
    }
  }

  await zip.generateAsync({ type: 'blob' }).then(function (content) {
    saveAs(content, FOLDER_NAME);
  });
};

export const GenerateSingleUserResponseSheet = async (
  responses: Array<{ response: ResponseEntity; question: QuestionEntity }>,
  context?: {
    title: string;
  }
): Promise<void> => {
  if (responses && responses?.length > 0) {
    let workbook = await xlsxPopulate.fromBlankAsync();
    let sheet = workbook.sheet(0);

    addSingleResponseToSheet(sheet, responses);

    // Output the workbook
    return workbook.outputAsync().then((res: any) => {
      saveAs(
        res,
        `${context?.title}-${moment().format('DD-MM-YYYY-hh-mm-ss-A')}.xlsx`
      );
    });
  }
};

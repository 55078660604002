import React, { FC } from 'react';
import { Grid } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { useHistory } from 'react-router-dom';

import LocationProgressItem from './LocationProgressItem';
import { LauncherLocation } from '../dashboard.graphql';
import ProgressListLoader from './ProgressListLoader';
import { LauncherLocationDetail } from 'pages/launcher/details/types/location-launch-details.types';
import { AmplitudeEvent, deployEvent } from '../../../../shared';

interface IProps {
  isLoading?: boolean;
  locations: LauncherLocationDetail[];
  isEmpty?: boolean;
}

const ProgressLists: FC<IProps> = ({ locations = [], isLoading, isEmpty }) => {
  const history = useHistory();

  return (
    <Grid
      templateColumns={{
        base: 'repeat(2, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(3, 1fr)',
        '2xl': 'repeat(4, 1fr)',
      }}
      gap={4}
    >
      {/*// @ts-ignore */}
      <AnimatePresence mode='popLayout'>
        <ProgressListLoader isLoading={isLoading} isEmpty={isEmpty}>
          {locations.map((location) => (
            <LocationProgressItem
              key={location.locationId}
              data={location}
              onClick={() => {
                deployEvent(AmplitudeEvent.LAUNCHER_LOCATION_DETAILS);
                history.push(`/launcher/detail/${location.launchId}`);
              }}
            />
          ))}
        </ProgressListLoader>
      </AnimatePresence>
    </Grid>
  );
};

export default ProgressLists;

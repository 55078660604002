// @ts-ignore
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

import { userObj } from 'sop-commons';

import { ADMIN, SUPER_ADMIN } from '../../utils/userRoles';
import TableActionMenu, {
  IActionMenuData,
} from '../../ui-components/TableActionMenu';
import { HandbookData } from './handbook.types';
import { CardModal, DeckModal } from '../../modal';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  width: 30px;
  height: 30px;
  top: 9px;
  right: 14px;
  z-index: 99;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  item: DeckModal | CardModal;
  onClick?: (name: string, item: DeckModal | CardModal) => void;
  menuData?: Array<IActionMenuData>;
}

function HandbookCardAction({
  item,
  onClick,
  menuData,
}: IProps): React.ReactElement {
  const authRole = useReactiveVar(userObj)?.authRole;

  // if (![SUPER_ADMIN, ADMIN].includes(authRole)) {
  //   return null;
  // }

  return (
    <Wrapper id={item.eid}>
      <TableActionMenu
        menuData={menuData}
        arrowSize={10}
        closeOnBlur={true}
        offset={[0, 5]}
        clickedItem={(_, clickedItem) => onClick?.(clickedItem, item)}
      >
        <div style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon
            icon={faEllipsis as IconProp}
            style={{
              fontSize: '20px',
              padding: '2px 3px',
              border: '1px solid transparent',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              color: 'white',
              borderRadius: '50%',
            }}
          />
        </div>
      </TableActionMenu>
    </Wrapper>
  );
}

export default HandbookCardAction;

import React from 'react';
import { membersCompare } from './membersCompare';
import { ICompareVisibilityType } from './visibilityHelper';

const compareData = (
  PData: string[],
  CData: string[]
): ICompareVisibilityType => {
  let visibilityType: ICompareVisibilityType = 'parentChildNoIntersection';
  if (PData.length === CData.length) {
    console.log('both length equal, proceeding');
    let every = PData.every((p) => CData.includes(p));
    let common = PData.some((p) => CData.includes(p));
    if (common) {
      visibilityType = 'parentChildSameLengthDifferentVisibility';
    }
    if (every) {
      visibilityType = 'parentChildSameVisibleSameLength';
    }
  } else {
    console.log('both length not equal, proceeding');
    if (PData.length > CData.length) {
      let isCIncludedInP = PData.some((p) => CData.includes(p));
      if (isCIncludedInP) {
        console.log('P has more visibility and C is inside P only');
        visibilityType = 'parentMoreVisibleThanChild';
      }
    } else {
      let isPIncludedInC = CData.some((c) => PData.includes(c));
      if (isPIncludedInC) {
        console.log('C has more visibility and P is inside C only');
        visibilityType = 'parentLessVisibleThanChild';
      }
    }
  }
  return visibilityType;
};

export const parentNoConditionChildNoCondition = (
  parent: any,
  child: any
): ICompareVisibilityType => {
  console.log({ PARENT_NO_CHILD_NO: { parent, child } });
  let flag: ICompareVisibilityType = 'parentChildNoIntersection';

  if (parent?.locations?.length > 0 && child?.locations?.length > 0) {
    flag = compareData(parent?.locations, child?.locations);
  } else if (parent?.jobs?.length > 0 && child?.jobs?.length > 0) {
    flag = compareData(parent?.locations, child?.locations);
  } else if (parent?.locations?.length > 0 && child?.jobs?.length > 0) {
    flag = compareData(parent?.locations, child?.locations);
  } else if (parent?.jobs?.length > 0 && child?.locations?.length > 0) {
    flag = compareData(parent?.locations, child?.locations);
  }

  console.log('initial flag : ', flag);
  let finalFlag = membersCompare(
    flag,
    parent?.members || [],
    child?.members || []
  );
  console.log({ PARENT_NO_CHILD_NO_FLAG: finalFlag });
  return finalFlag;
};

import { Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPowerUpModalTypes } from 'pages/Training/PowerUpModal/power-up.types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { ActionMenu } from 'ui-components';
import { ActionMenuData } from './editTraining.data';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

interface IProps {
  setEditCardData: React.Dispatch<any>;
  setSelectedCardType: React.Dispatch<
    React.SetStateAction<IPowerUpModalTypes['types']>
  >;
  setIsMilestoneModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMilestoneType: React.Dispatch<React.SetStateAction<string>>;
  setEditMilestoneData: React.Dispatch<React.SetStateAction<never[]>>;
  setActionCardModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCardModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setShowKnowledgeBaseDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditTrainingActionContainer: FC<IProps> = ({
  setEditCardData,
  setSelectedCardType,
  setIsMilestoneModalOpen,
  setMilestoneType,
  setEditMilestoneData,
  setActionCardModalOpened,
  setIsCardModalOpened,
  setShowKnowledgeBaseDrawer,
}) => {
  const { t } = useTranslation(['training', 'common']);
  const clickedItemHandler = (clickedValue: string) => {
    setEditCardData([]);
    if (clickedValue === 'add-milestone') {
      setSelectedCardType('milestone');
      setIsMilestoneModalOpen(true);
      setMilestoneType('actionMenu');
      setEditMilestoneData([]);
    } else if (clickedValue === 'create-new-card') {
      setActionCardModalOpened(true);
    } else if (clickedValue === 'create-quiz') {
      setSelectedCardType('quiz');
      setIsCardModalOpened(true);
    } else if (clickedValue === 'add-new-chapter') {
      setShowKnowledgeBaseDrawer(true);
    } else if (clickedValue === 'add-action') {
      setSelectedCardType('action');
      setActionCardModalOpened(true);
    } else if (clickedValue === 'add-video') {
      setSelectedCardType('video');
      setActionCardModalOpened(true);
    } else if (clickedValue === 'add-form') {
      setSelectedCardType('form');
      setActionCardModalOpened(true);
    }
  };
  return (
    <Flex id='edit-training-action-container' align='center'>
      <div style={{ flex: '1 1 auto' }}>
        <TitleHeader
          title={t('training:tp_name')}
          desc={t('training:sub_text')}
          isRequired
        />
      </div>
      <div>
        <ActionMenu
          menuData={ActionMenuData(t)}
          arrowSize={10}
          closeOnBlur={true}
          offset={[5, 5]}
          clickedItem={(_, clickedItem) => {
            return clickedItemHandler(clickedItem);
          }}
        >
          <Flex
            align='center'
            p='10px 24px'
            style={{
              border: '1px solid #2a85ff',
              marginRight: '1rem',
              borderRadius: '7px',
              cursor: 'pointer',
            }}
          >
            <span
              style={{
                color: '#2a85ff',
                fontSize: '15px',
                fontWeight: 'bold',
                paddingRight: 12,
              }}
            >
              {t('training:add_action_button')}
            </span>
            <FontAwesomeIcon
              style={{ color: '#2a85ff' }}
              icon={faAngleDown as IconProp}
            />
          </Flex>
        </ActionMenu>
      </div>
    </Flex>
  );
};

EditTrainingActionContainer.displayName =
  'EditTraining/EditTrainingActionContainer';

export default EditTrainingActionContainer;

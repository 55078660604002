import React, { FC } from 'react';
import { Box, Tooltip, TooltipProps } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg';

export interface IInfoTooltip extends Pick<TooltipProps, 'placement'> {
  details: any;
}

const CardDeckInfoTooltip: FC<IInfoTooltip> = ({ details, placement }) => {
  const infoHandler = () => {
    const { t } = useTranslation('card');
    return (
      <Box fontFamily='Inter' fontSize='12px' fontWeight='normal'>
        <>
          {details?.decks && details?.decks?.length > 0 && (
            <Box mb='2px'>
              <Trans
                t={t}
                i18nKey='tooltip_deck'
                values={{
                  value: details?.decks
                    ?.map((deck: any) => deck?.title)
                    ?.join(', '),
                }}
              />
            </Box>
          )}
          {details?.category && (
            <div>
              <Trans
                t={t}
                i18nKey='tooltip_category'
                values={{
                  value: details?.category,
                }}
              />
            </div>
          )}
        </>
      </Box>
    );
  };

  return (
    <Tooltip
      hasArrow
      placement={placement}
      label={infoHandler()}
      backgroundColor='blackAlpha.800'
      padding={'12px'}
      borderRadius='3px'
      color='white'
    >
      <InfoIcon style={{ cursor: 'pointer' }} />
    </Tooltip>
  );
};

export default CardDeckInfoTooltip;

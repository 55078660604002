import { Box, Center, Flex, Text, Textarea } from '@chakra-ui/react';
import { Configuration, OpenAIApi } from 'openai';
import { Card } from 'antd';
import SearchInput from 'atoms/SearchInput';
import React, { useRef, useState } from 'react';
import DashboardContainer from 'sub-components/DashboardContainer';
import Loader from 'ui-components/Loader';
import PrimaryButton from 'atoms/PrimaryButton';
import axios, { AxiosRequestConfig } from 'axios';

const selectOption = {
  model: 'text-davinci-003',
  prompt: '',
  temperature: 0,
  max_tokens: 1000,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
  stream: true,
};

const OpenAICreation = () => {
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetchedResult, setFetchedResult] = useState('');
  const textAreaRef = useRef();

  const fetchData = async () => {
    const data = JSON.stringify({
      model: 'text-davinci-003',
      prompt: input,
      temperature: 0,
      max_tokens: 1000,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      stream: true,
    });
    const config: AxiosRequestConfig = {
      method: 'post',
      url: 'https://api.openai.com/v1/completions',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
      },
      data: data,
      responseType: 'stream',
      onDownloadProgress: (progressEvent) => {
        const response = progressEvent.currentTarget.responseText;
        console.log('RESPONSE : ', response);
        const lines = response
          ?.split('\n')
          .filter((line) => line.trim() !== '');
        console.log('LINES : ', lines);
        const data = lines.map((line) => {
          const message = line.replace(/^data: /, '');
          console.log('MESSAGE : ', message);
          if (message === '[DONE]') {
            return '';
          } else {
            try {
              const parsed = JSON.parse(message);
              console.log('PARSED : ', parsed);
              const area = textAreaRef.current;
              if (area) {
                console.log('area : ', area);
                area.scrollTop = area?.scrollHeight;
              }
              return parsed.choices?.[0].text;
            } catch (error) {
              console.error(
                'Could not JSON parse stream message',
                message,
                error
              );
              return '';
            }
          }
        });
        setFetchedResult(data.join(''));
      },
    };
    await axios(config);
  };

  console.log('FETCHED RESULT : ', fetchedResult);

  return (
    <DashboardContainer>
      <Flex w='full' flexDir='column'>
        <Text as='h1' fontWeight='bold'>
          Create SOPs with Delightbot
        </Text>
        <Box w='full' borderRadius={4} bg='white' h='73vh' p={4}>
          <SearchInput
            value={input}
            onChange={(e) => {
              if (!e?.target?.value) {
                setFetchedResult('');
              }
              setInput(e?.target?.value);
            }}
            hideShortcuts
            placeholder='Enter text to begin with...'
          />
          <Center mt={4}>
            <PrimaryButton
              disabled={loading || !input}
              isLoading={loading}
              title='Search'
              onClick={fetchData}
              width='fit-content'
            />
          </Center>
          <Textarea
            ref={textAreaRef}
            value={fetchedResult}
            style={{ height: '55vh', width: '100%' }}
            overflow='scroll'
            mt={4}
            mb={4}
            fontSize='large'
            isDisabled
            variant='solid'
          />
        </Box>
      </Flex>
    </DashboardContainer>
  );
};

export default OpenAICreation;

import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { MessageButton } from '../../../atoms';
import SingleUserChatModal from '../../../ui-components/SingleUserChatModal';

import ContentHeader from './ContentHeader';
import ContentItem from './ContentItem';

import { ClickEvent } from '../search-modal.types';

import userIcon from '../../../assets/images/userIcon.svg';
import forwardIcon from '../../../assets/images/chevronForward.svg';
import phoneIcon from '../../../assets/images/phoneIcon.svg';
import { BaseSearchModal, RecentSearchModal } from '../../../modal';

const UserChatModal = styled(SingleUserChatModal)`
  min-height: 90vh;
  width: unset;
  left: 0;
  border-radius: 16px;
`;

const HeaderWrapper = styled(ContentHeader)`
  .labelCssUser {
    color: #9a9fa5;
  }
`;

interface IProps {
  data: RecentSearchModal;
  onClick?: (event: ClickEvent, data: BaseSearchModal) => void;
}

const LocationDetails: FC<IProps> = ({ data, onClick }) => {
  const { t } = useTranslation(['common', 'header']);
  const [showChat, setShowChat] = useState(false);
  function viewMember() {
    onClick?.('showMembers', data?.value || data?.meta?.name);
  }

  return (
    <>
      <HeaderWrapper
        icon={data.meta.profilePic}
        title={data?.value || data?.meta?.name || 'N/A'}
        // subtitle={data.meta?.role || '- -'}
        subtitle={t('location')}
        isLocation={true}
      />
      <div>
        <ContentItem
          type={data.type}
          leftIcon={phoneIcon}
          title={data.meta?.phone || '- -'}
        />
        <ContentItem
          type={data.type}
          leftIcon={userIcon}
          rightIcon={forwardIcon}
          title={t('header:view_member')}
          onClick={viewMember}
        />
      </div>

      <MessageButton
        w='full'
        size='lg'
        colorScheme='blue'
        margin='8px 0'
        onClick={() => setShowChat(true)}
      />

      {showChat && (
        <UserChatModal
          onClose={() => setShowChat(false)}
          selectedUser={data.recordId || data.eid}
        />
      )}
    </>
  );
};

export default LocationDetails;

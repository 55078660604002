import React, { FC } from 'react';
import { Flex, List, ListItem } from '@chakra-ui/react';
import { MessageIconButton } from '../../../../../atoms';
import { PerformanceMemberName } from '../../common';
import { LocationOwnerEntity } from '../perfor-detail.graphql';
import { useHasChatAccess } from 'hooks/useChatRestriction';

interface IProps {
  data: LocationOwnerEntity[];
  openChat?: (userId: string) => void;
}

const LocationOwnersList: FC<IProps> = ({ data, openChat }) => {
  const hasChatAccess = useHasChatAccess();
  return (
    <List spacing='20px'>
      {data.map((value) => {
        return (
          <ListItem key={value.eid}>
            <Flex justify='space-between' gap={3}>
              <PerformanceMemberName
                name={value.name}
                role={value.role}
                profilePic={value.profilePic}
                locationName={value?.locations?.[0]?.name}
              />

              <MessageIconButton
                hasChatAccess={hasChatAccess({
                  eid: value?.eid,
                  role: value?.role,
                  authRole: value?.authRole,
                })}
                onClick={() => openChat?.(value.eid)}
              />
            </Flex>
          </ListItem>
        );
      })}
    </List>
  );
};

export default LocationOwnersList;

import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { useConfirm, UseConfirm, BoxHeader } from 'ui-components';
import SupervisorContent from './SupervisorContent';
import React, { useCallback, useRef } from 'react';
import { SelectOption } from 'sub-components/CustomDropdowns';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import DashboardDataProvider from 'sub-components/DashboardContainer/DashboardStore/DashboardData/DashboardDataProvider';

interface IProps {
  title?: string;
  foundUsers?: SelectOption[];
  phaseEid?: string;
  locationId?: string;
}

type SupervisorModal = (props: IProps) => void;

export const useSupervisorModal = (): SupervisorModal => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ title, foundUsers = [], phaseEid, locationId }) => {
    const closeModal = () => {
      confirmRef.current?.destroy();
    };
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>
            <DashboardDataProvider>{children}</DashboardDataProvider>
          </ApolloProvider>
        );
      },
      title: (
        // @ts-ignore
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader
              title={`Edit supervisors for ${title}`}
              color='#CABDFF'
            />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <SupervisorContent
          foundUsers={foundUsers}
          phaseEid={phaseEid}
          locationId={locationId}
          closeModal={closeModal}
        />
      ),
      size: '3xl',
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      footer: null,
    });
  }, []);
};

import * as yup from 'yup';
import { JSONContent } from 'delightree-editor';
import { formatNumber } from '../../../../../../../utils';
import moment from 'moment';

// function checkContent(content: JSONContent[]): boolean {
//   let check = false;
//
//   for (const jsonContent of content) {
//     if (jsonContent.text) {
//       check = true;
//       break;
//     }
//
//     if (jsonContent.content) {
//       check = checkContent(jsonContent.content);
//     }
//   }
//
//   return check;
// }

function countTextLength(json: JSONContent[]): number {
  let totalLength = 0;

  // Helper function to recursively traverse the JSON structure
  function traverse(obj: JSONContent) {
    if (typeof obj === 'object' && obj !== null) {
      // Check if obj has type "text" and add its length to totalLength
      if (obj.type === 'text' && 'text' in obj && obj.text) {
        totalLength += obj.text.length;
      }

      // Recursively traverse content array if present
      if (Array.isArray(obj.content)) {
        obj.content.forEach((item) => traverse(item));
      }

      // Recursively traverse attrs object if present
      if (typeof obj.attrs === 'object') {
        traverse(obj.attrs);
      }

      // Recursively traverse other properties of obj
      for (const key in obj) {
        if (key !== 'type' && key !== 'content' && key !== 'attrs') {
          traverse(obj[key]);
        }
      }
    }
  }

  // Start traversal of each object in the json array
  json.forEach((obj) => traverse(obj));

  return totalLength;
}

const MAX_SUBJECT_LIMIT = 256;
const MAX_COMMENT_LIMIT = 8000;

export const validationSchema = yup.object().shape({
  occurrenceDate: yup
    .date()
    // .max(new Date(), 'Date must be present or in the past')
    .required('Please select date')
    .test('dateValidate', (value, context) => {
      if (moment(value).isAfter()) {
        return context.createError({
          message: 'Date must be present or in the past',
        });
      }
      return true;
    }),
  content: yup
    .object<JSONContent>()
    .test('contentTest', (value: JSONContent, context) => {
      const content = value?.content || [];

      const count = countTextLength(content);

      if (!count) {
        return context.createError({
          message: 'Please enter conversation notes',
        });
      }

      const subjCount = countTextLength([content?.[0]]);
      if (!subjCount) {
        return context.createError({
          message: 'Please enter conversation subject',
        });
      }

      if (subjCount > MAX_SUBJECT_LIMIT) {
        return context.createError({
          message: `This subject exceeds the ${formatNumber(
            MAX_SUBJECT_LIMIT
          )}-character limit by ${subjCount - MAX_SUBJECT_LIMIT} characters.`,
        });
      }

      const contentCount = count - subjCount;

      if (contentCount > MAX_COMMENT_LIMIT) {
        return context.createError({
          message: `This conversation exceeds the ${formatNumber(
            MAX_COMMENT_LIMIT
          )}-character limit by ${
            contentCount - MAX_COMMENT_LIMIT
          } characters.`,
        });
      }

      return true;
    }),

  category: yup.object().required('Category is required'),
  creator: yup.string(),
});

import { gql, useMutation } from '@apollo/client';
import { Flex, Text, useToast } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'atoms';
import React, { FC } from 'react';
import { getFoldersList } from 'shared/graphql/SharedGraphql';

const BULK_DELETE = gql`
  mutation BulkDeleteContent($contents: [String]) {
    bulkDeleteContent(contents: $contents) {
      succeed
    }
  }
`;

interface IProps {
  selectedData: { eid: string; type: string }[];
  onCloseHandler: (type: 'close' | 'update') => void;
  getChaptersListHandler?: () => void;
}

const BulkDeleteContainer: FC<IProps> = ({
  selectedData,
  getChaptersListHandler,
  onCloseHandler,
}) => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const onFetchFoldersListSuccess = () => {
    getChaptersListHandler?.();
    onCloseHandler('close');
  };

  const { execute: fetchFoldersList, loading: gettingFoldersList } =
    getFoldersList(onFetchFoldersListSuccess);

  const [bulkDelete, { loading }] = useMutation(BULK_DELETE, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Item(s) deleted successfully',
      });
      fetchFoldersList();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Item(s) could not be deleted',
      });
      onCloseHandler('close');
    },
  });

  const deleteHandler = () => {
    let selectedItemIds = selectedData?.map((data) => data?.eid);
    if (selectedItemIds?.length > 0) {
      bulkDelete({
        variables: {
          contents: selectedItemIds,
        },
      });
    }
  };

  return (
    <Flex gap={4} flexDir='column'>
      <Flex
        align='center'
        gap={2}
        bg='#B1E5FC66'
        p='16px 8px'
        borderRadius='10px'
      >
        <FontAwesomeIcon icon={faTrashCan as IconProp} color='#2A85FF' />
        <Text>
          Deleting items will delete all chapters, subfolders and chapters
          inside it
        </Text>
      </Flex>
      <PrimaryButton
        title='Delete anyway'
        colorScheme='red'
        variant='solid'
        disabled={loading || gettingFoldersList}
        isLoading={loading || gettingFoldersList}
        onClick={deleteHandler}
      />
    </Flex>
  );
};

export default BulkDeleteContainer;

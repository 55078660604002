import { gql } from '@apollo/client';
import { Address, LocationMember, LocationStatus, LocationType } from 'types';
import { OtherFieldsEntity } from '../AddLocation/add-location.graphql';
import { IEntityTypes } from '../AddLocation/static-data';

export interface LocationResponse {
  userById: {
    eid: string;
    isRoot: boolean;
    launchId?: string;

    // Basic Details
    locationType: LocationType;
    name: string;
    locationStatus: LocationStatus;
    locationEmail: string[];
    locationPhone: string[];
    members: LocationMember[];

    // Login Details
    username: string;

    // Address
    address: Address;
    timezone: string;

    // Entity details
    entityName: string;
    entityType: IEntityTypes;
    stateOfFormation: string;
    countryOfFormation: string;
    taxPayerId: string;

    // Other details
    otherFields: OtherFieldsEntity[];
  };
}

export const LOCATION_DATA_QUERY = gql`
  query LocationById($eid: String!) {
    userById(eid: $eid) {
      eid
      isRoot
      launchId

      locationType
      name
      locationStatus
      locationEmail
      locationPhone
      members

      username

      address {
        address
        city
        state
        zipCode
      }
      timezone

      entityName
      entityType
      stateOfFormation
      countryOfFormation
      taxPayerId

      otherFields {
        fieldId
        value
        options
        files {
          url
          type
          name
          mimetype
          fileSize
        }
      }
    }
  }
`;

import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { LocationState } from 'history';
import { useTranslation } from 'react-i18next';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import { BulkMoveContainer } from './components';
import { CustomCategoryEntity } from './components/BulkMoveContainer';

// import ModalContent from './ModalContent';
// import { LocationPath } from './types';

interface IProps {
  selectedData?: { eid: string; type: string }[];
  selectedFolder?: string;
}

type BulkMove = (props: IProps) => void;

export const useBulkMove = (): BulkMove => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const { folderId, subFolderId } = useRouteMatch<{
    folderId?: string;
    subFolderId?: string;
  }>().params;

  const history = useHistory();

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(
    ({ selectedData, selectedFolder } = {}) => {
      //   const pushTo = (location: LocationPath, state?: LocationState) => {
      //     history.push(location, state);
      //     confirmRef.current?.destroy();
      //   };

      const onCloseHandler = (data?: CustomCategoryEntity) => {
        confirmRef.current?.destroy();
        if (data) {
          if (data?.parentFolderId) {
            history.push(`/folders/${data?.parentFolderId}/${data?.eid}`);
          } else {
            history.push(`/folders/${data?.eid}`);
          }
        }
      };

      const typeCheck = () => {
        if (subFolderId) {
          return 'subFolder';
        } else if (folderId) {
          let isFolderPresent = selectedData?.some(
            (select) => select?.type === 'folder'
          );
          let isChapterPresent = selectedData?.some(
            (select) => select?.type === 'chapter'
          );
          if (isFolderPresent && isChapterPresent) {
            return 'folder';
          } else if (isFolderPresent) {
            return 'folder';
          } else if (isChapterPresent) {
            return 'subFolder';
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      };

      if (confirmRef.current) {
        confirmRef.current?.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        size: 'lg',
        title: (
          <Flex gap='12px' mx={8} my={6}>
            <Box flex={1}>
              <BoxHeader
                title={`Move ${selectedData?.length} ${
                  selectedData?.length === 1 ? 'item' : 'items'
                } to another folder`}
                color='#B5E4CA'
              />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <BulkMoveContainer
            selectedData={selectedData || []}
            selectedFolder={selectedFolder}
            onCloseHandler={onCloseHandler}
            type={typeCheck()}
          />
        ),
        headerProps: {
          padding: 0,
        },
        bodyProps: {
          padding: 0,
        },
        contentProps: {
          borderRadius: '16px',
        },
        isCentered: false,
        footer: null,
      });
    },
    [folderId, subFolderId]
  );
};

import React, { FC } from 'react';
import { ChangeLogTableWrapper } from './ChangeLogTable.styles';
import {
  IChangeEventEntity,
  IChangeLogData,
  IChangeLogDataDataEntity,
  IChangeLogLocationsEntity,
  IChangeLogTable,
  IChangeLogTableChangesEntity,
} from '../../../training-draft-types/training-draft-types';
import moment from 'moment';
import flagIcon from '../../../../../assets/images/flagIcon.png';
import locationIcon from '../../../../../assets/images/locationDefault.png';
import { Tooltip } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { getImage } from '../../../../../utils';

type IChangeLogTableEntity = {
  data: IChangeLogDataDataEntity[];
};

const ChangeLogTable = ({ data }: IChangeLogTableEntity) => {
  const { t } = useTranslation('training');
  const { t: tc } = useTranslation('common');
  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className='width-20'>{t('change_heading')}</th>
          <th className='width-50'>{tc('update')}</th>
          <th className='width-30'>{t('changed_by')}</th>
        </tr>
      </thead>
    );
  };

  const getOrderChangedImgHandler = (change: IChangeLogTableChangesEntity) => {
    if (change?.changeElement === 'card' || change?.changeElement === 'deck') {
      if (change?.meta?.thumbnail) {
        return (
          <img src={change?.meta?.thumbnail} style={{ borderRadius: '5px' }} />
        );
      } else {
        return (
          <img
            src={getImage(undefined, change?.meta?.name)}
            style={{ borderRadius: '5px' }}
          />
        );
      }
    } else {
      return (
        <img
          src={flagIcon}
          style={{
            borderRadius: '5px',
            backgroundColor: '#83bf6e',
            padding: '7px',
          }}
        />
      );
    }
  };

  const getMoreLocationStrings = (locations: IChangeLogLocationsEntity[]) => {
    let strArr: string[] = [];
    locations?.map((location, index) => {
      if (index > 1) {
        strArr.push(location?.name);
      }
    });
    return strArr.join(', ');
  };

  const getMoreLocations = (
    locations: IChangeLogLocationsEntity[],
    type: 'add' | 'remove'
  ) => {
    let count = 0;
    locations?.map((location, index) => {
      if (index > 1) {
        count++;
      }
    });
    return (
      <Tooltip hasArrow label={getMoreLocationStrings(locations)}>
        <div
          className={
            type === 'add'
              ? 'status new-status cursor-pointer'
              : 'status old-status cursor-pointer'
          }
        >
          <span
            className={
              type === 'add'
                ? 'status-text new-status-text'
                : 'status-text old-status-text'
            }
          >
            {t('count_more', {
              value: count,
            })}
          </span>
        </div>
      </Tooltip>
    );
  };

  const getMoreMembers = () => {
    return (
      <>
        <span>Jonny, Manager</span>&nbsp;<span>Jonny, Manager</span>&nbsp;
        <span>Jonny, Manager</span>
      </>
    );
  };

  const updatedComparisonsHandler = (change: IChangeLogTableChangesEntity) => {
    switch (change?.changeEvent) {
      // case 'assigneeAdded': {
      // // case 'supervisorAdded': {
      //     return (
      //         <>
      //             <div className='user-status'>
      //                 <img
      //                     src={getImage(change?.meta?.profilePic, change?.meta?.name)}
      //                     style={{ borderRadius: '5px' }}
      //                 />
      //             </div>
      //             <div className='status new-status'>
      //                 <span className='status-text new-status-text'>
      //                     {change?.meta?.name}
      //                 </span>
      //             </div>
      //         </>
      //     )
      // }
      // case 'assigneeRemoved': {
      // // case 'supervisorRemoved': {
      //     return (
      //         <>
      //             <div className='user-status'>
      //                 <img
      //                     src={getImage(change?.meta?.profilePic, change?.meta?.name)}
      //                     style={{ borderRadius: '5px' }}
      //                 />
      //             </div>
      //             <div className='status old-status'>
      //                 <span className='status-text old-status-text'>
      //                     {change?.meta?.name}
      //                 </span>
      //             </div>
      //         </>
      //     )
      // }
      // case 'cardAdded':
      // case 'deckAdded': {
      //     return (
      //         <>
      //             <div className='user-status'>
      //                 <img
      //                     src={getImage(change?.meta?.thumbnail, change?.meta?.name)}
      //                     style={{ borderRadius: '5px' }}
      //                 />
      //             </div>
      //             <div className='status new-status'>
      //                 <span className='status-text new-status-text'>
      //                     {change?.meta?.title}
      //                 </span>
      //             </div>
      //         </>
      //     )
      // }
      // case 'cardRemoved':
      // case 'deckRemoved': {
      //     return (
      //         <>
      //             <div className='user-status'>
      //                 <img
      //                     src={getImage(change?.meta?.thumbnail, change?.meta?.name)}
      //                     style={{ borderRadius: '5px' }}
      //                 />
      //             </div>
      //             <div className='status old-status'>
      //                 <span className='status-text old-status-text'>
      //                     {change?.meta?.title}
      //                 </span>
      //             </div>
      //         </>
      //     )
      // }
      case 'locationAdded': {
        return (
          <>
            {change?.locations?.map((location, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status new-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={locationIcon}
                        style={{
                          borderRadius: '5px',
                          backgroundColor: '#83bf6e',
                          padding: '5px',
                        }}
                      />
                    </div>
                    <span className='status-text new-status-text'>
                      {location?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.locations &&
              change?.locations?.length > 2 &&
              getMoreLocations(change?.locations, 'add')}
          </>
        );
      }
      case 'supervisorAdded': {
        return (
          <>
            {change?.supervisors?.map((supervisor, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status new-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={getImage(supervisor?.profilePic, supervisor?.name)}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text new-status-text'>
                      {supervisor?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.supervisors &&
              change?.supervisors?.length > 2 &&
              getMoreLocations(change?.supervisors, 'add')}
          </>
        );
      }
      case 'assigneeAdded': {
        return (
          <>
            {change?.assignees?.map((assignee, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status new-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={getImage(assignee?.profilePic, assignee?.name)}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text new-status-text'>
                      {assignee?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.assignees &&
              change?.assignees?.length > 2 &&
              getMoreLocations(change?.assignees, 'add')}
          </>
        );
      }
      case 'cardAdded': {
        return (
          <>
            {change?.cards?.map((card, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status new-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={getImage(card?.profilePic, card?.name)}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text new-status-text'>
                      {card?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.cards &&
              change?.cards?.length > 2 &&
              getMoreLocations(change?.cards, 'add')}
          </>
        );
      }
      case 'milestoneAdded': {
        return (
          <>
            {change?.milestones?.map((milestone, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status new-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={flagIcon}
                        style={{
                          borderRadius: '5px',
                          backgroundColor: '#83bf6e',
                          padding: '7px',
                        }}
                      />
                    </div>
                    <span className='status-text new-status-text'>
                      {milestone?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.milestones &&
              change?.milestones?.length > 2 &&
              getMoreLocations(change?.milestones, 'add')}
          </>
        );
      }
      case 'deckAdded': {
        return (
          <>
            {change?.decks?.map((deck, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status new-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={getImage(deck?.profilePic, deck?.name)}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text new-status-text'>
                      {deck?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.decks &&
              change?.decks?.length > 2 &&
              getMoreLocations(change?.decks, 'add')}
          </>
        );
      }
      case 'supervisorRemoved': {
        return (
          <>
            {change?.supervisors?.map((supervisor, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status old-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={getImage(supervisor?.profilePic, supervisor?.name)}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text old-status-text'>
                      {supervisor?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.supervisors &&
              change?.supervisors?.length > 2 &&
              getMoreLocations(change?.supervisors, 'remove')}
          </>
        );
      }
      case 'assigneeRemoved': {
        return (
          <>
            {change?.assignees?.map((assignee, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status old-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={getImage(assignee?.profilePic, assignee?.name)}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text old-status-text'>
                      {assignee?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.assignees &&
              change?.assignees?.length > 2 &&
              getMoreLocations(change?.assignees, 'remove')}
          </>
        );
      }
      case 'cardRemoved': {
        return (
          <>
            {change?.cards?.map((card, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status old-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={getImage(card?.profilePic, card?.name)}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text old-status-text'>
                      {card?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.cards &&
              change?.cards?.length > 2 &&
              getMoreLocations(change?.cards, 'remove')}
          </>
        );
      }
      case 'deckRemoved': {
        return (
          <>
            {change?.decks?.map((deck, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status old-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={getImage(deck?.profilePic, deck?.name)}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text old-status-text'>
                      {deck?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.decks &&
              change?.decks?.length > 2 &&
              getMoreLocations(change?.decks, 'remove')}
          </>
        );
      }
      case 'locationRemoved': {
        return (
          <>
            {change?.locations?.map((location, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status old-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={locationIcon}
                        style={{
                          borderRadius: '5px',
                          backgroundColor: '#ff6a55',
                          padding: '5px',
                        }}
                      />
                    </div>
                    <span className='status-text old-status-text'>
                      {location?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {change?.locations &&
              change?.locations?.length > 2 &&
              getMoreLocations(change?.locations, 'remove')}
          </>
        );
      }
      case 'roleAdded': {
        return (
          <>
            {change?.roles?.map((role) => (
              <>
                <div
                  className='status new-status margin-right-5'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span className='status-text new-status-text'>{role}</span>
                </div>
              </>
            ))}
          </>
        );
      }
      case 'roleRemoved': {
        return (
          <>
            {change?.roles?.map((role) => (
              <>
                <div
                  className='status old-status margin-right-5'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <span className='status-text old-status-text'>{role}</span>
                </div>
              </>
            ))}
          </>
        );
      }
      // case 'milestoneAdded': {
      //     return (
      //         <>
      //             <div className='user-status'>
      //                 <img
      //                     src={flagIcon}
      //                     style={{ borderRadius: '5px', backgroundColor: '#83bf6e', padding: '7px' }}
      //                 />
      //             </div>
      //             <div className='status new-status'>
      //                 <span className='status-text new-status-text'>
      //                     {change?.title?.new}
      //                 </span>
      //             </div>
      //         </>
      //     )
      // }
      case 'milestoneRemoved': {
        return (
          <>
            <div className='user-status'>
              <img
                src={flagIcon}
                style={{
                  borderRadius: '5px',
                  backgroundColor: '#ff6a55',
                  padding: '7px',
                }}
              />
            </div>
            <div className='status old-status'>
              <span className='status-text old-status-text'>
                {change?.title?.new}
              </span>
            </div>
          </>
        );
      }
      case 'mileStoneEdited': {
        return (
          <>
            <div className='status old-status margin-right-10'>
              <span className='status-text old-status-text strike-through'>
                {change?.title?.old}
              </span>
            </div>
            <div className='status new-status'>
              <span className='status-text new-status-text'>
                {change?.title?.new}
              </span>
            </div>
          </>
        );
      }
      case 'titleEdited': {
        return (
          <>
            <div className='status old-status margin-right-10'>
              <span className='status-text old-status-text strike-through'>
                {change?.title?.old}
              </span>
            </div>
            <div className='status new-status'>
              <span className='status-text new-status-text'>
                {change?.title?.new}
              </span>
            </div>
          </>
        );
      }
      case 'iconsUpdated': {
        return (
          <>
            {change?.icon?.old && (
              <div className='status old-status margin-right-10'>
                <span className='status-text old-status-text strike-through'>
                  <img
                    className='icon-update'
                    src={change?.icon?.old ? change?.icon?.old : ''}
                  />
                </span>
              </div>
            )}
            <div className='status new-status'>
              <span className='status-text new-status-text'>
                <img
                  className='icon-update'
                  src={change?.icon?.new ? change?.icon?.new : ''}
                />
              </span>
            </div>
          </>
        );
      }
      case 'orderChanged': {
        return (
          <>
            <div className='user-status'>
              {getOrderChangedImgHandler(change)}
            </div>
            <div className='status new-status'>
              <span className='status-text new-status-text'>
                {change?.meta?.title
                  ? change?.meta?.title
                  : change?.title?.new
                  ? change?.title?.new
                  : 'Milestone'}{' '}
                {change?.meta?.type && (
                  <span style={{ textTransform: 'capitalize' }}>
                    ({change?.meta?.type})
                  </span>
                )}
              </span>
            </div>
          </>
        );
      }
    }
  };

  const getChangeNameHandler = (eventType: IChangeEventEntity) => {
    switch (eventType) {
      case 'assigneeAdded':
        return t('assignee_added_change');
      case 'assigneeRemoved':
        return t('assignee_removed_change');
      case 'cardAdded':
        return t('card_added_change');
      case 'cardRemoved':
        return t('card_removed_change');
      case 'deckAdded':
        return t('deck_added_change');
      case 'deckRemoved':
        return t('deck_removed_change');
      case 'iconsUpdated':
        return t('icon_updated_change');
      case 'mileStoneEdited':
        return t('milestone_edited_change');
      case 'milestoneAdded':
        return t('milestone_added_change');
      case 'milestoneRemoved':
        return t('milestone_removed_change');
      case 'orderChanged':
        return t('order_changed_change');
      case 'supervisorAdded':
        return t('supervisor_added_change');
      case 'supervisorRemoved':
        return t('supervisor_removed_change');
      case 'titleEdited':
        return t('title_edited_change');
      case 'locationAdded':
        return t('location_added_change');
      case 'locationRemoved':
        return t('location_deleted_change');
      case 'roleAdded':
        return t('roles_added_change');
      case 'roleRemoved':
        return t('roles_removed_change');
      default:
        return '';
    }
  };

  const getChangedByHandler = (change: IChangeLogDataDataEntity) => {
    return (
      <>
        <div className='user-status'>
          <img
            src={getImage(
              change?.updatedBy?.profilePic,
              change?.updatedBy?.name
            )}
            style={{ borderRadius: '5px' }}
          />
        </div>
        <div>
          <span className='status-text'>
            <Trans
              t={t}
              i18nKey='changed_by_time'
              values={{
                name: change?.updatedBy?.name,
                time: moment(change?.updatedAt).format('hh:mm A'),
              }}
            />
          </span>
        </div>
      </>
    );
  };

  const getBody = (changeData: IChangeLogDataDataEntity) => {
    return changeData?.changes.map((_change, index) => {
      return (
        <tr key={index + Date.now()}>
          <td>{getChangeNameHandler(_change.changeEvent)}</td>
          <td>
            <div className='status-cell'>
              {updatedComparisonsHandler(_change)}
            </div>
          </td>
          <td>
            <div className='status-cell'>{getChangedByHandler(changeData)}</div>
          </td>
        </tr>
      );
      // return change?.changes?.map(_change => {
      //     // }
      // })
    });
  };

  return (
    <ChangeLogTableWrapper>
      {data?.map((change, index) => {
        return (
          <table key={index}>
            {getTableHeader()}
            {getBody(change)}
          </table>
        );
      })}
    </ChangeLogTableWrapper>
  );
};

export default ChangeLogTable;

import React, { forwardRef, useMemo } from 'react';
import {
  Box,
  Flex,
  InputProps,
  List,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  useBoolean,
} from '@chakra-ui/react';
import { FormInput } from '../../../../atoms';
import { mergeRefs } from '@chakra-ui/react-utils';
import { callAllHandlers } from '@chakra-ui/utils';
import { useReactiveVar } from '@apollo/client';
import { searchRegExp, toArray } from '../../../../utils';
import { AuthRole } from '../../../../authorization';
import { IconImage, RoleAndAuthRole } from '../../../../ui-components';
import { EntityUser } from '../../../../sub-components/tasks/performance/common';
import { UserStatus, UserType } from '../../../../types';
import { usersEntityObj } from 'sub-components/Header';

export interface IEntityUser extends EntityUser {
  status: UserStatus;
  type: UserType;
}

interface IProps
  extends Pick<InputProps, 'value' | 'onChange' | 'onBlur' | 'placeholder'> {
  notShowOwner: IEntityUser | undefined;
  onSelect?: (entity: EntityUser) => void;
  noMemberFoundFn?: () => void;
  emptyFieldFn?: () => void;
}

const OwnerAutocomplete = forwardRef<never, IProps>((props, ref) => {
  const inputRef = React.useRef(null);

  const [isOpen, setIsOpen] = useBoolean();

  const entityUsers = useReactiveVar(
    usersEntityObj
  ) as unknown as IEntityUser[];

  const owners = useMemo(() => {
    return toArray(entityUsers).reduce<IEntityUser[]>((previousValue, user) => {
      if (
        user?.type === 'user' &&
        user?.authRole === AuthRole.LOCATION_OWNER &&
        user?.status === 'active'
      ) {
        previousValue.push(user);
      }
      if (props?.notShowOwner) {
        return (
          previousValue?.filter(
            (val) => val?.eid !== props?.notShowOwner?.eid
          ) || []
        );
      }
      return previousValue;
    }, []);
  }, [entityUsers, props?.notShowOwner]);

  const filtered = useMemo(() => {
    const reg = searchRegExp(props.value?.toString(), 'gi');

    return owners.filter((it) => {
      return it.name?.match(reg);
      // return (
      //   it.name?.match(reg) ||
      //   it.authRole?.match(reg) ||
      //   it.role?.match(reg) ||
      //   it?.locations?.[0]?.name?.match(reg)
      // );
    });
  }, [owners, props.value]);

  const _isOpen = !!filtered.length && isOpen;

  return (
    <Popover matchWidth isOpen={_isOpen} initialFocusRef={inputRef}>
      {/*// @ts-ignore */}
      <PopoverAnchor>
        <FormInput
          size='lg'
          autoComplete='off'
          variant='outline'
          ref={mergeRefs(inputRef, ref)}
          onFocus={callAllHandlers(setIsOpen.on as never)}
          onBlur={() => {
            if (filtered?.length === 0) {
              props.noMemberFoundFn?.();
            }
            if (!props.value) {
              props.emptyFieldFn?.();
            }
            setTimeout(
              callAllHandlers(setIsOpen.off as never, props.onBlur),
              100
            );
          }}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
        />
      </PopoverAnchor>

      <PopoverContent>
        <PopoverBody>
          <List maxH='45vh' overflow='auto' px={3}>
            {filtered?.map((owner, index) => {
              return (
                <ListItem
                  key={index}
                  py={2}
                  _notLast={{
                    borderBottom: '1px solid #EFEFEF',
                  }}
                >
                  <Flex
                    p={2}
                    gap={3}
                    cursor='pointer'
                    borderRadius='8px'
                    _hover={{
                      bg: 'gray.200',
                    }}
                    onClick={() => {
                      props.onSelect?.(owner);
                    }}
                  >
                    <IconImage
                      name={owner?.name}
                      thumbnail={owner?.profilePic}
                      boxSize={40}
                      borderRadius='50%'
                      objectFit='cover'
                    />
                    <Flex flexDir='column' justify='center'>
                      <Box fontSize='14px' fontWeight='600' color='#171725'>
                        {owner.name}
                      </Box>
                      <RoleAndAuthRole
                        role={owner.role}
                        authRole={owner.locations?.[0]?.name}
                        disableAuthRoleSplit
                      />
                    </Flex>
                  </Flex>
                </ListItem>
              );
            })}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
});

OwnerAutocomplete.displayName = 'Owner Autocomplete';

export default React.memo(OwnerAutocomplete);

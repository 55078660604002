import React, { ReactNode } from 'react';
import {
  ISelectedLocationData,
  ISelectedMemberData,
  ISelectedRoleData,
} from 'zustandStore/slices/useVisibility';
import {
  IConditionType,
  IDropdownSelectionFormat,
  IExpandAccordion,
  ILocationData,
  IModalType,
  IRoleData,
  ISelectedFirstDropdown,
  IType,
  IUserData,
} from '../set-see-modal-types';

export interface ISetSeeDataContext {
  modalType: IModalType;
  selectedFirstDropdown: ISelectedFirstDropdown;
  selectionType: IType;
  locationsList: ILocationData[];
  rolesList: IRoleData[];
  membersList: IUserData[];
  selectedLocationsList: Array<ISelectedLocationData>;
  selectedRolesList: Array<ISelectedRoleData>;
  selectedMembersList: Array<ISelectedMemberData>;
  conditionType: IConditionType;
  showCondition: boolean;
  dropdownSelectionFormat: IDropdownSelectionFormat; // 1 => First Location, then Role; 2 => First Role, then Location;
  expandAccordion: IExpandAccordion;
  updateModalType: (type: IModalType) => void;
  updateSelectedFirstDropdown: (type: ISelectedFirstDropdown) => void;
  updateSelectionType: (type: IType) => void;
  updateLocationsList: (items: ILocationData[]) => void;
  updateRolesList: (items: IRoleData[]) => void;
  updateMembersList: (items: IUserData[]) => void;
  updateSelectedLocationsList: (items: ISelectedLocationData[]) => void;
  updateSelectedRolesList: (items: ISelectedRoleData[]) => void;
  updateSelectedMembersList: (items: ISelectedMemberData[]) => void;
  updateConditionType: (type: IConditionType) => void;
  updateShowCondition: (value: boolean) => void;
  updateDropDownSelectionFormat: (value: IDropdownSelectionFormat) => void;
  updateExpandAccordion: (value: IExpandAccordion) => void;
}

const SetSeeDataContext = React.createContext<ISetSeeDataContext>({
  modalType: 'write',
  selectedFirstDropdown: { label: 'Location', value: 'Location' },
  selectionType: 'public',
  locationsList: [],
  rolesList: [],
  membersList: [],
  selectedLocationsList: [],
  selectedRolesList: [],
  selectedMembersList: [],
  conditionType: 'or',
  showCondition: false,
  dropdownSelectionFormat: 1,
  expandAccordion: '0',
  updateModalType: (type: IModalType) => {},
  updateSelectedFirstDropdown: (type: ISelectedFirstDropdown) => {},
  updateSelectionType: (type: IType) => {},
  updateLocationsList: (items: ILocationData[]) => {},
  updateRolesList: (items: IRoleData[]) => {},
  updateMembersList: (items: IUserData[]) => {},
  updateSelectedLocationsList: (items: ISelectedLocationData[]) => {},
  updateSelectedRolesList: (items: ISelectedRoleData[]) => {},
  updateSelectedMembersList: (items: ISelectedMemberData[]) => {},
  updateConditionType: (type: IConditionType) => {},
  updateShowCondition: (value: boolean) => {},
  updateDropDownSelectionFormat: (value: IDropdownSelectionFormat) => {},
  updateExpandAccordion: (value: IExpandAccordion) => {},
});

export default SetSeeDataContext as React.Context<ISetSeeDataContext>;

import React, { ChangeEventHandler, FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import { IRoleData } from '../roles-list.types';

interface HeaderProps {
  roleData: IRoleData;
  jobHeading?: boolean | undefined;
  onBackClick: () => void;
  onClose: () => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const MoveToRoleHeader: FC<HeaderProps> = ({
  roleData,
  jobHeading,
  onBackClick,
  onClose,
  onChange,
}) => {
  const { t } = useTranslation('role');
  return (
    <Box>
      <Flex gap='12px' align='center'>
        <Box cursor='pointer'>
          <FontAwesomeIcon
            icon={faArrowLeft as IconProp}
            fontSize='17px'
            onClick={onBackClick}
          />
        </Box>
        <Box
          flex={1}
          fontWeight='600'
          fontSize='20px'
          lineHeight='32px'
          color='#1a1d1f'
          userSelect='none'
        >
          {jobHeading ? t('moveJobMembers') : t('move_member')}
        </Box>
      </Flex>
    </Box>
  );
};

export default MoveToRoleHeader;

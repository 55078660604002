import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { IHelpQueryListProps } from 'sub-components/LocationLaunchDetail/types/types';
import HelpQueryCard from '../Singleton/HelpQueryCard';

const HelpQueryList: FC<IHelpQueryListProps> = (props) => {
  const { list } = props;
  return (
    <Flex gap={4}>
      {list?.map((_l, index) => (
        <HelpQueryCard key={index} {..._l} />
      ))}
    </Flex>
  );
};

export default HelpQueryList;

import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';
import moment from 'moment';
import { CometChatAvatar, CometChatUserPresence } from '../../Shared';

import { CometChatContext } from '../../../util/CometChatContext';

import {
  modalRowStyle,
  nameColumnStyle,
  avatarStyle,
  nameStyle,
} from './style';

class SeenUserListItem extends React.Component {
  static contextType = CometChatContext;

  constructor(props, context) {
    super(props, context);
  }

  toggleTooltip = (event, flag) => {
    const elem = event.currentTarget;

    if (elem.classList.contains('name')) {
      const scrollWidth = elem.scrollWidth;
      const clientWidth = elem.clientWidth;

      if (scrollWidth <= clientWidth) {
        return false;
      }
    }

    if (flag) {
      elem.setAttribute('title', this.props.member.name);
    } else {
      elem.removeAttribute('title');
    }
  };

  render() {
    let editClassName = '';

    let name = this.props.member.name;
    let timeStamp = this.props.timeStamp;
    if (timeStamp) {
      timeStamp = new Date(timeStamp * 1000).getTime();
    }
    let userPresence = (
      <CometChatUserPresence
        status={this.props.member.status}
        style={{ top: '-41px', left: '25px' }}
      />
    );

    return (
      <div css={modalRowStyle(this.context)} className='content__row'>
        <div
          css={nameColumnStyle(this.context, editClassName)}
          className='userinfo'
        >
          <div
            css={avatarStyle(this.context, editClassName)}
            className='thumbnail'
            onMouseEnter={(event) => this.toggleTooltip(event, true)}
            onMouseLeave={(event) => this.toggleTooltip(event, false)}
          >
            <CometChatAvatar user={this.props.member} />
            {userPresence}
          </div>
          <div
            css={nameStyle(this.context, editClassName)}
            className='name'
            style={{ display: 'flex', flexDirection: 'column' }}
            onMouseEnter={(event) => this.toggleTooltip(event, true)}
            onMouseLeave={(event) => this.toggleTooltip(event, false)}
          >
            {name}
            <div
              style={{
                fontSize: '13px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                letterSpacing: 0,
                textAlign: 'left',
                color: 'rgba(20, 20, 20, 0.6)',
              }}
            >
              {moment(timeStamp).format('DD MMM YYYY hh:mm A')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Specifies the default values for props:
SeenUserListItem.defaultProps = {
  loggedinuser: {},
};

SeenUserListItem.propTypes = {
  loggedinuser: PropTypes.shape(CometChat.User),
};

export { SeenUserListItem };

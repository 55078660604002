import { BaseCardModal, CardModal } from './card-modal';
import { DeckEntity } from '../types';
import { toArray } from '../utils/utils';

export class DeckModal extends BaseCardModal {
  protected _cards: CardModal[] = [];
  constructor(data: DeckEntity) {
    super(data);

    this._cards = toArray(data.cards).map((card) => new CardModal(card));
  }

  get cards() {
    return this._cards;
  }

  get cardLength() {
    return this._cards.length;
  }
}

import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { UseConfirm, useConfirm } from '../../Confirm';
import ChapterContent from './ChapterContent';

interface IProps {
  sopId: string;
  title: string;
  onReadClick: () => Promise<void>;
  canOverride?: boolean;
}

type UseChapterViewProps = (props: IProps) => void;

export const useChapterView = (): UseChapterViewProps => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback(({ sopId, title, onReadClick, canOverride }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }
    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      content: (
        <ChapterContent
          chapterId={sopId}
          title={title}
          onReadClick={onReadClick}
          canOverride={canOverride}
        />
      ),
      contentProps: {
        py: '16px',
        px: '20px',
        maxW: '800px',
      },
      bodyProps: {
        px: 0,
      },
      footer: null,
    });
  }, []);
};

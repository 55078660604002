import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import styled from '@emotion/styled';

import { BoxHeader } from '../../../../../../ui-components';

import { ReactComponent as CardPreviewEmpty } from 'sop-commons/src/card-components/assets/cardPreview.svg';
import { PreviewProvider } from '../../../../../../pages/Training/CreateTrainingPath/Preview/context';
import MobilePreview from './MobilePreview';
import TrainingPreviewContent from './TrainingPreviewContent';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;

  > div {
    border: 1px solid #d2d2d2;
    padding-block: 18px;
    border-radius: 16px;
    width: 100%;
  }
`;

interface IProps {}

const TrainingPreview: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'task']);

  const previewData = useWatch({
    name: 'preview',
  });

  return (
    <Flex flexDir='column' width='300px' pt={1} gap={5}>
      <BoxHeader title={t('common:preview')} color='#CABDFF' />

      <Wrapper>
        {previewData?.eid ? (
          <TrainingPreviewContent eid={previewData?.eid} />
        ) : (
          <Center flexDir='column' gap='8px'>
            <CardPreviewEmpty />
            <Box fontSize='15px' fontWeight='700' color='#303030'>
              {t('task:noTrainingToPreview')}
            </Box>
            <Box
              maxW='200px'
              textAlign='center'
              fontSize='12px'
              fontWeight='400'
              color='#9E9E9E'
            >
              {t('task:noTrainingToPreviewDesc')}
            </Box>
          </Center>
        )}
      </Wrapper>
    </Flex>
  );
};

export default TrainingPreview;

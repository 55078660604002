import React, { FC, useMemo } from 'react';
import { Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';

import { Authorize, AuthRole } from 'authorization';
import { MessageIconButton } from 'atoms';
import { useUserDataSelector } from 'hooks';
import { toArray } from 'utils/utils';
import { UserStatus } from 'types';
import { shallowEqual } from 'utils';

import { LocationsEntity } from '../team.types';

import { ReactComponent as EditIcon } from '../../../../assets/images/editIcon.svg';
import { ReactComponent as SendIcon } from '../../../../assets/images/sendIocn.svg';
import { useHasChatAccess } from 'hooks/useChatRestriction';

interface IProps {
  status: UserStatus;
  role: string;
  eid: string;
  authRole: AuthRole;
  locations?: LocationsEntity[];
  onClick?: (action: 'edit' | 'message' | 'reInvite') => void;
}

const MemberAction: FC<IProps> = ({
  eid,
  role,
  status,
  onClick,
  locations,
  authRole,
}) => {
  const { t } = useTranslation(['common', 'invite']);
  const hasChatAccess = useHasChatAccess();
  const authUser = useUserDataSelector(
    (state) => ({
      eid: state.eid,
      authRole: state.authRole,
      locations: state.locations,
    }),
    shallowEqual
  );

  const authLocations = useMemo(() => {
    return toArray(authUser.locations).map((it) => it.eid);
  }, [authUser.locations]);

  const editDisabled = useMemo(() => {
    if (authUser.authRole === AuthRole.LOCATION_OWNER) {
      return !toArray(locations).some((loc) => {
        return authLocations.includes(loc.eid);
      });
    }
    return false;
  }, [locations, authLocations, authUser.authRole]);

  const resendInvite = useMemo(() => {
    return match([authUser.authRole])
      .with([AuthRole.SUPER_ADMIN], () => true)
      .with([AuthRole.ADMIN], () => authRole !== AuthRole.SUPER_ADMIN)
      .with([AuthRole.LOCATION_OWNER], () => {
        if ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(authRole)) {
          return false;
        }

        return toArray(locations).some((loc) => {
          return authLocations.includes(loc.eid);
        });
      })
      .with([AuthRole.WORKER], () => false)
      .otherwise(() => false);
  }, [authUser.eid, authUser.authRole, locations, authLocations]);

  if (status === 'pending') {
    return (
      <Flex gap='4px'>
        <Flex boxSize='40px' />

        <Authorize permittedFor='user' restrictedRoles={[AuthRole.WORKER]}>
          <Tooltip
            label={t('invite:resendInvite')}
            hasArrow
            borderRadius='4px'
            padding='4px 10px'
          >
            <IconButton
              isDisabled={!resendInvite}
              borderRadius='full'
              aria-label='edit'
              variant='ghost'
              icon={<SendIcon />}
              onClick={!resendInvite ? undefined : () => onClick?.('reInvite')}
            />
          </Tooltip>
        </Authorize>
      </Flex>
    );
  }

  return (
    <Flex gap='4px'>
      <MessageIconButton
        hasChatAccess={hasChatAccess({
          authRole,
          role,
          eid,
        })}
        onClick={() => onClick?.('message')}
      />

      <Authorize permittedFor='user' restrictedRoles={[AuthRole.WORKER]}>
        <Tooltip
          label={t('invite:edit_member')}
          hasArrow
          borderRadius='4px'
          padding='4px 10px'
        >
          <IconButton
            isDisabled={!resendInvite}
            borderRadius='full'
            aria-label='edit'
            variant='ghost'
            icon={<EditIcon />}
            onClick={!resendInvite ? undefined : () => onClick?.('edit')}
          />
        </Tooltip>
      </Authorize>
    </Flex>
  );
};

export default MemberAction;

import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import PerformanceHeader from './PerformanceHeader';
import PerformanceListContainer from './PerformanceListContainer';

interface IProps {}

const TaskPerformance: FC<IProps> = () => {
  const { t } = useTranslation(['task']);

  return (
    <Flex flexDir='column' gap='20px'>
      <PerformanceHeader title={t('task:performanceOverview')} />
      <PerformanceListContainer />
    </Flex>
  );
};

export default TaskPerformance;

import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { NewActivityList } from './activity-helper-new';

interface IProps {
  value: NewActivityList;
}

const DayAgoItem: FC<IProps> = ({ value }) => {
  const { i18n } = useTranslation();
  const dateString = useMemo(() => {
    const now = moment().startOf('day').subtract(1, 'day');
    const date = moment(value.data[0]?.createdAt);
    if (date.isSameOrAfter(now)) {
      return date.calendar().split(' ')[0];
    }
    return date.format('MMMM Do, YYYY');
  }, [value.group, i18n.language]);

  return (
    <Flex mb='16px'>
      <span className='days-ago-text'>{dateString}</span>
      {/* <Box flex={1} height='1px' bg='#EFEFEF' alignSelf='center' /> */}
    </Flex>
  );
};

export default DayAgoItem;

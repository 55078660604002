import React, { FC } from 'react';
import { Progress, ProgressProps } from '@chakra-ui/react';

interface IProgressBar extends ProgressProps {}

const ProgressBar: FC<IProgressBar> = (props) => {
  return <Progress {...props} />;
};

export default ProgressBar;

import React, { FC, useMemo } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import moment from 'moment/moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrophyStar } from '@fortawesome/pro-solid-svg-icons';

import { Trans, useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../../../../ui-components';
import { CardType, TrainingContType } from '../../../../../../../types';
import { AuthRole } from '../../../../../../../authorization';

import { UserEntityData } from '../../../../../../../shared/graphql/shared-types';

export interface ITProgressData {
  eid: string;
  title: string;
  isCompleted: boolean;
  viewedAt?: string;
  lastViewedAt?: string;
  type: TrainingContType;
  cardType?: CardType;
  formId?: string;
  userData?: UserEntityData;
}

interface IProps {
  index: number;
  data: ITProgressData;
  authRole: AuthRole;
}

const TrainingProgressItem: FC<IProps> = ({ data, authRole }) => {
  const { t } = useTranslation(['training']);

  const isForm = data.cardType === CardType.CustomForm;
  const isMilestone = data.type === TrainingContType.MILESTONE;

  const showButton = useMemo(() => {
    return (
      [AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(authRole) &&
      isForm &&
      data.isCompleted
    );
  }, [isForm, data.isCompleted, authRole]);

  const onViewFormResponse = (value: ITProgressData) => {
    window.open(`/forms/response/${value.formId}`, '_blank');
  };

  return (
    <Flex gap={4} align='center'>
      <Center
        boxSize='64px'
        minW='64px'
        borderRadius='8px'
        bg={data.isCompleted ? '#EAFAE4' : '#F4F4F4'}
      >
        <FontAwesomeIcon
          icon={(isMilestone ? faTrophyStar : faAward) as IconProp}
          color={data.isCompleted ? '#83BF6E' : '#979EA6'}
          size='2x'
        />
      </Center>

      <Flex flex={1} flexDir='column' justify='center'>
        <Box fontSize='12px' fontWeight='500' color='#6F767E'>
          {data.isCompleted
            ? moment(data.lastViewedAt).format('DD MMMM YYYY, hh:mm A')
            : 'Not Started'}
        </Box>
        <Box fontSize='14px' fontWeight='600' color='#272B30'>
          {!data.isCompleted ? (
            data?.title
          ) : (
            <Trans
              t={t}
              context={isForm ? 'form' : undefined}
              i18nKey='training:timelineStatus'
              values={{
                userName: data?.userData?.name,
                title: data?.title,
              }}
              components={{
                username: (
                  <span
                    style={{
                      fontSize: '14px',
                    }}
                  />
                ),
                status: (
                  <span
                    style={{
                      color: '#777777',
                      fontSize: '14px',
                      fontWeight: '400',
                    }}
                  />
                ),
                title: (
                  <span
                    style={{
                      color: '#6F767E',
                      fontSize: '14px',
                    }}
                  />
                ),
              }}
            />
          )}
        </Box>
      </Flex>

      <ActionButton
        size='sm'
        width='fit-content'
        variant='outline'
        style={{
          minWidth: 'unset',
          fontSize: '13px',
          fontWeight: '500',
          visibility: showButton ? 'visible' : 'hidden',
        }}
        actionFn={showButton ? () => onViewFormResponse?.(data) : undefined}
      >
        View response
      </ActionButton>
    </Flex>
  );
};

export default TrainingProgressItem;

import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import { BulkDeleteContainer } from './components';

// import ModalContent from './ModalContent';
// import { LocationPath } from './types';

interface IProps {
  name?: string;
  selectedData?: { eid: string; type: string }[];
  getChaptersListHandler?: () => void;
}

type BulkDelete = (props: IProps) => void;

export const useBulkDelete = (): BulkDelete => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  const history = useHistory();

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ name, selectedData, getChaptersListHandler } = {}) => {
    //   const pushTo = (location: LocationPath, state?: LocationState) => {
    //     history.push(location, state);
    //     confirmRef.current?.destroy();
    //   };

    const onCloseHandler = (type: 'close' | 'update') => {
      confirmRef.current?.destroy();
      // if (type === 'update') {
      //   history.push('/folders');
      // }
      //   if (data) {
      //     if (data?.parentFolderId) {
      //       history.push(`/folders/${data?.parentFolderId}/${data?.eid}`);
      //     } else {
      //       history.push(`/folders/${data?.eid}`);
      //     }
      //   }
    };

    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      size: '2xl',
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader
              title={`Delete ${selectedData?.length} ${
                selectedData?.length === 1 ? 'item' : 'items'
              } ${name ? `from ${name}` : ''}`}
              color='#B5E4CA'
            />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <BulkDeleteContainer
          selectedData={selectedData || []}
          getChaptersListHandler={getChaptersListHandler}
          onCloseHandler={onCloseHandler}
        />
      ),
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      isCentered: false,
      footer: null,
    });
  }, []);
};

import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ISessionByIdForMyTasks } from 'pages/TasksDashboard/MyTasks/services/my-tasks-types';
import React, { FC } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { getImage } from 'utils';

interface IProps {
  data: ISessionByIdForMyTasks | undefined;
  userData: {
    name: string;
    profilePic: string;
  };
}

const ProgressBar = styled.div<{ progress: number }>`
  position: relative;
  height: 8px;
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 10px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${(props) => `${props.progress}%`};
    background-color: #2a85ff;
    border-radius: 10px;
  }
`;

const ProfileImage = styled.img<{ progress: number }>`
  position: absolute;
  top: -100%;
  left: ${(props) => `calc(${props.progress}% - 10px)`};
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid white;
  z-index: 1;
`;

const ProgressDetails: FC<IProps> = ({ data, userData }) => {
  return (
    <Flex flexDir='column' gap={6} px={6}>
      <Flex id='my-task-details-progress-details-1' justify='space-between'>
        <GenericColorHeader
          color='#FFD88D'
          title={`${data?.SessionById?.taskItems?.length} ${
            data?.SessionById?.taskItems?.length === 1 ? 'Step' : 'Steps'
          }`}
        />
        <Text
          id='my-task-details-progress-details-text-1'
          fontWeight={500}
          fontSize='15px'
          color='#6F767E'
        >
          {data?.SessionById?.currentUserProgress?.completedPercent?.toFixed(0)}
          % Done
        </Text>
      </Flex>
      <Box id='my-task-details-progress-details-2' position='relative' w='full'>
        <ProfileImage
          src={getImage(userData?.profilePic, userData?.name)}
          progress={
            +(
              data?.SessionById?.currentUserProgress?.completedPercent || 0
            )?.toFixed(0)
          }
        />
        <ProgressBar
          progress={
            +(
              data?.SessionById?.currentUserProgress?.completedPercent || 0
            )?.toFixed(0)
          }
        />
      </Box>
    </Flex>
  );
};

export default ProgressDetails;

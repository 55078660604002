import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ICurrentOn } from 'sub-components/LocationLaunchDetail/types/types';
import CommonLabel from '../../CommonLabel';

const CurrentOn: FC<ICurrentOn> = (props) => {
  const { currentStage, sinceWhen, orientation = 'vertical' } = props;
  return (
    <Flex flexDir='column'>
      {orientation === 'vertical' ? (
        <CommonLabel label='Currently on' fontWeight={500} fontSize='12px' />
      ) : (
        <CommonLabel label='Current task' />
      )}
      <CommonLabel
        label={currentStage || '-'}
        fontWeight={500}
        color='#111315'
        isTruncated
        maxW='200px'
      />
      {orientation === 'vertical' ? (
        <CommonLabel
          label={`since ${sinceWhen}`}
          fontWeight={500}
          fontSize='12px'
        />
      ) : currentStage && sinceWhen ? (
        <CommonLabel label={`since ${sinceWhen}`} color='#A6B1BC' />
      ) : null}
    </Flex>
  );
};

export default CurrentOn;

import React, { FC } from 'react';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import moment from 'moment/moment';
import { getImage } from '../../../../utils';

import { PathNameThumbnail } from '../../../Training/Training/PathName';

interface IProps {
  name: string;
  thumbnail?: string;
  createdAt: number;
}

const GroupName: FC<IProps> = ({ name, thumbnail, createdAt }) => {
  return (
    <Flex align='flex-start' gap='16px' cursor='pointer'>
      <PathNameThumbnail
        thumbnail={getImage(thumbnail, name)}
        title={name}
        index={0}
      />

      <Box flex={1} overflow='hidden' pr={1}>
        <Box pos='relative' maxWidth='100%' width='max-content'>
          <Tooltip label={name} hasArrow borderRadius='4px'>
            <Box fontSize='15px' fontWeight='700' pr={5} isTruncated>
              {name}
            </Box>
          </Tooltip>
        </Box>

        <Box mt={1} fontSize='12px' fontWeight={500} color='#6F767E'>
          Created on: {moment(createdAt).format('DD MMMM')}
        </Box>
      </Box>
    </Flex>
  );
};

export default GroupName;

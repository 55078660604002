import React, { FC } from 'react';
import { Control, useFormState } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { ChatGroupEntity } from '../../../../../types';
import { ActionButton } from '../../../../../ui-components';
import { IFormInput } from '../create-channel.types';
import InviteRemoveMember from '../channel-config/InviteRemoveMember';

interface IProps {
  control: Control<IFormInput>;
  onSaveClick: (key: keyof IFormInput) => Promise<void>;
  owner: ChatGroupEntity['owner'];
  isDefaultChannel?: boolean;
}

const UpdateInviteSetting: FC<IProps> = ({
  control,
  onSaveClick,
  owner,
  isDefaultChannel,
}) => {
  const dirtyFields = useFormState({
    control,
    name: 'inviteUser',
  }).dirtyFields;

  const isDirty = Object.keys(dirtyFields).length > 0;

  return (
    <Flex flexDir='column' gap={4}>
      <InviteRemoveMember
        control={control}
        owner={owner}
        isDefaultChannel={isDefaultChannel}
      />
      {isDirty && (
        <ActionButton
          width='fit-content'
          colorScheme='blue'
          px={8}
          actionFn={() => onSaveClick('inviteUser')}
        >
          Save
        </ActionButton>
      )}
    </Flex>
  );
};

export default UpdateInviteSetting;

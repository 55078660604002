import moment from 'moment';
import { BaseModal } from './base';
import {
  BaseSearchData,
  RecentSearchData,
  SearchData,
  SearchMetaData,
} from '../sub-components/SearchModal/search-modal.types';
import { toArray } from '../utils/utils';

class SearchMeta extends BaseModal {
  protected transData: SearchMetaData['translations'];
  protected _data: Omit<SearchMetaData, 'translations'>;

  constructor(data: SearchMetaData) {
    super();
    this.transData = data.translations;
    this._data = data;
  }

  get title() {
    if (this.transData && this.transData[this.language])
      return this.transData[this.language].title;
    return this._data.title;
  }

  get profilePic() {
    return this._data.profilePic;
  }

  get thumbnail() {
    return this._data.thumbnail;
  }
  get role() {
    return this._data.role;
  }
  get name() {
    return this._data.name;
  }
  get category() {
    return this._data.category;
  }
  get email() {
    return this._data.email;
  }
  get files() {
    return this._data.files;
  }
  get username() {
    return this._data.username;
  }
  get authRole() {
    return this._data.authRole;
  }
  get phone() {
    return this._data.phone;
  }
  get icon() {
    return this._data.icon;
  }
  get sopType() {
    return this._data.sopType;
  }
  get parentName() {
    return this._data.parentName;
  }
  get parentId() {
    return this._data.parentId;
  }
  get categoryId() {
    return this._data.categoryId;
  }

  get lastUpdated() {
    if (this._data.lastUpdated) {
      return moment(this._data.lastUpdated).format('DD MMM, HH:mm A');
    }

    if (this._data.updatedAt) {
      return moment(this._data.updatedAt).format('DD MMM, HH:mm A');
    }

    return undefined;
  }
}

export class BaseSearchModal<
  T extends BaseSearchData = BaseSearchData
> extends BaseModal {
  protected _data: T;
  protected _meta: SearchMeta;

  constructor(props: T) {
    super();

    this._data = props;
    this._meta = new SearchMeta(props.meta);
  }

  get eid() {
    return this._data.eid;
  }

  get type() {
    return this._data.type;
  }

  get path() {
    return this._data.path;
  }

  get meta(): SearchMeta {
    return this._meta;
  }

  get data(): T {
    return this._data;
  }

  get value() {
    // @ts-ignore
    return this._data?.value;
  }
}

export class SearchModal extends BaseSearchModal<SearchData> {
  constructor(data: SearchData) {
    super(data);
  }

  get value(): string {
    return this._data.value;
  }
}

export class SearchDataList {
  protected _data: Array<SearchModal> = [];

  constructor(data: SearchData[] = []) {
    this._data = toArray(data).map((value) => new SearchModal(value));
  }

  get data(): Array<SearchModal> {
    return this._data;
  }
}

export class RecentSearchModal extends BaseSearchModal<RecentSearchData> {
  constructor(props: RecentSearchData) {
    super(props);
  }

  get viewedResult(): string {
    return this._data.viewedResult;
  }

  get recordId(): string {
    return this._data.recordId;
  }
}

export class RecentSearchDataList {
  protected _data: Array<RecentSearchModal> = [];

  constructor(data: RecentSearchData[] = []) {
    this._data = toArray(data)
      .filter((value) => value.meta)
      .map((value) => new RecentSearchModal(value));
  }

  get data(): Array<RecentSearchModal> {
    return this._data;
  }
}

import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import { IPowerUpModalTypes } from 'pages/Training/PowerUpModal/power-up.types';
import React, { FC } from 'react';
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { ISelectedIcon } from '.';
import EditTrainingActionContainer from './EditTrainingActionContainer';
import EditTrainingCountVisibilityContainer from './EditTrainingCountVisibilityContainer';
import EditTrainingDetails from './EditTrainingDetails';
import EditTrainingRightSide from './EditTrainingRightSide';
import EditTrainingSortableList from './EditTrainingSortableList';

interface IProps {
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  onFinish: (values: any) => void;
  setEditCardData: React.Dispatch<any>;
  setSelectedCardType: React.Dispatch<
    React.SetStateAction<IPowerUpModalTypes['types']>
  >;
  setIsMilestoneModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMilestoneType: React.Dispatch<React.SetStateAction<string>>;
  setEditMilestoneData: React.Dispatch<React.SetStateAction<never[]>>;
  setActionCardModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCardModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setShowKnowledgeBaseDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  trainingIcons: any;
  trainingIconsLoading: boolean;
  selectedIconHandler: (icon: any) => void;
  searchedStringHandler: (search: string) => void;
  selectedIcon: ISelectedIcon;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  trainingData: any;
  checkChangesHandler: () => void;
  register: UseFormRegister<FieldValues>;
  setTrainingData: React.Dispatch<any>;
  handleRemove: (id: string) => void;
  setSelectedMilestone: React.Dispatch<any>;
  handleCardEdit: (cardId: any) => void;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPowerupPreviewModal: React.Dispatch<React.SetStateAction<boolean>>;
  createdUser: any;
  thirdStepFinalSelections: IThirdStepData;
  setThirdStepSelections: React.Dispatch<React.SetStateAction<IThirdStepData>>;
  thirdStepSelections: IThirdStepData;
  setThirdStepFinalSelections: React.Dispatch<
    React.SetStateAction<IThirdStepData>
  >;
  setThirdStepData: React.Dispatch<React.SetStateAction<IThirdStepData>>;
  thirdStepData: IThirdStepData;
}

const EditTrainingBody: FC<IProps> = ({
  handleSubmit,
  onFinish,
  setEditCardData,
  setSelectedCardType,
  setIsMilestoneModalOpen,
  setMilestoneType,
  setEditMilestoneData,
  setActionCardModalOpened,
  setIsCardModalOpened,
  setShowKnowledgeBaseDrawer,
  trainingIcons,
  trainingIconsLoading,
  selectedIconHandler,
  searchedStringHandler,
  selectedIcon,
  errors,
  trainingData,
  checkChangesHandler,
  register,
  setTrainingData,
  handleRemove,
  setSelectedMilestone,
  handleCardEdit,
  setShowPreview,
  setOpenPowerupPreviewModal,
  createdUser,
  thirdStepFinalSelections,
  setThirdStepSelections,
  thirdStepSelections,
  setThirdStepFinalSelections,
  setThirdStepData,
  thirdStepData,
}) => {
  return (
    <>
      <form
        id='edit-training-body-form'
        className='invite-user-form-container'
        onSubmit={handleSubmit(onFinish)}
      >
        <div style={{ flex: '1 1 auto' }}>
          <EditTrainingActionContainer
            setEditCardData={setEditCardData}
            setSelectedCardType={setSelectedCardType}
            setIsMilestoneModalOpen={setIsMilestoneModalOpen}
            setMilestoneType={setMilestoneType}
            setEditMilestoneData={setEditMilestoneData}
            setActionCardModalOpened={setActionCardModalOpened}
            setIsCardModalOpened={setIsCardModalOpened}
            setShowKnowledgeBaseDrawer={setShowKnowledgeBaseDrawer}
          />
          <EditTrainingDetails
            trainingIcons={trainingIcons}
            trainingIconsLoading={trainingIconsLoading}
            selectedIconHandler={selectedIconHandler}
            searchedStringHandler={searchedStringHandler}
            selectedIcon={selectedIcon}
            errors={errors}
            trainingData={trainingData}
            checkChangesHandler={checkChangesHandler}
            register={register}
            setTrainingData={setTrainingData}
          />
          <EditTrainingCountVisibilityContainer
            trainingData={trainingData}
            setTrainingData={setTrainingData}
          />
          <EditTrainingSortableList
            trainingData={trainingData}
            setTrainingData={setTrainingData}
            setIsMilestoneModalOpen={setIsMilestoneModalOpen}
            setMilestoneType={setMilestoneType}
            setEditMilestoneData={setEditMilestoneData}
            handleRemove={handleRemove}
            setSelectedMilestone={setSelectedMilestone}
            handleCardEdit={handleCardEdit}
            setShowPreview={setShowPreview}
            setEditCardData={setEditCardData}
            setOpenPowerupPreviewModal={setOpenPowerupPreviewModal}
          />
        </div>
      </form>
      <EditTrainingRightSide
        trainingData={trainingData}
        setTrainingData={setTrainingData}
        createdUser={createdUser}
        thirdStepFinalSelections={thirdStepFinalSelections}
        setThirdStepSelections={setThirdStepSelections}
        thirdStepSelections={thirdStepSelections}
        setThirdStepFinalSelections={setThirdStepFinalSelections}
        setThirdStepData={setThirdStepData}
        thirdStepData={thirdStepData}
      />
    </>
  );
};

EditTrainingBody.displayName = 'EditTraining/EditTrainingBody';

export default EditTrainingBody;

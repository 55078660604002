import dateFormat from 'dateformat';
import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortEnd,
} from 'react-sortable-hoc';

import dragIcon from '../../../assets/images/filled.png';
import './card.scss';
import previewIcon from '../../../assets/images/preview-with-eye.png';
import PrimaryButton from '../../../atoms/PrimaryButton';
import { ReactComponent as EditIcon } from '../../../assets/images/editIcon.svg';
import { ReactComponent as CloseIconDark } from '../../../assets/images/closeIconDark.svg';
import closeIcon from '../../../assets/images/closeIcon.png';
import Image from '../../../ui-components/Image/Image';
import { CardType } from '../../../types';
import CardDeckDetails from './CardDeckDetails';

const DragHandler = SortableHandle(() => (
  <div className='sel-card-left'>
    <div className='drag-icon'>
      <img src={dragIcon} alt='' />
    </div>
  </div>
));

const DragHandlerAdded = SortableHandle(() => (
  <div className='addedCardsCreateDeck-sel-card-left'>
    <img src={dragIcon} alt='' />
  </div>
));

const getValue = (item: any, key: keyof any) => {
  if (item.cardType === CardType.CustomForm) {
    // @ts-ignore
    return item?.form?.[key] || item?.[key];
  }
  return item?.[key];
};

export interface CardProps {
  card: any;
  handleCheck?: (id: string) => void;
  handleRemove?: (id: string) => void;
  fromDeck?: boolean;
  previewHandler?: (data: any) => void;
  isEdit?: boolean;
  fromTraining?: boolean;
  type?: string;
  setCardSelected?: (data: any) => void;
  shouldShowHoverFunctionality?: boolean;
  hoverFunctionalityText?: string;
  hoverClick?: (data: any, type: 'milestone' | 'formResponse') => void;
  hoverEffectFn?: (card: any) => void;
  handleCardEdit?: (id: string) => void;
  handleCardClick?: (card: any) => void;
  cardStyleType?: string;
  toggleSwitch?: (id: string, toggleValue: boolean) => void;
}

const Card: FC<CardProps> = (props) => {
  const {
    card,
    handleCheck,
    handleRemove,
    fromDeck,
    previewHandler,
    isEdit,
    fromTraining,
    type,
    setCardSelected,
    shouldShowHoverFunctionality,
    hoverFunctionalityText,
    hoverClick,
    handleCardClick,
    hoverEffectFn,
    handleCardEdit,
    cardStyleType,
    toggleSwitch,
  } = props;

  const { t } = useTranslation(['common', 'deck']);

  const handleChange = () => {
    handleCheck?.(card?.eid);
  };

  return (
    <>
      {!cardStyleType && (
        <div
          className='card-container'
          onMouseEnter={() => {
            if (shouldShowHoverFunctionality) {
              hoverEffectFn?.(card);
            }
          }}
        >
          {fromDeck && <DragHandler />}
          <CardDeckDetails
            card={card}
            fromTraining={fromTraining}
            type={type}
            fromDeck={fromDeck}
            handleCardEdit={handleCardEdit}
            handleRemove={handleRemove}
            hoverClick={hoverClick}
            handleCardClick={handleCardClick}
            shouldShowHoverFunctionality={shouldShowHoverFunctionality}
            hoverFunctionalityText={hoverFunctionalityText}
            setCardSelected={setCardSelected}
            handleChange={handleChange}
            toggleSwitch={toggleSwitch}
          />
        </div>
      )}
      {cardStyleType === 'addCardsCreateDeck' && (
        <div
          className='addCardsCreateDeck-card-container'
          onMouseEnter={() => {
            if (shouldShowHoverFunctionality) {
              hoverEffectFn?.(card);
            }
          }}
        >
          {fromDeck && (
            <div className='addCardsCreateDeck-sel-card-left'>
              <div className='addCardsCreateDeck-drag-icon'>
                <img src={dragIcon} alt='' />
              </div>
            </div>
          )}
          <div
            className='addCardsCreateDeck-card-right'
            style={{ alignItems: 'center' }}
          >
            <div
              className='addCardsCreateDeck-img-cover'
              onClick={() => {
                if (fromTraining) {
                  let item = card;
                  item = {
                    ...item,
                    type,
                  };
                  setCardSelected?.(item);
                }
              }}
            >
              <Image
                src={getValue(card, 'thumbnail')}
                width={66}
                height={63}
                style={{
                  objectFit: 'cover',
                }}
              />
              <div className='addCardsCreateDeck-type-img-cover' />
            </div>
            <Flex
              className='addCardsCreateDeck-card-info-cover'
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'flex-end' }}
                className='addCardsCreateDeck-card-info-div'
              >
                <div
                  className={
                    fromDeck
                      ? 'addCardsCreateDeck-card-text-elips-preview'
                      : 'addCardsCreateDeck-card-text-elips'
                  }
                >
                  {fromTraining && type && (
                    <p style={{ textTransform: 'capitalize' }}>{type}</p>
                  )}
                  {!fromTraining && <p>{card?.category}</p>}

                  <span className='addCardsCreateDeck-card-title'>
                    {getValue(card, 'title')}
                  </span>
                  {!fromTraining && (
                    <p>
                      {t('last_updated_date', {
                        date: dateFormat(card?.updatedAt, 'dd mmm yyyy'),
                      })}
                    </p>
                  )}
                </div>
                <span
                  className='addCardsCreateDeck-card-title'
                  style={{ marginLeft: '10px' }}
                >
                  {card?.type === 'deck' &&
                    ` (${card?.cards?.length || 0} card${
                      card?.cards?.length < 2 ? '' : 's'
                    })`}
                </span>
              </div>
              {shouldShowHoverFunctionality && (
                <div style={{ marginRight: '6rem' }}>
                  <PrimaryButton
                    size='sm'
                    title={hoverFunctionalityText}
                    variant='outline'
                    onClick={() => hoverClick?.(card, 'milestone')}
                  />
                </div>
              )}
              {!fromDeck && !isEdit && (
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className='addCardsCreateDeck-card-info-actions'
                >
                  <div style={{ marginRight: '15px' }}>
                    <PrimaryButton
                      title={t('deck:add_to_deck')}
                      type='button'
                      variant='outline'
                      onClick={handleChange}
                    />
                  </div>
                  <div>
                    <img
                      src={previewIcon}
                      style={{ cursor: 'pointer' }}
                      onClick={() => previewHandler?.(card)}
                    />
                  </div>
                </div>
              )}
              {!fromDeck && isEdit && <img src={closeIcon} />}
            </Flex>
            {fromDeck && (
              <>
                {type === 'card' && card?.cardType === 'quiz' ? (
                  <div style={{ paddingRight: '5px', cursor: 'pointer' }}>
                    <EditIcon onClick={() => handleCardEdit?.(card?.eid)} />
                  </div>
                ) : (
                  <div
                    style={{
                      paddingRight: '5px',
                      height: '20px',
                      width: '27px',
                    }}
                  />
                )}
                <div style={{ paddingRight: '5px', cursor: 'pointer' }}>
                  <CloseIconDark onClick={() => handleRemove?.(card?.eid)} />
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {cardStyleType === 'addedCardsCreateDeck' && (
        <div
          className='addedCardsCreateDeck-card-container'
          onMouseEnter={() => {
            if (shouldShowHoverFunctionality) {
              hoverEffectFn?.(card);
            }
          }}
        >
          <div
            className='addedCardsCreateDeck-card-right'
            style={{ alignItems: 'center' }}
          >
            {fromDeck && <DragHandlerAdded />}
            <div
              className='addedCardsCreateDeck-img-cover'
              onClick={() => {
                if (fromTraining) {
                  let item = card;
                  item = {
                    ...item,
                    type,
                  };
                  setCardSelected?.(item);
                }
              }}
            >
              <Image
                src={getValue(card, 'thumbnail')}
                width={66}
                height={63}
                style={{
                  objectFit: 'cover',
                }}
              />
              <div className='addedCardsCreateDeck-type-img-cover' />
            </div>
            <Flex
              className='addedCardsCreateDeck-card-info-cover'
              style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Flex
                align='flex-end'
                className='addedCardsCreateDeck-card-info-div'
              >
                <div
                  className={
                    fromDeck
                      ? 'addedCardsCreateDeck-card-text-elips-preview'
                      : 'addedCardsCreateDeck-card-text-elips'
                  }
                >
                  {fromTraining && type && (
                    <p style={{ textTransform: 'capitalize' }}>{type}</p>
                  )}
                  {!fromTraining && <p>{card?.category}</p>}

                  <span className='addedCardsCreateDeck-card-title'>
                    {getValue(card, 'title')}
                  </span>
                  {!fromTraining && (
                    <p className='date'>
                      {t('last_updated_date', {
                        date: dateFormat(card?.updatedAt, 'dd mmm yyyy'),
                      })}
                    </p>
                  )}
                </div>
                <span
                  className='addedCardsCreateDeck-card-title'
                  style={{ marginLeft: '10px' }}
                >
                  {card?.type === 'deck' &&
                    ` (${card?.cards?.length || 0} card${
                      card?.cards?.length < 2 ? '' : 's'
                    })`}
                </span>
              </Flex>
              {/* {shouldShowHoverFunctionality && (
                <div style={{ marginRight: '6rem' }}>
                  <PrimaryButton
                    size='sm'
                    title={hoverFunctionalityText}
                    variant='outline'
                    onClick={() => hoverClick?.(card)}
                  />
                </div>
              )} */}
              {!fromDeck && !isEdit && (
                <Flex
                  align='center'
                  className='addedCardsCreateDeck-card-info-actions'
                >
                  <div style={{ marginRight: '15px' }}>
                    <PrimaryButton
                      title={t('deck:add_to_deck')}
                      type='button'
                      variant='outline'
                      onClick={handleChange}
                    />
                  </div>
                  <div>
                    <img
                      src={previewIcon}
                      style={{ cursor: 'pointer' }}
                      onClick={() => previewHandler?.(card)}
                      alt='preview-icon'
                    />
                  </div>
                </Flex>
              )}
              {/* {!fromDeck && isEdit && <img src={previewIcon} />} */}
              {!fromDeck && isEdit && <img src={closeIcon} />}
            </Flex>
            {fromDeck && (
              <div className='addedCardsCreateDeck-card-actions'>
                {type === 'card' && card?.cardType === 'quiz' ? (
                  <div style={{ marginRight: 12, cursor: 'pointer' }}>
                    <EditIcon onClick={() => handleCardEdit?.(card?.eid)} />
                  </div>
                ) : (
                  <div
                    style={{
                      paddingRight: '5px',
                      height: '20px',
                      width: '27px',
                    }}
                  />
                )}
                <div
                  style={{
                    paddingRight: '5px',
                    cursor: 'pointer',
                    marginRight: 6,
                  }}
                >
                  <img
                    src={previewIcon}
                    style={{ minWidth: '20px', maxWidth: '20px' }}
                    alt='preview-icon'
                    onClick={() => previewHandler?.(card)}
                  />
                </div>
                <div style={{ cursor: 'pointer' }}>
                  <CloseIconDark onClick={() => handleRemove?.(card?.eid)} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const SortableCard = SortableElement<CardProps>((props: CardProps) => (
  <div>
    <Card {...props} />
  </div>
));

interface SortableProps {
  items?: Array<any>;
  extraProps: any;
}

export const SortableCardList = SortableContainer<SortableProps>(
  ({ items, extraProps }: SortableProps) => {
    return (
      <div className='sortable-container'>
        {items?.map((item, index) => {
          return (
            <SortableCard
              key={`key-${item.eid}`}
              index={index}
              card={item}
              {...extraProps}
            />
          );
        })}
      </div>
    );
  }
);

export type { SortEnd };

export const moveArray = <T extends unknown>(
  array: T[],
  from: number,
  to: number
): T[] => {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
};

export default Card;

import { Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

export interface IPhaseListItem {
  title: string;
  desc?: string;
}

const PhaseListItem: FC<IPhaseListItem> = ({ title, desc }) => {
  return (
    <Flex gap={4}>
      <FontAwesomeIcon
        icon={faSquareCheck as IconProp}
        color='#2A85FF'
        size='2x'
      />
      <Flex flexDir='column'>
        <Text
          fontWeight={600}
          fontSize='16px'
          lineHeight='24px !important'
          m={0}
          p={0}
        >
          {title}
        </Text>
        {desc && (
          <Text
            fontWeight={400}
            fontSize='12px'
            color='#6F767E'
            lineHeight='22px !important'
            m={0}
            p={0}
          >
            {desc}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default PhaseListItem;

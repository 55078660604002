import styled from '@emotion/styled';

export const ImageComponentWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;

  .image-div {
    display: flex;
    border: 1px solid #dddddd;
    column-gap: 10px;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
  }

  .image-img {
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
    border-radius: 7px;
    object-fit: cover;
  }

  .image-span {
    text-decoration: underline;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

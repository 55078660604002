// @ts-ignore
import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box, Flex, Switch } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { userObj } from 'sop-commons/src/client/clientFactory';
import Image from '../../ui-components/Image/Image';
import { truncateString } from '../../utils';

interface IProps {
  deckCount?: number;
  cardCount?: number;
  isChecked?: boolean;
  onChange?: (value?: boolean) => void;
}

const HandbookCategory: FC<IProps> = ({
  deckCount,
  cardCount,
  isChecked,
  onChange,
}) => {
  const { t } = useTranslation('handbook');
  const categoryName = useRouteMatch<{ category: string }>().params?.category;
  const categories = useReactiveVar(userObj)?.entity?.category || [];

  const category = useMemo(() => {
    return categories.find((value) => value.name === categoryName);
  }, [categories, categoryName]);

  if (!categoryName) {
    return null;
  }

  return (
    <Flex mb={5} justify={'space-between'}>
      <Flex align='center' gap='15px'>
        <Box style={{ borderRadius: 8, width: 56, height: 56 }}>
          <Image width={56} height={56} src={category?.icon} />
        </Box>
        <Box overflow='hidden'>
          <Box
            fontSize='20px'
            fontWeight='600'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            overflow='hidden'
            color='#272B30'
          >
            {truncateString(categoryName, 70)}
          </Box>
          <Box
            fontSize='14px'
            lineHeight='24px'
            fontWeight='600'
            color='#6F767E'
          >
            {t('card_deck_count', {
              deck: deckCount,
              card: cardCount,
            })}
          </Box>
        </Box>
      </Flex>
      <Flex align='center'>
        <div className='member-active-container'>{t('show_only_deck')}</div>
        <div>
          <Switch
            size='lg'
            isChecked={isChecked}
            onChange={(e) => onChange?.(e?.target?.checked)}
          />
        </div>
      </Flex>
    </Flex>
  );
};

export default HandbookCategory;

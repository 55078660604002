import { useCallback } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { ConfirmConfig, NotAccessibleBase } from 'ui-components';
import { AccessibilityType } from 'types';

import { Messages, MessagesDesc } from '../component';

type AccessibilityConfig = (type: AccessibilityType) => Partial<ConfirmConfig>;

export const useFormAccessibility = (): AccessibilityConfig => {
  return useCallback((type) => {
    return {
      title: (
        <Flex justify='flex-end'>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <NotAccessibleBase
          minHeight='unset'
          paddingBottom='32px'
          type={type}
          titleKey={Messages[type]}
          descKey={MessagesDesc[type]}
        />
      ),

      isCentered: true,
      contentProps: {
        maxWidth: '580px',
        borderRadius: '16px',
      },
      footer: <></>,
    };
  }, []);
};

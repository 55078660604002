import React, { FC, useCallback } from 'react';
import {
  Box,
  Button,
  CSSObject,
  Flex,
  useBoolean,
  useStyles,
} from '@chakra-ui/react';
import {
  chakraComponents,
  MenuListProps,
  OptionProps,
  Size,
  SizeProps,
} from 'chakra-react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import Dropdown, {
  DropDownProps,
  SelectOption,
} from '../../../../../atoms/Dropdown';
import { FormCategory } from '../../../../../types';
import { ActionButton } from '../../../../../ui-components';

import { useCreateFormCategory } from '../../create';

interface CustomOptionProps
  extends Omit<OptionProps<FormCategory, false>, 'children'> {}

const CustomOption: FC<CustomOptionProps> = (props) => {
  const {
    children,
    isFocused,
    isDisabled,
    isSelected,
    selectProps: { size, selectedOptionStyle, chakraStyles },
  } = props;

  const itemStyles = useStyles().item as Record<string, CSSObject>;

  const paddings: SizeProps = {
    // sm: '0.3rem 0.6rem',
    // md: '0.4rem 0.8rem',
    // lg: '0.5rem 1rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
  };

  const paddingBlock: SizeProps = {
    sm: '0.3rem',
    md: '0.4rem',
    lg: '0.5rem',
  };

  const shouldHighlight: boolean =
    selectedOptionStyle === 'color' && isSelected;

  const selectedBg = '#B1E5FC47';

  const initialStyles: CSSObject = {
    ...itemStyles,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    textAlign: 'start',
    fontSize: size,
    paddingInline: paddings[size as Size],
    paddingBlock: 0,
    color: '#1A1D1F',
    bg: isFocused ? itemStyles._focus?.bg : 'transparent',
    ...(shouldHighlight && {
      bg: selectedBg,
      _active: { bg: selectedBg },
    }),
    ...(isDisabled && itemStyles._disabled),
    ...(isDisabled && { _active: {} }),
  };

  const sx: CSSObject = chakraStyles?.option
    ? // @ts-ignore
      chakraStyles.option(initialStyles, props)
    : initialStyles;

  return (
    <chakraComponents.Option
      {...props}
      // @ts-ignore
      innerProps={{ ...props.innerProps, sx }}
    >
      <Flex
        flex={1}
        align='center'
        justify='space-between'
        borderTop='1px solid #EAEBED'
        paddingBlock={paddingBlock[size as Size]}
        gap={4}
      >
        {children}
      </Flex>
    </chakraComponents.Option>
  );
};

type IProps = DropDownProps<SelectOption<never>> & {
  onMoveClick?: () => Promise<void>;
  formMoving?: boolean;
  categories: FormCategory[];
};

const FormCategorySelect: FC<IProps> = ({
  isDisabled,
  value,
  onChange,
  onMoveClick,
  formMoving,
  categories,
  isLoading,
}) => {
  const createFormCategory = useCreateFormCategory();

  const [menuIsOpen, action] = useBoolean();

  const onCreateClick = () => {
    createFormCategory({
      onCategoryCreated: (data) => {
        // @ts-ignore
        onChange?.(data);

        action.on();
      },
    });
  };

  const MenuList = useCallback(
    (menuProps: MenuListProps) => {
      // @ts-ignore
      const selValue: string = menuProps?.getValue()?.[0]?.name;

      return (
        <chakraComponents.MenuList {...menuProps}>
          <Button
            w='full'
            size='md'
            variant='ghost'
            colorScheme='blue'
            fontSize='15px'
            borderRadius='0'
            p='8px 20px'
            height='44px'
            justifyContent='start'
            leftIcon={
              <FontAwesomeIcon icon={faPlus as IconProp} fontSize='14px' />
            }
            onClick={onCreateClick}
          >
            <Box>Create new form category</Box>
          </Button>

          {menuProps.children}

          {selValue && (
            <Flex p={2} position='sticky' bottom='0'>
              <ActionButton
                w='full'
                colorScheme='green'
                variant='solid'
                fontSize='15px'
                actionFn={onMoveClick}
                isLoading={formMoving}
              >
                Move to category “{selValue}”
              </ActionButton>
            </Flex>
          )}
        </chakraComponents.MenuList>
      );
    },
    [onMoveClick, formMoving]
  );

  return (
    <Dropdown
      menuIsOpen={menuIsOpen}
      onMenuOpen={action.on}
      onMenuClose={action.off}
      menuPlacement='auto'
      isLoading={isLoading}
      isDisabled={isDisabled}
      options={categories as never}
      getOptionValue={(option) => option?.eid}
      getOptionLabel={(option) => option?.name}
      size='lg'
      className='dropdown'
      placeholder='Select form category'
      inputStyle={{
        border: 'none',
        borderColor: 'transparent',
        borderRadius: '12px',
        fontSize: '15px',
        outline: 'none',
        // ...inputStyle,
      }}
      selectStyles={{
        container: {
          flex: 2,
          position: 'initial',
          backgroundColor: '#2A85FF',
        },
        singleValue: {
          color: '#FCFCFC',
        },
        dropdownIndicator: {
          color: '#FCFCFC',
        },
        loadingIndicator: {
          color: '#FCFCFC',
        },
        placeholder: {
          color: '#FCFCFC',
        },
      }}
      components={{
        MenuList: MenuList as never,
        Option: CustomOption as never,
      }}
      value={value}
      // @ts-ignore
      onChange={onChange}
      closeMenuOnSelect={false}
    />
  );
};

export default FormCategorySelect;

import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { CommonWrapper } from './common';
import { CommonWrapperProps } from './common/CommonWrapper';

interface IProps extends CommonWrapperProps {}

const TrainingDesktop: FC<IProps> = ({ ...rest }) => {
  return (
    <Box
      width='100%'
      maxW='962px'
      borderRadius='8px'
      p='32px'
      pt='28px'
      bg='#FFFFFF'
      overflow='auto'
      className='training-desktop'
    >
      <Flex
        height='full'
        sx={{
          ' > div': {
            flex: 1,
            width: '100%',
          },
        }}
      >
        <CommonWrapper {...rest} />
      </Flex>
    </Box>
  );
};

export default TrainingDesktop;

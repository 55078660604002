import { Box, Flex, Text as NativeText, Text } from '@chakra-ui/react';
import { Card } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar as NativeBar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip as NativeTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { IChartEntity } from '../FormSummary';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ICheckBoxChart {
  drop: IChartEntity;
  domain: number[];
  ticks: number[];
  colorsArray: string[];
}

const CheckBoxChart: FC<ICheckBoxChart> = ({
  drop,
  domain,
  ticks,
  colorsArray,
}) => {
  const { t } = useTranslation(['form']);
  const getBackgroundColorHandler = () => {
    let _colorsArray: string[] = [];
    drop?.questionResponseData?.map((question, index) => {
      _colorsArray.push(colorsArray[index % 35]);
    });
    console.log(_colorsArray);
    return _colorsArray;
  };
  const dataHorBar = {
    labels: drop?.questionResponseData?.map((data) => data?.name),
    datasets: [
      {
        type: 'bar',
        backgroundColor: getBackgroundColorHandler(),
        borderColor: getBackgroundColorHandler(),
        borderWidth: 0,
        hoverBackgroundColor: getBackgroundColorHandler(),
        hoverBorderColor: getBackgroundColorHandler(),
        data: drop?.questionResponseData?.map((data) =>
          (data?.value * 100).toFixed(2)
        ),
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (val) {
            let label = '';
            drop?.questionResponseData?.map((question) => {
              if (question?.name === val?.label) {
                label = 'Count: ' + question?.originalValue;
              }
            });
            return label;
          },
        },
      },
    },
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 25,
          callback: (val) => {
            return val + '%';
          },
        },
      },
    },
  };

  const getWidthHandler = () => {
    let totalLabels = drop?.questionResponseData?.map(
      (data) => data?.name
    )?.length;
    if (totalLabels > 5) {
      const newWidth = 700 + (totalLabels - 5) * 50;
      return newWidth + '' + 'px';
    }
    return '650px';
  };

  const toPercent = (decimal, fixed = 0) => {
    return `${(decimal * 100).toFixed(0)}%`;
  };

  const tickFormatter = (value: string, index: number) => {
    return value;
    // const limit = 5;
    // if (value.length < limit) return value;
    // return `${value.substring(0, limit)}...`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'black', borderRadius: '7px' }}>
          <Flex color='white' padding='0.5rem 1rem' flexDir={'column'}>
            <NativeText as='b'>Name: {label}</NativeText>
            <NativeText as='b'>
              Percentage: {(payload?.[0]?.value * 100).toFixed(2)}%
            </NativeText>
            <NativeText as='b'>
              Count: {payload?.[0]?.payload?.originalValue}
            </NativeText>
          </Flex>
        </div>
      );
    }
    return null;
  };

  return (
    <Card style={{ width: '100%' }}>
      <div>
        <p style={{ fontWeight: '600', fontSize: '18px' }}>
          {drop?.questionName}
        </p>
        {drop?.totalResponseCount && (
          <p>
            {t(
              drop?.totalResponseCount > 1 ? 'form:responses' : 'form:response'
            )}
          </p>
        )}
      </div>
      {/* <Box overflow='auto' height='500px' maxW='100%'>
                <ResponsiveContainer width='100%' height={600}>
                    <BarChart
                        layout='horizontal'
                        width={600} height={500}
                        data={drop?.questionResponseData}
                        margin={{
                            right: 20,
                            bottom: 60,
                            left: 20,
                        }}
                        stackOffset='expand'
                    >
                        <CartesianGrid stroke='#f5f5f5' />
                        <XAxis
                            width={1200}
                            dataKey='name'
                            type='category'
                            axisLine={false}
                            domain={domain}
                            scale='auto'
                            tickLine={false}
                        />
                        <YAxis
                            type='number'
                            interval={0}
                            axisLine={false}
                            tickLine={false}
                            ticks={ticks}
                            tickCount={10}
                            tickFormatter={toPercent}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey='value' barSize={40}>
                            {drop?.questionResponseData?.map((entry, qIndex) => (
                                <Cell key={`cell-${qIndex}`} fill={colorsArray[qIndex % 35]} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Box> */}
      <Box id='vertical-bar-container' h='500px' maxW='650px' overflow='scroll'>
        {' '}
        {/*Control scrolling effect*/}
        <Box id='vertical-bar-container-body' h='100%' w={getWidthHandler()}>
          {' '}
          {/*Control the size of the chart*/}
          <Bar type='bar' data={dataHorBar} options={options} />
        </Box>
      </Box>
    </Card>
  );
};

export default CheckBoxChart;

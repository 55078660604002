import React, { FC, useMemo, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { AuthRole } from 'sop-commons/src/client';

import { useUserDataSelector, useUserEntity } from '../../../../../hooks';
import { shallowEqual } from '../../../../../utils';
import { getIndexColor } from '../../../../../utils/randomColor';
import { toArray } from '../../../../../utils/utils';

import PreviewHeader from '../../../../forms/component/PreviewHeader';
import {
  AxisPercentageDomain,
  getCompleteRate,
  getGraphLabel,
  getGraphTooltipLabel,
  IGraphRange,
  toPercent,
} from '../../common';

import {
  LOCATION_COMPLETION_RATE_QUERY,
  CompletionRateResponse,
  CompletionRateVariable,
} from './location-performance.graphql';
import {
  getToolTipConfig,
  GraphFooter,
  GraphLoader,
  GraphMonthWeekSelect,
  useGraphRangeVariable,
} from '../../common/graph';
import GraphLocationSelection, {
  LocationCellRender,
} from './GraphLocationSelection';

interface IProps {
  fromLocationOwner?: boolean;
}

const LocationCompleteRateGraph: FC<IProps> = ({ fromLocationOwner }) => {
  const { t } = useTranslation(['common', 'task']);

  const locations = useUserEntity(
    (entity) => entity?.locations || [],
    shallowEqual
  );

  const { authRole, userLocations } = useUserDataSelector(
    (state) => ({
      authRole: state.authRole,
      userLocations: state.locations || [],
    }),
    shallowEqual
  );

  const [selectedLocation, setSelectedLocation] = useState<string[]>();

  const [variables, setVariable] = useGraphRangeVariable();

  const locationIds = useMemo(() => {
    return toArray(locations).map((it) => it.eid);
  }, [locations]);

  React.useEffect(() => {
    if (userLocations && !selectedLocation) {
      setSelectedLocation(userLocations.map((it) => it.name));
    }
  }, [userLocations, selectedLocation]);

  const { data, loading } = useQuery<
    CompletionRateResponse,
    CompletionRateVariable
  >(LOCATION_COMPLETION_RATE_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      ...variables,
      locationIds: locationIds,
    },
  });

  const [graphData, locationNames] = useMemo(() => {
    const resDta = data?.TaskSupervisedLocationWiseTrend || {};

    const locRecord = toArray(
      authRole === AuthRole.LOCATION_OWNER ? userLocations : locations
    ).reduce<Record<string, string>>((acc, value) => {
      acc[value.eid] = value.name;

      return acc;
    }, {});

    const _locationNames = Object.values(locRecord);

    const newData = Object.entries(resDta).reduce((acc, [locId, records]) => {
      Object.entries(records).forEach(([weekMonth, value]) => {
        const prev = acc[weekMonth];

        let locationName = locRecord[locId];
        if (locationName) {
          if (prev) {
            acc[weekMonth] = {
              ...prev,
              [locationName]: getCompleteRate(value),
            };
          } else {
            acc[weekMonth] = {
              tooltipLabel: getGraphTooltipLabel(value, variables.type),
              month: getGraphLabel(value, variables.type),
              [locationName]: getCompleteRate(value),
            };
          }
        }
      });

      return acc;
    }, {} as Record<string, object>);

    return [Object.values(newData), _locationNames];
  }, [data, locations, variables]);

  const onGraphTypeChange = (newVariables: IGraphRange) => {
    setVariable((prevState) => {
      return {
        ...prevState,
        ...newVariables,
      };
    });
  };

  const onLocationClick = (value: string) => {
    setSelectedLocation((prevState = []) => {
      if (prevState.includes(value)) {
        return [...prevState.filter((it) => it !== value)];
      }
      return [...prevState, value];
    });
  };

  const filteredLocations = useMemo(() => {
    if (selectedLocation?.length) {
      return selectedLocation;
    }
    return locationNames;
  }, [locationNames, selectedLocation]);

  return (
    <Flex
      flexDir='column'
      borderRadius='10px'
      padding='16px 20px'
      bg='white'
      gap='24px'
    >
      <Flex flexDir='column' gap='12px'>
        <Flex justify='space-between'>
          <PreviewHeader
            title={
              fromLocationOwner
                ? t('task:locationOwnerLocOverallPerformance')
                : t('task:taskCompRateLocWise')
            }
            fontSize='18px'
            color='#B5E4CA'
          />

          <GraphLocationSelection
            locations={locationNames}
            selected={selectedLocation}
            onChange={onLocationClick}
          />
        </Flex>

        <Flex justify='space-between'>
          <LocationCellRender
            selected={selectedLocation}
            onChange={onLocationClick}
          />

          <GraphMonthWeekSelect data={variables} onChange={onGraphTypeChange} />
        </Flex>
      </Flex>

      <GraphLoader isLoading={loading} minHeight={260}>
        <ResponsiveContainer width='100%' height={260}>
          <LineChart data={graphData}>
            <CartesianGrid vertical={false} strokeDasharray='3 3' />
            <XAxis axisLine={false} dataKey='month' />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={toPercent}
              domain={AxisPercentageDomain}
            />

            <Tooltip
              labelFormatter={(label, payload) => {
                return (payload?.[0]?.payload?.tooltipLabel || label).concat(
                  ' (Completion score)'
                );
              }}
              {...getToolTipConfig()}
              // @ts-ignore
              itemSorter={(item) => 0 - item.value}
            />

            {filteredLocations.map((locName, index) => {
              return (
                <Line
                  key={locName}
                  type='linear'
                  dataKey={locName}
                  stroke={getIndexColor(index)}
                  dot={false}
                  unit='%'
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </GraphLoader>

      <GraphFooter data={variables} onChange={onGraphTypeChange} />
    </Flex>
  );
};

export default LocationCompleteRateGraph;

import React, { FC, useContext } from 'react';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Image,
  Popover,
  PopoverTrigger as OrigPopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Text,
  List,
  ListItem,
} from '@chakra-ui/react';
import i18next from 'i18next';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import TranslateIcon from '../../assets/images/language/translate.svg';
import { ILocaleEntity } from 'ui-components/LanguageSetting/language-setting.types';

interface IProps {
  showCircle: boolean;
  highlightSelectedText: boolean;
  onClickHandler: (prefer: ILocaleEntity) => void;
}

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

const LanguageDropdown: FC<IProps> = ({
  showCircle,
  highlightSelectedText,
  onClickHandler,
}) => {
  const languages = useContext(DashboardDataContext)?.preferredLanguages;

  const getPreferredLanguageHandler = () => {
    return languages
      ?.filter((pr) => pr?.languageCode === i18next?.language)
      ?.map((lan) => lan?.nativeName);
  };
  const showTickHandler = (prefer: ILocaleEntity) => {
    return prefer?.languageCode === i18next?.language;
  };

  return languages?.length > 0 ? (
    <Popover>
      <PopoverTrigger>
        <Box display='flex' alignItems='center' gap={2} cursor='pointer'>
          <Box pos='relative'>
            <Image
              src={TranslateIcon}
              minW='25px'
              maxW='25px'
              minH='25px'
              maxH='25px'
            />
            {showCircle && (
              <Box
                pos='absolute'
                top='-4px'
                right='-2px'
                h='10px'
                w='10px'
                borderRadius='full'
                bg='rgba(255, 106, 85, 1)'
              />
            )}
          </Box>
          <Text
            as='b'
            color={highlightSelectedText ? 'rgba(42, 133, 255, 1)' : 'black'}
          >
            {getPreferredLanguageHandler()}
          </Text>
          <ChevronDownIcon fontSize={'lg'} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        rootProps={{
          zIndex: 101,
        }}
      >
        <PopoverArrow />
        <PopoverBody>
          <List spacing={4}>
            {languages?.map((prefer, index) => (
              <ListItem
                key={index}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Box
                  display='flex'
                  alignItems='center'
                  gap={4}
                  cursor='pointer'
                  onClick={() => onClickHandler(prefer)}
                >
                  <Image src={prefer?.thumbnail} boxSize='25px' />
                  <Text
                    fontWeight={showTickHandler(prefer) ? 'bold' : 'normal'}
                  >
                    {prefer?.nativeName}
                  </Text>
                </Box>
                {showTickHandler(prefer) && (
                  <CheckIcon color='rgba(42, 133, 255, 1)' />
                )}
              </ListItem>
            ))}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : null;
};

export default LanguageDropdown;

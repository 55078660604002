import React, { FC, MouseEvent, useEffect } from 'react';
import { Box, Center, Flex, List, ListItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { SelectOption } from '../../../../atoms';
import EmptyState from '../../../../sub-components/EmptyState';

interface ItemProps {
  data: SelectOption;
  value?: SelectOption;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const OptionItem: FC<ItemProps> = ({ data, value, onClick }) => {
  const isSelected = data.value === value?.value;

  return (
    <ListItem
      id={`select-option-${data.value}`}
      display='flex'
      alignItems='center'
      gap='8px'
      justifyContent='space-between'
      cursor='pointer'
      p='10px 13px'
      borderRadius='10px'
      aria-selected={isSelected}
      _hover={{ bg: '#EEEEEE' }}
      _selected={{ bg: '#2A85FF1F' }}
      onClick={onClick}
    >
      <Box fontSize='15px' fontWeight='500' color='#272B30'>
        {data.label}
      </Box>

      <Flex justify='flex-end' minW='32px'>
        {data.value === value?.value && (
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            size='lg'
          />
        )}
      </Flex>
    </ListItem>
  );
};

interface IProps {
  options?: SelectOption[];
  value?: SelectOption;
  onChange?: (option: SelectOption) => void;
}

const LocationList: FC<IProps> = ({ options, value, onChange }) => {
  const { t } = useTranslation(['header', 'task']);

  useEffect(() => {
    const doc = document.getElementById(`select-option-${value?.value}`);

    doc?.scrollIntoView();
  }, []);

  if (!options?.length) {
    return (
      <Center flexDir='column' width='full' p='10px 13px'>
        <EmptyState
          image='Search'
          imageSize='110px'
          title={t('header:result_not_found')}
          description={t('task:oopsNoSearchQueryResult')}
        />
      </Center>
    );
  }

  return (
    <List flex={1} overflow='auto' spacing={1}>
      {options?.map((option) => (
        <OptionItem
          key={option.value}
          data={option}
          value={value}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onChange?.(option);
          }}
        />
      ))}
    </List>
  );
};

export default LocationList;

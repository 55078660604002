import React, { FC } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new';
import {
  AxisPercentageDomain,
  GraphLoader,
  toPercent,
} from '../../../../sub-components/tasks/performance';
import { getToolTipConfig } from '../../../../sub-components/tasks/performance/common/graph';
import { GraphEntity } from './task-performance.graphql';

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <text
      type='category'
      orientation='left'
      width='80'
      height='370'
      x='0'
      y={y}
      stroke='none'
      fill='#2E2C34'
      textAnchor='start'
    >
      <tspan x='0' dy='0.355em' width='80'>
        {payload.value.length > 12
          ? `${payload.value.substring(0, 12)}...`
          : payload.value}
      </tspan>
    </text>
  );
};

interface IProps {
  height: number;
  isLoading: boolean;
  data?: GraphEntity[];
}

const LocationBarGraph: FC<IProps> = ({ height, isLoading, data }) => {
  return (
    <GraphLoader
      isLoading={isLoading}
      minHeight={height}
      isEmpty={!data?.length}
      emptyI18Key='task:empty.locationPerformData'
    >
      <ResponsiveContainer width='100%' height={height}>
        <BarChart
          layout='vertical'
          data={data}
          barSize={18}
          barCategoryGap={16}
          margin={{
            left: 50,
          }}
        >
          <CartesianGrid horizontal={false} strokeDasharray='6 6' />

          <XAxis
            axisLine={false}
            type='number'
            tickFormatter={toPercent}
            domain={AxisPercentageDomain}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            interval={0}
            dataKey='name'
            type='category'
            tick={<CustomizedAxisTick />}
          />
          <Tooltip
            cursor={false}
            {...getToolTipConfig()}
            formatter={(value) => {
              return [`${value}% completed`, [] as any];
            }}
          />
          <Bar
            dataKey='completeRate'
            fill='#5542F6'
            radius={4}
            background={{ fill: '#F1F1F1' }}
          />
        </BarChart>
      </ResponsiveContainer>
    </GraphLoader>
  );
};

export default LocationBarGraph;

import React, { FC } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Control, Controller, useWatch } from 'react-hook-form';

import TitleHeader from '../../../../../sub-components/CardEditor/TitleHeader';

export interface TPhaseInput {
  eid?: string;
  newCategory: string;
  oldCategory: string;

  categories: { eid: string; category: string }[];
}

interface IProps {
  control: Control<TPhaseInput>;
  isDisabled?: boolean;
}

const SelectTaskPhase: FC<IProps> = ({ control, isDisabled }) => {
  const categories = useWatch({
    control: control,
    name: 'categories',
  });

  const taskId = useWatch({
    control: control,
    name: 'eid',
  });

  return (
    <Flex flexDir='column' gap={3}>
      <Box>
        <TitleHeader
          title={'Select Task Phase'}
          desc={'Which phase this task belongs to'}
        />
      </Box>

      <Flex align='center'>
        <Controller
          control={control}
          name='newCategory'
          disabled={!taskId}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isDisabled={field.disabled || isDisabled}
                isInvalid={!!fieldState.error}
              >
                <Flex gap={3} flexWrap='wrap'>
                  {categories?.map((category) => {
                    return (
                      <Button
                        isDisabled={field.disabled || isDisabled}
                        key={category.eid}
                        size='lg'
                        fontSize='15px'
                        variant='outline'
                        alignItems='stretch'
                        colorScheme={
                          field.value === category.eid ? 'blue' : 'gray'
                        }
                        bg={
                          field.value === category.eid ? 'blue.50' : undefined
                        }
                        onClick={() => field.onChange(category.eid)}
                        noOfLines={1}
                      >
                        {category.category}
                      </Button>
                    );
                  })}
                </Flex>

                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Flex>
    </Flex>
  );
};

export default SelectTaskPhase;

import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';

import { CometChatBackdrop } from '../../Shared';
import blueDoubleTick from './resources/message-read.svg';
import greyDoubleTick from './resources/message-delivered.svg';
import greyTick from './resources/message-sent.svg';
import sendingTick from './resources/wait.svg';
import errorTick from './resources/warning-small.svg';
import * as enums from '../../../util/enums.js';
import { CometChatContext } from '../../../util/CometChatContext';

import { theme } from '../../../resources/theme';
import Translator from '../../../resources/localization/translator';
import { GroupDetailManager } from '../../Groups/CometChatGroupDetails/controller';

import {
  modalWrapperStyle,
  modalCloseStyle,
  modalBodyStyle,
  modalCaptionStyle,
  modalListStyle,
  listHeaderStyle,
  listStyle,
  nameColumnStyle,
  iconStyle,
  modalErrorStyle,
} from './style';

import clearIcon from '../../Groups/CometChatBanGroupMemberList/resources/close.svg';
import { SeenUserListItem } from './SeenUserListItem';

class SeenUserList extends React.Component {
  static contextType = CometChatContext;

  constructor(props, context) {
    super(props, context);
    this._isMounted = false;
    const chatWindow = context.UIKitSettings.chatWindow;
    this.mq = chatWindow.matchMedia(this.context.theme.breakPoints[1]);
    this.state = {
      loggedInUser: null,
    };

    this.state = {
      userColumnTitle: '',
      errorMessage: '',
      deliveredToUsers: [],
    };
  }

  componentDidMount() {
    this.updateUserColumnTitle();
    CometChat.getLoggedinUser()
      .then((user) => {
        console.log('User', user);
        this.setState({
          loggedInUser: user,
        });
        this.GroupDetailManager = new GroupDetailManager(this.props.grouopId);
        this.getGroupMembers();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.updateUserColumnTitle();
    }
  }

  getGroupMembers = () => {
    const administratorslist = [],
      moderatorslist = [];

    this.GroupDetailManager.fetchNextGroupMembers()
      .then((groupMembers) => {
        groupMembers.forEach((member) => {
          if (member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN) {
            administratorslist.push(member);
          }

          if (member.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR) {
            moderatorslist.push(member);
          }

          this.state.deliveredToUsers.push(member);
        });
        this.setState({
          deliveredToUsers: this.state.deliveredToUsers,
        });

        // this.context.setAllGroupMembers(
        //   groupMembers,
        //   administratorslist,
        //   moderatorslist
        // );
      })
      .catch((error) => this.toastRef.setError('SOMETHING_WRONG'));
  };

  updateUserColumnTitle = () => {
    let userColumnTitle = Translator.translate('NAME', this.props.lang);
    // if (this.mq.matches) {
    //   userColumnTitle = Translator.translate('AVATAR', this.props.lang);
    // }

    this.setState({ userColumnTitle });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleScroll = (e) => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) ===
      Math.round(e.currentTarget.clientHeight);
    if (bottom) {
      //   this.props.actionGenerated(enums.ACTIONS['FETCH_GROUP_MEMBERS']);
    }
  };

  handleDeliveredScroll = (e) => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) ===
      Math.round(e.currentTarget.clientHeight);
    if (bottom) {
      this.getGroupMembers();
      // this.props.actionGenerated(
      //   enums.ACTIONS['FETCH_GROUP_MEMBERS'],
      //   null,
      //   null,
      //   null,
      //   null,
      //   this.props.groupController
      // );
    }
  };

  render() {
    const groupMembers = this.props.readReceipts.map((data, key) => {
      return (
        <SeenUserListItem
          timeStamp={data?.readAt}
          member={data?.sender}
          key={key}
        />
      );
    });

    const deliveredToUsers_ = this.state.deliveredToUsers.map((data, key) => {
      console.log('Chatbot role', data);
      if (
        !this.props.readUsersIdObj[data?.uid] &&
        this.state.loggedInUser?.uid !== data?.uid
      ) {
        if (data?.role !== 'chatbot') {
          return (
            <SeenUserListItem
              timeStamp={this.props.message?.sentAt}
              member={data}
              key={key}
            />
          );
        }
      }
      return;
    });

    const getDeliveredUsers = () => {
      const deliveredToUsers_ = [];
      this.state.deliveredToUsers.map((data, key) => {
        console.log('Chatbot role', data);
        if (
          !this.props.readUsersIdObj[data?.uid] &&
          this.state.loggedInUser?.uid !== data?.uid
        ) {
          if (data?.role !== 'chatbot') {
            deliveredToUsers_.push(data);
          }
        }
      });
      return deliveredToUsers_;
    };

    console.log('delivered to users', getDeliveredUsers());

    return (
      <React.Fragment>
        <CometChatBackdrop show={true} clicked={this.props.close} />
        <div
          css={modalWrapperStyle(this.context)}
          className='modal__seenbyusers'
        >
          <div
            className='modal__title'
            style={{
              fontSize: '18px',
              flexDirection: 'row',
              paddingTop: '30px',
              paddingLeft: '28px',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                fontSize: '18px',
                fontWeight: '550',
                textAlign: 'left',
                color: '#000000',
              }}
            >
              {Translator.translate('MESSAGE_INFO', this.context.language)}
            </div>
            <span
              css={modalCloseStyle(clearIcon, this.context)}
              className='modal__close'
              onClick={this.props.close}
              title={Translator.translate('CLOSE', this.context.language)}
            ></span>
          </div>
          <div
            css={modalBodyStyle()}
            className='modal__body'
            onScroll={this.handleDeliveredScroll}
            style={{ overflow: 'scroll' }}
          >
            <div css={modalErrorStyle(this.context)} className='modal__error'>
              {this.state.errorMessage}
            </div>
            <div css={modalListStyle()} className='modal__content'>
              {groupMembers && !!groupMembers?.length && (
                <>
                  {' '}
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <i
                      css={iconStyle(
                        blueDoubleTick,
                        this.context.theme.primaryColor
                      )}
                    ></i>
                    <div
                      css={modalCaptionStyle(
                        Translator.getDirection(this.context.language)
                      )}
                      className='modal__title'
                      style={{
                        fontSize: '13px',
                        fontWeight: '600',
                        marginTop: '2px',
                        marginLeft: '10px',
                        color: '#999999',
                      }}
                    >
                      {Translator.translate('READ_BY', this.context.language)}
                    </div>
                  </div>
                  <div
                    css={listStyle()}
                    className='content__list'
                    onScroll={this.handleScroll}
                    style={{ overflow: 'unset' }}
                  >
                    {groupMembers}
                  </div>
                </>
              )}
              {getDeliveredUsers() && !!getDeliveredUsers()?.length && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '20px',
                    }}
                  >
                    <i
                      css={iconStyle(
                        greyTick,
                        this.context.theme.secondaryTextColor
                      )}
                    ></i>
                    <div
                      css={modalCaptionStyle(
                        Translator.getDirection(this.context.language)
                      )}
                      className='modal__title'
                      style={{
                        fontSize: '13px',
                        fontWeight: '600',
                        color: '#999999',
                        marginTop: '2px',
                        marginLeft: '10px',
                        marginBottom: '0px',
                      }}
                    >
                      {Translator.translate(
                        'NOT_READ_BY',
                        this.context.language
                      )}
                    </div>
                  </div>

                  <div
                    css={listStyle()}
                    className='content__list'
                    // onScroll={this.handleDeliveredScroll}
                    style={{ height: '100%', overflow: 'unset' }}
                  >
                    {deliveredToUsers_}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// Specifies the default values for props:
SeenUserList.defaultProps = {
  theme: theme,
  userColumnTitle: '',

  enableKickGroupMembers: false,
  enableBanGroupMembers: false,
};

SeenUserList.propTypes = {
  theme: PropTypes.object,
  userColumnTitle: PropTypes.string,
  enableKickGroupMembers: PropTypes.bool,
  enableBanGroupMembers: PropTypes.bool,
};

export { SeenUserList };

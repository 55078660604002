import React, { FC, useEffect, useMemo } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput } from 'atoms';
import Dropdown from 'atoms/Dropdown';
import { BoxHeader } from 'ui-components';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';

import { IFormInput } from '../../AddLocation/add-location.types';
import EmailListField from './EmailListField';
import PhoneListField from './PhonelListField';
import OwnerListField from './OwnerListField';
import { LocationStatusOption } from '../../AddLocation/static-data';
import { useLocation } from 'react-router-dom';
import { ADD_EXISTING_LOCATION, ADD_PRE_LAUNCH_LOCATION } from 'appRoutes';

interface IProps {
  isEdit?: boolean;
  partialDisabled?: boolean;
}

const BasicDetails: FC<IProps> = ({ isEdit, partialDisabled }) => {
  const { t } = useTranslation(['common', 'location']);
  const { control, getValues, setValue } = useFormContext<IFormInput>();
  const location = useLocation();
  console.log('location : ', location);
  const isPreLaunch =
    location?.pathname === ADD_PRE_LAUNCH_LOCATION ||
    getValues('locationStatus.value') === 'preLaunch';
  const isReviewAdd = location?.pathname?.includes('/locations/review-add');
  const isAddExisting = location?.pathname === ADD_EXISTING_LOCATION;

  const locationStatusOptions = useMemo(() => {
    return [
      ...LocationStatusOption,
      ...(isPreLaunch ? [{ label: 'Pre Launch', value: 'preLaunch' }] : []),
    ];
  }, [isPreLaunch, LocationStatusOption]);

  useEffect(() => {
    if (isPreLaunch) {
      console.log('is pre launch : ', isPreLaunch);
      console.log('Location status open : ', locationStatusOptions);
      setValue('locationStatus', { label: 'Pre Launch', value: 'preLaunch' });
    }
    if (isAddExisting) {
      setValue('locationStatus', { label: 'Open', value: 'open' });
    }
  }, [isPreLaunch, isAddExisting, locationStatusOptions]);

  const isRoot = useWatch<IFormInput, 'isRoot'>({
    name: 'isRoot',
  });

  const haveLauncher = useWatch<IFormInput, 'haveLauncher'>({
    name: 'haveLauncher',
  });

  return (
    <Flex bg='white' borderRadius='8px' p={8} gap='20px' flexDir='column'>
      <BoxHeader
        fontSize='18px'
        color='#CABDFF'
        title={t('location:basicDetails')}
      />

      <Flex flexDir='column' gap='20px'>
        <Controller
          control={control}
          name='locationType'
          rules={{
            required: t('location:validation.locationTypeRequired'),
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                isDisabled={partialDisabled || isRoot}
                isInvalid={!!fieldState.error}
              >
                <TitleHeader title={t('location:locationType')} isRequired />
                <RadioGroup
                  mt={2}
                  value={field.value}
                  onChange={field.onChange}
                >
                  <Stack direction='row'>
                    <Radio value='corporate'>{t('location:corporate')}</Radio>
                    <Radio value='franchise'>{t('location:franchise')}</Radio>
                  </Stack>
                </RadioGroup>
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Flex gap='20px'>
          <Controller
            control={control}
            name='locationName'
            rules={{
              required: t('location:validation.locationNameRequired'),
              pattern: {
                value: /^[a-zA-Z0-9()\- ]+$/,
                message:
                  'Location name can only include letters, numbers, hyphens, and brackets.',
              },
              validate: (value) =>
                value.trim().length !== 0 ||
                t('common:validation.name_invalid'),
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl
                  isDisabled={partialDisabled}
                  isInvalid={!!fieldState.error}
                >
                  <TitleHeader title={t('location:locationName')} isRequired />
                  <FormInput
                    mt={2}
                    size='lg'
                    placeholder={t('location:placeholder.name')}
                    {...field}
                  />
                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />

          <Box>
            <TitleHeader title={t('common:status')} isRequired />
            <Controller
              control={control}
              name='locationStatus'
              render={({ field, fieldState }) => {
                return (
                  <FormControl
                    mt={2}
                    minW='220px'
                    isInvalid={!!fieldState.error}
                  >
                    <Dropdown
                      isDisabled={
                        haveLauncher ||
                        partialDisabled ||
                        isRoot ||
                        isPreLaunch ||
                        isReviewAdd ||
                        isAddExisting ||
                        isEdit
                      }
                      size='lg'
                      placeholder={t('location:placeholder.locationStatus')}
                      options={locationStatusOptions}
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Box>
        </Flex>

        <EmailListField />

        <PhoneListField />

        {!isPreLaunch && <OwnerListField isDisabled={partialDisabled} />}
      </Flex>
    </Flex>
  );
};

BasicDetails.displayName = 'pages/Locations/Components/BasicDetails';

export default BasicDetails;

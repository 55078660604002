import { ConfirmConfig } from '../../ui-components';
import { AlertConfig } from './useBlockPrompt';
import { RouteAlerts } from './alerts';

export const alertDataParse = (
  message: string,
  callback: (ok: boolean) => void
): string | Partial<ConfirmConfig> => {
  if (message?.includes('"alertType":')) {
    const config = JSON.parse(message) as AlertConfig;

    const alertConfig = RouteAlerts.get(config.alertType);
    if (alertConfig) {
      return alertConfig(callback);
    }
  }

  return message;
};

import React, { ChangeEvent, FC, useMemo } from 'react';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import MoveFormItem from './MoveFormItem';
import AllFormChecked from './AllFormChecked';
import MoveAction from './MoveAction';
import { IMoveFormItem } from './move-form.graphql';

interface IProps {
  formList: IMoveFormItem[];
  onAllSelect: (event: ChangeEvent<HTMLInputElement>, values: string[]) => void;
  onMoveSuccess: () => void;
}

const MoveFormLists: FC<IProps> = ({
  formList,
  onAllSelect,
  onMoveSuccess,
}) => {
  const { t } = useTranslation(['common', 'category']);
  // const [allChecked, setAllChecked] = useState(false);

  const filteredItems = useMemo(() => formList, [formList]);

  const onAllCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    // setAllChecked(event.target.checked);

    onAllSelect(
      e,
      formList.map((i) => i.eid)
    );
  };

  return (
    <Flex flex={1} flexDir='column' overflow='auto' mt={3}>
      <Flex flex={1} flexDir='column' gap='1px'>
        {/*{loading ? (*/}
        {/*  <Center minH='200px'>*/}
        {/*    <Loader size='lg' />*/}
        {/*  </Center>*/}
        {/*) : (*/}
        {/*  <>*/}
        {filteredItems?.length > 0 && (
          <HStack
            spacing='12px'
            // pl='24px'
            pb='8px'
          >
            <AllFormChecked items={filteredItems} onChange={onAllCheckChange} />
            <Text as='b' pl='6px'>
              {t('common:select_all')}
            </Text>
          </HStack>
        )}
        {filteredItems?.map((form) => {
          return (
            <MoveFormItem
              key={form.eid}
              data={form}
              // onCheckChange={onCheckChange}
            />
          );
        })}
        {/*  </>*/}
        {/*)}*/}
      </Flex>
      <MoveAction onMoveSuccess={onMoveSuccess} />
    </Flex>
  );
};

export default MoveFormLists;

import React, { FC } from 'react';
import { GridItem } from '@chakra-ui/react';

import EmptyState from '../../../../sub-components/EmptyState';

import ProgressItemSkeleton from './ProgressItemSkeleton';

interface IProps {
  isLoading?: boolean;
  isEmpty?: boolean;
}

const ProgressListLoader: FC<IProps> = ({ isEmpty, isLoading, children }) => {
  if (isLoading) {
    return (
      <>
        {Array.from({ length: 3 }).map((_, index) => (
          <ProgressItemSkeleton key={`skeleton-${index}`} />
        ))}
      </>
    );
  }

  if (React.Children.count(children) === 0) {
    return (
      <GridItem gridColumn='1/-1' minH='65vh'>
        <EmptyState
          image='LauncherList'
          title='No locations in progress'
          description='Locations will be displayed here as soon as you begin adding them.'
        />
      </GridItem>
    );
  }

  return <>{children}</>;
};

export default ProgressListLoader;

import React, { FC, useState } from 'react';
import { Checkbox, Flex, Text, useModalContext } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../../ui-components';

interface IProps {
  isEditMode?: boolean;
  defaultChecked?: boolean;
  onCancelClick?: (canNotify: boolean) => any | PromiseLike<any>;
  onPublishClick?: (canNotify: boolean) => any | PromiseLike<any>;
}

const NotifyUserContent: FC<IProps> = ({
  isEditMode,
  defaultChecked,
  onCancelClick,
  onPublishClick,
}) => {
  const { t } = useTranslation(['common', 'chapter']);
  const [notifyUsers, setNotifyUsers] = useState(defaultChecked || false);

  const { onClose } = useModalContext();

  return (
    <div>
      <Flex flexDirection='column' gap='12px' pb={4}>
        <Text fontWeight={500} fontSize='16px' color='#6F767E'>
          {t(
            isEditMode
              ? 'chapter:chapterUpdateNotification'
              : 'chapter:chapterPublishNotification'
          )}
        </Text>
        <Checkbox
          fontWeight={500}
          isChecked={!notifyUsers}
          onChange={(e) => setNotifyUsers(!e?.target?.checked)}
        >
          <Text fontSize='14px' color='#1A1D1F'>
            {t('chapter:dontNotify')}
          </Text>
        </Checkbox>
      </Flex>

      <Flex py={2} gap={2} justify='flex-end'>
        <ActionButton
          width='160px'
          size='lg'
          title={t('common:cancel')}
          variant='outline'
          colorScheme='gray'
          fontSize='15px'
          borderRadius='7px'
          close={onClose}
          actionFn={() => onCancelClick?.(notifyUsers)}
        >
          {t('common:cancel')}
        </ActionButton>
        <ActionButton
          minW='160px'
          size='lg'
          title={t('common:publish')}
          variant='solid'
          colorScheme='green'
          fontSize='15px'
          borderRadius='7px'
          close={onClose}
          actionFn={() => onPublishClick?.(notifyUsers)}
        >
          {t(isEditMode ? 'common:updateAndPublish' : 'common:publish')}
        </ActionButton>
      </Flex>
    </div>
  );
};

export default NotifyUserContent;

import { useLazyQuery } from '@apollo/client';
import {
  IAllSessionsForTask,
  IAllSessionsForTaskArgs,
  ISessionById,
  ISessionByIdArgs,
  ISupervisedTasksEntity,
} from 'pages/TasksDashboard/tasks.types';
import {
  GET_ALL_TASK_SESSIONS,
  GET_FORM_RESPONSE_SESSION,
  GET_SESSION_BY_ID,
  TASK_BY_ID,
} from './task-details.graphql';

export const getTaskById = () => {
  const [execute, { loading, error, data }] = useLazyQuery<{
    TaskById: ISupervisedTasksEntity;
  }>(TASK_BY_ID, { fetchPolicy: 'network-only' });
  return { execute, loading, error, data };
};

export const getFormSessionDetails = () => {
  const [execute, { loading, error, data }] = useLazyQuery(
    GET_FORM_RESPONSE_SESSION,
    { fetchPolicy: 'network-only' }
  );
  return { execute, loading, error, data };
};

export const getAllSessionsForTask = () => {
  const [execute, { loading, error, data }] = useLazyQuery<
    IAllSessionsForTask,
    IAllSessionsForTaskArgs
  >(GET_ALL_TASK_SESSIONS, { fetchPolicy: 'network-only' });
  return { execute, loading, error, data };
};

export const getSessionById = () => {
  const [execute, { loading, error, data }] = useLazyQuery<
    ISessionById,
    ISessionByIdArgs
  >(GET_SESSION_BY_ID, { fetchPolicy: 'network-only' });
  return { execute, loading, error, data };
};

import React, { FC } from 'react';
import { Center, Text } from '@chakra-ui/react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/chat-message.json';

interface IProps {
  sidebarView?: boolean;
}

const ChatFeatureNotAvailable: FC<IProps> = ({ sidebarView }) => {
  return (
    <Center flexDir='column' height='full' gap='12px'>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={sidebarView ? 200 : 240}
        width={sidebarView ? 200 : 240}
      />

      <Text
        fontSize={sidebarView ? '18px' : '22px'}
        fontWeight='600'
        lineHeight='152% !important'
      >
        This feature is not available to you.
      </Text>
    </Center>
  );
};

export default ChatFeatureNotAvailable;

import { cloneDeep } from '@apollo/client/utilities';
import { LauncherLocationByIdDetail } from '../types/location-launch-details.types';
import moment from 'moment-timezone';
import { isLauncherDue } from 'pages/launcher/dashboard/launcher.helpers';
import { calculateTimeDifference } from 'pages/Home/components/LOInProgress/helper';
import {
  getUserTimezoneConvertedDate,
  getUserTimezoneConvertedNow,
} from './getUserTimezoneConvertedDate';
import { AuthRole } from 'sop-commons/src/client';

const filterDataForAdmin = (
  details: LauncherLocationByIdDetail,
  adminEid: string
): LauncherLocationByIdDetail => {
  // Clone the original details to avoid mutating the input directly
  const filteredDetails: LauncherLocationByIdDetail = JSON.parse(
    JSON.stringify(details)
  );

  // Filter contents based on the admin's eid and selected status
  filteredDetails.contents = details.contents.filter(
    (content) => content.assignedUser.includes(adminEid) && !content.selected
  );

  // Collect all tasks that need to be retained from the contents that have the admin's eid and are selected
  const validTasks = new Set<string>();
  filteredDetails.contents.forEach((content) => {
    content.tasks.forEach((task) => validTasks.add(task.eid));
  });

  // Filter tasks in LauncherLocationDetailTask array based on the valid tasks collected
  filteredDetails.tasks = details.tasks.filter((task) =>
    validTasks.has(task.eid)
  );

  // Optionally, remove tasks from contents.tasks if they are not in validTasks
  filteredDetails.contents.forEach((content) => {
    content.tasks = content.tasks.filter((task) => validTasks.has(task.eid));
  });

  return filteredDetails;
};

export const foundLocationDataHandler = (
  data: LauncherLocationByIdDetail | undefined,
  loggedInUserTimezone: string,
  loggedInUserAuthRole: AuthRole,
  loggedInUserEid: string
) => {
  let _locationLaunchByIdData = cloneDeep(data);
  if (!_locationLaunchByIdData) return;
  if (loggedInUserAuthRole === AuthRole.ADMIN) {
    _locationLaunchByIdData = cloneDeep(
      filterDataForAdmin(_locationLaunchByIdData, loggedInUserEid)
    );
  }
  console.log('_locationLaunchByIdData : ', _locationLaunchByIdData);
  // _locationLaunchByIdData?.tasks?.forEach((task) => {
  //   let taskDueDate = moment.utc(task?.dueDate);
  //   let initTaskStartDate = moment.utc(task?.startDate);
  //   let bufferApplied = false;
  //   if (task.buffer > 0) {
  //     if (!bufferApplied) {
  //       // Apply buffer only to taskDueDate for the first occurrence
  //       taskDueDate = moment(task.dueDate).add(task.buffer, 'days');
  //       bufferApplied = true;
  //     } else {
  //       // Apply buffer to both taskStartDate and taskDueDate for subsequent tasks
  //       initTaskStartDate = moment(task.startDate).add(task.buffer, 'days');
  //       taskDueDate = moment(task.dueDate).add(task.buffer, 'days');
  //     }
  //   }
  //   let _taskStartDate = moment(
  //     moment.utc(initTaskStartDate),
  //     'DD-MMM-YYYY hh:mm a'
  //   )?.tz(loggedInUserTimezone);
  //   let taskStartDate = moment.utc(
  //     _taskStartDate?.format('DD-MMM-YYYY hh:mm a'),
  //     'DD-MMM-YYYY hh:mm a'
  //   );
  //   task.dueDate = taskDueDate?.toISOString();
  //   task.startDate = taskStartDate?.toISOString();
  // });
  _locationLaunchByIdData.isLauncherDue = isLauncherDue(
    _locationLaunchByIdData.tasks
  );
  _locationLaunchByIdData?.tasks?.map((task, index) => {
    let taskDueDate = moment.utc(task?.dueDate);
    let taskStartDate = moment.utc(task?.startDate);
    let taskCompleteDate = moment.utc(task?.completedAt);
    // const now = moment.utc(
    //   moment().tz(loggedInUserTimezone).format('DD-MMM-YYYY hh:mm a'),
    //   'DD-MMM-YYYY hh:mm a'
    // );
    const now = getUserTimezoneConvertedNow(loggedInUserTimezone);
    //   let _taskStartDate = moment(
    //     moment.utc(task?.startDate),
    //     'DD-MMM-YYYY hh:mm a'
    //   )?.tz(loggedInUserTimezone);
    //   let taskStartDate = moment.utc(
    //     _taskStartDate?.format('DD-MMM-YYYY hh:mm a'),
    //     'DD-MMM-YYYY hh:mm a'
    //   );
    //   let _taskCompleteDate = moment(
    //     moment.utc(task?.completedAt),
    //     'DD-MMM-YYYY hh:mm a'
    //   )?.tz(loggedInUserTimezone);
    //   let taskCompleteDate = moment.utc(
    //     _taskCompleteDate?.format('DD-MMM-YYYY hh:mm a'),
    //     'DD-MMM-YYYY hh:mm a'
    //   );
    let diffInMilliseconds = taskDueDate?.diff(now);
    let duration = moment?.duration(diffInMilliseconds);
    let hours = Math?.floor(duration?.asHours());
    let minutes = Math?.floor(duration?.asMinutes()) - hours * 60;
    let durationString = calculateTimeDifference(now, taskDueDate);
    if (task?.dependency === 'INDEPENDENT') {
      if (task?.completedAt) {
        task.formattedDueDate = taskDueDate;
        // task.formattedStartDate = taskStartDate;
        task.formattedStartDate = taskStartDate;
        task.formattedCompleteDate = taskCompleteDate;
        task.formattedDuration = durationString;
        task.taskStatus = 'complete';
      } else if (taskDueDate.isBefore(now)) {
        task.formattedDueDate = taskDueDate;
        task.formattedStartDate = taskStartDate;
        task.formattedCompleteDate = taskCompleteDate;
        task.formattedDuration = durationString;
        task.taskStatus = 'overdue';
      } else {
        task.formattedDueDate = taskDueDate;
        task.formattedStartDate = taskStartDate;
        task.formattedCompleteDate = taskCompleteDate;
        task.formattedDuration = durationString;
        task.taskStatus = 'inProgress';
      }
      task?.steps?.forEach((step) => {
        if (step?.completedAt) {
          let stepCompleteDate = getUserTimezoneConvertedDate(
            loggedInUserTimezone,
            step?.completedAt
          );
          step.formattedCompleteDate = stepCompleteDate;
        } else {
          step.formattedCompleteDate = undefined;
        }
      });
    } else {
      if (_locationLaunchByIdData?.tasks?.[index - 1]?.completedAt) {
        if (task?.completedAt) {
          task.formattedDueDate = taskDueDate;
          // task.formattedStartDate = taskStartDate;
          task.formattedStartDate = taskStartDate;
          task.formattedCompleteDate = taskCompleteDate;
          task.formattedDuration = durationString;
          task.taskStatus = 'complete';
        } else if (taskDueDate.isBefore(now)) {
          task.formattedDueDate = taskDueDate;
          task.formattedStartDate = taskStartDate;
          task.formattedCompleteDate = taskCompleteDate;
          task.formattedDuration = durationString;
          task.taskStatus = 'overdue';
        } else {
          task.formattedDueDate = taskDueDate;
          task.formattedStartDate = taskStartDate;
          task.formattedCompleteDate = taskCompleteDate;
          task.formattedDuration = durationString;
          task.taskStatus = 'inProgress';
        }
      } else {
        task.formattedDueDate = taskDueDate;
        task.formattedStartDate = taskStartDate;
        task.formattedCompleteDate = taskCompleteDate;
        task.formattedDuration = durationString;
        task.taskStatus = 'locked';
      }
      task?.steps?.forEach((step) => {
        if (step?.completedAt) {
          let stepCompleteDate = getUserTimezoneConvertedDate(
            loggedInUserTimezone,
            step?.completedAt
          );
          step.formattedCompleteDate = stepCompleteDate;
        } else {
          step.formattedCompleteDate = undefined;
        }
      });
    }
  });
  return _locationLaunchByIdData;
};

import { Center, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolder, faFolderUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput } from 'atoms';
import { ChapterSort } from 'pages/Chapters/chapters.graphql';
import React, { FC, useMemo } from 'react';
import { ISelectedFolderData } from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import EmptyState from 'sub-components/EmptyState';
import { useMoveSubFolderLogic } from '../hooks';
import MoveSubFolderFooter from './MoveSubFolderFooter';

interface IProps {
  folderDetails: ISelectedFolderData;
  getVariables?: () => {
    page: number;
    perPage: number;
    filter: Record<string, any>;
    sort: ChapterSort;
  };
  onMoveSubFolderCompleteHandler: () => void;
}

const MoveSubFolder: FC<IProps> = ({
  folderDetails,
  getVariables,
  onMoveSubFolderCompleteHandler,
}) => {
  const _p = useMoveSubFolderLogic({ folderDetails });
  return (
    <Flex flexDir='column' gap={2} w='full'>
      <Flex mx={8}>
        <SearchInput
          placeholder='Search folder name'
          hideShortcuts
          value={_p.searchValue}
          onChange={(e) => _p.setSearchValue(e?.target?.value)}
        />
      </Flex>
      <Flex w='full'>
        {_p.foldersList?.length > 0 ? (
          <Flex flexDir='column' maxH='50vh' overflowY='auto' w='full'>
            {_p.foldersList?.map((list) => (
              <Flex
                key={list?.eid}
                _selected={{
                  backgroundColor: '#2A85FF1F',
                }}
                aria-selected={list?.eid === _p.selectedFolder}
                _hover={{ bg: '#EFEFEF' }}
                w='full'
                cursor='pointer'
                onClick={() => _p.setSelectedFolder(list?.eid)}
                _notLast={{
                  '> div ': {
                    borderBottom: '1px solid #EFEFEF',
                  },
                }}
                paddingX='2rem'
              >
                <Flex w='full' align='center' justify='space-between' py='8px'>
                  <Flex flex={1} gap='5px' align='center'>
                    <Center
                      boxSize='32px'
                      border='1px solid #DDDDDD'
                      borderRadius='5px'
                    >
                      <FontAwesomeIcon
                        icon={
                          (list?.visibility === 'private'
                            ? faFolderUser
                            : faFolder) as IconProp
                        }
                        color='#6F767E'
                        fontSize='15px'
                      />
                    </Center>
                    <Text
                      flex={1}
                      fontSize='15px'
                      noOfLines={1}
                      pr='8px'
                      color='#272b30'
                      fontWeight='500'
                    >
                      {list?.name}
                    </Text>
                  </Flex>
                  <Flex align='center'>
                    <Text fontWeight={500} fontSize='12px' color='#979EA6'>
                      Folder
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex>
        ) : (
          <Center w='full' pb={4}>
            <EmptyState image='Search' title='No Folder found' />
          </Center>
        )}
      </Flex>
      <MoveSubFolderFooter
        folderDetails={folderDetails}
        selectedFolder={_p.selectedFolder}
        getVariables={getVariables}
        onMoveSubFolderCompleteHandler={onMoveSubFolderCompleteHandler}
      />
    </Flex>
  );
};

export default MoveSubFolder;

import { FC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  Button,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Flex,
  useToast,
  Box,
} from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

// import css
import './ChangePasswordModal.scss';
import PasswordInput from '../../atoms/PasswordInput';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      succeed
    }
  }
`;

interface ChangePassResponse {
  changePassword: {
    succeed: boolean;
  };
}
interface ChangePassVariable {
  currentPassword: string;
  newPassword: string;
}

interface IFormInput extends ChangePassVariable {
  confirmNewPassword: string;
}

interface IProps {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordModal: FC<IProps> = ({ open, onClose }) => {
  const { t } = useTranslation(['common', 'auth']);
  const toast = useToast({
    duration: 2000,
    position: 'top-right',
  });
  const submitRef = useRef(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    reset,
    setError,
  } = useForm<IFormInput>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const [changePassword, { loading: isLoading }] = useMutation<
    ChangePassResponse,
    ChangePassVariable
  >(CHANGE_PASSWORD, {
    onCompleted: () => {
      reset();
      onCloseHandler();
      toast({
        title: t('common:success'),
        description: t('auth:pass_update_success'),
        status: 'success',
      });
    },
    onError: (error) => {
      if (error?.message) {
        const message = error.message;
        if (message.includes('Current password')) {
          setError('currentPassword', {
            message: message,
          });
        } else {
          toast({
            title: t('common:error'),
            description: error.message,
            status: 'error',
          });
        }
      }
    },
  });

  const onCloseHandler = () => {
    deployEvent(
      AmplitudeEventNames.SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD_BUTTON_CLOSE
    );
    onClose();
  };

  const onFinish = (values: IFormInput) => {
    if (submitRef.current) {
      return;
    }
    submitRef.current = true;
    deployEvent(
      AmplitudeEventNames.SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD_BUTTON_SAVE
    );
    changePassword({
      variables: {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      },
    }).finally(() => {
      submitRef.current = false;
    });
  };

  return (
    <Modal isOpen={open} isCentered={true} onClose={onCloseHandler} size={'xl'}>
      <ModalOverlay />
      <ModalContent
        width={{
          sm: '340px',
          md: '684px',
          base: '740px',
          xl: '740px',
          ['2xl']: '740px',
        }}
      >
        <ModalHeader style={{ marginTop: 20 }}>
          <Flex align='center' gap='10px'>
            <Box h='32px' w='16px' borderRadius='4px' bg='#cabdff' />
            <div className='modal-title'>{t('auth:change_password')}</div>
          </Flex>
        </ModalHeader>
        <ModalCloseButton style={{ marginTop: 30, marginRight: 15 }} />
        <ModalBody>
          <form
            className='change-password-form-container'
            onSubmit={handleSubmit(onFinish)}
          >
            <div className='old-password'>
              <FormControl mt={3} isInvalid={!!errors?.currentPassword}>
                <div className='input-label'>{t('auth:current_password')}</div>
                <PasswordInput
                  id='current-password'
                  leftIcon={true}
                  rightIcon={true}
                  placeholder={t('auth:password')}
                  formProps={{
                    size: 'md',
                    ...register('currentPassword', {
                      required: t('auth:validation.current_pass_required'),
                      minLength: {
                        value: 6,
                        message: t('validation.password_min_six'),
                      },
                      onBlur: () =>
                        deployEvent(
                          AmplitudeEventNames.SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD_CURRENT_PASSWORD_FIELD
                        ),
                    }),
                  }}
                  error={!!errors?.currentPassword}
                />
                <FormErrorMessage>
                  {errors?.currentPassword?.message}
                </FormErrorMessage>
              </FormControl>
            </div>
            <div className='new-confirm-password-container'>
              <div className='new-password-container'>
                <div className='input-label'>{t('auth:new_password')}</div>
                <FormControl mt={3} isInvalid={!!errors?.newPassword}>
                  <PasswordInput
                    id='new-password'
                    leftIcon={true}
                    rightIcon={true}
                    placeholder={t('auth:password')}
                    formProps={{
                      size: 'md',
                      ...register('newPassword', {
                        required: t('validation.password_required'),
                        minLength: {
                          value: 6,
                          message: t('validation.password_min_six'),
                        },
                        validate: (value) => {
                          if (/^\s*$/.test(value)) {
                            return t('auth:validation.only_spaces_not_allowed');
                          }
                        },
                        onBlur: () =>
                          deployEvent(
                            AmplitudeEventNames.SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD_NEW_PASSWORD_FIELD
                          ),
                      }),
                    }}
                    error={!!errors?.newPassword}
                  />
                  <FormErrorMessage>
                    {errors?.newPassword?.message}
                  </FormErrorMessage>
                </FormControl>
              </div>
              <div className='confirm-new-password-container'>
                <div className='input-label'>{t('auth:conf_new_pass')}</div>
                <FormControl mt={3} isInvalid={!!errors?.confirmNewPassword}>
                  <PasswordInput
                    id='confirm-new-password'
                    leftIcon={true}
                    rightIcon={true}
                    placeholder={t('auth:password')}
                    formProps={{
                      size: 'md',
                      ...register('confirmNewPassword', {
                        required: t('validation.password_required'),
                        minLength: {
                          value: 6,
                          message: t('validation.password_min_six'),
                        },
                        onBlur: () =>
                          deployEvent(
                            AmplitudeEventNames.SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD_CONFIRM_NEW_PASSWORD_FIELD
                          ),
                        validate: (value) => {
                          if (value !== getValues('newPassword')) {
                            return t('auth:validation.cong_pass_not_match');
                          }
                          if (/^\s*$/.test(value)) {
                            return t('auth:validation.only_spaces_not_allowed');
                          }
                        },
                      }),
                    }}
                    error={!!errors?.confirmNewPassword}
                  />
                  <FormErrorMessage>
                    {errors?.confirmNewPassword?.message}
                  </FormErrorMessage>
                </FormControl>
              </div>
            </div>
            <Flex justify='flex-end' mb='30px'>
              <Button
                variant={'solid'}
                type={'button'}
                style={{
                  backgroundColor: '#f53944',
                  color: '#FFFFFF',
                  marginRight: 12,
                  fontSize: 16,
                  fontWeight: '800',
                  height: 42,
                  marginTop: 30,
                  borderRadius: 12,
                  width: 100,
                }}
                onClick={onCloseHandler}
              >
                {t('cancel')}
              </Button>
              <Button
                variant={'solid'}
                type={'submit'}
                isLoading={isLoading}
                style={{
                  backgroundColor: '#4D8AF0',
                  color: '#FFFFFF',
                  fontSize: 16,
                  fontWeight: '800',
                  height: 42,
                  marginTop: 30,
                  borderRadius: 12,
                  width: 100,
                }}
              >
                {t('save')}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;

import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react';

import { IFormInput, OptionWhiteList } from '../form.types';
import QuestionTitle from './QuestionTitle';
import FormInput from '../../../../atoms/FormInput';
import OptionList from '../options/OptionList';
import QuestionMenu from './QuestionMenu';
import { CustomTextArea } from './CustomTextArea';
import QuestionFooter from './QuestionFooter';
import VideoImageHelper from './VideoImageHelper';
import QuestionHiddenField from './QuestionHiddenField';
import QuestionHeader from './QuestionHeader';

const Wrapper = styled.div`
  margin-top: 32px;

  .question-container {
    background: #f9f9f9;
    border: 1px solid #dddddd;
    padding: 24px 30px 18px;
    border-radius: 10px;
    margin-top: 20px;
  }
`;

interface IProps {
  isReadOnly?: boolean;
  currentIndex: number;
  handleRemove: (index: number) => void;
  handleDuplicate: (index: number) => void;
  handleMoveUp: (index: number) => void;
  handleMoveDown: (index: number) => void;
  isLast?: boolean;
}

const Question: FC<IProps> = ({
  currentIndex,
  handleDuplicate,
  handleRemove,
  handleMoveUp,
  handleMoveDown,
  isReadOnly,
  isLast,
}) => {
  const { t } = useTranslation(['common', 'form', 'card']);
  const { control, getValues } = useFormContext<IFormInput>();

  const validateOptions = () => {
    const qType = getValues(`questions.${currentIndex}.qType`);
    if (OptionWhiteList.includes(qType)) {
      const optionLength = getValues(
        `questions.${currentIndex}.options`
      )?.filter((val) => !val.isArchieved)?.length;
      return optionLength < 1;
    } else {
      return false;
    }
  };

  return (
    <Wrapper>
      <QuestionHiddenField
        currentIndex={currentIndex}
        isReadOnly={isReadOnly}
      />
      {/* <QuestionTitle
        currentIndex={currentIndex}
        handleDuplicate={handleDuplicate}
        handleRemove={handleRemove}
        readOnly={isReadOnly}
      /> */}

      <Box className='question-container' position='relative'>
        <QuestionHeader
          currentIndex={currentIndex}
          title={t('card:question')}
          description={t('form:form_title_desc')}
          handleMoveUp={handleMoveUp}
          handleMoveDown={handleMoveDown}
          isReadOnly={isReadOnly}
          isLast={isLast}
        />

        <Controller
          name={`questions.${currentIndex}.label`}
          control={control}
          rules={{
            required: t('card:validation.question_required'),
            validate: (value) => {
              if (value?.trim()?.length === 0) {
                return t('card:validation.question_enter');
              }
              if (validateOptions()) {
                return t('form:validation.enter_min_option');
              }
            },
          }}
          defaultValue=''
          render={({ field, fieldState }) => {
            return (
              <FormControl
                mt={3}
                isInvalid={!!fieldState.error}
                isReadOnly={isReadOnly}
              >
                <FormInput
                  id={`question-${currentIndex}`}
                  size='lg'
                  {...field}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          name={`questions.${currentIndex}.subTitle`}
          control={control}
          defaultValue=''
          render={({ field, fieldState }) => {
            return (
              <FormControl
                mt={3}
                isInvalid={!!fieldState.error}
                isReadOnly={isReadOnly}
              >
                <CustomTextArea
                  readOnly={isReadOnly}
                  id={`description-${currentIndex}`}
                  bordered={false}
                  autoSize
                  placeholder={t('form:descriptionOptional')}
                  {...field}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <VideoImageHelper currentIndex={currentIndex} />
        <OptionList currentIndex={currentIndex} isReadOnly={isReadOnly} />
        <QuestionFooter
          currentIndex={currentIndex}
          isReadOnly={isReadOnly}
          handleDuplicate={handleDuplicate}
          handleRemove={handleRemove}
        />
      </Box>
    </Wrapper>
  );
};

export default Question;

import React, { FC, useContext } from 'react';
import { Flex, Center, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { PreviewContext } from '../../../../../../pages/Training/CreateTrainingPath/Preview/context';

interface NavItemProps {
  id: string;
  onClick: () => void;
  isSelected?: boolean;
}

const NavItem: FC<NavItemProps> = ({ id, isSelected, onClick }) => {
  return (
    <Flex
      id={id}
      aria-selected={isSelected}
      _selected={{ bg: '#2A85FF' }}
      onClick={onClick}
      h='5px'
      w='30px'
      minW='20px'
      bg='#D9D9D9'
      borderRadius='10px'
      cursor='pointer'
    />
  );
};

interface IProps {}

const MobilePreviewNavigation: FC<IProps> = () => {
  const { selectedIndex, trainingContents, onThumbnailClick } =
    useContext(PreviewContext);

  const onClick = (next: number) => {
    onThumbnailClick?.(next);
    document.getElementById(`training-preview-nav-${next}`)?.scrollIntoView({
      inline: 'center',
      block: 'center',
    });
  };

  const totalCards = trainingContents?.length || 0;

  const decrement = () => {
    const next = selectedIndex - 1;
    onThumbnailClick?.(next);
  };

  const increment = () => {
    const next = selectedIndex + 1;
    onThumbnailClick?.(next);
  };

  if (onThumbnailClick) {
    return (
      <>
        <IconButton
          display={selectedIndex === 0 ? 'none' : 'inline-flex'}
          position='absolute'
          size='sm'
          top={0}
          left='0'
          transform='auto'
          translateX='16px'
          translateY='calc(50vh - 160px)'
          borderRadius='full'
          boxShadow='md'
          aria-label={'back'}
          icon={<ChevronLeftIcon boxSize={6} />}
          onClick={decrement}
        />
        <IconButton
          display={selectedIndex + 1 === totalCards ? 'none' : 'inline-flex'}
          position='absolute'
          size='sm'
          top={0}
          right='0'
          transform='auto'
          translateX='-16px'
          translateY='calc(50vh - 160px)'
          borderRadius='full'
          boxShadow='md'
          aria-label={'next'}
          icon={<ChevronRightIcon boxSize={6} />}
          onClick={increment}
        />
      </>
    );
  }

  return (
    <Center position='absolute' bottom={4} left={5} right={5}>
      <Flex
        bottom={4}
        border='1px solid #DDDDDD'
        gap={1}
        padding='10px'
        borderRadius='full'
        align='center'
        bg='white'
        overflow='hidden'
      >
        {trainingContents?.map((it, index) => {
          return (
            <NavItem
              id={`training-preview-nav-${index}`}
              key={index}
              onClick={() => onClick?.(index)}
              isSelected={selectedIndex === index}
            />
          );
        })}
      </Flex>
    </Center>
  );
};

export default MobilePreviewNavigation;

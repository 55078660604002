import React, { FC } from 'react';
import { Box, Checkbox, Flex, HStack, VStack } from '@chakra-ui/react';
import moment from 'moment';
import { IconImage } from '../../../../../ui-components';
import { IMoveFormItem } from './move-form.graphql';

interface IProps {
  data: IMoveFormItem;
}

const MoveFormItem: FC<IProps> = ({ data }) => {
  const updateBy = data?.lastUpdatedBy?.name || data?.createdBy?.name;
  const updateAt = moment(data.updatedAt).format('DD MMMM');

  return (
    <Flex
      // paddingX='24px'
      className='move-item'
      _selected={{
        backgroundColor: '#2A85FF1A',
      }}
      // aria-selected={data?.checked}
      _notLast={{
        '> div ': {
          borderBottom: '1px solid #EFEFEF',
        },
      }}
    >
      <HStack w='full' spacing='12px' paddingY='12px'>
        <Box>
          <Checkbox
            value={data.eid}
            // isChecked={data?.checked}
            // onChange={() => onCheckChange(item.eid)}
          />
        </Box>
        <HStack
          style={{
            flex: 1,
            paddingLeft: 6,
            paddingRight: 6,
            cursor: 'pointer',
            overflow: 'hidden',
            userSelect: 'none',
          }}
          // onClick={() => onCheckChange(item.eid)}
        >
          <IconImage
            thumbnail={data.thumbnail}
            name={data.title}
            boxSize={48}
            borderRadius='8px'
          />

          <VStack align='stretch' flex='1' overflow='hidden'>
            <Box w='max-content' maxW='100%'>
              <Box
                // noOfLines={1}
                fontSize='15px'
                fontWeight='600'
                color='#272B30'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
              >
                {data?.title}
              </Box>
              <Box fontSize='12px' fontWeight='500' color='#6F767E' isTruncated>
                Last updated: {updateAt} by {updateBy}
              </Box>
            </Box>
          </VStack>
        </HStack>
      </HStack>
    </Flex>
  );
};

export default MoveFormItem;

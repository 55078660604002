import styled from '@emotion/styled';

export const FileComponentWrapper = styled.div`
  display: flex;
  row-gap: 10px;
  column-gap: 10px;
  flex-wrap: wrap;

  .file-item {
    display: flex;
    column-gap: 4px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 5px;
    max-width: 100px;
  }

  .file-span {
    text-decoration: underline;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

import React, { FC } from 'react';
import { Box, Flex, FlexProps } from '@chakra-ui/react';

import { usePagination } from '../../../../../../../sub-components/ChakraTable/usePagination';

import Pagination from './Pagination';

interface IProps extends FlexProps {
  totalRegisters?: number;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  registersPerPage?: number;
  itemCount?: number;
}

const LogPagination: FC<IProps> = ({
  totalRegisters,
  onPageChange,
  currentPage,
  registersPerPage = 10,
  itemCount,
  ...rest
}) => {
  const pagination = usePagination({
    totalRegisters: totalRegisters!,
    page: currentPage!,
    items: [],
    registersPerPage: registersPerPage,
    siblingsCount: 1,
  });

  if (!totalRegisters || totalRegisters <= registersPerPage) {
    return null;
  }

  return (
    <Flex
      bg='#FCFCFC'
      shadow='0px -6px 4px 0px #0000000D'
      padding='8px 20px'
      justify='space-between'
      align='center'
      {...rest}
    >
      <Box fontSize='13px' fontWeight='600' color='#1A1D1F'>
        {itemCount} {itemCount === 1 ? 'result' : 'results'}
      </Box>
      <Pagination {...pagination} onPageChange={onPageChange} />
    </Flex>
  );
};

export default LogPagination;

import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { match } from 'ts-pattern';

import { useUserData } from 'hooks';
import { toArray } from 'utils/utils';

import {
  SHARE_MEMBER_QUERY,
  ShareMemberEntity,
  ShareMemberResponse,
} from './share-member.graphql';
import { FormVisibility } from '../Visibility/visibility.types';

interface ReturnData {
  members: ShareMemberEntity[];
  loading?: boolean;
}

export const useVisibilityMemberData = (
  visibility: FormVisibility
): ReturnData => {
  const entityId = useUserData()?.entityId;
  const authUserId = useUserData()?.eid;
  const roleList = useUserData()?.entity?.roles;
  const { data, loading } = useQuery<ShareMemberResponse>(SHARE_MEMBER_QUERY, {
    fetchPolicy: 'network-only',
    skip: !entityId,
    variables: {
      entityId: entityId,
      status: ['active', 'inactive'],
      type: ['user'],
    },
  });

  const roles = useMemo(() => {
    return toArray(roleList).reduce((previousValue, currentValue) => {
      previousValue[currentValue.name] = currentValue.eid;
      // previousValue[currentValue.eid] = currentValue.name;
      return previousValue;
    }, {} as Record<string, string>);
  }, [roleList]);

  const visibilityCheck = JSON.stringify(visibility);

  const members = useMemo(() => {
    if (visibility.visibility === 'public') {
      return toArray(data?.EntityUser).filter((entity) => {
        return entity.eid !== authUserId;
      });
    }

    return match(visibility.visibleTo)
      .with({ condition: 'or' }, (_, value) => {
        return toArray(data?.EntityUser).filter((entity) => {
          return (
            (entity.locations.some((loc) =>
              value.locations.includes(loc.eid)
            ) ||
              value.roles.includes(roles[entity.role]) ||
              value.roles.includes(entity.role) ||
              value.users.includes(entity.eid)) &&
            entity.eid !== authUserId
          );
        });
      })
      .with({ condition: 'and' }, (_, value) => {
        return toArray(data?.EntityUser).filter((entity) => {
          return (
            ((entity.locations.some((loc) =>
              value.locations.includes(loc.eid)
            ) &&
              (value.roles.includes(roles[entity.role]) ||
                value.roles.includes(entity.role))) ||
              value.users.includes(entity.eid)) &&
            entity.eid !== authUserId
          );
        });
      })
      .otherwise(() => []);
  }, [data, visibilityCheck, roles, authUserId]);

  return {
    members,
    loading,
  };
};

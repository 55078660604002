import { Authorize, AuthRole } from 'authorization';
import React, { FC } from 'react';
import { useRenderFlag } from 'sub-components/Sidebar/sections/helper';
import ActivityFeed from 'ui-components/ActivityFeed';

interface IProps {
  setShowSingleUserChatModalHandler: (value: any) => void;
  setSelectedUserHandler: (user: any) => void;
}

const HomeActivityFeed: FC<IProps> = (props) => {
  const { setShowSingleUserChatModalHandler, setSelectedUserHandler } = props;

  let flag = useRenderFlag();
  return flag ? (
    <Authorize
      permittedFor={['user']}
      permittedRoles={[
        AuthRole.SUPER_ADMIN,
        AuthRole.ADMIN,
        AuthRole.LOCATION_OWNER,
      ]}
    >
      <div className='activity-container'>
        <ActivityFeed
          setShowSingleUserChatModalHandler={setShowSingleUserChatModalHandler}
          setSelectedUserHandler={setSelectedUserHandler}
        />
      </div>
    </Authorize>
  ) : null;
};

export default HomeActivityFeed;

import { gql } from '@apollo/client';

export const UPDATE_TRAINING = gql`
  mutation UpdateTraining($input: UpdateTrainingInput) {
    UpdateTraining(input: $input) {
      title
      entityId
      createdBy {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
      }
      status
      thumbnail
      description
      contents {
        type
        eid
        title
        backgroundColor
      }
      visibility
      visibleTo {
        users
        locations
        roles
        _id
      }
      supervisors
      assignedTo
      userProgress
      updatedAt
      createdAt
      eid
      assignedToUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
        locations {
          locations {
            email
            username
            name
            phone
            profilePic
            onboarded
            isDemo
            onboardedAt
            authRole
            type
            role
            entityId
            loginCount
            lastLogin
            isDeleted
            isRoot
            isInvited
            status
            childrens
            updatedAt
            createdAt
            eid
          }
        }
      }
      supervisorsUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        entityId
        loginCount
        lastLogin
        isDeleted
        isRoot
        isInvited
        status
        childrens
        updatedAt
        createdAt
        eid
        locations {
          locations {
            email
            username
            name
            phone
            profilePic
            onboarded
            isDemo
            onboardedAt
            authRole
            type
            role
            entityId
            loginCount
            lastLogin
            isDeleted
            isRoot
            isInvited
            status
            childrens
            updatedAt
            createdAt
            eid
          }
        }
      }
      contentDetails
    }
  }
`;

export const TRAINING_BY_ID_COMPLETE_NEW = gql`
  query TrainingById($eid: String!) {
    trainingById(eid: $eid) {
      title
      createdBy {
        email
        username
        name
        phone
        profilePic
        authRole
        type
        role
        status
        eid
        locations {
          eid
          name
        }
      }
      status
      thumbnail
      description
      contents {
        type
        eid
        title
        backgroundColor
        isMandatory
        thumbnail
        addedBy
        dateAdded
        fileSize
      }
      visibility
      supervisors
      assignedTo
      assignedRoles
      assignedToLocations {
        eid
        name
      }
      assignedLocations
      eid
      assignedToUsers {
        email
        username
        name
        phone
        profilePic
        authRole
        type
        role
        status
        eid
        locations {
          email
          username
          name
          phone
          profilePic
          authRole
          type
          role
          status
          eid
        }
      }
      supervisorsUsers {
        email
        username
        name
        phone
        profilePic
        onboarded
        isDemo
        onboardedAt
        authRole
        type
        role
        status
        eid
        locations {
          email
          username
          name
          phone
          profilePic
          authRole
          type
          role
          status
          eid
        }
      }
      contentDetails
      trainingItems
    }
  }
`;

export const GET_ALL_MEMBER_LOCATION_DATA = gql`
  query GetEntityUserLocationData($entityId: String!) {
    EntityUser(entityId: $entityId) {
      eid
      name
      type
      role
      authRole
      profilePic
      locations {
        name
      }
    }
  }
`;

import { Center, Flex } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { CategoryEntity } from 'sop-commons/src/client';
import Loader from 'ui-components/Loader';
import Heading from './components/Heading';
import List from './components/List';
import { ICustomCategoryEntity } from './components/List/List';

export type IListSortBy = 'asc' | 'desc';

interface IProps {
  height: number;
  foldersList: CategoryEntity[];
  loading?: boolean;
  viewType: 'list' | 'grid' | undefined;
  getChaptersList?: () => void;
}

const FolderList: FC<IProps> = ({
  height,
  foldersList,
  loading,
  viewType,
  getChaptersList,
}) => {
  const [listSortBy, setListSortBy] = useState<IListSortBy>('asc');
  if (loading) {
    return (
      <Center h='calc(100vh - 240px)' w='full'>
        <Loader />
      </Center>
    );
  }
  return (
    <Flex flexDir='column' gap={4} mt={4} w='full'>
      <Heading listSortBy={listSortBy} setListSortBy={setListSortBy} />
      <List
        height={height}
        foldersList={foldersList}
        listSortBy={listSortBy}
        viewType={viewType}
        getChaptersList={getChaptersList}
      />
    </Flex>
  );
};

export default FolderList;

import { gql } from '@apollo/client';
import { UserStatus } from '../../../../types';
import { InvitationMeta } from '../../InvitedMember/update-invite.graphql';
import { AuthRole } from '../../../../authorization';

export interface InvitedUserData {
  eid: string;
  status: UserStatus;
  authRole: AuthRole;
  role: string;
  locations: Array<{
    eid: string;
  }>;

  userInviteMeta: InvitationMeta;
}

export const INVITED_USER_DATA = gql`
  query InvitedUserData($eid: String!) {
    userById(eid: $eid) {
      eid
      status

      authRole
      role
      locations {
        eid
      }

      userInviteMeta {
        chatGroups
        trainings {
          eid
          title
          thumbnail
          contentLastUpdate
          contents {
            eid
            title
            type
          }
        }
      }
    }
  }
`;

import React, { FC, useContext, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { SearchInput, SelectOption } from '../../../../atoms';

import { LocationContext } from '../Context';
import LocationList from './LocationList';

const MoveButton: FC<{
  value?: SelectOption;
}> = ({ value }) => {
  const { t } = useTranslation('category');
  const { onMoveClick, moving, selectedItems } = useContext(LocationContext);

  if (!value?.label) {
    return null;
  }

  return (
    <PopoverFooter borderTop='none'>
      <Button
        isFullWidth
        colorScheme='green'
        fontSize='15px'
        onClick={() => onMoveClick?.([value])}
        isLoading={moving}
        isDisabled={selectedItems?.length === 0 || moving}
      >
        <Trans
          t={t}
          i18nKey='move_to'
          values={{
            value: value?.label,
          }}
        />
      </Button>
    </PopoverFooter>
  );
};

interface IProps {
  options?: SelectOption[];
  value?: SelectOption;
  onChange?: (option: SelectOption) => void;
}

const Content: FC<IProps> = ({ options = [], value, onChange }) => {
  const { t } = useTranslation(['location']);

  const [searchQuery, setSearchQuery] = useState('');

  const filteredOptions = useMemo(() => {
    if (searchQuery) {
      const reg = new RegExp(searchQuery, 'gi');
      return options.filter((opt) => opt.label?.match(reg));
    }
    return options;
  }, [options, searchQuery]);

  return (
    <React.Fragment>
      <PopoverHeader borderBottom='none'>
        <Flex justify='space-between' align='center'>
          <SearchInput
            hideShortcuts
            // @ts-ignore
            placeholder={t('location:searchLocationAmong', {
              value: options?.length || 0,
            })}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
        </Flex>
      </PopoverHeader>
      <PopoverBody display='flex' maxH='320px' overflow='hidden'>
        <LocationList
          options={filteredOptions}
          value={value}
          onChange={onChange}
        />
      </PopoverBody>
      <MoveButton value={value} />
    </React.Fragment>
  );
};

export default Content;

import { ICompareVisibilityType } from './visibilityHelper';

export const anyCommonInBetween = (arr1: string[], arr2: string[]) => {
  for (let i = 0; i < arr1.length; i++) {
    if (arr2.includes(arr1[i])) {
      return true;
    }
  }
  return false;
};

export const isDuplicate = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const membersCompare = (
  flag: ICompareVisibilityType,
  parentMembers: string[],
  childMembers: string[]
): ICompareVisibilityType => {
  console.log({ membersCompare: { flag, parentMembers, childMembers } });
  if (parentMembers?.length === 0 && childMembers?.length === 0) {
    return flag;
  }

  let finalFlag: ICompareVisibilityType = 'parentChildNoIntersection';

  if (parentMembers?.length > 0 && childMembers?.length > 0) {
    console.log('parentMembers?.length > 0 && childMembers?.length > 0');
    if (parentMembers?.length > childMembers?.length) {
      console.log('parentMembers?.length > childMembers?.length');
      let hasAllSame = isDuplicate(parentMembers, childMembers);
      let hasSomeCommon = anyCommonInBetween(parentMembers, childMembers);
      console.log({ hasAllSame, hasSomeCommon });
      if (flag === 'parentChildNoIntersection') {
        if (hasAllSame) {
          finalFlag = 'parentChildSameLengthDifferentVisibility';
        } else if (hasSomeCommon) {
          finalFlag = 'parentMoreVisibleThanChild';
        } else {
          finalFlag = 'parentChildNoIntersection';
        }
      } else if (flag === 'parentChildSameVisibleSameLength') {
        if (hasAllSame) {
          finalFlag = 'parentMoreVisibleThanChild';
        } else if (hasSomeCommon) {
          finalFlag = 'parentMoreVisibleThanChild';
        } else {
          finalFlag = 'parentMoreVisibleThanChild';
        }
      } else if (flag === 'parentChildSameLengthDifferentVisibility') {
        finalFlag = 'parentChildSameLengthDifferentVisibility';
      } else if (flag === 'parentLessVisibleThanChild') {
        finalFlag = 'parentLessVisibleThanChild';
      } else if (flag === 'parentMoreVisibleThanChild') {
        finalFlag = 'parentMoreVisibleThanChild';
      }
    } else if (childMembers?.length > parentMembers?.length) {
      console.log('childMembers?.length > parentMembers?.length');
      let hasAllSame = isDuplicate(parentMembers, childMembers);
      let hasSomeCommon = anyCommonInBetween(parentMembers, childMembers);
      console.log({ hasAllSame, hasSomeCommon });
      if (flag === 'parentChildNoIntersection') {
        if (hasAllSame) {
          finalFlag = 'parentChildSameLengthDifferentVisibility';
        } else if (hasSomeCommon) {
          finalFlag = 'parentLessVisibleThanChild';
        } else {
          finalFlag = 'parentChildNoIntersection';
        }
      } else if (flag === 'parentChildSameVisibleSameLength') {
        if (hasAllSame) {
          finalFlag = 'parentLessVisibleThanChild';
        } else if (hasSomeCommon) {
          finalFlag = 'parentLessVisibleThanChild';
        } else {
          finalFlag = 'parentLessVisibleThanChild';
        }
      } else if (flag === 'parentChildSameLengthDifferentVisibility') {
        finalFlag = 'parentLessVisibleThanChild';
      } else if (flag === 'parentLessVisibleThanChild') {
        finalFlag = 'parentLessVisibleThanChild';
      } else if (flag === 'parentMoreVisibleThanChild') {
        finalFlag = 'parentMoreVisibleThanChild';
      }
    } else {
      console.log('parentMembers?.length === childMembers?.length');
      let hasAllSame = isDuplicate(parentMembers, childMembers);
      let hasSomeCommon = anyCommonInBetween(parentMembers, childMembers);
      console.log({ hasAllSame, hasSomeCommon });
      if (flag === 'parentChildNoIntersection') {
        if (hasAllSame) {
          finalFlag = 'parentChildSameLengthDifferentVisibility';
        } else if (hasSomeCommon) {
          finalFlag = 'parentChildSameLengthDifferentVisibility';
        } else {
          finalFlag = 'parentChildNoIntersection';
        }
      } else if (flag === 'parentChildSameVisibleSameLength') {
        if (hasAllSame) {
          finalFlag = 'parentChildSameVisibleSameLength';
        } else if (hasSomeCommon) {
          finalFlag = 'parentChildSameLengthDifferentVisibility';
        } else {
          finalFlag = 'parentChildSameLengthDifferentVisibility';
        }
      } else if (flag === 'parentChildSameLengthDifferentVisibility') {
        finalFlag = 'parentChildSameLengthDifferentVisibility';
      } else if (flag === 'parentLessVisibleThanChild') {
        finalFlag = 'parentLessVisibleThanChild';
      } else if (flag === 'parentMoreVisibleThanChild') {
        finalFlag = 'parentMoreVisibleThanChild';
      }
    }
  } else if (parentMembers?.length > 0 && childMembers?.length === 0) {
    if (flag === 'parentChildNoIntersection') {
      finalFlag = 'parentMoreVisibleThanChild';
    } else if (flag === 'parentChildSameLengthDifferentVisibility') {
      finalFlag = 'parentMoreVisibleThanChild';
    } else if (flag === 'parentChildSameVisibleSameLength') {
      finalFlag = 'parentMoreVisibleThanChild';
    } else if (flag === 'parentLessVisibleThanChild') {
      finalFlag = 'parentMoreVisibleThanChild';
    } else if (flag === 'parentMoreVisibleThanChild') {
      finalFlag = 'parentMoreVisibleThanChild';
    }
  } else if (parentMembers?.length === 0 && childMembers?.length > 0) {
    if (flag === 'parentChildNoIntersection') {
      finalFlag = 'parentLessVisibleThanChild';
    } else if (flag === 'parentChildSameLengthDifferentVisibility') {
      finalFlag = 'parentLessVisibleThanChild';
    } else if (flag === 'parentChildSameVisibleSameLength') {
      finalFlag = 'parentLessVisibleThanChild';
    } else if (flag === 'parentLessVisibleThanChild') {
      finalFlag = 'parentLessVisibleThanChild';
    } else if (flag === 'parentMoreVisibleThanChild') {
      finalFlag = 'parentLessVisibleThanChild';
    }
  } else {
    finalFlag = flag;
  }
  return finalFlag;
};

import React, { forwardRef, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { ChatFeatureNotAvailable } from '../../pages/Chat';
import { useUserDataSelector, useUserEntity } from '../../hooks';
import UserNotRegistered from './UserNotRegistered';
import { Title } from '../common';

import { CometChatMessages } from '../../cometchat-pro-react-ui-kit/CometChatWorkspace/src';
import { theme } from '../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/resources/theme.js';
import { CometChatContextProvider } from '../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/util/CometChatContext.js';
import { useHasChatAccess } from 'hooks/useChatRestriction';
import { useReactiveVar } from '@apollo/client';
import { usersEntityObj } from 'sub-components/Header';
import { AuthRole } from 'authorization';

export interface ChatModalProps {
  onClose: () => void;
  selectedUser?: string;
  status?: string;
}

const ChatModalContent = forwardRef<never, ChatModalProps>(
  ({ status, ...props }, ref) => {
    const { i18n } = useTranslation();

    const entityUsers = useReactiveVar(usersEntityObj);
    const isChatEnabled = useUserEntity((entity) => entity?.features?.chat);

    const hasChatAccess = useHasChatAccess();

    const userDetails = useMemo(() => {
      let foundUser = entityUsers?.find(
        (user) => user?.eid === props.selectedUser
      );
      if (foundUser) {
        return {
          eid: foundUser?.eid,
          role: foundUser?.role,
          authRole: foundUser?.authRole,
          type: foundUser?.type,
        };
      } else {
        return {
          eid: '',
          role: '',
          authRole: '',
          type: '',
        };
      }
    }, [entityUsers]);

    if (!isChatEnabled) {
      return (
        <Flex flexDir='column' boxSize='full' px={6}>
          <Flex align='center' gap={3}>
            <ArrowBackIcon
              color='#000000'
              boxSize='24px'
              cursor='pointer'
              onClick={props.onClose}
            />
            <Title fontSize='18px' fontWeight='600' color='#1A1D1F'>
              Send message
            </Title>
          </Flex>
          <ChatFeatureNotAvailable sidebarView />
        </Flex>
      );
    }

    return (
      <div style={{ height: '100%', width: '100%' }}>
        {status === 'pending' ? (
          <UserNotRegistered onClose={props.onClose} />
        ) : (
          <CometChatContextProvider
            user={props.selectedUser}
            group={''}
            businessId={''}
            fetchChatCount={0}
            language={i18n.language}
          >
            <Flex pos='relative' w='100%' h='100%'>
              <CometChatMessages
                ref={ref}
                theme={theme}
                lang={i18n.language}
                _parent='unified'
                showInitialScreen={false}
                showUserDetailsIcon={false}
                onClose={props.onClose}
                chatRestricted={
                  !hasChatAccess({
                    eid: userDetails?.eid,
                    authRole: userDetails?.authRole as AuthRole,
                    role: userDetails?.role,
                    type: userDetails?.type,
                  })
                }
                // businessId={this.props.businessId}
              />
            </Flex>
          </CometChatContextProvider>
        )}
      </div>
    );
  }
);

ChatModalContent.displayName = 'ChatModalContent';

export default ChatModalContent;

import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import EmptyState from '../../../../sub-components/EmptyState';
import {
  Column,
  SortingTable,
} from '../../../../sub-components/ChakraTable/SortingTable';
import { MyTrainingModal } from '../../../Training/TrainingList/modal';
import {
  DemoProgressCell,
  DemoStatusCell,
} from '../../../../sub-components/TrainingPathTable/DemoComponent';
// import DownloadButton from '../../../Training/TrainingList/Component/DownloadButton';
import { SortByName } from '../../../../utils/sorting';
import { CertificateItem } from './certificate.graphql';
import { DownloadButton } from './component';
import CertificateTrainingName from './CertificateTrainingName';

function SortByProgress(
  a: Row<CertificateItem>,
  b: Row<CertificateItem>
): number {
  const viewCountA = a.original.viewedContents;
  const maxCountA = a.original.trainingItems;

  const valA = (viewCountA / maxCountA) * 100;

  const viewCountB = b.original.viewedContents;
  const maxCountB = b.original.trainingItems;

  const valB = (viewCountB / maxCountB) * 100;

  return valB - valA;
}

interface IProps {
  onPageChangeHandler: () => void;
  currentPage: number;
  dataList: any[];
  loading: boolean;
  itemCount: number;
  hasNextPage: boolean;
}

const CertificatesList: FC<IProps> = ({
  currentPage,
  dataList,
  loading,
  itemCount,
  hasNextPage,
  onPageChangeHandler,
}) => {
  const { t, i18n } = useTranslation(['common', 'training']);

  const columns = useMemo((): Column<CertificateItem>[] => {
    return [
      {
        Header: t('common:name'),
        accessor: 'training',
        width: '41%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <CertificateTrainingName
              pathName={value}
              thumbnail={row?.original.thumbnail}
              completedAt={row?.original.firstCompletedAt}
              index={row.index}
            />
          );
        },
        sortType: SortByName,
      },
      {
        id: 'status',
        Header: t('common:status'),
        accessor: 'trainingItems',
        width: '22%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <DemoStatusCell
              viewCount={row.original.viewedContents}
              totalCount={value}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('training:progress'),
        accessor: 'trainingItems',
        width: '22%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <DemoProgressCell
              // onClick={progressDataHandler}
              item={{
                ...row.original,
                totalViewedCount: row.original.viewedContents,
              }}
              maxLength={value}
              index={row?.index}
            />
          );
        },
        sortType: SortByProgress,
      },
      {
        Header: '',
        accessor: 'certificateUrl',
        id: 'download',
        Cell: ({ cell: { value, row } }) => {
          return <DownloadButton certificateUrl={value} isCompleted />;
        },
        disableSortBy: true,
      },
    ];
  }, [i18n.language]);

  return (
    <div>
      <SortingTable
        colorScheme='blue'
        emptyData={{
          content: (
            <EmptyState
              image='Certificate'
              title={t('training:noCertificateAwarded')}
            />
          ),
        }}
        page={currentPage || 1}
        onPageChange={onPageChangeHandler}
        columns={columns}
        data={dataList}
        isLoading={loading}
        totalRegisters={itemCount}
        isResponsive
        isHidePagination={
          !((currentPage === 1 && hasNextPage) || (currentPage || 1) > 1)
        }
      />
    </div>
  );
};

export default CertificatesList;

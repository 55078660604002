import React, { FC } from 'react';

import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';

interface IProps
  extends Pick<BoxProps, 'noOfLines' | 'fontSize' | 'isTruncated'> {
  title: string;
  color?: BoxProps['bg'];
  isRequired?: boolean;
  boxWidth?: BoxProps['w'];
}

const BoxHeader: FC<IProps> = ({
  title,
  color = '#b1e5fc',
  noOfLines,
  isTruncated,
  fontSize = '20px',
  isRequired,
  boxWidth = 4,
}) => {
  return (
    <Flex align='center' overflow={isTruncated ? 'hidden' : undefined}>
      <Box
        mr={boxWidth}
        w={boxWidth}
        minW={boxWidth}
        borderRadius={4}
        bg={color}
        style={{ aspectRatio: '1/2' }}
      />
      <Box
        fontWeight='600'
        fontSize={fontSize}
        lineHeight='32px'
        color='#1a1d1f'
        noOfLines={noOfLines}
        isTruncated={isTruncated}
      >
        {title}
      </Box>
      {isRequired && (
        <Text color='#FF6A55' fontWeight={600} fontSize='16px'>
          *
        </Text>
      )}
    </Flex>
  );
};

export default BoxHeader;

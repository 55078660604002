import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import scss
import './AuthContainer.scss';

import FeatureItem from './FeatureItem';
// @ts-ignore
import logo from '../../assets/images/logo.webp';
import React from 'react';
import { useCustomLoginPage } from '../../hooks';
import { Box, Center, Flex, Image } from '@chakra-ui/react';

const AuthContainer: FC = ({ children }) => {
  const { t } = useTranslation(['common', 'auth']);
  const { currentLabelData, currentLogo, isDefaultDomainUrl } =
    useCustomLoginPage();

  const render = () => {
    if (isDefaultDomainUrl) {
      return (
        <div className='auth-container'>
          <div className='auth-sidebar-container'>
            <Link to='/'>
              <div>
                <img
                  src={currentLogo}
                  style={{ width: '96px' }}
                  alt='delightree'
                />
              </div>
            </Link>

            <div className='auth-sidebar-title-container'>
              {currentLabelData?.sideCompanyTitle}
            </div>
            <div className='auth-sidebar-desc-container '>
              {currentLabelData?.sideCompanyDescription}
            </div>
            <div style={{ marginTop: '60px' }}>
              <FeatureItem title={t('auth:sidebar.quick_easy')} />
              <FeatureItem title={t('auth:sidebar.progress')} />
              <FeatureItem title={t('auth:sidebar.communication')} />
            </div>
          </div>
          <div className='auth-content-container'>
            <div className='auth-other-screen-container'>
              <Link to='/'>
                <img src={currentLogo} alt='delightree' />
              </Link>
            </div>

            {children}
          </div>
        </div>
      );
    }
    return (
      <div className='auth-container'>
        <Box className='auth-sidebar-container' pos='relative'>
          <Flex flexDir='column' w='full'>
            <Box as='span' fontSize='32px' color='#272b30' fontWeight={700}>
              {currentLabelData?.sideCompanyTitle}
            </Box>
            {currentLabelData?.sideCompanyDescription && (
              <div className='auth-sidebar-desc-container '>
                {currentLabelData?.sideCompanyDescription}
              </div>
            )}
          </Flex>

          <div style={{ marginTop: '10px' }}>
            <FeatureItem title={t('auth:sidebar.quick_easy')} />
            <FeatureItem title={t('auth:sidebar.progress')} />
            <FeatureItem title={t('auth:sidebar.communication')} />
          </div>
          <Center flexDir='column' gap='7px' pos='absolute' bottom='1.5rem'>
            <Flex gap='9px' align='center'>
              <Image src={logo} boxSize='18px' />
              <Box
                as='span'
                fontWeight={500}
                fontSize='12px'
                color='rgba(111, 118, 126, 1)'
              >
                Powered by Delightree
              </Box>
            </Flex>
            <Box
              as='span'
              fontWeight={400}
              fontSize='12px'
              color='rgba(111, 118, 126, 1)'
            >
              Operations and training on auto-pilot
            </Box>
          </Center>
        </Box>
        <div className='auth-content-container'>
          <div className='auth-other-screen-container'>
            <Link to='/'>
              <Image
                src={currentLogo}
                alt='delightree'
                mb='20px !important'
                w={['80px !important', '80px !important', '100px !important']}
              />
            </Link>
          </div>

          {children}
        </div>
      </div>
    );
  };

  return render();
};

export default AuthContainer;

import { cloneDeep } from '@apollo/client/utilities';
import { CardFormInput } from './card-editor.types';
import { CardType } from '../../types';
import { TranslatedData } from '../../types/base-card.types';
import { toArray } from '../../utils/utils';

const checkDiff = (a: object = {}, b: object = {}) => {
  return JSON.stringify(a) !== JSON.stringify(b);
};

const findImg = (val: any) => val.type === 'image';

export const isContentChanged = (
  values: CardFormInput,
  content: any[] | undefined
) => {
  if (!values.defaultLanguage) {
    return false;
  }
  const prevContent = values.translations[values.defaultLanguage]?.content;

  switch (values.cardType.value) {
    case CardType.Image:
      return checkDiff(prevContent, content as object);
    case CardType.Video:
      return checkDiff(prevContent?.[0], content?.[0] as object);
    case CardType.Form:
      return prevContent?.[0]?.url !== content?.[0]?.url;
    case CardType.Mixed:
      return checkDiff(prevContent?.find(findImg), content?.find(findImg));
    default:
      return false;
  }
};

const updateContent = (
  translation: TranslatedData,
  newContent: any[] | undefined,
  cardType: CardType
): TranslatedData => {
  const prevContent = toArray(translation.content);

  switch (cardType) {
    case CardType.Image:
      // return checkDiff(prevContent, content as object);
      return {
        ...translation,
        content: newContent,
      };
    case CardType.Video:
      // return checkDiff(prevContent?.[0], content?.[0] as object);
      prevContent[0] = newContent?.[0];
      return {
        ...translation,
        content: prevContent,
      };
    case CardType.Form:
      // return prevContent?.[0]?.url !== content?.[0]?.url;
      prevContent[0]['url'] = newContent?.[0]?.url;
      return {
        ...translation,
        content: prevContent,
      };
    case CardType.Mixed:
      // return checkDiff(prevContent?.find(findImg), content?.find(findImg));
      return {
        ...translation,
        content: [
          ...(prevContent?.filter((val: any) => val.type !== 'image') || []),
          newContent?.find(findImg),
        ],
      };
  }
  return translation;
};

export const getUpdatedContent = (
  values: any,
  newContent: any[] | undefined
): Promise<TranslatedData[]> | undefined => {
  if (!values.defaultLanguage) {
    return;
  }
  const translations = cloneDeep(values.translations);

  delete translations[values.defaultLanguage];

  return new Promise<TranslatedData[]>((resolve) => {
    const newData = Object.keys(translations).map((key) =>
      updateContent(
        translations[key],
        newContent,
        values.cardType?.value || values.cardType
      )
    );
    resolve(newData);
  });
};

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';
import produce from 'immer';

import { createAppStore } from 'zustandStore';
import { createJSONStorage, persist } from 'zustandStore/middleware';

interface State {
  histories: Location[];
  set: (callback: (state: State) => void) => void;
}

export const useRouteHistory = createAppStore<State>()(
  persist(
    (set) => ({
      histories: [] as Location[],
      set: (fn) => set(produce(fn)),
    }),
    {
      name: '@route-history-delightree',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useRouteHistoryListener = (): void => {
  const history = useHistory();
  const setState = useRouteHistory((store) => store.set);

  useEffect(() => {
    if (!history.location.key) {
      useRouteHistory.setState((state) => {
        state.histories.push(history.location);
        return state;
      });
    }

    const subs = history.listen((location, action) => {
      setState((state) => {
        switch (action) {
          case 'PUSH':
            state.histories.push(location);
            break;

          case 'POP':
            state.histories.splice(
              state.histories.findIndex((loc) => loc.key === location.key) + 1
            );
            break;

          case 'REPLACE':
            state.histories.splice(-1, 1, location);
            break;
        }
      });
    });

    return () => subs();
  }, [history]);
};

// @ts-ignore
import React, { FC, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';

import i18next from 'i18next';
import { useQuery } from '@apollo/client';

import { ILanguageSetting } from './ui-components/LanguageSetting/language-setting.types';
import { GET_SUPPORTED_LOCALES } from './ui-components/LanguageSetting/language-setting.graphql';
import { localizationEnabled } from './utils/constants';
import { useUserDataSelector } from './hooks';
import { useRouteHistoryListener } from './routes';

interface IProps {}

const ChangeLanguage: FC<IProps> = () => {
  useRouteHistoryListener();
  const [value, setValue] = useState(i18next.language);
  const preferredLanguage = useUserDataSelector(
    (state) => state.preferredLanguage
  );

  const { data } = useQuery<ILanguageSetting>(GET_SUPPORTED_LOCALES, {
    fetchPolicy: 'network-only',
  });

  const languages = data?.SupportedLocales || [];

  React.useEffect(() => {
    i18next.on('languageChanged', (lng) => {
      setValue(lng);
    });
  }, []);

  React.useEffect(() => {
    if (preferredLanguage && localizationEnabled) {
      i18next.changeLanguage(preferredLanguage);
    }
  }, [preferredLanguage]);

  const onClick = () => {
    const language = i18next.language;
    const TMP = languages.filter((val) => val.languageCode !== language);

    return i18next.changeLanguage(
      TMP[Math.floor(Math.random() * TMP.length)]?.languageCode
    );
  };

  if (process?.env?.NODE_ENV !== 'development') {
    return null;
  }

  return (
    <Box pos='fixed' bottom='10px' right='10px' zIndex='6000'>
      <Button
        borderRadius='full'
        colorScheme='blue'
        boxShadow={4}
        onClick={onClick}
        textTransform='uppercase'
      >
        {value}
      </Button>
    </Box>
  );
};

export default ChangeLanguage;

import { gql } from '@apollo/client';
import { AuthRole } from 'authorization';
import { UserStatus, UserType } from 'types';
import { LocationEntity } from '../../../../pages/Training/TrainingList/types';
import { TrendAnalytic, IGraphRange } from '../common';

export interface UserVariable {
  eid: string;
  userId: string;
}

export interface UserResponse {
  userById: {
    eid: string;
    name: string;
    email?: string;
    profilePic?: string;
    authRole: AuthRole;
    role: string;
    timezone: string;
    type: UserType;
    username?: string;
    phone?: string;
    locations: LocationEntity[];
  };

  UserTaskProgress: {
    complete: number;
    inComplete: number;
  };
}

export const USER_DETAIL_QUERY = gql`
  query PerformUserDetails($eid: String!, $userId: String!) {
    userById(eid: $eid) {
      eid
      name
      email
      profilePic
      authRole
      role
      timezone
      type
      username
      phone
      locations {
        eid
        name
      }
    }

    UserTaskProgress(userId: $userId)
  }
`;

export interface LocationOwnerEntity {
  eid: string;
  name: string;
  role: string;
  authRole: AuthRole;
  status: UserStatus;
  profilePic?: string;
  locations: LocationEntity[];
}

export interface LocationOwnerResponse {
  locationUser: LocationOwnerEntity[];
}

export interface LocationOwnerVariable {
  locationId?: string;
}

export const LOCATION_OWNER_QUERY = gql`
  query LocationOwner($locationId: String!) {
    locationUser(locationId: $locationId) {
      eid
      name
      role
      authRole
      status
      profilePic
      locations {
        eid
        name
      }
    }
  }
`;

export interface CompletionRateVariable extends IGraphRange {
  userId: string;
}

export interface CompletionRateResponse {
  UserTaskCompletionTrend: Record<number, TrendAnalytic>;
}

export const COMPLETION_RATE_QUERY = gql`
  query UserTaskCompletionTrend(
    $userId: String!
    $type: AnalyticsTrendType!
    $startDate: Date
    $endDate: Date
  ) {
    UserTaskCompletionTrend(
      userId: $userId
      type: $type
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

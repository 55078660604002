import { IdType, Row } from 'react-table';
import moment from 'moment/moment';
import { MIN_DATE } from './constants';

export function NullShorting<D extends object>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: IdType<D>,
  desc?: boolean
): number {
  return desc ? rowB.index - rowA.index : rowA.index - rowB.index;
}

export function SortByDate<D extends object>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: IdType<D>
): number {
  const valA = moment(rowA.values[columnId] || MIN_DATE).valueOf();
  const valB = moment(rowB.values[columnId] || MIN_DATE).valueOf();
  if (valA < valB) {
    return 1;
  } else if (valA > valB) {
    return -1;
  } else {
    return 0;
  }
}

export function SortByName<D extends object>(
  rowA: Row<D>,
  rowB: Row<D>,
  columnId: IdType<D>
): number {
  const valA = rowA.values[columnId];
  const valB = rowB.values[columnId];
  if (valA < valB) {
    return 1;
  } else if (valA > valB) {
    return -1;
  } else {
    return 0;
  }
}

export function SortByNumber<D extends object>(
  a: Row<D>,
  b: Row<D>,
  c: IdType<D>
): number {
  const valA = a.values[c];
  const valB = b.values[c];
  return valB - valA;
}

import React, { FC, useCallback, useMemo, useRef } from 'react';
import {
  Box,
  Flex,
  IconButton,
  List,
  ListItem,
  Text,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { SelectOption } from '../../../../atoms/Dropdown';
import { toArray } from '../../../../utils/utils';

interface ItemProps {
  option: SelectOption;
  value?: SelectOption[];
  onClick: () => void;
}

const SelectItem: FC<ItemProps> = ({ option, value, onClick }) => {
  const isSelected = useMemo(() => {
    return toArray(value).some((val) => val.value === option.value);
  }, [option, value]);

  return (
    <ListItem
      px='16px'
      cursor='pointer'
      onClick={onClick}
      _hover={{
        bg: '#F7F7F7',
      }}
    >
      <Flex align='center' justify='space-between'>
        <Text>{option.label}</Text>
        {isSelected && (
          <FontAwesomeIcon icon={faCheck as IconProp} color='#83BF6E' />
        )}
      </Flex>
    </ListItem>
  );
};

interface IProps {
  placeholder?: string;
  options: SelectOption[];
  value?: SelectOption[];
  onChange?: (value: SelectOption[]) => void;
  isDisabled?: boolean;
}

const MultiSelectCustom: FC<IProps> = ({
  placeholder,
  options = [],
  value = [],
  onChange,
  isDisabled,
}) => {
  const ref = useRef(null);
  const { isOpen, onClose, onToggle } = useDisclosure();

  useOutsideClick({
    ref: ref,
    handler: onClose,
  });

  const _onSelectChange = useCallback(
    (option: SelectOption) => {
      const tempValue = [...toArray(value)];

      const index = toArray(tempValue).findIndex(
        (val) => val.value === option.value
      );

      if (index >= 0) {
        tempValue.splice(index, 1);
        onChange?.(tempValue);
      } else {
        const newValues = tempValue.concat(option);

        onChange?.(newValues);
      }
    },
    [options, value, onChange]
  );

  const valueString = useMemo(() => {
    return toArray(value)
      .map((val) => val.label)
      .join(', ');
  }, [value]);

  return (
    <Box pos='relative' width='full'>
      <Flex
        borderRadius='12px'
        p='12px 8px'
        align='center'
        gap={2}
        onClick={isDisabled ? undefined : onToggle}
        cursor='pointer'
        bg='#ffffff'
        aria-disabled={isDisabled}
        _disabled={{
          opacity: 0.4,
          cursor: 'not-allowed',
        }}
      >
        <Box
          flex={1}
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          overflow='hidden'
          fontSize='15px'
          fontWeight='500'
          pl='8px'
          color={valueString ? '#1A1D1F' : '#979CA3'}
        >
          {valueString || placeholder}
        </Box>
        <IconButton
          hidden={!valueString || isDisabled}
          variant='ghost'
          borderRadius='full'
          size='xs'
          aria-label='clear'
          icon={<CloseIcon boxSize='9px' />}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onChange?.([]);
          }}
        />
        <ChevronDownIcon boxSize='24px' />
      </Flex>

      {isOpen && (
        <Box
          ref={ref}
          pos='absolute'
          w='inherit'
          zIndex={999}
          bg='white'
          boxShadow='lg'
          borderRadius='8px'
          top='54px'
          overflow='hidden'
        >
          <List overflowY='scroll' maxH='220px'>
            {options?.map((option, index) => (
              <SelectItem
                key={`option-${index}`}
                option={option}
                value={value}
                onClick={() => _onSelectChange(option)}
              />
            ))}
            <ListItem hidden={!!options?.length} px='16px' cursor='pointer'>
              <Flex align='center' justify='center' my={2}>
                <Text fontSize='15px' fontWeight='500' color='#979CA3'>
                  No options
                </Text>
              </Flex>
            </ListItem>
          </List>
        </Box>
      )}
    </Box>
  );
};

export default MultiSelectCustom;

import React, { useCallback, useRef } from 'react';

import { cloneDeep } from '@apollo/client/utilities';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { useTranslation } from 'react-i18next';

import {
  BoxHeader,
  useConfirm,
  UseConfirm,
} from '../../../../../../ui-components';
import { ChatGroupEntity } from '../../../../../../types';
import { updateObject } from '../../../../../../utils/objectHelper';
import { toArray } from '../../../../../../utils/utils';
import AddMemberContent from './AddMemberContent';

export const useAddMember = () => {
  const { t } = useTranslation(['common', 'setting']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const stateRef = useRef<ChatGroupEntity>();
  const confirm = useConfirm();

  const apolloClient = useApolloClient();

  return useCallback(({ initialState, onMemberUpdated }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    stateRef.current = updateObject(
      cloneDeep(initialState),
      ['details', 'locationIds'],
      toArray(initialState?.details?.locations).map((it) => it.eid)
    );

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex justify='space-between'>
          <BoxHeader title={t('setting:addMembers')} color='#CABDFF' />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <AddMemberContent
          initialValue={initialState}
          groupId={initialState?.guid}
          onMemberUpdated={callAll(
            onMemberUpdated,
            (_data: never) => _data && setTimeout(confirmRef.current!.destroy)
          )}
        />
      ),
      contentProps: {
        py: '12px',
        maxWidth: '600px',

        marginTop: 'auto',
        marginBottom: 'auto',
        containerProps: {
          // alignItems: 'start',
          paddingTop: '3.75rem',
          paddingBottom: '3.75rem',
        },
      },
      isCentered: true,
      footer: null,
    });
  }, []);
};

import { Box, Flex, Switch, Text, Tooltip } from '@chakra-ui/react';
import PrimaryButton from 'atoms/PrimaryButton';
import { ReactComponent as CloseIconDark } from '../../../../assets/images/closeIconDark.svg';
import previewIcon from '../../../../assets/images/preview-with-eye.png';
import closeIcon from '../../../../assets/images/closeIcon.png';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

interface IProps {
  card: any;
  fromDeck?: boolean;
  hoverFunctionalityText?: string;
  shouldShowHoverFunctionality?: boolean;
  isEdit?: boolean;
  handleRemove?: (id: string) => void;
  handleChange?: () => void;
  hoverClick?: (data: any, type: 'milestone' | 'formResponse') => void;
  previewHandler?: (data: any) => void;
  toggleSwitch?: (id: string, toggleValue: boolean) => void;
}

const RemoveWrapper = styled.div`
  padding-right: 5px;
  cursor: pointer;
  display: flex;
  gap: 10px;
`;

const ActionIcons: FC<IProps> = ({
  card,
  fromDeck,
  isEdit,
  shouldShowHoverFunctionality,
  hoverFunctionalityText,
  handleRemove,
  handleChange,
  previewHandler,
  toggleSwitch,
  hoverClick,
}) => {
  const { t } = useTranslation(['deck', 'common', 'training']);
  return (
    <>
      <Flex justify={'flex-end'} align={'center'} gap={2}>
        {card?.type === 'deck' && (
          <Text as={'b'} width={'full'} marginTop={'1.7rem'}>
            {card?.cards?.length || 0}{' '}
            {card?.cards?.length > 1 || card?.cards?.length === 0
              ? t('common:cards')
              : t('common:card')}
            {/* {` (${card?.cards?.length || 0} card${
              card?.cards?.length < 2 ? '' : 's'
            })`} */}
          </Text>
        )}
        {/* <Flex flexWrap={'wrap'} justify={'flex-end'} gap={2} align={'center'}>
          {shouldShowHoverFunctionality && card?.cardType === 'formCard' && (
            <div style={{ marginRight: '10px' }}>
              <PrimaryButton
                className='action-button'
                size='sm'
                title={t('training:view_form_response_button')}
                variant='outline'
                onClick={() => hoverClick?.(card, 'formResponse')}
              />
            </div>
          )}
          {shouldShowHoverFunctionality && (
            <div>
              <PrimaryButton
                className='action-button'
                size='sm'
                title={hoverFunctionalityText}
                variant='outline'
                onClick={() => hoverClick?.(card, 'milestone')}
              />
            </div>
          )}
        </Flex> */}
        {shouldShowHoverFunctionality && card?.cardType === 'formCard' && (
          <RemoveWrapper>
            <Tooltip
              label={t('training:makeFormRequired')}
              placement='top-end'
              hasArrow
            >
              <Box>
                <Switch
                  isChecked={card?.isMandatory}
                  onChange={(e) =>
                    toggleSwitch && toggleSwitch(card?.eid, e?.target?.checked)
                  }
                />
              </Box>
            </Tooltip>
            <CloseIconDark onClick={() => handleRemove?.(card?.eid)} />
          </RemoveWrapper>
        )}
      </Flex>
      {!fromDeck && !isEdit && (
        <Flex align='center'>
          <div style={{ marginRight: '15px' }}>
            <PrimaryButton
              title={t('deck:add_to_deck')}
              type='button'
              variant='outline'
              onClick={handleChange}
            />
          </div>
          <div>
            <img
              src={previewIcon}
              style={{ cursor: 'pointer' }}
              onClick={() => previewHandler?.(card)}
              alt='preview'
            />
          </div>
        </Flex>
      )}
      {!fromDeck && isEdit && <img src={closeIcon} alt='close' />}
    </>
  );
};

export default ActionIcons;

ActionIcons.displayName = 'ActionIcons';

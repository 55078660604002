import React, { FC } from 'react';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { userObj } from 'sop-commons/src/client';

interface IProps {
  selected?: string;
  onChange?: (value: string) => void;
}

const RoleSelect: FC<IProps> = ({ selected, onChange }) => {
  const { t } = useTranslation(['role']);
  const roles = useReactiveVar(userObj)?.entity?.roles || [];

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        variant='outline'
        fontSize='14px'
        fontWeight='600'
        disabled={!roles?.length}
        size='lg'
        rightIcon={<ChevronDownIcon boxSize={5} />}
        border='1.5px solid #E3E1E5'
        gap='10px'
        px={4}
      >
        {selected || t('role:selectJob')}
      </MenuButton>

      <MenuList padding='0' overflowY='auto' maxHeight='230px'>
        {roles?.map((value) => {
          const isSelected = selected === value.name;
          return (
            <MenuItem
              key={value.eid}
              padding='10px'
              onClick={() => onChange?.(value.name)}
            >
              <Flex justify='space-between' align='center' width='full'>
                <Flex align='center' gap={2}>
                  <Text isTruncated maxWidth='150px'>
                    {value.name}
                  </Text>
                </Flex>
                {isSelected && (
                  <FontAwesomeIcon icon={faCheck as IconProp} color='#7caf6a' />
                )}
              </Flex>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default RoleSelect;

import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import PhaseCard, { IPhaseCard } from '../Singleton/PhaseCard';

interface IPhaseCardList {
  list: IPhaseCard[];
}

const PhaseCardList: FC<IPhaseCardList> = (props) => {
  const { list } = props;
  return (
    <Flex gap={4}>
      {list?.map((_l, index) => (
        <PhaseCard key={index} {..._l} />
      ))}
    </Flex>
  );
};

export default PhaseCardList;

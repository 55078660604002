export const detailStyle = (context) => {
  return {
    height: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    fontFamily: `${context.theme.fontFamily}`,
    '*': {
      boxSizing: 'border-box',
      fontFamily: `${context.theme.fontFamily}`,
    },
  };
};

export const headerStyle = (context) => {
  return {
    padding: '16px 24px',
    position: 'relative',
    // borderBottom: `1px solid ${context.theme.borderColor.primary}`,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '75.42px',
  };
};

export const headerCloseStyle = (img, context) => {
  const mq = [...context.theme.breakPoints];

  return {
    cursor: 'pointer',
    display: 'none',
    // mask: `url(${img}) center center no-repeat`,
    // backgroundColor: '#6F767E',
    width: '20px',
    height: '20px',
    marginRight: '8px',
    [`@media ${mq[1]}, ${mq[2]}, ${mq[3]}, , ${mq[4]}`]: {
      display: 'block',
    },
  };
};

export const headerTitleStyle = () => {
  return {
    margin: '0',
    fontWeight: '600',
    fontSize: '16px',
    color: '#1a1d1f',
    flex: '1 1 auto',
  };
};

export const detailPaneStyle = () => {
  return {
    margin: '0',
    padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: 'calc(100% - 70px)',
  };
};

export const sectionStyle = () => {
  return {
    width: '100%',
  };
};

export const sectionHeaderStyle = (context) => {
  return {
    margin: '0',
    width: '100%',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '2.29',
    textTransform: 'capitalize',
    color: '#6f767e',
  };
};

export const sectionContentStyle = () => {
  return {
    width: '100%',
    margin: '6px 0',
    '&:not(:last-of-type)': {
      marginBottom: '16px',
    },
  };
};

export const contentItemStyle = () => {
  return {
    position: 'relative',
    display: 'flex',
    clear: 'both',
    width: '100%',
    padding: '2px 0',
    '&:not(:last-child)': {
      marginBottom: '4px',
    },
  };
};

export const itemLinkStyle = (context, deleteLink) => {
  return {
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: '600',
    display: 'flex',
    gap: '10px',
    cursor: 'pointer',
    padding: '2px 0',
    paddingRight: '8px',
    color: `${deleteLink ? context.theme.color.red : '#1a1d1f'}`,
  };
};

export const notificationSettingBtnStyle = () => {
  return {
    width: '100%',
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    cursor: 'pointer',
    padding: '2px 0',
    paddingRight: '8px',
    color: '#1a1d1f',
  };
};

export const groupCloseStyle = () => {
  return {
    borderRadius: '36px',
    backgroundColor: '#efefef',
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  };
};

export const groupDetailNameContainerStyle = () => {
  return {
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
  };
};

export const groupDetailIconStyle = () => {
  return {
    width: '56px',
    height: '56px',
    borderRadius: '8px',
    // backgroundColor: '#d5d5d5',
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
};

export const groupDetailNameStyle = () => {
  return {
    fontSize: '18px',
    fontWeight: '600',
    color: '#1a1d1f',
  };
};

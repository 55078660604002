import { Flex, IconButton, Progress, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarLines,
  faCalendarXmark,
  faCheck,
  faCircleCheck,
  faRepeat,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCircleInfo,
  faClockFour,
  faEllipsis,
} from '@fortawesome/pro-regular-svg-icons';
import { faUpRightFromSquare, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { FC, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { IClickedHandlerType } from 'sub-components/TasksBoard/components/MyTasksTab/MyTasksTab';
import { RepeatDetails } from 'sub-components/tasks/create-task/task.types';
import { taskRepeat } from 'utils/taskRepeat';
import TaskDataContext from '../store/task-context';
import { ITaskListDataEntity } from '../tasks.types';
import { getDateInUserTimezone } from '../helper';
import { useTerminatedTaskModal } from './sub-components/TerminatedTaskModal';
import TaskStatusIcon from 'ui-components/TaskStatusIcon';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';
import { ActionMenu } from 'ui-components';

interface IProps {
  backToTitle?: string;
  task: ITaskListDataEntity;
  clickedHandler?: (type: IClickedHandlerType, data: any) => void;
  /** Used to truncate the repeat string. Default will be 'FALSE' */
  truncRepeat?: boolean;
}

const MyTaskItem: FC<IProps> = ({
  backToTitle,
  task,
  clickedHandler,
  truncRepeat,
}) => {
  const terminatedTaskModal = useTerminatedTaskModal();
  let showDetailedAnalysis =
    localStorage.getItem('showDetailedAnalysis') === 'true';
  const taskCtx = useContext(TaskDataContext);
  const history = useHistory();
  const getTaskStatus = useMemo((): ITaskStatus | undefined => {
    switch (task?.status) {
      case 'completed':
        return 'completed';
      case 'currentRunning':
        return 'inProgress';
      case 'dueToday':
        return 'inProgress';
      case 'overdue':
        return 'overdue';
      case 'partiallyDone':
        return 'partiallyCompleted';
      case 'scheduled':
      case 'upcoming':
        return 'scheduled';
      case 'thisWeek':
        return 'inProgress';
      default:
        return undefined;
    }
  }, [task]);
  const backgroundHandler = () => {
    let color = '';
    switch (task.status) {
      case 'overdue':
        color = '#FF6A551A';
        break;
      case 'dueToday':
        color = '#B1E5FC33';
        break;
      case 'thisWeek':
      case 'upcoming':
        color = '#EFEFEF';
        break;
      case 'completed':
        color = '#dcedd6';
        break;
      default:
        color = '#FF6A551A';
        break;
    }
    return color;
  };
  const borderHandler = () => {
    let border = '';
    switch (task.status) {
      case 'overdue':
        border = '1px solid #FF6A55';
        break;
      case 'dueToday':
        border = '1px solid #2A85FF';
        break;
      case 'thisWeek':
      case 'upcoming':
        border = '1px solid #6F767E';
        break;
      case 'completed':
        border = '1px solid #83BF6E';
        break;
      default:
        border = '1px solid #FF6A55';
        break;
    }
    return border;
  };

  const timeLeftHandler = () => {
    let duration = moment?.duration({
      hours: task?.hours,
      minutes: task?.minutes,
    });
    let _timeLeft = '';
    if (duration?.asHours() < 24) {
      const hours = duration?.hours();
      const minutes = duration?.minutes();
      _timeLeft = `${hours} h ${minutes} m`;
    } else {
      const targetTime = moment()?.add(duration);
      _timeLeft = targetTime?.fromNow(true);
    }
    // let timeLeft = '';
    // if (task.hours && task.hours > 0 && task.minutes && task.minutes > 0) {
    //   timeLeft += task.hours + 'h ' + task.minutes + 'm';
    // } else if (task.hours && task.hours > 0) {
    //   timeLeft += task.hours + 'h';
    // } else if (task.minutes && task.minutes > 0) {
    //   timeLeft += task.minutes + 'm';
    // }
    return _timeLeft ? (
      <Flex
        p='5px 10px'
        bg='#83BF6E1A'
        color='#83BF6E'
        borderRadius='8px'
        h='fit-content'
        gap='5px'
        align='center'
      >
        <FontAwesomeIcon icon={faClockFour as IconProp} />
        <Text m={0}>{_timeLeft}</Text>
      </Flex>
    ) : null;
  };

  const redirectHandler = () => {
    if (task?.taskDetails?.task?.status === 'TASK_TERMINATED') {
      terminatedTaskModal({
        btnTxt: 'Go back',
      });
    } else {
      let url = `/tasks/my-tasks/details/${task.eid}/${task?.sessionId}`;
      if (backToTitle) {
        history.push(url, { backToTitle: backToTitle });
      } else {
        history.push(url);
      }
    }
  };

  const actionHandler = (_: string, value: string) => {
    if (value === 'open') {
      return redirectHandler();
    }
    if (value === 'not-relevant') {
      taskCtx.setTaskNotRelevantSideDrawerDetails({
        ...taskCtx.taskNotRelevantSideDrawerDetails,
        open: true,
        selectedTask: task,
      });
      clickedHandler && clickedHandler('relevant', task);
      return;
    }
  };

  return (
    <>
      <Flex align='center' w='full' gap='20px' pl={1}>
        <Flex
          gap='7px'
          w='80%'
          justify='space-between'
          cursor='pointer'
          onClick={redirectHandler}
        >
          <Flex gap='10px'>
            {task?.status !== 'completed' ? (
              // <Box
              //   boxSize='18.5px'
              //   mt='7px'
              //   bg={backgroundHandler()}
              //   borderRadius='full'
              //   border={borderHandler()}
              // />
              <TaskStatusIcon
                status={getTaskStatus}
                iconStyles={{ marginTop: '8px' }}
                imgSize='18px'
              />
            ) : (
              <TaskStatusIcon
                status={
                  task?.taskDetails?.task?.status === 'TASK_TERMINATED'
                    ? 'terminated'
                    : 'completed'
                }
                iconStyles={{ marginTop: '4px' }}
                imgSize='18px'
              />
              // <Center
              //   h='18.5px'
              //   w='20.5px'
              //   mt='7px'
              //   borderRadius='full'
              //   border='1px solid #83BF6E'
              //   bg='#83BF6E1A'
              // >
              //   <FontAwesomeIcon
              //     icon={
              //       (task?.taskDetails?.task?.status === 'TASK_TERMINATED'
              //         ? faXmark
              //         : faCheck) as IconProp
              //     }
              //     color='#83BF6E'
              //     fontSize={'12px'}
              //   />
              // </Center>
            )}
            <Flex flexDirection='column' py={1}>
              <Text m={0} isTruncated maxW='600px'>
                {task?.title}
              </Text>
              <Flex gap='10px' align='center'>
                <Flex
                  gap='5px'
                  id='task-list-item'
                  align='center'
                  borderRadius='5px'
                  h='fit-content'
                  // bg={
                  //   task?.status === 'overdue'
                  //     ? '#FF6A551A'
                  //     : task?.status === 'dueToday'
                  //     ? '#33383F12'
                  //     : '#83BF6E1A'
                  // }
                  color={
                    task?.status === 'overdue'
                      ? '#FF6A55'
                      : task?.status === 'dueToday'
                      ? '#2A85FF'
                      : task?.status === 'completed'
                      ? '#83BF6E'
                      : '#2985ff'
                  }
                >
                  {!showDetailedAnalysis ? (
                    <Flex align='center' gap='5px' fontSize='12px'>
                      <FontAwesomeIcon
                        icon={
                          (task?.taskDetails?.task?.status === 'TASK_TERMINATED'
                            ? faCalendarXmark
                            : faCalendarLines) as IconProp
                        }
                      />
                      {/* <Text m={0}>{task?.date}</Text> */}
                      <Text m={0}>
                        {task?.taskDetails?.task?.status !== 'TASK_TERMINATED'
                          ? task?.status === 'completed'
                            ? `Completed on ${task?.date}`
                            : task?.date
                          : `Terminated on ${getDateInUserTimezone(
                              task?.taskDetails?.task?.terminateDetails?.date
                            )?.format('MMM DD')}`}
                      </Text>
                      <>
                        <Text m={0}>&bull;</Text>
                        <FontAwesomeIcon icon={faRepeat as IconProp} />
                        <Text
                          m={0}
                          textTransform='capitalize'
                          isTruncated={truncRepeat}
                          maxW={truncRepeat ? '100px' : 'unset'}
                        >
                          {/* {task?.repeatString} */}
                          {taskRepeat(
                            task?.repeatDetails as RepeatDetails<Date>
                          )}
                        </Text>
                      </>
                      {/* {task?.repeatString && (
                        <>
                          <Text m={0}>&bull;</Text>
                          <FontAwesomeIcon icon={faRepeat as IconProp} />
                          <Text textTransform='capitalize' m={0}>
                            {task?.repeatString}
                          </Text>
                        </>
                      )}
                    )} */}
                      {task?.isMissed && (
                        <Flex color='red' align='center' gap='5px'>
                          <Text m={0}>&bull;</Text>
                          <FontAwesomeIcon icon={faCalendarXmark as IconProp} />
                          <Text textTransform='capitalize' m={0}>
                            Missed on {task?.isMissed}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  ) : (
                    <Flex id='detailed-analysis' flexDir='column'>
                      <Flex>
                        <Text as='b'>Timezone:&nbsp;</Text>
                        {task?.detailedAnalysis?.timezone}
                      </Flex>
                      <Flex>
                        <Text as='b'>Now:&nbsp;</Text>
                        {task?.detailedAnalysis?.now}
                      </Flex>
                      <Flex>
                        <Text as='b'>Now UTC:&nbsp;</Text>
                        {task?.detailedAnalysis?.nowUTC}
                      </Flex>
                      <Flex>
                        <Text as='b'>My Local Time (UTC)&nbsp;</Text>
                        {task?.detailedAnalysis?.myLocalUTC}
                      </Flex>
                      <Flex>
                        <Text as='b'>My Local Time&nbsp;</Text>
                        {task?.detailedAnalysis?.myLocal}
                      </Flex>
                      <Flex>
                        <Text as='b'>Backend Start Date (UTC):&nbsp;</Text>
                        {task?.detailedAnalysis?.backendTaskStartDate}
                      </Flex>
                      <Flex>
                        <Text as='b'>Backend Due Date (UTC):&nbsp;</Text>
                        {task?.detailedAnalysis?.backendTaskDueDate}
                      </Flex>
                      <Flex>
                        <Text as='b'>Task Start Date:&nbsp;</Text>
                        {task?.detailedAnalysis?.taskStartDate}
                      </Flex>
                      <Flex>
                        <Text as='b'>Task Due Date:&nbsp;</Text>
                        {task?.detailedAnalysis?.taskDueDate}
                      </Flex>
                      <Flex>
                        <Text as='b'>Is Last Session:&nbsp;</Text>
                        {task?.detailedAnalysis?.isLastSession
                          ? 'true'
                          : 'false'}
                      </Flex>
                      <Flex>
                        <Text as='b'>Is Repeating:&nbsp;</Text>
                        {task?.detailedAnalysis?.isRepeating ? 'true' : 'false'}
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex align='center'>
            {/* {task.status === 'dueToday' && timeLeftHandler()} */}
            {task.status !== 'overdue' &&
              task.status !== 'completed' &&
              timeLeftHandler()}
          </Flex>
        </Flex>
        <Flex w='20%' align='center' justify='space-between'>
          <Flex align='flex-start' flexDir='column'>
            <Flex gap='5px' align='center'>
              <FontAwesomeIcon
                icon={faCircleCheck as IconProp}
                color='#92929D'
                fontSize='16px'
              />
              <Text color='#92929D' fontSize='14px' m={0}>
                {task?.countData.completedCount}/{task?.countData.totalCount}
              </Text>
            </Flex>
            <Progress
              colorScheme='green'
              size='xs'
              value={
                +(
                  (task.countData.completedCount / task.countData.totalCount) *
                  100
                ).toFixed(0)
              }
              w='100px'
              borderRadius='5px'
            />
          </Flex>

          <ActionMenu
            menuData={[
              {
                name: 'Open',
                value: 'open',
                icon: faUpRightFromSquare,
                normalIcon: 'fontAwesome',
              },
              {
                name: 'Not relevant?',
                value: 'not-relevant',
                icon: faCircleInfo,
                normalIcon: 'fontAwesome',
                textColor: '#FF6A55',
                iconColor: '#FF6A55',
              },
            ]}
            clickedItem={actionHandler}
          >
            <IconButton
              aria-label='Options'
              icon={
                <FontAwesomeIcon
                  icon={faEllipsis as IconProp}
                  fontSize='20px'
                />
              }
              variant='none'
            />
          </ActionMenu>
        </Flex>
      </Flex>
    </>
  );
};

export default MyTaskItem;

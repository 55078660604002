import { useBoolean } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { CometChat } from '@cometchat-pro/chat';

export const useGetGroup = () => {
  const [loading, action] = useBoolean();
  const [channel, setChannel] = useState<CometChat.Group>();

  const fetchGroup = useCallback(async (groupId: string) => {
    action.on();

    await CometChat.getGroup(groupId);

    try {
      // await _fetch(request);
      const res = await CometChat.getGroup(groupId);
      action.off();
      setChannel(res);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      action.off();
    }
  }, []);

  return {
    fetchGroup: fetchGroup,
    groupLoading: loading,
    group: channel,
  };
};

import React, { PropsWithChildren } from 'react';
import {
  ColumnInstance,
  HeaderProps as NativeHeader,
  UseSortByColumnOptions,
} from 'react-table';
import { Tooltip } from '@chakra-ui/react';

interface IProps<T extends object> extends NativeHeader<T> {
  label?: string;
  column: ColumnInstance<T> & UseSortByColumnOptions<T>;
}

const ToolTipHeader = <T extends object>(
  props: PropsWithChildren<IProps<T>>
): React.ReactElement => {
  const column = props.column;

  if (column.disableSortBy) {
    return <>{props.children}</>;
  }

  return (
    <Tooltip
      label={props.label || `Click here to sort by ${props.children}`}
      hasArrow
      maxWidth='150px'
      borderRadius='6px'
      padding='4px 8px'
    >
      {/*// @ts-ignore*/}
      {props.children}
    </Tooltip>
  );
};

export default ToolTipHeader;

import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { get } from 'react-hook-form';

export const TaskItemWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: start;
  gap: 8px;
  padding-bottom: 16px;
  padding-top: 8px;
  padding-inline: 16px;
  background: #ffffff;
  border-radius: 12px;

  .more-action {
    //position: absolute;
    //right: 8px;
    //top: 8px;
  }

  .more-action:has(> button[aria-expanded='true']) {
    visibility: visible;
  }

  &:hover {
    box-shadow: ${(props) => get(props.theme, 'shadows.lg')};

    .more-action {
      visibility: visible;
    }
  }
`;

import React, { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Heading: FC = () => {
  const { t } = useTranslation(['sidebar']);
  return (
    <Box display={'flex'} justifyContent={'space-between'} width={'full'}>
      <Text fontSize={'20px'} fontWeight={'600'}>
        {t('sidebar:knowledge_base')}
      </Text>
      {/* <Box width={'fit-content'} display={'flex'} gap={'1rem'}>
            <Checkbox
              isChecked={trainingPathCtx?.isCardCheckboxSelected}
              onChange={() => {
                trainingPathCtx?.setIsCardCheckboxSelectedHandler(
                  !trainingPathCtx?.isCardCheckboxSelected
                );
                trainingPathCtx?.setIsDeckCheckboxSelectedHandler(false);
              }}
            >
              {t('cards')}
            </Checkbox>
            <Checkbox
              isChecked={trainingPathCtx?.isDeckCheckboxSelected}
              onChange={() => {
                trainingPathCtx?.setIsDeckCheckboxSelectedHandler(
                  !trainingPathCtx?.isDeckCheckboxSelected
                );
                trainingPathCtx?.setIsCardCheckboxSelectedHandler(false);
              }}
            >
              {t('decks')}
            </Checkbox>
          </Box> */}
    </Box>
  );
};

export default Heading;

import React, { useState } from 'react';

function isThenable(things) {
  return !!(things && !!things.then);
}

const ActionButton = ({ children, onClick, style, color, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false);

  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const handlePromise = (returnValue) => {
    if (!isThenable(returnValue)) {
      return null;
    }
    setIsLoading(true);
    returnValue?.then(
      (...args) => {
        mounted.current && setIsLoading(false);
      },
      (e) => {
        mounted.current && setIsLoading(false);
        // eslint-disable-next-line no-console
        console.error(e);
      }
    );
  };

  const internalOnClick = () => {
    if (!onClick) {
      return null;
    }

    let returnValue;
    if (onClick.length) {
      returnValue = onClick(close);
    } else {
      returnValue = onClick();
      if (!returnValue) {
        return null;
      }
    }
    handlePromise(returnValue);
  };

  return (
    <div
      {...rest}
      data-loading={isLoading}
      onClick={isLoading ? undefined : internalOnClick}
    >
      {children}
    </div>
  );
};

export default ActionButton;

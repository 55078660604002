import React, { FC } from 'react';
import { RadioGroup, Stack } from '@chakra-ui/react';

interface IProps {
  value?: string;
  onChange?: (nextValue: string) => void;
}

const BaseRadioGroup: FC<IProps> = ({ value, onChange, children }) => {
  return (
    <RadioGroup onChange={onChange} value={value}>
      <Stack
        spacing={4}
        sx={{
          '.chakra-radio__label': {
            color: '#33383F',
            fontSize: 14,
            fontWeight: 400,
            _checked: {
              color: '#2A85FF',
              fontWeight: 500,
            },
          },
          '.chakra-radio__control': {
            _checked: {
              borderColor: 'blue.500',
              background: 'white',
              color: 'blue.500',
              _hover: {
                background: 'white',
              },
            },
          },
        }}
      >
        {children}
      </Stack>
    </RadioGroup>
  );
};

export default BaseRadioGroup;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';

interface IProps {
  lastUpdated?: string;
  name?: string;
}

const ChapterFooter: FC<IProps> = ({ name, lastUpdated }) => {
  const [width, setWidth] = useState(875);

  const agoDate = useMemo(() => {
    if (lastUpdated) {
      return moment(lastUpdated).fromNow();
    }
    return null;
  }, [lastUpdated]);

  useEffect(() => {
    const element = document.getElementById('chapter-view-container');
    const myObserver = new ResizeObserver((entries) => {
      const W = entries[0]?.contentRect?.width;
      W && setWidth(W + 32);
    });

    element && myObserver.observe(element);

    return () => {
      element && myObserver.unobserve(element);
    };
  }, []);

  if (!name) return null;

  return (
    <Flex
      pos='sticky'
      bottom='-17px'
      height='60px'
      marginLeft='-16px'
      maxWidth={width}
      width='calc(100% + 32px)'
      align='flex-end'
      pb='20px'
      borderBottomRadius='8px'
      bg='linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0.1) 100%)'
      style={{
        transformOrigin: '0 0',
      }}
    >
      <Flex
        fontSize='14px'
        fontWeight='400'
        color='#111315'
        gap='4px'
        px='36px'
      >
        <Box>{name}</Box>
        <Box>edited</Box>
        <Box>{agoDate}</Box>
      </Flex>
    </Flex>
  );
};

export default ChapterFooter;

import React, { FC, ReactNode } from 'react';
import { Box, Spinner, Flex } from '@chakra-ui/react';

interface LoaderOverlayProps {
  isLoading: boolean;
  children: ReactNode;
}

const LoaderOverlay: FC<LoaderOverlayProps> = ({ isLoading, children }) => {
  return (
    <Box position='relative'>
      {children}
      {isLoading && (
        <Flex
          position='absolute'
          top={0}
          left={0}
          right={0}
          bottom={0}
          justifyContent='center'
          alignItems='center'
          backgroundColor='rgba(255, 255, 255, 0.8)' // Semi-transparent backdrop
          zIndex='modal' // Ensure it's above other content
        >
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Flex>
      )}
    </Box>
  );
};

export default LoaderOverlay;

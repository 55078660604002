import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { toArray } from '../../../../../../utils/utils';

import { DataNotFound } from '../common';

import FormListItem from './FormListItem';
import { fetchFormsQuery, FormResponse } from '../formChapter.graphql';

import EmptyDataIcon from '../../../../../../assets/images/empty-state/task-form-empty-state.svg';

interface IProps {
  values: string[];
  searchQuery?: string;
}

const FormListContainer: FC<IProps> = ({ values = [], searchQuery }) => {
  const { data, loading } = useQuery<FormResponse>(fetchFormsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      perPage: values.length + 20,
      filter: {
        status: 'active',
        query: searchQuery,
      },
      sort: '_ID_DESC',
    },
  });

  const itemList = useMemo(() => {
    return toArray(data?.FormsPagination?.items).filter((value) => {
      return !values?.includes(value.eid);
    });
  }, [data, values]);

  return (
    <Flex
      minH={{
        base: '260px',
        lg: '45vh',
      }}
      flex={1}
      flexDir='column'
      overflow='auto'
      position='relative'
      gap='1px'
    >
      <DataNotFound
        isLoading={loading}
        searchQuery={searchQuery}
        dataLength={itemList?.length}
        i18NotFoundKey='form:no_form_found'
        i18NotFoundDescKey='task:noFormDesc'
        i18SearchNotFoundKey='form:noFormMatchFound'
        emptyImage={EmptyDataIcon}
      >
        {itemList.map((item, index) => (
          <FormListItem key={item.eid} data={item} index={index} />
        ))}
      </DataNotFound>
    </Flex>
  );
};

export default FormListContainer;

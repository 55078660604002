import React from 'react';
import {
  IAllFilterSelection,
  INotRelevantUserDetails,
  IPerformingLocationStats,
  ISupervisedListData,
  ISupervisedTasksEntity,
  ITaskListData,
  ITaskListDataEntity,
  ITaskNotRelevantSideDrawerDetails,
  ITaskOverview,
  ITaskSortEntity,
  IWeeklyMonthlySelection,
  TaskRoutes,
} from '../tasks.types';

export interface ITaskDataContext {
  selectedRoute: TaskRoutes;
  myTasks: {
    taskOverviewData: ITaskOverview[];
    myTaskDetails: ITaskListData[];
  };
  dashboardData: {
    performingLocationStats: IPerformingLocationStats[];
    jobPerformanceData: {
      sortBy: ITaskSortEntity;
      isExpand: boolean;
      filterSelection: IAllFilterSelection;
      graphData: any[];
    };
    locationPerformanceData: {
      sortBy: ITaskSortEntity;
      isExpand: boolean;
      filterSelection: IAllFilterSelection;
      graphData: any[];
    };
    performanceOverviewData: {
      sortBy: ITaskSortEntity;
      filterSelection: IAllFilterSelection;
      userData: any[];
      viewAll: boolean;
    };
    locationWiseTaskCompletionRate: {
      dropdownLocations: any[];
      filterSelection: IWeeklyMonthlySelection;
      selectedLocations: any[];
      graphData: any[];
    };
  };
  taskNotRelevantSideDrawerDetails: ITaskNotRelevantSideDrawerDetails;
  supervisedByMe: {
    landing: {
      taskOverviewData: ITaskOverview[];
      supervisedTaskDetails: ISupervisedListData[];
    };
    detailsPage: {
      taskDetails: ISupervisedTasksEntity;
    };
  };
  taskOverviewDataHandler: (data: ITaskOverview[]) => void;
  selectedRouteHandler: (route: TaskRoutes) => void;
  myTaskDetailsHandler: (data: ITaskListData[]) => void;
  setTaskNotRelevantSideDrawerDetails: (
    data: ITaskNotRelevantSideDrawerDetails
  ) => void;
  dashboardDataHandler: (data: any) => void;
  supervisedTaskOverviewDataHandler: (data: ITaskOverview[]) => void;
  supervisedTaskDetailsHandler: (data: ISupervisedListData[]) => void;
  taskSupervisedDetailsHandler: (data: ISupervisedTasksEntity) => void;
}

const TaskDataContext = React.createContext<ITaskDataContext>({
  selectedRoute: 'my-tasks',
  myTasks: {
    taskOverviewData: [],
    myTaskDetails: [],
  },
  dashboardData: {
    performingLocationStats: [],
    jobPerformanceData: {
      sortBy: 'asc',
      isExpand: false,
      filterSelection: 'all',
      graphData: [],
    },
    locationPerformanceData: {
      sortBy: 'asc',
      isExpand: false,
      filterSelection: 'all',
      graphData: [],
    },
    performanceOverviewData: {
      sortBy: 'asc',
      filterSelection: 'all',
      userData: [],
      viewAll: false,
    },
    locationWiseTaskCompletionRate: {
      dropdownLocations: [],
      filterSelection: 'weekly',
      selectedLocations: [],
      graphData: [],
    },
  },
  taskNotRelevantSideDrawerDetails: {
    open: false,
    searchString: '',
    userDetails: [],
    selectedUser: {} as INotRelevantUserDetails,
    selectedTask: {} as ITaskListDataEntity,
  },
  supervisedByMe: {
    landing: {
      taskOverviewData: [],
      supervisedTaskDetails: [],
    },
    detailsPage: {
      taskDetails: {} as ISupervisedTasksEntity,
    },
  },
  taskOverviewDataHandler: (data: ITaskOverview[]) => {},
  selectedRouteHandler: (route: TaskRoutes) => {},
  myTaskDetailsHandler: (data: ITaskListData[]) => {},
  setTaskNotRelevantSideDrawerDetails: (
    data: ITaskNotRelevantSideDrawerDetails
  ) => {},
  dashboardDataHandler: (data: any) => {},
  supervisedTaskOverviewDataHandler: (data: ITaskOverview[]) => {},
  supervisedTaskDetailsHandler: (data: ISupervisedListData[]) => {},
  taskSupervisedDetailsHandler: (data: ISupervisedTasksEntity) => {},
});

export default TaskDataContext as React.Context<ITaskDataContext>;

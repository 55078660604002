import { useMutation } from '@apollo/client';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FormInput, PrimaryButton } from 'atoms';
import { useFolderSubFolder } from 'hooks';
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { getFoldersList } from 'shared/graphql/SharedGraphql';
import { HeaderColors } from 'shared/headerColors/header-colors';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import FolderIconUpload from 'sub-components/FolderIconUpload';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import {
  UpdateCategoryQuery,
  UpdateCategoryResponse,
  UpdateCategoryVariable,
} from 'sub-components/Sidebar/category/add-category.graphql';
import {
  useCustomHistory,
  useCustomRouteMatch,
} from 'sub-components/Sidebar/category/hook';
import { GET_FOLDERS_LIST } from '../chapters.graphql';

export interface EditFolderRef {
  initialise: (values: IFormInput) => void;
  folderIconDetails: (data: { icon: string; color: string }) => void;
  updateSubFolderData?: (data: ISubFolderData) => void;
}

interface IFormInput {
  category: string;
  oldCategory?: string;
  categoryId: string;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface ISubFolderData {
  isSubFolder: boolean;
  parentFolderName: string;
}

const EditFolder = forwardRef<EditFolderRef, IProps>((props, ref) => {
  const { isOpen, onClose } = props;
  const editRef = useRef<IFormInput | null>(null);
  const routeMatch = useCustomRouteMatch<{ category: string }>();
  const history = useCustomHistory();
  const { t } = useTranslation(['chapter', 'common']);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const [loading, setLoading] = useState(false);
  const [subFolderData, setSubFolderData] = useState<
    ISubFolderData | undefined
  >(undefined);
  const [selectedIconUrl, setSelectedIconUrl] = useState('');
  const [selectedFolderColor, setSelectedFolderColor] = useState('');
  const [oldSelectedIconUrl, setOldSelectedIconUrl] = useState('');
  const [oldSelectedFolderColor, setOldSelectedFolderColor] = useState('');
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
    getValues,
    setValue,
  } = useForm<IFormInput>();
  const oldNameWatch = useWatch<IFormInput, 'oldCategory'>({
    control: control,
    name: 'oldCategory',
  });
  const newNameWatch = useWatch<IFormInput, 'category'>({
    control: control,
    name: 'category',
  });
  const { getAllFolderSubFolderList } = useFolderSubFolder();

  const allFoldersList = useMemo(() => {
    return getAllFolderSubFolderList();
  }, [getAllFolderSubFolderList]);

  useImperativeHandle(
    ref,
    () => {
      return {
        initialise: (values: IFormInput) => {
          reset(values);
          setValue('oldCategory', values.category);
        },
        folderIconDetails: (data) => {
          setSelectedFolderColor(data.color);
          setSelectedIconUrl(data.icon);
          setOldSelectedFolderColor(data.color);
          setOldSelectedIconUrl(data.icon);
        },
        updateSubFolderData: (data: ISubFolderData) => {
          setSubFolderData({
            isSubFolder: data?.isSubFolder,
            parentFolderName: data?.parentFolderName,
          });
        },
      };
    },
    [reset]
  );

  const onFoldersFetchSuccess = () => {
    toast({
      status: 'success',
      title: 'Folder update successfully',
    });
    setLoading(false);
    onClose();
  };

  const { execute: fetchFoldersList } = getFoldersList(onFoldersFetchSuccess);

  const [updateCategory] = useMutation<
    UpdateCategoryResponse,
    UpdateCategoryVariable
  >(UpdateCategoryQuery, {
    onCompleted: () => {
      // if (editRef.current?.category && routeMatch && history) {
      //   const selectedCategory = routeMatch?.params?.category;
      //   if (selectedCategory === editRef.current?.oldCategory) {
      //     const newPath = routeMatch.url?.replace(
      //       selectedCategory,
      //       editRef.current.category
      //     );
      //     const oldState = history.location.state as any;
      //     if (oldState?.category === editRef.current.oldCategory) {
      //       oldState.category = history.location.state;
      //     }
      //     history.replace(newPath, history.location.state);
      //   }
      //   editRef.current = null;
      // }
      fetchFoldersList();
    },
    onError: (error) => {
      console.log(error);
      toast({
        status: 'error',
        title: 'Folder could not be updated',
      });
      setLoading(false);
      onClose();
    },
    // refetchQueries: [{ query: GET_FOLDERS_LIST }],
    // awaitRefetchQueries: true,
  });

  const submitHandler = (value: IFormInput) => {
    deployEvent(AmplitudeEventNames.EDIT_FOLDER_FROM_MODAL);
    setLoading(true);
    updateCategory({
      variables: {
        category: value.category,
        categoryId: value.categoryId,
        color: selectedFolderColor || '#f6f6f6',
        icon: selectedIconUrl || '',
        // ...(selectedFolderColor ? { color: selectedFolderColor } : {}),
        // ...(selectedIconUrl ? { icon: selectedIconUrl } : {}),
        // oldCategory: value.oldCategory,
        // newCategory: value.category,
      },
    });
  };

  const btnDisableFn = () => {
    if (isSubmitting || loading || !newNameWatch) {
      return true;
    }
    if (
      oldNameWatch === newNameWatch &&
      oldSelectedFolderColor === selectedFolderColor &&
      oldSelectedIconUrl === selectedIconUrl
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      size='md'
    >
      <ModalOverlay />
      <ModalContent p={4} borderRadius='16px'>
        <ModalHeader>
          <GenericColorHeader
            color={HeaderColors.Green}
            title={
              subFolderData?.isSubFolder ? 'Rename subfolder' : 'Edit Folder'
            }
          />
          <ModalCloseButton m={4} />
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Controller
              name='category'
              control={control}
              defaultValue=''
              rules={{
                required: t('chapter:validation.folderRequired'),
                validate: (value) => {
                  if (
                    allFoldersList?.some(
                      (list) =>
                        list?.name?.trim() === value?.trim() &&
                        list?.eid !== getValues('categoryId')
                    )
                  ) {
                    return 'Name already exists';
                  }
                },
              }}
              render={({ field, fieldState }) => (
                <FormControl isInvalid={!!fieldState.error}>
                  <Flex flexDir='column'>
                    {subFolderData?.isSubFolder && (
                      <TitleHeader title='Subfolder name' />
                    )}
                    <Flex align='center' gap='5px'>
                      <FolderIconUpload
                        selectedFolderColor={selectedFolderColor}
                        setSelectedFolderColor={setSelectedFolderColor}
                        selectedIconUrl={selectedIconUrl}
                        setSelectedIconUrl={setSelectedIconUrl}
                      />
                      <FormInput
                        id='category'
                        size='lg'
                        placeholder={t('chapter:folderName')}
                        {...field}
                      />
                    </Flex>
                    {subFolderData?.parentFolderName && (
                      <Flex align='center'>
                        in&nbsp;
                        <Text fontWeight={700}>
                          {subFolderData?.parentFolderName}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                  <FormErrorMessage>
                    <span style={{ marginLeft: '50px' }}>
                      {fieldState.error?.message}
                    </span>
                  </FormErrorMessage>
                </FormControl>
              )}
            />
            <PrimaryButton
              type='submit'
              size='lg'
              title={t('common:update')}
              style={{ marginTop: '16px' }}
              variant='solid'
              colorScheme='blue'
              isLoading={isSubmitting || loading}
              disabled={btnDisableFn()}
            />
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

EditFolder.displayName = 'Pages/Chapters/EditFolder';

export default EditFolder;

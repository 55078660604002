import React, { FC, Fragment } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  title: string;
}

const Section: FC<IProps> = ({ title }) => {
  return (
    <Fragment>
      <Center
        gap={3}
        pt='12px'
        pb='12px'
        bg='#EEEEEE'
        borderRadius='7px'
        px={4}
        my='12px'
      >
        <FontAwesomeIcon
          icon={faListUl as IconProp}
          fontSize='20px'
          color='#6F767E'
        />
        <Box
          fontWeight='600'
          lineHeight='24px'
          color='#000000'
          // textTransform={'uppercase'}
        >
          {title}
        </Box>
      </Center>
    </Fragment>
  );
};

export default Section;

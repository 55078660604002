import { TFunction } from 'i18next';
import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import { TrainingData } from 'pages/Training/training.types';
import { ChangeLogTableWrapper } from 'pages/TrainingDraft/ChangeLog/ChangeLogList/ChangeLogTable/ChangeLogTable.styles';
import {
  IChangeEventEntity,
  IChangeLogLocationsEntity,
  IChangeLogTableChangesEntity,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import React, { useEffect, useState } from 'react';
import { IPublishChangeDiffCheckTable } from '../PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import { Tooltip } from '@chakra-ui/react';

import { getImage } from '../../../../utils';

interface IAssigneesComponent {
  changedData: IPublishChangeDiffCheckTable[];
  trainingData: TrainingData;
  initialTrainingData: TrainingData;
  thirdStepData: IThirdStepData;
  t: TFunction<('common' | 'training')[], undefined, ('common' | 'training')[]>;
}

interface ICombinedChangedTrainingData {
  changes: ICreateChangeLogChangesEntity;
  name: string;
  icon: string;
}

const AssigneesComponent = ({
  changedData,
  trainingData,
  initialTrainingData,
  thirdStepData,
  t,
}: IAssigneesComponent) => {
  const [contentDetails, setContentDetails] = useState<
    ICreateChangeLogChangesEntity[]
  >([]);

  useEffect(() => {
    // if (trainingData?.contentDetails && initialTrainingData?.contentDetails) {
    let _contentDetails: ICreateChangeLogChangesEntity[] = [];
    let _assigneesAdded: IChangeLogLocationsEntity[] = [];
    let _assigneesRemoved: IChangeLogLocationsEntity[] = [];
    changedData?.map((change) => {
      if (
        change?.tableType === 'assigneeAdded' ||
        change?.tableType === 'assigneeRemoved'
      ) {
        change?.tableData?.changes?.map((_change) => {
          let foundAssignee = thirdStepData?.members?.find(
            (member) => member?.id === _change?.userId
          );
          if (foundAssignee) {
            if (change?.tableType === 'assigneeAdded') {
              _assigneesAdded?.push({
                eid: foundAssignee?.id,
                name: foundAssignee?.value,
                profilePic: getImage(foundAssignee?.url, foundAssignee?.value),
              });
            } else {
              _assigneesRemoved?.push({
                eid: foundAssignee?.id,
                name: foundAssignee?.value,
                profilePic: getImage(foundAssignee?.url, foundAssignee?.value),
              });
            }
          }
        });
        _contentDetails?.push({
          changeElement: 'assignee',
          changeEvent: change?.tableType,
          assignees:
            change?.tableType === 'assigneeAdded'
              ? _assigneesAdded
              : _assigneesRemoved,
        });
      }
    });
    // changedData?.map(change => {
    //   if (change?.tableType === 'assigneeAdded') {
    //     change?.tableData?.changes?.map(_change => {
    //       let foundAssignee = thirdStepData?.members?.find(member => member?.id === _change?.userId);
    //       if (foundAssignee) {
    //         _contentDetails.push({
    //           changes: {
    //             changeElement: _change?.changeElement,
    //             changeEvent: _change?.changeEvent,
    //             contentId: _change?.contentId
    //           },
    //           icon: getImage(foundAssignee?.url, foundAssignee?.value),
    //           name: foundAssignee?.value
    //         })
    //       }
    //       // trainingData?.assignedToUsers?.map(detail => {
    //       //   if (_change?.userId === detail?.eid) {
    //       //     _contentDetails.push({
    //       //       changes: {
    //       //         changeElement: _change?.changeElement,
    //       //         changeEvent: _change?.changeEvent,
    //       //         contentId: _change?.contentId,
    //       //       },
    //       //       icon: getImage(detail?.profilePic, detail?.name),
    //       //       name: detail?.name
    //       //     })
    //       //   }
    //       // })
    //     })
    //   } else if (change?.tableType === 'assigneeRemoved') {
    //     change?.tableData?.changes?.map(_change => {
    //       let foundAssignee = thirdStepData?.members?.find(member => member?.id === _change?.userId);
    //       if (foundAssignee) {
    //         _contentDetails.push({
    //           changes: {
    //             changeElement: _change?.changeElement,
    //             changeEvent: _change?.changeEvent,
    //             contentId: _change?.contentId
    //           },
    //           icon: getImage(foundAssignee?.url, foundAssignee?.value),
    //           name: foundAssignee?.value
    //         })
    //       }
    //       // initialTrainingData?.assignedToUsers?.map(detail => {
    //       //   if (_change?.userId === detail?.eid) {
    //       //     _contentDetails.push({
    //       //       changes: {
    //       //         changeElement: _change?.changeElement,
    //       //         changeEvent: _change?.changeEvent,
    //       //         contentId: _change?.contentId,
    //       //       },
    //       //       icon: getImage(detail?.profilePic, detail?.name),
    //       //       name: detail?.name
    //       //     })
    //       //   }
    //       // })
    //     })
    //   }
    // })
    setContentDetails(_contentDetails);
    // }
  }, [trainingData?.contentDetails, initialTrainingData?.contentDetails]);

  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className='width-30'>{t('training:assignees_table_heading')}</th>
          <th>{t('common:update')}</th>
        </tr>
      </thead>
    );
  };

  const getChangeNameHandler = (eventType: IChangeEventEntity) => {
    switch (eventType) {
      case 'assigneeAdded':
        return t('training:assignee_add_publish_change');
      case 'assigneeRemoved':
        return t('training:assignee_delete_publish_change');
    }
  };

  const getMoreLocationStrings = (assignees: IChangeLogLocationsEntity[]) => {
    let strArr: string[] = [];
    assignees?.map((location, index) => {
      if (index > 1) {
        strArr.push(location?.name);
      }
    });
    return strArr.join(', ');
  };

  const getMoreAssignees = (
    assignees: IChangeLogLocationsEntity[],
    type: 'add' | 'remove'
  ) => {
    let count = 0;
    assignees?.map((assignee, index) => {
      if (index > 1) {
        count++;
      }
    });
    return (
      <Tooltip hasArrow label={getMoreLocationStrings(assignees)}>
        <div
          className={
            type === 'add'
              ? 'status new-status cursor-pointer'
              : 'status old-status cursor-pointer'
          }
        >
          <span
            className={
              type === 'add'
                ? 'status-text new-status-text'
                : 'status-text old-status-text'
            }
          >
            +{count} more
          </span>
        </div>
      </Tooltip>
    );
  };

  const updatedComparisonsHandler = (
    eventType: IChangeEventEntity,
    assignees: IChangeLogLocationsEntity[]
  ) => {
    switch (eventType) {
      case 'assigneeAdded':
        return (
          <>
            {assignees?.map((assignee, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status new-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={assignee?.profilePic}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text new-status-text'>
                      {assignee?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {assignees &&
              assignees?.length > 2 &&
              getMoreAssignees(assignees, 'add')}
          </>
        );
      // return (
      //   <>
      //     <div className='user-status'>
      //       <img
      //         src={icon}
      //         style={{ borderRadius: '5px' }}
      //       />
      //     </div>
      //     <div className='status new-status'>
      //       <span className='status-text new-status-text'>
      //         {name}
      //       </span>
      //     </div>
      //   </>
      // )
      case 'assigneeRemoved':
        return (
          <>
            {assignees?.map((assignee, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status old-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={assignee?.profilePic}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text old-status-text'>
                      {assignee?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {assignees &&
              assignees?.length > 2 &&
              getMoreAssignees(assignees, 'remove')}
          </>
        );
      // return (
      //   <>
      //     <div className='user-status'>
      //       <img
      //         src={icon}
      //         style={{ borderRadius: '5px' }}
      //       />
      //     </div>
      //     <div className='status old-status'>
      //       <span className='status-text old-status-text'>
      //         {name}
      //       </span>
      //     </div>
      //   </>
      // )
    }
  };

  const getTableBody = (contentDetails: ICreateChangeLogChangesEntity[]) => {
    return contentDetails?.map((content, index) => {
      return (
        <tr key={JSON.stringify(content) + index}>
          <td>{getChangeNameHandler(content?.changeEvent)}</td>
          <td>
            <div className='status-cell'>
              {updatedComparisonsHandler(
                content?.changeEvent,
                content?.assignees || []
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      {contentDetails?.length > 0 ? (
        <ChangeLogTableWrapper>
          <table>
            {getTableHeader()}
            {getTableBody(contentDetails)}
          </table>
        </ChangeLogTableWrapper>
      ) : null}
    </>
  );
};

export default AssigneesComponent;

import React, { FC } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@chakra-ui/icons';

import LocationSelectContainer from './LocationSelectContainer';
import { AssignedLocationsType } from '../../task.types';
import LocationChips from './LocationChips';

interface IProps {
  value?: string[];
  onChange?: (value: string[], selectedType: AssignedLocationsType) => void;
}

const LocationSelect: FC<IProps> = ({ value, onChange }) => {
  const { t } = useTranslation(['task']);

  return (
    <Box pos='relative' mt={2}>
      <Popover matchWidth>
        {/*// @ts-ignore */}
        <PopoverTrigger>
          <Flex
            fontWeight='600'
            color='#1A1D1F'
            borderRadius='12px'
            bg='#F4F4F4'
            p='12px 16px'
            lineHeight='24px'
          >
            {t('task:selectAssignees')}
            <Spacer /> <ChevronDownIcon boxSize='24px' color='#6F767E' />
          </Flex>
        </PopoverTrigger>

        <PopoverContent width='unset' maxW='740px' borderRadius='12px'>
          <PopoverBody padding='16px 0px'>
            <LocationSelectContainer value={value} onChange={onChange} />
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <LocationChips value={value} />
    </Box>
  );
};

export default LocationSelect;

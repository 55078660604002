import { Link } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { iconObj } from '../../utils/iconHelper';
import { searchTypeObj } from '../../utils';

import CustomTag from '../CustomTag';
import { ReactComponent as ContentSvg } from '../../assets/images/searchContent.svg';
import { ReactComponent as SelectSearchSvg } from '../../assets/images/selectSearch.svg';

const ADD_RECENT_SEARCH = gql`
  mutation RecentSearchAdd($input: AddSearchInput) {
    RecentSearchAdd(input: $input) {
      searchQuery
      viewedResult
      path
      updatedAt
      createdAt
      eid
      recordId
    }
  }
`;

const regexPattern = (text: any) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

const SearchListItem = ({ item, onClose, searchQuery }) => {
  const type = item?.path;
  const searchType = searchTypeObj[item?.path];
  const [addRecentSearch] = useMutation(ADD_RECENT_SEARCH);

  const onSelectSearch = () => {
    addRecentSearch({
      variables: {
        input: {
          searchQuery: searchQuery,
          viewedResult: item?.value,
          path: item?.path,
          eid: item?.eid,
        },
      },
    });
    onClose();
  };

  const highlightSearchedWord = (
    fullText: any,
    searchData: string,
    isRawContent?: boolean
  ) => {
    // const fullText = item?.value;
    //Highlight function
    if (!searchData.trim()) {
      return <span>{fullText}</span>;
    }

    let regexStr =
      '(' + searchData.trim().split(/\s+/).map(regexPattern).join('|') + ')';
    if (isRawContent) {
      regexStr =
        '(' + searchData.trim().split(/\s+/).map(regexPattern).join(' ') + ')';
    }

    const regex = new RegExp(regexStr, 'gi');
    const parts = fullText.split(regex);
    let final = parts
      .filter((part) => part)
      .map((part, i) =>
        regex.test(part) ? (
          <span
            style={{ fontWeight: 'bold', color: '#000000', width: '80%' }}
            key={i}
          >
            {part}
          </span>
        ) : (
          <span key={i} style={{ color: '#333b4f', width: '80%' }}>
            {part}
          </span>
        )
      );

    return <span style={{ color: '#333b4f', width: '80%' }}>{final}</span>;
  };

  const iconStyle = {
    backgroundColor: `${item?.meta?.thumbnail_color}95`,
    borderColor: item?.meta?.thumbnail_color,
  };

  const getHighlightedTextLine = (fullText) => {
    const lines = fullText.split('. ');
    let lineText = '';
    let searchData = searchQuery.toLowerCase();
    let searchQueryList = searchQuery.toLowerCase().split(' ');
    lines.some((lineData, index) => {
      if (lineData.trim()) {
        let line = lineData.toLowerCase();
        let lineIndex = line.indexOf(searchData);
        if (lineIndex === -1 && searchQueryList.length > 1) {
          searchData = '';
          let splittedQuery = [...searchQueryList];
          for (let i = 0; i < searchQueryList.length - 1; i++) {
            splittedQuery.pop();
            searchData = splittedQuery.join(' ');
            lineIndex = line.indexOf(searchData);
            if (lineIndex !== -1) {
              break;
            }
          }
          if (lineIndex === -1) {
            for (let i = 0; i < searchQueryList.length; i++) {
              searchData = searchQueryList[i];

              lineIndex = line.indexOf(searchData);
              if (lineIndex !== -1) {
                break;
              }
            }
          }
        }
        if (lineIndex > 60) {
          if (line) {
            let subStringIndex = lineIndex - 60;

            while (
              line[subStringIndex] &&
              line[subStringIndex] !== '\n' &&
              line[subStringIndex] !== ' ' &&
              subStringIndex > 0
            ) {
              subStringIndex--;
            }

            const linesplit = lineData
              .toString()
              .substr(subStringIndex, line.length);

            if (!lineText) lineText = linesplit;
          }

          return false;
        } else {
          if (!lineText && lineIndex !== -1) lineText = lineData;

          return lineText;
        }
      }
    });
    if (lineText) {
      return highlightSearchedWord(lineText, searchData, true);
    }

    // return lines;
  };

  return (
    <>
      {type !== 'chapter' && type !== 'raw_content' && (
        <Link to={`/dashboard?${type}=${item?.value}`} onClick={onSelectSearch}>
          <div className='eventclass-search-item-clickable-container search-item-container'>
            {type === 'category' && (
              <img
                src={item?.meta?.icon}
                style={{
                  width: '18px',
                  height: '18px',
                  margin: 'unset',
                  marginRight: '16px',
                }}
              />
            )}

            {type === 'roles' && (
              <div className='eventclass-search-item-roles-container search-item-roles-container'></div>
            )}
            {type === 'bundles' && (
              <div className='eventclass-search-item-bundles-container search-item-bundles-container'></div>
            )}
            <div>
              {highlightSearchedWord(item?.value, searchQuery.toLowerCase())}
            </div>
            <CustomTag
              title={item?.path}
              style={{ marginLeft: '10px' }}
              backgroundColor={searchType?.color}
            />
            <div className='eventclass-search-item-hover-container search-item-hover-container'>
              <div className='search-item-hover-box'>
                <SelectSearchSvg
                  style={{ minWidth: '9px', marginLeft: '3px', width: '9px' }}
                />
              </div>
              <div>Select</div>
            </div>
          </div>
        </Link>
      )}
      {(type === 'chapter' || type === 'raw_content') && (
        <Link to={`/sop/${item?.eid}`} onClick={onSelectSearch}>
          <div className='eventclass-search-item-chapter-container search-item-chapter-container'>
            <div>
              <div className='search-item-category-icon' style={iconStyle}>
                {item?.meta?.thumbnail && !iconObj[item?.meta?.thumbnail] && (
                  <img
                    src={item?.meta?.thumbnail}
                    style={{
                      width: '11px',
                      marginLeft: '5px',
                      marginBottom: '3px',
                    }}
                  />
                )}
                {((item?.meta?.thumbnail && iconObj[item?.meta?.thumbnail]) ||
                  !item?.meta?.thumbnail) && (
                  <img
                    src={iconObj[item?.meta?.thumbnail] || iconObj['checklist']}
                    style={{
                      width: '34px',
                      marginLeft: '-7px',
                      marginBottom: '4px',
                    }}
                  />
                )}
              </div>
            </div>

            <div style={{ width: '100%' }}>
              <div style={{ display: 'flex', alignContent: 'center' }}>
                {type === 'chapter' && (
                  <div>
                    {highlightSearchedWord(
                      item?.value,
                      searchQuery.toLowerCase()
                    )}
                  </div>
                )}
                {type === 'raw_content' && (
                  <div>
                    {item?.meta?.chapter}
                    {/* {getHighlightedTextLine(item?.meta?.chapter)} */}
                  </div>
                )}
                <CustomTag
                  title='chapter'
                  style={{ marginLeft: '10px' }}
                  backgroundColor={searchTypeObj['chapter']?.color}
                />
                <div className='eventclass-search-item-hover-container search-item-hover-container'>
                  <div className='search-item-hover-box'>
                    <SelectSearchSvg
                      style={{
                        minWidth: '9px',
                        marginLeft: '3px',
                        width: '9px',
                      }}
                    />
                  </div>
                  <div>Select</div>
                </div>
              </div>
              <div>
                {type === 'chapter' && (
                  <div className='eventclass-search-item-raw-content search-item-raw-content'>
                    <span style={{ marginRight: '8px' }}>
                      <ContentSvg style={{ width: '8px', height: '8px' }} />
                    </span>
                    {item?.meta?.raw_content}
                  </div>
                )}
                {type === 'raw_content' && (
                  <div className='eventclass-search-item-raw-content search-item-raw-content'>
                    <span style={{ marginRight: '8px' }}>
                      <ContentSvg style={{ width: '8px', height: '8px' }} />
                    </span>
                    {getHighlightedTextLine(item?.value)}
                    {/* {getHighlightedTextLine(item?.value)} */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default SearchListItem;

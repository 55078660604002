import { gql } from '@apollo/client';
import { FormCategory } from '../../../../types';

export interface UpdateCategoryResponse {
  UpdateFormCategory: FormCategory;
}

export const FORM_CATEGORY_UPDATE = gql`
  mutation UpdateFormCategory($categoryId: String!, $category: String) {
    UpdateFormCategory(categoryId: $categoryId, category: $category) {
      eid
      name
      categoryType
      createdBy
      entityId
    }
  }
`;

import { IGroupedData } from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/Section/PhaseSection/PhaseSection';
import { cloneDeep } from '@apollo/client/utilities';
import { LauncherLocationByIdDetail } from '../types/location-launch-details.types';

export const getPhaseData = (
  foundLocationData: LauncherLocationByIdDetail | undefined
): IGroupedData[] => {
  if (!foundLocationData) {
    return [];
  }
  let _foundLocationData = cloneDeep(foundLocationData);
  // const grouped = (foundLocationData?.tasks || [])?.reduce((acc, item) => {
  //   if (!acc[item.category]) {
  //     acc[item.category] = {
  //       category: item.category,
  //       data: [],
  //       phaseSelected: false,
  //     };
  //   }
  //   acc[item.category].data.push(item);
  //   if (item.isPhaseSelected) {
  //     acc[item.category].phaseSelected = true;
  //   }
  //   return acc;
  // }, {} as Record<string, IGroupedData>);

  // return Object.values(grouped);
  let groupedData = _foundLocationData?.contents?.map((content) => {
    let _groupedData: IGroupedData = {
      category: content?.category,
      phaseSelected: content?.selected,
      phaseId: content?.eid,
      data: [],
    };
    content?.tasks?.map((task) => {
      _foundLocationData?.tasks?.map((taskData) => {
        if (task?.eid === taskData?.eid && task?.title === taskData?.title && task?.description === taskData?.description) {
          _groupedData.data.push(taskData);
        }
      });
    });
    return _groupedData;
  });
  return groupedData as IGroupedData[];
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsiveDivWrapper } from '../FormResponseTable.styles';
import { ImageComponentWrapper } from './ImageComponent.styles';
import { toArray } from 'utils/utils';
import Image from '../../../../../ui-components/Image/Image';
import NotesText from '../ResponseCell/NotesText';

interface IProps {
  images?: string[];
  noteText?: string;
}

const ImageComponent: FC<IProps> = ({ images: _images, noteText }) => {
  const { t } = useTranslation('common');
  const images = toArray(_images);
  return (
    <ResponsiveDivWrapper>
      <ImageComponentWrapper>
        {images?.length > 0 &&
          images?.map((image, index) => (
            <div className='image-div' key={image + index}>
              <Image className='image-img' src={image} width={25} height={25} />
              <span
                onClick={() => window.open(image, '_blank')}
                className='image-span'
              >
                {t('image')}
              </span>
            </div>
          ))}
        {images?.length === 0 && (
          <div style={{ minWidth: '300px', maxWidth: '300px' }}>-</div>
        )}
      </ImageComponentWrapper>
      <NotesText noteText={noteText} />
    </ResponsiveDivWrapper>
  );
};

export default ImageComponent;

import React from 'react';
import { Input, Typography, Col } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const { Text } = Typography;
type TextInputProps = {
  bordered?: boolean;
  size?: SizeType;
  placeholder?: string;
  style?: React.CSSProperties;
  type?: string;
  onChange?: any;
  prefix?: any;
  suffix?: any;
  value?: any;
  onError?: any;
  disabled?: boolean;
  inputRef?: any;
  handleKeyDown: any;
  allowClear?: boolean;
  inputError?: string;
  onBlur?: any;
};
export const TextInput = ({
  bordered,
  size,
  placeholder,
  style,
  type,
  onChange,
  prefix,
  suffix,
  value,
  onError,
  disabled,
  inputRef,
  handleKeyDown,
  allowClear,
  onBlur,
  inputError = '',
}: TextInputProps) => {
  return (
    <Col>
      <Input
        onBlur={onBlur}
        allowClear={allowClear}
        onKeyDown={handleKeyDown || null}
        ref={inputRef}
        disabled={disabled}
        onError={onError}
        value={value}
        suffix={suffix}
        prefix={prefix}
        onChange={onChange}
        type={type}
        style={style}
        bordered={bordered}
        size={size}
        placeholder={placeholder}
      />
      {/* {inputError && <Text type='danger'>{inputError}</Text>} */}
    </Col>
  );
};

import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'react-i18next';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import FormInput from 'atoms/FormInput';
import PrimaryButton from 'atoms/PrimaryButton';
import icon from '../../assets/images/delightreeIcon.svg';
import { EMAIL_REGEX } from '../../utils/constants';

const DELETE_USER_REQUEST = gql`
  mutation DeleteAccountRequest(
    $name: String!
    $email: String!
    $phone: String
  ) {
    DeleteAccountRequest(name: $name, email: $email, phone: $phone) {
      succeed
    }
  }
`;

interface IFormInput {
  phone: string;
  userEmail: string;
  userName: string;
}

type StatusBoxProps = {
  title: string;
  message: string;
};

interface IProps {}

const StatusBox: React.FC<StatusBoxProps> = ({ title, message }) => {
  return (
    <Box
      maxW='700px'
      width='100%'
      bg='white'
      padding='48px'
      borderRadius='20px'
      border='1px solid #CCCCCC'
    >
      <Box fontFamily='Inter' textAlign='center'>
        <Box fontWeight='700' fontSize='30px' color='#1A1D1F'>
          {title}
        </Box>
        <Box fontWeight='400' fontSize='16px' lineHeight='30px' color='#555555'>
          {message}
        </Box>
        <Box
          fontWeight='600'
          fontSize='14px'
          lineHeight='30px'
          color='#555555'
          mt={4}
        >
          You can close this window
        </Box>
      </Box>
    </Box>
  );
};

const DeleteUserForm: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'auth']);
  const { boot, update, show } = useIntercom();
  const { control, handleSubmit } = useForm<IFormInput>();
  const history = useHistory();
  const [status, setStatus] = useState<{
    status: 'success' | 'error' | '';
    message: string;
  }>({ status: '', message: '' });
  useEffect(() => {
    boot();
    update({
      hideDefaultLauncher: true,
      alignment: 'right',
      horizontalPadding: 20,
      verticalPadding: 20,
    });
    return () => {
      update({
        hideDefaultLauncher: true,
        alignment: 'left',
        horizontalPadding: 50,
        verticalPadding: 50,
      });
    };
  }, []);

  const [deleteUserFn, { loading }] = useMutation(DELETE_USER_REQUEST, {
    onCompleted: (data) => {
      setStatus({
        status: 'success',
        message:
          'We have received your request. We will review and update accordingly.',
      });
    },
    onError: (error) => {
      setStatus({ status: 'error', message: error.message });
    },
  });

  const onFinish = async (values: IFormInput) => {
    deleteUserFn({
      variables: {
        phone: values.phone,
        name: values.userName,
        email: values.userEmail,
      },
    });
  };

  const renderStatus = () => {
    if (!status) return null;

    let title = '';
    switch (status.status) {
      case 'success':
        title = 'Your request is submitted';
        break;
      case 'error':
        title = 'We have already received your request.';
        break;
      default:
        return null;
    }

    return <StatusBox title={title} message={status.message} />;
  };

  return (
    <Box height='100%' maxH='100%' bg='#E5E5E5' overflowY='auto' pos='relative'>
      <Flex align='center' w='full' h='70px' px='40px' bg='white'>
        <Image height='40px' width='46px' src={icon} />
      </Flex>

      <Flex justify='center' py='12px' my='40px'>
        <>
          {!status?.status ? (
            <Box
              maxW='700px'
              width='100%'
              bg='white'
              padding='48px'
              pt='32px'
              borderRadius='20px'
              border='1px solid #CCCCCC'
            >
              <Box fontFamily='Inter' textAlign='center' mb={5}>
                <Box fontWeight='700' fontSize='30px' color='#1A1D1F'>
                  Delete Account Form
                </Box>
                <Box
                  fontWeight='400'
                  fontSize='16px'
                  lineHeight='30px'
                  color='#555555'
                >
                  Enter following details to get your account deleted from
                  Delightree
                </Box>
              </Box>
              <form onSubmit={handleSubmit(onFinish)}>
                <TitleHeader title={t('auth:full_name')} isRequired />
                <Controller
                  name='userName'
                  control={control}
                  defaultValue=''
                  rules={{
                    required: t('validation.name_required'),
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl mt={1} mb={3} isInvalid={!!fieldState.error}>
                        <FormInput
                          type='text'
                          placeholder='John Doe'
                          {...field}
                        />
                        <FormErrorMessage>
                          {fieldState?.error?.message}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                />

                <TitleHeader title={'Email'} isRequired />
                <Controller
                  name='userEmail'
                  control={control}
                  defaultValue=''
                  rules={{
                    required: t('validation.email_required'),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t('validation.email_invalid'),
                    },
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl mt={1} mb={3} isInvalid={!!fieldState.error}>
                        <FormInput
                          type='email'
                          placeholder='john@delightree.com'
                          {...field}
                        />
                        <FormErrorMessage>
                          {fieldState?.error?.message}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                />

                <TitleHeader title={t('auth:phone')} />
                <Controller
                  name='phone'
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl mt={1} mb={8} isInvalid={!!fieldState.error}>
                        <FormInput
                          type='text'
                          placeholder='+1 456 435 765'
                          {...field}
                        />
                        <FormErrorMessage>
                          {fieldState?.error?.message}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                />

                <PrimaryButton
                  type='submit'
                  title='Request'
                  colorScheme='blue'
                  isLoading={loading}
                  disabled={loading}
                />
              </form>
            </Box>
          ) : (
            renderStatus()
          )}
        </>
      </Flex>
    </Box>
  );
};

export default DeleteUserForm;

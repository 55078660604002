import { gql } from '@apollo/client';
import { GroupMemberEntity } from '../../../../../types';

export interface RemoveVariable {
  guid: string;
  eid: string;
}

export const REMOVE_MEMBER = gql`
  mutation RemoveDirectMembersFromChatGroup($guid: String!, $eid: String!) {
    removeDirectMembersFromChatGroup(guid: $guid, eid: $eid) {
      eid
    }
  }
`;

export interface GroupMembersResponse {
  FetchChatGroupMembers: GroupMemberEntity[];
}

export const VIEW_CHANNEL_MEMBER = gql`
  query FetchChatGroupMembers($guid: String!) {
    FetchChatGroupMembers(guid: $guid)
  }
`;

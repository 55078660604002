import { AccessibilityType, AccessibleTransKeys } from 'types';

export const Messages: Record<AccessibilityType, AccessibleTransKeys> = {
  NOT_FOUND: 'form:thisFormIsDeleted',
  UNAUTHORISED: 'form:formNotAvailableToYou',
};

export const MessagesDesc: Record<AccessibilityType, AccessibleTransKeys> = {
  NOT_FOUND: 'form:pleaseContactAccAdmin',
  UNAUTHORISED: 'form:contactYourManager',
};

import React, { FC, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import momentTz from 'moment-timezone';
import { useQuery, useReactiveVar } from '@apollo/client';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { userObj } from 'sop-commons/src/client';

import { toArray } from '../../../../../utils/utils';
import zoneAbbrData from '../../../../../assets/moment-zone-abbr.json';
import { locationTimezoneQuery, TimezoneResponse } from './timezone.graphql';
import { getTimeZone } from '../../validation/helper';
import { IFormInput } from '../../task.types';
import TimezoneList from './TimezoneList';

type AbbrKeys = keyof typeof zoneAbbrData;

momentTz.fn.zoneName = function () {
  return zoneAbbrData[this.zoneAbbr() as AbbrKeys]?.name || this.zoneAbbr();
};

interface IProps {
  startDateType?: IFormInput['startDateType'];
}

const TimezoneInfo: FC<IProps> = ({ startDateType }) => {
  const { t } = useTranslation(['task', 'team']);
  const entityId = useReactiveVar(userObj).entityId;

  const { data } = useQuery<TimezoneResponse>(locationTimezoneQuery, {
    // fetchPolicy: 'network-only',
    variables: {
      eid: entityId,
    },
    skip: !entityId,
  });

  const [taskRunFor, taskDue, endCondition] = useWatch<
    IFormInput,
    ['taskRunFor', 'taskDue', 'endCondition.endType']
  >({
    name: ['taskRunFor', 'taskDue', 'endCondition.endType'],
  });

  const results = useMemo(() => {
    return toArray(data?.fetchEntityLocations).map((value) => {
      return Object.assign({}, value, {
        timezone: getTimeZone(value.timezone),
      });
    });
  }, [data]);

  if (!startDateType || (taskRunFor === 'once' ? !taskDue : !endCondition)) {
    return null;
  }

  return (
    <Flex
      bg='rgba(202, 189, 255, 0.3)'
      color='#272B30'
      padding='14px 20px'
      borderRadius='10px'
      gap='12px'
      mt='16px'
    >
      <Box flex={1} fontSize='12px'>
        {t('task:scheduleTimezoneHint')}
      </Box>

      <Menu isLazy>
        <MenuButton
          as={Button}
          fontSize='12px'
          fontWeight='semibold'
          borderRadius='7px'
          background='#FFFFFF'
          _hover={{
            background: 'white',
          }}
          _active={{
            background: 'white',
          }}
          disabled={!results?.length}
          rightIcon={<ChevronDownIcon boxSize={4} />}
          // gap='6px'
          // px="16px"
        >
          {t('task:viewLocations')}
        </MenuButton>

        <MenuList
          display='flex'
          flexDir='column'
          padding='0'
          maxHeight='50vh'
          overflow='hidden'
          borderRadius='10px'
        >
          <TimezoneList results={results} />
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default TimezoneInfo;

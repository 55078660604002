import React, { FC, useContext, useEffect, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import SupervisedTaskDetailsDataContext from 'pages/TasksDashboard/TaskDetails/store/supervised-task-details-context';

import GenericColorHeader from 'sub-components/GenericColorHeader';
import { getCompleteRate } from 'sub-components/tasks/performance';
import { toArray } from '../../../../../../utils/utils';
import {
  GraphEntity,
  LocationBarGraph,
  useTaskPerformance,
} from '../../../../Components/graphs';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { AuthRole } from 'authorization';
import {
  ITaskPerformanceType,
  PerformanceResponse,
  TaskPerformanceVariable,
  TASk_PERFORMANCE_QUERY,
} from 'pages/TasksDashboard/Components/graphs/task-performance.graphql';

interface LocationEntity {
  eid: string;
  name: string;
  profilePic: string | null;
}

interface IProps {
  taskId: string;
  sessionId?: string;
}

const LocationWisePerformance: FC<IProps> = ({ taskId, sessionId }) => {
  const { t } = useTranslation(['task']);
  const loggedInUserAuthRole = useReactiveVar(userObj)?.authRole;
  const locationDescendants = useReactiveVar(userObj)?.locations || [];
  const supervisedCtx = useContext(SupervisedTaskDetailsDataContext);

  const locations: LocationEntity[] =
    supervisedCtx?.taskDetails?.lastSession?.assigneeUsersLocations || [];

  // const { data, loading } = useTaskPerformance({
  //   taskId: taskId,
  //   type: 'location',
  //   sessionId: sessionId,
  // });

  const [getTaskPerformanceData, { data, loading }] = useLazyQuery<
    PerformanceResponse,
    TaskPerformanceVariable
  >(TASk_PERFORMANCE_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getTaskPerformanceData({
      variables: {
        taskId: taskId,
        type: 'location',
        ...(sessionId ? { sessionId: sessionId } : {}),
      },
    });
  }, [taskId, sessionId]);

  const graphData = useMemo(() => {
    const locationAnalytics = data?.TaskPerformance || {};
    // const filteredLocationNames = locations.filter((locationName) =>
    //   locationDescendants.some(
    //     (descendant) => descendant.eid === locationName.eid
    //   )
    // );
    // console.log('FILTERED LOCATION NAMES : ', filteredLocationNames);

    let _locations: LocationEntity[] = toArray(locations);
    // let _locations: LocationEntity[] = toArray(
    //   loggedInUserAuthRole === AuthRole.LOCATION_OWNER
    //     ? filteredLocationNames
    //     : locations
    // );
    console.log('graphData : _locations : ', _locations);

    return _locations.reduce<GraphEntity[]>((acc, loc) => {
      console.log('graphData : loc : ', loc);
      const value = locationAnalytics[loc.eid];

      if (value) {
        acc.push({
          name: loc.name,
          completeRate: getCompleteRate(value),
        });
      }

      return acc;
    }, []);
  }, [data, locations]);

  return (
    <Flex
      flex={1}
      flexDir='column'
      borderRadius='10px'
      padding={6}
      bg='white'
      gap='24px'
    >
      <Flex flexDir='column' gap='10px'>
        <GenericColorHeader
          title={t('task:locationWisePerformance')}
          color='#CABDFF'
        />

        <Flex align='center' gap='10px'>
          <Box boxSize='8px' borderRadius='full' bg='#5542F6' />
          <Box color='#2E2C34' fontSize='14px'>
            {t('task:taskCompletionRate')}
          </Box>
        </Flex>
      </Flex>

      <LocationBarGraph isLoading={loading} height={350} data={graphData} />
    </Flex>
  );
};

export default LocationWisePerformance;

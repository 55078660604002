import { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Image,
  Box,
  Flex,
  Center,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Loader from 'sub-components/Loader';
import { PSEUDO_MEMBERS_LIST } from './location-pseudo-members-graphql';
import { IPseudoUsers } from './pseudo-users-types';

import { getImage } from '../../../utils';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  locationId: string;
  selectedTrainingData: any;
}

const LocationPseudoMember: FC<IProps> = ({
  isOpen,
  onClose,
  locationId,
  selectedTrainingData,
}) => {
  const history = useHistory();
  const { t } = useTranslation('training');
  const [pseudoUsersData, setPseudoUsersData] = useState<IPseudoUsers[]>([]);

  const [getPseudoMembersList, { loading }] = useLazyQuery(
    PSEUDO_MEMBERS_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setPseudoUsersData(data?.LocationExternalUser);
      },
    }
  );

  useEffect(() => {
    if (locationId && isOpen) {
      getPseudoMembersList({
        variables: {
          locationId: locationId,
        },
      });
    }
  }, [isOpen]);

  const onUserSelect = (userData: IPseudoUsers) => {
    if (!selectedTrainingData?.eid) {
      return;
    }
    history.push('/training-play/' + selectedTrainingData.eid, {
      userData: userData,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent minHeight={'40vh'}>
        <ModalHeader>{t('click_name_start')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody h='full'>
          {!loading && pseudoUsersData?.length === 0 && (
            <Text as='b' fontSize={'md'}>
              {t('no_user_found')}
            </Text>
          )}
          {loading && (
            <Center h='full' w='full'>
              <Loader />
            </Center>
          )}
          {!loading && pseudoUsersData?.length > 0 && (
            <Box w='full' display='flex' flexDirection='column' gap='6'>
              {pseudoUsersData?.map((user) => (
                <Flex key={user?.eid} w='full' justify='space-between'>
                  <Flex w='80%' align='center' gap='2'>
                    <Image
                      borderRadius='50%'
                      maxW='30px'
                      src={getImage(user?.profilePic, user.name)}
                    />
                    <Text>{user.name}</Text>
                  </Flex>
                  <FontAwesomeIcon
                    icon={faArrowRight as IconProp}
                    cursor='pointer'
                    size='1x'
                    color='#6F767E'
                    onClick={() => onUserSelect(user)}
                  />
                </Flex>
              ))}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LocationPseudoMember;

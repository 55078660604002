import moment from 'moment';
import { LauncherLocationDetailTask } from '../types/location-launch-details.types';
import { TaskStatusDetail, validateDate } from './getGroupedData';

export interface LastTaskStatus {
  lastTask: TaskStatusDetail;
}

export const getLastDoneTask = (
  tasks: LauncherLocationDetailTask[]
): LastTaskStatus | null => {
  console.log('tasks : ', tasks);
  const lastDoneTask = tasks
    .filter((task) => validateDate(task.completedAt))
    .reduce(
      (latest: LauncherLocationDetailTask | null, task) =>
        !latest || moment(task.completedAt).isAfter(moment(latest.completedAt))
          ? task
          : latest,
      null
    );

  return lastDoneTask
    ? {
        lastTask: {
          category: lastDoneTask.category,
          title: lastDoneTask.title,
          completedAt: lastDoneTask.completedAt,
        },
      }
    : null;
};

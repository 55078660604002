// import scss
import './Layout.scss';
import { FC, ReactNode } from 'react';

interface LayoutProps {
  isSelected?: boolean;
  svgIcon?: ReactNode;
  onSelect?: () => void;
}

const Layout: FC<LayoutProps> = ({ isSelected, svgIcon, onSelect }) => {
  if (isSelected) {
    return <div className='card-layout-container selected'>{svgIcon}</div>;
  }

  return (
    <div className='card-layout-container' onClick={onSelect}>
      {svgIcon}
    </div>
  );
};

export default Layout;

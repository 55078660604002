import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  children: any;
}

const ParentWrapper: FC<{ children: any }> = ({ children }) => {
  return (
    <Flex gap='10px' align='center'>
      {children}
    </Flex>
  );
};

export default ParentWrapper;

import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { toArray } from '../../../../../utils';
import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../ui-components';

import ChangePhaseListContent from './ChangePhaseListContent';
import { IFormInput } from '../editor.types';
import { selected } from '../helper';

interface IProps {
  initialValue: string;
  onUpdate: (newCategory: string) => void | Promise<void>;
}

type IChangeCheckList = (props: IProps) => void;

export const useChangePhase = (): IChangeCheckList => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  const { getValues } = useFormContext<IFormInput>();

  return useCallback(({ initialValue, onUpdate }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const categoryList = toArray(getValues('contents'))
      .filter(selected)
      .map((cat) => ({
        eid: cat.eid,
        category: cat.category,
      }));

    confirmRef.current = confirm({
      title: (
        <Flex gap='12px' mt={2}>
          <Box flex={1}>
            <BoxHeader
              fontSize='18px'
              title={'Select task phase'}
              color='#CABDFF'
            />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <ChangePhaseListContent
          categories={categoryList}
          onUpdate={onUpdate}
          initialValue={initialValue}
        />
      ),
      isCentered: true,
      size: '2xl',
      contentProps: {},
      footer: null,
    });
  }, []);
};

export const presenceStyle = (props) => {
  let presenceStatus = {
    backgroundColor: '#C4C4C4',
    display: 'none',
  };

  if (props.status === 'online' || props.status === 'available') {
    presenceStatus = {
      backgroundColor: '#83bf6e',
    };
  }

  return {
    width: '15px',
    height: '15px',
    top: '-58px',
    left: '-2px',
    float: 'left',
    position: 'relative',
    ...presenceStatus,
  };
};

import { Center, Portal } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ISopMedia } from './chapter.types';
import ReactPlayer from 'react-player';

interface IVideoModalProps {
  onClose: () => void;
  media: ISopMedia[];
}

const VideoModal: FC<IVideoModalProps> = ({ onClose, media }) => {
  return (
    <Portal>
      <Center
        position='absolute'
        w='full'
        h='full'
        top='0'
        zIndex='999'
        bg='#000000ab'
        onClick={onClose}
      >
        <Center w='85%' h='85%'>
          <ReactPlayer
            url={media?.[0]?.mediaUrl}
            controls
            playing
            height='100%'
            width='100%'
          />
        </Center>
      </Center>
    </Portal>
  );
};

export default VideoModal;

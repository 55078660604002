export const listItem = (props, context) => {
  const selectedState =
    props.selectedUser && props.selectedUser.uid === props.user.uid
      ? {
          // backgroundColor: `${context.theme.backgroundColor.primary}`,
          backgroundColor: 'rgba(42, 133, 255, 0.1)',
          borderRadius: '12px',
          borderBottom: '1px solid transparent',
        }
      : {};

  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'flex-start',
    cursor: 'pointer',
    width: '100%',
    padding: '10px',
    borderBottom: '1px solid #efefef',
    ...selectedState,
    '&:hover': {
      backgroundColor: '#EFEFEF',
      borderRadius: '12px',
      borderBottom: '1px solid transparent',
    },
  };
};

export const itemThumbnailStyle = () => {
  return {
    display: 'inline-block',
    width: '56px',
    height: '56px',
    flexShrink: '0',
  };
};

export const itemDetailStyle = () => {
  return {
    flex: 1,
    marginLeft: '10px',
    overflow: 'hidden',
    // borderBottom: '1px solid rgba(20, 20, 20, 0.1)',
    '&[dir=rtl]': {
      // paddingRight: '16px',
      paddingLeft: '0',
    },
    // '&:hover': {
    //   borderBottom: `1px solid transparent`,
    // },
  };
};

export const itemNameStyle = (context) => {
  return {
    fontSize: '15px',
    fontWeight: '600',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    // margin: '5px 0 0 0',
    lineHeight: '22px',
    color: `${context.theme.color.primary}`,
  };
};

export const itemDescStyle = (context) => {
  return {
    //marginTop: '10px',
    // borderBottom: `1px solid ${context.theme.borderColor.primary}`,
  };
};

export const itemRoleStyle = (context) => {
  return {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: '600',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#9a9fa5',
    textTransform: 'capitalize',

    '.item__details_role': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '45%',
      "&[data-role]:not([data-role=''])": {
        color: '#2a85ff',
      },
    },

    // ':not(:only-child)': {
    //   '.item__details_role': {
    //     maxWidth: '45%',
    //   },
    // },

    '.item__details_authrole': {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    '.item__detail__role_dot': {
      minWidth: 4,
      width: 4,
      height: 4,
      borderRadius: 4,
      background: '#354b76',
      marginLeft: 6,
      marginRight: 6,
    },
  };
};

import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import LocationOwner from './LocationOwner';

interface IProps {
  ownerIds: {
    owner1: string;
    owner2: string;
  };
  setOwnerIds: React.Dispatch<
    React.SetStateAction<{
      owner1: string;
      owner2: string;
    }>
  >;
  locationId: string;
}

const LocationOwnerList: FC<IProps> = ({
  locationId,
  ownerIds,
  setOwnerIds,
}) => {
  const selectOwnerHandler = (id: string, number: number) => {
    if (number === 0) {
      setOwnerIds({ ...ownerIds, owner1: id });
    } else {
      setOwnerIds({ ...ownerIds, owner2: id });
    }
  };

  return (
    <Flex flexDir='column' gap={4}>
      <LocationOwner
        ownerPath='locationOwnerOne'
        locationId={locationId}
        title='Owner 1'
        selectOwnerHandler={(id: string) => selectOwnerHandler(id, 0)}
        isRequired
      />
      <LocationOwner
        ownerPath='locationOwnerTwo'
        locationId={locationId}
        title='Owner 2'
        selectOwnerHandler={(id: string) => selectOwnerHandler(id, 1)}
      />
    </Flex>
  );
};

export default LocationOwnerList;

import React, { FC } from 'react';
import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

interface IProps extends FlexProps {
  icon?: IconDefinition;
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>;
  childProps?: BoxProps;
}

const AlertBox: FC<IProps> = ({
  children,
  icon,
  iconProps,
  fontSize = '13px',
  childProps,
  ...props
}) => {
  return (
    <Flex
      gap='8px'
      bg='rgba(177, 229, 252, 0.4)'
      borderRadius='10px'
      p='12px 18px'
      {...props}
    >
      {icon && (
        <Flex
          align='center'
          height='26px'
          sx={{
            svg: {
              lineHeight: '26px',
              verticalAlign: 'unset',
            },
          }}
        >
          <FontAwesomeIcon
            icon={icon as IconProp}
            color='#2A85FF'
            fontSize='16px'
            {...iconProps}
          />
        </Flex>
      )}
      <Box
        flex={1}
        fontSize={fontSize || '13px'}
        fontWeight='400'
        lineHeight='26px'
        color='#33383F'
        {...childProps}
      >
        {children}
      </Box>
    </Flex>
  );
};

export default AlertBox;

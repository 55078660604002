import { Box, Flex, FormControl } from '@chakra-ui/react';
import FormInput from 'atoms/FormInput';
import React, { ChangeEvent, FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardType } from 'types';
import { actionCardBackgroundColorList } from 'utils';

interface IProps {}

const MilestoneCardEditor: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'training']);
  const { control, setValue } = useFormContext<any>();

  useEffect(() => {
    setValue('cardType', {
      label: 'Milestone',
      transKey: 'card:cardType.action',
      value: CardType.Milestone,
    });
  }, []);
  return (
    <>
      <Flex>
        <Flex w='55%' mr={8} flexDir='column'>
          <Flex h='100%' flexDir='column' justifyContent='space-between' mt={4}>
            <Box h='540px'>
              <div>
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    marginTop: '2rem',
                  }}
                >
                  {t('training:milestoneName')}
                </p>
                <div>
                  <FormControl mt={3}>
                    <FormInput
                      id='name'
                      placeholder=''
                      value={''}
                      size='lg'
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {}}
                    />
                  </FormControl>
                </div>
              </div>
              <div>
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    marginTop: '2rem',
                  }}
                >
                  {t('training:milestoneBackgroundColor')}
                </p>
                <div style={{ display: 'flex', marginTop: '1rem' }}>
                  {actionCardBackgroundColorList?.map((item, index) => {
                    let style = {};
                    // if (item === cardData?.backgroundColor) {
                    //     style = {
                    //         border: 'solid 1.5px #2a85ff',
                    //     };
                    // }

                    // const onChangeColor = () => {
                    //     // setSelectedColor(item);
                    //     cardData.backgroundColor = item;
                    //     console.log('Card Dara', cardData);
                    //     let newCardData = cloneDeep(cardData);
                    //     setCardData({ ...newCardData });
                    // };
                    return (
                      <div
                        key={index}
                        style={{
                          backgroundColor: item,
                          minWidth: '48.9px',
                          maxWidth: '48.9px',
                          minHeight: '48.9px',
                          maxHeight: '48.9px',
                          borderRadius: '58.9px',
                          boxShadow: '0 7px 22px 0 rgb(86 84 104 / 20%)',
                          marginRight: '11px',
                          cursor: 'pointer',
                          ...style,
                        }}
                        onClick={() => {}}
                      />
                    );
                  })}
                </div>
              </div>
              <div>
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    marginTop: '4rem',
                  }}
                >
                  {t('training:whatIsMilestone')}
                </p>
                <p style={{ fontSize: '14px' }}>
                  {t('training:milestoneDescription')}
                </p>
              </div>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default MilestoneCardEditor;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Flex, Stack, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';

import { AuthRole } from 'authorization';
import { toArray } from '../../../../utils/utils';

import { USER_DATA_QUERY, UserDataResponse } from './info.graphql';
import { DisablePermission, useDisabledField } from './useDisabledField';
import ProfileInfoForm, { InfoFormValues } from './ProfileInfoForm';
import ProfileAvatarInput from './ProfileAvatarInput';
import ProfileFormLoading from './ProfileFormLoading';
import { useHistory } from 'react-router-dom';

interface IProps {
  userId: string;
  disableLocation?: boolean;
  disablePermissions?: DisablePermission;
  onMagicLinkPress?: (...args: any[]) => any | PromiseLike<any>;
  onUserDataFetched?: (data: UserDataResponse['userById']) => void;
  children: React.ReactElement;
}

export interface InfoBaseRef {
  updateValues: (values: Partial<Record<keyof InfoFormValues, any>>) => void;
}

const InfoBaseComponent = forwardRef<InfoBaseRef, IProps>(
  (
    {
      userId,
      disableLocation,
      disablePermissions,
      onMagicLinkPress,
      onUserDataFetched,
      children,
    },
    ref
  ) => {
    const toast = useToast({
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
    const history = useHistory();
    const [selectedUserRole, setSelectedRole] = useState<AuthRole>(
      '' as AuthRole
    );
    const methods = useForm<InfoFormValues>({
      defaultValues: {},
    });

    const disabledField = useDisabledField(disablePermissions);

    useImperativeHandle(
      ref,
      () => {
        return {
          updateValues: (values) => {
            if (values) {
              Object.entries(values).forEach(([key, keyValue]) => {
                methods.setValue(key as keyof InfoFormValues, keyValue);
              });
            }
          },
        };
      },
      [methods.setValue]
    );

    const { loading } = useQuery<UserDataResponse>(USER_DATA_QUERY, {
      fetchPolicy: 'network-only',
      variables: {
        eid: userId,
      },
      onCompleted: (response) => {
        const data = response.userById;
        const [firstName, ...rest] = data.name?.trim()?.split(' ');
        setSelectedRole(data.authRole);
        onUserDataFetched?.(data);
        methods.reset(
          {
            eid: data.eid,
            firstName: firstName,
            lastName: rest.join(' '),
            email: data.email,
            profilePic: data.profilePic,
            role: data.role,
            initialAccessDetails: data.authRole,
            accessDetails: data.authRole,
            phoneNumber: data.phone,
            // location: toArray(data.locations)[0]?.eid,
            initialLocation: toArray(data.locations)
              ?.map((loc) => loc?.eid)
              .filter(Boolean),
            location: toArray(data.locations)
              ?.map((loc) => loc?.eid)
              .filter(Boolean),
            username: data.username,
            type: data.type,
            status: data.status,
            isDeleted: data.isDeleted,
          },
          {
            keepValues: false,
          }
        );
      },
      onError: (error) => {
        if (error?.graphQLErrors?.[0]?.message) {
          toast({
            title: error?.graphQLErrors?.[0]?.message,
            status: 'error',
          });
          history.push('/teams/members');
        }
      },
    });

    const childNode = React.Children.only(children);

    return (
      <FormProvider {...methods}>
        <Flex gap={6}>
          <Stack flex={1} spacing={6}>
            <ProfileFormLoading isLoading={loading}>
              <ProfileInfoForm
                disabledField={disabledField}
                authRole={selectedUserRole}
                disableLocation={disableLocation}
              />
            </ProfileFormLoading>

            {React.cloneElement(childNode, {
              isLoading: loading,
            })}
          </Stack>

          <Flex
            align='center'
            flexDir='column'
            width='180px'
            mt='34px'
            gap='16px'
          >
            <ProfileAvatarInput
              isLoading={loading}
              onMagicLinkPress={onMagicLinkPress}
            />
          </Flex>
        </Flex>
      </FormProvider>
    );
  }
);

InfoBaseComponent.displayName =
  'pages/Profile/TabComponents/InfoComponent/InfoBaseComponent';

export default InfoBaseComponent;

import React, { FC } from 'react';
import { Button, Image, useDisclosure } from '@chakra-ui/react';
import { PopupModal } from 'react-calendly';
import { useTranslation } from 'react-i18next';
import whiteIcon from '../../assets/images/logo-white.svg';
import { CALENDLY_LINK } from '../../utils/constants';

const GetDelightreeAccount: FC = () => {
  const { t } = useTranslation('demo');
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <>
      <Button
        leftIcon={<Image src={whiteIcon} w='20px' h='20px' />}
        title={t('get_own_account')}
        type='button'
        variant='solid'
        colorScheme='red'
        onClick={onToggle}
        fontSize='15px'
      >
        {t('get_own_account')}
      </Button>
      <PopupModal
        url={CALENDLY_LINK}
        // pageSettings={this.props.pageSettings}
        // utm={this.props.utm}
        // prefill={this.props.prefill}
        onModalClose={onClose}
        open={isOpen}
        rootElement={document.getElementById('root') as HTMLElement}
      />
    </>
  );
};

export default GetDelightreeAccount;

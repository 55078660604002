import { gql } from '@apollo/client';

export const getAllTrainings = gql`
  query TrainingPathTableGetAllTrainings(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
  ) {
    userTrainingNew(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        title
        status
        thumbnail
        assignedTo
        userProgress
        updatedAt
        eid
        assignedToUsers {
          eid
          profilePic
          name
        }
        trainingItems
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

import { gql, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useUserDataQuery } from 'hooks';
import {
  ChapterSort,
  GET_CHAPTERS,
  GET_FOLDERS_LIST,
} from 'pages/Chapters/chapters.graphql';
import { GET_USER } from 'pages/Login/login.graphql';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { getChaptersList, getFoldersList } from 'shared/graphql/SharedGraphql';

// const MOVE_SUBFOLDER = gql`
//   mutation MoveItems($input: moveItemsTypeInput) {
//     moveItems(input: $input) {
//       succeed
//     }
//   }
// `;

const MOVE_SUBFOLDER = gql`
  mutation UpdateCategory($categoryId: String!, $parent: String) {
    updateCategory(categoryId: $categoryId, parent: $parent) {
      succeed
    }
  }
`;

interface IProps {
  getVariables?: () => {
    page: number;
    perPage: number;
    filter: Record<string, any>;
    sort: ChapterSort;
  };
  onMoveSubFolderCompleteHandler: () => void;
}

export function useMoveSubFolderFooterLogic(props: IProps) {
  const trimString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  };

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const [makeSubFolderMainQuery, { loading: makingSubFolderMainLoading }] =
    useMutation(MOVE_SUBFOLDER, {
      onCompleted: () => {
        toast({
          status: 'success',
          title: 'Sub Folder made as main folder successfully',
        });
        fetchFoldersList();
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Sub Folder could not be made as main folder',
        });
      },
      refetchQueries: [{ query: GET_CHAPTERS }],
      awaitRefetchQueries: true,
    });

  const [moveSubFolder, { loading: movingSubFolderLoading }] = useMutation(
    MOVE_SUBFOLDER,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: 'Sub Folder moved successfully',
        });
        fetchFoldersList();
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Sub Folder could not be moved',
        });
      },
      refetchQueries: [{ query: GET_CHAPTERS }],
      awaitRefetchQueries: true,
    }
  );

  const onFetchChaptersListSuccess = () => {
    props.onMoveSubFolderCompleteHandler();
  };

  const onFetchFoldersListSuccess = () => {
    props.onMoveSubFolderCompleteHandler();
    // fetchChaptersList({
    //   variables: props?.getVariables?.()
    // })
  };

  const { execute: fetchChaptersList, loading: gettingChaptersList } =
    getChaptersList(onFetchChaptersListSuccess);
  const { execute: fetchFoldersList, loading: gettingFoldersList } =
    getFoldersList(onFetchFoldersListSuccess);

  const makeSubFolderMain = (subFolderId: string) => {
    console.log({ makeSubFolderMain: subFolderId });
    deployEvent(AmplitudeEventNames.MAKE_SUB_FOLDER_MAIN);
    makeSubFolderMainQuery({
      variables: {
        categoryId: subFolderId,
        parent: null,
      },
    });
  };

  const moveSubFolderHandler = (folderId: string, subFolderId: string) => {
    deployEvent(AmplitudeEventNames.MOVE_SUBFOLDER);
    moveSubFolder({
      variables: {
        categoryId: subFolderId,
        parent: folderId,
      },
    });
  };

  return {
    gettingChaptersList,
    gettingFoldersList,
    makingSubFolderMainLoading,
    movingSubFolderLoading,
    trimString,
    moveSubFolder,
    makeSubFolderMain,
    moveSubFolderHandler,
  };
}

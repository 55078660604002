import React, { FC, useState } from 'react';
import { Button, Checkbox, Flex } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { FormInput } from '../../../../atoms';

interface IProps {
  onFormAddClick?: () => void;
  onChapterAddClick?: () => void;
  onAddClick?: (value: string) => void;
}

const AddStepForm: FC<IProps> = ({
  onAddClick,
  onFormAddClick,
  onChapterAddClick,
}) => {
  const { t } = useTranslation(['common', 'task']);

  const [value, setValue] = useState('');

  const haveValue = value?.trim();

  return (
    <Flex gap={3}>
      <Checkbox isReadOnly />
      <FormInput
        placeholder={t('task:placeholder.addATODOItem')}
        variant='outline'
        value={value}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            onAddClick?.(haveValue);
            setValue('');
          }
        }}
        onChange={(event) => setValue(event.target.value)}
        rightElementProps={{
          width: '5rem',
        }}
        paddingRight={haveValue ? 20 : 4}
        rightIcon={
          haveValue ? (
            <Button
              fontSize='14px'
              leftIcon={<FontAwesomeIcon size='1x' icon={faPlus as IconProp} />}
              onClick={() => {
                onAddClick?.(haveValue);
                setValue('');
              }}
              colorScheme='blue'
              variant='ghost'
              paddingX='16px'
              borderRightRadius='12px'
            >
              {t('common:add')}
            </Button>
          ) : undefined
        }
      />
      <Flex gap={2}>
        <Button
          fontSize='14px'
          colorScheme='blue'
          variant='outline'
          isDisabled={!!haveValue}
          onClick={!haveValue ? onChapterAddClick : undefined}
        >
          {t('task:orChapter')}
        </Button>

        <Button
          fontSize='14px'
          colorScheme='blue'
          variant='outline'
          isDisabled={!!haveValue}
          onClick={!haveValue ? onFormAddClick : undefined}
        >
          {t('task:orForm')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default AddStepForm;

import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { ILocationDetails } from '../../utils/location-details.types';
import LocationContactDetails from './Components/LocationContactDetails';
import LocationDetailsComponent from './Components/LocationDetailsComponent';
import EntityDetails from './Components/EntityDetails';
import OtherDetails from './Components/OtherDetails';

interface IProps {
  details: ILocationDetails | undefined;
}

const LocationDetailsCard: FC<IProps> = ({ details }) => {
  return (
    <Flex flexDir='column' gap={6} w='full'>
      <LocationContactDetails details={details} />
      <LocationDetailsComponent details={details} />
      <EntityDetails details={details} />
      <OtherDetails details={details} />
    </Flex>
  );
};

export default LocationDetailsCard;

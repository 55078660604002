import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, FC } from 'react';
import './Steps.scss';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

export interface Step {
  active: boolean;
  icon: IconProp | IconDefinition;
  name: string;
  stringId: string;
  outline?: string;
}

interface StepProps {
  stepData: {
    count: number;
    steps: Step[];
  };
  switchOnStep: (step: Step, index: number) => void;
}

const Steps: FC<StepProps> = (props: StepProps) => {
  let stepsArray = {
    count: props.stepData.count,
    steps: [...props.stepData.steps],
  };

  const getWidth = () => {
    let res = (100 / stepsArray.steps.length).toFixed(2);
    return res + '%';
  };

  const circleStyles = (step: Step, index: number) => {
    let style: CSSProperties = {
      height: '30px',
      minWidth: '30px',
      width: '30px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    };

    if (index === 0) {
      style.backgroundColor = stepsArray?.steps?.[index + 1]?.active
        ? '#83bf6e'
        : '#2a85ff';
    } else if (index > 0 && index < stepsArray?.steps.length - 1) {
      if (step.outline === 'none') {
        if (!step.active) {
          style.backgroundColor = '#ffffff';
          style.border = '2px solid #bbb';
        } else {
          style.backgroundColor = stepsArray?.steps?.[index + 1]?.active
            ? '#83bf6e'
            : '#2a85ff';
        }
      } else {
        if (!step.active) {
          style.backgroundColor = '#bbb';
        } else {
          style.backgroundColor = stepsArray?.steps?.[index + 1]?.active
            ? '#83bf6e'
            : '#2a85ff';
        }
      }
    } else {
      if (!step.active) {
        style.backgroundColor = '#bbb';
      } else {
        style.backgroundColor = '#2a85ff';
      }
    }
    return style;
  };

  const leftLineStyle = (step: Step, index: number) => {
    let style: CSSProperties = {
      width: '50%',
      height: '4px',
    };
    if (index === 0) {
      style.backgroundColor = stepsArray?.steps?.[index + 1]?.active
        ? '#83bf6e'
        : '#2a85ff';
    } else if (index > 0 && index < stepsArray?.steps.length - 1) {
      if (!step.active) {
        style.backgroundColor = '#E5E7EB';
      } else {
        style.backgroundColor = stepsArray?.steps?.[index - 1]?.active
          ? '#83bf6e'
          : '#2a85ff';
      }
    } else {
      if (!step.active) {
        style.backgroundColor = '#E5E7EB';
      } else {
        style.backgroundColor =
          stepsArray?.steps?.[index - 1]?.active && step?.active
            ? '#83bf6e'
            : '#2a85ff';
      }
    }
    return style;
  };

  const rightLineStyle = (step: Step, index: number) => {
    let style: CSSProperties = {
      width: '50%',
      height: '4px',
    };
    if (index === 0) {
      if (!step.active || !stepsArray?.steps?.[index + 1]?.active) {
        style.backgroundColor = '#E5E7EB';
      } else {
        style.backgroundColor = stepsArray?.steps?.[index + 1]?.active
          ? '#83bf6e'
          : '#2a85ff';
      }
    } else if (index > 0 && index < stepsArray?.steps.length - 1) {
      if (!step.active || !stepsArray?.steps?.[index + 1]?.active) {
        style.backgroundColor = '#E5E7EB';
      } else {
        style.backgroundColor = stepsArray?.steps?.[index + 1]?.active
          ? '#83bf6e'
          : '#2a85ff';
      }
    } else {
      style.backgroundColor = '#E5E7EB';
    }
    return style;
  };

  const iconStyle = (step: Step) => {
    let color: string;
    if (step.outline === 'none') {
      if (!step.active) {
        color = '#bbb';
      } else {
        color = 'white';
      }
    } else {
      color = 'white';
    }
    return color;
  };

  const nameStyle = (step: Step, index: number) => {
    let style: CSSProperties = { fontSize: '13px', fontWeight: '600' };
    if (index === 0) {
      if (!step.active) {
        style.color = '#000000';
      } else {
        style.color = stepsArray?.steps?.[index + 1]?.active
          ? '#83bf6e'
          : '#2a85ff';
      }
    } else if (index > 0 && index < stepsArray?.steps.length - 1) {
      if (!step.active) {
        style.color = '#000000';
      } else {
        style.color = stepsArray?.steps?.[index + 1]?.active
          ? '#83bf6e'
          : '#2a85ff';
      }
    } else {
      if (!step.active) {
        style.color = '#000000';
      } else {
        style.color =
          step.active && stepsArray?.steps?.[index + 1]?.active
            ? '#83bf6e'
            : '#2a85ff';
      }
    }
    // if (step.active) {
    //   style.color = '#2a85ff';
    // }
    return style;
  };

  return (
    <>
      <ul
        style={{
          listStyle: 'none',
          display: 'flex',
          width: '100%',
          paddingLeft: 'unset',
        }}
      >
        {stepsArray.steps.map((step, index) => (
          <li key={index} style={{ width: getWidth() }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                }}
                onClick={() => props.switchOnStep(step, index)}
              >
                <div // LEFT LINE
                  style={leftLineStyle(step, index)}
                />
                <div // CIRCLE
                  style={circleStyles(step, index)}
                >
                  <FontAwesomeIcon // CIRCLE ICON
                    icon={step.icon as IconProp}
                    color={iconStyle(step)}
                  />
                </div>
                <div // RIGHT LINE
                  style={rightLineStyle(step, index)}
                />
              </div>
              <div style={nameStyle(step, index)}>{step.name}</div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Steps;

import { Flex, useToast } from '@chakra-ui/react';
import Heading from 'sub-components/LocationLaunchDetail/Heading';
import StatusBadge from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Top/StatusBadge';
import React, { FC } from 'react';
import LocationOwnerMenu from 'pages/LocationDetails/components/LocationAssetsCard/components/OwnersMembers/components/LocationOwnerMenu';
import { useHistory } from 'react-router-dom';
import { LauncherLocationByIdDetail } from '../types/location-launch-details.types';

interface IProps {
  foundLocationData: LauncherLocationByIdDetail | undefined;
  owners: any;
  launcherLocationUsersRefetchHandler: () => void;
  onAddLOClick: () => void;
}

const LocationDetailsHeader: FC<IProps> = ({
  foundLocationData,
  owners,
  launcherLocationUsersRefetchHandler,
  onAddLOClick,
}) => {
  const history = useHistory();
  const toast = useToast({
    isClosable: true,
    duration: 3000,
    position: 'top-right',
  });
  return (
    <Flex align='center' justify='space-between'>
      <Flex align='center' gap='10px'>
        <Heading
          headingTitle={foundLocationData?.location?.name!}
          headingIcon
          onClick={() => history.push('/launcher/dashboard')}
        />
        <StatusBadge
          label={!foundLocationData?.isLauncherDue ? 'ON TRACK' : 'LAGGING'}
          labelProps={{
            whiteSpace: 'nowrap',
            color: !foundLocationData?.isLauncherDue ? '#83BF6E' : '#FF6A55',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: 'unset !important',
          }}
          boxProps={{
            bg: !foundLocationData?.isLauncherDue ? '#B5E4CA4D' : '#FF6A5526',
            p: '5px 8px 4px',
          }}
        />
      </Flex>
      <Flex>
        <LocationOwnerMenu
          title='Add new owner'
          eid={foundLocationData?.locationId}
          menuBtnProps={{
            size: 'md',
            variant: 'outline',
            colorScheme: 'blue',
            style: {
              backgroundColor: 'white',
              border: '1px solid rgba(221, 221, 221, 1)',
            },
          }}
          onAddLOClick={onAddLOClick}
          owners={owners}
          refetch={() => {
            toast({
              status: 'success',
              title: 'Selected location owners updated successfully',
            });
            launcherLocationUsersRefetchHandler?.();
          }}
        />
      </Flex>
    </Flex>
  );
};

export default LocationDetailsHeader;

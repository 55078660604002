import { Box, HStack, ListItem, VStack, Image } from '@chakra-ui/react';
import logo from '../../assets/images/ic_logo.png';
import './ViewTrainingItem.scss';
import { Divider } from 'antd';
import { ReactComponent as CheckedIcon } from '../../assets/images/checkedIcon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/pro-light-svg-icons';
import flagIcon from '../../assets/images/flagIcon.png';
import dashboard from '../../pages/Dashboard';

const ViewTrainingItem = ({
  item,
  index,
  selectedTrainingItem,
  activePage,
  isQuizSelection,
  prevCompleted,
  enablePreview,
}: any) => {
  console.log({ isQuizSelection, prevCompleted, enablePreview });
  const noDrop = (isQuizSelection || !prevCompleted) && !enablePreview;
  return (
    <ListItem
      key={index}
      style={{ cursor: noDrop ? 'no-drop' : 'pointer' }}
      className={activePage === index + 1 ? 'active-background' : ''}
    >
      {item?.type === 'milestone' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: item?.completed ? '#83bf6e' : '#535a62',
            height: '40px',
            cursor: noDrop ? 'no-drop' : 'pointer',
          }}
        >
          <div style={{ display: 'flex' }}>
            <img
              src={flagIcon}
              style={{
                width: '20px',
                height: '20px',
                marginRight: '20px',
                marginLeft: '0',
              }}
            />
            <span
              style={{ fontSize: '14px', fontWeight: '600', color: '#ffffff' }}
            >
              {item?.title}
            </span>
          </div>
        </div>
      ) : (
        // <div className='milestone-box'>
        //   <div className='milestone-txt'>
        //     <Image
        //       src={flagIcon}
        //       boxSize='full'
        //       backgroundSize='cover'
        //       style={{
        //         height: '20px',
        //         width: '20px',
        //         marginRight: 10,
        //         cursor: 'pointer',
        //       }}
        //     />
        //     {item?.title}
        //   </div>
        // </div>
        <HStack
          spacing='15px'
          style={{ height: '80px' }}
          onClick={() => {
            if (!noDrop) {
              selectedTrainingItem(item?.thumbnail, index);
            } else {
              return;
            }
          }}
        >
          <HStack
            style={{
              minWidth: '85%',
              paddingLeft: 6,
              paddingRight: 6,
              cursor: noDrop ? 'no-drop' : 'pointer',
            }}
          >
            <Box>
              <div
                className='member-list-item'
                style={{ cursor: noDrop ? 'no-drop' : 'pointer' }}
              >
                <img
                  className='memberImage'
                  style={{ cursor: noDrop ? 'no-drop' : 'pointer' }}
                  src={item?.thumbnail || logo}
                />
              </div>
            </Box>

            <VStack spacing={-8} align='stretch'>
              <Box w='max-content'>
                <ListItem className='training-item-type'>
                  {item?.type ? item?.type : 'N/A'}
                </ListItem>
              </Box>
              <Box w='max-content'>
                <ListItem className='training-item-name'>
                  {item.title ? item.title : 'N/A'}
                </ListItem>
              </Box>
            </VStack>
          </HStack>

          {!enablePreview && (
            <div className=''>
              {item?.completed ? (
                <div className='isChecked'>
                  <CheckedIcon
                    style={{
                      width: '13.7px',
                      height: '13.7px',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              ) : (
                <div className='unChecked'></div>
              )}
            </div>
          )}
        </HStack>
      )}
      {/* <Divider className='divider' /> */}
    </ListItem>
  );
};

export default ViewTrainingItem;

import { gql, useMutation, useReactiveVar } from '@apollo/client';
import {
  Center,
  Checkbox,
  Divider,
  Flex,
  Image,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { PrimaryButton, SearchInput } from 'atoms';
import { AuthRole } from 'authorization';
import { UPDATE_LOCATION_LAUNCH } from 'pages/launcher/details/graphql/location-launch-details.graphql';
import React, { useEffect, useMemo, useState } from 'react';
import { AuthRoleFilter } from 'sub-components/CustomDropdowns';
import { ISelectedFilter } from 'sub-components/CustomDropdowns/AuthRoleFilter/AuthRoleFilter';
import { SelectOption } from 'sub-components/CustomDropdowns/CheckboxDropdown/CheckboxDropdown';
import EmptyState from 'sub-components/EmptyState';
import { usersEntityObj } from 'sub-components/Header';
import { getImage } from 'utils';
import useCombinedStore from 'zustandStore/store';

const UPDATE_SUPERVISORS = gql`
  mutation UpdateLocationLaunchPhase(
    $eid: String!
    $phaseEid: String!
    $assignedUsers: [String]
  ) {
    UpdateLocationLaunchPhase(
      eid: $eid
      phaseId: $phaseEid
      assignedUsers: $assignedUsers
    ) {
      eid
    }
  }
`;

interface IProps {
  foundUsers: SelectOption[];
  phaseEid?: string;
  locationId?: string;
  closeModal: () => void;
}

const SupervisorContent: React.FC<IProps> = ({
  foundUsers,
  phaseEid,
  locationId,
  closeModal,
}) => {
  const emitEvent = useCombinedStore((state) => state.emitEvent);
  const [selectedFilter, setSelectedFilter] =
    useState<ISelectedFilter>(undefined);
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const entityData = useReactiveVar(usersEntityObj);

  const [updateSupervisors, { loading }] = useMutation<
    { eid: string },
    { eid: string; phaseEid: string; assignedUsers: string[] }
  >(UPDATE_SUPERVISORS, {
    onCompleted: () => {
      updateLocationSupervisors({
        variables: {
          input: {
            launchId: locationId,
            supervisors: selectedUserIds,
          },
        },
      });
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Supervisors could not be updated',
      });
      closeModal();
    },
  });

  const [updateLocationSupervisors, { loading: updatingLocationSupervisors }] =
    useMutation(UPDATE_LOCATION_LAUNCH, {
      // This mutation is required to set the selected assignees as the supervisors for location as well
      onCompleted: () => {
        toast({
          status: 'success',
          title: 'Supervisors updated',
        });
        closeModal();
        emitEvent('LauncherDetailsPage', 'fetchCompleteData', {
          msg: 'Emitted successfully',
        });
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Supervisors could not be updated',
        });
        closeModal();
      },
    });

  const filteredUsers = useMemo(() => {
    let allFilteredUsers = entityData?.filter(
      (data) =>
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(data?.authRole) &&
        data?.type === 'user'
    );
    if (selectedFilter && selectedFilter?.value) {
      allFilteredUsers = allFilteredUsers?.filter(
        (user) => user?.authRole === selectedFilter.value
      );
    }
    if (searchQuery) {
      return allFilteredUsers?.filter((user) =>
        user?.name
          ?.trim()
          ?.toLowerCase()
          ?.includes(searchQuery?.trim()?.toLowerCase())
      );
    }
    return allFilteredUsers;
  }, [entityData, searchQuery, selectedFilter]);

  useEffect(() => {
    let ids = foundUsers?.map((user) => user?.eid || '')?.filter(Boolean);
    setSelectedUserIds(ids);
  }, [foundUsers]);

  const updateSupervisorsHandler = () => {
    if (locationId && phaseEid) {
      updateSupervisors({
        variables: {
          assignedUsers: selectedUserIds,
          eid: locationId,
          phaseEid: phaseEid,
        },
      });
    }
  };

  const calcAuthRole = (authRole: string) => {
    return authRole?.split(/(?=[A-Z])/).join(' ');
  };

  const isAllSelected = useMemo(() => {
    return filteredUsers?.every((user) => selectedUserIds.includes(user?.eid));
  }, [filteredUsers, selectedUserIds]);

  const checkAllHandler = (selected: boolean) => {
    if (selected) {
      let allUserIds = filteredUsers?.map((user) => user?.eid);
      setSelectedUserIds(allUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const userCheckboxHandler = (selected: boolean, userId: string) => {
    setSelectedUserIds((prev) => {
      if (selected) {
        if (!prev.includes(userId)) {
          return [...prev, userId];
        }
        return prev;
      } else {
        return prev.filter((id) => id !== userId);
      }
    });
  };

  const handleFilterChange = (option: ISelectedFilter) => {
    setSelectedFilter(option);
  };

  return (
    // @ts-ignore
    <Flex flexDir='column' w='full' gap={4}>
      <Flex flexDir='column' w='full' gap={4}>
        <Flex align='center' gap='5px'>
          <Flex w='65%'>
            <SearchInput
              placeholder='Search by name'
              hideShortcuts
              value={searchQuery}
              onChange={(e) => setSearchQuery(e?.target?.value)}
            />
          </Flex>
          <Flex w='35%'>
            <AuthRoleFilter
              options={[
                { label: 'Super Admins', value: AuthRole.SUPER_ADMIN },
                { label: 'Admins', value: AuthRole.ADMIN },
              ]}
              selectedFilter={selectedFilter}
              onFilterChange={handleFilterChange}
            />
          </Flex>
        </Flex>
        {filteredUsers?.length > 0 && (
          <Flex align='center' justify='space-between'>
            <Text fontWeight={500} color='rgba(111, 118, 126, 1)'>
              SELECT ALL
            </Text>
            <Checkbox
              mr='8px'
              isChecked={isAllSelected}
              onChange={(e) => {
                console.log('selection type : ', e?.target?.checked);
                checkAllHandler(e?.target?.checked);
              }}
            />
          </Flex>
        )}
      </Flex>
      {filteredUsers?.length === 0 && (searchQuery || selectedFilter) ? (
        <Center w='full' maxH='40vh' minH='40vh'>
          <EmptyState title='Search data not found' image='Search' />
        </Center>
      ) : (
        <Flex flexDir='column' w='full' gap={4} pr='4px'>
          <VStack
            divider={<Divider />}
            align='flex-start'
            gap={2}
            maxH='40vh'
            minH='40vh'
            overflow='scroll'
          >
            {filteredUsers?.map((user) => {
              return (
                <Flex
                  align='center'
                  key={user?.eid}
                  justify='space-between'
                  w='full'
                >
                  <Flex align='center' gap='10px'>
                    <Image
                      src={getImage(user?.profilePic, user?.name)}
                      boxSize='40px'
                      borderRadius='50%'
                    />
                    <Flex flexDir='column'>
                      <Text fontWeight={600} color='rgba(23, 23, 37, 1)'>
                        {user?.name}
                      </Text>
                      <Text
                        fontWeight={500}
                        color='rgba(146, 146, 157, 1)'
                        textTransform='capitalize'
                      >
                        {calcAuthRole(user?.authRole)}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex>
                    <Checkbox
                      isChecked={selectedUserIds?.some(
                        (id) => id === user?.eid
                      )}
                      onChange={(e) =>
                        userCheckboxHandler(e?.target?.checked, user?.eid)
                      }
                    />
                  </Flex>
                </Flex>
              );
            })}
          </VStack>
          <PrimaryButton
            title='Update supervisors'
            colorScheme='blue'
            disabled={
              loading ||
              selectedUserIds?.length === 0 ||
              updatingLocationSupervisors
            }
            isLoading={loading || updatingLocationSupervisors}
            onClick={updateSupervisorsHandler}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default SupervisorContent;

import styled from '@emotion/styled';

interface IProps {
  chatDisabled?: boolean;
}

export const SingleUserWrapper = styled.div<IProps>`
  position: fixed;
  top: 0;
  right: 0;
  width: ${(props) => (props.chatDisabled ? '400px' : '608px')};
  background-color: #fcfcfc;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  box-shadow: -10px 4px 10px rgba(153, 153, 153, 0.2);
  overflow-y: auto;
  z-index: 9999999999;
  border-radius: 8px 0 0 8px;

  .chat__secondary-view {
    width: 400px;
  }

  .chat__header,
  .chat__list {
    margin: 0 24px;
    width: calc(100% - 48px);
  }

  .chat__header {
    padding: 12px;
    .user__status {
      color: #6f767e;
    }
  }

  .chat__list,
  .chat__composer {
    border: unset;
    background: unset;
  }

  .chat__composer {
  }
`;

import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Flex, IconButton } from '@chakra-ui/react';
import { cloneDeep } from '@apollo/client/utilities';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';

import { HeaderColors } from '../../../../shared/headerColors/header-colors';
import { eventBus } from '../../../../shared/eventEmit';

import { IFormInput } from './editor.types';
import { EditorEvent, useEditorContext } from './context';
import { useSwitchConfirm } from './useSwitchConfirm';

const PublishedHeader: FC = () => {
  const { t } = useTranslation(['launcher']);
  const history = useHistory();

  const { emit } = useEditorContext();
  const { setValue, getValues } = useFormContext<IFormInput>();

  const [activeStep, contents] = useWatch<
    IFormInput,
    ['activeStep', 'contents']
  >({
    name: ['activeStep', 'contents'],
  });

  const maxLength = useMemo(() => {
    return contents?.filter((it) => it.selected)?.length || 0;
  }, [contents]);

  const isLast = activeStep + 1 >= maxLength;

  const focusNewTask = (newTask: IFormInput['newTaskAddress']) => {
    const [nextEditable, taskEditable] = getValues([
      `${newTask!}.tempEid`,
      'taskEditable',
    ]);

    if (nextEditable === taskEditable) {
      setTimeout(() => eventBus.emit('newLauncherTask'), 200);
    } else {
      setValue('taskEditable', nextEditable);
      setTimeout(() => eventBus.emit('newLauncherTask'), 1000);
    }
  };

  const onChange = (currentIndex: number, newIndex: number) => {
    emit(EditorEvent.UPDATE_PHASE_STATUS, currentIndex);

    const newTask = getValues('newTaskAddress');

    if (newTask) {
      focusNewTask(newTask);
    } else {
      setValue('activeStep', newIndex);
    }
  };

  const switchConfirm = useSwitchConfirm();

  const onSwitchToBoardView = () => {
    const newTask = getValues('newTaskAddress');
    if (newTask) {
      return switchConfirm({
        onYesPress: () => {
          const contents = cloneDeep(getValues('contents'));

          const newContents = contents.map((content) => {
            return {
              ...content,
              tasks: content.tasks.filter((it) => !!it.eid),
            };
          });

          setValue('contents', newContents);
          setValue('newTaskAddress', undefined);

          history.replace('?viewMode=board');
        },
        onNoPress: () => focusNewTask(newTask),
      });
    }
    history.replace('?viewMode=board');
  };

  return (
    <Flex align='center' gap={3}>
      <Flex gap={1}>
        <IconButton
          isDisabled={!activeStep || activeStep < 1}
          style={{
            backgroundColor: 'white',
          }}
          aria-label='prev'
          icon={
            <FontAwesomeIcon icon={faChevronLeft as IconProp} color='#6F767E' />
          }
          onClick={() => onChange(activeStep, activeStep - 1)}
        />

        <IconButton
          isDisabled={isLast}
          style={{
            backgroundColor: 'white',
          }}
          aria-label='next'
          icon={
            <FontAwesomeIcon
              icon={faChevronRight as IconProp}
              color='#6F767E'
            />
          }
          onClick={() => onChange(activeStep, activeStep + 1)}
        />
      </Flex>

      <Button
        variant='outline'
        style={{
          color: HeaderColors.DarkBlue,
          backgroundColor: 'white',
        }}
        borderColor='transparent'
        fontSize='15px'
        fontWeight='600'
        onClick={onSwitchToBoardView}
      >
        <Trans t={t} i18nKey='launcher:switchToBoardView' />
      </Button>
    </Flex>
  );
};

export default PublishedHeader;

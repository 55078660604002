import { ChapterModal } from './chapter-modal';
import { ChapterItem } from '../chapter.types';

export class ChapterListModal {
  protected _data: Array<ChapterModal> = [];
  constructor(data: ChapterItem[] = []) {
    this._data = data.map((value) => {
      return new ChapterModal(value);
    });
  }

  get data() {
    return this._data;
  }

  get length() {
    return this._data.length;
  }
}

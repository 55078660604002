import { ApolloClient } from '@apollo/client';
import { StringOrNumber } from 'types';
import { toArray } from 'utils';

import { usersEntityObj } from '../../../Header';
import { downloadMultiFormResponse } from '../../../../pages/forms/FormResponse/FormResponseTable/GenerateFormResponseSheetHandler/GenerateFormResponseSheet';

import {
  BULK_FORM_DELETE,
  BULK_RESPONSE_EXPORT,
  CategoryResponseExport,
  GET_CATEGORY_FORMS_COUNT,
  ResponseExportEntity,
} from './delete.graphql';

export const getCategoryFormCount = async (
  client: ApolloClient<object>,
  categoryId: string
): Promise<number> => {
  return client
    .query({
      query: GET_CATEGORY_FORMS_COUNT,
      fetchPolicy: 'network-only',
      variables: {
        page: 1,
        perPage: 1,
        filter: {
          categoryId: categoryId,
        },
      },
    })
    .then((response) => {
      if (response.errors) {
        return Promise.reject(response.errors);
      }
      return response.data?.FormsPagination?.count;
    });
};

export const bulkFormsDelete = async (
  client: ApolloClient<object>,
  categoryId: string,
  formIds?: StringOrNumber[]
): Promise<boolean> => {
  return client
    .mutate({
      mutation: BULK_FORM_DELETE,
      variables: {
        categoryId: categoryId,
        formIds: formIds,
      },
    })
    .then((response) => {
      if (response.errors) {
        return Promise.reject(response.errors);
      }
      return response.data?.BulkDeleteForms?.succeed;
    });
};

export const bulkResponseExport = async (
  client: ApolloClient<object>,
  categoryId: string,
  formIds?: StringOrNumber[]
): Promise<void> => {
  return client
    .query<CategoryResponseExport>({
      query: BULK_RESPONSE_EXPORT,
      fetchPolicy: 'network-only',
      variables: {
        categoryId: categoryId,
        formIds: formIds,
      },
    })
    .then((response) => {
      if (response.errors) {
        return Promise.reject(response.errors);
      }
      return response.data?.FormCategoryResponseExport;
    })
    .then(async (data) => {
      const responses = toArray(data?.responses).reduce((acc, item) => {
        if (acc[item.formId]) {
          acc[item.formId].push(item);
        } else {
          acc[item.formId] = [item];
        }

        return acc;
      }, {} as Record<string, ResponseExportEntity[]>);

      await downloadMultiFormResponse(
        data.forms,
        responses,
        usersEntityObj() as never
      );
    });
};

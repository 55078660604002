import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

interface IProps {
  value: string;
}

const LocationType: FC<IProps> = ({ value }) => {
  return (
    <Flex
      align='center'
      textOverflow='ellipsis'
      overflow='hidden'
      whiteSpace='nowrap'
      color={
        value === 'corporate'
          ? '#8E59FF'
          : value === 'franchise'
          ? '#2A85FF'
          : 'black'
      }
      bg={
        value === 'corporate'
          ? '#CABDFF4D'
          : value === 'franchise'
          ? '#B1E5FC4D'
          : 'none'
      }
      p='5px 10px'
      borderRadius='6px'
      fontSize='12px'
      fontWeight='600'
      w='fit-content'
      textTransform='capitalize'
    >
      {value || '-'}
    </Flex>
  );
};

export default LocationType;

import { membersCompare } from './membersCompare';
import { ICompareVisibilityType } from './visibilityHelper';

export const parentNoConditionChildOrCondition = (parent: any, child: any) => {
  console.log({ PARENT_NO_CHILD_OR: { parent, child } });
  let visibilityType: ICompareVisibilityType = undefined;
  let parentCombinations = [...parent?.locations, ...parent?.jobs];
  let childORCombinations = [...child?.locations, ...child?.jobs];
  parent?.locations?.forEach((location: any) => {
    parent?.jobs?.forEach((role: any) => {
      parentCombinations?.push(`${location} or ${role}`);
    });
  });

  child?.locations?.forEach((location: any) => {
    child?.jobs?.forEach((role: any) => {
      childORCombinations?.push(`${location} or ${role}`);
    });
  });
  let commonCombinations = parentCombinations?.filter((element) =>
    childORCombinations?.includes(element)
  );
  console.log({
    parentNoChildOr: {
      parentCombinations,
      childORCombinations,
      commonCombinations,
    },
  });
  let flag: ICompareVisibilityType = 'parentChildNoIntersection';
  if (commonCombinations?.length > 0) {
    // Child Contains all parent's elements
    const childContainsAllOfParent = parentCombinations?.every((element) =>
      childORCombinations?.includes(element)
    );

    // Parent Contains all child's elements
    const parentContainsAllOfChild = childORCombinations?.every((element) =>
      parentCombinations?.includes(element)
    );

    // Both child and parent have exactly same elements
    if (childContainsAllOfParent && parentContainsAllOfChild) {
      visibilityType = 'parentChildSameVisibleSameLength';
      flag = visibilityType;
    }

    // Both parent and child have same length but they have different elements
    if (
      !childContainsAllOfParent &&
      childORCombinations?.length === parentCombinations?.length
    ) {
      visibilityType = 'parentChildSameLengthDifferentVisibility';
      flag = visibilityType;
    }

    // Child has all parent elements but has its own elements as well making child > parent
    if (
      childContainsAllOfParent &&
      childORCombinations?.length > parentCombinations?.length
    ) {
      visibilityType = 'parentLessVisibleThanChild';
      flag = visibilityType;
    }

    // Child contains fewer elements than parent making parent > child
    if (
      !childContainsAllOfParent &&
      childORCombinations?.length < parentCombinations?.length
    ) {
      visibilityType = 'parentMoreVisibleThanChild';
      flag = visibilityType;
    }

    // Child does not contain all the elements of the parent but has more elements than parent
    if (
      !childContainsAllOfParent &&
      childORCombinations?.length > parentCombinations?.length
    ) {
      visibilityType = 'parentLessVisibleThanChild';
      flag = visibilityType;
    }
  } else {
    visibilityType = 'parentChildNoIntersection';
    flag = visibilityType;
  }
  console.log('initial flag : ', flag);
  let finalFlag = membersCompare(
    flag,
    parent?.members || [],
    child?.members || []
  );
  console.log({ PARENT_NO_CHILD_OR_FLAG: finalFlag });
  return finalFlag;
};

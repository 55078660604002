import {
  Box,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Menu,
  Text,
  Flex,
  Center,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput, SelectOption } from 'atoms';
import { t } from 'i18next';
import React, { FC, useContext, useMemo, useState } from 'react';
import TrainingPathContext from '../TrainingPathStore/training-path-context';
import {
  faCheck,
  faFilter,
  faFolder,
  faFolderUser,
} from '@fortawesome/pro-regular-svg-icons';
import { useReactiveVar } from '@apollo/client';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { useFolderSubFolder } from 'hooks';
import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import Dropdown from 'atoms/Dropdown';
import { chakraComponents } from 'chakra-react-select';
import EmptyState from 'sub-components/EmptyState';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

interface IProps {
  setCategoryHandler: (id: string) => void;
}

const SearchFilter: FC<IProps> = ({ setCategoryHandler }) => {
  const trainingPathCtx = useContext(TrainingPathContext);
  const foldersList = useReactiveVar(foldersListVar);
  const [searchQuery, setSearchQuery] = useState('');
  const [typeFilter, setTypeFilter] = useState<SelectOption | null>(null);
  const { getAllFolderSubFolderList } = useFolderSubFolder();

  const onTypeFilterChange = (value: SelectOption<any>) => {
    setTypeFilter(value);
  };

  const itemsList = useMemo(() => {
    let _allFolderSubFolders: CustomCategoryEntity[] =
      getAllFolderSubFolderList();
    let allFolderSubFolders: CustomCategoryEntity[] = JSON.parse(
      JSON.stringify(_allFolderSubFolders)
    );
    if (typeFilter) {
      allFolderSubFolders = allFolderSubFolders.filter((item) => {
        if (typeFilter.value === 'all') return true;
        if (typeFilter.value === 'subFolder') return item.type === 'subFolder';
        if (typeFilter.value === 'folder') return item.type === 'folder';
        return false;
      });
    }
    if (searchQuery) {
      allFolderSubFolders = allFolderSubFolders.filter((item) =>
        item.name
          .toLowerCase()
          .trim()
          .includes(searchQuery.toLowerCase().trim())
      );
    }

    return allFolderSubFolders.sort((a, b) => a.name.localeCompare(b.name));
    // if (!searchQuery) {
    //   return filteredFolderSubFolderList?.sort((a, b) =>
    //     a.name.localeCompare(b.name)
    //   );
    // } else {
    //   return filteredFolderSubFolderList
    //     ?.filter((item) =>
    //       item?.name
    //         ?.toLowerCase()
    //         ?.trim()
    //         ?.includes(searchQuery?.toLowerCase()?.trim())
    //     )
    //     ?.sort((a, b) => a.name.localeCompare(b.name));
    // }
  }, [foldersList, searchQuery, typeFilter]);

  return (
    <Box display={'flex'} gap={2}>
      <SearchInput
        hideShortcuts
        placeholder={t('chapter:knowledgeBaseSearchPlaceholder')}
        value={trainingPathCtx?.searchedHandbookString}
        onChange={(e) =>
          trainingPathCtx?.setSearchedHandbookStringHandler(e?.target?.value)
        }
      />
      <Menu>
        {({ isOpen, onClose }) => (
          <>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<FontAwesomeIcon icon={faFilter as IconProp} />}
              style={{
                border: isOpen
                  ? '1px solid rgba(42, 133, 255, 1)'
                  : '1px solid rgba(239, 239, 239, 1)',
                backgroundColor: isOpen ? 'rgba(42, 133, 255, 1)' : 'white',
                color: isOpen ? 'white' : 'rgba(111, 118, 126, 1)',
              }}
            />
            <MenuList padding={'0'} overflowY={'auto'} maxHeight={'420px'}>
              <Flex flexDir='column' w='full' gap={4} pb={4}>
                <Flex m={4} align='center' gap={2}>
                  <Flex w='80%'>
                    <SearchInput
                      placeholder='Search folder or subfolder'
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e?.target?.value)}
                      hideShortcuts
                    />
                  </Flex>
                  <Flex zIndex='100'>
                    <Dropdown
                      placeholder={t('handbook:type')}
                      value={typeFilter}
                      onChange={onTypeFilterChange}
                      options={[
                        {
                          label: 'All',
                          value: 'all',
                        },
                        {
                          label: 'Folders',
                          value: 'folder',
                        },
                        {
                          label: 'Subfolders',
                          value: 'subFolder',
                        },
                      ]}
                      selectStyles={{
                        singleValue: {
                          color: '#6f767e',
                          fontWeight: '600',
                        },
                        inputContainer: {
                          width: '70px',
                        },
                      }}
                      components={{
                        SingleValue: (valProps) => {
                          return (
                            <chakraComponents.SingleValue {...valProps}>
                              {/* @ts-ignore */}
                              {t('handbook:type_by', {
                                value: valProps.children,
                              })}
                            </chakraComponents.SingleValue>
                          );
                        },
                      }}
                    />
                  </Flex>
                </Flex>
                {searchQuery && itemsList?.length === 0 && (
                  <EmptyState
                    image='Search'
                    title='No folder or subfolder found.'
                    description='Oops! Try a different search to get results.'
                  />
                )}
                {itemsList?.length > 0 && (
                  <Flex flexDir='column' overflowY='auto' maxH='40vh'>
                    {itemsList?.map((list) => (
                      <Flex
                        id='b-m-c-l-item'
                        key={list?.eid}
                        // _selected={{
                        //   backgroundColor: '#2A85FF1F',
                        // }}
                        aria-selected={
                          trainingPathCtx?.selectedHandbookFilterOption ===
                          list?.eid
                        }
                        w='full'
                        cursor='pointer'
                        onClick={() => {
                          setCategoryHandler(list?.eid);
                          onClose();
                        }}
                        _hover={{ bg: '#EFEFEF' }}
                        _notLast={{
                          '> div ': {
                            borderBottom: '1px solid #EFEFEF',
                          },
                        }}
                      >
                        <Flex w='full' align='center' mx='32px' py='10px'>
                          <Flex flex={1} align='center' gap={2}>
                            <Center
                              boxSize='32px'
                              border='1px solid #DDDDDD'
                              borderRadius='5px'
                            >
                              <FontAwesomeIcon
                                icon={
                                  (list?.visibility === 'private'
                                    ? faFolderUser
                                    : faFolder) as IconProp
                                }
                                color='#6F767E'
                                fontSize='15px'
                              />
                            </Center>
                            <Text isTruncated maxW='140px'>
                              {list?.name}
                            </Text>
                            {list?.parentFolderName && (
                              <Flex gap='5px' align='center'>
                                <Text>in</Text>
                                <Text fontWeight={700} isTruncated maxW='140px'>
                                  {list?.parentFolderName}
                                </Text>
                              </Flex>
                            )}
                          </Flex>
                          {list?.eid !==
                          trainingPathCtx?.selectedHandbookFilterOption ? (
                            <Text
                              fontWeight={500}
                              fontSize='12px'
                              color='#979EA6'
                            >
                              {list?.type === 'folder'
                                ? 'Folder'
                                : 'Sub Folder'}
                            </Text>
                          ) : (
                            <FontAwesomeIcon
                              icon={faCircleCheck as IconProp}
                              color='rgba(131, 191, 110, 1)'
                              fontSize='20px'
                            />
                          )}
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                )}
              </Flex>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default SearchFilter;

import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Box, Center, Image as ChakraImage } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

import {
  TemplateResponse,
  getTemplate,
  TemplateItem,
} from './form-template.graphql';
import Loader from '../../Loader';
import SearchInput from '../../../atoms/SearchInput';
import noImage from '../../../assets/images/placeholder/empty-template.svg';

import FormTemplateItem from './FormTemplateItem';

interface IProps {
  loadingCategory: boolean;
  category: string;
  onClick: (template: TemplateItem) => void;
}

const FormTemplateList: FC<IProps> = ({
  category,
  loadingCategory,
  onClick,
}) => {
  const { t } = useTranslation('form');
  const [searchQuery, setSearchQuery] = useState('');

  const [fetchData, { data, loading }] = useLazyQuery<TemplateResponse>(
    getTemplate,
    {
      fetchPolicy: 'network-only',
      variables: {
        perPage: 6,
        isTemplate: true,
      },
    }
  );

  useEffect(() => {
    fetchData({
      variables: {
        perPage: 6,
        filter: {
          category: [category],
          isTemplate: true,
        },
      },
    });
  }, [category]);

  const debouncedFetchData = debounce(async (query) => {
    const title = query.trim();

    const filter: { query?: string; category?: string[] } = {
      category: [category],
    };

    if (title) {
      filter.query = title;
    }

    fetchData({
      variables: {
        perPage: 6,
        filter: filter,
      },
    });
  }, 500);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    debouncedFetchData(event.target.value);
    setSearchQuery(event.target.value);
  };

  const isLoading = loading || loadingCategory;

  const templateItems: TemplateItem[] =
    data?.FormsTemplatePagination?.items || [];

  return (
    <div className='template-list-container'>
      <SearchInput
        disabled={true}
        size='md'
        width='280px'
        hideShortcuts={true}
        placeholder={t('search_template')}
        onChange={handleChange}
        value={searchQuery}
        className='template-search'
      />
      <div className='template-list'>
        {isLoading ? (
          <Center mx='auto'>
            <Loader size='lg' />
          </Center>
        ) : (
          <>
            {templateItems?.length === 0 && (
              <Center w='full'>
                <ChakraImage src={noImage} maxW='340px' />
              </Center>
            )}
            {templateItems?.map((template) => {
              return (
                <FormTemplateItem
                  key={template.eid}
                  data={template}
                  onClick={() => onClick(template)}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default FormTemplateList;

import { gql } from '@apollo/client';

export const GET_ALL_LOCATION_OWNERS = gql`
  query GetAllLocationOwners(
    $entityId: String!
    $authRole: [userAuthRoleEnum]
  ) {
    EntityUser(entityId: $entityId, authRole: $authRole) {
      authRole
      eid
      locations {
        eid
        name
      }
      name
      profilePic
      role
    }
  }
`;

export const UPDATE_LOCATION_OWNERS_FOR_LOCATION = gql`
  mutation UpdateLocationOwnersForLocation($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      eid
    }
  }
`;

import { createAvatar } from '@dicebear/core';
import * as initials from '@dicebear/initials';

const getAvatar = (seed?: string) => {
  return createAvatar(initials, {
    seed: seed,
  }).toDataUri();
};

export const getImage = (image?: string, name?: string): string => {
  if (image?.includes('avatars.dicebear.com/api/identicon/')) {
    return `https://api.dicebear.com/7.x/identicon/svg?seed=${image.slice(
      image.lastIndexOf('/') + 1,
      image.indexOf('.svg')
    )}`;
  }
  if (image?.includes('avatars.dicebear.com/api/initials/')) {
    return `https://api.dicebear.com/7.x/initials/svg?seed=${image.slice(
      image.lastIndexOf('/') + 1,
      image.indexOf('.svg')
    )}`;
  }

  if (image) {
    return image;
  }

  return getAvatar(name);
  // return `https://api.dicebear.com/7.x/initials/svg?seed=${name}`;
  // return `https://api.dicebear.com/7.x/initials/svg?seed=${name}&backgroundType=gradientLinear`;
};

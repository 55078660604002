import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import CommonLabel from '../CommonLabel';
import PercentDetails from '../Top/PercentDetails';
import { InProgressBar } from '../Top/ProgressBar';

interface IProps {
  tasksTotalCount: number;
  tasksCompletedCount: number;
}

const TotalTasksDone: FC<IProps> = ({
  tasksCompletedCount,
  tasksTotalCount,
}) => {
  return (
    <Flex flexDir='column' w='full' h='full' justify='space-between'>
      <CommonLabel label='Total tasks done' />
      <InProgressBar
        completed={tasksCompletedCount}
        totalCount={tasksTotalCount}
      />
      <PercentDetails
        orientation={'horizontal'}
        completed={tasksCompletedCount}
        totalCount={tasksTotalCount}
      />
    </Flex>
  );
};

export default TotalTasksDone;

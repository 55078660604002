import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import FilterBadge, { IFilterBadge } from '../Singleton/FilterBadge';

interface IProps {
  list: IFilterBadge[];
}

const FilterBadgeList: FC<IProps> = (props) => {
  const { list } = props;
  return (
    <Flex gap={2}>
      {list?.map((_l, index) => (
        <FilterBadge key={index} {..._l} />
      ))}
    </Flex>
  );
};

export default FilterBadgeList;

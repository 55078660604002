import { userObj } from 'sop-commons/src/client';
import { toArray } from '../../../../utils/utils';
import { TrainingBaseModal } from './training-base';
import { MyTrainingItem } from '../types';
import { TrainingContent, TrainingItems } from '../types/training.content';

function filterCard<T extends any = {}>(val: T) {
  // @ts-ignore
  return val?.type === 'card' || val?.type === 'sop';
}

export class MyTrainingModal extends TrainingBaseModal<MyTrainingItem> {
  protected _assignedTo: MyTrainingItem['assignedToUsers'];
  protected _userProgress?: MyTrainingItem['userProgress'][number];
  protected _totalViewedCount: number;
  protected _maxCount: number;
  private _isDemoAccount: boolean | undefined;

  constructor(props: MyTrainingItem) {
    super(props);

    this._assignedTo = toArray(props.assignedToUsers).filter(
      (it) => it?.eid === userObj()?.eid
    );

    this._userProgress = toArray(props.userProgress).find(
      (_it) => _it?.userId === userObj()?.eid
    );

    this._totalViewedCount = toArray(this._userProgress?.viewedContents).filter(
      filterCard
    ).length;

    this._maxCount = toArray(props?.trainingContentTiny).filter(
      filterCard
    ).length;
  }

  get assignedTo() {
    return this._assignedTo;
  }

  get status() {
    return this._data.status;
  }

  get updatedAt() {
    return this._data.updatedAt;
  }

  get viewCount() {
    return this._userProgress?.viewedContents?.length || 0;
  }

  get totalCount() {
    return this._data.trainingContentTiny?.length || 0;
  }

  get trainingContentList() {
    return this._data.trainingContentList;
  }

  get trainingContentTiny() {
    return this._data.trainingContentTiny;
  }

  get trainingItems() {
    // return this._data.trainingItems?.map(item => {
    //   return { ...item, subType: 'sop' };
    // });
    let dataItems: TrainingItems[] = this._data.trainingContentTiny;

    let returnedData = dataItems?.map((item) => {
      if (item?.cards && item.cards.length > 0) {
        let itemCards = item.cards;
        let returnedItemCards = itemCards.map((card) => {
          return { ...card, subType: 'sop' };
        });
        return {
          ...item,
          cards: returnedItemCards,
        };
      } else {
        return item;
      }
    });
    return returnedData;
  }

  get isDownloadable() {
    return !!this._userProgress?.firstCompletedAt;
  }

  get totalViewedCount() {
    return this._totalViewedCount;
  }

  get maxCount() {
    return this._maxCount;
  }

  get certificateId() {
    return this._userProgress?.certificate;
  }

  get userProgress(): MyTrainingItem['userProgress'][number] | undefined {
    return this._userProgress;
  }

  get isDemoAccount(): boolean {
    return !!this._isDemoAccount;
  }

  set isDemoAccount(value: boolean) {
    this._isDemoAccount = value;
  }
}

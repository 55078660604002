import React, { FC } from 'react';
import { Accordion, Flex } from '@chakra-ui/react';
import { BoxHeader } from '../../../../ui-components';

import TemplatePhase from './TemplatePhase';
import StartButton from './StartButton';

import { TemplateContent } from '../board-view/template.graphql'; // TODO
import AccordionSkeleton from './AccordionSkeleton';

interface IProps {
  title: string;
  contents: TemplateContent[];
  initialising?: boolean;
}

const TemplateView: FC<IProps> = ({ title, contents, initialising }) => {
  return (
    <Flex flexDir='column' gap='16px'>
      <BoxHeader fontSize='18px' title={title} color='#CABDFF' />
      <AccordionSkeleton isLoaded={!initialising}>
        <Accordion defaultIndex={[]} allowMultiple>
          {contents.map((content) => {
            return <TemplatePhase key={content._id} content={content} />;
          })}
        </Accordion>
      </AccordionSkeleton>
      <StartButton
        title={title}
        initialising={initialising}
        contents={contents}
      />
    </Flex>
  );
};

export default TemplateView;

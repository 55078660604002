import { gql } from '@apollo/client';

export const GET_TRAINING_CHANGE_LOG = gql`
  query TrainingChangeLog($trainingId: String!) {
    TrainingChangeLog(trainingId: $trainingId) {
      updatedBy {
        name
        eid
        profilePic
      }
      trainingId
      changes {
        changeElement
        changeEvent
        userId
        title {
          old
          new
        }
        milestone {
          old
          new
        }
        icon {
          old
          new
        }
        locationId
        roles
        contentId
        comment
        meta
      }
      status
      updatedAt
      createdAt
      eid
    }
  }
`;

export const CREATE_CHANGE_LOG = gql`
  mutation CreateChangeLog($input: TrainingChangeLogInput) {
    CreateChangeLog(input: $input) {
      updatedBy {
        name
        profilePic
        eid
      }
      trainingId
      changes {
        changeElement
        changeEvent
        userId
        title {
          old
          new
        }
        milestone {
          old
          new
        }
        icon {
          old
          new
        }
        contentId
        comment
      }
      status
      updatedAt
      createdAt
      eid
    }
  }
`;

export const UPDATE_CHANGE_LOG = gql`
  mutation UpdateChangeLog($updateChangeLogInput2: UpdateChangeLogInput) {
    UpdateChangeLog(input: $updateChangeLogInput2) {
      updatedBy {
        name
        profilePic
        eid
      }
      trainingId
      changes {
        changeElement
        changeEvent
        userId
        title {
          old
          new
        }
        milestone {
          old
          new
        }
        icon {
          old
          new
        }
        contentId
        comment
      }
      status
      updatedAt
      createdAt
      eid
    }
  }
`;

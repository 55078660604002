import React, { FC } from 'react';
import {
  useFieldArray,
  UseFieldArrayMove,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import { Trans, useTranslation } from 'react-i18next';

import { updateObject } from '../../../../utils/objectHelper';

import { SectionAction, SectionTitleEditor } from '../common';
import { IFormInput, ITaskItem } from '../editor-form/editor.types';
import { EditorEvent, useEditorContext } from '../editor-form';
import { AddTaskButton } from '../board-view/add-task';

import TaskItemContainer from './TaskItemContainer';
import { useLauncherTaskForm } from './task-form';
import { useTaskItemUpdate } from './useTaskItemUpdate';
import { usePostSetupTaskDelete } from './delete-task';

interface IProps {
  sectionIndex: number;
  isLastSection?: boolean;
  moveSectionTo: UseFieldArrayMove;
  onPhaseDeleteClick: (index: number) => void;
}

const TaskSection: FC<IProps> = ({
  sectionIndex,
  isLastSection,
  moveSectionTo,
  onPhaseDeleteClick,
}) => {
  const { t } = useTranslation(['launcher']);
  const { emit } = useEditorContext();

  const { control, setValue, getValues } = useFormContext<IFormInput>();
  const title = useWatch<IFormInput, `contents.${number}.category`>({
    name: `contents.${sectionIndex}.category`,
  });

  const launcherTaskForm = useLauncherTaskForm();
  const deleteTask = usePostSetupTaskDelete();

  const { fields, insert, update, remove, move } = useFieldArray<
    IFormInput,
    `contents.${number}.tasks`
  >({
    control: control,
    name: `contents.${sectionIndex}.tasks`,
  });

  const updateTask = useTaskItemUpdate({
    sectionIndex,
    update,
    insert,
    remove,
  });

  const onEditTask = (data: ITaskItem, index: number) => {
    launcherTaskForm({
      index: index,
      initialValue: {
        ...data,
        oldCategory: getValues(`contents.${sectionIndex}.eid`)!,
        newCategory: getValues(`contents.${sectionIndex}.eid`)!,
        categories: getValues('contents').map((ct) => ({
          eid: ct.eid!,
          category: ct.category,
        })),
      },
      onUpdate: (values) => updateTask(values, index),
    });
  };

  const onTitleUpdate = async (index: number, newTitle: string) => {
    await emit(EditorEvent.UPDATE_PHASE, {
      index: index,
      title: newTitle,
    });

    setValue(`contents.${index}.category`, newTitle, {
      shouldDirty: true,
    });
  };

  const onDeleteClick = (data: ITaskItem, taskIndex: number) => {
    const nextTask = getValues(
      `contents.${sectionIndex}.tasks.${taskIndex + 1}`
    );
    deleteTask({
      nextTask: nextTask,
      onDeletePress: async () => {
        await emit(EditorEvent.DELETE_TASK, {
          taskIndex: taskIndex,
          categoryId: getValues(`contents.${sectionIndex}.eid`)!,
          task: data,
        });

        if (nextTask?.dependency === 'PREVIOUS_TASK') {
          updateObject(nextTask, ['dependency'], 'INDEPENDENT');
          update(taskIndex + 1, nextTask);
        }

        remove(taskIndex);
      },
    });
  };

  const actionHandler = (action: string, value: ITaskItem, index: number) => {
    switch (action) {
      case 'edit':
        return onEditTask(value, index);
      case 'delete':
        return onDeleteClick(value, index);
      case 'add-above':
        return onEditTask({} as never, index);
      case 'add-below':
        return onEditTask({} as never, index + 1);
    }
  };

  const onTaskMove = async (currentIndex: number, nextIndex: number) => {
    move(currentIndex, nextIndex);
    await emit(EditorEvent.MOVE_TASK, {
      currentIndex,
      nextIndex,
      sectionIndex,
    });
  };

  return (
    <Flex flexDir='column' gap={3} width='300px' minW='300px'>
      <Flex gap={8} mb={3} align='start'>
        <Flex flex={1} flexDir='column' gap='6px'>
          <Flex gap={3} align='center'>
            <SectionTitleEditor
              title={title}
              index={sectionIndex}
              onUpdate={onTitleUpdate}
            >
              <Box>
                <Tooltip
                  label={title}
                  hasArrow
                  borderRadius='6px'
                  padding='8px 10px'
                  placement='top-start'
                  minWidth='100px'
                  maxWidth='250px'
                  bg='#000000CC'
                >
                  <Box
                    fontSize='16px'
                    fontWeight='600'
                    color='#1A1D1F'
                    noOfLines={1}
                  >
                    {title}
                  </Box>
                </Tooltip>
              </Box>
            </SectionTitleEditor>
          </Flex>
          <Box fontSize='15px' color='#6F767E'>
            <Trans
              t={t}
              i18nKey='launcher:noOfTask'
              count={fields.length}
              values={{ count: fields.length }}
            />
          </Box>
        </Flex>
        <SectionAction
          index={sectionIndex}
          isLastSection={isLastSection}
          moveSectionTo={moveSectionTo}
          onDeleteClick={onPhaseDeleteClick}
        />
      </Flex>

      <AddTaskButton onClick={() => onEditTask({} as never, fields.length)} />

      {/*// @ts-ignore */}
      <AnimatePresence mode='popLayout'>
        {fields.map((value, index, arr) => (
          <TaskItemContainer
            key={value.id}
            index={index}
            isLast={arr.length === index + 1}
            data={value}
            actionHandler={(action) => actionHandler(action, value, index)}
            moveTask={onTaskMove}
          />
        ))}
      </AnimatePresence>
    </Flex>
  );
};

TaskSection.displayName = 'pages/launcher/config-editor/post-setup/TaskSection';

export default TaskSection;

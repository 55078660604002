import { gql } from '@apollo/client';
import { UserTaskProgress } from 'types';
import { RepeatCycle } from '../../../create-task/task.types';

export const SectionKeys = [
  'overdue',
  'dueToday',
  'thisWeek',
  'upcoming',
  'hidden',
  'completed',
] as const;

export type SectionKeysType = typeof SectionKeys[number];

export interface UserTaskSession {
  eid: string;
  title: string;
  taskId: string;
  task: {
    repeatCycle: RepeatCycle;
    lastSession?: {
      eid: string;
    };
  };

  startDate: string;
  dueDate: string;

  supervisorUsers: Array<{
    eid: string;
  }>;

  currentUserProgress?: UserTaskProgress;
  itemCount: number;
  isLastSession: boolean;
}

export interface UserTasksResponse {
  UserTaskSession: UserTaskSession;
}

export const USER_TASK_SESSION = gql`
  query UserTaskSession($userId: String!, $endDate: Date!) {
    UserTaskSession(userId: $userId, endDate: $endDate) {
      eid
      title
      taskId

      task {
        repeatCycle
        lastSession {
          eid
        }
      }

      startDate
      dueDate

      supervisorUsers {
        eid
      }

      currentUserProgress
      itemCount
      isLastSession
    }
  }
`;

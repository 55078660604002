import { ChakraStylesConfig, GroupBase } from 'chakra-react-select';
import { CSSObject } from '@chakra-ui/react';

export type SelectStyleKey =
  | 'menu'
  | 'control'
  | 'singleValue'
  | 'inputContainer'
  | 'valueContainer'
  | 'option'
  | 'menuList'
  | 'container'
  | 'dropdownIndicator'
  | 'placeholder'
  | 'loadingIndicator';

export type SelectStyles = {
  [key in SelectStyleKey]?: CSSObject;
};

const positions = {
  bottom: {
    marginTop: 'unset',
  },
  top: {
    marginBottom: 'unset',
  },
};

export const getChakraSelectStyles = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  selectStyles?: SelectStyles
): ChakraStylesConfig<Option, IsMulti, Group> => ({
  container: (provided) => ({
    ...provided,
    fontSize: '15px',
    backgroundColor: 'var(--chakra-colors-gray-100)',
    borderRadius: '12px',
    ...selectStyles?.container,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    bg: 'transparent',
    px: 2,
    cursor: 'inherit',
    ...selectStyles?.dropdownIndicator,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 'max-content',
    minWidth: '100%',
    padding: '8px',
    ...positions[state.placement],
    ...selectStyles?.menu,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '15px',
    ...selectStyles?.singleValue,
  }),
  inputContainer: (provided) => ({
    ...provided,
    ...selectStyles?.inputContainer,
  }),

  option: (provided) => ({
    ...provided,
    ...selectStyles?.option,
  }),
  control: (provided, state) => {
    return {
      ...provided,
      borderRadius: '12px',
      ...selectStyles?.control,
      ...(state.isFocused
        ? {
            borderColor: 'rgba(42, 133, 255, 0.35) !important',
            boxShadow: '0 0 0 2px rgba(42, 133, 255, 0.35) !important',
            color: 'inherit',
          }
        : {}),
      '&[data-invalid=true]': {
        borderWidth: 0,
        boxShadow: 'none',
        borderColor: 'rgba(42, 133, 255, 0.35) !important',
        background: 'rgba(255, 188, 153, 0.3)',
        color: 'var(--chakra-colors-red-500)',
      },
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    ...selectStyles?.valueContainer,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '15px',
    ...selectStyles?.placeholder,
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    ...selectStyles?.loadingIndicator,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow: 'var(--chakra-shadows-md)',
    ...selectStyles?.menuList,
    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#d5d5d5',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#dfdfdf',
    },
  }),
});

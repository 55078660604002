import React, { useMemo } from 'react';
import { Button, ButtonProps, CloseButton, forwardRef } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

interface IProps extends ButtonProps {
  category: string[];
  onCategoryChange: (category: string[]) => void;
}

const TriggerButton = forwardRef<IProps, 'button'>(
  ({ category, onCategoryChange, ...props }, ref) => {
    const { t } = useTranslation(['common']);

    const catLength = category?.length;

    const otherProps = useMemo((): ButtonProps => {
      if (catLength) {
        return {
          color: '#2A85FF',
          rightIcon: (
            <CloseButton
              size='sm'
              _hover={{}}
              onClick={(e) => {
                e.preventDefault();
                onCategoryChange([]);
              }}
            />
          ),
        };
      }

      return {
        color: '#6F767E',
      };
    }, [catLength]);

    return (
      <Button
        ref={ref}
        variant='outline'
        borderWidth='2px'
        {...otherProps}
        fontSize='14px'
        leftIcon={<FontAwesomeIcon icon={faBarsFilter as IconProp} />}
        {...props}
      >
        {t('common:filter')} {catLength ? `(${catLength})` : undefined}
      </Button>
    );
  }
);

export default TriggerButton;

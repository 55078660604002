import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { Box } from '@chakra-ui/react';

import {
  TrainingProgressQuery,
  TrainingProgressResponse,
} from './training-progress.graphql';
import ProgressList from './ProgressList';
import ProgressListLoader from './ProgressListLoader';

interface IProps {
  trainingId: string;
  userId?: string;
}

const TrainingProgress: FC<IProps> = ({ trainingId, userId }) => {
  const { data, loading } = useQuery<TrainingProgressResponse>(
    TrainingProgressQuery,
    {
      fetchPolicy: 'network-only',
      variables: {
        eid: trainingId,
      },
    }
  );

  return (
    <Box w='full' py={2}>
      <ProgressListLoader isLoaded={!loading}>
        <ProgressList data={data?.trainingById!} userId={userId} />
      </ProgressListLoader>
    </Box>
  );
};

export default TrainingProgress;

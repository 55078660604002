import { Flex, Text } from '@chakra-ui/react';
import { ILocationDetails } from 'pages/LocationDetails/utils/location-details.types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import GenericColorHeader from 'sub-components/GenericColorHeader';

interface IProps {
  details: ILocationDetails | undefined;
}

const EntityDetails: FC<IProps> = ({ details }) => {
  const { t } = useTranslation(['location']);
  return (
    <Flex flexDir='column' gap='20px'>
      <GenericColorHeader title={t('location:entityDetails')} color='#B1E5FC' />
      <Flex flexDir='column' gap='20px'>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:entityType')}:</Text>
          <Text>{details?.entityType || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:entityName')}:</Text>
          <Text>{details?.entityName || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:stateOfFormation')}:</Text>
          <Text>{details?.stateOfFormation || '-'}</Text>
        </Flex>
        <Flex flexDir='column'>
          <Text fontWeight={600}>{t('location:countryOfFormation')}:</Text>
          <Text>{details?.countryOfFormation || '-'}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EntityDetails;

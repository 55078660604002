import { useCallback } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import GoLiveDateContent from './GoLiveDateContent';

import { BoxHeader, useConfirm } from 'ui-components';

interface IProps {
  onProceed: (...args: any[]) => any | PromiseLike<any>;
  onCancel?: (...args: any[]) => any | PromiseLike<any>;
}

type GoLiveDate = (props: IProps) => void;

export const useGoLiveDate = (): GoLiveDate => {
  const confirm = useConfirm();

  return useCallback(
    ({ onProceed, onCancel }) => {
      confirm({
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader
                title='Insufficient duration'
                color='rgba(255, 188, 153, 1)'
              />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: <GoLiveDateContent />,
        isCentered: true,
        contentProps: {
          py: '16px',
          px: '24px',
          minW: '550px',
          borderRadius: '16px',
        },
        cancelButtonProps: {
          backgroundColor: 'rgba(243, 165, 13, 1)',
          color: 'white',
          size: 'lg',
          width: '50%',
          fontSize: '14px',
          fontWeight: '700',
          sx: {
            _hover: {
              backgroundColor: 'rgba(243, 165, 13, 1)',
            },
          },
        },
        okButtonProps: {
          colorScheme: 'blue',
          size: 'lg',
          fontSize: '14px',
          fontWeight: '700',
          width: '50%',
        },
        okText: 'Cancel',
        cancelText: 'Proceed with selected date',
        onCancel: onProceed,
        onOK: onCancel,
      });
    },
    [confirm]
  );
};

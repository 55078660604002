import React, { FC } from 'react';
import { Flex, Image, ListItem, Text } from '@chakra-ui/react';

import { MessageIconButton } from 'atoms';
import { LocationMember } from 'types';
import { RoleAndAuthRole } from 'ui-components';
import { getImage } from 'utils';
import { useHasChatAccess } from 'hooks/useChatRestriction';

interface IProps {
  member: LocationMember;
  onClick?: (value: LocationMember) => void;
  onSendMessage: (userId: string) => void;
}

const OwnerMemberItem: FC<IProps> = ({ member, onClick, onSendMessage }) => {
  const _hasChatAccess = useHasChatAccess();
  const hasChatAccess = _hasChatAccess({
    eid: member?.eid,
    role: member?.role,
    authRole: member?.authRole,
    name: member?.name,
  });
  console.log('HAS CHAT ACCESS : ', hasChatAccess);
  return (
    <ListItem>
      <Flex
        flexDir='row'
        w='full'
        alignItems='center'
        justifyContent='space-between'
      >
        <Flex
          gap='10px'
          align='center'
          cursor='pointer'
          onClick={() => onClick?.(member)}
        >
          <Image
            src={getImage(member?.profilePic, member?.name)}
            boxSize='40px'
            borderRadius='50%'
          />
          <Flex flexDir='column'>
            <Text fontWeight={600} fontSize='14px' m={0} p={0}>
              {member?.name}
            </Text>
            <RoleAndAuthRole
              role={member?.role}
              authRole={member?.authRole}
              defaultValue='-'
            />
          </Flex>
        </Flex>

        {member?.status === 'active' && (
          <MessageIconButton
            hasChatAccess={hasChatAccess}
            variant='solid'
            onClick={() => onSendMessage(member.eid)}
          />
        )}
      </Flex>
    </ListItem>
  );
};

export default OwnerMemberItem;

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HQICon } from '../../assets/images/headquarters.svg';

const Wrapper = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;

  color: ${({ isSelected }) => (isSelected ? 'inherit' : '#2a85ff')};
  font-size: 15px;
  font-weight: 600;

  .hq-dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${({ isSelected }) => (isSelected ? 'white' : '#354b76')};
    margin: 0 8px;
  }
  svg {
    margin-left: 5px;
    width: 16px;
    height: 13px;
    path {
      ${({ isSelected }) => (isSelected ? { fill: 'white' } : {})};
    }
  }
`;

interface IProps {
  data: any;
  isSelected?: boolean;
}

export const HQComp: FC<IProps> = ({ data, isSelected }) => {
  const { t } = useTranslation('common');

  if (!data?.isHQ) return null;
  return (
    <Wrapper isSelected={isSelected}>
      <div className='hq-dot' />
      {t('HQ')}
      <HQICon />
    </Wrapper>
  );
};

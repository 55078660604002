import { Divider, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { IInProgressCard } from 'sub-components/LocationLaunchDetail/types/types';
import CommonContainer from 'ui-components/CommonContainer';
import InProgressCardBottom from '../Bottom';
import InProgressCardMid from '../Mid';
import InProgressCardTop from '../Top';

type IVerticalProps = Pick<IInProgressCard, 'topDetails' | 'midDetails'>;

const Vertical: FC<IVerticalProps> = (props) => {
  const { topDetails, midDetails } = props;
  const history = useHistory();
  return (
    <CommonContainer
      display='flex'
      flexDir='column'
      w='360px'
      bg='white'
      borderRadius='12px'
      p='20px'
      gap={2}
      cursor='pointer'
      onClick={() => history.push('/launcher/detail/12345')}
    >
      <InProgressCardTop {...topDetails} />
      <Divider />
      <InProgressCardMid {...midDetails} />
      <Divider />
      <InProgressCardBottom />
    </CommonContainer>
  );
};

export default Vertical;

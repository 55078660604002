import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IFormInput } from '../editor-form/editor.types';

export const useLocalChangesCount = (): number | undefined => {
  const [changes, setChanges] = useState<number>();
  const { watch } = useFormContext<IFormInput>();

  useEffect(() => {
    const { unsubscribe } = watch((value) => {
      const updateLength = value?.contents?.reduce<number | undefined>(
        (acc, curr) => {
          if (curr?.isLocal && !acc) {
            acc = 0;
          }

          const taskLen = curr?.tasks?.filter((task) => task?.isLocal).length;

          if (taskLen) {
            acc = (acc || 0) + taskLen;
          }

          return acc;
        },
        value.publishRequired ? 0 : undefined
      );

      setChanges(updateLength);
    });
    return () => unsubscribe();
  }, [watch]);

  return changes;
};

import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import PreviewHeader from '../../../../forms/component/PreviewHeader';
import {
  AxisPercentageDomain,
  getCompleteRate,
  getGraphLabel,
  getGraphTooltipLabel,
  IGraphRange,
  toPercent,
} from '../../common';

import {
  JOB_COMPLETION_RATE_QUERY,
  CompletionRateResponse,
  CompletionRateVariable,
} from './job-performance.graphql';
import { getIndexColor } from '../../../../../utils/randomColor';
import {
  getToolTipConfig,
  GraphFooter,
  GraphLoader,
  GraphMonthWeekSelect,
  useGraphRangeVariable,
} from '../../common/graph';

interface IProps {}

const JobCompleteRateGraph: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'task']);

  const [variables, setVariable] = useGraphRangeVariable();

  const { data, loading } = useQuery<
    CompletionRateResponse,
    CompletionRateVariable
  >(JOB_COMPLETION_RATE_QUERY, {
    fetchPolicy: 'network-only',
    variables: variables,
  });

  const [graphData, jobs] = useMemo(() => {
    const resDta = data?.TaskSupervisedRoleWiseTrend || {};

    const newData = Object.entries(resDta).reduce((acc, [job, records]) => {
      Object.entries(records).forEach(([weekMonth, value]) => {
        const prev = acc[weekMonth];

        if (prev) {
          acc[weekMonth] = {
            ...prev,
            [job]: getCompleteRate(value),
          };
        } else {
          acc[weekMonth] = {
            tooltipLabel: getGraphTooltipLabel(value, variables.type),
            month: getGraphLabel(value, variables.type),
            [job]: getCompleteRate(value),
          };
        }
      });

      return acc;
    }, {} as Record<string, object>);

    return [Object.values(newData), Object.keys(resDta)];
  }, [data, variables]);

  const onGraphTypeChange = (newVariables: IGraphRange) => {
    setVariable((prevState) => {
      return {
        ...prevState,
        ...newVariables,
      };
    });
  };

  return (
    <Flex
      flexDir='column'
      borderRadius='10px'
      padding='16px 20px'
      bg='white'
      gap='24px'
    >
      <Flex justify='space-between'>
        <PreviewHeader
          title={t('task:taskCompRateJobWise')}
          fontSize='18px'
          color='#B5E4CA'
        />

        <GraphMonthWeekSelect data={variables} onChange={onGraphTypeChange} />
      </Flex>

      <GraphLoader isLoading={loading} minHeight={260}>
        <Box pr='16px'>
          <ResponsiveContainer width='100%' height={260}>
            <LineChart data={graphData}>
              <CartesianGrid vertical={false} strokeDasharray='3 3' />
              <XAxis axisLine={false} dataKey='month' />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={toPercent}
                domain={AxisPercentageDomain}
              />

              <Tooltip
                labelFormatter={(label, payload) => {
                  return (payload?.[0]?.payload?.tooltipLabel || label).concat(
                    ' (Completion score)'
                  );
                }}
                {...getToolTipConfig()}
                // @ts-ignore
                itemSorter={(item) => 0 - item.value}
              />

              {jobs.map((jobName, index) => {
                return (
                  <Line
                    key={jobName}
                    type='linear'
                    dataKey={jobName}
                    stroke={getIndexColor(index)}
                    dot={false}
                    unit='%'
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </GraphLoader>

      <GraphFooter data={variables} onChange={onGraphTypeChange} />
    </Flex>
  );
};

export default JobCompleteRateGraph;

import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import OwnerListField from 'pages/Locations/Components/BasicDetails/OwnerListField';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

interface IProps {}

const LocationOwnerDropdown: FC<IProps> = () => {
  const { formState } = useFormContext();
  return (
    <FormControl isInvalid={!!formState?.errors.locationOwners}>
      <OwnerListField hideLOBtn templateColumns={'repeat(1, 1fr)'} />
      <FormErrorMessage>
        {formState?.errors.locationOwners && 'Location owner must be selected.'}
      </FormErrorMessage>
    </FormControl>
  );
};

export default LocationOwnerDropdown;

import { createContext, FC, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { cloneDeep } from '@apollo/client/utilities';

import { useTranslation } from 'react-i18next';

import { userObj } from 'sop-commons/src/client';

import { toArray } from '../../../utils/utils';
import { CardType } from '../../../types';

import {
  GET_TRAINING_PLAY,
  InputVariable,
  REASSIGN_TRAINING,
  ReassignVariable,
  TrainingProgressResponse,
  TrainingResponse,
} from './training-play.graphql';

import { trainingItemToPlayList } from './convertTrainingData';
import { UserProgressEntity } from '../training.types';
import { useTrainingProgressUpdate } from './useTrainingProgressUpdate';

export interface UserData {
  eid: string;
  name: string;
  profilePic?: string;
  type: string;
}

interface TrainingPlay {
  trainingData?: TrainingResponse['trainingById'];
  trainingContents: any[];
  increment: () => void;
  decrement: () => void;
  confirmRetake: () => Promise<void>;
  selectedIndex: number;
  totalCards?: number;
  trainingItems: any[];
  userProgress?: any;
  loading?: boolean;
  updating?: boolean;
  userData: UserData;
  onFinish?: () => Promise<void> | void;
}

export const TrainingPlayContext = createContext<TrainingPlay>({
  trainingContents: [],
  trainingItems: [],
  selectedIndex: 0,
  totalCards: 0,
  confirmRetake: () => Promise.resolve(),
  increment: () => {},
  decrement: () => {},
  userData: {} as UserData,
});

export const TrainingPlayConsumer = TrainingPlayContext.Consumer;

export const getUserTrainingProgress = (
  progress: UserProgressEntity[] = [],
  userId: string
): UserProgressEntity | undefined => {
  return progress.find((it) => it.userId === userId);
};

interface IProps {
  trainingId?: string;
  userData?: UserData;
  onFinish?: () => void;
  onProgressUpdate?: (
    data: TrainingProgressResponse['UpdateUserTrainingProgress']
  ) => void;
  inputVariable?: InputVariable;
  formAccessOverride?: boolean;
}

export const TrainingPlayProvider: FC<IProps> = ({
  children,
  trainingId,
  userData: _userData,
  onFinish,
  onProgressUpdate,
  inputVariable,
  formAccessOverride,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const authUser = useReactiveVar(userObj);
  const { t } = useTranslation(['common']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const userData = useMemo((): UserData => {
    return _userData || authUser;
  }, [_userData, authUser]);

  const checkAndResumeTraining = (
    _trainingData: TrainingResponse['trainingById']
  ) => {
    const progress = _trainingData?.userProgress.find(
      (value) => value.userId === userData.eid
    );

    const viewedContentIds = toArray(progress?.viewedContents).map(
      (val) => val.uid
    );
    const _trainingItems = trainingItemToPlayList(_trainingData?.trainingItems);

    const index = _trainingItems.findIndex((item) => {
      return !viewedContentIds.includes(item?.sopId || item.eid);
    });
    setSelectedIndex(index >= 0 ? index : 0);
  };

  const { data, loading } = useQuery<TrainingResponse>(GET_TRAINING_PLAY, {
    fetchPolicy: 'network-only',
    variables: {
      eid: trainingId,
    },
    onCompleted: (response) => {
      checkAndResumeTraining(response.trainingById);
    },
  });

  const trainingData = useMemo(() => {
    const _trainingById = cloneDeep(data?.trainingById);

    const accessibleForms = toArray(data?.trainingForms).reduce(
      (previousValue, currentValue) => {
        if (currentValue.isAccessible) {
          previousValue.push(currentValue.formId);
        }
        return previousValue;
      },
      [] as string[]
    );

    if (_trainingById?.trainingItems) {
      _trainingById.trainingItems = _trainingById.trainingItems?.map(
        (value) => {
          if (value?.cardType === CardType.CustomForm) {
            return {
              ...value,
              isAccessible:
                formAccessOverride ||
                value.isMandatory ||
                accessibleForms.includes(value?.form?.eid),
            };
          }
          if (value?.type === 'milestone') {
            return {
              ...value,
              content: [
                {
                  url: value?.thumbnail,
                },
              ],
            };
          }
          return { ...value };
        }
      );
    }
    return _trainingById;
  }, [data, formAccessOverride]);

  const userProgress = useMemo(() => {
    if (trainingData?.userProgress) {
      return getUserTrainingProgress(trainingData?.userProgress, userData.eid);
    }
    return undefined;
  }, [trainingData?.userProgress, userData?.eid]);

  const { updateProgress, updating } = useTrainingProgressUpdate({
    trainingId: trainingId!,
    userId: userData.eid,
    inputVariable: inputVariable,
    onProgressUpdate: onProgressUpdate,
  });

  const [ReassignTraining] = useMutation<never, ReassignVariable>(
    REASSIGN_TRAINING,
    {
      refetchQueries: [
        {
          query: GET_TRAINING_PLAY,
          variables: {
            eid: trainingId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toast({
          title: t('common:update'),
          status: 'success',
        });
        setSelectedIndex(0);
      },
      onError: () => {
        toast({
          title: t('common:error'),
          status: 'error',
        });
      },
    }
  );

  const trainingContents = useMemo(() => {
    if (trainingData?.trainingItems) {
      return trainingItemToPlayList(trainingData.trainingItems);
    }
    return [];
  }, [trainingData]);

  const onRetakeConfirm = useCallback(async () => {
    await ReassignTraining({
      variables: {
        trainingId: trainingId!,
        userId: userData.eid,
      },
    });
  }, [trainingId, userData]);

  const decrement = useCallback(() => {
    const next = selectedIndex - 1;
    setSelectedIndex(next);
  }, [selectedIndex]);

  const increment = useCallback(() => {
    const next = selectedIndex + 1;
    updateProgress(trainingContents[selectedIndex]);
    setSelectedIndex(next);
  }, [selectedIndex, trainingContents]);

  return (
    <TrainingPlayContext.Provider
      value={{
        loading: loading,
        updating: updating,
        userData: userData,
        trainingData: trainingData,
        trainingContents,
        decrement,
        increment,
        selectedIndex,
        totalCards: trainingContents?.length || 0,
        trainingItems: toArray(trainingData?.trainingItems),
        userProgress,
        confirmRetake: onRetakeConfirm,
        onFinish: onFinish,
      }}
    >
      {children}
    </TrainingPlayContext.Provider>
  );
};

import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment/moment';

import { IconImage } from 'ui-components';

import { CommonListItem } from '../common';
import { ChapterItem } from '../formChapter.graphql';

interface IProps {
  data: ChapterItem;
  index: number;
}

const ChapterListItem: FC<IProps> = ({ data }) => {
  return (
    <CommonListItem data={data}>
      <Flex flex={1} alignItems='center' overflow='hidden'>
        <Flex mr='1rem' gap='10px' maxWidth='100%'>
          <IconImage
            icon={data.icon}
            name={data.title}
            files={data.files}
            thumbnail={data.thumbnail}
            boxSize={60}
          />

          <Flex flexDir='column' overflow='hidden'>
            <Box fontSize='12px' fontWeight='500' color='#6F767E' isTruncated>
              {data?.category}
            </Box>
            <Box fontSize='15px' fontWeight='600' color='#1A1D1F' isTruncated>
              {data?.title}
            </Box>
            <Box fontSize='12px' fontWeight='500' color='#6F767E' isTruncated>
              Last updated: {moment(data?.lastUpdated).format('DD MMMM')}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </CommonListItem>
  );
};

export default ChapterListItem;

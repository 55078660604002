import React, { FC } from 'react';
import { Box, Flex, useModalContext } from '@chakra-ui/react';

import { useIsReadonly } from '../../Profile/TabComponents';
import {
  DeleteAccount,
  ResendInvite,
} from '../../Profile/TabComponents/InfoComponent/ActionButtons';

const useCustomModalContext = (): ReturnType<typeof useModalContext> => {
  try {
    return useModalContext();
  } catch (e) {
    return {} as never;
  }
};

interface IProps {
  userId: string;
  isLoading?: boolean;
  onResendClick?: (...args: any[]) => any | PromiseLike<any>;
  refetchData?: (...args: any[]) => any | PromiseLike<any>;
}

const ProfileActionButtons: FC<IProps> = ({
  userId,
  isLoading,
  refetchData,
  onResendClick,
}) => {
  const { onClose } = useCustomModalContext();

  const isReadonly = useIsReadonly();

  if (isReadonly || isLoading) {
    return null;
  }

  return (
    <Flex gap='10px'>
      <DeleteAccount userId={userId} close={onClose} onDeleted={refetchData} />

      <Box flex={1} />

      <ResendInvite
        userId={userId}
        close={onClose}
        onResendClick={onResendClick}
        onUpdated={refetchData}
      />
    </Flex>
  );
};

export default ProfileActionButtons;

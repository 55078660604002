import React, { CSSProperties, FC } from 'react';
import styled from '@emotion/styled';
import moment from 'moment/moment';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import Image from '../../../ui-components/Image';

import { getImage } from '../../../utils';

interface Status {
  status: string;
}

const CountWrapper = styled.div<Status>`
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 500;

  ${({ status }) => ({
    backgroundColor:
      status === 'active' || status === 'draft' ? 'none' : 'white',
    opacity: status === 'active' || status === 'draft' ? '1' : '.5',
    cursor: status === 'active' || status === 'draft' ? 'default' : 'no-drop',
  })}
`;

interface IProps extends Status {
  count: number;
}

export const CardsCount: FC<IProps> = ({ status, count }) => {
  return <CountWrapper status={status}>{count}</CountWrapper>;
};

const CreatedWrapper = styled.div<Status>`
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 135px;

  ${({ status }) => ({
    backgroundColor:
      status === 'active' || status === 'draft' ? 'none' : 'white',
    opacity: status === 'active' || status === 'draft' ? '1' : '.5',
    cursor: status === 'active' || status === 'draft' ? 'pointer' : 'no-drop',
  })}
`;

interface ICProps extends Status {
  name: string;
}

export const CreatedBy: FC<ICProps> = ({ status, name }) => {
  return (
    <CreatedWrapper status={status}>
      <Tooltip label={name} hasArrow>
        {name}
      </Tooltip>
    </CreatedWrapper>
  );
};

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface ICRProps extends Status {
  name: string;
  profilePic: string;
  role: string;
}

export const CreatedByUser: FC<ICRProps> = ({
  status,
  name,
  profilePic,
  role,
}) => {
  return (
    <CreatedWrapper
      status={status}
      style={{
        width: 'max-content',
        maxWidth: '100%',
        backgroundColor: 'rgba(177, 229, 252, 0.7)',
        borderRadius: '7px',
      }}
    >
      <Tooltip label={`${name}, ${role}`} padding='4px 8px' hasArrow>
        <Flex fontSize='14px' fontWeight='600' p='5px 7px' gap='8px'>
          <Flex h='20px' w='20px'>
            <Image
              src={getImage(profilePic, name)}
              width={20}
              height={20}
              style={{
                borderRadius: 5,
                objectFit: 'cover',
              }}
            />
          </Flex>
          <Flex gap='4px' width='max-content' maxWidth='100%'>
            <Box style={wrapStyles}>{name}</Box>
          </Flex>
        </Flex>
      </Tooltip>
    </CreatedWrapper>
  );
};

const DateWrapper = styled.div<Status>`
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 500;

  ${({ status }) => ({
    backgroundColor:
      status === 'active' || status === 'draft' || status === 'valid'
        ? 'none'
        : 'white',
    opacity:
      status === 'active' || status === 'draft' || status === 'valid'
        ? '1'
        : '.5',
    cursor:
      status === 'active' || status === 'draft' || status === 'valid'
        ? 'default'
        : 'no-drop',
  })}
`;

interface DProps extends Status {
  date: string;
  format?: string;
}

export const DateCell: FC<DProps> = ({
  status,
  date,
  format = 'DD MMM YYYY',
}) => {
  return (
    <DateWrapper status={status}>{moment(date)?.format(format)}</DateWrapper>
  );
};

import React, { useCallback } from 'react';
import { useConfirm } from '../../../../ui-components/Confirm';
import ProgressContainer from './ProgressContainer';
import ProgressHeader from './ProgressHeader';

type UseTrainingProgress = (props: {
  trainingItems: any[];
  userProgress: any;
  name: string;
  profilePic?: string;
  selectedTrainingId?: string;
}) => void;

export const useTrainingProgress = (): UseTrainingProgress => {
  const confirm = useConfirm();

  return useCallback(
    ({ trainingItems, userProgress, name, profilePic, selectedTrainingId }) => {
      confirm({
        title: <ProgressHeader name={name} />,
        content: (
          <ProgressContainer
            userProgress={userProgress}
            trainingItems={trainingItems}
            name={name}
            profilePic={profilePic}
            selectedTrainingId={selectedTrainingId}
          />
        ),
        size: 'full',
        contentProps: {
          padding: '12px',
          borderRadius: 0,
          bg: '#FFFFFF',
          containerProps: {
            maxW: '436px',
            left: 'unset',
            right: 0,
          },
        },
        footer: <></>, // TODO test
      });
    },
    [confirm]
  );
};

import React, { FC } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { FormPreviewInput } from '../../form-preview.types';

import SignatureInput from './SignatureInput';

interface IProps {
  currentIndex: number;
  isPreview?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
}

const AcknowledgeInput: FC<IProps> = ({
  currentIndex,
  isPreview,
  isReadOnly,
  isDisabled,
}) => {
  const { t } = useTranslation('form');

  const { control } = useFormContext<FormPreviewInput>();

  const signature = useWatch({
    control,
    name: `response.${currentIndex}.signature`,
  });

  return (
    <Flex align='center' justify='space-between' gap={3}>
      {signature ? (
        <Controller
          name='submitDate'
          control={control}
          shouldUnregister
          defaultValue={moment().format('DD MMM YYYY')}
          render={({ field }) => {
            return (
              <Box
                whiteSpace='nowrap'
                fontWeight='500'
                color='#33383F'
                letterSpacing='-0.03em'
              >
                {field.value}
              </Box>
            );
          }}
        />
      ) : (
        <Box />
      )}

      <Controller
        name={`response.${currentIndex}.signature`}
        control={control}
        rules={{
          required: t('validation.field_required'),
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              display='flex'
              flexDir='column'
              width='auto'
              isInvalid={!!fieldState.error}
            >
              <SignatureInput
                {...field}
                isPreview={isPreview}
                isReadOnly={isReadOnly}
                isDisabled={isDisabled}
              />

              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Flex>
  );
};

export default AcknowledgeInput;

import { gql } from '@apollo/client';
import { AuthRole } from 'authorization';

export type GroupType = 'public' | 'private';

interface BaseGroupVariable {
  guid: string;
  name: string;
  type: GroupType;
  directMembers: string[];
  authRoles: AuthRole[];
  roles: string[];
  locationIds: string[];
}

export interface AddGroupVariable extends BaseGroupVariable {}

export const ADD_GROUP_QUERY = gql`
  mutation AddNewDefaultChatGroup(
    $guid: String!
    $name: String
    $type: String
    $roles: [String]
    $authRoles: [String]
    $locationIds: [String]
    $directMembers: [String]
  ) {
    addNewDefaultChatGroup(
      guid: $guid
      name: $name
      type: $type
      roles: $roles
      authRoles: $authRoles
      locationIds: $locationIds
      directMembers: $directMembers
    ) {
      guid
      name
      type
      directMembers
      authRoles
      roles
      locationIds
    }
  }
`;

export interface BaseGroupResponse {
  guid: string;
  name: string;
  type: GroupType;
  directMembers: string[];
  authRoles: AuthRole[];
  roles: string[];
  locationIds: string[];
}

export interface AddGroupResponse {
  addNewDefaultChatGroup: BaseGroupResponse;
}

export interface UpdateGroupResponse {
  updateChatGroupByGuid: BaseGroupResponse;
}

export interface UpdateGroupVariable
  extends Omit<BaseGroupVariable, 'name' | 'type'> {
  name?: string;
  type?: GroupType;
}

export const UPDATE_GROUP_QUERY = gql`
  mutation UpdateChatGroupByGuid(
    $guid: String!
    $name: String
    $type: chatGroupTypeEnum
    $roles: [String]
    $authRoles: [String]
    $locationIds: [String]
    $directMembers: [String]
  ) {
    updateChatGroupByGuid(
      guid: $guid
      name: $name
      type: $type
      roles: $roles
      authRoles: $authRoles
      locationIds: $locationIds
      directMembers: $directMembers
    ) {
      guid
      name
      type
      directMembers
      authRoles
      roles
      locationIds
    }
  }
`;

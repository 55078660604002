import React, { FC } from 'react';
import { Center } from '@chakra-ui/react';
import Loader from '../../../../Loader';

interface IProps {
  isLoading?: boolean;
}

const UserTaskLoader: FC<IProps> = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <Center minHeight='300px'>
        <Loader size='xl' />
      </Center>
    );
  }

  return <>{children}</>;
};

export default UserTaskLoader;

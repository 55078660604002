import React, { FC, useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChartColumn } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'antd';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import PrimaryButton from 'atoms/PrimaryButton';
import { IconImage } from 'ui-components';

// import { FORMS_PAGINATION } from 'pages/forms/forms-graphql';
import { FORMS_PAGINATION } from './home-form-response.graphql';
import FormType from 'pages/forms/modules/FormListInternal/FormType';
import { FormName } from 'pages/forms/modules';

import { userObj } from 'sop-commons/src/client/clientFactory';
import { SortingTable } from 'sub-components/ChakraTable/SortingTable';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import {
  IHomeFormItemsEntity,
  IHomeFormResponse,
} from './home-form-response.types';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import styled from '@emotion/styled';
import { HeaderColors } from 'shared/headerColors/header-colors';
import BaseLayoutWrapper from 'sub-components/BaseLayoutWrapper';

interface IFormRes {
  FormsResponse: {
    items: {
      completedAt: string;
      createdAt: string;
      createdBy: {
        eid: string;
        name: string;
      };
      eid: string;
      form: IHomeFormItemsEntity;
      formId: string;
      initialResponseAt?: string;
      locations: string[];
    }[];
  };
}

const GET_FORMS_RESPONSE = gql`
  query FormsResponse(
    $filter: FilterFindManyFormResponse
    $sort: SortFindManyFormsResponseInput
    $page: Int
    $perPage: Int
  ) {
    FormsResponse(
      filter: $filter
      sort: $sort
      page: $page
      perPage: $perPage
    ) {
      items {
        createdBy {
          eid
          name
        }
        eid
        formId
        form {
          category
          createdBy {
            eid
            name
            username
          }
          eid
          lastUpdatedBy {
            eid
            name
          }
          latestResponse {
            completedAt
            createdAt
            createdBy {
              eid
              name
              profilePic
              role
            }
            eid
          }
          title
          thumbnail
        }
      }
    }
  }
`;

const HomeFormResponse: FC = () => {
  const { t } = useTranslation(['common', 'home', 'form']);
  const dashboardCtx = useContext(DashboardDataContext);
  const history = useHistory();
  const userData = useReactiveVar(userObj);
  const [formData, setFormData] = useState<IFormRes>({
    FormsResponse: {
      items: [],
    },
  });
  useEffect(() => {
    getFormsList({
      variables: {
        page: 1,
        perPage: 3,
        sort: '_ID_DESC',
        filter: {
          unique: false,
        },
      },
    });
    // getFormsListHandler({
    //   variables: {
    //     page: 1,
    //     perPage: 1000,
    //     sort: '_ID_DESC',
    //     filter: {
    //       status: 'active',
    //     },
    //   },
    // });
  }, []);

  const [getFormsList, { loading }] = useLazyQuery(GET_FORMS_RESPONSE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setFormData(data);
    },
  });

  // const [getFormsListHandler, { loading }] = useLazyQuery<IHomeFormResponse>(
  //   FORMS_PAGINATION,
  //   {
  //     fetchPolicy: 'network-only',
  //     onCompleted: (data) => {
  //       let _data = JSON.parse(JSON.stringify(data));
  //       let _tempArr: any[] = [];
  //       let _truncatedArr: any[] = [];
  //       _tempArr = data?.FormsPagination?.items?.filter(
  //         (item) => item?.responseCount > 0
  //       );
  //       // data?.FormsPagination?.items?.map((item) => {
  //       //   if (
  //       //     userData?.eid === item?.createdBy.eid ||
  //       //     item?.contributors.includes(userData?.eid)
  //       //   ) {
  //       //     _tempArr.push(item);
  //       //   }
  //       // });
  //       if (_tempArr.length > 3) {
  //         _truncatedArr = _tempArr.slice(0, 3);
  //         _data.FormsPagination.items = _truncatedArr;
  //       } else {
  //         _data.FormsPagination.items = _tempArr;
  //       }
  //       console.log({ FORMS__DATA: _data });
  //       setFormData(_data);
  //       // formCtx.updateFormsListDataHandler({ count: 0, items: [], pageInfo: { currentPage: 0, hasNextPage: false, hasPreviousPage: false, itemCount: 0, pageCount: 0, perPage: 0 } });
  //       if (_data?.FormsPagination?.items?.length > 0) {
  //         dashboardCtx?.showHomePageEmptyStateHandler(false);
  //       }
  //     },
  //     onError: (error) => {},
  //   }
  // );

  const getColumns = () => {
    let tableColumns: any = [
      {
        Header: t('form:form_name_header'),
        accessor: 'formName' as const,
        width: '40%',
        Cell: ({ cell: { value, row } }: any) => {
          // return <FormName formData={value} hideUpdatedBy latestResponseText />;
          return (
            <Flex mr='1rem' maxWidth='100%' gap='10px'>
              <div>
                <IconImage
                  name={value.title}
                  thumbnail=''
                  boxSize={60}
                  borderRadius='6px'
                />
              </div>
              <Flex flexDir='column' overflow='hidden' justify='space-around'>
                <Box fontWeight='700' whiteSpace='pre-wrap'>
                  {value?.title}
                </Box>
                <span
                  style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#6F767E',
                  }}
                >
                  <Trans
                    t={t}
                    i18nKey={'common:last_updated_date'}
                    values={{
                      date: moment(value?.latestResponse?.completedAt).format(
                        'DD MMM'
                      ),
                    }}
                  />
                </span>
                <span
                  style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    color: '#6F767E',
                  }}
                >
                  {t('common:by_lower')}{' '}
                  {value?.lastUpdatedBy?.name || value?.createdBy?.name}
                </span>
              </Flex>
            </Flex>
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('home:submitted_by'),
        accessor: 'latestResponse',
        width: '30%',
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <Flex
              align='center'
              gap='5px'
              bg='#CABDFF66'
              w='fit-content'
              borderRadius='7px'
              p='5px 10px'
            >
              <IconImage
                name={value?.createdBy?.name}
                thumbnail={value?.createdBy?.profilePic}
                boxSize={25}
                borderRadius='5px'
              />
              <Tooltip
                label={
                  value?.createdBy?.role
                    ? `${value?.createdBy?.name}, ${value?.createdBy?.role}`
                    : value?.createdBy?.name
                }
                hasArrow
                placement='top'
              >
                <Flex cursor='pointer'>
                  <Text
                    isTruncated
                    maxW='50px'
                    fontWeight={600}
                    fontSize='12px'
                  >
                    {value?.createdBy?.name}
                  </Text>
                  {value?.createdBy?.role && (
                    <>
                      ,&nbsp;
                      <Text isTruncated maxW='50px' fontSize='12px'>
                        {value?.createdBy?.role}
                      </Text>
                    </>
                  )}
                </Flex>
              </Tooltip>
            </Flex>
          );
        },
        disableSortBy: true,
      },
      // {
      //   Header: t('form:form_type'),
      //   accessor: 'formType' as const,
      //   width: '20%',
      //   Cell: ({ cell: { value, row } }: any) => {
      //     return <FormType text={value} index={row?.index} />;
      //   },
      //   disableSortBy: true,
      // },
      // {
      //   Header: t('form:responses'),
      //   accessor: 'responses' as const,
      //   width: '20%',
      //   Cell: ({ cell: { value, row } }: any) => {
      //     return (
      //       <Box>
      //         <Text as={'b'}>{value?.responseCount}</Text>
      //         {value?.responseCount > 0 && (
      //           <>
      //             <Text fontSize={'12px'} color={'rgba(111, 118, 126, 1)'}>
      //               {t('home:last_response')}
      //             </Text>
      //             <Text fontSize={'12px'} color={'rgba(111, 118, 126, 1)'}>
      //               {value?.latestResponse?.completedAt
      //                 ? moment(value?.latestResponse?.completedAt)?.fromNow()
      //                 : '-'}
      //             </Text>
      //           </>
      //         )}
      //       </Box>
      //     );
      //   },
      //   disableSortBy: true,
      // },
      {
        Header: '',
        accessor: 'action' as const,
        width: '20%',
        disableSortBy: true,
      },
    ];
    return tableColumns;
  };

  const getTableData = () => {
    let tableData: Array<{
      item: IHomeFormItemsEntity;
      formName: IHomeFormItemsEntity;
      responses: IHomeFormItemsEntity;
      formType: string;
      latestResponse: any;
      action: any;
    }> = [];
    formData?.FormsResponse?.items?.map((item, index: number) => {
      tableData?.push({
        item: item?.form,
        formName: item?.form,
        responses: item?.form,
        formType: item?.form?.category,
        latestResponse: item?.form?.latestResponse,
        action: (
          <Flex
            alignItems={'center'}
            gap={3}
            border={'1px solid rgba(221, 221, 221, 1)'}
            width={'fit-content'}
            borderRadius={'8px'}
            padding={'6px 14px'}
            cursor={'pointer'}
            onClick={() => {
              let url = '';
              if (item?.form?.latestResponse?.createdBy?.eid) {
                url = `/forms/response/${item?.formId}?userId=${item?.form?.latestResponse?.createdBy?.eid}`;
              } else {
                url = `/forms/response/${item?.formId}`;
              }
              history.push(url, {
                backToTitle: 'Back to Home',
              });
            }}
          >
            <FontAwesomeIcon
              icon={faChartColumn as IconProp}
              color={'#6F767E'}
            />
            <Text as={'b'}>{t('form:response')}</Text>
          </Flex>
        ),
      });
    });
    return [...tableData];
  };

  return formData?.FormsResponse?.items?.length > 0 ? (
    <BaseLayoutWrapper p={6} flexDir='column'>
      <Flex width={'full'} justifyContent={'space-between'} align='center'>
        <GenericColorHeader
          color={HeaderColors.Yellow}
          title={t('home:formResponses')}
        />
        <Box>
          <PrimaryButton
            variant='outline'
            style={{ color: '#6F767E' }}
            title={t('home:view_all')}
            onClick={() => history.push('/forms')}
          />
        </Box>
      </Flex>
      <SortingTable
        colorScheme='blue'
        emptyData={{
          text: t('no_data_found'),
        }}
        page={1}
        onPageChange={() => {}}
        columns={getColumns()}
        data={getTableData()}
        // data={[]}
        isLoading={loading}
        // totalRegisters={formCtx?.formsListData?.pageInfo?.itemCount}
        isResponsive
        isHidePagination
        customWrapperStyles={{ marginTop: '1rem' }}
      />
    </BaseLayoutWrapper>
  ) : null;
};

export default HomeFormResponse;

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { HeaderColors } from 'shared/headerColors/header-colors';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'atoms';
import FileVisibility from 'pages/Chapters/CreateChapterModal/FileVisibility';
import React, { FC } from 'react';
import VisibilityStatusInfo from 'ui-components/VisibilityStatusInfo';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { IVisibility } from 'pages/Chapters/CreateChapterModal/UploadFile/UploadFile';
import { IFolderVisibilityType, IModalStep } from '../FolderVisibility';
import useCombinedStore from 'zustandStore/store';

interface IProps {
  isOpen: boolean;
  locationsList: any[];
  membersList: any[];
  rolesList: any[];
  modalType: IFolderVisibilityType;
  categoryId: string;
  onClose: () => void;
  updateModalStep: (step: IModalStep) => void;
}

const InitialRender: FC<IProps> = ({
  isOpen,
  modalType,
  categoryId,
  onClose,
  updateModalStep,
}) => {
  const { hierarchyData, operationType, visibilityScope, updateOperationType } =
    useCombinedStore();
  const initialData =
    visibilityScope === 'folder'
      ? hierarchyData?.parentFolder?.details
      : visibilityScope === 'chapter'
      ? hierarchyData?.subFolder?.details
      : hierarchyData?.chapter?.details;

  const getName = () => {
    switch (visibilityScope) {
      case 'folder':
        return hierarchyData?.parentFolder?.details?.name || 'Folder';
      case 'subFolder':
        return hierarchyData?.subFolder?.details?.name || 'Subfolder';
      case 'chapter':
        return hierarchyData?.chapter?.details?.title || 'Chapter';
      default:
        return '';
    }
  };

  console.log({ hierarchyData, operationType, visibilityScope, initialData });

  const getParentFolderName = () => {
    switch (visibilityScope) {
      case 'folder':
      case 'subFolder':
        return hierarchyData?.parentFolder?.details?.name || '';
      case 'chapter':
        return hierarchyData?.subFolder
          ? hierarchyData?.subFolder?.details?.name
          : hierarchyData?.parentFolder?.details?.name || '';
      default:
        return '';
    }
  };

  const visibilityStatusInfoRender = () => {
    switch (visibilityScope) {
      case 'folder':
        return (
          <VisibilityStatusInfo
            hitApi
            parentDetails={hierarchyData?.parentFolder?.visibilityData}
            childDetails={hierarchyData?.subFolder?.visibilityData}
            type='folder'
            categoryId={categoryId}
            folderName={getName()}
            parentFolderName={getParentFolderName()}
          />
        );
      case 'subFolder':
        return (
          <>
            {/* Comparison with parent folder visibility */}
            <VisibilityStatusInfo
              parentDetails={hierarchyData?.parentFolder?.visibilityData}
              childDetails={hierarchyData?.subFolder?.visibilityData}
              type='subFolder'
              categoryId={categoryId}
              folderName={getName()}
              parentFolderName={getParentFolderName()}
            />
            {/* Comparison with api data of chapters inside the sub folder */}
            <VisibilityStatusInfo
              hitApi
              parentDetails={hierarchyData?.subFolder?.visibilityData}
              childDetails={undefined}
              type='subFolder'
              categoryId={categoryId}
              folderName={getName()}
              parentFolderName={getParentFolderName()}
              withReferenceTo='chapter'
            />
          </>
        );
      case 'chapter':
        return (
          <VisibilityStatusInfo
            parentDetails={
              hierarchyData?.subFolder
                ? hierarchyData?.subFolder?.visibilityData
                : hierarchyData?.parentFolder?.visibilityData
            }
            childDetails={hierarchyData?.chapter?.visibilityData}
            type='chapter'
            categoryId={categoryId}
            folderName={getName()}
            parentFolderName={getParentFolderName()}
          />
        );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} size='2xl'>
      <ModalOverlay />
      <ModalContent p={4} borderRadius='16px'>
        <ModalHeader>
          <Flex align='center' justify='space-between'>
            <GenericColorHeader
              color={HeaderColors.Purple}
              title={`${getName()} visibility`}
            />
            <ModalCloseButton m={5} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex flexDir='column' gap={4}>
            <FileVisibility
              isEditMode
              hideChangeVisibilityBtn
              viewDirection='top-bottom'
              type={modalType}
              customElement={<Text>{getName()} is visible to -</Text>}
            />
            {visibilityStatusInfoRender()}
            <Flex justify='flex-end' gap={4} w='full'>
              <PrimaryButton
                title='Cancel'
                variant='outline'
                style={{ width: '128px' }}
                onClick={onClose}
              />
              <PrimaryButton
                title='Edit'
                leftIcon={<FontAwesomeIcon icon={faPen as IconProp} />}
                variant='solid'
                colorScheme='blue'
                style={{ width: '140px' }}
                onClick={() => {
                  updateModalStep(2);
                  updateOperationType('edit');
                }}
              />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InitialRender;

import FormDataContext from 'pages/forms/FormStore/form-data-context';
import React, { FC, useContext, useMemo } from 'react';
import { ResponsiveDivWrapper } from '../FormResponseTable.styles';
import { MemberComponentWrapper } from './MemberComponent.styles';

import { getImage } from 'utils';
import { UserListEntity } from '../../../../../types';

interface IProps {
  memberId: string;
  userList?: UserListEntity[];
}

const MemberComponent: FC<IProps> = ({ memberId, userList: _userList }) => {
  const responseByIdData = useContext(FormDataContext)?.formResponseByIdData;

  const userList = responseByIdData?.eid
    ? responseByIdData?.userList
    : _userList;

  const memberData = useMemo(() => {
    return userList?.find((user) => user.eid === memberId);
  }, [userList, memberId]);

  return (
    <ResponsiveDivWrapper>
      <MemberComponentWrapper>
        {memberData?.name && (
          <div className='member-div'>
            <div>
              <img
                className='member-img'
                src={getImage(memberData?.profilePic, memberData?.name)}
              />
            </div>
            <span className='member-span'>{memberData?.name}</span>
          </div>
        )}
        {!memberData?.name && (
          <div style={{ minWidth: '300px', maxWidth: '300px' }}>-</div>
        )}
      </MemberComponentWrapper>
    </ResponsiveDivWrapper>
  );
};

export default MemberComponent;

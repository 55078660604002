import { match } from 'ts-pattern';
import moment from 'moment';
import { IFormInput } from '../task.types';
import { getPublishDate } from './helper';

export const endDateValidation = (
  value: Date,
  values?: IFormInput
): boolean => {
  let publishDate = getPublishDate(
    values?.startDateType!,
    values?.startDate,
    values?.startTime
  );

  if (values?.isEditing) {
    if (values?.endCondition?.endType === 'date') {
      publishDate = moment().isSameOrAfter(publishDate)
        ? moment()
        : publishDate;
    } else {
      return false;
    }
  }

  return match(values?.repeatCycle!)
    .with('daily', () => moment(value).diff(publishDate, 'hour') < 24)
    .with('weekly', () => moment(value).diff(publishDate, 'day') < 7)
    .with('monthly', () => moment(value).diff(publishDate, 'month') < 1)
    .with('yearly', () => moment(value).diff(publishDate, 'year') < 1)
    .with(
      'days',
      () => moment(value).diff(publishDate, 'day') < values?.noOfDays!
    )
    .otherwise(() => false);
};

export const getMinDate = (values?: IFormInput): moment.Moment => {
  let minDate = getPublishDate(
    values?.startDateType!,
    values?.startDate,
    values?.startTime
  );

  if (values?.isEditing) {
    if (values?.endCondition?.endType === 'date') {
      minDate = moment().isSameOrAfter(minDate) ? moment() : minDate;
    } else {
      return minDate;
    }
  }

  return match(values?.repeatCycle!)
    .with('daily', () => minDate.add(24, 'hour'))
    .with('weekly', () => minDate.add(7, 'day'))
    .with('monthly', () => minDate.add(1, 'month'))
    .with('yearly', () => minDate.add(1, 'year'))
    .with('days', () => minDate.add(values?.noOfDays!, 'day'))
    .otherwise(() => minDate);
};

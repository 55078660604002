// @ts-ignore
import { debug } from 'console';
import { HandbookResponse } from 'pages/Handbook/handbook.graphql';
import { IPowerUpModalTypes } from 'pages/Training/PowerUpModal/power-up.types';
import React, { FC, useState } from 'react';
import { IIconList } from '../../../../../sub-components/TrainingPathIconsModal';
import { HandbookData } from '../../../../Handbook/handbook.types';
import {
  IAssignEntity,
  ITrainingCategoryEntity,
  ITrainingType,
} from '../../training-path-types';
import { ChapterResponse } from '../TrainingHandbookList/trainining-handbook.graphql';
import TrainingPathContext from './training-path-context';

const TrainingPathDataProvider: FC = (props) => {
  const [trainingPathType, setTrainingPathType] =
    useState<ITrainingType>('add');
  const [isCreate, setIsCreate] = useState(true);
  const [stepCount, setStepCount] = useState<1 | 2>(1);
  const [isCounterMovedFromFirstStep, setIsCounterMovedFromFirstStep] =
    useState(false);
  const [trainingPathName, setTrainingPathName] = useState('');
  const [trainingPathIcon, setTrainingPathIcon] = useState<IIconList>({
    createdAt: '',
    description: '',
    backgroundColor: '',
    eid: '',
    label: '',
    tags: [],
    type: '',
    updatedAt: '',
    url: '',
  });
  const [trainingPathIconsList, setTrainingPathIconsList] = useState<
    IIconList[]
  >([]);
  const [addedItemsCount, setAddedItemsCount] = useState(0);
  const [decksCount, setDecksCount] = useState(0);
  const [cardsCount, setCardsCount] = useState(0);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedDeck, setSelectedDeck] = useState(null);
  const [shouldMoveToNextStep, setShouldMoveToNextStep] = useState(false);
  const [addedItems, setAddedItems] = useState<HandbookData[]>([]);
  const [isCardCheckboxSelected, setIsCardCheckboxSelected] = useState(false);
  const [isDeckCheckboxSelected, setIsDeckCheckboxSelected] = useState(false);
  const [searchedHandbookString, setSearchedHandbookString] = useState('');
  const [handbookCategoryList, setHandbookCategoryList] = useState<
    ITrainingCategoryEntity[]
  >([]);
  const [selectedHandbookFilterOption, setSelectedHandbookFilterOption] =
    useState<string | undefined>(undefined);
  const [selectedHandbookItem, setSelectedHandbookItem] = useState<
    HandbookData | undefined
  >(undefined);
  const [handbookItemsList, setHandbookItemsList] = useState<HandbookResponse>({
    handbookPagination: {
      count: 0,
      items: [],
      pageInfo: {
        currentPage: 0,
        itemCount: 0,
        pageCount: 0,
        perPage: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      },
    },
  });
  const [chapterItemsList, setChapterItemsList] = useState<ChapterResponse>({
    SopPagination: {
      count: 0,
      items: [],
      pageInfo: {
        currentPage: 0,
        itemCount: 0,
        pageCount: 0,
        perPage: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      },
    },
  });
  const [handbookListLoading, setHandbookListLoading] = useState(false);
  const [chapterListLoading, setChapterListLoading] = useState(false);
  const [shouldShowHiddenItems, setShouldShowHiddenItems] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedSupervisors, setSelectedSupervisors] = useState<string[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [rolesList, setRolesList] = useState<IAssignEntity[]>([]);
  const [supervisorsList, setSupervisorsList] = useState<IAssignEntity[]>([]);
  const [locationsList, setLocationsList] = useState<IAssignEntity[]>([]);
  const [membersList, setMembersList] = useState<IAssignEntity[]>([]);
  const [selectedPowerupType, setSelectedPowerupType] =
    useState<IPowerUpModalTypes['types']>('');
  const [returnedData, setReturnedData] = useState<Object>({});

  const setTrainingPathTypeHandler = (type: ITrainingType) => {
    setTrainingPathType(type);
  };

  const setIsCreateHandler = (value: boolean) => {
    setIsCreate(value);
  };

  const setStepCountHandler = (value: 1 | 2) => {
    setStepCount(value);
  };

  const isCounterMovedFromFirstStepHandler = (value: boolean) => {
    setIsCounterMovedFromFirstStep(value);
  };

  const setTrainingPathNameHandler = (name: string) => {
    setTrainingPathName(name);
  };

  const setTrainingPathIconHandler = (icon: IIconList) => {
    setTrainingPathIcon(icon);
  };

  const setTrainingPathIconsListHandler = (icons: IIconList[]) => {
    console.log('setTrainingPathIconsListHandler => ', icons);
    setTrainingPathIconsList(icons);
  };

  const setCountHandler = () => {
    let cardsCount = 0;
    let decksCount = 0;
    let itemsCount = 0;
    if (addedItems?.length > 0) {
      addedItems?.map((item) => {
        if (item?.type === 'card') {
          cardsCount++;
        } else if (item?.type === 'deck') {
          // let _count = item?.cards?.length || 0;
          // decksCount += _count;
          decksCount++;
        }
      });
    }
    itemsCount = cardsCount + decksCount;
    setAddedItemsCount(itemsCount);
    setDecksCount(decksCount);
    setCardsCount(cardsCount);
  };

  const setSelectedCardHandler = (card: any) => {
    setSelectedCard(card);
  };

  const setSelectedDeckHandler = (deck: any) => {
    setSelectedDeck(deck);
  };

  const setShouldMoveToNextStepHandler = (value: boolean) => {
    setShouldMoveToNextStep(value);
  };

  const setAddedItemsHandler = (items: HandbookData[]) => {
    setAddedItems(items);
  };

  const setIsCardCheckboxSelectedHandler = (value: boolean) => {
    setIsCardCheckboxSelected(value);
  };

  const setIsDeckCheckboxSelectedHandler = (value: boolean) => {
    setIsDeckCheckboxSelected(value);
  };

  const setHandbookCategoryListHandler = (items: ITrainingCategoryEntity[]) => {
    setHandbookCategoryList(items);
  };

  const setSelectedHandbookItemHandler = (item: HandbookData) => {
    setSelectedHandbookItem(item);
  };

  const setHandbookItemsListHandler = (items: HandbookResponse) => {
    setHandbookItemsList(items);
  };

  const setChapterItemsListHandler = (items: ChapterResponse) => {
    setChapterItemsList(items);
  };

  const setHandbookListLoadingHandler = (value: boolean) => {
    setHandbookListLoading(value);
  };

  const setChapterListLoadingHandler = (value: boolean) => {
    setChapterListLoading(value);
  };

  const setShouldShowHiddenItemsHandler = (value: boolean) => {
    setShouldShowHiddenItems(value);
  };

  const setSelectedRolesHandler = (items: string[]) => {
    setSelectedRoles(items);
  };

  const setSelectedSupervisorsHandler = (items: string[]) => {
    setSelectedSupervisors(items);
  };

  const setSelectedMembersHandler = (items: string[]) => {
    setSelectedMembers(items);
  };

  const setSelectedLocationsHandler = (items: string[]) => {
    setSelectedLocations(items);
  };

  const setRolesListHandler = (items: IAssignEntity[]) => {
    setRolesList(items);
  };

  const setSelectedSupervisorsListHandler = (items: IAssignEntity[]) => {
    setSupervisorsList(items);
  };

  const setLocationsListHandler = (items: IAssignEntity[]) => {
    setLocationsList(items);
  };

  const setMembersListHandler = (items: IAssignEntity[]) => {
    setMembersList(items);
  };

  const setSelectedPowerupTypeHandler = (type: IPowerUpModalTypes['types']) => {
    setSelectedPowerupType(type);
  };

  const setReturnedDataHandler = (data: Object) => {
    setReturnedData(data);
  };

  const setSelectedHandbookFilterOptionHandler = (id?: string) => {
    setSelectedHandbookFilterOption(id);
  };

  const trainingPathContext = {
    trainingPathType: trainingPathType,
    isCreate: isCreate,
    stepCount: stepCount,
    isCounterMovedFromFirstStep: isCounterMovedFromFirstStep,
    trainingPathName: trainingPathName,
    trainingPathIcon: trainingPathIcon,
    trainingPathIconsList: trainingPathIconsList,
    addedItemsCount: addedItemsCount,
    decksCount: decksCount,
    cardsCount: cardsCount,
    selectedCard: selectedCard,
    selectedDeck: selectedDeck,
    shouldMoveToNextStep: shouldMoveToNextStep,
    addedItems: addedItems,
    isCardCheckboxSelected: isCardCheckboxSelected,
    isDeckCheckboxSelected: isDeckCheckboxSelected,
    searchedHandbookString: searchedHandbookString,
    handbookCategoryList: handbookCategoryList,
    selectedHandbookItem: selectedHandbookItem,
    handbookItemsList: handbookItemsList,
    chapterItemsList: chapterItemsList,
    handbookListLoading: handbookListLoading,
    chapterListLoading: chapterListLoading,
    shouldShowHiddenItems: shouldShowHiddenItems,
    selectedRoles: selectedRoles,
    selectedSupervisors: selectedSupervisors,
    selectedMembers: selectedMembers,
    selectedLocations: selectedLocations,
    rolesList: rolesList,
    supervisorsList: supervisorsList,
    locationsList: locationsList,
    membersList: membersList,
    selectedPowerupType: selectedPowerupType,
    returnedData: returnedData,
    selectedHandbookFilterOption: selectedHandbookFilterOption,
    setTrainingPathTypeHandler: setTrainingPathTypeHandler,
    setIsCreateHandler: setIsCreateHandler,
    setStepCountHandler: setStepCountHandler,
    setIsCounterMovedFromFirstStepHandler: isCounterMovedFromFirstStepHandler,
    setTrainingPathNameHandler: setTrainingPathNameHandler,
    setTrainingPathIconHandler: setTrainingPathIconHandler,
    setTrainingPathIconsListHandler: setTrainingPathIconsListHandler,
    setCountsHandler: setCountHandler,
    setSelectedCardHandler: setSelectedCardHandler,
    setSelectedDeckHandler: setSelectedDeckHandler,
    setShouldMoveToNextStep: setShouldMoveToNextStepHandler,
    setAddedItemsHandler: setAddedItemsHandler,
    setIsCardCheckboxSelectedHandler: setIsCardCheckboxSelectedHandler,
    setIsDeckCheckboxSelectedHandler: setIsDeckCheckboxSelectedHandler,
    setSearchedHandbookStringHandler: setSearchedHandbookString,
    setHandbookCategoryListHandler: setHandbookCategoryListHandler,
    setSelectedHandbookItemHandler: setSelectedHandbookItemHandler,
    setHandbookItemsListHandler: setHandbookItemsListHandler,
    setChapterItemsListHandler: setChapterItemsListHandler,
    setHandbookListLoadingHandler: setHandbookListLoadingHandler,
    setChapterListLoadingHandler: setChapterListLoadingHandler,
    setShouldShowHiddenItemsHandler: setShouldShowHiddenItemsHandler,
    setSelectedRolesHandler: setSelectedRolesHandler,
    setSelectedSupervisorsHandler: setSelectedSupervisorsHandler,
    setSelectedMembersHandler: setSelectedMembersHandler,
    setSelectedLocationsHandler: setSelectedLocationsHandler,
    setRolesListHandler: setRolesListHandler,
    setSupervisorsListHandler: setSelectedSupervisorsListHandler,
    setLocationsListHandler: setLocationsListHandler,
    setMembersListHandler: setMembersListHandler,
    setSelectedPowerupTypeHandler: setSelectedPowerupTypeHandler,
    setReturnedDataHandler: setReturnedDataHandler,
    setSelectedHandbookFilterOptionHandler:
      setSelectedHandbookFilterOptionHandler,
  };

  return (
    <TrainingPathContext.Provider value={trainingPathContext}>
      {props.children}
    </TrainingPathContext.Provider>
  );
};

export default TrainingPathDataProvider;

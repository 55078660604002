import Editor, {
  JSONContent,
  IMenuType,
  IEmojiStructure,
  ICON_TYPE,
} from 'delightree-editor';
import React, { FC, useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { Controller, useFormContext } from 'react-hook-form';
import { IFormInput } from '../chaptor.types';
import { Sop } from '../chapter.types';
import { Box } from '@chakra-ui/react';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';
import { IAmplitudeEventNamesEntity } from 'shared/amplitudeEvents/amplitude-events-types';
import EmptyState from '../../../assets/images/empty-state/form-empty-state.svg';
import { useLocation } from 'react-router-dom';
import ErrorContainer from '../../../sub-components/ErrorContainer';
import mechanicalEngineer from '../../../assets/images/chapter-editor/mechanicalEngineering.gif';
import { useUploadImage } from 'hooks';
interface IProps {
  editorData: Sop | undefined;
  getThumbnailFn?: (base64Img: string) => void;
  getThumbnail?: boolean;
  onItemClick?: (event: MouseEvent, node: any) => void;
  selectedEmoji: IEmojiStructure;
  setSelectedEmoji: React.Dispatch<React.SetStateAction<IEmojiStructure>>;
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ChapterEditorContainer: FC<IProps> = ({
  editorData,
  getThumbnailFn,
  getThumbnail,
  onItemClick,
  selectedEmoji,
  setSelectedEmoji,
}) => {
  const [fetchedResult, setFetchedResult] = useState('');
  const [uploadingChapterIcon, setUploadingChapterIcon] = useState(false);
  const [aiSearchString, setAISearch] = useState('');
  const [aiDataLoading, setAIDataLoading] = useState(false);
  const [fileParseLoading, setFileParseLoading] = useState(false);
  const [pdfExtractedData, setPdfExtractedData] = useState<
    { content: string; type: string }[]
  >([]);
  const [isIconMenuOpen, setIsIconMenuOpen] = useState(false);
  let query = useQuery();

  const { control, setValue } = useFormContext<IFormInput>();

  const uploadImage = useUploadImage();

  const entityId = useReactiveVar(userObj)?.entityId;

  useEffect(() => {
    let url = query.get('fileUrl');
    console.log('URL QUERY : : : : : ', url);
    if (url) {
      console.log('FETCHING FILE DATA : : : : : ');
      fetchFileData(url);
    }
  }, [query.get('fileUrl')]);

  useEffect(() => {
    const handleSlashCommandModalOpened = () => {
      deployEvent(AmplitudeEventNames.SLASH_COMMAND);
    };
    const handleMenuBarClicked = (event) => {
      let clickedItem = event?.detail?.message as IMenuType;
      let eventName: IAmplitudeEventNamesEntity = `Chapter Editor: Menu clicked ${clickedItem}`;
      deployEvent(eventName);
    };
    const handleSamplePromptClicked = () => {
      deployEvent(AmplitudeEventNames.WRITE_WITH_AI_SAMPLE_PROMPT);
    };
    const handleUseThisClicked = () => {
      deployEvent(AmplitudeEventNames.WRITE_WITH_AI_USE_THIS);
    };
    const handleCloseAIModalClicked = () => {
      deployEvent(AmplitudeEventNames.WRITE_WITH_AI_DISMISS_MODAL);
    };
    window.addEventListener(
      'slashCommandModalOpened',
      handleSlashCommandModalOpened
    );
    window.addEventListener('menuBarClicked', handleMenuBarClicked);
    window.addEventListener('samplePromptClicked', handleSamplePromptClicked);
    window.addEventListener('useThisClicked', handleUseThisClicked);
    window.addEventListener('closeAIModalClicked', handleCloseAIModalClicked);
    return () => {
      window.removeEventListener(
        'slashCommandModalOpened',
        handleSlashCommandModalOpened
      );
      window.removeEventListener('menuBarClicked', handleMenuBarClicked);
      window.removeEventListener(
        'samplePromptClicked',
        handleSamplePromptClicked
      );
      window.removeEventListener('useThisClicked', handleUseThisClicked);
      window.removeEventListener(
        'closeAIModalClicked',
        handleCloseAIModalClicked
      );
    };
  }, []);

  useEffect(() => {
    if (editorData) {
      setValue('title', editorData.title, {
        shouldDirty: false,
      });
      setValue('content', editorData?.content?.[0]?.tiptap, {
        shouldDirty: false,
      });
      setValue('smartPage', editorData?.smartPageEnabled, {
        shouldDirty: false,
      });
    }
  }, [editorData]);

  const fetchFileData = async (url: string) => {
    console.log('FETCH FILE DATA METHOD : : : : : ');
    console.log(
      'REACT APP FILE PARSE ENDPOINT : ',
      process?.env?.REACT_APP_FILE_PARSE_ENDPOINT
    );
    if (process?.env?.REACT_APP_FILE_PARSE_ENDPOINT) {
      console.log('FETCH FILE DATA : url : ', url);
      const data = { url };
      console.log('FETCH FILE DATA : data : ', data);
      try {
        setFileParseLoading(true);
        // const result = await axios({
        //   method: 'post',
        //   url: process?.env?.REACT_APP_FILE_PARSE_ENDPOINT,
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   data,
        // });
        const response = await fetch(
          process?.env?.REACT_APP_FILE_PARSE_ENDPOINT,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        console.log('RESPONSE : ', response);
        const result = await response.json();
        console.log('FILE PARSE RESULT : ', result);
        let _resultData = result;
        console.log('Original Items : ', _resultData);
        let sortedKeys = Object.keys(_resultData).sort((a, b) => {
          let aNumber = parseInt(a.split('_')[1]);
          let bNumber = parseInt(b.split('_')[1]);

          return aNumber - bNumber;
        });

        let sortedValues = sortedKeys.map((key) => _resultData[key]);
        console.log('SORTED VALUES : ', sortedValues);
        let _data = Object.values(_resultData);
        console.log('Object Value Items : ', _data);
        let tempArr: any[] = [];
        sortedValues?.forEach((item) => {
          item?.forEach((_item) => {
            let operatedStrings = cleanText(item);
            let obj = {
              content:
                _item?.type !== 'image' && _item?.type !== 'table'
                  ? replaceNewLineWithBreak(`<p>${operatedStrings}</p>`)
                  : replaceNewLineWithBlank(_item?.content),
              type: _item?.type,
            };
            tempArr.push(obj);
          });
        });
        tempArr?.forEach((temp) => {
          if (temp?.type === 'image') {
            temp.content = `<img src=${temp?.content} />`;
          }
        });
        console.log('Temp Arr : ', tempArr);
        tempArr.unshift({
          content: `<h2>${getFileNameHandler()}</h2>`,
          type: 'text',
        });
        setPdfExtractedData(tempArr);
      } catch (err) {
        console.error(err);
      } finally {
        setFileParseLoading(false);
      }
    }
  };

  const getFileNameHandler = () => {
    let url = query.get('fileUrl');
    let cleanName = 'Untitled Document';
    if (url) {
      let segments = url.split('/');
      let fileName = segments[segments.length - 1];
      fileName = decodeURIComponent(fileName);
      let nameWithoutPrefix = fileName.replace(/^\d+_\d+_/, '');
      cleanName = nameWithoutPrefix.split('.pdf')[0];
    }
    return cleanName;
  };

  function replaceNewLineWithBreak(str) {
    return str.replace(/\n/g, '<br />');
  }

  function replaceNewLineWithBlank(str) {
    return str.replace(/\n/g, '');
  }

  function cleanText(jsonArr) {
    // Find the text and table objects
    let textObj = jsonArr.find((obj) => obj.type === 'text')?.content;
    let tableObj =
      jsonArr.find((obj) => obj.type === 'table')?.content?.length > 0
        ? replaceNewLineWithBlank(
            jsonArr.find((obj) => obj.type === 'table')?.content
          )
        : jsonArr.find((obj) => obj.type === 'table')?.content;

    if (textObj?.length > 0 && tableObj?.length > 0) {
      // let witoutTableHeader_1 = tableObj?.split('<table>');
      // console.log('Without Table Header : ', witoutTableHeader_1);
      let regex = /<td[^>]*>(.*?)<\/td>/g;
      let html = tableObj;
      let match;
      let results = [];
      while ((match = regex.exec(html)) !== null) {
        results.push(match[1]);
      }
      let _result = results?.map((res) => res.replaceAll('\\n', '\n'));
      let operatedRes = '';
      let _arr = [];
      for (let i of _result) {
        if (i) {
          _arr.push(i);
        }
        operatedRes = _arr?.join(' \n');
      }
      let escapedSubString = operatedRes.replace(
        /[-\\^$*+?.()|[\]{}]/g,
        '\\$&'
      );
      let _regex = new RegExp(escapedSubString, 'g');
      let newString = textObj.replace(_regex, '');
      return newString;
    } else {
      return textObj;
    }
  }

  const aiSearchButtonClick = () => {
    aiSearchString && fetchData();
  };

  const fetchData = async () => {
    try {
      // const data = JSON.stringify({
      //   model: 'text-davinci-003',
      //   prompt: aiSearchString,
      //   temperature: 0,
      //   max_tokens: 1000,
      //   top_p: 1,
      //   frequency_penalty: 0,
      //   presence_penalty: 0,
      //   stream: true,
      // });
      // setAIDataLoading(true);
      // const config: AxiosRequestConfig = {
      //   method: 'post',
      //   url: 'https://api.openai.com/v1/completions',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
      //   },
      //   data: data,
      //   responseType: 'stream',
      //   // onDownloadProgress: (progressEvent) => {
      //   //   console.log('PROGRESS EVENT : ', progressEvent);
      //   //   const response = progressEvent.currentTarget.responseText;
      //   //   const lines = response
      //   //     ?.split('\n')
      //   //     .filter((line: any) => line.trim() !== '');
      //   //   const data = lines.map((line: any) => {
      //   //     const message = line.replace(/^data: /, '');
      //   //     if (message === '[DONE]') {
      //   //       return '';
      //   //     } else {
      //   //       try {
      //   //         const parsed = JSON.parse(message);
      //   //         return parsed.choices?.[0].text;
      //   //       } catch (error) {
      //   //         console.error(
      //   //           'Could not JSON parse stream message',
      //   //           message,
      //   //           error
      //   //         );
      //   //         setAIDataLoading(false);
      //   //         return '';
      //   //       }
      //   //     }
      //   //   });
      //   //   // let joinedData = `<br /><h1>${aiSearchString}</h1>` + `<p>${data.join('')}</p>`;
      //   //   let joinedData = data.join('');
      //   //   // setFetchedResult(data.join(''));
      //   //   console.log('JOINED DATA : ', joinedData);
      //   //   setFetchedResult(joinedData);
      //   //   setAIDataLoading(false);
      //   // },
      // };
      setAIDataLoading(true);
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          max_tokens: 1000,
          messages: [{ role: 'system', content: aiSearchString }],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`,
          },
        }
      );
      console.log('RESPONSE DATA : ', response);
      const completionText = response?.data?.choices?.[0]?.message?.content
        .replace(/^\n+|\n+$/g, '')
        .trim();
      console.log('COMPLETION TEXT : ', completionText);
      setFetchedResult(completionText);
      setAIDataLoading(false);
      // const data = await response.json();
      // let result = await axios(config);
      // let response: any = result.data;
      // const lines = response
      //   ?.split('\n')
      //   ?.filter((line: any) => line.trim() !== '');
      // const _data = lines.map((line: any) => {
      //   const message = line.replace(/^data: /, '');
      //   if (message === '[DONE]') {
      //     return '';
      //   } else {
      //     try {
      //       const parsed = JSON.parse(message);
      //       return parsed.choices?.[0].text;
      //     } catch (error) {
      //       console.error('Could not JSON parse stream message', message, error);
      //       setAIDataLoading(false);
      //       return '';
      //     }
      //   }
      // });
      // // let joinedData = `<br /><h1>${aiSearchString}</h1>` + `<p>${data.join('')}</p>`;
      // let joinedData = _data.join('');
      // console.log('parsedData : ', JSON.parse(joinedData))
      // console.log('joinedData : ', joinedData);
      // // setFetchedResult(data.join(''));
      // console.log('JOINED DATA : ', joinedData);
      // setFetchedResult(joinedData);
      // setAIDataLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setAIDataLoading(false);
    }
  };

  const chapterHeadingHandler = (jsonData: JSONContent) => {
    if (jsonData?.content) {
      if (Array.isArray(jsonData?.['content'])) {
        let breakLoop = false;
        jsonData?.['content']?.forEach((_content: any) => {
          if (_content?.type === 'heading' && !breakLoop) {
            const title = _content?.content?.[0]?.text || 'Untitled';
            title && setValue('title', title);
            breakLoop = true;
          }
        });
      }
    }
  };

  const extractFormEids = (node: any) => {
    let eids: any = [];

    if (node.type === 'form') {
      eids.push(node.attrs.eid);
    }

    if (node.content) {
      node.content.forEach((child: any) => {
        eids = eids.concat(extractFormEids(child));
      });
    }

    return eids;
  };

  const chapterIconUploadHandler = async (file: File | null) => {
    console.log('Chapter icon upload handler : ', file);
    try {
      setUploadingChapterIcon(true);
      const imageUrl = await uploadImage(file || null);
      console.log('IMAGE URL : : : : : ', imageUrl);
      setSelectedEmoji({
        emoticons: [],
        id: ICON_TYPE,
        keywords: [],
        name: '',
        native: imageUrl,
        shortcodes: '',
        unified: '',
      });
      // setValue('thumbnail', imageUrl);
    } catch (e) {
      console.error(e);
    } finally {
      setUploadingChapterIcon(false);
      setIsIconMenuOpen(false);
    }
  };

  return (
    <Controller
      control={control}
      name='content'
      render={({ field }) => {
        return (
          <Editor
            chapterEditor={true}
            readonly={false}
            onChange={(jsonData) => {
              console.log('ON CHANGE : ', jsonData);
              chapterHeadingHandler(jsonData);
              field.onChange(jsonData);
            }}
            value={field.value}
            aiDataLoading={aiDataLoading}
            aiSearchString={aiSearchString}
            aiSearchButtonClick={aiSearchButtonClick}
            setAISearch={setAISearch}
            aiFetchedData={fetchedResult}
            setAIFetchedData={setFetchedResult}
            entityId={entityId}
            editorData={editorData}
            getThumbnailFn={getThumbnailFn}
            getThumbnail={getThumbnail}
            EmptyState={EmptyState}
            onItemClick={onItemClick}
            shouldShowInitialParagraphs={editorData ? false : true}
            selectedEmoji={selectedEmoji}
            setSelectedEmoji={setSelectedEmoji}
            pdfExtractedData={pdfExtractedData}
            fileParseLoading={fileParseLoading}
            parseLoadingIcon={mechanicalEngineer}
            chapterIconUpload={chapterIconUploadHandler}
            chapterIconUploading={uploadingChapterIcon}
            isIconMenuOpen={isIconMenuOpen}
            setIsIconMenuOpen={setIsIconMenuOpen}
          />
        );
      }}
    />
  );
};

export default ChapterEditorContainer;

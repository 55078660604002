import React, { FC } from "react";

interface IProps {
  content?: any[];
  shouldSetFixedHeight?: boolean;
}

export const FormType: FC<IProps> = ({ content = [], shouldSetFixedHeight }) => {
  const source = content?.[0]?.url;
  return (
    <iframe
      src={source}
      frameBorder="0"
      scrolling="yes"
      style={{ position: "relative", height: "100%", width: "100%", minHeight: shouldSetFixedHeight ? '80vh' : 'none' }}
    >
      Loading…
    </iframe>
  );
};

import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { MessageIconButton } from '../../../../../atoms';
import { SortByNumber } from '../../../../../utils/sorting';

import { Column, SortingTable } from '../../../../ChakraTable/SortingTable';

import { LocationCell } from '../../../../../pages/Teams/members/component';

import { PerformanceEntity } from './performance.graphql';
import { PerformanceMemberName, TaskProgress } from '../../common';
import AssigneeEmpty from './AssigneeEmpty';
import { useHasChatAccess } from 'hooks/useChatRestriction';

interface IProps {
  dataList: PerformanceEntity[];
  onPageChange: (newPage: number) => void;
  loading: boolean;
  currentPage: number;
  itemCount?: number;
  onClickedHandler?: (clickedItem: string, data: PerformanceEntity) => void;
}

const PerformanceList: FC<IProps> = ({
  dataList,
  onPageChange,
  loading,
  itemCount,
  currentPage,
  onClickedHandler,
}) => {
  const { t, i18n } = useTranslation(['common', 'team', 'task']);
  const hastChatAccess = useHasChatAccess();

  const columns = useMemo((): Column<PerformanceEntity>[] => {
    return [
      {
        Header: t('common:name'),
        accessor: 'name',
        width: 'calc(45% - 60px)',
        Cell: ({ cell: { value, row } }) => {
          return (
            <PerformanceMemberName
              name={value}
              profilePic={row.original.profilePic}
              role={row.original.role}
              authRole={row.original.authRole}
              onClick={() => {
                onClickedHandler?.('open', row.original);
              }}
            />
          );
        },
      },
      {
        Header: t('common:location'),
        accessor: 'locations',
        width: '30%',
        Cell: ({ cell: { value } }) => {
          return (
            <LocationCell
              value={value}
              marginBottom='0px'
              hideImage
              color='#000000'
              background='rgba(181, 228, 202, 0.6)'
            />
          );
        },
      },
      {
        Header: t('task:completionRate'),
        accessor: 'completionRate',
        width: '25%',
        Cell: ({ cell: { value } }) => {
          return <TaskProgress value={value} />;
        },
        sortType: SortByNumber,
      },
      {
        Header: '',
        accessor: 'eid',
        width: '60px',
        Cell: ({ cell: { value, row } }) => {
          return (
            <Flex justify='flex-end'>
              <MessageIconButton
                hasChatAccess={hastChatAccess({
                  eid: row?.original?.eid,
                  role: row?.original?.role,
                  authRole: row?.original?.authRole,
                })}
                onClick={() => onClickedHandler?.('message', row.original)}
              />
            </Flex>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [t, i18n.language, onClickedHandler]);

  return (
    <SortingTable
      colorScheme='blue'
      emptyData={{
        content: <AssigneeEmpty />,
      }}
      page={currentPage || 1}
      onPageChange={onPageChange}
      columns={columns}
      data={dataList}
      isLoading={loading}
      totalRegisters={itemCount}
      isResponsive
      isHidePagination={!(currentPage > 1 || (itemCount || 0) > 10)}
    />
  );
};

export default PerformanceList;

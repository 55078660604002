import { gql } from '@apollo/client';

export const GET_TRAINING_HANDBOOK_QUERY = gql`
  query HandbookPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyhandbookInput
    $sort: SortFindManyhandbookInput
  ) {
    handbookPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        backgroundColor
        cardType
        category
        content
        createdAt
        createdBy
        eid
        entityId
        form {
          category
          createdBy {
            eid
          }
          description
          eid
          questions
          thumbnail
          title
        }
        formId
        layout
        title
        thumbnail
        subTitle
        raw_content
        status
        views
        updatedAt
        type
        cards {
          backgroundColor
          cardType
          category
          content
          createdAt
          createdBy
          eid
          entityId
          form {
            category
            createdBy {
              eid
            }
            description
            eid
            questions
            thumbnail
            title
          }
          formId
          layout
          title
          thumbnail
          translations
          subTitle
          raw_content
          status
          views
          updatedAt
        }
        trainings {
          title
        }
        translations
      }
    }
  }
`;

export const GET_ALL_MEMBER_LOCATION_DATA = gql`
  query GetEntityUserLocationData($entityId: String!) {
    EntityUser(entityId: $entityId) {
      eid
      name
      type
      role
      authRole
      profilePic
      locations {
        name
      }
    }
  }
`;

import React, { FC } from 'react';
import { Box, Center, Flex, Spacer } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Title } from '../../../../../ui-components/common';

interface IProps {
  title: string;
}

const PerformanceHeader: FC<IProps> = ({ title }) => {
  const { t } = useTranslation(['common', 'task']);
  const history = useHistory();

  return (
    <Flex gap={3}>
      <Center height='48px' onClick={history.goBack} cursor='pointer'>
        <ArrowBackIcon color='#272B30' boxSize='22px' />
      </Center>
      <Box>
        <Title fontSize='24px'>{title}</Title>
        <Box
          fontSize='15px'
          fontWeight='400'
          color='#6F767E'
          cursor='pointer'
          onClick={history.goBack}
        >
          {t('task:backToDashboard')}
        </Box>
      </Box>

      <Spacer />
    </Flex>
  );
};

export default PerformanceHeader;

import React, { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { IFormInput } from '../task.types';
import { MemberSelection, SelectOption } from '../common';
import TitleHeader from '../../../CardEditor/TitleHeader';
import { CustomSelectComponent } from 'sub-components/CustomDropdowns';
import { HeaderColors } from 'shared/headerColors/header-colors';

interface IProps {
  members: SelectOption[];
  loading: boolean;
}

const MemberAssignee: FC<IProps> = ({ members, loading }) => {
  const { t } = useTranslation(['task']);

  const { control } = useFormContext<IFormInput>();
  const assignToType = useWatch<IFormInput, 'assignToType'>({
    name: 'assignToType',
  });

  if (!assignToType?.includes('member')) {
    return null;
  }

  return (
    <Box pt={4} pb={5} borderTop='1px solid #E9E9E9'>
      <TitleHeader title={t('task:selectMembers')} />

      <Controller
        control={control}
        name='assignedToUsers'
        rules={{
          required: t('task:validation.memberRequired'),
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={2} isInvalid={!!fieldState?.error}>
              {/* <MemberSelection
                placeholder={t('task:placeholder.selectMembers')}
                members={members}
                value={field.value}
                onChange={field.onChange}
                isLoading={loading}
              /> */}
              <CustomSelectComponent
                options={members}
                value={field.value}
                placeholder='Select member(s)'
                showHeader
                headerTitle='Select member(s)'
                headerBarColor={HeaderColors.Purple}
                showHeaderCloseIcon
                showSearchField
                showDivider
                bottomOutsideBtn={{
                  show: true,
                  title: 'Add selected members',
                }}
                showFilter
                showSelectAll
                onChange={field.onChange}
              />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default MemberAssignee;

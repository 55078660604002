import React, { FC, useEffect, useState } from 'react';
import { Box, chakra, Flex, Spacer, StylesProvider } from '@chakra-ui/react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../atoms/PrimaryButton';
import { FormContributor } from './ReviewPublish';
import { IFormInput } from './form.types';
import { useConfirm } from '../../../ui-components/Confirm';
import PreviewHeader from '../component/PreviewHeader';
import DeleteFormModal from './DeleteFormModal';
// import ContributorSelect from './ReviewPublish/ContributorSelect';
import ContributorSelect from './ReviewPublish/ContributorSelect';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  ContributorResponse,
  getContributors,
} from './ReviewPublish/contributor.graphql';
import { userObj } from 'sop-commons/src/client';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { CustomSelectComponent } from 'sub-components/CustomDropdowns';
import { HeaderColors } from 'shared/headerColors/header-colors';

interface IProps {
  submitting: boolean;
  onPublish: (values: IFormInput) => void;
  confirmReset?: () => void;
  isEdit: boolean | undefined;
}

interface SelectOption {
  id: string;
  label: string;
  value: string;
  url?: string;
  authRole?: string;
}

const Allowed: string[] = ['active', 'inactive'];

const ActionButtons: FC<IProps> = ({
  submitting,
  isEdit,
  onPublish,
  confirmReset,
}) => {
  const { t } = useTranslation(['common', 'form']);
  const { isDirty } = useFormState<IFormInput>();
  const { control, getValues } = useFormContext();
  const confirm = useConfirm();
  const [isOpen, setIsOpen] = React.useState(false);
  const authUser = useReactiveVar(userObj);
  const styles = {};

  const watchedContributors = useWatch({
    control,
    name: 'contributors',
  });

  useEffect(() => {
    getUsersData({
      variables: {
        entityId: authUser?.entityId,
      },
    });
  }, [authUser?.entityId]);

  const onCancelClick = () => {
    if (!isDirty) {
      return;
    }
    confirm({
      title: <PreviewHeader title={t('form:deleteForm')} color='#CABDFF' />,
      content: (
        <Flex>
          <Box w={8} />
          <Box>{t('form:deleteFormContent')}</Box>
        </Flex>
      ),
      okText: t('form:deleteAnyway'),
      okButtonProps: {
        backgroundColor: '#FF6A55',
      },
      cancelText: t('form:exportFormResponse'),
      isCentered: true,
      contentProps: {
        paddingTop: '12px',
        paddingBottom: '12px',
      },
      onOK: confirmReset,
    });
  };

  const [members, setMembers] = useState<SelectOption[]>([]);

  const [getUsersData, { loading }] = useLazyQuery<ContributorResponse>(
    getContributors,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const _userData: ContributorResponse['AllowedContributorsList'] =
          data?.AllowedContributorsList?.filter((value) => {
            return (
              value.type !== 'branch' &&
              Allowed.includes(value.status) &&
              value.eid !== authUser?.eid
            );
          });

        const _supervisors: SelectOption[] = _userData?.map((user) => {
          return {
            id: user.eid,
            label: user.name,
            value: user.eid,
            url: user?.profilePic,
            role: user?.role,
            locations: user?.locations,
            authRole: user?.authRole,
          };
        });

        setMembers(_supervisors);
      },
    }
  );

  return (
    <>
      {watchedContributors?.length > 0 && (
        <StylesProvider value={{}}>
          <Flex my={4} direction='column' gap={4}>
            <GenericColorHeader
              title={t('form:contributors')}
              color='#CABDFF'
            />
            <Controller
              control={control}
              name='contributors'
              render={({ field }) => {
                return (
                  // <ContributorSelect
                  //   members={members}
                  //   value={field.value}
                  //   onChange={field.onChange}
                  // />
                  <CustomSelectComponent
                    options={members}
                    value={field.value}
                    placeholder='Select contributors(s)'
                    showHeader
                    headerTitle='Select contributors(s)'
                    headerBarColor={HeaderColors.Purple}
                    showHeaderCloseIcon
                    showSearchField
                    showDivider
                    bottomOutsideBtn={{
                      show: true,
                      title: 'Add selected members',
                    }}
                    showFilter
                    showSelectAll
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </Flex>
        </StylesProvider>
      )}
      <Flex
        mt='40px'
        sx={{
          button: {
            paddingLeft: '20px',
            paddingRight: '20px',
          },
          'button + button': {
            marginLeft: '10px',
          },
        }}
      >
        {isEdit && (
          <PrimaryButton
            title={t('delete')}
            // width='146px'
            width='auto'
            variant='solid'
            colorScheme='red'
            disabled={submitting}
            onClick={() => setIsOpen(true)}
            // onClick={() => history.goBack()}
          />
        )}
        <Spacer />
        <FormContributor submitting={submitting} onPublish={onPublish} />
        <PrimaryButton
          type='submit'
          title={t('publish')}
          // width='191px'
          isLoading={submitting}
          disabled={submitting}
          width='auto'
          variant='solid'
          colorScheme='blue'
        />
        {isOpen && (
          <DeleteFormModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={t('form:deleteForm')}
            desc={t('form:deleteFormContent')}
          />
        )}
      </Flex>
    </>
  );
};

export default ActionButtons;

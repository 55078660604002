import React, { FC } from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import SkeletonTask from './SkeletonTask';
import SkeletonAddButton from './SkeletonAddButton';

interface IProps {
  length?: number;
}

const SkeletonSection: FC<IProps> = ({ length = 0 }) => {
  return (
    <Flex flexDir='column' gap={3} width='300px' minW='300px'>
      <Flex flexDir='column' gap='10px' py={2} mb={3}>
        <Skeleton height='14px' borderRadius='full' width='200px' />
        <Skeleton height='10px' borderRadius='full' width='80px' />
      </Flex>

      <SkeletonAddButton />

      {Array.from({ length: length }).map((_, index) => {
        return <SkeletonTask key={index} />;
      })}
    </Flex>
  );
};

export default SkeletonSection;

import { useEffect, useState } from 'react';
import { Col, Row, Typography, Layout, Button, message, Select } from 'antd';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { Header } from '../../ui-components/Header';
import { OnboardingMenu } from '../../ui-components/OnboardingMenu/OnboardingMenu';
import { TextInput } from '../../ui-components/TextInput';
import gql from 'graphql-tag';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { client, userObj } from 'sop-commons/src/client/clientFactory';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/robot-says-hello.json';
import { capitalizeFirstLetter } from 'sop-commons/src/utils/stringUtils';
import './CompanyInfo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { UPDATE_ENTITY_ONBOARDING_FLAG } from '../SopList';

const { Title, Text } = Typography;
const { Option } = Select;

import ResponsiveModal from '../../ui-components/ResponsiveModal';
import { ADMIN } from '../../utils/userRoles';
import { GET_USER } from '../Login/login.graphql';

interface CompanyInfoProps {
  onClickSubmit?: any;
}
const UPDATE_USER_MUTATION = gql`
  mutation UpdateUserMutation($input: UserUpdateInput) {
    updateUser(input: $input) {
      email
      name
      phone
      profilePic
      authRole
      role
      account {
        verification {
          verified
        }
      }
      updatedAt
      createdAt
      eid
      entity {
        name
        address
        profilePic
        isDemo
        roles {
          name
          status
        }
        category {
          name
          status
        }
        createdAt
        updatedAt
        eid
        onboarded
      }
    }
  }
`;

const GET_SOP_REQUESTS = gql`
  query Query($page: Int, $perPage: Int) {
    SopRequestsPagination(page: $page, perPage: $perPage) {
      items {
        chapter
        files {
          filename
          mimetype
          uploadedBy
          uploadedAt
          url
          _id
        }
        links
        status
        category
        entity {
          name
          address
          profilePic
          roles {
            status
            name
          }
          bundles {
            name
            status
          }
          category {
            name
            status
          }
          updatedAt
          createdAt
          eid
        }
        updatedAt
        createdAt
        eid
      }
      count
    }
  }
`;
export const CompanyInfo = ({ onClickSubmit }: CompanyInfoProps) => {
  const history = useHistory();
  const [step, setStep] = useState('0');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const userObject = useReactiveVar<any>(userObj);

  const {
    loading: getsopLoading,
    error: getSopError,
    data: getSopData,
  } = useQuery(GET_SOP_REQUESTS);

  const {
    loading: getUserLoading,
    error: getUserError,
    data: getUserData,
  } = useQuery(GET_USER);

  const isAdmin = getUserData?.user?.authRole === ADMIN;

  const [updateEntityOnboardingStatus] = useMutation(
    UPDATE_ENTITY_ONBOARDING_FLAG
  );

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => {
      const newUserObj = { ...userObject };
      if (!isAdmin) {
        newUserObj.onboarded = true;
        newUserObj.onboardedAt = new Date();
      }

      userObj({ ...newUserObj });
      updateEntityOnboardingStatus({
        variables: {
          input: {
            eid: getUserData?.user?.entity?.eid,
            onboarded: true,
          },
        },
      });
      client.writeQuery({
        query: GET_USER,
        data: {
          user: { ...newUserObj, name: name, role: role },
        },
      });
    },
  });

  useEffect(() => {
    if (!name && getUserData?.user?.name) {
      setName(getUserData?.user?.name);
    }
    if (!role && getUserData?.user?.role) {
      setRole(capitalizeFirstLetter(getUserData?.user?.role));
    }
  }, [getUserData?.user?.name]);

  const handleClick = (menuClickedTitle: string, e: any) => {
    setStep(e?.key);
  };
  const onClick = (role: any) => {
    setRole(role);
    setMenuOpen(false);
  };

  const onNextPress = () => {
    if (step === '1') {
      if (!isAdmin) {
        updateUser({
          variables: {
            input: {
              name: name,
              //profilePic: null,
              role: role,
              onboarded: true,
            },
          },
        });
      } else {
        updateUser({
          variables: {
            input: {
              name: name,
              //profilePic: null,
              role: role,
            },
          },
        });
        if (onClickSubmit) {
          onClickSubmit();
        }
        history.push('/onboarding/add-sop');
      }
    } else {
      if (!name) {
        message.error('Please enter valid name to proceed');
      } else {
        setStep('1');
      }
    }
  };
  const onChangeInput = (e: any) => {
    if (step === '0') {
      setName(e.target.value);
    } else {
      setRole(e.target.value);
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (step === '1') {
        if (!isAdmin) {
          updateUser({
            variables: {
              input: {
                name: name,
                //profilePic: null,
                role: role,
                onboarded: true,
              },
            },
          });
        } else {
          updateUser({
            variables: {
              input: {
                name: name,
                //profilePic: null,
                role: role,
              },
            },
          });
        }
        if (onClickSubmit) {
          onClickSubmit();
        }
        history.push('/onboarding/add-sop');
      } else {
        if (!name) {
          message.error('Please enter valid name to proceed');
        } else {
          setStep('1');
        }
      }
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Layout
      style={{
        backgroundColor: '#fbf7f0',
        overflowY: 'auto',
        height: '100%',
        maxHeight: '100%',
      }}
    >
      {isMobile && <ResponsiveModal />}
      {!isMobile && (
        <>
          {' '}
          <Header disableSearch />
          <div
            className='logo'
            style={{ marginBlock: 30, marginRight: 100, marginLeft: 100 }}
          />
          <Row
            justify='center'
            style={{ marginBottom: 20 }}
            className='app-main-container'
          >
            <Col
              span={5}
              style={{
                borderRadius: 10,
                maxHeight: '100%',
                minWidth: 290,
                maxWidth: 350.98,
              }}
            >
              <OnboardingMenu
                // subMenuIcon={
                //   <div
                //     style={{
                //       display: 'flex',
                //       alignItems: 'center',
                //       justifyContent: 'center',
                //       border: 'solid',
                //       height: 15,
                //       width: 15,
                //       borderWidth: 1,
                //       borderColor: '#999999',
                //       borderRadius: 2,
                //     }}
                //   >
                //     <Text
                //       style={{ color: '#999999', fontWeight: 600, fontSize: 8 }}
                //     >
                //       1
                //     </Text>
                //   </div>
                // }
                sopRequestCategoryName={getSopData?.SopRequestsPagination?.items?.map(
                  (sopItem: any, index: number) => sopItem?.category
                )}
                subMenu2Disabled={
                  !(getUserData?.user?.name && getUserData?.user?.role)
                }
                handleSelectCallback={handleClick}
                menuStyle={{
                  fontSize: 16,
                  borderRadius: 10,
                  backgroundColor: '#FFFFFF',
                  minHeight: '96%',
                }}
                menuDefaultSelectedKeys={['0']}
                currentSelectedKeys={[step]}
                menuDefaultOpenKeys={['sub1']}
                menuMode='inline'
                name={name}
                isAdmin={isAdmin}
                isRemoveContinue
              />
            </Col>
            <Col
              className='company-info-container'
              span={18}
              style={{ maxWidth: 1360, marginLeft: 20 }}
            >
              <div
                className='company-info-container'
                style={{
                  marginBottom: 20,
                  backgroundColor: 'white',
                  borderRadius: 10,
                  padding: 50,
                  border: 'solid',
                  borderWidth: 1,
                  borderColor: '#dddddd',
                }}
              >
                <div
                  className='company-info-container'
                  style={{ marginLeft: 10 }}
                >
                  <Row>
                    <Col span={17}>
                      <Title
                        style={{
                          fontFamily: 'Open Sans',
                          fontWeight: 'bolder',
                          fontSize: 30,
                        }}
                        level={2}
                      >
                        Welcome to Delightree!
                      </Title>
                      <Title
                        style={{
                          fontFamily: 'Open Sans',
                          fontWeight: 'lighter',
                          marginBottom: 77,
                          fontSize: 18,
                          color: '#777777',
                        }}
                        level={5}
                      >
                        We’re glad to get
                        <Text style={{ color: '#4D8AF0', fontWeight: 700 }}>
                          {' '}
                          {getUserData?.user?.entity?.name || ' '}{' '}
                        </Text>
                        SOP Handbook set up.
                      </Title>
                    </Col>
                    <Lottie options={defaultOptions} height={169} width={209} />
                  </Row>

                  <Text
                    style={{
                      fontFamily: 'Open Sans',
                      color: '#4D8AF0',
                      fontSize: '16',
                    }}
                  >
                    {step === '0' ? 'Introduce yourself' : 'Job Role'}
                  </Text>
                </div>

                {step === '0' ? (
                  <TextInput
                    className='eventclass-company-info-input'
                    handleKeyDown={handleKeyDown}
                    style={{
                      paddingBlock: 16.5,
                      fontFamily: 'Open Sans',
                      fontSize: 25,
                      borderBottom: 'solid',
                      borderBottomColor: '#4D8AF0',
                      borderBottomWidth: 2.5,
                    }}
                    onChange={onChangeInput}
                    value={step === '0' ? name : role}
                    bordered={false}
                    size='large'
                    placeholder={'Please tell your name'}
                  />
                ) : (
                  <Select
                    suffixIcon={
                      <FontAwesomeIcon
                        style={{ height: 18, width: 21 }}
                        icon={faChevronDown}
                      />
                    }
                    className='eventclass-company-info-role-dropwdown'
                    onMouseEnter={() => setMenuOpen(true)}
                    onMouseLeave={() => setMenuOpen(false)}
                    open={menuOpen}
                    autoFocus
                    onKeyDown={handleKeyDown}
                    onChange={onClick}
                    //defaultValue={role}
                    value={role}
                    placeholder={`and what you do at ${
                      getUserData?.user?.entity?.name || ' '
                    }?`}
                    dropdownStyle={{ bottom: 700 }}
                    style={{
                      paddingBlock: 20,
                      fontSize: 25,
                      width: '100%',
                      borderBottom: 'solid',
                      borderBottomColor: '#4D8AF0',
                      borderBottomWidth: 2.5,
                    }}
                    bordered={false}
                  >
                    {getUserData?.user?.entity?.roles?.length > 0 ? (
                      getUserData?.user?.entity?.roles?.map(
                        (roleObject: any, index: number) => (
                          <Option
                            style={{
                              margin: 5,
                              fontSize: 25,
                              paddingLeft: 45,
                              paddingBlock: 18,
                              fontFamily: 'Open Sans',
                              fontWeight: 300,
                            }}
                            key={roleObject?.name}
                            value={roleObject?.name}
                          >
                            {capitalizeFirstLetter(roleObject?.name)}
                          </Option>
                        )
                      )
                    ) : (
                      <Option key={'0'} value={'Loading roles ...'}>
                        Loading roles.....
                      </Option>
                    )}
                  </Select>
                )}
                <Row style={{ alignItems: 'center', marginBlock: 40 }}>
                  <Button
                    className='eventclass-company-info-next-button'
                    disabled={step === '0' ? !name : !role}
                    onClick={onNextPress}
                    style={{
                      backgroundColor:
                        step === '0'
                          ? !name
                            ? '#999999'
                            : '#4D8AF0'
                          : !role
                          ? '#999999'
                          : '#4D8AF0',
                      color:
                        step === '0'
                          ? !name
                            ? '#DDDDDD'
                            : '#FFFFFF'
                          : !role
                          ? '#DDDDDD'
                          : '#FFFFFF',
                      fontWeight: 600,
                      borderWidth: 0,
                      width: 118,
                      height: 48,
                      borderRadius: 5,
                      marginRight: 22,
                      fontSize: 20,
                    }}
                    type='primary'
                  >
                    Next
                  </Button>
                  <Text
                    style={{ fontSize: 16, fontWeight: 400, color: '#999999' }}
                  >
                    Or press ENTER
                  </Text>
                </Row>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Layout>
  );
};

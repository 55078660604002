import React, { FC } from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';
import { getRandomNumber } from 'utils';

interface IProps {
  isLoaded?: boolean;
}

const FormResponseHeaderSkeleton: FC<IProps> = ({ isLoaded, children }) => {
  if (isLoaded) {
    return <>{children}</>;
  }

  return (
    <Flex justify='center' gap='1rem'>
      <Skeleton boxSize='62px' borderRadius='4px' />
      <Flex flexDir='column' flex={1} gap={2}>
        <Skeleton
          height='13px'
          borderRadius='full'
          width={`${getRandomNumber(40, 60)}%`}
        />
        <Skeleton
          height='9px'
          borderRadius='full'
          width={`${getRandomNumber(30, 40)}%`}
        />
        <Skeleton
          height='9px'
          borderRadius='full'
          width={`${getRandomNumber(20, 40)}%`}
        />
      </Flex>
      <Flex flex={1} />
    </Flex>
  );
};

export default FormResponseHeaderSkeleton;

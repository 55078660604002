import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Authorization, AuthRole } from '../../../authorization';

interface ItemProps {
  label: string;
  onClick: () => void;
  isSelected: boolean;
}
const TabItem: FC<ItemProps> = ({ label, onClick, isSelected }) => {
  return (
    <Box
      fontSize='15px'
      fontWeight='600'
      color={isSelected ? '#33383F' : '#6F7E7C'}
      pb='12px'
      px='8px'
      borderBottom='3px solid #2A85FF'
      borderBottomColor={isSelected ? '#2A85FF' : 'transparent'}
      cursor='pointer'
      onClick={onClick}
    >
      {label}
    </Box>
  );
};

export type TrainingTabValue = 'myTrainings' | 'tracking';

interface IProps {
  value: TrainingTabValue;
  onClick: (newValue: TrainingTabValue) => void;
}

const TrainingTabs: FC<IProps> = ({ value, onClick }) => {
  const { t } = useTranslation('training');
  return (
    <Flex gap='24px' borderBottom='1px solid #F5F5F5' mb='16px'>
      <Authorization
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.LOCATION_OWNER,
        ]}
        permittedFor={['user']}
      >
        <TabItem
          label={t('tracking')}
          isSelected={value === 'tracking'}
          onClick={() => onClick('tracking')}
        />
      </Authorization>
      <TabItem
        label={t('myTrainings')}
        isSelected={value === 'myTrainings'}
        onClick={() => onClick('myTrainings')}
      />
    </Flex>
  );
};

export default TrainingTabs;

import { ICompareVisibilityType } from './visibilityHelper';
import { membersCompare } from './membersCompare';

export const allAND = (parent: any, child: any): ICompareVisibilityType => {
  console.log({ ALL_AND: { parent, child } });
  let parentANDCombinations: any[] = [];
  let childANDCombinations: any[] = [];
  parent?.locations?.forEach((location: any) => {
    parent?.jobs?.forEach((role: any) => {
      parentANDCombinations?.push(`${location} & ${role}`);
    });
  });

  child?.locations?.forEach((location: any) => {
    child?.jobs?.forEach((role: any) => {
      childANDCombinations.push(`${location} & ${role}`);
    });
  });
  let commonCombinations = parentANDCombinations?.filter((element) =>
    childANDCombinations?.includes(element)
  );
  console.log({
    allAND: {
      parentANDCombinations,
      childANDCombinations,
      commonCombinations,
    },
  });
  let flag: ICompareVisibilityType = 'parentChildNoIntersection';
  if (commonCombinations?.length > 0) {
    const childContainsAllOfParent = parentANDCombinations?.every((element) =>
      childANDCombinations?.includes(element)
    );
    const parentContainsAllOfChild = childANDCombinations?.every((element) =>
      parentANDCombinations?.includes(element)
    );
    // Both child and parent have exactly same elements
    if (childContainsAllOfParent && parentContainsAllOfChild) {
      flag = 'parentChildSameVisibleSameLength';
    }

    // Child has all parent elements but has its own elements as well making child > parent
    if (
      childContainsAllOfParent &&
      childANDCombinations?.length > parentANDCombinations?.length
    ) {
      flag = 'parentLessVisibleThanChild';
    }

    // Child contains fewer elements than parent making parent > child
    if (
      !childContainsAllOfParent &&
      childANDCombinations?.length < parentANDCombinations?.length
    ) {
      flag = 'parentMoreVisibleThanChild';
    }

    // Both parent and child have same elements but they have different elements
    if (
      !childContainsAllOfParent &&
      childANDCombinations?.length === parentANDCombinations?.length
    ) {
      console.log(
        'Child and parent have the same length, but they contain different elements.'
      );
      flag = 'parentChildSameLengthDifferentVisibility';
    } else if (
      !childContainsAllOfParent &&
      childANDCombinations?.length > parentANDCombinations?.length
    ) {
      console.log(
        'Child has more elements and does not contain all elements of the parent.'
      );
      flag = 'parentLessVisibleThanChild';
    }
  } else {
    console.log('NO COMMON INTERSECTION');
    flag = 'parentChildNoIntersection';
  }
  console.log('initial flag : ', flag);
  let finalFlag = membersCompare(
    flag,
    parent?.members || [],
    child?.members || []
  );
  console.log({ ALL_AND_FLAG: finalFlag });
  return finalFlag;
};

import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { CardProps, SortableCard } from '../../../../../CreateDeck/card';
import Milestone, {
  MilestoneProps,
} from '../../../../../../sub-components/Milestone';
import { useTranslation } from 'react-i18next';

export const SortableMilestone = SortableElement<MilestoneProps>(
  (props: MilestoneProps) => <Milestone {...props} />
);

interface SortableTrainingProps {
  items?: Array<any>;
  toggleSwitch?: (id: string, toggleValue: boolean) => void;
  milestoneProps: Required<
    Pick<MilestoneProps, 'handleMilestoneEdit' | 'handleRemove'>
  >;
  cardProps: Required<
    Pick<
      CardProps,
      | 'handleRemove'
      | 'setCardSelected'
      | 'hoverClick'
      | 'handleCardEdit'
      | 'handleCardClick'
    >
  > &
    Pick<CardProps, 'hoverEffectFn'>;
}

export const SortableTrainingList = SortableContainer<SortableTrainingProps>(
  ({
    items,
    milestoneProps,
    cardProps,
    toggleSwitch,
  }: SortableTrainingProps) => {
    const { t } = useTranslation(['common', 'training']);
    return (
      <div className='sortable-container'>
        {items?.map?.((item, index) => {
          if (item?.type === 'milestone') {
            return (
              <SortableMilestone
                key={`key-${
                  item.eid || item.tempEid || item.tempId || item.title + index
                }`}
                index={index}
                title={item?.title}
                card={item}
                backgroundColor={item?.backgroundColor}
                {...milestoneProps}
              />
            );
          }
          return (
            <SortableCard
              key={`key-${item.eid}`}
              index={index}
              card={item}
              fromDeck
              fromTraining
              type={t((item?.type || 'Card').toLowerCase())}
              hoverFunctionalityText={t('training:milestone')}
              shouldShowHoverFunctionality
              toggleSwitch={toggleSwitch}
              {...cardProps}
            />
          );
        })}
      </div>
    );
  }
);

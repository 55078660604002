import { gql } from '@apollo/client';
import { AuthRole } from '../../../authorization';
import { InvitedUser } from '../InvitedMember/update-invite.graphql';

export interface UserUpdateIdInput {
  authRole: AuthRole;
  eid: string;
  email: string;
  locations: string[];
  name: string;
  phone: string;
  profilePic: string;
  role: string;
  username: string;
  isDeleted?: boolean;
}

export interface UpdateUserResponse {
  updateUserById: InvitedUser;
}

export interface MagicLinkResponse {
  getMagicLink: string;
}
export interface MagicLinkVariable {
  eid: string;
}

export const MAGIC_LINK_QUERY = gql`
  query Query($eid: String!) {
    getMagicLink(eid: $eid)
  }
`;

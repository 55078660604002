import { Flex } from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { IDateFilterOptions } from '../types';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Wrapper } from '../styles/date-filter-custom-range-styles';

interface ICustomRange {
  from: moment.Moment;
  to: moment.Moment;
}

interface IDateFilterCustomRange {
  selectedDateFilter: IDateFilterOptions | undefined;
  customRangeSetHandler: (from: moment.Moment, to: moment.Moment) => void;
}

const DateFilterCustomRange: FC<IDateFilterCustomRange> = ({
  selectedDateFilter,
  customRangeSetHandler,
}) => {
  const [isRangeComplete, setIsRangeComplete] = useState(true);
  const [customRange, setCustomRange] = useState<ICustomRange | undefined>(
    undefined
  );

  useEffect(() => {
    if (selectedDateFilter?.range?.from && selectedDateFilter?.range?.to) {
      setCustomRange({
        from: selectedDateFilter?.range?.from,
        to: selectedDateFilter?.range?.to,
      });
      if (
        selectedDateFilter?.range?.from.isSame(
          selectedDateFilter?.range?.to,
          'day'
        )
      ) {
        setIsRangeComplete(false);
      } else {
        setIsRangeComplete(true);
      }
    } else {
      setCustomRange(undefined);
    }
  }, [selectedDateFilter]);

  const onDatePickerChangeHandler = (value: any) => {
    if (selectedDateFilter?.isReadOnly) {
      return;
    }
    const fromDate = moment(value?.[0] as unknown as moment.Moment).startOf(
      'day'
    );
    const toDate = moment(value?.[1] as unknown as moment.Moment).endOf('day');
    if (fromDate.isSame(toDate, 'day')) {
      setCustomRange((prev) => ({
        from: fromDate,
        to: fromDate.clone().endOf('day'),
      }));
      setIsRangeComplete(false);
      return;
    } else {
      setCustomRange((prev) => ({
        from: fromDate,
        to: toDate,
      }));
      setIsRangeComplete(true);
    }
  };

  return (
    <Flex flexDir='column' position='relative'>
      <Wrapper
        flag={isRangeComplete}
        isReadOnly={!!selectedDateFilter?.isReadOnly}
      >
        <Flex gap='20px'>
          <DateRangePicker
            isOpen
            maxDate={
              selectedDateFilter?.uniqueValue === 'custom'
                ? moment().endOf('day')?.toDate()
                : selectedDateFilter?.range?.to?.toDate() ||
                  moment().endOf('day')?.toDate()
            }
            minDate={
              selectedDateFilter?.title !== 'Custom'
                ? selectedDateFilter?.range?.from?.toDate()
                : moment('2000-01-01').toDate()
            }
            tileDisabled={() => {
              return !!selectedDateFilter?.isReadOnly || !selectedDateFilter;
            }}
            onChange={(value: any) => onDatePickerChangeHandler(value)}
            onClickDay={() => {
              if (isRangeComplete) {
                setIsRangeComplete(false);
              }
            }}
            onClickMonth={() => setIsRangeComplete((prev) => prev)}
            onClickWeekNumber={() => setIsRangeComplete((prev) => prev)}
            onClickDecade={() => setIsRangeComplete((prev) => prev)}
            onClickYear={() => setIsRangeComplete((prev) => prev)}
            shouldCloseCalendar={() => false}
            value={[
              customRange?.from?.toDate() || null,
              customRange?.to?.toDate() || null,
            ]}
            next2Label={null}
            prev2Label={null}
          />
        </Flex>
        <Flex position='absolute' top='370px' w='350px'>
          <PrimaryButton
            title='Apply'
            colorScheme='blue'
            size='md'
            variant='solid'
            disabled={!customRange || !customRange?.from || !customRange?.to}
            onClick={() => {
              if (customRange && customRange?.from && customRange?.to) {
                customRangeSetHandler(customRange.from, customRange.to);
              }
            }}
          />
        </Flex>
      </Wrapper>
    </Flex>
  );
};

export default DateFilterCustomRange;

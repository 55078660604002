import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment/moment';
import { match } from 'ts-pattern';

import { TFuncKey } from 'i18next';

import { toArray } from '../../../../utils/utils';

import { IFormInput, IWeekDays, Weekdays } from '../task.types';

const sortWeekdays = <T extends IWeekDays>(a: T, b: T) => {
  return Weekdays.indexOf(a) - Weekdays.indexOf(b);
};

const checkEndCondition = (data: IFormInput['endCondition']) => {
  switch (data?.endType) {
    case 'never':
      return true;
    case 'repeat':
      return !!data.repeatCount;
    case 'date':
      return !!data.endDate;
    default:
      return false;
  }
};

const getStartDate = (
  startDateType: IFormInput['startDateType'],
  startDate: IFormInput['startDate'],
  startTime: IFormInput['startTime']
) => {
  const ST = moment(startTime);
  switch (startDateType) {
    case 'now':
      return 'now';
    case 'date':
      if (startDate) {
        return moment(startDate)
          .hours(ST.hours())
          .minutes(ST.minutes())
          .format('[on] MMMM Do [at] hh:mm A');
      }
      return undefined;
    default:
      return undefined;
  }
};

interface TransObj {
  i18nKey: TFuncKey<'task', undefined> | undefined;
  values: object;
  context: string;
}

export const useSchedulerInfo = (): TransObj | undefined => {
  const [repeatCycle, weekDays, repeatMonthly, repeatYearly, noOfDays] =
    useWatch<
      IFormInput,
      ['repeatCycle', 'weekDays', 'repeatMonthly', 'repeatYearly', 'noOfDays']
    >({
      name: [
        'repeatCycle',
        'weekDays',
        'repeatMonthly',
        'repeatYearly',
        'noOfDays',
      ],
    });

  const [taskDue, taskDueDate, dueTime] = useWatch<
    IFormInput,
    ['taskDue', 'taskDueDate', 'dueTime']
  >({
    name: ['taskDue', 'taskDueDate', 'dueTime'],
  });

  const [startDateType, startDate, startTime] = useWatch<
    IFormInput,
    ['startDateType', 'startDate', 'startTime']
  >({
    name: ['startDateType', 'startDate', 'startTime'],
  });

  const [taskRunFor, endCondition] = useWatch<
    IFormInput,
    ['taskRunFor', 'endCondition']
  >({
    name: ['taskRunFor', 'endCondition'],
  });

  return useMemo((): TransObj | undefined => {
    const END_DATE_FORMAT = 'MMMM Do [at] hh:mm A';

    const startOn = getStartDate(startDateType, startDate, startTime);

    const DT = dueTime ? moment(dueTime) : undefined;

    if (taskRunFor === 'repeat') {
      if (!(startOn && checkEndCondition(endCondition))) {
        return undefined;
      }

      if (!DT && repeatCycle !== 'days') {
        return undefined;
      }

      return match<IFormInput['repeatCycle'], TransObj | undefined>(repeatCycle)
        .with('daily', () => {
          return {
            i18nKey: 'scheduleInfo.repeat',
            values: {
              startDate: startOn,
              repeatTime: DT!.format('hh:mm A'),
              endDate: moment(endCondition.endDate).format(END_DATE_FORMAT),
              noOfTimes: endCondition.repeatCount,
            },
            context: repeatCycle!.concat('_', endCondition.endType),
          };
        })
        .with('weekly', () => {
          if (!isEmpty(weekDays)) {
            return {
              i18nKey: 'scheduleInfo.repeat',
              values: {
                startDate: startOn,
                repeatDate: toArray(weekDays)
                  .sort(sortWeekdays)
                  .map((i) => i.slice(0, 3)),
                repeatTime: DT!.format('hh:mm A'),
                endDate: moment(endCondition.endDate).format(END_DATE_FORMAT),
                noOfTimes: endCondition.repeatCount,
              },
              context: repeatCycle!.concat('_', endCondition.endType),
            };
          }
          return undefined;
        })
        .with('monthly', () => {
          if (repeatMonthly) {
            return {
              i18nKey: 'scheduleInfo.repeat',
              values: {
                startDate: startOn,
                repeatDate:
                  repeatMonthly === 'lastDay'
                    ? 'last day'
                    : moment(repeatMonthly).format('Do'),
                repeatTime: DT!.format('hh:mm A'),
                endDate: moment(endCondition.endDate).format(END_DATE_FORMAT),
                noOfTimes: endCondition.repeatCount,
              },
              context: repeatCycle!.concat('_', endCondition.endType),
            };
          }
          return undefined;
        })
        .with('yearly', () => {
          if (repeatYearly) {
            return {
              i18nKey: 'scheduleInfo.repeat',
              values: {
                startDate: startOn,
                repeatDate: moment(repeatYearly).format('MMMM Do'),
                repeatTime: DT!.format('hh:mm A'),
                endDate: moment(endCondition.endDate).format(END_DATE_FORMAT),
                noOfTimes: endCondition.repeatCount,
              },
              context: repeatCycle!.concat('_', endCondition.endType),
            };
          }
          return undefined;
        })
        .with('days', () => {
          if (noOfDays) {
            return {
              i18nKey: 'scheduleInfo.repeat',
              values: {
                startDate: startOn,
                repeatDate: noOfDays,
                repeatTime: moment().startOf('day').format('hh:mm A'),
                endDate: moment(endCondition.endDate).format(END_DATE_FORMAT),
                noOfTimes: endCondition.repeatCount,
              },
              context: repeatCycle!.concat('_', endCondition.endType),
            };
          }
          return undefined;
        })
        .otherwise(() => undefined);
    }

    return match<IFormInput['taskDue'], TransObj | undefined>(taskDue)
      .with('nextWeek', () => {
        if (dueTime && startOn && DT) {
          return {
            i18nKey: 'scheduleInfo.once',
            values: {
              startDate: startOn,
              endDate: moment()
                .startOf('isoWeek')
                .add(1, 'week')
                .day('monday')
                .hours(DT.hours())
                .minutes(DT.minutes())
                .format(END_DATE_FORMAT),
            },
            context: taskDue,
          };
        }
        return undefined;
      })
      .with('endOfMonth', () => {
        if (startOn) {
          return {
            i18nKey: 'scheduleInfo.once',
            values: {
              startDate: startOn,
              endDate: moment().endOf('month').format(END_DATE_FORMAT),
            },
            context: taskDue,
          };
        }
        return undefined;
      })
      .with('endOfYear', () => {
        if (startOn) {
          return {
            i18nKey: 'scheduleInfo.once',
            values: {
              startDate: startOn,
              endDate: moment().endOf('year').format(END_DATE_FORMAT),
            },
            context: taskDue,
          };
        }
        return undefined;
      })
      .with('date', () => {
        if (taskDueDate && startOn && DT) {
          return {
            i18nKey: 'scheduleInfo.once',
            values: {
              startDate: startOn,
              endDate: moment(taskDueDate)
                .hours(DT.hours())
                .minutes(DT.minutes())
                .format(END_DATE_FORMAT),
            },
            context: taskDue,
          };
        }
        return undefined;
      })
      .otherwise(() => undefined);
  }, [
    taskRunFor,
    // Task publish details
    startDateType,
    startDate,
    startTime,
    // Task due details
    taskDue,
    taskDueDate,
    dueTime,
    // Repeat details
    repeatCycle,
    weekDays,
    repeatMonthly,
    repeatYearly,
    noOfDays,
    // End condition
    endCondition?.endType,
    endCondition?.repeatCount,
    endCondition?.endDate,
  ]);
};

import styled from '@emotion/styled';
import { Input } from 'antd';

export const CustomTextArea = styled(Input.TextArea)`
  font-size: 16px;
  padding-left: 4px;
  padding-right: 4px;
  font-family: 'Inter', sans-serif;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #979ca3;
    opacity: 1; /* Firefox */
    font-size: 16px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #979ca3;
    font-size: 16px;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #979ca3;
    font-size: 16px;
  }
`;

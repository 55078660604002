// import scss
import React from 'react';
import { useState, useEffect } from 'react';
import { ChakraProvider, extendTheme, Progress } from '@chakra-ui/react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { TrainingById } from 'sop-commons/Query/Sop';

import { UserProgressEntity } from '../../pages/Training/training.types';
import './TrainingModal.scss';
import closeBtn from '../../assets/images/closeIconWhite.png';
import { ReactComponent as ShowTrainingImg } from '../../assets/images/showTrainingImg.svg';
import TrainingPathListItemModal from './TrainingPathListItemModal';

//import 'fontsource-inter/500.css';
//import theme from './theme';

import CustomCarousel from './CustomCarousel';
import { userObj } from 'sop-commons/src/client/clientFactory';

const colors = {
  brand: {
    50: '#ecefff',
    100: '#cbceeb',
    200: '#a9aed6',
    300: '#888ec5',
    400: '#666db3',
    500: '#4d5499',
    600: '#3c4178',
    700: '#2a2f57',
    800: '#181c37',
    900: '#080819',
  },
};
const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};
const theme = extendTheme({ colors, config });

type ITrainingPathModal = {
  open: boolean;
  onClose: () => void;
  selectedTrainingData: any;
  getSingleTrainingHandler?: () => void;
  quizCardDetails?: {
    cardId: string;
    cardType: string;
    completed: null | string | boolean;
    createdAt: string;
    eid: string;
    entityId: string;
    responseDetails: JSON;
    updatedAt: Date;
    userId: string;
    response: string[];
  }[];
  setShouldAllowSettingModalBooleanValue?: React.Dispatch<
    React.SetStateAction<string>
  >;
};

const TrainingPathModal = ({
  open,
  onClose,
  selectedTrainingData,
  getSingleTrainingHandler,
  quizCardDetails,
  setShouldAllowSettingModalBooleanValue,
}: ITrainingPathModal) => {
  const { t } = useTranslation('training');
  const userObject = useReactiveVar(userObj);
  const [activePage, setActivePage] = useState(1);
  const [isQuizSelection, setIsQuizSelection] = useState(true);
  const [isOpenTrainingPathListItem, setIsOpenTrainingPathListItem] =
    useState(false);
  const [selectedTraining, setSelectedTraining] = useState(null);

  // const [getTrainingById, { loading: getTrainingLoading, data: trainingData }] =
  //   useLazyQuery(TrainingById, { fetchPolicy: 'network-only' });

  // useEffect(() => {
  //   getTrainingById({
  //     variables: {
  //       eid: '2l2qan4oon62uljx85qda6e8',
  //     },
  //   });
  //   if (selectedTrainingData?.assignedTo?.[0]?.totalViewedCount > 0) {
  //     setActivePage(selectedTrainingData?.assignedTo?.[0]?.totalViewedCount + 1);
  //   }
  // }, []);

  //let getTrainingPathData = trainingData?.trainingById;
  useEffect(() => {
    // let found = selectedTrainingData?.trainingContentExpanded?.map((expand: any, index: number) => {
    //   if (!expand?.completed) {
    //     return index;
    //   }
    // });
    selectedTrainingData?.userProgress?.map((progress: UserProgressEntity) => {
      if (progress?.userId === userObject?.eid) {
        if (progress?.viewedContents && progress?.viewedContents?.length > 0) {
          let index = -1;
          for (let i in selectedTrainingData?.trainingContentExpanded) {
            if (
              !selectedTrainingData?.trainingContentExpanded?.[i]?.completed
            ) {
              index = +i;
              break;
            }
          }
          if (index > -1) {
            setActivePage(index + 1);
          } else {
            setActivePage(1);
          }
        }
      }
    });
  }, []);
  let getContentDetails = selectedTrainingData?.trainingContentExpanded;
  const filterData = getContentDetails?.filter(
    (dt: any) => dt.type !== 'milestone'
  );

  const currentPage = (cPage: number) => {
    setActivePage(cPage);
  };

  const percentage = (x: any, y: any) => {
    return 100 / (y / x);
  };

  const openTrainingListing = () => {
    if (isOpenTrainingPathListItem) {
      setIsOpenTrainingPathListItem(false);
    } else {
      setIsOpenTrainingPathListItem(true);
    }
  };

  const onCloseTrainingPathListItem = () => {
    setIsOpenTrainingPathListItem(false);
  };

  const selectedTrainingItem = (image: any, active: number) => {
    setSelectedTraining(image);
    setActivePage(active);
  };

  const removeSelectedImage = () => {
    setSelectedTraining(null);
  };

  const slideCount = selectedTrainingData?.trainingContentExpanded?.length;

  const setActivePageHandler = () => {
    // if (activePage !== slideCount) {
    //   setActivePage(activePage + 1);
    // } else {
    //   setActivePage(1);
    // }
    setActivePage(activePage + 1);
  };

  const onCloseHandler = () => {
    if (setShouldAllowSettingModalBooleanValue) {
      setShouldAllowSettingModalBooleanValue('child');
    }
    onClose();
  };

  return (
    <>
      {getContentDetails && (
        <div className='training-path-main-container'>
          <div className='training-path-sub-container'>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <img
                src={closeBtn}
                style={{
                  cursor: 'pointer',
                  borderRadius: '100%',
                  height: '40px',
                  width: '40px',
                  padding: '5px',
                  marginTop: '10px',
                  marginRight: '10px',
                }}
                onClick={onCloseHandler}
              />
            </div>
            <div style={{ width: '100%', height: '80%' }}>
              <CustomCarousel
                slides={selectedTrainingData?.trainingContentExpanded}
                currentPage={currentPage}
                selectedTraining={selectedTraining}
                removeSelectedImage={removeSelectedImage}
                activePage={activePage}
                setActivePageHandler={setActivePageHandler}
                selectedTrainingData={
                  selectedTrainingData?.trainingContentExpanded
                }
                trainingId={selectedTrainingData?.eid}
                isQuizSelection={isQuizSelection}
                setIsQuizSelection={setIsQuizSelection}
                getSingleTrainingHandler={getSingleTrainingHandler}
                quizCardDetails={quizCardDetails as any}
                title={selectedTrainingData?.title}
                onFinish={onClose}
                setShouldAllowSettingModalBooleanValue={
                  setShouldAllowSettingModalBooleanValue
                }
              />
              {/* <ChakraProvider theme={theme}>
              </ChakraProvider> */}
            </div>
            {selectedTrainingData?.trainingContentExpanded?.length > 0 && (
              <div
                style={{
                  width: '100%',
                  height: '10%',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <Progress
                    value={percentage(activePage, getContentDetails?.length)}
                    alignSelf='center'
                    borderRadius='2px'
                    bg='#b6b9ba'
                    flex={1}
                    h='3px'
                    sx={{
                      '> div': {
                        backgroundColor: '#68a16a',
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginBottom: '-4px', marginLeft: '10px' }}>
                    <ShowTrainingImg />
                  </div>
                  <p
                    className='ShowTraining'
                    onClick={() => openTrainingListing()}
                  >
                    {t('progress_modal_show_training')}
                  </p>
                </div>
              </div>
            )}
          </div>
          {isOpenTrainingPathListItem && (
            <TrainingPathListItemModal
              open={isOpenTrainingPathListItem}
              onClose={onCloseTrainingPathListItem}
              getContentDetails={getContentDetails}
              selectedTrainingItem={selectedTrainingItem}
              activePage={activePage}
              isQuizSelection={isQuizSelection}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TrainingPathModal;

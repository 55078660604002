import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import FormInput from '../../../../../atoms/FormInput';
import { IFormInput } from '../../task.types';

interface IProps {
  isReadOnly?: boolean;
  name: 'noOfDays' | 'endCondition.repeatCount';
  visible?: boolean;
  suffixText?: string;
  placeholder?: string;
  minValue: number;
  deps?: string | string[];
}

const DayInputField: FC<IProps> = ({
  isReadOnly,
  name,
  visible,
  placeholder,
  suffixText,
  minValue,
  deps,
}) => {
  const { t } = useTranslation(['task']);

  const { control } = useFormContext<IFormInput>();

  if (!visible) {
    return null;
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: t('task:validation.thisFieldRequired'),
        pattern: {
          value: /^[0-9]+$/,
          message: t('task:validation.onlyNumberAccepted'),
        },
        min: {
          value: minValue,
          // @ts-ignore
          message: t('task:validation.minValues', {
            value: minValue,
          }),
        },
        deps: deps,
      }}
      defaultValue={'' as never}
      render={({ field, fieldState }) => {
        return (
          <FormControl
            mt={3}
            maxW='280px'
            isInvalid={!!fieldState.error}
            isReadOnly={isReadOnly}
          >
            <FormInput
              min={minValue}
              id='repeat-count'
              size='lg'
              placeholder={placeholder || t('task:enterNumberGreater')}
              {...field}
              rightIcon={
                field?.value && (
                  <Box fontSize='14px' color='#BFBFBF' pr={2}>
                    {suffixText || t('task:days')}
                  </Box>
                )
              }
            />

            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default DayInputField;

import * as yup from 'yup';
import { AssignToType } from '../task.types';

const assignToType = yup
  .array()
  .of(yup.string())
  .min(1, 'This field is required')
  .required('This field is required');

const assignedToLocation = yup.array().when('assignToType', {
  // @ts-ignore
  is: (val = [] as AssignToType[]) => val.includes('location'),
  then: () =>
    yup
      .array()
      .of(yup.string())
      .min(1, 'Please select location')
      .required('Please select location'),
  otherwise: () => yup.array(),
});

const assignedToUsers = yup.array().when('assignToType', {
  // @ts-ignore
  is: (val = [] as AssignToType[]) => val.includes('member'),
  then: () =>
    yup
      .array()
      .of(yup.string())
      .min(1, 'Please select member')
      .required('Please select member'),
  otherwise: () => yup.array(),
});

const assignedToRole = yup.array().when('assignToType', {
  // @ts-ignore
  is: (val = [] as AssignToType[]) => val.includes('job'),
  then: () =>
    yup
      .array()
      .of(yup.string())
      .min(1, 'Please select job')
      .required('Please select job'),
  otherwise: () => yup.array(),
});

export const AssigneesValidationRule = {
  assignToType: assignToType,

  assignedToLocation: assignedToLocation,

  assignedToUsers: assignedToUsers,

  assignedToRole: assignedToRole,
};

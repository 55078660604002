import React, { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';

import { toArray } from '../../../../utils/utils';
import { IFormInput } from '../task.types';
import { InfoText, SelectOption } from '../common';

interface IProps {
  members: SelectOption[];
}

export const useSupervisorNames = (
  members: SelectOption[] = []
): [string[], number] => {
  const supervisorIds = useWatch<IFormInput, 'supervisors'>({
    name: 'supervisors',
  });

  return useMemo(() => {
    const data = toArray(members).reduce<string[]>((acc, value) => {
      if (supervisorIds?.includes(value.value)) {
        acc.push(value.label);
      }
      return acc;
    }, []);

    if (data.length > 4) {
      return [data.slice(0, 3), data.slice(3).length];
    }

    return [data, 1];
  }, [members, supervisorIds]);
};

const SupervisorHint: FC<IProps> = ({ members }) => {
  const { t } = useTranslation('task');

  const [selected, haveMore] = useSupervisorNames(members);

  if (!selected?.length) {
    return null;
  }

  return (
    <InfoText>
      <Trans
        t={t}
        i18nKey='supervisorNames'
        count={haveMore}
        values={{
          names: haveMore > 1 ? selected.join(', ') : selected,
        }}
      />
    </InfoText>
  );
};

export default SupervisorHint;

import { useEffect } from 'react';
import userflow from 'userflow.js';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';

export const useUserFlowInit = (): void => {
  const user = useReactiveVar(userObj);

  useEffect(() => {
    if (process?.env?.NODE_ENV === 'development') {
      return () => {};
    }
    if (user?.eid) {
      userflow.setBaseZIndex(89);
      userflow.init(process.env.REACT_APP_USERFLOW_TOKEN!);
      userflow
        .identify(user.eid, {
          name: user.name,
          email: user.name,
          signed_up_at: new Date().toISOString(),
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('userflow error ==> ', err?.message);
        });
    }
  }, [user?.eid]);
};

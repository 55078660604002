import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';

import AddStepForm from './AddStepForm';
import { useChapterModal, useFormModal } from './AddFormChapter';

import { IFormInput } from '../task.types';

const UNDEFINED = undefined as unknown as string;

const mapId = (data: IFormInput['contents'][number]) => {
  return data.tempEid;
};

interface IProps {
  append: (values: IFormInput['contents']) => void;
}

const TaskStepFormNew: FC<IProps> = ({ append }) => {
  const { getValues } = useFormContext<IFormInput>();

  const openFormModal = useFormModal();
  const openChapterModal = useChapterModal();

  const onFormAddClick = () => {
    openFormModal({
      values: getValues('contents').map(mapId),
      onAddClick: (values) => {
        append(values);
      },
    });
  };

  const onChapterAddClick = () => {
    openChapterModal({
      values: getValues('contents').map(mapId),
      onAddClick: (values) => {
        append(values);
      },
    });
  };

  const onAddClick = (value: string) => {
    append([
      {
        type: 'todo',
        eid: UNDEFINED,
        tempEid: new Date().getTime().toString(),
        title: value,
        dateAdded: moment().format('D MMMM'),
      },
    ]);
  };

  return (
    <Flex flexDir='column' gap={4} pt='20px'>
      <AddStepForm
        onAddClick={onAddClick}
        onFormAddClick={onFormAddClick}
        onChapterAddClick={onChapterAddClick}
      />
    </Flex>
  );
};

export default TaskStepFormNew;

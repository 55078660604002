import { toArray } from '../../../../utils/utils';
import { MyTrainingModal } from './my-training';
import { MyTrainingItem } from '../types';
import { userObj } from 'sop-commons/src/client';

export class MyTrainingListModal {
  protected _data: MyTrainingModal[];
  constructor(props: MyTrainingItem[] = []) {
    this._data = toArray(props).map((value, index) => {
      let myTrainingModal = new MyTrainingModal(value);
      if ([0, 1].includes(index) && userObj()?.entity?.isDemo) {
        myTrainingModal.isDemoAccount = !localStorage.getItem(
          `demo-train-path-${value.eid}`
        );
      }
      return myTrainingModal;
    });
  }

  get data() {
    return this._data;
  }

  get length() {
    return this._data?.length || 0;
  }
}

import { gql } from '@apollo/client';
import { LAUNCHER_TASK_STEP_TYPE, TASK_DEPENDENCY } from '../../../../types';

export interface LauncherTaskStep {
  formId?: string;
  sopId?: string;
  trainingId?: string;
  stepId: string;
  title: string;
  type: LAUNCHER_TASK_STEP_TYPE;
}

export interface LauncherTask {
  eid: string;
  title: string;
  description?: string;
  duration: number;
  completed: boolean;
  dependency: TASK_DEPENDENCY;
  docRequired: boolean;
  steps: LauncherTaskStep[];
  selected?: boolean;
}

export interface LauncherContent {
  eid: string;
  category: string;
  selected?: boolean;
  tasks: LauncherTask[];
}

export interface LauncherConfig {
  eid: string;
  title?: string;
  published: boolean;
  contents: LauncherContent[];
}

export const LauncherTaskFragment = gql`
  fragment LauncherItem on LauncherTask {
    eid
    title
    description
    duration
    completed
    dependency
    docRequired
    steps {
      formId
      stepId
      title
      type
      sopId
      trainingId
    }
  }
`;

export const LauncherConfigFragment = gql`
  fragment LauncherConfig on Launcher {
    eid
    published
    contents {
      category
      eid
      selected
      tasks {
        eid
        title
        description
        duration
        completed
        dependency
        docRequired
        selected
        steps {
          formId
          stepId
          title
          type
          sopId
          trainingId
        }
      }
    }
  }
`;

import React, { FC } from 'react';
import { Flex, IconButton, Spacer, useModalContext } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import BoxHeader from '../../BoxHeader';

interface IProps {
  title?: string;
}

const ChapterViewHeader: FC<IProps> = ({ title }) => {
  const { onClose } = useModalContext();

  return (
    <Flex align='center' justify='space-between' px='20px'>
      <BoxHeader color='#CABDFF' title={title!} />
      <Spacer />
      <IconButton
        borderRadius='full'
        aria-label='more'
        icon={<FontAwesomeIcon icon={faX as IconProp} color='#5B5A64' />}
        onClick={onClose}
      />
    </Flex>
  );
};

export default ChapterViewHeader;

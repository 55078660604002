import React, { FC } from 'react';
import { Flex, Skeleton, SkeletonText } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { getRandomNumber } from '../../../../../utils';

interface IProps {}

const SkeletonTask: FC<IProps> = () => {
  return (
    <Flex
      gap={2}
      p={4}
      bg='#FFFFFF'
      align='start'
      borderRadius='12px'
      _hover={{
        boxShadow: 'lg',
      }}
    >
      <Flex flex={1} flexDir='column' gap={1}>
        <Skeleton height='12px' borderRadius='full' width='150px' />

        <SkeletonText mt='4' noOfLines={getRandomNumber(3, 7)} spacing='3' />

        <Flex flexDir='column' gap={2} mt={2}>
          {Array.from({ length: getRandomNumber(0, 3) })?.map((step, index) => (
            <Flex align='center' key={index} gap='10px' color='#6F767E'>
              <ArrowForwardIcon boxSize={4} mt={0.5} />
              <Skeleton height='6px' width={`${getRandomNumber(40, 60)}%`} />
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Skeleton boxSize='16px' borderRadius='full' ml={2} />
    </Flex>
  );
};

export default SkeletonTask;

import React, { FC } from 'react';
import {
  Box,
  Flex,
  Image,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Controller, useFormContext } from 'react-hook-form';

import { ISupportedLocalesEntity } from '../../ui-components/LanguageSetting/language-setting.types';
import translateIcon from '../../assets/images/language/translate.svg';
import { CardFormInput, QuizOptionData } from './card-editor.types';
import { TextLayout } from 'sop-commons/src/utils/cardLayouts';
import { toArray } from '../../utils/utils';

interface IProps {
  languageName: string;
  languages: ISupportedLocalesEntity;
  value: string;
  onChange: (value: string) => void;
}

const ChangeLanguage: FC<IProps> = ({
  languageName,
  languages,
  value,
  onChange,
}) => {
  const { setValue, getValues } = useFormContext<CardFormInput>();

  const onLanguageChange = (lng: string) => {
    const defaultLanguage = getValues('defaultLanguage');
    const cardType = getValues('cardType')?.value;
    const translations = getValues('translations');
    const data = translations?.[lng];
    setValue('title', data?.title || '');
    setValue('subTitle', data?.subTitle || '');
    setValue('raw_content', data?.raw_content || '');

    const cardContent = toArray(data?.content);
    let tiptap = cardContent?.find((val: any) => val.type === 'tiptap');

    let mergeContent = [];

    if (!cardContent?.length && defaultLanguage) {
      mergeContent = toArray(translations?.[defaultLanguage]?.content);
    } else {
      mergeContent = cardContent;
    }

    let images = mergeContent?.find((val: any) => val.type === 'image');

    switch (cardType) {
      case 'text':
        if (getValues('layout') === TextLayout.RICH_TEXT) {
          setValue(
            'bodyJson',
            tiptap?.tiptap || {
              type: 'doc',
              content: [],
            }
          );
        }
        break;
      case 'image':
        setValue('images', images?.image);
        break;
      case 'quiz':
        setValue(
          'choices',
          cardContent?.[0]?.choices?.map((val: QuizOptionData) => ({
            ...val,
            choiceId: val.id,
          })),
          {
            shouldDirty: true,
          }
        );
        setValue('correctAnswer', cardContent?.[0]?.correctAnswer, {
          shouldDirty: true,
        });
        break;
      case 'video':
        setValue('caption', cardContent?.[1]?.caption, {
          shouldDirty: true,
        });
        setValue('videoUrl', mergeContent?.[0]?.video, {
          shouldDirty: true,
        });
        break;
      case 'form':
        setValue('formLink', mergeContent?.[0]?.url);
        break;
      case 'mixed':
        setValue('images', images?.image, {
          shouldDirty: true,
        });
        setValue(
          'bodyJson',
          tiptap?.tiptap || {
            type: 'doc',
            content: [],
          }
        );
        break;
    }
    onChange(lng);
  };

  return (
    <Popover placement='bottom-end'>
      {/*// @ts-ignore */}
      <PopoverTrigger>
        <Flex align='center' gap={1} cursor='pointer' color='#2A85FF'>
          <Box pos='relative' mr={1}>
            <Image w='18px' h='18px' src={translateIcon} />
            <Box
              pos='absolute'
              top='-4px'
              right='-2px'
              h='10px'
              w='10px'
              borderRadius='full'
              bg='#FF6A55'
            />
          </Box>
          {languageName}
          <ChevronDownIcon fontSize={'lg'} />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        rootProps={{
          zIndex: 101,
        }}
      >
        <PopoverArrow />
        <PopoverBody>
          <List spacing={3}>
            {languages?.map((prefer, index) => (
              <ListItem
                key={index}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
              >
                <Flex
                  align='center'
                  gap={4}
                  cursor='pointer'
                  onClick={() => onLanguageChange(prefer.languageCode)}
                >
                  <Image src={prefer?.thumbnail} boxSize='25px' />
                  <Text
                    fontWeight={
                      value === prefer.languageCode ? 'bold' : 'normal'
                    }
                  >
                    {prefer?.nativeName}
                  </Text>
                </Flex>
                {value === prefer.languageCode && (
                  <CheckIcon color='rgba(42, 133, 255, 1)' />
                )}
              </ListItem>
            ))}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const EditLanguage: FC<{
  languages: ISupportedLocalesEntity;
  languageName: string;
}> = ({ languages, languageName }) => {
  const { t, i18n } = useTranslation(['card']);
  const { control } = useFormContext();

  return (
    <Controller
      defaultValue={i18n.language}
      control={control}
      name={'language'}
      render={({ field }) => {
        return (
          <Flex fontSize='15px' fontWeight='600' gap={2}>
            {t('card:editing_in')}
            <ChangeLanguage
              languageName={languageName}
              languages={languages}
              value={field.value}
              onChange={async (value: string) => {
                await i18n.changeLanguage(value);
                field.onChange(value);
              }}
            />
          </Flex>
        );
      }}
    />
  );
};

export default EditLanguage;

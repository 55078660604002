export const containerStyle = () => {
  return {
    position: 'absolute',
    zIndex: '100',
    bottom: '90px',
    marginRight: '16px',
  };
};

export const pickerStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    'em-emoji-picker': {
      '--border-radius': '0 0 10px 10px',
      '--font-family': "'Inter', 'Open Sans', sans-serif",
      // '--font-size': '20px',
      '--rgb-accent': '174, 101, 197',
      height: '320px',
    },
  };
};

export const closeEmojiStyle = () => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px 10px 0 0',
    paddingInline: '12px',
    height: '40px',
    boxShadow:
      '.3px .5px 2.7px hsl(0deg 0% 0% / .14), .4px .8px 1px -3.2px hsl(0deg 0% 0% / .14), 1px 2px 2.5px -4.5px hsl(0deg 0% 0% / .14)',
    // cursor: 'pointer',
    img: {
      cursor: 'pointer',
    },
  };
};

import { useState, useContext } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';

import { CometChatAvatar, CometChatUserPresence } from '../../Shared';

import { CometChatContext } from '../../../util/CometChatContext';

import { theme } from '../../../resources/theme';
import { ReactComponent as EpLocation } from '../../../../../../assets/images/epLocation.svg';
import { ReactComponent as CheckedIcon } from '../../../../../../assets/images/checkedIcon.svg';

import {
  modalRowStyle,
  modalColumnStyle,
  avatarStyle,
  nameStyle,
  selectionColumnStyle,
  selectionBoxStyle,
  roleStyle,
  locationBullet,
  roleBox,
  unChecked,
  isChecked,
  locationElipsis,
} from './style';

import inactiveIcon from './resources/group-member-unselect.svg';
import activeIcon from './resources/group-member-select.svg';

const CometChatAddGroupMemberListItem = (props) => {
  const { groupMembers, theme } = useContext(CometChatContext);

  const [checked, setChecked] = useState(() => {
    const found = groupMembers.find((member) => member.uid === props.user.uid);
    const value = found ? true : props?.alreadyChecked || false;

    return value;
  });

  const handleCheck = (event) => {
    const value = checked === true ? false : true;
    setChecked(value);
    props.changed(props.user, value);
  };

  const toggleTooltip = (event, flag) => {
    const elem = event.currentTarget;
    const nameContainer = elem.lastChild;

    const scrollWidth = nameContainer.scrollWidth;
    const clientWidth = nameContainer.clientWidth;

    if (scrollWidth <= clientWidth) {
      return false;
    }

    if (flag) {
      nameContainer.setAttribute('title', nameContainer.textContent);
    } else {
      nameContainer.removeAttribute('title');
    }
  };

  return (
    <div css={modalRowStyle(theme)} aria-checked={checked}>
      <div
        css={modalColumnStyle()}
        className='userinfo'
        onMouseEnter={(event) => toggleTooltip(event, true)}
        onMouseLeave={(event) => toggleTooltip(event, false)}
        onClick={handleCheck}
      >
        <div css={avatarStyle()} className='avatar'>
          <CometChatAvatar user={props.user} />
          <CometChatUserPresence
            status={props.user.status}
            style={{ top: '-41px' }}
          />
        </div>
        <div style={{ overflow: 'hidden', flex: 1 }}>
          <div css={nameStyle()} className='name'>
            {props.user.name}
          </div>

          <div css={roleStyle()} className='name'>
            <span
              className='name__role'
              data-role={props?.user?.metadata?.role || ''}
            >
              {props?.user?.metadata?.role || props?.user?.role}
            </span>
            <div css={locationBullet()}></div>
            <p css={locationElipsis()}>
              {props?.user?.metadata?.parents
                ? props?.user?.metadata?.parents
                : 'N/A'}
            </p>
          </div>
        </div>
      </div>
      <div
        css={selectionColumnStyle()}
        className='selection'
        id={props.user.uid + 'sel'}
        onClick={handleCheck}
      >
        {checked ? (
          <div css={isChecked()}>
            <CheckedIcon
              style={{
                width: '13.7px',
                height: '13.7px',
                objectFit: 'contain',
              }}
            />
          </div>
        ) : (
          <div css={unChecked()}></div>
        )}
      </div>
    </div>
  );
};

// Specifies the default values for props:
CometChatAddGroupMemberListItem.defaultProps = {
  theme: theme,
};

CometChatAddGroupMemberListItem.propTypes = {
  theme: PropTypes.object,
};

export { CometChatAddGroupMemberListItem };

import React, { FC, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Center, Image, useToast } from '@chakra-ui/react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Row } from 'react-table';

import { truncateString } from '../../../../utils';
import EmptyState from '../../../../sub-components/EmptyState';

import { UserTrainingItem } from '../../../../sub-components/TrackTrainingProgress/user-training.types';
import {
  USER_TRAINING_QUERY,
  UserTrainingResponse,
} from './user-training.graphql';
import { toArray } from '../../../../utils/utils';
import {
  Column,
  SortingTable,
} from '../../../../sub-components/ChakraTable/SortingTable';
import PathName from '../../../Training/Training/PathName';

import RetakeIcon from '../../../../assets/images/retake.svg';
import AlarmIcon from '../../../../assets/images/alarm.svg';
import { useRetakeTraining } from '../../../../ui-components/Training';
import SingleUserChatModal from '../../../../ui-components/SingleUserChatModal';
import { reAssignTraining } from '../../../Training/TrainingList/training.graphql';
import { ActionButton } from '../../../../ui-components/Confirm';

interface RowItem extends UserTrainingItem {
  totalCards: number;
  viewedCards: number;
}

function SortByProgress(a: Row<RowItem>, b: Row<RowItem>): number {
  const viewCountA = a.original.viewedCards;
  const maxCountA = a.original.totalCards;

  const valA = (viewCountA / maxCountA) * 100;

  const viewCountB = b.original.viewedCards;
  const maxCountB = b.original.totalCards;

  const valB = (viewCountB / maxCountB) * 100;

  return valB - valA;
}

interface IProps {
  userId: string;
  username?: string;
}

const TrainingComponent: FC<IProps> = ({ userId, username }) => {
  const { t, i18n } = useTranslation(['common', 'training']);
  const [trainings, setTraining] = useState<UserTrainingItem[]>([]);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const apolloClient = useApolloClient();

  const getTrainingStatus = useCallback(
    (filteredResult): UserTrainingItem['status'] => {
      if (filteredResult) {
        if (
          (filteredResult?.started && filteredResult?.completed) ||
          filteredResult?.completed
        ) {
          return 'common:completed';
        } else if (filteredResult?.started && !filteredResult?.completed) {
          return 'common:pending';
        } else return 'common:pending';
      } else return 'common:pending';
    },
    []
  );

  function filterCard(val: any) {
    return val.type === 'card' || val.type === 'sop';
  }

  const onQueryComplete = useCallback(
    (data: UserTrainingResponse) => {
      const trainingListData = data?.userTrainingDetails || [];

      let newArrTraining = trainingListData.map((trainData) => {
        const filteredResult = trainData?.userProgress.find(
          (e) => e.userId === userId
        );

        const viewedCards = toArray(filteredResult?.viewedContents).filter(
          filterCard
        );
        const totalCards = toArray(trainData?.trainingItems).filter(filterCard);
        const status = getTrainingStatus(filteredResult);

        const viewedCardsLength = viewedCards?.length || 0;
        const totalCardsLength = totalCards?.length || 0;

        return {
          ...trainData,
          viewedCards: viewedCardsLength,
          totalCards: totalCardsLength,
          status: status,
          progress: `${viewedCardsLength}/${totalCardsLength}`,
          isCompleted: status === 'common:completed',
        };
      });

      setTraining(newArrTraining as never);
    },
    [userId, getTrainingStatus]
  );

  const { loading } = useQuery<UserTrainingResponse>(USER_TRAINING_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      userId: userId,
    },
    onCompleted: onQueryComplete,
  });

  const [showChatModal, setShowChatModal] = useState(false);

  const [showRemindUserChatModal, setShowRemindUserChatModal] = useState(false);
  const [trainingPathData, setTrainingPathData] = useState<UserTrainingItem>();

  const retakeTraining = useRetakeTraining();

  const [reassignTraining] = useMutation(reAssignTraining, {
    onCompleted: async () => {
      toast({
        status: 'success',
        title: t('common:update'),
      });
    },
    onError: (error) => {
      toast({
        status: 'error',
        title: t('common:error'),
      });
    },
  });

  const confirmRetake = async (data: UserTrainingItem) => {
    setTrainingPathData(data);
    setShowChatModal(true);
    const res = await reassignTraining({
      variables: {
        userId: userId,
        trainingId: data.eid,
      },
    });
    if (res?.data) {
      return await apolloClient
        .query<UserTrainingResponse>({
          fetchPolicy: 'network-only',
          query: USER_TRAINING_QUERY,
          variables: {
            userId: userId,
          },
        })
        .then((response) => {
          if (response.data) {
            onQueryComplete(response.data);
          }
        });
    }
  };

  const onRetake = (data: UserTrainingItem) => {
    return new Promise((resolve) => {
      retakeTraining({
        // @ts-ignore
        title: t('training:retake_confirm_user', {
          name: username,
        }),
        onOk: async () => {
          await confirmRetake(data);
          resolve(t('common:success'));
        },
        onCancel: () => resolve(null),
      });
    });
  };

  const onItemClicked = (data?: UserTrainingItem) => {
    if (data) {
      if (data.status === 'common:completed') {
        return onRetake(data);
      } else {
        setShowRemindUserChatModal(true);
        setTrainingPathData(data);
        setShowChatModal(true);
      }
    }
  };

  const onChatClose = () => {
    setShowRemindUserChatModal(false);
    setTrainingPathData(undefined);
    setShowChatModal(false);
  };

  const columns = useMemo((): Column<UserTrainingItem>[] => {
    return [
      {
        Header: t('common:training'),
        accessor: 'title',
        width: '41%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <PathName
              pathName={value}
              thumbnail={row?.original.thumbnail}
              index={row.index}
              updatedAt={row.original.contentLastUpdate}
              status='active'
              onClick={() => null}
            />
          );
        },
      },
      {
        Header: t('common:status'),
        accessor: 'status',
        width: '22%',
        Cell: ({ cell: { value } }) => {
          const isPending = value === 'common:pending';
          return (
            <Box
              mb='25px'
              fontSize='14px'
              fontWeight='600'
              lineHeight='24px'
              textAlign='center'
              width='max-content'
              px='10px'
              borderRadius='8px'
              bg={isPending ? 'rgba(255, 188, 153, 0.2)' : '#EAFAE4'}
              color={isPending ? '#FF6A55' : '#83BF6E'}
            >
              {t(value)}
            </Box>
          );
        },
      },
      {
        Header: t('training:chapterCompleted'),
        accessor: 'progress',
        width: '22%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <Box
              mb='25px'
              fontSize='15px'
              fontWeight='600'
              lineHeight='24px'
              textAlign='center'
              width='max-content'
              px='10px'
              borderRadius='5px'
              bg={row?.original?.isCompleted ? '#EAFAE4' : '#EFEFEF'}
              color={row?.original?.isCompleted ? '#83BF6E' : '#1A1D1F'}
              cursor='pointer'
              // onClick={() => onTrackProgress(row?.original)}
            >
              {value}
            </Box>
          );
        },
        sortType: SortByProgress as never,
      },
      {
        Header: '',
        accessor: 'isCompleted',
        id: 'action',
        width: '18%',
        Cell: ({ cell: { value, row } }) => {
          const icon = value ? RetakeIcon : AlarmIcon;
          return (
            <Center w='full' mb='25px'>
              <ActionButton
                leftIcon={<Image src={icon} w='17px' h='17px' />}
                fontSize='14px'
                borderRadius='8px'
                fontWeight='600'
                size='sm'
                variant='outline'
                px={4}
                actionFn={() => onItemClicked(row?.original)}
              >
                {value
                  ? t('training:retake')
                  : t('training:training_details_section.remind_button')}
              </ActionButton>
            </Center>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [i18n.language]);

  return (
    <>
      <SortingTable
        colorScheme='blue'
        emptyData={{
          content: (
            <EmptyState
              image='TrainingPath'
              title={
                <Trans
                  t={t}
                  i18nKey='training:noTrainingAssignedTo'
                  values={{
                    name: truncateString(username, 24),
                  }}
                />
              }
            />
          ),
        }}
        page={1}
        columns={columns}
        data={trainings}
        isLoading={loading}
        isResponsive
        isHidePagination={true}
      />

      {showChatModal && (
        <SingleUserChatModal
          onClose={onChatClose}
          selectedUser={userId}
          prefilledMessage={
            showRemindUserChatModal
              ? `It seems you have been stuck/haled on the training module. Sending you a gentle reminder to resume your training.\n${process.env.REACT_APP_FRONTEND_URL}/view/${trainingPathData?.eid}`
              : `To refresh your knowledge and review the content to solidify your understanding, I would like to ask you to retake the training.\n${process.env.REACT_APP_FRONTEND_URL}/view/${trainingPathData?.eid}`
          }
        />
      )}
    </>
  );
};

export default TrainingComponent;

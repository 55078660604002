import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faSortDown, faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Respondent } from 'pages/forms/forms-types';

interface IProps {
  showFilters: boolean | undefined;
  usersData: Respondent[] | undefined;
  getSelectedCountHandler: (type: 'user' | 'location') => number;
  onFilterCloseHandler: () => void;
  userFilterClickHandler: ((user: Respondent) => void) | undefined;
}

const UserHeaderComponent: FC<IProps> = ({
  showFilters,
  usersData,
  getSelectedCountHandler,
  onFilterCloseHandler,
  userFilterClickHandler,
}) => {
  const { t } = useTranslation(['form']);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onMainFlexClick = () => {
    if (!isOpen && showFilters) {
      onOpen();
    }
  };

  return (
    <Flex gap='5px' onClick={onMainFlexClick} cursor='pointer'>
      <Flex justify='flex-start' gap='5px' w='50%'>
        <Text>{t('form:user')}</Text>
        {showFilters && getSelectedCountHandler('user') > 0 && (
          <Text>({getSelectedCountHandler('user')})</Text>
        )}
      </Flex>
      {showFilters && (
        <Flex justify='flex-end' w='50%'>
          <Menu
            isOpen={isOpen}
            onClose={() => {
              onClose();
              onFilterCloseHandler();
            }}
          >
            <MenuButton>
              <FontAwesomeIcon
                icon={faSortDown as IconProp}
                style={{ marginBottom: '2px' }}
                color='#6f767e'
              />
            </MenuButton>
            <Portal>
              <MenuList zIndex={9999} p={2} maxH='300px' overflowY='scroll'>
                {usersData?.map((user) => (
                  <Flex
                    key={user?.eid}
                    align='center'
                    justify='space-between'
                    p={2}
                    my={2}
                    cursor='pointer'
                    onClick={() =>
                      userFilterClickHandler && userFilterClickHandler(user)
                    }
                  >
                    <Text isTruncated maxW='200px'>
                      {user?.name}
                    </Text>
                    {!user?.selected && (
                      <FontAwesomeIcon
                        icon={faSquare as IconProp}
                        color='#6F767E'
                        size='lg'
                      />
                    )}
                    {user?.selected && (
                      <FontAwesomeIcon
                        icon={faSquareCheck as IconProp}
                        color='#2a85ff'
                        size='lg'
                      />
                    )}
                  </Flex>
                ))}
              </MenuList>
            </Portal>
          </Menu>
        </Flex>
      )}
    </Flex>
  );
};

export default UserHeaderComponent;

import { FileWithEid } from 'hooks/useUploadMultipleFilesWithCancel';
import { ISelectedStep } from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/TaskDetailDrawer/task-detail-drawer.types';
import { StateCreator } from 'zustand';

export interface LauncherTaskState {
  taskDetails: {
    isUploading: boolean;
    loading: boolean;
    markAsUnfinishedLoading: boolean;
    selectedFiles: FileWithEid[];
    selectedStep: ISelectedStep;
    reset: () => void;
    updateIsUploading: (value: boolean) => void;
    updateIsLoading: (value: boolean) => void;
    updateMarkAsUnfinishedLoading: (value: boolean) => void;
    updateSelectedFiles: (files: FileWithEid[]) => void;
    updateSelectedStep: (step: ISelectedStep) => void;
  };
}

export const launcherTaskSlice: StateCreator<LauncherTaskState> = (set) => ({
  taskDetails: {
    isUploading: false,
    loading: false,
    markAsUnfinishedLoading: false,
    selectedFiles: [],
    selectedStep: {
      loading: false,
      index: -1,
    },
    reset: () =>
      set((state) => ({
        taskDetails: {
          ...state.taskDetails,
          isUploading: false,
          loading: false,
          markAsUnfinishedLoading: false,
          selectedFiles: [],
          selectedStep: {
            loading: false,
            index: -1,
          },
        },
      })),
    updateIsUploading: (value) =>
      set((state) => ({
        taskDetails: {
          ...state.taskDetails,
          isUploading: value,
        },
      })),
    updateIsLoading: (value) =>
      set((state) => ({
        taskDetails: {
          ...state.taskDetails,
          loading: value,
        },
      })),
    updateMarkAsUnfinishedLoading: (value) =>
      set((state) => ({
        taskDetails: {
          ...state.taskDetails,
          markAsUnfinishedLoading: value,
        },
      })),
    updateSelectedFiles: (files) =>
      set((state) => ({
        taskDetails: {
          ...state.taskDetails,
          selectedFiles: files,
        },
      })),
    updateSelectedStep: (step) =>
      set((state) => ({
        taskDetails: {
          ...state.taskDetails,
          selectedStep: step,
        },
      })),
  },
});

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  margin-top: 16px;

  .supervised-div {
    display: flex;
    align-items: center;
  }

  .created-by {
    display: flex;
    align-items: center;
    white-space: pre;

    font-weight: 400;
    font-size: 14px;
    line-height: 1.71;

    > .name {
      font-weight: 600;
      padding-left: 4px;
    }
  }
  .ellipse {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #354b76;
    margin: 0 12px;
  }

  .more-button {
    font-size: 14px;
    font-weight: 500;
    color: #2a85ff;
    padding: 0 2px;
    cursor: pointer;
  }

  .avatar-list {
    position: relative;
    display: flex;
    padding: 0 6px;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 100ms ease-in-out;

      :hover {
        //z-index: 1;
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.7);
      }

      :not(:first-child) {
        margin-left: -8px;
      }
    }
  }
`;

import React, { ChangeEvent, FC, useContext } from 'react';
import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

import { VisibilityContext } from './context';
import { IVisibilityInput, VisibilityType } from './visibility.types';

interface IProps {}

const PublicVisibility: FC<IProps> = () => {
  const { t } = useTranslation(['common']);
  const { resetPrivateValues } = useContext(VisibilityContext);

  const { control } = useFormContext<IVisibilityInput>();

  const visibility = useWatch<IVisibilityInput>({
    name: 'visibility',
  });

  const onChange = (
    event: ChangeEvent<HTMLInputElement>,
    callback: (value: VisibilityType) => void
  ) => {
    callback(event.target.checked ? 'public' : 'private');
    if (event.target.checked) {
      resetPrivateValues();
    }
  };

  return (
    <Flex align='center' gap={2} opacity={visibility === 'public' ? 1 : 0.4}>
      <FontAwesomeIcon
        style={{ fontSize: 18 }}
        icon={faGlobe as IconProp}
        color='#333B4F'
      />
      <Box fontSize='20px' fontWeight='600' color='#333B4F'>
        {t('common:public')}
      </Box>
      {/* <FontAwesomeIcon
        style={{ fontSize: 17 }}
        icon={faCircleExclamation as IconProp}
        rotation={180}
        color='#CCCCCC'
      /> */}
      <Box flex={1} />
      <Controller
        control={control}
        name='visibility'
        render={({ field }) => {
          return (
            <Checkbox
              size='lg'
              {...field}
              isChecked={field.value === 'public'}
              onChange={(event) => onChange(event, field.onChange)}
            />
          );
        }}
      />
    </Flex>
  );
};

export default PublicVisibility;

import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserDataSelector } from '../../../../hooks';
import { TrainingData } from '../../training.types';
import { MyTrainingListModal } from '../modal';
import {
  getMyTrainings,
  MyTrainingResponse,
  reAssignTraining,
} from '../training.graphql';
import { IButtonValue } from './MyTrainingList';

export const useServiceLayer = (
  perPage: number,
  searchQuery: string | undefined,
  tabSelected: IButtonValue,
  setShowViewAllBtn: React.Dispatch<React.SetStateAction<boolean>> | undefined
) => {
  const { t } = useTranslation(['common', 'training']);
  const userObject = useUserDataSelector((state) => state);
  const [selectedPage, setSelectedPage] = useState(1);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  //   const [renderOnce, setRenderOnce] = useState(true);
  //   const [trainingPlay, setTrainingPlay] = useState<TrainingData>();
  //   const [trainingPlayOpen, setTrainingPlayOpen] = useState<boolean>(false);

  const getVariables = useCallback(() => {
    const filter: Record<string, any> = {
      status: 'active',
      query: searchQuery,
    };

    if (tabSelected === 'assignedToMe') {
      filter.assigned = true;
    } else {
      filter.assigned = true;
      filter.includePublic = true;
    }

    return {
      page: selectedPage,
      perPage: perPage,
      filter: filter,
      sort: '_ID_DESC',
      userIds: [userObject?.eid],
    };
  }, [searchQuery, selectedPage, tabSelected, userObject?.eid]);

  useEffect(() => {
    fetchMyTraining({
      variables: getVariables(),
    });
  }, [getVariables]);

  useEffect(() => {
    setSelectedPage(1);
  }, [tabSelected]);

  const [fetchMyTraining, { data, loading, refetch }] =
    useLazyQuery<MyTrainingResponse>(getMyTrainings, {
      fetchPolicy: 'network-only',
    });

  const [reassignTraining] = useMutation(reAssignTraining, {
    onCompleted: async (response) => {
      const tempId = localStorage.getItem('retake-training');

      if (tempId === response?.ReassignTraining?.eid) {
        localStorage.removeItem('retake-training');
        localStorage.setItem(`demo-train-path-${tempId}`, 'retake');
      }

      toast({
        status: 'success',
        title: t('common:update'),
      });
      await fetchMyTraining({
        variables: getVariables(),
      });
    },
    onError: (error) => {
      localStorage.removeItem('retake-training');
      toast({
        status: 'error',
        title: t('common:error'),
      });
    },
  });

  const dataList = useMemo(() => {
    let classData = new MyTrainingListModal(data?.userTrainingNew?.items).data;
    if (setShowViewAllBtn) {
      if (classData?.length > 0) {
        setShowViewAllBtn(true);
      } else {
        setShowViewAllBtn(false);
      }
    }
    return classData;
  }, [data]);

  const { currentPage, hasNextPage, itemCount } =
    data?.userTrainingNew?.pageInfo || {};

  //   const onTrainingPlayClose = useCallback(async () => {
  //     fetchMyTraining({
  //       variables: getVariables(),
  //     });
  //     setTrainingPlay(undefined);
  //     setTrainingPlayOpen(false);
  //     setRenderOnce(true);
  //   }, [getVariables]);

  // const fetchTrainingData = useCallback(
  //   async (trainingId?: string, openTraining?: boolean) => {
  //     if (!trainingId) {
  //       return null;
  //     }
  //     try {
  //       const response = await apolloClient.query<TrainingDetailResponse>({
  //         fetchPolicy: 'network-only',
  //         query: getTrainingDetails,
  //         variables: {
  //           eid: trainingId,
  //         },
  //       });
  //       onDataFetched(response.data, openTraining);
  //     } catch (e: any) {
  //       console.log(e?.message);
  //     }
  //   },
  //   [apolloClient, onDataFetched]
  // );

  // const onDataFetched = (
  //   response: TrainingDetailResponse,
  //   shouldOpen?: boolean
  // ) => {
  //   let _item: TrainingData = JSON.parse(
  //     JSON.stringify(response?.trainingById)
  //   );
  //   _item?.trainingContentExpanded?.forEach((it) => {
  //     it.completed = false;
  //     _item?.userProgress?.map((progress) => {
  //       if (progress?.userId === userObject?.eid) {
  //         progress?.viewedContents?.forEach((content: any) => {
  //           if (it?.uid === content?.uid) {
  //             it.completed = true;
  //           }
  //         });
  //       }
  //     });
  //   });
  //   _item?.trainingContentExpanded?.push({
  //     eid: '',
  //     uid: '',
  //     type: 'finish',
  //     title: _item?.title,
  //   });
  //   setTrainingPlay(_item);
  //   shouldOpen && setTrainingPlayOpen(true);
  // };

  // useEffect(() => {
  //   if (trainingPlay && myTrainingResponse && renderOnce) {
  //     console.log('my training response : ', myTrainingResponse);
  //     console.log('training play : ', trainingPlay);
  //     const foundTraining = myTrainingResponse.userTrainingNew.items.find(
  //       (training) => training.eid === trainingPlay.eid
  //     );
  //     console.log('Found training : ', foundTraining);
  //     if (foundTraining && trainingPlay.trainingContentExpanded) {
  //       const updatedContent = trainingPlay.trainingContentExpanded.map(
  //         (expandedItem) => {
  //           let subType = '';
  //           let parentSopId = '';
  //           for (const list of foundTraining.trainingContentList) {
  //             if (list.cards) {
  //               const matchingCard = list.cards.find(
  //                 (card) => card.eid === expandedItem.eid
  //               );
  //               if (matchingCard) {
  //                 subType = 'sop';
  //                 parentSopId = list.eid;
  //                 break;
  //               }
  //             }
  //           }
  //           return { ...expandedItem, subType, parentSopId };
  //         }
  //       );
  //       const _trainingPlay = {
  //         ...trainingPlay,
  //         trainingContentExpanded: updatedContent,
  //       };
  //       console.log('training play updated : ', _trainingPlay);
  //       setTrainingPlay(_trainingPlay);
  //       setRenderOnce(false);
  //     }
  //   }
  // }, [trainingPlay, myTrainingResponse, renderOnce]);
  return {
    currentPage,
    hasNextPage,
    itemCount,
    dataList,
    loading,
    reassignTraining,
    setSelectedPage,
  };
};

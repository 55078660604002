import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useConfirm, UseConfirm } from '../../Confirm';
import BoxHeader from '../../BoxHeader';

import { useUserDataSelector } from 'hooks';

const Content = (stepData) => {
  const userData = useUserDataSelector();

  let copyText = `Once you begin training, it will be assigned to you. Please start only if you are committed to finishing it.`;

  if (
    userData.eid !== stepData.stepData.trainingAssignee &&
    stepData.stepData.trainingAssignee
  ) {
    copyText = `Training progress and completion will be attributed to the user who initiated it.`;
  }

  return (
    <Flex flexDir='column' align='center' gap='20px'>
      <Flex
        boxSize='50px'
        align='center'
        justify='center'
        borderRadius='full'
        bg='#FFD88D99'
      >
        <FontAwesomeIcon
          icon={faTriangleExclamation as IconProp}
          color='#FAB05B'
          size='lg'
        />
      </Flex>
      <Flex color='#33383F' textAlign='center'>
        {copyText}
      </Flex>
    </Flex>
  );
};

export const useTrainingStartWarning = () => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const userData = useUserDataSelector();

  return useCallback((onOkClick, stepData) => {
    if (userData.eid === stepData.trainingAssignee) {
      onOkClick();
      return;
    }

    if (confirmRef.current) {
      confirmRef.current.destroy();
    }
    confirmRef.current = confirm({
      title: (
        <Flex align='center' gap={3} justify='space-between'>
          <BoxHeader title={'Start this training?'} color='#FFBC99' />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: <Content stepData={stepData} />,
      isCentered: true,
      contentProps: {
        py: '8px',
        maxW: '500px',
      },
      cancelButtonProps: {
        flex: 1,
        fontSize: '15px',
        variant: 'outline',
      },
      okButtonProps: {
        flex: 1,
        fontSize: '15px',
      },
      okText: 'Yes, start my training',
      onOK: onOkClick,
    });
  }, []);
};

import React, { PropsWithChildren } from 'react';
import { Box, Center, Checkbox, Flex } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps<T> {
  data: T;
}

function CommonListItem<T extends { eid: string }>({
  data = {} as T,
  children,
}: PropsWithChildren<IProps<T>>): React.ReactElement {
  const { control, setValue, watch } = useFormContext();

  const isSelected = watch('preview')?.eid === data.eid;

  return (
    <Controller
      name={`taskStepSelection.${data.eid}`}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Flex
            px={3}
            borderRadius='4px'
            _notLast={{
              '> div ': {
                borderBottom: '1px solid #EFEFEF',
              },
            }}
            _selected={{
              backgroundColor: '#2A85FF1F',
            }}
            aria-selected={value?.eid === data.eid}
          >
            <Flex py={3} flex={1} gap={3}>
              <Flex
                flex={1}
                cursor='pointer'
                overflow='hidden'
                onClick={() => setValue('preview', data)}
              >
                {children}
              </Flex>
              <Flex gap={3} alignItems='start'>
                {isSelected ? (
                  <Center
                    boxSize='20px'
                    cursor='pointer'
                    onClick={(event) => {
                      event.stopPropagation();
                      setValue('preview', undefined);
                    }}
                  >
                    <FontAwesomeIcon icon={faEye as IconProp} color='#2A85FF' />
                  </Center>
                ) : (
                  <Box boxSize='20px' />
                )}

                <Checkbox
                  size='lg'
                  value={data.eid}
                  isChecked={value?.eid === data.eid}
                  onChange={() => onChange(value ? null : data)}
                  onBlur={onBlur}
                />
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    />
  );
}

export default CommonListItem;

import React, { FC, useState } from 'react';
import { ISupervisedTasksEntity } from '../../tasks.types';
import SupervisedTaskDetailsDataContext from './supervised-task-details-context';

const SupervisedTaskDetailsDataProvider: FC = (props) => {
  const [supervisedTaskDetail, setSupervisedTaskDetail] =
    useState<ISupervisedTasksEntity>({} as ISupervisedTasksEntity);

  const taskSupervisedDetailsHandler = (data: ISupervisedTasksEntity) => {
    setSupervisedTaskDetail(data);
  };

  const supervisedContext = {
    taskDetails: supervisedTaskDetail,
    taskSupervisedDetailsHandler: taskSupervisedDetailsHandler,
  };

  return (
    <SupervisedTaskDetailsDataContext.Provider value={supervisedContext}>
      {props.children}
    </SupervisedTaskDetailsDataContext.Provider>
  );
};

export default SupervisedTaskDetailsDataProvider;

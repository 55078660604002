import React, { FC } from 'react';
import { Divider, Flex } from '@chakra-ui/react';

import { RepeatingSchedule } from './Repeating';
import PublishDate from './PublishDate';
import EndDate from './EndDate';

interface IProps {
  isEdit?: boolean;
}

const MultiTimesContainer: FC<IProps> = ({ isEdit }) => {
  return (
    <Flex flexDir='column' gap={4}>
      <RepeatingSchedule isEdit={isEdit} />

      <Divider opacity={1} borderColor='#E9E9E9' />

      <PublishDate isEdit={isEdit} />

      <Divider opacity={1} borderColor='#E9E9E9' />

      <EndDate />
    </Flex>
  );
};

export default MultiTimesContainer;

import React, { FC } from 'react';

import { useDeleteContext } from './context';

import DeleteFormCategoryForm from './DeleteFormCategoryForm';
import DeleteConfirmContent from './DeleteConfirmContent';
import { MoveFormContainer } from './move-form';
import DeleteFormsLoader from './DeleteFormsLoader';
import FormDeleteSuccess from './FormDeleteSuccess';
import DeleteCategoryContent from './DeleteCategoryContent';
import Exporting from './Exporting';
import ExportFormDeleting from './ExportFormDeleting';

interface IProps {}

const ModalContent: FC<IProps> = () => {
  const { action } = useDeleteContext();

  if (action === 'deleteConfirm') {
    return <DeleteConfirmContent />;
  }

  if (action === 'move') {
    return <MoveFormContainer />;
  }

  if (action === 'deleteForms') {
    return <DeleteFormsLoader />;
  }

  if (action === 'formDeletedSuccess') {
    return <FormDeleteSuccess />;
  }

  if (action === 'deleteCategory') {
    return <DeleteCategoryContent />;
  }

  if (action === 'exportAndDelete') {
    return <Exporting />;
  }

  if (action === 'exportFormDeleting') {
    return <ExportFormDeleting />;
  }

  return <DeleteFormCategoryForm />;
};

export default ModalContent;

import React, { useCallback, useRef } from 'react';

import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';
import { AlertBox } from '../../../../atoms';

interface IProps {
  onYesPress: (...args: any[]) => any | PromiseLike<any>;
  onNoPress: (...args: any[]) => any | PromiseLike<any>;
}

type UseSwitchConfirm = (props: IProps) => void;

export const useSwitchConfirm = (): UseSwitchConfirm => {
  const { t } = useTranslation(['common']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback(({ onYesPress, onNoPress }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      title: <BoxHeader title='Are you sure?' color='#B5E4CA' />,
      content: (
        <AlertBox fontSize='14px' icon={faCircleExclamation}>
          <strong>Switching to board view</strong> will remove the unsaved new
          task
        </AlertBox>
      ),
      isCentered: true,
      contentProps: {
        py: '16px',
        px: '12px',
        minW: '600px',
      },
      okButtonProps: {
        // size: 'lg',
        // colorScheme: 'red',
        fontSize: '15px',
        minWidth: '100px',
      },
      cancelButtonProps: {
        // size: 'lg',
        // colorScheme: 'red',
        fontSize: '15px',
        minWidth: '100px',
      },
      cancelText: t('common:no'),
      onCancel: onNoPress,
      okText: t('common:yes'),
      onOK: onYesPress,
    });
  }, []);
};

import React, { FC } from 'react';
import { Box, Center, Image } from '@chakra-ui/react';
import { ActionButton } from '../../../../ui-components';
import trashImage from '../../../../assets/images/trash-red.svg';

import { useDeleteContext } from './context';

interface IProps {}

const DeleteCategoryContent: FC<IProps> = () => {
  const { category, actionHandler } = useDeleteContext();

  return (
    <Center flex={1} flexDirection='column' gap={4} py={3}>
      <Image
        src={trashImage}
        boxSize='56px'
        p='16px'
        bg='#FF6A5533'
        borderRadius='full'
      />
      <Box
        fontSize='16px'
        textAlign='center'
        maxW='418px'
        color='#33383F'
        mb={1}
      >
        All forms have been deleted. <br />
        Are you sure you want to delete “{category.name}” category?
      </Box>

      <ActionButton
        w='full'
        size='lg'
        fontSize='15px'
        colorScheme='red'
        actionFn={() => actionHandler?.('delete')}
      >
        Delete
      </ActionButton>
    </Center>
  );
};

export default DeleteCategoryContent;

import React, { FC, useState } from 'react';
import { Flex } from '@chakra-ui/react';

import useCombinedStore from 'zustandStore/store';
import { useUserDataSelector } from 'hooks';

import {
  CustomLauncherTask,
  LaunchTaskSteps,
} from 'pages/Home/components/LOInProgress/types';
import { useSubmitForm } from 'sub-components/forms';

import { IModeType } from './BodyComponent';

import UserResponseFromUrlModal from 'pages/forms/FormResponse/FormResponseTable/UserResponseFromUrlModal';
import { IUserResponseFromUrl } from 'pages/forms/FormResponse/FormResponseTable/FormResponseTable';
import {
  useChapterView,
  useTrainingStartWarning,
  useTrainingView,
} from 'ui-components/resource-view';

import StepListItem from './StepListItem';
import { useFormResponseById } from './useFormResponseById';
import { useUncheckConfirm } from './useUncheckConfirm';

interface IProps {
  mode: IModeType;
  selectedTaskData: CustomLauncherTask;
  markStepAsCompleteHandler: (
    stepId: string,
    formResponseId?: string
  ) => Promise<void>;
  markStepAsIncompleteHandler: (stepId: string) => Promise<void>;
}

const StepsList: FC<IProps> = ({
  mode,
  selectedTaskData,
  markStepAsCompleteHandler,
  markStepAsIncompleteHandler,
}) => {
  const {
    taskDetails: { selectedStep, updateSelectedStep },
  } = useCombinedStore();
  const [userResponseFromUrl, setUserResponseFromUrl] =
    useState<IUserResponseFromUrl>({
      userData: undefined,
      showChat: false,
      show: false,
    });
  const [formId, setFormId] = useState('');
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const submitForm = useSubmitForm();
  const chapterView = useChapterView();
  const trainingView = useTrainingView();
  const trainingWarning = useTrainingStartWarning();
  const uncheckConfirm = useUncheckConfirm();

  const responseById = useFormResponseById();

  const onViewFormResponse = async (step: LaunchTaskSteps) => {
    try {
      setFormId(step.formId);
      const response = await responseById({
        responseId: step.formResponseId!,
      });
      setUserResponseFromUrl({
        show: true,
        showChat: false,
        userData: response as never,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  const onCheckboxClickHandler = (step: LaunchTaskSteps, index: number) => {
    // if (
    //   step?.isCompleted ||
    //   selectedStep?.loading ||
    //   mode === 'view'
    // ) {
    //   return;
    // }
    if (selectedStep?.loading) {
      return;
    }
    if (step?.isCompleted) {
      if (step.type === 'form') {
        uncheckConfirm(async () => {
          updateSelectedStep({ loading: true, index: index });
          await markStepAsIncompleteHandler(step?.stepId);
        });
      } else {
        updateSelectedStep({ loading: true, index: index });
        markStepAsIncompleteHandler(step?.stepId);
      }
    } else {
      if (step?.type === 'text') {
        updateSelectedStep({ loading: true, index: index });
        markStepAsCompleteHandler(step?.stepId);
      } else if (step?.type === 'form') {
        submitForm({
          formId: step?.formId,
          title: step?.title,
          canOverride: true,
          onSubmitted: (returnedFormData) => {
            updateSelectedStep({ loading: true, index: index });
            markStepAsCompleteHandler(step?.stepId, returnedFormData?.eid);
          },
        });
      } else if (step?.type === 'sop') {
        chapterView({
          sopId: step?.stepId,
          title: step?.title,
          canOverride: true,
          onReadClick: async () => {
            updateSelectedStep({ loading: true, index: index });
            await markStepAsCompleteHandler(step?.stepId);
          },
        });
      } else if (step?.type === 'training') {
        trainingWarning(() => {
          trainingView({
            trainingId: step?.stepId,
            title: step?.title,
            formAccessOverride: true,
            inputVariable: {
              locationLaunchId: selectedTaskData?.launchId!,
              taskId: selectedTaskData?.eid!,
              stepId: step?.stepId!,
            },
            onFinish: async () => {
              updateSelectedStep({ loading: true, index: index });
              await markStepAsCompleteHandler(step?.stepId);
            },
          });
        }, step);
      }
    }
  };

  return (
    <Flex flexDir='column' w='full'>
      <Flex flexDir='column' align='center' w='full'>
        {selectedTaskData?.steps?.map((step, index) => (
          <StepListItem
            key={step.stepId}
            step={step}
            stepIndex={index}
            authRole={loggedInUserAuthRole}
            isLoading={selectedStep?.loading}
            isSelected={selectedStep?.index === index}
            onClick={() => onCheckboxClickHandler(step, index)}
            onViewFormResponse={onViewFormResponse}
          />
        ))}
      </Flex>
      {userResponseFromUrl?.show && userResponseFromUrl?.userData && (
        <UserResponseFromUrlModal
          userResponseFromUrl={userResponseFromUrl}
          formId={formId}
          setUserResponseFromUrl={setUserResponseFromUrl}
        />
      )}
    </Flex>
  );
};

export default StepsList;

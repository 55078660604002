import React, { FC, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Flex } from '@chakra-ui/react';

import { toArray } from '../../../../../../../utils/utils';
import { usersEntityObj } from '../../../../../../Header';

import { TrainingProgressResponse } from './training-progress.graphql';
import TrainingProgressItem, { ITProgressData } from './TrainingProgressItem';
import { useUserDataSelector } from '../../../../../../../hooks';

interface IProps {
  data: TrainingProgressResponse['trainingById'];
  userId?: string;
}

const ProgressList: FC<IProps> = ({ data, userId }) => {
  const membersData = useReactiveVar(usersEntityObj);

  const authRole = useUserDataSelector((state) => state.authRole);

  const itemList = useMemo((): ITProgressData[] => {
    if (!data) return [];

    const userProgress = data.userProgress.find((it) => it.userId === userId);
    const viewedContents = toArray(userProgress?.viewedContents);
    const userData = membersData?.find((usr) => usr.eid === userId);

    return toArray(data.contentDetails).map((item) => {
      const find = viewedContents.find((value) => value.uid === item.eid);

      return {
        // ...item,
        eid: item?.eid,
        title: item.title,
        isCompleted: !!find?.uid,
        viewedAt: find?.viewedAt,
        lastViewedAt: find?.lastViewedAt,
        type: item.type,
        cardType: item.cardType,
        formId: item.formId?.eid,
        // formResponseId: find?.formResponseId,
        userData: userData,
      };
    });
  }, [data]);

  return (
    <Flex flexDir='column' gap={4}>
      {itemList.map((value, index) => {
        return (
          <TrainingProgressItem
            index={index}
            data={value}
            key={value.eid}
            authRole={authRole}
          />
        );
      })}
    </Flex>
  );
};

export default ProgressList;

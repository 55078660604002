interface Config {
  fontFamily: string;
  fontSize?: string | number;
}

export const signatureConfigs: Config[] = [
  {
    fontFamily: 'SnellRoundhand',
    fontSize: 18,
  },
  {
    fontFamily: 'Zapfino',
    fontSize: 20,
  },
  {
    fontFamily: 'Bradley Hand',
    fontSize: 16,
  },
  {
    fontFamily: 'SignPainter',
    fontSize: 15,
  },
  {
    fontFamily: 'Noteworthy',
    fontSize: 21,
  },
  {
    fontFamily: 'PartyLETPlain',
    fontSize: 20,
  },
];

import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput } from 'atoms';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { faCirclePlus } from '@fortawesome/pro-duotone-svg-icons';
import { HeaderColorValues } from 'shared/headerColors/header-colors';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import {
  faCheck,
  faCircleXmark,
  faSquareCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { getImage } from 'utils';
import Dropdown from 'atoms/Dropdown';
import { useTranslation } from 'react-i18next';
import { chakraComponents } from 'chakra-react-select';
import { AuthRole } from 'authorization';
import { SelectOption } from 'sub-components/CustomDropdowns/CustomSelectComponent/custom-select-component.types';
import { OwnerOption } from 'pages/Locations/Components/LocationOwner';
import EmptyState from 'sub-components/EmptyState';

interface IProps {
  reset?: () => void;
  showSearchField: boolean;
  searchFieldPlaceholder: string;
  options: OwnerOption[];
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  handleOptionClick: (value: OwnerOption) => void;
  selectedValue: OwnerOption;
  showList: boolean;
  showInviteButton: boolean;
  onInviteClick?: () => void;
}

const MenuContainer: FC<IProps> = ({
  showSearchField,
  searchFieldPlaceholder,
  options,
  reset,
  setShowList,
  handleOptionClick,
  selectedValue,
  showList,
  showInviteButton,
  onInviteClick,
}) => {
  const { t } = useTranslation(['handbook', 'header', 'location']);

  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = useCallback(() => {
    if (!searchValue) {
      return options;
    } else {
      let filteredMembers = options?.filter(
        (option) =>
          option?.label
            ?.toLowerCase()
            ?.trim()
            ?.includes(searchValue?.toLowerCase()?.trim()) ||
          option?.role
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase()?.trim()) ||
          option?.locations?.some((loc) =>
            loc?.name
              ?.toLowerCase()
              ?.trim()
              ?.includes(searchValue?.toLowerCase()?.trim())
          )
      );
      return filteredMembers;
    }
  }, [options, searchValue, showList]);

  return (
    <Flex
      pos='absolute'
      maxH='50vh'
      overflowY='scroll'
      bg='white'
      w='full'
      zIndex='999'
      top='45px'
      borderRadius='10px'
      boxShadow='0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)'
      flexDir='column'
      p={4}
    >
      {showSearchField && (
        <Box w='full'>
          <SearchInput
            placeholder={searchFieldPlaceholder}
            hideShortcuts
            value={searchValue}
            onChange={(e) => setSearchValue(e?.target?.value)}
          />
        </Box>
      )}
      {filteredOptions()?.length > 0 && (
        <List overflowY='scroll'>
          {filteredOptions()?.map((option) => (
            <ListItem
              key={option?.value}
              cursor='pointer'
              _hover={{ bg: '#DEEBFF', borderRadius: '8px' }}
              onClick={() => handleOptionClick(option)}
            >
              <Flex flexDir='column' my={2}>
                <Flex justify='space-between' align='center' p={2}>
                  <Flex gap='10px' align='center'>
                    <Image
                      src={getImage(option?.profilePic, option?.label)}
                      borderRadius='50%'
                      boxSize='40px'
                    />
                    <Flex flexDir='column'>
                      <Text m={0} p={0}>
                        {option?.label}
                      </Text>
                      <Flex
                        gap='5px'
                        mt='-5px'
                        flexDir='column'
                        fontWeight={500}
                        fontSize='12px'
                        color='#92929D'
                      >
                        <Text m={0} p={0}>
                          {option?.role}
                        </Text>
                        <Text m={0} p={0} mt='-12px'>
                          {option?.locations?.[0]?.name}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  {selectedValue?.eid === option?.eid && (
                    <FontAwesomeIcon
                      icon={faCheck as IconProp}
                      color='#83BF6E'
                      size='lg'
                    />
                  )}
                </Flex>
                <Divider />
              </Flex>
            </ListItem>
          ))}
        </List>
      )}
      {filteredOptions()?.length === 0 && (
        <EmptyState image='Search' title={t('header:result_not_found')} />
      )}
      {showInviteButton && (
        <Button
          w='full'
          size='lg'
          variant='ghost'
          colorScheme='blue'
          fontSize='15px'
          p='8px 12px'
          height='unset'
          onClick={onInviteClick}
        >
          <Flex w='full' align='center' gap='8px'>
            <FontAwesomeIcon
              icon={faCirclePlus as IconProp}
              fontSize='40px'
              style={
                {
                  '--fa-primary-color': '#6f767e',
                  '--fa-secondary-color': '#efefef',
                } as never
              }
            />
            <Box>{t('location:inviteNewOwner')}</Box>
          </Flex>
        </Button>
      )}
    </Flex>
  );
};

export default MenuContainer;

import {
  Divider,
  Flex,
  HStack,
  Spinner,
  Switch,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { GlobalChatSetting, ISelection } from '../../GlobalChat';
import React, { FC, useEffect } from 'react';
import CreateNewChannels from './components/CreateNewChannels';
import StartDirectMessages from './components/StartDirectMessages';
import LocationsCanChat from './components/LocationsCanChat';
import { useMutation } from '@apollo/client';
import { UPDATE_GLOBAL_CHAT_CONFIG } from './services/access.graphql';
import { useUserDataSelector } from 'hooks';
import { getLoggedInUserDataHandler } from 'shared/graphql/SharedGraphql';

type UpdateConfigType =
  | 'restrictChat'
  | 'locationChat'
  | 'newChannel'
  | 'directMessage';

export type RestrictType =
  | 'role'
  | 'authRole'
  | 'anyone'
  | 'creator'
  | undefined;

interface UpdateGlobalChatConfigVariables {
  locationChat?: boolean;
  newChannel?: {
    authRoles?: string[];
    members?: string[];
    restrictType?: RestrictType;
    roles?: string[];
  };
  restrictions?: boolean;
  startDM?: {
    authRoles?: string[];
    members?: string[];
    restrictType?: RestrictType;
    roles?: string[];
  };
}

interface UpdateGlobalChatConfigResponse {
  updateEntityChatConfig: {
    eid: string;
  };
}

interface IProps {
  globalChatSetting: GlobalChatSetting;
  setGlobalChatSetting: React.Dispatch<React.SetStateAction<GlobalChatSetting>>;
}

const Access: FC<IProps> = ({ globalChatSetting, setGlobalChatSetting }) => {
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
    isClosable: true,
  });

  const chatConfigData = useUserDataSelector(
    (state) => state?.entity?.chatConfig
  );

  const onSuccess = () => {
    setGlobalChatSetting({
      ...globalChatSetting,
      restrictChat: {
        ...globalChatSetting.restrictChat,
        loading: false,
      },
      locationsCanChat: {
        ...globalChatSetting.locationsCanChat,
        loading: false,
      },
      whoCreateChannels: {
        ...globalChatSetting.whoCreateChannels,
        loading: false,
      },
      whoDirectMessages: {
        ...globalChatSetting.whoDirectMessages,
        loading: false,
      },
    });
  };

  const { execute } = getLoggedInUserDataHandler(onSuccess);

  const [updateGlobalChatConfig] = useMutation<
    UpdateGlobalChatConfigResponse,
    { input: UpdateGlobalChatConfigVariables }
  >(UPDATE_GLOBAL_CHAT_CONFIG);

  useEffect(() => {
    setGlobalChatSetting({
      locationsCanChat: {
        loading: false,
        selection: chatConfigData?.locationChat,
      },
      restrictChat: {
        loading: false,
        selection: chatConfigData?.restrictions,
      },
      whoCreateChannels: {
        expanded: false,
        loading: false,
        selectedOptions: {
          jobs:
            chatConfigData?.newChannel?.roles?.length > 0
              ? chatConfigData?.newChannel?.roles
              : undefined,
          members:
            chatConfigData?.newChannel?.members?.length > 0
              ? chatConfigData?.newChannel?.members
              : undefined,
          roles:
            chatConfigData?.newChannel?.authRoles?.length > 0
              ? chatConfigData?.newChannel?.authRoles
              : undefined,
        },
        selection: chatConfigData?.newChannel?.restrictType,
        shouldSpecificMembers: chatConfigData?.newChannel?.members?.length > 0,
      },
      whoDirectMessages: {
        expanded: false,
        loading: false,
        selectedOptions: {
          jobs:
            chatConfigData?.startDM?.roles?.length > 0
              ? chatConfigData?.startDM?.roles
              : undefined,
          members:
            chatConfigData?.startDM?.members?.length > 0
              ? chatConfigData?.startDM?.members
              : undefined,
          roles:
            chatConfigData?.startDM?.authRoles?.length > 0
              ? chatConfigData?.startDM?.authRoles
              : undefined,
        },
        selection: chatConfigData?.startDM?.restrictType,
        shouldSpecificMembers: chatConfigData?.startDM?.members?.length > 0,
      },
    });
  }, [chatConfigData]);

  const restrictChatHandler = async (value: boolean) => {
    try {
      let res = await updateGlobalChatConfig({
        variables: {
          input: {
            restrictions: value,
          },
        },
      });
      if (res?.data?.updateEntityChatConfig?.eid) {
        toast({
          status: 'success',
          title: 'Global chat restriction updated successfully',
          description: `Chat will be visible to ${
            value ? 'Admin team' : 'everyone in the organization'
          }`,
        });
        execute();
      }
    } catch (e) {
      toast({
        status: 'error',
        title: 'Global chat restriction could not be updated',
      });
    }
  };

  const locationCanChatHandler = async (value: boolean) => {
    try {
      let res = await updateGlobalChatConfig({
        variables: {
          input: {
            locationChat: value,
          },
        },
      });
      if (res?.data?.updateEntityChatConfig?.eid) {
        toast({
          status: 'success',
          title: 'Locations chat check updated successfully',
          description: `${
            value
              ? 'Location Owners and workers can initiate DMs and initiate channels'
              : 'Location Owners and workers cannot initiate DMs and initiate channels'
          }`,
        });
        execute();
      }
    } catch (e) {
      toast({
        status: 'error',
        title: 'Locations chat check could not be updated',
      });
    }
  };

  const saveNewChannelPreferences = async () => {
    try {
      setGlobalChatSetting({
        ...globalChatSetting,
        whoCreateChannels: {
          ...globalChatSetting.whoCreateChannels,
          loading: true,
        },
      });
      let res = await updateGlobalChatConfig({
        variables: {
          input: {
            newChannel: {
              restrictType: globalChatSetting?.whoCreateChannels?.selection,
              authRoles:
                globalChatSetting?.whoCreateChannels?.selectedOptions?.roles,
              members:
                globalChatSetting?.whoCreateChannels?.selectedOptions?.members,
              roles:
                globalChatSetting?.whoCreateChannels?.selectedOptions?.jobs,
            },
          },
        },
      });
      if (res?.data?.updateEntityChatConfig?.eid) {
        toast({
          status: 'success',
          title: 'New Channel preferences updated successfully',
        });
        execute();
      }
    } catch (e) {
      toast({
        status: 'error',
        title: 'New Channel preferences could not be updated',
      });
    }
  };

  const saveDirectMessagesPreferences = async () => {
    try {
      setGlobalChatSetting({
        ...globalChatSetting,
        whoDirectMessages: {
          ...globalChatSetting.whoDirectMessages,
          loading: true,
        },
      });
      let res = await updateGlobalChatConfig({
        variables: {
          input: {
            startDM: {
              restrictType: globalChatSetting?.whoDirectMessages?.selection,
              authRoles:
                globalChatSetting?.whoDirectMessages?.selectedOptions?.roles,
              members:
                globalChatSetting?.whoDirectMessages?.selectedOptions?.members,
              roles:
                globalChatSetting?.whoDirectMessages?.selectedOptions?.jobs,
            },
          },
        },
      });
      if (res?.data?.updateEntityChatConfig?.eid) {
        toast({
          status: 'success',
          title: 'Direct Messages preferences updated successfully',
        });
        execute();
      }
    } catch (e) {
      toast({
        status: 'error',
        title: 'Direct Messages preferences could not be updated',
      });
    }
  };

  return (
    <VStack w='full'>
      <HStack
        justify='space-between'
        w='full'
        // cursor='pointer'
        // onClick={() => {
        //   setGlobalChatSetting({
        //     ...globalChatSetting,
        //     restrictChat: !globalChatSetting?.restrictChat,
        //   });
        //   restrictChatHandler(!globalChatSetting?.restrictChat);
        // }}
      >
        <Flex flexDir='column' marginBottom={'18px'}>
          <Text fontWeight={600} color='rgba(111, 118, 126, 1)'>
            ACCESS
          </Text>
          <Text fontWeight={500} fontSize='16px' color='rgba(26, 29, 31, 1)'>
            Restrict chat
          </Text>
          <Text color='rgba(111, 118, 126, 1)'>
            Chat will only be visible to Admin team
          </Text>
        </Flex>
        {globalChatSetting?.restrictChat?.loading ? (
          <Spinner thickness='2px' size='sm' />
        ) : (
          <Switch
            size='sm'
            isChecked={globalChatSetting?.restrictChat?.selection}
            onChange={(e) => {
              setGlobalChatSetting({
                ...globalChatSetting,
                restrictChat: {
                  loading: true,
                  selection: e?.target?.checked,
                },
              });
              restrictChatHandler(e?.target?.checked);
            }}
          />
        )}
      </HStack>
      {!globalChatSetting?.restrictChat?.selection && (
        <VStack divider={<Divider />} align='flex-start' w='full' gap={4}>
          <CreateNewChannels
            globalChatSetting={globalChatSetting}
            savePreferences={saveNewChannelPreferences}
            setGlobalChatSetting={setGlobalChatSetting}
          />
          <StartDirectMessages
            globalChatSetting={globalChatSetting}
            savePreferences={saveDirectMessagesPreferences}
            setGlobalChatSetting={setGlobalChatSetting}
          />
          {/* <LocationsCanChat
            globalChatSetting={globalChatSetting}
            locationCanChatHandler={locationCanChatHandler}
            setGlobalChatSetting={setGlobalChatSetting}
          /> */}
        </VStack>
      )}
    </VStack>
  );
};

export default Access;

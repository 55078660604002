import React, { useCallback, useRef } from 'react';
import { useTheme } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { UseConfirm, useConfirm } from '../../../../../ui-components/Confirm';

import Header from './Header';
import TaskDetailsContainer from './TaskDetailsContainer';
import { useTaskFormResponse } from '../form-response/useTaskFormResponse';

interface IProps {
  taskId: string;
  userId: string;
  sessionId?: string;
}

type TaskDetailsView = (props: IProps) => void;

export const useTaskDetailsView = (): TaskDetailsView => {
  const confirm = useConfirm();
  const history = useHistory();
  const apolloClient = useApolloClient();
  const drawerConfig = useTheme().components?.Drawer;

  const viewFormResponse = useTaskFormResponse();

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(
    ({ taskId, userId, sessionId }) => {
      const onViewClick = () => {
        if (sessionId) {
          // history.push(`/tasks/my-tasks/details/${taskId}`);
          history.push(`/tasks/supervised/details/${taskId}/${sessionId}`);
        }
        confirmRef.current?.destroy();
      };

      const onResponseView = (type: string, eid: string) => {
        switch (type) {
          case 'form':
            return viewFormResponse({
              userId: userId,
              formId: eid,
            });
        }
      };

      if (confirmRef.current) {
        confirmRef.current?.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        styleConfig: drawerConfig,
        title: <Header onViewDetail={onViewClick} />,
        content: (
          <TaskDetailsContainer
            taskId={taskId}
            userId={userId}
            onClick={onResponseView}
          />
        ),
        zIndex: 1799,
        bodyProps: {
          display: 'flex',
          overflow: 'hidden',
        },
        contentProps: {
          maxWidth: '600px',
          padding: '8px 16px',
          containerProps: {
            justifyContent: 'flex-end',
          },
        },
        footer: null,
      });
    },
    [drawerConfig]
  );
};

import { useCallback, useEffect } from 'react';
import { useLazyQuery, ApolloError } from '@apollo/client';
import debounce from 'lodash.debounce';
import { useToast } from '@chakra-ui/react';
import { CHECK_USERNAME_EXISTS } from 'sub-components/BulkLocationsUpload/bulk-locations.graphql';
import { useFormContext, useWatch } from 'react-hook-form';
import { IFormInput } from '../AddLocation/add-location.types';
import { useTranslation } from 'react-i18next';

interface CheckUsernameExistsData {
  CheckUsernameExists: {
    found: string[];
  };
}

interface CheckUsernameExistsVars {
  usernames: string[];
}

const useValidateUsername = (): boolean => {
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const { t } = useTranslation(['common', 'auth', 'location']);
  const { control, clearErrors, setError } = useFormContext<IFormInput>();
  const username = useWatch<IFormInput, 'username'>({
    control,
    name: 'username',
  });

  const [validateUserName, { loading: validatingUserName }] = useLazyQuery<
    CheckUsernameExistsData,
    CheckUsernameExistsVars
  >(CHECK_USERNAME_EXISTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data?.CheckUsernameExists?.found?.length) {
        clearErrors('username');
      } else {
        setError(
          'username',
          {
            type: 'custom',
            message: 'Username already exists',
          },
          {
            shouldFocus: true,
          }
        );
      }
    },
    onError: (error: ApolloError) => {
      if (error.message.includes('Username')) {
        setError(
          'username',
          {
            type: 'custom',
            message: error.message,
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        toast({
          status: 'error',
          title: t('common:error'),
          description: t('location:location_error'),
        });
      }
    },
  });

  const debouncedValidateUsername = useCallback(
    debounce((usernameToValidate: string) => {
      if (!usernameToValidate) {
        clearErrors('username');
        return;
      }

      validateUserName({
        variables: {
          usernames: [usernameToValidate],
        },
      });
    }, 500),
    [validateUserName, clearErrors, t, toast]
  );

  useEffect(() => {
    debouncedValidateUsername(username);

    return () => {
      debouncedValidateUsername.cancel();
    };
  }, [username, debouncedValidateUsername]);

  return validatingUserName;
};

export default useValidateUsername;

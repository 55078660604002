import React, { FC, useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { AssignedToUsersEntity } from '../training.types';
import { Wrapper } from './styles/training-footer.styles';
import Image from '../../../ui-components/Image/Image';
import { getImage, truncateString } from '../../../utils';

interface IProps {
  createdBy: string;
  superVisors: Array<AssignedToUsersEntity>;
  onSuperVisorClick?: () => void;
}

const TrainingPathFooter: FC<IProps> = ({
  createdBy,
  superVisors = [],
  onSuperVisorClick,
}) => {
  const { t } = useTranslation('training');
  const [data, moreSupervisor] = useMemo(() => {
    const more = superVisors.length - 4;
    return [superVisors?.slice(0, 4) || [], more];
  }, [superVisors]);

  if (!createdBy) {
    return null;
  }

  return (
    <Wrapper>
      <div className='created-by'>
        <Trans
          t={t}
          i18nKey='training_details_section.created_by_user'
          components={{ boldName: <Text as='b' /> }}
          values={{ name: `${truncateString(createdBy, 30)}` }}
        />
        {/* {t('training_details_section.created_by_user', { name: truncateString(createdBy, 30) })} */}
        {/* Created by <span className='name'>{truncateString(createdBy, 30)}</span> */}
        {data?.length > 0 && (
          <div className='supervised-div'>
            <div className='ellipse' />
            {t('training_details_section.supervised_by')}
            <div className='avatar-list'>
              {data.map((user) => {
                return (
                  <Image
                    width={20}
                    height={20}
                    src={getImage(user.profilePic, user.name)}
                    key={user.eid}
                    onClick={onSuperVisorClick}
                  />
                );
              })}
            </div>
            {moreSupervisor > 0 && (
              <div className='more-button' onClick={onSuperVisorClick}>
                <Trans
                  t={t}
                  i18nKey='count_more'
                  values={{
                    value: moreSupervisor,
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default TrainingPathFooter;

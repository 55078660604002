import { CardType } from 'types';
import imageIcon from '../../../assets/images/handbook/image.svg';
import videoIcon from '../../../assets/images/handbook/video.svg';
import mixedIcon from '../../../assets/images/handbook/mixed.svg';

export const HandbookImage = {
  [CardType.Text]: null,
  [CardType.Image]: imageIcon,
  [CardType.Action]: null,
  [CardType.Quiz]: null,
  [CardType.Video]: videoIcon,
  [CardType.Form]: null,
  [CardType.CustomForm]: null,
  [CardType.Mixed]: mixedIcon,
};

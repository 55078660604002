import React, { FC, useCallback, useRef, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

import {
  UserDetailsComponent,
  UserDetailsRef,
} from '../../../sub-components/UserLocationDetails';
import { useSubmitForm } from '../../../sub-components/forms';

import {
  ChapterContent,
  ChapterFileView,
  ChapterFooter,
} from '../../../pages/Chapters/ChapterView';
import ChapterActionFooter from './ChapterActionFooter';
import ChapterViewHeader from './ChapterViewHeader';

const Wrapper = styled.div`
  position: relative;
  min-height: 50vh;
`;

interface IProps {
  data: any;
  title?: string;
  onReadClick: () => Promise<void>;
}

const ChapterViewContainer: FC<IProps> = ({ data, onReadClick }) => {
  const useDetailRef = useRef<UserDetailsRef>(null);
  const [searchQuery] = useState('');

  const submitForm = useSubmitForm();

  const sopUser = data?.lastUpdatedByUser || data?.createdByUser;

  const onItemClick = useCallback((event: MouseEvent, node: any) => {
    switch (node?.type?.name) {
      case 'member':
        useDetailRef.current?.openDetail('user', node.attrs);
        break;
      case 'location':
        useDetailRef.current?.openDetail('branch', node.attrs);
        break;
      case 'form':
        submitForm({
          formId: node.attrs?.eid,
          title: node.attrs?.label,
        });
        break;
      case 'chapter':
        window.open(`/chapters/view/${node.attrs?.eid}`, '_blank');
        break;
    }
  }, []);

  return (
    <Flex flexDir='column'>
      <Wrapper>
        <ChapterViewHeader title={data?.title} />

        {data?.sopType !== 'text' ? (
          <ChapterFileView sopData={data} />
        ) : (
          <ChapterContent
            data={data}
            searchQuery={searchQuery}
            onItemClick={onItemClick}
            // onScroll={onScrollHandler}
          />
        )}
        <ChapterFooter name={sopUser?.name} lastUpdated={data?.lastUpdated} />
      </Wrapper>
      <ChapterActionFooter onReadClick={onReadClick} />
      <UserDetailsComponent ref={useDetailRef} />
    </Flex>
  );
};

export default ChapterViewContainer;

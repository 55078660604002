import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex, useTheme } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { UseConfirm, useConfirm } from '../../../../../ui-components/Confirm';

import PreviewHeader from '../../../../forms/component/PreviewHeader';
import { LocationOwnerEntity } from '../perfor-detail.graphql';
import ModalContent from './ModalContent';

interface IProps {
  ownerList: LocationOwnerEntity[];
  openChat?: (userId: string) => void;
}

type ILocationOwnerList = (props: IProps) => void;

export const useLocationOwnerList = (): ILocationOwnerList => {
  const { t } = useTranslation(['task']);
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const drawerConfig = useTheme().components?.Drawer;

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(
    ({ ownerList, openChat }) => {
      const onMessageClick = (userId: string) => {
        openChat?.(userId);
        // confirmRef.current?.destroy();
      };

      if (confirmRef.current) {
        confirmRef.current?.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        styleConfig: drawerConfig,
        title: (
          <Flex align='center' gap='12px' justify='space-between'>
            <PreviewHeader
              color='#CABDFF'
              fontSize='18px'
              title={t('task:locationOwner')}
            />

            <AlertDialogCloseButton
              ml='4px'
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
              bg='gray.100'
            />
          </Flex>
        ),
        content: (
          <ModalContent locations={ownerList} onMessageClick={onMessageClick} />
        ),
        zIndex: 1799,
        bodyProps: {
          display: 'flex',
          overflow: 'hidden',
        },
        contentProps: {
          maxWidth: '420px',
          padding: '8px 16px',
          containerProps: {
            justifyContent: 'flex-end',
          },
        },
        footer: null,
      });
    },
    [drawerConfig]
  );
};

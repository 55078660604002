import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';

import { UseConfirm, useConfirm } from 'ui-components';

import { LocationProvider } from './Context';

import MemberMoveTo, { MemberMoveToRef } from './MemberMoveToComponent';
import { MoveToLocationFooter, MoveToLocationHeader } from './MoveToLocation';
import { ISelectedFilter } from 'sub-components/CustomDropdowns/AuthRoleFilter/AuthRoleFilter';

interface IProps {
  locationId: string;
  locationName: string;
  onBackPress: () => void;
  onDeletePress?: (...args: any[]) => any | PromiseLike<any>;
  onMoveSuccess?: () => void;
}

type IMoveMemberTo = (props: IProps) => void;

export const useMoveMemberTo = (): IMoveMemberTo => {
  const inputRef = useRef<MemberMoveToRef>(null);
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const apolloClient = useApolloClient();

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      inputRef.current?.onChange(event.target.value);
    },
    []
  );

  const onFilterChange = (option: ISelectedFilter) => {
    inputRef.current?.onFilterChange(option);
  };

  return useCallback(
    ({
      locationId,
      locationName,
      onBackPress,
      onDeletePress,
      onMoveSuccess,
    }) => {
      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>
              <LocationProvider
                locationId={locationId}
                onMoveSuccess={onMoveSuccess}
              >
                {children}
              </LocationProvider>
            </ApolloProvider>
          );
        },
        title: (
          <MoveToLocationHeader
            onBackClick={() => {
              confirmRef.current?.destroy?.();
              setTimeout(onBackPress, 100);
            }}
            onClose={() => confirmRef.current?.destroy?.()}
            onChange={handleSearchChange}
            onFilterChange={(option: ISelectedFilter) => onFilterChange(option)}
          />
        ),
        content: (
          <MemberMoveTo
            ref={inputRef}
            locationId={locationId}
            locationName={locationName}
            onDeletePress={onDeletePress}
            close={() => confirmRef.current?.destroy?.()}
          />
        ),
        isCentered: true,
        // scrollBehavior: 'inside',
        contentProps: {
          paddingTop: '12px',
          paddingBottom: '12px',
          minW: '600px',
        },
        footer: <MoveToLocationFooter />,
      });
    },
    [handleSearchChange, onFilterChange]
  );
};

import { ChangeEvent, FC, useEffect, useState } from 'react';
import { userObj } from 'sop-commons/src/client/clientFactory';
import axios from 'axios';
import { CometChat } from '@cometchat-pro/chat';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
// import css
import './Profile.scss';
import { Spin } from 'antd';
import ChangePasswordModal from '../ChangePasswordModal';
import FormInput from '../../atoms/FormInput';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import PrimaryButton from '../../atoms/PrimaryButton';
import { ADMIN, MANAGER, SUPER_ADMIN, WORKER } from 'utils/userRoles';
import Dropdown from 'atoms/Dropdown';
import { roleObj } from 'ui-components/DashboardMenu';
import { loginUserBranchObj } from 'sub-components/Header';
import { EMAIL_REGEX } from '../../utils/constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { GET_USER } from '../../pages/Login/login.graphql';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import { getAvatar } from '../../utils';
import { useTranslation } from 'react-i18next';
import { Authorize, AuthRole } from '../../authorization';

const UPDATE_USER_BY_ID = gql`
  mutation UpdateUserById($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      email
      name
      username
      phone
      profilePic
      authRole
      role
      eid
      locations {
        locations {
          name
          eid
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($input: UserUpdateInput) {
    updateUser(input: $input) {
      name
      phone
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation GetSignedUrlMutation($getSignedUrlInput: [FileDetailsInput]) {
    getSignedUrl(input: $getSignedUrlInput) {
      signedRequestUrl
      url
      mimetype
      s3_filename
      filename
    }
  }
`;

interface IFormInput {
  name: string;
  phone: string;
  email: string;
}

const Profile: FC = () => {
  const { t } = useTranslation(['common', 'setting', 'role']);
  const toast = useToast({
    position: 'top-right',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [profileUploadLoading, setProfileUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [name, setName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [enable, setEnable] = useState(true);
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState<any>('');
  const [selectedBranch, setSelectedBranch] = useState<any>('');
  const roleColorByName = useReactiveVar<any>(roleObj);
  let branchList = useReactiveVar(loginUserBranchObj);

  const userObject = useReactiveVar(userObj);
  const [getSignedUrl] = useMutation(GET_SIGNED_URL);

  const [getUser] = useLazyQuery(GET_USER, {
    onCompleted: (data: any) => {
      setIsLoading(false);
      userObj(data?.user || {});
    },
    fetchPolicy: 'network-only',
  });

  const [updateProfile] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      setIsLoading(false);
      toast({
        status: 'success',
        title: t('setting:profileUpdateSuccess'),
      });
      getUser();
    },
    onError: (error) => {
      setIsLoading(false);
      setError(error);
      toast({
        status: 'error',
        title: error?.message,
      });
    },
  });

  const [updateUser] = useMutation(UPDATE_USER_BY_ID, {
    onCompleted: () => {
      setIsLoading(false);
      toast({
        status: 'success',
        title: t('setting:profileUpdateSuccess'),
      });
      getUser();
    },
    refetchQueries: [
      {
        query: GET_USER,
      },
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      setIsLoading(false);
      setError(error);
      toast({
        status: 'error',
        title: error?.message,
      });
    },
  });

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
  } = useForm<IFormInput>();

  useEffect(() => {
    if (roleColorByName) {
      let roleData: any = [];
      Object.keys(roleColorByName)?.forEach((key) => {
        roleData.push(key);
      });
      const role = {
        label: roleData[0],
        value: roleData[0],
      };
      setRoleList(roleData);
    }
    if (branchList?.length) {
      const branch: any = {
        label: branchList[0]?.name,
        value: branchList[0]?.eid,
      };
      setSelectedBranch(branch);
    }
  }, [roleColorByName, branchList]);

  useEffect(() => {
    if (userObject?.name?.trim()) {
      setValue('name', userObject?.name?.trim(), { shouldDirty: false });
      setValue('phone', userObject?.phone?.trim(), { shouldDirty: false });
      setValue('email', userObject?.email?.trim(), { shouldDirty: false });
    }
    if (userObject?.locations?.length) {
      setSelectedBranch({
        label: userObject?.locations?.[0]?.name,
        value: userObject.locations?.[0]?.eid,
      });
    }
  }, [userObject]);

  useEffect(() => {
    const subscription = watch(() => {
      setError(null);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onOpenModal = () => {
    deployEvent(AmplitudeEventNames.SETTINGS_EDIT_PROFILE_UPDATE_PASSWORD);
    setIsModalOpened(true);
  };

  const onCloseModal = () => {
    setIsModalOpened(false);
  };

  useEffect(() => {
    if (userObject?.name?.trim()) {
      setName(userObject?.name?.trim());
    }

    if (userObject?.phone?.trim()) {
      setMobileNumber(userObject?.phone?.trim());
    }
    if (userObject?.profilePic) {
      setImageUrl(userObject?.profilePic);
    }
    if (userObject?.role) {
      setSelectedRole({
        label: userObject?.role,
        value: userObject?.role,
      });
    }
  }, [userObject?.eid]);

  const updateCometUser = (data: { name?: string; imageUrl?: string }) => {
    const user = new CometChat.User(userObject?.eid);

    if (data?.name) {
      user.setName(data.name);
    }
    if (data?.imageUrl) {
      user.setAvatar(encodeURI(data.imageUrl));
    }
    CometChat.updateUser(
      user,
      process.env.REACT_APP_COMET_CHAT_AUTH_KEY as string
    ).then(
      (user) => {
        console.log('user updated', user);
      },
      (error) => {
        console.log('error', error);
      }
    );
  };

  const onUpdateUser = (values: IFormInput) => {
    setError(null);
    if (values?.name?.trim()) {
      setIsLoading(true);
      deployEvent(AmplitudeEventNames.SETTINGS_EDIT_PROFILE_SAVE);
      if ([ADMIN, SUPER_ADMIN].includes(userObject.authRole)) {
        updateUser({
          variables: {
            input: {
              eid: userObject?.eid,
              name: values?.name,
              phone: values?.phone,
              email: values?.email,
              profilePic: imageUrl,
              role: selectedRole?.value,
              location: selectedBranch?.value,
            },
          },
        });
      } else {
        updateProfile({
          variables: {
            input: {
              name: values?.name,
              phone: values?.phone,
              email: values?.email,
              profilePic: imageUrl,
              role: selectedRole?.value,
            },
          },
        });
      }
      setName(values.name);
      setMobileNumber(values.phone);

      updateCometUser({
        name: values.name,
        imageUrl: imageUrl,
      });
    }
  };

  const imageCallback = async (files: FileList | null) => {
    if (files && files.length) {
      setProfileUploadLoading(true);
      let file = files[0];
      const { name: filename, type } = file;
      const response = await getSignedUrl({
        variables: {
          getSignedUrlInput: [{ filename: filename, mimetype: type }],
        },
      });
      if (response) {
        if (
          response &&
          response.data &&
          response.data.getSignedUrl &&
          response.data.getSignedUrl.length
        ) {
          const data = response.data.getSignedUrl[0];
          const signedRequestUrl = data.signedRequestUrl;
          const imageUrl = data.url;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event: any) => {
            let binaryString = event?.target!.result;
            let binary = atob((binaryString as string)!.split(',')[1]);
            let array = [];
            for (var j = 0; j < binary.length; j++) {
              array.push(binary.charCodeAt(j));
            }
            let blobData = new Blob([new Uint8Array(array)], {
              type: file?.type,
            });

            axios
              .put(signedRequestUrl, blobData)
              .then(() => {
                setProfileUploadLoading(false);
                setImageUrl(imageUrl);
                if (name.trim()) {
                  updateCometUser({
                    imageUrl: imageUrl,
                  });
                  updateProfile({
                    variables: {
                      input: {
                        profilePic: imageUrl,
                      },
                    },
                  });
                }
              })
              .catch(function (error: any) {
                console.error(error);
              });
          };
        }
      }
    }
  };

  const fileChange = (event: ChangeEvent<HTMLInputElement>) => {
    return imageCallback(event.target.files);
  };

  const imageLoadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

  const getRightIcon = () => {
    if (errors?.name || error?.message) {
      return (
        <FontAwesomeIcon
          icon={faTriangleExclamation as IconProp}
          color={'#ff6a55'}
          style={{ cursor: 'pointer' }}
        />
      );
    }
  };
  const handleChange = (e: any) => {
    setEnable(false);
  };

  const getRoleList = () => {
    const list: any = [];
    roleList.map((item) => {
      list.push({
        label: item,
        value: item,
      });
    });
    return list;
  };

  const getBranchList = () => {
    const list: any = [];
    branchList.map((item: any) => {
      list.push({
        label: item?.name,
        value: item?.eid,
      });
    });
    return list;
  };

  return (
    <div>
      <div className='profile-container'>
        <div className='eventclass-header-profile-pic profile-pic-upload-container'>
          <Flex style={{ flexDirection: 'column' }}>
            {!profileUploadLoading && (
              <div
                className='image-container'
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                  position: 'relative',
                }}
              >
                <img
                  src={getAvatar({
                    name: 'auth-user-profile',
                    url: imageUrl,
                  })}
                  className=' profile-square-pic'
                />
                <label htmlFor='file'>
                  <div className='profile-edit-button-container'>
                    <FontAwesomeIcon
                      // style={{ fontSize: 16 }}
                      icon={faPen as IconProp}
                      color='#AAAAAA'
                    />
                  </div>
                </label>
                <input
                  type='file'
                  onChange={fileChange}
                  id='file'
                  style={{ display: 'none' }}
                  accept='image/*'
                />
              </div>
            )}
          </Flex>
          {profileUploadLoading && (
            <div>
              <Spin
                className='eventclass-loader'
                indicator={imageLoadingIcon}
              />
            </div>
          )}
        </div>
        <div className='profile-content-container'>
          <form
            className='profile-form-container'
            onSubmit={handleSubmit(onUpdateUser)}
          >
            <div className='profile-content-title' style={{ marginTop: '0px' }}>
              {t('setting:edit_profile_section.full_name')}
            </div>

            <FormControl isInvalid={!!errors?.name} onChange={handleChange}>
              <FormInput
                id='name'
                size='lg'
                type='text'
                placeholder={t(
                  'setting:edit_profile_section.full_name_placeholder'
                )}
                {...register('name', {
                  required: t('common:validation.name_required'),
                  onBlur: () =>
                    deployEvent(
                      AmplitudeEventNames.SETTINGS_EDIT_PROFILE_FULL_NAME
                    ),
                })}
                error={errors?.name || error?.message}
                rightIcon={getRightIcon()}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: '1 1 auto', marginRight: '7px' }}>
                <div className='profile-content-title'>
                  {t('setting:edit_profile_section.access')}
                </div>
                <Flex>
                  <FormInput
                    size='lg'
                    type='text'
                    value={userObject?.authRole?.trim()}
                    isDisabled
                  />
                </Flex>
              </div>
              <div style={{ flex: '1 1 auto', marginLeft: '7px' }}>
                <div className='profile-content-title'>
                  {t('setting:edit_profile_section.role')}
                </div>
                <Flex>
                  <Authorize
                    permittedRoles={[AuthRole.MANAGER, AuthRole.WORKER]}
                  >
                    <FormInput
                      size='lg'
                      type='text'
                      value={userObject?.role?.trim()}
                      isDisabled
                    />
                  </Authorize>

                  <Authorize
                    permittedRoles={[AuthRole.ADMIN, AuthRole.SUPER_ADMIN]}
                  >
                    <div style={{ width: '100%' }}>
                      <Dropdown
                        options={getRoleList()}
                        value={selectedRole}
                        onChange={(value) => {
                          deployEvent(
                            AmplitudeEventNames.SETTINGS_EDIT_PROFILE_ROLE
                          );
                          handleChange(value);
                          setSelectedRole(value);
                        }}
                        placeholder={t('role:select_role')}
                        size='lg'
                        className='member-role-dropdown-container'
                        inputStyle={{
                          border: 'none',
                          borderColor: 'transparent',
                          borderRadius: '12px',
                          fontSize: '15px',
                          outline: 'none',
                          width: '100%',
                        }}
                      />
                    </div>
                  </Authorize>
                </Flex>
              </div>
            </div>

            {userObject?.email?.trim() && (
              <div>
                <div className='profile-content-title'>
                  {t('setting:edit_profile_section.email')}
                </div>
                <FormControl
                  isInvalid={!!errors?.email}
                  onChange={handleChange}
                  isReadOnly
                  isDisabled
                >
                  <FormInput
                    size='lg'
                    // type='email'
                    // value={userObject?.email?.trim()}
                    {...register('email', {
                      required: t('validation.email_required'),
                      pattern: {
                        value: EMAIL_REGEX,
                        message: t('validation.email_invalid'),
                      },
                      onBlur: () =>
                        deployEvent(
                          AmplitudeEventNames.SETTINGS_EDIT_PROFILE_EMAIL
                        ),
                    })}
                    // isDisabled
                    error={errors?.email || error?.email?.message}
                  />
                  <FormErrorMessage>
                    {errors.email && errors.email.message}
                  </FormErrorMessage>
                </FormControl>
              </div>
            )}

            <div className='profile-content-title'>
              {t('setting:edit_profile_section.phone_number')}
            </div>
            <FormControl onChange={handleChange}>
              <FormInput
                id='phone'
                size='lg'
                type='tel'
                placeholder='+1 876 976 556'
                {...register('phone', {
                  onBlur: () =>
                    deployEvent(
                      AmplitudeEventNames.SETTINGS_EDIT_PROFILE_PHONE_NUMBER
                    ),
                })}
                // value={mobileNumber}
                // onChange={(e: any) => {
                //   setMobileNumber(e?.target?.value);
                // }}
              />
            </FormControl>

            {(userObject?.authRole === MANAGER ||
              userObject?.authRole === WORKER) &&
              userObject?.locations?.[0]?.name && (
                <>
                  <div className='profile-content-title'>
                    {t('setting:edit_profile_section.location')}
                  </div>
                  <Flex>
                    <FormInput
                      size='lg'
                      type='text'
                      value={userObject?.locations?.[0]?.name}
                      isDisabled={
                        userObject?.authRole === MANAGER ||
                        userObject?.authRole === WORKER
                      }
                    />
                  </Flex>
                </>
              )}
            {(userObject?.authRole === ADMIN ||
              userObject?.authRole === SUPER_ADMIN) && (
              <>
                <div className='profile-content-title'>
                  {t('setting:edit_profile_section.location')}
                </div>
                <div style={{ width: '100%' }}>
                  <Dropdown
                    options={getBranchList()}
                    value={selectedBranch}
                    onChange={(value) => {
                      deployEvent(
                        AmplitudeEventNames.SETTINGS_EDIT_PROFILE_LOCATION
                      );
                      handleChange(value);
                      setSelectedBranch(value);
                    }}
                    size='lg'
                    placeholder={t('location')}
                    className='member-role-dropdown-container'
                    inputStyle={{
                      border: 'none',
                      borderColor: 'transparent',
                      borderRadius: '12px',
                      fontSize: '15px',
                      outline: 'none',
                      width: '100%',
                    }}
                  />
                </div>
              </>
            )}

            {userObject?.username?.trim() && (
              <div>
                <div className='profile-content-title'>
                  {t('setting:edit_profile_section.username')}
                </div>
                <FormInput
                  size='lg'
                  type='text'
                  value={userObject?.username?.trim()}
                  isDisabled
                />
              </div>
            )}

            <div className='profile-buttons-container'>
              <PrimaryButton
                type='submit'
                size='lg'
                isLoading={isLoading}
                disabled={enable}
                title={t('save')}
                style={{ maxWidth: '159px' }}
                variant='solid'
                colorScheme='blue'
                className='profile-button-save-container'
              />
              <PrimaryButton
                type='button'
                size='lg'
                title={t('setting:edit_profile_section.update_password_button')}
                onClick={onOpenModal}
                style={{
                  padding: '0px 37px',
                  marginRight: 20,
                  maxWidth: '196px',
                }}
                variant='outline'
                className='profile-button-update-container'
              />
            </div>
          </form>
        </div>
      </div>
      <ChangePasswordModal open={isModalOpened} onClose={onCloseModal} />
    </div>
  );
};

export default Profile;

import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import ProgressItemBar from './ProgressItemBar';

import { CardType } from '../../../../types';
import Image from '../../../../ui-components/Image';

import boltSolid from '../../../../assets/images/bolt-solid.svg';
import flagSolid from '../../../../assets/images/flag-solid.svg';

import actionIcon from '../../../../assets/images/actionIcon.svg';
import formPowerUp from '../../../../assets/images/formPowerUp.svg';
import quizIcon from '../../../../assets/images/quizSvg.svg';
import videoIcon from '../../../../assets/images/powerup-menu/videoMenu.svg';

const TypeJson = {
  sop: 'Chapter',
  card: 'Card',
  [CardType.Action]: 'Action',
  [CardType.Quiz]: 'Quiz',
  [CardType.Video]: 'Video',
  [CardType.CustomForm]: 'Form',
};

const PowerUpData = {
  [CardType.Action]: actionIcon,
  [CardType.Quiz]: quizIcon,
  [CardType.Video]: videoIcon,
  [CardType.CustomForm]: formPowerUp,
};

interface IPowerUP {
  cardType?: string;
}

const PowerUpIcon: FC<IPowerUP> = ({ cardType }) => {
  const src = PowerUpData[cardType as never];
  if (src) {
    return <Image src={src} width={14} height={12} />;
  }
  return null;
};

interface IProps {
  index: number;
  isLastItem: boolean;
  nextCompleted?: boolean;
  userName?: string;
  profilePic?: string;
  data: {
    title: string;
    type: string;
    isCompleted: boolean;
    cardType?: string;
    currentlySelected: boolean;
  };
}

const ProgressItem: FC<IProps> = ({
  data,
  index,
  isLastItem,
  nextCompleted,
  profilePic,
  userName,
}) => {
  switch (data.type) {
    case 'milestone':
      return (
        <Flex align='center'>
          <ProgressItemBar
            isCompleted={data.isCompleted}
            nextCompleted={nextCompleted}
            currentlyActive={data.currentlySelected}
            index={index}
            isLastItem={isLastItem}
            milestoneColor={data.isCompleted ? '#E9F7EF' : '#EFEFEF'}
            icon={flagSolid}
            profilePic={profilePic}
            userName={userName}
          />
          <Flex
            flex={1}
            align='center'
            fontWeight='600'
            my='12px'
            height='60px'
            pl='16px'
            bg={data.isCompleted ? '#E9F7EF' : '#EFEFEF'}
          >
            <Box
              color={data.isCompleted ? '#83BF6E' : '#1A1D1F'}
              fontSize='15px'
            >
              {data.title}
            </Box>
          </Flex>
        </Flex>
      );
    default:
      return (
        <Flex gap='16px' align='center'>
          <ProgressItemBar
            isCompleted={data.isCompleted}
            nextCompleted={nextCompleted}
            currentlyActive={data.currentlySelected}
            index={index}
            isLastItem={isLastItem}
            isPowerUp={data.type !== 'sop'}
            icon={data.type !== 'sop' ? boltSolid : undefined}
            profilePic={profilePic}
            userName={userName}
          />
          <Box flex={1} fontWeight='600' my='12px'>
            <Flex color='#6F767E' fontSize='12px' align='center' gap='6px'>
              <PowerUpIcon cardType={data.cardType} />
              {/*// @ts-ignore */}
              {TypeJson[data.cardType || data.type]}
            </Flex>
            <Box color='#1A1D1F' fontSize='15px'>
              {data.title}
            </Box>
          </Box>
        </Flex>
      );
  }
};

export default ProgressItem;

import React, { FC } from 'react';
import {
  Box,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { FormInput } from '../../../../atoms';
import { ActionButton } from '../../../../ui-components';
import { useUserEntity } from '../../../../hooks';

import { IFormInput } from './create-channel.types';

import chatGroupIcon from '../../../../assets/images/chat-group.svg';

interface IProps {
  onNextClick: (values: IFormInput) => void;
}

const NewChannelForm: FC<IProps> = ({ onNextClick }) => {
  const { t } = useTranslation(['common', 'setting']);
  const { control, handleSubmit, watch } = useFormContext<IFormInput>();

  const entityName = useUserEntity((entity) => entity.name);

  const groupName = watch('groupName');
  const isPublic = watch('isPublic');

  return (
    <div>
      <Flex gap={2}>
        <Center borderRadius='8px' minW='48px' boxSize='48px' bg='#EEEEEE'>
          <Image src={chatGroupIcon} />
        </Center>

        <Controller
          control={control}
          defaultValue=''
          name='groupName'
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={!!fieldState.error}>
                <FormInput
                  size='lg'
                  placeholder={t('setting:placeholder.channelName')}
                  {...field}
                />
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Flex>

      <Controller
        control={control}
        defaultValue={false}
        name='isPublic'
        render={({ field }) => {
          return (
            <Checkbox
              mt={4}
              color='#6F767E'
              isChecked={field.value}
              onChange={field.onChange}
              ref={field.ref}
            >
              <Box fontSize='14px'>
                <Trans
                  t={t}
                  i18nKey='setting:addEveryoneInOrgToChannel'
                  values={{
                    name: entityName,
                  }}
                />
              </Box>
            </Checkbox>
          );
        }}
      />

      <Box pt={4} pb={2}>
        <ActionButton
          width='full'
          fontSize='15px'
          colorScheme='blue'
          isDisabled={!groupName}
          actionFn={handleSubmit(onNextClick)}
          // actionFn={onSubmit}
        >
          {isPublic ? 'Next (1/2)' : 'Next 1/3'}
        </ActionButton>
      </Box>
    </div>
  );
};

export default NewChannelForm;

import { gql } from '@apollo/client';
import { ShareMemberEntity } from 'ui-components/ShareToMember';

export const SEND_FORM_QUERY = gql`
  mutation SendFormToUser(
    $senderId: String!
    $message: String!
    $receiverIds: [String]
  ) {
    sendDirectMessage(
      senderId: $senderId
      message: $message
      receiverIds: $receiverIds
    ) {
      succeed
    }
  }
`;

export interface FormToUserVariable {
  senderId: string;
  receiverIds: string[];
  message: string;
}

export interface FormToUserResponse {
  sendDirectMessage: {
    succeed: boolean;
  };
}

export interface MemberEntity extends ShareMemberEntity {
  // checked?: boolean; // TODO custom data
}

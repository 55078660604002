import { CSSProperties, FC } from 'react';
import { Flex } from '@chakra-ui/react';

interface IProps {
  icon: any;
  name: string;
  description?: string;
  value: string;
  isSelected?: boolean;
  onSelect: (value: string) => void;
  style?: CSSProperties;
  isDisabled?: boolean;
}

const AuthorizationItem: FC<IProps> = ({
  icon,
  name,
  description,
  value,
  isSelected,
  onSelect,
  style,
  isDisabled,
}) => {
  const Icon = icon;

  let className = 'authorization-level-item-container';
  if (isSelected) {
    className = `${className} selected`;
  }
  if (isDisabled) {
    className = `${className} disabled`;
  }

  return (
    <Flex
      onClick={() => (isDisabled ? undefined : onSelect?.(value))}
      className={className}
      style={style}
    >
      <Icon />
      <div className='authorization-level-item-content'>
        <div className='authorization-level-item-title'>{name}</div>
        <div className='authorization-level-item-desc'>{description}</div>
      </div>
    </Flex>
  );
};

export default AuthorizationItem;

// @ts-ignore
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
// import css
import './Handbook.scss';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Flex, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import DashboardContainer from '../../sub-components/DashboardContainer';
import { handbookMeta as handbookMetaQuery } from 'sop-commons/Query/Sop';
import { userObj } from 'sop-commons/src/client/clientFactory';
import AddNewPathModal from '../../sub-components/AddNewPathModal/index';
import ListPagination from '../../atoms/ListPagination';
import CardEditorModal from '../../sub-components/CardEditorModal';
import CardPreview from '../../sub-components/CardPreview';
import Loader from '../../sub-components/Loader';
import {
  GET_HANDBOOK_QUERY,
  HandbookResponse,
  HandbookVariable,
  RemoveDeckQuery,
  RemoveDeckResponse,
} from './handbook.graphql';
import AddDeck from '../../ui-components/AddDeck';
import HandbookCard from './HandbookCard';
import { deployEvent } from '../../shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from '../../shared/amplitudeEvents/amplitude-events-types';
import { AuthRole, useAuthorization } from '../../authorization';
import HandbookHeader from './HandbookHeader';
import { HandbookListView } from './HandbookList';

// @ts-ignore
import DarkEditIcon from '../../assets/images/dark_edit.png';
// @ts-ignore
import TrashIcon from '../../assets/images/trash-red.svg';
import { generateDeleteMessage, useCardDeckDelete } from '../../hooks';
import {
  RemoveCardQuery,
  RemoveCardResponse,
} from '../../sub-components/CardEditor/card-editor.graphql';
import EmptyHandbook from './EmptyHandbook';
import { IActionMenuData } from '../../ui-components/TableActionMenu';
import { CardModal, DeckModal, HandbookModal } from '../../modal';
import { SelectOption } from '../../atoms/Dropdown';
import PrimaryButton from '../../atoms/PrimaryButton';

const HandbookChild: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [cardSelected, setCardSelected] = useState<
    DeckModal | CardModal | null
  >(null);
  const [selectedSortValue, setSelectedSortValue] = useState<
    SelectOption | undefined
  >();
  const authRole = useReactiveVar(userObj).authRole;
  const [isCardModalOpened, setIsCardModalOpened] = useState(false);
  const [editCardId, setEditCardId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeckOnly, setIsDeckOnly] = useState(false);
  const [pathModalData, setPathModalData] = useState<DeckModal | CardModal>();
  const category = useRouteMatch<{ category?: string }>().params?.category;
  const [openDeck, setOpenDeck] = useState(false);
  const [viewType, setViewType] = useState<'list' | 'grid'>('list');
  const authorization = useAuthorization({});
  const cardDeckDelete = useCardDeckDelete();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });

  const [
    getHandbookData,
    { data: deckApiData, refetch: refetchCards, loading },
  ] = useLazyQuery<HandbookResponse, HandbookVariable>(GET_HANDBOOK_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [handBookMetaData, { data: metaData, refetch: refetchMetaData }] =
    useLazyQuery(handbookMetaQuery, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    setPage(1);
  }, [category, isDeckOnly]);

  const [deleteDeck] = useMutation<RemoveDeckResponse>(RemoveDeckQuery, {
    onCompleted: (data) => {
      if (data?.removeDeck?.succeed) {
        refetchCards();
        refetchMetaData();
        toast({
          status: 'success',
          title: t('success'),
          description: t('handbook:deck_success_removed'),
        });
      }
    },
  });

  const [deleteCard] = useMutation<RemoveCardResponse>(RemoveCardQuery, {
    onCompleted: (data) => {
      if (data?.removeCard?.succeed) {
        refetchCards();
        refetchMetaData();
        toast({
          status: 'success',
          title: t('success'),
          description: t('handbook:card_success_removed'),
        });
      }
    },
  });

  const getHandbookDataHandler = () => {
    let filter: Record<string, string> = {};

    if (searchQuery) {
      filter.query = searchQuery;
    }
    if (isDeckOnly) {
      filter.type = 'deck';
    }
    let sortValue = '';
    if (selectedSortValue?.value) {
      if (selectedSortValue.value === 'Last Updated') {
        sortValue = 'UPDATEDAT_DESC';
      } else if (selectedSortValue.value === 'Last Created') {
        sortValue = 'CREATEDAT_DESC';
      } else if (selectedSortValue.value === 'Alphabetically') {
        sortValue = 'TITLE_ASC';
      } else if (selectedSortValue.value === 'Active') {
        filter.status = 'active';
        sortValue = '_ID_DESC';
      } else if (selectedSortValue.value === 'Inactive') {
        filter.status = 'inactive';
        sortValue = '_ID_DESC';
      }
    } else {
      sortValue = '_ID_DESC';
    }

    if (
      ![AuthRole.ADMIN, AuthRole.SUPER_ADMIN].includes(authRole as AuthRole)
    ) {
      filter.status = 'active';
    }

    getHandbookData({
      variables: {
        page: page,
        filter: {
          category: category,
          handbookView: true,
          ...filter,
        },
        sort: sortValue,
      },
    });
  };

  useEffect(() => {
    getHandbookDataHandler();
    handBookMetaData({});
  }, [searchQuery, page, category, isDeckOnly, authRole]);

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchQuery(e?.target?.value);
  };

  useEffect(() => {
    if (refetchCards) {
      refetchCards();
    }
    if (refetchMetaData) {
      refetchMetaData();
    }
  }, []);

  const onCloseSupervisorEditModal = () => {
    setPathModalData(undefined);
  };

  const onAddCardClick = () => {
    deployEvent(AmplitudeEventNames.HANDBOOK_ADD_NEW_CARD_BUTTON);
    openCardModal(false);
  };

  const onAddDeckClick = () => {
    if (category) {
      return history.push(`/${category}/deck/create`);
    }
    setOpenDeck(true);
  };

  const deckCloseHandler = () => {
    setOpenDeck(false);
  };

  const itemsList = useMemo(() => {
    return new HandbookModal(deckApiData?.handbookPagination?.items).data;
  }, [deckApiData]);

  const pageInfo = deckApiData?.handbookPagination?.pageInfo;
  const handbookMeta = metaData?.handbookMeta || {};

  const deckData = useMemo(() => {
    if (!itemsList) {
      return [];
    }
    if (searchQuery) {
      const reg = new RegExp(searchQuery, 'gi');
      return itemsList.filter((value) => {
        return value.title?.match(reg);
      });
    }
    return itemsList;
  }, [itemsList, searchQuery]);

  const openCardModal = (isEditValue?: boolean) => {
    setIsCardModalOpened(true);
    setIsEdit(isEditValue);
    if (!isEditValue) {
      setCardSelected(null);
    }
  };

  const closeCardModal = () => {
    setIsCardModalOpened(false);
    setEditCardId(null);
  };

  const onCardSuccess = (selectedCard) => {
    setEditCardId(null);
    if (selectedCard && isEdit) {
      setCardSelected((prevState) => {
        if (prevState === selectedCard) {
          return selectedCard;
        }
        return prevState;
      });
    }
    if (refetchCards) {
      refetchCards();
      // closeCardModal();
    }
    if (refetchMetaData) {
      refetchMetaData();
    }
  };

  const onCardDelete = () => {
    setIsCardModalOpened(false);
    setEditCardId(null);
    if (refetchCards) {
      refetchCards();
      // closeCardModal();
    }
    if (refetchMetaData) {
      refetchMetaData();
    }
  };

  const onCardDeleteFromPrev = () => {
    setCardSelected(null);
    if (refetchCards) {
      refetchCards();
      // closeCardModal();
    }
    if (refetchMetaData) {
      refetchMetaData();
    }
  };

  const onDeletePress = (item: DeckModal | CardModal) => {
    if (!item?.eid) {
      return;
    }

    cardDeckDelete({
      title: item.title,
      message: generateDeleteMessage(item.type as any, item.trainings, t),
      onDeletePress: () => {
        switch (item.type) {
          case 'card':
            return deleteCard({
              variables: {
                eid: item.eid,
              },
            });
          case 'deck':
            return deleteDeck({
              variables: {
                eid: item.eid,
              },
            });
        }
      },
    });
  };

  const clickedItemHandler = (
    menuClicked: string,
    item: DeckModal | CardModal
  ) => {
    if (menuClicked === 'add_to_path') {
      setPathModalData(item);
    } else if (menuClicked === 'edit') {
      if (item.type === 'card') {
        setEditCardId(item.eid);
        setCardSelected((prevState) => {
          if (prevState?.eid === item.eid) {
            return prevState;
          }
          return null;
        });
        setIsEdit(true);
        setIsCardModalOpened(true);
      } else if (item.type === 'deck') {
        history.push(
          `/${item.category}/deck/edit/${item.eid}?from=${
            category || 'Handbook'
          }`
        );
      }
    } else if (menuClicked === 'delete') {
      onDeletePress(item);
    }
  };

  const [totalCardCount, totalDeckCount] = useMemo(() => {
    if (isDeckOnly) {
      const sum = deckData.reduce((accumulator, object) => {
        // @ts-ignore
        return accumulator + object?.cards?.length || 0;
      }, 0);
      return [sum, handbookMeta[category]?.deck];
    }

    return [handbookMeta[category]?.card, handbookMeta[category]?.deck];
  }, [isDeckOnly, category, handbookMeta, deckData]);

  const onItemClick = useCallback(
    (item: DeckModal | CardModal) => {
      if (item?.type === 'card') {
        setCardSelected(item);
      } else if (item?.type === 'deck') {
        history.push({
          pathname: (category ? `/${category}` : '') + `/deck/${item?.eid}`,
          state: {
            deckId: item.eid,
            title: item.title,
            category: category,
          },
        });
      }
    },
    [category]
  );

  useEffect(() => {
    getHandbookDataHandler();
  }, [selectedSortValue]);

  const MenuData = useMemo(() => {
    return [
      // {
      //   name: 'Add to path',
      //   value: 'add_to_path',
      //   icon: AddIconImg,
      // },
      {
        name: t('edit'),
        value: 'edit',
        icon: DarkEditIcon,
      },
      authorization(
        {
          permittedRoles: [AuthRole.SUPER_ADMIN],
        },
        {
          name: t('delete'),
          value: 'delete',
          icon: TrashIcon,
          textColor: '#FF6A55',
        },
        null
      ),
    ].filter((value) => !!value);
  }, [authorization]);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <div className='handbook-title-wrapper'>
        <div className='handbook-title-container'>{t('handbook')}</div>
        <PrimaryButton
          title='Go to new Knowledge base'
          width='fit-content'
          variant='solid'
          colorScheme='blue'
          onClick={() => history.push('/folders')}
        />
      </div>
      <div className='handbook-container'>
        <HandbookHeader
          totalDeck={totalDeckCount}
          totalCard={totalCardCount}
          isDeckOnly={isDeckOnly}
          onShowOnlyChange={setIsDeckOnly}
          onSearch={handleQueryChange}
          searchQuery={searchQuery}
          onCardCreate={onAddCardClick}
          onDeckCreate={onAddDeckClick}
          sortValue={selectedSortValue}
          onSortChange={setSelectedSortValue}
          viewType={viewType}
          onViewChange={setViewType}
        />
        {viewType === 'list' ? (
          <HandbookListView
            handBooks={deckData}
            isLoading={loading}
            onActionClick={clickedItemHandler}
            onItemClick={onItemClick}
            menuData={MenuData as IActionMenuData[]}
            onAddCardClick={onAddCardClick}
            onAddDeckClick={onAddDeckClick}
          />
        ) : (
          <div
            className={
              deckData?.length > 4
                ? 'handbook-card-list'
                : 'handbook-card-list-flex'
            }
          >
            {loading && (
              <div className='sop-list-loader'>
                <Loader size='xl' style={undefined} />
              </div>
            )}
            {!loading && deckData?.length === 0 && (
              <Flex justify='center' pt='18px' w='full'>
                <EmptyHandbook
                  onAddCardClick={onAddCardClick}
                  onAddDeckClick={onAddDeckClick}
                />
              </Flex>
            )}
            {!loading &&
              deckData?.map((value) => (
                <HandbookCard
                  item={value}
                  key={value.eid}
                  selectedCard={cardSelected?.eid}
                  onItemClick={onItemClick}
                  onActionClick={clickedItemHandler}
                  menuData={MenuData as IActionMenuData[]}
                />
              ))}
          </div>
        )}

        {((pageInfo?.currentPage === 1 && pageInfo?.hasNextPage) ||
          pageInfo?.currentPage > 1) && (
          <ListPagination
            onPageChange={(page) => setPage(page)}
            data={itemsList}
            totalRegisters={deckApiData?.handbookPagination?.count}
            page={page}
          />
        )}
      </div>
      {cardSelected && (
        <CardPreview
          cardSelected={cardSelected}
          onCloseClick={() => setCardSelected(null)}
          onSubmit={onCardSuccess}
          onCardDelete={onCardDeleteFromPrev}
        />
      )}
      {isCardModalOpened && (
        <CardEditorModal
          open={isCardModalOpened}
          onClose={closeCardModal}
          onSubmit={onCardSuccess}
          onCardDelete={onCardDelete}
          isEdit={isEdit}
          cardId={cardSelected?.eid || editCardId}
        />
      )}

      {pathModalData?.eid && (
        <AddNewPathModal
          isOpen={!!pathModalData?.eid}
          onClose={onCloseSupervisorEditModal}
          selectedBlockType={pathModalData.type}
          selectedBlockTitle={pathModalData.title}
          selectedBlockEid={pathModalData.eid}
        />
      )}
      <AddDeck open={openDeck} onClose={deckCloseHandler} from={'Handbook'} />
    </div>
  );
};

const Handbook: FC = () => {
  return (
    <DashboardContainer>
      <HandbookChild />
    </DashboardContainer>
  );
};

export default Handbook;

import React, { FC, useMemo } from 'react';
import { Flex, Tooltip as ChakraToolTip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsMaximize } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { BoxHeader } from '../../../../ui-components';
import {
  getCompleteRate,
  UserAnalytics,
} from 'sub-components/tasks/performance';
import RoleBarGraph from './RoleBarGraph';

interface Response {
  TaskSupervisedRoleAnalytics: Record<string, UserAnalytics>;
}

const TASK_ANALYTICS = gql`
  query TaskRoleAnalytics {
    TaskSupervisedRoleAnalytics
  }
`;

interface IProps {
  onFullViewClick?: () => void;
}

const JobPerformanceBarGraph: FC<IProps> = ({ onFullViewClick }) => {
  const { t } = useTranslation(['task']);

  const { data, loading } = useQuery<Response>(TASK_ANALYTICS, {
    fetchPolicy: 'network-only',
  });

  const graphData = useMemo(() => {
    const roleAnalytics = data?.TaskSupervisedRoleAnalytics;

    return Object.entries(roleAnalytics || {}).map(([roleName, value]) => {
      return {
        name: roleName,
        completeRate: getCompleteRate(value),
      };
    });
  }, [data]);

  return (
    <Flex
      flex={1}
      flexDir='column'
      borderRadius='10px'
      padding='16px 20px'
      bg='white'
      gap='24px'
    >
      <Flex justify='space-between' gap='10px'>
        <BoxHeader
          title={t('task:jobPerformance')}
          fontSize='18px'
          color='#B5E4CA'
        />

        {onFullViewClick && (
          <Flex gap={2} align='center' pos='relative'>
            <ChakraToolTip
              label={t('task:fullView')}
              shouldWrapChildren
              hasArrow
              padding='6px 10px'
              borderRadius='4px'
            >
              <FontAwesomeIcon
                icon={faArrowsMaximize as IconProp}
                cursor='pointer'
                fontSize='20px'
                color='#504F54'
                onClick={onFullViewClick}
              />
            </ChakraToolTip>
          </Flex>
        )}
      </Flex>

      <RoleBarGraph isLoading={loading} height={400} data={graphData} />
    </Flex>
  );
};

export default JobPerformanceBarGraph;

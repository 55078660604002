import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import {
  FieldErrors,
  FormProvider,
  useForm,
  UseFormHandleSubmit,
} from 'react-hook-form';

import { AuthRole, userObj } from 'sop-commons/src/client';

import { SelectOption } from 'atoms';
import { toArray } from 'utils/utils';

import {
  getLocations,
  getMembers,
  LocationResponse,
  MembersResponse,
} from '../../../../pages/Training/TrainingList/Component/MemberFilter/member-filter.graphql';
import { FormVisibility, IVisibilityInput } from './visibility.types';
import { formatVisibilityData } from './formatVisibilityData';

interface VisibilityCtxProps {
  fetchingData?: boolean;

  allOptions: SelectOption[];
  dataObject: Record<string, SelectOption[]>;
  memberList: SelectOption[];
  resetPrivateValues: () => void;

  submitForm: (values: IVisibilityInput) => PromiseLike<void>;
  handleSubmit: UseFormHandleSubmit<IVisibilityInput>;

  /**
   *
   * @deprecated pls use handleSubmit and submitForm
   *
   * @example
   *
   * handleSubmit(submitForm)
   *
   * */
  onSubmitClick: (...args: any[]) => any | PromiseLike<any>;
}

export const VisibilityContext = createContext<VisibilityCtxProps>(
  {} as VisibilityCtxProps
);

const sortLocation = <T extends SelectOption>(valA: T, valB: T) => {
  if (valA.label < valB.label) {
    return -1;
  } else if (valA.label > valB.label) {
    return 1;
  } else {
    return 0;
  }
};

interface IProps {
  onApplyClick?: (values: IVisibilityInput) => PromiseLike<void>;
  initialValue?: Partial<FormVisibility>;
}

export const VisibilityProvider: FC<IProps> = ({
  children,
  initialValue,
  onApplyClick,
}) => {
  const { t, i18n } = useTranslation(['common', 'role']);
  const [queriesCompleted, setQueriesCompleted] = useState({
    users: false,
    branch: false,
  });

  const userObject = useReactiveVar(userObj);

  const methods = useForm<IVisibilityInput>({
    defaultValues: {
      visibility:
        userObject?.authRole === AuthRole.LOCATION_OWNER ? 'private' : 'public',
      members: [],
      filterValue: [],
      otherFilterValue: [],
    },
  });

  const allOptions = useMemo((): SelectOption[] => {
    return [
      { label: t('common:location'), value: 'location' },
      { label: t('role:job'), value: 'job' },
    ];
  }, [i18n.language]);

  // const { data, loading: fetchingData } = useQuery<LocationResponse>(
  //   getLocations,
  //   {
  //     fetchPolicy: 'network-only',
  //     onCompleted: (response) => {
  //       if (initialValue) {
  //         const values = formatVisibilityData(
  //           initialValue,
  //           response.branch,
  //           userObject?.entity?.roles,
  //           t
  //         );

  //         methods.reset(values);
  //       } else if (userObject?.authRole === AuthRole.LOCATION_OWNER) {
  //         methods.setValue(
  //           'filterBy',
  //           allOptions.find((op) => op.value === 'location') || null
  //         );
  //         methods.setValue(
  //           'filterValue',
  //           toArray(userObject?.locations).map((loc) => ({
  //             label: loc.name,
  //             value: loc.eid,
  //           }))
  //         );
  //       }
  //     },
  //   }
  // );

  // const { data: membersData, loading: fetchingMembersData } =
  //   useQuery<MembersResponse>(getMembers, {
  //     fetchPolicy: 'network-only',
  //   });

  const [fetchLocations, { data: locationsData, loading: fetchingData }] =
    useLazyQuery<LocationResponse>(getLocations, {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        console.log({ FETCH_LOCATIONS: true });
        setQueriesCompleted((prev) => ({ ...prev, branch: true }));
      },
    });

  const [fetchMembers, { data: membersData, loading: fetchingMembersData }] =
    useLazyQuery<MembersResponse>(getMembers, {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        console.log({ FETCH_MEMBERS: true });
        setQueriesCompleted((prev) => ({ ...prev, users: true }));
      },
    });

  useEffect(() => {
    fetchLocations();
    fetchMembers();
  }, []);

  useEffect(() => {
    if (
      queriesCompleted.users &&
      queriesCompleted.branch &&
      locationsData &&
      membersData
    ) {
      console.log({ LD__MD: true, locationsData, membersData });
      if (initialValue) {
        const values = formatVisibilityData(
          initialValue,
          locationsData?.branch,
          userObject?.entity?.roles,
          t,
          membersData
        );

        methods.reset(values);
      } else if (userObject?.authRole === AuthRole.LOCATION_OWNER) {
        methods.setValue(
          'filterBy',
          allOptions.find((op) => op.value === 'location') || null
        );
        methods.setValue(
          'filterValue',
          toArray(userObject?.locations).map((loc) => ({
            label: loc.name,
            value: loc.eid,
          }))
        );
      }
    }
  }, [queriesCompleted]);

  const locationList = useMemo(() => {
    return toArray(locationsData?.branch?.descendantBranches)
      .map((it) => {
        return {
          label: it.name,
          value: it.eid,
        };
      })
      .sort(sortLocation);
  }, [locationsData?.branch?.descendantBranches]);

  const memberList = useMemo(() => {
    return toArray(membersData?.userDescendants)
      .reduce((acc, user) => {
        if (
          ['active', 'inactive'].includes(user.status) &&
          user.eid !== userObject?.eid
        ) {
          acc.push({
            label: user.name,
            value: user.eid,
            role: user?.role,
            authRole: user?.authRole,
            locations: user?.locations,
            profilePic: user?.profilePic,
          });
        }
        return acc;
      }, [] as SelectOption[])
      .sort(sortLocation);
  }, [membersData?.userDescendants, userObject?.eid]);

  const roleList = useMemo(() => {
    return toArray(userObject?.entity?.roles).map((it) => {
      return {
        label: it.name,
        value: it.name,
      };
    });
  }, [userObject?.entity?.roles]);

  const dataObject = useMemo(() => {
    return {
      job: roleList,
      location: locationList,
    };
  }, [roleList, locationList]);

  const resetPrivateValues = () => {
    methods.reset({
      visibility: methods.getValues('visibility'),
    });
  };

  const onSubmit = async (values: IVisibilityInput) => {
    await onApplyClick?.(values);
  };

  const onError = (errors: FieldErrors<IVisibilityInput>) => {
    // eslint-disable-next-line no-console
    console.log(errors);
    throw errors;
  };

  return (
    <VisibilityContext.Provider
      value={{
        dataObject,
        memberList,
        allOptions,

        fetchingData: fetchingData && fetchingMembersData,

        resetPrivateValues: resetPrivateValues,

        handleSubmit: methods.handleSubmit,
        submitForm: onSubmit,
        // onSubmitClick: onSubmitClick,
        onSubmitClick: methods.handleSubmit(onSubmit, onError),
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
      </FormProvider>
    </VisibilityContext.Provider>
  );
};

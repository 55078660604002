import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Button,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import './UpdateDeckAddCards.scss';
import SearchInput from 'atoms/SearchInput';
import dateFormat from 'dateformat';
import previewIcon from '../../../assets/images/preview.png';
import closeIcon from '../../../assets/images/closeIcon.png';
import PrimaryButton from 'atoms/PrimaryButton';
// @ts-ignore
import { getCardsData } from 'sop-commons/Query/Sop';
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client';
import Dropdown, { SelectOption } from '../../../atoms/Dropdown';
import { userObj } from 'sop-commons/src/client/clientFactory';

import { ReactComponent as CardNumber } from '../../../assets/images/cardNumber.svg';
import { CardType } from '../../../types';
import Image from '../../../ui-components/Image/Image';
import {
  AddDeckCardModal,
  UpdateDeckCardModal,
  UpdateDeckModal,
} from './addDeckCard.modal';

const GET_HANDBOOK_QUERY = gql`
  query HandbookPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyhandbookInput
    $sort: SortFindManyhandbookInput
  ) {
    handbookPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        cardType
        title
        thumbnail
        backgroundColor
        layout
        subTitle
        createdBy
        entityId
        content
        status
        views
        createdAt
        updatedAt
        type
        eid
        cards {
          cardType
          title
          backgroundColor
          layout
          subTitle
          thumbnail
          category
          createdBy
          entityId
          content
          translations
          status
          views
          updatedAt
          eid
          translations
        }
        category
        form {
          eid
          title
          thumbnail
        }
        translations
      }
    }
  }
`;

type CardData = any;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCards: Array<CardData>;
  cardsToBeAdded?: (
    cards: Array<UpdateDeckCardModal | UpdateDeckModal>
  ) => void;
  previewHandler?: (data: UpdateDeckCardModal | UpdateDeckModal) => void;
  title?: string;
  searchPlaceholder?: string;
  buttonText?: string;
  showCategory?: boolean;
  includeDecks?: boolean;
}

const getValue = (item: CardData, key: keyof CardData) => {
  if (item.cardType === CardType.CustomForm) {
    // @ts-ignore
    return item?.form?.[key];
  }
  return item?.[key];
};

const UpdateDeckAddCards: FC<IProps> = ({
  isOpen,
  onClose,
  selectedCards,
  cardsToBeAdded,
  previewHandler,
  title,
  searchPlaceholder,
  buttonText,
  showCategory,
  includeDecks,
}) => {
  const { t } = useTranslation(['common', 'deck', 'category']);
  const [categoryList, setCategoryList] = useState<SelectOption[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<SelectOption>();

  const [cardsList, setCardsList] = useState<
    (UpdateDeckCardModal | UpdateDeckModal)[]
  >([]);
  const [getAllCards] = useLazyQuery(
    includeDecks ? GET_HANDBOOK_QUERY : getCardsData,
    {
      fetchPolicy: 'network-only',
      onCompleted: (response) => {
        const itemsList = includeDecks
          ? response.handbookPagination.items
          : response.cardsPagination.items;

        let tempCardsData: CardData[] = [];
        let uniqueCardsData = [];
        if (itemsList.length > 0) {
          if (selectedCards.length > 0) {
            let tempData: Array<CardData> = JSON.parse(
              JSON.stringify(itemsList)
            );
            uniqueCardsData = tempData.filter((object1) => {
              return !selectedCards.some((object2) => {
                return object1.eid === object2.eid;
              });
            });
            tempCardsData = JSON.parse(JSON.stringify(uniqueCardsData));
          } else {
            tempCardsData = JSON.parse(JSON.stringify(itemsList));
          }
          tempCardsData.forEach((data) => (data.added = false));
        }
        setCardsList(new AddDeckCardModal(tempCardsData).data);
      },
    }
  );
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);

  const userObject = useReactiveVar(userObj);

  useEffect(() => {
    if (userObject?.entity?.category?.length) {
      let tempArr: SelectOption[] = [{ value: '', label: 'Select Category' }];
      userObject?.entity?.category.map((category) => {
        tempArr.push({
          value: category.name,
          label: category.name,
        });
      });
      setCategoryList(tempArr);
    }
  }, [userObject]);

  useEffect(() => {
    let filter: Record<string, string | number | boolean> = {
      status: 'active',
    };
    if (query) {
      filter.query = query;
    }

    if (selectedCategory?.value) {
      filter.category = selectedCategory?.value;
    }

    getAllCards({
      variables: {
        page: page,
        sort: '_ID_DESC',
        filter: { ...filter },
      },
    });
  }, [query, page, selectedCategory]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    const value = e.target.value;
    setQuery(value);
  };

  const handleChange = (card: UpdateDeckCardModal | UpdateDeckModal) => {
    setCardsList((prevState) => {
      prevState.forEach((list) => {
        if (list.eid === card.eid) {
          list.added = !list.added;
        }
      });
      return [...prevState];
    });
  };

  const saveHandler = () => {
    let filteredCardsData = cardsList.filter((val) => val.added);
    cardsToBeAdded?.(filteredCardsData);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size='5xl'>
      <ModalOverlay />
      <ModalContent style={{ borderRadius: '10px' }}>
        <div style={{ margin: '40px 40px 20px 40px' }}>
          <Flex w='100%' align='center' justify='space-between'>
            <Flex align='center' w='full' mb='30px' gap='16px'>
              <Box h='32px' w='16px' bg='#cabdff' borderRadius='4px' />
              <div style={{ fontSize: '20px', fontWeight: '600' }}>
                {title || t('deck:add_card_deck')}
              </div>
            </Flex>
            <div style={{ marginBottom: '30px' }}>
              <img
                src={closeIcon}
                onClick={onClose}
                style={{ cursor: 'pointer' }}
                alt='close-icon'
              />
            </div>
            {/* <ModalCloseButton /> */}
          </Flex>
          <div style={{ height: '70vh' }}>
            <Box display='flex'>
              <SearchInput
                size='lg'
                width='100%'
                hideShortcuts={true}
                onChange={handleSearchChange}
                placeholder={searchPlaceholder || t('deck:title_card_deck')}
              />
              {showCategory ? (
                <Box minWidth='25%' ml='16px'>
                  <Dropdown
                    options={categoryList}
                    value={selectedCategory}
                    onChange={setSelectedCategory}
                    placeholder={t('category:category_name')}
                    size='lg'
                    inputStyle={{
                      border: '1px solid',
                      borderColor: '#CCCCCC',
                      borderRadius: '12px',
                      fontSize: '15px',
                      outline: 'none',
                    }}
                  />
                </Box>
              ) : null}
            </Box>
            <div className='updated-deck-card-container'>
              {cardsList.map((card, index) => (
                <div
                  key={index}
                  className='updated-deck-card-container-sub-div'
                >
                  <div className='updated-deck-img-cover'>
                    <Image
                      src={getValue(card, 'thumbnail')}
                      height={61}
                      width={63}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <div className='updated-deck-type-img-cover' />
                    {card.type === 'deck' && (
                      <div className='updated-deck-isDeck'>
                        {card?.cards?.length || '0'}
                        <CardNumber />
                      </div>
                    )}
                  </div>
                  <div
                    className='updated-deck-card-info-cover'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div className={'updated-deck-card-text-elips'}>
                      <p>{card?.category}</p>
                      <span className='updated-deck-card-title'>
                        {getValue(card, 'title')}
                      </span>
                      <p>
                        {t('common:last_updated_date', {
                          date: dateFormat(card.updatedAt, 'dd mmm yyyy'),
                        })}
                      </p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: '15px' }}>
                        <PrimaryButton
                          title={
                            card.added
                              ? t('deck:added')
                              : buttonText || t('deck:add_to_deck')
                          }
                          type='button'
                          variant='outline'
                          style={{
                            backgroundColor: card.added
                              ? 'rgb(42, 133, 255)'
                              : 'white',
                            color: card.added ? 'white' : 'black',
                            minWidth: 90,
                          }}
                          onClick={() => handleChange(card)}
                        />
                      </div>
                      <div>
                        <img
                          src={previewIcon}
                          style={{ cursor: 'pointer' }}
                          onClick={() => previewHandler?.(card)}
                          alt='preview-icon'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Button
            colorScheme='blue'
            onClick={saveHandler}
            size='lg'
            style={{
              width: '100%',
              backgroundColor: '#2a85ff',
              marginTop: '43px',
            }}
          >
            {t('deck:save_close')}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default UpdateDeckAddCards;

import React, { ChangeEventHandler, FC, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  IconButton,
  List,
  ListItem,
  MenuDivider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDownToBracket,
  faEye,
  faMobile,
  faX,
} from '@fortawesome/pro-regular-svg-icons';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as ShareIcon } from '../../../assets/images/chapter-editor/share-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash-red.svg';

import { SearchInput } from 'atoms';
import { Authorize, AuthRole } from 'authorization';
import { SopType } from 'types';

import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { SopFile } from '../chapter.types';
import { Title } from '../../../ui-components/common';
import { CHAPTER_URL } from '../../../appRoutes';
import useCombinedStore from 'zustandStore/store';
import { getQueryParamValue } from 'utils/queryParams';
import { CategoryEntity } from 'sop-commons/src/client';

interface IProps {
  title?: string;
  category?: string;
  categoryId?: string;
  /** (Optional) Used to check if there is parent folder present for the `categoryId` being passed as one of the prop. */
  treeData: CategoryEntity | undefined;
  sopFile?: SopFile;
  sopType?: SopType;
  searchQuery?: string;
  onSearch?: ChangeEventHandler<HTMLInputElement>;
  creatorId?: string;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  showVisibility: () => void;
  shareChapterHandler: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  exportPDFHandler?: () => void;
}

const ChapterHeader: FC<IProps> = ({
  category,
  categoryId,
  title,
  treeData,
  sopFile,
  sopType,
  searchQuery,
  onSearch,
  creatorId,
  setShowPreview,
  showVisibility,
  shareChapterHandler,
  exportPDFHandler,
  onEditClick,
  onDeleteClick,
}) => {
  console.log('Tree Data : ', treeData);
  const statusValue = getQueryParamValue('status');
  const { t } = useTranslation(['common', 'sidebar']);
  const {
    visibilitySelection,
    reset,
    updateCustomSectionExpand,
    updateSameAsParent,
    updateOperationType,
    updateVisibilityScope,
  } = useCombinedStore();
  const history = useHistory();
  let dashboardCtx = useContext(DashboardDataContext);
  let selectedFolderId =
    dashboardCtx?.navigationPersistData?.knowledgeBase?.selectedFolder?.eid;

  console.log('Selected Folder ID : ', selectedFolderId);

  const goBackHandler = () => {
    let goBackStr = '';
    if (selectedFolderId) {
      if (statusValue) {
        goBackStr = `/folders/${selectedFolderId}?status=RESTRICT`;
      } else {
        goBackStr = `/folders/${selectedFolderId}`;
      }
    } else {
      goBackStr = '/folders';
    }
    history.push(goBackStr);
  };

  const onDownloadClick = (url?: string) => {
    if (url) {
      const a = document.createElement('a');
      a.href = url;
      a.download = url.split('/').pop()!;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const breadcrumbRender = () => {
    const baseBreadcrumb = (
      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          to={'/folders'}
          fontSize='15px'
          color='#6F767E'
        >
          {t('sidebar:knowledge_base')}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );

    const treeDataBreadcrumb = treeData?.eid ? (
      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          to={`/folders/${treeData.eid}`}
          fontSize='15px'
          color='#33383F'
        >
          {treeData.name}
        </BreadcrumbLink>
      </BreadcrumbItem>
    ) : null;

    const categoryBreadcrumb = category ? (
      <BreadcrumbItem>
        <BreadcrumbLink
          as={Link}
          to={`/folders/${
            treeData?.eid ? `${treeData.eid}/` : ''
          }${categoryId}`}
          fontSize='15px'
          color='#33383F'
        >
          {category}
        </BreadcrumbLink>
      </BreadcrumbItem>
    ) : null;

    return (
      <Breadcrumb>
        {baseBreadcrumb}
        {treeDataBreadcrumb}
        {categoryBreadcrumb}
      </Breadcrumb>
    );
  };

  return (
    <Flex align='center' justify='space-between' px='20px'>
      {/* {sopType === 'text' ? (
        <SearchInput
          disabled={true}
          size='md'
          width='350px'
          hideShortcuts={true}
          placeholder={t('common:search')}
          onChange={onSearch}
          value={searchQuery}
        />
      ) : ( */}
      <Box>
        {breadcrumbRender()}
        <Title lineHeight='36px'>{title}</Title>
      </Box>
      {/* )} */}
      <Spacer />
      <Flex gap='12px'>
        {sopType === 'text' && (
          <SearchInput
            disabled={true}
            size='md'
            width='350px'
            hideShortcuts={true}
            placeholder={t('common:search')}
            onChange={onSearch}
            value={searchQuery}
          />
        )}
        <Popover>
          {/* @ts-ignore */}
          <PopoverTrigger>
            <IconButton
              borderRadius='full'
              aria-label='options'
              icon={
                <FontAwesomeIcon
                  icon={faEllipsisVertical as IconProp}
                  color='#5B5A64'
                />
              }
            />
          </PopoverTrigger>
          <PopoverContent w='300px' borderRadius='16px'>
            <PopoverArrow />
            <PopoverBody>
              <List cursor='pointer' spacing={2} py={2} px={0}>
                {sopType === 'text' ? (
                  <ListItem px='16px' onClick={() => setShowPreview(true)}>
                    <Flex gap={3} align='center' justify='flex-start'>
                      <FontAwesomeIcon icon={faMobile as IconProp} />
                      <Text my='5px' ml={'5px'}>
                        Preview
                      </Text>
                    </Flex>
                  </ListItem>
                ) : (
                  <ListItem
                    px='16px'
                    onClick={() => onDownloadClick(sopFile?.url)}
                  >
                    <Flex gap={3} align='center' justify='flex-start'>
                      <FontAwesomeIcon
                        icon={faArrowDownToBracket as IconProp}
                      />
                      <Text my='5px' ml={'5px'}>
                        Download
                      </Text>
                    </Flex>
                  </ListItem>
                )}
                <ListItem
                  px='16px'
                  onClick={() => {
                    updateOperationType('view');
                    updateVisibilityScope('chapter');
                    updateCustomSectionExpand(false);
                    updateSameAsParent(false);
                    showVisibility();
                  }}
                >
                  <Flex gap={3} align='center' justify='flex-start'>
                    <FontAwesomeIcon icon={faEye as IconProp} />
                    <Text my='5px' fontWeight={400} fontSize='14px'>
                      Visibility
                    </Text>
                  </Flex>
                </ListItem>
                <ListItem px='16px' onClick={shareChapterHandler}>
                  <Flex gap={3} align='center' justify='flex-start'>
                    <ShareIcon />
                    <Text my='5px' fontWeight={400} fontSize='14px'>
                      Share
                    </Text>
                  </Flex>
                </ListItem>
                {sopType === 'text' && (
                  <ListItem px='16px' onClick={exportPDFHandler}>
                    <Flex gap={3} align='center' justify='flex-start'>
                      <Text
                        bg='#A80505'
                        color='white'
                        fontWeight={600}
                        fontSize='13px'
                        borderRadius='4px'
                        p='0px 6px'
                      >
                        beta
                      </Text>
                      <Text my='5px' fontWeight={400} fontSize='14px'>
                        Export as PDF
                      </Text>
                    </Flex>
                  </ListItem>
                )}

                <Authorize
                  permittedFor='user'
                  permittedRoles={AuthRole.SUPER_ADMIN}
                  canAccess={(authUser) => {
                    return authUser?.eid === creatorId;
                  }}
                >
                  <>
                    <MenuDivider mx='8px' />
                    <ListItem px='16px' onClick={onDeleteClick}>
                      <Flex gap={3} align='center' justify='flex-start'>
                        <DeleteIcon width='18px' />
                        <Text
                          my='5px'
                          fontWeight={400}
                          fontSize='14px'
                          color='#FF6A55'
                        >
                          {t('common:delete')}
                        </Text>
                      </Flex>
                    </ListItem>
                  </>
                </Authorize>
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Authorize
          permittedFor={['user']}
          permittedRoles={[AuthRole.SUPER_ADMIN]}
          canAccess={(authUser) => {
            return authUser?.eid === creatorId;
          }}
        >
          <IconButton
            disabled={!creatorId}
            borderRadius='full'
            aria-label='more'
            icon={<FontAwesomeIcon icon={faPen as IconProp} color='#5B5A64' />}
            onClick={onEditClick}
          />
        </Authorize>
        <IconButton
          borderRadius='full'
          aria-label='more'
          icon={<FontAwesomeIcon icon={faX as IconProp} color='#5B5A64' />}
          onClick={goBackHandler}
          // onClick={() => history.push('/folders')}
        />
      </Flex>
    </Flex>
  );
};

export default ChapterHeader;

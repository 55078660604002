import { TranslatedData } from './base-card.types';
import { IEmojiStructure } from 'delightree-editor';

export type TrainingStatus = 'active' | 'inactive' | 'archieved' | 'draft';

export enum TrainingContType {
  SOP = 'sop',
  CARD = 'card',
  DECK = 'deck',
  MILESTONE = 'milestone',
}

export interface TrainingItemEntity {
  type: string;
  eid: string;
  uid: string;
  content?: null[];
  icon: IEmojiStructure;
  title: string;
  isMultiPage: boolean;
  createdAt: string;
  updatedAt: string;
  sopType: string;
  lastUpdated: string;
  visibility: string;
  files?: null[];
  media?: null[];
  categoryId: string;
  category: string;
  thumbnail: string;
  status: string;
  smartPageEnabled: boolean;
  translations: Record<string, TranslatedData>;
  cards?: CardsEntity[];
  cardView?: CardViewEntity[];
  dateAdded: string;
}

// TODO types
export interface CardsEntity {
  type: string;
  eid: string;
  uid: string;
  title: string;
  subTitle: string;
  content?: null[];
  createdAt: string;
  updatedAt: string;
  lastUpdated: string;
  formId: string;
  visibility: string;
  createdBy: string;
  category: string;
  thumbnail: string;
  status: string;
  layout: string;
  cardType: string;
  files?: null[] | null;
  media?: null[] | null;
  defaultLanguage: string;
  backgroundColor: string;
  form: string;
  translations: Record<string, TranslatedData>;
}

// TODO types
export interface CardViewEntity {
  type: string;
  eid: string;
  uid: string;
  title: string;
  subTitle: string;
  content?: null[] | null;
  createdAt: string;
  updatedAt: string;
  lastUpdated: string;
  formId: string;
  visibility: string;
  createdBy: string;
  category: string;
  thumbnail: string;
  status: string;
  layout: string;
  cardType: string;
  files?: null[];
  media?: null[];
  defaultLanguage: string;
  backgroundColor: string;
  form: string;
  translations: Record<string, TranslatedData>;
}

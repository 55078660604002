import moment from 'moment/moment';

import { IFormInput } from './task.types';
import { AddTaskInput } from './task.graphql';

const DATE_FORMAT = 'YYYY-MM-DD';

export const getEndCondition = (
  values: IFormInput
): AddTaskInput['endCondition'] => {
  if (values.taskRunFor === 'once') {
    const res: AddTaskInput['endCondition'] = {
      endType: 'date',
    };

    switch (values.taskDue) {
      case 'nextWeek':
        res.endDate = moment()
          .startOf('isoWeek')
          .add(1, 'week')
          .day('monday')
          .format(DATE_FORMAT);
        break;
      case 'endOfMonth':
        res.endDate = moment().endOf('month').format(DATE_FORMAT);
        break;
      case 'endOfYear':
        res.endDate = moment().endOf('year').format(DATE_FORMAT);
        break;
      case 'date':
        res.endDate = moment(values.taskDueDate).format(DATE_FORMAT);
        break;
      default:
        break;
    }
    return res;
  }

  const endData: AddTaskInput['endCondition'] = {
    endType: values.endCondition.endType,
  };

  if (endData.endType === 'repeat') {
    endData.repeatCount = +(values.endCondition.repeatCount || 0);
  }
  if (endData.endType === 'date') {
    endData.endDate = moment(values.endCondition.endDate).format(DATE_FORMAT);
  }

  return endData;
};

import { gql } from '@apollo/client';
import { LocationMember } from 'types';

export interface DeleteLocationResponse {
  deleteLocationWithUsers: {
    succeed: boolean;
  };
}

export interface DeleteLocationVariable {
  locationId: string;
}

export const DELETE_LOCATION_QUERY = gql`
  mutation DeleteLocationWithUsers($locationId: String!) {
    deleteLocationWithUsers(locationId: $locationId) {
      succeed
    }
  }
`;

export interface ILocationMember extends LocationMember {
  checked?: boolean;
}

export interface LocationResponse {
  userById: {
    eid: string;
    members: LocationMember[];
  };
}

export interface LocationVariable {
  eid: string;
}

export const getMembers = gql`
  query GetLocationMembers($eid: String!) {
    userById(eid: $eid) {
      eid
      members
    }
  }
`;

export interface MoveMemberResponse {
  moveUsersToLocation: {
    succeed: boolean;
  };
}

export interface MoveMemberVariable {
  deleteLoc: string;
  moveToLoc: string;
  userIds: string[];
}

export const moveLocationMember = gql`
  mutation MoveMembers(
    $deleteLoc: String!
    $moveToLoc: String!
    $userIds: [String!]
  ) {
    moveUsersToLocation(
      deleteLoc: $deleteLoc
      moveToLoc: $moveToLoc
      userIds: $userIds
    ) {
      succeed
    }
  }
`;

import React, { FC } from 'react';
import { Center } from '@chakra-ui/react';
import Loader from '../../../../Loader';
import EmptyState from '../../../../EmptyState';

interface IProps {
  isEmpty?: boolean;
  isLoading?: boolean;
}

const MoveFormLoader: FC<IProps> = ({ isLoading, isEmpty, children }) => {
  if (isLoading) {
    return (
      <Center flex={1} minH='260px'>
        <Loader size='lg' />
      </Center>
    );
  }

  if (isEmpty) {
    return (
      <Center flex={1} minH='320px'>
        <EmptyState
          image='Search'
          imageSize='160px'
          title='No forms found'
          description='Oops! Try a different search to get results.'
        />
      </Center>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default MoveFormLoader;

import React, { ChangeEvent, FC, useState } from 'react';
import Loader from '../../Loader';

interface IProps {
  imageKey: number;
  image?: string;
  onFileChange?: (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => PromiseLike<void>;
}

const EditImage: FC<IProps> = ({ imageKey, image, onFileChange }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true);
      await onFileChange?.(event, imageKey);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div style={{ position: 'relative' }}>
        <img
          src={image}
          className='card-editor-image-container'
          key={imageKey}
        />
        <Loader
          size='lg'
          style={{
            position: 'absolute',
            top: '26px',
            left: '26px',
          }}
        />
      </div>
    );
  }

  return (
    <>
      <label htmlFor={`file-${imageKey}`} className='card-editor-image-label'>
        <img
          src={image}
          className='card-editor-image-container'
          key={imageKey}
        />
        <span className='image-index-count'>{imageKey + 1}</span>
      </label>
      <input
        type='file'
        onChange={onImageChange}
        id={`file-${imageKey}`}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default EditImage;

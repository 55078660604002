import React, { FC, useEffect } from 'react';
import { Box, Center, Divider, Flex, Image, Text } from '@chakra-ui/react';
import { ImageStyle } from './FolderIconUpload';
import SiteGallery from 'sub-components/SiteGallery';
import FolderUploadContainer from './FolderUploadContainer';
import { eventBus } from 'shared/eventEmit/eventBus';

interface IProps {
  colorArray: string[];
  selectedColor: string;
  selectedGalleryImage: string;
  selectedTab: number;
}

const FolderIconTabs: FC<IProps> = ({
  colorArray,
  selectedColor,
  selectedGalleryImage,
  selectedTab,
}) => {
  const selectedTabEmit = (tabNum: number) => {
    eventBus.emit('setSelectedTab', tabNum); // emit to FolderIconUpload component
  };

  const selectedColorEmit = (color: string) => {
    eventBus.emit('setSelectedColor', color); // emit to FolderIconUpload component
  };

  const selectedGalleryImageEmit = (value: string) => {
    eventBus.emit('setSelectedGalleryImage', value); // emit to FolderIconUpload compoent
  };

  const removeSelectedImageFileEmit = () => {
    eventBus.emit('removeSelectedImageFile'); // emit to FolderUploadContainer
  };

  return (
    <Flex
      w='460px'
      h='400px'
      borderRadius='17px'
      bg='white'
      p='32px 29px'
      border='1px solid #F5F5F5'
      position='relative'
      flexDir='column'
      gap={4}
    >
      <Flex justify='space-between' w='full' h='fit-content'>
        <Flex gap={4}>
          <Flex position='relative' h='fit-content'>
            <Text
              cursor='pointer'
              onClick={() => selectedTabEmit(0)}
              color={selectedTab === 0 ? '#33383F' : '#6F7E7C'}
              fontSize='15px'
              fontWeight={selectedTab === 0 ? 700 : 600}
              h='fit-content'
            >
              Site Gallery
            </Text>
            {selectedTab === 0 && (
              <Box
                position='absolute'
                bg='#2A85FF'
                h='3px'
                top='26px'
                w='full'
                style={{ zIndex: 1 }}
              />
            )}
          </Flex>
          <Flex position='relative' h='fit-content'>
            <Text
              cursor='pointer'
              onClick={() => selectedTabEmit(1)}
              color={selectedTab === 1 ? '#33383F' : '#6F7E7C'}
              fontSize='15px'
              fontWeight={selectedTab === 1 ? 700 : 600}
              h='fit-content'
            >
              Upload
            </Text>
            {selectedTab === 1 && (
              <Box
                position='absolute'
                bg='#2A85FF'
                h='3px'
                top='26px'
                w='full'
                style={{ zIndex: 1 }}
              />
            )}
          </Flex>
        </Flex>
        <Flex gap={2} align='center'>
          {selectedTab === 0 && (
            <>
              <Flex align='center' gap={2}>
                {colorArray?.map((color, index) => (
                  <Center
                    key={index}
                    p='2px'
                    borderRadius='50%'
                    border={
                      selectedColor === color ? `1px solid ${color}` : 'none'
                    }
                    onClick={() => selectedColorEmit(color)}
                  >
                    <Box
                      borderRadius='50%'
                      bg={color}
                      boxSize='20px'
                      cursor='pointer'
                    />
                  </Center>
                ))}
              </Flex>
              <Divider orientation='vertical' borderColor='black' />
            </>
          )}
          <Text
            onClick={() => {
              selectedGalleryImageEmit('');
              if (selectedTab === 1) {
                removeSelectedImageFileEmit();
              }
            }}
          >
            Remove
          </Text>
        </Flex>
      </Flex>
      <Box
        position='absolute'
        h='1px'
        bg='#F5F5F5'
        top='59px'
        w={selectedTab === 0 ? '40%' : '90%'}
      />
      {selectedTab === 0 && (
        <SiteGallery
          selectedColor={selectedColor}
          selectedGalleryImage={selectedGalleryImage}
        />
      )}
      {selectedTab === 1 && <FolderUploadContainer />}
    </Flex>
  );
};

export default FolderIconTabs;

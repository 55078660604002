import { gql } from '@apollo/client';

export const CHANNEL_DETAILS_QUERY = gql`
  query ChatGroupGuid($guid: String!) {
    FetchChatGroupDetails(guid: $guid)
  }
`;

export interface GroupMetaVariable {
  guid: string;
  metadata?: Object;
  name?: string;
  type?: string;
}

export const UPDATE_GROUP_METADATA = gql`
  mutation UpdateCometChatGroupData(
    $guid: String!
    $metadata: JSON
    $name: String
    $type: String
  ) {
    updateCometChatGroupData(
      guid: $guid
      metadata: $metadata
      name: $name
      type: $type
    )
  }
`;

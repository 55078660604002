import React, { FC } from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Image as NativeImage,
  Button,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'ui-components/Confirm';
import MoveToRoleHeader from '../MoveMembersToAnotherRoleModal/MoveToRoleHeader';
import emptyIcon from '../../../../assets/images/placeholder/empty-role-category.svg';
import { IRoleData } from '../roles-list.types';
import EmptyState from 'sub-components/EmptyState';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onDeletePress: () => void;
  roleData: IRoleData;
  loading: boolean;
  showData: {
    initialModal: boolean;
    itemsModal: boolean;
    noDataModal: boolean;
    selectedRole: IRoleData;
  };
  setShowData: React.Dispatch<
    React.SetStateAction<{
      initialModal: boolean;
      itemsModal: boolean;
      noDataModal: boolean;
      selectedRole: IRoleData;
    }>
  >;
}

const NoRoleModal: FC<IProps> = ({
  isOpen,
  onClose,
  roleData,
  showData,
  loading,
  setShowData,
  onDeletePress,
}) => {
  const { t } = useTranslation(['category', 'role']);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={'2xl'}>
      <ModalOverlay />
      <ModalContent p={['20px 40px']} gap={4}>
        <ModalHeader>
          <MoveToRoleHeader
            roleData={roleData}
            onClose={onClose}
            onBackClick={() =>
              setShowData({
                ...showData,
                initialModal: true,
                itemsModal: false,
                noDataModal: false,
              })
            }
            onChange={() => {}}
          />
          <ModalCloseButton m={5} />
        </ModalHeader>
        <Box maxH='calc(100vh - 280px)' overflow='auto'>
          <Flex direction='column' align='center' gap='12px' py='36px'>
            <EmptyState
              title={t('role:no_member')}
              image='Job'
              showButton
              buttonProps={
                <Button
                  colorScheme={'red'}
                  onClick={onDeletePress}
                  isLoading={loading}
                  disabled={loading}
                >
                  {t('category:delete_name', {
                    value: roleData?.name,
                  })}
                </Button>
              }
            />
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default NoRoleModal;

import React, { useCallback, useMemo, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import {
  ApolloProvider,
  useApolloClient,
  useReactiveVar,
} from '@apollo/client';

import { useTranslation } from 'react-i18next';

import { BoxHeader, UseConfirm, useConfirm } from '../../../ui-components';

import ModalContent from './ModalContent';
import DashboardDataProvider from 'sub-components/DashboardContainer/DashboardStore/DashboardData/DashboardDataProvider';
import { useRouteMatch } from 'react-router-dom';
import { CategoryEntity } from 'sop-commons/src/client';
import { foldersListVar } from '../ChapterContainer/ChapterContainer';

interface IProps {
  chapterId?: string;
  onChapterUpdated?: () => void;
}

type ChapterEdit = (props: IProps) => void;

export const useEditChapter = (): ChapterEdit => {
  const { t } = useTranslation(['chapter']);
  const confirm = useConfirm();
  const folderLists = useReactiveVar(foldersListVar);
  const apolloClient = useApolloClient();

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ onChapterUpdated, chapterId } = {}) => {
    if (!chapterId) {
      return;
    }

    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>
            <DashboardDataProvider>{children}</DashboardDataProvider>
          </ApolloProvider>
        );
      },
      size: '3xl',
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader title={t('chapter:editChapter')} color='#B5E4CA' />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <ModalContent
          chapterId={chapterId}
          onChapterUpdated={onChapterUpdated}
        />
      ),
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      isCentered: false,
      footer: null,
    });
  }, []);
};

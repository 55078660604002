import { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { useConfirm } from '../Confirm';
import PreviewHeader from '../../sub-components/forms/component/PreviewHeader';

type Callback = ReturnType<ReturnType<typeof useConfirm>>;

type RetakeTraining = (props: {
  title: string;
  onOk?: (...args: any[]) => any | PromiseLike<any>;
  onCancel?: (...args: any[]) => any | PromiseLike<any>;
}) => Callback;

export const useRetakeTraining = (): RetakeTraining => {
  const confirm = useConfirm();
  const ref = useRef<Callback>();

  return useCallback(({ title, onOk, onCancel }) => {
    ref.current = confirm({
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <PreviewHeader title={title} color='#B5E4CA' />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
            onClick={(event) => {
              event.preventDefault();
              ref.current?.destroy?.();
              onCancel?.();
            }}
          />
        </Flex>
      ),
      isCentered: true,
      contentProps: {
        py: '16px',
        px: '30px',
        minW: '800px',
      },
      footerProps: {
        paddingTop: '30px',
      },
      okButtonProps: {
        borderRadius: '10px',
        minW: '160px',
        size: 'lg',
      },
      cancelButtonProps: {
        colorScheme: 'blue',
        variant: 'outline',
        borderRadius: '10px',
        minW: '160px',
        size: 'lg',
      },
      onOK: onOk,
      onCancel: onCancel,
    });

    return ref.current;
  }, []);
};

import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { FORM_RESPONSE_BY_ID, FormResponse } from './step-listing.graphql';

interface IProps {
  responseId: string;
}

type FormResponseById = (props: IProps) => Promise<FormResponse>;

export const useFormResponseById = (): FormResponseById => {
  const client = useApolloClient();

  return useCallback(async ({ responseId }) => {
    const response = await client.query<
      Record<'FormResponseById', FormResponse>
    >({
      query: FORM_RESPONSE_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        eid: responseId,
      },
    });

    if (response.error) {
      throw response.error;
    }
    if (response.errors) {
      throw response.errors;
    }
    return response.data?.FormResponseById;
  }, []);
};

import { Box } from '@chakra-ui/react';
import { Card } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { IChartEntity } from '../FormSummary';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IDropDownChart {
  drop: IChartEntity;
  domain: number[];
  ticks: number[];
  fill: string;
}

const DropDownChart: FC<IDropDownChart> = ({
  drop,
  domain,
  ticks,
  fill,
}: IDropDownChart) => {
  const { t } = useTranslation(['form']);
  const toPercent = (decimal, fixed = 0) => {
    return `${(decimal * 100).toFixed(0)}%`;
  };

  const tickFormatter = (value: string, index: number) => {
    return value;
    // const limit = 6;
    // if (value.length < limit) return value;
    // return `${value.substring(0, limit)}...`;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'black', borderRadius: '7px' }}>
          <p
            style={{ color: 'white', padding: '0.5rem 1rem' }}
          >{`${payload?.[0]?.payload?.originalValue}`}</p>
        </div>
      );
    }
    return null;
  };

  const dataHorBar = {
    labels: drop?.questionResponseData?.map((data) => data?.name),
    datasets: [
      {
        type: 'bar',
        backgroundColor: 'rgb(181, 228, 202)',
        borderColor: 'rgb(181, 228, 202)',
        borderWidth: 0,
        hoverBackgroundColor: 'rgb(181, 228, 202)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: drop?.questionResponseData?.map((data) =>
          (data?.value * 100).toFixed(2)
        ),
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (val) {
            let label = '';
            drop?.questionResponseData?.map((question) => {
              if (question?.name === val?.label) {
                label = 'Count: ' + question?.originalValue;
              }
            });
            return label;
          },
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 25,
          callback: (val) => {
            return val + '%';
          },
        },
      },
    },
  };

  const getHeightHandler = () => {
    let totalLabels = drop?.questionResponseData?.map(
      (data) => data?.name
    )?.length;
    if (totalLabels > 5) {
      const newWidth = 700 + (totalLabels - 5) * 50;
      return newWidth + '' + 'px';
    }
    return '450px';
  };

  return (
    <Card style={{ width: '100%' }}>
      <div>
        <p style={{ fontWeight: '600', fontSize: '18px' }}>
          {drop?.questionName}
        </p>
        {drop?.totalResponseCount && (
          <p>
            {t(
              drop?.totalResponseCount > 1 ? 'form:responses' : 'form:response'
            )}
          </p>
        )}
      </div>
      {/* <ResponsiveContainer width='100%'>
                <ComposedChart
                    layout='vertical'
                    data={drop?.questionResponseData}
                >
                    <CartesianGrid stroke='#f5f5f5' />
                    <Tooltip content={<CustomTooltip />} />
                    <XAxis
                        type='number'
                        axisLine={false}
                        domain={domain}
                        tickLine={false}
                        ticks={ticks}
                        tickCount={10}
                        tickFormatter={toPercent}
                    />
                    <YAxis
                        dataKey='name'
                        type='category'
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={tickFormatter}
                    />
                    <Bar dataKey='value' barSize={40} fill={fill} />
                </ComposedChart>
            </ResponsiveContainer> */}
      <Box
        id='horizontal-bar-container'
        h='700px'
        maxH='490px'
        overflow='scroll'
      >
        {' '}
        {/*Control scrolling effect*/}
        <Box id='horizontal-bar-container-body' h={getHeightHandler()}>
          {' '}
          {/*Control the size of the chart*/}
          <Bar type='bar' data={dataHorBar} options={options} />
        </Box>
      </Box>
    </Card>
  );
};

export default DropDownChart;

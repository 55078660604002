import React, { FC } from 'react';
import { Box, Center, Flex, Spacer } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Title } from '../../../ui-components/common';
import PrimaryButton from '../../../atoms/PrimaryButton';
import { useTrainingPreview } from '../../../pages/Training/CreateTrainingPath/Preview';

import { IFormInput, ITaskAction } from './task.types';
import { toArray } from '../../../utils/utils';
import { CardType } from '../../../types';

import HeaderActions from './HeaderActions';

const toTrainingContent = (data: IFormInput['contents']) => {
  return toArray(data).reduce((previousValue, currentValue) => {
    if (currentValue.type === 'form') {
      previousValue.push({
        cardType: CardType.CustomForm,
        form: currentValue.formData,
        title: currentValue.title,
        thumbnail: currentValue.thumbnail,
      });
    }
    if (currentValue.type === 'sop') {
      previousValue.push({
        type: 'sop',
        ...currentValue.sopData,
      });
    }

    return previousValue;
  }, [] as any[]);
};

interface LocationState {
  backToTitle?: string;
}

interface IProps {
  isLoading?: boolean;
  isEdit?: boolean;
  title?: string;
  onCreatePress?: () => void;
  handleAction?: (type: ITaskAction) => void;
}

const TaskCreationHeader: FC<IProps> = ({
  isLoading,
  title,
  onCreatePress,
  isEdit,
  handleAction,
}) => {
  const { t } = useTranslation(['common', 'task']);

  const { getValues } = useFormContext<IFormInput>();

  const history = useHistory();
  const locState = useLocation<LocationState>().state;

  const trainingPreview = useTrainingPreview();

  const onPreviewClick = () => {
    const contents = getValues('contents');
    if (contents.length) {
      trainingPreview({
        trainingContents: toTrainingContent(getValues('contents')),
      });
    }
  };

  const redirectHandler = () => {
    if (locState?.backToTitle) {
      history.goBack();
    } else {
      history.push('/tasks/supervised');
    }
  };

  return (
    <Flex gap={3}>
      <Center height='48px' onClick={redirectHandler} cursor='pointer'>
        <ArrowBackIcon color='#272B30' boxSize='22px' />
      </Center>
      <Box>
        <Title>{title || t('task:createNewTask')}</Title>
        <Box
          fontSize='15px'
          fontWeight='400'
          color='#6F767E'
          cursor='pointer'
          onClick={redirectHandler}
        >
          {locState?.backToTitle ?? t('task:backToTasks')}
        </Box>
      </Box>
      <Spacer />
      <Flex gap={2}>
        {/*<PrimaryButton*/}
        {/*  size='lg'*/}
        {/*  variant='outline'*/}
        {/*  style={{*/}
        {/*    minWidth: 140,*/}
        {/*    borderWidth: 2,*/}
        {/*  }}*/}
        {/*  leftIcon={*/}
        {/*    <FontAwesomeIcon icon={faMobile as IconProp} color='#000000' />*/}
        {/*  }*/}
        {/*  title={t('common:preview')}*/}
        {/*  onClick={onPreviewClick}*/}
        {/*/>*/}

        <HeaderActions
          isLoading={isLoading}
          isEdit={isEdit}
          onCreatePress={onCreatePress}
          handleAction={handleAction}
        />
      </Flex>
    </Flex>
  );
};

export default TaskCreationHeader;

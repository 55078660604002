import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { ChangeLogWrapper } from 'pages/TrainingDraft/ChangeLog/ChangeLog.styles';
import { PublishChangeDiffCheckModalWrapper } from './PublishChangeDiffCheckModal.styles';
import PublishChangeDiffCheckTable from './PublishChangeDiffCheckTable';
import { IPublishChangeDiffCheckTable } from './PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import { TrainingData } from '../training.types';
import PrimaryButton from 'atoms/PrimaryButton';
import { IThirdStepData } from '../CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import { useTranslation } from 'react-i18next';
import { ModalCloseButton } from 'sub-components/SearchModal/component/header';

interface IPublishChangeDiffCheckModal {
  open: boolean;
  onClose: (type: 'cancel' | 'publish' | 'continue' | 'draft') => void;
  changedData: IPublishChangeDiffCheckTable[];
  trainingData: TrainingData;
  initialTrainingData: TrainingData;
  thirdStepData: IThirdStepData;
  title: string;
}

const PublishChangeDiffCheckModal = ({
  open,
  onClose,
  changedData,
  trainingData,
  initialTrainingData,
  thirdStepData,
  title,
}: IPublishChangeDiffCheckModal) => {
  const { t } = useTranslation('training');
  return (
    <Modal isOpen={open} onClose={() => onClose('cancel')} size={'6xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={'10'}>
          <ChangeLogWrapper>
            <div className='change-log-header-container'>
              <div className='change-log-heading-container'>
                <div className='change-log-color' />
                <div className='change-log-heading'>
                  {title ?? t('publish_changes_heading')}
                </div>
              </div>
            </div>
          </ChangeLogWrapper>
          <PublishChangeDiffCheckModalWrapper>
            {!title && (
              <div className='public-review-warning'>
                {t('publish_change_modal_text')}
              </div>
            )}
          </PublishChangeDiffCheckModalWrapper>
          <PublishChangeDiffCheckTable
            changedData={changedData}
            trainingData={trainingData}
            initialTrainingData={initialTrainingData}
            thirdStepData={thirdStepData}
          />
          {!title && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
              }}
            >
              <PrimaryButton
                onClick={() => onClose('continue')}
                title={t('continue_editing_button')}
                style={{
                  color: 'black',
                  marginRight: '10px',
                  border: 'solid 2px #efefef',
                  backgroundColor: 'white',
                  width: '180px',
                  height: '48px',
                }}
              />
              <PrimaryButton
                onClick={() => onClose('publish')}
                title={t('save_and_publish_button')}
                style={{
                  color: 'white',
                  backgroundColor: '#83bf6e',
                  width: '180px',
                  height: '48px',
                }}
              />
            </div>
          )}
          {title && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
              }}
            >
              <PrimaryButton
                title={t('no_save_as_draft_button')}
                onClick={() => onClose('draft')}
                style={{
                  width: '160px',
                  height: '48px',
                  color: 'black',
                  backgroundColor: '#fcfcfc',
                  border: 'solid 2px #efefef',
                  marginRight: '10px',
                }}
              />
              <PrimaryButton
                title={t('yes')}
                onClick={() => onClose('publish')}
                style={{
                  width: '160px',
                  height: '48px',
                  color: 'white',
                  backgroundColor: '#2a85ff',
                  border: 'solid 2px #efefef',
                }}
              />
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PublishChangeDiffCheckModal;

import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  DrawerFooter,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  MenuItem,
  Center,
  List,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import {
  faFilter,
  faFolder,
  faFolderUser,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import SearchInput from 'atoms/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  ChapterResponse,
  ChapterVariable,
  GET_CHAPTERS_LIST_FOR_TRAINING,
} from 'pages/Training/CreateTrainingPath/CreateTrainingPathContainer/TrainingHandbookList/trainining-handbook.graphql';
import { cloneDeep } from '@apollo/client/utilities';
import { ChapterModal } from 'modal/chapter-modal';
import { userObj } from 'sop-commons/src/client';
import { ITrainingCategoryEntity } from 'pages/Training/CreateTrainingPath/training-path-types';
import Loader from 'sub-components/Loader';
import EmptyState from 'sub-components/EmptyState';
import { JSONContent, useReadOnlyEditor } from 'delightree-editor';
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from 'ui-components/InfiniteScroll';
import useInfiniteScroll from 'ui-components/InfiniteScroll/useInfiniteScroll';
import { IChapter } from 'pages/Training/CreateTrainingPath/CreateTrainingPathContainer/TrainingPathStore/training-path-context';
import { useHistory } from 'react-router-dom';
import PrimaryButton from 'atoms/PrimaryButton';
import TrainingHandbookListItem from '../../Training/CreateTrainingPath/CreateTrainingPathContainer/TrainingHandbookList/TrainingHandbookListItem';
import { CardModal } from '../../../modal';
import PreviewHeader from '../../../sub-components/forms/component/PreviewHeader';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { SelectOption } from 'atoms';
import { useFolderSubFolder } from 'hooks';
import Dropdown from 'atoms/Dropdown';
import { chakraComponents } from 'chakra-react-select';
import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import { getFoldersList } from 'shared/graphql/SharedGraphql';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  trainingData: any;
  setTrainingData: React.Dispatch<any>;
}

const KnowledgeBaseDrawer: FC<IProps> = ({
  isOpen,
  trainingData,
  onClose,
  setTrainingData,
}) => {
  const [t] = useTranslation([
    'chapter',
    'common',
    'handbook',
    'header',
    'sidebar',
  ]);
  const history = useHistory();
  const foldersList = useReactiveVar(foldersListVar);
  const [searchQuery, setSearchQuery] = useState('');
  const [typeFilter, setTypeFilter] = useState<SelectOption | null>(null);
  const [selectedHandbookFilterOption, setSelectedHandbookFilterOption] =
    useState<string | undefined>(undefined);
  const { getAllFolderSubFolderList } = useFolderSubFolder();
  const readOnlyEditor = useReadOnlyEditor();
  const [searchString, setSearchString] = useState('');
  const userObject = useReactiveVar(userObj);
  const [chapterItemsList, setChapterItemsList] = useState<IChapter>({
    SopPagination: {
      count: 0,
      items: [],
      pageInfo: {
        currentPage: 0,
        itemCount: 0,
        pageCount: 0,
        perPage: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      },
    },
  });
  const [categories, setCategories] = useState<ITrainingCategoryEntity[]>([]);

  const { execute, loading: foldersListLoading } = getFoldersList();

  useEffect(() => {
    execute();
  }, []);

  useEffect(() => {
    let _category = JSON.parse(
      JSON.stringify(userObject?.entity?.category || [])
    );
    let _totalCategories: ITrainingCategoryEntity[] = [];
    if (_category && Array.isArray(_category) && _category?.length > 0) {
      _category?.forEach((cat: any) => (cat.isSelected = false));
    }
    _totalCategories = _category;
    setCategories(_totalCategories);
  }, [userObject?.entity?.category]);

  useEffect(() => {
    getChapterDataHandler();
  }, [searchString, selectedHandbookFilterOption]);

  const onTypeFilterChange = (value: SelectOption<any>) => {
    setTypeFilter(value);
  };

  const itemsList = useMemo(() => {
    let _allFolderSubFolders: CustomCategoryEntity[] =
      getAllFolderSubFolderList();
    let allFolderSubFolders: CustomCategoryEntity[] = JSON.parse(
      JSON.stringify(_allFolderSubFolders)
    );
    if (typeFilter) {
      allFolderSubFolders = allFolderSubFolders.filter((item) => {
        if (typeFilter.value === 'all') return true;
        if (typeFilter.value === 'subFolder') return item.type === 'subFolder';
        if (typeFilter.value === 'folder') return item.type === 'folder';
        return false;
      });
    }
    if (searchQuery) {
      allFolderSubFolders = allFolderSubFolders.filter((item) =>
        item.name
          .toLowerCase()
          .trim()
          .includes(searchQuery.toLowerCase().trim())
      );
    }

    return allFolderSubFolders.sort((a, b) => a.name.localeCompare(b.name));
    // if (!searchQuery) {
    //   return filteredFolderSubFolderList?.sort((a, b) =>
    //     a.name.localeCompare(b.name)
    //   );
    // } else {
    //   return filteredFolderSubFolderList
    //     ?.filter((item) =>
    //       item?.name
    //         ?.toLowerCase()
    //         ?.trim()
    //         ?.includes(searchQuery?.toLowerCase()?.trim())
    //     )
    //     ?.sort((a, b) => a.name.localeCompare(b.name));
    // }
  }, [foldersList, searchQuery, typeFilter]);

  console.log('ITEMS LIST : ', itemsList);

  const getChapterDataHandler = () => {
    let filter: {
      query?: string;
      category?: string;
      categoryId?: string;
      status?: string;
    } = {};
    filter.status = 'active';

    if (searchString) {
      filter.query = searchString;
    }
    // if (categories?.some((list) => list?.isSelected)) {
    //   filter.category = categories
    //     ?.filter((list) => list?.isSelected)
    //     ?.map((list) => list?.name)?.[0];
    // }
    if (selectedHandbookFilterOption) {
      filter.categoryId = selectedHandbookFilterOption;
    }
    setChapterItemsList({
      SopPagination: {
        count: 0,
        items: [],
        pageInfo: {
          currentPage: 0,
          itemCount: 0,
          pageCount: 0,
          perPage: 0,
          hasNextPage: false,
          hasPreviousPage: false,
        },
      },
    });
    getChapterData({
      variables: {
        page: 1,
        ...((filter?.query || filter?.categoryId || filter?.status) && {
          filter: filter,
        }),
      },
    });
  };

  const [getChapterData, { loading: chapterDataLoading }] = useLazyQuery<
    ChapterResponse,
    ChapterVariable
  >(GET_CHAPTERS_LIST_FOR_TRAINING, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _chapterData = cloneDeep(data);
      _chapterData?.SopPagination?.items?.forEach(
        (_item) => (_item.type = 'sop')
      );
      if (trainingData?.trainingItems?.length > 0) {
        let tempData: IChapter = JSON.parse(JSON.stringify(_chapterData));
        let filteredData: any[] = [];
        _chapterData?.SopPagination?.items?.map((item) => {
          let flag = trainingData?.trainingItems?.some((list: any) => {
            return list?.eid === item?.eid;
          });
          if (!flag) {
            filteredData?.push(item);
          }
        });
        tempData!.SopPagination!.items = filteredData;
        setChapterItemsList({
          SopPagination: {
            count: tempData?.SopPagination?.count,
            items: [
              ...((chapterItemsList &&
                chapterItemsList?.SopPagination &&
                chapterItemsList?.SopPagination.items) ||
                []),
              ...(new ChapterModal(filteredData).data || []),
            ],
            pageInfo: tempData?.SopPagination?.pageInfo,
          },
        });
      } else {
        setChapterItemsList({
          SopPagination: {
            count: _chapterData?.SopPagination?.count,
            items: [
              ...((chapterItemsList &&
                chapterItemsList?.SopPagination &&
                chapterItemsList?.SopPagination.items) ||
                []),
              ...(new ChapterModal(_chapterData?.SopPagination?.items).data ||
                []),
            ],
            pageInfo: _chapterData?.SopPagination?.pageInfo,
          },
        });
      }
      // let _trainingPathItems = cloneDeep(
      //     chapterItemsList?.SopPagination?.items
      // );
      // console.log('training path items : ', _trainingPathItems);
      // setChapterItemsList({
      //     SopPagination: {
      //         count: _chapterData?.SopPagination?.count,
      //         items: [
      //             ...(_trainingPathItems || []),
      //             ...(new ChapterModal(_chapterData?.SopPagination?.items).data ||
      //                 []),
      //         ],
      //         pageInfo: _chapterData?.SopPagination?.pageInfo,
      //     },
      // });
    },
  });

  const loadMoreHandbookItems = () => {
    let page = cloneDeep(
      chapterItemsList?.SopPagination?.pageInfo?.currentPage
    );
    let nextPage = (page || 1) + 1;
    getChapterData({
      variables: {
        page: nextPage,
        filter: {
          status: 'active',
        },
      },
    });
  };

  const [lastElementRef] = useInfiniteScroll(
    chapterItemsList?.SopPagination?.pageInfo?.hasNextPage
      ? loadMoreHandbookItems
      : () => {},
    chapterDataLoading
  );

  const addItemHandler = (item: ChapterModal['data'][number]) => {
    let _chapterData = cloneDeep(chapterItemsList);
    let _chapterItems: CardModal[] = cloneDeep(
      chapterItemsList?.SopPagination?.items || []
    );
    let filteredItems = _chapterItems?.filter(
      (_item) => _item?.eid !== item?.eid
    );
    _chapterItems = filteredItems;
    _chapterData.SopPagination!.items = _chapterItems;
    let _trainingData = cloneDeep(trainingData);
    _trainingData?.trainingItems?.push(item);
    setTrainingData(_trainingData);
    setChapterItemsList(_chapterData);
  };

  // const setCategoryHandler = (indexValue: number) => {
  //   let _categories: ITrainingCategoryEntity[] = cloneDeep(categories);
  //   _categories?.forEach((category, index) => {
  //     if (index !== indexValue) {
  //       category.isSelected = false;
  //     } else {
  //       category.isSelected = !category.isSelected;
  //     }
  //   });
  //   setCategories(_categories);
  // };

  const setCategoryHandler = (id: string) => {
    if (id === selectedHandbookFilterOption) {
      setSelectedHandbookFilterOption?.(undefined);
    } else {
      setSelectedHandbookFilterOption?.(id);
    }
  };

  const onChapterClick = () => {
    window.open('/create-chapter', '_blank');
  };

  const getReadingTime = (readingContent: JSONContent) => {
    let raw_content = readOnlyEditor({
      content: readingContent,
    }).getText();
    const wordsPerMinute = 200; // Change this value to adjust the reading speed
    const words = raw_content.trim().split(/\s+/).length;
    const minutes = words / wordsPerMinute;
    return Math.ceil(minutes).toFixed(0) + ' min read';
  };
  const getListDataHandler = () => {
    if (
      (!chapterItemsList?.SopPagination?.items?.length && chapterDataLoading) ||
      foldersListLoading
    ) {
      return (
        <Center width='full' h='full'>
          <Loader size='lg' />
        </Center>
      );
    }

    if (!chapterDataLoading) {
      if (chapterItemsList?.SopPagination?.items?.length === 0) {
        return (
          <EmptyState
            image='Search'
            title={t('header:result_not_found')}
            description={t('header:not_found_desc')}
          />
        );
      }
    }

    const itemLength = chapterItemsList?.SopPagination?.items?.length;

    return (
      <InfiniteScroll
        hasMore={chapterItemsList?.SopPagination?.pageInfo?.hasNextPage!}
        loader={chapterDataLoading}
        loaderType='text'
        loaderText={t('common:loading')}
        loaderTextPosition='center'
        loaderTextColor='#000000'
        loaderTextWeight={600}
        loaderTextSize='16px'
        loaderTextMarginBottom='20px'
      >
        <List spacing={4}>
          {chapterItemsList?.SopPagination?.items?.map((item, index) => (
            <TrainingHandbookListItem
              key={index}
              ref={itemLength === index + 1 ? lastElementRef : null}
              item={item}
              getReadingTime={getReadingTime}
              addItemHandler={addItemHandler}
              addPosition='right'
            />
          ))}
        </List>
      </InfiniteScroll>
    );
  };

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
      <DrawerContent>
        <DrawerCloseButton top='16px' right='24px' />
        <DrawerHeader>
          <PreviewHeader title={t('sidebar:knowledge_base')} />
        </DrawerHeader>
        <DrawerBody overflow='hidden' display='flex' flexDir='column'>
          <Box display={'flex'} gap={2}>
            <SearchInput
              hideShortcuts
              placeholder={t('chapter:knowledgeBaseSearchPlaceholder')}
              value={searchString}
              onChange={(e) => setSearchString(e?.target?.value)}
            />
            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<FontAwesomeIcon icon={faFilter as IconProp} />}
                    style={{
                      border: isOpen
                        ? '1px solid rgba(42, 133, 255, 1)'
                        : '1px solid rgba(239, 239, 239, 1)',
                      backgroundColor: isOpen
                        ? 'rgba(42, 133, 255, 1)'
                        : 'white',
                      color: isOpen ? 'white' : 'rgba(111, 118, 126, 1)',
                    }}
                  />
                  <MenuList
                    padding={'0'}
                    overflowY={'auto'}
                    maxHeight={'420px'}
                  >
                    <Flex flexDir='column' w='full' gap={4} pb={4}>
                      <Flex m={4} align='center' gap={2}>
                        <Flex w='80%'>
                          <SearchInput
                            placeholder='Search folder or subfolder'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e?.target?.value)}
                            hideShortcuts
                          />
                        </Flex>
                        <Flex zIndex='100'>
                          <Dropdown
                            placeholder={t('handbook:type')}
                            value={typeFilter}
                            onChange={onTypeFilterChange}
                            options={[
                              {
                                label: 'All',
                                value: 'all',
                              },
                              {
                                label: 'Folders',
                                value: 'folder',
                              },
                              {
                                label: 'Subfolders',
                                value: 'subFolder',
                              },
                            ]}
                            selectStyles={{
                              singleValue: {
                                color: '#6f767e',
                                fontWeight: '600',
                              },
                              inputContainer: {
                                width: '70px',
                              },
                            }}
                            components={{
                              SingleValue: (valProps) => {
                                return (
                                  <chakraComponents.SingleValue {...valProps}>
                                    {/* @ts-ignore */}
                                    {t('handbook:type_by', {
                                      value: valProps.children,
                                    })}
                                  </chakraComponents.SingleValue>
                                );
                              },
                            }}
                          />
                        </Flex>
                      </Flex>
                      {searchQuery && itemsList?.length === 0 && (
                        <EmptyState
                          image='Search'
                          title='No folder or subfolder found.'
                          description='Oops! Try a different search to get results.'
                        />
                      )}
                      {itemsList?.length > 0 && (
                        <Flex flexDir='column' overflowY='auto' maxH='40vh'>
                          {itemsList?.map((list) => (
                            <Flex
                              id='b-m-c-l-item'
                              key={list?.eid}
                              // _selected={{
                              //   backgroundColor: '#2A85FF1F',
                              // }}
                              aria-selected={
                                selectedHandbookFilterOption === list?.eid
                              }
                              w='full'
                              cursor='pointer'
                              onClick={() => {
                                setCategoryHandler(list?.eid);
                                onClose();
                              }}
                              _hover={{ bg: '#EFEFEF' }}
                              _notLast={{
                                '> div ': {
                                  borderBottom: '1px solid #EFEFEF',
                                },
                              }}
                            >
                              <Flex w='full' align='center' mx='32px' py='10px'>
                                <Flex flex={1} align='center' gap={2}>
                                  <Center
                                    boxSize='32px'
                                    border='1px solid #DDDDDD'
                                    borderRadius='5px'
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        (list?.visibility === 'private'
                                          ? faFolderUser
                                          : faFolder) as IconProp
                                      }
                                      color='#6F767E'
                                      fontSize='15px'
                                    />
                                  </Center>
                                  <Text isTruncated maxW='140px'>
                                    {list?.name}
                                  </Text>
                                  {list?.parentFolderName && (
                                    <Flex gap='5px' align='center'>
                                      <Text>in</Text>
                                      <Text
                                        fontWeight={700}
                                        isTruncated
                                        maxW='140px'
                                      >
                                        {list?.parentFolderName}
                                      </Text>
                                    </Flex>
                                  )}
                                </Flex>
                                {list?.eid !== selectedHandbookFilterOption ? (
                                  <Text
                                    fontWeight={500}
                                    fontSize='12px'
                                    color='#979EA6'
                                  >
                                    {list?.type === 'folder'
                                      ? 'Folder'
                                      : 'Sub Folder'}
                                  </Text>
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faCircleCheck as IconProp}
                                    color='rgba(131, 191, 110, 1)'
                                    fontSize='20px'
                                  />
                                )}
                              </Flex>
                            </Flex>
                          ))}
                        </Flex>
                      )}
                    </Flex>
                  </MenuList>
                </>
              )}
            </Menu>
          </Box>
          <Box flex={1} marginTop='1rem' overflowY='auto'>
            {getListDataHandler()}
          </Box>
        </DrawerBody>
        <DrawerFooter>
          <Box w='full'>
            <Tooltip
              label={t('chapter:createChapterBtnHint')}
              hasArrow
              p='8px 12px'
              borderRadius='8px'
              shouldWrapChildren
              offset={[0, 24]}
            >
              <PrimaryButton
                title={t('chapter:createNewChapter')}
                variant='solid'
                colorScheme='blue'
                size='lg'
                width='full'
                onClick={() => onChapterClick()}
                // onClick={() => window.open('/create-chapter', '_blank')}
              />
            </Tooltip>
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
export default KnowledgeBaseDrawer;

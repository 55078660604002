export const commonStyles = {
  singleValue: {
    color: '#1A1D1F',
    fontWeight: '500',
    fontSize: '15px',
  },
  control: {
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    borderColor: '#ffffff',
  },
  container: {
    width: '100%',
  },
};

import React, { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';

import FormInput from 'atoms/FormInput';
import { actionCardBackgroundColorList } from '../../utils';
import TitleHeader from './TitleHeader';
import { CardFormInput } from './card-editor.types';
import { useTranslation } from 'react-i18next';

const ActionCardEditor: FC = () => {
  const { t } = useTranslation('card');
  const { control, setValue } = useFormContext<CardFormInput>();
  const isReadOnly = useWatch<CardFormInput>({ name: 'isReadOnly' });

  return (
    <div>
      <div>
        <TitleHeader
          title={t('take_action')}
          desc={t('take_action_desc')}
          titleStyle={{ marginTop: '30px' }}
          isRequired
        />

        <Controller
          name={'title'}
          control={control}
          rules={{
            required: t('validation.action_required'),
            validate: (value) => {
              if (value?.trim()?.length === 0) {
                return t('validation.action_enter');
              }
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={3} isInvalid={!!fieldState?.error}>
                <FormInput
                  id='actionText'
                  size='lg'
                  {...field}
                  isDisabled={isReadOnly}
                  onChange={(event) => {
                    console.log('CHANGE EVENT : ', event);
                    field.onChange(event);
                    setValue('raw_content', event.target.value);
                  }}
                />
                <FormErrorMessage>
                  <span>{fieldState?.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </div>

      <div>
        <TitleHeader
          title={'Action button'}
          desc={
            'You can ask your teams to complete a task in-person, for a more wholesome training!'
          }
          titleStyle={{ marginTop: '30px' }}
          isRequired
        />

        <Controller
          name={'subTitle'}
          control={control}
          rules={{
            required: t('validation.action_required'),
            validate: (value) => {
              if (value?.trim()?.length === 0) {
                return t('validation.action_enter');
              }
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={3} isInvalid={!!fieldState?.error}>
                <FormInput
                  id='actionText'
                  size='lg'
                  isDisabled={isReadOnly}
                  {...field}
                  onChange={(event) => {
                    console.log('CHANGE EVENT : ', event);
                    field.onChange(event);
                  }}
                />
                <FormErrorMessage>
                  <span>{fieldState?.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </div>

      <TitleHeader
        title={t('background_color')}
        // desc=' This field will give title to your card.'
        titleStyle={{ marginTop: '30px' }}
      />

      <Controller
        name={'backgroundColor'}
        control={control}
        render={({ field }) => {
          return (
            <Flex mt={4}>
              {actionCardBackgroundColorList.map((item, index) => {
                let style = {};
                if (item === field.value) {
                  style = {
                    border: 'solid 1.5px #2a85ff',
                  };
                }
                return (
                  <div
                    className='card-editor-background-container'
                    key={index}
                    style={{
                      backgroundColor: item,
                      cursor: isReadOnly ? 'no-drop' : 'pointer',
                      ...style,
                    }}
                    onClick={() => !isReadOnly && field.onChange(item)}
                  />
                );
              })}
            </Flex>
          );
        }}
      />
    </div>
  );
};

export default ActionCardEditor;

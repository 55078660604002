import { create, createStore, useStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

/**
 *
 * This one is exported after modification done
 *
 */
export const createAppStore = create;

/**
 *
 * This one is exported as it is in zustand library
 *
 */
export const createNativeStore = create;

export { createStore, useStore, useShallow };

import React, { FC } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Flex, SkeletonText, useBoolean } from '@chakra-ui/react';

import { TrainingItemEntity } from '../../../../../../types';
import { toArray } from '../../../../../../utils/utils';

import { PreviewProvider } from '../../../../../../pages/Training/CreateTrainingPath/Preview/context';
import MobilePreview from './MobilePreview';

const TRAINING_PREVIEW = gql`
  query TrainingPreviewById($eid: String!) {
    trainingById(eid: $eid) {
      eid
      trainingItems
    }
  }
`;

interface Response {
  trainingById: {
    eid: string;
    trainingItems: TrainingItemEntity;
  };
}

interface IProps {
  eid: string;
}

// TODO No data view is missing there
const TrainingPreviewContent: FC<IProps> = ({ eid }) => {
  // This one is used to reset the provider value
  const [isLoading, action] = useBoolean();
  const { loading, data } = useQuery<Response>(TRAINING_PREVIEW, {
    variables: {
      eid: eid,
    },
  });

  React.useEffect(() => {
    action.on();
    const x = setTimeout(() => {
      action.off();
    }, 200);

    return () => {
      clearTimeout(x);
    };
  }, [eid]);

  if (loading || isLoading) {
    return (
      <Flex align='center' px={4}>
        <SkeletonText w='full' spacing={3} skeletonHeight={3} noOfLines={4} />
      </Flex>
    );
  }

  return (
    <PreviewProvider data={toArray(data?.trainingById?.trainingItems)}>
      <MobilePreview />
    </PreviewProvider>
  );
};

export default React.memo(TrainingPreviewContent);

import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Text,
} from '@chakra-ui/react';

import FormInput from 'atoms/FormInput';
import { actionCardBackgroundColorList } from '../../utils';
import TitleHeader from './TitleHeader';
import { CardFormInput } from './card-editor.types';
import { useTranslation } from 'react-i18next';
import PowerUpUploadSkeleton from '../PowerUpUploadSkeleton';
import { useUploadImage } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Loader from 'ui-components/Loader';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { faGif } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import { fileSize } from 'utils/fileSize';
import { encodeSpaces } from 'utils/encodeSpaces';
import { getUrlFileSize } from 'utils/utils';

const MilestoneEditor: FC = () => {
  const { t } = useTranslation(['card', 'training']);
  const userData = useReactiveVar(userObj);
  const uploadImage = useUploadImage();
  const [file, setFile] = useState<File | string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const { control, getValues, setValue } = useFormContext<CardFormInput>();

  const milestoneUrl = useWatch<CardFormInput>({
    name: 'milestoneUrl',
  });
  const milestoneFileSize = useWatch<CardFormInput>({
    name: 'milestoneFileSize',
  });
  const milestoneAddedBy = useWatch<CardFormInput>({
    name: 'milestoneAddedBy',
  });
  const milestoneDateAdded = useWatch<CardFormInput>({
    name: 'milestoneDateAdded',
  });

  useEffect(() => {
    if (typeof file === 'string') {
      setValue('milestoneUrl', file);
    }
  }, []);

  const onFileDrop = async (fileLists: File[]) => {
    const newFile = fileLists?.[0];
    setFile(newFile);
    setIsUploading(true);
    const fileUrl = await uploadImage(newFile || null);
    let parsedFileUrl = encodeSpaces(fileUrl);
    setIsUploading(false);
    if (parsedFileUrl) {
      setValue('milestoneUrl', parsedFileUrl);
      setValue('milestoneAddedBy', userData?.name);
      setValue('milestoneDateAdded', moment()?.toISOString());
      setValue('milestoneFileSize', newFile?.size);
      setFile(null);
    }
  };

  const removeHandler = () => {
    setFile(null);
    setValue('milestoneUrl', '');
    setValue('milestoneAddedBy', '');
    setValue('milestoneDateAdded', '');
    setValue('milestoneFileSize', 0);
  };

  return (
    <Flex mr='2rem' flexDir='column'>
      <Flex h='full' flexDir='column' justify='space-between' mt='1rem'>
        <div id='milestone-editor-540-h' style={{ maxHeight: '645px' }}>
          <div>
            <p
              style={{
                fontSize: '14px',
                fontWeight: '600',
                marginTop: '2rem',
              }}
            >
              {t('training:milestoneName')}
            </p>
            <Controller
              name={'title'}
              control={control}
              render={({ field, fieldState }) => (
                <FormControl mt={3}>
                  <FormInput
                    id='milestoneTitle'
                    size='lg'
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);
                      setValue('raw_content', event.target.value);
                    }}
                  />
                </FormControl>
              )}
            />
          </div>
          <Flex flexDir='column' mt='2rem'>
            <Box mb='1rem'>
              <TitleHeader title={t('card:addImageGif')} />
            </Box>
            {isUploading && (
              <Flex>
                <Loader />
              </Flex>
            )}
            {!milestoneUrl && !isUploading && (
              <Controller
                name='milestoneUrl'
                control={control}
                render={({ field, fieldState }) => (
                  <Flex>
                    <PowerUpUploadSkeleton
                      accept={{
                        'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
                      }}
                      onFileDrop={onFileDrop}
                      onBlur={async (e) => {
                        let newValue = e.target.value;
                        let parsedNewValue = encodeSpaces(newValue);
                        field.onChange(parsedNewValue);
                        setValue('milestoneAddedBy', userData?.name);
                        setValue('milestoneDateAdded', moment()?.toISOString());
                        const size = await getUrlFileSize(parsedNewValue);
                        setValue('milestoneFileSize', size);
                        setFile(null);
                      }}
                    />
                  </Flex>
                )}
              />
            )}
            {milestoneUrl && !isUploading && (
              <Flex justify='space-between'>
                {/* <Image src={milestoneUrl} boxSize='30px' borderRadius='8px' /> */}
                <Flex gap='10px'>
                  <FontAwesomeIcon
                    icon={faGif as IconProp}
                    color='#6F767E'
                    size='2x'
                  />
                  <Flex flexDir='column'>
                    <Text fontWeight={600} isTruncated maxW='350px'>
                      {milestoneUrl}
                    </Text>
                    <Flex gap='2px'>
                      <Text>{fileSize(milestoneFileSize || 0)},</Text>
                      <Text>{t('card:uploadedBy')}</Text>
                      <Text bg='#CABDFF66'>{milestoneAddedBy}, </Text>
                      <Text>{moment(milestoneDateAdded)?.fromNow(false)}</Text>
                    </Flex>
                  </Flex>
                </Flex>
                <FontAwesomeIcon
                  icon={faTrash as IconProp}
                  cursor='pointer'
                  onClick={removeHandler}
                />
              </Flex>
            )}
          </Flex>
          {/* <div>
            <p
              style={{
                fontSize: '14px',
                fontWeight: '600',
                marginTop: '2rem',
              }}
            >
              {t('training:milestoneBackgroundColor')}
            </p>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              {actionCardBackgroundColorList?.map((item, index) => {
                let style = {};
                // if (item === cardData?.backgroundColor) {
                //   style = {
                //     border: 'solid 1.5px #2a85ff',
                //   };
                // }

                // const onChangeColor = () => {
                //   // setSelectedColor(item);
                //   cardData.backgroundColor = item;
                //   console.log('Card Dara', cardData);
                //   let newCardData = cloneDeep(cardData);
                //   setCardData({ ...newCardData });
                // };
                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor: item,
                      minWidth: '48.9px',
                      maxWidth: '48.9px',
                      minHeight: '48.9px',
                      maxHeight: '48.9px',
                      borderRadius: '58.9px',
                      boxShadow: '0 7px 22px 0 rgb(86 84 104 / 20%)',
                      marginRight: '11px',
                      cursor: 'pointer',
                      ...style,
                    }}
                    onClick={() => {}}
                  />
                );
              })}
            </div>
          </div> */}
          <div>
            <p
              style={{
                fontSize: '14px',
                fontWeight: '600',
                marginTop: '2rem',
              }}
            >
              {t('training:whatIsMilestone')}
            </p>
            <p style={{ fontSize: '14px' }}>
              {t('training:milestoneDescription')}
            </p>
          </div>
        </div>
      </Flex>
    </Flex>
  );
};

export default MilestoneEditor;

import React, { FC, ReactNode } from 'react';
import moment from 'moment/moment';
import { AbsoluteCenter, Box, Button } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import { ActivityList } from '../../../../ui-components/ActivityFeed/activity-feed.types';
import { getAvatar } from '../../../../utils';

const SampleData: ActivityList[] = [
  [
    '0',
    [
      {
        eventName: '01',
        createdAt: moment().subtract(2, 'minute'),
        eid: '01',
        user: {
          name: 'Lindsey',
          profilePic: getAvatar({
            name: 'form-act-01',
          }),
        } as never,
        name: 'Lindsey',
        status: 'submitted',
        task: 'a complaint form - Sexual harassment',
        dateStr: moment().subtract(2, 'minute').format('MMMM Do, YYYY'),
      } as never,
      {
        eventName: '01',
        createdAt: moment().subtract(21, 'minute'),
        eid: '02',
        user: {
          name: 'Filomena',
          profilePic: getAvatar({
            name: 'form-act-02',
          }),
        } as never,
        name: 'Filomena',
        status: 'submitted',
        task: 'complaint form - Healthcare & hygiene in kitchen',
        dateStr: moment().subtract(21, 'minute').format('MMMM Do, YYYY'),
      } as never,
    ],
  ],
  [
    '1',
    [
      {
        eventName: '01',
        createdAt: moment().subtract(1, 'day'),
        eid: '04',
        user: {
          name: 'Jessika Dora',
          profilePic: '',
        } as never,
        name: 'Jessika Dora',
        status: 'submitted',
        task: 'response for Inspection Form',
        dateStr: moment().subtract(1, 'day').format('MMMM Do, YYYY'),
      } as never,
      {
        eventName: '01',
        createdAt: moment().subtract(1, 'day'),
        eid: '05',
        user: {
          name: 'Filomena',
          profilePic: getAvatar({
            name: 'form-act-02',
          }),
        } as never,
        name: 'Filomena',
        status: 'submitted',
        task: 'response for Accounting',
        dateStr: moment().subtract(1, 'day').format('MMMM Do, YYYY'),
      } as never,
    ],
  ],
  [
    '2',
    [
      {
        eventName: '01',
        createdAt: moment().subtract(1, 'day'),
        eid: '05',
        user: {
          name: 'Tory Black',
          profilePic: '',
        } as never,
        name: 'Tory Black',
        status: 'submitted',
        task: 'a audit form - Restaurant cleaning checklist',
        dateStr: moment().subtract(2, 'day').format('MMMM Do, YYYY'),
      } as never,
    ],
  ],
];

interface IProps {
  daysAgoHandler: (value: ActivityList) => ReactNode;
  feedDataHandler: (value: ActivityList) => ReactNode;
}

const FillFormToSeeFeed: FC<IProps> = ({ feedDataHandler, daysAgoHandler }) => {
  const { t } = useTranslation('form');
  return (
    <Box pos='relative' height='100%' overflowY='hidden'>
      <Box opacity='0.1' pointerEvents='none' userSelect='none'>
        {SampleData.map((value, index) => {
          return (
            <div key={index}>
              {daysAgoHandler(value)}
              {feedDataHandler(value)}
            </div>
          );
        })}
      </Box>
      <AbsoluteCenter>
        <Button
          leftIcon={
            <FontAwesomeIcon
              icon={faFileLines as IconProp}
              style={{ fontSize: 17 }}
              color={'#2A85FF'}
            />
          }
          fontSize='15px'
          colorScheme='blue'
          variant='outline'
          bg='white'
        >
          {t('fill_form_feed')}
        </Button>
      </AbsoluteCenter>
    </Box>
  );
};

export default FillFormToSeeFeed;

import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Loader from '../../Loader';
import { useWatch } from 'react-hook-form';
import {
  LayoutImageCount,
  MixedLayout,
} from 'sop-commons/src/utils/cardLayouts';
import { CardFormInput } from '../card-editor.types';

interface IProps {
  onFileChange?: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
}

const NewImage: FC<IProps> = ({ onFileChange }) => {
  const mounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const onImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true);
      await onFileChange?.(event, -1);
    } catch (e) {
      console.log(e);
    } finally {
      mounted.current && setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div
        className='card-editor-empty-image-container'
        style={{ position: 'relative' }}
      >
        <Loader
          size='lg'
          style={{
            position: 'absolute',
            top: '26px',
            left: '26px',
          }}
        />
      </div>
    );
  }

  return (
    <>
      <label htmlFor='emptyFile'>
        <div className='card-editor-empty-image-container'>
          <FontAwesomeIcon
            style={{ width: '20px', height: '20px' }}
            color='#B3B3B4'
            icon={faPlus as IconProp}
          />
        </div>
      </label>

      <input
        type='file'
        onChange={onImageChange}
        id='emptyFile'
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

const AddImage: FC<IProps> = ({ onFileChange }) => {
  const images: string[] = useWatch<CardFormInput>({
    name: 'images',
  });

  const layout: MixedLayout = useWatch<CardFormInput>({
    name: 'layout',
  });

  const maxImage = LayoutImageCount[layout];

  if (images?.length >= maxImage) {
    return null;
  }
  return <NewImage onFileChange={onFileChange} />;
};

export default AddImage;

import React, { FC, useCallback, useRef } from 'react';
import {
  AlertDialogCloseButton,
  Center,
  Flex,
  Image,
  useModalContext,
} from '@chakra-ui/react';
import {
  ActionButton,
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../../ui-components';
import trashImage from '../../../../../../assets/images/trash-red.svg';

interface IProps {
  onDeleteClick: () => Promise<void>;
}

const ConfirmContent: FC<IProps> = ({ onDeleteClick }) => {
  const { onClose } = useModalContext();

  return (
    <Center flexDir='column' gap={4} pb={2}>
      <Image
        src={trashImage}
        boxSize='56px'
        p='16px'
        bg='#FF6A5533'
        borderRadius='full'
      />
      <Flex color='#33383F'>
        Are you sure you want to delete this conversation log?
      </Flex>
      <ActionButton
        w='full'
        fontSize='15px'
        colorScheme='red'
        actionFn={onDeleteClick}
        close={onClose}
      >
        Yes, delete
      </ActionButton>
    </Center>
  );
};

type LogDeleteConfirm = (props: IProps) => void;

export const useLogDeleteConfirm = (): LogDeleteConfirm => {
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ onDeleteClick }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader color='#FFBC99' title='Delete this conversation log?' />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: <ConfirmContent onDeleteClick={onDeleteClick} />,
      isCentered: true,
      contentProps: {
        maxW: '545px',
        borderRadius: '12px',
        paddingBlock: '16px',
      },
      footer: null,
    });
  }, []);
};

import { useMemo } from 'react';
import { ButtonProps } from '@chakra-ui/react';

interface IProps {
  isSelected?: boolean;
  isReadOnly?: boolean;
}

export const useButtonProps = ({
  isSelected,
  isReadOnly,
}: IProps): ButtonProps => {
  return useMemo((): ButtonProps => {
    if (isReadOnly) {
      return {
        variant: isSelected ? 'solid' : 'outline',
        color: '#6F767E',
        cursor: 'not-allowed',
        fontWeight: isSelected ? '600' : '500',
        _hover: {
          background: isSelected ? 'gray.100' : 'unset',
        },
        _active: {
          background: isSelected ? 'gray.100' : 'unset',
        },
      };
    }

    if (isSelected) {
      return {
        colorScheme: 'blue',
      };
    }

    return {
      variant: 'outline',
    };
  }, [isReadOnly, isSelected]);
};

import React, { useContext } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  Switch,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { ActionButton } from '../../../../../../ui-components';
import ConfigComponent from './ConfigComponent';
import {
  UPDATE_GROUPS_NOTIFICATION,
  USER_GROUPS_NOTIFICATION,
} from '../../../util/query.graphql';
import { CometChatContext } from '../../../util/CometChatContext';
import usePhoneNumberUpdate from './usePhoneNumberUpdate';

const defaultConfig = {
  mute: false,
  pushNotification: 'on',
  emailNotification: 'on',
  smsNotification: 'on',
};

const convertConfig = (config) => {
  return {
    mute: config.mute,
    pushNotification: config.pushNotification ? 'on' : 'off',
    emailNotification: config.emailNotification ? 'on' : 'off',
    smsNotification: config.smsNotification ? 'on' : 'off',
  };
};

const NotificationSettingsForm = ({ groupId }) => {
  const { notificationConfig } = useContext(CometChatContext);
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
  });

  const { onClose } = useModalContext();

  const phoneNumberUpdate = usePhoneNumberUpdate();

  const { control, formState, handleSubmit } = useForm({
    defaultValues: () => {
      if (notificationConfig?.[groupId]) {
        return Promise.resolve(convertConfig(notificationConfig[groupId]));
      } else {
        return Promise.resolve(defaultConfig);
      }
    },
  });

  const isDirty = formState.isDirty;

  const isMuted = useWatch({
    control,
    name: 'mute',
  });

  const [updateSetting] = useMutation(UPDATE_GROUPS_NOTIFICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: USER_GROUPS_NOTIFICATION,
        fetchPolicy: 'network-only',
      },
    ],
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Notification settings successfully updated',
      });
    },
    onError: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Notification settings updating failed',
      });
    },
  });

  const onSubmit = async (values) => {
    await updateSetting({
      variables: {
        guid: groupId,
        mute: values.mute,
        pushNotification: values.pushNotification === 'on',
        emailNotification: values.emailNotification === 'on',
        smsNotification: values.smsNotification === 'on',
      },
    });

    if (
      !values.mute &&
      values.smsNotification === 'on' &&
      values.smsNotification !== formState.defaultValues.smsNotification
    ) {
      await phoneNumberUpdate();
    }

    setTimeout(onClose, 100);
  };

  return (
    <Flex flexDir='column' gap={3}>
      <Controller
        name='mute'
        control={control}
        render={({ field }) => {
          return (
            <FormControl>
              <Flex gap={4}>
                <Flex flex={1} flexDir='column' gap={1}>
                  <Box fontSize='16px' fontWeight='500' color='#1A1D1F'>
                    Mute channel
                  </Box>
                  <Box color='#6F767E'>
                    You won't receive emails, sms or notifications for messages
                    in the channel.
                  </Box>
                </Flex>
                <Switch
                  ref={field.ref}
                  isChecked={field.value}
                  onChange={field.onChange}
                />
              </Flex>
            </FormControl>
          );
        }}
      />

      <Divider my={1} />

      <Controller
        name='pushNotification'
        control={control}
        render={({ field }) => {
          return (
            <FormControl opacity={isMuted ? 0.6 : 1}>
              <ConfigComponent
                title='App notifications'
                description='Choose how you want to receive chat notifications on the app'
                value={field.value}
                onChange={field.onChange}
                isDisabled={isMuted}
              />
            </FormControl>
          );
        }}
      />

      <Divider my={1} />

      <Controller
        name='emailNotification'
        control={control}
        render={({ field }) => {
          return (
            <FormControl opacity={isMuted ? 0.6 : 1}>
              <ConfigComponent
                title='Email notifications'
                description='Choose how you want to receive chat notifications over email'
                value={field.value}
                onChange={field.onChange}
                isDisabled={isMuted}
              />
            </FormControl>
          );
        }}
      />

      <Divider my={1} />

      <Controller
        name='smsNotification'
        control={control}
        render={({ field }) => {
          return (
            <FormControl opacity={isMuted ? 0.6 : 1}>
              <ConfigComponent
                title='SMS notifications'
                description='Choose how you want to receive chat notifications via SMS'
                value={field.value}
                onChange={field.onChange}
                isDisabled={isMuted}
              />
            </FormControl>
          );
        }}
      />

      <Flex justify='flex-end' mt={4}>
        <ActionButton
          size='lg'
          minW='160px'
          fontSize='15px'
          colorScheme='blue'
          isDisabled={!isDirty}
          actionFn={handleSubmit(onSubmit)}
        >
          Save changes
        </ActionButton>
      </Flex>
    </Flex>
  );
};

NotificationSettingsForm.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default NotificationSettingsForm;

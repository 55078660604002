import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  FORM_BY_ID_RESPONSE_WITH_QUESTIONS,
  FormDataById,
} from 'pages/forms/forms-graphql';
import { IFormResponseItemsEntity } from 'pages/forms/forms-types';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { useTranslation } from 'react-i18next';
import { Image } from '../../../../../ui-components';
import mailIcon from '../../../../../assets/images/UI icon_mail_light.svg';
import shieldIcon from '../../../../../assets/images/shieldIcon.svg';
import locationIcon from '../../../../../assets/images/location.svg';
import { MessageButton } from '../../../../../atoms';
import { mapUserFormResponse, UserFormResponse } from '../UserFormResponse';

import { getImage, toArray } from '../../../../../utils';
import { QuestionEntity, ResponseEntity } from '../../../../../types';
import { useUserDataSelector } from '../../../../../hooks';
import { usersEntityObj } from '../../../../../sub-components/Header';

interface FormResponseDetails {
  createdBy: IFormResponseItemsEntity['createdBy'];
  data: Array<{ response: ResponseEntity; question: QuestionEntity }>;
}

interface DetailsEntity {
  createdBy: IFormResponseItemsEntity['createdBy'];
  response: ResponseEntity[];
}

interface IProps {
  isOpen: boolean;
  formId: string;
  details: DetailsEntity;
  onClose: (type?: 'close' | 'other') => void;
  setShowChat: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormResponseModal: FC<IProps> = ({
  isOpen,
  formId,
  details,
  onClose,
  setShowChat,
}) => {
  const { t } = useTranslation(['form', 'common', 'card']);
  // const params: { id: string } = useParams();
  const userEid = useUserDataSelector((state) => state.eid);

  const userList = useReactiveVar(usersEntityObj);

  const [formDetails, setFormDetails] = useState<FormResponseDetails>();

  const [getFormResponse, { loading }] = useLazyQuery<
    Record<'FormById', FormDataById>
  >(FORM_BY_ID_RESPONSE_WITH_QUESTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _details: DetailsEntity = JSON.parse(JSON.stringify(details));

      const responseMap = mapUserFormResponse(
        toArray(data?.FormById?.questions),
        toArray(_details?.response)
      );

      setFormDetails({
        createdBy: _details.createdBy,
        data: responseMap,
      });
    },
    onError: (error) => {},
  });

  useEffect(() => {
    getFormResponse({
      variables: {
        eid: formId,
      },
    });
  }, []);

  const userName =
    formDetails?.createdBy?.eid === userEid
      ? 'Your'
      : formDetails?.createdBy?.name;

  return (
    <Modal isOpen={isOpen} onClose={() => onClose('close')} size='6xl'>
      <ModalOverlay />
      <ModalContent p={'6'}>
        <ModalHeader>
          <GenericColorHeader
            title={`${userName || ''} Response`}
            color='#b1e5fc'
          />
        </ModalHeader>
        <ModalCloseButton m={'6'} />
        <ModalBody>
          <Flex gap='16px'>
            <UserFormResponse
              loading={loading}
              response={formDetails?.data}
              userList={userList}
              userName={formDetails?.createdBy?.name!}
            />
            {!loading && (
              <Flex
                flexDir='column'
                pl={4}
                maxW='340px'
                w='full'
                gap='10px'
                minH='600px'
                maxH='800px'
              >
                <div style={{ paddingBottom: '30px' }}>
                  <HStack align='flex-start' spacing='15px'>
                    <Box>
                      <Image
                        height={80}
                        width={80}
                        style={{
                          minWidth: '80px',
                          maxWidth: '80px',
                          minHeight: '80px',
                          maxHeight: '80px',
                        }}
                        className='img-card-user'
                        src={getImage(
                          details?.createdBy?.profilePic,
                          details?.createdBy?.name
                        )}
                      />
                    </Box>

                    <VStack align='stretch'>
                      <Text
                        fontWeight={600}
                        fontSize='18px'
                        m={0}
                        isTruncated
                        maxW='200px'
                      >
                        {details?.createdBy?.name}
                      </Text>
                      <Text
                        fontWeight={600}
                        my={-6}
                        fontSize='13px'
                        color='#9A9FA5'
                      >
                        {details?.createdBy?.role}
                      </Text>
                    </VStack>
                  </HStack>
                </div>

                <div>
                  <HStack spacing='15px'>
                    <Box>
                      <img className='iconmaillight' src={mailIcon} />
                    </Box>

                    <VStack spacing={-6} align='stretch'>
                      <Box w='max-content'>
                        <div
                          className='faheydesignerrobotco'
                          style={{ width: '100%' }}
                        >
                          {details?.createdBy?.email
                            ? details?.createdBy?.email
                            : 'N/A'}
                        </div>
                      </Box>
                    </VStack>
                  </HStack>
                </div>
                <Divider className='Line-2' />
                <div>
                  <HStack spacing='15px'>
                    <Box>
                      <img className='iconmaillight' src={shieldIcon} />
                    </Box>

                    <VStack spacing={-6} align='stretch'>
                      <Box w='max-content'>
                        <div
                          className='faheydesignerrobotco'
                          style={{ width: '100%', textTransform: 'capitalize' }}
                        >
                          {details?.createdBy?.authRole || 'N/A'}
                        </div>
                      </Box>
                    </VStack>
                  </HStack>
                </div>
                <Divider className='Line-2' />
                <div>
                  <HStack spacing='15px'>
                    <Box>
                      <img className='iconmaillight' src={locationIcon} />
                    </Box>
                    <VStack spacing={-6} align='stretch'>
                      <Box w='max-content'>
                        <div
                          className='faheydesignerrobotco'
                          style={{ width: '100%' }}
                        >
                          {details?.createdBy?.locations?.[0]?.name || 'N/A'}
                        </div>
                      </Box>
                    </VStack>
                  </HStack>
                </div>
                <Divider className='Line-2' />
                {userEid !== details?.createdBy?.eid && (
                  <div style={{ marginBottom: '20px' }}>
                    <MessageButton
                      width='full'
                      size='lg'
                      colorScheme='blue'
                      onClick={() => {
                        setShowChat(true);
                        onClose('other');
                      }}
                    />
                  </div>
                )}
              </Flex>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FormResponseModal;

import React, { FC, useMemo } from 'react';
import { Box, Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { UserSignature } from '../../../../../types';
import { signatureConfigs } from '../../../../../configs';

interface IProps extends UserSignature {
  isSelected?: boolean;
  onClick: () => void;
  signConfig?: typeof signatureConfigs[number];
}

export const BaseSignature = forwardRef<
  Pick<IProps, 'isSelected' | 'signConfig'> & ButtonProps,
  'button'
>(({ isSelected, signConfig, children, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      fontWeight='unset'
      variant='outline'
      borderWidth='2px'
      justifyContent='start'
      colorScheme={isSelected ? 'blue' : undefined}
      {...props}
    >
      <Box noOfLines={1} {...signConfig}>
        {children}
      </Box>
    </Button>
  );
});

const Signature: FC<IProps> = ({ text, font, isSelected, onClick }) => {
  const config = useMemo(() => {
    return signatureConfigs.find((value) => value.fontFamily === font);
  }, [font]);

  return (
    <BaseSignature
      isSelected={isSelected}
      signConfig={config}
      onClick={onClick}
    >
      {text}
    </BaseSignature>
  );
};

export default Signature;

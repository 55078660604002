import React, { FC, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new';
import { useTranslation } from 'react-i18next';
import { gql, useQuery, useReactiveVar } from '@apollo/client';

import { userObj } from 'sop-commons/src/client';

import { AuthRole } from 'authorization';
import { toArray } from 'utils/utils';
import { BoxHeader } from 'ui-components';

import {
  AnalyticBase,
  AxisPercentageDomain,
  getCompleteRate,
  toPercent,
} from '../../common';

import RoleSelect from './RoleSelect';
import { MemberVariable } from '../assignee/performance.graphql';
import { getToolTipConfig, GraphLoader } from '../../common/graph';

interface Variable extends MemberVariable {
  role: string;
}

interface GraphEntity {
  location: string;
  completeRate: number;
}

interface Response {
  EntityUser: Array<{
    eid: string;
    name: string;
  }>;
  TaskRolesLocationWisePerformance: Record<string, AnalyticBase>;
}

const TASK_ANALYTICS = gql`
  query TaskRolesLocationWisePerformance(
    $entityId: String!
    $role: String!
    $type: [userTypeEnum]
    $status: [userStatusEnum]
  ) {
    EntityUser(entityId: $entityId, type: $type, status: $status) {
      eid
      name
    }

    TaskRolesLocationWisePerformance(entityId: $entityId, role: $role)
  }
`;

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor='end'
        fill='#666'
        transform='rotate(-90)'
      >
        {payload.value.length > 9
          ? `${payload.value.substring(0, 9)}...`
          : payload.value}
      </text>
    </g>
  );
};

interface IProps {}

const JobLocationDistributionGraph: FC<IProps> = () => {
  const { t } = useTranslation(['task']);

  const entityId = useReactiveVar(userObj)?.entityId;
  const defaultRole = useReactiveVar(userObj)?.role;
  const loggenInUserAuthRole = useReactiveVar(userObj)?.authRole;
  const locationDescendants = useReactiveVar(userObj)?.locations || [];

  const [selectedRole, setSelectedRole] = useState<string>();

  React.useEffect(() => {
    if (defaultRole && !selectedRole) {
      setSelectedRole(defaultRole);
    }
  }, [defaultRole, selectedRole]);

  const { data, loading } = useQuery<Response, Variable>(TASK_ANALYTICS, {
    fetchPolicy: 'network-only',
    skip: !entityId || !selectedRole,
    variables: {
      entityId: entityId,
      role: selectedRole!,
      status: ['active', 'inactive'],
      type: ['branch'],
    },
  });

  const graphData = useMemo(() => {
    const performance = data?.TaskRolesLocationWisePerformance;
    const entityUser = data?.EntityUser || [];

    const matchedEntityUsers = entityUser.filter((user) =>
      locationDescendants.some((descendant) => descendant.eid === user.eid)
    );

    return toArray(
      loggenInUserAuthRole === AuthRole.LOCATION_OWNER
        ? matchedEntityUsers
        : entityUser
    )
      .reduce<GraphEntity[]>((acc, loc) => {
        const value = performance?.[loc.eid];

        if (value) {
          acc.push({
            location: loc.name,
            completeRate: getCompleteRate(value),
          });
        }

        return acc;
      }, [])
      .sort((a, b) => {
        return b.completeRate - a.completeRate;
      })
      .slice(0, 12);
  }, [data]);

  return (
    <Flex
      flex={1}
      flexDir='column'
      borderRadius='10px'
      padding='16px 20px'
      bg='white'
      gap='24px'
    >
      <Flex flexDir='column' justify='space-between' gap='10px'>
        <Flex justify='space-between' gap='10px'>
          <BoxHeader
            title={t('task:jobLocationDistribution')}
            fontSize='18px'
            color='#B5E4CA'
          />

          <RoleSelect selected={selectedRole} onChange={setSelectedRole} />
        </Flex>

        {selectedRole && (
          <Flex
            border='1px solid #8E59FF'
            bg='rgba(202, 189, 255, 0.2)'
            borderRadius='full'
            align='center'
            padding='6px 10px'
            gap={2}
            width='max-content'
          >
            <Box fontSize='12px' fontWeight='500' color='#8E59FF'>
              {selectedRole}
            </Box>
          </Flex>
        )}
      </Flex>

      <GraphLoader isLoading={loading} minHeight={400}>
        <ResponsiveContainer width='100%' height={400}>
          <BarChart
            data={graphData}
            barSize={24}
            barCategoryGap={16}
            margin={{
              bottom: 60,
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray='3 3' />
            <XAxis
              axisLine={false}
              interval={0}
              dataKey='location'
              height={60}
              tick={<CustomizedAxisTick />}
            />

            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={toPercent}
              domain={AxisPercentageDomain}
            />
            <Tooltip
              cursor={false}
              {...getToolTipConfig()}
              formatter={(value) => {
                return [`${value}% completed`, [] as any];
              }}
            />
            <Bar dataKey='completeRate' radius={4} fill='#5542F6' />
          </BarChart>
        </ResponsiveContainer>
      </GraphLoader>
    </Flex>
  );
};

export default JobLocationDistributionGraph;

import { gql } from '@apollo/client';

export const ADD_LOCATION = gql`
  mutation AddLocation($input: NewLocationInput) {
    addLocation(input: $input) {
      eid
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateUserById($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      eid
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation ChangeUsersPassword($eid: String!, $password: String!) {
    changeUsersPassword(eid: $eid, password: $password) {
      succeed
    }
  }
`;

export interface CityEntity {
  city: string;
  timezone: string;
}

export interface StateList {
  [key: string]: CityEntity[];
}

export interface CityStatesResponse {
  CityStates: {
    [key: string]: CityEntity[];
  };
}

export const GET_CITY_STATES_OF_US = gql`
  query CityStates {
    CityStates
  }
`;

import React, { FC, useContext, useState } from 'react';
import { Flex, Image, List, ListItem, Text, Tooltip } from '@chakra-ui/react';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import SupervisedTaskDetailsDataContext from '../../store/supervised-task-details-context';
import { getImage } from 'utils';
import { MessageIconButton } from 'atoms';
import { useHasChatAccess } from 'hooks/useChatRestriction';

const TaskSupervisors: FC = () => {
  const hasChatAccess = useHasChatAccess();
  const supervisorCtx = useContext(SupervisedTaskDetailsDataContext);
  const [selectedUserId, setSelectedUserId] = useState('');
  return (
    <Flex
      p={6}
      bg='white'
      borderRadius='8px'
      height={'100%'}
      flexDir='column'
      gap='10px'
    >
      <GenericColorHeader title='Supervisors' color='#B1E5FC' />
      <List maxH='25vh' overflow='auto'>
        {supervisorCtx?.taskDetails?.supervisorsUsers?.map((user, index) => (
          <ListItem key={user?.eid}>
            <Flex align='center' justify='space-between'>
              <Flex align='center' gap='10px'>
                <Image
                  boxSize='40px'
                  borderRadius='full'
                  src={getImage(user?.profilePic, user?.name)}
                />
                <Tooltip
                  hasArrow
                  placement='top'
                  label={`${user?.name} (${user?.role} - ${user?.locations?.[0]?.name})`}
                >
                  <Flex flexDir={'column'}>
                    <Text isTruncated maxW='150px' fontWeight={600} m='0px'>
                      {user?.name}
                    </Text>
                    <Flex
                      align='center'
                      color='#92929D'
                      fontWeight={500}
                      fontSize='12px'
                      gap='5px'
                    >
                      <Text isTruncated maxW='100px' m='0px'>
                        {user?.role}
                      </Text>
                      <Text m='0px !important'>&bull;</Text>
                      <Text isTruncated maxW='100px' m='0px'>
                        {user?.locations?.[0]?.name}
                      </Text>
                    </Flex>
                  </Flex>
                </Tooltip>
              </Flex>

              <MessageIconButton
                hasChatAccess={hasChatAccess({
                  eid: user?.eid,
                  role: user?.role,
                  authRole: user?.authRole,
                })}
                onClick={() => setSelectedUserId(user?.eid)}
              />
            </Flex>
          </ListItem>
        ))}
      </List>
      {selectedUserId && (
        <SingleUserChatModal
          selectedUser={selectedUserId}
          onClose={() => setSelectedUserId('')}
        />
      )}
    </Flex>
  );
};

export default TaskSupervisors;

import styled from '@emotion/styled';

export const EditorWrapper = styled.div`
  display: flex;
  flex: 2;
  padding: 30px 44px;

  .chakra-form-control > div {
    display: flex;
    flex-direction: column;
    flex: 1;

    .editorWrapper {
      overflow: auto;
    }
  }
`;

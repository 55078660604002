import { useLazyQuery } from '@apollo/client';
import { ITaskPlay } from '../types/task-play.types';
import { TASK_PLAY } from './task-play.graphql';

export const getMyTasks = () => {
  const [execute, { loading, error, data }] = useLazyQuery<
    ITaskPlay,
    { eid: string }
  >(TASK_PLAY, { fetchPolicy: 'network-only' });
  return { execute, loading, error, data };
};

import { ICompareVisibilityType } from './visibilityHelper';

export const parentANDChildOR = (
  parent: any,
  child: any
): ICompareVisibilityType => {
  console.log({ PARENT_AND_CHILD_OR: { parent, child } });
  console.log({ PARENT_AND_CHILD_OR_FLAG: 'parentChildNoIntersection' });
  return 'parentChildNoIntersection';
};

import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import PerformanceHeader from '../assignee/PerformanceHeader';
import ListContainer from './ListContainer';
import JobPerformanceBarGraph from '../../../../../pages/TasksDashboard/Components/graphs/JobPerformanceBarGraph';
import JobCompleteRateGraph from './JobCompleteRateGraph';
import JobLocationDistributionGraph from './JobLocationDistributionGraph';

interface IProps {}

const JobsTaskPerformance: FC<IProps> = () => {
  const { t } = useTranslation(['task']);
  return (
    <Flex flexDir='column' gap='20px'>
      <PerformanceHeader title={t('task:jobPerformance')} />

      <Flex gap='20px'>
        <JobPerformanceBarGraph />
        <JobLocationDistributionGraph />
      </Flex>

      <JobCompleteRateGraph />

      <ListContainer />
    </Flex>
  );
};

export default JobsTaskPerformance;

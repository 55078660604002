import { FC } from 'react';
import checkmark from '../../assets/images/checkmark.webp';

interface IProps {
  title: string;
}

const FeatureItem: FC<IProps> = ({ title }) => {
  return (
    <div className='feature-item-container'>
      <div className='feature-item-icon'>
        <img src={checkmark} alt='checkmark' />
      </div>
      <div className='feature-item-title-container'>{title}</div>
    </div>
  );
};

export default FeatureItem;

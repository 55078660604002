import { useState } from 'react';
import moment from 'moment/moment';
import { GraphMode } from '../performance.types';

export interface IGraphRange {
  type: GraphMode;
  startDate: string;
  endDate: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGraphRangeVariable = () => {
  return useState<IGraphRange>({
    type: 'monthly',
    startDate: moment().startOf('year').toISOString(),
    endDate: moment().endOf('year').toISOString(),
  });
};

import React, { FC } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ITitleEdit, TitleForm } from '../common';

interface IProps {
  addNewSection: (title: string) => void | Promise<void>;
}

const AddNewList: FC<IProps> = ({ addNewSection }) => {
  const { t } = useTranslation(['common', 'launcher']);
  const { setValue } = useFormContext<ITitleEdit>();

  const sectionTitle = useWatch<ITitleEdit, 'sectionTitle'>({
    name: 'sectionTitle',
  });

  if (sectionTitle === -2) {
    return (
      <Popover isLazy isOpen placement='bottom-end'>
        {/*// @ts-ignore */}
        <PopoverTrigger>
          <Flex width='300px' minW='300px' />
        </PopoverTrigger>
        <PopoverContent borderRadius='12px' width='440px' boxShadow='md'>
          <PopoverBody px={4}>
            <TitleForm
              onSubmit={addNewSection}
              onClose={() => setValue('sectionTitle', undefined)}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Flex
      id='add-launcher-section-btn' // id is being used to focus the element
      gap={3}
      p={4}
      bg='#FFFFFF'
      align='center'
      borderRadius='12px'
      cursor='pointer'
      width='300px'
      minW='300px'
      onClick={() => setValue('sectionTitle', -2)}
    >
      <FontAwesomeIcon size='lg' icon={faPlus as IconProp} color='#6F767E' />
      <Box fontSize='16px' fontWeight='600' color='#1A1D1F'>
        {t('launcher:addNewPhase')}
      </Box>
    </Flex>
  );
};

export default AddNewList;

import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useUserEntity } from '../../hooks';

import DashboardContainer from '../../sub-components/DashboardContainer';
import ChatContent from './ChatContent';

import './Chat.scss';
import ChatFeatureNotAvailable from './ChatFeatureNotAvailable';

interface IProps {}

const ChatContainer: FC<IProps> = () => {
  const { t } = useTranslation(['sidebar']);
  const isChatEnabled = useUserEntity((entity) => entity?.features?.chat);

  return (
    <DashboardContainer>
      <div className='settings-title-wrapper'>
        <Box fontSize='28px' fontWeight='700' mb='17px' flex='1 1 auto'>
          {t('sidebar:chat')}
        </Box>
      </div>
      <div className='chat-container'>
        {!isChatEnabled ? <ChatFeatureNotAvailable /> : <ChatContent />}
      </div>
    </DashboardContainer>
  );
};

export default ChatContainer;

import { gql, useMutation, useReactiveVar } from '@apollo/client';
import {
  Box,
  Center,
  Divider,
  Flex,
  List,
  ListItem,
  Text,
  useToast,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolder, faFolderUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, SearchInput } from 'atoms';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import { chakraComponents } from 'chakra-react-select';
import { useFolderSubFolder } from 'hooks';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { GET_FOLDERS_LIST } from 'pages/Chapters/chapters.graphql';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { getFoldersList } from 'shared/graphql/SharedGraphql';
import { CategoryEntity } from 'sop-commons/src/client';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import EmptyState from 'sub-components/EmptyState';

export const BULK_MOVE = gql`
  mutation BulkMoveContent($input: MoveContentTypeInput) {
    bulkMoveContent(input: $input) {
      succeed
    }
  }
`;

interface IProps {
  selectedData: { eid: string; type: string }[];
  selectedFolder?: string;
  type?: 'folder' | 'subFolder';
  onCloseHandler: (data?: CategoryEntity) => void;
}

export interface CustomCategoryEntity extends CategoryEntity {
  type: 'folder' | 'subFolder';
  parentFolderId?: string;
  parentFolderName?: string;
}

const BulkMoveContainer: FC<IProps> = ({
  selectedData,
  selectedFolder,
  type,
  onCloseHandler,
}) => {
  console.log('BULK MOVE CONTAINER : type :', type);
  const { t } = useTranslation(['handbook']);
  const [searchQuery, setSearchQuery] = useState('');
  const [typeFilter, setTypeFilter] = useState<SelectOption | null>(null);
  const { getAllFolderSubFolderList } = useFolderSubFolder();
  const [selectedItem, setSelectedItem] = useState<CategoryEntity>(
    {} as CategoryEntity
  );
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const foldersList = useReactiveVar(foldersListVar);

  const onFetchFoldersListSuccess = () => {
    onCloseHandler(selectedItem);
  };

  const { execute: fetchFoldersList, loading: fetchingFoldersList } =
    getFoldersList(onFetchFoldersListSuccess);

  const [moveItems, { loading }] = useMutation(BULK_MOVE, {
    onCompleted: (data) => {
      toast({
        status: 'success',
        title: 'Items moved successfully',
      });
      fetchFoldersList();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Items could not be moved',
      });
      onCloseHandler();
    },
  });

  const itemsList = useMemo(() => {
    let _allFolderSubFolders: CustomCategoryEntity[] =
      getAllFolderSubFolderList();
    let allItemsList: CustomCategoryEntity[] = [];
    if (type === 'subFolder') {
      allItemsList = _allFolderSubFolders;
    } else {
      allItemsList = _allFolderSubFolders?.filter(
        (item) => item?.type === 'folder'
      );
    }
    // let allFolderSubFolders: CategoryEntity[] = foldersList;

    let filteredFolderSubFolderList = allItemsList.filter(
      (all) => all.eid !== selectedFolder
    );

    if (typeFilter) {
      filteredFolderSubFolderList = filteredFolderSubFolderList.filter(
        (item) => {
          if (typeFilter.value === 'all') return true;
          if (typeFilter.value === 'subFolders')
            return item.type === 'subFolder';
          if (typeFilter.value === 'folders') return item.type === 'folder';
          return false;
        }
      );
    }

    if (searchQuery) {
      filteredFolderSubFolderList = filteredFolderSubFolderList.filter((item) =>
        item.name
          .toLowerCase()
          .trim()
          .includes(searchQuery.toLowerCase().trim())
      );
    }

    return filteredFolderSubFolderList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }, [getAllFolderSubFolderList, searchQuery, selectedFolder]);

  const moveHandler = () => {
    let selectedItemIds = selectedData?.map((data) => data?.eid);
    if (selectedItemIds?.length > 0) {
      moveItems({
        variables: {
          input: {
            categoryId: selectedItem?.eid,
            contents: selectedItemIds,
          },
        },
      });
    }
  };

  const onTypeFilterChange = (value: SelectOption<string> | null) => {
    setTypeFilter(value);
  };

  return (
    <Flex flexDir='column' w='full' gap={4} pb={4}>
      <Flex mx={8} align='center' gap={2}>
        <Flex w='full'>
          <SearchInput
            placeholder={
              type === 'subFolder'
                ? 'Search folder or subfolder'
                : 'Search folder name'
            }
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
            hideShortcuts
          />
        </Flex>
        {type === 'subFolder' && (
          <Flex w='30%'>
            <Dropdown
              inputStyle={{ width: '130px' }}
              placeholder={t('handbook:type')}
              value={typeFilter}
              onChange={onTypeFilterChange}
              options={[
                {
                  label: 'All',
                  value: 'all',
                },
                {
                  label: 'Folders',
                  value: 'folders',
                },
                {
                  label: 'Subfolders',
                  value: 'subFolders',
                },
              ]}
              selectStyles={{
                singleValue: {
                  color: '#6f767e',
                  fontWeight: '600',
                },
              }}
              components={{
                SingleValue: (valProps) => {
                  return (
                    <chakraComponents.SingleValue {...valProps}>
                      {/* @ts-ignore */}
                      {t('handbook:type_by', {
                        value: valProps.children,
                      })}
                    </chakraComponents.SingleValue>
                  );
                },
              }}
            />
          </Flex>
        )}
      </Flex>
      {searchQuery && itemsList?.length === 0 && (
        <EmptyState
          image='Search'
          title='No folder found.'
          description='Oops! Try a different search to get results.'
        />
      )}
      {itemsList?.length > 0 && (
        <List overflowY='auto' maxH='40vh'>
          {itemsList?.map((list) => (
            <ListItem
              id='b-m-c-l-item'
              key={list?.eid}
              bg={selectedItem?.eid === list?.eid ? '#2A85FF1F' : 'none'}
              w='full'
              cursor='pointer'
              onClick={() => setSelectedItem(list)}
            >
              <Flex mx={8} flexDir='column' justify='center'>
                <Flex align='center' justify='space-between' m='10px 0px'>
                  <Flex align='center' gap={2}>
                    <Center
                      boxSize='32px'
                      border='1px solid #DDDDDD'
                      borderRadius='5px'
                    >
                      <FontAwesomeIcon
                        icon={
                          (list?.visibility === 'private'
                            ? faFolderUser
                            : faFolder) as IconProp
                        }
                        color='#6F767E'
                        fontSize='15px'
                      />
                    </Center>
                    <Flex align='center' gap={1}>
                      <Text isTruncated maxW='150px'>
                        {list?.name}
                      </Text>
                      {list?.type === 'subFolder' && (
                        <Flex gap={1}>
                          <Text>in</Text>
                          <Text fontWeight={700} isTruncated maxW='150px'>
                            {list?.parentFolderName}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                  <Text fontWeight={500} fontSize='12px' color='#979EA6'>
                    {list?.type === 'folder' ? 'Folder' : 'Subfolder'}
                  </Text>
                </Flex>
                <Divider />
              </Flex>
            </ListItem>
          ))}
        </List>
      )}
      <Flex mx={8}>
        <PrimaryButton
          title={selectedItem?.name ? `Move to ${selectedItem?.name}` : 'Move'}
          colorScheme='green'
          variant='solid'
          disabled={loading || !selectedItem?.eid || fetchingFoldersList}
          isLoading={loading || fetchingFoldersList}
          onClick={moveHandler}
        />
      </Flex>
    </Flex>
  );
};

export default BulkMoveContainer;

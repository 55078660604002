import React, { FC } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react';

interface IProps {
  title: string;
  subTitle: React.ReactNode;
}

const BaseAccordionItem: FC<IProps> = ({ title, subTitle, children }) => {
  return (
    <AccordionItem
      borderTopWidth='0'
      borderColor='#EEEEEE'
      borderBottomWidth='1px'
    >
      <AccordionButton borderRadius='12px' px={0} py={5} _hover={{}}>
        <Flex flex={1} flexDir='column' align='start' gap='2px'>
          <Box fontSize='16px' fontWeight='600' color='#1A1D1F'>
            {title}
          </Box>
          <Box
            fontSize='14px'
            fontWeight='400'
            color='#6F767E'
            textAlign='start'
          >
            {subTitle}
          </Box>
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pt={0} px={0}>
        {children}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default BaseAccordionItem;

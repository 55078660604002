import React, { FC } from 'react';
import { Box, Button, Flex, Image, useClipboard } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import CopyIcon from '../../assets/images/copy-purple.svg';

interface IProps {
  name?: string;
  link?: string;
}

const ShareLoginLink: FC<IProps> = ({ link }) => {
  const { t } = useTranslation('location');
  const { onCopy, hasCopied } = useClipboard(link!);

  return (
    <Flex
      gap='8px'
      bg='rgba(202, 189, 255, 0.4)'
      borderRadius='10px'
      p='10px 16px'
      mb='12px'
      align='center'
    >
      <FontAwesomeIcon icon={faInfoCircle as IconProp} color='#6F767E' />
      <Box flex={1} fontSize='15px' fontWeight='500' color='#33383F'>
        <Trans t={t} i18nKey='linkAutoLogInYou' />
      </Box>
      <Button
        color='#8E59FF'
        fontSize='14px'
        height='32px'
        leftIcon={<Image src={CopyIcon} height='16px' width='16px' />}
        onClick={onCopy}
        bg='white'
        fontWeight='500'
      >
        {t(hasCopied ? 'copied' : 'copy_link')}
      </Button>
    </Flex>
  );
};

export default ShareLoginLink;

import React, { FC } from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';

interface IProps extends Pick<BoxProps, 'noOfLines' | 'fontSize'> {
  title: string;
  color?: BoxProps['bg'];
}

const PreviewHeader: FC<IProps> = ({
  title,
  color = '#b1e5fc',
  noOfLines,
  fontSize = '20px',
}) => {
  return (
    <Flex align='center'>
      <Box mr={4} h={8} w={4} minW={4} borderRadius={4} bg={color} />
      <Box
        fontWeight='600'
        fontSize={fontSize}
        lineHeight='32px'
        color='#1a1d1f'
        noOfLines={noOfLines}
      >
        {title}
      </Box>
    </Flex>
  );
};

export default PreviewHeader;

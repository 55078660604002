import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  BoxHeader,
  useConfirm,
  UseConfirm,
} from '../../../../../ui-components';

import TaskDeleteContent from './TaskDeleteContent';
import { ITaskItem } from '../../editor-form/editor.types';

interface IProps {
  nextTask?: ITaskItem;
  onDeletePress: (...args: any[]) => any | PromiseLike<any>;
}

type UsePostSetupTaskDelete = (props: IProps) => void;

export const usePostSetupTaskDelete = (): UsePostSetupTaskDelete => {
  const { t } = useTranslation(['common']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback(({ nextTask, onDeletePress }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex justify='space-between' align='center'>
          <BoxHeader title='Delete this task' color='#B5E4CA' />

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),

      content: <TaskDeleteContent nextTask={nextTask} />,

      isCentered: true,
      contentProps: {
        py: '16px',
        px: '12px',
        minW: '600px',
      },
      okButtonProps: {
        size: 'lg',
        colorScheme: 'red',
        width: '180px',
        fontSize: '15px',
      },
      cancelText: null,
      okText: t('common:delete'),
      onOK: onDeletePress,
    });
  }, []);
};

import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Card } from 'antd';

import FormActivity from './modules/FormActivity';
import FormHeader from './modules/FormHeader';
import FormListInternal from './modules/FormListInternal';

const FormList: FC = () => {
  return (
    <Flex direction='column'>
      <FormHeader />
      <Flex mt='20px' columnGap='1rem'>
        <Card
          style={{
            flex: 1,
            borderRadius: '12px',
          }}
        >
          <FormListInternal />
        </Card>
        <Box width='30%' height='fit-content' pos='sticky' top='0'>
          <FormActivity />
        </Box>
      </Flex>
    </Flex>
  );
};

export default FormList;

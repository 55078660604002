import React, { FC, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useLocation, useParams } from 'react-router-dom';

import BackButton from './BackButton';
import UserDetailsContainer from './UserDetailsContainer';
import {
  USER_DETAIL_QUERY,
  UserResponse,
  UserVariable,
} from './perfor-detail.graphql';
import { UserTasks } from './user-tasks';
import SingleUserChatModal from '../../../../ui-components/SingleUserChatModal';
import { LocationOwners } from './LocationOwners';

interface IProps {}

const PerformanceDetails: FC<IProps> = () => {
  const userId = useParams<{ userId: string }>()?.userId;
  const locState = useLocation<{ name?: string }>().state;
  const [chatUserId, setChatUserId] = useState<string>();

  const { loading, data } = useQuery<UserResponse, UserVariable>(
    USER_DETAIL_QUERY,
    {
      fetchPolicy: 'network-only',
      skip: !userId,
      variables: {
        eid: userId,
        userId: userId,
      },
    }
  );

  const progressValue = useMemo(() => {
    let tp = data?.UserTaskProgress;

    if (!tp) {
      return 0;
    }

    return Math.round((tp.complete / (tp.complete + tp.inComplete)) * 100);
  }, [data?.UserTaskProgress]);

  const userName = data?.userById?.name || locState?.name;

  return (
    <Box>
      <BackButton userName={userName} />

      <Flex gap={4} mt='20px'>
        <UserDetailsContainer
          loading={loading}
          progressLoading={loading}
          data={data?.userById}
          progressValue={progressValue}
          openChat={setChatUserId}
        />

        <LocationOwners
          locations={data?.userById?.locations}
          openChat={setChatUserId}
        />
      </Flex>

      <UserTasks
        userTimeZone={data?.userById?.timezone}
        userId={data?.userById?.eid}
        graphUserId={userId}
        userName={userName}
      />

      {chatUserId && (
        <SingleUserChatModal
          selectedUser={chatUserId}
          onClose={() => setChatUserId(undefined)}
        />
      )}
    </Box>
  );
};

export default PerformanceDetails;

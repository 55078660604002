import React, { FC, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IRoleData, IRoleMemberEntity } from '../roles-list.types';
import { userObj } from 'sop-commons/src/client';
import { SelectOption } from 'atoms/Dropdown';
import RoleSelect from './RoleSelect';
import AddRole from '../AddRole';
import { GET_USER } from 'pages/Login/login.graphql';
import { ROLES_MUTATION } from '../roles.graphql';
import { roleObj } from 'ui-components/DashboardMenu';

interface IFooterProps {
  roleData: IRoleData;
  members: string[];
  selectedAlignCenter?: boolean;
  onClose: () => void;
}

const MoveToRoleFooter: FC<IFooterProps> = ({
  roleData,
  members,
  selectedAlignCenter,
  onClose,
}) => {
  const { t } = useTranslation('role');
  const roleEntities = useReactiveVar(userObj)?.entity?.roles || [];
  const [value, setValue] = useState<SelectOption>();
  const [newRole, setNewRole] = useState(false);
  const toast = useToast();

  const [moveItems, { loading }] = useMutation(ROLES_MUTATION, {
    onCompleted: (data) => {
      toast({
        title: t('member_success_moves'),
        position: 'top-right',
        isClosable: true,
        duration: 3000,
        status: 'success',
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: t('member_not_move'),
        position: 'top-right',
        isClosable: true,
        duration: 3000,
        status: 'error',
      });
    },
  });

  const roleList = useMemo(() => {
    const rolesList: SelectOption[] = [];
    roleEntities?.forEach((item) => {
      if (item?.eid !== roleData?.eid) {
        rolesList.push({
          label: item?.name,
          value: item?.name,
        });
      }
    });
    return rolesList;
  }, [roleEntities]);

  const onSavePressHandler = () => {
    moveItems({
      variables: {
        deleteRole: roleData?.name,
        moveToRole: value?.label,
        userIds: members,
      },
    });
    // moveItems({
    //   variables: {
    //     input: {
    //       change: 'existing',
    //       role: value?.label,
    //       users: members,
    //     },
    //   },
    // });
  };

  const [getUser] = useLazyQuery(GET_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      userObj(data?.user || {});
      const roles = data?.user?.entity?.roles;
      if (roles && roles.length) {
        let roleWithColor: any = {};
        roles.forEach((role: any) => {
          roleWithColor[role.name] = role.color;
        });

        roleObj(roleWithColor);
      }
    },
  });

  return (
    <Box width='full'>
      <RoleSelect
        isLoading={loading}
        isDisabled={members?.length === 0}
        options={roleList}
        placeholder={t('select_role')}
        value={value}
        onChange={(newValue) => setValue(newValue as SelectOption)}
        onCreatePress={() => setNewRole(true)}
        onSavePress={onSavePressHandler}
        selectStyles={{
          container: {
            background: '#2A85FF',
          },
          singleValue: {
            color: '#FCFCFC',
          },
          dropdownIndicator: {
            svg: {
              color: '#FCFCFC',
            },
          },
        }}
        selectedAlignCenter={selectedAlignCenter}
      />
      <AddRole
        open={newRole}
        type='add'
        onClose={() => setNewRole(false)}
        zIndex={2002}
        onRoleCreate={(value: string) => {
          setValue({ label: value, value: value });
          getUser({ variables: {} });
        }}
      />
    </Box>
  );
};

export default MoveToRoleFooter;

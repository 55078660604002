import { toArray } from './authorization.utils';
import { AuthUser, Permission } from './authorization.types';

function checkPermittedRoles<T>(
  userRole: T | T[],
  permittedRoles: T[]
): boolean {
  if (permittedRoles.length === 0) return true;

  const _userRoles = toArray(userRole);

  return _userRoles.some((role) => permittedRoles.includes(role));
}

function checkRestrictedRoles<T>(
  userRole: T | T[],
  restrictedRoles: T[]
): boolean {
  if (restrictedRoles.length === 0) return false;

  const _userRoles = toArray(userRole);

  return _userRoles.some((role) => restrictedRoles.includes(role));
}

export const checkPermission = (
  authUser: AuthUser,
  permission: Permission
): boolean => {
  if (!permission) return true;
  const {
    permittedRoles = [],
    restrictedRoles = [],
    permittedFor,
    restrictedFor,
  } = permission;

  const _userType = toArray(authUser.type);

  const _restrictedFor = toArray(restrictedFor);

  if (checkRestrictedRoles(_userType, _restrictedFor)) {
    return false;
  }

  const _permittedFor = toArray(permittedFor);

  if (!checkPermittedRoles(_userType, _permittedFor)) {
    return false;
  }

  const _restrictedRoles = toArray(restrictedRoles);
  const restricted = checkRestrictedRoles(authUser.authRole, _restrictedRoles);

  if (restricted) return false;

  const _permittedRolesRoles = toArray(permittedRoles);

  return checkPermittedRoles(authUser.authRole, _permittedRolesRoles);
};

import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import BaseTaskForm, {
  ITaskInput,
} from '../../editor-form/section/BaseTaskForm';
import SelectTaskPhase, { TPhaseInput } from './SelectTaskPhase';

export interface TaskFormProps {
  index: number;
  initialValue?: ITaskInput & TPhaseInput;
  onUpdate: (data: ITaskInput & TPhaseInput) => Promise<void> | void;
  closeForm?: Function;
}

const TaskForm: FC<TaskFormProps> = ({
  index,
  initialValue,
  onUpdate,
  closeForm,
}) => {
  const onSubmit = async (values: ITaskInput) => {
    await onUpdate?.(values as never);
  };

  return (
    <Flex flexDir='column' gap={3}>
      <BaseTaskForm
        layout='vertical'
        isEdit={!!initialValue?.title?.trim()}
        isFirstTask={index === 0}
        initialValue={initialValue}
        onSubmit={onSubmit}
        phaseComponent={SelectTaskPhase}
        closeForm={closeForm}
      />
    </Flex>
  );
};

export default TaskForm;

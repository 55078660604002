import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import flagIcon from '../../../../assets/images/flagIcon.png';
import './ManagerTrainingStatusList.scss';
import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IListProps {
  trainingData: any;
}

const ManagerTrainingStatusList = ({ trainingData }: IListProps) => {
  return (
    <div>
      {trainingData?.map((expand: any) => {
        return (
          <div
            key={expand?.eid}
            className='manager-training-status-list-container'
          >
            {(expand?.type === 'card' || expand?.type === 'sop') && (
              <>
                <div className='card-deck-list-container'>
                  <img className='list-img' src={expand?.thumbnail} />
                  <div className='text-container'>
                    <p className='text-type'>{expand?.type}</p>
                    <p className='text-content'>
                      {expand?.title}{' '}
                      {expand?.type === 'deck' && (
                        <span style={{ marginLeft: '10px' }}>{`(${
                          expand?.cards?.length || 0
                        } card${expand?.cards?.length < 2 ? '' : 's'})`}</span>
                      )}
                    </p>
                  </div>
                </div>
                {expand?.completed && (
                  <FontAwesomeIcon
                    icon={faCircleCheck as IconProp}
                    className='completed-part'
                  />
                )}
              </>
            )}
            {expand?.type === 'milestone' && (
              <>
                <div className='milestone-container'>
                  <div className='icon-title-container'>
                    <div className='icon-container'>
                      <img className='icon' src={flagIcon} />
                    </div>
                    {expand?.title}
                  </div>
                  {expand?.completed && (
                    <FontAwesomeIcon
                      className='icon-completed'
                      icon={faCircleCheck as IconProp}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ManagerTrainingStatusList;

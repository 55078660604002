import React, { FC } from 'react';
import { Question as QuestionData } from '../../create-form/form.types';

interface IProps {
  question: QuestionData;
}

const Section: FC<IProps> = ({ question }) => {
  return <div className='custom-form-preview-section'>{question.label}</div>;
};

export default Section;

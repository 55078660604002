import React from 'react';
import { cloneDeep } from '@apollo/client/utilities';
import { t } from 'i18next';
import moment from 'moment';
import { SortByDate } from 'utils/sorting';
import ProgressCell from './ProgressCell';

type ISetProgressData = React.Dispatch<
  React.SetStateAction<{
    allMilestoneData: any[];
    showEmptyState: boolean;
    userData: any;
    trainingName?: string | undefined;
  }>
>;

type ISetProgressTimelineModal = React.Dispatch<React.SetStateAction<boolean>>;

export function SortByProgress(a: any, b: any, c: string) {
  const valA = a?.values?.[c]?.item?.['totalViewedCount'];
  const valB = b?.values?.[c]?.item?.['totalViewedCount'];
  if (valA < valB) {
    return 1;
  } else if (valA > valB) {
    return -1;
  } else {
    return 0;
  }
}

export function filterCard(val: any) {
  return val?.type === 'card' || val?.type === 'sop';
}

const processedUserDataHandler = (
  _userData: any,
  trainingPathData: any,
  processedUserData: any[]
) => {
  if (_userData?.userProgress && _userData?.userProgress?.[0]?.viewedContents) {
    trainingPathData?.trainingContentTiny?.forEach((card: any) => {
      let userViewedDate = '';
      const viewedContent = _userData?.userProgress?.[0]?.viewedContents?.find(
        (content: any) => {
          if (content?.uid === card?.eid) {
            userViewedDate = content?.lastViewedAt;
            return true;
          }
        }
      );
      const newCard = {
        ...card,
        completed: viewedContent ? true : false,
        status: viewedContent ? true : false,
        watchDetails: {
          contentLastUpdated: trainingPathData?.updatedAt,
          userLastWatched: userViewedDate,
          didUserWatchedLatestContent: card?.updatedAt
            ? new Date(userViewedDate) >= new Date(card?.updatedAt)
            : true,
        },
      };
      processedUserData?.push(newCard);
    });
  }
};

const convertToMoments = (_userData: any) => {
  let moments = (_userData?.userProgress?.[0]?.viewedContents &&
    _userData?.userProgress?.[0]?.viewedContents?.map((content: any) => {
      return moment(content?.lastViewedAt);
    })) || [moment()];
  return moments;
};

const progressStateUpdateHandler = (
  processedUserData: any[],
  _userData: any,
  trainingPathData: any,
  maxDate: string,
  totalViewRoot: any,
  setProgressData: ISetProgressData,
  setProgressTimelineModal: ISetProgressTimelineModal
) => {
  processedUserData?.push({
    status:
      _userData?.userProgress?.[0]?.viewedContents?.length ===
      trainingPathData?.trainingContentTiny?.length
        ? true
        : false,
    date: maxDate,
    lastViewedAt: _userData?.userProgress?.[0]?.lastViewed,
    contentLastUpdate: trainingPathData?.updatedAt,
  });
  setProgressData({
    allMilestoneData: processedUserData,
    showEmptyState: !totalViewRoot,
    userData: _userData,
    trainingName: _userData?.title,
  });
  setProgressTimelineModal(true);
};

export const progressDataHandler = (
  userData: any,
  item: any,
  trainingPathData: any,
  setUserName: React.Dispatch<React.SetStateAction<string>>,
  setProgressData: ISetProgressData,
  setProgressTimelineModal: ISetProgressTimelineModal
) => {
  let _userData = cloneDeep(userData);
  setUserName(_userData?.name || 'User');
  const totalViewRoot = _userData?.totalViewedCount || 0;
  const processedUserData: any[] = [];
  processedUserDataHandler(_userData, trainingPathData, processedUserData);
  const moments = convertToMoments(_userData);
  let maxDate = moment?.max(moments)?.format('DD MMM YYYY, hh:mm A');
  progressStateUpdateHandler(
    processedUserData,
    _userData,
    trainingPathData,
    maxDate,
    totalViewRoot,
    setProgressData,
    setProgressTimelineModal
  );
};

export const getColumns = (
  isTablet: boolean,
  trainingPathData: any,
  setUserName: React.Dispatch<React.SetStateAction<string>>,
  setProgressData: React.Dispatch<
    React.SetStateAction<{
      allMilestoneData: any[];
      showEmptyState: boolean;
      userData: any;
      trainingName?: string | undefined;
    }>
  >,
  setProgressTimelineModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let tableColumns: Array<any> = [
    {
      Header: t('training:assignee'),
      accessor: 'userInfo' as const,
      width: '25%',
    },
    {
      Header: t('common:status'),
      accessor: 'status' as const,
      width: '16%',
      disableSortBy: true,
    },
    {
      Header: t('training:training_details_section.assign_date_heading'),
      accessor: 'assignDate',
      width: '16%',
      Cell: ({ cell: { value } }: any) => {
        if (moment(value)?.isValid()) {
          return (
            <div
              style={{
                marginBottom: '25px',
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              {moment(value)?.format('DD MMM YYYY')}
            </div>
          );
        }
        return <div style={{ color: '#9A9FA5', paddingLeft: 36 }}>-</div>;
      },
      sortType: SortByDate,
    },
    {
      Header: t('training:training_details_section.current_progress_heading'),
      accessor: 'currentProgress' as const,
      width: '16%',
      Cell: ({ cell: { value } }: any) => {
        return (
          <ProgressCell
            onClick={(_userData: any) =>
              progressDataHandler(
                _userData,
                value?.item,
                trainingPathData,
                setUserName,
                setProgressData,
                setProgressTimelineModal
              )
            }
            item={value?.item}
            maxLength={value?.maxLength}
          />
        );
      },
      sortType: SortByProgress,
    },
    {
      Header: t('training:training_details_section.start_date_heading'),
      accessor: 'startDate',
      width: '16%',
      Cell: ({ cell: { value } }: any) => {
        return (
          <div
            style={{
              marginBottom: '25px',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            {value ? (
              moment(value)?.format('DD MMM YYYY')
            ) : (
              <div style={{ color: '#9A9FA5', paddingLeft: 36 }}>-</div>
            )}
          </div>
        );
      },
      sortType: SortByDate,
    },
    {
      Header: '',
      accessor: 'action' as const,
      width: '11%',
      disableSortBy: true,
    },
  ];
  return tableColumns;
};

export const searchHandler = (
  query: string,
  setTrainingPathData: React.Dispatch<any>,
  copyTrainingPathData: any,
  setAssigneesTrainingPathData: React.Dispatch<any>,
  copyAssigneeTrainingPathData: any,
  setGuestsTrainingPathData: React.Dispatch<any>,
  copyGuestTrainingPathData: any,
  selectedViewOption: {
    label: string;
    value: string;
  }
) => {
  if (query === '') {
    setTrainingPathData(copyTrainingPathData);
    setAssigneesTrainingPathData(copyAssigneeTrainingPathData);
    setGuestsTrainingPathData(copyGuestTrainingPathData);
  } else {
    let tempTrainingPathData =
      selectedViewOption?.value === 'All'
        ? JSON.parse(JSON.stringify(copyTrainingPathData))
        : selectedViewOption?.value === 'Assignees'
        ? JSON.parse(JSON.stringify(copyAssigneeTrainingPathData))
        : JSON.parse(JSON.stringify(copyGuestTrainingPathData));
    const reg = new RegExp(query, 'gi');
    let filteredTrainingPathData =
      tempTrainingPathData?.assignedToUsers?.filter((userData: any) => {
        (userData?.isTrainingCompleted ? 'complete' : 'pending')?.match(reg);
        return (
          userData?.name?.match(reg) ||
          userData?.role?.match(reg) ||
          userData?.authRole?.match(reg) ||
          userData?.address?.match(reg) ||
          (userData?.isTrainingCompleted ? 'complete' : 'pending')?.match(reg)
        );
      });
    tempTrainingPathData.assignedToUsers = filteredTrainingPathData;
    selectedViewOption?.value === 'All'
      ? setTrainingPathData(tempTrainingPathData)
      : selectedViewOption?.value === 'Assignees'
      ? setAssigneesTrainingPathData(tempTrainingPathData)
      : setGuestsTrainingPathData(tempTrainingPathData);
  }
};

export const getCustomTrainingDataHandler = (trainingPathData: any) => {
  let _trainingData = JSON.parse(JSON.stringify(trainingPathData));
  let _contents: any[] = [];
  let _fullContentDetails: any[] = [];
  _trainingData?.contentDetails?.forEach((content: any) => {
    if (content?.type !== 'deck') {
      _fullContentDetails?.push(content);
    } else {
      content?.cards?.forEach((card: any) => {
        _fullContentDetails?.push(card);
      });
    }
  });
  _fullContentDetails?.filter((content: any) => {
    let found = _trainingData?.trainingContentTiny?.find(
      (expand: any) => expand?.eid === content?.eid
    );
    if (found) {
      _contents?.push(found);
    } else {
      _contents?.push(content);
    }
  });
  _trainingData.contentDetails = _contents;
  return _trainingData;
};

import React, { FC, useMemo } from 'react';
import { ButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../ui-components';

export interface InputButtonProps {
  actionFn?: (...args: any[]) => any | PromiseLike<any>;
  inviteSent?: boolean;
  alreadyUser?: boolean;
  isDisabled?: boolean;
}

const InputButton: FC<InputButtonProps> = ({
  actionFn,
  inviteSent,
  alreadyUser,
  isDisabled,
}) => {
  if (!alreadyUser) return null;

  const { t } = useTranslation('location');

  const config = useMemo((): ButtonProps => {
    if (alreadyUser) {
      return {
        variant: 'unstyled',
        padding: '0 12px',
        _disabled: {
          color: '#6F767E',
          // opacity: 0.4,
          boxShadow: 'none',
        },
      };
    }
    if (inviteSent) {
      return {
        variant: 'unstyled',
        padding: '0 12px',
        color: '#83BF6E',
      };
    }
    return {
      variant: 'solid',
      colorScheme: 'blue',
    };
  }, [alreadyUser, inviteSent]);

  return (
    <ActionButton
      width='fit-content'
      borderRadius='10px'
      height='36px'
      lineHeight='unset'
      size='sm'
      {...config}
      isDisabled={isDisabled}
      actionFn={isDisabled || inviteSent ? undefined : actionFn}
    >
      {t(
        alreadyUser
          ? 'alreadyAnExistingOwner'
          : inviteSent
          ? 'inviteBeenSent'
          : 'sendInvite'
      )}
    </ActionButton>
  );
};

export default InputButton;

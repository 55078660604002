import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';
import { FormCategory } from '../../../../types';

import EditFormCategoryForm from './EditFormCategoryForm';

interface IProps {
  category: FormCategory;
  onCategoryUpdated?: (category: FormCategory) => void;
  onDeleteClick?: () => void;
}

type EditFormCategory = (props: IProps) => void;

export const useEditFormCategory = (): EditFormCategory => {
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const apolloClient = useApolloClient();

  return useCallback(({ category, onCategoryUpdated, onDeleteClick }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },

      title: (
        <Flex justify='space-between'>
          <BoxHeader color='#CABDFF' title='Edit form category' />

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <EditFormCategoryForm
          onDeleteClick={onDeleteClick}
          category={category}
          onCategoryUpdated={onCategoryUpdated}
        />
      ),
      isCentered: true,
      footer: null,
      contentProps: {
        minWidth: '640px',
        borderRadius: '10px',
        padding: '16px 12px',
      },
    });
  }, []);
};

import { MemberTrainingItem } from '../types';
import { toArray } from '../../../../utils/utils';

export class MemberTrainingModal {
  protected _data: MemberTrainingItem;

  constructor(data: MemberTrainingItem) {
    this._data = data;
  }

  get eid() {
    return this._data.eid;
  }
  get name() {
    return this._data.name;
  }

  get profilePic() {
    return this._data.profilePic;
  }
  get role() {
    return this._data.role;
  }
  get authRole() {
    return this._data.authRole;
  }
  get address() {
    if (typeof this._data.location?.[0] === 'object') {
      return this._data.location?.[0]?.name;
    }
    return this._data.location?.[0];
  }

  get isExternal() {
    return this._data.isExternal;
  }

  get assignedTrainings() {
    return toArray(this._data.assignedTrainings);
  }

  get completedTrainings() {
    return toArray(this._data.completedTrainings);
  }

  get unfinishedTrainings() {
    return toArray(this._data.unfinishedTrainings);
  }

  get jsonData() {
    return this._data;
  }
}

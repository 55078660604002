export const orderHeaders = [
    {
        heading: 'First Name',
        keyName: 'firstName',
    },
    {
        heading: 'Last Name',
        keyName: 'lastName',
    },
    {
        heading: 'Email',
        keyName: 'email',
    },
    {
        heading: 'Phone',
        keyName: 'phone',
    },
    {
        heading: 'Primary User ID',
        keyName: 'primaryUserId',
    },
    {
        heading: 'AccessLevel',
        keyName: 'accessLevel',
    },
    {
        heading: 'role',
        keyName: 'Role',
    },
    {
        heading: 'addedOn',
        keyName: 'Added On',
    },
    {
        heading: 'lastActive',
        keyName: 'Last Active',
    },
];
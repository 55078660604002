import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { LocationFieldEntity } from 'sop-commons/src/client';

import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import AddLocationFieldForm from './AddLocationFieldForm';

interface IProps {
  locationField?: LocationFieldEntity;
  onFieldAdded?: () => void;
}

type UseAddLocationField = (props: IProps) => void;

export const useAddLocationField = (): UseAddLocationField => {
  const { t } = useTranslation(['setting']);
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const apolloClient = useApolloClient();

  return useCallback(({ onFieldAdded, locationField }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader
            title={t(
              locationField ? 'setting:editField' : 'setting:addNewFields'
            )}
            color='#CABDFF'
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <AddLocationFieldForm
          locationField={locationField}
          onFieldAdded={onFieldAdded}
        />
      ),
      size: '2xl',
      isCentered: true,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      footer: null,
    });
  }, []);
};

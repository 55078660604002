import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../atoms/FormInput';

import TitleHeader from '../TitleHeader';

import { CardFormInput } from '../card-editor.types';
import ImageLayoutSelect from './ImageLayoutSelect';
import ImageFieldComponent from '../common/ImageFieldComponent';
import { LayoutImageCount } from 'sop-commons/src/utils/cardLayouts';

const ImageCardEditor: FC = () => {
  const { t } = useTranslation('card');
  const { control, setValue, getValues } = useFormContext<CardFormInput>();

  return (
    <>
      <Controller
        name={'layout'}
        control={control}
        rules={{
          required: t('validation.img_layout_required'),
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <ImageLayoutSelect
                layout={field.value as any}
                onSelectLayout={(layout) => {
                  field.onChange(layout);
                  const images = getValues('images') || [];
                  const maxImage = LayoutImageCount[layout];
                  setValue(
                    'images',
                    images.filter((_, index) => index < maxImage)
                  );
                }}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <TitleHeader
        title={t('media')}
        desc={t('media_desc')}
        titleStyle={{ marginTop: '30px' }}
        isRequired
      />

      <ImageFieldComponent />

      <TitleHeader
        title={t('caption')}
        desc={t('caption_desc')}
        titleStyle={{ marginTop: '30px' }}
        isRequired
      />

      <Controller
        control={control}
        name='title'
        rules={{
          required: t('validation.caption_required'),
          validate: (value) => {
            if (value?.trim()?.length === 0) {
              return t('validation.caption_enter');
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={3} isInvalid={!!fieldState.error}>
              <FormInput
                id='caption'
                size='lg'
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  setValue('raw_content', event.target.value);
                }}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </>
  );
};

export default ImageCardEditor;

import React, { FC } from 'react';
import { cloneDeep } from '@apollo/client/utilities';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { isCheckList, toArray } from 'utils/utils';
import { QuestionType } from 'types';

import {
  IFormInput,
  OptionWhiteList,
  Question as IQuestion,
} from '../form.types';
import QuestionTypeSelect from '../QuestionTypeSelect/QuestionTypeSelect';
import QuestionAndSection from '../QuestionAndSection';

const filter = (question: IQuestion) => {
  return question.qType === QuestionType.SECTION;
};

interface IProps {
  hideAddQuestion?: boolean;
  isReadOnly?: boolean;
}

const QuestionList: FC<IProps> = ({ hideAddQuestion, isReadOnly }) => {
  const { t } = useTranslation('form');
  const { control, getValues } = useFormContext<IFormInput>();
  const { fields, remove, append, insert, update, move } = useFieldArray<
    IFormInput,
    'questions'
  >({
    control,
    name: 'questions',
  });

  const handleMoveUp = (index: number) => {
    if (index > 0) {
      move(index, index - 1);
    }
  };

  const handleMoveDown = (index: number) => {
    if (index < fields.length - 1) {
      move(index, index + 1);
    }
  };

  function onQuestionAdd(type: QuestionType) {
    let question = '';
    const questions = getValues('questions') || [];
    if (type === QuestionType.SECTION) {
      const length = questions.filter(filter).length || 0;
      question = `${t('questionType.section')} ${length + 1}`;
    }
    append(
      {
        qType: type,
        options: OptionWhiteList.includes(type)
          ? [{ label: '', isCorrect: false, isDisabled: false, subTitle: '' }]
          : [],
        label: question,
        isRequired: isCheckList(getValues('formCategory')?.name),
        hasAnalytics: false,
      },
      {
        shouldFocus: true,
        focusName: `questions.${questions.length}.label`,
      }
    );
  }

  const handleRemove = (index: number) => {
    const question = getValues(`questions.${index}`);
    if (question?.eid) {
      update(index, {
        ...question,
        isArchieved: true,
      });
    } else {
      remove(index);
    }
  };

  const handleDuplicate = (index: number) => {
    const questions = getValues('questions') || [];
    const ques = questions[index];
    insert(
      index + 1,
      {
        eid: '',
        qType: ques.qType,
        isRequired: ques.isRequired,
        hasAnalytics: ques.hasAnalytics,
        isDisabled: ques.isDisabled,
        options: toArray(cloneDeep(ques.options)).reduce<IQuestion['options']>(
          (acc, opt) => {
            if (!opt.isArchieved) {
              delete opt.eid;
              acc.push(opt);
            }

            return acc;
          },
          []
        ),
        label: ques.label,
        subTitle: ques.subTitle,
      },
      {
        shouldFocus: true,
        focusName: `questions.${index + 1}.label`,
      }
    );
  };

  return (
    <div>
      {fields?.map((field, index, array) => {
        return (
          <QuestionAndSection
            currentIndex={index}
            key={field.id}
            handleRemove={handleRemove}
            handleDuplicate={handleDuplicate}
            isReadOnly={isReadOnly}
            isLast={array.length === index + 1}
            handleMoveUp={handleMoveUp}
            handleMoveDown={handleMoveDown}
          />
        );
      })}

      {!(hideAddQuestion || isReadOnly) ? (
        <QuestionTypeSelect onClick={onQuestionAdd} />
      ) : null}
    </div>
  );
};

export default QuestionList;

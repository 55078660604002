import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ChapterPreview } from 'pages/Chapters';
import React, { FC } from 'react';
import GenericColorHeader from '../../GenericColorHeader';
import { IFormInput } from '../chaptor.types';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const PreviewModal: FC<IProps> = ({ open, onClose }) => {
  return (
    <Modal isOpen={open} onClose={onClose} size='4xl'>
      <ModalOverlay />
      <ModalContent
        borderRadius='40px 40px 0px 0px'
        p={4}
        position='fixed'
        top='0'
        h='100vh'
        mt={4}
      >
        <ModalBody>
          <ChapterPreview onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PreviewModal;

import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import GenerateConditionsText from './GenerateConditionsText';
import useCombinedStore from 'zustandStore/store';
import { parentDataRender } from './utility';

interface IProps {
  showVisibilityInfo: boolean | undefined;
  btnComponent: React.ReactElement;
}

const MessagesRender: FC<IProps> = ({ showVisibilityInfo, btnComponent }) => {
  const {
    hierarchyData,
    visibilitySelection,
    customSectionExpand,
    operationType,
    visibilityScope,
  } = useCombinedStore();

  const nameRender = () => {
    switch (visibilityScope) {
      case 'folder':
        if (operationType === 'add') {
          return null;
        }
        return (
          <Box>
            <Text fontWeight={700} as='span'>
              {hierarchyData?.parentFolder?.details?.name || 'Folder'}
            </Text>{' '}
            is visible to -
          </Box>
        );
      case 'subFolder':
        return (
          <Box>
            <Text fontWeight={700} as='span'>
              {hierarchyData?.subFolder?.details?.name || 'Subfolder'}
            </Text>{' '}
            is visible to -
          </Box>
        );
      case 'chapter':
        return (
          <Box>
            <Text fontWeight={700} as='span'>
              {hierarchyData?.chapter?.details?.title || 'Chapter'}
            </Text>{' '}
            is visible to -
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {!customSectionExpand && (
        <Flex mb={3} flexDir='column'>
          <GenerateConditionsText
            backgroundColor='rgba(177, 229, 252, 0.4)'
            customElement={nameRender() || undefined}
          />
          {parentDataRender(
            visibilityScope,
            hierarchyData,
            visibilitySelection
          )}
        </Flex>
      )}
      {visibilityScope === 'folder' && operationType === 'edit' && (
        <Flex
          bg='#FFD88D80'
          p='16px 20px'
          borderRadius='10px'
          align='center'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color='#FFAA00'
          />
          <Text>
            Editing folder visibility will overwrite the visibility of all the
            subfolders & chapters inside it.
          </Text>
        </Flex>
      )}
      {visibilityScope === 'subFolder' && operationType === 'edit' && (
        <Flex
          bg='#FFD88D80'
          p='16px 20px'
          borderRadius='10px'
          align='center'
          gap={2}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation as IconProp}
            color='#FFAA00'
          />
          <Flex align='center' gap='5px'>
            <Text>
              Editing subfolder visibility will overwrite the visibility of all
              the
            </Text>
            <Text fontWeight={700}>chapters</Text>
            <Text>inside it.</Text>
          </Flex>
        </Flex>
      )}
      {showVisibilityInfo && (
        <Flex flexDir='column'>
          <Flex justify='space-between' w='full' align='center'>
            <Tooltip
              label='The visibility of a chapter is the same as the folder in which the chapter is created.'
              hasArrow
              placement='bottom-start'
            >
              <Text cursor='pointer' textDecor='underline' color='#2A85FF'>
                How chapter visibility are set?
              </Text>
            </Tooltip>
            <a href='mailto:madhulika@delightree.com?subject=Request to change the visibility of chapter'>
              <Text cursor='pointer' textDecor='underline' color='#8E59FF'>
                To change the visibility of chapter? Contact us
              </Text>
            </a>
          </Flex>
          {btnComponent}
        </Flex>
      )}
    </>
  );
};

export default MessagesRender;

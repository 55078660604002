import React, { FC } from 'react';
import { BaseSearchModal } from '../../modal';
import { useTranslation } from 'react-i18next';
import camelCase from 'lodash.camelcase';

interface IProps {
  type: string;
  item: BaseSearchModal;
}

const SearchTitle: FC<IProps> = ({ type, item }) => {
  const { t } = useTranslation('common');

  if (type === 'user') {
    return <>{item?.meta?.role || item?.type || 'N/A'}</>;
  }
  if (['location', 'branch'].includes(type)) {
    return <>{item?.meta?.role || item?.type || 'Location'}</>;
  }
  if (type === 'sop') {
    return item?.meta?.parentId === item?.meta?.categoryId ? (
      <>{item?.meta?.category}</>
    ) : item?.meta?.parentName && item?.meta?.category ? (
      <>
        {item?.meta?.parentName} &gt; {item?.meta?.category}
      </>
    ) : item?.meta?.parentName ? (
      <>{item?.meta?.parentName}</>
    ) : item?.meta?.category ? (
      <>{item?.meta?.category}</>
    ) : (
      <>
        {item?.data?.meta?.category || item?.meta?.role || item?.type || 'N/A'}
      </>
    );
    // return (
    //   <>
    //     {item?.data?.meta?.category || item?.meta?.role || item?.type || 'N/A'}
    //   </>
    // );
  }
  if (type === 'category') {
    return item?.meta?.parentName && item?.meta?.category ? (
      <>
        {item?.meta?.parentName} &gt; {item?.meta?.name}
      </>
    ) : item?.meta?.parentName ? (
      <>{item?.meta?.parentName}</>
    ) : item?.meta?.category ? (
      <>{item?.meta?.name}</>
    ) : (
      <>{item?.meta?.name}</>
    );
    // return <>{item?.meta?.parentName || item?.meta?.name}</>
  }

  // return <>{item?.type || 'N/A'}</>;
  return (
    <>
      {/* @ts-ignore */}
      {t(camelCase(item?.type || 'N/A'), {
        defaultValue: item?.type || 'N/A',
      })}
    </>
  );
};

export default SearchTitle;

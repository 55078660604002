import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { toArray } from 'utils/utils';
import { FILENAME_REGEX } from 'utils/constants';

import { NoteImageEntity } from '../../../../../types';

import filePreview from '../../../../../assets/images/filePreview.svg';
import { FileComponentWrapper } from './FileComponent.styles';
import NotesText from '../ResponseCell/NotesText';
import { NoteImageTag } from '../ResponseCell';

interface FileProps {
  file: string;
}

const FileName: FC<FileProps> = ({ file }) => {
  const fileName = file?.split(FILENAME_REGEX).pop();
  return (
    <div className='file-item'>
      <img alt='file-icon' src={filePreview} />
      <span
        onClick={() => window.open(file, '_blank')}
        className='file-span'
        title={fileName}
      >
        {fileName}
      </span>
    </div>
  );
};

interface IProps {
  files?: string[];
  noteText?: string;
  noteImages?: NoteImageEntity[];
}

const FileComponent: FC<IProps> = ({ files: _files, noteText, noteImages }) => {
  const files = toArray(_files);
  return (
    <Flex flexDir='column' gap='5px'>
      <FileComponentWrapper>
        {files.length > 0 &&
          files?.map((file, index) => {
            return <FileName key={file + index} file={file} />;
          })}
        {files.length === 0 && (
          <div style={{ minWidth: '300px', maxWidth: '300px' }}>-</div>
        )}
      </FileComponentWrapper>
      <NotesText noteText={noteText} />
      <NoteImageTag noteImages={noteImages} />
    </Flex>
  );
};

export default FileComponent;

import moment from 'moment-timezone';

function formatUnit(value: number, unit: string): string {
  return `${value} ${unit}${value > 1 ? 's' : ''}`;
}

export const formatDuration = (
  start: moment.Moment,
  end: moment.Moment
): string => {
  const minutes = end.diff(start, 'minutes');
  const hours = end.diff(start, 'hours');
  const days = end.diff(start, 'days');
  const months = end.diff(start, 'months');

  if (minutes < 1) {
    return 'just now';
  } else if (minutes < 60) {
    return `${formatUnit(minutes, 'minute')}`;
  } else if (hours < 24) {
    return `${formatUnit(hours, 'hour')}`;
  } else if (days < 30) {
    return `${formatUnit(days, 'day')}`;
  } else {
    return `${formatUnit(months, 'month')}`;
  }
};

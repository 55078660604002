import React, { FC } from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { ChatGroupEntity } from 'types';
import { IconImage } from 'ui-components';

import { BG, Colors } from '../create-channel/select-group/SelectedTags';

interface TagProps {
  type: string;
  value: string;
}

const TagItem: FC<TagProps> = ({ type, value }) => {
  return (
    <Tooltip label={value} hasArrow placement='bottom-start'>
      <Flex
        align='center'
        bg={BG[type!]}
        p='6px 12px'
        borderRadius='4px'
        gap='5px'
        cursor='pointer'
      >
        <Text
          fontWeight='600'
          noOfLines={1}
          fontSize='12px'
          color={Colors[type!]}
          textTransform='capitalize'
        >
          {value}
        </Text>
      </Flex>
    </Tooltip>
  );
};

interface IProps {
  data: ChatGroupEntity;
  onClick?: () => void;
}

const ChannelListMembers: FC<IProps> = ({ data, onClick }) => {
  const { details } = data;

  // const firstTwoLocations = details?.locations?.slice(0, 2) || [];
  // const firstTwoRoles = details?.roles?.slice(0, 2) || [];

  const renderLocationChips = () => {
    if (details?.locations?.length === 0) {
      return null;
    }
    return details?.locations?.map((location) => (
      <TagItem key={location.eid} type='location' value={location.name} />
    ));
  };

  const renderRoleChips = () => {
    if (details?.roles?.length === 0) {
      return null;
    }
    return details?.roles?.map((role) => (
      <TagItem key={role} type='job' value={role} />
    ));
  };

  const renderAuthRoleChips = () => {
    if (details?.authRoles?.length === 0) {
      return null;
    }
    return details?.authRoles?.map((authRole, index) => (
      <TagItem key={index} type='role' value={authRole} />
    ));
  };

  const renderMemberChips = () => {
    return (
      <Flex align='center' gap='5px'>
        {data?.owner?.type === 'branch' ? (
          <TagItem type='location' value={data?.owner?.name} />
        ) : (
          <Tooltip
            label={
              <Text>
                {data?.owner?.name}, {data?.owner?.authRole}
              </Text>
            }
            hasArrow
            placement='bottom-start'
          >
            <Flex
              p='5px 10px'
              borderRadius='5px'
              bg='#B1E5FC80'
              w='fit-content'
              cursor='pointer'
              align='center'
              gap='5px'
            >
              <IconImage
                name={data?.owner?.name}
                thumbnail={data?.owner?.profilePic}
                boxSize={20}
                borderRadius='initial'
                objectFit='cover'
              />

              <Text fontWeight={600} fontSize='12px'>
                {data?.owner?.name}
              </Text>
              <Text fontWeight={400} textTransform='capitalize' fontSize='12px'>
                , {data?.owner?.role}
              </Text>
            </Flex>
          </Tooltip>
        )}

        {data?.membersCount > 1 && (
          <Box
            as='button'
            border='1px solid rgba(221, 221, 221, 1)'
            p='5px 8px'
            borderRadius='7px'
            cursor='pointer'
            onClick={onClick}
          >
            <Flex fontSize='14px' gap='3px' fontWeight='500'>
              <span>+</span>
              <span>{data?.membersCount - 1}</span>
            </Flex>
          </Box>
        )}
      </Flex>
    );
  };

  return (
    <Flex align='center' gap='5px' flexWrap='wrap'>
      {renderAuthRoleChips()}
      {renderRoleChips()}
      {renderLocationChips()}
      {renderMemberChips()}
    </Flex>
  );
};

export default ChannelListMembers;

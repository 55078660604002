import React, { FC } from 'react';
import { Box, Center, Flex, IconButton, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import TitleHeader from '../../../CardEditor/TitleHeader';

interface IProps {
  currentIndex: number;
  title: string;
  description?: string;
  isReadOnly?: boolean;
  isLast?: boolean;
  handleMoveUp: (index: number) => void;
  handleMoveDown: (index: number) => void;
}

const QuestionHeader: FC<IProps> = ({
  currentIndex,
  title,
  description,
  isReadOnly,
  isLast,
  handleMoveUp,
  handleMoveDown,
}) => {
  return (
    <Flex gap={4}>
      <Center
        boxSize='40px'
        bg='white'
        border='1px solid #E6E6E6'
        borderRadius='8px'
      >
        <Text fontWeight={600} fontSize='16px' color='#6F676E'>
          {currentIndex + 1}
        </Text>
      </Center>
      <Box flex={1} flexDir='column'>
        <TitleHeader
          title={title}
          desc={description}
          // titleStyle={{ marginTop: '30px' }}
          isRequired
        />
      </Box>

      <Flex gap='4px'>
        <IconButton
          aria-label='Up'
          disabled={currentIndex === 0}
          onClick={() => !isReadOnly && handleMoveUp(currentIndex)}
          icon={<FontAwesomeIcon icon={faChevronUp as IconProp} />}
          variant='solid'
          bg='white'
          color='black'
          cursor={isReadOnly ? 'no-drop' : 'pointer'}
        />
        <IconButton
          aria-label='Down'
          disabled={isLast}
          onClick={() => !isReadOnly && handleMoveDown(currentIndex)}
          icon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
          variant='solid'
          bg='white'
          color='black'
          cursor={isReadOnly ? 'no-drop' : 'pointer'}
        />
      </Flex>
      {/* <Controller
            name={`questions.${currentIndex}.qType`}
            control={control}
            rules={{
              deps: [`questions.${currentIndex}.label`],
            }}
            render={({ field }) => (
              <QuestionMenu
                isLast={isLast}
                {...field}
                isReadOnly={isReadOnly}
                setValue={setValue}
                currentIndex={currentIndex}
              />
            )}
          /> */}
    </Flex>
  );
};

export default QuestionHeader;

import { gql, useQuery } from '@apollo/client';
import { Center, Flex, Image } from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';
import { eventBus } from 'shared/eventEmit/eventBus';
import { ImageStyle } from 'sub-components/FolderIconUpload/FolderIconUpload';
import Loader from 'ui-components/Loader';

const GET_FOLDER_ICONS = gql`
  query Query {
    FolderIcons
  }
`;

interface IProps {
  selectedColor: string;
  selectedGalleryImage: string;
}

const SiteGallery: FC<IProps> = ({ selectedColor, selectedGalleryImage }) => {
  const { data, loading } = useQuery<{ FolderIcons: string[] }>(
    GET_FOLDER_ICONS,
    {
      fetchPolicy: 'network-only',
    }
  );

  // useEffect(() => {
  //   const newImageStyles: { [index: number]: ImageStyle } = {};
  //   data?.FolderIcons?.forEach((icon, index) => {
  //     const img = document.createElement('img');
  //     img.onload = () => {
  //       const aspectRatio = img.naturalWidth / img.naturalHeight;
  //       newImageStyles[index] =
  //         aspectRatio >= 1
  //           ? { width: '100%', height: 'auto', transform: 'scale(0.8)' }
  //           : { width: 'auto', height: '100%', transform: 'scale(0.8)' };
  //       setImageStyles({ ...newImageStyles });
  //     };
  //     img.src = icon;
  //   });
  // }, [data?.FolderIcons]);

  const selectedGalleryImageEmit = (value: string) => {
    eventBus.emit('setSelectedGalleryImage', value); // emit to FolderIconUpload compoent
  };

  if (loading) {
    return (
      <Center h='400px' w='450px' bg='white' borderRadius='17px'>
        <Loader />
      </Center>
    );
  }

  return (
    <Flex flexWrap='wrap' gap='25px' flexDir='row' overflowY='auto'>
      {data?.FolderIcons?.map((icon, index) => (
        <Center
          key={index}
          boxSize='80px'
          overflow='hidden'
          borderRadius='10px'
          bg={selectedColor || '#f5f5f5'}
          cursor='pointer'
          border={selectedGalleryImage === icon ? '2px solid #2A85FF' : 'none'}
          onClick={() => selectedGalleryImageEmit(icon)}
        >
          <Image
            src={icon}
            // {...(imageStyles[index] || { width: '100%', height: '100%' })}
            p={2}
            objectFit='cover'
          />
        </Center>
      ))}
    </Flex>
  );
};

export default SiteGallery;

import { AuthRole } from 'authorization';
import { ChatConfigEntity, RestrictChatType } from 'sop-commons/src/client';

export const checkAccess = (
  type: RestrictChatType,
  config: ChatConfigEntity,
  userData: any
) => {
  switch (type) {
    case 'authRole':
      return (
        config?.startDM?.authRoles?.includes(userData?.authRole) ||
        config?.startDM?.members?.includes(userData?.eid)
      );
    case 'role':
      return (
        config?.startDM?.roles.includes(userData?.role) ||
        config?.startDM?.members?.includes(userData?.eid)
      );
    case 'anyone':
      return true;
  }
};

export const checkChatAccess = (
  config: ChatConfigEntity,
  selectedUserDetails: any,
  loggedInUserDetails: any,
  locations: any[] = []
) => {
  if (config?.restrictions) {
    const isLocation = locations?.some(
      (loc: any) =>
        loc?.eid === selectedUserDetails?.eid ||
        loc?.eid === loggedInUserDetails?.eid
    );

    if (isLocation) {
      return false;
    }

    if (
      isLocation ||
      ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
        selectedUserDetails?.authRole
      ) &&
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN]?.includes(
          loggedInUserDetails?.authRole
        ))
    ) {
      // Both logged in user and selected user have either 'Super Admin' or 'Admin' auth role
      return true;
    } else {
      return false;
    }
  } else {
    const isLocation = locations?.some(
      (loc: any) =>
        loc?.eid === selectedUserDetails?.eid ||
        loc?.eid === loggedInUserDetails?.eid
    );

    const selectedUserAccess = checkAccess(
      config?.startDM?.restrictType,
      config,
      selectedUserDetails
    );
    const loggedInUserAccess = checkAccess(
      config?.startDM?.restrictType,
      config,
      loggedInUserDetails
    );

    // if (
    //   config?.startDM?.restrictType === 'role' &&
    //   config?.startDM?.roles.includes(selectedUserDetails?.role) &&
    //   config?.startDM?.roles?.includes(loggedInUserDetails?.role)
    // ) {
    //   hasRoleAccess = true;
    // }
    // if (
    //   config?.startDM?.restrictType === 'authRole' &&
    //   config?.startDM?.authRoles?.includes(selectedUserDetails?.authRole) &&
    //   config?.startDM?.authRoles?.includes(loggedInUserDetails?.authRole)
    // ) {
    //   hasAuthRoleAccess = true;
    // }

    // if (config?.startDM?.members?.length > 0) {
    //   // If member is present in the 'members' array and the logged in user has access as well to chat with member, then only it will be 'true'
    //   memberAccess =
    //     config?.startDM?.members?.includes(selectedUserDetails?.eid) &&
    //     (config?.startDM?.roles?.includes(loggedInUserDetails?.role) ||
    //       config?.startDM?.authRoles?.includes(loggedInUserDetails?.authRole));
    // }

    // console.log({ hasRoleAccess, hasAuthRoleAccess, memberAccess });

    // return (
    //   hasRoleAccess ||
    //   hasAuthRoleAccess ||
    //   memberAccess ||
    //   selectedUserDetails?.type === 'branch' ||
    //   loggedInUserDetails?.type === 'branch' ||
    //   config?.startDM?.restrictType === 'anyone' ||
    //   isLocation
    // );
    return (
      (selectedUserAccess && loggedInUserAccess) ||
      config?.startDM?.restrictType === 'anyone' ||
      isLocation
    );
  }
};

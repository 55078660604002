import * as localForage from 'localforage';
import { userObj } from 'sop-commons/src/client';
import { BaseFormInput } from './base-launcher.types';

class LocalLauncherConfig {
  private _store: typeof localForage | undefined;
  private _entityId: string | undefined;

  private get store() {
    if (!this._store) {
      this._entityId = userObj().entityId;
      this._store = localForage.createInstance({
        name: 'launcher',
        storeName: `launcher-local-data-${this._entityId}`,
      });
    }
    return this._store;
  }

  getItem<T>(id: string) {
    return this.store.getItem<T>(id);
  }

  // async getContents() {
  //   return this.getItem<MocContent[]>('contents').then((res) => res || []);
  // }

  private async setConfig(configs: BaseFormInput) {
    return this.store.setItem('configs', configs);
  }

  async getConfig() {
    const configs = await this.getItem<BaseFormInput>('configs');

    if (!configs) {
      return undefined;
    }

    return {
      activeStep: 0,
      ...configs,
    };
  }

  async updateConfig(configs: BaseFormInput) {
    return await this.setConfig(configs);
  }

  async clearConfig() {
    return this.store.removeItem('configs');
  }
}

const localConfigDatabase = new LocalLauncherConfig();

export default localConfigDatabase;

import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import FormInput from '../../../../atoms/FormInput';
import { FormCategory } from '../../../../types';
import { ActionButton } from '../../../../ui-components';
import { toArray } from '../../../../utils';

import {
  AddCategoryResponse,
  FORM_CATEGORY_CREATE,
} from './category-create.graphql';
import { CategoryResponse, getFormCategories } from '../../form.graphql';

interface IFormInput {
  category: string;
}

interface IProps {
  onCategoryCreated: (category: FormCategory) => void;
}

const AddFormCategoryForm: FC<IProps> = ({ onCategoryCreated }) => {
  const { onClose } = useModalContext();
  const { t } = useTranslation(['common', 'form']);

  const { data } = useQuery<CategoryResponse>(getFormCategories);

  const { control, formState, handleSubmit } = useForm<IFormInput>({
    defaultValues: {
      category: '',
    },
  });

  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const isDirty = formState.isDirty;

  const [createCategory] = useMutation<AddCategoryResponse>(
    FORM_CATEGORY_CREATE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: getFormCategories,
          fetchPolicy: 'network-only',
        },
      ],
    }
  );

  const onSubmit = async (values: IFormInput) => {
    try {
      const response = await createCategory({
        variables: {
          category: values.category,
        },
      });

      if (response.errors) {
        await Promise.reject(response.errors);
      } else {
        toast({
          title: t('success'),
          status: 'success',
          description: 'Form category successfully added',
        });
        onCategoryCreated(response.data!.AddFormCategory);
        onClose();
      }
    } catch (e) {
      toast({
        title: t('error'),
        status: 'error',
        description: 'Form category creation failed',
      });
      throw e;
    }
  };

  const categoryList = useMemo(() => {
    return toArray(data?.EntityFormCategories);
  }, [data?.EntityFormCategories]);

  return (
    <Flex flexDirection='column' gap={4}>
      <Controller
        control={control}
        name='category'
        defaultValue=''
        rules={{
          required: t('form:validation.formCategoryRequired'),
          validate: (value) => {
            if (
              categoryList.find(
                (category) => category.name.trim() === value.trim()
              )
            ) {
              return '* Form category with same name exists';
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <FormInput
                size='lg'
                placeholder={t('form:formCategory')}
                {...field}
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <Flex justifyContent='flex-end' pb={2}>
        <ActionButton
          size='lg'
          minW='180px'
          fontSize='15px'
          colorScheme='blue'
          isDisabled={!isDirty}
          actionFn={handleSubmit(onSubmit)}
        >
          {t('common:add')}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default AddFormCategoryForm;

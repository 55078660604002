import { Flex } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import DashboardContainer from 'sub-components/DashboardContainer';
import { getImage } from 'utils';
import { toArray } from 'utils/utils';
import CheckboxDropdown from './CheckboxDropdown';
import SelectionComponent from './SelectionComponent';

const initialOptions = [
  {
    label: 'Option 1',
    value: 'Option 1',
    role: 'Admin',
    authRole: 'Location Owner',
    eid: '1',
    profilePic: getImage(),
  },
  {
    label: 'Option 2',
    value: 'Option 2',
    role: 'Admin',
    authRole: 'Location Owner',
    eid: '1',
    profilePic: getImage(),
  },
];

const CheckboxRoot: FC = () => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  return (
    <DashboardContainer>
      {/* <CheckboxDropdown
        isMulti={true}
        indicator='tick'
        options={initialOptions}
        onChange={(values: string[]) => {
          console.log(values);
          setSelectedValues(values)
        }}
        placeholder='Select Supervisor(s)'
        showSelectedInline={false}
        value={selectedValues}
        menuListTitle='Select Supervisor(s)'
        chipType='icon'
      /> */}
      {/* <SelectionComponent
        members={initialOptions}
        value={selectedValues}
        onChange={(newValue: string[]) => {
          console.log('New Value : ', newValue);
          setSelectedValues(newValue);
        }}
        placeholder='Select Supervisor(s)'
        isLoading={false}
      /> */}
    </DashboardContainer>
  );
};

export default CheckboxRoot;

import React, { FC } from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { MANAGE_CHANNEL } from '../../../../../../appRoutes';

const ManageChannels: FC = () => {
  const history = useHistory();
  return (
    <HStack
      justify='space-between'
      w='full'
      cursor='pointer'
      onClick={() => history.push(MANAGE_CHANNEL)}
    >
      <Flex flexDir='column'>
        <Text fontWeight={600} color='rgba(111, 118, 126, 1)'>
          MANAGE CHANNELS
        </Text>
        <Text fontWeight={500} fontSize='16px' color='rgba(26, 29, 31, 1)'>
          Manage channels
        </Text>
        <Text color='rgba(111, 118, 126, 1)'>
          Create new channels, add/remove members, set restrictions on channels
        </Text>
      </Flex>
      <ChevronRightIcon fontSize='25px' color='rgba(111, 118, 126, 1)' />
    </HStack>
  );
};

export default ManageChannels;

import { Center, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCalendarExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

const GoLiveDateContent: FC = () => {
  return (
    <Center flexDir='column' gap={2}>
      <FontAwesomeIcon
        icon={faCalendarExclamation as IconProp}
        color='rgba(243, 165, 13, 1)'
        style={{
          backgroundColor: 'rgba(255, 216, 141, 0.6)',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          padding: '20px',
        }}
      />
      <Text as='span' textAlign='center'>
        {`The total duration for launching this location is less than the days
        selected. Please ensure that there's ample time for all necessary
        preparations and adjustments before proceeding.`}
      </Text>
    </Center>
  );
};

export default GoLiveDateContent;

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Flex,
} from '@chakra-ui/react';
import './MilestoneModal.scss';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUploadImage } from 'hooks';
import moment from 'moment';
import MilestoneModalContent from './MilestoneModalContent';
import MilestoneModalPreview from './MilestoneModalPreview';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { encodeSpaces } from 'utils/encodeSpaces';

interface IProps {
  isOpen: boolean;
  onClose: (operation: 'close' | 'save', data?: any) => void;
  editMilestoneData?: Array<any>;
  isFromEdit?: boolean;
}

export interface ICardData {
  mileStoneName?: string;
  backgroundColor?: string;
  milestoneUrl?: string;
  milestoneAddedBy?: string;
  milestoneDateAdded?: string;
  milestoneFileSize?: number;
  tempEid?: string | number;
}

const MilestoneModal: FC<IProps> = ({
  isOpen,
  onClose,
  editMilestoneData,
  isFromEdit,
}) => {
  const { t } = useTranslation(['common', 'training']);
  const [file, setFile] = useState<File | string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const userData = useReactiveVar(userObj);
  let isEdit = false;
  const uploadImage = useUploadImage();

  if (
    editMilestoneData &&
    Array.isArray(editMilestoneData) &&
    editMilestoneData?.length > 0
  ) {
    isEdit = true;
  }
  const [cardData, setCardData] = useState<ICardData>({
    mileStoneName: isEdit ? editMilestoneData?.[0]?.title : '',
    backgroundColor: isEdit ? editMilestoneData?.[0]?.backgroundColor : '',
    milestoneUrl: isEdit ? editMilestoneData?.[0]?.content?.[0]?.url : '',
    milestoneAddedBy: isEdit
      ? editMilestoneData?.[0]?.content?.[0]?.addedBy
      : '',
    milestoneDateAdded: isEdit
      ? editMilestoneData?.[0]?.content?.[0]?.dateAdded
      : '',
    milestoneFileSize: isEdit
      ? editMilestoneData?.[0]?.content?.[0]?.fileSize
      : 0,
    // tempEid: isEdit
    //   ? isFromEdit
    //     ? editMilestoneData?.[0]?.eid
    //     : editMilestoneData?.[0]?.tempEid
    //   : 0,
    tempEid: editMilestoneData?.[0]?.tempEid
      ? editMilestoneData?.[0]?.tempEid
      : editMilestoneData?.[0]?.tempId
      ? editMilestoneData?.[0]?.tempId
      : editMilestoneData?.[0]?.eid,
  });
  const nameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCardData({ ...cardData, mileStoneName: e.target.value });
  };

  const onFileDrop = async (fileLists: File[]) => {
    const newFile = fileLists?.[0];
    setFile(newFile);
    setIsUploading(true);
    const fileUrl = await uploadImage(newFile || null);
    let parsedFileUrl = encodeSpaces(fileUrl);
    setIsUploading(false);
    if (parsedFileUrl) {
      // setValue('milestoneUrl', parsedFileUrl);
      setCardData({
        ...cardData,
        milestoneUrl: parsedFileUrl,
        milestoneAddedBy: userData?.name,
        milestoneDateAdded: moment()?.toISOString(),
        milestoneFileSize: newFile?.size,
      });
    }
  };

  const resetHandler = () => {
    setFile(null);
    setIsUploading(false);
    setCardData({ ...cardData, milestoneUrl: '' });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose('close')}
      isCentered={true}
      size='4xl'
    >
      <ModalOverlay />
      <ModalContent p='20px'>
        <ModalBody>
          <Flex>
            <MilestoneModalContent
              cardData={cardData}
              isEdit={isEdit}
              isUploading={isUploading}
              file={file}
              nameChangeHandler={nameChangeHandler}
              onClose={onClose}
              onFileDrop={onFileDrop}
              resetHandler={resetHandler}
              setCardData={setCardData}
            />
            <MilestoneModalPreview cardData={cardData} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

MilestoneModal.displayName = 'Training/CreateTP/CC/AS/CT/MM';

export default MilestoneModal;

import {
  ITaskOverview,
  ITaskOverviewEntity,
} from 'pages/TasksDashboard/tasks.types';

export const taskTypeCount = (
  data: ITaskOverview[],
  type: ITaskOverviewEntity
) => {
  switch (type) {
    case 'overdue': {
      let overdueCount = data
        ?.filter((_data) => _data.id === 'overdue')
        ?.map((_data) => _data.count)?.[0];
      return overdueCount;
    }
    case 'dueToday': {
      let dueTodayCount = data
        ?.filter((_data) => _data.id === 'dueToday')
        ?.map((_data) => _data.count)?.[0];
      return dueTodayCount;
    }
    case 'thisWeek': {
      let thisWeekCount = data
        ?.filter((_data) => _data.id === 'thisWeek')
        ?.map((_data) => _data.count)?.[0];
      return thisWeekCount;
    }
    case 'upcoming': {
      let upcomingCount = data
        ?.filter((_data) => _data.id === 'upcoming')
        ?.map((_data) => _data.count)?.[0];
      return upcomingCount;
    }
    case 'totalTasks': {
      let totalTasksCount = data
        ?.filter((_data) => _data.id === 'totalTasks')
        ?.map((_data) => _data.count)?.[0];
      return totalTasksCount;
    }
    case 'scheduled': {
      let scheduledTasksCount = data
        ?.filter((_data) => _data.id === 'scheduled')
        ?.map((_data) => _data.count)?.[0];
      return scheduledTasksCount;
    }
    default: {
      return 0;
    }
  }
};

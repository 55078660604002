export const modalWidth = {
  sm: '340px',
  md: '740px',
  base: '740px',
  xl: '740px',
  ['2xl']: '740px',
};

export const searchMinWidth = {
  sm: '340px',
  md: '702px',
  base: '702px',
  xl: '702px',
  ['2xl']: '740px',
};

export const memberMinWidth = {
  sm: '340px',
  md: '340px',
  base: '340px',
  xl: '340px',
  ['2xl']: '340px',
};

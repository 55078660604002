import React, { FC, useContext, useMemo } from 'react';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import Dropdown, { SelectOption } from '../../../../atoms/Dropdown';
import MultiSelectCustom from './MultiSelectCustom';
import { VisibilityContext } from './context';

import { commonStyles } from './visibility.styles';
import { IVisibilityInput } from './visibility.types';
import OtherConditions from './OtherConditions';
import { useErrorKeys, usePlaceHolderKeys } from './useErrorKeys';
import {
  Authorize,
  AuthRole,
  useAuthorization,
} from '../../../../authorization';

const LocationAndJobs: FC = () => {
  const { t } = useTranslation(['common', 'deck', 'role', 'location', 'form']);
  const { control, getValues, setValue } = useFormContext<IVisibilityInput>();

  const { allOptions, dataObject } = useContext(VisibilityContext);

  const filterBy = useWatch<IVisibilityInput, 'filterBy'>({
    name: 'filterBy',
  });

  const authorization = useAuthorization({
    permittedFor: 'user',
  });

  const PlaceholderKeys = usePlaceHolderKeys();
  const ErrorKeys = useErrorKeys();

  const onChangeFilterBy = <T extends SelectOption>(
    _value: T,
    callback: (value: T) => void
  ) => {
    if (getValues('filterBy.value') !== _value.value) {
      setValue('filterValue', []);
    }
    if (getValues('otherFilterBy.value') === _value.value) {
      setValue('otherFilterBy', null);
      setValue('otherFilterValue', []);
    }
    callback(_value);
  };

  const isDisabled = useMemo(() => {
    return authorization(
      {
        permittedRoles: [AuthRole.SUPER_ADMIN, AuthRole.ADMIN],
      },
      false,
      true
    );
  }, [authorization]);

  return (
    <Flex
      flexDir='column'
      gap='20px'
      bg='#F7F7F7'
      p='28px 28px'
      borderRadius='10px'
      mt='16px'
    >
      <Box fontSize='16px' fontWeight='600' color='#333B4F'>
        <Authorize
          permittedFor='user'
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        >
          <>{t('common:locationJobs')}</>
        </Authorize>

        <Authorize
          permittedFor='user'
          permittedRoles={[AuthRole.LOCATION_OWNER]}
        >
          <>{t('common:location')}</>
        </Authorize>
      </Box>

      <Flex gap='32px'>
        <Box flex={1} maxW='calc(50% - 35px)'>
          <Controller
            control={control}
            name='filterBy'
            rules={{
              validate: (value, values) => {
                if (isEmpty(value)) {
                  if (isEmpty(values.members) || values.operation) {
                    return t('form:validation.selectFilter');
                  }

                  if (!isEmpty(values.members)) {
                    return undefined;
                  }

                  return t('form:validation.selectFilter');
                }
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl
                  isDisabled={isDisabled}
                  isInvalid={!!fieldState.error}
                >
                  <Dropdown
                    size='lg'
                    value={field.value}
                    onChange={(_val) => onChangeFilterBy(_val, field.onChange)}
                    options={allOptions}
                    selectStyles={commonStyles}
                  />
                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>

        <Box fontSize='15px' lineHeight='48px' fontWeight='600'>
          {t('deck:visibility.is_text')}
        </Box>

        <Box flex={1} maxW='calc(50% - 35px)'>
          <Controller
            control={control}
            name='filterValue'
            rules={{
              validate: (value, values) => {
                if (!value?.length && getValues('filterBy.value')) {
                  if (isEmpty(values.members) || values.operation) {
                    return ErrorKeys[getValues('filterBy.value') as never];
                  }

                  if (!isEmpty(values.members)) {
                    return undefined;
                  }

                  return ErrorKeys[getValues('filterBy.value') as never];
                }
              },
              deps: ['filterBy', 'otherFilterBy'],
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl isInvalid={!!fieldState.error}>
                  <MultiSelectCustom
                    isDisabled={isDisabled}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={PlaceholderKeys[filterBy?.value as never]}
                    options={dataObject[filterBy?.value as never]}
                  />
                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </Box>
      </Flex>

      <Authorize
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <OtherConditions />
      </Authorize>
    </Flex>
  );
};

export default LocationAndJobs;

import React, { FC } from 'react';
import { Divider, Flex } from '@chakra-ui/react';

import WhenTaskDue from './WhenTaskDue';
import PublishDate from './PublishDate';

interface IProps {
  isEdit?: boolean;
}

const OnceContainer: FC<IProps> = ({ isEdit }) => {
  return (
    <Flex flexDir='column' gap={4}>
      <WhenTaskDue />

      <Divider opacity={1} borderColor='#E9E9E9' />

      <PublishDate isEdit={isEdit} />
    </Flex>
  );
};

export default OnceContainer;

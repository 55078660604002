export const EventBusEmits = {
  chapterContainer: {
    LIST_ITEM_FOLDER_COPY_PRESS: 'listItemFolderCopyPress',
    LIST_ITEM_FOLDER_EDIT_PRESS: 'listItemFolderEditPress',
    LIST_ITEM_FOLDER_VISIBILITY_PRESS: 'listItemFolderVisibilityPress',
    LIST_ITEM_SUB_FOLDER_CREATED: 'listItemSubFolderCreated',
    CHAPTER_LIST_GRID_TOGGLE: 'chapterListGridToggle',
    CHAPTERS_FOLDER_VISIBILITY_CLOSE_MODAL:
      'chaptersFolderVisibilityCloseModal',
  },
  sidebar: {
    MOVE_TO_CATEGORY_GET_CHAPTERS_LIST: 'moveToCategoryGetChaptersList',
    MOVE_TO_CATEGORY_HISTORY_PUSH: 'moveToCategoryHistoryPush',
  },
  knowledgeBase: {
    BULK_DELETE_HEADER_GET_CHAPTERS_LIST: 'bulkDeleteHeaderGetChaptersList',
  },
};

import { match, P } from 'ts-pattern';
import { TFunction } from 'i18next';
import moment from 'moment/moment';
import { UserData } from 'sop-commons/src/client/user-data.types';
import {
  Activity,
  ActivityEventName,
  ActivityItem,
  ActivityMeta,
} from './activity-feed.types';
import { ILocActivity } from '../../locales/en/activity';

export interface ActivityResult extends Activity {
  transPrefix: 'feed';
  transKey: keyof ILocActivity['feed'];

  nameUser?: ActivityMeta['user'];
  training?: ActivityMeta['training'];
  form?: ActivityMeta['form'];
  locations?: ActivityMeta['locations'];
  userInstance?: ActivityMeta['invitedBy'];

  role?: string;
  cardName?: string;
  groupName?: string;
  deckName?: string;

  isLastItem?: boolean;
}

export type NewActivityList = {
  group: string;
  data: ActivityResult[];
};

const mapEntries = ([date, activities]: [string, ActivityResult[]]) => {
  return {
    group: date,
    data: activities,
  };
};

const reduceEntries = (
  previousData: NewActivityList[],
  [date, activities]: [string, ActivityResult[]]
) => {
  if (activities.length) {
    previousData.push({
      group: date,
      data: activities,
    });
  }
  return previousData;
};

export const activityDataModifier = (
  _activityData: Activity[],
  userData: UserData
): NewActivityList[] => {
  let DECK_VIEW = 'DeckViewComplete'.toLowerCase();
  let CREATE_DECK = 'createDeck'.toLowerCase();
  let UPDATE_DECK_VISIBILITY = 'UpdateDeckVisibility'.toLowerCase();
  let SET_CARD_BOOKMARK = 'setCardBookmark'.toLowerCase();

  const activityDataList: ActivityItem[] = JSON.parse(
    JSON.stringify(_activityData)
  );

  const activityResult = activityDataList.reduce(
    (groups, item, currentIndex, array) => {
      const date = moment(item.createdAt).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }

      const matchResult = match<
        [Activity, ActivityEventName],
        ActivityResult | null
      >([item, item.eventName])
        .with([P._, 'TrainingPathComplete'], ([data]) => {
          return {
            transPrefix: 'feed',
            transKey: 'trainingCompleted',
            eid: data.eid,
            createdAt: data.createdAt,
            nameUser: data.user,
            training: data.meta?.training,
          };
        })
        .with([P._, 'addFormResponse'], ([data]) => {
          return {
            transPrefix: 'feed',
            transKey: 'formSubmitted',
            eid: data.eid,
            createdAt: data.createdAt,

            nameUser: data.user,
            form: data.meta?.form,
          };
        })
        .with([P._, 'addLocation'], ([data]) => {
          return {
            transPrefix: 'feed',
            transKey: 'locationAdded',
            eid: data.eid,
            createdAt: data.createdAt,

            nameUser: data.user,
            locations: data.meta?.locations || data.meta?.location,
          };
        })
        .with([P._, 'joinInvite'], ([data]) => {
          return {
            transPrefix: 'feed',
            transKey: 'userAdded',
            eid: data.eid,
            createdAt: data.createdAt,

            nameUser: data.user,
            userInstance: data.meta?.invitedBy,
            locations: data.meta?.locations || data.meta?.location,
            role: data.user?.role,
          };
        })
        .with([P._, 'removeUser'], ([data]) => {
          return {
            transPrefix: 'feed',
            transKey: 'userRemoved',
            eid: data.eid,
            createdAt: data.createdAt,

            nameUser: data.meta?.removedUser,
            userInstance: data.user,
          };
        })
        .with([P._, 'setCardBookmark'], ([data]) => {
          // return {
          //   transPrefix: 'feed',
          //   transKey: 'userAdded',
          //   ...data,
          //   name: data.user?.name,
          // };

          return null;
        })
        .with([P._, 'BookmarkContent'], ([data]) => {
          return {
            transPrefix: 'feed',
            transKey: data.payload?.input?.bookmark
              ? 'bookmarkContent'
              : 'bookmarkContentRemoved',
            eid: data.eid,
            createdAt: data.createdAt,

            nameUser: data.user,
            cardName: data?.meta?.title,
          };
        })
        .with([P._, 'ChatGroupCreated'], ([data]) => {
          return {
            transPrefix: 'feed',
            transKey: 'chatGroupCreated',
            eid: data.eid,
            createdAt: data.createdAt,

            nameUser: data.user,
            groupName: data?.payload?.title,
          };
        })
        .with([P._, 'createDeck'], ([data]) => {
          return {
            transPrefix: 'feed',
            transKey: 'deckCreated',
            eid: data.eid,
            createdAt: data.createdAt,

            nameUser: data.user,
            deckName: data.meta?.deck?.title,
          };
        })
        .otherwise(([, event]) => {
          console.log('activity handler not added for ', event);
          return null;
        });

      if (matchResult) {
        matchResult.isLastItem = array.length === currentIndex + 1;
        groups[date].push(matchResult);
      }

      return groups;
    },
    {} as Record<string, ActivityResult[]>
  );

  return Object.entries(activityResult).reduce(reduceEntries, []);
};

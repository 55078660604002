import { ApolloClient } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';

import { UserSignature } from '../../../../../types';
import { toArray } from '../../../../../utils/utils';

import { GET_USER_SIGNS, UPDATE_SIGNS } from './signature.graphql';

export const updateSignatures = async (
  client: ApolloClient<Object>,
  userId: string,
  newSign: UserSignature
): Promise<UserSignature> => {
  const signatures = await client
    .query({
      query: GET_USER_SIGNS,
      fetchPolicy: 'network-only',
      variables: {
        eid: userId,
      },
    })
    .then(async (response) => {
      if (response.error) {
        throw response.error;
      }

      return toArray<UserSignature>(
        response.data?.userById?.signatures
      ).slice();
    });

  const index = signatures.findIndex(
    (value) => value.font === newSign.font && value.text === newSign.text
  );

  if (index === -1) {
    signatures.push(newSign);

    await client
      .mutate({
        mutation: UPDATE_SIGNS,
        variables: {
          input: {
            signatures: signatures.map((it) => ({
              font: it.font,
              text: it.text,
            })),
          },
        },
      })
      .then(async (response) => {
        if (response.errors) {
          throw response.errors;
        }

        const _signs = toArray<UserSignature>(
          response.data?.updateUserById?.signatures
        );

        const userData = userObj();

        userObj({
          ...userData,
          signatures: _signs,
        });

        return _signs;
      });
  } else {
    const userData = userObj();

    userObj({
      ...userData,
      signatures: signatures,
    });
  }

  return newSign;
};

// @ts-ignore
import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CounterProps {
  currentIndex?: number;
  totalCards?: number;
}

const Counter: FC<CounterProps> = ({ currentIndex, totalCards }) => {
  if (currentIndex === undefined || totalCards === undefined) {
    return null;
  }

  return (
    <Flex fontWeight='600' fontSize='14px' align='center'>
      <Box color='#000000'>{currentIndex}</Box>
      <Box color='rgba(0, 0, 0, 0.51)' fontSize='12px'>
        /{totalCards}
      </Box>
    </Flex>
  );
};

interface IProps extends CounterProps {
  title?: string;
}

const Header: FC<IProps> = ({ title, currentIndex, totalCards }) => {
  return (
    <Flex
      position='sticky'
      top='0'
      bg='#fff'
      zIndex='10'
      p='10px 16px'
      boxShadow='sm'
    >
      <Box flex={1}>
        <FontAwesomeIcon icon={faChevronLeft as IconProp} />
      </Box>
      <Box fontSize='16px' fontWeight='600'>
        {title}
      </Box>
      <Flex justify='flex-end' flex={1}>
        <Counter currentIndex={currentIndex} totalCards={totalCards} />
        {/*<FontAwesomeIcon icon={faEllipsisVertical as IconProp} />*/}
      </Flex>
    </Flex>
  );
};

export default Header;

import React, { FC } from 'react';
import { valueToPercent } from '@chakra-ui/utils';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { IInProgressBarProps } from 'sub-components/LocationLaunchDetail/types/types';
import ProgressBar from './ProgressBar';

const InProgressBar: FC<IInProgressBarProps> = (props) => {
  const { completed, totalCount } = props;

  const percent = valueToPercent(completed, 0, totalCount) || 0;

  return (
    <ProgressBar
      value={percent}
      borderRadius='full'
      sx={{
        '& > div': {
          backgroundColor:
            percent === 100 ? HeaderColors.GreenDark : HeaderColors.Yellow,
        },
      }}
    />
  );
};

export default InProgressBar;

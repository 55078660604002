import React, { FC, useMemo } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { InfoFormValues } from './ProfileInfoForm';

interface IProps {
  watch: UseFormWatch<InfoFormValues>;
  check?: (values: InfoFormValues) => boolean;
}

const InputAuthorize: FC<IProps> = ({ children, watch, check }) => {
  const values = watch();

  const isDisabled = useMemo(() => {
    return !!(check && !check(values));
  }, [values]);

  if (isDisabled) {
    return null;
  }

  return <>{children}</>;
};

export default InputAuthorize;

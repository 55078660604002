import {
  Box,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { getFormSessionDetails } from 'pages/TasksDashboard/TaskDetails/services/task-details.service';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { IChapterDataResponse } from '../TaskOverviewSteps';

interface IProps {
  isOpen: boolean;
  formId: string;
  type: string;
  chapterData?: IChapterDataResponse[];
  selectedChapterDetail?: any;
  sessionId: string;
  onClose: () => void;
}

import styled from '@emotion/styled';
import ImageText from 'pages/TasksDashboard/Components/sub-components/ImageText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useTranslation } from 'react-i18next';
import Loader from 'ui-components/Loader';
import PrimaryButton from 'atoms/PrimaryButton';
import FormDataProvider from 'pages/forms/FormStore/FormDataProvider';
import FormDataContext from 'pages/forms/FormStore/form-data-context';
import FormResponseTable from 'pages/forms/FormResponse/FormResponseTable';
import SupervisedTaskDetailsDataContext from 'pages/TasksDashboard/TaskDetails/store/supervised-task-details-context';
import { IAssigneeUsersEntity } from 'pages/TasksDashboard/tasks.types';
import { useLazyQuery } from '@apollo/client';
import { FORM_BY_ID_RESPONSE } from 'pages/forms/forms-graphql';
import ChapterIconComponent from 'pages/Chapters/ChapterIconComponent';
import { ICON_TYPE } from 'delightree-editor';
import { getImage } from '../../../../../../utils';
import { IconImage } from 'ui-components';
import { useUserDataSelector } from 'hooks';
import { AuthRole } from 'authorization';

export const ChapterResponseModalWrapper = styled.div`
  flex: 1;

  table {
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 1rem;
    width: 100%;
  }

  th,
  tr,
  td {
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 15px;
    text-align: start;
  }

  th {
    text-align: start;
    font-weight: 600;
  }

  td {
    /* vertical-align: baseline; */
  }
`;

export interface ILocationFilter {
  name: string;
  eid: string;
  selected: boolean;
}

const StepDetailsChild: FC<IProps> = ({
  isOpen,
  formId,
  type,
  chapterData,
  selectedChapterDetail,
  sessionId,
  onClose,
}) => {
  console.log('Chapter Data : ', selectedChapterDetail);
  const [usersData, setUsersData] = useState<IAssigneeUsersEntity[]>([]);
  const [locationsData, setLocationsData] = useState<ILocationFilter[]>([]);
  const loggedInUserAuthRole = useUserDataSelector((state) => state.authRole);
  const formCtx = useContext(FormDataContext);
  const supervisedCtx = useContext(SupervisedTaskDetailsDataContext);
  const { t } = useTranslation(['common', 'form']);
  const {
    execute,
    loading,
    error,
    data: formSessionData,
  } = getFormSessionDetails();

  function removeDuplicates(arr: any[]) {
    const seen = new Set();
    return arr.filter((item) => {
      if (!seen.has(item.eid)) {
        seen.add(item.eid);
        return true;
      }
      return false;
    });
  }

  useEffect(() => {
    if (supervisedCtx?.taskDetails?.lastSession?.assigneeUsers?.length > 0) {
      let _users: IAssigneeUsersEntity[] = JSON.parse(
        JSON.stringify(
          supervisedCtx?.taskDetails?.lastSession?.assigneeUsers || []
        )
      );
      _users?.forEach((user) => (user.selected = false));
      setUsersData(_users);
      let locations: ILocationFilter[] = removeDuplicates(
        _users
          ?.map((user) => {
            return user?.locations?.map((location) => location)?.[0];
          })
          .filter(Boolean)
      );
      console.log('LOCATIONS : ', locations);
      locations?.forEach((location) => (location.selected = false));
      setLocationsData(locations);
    }
  }, [supervisedCtx?.taskDetails?.lastSession?.assigneeUsers]);

  useEffect(() => {
    if (!formSessionData) return;
    let _formResponse = JSON.parse(JSON.stringify(formSessionData));
    _formResponse?.FormResponse?.items?.map((item: any, index: number) => {
      item?.response?.map((res: any) => {
        res.isExpanded = false;
      });
    });
    formCtx.updateResponseData(_formResponse?.FormResponse);
  }, [formSessionData]);

  const onClickHandler = () => {
    setUsersData([]);
    onClose();
  };

  const userFilterClickHandler = (user: IAssigneeUsersEntity) => {
    let _usersData: IAssigneeUsersEntity[] = JSON.parse(
      JSON.stringify(usersData)
    );
    _usersData?.map((data) => {
      if (user?.eid === data?.eid) {
        data.selected = !data.selected;
      }
    });
    setUsersData(_usersData);
  };

  const locationFilterClickHandler = (location: ILocationFilter) => {
    let _locationsData: ILocationFilter[] = JSON.parse(
      JSON.stringify(locationsData)
    );
    _locationsData?.map((data) => {
      if (location?.eid === data?.eid) {
        data.selected = !data.selected;
      }
    });
    setLocationsData(_locationsData);
  };

  const [getFormByIdResponse] = useLazyQuery(FORM_BY_ID_RESPONSE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log('Form By Id REsponse : ', data);
      formCtx?.updateFormResponseByIdData(data?.FormById);
    },
  });

  useEffect(() => {
    if (!formId) return;
    getFormByIdResponse({
      variables: {
        eid: formId,
        ...(loggedInUserAuthRole === AuthRole.LOCATION_OWNER
          ? { override: true }
          : {}),
      },
    });
  }, [formId]);

  const formResponseRender = () => {
    return (
      <FormResponseTable
        formId={formId}
        sessionId={sessionId}
        usersData={usersData}
        locationsData={locationsData}
        showLocation
        showFilters
        userFilterClickHandler={userFilterClickHandler}
        locationFilterClickHandler={locationFilterClickHandler}
      />
    );
  };

  const chapterResponseRender = () => {
    return (
      <ChapterResponseModalWrapper>
        {chapterData && chapterData?.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Assignee</th>
                <th>Location</th>
                <th>Is completed?</th>
              </tr>
            </thead>
            <tbody>
              {chapterData?.map((data) => {
                return (
                  <tr key={data?.eid}>
                    <td style={{ minWidth: '100px', maxWidth: '100px' }}>
                      <Text>
                        {data?.completedDate
                          ? moment(data?.completedDate)?.format('DD-MMM-YYYY')
                          : '-'}
                      </Text>
                    </td>
                    <td>
                      <ImageText
                        backgroundColor='#B1E5FC66'
                        boldText
                        img={
                          <Image
                            src={getImage(data?.profilePic, data?.name)}
                            boxSize='25px'
                            borderRadius='5px'
                            mr='5px'
                          />
                        }
                        text={data?.name}
                      />
                    </td>
                    <td>
                      <ImageText
                        backgroundColor='#CABDFF4D'
                        text={data?.locations?.[0]?.name || '-'}
                        img={
                          <Flex
                            bg='#8E59FF'
                            borderRadius='5px'
                            boxSize='25px'
                            align='center'
                            justify='center'
                            p='5px'
                            mr='5px'
                          >
                            <FontAwesomeIcon
                              icon={faLocationDot as IconProp}
                              color='white'
                            />
                          </Flex>
                        }
                      />
                    </td>
                    <td>
                      <ImageText
                        backgroundColor={
                          data?.completed ? '#B5E4CA33' : '#EFEFEF'
                        }
                        text={data?.completed ? 'Completed' : 'Not started'}
                        textColor={data?.completed ? '#83BF6E' : '#33383F'}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </ChapterResponseModalWrapper>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClickHandler} size='6xl'>
      <ModalOverlay />
      <ModalContent p={4} borderRadius='20px'>
        <ModalHeader>
          <GenericColorHeader
            color='#CABDFF'
            title={type === 'form' ? 'Form responses' : 'Chapter responses'}
          />
        </ModalHeader>
        <ModalCloseButton m={4} />
        <ModalBody>
          <Flex gap='10px' flexDir='column'>
            <Flex gap='10px'>
              <IconImage
                icon={selectedChapterDetail?.icon}
                name={selectedChapterDetail?.title}
                files={selectedChapterDetail?.files}
                thumbnail={selectedChapterDetail?.thumbnail}
                boxSize={50}
                iconFontSize='26px'
                borderRadius='8px'
              />
              <Flex flexDir='column'>
                <Text as='b'>{selectedChapterDetail?.title}</Text>
                <Text color='#6F767E' fontWeight={400} fontSize='12px'>
                  Added on{' '}
                  {moment(selectedChapterDetail?.dateAdded)?.format('DD MMM')}
                </Text>
              </Flex>
            </Flex>
            {type === 'sop'
              ? chapterResponseRender()
              : type === 'form'
              ? formResponseRender()
              : null}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const StepDetails: FC<IProps> = ({
  isOpen,
  formId,
  type,
  chapterData,
  selectedChapterDetail,
  sessionId,
  onClose,
}) => {
  return (
    <FormDataProvider>
      <StepDetailsChild
        isOpen={isOpen}
        formId={formId}
        type={type}
        chapterData={chapterData}
        selectedChapterDetail={selectedChapterDetail}
        onClose={onClose}
        sessionId={sessionId}
      />
    </FormDataProvider>
  );
};

export default StepDetails;

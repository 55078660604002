import { FC } from 'react';
import { Flex } from '@chakra-ui/react';

// import scss
import './AuthorizationLevel.scss';

import AuthorizationItem from './AuthorizationItem';

import { ReactComponent as SuperAdminSvg } from '../../assets/images/superAdminIcon.svg';
import { ReactComponent as AdminSvg } from '../../assets/images/adminIcon.svg';
import { ReactComponent as ManagerSvg } from '../../assets/images/managerIcon.svg';
import { ReactComponent as WorkerSvg } from '../../assets/images/workerIcon.svg';
import {
  SUPER_ADMIN,
  ADMIN,
  MANAGER,
  WORKER,
  LOCATIONOWNER,
} from '../../utils/userRoles';
import { Authorize, AuthRole } from 'authorization';
import { useTranslation } from 'react-i18next';

interface IProps {
  selectedAuthRole?: string;
  onSelectAuthRole: (value: string) => void;
  isDisabled?: boolean;
}

const AuthorizationLevel: FC<IProps> = ({
  selectedAuthRole,
  onSelectAuthRole,
  isDisabled,
}) => {
  const { t } = useTranslation('invite', {
    keyPrefix: 'authLevel',
  });
  const onSelect = (value: string) => {
    onSelectAuthRole(value);
  };

  return (
    <Flex className='authorization-level-container'>
      <Authorize
        permittedFor={['user', 'branch']}
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <AuthorizationItem
          icon={SuperAdminSvg}
          name={t('super_admin')}
          description={t('full_access')}
          value={SUPER_ADMIN}
          onSelect={onSelect}
          isSelected={selectedAuthRole === SUPER_ADMIN}
          isDisabled={isDisabled}
        />
      </Authorize>

      <Authorize
        permittedFor={['user', 'branch']}
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <AuthorizationItem
          icon={AdminSvg}
          name={t('admin')}
          description={t('full_access_bill')}
          value={ADMIN}
          onSelect={onSelect}
          isSelected={selectedAuthRole === ADMIN}
          isDisabled={isDisabled}
        />
      </Authorize>

      <Authorize
        permittedFor={['user', 'branch']}
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <AuthorizationItem
          icon={ManagerSvg}
          name={t('locationOwner')}
          description={t('manage_location')}
          value={LOCATIONOWNER}
          onSelect={onSelect}
          isSelected={selectedAuthRole === LOCATIONOWNER}
          isDisabled={isDisabled}
        />
      </Authorize>

      <Authorize
        permittedFor={['user', 'branch']}
        permittedRoles={[
          AuthRole.SUPER_ADMIN,
          AuthRole.ADMIN,
          AuthRole.MANAGER,
        ]}
      >
        <AuthorizationItem
          icon={WorkerSvg}
          name={t('worker')}
          description={t('access_handbook')}
          value={WORKER}
          onSelect={onSelect}
          isSelected={selectedAuthRole === WORKER}
          style={{ marginRight: '0px', marginTop: '0px' }}
          isDisabled={isDisabled}
        />
      </Authorize>
    </Flex>
  );
};

export default AuthorizationLevel;

// @ts-ignore
import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import Image from '../../../../ui-components/Image/Image';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { getImage } from '../../../../utils';

const Wrapper = styled.div<{ status: string }>`
  display: flex;
  align: center;
  gap: 3px;

  ${({ status }) => ({
    backgroundColor:
      status === 'active' || status === 'draft' ? 'none' : 'white',
    opacity: status === 'active' || status === 'draft' ? '1' : '.5',
    cursor: status === 'active' || status === 'draft' ? 'default' : 'no-drop',
  })}

  .avatar-list {
    position: relative;
    display: flex;
    width: max-content;
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: ${({ status }) =>
        status === 'active' || status === 'draft' ? 'auto' : 'no-drop'};

      :not(:first-child) {
        margin-left: -8px;
      }
    }
  }
`;

interface IProps {
  assignees: Array<{
    eid: string;
    name: string;
    profilePic: string;
  }>;
  status: string;
}

const AssigneeBadgeStack: FC<IProps> = ({ assignees = [], status }) => {
  const { t } = useTranslation('training');
  const [data, moreAssignee] = useMemo(() => {
    const more = assignees?.length - 4;
    return [assignees?.slice(0, 4) || [], more];
  }, [assignees]);

  return (
    <Wrapper status={status}>
      {data?.length > 0 ? (
        <>
          <Tooltip
            label={assignees?.map((value) => value.name)?.join(', ')}
            hasArrow
            p='8px 12px'
            borderRadius='8px'
          >
            <div className='avatar-list'>
              {data?.map((user) => {
                return (
                  <Image
                    width={20}
                    height={20}
                    key={user?.eid}
                    src={getImage(user?.profilePic, user?.name)}
                  />
                );
              })}
            </div>
          </Tooltip>
          {moreAssignee > 0 && (
            <Flex
              borderRadius='50%'
              border='1px solid #E2E2EA'
              align='center'
              justify='center'
              boxSize='22px'
            >
              <Text fontSize='10px' fontWeight={500} color='#6f767e' m={0}>
                +{moreAssignee}
              </Text>
            </Flex>
          )}
        </>
      ) : (
        <Flex align='center' gap='5px'>
          <FontAwesomeIcon
            icon={faUser as IconProp}
            color='white'
            fontSize='15px'
            style={{
              backgroundColor: '#B5E4CA',
              padding: '4px 5px',
              borderRadius: '50%',
            }}
          />
          <Text>No assignees</Text>
        </Flex>
      )}
    </Wrapper>
  );
};

export default AssigneeBadgeStack;

import React, { FC, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { NoteImageEntity } from '../../../../../types';
import { NoteImageTag } from '../ResponseCell';

interface IProps {
  longAnswer: { answer: string; eid: string };
  defaultLength?: number;
  noteImages?: NoteImageEntity[];
}

const LongAnswerComponent: FC<IProps> = ({
  longAnswer,
  defaultLength = 40,
  noteImages,
}) => {
  const [renderingText, setRenderingTexts] = useState<{
    answer: string;
    isExpanded: boolean;
    eid: string;
  }>({ answer: '', isExpanded: false, eid: '' });

  useEffect(() => {
    let _renderingText: {
      answer: string;
      isExpanded: boolean;
      eid: string;
    } = { answer: '', isExpanded: false, eid: '' };
    if (longAnswer?.answer?.length > defaultLength) {
      _renderingText.answer =
        longAnswer?.answer?.substring(0, defaultLength) + '...';
      _renderingText.isExpanded = false;
      _renderingText.eid = longAnswer.eid;
    } else {
      _renderingText.answer = longAnswer?.answer;
      _renderingText.isExpanded = false;
      _renderingText.eid = longAnswer.eid;
    }
    setRenderingTexts(_renderingText);
  }, [longAnswer]);

  const expandTextHandler = (isExpanded: boolean, eid: string) => {
    let _longAnswer: { answer: string; eid: string } = JSON.parse(
      JSON.stringify(longAnswer)
    );
    let _renderingTexts: {
      answer: string;
      eid: string;
      isExpanded: boolean;
    } = JSON.parse(JSON.stringify(renderingText));
    if (_renderingTexts?.eid === eid) {
      _renderingTexts.isExpanded = !isExpanded;
    }
    if (_renderingTexts.isExpanded) {
      _renderingTexts.answer = _longAnswer.answer;
    } else {
      _renderingTexts.answer =
        _longAnswer.answer?.substring(0, defaultLength) + '...';
    }
    setRenderingTexts(_renderingTexts);
  };

  const getStyleHandler = (isExpanded: boolean): React.CSSProperties => {
    if (isExpanded) {
      return {
        // minWidth: '230px',
        // maxWidth: '230px',
        // whiteSpace: 'nowrap',
        // marginRight: '1rem',
        whiteSpace: 'break-spaces',
      };
    }
    return {
      // minWidth: '230px',
      // maxWidth: '230px',
      whiteSpace: 'break-spaces',
    };
  };

  return (
    <Flex flexDir='column' gap='5px'>
      <div
        key={renderingText?.eid}
        style={getStyleHandler(renderingText?.isExpanded)}
      >
        {renderingText?.answer}&nbsp;
        {!renderingText?.answer && '-'}
        {renderingText?.answer?.length > defaultLength && (
          <strong
            style={{ color: '#2A85FF', cursor: 'pointer' }}
            onClick={() =>
              expandTextHandler(renderingText?.isExpanded, longAnswer?.eid)
            }
          >
            {!renderingText?.isExpanded ? 'show more' : 'show less'}
          </strong>
        )}
      </div>
      <NoteImageTag noteImages={noteImages} />
    </Flex>
  );
};

export default LongAnswerComponent;

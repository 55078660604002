import { useMemo } from 'react';
import { useUserData } from '../../../hooks';
import { useWatch } from 'react-hook-form';
import { InfoFormValues } from '../../Profile/TabComponents';
import { toArray } from '../../../utils/utils';
import { match } from 'ts-pattern';
import { AuthRole } from '../../../authorization';
import { UserDataResponse } from '../../Profile/TabComponents/InfoComponent/info.graphql';

export const useTabPermission = (
  userData?: UserDataResponse['userById']
): boolean => {
  const authUser = useUserData();

  const authLocations = useMemo(() => {
    return toArray(authUser.locations).map((it) => it.eid);
  }, [authUser.locations]);

  return useMemo(() => {
    if (!userData) {
      return true;
    }
    if (authUser?.eid === userData?.eid) {
      return false;
    }

    if (authUser?.type === 'branch') {
      return true;
    }

    return match(authUser?.authRole!)
      .with(AuthRole.SUPER_ADMIN, () => false)
      .with(AuthRole.ADMIN, () => {
        return userData.authRole === AuthRole.SUPER_ADMIN;
      })
      .with(AuthRole.LOCATION_OWNER, () => {
        if (
          [AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(userData.authRole)
        ) {
          return true;
        }

        return !toArray(userData.locations).some((loc) => {
          return authLocations.includes(loc.eid);
        });
      })
      .with(AuthRole.WORKER, () => true)
      .otherwise(() => true);
  }, [
    authUser?.eid,
    authUser?.authRole,
    authUser?.type,
    userData,
    authLocations,
  ]);
};

import React, { FC } from 'react';
import { ButtonProps, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import PrimaryButton from '../../../../atoms/PrimaryButton';

interface IProps extends Pick<ButtonProps, 'onClick' | 'disabled'> {
  title?: string;
}

const SaveNextButton: FC<IProps> = ({ onClick, title, disabled }) => {
  const { t } = useTranslation('task');

  return (
    <Flex justify='flex-end' mt='16px'>
      <PrimaryButton
        size='lg'
        width='auto'
        colorScheme='blue'
        style={{
          minWidth: 148,
          paddingInline: '32px',
        }}
        title={title || t('saveNext')}
        onClick={onClick}
        disabled={disabled}
      />
    </Flex>
  );
};

export default SaveNextButton;

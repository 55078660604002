import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import FilterBadge from 'sub-components/LocationLaunchDashboard/FilterBadge/Singleton';
import FilterBadgeList from 'sub-components/LocationLaunchDashboard/FilterBadge/List';
import ReadyLiveCard from 'sub-components/LocationLaunchDashboard/ReadyLive/Singleton';
import ReadyLiveCardList from 'sub-components/LocationLaunchDashboard/ReadyLive/List';
import InProgressCard from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/InProgressCard';
import NameDetails from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Top/NameDetails';
import StatusBadge from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Top/StatusBadge';
import ProgressBar from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Top/ProgressBar';
import { HeaderColors } from 'shared/headerColors/header-colors';
import PercentDetails from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Top/PercentDetails';
import CurrentOn from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Mid/CurrentOn';
import LastDone from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Mid/LastDone';
import InProgressCardBottom from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Bottom';
import InProgressList from 'sub-components/LocationLaunchDashboard/InProgress/List';
import MakeLocLive from 'sub-components/LocationLaunchDashboard/MakeLocLive';
import { IMakeLocLive } from 'sub-components/LocationLaunchDashboard/MakeLocLive/MakeLocLive';
import MainSection from 'sub-components/LocationLaunchDashboard/MakeLocLive/MainSection';
import SubSection from 'sub-components/LocationLaunchDashboard/MakeLocLive/SubSection';
import PhaseListItem from 'sub-components/LocationLaunchDashboard/MakeLocLive/Phase/Singleton';
import PhaseList from 'sub-components/LocationLaunchDashboard/MakeLocLive/Phase/List';
import StatusSwitch from 'sub-components/LocationLaunchDashboard/MakeLocLive/StatusSwitch';
import StatusSwitchText from 'sub-components/LocationLaunchDashboard/MakeLocLive/StatusSwitch/StatusSwitchText';
import CelebrateConfetti from 'sub-components/LocationLaunchDashboard/MakeLocLive/CelebrateConfetti';
import PhaseCard from 'sub-components/LocationLaunchDashboard/MakeLocLive/Phase/CardType/Singleton';
import PhaseCardList from 'sub-components/LocationLaunchDashboard/MakeLocLive/Phase/CardType/List';
import Heading from 'sub-components/LocationLaunchDetail/Heading/Heading';
import TotalTasksDone from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/TotalTasksDone';
import HelpQueryCard from 'sub-components/LocationLaunchDetail/HelpQuery/Singleton';
import HelpQueryList from 'sub-components/LocationLaunchDetail/HelpQuery/List';
import { AllOpenHeading } from 'sub-components/LocationLaunchDetail/OpeningTask/Filter/Heading';
import { AllOpeningFilterBadge } from 'sub-components/LocationLaunchDetail/OpeningTask/Filter/FilterBadge/Singleton';
import { AllOpeningFilterBadgeList } from 'sub-components/LocationLaunchDetail/OpeningTask/Filter/FilterBadge/List';
import { IFilterBadgeProps } from 'sub-components/LocationLaunchDetail/OpeningTask/Filter/FilterBadge/Singleton/FilterBadge';
import OpeningTask from 'sub-components/LocationLaunchDetail/OpeningTask';
import TaskListItem from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/Singleton/TaskListItem';
import PhaseSection from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/Section/PhaseSection';
import StatusSection from 'sub-components/LocationLaunchDetail/OpeningTask/TaskListing/Section/StatusSection';
import LaunchGraph from 'sub-components/LocationLaunchDashboard/LaunchGraph';
import { faClockEight } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CommonLabel from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';
import CommonWrapper from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons';

const filterOptions: IFilterBadgeProps[] = [
  { label: 'By phase', value: 'phase' },
  { label: 'By status', value: 'status' },
];

const CustomText: FC<{ children: React.ReactChild }> = ({ children }) => {
  return (
    <Text textDecor='underline' fontWeight={600} fontSize='16px'>
      {children}
    </Text>
  );
};

const CustomFlex: FC = (props) => {
  const { children } = props;
  return (
    <Flex flexDir='column' bg='gray.200' p={4} borderRadius='5px' gap={4}>
      {children}
    </Flex>
  );
};

const Playground: FC = () => {
  const [filterBadgeList, setFilterBadgeList] = useState([
    {
      active: true,
      badgeTxt: 'all',
    },
    {
      active: false,
      badgeTxt: 'lagging',
    },
    {
      active: false,
      badgeTxt: 'on-track',
    },
  ]);
  const [status, setStatus] = useState<IMakeLocLive['isLive']>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>(
    filterOptions[0].value
  );

  // Handler that updates the selected filter
  const handleFilterSelect = (value: string) => {
    setSelectedFilter(value);
  };

  const filterBadges = filterOptions.map((filter) => ({
    ...filter,
    selected: filter.value === selectedFilter,
    onSelected: handleFilterSelect,
  }));
  return (
    <Flex p={8} flexDir='column' gap={4} h='99vh' overflow='auto' bg='gray.100'>
      <Text fontWeight={700} fontSize='30px' textDecor='underline'>
        Playground for location owner dashboard components
      </Text>
      <CustomText>Ready to go live Info text</CustomText>
      <CustomFlex>
        <CustomText>Ready to go live Single Card</CustomText>
        <ReadyLiveCard
          eid='locatioId'
          completedOn='27th October'
          locationName='162 Maple Street'
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Ready to go live Vertical List</CustomText>
        <ReadyLiveCardList
          locations={Array(3)
            .fill(null)
            .map(() => ({
              eid: Math.random().toString(),
              completedOn: '27th October',
              locationName: '162 Maple Street',
            }))}
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Launch Graph</CustomText>
        <LaunchGraph height={350} />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Location in progress filter badge</CustomText>
        <FilterBadge active badgeTxt='all' />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Location in progress filter badge list</CustomText>
        <FilterBadgeList list={filterBadgeList} />
      </CustomFlex>
      <CustomFlex>
        <CustomText>In Progress location name and started on date</CustomText>
        <NameDetails locName='162 Maple Street' startedOnDate='27th October' />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Status coloured badge</CustomText>
        <Flex gap={4}>
          <StatusBadge
            label='LAGGING'
            labelProps={{ color: '#FF6A55', fontWeight: 600 }}
            boxProps={{
              bg: '#FF6A5526',
            }}
          />
          <StatusBadge
            label='ON TRACK'
            labelProps={{ color: '#83BF6E', fontWeight: 600 }}
            boxProps={{
              bg: '#B5E4CA4D',
            }}
          />
        </Flex>
      </CustomFlex>
      <CustomFlex>
        <CustomText>Task progress bar</CustomText>
        <Flex flexDir='column' gap={2}>
          <ProgressBar
            value={+((50 / 100) * 100).toFixed(2)}
            borderRadius='12px'
            sx={{
              '& > div': {
                backgroundColor: HeaderColors.Yellow,
              },
            }}
          />
          <ProgressBar
            value={+((50 / 100) * 100).toFixed(2)}
            borderRadius='12px'
            sx={{
              '& > div': {
                backgroundColor: HeaderColors.Green,
              },
            }}
          />
          <ProgressBar
            value={+((50 / 100) * 100).toFixed(2)}
            borderRadius='12px'
            sx={{
              '& > div': {
                backgroundColor: HeaderColors.Red,
              },
            }}
            hasStripe
          />
          <ProgressBar
            value={+((50 / 100) * 100).toFixed(2)}
            borderRadius='12px'
            isIndeterminate
          />
        </Flex>
      </CustomFlex>
      <CustomFlex>
        <CustomText>
          In Progress currently on & last done text status
        </CustomText>
        <PercentDetails completed={7} totalCount={50} />
      </CustomFlex>
      <CustomFlex>
        <CustomText>In Progress Currently on</CustomText>
        <CurrentOn currentStage='Signing lease' sinceWhen='3 days' />
      </CustomFlex>
      <CustomFlex>
        <CustomText>In Progress Last done</CustomText>
        <Flex w='170px' justify='flex-end'>
          <LastDone lastDoneOn='24th Nov' lastDoneStep='Finalise site' />
        </Flex>
      </CustomFlex>
      <CustomFlex>
        <CustomText>In Progress latest help query</CustomText>
        <InProgressCardBottom />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Location Progress Single Card</CustomText>
        <InProgressCard
          topDetails={{
            completed: 7,
            locName: '162 Maple Street',
            startedOnDate: '27th October',
            status: 'onTrack',
            totalCount: 50,
          }}
          midDetails={{
            currentStage: 'Signing lease',
            lastDoneOn: '24th Nov',
            lastDoneStep: 'Finalise site',
            sinceWhen: '3 days',
          }}
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Location Progress Vertical List</CustomText>
        <InProgressList
          list={[
            {
              topDetails: {
                completed: 7,
                locName: '162 Maple Street',
                startedOnDate: '27th October',
                status: 'onTrack',
                totalCount: 50,
              },
              midDetails: {
                currentStage: 'Signing lease',
                lastDoneOn: '24th Nov',
                lastDoneStep: 'Finalise site',
                sinceWhen: '3 days',
              },
            },
            {
              topDetails: {
                completed: 7,
                locName: '162 Maple Street',
                startedOnDate: '27th October',
                status: 'lagging',
                totalCount: 50,
              },
              midDetails: {
                currentStage: 'Signing lease',
                lastDoneOn: '24th Nov',
                lastDoneStep: 'Finalise site',
                sinceWhen: '3 days',
              },
            },
            {
              topDetails: {
                completed: 7,
                locName: '162 Maple Street',
                startedOnDate: '27th October',
                status: 'onTrack',
                totalCount: 50,
              },
              midDetails: {
                currentStage: 'Signing lease',
                lastDoneOn: '24th Nov',
                lastDoneStep: 'Finalise site',
                sinceWhen: '3 days',
              },
            },
          ]}
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Make location live Phase List Card</CustomText>
        <PhaseCard phaseName='Pre-opening' locCount={12} />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Make location live Phase List</CustomText>
        <PhaseCardList
          list={[
            { phaseName: 'Pre-opening', locCount: 12 },
            { phaseName: 'Site-selection', locCount: 8 },
            { phaseName: 'Construction', locCount: 8 },
            { phaseName: 'Marketing', locCount: 1 },
            { phaseName: 'Marketing', locCount: 7 },
          ]}
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Make location live Main Section</CustomText>
        <Flex gap={6} flexDir='column'>
          <MainSection name='Maple Street' />
          <Box h='1px' bg='black' />
          <MainSection name='Maple Street' isLive />
        </Flex>
      </CustomFlex>
      <CustomFlex>
        <CustomText>Make location live Sub Section</CustomText>
        <Flex gap={6} flexDir='column'>
          <SubSection locationName='Maple Street' />
          <Box h='1px' bg='black' />
          <SubSection locationName='Maple Street' isLive />
        </Flex>
      </CustomFlex>
      <CustomFlex>
        <CustomText>Make location live Phase List Item</CustomText>
        <PhaseListItem
          title='Pre-opening checklist'
          desc='Tasks that need to be completed prior to opening of a store.'
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Make location live Phase List</CustomText>
        <PhaseList
          list={[
            {
              title: 'Pre-opening checklist',
              desc: 'Tasks that need to be completed prior to opening of a store.',
            },
            {
              title: 'Site-selection checklist',
              desc: 'Equipments to be installed in a store before or shortly after its opening.',
            },
            {
              title: 'Construction checklist',
              desc: 'Documents to be submitted by a store before or just after its opening.',
            },
            {
              title: 'Marketing checklist',
              desc: 'Pictures that must be submitted by a store before or shortly after its opening.',
            },
          ]}
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Make location live Status Switch Text</CustomText>
        <Flex gap={8}>
          <StatusSwitchText />
          <StatusSwitchText isLive />
        </Flex>
      </CustomFlex>
      {/* <Flex flexDir='column'>
        <CustomText>Make location live Celebrate Confetti</CustomText>
        <CelebrateConfetti />
      </Flex> */}
      <CustomFlex>
        <CustomText>Make location live Footer</CustomText>
        <Flex gap={8}>
          <StatusSwitch />
          <StatusSwitch isLive />
        </Flex>
      </CustomFlex>
      <CustomFlex>
        <CustomText>Make location live Component (NOT LIVE)</CustomText>
        <MakeLocLive
          locationId='location-1'
          locationName='Maple Street'
          phaseList={[]}
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Make location live Component (LIVE)</CustomText>
        <MakeLocLive
          locationId='location-2'
          locationName='Maple Street'
          phaseList={[]}
          isLive
        />
      </CustomFlex>
      <Text fontWeight={700} fontSize='30px' textDecor='underline'>
        Playground for location owner details page components
      </Text>
      <CustomFlex>
        <CustomText>Normal Heading</CustomText>
        <Heading headingTitle='Maple street' />
        <Box h='1px' bg='black' />
        <CustomText>Heading with heading icon</CustomText>
        <Heading headingTitle='Maple street' headingIcon />
        <Box h='1px' bg='black' />
        <CustomText>Normal Heading with description</CustomText>
        <Heading headingTitle='Maple street' desc='added since 27th November' />
        <Box h='1px' bg='black' />
        <CustomText>Heading with heading icon and description</CustomText>
        <Heading
          headingTitle='Maple street'
          headingIcon
          desc='added since 27th November'
        />
        <Box h='1px' bg='black' />
        <CustomText>
          Normal Heading with description and description icon
        </CustomText>
        <Heading
          headingTitle='Maple street'
          desc='added since 27th November'
          descIcon
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Location Progress Card (Horizontal)</CustomText>
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Total Tasks Done
        </Text>
        <TotalTasksDone />
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Current task
        </Text>
        <CurrentOn
          orientation='horizontal'
          currentStage='Signing lease'
          sinceWhen='3 days'
        />
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Last done task
        </Text>
        <LastDone
          orientation='horizontal'
          lastDoneOn='24th Nov'
          lastDoneStep='Finalise site'
        />
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Complete Component
        </Text>
        <InProgressCard
          orientation='horizontal'
          topDetails={{
            completed: 7,
            locName: '162 Maple Street',
            startedOnDate: '27th October',
            status: 'onTrack',
            totalCount: 50,
          }}
          midDetails={{
            currentStage: 'Signing lease',
            lastDoneOn: '24th Nov',
            lastDoneStep: 'Finalise site',
            sinceWhen: '3 days',
          }}
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Help Query Card</CustomText>
        <HelpQueryCard query='Uploading state & federal tax statement' />
      </CustomFlex>
      <CustomFlex>
        <CustomText>Help Query List</CustomText>
        <HelpQueryList
          list={[
            { query: 'Uploading state & federal tax statement' },
            { query: 'Uploading state & federal tax statement' },
            { query: 'Uploading state & federal tax statement' },
            { query: 'Uploading state & federal tax statement' },
          ]}
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>All opening tasks heading</CustomText>
        <AllOpenHeading />
      </CustomFlex>
      <CustomFlex>
        <CustomText>All opening tasks filter badge</CustomText>
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Filter bage un-selected
        </Text>
        <AllOpeningFilterBadge
          label='By phase'
          value='byPhase'
          selected={false}
          onSelected={(value: string) =>
            console.log('selected all opening filter badge : ', value)
          }
        />
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Filter bage selected
        </Text>
        <AllOpeningFilterBadge
          label='By phase'
          value='byPhase'
          selected
          onSelected={(value: string) =>
            console.log('selected all opening filter badge : ', value)
          }
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>All opening tasks filter badge list</CustomText>
        <AllOpeningFilterBadgeList list={filterBadges} />
      </CustomFlex>
      <CustomFlex>
        <CustomText>All opening tasks header</CustomText>
        <OpeningTask
          filterOptions={filterOptions}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      </CustomFlex>
      <CustomFlex>
        <CustomText>All opening tasks list item</CustomText>
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Overdue
        </Text>
        <TaskListItem
          dueDate='Dec 15'
          status='overdue'
          title='Ensure that all systems are tested and functioning properly.'
        />
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Due Next Week
        </Text>
        <TaskListItem
          dueDate='Dec 15'
          status='nextWeek'
          title='Ensure that all systems are tested and functioning properly.'
        />
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Completed
        </Text>
        <TaskListItem
          dueDate='Dec 15'
          completedDate='30 Dec'
          status='complete'
          title='Ensure that all systems are tested and functioning properly.'
        />
      </CustomFlex>
      {/* <CustomFlex>
        <CustomText>All opening task section</CustomText>
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Phase Section
        </Text>
        <PhaseSection
          startDate='5 Dec'
          title='Pre-opening'
          tasks={[
            {
              dueDate: 'Dec 5',
              status: 'overdue',
              title:
                'Tax documentation : Accurate tax documentation ensures  compliance and transparency.',
            },
            {
              dueDate: 'Dec 15',
              status: 'nextWeek',
              title:
                'Attend training sessions provided by the franchisor to understand the business model, products/service..',
            },
            {
              dueDate: 'Dec 15',
              completedDate: 'June 30',
              status: 'complete',
              title:
                'Order and stock necessary inventory and supplies based on the opening requirements.',
            },
          ]}
        />
        <StatusSection
          bandColor={HeaderColors.Red.Dark}
          title='Overdue'
          tasks={[
            {
              dueDate: 'Dec 5',
              status: 'overdue',
              title:
                'Tax documentation : Accurate tax documentation ensures  compliance and transparency.',
            },
            {
              dueDate: 'Dec 15',
              status: 'overdue',
              title:
                'Attend training sessions provided by the franchisor to understand the business model, products/service..',
            },
            {
              dueDate: 'Dec 15',
              completedDate: 'June 30',
              status: 'overdue',
              title:
                'Order and stock necessary inventory and supplies based on the opening requirements.',
            },
          ]}
        />
        <StatusSection
          bandColor={HeaderColors.Blue}
          title='Next week'
          tasks={[
            {
              dueDate: 'Dec 5',
              status: 'nextWeek',
              title:
                'Tax documentation : Accurate tax documentation ensures  compliance and transparency.',
            },
            {
              dueDate: 'Dec 15',
              status: 'nextWeek',
              title:
                'Attend training sessions provided by the franchisor to understand the business model, products/service..',
            },
            {
              dueDate: 'Dec 15',
              completedDate: 'June 30',
              status: 'nextWeek',
              title:
                'Order and stock necessary inventory and supplies based on the opening requirements.',
            },
          ]}
        />
        <StatusSection
          bandColor={HeaderColors.Green}
          title='Completed'
          tasks={[
            {
              dueDate: 'Dec 5',
              status: 'complete',
              title:
                'Tax documentation : Accurate tax documentation ensures  compliance and transparency.',
            },
            {
              dueDate: 'Dec 15',
              status: 'complete',
              title:
                'Attend training sessions provided by the franchisor to understand the business model, products/service..',
            },
            {
              dueDate: 'Dec 15',
              completedDate: 'June 30',
              status: 'complete',
              title:
                'Order and stock necessary inventory and supplies based on the opening requirements.',
            },
          ]}
        />
      </CustomFlex> */}
      <CustomFlex>
        <CustomText>All opening task details</CustomText>
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Due date badge
        </Text>
        <StatusBadge
          label='2 days'
          labelProps={{
            color: HeaderColors.DarkBlue,
            fontWeight: 700,
            fontSize: '14px',
          }}
          boxProps={{
            bg: '#2A85FF1A',
            borderRadius: '5px',
          }}
          leftIcon={faClockEight as IconProp}
          leftIconProps={{ color: HeaderColors.DarkBlue, fontSize: '16px' }}
        />
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Task Phase badge
        </Text>
        <StatusBadge
          label='Pre-Opening task'
          labelProps={{ color: '#6F767E', fontWeight: 700, fontSize: '14px' }}
          boxProps={{
            bg: '#F4F4F4',
            borderRadius: '5px',
          }}
        />
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Task Title
        </Text>
        <CommonLabel
          label='Follow food safety guidelines and hygiene practices. Adhere to recipes and portion sizes for consistency.'
          fontWeight={600}
          fontSize='18px'
          color='#272B30'
        />
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Task Description
        </Text>
        <CommonLabel
          label='Cash Handling and Transactions: Process customer payments accurately. Handle cash and credit card transactions securely.Maintain a balanced cash register and reconcile at the end of the shift.'
          fontSize='14px'
          color='#6F767E'
        />
        <Text textDecor='underline' fontWeight={600} fontSize='14px'>
          Task Date Section
        </Text>
        <CommonWrapper
          firstLabel={
            <Flex flexDir='column'>
              <Flex justify='space-between' align='center' mb={2}>
                <CommonLabel
                  label='Schedule start'
                  color='#33383F'
                  fontWeight={500}
                />
                <Flex align='center' gap='5px'>
                  <FontAwesomeIcon
                    icon={faCalendarCheck as IconProp}
                    color='#33383F'
                    cursor='pointer'
                  />
                  <CommonLabel
                    label='23 Dec 2023'
                    fontWeight={500}
                    color='#33383F'
                  />
                </Flex>
              </Flex>
              <Flex justify='space-between' align='center' mb={2}>
                <CommonLabel
                  label='Schedule completion'
                  color='#33383F'
                  fontWeight={500}
                />
                <Flex align='center' gap='5px'>
                  <FontAwesomeIcon
                    icon={faCalendarCheck as IconProp}
                    color='#33383F'
                    cursor='pointer'
                  />
                  <CommonLabel
                    label='29 Dec 2023'
                    fontWeight={500}
                    color='#33383F'
                  />
                </Flex>
              </Flex>
            </Flex>
          }
        />
      </CustomFlex>
      <Text fontWeight={700} fontSize='30px' textDecor='underline'>
        Playground for Category based tasks list in Home Page
      </Text>
      <CustomFlex></CustomFlex>
    </Flex>
  );
};

export default Playground;

import React, { FC } from 'react';
import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IFormInput } from '../../task.types';
import TitleHeader from '../../../../CardEditor/TitleHeader';
import LocationSelect from './LocationSelect';

interface IProps {}

const LocationAssignee: FC<IProps> = () => {
  const { t } = useTranslation(['task']);

  const { control, setValue } = useFormContext<IFormInput>();

  const assignToType = useWatch<IFormInput, 'assignToType'>({
    name: 'assignToType',
  });

  if (!assignToType?.includes('location')) {
    return null;
  }

  return (
    <Box pt={4} pb={5} borderTop='1px solid #E9E9E9'>
      <TitleHeader
        title={t('task:whoInLocation')}
        desc={t('task:whoInLocationDesc')}
      />

      <Controller
        control={control}
        name='assignedToLocation'
        rules={{
          required: t('task:validation.locationRequired'),
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={2} isInvalid={!!fieldState?.error}>
              <LocationSelect
                value={field.value}
                onChange={(values, selectedType) => {
                  field.onChange(values);
                  setValue('assignedLocationsType', selectedType, {
                    shouldValidate: true,
                  });
                }}
              />
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </Box>
  );
};

export default LocationAssignee;

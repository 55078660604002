import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import EmptyState from '../../../../sub-components/EmptyState';
import { MyTrainingModal } from './../modal';
import LocationPseudoMember from '../../LocationPseudoMemberModal';
import ProgressTimeline from '../../../../ui-components/ProgressTimeline';
// @ts-ignore
import MilestoneSuccess from '../../../../assets/images/milestone_success.png';
// @ts-ignore
import MedalIcon from '../../../../assets/images/medal.svg';
import { useServiceLayer } from './useServiceLayer';
import { useModelLayer } from './useModelLayer';
import { SortingTable } from '../../../../sub-components/ChakraTable/SortingTable';

export type IButtonValue = 'public' | 'assignedToMe';

const EmptyTitle: {
  [key in IButtonValue]:
    | 'training:trainingNotStarted'
    | 'training:nothingToLearn';
} = {
  public: 'training:trainingNotStarted',
  assignedToMe: 'training:nothingToLearn',
};

const EmptyDescription: {
  [key in IButtonValue]:
    | 'training:trainingNotStartedDesc'
    | 'training:managerWillAssignDesc';
} = {
  public: 'training:trainingNotStartedDesc',
  assignedToMe: 'training:managerWillAssignDesc',
};

interface IProps {
  tabSelected: IButtonValue;
  searchQuery?: string;
  shouldHideAction?: boolean;
  shouldHideCertificateDownload?: boolean;
  shouldHidePagination?: boolean;
  shouldHideActionLabel?: boolean;
  perPage?: number;
  setShowViewAllBtn?: React.Dispatch<React.SetStateAction<boolean>>;
  customEmptyStateComponent?: React.ReactNode;
  backToTitle?: string;
}

const MyTrainingList: FC<IProps> = ({
  tabSelected,
  searchQuery,
  shouldHideAction,
  shouldHideCertificateDownload,
  shouldHidePagination,
  shouldHideActionLabel,
  perPage = 10,
  setShowViewAllBtn,
  customEmptyStateComponent,
  backToTitle,
}) => {
  const { t } = useTranslation(['common', 'training']);
  const userObject = useReactiveVar(userObj);
  const [progressData, setProgressData] = useState<{
    allMilestoneData: any[];
    showEmptyState: boolean;
    userData: any;
    trainingName?: string;
  }>({ allMilestoneData: [], showEmptyState: false, userData: null });
  const [progressTimelineModal, setProgressTimelineModal] = useState(false);

  const [pseudoTrainingData, setPseudoTrainingData] = useState({
    show: false,
    item: {} as MyTrainingModal,
  });

  const {
    currentPage,
    hasNextPage,
    itemCount,
    dataList,
    loading,
    reassignTraining,
    setSelectedPage,
  } = useServiceLayer(perPage, searchQuery, tabSelected, setShowViewAllBtn);

  const reassignTrainingHandler = (trainingId: string) => {
    reassignTraining({
      variables: {
        userId: userObject?.eid,
        trainingId: trainingId,
      },
    });
  };

  const { columns, onPageChangeHandler } = useModelLayer(
    backToTitle,
    shouldHideActionLabel,
    shouldHideAction,
    shouldHideCertificateDownload,
    reassignTrainingHandler,
    setProgressData,
    setProgressTimelineModal,
    setPseudoTrainingData,
    setSelectedPage
  );

  return (
    <div style={{ marginBottom: '2rem', width: '100%' }}>
      <SortingTable
        colorScheme='blue'
        customWrapperStyles={{ marginTop: '1rem' }}
        emptyData={{
          content: customEmptyStateComponent ? (
            customEmptyStateComponent
          ) : (
            <EmptyState
              image='MyTraining'
              title={t(EmptyTitle[tabSelected])}
              description={t(EmptyDescription[tabSelected]) as string}
            />
          ),
        }}
        page={currentPage || 1}
        onPageChange={onPageChangeHandler}
        columns={columns}
        data={dataList}
        isLoading={loading}
        totalRegisters={itemCount}
        isResponsive
        isHidePagination={
          !((currentPage === 1 && hasNextPage) || (currentPage || 1) > 1) ||
          shouldHidePagination
        }
      />
      <LocationPseudoMember
        isOpen={pseudoTrainingData.show}
        onClose={() =>
          setPseudoTrainingData({
            ...pseudoTrainingData,
            show: false,
          })
        }
        locationId={userObject?.eid}
        selectedTrainingData={pseudoTrainingData.item}
      />

      {/* {trainingPlayOpen && (
        <CustomPathCarousel
          isOpen={trainingPlayOpen}
          onClose={onTrainingPlayClose}
          selectedTrainingData={trainingPlay}
          getSingleTrainingHandler={() => fetchTrainingData(trainingPlay?.eid)}
        />
      )} */}

      {progressTimelineModal && (
        <ProgressTimeline
          isOpen={progressTimelineModal}
          timelineData={progressData?.allMilestoneData}
          trainingName={progressData?.trainingName}
          onCloseClick={() => setProgressTimelineModal(false)}
          milestoneSuccessImg={MilestoneSuccess}
          medalIcon={MedalIcon}
          userName={userObject?.name}
          isMyTimeline
          showEmptyState={progressData?.showEmptyState}
          userData={progressData?.userData}
        />
      )}
    </div>
  );
};

export default MyTrainingList;

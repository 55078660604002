import { ChangeLogTableWrapper } from 'pages/TrainingDraft/ChangeLog/ChangeLogList/ChangeLogTable/ChangeLogTable.styles';
import {
  IChangeEventEntity,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import React, { useEffect, useState } from 'react';
import { IPublishChangeDiffCheckTable } from '../PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import { TFunction } from 'i18next';

interface IBasicInfoComponent {
  changedData: IPublishChangeDiffCheckTable[];
  t: TFunction<('common' | 'training')[], undefined, ('common' | 'training')[]>;
}

const BasicInfoComponent = ({ changedData, t }: IBasicInfoComponent) => {
  // const [contentDetails, setContentDetails] = useState<IPublishChangeDiffCheckTable[]>([]);
  const [shouldShowbasicInfo, setShouldShowBasicInfo] = useState(false);

  useEffect(() => {
    if (changedData && changedData?.length > 0) {
      changedData?.map((change) => {
        if (
          change?.tableType === 'iconsUpdated' ||
          change?.tableType === 'titleEdited'
        ) {
          setShouldShowBasicInfo(true);
        }
      });
      // setContentDetails(changedData);
    }
  }, [changedData]);

  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className='width-30'>{t('training:basic_info_table_heading')}</th>
          <th>{t('common:update')}</th>
        </tr>
      </thead>
    );
  };

  const getChangeNameHandler = (eventType: IChangeEventEntity) => {
    switch (eventType) {
      case 'iconsUpdated':
        return t('training:icon_update_publish_change');
      case 'titleEdited':
        return t('training:title_update_publish_change');
    }
  };

  const updatedComparisonsHandler = (change: ICreateChangeLogChangesEntity) => {
    switch (change?.changeEvent) {
      case 'iconsUpdated':
        return (
          <>
            {change?.icon?.old && (
              <div className='status old-status margin-right-10'>
                <span className='status-text old-status-text strike-through'>
                  <img
                    className='icon-update'
                    src={change?.icon?.old ? change?.icon?.old : ''}
                  />
                </span>
              </div>
            )}
            <div className='status new-status'>
              <span className='status-text new-status-text'>
                <img
                  className='icon-update'
                  src={change?.icon?.new ? change?.icon?.new : ''}
                />
              </span>
            </div>
          </>
        );
      case 'titleEdited':
        return (
          <>
            <div className='status old-status margin-right-10'>
              <span className='status-text old-status-text strike-through'>
                {change?.title?.old}
              </span>
            </div>
            <div className='status new-status'>
              <span className='status-text new-status-text'>
                {change?.title?.new}
              </span>
            </div>
          </>
        );
    }
  };

  const getTableBody = () => {
    return changedData?.map((change) => {
      if (
        change?.tableType === 'titleEdited' ||
        change?.tableType === 'iconsUpdated'
      ) {
        return change?.tableData?.changes?.map((_change, index) => {
          return (
            <tr key={JSON.stringify(_change) + index}>
              <td>{getChangeNameHandler(_change.changeEvent)}</td>
              <td>
                <div className='status-cell'>
                  {updatedComparisonsHandler(_change)}
                </div>
              </td>
            </tr>
          );
        });
      }
    });
  };

  return (
    <>
      {shouldShowbasicInfo ? (
        <ChangeLogTableWrapper>
          <table>
            {getTableHeader()}
            {getTableBody()}
          </table>
        </ChangeLogTableWrapper>
      ) : null}
    </>
  );
};

export default BasicInfoComponent;

import React, { FC } from 'react';
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import Container from './common/Container';

const getRandom = (min: number, max: number) => {
  return Math.round(min + Math.random() * (max - min));
};

const SectionSkeleton: FC = () => {
  return (
    <Container gap='16px'>
      <SkeletonCircle boxSize='60px' borderRadius='8px' />

      <Flex flex={1} flexDir='column' gap={2} justify='center'>
        <Skeleton h='10px' w={`${getRandom(30, 60)}%`} borderRadius='full' />
        <Skeleton h='8px' w={`${getRandom(20, 35)}%`} borderRadius='full' />
      </Flex>

      <SkeletonCircle boxSize='24px' alignSelf='center' />
    </Container>
  );
};

interface IProps {
  isLoading?: boolean;
}

const TaskFormLoading: FC<IProps> = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <Flex flexDir='column' w='full' maxW='780' mx='auto' pt='32px' gap='12px'>
        <SectionSkeleton />
        <SectionSkeleton />
        <SectionSkeleton />
        <SectionSkeleton />
        <SectionSkeleton />
      </Flex>
    );
  }

  return <>{children}</>;
};

export default TaskFormLoading;

import { SelectOption } from 'atoms';
import { IDeleteSubFolderProps } from 'pages/Chapters/ChapterContainer/hooks/useChapterContainerMisc';
import { ChapterSort } from 'pages/Chapters/chapters.graphql';
import { CategoryEntity } from 'sop-commons/src/client';
import { StateCreator } from 'zustand';

// Define the state and actions interfaces for the user slice
export interface KnowledgeBaseState {
  deleteSubFolderData: IDeleteSubFolderProps;
  filterBy: SelectOption | null;
  foldersList: CategoryEntity[];
  sortBy: ChapterSort | undefined;
  updateDeleteSubFolderData: (data: IDeleteSubFolderProps) => void;
  updateFilterBy: (data: SelectOption | null) => void;
  updateFoldersList: (newList: CategoryEntity[]) => void;
  updateSortBy: (data: ChapterSort | undefined) => void;
}

export const knowledgeBaseSlice: StateCreator<KnowledgeBaseState> = (set) => ({
  deleteSubFolderData: {
    showConfirmModal: false,
    showMoveChaptersModal: false,
    showEmptyStateModal: false,
    selectedSubFolderDetails: undefined,
  },
  filterBy: null,
  foldersList: [],
  sortBy: undefined,
  updateDeleteSubFolderData: (data) =>
    set(() => ({ deleteSubFolderData: data })),
  updateFilterBy: (data) => set(() => ({ filterBy: data })),
  updateFoldersList: (newList) => set(() => ({ foldersList: newList })),
  updateSortBy: (data) => set(() => ({ sortBy: data })),
});

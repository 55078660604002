import React, { FC } from 'react';
import {
  Box,
  Center,
  Flex,
  IconButton,
  Spacer,
  Tooltip,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { UseFieldArrayMove } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisStrokeVertical,
  faPlus,
  faStopwatch,
} from '@fortawesome/pro-regular-svg-icons';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Trans, useTranslation } from 'react-i18next';

import { ActionMenu, IActionMenuData } from '../../../../ui-components';

import SubTaskList from './SubTaskList';
import { ITaskItem } from '../editor-form/editor.types';

import { TaskItemWrapper } from './task-item.styles';

import editIcon from '../../../../assets/images/edit-black.svg';
import deleteIcon from '../../../../assets/images/trash-red.svg';

const MenuData: IActionMenuData[] = [
  {
    name: 'Add new task above',
    value: 'add-above',
    normalIcon: 'fontAwesome',
    icon: faPlus,
  },
  {
    name: 'Add new task below',
    value: 'add-below',
    normalIcon: 'fontAwesome',
    icon: faPlus,
  },
  {
    name: 'Edit task',
    value: 'edit',
    icon: editIcon,
  },
  {
    name: 'Delete task',
    value: 'delete',
    icon: deleteIcon,
    textColor: '#FF6A55',
  },
];

interface IProps {
  index: number;
  isLast?: boolean;
  data: ITaskItem;
  actionHandler: (action: string) => void;
  moveTask: UseFieldArrayMove;
}

const TaskItemContainer: FC<IProps> = ({
  index,
  isLast,
  data,
  actionHandler,
  moveTask,
}) => {
  const { t } = useTranslation(['launcher']);

  return (
    <TaskItemWrapper
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeInOut', duration: 0.2 }}
    >
      <Flex flex={1} flexDir='column' gap={1}>
        <Flex align='center'>
          {data.duration && (
            <Flex gap='6px'>
              <FontAwesomeIcon icon={faStopwatch as IconProp} color='#6F767E' />
              <Box fontSize='12px' fontWeight='400' color='#33383F'>
                <Trans
                  t={t}
                  i18nKey='launcher:noOfDay'
                  count={data.duration}
                  values={{ value: data.duration }}
                />
              </Box>
            </Flex>
          )}
          <Spacer />

          <Flex className='more-action' visibility='hidden'>
            <IconButton
              size='sm'
              aria-label='move-up'
              variant='ghost'
              icon={<ChevronUpIcon boxSize='20px' color='#6F767E' />}
              isDisabled={index === 0}
              onClick={() => moveTask(index, index - 1)}
            />

            <IconButton
              size='sm'
              aria-label='move-down'
              variant='ghost'
              icon={<ChevronDownIcon boxSize='20px' color='#6F767E' />}
              isDisabled={isLast}
              onClick={() => moveTask(index, index + 1)}
            />

            <ActionMenu
              menuData={MenuData}
              clickedItem={(_, value) => actionHandler(value)}
            >
              <IconButton
                size='sm'
                aria-label='menu'
                variant='ghost'
                icon={
                  <FontAwesomeIcon
                    icon={faEllipsisStrokeVertical as IconProp}
                    size='lg'
                    color='#6F767E'
                  />
                }
              />
            </ActionMenu>
          </Flex>

          {data.isLocal && (
            <Tooltip
              label='Updates to this task will not be visible to anyone until it is published.'
              hasArrow
              borderRadius='6px'
              padding='10px'
              placement='bottom-end'
              maxW='185px'
              bg='#000000CC'
            >
              <Center cursor='pointer' boxSize='28px'>
                <FontAwesomeIcon icon={faEyeSlash as IconProp} />
              </Center>
            </Tooltip>
          )}
        </Flex>

        <Box fontSize='15px' fontWeight='500' color='#33383F'>
          {data.title}
        </Box>
        <Box
          fontSize='12px'
          fontWeight='400'
          color='#6F767E'
          wordBreak='break-all'
          noOfLines={3}
        >
          {data.description}
        </Box>

        <SubTaskList subTasks={data.steps} />
      </Flex>
    </TaskItemWrapper>
  );
};

export default TaskItemContainer;

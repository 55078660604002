import React, { FC, useEffect, useMemo } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { useUserData } from 'hooks';
import { toArray } from 'utils/utils';

import { FormVisibility } from '../Visibility/visibility.types';
import BoxHeader from '../BoxHeader';
import GenerateConditionsText from '../../pages/Handbook/setVisibilityModal/SetSeeModal/GenerateConditionsText';
import { ShareMemberEntity } from './share-member.graphql';
import useCombinedStore from 'zustandStore/store';
import { CustomCategoryEntity } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import { IType } from 'pages/Handbook/setVisibilityModal/SetSeeModal/set-see-modal-types';

interface IProps {
  title: string;
  showVisibility?: boolean;
  visibility: FormVisibility;
  members: ShareMemberEntity[];
  onClose: () => void;
}

const VisibilityView: FC<IProps> = ({
  showVisibility = false,
  visibility,
  title,
  members,
  onClose,
}) => {
  const { updateHierarchyData } = useCombinedStore();
  const roleList = useUserData()?.entity?.roles;
  const locations = useUserData()?.entity?.locations;

  useEffect(() => {
    updateHierarchyData({
      parentFolder: {
        details: {} as CustomCategoryEntity,
        visibilityData: {
          locations: visibility.visibleTo.locations || [],
          roles: visibility.visibleTo.roles || [],
          users: visibility.visibleTo.users || [],
          condition: visibility.visibleTo.condition,
          visibility: visibility.visibility as IType,
        },
      },
    });
  }, [
    visibility.visibleTo?.roles,
    visibility.visibleTo?.locations,
    visibility.visibleTo?.users,
  ]);

  const selectedRoles = useMemo(() => {
    const _roles = visibility.visibleTo?.roles || [];

    return toArray(roleList).reduce<NonNullable<typeof roleList>>(
      (previousValue, currentValue) => {
        if (
          _roles.includes(currentValue.eid) ||
          _roles.includes(currentValue.name)
        ) {
          previousValue.push(currentValue);
        }

        return previousValue;
      },
      []
    );
  }, [roleList, visibility.visibleTo?.roles]);

  const selectedLocations = useMemo(() => {
    const _locations = visibility.visibleTo?.locations || [];

    return toArray(locations).reduce<NonNullable<typeof locations>>(
      (previousValue, currentValue) => {
        if (_locations.includes(currentValue.eid)) {
          previousValue.push(currentValue);
        }

        return previousValue;
      },
      []
    );
  }, [locations, visibility.visibleTo?.locations]);

  const selectedMembers = useMemo(() => {
    const _users = visibility.visibleTo?.users || [];

    return toArray(members).reduce<NonNullable<typeof locations>>(
      (previousValue, currentValue) => {
        if (_users.includes(currentValue.eid)) {
          previousValue.push(currentValue);
        }

        return previousValue;
      },
      []
    );
  }, [members, visibility.visibleTo?.users]);

  return (
    <Modal isOpen={showVisibility} onClose={onClose} size='2xl'>
      <ModalOverlay zIndex={2002} />
      <ModalContent
        containerProps={{
          zIndex: 2002,
        }}
      >
        <ModalHeader pt={6}>
          <Flex align='center' justify='space-between'>
            <BoxHeader color='#CABDFF' title={title} />
            <Flex justify='flex-end' gap='8px'>
              <ModalCloseButton
                pos='relative'
                borderRadius='full'
                top='0'
                right='0'
              />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody pb={6}>
          <GenerateConditionsText
            backgroundColor='rgba(177, 229, 252, 0.4)'
            descTitle='Folder visibility:'
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VisibilityView;

import React from 'react';
import { Center, Grid } from '@chakra-ui/react';
import { TFunction } from 'i18next';

import Loader from '../../../../sub-components/Loader';
import CheckBoxChart from './CheckBoxChart';
import DropDownChart from './DropDownChart';
import { ISummaryData } from './form-summary.types';
import LocationUserChart from './LocationUserChart';
import MemberChart from './MemberChart';
import MultipleChoiceChart from './MultipleChoiceChart';
import SingleChoiceChart from './SingleChoiceChart';
import EmptyState from 'sub-components/EmptyState';

export const statusChecker = (
  loading: boolean,
  summaryData: ISummaryData,
  domain: number[],
  colorsArray: string[],
  ticks: number[],
  t: TFunction<'form'[], undefined, 'form'[]>
) => {
  if (loading) {
    return (
      <Center boxSize='100%'>
        <Loader size='lg' />
      </Center>
    );
  } else if (
    !loading &&
    summaryData?.checkListData?.length === 0 &&
    summaryData?.dropdown?.length === 0 &&
    summaryData?.locationUser?.length === 0 &&
    summaryData?.member?.length === 0 &&
    summaryData?.multipleChoice?.length === 0 &&
    summaryData?.singleChoice?.length === 0
  ) {
    return <EmptyState image='DataCenter' title='No Summary Found' />;
    // return (
    //   <Center boxSize='100%' fontWeight='bold'>
    //     {t('form:no_summary_found')}
    //   </Center>
    // );
  } else {
    return (
      <Grid templateColumns='repeat(2, 1fr)' gap={2} overflow='auto'>
        {/* DROPDOWN CHARTS */}
        {summaryData?.dropdown?.map((drop, index) => {
          return drop?.questionResponseData?.length > 0 ? (
            <DropDownChart
              key={index}
              drop={drop}
              domain={domain}
              fill={colorsArray[0]}
              ticks={ticks}
            />
          ) : null;
        })}

        {/* MULTIPE CHOICE CHARTS */}
        {summaryData?.multipleChoice?.map((multi, index) => {
          return multi?.questionResponseData?.length > 0 ? (
            <MultipleChoiceChart key={index} multi={multi} />
          ) : null;
        })}

        {/* LOCATION CHARTS */}
        {summaryData?.locationUser?.map((drop, index) => {
          return drop?.questionResponseData?.length > 0 ? (
            <LocationUserChart
              key={index}
              drop={drop}
              index={index}
              colorsArray={colorsArray}
              domain={domain}
            />
          ) : null;
        })}

        {/* MEMBER CHARTS */}
        {summaryData?.member?.map((multi, index) => {
          return multi?.questionResponseData?.length > 0 ? (
            <MemberChart key={index} multi={multi} />
          ) : null;
        })}

        {/* SINGLE CHOICE CHARTS */}
        {summaryData?.singleChoice?.map((multi, index) => {
          return multi?.questionResponseData?.length > 0 ? (
            <SingleChoiceChart key={index} multi={multi} />
          ) : null;
        })}

        {/* CHECKLIST CHARTS */}
        {summaryData?.checkListData?.map((drop, index) => {
          return drop?.questionResponseData?.length > 0 ? (
            <CheckBoxChart
              key={index}
              drop={drop}
              domain={domain}
              ticks={ticks}
              colorsArray={colorsArray}
            />
          ) : null;
        })}
      </Grid>
    );
  }
};

import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Col, Row } from 'antd';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../atoms/SearchInput';
import PrimaryButton from '../../atoms/PrimaryButton';
import AddAssigneeListItem from '../../sub-components/Assignee/AddAssigneeListItem';

// @ts-ignore
import { UserDescendants } from 'sop-commons/Query/Sop';
import Loader from '../Loader';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { TrainingDraftMethods } from 'pages/TrainingDraft/training-draft-methods/TrainingDraftMethods';

const AddAssigneeModal: FC = ({
  open,
  onClose,
  trainingData,
  isAdd,
  addedData,
  selectedAssigneeData,
  checkedData,
  savedPublishData,
  dataUpdation,
  setDataUpdation,
}: any) => {
  const { t } = useTranslation(['common', 'training']);
  const [allChecked, setAllChecked] = useState(false);
  const [existingAssignees, setExistingAssignees] = useState(
    trainingData?.assignedTo || []
  );
  const [search, setNewSearch] = useState('');
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const userObject = useReactiveVar(userObj);
  const [temporaryCheckedAssignees, setTemporaryCheckedAssignees] = useState<
    string[]
  >([]);
  const [temporaryUnCheckedAssignees, setTemporaryUnCheckedAssignees] =
    useState<string[]>([]);

  const [getUsersData, { loading }] = useLazyQuery(UserDescendants, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _userData = JSON.parse(JSON.stringify(data?.userDescendants));
      _userData = _userData?.filter((data: any) => {
        return userObject?.eid !== data.eid;
      });
      let filteredData;
      if (isAdd) {
        filteredData = _userData?.filter(
          (o: any) =>
            !savedPublishData?.supervisorData?.find(
              (i: any) => i?.eid || i === o.eid
            )
        );
        // if (filteredData?.length === 0) {
        //   filteredData = _userData;
        // }
      } else {
        filteredData = _userData?.filter(
          (o: any) =>
            !trainingData?.supervisorsUsers?.find((i: any) => i?.eid === o?.eid)
        );
        // if (filteredData?.length === 0) {
        //   filteredData = _userData;
        // }
      }
      if (isAdd) {
        if (addedData?.length > 0) {
          filteredData?.forEach((d: any) => {
            addedData?.forEach((assignee: any) => {
              if (d?.eid === assignee?.eid) {
                d.checked = true;
              }
            });
          });
        }
      } else {
        filteredData?.forEach((d: any) => {
          selectedAssigneeData?.forEach((assignee: any) => {
            if (d?.eid === assignee?.eid) {
              d.checked = true;
            }
          });
        });
      }
      setAllChecked(!filteredData.some((val: any) => !val.checked));
      setAssigneeList(filteredData);
    },
  });

  useEffect(() => {
    getUsersData({
      variables: {
        sort: 'NAME_ASC',
        status: 'active',
      },
    });
  }, []);

  const onCheckChange = (itemId: any) => {
    if (isAdd) {
      let _assigneeList = JSON.parse(JSON.stringify(assigneeList));
      _assigneeList?.forEach((list: any) => {
        if (list?.eid === itemId) {
          list.checked = !list?.checked;
          if (list.checked) {
            setTemporaryCheckedAssignees([
              ...temporaryCheckedAssignees,
              itemId,
            ]);
          } else {
            setTemporaryUnCheckedAssignees([
              ...temporaryUnCheckedAssignees,
              itemId,
            ]);
          }
          // TrainingDraftMethods.assigneeAddRemoveHandler(setDataUpdation, dataUpdation, itemId, list.checked);
        }
      });
      setAssigneeList(_assigneeList);
      let filteredAssignees = _assigneeList?.filter(
        (list: any) => list?.checked
      );
      setExistingAssignees(filteredAssignees);
    } else {
      let _assigneeList = JSON.parse(JSON.stringify(assigneeList));
      _assigneeList?.forEach((list: any) => {
        if (list?.eid === itemId) {
          list.checked = !list?.checked;
          // TrainingDraftMethods.assigneeAddRemoveHandler(setDataUpdation, dataUpdation, itemId, list.checked);
        }
      });
      setAssigneeList(_assigneeList);
    }
    // if (isAdd) {
    //   if (existingSupervisors.filter((data: any) => data === itemId).length > 0) {
    //     const list = existingSupervisors.filter((item: any) => item !== itemId);
    //     setExistingSupervisors(list);
    //   } else {
    //     const list = [...existingSupervisors, itemId];
    //     setExistingSupervisors(list);
    //   }
    // } else {
    //   const newList = existingSupervisors
    //     .filter((dt: any) => dt?.eid !== null)
    //     .map((value: any) => value?.eid);
    //   console.log(`Clicked newList: ${JSON.stringify(newList)}`);
    //   if (newList.filter((data: any) => data === itemId).length > 0) {
    //     console.log(`Clicked ItemId if: ${itemId}`);
    //     const list = newList.filter((item: any) => item !== itemId);
    //     setExistingSupervisors(list);
    //   } else {
    //     console.log(`Clicked ItemId else: ${itemId}`);
    //     const list = [...newList, itemId];
    //     setExistingSupervisors(list);
    //     console.log(`Clicked ItemId else1: ${JSON.stringify(list)}`);
    //   }
    //   console.log(`Clicked ItemId Finally: ${JSON.stringify(newList)}`);
    // }
  };

  const updateAssignees = () => {
    let _selectedAssignees: any = assigneeList?.filter(
      (list: any) => list?.checked
    );
    checkedData(_selectedAssignees);
    onClose('refetch');
  };

  const addAssignees = (existingSupervisors: any[]) => {
    selectedAssigneeData(existingSupervisors);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewSearch(e.target.value);
  };

  const filteredRoles = useMemo(() => {
    if (search) {
      const reg = new RegExp(search, 'gi');
      return (
        assigneeList?.filter((value: any) => {
          return (
            value.name?.match(reg) ||
            value.authRole?.match(reg) ||
            value.role?.match(reg) ||
            value?.locations?.[0]?.name?.match(reg)
          );
        }) || []
      );
    }
    return assigneeList;
  }, [assigneeList, search]);

  const onCheckAllPress = (event: ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
    const keys: string[] = filteredRoles?.map((val: any) => val.eid) || [];
    let _assigneeList = JSON.parse(JSON.stringify(assigneeList));
    _assigneeList?.forEach((list: any) => {
      if (keys.includes(list.eid)) {
        list.checked = event.target.checked;
      }
    });
    setAssigneeList(_assigneeList);
    if (isAdd) {
      setExistingAssignees(_assigneeList?.filter((item: any) => item?.checked));
    }
  };

  const [allItemChecked, isIndeterminate] = useMemo(() => {
    const all = filteredRoles?.every((val: any) => val.checked);
    const some = filteredRoles?.some((val: any) => val.checked);
    return [all && !!filteredRoles?.length, some && !all];
  }, [filteredRoles]);

  return (
    <>
      <Modal
        isOpen={open}
        onClose={() => onClose('close')}
        closeOnOverlayClick={false}
        scrollBehavior='inside'
      >
        <ModalOverlay />
        <ModalContent
          width={{
            sm: '340px',
            md: '600px',
            base: '600px',
            xl: '600px',
            ['2xl']: '600px',
          }}
          minWidth={{
            sm: '340px',
            md: '702px',
            base: '702px',
            xl: '702px',
            ['2xl']: '600px',
          }}
        >
          <ModalHeader style={{ marginTop: 20 }}>
            <div>
              <Row>
                <Col
                  style={{
                    width: '16.02px',
                    height: '32.04px',
                    background: '#cabdff',
                    borderRadius: '4.00549px',
                  }}
                />
                <Col
                  style={{
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '32px',
                    letterSpacing: '-0.02em',
                    color: '#1a1d1f',
                    marginLeft: '16px',
                  }}
                >
                  {isAdd
                    ? t('training:assignees_table_heading')
                    : t('training:editAssignees')}
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: 24 }}>
              <SearchInput
                size='md'
                width='100%'
                hideShortcuts={true}
                placeholder={t('training:searchMemberRole')}
                disabled={true}
                onChange={handleSearchChange}
              />
            </div>
          </ModalHeader>
          <ModalCloseButton
            style={{
              marginTop: 30,
              marginRight: 20,
              outline: 'none',
              boxShadow: 'none',
            }}
          />
          <ModalBody>
            {loading && (
              <div className='sop-list-loader'>
                <Loader />
              </div>
            )}

            {!loading && (
              <div style={{ paddingRight: 8 }}>
                <Box display='flex' justifyContent='flex-end' mb='16px'>
                  <Checkbox
                    isChecked={allChecked && allItemChecked}
                    isIndeterminate={allChecked && isIndeterminate}
                    flexDirection='row-reverse'
                    onChange={onCheckAllPress}
                  >
                    <Box
                      pr='6px'
                      fontSize='14px'
                      fontWeight='700'
                      color='#1A1D1F'
                    >
                      {t('common:select_all')}
                    </Box>
                  </Checkbox>
                </Box>
                {filteredRoles?.length > 0 ? (
                  <List spacing={3}>
                    {filteredRoles?.map((item: any) => {
                      return (
                        <AddAssigneeListItem
                          item={item}
                          key={item?.eid}
                          existingSupervisors={existingAssignees}
                          onCheckChange={onCheckChange}
                        />
                      );
                    })}
                  </List>
                ) : (
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    {t('common:no_data_found')}
                  </div>
                )}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <PrimaryButton
              type='button'
              size='lg'
              title={t('common:cancel')}
              onClick={() => onClose('close')}
              style={{
                padding: '0px 37px',
                marginRight: 20,
                maxWidth: '146px',
              }}
              variant='outline'
              className='profile-button-update-container'
            />
            <PrimaryButton
              type='submit'
              size='lg'
              title={t(isAdd ? 'common:add' : 'common:update')}
              onClick={() => {
                isAdd ? addAssignees(existingAssignees) : updateAssignees();
              }}
              style={{ maxWidth: '159px' }}
              variant='solid'
              colorScheme='blue'
              className='profile-button-save-container'
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddAssigneeModal;

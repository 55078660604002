import React, { FC } from 'react';
import { Box, Button, Flex, Slide, useDisclosure } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import QuestionTypeOption from './QuestionTypeOption';
import QuestionTypeData from './data';
import { IFormInput } from '../form.types';
import { QuestionType } from 'types';

interface IProps {
  onClick?: (value: QuestionType) => void;
}

const QuestionTypeSelect: FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation('form');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formCategory = useWatch<IFormInput>({
    name: 'formCategory.name',
  });

  function _onClick(value: QuestionType) {
    onClick?.(value);
    setTimeout(onClose);
  }

  // if (formCategory === 'Checklist') {
  //   return null;
  // }

  return (
    <>
      <Button
        isFullWidth
        leftIcon={<FontAwesomeIcon icon={faPlusCircle as IconProp} />}
        variant='ghost'
        border='1px dashed #999999'
        borderRadius='10px'
        fontSize='14px'
        py='36px'
        mt='32px'
        onClick={onOpen}
        isDisabled={isOpen}
      >
        {t('addQuestion')}
      </Button>

      <Slide direction='bottom' in={isOpen} style={{ zIndex: 1 }}>
        <Box
          // pos='fixed'
          // bottom='0'
          bg='rgba(0, 0, 0, 0.8)'
          boxShadow='0 -10px 30px 0 rgba(0, 0, 0, 0.08)'
          marginRight={{
            base: '38px',
          }}
          marginLeft={{
            base: '294px',
            md: '105px',
            xl: '294px',
            '2xl': '342px',
          }}
          zIndex='1'
          px={2}
        >
          <Flex align='flex-end' gap={4} px={4} py={2} overflowX='auto' sx={{}}>
            {QuestionTypeData.map((item, index) => {
              return (
                <QuestionTypeOption
                  key={index}
                  {...item}
                  onClick={() => _onClick(item.value)}
                />
              );
            })}
          </Flex>
        </Box>
      </Slide>
    </>
  );
};

export default QuestionTypeSelect;

// import { Modal, Button } from 'antd';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { CometChat } from '@cometchat-pro/chat';

// import css
import './ResponsiveModal.css';

import responsive1 from '../../assets/images/responsive2.svg';

import responsive2 from '../../assets/images/responsive1.svg';
import mobileImage from '../../assets/images/mobile_image.png';
import delightreeMobile from '../../assets/images/delightree-mobile.png';
import googleStoreImg from '../../assets/images/google_play.png';
import appleStoreImg from '../../assets/images/apple_store.png';
import { CloseOutlined } from '@ant-design/icons';
import {
  authTokenVar,
  userObj,
  client,
  chatStatus,
} from 'sop-commons/src/client/clientFactory';
import { bundleObj, roleObj } from '../../ui-components/DashboardMenu';
import { useIntercom } from 'react-use-intercom';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { GET_USER } from '../../pages/Login/login.graphql';

const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
};

const ResponsiveModal = ({ onClose, isOpen }: any) => {
  const { shutdown } = useIntercom();
  const history = useHistory();

  const onClearData = () => {
    localStorage.clear();

    authTokenVar('');
    userObj({});
    chatStatus(false);
    bundleObj({});
    roleObj({});
    shutdown();
    client.writeQuery({
      query: GET_USER,
      data: {
        user: {},
      },
    });
    client.clearStore();
    window.location.reload();

    history.replace('/');
  };

  const onOpenAppStore = () => {
    const deviceType = getMobileOperatingSystem();
    console.log('Device Type', deviceType);
    if (deviceType === 'android') {
      setTimeout(function () {
        window.location.href =
          'https://play.google.com/store/apps/details?id=com.delightree';
      }, 25);
      window.location.href = 'delightree://home';
    } else if (deviceType === 'ios') {
      setTimeout(function () {
        window.location.href =
          'https://apps.apple.com/app/delightree/id1505988671';
      }, 25);
      window.location.href = 'delightree://home';
    }
  };
  return (
    // <Modal
    //   visible={true}
    //   footer={null}
    //   closable={false}
    //   centered={true}
    //   // style={{ height: '300px', width: '300px', padding: '0px !important' }}
    //   bodyStyle={{ width: 'calc(100vw - 700px)' }}
    //   className='new-responsive-modal-container'
    // >
    //   {/* <div className='new-responsive-image-container'>
    //     <div
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'row-reverse',
    //         marginRight: '10px',
    //         marginTop: '10px',
    //       }}
    //     >
    //       <CloseOutlined
    //         style={{ color: 'black' }}
    //         onClick={() => {
    //           onClearData();
    //         }}
    //       />
    //     </div>
    //     <div
    //       style={{
    //         paddingTop: '40px',
    //         display: 'flex',
    //         justifyContent: 'center',
    //       }}
    //     >
    //       <img src={mobileImage} />
    //     </div>
    //   </div> */}
    //   <div style={{ display: 'flex', flexDirection: 'column',backgroundColor: '#b1e5fc', width: '80%' }}>
    //     <div className='responsive-modal-header-text'>Download our app!</div>
    //     <div className='responsive-modal-desc'>
    //       Switch to app for a better experience.
    //     </div>
    //   </div>
    //   <div className='new-responsive-image-container' style={{ width: '20%' }}>
    //     <div
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'row-reverse',
    //         marginRight: '10px',
    //         marginTop: '10px',
    //       }}
    //     >
    //       <CloseOutlined
    //         style={{ color: 'black' }}
    //         onClick={() => {
    //           // onClearData();
    //           onClose()
    //         }}
    //       />
    //     </div>
    //     <div
    //       style={{
    //         paddingTop: '40px',
    //         display: 'flex',
    //         justifyContent: 'center',
    //       }}
    //     >
    //       <img src={mobileImage} />
    //     </div>
    //   </div>
    //   {/* <div style={{ margin: '30px 20px 20px 20px' }}>
    //     <Button
    //       htmlType={'button'}
    //       style={{
    //         color: '#fff',
    //         fontSize: 14,
    //         fontWeight: 'bold',
    //         height: 42,
    //         borderRadius: 3,
    //         alignSelf: 'stretch',
    //         border: 'none',
    //         width: '100%',
    //         backgroundColor: '#5CB85C',
    //       }}
    //       type='primary'
    //       onClick={onOpenAppStore}
    //     >
    //       {getMobileOperatingSystem() === 'android' && <> Open Play Store</>}
    //       {getMobileOperatingSystem() === 'ios' && <> Open App Store</>}
    //     </Button>
    //   </div> */}
    // </Modal>

    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent
        p={0}
        height={'474px'}
        width={{
          sm: 'calc(100% - 100px)',
          md: 'calc(100% - 100px)',
          base: 'calc(100% - 100px)',
          xl: '900px',
          ['2xl']: '1000px',
        }}
        minWidth={{
          sm: 'calc(100% - 100px)',
          md: 'calc(100% - 100px)',
          base: 'calc(100% - 100px)',
          xl: '900px',
          ['2xl']: '1000px',
        }}
      >
        <ModalBody p={0}>
          <div className='app-modal-main-div'>
            <CloseOutlined
              className='app-modal-close-icon'
              onClick={() => {
                onClearData();
              }}
            />
            <div className='app-modal-content'>
              <div className='app-modal-text-content'>
                <p className='app-heading'>Download our app!</p>
                <p className='app-details'>
                  Save your team hours every week by making all your company
                  knowledge instantly accessible, searchable, and trackable
                </p>
                <div
                  className='app-store-img-div'
                  style={{ marginTop: '22px' }}
                >
                  <img
                    src={appleStoreImg}
                    className='app-modal-mobile-store-img'
                    onClick={() =>
                      window.open(
                        'https://apps.apple.com/app/delightree/id1505988671',
                        '_blank'
                      )
                    }
                  />
                  <img
                    src={googleStoreImg}
                    className='app-modal-mobile-store-img'
                    onClick={() =>
                      window.open(
                        'https://play.google.com/store/apps/details?id=com.delightree',
                        '_blank'
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className='app-modal-mobile-img-div'>
              <img className='app-modal-mobile-img' />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ResponsiveModal;

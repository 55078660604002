import React, { FC } from 'react';
import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';
import { QuestionType } from '../../../../types';
import { FormInput, RichInput } from '../../../../atoms';
import Textarea from '../../../../atoms/Input/Textarea';

import { IFormInput } from '../form.types';
import {
  BaseQuestionFooter,
  QuestionHeader,
  QuestionHiddenField,
} from '../question';

const Wrapper = styled.div`
  margin-top: 32px;

  .question-container {
    background: #f9f9f9;
    border: 1px solid #dddddd;
    padding: 24px 30px 18px;
    border-radius: 10px;
    margin-top: 20px;
  }
`;

interface IProps {
  isReadOnly?: boolean;
  currentIndex: number;
  handleRemove: (index: number) => void;
  handleDuplicate: (index: number) => void;
  handleMoveUp: (index: number) => void;
  handleMoveDown: (index: number) => void;
  isLast?: boolean;
}

const OtherQuestion: FC<IProps> = ({
  currentIndex,
  handleDuplicate,
  handleRemove,
  handleMoveUp,
  handleMoveDown,
  isReadOnly,
  isLast,
}) => {
  const { t } = useTranslation(['common', 'form', 'card']);
  const { control } = useFormContext<IFormInput>();

  const qType = useWatch({
    control: control,
    name: `questions.${currentIndex}.qType`,
  });

  return (
    <Wrapper>
      <QuestionHiddenField
        currentIndex={currentIndex}
        isReadOnly={isReadOnly}
      />
      {/* <QuestionTitle
        currentIndex={currentIndex}
        handleDuplicate={handleDuplicate}
        handleRemove={handleRemove}
        readOnly={isReadOnly}
      /> */}

      <Box className='question-container' position='relative'>
        <QuestionHeader
          currentIndex={currentIndex}
          title={t('form:section_title')}
          description={t('form:section_title_desc')}
          handleMoveUp={handleMoveUp}
          handleMoveDown={handleMoveDown}
          isReadOnly={isReadOnly}
          isLast={isLast}
        />

        <Controller
          name={`questions.${currentIndex}.label`}
          control={control}
          rules={{
            required: t('form:validation.field_required'),
            validate: (value) => {
              if (value?.trim()?.length === 0) {
                return t('card:validation.question_enter');
              }
            },
          }}
          defaultValue=''
          render={({ field, fieldState }) => {
            return (
              <FormControl
                mt={3}
                isInvalid={!!fieldState.error}
                isReadOnly={isReadOnly}
              >
                {qType === QuestionType.TEXT_SECTION ? (
                  <FormInput
                    id={`question-${currentIndex}`}
                    size='lg'
                    {...field}
                  />
                ) : (
                  <Textarea
                    isReadOnly={isReadOnly}
                    id={`question-${currentIndex}`}
                    {...field}
                  />
                )}

                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <Controller
          name={`questions.${currentIndex}.subTitle`}
          control={control}
          defaultValue=''
          render={({ field, fieldState }) => {
            if (qType === QuestionType.ACKNOWLEDGMENT) {
              return <React.Fragment />;
            }

            return (
              <FormControl
                mt={3}
                isInvalid={!!fieldState.error}
                isReadOnly={isReadOnly}
              >
                {qType === QuestionType.TEXT_SECTION ? (
                  <RichInput
                    readOnly={isReadOnly}
                    value={field.value}
                    onChange={field.onChange}
                    // placeholder={t('form:descriptionOptional')}
                  />
                ) : (
                  <Textarea
                    isReadOnly={isReadOnly}
                    id={`description-${currentIndex}`}
                    // placeholder={t('form:descriptionOptional')}
                    {...field}
                  />
                )}

                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <BaseQuestionFooter
          currentIndex={currentIndex}
          isReadOnly={isReadOnly}
          handleDuplicate={handleDuplicate}
          handleRemove={handleRemove}
        />
      </Box>
    </Wrapper>
  );
};

export default OtherQuestion;

import { Flex, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import React, { FC } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

/**
 * Interface for `Heading` component props.
 */
export interface IHeadingProps {
  /** The icon to be shown on the left side of the heading. Optional, but required if `showIcon` is true. */
  headingIcon?: React.ReactNode;

  /** The main title text for the heading. Required. */
  headingTitle: string;

  /** Determines if an icon to left of description text should be shown. Optional. */
  descIcon?: boolean;

  /** The description text for the heading. Optional. */
  desc?: string;

  /** On click callback event. Optional. */
  onClick?: () => void;
}

/**
 * `Heading` Component
 *
 * This component creates a heading section that optionally includes an icon and a description.
 * It utilizes a flexible layout and is designed to be customizable through its props.
 *
 * @param {IHeadingProps} props - The properties passed to the Heading component.
 * @returns {JSX.Element} The JSX code for the Heading component.
 */
const Heading: FC<IHeadingProps> = (props) => {
  const { headingIcon, headingTitle, descIcon, desc, onClick } = props;

  return (
    <Flex>
      <Flex gap='10px'>
        {headingIcon && (
          <FontAwesomeIcon
            icon={faArrowLeft as IconProp}
            fontSize='24px'
            cursor='pointer'
            onClick={onClick}
          />
        )}
        <Flex gap='5px' flexDir='column'>
          <Text fontWeight={600} fontSize='24px'>
            {headingTitle}
          </Text>
          {desc && (
            <Flex
              color='#6F767E'
              cursor='pointer'
              _hover={{ color: '#1a1d1f' }}
              w='fit-content'
              align='center'
              gap='5px'
            >
              {descIcon && <FontAwesomeIcon icon={faChevronLeft as IconProp} />}
              <Text fontWeight={400} fontSize='15px'>
                {desc}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Heading;

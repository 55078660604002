import React, { FC, useEffect, useMemo } from 'react';
import { Box, Flex, Tooltip, useStyleConfig } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';

import EditLanguage from './EditLanguage';
import { CardFormInput } from './card-editor.types';

import { ILanguageSetting } from '../../ui-components/LanguageSetting/language-setting.types';
import { GET_SUPPORTED_LOCALES } from '../../ui-components/LanguageSetting/language-setting.graphql';
import { localizationEnabled } from '../../utils/constants';
import { IPowerUpModalTypes } from 'pages/Training/PowerUpModal/power-up.types';
import GenericColorHeader from 'sub-components/GenericColorHeader';

const StyledLink: FC<{ to: string; children: React.ReactNode }> = ({
  to,
  children,
}) => {
  const buttonStyles = useStyleConfig('Button', {
    variant: 'solid',
    colorScheme: 'blue',
  });
  const { t } = useTranslation(['card']);

  return (
    <Link
      to={{
        pathname: to,
        search: `from=powerup`,
      }}
      target='_blank'
    >
      <Tooltip
        label={t('card:createNewFormHelp')}
        placement='bottom-end'
        hasArrow
        padding='8px 12px'
        borderRadius='4px'
        maxWidth='240px'
      >
        <Box
          as='span'
          sx={buttonStyles}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          {children}
        </Box>
      </Tooltip>
    </Link>
  );
};

const EditorHeader: FC<{
  isEdit?: boolean;
  passedCardType?: IPowerUpModalTypes['types'];
  isReadOnly?: boolean;
}> = ({ isEdit, passedCardType, isReadOnly }) => {
  const { t, i18n } = useTranslation(['common', 'card', 'deck']);
  const history = useHistory();
  const language = useWatch<CardFormInput>({
    name: 'language',
    defaultValue: i18n.language,
  });

  const { data } = useQuery<ILanguageSetting>(GET_SUPPORTED_LOCALES);

  const languages = data?.SupportedLocales || [];

  const languageName = useMemo(() => {
    return languages.find((value) => value.languageCode === language)
      ?.nativeName as string;
  }, [languages, language]);

  useEffect(() => {
    if (language !== i18n.language && localizationEnabled) {
      i18n.changeLanguage(language);
    }
  }, [i18n.language, language]);

  const createFormHandler = () => {
    history.push('/training/power-up/create');
  };

  return (
    <Flex align='center' pr='70px'>
      <Flex w='72%' justify='space-between' align='center'>
        <GenericColorHeader
          title={t(
            isReadOnly
              ? 'common:preview'
              : isEdit
              ? 'card:updatePowerup'
              : 'card:createPowerup'
          )}
          color='#cabdff'
        />
        {passedCardType === 'form' && (
          <StyledLink to='/forms/create'>{t('card:createNewForm')}</StyledLink>
        )}
      </Flex>
      {/* <Box mr={4} h={8} w={4} borderRadius={4} bg={'#cabdff'} />
      <Box flex={1} className='modal-title'>
        {t(
          isReadOnly
            ? 'common:preview'
            : isEdit
            ? 'card:updatePowerup'
            : 'card:createPowerup'
        )}
      </Box> */}
      {isEdit && localizationEnabled && !isReadOnly ? (
        <EditLanguage languageName={languageName} languages={languages} />
      ) : null}
      {/* {passedCardType === 'form' && <PrimaryButton onClick={createFormHandler} width='fit-content' title={t('card:createNewForm')} variant='solid' colorScheme={'blue'} />} */}
    </Flex>
  );
};

export default EditorHeader;

EditorHeader.displayName = 'SubComponents/EditorHeader';

import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import videoPreview from '../../../../../assets/images/videoPreview.svg';
import { toArray } from 'utils/utils';
import { FILENAME_REGEX } from 'utils/constants';
import NotesText from '../ResponseCell/NotesText';

interface VideoProps {
  video: string;
}
const VideoName: FC<VideoProps> = ({ video }) => {
  const videoName = video?.split(FILENAME_REGEX).pop();
  return (
    <div>
      <Flex
        style={{
          columnGap: '8px',
          border: '1px solid #dddddd',
          borderRadius: '5px',
          padding: '5px',
          maxWidth: '100px',
        }}
      >
        <img src={videoPreview} />
        <span
          onClick={() => window.open(video, '_blank')}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          title={videoName}
        >
          {videoName}
        </span>
      </Flex>
    </div>
  );
};

interface IProps {
  videos: string[];
  noteText?: string;
}

const VideoComponent: FC<IProps> = ({ videos: _videos, noteText }) => {
  const videos = toArray(_videos);
  return (
    <Flex
      style={{
        minWidth: '200px',
        maxWidth: '200px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginRight: '1rem',
        overflow: 'hidden',
        flexDirection: 'column',
        rowGap: '5px',
      }}
    >
      {videos?.length > 0 &&
        videos?.map((video, index) => (
          <VideoName key={video + index} video={video} />
        ))}
      {videos?.length === 0 && (
        <div style={{ minWidth: '300px', maxWidth: '300px' }}>-</div>
      )}
      <NotesText noteText={noteText} />
    </Flex>
  );
};

export default VideoComponent;

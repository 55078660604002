import { useMemo } from 'react';
import { match } from 'ts-pattern';
import { useWatch } from 'react-hook-form';

import { AuthRole } from '../../../../authorization';
import { useUserDataSelector } from '../../../../hooks';
import { toArray } from '../../../../utils';

import { InfoFormValues } from './ProfileInfoForm';

export const useIsReadonly = (): boolean => {
  const authUser = useUserDataSelector((state) => ({
    eid: state.eid,
    type: state.type,
    authRole: state.authRole,
    locations: state.locations,
  }));

  const [userId, userType, accessType, location] = useWatch<
    InfoFormValues,
    ['eid', 'type', 'accessDetails', 'location']
  >({
    name: ['eid', 'type', 'accessDetails', 'location'],
  });

  return useMemo(() => {
    if (authUser?.eid === userId) {
      return false;
    }

    if (authUser?.type === 'branch') {
      return true;
    }

    return match(authUser?.authRole!)
      .with(AuthRole.SUPER_ADMIN, () => false)
      .with(AuthRole.ADMIN, () => {
        return accessType === AuthRole.SUPER_ADMIN;
      })
      .with(AuthRole.LOCATION_OWNER, () => {
        if ([AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(accessType)) {
          return true;
        }

        return !toArray(authUser.locations).some((loc) => {
          return toArray(location).includes(loc.eid);
        });
      })
      .with(AuthRole.WORKER, () => true)
      .otherwise(() => true);
  }, [
    authUser?.eid,
    authUser?.authRole,
    authUser?.type,
    authUser?.locations,
    userId,
    accessType,
    userType,
    location,
  ]);
};

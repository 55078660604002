import { gql } from '@apollo/client';
import { UserStatus, UserType } from '../../../../../types';
import { EntityUser, EntityUserFragment } from '../../common';

export interface MemberVariable {
  entityId: string;
  type?: UserType[];
  status?: UserStatus[];
}

export type { EntityUser };

interface UserAnalytics {
  complete: number;
  inComplete: number;
  total: number;
}

export interface PerformanceEntity extends EntityUser, UserAnalytics {
  completionRate: number;
}

export interface PerformanceResponse {
  EntityUser: EntityUser[];

  TaskSupervisedUserAnalytics: Record<string, UserAnalytics>;
}

export const PERFORMANCE_MEMBER = gql`
  ${EntityUserFragment}
  query PerformanceMember(
    $entityId: String!
    $type: [userTypeEnum]
    $status: [userStatusEnum]
  ) {
    EntityUser(entityId: $entityId, type: $type, status: $status) {
      ...entityUser
    }

    TaskSupervisedUserAnalytics
  }
`;

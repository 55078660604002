import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useFormContext, useWatch } from 'react-hook-form';
import { IFormInput } from 'ui-components/InviteUserNew/invite.types';
import { SelectOption } from 'atoms';
import { AuthRole } from 'sop-commons/src/client';
import { toArray } from 'utils/utils';

interface IProps {}

const PendingUserInviteLocationChips: FC<IProps> = () => {
  const { setValue, getValues } = useFormContext<IFormInput>();
  const locationList = useWatch<IFormInput, 'computedLocations'>({
    name: 'computedLocations',
  });

  const permissionLevel = useWatch<IFormInput, 'permissionLevel'>({
    name: 'permissionLevel',
  });

  const isMultiLocation = permissionLevel === AuthRole.LOCATION_OWNER;

  const onRemove = (value: SelectOption) => {
    const newLocations = toArray(getValues('computedLocations')).filter(
      (it) => it.id !== (value.id || value)
    );
    setValue('computedLocations', newLocations, {
      shouldValidate: true,
    });
  };

  if (!isMultiLocation) {
    return null;
  }

  if (!Array.isArray(locationList)) {
    return null;
  }

  return (
    <Flex mt={3} wrap='wrap' gap={2}>
      {locationList?.map((location, index) => {
        return (
          <Flex
            key={index}
            bg='#2A85FF'
            padding='8px 16px'
            borderRadius='full'
            alignItems='center'
            gap='8px'
          >
            <Text fontSize='13px' fontWeight='semibold' color='white'>
              {location.label}
            </Text>

            <CloseIcon
              cursor='pointer'
              color='white'
              boxSize='10px'
              onClick={() => onRemove(location)}
            />
          </Flex>
        );
      })}
    </Flex>
  );
};

export default PendingUserInviteLocationChips;

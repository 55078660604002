import { Card } from 'antd';
import React, { FC } from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { IChartEntity } from '../FormSummary';
import { useTranslation } from 'react-i18next';

interface IMemberChart {
  multi: IChartEntity;
}

const MemberChart: FC<IMemberChart> = ({ multi }) => {
  const { t } = useTranslation(['form']);
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'black', borderRadius: '7px' }}>
          <p
            style={{ color: 'white', padding: '0.5rem 1rem' }}
          >{`${payload?.[0]?.name} (${payload?.[0]?.payload?.originalValue})`}</p>
        </div>
      );
    }
    return null;
  };

  const renderMembersLegend = (props) => {
    const { payload } = props;

    return (
      <ul
        style={{
          listStyle: 'none',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          maxHeight: '380px',
          overflow: 'scroll',
        }}
      >
        {payload.map((entry, index) => (
          <li key={`item-${index}`}>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <div
                style={{
                  height: '10px',
                  width: '10px',
                  borderRadius: '50%',
                  backgroundColor: `${entry?.color}`,
                }}
              />
              <span>{(entry?.payload?.percent * 100).toFixed(2)}%</span>
            </div>
            <span>{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Card>
      <div>
        <p style={{ fontWeight: '600', fontSize: '18px' }}>
          {multi?.questionName}
        </p>
        {multi?.totalResponseCount && (
          <p>
            {t(
              multi?.totalResponseCount > 1 ? 'form:responses' : 'form:response'
            )}
          </p>
        )}
      </div>
      <PieChart width={600} height={400}>
        <Pie
          data={multi?.questionResponseData}
          cx={200}
          cy={200}
          labelLine={false}
          innerRadius={140}
          outerRadius={180}
          dataKey='value'
          stroke=''
        >
          {multi?.questionResponseData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry?.color} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend
          content={renderMembersLegend}
          layout='vertical'
          verticalAlign='middle'
          align='right'
          wrapperStyle={{
            right: '10%',
            top: 0,
            marginLeft: '1rem',
          }}
        />
      </PieChart>
    </Card>
  );
};

export default MemberChart;

import { gql } from '@apollo/client';
import { CardDetailEntity, DeckEntity, PageInfo } from '../../types';

export interface CardListResponse {
  cardsPagination: {
    count: number;
    items: Array<CardDetailEntity>;
    pageInfo: PageInfo;
  };
}

export const CARD_LIST_QUERY = gql`
  query CardsListPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyCardInput
    $sort: SortFindManyCardInput
  ) {
    cardsPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        media {
          playbackId
          assetId
          rawUrl
          type
          mediaUrl
          thumbnail
          status
          meta
          _id
        }
        cardType
        title
        thumbnail
        backgroundColor
        layout
        subTitle
        createdBy
        category
        entityId
        content
        raw_content
        status
        views
        updatedAt
        createdAt
        eid
        form {
          eid
          title
          thumbnail
        }
        translations
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

interface Card extends CardDetailEntity {
  lastViewed: string;
  lastUpdated: string;

  decks: Array<{
    title: string;
    eid: string;
  }>;

  formId?: string;
}

interface DeckDetails extends DeckEntity {
  description: string;
  visibility: string;
  assignedTo: {
    progress: string;
    status: string;
    userId: string;
  };

  cards: Array<Card>;

  visibleTo: {
    users: string[];
    locations: string[];
    roles: string[];
    condition: string;
    _id: string;
  };
}

export interface FindEditDeckResponse {
  findDeckById: DeckDetails;
}

export const getDeckForEdit = gql`
  query FindEditDeck($eid: String!) {
    findDeckById(eid: $eid) {
      title
      entityId
      createdBy
      category
      description
      thumbnail
      visibility
      status
      assignedTo {
        progress
        status
        userId
      }
      cards {
        form {
          eid
          title
        }
        media {
          playbackId
          assetId
          rawUrl
          type
          mediaUrl
          thumbnail
          status
          meta
          _id
        }
        cardType
        title
        thumbnail
        backgroundColor
        layout
        subTitle
        createdBy
        entityId
        content
        raw_content
        status
        views
        updatedAt
        createdAt
        eid
        lastViewed
        lastUpdated
        media {
          thumbnail
          mediaUrl
        }
        decks {
          title
          eid
        }
        category
        formId
        form {
          eid
          title
          category
          thumbnail
          description
          questions
        }
        translations
      }
      visibleTo {
        users
        locations
        roles
        condition
        _id
      }
      updatedAt
      eid
      createdAt
      trainings {
        title
      }
      translations
    }
  }
`;

export const CREATE_DECK_MUTATION = gql`
  mutation CreateDeck($input: DecksAddInputInput) {
    createDeck(input: $input) {
      title
      entityId
      createdBy
      category
      thumbnail
      description
      visibility
      status
      assignedTo {
        userId
        progress
        status
        _id
      }
      visibleTo {
        users
        roles
        locations
      }
      updatedAt
      createdAt
      eid
      cards {
        cardType
        title
        backgroundColor
        thumbnail
      }
    }
  }
`;

export const UPDATE_DECK_MUTATION = gql`
  mutation UpdateDeck($input: DecksUpdateInputInput) {
    updateDeck(input: $input) {
      title
      entityId
      createdBy
      category
      thumbnail
      description
      visibility

      status

      updatedAt
      createdAt
      eid
    }
  }
`;

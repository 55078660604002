// @ts-ignore
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import GenericColorHeader from '../../../sub-components/GenericColorHeader';
import AddCategory from '../../../sub-components/Sidebar/category/AddCategory';
import CustomSelect from './CustomSelect';
import CategoryActionButton from './CategoryActionButton';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { useTranslation } from 'react-i18next';
import { IFormInput } from '../chaptor.types';
import { useFolderSubFolder } from 'hooks';
import { SelectOption } from 'atoms';
import { useCategoryList, useSubFoldersList } from './useCategoryList';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { cloneDeep } from '@apollo/client/utilities';
import { useLazyQuery } from '@apollo/client';
import { GET_USER } from 'pages/Login/login.graphql';
import { userObj } from 'sop-commons/src/client';
import CategoryForm from './CategoryForm';
import { IShowState } from '../hooks/types';
import useCombinedStore from 'zustandStore/store';

interface IProps {
  isOpen: boolean;
  isSaveChapterTemp?: boolean;
  categoryId?: string;
  onClose: () => void;
  setReturnedChapterId: React.Dispatch<React.SetStateAction<string>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setShowState: React.Dispatch<
    React.SetStateAction<{
      showFolder: boolean;
      showVisibility: boolean;
      showTrainingPathFlowModal: boolean;
    }>
  >;
  saveTemporaryHandler: () => void;
  showStateData?: {
    showFolder: boolean;
    showVisibility: boolean;
  };
  onAddCategoryModalClose: () => void;
}

export interface CategoryRef {
  saveChapterHandler: () => void;
}

const CategoryModal = forwardRef<CategoryRef, IProps>((props, ref) => {
  const { visibilitySelection } = useCombinedStore();
  const { t } = useTranslation(['category']);

  const updateShowStateHandler = (data: IShowState) => {
    props.setShowState(data);
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered size={'lg'}>
      <ModalOverlay />
      <ModalContent
        p='24px 24px 32px'
        borderRadius='7px'
        mt={0}
        mb={'5rem'}
        visibility={props.showStateData?.showVisibility ? 'hidden' : 'visible'}
        maxW='640px'
      >
        <ModalCloseButton mt={4} mr={4} />
        <ModalHeader>
          <GenericColorHeader
            color='#CABDFF'
            title={t('category:selectFolder')}
          />
        </ModalHeader>
        <ModalBody>
          <CategoryForm
            categoryId={props.categoryId}
            ref={ref}
            _visibilityData={visibilitySelection!}
            isSaveChapterTemp={props.isSaveChapterTemp}
            updateShowStateHandler={updateShowStateHandler}
            saveTemporaryHandler={props.saveTemporaryHandler}
            onAddCategoryModalClose={props.onAddCategoryModalClose}
          />
          {/* <Controller
            control={control}
            name={'category'}
            render={({ field }) => {
              return (
                <Box mt={2}>
                  <TitleHeader title='Folder' />
                  <CustomSelect
                    options={customCategoryList}
                    setNewCategory={setNewCategory}
                    value={field.value}
                    field={field}
                    handleChange={(option: any) => {
                      if (option.value === 'create') {
                        setNewCategory(true);
                      } else {
                        setValue('subFolder', {
                          label: '',
                          value: '',
                        });
                        field.onChange(option);
                      }
                    }}
                  />
                </Box>
              );
            }}
          />
          {subFoldersPresent && (
            <Controller
              control={control}
              name={'subFolder'}
              render={({ field }) => {
                return (
                  <Box mt={2}>
                    <TitleHeader title='Subfolder (Optional)' />
                    <CustomSelect
                      options={subFoldersList}
                      setNewCategory={setNewCategory}
                      value={field.value}
                      field={field}
                      hideCreateButton
                      handleChange={(option: any) => {
                        if (option.value === 'create') {
                          setNewCategory(true);
                        } else {
                          field.onChange(option);
                        }
                      }}
                    />
                  </Box>
                );
              }}
            />
          )}
          <CategoryActionButton
            category={category}
            isSaveChapterTemp={props.isSaveChapterTemp}
            setShowState={props.setShowState}
            saveTemporaryHandler={props.saveTemporaryHandler}
          /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export default CategoryModal;
CategoryModal.displayName = 'SubComponents/CategoryModal';

import React, { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useModalContext,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import PasswordInput from '../../../../../atoms/PasswordInput';
import { IFormInput, PasswordChangeContext } from './context';

interface IProps {}

const ContentComponent: FC<IProps> = () => {
  const { onSubmit, loading, userId } = useContext(PasswordChangeContext);
  const { t } = useTranslation(['common', 'auth']);
  const { control, getValues, handleSubmit } = useFormContext<IFormInput>();

  const authUserId = useReactiveVar(userObj)?.eid;

  const { onClose } = useModalContext();

  const isMe = authUserId === userId;

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDir='column' gap='16px'>
          {isMe && (
            <Flex gap='16px'>
              <Controller
                control={control}
                name='currentPassword'
                rules={{
                  required: t('auth:validation.current_pass_required'),
                  minLength: {
                    value: 6,
                    message: t('common:validation.password_min_six'),
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <FormControl flex={1} mt={1} isInvalid={!!fieldState.error}>
                      <FormLabel>{t('auth:current_password')}</FormLabel>
                      <PasswordInput
                        id='current-password'
                        formProps={{ ...field }}
                        error={!!fieldState.error}
                        placeholder=' '
                      />
                      <FormErrorMessage>
                        {fieldState?.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              />

              <Box flex={1} />
            </Flex>
          )}

          <Flex gap='16px'>
            <Controller
              control={control}
              name='password'
              rules={{
                required: t('common:validation.password_required'),
                minLength: {
                  value: 6,
                  message: t('common:validation.password_min_six'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={1} mb={3} isInvalid={!!fieldState.error}>
                    <FormLabel>{t('auth:new_password')}</FormLabel>
                    <PasswordInput
                      placeholder={t('common:placeholder.create_password')}
                      id='new-password'
                      rightIcon
                      formProps={{ ...field }}
                      error={!!fieldState.error}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <Controller
              control={control}
              name='confirmPassword'
              rules={{
                required: t('common:validation.conf_pass_required'),
                minLength: {
                  value: 6,
                  message: t('common:validation.password_min_six'),
                },
                validate: (value) =>
                  value === getValues('password') ||
                  t('common:validation.password_not_match'),
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={1} mb={3} isInvalid={!!fieldState.error}>
                    <FormLabel>{t('auth:conf_new_pass')}</FormLabel>
                    <PasswordInput
                      placeholder={t('common:placeholder.confirm_password')}
                      id='confirm-password'
                      rightIcon
                      formProps={{ ...field }}
                      error={!!fieldState.error}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          </Flex>

          <Flex gap='16px' justify='flex-end' mb='16px'>
            <Button minW='140px' px='16px' colorScheme='red' onClick={onClose}>
              {t('common:cancel')}
            </Button>
            <Button
              type='submit'
              minW='140px'
              px='16px'
              colorScheme='blue'
              isLoading={loading}
            >
              {t('common:update')}
            </Button>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

export default ContentComponent;

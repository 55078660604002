import styled from '@emotion/styled';

export const EditorWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .launcher-editor-header {
    position: sticky;
    z-index: 2;
    padding-top: 20px;
    top: -20px;
    margin-top: -20px;
    margin-inline: -38px;
    padding-inline: 38px;
    background: #f4f4f4;

    // tablet and small-desktop
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      margin-inline: -21px;
      padding-inline: 21px;
    }

    // mobile devices
    @media only screen and (max-width: 767px) {
      margin-inline: -19px;
      padding-inline: 19px;
    }

    .ant-steps {
      overflow: auto;
    }
  }

  @supports (animation-timeline: scroll()) {
    .launcher-editor-header {
      animation: scroll-shadow linear both;
      animation-timeline: scroll();
      animation-range: 0ex 5ex;
    }

    @keyframes scroll-shadow {
      from {
        box-shadow: none;
      }

      to {
        box-shadow: 0 5px 7px -8px rgba(0, 0, 0, 1);
      }
    }
  }

  .ant-steps {
    .ant-steps-item {
      .ant-steps-item-icon {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        line-height: 32px;
        margin: 0;
        font-family: Inter, sans-serif;

        border-width: 4px;
        border-style: solid;

        .ant-steps-custom-icon {
          color: white;
          font-size: 15px;
          font-weight: 600;
          user-select: none;
        }
      }
    }

    .ant-steps-item-tail {
      top: 18px;
      margin-left: 20px;
      padding: 0 20px;

      :after {
        height: 4px;
      }
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background: #83bf6e;
        border-color: #b5e4ca;
      }

      .ant-steps-item-tail:after {
        background-color: #b5e4ca;
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-icon {
        background: #fff;
        border-color: #fff;

        .ant-steps-custom-icon {
          color: #6f767e;
        }
      }
      .ant-steps-item-tail:after {
        background-color: white;
      }
    }

    .ant-steps-item-process {
      .ant-steps-item-icon {
        background: #2a85ff;
        border-color: #b1e5fc;
      }

      .ant-steps-item-tail:after {
        background-color: #b1e5fc;
      }
    }

    .add-section {
      min-width: unset !important;

      align-self: flex-start;
      .ant-steps-item-content {
        width: unset;
        margin-top: unset;
      }
    }
  }

  .ant-steps-label-vertical {
    .ant-steps-item {
      min-width: 300px;
      max-width: 3000px; // TODO
    }

    .ant-steps-item-icon {
      margin-left: 0;
    }

    .ant-steps-item-content {
      width: auto;
      text-align: unset;

      .ant-steps-item-title {
        width: calc(100% - 16px);
        font-size: 15px;
        font-weight: 600;
        font-family: Inter, sans-serif;
        color: #111315;
      }
    }
  }

  .ant-steps-vertical {
    .ant-steps-item-wait {
      .ant-steps-item-icon {
        background: #efefef;
        border-color: #fff;

        .ant-steps-custom-icon {
          color: #111315;
        }
      }
    }

    .ant-steps-item {
      .ant-steps-item-container > .ant-steps-item-tail {
        padding: 40px 0 0;
        margin-left: 18px;
        left: 0;

        ::after {
          width: 4px;
        }
      }

      .ant-steps-item-content {
        padding-left: 32px;
        padding-bottom: 16px;

        .ant-steps-item-title {
          position: initial;
          color: initial;
          width: 100%;
          line-height: initial;
          padding: initial;
          font-size: initial;
        }
      }
    }
  }
`;

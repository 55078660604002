import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import FormInput from '../../../atoms/FormInput';
import PrimaryButton from '../../../atoms/PrimaryButton';
import FileVisibility from 'pages/Chapters/CreateChapterModal/FileVisibility';
import { AddCategoryRef, IFormInput, IProps } from './types';
import VisibilityStatusInfo from 'ui-components/VisibilityStatusInfo';
import FolderIconUpload from 'sub-components/FolderIconUpload';
import { useFolderSubFolder, useUserData, useUserDataQuery } from 'hooks';
import {
  AddCategoryQuery,
  AddCategoryResponse,
  AddCategoryVariable,
  FolderVisibilityArgs,
  FolderVisibilityQuery,
  FolderVisibilityResponse,
  UpdateCategoryQuery,
  UpdateCategoryResponse,
  UpdateCategoryVariable,
} from './add-category.graphql';
import {
  compareVisibilityHandler,
  ICompareVisibilityType,
} from 'pages/Chapters/CreateEditSubFolder/components/helper/visibilityHelper';
import { VisibilitySetting } from 'hooks/useVisibilityModule';
import {
  locationUserRoleHelper,
  rolesListSetHandler,
  selectedDataSetHandler,
} from 'pages/Chapters/EditChapterModal/helper';
import { useTranslation } from 'react-i18next';
import { IVisibility } from 'pages/Chapters/CreateChapterModal/UploadFile/UploadFile';
import { useMutation, useReactiveVar } from '@apollo/client';
import { useCategoryList } from 'sub-components/ChapterEditor/CategoryModal/useCategoryList';
import { CategoryEntity, userObj } from 'sop-commons/src/client';
import { loginUserBranchRootObj, usersEntityObj } from 'sub-components/Header';
import { useCustomHistory, useCustomRouteMatch } from './hook';
import { GET_USER } from 'pages/Login/login.graphql';
import { GET_FOLDERS_LIST } from 'pages/Chapters/chapters.graphql';
import { getFoldersList } from 'shared/graphql/SharedGraphql';
import useCombinedStore from 'zustandStore/store';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const AddCategory = forwardRef<AddCategoryRef, IProps>((props, ref) => {
  const editRef = useRef<IFormInput | null>(null);
  const { visibilitySelection, updateVisibilitySelection } = useCombinedStore();
  const usersEntityData = useReactiveVar(usersEntityObj);
  const { selectedData, updateSelectedData } = useCombinedStore();
  const { t: tc } = useTranslation('common');
  const { t } = useTranslation(['chapter']);
  const [membersList, setMembersList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [rolesList, setRolesList] = useState<any[]>([]);
  const [mode, setMode] = useState<'edit' | 'visibility'>('edit');
  const [selectedIconUrl, setSelectedIconUrl] = useState('');
  const [selectedFolderColor, setSelectedFolderColor] = useState('');
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [updatingFolder, setUpdatingFolder] = useState(false);
  const [foundCategoryVisibility, setFoundCategoryVisibility] =
    useState<IVisibility>({
      locations: [],
      roles: [],
      users: [],
      visibility: 'public',
      condition: '',
    });
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const category = useReactiveVar(userObj)?.entity?.category || [];
  const categoryList = useCategoryList();
  const entityId = useReactiveVar(userObj)?.entityId;
  const userBranchRoot = useReactiveVar(loginUserBranchRootObj);

  const [isEdit, setIsEdit] = useState(false);

  const zIndex = props.zIndex || '1403';

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
    getValues,
    setValue,
  } = useForm<IFormInput>();

  const categoryName = useWatch<IFormInput>({
    control: control,
    name: 'category',
  });

  const oldCategoryName = useWatch<IFormInput>({
    control: control,
    name: 'oldCategory',
  });

  const { getAllFolderSubFolderList } = useFolderSubFolder();

  let userData = useUserData();

  const { subFolders, allSubFoldersList } = useFolderSubFolder(
    getValues('categoryId')
  );

  const onModalClosed = () => {
    reset({
      category: '',
    });
    setIsEdit(false);
    props.onClose?.();
  };

  const visibilityHandler = (categoryId: string) => {
    const input: FolderVisibilityArgs = {
      input: {
        categoryId: categoryId,
        copyVisibility: true,
        ...{
          condition: visibilitySelection?.condition
            ? visibilitySelection?.condition
            : undefined,
        },
        locations: visibilitySelection?.locations || [],
        roles: visibilitySelection?.roles || [],
        users: visibilitySelection?.users || [],
        visibility: visibilitySelection?.visibility || 'public',
      },
    };
    updateFolderVisibility({
      variables: input,
    });
  };

  const routeMatch = useCustomRouteMatch<{ category: string }>();
  const history = useCustomHistory();
  const onFetchCompleted = () => {
    if (editRef.current?.category && routeMatch && history) {
      const selectedCategory = routeMatch?.params?.category;
      if (selectedCategory === editRef.current?.oldCategory) {
        const newPath = routeMatch.url?.replace(
          selectedCategory,
          editRef.current.category
        );
        const oldState = history.location.state as any;
        if (oldState?.category === editRef.current.oldCategory) {
          oldState.category = history.location.state;
        }
        history.replace(newPath, history.location.state);
      }
      editRef.current = null;
    }
  };

  const [createCategory] = useMutation<
    AddCategoryResponse,
    AddCategoryVariable
  >(AddCategoryQuery, {
    onCompleted: (data) => {
      // visibilityHandler(data?.addCategory?.eid);
      const NCatName = getValues('category');
      // const _category =
      //   createCategoryData?.addCategory?.category || [];
      // const category = _category.find((val) => val.name === NCatName);
      const category = data?.addCategory;
      toast({
        status: 'success',
        title: tc('success'),
        description: 'Folder created successfully',
        // @ts-ignore
        // description: t('chapter:successFolderAdded', {
        //   category: category.name,
        // }),
      });
      if (props?.onCategoryCreate && category) {
        props?.onCategoryCreate({
          ...category,
          icon: category?.icon,
          name: category?.name,
          status: category?.status,
          visibleTo: {
            condition:
              category?.visibility === 'private' &&
              (category?.visibleTo?.locations?.length > 0 ||
                category?.visibleTo?.roles?.length > 0)
                ? category?.visibleTo?.condition
                : undefined,
            locations: visibilitySelection?.locations || [],
            roles: visibilitySelection?.roles || [],
            users: visibilitySelection?.users || [],
          },
          visibility: visibilitySelection?.visibility || 'public',
        });
      }
      fetchFoldersList();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Folder could not be created',
      });
      setCreatingFolder(false);
      onModalClosed?.();
    },
  });

  const onSuccess = (data: CategoryEntity[]) => {
    setCreatingFolder(false);
    setUpdatingFolder(false);
    onModalClosed?.();
  };

  const { execute: fetchFoldersList } = getFoldersList(onSuccess);

  const [updateFolderVisibility, { loading: updatingFolderVisibility }] =
    useMutation<FolderVisibilityResponse, FolderVisibilityArgs>(
      FolderVisibilityQuery,
      {
        onCompleted: (data) => {
          console.log('Update Folder Visibility data : ', data);
          props?.onCategoryUpdate?.(
            getValues('category'),
            getValues('oldCategory')
          );
          editRef.current = getValues();
          fetchFoldersList();
          toast({
            status: 'success',
            title: tc('success'),
            description: t('chapter:successFolderUpdated'),
          });
          // onFetchCompleted();
          // onModalClosed?.();
        },
        onError: () => {
          setUpdatingFolder(false);
          onModalClosed?.();
        },
      }
    );

  const [updateCategory] = useMutation<
    UpdateCategoryResponse,
    UpdateCategoryVariable
  >(UpdateCategoryQuery, {
    onCompleted: () => {
      const input: FolderVisibilityArgs = {
        input: {
          categoryId: getValues('categoryId'),
          copyVisibility: true,
          ...{
            condition: visibilitySelection?.condition
              ? visibilitySelection?.condition
              : undefined,
          },
          locations: visibilitySelection?.locations || [],
          roles: visibilitySelection?.roles || [],
          users: visibilitySelection?.users || [],
          visibility: visibilitySelection?.visibility || 'public',
        },
      };
      updateFolderVisibility({
        variables: input,
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        status: 'error',
        title: 'Folder could not be updated',
      });
      onModalClosed?.();
    },
  });

  // const visibilityStatus: ICompareVisibilityType = useMemo(() => {
  //   const folder: VisibilitySetting = {
  //     eid: getValues('categoryId'),
  //     type: visibilitySelection?.visibility || 'public',
  //     condition: visibilitySelection?.visibility,
  //     jobs: visibilitySelection?.roles || [],
  //     locations: visibilitySelection?.locations || [],
  //     members: visibilitySelection?.users || [],
  //   };
  //   const _subFolders: VisibilitySetting[] = subFolders?.map((sub) => {
  //     return {
  //       eid: sub.eid,
  //       type: sub.visibility || 'public',
  //       condition: sub.visibility,
  //       jobs: sub.visibleTo.roles,
  //       locations: sub.visibleTo.locations,
  //       members: sub.visibleTo.users,
  //     };
  //   });
  //   // const status = intersectionStatus({ folder, subFolders: _subFolders });
  //   let visibilityConditionType: ICompareVisibilityType =
  //     'parentChildNoIntersection';
  //   let visibilityConditionArray: ICompareVisibilityType[] = [];
  //   _subFolders?.forEach((sub) => {
  //     let _visibilityConditionType = compareVisibilityHandler(
  //       folder,
  //       sub,
  //       usersEntityData
  //     );
  //     visibilityConditionArray?.push(_visibilityConditionType);
  //   });
  //   if (
  //     visibilityConditionArray.every(
  //       (arr) => arr === 'parentChildNoIntersection'
  //     )
  //   ) {
  //     visibilityConditionType = 'parentChildNoIntersection';
  //   } else if (
  //     visibilityConditionArray.every(
  //       (arr) => arr === 'parentChildSameLengthDifferentVisibility'
  //     )
  //   ) {
  //     visibilityConditionType = 'parentChildSameLengthDifferentVisibility';
  //   } else if (
  //     visibilityConditionArray.every(
  //       (arr) => arr === 'parentChildSameVisibleSameLength'
  //     )
  //   ) {
  //     visibilityConditionType = 'parentChildSameVisibleSameLength';
  //   } else if (
  //     visibilityConditionArray.every(
  //       (arr) => arr === 'parentLessVisibleThanChild'
  //     )
  //   ) {
  //     visibilityConditionType = 'parentLessVisibleThanChild';
  //   } else if (
  //     visibilityConditionArray.every(
  //       (arr) => arr === 'parentMoreVisibleThanChild'
  //     )
  //   ) {
  //     visibilityConditionType = 'parentMoreVisibleThanChild';
  //   } else {
  //     visibilityConditionType = 'parentChildNoIntersection';
  //   }
  //   return visibilityConditionType;
  // }, [visibilitySelection, subFolders]);

  // const { loading: locationUserLoading, data } = useQuery(GET_BRANCH, {
  //   fetchPolicy: 'network-only',
  // });

  useEffect(() => {
    if (userBranchRoot) {
      let _data = JSON.parse(JSON.stringify({ branch: userBranchRoot }));
      locationUserRoleHelper(
        _data,
        userData,
        setMembersList,
        setLocationsList,
        selectedData,
        updateSelectedData
      );
    }
  }, [userBranchRoot]);

  useEffect(() => {
    rolesListSetHandler(userData, setRolesList);
  }, [userData]);

  useEffect(() => {
    selectedDataSetHandler(
      membersList,
      locationsList,
      visibilitySelection!,
      rolesList,
      updateSelectedData
    );
  }, [locationsList, membersList, visibilitySelection]);

  useEffect(() => {
    if (props.open) {
      updateVisibilitySelection({
        locations: [],
        roles: [],
        users: [],
        visibility: 'public',
        condition: '',
      });
      updateSelectedData({
        locations: [],
        members: [],
        roles: [],
      });
    }
  }, [props.open]);

  useEffect(() => {
    if (isEdit && categoryList) {
      let _subFoldersList = allSubFoldersList();
      let _processedSubFoldersList: any[] = [];
      _subFoldersList?.map((l) => {
        _processedSubFoldersList?.push({
          ...l,
          visibility: {
            visibleTo: l?.visibleTo,
            visibility: l?.visibility,
          },
        });
      });
      let aggregatedFoldersList = [
        ...categoryList,
        ..._processedSubFoldersList,
      ];
      let foundCategory = aggregatedFoldersList?.find(
        (cat) => cat?.eid === getValues('categoryId')
      );
      setFoundCategoryVisibility({
        locations: foundCategory?.visibility?.visibleTo?.locations || [],
        roles: foundCategory?.visibility?.visibleTo?.roles || [],
        users: foundCategory?.visibility?.visibleTo?.users || [],
        condition: foundCategory?.visibility?.visibleTo?.condition,
        visibility: foundCategory?.visibility?.visibility || 'public',
      });
      updateVisibilitySelection({
        locations: foundCategory?.visibility?.visibleTo?.locations || [],
        roles: foundCategory?.visibility?.visibleTo?.roles || [],
        users: foundCategory?.visibility?.visibleTo?.users || [],
        condition: foundCategory?.visibility?.visibleTo?.condition,
        visibility: foundCategory?.visibility?.visibility || 'public',
      });
    }
  }, [isEdit, categoryList]);

  useImperativeHandle(
    ref,
    () => {
      return {
        initialise: (values: IFormInput) => {
          reset(values);
          setValue('oldCategory', values.category);
          setIsEdit(true);
        },
        mode: (type: 'edit' | 'visibility') => {
          setMode(type);
        },
        folderIconDetails: (data) => {
          setSelectedFolderColor(data.color);
          setSelectedIconUrl(data.icon);
        },
      };
    },
    [reset]
  );

  const submitHandler = (value: IFormInput) => {
    if (isEdit) {
      deployEvent(AmplitudeEventNames.EDIT_FOLDER_BACKEND);
      setUpdatingFolder(true);
      return updateCategory({
        variables: {
          category: value.category,
          categoryId: value.categoryId,
          ...(selectedFolderColor ? { color: selectedFolderColor } : {}),
          ...(selectedIconUrl ? { icon: selectedIconUrl } : {}),
          // oldCategory: value.oldCategory,
          // newCategory: value.category,
        },
      });
      // if (value.oldCategory !== value.category) {
      //   return _queries.updateCategory({
      //     variables: {
      //       category: value.category,
      //       categoryId: value.categoryId,
      //       ...(selectedFolderColor ? {color: selectedFolderColor} : {}),
      //       ...(selectedIconUrl ? {color: selectedIconUrl} : {}),
      //       // oldCategory: value.oldCategory,
      //       // newCategory: value.category,
      //     },
      //   });
      // } else {
      //   visibilityHandler(value.categoryId);
      // }
    } else {
      deployEvent(AmplitudeEventNames.ADD_FOLDER);
      setCreatingFolder(true);
      return createCategory({
        variables: {
          eid: entityId,
          category: value.category,
          ...(selectedIconUrl ? { icon: selectedIconUrl } : {}),
          ...(selectedFolderColor ? { color: selectedFolderColor } : {}),
          copyVisibility: true,
          ...{
            condition: visibilitySelection?.condition
              ? visibilitySelection?.condition
              : undefined,
          },
          locations: visibilitySelection?.locations || [],
          roles: visibilitySelection?.roles || [],
          users: visibilitySelection?.users || [],
          condition: visibilitySelection?.condition || undefined,
          visibility: visibilitySelection?.visibility || 'public',
        },
      });
    }
  };

  return (
    <Modal
      isOpen={props.open}
      isCentered={true}
      onClose={onModalClosed}
      // onCloseComplete={onModalClosed}
      closeOnOverlayClick={false}
    >
      <ModalOverlay zIndex={zIndex} />
      <ModalContent
        width={{
          sm: '500px',
          md: '500px',
          base: '500px',
          xl: '500px',
          ['2xl']: '500px',
        }}
        minWidth={{
          sm: '500px',
          md: '500px',
          base: '500px',
          xl: '500px',
          ['2xl']: '500px',
        }}
        borderRadius={{
          sm: '16px',
          md: '16px',
          base: '16px',
          xl: '16px',
          ['2xl']: '16px',
        }}
        containerProps={{
          zIndex: zIndex,
        }}
      >
        <ModalHeader
          px='50px'
          p={mode === 'edit' ? '40px 40px' : '40px 40px 0px 40px'}
        >
          <Flex align='center' justify='space-between'>
            <Flex gap='10px' flex='1'>
              <Box h='32px' w='16px' borderRadius='4px' bg='#b5e4ca' />
              <div className='modal-title'>
                {mode === 'visibility'
                  ? `${getValues('category')} visibility`
                  : isEdit
                  ? t('chapter:editFolder')
                  : t('chapter:addNewFolder')}
              </div>
            </Flex>
            <Flex>
              <ModalCloseButton
                pos='relative'
                top='unset'
                right='unset'
                borderRadius='full'
                bg='rgb(239, 239, 239, 0.6)'
              />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody px='50px' pt={0} pb='40px' id='add-category'>
          <form onSubmit={handleSubmit(submitHandler)}>
            {mode === 'edit' && (
              <Controller
                name='category'
                control={control}
                defaultValue=''
                rules={{
                  required: t('chapter:validation.folderRequired'),
                  validate: (value) => {
                    if (value?.trim()?.length === 0) {
                      return t('chapter:validation.enterFolder');
                    }
                    if (
                      getAllFolderSubFolderList()?.some(
                        (list) => list?.name === value?.trim()
                      )
                    ) {
                      return `${value?.trim()} already exists`;
                    }

                    // // In add mode, check if the category already exists
                    // if (
                    //   !isEdit &&
                    //   categoryData.some((cat) => cat === value?.trim())
                    // ) {
                    //   // @ts-ignore
                    //   return t('chapter:validation.folderExists', {
                    //     category: value,
                    //   });
                    // }

                    // // In edit mode, allow same value as oldCategory, but check for duplicates otherwise
                    // if (isEdit) {
                    //   if (
                    //     value !== getValues('oldCategory') &&
                    //     categoryData.some((cat) => cat === value?.trim())
                    //   ) {
                    //     // @ts-ignore
                    //     return t('chapter:validation.folderExists', {
                    //       category: value,
                    //     });
                    //   }
                    // }
                  },
                  // validate: (value) => {
                  //   /**
                  //    * We will not use .toLowerCase() because we need to do matching with exact folder/category name and not after doing string manipulation
                  //    */
                  //   if (value?.trim()?.length === 0) {
                  //     return t('chapter:validation.enterFolder');
                  //   } else if (
                  //     categoryData.some((cat) => cat === value?.trim())
                  //   ) {
                  //     if (value === getValues('oldCategory') && isEdit) {
                  //       return t('chapter:validation.folderExit');
                  //     }
                  //     // @ts-ignore
                  //     return t('chapter:validation.folderExists', {
                  //       category: value,
                  //     });
                  //   }
                  // },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <Flex align='center'>
                      <FormControl isInvalid={!!fieldState.error}>
                        <Flex align='center' gap='5px'>
                          <FolderIconUpload
                            selectedFolderColor={selectedFolderColor}
                            setSelectedFolderColor={setSelectedFolderColor}
                            selectedIconUrl={selectedIconUrl}
                            setSelectedIconUrl={setSelectedIconUrl}
                          />
                          <FormInput
                            id='category'
                            size='lg'
                            placeholder={t('chapter:folderName')}
                            {...field}
                          />
                        </Flex>
                        <FormErrorMessage>
                          <span style={{ marginLeft: '50px' }}>
                            {fieldState.error?.message}
                          </span>
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  );
                }}
              />
            )}

            <Flex mt={4}>
              <FileVisibility
                isEditMode
                hideChangeVisibilityBtn={mode === 'visibility'}
                viewDirection='top-bottom'
                type={props.type}
              />
            </Flex>
            {isEdit && (
              <Flex mt={4}>
                <VisibilityStatusInfo
                  parentDetails={{
                    locations: visibilitySelection?.locations || [],
                    roles: visibilitySelection?.roles || [],
                    users: visibilitySelection?.users || [],
                    condition: visibilitySelection?.condition,
                    visibility: visibilitySelection?.visibility,
                  }}
                  type={props.type}
                  categoryId={getValues('categoryId')}
                  folderName={getValues('category')}
                />
              </Flex>
            )}
            {mode === 'edit' && (
              <PrimaryButton
                type='submit'
                size='lg'
                title={isEdit ? tc('update') : t('chapter:addFolder')}
                style={{ marginTop: '16px' }}
                variant='solid'
                colorScheme='blue'
                isLoading={
                  isSubmitting ||
                  updatingFolderVisibility ||
                  creatingFolder ||
                  updatingFolder
                }
                disabled={
                  isSubmitting ||
                  updatingFolderVisibility ||
                  creatingFolder ||
                  updatingFolder ||
                  !categoryName
                }
                // disabled={
                //   isSubmitting ||
                //   updatingFolderVisibility ||
                //   getVisibilityChange()
                // }
              />
            )}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

AddCategory.displayName = 'Add Category';

export default AddCategory;

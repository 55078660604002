import { useCallback } from 'react';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { useFormContext, UseFormReturn } from 'react-hook-form';
import { cloneDeep } from '@apollo/client/utilities';

import { userObj } from 'sop-commons/src/client';

import { PayloadInput } from '../../../../types';
import { updateObject } from '../../../../utils/objectHelper';

import {
  BaseFormInput,
  UPDATE_CONFIG_QUERY,
  UpdateLauncherInput,
} from './index';

import { transformCreateData } from './transform-create-data';
import { LauncherConfig, LauncherConfigFragment } from './fragment.graphql';
import { transformUpdateData } from './helpers';
import { useAddLauncherConfig } from './useAddLauncherConfig';
import { getLauncherDefaultValues } from '../editor-form/getLauncherDefaultValues';

export const LAUNCHER_DATA = gql`
  ${LauncherConfigFragment}
  query LauncherData($eid: String!) {
    LauncherById(eid: $eid) {
      ...LauncherConfig
    }
  }
`;

export interface LauncherResponse {
  LauncherById: LauncherConfig;
}

interface UpdateResponse {
  UpdateLauncherTasks: LauncherConfig;
}

interface MutationOptions {
  onCompleted?: (data: LauncherConfig) => void;
  onError?: (error: ApolloError) => void;
}

type ConfigSubmit = (inputs?: UpdateLauncherInput) => Promise<LauncherConfig>;

export function useConfigSubmit<T extends BaseFormInput>(
  methods?: UseFormReturn<T>,
  options: MutationOptions = {}
): ConfigSubmit {
  const { getValues, setValue, reset, setError } =
    methods || useFormContext<T>();

  const [updateLauncherConfig] = useMutation<
    UpdateResponse,
    PayloadInput<UpdateLauncherInput>
  >(UPDATE_CONFIG_QUERY, {
    ...options,
    onCompleted: (data) => {
      const launcher = data?.UpdateLauncherTasks;
      const userData = cloneDeep(userObj());
      if (launcher && userData?.entity?.eid) {
        updateObject(
          userData,
          ['entity', 'launcher', 'contents'],
          launcher.contents
        );
        updateObject(
          userData,
          ['entity', 'launcher', 'published'],
          launcher.published
        );
        userObj(userData);
      }

      options?.onCompleted?.(launcher);
    },
    onError: (err) => {
      const message = err.graphQLErrors?.[0]?.message?.split(': ');
      if (message && message[1]?.includes('contents.')) {
        setError(message[1] as never, {
          message: message[2],
          type: 'validate',
        });
      }
      options?.onError?.(err);
    },
  });

  const [addLauncherConfig] = useAddLauncherConfig({
    onCompleted: (launcher) => {
      if (launcher) {
        // @ts-ignore
        setValue('eid', launcher.eid);
        // @ts-ignore
        reset(getLauncherDefaultValues(launcher));
      }
    },
  });

  return useCallback((inputs) => {
    const _inputs = inputs || transformUpdateData(getValues());

    if (_inputs.eid) {
      return updateLauncherConfig({
        variables: {
          input: _inputs,
        },
      }).then((resp) => {
        if (resp.errors) {
          throw resp.errors;
        }
        return resp?.data?.UpdateLauncherTasks!;
      });
    }

    return addLauncherConfig({
      variables: {
        input: transformCreateData(_inputs as never, true),
      },
    }).then((resp) => {
      if (resp.errors) {
        throw resp.errors;
      }
      return resp?.data?.AddLauncher!;
    });
  }, []);
}

import React, { FC } from 'react';
import {
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  IconButton,
  Image,
} from '@chakra-ui/react';
import { CometChat } from '@cometchat-pro/chat';
import moment from 'moment/moment';
import { Trans, useTranslation } from 'react-i18next';

import { FormInput } from '../../../../../atoms';
import { ChatGroupEntity } from '../../../../../types';
import { useUserEntity } from '../../../../../hooks';

import chatGroupIcon from '../../../../../assets/images/chat-group.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/images/edit-dark.svg';

interface IProps {
  initialValue: ChatGroupEntity;
  onEditButtonClick: () => void;
}

const AboutChannel: FC<IProps> = ({ initialValue, onEditButtonClick }) => {
  const { t } = useTranslation(['common', 'setting']);

  const entityName = useUserEntity((entity) => entity.name);

  const isDefault =
    initialValue?.details?.isEditable === false || !initialValue?.hasJoined;

  return (
    <div>
      <Flex gap={2}>
        <Center borderRadius='8px' minW='48px' boxSize='48px' bg='#EEEEEE'>
          <Image src={chatGroupIcon} />
        </Center>

        <FormInput
          size='lg'
          placeholder={t('setting:placeholder.channelName')}
          value={initialValue.name}
          isReadOnly
        />

        <IconButton
          size='lg'
          aria-label='edit-channel-name'
          icon={<EditIcon color='#6F767E' />}
          onClick={onEditButtonClick}
        />
      </Flex>

      {!isDefault && (
        <Checkbox
          mt={4}
          color='#6F767E'
          isChecked={initialValue.details?.type === CometChat.GROUP_TYPE.PUBLIC}
          isReadOnly
        >
          <Box fontSize='14px'>
            <Trans
              t={t}
              i18nKey='setting:everyoneInOrgAddedToChannel'
              values={{
                name: entityName,
              }}
            />
          </Box>
        </Checkbox>
      )}

      <Divider opacity={1} borderColor='#EFEFEF' my={4} />

      <Flex flexDir='column' pb={2}>
        <Box fontSize='16px' fontWeight='500' color='#1A1D1F'>
          Created by
        </Box>
        <Box fontSize='14px' fontWeight='400' color='#6F767E'>
          {initialValue.owner?.name} on{' '}
          {moment.unix(initialValue.createdAt).format('MMM DD, YYYY')}
        </Box>
      </Flex>
    </div>
  );
};

export default AboutChannel;

import { useMutation, useReactiveVar } from '@apollo/client';
import { Flex, Progress, Text, useToast } from '@chakra-ui/react';
import { PrimaryButton } from 'atoms';
import { useUploadMultipleImages } from 'hooks/useUploadMultipleImages';
import { UPDATE_FILES } from 'pages/LocationDetails/service/location-details.graphql';
import { IFilesEntity } from 'pages/LocationDetails/utils/location-details.types';
import React, { FC, useEffect, useState } from 'react';
import { userObj } from 'sop-commons/src/client';
import { getThumbnail } from 'utils/fileThumbnail';
import { getFileType } from 'utils/utils';
import { IDocUploadCloseType } from '../hooks/useDocumentsUpload';
import DocumentSkeleton from './DocumentSkeleton';
import DocumentThumbnailDetail from './DocumentThumbnailDetail';

export interface IThumbnailProps {
  type: string;
  content: React.ReactNode | string;
}

interface IUploadFilesEntity {
  createdBy: string | undefined;
  fileSize: number;
  mimetype: string | undefined;
  name: string;
  type: string;
  url: string;
}

export interface Crop {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ICropImgFilesData {
  imageBase64Url: string;
  zoom: number;
  crop: { x: number; y: number };
  croppedImageFile: File;
  croppedAreaPixels: Crop;
}

interface IProps {
  uploadedFiles: IFilesEntity[];
  onCloseHandler: (type: IDocUploadCloseType) => void;
  refetchHandler: () => void;
  locationId: string;
  type: 'add' | 'edit';
  fileIndex?: number;
}

const DocumentsUploadComponent: FC<IProps> = ({
  uploadedFiles,
  locationId,
  onCloseHandler,
  refetchHandler,
  type,
  fileIndex,
}) => {
  const userData = useReactiveVar(userObj);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState(-1);
  const [files, setFiles] = useState<File[]>([]);
  const [progresses, setProgresses] = useState<Record<number, number>>({});
  const uploadMultipleImages = useUploadMultipleImages();
  const [thumbnails, setThumbnails] = useState<IThumbnailProps[]>([]);
  const [cropImgFilesData, setCropImgFilesData] = useState<ICropImgFilesData[]>(
    []
  );

  const [updateFiles, { loading }] = useMutation(UPDATE_FILES, {
    onCompleted: () => {
      onCloseHandler('refetch');
      toast({
        title: 'File(s) updated successfully',
        status: 'success',
      });
      refetchHandler();
    },
    onError: () => {
      toast({
        title: 'File(s) could not be updated',
        status: 'error',
      });
      onCloseHandler('refetch');
      refetchHandler();
    },
  });

  useEffect(() => {
    const generateThumbnails = async () => {
      const thumbs = await Promise.all(
        files.map(async (file) => {
          const process = await getThumbnail(file);
          return process;
        })
      );
      setThumbnails(thumbs);
      setSelectedThumbnail(0);
    };

    generateThumbnails();
  }, [files]);

  const uploadHandler = async () => {
    setIsLoading(true);
    let _filesData: File[] = [];
    files?.map((file, index) => {
      if (!file?.type?.includes('image')) {
        _filesData?.push(file);
      } else {
        if (cropImgFilesData?.[index]?.croppedImageFile) {
          _filesData?.push(cropImgFilesData?.[index]?.croppedImageFile!);
        }
      }
    });
    const fileUrls = await uploadMultipleImages(
      _filesData,
      (progress, index) => {
        setProgresses((prevProgresses) => {
          return { ...prevProgresses, [index]: progress };
        });
      }
    );
    const fileObjs = files.map((file, index) => ({
      createdBy: userData?.eid,
      fileSize: file.size,
      mimetype: file.type,
      name: file.name,
      type: getFileType(file.type),
      url: fileUrls[index],
    }));
    let filesToUpload: IUploadFilesEntity[] = [];
    let _uploadedFiles = uploadedFiles?.map((file) => {
      return {
        createdBy: file?.createdBy?.eid,
        fileSize: file?.fileSize,
        mimetype: file?.mimetype,
        name: file?.name,
        type: file?.type,
        url: file?.url,
      };
    });
    if (type === 'add') {
      filesToUpload = [..._uploadedFiles, ...fileObjs];
    } else {
      filesToUpload = [
        ..._uploadedFiles.slice(0, fileIndex!),
        ...fileObjs,
        ..._uploadedFiles.slice(fileIndex! + 1),
      ];
    }
    setIsLoading(false);
    updateFiles({
      variables: {
        input: {
          eid: locationId,
          files: filesToUpload,
        },
      },
    });
  };

  return (
    <Flex>
      {files.length === 0 ? (
        <DocumentSkeleton
          setFiles={setFiles}
          setCropImgFilesData={setCropImgFilesData}
        />
      ) : (
        <Flex flexDir='column' gap={4} w='full'>
          {!isLoading ? (
            <DocumentThumbnailDetail
              cropImgFilesData={cropImgFilesData}
              files={files}
              thumbnails={thumbnails}
              setCropImgFilesData={setCropImgFilesData}
              selectedThumbnail={selectedThumbnail}
              setSelectedThumbnail={setSelectedThumbnail}
            />
          ) : (
            <Flex gap='10px' flexDir='column' w='full'>
              {files.map((_, index) => (
                <Flex
                  key={index}
                  flexDir='column'
                  border='1px solid #DDDDDD'
                  borderRadius='6px'
                  w='full'
                  p={2}
                >
                  <Flex gap='10px'>
                    {thumbnails?.[index]?.content}
                    <Flex flexDir='column'>
                      <Text fontWeight={600} fontSize='16px'>
                        Please wait! File uploading in progress
                      </Text>
                      <Text
                        fontWeight={400}
                        fontSize='14px'
                        color='#33383F'
                        isTruncated
                        maxW='400px'
                      >
                        {files[index]?.name}
                      </Text>
                    </Flex>
                  </Flex>
                  <Progress
                    isAnimated
                    value={progresses[index] || 0}
                    width='100%'
                    size='xs'
                    mt={2}
                    colorScheme={progresses[index] === 100 ? 'green' : 'blue'}
                  />
                </Flex>
              ))}
            </Flex>
          )}
          <Flex w='full' justify='flex-end' align='center' gap='10px'>
            <PrimaryButton
              title={type === 'add' ? 'Upload' : 'Update'}
              isLoading={isLoading || loading}
              disabled={isLoading || loading}
              colorScheme='blue'
              width='fit-content'
              onClick={uploadHandler}
            />
            <PrimaryButton
              title='Cancel'
              variant='outline'
              width='fit-content'
              onClick={() => onCloseHandler('close')}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default DocumentsUploadComponent;

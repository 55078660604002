import React, { cloneElement, forwardRef, isValidElement } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

interface PickerButtonProps extends ButtonProps {
  readOnly?: boolean;
}

const DateInputButton = forwardRef<never, PickerButtonProps>(
  (
    { value, onClick, placeholder, readOnly, disabled, children, ...rest },
    ref
  ) => {
    return (
      <Button
        width='full'
        size='lg'
        fontSize='14px'
        fontWeight='500'
        borderRadius='12px'
        justifyContent='space-between'
        rightIcon={<FontAwesomeIcon icon={faCaretDown as IconProp} size='lg' />}
        variant='solid'
        aria-readonly={readOnly}
        onClick={onClick}
        ref={ref}
        isDisabled={disabled}
        _readOnly={{
          bg: 'gray.100',
          opacity: '0.6',
          cursor: 'initial',
        }}
        {...rest}
      >
        {isValidElement(children)
          ? cloneElement(children, {
              children: value || placeholder,
            })
          : value || placeholder}
      </Button>
    );
  }
);

DateInputButton.displayName = 'DateInputButton';

export default DateInputButton;

import React from 'react';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChangeLogWrapper } from './ChangeLog.styles';
import closeIcon from '../../../assets/images/closeIcon.png';
import ChangeLogList from './ChangeLogList';

export type IChangeLogContainer = {
  open: boolean;
  onClose: (type: string) => void;
  selectedDraftData: any;
  isReadOnly: boolean;
};

const ChangeLog = ({
  open,
  onClose,
  selectedDraftData,
  isReadOnly,
}: IChangeLogContainer) => {
  const { t } = useTranslation('training');
  return (
    <Modal isOpen={open} onClose={() => onClose('close')} size={'6xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={'10'}>
          <ChangeLogWrapper>
            <div className='change-log-header-container'>
              <div className='change-log-heading-container'>
                <div className='change-log-color' />
                <div className='change-log-heading'>{t('change_log')}</div>
              </div>
              <div>
                <img
                  src={closeIcon}
                  className='cursor-pointer'
                  onClick={() => onClose('close')}
                />
              </div>
            </div>
            <div className='change-log-list-container'>
              <ChangeLogList
                selectedDraftData={selectedDraftData}
                onClose={onClose}
                isReadOnly={isReadOnly}
              />
            </div>
          </ChangeLogWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChangeLog;

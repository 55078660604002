import React, { FC, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import { match } from 'ts-pattern';
import { Trans, useTranslation } from 'react-i18next';
import { TFuncKey } from 'i18next';

import { toArray } from '../../../../utils/utils';

import { AssignToType, IFormInput } from '../task.types';
import { InfoText, SelectOption } from '../common';
import { useLocationChips } from './Locations/LocationChips';

interface IAInfo {
  i18nKey: TFuncKey<'task', undefined>;
  values: Record<string, string[]>;
  context?: string;
}

export const useAssigneesInfo = (members: SelectOption[] = []): IAInfo[] => {
  const locations = useLocationChips();

  const [assignToType, assignedLocationsType, assignedToLocation] = useWatch<
    IFormInput,
    ['assignToType', 'assignedLocationsType', 'assignedToLocation']
  >({
    name: ['assignToType', 'assignedLocationsType', 'assignedToLocation'],
  });

  const [assignedToRole, assignedToUsers] = useWatch<
    IFormInput,
    ['assignedToRole', 'assignedToUsers']
  >({
    name: ['assignedToRole', 'assignedToUsers'],
  });

  return useMemo(() => {
    return toArray(assignToType).reduce<IAInfo[]>((acc, value) => {
      const checkValue = match<AssignToType, IAInfo | undefined>(value)
        .with('location', () => {
          if (
            assignedToLocation?.length &&
            assignedLocationsType &&
            locations?.length
          ) {
            return {
              i18nKey: 'assigneeInfo.location',
              values: {
                locations: locations.reduce<string[]>(
                  (previousValue, currentValue) => {
                    if (assignedToLocation.includes(currentValue.value)) {
                      previousValue.push(currentValue.label);
                    }
                    return previousValue;
                  },
                  []
                ),
              },
              context: assignedLocationsType,
            };
          }
        })
        .with('member', () => {
          if (assignedToUsers?.length && members?.length) {
            return {
              i18nKey: 'assigneeInfo.member',
              values: {
                users: members.reduce<string[]>(
                  (previousValue, currentValue) => {
                    if (assignedToUsers.includes(currentValue.value)) {
                      previousValue.push(currentValue.label);
                    }
                    return previousValue;
                  },
                  []
                ),
              },
            };
          }
        })
        .with('job', () => {
          if (assignedToRole?.length) {
            return {
              i18nKey: 'assigneeInfo.job',
              values: {
                jobs: assignedToRole,
              },
            };
          }
        })
        .otherwise(() => undefined);

      if (checkValue) {
        acc.push(checkValue);
      }

      return acc;
    }, []);
  }, [
    assignToType,
    assignedLocationsType,
    assignedToLocation,
    assignedToRole,
    assignedToUsers,
    members,
    locations,
  ]);
};

interface IProps {
  members: SelectOption[];
}

const AssigneeHint: FC<IProps> = ({ members = [] }) => {
  const { t } = useTranslation('task');

  const data = useAssigneesInfo(members);

  if (!data?.length) {
    return null;
  }

  return (
    <Box borderTop='1px solid #E9E9E9'>
      {data?.map((value) => {
        return (
          <InfoText key={value?.i18nKey}>
            <Trans t={t} {...value} />
          </InfoText>
        );
      })}
    </Box>
  );
};

export default AssigneeHint;

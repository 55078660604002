import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { Center, CheckboxGroup, Flex, Image } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { searchRegExp, toArray } from '../../../../../utils';
import { ActionButton } from '../../../../../ui-components';

import MoveFormLoader from './MoveFormLoader';
import MoveFormLists from './MoveFormLists';
import MoveHeader from './MoveHeader';

import { useDeleteContext } from '../context';
import { moveFormListGraphQL, MoveFormListResponse } from './move-form.graphql';

import noFormEmptyState from '../../../../../assets/images/empty-state/no-form-empty-state.svg';

interface IProps {}

const MoveFormContainer: FC<IProps> = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [checked, setChecked] = useState<(string | number)[]>([]);

  const { category, totalFormCount, actionHandler, onCategoryDeleted } =
    useDeleteContext();

  const { data, loading } = useQuery<MoveFormListResponse>(
    moveFormListGraphQL,
    {
      skip: !category?.eid,
      variables: {
        page: 1,
        perPage: totalFormCount,
        filter: {
          categoryId: category?.eid,
        },
      },
    }
  );

  const list = useMemo(() => {
    return toArray(cloneDeep(data?.FormsPagination?.items));
  }, [data?.FormsPagination?.items]);

  const filterList = useMemo(() => {
    if (searchQuery) {
      const reg = searchRegExp(searchQuery, 'gi');
      return list.filter((item) => {
        return item.title?.match(reg);
      });
    }
    return list;
  }, [searchQuery, list]);

  const onAllSelect = (
    event: ChangeEvent<HTMLInputElement>,
    _values: string[] = []
  ) => {
    if (event.target.checked) {
      setChecked((prevState) => {
        const _newValue = _values.filter((v) => !prevState.includes(v));
        return [...prevState, ..._newValue];
      });
    } else {
      setChecked((prevState) => {
        const _newValue = prevState.filter((v) => !_values.includes(String(v)));
        return [..._newValue];
      });
    }
  };

  const onMoveSuccess = () => {
    setChecked([]);
    onCategoryDeleted?.();
  };

  if (!list?.length && !loading) {
    return (
      <Flex flex={1} flexDirection='column' minH='calc(100vh - 6rem - 104px)'>
        <MoveHeader
          haveForms={false}
          searchQuery={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />

        <Center flex={1} flexDir='column' gap={4} py={4}>
          <Image src={noFormEmptyState} boxSize='220px' />
          <Flex fontWeight={600} fontSize='15px' color='#303030'>
            There are no forms in “{category.name}”
          </Flex>
          <ActionButton
            w='max-content'
            minW='240px'
            colorScheme='red'
            fontSize='15px'
            actionFn={() => actionHandler?.('delete')}
          >
            Delete “{category.name}”
          </ActionButton>
        </Center>
      </Flex>
    );
  }

  return (
    <Flex flex={1} flexDirection='column' minH='calc(100vh - 6rem - 104px)'>
      <MoveHeader
        searchQuery={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
      />
      <MoveFormLoader isLoading={loading} isEmpty={!filterList?.length}>
        <CheckboxGroup value={checked} onChange={setChecked}>
          <MoveFormLists
            formList={filterList}
            onAllSelect={onAllSelect}
            onMoveSuccess={onMoveSuccess}
          />
        </CheckboxGroup>
      </MoveFormLoader>
    </Flex>
  );
};

export default MoveFormContainer;

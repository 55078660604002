import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormInput } from '../task.types';
import {
  BaseFormStepper,
  useStepperAction,
  useStepperIsCompleted,
  useStepperIsFilled,
} from '../common';

import TaskStepperDescription from './TaskStepperDescription';

import taskStepsIcon from '../../../../assets/images/task-steps.svg';
import taskCheckCircle from '../../../../assets/images/task-check-circle.svg';
const TaskStepStepper: FC = () => {
  const { t } = useTranslation('task');

  const { handleSubmit } = useFormContext<IFormInput>();
  const isCompleted = useStepperIsCompleted('taskSteps');
  const isFilled = useStepperIsFilled('taskSteps');

  const { onSuccess, onError } = useStepperAction('taskSteps');

  return (
    <BaseFormStepper
      imageUrl={isFilled ? taskCheckCircle : taskStepsIcon}
      isCompleted={isCompleted}
      title={isCompleted ? t('steps') : t('addStep')}
      caption={isCompleted ? <TaskStepperDescription /> : t('addStepDesc')}
      onClick={handleSubmit(onSuccess, onError)}
    />
  );
};

export default TaskStepStepper;

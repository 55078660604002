import React, { FC, useState } from 'react';
import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  FormExportEntity,
  FORMS_RESPONSE_EXPORT,
} from '../../../pages/forms/forms-graphql';
import { GenerateFormResponseSheet } from '../../../pages/forms/FormResponse/FormResponseTable/GenerateFormResponseSheetHandler/GenerateFormResponseSheet';
import { QuestionEntity } from '../../../types';
import { AmplitudeEvent, deployEvent } from '../../../shared';

const DELETE_FORM = gql`
  mutation DeleteForm($eid: String!) {
    deleteForm(eid: $eid) {
      succeed
    }
  }
`;

interface IFormDataExport {
  title: string;
  userList: any;
  questions: QuestionEntity[];
}

const FORM_DATA_EXPORT = gql`
  query FormDataForExportId($eid: String!) {
    FormById(eid: $eid) {
      title
      userList
      questions
    }
  }
`;

interface IProps {
  desc: string;
  formId?: string;
  onDeleteSuccess?: () => void;
  onDeleteError?: () => void;
  onSheetGenerated?: () => void;
}

const DeleteFormModalBody: FC<IProps> = ({
  desc,
  formId,
  onDeleteSuccess,
  onDeleteError,
  onSheetGenerated,
}) => {
  const { t } = useTranslation(['form']);
  const [formData, setFormData] = useState<IFormDataExport>();
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });

  const [getFormExportData, { loading: exportDataLoading }] = useLazyQuery<
    Record<'FormResponseExport', FormExportEntity[]>
  >(FORMS_RESPONSE_EXPORT, {
    onCompleted: async (data) => {
      if (formData) {
        await generateHandler(data?.FormResponseExport, formData);
      }
    },
  });

  const [deleteForm, { loading: loadingDeleteForm }] = useMutation(
    DELETE_FORM,
    {
      onCompleted: () => {
        toast({
          title: t('form:formDeleteSuccess'),
          status: 'success',
        });
        onDeleteSuccess?.();
      },
      onError: () => {
        toast({
          title: t('form:formDeleteError'),
          status: 'error',
        });
        onDeleteError?.();
      },
    }
  );

  const generateHandler = async (
    formResponseExport: FormExportEntity[],
    _formCtx: IFormDataExport
  ) => {
    await GenerateFormResponseSheet(_formCtx.questions, formResponseExport, {
      title: _formCtx.title,
      userList: _formCtx.userList,
    });
    onSheetGenerated?.();
  };

  const [getFormByIdData, { loading: formByIdLoading }] = useLazyQuery<
    Record<'FormById', IFormDataExport>
  >(FORM_DATA_EXPORT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setFormData(data?.FormById);
      getFormExportData({
        variables: {
          formId: formId,
        },
      });
    },
  });

  const generateUserWiseReportHandler = () => {
    deployEvent(AmplitudeEvent.FORMS_DELETE_EXPORT);
    if (formId) {
      getFormByIdData({
        variables: {
          eid: formId,
        },
      });
    }
  };

  const deleteFormHandler = () => {
    deployEvent(AmplitudeEvent.FORMS_DELETE_CONFIRM);
    return deleteForm({
      variables: {
        eid: formId,
      },
    });
  };

  return (
    <Box>
      <Text color='#6F767E' fontWeight={500} fontSize='16px' mb={8}>
        {desc}
      </Text>
      <Flex justify='flex-end' gap={4}>
        <Button
          variant='solid'
          colorScheme='blue'
          isLoading={exportDataLoading || formByIdLoading}
          disabled={exportDataLoading || formByIdLoading || loadingDeleteForm}
          onClick={generateUserWiseReportHandler}
        >
          {t('form:exportFormResponse')}
        </Button>
        <Button
          variant='solid'
          colorScheme='red'
          onClick={deleteFormHandler}
          isLoading={loadingDeleteForm}
          disabled={exportDataLoading || formByIdLoading || loadingDeleteForm}
        >
          {t('form:deleteAnyway')}
        </Button>
      </Flex>
    </Box>
  );
};

export default DeleteFormModalBody;

import { useCallback } from 'react';
import { useFormContext, UseFormReturn } from 'react-hook-form';

import { convertContent, transformUpdateData } from './helpers';
import { UpdateLauncherInput } from './update-launcher.graphql';
import { LauncherConfig } from './fragment.graphql';
import { BaseFormInput } from './base-launcher.types';

import localConfigDatabase from './local-config-database';

type ConfigSave = (inputs?: UpdateLauncherInput) => Promise<LauncherConfig>;

export const useConfigSave = <T extends BaseFormInput>(
  methods?: UseFormReturn<T>
): ConfigSave => {
  const { getValues, setValue } = methods || useFormContext<T>();

  return useCallback(async (inputs) => {
    const _inputs = inputs || transformUpdateData(getValues());

    // @ts-ignore
    if (!getValues('publishRequired')) {
      // @ts-ignore
      setValue('publishRequired', true);
    }

    console.log('Launcher config saved');

    return localConfigDatabase.updateConfig({
      ..._inputs,
      // @ts-ignore
      publishRequired: true,
      // @ts-ignore
      contents: convertContent(_inputs['contents']),
    }) as never;
  }, []);
};

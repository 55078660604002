import { FC } from 'react';
import {
  PromptAction,
  useBlockPrompt,
  useCloseConfirm,
} from '../../../../routes';
import { useLocalChangesCount } from '../common';
import { EditorEvent, useEditorContext } from './context';

const NavigationBlock: FC = () => {
  const changes = useLocalChangesCount();
  const { emit } = useEditorContext();

  useCloseConfirm(changes !== undefined);

  useBlockPrompt({
    canShowPrompt: changes !== undefined,
    blockConfig: (nextLocation) => {
      if (nextLocation.pathname !== window.location.pathname) {
        return {
          alertType: 'LauncherPublishAlert',
        };
      }
      return undefined;
    },
    actionHandler: async (action) => {
      if (action === PromptAction.PUBLISH_NOW) {
        await emit(EditorEvent.PUBLISH_CONFIG);
      }
    },
  });

  return null;
};

export default NavigationBlock;

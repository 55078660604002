import { gql } from '@apollo/client';
import { JSONContent } from 'delightree-editor';
import { VisibilityConfig } from '../../../types';

export interface TiptapContent {
  attrs: JSONContent['attrs'];
  text: string;
  type: 'tiptap';
  tiptap: JSONContent;
}

interface ChapterInput extends VisibilityConfig {
  // category: string;
  categoryId: string;
  content: Array<TiptapContent>;
  isMultiPage: boolean;
  raw_content: string;
  smartPageEnabled: boolean;
  thumbnail: string;
  thumbnail_color?: string;
  title: string;
  attachedForms: string[];
  notifyUsers?: boolean;
  icon: any;
  status: string;
}

export interface AddChapterVariable {
  input: ChapterInput;
}

export interface UpdateChapterVariable {
  input: {
    // category: string;
    categoryId: string;
    content: Array<TiptapContent>;
    eid: string;
    isMultiPage: boolean;
    languageCode: string;
    raw_content: string;
    smartPageEnabled: boolean;
    status?: string;
    thumbnail: string;
    thumbnail_color?: string;
    title: string;
  };
}

export interface AddChapterResponse {
  addSop: {
    eid: string;
    content: Array<TiptapContent>;
    raw_content: string;
    icon: any;
    notifyUsers: boolean;
    category: string;
    categoryId: string;
    thumbnail: string;
    title: string;
    smartPageEnabled: boolean;
    isMultiPage: boolean;
    createdBy: string;
    entityId: string;
    status: string;
    visibleTo: {
      users: string[];
      condition: 'or' | 'and';
      locations: string[];
      roles: string[];
    };
  };
}

export const ADD_CHAPTER = gql`
  mutation AddChapter($input: SopAddInput) {
    addSop(input: $input) {
      eid
      content
      title
      icon
      raw_content
      createdBy
      entityId
      category
      notifyUsers
      thumbnail
      title
      smartPageEnabled
      isMultiPage
      visibleTo {
        users
        locations
        roles
        condition
        _id
      }
      visibility
    }
  }
`;

export interface UpdateChapterResponse {
  updateSop: {
    eid: string;
    content: Array<TiptapContent>;
    raw_content: string;
    icon: any;
    notifyUsers: boolean;
    category: string;
    categoryId: string;
    thumbnail: string;
    title: string;
    smartPageEnabled: boolean;
    isMultiPage: boolean;
    createdBy: string;
    entityId: string;
    status: string;
    visibleTo: {
      users: string[];
      condition: 'or' | 'and';
      locations: string[];
      roles: string[];
    };
  };
}

export const UPDATE_CHAPTER = gql`
  mutation UpdateSop($input: SopUpdateInput) {
    updateSop(input: $input) {
      eid
      content
      title
      icon
      raw_content
      createdBy
      entityId
      category
      categoryId
      notifyUsers
      thumbnail
      title
      smartPageEnabled
      isMultiPage
      visibleTo {
        users
        locations
        roles
        condition
        _id
      }
      visibility
    }
  }
`;

export interface SmartCardItem {
  backgroundColor: string;
  cardType: string;
  category: string;
  content: any;
  defaultLanguage: string;
  formId: string;
  layout: string;
  raw_content: string;
  subTitle: string;
  thumbnail: string;
  title: string;
  hasHorizontal?: boolean;
}

export interface SmartCardVariable {
  input: {
    cards: SmartCardItem[];
    language: string;
    sop: string;
  };
}

export const SMART_CARD_UPDATE = gql`
  mutation UpdateSmartCards($input: BulkCardAddInputInput) {
    UpdateSmartCards(input: $input) {
      eid
    }
  }
`;

import React, { FC } from 'react';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { ActionButton } from '../../../../ui-components';

import trashImage from '../../../../assets/images/trash-red.svg';
import { useDeleteContext } from './context';

interface IProps {}

const DeleteConfirmContent: FC<IProps> = () => {
  const { category, actionHandler } = useDeleteContext();

  return (
    <Flex flex={1} flexDirection='column' gap={4}>
      <Center flexDirection='column' gap={4} py={3}>
        <Image
          src={trashImage}
          boxSize='56px'
          p='16px'
          bg='#FF6A5533'
          borderRadius='full'
        />

        <Box textAlign='center' color='#33383F'>
          {/*Are you sure you want to delete these forms? <br />*/}
          {/*You can still download their responses.*/}
          These forms might have responses! <br />
          You can also download responses before deleting.
        </Box>
      </Center>

      <Flex flexDirection='column' pb={2} gap={2}>
        <ActionButton
          size='lg'
          fontSize='15px'
          colorScheme='blue'
          actionFn={() => actionHandler?.('exportAndDelete')}
        >
          Download responses & delete forms
        </ActionButton>
        <ActionButton
          size='lg'
          fontSize='15px'
          colorScheme='red'
          actionFn={() => actionHandler?.('deleteForms')}
        >
          Delete forms and their responses
        </ActionButton>
      </Flex>
    </Flex>
  );
};

export default DeleteConfirmContent;

import React, { CSSProperties, FC } from 'react';
import styled from '@emotion/styled';

const Div = styled.div<{ showPointer: boolean }>`
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-direction: row;
  /* cursor: ${({ showPointer }) =>
    showPointer ? 'pointer' : 'not-allowed'}; */
  cursor: pointer;
`;

interface IProps {
  onClick?: (...args: any) => void;
  item: any;
  maxLength: number;
  style?: CSSProperties;
}

const ProgressCell: FC<IProps> = ({ item, maxLength, onClick, style }) => {
  const totalView = item?.totalViewedCount || 0;
  return (
    <Div
      showPointer={totalView === maxLength || totalView > 0}
      onClick={() => {
        onClick?.(item);
        // if (totalView === maxLength || totalView > 0) {
        // }
      }}
      style={style}
    >
      <span
        style={{
          backgroundColor: '#efefef',
          padding: '2px 5px',
          borderRadius: '4px',
          marginRight: '5px',
        }}
      >
        {totalView}/{maxLength}
      </span>
      <div
        style={{
          height: '15px',
          width: '50px',
          backgroundColor: '#efefef',
          borderRadius: '4px',
        }}
      >
        <div
          style={{
            width: `${Math.ceil((totalView / maxLength) * 100)}%`,
            height: '100%',
            borderRadius: '2px',
            backgroundColor: totalView === maxLength ? '#83bf6e' : '#2a85ff',
          }}
        />
      </div>
    </Div>
  );
};

export default ProgressCell;

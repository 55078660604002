import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Box,
  Flex,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useForm, FormProvider, Controller } from 'react-hook-form';

import { PowerUpModalRef, IPowerUpModalTypes } from './power-up.types';
import PrimaryButton from 'atoms/PrimaryButton';
import {
  CardDefaultValues,
  CardFormInput,
} from 'sub-components/CardEditor/card-editor.types';
import ActionCardEditor from 'sub-components/UpdatedCardEditor/CardEditorComponents/ActionCardEditor';
import TrainingCardContainer from 'sub-components/CardEditorModal/TrainingCardContainer';
// import ActionCardPreview from "sub-components/UpdatedCardEditor/CardPreviewComponents/ActionCardPreview";
import ActionCardPreview from 'sop-commons/src/card-components/layouts/newLayouts/Action';
import MilestoneCardEditor from 'sub-components/UpdatedCardEditor/CardEditorComponents/MilestoneCardEditor';

const PowerUpModal = forwardRef<PowerUpModalRef, IPowerUpModalTypes>(
  (props, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectionType, setSelectionType] =
      useState<IPowerUpModalTypes['types']>('');
    const methods = useForm<CardFormInput>({
      defaultValues: CardDefaultValues,
    });
    const { handleSubmit, reset, control } = methods;

    // useEffect(() => {
    //   setSelectionType(props.types);
    // }, [isOpen, props.types]);

    const openModal = (type: IPowerUpModalTypes['types']) => {
      setIsOpen(true);
      reset();
      setSelectionType(type);
    };

    const closeModal = () => {
      setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      openModal,
      closeModal,
    }));

    const onSubmit = (data: CardFormInput) => {
      console.log('Data submit : ', data);
    };

    const getCardRender = () => {
      switch (selectionType) {
        case 'action':
          return <ActionCardEditor />;
        case 'milestone':
          return <MilestoneCardEditor />;
        default:
          return <ActionCardEditor />;
      }
    };

    return (
      <Modal isOpen={isOpen} onClose={closeModal} size='6xl'>
        <ModalOverlay />
        <ModalContent py={4}>
          <ModalBody>
            <FormProvider {...methods}>
              <ModalHeader p={0}>
                <Flex flexDir='row' alignItems='center'>
                  <Box
                    width='16px'
                    height='32px'
                    borderRadius='4px'
                    marginRight='4px'
                    backgroundColor='#CABDFF'
                  />
                  <Text>Create power-up</Text>
                </Flex>
              </ModalHeader>
              <ModalCloseButton />
              <Flex w='full'>
                <Box w='80%'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex direction='row'>
                      <Box flex='4'>
                        <Controller
                          name={'cardType'}
                          control={control}
                          render={() => getCardRender()}
                        />
                        <PrimaryButton
                          width={'fit-content'}
                          style={{ color: 'white' }}
                          title='Save'
                          variant='solid'
                          mt={4}
                          colorScheme='blue'
                          type='submit'
                        />
                      </Box>
                      <Box flex='1' />
                    </Flex>
                  </form>
                </Box>
                <Box w='20%'>
                  <TrainingCardContainer />
                  {/* <ActionCardPreview 
                  buttonText={'Done'}
                  backgroundColor={'white'}
                  cardId=""
                  content={[]}
                  title={'Create action power-up'}
                  isPreview
                  shouldSetFixedHeight={false}
                  shouldMoveToNextCard={false}
                /> */}
                </Box>
              </Flex>
            </FormProvider>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
);

PowerUpModal.displayName = 'PowerUpModal';

export default PowerUpModal;

import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';

import { getImage } from '../../../utils';
import { IconsPlaceholder, Image } from '../../../ui-components';

import { TemplateItem } from './form-template.graphql';

interface IProps {
  data: TemplateItem;
  onClick?: () => void;
}

const FormTemplateItem: FC<IProps> = ({ data, onClick }) => {
  return (
    <div className='template-item' onClick={onClick}>
      <Image
        width={230}
        height={180}
        src={getImage(data.thumbnail || IconsPlaceholder['form'])}
        style={{
          maxHeight: 180,
          objectFit: 'cover',
          borderRadius: 12,
          backgroundColor: '#efefef',
        }}
      />
      <div className='template-item-text-container'>
        <Box as='p' noOfLines={1}>
          {data?.title}
        </Box>
        <Box as='span' noOfLines={4}>
          {data?.description}
        </Box>
      </div>
    </div>
  );
};

export default FormTemplateItem;

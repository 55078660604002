import React, { FC, useMemo } from 'react';
import { Box, Flex, List } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { match } from 'ts-pattern';

import PreviewHeader from '../../../../forms/component/PreviewHeader';
import { SectionKeysType, UserTaskSession } from './user-task.graphql';
import UserTaskItem from './UserTaskItem';
import HiddenTasks from './HiddenTasks';

interface IProps {
  data: UserTaskSession[];
  status: SectionKeysType;
  userTimeZone?: string;
  onClick?: (data: UserTaskSession) => void;
}

const UserTaskList: FC<IProps> = ({ data, status, userTimeZone, onClick }) => {
  const [title, color] = useMemo(() => {
    return match(status)
      .with('overdue', () => ['Overdue tasks', 'rgba(255, 106, 85, 0.4)'])
      .with('dueToday', () => ['Today’s tasks', 'rgba(202, 189, 255, 1)'])
      .with('completed', () => ['Completed tasks', 'rgba(181, 228, 202, 1)'])
      .with('upcoming', () => ['Upcoming tasks', 'rgba(177, 229, 252, 1)'])
      .with('hidden', () => ['Hidden tasks', 'rgba(199, 199, 199, 1)'])
      .otherwise(() => ['Upcoming tasks', 'rgba(199, 199, 199, 1)']);
  }, [status]);

  if (!data.length || status === 'thisWeek') {
    return null;
  }

  if (status === 'hidden') {
    return (
      <Box>
        <Flex align='center' gap='10px'>
          <PreviewHeader title={title} color={color} />
          <InfoOutlineIcon width='18px' height='18px' color='#6F767E' />
        </Flex>

        <List spacing='12px' mt='14px'>
          <HiddenTasks />
          <HiddenTasks />
        </List>
      </Box>
    );
  }

  return (
    <Box id={status}>
      <PreviewHeader title={title} color={color} />

      <List spacing='12px' mt='14px'>
        {data.map((value) => (
          <UserTaskItem
            key={value.eid}
            task={value}
            status={status}
            userTimeZone={userTimeZone}
            onClick={onClick}
          />
        ))}
      </List>
    </Box>
  );
};

export default UserTaskList;

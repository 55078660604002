import React, { ChangeEvent, FC, useState } from 'react';
import { Button } from '@chakra-ui/react';
import _uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';
import { FileName, useFileName } from './file-name';
import { ReactComponent as CloudUpload } from '../../../../assets/images/cloudUpload.svg';

interface IProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  value?: string[];
  onRemove?: () => void;
}

const AddFile: FC<IProps> = ({
  value,
  onChange,
  isLoading,
  isDisabled,
  onRemove,
}) => {
  const { t } = useTranslation('form');
  const fileName = useFileName(value);
  const [inputId] = useState(_uniqueId('addFile-'));

  if (fileName) {
    return (
      <FileName
        icon={<CloudUpload style={{ width: 20, height: 20 }} />}
        fileName={fileName}
        value={value}
        isDisabled={isDisabled}
        onRemove={onRemove}
      />
    );
  }

  if (isDisabled) {
    return (
      <div>
        <Button
          width='full'
          isDisabled={isDisabled}
          isLoading={isLoading}
          as='div'
          borderRadius='12px'
          h='48px'
          leftIcon={<CloudUpload style={{ width: 20, height: 20 }} />}
          fontWeight='400'
          cursor='pointer'
        >
          {t('add_file')}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <label htmlFor={inputId}>
        <Button
          width='full'
          isLoading={isLoading}
          as='div'
          borderRadius='12px'
          h='48px'
          leftIcon={<CloudUpload style={{ width: 20, height: 20 }} />}
          fontWeight='400'
          cursor='pointer'
        >
          {t('add_file')}
        </Button>
      </label>

      {!isLoading ? (
        <input
          disabled={isLoading}
          type='file'
          onChange={onChange}
          id={inputId}
          style={{ display: 'none' }}
          // accept='image/*'
        />
      ) : null}
    </div>
  );
};

export default AddFile;

import React, { FC, ReactNode } from 'react';
import { Box, BoxProps, HStack, Skeleton, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Divider } from 'antd';

const Wrapper = styled.div<{ isAction?: boolean }>`
  padding-top: 20px;

  > div:first-child {
    cursor: ${({ isAction }) => (isAction ? 'pointer' : 'initial')};
  }

  .content-item-icon {
    img {
      width: 26px;
      height: 26px;
    }
  }

  .content-item-text {
    font-size: 16px;
    font-weight: 600;
    color: #1a1d1f;
  }

  .ant-divider {
    background: #eee;
    height: 1px;
    margin: 0;
  }

  :last-child {
    .ant-divider {
      display: none !important;
    }
  }
`;

interface IProps {
  type?: string;
  leftIcon: any;
  rightIcon?: any;
  title: ReactNode;
  allowedTypes?: string[];
  onClick?: () => void;
  contentProps?: BoxProps;
  isLoading?: boolean;
}

const ContentItem: FC<IProps> = ({
  leftIcon,
  rightIcon,
  title,
  type,
  allowedTypes,
  onClick,
  contentProps,
  isLoading,
}) => {
  if (allowedTypes && !allowedTypes?.includes(type!)) {
    return null;
  }

  return (
    <Wrapper className='content-item' isAction={!!onClick}>
      <HStack spacing='15px' marginBottom='20px' onClick={onClick}>
        <Box className='content-item-icon'>
          <img src={leftIcon} />
        </Box>

        <VStack spacing={-6} align='stretch' flex='1'>
          <Skeleton minH='24px' isLoaded={!isLoading} borderRadius='4px'>
            <Box {...contentProps} className='content-item-text'>
              {title}
            </Box>
          </Skeleton>
        </VStack>
        {rightIcon && (
          <Box className='content-item-icon'>
            <img src={rightIcon} />
          </Box>
        )}
      </HStack>
      <Divider />
    </Wrapper>
  );
};

export default ContentItem;

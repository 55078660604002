import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Portal } from '@chakra-ui/react';

import { useHideIntercomButton, useUserEntity } from '../../hooks';

import { SingleUserWrapper } from './single-user-chat.styles';
import ChatModalContent, { ChatModalProps } from './ChatModalContent';

interface IProps extends ChatModalProps {
  className?: string;
  prefilledMessage?: string;
  usePortal?: boolean;
}

const SingleUserChatModal: FC<IProps> = ({ className, ...props }) => {
  useHideIntercomButton();
  const ref = useRef<never>(null);
  const timerRef = useRef<any>();

  const isChatEnabled = useUserEntity((entity) => entity?.features?.chat);

  // TODO in testing phase
  const setInitialMessage = useCallback(
    (element?: HTMLElement, message?: string) => {
      if (element && message) {
        element.textContent = message;

        element?.dispatchEvent?.(new Event('input', { bubbles: true }));
        element?.blur();
      }
    },
    []
  );

  // TODO in testing phase
  useEffect(() => {
    if (!props.prefilledMessage) {
      return () => {};
    } else {
      // timerRef.current = setTimeout(() => {
      //   setInitialMessage(element, props.prefilledMessage);
      // }, 1000);

      timerRef.current = setInterval(() => {
        // @ts-ignore
        const element = ref.current?.composerRef?.messageInputRef?.current;
        // @ts-ignore
        const messageLState = ref.current?.messageListRef?.state;

        if (element && messageLState?.decoratorMessage !== 'LOADING') {
          setInitialMessage(element, props.prefilledMessage);
          clearInterval(timerRef.current);
        }
      }, 1000);

      return () => {
        // clearTimeout(timerRef.current);
        clearInterval(timerRef.current);
      };
    }
  }, [props.prefilledMessage]);

  const renderHandler = () => {
    return (
      <SingleUserWrapper chatDisabled={!isChatEnabled} className={className}>
        <ChatModalContent ref={ref} {...props} />
      </SingleUserWrapper>
    );
  };

  return props?.usePortal ? (
    <Portal>{renderHandler()}</Portal>
  ) : (
    renderHandler()
  );
};

export default SingleUserChatModal;

import React, { ChangeEvent, FC } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FileName, useFileName } from './file-name';
import { ReactComponent as CameraIcon } from '../../../../assets/images/camera.svg';

interface IProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isPreview?: boolean;
  value?: string[];
  onRemove?: () => void;
}

const AddImage: FC<IProps> = ({
  value,
  onChange,
  isLoading,
  isDisabled,
  isPreview,
  onRemove,
}) => {
  const { t } = useTranslation('form');
  const fileName = useFileName(value);

  if (fileName) {
    return (
      <FileName
        icon={<CameraIcon style={{ width: 20, height: 20 }} />}
        fileName={fileName}
        value={value}
        isDisabled={isDisabled}
        onRemove={onRemove}
      />
    );
  }

  if (isDisabled) {
    return (
      <div>
        <Button
          isDisabled={isDisabled}
          isLoading={isLoading}
          as='div'
          borderRadius='12px'
          h='48px'
          leftIcon={<CameraIcon style={{ width: 20, height: 20 }} />}
          fontWeight='400'
          cursor='pointer'
        >
          {t('open_camera')}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Button
        isLoading={isLoading}
        as='div'
        borderRadius='12px'
        h='48px'
        leftIcon={<CameraIcon style={{ width: 20, height: 20 }} />}
        fontWeight='400'
        cursor='pointer'
        onClick={onChange as any}
      >
        {t('open_camera')}
      </Button>
    </div>
  );

  // return (
  //   <div>
  //     <label htmlFor='addImage'>
  //       <Button
  //         isLoading={isLoading}
  //         as='div'
  //         borderRadius='12px'
  //         h='48px'
  //         leftIcon={<CameraIcon style={{ width: 20, height: 20 }} />}
  //         fontWeight='400'
  //         cursor='pointer'
  //       >
  //         Open Camera
  //       </Button>
  //     </label>
  //
  //     {!isLoading ? (
  //       <input
  //         disabled={isLoading}
  //         type='file'
  //         onChange={onChange}
  //         id='addImage'
  //         style={{ display: 'none' }}
  //         // accept='image/*'
  //         // capture='user'
  //         capture
  //         accept='image/*;capture=camera'
  //       />
  //     ) : null}
  //   </div>
  // );
};

export default AddImage;

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Translation, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { cloneDeep } from '@apollo/client/utilities';
// @ts-ignore
import { getCardById } from 'sop-commons/Query/Search';
import { userObj } from 'sop-commons/src/client';
import CardEditor from '../CardEditor';
import { modalContentMinWidth, modalContentWidth } from './card-editor.styles';
import {
  CardDefaultValues,
  CardFormInput,
  QuizOptionData,
} from '../CardEditor/card-editor.types';
import {
  generateDeleteMessage,
  useCardDeckDelete,
  useSearchParam,
} from '../../hooks';
import TrainingCardContainer from './TrainingCardContainer';
import { TextLayout } from 'sop-commons/src/utils/cardLayouts';
import { useCardResetInitial } from '../CardEditor/hook';
import { CardType } from '../../types';
import { CardTypeLabel, CardTypeTrans } from '../CardEditor/card-editor.data';
import { Authorize, AuthRole } from '../../authorization';
import {
  RemoveCardQuery,
  RemoveCardResponse,
} from '../CardEditor/card-editor.graphql';
import AddCategory from '../Sidebar/category/AddCategory';
import EditorHeader from '../CardEditor/EditorHeader';
import { TranslatedData } from 'types/base-card.types';
import EditProvider from '../CardEditor/EditProvider';
import { IPowerUpModalTypes } from 'pages/Training/PowerUpModal/power-up.types';

const capitalize = (val?: string) => {
  return val ? val[0].toUpperCase() + val.slice(1) : '';
};

export const getCardTypeLabel = (cardType: CardType): string => {
  return CardTypeLabel[cardType] || capitalize(cardType);
};

interface IProps {
  open: boolean;
  onClose: () => void;
  isEdit?: boolean;
  cardId?: string;
  onSubmit?: (...args: any) => void;
  isDeckView?: boolean;
  otherDefaultCardType?: string; //
  otherDefaultCardInitialData?: any;
  returnedCardData?: any;
  isHide?: boolean;
  disabledLayout?: CardType[];
  onCardDelete?: () => void;
  fromTraining?: boolean;
  passedCardType?: IPowerUpModalTypes['types'];
  isReadOnly?: boolean;
}

const CardEditorModal: FC<IProps> = ({
  open,
  onClose,
  isEdit,
  cardId,
  onSubmit,
  isDeckView,
  otherDefaultCardType,
  otherDefaultCardInitialData,
  returnedCardData,
  isHide,
  disabledLayout,
  onCardDelete,
  fromTraining,
  passedCardType,
  isReadOnly,
}) => {
  const { t, i18n } = useTranslation(['common', 'card', 'deck']);
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
  });
  const cardDeckDelete = useCardDeckDelete();
  const methods = useForm<CardFormInput>({
    defaultValues: CardDefaultValues,
  });

  const getInitialData = useCardResetInitial(methods.getValues);

  const [stableCardData, setStableCardData] = useState(null);

  const match = useRouteMatch<{ category: string }>();
  const categoryName = useSearchParam<{ categoryName?: string }>().categoryName;

  const category = match.params?.category || categoryName;

  const userData = useReactiveVar(userObj);
  const [deleteMessage, setDeleteMessage] = useState<string>('');

  const [deleteCard] = useMutation<RemoveCardResponse>(RemoveCardQuery, {
    onCompleted: (data) => {
      if (data?.removeCard?.succeed) {
        toast({
          title: t('common:success'),
          status: 'success',
          description: t('card:card_removed_success_toast', {
            title: methods.getValues('title'),
          }),
        });
        onCardDelete?.();
      }
    },
  });

  const [newCategory, setNewCategory] = useState(false);

  const updateFormData = (cardData: any) => {
    if (!cardData) {
      return;
    }
    // methods.setValue('title', cardData?.title?.trim(), { shouldDirty: true });
    methods.setValue('layout', cardData?.layout, {
      shouldDirty: true,
    });
    methods.setValue('status', cardData?.status, {
      shouldDirty: true,
    });
    methods.setValue('thumbnail', cardData?.thumbnail, {
      shouldDirty: true,
    });
    methods.setValue('backgroundColor', cardData?.backgroundColor, {
      shouldDirty: true,
    });
    // methods.setValue('raw_content', cardData?.raw_content, {
    //   shouldDirty: true,
    // });
    // methods.setValue('subTitle', cardData?.subTitle, {
    //   shouldDirty: true,
    // });
    methods.setValue('handbookView', cardData?.handbookView, {
      shouldDirty: true,
    });

    const _cardContent = cardData?.content || [];

    const translations: CardFormInput['translations'] =
      cardData?.translations || {};

    const defaultLanguage = cardData?.defaultLanguage || 'en';

    if (!translations[defaultLanguage]) {
      translations[defaultLanguage] = {
        languageCode: defaultLanguage,
        title: cardData?.title?.trim(),
        subTitle: cardData?.subTitle,
        raw_content: cardData?.raw_content,
        content: _cardContent,
      } as CardFormInput['translations'][string];
    }

    methods.setValue('translations', translations || {});
    methods.setValue('defaultLanguage', defaultLanguage);

    const transData: TranslatedData = translations[i18n.language] || {};

    methods.setValue('title', transData?.title?.trim(), { shouldDirty: true });
    methods.setValue('raw_content', transData?.raw_content, {
      shouldDirty: true,
    });
    methods.setValue('subTitle', transData?.subTitle, {
      shouldDirty: true,
    });

    const cardContent = transData?.content || [];

    let tiptap = cardContent?.find((val: any) => val.type === 'tiptap');

    const mergeContent = cardContent?.length === 0 ? _cardContent : cardContent;

    let images = mergeContent?.find((val: any) => val.type === 'image');

    switch (cardData?.cardType as CardType) {
      case 'text':
        if (cardData?.layout === TextLayout.RICH_TEXT) {
          methods.setValue('bodyJson', tiptap?.tiptap, {
            shouldDirty: true,
          });
        }
        break;
      case 'image':
        methods.setValue('images', images?.image, {
          shouldDirty: true,
        });
        break;
      case 'quiz':
        methods.setValue(
          'choices',
          cardContent?.[0]?.choices?.map((val: QuizOptionData) => ({
            ...val,
            choiceId: val.id,
          })),
          {
            shouldDirty: true,
          }
        );
        methods.setValue('correctAnswer', cardContent?.[0]?.correctAnswer, {
          shouldDirty: true,
        });
        break;
      case 'video':
        methods.setValue('caption', cardContent?.[1]?.caption, {
          shouldDirty: true,
        });
        methods.setValue('videoUrl', mergeContent?.[0]?.video, {
          shouldDirty: true,
        });
        methods.setValue('videoAddedBy', mergeContent?.[2]?.addedBy);
        methods.setValue('videoDateAdded', mergeContent?.[2]?.dateAdded);
        methods.setValue('videoFileSize', mergeContent?.[2]?.fileSize);
        break;
      case 'form':
        methods.setValue('formLink', mergeContent?.[0]?.url, {
          shouldDirty: true,
        });
        break;
      case 'mixed':
        methods.setValue('images', images?.image, {
          shouldDirty: true,
        });
        methods.setValue('bodyJson', tiptap?.tiptap, {
          shouldDirty: true,
        });
        break;
      case CardType.CustomForm:
        methods.setValue(
          'form',
          {
            label: cardData?.form.title,
            value: cardData?.form.eid,
            ...cardData?.form,
          },
          {
            shouldDirty: true,
          }
        );
        break;
    }
  };

  const [findCardById] = useLazyQuery(getCardById, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      methods.setValue('cardType', {
        label: getCardTypeLabel(response?.findCardById?.cardType),
        transKey: CardTypeTrans[response?.findCardById?.cardType as CardType],
        value: response?.findCardById?.cardType,
      });
      let cardData = cloneDeep(response?.findCardById);
      methods.setValue('category', {
        label: capitalize(response?.findCardById?.category),
        value: response?.findCardById?.category,
      });
      updateFormData(cardData);
      setStableCardData({ ...cardData });
      setDeleteMessage(
        generateDeleteMessage('card', response?.findCardById?.trainings, t)
      );
    },
  });

  useEffect(() => {
    if (isEdit) {
      findCardById({
        variables: {
          eid: cardId,
        },
      });
    }
  }, [cardId]);

  useEffect(() => {
    const dataset = {
      image: 'Image',
      action: 'Action',
      quiz: 'Quiz',
      video: 'Video',
      form: 'Form',
      mixed: 'Mixed',
    };
    if (otherDefaultCardType) {
      const cardType =
        otherDefaultCardType?.toLowerCase() as keyof typeof dataset;

      if (dataset[cardType]) {
        const initialData = getInitialData({
          cardType: {
            label: dataset[cardType],
            value: cardType as any,
          },
        });
        methods.reset(initialData);
      }
    }
  }, [otherDefaultCardType]);

  useEffect(() => {
    if (!isEdit) {
      const entityId = userData?.entity?.eid;
      if (category) {
        methods.setValue('category', {
          label: capitalize(category),
          value: category as string,
        });
      }
      methods.setValue('entityId', entityId);
    }
  }, []);

  const onDeletePress = () => {
    cardDeckDelete({
      title: methods.getValues('title') || '',
      message: deleteMessage,
      onDeletePress: () => {
        return deleteCard({
          variables: {
            eid: cardId,
          },
        });
      },
    });
  };

  const onCreatePress = useCallback(() => {
    setNewCategory(true);
  }, []);

  return (
    <>
      <Modal isOpen={open} onClose={onClose}>
        <ModalOverlay zIndex={1402} />
        <ModalContent
          width={modalContentWidth}
          minWidth={modalContentMinWidth}
          containerProps={{ zIndex: 1402 }}
          borderRadius={16}
        >
          <EditProvider isEdit={isEdit}>
            <FormProvider {...methods}>
              <ModalHeader px={10} mt={4}>
                <EditorHeader
                  isEdit={isEdit}
                  passedCardType={passedCardType}
                  isReadOnly={isReadOnly}
                />
              </ModalHeader>
              {isEdit && !isReadOnly ? (
                <Authorize
                  permittedFor='user'
                  permittedRoles={AuthRole.SUPER_ADMIN}
                >
                  <ModalCloseButton
                    top={8}
                    right='70px'
                    outline='none'
                    boxShadow='none'
                    onClick={(event) => {
                      event.preventDefault();
                      onDeletePress();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashCan as IconProp}
                      size='lg'
                      color='#ff6a55'
                    />
                  </ModalCloseButton>
                </Authorize>
              ) : null}
              <ModalCloseButton
                top={8}
                right={8}
                outline='none'
                boxShadow='none'
              />
              <ModalBody px={10} pb={10}>
                <Flex>
                  <Box
                    flex='1'
                    flexBasis='auto'
                    mr={8}
                    width='calc(100% - 366px)'
                  >
                    <CardEditor
                      entityId={userData?.entity?.eid}
                      isEdit={isEdit}
                      cardId={cardId}
                      onSubmit={onSubmit}
                      onClose={onClose}
                      isDeckView={isDeckView}
                      stableCardData={stableCardData}
                      returnedCardData={returnedCardData}
                      otherDefaultCardInitialData={otherDefaultCardInitialData}
                      disabledLayout={disabledLayout}
                      isHide={isHide}
                      fromTraining={fromTraining}
                      onCreatePress={onCreatePress}
                      passedCardType={passedCardType}
                      isReadOnly={isReadOnly}
                    />
                  </Box>
                  <Flex direction='column' pl={4}>
                    <Flex align='center'>
                      <Box mr={4} h={8} w={4} borderRadius={4} bg={'#b1e5fc'} />
                      <div className='preview-title-container'>
                        <Translation>{(t1) => t1('preview')}</Translation>
                      </div>
                    </Flex>
                    <div className='create-card-container'>
                      <TrainingCardContainer />
                    </div>
                  </Flex>
                </Flex>
              </ModalBody>
            </FormProvider>
          </EditProvider>
        </ModalContent>
      </Modal>
      <AddCategory
        open={newCategory}
        onClose={() => setNewCategory(false)}
        type='chapter'
        onCategoryCreate={(cate) => {
          methods.setValue('category', {
            label: cate.name,
            value: cate.name,
          });
        }}
      />
    </>
  );
};

export default CardEditorModal;

CardEditorModal.displayName = 'CardEditorModal';

import {
  ContentDetailsEntity,
  TrainingData,
} from 'pages/Training/training.types';
import { ChangeLogTableWrapper } from 'pages/TrainingDraft/ChangeLog/ChangeLogList/ChangeLogTable/ChangeLogTable.styles';
import {
  IChangeEventEntity,
  IChangeLogTableChangesEntity,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import React, { useEffect, useState } from 'react';
import { IPublishChangeDiffCheckTable } from '../PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import { TFunction } from 'i18next';

import { getImage } from '../../../../utils';

interface ITrainingCardsComponent {
  changedData: IPublishChangeDiffCheckTable[];
  trainingData: TrainingData;
  initialTrainingData: TrainingData;
  t: TFunction<('common' | 'training')[], undefined, ('common' | 'training')[]>;
}

interface ICombinedChangedTrainingData {
  changes: ICreateChangeLogChangesEntity;
  name: string;
  icon: string;
}

const TrainingCardsComponent = ({
  changedData,
  trainingData,
  initialTrainingData,
  t,
}: ITrainingCardsComponent) => {
  const [contentDetails, setContentDetails] = useState<
    ICombinedChangedTrainingData[]
  >([]);
  useEffect(() => {
    if (trainingData?.contentDetails && initialTrainingData?.contentDetails) {
      let _contentDetails: ICombinedChangedTrainingData[] = [];
      changedData?.map((change) => {
        if (change?.tableType === 'cardAdded') {
          change?.tableData?.changes?.map((_change) => {
            trainingData?.contentDetails?.map((detail) => {
              if (_change?.contentId === detail?.eid) {
                _contentDetails?.push({
                  changes: {
                    changeElement: _change?.changeElement,
                    changeEvent: _change?.changeEvent,
                    contentId: _change?.contentId,
                  },
                  icon: getImage(detail?.thumbnail, detail?.title),
                  name: detail?.title,
                });
              }
            });
          });
        } else if (change?.tableType === 'cardRemoved') {
          change?.tableData?.changes?.map((_change) => {
            initialTrainingData?.contentDetails?.map((detail) => {
              if (_change?.contentId === detail?.eid) {
                _contentDetails.push({
                  changes: {
                    changeElement: _change?.changeElement,
                    changeEvent: _change?.changeEvent,
                    contentId: _change?.contentId,
                  },
                  icon: getImage(detail?.thumbnail, detail?.title),
                  name: detail?.title,
                });
              }
            });
          });
        }
      });
      setContentDetails(_contentDetails);
    }
  }, [trainingData?.contentDetails, initialTrainingData?.contentDetails]);
  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className='width-30'>
            {t('training:training_cards_table_heading')}
          </th>
          <th>{t('common:update')}</th>
        </tr>
      </thead>
    );
  };

  const getChangeNameHandler = (eventType: IChangeEventEntity) => {
    switch (eventType) {
      case 'cardAdded':
        return t('training:card_add_publish_change');
      case 'cardRemoved':
        return t('training:card_delete_publish_change');
    }
  };

  const updatedComparisonsHandler = (
    eventType: IChangeEventEntity,
    icon: string,
    name: string
  ) => {
    switch (eventType) {
      case 'cardAdded':
        return (
          <>
            <div className='user-status'>
              <img src={icon} style={{ borderRadius: '5px' }} />
            </div>
            <div className='status new-status'>
              <span className='status-text new-status-text'>{name}</span>
            </div>
          </>
        );
      case 'cardRemoved':
        return (
          <>
            <div className='user-status'>
              <img src={icon} style={{ borderRadius: '5px' }} />
            </div>
            <div className='status old-status'>
              <span className='status-text old-status-text'>{name}</span>
            </div>
          </>
        );
    }
  };

  const getTableBody = (contentDetails: ICombinedChangedTrainingData[]) => {
    return contentDetails?.map((content, index) => {
      return (
        <tr key={JSON.stringify(content) + index}>
          <td>{getChangeNameHandler(content?.changes?.changeEvent)}</td>
          <td>
            <div className='status-cell'>
              {updatedComparisonsHandler(
                content?.changes?.changeEvent,
                content?.icon,
                content?.name
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      {contentDetails?.length > 0 ? (
        <ChangeLogTableWrapper>
          <table>
            {getTableHeader()}
            {getTableBody(contentDetails)}
          </table>
        </ChangeLogTableWrapper>
      ) : null}
    </>
  );
};

export default TrainingCardsComponent;

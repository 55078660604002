import { ChangeLogTableWrapper } from 'pages/TrainingDraft/ChangeLog/ChangeLogList/ChangeLogTable/ChangeLogTable.styles';
import {
  IChangeEventEntity,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import React, { useEffect, useState } from 'react';
import { IPublishChangeDiffCheckTable } from '../PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import flagIcon from '../../../../assets/images/flagIcon.png';
import { TFunction } from 'i18next';

interface IMilestoneComponent {
  changedData: IPublishChangeDiffCheckTable[];
  t: TFunction<('common' | 'training')[], undefined, ('common' | 'training')[]>;
}

const MilestoneComponent = ({ changedData, t }: IMilestoneComponent) => {
  // const [contentDetails, setContentDetails] = useState<IPublishChangeDiffCheckTable[]>([]);
  const [shouldShowMilestone, setShouldShowMilestone] = useState(false);

  useEffect(() => {
    if (changedData && changedData?.length > 0) {
      changedData?.map((change) => {
        if (
          change?.tableType === 'mileStoneEdited' ||
          change?.tableType === 'milestoneAdded' ||
          change?.tableType === 'milestoneRemoved'
        ) {
          setShouldShowMilestone(true);
        }
      });
      // setContentDetails(changedData);
    }
  }, [changedData]);

  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className='width-30'>{t('training:milestone_table_heading')}</th>
          <th>{t('common:update')}</th>
        </tr>
      </thead>
    );
  };

  const getChangeNameHandler = (eventType: IChangeEventEntity) => {
    switch (eventType) {
      case 'mileStoneEdited':
        return t('training:milestone_edit_publish_change');
      case 'milestoneAdded':
        return t('training:milestone_add_publish_change');
      case 'milestoneRemoved':
        return t('training:milestone_delete_publish_change');
    }
  };

  const updatedComparisonsHandler = (change: ICreateChangeLogChangesEntity) => {
    switch (change?.changeEvent) {
      case 'milestoneAdded': {
        return (
          <>
            <div className='user-status'>
              <img
                src={flagIcon}
                style={{
                  borderRadius: '5px',
                  backgroundColor: '#83bf6e',
                  padding: '7px',
                }}
              />
            </div>
            <div className='status new-status'>
              <span className='status-text new-status-text'>
                {change?.title?.new}
              </span>
            </div>
          </>
          // <div className='status new-status'>
          //   <span className='status-text new-status-text'>
          //     {change?.title?.new}
          //   </span>
          // </div>
        );
      }
      case 'mileStoneEdited': {
        return (
          <>
            <div className='status old-status margin-right-10'>
              <span className='status-text old-status-text strike-through'>
                {change?.title?.old}
              </span>
            </div>
            <div className='status new-status'>
              <span className='status-text new-status-text'>
                {change?.title?.new}
              </span>
            </div>
          </>
        );
      }
      case 'milestoneRemoved': {
        return (
          <>
            <div className='user-status'>
              <img
                src={flagIcon}
                style={{
                  borderRadius: '5px',
                  backgroundColor: '#ff6a55',
                  padding: '7px',
                }}
              />
            </div>
            <div className='status old-status'>
              <span className='status-text old-status-text'>
                {change?.title?.new}
              </span>
            </div>
          </>
        );
      }
    }
  };

  const getTableBody = () => {
    return changedData?.map((change) => {
      if (
        change?.tableType === 'mileStoneEdited' ||
        change?.tableType === 'milestoneAdded' ||
        change?.tableType === 'milestoneRemoved'
      ) {
        return change?.tableData?.changes?.map((_change, index) => {
          return (
            <tr key={JSON.stringify(_change) + index}>
              <td>{getChangeNameHandler(_change.changeEvent)}</td>
              <td>
                <div className='status-cell'>
                  {updatedComparisonsHandler(_change)}
                </div>
              </td>
            </tr>
          );
        });
      }
    });
  };

  return (
    <>
      {shouldShowMilestone ? (
        <ChangeLogTableWrapper>
          <table>
            {getTableHeader()}
            {getTableBody()}
          </table>
        </ChangeLogTableWrapper>
      ) : null}
    </>
  );
};

export default MilestoneComponent;

import React, { FC, useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient, useMutation } from '@apollo/client';
import {
  AlertDialogCloseButton,
  Box,
  Center,
  Flex,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { LocationFieldEntity, userObj } from 'sop-commons/src/client';

import {
  ActionButton,
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../ui-components';
import { toArray } from '../../../utils/utils';

import {
  UPDATE_LOCATION_FIELD,
  UpdateFieldVariable,
  mapToInput,
} from './location-field.graphql';

import { ReactComponent as ArchiveIcon } from '../../../assets/images/archive-solid.svg';

interface IProps {
  locationField: LocationFieldEntity;
  onFieldDeleted?: () => void;
}

const Content: FC<IProps> = ({ locationField, onFieldDeleted }) => {
  const { t } = useTranslation(['setting', 'common']);
  const { onClose } = useModalContext();

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const [updateLocationField] = useMutation<never, UpdateFieldVariable>(
    UPDATE_LOCATION_FIELD,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('common:success'),
          description: t('setting:fieldDeleteSuccess'),
        });
        onFieldDeleted?.();
        setTimeout(onClose);
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('common:error'),
          description: t('setting:fieldDeleteError'),
        });
      },
    }
  );

  const _onDeleteClick = async () => {
    const newValues = toArray(userObj().entity.locationFields).filter(
      (value) => value.eid !== locationField?.eid
    );

    await updateLocationField({
      variables: {
        input: {
          eid: userObj().entityId,
          locationFields: newValues.map(mapToInput),
        },
      },
    });
  };

  return (
    <Flex flexDir='column' gap='24px'>
      <Flex
        p='16px 20px'
        gap={3}
        borderRadius='10px'
        bg='rgba(177, 229, 252, 0.40)'
      >
        <Center height='18px'>
          <ArchiveIcon />
        </Center>
        <Box fontSize='14px' fontWeight='400' color='#33383F'>
          {t('setting:fieldDeleteMessage')}
        </Box>
      </Flex>

      <ActionButton
        colorScheme='red'
        px='32px'
        fontSize='15px'
        fontWeight='600'
        borderRadius='5px'
        alignSelf='flex-end'
        actionFn={_onDeleteClick}
      >
        {t('common:delete')}
      </ActionButton>
    </Flex>
  );
};

type UseDeleteLocationField = (props: IProps) => void;

export const useDeleteLocationField = (): UseDeleteLocationField => {
  const { t } = useTranslation(['setting', 'deck']);
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const apolloClient = useApolloClient();

  return useCallback(({ locationField, onFieldDeleted }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader
            // @ts-ignore
            title={t('deck:delete_name_question', {
              title: locationField.fieldName,
            })}
            color='#CABDFF'
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <Content
          locationField={locationField}
          onFieldDeleted={onFieldDeleted}
        />
      ),
      size: '2xl',
      isCentered: true,
      contentProps: {
        py: '12px',
        px: '8px',
        borderRadius: '16px',
      },
      footer: null,
    });
  }, []);
};

import React, { FC } from 'react';
import { Box, CloseButton, Flex } from '@chakra-ui/react';
import { SelectOption } from '../../../../../atoms/Dropdown';

interface IProps {
  data: SelectOption;
  onClick?: (eid: string) => void;
}

const ChipsItem: FC<IProps> = ({ data, onClick }) => {
  return (
    <Flex
      align='center'
      gap={1}
      bg='rgba(181, 228, 202, 0.6)'
      borderRadius='7px'
      padding='5px 6px 5px 12px'
    >
      <Box as='span' fontSize='14px' fontWeight='600' color='#000000'>
        {data.label}
      </Box>
      <CloseButton size='sm' onClick={() => onClick?.(data.value)} />
    </Flex>
  );
};

export default ChipsItem;

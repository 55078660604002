import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { IFormInput, Stepper } from '../task.types';

interface UseStepperAction {
  onSuccess: () => void;
  onError: () => void;
}

export const useStepperAction = (nextStep: Stepper): UseStepperAction => {
  const { setValue, getValues } = useFormContext<IFormInput>();

  const onSuccess = useCallback(() => {
    let stepper = getValues('currentStep')!;
    if (stepper) {
      setValue(`completed.${stepper}`, true);
      setValue(`filled.${stepper}`, true);
    }

    setValue('currentStep', nextStep);
  }, [nextStep, getValues, setValue]);

  const onError = useCallback(() => {
    let stepper = getValues('currentStep')!;
    if (stepper) {
      setValue(`completed.${stepper}`, false);
      setValue(`filled.${stepper}`, false);
    }

    setValue('currentStep', nextStep);
  }, [nextStep, getValues, setValue]);

  return {
    onSuccess,
    onError,
  };
};

import { Divider, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { IInProgressCardMid } from 'sub-components/LocationLaunchDetail/types/types';
import CurrentOn from './CurrentOn';
import LastDone from './LastDone';

const InProgressCardMid: FC<IInProgressCardMid> = (props) => {
  const {
    currentStage,
    lastDoneOn,
    lastDoneStep,
    sinceWhen,
    orientation = 'vertical',
  } = props;
  return (
    <Flex justify={orientation === 'vertical' ? 'space-between' : 'unset'}>
      <CurrentOn
        currentStage={currentStage}
        sinceWhen={sinceWhen}
        orientation={orientation}
      />
      {orientation === 'horizontal' && <Divider orientation='vertical' />}
      <LastDone
        lastDoneOn={lastDoneOn}
        lastDoneStep={lastDoneStep}
        orientation={orientation}
      />
    </Flex>
  );
};

export default InProgressCardMid;

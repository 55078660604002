import React from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  useModalContext,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { userObj } from 'sop-commons/src/client';

import { FormInput } from '../../../../../../atoms';
import { ActionButton } from '../../../../../../ui-components';
import TitleHeader from '../../../../../../sub-components/CardEditor/TitleHeader';
import { ReactComponent as EditIcon } from '../../../../../../assets/images/edit-dark.svg';
import { UPDATE_PHONE, validationSchema } from './phone-update.graphql';

const PhoneUpdateForm = ({ phoneNumber }) => {
  const { t } = useTranslation(['common', 'setting']);
  const { onClose } = useModalContext();
  const { control, watch, setValue, setFocus, handleSubmit } = useForm({
    defaultValues: {
      phoneNumber,
      isReadOnly: !!phoneNumber,
    },
    resolver: yupResolver(validationSchema),
  });

  const isReadOnly = watch('isReadOnly');

  const [updatePhone] = useMutation(UPDATE_PHONE, {
    onCompleted: (data) => {
      const userData = userObj();
      userObj({
        ...userData,
        phone: data.updateUser?.phone,
      });
    },
  });

  const onSubmit = async (values) => {
    if (!values.isReadOnly) {
      await updatePhone({
        variables: {
          input: {
            phone: values.phoneNumber,
          },
        },
      });
    }
    setTimeout(onClose, 100);
  };

  return (
    <Flex flexDirection='column' gap={3} paddingBottom={2}>
      <Flex flexDirection='column'>
        <TitleHeader title='Mobile number' />

        <Controller
          control={control}
          name='phoneNumber'
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={1} isInvalid={!!fieldState.error}>
                <Flex gap={2}>
                  <FormInput
                    size='lg'
                    variant='outline'
                    placeholder='Enter mobile number'
                    {...field}
                    isReadOnly={isReadOnly}
                  />

                  <IconButton
                    size='lg'
                    variant='outline'
                    aria-label='edit-phone-number'
                    icon={<EditIcon color='#272B30' />}
                    borderRadius='10px'
                    isDisabled={!isReadOnly}
                    onClick={() => {
                      setValue('isReadOnly', false, {
                        shouldDirty: false,
                      });
                      setFocus('phoneNumber');
                    }}
                  />
                </Flex>
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Flex>

      <Flex gap={3}>
        {!phoneNumber ? (
          <>
            <ActionButton
              minWidth='148px'
              fontSize='15px'
              variant='outline'
              close={onClose}
            >
              {t('common:cancel')}
            </ActionButton>
            <ActionButton
              flex={1}
              colorScheme='blue'
              actionFn={handleSubmit(onSubmit)}
            >
              {t('setting:addAndClose')}
            </ActionButton>
          </>
        ) : (
          <ActionButton
            flex={1}
            colorScheme='blue'
            actionFn={handleSubmit(onSubmit)}
          >
            {isReadOnly ? 'Yes, it is correct' : 'Update & close'}
          </ActionButton>
        )}
      </Flex>
    </Flex>
  );
};

export default PhoneUpdateForm;

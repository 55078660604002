import { FC } from 'react';

// import css
import './NotificationCount.scss';
import Responsive from '../../responsive';

interface IProps {
  notificationNumber: number;
}

const NotificationCount: FC<IProps> = ({ notificationNumber }) => {
  return (
    <>
      {notificationNumber < 100 && (
        <>
          <Responsive isTablet isSmallDesktop isMobile>
            <div className='notification-count-container' />
          </Responsive>
          <Responsive isDesktop isBigScreen>
            <div className='notification-count-container'>
              {notificationNumber}
            </div>
          </Responsive>
        </>
      )}
      {notificationNumber > 99 && (
        <>
          <Responsive isTablet isSmallDesktop isMobile>
            <div className='notification-count-container'></div>
          </Responsive>
          <Responsive isDesktop isBigScreen>
            <div className='notification-big-count-container'>99+</div>
          </Responsive>
        </>
      )}
    </>
  );
};

export default NotificationCount;

import React, { FC, useContext } from 'react';
import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import FormDataContext from '../../FormStore/form-data-context';
import noImage from '../../../../assets/images/placeholder/empty-response.svg';

const EmptyFormResponse: FC = () => {
  const { t } = useTranslation('form');
  const { formResponseByIdData } = useContext(FormDataContext);
  return (
    <Center w='full' height='calc(100vh - 400px)'>
      <Flex direction='column' maxW='350px' gap='14px'>
        <Image src={noImage} maxW='300px' mx='auto' />
        <Flex direction='column' textAlign='center' gap='2px'>
          <Box fontSize='15px' fontWeight='700' color='#303030'>
            {t('form_has_no_response', {
              title: formResponseByIdData?.title,
            })}
          </Box>
          <Box fontSize='11px' fontWeight='500' color='#9E9E9E' px='12px'>
            {t('share_form_team')}
          </Box>
        </Flex>
      </Flex>
    </Center>
  );
};

export default EmptyFormResponse;

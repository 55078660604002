import React, { FC } from 'react';
import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment/moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleBolt } from '@fortawesome/pro-duotone-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { getImage } from '../../../../utils';
import { ChatGroupEntity } from '../../../../types';
import { useIsAutoAdd } from '../create-channel/common';

interface IProps {
  channelName: string;
  channel: ChatGroupEntity;
}

const ChannelNameCell: FC<IProps> = ({ channelName, channel }) => {
  const isAutoAdd = useIsAutoAdd(channel?.details);

  return (
    <Flex gap='10px' h='full'>
      <Image
        src={getImage(channel?.icon, channelName)}
        boxSize='56px'
        borderRadius='8px'
      />
      <Flex flexDir='column'>
        <Flex align='baseline' gap='5px'>
          <Text
            fontWeight={700}
            fontSize='15px'
            maxW='210px'
            // isTruncated
            whiteSpace='pre-wrap'
          >
            {channelName}
          </Text>
          {isAutoAdd && (
            <Tooltip hasArrow label='New members will be added automatically'>
              <Box>
                <FontAwesomeIcon
                  icon={faCircleBolt as IconProp}
                  cursor='pointer'
                  fontSize='16px'
                  style={
                    {
                      '--fa-primary-color': 'white',
                      '--fa-secondary-color': '#ffc700',
                      '--fa-secondary-opacity': '1',
                    } as never
                  }
                />
              </Box>
            </Tooltip>
          )}
        </Flex>
        <Text fontWeight={500} fontSize='12px'>
          Updated on:{' '}
          {moment
            ?.unix(channel?.updatedAt || channel?.createdAt)
            ?.format('D MMM')}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ChannelNameCell;

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIconDark } from '../../assets/images/closeIconDark.svg';
import Translator from '../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/resources/localization/translator';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: 900;
    padding: 0 30px;
  }

  .__content {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
  }

  .__message {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }
`;

interface IProps {
  onClose?: () => void;
}

const UserNotRegistered: FC<IProps> = (props) => {
  const { i18n } = useTranslation();

  return (
    <Wrapper>
      <div className='__header'>
        <div className='shareHeading' style={{ flex: '1 1 auto' }}></div>
        <div onClick={props.onClose} style={{ cursor: 'pointer' }}>
          <div className='Comment'>
            <CloseIconDark />
          </div>
        </div>
      </div>
      <div className='__content'>
        <div className='__message'>
          {Translator.translate('USER_NOT_AVAILAVLE_FOR_CHAT', i18n.language)}
        </div>
      </div>
    </Wrapper>
  );
};

export default UserNotRegistered;

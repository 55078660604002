import { useCallback } from 'react';

import { IFormInput } from '../../../../../../../sub-components/tasks/create-task';
import { useResourceVisibility } from '../common';
import ChapterContainer from './ChapterContainer';
import { ChapterResource } from './ChapterListContainer';

interface IProps {
  values: ChapterResource[];
  onAddClick?: (values: IFormInput['contents']) => void;
  onCancelClick?: (...args: any[]) => Promise<void> | void;
}

type UseChapterResource = (props: IProps) => void;

export const useChapterVisibilityCheck = (): UseChapterResource => {
  const resourceVisibility = useResourceVisibility();

  return useCallback(({ values, onAddClick, onCancelClick }) => {
    resourceVisibility({
      title: 'Confirm adding chapter',
      values: values,
      component: ChapterContainer,
      onAddClick: onAddClick,
      onCancelClick: onCancelClick,
    });
  }, []);
};

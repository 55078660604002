import React, { FC } from 'react';
import { Box, FormControl, FormErrorMessage, Radio } from '@chakra-ui/react';
import { Control, Controller, useWatch } from 'react-hook-form';

import BaseRadioGroup from './BaseRadioGroup';
import { SelectRoleAndMember } from './role-member';
import { IFormInput } from '../create-channel.types';

interface IProps {
  control: Control<IFormInput>;
}

const WhoCanPost: FC<IProps> = ({ control }) => {
  const whoCanPost = useWatch({
    control,
    name: 'postMessage.type',
  });

  return (
    <Box>
      <Controller
        control={control}
        name='postMessage.type'
        defaultValue='anyone'
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState?.error}>
              <BaseRadioGroup value={field.value} onChange={field.onChange}>
                <Radio value='anyone'>All channel members</Radio>
                <Radio value='authRole'>Specific members or group</Radio>
              </BaseRadioGroup>
              <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <SelectRoleAndMember
        prefix='postMessage'
        permission={whoCanPost}
        control={control}
      />
    </Box>
  );
};

export default WhoCanPost;

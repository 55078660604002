import { gql } from '@apollo/client';
import { UserStatus } from '../../../../types';

export const getMembers = gql`
  query Members($entityId: String!) {
    EntityUser(entityId: $entityId) {
      eid
      name
      type
      status
    }
  }
`;

export interface MembersResponse {
  EntityUser: Array<{
    eid: string;
    name: string;
    type: 'user' | 'branch';
    status: UserStatus;
  }>;
}

export const NotAllowed: UserStatus[] = ['revoked', 'pending'];

import { gql } from '@apollo/client';

export const PSEUDO_MEMBERS_LIST = gql`
  query LocationExternalUser($locationId: String!) {
    LocationExternalUser(locationId: $locationId) {
      eid
      name
      profilePic
      type
    }
  }
`;

import { QuestionType } from '../types';

export const HiddenResponse = [QuestionType.SECTION, QuestionType.TEXT_SECTION];

export const DisabledNote = [
  QuestionType.SHORT_TEXT,
  QuestionType.LONG_TEXT,
  QuestionType.IMAGE_UPLOAD,
  QuestionType.VIDEO_UPLOAD,
  QuestionType.LOCATION,
  QuestionType.MEMBER,
];

import { useMemo } from 'react';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import { toArray } from '../../../../../utils/utils';

export interface LocationAnalytics {
  complete: number;
  inComplete: number;
  total: number;
}

interface Response {
  EntityUser: Array<{
    eid: string;
    name: string;
  }>;
  TaskSupervisedLocationAnalytics: Record<string, LocationAnalytics>;
}

const TASK_LOCATION_ANALYTICS = gql`
  query TaskLocationAnalytics(
    $entityId: String!
    $type: [userTypeEnum]
    $status: [userStatusEnum]
  ) {
    EntityUser(entityId: $entityId, type: $type, status: $status) {
      eid
      name
    }

    TaskSupervisedLocationAnalytics
  }
`;

interface GraphEntity {
  name: string;
  completeRate: number;
}

interface ReturnType {
  graphData: GraphEntity[];
  loading: boolean;
}

export const useLocationGraphData = (): ReturnType => {
  const entityId = useReactiveVar(userObj)?.entityId;

  const { data, loading } = useQuery<Response>(TASK_LOCATION_ANALYTICS, {
    fetchPolicy: 'network-only',
    skip: !entityId,
    variables: {
      entityId: entityId,
      status: ['active', 'inactive'],
      type: ['branch'],
    },
  });

  const graphData = useMemo(() => {
    const roleAnalytics = data?.TaskSupervisedLocationAnalytics;
    const entityUser = data?.EntityUser;

    return toArray(entityUser).reduce<GraphEntity[]>((acc, loc) => {
      const value = roleAnalytics?.[loc.eid];

      if (value) {
        acc.push({
          name: loc.name,
          completeRate: value
            ? Math.round((value.complete / value.total) * 100)
            : 0,
        });
      }

      return acc;
    }, []);
  }, [data]);

  return { graphData, loading };
};

import React, { FC, useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts-new';

import { GraphLoader } from 'sub-components/tasks/performance';
import { getToolTipConfig } from '../../tasks/performance/common/graph';
import CustomLaunchBar from './CustomLaunchBar';

export interface GraphData {
  name: string;
  total: number;
  active: string;
  completed: string;
  dueTask: string;
}

/**
 * Interface for the props of LaunchGraph component.
 * @param height The height of the graph.
 */
interface ILaunchGraphProps {
  height: number;
  graphData?: GraphData[];
  isLoading?: boolean;
}

/**
 * CustomizedAxisTick renders custom ticks on the X-axis of the bar chart.
 * @param x The x-coordinate of the tick.
 * @param y The y-coordinate of the tick.
 * @param payload The payload containing the value to be displayed.
 * @returns A group of SVG elements representing the custom tick.
 */
const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor='end'
        fill='#2E2C34'
        transform='rotate(-90)'
      >
        {payload.value.length > 16
          ? `${payload.value.substring(0, 14)}...`
          : payload.value}
      </text>
    </g>
  );
};

/**
 * LaunchGraph is a bar chart component displaying data from `launchGraphData`.
 * It uses the Recharts library for rendering the chart.
 *
 * @param props The props passed to the component, including height.
 * @returns The rendered bar chart component wrapped in a GraphLoader.
 */
const LaunchGraph: FC<ILaunchGraphProps> = (props) => {
  const { height, graphData = [], isLoading } = props;

  const graphLength = graphData.length;

  const barSize = useMemo(() => {
    if (graphLength < 9) {
      return 80;
    } else if (graphLength < 16) {
      return 36;
    }
    return 24;
  }, [graphLength]);

  return (
    <GraphLoader
      isLoading={isLoading}
      minHeight={height}
      isEmpty={!graphLength}
      emptyI18Key='launcher:empty.locationProgressData'
    >
      <ResponsiveContainer width='100%' height={height}>
        <BarChart
          data={graphData}
          barSize={barSize}
          barCategoryGap={16}
          margin={{
            top: 20,
            right: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray='3 3' />
          <XAxis
            axisLine={false}
            dataKey='name'
            height={120}
            interval={0}
            tick={<CustomizedAxisTick />}
          />
          <YAxis axisLine={false} tickLine={false} />

          <Tooltip
            cursor={false}
            {...getToolTipConfig()}
            // @ts-ignore
            itemSorter={(item) => 0 - item.value}
          />

          <Bar
            dataKey='dueTask'
            name='Overdue tasks'
            stackId='a'
            fill='#FF6A55'
            unit='%'
            shape={CustomLaunchBar}
          />
          <Bar
            dataKey='active'
            name='Active tasks'
            stackId='a'
            fill='#2A85FF'
            unit='%'
            shape={CustomLaunchBar}
          />
          <Bar
            dataKey='completed'
            name='Completed tasks'
            stackId='a'
            fill='#83BF6E'
            radius={[4, 4, 0, 0]}
            unit='%'
            shape={CustomLaunchBar}
          />
        </BarChart>
      </ResponsiveContainer>
    </GraphLoader>
  );
};

export default LaunchGraph;

import React, { FC } from 'react';
import {
  Accordion,
  Box,
  Center,
  Divider,
  Flex,
  Switch,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { Controller, useForm } from 'react-hook-form';
import { CometChat } from '@cometchat-pro/chat';
import { useMutation } from '@apollo/client';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../../ui-components';
import { Loader } from '../../../../../sub-components';
import { ChatGroupEntity } from '../../../../../types';
import { useUserDataSelector } from '../../../../../hooks';

import { useChatGroupDelete } from '../../ChannelList/useChatGroupDelete';

import BaseAccordionItem from '../channel-config/BaseAccordionItem';
import { IFormInput } from '../create-channel.types';
import { cleanConfig } from '../create-channel.helpers';
import { DELETE_CHAT_GROUP } from '../../service/chat-setting.graphql';

import { ReactComponent as TrashIcon } from '../../../../../assets/images/trash-red.svg';

import UpdateInviteSetting from './UpdateInviteSetting';
import UpdatePostSetting from './UpdatePostSetting';
import { useInviteSubtitle, usePostSubtitle } from '../channel-config';
import {
  GroupMetaVariable,
  UPDATE_GROUP_METADATA,
} from './update-channel.graphql';

interface IProps {
  group: ChatGroupEntity;
  groupId: string;
  onGroupDeleted?: () => void;
}

const ChannelSetting: FC<IProps> = ({ groupId, group, onGroupDeleted }) => {
  const { t } = useTranslation(['common', 'setting']);
  const [initialising, action] = useBoolean(true);

  const authUserId = useUserDataSelector((state) => state.eid);

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const methods = useForm<IFormInput>({
    defaultValues: async () => {
      action.on();
      const _group = await CometChat.getGroup(groupId);
      setTimeout(action.off, 2000);
      return _group.getMetadata() as unknown as IFormInput;
    },
  });

  const chatGroupDelete = useChatGroupDelete();

  const [updateMetaData] = useMutation<never, GroupMetaVariable>(
    UPDATE_GROUP_METADATA
  );

  const onSaveClick = async (key: keyof IFormInput) => {
    try {
      action.on();
      const _values = methods.getValues(key);

      const group = await CometChat.getGroup(groupId);
      const metaData = group.getMetadata() as unknown as IFormInput;

      const newValues = match(key)
        .with('channelCantLeave', () => _values)
        .with('postMessage', () => cleanConfig(_values as never))
        .with('inviteUser', () => cleanConfig(_values as never))
        .otherwise(() => undefined);

      if (newValues !== undefined) {
        const values = Object.assign({}, metaData, {
          [key]: newValues,
        });

        const res = await updateMetaData({
          variables: {
            guid: groupId,
            metadata: values,
          },
        });

        if (res.errors) {
          return Promise.reject(res.errors);
        }

        methods.resetField(key, {
          keepDirty: false,
          keepError: false,
          keepTouched: false,
          defaultValue: values[key],
        });

        action.off();
        toast({
          status: 'success',
          title: t('common:success'),
          description: t('setting:channelSettingSaved'),
        });
      } else {
        console.log('Nothing get changed');
      }
    } catch (e) {
      action.off();
      toast({
        status: 'error',
        title: t('common:error'),
        description: t('setting:channelSettingError'),
      });
      return Promise.reject(e);
    }
  };

  const onLeaveChange = async () => {
    try {
      await onSaveClick('channelCantLeave');
    } catch (e) {
      console.log(e);
    }
  };

  const [deleteChatGroup] = useMutation(DELETE_CHAT_GROUP, {
    onCompleted: () => {
      toast({
        status: 'success',
        title: 'Chat group deleted successfully',
      });
      onGroupDeleted?.();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Chat group could not be deleted',
      });
      // refetch && refetch();
    },
  });

  const onDeletePress = () => {
    chatGroupDelete({
      chatGroup: group,
      onDeletePress: () => {
        return deleteChatGroup({
          variables: {
            guid: group.guid,
          },
        });
      },
    });
  };

  const inviteSubtitle = useInviteSubtitle(methods.control, group?.owner);
  const postSubtitle = usePostSubtitle(methods.control);

  const isDefault = group?.details?.isEditable === false;
  const isOwner = group?.owner?.eid === authUserId;
  const showDelete = !isDefault && !initialising && isOwner;

  return (
    <Box position='relative'>
      {initialising && (
        <Center
          position='absolute'
          zIndex={1}
          borderRadius='md'
          bg='blackAlpha.600'
          width='calc(100% + 24px)'
          height='calc(100% + 12px)'
          mx='-12px'
        >
          <Loader size='lg' />
        </Center>
      )}
      <Accordion defaultIndex={[]} reduceMotion allowToggle>
        <BaseAccordionItem
          title='Who can invite/remove members?'
          subTitle={inviteSubtitle}
        >
          <UpdateInviteSetting
            control={methods.control}
            onSaveClick={onSaveClick}
            owner={group?.owner}
            isDefaultChannel={isDefault}
          />
        </BaseAccordionItem>

        <BaseAccordionItem title='Who can post?' subTitle={postSubtitle}>
          <UpdatePostSetting
            control={methods.control}
            onSaveClick={onSaveClick}
          />
        </BaseAccordionItem>
      </Accordion>

      <Controller
        control={methods.control}
        defaultValue={false}
        name='channelCantLeave'
        render={({ field }) => {
          return (
            <Flex justify='space-between' mt={5}>
              <Box fontSize='16px' fontWeight='500' color='#1A1D1F'>
                Do not allow members to leave this channel
              </Box>

              <Switch
                isChecked={field.value}
                onChange={callAll(field.onChange, onLeaveChange)}
                ref={field.ref}
              />
            </Flex>
          );
        }}
      />

      {showDelete && (
        <React.Fragment>
          <Divider opacity={1} my={5} />

          <ActionButton
            display='flex'
            alignItems='center'
            variant='unstyled'
            leftIcon={<TrashIcon />}
            color='#FF6A55'
            px={0}
            actionFn={onDeletePress}
          >
            Delete channel for everyone
          </ActionButton>
        </React.Fragment>
      )}
    </Box>
  );
};

export default ChannelSetting;

import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUserData } from '../../../../../hooks';
import { toArray } from '../../../../../utils/utils';
import { SelectOption } from '../../../../../atoms';

import ChipsItem from './ChipsItem';
import { AssignedLocationsType, IFormInput } from '../../task.types';
import { sortLocation } from './location.graphql';

const TransData: Record<
  AssignedLocationsType,
  'location' | 'locationOwnerOf' | 'workerOf' | 'everyoneIn'
> = {
  locationUser: 'location',
  locationOwner: 'locationOwnerOf',
  worker: 'workerOf',
  everyone: 'everyoneIn',
};

export const useLocationChips = (): SelectOption[] => {
  const locations = useUserData()?.entity?.locations;
  return useMemo(() => {
    return toArray(locations)
      .map((it) => {
        return {
          label: it.name,
          value: it.eid,
        };
      })
      .sort(sortLocation);
  }, [locations]);
};

interface IProps {
  value?: string[];
}

const LocationChips: FC<IProps> = ({ value }) => {
  const { t } = useTranslation(['task']);

  const { setValue, getValues } = useFormContext<IFormInput>();

  const locationUserType = useWatch<IFormInput, 'assignedLocationsType'>({
    name: 'assignedLocationsType',
  });

  const locationList = useLocationChips();

  const options = useMemo(() => {
    return locationList.filter((it) => value?.includes(it.value));
  }, [locationList, value]);

  const onDeleteClick = (eid: string) => {
    setValue(
      'assignedToLocation',
      getValues('assignedToLocation')?.filter((it) => it !== eid),
      {
        shouldValidate: true,
      }
    );
  };

  if (!locationUserType || !value?.length) {
    return null;
  }

  return (
    <Flex gap='10px' mt='10px'>
      <Box lineHeight='38px' fontSize='14px' fontWeight='600' color='#000000'>
        {t(`task:${TransData[locationUserType]}`)} :
      </Box>
      <Flex flex={1} gap='10px' wrap='wrap'>
        {options.map((value) => (
          <ChipsItem key={value.value} data={value} onClick={onDeleteClick} />
        ))}
      </Flex>
    </Flex>
  );
};

export default LocationChips;

import { useMutation } from '@apollo/client';

import { useUserDataSelector } from '../../../hooks';
import { shallowEqual } from '../../../utils';

import {
  GET_TRAINING_PLAY,
  InputVariable,
  LAUNCHER_TRAINING_COMPLETE,
  TrainingProgressResponse,
  TrainingProgressVariable,
  UPDATE_TRAINING_COMPLETE,
} from './training-play.graphql';

interface IProps {
  userId: string;
  trainingId: string;
  onProgressUpdate?: (
    data: TrainingProgressResponse['UpdateUserTrainingProgress']
  ) => void;
  inputVariable?: InputVariable;
}

interface TrainingProgressUpdate {
  updateProgress: (data: any) => void;
  updating: boolean;
}

export const useTrainingProgressUpdate = (
  props: IProps
): TrainingProgressUpdate => {
  const { trainingId, onProgressUpdate, userId, inputVariable } = props || {};

  const authUser = useUserDataSelector(
    (state) => ({
      eid: state.eid,
      type: state.type,
    }),
    shallowEqual
  );

  const isLauncherTraining = !!inputVariable?.locationLaunchId;

  const [updateTrainingProgress, { loading: updating }] = useMutation<
    TrainingProgressResponse,
    TrainingProgressVariable
  >(
    isLauncherTraining ? LAUNCHER_TRAINING_COMPLETE : UPDATE_TRAINING_COMPLETE,
    {
      refetchQueries: [
        {
          query: GET_TRAINING_PLAY,
          variables: {
            eid: trainingId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (response) => {
        if (onProgressUpdate) {
          onProgressUpdate(
            isLauncherTraining
              ? response.UpdateUserTrainingProgressLauncher
              : response.UpdateUserTrainingProgress
          );
        }
      },
      onError: (error) => {
        console.log('updateProgress ==> ', error?.message);
      },
    }
  );

  const updateProgress = (_data: any) => {
    if (!trainingId || !_data) {
      return;
    }

    const input = {
      eid: trainingId,
    } as TrainingProgressVariable['input'];

    switch (_data?.subType) {
      case 'sop':
        if (_data.sopId && _data.isLastCard) {
          input.type = 'sop';
          input.contentId = _data.sopId;
        } else {
          return;
        }
        break;
      default:
        input.type = _data.type;
        input.contentId = _data.uid;
    }

    if (authUser.type === 'branch' && authUser.eid !== userId) {
      // @ts-ignore
      input['userId'] = userId;
    }

    if (isLauncherTraining) {
      // @ts-ignore
      input['trainingId'] = input.eid;
      // @ts-ignore
      delete input['userId'];
      // @ts-ignore
      delete input['eid'];

      Object.assign(input, inputVariable);
    }

    updateTrainingProgress({
      variables: {
        input: input,
      },
    });
  };

  return {
    updateProgress,
    updating,
  };
};

import { TextProps } from '@chakra-ui/react';
import React, { FC, ReactElement } from 'react';
import CommonContainer from 'ui-components/CommonContainer';
import CommonLabel from '../CommonLabel';

type ICommonWrapperProps = {
  /** Label to be shown at top */
  firstLabel?: string | ReactElement;

  /** Text props (ChakraUI Text Props) for first label. Optional */
  firstLabelProps?: TextProps;

  /** Label to be shown at mid */
  secondLabel?: string | ReactElement;

  /** Text props (ChakraUI Text Props) for second label. Optional */
  secondLabelProps?: TextProps;

  /** Label to be shown at bottom */
  thirdLabel?: string | ReactElement;

  /** Text props (ChakraUI Text Props) for third label. Optional */
  thirdLabelProps?: TextProps;
};

const renderLabel = (label: string | ReactElement, props?: TextProps) =>
  typeof label === 'string' ? <CommonLabel label={label} {...props} /> : label;

const CommonWrapper: FC<ICommonWrapperProps> = (props) => {
  const {
    firstLabel,
    firstLabelProps,
    secondLabel,
    secondLabelProps,
    thirdLabel,
    thirdLabelProps,
  } = props;
  return (
    <CommonContainer display='flex' flexDir='column' w='full'>
      {firstLabel && renderLabel(firstLabel, firstLabelProps)}
      {secondLabel && renderLabel(secondLabel, secondLabelProps)}
      {thirdLabel && renderLabel(thirdLabel, thirdLabelProps)}
    </CommonContainer>
  );
};

export default CommonWrapper;

import { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';

import { ChannelConfig, ChatGroupEntity } from '../../../../../types';
import { toArray } from '../../../../../utils/utils';
import { usersEntityObj } from '../../../../../sub-components/Header';
import { UserEntityData } from '../../../../../shared/graphql/shared-types';

export const useInviteSubtitle = (
  control: Control<any>,
  owner: ChatGroupEntity['owner']
) => {
  const { t } = useTranslation(['setting']);
  const values = useWatch<ChannelConfig, 'inviteUser'>({
    control,
    name: 'inviteUser',
  });

  const _userLists = useReactiveVar(usersEntityObj);

  const users = useMemo(() => {
    return [..._userLists].reduce((acc, value) => {
      acc[value.eid] = value;
      return acc;
    }, {} as Record<string, UserEntityData>);
  }, [_userLists]);

  const options = useMemo(() => {
    if (values?.type === 'authRole') {
      const members = values.members?.map((m) => users[m].name)?.join(', ');

      if (members) {
        return {
          context: 'authRole_member',
          values: {
            roles: toArray(values?.authRoles).join(', '),
            members: members,
          },
        };
      }

      return {
        context: 'authRole',
        values: {
          roles: toArray(values?.authRoles).join(', '),
        },
      };
    }

    if (values?.type === 'creator') {
      return {
        context: 'creator',
        values: {
          creator: owner?.name,
        },
      };
    }

    if (values?.type === 'anyone') {
      return {
        context: 'anyone',
      };
    }

    return undefined;
  }, [values, owner]);

  return <Trans t={t} i18nKey='setting:inviteSubtitle' {...options} />;
};

export const usePostSubtitle = (control: Control<any>) => {
  const { t } = useTranslation(['setting']);
  const values = useWatch<ChannelConfig, 'postMessage'>({
    control,
    name: 'postMessage',
  });

  const _userLists = useReactiveVar(usersEntityObj);

  const users = useMemo(() => {
    return [..._userLists].reduce((acc, value) => {
      acc[value.eid] = value;
      return acc;
    }, {} as Record<string, UserEntityData>);
  }, [_userLists]);

  const options = useMemo(() => {
    if (values?.type === 'authRole') {
      const members = values.members?.map((m) => users[m].name)?.join(', ');

      if (members) {
        return {
          context: 'authRole_member',
          values: {
            roles: toArray(values?.authRoles).join(', '),
            members: members,
          },
        };
      }

      return {
        context: 'authRole',
        values: {
          roles: toArray(values?.authRoles).join(', '),
        },
      };
    }

    if (values?.type === 'anyone') {
      return {
        context: 'anyone',
      };
    }

    return undefined;
  }, [values]);

  return <Trans t={t} i18nKey='setting:postSubtitle' {...options} />;
};

import React, { ChangeEvent, FC } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import EditImage from './EditImage';
import AddImage from './AddImage';
import { useUploadImage } from '../../../hooks';
import { useLayoutDisabled } from '../hook';

interface IProps {
  images?: string[];
  onChange: (images: string[], imageUrl: string) => void;
}

// TODO Need to cross check for new commit
const ImageUpload: FC<IProps> = ({ images = [], onChange }) => {
  const { t } = useTranslation('card');
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
  });
  const imageUpload = useUploadImage();
  const isDisabled = useLayoutDisabled();

  const imageCallback = async (files: FileList | null, index: number) => {
    if (files && files.length) {
      try {
        const imageUrl = await imageUpload(files[0]);
        toast({
          title: t('image_update_success_toast'),
          status: 'success',
        });
        if (index === -1) {
          images.push(imageUrl);
        } else {
          images.splice(index, 1, imageUrl);
        }

        onChange(images, imageUrl);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const fileChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    return imageCallback(e.target.files, index);
  };

  return (
    <Flex mt={2.5} pointerEvents={isDisabled ? 'none' : 'initial'}>
      {images?.map((image, key) => {
        return (
          <EditImage
            key={key}
            image={image}
            imageKey={key}
            onFileChange={(event) => fileChange(event, key)}
          />
        );
      })}

      <AddImage onFileChange={(event) => fileChange(event, -1)} />
    </Flex>
  );
};

export default ImageUpload;

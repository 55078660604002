import React from 'react';
import { allAND } from './allAND';
import { allOR } from './allOR';
import { parentNoConditionChildOrCondition } from './parentNoConditionChildOrCondition';
import { parentNoConditionChildAndCondition } from './parentNoConditionChildAndCondition';
import { parentNoConditionChildNoCondition } from './parentNoConditionChildNoCondition';
import { parentAndConditionChildNoCondition } from './parentAndConditionChildNoCondition';
import { parentOrConditionChildNoCondition } from './parentOrConditionChildNoCondition';
import { parentANDChildOR } from './parentANDChildOR';
import { parentORChildAND } from './parentORChildAND';
import { UserEntityData } from 'shared/graphql/shared-types';

export function compareVisibilityHandler(
  parent: any,
  child: any,
  usersEntityData: UserEntityData[]
) {
  return newCompareVisibility(parent, child, usersEntityData);
}

export type ICompareVisibilityType =
  | 'parentPublicChildPublic'
  | 'parentChildSameVisibleSameLength'
  | 'parentChildSameLengthDifferentVisibility'
  | 'parentLessVisibleThanChild'
  | 'parentMoreVisibleThanChild'
  | 'parentChildNoIntersection'
  | undefined;

const newCompareVisibility = (
  parent: any,
  child: any,
  usersEntityData: UserEntityData[]
) => {
  let visibilityType: ICompareVisibilityType = 'parentChildNoIntersection';
  if (parent?.visibility === 'public' && child?.visibility === 'public') {
    // parent: PUBLIC, child: PUBLIC
    console.log({ parentVisibility: 'public', childVisibility: 'public' });
    visibilityType = 'parentPublicChildPublic';
  } else if (
    parent?.visibility === 'public' &&
    child?.visibility === 'private'
  ) {
    // parent: PUBLIC, child: PRIVATE
    console.log({ parentVisibility: 'public', childVisibility: 'private' });
    visibilityType = 'parentMoreVisibleThanChild';
  } else if (
    parent?.visibility === 'private' &&
    child?.visibility === 'public'
  ) {
    // parent: PRIVATE, child: PUBLIC
    console.log({ parentVisibility: 'private', childVisibility: 'public' });
    visibilityType = 'parentLessVisibleThanChild';
  } else if (
    parent?.visibility === 'private' &&
    child?.visibility === 'private'
  ) {
    // parent: PRIVATE, child: PRIVATE
    console.log({ parentVisibility: 'private', childVisibility: 'private' });
    console.log({
      parentCondition: parent?.condition,
      childCondition: child?.condition,
    });
    if (parent?.condition === 'or' && child?.condition === 'or') {
      let returnedType = allOR(parent, child);
      visibilityType = returnedType;
    } else if (parent?.condition === 'and' && child?.condition === 'and') {
      let returnedType = allAND(parent, child);
      visibilityType = returnedType;
    } else if (parent?.condition === 'and' && child?.condition === 'or') {
      let returnedType = parentANDChildOR(parent, child);
      visibilityType = returnedType;
    } else if (parent?.condition === 'or' && child?.condition === 'and') {
      let returnedType = parentORChildAND(parent, child);
      visibilityType = returnedType;
    } else if (!parent?.condition && child?.condition === 'or') {
      let returnedType = parentNoConditionChildOrCondition(parent, child);
      visibilityType = returnedType;
    } else if (!parent?.condition && child?.condition === 'and') {
      let returnedType = parentNoConditionChildAndCondition(parent, child);
      visibilityType = returnedType;
    } else if (!parent?.condition && !child?.condition) {
      let returnedType = parentNoConditionChildNoCondition(parent, child);
      visibilityType = returnedType;
    } else if (parent?.condition === 'and' && !child?.condition) {
      let returnedType = parentAndConditionChildNoCondition(parent, child);
      visibilityType = returnedType;
    } else if (parent?.condition === 'or' && !child?.condition) {
      let returnedType = parentOrConditionChildNoCondition(parent, child);
      visibilityType = returnedType;
    }
  }
  console.log({ COMPARE_VISIBILITY_FLAG: visibilityType });
  return visibilityType;
};

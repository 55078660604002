import React, { FC, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { Box, Center } from '@chakra-ui/react';
import { gql, useQuery } from '@apollo/client';

import { Trans, useTranslation } from 'react-i18next';

import { AlertBox } from '../../../../atoms';
import { ChatGroupEntity } from '../../../../types';
import { ActionButton } from '../../../../ui-components';
import { toArray } from '../../../../utils/utils';
import { truncateString } from '../../../../utils';
import EmptyState from '../../../../sub-components/EmptyState';

import { INVITED_USER_DATA, InvitedUserData } from './pending-user.graphql';
import {
  Column,
  SortingTable,
} from '../../../../sub-components/ChakraTable/SortingTable';
import GroupName from './GroupName';
import GroupMember from './GroupMember';
import { useUpdateData } from './useUpdateData';
import { InvitedChatGroup } from '../../../../ui-components/InviteUserNew/invite.types';
import { filterChatChannel } from '../../../../ui-components/InviteUserNew/ChatGroups';

const Wrapper = styled.div`
  padding-top: 20px;

  table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`;

const ENTITY_CHAT_GROUPS = gql`
  query Query {
    FetchEntityChatGroups
  }
`;

interface IProps {
  userId: string;
  username: string;
}

const PendingUserChatGroups: FC<IProps> = ({ userId, username }) => {
  const { t, i18n } = useTranslation(['common', 'invite', 'team']);

  const { data, loading } = useQuery<Record<'userById', InvitedUserData>>(
    INVITED_USER_DATA,
    {
      variables: {
        eid: userId,
      },
    }
  );

  const { data: groups, loading: groupLoading } = useQuery<
    Record<'FetchEntityChatGroups', ChatGroupEntity[]>
  >(ENTITY_CHAT_GROUPS, {
    fetchPolicy: 'network-only',
  });

  const [updateUserTraining] = useUpdateData({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: INVITED_USER_DATA,
        variables: {
          eid: userId,
        },
      },
    ],
  });

  const groupList = useMemo(() => {
    if (groups?.FetchEntityChatGroups && data?.userById) {
      return filterChatChannel(
        groups?.FetchEntityChatGroups,
        data.userById.authRole,
        data.userById.role,
        toArray(data.userById.locations).map((l) => l.eid)
      );
    }

    return [];
  }, [groups?.FetchEntityChatGroups, data?.userById]);

  const onRemoveClick = useCallback(
    async (groupId: string) => {
      const newValue = groupList.reduce<InvitedChatGroup[]>(
        (previousValue, currentValue) => {
          if (currentValue.guid !== groupId) {
            previousValue.push({
              guid: currentValue.guid,
              name: currentValue.name,
            });
          }
          return previousValue;
        },
        []
      );
      await updateUserTraining({
        variables: {
          input: {
            userId: userId,
            chatGroups: newValue,
          },
        },
      });
    },
    [groupList, userId]
  );

  const columns = useMemo((): Column<ChatGroupEntity>[] => {
    return [
      {
        Header: t('team:chatGroups'),
        accessor: 'name',
        width: '40%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <GroupName
              name={value}
              thumbnail={row.original.icon}
              createdAt={row.original.createdAt}
            />
          );
        },
      },
      {
        Header: t('team:groupMembers'),
        accessor: 'membersCount',
        width: '40%',
        Cell: ({ cell: { value, row } }) => {
          if (value) {
            return (
              <GroupMember owner={row.original.owner} membersCount={value} />
            );
          }
          return (
            <Box
              mb='25px'
              fontSize='12px'
              fontWeight='600'
              textAlign='center'
              width='max-content'
              padding='3.5px 10px'
              borderRadius='6px'
              bg='rgba(111, 118, 126, 0.12)'
              color='#6F767E'
            >
              {t('common:notStarted')}
            </Box>
          );
        },
      },
      {
        Header: '',
        accessor: 'guid',
        id: 'action',
        width: '20%',
        Cell: ({ cell: { value } }) => {
          return (
            <Center w='full' mb='25px'>
              <ActionButton
                fontSize='14px'
                borderRadius='8px'
                fontWeight='600'
                size='sm'
                variant='outline'
                px={4}
                actionFn={() => onRemoveClick(value)}
              >
                {/* {t('invite:removeFromGroup')} */}
                {t('invite:removeFromChannel')}
              </ActionButton>
            </Center>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [i18n.language, onRemoveClick]);

  return (
    <Wrapper>
      {!!groupList.length && (
        <AlertBox background='rgba(202, 189, 255, 0.35)'>
          <Trans
            t={t}
            i18nKey='team:chatGroupInfo'
            values={{
              name: username,
            }}
          />
        </AlertBox>
      )}

      <SortingTable
        background='#FFFFFF'
        colorScheme='blue'
        emptyData={{
          content: (
            <EmptyState
              image='ChatGroup'
              title={
                <Trans
                  t={t}
                  i18nKey='team:noChatGroupAssignedTo'
                  values={{
                    name: truncateString(username, 24),
                  }}
                />
              }
            />
          ),
        }}
        page={1}
        columns={columns}
        data={groupList}
        isLoading={loading || groupLoading}
        isResponsive
        isHidePagination={true}
      />
    </Wrapper>
  );
};

export default PendingUserChatGroups;

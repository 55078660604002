import React, { FC, useContext, useMemo } from 'react';
import { Box, Flex, Skeleton, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleBolt } from '@fortawesome/pro-duotone-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { BoxHeader } from '../../../../../ui-components';

import { useIsAutoAdd } from '../common';
import { UpdateChannelContext } from './UpdateChannelProvider';

const TitleSkeleton: FC = () => {
  return (
    <Flex align='center'>
      <BoxHeader title='' fontSize='18px' color='#B5E4CA' isTruncated />
      <Skeleton height='20px' width='220px' borderRadius='4px' />
    </Flex>
  );
};

const UpdateChannelTitle: FC = () => {
  const { channel, loading } = useContext(UpdateChannelContext);

  const isAutoAdd = useIsAutoAdd(channel?.details);

  return (
    <Flex align='center' gap='5px'>
      {loading ? (
        <TitleSkeleton />
      ) : (
        <BoxHeader
          title={channel?.name!}
          fontSize='18px'
          color='#B5E4CA'
          isTruncated
        />
      )}
      {isAutoAdd && (
        <Tooltip hasArrow label='New members will be added automatically'>
          <Box>
            <FontAwesomeIcon
              icon={faCircleBolt as IconProp}
              cursor='pointer'
              fontSize='16px'
              style={
                {
                  '--fa-primary-color': 'white',
                  '--fa-secondary-color': '#ffc700',
                  '--fa-secondary-opacity': '1',
                } as never
              }
            />
          </Box>
        </Tooltip>
      )}
    </Flex>
  );
};

export default UpdateChannelTitle;

import { Flex } from '@chakra-ui/react';
import { FC } from 'react';
import TaskOverviewHeading from './TaskOverviewHeading';
import TaskOverviewDetails from './TaskOverviewDetails';

interface IProps {
  sessionId: string;
}

const TaskOverviewRoot: FC<IProps> = ({ sessionId }) => {
  return (
    <Flex
      id='task-overview-root'
      bg='white'
      p={6}
      borderRadius='8px'
      w='full'
      flexDir='column'
      gap='20px'
      h='full'
    >
      <TaskOverviewHeading />
      <Flex id='task-overview-root-details' gap='20px' w='full'>
        <TaskOverviewDetails sessionId={sessionId} />
      </Flex>
    </Flex>
  );
};

TaskOverviewRoot.displayName = 'TaskDetails/C/TaskOverview/TaskOverviewRoot';

export default TaskOverviewRoot;

import React, { FC } from 'react';
import { Center, Flex, List, ListItem, Skeleton } from '@chakra-ui/react';
import { MemberNameSkeleton } from '../../common';
import { LocationOwnerEntity } from '../perfor-detail.graphql';
import LocationOwnersList from './LocationOwnersList';

interface IProps {
  data: LocationOwnerEntity[];
  loading: boolean;
  openChat?: (userId: string) => void;
}

const LocationOwnersListContainer: FC<IProps> = ({
  data,
  loading,
  openChat,
}) => {
  if (loading) {
    return (
      <List spacing='20px'>
        {Array.from({ length: 2 }).map((value, index) => {
          return (
            <ListItem key={index}>
              <Flex justify='space-between'>
                <MemberNameSkeleton />
                <Center px={4}>
                  <Skeleton boxSize='16px' borderRadius='4px' />
                </Center>
              </Flex>
            </ListItem>
          );
        })}
      </List>
    );
  }

  return <LocationOwnersList data={data} openChat={openChat} />;
};

export default LocationOwnersListContainer;

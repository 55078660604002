import { useEffect, useState, useRef } from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import { PlusOutlined, CheckOutlined, RightOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import gql from 'graphql-tag';

import { bundleObj, roleObj } from '../DashboardMenu';
import RoleLabel from '../RoleLabel';

// import css
import './SopRoles.css';

const EDIT_SOP = gql`
  mutation updateSop($input: SopUpdateInput) {
    updateSop(input: $input) {
      bundles
      chapter
      raw_content
      entity
      content
      entityDetails {
        name
        bundles {
          color
          name
        }
        roles {
          name
          color
        }
      }
    }
  }
`;

interface SearchBarProps {
  autoFocus: boolean;
  setSearchedText: (e: any) => void;
  searchedText: string;
  placeholder: string;
  onEnter?: any;
}

interface Props {
  roles?: any;
  sopId?: string;
  chapter?: any;
  setSelectedRoles?: any;
  isAdmin?: boolean;
  refetchSopData?: Function;
  isPublic?: boolean;
  roleWithColor?: any;
}

export const SearchBar = ({
  autoFocus,
  setSearchedText,
  searchedText,
  placeholder = '',
  onEnter,
}: SearchBarProps) => {
  return (
    <div className='sop-roles-search-bar'>
      <PlusOutlined className='sop-roles-search-bar-add-icon' />
      <div>
        <input
          value={searchedText}
          autoFocus={autoFocus}
          onChange={(e) => setSearchedText(e.target.value)}
          placeholder={placeholder}
          onKeyPress={onEnter}
        />
      </div>
    </div>
  );
};

interface AddRolePopoverOptionsProp<TValue> {
  [id: string]: TValue;
}
interface AddRolePopoverProps {
  options: AddRolePopoverOptionsProp<string>;
  type: string;
  selectedData?: any;
  sopId: any;
  chapter?: any;
  setSelectedData?: any;
}

const useDebounce = (val: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(val);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(val);
    }, delay);
    return () => clearTimeout(handler);
  }, [val]);
  return debouncedValue;
};

export const AddRolePopover = ({
  type = 'role',
  options = {},
  selectedData,
  sopId,
  chapter,
  setSelectedData,
  refetchUser,
}: AddRolePopoverProps) => {
  const [updateSop] = useMutation(EDIT_SOP, {
    onCompleted: (response) => {},
  });
  const [searchedText, setSearchedText] = useState('');
  const [selectedOption, setSelectedOption] = useState<string[]>([]);
  const debouncedSearchText = useDebounce(searchedText, 300);
  const [displayOptions, setDisplayOptions] = useState(options);
  const [openPopup, setOpenPopup] = useState(false);
  const ref: any = useRef(null);

  useEffect(() => {
    const newOptions: any = {};
    Object.keys(options).forEach((item: string) => {
      if (item.toLowerCase().includes(debouncedSearchText)) {
        newOptions[item] = options[item];
      }
    });
    setDisplayOptions(newOptions);
    setSelectedOption([...selectedData]);
  }, [debouncedSearchText, selectedData, options]);

  const onOpen = () => {
    setOpenPopup(!openPopup);
  };

  const onClose = () => {
    setSearchedText('');
    setOpenPopup(false);
  };

  const onCreateBundle = () => {
    let variables: any = {
      input: {
        sopId: sopId,
        chapter: chapter,
      },
    };
    selectedOption.push(debouncedSearchText);
    if (type === 'role') {
      variables.input.roles = selectedOption;
    } else if (type === 'bundle') {
      variables.input.bundles = selectedOption;
    }
    updateSop({
      variables: variables,
    });

    setSelectedData([...selectedOption]);
    setTimeout(() => {
      options[debouncedSearchText] = '#4d8af0';
      bundleObj(options);
      refetchUser();
      onClose();
    }, 200);
  };

  return (
    <Popover
      placement='bottomRight'
      ref={ref}
      title={
        <SearchBar
          autoFocus
          setSearchedText={setSearchedText}
          searchedText={searchedText}
          placeholder={
            type === 'bundle' ? `Create or search ${type}s` : 'Search Roles'
          }
          onEnter={(e) => {
            if (e.key === 'Enter' && type === 'bundle') {
              onCreateBundle();
            }
          }}
        />
      }
      visible={openPopup}
      arrowPointAtCenter
      onVisibleChange={(visibleEvent) => {
        if (!visibleEvent) {
          onClose();
        }
      }}
      content={
        <>
          {Object.keys(displayOptions).map((item: string) => {
            // const item = data.trim().toLowerCase();
            return (
              <div
                key={item}
                className='add-role-popover-each-result'
                onClick={() => {
                  if (selectedOption.indexOf(item) === -1) {
                    selectedOption.push(item);
                  } else {
                    const index = selectedOption.indexOf(item);
                    selectedOption.splice(index, 1);
                  }
                  let variables: any = {
                    input: {
                      sopId: sopId,
                      chapter: chapter,
                    },
                  };
                  if (type === 'role') {
                    variables.input.roles = selectedOption;
                  } else if (type === 'bundle') {
                    variables.input.bundles = selectedOption;
                  }
                  updateSop({
                    variables: variables,
                  });
                  setSelectedOption([...selectedOption]);
                  setSelectedData([...selectedOption]);
                }}
              >
                <div
                  style={{
                    backgroundColor: displayOptions[item],
                  }}
                  className='each-result-color-box'
                ></div>
                <div className='each-result-text'>{item}</div>
                <div className='each-result-checked'>
                  {selectedOption.indexOf(item) !== -1 && <CheckOutlined />}
                </div>
              </div>
            );
          })}
          {!!debouncedSearchText &&
            debouncedSearchText.length > 0 &&
            type !== 'role' &&
            Object.keys(displayOptions).length === 0 && (
              <div
                className='create-new-bundle-cta'
                onClick={() => {
                  onCreateBundle();
                }}
              >
                <div>Create new {type}</div>
                <RightOutlined />
              </div>
            )}
        </>
      }
      trigger='click'
    >
      <PlusOutlined className='sop-roles-header-add-cta' onClick={onOpen} />
    </Popover>
  );
};

const SopRoles = ({
  roles,
  sopId,
  chapter,
  setSelectedRoles,
  isAdmin,
  isPublic,
  roleWithColor,
}: Props) => {
  const roleColorByName = isPublic
    ? roleWithColor
    : useReactiveVar<any>(roleObj);
  return (
    <div>
      <div className='sop-roles-header'>
        <div className='eventclass-sop-detail-role-popover sop-roles-header-text'>
          Roles
        </div>
        {isAdmin && !isPublic && (
          <AddRolePopover
            type='role'
            options={roleColorByName}
            selectedData={roles}
            sopId={sopId}
            chapter={chapter}
            setSelectedData={setSelectedRoles}
          />
        )}
      </div>

      <div
        className='eventclass-sop-detail-roles-tag'
        style={{ display: 'flex', flexWrap: 'wrap', margin: '10px 0' }}
      >
        {roles &&
          !!roles.length &&
          roles.map((role: any, index: number) => {
            return (
              <RoleLabel
                title={role}
                colorCode={roleColorByName[role]}
                key={index}
                style={{ marginRight: '8px' }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SopRoles;

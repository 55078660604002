import React, { FC, useContext } from 'react';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import SetSeeModalNew from './SetSeeModal/SetSeeModalNew';
import SetSeeDataContext from './SetSeeModal/SetSeeModalStore/set-see-data-context';
import SetSeeDataProvider from './SetSeeModal/SetSeeModalStore/SetSeeDataProvider';
import { useWatch } from 'react-hook-form';
import { IFormInput } from '../../../sub-components/ChapterEditor/chaptor.types';
import {
  IConditionType,
  IType,
  IVisibilityEntity,
} from './SetSeeModal/set-see-modal-types';
import { IFolderVisibilityType } from 'pages/Chapters/FolderVisibility/FolderVisibility';
import {
  ISelectedLocationData,
  ISelectedMemberData,
  ISelectedRoleData,
} from 'zustandStore/slices/useVisibility';

interface Props {
  isOpen: boolean;
  handleClose: (
    type: string,
    visibilityObject?: IVisibilityEntity,
    selectedData?: any
  ) => void;
  visibilityData?: any;
  setVisiblityData?: any;
  isCreate?: boolean;
  showVisibilityInfo?: boolean;
  modalType: 'read' | 'write';
  setCreateVisibility?: React.Dispatch<
    React.SetStateAction<{
      locations: string[];
      roles: string[];
      users: string[];
      condition?: IConditionType;
      visibility?: IType;
    }>
  >;
  isLoading: boolean;
  showStateData?: {
    showFolder?: boolean;
    showVisibility?: boolean;
  };
  shouldHideOverlay?: boolean;
  folderVisibilityUpdateHandler?: () => void;
}

const VisibilityModal: FC<Props> = (props: Props) => {
  const {
    isOpen,
    handleClose,
    showVisibilityInfo,
    setCreateVisibility,
    modalType,
    isLoading,
    folderVisibilityUpdateHandler,
  } = props;

  const chapterHeading = useWatch<IFormInput>({
    name: 'title',
  });

  const onClose = (
    type: string,
    visibilityObject?: IVisibilityEntity,
    selectedData?: any
  ) => {
    handleClose(type, visibilityObject, selectedData);
  };

  return (
    <SetSeeDataProvider>
      <Modal isOpen={isOpen} onClose={() => onClose('close')} size={'3xl'}>
        {!props?.shouldHideOverlay && <ModalOverlay />}
        <ModalContent>
          <ModalBody p={'8'}>
            <SetSeeModalNew
              onClose={onClose}
              title={chapterHeading}
              showVisibilityInfo={showVisibilityInfo}
              setCreateVisibility={setCreateVisibility}
              modalType={modalType}
              isLoading={isLoading}
              folderVisibilityUpdateHandler={folderVisibilityUpdateHandler}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </SetSeeDataProvider>
  );
};

export default VisibilityModal;

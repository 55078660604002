import React, { CSSProperties, FC, ReactNode } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerProps,
  Box,
} from '@chakra-ui/react';

/**
 * Props for CommonDrawer component.
 */
interface IDrawerProps {
  /**
   * Indicates whether the drawer is open.
   */
  isOpen: boolean;

  /**
   * Function to call when the drawer needs to be closed.
   */
  onClose: () => void;

  /**
   * Placement of the drawer. Default is `right`. Optional.
   */
  placement?: DrawerProps['placement'];

  /**
   * Size of the drawer. Uses `DrawerProps['size']`. Default is `sm`. Optional.
   */
  size?: DrawerProps['size'];

  /**
   * Optional flag to show the close button. Defaults to true.
   */
  showCloseBtn?: boolean;

  /**
   * Optional padding for the drawer.
   */
  padding?: CSSProperties['padding'];

  /**
   * Optional ReactNode for the header component.
   */
  headerComp?: ReactNode;

  /**
   * Optional ReactNode for the body component.
   */
  bodyComp?: ReactNode;

  /**
   * Optional ReactNode for the footer component.
   */
  footerComp?: ReactNode;
}

/**
 * CommonDrawer is a functional component that utilizes Chakra UI's Drawer component.
 * It renders a drawer with customizable header, body, and footer components.
 *
 * @param props - The properties passed to the component.
 * @param props.bodyComp - Optional ReactNode for the body component.
 * @param props.footerComp - Optional ReactNode for the footer component.
 * @param props.headerComp - Optional ReactNode for the header component.
 * @param props.padding - Optional padding of the drawer. Defaults to `2rem`.
 * @param props.placement - Optional placement of the drawer. Defaults to `right.
 * @param props.showCloseBtn - Optional flag to show the close button. Defaults to `true`.
 * @param props.size - Optional size of the drawer. Uses `DrawerProps['size']`. Default is `sm`.
 * @returns A Drawer component with optional header, body, and footer.
 */
const CommonDrawer: FC<IDrawerProps> = ({
  bodyComp,
  footerComp,
  headerComp,
  isOpen,
  padding = '2rem',
  placement = 'right',
  showCloseBtn = true,
  size = 'sm',
  onClose,
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement={placement} size={size}>
      <DrawerOverlay />
      <DrawerContent p={padding}>
        {showCloseBtn && <DrawerCloseButton />}
        {headerComp && <DrawerHeader>{headerComp}</DrawerHeader>}
        {bodyComp && <DrawerBody>{bodyComp}</DrawerBody>}
        {footerComp && <DrawerFooter>{footerComp}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  );
};

export default CommonDrawer;

import {
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
} from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBullseyeArrow } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useContext } from 'react';
import { CircularProgressIcon } from 'sub-components';
import SupervisedTaskDetailsDataContext from '../../store/supervised-task-details-context';

interface IProps {}

const ProgressRate: FC<IProps> = () => {
  const supervisedCtx = useContext(SupervisedTaskDetailsDataContext);
  const progressRate =
    supervisedCtx?.taskDetails?.lastSession?.progressRate || 0;
  return (
    <Flex bg='white' borderRadius='8px' p={6} gap='10px' align='center'>
      <Flex>
        <CircularProgressIcon
          progressRate={progressRate}
          icon={
            <FontAwesomeIcon
              icon={faBullseyeArrow as IconProp}
              color='#0062FF'
            />
          }
        />
      </Flex>
      <Flex flexDir='column' gap={2}>
        <Text fontWeight={600} fontSize='28px' color='#171725'>
          {progressRate}%
        </Text>
        <Text fontWeight={400} fontSize='14px' color='#696974'>
          Completion rate
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProgressRate;

import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import './formCard.scss';

import FormInput from '../../../atoms/FormInput';
import TitleHeader from '../TitleHeader';
import { CardFormInput } from '../card-editor.types';
import { useLayoutDisabled } from '../hook';

const FormCardEditor: FC = () => {
  const { t } = useTranslation('card');
  const { t: tc } = useTranslation('common');
  const { control, setValue } = useFormContext<CardFormInput>();
  const isDisabled = useLayoutDisabled();

  return (
    <div className='videoCardContainer'>
      <div>
        <TitleHeader
          title={t('title')}
          desc={t('title_desc')}
          titleStyle={{ marginTop: '30px' }}
          isRequired
        />
        <Controller
          name={'title'}
          control={control}
          rules={{
            required: t('validation.title_required'),
            validate: (value) => {
              if (value?.trim()?.length === 0) {
                return tc('validation.enter_title');
              }
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={3} isInvalid={!!fieldState?.error}>
                <FormInput
                  id='title'
                  size='lg'
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    setValue('raw_content', event.target.value);
                  }}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <TitleHeader
          title={t('google_form')}
          desc={t('google_form_desc')}
          titleStyle={{ marginTop: '30px' }}
          isRequired
        />

        <Controller
          name={'formLink'}
          control={control}
          rules={{
            required: t('validation.link_required'),
            validate: (value) => {
              if (value?.trim()?.length === 0) {
                return t('validation.link_enter');
              }
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl
                mt={3}
                isInvalid={!!fieldState?.error}
                isDisabled={isDisabled}
              >
                <FormInput id='formLink' size='lg' {...field} />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </div>
    </div>
  );
};

export default FormCardEditor;

import { TFunction } from 'i18next';
import { RolesEntity } from 'sop-commons/src/client';

import { FormVisibility, IVisibilityInput } from './visibility.types';
import {
  LocationResponse,
  MembersResponse,
} from '../../../../pages/Training/TrainingList/Component/MemberFilter/member-filter.graphql';
import { SelectOption } from '../../../../atoms';
import { toArray } from '../../../../utils/utils';

interface DataItem {
  name: string;
  eid: string;
  status: string;
}

const mapSelectionData = (dataList: DataItem[], values?: string[]) => {
  return toArray(dataList)?.reduce((acc, value) => {
    if (values?.includes(value.eid)) {
      acc.push({
        value: value.eid,
        label: value.name,
      });
    }
    return acc;
  }, [] as SelectOption[]);
};

const mapRoleSelectionData = (dataList: DataItem[], values?: string[]) => {
  return toArray(dataList)?.reduce((acc, value) => {
    if (values?.includes(value.eid) || values?.includes(value.name)) {
      acc.push({
        value: value.name,
        label: value.name,
      });
    }
    return acc;
  }, [] as SelectOption[]);
};

export const formatVisibilityData = (
  _values: Partial<FormVisibility>,
  branch: LocationResponse['branch'],
  roles: RolesEntity[] = [],
  trans: TFunction<('common' | 'role')[], undefined, ('common' | 'role')[]>,
  membersData: MembersResponse
): IVisibilityInput => {
  const { visibility, visibleTo } = _values || ({} as FormVisibility);

  const data = {
    visibility: visibility,
  } as IVisibilityInput;

  if (visibility !== 'private') {
    return data;
  }

  const { descendantBranches } = branch;

  if (visibleTo?.locations?.length) {
    data.filterBy = {
      value: 'location',
      label: trans('common:location'),
    };

    data.filterValue = mapSelectionData(
      descendantBranches,
      visibleTo?.locations
    );

    if (visibleTo?.roles?.length) {
      data.operation = visibleTo.condition;

      data.otherFilterBy = {
        value: 'job',
        label: trans('role:job'),
      };

      data.otherFilterValue = mapRoleSelectionData(roles, visibleTo?.roles);
    }
  } else {
    data.filterValue = mapRoleSelectionData(roles, visibleTo?.roles);

    if (data.filterValue?.length) {
      data.filterBy = {
        value: 'job',
        label: trans('role:job'),
      };
    }

    if (visibleTo?.locations?.length) {
      data.operation = visibleTo.condition;

      data.otherFilterBy = {
        value: 'location',
        label: trans('common:location'),
      };

      data.otherFilterValue = mapSelectionData(
        descendantBranches,
        visibleTo?.locations
      );
    }
  }

  data.members = mapSelectionData(
    membersData?.userDescendants,
    visibleTo?.users
  );

  return data;
};

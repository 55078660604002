import { HStack, VStack } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import React, { FC } from 'react';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons';
import CommonLabel from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';

interface IProps {
  selectedTaskData: CustomLauncherTask;
}

const DateDetails: FC<IProps> = ({ selectedTaskData }) => {
  console.log({ selectedTaskData });
  return (
    <VStack align='flex-start' w='full'>
      {/* SCHEDULE START */}
      <HStack align='center' justify='space-between' w='full'>
        <CommonLabel label='Schedule start' fontWeight={500} color='#33383F' />
        <HStack>
          <FontAwesomeIcon
            icon={faCalendarCheck as IconProp}
            color='#33383F'
            cursor='pointer'
          />
          <CommonLabel
            label={selectedTaskData?.formattedStartDate?.format('DD MMM YYYY')}
            fontWeight={500}
            color='#33383F'
          />
        </HStack>
      </HStack>

      {/* SCHEDULE COMPLETE */}
      <HStack align='center' justify='space-between' w='full'>
        <CommonLabel
          label='Schedule completion'
          fontWeight={500}
          color='#33383F'
        />
        <HStack>
          <FontAwesomeIcon
            icon={faCalendarCheck as IconProp}
            color='#33383F'
            cursor='pointer'
          />
          <CommonLabel
            label={selectedTaskData?.formattedDueDate?.format('DD MMM YYYY')}
            fontWeight={500}
            color='#33383F'
          />
        </HStack>
      </HStack>

      {/* MARKED COMPLETE ON */}
      {selectedTaskData?.formattedCompleteDate &&
        selectedTaskData?.formattedCompleteDate?.isValid() &&
        selectedTaskData?.taskStatus === 'complete' && (
          <HStack align='center' justify='space-between' w='full'>
            <CommonLabel
              label='Marked complete on'
              fontWeight={500}
              color='#33383F'
            />
            <HStack>
              <FontAwesomeIcon
                icon={faCalendarCheck as IconProp}
                color='#33383F'
                cursor='pointer'
              />
              <CommonLabel
                label={selectedTaskData?.formattedCompleteDate?.format(
                  'DD MMM YYYY'
                )}
                fontWeight={500}
                color='#33383F'
              />
            </HStack>
          </HStack>
        )}
    </VStack>
  );
};

export default DateDetails;

import React, { FC } from 'react';
import { List } from '@chakra-ui/react';

import { UserTaskProgress } from 'types';

import { ITaskData } from './task-details.graphql';
import TaskDetailItem from './TaskDetailItem';

interface IProps {
  taskItems?: ITaskData['taskItems'];
  userProgress?: UserTaskProgress;
  onClick?: (data: ITaskData['taskItems'][number]) => void;
}

const TaskDetailsList: FC<IProps> = ({
  taskItems = [],
  userProgress,
  onClick,
}) => {
  return (
    <List spacing='20px' mt='24px' overflow='auto'>
      {taskItems.map((value) => {
        return (
          <TaskDetailItem
            key={value.eid}
            content={value}
            userProgress={userProgress}
            onClick={onClick}
          />
        );
      })}
    </List>
  );
};

export default TaskDetailsList;

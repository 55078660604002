import React, { FC, useRef } from 'react';
import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div<{
  isDisabled?: boolean;
}>`
  margin-top: 22px;
  position: relative;

  ${({ isDisabled }) => {
    if (isDisabled) {
      return {
        cursor: 'not-allowed',
        '> div': {
          'pointer-events': 'none',
        },
      };
    }
    return {};
  }}

  .scroll-wrapper {
    display: flex;
    align-items: center;
    overflow-x: auto;
    gap: 12px;
    padding: 4px;

    svg {
      cursor: pointer;
    }
  }

  .scroll-left,
  .scroll-right {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 50%;
    cursor: pointer;
    background: white;

    :hover {
      opacity: 0.75;
    }
  }

  .scroll-left {
    left: 0;
    transform: translate(-45%, -50%);
  }

  .scroll-right {
    right: 0;
    transform: translate(45%, -50%);
  }
`;

const LayoutSelect: FC<{
  isDisabled?: boolean;
}> = ({ children, isDisabled }) => {
  const ref = useRef<HTMLDivElement>(null);

  const onLeftClick = () => {
    const widthToScroll = ref?.current?.children.item(0)?.clientWidth || 60;
    ref.current?.scrollBy({
      left: -widthToScroll,
      behavior: 'smooth',
    });
  };

  const onRightClick = () => {
    const widthToScroll = ref?.current?.children.item(0)?.clientWidth || 60;
    ref.current?.scrollBy({
      left: widthToScroll,
      behavior: 'smooth',
    });
  };

  return (
    <Wrapper isDisabled={isDisabled}>
      <div className='scroll-left' onClick={onLeftClick}>
        <FontAwesomeIcon
          icon={faCircleChevronLeft as IconProp}
          color='#6f767e'
        />
      </div>
      <div className='scroll-wrapper' ref={ref}>
        {children}
      </div>
      <div className='scroll-right' onClick={onRightClick}>
        <FontAwesomeIcon
          icon={faCircleChevronRight as IconProp}
          color='#6f767e'
        />
      </div>
    </Wrapper>
  );
};

export default LayoutSelect;

import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../../ui-components';
import { LAUNCHER_OPENING_TASKS } from '../../../../appRoutes';
import { capitalizeFirstLetter } from '../../../../utils';

import { TemplateContent } from '../board-view/template.graphql'; // TODO

import { transformTemplate } from './transform-template';

interface IProps {
  title: string;
  contents: TemplateContent[];
  initialising?: boolean;
}

const StartButton: FC<IProps> = ({ initialising, contents, title }) => {
  const { t } = useTranslation('launcher');
  const history = useHistory();

  // Define an asynchronous function to handle the click event
  const onProceedClick = async () => {
    const response = transformTemplate(
      contents,
      capitalizeFirstLetter(title.toLowerCase()).concat(' ', 'checklist')
    );
    // Redirect to the launcher opening task page
    history.push({
      pathname: LAUNCHER_OPENING_TASKS,
      state: {
        templatedData: response,
      },
    });
  };

  if (initialising) {
    return null;
  }

  const isDisabled = !contents?.length;

  return (
    <Flex position='sticky' bottom='0' width='full'>
      <ActionButton
        w='full'
        fontSize='15px'
        fontWeight='700'
        colorScheme='blue'
        isDisabled={isDisabled}
        actionFn={isDisabled ? undefined : onProceedClick}
      >
        {t('startUsingTemplate')}
      </ActionButton>
    </Flex>
  );
};

export default StartButton;

import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowUpFromBracket } from '@fortawesome/sharp-regular-svg-icons';

interface IProps {
  multiple?: boolean;
  onFileDrop?: (fileList: File[]) => void;
}

const DocUpload: FC<IProps> = ({ onFileDrop, multiple = false }) => {
  const { t } = useTranslation(['location']);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
    multiple: multiple,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Flex flexDir='column' gap='22px' pb='20px'>
      <Center flexDirection='column' gap='4px'>
        <Text fontWeight={600} fontSize='22px' color='#000000'>
          {t('location:uploadHere')}
        </Text>
        <Text fontWeight={600} fontSize='17px' color='#92929D'>
          <Trans
            t={t}
            i18nKey='location:dropYourFileOrBrowse'
            components={{
              span: (
                <Button
                  variant='link'
                  colorScheme='blue'
                  fontSize='17px'
                  fontWeight='600'
                  onClick={open}
                />
              ),
            }}
          />
        </Text>
      </Center>

      <Flex
        w='full'
        flexDir='column'
        gap={2}
        p='20px 16px'
        border='1px dashed #9A9FA5'
        borderRadius='11px'
      >
        <Box {...getRootProps()}>
          <input {...getInputProps()} />
          <Center h='full' flexDirection='column' gap={1}>
            <FontAwesomeIcon
              icon={faArrowUpFromBracket as IconProp}
              color='#212121'
              size='2x'
            />

            <Text mt={2} fontWeight={400} fontSize='18px' color='#212121'>
              {t('location:dragAndDropImgHere')}
            </Text>
            <Text fontWeight={400} fontSize='14px' color='#212121B2'>
              <Trans
                t={t}
                i18nKey='location:jpgPdfMaxSize'
                values={{
                  size: '5Mb',
                }}
              />
            </Text>
          </Center>
        </Box>
      </Flex>
    </Flex>
  );
};

export default DocUpload;

import React, { FC, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Center, Flex, Text, useModalContext } from '@chakra-ui/react';
import {
  ActionButton,
  IconImage,
  UseConfirm,
  useConfirm,
} from '../ui-components';
import delightree from '../assets/images/delightreeIcon.svg';
import { alertDataParse } from './route-alert/alert-helper';

interface IProps {
  message: string;
  callback: CallableFunction;
}

const ConfirmContent: FC<IProps> = ({ message, callback }) => {
  const { onClose } = useModalContext();

  return (
    <Center flexDir='column' gap={3}>
      <IconImage name='Delightree' thumbnail={delightree} boxSize={80} />
      <Text fontSize='20px' fontWeight='600'>
        Leave page?
      </Text>
      <Text textAlign='center'>{message}</Text>
      <Flex w='full' gap={3}>
        <ActionButton
          flex={1}
          fontSize='15px'
          actionFn={() => callback(false)}
          close={onClose}
        >
          Cancel
        </ActionButton>
        <ActionButton
          flex={1}
          fontSize='15px'
          actionFn={() => callback(true)}
          close={onClose}
          colorScheme='blue'
        >
          Leave
        </ActionButton>
      </Flex>
    </Center>
  );
};

const Router: FC = (props) => {
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  const getUserConfirmation = (
    message: string,
    callback: (ok: boolean) => void
  ) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    const config = alertDataParse(message, callback);

    if (typeof config === 'object') {
      confirmRef.current = confirm({
        // content: <ConfirmContent message={message} callback={callback} />,
        ...config,
        contentProps: {
          maxWidth: '400px',
          paddingInline: '8px',
          paddingTop: '14px ',
          paddingBottom: '22px ',
          borderRadius: 'lg',
          ...config.contentProps,
        },
        bodyProps: {
          paddingTop: '16px',
          ...config.bodyProps,
        },
        footer: null,
      });
    } else {
      callback(window.confirm(message));
    }
  };

  // @ts-ignore
  return <BrowserRouter getUserConfirmation={getUserConfirmation} {...props} />;
};

export default Router;

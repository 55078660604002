import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import JobPerformanceBarGraph from './Components/graphs/JobPerformanceBarGraph';

import PerformanceOverview from './LocationOwnerGraphs/PerformanceOverview';
import MyWeeklyGraph from './LocationOwnerGraphs/MyWeeklyGraph';

import { JOB_PERFORMANCE } from '../../appRoutes';
import { LocationCompleteRateGraph } from '../../sub-components/tasks/performance/overview';

const LocationOwnerGraphContainer: FC = () => {
  const history = useHistory();

  const onFullViewClick = () => history.push(JOB_PERFORMANCE);

  return (
    <Flex flexDirection='column' gap='20px'>
      <Flex gap='20px'>
        <Flex flex={3} flexDir='column' gap='20px'>
          <JobPerformanceBarGraph onFullViewClick={onFullViewClick} />
          <MyWeeklyGraph />
        </Flex>
        <Flex flex={3}>
          <PerformanceOverview />
        </Flex>
      </Flex>
      <LocationCompleteRateGraph fromLocationOwner />
    </Flex>
  );
};

export default LocationOwnerGraphContainer;

import React, { FC } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import CommonLabel from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';

/**
 * Props for the ReadyLiveCard component.
 *
 * @property {string} locName - The name of the location.
 * @property {string} completedOn - The date when the task was completed.
 * @property {() => void} [onClick] - Optional click handler for the 'Make Live' button.
 */

export interface IReadyLiveCard {
  eid: string;
  locationName: string;
  completedOn: string;
  isLive?: boolean;
  onClick?: () => void;
}

/**
 * ReadyLiveCard component displays a card with location name, completion date,
 * and button to make the location live.
 * Only those locations will be shown here who have completed all the tasks and only making them live remains.
 *
 * This component is a part of the Location Launch Dashboard, it's used for
 * showing locations that are ready to go live.
 *
 * @param props - The properties for the ReadyLiveCard component
 * @returns The JSX code for ReadyLiveCard component.
 */

const ReadyLiveCard: FC<IReadyLiveCard> = ({
  locationName,
  completedOn,
  onClick,
}) => {
  return (
    <Flex
      gap={4}
      p='20px'
      borderBottom='4px solid #B5E4CA'
      w='336px'
      maxW='336px'
      bg='white'
      borderRadius='12px'
      align='center'
      justify='space-between'
    >
      <Flex flexDir='column'>
        <CommonLabel
          label={locationName}
          fontWeight={700}
          lineHeight='24px !important'
          color='black'
          noOfLines={1}
        />
        <CommonLabel
          label={`Completed on ${completedOn}`}
          color='#6F767E'
          fontWeight={500}
          fontSize='12px'
          lineHeight='12px !important'
          whiteSpace='nowrap'
        />
      </Flex>

      <Button
        minW='fit-content'
        variant='solid'
        colorScheme='blue'
        fontSize='13px'
        fontWeight='700'
        onClick={onClick}
      >
        Make live
      </Button>
    </Flex>
  );
};

export default ReadyLiveCard;

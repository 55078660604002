import { Box, Flex, Image } from '@chakra-ui/react';
import EmptyIcon from '../../../../assets/images/placeholder/empty-member.svg';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'ui-components';

interface IProps {
  name: string;
  loading: boolean;
  onDeletePress: () => void;
  close: () => void;
}

const EmptyContainer: FC<IProps> = (props) => {
  const { t } = useTranslation(['category']);
  return (
    <Box maxH='calc(100vh - 280px)' overflow='auto'>
      <Flex direction='column' align='center' gap='12px' py='36px'>
        <Image src={EmptyIcon} />
        <Box fontSize='15px' fontWeight='700'>
          {/* {t('category:folderNoChapter')} */}
          There are no chapters in this subfolder
        </Box>
        <ActionButton
          colorScheme='red'
          px='32px'
          actionFn={props.onDeletePress}
          close={props.close}
          isLoading={props.loading}
          disabled={props.loading}
        >
          {/*// @ts-ignore */}
          {t('category:delete_name', {
            value: `“${props.name}”`,
          })}
        </ActionButton>
      </Flex>
    </Box>
  );
};

export default EmptyContainer;

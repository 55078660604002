import { Box, Divider, Flex, useToast, VStack } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import { useMutation } from '@apollo/client';
import TaskDrawerUpload from '../TaskDrawerUpload';
import DateDetails from './DateDetails';
import StepsList from './StepsList';
import TaskDescription from './TaskDescription';
import TaskDurationData from './TaskDurationData';
import { FileWithEid } from 'hooks/useUploadMultipleFilesWithCancel';
import { PrimaryButton } from 'atoms';
import { MARK_LAUNCHER_TASK_UNDONE } from '../task-detail-drawer.graphql';
import {
  IFilesProgressData,
  IRefetchType,
  ISelectedStep,
  MarkTaskUndoneResponse,
  MarkTaskUndoneVariables,
} from '../task-detail-drawer.types';
import useCombinedStore from 'zustandStore/store';
import { eventBus } from 'shared/eventEmit';

interface ViewModeProps {
  onClose: () => void;
  refetchHandler: (type: IRefetchType) => void;
  selectedTaskData: CustomLauncherTask;
}

const ViewMode: FC<ViewModeProps> = ({
  selectedTaskData,
  onClose,
  refetchHandler,
}) => {
  const {
    taskDetails: { updateMarkAsUnfinishedLoading },
  } = useCombinedStore();
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });

  const [filesProgressData, setFilesProgressData] = useState<
    IFilesProgressData[]
  >([]);

  const [markAsUndone] = useMutation<
    MarkTaskUndoneResponse,
    MarkTaskUndoneVariables
  >(MARK_LAUNCHER_TASK_UNDONE);

  useEffect(() => {
    const markAsIncompleteHandler = async () => {
      try {
        updateMarkAsUnfinishedLoading(true);
        let res = await markAsUndone({
          variables: {
            launchId: selectedTaskData?.launchId,
            taskId: selectedTaskData?.eid,
          },
        });
        if (res?.data?.MarkLauncherTaskUndone?.succeed) {
          toast({
            status: 'success',
            title: 'Task marked undone',
          });
          refetchHandler('task');
        }
      } catch (err) {
        refetchHandler('task');
        toast({
          status: 'error',
          title: 'Task could not be marked undone',
        });
      } finally {
        updateMarkAsUnfinishedLoading(false);
        onClose();
      }
    };

    eventBus.on('markAsUnfinished', markAsIncompleteHandler);
    return () => {
      eventBus.off('markAsUnfinished', markAsIncompleteHandler);
    };
  }, [markAsUndone, toast]);

  const markStepAsIncompleteHandler = async (stepId: string) => {
    try {
      let res = await markAsUndone({
        variables: {
          launchId: selectedTaskData?.launchId,
          taskId: selectedTaskData?.eid,
          stepId: stepId,
        },
      });
      if (res?.data?.MarkLauncherTaskUndone?.succeed) {
        toast({
          status: 'success',
          title: 'Step marked undone',
        });
        refetchHandler('step');
      }
    } catch (err) {
      refetchHandler('step');
      toast({
        status: 'error',
        title: 'Step could not be marked undone',
      });
    }
  };

  return (
    <Flex w='full' gap='20px'>
      <Flex flexDir='column' w='60%' gap={4}>
        <TaskDurationData selectedTaskData={selectedTaskData} />
        <TaskDescription selectedTaskData={selectedTaskData} />
        <StepsList
          mode='submit'
          selectedTaskData={selectedTaskData}
          markStepAsCompleteHandler={() => {}}
          markStepAsIncompleteHandler={(stepId: string) =>
            markStepAsIncompleteHandler(stepId)
          }
        />
      </Flex>
      <Flex>
        <Divider orientation='vertical' />
      </Flex>
      <Flex flexDir='column' w='40%' gap={4}>
        <DateDetails selectedTaskData={selectedTaskData} />
        <TaskDrawerUpload
          mode='submit'
          selectedTaskData={selectedTaskData}
          filesProgressData={filesProgressData}
          setFilesProgressData={setFilesProgressData}
          refetchHandler={refetchHandler}
        />
      </Flex>
    </Flex>
    // @ts-ignore
    // <VStack gap='12px'>
    //   <TaskDurationData selectedTaskData={selectedTaskData} />
    //   <TaskDescription selectedTaskData={selectedTaskData} />
    //   <StepsList
    //     selectedTaskData={selectedTaskData}
    //     markStepAsCompleteHandler={() => {}}
    //     markStepAsIncompleteHandler={(stepId: string) =>
    //       markStepAsIncompleteHandler(stepId)
    //     }
    //     mode='view'
    //   />
    //   <DateDetails selectedTaskData={selectedTaskData} />
    //   <TaskDrawerUpload
    //     mode='view'
    //     selectedTaskData={selectedTaskData}
    //     filesProgressData={filesProgressData}
    //     setFilesProgressData={setFilesProgressData}
    //     refetchHandler={refetchHandler}
    //   />
    //   {selectedTaskData?.isCompleted && (
    //     <Flex w='full'>
    //       <Box w='full'>
    //         <PrimaryButton
    //           title='Mark as unfinished?'
    //           variant='outline'
    //           isLoading={loading}
    //           disabled={loading}
    //           onClick={markAsIncompleteHandler}
    //         />
    //       </Box>
    //     </Flex>
    //   )}
    // </VStack>
  );
};

export default ViewMode;

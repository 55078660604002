import * as yup from 'yup';

export const schema = yup.object().shape({
  groupName: yup.string().trim().required('Group name is required'),

  // assignedToUser: yup.array().of(yup.string()).required(),
  assignedToUser: yup.array().when('autoAddMember', {
    is: (val: never) => val === false,
    then: () =>
      yup
        .array()
        .of(yup.string())
        .min(1, 'Please select member')
        .required('Please select member'),
    otherwise: () => yup.array().of(yup.string()),
  }),
  addToChannel: yup.array().when('autoAddMember', {
    is: (val: never) => val === true,
    then: () =>
      yup
        .array()
        .of(yup.string())
        .min(1, 'Please select group')
        .required('Please select group'),
    otherwise: () => yup.array().of(yup.string()),
  }),

  inviteUser: yup.object().when('channelCantLeave', {
    is: (val: never) => !!val === val,
    then: (schema) => {
      return schema.shape({
        type: yup
          .string()
          .oneOf(
            ['authRole', 'anyone', 'creator'],
            'Please select correct option'
          )
          .required('Please select one option'),
        authRoles: yup.string().when('type', {
          is: (val: never) => val === 'authRole',
          then: () =>
            yup
              .array()
              .of(yup.string())
              .min(1, 'Please select role')
              .required('Please select role'),
          otherwise: () => yup.array().of(yup.string()),
        }),
        members: yup.array().of(yup.string()),
      });
    },
    otherwise: () => yup.object(),
  }),
});

import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { CometChat } from '@cometchat-pro/chat';
import { useTranslation } from 'react-i18next';

import { BoxHeader, UseConfirm, useConfirm } from '../../../../ui-components';

import NewChannelForm from './NewChannelForm';
import AddMemberForm, { AddMemberProps } from './AddMemberForm';
import { ChannelConfigForm } from './channel-config';
import FormProvider from './FormProvider';
import { IFormInput } from './create-channel.types';
import AddMemberHeader from './AddMemberHeader';

interface IProps {
  reFetchData?: (...args: any) => void;
  onGroupCreated?: (
    values: IFormInput,
    group: CometChat.Group
  ) => Promise<void> | void;
}

type ICreateChannel = (props?: IProps) => void;

export const useCreateChannel = (): ICreateChannel => {
  const { t } = useTranslation(['setting']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const reFetchRef = useRef<any>();
  const groupCreatedRef = useRef<IProps['onGroupCreated']>();
  const confirm = useConfirm();

  const apolloClient = useApolloClient();

  const addMemberConfig = useCallback((props: AddMemberProps) => {
    return {
      title: <AddMemberHeader />,
      content: <AddMemberForm {...props} />,
    };
  }, []);

  const addChannelConfig = useCallback(() => {
    return {
      content: (
        <ChannelConfigForm
          onGroupCreated={async (values, group) => {
            await groupCreatedRef.current?.(values, group);
            reFetchRef.current?.();
            setTimeout(confirmRef.current!.destroy);
          }}
        />
      ),
    };
  }, []);

  const createChannelConfig = useCallback((_onGroupNameAdded) => {
    return {
      title: (
        <Flex justify='space-between'>
          <BoxHeader
            title={t('setting:createNewChannel')}
            fontSize='18px'
            color='#CABDFF'
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: <NewChannelForm onNextClick={_onGroupNameAdded} />,
    };
  }, []);

  // const onBackPress = useCallback(() => {
  //   if (!confirmRef.current) {
  //     return;
  //   }
  //   confirmRef.current?.update(createChannelConfig(onGroupCreated));
  // }, []);

  const onMemberSelected = useCallback((values: IFormInput) => {
    if (!confirmRef.current) {
      return;
    }
    if (values.isPublic) {
      setTimeout(confirmRef.current.destroy);
    } else {
      confirmRef.current?.update(addChannelConfig());
    }
  }, []);

  const onGroupNameAdded = useCallback((values: IFormInput) => {
    if (!confirmRef.current) {
      return;
    }
    if (values.isPublic) {
      confirmRef.current?.update(addChannelConfig());
    } else {
      confirmRef.current?.update(
        addMemberConfig({
          onNextClick: onMemberSelected,
        })
      );
    }
  }, []);

  return useCallback((props) => {
    reFetchRef.current = props?.reFetchData;
    groupCreatedRef.current = props?.onGroupCreated;
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>
            <FormProvider>{children}</FormProvider>
          </ApolloProvider>
        );
      },
      contentProps: {
        py: '12px',
        maxWidth: '600px',
        marginTop: 'auto',
        marginBottom: 'auto',
        containerProps: {
          // alignItems: 'start',
          paddingTop: '3.75rem',
          paddingBottom: '3.75rem',
        },
      },
      isCentered: true,
      ...createChannelConfig(onGroupNameAdded),
      footer: null,
    });
  }, []);
};

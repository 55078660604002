import { useEffect } from 'react';
import './AddNewPathModal.scss';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ListItem,
  HStack,
  List,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Divider } from 'antd';
import PrimaryButton from '../../atoms/PrimaryButton';
import { userTraining, UpdateTraining } from 'sop-commons/Query/Sop';
import { ReactComponent as GroupIcon } from '../../assets/images/group.svg';
import Loader from '../Loader';

const AddNewPathModal = ({
  isOpen,
  onClose,
  selectedBlockType,
  selectedBlockTitle,
  selectedBlockEid,
}: any) => {
  const { t } = useTranslation(['common', 'training']);
  const [userTrainingData, { loading, data: addNewPathList, refetch }] =
    useLazyQuery(userTraining, { fetchPolicy: 'network-only' });

  useEffect(() => {
    userTrainingData({
      variables: {
        filter: {
          query: null,
        },
        sort: 'TITLE_ASC',
      },
    });
  }, []);

  let trainingList = addNewPathList?.userTrainingNew?.items;

  const [updateTrainingCall, { loading: updateLoader }] = useMutation(
    UpdateTraining,
    {
      onCompleted: (response) => {
        refetch();
      },
    }
  );

  const addItemintoTrainingPath = (sanId, contentObj) => {
    let obj = {
      title: selectedBlockTitle,
      eid: selectedBlockEid,
      type: selectedBlockType,
    };

    const newMutableArray = [...contentObj, obj];

    //setContentArray(newMutableArray);
    let variables: any = {
      input: {
        contents: newMutableArray,
        eid: sanId,
      },
    };
    updateTrainingCall({
      variables: variables,
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        scrollBehavior='inside'
      >
        <ModalOverlay />
        <ModalContent
          width={{
            sm: '340px',
            md: '740px',
            base: '740px',
            xl: '740px',
            ['2xl']: '740px',
          }}
          minWidth={{
            sm: '340px',
            md: '702px',
            base: '702px',
            xl: '702px',
            ['2xl']: '740px',
          }}
        >
          <ModalHeader style={{ marginTop: 20 }}>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  height: 32,
                  width: 16,
                  marginRight: 10,
                  borderRadius: 4,
                  backgroundColor: '#cabdff',
                }}
              />
              <div
                style={{ textTransform: 'capitalize' }}
                className='modal-title'
              >
                {t('training:add_to_training_path', {
                  value: selectedBlockType,
                })}
              </div>
            </div>
          </ModalHeader>
          <ModalCloseButton
            style={{
              marginTop: 30,
              marginRight: 20,
              outline: 'none',
              boxShadow: 'none',
            }}
          />
          <ModalBody>
            <List spacing={3}>
              {loading && (
                <div className='sop-list-loader'>
                  <Loader size='xl' style={undefined} />
                </div>
              )}
              {!loading &&
                trainingList &&
                trainingList.map((item: any, index: number) => {
                  const checkExistValue = item.contents
                    .filter((ls) => ls.eid === selectedBlockEid)
                    .map((value) => value.eid);

                  let isAdded = false;
                  if (checkExistValue.length > 0) {
                    isAdded = true;
                  }

                  return (
                    <ListItem key={index}>
                      <HStack spacing='15px'>
                        <HStack
                          style={{
                            minWidth: '100%',
                            paddingLeft: 6,
                            paddingRight: 6,
                            cursor: 'pointer',
                          }}
                        >
                          <Box>
                            <div
                              className='icon'
                              style={{ backgroundColor: '#b1e5fc' }}
                            >
                              <GroupIcon />
                            </div>
                          </Box>

                          <Flex height='100%' width='100%' gridGap={2}>
                            <Box w='max-content' width='80%'>
                              <ListItem
                                className='san-title'
                                style={{
                                  color: isAdded ? '#1a1d1f' : '#6f767e',
                                  marginLeft: '10px',
                                  maxWidth: '300px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {item?.title
                                  ? item?.title +
                                    ' (' +
                                    item.contents?.length +
                                    ')'
                                  : 'N/A'}
                              </ListItem>
                            </Box>
                            <Box w='max-content' width='20%'>
                              <ListItem>
                                <PrimaryButton
                                  type='submit'
                                  size='lg'
                                  title={isAdded ? 'Added' : 'Add'}
                                  isLoading={updateLoader}
                                  style={{
                                    maxWidth: '187px',
                                    height: '30px',
                                    borderColor: isAdded ? 'blue' : '#ccc',
                                  }}
                                  variant={isAdded ? 'solid' : 'outline'}
                                  colorScheme={isAdded ? 'blue' : 'white'}
                                  onClick={() => {
                                    isAdded
                                      ? null
                                      : addItemintoTrainingPath(
                                          item?.eid,
                                          item.contents.map((cont) => {
                                            return {
                                              title: cont.title,
                                              eid: cont.eid,
                                              type: cont.type,
                                            };
                                          })
                                        );
                                  }}
                                />
                              </ListItem>
                            </Box>
                          </Flex>
                        </HStack>
                      </HStack>
                      <Divider className='divider' />
                    </ListItem>
                  );
                })}
            </List>
          </ModalBody>
          <ModalFooter>
            <Flex justify='flex-end' my='30px' gap='20px'>
              <PrimaryButton
                type='submit'
                size='lg'
                title={t('training:save_close')}
                style={{ maxWidth: '187px' }}
                variant='solid'
                colorScheme='#83bf6e'
                className='addnewpath-button-save-container'
                onClick={() => onClose()}
              />
              <PrimaryButton
                type='button'
                size='lg'
                title={t('common:cancel')}
                onClick={() => onClose()}
                style={{
                  padding: '0px 37px',
                  maxWidth: '187px',
                }}
                variant='outline'
                className='addnewpath-cancel-buttonr'
              />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddNewPathModal;

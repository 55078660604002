import React, { FC, useCallback, useMemo } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { SelectOption } from '../../../../../../atoms';
import { toArray } from '../../../../../../utils/utils';

import { BG, Colors } from '../../select-group/SelectedTags';
import { useJobLocationData } from '../../select-group/useJobLocationData';
import { AuthRole } from '../../../../../../authorization';

interface IProps {
  value?: string[];
  onChange: (value: string[]) => void;
}

const JobTags: FC<IProps> = ({ value, onChange }) => {
  const _options = useJobLocationData().jobs;

  const addToChannel: string[] = [];

  const allData = useMemo(() => {
    return _options.filter((item) => value?.includes(item.value));
  }, [_options, addToChannel]);

  const handleRemove = useCallback(
    (item: SelectOption) => {
      if (onChange) {
        onChange(toArray(value).filter((val) => val !== item.value));
      }
    },
    [value, onChange]
  );

  if (!allData?.length) {
    return null;
  }

  return (
    <Flex gap='10px' wrap='wrap'>
      {allData.map((item) => (
        <Flex
          key={item?.value}
          align='center'
          bg={BG[item.type!]}
          p='3px 10px'
          borderRadius='4px'
          gap='5px'
        >
          <Text
            as='span'
            fontWeight='600'
            pr='3px'
            noOfLines={1}
            fontSize='12px'
            color={Colors[item.type!]}
            textTransform='capitalize'
          >
            {item?.label}
          </Text>
          {item?.value !== AuthRole.SUPER_ADMIN && (
            <Box
              cursor='pointer'
              fontSize='18px'
              fontWeight='500'
              lineHeight='24px'
              onClick={() => handleRemove(item)}
              px='2px'
            >
              &times;
            </Box>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default JobTags;

import React, { FC, useContext, useState } from 'react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import FormDataContext from 'pages/forms/FormStore/form-data-context';
import { DefaultCategoryResponse, getDefaultCategories } from '../form.graphql';
import TemplateSelection from './TemplateSelection';
import { TemplateItem } from './form-template.graphql';
import TemplatePreview from './TemplatePreview';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onTemplateSelect?: (item: TemplateItem) => void;
}

const FormTemplate: FC<IProps> = ({ isOpen, onClose, onTemplateSelect }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateItem>();

  const history = useHistory();
  const formCtx = useContext(FormDataContext);

  const { data, loading: loadingCategory } = useQuery<DefaultCategoryResponse>(
    getDefaultCategories,
    {
      onCompleted: (response) => {
        formCtx.changeTemplateTab(response.FormCategories?.[0]);
      },
    }
  );

  const onTemplateClick = (template: TemplateItem) => {
    if (onTemplateSelect) {
      onTemplateSelect(template);
    } else {
      history.push(`/forms/create/${template.eid}`);
    }
  };

  const onBackPress = () => {
    setSelectedTemplate(undefined);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='6xl' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent p={'8'} borderRadius='21px' maxH='90vh' h='600px' my='auto'>
        <TemplateSelection
          categories={data?.FormCategories}
          loadingCategory={loadingCategory}
          onTemplateClick={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
        />
        <TemplatePreview
          selectedTemplate={selectedTemplate}
          onBackPress={onBackPress}
          onTemplateUse={onTemplateClick}
        />
      </ModalContent>
    </Modal>
  );
};

export default FormTemplate;

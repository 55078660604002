import React from 'react';
import { chakra, forwardRef } from '@chakra-ui/system';
import { FlexProps } from '@chakra-ui/react';

export const Container = forwardRef<FlexProps, 'div'>((props, ref) => {
  const { direction, align, justify, wrap, basis, grow, shrink, ...rest } =
    props;

  const styles = {
    display: 'flex',
    flexDirection: direction,
    alignItems: align,
    justifyContent: justify,
    flexWrap: wrap,
    flexBasis: basis,
    flexGrow: grow,
    flexShrink: shrink,
  };

  return (
    <chakra.div
      ref={ref}
      __css={styles}
      p='10px 14px'
      bg='#FFFFFF'
      borderRadius='12px'
      {...rest}
    />
  );
});

export default Container;

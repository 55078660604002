import React, { FC, useMemo } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';

import PreviewHeader from '../../../forms/component/PreviewHeader';
import {
  AxisPercentageDomain,
  getCompleteRate,
  getGraphLabel,
  getGraphTooltipLabel,
  IGraphRange,
  toPercent,
} from '../common';
import {
  COMPLETION_RATE_QUERY,
  CompletionRateResponse,
  CompletionRateVariable,
} from './perfor-detail.graphql';
import {
  getToolTipConfig,
  GraphFooter,
  GraphLoader,
  GraphMonthWeekSelect,
  useGraphRangeVariable,
} from '../common/graph';

interface IProps {
  userId?: string;
  name?: string;
}

const CompletionRateGraph: FC<IProps> = ({ userId, name }) => {
  const { t } = useTranslation(['common', 'task']);

  const [variables, setVariable] = useGraphRangeVariable();

  const { data, loading } = useQuery<
    CompletionRateResponse,
    CompletionRateVariable
  >(COMPLETION_RATE_QUERY, {
    fetchPolicy: 'network-only',
    skip: !userId,
    variables: {
      userId: userId!,
      ...variables,
    },
  });

  const graphData = useMemo(() => {
    return Object.values(data?.UserTaskCompletionTrend || {}).map((value) => {
      return {
        tooltipLabel: getGraphTooltipLabel(value, variables.type),
        label: getGraphLabel(value, variables.type),
        completeRate: getCompleteRate(value),
      };
    });
  }, [data, variables]);

  const onGraphTypeChange = (newVariables: IGraphRange) => {
    setVariable((prevState) => {
      return {
        ...prevState,
        ...newVariables,
      };
    });
  };

  return (
    <Flex
      flexDir='column'
      borderRadius='10px'
      padding='16px 20px'
      bg='white'
      gap='24px'
    >
      <Flex justify='space-between'>
        <PreviewHeader
          // @ts-ignore
          title={t('task:userTaskCompleteRate', {
            name: name!?.slice(0, 30).concat(name?.length > 30 ? '...' : ''),
          })}
          fontSize='18px'
          color='#B5E4CA'
          noOfLines={1}
        />

        <GraphMonthWeekSelect data={variables} onChange={onGraphTypeChange} />
      </Flex>

      <GraphLoader isLoading={loading} minHeight={260}>
        <Box pr='16px'>
          <ResponsiveContainer width='100%' height={260}>
            <LineChart data={graphData}>
              <CartesianGrid vertical={false} strokeDasharray='3 3' />
              <XAxis axisLine={false} dataKey='label' />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={toPercent}
                domain={AxisPercentageDomain}
              />

              <Line
                type='linear'
                dataKey='completeRate'
                stroke='#FFA043'
                dot={false}
              />

              <Line
                type='linear'
                dataKey='averageRate'
                stroke='#5542F6'
                dot={false}
              />

              <Tooltip
                cursor={false}
                labelFormatter={(label, payload) => {
                  return payload?.[0]?.payload?.tooltipLabel || label;
                }}
                {...getToolTipConfig()}
                formatter={(value) => {
                  return [`${value}% completed`, [] as any];
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </GraphLoader>

      <GraphFooter data={variables} onChange={onGraphTypeChange} />
    </Flex>
  );
};

export default CompletionRateGraph;

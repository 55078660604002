import React, { FC, useEffect, useState } from 'react';
import { Center, Flex, Image, useOutsideClick } from '@chakra-ui/react';
import { faFolder } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import FolderIconTabs from './FolderIconTabs';
import { eventBus } from 'shared/eventEmit/eventBus';

export interface ImageStyle {
  width: string;
  height: string;
  transform: string;
}

const colorArray = ['#CABDFF', '#FFBC99', '#B1E5FC', '#B5E4CA', '#FFD88D'];

interface IProps {
  selectedFolderColor: string;
  setSelectedFolderColor: React.Dispatch<React.SetStateAction<string>>;
  selectedIconUrl: string;
  setSelectedIconUrl: React.Dispatch<React.SetStateAction<string>>;
}

const FolderIconUpload: FC<IProps> = ({
  selectedFolderColor,
  selectedIconUrl,
  setSelectedFolderColor,
  setSelectedIconUrl,
}) => {
  const [showGallery, setShowGallery] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [imageStyles, setImageStyles] = useState<{
    [index: number]: ImageStyle;
  }>({});

  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setSelectedTabHandler = (tabNum: number) => {
      setSelectedTab(tabNum);
    };

    const setSelectedColorHandler = (color: string) => {
      setSelectedFolderColor(color);
    };

    const setSelectedGalleryImageHandler = (value: string) => {
      setSelectedIconUrl(value);
    };

    eventBus.on('onFolderIconUploadClose', onCloseHandler);
    eventBus.on('setSelectedTab', setSelectedTabHandler);
    eventBus.on('setSelectedColor', setSelectedColorHandler);
    eventBus.on('setSelectedGalleryImage', setSelectedGalleryImageHandler);

    return () => {
      eventBus.off('setSelectedTab', setSelectedTabHandler);
      eventBus.off('setSelectedColor', setSelectedColorHandler);
      eventBus.off('setSelectedGalleryImage', setSelectedGalleryImageHandler);
      eventBus.off('onFolderIconUploadClose', onCloseHandler);
    };
  }, []);

  useOutsideClick({
    ref: ref,
    handler: () => onCloseHandler(),
  });

  const onCloseHandler = () => {
    setShowGallery(false);
    setSelectedTab(0);
  };

  return (
    <Flex>
      <Center
        boxSize='44px'
        borderRadius='7px'
        bg={
          selectedFolderColor && selectedIconUrl
            ? selectedFolderColor
            : '#f4f4f4'
        }
        cursor='pointer'
        pos='relative'
        onClick={() => setShowGallery(true)}
      >
        {!selectedIconUrl ? (
          <FontAwesomeIcon
            icon={faFolder as IconProp}
            color='#6F767E'
            fontSize='20px'
          />
        ) : (
          <Image
            src={selectedIconUrl}
            objectFit='contain'
            boxSize='44px'
            p='5px'
          />
        )}
        {showGallery && (
          <Flex pos='absolute' zIndex='modal' top='50px' left='-5px' ref={ref}>
            <FolderIconTabs
              colorArray={colorArray}
              selectedColor={selectedFolderColor}
              selectedGalleryImage={selectedIconUrl}
              selectedTab={selectedTab}
            />
          </Flex>
        )}
      </Center>
    </Flex>
  );
};

export default FolderIconUpload;

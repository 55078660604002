import React, { FC, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SearchInput } from 'atoms';
import { searchRegExp } from 'utils';

import { FormVisibility } from '../Visibility/visibility.types';
import ShareMemberList from './ShareMemberList';
import { SharedProps } from './share-member.types';
import { ShareMemberEntity } from './share-member.graphql';

interface IProps extends SharedProps {
  visibility: FormVisibility;
  members: ShareMemberEntity[];
  loading?: boolean;
}

const ShareToMember: FC<IProps> = ({
  members,
  loading,
  onSendClick,
  onClickHandler,
  formCreatorId,
}) => {
  const { t } = useTranslation(['form', 'team']);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredMembers = useMemo(() => {
    if (!searchQuery) {
      return members;
    }

    const reg = searchRegExp(searchQuery, 'gi');

    return members.filter((value) => {
      return (
        value.name?.match(reg) ||
        value.authRole?.match(reg) ||
        value.role?.match(reg) ||
        value?.locations?.[0]?.name?.match(reg)
      );
    });
  }, [members, searchQuery]);

  return (
    <Flex flex={1} flexDir='column' gap={5} overflow='hidden'>
      <SearchInput
        placeholder={t('form:searchMemberLocJob')}
        hideShortcuts
        value={searchQuery}
        onChange={(e) => setSearchQuery(e?.target?.value)}
      />
      {!!filteredMembers?.length && (
        <Box
          textTransform='uppercase'
          fontSize='12px'
          fontWeight='600'
          color='#6F767E'
        >
          {t('team:members')}
        </Box>
      )}
      <ShareMemberList
        search={searchQuery}
        members={filteredMembers}
        loading={loading}
        onSendClick={onSendClick}
        onClickHandler={onClickHandler}
        formCreatorId={formCreatorId}
        i18NotFoundKey='form:formNotVisibleAnybody'
        i18NotFoundDescKey='form:checkVisibilitySetting'
      />
    </Flex>
  );
};

export default ShareToMember;

import React, { FC, useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import TempExp from '../../assets/images/temp/mobile-experience-test.svg';

// @ts-ignore
import searchVideo from '../../assets/video/search_final.mp4';
// @ts-ignore
import trainingVideo from '../../assets/video/training_final.mp4';
// @ts-ignore
import taskVideo from '../../assets/video/tasks_final.mp4';
// @ts-ignore
import formVideo from '../../assets/video/form_final.mp4';

const ContentData: { [key: string]: typeof trainingVideo } = {
  'Training paths': trainingVideo,
  Search: searchVideo,
  Task: taskVideo,
  Form: formVideo,
};

interface IProps {
  state: string;
}

const ExperienceVideo: FC<IProps> = ({ state }) => {
  const [borderRadius, setBorderRadius] = React.useState<number>();

  useEffect(() => {
    const element = document.getElementById('mobile-experience-video');

    const myObserver = new ResizeObserver((entries) => {
      if (entries[0]?.contentRect) {
        const { width, height } = entries[0]?.contentRect;

        setBorderRadius(
          Math.round(Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 16)
        );
      } else {
        setBorderRadius(36);
      }
    });

    element && myObserver.observe(element);

    return () => {
      element && myObserver.unobserve(element);
    };
  }, []);

  return (
    <Box
      height='75vh'
      maxH='680px'
      style={{ aspectRatio: '9/16' }}
      borderRadius='16px'
    >
      <video
        id='mobile-experience-video'
        src={ContentData[state]}
        style={{ height: '100%', borderRadius: borderRadius }}
        autoPlay
        loop
      />
      {/*<Image*/}
      {/*  width='100%'*/}
      {/*  height='100%'*/}
      {/*  borderRadius='16px'*/}
      {/*  src={TempExp}*/}
      {/*/>*/}
    </Box>
  );
};

export default ExperienceVideo;

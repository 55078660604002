import React from 'react';
import { ISupervisedTasksEntity } from '../../tasks.types';

export interface ISupervisedTaskDetailsDataContext {
  taskDetails: ISupervisedTasksEntity;
  taskSupervisedDetailsHandler: (data: ISupervisedTasksEntity) => void;
}

const SupervisedTaskDetailsDataContext =
  React.createContext<ISupervisedTaskDetailsDataContext>({
    taskDetails: {} as ISupervisedTasksEntity,
    taskSupervisedDetailsHandler: (data: ISupervisedTasksEntity) => {},
  });

export default SupervisedTaskDetailsDataContext as React.Context<ISupervisedTaskDetailsDataContext>;

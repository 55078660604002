import React, { FC, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { Box, Center, Flex, List } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'ui-components/InfiniteScroll';
import useInfiniteScroll from 'ui-components/InfiniteScroll/useInfiniteScroll';
import { ITrainingCategoryEntity } from '../../training-path-types';
import TrainingPathContext, {
  IChapter,
} from '../TrainingPathStore/training-path-context';
import { TrainingHandbookListWrapper } from './TrainingHandbookList.styles';
import { CardModal } from 'modal';
import Loader from 'ui-components/Loader';
import EmptyState from 'sub-components/EmptyState';
import {
  ChapterResponse,
  ChapterVariable,
  GET_CHAPTERS_LIST_FOR_TRAINING,
} from './trainining-handbook.graphql';
import { ChapterModal } from 'modal/chapter-modal';
import { JSONContent, useReadOnlyEditor } from 'delightree-editor';
import { useTrainingPreview } from '../../Preview';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';
import TrainingHandbookListItem from './TrainingHandbookListItem';
import AddChapterButton from './AddChapterButton';
import { useForm } from 'react-hook-form';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';
import Heading from './Heading';
import SearchFilter from './SearchFilter';
import { DEFAULT_VALUES } from './initialData';
import { toArray } from '../../../../../utils/utils';
import { getFoldersList } from 'shared/graphql/SharedGraphql';

const TrainingHandbookList: FC = () => {
  const { t } = useTranslation([
    'common',
    'chapter',
    'training',
    'header',
    'sidebar',
  ]);
  const trainingPathCtx = useContext(TrainingPathContext);
  const readOnlyEditor = useReadOnlyEditor();
  const trainingPreview = useTrainingPreview();
  const methods = useForm<IFormInput>({
    defaultValues: DEFAULT_VALUES,
  });

  const addItemHandler = (item: ChapterModal['data'][number]) => {
    let _chapterItems: CardModal[] = cloneDeep(
      trainingPathCtx?.chapterItemsList?.SopPagination?.items || []
    );
    let filteredItems = _chapterItems?.filter(
      (_item) => _item?.eid !== item?.eid
    );
    let _chapterData: IChapter = JSON.parse(
      JSON.stringify(trainingPathCtx?.chapterItemsList)
    );
    _chapterData!.SopPagination!.items = filteredItems;
    trainingPathCtx?.setChapterItemsListHandler(_chapterData);
    trainingPathCtx?.setAddedItemsHandler([
      ...trainingPathCtx?.addedItems,
      item,
    ]);
    deployEvent(AmplitudeEventNames.TRAINING_ADD_KNOWLEDGE_BASE);
  };

  const onPreviewClick = (item: ChapterModal['data'][number]) => {
    trainingPathCtx?.setSelectedCardHandler(item);
    methods?.setValue('title', item?.title, {
      shouldDirty: false,
    });
    methods?.setValue('content', item?.content?.[0]?.tiptap, {
      shouldDirty: false,
    });
    methods?.setValue('smartPage', item?.smartPageEnabled, {
      shouldDirty: false,
    });
    methods?.setValue('chapterIcon', item?.icon?.native);
    trainingPreview({ trainingContents: toArray(item?.data) });
  };

  const [getChapterData, { loading: chapterDataLoading }] = useLazyQuery<
    ChapterResponse,
    ChapterVariable
  >(GET_CHAPTERS_LIST_FOR_TRAINING, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _chapterData: ChapterResponse = JSON.parse(JSON.stringify(data));
      _chapterData?.SopPagination?.items?.forEach(
        (_item) => (_item.type = 'sop')
      );
      let _trainingPathItems = cloneDeep(
        trainingPathCtx?.chapterItemsList?.SopPagination?.items
      );
      trainingPathCtx?.setChapterItemsListHandler({
        SopPagination: {
          count: _chapterData?.SopPagination?.count,
          items: [
            ...(_trainingPathItems || []),
            ...(new ChapterModal(_chapterData?.SopPagination?.items).data ||
              []),
          ],
          pageInfo: _chapterData?.SopPagination?.pageInfo,
        },
      });
    },
  });

  const { execute: fetchFoldersList, loading: gettingFoldersList } =
    getFoldersList();

  useEffect(() => {
    fetchFoldersList();
  }, []);

  useEffect(() => {
    trainingPathCtx?.setChapterListLoadingHandler(chapterDataLoading);
  }, [chapterDataLoading]);

  const loadMoreHandbookItems = () => {
    let page = JSON.parse(
      JSON.stringify(
        trainingPathCtx?.chapterItemsList?.SopPagination?.pageInfo?.currentPage
      )
    );
    let nextPage = (page || 1) + 1;
    getChapterData({
      variables: {
        page: nextPage,
        filter: {
          status: 'active',
        },
      },
    });
  };

  const [lastElementRef] = useInfiniteScroll(
    trainingPathCtx?.chapterItemsList?.SopPagination?.pageInfo?.hasNextPage
      ? loadMoreHandbookItems
      : () => {},
    chapterDataLoading
  );

  const setCategoryHandler = (id: string) => {
    // let _categories: ITrainingCategoryEntity[] = JSON.parse(
    //   JSON.stringify(trainingPathCtx?.handbookCategoryList)
    // );
    // _categories?.forEach((category, index) => {
    //   if (index !== indexValue) {
    //     category.isSelected = false;
    //   } else {
    //     category.isSelected = !category.isSelected;
    //   }
    // });
    // trainingPathCtx?.setHandbookCategoryListHandler(_categories);
    if (id === trainingPathCtx?.selectedHandbookFilterOption) {
      trainingPathCtx?.setSelectedHandbookFilterOptionHandler?.(undefined);
    } else {
      trainingPathCtx?.setSelectedHandbookFilterOptionHandler?.(id);
    }
  };

  const getReadingTime = (readingContent: JSONContent) => {
    let raw_content = readOnlyEditor({
      content: readingContent,
    }).getText();
    const wordsPerMinute = 200;
    const words = raw_content.trim().split(/\s+/).length;
    const minutes = words / wordsPerMinute;
    return Math.ceil(minutes).toFixed(0) + ' min read';
  };

  const getListDataHandler = () => {
    if (
      (!trainingPathCtx?.chapterItemsList?.SopPagination?.items?.length &&
        trainingPathCtx?.chapterListLoading) ||
      gettingFoldersList
    ) {
      return (
        <Center width={'full'}>
          <Loader />
        </Center>
      );
    }
    if (!trainingPathCtx?.chapterListLoading) {
      if (
        trainingPathCtx?.chapterItemsList?.SopPagination?.items?.length === 0
      ) {
        return (
          <EmptyState
            image='Search'
            title={t('header:result_not_found')}
            description={t('header:not_found_desc')}
          />
        );
      }
    }

    const itemLength =
      trainingPathCtx?.chapterItemsList?.SopPagination?.items?.length;

    return (
      <InfiniteScroll
        hasMore={
          trainingPathCtx?.chapterItemsList?.SopPagination?.pageInfo
            ?.hasNextPage!
        }
        loader={trainingPathCtx?.chapterListLoading}
        loaderType='text'
        loaderText={t('common:loading')}
        loaderTextPosition='center'
        loaderTextColor='#000000'
        loaderTextWeight={600}
        loaderTextSize='16px'
        loaderTextMarginBottom='20px'
      >
        <List spacing={6} w='full' pl='unset'>
          {trainingPathCtx?.chapterItemsList?.SopPagination?.items?.map(
            (item, index) => (
              <TrainingHandbookListItem
                key={index}
                ref={itemLength === index + 1 ? lastElementRef : null}
                item={item}
                getReadingTime={getReadingTime}
                addItemHandler={addItemHandler}
                onPreviewClick={onPreviewClick}
              />
            )
          )}
        </List>
      </InfiniteScroll>
    );
  };

  return (
    <TrainingHandbookListWrapper>
      <Flex gap={4} direction={'column'} w='full' pl={2}>
        <Heading />
        <SearchFilter setCategoryHandler={setCategoryHandler} />
        <Box
          id='training-knowledge-base-parent-box'
          maxH='90vh'
          overflowY='scroll'
          marginTop={'1rem'}
          paddingBottom='48px'
        >
          {getListDataHandler()}
        </Box>
        <AddChapterButton />
      </Flex>
    </TrainingHandbookListWrapper>
  );
};

export default TrainingHandbookList;

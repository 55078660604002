import React, { FC } from 'react';
import { Box, Flex, ModalCloseButton } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import Editor from 'delightree-editor';

import { IconImage } from '../../../../../../../ui-components';

import {
  CONVERSATION_LOGS_FIND,
  LogEditResponse,
} from '../create-logs/create-log.graphql';
import { OccurredDateCell } from '../components';
import LogViewSkeleton from './LogViewSkeleton';

interface IProps {
  noteId: string;
}

const ConversationLogView: FC<IProps> = ({ noteId }) => {
  const { data, loading } = useQuery<LogEditResponse>(CONVERSATION_LOGS_FIND, {
    fetchPolicy: 'network-only',
    variables: {
      eid: noteId,
    },
  });

  const noteData = data?.conversationLogsFindById;

  if (loading || !noteData) {
    return <LogViewSkeleton />;
  }

  return (
    <Flex flexDir='column' minH='calc(100vh - 12rem)' gap='12px'>
      <ModalCloseButton top={8} right={8} />
      <OccurredDateCell
        value={noteData.occurrenceDate}
        dateFormat='DD MMM, hh:mm A'
      />

      <Box fontSize='24px' fontWeight={700} color='#272B30'>
        {noteData.subject}
      </Box>

      <Flex flexDir='column' gap='10px' paddingBlock={3}>
        <Flex align='center' gap={2} fontSize='14px' fontWeight={500}>
          <Box color='#979EA6'>Type of note:</Box>
          <Box color='#1A1D1F'>{noteData.category}</Box>
        </Flex>
        <Flex align='center' gap={2} fontSize='14px' fontWeight={500}>
          <Box color='#979EA6'>Created by/on:</Box>
          <Flex
            align='center'
            color='#1A1D1F'
            gap={2}
            bg='#B1E5FC80'
            padding='4px 10px 4px 6px'
            borderRadius='5px'
          >
            <IconImage
              name={noteData.createdByUser.name}
              thumbnail={noteData.createdByUser.profilePic}
              boxSize={18}
              borderRadius='5px'
            />
            <span>
              {noteData.createdByUser?.name}, {noteData.createdByUser?.role}
            </span>
          </Flex>
          <Box color='#979EA6'>on</Box>
          <Box color='#1A1D1F'>
            {moment(noteData.createdAt).format('DD MMMM')}
          </Box>
        </Flex>
      </Flex>

      <Editor readonly value={noteData.content} />
    </Flex>
  );
};

export default ConversationLogView;

import { ConfirmConfig } from '../../../../../ui-components';

export const modalConfig: Partial<ConfirmConfig> = {
  bodyProps: {
    display: 'flex',
    overflow: 'hidden',
    paddingInline: 0,
  },

  headerProps: {
    paddingInline: 3,
  },

  scrollBehavior: 'inside',

  contentProps: {
    padding: '12px 24px 16px',
    maxWidth: '1100px',
    borderRadius: '16px',
    marginTop: '3rem',
    marginBottom: '3rem',
    maxH: 'calc(100% - 6rem)',
    height: 'full',
    containerProps: {
      px: {
        base: '16px',
        md: '32px',
      },
    },
  },
  footer: null,
};

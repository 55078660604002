import React, { useCallback } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BoxHeader, useConfirm } from '../../../../ui-components';
import { ChatGroupEntity } from '../../../../types';
import { ReactComponent as DeleteChatWarn } from '../../../../assets/images/delete-chat-warn.svg';

interface IProps {
  chatGroup: ChatGroupEntity;
  onDeletePress: (...args: any[]) => any | PromiseLike<any>;
}

type ChatGroupDelete = (props: IProps) => void;

export const useChatGroupDelete = (): ChatGroupDelete => {
  const confirm = useConfirm();
  const { t } = useTranslation(['common']);

  return useCallback(
    ({ onDeletePress, chatGroup }) => {
      confirm({
        title: (
          <Flex justify='space-between' gap={4}>
            <BoxHeader title={`Delete ${chatGroup?.name}?`} color='#CABDFF' />

            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        ),
        content: (
          <Flex
            gap='10px'
            align='center'
            bg='#B1E5FC66'
            borderRadius='10px'
            p='10px 12px'
          >
            <DeleteChatWarn />
            All the chats in this channel will be deleted, including any
            pictures or videos
          </Flex>
        ),
        isCentered: true,
        contentProps: {
          py: '16px',
          px: '24px',
          minW: '700px',
        },
        okButtonProps: {
          colorScheme: 'red',
          size: 'lg',
          px: '40px',
        },
        okText: t('common:delete'),
        onOK: onDeletePress,
        cancelText: null,
      });
    },
    [confirm]
  );
};

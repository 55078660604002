import React, {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Flex, ModalBody, ModalFooter } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { AlertBox, SearchInput } from 'atoms';
import { PageInfo } from 'types';
import { toArray } from 'utils/utils';

import useInfiniteScroll from 'ui-components/InfiniteScroll/useInfiniteScroll';
import InfiniteScroll from 'ui-components/InfiniteScroll';

import { IFormInput } from './invite.types';
import AssignTrainingEmpty from './AssignTrainingEmpty';
import TrainingPathItem from './TrainingPathItem';
import InviteFinishButton from './InviteFinishButton';

export interface InviteTrainingItem {
  eid: string;
  title: string;
  thumbnail: string;
  trainingContentTiny: Array<{
    eid: string;
    title: string;
    type: 'sop' | 'card' | 'deck' | 'milestone';
  }>;
}

interface ITrainingPaths {
  trackTrainingPagination: {
    count: number;
    pageInfo: PageInfo;
    items: InviteTrainingItem[];
  };
}

const GET_TRAINING_ITEMS = gql`
  query TrackTrainingPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
  ) {
    trackTrainingPagination(page: $page, perPage: $perPage, filter: $filter) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        eid
        title
        thumbnail
        trainingContentTiny
      }
    }
  }
`;

interface IProps {
  reInviteUser?: boolean;
  initialTrainings?: any[];
  closeHandler: () => void;
}

const TrainingPaths: FC<IProps> = ({
  initialTrainings,
  reInviteUser,
  closeHandler,
}) => {
  const { t } = useTranslation(['common', 'invite']);
  const searchRef = useRef<string>();

  const [searchQuery, setSearchQuery] = useState<string>();
  const { getValues, setValue } = useFormContext<IFormInput>();

  const [previousTrainings, setPreviousTrainings] = useState(
    initialTrainings || []
  );

  const [trainingPaths, setTrainingPaths] = useState<
    ITrainingPaths['trackTrainingPagination']
  >({
    count: 0,
    items: [],
    pageInfo: {
      currentPage: 1,
      itemCount: 0,
      pageCount: 1,
      perPage: 10,
      hasNextPage: false,
      hasPreviousPage: false,
    },
  });

  const selLocations = useWatch<IFormInput, 'location'>({
    name: 'location',
  });

  const [getTrainingPaths, { loading }] = useLazyQuery<
    ITrainingPaths,
    { page: number; perPage: number; filter: Record<string, any> }
  >(GET_TRAINING_ITEMS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _data: ITrainingPaths['trackTrainingPagination'] = JSON.parse(
        JSON.stringify(data?.trackTrainingPagination)
      );

      if (!searchQuery) {
        _data.items = _data.items.filter((value) => {
          return (
            previousTrainings.findIndex((it) => it.eid === value.eid) === -1
          );
        });
      }

      if (searchQuery === searchRef.current) {
        _data.items = [...trainingPaths?.items, ..._data?.items];
      }
      searchRef.current = searchQuery;
      setTrainingPaths(_data);
    },
  });

  const hasMoreTrainingHandler = () => {
    getTrainingPathsFn(trainingPaths?.pageInfo?.currentPage + 1);
  };

  const loadMoreTrainingPaths = () => {
    hasMoreTrainingHandler();
  };

  const [lastElementRef] = useInfiniteScroll(
    trainingPaths?.pageInfo?.hasNextPage ? loadMoreTrainingPaths : () => {},
    loading
  );

  const getTrainingPathsFn = (page = trainingPaths?.pageInfo?.currentPage) => {
    getTrainingPaths({
      variables: {
        page: page,
        perPage: 20,
        filter: {
          query: searchQuery,
        },
      },
    });
  };

  useEffect(() => {
    getTrainingPathsFn();
  }, []);

  const onChange = (
    item: InviteTrainingItem,
    callback: Function,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const _values = getValues('selectedTrainingPaths') || [];

    if (event.target.checked) {
      callback([..._values, item.eid]);
    } else {
      callback(_values.filter((it) => it !== item.eid));
    }
    if (searchQuery) {
      setPreviousTrainings((prevState) => {
        const index = prevState.findIndex((it) => it.eid === item.eid);
        if (event.target.checked) {
          if (index >= 0) {
            return prevState;
          }
          return [...prevState, item];
        } else {
          prevState.splice(index, 1);
          return [...prevState];
        }
      });
    }
  };

  const locationNames = useMemo(() => {
    return toArray(selLocations).map((it) => it.label);
  }, [selLocations]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    getTrainingPaths({
      variables: {
        page: 1,
        perPage: 20,
        filter: {
          query: event.target.value,
        },
      },
    });
  };

  return (
    <>
      <ModalBody>
        <Flex minH='50vh' flexDir='column' gap={3}>
          <AlertBox>
            <Trans
              t={t}
              i18nKey='invite:inviteTrainingAssignHelp'
              values={{
                name: getValues('name'),
                job: getValues('job.label'),
                location: locationNames,
              }}
            />
          </AlertBox>
          <SearchInput
            placeholder={t('invite:searchTrainingPath')}
            onChange={handleChange}
            hideShortcuts
          />
          <Flex
            flex={1}
            flexDir='column'
            overflow='scroll'
            maxH='60vh'
            gap='10px'
          >
            <Controller
              name='selectedTrainingPaths'
              render={({ field }) => {
                const value = field.value || [];
                return (
                  <AssignTrainingEmpty
                    haveItems={
                      !!trainingPaths?.items?.length ||
                      !!previousTrainings?.length ||
                      loading
                    }
                  >
                    <InfiniteScroll
                      hasMore={!!trainingPaths?.pageInfo?.hasNextPage}
                      loader={loading}
                      loaderType='text'
                      loaderText={t('loading')}
                      loaderTextPosition='center'
                      loaderTextColor='#000000'
                      loaderTextWeight={600}
                      loaderTextSize='16px'
                      loaderTextMarginBottom='20px'
                    >
                      {!searchQuery &&
                        searchQuery === searchRef.current &&
                        previousTrainings?.map((item, index) => (
                          <TrainingPathItem
                            key={`prev-${index}`}
                            ref={
                              trainingPaths?.items?.length === index + 1
                                ? lastElementRef
                                : null
                            }
                            item={item}
                            isChecked={value.includes(item.eid)}
                            onChange={(event) =>
                              onChange(item, field.onChange, event)
                            }
                          />
                        ))}
                      {trainingPaths?.items?.map((item, index) => (
                        <TrainingPathItem
                          key={index}
                          ref={
                            trainingPaths?.items?.length === index + 1
                              ? lastElementRef
                              : null
                          }
                          item={item}
                          isChecked={value.includes(item.eid)}
                          onChange={(event) =>
                            onChange(item, field.onChange, event)
                          }
                        />
                      ))}
                    </InfiniteScroll>
                  </AssignTrainingEmpty>
                );
              }}
            />
          </Flex>
        </Flex>
      </ModalBody>

      <ModalFooter>
        {/*<Flex w='full' justify='flex-end' gap={2}>*/}
        {/*  <PrimaryButton*/}
        {/*    title={t('invite:goBack')}*/}
        {/*    variant='outline'*/}
        {/*    width='fit-content'*/}
        {/*    onClick={() => setValue('currentStep', 'shareTabs')}*/}
        {/*  />*/}
        {/*  <PrimaryButton*/}
        {/*    title={t('common:next')}*/}
        {/*    colorScheme='blue'*/}
        {/*    variant='solid'*/}
        {/*    width='auto'*/}
        {/*    style={{*/}
        {/*      minWidth: '120px',*/}
        {/*    }}*/}
        {/*    onClick={() => setValue('currentStep', 'chatGroup')}*/}
        {/*  />*/}
        {/*</Flex>*/}
        <InviteFinishButton
          reInviteUser={reInviteUser}
          closeHandler={closeHandler}
          onBackClick={() => setValue('currentStep', 'shareTabs')}
        />
      </ModalFooter>
    </>
  );
};

export default TrainingPaths;

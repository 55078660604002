import { useCallback, useEffect } from 'react';

/**
 * `useCloseConfirm` hook is used to confirm with the user before closing or reloading the browser tab or window.
 * @param showExitPrompt
 * */
export default function useCloseConfirm(showExitPrompt?: boolean): void {
  const listener = useCallback(
    (event) => {
      if (showExitPrompt) {
        const e = event || window.event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        return ''; // Legacy method for cross browser support
      }
    },
    [showExitPrompt]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', listener);

    return () => window.removeEventListener('beforeunload', listener);
  }, [listener]);
}

import React, { FC, useContext, useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import { TrainingPlayContext } from './context';
import { useTrainingPreview } from '../CreateTrainingPath/Preview/component/useTrainingPreview';
import { CardType } from '../../../types';

const useContent = () => {
  const { selectedIndex, trainingContents } = useContext(TrainingPlayContext);

  const rawContent = useMemo(() => {
    return trainingContents?.[selectedIndex];
  }, [trainingContents, selectedIndex]);

  const content = useTrainingPreview(rawContent);
  return [content, rawContent];
};

export const PrevButton: FC = () => {
  const { selectedIndex, decrement } = useContext(TrainingPlayContext);

  return (
    <IconButton
      display={selectedIndex === 0 ? 'none' : 'inline-flex'}
      transform='auto'
      borderRadius='full'
      boxShadow='md'
      aria-label={'back'}
      backgroundColor='#B3B3B3'
      icon={<ChevronLeftIcon boxSize={7} color='white' />}
      onClick={decrement}
    />
  );
};

export const NextButton: FC = () => {
  const { selectedIndex, increment, totalCards, loading } =
    useContext(TrainingPlayContext);

  const [content, rawContent] = useContent();

  const isDisabled = useMemo(() => {
    switch (content?.type) {
      case CardType.Quiz:
        return true;
      case CardType.Action:
        return true;
      case CardType.CustomForm:
        return rawContent?.isMandatory ?? true;
      default:
        return false;
    }
  }, [content, rawContent]);

  return (
    <IconButton
      display={selectedIndex + 1 === totalCards ? 'none' : 'inline-flex'}
      transform='auto'
      borderRadius='full'
      boxShadow='md'
      aria-label={'next'}
      backgroundColor='#B3B3B3'
      icon={<ChevronRightIcon boxSize={7} color='white' />}
      onClick={increment}
      isDisabled={isDisabled || loading}
    />
  );
};

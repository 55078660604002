import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { LocationOwnerList } from '../Components';
import { ActionButton } from '../../../ui-components';
import { useFormContext, useWatch } from 'react-hook-form';
import { IFormInput } from './add-location.types';
import { useMutation } from '@apollo/client';
import { UPDATE_LOCATION_OWNERS_FOR_LOCATION } from '../../LocationDetails/components/LocationAssetsCard/components/OwnersMembers/components/LocationOwnerListMenu/location-owner-list-menu.graphql';
import { useHistory } from 'react-router-dom';
import { LAUNCHER_DASHBOARD } from '../../../appRoutes';

interface IProps {}

const AddInviteOwner: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'location']);
  const { control, handleSubmit, getValues, setValue, formState } =
    useFormContext<IFormInput>();
  const history = useHistory();
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const [ownerIds, setOwnerIds] = useState<{ owner1: string; owner2: string }>({
    owner1: '',
    owner2: '',
  });
  const locationOwner1Watch = useWatch<IFormInput>({
    control: control,
    name: 'locationOwnerOne',
  });
  const locationOwner2Watch = useWatch<IFormInput>({
    control: control,
    name: 'locationOwnerTwo',
  });
  const loadingWatch = useWatch<IFormInput>({
    control: control,
    name: 'loading',
  });

  useEffect(() => {
    let _ownerIds: string[] = [];
    if (ownerIds.owner1) {
      _ownerIds.push(ownerIds.owner1);
    }
    if (ownerIds.owner2) {
      _ownerIds.push(ownerIds.owner2);
    }
    setValue('locationOwners', _ownerIds);
  }, [ownerIds]);

  const [addLocationOwners, { loading }] = useMutation(
    UPDATE_LOCATION_OWNERS_FOR_LOCATION,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: 'Finished setting up location',
        });
        history.push(LAUNCHER_DASHBOARD);
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Location setup could not be finished',
        });
      },
    }
  );

  const addLocationOwnersHandler = () => {
    console.log('values : ', getValues());
    // return addLocationOwners({
    //   variables: {
    //     input: {
    //       eid: getValues('createdLocationId'),
    //       locationOwners: getValues('locationOwners'),
    //     },
    //   },
    // });
  };

  console.log('form state : ', formState);

  const disableBtnHandler = () => {
    let flag = false;
    if (loadingWatch) {
      flag = true;
    }
    if (
      (!locationOwner1Watch?.name &&
        !locationOwner1Watch?.job?.value &&
        (!locationOwner1Watch?.phone || !locationOwner1Watch?.email)) ||
      !locationOwner1Watch?.name ||
      !locationOwner1Watch?.job?.value
    ) {
      flag = true;
    }
    if (
      locationOwner1Watch?.name &&
      locationOwner1Watch?.job?.value &&
      (locationOwner1Watch?.phone || locationOwner1Watch?.email)
    ) {
      if (
        (!locationOwner2Watch?.name && locationOwner2Watch?.job?.value) ||
        (locationOwner2Watch?.name && !locationOwner2Watch?.job?.value) ||
        (locationOwner2Watch?.name &&
          locationOwner2Watch?.job?.value &&
          !locationOwner2Watch?.phone &&
          !locationOwner2Watch?.email)
      ) {
        flag = true;
      }
    }
    return flag;
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Box fontSize='32px' fontWeight='600' color='#111315' textAlign='center'>
        Add & invite owners
      </Box>

      <LocationOwnerList
        locationId={getValues('createdLocationId')}
        ownerIds={ownerIds}
        setOwnerIds={setOwnerIds}
      />

      <ActionButton
        size='lg'
        type='submit'
        colorScheme='blue'
        fontSize='15px'
        fontWeight='600'
        disabled={disableBtnHandler()}
        isLoading={loadingWatch}
        actionFn={handleSubmit}
      >
        {t('common:finish')}
      </ActionButton>
    </Flex>
  );
};

export default AddInviteOwner;

import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Title } from 'ui-components';

interface LocationState {
  backToTitle?: string;
}

interface IProps {
  title?: string;
}

const AddLocationHeader: FC<IProps> = ({ title }) => {
  const history = useHistory();
  const { t } = useTranslation('location');
  const locState = useLocation<LocationState>().state;

  return (
    <Flex gap={3}>
      <Center height='48px' onClick={history.goBack} cursor='pointer'>
        <ArrowBackIcon color='#272B30' boxSize='26px' />
      </Center>
      <Box>
        <Title>{title || t('add_location')}</Title>
        <Box
          fontSize='15px'
          fontWeight='400'
          color='#6F767E'
          cursor='pointer'
          onClick={history.goBack}
        >
          {locState?.backToTitle ?? t('backToLocation')}
        </Box>
      </Box>
    </Flex>
  );
};

AddLocationHeader.displayName = 'pages/Locations/AddLocation/AddLocationHeader';

export default AddLocationHeader;

import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InfoFormValues } from '../ProfileInfoForm';
import { usePasswordChange } from '../password-change';

interface IProps {
  userId: string;
}

const UpdatePassword: FC<IProps> = ({ userId }) => {
  const { t } = useTranslation(['common', 'profile', 'invite']);

  const { getValues, watch } = useFormContext<InfoFormValues>();

  const passwordChange = usePasswordChange();

  const isDeleted = watch('isDeleted');
  const userStatus = watch('status');

  const _onUpdatePassword = () => {
    passwordChange({
      userId: userId,
      name: getValues('firstName').concat(' ', getValues('lastName')),
    });
  };

  if (userStatus === 'pending') {
    return null;
  }

  return (
    <Button
      minW='140px'
      mt={6}
      variant='outline'
      fontSize='15px'
      fontWeight='600'
      px={4}
      onClick={isDeleted ? undefined : _onUpdatePassword}
      isDisabled={isDeleted}
    >
      {t('profile:updatePassword')}
    </Button>
  );
};

export default UpdatePassword;

import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { IMakeLocLive } from '../MakeLocLive';
import StatusSwitchText from './StatusSwitchText';
import ToggleSwitch from './ToggleSwitch';

type IStatusSwitch = Pick<IMakeLocLive, 'isLive'> & {
  onSwitchClick?: () => void;
};

const StatusSwitch: FC<IStatusSwitch> = (props) => {
  const { isLive, onSwitchClick } = props;
  return (
    <Flex flexDir='column' align='center' gap={4}>
      <Text fontWeight={600} fontSize='18px' color='#6F767E'>
        CURRENT STATUS
      </Text>
      <StatusSwitchText isLive={isLive} />
      <ToggleSwitch
        value={isLive}
        onClick={isLive ? undefined : onSwitchClick}
      />
    </Flex>
  );
};

export default StatusSwitch;

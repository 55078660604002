import React, { CSSProperties, FC } from 'react';
import {
  Box,
  Center,
  Checkbox,
  Flex,
  GridItem,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { IconImage } from '../../../ui-components';
import { ChapterModal } from '../modal';

import { ActionMenu } from '../../../ui-components/ActionMenu';
import { ListBaseProps } from '../chapert-view.types';
import ChapterIconComponent from '../ChapterIconComponent';
import { ICON_TYPE } from 'delightree-editor';
import { faFolder } from '@fortawesome/pro-regular-svg-icons';

import {
  faEarthAsia,
  faLockKeyhole,
  faUserGroup,
} from '@fortawesome/pro-solid-svg-icons';
import { UserData } from 'sop-commons/src/client';
import { checkBoxCellDisableHandler } from '../ChapterContainer/helper';
import { Authorize, AuthRole } from 'authorization';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface IProps extends Pick<ListBaseProps, 'getMenuData'> {
  data: ChapterModal;
  userObject: UserData;
  checkboxSelectHandler: (
    type: 'single' | 'all',
    selectedValue: boolean,
    selectedId?: string,
    selectedType?: string
  ) => void;
  onClick?: (clickedItem: string, data: ChapterModal) => void;
  selectedItems: {
    type: string;
    eid: string;
  }[];
}

const ChapterGridItem: FC<IProps> = ({
  data,
  selectedItems,
  userObject,
  checkboxSelectHandler,
  onClick,
  getMenuData,
}) => {
  const chapterStatus = () => {
    if (data?.visibility === 'public') {
      return (
        <FontAwesomeIcon
          icon={faEarthAsia as IconProp}
          color='#33383F'
          fontSize='12px'
          style={{
            backgroundColor: '#F0F0F0',
            padding: '5px',
            borderRadius: '50%',
          }}
        />
      );
    } else if (data?.status === 'draft') {
      return (
        <FontAwesomeIcon
          icon={faLockKeyhole as IconProp}
          color='#6F767E'
          fontSize='12px'
          style={{
            backgroundColor: '#F0F0F0',
            padding: '5px 7px',
            borderRadius: '50%',
          }}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faUserGroup as IconProp}
          color='#6F767E'
          fontSize='12px'
          style={{
            backgroundColor: '#F0F0F0',
            padding: '5px',
            borderRadius: '50%',
          }}
        />
      );
    }
  };

  const checkNegation = () => {
    let flag = false;
    let found = selectedItems?.find((item) => item?.eid === data?.eid);
    if (found) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  };

  return (
    <GridItem minW={0} p={1}>
      <Flex
        direction='column'
        cursor='pointer'
        gap={3}
        onClick={() => onClick?.('open', data)}
        sx={{
          '.grid-checkbox': {
            display: selectedItems?.find((item) => item?.eid === data?.eid)
              ? 'flex'
              : 'none',
          },
          '.folder-icon': {
            display: 'flex',
          },
          '.more-action[aria-expanded="true"]': {
            display: 'flex',
          },
        }}
        _hover={{
          '.grid-checkbox': {
            display: 'flex',
          },
          '.more-action': {
            display: 'flex',
          },
          '.folder-icon': {
            display: 'none',
          },
        }}
      >
        <Box
          borderRadius={10}
          // p={3}
          border='0.5px solid #CCCCCC'
          pos='relative'
        >
          <Authorize
            restrictedFor={['branch']}
            restrictedRoles={[AuthRole.LOCATION_OWNER, AuthRole.WORKER]}
          >
            <Box
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (
                  checkBoxCellDisableHandler(userObject, data?.createdBy?.eid)
                ) {
                  return;
                }
                let isSelected = checkNegation();
                if (isSelected) {
                  deployEvent(
                    AmplitudeEventNames.TURN_ON_SINGLE_GRID_VIEW_SELECT
                  );
                } else {
                  deployEvent(
                    AmplitudeEventNames.TURN_OFF_SINGLE_GRID_VIEW_SELECT
                  );
                }
                checkboxSelectHandler(
                  'single',
                  isSelected,
                  data?.eid,
                  data?.type
                );
              }}
            >
              <Checkbox
                className='grid-checkbox'
                colorScheme='green'
                display='none'
                pos='absolute'
                left={3}
                top={4}
                bg='white'
                borderRadius='3px'
                borderColor='rgba(111, 118, 126, 1)'
                readOnly
                isDisabled={checkBoxCellDisableHandler(
                  userObject,
                  data?.createdBy?.eid
                )}
                isChecked={
                  !!selectedItems?.find((item) => item?.eid === data?.eid)
                }
              />
            </Box>
          </Authorize>
          {data?.type === 'folder' && data?.icon && (
            <Center
              className='folder-icon'
              boxSize='30px'
              bg='white'
              borderRadius='5px'
              position='absolute'
              top={3}
              right={3}
            >
              <FontAwesomeIcon icon={faFolder as IconProp} />
            </Center>
          )}
          <ActionMenu
            menuData={getMenuData(data)}
            arrowSize={10}
            closeOnBlur={true}
            offset={[5, 10]}
            clickedItem={(_, clickedItem) => onClick?.(clickedItem, data)}
          >
            <Center
              className='more-action'
              display='none'
              pos='absolute'
              bg='rgba(0, 0, 0, 0.8)'
              borderRadius='5px'
              px={2}
              top={3}
              right={3}
              onClick={(event) => {
                event.stopPropagation();
                deployEvent(
                  AmplitudeEventNames.GRID_VIEW_SINGLE_ITEM_ACTION_MENU_CLICK
                );
              }}
            >
              <FontAwesomeIcon
                icon={faEllipsis as IconProp}
                size='2x'
                color='#FFFFFF'
              />
            </Center>
          </ActionMenu>

          <ChapterIconComponent
            type={
              data?.icon?.id === ICON_TYPE
                ? 'icon'
                : data?.type === 'folder'
                ? 'folder'
                : 'emoji'
            }
            sopType={data?.sopType}
            fileType={data?.fileType}
            border={undefined}
            height='160px'
            padding='.75rem'
            maxWidth='200px'
            fontSize='30px'
            borderRadius='10px'
            minWidth='full'
            folderVisibility={data?.visibility}
            chapterMediaDetails={data?.mediaData?.[0]}
            chapterFileThumbnail={
              data?.type === 'folder'
                ? (data.icon as unknown as string)
                : data?.thumbnail
            }
            folderColor={data.color}
            chapterName={data.title}
            chapterLink={data?.icon?.native}
            imageStyle={{
              margin: 'auto',
              width: '136px',
              height: '100%',
              alignSelf: 'center',
              borderRadius: 10,
              objectFit: 'cover',
            }}
          />
          {/* {data?.icon?.native ? (
            <Center minW='full' height='136px' borderRadius='10px'>
              <Text fontSize='56px'>{data.icon.native}</Text>
            </Center>
          ) : (
            <Image
              src={getImage(undefined, data.title)}
              width={220}
              style={{
                margin: 'auto',
                width: '136px',
                height: '100%',
                alignSelf: 'center',
                borderRadius: 10,
                objectFit: 'cover',
              }}
            />
          )} */}
        </Box>
        <Flex gap={2} px={1}>
          <Box flex={1} overflow='hidden' maxWidth='100%'>
            <Flex align='center' gap='5px'>
              <Tooltip label={data.title} hasArrow borderRadius='4px'>
                <Box
                  color='#1A1D1F'
                  fontWeight='600'
                  style={wrapStyles}
                  maxWidth='fit-content'
                >
                  {data.title}
                </Box>
              </Tooltip>
            </Flex>
            <Flex align='center' gap='5px'>
              {chapterStatus()}
              <Text color='#9A9FA5'>&bull;</Text>
              <Box fontSize='13px' color='#9A9FA5' mt={1} style={wrapStyles}>
                {data.category}
              </Box>
            </Flex>
          </Box>

          <Box minW='24px'>
            <IconImage
              name={data.createdBy?.name || data.title}
              thumbnail={data.createdBy?.profilePic}
              boxSize={28}
              borderRadius='50%'
              objectFit='cover'
            />
          </Box>
        </Flex>
      </Flex>
    </GridItem>
  );
};

export default ChapterGridItem;

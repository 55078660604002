import React, { FC } from 'react';
import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import { MessageIconButton, SearchInput } from 'atoms';
import Loader from 'ui-components/Loader';
import { RoleAndAuthRole } from 'ui-components';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { getImage } from 'utils';
import EmptyState from 'sub-components/EmptyState';
import { INotRelevantUserDetails } from 'pages/TasksDashboard/tasks.types';
import { useHasChatAccess } from 'hooks/useChatRestriction';

interface IProps {
  isDrawerOpen: boolean;
  searchQuery: string;
  taskByIdLoading: boolean;
  getUsersList: () => INotRelevantUserDetails[];
  onDrawerClose: () => void;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  setSelectedUserId: React.Dispatch<React.SetStateAction<string>>;
}

const NotRelevant: FC<IProps> = ({
  isDrawerOpen,
  searchQuery,
  taskByIdLoading,
  getUsersList,
  onDrawerClose,
  setSearchQuery,
  setSelectedUserId,
}) => {
  const hasChatAccess = useHasChatAccess();
  return (
    <Drawer
      size='md'
      isOpen={isDrawerOpen}
      placement='right'
      onClose={onDrawerClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <GenericColorHeader
            title='Task not relevant'
            color={HeaderColors.Purple}
          />
        </DrawerHeader>
        <DrawerBody px={0}>
          {taskByIdLoading ? (
            <Center w='full' h='80vh'>
              <Loader />
            </Center>
          ) : (
            <Flex flexDir='column' gap='10px'>
              <Flex flexDir='column' gap='10px' px={6}>
                <Box bg='#CABDFF66' p={4} borderRadius='10px'>
                  If this task is not relevant then please message task
                  supervisors to remove you from the assignee list.
                </Box>
                <SearchInput
                  placeholder='Search supervisors, creators'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e?.target?.value)}
                  hideShortcuts
                />
              </Flex>
              <List spacing='10px'>
                {getUsersList() && getUsersList()?.length > 0 ? (
                  getUsersList()?.map((user, index) => (
                    <ListItem
                      key={index}
                      px={6}
                      py='10px'
                      _hover={{
                        bg: '#EFEFEFB2',
                      }}
                    >
                      <Flex gap='10px' align='center'>
                        <Image
                          src={getImage(user.profilePic, user.name)}
                          boxSize='40px'
                          borderRadius='full'
                        />
                        <Flex justify='space-between' align='center' w='full'>
                          <Flex flexDir='column'>
                            <Text
                              fontWeight={600}
                              p={0}
                              m={0}
                              isTruncated
                              maxW='300px'
                            >
                              {user.name}
                            </Text>
                            <Flex gap='5px' color='#92929D'>
                              <RoleAndAuthRole
                                role={user.role}
                                authRole={user.locations?.[0]?.name}
                                disableAuthRoleSplit
                                disableToolTip
                                roleProps={{
                                  color: '#92929D',
                                  fontWeight: '500',
                                }}
                                authRoleProps={{
                                  color: '#92929D',
                                  fontWeight: '500',
                                }}
                              />
                            </Flex>
                          </Flex>

                          <MessageIconButton
                            hasChatAccess={hasChatAccess({
                              eid: user?.eid,
                              role: user?.role,
                              authRole: user?.authRole,
                            })}
                            onClick={() => {
                              setSelectedUserId(user?.eid);
                              onDrawerClose();
                            }}
                          />
                        </Flex>
                      </Flex>
                    </ListItem>
                  ))
                ) : (
                  // <Center>
                  //   <Text>No Data Found</Text>
                  // </Center>
                  <EmptyState
                    image={searchQuery ? 'Search' : 'Job'}
                    title={
                      searchQuery
                        ? 'No Search Found'
                        : 'Supervisors or Creators not present'
                    }
                  />
                )}
              </List>
            </Flex>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default NotRelevant;

import React, { FC } from 'react';
import { Box, Center, Flex, ListItem, Progress, Text } from '@chakra-ui/react';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarLines,
  faCheck,
  faRepeat,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { getTimeZone } from '../../../create-task/validation/helper';
import TaskTimeLeft from './TaskTimeLeft';

interface IProps {}

const HiddenTasks: FC<IProps> = () => {
  return (
    <ListItem
      bg='white'
      cursor='pointer'
      borderRadius='10px'
      pos='relative'
      overflow='hidden'
    >
      <Flex align='center' w='full' gap='20px' p='12px 18px'>
        <Flex gap='7px' flex={1} justify='space-between'>
          <Flex gap='10px'>
            <Center
              boxSize='18px'
              bg='rgba(177, 229, 252, 0.2)'
              mt='4px'
              borderRadius='full'
              border='1px solid #2A85FF'
            />

            <Flex flexDirection='column' gap='2px'>
              <Text m={0} isTruncated maxW='600px' color='#272B30'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              </Text>
              <Flex gap='8px' align='center'>
                <Flex gap='5px' align='center' h='fit-content' color='#2A85FF'>
                  <Flex fontSize='12px' align='center' gap='8px'>
                    <FontAwesomeIcon icon={faCalendarLines as IconProp} />
                    <Text m={0}>{moment().format('MMM DD')}</Text>
                    <Text m={0} color='#BFC6CE'>
                      &bull;
                    </Text>
                    <FontAwesomeIcon icon={faRepeat as IconProp} />
                    <Text textTransform='capitalize' m={0}>
                      31st Dec of every year
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex align='center'>
            <TaskTimeLeft
              dueDate={moment().add(10, 'day').toISOString()}
              timeZone={getTimeZone()}
            />
          </Flex>
        </Flex>
        <Flex flexDir='column' gap='2px'>
          <Flex gap='5px' align='center'>
            <Center
              boxSize='16px'
              borderRadius='6px'
              border='1.25px solid #92929D'
            >
              <FontAwesomeIcon
                icon={faCheck as IconProp}
                color='#92929D'
                fontSize='10px'
              />
            </Center>

            <Text color='#92929D' fontSize='14px' m={0}>
              2/5
            </Text>
          </Flex>
          <Progress
            colorScheme='green'
            size='xs'
            value={Math.round((2 / 5) * 100)}
            w='100px'
            borderRadius='5px'
          />
        </Flex>
      </Flex>
      <Box pos='absolute' top='0' boxSize='full' backdropFilter='blur(4px)' />
    </ListItem>
  );
};

export default HiddenTasks;

import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import OverviewItem from './OverviewItem';

interface IProps {
  data: Array<{
    link?: string;
    name: string;
    color: string;
    count: number;
  }>;
}

const Overview: FC<IProps> = ({ data = [] }) => {
  const onClick = (link?: string) => {
    if (link) {
      const element = document.getElementById(link);
      element?.scrollIntoView();
    }
  };

  return (
    <Flex gap={'20px'} align='center'>
      {data.map((value, index) => (
        <OverviewItem
          key={index}
          name={value.name}
          color={value.color}
          count={value.count}
          onClick={() => onClick(value.link)}
        />
      ))}
    </Flex>
  );
};

export default Overview;

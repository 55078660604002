import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import { TrainingData } from 'pages/Training/training.types';
import { ChangeLogTableWrapper } from 'pages/TrainingDraft/ChangeLog/ChangeLogList/ChangeLogTable/ChangeLogTable.styles';
import {
  IChangeEventEntity,
  IChangeLogLocationsEntity,
  IChangeLogTableChangesEntity,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import React, { useEffect, useState } from 'react';
import { IPublishChangeDiffCheckTable } from '../PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import { TFunction } from 'i18next';
import { Tooltip } from '@chakra-ui/react';

import { getImage } from '../../../../utils';

interface ISupervisorsComponent {
  changedData: IPublishChangeDiffCheckTable[];
  trainingData: TrainingData;
  initialTrainingData: TrainingData;
  thirdStepData: IThirdStepData;
  t: TFunction<('common' | 'training')[], undefined, ('common' | 'training')[]>;
}

interface ICombinedChangedTrainingData {
  changes: ICreateChangeLogChangesEntity;
  name: string;
  icon: string;
}

const SupervisorsComponent = ({
  changedData,
  trainingData,
  initialTrainingData,
  thirdStepData,
  t,
}: ISupervisorsComponent) => {
  const [contentDetails, setContentDetails] = useState<
    ICreateChangeLogChangesEntity[]
  >([]);

  useEffect(() => {
    // if (trainingData?.contentDetails && initialTrainingData?.contentDetails) {
    let _contentDetails: ICreateChangeLogChangesEntity[] = [];
    let _supervisorsAdded: IChangeLogLocationsEntity[] = [];
    let _supervisorsRemoved: IChangeLogLocationsEntity[] = [];
    changedData?.map((change) => {
      if (
        change?.tableType === 'supervisorAdded' ||
        change?.tableType === 'supervisorRemoved'
      ) {
        change?.tableData?.changes?.map((_change) => {
          let foundSupervisor = thirdStepData?.supervisors?.find(
            (supervisor) => supervisor?.id === _change?.userId
          );
          if (foundSupervisor) {
            if (change?.tableType === 'supervisorAdded') {
              _supervisorsAdded?.push({
                eid: foundSupervisor?.id,
                name: foundSupervisor?.value,
                profilePic: getImage(
                  foundSupervisor?.url,
                  foundSupervisor?.value
                ),
              });
            } else {
              _supervisorsRemoved?.push({
                eid: foundSupervisor?.id,
                name: foundSupervisor?.value,
                profilePic: getImage(
                  foundSupervisor?.url,
                  foundSupervisor?.value
                ),
              });
            }
          }
        });
        _contentDetails?.push({
          changeElement: 'supervisor',
          changeEvent: change?.tableType,
          supervisors:
            change?.tableType === 'supervisorAdded'
              ? _supervisorsAdded
              : _supervisorsRemoved,
        });
      }
    });
    setContentDetails(_contentDetails);
    // }
  }, [trainingData?.contentDetails, initialTrainingData?.contentDetails]);

  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className='width-30'>{t('training:supervisor_table_heading')}</th>
          <th>{t('common:update')}</th>
        </tr>
      </thead>
    );
  };

  const getChangeNameHandler = (eventType: IChangeEventEntity) => {
    switch (eventType) {
      case 'supervisorAdded':
        return t('training:supervisor_add_publish_change');
      case 'supervisorRemoved':
        return t('training:supervisor_delete_publish_change');
    }
  };

  const getMoreLocationStrings = (supervisors: IChangeLogLocationsEntity[]) => {
    let strArr: string[] = [];
    supervisors?.map((supervisor, index) => {
      if (index > 1) {
        strArr.push(supervisor?.name);
      }
    });
    return strArr.join(', ');
  };

  const getMoreSupervisors = (
    supervisors: IChangeLogLocationsEntity[],
    type: 'add' | 'remove'
  ) => {
    let count = 0;
    supervisors?.map((supervisor, index) => {
      if (index > 1) {
        count++;
      }
    });
    return (
      <Tooltip hasArrow label={getMoreLocationStrings(supervisors)}>
        <div
          className={
            type === 'add'
              ? 'status new-status cursor-pointer'
              : 'status old-status cursor-pointer'
          }
        >
          <span
            className={
              type === 'add'
                ? 'status-text new-status-text'
                : 'status-text old-status-text'
            }
          >
            +{count} more
          </span>
        </div>
      </Tooltip>
    );
  };

  const updatedComparisonsHandler = (
    eventType: IChangeEventEntity,
    supervisors: IChangeLogLocationsEntity[]
  ) => {
    console.log('updatedComparisonsHandler : ', eventType, supervisors);
    switch (eventType) {
      case 'supervisorAdded':
        return (
          <>
            {supervisors?.map((supervisor, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status new-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={supervisor?.profilePic}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text new-status-text'>
                      {supervisor?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {supervisors &&
              supervisors?.length > 2 &&
              getMoreSupervisors(supervisors, 'add')}
          </>
        );
      // return (
      //   <>
      //     <div className='user-status'>
      //       <img
      //         src={icon}
      //         style={{ borderRadius: '5px' }}
      //       />
      //     </div>
      //     <div className='status new-status'>
      //       <span className='status-text new-status-text'>
      //         {name}
      //       </span>
      //     </div>
      //   </>
      // )
      case 'supervisorRemoved':
        return (
          <>
            {supervisors?.map((supervisor, index) => (
              <>
                {index < 2 && (
                  <div
                    className='status old-status margin-right-5'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div className='user-status'>
                      <img
                        src={supervisor?.profilePic}
                        style={{ borderRadius: '5px' }}
                      />
                    </div>
                    <span className='status-text old-status-text'>
                      {supervisor?.name}
                    </span>
                  </div>
                )}
              </>
            ))}
            {supervisors &&
              supervisors?.length > 2 &&
              getMoreSupervisors(supervisors, 'remove')}
          </>
        );
      // return (
      //   <>
      //     <div className='user-status'>
      //       <img
      //         src={icon}
      //         style={{ borderRadius: '5px' }}
      //       />
      //     </div>
      //     <div className='status old-status'>
      //       <span className='status-text old-status-text'>
      //         {name}
      //       </span>
      //     </div>
      //   </>
      // )
    }
  };

  const getTableBody = (contentDetails: ICreateChangeLogChangesEntity[]) => {
    return contentDetails?.map((content, index) => {
      return (
        <tr key={JSON.stringify(content) + index}>
          <td>{getChangeNameHandler(content?.changeEvent)}</td>
          <td>
            <div className='status-cell'>
              {updatedComparisonsHandler(
                content?.changeEvent,
                content?.supervisors || []
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      {contentDetails?.length > 0 ? (
        <ChangeLogTableWrapper>
          <table>
            {getTableHeader()}
            {getTableBody(contentDetails)}
          </table>
        </ChangeLogTableWrapper>
      ) : null}
    </>
  );
};

export default SupervisorsComponent;

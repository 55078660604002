import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Dashboard.css';
import { Menu, Progress, Row, Button, SubMenuProps } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import { useQuery, gql, makeVar, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLink,
  faTrashAlt,
  faChevronDown,
  faPlusCircle,
  faChevronUp,
  faAngleRight,
  faBook,
} from '@fortawesome/free-solid-svg-icons';
import { useRouter } from '../../utils';
import React from 'react';

const { SubMenu } = Menu;

export const roleObj = makeVar({});
export const bundleObj = makeVar({});

type DashboardMenuProps = {
  handleMenuClick?: any;
  menuStyle?: object;
  subMenuIcon?: Node;
  menuMode?: any;
  menuDefaultSelectedKeys?: string[];
  menuDefaultOpenKeys?: string[];
  currentSelectedKeys?: string[];
  menuProgressBarPercent?: number;
  disableMenuItem?: boolean;
  handleSelectCallback?: any;
  getMenuItems?: any;
  sopRequestCategoryName?: any;
  getUserData?: any;
  getUserError?: any;
};
const companyInfoMenu = ['Your Name', 'Your Role'];
const subMenuIcon = <PlusSquareOutlined />;
interface menuItems {
  selectedCategory?: any[];
  selectedRoles?: any[];
  selectedBundles?: any[];
  selectedFilter?: {};
}

// interface UserVars {
//   year: number;
// }

export const DashboardMenu = ({
  handleMenuClick,
  menuStyle,
  menuDefaultSelectedKeys,
  menuDefaultOpenKeys,
  menuMode,
  currentSelectedKeys,
  disableMenuItem,
  handleSelectCallback,
  getMenuItems,
  getUserError,
  getUserData,
}: DashboardMenuProps) => {
  const history = useHistory();
  const router = useRouter();
  const { pathname, search }: any = router?.location;
  const [sopCategory, setSopCategory] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const [roles, setRoles] = useState<any>();
  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [bundles, setBundles] = useState<any>();
  const [selectedBundles, setSelectedBundles] = useState<any>([]);
  const [selectedFilter, setSelectedFilter] = useState<any>();

  useEffect(() => {
    const { user } = getUserData || {};
    const { entity } = user || {};
    const { category, roles, bundles } = entity || {};
    setSopCategory(category);
    setRoles(roles);
    if (roles && roles.length) {
      let roleWithColor: any = {};
      roles.forEach((role: any) => {
        roleWithColor[role.name] = role.color;
      });

      roleObj(roleWithColor);
    }

    setBundles(bundles);
    if (bundles && bundles.length) {
      let bundleWithColor: any = {};
      bundles.forEach((bundle: any) => {
        bundleWithColor[bundle.name] = bundle.color;
      });

      bundleObj(bundleWithColor);
    }

    if (getMenuItems) {
      getMenuItems(category);
    }

    if (getUserError?.message === 'You must be authorized.') {
      history.push('/');
    }
  }, [getUserData]);
  const { query }: any = router;

  useEffect(() => {
    const types: any = Object.keys(query);
    if (types && types.length) {
      const type: any = types[0];
      const name = query[type];
      setSelectedFilter({ name, type });
    }
  }, [query]);

  const getMenuItem = () => {
    return {
      selectedCategory: selectedCategory,
      selectedRoles: selectedRoles,
      selectedBundle: selectedBundles,
      selectedFilter: selectedFilter,
    };
  };
  const handleSelect = (event: any) => {
    const { keyPath, key } = event;
    const keyList = key.split('-');
    const keyValue = keyList[1];
    let menuItems: menuItems = {
      selectedCategory: [...selectedCategory],
      selectedRoles: [...selectedRoles],
      selectedBundles: [...selectedBundles],
      selectedFilter: {},
    };
    if (keyPath[1] === 'category') {
      const tempSelectedCategory = [
        ...selectedCategory,
        sopCategory[parseInt(keyValue)],
      ];
      menuItems['selectedCategory'] = tempSelectedCategory;
      const category = {
        type: 'category',
        ...sopCategory[parseInt(keyValue)],
      };
      menuItems['selectedFilter'] = category;
      setSelectedCategory(tempSelectedCategory);

      setSelectedFilter(category);
    } else if (keyPath[1] === 'roles') {
      const tempSelectedRoles = [...selectedRoles, roles[parseInt(keyValue)]];
      const role = {
        type: 'roles',
        ...roles[parseInt(keyValue)],
      };
      menuItems['selectedRoles'] = tempSelectedRoles;
      menuItems['selectedFilter'] = role;
      setSelectedRoles(tempSelectedRoles);

      setSelectedFilter(role);
    } else if (keyPath[1] === 'bundles') {
      const tempSelectedBundles = [
        ...selectedBundles,
        bundles[parseInt(keyValue)],
      ];
      const bundle = {
        type: 'bundles',
        ...bundles[parseInt(keyValue)],
      };
      menuItems['selectedBundles'] = tempSelectedBundles;
      menuItems['selectedFilter'] = bundle;
      setSelectedBundles(tempSelectedBundles);

      setSelectedFilter(bundle);
    }

    if (handleSelectCallback) {
      handleSelectCallback(menuItems, event);
    }
  };

  const handleDeselect = (event: any) => {
    if (
      pathname === '/dashboard' &&
      event?.selectedKeys?.indexOf(event.key) !== -1
    ) {
      handleSelect(event);
    }
  };

  const bundleVarData: any = useReactiveVar(bundleObj);
  return (
    <Menu
      className='eventclass-handbook-menu'
      expandIcon={(props: SubMenuProps) => {
        if (props.isOpen) {
          return (
            <FontAwesomeIcon
              style={{ height: 14, width: 16, color: '#999999' }}
              icon={faChevronDown}
            />
          );
        }
        return (
          <FontAwesomeIcon
            style={{ height: 14, width: 16, color: '#999999' }}
            icon={faAngleRight}
          />
        );
      }}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onClick={handleMenuClick}
      style={menuStyle}
      defaultSelectedKeys={menuDefaultSelectedKeys}
      defaultOpenKeys={menuDefaultOpenKeys}
      //selectedKeys={currentSelectedKeys}
      mode={menuMode}
    >
      <div
        className='handbook-menu'
        style={{
          backgroundColor:
            pathname === '/dashboard' && search === '' ? '#EEEEEE' : null,
        }}
      >
        <Link
          to='/dashboard'
          onClick={() => {
            setSelectedFilter(null);
          }}
        >
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className='full-handbook-container'
          >
            <FontAwesomeIcon
              style={{
                height: 14,
                width: 16,
                marginBottom: '13px',
                marginRight: '8px',
                marginLeft: '-1px',
                color:
                  pathname === '/dashboard' && search === '' ? '#4d8af0' : null,
              }}
              icon={faBook}
            />
            <p
              className='handbook-text'
              style={{
                color:
                  pathname === '/dashboard' && search === '' ? '#4d8af0' : null,
              }}
            >
              Full Handbook
            </p>
          </div>
        </Link>
      </div>
      <SubMenu
        className='eventclass-sop-category-menu'
        style={{
          fontWeight: 700,
          color: '#AAAAAA',
          fontSize: 12,
        }}
        key='category'
        title='CATEGORY'
      >
        {sopCategory?.map((category: any, index: number) => {
          return (
            <Menu.Item
              className='eventclass-sop-category-item'
              style={{
                paddingLeft: 65,
                color: 'black',
                backgroundColor:
                  selectedFilter?.name === category?.name
                    ? '#EEEEEE'
                    : '#FFFFFF',
              }}
              disabled={disableMenuItem}
              icon={
                <img
                  src={category?.icon}
                  style={{
                    alignContent: 'center',
                    alignSelf: 'center',
                    borderRadius: '50%',
                    height: 13,
                    width: 6,
                  }}
                />
              }
              key={`category-${index}`}
            >
              {disableMenuItem ? (
                <s>{category?.name}</s>
              ) : (
                <span
                  style={{
                    color:
                      selectedFilter?.name === category?.name
                        ? '#4d8af0'
                        : '#333b4f',
                  }}
                >
                  {category?.name}
                </span>
              )}
            </Menu.Item>
          );
        })}
      </SubMenu>
      <SubMenu
        className='eventclass-roles-menu'
        style={{ fontWeight: 700, color: '#AAAAAA', fontSize: 12 }}
        key='roles'
        title='ROLES'
      >
        {roles?.map((role: any, index: number) => (
          <Menu.Item
            className='eventclass-roles-item'
            style={{
              paddingLeft: 65,
              fontSize: 14,
              alignSelf: 'center',
              color: 'black',
              backgroundColor:
                selectedFilter?.name === role?.name ? '#EEEEEE' : '#FFFFFF',
            }}
            disabled={disableMenuItem}
            icon={
              <div
                style={{
                  alignContent: 'center',
                  alignSelf: 'center',
                  borderRadius: '50%',
                  backgroundColor: role?.color,
                  height: 12,
                  width: 6,
                  minWidth: 12,
                }}
              />
            }
            key={`roles-${index}`}
          >
            {disableMenuItem ? (
              <s>
                {role?.name?.charAt[0]?.toUpperCase() + role?.name?.slice(1)}
              </s>
            ) : (
              <span
                style={{
                  color:
                    selectedFilter?.name === role?.name ? '#4d8af0' : '#333b4f',
                }}
              >
                {role?.name?.charAt(0)?.toUpperCase() + role?.name?.slice(1)}
              </span>
            )}
          </Menu.Item>
        ))}
      </SubMenu>
      <SubMenu
        className='eventclass-bundle-menu'
        style={{
          fontWeight: 700,
          color: '#AAAAAA',
          fontSize: 12,
          // marginBottom: 10,
        }}
        key='bundles'
        title='BUNDLES'
      >
        {bundles?.length === 0 && (
          <Menu.Item
            style={{
              paddingLeft: 65,
              position: 'relative',
              bottom: 15,
              marginTop: '15px',
              lineHeight: '20px',
              color: '#777',
              fontSize: 13,
            }}
          >
            Your list of bundles will show <br /> up here.
          </Menu.Item>
        )}

        {!!Object.keys(bundleVarData)?.length &&
          Object.keys(bundleVarData)?.map((bundleName: any, index: number) => {
            const bundle = {
              name: bundleName,
              color: bundleVarData[bundleName],
            };

            return (
              <Menu.Item
                className='eventclass-bundle-item'
                style={{
                  paddingLeft: 65,
                  color: 'black',
                  backgroundColor:
                    selectedFilter?.name?.trim() === bundle?.name?.trim()
                      ? '#EEEEEE'
                      : '#FFFFFF',
                }}
                disabled={disableMenuItem}
                icon={
                  <div
                    style={{
                      alignContent: 'center',
                      alignSelf: 'center',
                      borderRadius: '50%',
                      backgroundColor: bundle?.color,
                      height: 12,
                      width: 6,
                      minWidth: 12,
                    }}
                  />
                }
                key={`bundles-${index}`}
              >
                {disableMenuItem ? (
                  <s>{bundle?.name}</s>
                ) : (
                  <span
                    style={{
                      color:
                        selectedFilter?.name?.trim() === bundle?.name?.trim()
                          ? '#4d8af0'
                          : '#333b4f',
                    }}
                  >
                    {bundle?.name}
                  </span>
                )}
              </Menu.Item>
            );
          })}
      </SubMenu>
    </Menu>
  );
};

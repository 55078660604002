import { Flex, Text, Box, useToast } from '@chakra-ui/react';
import React, { useContext, useRef, useState } from 'react';
import DecksCount from '../../../../../assets/images/decksCount.png';
import FlagIcon from '../../../../../assets/images/flagSvg.svg';
import QuizIcon from '../../../../../assets/images/quizSvg.svg';
import TrainingPathContext from '../TrainingPathStore/training-path-context';
import TableActionMenu from 'ui-components/TableActionMenu';
import { AddIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { deployEvent } from 'shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from 'shared/amplitudeEvents/amplitude-events-types';
import CardEditorModal from 'sub-components/CardEditorModal';
import { cardJsonFormat, useRouteMatch } from 'utils';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { CardType } from 'types';
import MilestoneModal from '../../CreateContainer/AddStructure/CreateTraining/MilestoneModal';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PowerupComponent } from '../../../../../assets/images/powerup-menu/actionMenu.svg';
import { ReactComponent as VideoComponent } from '../../../../../assets/images/powerup-menu/videoMenu.svg';
import { ReactComponent as FormComponent } from '../../../../../assets/images/powerup-menu/formMenu.svg';
import PowerUpModal from 'pages/Training/PowerUpModal';
import {
  IPowerUpModalTypes,
  PowerUpModalRef,
} from '../../../../../pages/Training/PowerUpModal/power-up.types';
import { CardModal, HandbookModal } from 'modal';
import { VisibilityAlert } from 'delightree-editor';
import { AuthRole } from 'sop-commons/src/client';

const TrainingPathCountAction = () => {
  const { t } = useTranslation(['common', 'training']);
  const powerUpModalRef = useRef<PowerUpModalRef>(null);
  const [selectedCardType, setSelectedCardType] =
    useState<IPowerUpModalTypes['types']>('');
  const trainingPathCtx = useContext(TrainingPathContext);
  const userData = useReactiveVar(userObj);
  const [actionCardModalOpened, setActionCardModalOpened] = useState(false);
  const [editCardData, setEditCardData] = useState([]);
  const [isCardModalOpened, setIsCardModalOpened] = useState(false);
  const [milestoneModalOpened, setMilestoneModalOpened] = useState(false);
  const [milestoneType, setMilestoneType] = useState('');
  const [editMilestoneData, setEditMilestoneData] = useState([]);
  const [selectedMilestone, setSelectedMilestone] = useState<any>(null);

  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });

  const clickedItemHandler = (clickedItemName: string) => {
    console.log('CLICKED ITEM', clickedItemName);
    if (clickedItemName === 'add-milestone') {
      setSelectedCardType('milestone');
      setActionCardModalOpened(true);
      trainingPathCtx?.setSelectedPowerupTypeHandler('milestone');
    }
    if (clickedItemName === 'add-action') {
      setSelectedCardType('action');
      setActionCardModalOpened(true);
      trainingPathCtx?.setSelectedPowerupTypeHandler('action');
    }
    if (clickedItemName === 'create-quiz') {
      setSelectedCardType('quiz');
      setIsCardModalOpened(true);
      trainingPathCtx?.setSelectedPowerupTypeHandler('quiz');
    }
    if (clickedItemName === 'add-video') {
      setSelectedCardType('video');
      setActionCardModalOpened(true);
      trainingPathCtx?.setSelectedPowerupTypeHandler('video');
    }
    if (clickedItemName === 'add-form') {
      setSelectedCardType('form');
      setActionCardModalOpened(true);
      trainingPathCtx?.setSelectedPowerupTypeHandler('form');
    }
    // if (clickedItemName === 'add-milestone') {
    //   openPowerUpModal('milestone');
    // } else if (clickedItemName === 'add-action') {
    //   openPowerUpModal('action');
    // }
    // if (clickedItemName === 'add-milestone') {
    //   deployEvent(
    //     AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_ADD_MILESTONE_BUTTON
    //   );
    //   setMilestoneModalOpened(true);
    //   setMilestoneType('actionMenu');
    //   setEditMilestoneData([]);
    //   // let tempArr = JSON.parse(JSON.stringify(dragItemsList));
    //   // console.log({ tempArr });
    //   // tempArr.push({
    //   //     type: 'milestone',
    //   //     title: 'Milestone 1'
    //   // })
    //   // trainingPathCtx?.setAddedItemsHandler({
    //   //     type: 'milestone',
    //   //     title: 'Milestone 1'
    //   // });
    //   // setDragItemsList(tempArr);
    // } else if (clickedItemName === 'create-card') {
    //   deployEvent(
    //     AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_CREATE_CARD_BUTTON
    //   );
    //   setActionCardModalOpened(true);
    // } else if (clickedItemName === 'create-quiz') {
    //   deployEvent(
    //     AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_ADD_QUIZ_CARD_BUTTON
    //   );
    //   setIsCardModalOpened(true);
    // }
  };

  const closeActionCardModal = () => {
    setActionCardModalOpened(false);
  };

  const showVisibilityAlert = (_formData: any) => {
    const canEditForm =
      userData?.authRole === AuthRole.SUPER_ADMIN ||
      _formData?.createdBy?.eid === userData?.eid;

    toast({
      duration: 6000,
      render: (_props) => {
        return (
          <VisibilityAlert
            {..._props}
            title={
              'This form may not be visible to all members, as it’s visibility is set to private'
            }
            isClosable
            onUndo={
              canEditForm
                ? () => window.open(`forms/edit/${_formData.eid}`, '_blank')
                : undefined
            }
          />
        );
      },
    });
  };

  const returnedCardDataHandler = (returnedData: any) => {
    console.log('RETURNED DATA HANDLER', returnedData);
    let extractedReturnedData =
      returnedData?.data?.createCard?.[0] || returnedData;
    console.log('EXTRACTED RETURNED DATA', extractedReturnedData);
    let tempArr = JSON.parse(JSON.stringify(trainingPathCtx?.addedItems));
    console.log('quiz temp arr : ', tempArr);
    let _returnedData = JSON.parse(JSON.stringify(extractedReturnedData));
    _returnedData.type = ['action', 'video', 'form', 'quiz'].includes(
      trainingPathCtx.selectedPowerupType
    )
      ? 'card'
      : 'milestone';
    if (
      _returnedData?.type === 'milestone' &&
      (!_returnedData?.eid || !_returnedData?.tempId || !_returnedData?.tempEid)
    ) {
      _returnedData.tempId = Date.now();
    }

    if (['private', 'restricted'].includes(_returnedData?.form?.visibility)) {
      showVisibilityAlert(_returnedData?.form);
    }

    tempArr.push(_returnedData);
    // TrainingDraftMethods.cardsCardDeckSortAdditionHandler(
    //     setDataUpdation,
    //     dataUpdation,
    //     _returnedData?.data?.createCard?.[0]
    // );
    let translatedReturnedData = new CardModal(_returnedData);
    console.log('TRANSLATED RETURNED DATA : ', translatedReturnedData);
    trainingPathCtx?.setAddedItemsHandler([
      ...trainingPathCtx?.addedItems,
      translatedReturnedData,
    ]);
    trainingPathCtx?.setCountsHandler();
  };

  const closeCardModal = () => {
    setEditCardData([]);
    setIsCardModalOpened(false);
  };

  const onCardSuccess = () => {};

  const onCardEditSuccess = async (data) => {
    let tempArr = JSON.parse(JSON.stringify(trainingPathCtx?.addedItems));
    let promise = tempArr?.map((item) => {
      if (item?.eid === data?.eid) {
        item = { ...data };
        item.type = 'card';
        item.cards = [];
        item.added = true;
      }
      return item;
    });
    let tmpAry = await Promise.all(promise);
    trainingPathCtx?.setAddedItemsHandler([]);
    trainingPathCtx?.setAddedItemsHandler(tmpAry);
    // setDragItemsList(tmpAry);
    // setSavedDataStructure(tmpAry);
  };

  const handleCardEdit = (cardId: string) => {
    setIsCardModalOpened(true);
    let filterdData = trainingPathCtx?.addedItems?.filter(
      (data) => data?.eid === cardId && data?.type === 'card'
    );
    setEditCardData(filterdData);
  };

  const milestoneModalCloseHandler = async (
    type: 'close' | 'save',
    data: any
  ) => {
    //
    if (type === 'close') {
      setMilestoneModalOpened(false);
    } else {
      if (milestoneType === 'actionMenu') {
        let tempEid = Date.now();
        setMilestoneModalOpened(false);
        let tempArr = JSON.parse(JSON.stringify(trainingPathCtx?.addedItems));
        tempArr.push({
          type: 'milestone',
          title: data?.mileStoneName,
          backgroundColor: data?.backgroundColor,
          tempEid: tempEid,
        });
        // TrainingDraftMethods.milestoneAdded();
        console.log('TEMP ARR Action Menu : ', tempArr);
        trainingPathCtx?.setAddedItemsHandler([
          ...trainingPathCtx?.addedItems,
          {
            type: 'milestone',
            title: data?.mileStoneName,
            backgroundColor: data?.backgroundColor,
            tempEid: tempEid,
          },
        ]);
        // setDragItemsList(tempArr);
        // setSavedDataStructure(tempArr);
      } else if (milestoneType === 'editMenu') {
        setMilestoneModalOpened(false);
        let tempArr = JSON.parse(JSON.stringify(trainingPathCtx?.addedItems));
        let promise = tempArr?.map((item) => {
          if (!trainingPathCtx?.isCreate) {
            if (item?.tempEid === data?.tempEid) {
              item.title = data?.mileStoneName;
              item.backgroundColor = data?.backgroundColor;
            }
          } else {
            if (item?.eid === data?.tempEid) {
              item.title = data?.mileStoneName;
              item.backgroundColor = data?.backgroundColor;
            }
          }
          return item;
        });
        await Promise.all(promise);
        console.log('TEMP ARR Action Menu : ', tempArr);
        // TrainingDraftMethods.mileStoneEditedHandler();
        trainingPathCtx?.setAddedItemsHandler(promise);
        // setDragItemsList(tempArr);
        // setSavedDataStructure(tempArr);
      } else {
        let tempEid = Date.now();
        setMilestoneModalOpened(false);
        let tempArr = JSON.parse(JSON.stringify(trainingPathCtx?.addedItems));
        console.log('TEMP ARR Row Menu : ', tempArr);
        let index = tempArr?.findIndex((arr: any) => {
          return arr?.eid === selectedMilestone?.eid;
        });
        tempArr.splice(index + 1, 0, {
          type: 'milestone',
          title: data?.mileStoneName,
          backgroundColor: data?.backgroundColor,
          tempEid: tempEid,
        });
        trainingPathCtx?.setAddedItemsHandler(tempArr);
        // TrainingDraftMethods.milestoneAdded();
        // setDragItemsList(tempArr);
        // setSavedDataStructure(tempArr);
      }
    }
  };

  const openPowerUpModal = (type: IPowerUpModalTypes['types']) => {
    powerUpModalRef.current?.openModal(type);
  };

  const filterChapterCount = () => {
    return trainingPathCtx?.addedItems?.length || 0;
    // return (
    //   trainingPathCtx?.addedItems?.filter((item) => item?.type === 'sop')
    //     ?.length || 0
    // );
  };

  return (
    <>
      <Flex align='center' margin='1.5rem 0' justifyContent={'space-between'}>
        <Box display={'flex'} alignItems='center'>
          <Text
            style={{
              marginRight: '1rem',
              color: '#979EA6',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            {t('training:chaptersAdded')}&nbsp;(
            {filterChapterCount()}):
          </Text>
          <Text
            style={{
              height: '20px',
              width: '21px',
              backgroundColor: '#2A85FF',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '5px',
            }}
          >
            <img
              src={DecksCount}
              style={{
                minWidth: '15px',
                maxWidth: '15px',
                minHeight: '15px',
                maxHeight: '15px',
              }}
              alt='number-of-deck'
            />
          </Text>
          <Text
            style={{
              marginRight: '10px',
              fontSize: '14px',
              fontWeight: '700',
            }}
          >
            {filterChapterCount()}{' '}
            {filterChapterCount() > 1 || filterChapterCount() === 0
              ? t('training:multipleItems')
              : t('training:singleItem')}
          </Text>
          {/* <Text
            style={{
              height: '20px',
              width: '21px',
              backgroundColor: '#2A85FF',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '5px',
            }}
          >
            <img
              src={DecksCount}
              style={{
                minWidth: '15px',
                maxWidth: '15px',
                minHeight: '15px',
                maxHeight: '15px',
              }}
              alt='number-of-deck'
            />
          </Text>
          <Text
            style={{
              marginRight: '10px',
              fontSize: '14px',
              fontWeight: '700',
            }}
          >
            {trainingPathCtx?.decksCount}{' '}
            {trainingPathCtx?.decksCount > 1 ||
            trainingPathCtx?.decksCount === 0
              ? t('common:decks')
              : t('common:deck')}
          </Text>
          <Text
            style={{
              height: '20px',
              width: '20px',
              backgroundColor: '#83BF6E',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '5px',
            }}
          >
            <img
              src={CardsCount}
              style={{
                minWidth: '10px',
                maxWidth: '10px',
                minHeight: '12px',
                maxHeight: '12px',
              }}
              alt='number-of-cards'
            />
          </Text>
          <Text
            style={{
              marginRight: '10px',
              fontSize: '14px',
              fontWeight: '700',
            }}
          >
            {trainingPathCtx?.cardsCount}{' '}
            {trainingPathCtx?.cardsCount > 1 ||
            trainingPathCtx?.cardsCount === 0
              ? t('common:cards')
              : t('common:card')}
          </Text> */}
        </Box>
        <Box>
          <TableActionMenu
            menuData={[
              {
                name: t('training:powerup.actionMenu'),
                value: 'add-action',
                icon: (
                  // <AddIcon w={5} h={5} color='#6F767E' marginRight='14px' />
                  <PowerupComponent
                    fill='#6F767E'
                    color='#6F767E'
                    style={{ marginRight: '14px' }}
                  />
                ),
                normalIcon: 'chakraUIIcon',
                textColor: '#6F767E',
              },
              {
                name: t('training:powerup.quizMenu'),
                value: 'create-quiz',
                icon: QuizIcon,
                normalIcon: 'img',
                textColor: '#6F767E',
              },
              {
                name: t('training:powerup.milestoneMenu'),
                value: 'add-milestone',
                icon: FlagIcon,
                normalIcon: 'img',
                textColor: '#6F767E',
              },
              {
                name: t('training:powerup.videoMenu'),
                value: 'add-video',
                icon: <VideoComponent style={{ marginRight: '14px' }} />,
                normalIcon: 'chakraUIIcon',
                textColor: '#6F767E',
              },
              {
                name: t('training:powerup.formMenu'),
                value: 'add-form',
                icon: <FormComponent style={{ marginRight: '14px' }} />,
                normalIcon: 'chakraUIIcon',
                textColor: '#6F767E',
              },
            ]}
            arrowSize={10}
            closeOnBlur={true}
            offset={[-200, 10]}
            clickedItem={(_, clickedItemName) =>
              clickedItemHandler(clickedItemName)
            }
          >
            <div
              style={{
                display: 'flex',
                // width: '110px',
                border: '1px solid #2a85ff',
                borderRadius: '7px',
                padding: '10px 24px',
                justifyContent: 'space-around',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <span
                style={{
                  color: '#2a85ff',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  paddingRight: 8,
                }}
              >
                {t('training:poweUpInsert')}
              </span>
              <FontAwesomeIcon
                style={{ color: '#2a85ff' }}
                icon={faAngleDown as IconProp}
              />
            </div>
          </TableActionMenu>
        </Box>
      </Flex>
      {actionCardModalOpened && (
        <CardEditorModal
          open={actionCardModalOpened}
          onClose={closeActionCardModal}
          isDeckView={false}
          returnedCardData={returnedCardDataHandler}
          fromTraining
          isHide
          passedCardType={selectedCardType}
        />
      )}
      {editCardData.length === 0 && isCardModalOpened && (
        <CardEditorModal
          open={isCardModalOpened}
          onClose={closeCardModal}
          onSubmit={onCardSuccess}
          isDeckView={false}
          otherDefaultCardType={'quiz'}
          otherDefaultCardInitialData={cardJsonFormat(
            'quiz',
            'single-choice',
            userData?.entity?.eid,
            '#ffffff',
            null,
            null,
            undefined
          )}
          returnedCardData={returnedCardDataHandler}
          isHide={true}
          fromTraining
        />
      )}
      {editCardData.length > 0 && isCardModalOpened && (
        <CardEditorModal
          open={isCardModalOpened}
          onClose={closeCardModal}
          isEdit={true}
          isHide
          onSubmit={onCardEditSuccess}
          cardId={editCardData?.[0]?.eid}
          disabledLayout={[CardType.Quiz]}
          fromTraining
        />
      )}
      {milestoneModalOpened && (
        <MilestoneModal
          isOpen={milestoneModalOpened}
          onClose={milestoneModalCloseHandler}
          editMilestoneData={editMilestoneData}
          isFromEdit={trainingPathCtx?.isCreate}
        />
      )}
      <PowerUpModal ref={powerUpModalRef} />
    </>
  );
};

export default TrainingPathCountAction;

import { createContext, FC, useContext, useRef } from 'react';
import { useStore } from '../../../zustandStore';

import { createLocationStore, LocationAction } from './location.store';

type LocationStore = ReturnType<typeof createLocationStore>;

const LocationContext = createContext<LocationStore | null>(null);

const LocationProvider: FC = ({ children }) => {
  const storeRef = useRef<LocationStore>();
  if (!storeRef.current) {
    storeRef.current = createLocationStore();
  }

  return (
    <LocationContext.Provider value={storeRef.current}>
      {children}
    </LocationContext.Provider>
  );
};

function useLocationContext<T>(selector: (state: LocationAction) => T): T {
  const store = useContext(LocationContext);
  if (!store) throw new Error('Missing LocationContext.Provider in the tree');
  return useStore(store, selector);
}

export { LocationProvider, useLocationContext };

import React, { FC } from 'react';
import { motion } from 'framer-motion';

interface IProps {
  children: React.ReactNode;
}

const AnimateItemContainer: FC<IProps> = ({ children }) => {
  return (
    <motion.div
      layout
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.8, opacity: 0 }}
      transition={{ ease: 'linear', duration: 0.2 }}
    >
      {children as never}
    </motion.div>
  );
};

export default AnimateItemContainer;

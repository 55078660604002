import { gql } from '@apollo/client';
import { HandbookData } from './handbook.types';
import { PageInfo } from '../../types';

export const GET_HANDBOOK_QUERY = gql`
  query HandbookPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyhandbookInput
    $sort: SortFindManyhandbookInput
  ) {
    handbookPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        cardType
        title
        thumbnail
        createdBy
        entityId
        content
        status
        views
        updatedAt
        createdAt
        type
        eid
        translations
        cards {
          cardType
          title
          thumbnail
          category
          createdBy
          entityId
          content
          status
          views
          updatedAt
          eid
          translations
        }
        category
        form {
          title
          eid
          thumbnail
        }
        trainings {
          title
        }
      }
    }
  }
`;

export interface HandbookVariable {
  page: number;
  perPage?: number;
  sort?: string;
  filter?: Record<string, string | boolean | number>;
}

export interface HandbookResponse {
  handbookPagination?: {
    count?: number;
    items?: HandbookData[];
    pageInfo?: PageInfo;
  };
}

export type { HandbookData };

export const RemoveDeckQuery = gql`
  mutation RemoveDeck($eid: String!) {
    removeDeck(eid: $eid) {
      succeed
    }
  }
`;

export interface RemoveDeckResponse {
  removeDeck?: {
    succeed?: boolean;
  };
}

export const UpdateDeckStatus = gql`
  mutation UpdateDeck($input: DecksUpdateInputInput) {
    updateDeck(input: $input) {
      eid
      status
    }
  }
`;

export interface UpdateDeckStatusResponse {
  updateDeck?: {
    eid?: string;
    status?: 'active' | 'inactive' | 'archieved';
  };
}

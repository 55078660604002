import React, { FC } from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Grid,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormInput, PrimaryButton } from 'atoms';
import { EMAIL_REGEX } from 'utils/constants';

import { IFormInput } from '../../AddLocation/add-location.types';
import TitleHeader from '../../../../sub-components/CardEditor/TitleHeader';

interface IProps {}

const EmailListField: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'location']);

  const { control } = useFormContext<IFormInput>();

  const { fields, append, remove } = useFieldArray<IFormInput, 'locationEmail'>(
    {
      name: 'locationEmail',
    }
  );

  return (
    <Box>
      <TitleHeader title={t('common:emailAddress')} isRequired />
      <Grid templateColumns='repeat(3, 1fr)' gap={4} pt={2}>
        {fields?.map((value, index, array) => {
          return (
            <Controller
              key={value.id}
              control={control}
              name={`locationEmail.${index}.email`}
              rules={{
                required: t('common:validation.email_required'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('common:validation.email_invalid'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormInput
                      size='lg'
                      variant='outline'
                      placeholder={t('location:placeholder.email')}
                      rightIcon={
                        array.length > 1 ? (
                          <IconButton
                            size='xs'
                            borderRadius='full'
                            aria-label='remove'
                            icon={<CloseIcon boxSize='10px' />}
                            onClick={() => remove(index)}
                          />
                        ) : undefined
                      }
                      {...field}
                    />
                    <FormErrorMessage>
                      <span>{fieldState.error?.message}</span>
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />
          );
        })}

        {fields?.length < 3 && (
          <PrimaryButton
            width='fit-content'
            size='lg'
            variant='outline'
            borderWidth='2px'
            title={t('location:addAnotherEmail')}
            onClick={() => append({ email: '' })}
          />
        )}
      </Grid>
    </Box>
  );
};

export default EmailListField;

import i18next from 'i18next';

export class BaseModal {
  private _defaultLanguage = 'en';

  get defaultLanguage(): string {
    return this._defaultLanguage;
  }

  set defaultLanguage(value: string) {
    this._defaultLanguage = value;
  }

  get language(): string {
    return i18next.language;
  }

  get t() {
    return i18next.t;
  }
}

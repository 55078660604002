import React, { FC } from 'react';
import { useUploadImage } from '../../../hooks/useUploadImage';
import ThumbnailUploadField from './ThumbnailUploadField';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IProps {
  onChange?: (imageUrl: string) => void;
  value?: string;
  isReadOnly?: boolean;
}

const ThumbnailUpload: FC<IProps> = ({ onChange, value, isReadOnly }) => {
  const { t } = useTranslation('form');
  const toast = useToast({
    duration: 3000,
    position: 'top-right',
  });
  const imageUpload = useUploadImage();

  const imageCallback = async (files: FileList | null) => {
    if (files && files.length) {
      try {
        const imageUrl = await imageUpload(files[0]);
        toast({
          status: 'success',
          title: t('thumbnail_success'),
        });
        onChange?.(imageUrl);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <ThumbnailUploadField
      image={value}
      onFileChange={imageCallback}
      isReadOnly={isReadOnly}
    />
  );
};

export default ThumbnailUpload;

import React, { FC, useMemo } from 'react';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IFormResponseItemsEntity } from '../../forms-types';
import dropdown from '../../../../assets/images/form/Drodpown.svg';
import multiChoice from '../../../../assets/images/form/Multiplechoice.svg';
import checkbox from '../../../../assets/images/form/Checkbox.svg';
import shortAnswer from '../../../../assets/images/form/Short answer.svg';
import multiLine from '../../../../assets/images/form/Paragraph.svg';
import fileUpload from '../../../../assets/images/form/File Upload.svg';
import image from '../../../../assets/images/form/Image.svg';
import record from '../../../../assets/images/form/Video.svg';
import location from '../../../../assets/images/form/Location.svg';
import member from '../../../../assets/images/form/Member.svg';
import signature from '../../../../assets/images/form/signature.svg';

import { faSortDown, faSquareCheck } from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { QuestionType } from 'types';

type QuestionData = IFormResponseItemsEntity['form']['questions'][number];

interface QIProps {
  qType: QuestionType;
}

const QuestionIcon: FC<QIProps> = ({ qType }) => {
  switch (qType) {
    case QuestionType.SHORT_TEXT:
      return <img src={shortAnswer} />;
    case QuestionType.LONG_TEXT:
      return <img src={multiLine} />;
    case QuestionType.CHECKBOX:
      return <img src={checkbox} />;
    case QuestionType.MULTI_CHOICE:
      return <img src={multiChoice} />;
    case QuestionType.FILE_UPLOAD:
      return <img src={fileUpload} />;
    case QuestionType.IMAGE_UPLOAD:
      return <img src={image} />;
    case QuestionType.VIDEO_UPLOAD:
      return <img src={record} />;
    case QuestionType.DROPDOWN:
      return <img src={dropdown} />;
    case QuestionType.MEMBER:
      return <img src={member} />;
    case QuestionType.LOCATION:
      return <img src={location} />;
    case QuestionType.ACKNOWLEDGMENT:
      return <img src={signature} />;

    // @ts-ignore
    case 'singleChoice':
      return <img src={multiChoice} />;
    default:
      return null;
  }
};

interface IProps {
  question: QuestionData;
  questions: QuestionData[];
  setQuestions: React.Dispatch<React.SetStateAction<QuestionData[]>>;
  onQuestionFilterChange: (ids?: string[]) => void;
}

const QuestionCell: FC<IProps> = ({
  question: data,
  questions,
  setQuestions,
  onQuestionFilterChange,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onCellClick = () => {
    if (!isOpen && isValidQuestion) {
      onOpen();
    }
  };

  const getCountHandler = (_questions = questions) => {
    return (
      _questions?.reduce((count, _question) => {
        if (_question?.eid === data?.eid) {
          count +=
            _question?.options?.reduce(
              (optionCount, _option) =>
                _option?.selected ? optionCount + 1 : optionCount,
              0
            ) || 0;
        }
        return count;
      }, 0) || 0
    );
  };

  const questionFilterHandler = (option: any) => {
    let _questions: QuestionData[] = JSON.parse(JSON.stringify(questions));
    let selectedIds: string[] = [];
    _questions?.map((_question) => {
      if (_question?.eid === data?.eid) {
        _question?.options?.map((_option) => {
          if (_option?.eid === option.eid) {
            _option.selected = !_option.selected;
          }
        });
      }
    });
    setQuestions(_questions);
    let count = getCountHandler(_questions);
    if (count === 0) {
      onQuestionFilterChange(selectedIds);
    }
  };

  const isValidQuestion = useMemo(() => {
    if (
      data?.qType !== QuestionType.CHECKBOX &&
      data?.qType !== QuestionType.MULTI_CHOICE &&
      data?.qType !== QuestionType.DROPDOWN
    ) {
      return false;
    }
    return true;
  }, [data?.qType]);

  const getOptionsMenu = () => {
    if (!isValidQuestion) return;
    return (
      <Box pos='relative'>
        <Menu
          isOpen={isOpen}
          onClose={() => {
            onClose();
            onQuestionFilterChange();
          }}
        >
          <MenuButton>
            <FontAwesomeIcon
              icon={faSortDown as IconProp}
              style={{ marginBottom: '2px' }}
              color='#6f767e'
            />
          </MenuButton>
          <Portal>
            <MenuList zIndex={9999} p={0} maxH='300px' overflowY='scroll'>
              {data?.options?.map((option) => (
                <Flex
                  key={option?.eid}
                  align='center'
                  justify='space-between'
                  p={2}
                  cursor='pointer'
                  onClick={() => questionFilterHandler(option)}
                >
                  <Text>{option?.label}</Text>
                  {!option?.selected && (
                    <FontAwesomeIcon
                      icon={faSquare as IconProp}
                      color='#6F767E'
                      size='lg'
                    />
                  )}
                  {option?.selected && (
                    <FontAwesomeIcon
                      icon={faSquareCheck as IconProp}
                      color='#2a85ff'
                      size='lg'
                    />
                  )}
                </Flex>
              ))}
            </MenuList>
          </Portal>
        </Menu>
        {/* <Tooltip
          label={data?.label}
          borderRadius='8px'
          px={3}
          py={2}
          hasArrow
          placement='bottom-end'
        >
          <Box>
            <FontAwesomeIcon icon={faInfoCircle as IconProp} />
          </Box>
        </Tooltip> */}
      </Box>
    );
  };

  const labelRender = () => {
    return (
      <Flex gap='5px'>
        <Text isTruncated maxW={isValidQuestion ? '120px' : '180px'} m={0}>
          {data?.label}
        </Text>
        {isValidQuestion && getCountHandler() > 0 && (
          <Text m={0}>({getCountHandler()})</Text>
        )}
      </Flex>
    );
  };

  return (
    <Flex
      pos='relative'
      align='center'
      cursor='pointer'
      onClick={onCellClick}
      justify={labelRender() ? 'space-between' : 'flex-start'}
      sx={{
        img: {
          marginRight: '10px',
          minHeight: '20px',
          maxHeight: '20px',
          minWidth: '20px',
          maxWidth: '20px',
        },
      }}
    >
      <Flex align='center'>
        <QuestionIcon qType={data.qType} />
        <Tooltip label={data?.label} placement='top' hasArrow>
          {labelRender()}
        </Tooltip>
      </Flex>
      {getOptionsMenu()}
    </Flex>
  );
};

export default QuestionCell;

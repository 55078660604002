import React, { FC } from 'react';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Authorize, AuthRole } from '../../authorization';

import noImage from '../../assets/images/placeholder/empty-deck.svg';

interface IProps {
  onAddCardClick: () => void;
  onAddDeckClick: () => void;
}

const EmptyHandbook: FC<IProps> = ({ onAddCardClick, onAddDeckClick }) => {
  const { t } = useTranslation(['handbook', 'sidebar']);
  const category = useRouteMatch<{ category?: string }>().params?.category;

  return (
    <Flex direction='column' maxW='300px' gap='14px'>
      <Image src={noImage} maxW='300px' mx='auto' />
      <Flex direction='column' textAlign='center' gap='2px'>
        <Box fontSize='15px' fontWeight='700' color='#303030'>
          {t('handbook:category_no_item', {
            category: category || t('sidebar:knowledge_base'),
          })}
        </Box>
        <Authorize
          permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
          permittedFor='user'
        >
          <Box fontSize='11px' fontWeight='500' color='#9E9E9E' px='12px'>
            {t('handbook:add_card_to_category')}
          </Box>
        </Authorize>
      </Flex>
      <Authorize
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        permittedFor='user'
      >
        <Flex gap='10px' justify='center'>
          <Button
            w='max-content'
            px='16px'
            colorScheme='blue'
            maxW='full'
            fontSize='15px'
            onClick={onAddCardClick}
          >
            {t('handbook:add_new_card')}
          </Button>
          <Button
            w='max-content'
            px='16px'
            colorScheme='purple'
            bg='#8E59FF'
            maxW='full'
            fontSize='15px'
            onClick={onAddDeckClick}
          >
            {t('handbook:add_new_deck')}
          </Button>
        </Flex>
      </Authorize>
    </Flex>
  );
};

export default EmptyHandbook;

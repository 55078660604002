import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { Question as QuestionData } from '../create-form/form.types';
import { TextSectionPreview } from '../from-preview';

interface IProps {
  question: QuestionData;
}

const TextSection: FC<IProps> = ({ question }) => {
  if (question.isArchieved) {
    return null;
  }

  return (
    <Box
      my='12px'
      p='16px 24px'
      borderRadius='10px'
      border='1px solid'
      borderColor='#dddddd'
      bg='#f9f9f9'
    >
      <Box
        fontWeight='600'
        fontSize='16px'
        lineHeight='24px'
        letterSpacing='-0.01em'
        color='#33383f'
      >
        {question.label}
      </Box>

      <TextSectionPreview value={question.subTitle} />
    </Box>
  );
};

export default TextSection;

import React, { FC, useMemo } from 'react';
import { Box, BoxProps, Tooltip } from '@chakra-ui/react';

interface IProps {
  role?: string;
  authRole?: string;
  defaultValue?: string;
  roleProps?: BoxProps;
  authRoleProps?: BoxProps;
  disableAuthRoleSplit?: boolean;
  disableToolTip?: boolean;
}

const RoleAndAuthRole: FC<IProps> = ({
  role,
  authRole,
  defaultValue,
  authRoleProps,
  roleProps,
  disableAuthRoleSplit,
  disableToolTip,
}) => {
  const calcAuthRole = useMemo(() => {
    if (disableAuthRoleSplit) {
      return authRole;
    }
    return authRole?.split(/(?=[A-Z])/).join(' ');
  }, [authRole, disableAuthRoleSplit]);

  return (
    <Tooltip
      cursor='pointer'
      hasArrow
      placement='top'
      label={
        disableToolTip
          ? undefined
          : `${role?.trim() || defaultValue} • ${calcAuthRole || defaultValue}`
      }
      isDisabled={disableToolTip}
    >
      <Box
        display='flex'
        alignItems='center'
        mt='0 !important'
        fontSize='12px'
        fontWeight='600'
        color='#9a9fa5'
        textOverflow='ellipsis'
        whiteSpace='nowrap'
        overflow='hidden'
        textTransform='capitalize'
      >
        <Box
          as='span'
          color='#2a85ff'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          overflow='hidden'
          {...roleProps}
        >
          {role?.trim() || defaultValue}
        </Box>
        <span
          style={{
            minWidth: 4,
            width: 4,
            height: 4,
            borderRadius: 4,
            background: '#354b76',
            marginLeft: 6,
            marginRight: 6,
          }}
        />
        <Box
          flex='1'
          as='span'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          overflow='hidden'
          {...authRoleProps}
        >
          {calcAuthRole || defaultValue}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default RoleAndAuthRole;

import { Card } from 'antd';
import React, { FC } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { IChartEntity } from '../FormSummary';
import { useTranslation } from 'react-i18next';

interface ILocationUserChart {
  drop: IChartEntity;
  domain: number[];
  colorsArray: string[];
  index: number;
}

const LocationUserChart: FC<ILocationUserChart> = ({
  drop,
  domain,
  colorsArray,
  index,
}) => {
  const { t } = useTranslation(['common', 'form']);
  const toPercent = (decimal, fixed = 0) => {
    return `${(decimal * 100).toFixed(0)}%`;
  };
  const renderCustomizedLabelName = (props) => {
    const { x, y, width, height, value } = props;
    const newY = y + height / 2;
    return (
      <text x={x + 20} y={newY} fill={'black'} alignmentBaseline='middle'>
        {value}
      </text>
    );
  };

  const renderCustomizedLabelPercentage = (props) => {
    const { x, y, width, height, value } = props;
    const newY = y + height / 2;
    return (
      <text
        x={500 - 20}
        y={newY}
        fill={'black'}
        textAnchor='end'
        alignmentBaseline='middle'
      >
        {(value * 100).toFixed(2)}%
      </text>
    );
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'black', borderRadius: '7px' }}>
          <p
            style={{ color: 'white', padding: '0.5rem 1rem' }}
          >{`${payload?.[0]?.payload?.originalValue}`}</p>
        </div>
      );
    }
    return null;
  };
  return (
    <Card>
      <div>
        <p style={{ fontWeight: '600', fontSize: '18px' }}>
          {drop?.questionName} (Locations, Users)
        </p>
        {drop?.totalResponseCount && (
          <p>
            {t(
              drop?.totalResponseCount > 1 ? 'form:responses' : 'form:response'
            )}
          </p>
        )}
      </div>
      {drop?.questionResponseData?.length > 0 ? (
        <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
          <BarChart
            layout='vertical'
            width={600}
            height={400}
            data={drop?.questionResponseData}
            barCategoryGap={10}
            barGap={10}
          >
            <Tooltip content={<CustomTooltip />} />
            <XAxis
              hide
              type='number'
              tickCount={10}
              tickFormatter={toPercent}
              domain={domain}
            />
            <YAxis hide dataKey='name' type='category' />
            <Bar
              barSize={80}
              dataKey='value'
              background={{ fill: colorsArray[index % 35], opacity: 0.5 }}
              radius={[10, 10, 10, 10]}
            >
              <LabelList
                dataKey='name'
                content={renderCustomizedLabelName}
                style={{ fill: 'black' }}
              />
              <LabelList
                dataKey='value'
                content={renderCustomizedLabelPercentage}
                style={{ fill: 'black' }}
                position='insideRight'
              />
              {drop?.questionResponseData?.map((entry, qIndex) => (
                <Cell
                  key={`cell-${qIndex}`}
                  fill={colorsArray[qIndex % 35]}
                  radius={10}
                />
              ))}
            </Bar>
          </BarChart>
        </div>
      ) : (
        <div>{t('common:no_data_found')}</div>
      )}
    </Card>
  );
};

export default LocationUserChart;

import React, { FC, useMemo } from 'react';
import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import { IconImage, Image } from '../../../ui-components';
import { getImage } from '../../../utils';
import { SelectOption } from './custom-select-component.types';

interface IProps {
  options: SelectOption[];
  value: string[];
  chipsColor?: FlexProps['bg'];
  hideChipsImage?: boolean;
  handleRemove: (value: string, values: string[]) => void;
}

const CustomSelectChips: FC<IProps> = ({
  value = [],
  options = [],
  chipsColor,
  hideChipsImage,
  handleRemove,
}) => {
  const selectedOptions = useMemo(() => {
    return options.filter((op) => value.includes(op.value));
  }, [options, value]);

  if (!value?.length) {
    return null;
  }

  return (
    <Flex align='center' gap='10px' wrap='wrap' mt='1rem'>
      {selectedOptions.map((selectedItem) => {
        return (
          selectedItem && (
            <Flex
              key={selectedItem?.value}
              align='center'
              bg={chipsColor || '#b1e5fc'}
              p='5px 6px'
              borderRadius='5px'
              gap='5px'
            >
              {hideChipsImage ? (
                <Box w='3px' />
              ) : (
                <IconImage
                  name={selectedItem?.label}
                  thumbnail={selectedItem?.profilePic}
                  boxSize={24}
                  borderRadius='5px'
                />
              )}
              <Text as='span' fontWeight='600' pr='3px' noOfLines={1}>
                {selectedItem?.label}
              </Text>
              <Box
                cursor='pointer'
                fontSize='20px'
                fontWeight='500'
                onClick={() => handleRemove(selectedItem?.value, value)}
                px={1}
              >
                &times;
              </Box>
            </Flex>
          )
        );
      })}
    </Flex>
  );
};

export default CustomSelectChips;

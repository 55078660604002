import {
  ContentDetailsEntity,
  TrainingData,
} from 'pages/Training/training.types';
import { ChangeLogTableWrapper } from 'pages/TrainingDraft/ChangeLog/ChangeLogList/ChangeLogTable/ChangeLogTable.styles';
import {
  IChangeElementEntity,
  IChangeEventEntity,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import React, { useEffect, useState } from 'react';
import { IPublishChangeDiffCheckTable } from '../PublishChangeDiffCheckTable/PublishChangeDiffCheckTable';
import flagIcon from '../../../../assets/images/flagIcon.png';
import { TFunction } from 'i18next';

import { getImage } from '../../../../utils';

interface IOrderSequenceComponent {
  changedData: IPublishChangeDiffCheckTable[];
  trainingData: TrainingData;
  initialTrainingData: TrainingData;
  t: TFunction<('common' | 'training')[], undefined, ('common' | 'training')[]>;
}

interface ICombinedChangedTrainingData {
  changes: ICreateChangeLogChangesEntity;
  name: string;
  icon: string;
}

const OrderSequenceComponent = ({
  changedData,
  trainingData,
  initialTrainingData,
  t,
}: IOrderSequenceComponent) => {
  const [contentDetails, setContentDetails] = useState<
    ICombinedChangedTrainingData[]
  >([]);

  const getImageHandler = (detail: ContentDetailsEntity) => {
    if (detail?.type === 'card' || detail?.type === 'deck') {
      return getImage(detail?.thumbnail, detail?.title);
    } else {
      return flagIcon;
    }
  };

  useEffect(() => {
    if (trainingData?.contentDetails && initialTrainingData?.contentDetails) {
      let _contentDetails: ICombinedChangedTrainingData[] = [];
      changedData?.map((change) => {
        if (change?.tableType === 'orderChanged') {
          change?.tableData?.changes?.map((_change) => {
            trainingData?.contentDetails?.map((detail) => {
              if (_change?.contentId === detail?.eid) {
                _contentDetails.push({
                  changes: {
                    changeElement: _change?.changeElement,
                    changeEvent: _change?.changeEvent,
                    contentId: _change?.contentId,
                  },
                  icon: getImageHandler(detail),
                  name: detail?.title,
                });
              }
            });
          });
        }
      });
      setContentDetails(_contentDetails);
    }
  }, [trainingData?.contentDetails, initialTrainingData?.contentDetails]);

  const getTableHeader = () => {
    return (
      <thead>
        <tr>
          <th className='width-30'>
            {t('training:order_sequence_table_heading')}
          </th>
          <th>{t('common:update')}</th>
        </tr>
      </thead>
    );
  };

  const getChangeNameHandler = (
    eventType: IChangeEventEntity,
    elementType: IChangeElementEntity
  ) => {
    switch (eventType) {
      case 'orderChanged':
        if (elementType === 'card') {
          return t('training:card_publish_change');
        } else if (elementType === 'milestone') {
          return t('training:milestone');
        } else if (elementType === 'deck') {
          return t('training:deck_publish_change');
        }
    }
  };

  const updatedComparisonsHandler = (
    eventType: IChangeEventEntity,
    icon: string,
    name: string,
    changeElement: IChangeElementEntity
  ) => {
    switch (eventType) {
      case 'orderChanged': {
        return (
          <>
            {changeElement === 'card' ||
              (changeElement === 'deck' && (
                <div className='user-status'>
                  <img src={icon} style={{ borderRadius: '5px' }} />
                </div>
              ))}
            {changeElement === 'milestone' && (
              <div className='user-status'>
                <img
                  src={flagIcon}
                  style={{
                    borderRadius: '5px',
                    backgroundColor: '#83bf6e',
                    padding: '7px',
                  }}
                />
              </div>
            )}
            <div className='status new-status'>
              <span className='status-text new-status-text'>{name}</span>
            </div>
          </>
        );
      }
    }
  };

  const getTableBody = (contentDetails: ICombinedChangedTrainingData[]) => {
    return contentDetails?.map((content, index) => {
      return (
        <tr key={JSON.stringify(content) + index}>
          <td>
            {getChangeNameHandler(
              content?.changes?.changeEvent,
              content?.changes?.changeElement
            )}
          </td>
          <td>
            <div className='status-cell'>
              {updatedComparisonsHandler(
                content?.changes?.changeEvent,
                content?.icon,
                content?.name,
                content?.changes?.changeElement
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      {contentDetails?.length > 0 ? (
        <ChangeLogTableWrapper>
          <table>
            {getTableHeader()}
            {getTableBody(contentDetails)}
          </table>
        </ChangeLogTableWrapper>
      ) : null}
    </>
  );
};

export default OrderSequenceComponent;

import React, { FC, useCallback, useRef } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import {
  components,
  GroupBase,
  MenuProps,
  OptionProps,
  Props,
  Select,
} from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SelectOption } from 'atoms/Dropdown';
import {
  getChakraSelectStyles,
  SelectStyles,
} from 'atoms/Dropdown/dropdown.styles';

const Option: FC<OptionProps<SelectOption, false>> = (props) => {
  const { isSelected, children } = props;
  return (
    <components.Option {...props} isSelected={false}>
      <Flex align='center'>
        <Text
          flex={1}
          fontSize='15px'
          noOfLines={1}
          pr='8px'
          color='#272b30'
          fontWeight='500'
        >
          {children}
        </Text>
        {isSelected && (
          <FontAwesomeIcon icon={faCheck as IconProp} color='#83bf6e' />
        )}
      </Flex>
    </components.Option>
  );
};

interface IProps
  extends Pick<
    Props<SelectOption, false, GroupBase<SelectOption>>,
    'options' | 'value' | 'onChange' | 'placeholder'
  > {
  onCreatePress?: () => void;
  isDisabled?: boolean;
  selectStyles?: SelectStyles;
}

const CategorySelect: FC<IProps> = ({
  options,
  value,
  onChange,
  isDisabled,
  placeholder,
  onCreatePress,
  selectStyles,
}) => {
  const { t } = useTranslation(['card', 'category']);
  const selectRef = useRef<any>(null);

  const Menu = useCallback(
    (menuProps: MenuProps<SelectOption, false>) => {
      return (
        <components.Menu {...menuProps}>
          <Box px={3} pt={3}>
            <Button
              leftIcon={<AddIcon boxSize='12px' />}
              isFullWidth
              colorScheme='blue'
              onClick={() => {
                selectRef.current?.blur();
                onCreatePress?.();
              }}
              justifyContent='flex-start'
              variant='ghost'
              fontSize='15px'
            >
              {t('card:createNewFolder')}
            </Button>
          </Box>
          {menuProps.children}
        </components.Menu>
      );
    },
    [onCreatePress]
  );

  return (
    <Select<SelectOption, false>
      ref={selectRef}
      options={options}
      value={value}
      onChange={onChange}
      size='lg'
      isDisabled={isDisabled}
      placeholder={placeholder}
      chakraStyles={getChakraSelectStyles<SelectOption, false>({
        ...selectStyles,
        menuList: {
          padding: '4px 12px 12px',
          ...selectStyles?.menu,
        },
        control: {
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '12px',
          fontSize: '15px',
          outline: 'none',
          ...selectStyles?.control,
        },
      })}
      styles={{
        option: (base, optionProps) => ({
          ...base,
          borderBottom: '1px solid #efefef',
          borderRadius: optionProps?.isFocused ? 8 : 0,
          ':last-child': {
            borderBottom: 'none',
          },
        }),
        menu: (base) => ({
          ...base,
          borderRadius: '8px',
        }),
      }}
      components={{
        Option,
        Menu,
      }}
    />
  );
};

export default CategorySelect;

import React, { FC, useEffect, useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Picker, EmojiData } from 'emoji-mart';
import { useTranslation } from 'react-i18next';
import Editor from 'delightree-editor';
import { TextLayout } from 'sop-commons/src/utils/cardLayouts';
import { backgroundColorList } from '../../utils';
import FormInput from '../../atoms/FormInput';
import TitleHeader from './TitleHeader';
import { CardFormInput } from './card-editor.types';
import TextLayoutSelect from './text-card/TextLayoutSelect';
import { editorDataValidator } from './editorDataValidator';

interface Props {
  inputId?: string;
}
const RightIcon: FC<Props> = ({ inputId }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const emojiPicker = useRef<HTMLDivElement>(null);

  const handleSelectEmoji = (selected: EmojiData) => {
    if (inputId && 'native' in selected) {
      const emoji: string = selected.native;
      const input = document.getElementById(inputId) as HTMLInputElement;
      input.focus();

      if (document.execCommand) {
        // document.execCommand is absolute but it //add support for undo redo and insert emoji at carrot position
        //any one has better solution ?

        const event = new Event('input');
        document.execCommand('insertText', false, emoji);
        return;
      }
      // insert emoji on carrot position
      const [start, end] = [input.selectionStart, input.selectionEnd] as [
        number,
        number
      ];
      input.setRangeText(emoji, start, end, 'end');
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (emojiPicker.current && !emojiPicker.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [emojiPicker]);

  return (
    <div>
      <IconButton isRound aria-label='emoji' onClick={onToggle}>
        <FontAwesomeIcon icon={faSmile as IconProp} />
      </IconButton>
      <Box pos='relative' ref={emojiPicker}>
        {isOpen ? (
          <Picker
            useButton={false}
            onSelect={handleSelectEmoji}
            showSkinTones={false}
            emojiTooltip={false}
            showPreview={false}
            sheetSize={32}
            native
            style={{
              position: 'absolute',
              top: 16,
              left: -32,
              borderRadius: 12,
            }}
          />
        ) : null}
      </Box>
    </div>
  );
};

const TextCardEditor: FC = () => {
  const { t } = useTranslation(['common', 'card']);
  const { control, setValue } = useFormContext<CardFormInput>();
  const layout: TextLayout = useWatch<CardFormInput>({
    control: control,
    name: 'layout',
  });

  return (
    <>
      <Controller
        name={'layout'}
        control={control}
        render={({ field }) => {
          return (
            <TextLayoutSelect
              layout={field.value as TextLayout}
              onSelectLayout={field.onChange}
            />
          );
        }}
      />

      <TitleHeader
        title={t('card:title')}
        desc={t('card:title_desc')}
        titleStyle={{ marginTop: '30px' }}
        isRequired
      />

      <Controller
        name={'title'}
        control={control}
        rules={{
          required: t('card:validation.title_required'),
          validate: (value) => {
            if (value?.trim()?.length === 0) {
              return t('validation.enter_title');
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={3} isInvalid={!!fieldState?.error}>
              <FormInput
                id='title'
                size='lg'
                {...field}
                rightIcon={<RightIcon inputId='title' />}
              />
              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      {layout === TextLayout.RICH_TEXT && (
        <>
          <TitleHeader
            title={t('card:description')}
            desc={t('card:category_desc')}
            titleStyle={{ marginTop: '30px' }}
            isRequired
          />

          <Controller
            name={'bodyJson'}
            control={control}
            rules={{
              required: t('card:validation.body_required'),
              validate: (value) => {
                if (editorDataValidator(value)) {
                  return t('card:validation.body_invalid');
                }
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <FormControl isInvalid={!!fieldState.error}>
                  <div className='card-editor-description-container'>
                    <Editor
                      readonly={false}
                      onChange={(jsonData, dataInText) => {
                        field.onChange(jsonData);
                        setValue('raw_content', dataInText);
                      }}
                      isBasicVersion
                      value={field.value}
                    />
                  </div>
                  <FormErrorMessage>
                    <span>{fieldState.error?.message}</span>
                  </FormErrorMessage>
                </FormControl>
              );
            }}
          />
        </>
      )}

      <TitleHeader
        title={t('card:background_color')}
        // desc=' This field will give title to your card.'
        titleStyle={{ marginTop: '30px' }}
      />

      <Controller
        name={'backgroundColor'}
        control={control}
        render={({ field }) => {
          return (
            <Flex mt={4}>
              {backgroundColorList.map((item, index) => {
                let style = {};
                if (item === field.value) {
                  style = {
                    border: 'solid 1.5px #2a85ff',
                  };
                }
                return (
                  <div
                    className='card-editor-background-container'
                    key={index}
                    style={{ backgroundColor: item, ...style }}
                    onClick={() => field.onChange(item)}
                  />
                );
              })}
            </Flex>
          );
        }}
      />
    </>
  );
};

export default TextCardEditor;

import { Flex } from '@chakra-ui/react';
import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import React, { FC } from 'react';
import CommonLabel from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';

interface IProps {
  selectedTaskData: CustomLauncherTask;
}

const TaskDescription: FC<IProps> = ({ selectedTaskData }) => {
  return (
    <Flex flexDir='column' align='flex-start' w='full'>
      <CommonLabel
        label={selectedTaskData?.title}
        fontWeight={600}
        fontSize='18px'
        color='#272B30'
      />
      {selectedTaskData?.description && (
        <CommonLabel
          label={selectedTaskData?.description}
          fontSize='14px'
          color='#6F767E'
        />
      )}
    </Flex>
  );
};

export default TaskDescription;

import { useContext } from 'react';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { AuthRole } from 'authorization';
import { useUserDataSelector } from 'hooks';

/**
 * A custom hook to determine the rendering of components based on specific conditions.
 * It checks if the location launcher process is in progress and whether the user's role is a location owner.
 *
 * @returns {boolean} Returns `false` if the location launcher is in progress and the user's role is `LOCATION_OWNER`,
 * indicating that the component should not be rendered. Otherwise, returns `true`.
 *
 * @example
 * ```
 * const shouldRender = useRenderFlag();
 * if (shouldRender) {
 *   // Render the component
 * }
 * ```
 */
export const useRenderFlag = (): boolean => {
  const dashboardCtx = useContext(DashboardDataContext);
  const isLocationLauncherInProgress = dashboardCtx?.isLauncherInProgress;
  const authRole = useUserDataSelector((state) => state?.authRole);

  return !(
    isLocationLauncherInProgress && authRole === AuthRole.LOCATION_OWNER
  );
};

import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';
import GenerateConditionsText from 'pages/Handbook/setVisibilityModal/SetSeeModal/GenerateConditionsText';
import { HeaderColors } from 'shared/headerColors/header-colors';

interface IProps {
  heading?: string;
}

type VisibilityView = (props: IProps) => void;

export const useVisibilityView = (): VisibilityView => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ heading } = {}) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      size: '2xl',
      title: (
        <Flex gap='12px' mx={8} my={6}>
          <Box flex={1}>
            <BoxHeader
              title={heading || 'Visibility'}
              color={HeaderColors.Purple}
            />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <Flex p='0rem 2rem 2rem 2rem'>
          <GenerateConditionsText backgroundColor='rgba(177, 229, 252, 0.4)' />
        </Flex>
      ),
      headerProps: {
        padding: 0,
      },
      bodyProps: {
        padding: 0,
      },
      contentProps: {
        borderRadius: '16px',
      },
      isCentered: false,
      footer: null,
    });
  }, []);
};

import { gql } from '@apollo/client';
import { TeamItem } from './team.types';
import { AuthRole } from '../../../authorization';

export interface MemberListResponse {
  teams: {
    items: TeamItem[];

    hasMore: boolean;
    count: number;
    pageCount: number;
    currentPage: number;
  };
}

export type MemberSort =
  | '_ID_ASC'
  | '_ID_DESC'
  | 'EMAIL_ASC'
  | 'EMAIL_DESC'
  | 'STATUS_ASC'
  | 'STATUS_DESC'
  | 'ROLE_ASC'
  | 'ROLE_DESC'
  | 'NAME_ASC'
  | 'NAME_DESC'
  | 'createdAt_asc'
  | 'createdAt_desc';

export interface MemberListVariable {
  page: number;
  type: 'all' | 'user' | 'branch';
  query?: string;
  sort?: MemberSort;
  authRoles?: AuthRole[];
}

export const MEMBER_LIST_QUERY = gql`
  query Teams(
    $page: Int!
    $type: teamsType!
    $query: String
    $sort: teamsSort
    $locations: [String!]
    $authRoles: [String!]
  ) {
    teams(
      page: $page
      type: $type
      query: $query
      sort: $sort
      locations: $locations
      authRoles: $authRoles
    ) {
      items {
        name
        eid
        type
        role
        authRole
        email
        phone
        createdAt
        status
        inviteDetails
        isInvited
        profilePic
        locations {
          name
          eid
          profilePic
        }
      }
      hasMore
      count
      pageCount
      currentPage
    }
  }
`;

export const initialData = {
  userAnalyticsOverview: {
    locationCount: 0,
    userCount: 0,
    tasks: {
      complete: 0,
      incomplete: 0,
      rate: 0,
    },
    trainings: {
      complete: 0,
      incomplete: 0,
    },
    formsFilled: 0,
    taskCompanyAvg: {
      complete: 0,
      incomplete: 0,
      rate: 0,
    },
    bestLocationTaskPerformance: {
      complete: 0,
      inComplete: 0,
      rate: 0,
      locationId: '',
    },
  },
};

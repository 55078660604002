import isEmpty from 'lodash/isEmpty';
import { JSONContent, useReadOnlyEditor } from 'delightree-editor';

import { CardType } from 'types';
import { toArray } from 'utils/utils';

import { SmartCardItem } from '../ChapterEditorContainer/chapter-editor.graphql';
import { smartCardContent } from './smartCardContent';

const getCardType = (data: JSONContent[]) => {
  switch (data[0]?.type) {
    case 'video':
      return CardType.Video;
    case 'image':
      return CardType.Image;
    default:
      return CardType.Text;
  }
};

const filterEmpty = (content: JSONContent[]): boolean => {
  return toArray(content).some((value) => {
    if (
      ['customHorizontalRule', 'horizontalRule'].includes(value.type as string)
    ) {
      return false;
    }
    return !isEmpty(value.content || value.text || value.attrs || value.marks);
  });
};

export function filterData(cardData: JSONContent[][]): JSONContent[][] {
  return toArray(cardData).filter(filterEmpty);

  // return toArray(cardData).filter((val) => {
  //   const newVal = toArray(val);
  //   if (isEmpty(newVal)) {
  //     return false;
  //   } else if (
  //     ['customHorizontalRule', 'horizontalRule'].includes(
  //       newVal[0]?.type as string
  //     ) &&
  //     newVal.length === 1
  //   ) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // });
}

const smartCardGenerate = (
  cardData: JSONContent[][],
  readOnlyEditor?: ReturnType<typeof useReadOnlyEditor>,
  hasHorizontalCheck?: boolean
): SmartCardItem[] => {
  const editor = readOnlyEditor?.({} as any);
  return filterData(cardData).map((data) => {
    const cardType = getCardType(data);

    editor?.commands?.setContent({
      type: 'doc',
      content: data,
    });

    const cc = smartCardContent(cardType, data, editor?.getText());
    const smartCardItem: SmartCardItem = {
      cardType: cardType,
      content: cc.content,
      thumbnail: cc.thumbnail,
      raw_content: cc.raw_content,
      layout: cc.layout,
    };

    if (hasHorizontalCheck) {
      const type = toArray(data)[0]?.type;
      smartCardItem.hasHorizontal =
        type === 'customHorizontalRule' || type === 'horizontalRule';
    }

    return smartCardItem;
  });
};

export { smartCardGenerate };

import { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import TrainingCard from 'sub-components/TrainingCard';
import { getCardById } from 'sop-commons/Query/Search';
import CloseIcon from '../../assets/images/closeIcon.png';
import './CardDeckPreviewModal.scss';
import Loader from 'sub-components/Loader';
import CardDeckInfoTooltip from 'sub-components/CardDeckInfoTooltip';
import { CardDetailModal } from '../../modal';
import { CardPreviewModal } from './cardPreview.modal';

interface GenericModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCard: any;
}

const CardDeckPreviewModal: FC<GenericModalProps> = ({
  isOpen,
  onClose,
  selectedCard,
}) => {
  const { t } = useTranslation('common');
  const { loading, data: response } = useQuery(getCardById, {
    variables: {
      eid: selectedCard.recordId ? selectedCard.recordId : selectedCard.eid,
    },
  });

  const data = useMemo(() => {
    if (response?.findCardById) {
      return new CardPreviewModal(response?.findCardById);
    }
    return {} as any;
  }, [response]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        // containerProps={{
        //   justifyContent: 'flex-end',
        // }}
        className='card-deck-preview-modal-content-container'
      >
        <ModalBody>
          <div className='card-deck-preview-modal'>
            <div className='card-deck-heading-container'>
              <div className='card-deck-heading-close-icon-container'>
                {data?.eid && (
                  <div className='card-deck-heading-info-incon'>
                    <CardDeckInfoTooltip details={data} placement='bottom' />
                  </div>
                )}
                <div>
                  <img
                    src={CloseIcon}
                    alt='close-icon'
                    className='card-deck-modal-close-icon'
                    onClick={onClose}
                  />
                </div>
              </div>
              <div className='card-deck-sub-heading-container'>
                <span className='card-deck-heading-color-div' />
                <span className='card-deck-heading-text'>{t('preview')}</span>
              </div>
            </div>
            <div className='card-deck-content-container'>
              {loading && (
                <div className='sop-list-loader'>
                  <Loader size='xl' style={undefined} />
                </div>
              )}
              <TrainingCard content={data} isPreview={false} />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CardDeckPreviewModal;

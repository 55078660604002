import React, { FC } from 'react';
import {
  Box,
  Flex,
  PopoverTrigger as OrigPopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { TFunction } from 'i18next';

import { IconImage } from '../../../ui-components';

export interface IMembersDataEntity {
  eid: string;
  image: string;
  profilePic: string;
  name: string;
  role: string;
  location: string;
  authRole: string;
  locations: {
    name: string;
  }[];
}

export const PopoverTrigger: React.FC<{ children: React.ReactNode }> =
  OrigPopoverTrigger;

const JobMembersComponent: FC<{
  membersData: IMembersDataEntity[];
  t: TFunction<
    ('training' | 'role' | 'setting')[],
    undefined,
    ('training' | 'role' | 'setting')[]
  >;
  jobMembersClickHandler: (
    members: IMembersDataEntity[],
    title: string
  ) => void;
  title: string;
}> = ({ membersData, title, t, jobMembersClickHandler }) => {
  let firstThreeData = membersData?.slice(0, 3);
  let otherData = membersData?.slice(3);

  const renderFirstThreeHandler = () => {
    return firstThreeData?.map((member, index) => (
      <Flex
        key={index}
        bg='#B1E5FC80'
        borderRadius='7px'
        width='fit-content'
        p={['5px 8px']}
        alignItems='center'
        gap='4px'
      >
        <IconImage
          name={member?.name}
          thumbnail={member?.profilePic}
          boxSize={25}
          borderRadius='4px'
        />
        <Text as='b' isTruncated maxWidth='170px'>
          {member?.name}
        </Text>
        {/* <Text textTransform={'capitalize'}>{member?.role}</Text> */}
      </Flex>
    ));
  };

  const renderOtherHandler = () => {
    return otherData?.length > 0 ? (
      <Box
        as='button'
        border='1px solid rgba(221, 221, 221, 1)'
        p={['5px 8px']}
        borderRadius='7px'
        cursor='pointer'
      >
        {otherData?.length > 0 ? (
          <Flex gap='3px' fontWeight='500'>
            <Text>+</Text>
            <Text>{otherData?.length}</Text>
            <Text>{t('training:more')}</Text>
          </Flex>
        ) : null}
      </Box>
    ) : // <Popover>
    //   <PopoverTrigger>
    //   </PopoverTrigger>
    //   <PopoverContent>
    //     <PopoverArrow />
    //     <PopoverCloseButton />
    //     <PopoverBody>
    //       <List spacing={4} styleType='none' maxH='200px' overflow='scroll'>
    //         {otherData?.map((member, index) => {
    //           return (
    //             <Flex key={index} gap='4px'>
    //               <Image
    //                 src={getImage(member?.image, member?.name)}
    //                 boxSize='45px'
    //                 borderRadius='7px'
    //               />
    //               <Flex direction='column'>
    //                 <Text fontWeight={500} color='#1A1D1F'>
    //                   {member?.name}
    //                 </Text>
    //                 <Flex>
    //                   <Text
    //                     marginBottom='-5px'
    //                     fontWeight={600}
    //                     color='rgba(42, 133, 255, 1)'
    //                   >
    //                     {member?.role}
    //                   </Text>
    //                   <Text>&bull;</Text>
    //                   <Text fontWeight={600} color='#9A9FA5'>
    //                     {member?.locations?.[0]?.name || '-'}
    //                   </Text>
    //                 </Flex>
    //               </Flex>
    //             </Flex>
    //           );
    //         })}
    //       </List>
    //     </PopoverBody>
    //   </PopoverContent>
    // </Popover>
    null;
  };

  return membersData?.length > 0 ? (
    <Flex
      alignItems='center'
      gap='8px'
      cursor='pointer'
      onClick={() => jobMembersClickHandler(membersData, title)}
    >
      {renderFirstThreeHandler()}
      {renderOtherHandler()}
    </Flex>
  ) : (
    <Text>-</Text>
  );
};

export default JobMembersComponent;

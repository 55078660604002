import React, { CSSProperties, FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Trans, useTranslation } from 'react-i18next';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface IProps {
  selected?: boolean;
  count: number;
  name: string;
  color: string;
  onClick?: () => void;
}

const OverviewItem: FC<IProps> = ({
  selected,
  name,
  count,
  color,
  onClick,
}) => {
  const { t } = useTranslation(['common', 'task', 'form']);

  return (
    <Flex
      flex={1}
      cursor={onClick ? 'pointer' : undefined}
      p={5}
      borderRadius='8px'
      border={selected ? '1px solid #2b85ff' : 'none'}
      bg='white'
      flexDir='column'
      gap='8px'
      onClick={onClick}
    >
      <Flex align='center' justify='space-between'>
        <Flex gap='8px' align='center'>
          <Box h='20px' w='12px' bg={color} borderRadius='2px' />
          <Text fontWeight='semibold' color='#6F767E' style={wrapStyles}>
            {name}
          </Text>
        </Flex>
        <CheckCircleIcon
          color='#2b85ff'
          display={selected ? 'block' : 'none'}
        />
      </Flex>

      <Text fontWeight={700} fontSize='20px' color='#171725'>
        <Trans
          t={t}
          i18nKey='task:taskCount'
          count={count}
          values={{ count }}
        />
      </Text>
    </Flex>
  );
};

export default OverviewItem;

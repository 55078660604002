import { Flex, List, ListItem } from '@chakra-ui/react';
import MyTaskItem from 'pages/TasksDashboard/Components/MyTaskItem';
import { taskTypeCount } from 'pages/TasksDashboard/MyTasks/functions/taskTypeCount';
import {
  IMyTask,
  ITaskListData,
  ITaskOverview,
} from 'pages/TasksDashboard/tasks.types';
import React, { FC } from 'react';
import GenericColorHeader from 'sub-components/GenericColorHeader';

const ThisWeekTasksComponent: FC<{
  data: IMyTask | undefined;
  taskOverviewData: ITaskOverview[];
  taskData: ITaskListData[];
}> = ({ data, taskOverviewData, taskData }) => {
  let flag = taskOverviewData
    ?.filter((data) => data.id === 'thisWeek')
    ?.map((data) => data?.selected)?.[0];
  if (!flag) {
    return taskTypeCount(taskOverviewData, 'upcoming') > 0 ? (
      <Flex flexDirection='column' gap='10px'>
        <GenericColorHeader title='Upcoming tasks' color='#B1E5FC' />
        <Flex flexDir='column'>
          <List spacing={2}>
            {taskData?.map((task, index) => {
              return (
                task?.type === 'upcoming' &&
                task?.data?.map((_data, index) => (
                  <ListItem
                    key={index}
                    bg='white'
                    p={2}
                    borderRadius='8px'
                    _hover={{ bg: '#F9F9F9' }}
                  >
                    <MyTaskItem task={_data} />
                  </ListItem>
                ))
              );
            })}
          </List>
        </Flex>
      </Flex>
    ) : null;
  }
  return taskTypeCount(taskOverviewData, 'thisWeek') > 0 ? (
    <Flex flexDirection='column' gap='10px'>
      <GenericColorHeader title='Upcoming tasks' color='#B1E5FC' />
      <Flex flexDir='column'>
        <List spacing={2}>
          {taskData?.map((task, index) => {
            return (
              task?.type === 'thisWeek' &&
              task?.data?.map((_data, index) => (
                <ListItem
                  key={index}
                  bg='white'
                  p={2}
                  borderRadius='8px'
                  _hover={{ bg: '#F9F9F9' }}
                >
                  <MyTaskItem task={_data} />
                </ListItem>
              ))
            );
          })}
        </List>
      </Flex>
    </Flex>
  ) : null;
};

export default ThisWeekTasksComponent;

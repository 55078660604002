import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import AssignPublishForm from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/AssignPublishForm';
import AssignPublishText from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/AssignPublishText';
import { RoleLocationEditModalWrapper } from './RoleLocationEditModal.styles';
import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import PrimaryButton from 'atoms/PrimaryButton';

interface IRoleLocationEditModal {
  isOpen: boolean;
  onClose: (type: 'close' | 'save') => void;
  thirdStepSelections: IThirdStepData;
  thirdStepData: IThirdStepData;
  setThirdStepSelections: React.Dispatch<React.SetStateAction<IThirdStepData>>;
  setThirdStepData: React.Dispatch<React.SetStateAction<IThirdStepData>>;
}

const RoleLocationEditModal = ({
  isOpen,
  onClose,
  thirdStepSelections,
  thirdStepData,
  setThirdStepSelections,
  setThirdStepData,
}: IRoleLocationEditModal) => {
  const { t } = useTranslation(['common', 'training']);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose('close')}
      size={'6xl'}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent p={'9'}>
        <ModalHeader>
          {t('training:edit_assignee_supervisors_heading')}
        </ModalHeader>
        <ModalCloseButton m={'9'} />
        <ModalBody>
          <RoleLocationEditModalWrapper>
            <div className='role-location-form-text-container'>
              <div className='role-location-assign-publish-form'>
                <AssignPublishForm
                  thirdStepSelections={thirdStepSelections}
                  thirdStepData={thirdStepData}
                  setThirdStepSelections={setThirdStepSelections}
                  setThirdStepData={setThirdStepData}
                />
              </div>
              <div className='role-location-assign-publish-text'>
                <AssignPublishText thirdStepSelections={thirdStepSelections} />
              </div>
            </div>
            <div className='role-location-form-text-button-container'>
              <PrimaryButton
                title={t('common:save')}
                className='role-location-form-text-button'
                onClick={() => onClose('save')}
              />
            </div>
          </RoleLocationEditModalWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RoleLocationEditModal;

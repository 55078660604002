import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { UpdateUserResponse, UserUpdateIdInput } from './member-detail.graphql';
import { PayloadInput } from '../../../types';
import { UPDATE_INVITED_USER } from '../InvitedMember/update-invite.graphql';

import { InfoFormValues } from '../../Profile/TabComponents';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

const UPDATE_INVITATION = gql`
  query FetchInviteDetails($eid: String!) {
    FetchInviteDetails(eid: $eid) {
      inviteUrl
    }
  }
`;

interface IProps {
  userId: string;
  onCompleted?: (data: UpdateUserResponse) => void;
}

type ReturnType = (values: InfoFormValues) => Promise<void>;

export const useProfileUpdateMutation = ({
  userId,
  onCompleted,
}: IProps): ReturnType => {
  const { t } = useTranslation(['common', 'profile']);

  const toast = useToast({
    duration: 2000,
    position: 'top-right',
    isClosable: true,
  });

  const [updateUser, { client }] = useMutation<
    UpdateUserResponse,
    PayloadInput<UserUpdateIdInput>
  >(UPDATE_INVITED_USER, {
    onCompleted: (response) => {
      toast({
        status: 'success',
        title: t('common:success'),
        // @ts-ignore
        description: t('profile:memberInfoUpdated', {
          name: response.updateUserById.name,
        }),
      });
      onCompleted?.(response);
    },
    onError: (error) => {
      toast({
        status: 'error',
        title: error?.message,
      });
    },
  });

  return useCallback(
    async (values: InfoFormValues) => {
      if (values.status === 'pending') {
        deployEvent(AmplitudeEventNames.RESEND_INVITE_BUTTON_FROM_DETAILS_PAGE);
        try {
          await client.query({
            query: UPDATE_INVITATION,
            fetchPolicy: 'network-only',
            variables: {
              eid: userId,
            },
          });
        } catch (e) {
          toast({
            status: 'error',
            title: t('common:error'),
            description: 'Resend invitation failed!',
          });

          return Promise.reject('something went wrong');
        }
      }
      deployEvent(AmplitudeEventNames.MEMBER_UPDATE);
      const response = await updateUser({
        variables: {
          input: {
            role: values.role,
            eid: userId,
            email: values.email,
            profilePic: values.profilePic,
            name: values.firstName.concat(' ', values.lastName).trim(),
            authRole: values.accessDetails,
            locations: values.location,
            phone: values.phoneNumber,
            username: values.username,
          },
        },
      });

      if (response.errors) {
        return Promise.reject('Member detail update failed');
      }
    },
    [userId]
  );
};

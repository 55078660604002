import ReactDOM from 'react-dom';
import { ReactElement } from 'react';

type Root = {
  render: (element: ReactElement) => Element;
  unmount: () => void;
};

type CreateRoot = (container: ContainerType) => Root;

const fullClone = { ...ReactDOM } as typeof ReactDOM & {
  __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED?: {
    usingClientEntryPoint?: boolean;
  };
  createRoot?: CreateRoot;
};

let createRoot: CreateRoot | undefined;

try {
  const mainVersion = Number((fullClone.version || '').split('.')[0]);
  if (mainVersion >= 18) {
    createRoot = fullClone.createRoot;
  }
} catch (e) {
  // eslint-disable-next-line no-console
  console.debug(e);
}

const MARK = '__delightree_confirm_root';

type ContainerType = (Element | DocumentFragment) & {
  [MARK]?: Root;
};

function toggleWarning(skip: boolean) {
  const { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } = fullClone;
  if (
    __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED &&
    typeof __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED === 'object'
  ) {
    __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.usingClientEntryPoint =
      skip;
  }
}

function legacyRender(node: ReactElement, container: ContainerType) {
  fullClone.render(node, container);
}

function modernRender(node: ReactElement, container: ContainerType) {
  if (createRoot) {
    toggleWarning(true);
    const root = container[MARK] || createRoot?.(container);
    toggleWarning(false);

    root.render(node);
    container[MARK] = root;
  }
}

export function customRender(
  node: ReactElement,
  container: ContainerType
): void {
  if (createRoot) {
    modernRender(node, container);
    return;
  }
  legacyRender(node, container);
}

function modernUnmount(container: ContainerType) {
  return Promise.resolve().then(() => {
    container[MARK]?.unmount();

    delete container[MARK];
  });
}

function legacyUnmount(container: ContainerType) {
  fullClone.unmountComponentAtNode(container);
}

export function customUnmount(
  container: ContainerType
): PromiseLike<void> | void {
  if (createRoot !== undefined) {
    // Delay to unmount to avoid React 18 sync warning
    return modernUnmount(container);
  }
  legacyUnmount(container);
}

import React, { FC } from 'react';
import { Center, Tooltip } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
  totalViewedCount: number;
  maxCount: number;
  certificateId?: string;
  flag?: boolean;
}

const DownloadButton: FC<IProps> = ({
  totalViewedCount,
  maxCount,
  certificateId,
  flag,
}) => {
  const { t } = useTranslation('training');

  const isCompleted = flag || totalViewedCount === maxCount;

  return (
    <Center w='full' marginBottom='25px'>
      <Tooltip
        hasArrow
        label={t(
          isCompleted ? 'download_certificate' : 'download_training_certificate'
        )}
        placement='bottom-start'
        padding='8px 12px'
        borderRadius='4px'
      >
        <DownloadIcon
          cursor={isCompleted ? 'pointer' : 'no-drop'}
          fontSize='large'
          color={isCompleted ? '#2a85ff' : 'lightgray'}
          onClick={() => {
            isCompleted &&
              window.open(
                `${process.env.REACT_APP_API_BASE_ENDPOINT}/certify/${certificateId}`
              );
          }}
        />
      </Tooltip>
    </Center>
  );
};

export default DownloadButton;

import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDeleteSubFolderProps } from 'pages/Chapters/ChapterContainer/hooks/useChapterContainerMisc';
import React, { FC, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SearchInput, SelectOption } from 'atoms';
import {
  faArrowLeft,
  faArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import MoveSubFolderItems from './MoveSubFolderItems';
import useCombinedStore from 'zustandStore/store';

const MoveSubFolderChapters: FC<{
  open: boolean;
  onClose: () => void;
  onMoveSuccess: () => void;
  onMoveError: () => void;
  selectedFolderData: SelectOption<string> | undefined;
}> = ({ open, selectedFolderData, onClose, onMoveSuccess }) => {
  const { deleteSubFolderData, updateDeleteSubFolderData } = useCombinedStore();
  const [searchValue, setSearchValue] = useState('');
  const onNoData = () => {
    updateDeleteSubFolderData({
      ...deleteSubFolderData,
      showEmptyStateModal: true,
    });
    // setDeleteSubFolderData((prev) => {
    //   return {
    //     ...prev,
    //     showEmptyStateModal: true,
    //   };
    // });
  };
  const onMoveSuccessHandler = () => {
    onMoveSuccess?.();
  };
  const onMoveErrorHandler = () => {
    onClose?.();
  };
  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size='lg'
    >
      <ModalOverlay />
      <ModalContent p={3}>
        <ModalHeader>
          <Box>
            <Flex gap='12px' align='center'>
              <Box cursor='pointer'>
                <FontAwesomeIcon
                  icon={faArrowLeft as IconProp}
                  fontSize='17px'
                  onClick={() => {
                    updateDeleteSubFolderData({
                      ...deleteSubFolderData,
                      showConfirmModal: true,
                      showMoveChaptersModal: false,
                    });
                    // setDeleteSubFolderData((prev) => {
                    //   return {
                    //     ...prev,
                    //     showConfirmModal: true,
                    //     showMoveChaptersModal: false,
                    //   };
                    // });
                  }}
                />
              </Box>
              <Box
                flex={1}
                fontWeight='600'
                fontSize='20px'
                lineHeight='32px'
                color='#1a1d1f'
                userSelect='none'
              >
                Move chapters
              </Box>
              <ModalCloseButton m={4} />
            </Flex>
            <Flex
              mt={3}
              fontSize='14px'
              fontWeight={400}
              color='#33383F'
              bg='rgba(177, 229, 252, 0.4)'
              py='12px'
              px='16px'
              borderRadius='10px'
              gap='12px'
              align='center'
            >
              <FontAwesomeIcon
                icon={faArrowUpRight as IconProp}
                color='#2A85FF'
              />
              Moving chapters will remove them from their current subfolder.
            </Flex>
            <Box mt={3}>
              <SearchInput
                size='md'
                width='100%'
                hideShortcuts
                placeholder='Search chapter'
                disabled
                value={searchValue}
                onChange={(e) => setSearchValue(e?.target?.value)}
              />
            </Box>
          </Box>
        </ModalHeader>
        <ModalBody>
          <MoveSubFolderItems
            folderDetails={{
              eid: selectedFolderData?.value!,
              name: selectedFolderData?.label!,
              visibility: selectedFolderData?.visibility,
              visibleTo: selectedFolderData?.visibleTo,
            }}
            searchValue={searchValue}
            onMoveSuccess={onMoveSuccessHandler}
            onMoveError={onMoveErrorHandler}
            onNoData={onNoData}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MoveSubFolderChapters;

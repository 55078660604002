import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import DashboardContainer from '../../sub-components/DashboardContainer';
import MyTasks from './MyTasks';
import SupervisedTasks from './SupervisedTasks';
import { TaskRoutes } from './tasks.types';
import TaskDataProvider from './store/TaskDataProvider';
import TasksDashboard from './TasksDashboard';

const TasksPageContainer: FC = () => {
  const { taskType } = useParams<{ taskType: TaskRoutes }>();

  const navigationHandler = () => {
    switch (taskType) {
      case 'dashboard':
        return <TasksDashboard />;
      case 'my-tasks':
        return <MyTasks />;
      case 'supervised':
        return <SupervisedTasks />;
      default:
        return <TasksDashboard />;
    }
  };

  return (
    <DashboardContainer>
      <TaskDataProvider>{navigationHandler()}</TaskDataProvider>
    </DashboardContainer>
  );
};

export default TasksPageContainer;

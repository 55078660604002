import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Question } from '../../create-form/form.types';
import { FormPreviewInput } from '../form-preview.types';
import Textarea from '../../../../atoms/Input/Textarea';

interface IProps {
  question: Question;
  currentIndex: number;
  isDisabled?: boolean;
}

const FormTextAreaInput: FC<IProps> = ({
  question,
  currentIndex,
  isDisabled,
}) => {
  const { t } = useTranslation('form');
  const { control } = useFormContext<FormPreviewInput>();
  return (
    <Controller
      name={`response.${currentIndex}.response`}
      control={control}
      rules={{
        required: {
          value: question?.isRequired === true,
          message: t('validation.field_required'),
        },
      }}
      render={({ field, fieldState }) => {
        return (
          <FormControl
            mt={3}
            isInvalid={!!fieldState.error}
            isDisabled={isDisabled}
          >
            <Textarea {...field} />
            <FormErrorMessage>
              <span>{fieldState?.error?.message}</span>
            </FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default FormTextAreaInput;

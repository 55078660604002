import React, { FC } from 'react';
import DashboardContainer from 'sub-components/DashboardContainer';
import FormDataProvider from 'pages/forms/FormStore/FormDataProvider';
import FormResponseContainer from './FormResponseContainer';

const FormResponseRootContainer: FC = () => {
  return (
    <DashboardContainer>
      <FormDataProvider>
        <FormResponseContainer />
      </FormDataProvider>
    </DashboardContainer>
  );
};

export default FormResponseRootContainer;

import { CometChat } from '@cometchat-pro/chat';
import { match } from 'ts-pattern';
import { ChannelConfigEntity, ChatGroupEntity } from '../../../../types';
import { AuthRole } from '../../../../authorization';

const roles: Record<string, AuthRole> = {
  admin: AuthRole.ADMIN,
  superAdmin: AuthRole.SUPER_ADMIN,
  superadmin: AuthRole.SUPER_ADMIN,
  locationOwner: AuthRole.LOCATION_OWNER,
  locationowner: AuthRole.LOCATION_OWNER,
  worker: AuthRole.WORKER,
};

interface ChannelData {
  metadata: ChatGroupEntity['metadata'];
  owner: string;
}

export const userCanInvite = (channel: ChannelData, user: CometChat.User) => {
  const config = channel?.metadata?.inviteUser;
  return match(config?.type)
    .with('anyone', () => {
      return (
        roles[user?.getRole()] !== AuthRole.WORKER ||
        channel?.owner === user?.getUid()
      );
    })
    .with('creator', () => channel?.owner === user?.getUid())
    .with('authRole', () => {
      return (
        config?.authRoles?.includes(roles[user?.getRole()]) ||
        config?.members?.includes(user?.getUid()) ||
        channel?.owner === user?.getUid()
      );
    })
    .otherwise(() => true);
};

export const userCanPostMessage = (
  channel: ChannelData,
  user: CometChat.User
) => {
  const config = channel?.metadata?.postMessage;

  return match(config?.type)
    .with('anyone', () => true)
    .with('creator', () => channel?.owner === user?.getUid())
    .with('authRole', () => {
      return (
        config?.authRoles?.includes(roles[user?.getRole()]) ||
        config?.members?.includes(user?.getUid()) ||
        channel?.owner === user?.getUid()
      );
    })
    .otherwise(() => true);
};

export const getUserPermission = (
  user: CometChat.User,
  config: ChannelConfigEntity
) => {
  if (roles[user?.getRole()] === AuthRole.SUPER_ADMIN) {
    return CometChat.GROUP_MEMBER_SCOPE.ADMIN;
  }
  if (config?.type === 'authRole') {
    const authRole = roles[user?.getRole()];

    if (authRole === AuthRole.WORKER) {
      return CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT;
    }

    if (config.members?.includes(user?.getUid())) {
      return CometChat.GROUP_MEMBER_SCOPE.ADMIN;
    }

    if (authRole && config.authRoles?.includes(authRole)) {
      return CometChat.GROUP_MEMBER_SCOPE.ADMIN;
    }
    return CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT;
  }
  return CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT;
};

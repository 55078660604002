import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Editor from 'delightree-editor';
import {
  LayoutImageCount,
  MixedLayout,
} from 'sop-commons/src/utils/cardLayouts';

import TitleHeader from './TitleHeader';
import FormInput from '../../atoms/FormInput';

import { CardFormInput } from './card-editor.types';
import MixedLayoutSelect from './MixedLayoutSelect';
import { editorDataValidator } from './editorDataValidator';
import ImageFieldComponent from './common/ImageFieldComponent';

const MixedCardEditor: FC = () => {
  const { t } = useTranslation('card');
  const { t: tc } = useTranslation('common');
  const { control, setValue, getValues } = useFormContext<CardFormInput>();

  return (
    <>
      <Controller
        name={'layout'}
        control={control}
        rules={{
          required: t('validation.mixed_layout_required'),
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <MixedLayoutSelect
                layout={field.value as MixedLayout}
                onSelectLayout={(layout) => {
                  field.onChange(layout);
                  const images = getValues('images') || [];
                  const maxImage = LayoutImageCount[layout];
                  setValue(
                    'images',
                    images.filter((_, index) => index < maxImage)
                  );
                }}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <TitleHeader
        title={t('title')}
        desc={t('title_desc')}
        titleStyle={{ marginTop: '30px' }}
        isRequired
      />

      <Controller
        control={control}
        name='title'
        rules={{
          required: t('validation.title_required'),
          validate: (value) => {
            if (value?.trim()?.length === 0) {
              return tc('validation.enter_title');
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={3} isInvalid={!!fieldState.error}>
              <FormInput
                id='title'
                size='lg'
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  setValue('raw_content', event.target.value);
                }}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <TitleHeader
        title={t('media')}
        desc={t('media_desc')}
        titleStyle={{ marginTop: '30px' }}
        isRequired
      />

      <ImageFieldComponent />

      <TitleHeader
        title={t('body_text')}
        desc={t('description_desc')}
        titleStyle={{ marginTop: '30px' }}
        isRequired
      />
      <Controller
        name={'bodyJson'}
        control={control}
        rules={{
          required: t('validation.body_required'),
          validate: (value) => {
            if (editorDataValidator(value)) {
              return t('validation.body_invalid');
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl isInvalid={!!fieldState.error}>
              <div className='card-editor-description-container'>
                <Editor
                  readonly={false}
                  chapterEditor={false}
                  onChange={(jsonData) => {
                    field.onChange(jsonData);
                  }}
                  value={field.value}
                  isBasicVersion
                />
              </div>

              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </>
  );
};

export default MixedCardEditor;

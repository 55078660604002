import React, { FC, useState } from 'react';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubmitForm from '../../forms/submit-form/SubmitForm';
import PrimaryButton from '../../../atoms/PrimaryButton';
import { Authorize, AuthRole } from '../../../authorization';
import { BoxHeader } from '../../../ui-components';

interface IProps {
  formId: string;
  title: string;
  onClose: () => void;
}

const SearchFormView: FC<IProps> = ({ formId, title, onClose }) => {
  const { t } = useTranslation('common');
  const [formData, setFormData] = useState<any>();
  const history = useHistory();

  const onDataLoad = (data: any) => {
    if (data.eid) {
      setFormData(data);
    } else {
      setFormData(undefined);
    }
  };

  return (
    <Modal isOpen onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        minH='60%'
        maxW='800px'
        mx='auto'
        p='24px'
        borderRadius='10px'
      >
        <ModalHeader pos='relative'>
          <ModalCloseButton top='unset' />
          <Flex align='center' pr='36px'>
            <BoxHeader color='#cabdff' title={title} />
            <Spacer />
            <Authorize
              permittedFor='user'
              permittedRoles={[AuthRole.SUPER_ADMIN]}
              canAccess={({ eid }) => eid === formData?.createdBy?.eid}
            >
              <PrimaryButton
                width='auto'
                variant='outline'
                colorScheme='blue'
                size='sm'
                title={t('modify')}
                style={{
                  fontSize: '14px',
                }}
                leftIcon={
                  <FontAwesomeIcon
                    icon={faPen as IconProp}
                    size='sm'
                    color='#2a85ff'
                  />
                }
                onClick={() => history.push(`/forms/edit/${formId}`)}
              />
            </Authorize>
          </Flex>
        </ModalHeader>
        <ModalBody display='flex'>
          <SubmitForm formId={formId} onDataLoad={onDataLoad} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchFormView;

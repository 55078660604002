import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { callAll } from '@chakra-ui/utils';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';

import { GroupMemberEntity } from '../../../../types';
import { useConfirm, UseConfirm } from '../../../../ui-components';

import { UpdateChannelContent } from './update-channel';

import UpdateChannelTitle from './update-channel/UpdateChannelTitle';
import { UpdateChannelProvider } from './update-channel/UpdateChannelProvider';

interface IProps {
  activeTab?: number;
  groupId: string;
  reFetchData?: (...args: any) => void;
  onMessage: (userId: string) => void;
}

type IChanelSetting = (props: IProps) => void;

export const useChannelSetting = (): IChanelSetting => {
  const reFetchRef = useRef<any>();

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const confirm = useConfirm();

  const apolloClient = useApolloClient();

  return useCallback(({ activeTab, groupId, reFetchData, onMessage }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    reFetchRef.current = reFetchData;

    const _onSendMessage = (data: GroupMemberEntity) => {
      onMessage?.(data.uid);
      setTimeout(confirmRef.current!.destroy);
    };

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>
            <UpdateChannelProvider groupId={groupId}>
              {children}
            </UpdateChannelProvider>
          </ApolloProvider>
        );
      },
      title: (
        <Flex justify='space-between'>
          <UpdateChannelTitle />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <UpdateChannelContent
          activeTab={activeTab}
          onGroupDeleted={callAll(reFetchRef.current, () =>
            setTimeout(confirmRef.current!.destroy)
          )}
          onGroupUpdated={reFetchRef.current}
          onMemberUpdated={reFetchRef.current}
          onSendMessage={_onSendMessage}
        />
      ),
      contentProps: {
        py: '12px',
        minWidth: '600px',

        marginTop: 'auto',
        marginBottom: 'auto',
        containerProps: {
          // alignItems: 'start',
          paddingTop: '3.75rem',
          paddingBottom: '3.75rem',
        },
      },
      isCentered: true,
      footer: null,
    });
  }, []);
};

import React, { FC } from 'react';
import { Center, Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';

interface IProps {
  isLoaded?: boolean;
}

const EditorStepLoader: FC<IProps> = ({ isLoaded, children }) => {
  if (isLoaded) {
    return <>{children}</>;
  }

  return (
    <Flex flexDir='column' gap={4} pb='20px'>
      <Flex align='center'>
        <Center border='4px solid white' borderRadius='full'>
          <SkeletonCircle size='32px' />
        </Center>
        <Flex bg='white' flex={1} h={1} />
        <Center border='4px solid white' borderRadius='full'>
          <SkeletonCircle size='32px' />
        </Center>
        <Flex bg='white' flex={1} h={1} />
        <Center border='4px solid white' borderRadius='full'>
          <SkeletonCircle size='32px' />
        </Center>
      </Flex>
      <Flex align='center'>
        <Skeleton h='14px' borderRadius='full' w='120px' />
        <Flex flex={1} />
        <Skeleton h='14px' borderRadius='full' w='120px' />
        <Flex flex={1} />
        <Flex w='40px' />
      </Flex>
    </Flex>
  );
};

export default EditorStepLoader;

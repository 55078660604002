import React, { FC, useCallback } from 'react';
import { chakraComponents } from 'chakra-react-select';
import { Trans, useTranslation } from 'react-i18next';

import { AuthRole } from '../../../../authorization';
import Dropdown, { SelectOption } from '../../../../atoms/Dropdown';

interface IProps {
  filterBy?: SelectOption | null;
  onFilterChange?: (value: SelectOption | null) => void;
}

const AuthRoleFilter: FC<IProps> = ({ filterBy, onFilterChange }) => {
  const { t } = useTranslation(['common', 'handbook']);

  const _onFilterChange = useCallback(
    (option: SelectOption) => {
      if (option?.value === filterBy?.value) {
        onFilterChange?.({
          label: t('common:all'),
          value: 'all',
        });
      } else {
        onFilterChange?.(option);
      }
    },
    [filterBy]
  );

  return (
    <Dropdown
      placeholder={t('common:filterBy')}
      value={filterBy}
      onChange={_onFilterChange}
      options={[
        {
          label: t('common:all'),
          value: 'all',
        },
        {
          label: 'Super Admins',
          value: AuthRole.SUPER_ADMIN,
        },
        {
          label: 'Admins',
          value: AuthRole.ADMIN,
        },
        {
          label: 'Location Owners',
          value: AuthRole.LOCATION_OWNER,
        },
        {
          label: 'Workers',
          value: AuthRole.WORKER,
        },
      ]}
      selectStyles={{
        singleValue: {
          color: '#6f767e',
          fontWeight: '600',
        },
      }}
      components={{
        SingleValue: (valProps) => {
          return (
            <chakraComponents.SingleValue {...valProps}>
              <Trans
                t={t}
                i18nKey='handbook:filterBy'
                values={{
                  value: valProps.children,
                }}
              />
            </chakraComponents.SingleValue>
          );
        },
      }}
    />
  );
};

export default AuthRoleFilter;

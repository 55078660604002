import React, { useMemo } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Box, useToast } from '@chakra-ui/react';

import { useUserDataQuery } from '../../../../hooks';
import { AuthRole } from '../../../../authorization';

import {
  UPDATE_LOCATION,
  UPDATE_PROFILE,
  UpdateLocationVariable,
  UpdateProfileVariable,
  UserDataResponse,
} from './info.graphql';
import InfoBaseComponent from './InfoBaseComponent';
import { DisablePermission } from './useDisabledField';
import { InfoFormValues } from './ProfileInfoForm';
import ProfileActionButtons from './ProfileActionButtons';
import { UpdateButton, UpdatePassword } from './ActionButtons';

interface IProps {
  userId: string;
  disableLocation?: boolean;
  onUserDataFetched?: (data: UserDataResponse['userById']) => void;
}

const InfoComponent: React.FC<IProps> = ({
  userId,
  onUserDataFetched,
  disableLocation,
}) => {
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const { t } = useTranslation(['setting']);

  const [getUser] = useUserDataQuery();

  const onCompleted = () => {
    // setIsLoading(false);
    toast({
      status: 'success',
      title: t('setting:profileUpdateSuccess'),
    });
    // getUser();
    getUser({ variables: {} });
  };
  const onError = (error: ApolloError) => {
    // setIsLoading(false);
    // setError(error);
    toast({
      status: 'error',
      title: error?.message,
    });
  };

  const [updateProfile] = useMutation<unknown, UpdateProfileVariable>(
    UPDATE_PROFILE,
    {
      onCompleted: onCompleted,
      onError: onError,
    }
  );

  const [updateLocation] = useMutation<unknown, UpdateLocationVariable>(
    UPDATE_LOCATION,
    {
      onCompleted: onCompleted,
      onError: onError,
    }
  );

  const disablePermission = useMemo((): DisablePermission => {
    return {
      email: {
        permittedFor: ['user'],
        permittedRoles: [AuthRole.SUPER_ADMIN],
      },
      role: {
        permittedFor: ['user'],
        permittedRoles: [AuthRole.SUPER_ADMIN],
      },
      accessDetails: {
        restrictedFor: ['user', 'branch'],
      },
      location: {
        permittedFor: ['user'],
        permittedRoles: [AuthRole.SUPER_ADMIN],
      },
    };
  }, []);

  const onSubmit = async (values: InfoFormValues) => {
    if (values.type === 'user') {
      await updateProfile({
        variables: {
          input: {
            role: values.role,
            email: values.email,
            profilePic: values.profilePic,
            name: values.firstName.concat(' ', values.lastName).trim(),
            phone: values.phoneNumber,
          },
        },
      });
    } else {
      await updateLocation({
        variables: {
          input: {
            eid: userId,
            name: values.firstName.concat(' ', values.lastName).trim(),
            profilePic: values.profilePic,
            phone: values.phoneNumber,
            username: values.username,
          },
        },
      });
    }
  };

  return (
    <Box p={2} mt='12px'>
      <InfoBaseComponent
        userId={userId}
        disablePermissions={disablePermission}
        disableLocation={disableLocation}
        onUserDataFetched={onUserDataFetched}
      >
        <ProfileActionButtons>
          <Box flex={1} />
          <UpdatePassword userId={userId} />
          <UpdateButton onSubmit={onSubmit} />
        </ProfileActionButtons>
      </InfoBaseComponent>
    </Box>
  );
};

InfoComponent.displayName = 'pages/Profile/TabComponents/InfoComponent';

export default InfoComponent;

import { Flex, FlexProps, SpaceProps } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

type BaseLayoutWrapperProps = {
  children: ReactNode;
} & FlexProps;

const BaseLayoutWrapper: FC<BaseLayoutWrapperProps> = ({
  children,
  ...rest
}) => {
  return (
    <Flex borderRadius='8px' bg='white' {...rest}>
      {children}
    </Flex>
  );
};

export default BaseLayoutWrapper;

import React, { Component } from 'react';
import { Box, Center, Flex, IconButton, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { CometChat } from '@cometchat-pro/chat';

import ArchivedList from './ArchivedList';
import Translator from '../../../../resources/localization/translator';

class ArchivedChats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      archivedChats: [],
      decoratorMessage: Translator.translate('LOADING', props.lang),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const archivedChats = (props.conversationlist || []).filter((it) =>
      props.archivedIds?.includes(it.conversationWith?.uid)
    );

    let conversations = [...state.archivedChats];

    archivedChats.forEach((conversation) => {
      let conversationKey = conversations.findIndex(
        (c) => c.conversationId === conversation.conversationId
      );

      if (conversationKey === -1) {
        conversations.push(conversation);
      }
    });

    return {
      archivedIds: props.archivedIds || [],
      archivedChats: conversations,
    };
  }

  componentDidMount() {
    this.conversationRequest = new CometChat.ConversationsRequestBuilder()
      .setLimit(30)
      .setTags(['archivedChat'])
      .withTags(true)
      .build();

    this.conversationRequest
      .fetchNext()
      .then(this.handleConversation)
      .catch(this.handleError);
  }

  handleError = (error) => {
    console.log('Error', error);
    this.setState({
      decoratorMessage: Translator.translate(
        'SOMETHING_WRONG',
        this.props.lang
      ),
    });
  };

  handleConversation = (conversationData) => {
    let conversationList = [...conversationData];
    if (conversationList.length === 0) {
      if (this.state.archivedIds.length === 0) {
        this.setState({
          decoratorMessage: Translator.translate(
            'NO_CHATS_FOUND',
            this.props.lang
          ),
        });
      }
    } else {
      this.setState({ decoratorMessage: '' });
    }

    let conversations = [...this.state.archivedChats];

    conversationList.forEach((conversation) => {
      let conversationKey = conversations.findIndex(
        (c) => c.conversationId === conversation.conversationId
      );

      if (conversationKey === -1) {
        conversations.push(conversation);
      } else {
        const newUnreadMessageCount =
          conversations[conversationKey]['unreadMessageCount'] +
          conversation['unreadMessageCount'];
        conversations.splice(conversationKey, 1, {
          ...conversations[conversationKey],
          unreadMessageCount: newUnreadMessageCount,
        });
      }
    });

    this.setState({
      archivedChats: [...conversations],
    });
  };

  getConversations = () => {
    this.conversationRequest
      .fetchNext()
      .then(this.handleConversation)
      .catch(this.handleError);
  };

  render() {
    let messageContainer = null;
    if (this.state.decoratorMessage.length !== 0) {
      messageContainer = (
        <Center pos='absolute' overflow='hidden' top='50%' width='full'>
          <Text
            fontSize='20px!important'
            fontWeight={600}
            lineHeight='30px'
            padding='0 16px'
            color='#cccccc'
          >
            {this.state.decoratorMessage}
          </Text>
        </Center>
      );
    }

    return (
      <Flex flexDir='column' overflow='hidden' gap={3} pt={1}>
        <Flex gap={4} align='center'>
          <Box width={4} height={8} borderRadius='4px' bg='#CABDFF' />
          <Box flex={1} fontSize='18px' fontWeight='600' color='#1A1D1F'>
            Archived chats
          </Box>
          <IconButton
            size='sm'
            variant='ghost'
            borderRadius='full'
            aria-label='close'
            icon={<CloseIcon boxSize='12px' />}
            onClick={this.props.onClose}
          />
        </Flex>

        <Flex
          gap='8px'
          bg='rgba(221, 221, 221, 0.4)'
          borderRadius='10px'
          p='12px 18px'
          mt={3}
        >
          <Flex
            align='center'
            height='24px'
            sx={{
              svg: {
                lineHeight: '24px',
                verticalAlign: 'unset',
              },
            }}
          >
            <FontAwesomeIcon
              icon={faCircleExclamation}
              color='#6F767E'
              fontSize='16px'
            />
          </Flex>
          <Box
            flex={1}
            fontSize='14px'
            fontWeight='500'
            lineHeight='24px'
            color='#33383F'
          >
            These will remain archived even if you get new messages, until you
            un-archive them.
          </Box>
        </Flex>

        {messageContainer ? (
          messageContainer
        ) : (
          <ArchivedList
            archivedIds={this.props.archivedIds}
            onItemClick={this.props.onItemClick}
            conversations={this.state.archivedChats}
            getConversations={this.getConversations}
          />
        )}
      </Flex>
    );
  }
}

export default ArchivedChats;

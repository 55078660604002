import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import MyTrainingList from 'pages/Training/TrainingList/MyTrainingList/MyTrainingList';
import React, { FC } from 'react';
import AssignedToMeEmpty from './AssignedToMeEmpty';

const Wrapper = styled.div`
  width: 100%;
  table {
    background: white;
  }

  table > thead {
    background-color: #f4f4f4;
  }
`;

interface IProps {
  setShowViewAllBtn: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssignedToMe: FC<IProps> = ({ setShowViewAllBtn }) => {
  return (
    <>
      <Flex w='full'>
        <MyTrainingList
          tabSelected='assignedToMe'
          shouldHideCertificateDownload
          shouldHidePagination
          shouldHideActionLabel
          perPage={3}
          setShowViewAllBtn={setShowViewAllBtn}
          customEmptyStateComponent={<AssignedToMeEmpty />}
          backToTitle='Back to Home'
        />
      </Flex>
    </>
  );
};

export default AssignedToMe;

import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { IInProgressList } from 'sub-components/LocationLaunchDetail/types/types';
import InProgressCard from '../Singleton/InProgressCard';

const InProgressList: FC<IInProgressList> = ({ list }) => {
  return (
    <Flex gap={6}>
      {list?.map((_l, index) => (
        <InProgressCard key={index} {..._l} />
      ))}
    </Flex>
  );
};

export default InProgressList;

import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';

import TaskItemSkeleton from './TaskItemSkeleton';
import { getRandomNumber } from '../../../../../utils';

const SkeletonLoading = React.memo(() => {
  return (
    <Box pt={2}>
      {Array.from({ length: getRandomNumber(3, 6) }).map((_, index, arr) => {
        return (
          <TaskItemSkeleton key={index} isLastItem={arr.length === index + 1} />
        );
      })}
    </Box>
  );
});

SkeletonLoading.displayName = 'EditorSectionLoader Skeleton';

interface IProps {
  isLoaded?: boolean;
}

const EditorSectionLoader: FC<IProps> = ({ isLoaded, children }) => {
  return isLoaded ? <>{children}</> : <SkeletonLoading />;
};

export default EditorSectionLoader;

import React, { FC, useState } from 'react';
import { Box, Flex, RadioGroup } from '@chakra-ui/react';
import { faEarthAmericas, faLock } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import CustomCheck from './CustomCheck';
import { ReactComponent as EyeIcon } from '../../../assets/images/eyeIcon.svg';

type Visibility = 'public' | 'private';

const Message: Record<Visibility, string> = {
  public:
    'Anyone can search and view this training path, but only assignees will be notified.',
  private: 'Only assignees can search and view the training path.',
};

interface IProps {
  defaultValue: Visibility;
  onChange: (newValue: Visibility) => void;
}

const VisibilityComponent: FC<IProps> = ({ defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue);

  const _onChange = (newVal: string) => {
    setValue(newVal as Visibility);
    onChange?.(newVal as Visibility);
  };

  return (
    <Box>
      <RadioGroup pb='20px' value={value} onChange={_onChange}>
        <CustomCheck icon={faEarthAmericas as IconProp} value='public' />
        <Box height='1px' bg='#dddddd' width='full' />
        <CustomCheck icon={faLock as IconProp} value='private' />
      </RadioGroup>

      <Flex
        px={5}
        py={4}
        bg='rgba(177, 229, 252, 0.4)'
        borderRadius='10px'
        color='#33383f'
      >
        <Box mr={3}>
          <EyeIcon />
        </Box>
        <div>{Message[value]}</div>
      </Flex>
    </Box>
  );
};

export default VisibilityComponent;

import React, { FC, useState } from 'react';
import { ITaskPlay, ITaskPlayEntity } from '../types/task-play.types';
import TaskPlayDataContext from './task-play-context';

const TaskPlayDataProvider: FC = (props) => {
  const [taskPlayData, setTaskPlayData] = useState<ITaskPlayEntity>(
    {} as ITaskPlayEntity
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  const taskPlayDataHandler = (data: ITaskPlayEntity) => {
    setTaskPlayData(data);
  };

  const currentIndexHandler = (index: number) => {
    setCurrentIndex(index);
  };

  const taskPlayContext = {
    taskPlayData: taskPlayData,
    currentIndex: currentIndex,
    taskPlayDataHandler: taskPlayDataHandler,
    currentIndexHandler: currentIndexHandler,
  };

  return (
    <TaskPlayDataContext.Provider value={taskPlayContext}>
      {props.children}
    </TaskPlayDataContext.Provider>
  );
};

export default TaskPlayDataProvider;

import React, { FC, useCallback } from 'react';
import { Button } from '@chakra-ui/react';
import { useButtonProps } from './useButtonProps';

interface IProps {
  isReadOnly?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  clickDisabled?: boolean;
}

const CheckButton: FC<IProps> = ({
  isReadOnly,
  isSelected,
  onClick,
  children,
  clickDisabled = true,
}) => {
  const otherProps = useButtonProps({
    isSelected: isSelected,
    isReadOnly: isReadOnly,
  });

  const _onClick = useCallback(() => {
    if (isReadOnly) {
      return undefined;
    }

    return isSelected ? (clickDisabled ? undefined : onClick?.()) : onClick?.();
  }, [isReadOnly, isSelected, clickDisabled, onClick]);

  return (
    <Button
      size='lg'
      fontSize='14px'
      fontWeight='500'
      borderRadius='12px'
      {...otherProps}
      onClick={_onClick}
    >
      {children}
    </Button>
  );
};

export default CheckButton;

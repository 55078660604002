import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import {
  LauncherLocationByIdDetail,
  LauncherLocationDetailTask,
} from '../types/location-launch-details.types';

export const taskClickSimulate = (
  taskData: LauncherLocationDetailTask,
  foundLocationData: LauncherLocationByIdDetail | undefined
) => {
  let parsedData: CustomLauncherTask = {} as CustomLauncherTask;
  parsedData.eid = taskData.eid;
  parsedData.title = taskData.title;
  parsedData.description = taskData.description;
  parsedData.completedAt = taskData.completedAt;
  parsedData.startDate = taskData.startDate;
  parsedData.dueDate = taskData.dueDate;
  parsedData.isCompleted = taskData.isCompleted;
  parsedData.files = taskData.files;
  parsedData.duration = taskData.duration;
  parsedData.dependency = taskData.dependency;
  parsedData.docRequired = taskData.docRequired;
  parsedData.locationId = foundLocationData?.locationId!;
  parsedData.launchId = foundLocationData?.eid!;
  parsedData.categoryName = taskData.category;
  parsedData.steps = taskData.steps;
  parsedData.formattedDuration = taskData.formattedDuration;
  parsedData.formattedDueDate = taskData.formattedDueDate;
  parsedData.formattedStartDate = taskData.formattedStartDate;
  parsedData.formattedCompleteDate = taskData.formattedCompleteDate;
  parsedData.taskStatus = taskData.taskStatus;
  return parsedData;
};

import React, { FC } from 'react';
import EditorPreview, { ICON_TYPE, JSONContent } from 'delightree-editor';
import { Box, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import MobileNotch from './MobileNotch';
import MobileHeader from './MobileHeader';
import { useSmartPreviewFormat } from './hooks';

interface IProps {
  title?: string;
  selectedIndex: number;
  value?: JSONContent;
  onChange: (value: number) => void;
  onItemClick?: (event: MouseEvent, node: any) => void;
  chapterIcon?: string;
  iconType?: string;
}

const ChapterMobile: FC<IProps> = ({
  title,
  value,
  selectedIndex,
  onChange,
  onItemClick,
  chapterIcon,
  iconType,
}) => {
  const prevData = useSmartPreviewFormat(value, selectedIndex);

  const totalCards = Array.isArray(value) ? value.length : 0;

  const decrement = () => {
    const next = selectedIndex - 1;
    onChange(next);
    document.getElementById(`preview-thumb-${next}`)?.scrollIntoView({
      inline: 'center',
      block: 'center',
    });
  };

  const increment = () => {
    const next = selectedIndex + 1;
    onChange(next);
    document.getElementById(`preview-thumb-${next}`)?.scrollIntoView({
      inline: 'center',
      block: 'center',
    });
  };

  return (
    <Box
      position='relative'
      borderRadius='44px'
      p='12px'
      border='4px solid #3b444b'
      bg='#151515'
      minW='320px'
      h='full'
      style={{
        aspectRatio: '9/19.5',
      }}
    >
      {totalCards > 1 && (
        <>
          <IconButton
            display={selectedIndex === 0 ? 'none' : 'inline-flex'}
            position='absolute'
            left='0'
            transform='auto'
            translateX='calc(-100% - 20px)'
            translateY='calc(50vh - 160px)'
            borderRadius='full'
            boxShadow='md'
            aria-label={'back'}
            icon={<ChevronLeftIcon boxSize={7} />}
            onClick={decrement}
          />
          <IconButton
            display={selectedIndex + 1 === totalCards ? 'none' : 'inline-flex'}
            position='absolute'
            right='0'
            transform='auto'
            translateX='calc(100% + 20px)'
            translateY='calc(50vh - 160px)'
            borderRadius='full'
            boxShadow='md'
            aria-label={'next'}
            icon={<ChevronRightIcon boxSize={7} />}
            onClick={increment}
          />
        </>
      )}

      <Box
        pos='relative'
        borderRadius='28px'
        bg='#ffffff'
        overflow='hidden'
        h='full'
      >
        <MobileNotch />
        <Box overflow='auto' h='full'>
          <MobileHeader
            title={title}
            totalCards={totalCards}
            currentIndex={totalCards > 1 ? selectedIndex + 1 : undefined}
          />
          <Box p='16px' pt='10px' mb='36px'>
            <EditorPreview
              readonly
              value={prevData}
              onItemClick={onItemClick}
              chapterIcon={chapterIcon}
              iconType={iconType === ICON_TYPE ? 'icon' : 'emoji'}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChapterMobile;

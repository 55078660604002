import { useReactiveVar } from '@apollo/client';
import { Flex, List, ListItem } from '@chakra-ui/react';
import MyTaskItem from 'pages/TasksDashboard/Components/MyTaskItem';
import { completedTasksHandler } from 'pages/TasksDashboard/MyTasks/functions/completedTasksHandler';
import {
  IMyTask,
  ITaskListData,
  ITaskOverview,
} from 'pages/TasksDashboard/tasks.types';
import React, { FC } from 'react';
import { userObj } from 'sop-commons/src/client';
import GenericColorHeader from 'sub-components/GenericColorHeader';

const CompletedTasksComponent: FC<{
  data: IMyTask | undefined;
  taskOverviewData: ITaskOverview[];
  taskData: ITaskListData[];
}> = ({ data, taskOverviewData, taskData }) => {
  const userData = useReactiveVar(userObj);
  return completedTasksHandler(data, userData?.timezone)?.length > 0 ? (
    <Flex flexDirection='column' gap='10px'>
      <GenericColorHeader title='Completed tasks' color='#b6e4ca' />
      <Flex flexDir='column'>
        <List spacing={2}>
          {completedTasksHandler(data, userData?.timezone)?.map(
            (task, index) => {
              return (
                <ListItem
                  key={index}
                  bg='white'
                  p={2}
                  borderRadius='8px'
                  _hover={{ bg: '#F9F9F9' }}
                >
                  <MyTaskItem task={task} />
                </ListItem>
              );
            }
          )}
        </List>
      </Flex>
    </Flex>
  ) : null;
};

export default CompletedTasksComponent;

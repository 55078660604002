import styled from '@emotion/styled';

export const ChangeLogTableWrapper = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 1rem;
  }

  th,
  tr,
  td {
    border: 1px solid #dddddd;
    padding: 15px;
    text-align: start;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .width-30 {
    width: 30%;
  }

  .width-50 {
    width: 50%;
  }

  .width-20 {
    width: 20%;
  }

  .margin-right-5 {
    margin-right: 5px;
  }

  .margin-right-10 {
    margin-right: 10px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .status-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 5px;
  }

  .status {
    /* width: 137px; */
    /* height: 33px; */
    /* flex-grow: 0; */
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    /* gap: 10px; */
    padding: 8px 10px;
    border-radius: 7px;
  }

  .old-status {
    background-color: rgba(255, 106, 85, 0.2);
  }

  .new-status {
    background-color: rgba(181, 228, 202, 0.5);
  }

  .status-text {
    /* width: 117px; */
    /* height: 17px; */
    /* flex-grow: 0; */
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    /* font-stretch: normal; */
    /* font-style: normal; */
    /* line-height: normal; */
    /* letter-spacing: -0.28px; */
    /* text-align: left; */
  }

  .strike-through {
    text-decoration: line-through;
  }

  .old-status-text {
    color: #6f767e;
  }

  .new-status-text {
    color: #272b30;
  }

  .user-status {
    img {
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }
  }

  .icon-update {
    width: 40px;
  }
`;

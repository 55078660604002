import React, { FC } from 'react';
// @ts-ignore
import moment from 'moment';
import { Box, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Trans, useTranslation } from 'react-i18next';
import { TrainingData } from '../training.types';

const colorSetHandler = (index: number) => {
  let colorObj = [
    '#8830b0',
    '#30048c',
    '#60b569',
    '#02ade5',
    '#c7c37b',
    '#cc86a9',
    '#8b78e6',
    '#2578ae',
  ];
  return colorObj[index % 8];
};

interface IPNThumbnail {
  index: number;
  title: string;
  thumbnail?: string;
}

export const PathNameThumbnail: FC<IPNThumbnail> = ({
  thumbnail,
  title,
  index,
}) => {
  if (thumbnail) {
    return (
      <Center
        w='62px'
        h='62px'
        borderRadius='8px'
        fontSize='12px'
        fontWeight='900'
      >
        <img
          src={thumbnail}
          style={{
            minWidth: 62,
            height: 62,
            borderRadius: 8,
          }}
          alt='thumbnail'
        />
      </Center>
    );
  }

  return (
    <Center
      style={{
        minWidth: 62,
        height: 62,
        borderRadius: 8,
        backgroundColor: colorSetHandler(index),
      }}
    >
      <span
        style={{
          fontSize: 'xx-large',
          fontWeight: '600',
          color: 'white',
        }}
      >
        {title?.split('')?.[0]?.toUpperCase()}
      </span>
    </Center>
  );
};

interface IProps {
  index: number;
  pathName: string;
  item?: TrainingData;
  thumbnail?: string;
  updatedAt?: string;
  status?: 'active' | 'draft' | 'inactive' | 'archieved';
  onClick?: (data?: TrainingData) => void;
  isFromDeck?: boolean;
  lastUpdatedBy?: {
    name: string;
    // profilePic: string;
    // eid: string;
  };
  visibility?: 'public' | 'private' | 'restricted';
  maxW?: string;
}

const PathName: FC<IProps> = ({
  item,
  index,
  thumbnail,
  pathName,
  updatedAt,
  status,
  onClick,
  isFromDeck,
  lastUpdatedBy,
  visibility,
  maxW,
}) => {
  const { t } = useTranslation(['common', 'training']);
  const title = pathName?.trim();

  const getStyle = () => {
    let style: any = {
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '-0.15px',
      fontWeight: 'bold',
      marginBottom: '4.5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '5px',
    };

    if (maxW) {
      style = { ...style, maxWidth: maxW };
    }

    return style;
  };

  return (
    <Flex
      align='flex-start'
      style={{
        backgroundColor:
          status === 'active' || status === 'draft' ? 'none' : 'white',
        opacity: status === 'active' || status === 'draft' ? '1' : '.5',
      }}
      cursor={
        onClick &&
        (['active', 'draft'].includes(status) ? 'pointer' : 'no-drop')
      }
      onClick={() =>
        ['active', 'draft'].includes(status) ? onClick?.(item) : null
      }
      gap='16px'
    >
      <PathNameThumbnail thumbnail={thumbnail} index={index} title={title} />

      <Box flex={1} overflow='hidden' pr={1}>
        <Box pos='relative' maxWidth='100%' width='max-content'>
          <Tooltip label={title} hasArrow borderRadius='4px'>
            <Box style={getStyle()} pr={5}>
              {title}
            </Box>
          </Tooltip>
          {visibility === 'private' && (
            <Tooltip label={t('private')} hasArrow borderRadius='4px'>
              <Flex
                pos='absolute'
                top={0}
                right='-12px'
                h='full'
                align='center'
              >
                <FontAwesomeIcon icon={faLockKeyhole as IconProp} />
              </Flex>
            </Tooltip>
          )}
        </Box>

        <div
          style={{
            fontSize: '12px',
            fontWeight: '500',
            letterSpacing: '0.112px',
            color: '#6f767e',
          }}
        >
          {isFromDeck ? (
            <div>
              <Trans
                t={t}
                i18nKey='training:last_update_date_time'
                values={{
                  date: moment(updatedAt).format('DD MMM'),
                  time: moment(updatedAt).format('HH:mm:ss'),
                }}
                components={{
                  span: <span />,
                }}
              />
            </div>
          ) : (
            <div>
              <span>
                {t('last_updated_date', {
                  date: moment(updatedAt).format('DD MMM'),
                })}
              </span>
              {lastUpdatedBy?.name && (
                <Text as='div' isTruncated maxWidth='100%'>
                  {t('training:by_user', {
                    name: lastUpdatedBy.name,
                  })}
                </Text>
              )}
            </div>
          )}
          {/*{!isFromDeck && `Last Updated: ${moment(updatedAt).format('DD MMM')}`}*/}
          {/*{isFromDeck && <div>*/}
          {/*  <span>Last Updated: {moment(updatedAt).format('DD MMM')}</span><br />*/}
          {/*  <span>at {moment(updatedAt).format("HH:mm:ss")}</span>*/}
          {/*</div>}*/}
        </div>
      </Box>
    </Flex>
  );
};

export default PathName;

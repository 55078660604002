import React, { FC } from 'react';
import DashboardContainer from '../../sub-components/DashboardContainer';
import EditFormContainer from '../../sub-components/forms/edit-form';

const EditForm: FC = () => {
  return (
    <DashboardContainer>
      <EditFormContainer />
    </DashboardContainer>
  );
};

export default EditForm;

import React, { FC, useMemo } from 'react';
import { Box, Flex, useBoolean } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagramSubtask } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ITaskItem } from '../editor-form/editor.types';

interface IProps {
  subTasks: ITaskItem['steps'];
}

const SubTaskList: FC<IProps> = ({ subTasks = [] }) => {
  const [isOpen, { toggle }] = useBoolean();

  const [tasks, remain] = useMemo(() => {
    if (subTasks.length > 6) {
      return [subTasks.slice(0, 5), subTasks.slice(5)];
    }
    return [subTasks];
  }, [subTasks]);

  if (!subTasks?.length) {
    return null;
  }

  return (
    <Flex flexDir='column' gap={2} mt={2}>
      {tasks?.map((step, index) => (
        <Flex align='start' key={index} gap='10px' color='#6F767E'>
          <ArrowForwardIcon boxSize={4} mt={0.5} />
          <Box fontSize='12px' fontWeight='500' lineHeight='20px'>
            {step.title}
          </Box>
        </Flex>
      ))}

      {isOpen &&
        remain?.map((step, index) => (
          <Flex align='start' key={index} gap='10px' color='#6F767E'>
            <ArrowForwardIcon boxSize={4} mt={0.5} />
            <Box fontSize='12px' fontWeight='500' lineHeight='20px'>
              {step.title}
            </Box>
          </Flex>
        ))}

      {!!remain && (
        <Flex
          align='center'
          gap={2}
          color='#6F767E'
          cursor='pointer'
          onClick={toggle}
          userSelect='none'
          mt={2}
        >
          <FontAwesomeIcon icon={faDiagramSubtask as IconProp} />
          <Box fontSize='12px' fontWeight='500' lineHeight='18px'>
            {isOpen ? 'hide' : 'view'} {remain.length} more subtasks
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default SubTaskList;

import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment-timezone';
import { Trans, useTranslation } from 'react-i18next';

import { TimezoneLocation } from './timezone.graphql';
import { IFormInput } from '../../task.types';
import { convertMinutes, getDueDate, getPublishDate } from './timezone.helper';

const FORMAT = 'MMM DD, hh:mm A (z)';

interface IProps {
  results: TimezoneLocation[];
}

const TimezoneList: FC<IProps> = ({ results = [] }) => {
  const { t } = useTranslation(['task']);
  const { getValues } = useFormContext<IFormInput>();

  const dueDate = useMemo(() => getDueDate(getValues()), []);
  const publishDate = useMemo(() => getPublishDate(getValues()), []);

  return (
    <Flex flexDir='column' overflowY='auto' flex={1}>
      {results?.map((value) => {
        const _due = moment.tz(dueDate?.format('LLL')!, 'LLL', value.timezone);
        const _pub = moment.tz(publishDate, value.timezone);
        // const isSelected = selected.includes(value);

        const timeDiff = _due.diff(_pub, 'minute');

        return (
          <Flex
            key={value.eid}
            padding='0 12px'
            _notLast={{
              '> div': {
                borderBottom: '1px solid #EAEBED',
              },
            }}
            _hover={{
              background: 'gray.100',
            }}
          >
            <Flex flex={1} flexDir='column' gap='4px' paddingY='10px'>
              <Flex color='#1A1D1F' fontSize='14px' gap='6px'>
                <Box flex={1} noOfLines={1} fontWeight='600'>
                  {value.name} -
                </Box>

                <Box color='#33383F' fontSize='12px'>
                  Due on {_due.format(FORMAT)}
                </Box>
              </Flex>
              <Box color='#6F767E' fontSize='11px' noOfLines={1}>
                Published at {_pub.format(FORMAT)}
              </Box>
              {timeDiff > 30 ? (
                <Box color='#272B30' fontSize='11px' noOfLines={1}>
                  <Trans
                    t={t}
                    i18nKey='task:getTimeToCompTask'
                    values={{
                      name: value.name,
                      time: convertMinutes(timeDiff),
                    }}
                    components={{
                      span: <Box as='span' color='#2A85FF' fontWeight='600' />,
                    }}
                  />
                </Box>
              ) : (
                <Box color='#FF6A55' fontSize='11px' noOfLines={1}>
                  <Trans
                    t={t}
                    i18nKey='task:hasNoTimeToCompTask'
                    values={{
                      name: value.name,
                    }}
                  />
                </Box>
              )}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default TimezoneList;

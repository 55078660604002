import React, { FC } from 'react';
import { Box, Button, Center } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { toArray } from '../../../../../utils/utils';
import { FILENAME_REGEX } from '../../../../../utils/constants';

import { getFileType } from '../../../../Chapters/CreateChapterModal';
import { SopDetailResponse } from '../../../../Chapters/ChapterView/chapter.graphql';
import ChapterFileView from '../../../../Chapters/ChapterView/ChapterFileView';

interface IProps {
  sopData?: SopDetailResponse['SopById'];
  isNotDesktop?: boolean;
}

const ChapterFilePreview: FC<IProps> = ({ sopData, isNotDesktop }) => {
  const file = toArray(sopData?.files)[0];

  if (getFileType(file?.mimetype!) === 'pdf') {
    return <ChapterFileView sopData={sopData} />;
  }

  if (isNotDesktop) {
    return (
      <Center
        overflow='hidden'
        height='100%'
        display='flex !important'
        flexDir='column'
        gap={3}
      >
        <Box
          color='blue.500'
          userSelect='none'
          fontSize='16px'
          fontWeight='600'
        >
          {file?.url?.split(FILENAME_REGEX).pop()}
        </Box>

        <Box isTruncated fontWeight='600'>
          Preview not available for this file
        </Box>

        <FontAwesomeIcon
          icon={faCloudArrowDown as IconProp}
          size='3x'
          color='#90cdf4'
        />

        <Button
          as='a'
          href={file?.url}
          download='download file'
          px='20px'
          fontSize='14px'
          textDecoration='none !important'
        >
          Click here to download
        </Button>
      </Center>
    );
  } else {
    return <ChapterFileView sopData={sopData} />;
  }
};

export default ChapterFilePreview;

import React, { FC } from 'react';
import { Center, Text } from '@chakra-ui/react';
import QRCode from 'qrcode.react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { IFormInput } from './invite.types';

interface IProps {}

const ScanQrCode: FC<IProps> = () => {
  const { t } = useTranslation(['invite']);
  const { getValues } = useFormContext<IFormInput>();

  return (
    <Center flex={1} flexDir='column' gap='8px'>
      <Text mb={4} color='#6F767E'>
        <Trans
          t={t}
          i18nKey='invite:askUserToScanQr'
          values={{
            name: getValues('name'),
          }}
        />
      </Text>
      <QRCode
        value={getValues('inviteUrl')!}
        style={{ width: '180px', height: '180px' }}
      />
    </Center>
  );
};

export default ScanQrCode;

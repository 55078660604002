import { Box, Flex, HStack, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  userData: any;
}

const Heading: FC<IProps> = (props) => {
  const { userData } = props;
  const { t } = useTranslation('home');
  return (
    <HStack align='center' justify='space-between' w='full' mb={4}>
      <VStack w='80%' align='flex-start'>
        <Flex className='home-name-container'>
          <span>
            {t('welcome')} {userData?.name}!
          </span>{' '}
        </Flex>
        <Box fontWeight='500' color='#6f767e' mb='10px'>
          {t('what_want_today')}
        </Box>
      </VStack>
    </HStack>
  );
};

export default Heading;

import React, { useEffect, useState, ChangeEvent, useMemo } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Checkbox,
  Box,
  Flex,
} from '@chakra-ui/react';
import { Col, Row } from 'antd';
import { List } from '@chakra-ui/react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import SearchInput from '../../atoms/SearchInput';
import PrimaryButton from '../../atoms/PrimaryButton';
import AddSupervisorListItem from '../../sub-components/Supervisor/AddSupervisorListItem';
// @ts-ignore
import { UserDescendants } from 'sop-commons/Query/Sop';
import { SUPER_ADMIN, ADMIN, MANAGER } from '../../utils/userRoles';
import Loader from '../Loader';
import { userObj } from 'sop-commons/src/client/clientFactory';
import { TrainingDraftMethods } from 'pages/TrainingDraft/training-draft-methods/TrainingDraftMethods';
import { MemberData } from 'ui-components/Members/members.types';
import { PublishState } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/AssignPublish';
import { ICreateChangeLogChangesEntity } from 'pages/TrainingDraft/training-draft-types/training-draft-types';

const AddSupervisorModal = ({
  open,
  onClose,
  trainingData,
  isAdd,
  addedData,
  selectedSupervisorData,
  checkedData,
  savedPublishData,
  dataUpdation,
  setDataUpdation,
}: any) => {
  const { t } = useTranslation(['common', 'training']);
  const [allChecked, setAllChecked] = useState(false);
  const [existingSupervisors, setExistingSupervisors] = useState(
    trainingData?.supervisors || []
  );
  const [search, setNewSearch] = useState('');
  const [supervisorList, setSupervisorList] = useState<any>([]);
  const userObject = useReactiveVar(userObj);
  const [temporaryCheckedSupervisors, setTemporaryCheckedSupervisors] =
    useState<string[]>([]);
  const [temporaryUnCheckedSupervisors, setTemporaryUnCheckedSupervisors] =
    useState<string[]>([]);

  const [getUsersData, { loading }] = useLazyQuery(UserDescendants, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _userData = JSON.parse(JSON.stringify(data?.userDescendants));
      // let filteredData = savedPublishData?.assigneeData?.filter((o: any)=> !_userData?.find((i: any)=> i.eid === o.eid));
      _userData = _userData?.filter((data: any) => {
        return (
          (data.authRole === SUPER_ADMIN ||
            data.authRole === ADMIN ||
            data.authRole === MANAGER) &&
          userObject?.eid !== data.eid
        );
      });

      let filteredData;
      if (isAdd) {
        filteredData = _userData?.filter(
          (o: any) =>
            !savedPublishData?.assigneeData?.find(
              (i: any) => i?.eid === o?.eid || i === o?.eid
            )
        );
      } else {
        filteredData = _userData?.filter(
          (o: any) =>
            !trainingData?.assignedToUsers?.find((i: any) => i?.eid === o?.eid)
        );
      }
      filteredData.forEach((d: any) => (d.checked = false));
      if (isAdd) {
        if (addedData.length > 0) {
          filteredData.forEach((d: any) => {
            addedData.forEach((assignee: any) => {
              if (d?.eid === assignee?.eid) {
                d.checked = true;
              }
            });
          });
        }
      } else {
        filteredData.forEach((d: any) => {
          selectedSupervisorData?.forEach((assignee: any) => {
            if (d?.eid === assignee?.eid) {
              d.checked = true;
            }
          });
        });
      }
      setAllChecked(!filteredData.some((val: any) => !val.checked));
      setSupervisorList(filteredData);
    },
  });

  useEffect(() => {
    getUsersData({
      variables: {
        sort: 'NAME_ASC',
        status: 'active',
      },
    });
  }, []);

  const onCheckChange = (itemId: string) => {
    if (isAdd) {
      let _supervisorList = JSON.parse(JSON.stringify(supervisorList));
      _supervisorList.forEach((list: any) => {
        if (list?.eid === itemId) {
          list.checked = !list?.checked;
          if (list.checked) {
            setTemporaryCheckedSupervisors([
              ...temporaryCheckedSupervisors,
              itemId,
            ]);
          } else {
            setTemporaryUnCheckedSupervisors([
              ...temporaryCheckedSupervisors,
              itemId,
            ]);
          }
          // TrainingDraftMethods.supervisorAddRemoveHandler(setDataUpdation, dataUpdation, itemId, list.checked);
        }
      });
      setSupervisorList(_supervisorList);
      let filteredAssignees = _supervisorList.filter(
        (list: any) => list.checked
      );
      setExistingSupervisors(filteredAssignees);
    } else {
      let _supervisorList = JSON.parse(JSON.stringify(supervisorList));
      _supervisorList.forEach((list: any) => {
        if (list?.eid === itemId) {
          list.checked = !list?.checked;
        }
      });
      setSupervisorList(_supervisorList);
    }
    // if (existingSupervisors.filter((data: any) => data === itemId).length > 0) {
    //   const list = existingSupervisors.filter((item: any) => item !== itemId);
    //   setExistingSupervisors(list);
    // } else {
    //   const list = [...existingSupervisors, itemId];
    //   setExistingSupervisors(list);
    // }
  };

  const updateSupervisors = () => {
    let _selectedSupervisorList = supervisorList?.filter(
      (list: any) => list?.checked
    );
    checkedData(_selectedSupervisorList);
    onClose('refetch');
  };

  const addSupervisors = (existingSupervisors: any[]) => {
    selectedSupervisorData(existingSupervisors);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewSearch(e.target.value);
  };

  const filteredRoles = useMemo(() => {
    if (search) {
      const reg = new RegExp(search, 'gi');
      return (
        supervisorList?.filter((value: any) => {
          return (
            value.name?.match(reg) ||
            value.authRole?.match(reg) ||
            value.role?.match(reg) ||
            value?.locations?.[0]?.name?.match(reg)
          );
        }) || []
      );
    }
    return supervisorList;
  }, [supervisorList, search]);

  const onCheckAllPress = (event: ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
    const keys: string[] = filteredRoles?.map((val: any) => val.eid) || [];
    let _supervisorList = JSON.parse(JSON.stringify(supervisorList));
    _supervisorList?.forEach((list: any) => {
      if (keys.includes(list.eid)) {
        list.checked = event.target.checked;
      }
    });
    setSupervisorList(_supervisorList);
    if (isAdd) {
      setExistingSupervisors(
        _supervisorList?.filter((item: any) => item?.checked)
      );
    }
  };

  const [allItemChecked, isIndeterminate] = useMemo(() => {
    const all = filteredRoles?.every((val: any) => val.checked);
    const some = filteredRoles?.some((val: any) => val.checked);
    return [all && !!filteredRoles?.length, some && !all];
  }, [filteredRoles]);

  return (
    <Modal
      isOpen={open}
      onClose={() => onClose('close')}
      closeOnOverlayClick={false}
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent
        width={{
          sm: '340px',
          md: '600px',
          base: '600px',
          xl: '600px',
          ['2xl']: '600px',
        }}
        minWidth={{
          sm: '340px',
          md: '702px',
          base: '702px',
          xl: '702px',
          ['2xl']: '600px',
        }}
      >
        <ModalHeader style={{ marginTop: 20 }}>
          <div>
            <Row>
              <Col
                style={{
                  width: '16.02px',
                  height: '32.04px',
                  background: '#cabdff',
                  borderRadius: '4.00549px',
                }}
              />
              <Col
                style={{
                  fontWeight: '600',
                  fontSize: '20px',
                  lineHeight: '32px',
                  letterSpacing: '-0.02em',
                  color: '#1a1d1f',
                  marginLeft: '16px',
                }}
              >
                {isAdd
                  ? t('training:supervisor_table_heading')
                  : t('training:editSupervisors')}
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: 24 }}>
            <SearchInput
              size='md'
              width='100%'
              hideShortcuts={true}
              placeholder={t('training:searchMemberRole')}
              disabled={true}
              onChange={handleSearchChange}
            />
          </div>
        </ModalHeader>
        <ModalCloseButton
          style={{
            marginTop: 30,
            marginRight: 20,
            outline: 'none',
            boxShadow: 'none',
          }}
        />
        <ModalBody>
          {loading && (
            <div className='sop-list-loader'>
              <Loader />
            </div>
          )}

          {!loading && (
            <div style={{ paddingRight: 8 }}>
              <Flex justifyContent='flex-end' mb='16px'>
                <Checkbox
                  isChecked={allChecked && allItemChecked}
                  isIndeterminate={allChecked && isIndeterminate}
                  flexDirection='row-reverse'
                  onChange={onCheckAllPress}
                >
                  <Box
                    pr='6px'
                    fontSize='14px'
                    fontWeight='700'
                    color='#1A1D1F'
                  >
                    {t('common:select_all')}
                  </Box>
                </Checkbox>
              </Flex>
              {filteredRoles.length > 0 ? (
                <List spacing={3}>
                  {filteredRoles.map((item: any) => {
                    return (
                      <AddSupervisorListItem
                        item={item}
                        key={item.eid}
                        existingSupervisors={existingSupervisors}
                        onCheckChange={onCheckChange}
                      />
                    );
                  })}
                </List>
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  {t('common:no_data_found')}
                </div>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <PrimaryButton
            type='button'
            size='lg'
            title={t('common:cancel')}
            onClick={() => {
              onClose('close');
              setTemporaryCheckedSupervisors([]);
              setTemporaryUnCheckedSupervisors([]);
            }}
            style={{
              padding: '0px 37px',
              marginRight: 20,
              maxWidth: '146px',
              // borderTopLeftRadius
            }}
            variant='outline'
            className='profile-button-update-container'
          />
          <PrimaryButton
            type='submit'
            size='lg'
            title={t(isAdd ? 'common:add' : 'common:update')}
            onClick={() => {
              isAdd ? addSupervisors(existingSupervisors) : updateSupervisors();
            }}
            style={{ maxWidth: '159px' }}
            variant='solid'
            colorScheme='blue'
            className='profile-button-save-container'
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSupervisorModal;

import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { ISessionByIdForMyTasks } from 'pages/TasksDashboard/MyTasks/services/my-tasks-types';

interface IProps {
  data: ISessionByIdForMyTasks | undefined;
}

const Heading: FC<IProps> = ({ data }) => {
  return (
    <Flex id='my-task-details-heading-1' flexDir='column' px={6}>
      <Text
        id='my-task-details-heading-text-1'
        fontWeight={600}
        fontSize='18px'
      >
        {data?.SessionById?.title}
      </Text>
      <Text
        id='my-task-details-heading-text-2'
        fontWeight={400}
        fontSize='14px'
        color='#6F767E'
      >
        {data?.SessionById?.task?.description}
      </Text>
    </Flex>
  );
};

export default Heading;

import React, { FC } from 'react';
import moment from 'moment';
import { Flex } from '@chakra-ui/react';

interface IProps {
  date: string;
}

const UserDateComponent: FC<IProps> = ({ date }) => {
  return (
    <Flex
      flexDir='column'
      id='user-date-component'
      minW='100px'
      maxW='300px'
      mr='1rem'
      isTruncated
    >
      <span>{moment(date).format('DD MMM YYYY')}</span>
      <span style={{ fontWeight: '400', fontSize: '12px', color: '#8D949C' }}>
        {moment(date).format('hh:mm A')}
      </span>
    </Flex>
  );
};

export default UserDateComponent;

import React, { FC } from 'react';
import MilestoneModal from 'pages/Training/CreateTrainingPath/CreateContainer/AddStructure/CreateTraining/MilestoneModal';
import { IMilestoneModalClose } from '.';
import { milestoneModalCloseHandler } from './helper';
import { UserData } from 'sop-commons/src/client';
import CardEditorModal from 'sub-components/CardEditorModal';
import { cardJsonFormat } from 'utils';
import { IPowerUpModalTypes } from 'pages/Training/PowerUpModal/power-up.types';
import UpdateDeckAddCards from 'pages/CreateDeck/UpdateDeckAddCards';
import { useTranslation } from 'react-i18next';
import { CardType } from 'types';
import CustomPathCarousel from 'sub-components/AddNewPathModal/CustomPathCarousel';
import PublishChangeDiffCheckModal from 'pages/Training/PublishChangeDiffCheckModal';
import KnowledgeBaseDrawer from './KnowledgeBaseDrawer';

interface IProps {
  isMilestoneModalOpen: boolean;
  setIsMilestoneModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  milestoneType: string;
  userData: UserData;
  trainingData: any;
  contentById: any;
  setTrainingData: React.Dispatch<any>;
  setContentById: React.Dispatch<any>;
  setCheckEditCardOrMilestone: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMilestone: any;
  editMilestoneData: never[];
  editCardData: any;
  isCardModalOpened: boolean;
  closeCardModal: () => void;
  returnedCardDataHandler: (data: any) => void;
  actionCardModalOpened: boolean;
  closeActionCardModal: () => void;
  selectedCardType: IPowerUpModalTypes['types'];
  showSearchModal: boolean;
  closeUpdateDeckAddModalHandler: () => void;
  cardsToBeAddedHandler: (cards: any) => void;
  previewHandler: (_data: any) => void;
  onCardEditSuccess: (_data: any) => Promise<void>;
  onCardDelete: () => void;
  trainingProgressModal: boolean;
  closeModalHandler: () => void;
  getCustomTrainingDataHandler: () => any;
  isPublishChangeDiffCheckModalOpen: boolean;
  showKnowledgeBaseDrawer: boolean;
  setShowKnowledgeBaseDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openPowerupPreviewModal: boolean;
  setOpenPowerupPreviewModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditTrainingModals: FC<IProps> = ({
  isMilestoneModalOpen,
  setIsMilestoneModalOpen,
  milestoneType,
  userData,
  trainingData,
  contentById,
  setTrainingData,
  setContentById,
  setCheckEditCardOrMilestone,
  selectedMilestone,
  editMilestoneData,
  editCardData,
  isCardModalOpened,
  closeCardModal,
  returnedCardDataHandler,
  actionCardModalOpened,
  closeActionCardModal,
  selectedCardType,
  showSearchModal,
  closeUpdateDeckAddModalHandler,
  cardsToBeAddedHandler,
  previewHandler,
  onCardEditSuccess,
  onCardDelete,
  trainingProgressModal,
  closeModalHandler,
  getCustomTrainingDataHandler,
  isPublishChangeDiffCheckModalOpen,
  showKnowledgeBaseDrawer,
  setShowKnowledgeBaseDrawer,
  openPowerupPreviewModal,
  setOpenPowerupPreviewModal,
}) => {
  const { t } = useTranslation(['training', 'common']);
  const milestoneModalCloseHelperFn = (
    type: IMilestoneModalClose,
    data?: any
  ) => {
    milestoneModalCloseHandler(
      type,
      setIsMilestoneModalOpen,
      milestoneType,
      userData,
      trainingData,
      contentById,
      setTrainingData,
      setContentById,
      setCheckEditCardOrMilestone,
      selectedMilestone,
      data
    );
  };

  return (
    <>
      {isMilestoneModalOpen && (
        <MilestoneModal
          isOpen={isMilestoneModalOpen}
          onClose={milestoneModalCloseHelperFn}
          editMilestoneData={editMilestoneData}
          isFromEdit={true}
        />
      )}
      {/* {actionCardModalOpened && (
        <CardEditorModal
          open={actionCardModalOpened}
          onClose={closeActionCardModal}
          // onSubmit={onCardSuccess}
          isDeckView={false}
          returnedCardData={returnedCardDataHandler}
          fromTraining
        />
      )} */}
      {((Array.isArray(editCardData) && editCardData?.length === 0) ||
        Object.keys(editCardData).length === 0) &&
        isCardModalOpened && (
          <CardEditorModal
            open={isCardModalOpened}
            onClose={closeCardModal}
            // onSubmit={onCardSuccess}
            isDeckView={false}
            otherDefaultCardType={'quiz'}
            otherDefaultCardInitialData={cardJsonFormat(
              'quiz',
              'single-choice',
              userData?.entity?.eid,
              '#ffffff',
              null,
              null,
              undefined
            )}
            returnedCardData={returnedCardDataHandler}
            fromTraining
          />
        )}
      {actionCardModalOpened && (
        <CardEditorModal
          open={actionCardModalOpened}
          onClose={closeActionCardModal}
          isDeckView={false}
          returnedCardData={returnedCardDataHandler}
          fromTraining
          isHide
          passedCardType={selectedCardType}
        />
      )}
      {showSearchModal && (
        <UpdateDeckAddCards
          isOpen={showSearchModal}
          onClose={closeUpdateDeckAddModalHandler}
          selectedCards={trainingData?.contentDetails}
          cardsToBeAdded={cardsToBeAddedHandler}
          previewHandler={previewHandler}
          title={t('training:search_item_handbook')}
          searchPlaceholder={t('training:search_created_deck')}
          buttonText={t('common:add')}
          showCategory
          includeDecks
        />
      )}
      {editCardData.length > 0 && isCardModalOpened && (
        <CardEditorModal
          open={isCardModalOpened}
          onClose={closeCardModal}
          isEdit={true}
          onSubmit={onCardEditSuccess}
          onCardDelete={onCardDelete}
          cardId={editCardData?.[0]?.eid}
          disabledLayout={[CardType.Quiz]}
          fromTraining
        />
      )}
      {trainingProgressModal && (
        <CustomPathCarousel
          enablePreview={true}
          isOpen={trainingProgressModal}
          onClose={closeModalHandler}
          selectedTrainingData={getCustomTrainingDataHandler()}
        />
      )}
      {/* {isPublishChangeDiffCheckModalOpen && (
        <PublishChangeDiffCheckModal
          open={isPublishChangeDiffCheckModalOpen}
          onClose={(type: 'cancel' | 'publish' | 'continue') =>
            publishChangeDiffCheckModalCloseHandler(type)
          }
          changedData={changedData}
          trainingData={trainingData}
          initialTrainingData={initialTrainingData}
          thirdStepData={thirdStepData}
        />
      )} */}
      {showKnowledgeBaseDrawer && (
        <KnowledgeBaseDrawer
          isOpen={showKnowledgeBaseDrawer}
          onClose={() => setShowKnowledgeBaseDrawer(false)}
          trainingData={trainingData}
          setTrainingData={setTrainingData}
        />
      )}
      {openPowerupPreviewModal && editCardData?.cardType !== 'formCard' && (
        <CardEditorModal
          open={openPowerupPreviewModal}
          onClose={() => setOpenPowerupPreviewModal(false)}
          isEdit={true}
          isHide
          onSubmit={onCardEditSuccess}
          onCardDelete={onCardDelete}
          cardId={editCardData?.eid}
          isReadOnly
        />
      )}
      {/* <FormProvider {...methods}>
        <PreviewModal
          open={showPreview}
          onClose={() => setShowPreview(false)}
        />
      </FormProvider> */}
    </>
  );
};

EditTrainingModals.displayName = 'EditTraining/EditTrainingModals';

export default EditTrainingModals;

import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';

import { UseConfirm, useConfirm } from '../../../../ui-components';
import { FormCategory } from '../../../../types';

import { DeleteContextProvider } from './context';
import DeleteCategoryTitle from './DeleteCategoryTitle';
import ModalContent from './ModalContent';
import { getCategoryFormCount } from './delete.query-helper';

interface IProps {
  category: FormCategory;
  onCategoryDeleted?: () => Promise<void>;
}

type DeleteFormCategory = (props: IProps) => void | Promise<void>;

export const useDeleteFormCategory = (): DeleteFormCategory => {
  const apolloClient = useApolloClient();
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(async ({ category, onCategoryDeleted }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    const count = await getCategoryFormCount(apolloClient, category.eid);

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>
            <DeleteContextProvider
              formCount={count}
              category={category}
              onClose={confirmRef.current?.destroy}
              onCategoryDeleted={onCategoryDeleted}
            >
              {children}
            </DeleteContextProvider>
          </ApolloProvider>
        );
      },

      title: <DeleteCategoryTitle />,
      content: <ModalContent />,
      isCentered: true,
      footer: null,
      contentProps: {
        minWidth: '560px',
        borderRadius: '10px',
        paddingBlock: '12px',
        maxHeight: 'calc(100vh - 6rem)',
        marginY: '3rem',
      },
      bodyProps: {
        display: 'flex',
        overflow: 'hidden',
      },
    });
  }, []);
};

import React, { FC, useState } from 'react';
import {
  Box,
  CloseButton,
  Flex,
  Grid,
  PopoverBody,
  PopoverHeader,
} from '@chakra-ui/react';
import { useApolloClient } from '@apollo/client';

import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { UserSignature } from '../../../../../types';
import { FormInput } from '../../../../../atoms';
import { useUserData } from '../../../../../hooks';
import { signatureConfigs } from '../../../../../configs';
import { ActionButton } from '../../../../../ui-components';

import { BaseSignature } from './Signature';
import { updateSignatures } from './signature.helper';

interface IProps {
  onChange?: (sign: UserSignature) => void;
  close?: () => void;
  isPreview?: boolean;
}

const CreateSignature: FC<IProps> = ({ onChange, close, isPreview }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedFont, setSelectFont] = useState<string>();

  const userId = useUserData().eid;

  const client = useApolloClient();

  const trimValue = inputValue?.trim();

  const simulate = <T extends unknown>(data: T) => {
    return new Promise<T>((resolve) => {
      setTimeout(resolve, 3000, data);
    });
  };

  const onSubmit = async () => {
    if (isPreview) {
      const tempSign = await simulate({
        font: selectedFont!,
        text: trimValue!,
      });
      onChange?.(tempSign);
    } else {
      const newSign = await updateSignatures(client, userId, {
        font: selectedFont!,
        text: trimValue!,
      });

      onChange?.(newSign);
    }
  };

  return (
    <React.Fragment>
      <PopoverHeader borderBottom='none'>
        <Flex justify='space-between' align='center'>
          <Box fontSize='16px' fontWeight='600' color='#1A1D1F'>
            Create Signature
          </Box>
          <CloseButton size='sm' onClick={close} />
        </Flex>
      </PopoverHeader>

      <PopoverBody padding='12px 16px'>
        <FormInput
          leftIcon={faUser as IconProp}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />

        <Grid
          templateColumns='repeat(2, 1fr)'
          gap='6px'
          my={4}
          alignItems='center'
        >
          {signatureConfigs.map((sign, index) => (
            <BaseSignature
              key={index}
              isSelected={sign.fontFamily === selectedFont}
              onClick={() => setSelectFont(sign.fontFamily)}
              signConfig={sign}
            >
              {trimValue || 'Delightree'}
            </BaseSignature>
          ))}
        </Grid>

        <ActionButton
          w='full'
          fontSize='14px'
          fontWeight='600'
          variant='solid'
          colorScheme='blue'
          actionFn={onSubmit}
          isDisabled={!trimValue || !selectedFont}
        >
          Create and sign
        </ActionButton>
      </PopoverBody>
    </React.Fragment>
  );
};

export default CreateSignature;

import gql from "graphql-tag";

//AUTH RELATED MUTATIONS
export const getAllSops = gql`
  query Query(
    $page: Int
    $perPage: Int
    $filter: FilterFindManySopInput
    $sort: SortFindManySopInput
  ) {
    SopPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        chapter
        content
        raw_content
        lastUpdated
        createdBy {
          email
          name
          phone
          profilePic
          role
        }
        lastViewed
        thumbnail
        thumbnail_color
        entityDetails {
          name
          address
          profilePic
          isDemo
          roles {
            name
            status
          }
          bundles {
            name
            status
          }
          category {
            name
            status
          }
          updatedAt
          createdAt
          eid
        }
        status
        roles
        bundles
        category
        sharedDetails {
          email
          userId
        }
        updatedAt
        createdAt
        eid
        entity
        meta
        views {
          sopId
          userId
          viewCount
          bookmark
          updatedAt
          createdAt
          eid
        }
        viewCount
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const getMySops = gql`
  query SopHandbook($input: SopHandBookInput) {
    SopHandbook(input: $input) {
      chapter
      content
      raw_content
      createdBy {
        email
        name
        phone
        profilePic
      }
      lastUpdated
      entity
      status
      roles
      bundles
      viewCount
      lastViewed
      category
      sharedDetails {
        email
        userId
      }
      thumbnail
      thumbnail_color
      updatedAt
      createdAt
      eid
      entityDetails {
        name
        address
        profilePic
        bundles {
          name
          status
          color
        }
        roles {
          name
          status
          color
        }
        isDemo
        category {
          name
          description
          status
          icon
        }
        onboarded
        updatedAt
        createdAt
        eid
      }
      meta
      views {
        sopId
        userId
        viewCount
        bookmark
        updatedAt
        createdAt
        eid
        bookmarkedAt
      }
    }
  }
`;

export const getSopById = gql`
  query SopById($eid: String!) {
    SopById(eid: $eid) {
      chapter
      content
      lastUpdated
      createdBy {
        email
        name
        phone
        profilePic
        updatedAt
        createdAt
        role
        onboarded
      }
      entity
      status
      roles
      bundles
      viewCount
      lastViewed
      category
      sharedDetails {
        email
        userId
      }
      thumbnail
      thumbnail_color
      updatedAt
      createdAt
      eid
      entityDetails {
        name
        address
        profilePic
        isDemo
        roles {
          name
          status
          color
        }
        bundles {
          name
          status
          color
        }
        category {
          name
          description
          status
          icon
        }
        onboarded
        updatedAt
        createdAt
        eid
      }
      meta
      views {
        sopId
        userId
        viewCount
        bookmark
        updatedAt
        createdAt
        eid
      }
    }
  }
`;
export const markSopSeen = gql`
  mutation SetSopView($sopId: String!) {
    setSopView(sopId: $sopId) {
      viewCount
    }
  }
`;

export const sopBookmark = gql`
  mutation SetSopBookmark($sopId: String!, $bookmark: Boolean!) {
    setSopBookmark(sopId: $sopId, bookmark: $bookmark) {
      sopId
      bookmark
    }
  }
`;

export const recentlyViewedSop = gql`
  query RecentSop($limit: Int) {
    RecentSop(limit: $limit) {
      chapter
      content
      raw_content
      entity
      status
      roles
      bundles
      viewCount
      lastUpdated
      lastViewed
      category
      thumbnail
      thumbnail_color
      updatedAt
      createdAt
      eid
      createdBy {
        name
        email
        phone
      }
      entityDetails {
        name
        address
        profilePic
        isDemo
        bundles {
          name
          status
          color
        }
        roles {
          name
          status
          color
        }
        category {
          name
          description
          status
          icon
        }
        onboarded
        updatedAt
        createdAt
        eid
        activeRoles
        activeBundles
        activeCategories
      }
      meta
      views {
        sopId
        userId
        entityId
        viewCount
        bookmark
        bookmarkedAt
        updatedAt
        createdAt
        eid
      }
    }
  }
`;

export const getSopBundlePagination = gql`
  query SopBundlePagination($page: Int!) {
    SopBundlePagination(page: $page) {
      items {
        bundle
        hasMore
        sop {
          chapter
          lastUpdated
          lastViewed
          thumbnail
          thumbnail_color
          category
          eid
          views {
            bookmark
          }
          content
          viewCount
          createdBy {
            name
            role
          }
          meta
          updatedAt
        }
      }
      hasMore
    }
  }
`;

export const getCardsData = gql`
  query CardsPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyCardInput
    $sort: SortFindManyCardInput
  ) {
    cardsPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        media {
          playbackId
          assetId
          rawUrl
          type
          mediaUrl
          thumbnail
          status
          meta
          _id
        }
        cardType
        title
        thumbnail
        backgroundColor
        layout
        subTitle
        createdBy
        category
        entityId
        content
        raw_content
        status
        views
        updatedAt
        createdAt
        eid
        form {
          eid
          title
          thumbnail
        }
        translations
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const handbookPagination = gql`
  query HandbookPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyhandbookInput
    $sort: SortFindManyhandbookInput
  ) {
    handbookPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        cardType
        title
        thumbnail
        backgroundColor
        layout
        subTitle
        createdBy
        entityId
        content
        raw_content
        status
        views
        createdAt
        updatedAt
        lastUpdated
        type
        eid

        cards {
          cardType
          title
          thumbnail
          backgroundColor
          layout
          subTitle
          category
          createdBy
          entityId
          content
          raw_content
          status
          views
          updatedAt
          createdAt
          eid
          media {
            thumbnail
            mediaUrl
          }
        }
        category
      }
    }
  }
`;

export const getDeckById = gql`
  query FindDeckById($eid: String!) {
    findDeckById(eid: $eid) {
      title
      entityId
      createdBy
      category
      description
      thumbnail
      visibility
      status
      assignedTo {
        progress
        status
        userId
      }
      cards {
        form {
          eid
          title
        }
        media {
          playbackId
          assetId
          rawUrl
          type
          mediaUrl
          thumbnail
          status
          meta
          _id
        }
        cardType
        title
        thumbnail
        backgroundColor
        layout
        subTitle
        createdBy
        entityId
        content
        raw_content
        status
        views
        updatedAt
        createdAt
        eid
        lastViewed
        lastUpdated
        media {
          thumbnail
          mediaUrl
        }
        decks {
          title
          eid
        }
        category
        formId
        form {
          eid
          title
          category
          thumbnail
          description
          questions
        }
        translations
      }
      visibleTo {
        users
        locations
        roles
        condition
        _id
      }
      updatedAt
      eid
      createdAt
      trainings {
        title
      }
    }
  }
`;

export const getHomePageData = gql`
  query UserHomepageNew {
    userHomepageNew {
      recent {
        cardType
        title
        eid
        type
        thumbnail
        updatedAt
        lastUpdated
        subTitle
        content
        category
        cards {
          cardType
          title
        }
        status
      }
      userTrainings {
        title
        entityId
        status
        thumbnail
        thumbnailColor
        description
        contents {
          type
          eid
          title
        }
        visibility
        supervisors
        assignedTo
        updatedAt
        createdAt
        eid
        userProgress
        trainingContentList
      }
      resumeTrainings {
        userProgress
        trainingContentList
        title
        entityId
        status
        thumbnail
        thumbnailColor
        description
        contents {
          type
          eid
          title
        }
        visibility
        supervisors
        assignedTo
        updatedAt
        createdAt
        eid
        externalUserProgress
        externalUsers {
          eid
          name
        }
      }
    }
  }
`;

export const getBookmarkedCard = gql`
  query BookmarkedCard {
    bookmarkedCard {
      cardType
      title
      thumbnail
      backgroundColor
      layout
      subTitle
      category
      createdBy
      entityId
      content
      raw_content
      status
      views
      lastViewed
      media {
        playbackId
        assetId
        type
        rawUrl
        mediaUrl
        thumbnail
        status
        meta
        _id
      }
      updatedAt
      createdAt
      eid
    }
  }
`;

export const CardBookmark = gql`
  mutation SetCardBookmark($eid: String!, $bookmark: Boolean!) {
    setCardBookmark(eid: $eid, bookmark: $bookmark) {
      cardId
      userId
      viewCount
      bookmark
      bookmarkedAt
      lastViewedAt
      updatedAt
      createdAt
      eid
    }
  }
`;

export const CreateDeckView = gql`
  mutation CreateDeckViews($deckId: String!) {
    CreateDeckViews(deckId: $deckId) {
      userId
      deckId
      viewedCardIds
      lastViewed
      updatedAt
      createdAt
      eid
    }
  }
`;

export const AddDeckView = gql`
  mutation AddDeckViews($viewId: String!, $cardId: String!) {
    AddDeckViews(viewId: $viewId, cardId: $cardId) {
      userId
      deckId
      eid
      lastViewed
      viewedCardIds
    }
  }
`;

export const UpdateCardViewCount = gql`
  mutation SetCardView($eid: String!) {
    setCardView(eid: $eid) {
      title
      thumbnail
      entityId
      views
      lastViewed
      eid
    }
  }
`;

export const getLastViewedDeckData = gql`
  query DeckViewById($deckId: String!) {
    DeckViewById(deckId: $deckId) {
      userId
      deckId
      viewedCardIds
      lastViewed
      eid
    }
  }
`;

export const handbookMeta = gql`
  query HandbookMeta {
    handbookMeta
  }
`;

export const userTraining = gql`
  query userTraining(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyTrainingInput
    $sort: SortFindManyTrainingInput
  ) {
    userTrainingNew(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        title
        contents {
          type
          eid
          title
        }
        eid
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const UpdateTraining = gql`
  mutation UpdateTraining($input: UpdateTrainingInput) {
    UpdateTraining(input: $input) {
      contents {
        type
        eid
        title
      }
      eid
    }
  }
`;

export const TrainingById = gql`
  query TrainingById($eid: String!) {
    trainingById(eid: $eid) {
      title
      contentDetails
    }
  }
`;

export const DescendantUsers = gql`
  query DescendantUsers {
    branch {
      descendantUsers {
        name
        eid
        type
        role
        authRole
        email
        createdAt
        status
        isInvited
        inviteLocation
        profilePic
        locations {
          name
        }
      }
    }
  }
`;

export const UpdateTrainingSupervisor = gql`
  mutation UpdateTraining($input: UpdateTrainingInput) {
    UpdateTraining(input: $input) {
      eid
      supervisors
    }
  }
`;

export const UpdateTrainingAssignee = gql`
  mutation UpdateTraining($input: UpdateTrainingInput) {
    UpdateTraining(input: $input) {
      eid
      assignedTo
    }
  }
`;

export const UserDescendants = gql`
  query UserDescendants(
    $sort: teamsSort
    $status: userStatusType
    $query: String
  ) {
    userDescendants(sort: $sort, status: $status, query: $query) {
      name
      eid
      type
      role
      authRole
      profilePic
      locations {
        name
      }
    }
  }
`;

export const GET_USER_ACTIVITY = gql`
  query UserActivity(
    $page: Int
    $perPage: Int
    $sort: SortFindManyLogInput
    $filter: FilterFindManyLogInput
  ) {
    userActivity(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
      count
      items {
        eventName
        meta
        payload
        createdAt
        eid
        user {
          name
          email
          profilePic
          type
          authRole
          role
          status
          eid
          locations {
            name
          }
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        hasNextPage
      }
    }
  }
`;

export const GET_LOCATION_DROPDOWN = gql`
  query Branch {
    branch {
      descendants {
        name
        eid
      }
    }
  }
`;

export const UserEntity = gql`
  query User {
    user {
      entity {
        profilePic
        headquarters
        email
        phone
        name
      }
    }
  }
`;

export const bookMarkContent = gql`
  mutation BookmarkContent($input: BookmarkItemInput) {
    BookmarkContent(input: $input) {
      userId
    }
  }
`;

export const getBookMarkedContent = gql`
  query FetchUserBookmark {
    FetchUserBookmark {
      bookmarkedContents

      eid
    }
  }
`;

export const getHomePageContinueDeck = gql`
  query UserHomepageContinueDeck {
    userHomepageContinueDeck {
      eid
      deckId
      deck {
        title
        entityId
        cards {
          title
          eid
        }
      }
      viewedCardIds
      viewedCards {
        title
      }
    }
  }
`;

export const getHomePageStats = gql`
  query Query {
    userHomepageStats
  }
`;

import React, { FC, useMemo } from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';

import moment from 'moment-timezone';
import { match } from 'ts-pattern';

import {
  faCalendarCheck,
  faRepeat,
  faSquareCheck,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ITaskData } from './task-details.graphql';

import { toArray } from '../../../../../utils/utils';

interface ChipsProps extends Pick<BoxProps, 'background'> {
  icon: IconDefinition;
  color: string;
  value: string;
}

const ItemChips: FC<ChipsProps> = ({ icon, color, background, value }) => {
  return (
    <Flex
      p='7px 12px'
      background={background}
      align='center'
      gap='6px'
      borderRadius='6px'
      width='max-content'
    >
      <FontAwesomeIcon icon={icon as IconProp} color={color} />
      <Box textTransform='capitalize' color={color}>
        {value}
      </Box>
    </Flex>
  );
};

const trimWeekDays = <T extends string>(weekDays?: T[]): string =>
  toArray(weekDays)
    .map((i) => i.slice(0, 3))
    .join(', ');

interface IProps {
  data?: ITaskData;
}

const TaskScheduleDetails: FC<IProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  const repString = useMemo(() => {
    if (data.isRepeating) {
      const repeatDetails = data.repeatDetails;
      return match(data.repeatCycle)
        .with('daily', (value) =>
          value.concat(' - ', moment(repeatDetails?.dueTime).format('hh:mm A'))
        )
        .with('weekly', (value) =>
          value.concat(' - ', trimWeekDays(repeatDetails?.weekDays))
        )
        .with('monthly', () => {
          switch (repeatDetails?.repeatMonthly) {
            case 'lastDay':
              return 'Last day of every month';
            default:
              return moment(repeatDetails?.repeatMonthly)
                .format('Do')
                .concat(' of every month');
          }
        })
        .with('yearly', () => {
          return moment(repeatDetails?.repeatYearly)
            .format('Do MMMM')
            .concat(' of every year');
        })
        .with('days', () => {
          return 'every '.concat(
            moment.localeData().ordinal(repeatDetails?.noOfDays || 2),
            ' days'
          );
        })
        .otherwise((value) => value);
    }
    return '';
  }, [data]);

  return (
    <Flex gap='12px'>
      <ItemChips
        icon={faSquareCheck}
        color='#33383F'
        background='rgba(241, 241, 245, 1)'
        value={`${data.taskItems.length} steps`}
      />

      <ItemChips
        icon={faCalendarCheck}
        color='#8E59FF'
        background='rgba(202, 189, 255, 0.2)'
        value={moment(data.lastSession.dueDate).format('DD MMMM YYYY')}
      />

      {data.isRepeating && (
        <ItemChips
          icon={faRepeat}
          color='#2A85FF'
          background='rgba(177, 229, 252, 0.3)'
          value={repString}
        />
      )}
    </Flex>
  );
};

export default TaskScheduleDetails;

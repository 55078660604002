import React, { FC, useCallback } from 'react';
import { components, MenuProps, Select } from 'chakra-react-select';
import { CheckIcon } from '@chakra-ui/icons';
import { getChakraSelectStyles } from 'atoms/Dropdown/dropdown.styles';
import { AuthRole } from '../../../authorization';
import CustomOption from './CustomOption';
import { useUserData } from 'hooks';
import { SelectOption } from '../../../atoms/Dropdown';
import { PrimaryButton } from 'atoms';
import { Flex, Text } from '@chakra-ui/react';
import { HeaderColors } from 'shared/headerColors/header-colors';

interface IProps {
  options: any;
  setNewCategory: any;
  value: any;
  field: any;
  hideCreateButton?: boolean;
  handleChange: (option: any) => void;
  clickHandler?: () => void;
}

const CustomSelect: FC<IProps> = ({
  options,
  setNewCategory,
  value,
  field,
  hideCreateButton,
  clickHandler,
  handleChange,
}) => {
  const userData = useUserData();

  const Menu = useCallback((menuProps: MenuProps<SelectOption, false>) => {
    const btnRenderHandler = () => {
      if (hideCreateButton) {
        return false;
      }
      if (
        userData?.authRole === AuthRole.SUPER_ADMIN ||
        (userData?.authRole === AuthRole.ADMIN && userData?.type === 'user')
      ) {
        return true;
      }
      return false;
    };
    return (
      <components.Menu {...menuProps}>
        {btnRenderHandler() && (
          <Flex p={4} cursor='pointer' onClick={clickHandler}>
            <Text fontWeight={600} color='blue.500'>
              + Create new folder
            </Text>
          </Flex>
        )}
        {menuProps.children}
      </components.Menu>
    );
  }, []);

  return (
    <>
      <Select
        value={value}
        onChange={handleChange}
        components={{
          Menu,
          Option: CustomOption,
        }}
        options={options}
        // options={[
        //   ...((!hideCreateButton &&
        //     userData?.authRole === AuthRole.SUPER_ADMIN) ||
        //   (userData?.authRole === AuthRole.ADMIN && userData?.type === 'user')
        //     ? [{ value: 'create', label: '+ Create new folder' }]
        //     : []),
        //   ...options,
        // ]}
        chakraStyles={getChakraSelectStyles<SelectOption, false>()}
        placeholder='Select a folder'
      />
    </>
  );
};

export default CustomSelect;

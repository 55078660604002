import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import GenericModal from 'ui-components/GenericModal';
import closeIcon from '../../../../assets/images/closeIcon.png';

interface IProps {
  showShowArchiveModal: boolean;
  closeShowArchiveModal: () => void;
  archiveFormHandler: () => void;
}

const AllFormResponseArchiveModal: FC<IProps> = ({
  showShowArchiveModal,
  closeShowArchiveModal,
  archiveFormHandler,
}) => {
  const { t } = useTranslation('form');
  const { t: tc } = useTranslation('common');
  return (
    <GenericModal
      isOpen={showShowArchiveModal}
      onClose={closeShowArchiveModal}
      title={t('archive_form_warn')}
      closeIcon={closeIcon}
      lineColor={'#b5e4ca'}
      isCentered={true}
      buttonsObject={[
        {
          mt: 6,
          type: 'button',
          size: 'lg',
          isLoading: false,
          disabled: false,
          title: tc('cancel'),
          leftIcon: null,
          rightIcon: null,
          onClick: closeShowArchiveModal,
          style: {
            width: '160px',
            backgroundColor: 'white',
            marginRight: '1rem',
          },
          variant: 'outline',
          colorScheme: 'blue',
          className: null,
          value: null,
        },
        {
          mt: 6,
          type: 'button',
          size: 'lg',
          isLoading: false,
          disabled: false,
          title: tc('ok_uppercase'),
          leftIcon: null,
          rightIcon: null,
          onClick: archiveFormHandler,
          style: { width: '160px' },
          variant: 'solid',
          colorScheme: 'blue',
          className: null,
          value: null,
        },
      ]}
      buttonsPosition='right'
      paddingLeft={30}
      paddingRight={30}
      paddingTop={30}
      paddingBottom={20}
      modalSizes={{
        sm: '800px',
        md: '800px',
        base: '800px',
        xl: '800px',
        ['2xl']: '800px',
      }}
      modalMinSizes={{
        sm: '800px',
        md: '800px',
        base: '800px',
        xl: '800px',
        ['2xl']: '800px',
      }}
    >
      <div
        style={{
          marginTop: '13px',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '1.63',
          letterSpacing: '-0.16px',
          marginLeft: '20px',
          width: '93%',
        }}
      >
        {null}
        {/* Looks like you don&apos;t have visibility access to this deck / card. Please contact your manager to get access! */}
      </div>
    </GenericModal>
  );
};

export default AllFormResponseArchiveModal;

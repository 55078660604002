import React, { FC } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useDeleteContext } from './context';

interface IProps {}

const FormDeleteSuccess: FC<IProps> = () => {
  const { category } = useDeleteContext();

  return (
    <Center
      flex={1}
      flexDirection='column'
      style={{
        aspectRatio: '2/1',
      }}
      gap={4}
    >
      <FontAwesomeIcon
        icon={faCircleCheck as IconProp}
        color='#83BF6E'
        size='3x'
      />
      <Box fontSize='16px' textAlign='center' maxW='418px' color='#33383F'>
        All forms were successfully deleted! <br />
        Redirecting to Delete ‘{category.name}’ page...
      </Box>
    </Center>
  );
};

export default FormDeleteSuccess;

export const footerStyle = () => {
  return {
    width: '100%',
    zIndex: '1',
    height: '40px',
    backgroundColor: '#f4f4f4',
    borderRadius: '40px',
    padding: '4px',
    // borderTop: '1px solid rgba(20, 20, 20, 0.1)',
  };
};

export const navbarStyle = () => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'space-around',
    width: '100%',
    height: '100%',
  };
};

export const itemStyle = (props, isActive) => {
  let activeTabBg = isActive
    ? {
        backgroundColor: '#fcfcfc',
        boxShadow:
          '0 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0 2px 0 0 rgba(255, 255, 255, 0.25), inset 0 -1px 1px 0 rgba(0, 0, 0, 0.04)',
        borderRadius: '32px',
      }
    : {
        cursor: 'pointer',
      };
  return {
    // padding: '8px',
    // cursor: 'pointer',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    // fontSize: '12px',
    // color: `${props.theme.color.helpText}`,
    // marginTop: '10px',
    flex: '1 1',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...activeTabBg,
  };
};

export const itemLinkStyle = (icon, isActive, context) => {
  let activeStateBg = isActive
    ? {
        fill: `#1a1d1f`,
      }
    : {
        fill: `${context.theme.secondaryTextColor}`,
      };

  return {
    height: '18px',
    width: '18px',
    display: 'inline-block',
    // mask: `url(${icon}) no-repeat center center`,
    ...activeStateBg,
  };
};

export const itemIconStyle = (icon, isActive, context) => {
  let activeStateBg = isActive
    ? {
        fill: `#1a1d1f`,
      }
    : {
        fill: `${context.theme.secondaryTextColor}`,
      };

  return {
    path: {
      ...activeStateBg,
    },
    // height: '18px',
    // width: '18px',
    // display: 'inline-block',
    // // mask: `url(${icon}) no-repeat center center`,
  };
};

export const itemLinkTextStyle = (isActive, context) => {
  const colorProp = isActive
    ? {
        color: `#1a1d1f`,
      }
    : {
        color: `${context.theme.secondaryTextColor}`,
      };

  return {
    ...colorProp,
    marginLeft: '8px',
    // paddingTop: '2px',
  };
};

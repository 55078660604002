import { Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import dateFormat from 'dateformat';
import React, { FC } from 'react';
import { CardType } from 'types';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as PowerupComponent } from '../../../../../assets/images/powerup-menu/actionMenu.svg';
import { ReactComponent as PowerupComponent } from '../../../../assets/images/powerup-menu/actionMenu.svg';
import { ReactComponent as VideoComponent } from '../../../../assets/images/powerup-menu/videoMenu.svg';
import { ReactComponent as FormComponent } from '../../../../assets/images/powerup-menu/formMenu.svg';
import { ReactComponent as QuizIcon } from '../../../../assets/images/quizSvg.svg';

interface IProps {
  card: any;
  fromTraining?: boolean;
  type?: string;
}

const CardDeckInfo: FC<IProps> = ({ fromTraining, card, type }) => {
  const { t } = useTranslation(['common']);
  const getValue = (item: any, key: keyof any) => {
    if (item.cardType === CardType.CustomForm) {
      // @ts-ignore
      return item?.form?.[key] || item?.[key];
    }
    let value = item?.[key];
    if (value?.length > 60) {
      value = value?.substring(0, 60) + '...';
    }
    return value;
  };

  const getTooltipValue = (item: any, key: keyof any) => {
    if (item.cardType === CardType.CustomForm) {
      // @ts-ignore
      return item?.form?.[key] || item?.[key];
    }
    let value = item?.[key];
    return value;
  };

  return (
    <>
      <Flex
        id='card-deck-info-flex-1'
        direction={'row'}
        alignItems={'center'}
        width={'full'}
      >
        <Flex direction={'column'} isTruncated width={'full'}>
          {fromTraining && type && (
            // <Text textTransform={'capitalize'} width={'full'}>
            //   {type === 'sop' ? 'Chapter' : 'Card'}
            // </Text>
            <Flex gap='5px' alignItems='center'>
              {type === 'sop' && <Text fontSize='12px'>Chapter</Text>}
              {type?.toLowerCase() === 'card' &&
                card?.cardType?.toLowerCase() === 'action' && (
                  <>
                    <PowerupComponent style={{ width: '15px' }} />
                    <Text fontSize='12px' color='#6F767E' fontWeight={600}>
                      Action
                    </Text>
                  </>
                )}
              {type?.toLowerCase() === 'card' &&
                card?.cardType?.toLowerCase() === 'video' && (
                  <>
                    <VideoComponent style={{ width: '15px' }} />
                    <Text fontSize='12px' color='#6F767E' fontWeight={600}>
                      Video
                    </Text>
                  </>
                )}
              {type?.toLowerCase() === 'card' &&
                card?.cardType?.toLowerCase() === 'quiz' && (
                  <>
                    <QuizIcon style={{ width: '15px' }} />
                    <Text fontSize='12px' color='#6F767E' fontWeight={600}>
                      Quiz
                    </Text>
                  </>
                )}
            </Flex>
          )}
          {!fromTraining && (
            <Text as={'p'} isTruncated width={'full'}>
              {card?.category}
            </Text>
          )}
          <Tooltip
            label={getTooltipValue(card, 'title')}
            placement='top'
            hasArrow
          >
            <Text as={'b'} isTruncated maxW='300px'>
              {getValue(card, 'title')}
            </Text>
          </Tooltip>
          {!fromTraining && (
            <Text as={'p'}>
              {t('last_updated_date', {
                date: dateFormat(card?.updatedAt, 'dd mmm yyyy'),
              })}
            </Text>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default CardDeckInfo;

import { match } from 'ts-pattern';
import moment from 'moment/moment';
import momentTz from 'moment-timezone';
import { userObj } from 'sop-commons/src/client';
import { IFormInput } from '../task.types';
import { getPublishDate, getTimeZone } from './helper';
import { getRepeatString } from '../repeat-string';

export const taskDueDateValidation = (
  value: IFormInput['taskDue'],
  values: IFormInput
): boolean => {
  const taskDueTime = moment(values?.dueTime);

  let publishDate = getPublishDate(
    values.startDateType,
    values.startDate,
    values.startTime
  );

  if (values.isEditing) {
    if (values.repeatString === getRepeatString(values)) {
      return false;
    }
    publishDate = moment().isSameOrAfter(publishDate) ? moment() : publishDate;
  }

  return match<IFormInput['taskDue'], boolean>(value)
    .with('nextWeek', () => {
      return (
        moment()
          .startOf('isoWeek')
          .add(1, 'week')
          .day('monday')
          .set('hour', taskDueTime.get('hour'))
          .set('minute', taskDueTime.get('minute'))
          .diff(publishDate, 'hour') < 12
      );
    })
    .with(
      'endOfMonth',
      () => moment().endOf('month').diff(publishDate, 'hour') < 12
    )
    .with(
      'endOfYear',
      () => moment().endOf('year').diff(publishDate, 'hour') < 12
    )
    .with('date', () => {
      if (!values?.taskDueDate) {
        return false;
      }

      if (values?.timeOffsetDiff! > 0) {
        const userTimeZone = getTimeZone(userObj()?.timezone);

        const TD = momentTz(
          moment(values.taskDueDate).format('L'),
          'L',
          userTimeZone
        )
          .set('hour', taskDueTime.get('hour'))
          .set('minute', taskDueTime.get('minute'));

        const PD = momentTz(publishDate.format('L'), 'L', userTimeZone)
          .set('hour', publishDate.get('hour'))
          .set('minute', publishDate.get('minute'))
          .add(values?.timeOffsetDiff!, 'minute')
          .add(1, 'hour');

        return TD.diff(PD, 'hour') < 7;
      }

      return (
        moment(values.taskDueDate)
          .set('hour', taskDueTime.get('hour'))
          .set('minute', taskDueTime.get('minute'))
          .diff(publishDate, 'hour') < 7
      );
    })
    .otherwise(() => false);
};

export const getMinDueDate = (
  values: IFormInput
): moment.Moment | undefined => {
  let publishDate = getPublishDate(
    values?.startDateType!,
    values?.startDate,
    values?.startTime
  );

  if (values.isEditing) {
    if (values.repeatString === getRepeatString(values)) {
      return undefined;
    }
    publishDate = moment().isSameOrAfter(publishDate) ? moment() : publishDate;
  }

  return (
    match(values?.taskDue!)
      // .with('nextWeek', () => minDate.add(24, 'hour'))
      // .with('endOfMonth', () => minDate.add(24, 'hour'))
      // .with('endOfYear', () => minDate.add(24, 'hour'))
      .with('date', () => {
        const userTimeZone = getTimeZone(userObj()?.timezone);

        let PD = momentTz(publishDate.format('L'), 'L', userTimeZone)
          .set('hour', publishDate.get('hour'))
          .set('minute', publishDate.get('minute'))
          // .add(values?.timeOffsetDiff!, 'minute')
          .add(8, 'hour');

        if (values?.timeOffsetDiff! > 0) {
          PD = PD.add(values?.timeOffsetDiff!, 'minute');
        }

        return PD;
      })
      .otherwise(() => undefined)
  );
};

import { Col, Row, Layout, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { RightOutlined, HomeFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  makeVar,
  useQuery,
  gql,
  useSubscription,
  useReactiveVar,
} from '@apollo/client';
import { useState, useEffect, cloneElement, createRef, useRef } from 'react';
import { GlobalHotKeys, HotKeys } from 'react-hotkeys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faComment } from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CometChat } from '@cometchat-pro/chat';
import { userObj } from 'sop-commons/src/client/clientFactory';

// import components
import { Header } from '../../ui-components/Header';
import { DashboardMenu } from '../../ui-components/DashboardMenu';
import NotificationList from '../../ui-components/NotificationList';

// import helper
import { useRouter } from '../../utils';
import NotificationCount from 'ui-components/NotificationCount';
import { onNotification } from 'ui-components/NotificationList/NotificationList';
import { PublicHeader } from '../PublicHeader';
import React from 'react';
import ResponsiveModal from '../ResponsiveModal';
import { useChatMessageCount } from '../../hooks';
import { GET_USER } from '../../pages/Login/login.graphql';
import { CHAT_URL } from '../../appRoutes';

export const dashboardRouteVar = makeVar('');

export const GET_NOTIFICATION_COUNT = gql`
  query {
    unseenNotificationCount {
      count
    }
  }
`;

interface Props {
  children?: any;
  isNotification?: boolean;
  refetchSopList?: any;
  hideSidebar?: boolean;
  entityDetails?: object;
  isSettings?: boolean;
  disableDefaultMenuKeys?: boolean;
  containerStyle?: any;
}
const keyMap = {
  OPEN_SEARCH: ['ctrl+k', 'command+k'],
};

interface UserData {
  user: any;
}

interface UserVars {}
const MainContainer = ({
  children,
  isNotification,
  refetchSopList,
  hideSidebar,
  entityDetails,
  isSettings,
  disableDefaultMenuKeys,
  containerStyle,
}: Props) => {
  const scrollRef = useRef();
  let history = useHistory();
  const chatMessageCount = useChatMessageCount();
  const [isScrolling, setIsScrolling] = useState(false);
  const router = useRouter();
  const [handlers, setHandlers] = useState({});
  const { query, pathname }: any = router;
  const types: any = Object.keys(query);
  const userObject = useReactiveVar<any>(userObj);
  const { startTour } = useIntercom();
  const { data, refetch: onNotificationCountRefetch } = useQuery(
    GET_NOTIFICATION_COUNT,
    {
      fetchPolicy: 'network-only',
    }
  );

  const {
    error: getUserError,
    data: getUserData,
    refetch: refetchUser,
  } = useQuery<UserData, UserVars>(GET_USER);

  const {
    data: onNotificationSubscription,
    error: onNotificationSubscriptionError,
  } = useSubscription(onNotification);

  useEffect(() => {
    console.log('Is Demo', userObject?.isDemo);
    if (userObject?.isDemo) {
      const intercomId: any = process.env.REACT_APP_INTERCOM_TOUR_ID;
      startTour(intercomId);
    }
  }, [userObject?.eid]);

  useEffect(() => {
    if (onNotificationSubscription) {
      onNotificationCountRefetch();
      if (refetchSopList) {
        refetchSopList();
      }
    }
  }, [onNotificationSubscription]);

  const onReloadListWithFilter = (items: any) => {
    const name = items?.selectedFilter?.name;
    const type = items?.selectedFilter?.type;
    if (type === 'category') {
      dashboardRouteVar(`/dashboard?category=${name}`);
      history.push({
        pathname: '/dashboard',
        search: `?category=${name}`,
      });
    } else if (type === 'roles') {
      dashboardRouteVar(`/dashboard?roles=${name}`);
      history.push({
        pathname: '/dashboard',
        search: `?roles=${name}`,
      });
    } else if (type === 'bundles') {
      dashboardRouteVar(`/dashboard?bundles=${name}`);
      history.push({
        pathname: '/dashboard',
        search: `?bundles=${name}`,
      });
    }
  };

  let selectedStyle = {};
  let settingsStyle = {};
  let chatStyle = {};
  if (pathname === '/') {
    selectedStyle = {
      color: '#4d8af0',
      backgroundColor: '#f3f3f3',
      // border: '1px solid #4d8af0',
    };
  }
  if (pathname === '/settings') {
    settingsStyle = {
      color: '#4d8af0',
      backgroundColor: '#f3f3f3',
      // border: '1px solid #4d8af0',
    };
  }
  if (pathname === CHAT_URL) {
    chatStyle = {
      color: '#4d8af0',
      backgroundColor: '#f3f3f3',
    };
  }

  const notificationCount = data?.unseenNotificationCount?.count;
  return (
    <HotKeys
      keyMap={keyMap}
      handlers={handlers}
      style={{
        backgroundColor: '#fbf7f0',
        height: '100%',
        maxHeight: '100%',
      }}
    >
      <Layout
        style={{
          backgroundColor: '#fbf7f0',
          height: '100%',
          maxHeight: '100%',
        }}
      >
        {isMobile && !hideSidebar && <ResponsiveModal />}
        {(!isMobile || hideSidebar) && (
          <>
            {hideSidebar && <PublicHeader entityDetails={entityDetails} />}
            {!hideSidebar && <Header setHandlers={setHandlers} />}

            <div
              className='logo'
              style={{ marginBlock: 30, marginRight: 100, marginLeft: 100 }}
            />
            <Row
              justify='center'
              style={{
                height: 'calc(100% - 130px)',
              }}
              className='app-main-container'
            >
              {!hideSidebar && (
                <Col
                  span={5}
                  style={{
                    // borderRadius: 10,
                    maxHeight: '100%',
                    //marginLeft: 30,
                    //marginRight: 20,
                    // marginBottom: 20,
                    // position: 'sticky',
                    // position: '-webkit-sticky',
                    // height: 'calc(100% - 10px)',
                    minWidth: 290,
                    maxWidth: 350.98,
                    borderRadius: 10,
                    // overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  <Scrollbars style={{ height: 'calc(100% - 10px' }} autoHide>
                    <div
                      style={{
                        backgroundColor: '#fff',
                        border: '1px solid rgb(221, 221, 221)',
                        borderRadius: 10,
                        minWidth: 290,
                        maxWidth: 349.98,
                        paddingBottom: 7,
                      }}
                    >
                      <div
                        style={{
                          marginBlock: 0,
                          borderRadius: 0,
                          marginTop: 22,
                        }}
                      >
                        <Link to='/'>
                          <Button
                            style={{
                              display: 'flex',
                              width: '100%',
                              fontWeight: 600,
                              height: 50,
                              fontSize: 14,
                              borderRadius: 0,
                              paddingLeft: 37,
                              paddingRight: 37,
                              alignItems: 'center',
                              border: 'none',
                              boxShadow: 'none',
                              ...selectedStyle,
                            }}
                          >
                            <HomeFilled style={{ marginRight: '8px' }} />
                            <div
                              style={{
                                marginLeft: '8px',
                                flex: '1 1 auto',
                                textAlign: 'left',
                              }}
                            >
                              Home
                            </div>
                            {!!notificationCount && (
                              <NotificationCount
                                notificationNumber={notificationCount}
                              />
                            )}
                          </Button>
                        </Link>
                      </div>

                      <div
                        style={{
                          marginBlock: 0,
                          borderRadius: 0,
                        }}
                      >
                        <Link to={CHAT_URL}>
                          <Button
                            style={{
                              display: 'flex',
                              width: '100%',
                              fontWeight: 600,
                              height: 50,
                              fontSize: 14,
                              borderRadius: 0,
                              paddingLeft: 37,
                              paddingRight: 37,
                              alignItems: 'center',
                              border: 'none',
                              boxShadow: 'none',
                              ...chatStyle,
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                height: 14,
                                width: 16,
                                marginRight: '8px',
                              }}
                              icon={faComment}
                            />

                            <div
                              style={{
                                marginLeft: '8px',
                                flex: '1 1 auto',
                                textAlign: 'left',
                              }}
                            >
                              Messages
                            </div>
                            {!!chatMessageCount && (
                              <NotificationCount
                                notificationNumber={chatMessageCount}
                              />
                            )}
                          </Button>
                        </Link>
                      </div>

                      <DashboardMenu
                        handleSelectCallback={onReloadListWithFilter}
                        menuStyle={{
                          fontSize: 16,
                          padding: 0,
                          borderRadius: 0,
                          // overflowY: 'scroll',
                          maxHeight: 'calc(100% - 90px)',
                          border: 'none',
                          boxShadow: 'none',
                        }}
                        menuMode='inline'
                        menuDefaultOpenKeys={
                          disableDefaultMenuKeys
                            ? []
                            : types &&
                              types.length &&
                              (types.indexOf('category') !== -1 ||
                                types.indexOf('roles') !== -1 ||
                                types.indexOf('bundles') !== -1)
                            ? types
                            : ['category']
                        }
                        getUserData={getUserData}
                        getUserError={getUserError}
                      />
                      <div style={{ borderRadius: 0, marginBottom: 20 }}>
                        <Link to='/settings'>
                          <Button
                            style={{
                              display: 'flex',
                              width: '100%',
                              fontWeight: 600,
                              height: 50,
                              fontSize: 14,
                              borderRadius: 0,
                              paddingLeft: 37,
                              paddingRight: 37,
                              alignItems: 'center',
                              border: 'none',
                              boxShadow: 'none',
                              ...settingsStyle,
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                height: 14,
                                width: 16,
                                marginRight: '8px',
                              }}
                              icon={faCog}
                            />

                            <div
                              style={{
                                marginLeft: '8px',
                                flex: '1 1 auto',
                                textAlign: 'left',
                              }}
                            >
                              Settings
                            </div>
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Scrollbars>
                </Col>
              )}

              {!isNotification && !isSettings && (
                <Col
                  span={hideSidebar ? 20 : 18}
                  style={{
                    marginLeft: 20,
                    marginBottom: '20px',
                    // height: 'calc(100% - 5px)',
                    // overflowY: 'scroll',
                    backgroundColor: '#fff',
                    borderRadius: '15px',
                    padding: '35px',
                    border: 'solid 1px #ddd',
                    maxWidth: 1360,
                    ...containerStyle,
                  }}
                  id='sop-list-id'
                >
                  <Scrollbars
                    style={{ height: 'calc(100% - 5px)', marginRight: 0 }}
                    autoHide
                    ref={scrollRef}
                    onScrollCapture={() => {
                      // console.log('On Scroll Capture');
                      // setIsScrolling(true);
                    }}
                    onScrollStop={() => {
                      // console.log('On Scroll Stop');
                      // setIsScrolling(false);
                    }}
                  >
                    {cloneElement(children, {
                      scrollRef: scrollRef,
                      refetchUser,
                      refetchSopList,
                      onNotificationCountRefetch,
                    })}
                  </Scrollbars>
                </Col>
              )}
              {isSettings && (
                <Col
                  span={hideSidebar ? 20 : 18}
                  style={{
                    marginLeft: 20,
                    marginBottom: '20px',
                    height: 'calc(100% - 5px)',
                    // overflowY: 'scroll',
                    backgroundColor: '#fff',
                    borderRadius: '15px',
                    padding: '35px',
                    border: 'solid 1px #ddd',
                    maxWidth: 1360,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  id='sop-list-id'
                >
                  {/* <Scrollbars
                    style={{ height: 'calc(100% - 5px)' }}
                    autoHide
                    ref={scrollRef}
                    onScrollCapture={() => {
                      // console.log('On Scroll Capture');
                      // setIsScrolling(true);
                    }}
                    onScrollStop={() => {
                      // console.log('On Scroll Stop');
                      // setIsScrolling(false);
                    }}
                  > */}
                  {cloneElement(children, {
                    scrollRef: scrollRef,
                    refetchUser,
                    refetchSopList,
                    onNotificationCountRefetch,
                  })}
                  {/* </Scrollbars> */}
                </Col>
              )}

              {isNotification && (
                <>
                  <Col
                    span={18}
                    style={{
                      marginLeft: 20,
                      marginBottom: '20px',
                      // height: 'calc(100% - 5px)',
                      // overflowY: 'scroll',
                      borderRadius: '15px',

                      maxWidth: 1360,
                      paddingBottom: 0,
                      overflowX: 'hidden',
                      paddingLeft: '4px',
                    }}
                  >
                    <Scrollbars
                      style={{
                        height: 'calc(100% - 5px)',
                      }}
                      autoHide
                    >
                      {cloneElement(children, {
                        text: onNotificationCountRefetch,
                      })}
                    </Scrollbars>
                  </Col>
                </>
              )}

              {/* {isNotification && (
            <>
              <Col
                span={12}
                style={{
                  marginLeft: 20,
                  marginBottom: '20px',
                  height: 'calc(100% - 10px)',
                  overflowY: 'scroll',
                  paddingRight: '25px',
                  maxWidth: 960,
                }}
              >
                {children}
              </Col>
              <Col
                span={6}
                style={{
                  marginBottom: '20px',
                  height: 'calc(100% - 10px)',
                  maxWidth: 400,
                }}
              >
                <NotificationList
                  onNotificationCountRefetch={onNotificationCountRefetch}
                  refetchSopList={refetchSopList}
                />
              </Col>
            </>
          )} */}
            </Row>
          </>
        )}
      </Layout>
    </HotKeys>
  );
};

export default MainContainer;

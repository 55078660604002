import React, { FC, useContext, useMemo } from 'react';
import { Box, Progress, Tooltip } from '@chakra-ui/react';
import { getAvatar, toArray } from '../../../utils';
import { Image } from '../../../ui-components';

import { TrainingPlayContext } from './context';

interface IProps {}

const TrainingProgressBar: FC<IProps> = () => {
  const { trainingItems, userProgress, loading, userData } =
    useContext(TrainingPlayContext);

  const [percentage] = useMemo(() => {
    const viewedContents = toArray(userProgress?.viewedContents);
    const data = toArray(trainingItems).map((item) => {
      const find = viewedContents.find((value) => value.uid === item.eid);
      return {
        isCompleted: !!find?.uid,
      };
    });

    const completed = data.filter((val) => val.isCompleted).length;
    const uncompleted = data.filter((val) => !val.isCompleted).length;

    const percentage = Math.round(
      (completed / (completed + uncompleted)) * 100
    );

    return [percentage];
  }, [trainingItems, userProgress]);

  return (
    <Box pos='relative'>
      <Progress
        isIndeterminate={loading}
        size='sm'
        bg='#DDE4EC'
        borderRadius='full'
        colorScheme={percentage === 100 ? 'green' : 'blue'}
        value={percentage}
      />
      {!loading && (
        <Tooltip label={`${percentage}%`} hasArrow>
          <Box
            pos='absolute'
            transform='translateY(-20px)'
            left={`calc(${percentage}% - 30px)`}
            cursor='pointer'
          >
            <Image
              src={getAvatar({
                name: 'auth-user-profile',
                url: userData?.profilePic,
              })}
              width={30}
              height={30}
              style={{
                borderRadius: '100%',
                border: '2px solid #fff',
                maxHeight: 30,
                width: 30,
                height: 30,
              }}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default TrainingProgressBar;

import React, { FC, useMemo } from 'react';
import Overview from './Overview';
import { Flex } from '@chakra-ui/react';
import { useQuery, useReactiveVar } from '@apollo/client';

import { userObj } from 'sop-commons/src/client';

import {
  SectionKeysType,
  USER_TASK_SESSION,
  UserTaskSession,
  UserTasksResponse,
} from './user-task.graphql';
import moment from 'moment-timezone';
import { toArray } from '../../../../../utils/utils';
import { getUniqueSessionTask } from './helper';
import UserTaskList from './UserTaskList';
import { useTaskDetailsView } from '../task-details';
import CompletionRateGraph from '../CompletionRateGraph';
import EmptyUserTasks from './EmptyUserTasks';
import UserTaskLoader from './UserTaskLoader';

interface IProps {
  userId?: string;
  graphUserId?: string;
  userName?: string;
  userTimeZone?: string;
}

const UserTasks: FC<IProps> = ({
  userTimeZone,
  userId,
  graphUserId,
  userName,
}) => {
  const authUserId = useReactiveVar(userObj)?.eid;
  const endDate = useMemo(() => {
    return moment()?.subtract(1, 'years')?.toISOString();
  }, []);

  const taskDetailsView = useTaskDetailsView();

  const { data, loading } = useQuery<UserTasksResponse>(USER_TASK_SESSION, {
    fetchPolicy: 'network-only',
    skip: !userId,
    variables: {
      userId: userId,
      endDate: endDate,
    },
  });

  const { overviewData, listingData } = useMemo(() => {
    const nowUser = userTimeZone ? moment().tz(userTimeZone) : moment();

    const UTC_NOW = moment.utc(nowUser.format('LLL'), 'LLL');

    const userTaskSessions = getUniqueSessionTask(
      toArray(data?.UserTaskSession),
      UTC_NOW
    );

    const reduce = userTaskSessions.reduce(
      (acc, value) => {
        const dueDate = moment.utc(value.dueDate);
        const findSuper = value.supervisorUsers?.find(
          (val) => val.eid === authUserId
        );
        if (!findSuper) {
          acc['hidden'].push(value);
        } else if (
          value.currentUserProgress?.completedTasks?.length === value.itemCount
        ) {
          acc['completed'].push(value);
        } else if (dueDate.isBefore(UTC_NOW)) {
          acc['overdue'].push(value);
        } else if (dueDate.isSame(UTC_NOW, 'day')) {
          acc['dueToday'].push(value);
        } else if (dueDate.isBetween(UTC_NOW, UTC_NOW.clone().endOf('week'))) {
          acc['thisWeek'].push(value);
          acc['upcoming'].push(value);
        } else if (dueDate.isAfter(UTC_NOW)) {
          acc['upcoming'].push(value);
        }

        return acc;
      },
      {
        overdue: [],
        dueToday: [],
        thisWeek: [],
        upcoming: [],
        hidden: [],
        completed: [],
      } as Record<SectionKeysType, UserTaskSession[]>
    );

    return {
      overviewData: [
        {
          name: 'Overdue tasks',
          link: 'overdue',
          color: 'rgba(255, 31, 0, 0.2)',
          count: reduce.overdue.length,
        },
        {
          name: 'Due today',
          link: 'dueToday',
          color: 'rgba(202, 189, 255, 1)',
          count: reduce.dueToday.length,
        },
        {
          name: 'This week’s tasks',
          link: 'upcoming',
          color: 'rgba(177, 229, 252, 1)',
          count: reduce.thisWeek.length,
        },
        {
          name: 'Total tasks',
          color: 'rgba(255, 216, 141, 1)',
          count: userTaskSessions.length,
        },
      ],
      listingData: Object.entries(reduce),
    };
  }, [data, userTimeZone, authUserId]);

  const onTaskClick = (task: UserTaskSession) => {
    taskDetailsView({
      taskId: task.taskId,
      userId: userId!,
      sessionId: task.task?.lastSession?.eid,
    });
  };

  const isEmpty = useMemo(() => {
    return (
      overviewData.find((value) => value.name === 'Total tasks')?.count === 0
    );
  }, [overviewData]);

  return (
    <Flex flexDir='column' mt='16px' gap='20px'>
      <Overview data={overviewData} />

      <CompletionRateGraph userId={graphUserId} name={userName} />

      <Flex flexDir='column' gap='16px'>
        <UserTaskLoader isLoading={loading}>
          <EmptyUserTasks isEmpty={isEmpty} name={userName} />
        </UserTaskLoader>

        {listingData.map(([key, section]) => {
          return (
            <UserTaskList
              key={key}
              data={section}
              status={key as SectionKeysType}
              userTimeZone={userTimeZone}
              onClick={onTaskClick}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default UserTasks;

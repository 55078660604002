import { useLazyQuery } from '@apollo/client';
import {
  ISupervisedAPIArgs,
  ISupervisedTasks,
} from 'pages/TasksDashboard/tasks.types';
import {
  GET_SUPERVISED_BY_ME_TASKS,
  GET_USERS,
} from './supervised-tasks.graphql';

export const getSupervisedByMeTasks = () => {
  const [execute, { loading, error, data }] = useLazyQuery<
    ISupervisedTasks,
    ISupervisedAPIArgs
  >(GET_SUPERVISED_BY_ME_TASKS, { fetchPolicy: 'network-only' });
  return { execute, loading, error, data };
};

export const getUsers = () => {
  const [execute, { loading, error, data }] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'network-only',
  });
  return { execute, loading, error, data };
};

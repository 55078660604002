import React, { FC, ReactNode } from 'react';
import {
  Box,
  Center,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'sub-components/Loader';
// import './TableActionMenu.css';

export interface IActionMenuData {
  type?: 'divider';
  name: string;
  value: string;
  icon: any;
  normalIcon?: string;
  textColor?: string;
  showChild?: boolean;
}

interface IProps {
  arrowSize?: number;
  menuData?: Array<IActionMenuData>;
  clickedItem?: (name: string, value: string) => void;
  isLoading?: boolean;
  fetchMthd?: () => void;
  closeOnBlur?: boolean;
  offset?: [number, number];
  renderEl?: ReactNode;
}

const TableActionMenu: FC<IProps> = ({
  children,
  menuData,
  arrowSize,
  closeOnBlur,
  offset,
  clickedItem,
  fetchMthd,
  isLoading,
  renderEl,
}) => {
  const itemClickHandler = async (
    menu: any,
    mthd: any,
    showChild?: boolean
  ) => {
    if (!showChild) {
      clickedItem?.(menu.name, menu.value);
      mthd();
    } else {
      fetchMthd?.();
    }
  };

  return (
    <Popover
      placement='bottom-start'
      arrowSize={arrowSize}
      closeOnBlur={closeOnBlur}
      offset={offset}
    >
      {({ onClose }) => (
        <>
          {/*// @ts-ignore*/}
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent borderRadius='10'>
            <PopoverArrow />
            <PopoverBody borderRadius='10' boxShadow='lg'>
              <Flex flexDir='column' p='8px 18px'>
                <Flex flexDir='column' p='8px 18px'>
                  {menuData?.map((menu) => (
                    <Flex
                      key={menu.value || menu.name}
                      align='center'
                      my='6px'
                      cursor='pointer'
                      onClick={() =>
                        itemClickHandler(menu, onClose, menu?.showChild)
                      }
                    >
                      {(menu?.normalIcon === 'img' || !menu?.normalIcon) && (
                        <img
                          src={menu.icon}
                          style={{
                            height: '24px',
                            width: '24px',
                            marginRight: '12px',
                            marginLeft: 'unset',
                          }}
                        />
                      )}
                      {menu?.normalIcon === 'fontAwesome' && (
                        <Box marginRight={'12px'} width='fit-content'>
                          <FontAwesomeIcon icon={menu?.icon} fontSize='20px' />
                        </Box>
                      )}
                      {menu?.normalIcon === 'chakraUIIcon' && menu?.icon}
                      {menu?.showChild && (
                        <Popover offset={[20, 10]} placement='left'>
                          {/*// @ts-ignore*/}
                          <PopoverTrigger>
                            <span
                              style={{
                                fontSize: '15px',
                                fontWeight: '600',
                                lineHeight: '1.6',
                                cursor: 'pointer',
                                marginTop: '8px',
                                marginBottom: '8px',
                                color: menu?.textColor || 'black',
                              }}
                            >
                              {menu.name}
                            </span>
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverBody>
                              {isLoading && (
                                <Center p='1rem 0'>
                                  <Loader />
                                </Center>
                              )}
                              {!isLoading && renderEl}
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      )}
                      {!menu?.showChild && (
                        <span
                          style={{
                            fontSize: '15px',
                            fontWeight: '600',
                            lineHeight: '1.6',
                            cursor: 'pointer',
                            marginTop: '8px',
                            marginBottom: '8px',
                            color: menu?.textColor || 'black',
                          }}
                        >
                          {menu.name}
                        </span>
                      )}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
    // <Popover placement='bottom-start' isLazy>
    //   <PopoverTrigger>{children}</PopoverTrigger>
    //   <PopoverContent
    //     className='container'
    //     style={{ width: '300px', height: '185px', borderRadius: '12px' }}
    //   >
    //     <PopoverArrow />
    //     <PopoverBody>
    //       <div
    //         style={{
    //           display: 'flex',
    //           flexDirection: 'column',
    //           padding: '8px 18px',
    //         }}
    //       >
    //         {menuData.map((menu) => (
    //           <div
    //             key={menu.name}
    //             style={{
    //               display: 'flex',
    //               flexDirection: 'row',
    //               alignItems: 'center',
    //               marginTop: '6px',
    //               marginBottom: '6px',
    //             }}
    //           >
    //             <img
    //               src={menu.icon}
    //               style={{ height: '24px', width: '24px', marginRight: '12px' }}
    //             />
    //             <span
    //               style={{
    //                 fontSize: '15px',
    //                 fontWeight: '600',
    //                 lineHeight: '1.6',
    //                 cursor: 'pointer',
    //                 marginTop: '8px',
    //                 marginBottom: '8px',
    //               }}
    //             >
    //               {menu.name}
    //             </span>
    //           </div>
    //         ))}
    //       </div>
    //     </PopoverBody>
    //   </PopoverContent>
    // </Popover>
  );
};

export default TableActionMenu;

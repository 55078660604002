// import css
import { Tag } from 'antd';
import './CustomTag.css';

const CustomTag = ({ title, style, backgroundColor }: any) => {
  return (
    <Tag
      className='eventclass-custom-tag-container custom-tag-container'
      style={{ ...style, backgroundColor, borderColor: backgroundColor }}
    >
      {title}
    </Tag>
  );
};

export default CustomTag;

import React, { FC, useMemo } from 'react';
import { Flex, Text, Tooltip } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import {
  NoteImageEntity,
  QuestionType,
  ResponseEntity,
} from '../../../../../types';
import { toArray } from '../../../../../utils/utils';

import NotesText from './NotesText';
import NoteImageTag from './NoteImageTag';

interface Opt {
  eid: string;
  isSelected: boolean;
  label: string;
  noteImage?: NoteImageEntity;
}

interface IProps {
  response: ResponseEntity;
  question: any;
  showUnselectedOption?: boolean;
  isMultiChoice?: boolean;
}

const CheckBoxCell: FC<IProps> = ({
  response,
  question,
  showUnselectedOption,
  isMultiChoice,
}) => {
  const options = useMemo(() => {
    const res = response?.responseId || [];

    const _noteImage = toArray(
      response.qType === QuestionType.CHECKBOX ? response?.noteImage : []
    ).reduce((acc, value) => {
      if (value.optionId) {
        acc[value.optionId] = value;
      }

      return acc;
    }, {} as Record<string, NoteImageEntity>);

    return toArray(question?.options).reduce<Opt[]>((previousValue, option) => {
      if (res.includes(option.eid)) {
        previousValue.push({
          eid: option.eid,
          isSelected: true,
          label: option.isOther
            ? response?.otherResponse || option.label
            : option.label,
          noteImage: _noteImage[option.eid],
        });
      } else if (showUnselectedOption) {
        previousValue.push({
          eid: option.eid,
          isSelected: false,
          label: option.label,
        });
      }

      return previousValue;
    }, []);
  }, [question, response, showUnselectedOption]);

  const icon = (isMultiChoice ? faCircleDot : faSquareCheck) as IconProp;

  const noteImages = useMemo(() => {
    if (response.qType === QuestionType.CHECKBOX) {
      return undefined;
    }
    return response?.noteImage;
  }, [response, isMultiChoice]);

  if (options.length > 0) {
    return (
      <Flex
        gap='5px'
        wrap='wrap'
        width='fit-content'
        maxW='full'
        flexDir='column'
      >
        {options?.map((data) => {
          return (
            <React.Fragment key={data.eid}>
              <Tooltip key={data.eid} label={data.label} hasArrow>
                <Flex
                  gap='5px'
                  p='5px 10px'
                  bg='#e0f5fe'
                  borderRadius='7px'
                  align='center'
                  justify='flex-start'
                  w='fit-content'
                  maxW='100%'
                  cursor='pointer'
                >
                  <FontAwesomeIcon
                    fontSize='18px'
                    icon={icon}
                    color={data.isSelected ? '#2A85FF' : '#D1D1D1'}
                  />

                  <Text m={0} p={0} color='#272B30' isTruncated>
                    {data.label}
                  </Text>
                </Flex>
              </Tooltip>

              <NoteImageTag noteImages={toArray(data.noteImage)} />
            </React.Fragment>
          );
        })}
        <NotesText noteText={response?.noteText} />
        <NoteImageTag noteImages={noteImages} />
      </Flex>
    );
  }

  if (response?.noteText || noteImages) {
    return (
      <>
        <NotesText noteText={response?.noteText} />
        <NoteImageTag noteImages={noteImages} />
      </>
    );
  }

  return <div>-</div>;
};

export default CheckBoxCell;

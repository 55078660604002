import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { useButtonProps } from '../useButtonProps';

export interface TimePickerButtonProps extends ButtonProps {
  isSelected?: boolean;
  readOnly?: boolean;
}

const TimePickerButton = forwardRef<never, TimePickerButtonProps>(
  ({ isSelected, value, onClick, placeholder, readOnly }, ref) => {
    const otherProps = useButtonProps({
      isReadOnly: readOnly,
      isSelected: isSelected,
    });

    return (
      <Button
        size='lg'
        fontSize='14px'
        fontWeight='500'
        borderRadius='12px'
        rightIcon={<FontAwesomeIcon icon={faCaretDown as IconProp} size='lg' />}
        {...otherProps}
        aria-readonly={readOnly}
        onClick={onClick}
        ref={ref}
      >
        {isSelected ? value || placeholder : placeholder}
      </Button>
    );
  }
);

TimePickerButton.displayName = 'Time Picker Button';

export default TimePickerButton;

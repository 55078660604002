import React, { FC, Fragment, useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import { CometChat } from '@cometchat-pro/chat';
import { chatStatus, userObj } from 'sop-commons/src/client/clientFactory';

export const chatMessageCount = makeVar(0);

const ChatMessageCountListener: FC = () => {
  const userObject = useReactiveVar(userObj);
  const isChatLoggedIn = useReactiveVar(chatStatus);

  const fetchChatCount = () => {
    CometChat.getUnreadMessageCount().then(
      (array: any) => {
        let count = 0;
        const groups = array?.groups;
        const users = array?.users;
        Object.keys(groups).forEach((gid) => {
          count += groups[gid];
        });
        Object.keys(users).forEach((uid) => {
          count += users[uid];
        });
        chatMessageCount(count);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.log('Error in getting message count', error);
      }
    );
  };

  useEffect(() => {
    const rootElm = document.getElementById('root');

    function listener() {
      setTimeout(fetchChatCount, 1000);
      setTimeout(fetchChatCount, 1500);
    }
    rootElm?.addEventListener('cometChat', listener);

    return () => rootElm?.removeEventListener('cometChat', listener);
  }, []);

  useEffect(() => {
    if (isChatLoggedIn) {
      fetchChatCount();
    }
  }, [isChatLoggedIn]);

  useEffect(() => {
    if (isChatLoggedIn) {
      const listenerID = `${userObject?.eid}:${userObject?.entity?.eid}`;
      CometChat.addMessageListener(
        listenerID,
        new CometChat.MessageListener({
          onTextMessageReceived: () => fetchChatCount(),
          onMessagesRead: () => fetchChatCount(),
        })
      );

      return () => CometChat.removeMessageListener(listenerID);
    }
  }, [isChatLoggedIn]);

  return <Fragment />;
};

export default ChatMessageCountListener;

import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { UseConfirm, useConfirm } from '../Confirm';
import { FormVisibility } from '../Visibility/visibility.types';
import ShareContent from './ShareContent';
import BoxHeader from '../BoxHeader';

interface IProps extends FormVisibility {
  formId: string;
  formName: string;
  onClickHandler?: (name: string) => void;
  formCreatorId?: string;
}

type UseShareModal = (props: IProps) => void;

export const useShareModal = (): UseShareModal => {
  const { t } = useTranslation(['form']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();
  const apolloClient = useApolloClient();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    const _onClickHandler = (name: string, closeModal?: boolean) => {
      if (closeModal) {
        confirmRef.current?.destroy();
      }
      props.onClickHandler?.(name);
    };

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      size: 'full',
      title: (
        <Flex align='center' justify='space-between'>
          <BoxHeader color='#B5E4CA' title={t('form:shareForm')} />
          <Flex justify='flex-end' gap='8px'>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
            />
          </Flex>
        </Flex>
      ),
      content: (
        <ShareContent
          formId={props.formId}
          formName={props.formName}
          formCreatorId={props.formCreatorId}
          onClickHandler={_onClickHandler}
          visibility={{
            visibility: props.visibility,
            visibleTo: props.visibleTo,
          }}
        />
      ),
      bodyProps: {
        display: 'flex',
        flexDir: 'column',
        overflow: 'hidden',
      },
      scrollBehavior: 'inside',
      contentProps: {
        padding: '12px',
        borderRadius: 0,
        bg: '#FFFFFF',
        height: '100vh',
        containerProps: {
          maxW: '560px',
          left: 'unset',
          right: 0,
          overflow: 'hidden',
        },
      },
      footer: null,
    });
  }, []);
};

import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Box, Flex, useToast, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import DashboardContainer from '../../sub-components/DashboardContainer';
import './Training.scss';
import PrimaryButton from '../../atoms/PrimaryButton';
import SearchInput from '../../atoms/SearchInput';
import viewChangeLogIcon from '../../assets/images/viewChangeLog.svg';
import TableActionMenu, {
  IActionMenuData,
} from '../../ui-components/TableActionMenu/TableActionMenu';
import OpenIcon from '../../assets/images/open.png';
import DarkEditIcon from '../../assets/images/edit-dark.svg';
import ArchiveIcon from '../../assets/images/archive.png';
import ProgressIcon from '../../assets/images/formResponse.svg';
import { UserData, userObj } from 'sop-commons/src/client/clientFactory';
import { ADMIN, MANAGER, SUPER_ADMIN, WORKER } from '../../utils/userRoles';
import { UPDATE_TRAINING } from './training.graphql';

// import { getAllTrainings } from 'sop-commons/Query/Training/index';
import { TrainingPathsTableData } from './training.graphql';
import TrainingPathModal from '../../sub-components/AddNewPathModal/TrainingModal';
import { getSingleTrainingData } from 'sop-commons/Query/Training';
import { ContentDetailsEntity, TrainingData } from './training.types';
import PathName from './Training/PathName';
import Assignee from './Training/Assignee';
import { SortingTable } from '../../sub-components/ChakraTable/SortingTable';
import { CardsCount, CreatedBy, DateCell } from './Training/TrainingComponent';
import { SortByDate, SortByName, SortByNumber } from '../../utils/sorting';
import ChangeLog from '../TrainingDraft/ChangeLog';
import { deployEvent } from '../../shared/amplitudeEvents/AmplitudeEvents';
import { AmplitudeEventNames } from '../../shared/amplitudeEvents/amplitude-events-types';
import LocationPseudoMember from './LocationPseudoMemberModal/LocationPseudoMember';
import { Authorize, AuthRole } from '../../authorization';
// import ToggleButton from './ToggleButton';

import TrainingPathTable from '../../sub-components/TrainingPathTable';
import EmptyState from '../../sub-components/EmptyState';

function SortByAssignee(a: any, b: any, c: string) {
  const valA = a.values[c]?.length;
  const valB = b.values[c]?.length;
  return valB - valA;
}

const Training: FC = () => {
  const toast = useToast({
    position: 'top-right',
  });
  const { t } = useTranslation(['training', 'common', 'home']);
  const userObject = useReactiveVar(userObj);
  const [selectedTrainingData, setSelectedTrainingData] = useState<any>(null);
  const [isTrainingPathModalOpen, setIsTrainingPathModalOpen] = useState(false);
  const [isCardModalOpened, setIsCardModalOpened] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [myTrainingSearchQuery, setMyTrainingSearchQuery] = useState('');
  const [progressTimelineModal, setProgressTimelineModal] = useState(false);
  const [openLocationPseudoTrainingModal, setOpenLocationPseudoTrainingModal] =
    useState({ show: false, data: {} as UserData, item: {} as TrainingData });
  const [shouldShowChangeLogModal, setShouldShowChangeLogModal] =
    useState(false);
  const [selectedDraftData, setSelectedDraftData] = useState<any>({});
  const [trainingList, setTrainingListData] = useState<TrainingData[]>([]);
  const [selectedOption, setSelectedOption] = useState<
    'trainingPath' | 'myTraining'
  >('trainingPath');
  const [currentPageData, setCurrentPageData] = useState({
    currentPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    itemCount: 1,
    pageCount: 1,
    perPage: 1,
  });

  const [
    trainingListData,
    { data: getTrainingListData, loading: trainingDataLoading },
  ] = useLazyQuery(TrainingPathsTableData, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCurrentPageData(data?.userTrainingNew?.pageInfo);
      addColorsHandler(data.userTrainingNew.items);
    },
  });

  const [updateTraining] = useMutation(UPDATE_TRAINING, {
    onCompleted: (data) => {
      toast({
        title: t('training:training_path_update_success_toast'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchTrainingListDataHandler();
    },
    onError: () => {
      toast({
        title: t('training:training_path_update_error_toast'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      fetchTrainingListDataHandler();
    },
  });

  const addColorsHandler = (data: TrainingData[]) => {
    let _data: TrainingData[] = JSON.parse(JSON.stringify(data));
    let activeData = _data?.filter((path) => path?.status === 'active');
    let inactiveData = _data?.filter((path) => path?.status !== 'active');
    let _combinedData = [...activeData, ...inactiveData];
    let colorArr = [
      { color: '#cabdff' },
      { color: '#ffbc99' },
      { color: '#b1e5fc' },
      { color: '#b5e4ca' },
      { color: '#ffd88d' },
    ];
    _combinedData.map((val, index) => {
      let num = index % colorArr.length;
      val.color = colorArr[num].color;
      val.cardCount = val?.trainingContentList?.filter(
        (value) => value.type === 'card'
      )?.length;
    });
    setTrainingListData(_combinedData);
  };

  useEffect(() => {
    fetchTrainingListDataHandler();
  }, [userObject]);

  const fetchTrainingListDataHandler = (
    currentPage = currentPageData?.currentPage,
    query = searchQuery
  ) => {
    let _query;
    if (!query) {
      _query = null;
    } else {
      _query = query;
    }

    if (userObject.type === 'branch') {
      return trainingListData({
        variables: {
          page: currentPage,
          perPage: 10,
          filter: {
            query: _query,
            assigned: true,
          },
          sort: 'CREATEDAT_DESC',
        },
      });
    } else if ([SUPER_ADMIN, ADMIN].includes(userObject?.authRole)) {
      trainingListData({
        variables: {
          page: currentPage,
          perPage: 10,
          filter: {
            query: _query,
            status: 'active',
          },
          sort: 'CREATEDAT_DESC',
        },
      });
    } else {
      trainingListData({
        variables: {
          page: currentPage,
          perPage: 10,
          filter: {
            query: _query,
            supervisor: true,
            assigned: true,
          },
          sort: 'CREATEDAT_DESC',
        },
      });
    }
  };

  const [singleTrainingHandler] = useLazyQuery(getSingleTrainingData, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _item: TrainingData = JSON.parse(JSON.stringify(data?.trainingById));
      _item?.trainingContentList?.forEach((it: any) => {
        it.completed = false;
        _item?.userProgress?.map((progress) => {
          if (progress?.userId === userObject?.eid) {
            _item?.userProgress?.[0]?.viewedContents?.forEach(
              (content: any) => {
                if (it?.uid === content?.uid) {
                  it.completed = true;
                }
              }
            );
          }
        });
      });
      setSelectedTrainingData(_item);
      setIsTrainingPathModalOpen(true);
    },
    onError: (error) => {
      toast({
        title: t('training:training_path_open_error_toast'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const ref = useRef(null);
  const history = useHistory();
  const getColumns = (isTablet: boolean) => {
    let tableColumns: any = [
      {
        Header: t('training:path_name'),
        accessor: 'pathName' as const,
        width: '32%',
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <PathName
              pathName={value}
              thumbnail={row?.original?.thumbnail}
              status={row?.original?.status}
              updatedAt={row?.original?.updatedAt}
              lastUpdatedBy={row?.original?.lastUpdatedBy}
              visibility={row?.original?.visibility}
              index={row.index}
              onClick={() => clickedItemHandler('open', row?.original?.item, 1)}
            />
          );
        },
        sortType: SortByName,
      },
      {
        Header: t('training:cards'),
        accessor: 'cardCount' as const,
        width: '8%',
        Cell: ({ cell: { value, row } }: any) => {
          return <CardsCount count={value} status={row?.original?.status} />;
        },
        sortType: SortByNumber,
      },
      {
        Header: t('training:assignee'),
        accessor: 'assignee' as const,
        width: '10%',
        Cell: ({ cell: { value, row } }: any) => {
          return <Assignee assignees={value} status={row?.original?.status} />;
        },
        sortType: SortByAssignee,
      },
      {
        Header: t('training:created_by'),
        accessor: 'createdBy' as const,
        width: '15%',
        Cell: ({ cell: { value, row } }: any) => {
          return <CreatedBy name={value} status={row?.original?.status} />;
        },
        sortType: SortByName,
      },
      {
        Header: t('training:created_on'),
        accessor: 'createdOn' as const,
        width: '15%',
        Cell: ({ cell: { value, row } }: any) => {
          return <DateCell date={value} status={row?.original?.status} />;
        },
        sortType: SortByDate,
      },
      {
        Header: t('training:last_activity'),
        accessor: 'updatedAt' as const,
        width: '15%',
        Cell: ({ cell: { value, row } }: any) => {
          return <DateCell date={value} status={row?.original?.status} />;
        },
        sortType: SortByDate,
      },
      {
        Header: '',
        accessor: 'action' as const,
        width: '5%',
        disableSortBy: true,
      },
    ];
    return tableColumns;
  };

  const openCardModal = (cardItem) => {
    setIsCardModalOpened(true);
  };

  const openTraining = (item: TrainingData) => {
    deployEvent(AmplitudeEventNames.TRAINING_OPEN_PATH);
    if (userObject?.type === 'branch') {
      return setOpenLocationPseudoTrainingModal({
        data: userObject,
        show: true,
        item: item,
      });
    } else if ([SUPER_ADMIN, ADMIN].includes(userObject?.authRole)) {
      history.push(`/training/${item.eid}`);
    } else if (
      ([MANAGER].includes(userObject?.authRole) &&
        !item?.assignedToUsers?.some(
          (assign) => assign?.eid === userObject?.eid
        )) ||
      item?.createdBy?.eid === userObject?.eid ||
      item?.supervisors?.includes(userObject?.eid)
    ) {
      history.push(`/training/${item.eid}`);
    } else {
      if (userObject?.authRole === WORKER) {
        singleTrainingHandler({
          variables: {
            eid: item?.eid,
          },
        });
      } else {
        let isSupervisor = item?.supervisors?.find(
          (supervisor: string) => supervisor === userObject?.eid
        );
        let isAssignedTo = item?.assignedToUsers
          ?.filter((assigned) => assigned?.eid === userObject?.eid)
          ?.map((user) => user?.eid);
        history.push(`/view/${item.eid}`);
        // if ((isAssignedTo?.[0] && isSupervisor) || isSupervisor) {
        // } else {
        //   singleTrainingHandler({
        //     variables: {
        //       eid: item?.eid,
        //     },
        //   });
        //   setIsTrainingPathModalOpen(true);
        // }
      }
    }
  };

  const clickedItemHandler = (menuClicked: string, item: TrainingData) => {
    if (
      item?.status === 'inactive' &&
      [MANAGER, WORKER].includes(userObject.authRole)
    ) {
      return;
    }
    if (menuClicked === 'open' || menuClicked === 'track-progress') {
      return openTraining(item);
    } else if (menuClicked === 'edit') {
      deployEvent(AmplitudeEventNames.TRAINING_EDIT_PATH);
      history.push(`/training/${item.eid}/edit`);
    } else if (menuClicked === 'view-changelog') {
      setShouldShowChangeLogModal(true);
      setSelectedDraftData(item);
    } else {
      let supervisorsIdArray: TrainingData['supervisors'] = [];
      let assignedToIdArray: string[] = [];
      let contentsArray: ContentDetailsEntity[] = [];

      if (item?.supervisors && item?.supervisors?.length > 0) {
        supervisorsIdArray = item?.supervisors;
        // supervisorsIdArray = item?.supervisors.map(
        //   (singleItem: any) => singleItem?.eid
        // );
      }

      if (item?.assignedTo && item?.assignedTo?.length > 0) {
        assignedToIdArray = item?.assignedTo.map(
          (singleItem) => singleItem?.eid
        );
      }

      if (item?.contentDetails?.length) {
        item?.contentDetails?.forEach((content: any) => {
          if (content?.type === 'milestone') {
            contentsArray?.push({
              type: content?.type,
              eid: content?.eid,
              title: content?.title,
              backgroundColor: content?.backgroundColor,
            });
          } else {
            contentsArray?.push({
              type: content?.type,
              eid: content?.eid,
              title: content?.title,
            });
          }
        });
      }

      let input = {
        eid: item?.eid,
        title: item?.title,
        status: item?.status === 'active' ? 'inactive' : 'active',
        thumbnail: item?.thumbnail,
        description: item?.description,
        supervisors: supervisorsIdArray,
        assignedTo: assignedToIdArray,
        contents: contentsArray,
      };
      updateTraining({
        variables: {
          input: input,
        },
      });
    }
  };

  const getMenuData = (menuData: TrainingData): IActionMenuData[] => {
    // if (menuData?.status === 'active') {
    //   return [
    //     {
    //       name: 'Open',
    //       icon: OpenIcon,
    //     },
    //     {
    //       name: 'Edit',
    //       icon: DarkEditIcon,
    //     },
    //     {
    //       name: 'Mark as Inactive',
    //       icon: ArchiveIcon,
    //     },
    //   ];
    // } else {
    //   return [
    //     {
    //       name: 'Mark as Active',
    //       icon: ArchiveIcon,
    //     },
    //   ];
    // }
    if (userObject?.type === 'branch') {
      return [
        {
          name: t('common:open'),
          value: 'open',
          icon: OpenIcon,
        },
      ];
    } else {
      if (
        userObject?.authRole === SUPER_ADMIN ||
        userObject?.authRole === ADMIN
      ) {
        if (menuData?.status === 'active') {
          return [
            {
              name: t('training:track_progress'),
              value: 'track-progress',
              icon: ProgressIcon,
            },
            {
              name: t('common:edit'),
              value: 'edit',
              icon: DarkEditIcon,
            },
            {
              name: t('training:view_changelog'),
              value: 'view-changelog',
              icon: viewChangeLogIcon,
            },
            {
              name: t('training:mark_as_inactive'),
              value: 'mark-inactive',
              icon: ArchiveIcon,
            },
          ];
        } else {
          return [
            {
              name: t('training:track_progress'),
              value: 'track-progress',
              icon: ProgressIcon,
            },
            {
              name: t('training:view_changelog'),
              value: 'view-changelog',
              icon: viewChangeLogIcon,
            },
            {
              name: t('training:markActive'),
              value: 'mark-active',
              icon: ArchiveIcon,
            },
          ];
        }
      } else {
        if (menuData?.status === 'inactive') {
          return null;
        }
        return [
          {
            name: t('common:open'),
            value: 'open',
            icon: OpenIcon,
          },
        ];
        // if (menuData?.status === 'active') {
        // } else {
        //   return [
        //     {
        //       name: 'Mark as Active',
        //       icon: ArchiveIcon,
        //     },
        //   ];
        // }
      }
    }
  };

  const getTableData = (isTablet: boolean) => {
    const tableData = trainingList?.map((item, index: number) => ({
      item: item,
      pathName: item.title?.trim(),
      assignee: item.assignedToUsers,
      cardCount: item.cardCount,
      createdBy: item.createdBy?.name,
      createdOn: item.createdAt,
      updatedAt: item.updatedAt,
      lastUpdatedBy: item.lastUpdatedBy,
      status: item.status,
      thumbnail: item.thumbnail,
      visibility: item.visibility,
      action: (
        <div
          id={item.eid}
          style={{
            cursor: 'pointer',
            marginBottom: '25px',
          }}
        >
          {[WORKER, MANAGER].includes(userObject.authRole) &&
          item?.status === 'inactive' ? (
            <Box>
              <FontAwesomeIcon
                icon={faEllipsis as IconProp}
                size='2x'
                color='#33383F'
                style={{
                  opacity: '.5',
                  cursor: 'no-drop',
                }}
              />
            </Box>
          ) : (
            <TableActionMenu
              menuData={getMenuData(item)}
              arrowSize={10}
              closeOnBlur={true}
              offset={[50, 5]}
              clickedItem={(_, clickedItem) => {
                clickedItemHandler(clickedItem, item);
              }}
            >
              <Box>
                <FontAwesomeIcon
                  icon={faEllipsis as IconProp}
                  size='2x'
                  color='#33383F'
                  style={{
                    opacity: item?.status === 'active' ? '1' : '.5',
                  }}
                />
              </Box>
            </TableActionMenu>
          )}
        </div>
      ),
    }));
    return [...tableData];
  };

  const progressTimelinehandler = () => {
    setProgressTimelineModal(false);
  };

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value);
    // fetchTrainingListDataHandler(currentPageData?.currentPage, e.target.value);
    fetchTrainingListDataHandler(1, e.target.value);
    // trainingListData({
    //   variables: {
    //     page: 1,
    //     perPage: 10,
    //     filter: {
    //       query: e.target.value,
    //     },
    //     sort: '_ID_DESC',
    //   },
    // });
  };

  const handleMyTrainingQueryChange = (e) => {
    setMyTrainingSearchQuery(e.target.value);
  };

  const onPageChangeHandler = (pageData: number) => {
    setCurrentPageData({ ...currentPageData, currentPage: pageData });
    fetchTrainingListDataHandler(pageData);
  };

  const onCloseTrainingModal = () => {
    setIsTrainingPathModalOpen(false);
  };

  const changeModalLogHandler = (type: string) => {
    if (type === 'close') {
      setShouldShowChangeLogModal(false);
    }
  };

  return (
    <DashboardContainer>
      {/* <div className='training-coming-soon-container'>
        <img src={comingSoonImage} />
      </div> */}
      <>
        <div className='training-title-wrapper'>
          <div className='training-title-container'>
            {t('training:training_progress')}
          </div>
          <div
            style={{
              marginBottom: '24px',
              marginTop: '7px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {/* <PrimaryButton
              type='button'
              size='md'
              title='Show Progress Timeline'
              colorScheme='blue'
              // onClick={() => setProgressTimelineModal(true)}
              onClick={() => history.push('/training/path')}
            /> */}
            <Authorize
              permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
              permittedFor={'user'}
            >
              <PrimaryButton
                leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
                type='button'
                size='md'
                title={t('training:add_new_path')!}
                colorScheme='blue'
                onClick={() => {
                  deployEvent(
                    AmplitudeEventNames.TRAINING_ADD_NEW_PATH_BUTTON_CLICK
                  );
                  history.push('/create-path');
                }}
              />
            </Authorize>
          </div>
        </div>
        <div className='training-container'>
          <Flex mb='10px' gap='16px' justify='space-between'>
            <Flex align='center' gap='16px'>
              <Box w='16px' h='32px' bg='#cabdff' borderRadius='4px' />
              <div
                style={{
                  fontWeight: '600',
                  lineHeight: '1.6',
                  fontSize: '18px',
                  fontFamily: 'Inter',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                }}
              >
                {t(
                  selectedOption === 'trainingPath'
                    ? 'training:training_paths'
                    : 'training:my_trainings'
                )}
              </div>
              {selectedOption === 'trainingPath' && (
                <SearchInput
                  disabled={true}
                  size='md'
                  width='435px'
                  hideShortcuts={true}
                  placeholder={t('training:search_paths')!}
                  onChange={handleQueryChange}
                  value={searchQuery}
                  className='training-search'
                />
              )}
              {selectedOption === 'myTraining' && (
                <SearchInput
                  disabled={true}
                  size='md'
                  width='435px'
                  hideShortcuts={true}
                  placeholder={t('training:search_my_training')}
                  onChange={handleMyTrainingQueryChange}
                  value={myTrainingSearchQuery}
                  className='training-search'
                />
              )}
            </Flex>
            <Flex gap={4}>
              <Text
                cursor='pointer'
                onClick={() => {
                  setSelectedOption('trainingPath');
                  setSearchQuery('');
                  setMyTrainingSearchQuery('');
                }}
                style={{
                  color:
                    selectedOption === 'trainingPath' ? 'black' : '#6f767e',
                  fontSize: '15px',
                  fontWeight: '600',
                  padding: '8px 16px',
                  backgroundColor:
                    selectedOption === 'trainingPath'
                      ? '#efefef'
                      : 'transparent',
                }}
              >
                {t('training:training_paths')}
              </Text>
              <Text
                cursor='pointer'
                onClick={() => {
                  setSelectedOption('myTraining');
                  setSearchQuery('');
                  setMyTrainingSearchQuery('');
                }}
                style={{
                  color: selectedOption === 'myTraining' ? 'black' : '#6f767e',
                  fontSize: '15px',
                  fontWeight: '600',
                  padding: '8px 16px',
                  backgroundColor:
                    selectedOption === 'myTraining' ? '#efefef' : 'transparent',
                }}
              >
                {t('training:my_trainings')}
              </Text>
            </Flex>
          </Flex>
          {selectedOption === 'trainingPath' && (
            <div ref={ref} style={{ marginBottom: '2rem' }}>
              <SortingTable
                colorScheme='blue'
                emptyData={{
                  content: (
                    <EmptyState
                      image='TrainingPath'
                      title={t('training:nothingToLearn')}
                      description={t('training:howToStart')}
                    />
                  ),
                }}
                page={currentPageData?.currentPage}
                onPageChange={onPageChangeHandler}
                columns={getColumns(false)}
                data={getTableData(false)}
                isLoading={trainingDataLoading}
                totalRegisters={currentPageData?.itemCount}
                isResponsive
                isHidePagination={
                  (currentPageData?.currentPage === 1 &&
                    currentPageData?.hasNextPage) ||
                  currentPageData?.currentPage > 1
                    ? false
                    : true
                }
              />
            </div>
          )}
          {selectedOption === 'myTraining' && (
            <TrainingPathTable
              showMainTitle={false}
              showBackNavigation={false}
              heading={t('home:training_assigned_you')}
              showHeading={false}
              searchQuery={myTrainingSearchQuery}
              showSearchInput={true}
              // userTrainingData={userTrainingList}
              // searchTrainingPathHandler={searchTrainingPathHandler}
            />
          )}
          {isTrainingPathModalOpen && (
            <TrainingPathModal
              open={isTrainingPathModalOpen}
              onClose={onCloseTrainingModal}
              selectedTrainingData={selectedTrainingData}
            />
          )}
          {/* <ListPagination
            onPageChange={(page) => setPage(page)}
            data={deckData}
            totalRegisters={deckApiData?.handbookPagination?.count}
            page={page}
          /> */}
        </div>
        {/* {cardSelected && (
          <CardPreview
            cardSelected={cardSelected}
            onCloseClick={() => setCardSelected(null)}
            onSubmit={onCardSuccess}
          />
        )} */}
        {/* {isCardModalOpened && (
          <CardEditorModal
            open={isCardModalOpened}
            onClose={closeCardModal}
            onSubmit={onCardSuccess}
            isEdit={isEdit}
            cardId={cardSelected?.eid}
          />
        )} */}
        {/* {progressTimelineModal && (
          <ProgressTimeline
            timelineData={progressData}
            onCloseClick={progressTimelinehandler}
            milestoneSuccessImg={MilestoneSuccess}
            userName={'Lindsey'}
          />
        )} */}
      </>
      {shouldShowChangeLogModal && (
        <ChangeLog
          isReadOnly={true}
          open={shouldShowChangeLogModal}
          onClose={(type: string) => changeModalLogHandler(type)}
          selectedDraftData={selectedDraftData}
        />
      )}
      <LocationPseudoMember
        isOpen={openLocationPseudoTrainingModal.show}
        onClose={() =>
          setOpenLocationPseudoTrainingModal({
            ...openLocationPseudoTrainingModal,
            show: false,
          })
        }
        locationId={openLocationPseudoTrainingModal.data.eid}
        selectedTrainingData={openLocationPseudoTrainingModal.item}
      />
    </DashboardContainer>
  );
};

export default Training;

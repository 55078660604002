import { Box, Flex, Tooltip } from '@chakra-ui/react';
import React, { FC, useContext } from 'react';
import TrainingPathContext from '../TrainingPathStore/training-path-context';
import PrimaryButton from 'atoms/PrimaryButton';
import { ViewIcon } from '@chakra-ui/icons';

const TrainingPathActionButtons: FC<{
  savingAsDraft: boolean;
  isPublishingChanges: boolean;
  creatingDraft: boolean;
  savingDraft: boolean;
  training_preview_button: string;
  training_save_as_draft_button: string;
  training_save_and_next_button: string;
  training_publish_button: string;
  training_save_button: string;
  training_preview_publish_button: string;
  training_create_draft_button: string;
  training_prevent_publish_selection: string;
  setTrainingProgressModal: React.Dispatch<React.SetStateAction<boolean>>;
  saveAsDraftHandler: () => void;
  moveToNextStepHandler: () => void;
  publishHandler: () => void;
  saveUpdatedDraftHandler: () => void;
  publishTrainingDraftHandler: () => void;
  saveDraftHandler: () => void;
  createDraftHandler: () => void;
  saveAndNextSaveAsDraftHandler: () => void;
  previewClickBtn: () => void;
}> = ({
  setTrainingProgressModal,
  savingAsDraft,
  savingDraft,
  training_preview_button,
  training_save_as_draft_button,
  training_save_and_next_button,
  training_publish_button,
  training_save_button,
  training_preview_publish_button,
  training_create_draft_button,
  training_prevent_publish_selection,
  saveAsDraftHandler,
  moveToNextStepHandler,
  publishHandler,
  isPublishingChanges,
  creatingDraft,
  saveUpdatedDraftHandler,
  publishTrainingDraftHandler,
  saveDraftHandler,
  createDraftHandler,
  saveAndNextSaveAsDraftHandler,
  previewClickBtn,
}) => {
  const trainingPathCtx = useContext(TrainingPathContext);

  const getActionButtonsHandler = () => {
    if (
      trainingPathCtx?.trainingPathType === 'add' &&
      trainingPathCtx?.isCreate
    ) {
      return (
        <Flex gap={5}>
          <PrimaryButton
            title={training_preview_button}
            leftIcon={<ViewIcon />}
            size={'lg'}
            disabled={trainingPathCtx?.addedItems?.length === 0}
            variant={'outline'}
            style={{ backgroundColor: 'white' }}
            onClick={() => {
              previewClickBtn();
            }}
          />
          {/* {trainingPathCtx?.stepCount === 2 && (
            <PrimaryButton
              title={training_save_as_draft_button}
              disabled={
                !trainingPathCtx?.trainingPathName?.trim() || savingAsDraft
              }
              size={'lg'}
              variant={'outline'}
              isLoading={savingAsDraft}
              style={{ backgroundColor: 'white' }}
              onClick={saveAsDraftHandler}
            />
          )} */}
          {trainingPathCtx?.stepCount === 1 ? (
            <PrimaryButton
              title={training_save_and_next_button}
              disabled={
                !trainingPathCtx?.trainingPathName?.trim() ||
                trainingPathCtx?.addedItems?.length === 0
              }
              variant={'solid'}
              size={'lg'}
              style={{ backgroundColor: 'rgb(42, 133, 255)', color: 'white' }}
              onClick={moveToNextStepHandler}
            />
          ) : (
            <Tooltip
              label={training_prevent_publish_selection}
              hasArrow
              placement='bottom-end'
              isDisabled={
                trainingPathCtx?.selectedRoles?.length > 0 ||
                trainingPathCtx?.selectedLocations?.length > 0 ||
                trainingPathCtx?.selectedMembers?.length > 0
              }
            >
              <Box>
                <PrimaryButton
                  title={training_publish_button}
                  variant={'solid'}
                  disabled={
                    (trainingPathCtx?.selectedRoles?.length === 0 &&
                      trainingPathCtx?.selectedLocations?.length === 0 &&
                      trainingPathCtx?.selectedMembers?.length === 0) ||
                    savingAsDraft
                  }
                  size={'lg'}
                  style={{ backgroundColor: '#83bf6e', color: 'white' }}
                  onClick={publishHandler}
                />
              </Box>
            </Tooltip>
          )}
        </Flex>
      );
    } else if (
      trainingPathCtx?.trainingPathType === 'edit' &&
      !trainingPathCtx?.isCreate
    ) {
      // UPDATE TRAINING DRAFT
      return (
        <Flex gap={5}>
          <PrimaryButton
            title={training_save_button}
            size={'lg'}
            width='fit-content'
            variant={'outline'}
            isLoading={isPublishingChanges}
            disabled={
              !trainingPathCtx?.trainingPathName?.trim() ||
              isPublishingChanges ||
              creatingDraft
            }
            style={{ backgroundColor: 'white' }}
            onClick={saveUpdatedDraftHandler}
          />
          {trainingPathCtx?.stepCount === 1 ? (
            <PrimaryButton
              title={training_save_and_next_button}
              disabled={
                !trainingPathCtx?.trainingPathName?.trim() ||
                trainingPathCtx?.addedItems?.length === 0
              }
              variant={'solid'}
              size={'lg'}
              style={{ backgroundColor: 'rgb(42, 133, 255)', color: 'white' }}
              onClick={moveToNextStepHandler}
            />
          ) : (
            <PrimaryButton
              title={training_preview_publish_button}
              variant={'solid'}
              isLoading={isPublishingChanges}
              disabled={
                (trainingPathCtx?.selectedRoles?.length === 0 &&
                  trainingPathCtx?.selectedLocations?.length === 0 &&
                  trainingPathCtx?.selectedMembers?.length === 0) ||
                creatingDraft ||
                isPublishingChanges
              }
              size={'lg'}
              style={{
                backgroundColor: '#83bf6e',
                color: 'white',
                width: 'fit-content',
              }}
              onClick={publishTrainingDraftHandler}
            />
          )}
        </Flex>
      );
    } else {
      return (
        <Flex gap={5}>
          <PrimaryButton
            title={training_preview_button}
            leftIcon={<ViewIcon />}
            size={'lg'}
            disabled={trainingPathCtx?.addedItems?.length === 0}
            variant={'outline'}
            style={{ backgroundColor: 'white' }}
            onClick={() => {
              previewClickBtn();
            }}
          />
          {/* {trainingPathCtx?.stepCount === 2 && (
                            )} */}
          <PrimaryButton
            title={training_save_button}
            size={'lg'}
            variant={'outline'}
            isLoading={savingDraft}
            disabled={
              !trainingPathCtx?.trainingPathName?.trim() ||
              savingDraft ||
              creatingDraft
            }
            style={{ backgroundColor: 'white' }}
            onClick={saveDraftHandler}
          />
          {trainingPathCtx?.stepCount === 1 ? (
            <PrimaryButton
              title={training_save_and_next_button}
              disabled={
                !trainingPathCtx?.trainingPathName?.trim() || savingAsDraft
              }
              isLoading={savingAsDraft}
              variant={'solid'}
              size={'lg'}
              style={{ backgroundColor: 'rgb(42, 133, 255)', color: 'white' }}
              onClick={moveToNextStepHandler}
              // onClick={saveAndNextSaveAsDraftHandler}
            />
          ) : (
            <PrimaryButton
              title={training_create_draft_button}
              variant={'solid'}
              isLoading={creatingDraft}
              disabled={
                (trainingPathCtx?.selectedRoles?.length === 0 &&
                  trainingPathCtx?.selectedLocations?.length === 0 &&
                  trainingPathCtx?.selectedMembers?.length === 0) ||
                savingDraft ||
                creatingDraft
              }
              size={'lg'}
              style={{ backgroundColor: '#83bf6e', color: 'white' }}
              onClick={createDraftHandler}
            />
          )}
        </Flex>
      );
    }
  };

  return getActionButtonsHandler();
};

export default TrainingPathActionButtons;

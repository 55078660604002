import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

// const ExperienceData = ['Training paths', 'Search', 'Home', 'Chat'] as const;
const ExperienceData = ['Training paths', 'Search', 'Task', 'Form'] as const;

export const DefaultValue = ExperienceData[0];

interface IProps {
  value?: string;
  onChange: (newVal: string) => void;
}

const ExperienceSideBar: FC<IProps> = ({ value, onChange }) => {
  return (
    <Flex direction='column' gap='8px'>
      {ExperienceData.map((item, index) => {
        return (
          <Flex
            align='center'
            key={index}
            h='46px'
            borderRadius='8px'
            px={3}
            bg={value === item ? '#EFEFEF' : 'unset'}
            cursor='pointer'
            onClick={() => onChange(item)}
          >
            <Text
              fontSize='15px'
              fontWeight='600'
              fontFamily='Inter'
              color='#6F767E'
            >
              {item}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default ExperienceSideBar;

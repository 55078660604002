import { gql } from '@apollo/client';

export const FORMS_PAGINATION = gql`
  query FormsPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyFormsInput
    $sort: SortFindManyFormsInput
  ) {
    FormsPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        category
        appearsInSop {
          eid
          title
        }
        contributors
        createdBy {
          eid
          name
          username
        }
        eid
        responseCount
        status
        title
        thumbnail
        updatedAt
        lastUpdatedBy {
          name
        }
        visibility
        visibleTo {
          condition
          locations
          roles
          users
        }
      }
    }
  }
`;

export const FORMS_RESPONSE_PAGINATION = gql`
  query FormsResponseListPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyFormsInput
    $sort: SortFindManyFormsInput
  ) {
    FormsResponseListPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        category
        createdAt
        createdBy {
          eid
          name
          username
        }
        description
        eid
        entityId
        updatedAt
        lastUpdated
        thumbnail
        title
        lastUpdatedBy {
          eid
          name
        }
        responseCount
      }
    }
  }
`;

import React, { FC, useState } from 'react';
import { Box } from '@chakra-ui/react';
import ActivityFeed from '../../../../ui-components/ActivityFeed';
import { SingleUserChatModal } from 'ui-components';

interface IProps {
  userId: string;
}

const MyActivityComponent: FC<IProps> = ({ userId }) => {
  const [showSingleUserChatModal, setShowSingleUserChatModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const setShowSingleUserChatModalHandler = (value: any) => {
    setShowSingleUserChatModal(value);
  };

  const setSelectedUserHandler = (user: any) => {
    setSelectedUser(user?.eid);
  };

  return (
    <Box mt='16px'>
      <ActivityFeed
        hideHeader
        showSpecificUser={userId}
        setShowSingleUserChatModalHandler={setShowSingleUserChatModalHandler}
        setSelectedUserHandler={setSelectedUserHandler}
      />
      {showSingleUserChatModal && (
        <SingleUserChatModal
          onClose={() => setShowSingleUserChatModal(false)}
          selectedUser={selectedUser}
        />
      )}
    </Box>
  );
};

export default MyActivityComponent;

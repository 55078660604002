import React, {
  ChangeEventHandler,
  FC,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useReactiveVar } from '@apollo/client';
import { AlertDialogCloseButton, Box, Flex } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

import { userObj } from 'sop-commons/src/client';

import { SearchInput, SelectOption } from 'atoms';

import { LocationContext } from './Context';
import { AuthRoleFilter } from 'sub-components/CustomDropdowns';
import { ISelectedFilter } from 'sub-components/CustomDropdowns/AuthRoleFilter/AuthRoleFilter';
import { LocationSelect } from './location-select';

interface HeaderProps {
  onFilterChange: (option: ISelectedFilter) => void;
  onBackClick: () => void;
  onClose: () => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const MoveToLocationHeader: FC<HeaderProps> = ({
  onFilterChange,
  onBackClick,
  onClose,
  onChange,
}) => {
  const { t } = useTranslation('location');
  const [selectedFilter, setSelectedFilter] = useState<ISelectedFilter>(null);

  return (
    <Box>
      <Flex gap='12px' align='center'>
        <Box cursor='pointer'>
          <FontAwesomeIcon
            icon={faArrowLeft as IconProp}
            fontSize='17px'
            onClick={onBackClick}
          />
        </Box>
        <Box
          flex={1}
          fontWeight='600'
          fontSize='20px'
          lineHeight='32px'
          color='#1a1d1f'
          userSelect='none'
        >
          {t('moveToAnotherLocation')}
        </Box>
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
          onClick={(event) => {
            event.preventDefault();
            onClose?.();
          }}
        />
      </Flex>
      <Flex
        mt={3}
        fontSize='14px'
        fontWeight={400}
        color='#33383F'
        bg='rgba(177, 229, 252, 0.4)'
        py='12px'
        px='16px'
        borderRadius='10px'
        gap='12px'
        align='center'
      >
        <FontAwesomeIcon icon={faArrowUpRight as IconProp} color='#2A85FF' />
        {t('movingToAnotherLocation')}
      </Flex>
      <Flex w='full' mt={3} gap='10px'>
        <Box w='60%'>
          <SearchInput
            size='md'
            width='100%'
            hideShortcuts
            placeholder={t('searchMember')}
            disabled
            onChange={onChange}
          />
        </Box>
        <Box w='40%'>
          <AuthRoleFilter
            selectedFilter={selectedFilter}
            onFilterChange={(option: ISelectedFilter) => {
              let _option: ISelectedFilter = null;
              if (option?.value === selectedFilter?.value) {
                _option = null;
              } else {
                _option = option;
              }
              setSelectedFilter(_option);
              onFilterChange(_option);
            }}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export const MoveToLocationFooter: FC = () => {
  const locationEntities = useReactiveVar(userObj)?.entity?.locations || [];
  const { locationId, totalCount, selectRef } = useContext(LocationContext);

  const locationList = useMemo(() => {
    return locationEntities.reduce<SelectOption[]>((previousValue, item) => {
      if (item.eid !== locationId) {
        previousValue.push({
          label: item?.name,
          value: item?.eid,
        });
      }
      return previousValue;
    }, []);
  }, [locationEntities, locationId]);

  if (totalCount === 0) {
    return <Box />;
  }

  return (
    <Box width='full'>
      <LocationSelect ref={selectRef} options={locationList} />
    </Box>
  );
};

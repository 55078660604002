import React, { FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment/moment';
import { Trans, useTranslation } from 'react-i18next';

import { TrainingContType } from 'types';
import { toArray } from 'utils/utils';
import { IconImage } from 'ui-components';

import { CommonListItem } from '../common';
import { TrainingItem } from '../formChapter.graphql';

interface IProps {
  data: TrainingItem;
  index: number;
}

function chapterFilter<T extends TrainingItem['contents'][number]>(value: T) {
  return value.type === TrainingContType.SOP;
}

const TrainingListItem: FC<IProps> = ({ data }) => {
  const { t } = useTranslation(['task']);

  const count = useMemo(() => {
    return toArray(data.contents).filter(chapterFilter).length;
  }, [data.contents]);

  return (
    <CommonListItem data={data}>
      <Flex flex={1} alignItems='center' overflow='hidden'>
        <Flex mr='1rem' gap='10px' maxWidth='100%'>
          <IconImage
            name={data.title}
            thumbnail={data.thumbnail}
            boxSize={60}
          />

          <Flex flexDir='column' overflow='hidden'>
            <Box fontSize='12px' fontWeight='500' color='#6F767E'>
              <Trans
                t={t}
                i18nKey='task:noOfChapter'
                count={count}
                values={{ value: count }}
              />
            </Box>
            <Box fontSize='15px' fontWeight='600' color='#1A1D1F' isTruncated>
              {data?.title}
            </Box>
            <Box fontSize='12px' fontWeight='400' color='#999999' isTruncated>
              Last updated: {moment(data.updatedAt).format('DD MMMM')} by{' '}
              {data.lastUpdatedBy?.name}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </CommonListItem>
  );
};

export default TrainingListItem;

import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { Center, Flex, Text, useToast } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolder, faFolderUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, SearchInput } from 'atoms';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import { chakraComponents } from 'chakra-react-select';
import { useFolderSubFolder } from 'hooks';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import { BULK_MOVE_SOP, GET_CHAPTERS } from 'pages/Chapters/chapters.graphql';
import { GET_USER } from 'pages/Login/login.graphql';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';
import { CategoryEntity } from 'sop-commons/src/client';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import EmptyState from 'sub-components/EmptyState';
import { CustomCategoryEntity } from '../ChapterHeader/components/BulkMoveContainer';
import { ChapterModal } from '../modal';

const BULK_MOVE = gql`
  mutation BulkMoveContent($input: MoveContentTypeInput) {
    bulkMoveContent(input: $input) {
      succeed
    }
  }
`;

interface IProps {
  chapterDetails?: ChapterModal;
  onCloseHandler: (data?: CustomCategoryEntity) => void;
}

const ChapterMoveContainer: FC<IProps> = ({
  chapterDetails,
  onCloseHandler,
}) => {
  const { t } = useTranslation(['chapter', 'handbook']);
  const [searchQuery, setSearchQuery] = useState('');
  const [typeFilter, setTypeFilter] = useState<SelectOption | null>(null);
  const { getAllFolderSubFolderList } = useFolderSubFolder();
  const [selectedItem, setSelectedItem] = useState<CustomCategoryEntity>(
    {} as CustomCategoryEntity
  );
  const toast = useToast({
    position: 'top-right',
    isClosable: true,
    duration: 3000,
  });
  const foldersList = useReactiveVar(foldersListVar);

  const [moveItems, { loading }] = useMutation(BULK_MOVE, {
    onCompleted: () => {
      toast({
        title: t('chapter:chapterMoveSuccess'),
        status: 'success',
      });
      onCloseHandler(selectedItem);
    },
    onError: () => {
      toast({
        title: t('chapter:chapterMoveError'),
        status: 'error',
      });
      onCloseHandler();
    },
    refetchQueries: [
      { query: GET_USER },
      { query: GET_CHAPTERS },
      { query: GET_CHAPTERS },
    ],
    awaitRefetchQueries: true,
  });

  const itemsList = useMemo(() => {
    let allFolderSubFolders: CustomCategoryEntity[] =
      getAllFolderSubFolderList();
    let filteredFolderSubFolderList = allFolderSubFolders?.filter(
      (all) => all?.eid !== chapterDetails?.categoryId!
    );
    if (typeFilter) {
      filteredFolderSubFolderList = filteredFolderSubFolderList.filter(
        (item) => {
          if (typeFilter.value === 'all') return true;
          if (typeFilter.value === 'subFolder')
            return item.type === 'subFolder';
          if (typeFilter.value === 'folder') return item.type === 'folder';
          return false;
        }
      );
    }
    if (searchQuery) {
      filteredFolderSubFolderList = filteredFolderSubFolderList.filter((item) =>
        item.name
          .toLowerCase()
          .trim()
          .includes(searchQuery.toLowerCase().trim())
      );
    }

    return filteredFolderSubFolderList.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    // if (!searchQuery) {
    //   return filteredFolderSubFolderList?.sort((a, b) =>
    //     a.name.localeCompare(b.name)
    //   );
    // } else {
    //   return filteredFolderSubFolderList
    //     ?.filter((item) =>
    //       item?.name
    //         ?.toLowerCase()
    //         ?.trim()
    //         ?.includes(searchQuery?.toLowerCase()?.trim())
    //     )
    //     ?.sort((a, b) => a.name.localeCompare(b.name));
    // }
  }, [foldersList, searchQuery, typeFilter]);

  const moveHandler = () => {
    // bulkMoveSop({
    //   variables: {
    //     input: {
    //       categoryId: selectedItem?.eid,
    //       change: 'existing',
    //       items: [chapterDetails?.categoryId],
    //     },
    //   },
    // });
    deployEvent(AmplitudeEventNames.MOVE_SINGLE_CHAPTER);
    moveItems({
      variables: {
        input: {
          categoryId: selectedItem?.eid,
          contents: [chapterDetails?.eid],
        },
      },
    });
  };

  const onTypeFilterChange = (value: SelectOption<any>) => {
    setTypeFilter(value);
  };

  return (
    <Flex flexDir='column' w='full' gap={4} pb={4}>
      <Flex mx={8} align='center' gap={2}>
        <Flex w='80%'>
          <SearchInput
            placeholder='Search folder or subfolder'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
            hideShortcuts
          />
        </Flex>
        <Flex zIndex='100'>
          <Dropdown
            placeholder={t('handbook:type')}
            value={typeFilter}
            onChange={onTypeFilterChange}
            options={[
              {
                label: 'All',
                value: 'all',
              },
              {
                label: 'Folders',
                value: 'folder',
              },
              {
                label: 'Subfolders',
                value: 'subFolder',
              },
            ]}
            selectStyles={{
              singleValue: {
                color: '#6f767e',
                fontWeight: '600',
              },
              inputContainer: {
                width: '70px',
              },
            }}
            components={{
              SingleValue: (valProps) => {
                return (
                  <chakraComponents.SingleValue {...valProps}>
                    {/* @ts-ignore */}
                    {t('handbook:type_by', {
                      value: valProps.children,
                    })}
                  </chakraComponents.SingleValue>
                );
              },
            }}
          />
        </Flex>
      </Flex>
      {searchQuery && itemsList?.length === 0 && (
        <EmptyState
          image='Search'
          title='No folder found.'
          description='Oops! Try a different search to get results.'
        />
      )}
      {itemsList?.length > 0 && (
        <Flex flexDir='column' overflowY='auto' maxH='40vh'>
          {itemsList?.map((list) => (
            <Flex
              id='b-m-c-l-item'
              key={list?.eid}
              _selected={{
                backgroundColor: '#2A85FF1F',
              }}
              aria-selected={selectedItem?.eid === list?.eid}
              w='full'
              cursor='pointer'
              onClick={() => setSelectedItem(list)}
              _hover={{ bg: '#EFEFEF' }}
              _notLast={{
                '> div ': {
                  borderBottom: '1px solid #EFEFEF',
                },
              }}
            >
              <Flex w='full' align='center' mx='32px' py='10px'>
                <Flex flex={1} align='center' gap={2}>
                  <Center
                    boxSize='32px'
                    border='1px solid #DDDDDD'
                    borderRadius='5px'
                  >
                    <FontAwesomeIcon
                      icon={
                        (list?.visibility === 'private'
                          ? faFolderUser
                          : faFolder) as IconProp
                      }
                      color='#6F767E'
                      fontSize='15px'
                    />
                  </Center>
                  <Text isTruncated maxW='140px'>
                    {list?.name}
                  </Text>
                  {list?.parentFolderName && (
                    <Flex gap='5px' align='center'>
                      <Text>in</Text>
                      <Text fontWeight={700} isTruncated maxW='140px'>
                        {list?.parentFolderName}
                      </Text>
                    </Flex>
                  )}
                </Flex>
                <Text fontWeight={500} fontSize='12px' color='#979EA6'>
                  {list?.type === 'folder' ? 'Folder' : 'Sub Folder'}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
      <Flex mx={8}>
        <PrimaryButton
          title='Move'
          colorScheme='green'
          variant='solid'
          disabled={loading || !selectedItem?.eid}
          isLoading={loading}
          onClick={moveHandler}
        />
      </Flex>
    </Flex>
  );
};

export default ChapterMoveContainer;

import { gql } from '@apollo/client';
import { AuthRole } from '../../authorization';
import { InviteType } from './invite.types';

export interface InviteVariable {
  input: {
    authRole: AuthRole;
    branchIds: string[];
    name: string;
    role: string;
  };
}

export interface InvitedUser {
  inviteUrl: string;
  name: string;
  code: string;
  eid: string;
}

export interface InviteResponse {
  inviteUser: InvitedUser;
}

export const INVITE_USER_QUERY = gql`
  mutation InviteUser($input: InviteUserAppInput) {
    inviteUser(input: $input) {
      inviteUrl
      code
      eid
      name
    }
  }
`;

export interface SendInviteResponse {
  sendInvite: {
    succeed: boolean;
  };
}

export interface SendInviteVariable {
  input: {
    contact: string;
    eid: string;
    type: InviteType;
  };
}

export const SEND_INVITE_QUERY = gql`
  mutation SendInvite($input: SendInviteInputInput) {
    sendInvite(input: $input) {
      succeed
    }
  }
`;

import React, { FC, useCallback, useRef } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import {
  components,
  GroupBase,
  MenuProps,
  OptionProps,
  Props,
  Select,
} from 'chakra-react-select';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import { SelectOption } from '../../../../atoms';
import {
  getChakraSelectStyles,
  SelectStyles,
} from '../../../../atoms/Dropdown/dropdown.styles';

const Option: FC<OptionProps<SelectOption, false>> = (props) => {
  const { isSelected, children } = props;
  return (
    <components.Option {...props}>
      <Flex align='center' justify='space-between'>
        <Text
          fontSize='15px'
          noOfLines={1}
          pr='8px'
          color='#272b30'
          fontWeight='500'
        >
          {children}
        </Text>
        {isSelected && (
          <FontAwesomeIcon
            icon={faCircleCheck as IconProp}
            color='#83BF6E'
            size='lg'
          />
        )}
      </Flex>
    </components.Option>
  );
};

interface IProps
  extends Pick<
    Props<SelectOption, false, GroupBase<SelectOption>>,
    'options' | 'value' | 'onChange' | 'placeholder'
  > {
  isDisabled?: boolean;
  isLoading: boolean;
  selectStyles?: SelectStyles;
  onCreatePress?: () => void;
  onSavePress: () => void;
  selectedAlignCenter?: boolean;
}

const RoleSelect: FC<IProps> = ({
  isDisabled,
  isLoading,
  options,
  placeholder,
  selectStyles,
  value,
  selectedAlignCenter,
  onChange,
  onCreatePress,
  onSavePress,
}) => {
  const { t } = useTranslation(['common', 'category']);
  const selectRef = useRef<any>(null);

  const Menu = useCallback(
    (menuProps: MenuProps<SelectOption, false>) => {
      return (
        <components.Menu {...menuProps}>
          <Box px={3} pt={3}>
            <Button
              leftIcon={<AddIcon boxSize='12px' />}
              isFullWidth
              colorScheme='blue'
              onClick={() => {
                selectRef.current?.blur();
                onCreatePress?.();
              }}
              justifyContent='flex-start'
              variant='ghost'
              fontSize='15px'
            >
              {t('create_new_role')}
            </Button>
          </Box>
          {menuProps.children}
          {value?.label && (
            <Box p={3}>
              <Button
                isFullWidth
                onClick={() => {
                  selectRef.current?.blur();
                  onSavePress();
                }}
                justifyContent={selectedAlignCenter ? 'center' : 'flex-start'}
                fontSize='15px'
                backgroundColor={'rgba(131, 191, 110, 1)'}
                color='white'
              >
                <Trans
                  t={t}
                  i18nKey='category:move_to'
                  values={{
                    value: value?.label,
                  }}
                />
              </Button>
            </Box>
          )}
        </components.Menu>
      );
    },
    [onCreatePress]
  );

  return (
    <Select<SelectOption, false>
      ref={selectRef}
      closeMenuOnSelect={false}
      options={options}
      value={value}
      onChange={onChange}
      size='lg'
      menuIsOpen
      isDisabled={isDisabled || isLoading}
      isLoading={isLoading}
      placeholder={placeholder}
      placeholderColor={'#FCFCFC'}
      chakraStyles={getChakraSelectStyles<SelectOption, false>({
        ...selectStyles,
        menuList: {
          padding: '4px 12px 12px',
          ...selectStyles?.menu,
        },
        control: {
          border: 'none',
          borderColor: 'transparent',
          borderRadius: '12px',
          fontSize: '15px',
          outline: 'none',
          ...selectStyles?.control,
        },
      })}
      styles={{
        option: (base, optionProps) => ({
          ...base,
          borderBottom: '1px solid #efefef',
          borderRadius:
            optionProps?.isSelected || optionProps?.isFocused ? 8 : 0,
          ':last-child': {
            borderBottom: 'none',
          },
          backgroundColor: optionProps?.isSelected
            ? '#2A85FF1F'
            : optionProps?.isFocused
            ? '#EEEEEE'
            : 'transparent',
        }),
        menu: (base) => ({
          ...base,
          borderRadius: '8px',
        }),
      }}
      components={{
        Option,
        Menu,
      }}
    />
  );
};

export default RoleSelect;

import gql from "graphql-tag";

//AUTH RELATED MUTATIONS
export const getSignedUserInfo = gql`
  query Query {
    user {
      email
      username
      name
      phone
      profilePic
      role
      type
      updatedAt
      createdAt
      eid
      authRole
      account {
        verification {
          verified
        }
        phoneVerification {
          verified
          otp
          expiresIn
        }
      }
      locations {
        name
        eid
      }
      entity {
        name
        address
        profilePic
        isDemo
        roles {
          name
          status
          color
        }
        bundles {
          name
          status
          color
        }
        category {
          name
          status
          icon
        }
        updatedAt
        createdAt
        eid
        activeRoles
        activeBundles
        activeCategories
        locations {
          name
          eid
          isRoot
        }
      }
    }
  }
`;
export const getEntityUser = gql`
  query EntityUser($entityId: String!) {
    EntityUser(entityId: $entityId) {
      email
      name
      phone
      profilePic
      role
    }
  }
`;

export const inviteUser = gql`
  mutation InviteUser($input: InviteUserAppInput) {
    inviteUser(input: $input) {
      name
      inviteUrl
      eid
      code
    }
  }
`;

export const inviteExternalUser = gql`
mutation AddUsersExternal($input: addUserExternalInput) {
  addUsersExternal(input: $input) {
    account {
      onboardingStage
    }
    eid
    name
    profilePic
    status
  }
}
`;

export const updateExternalUserQuery = gql`
mutation EditUsersExternal($input: editUserExternalInput) {
  editUsersExternal(input: $input) {
    authRole
  }
}
`;

export const getExternalMembers = gql`
query LocationExternalUser($locationId: String!) {
  LocationExternalUser(locationId: $locationId) {
    members
    email
    name
    eid
    role
    createdAt
    updatedAt
  }
}
`;

export const getInvitedUser = gql`
  query InvitePagination(
    $filter: FilterFindManyUserInviteInput
    $page: Int
    $perPage: Int
    $sort: SortFindManyUserInviteInput
  ) {
    invitePagination(
      filter: $filter
      page: $page
      perPage: $perPage
      sort: $sort
    ) {
      items {
        name
        email
        role
        authCode
        status
        createdAt
      }
    }
  }
`;
export const updateUserRole = gql`
  mutation UpdateUserRole($eid: String!, $role: String!) {
    updateUserRole(eid: $eid, role: $role) {
      succeed
    }
  }
`;
export const removeUser = gql`
  mutation RemoveUser($eid: String!) {
    removeUser(eid: $eid) {
      succeed
    }
  }
`;

export const changeUserPassword = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      succeed
    }
  }
`;
export const updateUser = gql`
  mutation UpdateUser($input: UserUpdateInput) {
    updateUser(input: $input) {
      email
      name
      phone
      profilePic
      onboarded
      onboardedAt
      authRole
      role
      account {
        verification {
          verified
        }
        phoneVerification {
          verified
          otp
          expiresIn
        }
        onboardingStage
      }
      loginCount
      lastLogin
      isDeleted
      updatedAt
      createdAt
      eid
      entity {
        name
        address
        profilePic
        isDemo
        roles {
          name
          status
          color
          eid
        }
        bundles {
          name
          status
          color
        }
        category {
          name
          description
          status
          icon
        }
        onboarded
        updatedAt
        createdAt
        eid
        activeRoles
        activeBundles
        activeCategories
      }
    }
  }
`;
export const revokeUserInvitationById = gql`
  mutation RevokeInviteByCode($eid: String!) {
    RevokeInviteByCode(eid: $eid) {
      succeed
    }
  }
`;
export const updateUserById = gql`
  mutation UpdateUserById($input: UserUpdateIdInput) {
    updateUserById(input: $input) {
      inviteDetails {
        authCode
        status
      }
      email
      name
      phone
      role
    }
  }
`;

export const getUserList = gql`
  query UserPagination(
    $sort: SortFindManyUserInput
    $page: Int
    $perPage: Int
    $filter: FilterFindManyUserInput
  ) {
    userPagination(
      sort: $sort
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        email
        name
        phone
        profilePic
        onboarded
        isInvited
        isDeleted
        eid
        status
        entity {
          name
          eid
          profilePic
          address
        }
        inviteDetails {
          invitedBy
          joinedAt
          status
          comment
          authCode
        }
        role
      }
    }
  }
`;

export const fetchInviteByCode = gql`
  query FetchInviteByCode($authCode: String!) {
    FetchInviteByCode(authCode: $authCode) {
      name
      inviteDetails {
        status
      }
    }
  }
`;
export const joinInviteByCode = gql`
  mutation JoinInvite($input: JoinInviteInput) {
    joinInvite(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
export const getBranch = gql`
  query Branch {
    branch {
      descendantBranches {
        name
        eid
        type
        role
        authRole
        email
        createdAt
        status
      }
    }
  }
`;

export const getTeam = gql`
  query Items(
    $page: Int!
    $type: teamsType!
    $query: String
    $sort: teamsSort
  ) {
    teams(page: $page, type: $type, query: $query, sort: $sort) {
      items {
        name
        eid
        role
        type
        authRole
        createdAt
        email
        status
        profilePic
        isDeleted
        locations {
          name
          eid
        }
        inviteDetails
      }
      hasMore
      count
      pageCount
      currentPage
    }
  }
`;

export const GET_LOCATIONS = gql`
  query Locations($page: Int, $query: String, $sort: userLocationSort) {
    Locations(page: $page, query: $query, sort: $sort) {
      items {
        name
        phone
        status
        email
        username
        isDeleted
        eid
        members
        thumbnail
        createdAt
      }
      hasMore
      count
      pageCount
      currentPage
    }
  }
`;

export const getImageUrl = gql`
  mutation GetSignedUrlMutation($getSignedUrlInput: [FileDetailsInput]) {
    getSignedUrl(input: $getSignedUrlInput) {
      signedRequestUrl
      url
      mimetype
      s3_filename
      filename
    }
  }
`;

export const AddLogs = gql`
  mutation AddLog($input: LogsInputInput) {
    addLog(input: $input) {
      succeed
    }
  }
`;

export const getUserActivity = gql`
  query UserActivity($page: Int, $perPage: Int, $sort: SortFindManyLogInput, $filter: FilterFindManyLogInput) {
    userActivity(page: $page, perPage: $perPage, sort: $sort, filter: $filter) {
      pageInfo {
        currentPage
        hasNextPage
      }
      items {
        eventName
        payload
        meta
        eid
        updatedAt
        comment
        eventType
        user {
          email
          username
          name
          profilePic
          eid
          role
        }
      }
    }
  }
`;

export const getUserDetailById = gql`
query UserById($eid: String!) {
  userById(eid: $eid) {
    email
    authRole
    locations {
      name
    }
    members
  }
  }
`;
import React, { FC, useCallback, useMemo } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { Row } from 'react-table';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';

import { AlertBox } from '../../../../atoms';
import { toArray } from '../../../../utils/utils';
import { truncateString } from '../../../../utils';
import { ActionButton } from '../../../../ui-components';
import EmptyState from '../../../../sub-components/EmptyState';

import {
  Column,
  SortingTable,
} from '../../../../sub-components/ChakraTable/SortingTable';
import PathName from '../../../Training/Training/PathName';

import { INVITED_USER_DATA, InvitedUserData } from './pending-user.graphql';
import { useUpdateData } from './useUpdateData';

const Wrapper = styled.div`
  padding-top: 20px;

  table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`;

interface TrainingItem {
  eid: string;
  title: string;
  thumbnail?: string;
  contentLastUpdate?: string;
  progress: string;
  status: string;
  isCompleted: boolean;
}

function filterCard(val: any) {
  return val.type === 'card' || val.type === 'sop';
}

interface RowItem {
  totalCards: number;
  viewedCards: number;
}

function SortByProgress(a: Row<RowItem>, b: Row<RowItem>): number {
  const viewCountA = a.original.viewedCards;
  const maxCountA = a.original.totalCards;

  const valA = (viewCountA / maxCountA) * 100;

  const viewCountB = b.original.viewedCards;
  const maxCountB = b.original.totalCards;

  const valB = (viewCountB / maxCountB) * 100;

  return valB - valA;
}

interface IProps {
  userId: string;
  username: string;
}

const PendingUserTrainings: FC<IProps> = ({ userId, username }) => {
  const { t, i18n } = useTranslation(['common', 'training', 'invite', 'team']);

  const { data, loading } = useQuery<Record<'userById', InvitedUserData>>(
    INVITED_USER_DATA,
    {
      fetchPolicy: 'network-only',
      variables: {
        eid: userId,
      },
    }
  );

  const [updateUserTraining] = useUpdateData({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: INVITED_USER_DATA,
        variables: {
          eid: userId,
        },
      },
    ],
  });

  const trainings = useMemo(() => {
    if (data?.userById) {
      return toArray(data.userById.userInviteMeta.trainings).map<TrainingItem>(
        (trainData) => {
          // @ts-ignore
          const totalCards = toArray(trainData?.contents).filter(filterCard);

          const viewedCardsLength = 0;
          const totalCardsLength = totalCards?.length || 0;

          return {
            ...trainData,
            viewedCards: viewedCardsLength,
            totalCards: totalCardsLength,
            status: 'common:notStarted',
            progress: `${viewedCardsLength}/${totalCardsLength}`,
            isCompleted: false,
          };
        }
      );
    }
    return [];
  }, [data?.userById]);

  const onRemoveClick = useCallback(
    async (trainingId: string) => {
      const newValue = trainings.reduce<string[]>(
        (previousValue, currentValue) => {
          if (currentValue.eid !== trainingId) {
            previousValue.push(currentValue.eid);
          }
          return previousValue;
        },
        []
      );
      await updateUserTraining({
        variables: {
          input: {
            userId: userId,
            trainings: newValue,
          },
        },
      });
    },
    [trainings, userId]
  );

  const columns = useMemo((): Column<TrainingItem>[] => {
    return [
      {
        Header: t('common:training'),
        accessor: 'title',
        width: '41%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <PathName
              pathName={value}
              thumbnail={row?.original.thumbnail}
              index={row.index}
              updatedAt={row.original.contentLastUpdate}
              status='active'
              onClick={() => null}
            />
          );
        },
      },
      {
        Header: t('common:status'),
        accessor: 'status',
        width: '22%',
        Cell: () => {
          return (
            <Box
              mb='25px'
              fontSize='12px'
              fontWeight='600'
              textAlign='center'
              width='max-content'
              padding='3.5px 10px'
              borderRadius='6px'
              bg='rgba(111, 118, 126, 0.12)'
              color='#6F767E'
            >
              {t('common:notStarted')}
            </Box>
          );
        },
      },
      {
        Header: t('training:chapterCompleted'),
        accessor: 'progress',
        width: '20%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <Box
              mb='25px'
              fontSize='15px'
              fontWeight='600'
              lineHeight='24px'
              textAlign='center'
              width='max-content'
              px='10px'
              borderRadius='5px'
              bg={'#EFEFEF'}
              color={'#1A1D1F'}
              cursor='pointer'
            >
              {value}
            </Box>
          );
        },
        sortType: SortByProgress as never,
      },
      {
        Header: '',
        accessor: 'eid',
        id: 'action',
        width: '20%',
        Cell: ({ cell: { value } }) => {
          return (
            <Center w='full' mb='25px'>
              <ActionButton
                fontSize='14px'
                borderRadius='8px'
                fontWeight='600'
                size='sm'
                variant='outline'
                px={4}
                actionFn={() => onRemoveClick(value)}
              >
                {t('invite:removeFromTraining')}
              </ActionButton>
            </Center>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [i18n.language, onRemoveClick]);

  return (
    <Wrapper>
      {!!trainings?.length && (
        <AlertBox background='rgba(202, 189, 255, 0.35)'>
          <Trans
            t={t}
            i18nKey='team:trainingInfo'
            values={{
              name: username,
            }}
          />
        </AlertBox>
      )}

      <SortingTable
        background='#FFFFFF'
        colorScheme='blue'
        emptyData={{
          content: (
            <EmptyState
              image='TrainingPath'
              title={
                <Trans
                  t={t}
                  i18nKey='training:noTrainingAssignedTo'
                  values={{
                    name: truncateString(username, 24),
                  }}
                />
              }
            />
          ),
        }}
        page={1}
        columns={columns}
        data={trainings}
        isLoading={loading}
        isResponsive
        isHidePagination={true}
      />
    </Wrapper>
  );
};

export default PendingUserTrainings;

import React, { CSSProperties, FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { AuthRole } from '../../../../authorization';
import { IconImage } from '../../../../ui-components';
import { EntityUser } from './fragment.graphql';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface IProps
  extends Omit<EntityUser, 'eid' | 'createdAt' | 'locations' | 'authRole'> {
  onClick?: () => void;
  locationName?: string;
  authRole?: AuthRole;
}

const PerformanceMemberName: FC<IProps> = ({
  name,
  profilePic,
  role,
  authRole,
  onClick,
  locationName,
}) => {
  const calcAuthRole = useMemo(() => {
    if (locationName) {
      return locationName;
    }
    return authRole?.split(/(?=[A-Z])/).join(' ');
  }, [authRole, locationName]);

  return (
    <Flex
      gap='12px'
      align='center'
      onClick={onClick}
      cursor={onClick ? 'pointer' : undefined}
      maxWidth='max-content'
      overflow='hidden'
    >
      <IconImage
        thumbnail={profilePic}
        name={name}
        boxSize={40}
        borderRadius='50%'
      />
      <Flex flexDirection='column' gap='6px' overflow='hidden'>
        <Box
          fontSize='14px'
          fontWeight='600'
          maxW='300px'
          lineHeight='17px !important'
          style={wrapStyles}
        >
          {name}
        </Box>
        <Flex
          gap='5px'
          align='center'
          color='#92929D'
          fontSize='12px'
          fontWeight='semibold'
          lineHeight='16px !important'
        >
          <Box>{role}</Box>
          <Box fontSize='16px' color='#D9D9D9'>
            &bull;
          </Box>
          <Box textTransform='capitalize'>{calcAuthRole}</Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PerformanceMemberName;

import * as yup from 'yup';
import { AssigneesValidationRule } from './assigneesSchema';
import { ScheduleValidationRule } from './scheduleValidationRule';
import { IFormInput, Stepper } from '../task.types';
import { AnyObject } from 'yup';

const title = yup.string().trim().required('Task title is required');

const description = yup.string();

const contents = yup
  .array()
  .of(yup.object())
  .min(1, 'Please add some task steps')
  .required('Task step is required');

const supervisors = yup.array().of(yup.string());
// .min(1, 'Please select supervisor')
// .required('Please select supervisor')

export const DetailRule = {
  title: title,
  description: description,
};

export const TaskCreateScheme = yup.object<AnyObject, never>(
  Object.assign(
    {},
    DetailRule,
    ScheduleValidationRule,
    {
      contents: contents,
    },
    AssigneesValidationRule,
    {
      supervisors: supervisors,
    }
  )
);

export const TaskEditScheme = yup.object<AnyObject, never>(
  Object.assign(
    {},
    DetailRule,
    // ScheduleValidationRule,
    {
      contents: contents,
    },
    AssigneesValidationRule,
    {
      supervisors: supervisors,
    }
  )
);

type FormInputKeys = keyof IFormInput;

export const SchemaKeys: Record<Stepper, FormInputKeys[]> = {
  details: Object.keys(DetailRule) as FormInputKeys[],
  scheduler: Object.keys(ScheduleValidationRule) as FormInputKeys[],
  taskSteps: ['contents'],
  assignees: Object.keys(AssigneesValidationRule) as FormInputKeys[],
  supervisors: ['supervisors'],
};

import styled from '@emotion/styled';

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 367px;
  top: 0;
  height: 100%;
  right: 0;
  background: #fcfcfc;
  box-shadow: -10px 4px 10px rgba(153, 153, 153, 0.2);
  border-radius: 8px 0 0 8px;
  z-index: 200;

  .__header {
    display: flex;
    align-items: center;
    margin: 46px 18px 46px 28px;

    .box {
      height: 32px;
      width: 16px;
      border-radius: 4px;
      background-color: #b1e5fc;
      margin-right: 10px;
    }
    .title {
      font-size: 18px;
      font-weight: 600;
      color: #272b30;
      flex: 1;
    }

    .close {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #efefef;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .supervisor-list {
    overflow-y: auto;
    flex: 1;
    padding: 0 28px 46px;

    .item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #efefef;
      padding: 12px 0;
      margin-bottom: 4px;

      .avatar {
        img {
          width: 44px;
          height: 44px;
          border-radius: 5px;
        }
      }

      .item-content {
        margin-left: 12px;

        .name {
          font-weight: 700;
          font-size: 15px;
          color: #1a1d1f;
        }
        .position {
          font-weight: 600;
          font-size: 12px;
          color: #6f767e;
        }
      }
    }
  }
`;

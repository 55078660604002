import React, { FC, useRef } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLockKeyhole,
  faEarthAmerica,
} from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useConfirm } from '../../ui-components/Confirm';
import PreviewHeader from '../../sub-components/forms/component/PreviewHeader';
import VisibilityComponent from '../Training/CreateTrainingPath/VisibilityComponent';

type Visibility = 'public' | 'private';

interface IProps {
  visibility: Visibility;
  onChange: (newValue: Visibility) => void;
  pathName?: string;
}

const IconData: Record<Visibility, IconProp> = {
  private: faLockKeyhole as IconProp,
  // public: faLockKeyholeOpen as IconProp,
  public: faEarthAmerica as IconProp,
};

const ChangeVisibility: FC<IProps> = ({
  visibility,
  onChange,
  pathName = '',
}) => {
  const { t } = useTranslation('training');
  const { t: tc } = useTranslation('common');
  const confirm = useConfirm();
  const visibilityRef = useRef<Visibility>();

  const onChangeClick = () => {
    visibilityRef.current = visibility;
    confirm({
      title: <PreviewHeader title={pathName} color='#cabdff' />,
      content: (
        <VisibilityComponent
          defaultValue={visibility}
          onChange={(newValue) => {
            visibilityRef.current = newValue;
          }}
        />
      ),
      okText: tc('save'),
      isCentered: true,
      contentProps: {
        paddingTop: '12px',
        paddingBottom: '12px',
        maxWidth: '700px',
        borderRadius: '16px',
      },
      onOK: () => onChange(visibilityRef.current as Visibility),
    });
  };

  if (!visibility) {
    return null;
  }

  return (
    <Flex fontSize='14px' fontWeight='600' align='center' gap='10px'>
      <Box color='#979ea6'>{t('visibility_label')}:</Box>
      <Flex
        textTransform='capitalize'
        align='center'
        gap='6px'
        fontWeight='700'
        color='#2a85ff'
        cursor='pointer'
        onClick={onChangeClick}
      >
        <FontAwesomeIcon icon={IconData[visibility]} />
        {visibility}
      </Flex>
    </Flex>
  );
};

export default ChangeVisibility;

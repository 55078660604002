import React, { FC, useMemo } from "react";

const SOP_UPLOAD_REGX = /https:\/\/sop-uploads-staging.s3.amazonaws.com/g;
const IMGIX_URL = "https://d-sop-staging.imgix.net";
const SOP_UPLOAD_URL = "https://sop-uploads-staging.s3.amazonaws.com";

interface IProps {
  image?: string;
  onClick?: (image: string) => void;
}

const BackgroundImage: FC<IProps> = ({ image, onClick }) => {
  const imageUrl = useMemo(() => {
    let _image = image;
    if (_image && _image.match(SOP_UPLOAD_REGX)) {
      _image = _image.replace(SOP_UPLOAD_URL, IMGIX_URL);

      _image = `${_image}?auto=compress,format&q=25`;
    }
    return encodeURI(_image as string);
  }, [image]);

  return (
    <div
      style={{
        backgroundImage: `url("${imageUrl}")`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflow: "hidden",
        height: "100%",
        borderRadius: 12,
        cursor: "pointer",
      }}
      onClick={() => onClick?.(image as string)}
    />
  );
};

export default BackgroundImage;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles/supervisor-modal.styles';
import { AssignedToUsersEntity } from '../training.types';
import { ReactComponent as CloseIconDark } from '../../../assets/images/closeIconDark.svg';
import { capitalizeFirstLetter } from 'sop-commons/src/utils/stringUtils';
import Image from '../../../ui-components/Image/Image';

import { getImage } from '../../../utils';

interface IProps {
  isOpen?: boolean;
  superVisors: Array<AssignedToUsersEntity>;
  onClose: () => void;
}

const SupervisorsSideModal: FC<IProps> = ({
  isOpen,
  superVisors = [],
  onClose,
}) => {
  const { t } = useTranslation('training');
  if (!isOpen) {
    return null;
  }
  return (
    <Wrapper>
      <div className='__header'>
        <div className='box' />
        <div className='title'>{t('supervisor_table_heading')}</div>
        <div className='close' onClick={onClose}>
          <CloseIconDark />
        </div>
      </div>
      <div className='supervisor-list'>
        {superVisors.map((supervisor) => {
          return (
            <div className='item' key={supervisor.eid}>
              <div className='avatar'>
                <Image
                  width={44}
                  height={44}
                  src={getImage(supervisor.profilePic, supervisor.name)}
                />
              </div>
              <div className='item-content'>
                <div className='name'>{supervisor.name || '--'}</div>
                <div className='position'>
                  {capitalizeFirstLetter(supervisor.authRole || '--')}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default SupervisorsSideModal;

import React, { FC, ReactNode } from 'react';
import { Box, ThemeTypings } from '@chakra-ui/react';
import { Column, useTable } from 'react-table';

import { usePagination } from './usePagination';

import { Pagination } from './Pagination';

import { ReactComponent as ChevronUp } from '../../assets/images/chevronUp.svg';
import { ReactComponent as ChevronDown } from '../../assets/images/chevronDown.svg';

import Loader from '../../ui-components/Loader';

type DataType = {
  [key: string]: any;
};

interface TableProps {
  columns: Column<DataType>[];
  data: DataType[];
  page: number;
  totalRegisters: number;
  onPageChange: (page: number) => void;
  colorScheme?: ThemeTypings['colorSchemes'];
  emptyData?: {
    text?: string;
    content?: ReactNode;
  };
  isLoading?: boolean;
  onSort?: any;
  sort?: string;
  onNameSort?: any;
  onRoleSort?: any;
  onStatusSort?: any;
  onClickRow?: any;
  isHidePagination: boolean;
  isShareModalOpen?: boolean;
}

const Table: FC<TableProps> = ({
  page,
  onPageChange,
  totalRegisters,
  data,
  columns,
  colorScheme = 'teal',
  emptyData,
  isLoading,
  onSort,
  sort,
  onNameSort,
  onRoleSort,
  onStatusSort,
  isHidePagination = false,
  isShareModalOpen = false,
}: TableProps) => {
  const pagination = usePagination({
    totalRegisters,
    page,
    items: data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: pagination.pageItems });

  if (isLoading) {
    return (
      <div className='sop-list-loader'>
        <Loader />
      </div>
    );
  }

  if (!isLoading && data.length === 0) {
    return (
      <div className='sop-list-loader'>
        {emptyData?.content || emptyData?.text}
      </div>
    );
  }

  return (
    <>
      <Box
        as='table'
        {...getTableProps()}
        // style={{ borderRadius: '8px', backgroundColor: '#fcfcfc' }}
        borderRadius='8'
        w='full'
        h='100%'
        backgroundColor={'#fcfcfc'}
      >
        {/* <ChakraTable {...getTableProps()}> */}
        <Box style={{ height: '66px' }} as='thead'>
          {headerGroups.map((headerGroup, headIndex) => (
            <Box
              as='tr'
              // @ts-ignore
              key={headIndex}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => {
                return (
                  <React.Fragment key={column.id}>
                    <Box
                      as='th'
                      {...column.getHeaderProps()}
                      style={{
                        textTransform: 'capitalize',
                        color: '#6f767e',
                        borderBottom: 'none',
                        textAlign: 'left',
                        fontSize: '13px',
                        width: column?.width,
                        // display: 'flex',
                        // alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (column.id === 'addedOn') {
                            onSort();
                          }
                          if (column.id === 'name') {
                            onNameSort();
                          }
                          if (column.id === 'role') {
                            onRoleSort();
                          }
                          if (column.id === 'status') {
                            onStatusSort();
                          }
                        }}
                      >
                        {column.render('Header')}
                        {column.id === 'name' && (
                          <div style={{ marginLeft: '5px' }}>
                            <ChevronUp
                              ml={1}
                              w={4}
                              h={4}
                              className={
                                sort === 'NAME_DESC'
                                  ? 'member-sort-container'
                                  : ''
                              }
                              style={{ marginBottom: '1px' }}
                            />
                            <ChevronDown
                              ml={1}
                              w={4}
                              h={4}
                              className={
                                sort === 'NAME_ASC'
                                  ? 'member-sort-container'
                                  : ''
                              }
                            />
                          </div>
                        )}
                        {column.id === 'role' && (
                          <div style={{ marginLeft: '5px' }}>
                            <ChevronUp
                              ml={1}
                              w={4}
                              h={4}
                              className={
                                sort === 'ROLE_DESC'
                                  ? 'member-sort-container'
                                  : ''
                              }
                              style={{ marginBottom: '1px' }}
                            />
                            <ChevronDown
                              ml={1}
                              w={4}
                              h={4}
                              className={
                                sort === 'ROLE_ASC'
                                  ? 'member-sort-container'
                                  : ''
                              }
                            />
                          </div>
                        )}
                        {column.id === 'status' && (
                          <div style={{ marginLeft: '5px' }}>
                            <ChevronUp
                              ml={1}
                              w={4}
                              h={4}
                              className={
                                sort === 'STATUS_DESC'
                                  ? 'member-sort-container'
                                  : ''
                              }
                              style={{ marginBottom: '1px' }}
                            />
                            <ChevronDown
                              ml={1}
                              w={4}
                              h={4}
                              className={
                                sort === 'STATUS_ASC'
                                  ? 'member-sort-container'
                                  : ''
                              }
                            />
                          </div>
                        )}
                        {column.id === 'addedOn' && (
                          <div style={{ marginLeft: '5px' }}>
                            <ChevronUp
                              ml={1}
                              w={4}
                              h={4}
                              className={
                                sort === '_ID_DESC'
                                  ? 'member-sort-container'
                                  : ''
                              }
                              style={{ marginBottom: '1px' }}
                            />
                            <ChevronDown
                              ml={1}
                              w={4}
                              h={4}
                              className={
                                sort === '_ID_ASC'
                                  ? 'member-sort-container'
                                  : ''
                              }
                            />
                          </div>
                        )}
                      </div>

                      {/* {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDownIcon ml={1} w={4} h={4} />
                      ) : (
                        <ChevronUpIcon ml={1} w={4} h={4} />
                      )
                    ) : (
                      ''
                    )} */}
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>
          ))}
        </Box>

        <Box as='tbody' {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Box as='tr' {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell, index) => (
                  <React.Fragment key={cell.column.id + index}>
                    <Box
                      as='td'
                      {...cell.getCellProps()}
                      style={{ borderBottom: 'none', padding: '20px 0px' }}
                    >
                      {cell.render('Cell')}
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            );
          })}
        </Box>
        {!isHidePagination && (
          <div
            className={
              isShareModalOpen
                ? 'change-page-index fixed-pagination-btn'
                : 'fixed-pagination-btn'
            }
          >
            <Pagination
              {...pagination}
              colorScheme={colorScheme}
              onPageChange={onPageChange}
            />
          </div>
        )}

        {/* </ChakraTable> */}
      </Box>
    </>
  );
};

export default Table;

import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import {
  CardDefaultValues,
  CardFormInput,
  getDefaultQuizOption,
} from '../card-editor.types';
import {
  MixedLayout,
  OtherLayout,
  QuizLayout,
  TextLayout,
} from 'sop-commons/src/utils/cardLayouts';
import { CardType } from '../../../types';

type InitialFun = (values: Partial<CardFormInput>) => CardFormInput;

export default function useCardResetInitial(
  getValues: UseFormReturn<CardFormInput>['getValues']
): InitialFun {
  return useCallback(
    (values: Partial<CardFormInput>) => {
      const defaultData = {
        ...CardDefaultValues,
        entityId: getValues('entityId'),
        backgroundColor: '#ffffff',
        status: getValues('status'),
        ...values,
      };

      switch (defaultData?.cardType?.value) {
        case CardType.Text:
          defaultData.layout = TextLayout.SIMPLE_TEXT;
          break;
        case CardType.Image:
          defaultData.layout = MixedLayout.IMAGE_SINGLE;
          break;
        case CardType.Action:
          defaultData.layout = OtherLayout.ACTION;
          break;
        case CardType.Quiz:
          defaultData.layout = QuizLayout.SINGLE_CHOICE;
          defaultData.choices = [...getDefaultQuizOption()];
          defaultData.correctAnswer = [];
          break;
        case CardType.Video:
          break;
        case CardType.Form:
          defaultData.layout = OtherLayout.FORM;
          break;
        case CardType.Mixed:
          defaultData.layout = MixedLayout.MIXED_TOP_IMAGE_TEXT;
          break;
        case CardType.CustomForm:
          defaultData.layout = OtherLayout.CUSTOM_FORM;
          break;
        case CardType.Milestone:
          defaultData.milestoneUrl = '';
          defaultData.milestoneAddedBy = '';
          defaultData.milestoneDateAdded = '';
          defaultData.milestoneFileSize = 0;
          break;
        default:
          break;
      }

      return defaultData;
    },
    [getValues]
  );
}

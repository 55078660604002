import { gql } from '@apollo/client';
import { UserProgressItem } from '../../../../../../../pages/Training/TrainingList/types/user-progress.types';

export interface TrainingProgressResponse {
  trainingById: {
    userProgress: UserProgressItem[];
    contentDetails: any;
  };
}

export const TrainingProgressQuery = gql`
  query TrainingProgress($eid: String!) {
    trainingById(eid: $eid) {
      userProgress
      contentDetails
    }
  }
`;

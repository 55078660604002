import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Center,
  Checkbox,
  Flex,
  List,
  ListItem,
  Text,
  useToast,
} from '@chakra-ui/react';
import { SelectOption } from 'atoms';
import { useFolderSubFolder } from 'hooks';
import { cloneDeep } from 'lodash';
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { ISelectedFolderData } from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import EmptyState from 'sub-components/EmptyState';
import { IconImage } from 'ui-components';
import Loader from 'ui-components/Loader';
import MoveFolderSubFolderSelect from './MoveFolderSubFolderSelect';

interface IProps {
  folderDetails: ISelectedFolderData;
  searchValue: string;
  onMoveSuccess?: () => void;
  onMoveError?: () => void;
  onNoData?: () => void;
}

const MOVE_CHAPTERS = gql`
  mutation MoveItems($input: moveItemsTypeInput) {
    moveItems(input: $input) {
      succeed
    }
  }
`;

const SUB_FOLDER_CHAPTERS = gql`
  query SopPagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManySopInput
  ) {
    SopPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        eid
        icon
        category
        visibility
        visibleToDetails {
          roles
          users {
            eid
            name
          }
          locations {
            eid
            name
          }
          condition
        }
        title
      }
    }
  }
`;

const MoveSubFolderItems: FC<IProps> = ({
  folderDetails,
  searchValue,
  onMoveSuccess,
  onMoveError,
  onNoData,
}) => {
  console.log('MoveSubFolderItems : ', folderDetails);
  const { _allFoldersList, allSubFoldersList } = useFolderSubFolder();
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const allFolderSubFolderList = useMemo(() => {
    let allFolders = _allFoldersList('asc', true);
    let _allFolders: any[] = cloneDeep(allFolders as any);
    let customAllFolders: any[] = [];
    _allFolders?.map((folder: any) => {
      customAllFolders.push({
        ...folder,
        type: 'Folder',
        label: folder.name,
        value: folder.eid,
      });
    });

    let allSubFolders = allSubFoldersList();
    let _allSubFolders: any[] = cloneDeep(allSubFolders as any);
    let customAllSubFolders: any[] = [];
    _allSubFolders?.forEach((sub: any) => {
      customAllSubFolders.push({
        ...sub,
        type: 'Subfolder',
        label: sub.name,
        value: sub.eid,
      });
    });
    let _allFoldersLists = [
      ...customAllFolders,
      ...customAllSubFolders,
    ]?.filter((list) => list?.eid !== folderDetails?.eid);
    _allFoldersLists.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore case
      const nameB = b.name.toUpperCase(); // ignore case
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return _allFoldersLists;
  }, []);
  const [value, setValue] = useState<SelectOption>();
  const [selectedChapters, setSelectedChapters] = useState<string[]>([]);
  const { data, loading } = useQuery(SUB_FOLDER_CHAPTERS, {
    fetchPolicy: 'network-only',
    variables: {
      page: 1,
      perPage: 1000,
      filter: {
        categoryId: folderDetails?.eid,
      },
    },
  });
  const itemsList = useMemo(() => {
    let list: any[] = data?.SopPagination?.items || [];
    if (searchValue) {
      list = list.filter((item: any) =>
        item?.title.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    return list;
  }, [searchValue, data?.SopPagination?.items]);
  useEffect(() => {
    if (!loading && data?.SopPagination?.items?.length === 0) {
      onNoData?.();
    }
  }, [data, loading]);
  const [moveChapters, { loading: movingChapters }] = useMutation(
    MOVE_CHAPTERS,
    {
      onCompleted: () => {
        toast({
          status: 'success',
          title: 'Chapters moved successfully',
        });
        onMoveSuccess?.();
      },
      onError: () => {
        toast({
          status: 'error',
          title: 'Chapters could not be moved',
        });
        onMoveError?.();
      },
    }
  );
  const selectAllHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedChapters([
        ...data?.SopPagination?.items?.map((item: any) => item?.eid),
      ]);
    } else {
      setSelectedChapters([]);
    }
  };
  const selectHandler = (
    event: ChangeEvent<HTMLInputElement>,
    itemId: string
  ) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      setSelectedChapters((prev) => [...prev, itemId]);
    } else {
      let filteredArr = selectedChapters?.filter((ch) => ch !== itemId);
      console.log('Filtered Arr : ', filteredArr);
      setSelectedChapters(filteredArr);
    }
  };
  const onMoveHandler = () => {
    if (!value) return;
    let input = {
      categoryId: value.eid!,
      change: 'existing',
      items: selectedChapters,
    };
    console.log('input : ', input);
    moveChapters({
      variables: {
        input: input,
      },
    });
  };
  return loading ? (
    <Center>
      <Loader />
    </Center>
  ) : (
    <>
      {itemsList?.length > 0 ? (
        <Flex flexDir='column' gap={4} justify='space-between'>
          <Checkbox onChange={selectAllHandler}>
            <Text fontWeight={700}>Select All</Text>
          </Checkbox>
          <List maxH='40vh' overflowY='auto' p={0}>
            {itemsList?.map((item: any) => {
              console.log(item);
              return (
                <ListItem key={item?.eid} my={2}>
                  <Flex gap={2}>
                    <Checkbox
                      isChecked={selectedChapters?.includes(item?.eid)}
                      onChange={(e) => selectHandler(e, item?.eid)}
                    />
                    <IconImage
                      boxSize={50}
                      icon={item.icon}
                      name={item.title}
                      thumbnail={item.thumbnail}
                      iconFontSize='30px'
                    />
                    <Flex flexDir='column'>
                      <Text
                        m={0}
                        color='#6F767E'
                        fontWeight={500}
                        fontSize='12px'
                      >
                        {item?.category}
                      </Text>
                      <Text
                        m={0}
                        color='#1A1D1F'
                        fontWeight={600}
                        fontSize='15px'
                      >
                        {item?.title}
                      </Text>
                    </Flex>
                  </Flex>
                </ListItem>
              );
            })}
          </List>
          <Box width='full'>
            <MoveFolderSubFolderSelect
              allFolderSubFolderList={allFolderSubFolderList}
              anySelectionMade={!!selectedChapters?.length}
              loading={movingChapters}
              value={value}
              onMove={onMoveHandler}
              setValue={setValue}
            />
            {/* <FolderSubFolderSelect
              options={allFolderSubFolderList}
              checkIcon={
                <FontAwesomeIcon
                  icon={faCircleCheck as IconProp}
                  color='#83BF6E'
                />
              }
              value={value}
              loading={movingChapters}
              onChange={(newValue) => setValue(newValue as SelectOption)}
              isDisabled={selectedChapters?.length === 0}
              onMove={onMoveHandler}
              placeholder={'Select folder or sub folder'}
              selectStyles={{
                container: {
                  background: '#2A85FF',
                },
                singleValue: {
                  color: '#FCFCFC',
                },
                dropdownIndicator: {
                  svg: {
                    color: '#FCFCFC',
                  },
                },
              }}
            /> */}
          </Box>
        </Flex>
      ) : (
        <Center>
          <EmptyState image='Search' title='Data not found' />
        </Center>
      )}
    </>
  );
};

export default MoveSubFolderItems;

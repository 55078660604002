import React, { FC, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'atoms/PrimaryButton';
import { QuestionType } from 'types';

import { IFormInput, OptionWhiteList } from '../form.types';
import OptionInput from './OptionInput';

interface IProps {
  currentIndex: number;
  isReadOnly?: boolean;
}

const OptionList: FC<IProps> = ({ currentIndex, isReadOnly }) => {
  const { t } = useTranslation('form');
  const { control, getValues } = useFormContext<IFormInput>();
  const { fields, append, remove, update, insert } = useFieldArray<
    IFormInput,
    `questions.0.options`
  >({
    control,
    name: `questions.${currentIndex}.options` as `questions.0.options`,
  });

  const questionType: QuestionType = useWatch({
    control: control,
    name: `questions.${currentIndex}.qType`,
  });

  const onAddOptionClick = () => {
    const options = getValues(`questions.${currentIndex}.options`) || [];
    const filteredOption = options.filter((val) => !val.isOther);

    insert(
      filteredOption.length,
      {
        label: '',
        isCorrect: false,
        isDisabled: false,
        subTitle: '',
      },
      {
        shouldFocus: true,
        focusName: `questions.${currentIndex}.options.${filteredOption.length}.label`,
      }
    );
  };

  const onAddOtherClick = () => {
    const options = getValues(`questions.${currentIndex}.options`) || [];
    const filteredOption = options.filter((val) => !val.isOther);

    if (options.length === filteredOption.length) {
      append({
        label: 'Other',
        isCorrect: false,
        isDisabled: false,
        subTitle: '',
        isOther: true,
      });
    }
  };

  const onRemove = (index: number) => {
    const option = getValues(`questions.${currentIndex}.options.${index}`);
    if (option?.eid) {
      update(index, {
        ...option,
        isArchieved: true,
      });
    } else {
      remove(index);
    }
  };

  const hideAddOther = useMemo(() => {
    if (questionType === QuestionType.DROPDOWN) {
      return true;
    }
    return fields?.findIndex((val) => val.isOther) > -1;
  }, [fields, questionType]);

  if (!OptionWhiteList.includes(questionType)) {
    return null;
  }

  return (
    <div>
      {fields?.map((field, index) => {
        return (
          <OptionInput
            questionIndex={currentIndex}
            currentIndex={index}
            key={field.id}
            isReadOnly={isReadOnly}
            onRemove={() => !isReadOnly && onRemove(index)}
          />
        );
      })}
      {!isReadOnly ? (
        <Flex gap='16px' mt={5}>
          <PrimaryButton
            width='auto'
            colorScheme='blue'
            title={t('add_option')}
            style={{
              fontSize: 14,
            }}
            onClick={onAddOptionClick}
          />
          {!hideAddOther && (
            <PrimaryButton
              width='auto'
              variant='link'
              colorScheme='blue'
              title={t('addOther')}
              style={{
                fontSize: 14,
              }}
              onClick={onAddOtherClick}
            />
          )}
        </Flex>
      ) : null}
    </div>
  );
};

export default OptionList;

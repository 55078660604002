import React, { FC, useEffect, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { UPDATE_TRAINING } from 'pages/EditTraining/edit-training.graphql';
import {
  ContentDetailsEntity,
  TrainingData,
} from 'pages/Training/training.types';
import { useTranslation } from 'react-i18next';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { userObj } from 'sop-commons/src/client/clientFactory';
import DashboardContainer from 'sub-components/DashboardContainer';
import { SortByDate, SortByName, SortByNumber } from 'utils/sorting';
import {
  CardsCount,
  CreatedBy,
  DateCell,
} from '../Training/Training/TrainingComponent';
import Assignee from '../Training/Training/Assignee';
import { ADMIN, SUPER_ADMIN, WORKER } from 'utils/userRoles';
import PathName from '../Training/Training/PathName';
import OpenIcon from '../../assets/images/open.png';
import PublishIcon from '../../assets/images/publish.svg';
import viewChangeLogIcon from '../../assets/images/viewChangeLog.svg';
import DeleteBinIcon from '../../assets/images/deleteBin.svg';
import DarkEditIcon from '../../assets/images/dark_edit.png';
import ArchiveIcon from '../../assets/images/archive.png';
import TableActionMenu, {
  IActionMenuData,
} from 'ui-components/TableActionMenu';
import FilledHorizontal from '../../assets/images/filled_horizontal.png';
import { SortingTable } from 'sub-components/ChakraTable/SortingTable';
import TrainingPathModal from 'sub-components/AddNewPathModal/TrainingModal';
import SearchInput from 'atoms/SearchInput';
import PrimaryButton from 'atoms/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getSingleTrainingData } from 'sop-commons/Query/Training';
import { getAllTrainings } from 'sop-commons/Query/Training/index';
import CreatedUpdatedBy from '../Training/Training/CreatedUpdatedBy';
import ChangeLog from './ChangeLog';
import { TrainingDraftMethods } from './training-draft-methods/TrainingDraftMethods';
import GenericModal from 'ui-components/GenericModal';
import closeIcon from '../../assets/images/closeIcon.png';
import EmptyState from 'sub-components/EmptyState';

function SortByAssignee(a: any, b: any, c: string) {
  const valA = a.values[c]?.length;
  const valB = b.values[c]?.length;
  return valB - valA;
}

const TrainingDraft: FC = () => {
  const { t } = useTranslation('training');
  const { t: tc } = useTranslation('common');
  const history = useHistory();
  const toast = useToast({
    position: 'top-right',
  });
  const userObject = useReactiveVar<any>(userObj);
  const [selectedDraftData, setSelectedDraftData] = useState<any>(null);
  const [isTrainingPathModalOpen, setIsTrainingPathModalOpen] = useState(false);
  const [isChangeLogModalOpen, setIsChangeLogModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [trainingList, setTrainingListData] = useState<TrainingData[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPageData, setCurrentPageData] = useState({
    currentPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    itemCount: 1,
    pageCount: 1,
    perPage: 1,
  });

  const [
    trainingListData,
    { data: getTrainingListData, loading: trainingDataLoading },
  ] = useLazyQuery(getAllTrainings, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setCurrentPageData(data?.userTrainingNew?.pageInfo);
      addColorsHandler(data.userTrainingNew.items);
    },
  });

  const [updateTraining] = useMutation(UPDATE_TRAINING, {
    onCompleted: (data) => {
      toast({
        title: t('training_path_update_success_toast'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchTrainingListDataHandler();
    },
    onError: () => {
      toast({
        title: t('training_path_update_error_toast'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      fetchTrainingListDataHandler();
    },
  });

  const addColorsHandler = (data: TrainingData[]) => {
    let _data: TrainingData[] = JSON.parse(JSON.stringify(data));
    let activeData = _data?.filter((path) => path?.status === 'active');
    let inactiveData = _data?.filter((path) => path?.status !== 'active');
    let _combinedData = [...activeData, ...inactiveData];
    let colorArr = [
      { color: '#cabdff' },
      { color: '#ffbc99' },
      { color: '#b1e5fc' },
      { color: '#b5e4ca' },
      { color: '#ffd88d' },
    ];
    _combinedData.map((val, index) => {
      let num = index % colorArr.length;
      val.color = colorArr[num].color;
      val.cardCount = val.trainingContentExpanded?.filter(
        (value) => value.type === 'card'
      )?.length;
    });
    setTrainingListData(_combinedData);
  };

  useEffect(() => {
    fetchTrainingListDataHandler();
  }, [userObject]);

  const fetchTrainingListDataHandler = (
    currentPage = currentPageData?.currentPage,
    query = searchQuery
  ) => {
    let _query;
    if (!query) {
      _query = null;
    } else {
      _query = query;
    }
    trainingListData({
      variables: {
        page: currentPage,
        perPage: 10,
        filter: {
          query: _query,
          status: 'draft',
        },
        sort: 'UPDATEDAT_DESC',
      },
    });
  };

  const [singleTrainingHandler] = useLazyQuery(getSingleTrainingData, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let _item = JSON.parse(JSON.stringify(data?.trainingById));
      _item?.trainingContentExpanded?.forEach((it: any) => {
        it.completed = false;
        _item?.userProgress?.[0]?.viewedContents?.forEach((content: any) => {
          if (it?.uid === content?.uid) {
            it.completed = true;
          }
        });
      });
      // setSelectedTrainingData(_item);
      setIsTrainingPathModalOpen(true);
    },
    onError: (error) => {
      toast({
        title: t('training_path_open_error_toast'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const ref = useRef(null);
  const getColumns = (isTablet: boolean) => {
    let tableColumns: any = [
      {
        Header: t('path_name'),
        accessor: 'pathName' as const,
        width: '30%',
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <PathName
              pathName={value}
              thumbnail={row?.original?.thumbnail}
              status={row?.original?.status}
              updatedAt={row?.original?.updatedAt}
              index={row.index}
              visibility={row?.original?.visibility}
              onClick={() =>
                clickedItemHandler('open-edit', row?.original?.item)
              }
              isFromDeck
            />
          );
        },
        sortType: SortByName,
      },
      {
        Header: t('cards'),
        accessor: 'cardCount' as const,
        width: '10%',
        Cell: ({ cell: { value, row } }: any) => {
          return <CardsCount count={value} status={row?.original?.status} />;
        },
        sortType: SortByNumber,
      },
      {
        Header: t('assignee'),
        accessor: 'assignee' as const,
        width: '10%',
        Cell: ({ cell: { value, row } }: any) => {
          return <Assignee assignees={value} status={row?.original?.status} />;
        },
        sortType: SortByAssignee,
      },
      {
        Header: t('created_by'),
        accessor: 'createdBy' as const,
        width: '15%',
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <CreatedUpdatedBy assignee={value} status={row?.original?.status} />
          );
        },
        sortType: SortByName,
      },
      {
        Header: t('created_on'),
        accessor: 'createdOn' as const,
        width: '15%',
        Cell: ({ cell: { value, row } }: any) => {
          return <DateCell date={value} status={row?.original?.status} />;
        },
        sortType: SortByDate,
      },
      {
        Header: t('last_updated_by_heading'),
        accessor: 'updatedBy' as const,
        width: '15%',
        Cell: ({ cell: { value, row } }: any) => {
          return (
            <CreatedUpdatedBy assignee={value} status={row?.original?.status} />
          );
        },
        sortType: SortByDate,
      },
      {
        Header: '',
        accessor: 'action' as const,
        width: '5%',
        disableSortBy: true,
      },
    ];
    return tableColumns;
  };

  const clickedItemHandler = (clickedItemName: string, item: TrainingData) => {
    // if (menuClicked === 'Open') {
    //     if (
    //         userObject?.authRole === SUPER_ADMIN ||
    //         userObject?.authRole === ADMIN
    //     ) {
    //         history.push(`/training/${item.eid}`);
    //     } else {
    //         if (userObject?.authRole === WORKER) {
    //             singleTrainingHandler({
    //                 variables: {
    //                     eid: item?.eid,
    //                 },
    //             });
    //         } else {
    //             let isSupervisor = item?.supervisors?.find(
    //                 (supervisor: string) => supervisor === userObject?.eid
    //             );
    //             let isAssignedTo = item?.assignedToUsers
    //                 ?.filter((assigned) => assigned?.eid === userObject?.eid)
    //                 ?.map((user) => user?.eid);
    //             history.push(`/view/${item.eid}`);
    //             // if ((isAssignedTo?.[0] && isSupervisor) || isSupervisor) {
    //             // } else {
    //             //   singleTrainingHandler({
    //             //     variables: {
    //             //       eid: item?.eid,
    //             //     },
    //             //   });
    //             //   setIsTrainingPathModalOpen(true);
    //             // }
    //         }
    //     }
    // } else if (menuClicked === 'Edit') {
    //     history.push(`/training/${item.eid}/edit`);
    // } else {
    //     let supervisorsIdArray: TrainingData['supervisors'] = [];
    //     let assignedToIdArray: string[] = [];
    //     let contentsArray: ContentDetailsEntity[] = [];

    //     if (item?.supervisors?.length > 0) {
    //         supervisorsIdArray = item?.supervisors;
    //         // supervisorsIdArray = item?.supervisors.map(
    //         //   (singleItem: any) => singleItem?.eid
    //         // );
    //     }

    //     if (item?.assignedTo?.length > 0) {
    //         assignedToIdArray = item?.assignedTo.map(
    //             (singleItem) => singleItem?.eid
    //         );
    //     }

    //     if (item?.contentDetails?.length) {
    //         item?.contentDetails?.forEach((content: any) => {
    //             if (content?.type === 'milestone') {
    //                 contentsArray?.push({
    //                     type: content?.type,
    //                     eid: content?.eid,
    //                     title: content?.eid,
    //                     backgroundColor: content?.backgroundColor,
    //                 });
    //             } else {
    //                 contentsArray?.push({
    //                     type: content?.type,
    //                     eid: content?.eid,
    //                     title: content?.eid,
    //                 });
    //             }
    //         });
    //     }

    //     let input = {
    //         eid: item?.eid,
    //         title: item?.title,
    //         status: item?.status === 'active' ? 'inactive' : 'active',
    //         thumbnail: item?.thumbnail,
    //         description: item?.description,
    //         supervisors: supervisorsIdArray,
    //         assignedTo: assignedToIdArray,
    //         contents: contentsArray,
    //     };
    //     updateTraining({
    //         variables: {
    //             input: input,
    //         },
    //     });
    // }
    if (clickedItemName === 'change-log') {
      setIsChangeLogModalOpen(true);
      setSelectedDraftData(item);
    } else if (clickedItemName === 'open-edit') {
      history.push(`/drafts/edit/${item?.eid}`);
    } else if (clickedItemName === 'delete-draft') {
      setSelectedDraftData(item);
      setShowDeleteModal(true);
    }
  };

  const getMenuData = (menuData: TrainingData): IActionMenuData[] => {
    return [
      {
        name: t('open_edit_draft'),
        value: 'open-edit',
        icon: OpenIcon,
      },
      // {
      //   name: 'Publish changes',
      //   value: 'publish-changes',
      //   icon: PublishIcon,
      // },
      {
        name: t('change_log'),
        value: 'change-log',
        icon: viewChangeLogIcon,
      },
      {
        name: t('delete_draft'),
        value: 'delete-draft',
        icon: DeleteBinIcon,
      },
    ];
  };

  const getTableData = (isTablet: boolean) => {
    const tableData = trainingList?.map((item, index: number) => ({
      item: item,
      pathName: item.title?.trim(),
      assignee: item.assignedToUsers,
      cardCount: item.cardCount,
      createdBy: item.createdBy,
      createdOn: item.createdAt,
      updatedBy: item?.lastUpdatedBy || item.createdBy,
      updatedAt: item.updatedAt,
      status: item.status,
      thumbnail: item.thumbnail,
      visibility: item.visibility,
      action: (
        <div
          id={item.eid}
          style={{
            cursor: 'pointer',
            marginBottom: '25px',
          }}
        >
          <TableActionMenu
            menuData={getMenuData(item)}
            arrowSize={10}
            closeOnBlur={true}
            offset={[50, 5]}
            clickedItem={(_, clickedItemName) =>
              clickedItemHandler(clickedItemName, item)
            }
          >
            <img
              src={FilledHorizontal}
              style={{
                opacity: item?.status === 'active' ? '1' : '.5',
              }}
              alt='more-icon'
            />
          </TableActionMenu>
        </div>
      ),
    }));
    return [...tableData];
  };

  const handleQueryChange = (e) => {
    setSearchQuery(e.target.value);
    fetchTrainingListDataHandler(currentPageData?.currentPage, e.target.value);
    // trainingListData({
    //   variables: {
    //     page: 1,
    //     perPage: 10,
    //     filter: {
    //       query: e.target.value,
    //     },
    //     sort: '_ID_DESC',
    //   },
    // });
  };

  const onPageChangeHandler = (pageData: number) => {
    setCurrentPageData({ ...currentPageData, currentPage: pageData });
    fetchTrainingListDataHandler(pageData);
  };

  const onCloseTrainingModal = () => {
    setIsTrainingPathModalOpen(false);
  };

  const changeModalLogHandler = (type: string) => {
    setIsChangeLogModalOpen(false);
  };

  const onCloseHandler = () => {
    setShowDeleteModal(false);
  };

  const deleteDraftHandler = () => {
    setCurrentPageData({ ...currentPageData, currentPage: 1 });
    updateTraining({
      variables: {
        input: {
          eid: selectedDraftData?.eid,
          status: 'archieved',
        },
      },
    });
    setShowDeleteModal(false);
  };

  return (
    <DashboardContainer>
      <>
        <div className='training-title-wrapper'>
          <div className='training-title-container'>
            {t('training_draft_heading')}
          </div>
          <div
            style={{
              marginBottom: '24px',
              marginTop: '7px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {/* <PrimaryButton
                type='button'
                size='md'
                title='Show Progress Timeline'
                colorScheme='blue'
                onClick={() => history.push('/training/path')}
              /> */}
            {(userObject?.authRole?.toLowerCase() === SUPER_ADMIN ||
              userObject?.authRole?.toLowerCase() === ADMIN) && (
              <PrimaryButton
                leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
                type='button'
                size='md'
                title={t('add_draft')}
                colorScheme='blue'
                onClick={() => history.push('/drafts/create/draft')}
              />
            )}
          </div>
        </div>
        <div className='training-container'>
          <div className='training-search-container'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '16px',
                  height: '32px',
                  background: '#cabdff',
                  borderRadius: '4.00549px',
                }}
              ></div>
              <div
                style={{
                  fontWeight: '600',
                  lineHeight: '1.6',
                  fontSize: '20px',
                  marginLeft: '16px',
                  fontFamily: 'Inter',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                }}
              >
                {t('training_draft_component_heading')}
              </div>
            </div>
            <SearchInput
              disabled={true}
              size='md'
              width='435px'
              hideShortcuts={true}
              placeholder={t('search_drafts_placeholder')}
              onChange={handleQueryChange}
              value={searchQuery}
              className='training-search'
            />
          </div>
          <div ref={ref}>
            <SortingTable
              colorScheme='blue'
              emptyData={{
                content: (
                  <EmptyState
                    image='TrainingDraft'
                    title={t('unsavedTrainingAppear')}
                    description={t('unsavedTrainingAppearDesc')}
                  />
                ),
              }}
              page={currentPageData?.currentPage}
              onPageChange={onPageChangeHandler}
              columns={getColumns(false)}
              data={getTableData(false)}
              isLoading={trainingDataLoading}
              totalRegisters={currentPageData?.itemCount}
              isHidePagination={
                (currentPageData?.currentPage === 1 &&
                  currentPageData?.hasNextPage) ||
                currentPageData?.currentPage > 1
                  ? false
                  : true
              }
              isResponsive
            />
          </div>
          {isTrainingPathModalOpen && (
            <TrainingPathModal
              open={isTrainingPathModalOpen}
              onClose={onCloseTrainingModal}
              // selectedTrainingData={selectedTrainingData}
            />
          )}
        </div>
      </>
      {isChangeLogModalOpen && (
        <ChangeLog
          isReadOnly={true}
          open={isChangeLogModalOpen}
          onClose={(type: string) => changeModalLogHandler(type)}
          selectedDraftData={selectedDraftData}
        />
      )}
      {showDeleteModal && (
        <GenericModal
          isOpen={showDeleteModal}
          onClose={onCloseHandler}
          title={t('confirm_delete')}
          closeIcon={closeIcon}
          lineColor={'#b5e4ca'}
          isCentered={true}
          buttonsObject={[
            {
              mt: 6,
              type: 'button',
              size: 'lg',
              isLoading: false,
              disabled: false,
              title: tc('cancel'),
              leftIcon: null,
              rightIcon: null,
              onClick: onCloseHandler,
              style: {
                width: '160px',
                backgroundColor: 'white',
                marginRight: '1rem',
              },
              variant: 'outline',
              colorScheme: 'blue',
              className: null,
              value: null,
            },
            {
              mt: 6,
              type: 'button',
              size: 'lg',
              isLoading: false,
              disabled: false,
              title: tc('ok_uppercase'),
              leftIcon: null,
              rightIcon: null,
              onClick: deleteDraftHandler,
              style: { width: '160px' },
              variant: 'solid',
              colorScheme: 'blue',
              className: null,
              value: null,
            },
          ]}
          buttonsPosition='right'
          paddingLeft={30}
          paddingRight={30}
          paddingTop={30}
          paddingBottom={20}
          modalSizes={{
            sm: '800px',
            md: '800px',
            base: '800px',
            xl: '800px',
            ['2xl']: '800px',
          }}
          modalMinSizes={{
            sm: '800px',
            md: '800px',
            base: '800px',
            xl: '800px',
            ['2xl']: '800px',
          }}
        >
          <div
            style={{
              marginTop: '13px',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '1.63',
              letterSpacing: '-0.16px',
              marginLeft: '20px',
              width: '93%',
            }}
          >
            {null}
          </div>
        </GenericModal>
      )}
    </DashboardContainer>
  );
};

export default TrainingDraft;

import { IThirdStepData } from 'pages/Training/CreateTrainingPath/CreateContainer/AssignPublish/assign-publish-types.ts/AssignPublishTypes';
import { TrainingData } from 'pages/Training/training.types';
import { ChangeLogTableWrapper } from 'pages/TrainingDraft/ChangeLog/ChangeLogList/ChangeLogTable/ChangeLogTable.styles';
import {
  IChangeElementEntity,
  IChangeEventEntity,
  ICreateChangeLogChangesEntity,
} from 'pages/TrainingDraft/training-draft-types/training-draft-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AssigneesComponent from '../AssigneesComponent';
import BasicInfoComponent from '../BasicInfoComponent';
import LocationComponent from '../LocationComponent';
import MilestoneComponent from '../MilestoneComponent';
import OrderSequenceComponent from '../OrderSequenceComponent';
import RoleComponent from '../RoleComponent';
import SupervisorsComponent from '../SupervisorsComponent';
import TrainingCardsComponent from '../TrainingCardsComponent';

export interface IPublishChangeDiffCheckTable {
  tableType: IChangeEventEntity;
  tableData: {
    changes: ICreateChangeLogChangesEntity[];
  };
}

interface IPublishChangeDiffCheckTableEntity {
  changedData: IPublishChangeDiffCheckTable[];
  trainingData: TrainingData;
  initialTrainingData: TrainingData;
  thirdStepData: IThirdStepData;
}

const PublishChangeDiffCheckTable = ({
  changedData,
  trainingData,
  initialTrainingData,
  thirdStepData,
}: IPublishChangeDiffCheckTableEntity) => {
  const { t } = useTranslation(['common', 'training']);
  return (
    <div>
      <AssigneesComponent
        changedData={changedData}
        trainingData={trainingData}
        initialTrainingData={initialTrainingData}
        thirdStepData={thirdStepData}
        t={t}
      />
      <BasicInfoComponent changedData={changedData} t={t} />
      <MilestoneComponent changedData={changedData} t={t} />
      <RoleComponent
        changedData={changedData}
        trainingData={trainingData}
        initialTrainingData={initialTrainingData}
        t={t}
      />
      <LocationComponent
        changedData={changedData}
        trainingData={trainingData}
        initialTrainingData={initialTrainingData}
        thirdStepData={thirdStepData}
        t={t}
      />
      <OrderSequenceComponent
        changedData={changedData}
        trainingData={trainingData}
        initialTrainingData={initialTrainingData}
        t={t}
      />
      <SupervisorsComponent
        changedData={changedData}
        trainingData={trainingData}
        initialTrainingData={initialTrainingData}
        thirdStepData={thirdStepData}
        t={t}
      />
      <TrainingCardsComponent
        changedData={changedData}
        trainingData={trainingData}
        initialTrainingData={initialTrainingData}
        t={t}
      />
    </div>
  );
};

export default PublishChangeDiffCheckTable;

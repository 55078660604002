import React, { FC } from 'react';
import { Box, Flex, ListItem, Spacer } from '@chakra-ui/react';

import { GroupMemberEntity } from 'types';
import { ActionButton, IconImage, RoleAndAuthRole } from 'ui-components';

interface IProps {
  item: GroupMemberEntity;
  onActionClick: (data: GroupMemberEntity) => void;
  actionIcon: React.ReactElement;
}

const CMemberItem: FC<IProps> = ({ item, onActionClick, actionIcon }) => {
  return (
    <ListItem>
      <Flex align='center' gap='6px'>
        <IconImage
          name={item?.name}
          thumbnail={item?.avatar}
          boxSize={40}
          borderRadius='50%'
          objectFit='cover'
        />

        <Flex direction='column' overflow='hidden'>
          <Box fontWeight={500} color='#1A1D1F' isTruncated>
            {item?.name}
          </Box>
          <RoleAndAuthRole
            role={item?.metadata?.role}
            authRole={item?.metadata?.parents?.[0]}
            disableAuthRoleSplit
            defaultValue='-'
          />
        </Flex>
        <Spacer />

        <ActionButton
          padding='0'
          borderRadius='full'
          size='sm'
          actionFn={() => onActionClick?.(item)}
          pos='unset'
          variant='ghost'
        >
          {React.cloneElement(actionIcon, {
            // @ts-ignore
            'aria-hidden': true,
            focusable: false,
          })}
        </ActionButton>
      </Flex>
    </ListItem>
  );
};

export default CMemberItem;

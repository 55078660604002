import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';

import { BoxHeader } from '../../../../../../ui-components';

import SelectButton from './SelectButton';
import SelectRoleContent from './SelectRoleContent';
import RoleTags from './RoleTags';
import { AuthRole } from '../../../../../../authorization';

interface IProps {
  value?: string[];
  onChange: (value: string[]) => void;
  error?: FieldError;
  customStyles?: any;
}

const RoleDropDown: FC<IProps> = ({
  onChange,
  value: _value = [],
  error,
  customStyles,
}) => {
  const [width, setWidth] = useState<string>();

  const resize = useMemo(() => {
    return new ResizeObserver(function (entries) {
      let rect = entries[0]?.borderBoxSize?.[0];

      if (rect?.inlineSize) {
        setWidth(rect.inlineSize + 'px');
      }
    });
  }, []);

  useEffect(() => {
    const elm = document.querySelector('#popover-trigger-select-group');
    if (elm) {
      resize.observe(elm);
    }

    return () => {
      if (elm) {
        resize.unobserve(elm);
      }
    };
  }, []);

  const value = useMemo(() => {
    if (_value?.includes(AuthRole.SUPER_ADMIN)) {
      return _value;
    } else {
      onChange?.([AuthRole.SUPER_ADMIN, ..._value]);
      return [AuthRole.SUPER_ADMIN, ..._value];
    }
  }, [_value]);

  return (
    <>
      <Popover
        isLazy
        lazyBehavior='unmount'
        id='select-group'
        closeOnBlur={false}
        placement='bottom'
        autoFocus
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom'],
            },
          },
        ]}
      >
        {/*// @ts-ignore  */}
        <PopoverTrigger>
          <SelectButton
            error={error}
            // TODO: don't remove this id, this id is needed for calculating the width of containers
            id='select-group-box-width'
            style={customStyles}
          >
            Select role/s
          </SelectButton>
        </PopoverTrigger>
        <PopoverContent width={width} py={3} px={2}>
          <PopoverHeader borderBottom='none'>
            <Flex justify='space-between' align='center'>
              <BoxHeader
                title='Select role(s)'
                fontSize='18px'
                color='#CABDFF'
              />
              <PopoverCloseButton pos='relative' top='0' right='0' />
            </Flex>
          </PopoverHeader>
          <PopoverBody>
            <SelectRoleContent value={value} onChange={onChange} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <RoleTags value={value} onChange={onChange} />
    </>
  );
};

export default RoleDropDown;

import React, { FC, useMemo } from 'react';
import { Flex, FlexProps, Text } from '@chakra-ui/react';
import Image from '../../../../../ui-components/Image';
import { JsonItem } from '../../../../Chapters/ChapterPreview/PreviewBottom';
import { CardType } from '../../../../../types';

import { getFileIcon, getImage } from 'utils';
import { ChapterFileType, getFileType } from '../../../../../utils/utils';

// import singleImage from '../../../../../assets/images/singleImage.svg';
// import milestone from '../../../../../assets/images/milestone.svg';
import actionIcon from '../../../../../assets/images/resource/action.svg';
import formIcon from '../../../../../assets/images/resource/form.svg';
import milestoneIcon from '../../../../../assets/images/resource/milestone.svg';
import quizIcon from '../../../../../assets/images/resource/quiz.svg';
import DocIcon from '../../../../../assets/images/resource/docs.svg';
import PdfIcon from '../../../../../assets/images/resource/pdf.svg';
import ExcelIcon from '../../../../../assets/images/resource/xlsx.svg';
import ImageIcon from '../../../../../assets/images/resource/image.svg';
import VideoIcon from '../../../../../assets/images/resource/video.svg';

// @ts-ignore
const CardJson: Record<CardType | 'sop', string> = {
  [CardType.Text]: 'Text Card',
  [CardType.Video]: 'Video Card',
  [CardType.Image]: 'Image Card',
  [CardType.Action]: 'Action Card',
  [CardType.Quiz]: 'Quiz Card',
  [CardType.CustomForm]: 'Form',
  [CardType.Form]: 'Google Form',
  milestone: 'Milestone',
  sop: 'Chapter',
};

// @ts-ignore
const CardImageJson: Record<CardType | ChapterFileType, string> = {
  [CardType.Text]: getImage(undefined, 'T'),
  [CardType.Video]: VideoIcon,
  [CardType.Image]: ImageIcon,
  milestone: milestoneIcon,
  docs: DocIcon,
  pdf: PdfIcon,
  xlsx: ExcelIcon,
};

interface ThumbnailProps extends Pick<FlexProps, 'boxSize'> {
  id: string;
  item: JsonItem;
  onClick: () => void;
  isSelected?: boolean;
  imageWidth?: number;
}

const ThumbnailItem: FC<ThumbnailProps> = ({
  id,
  item,
  onClick,
  isSelected,
  boxSize,
  imageWidth = 0,
}) => {
  const thumbnail = useMemo(() => {
    if (item?.cardType === 'formCard') {
      return formIcon;
    }
    if (item?.cardType === 'action') {
      return actionIcon;
    }
    if (item?.cardType === 'quiz') {
      return quizIcon;
    }
    if (item?.type === 'milestone') {
      return milestoneIcon;
    }

    if (item?.thumbnail) {
      return item?.thumbnail;
    }
    if (item?.content?.[0]?.url) {
      return item?.content?.[0]?.url;
    }

    return (
      // @ts-ignore
      CardImageJson[item?.cardType || item?.type] ||
      // @ts-ignore
      CardImageJson[getFileType(item?.files?.[0]?.mimetype)] ||
      // @ts-ignore
      getFileIcon(item?.files?.[0]?.mimetype)
    );
  }, [item]);

  return (
    <Flex
      id={id}
      flexDir='column'
      gap='6px'
      minW='160px'
      boxSize={boxSize}
      alignItems='center'
      onClick={onClick}
      cursor='pointer'
      p={3}
      borderRadius='12px'
      bg='#fff'
      border='1.5px solid'
      borderColor={isSelected ? '#2A85FF' : '#EEEEEE'}
    >
      <Image
        src={thumbnail}
        width={imageWidth}
        height={imageWidth * 0.79}
        style={{
          borderRadius: 12,
          objectFit: 'cover',
        }}
      />

      <Text fontWeight='600' noOfLines={1}>
        {/* @ts-ignore */}
        {/*{CardJson[item?.cardType || item?.type]}*/}
        {item?.title}
      </Text>
    </Flex>
  );
};

ThumbnailItem.defaultProps = {
  boxSize: '160px',
  imageWidth: 140,
};

export default ThumbnailItem;

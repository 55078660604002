import styled from '@emotion/styled';

export const ProfileWrapper = styled.div`
  background: white;
  border-radius: 12px;
  //padding-block: 32px;
  padding-top: 24px;
  padding-bottom: 32px;

  padding-inline: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

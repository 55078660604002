import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Center,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { MessageButton } from '../../atoms';

import Image from '../../ui-components/Image';
import SingleUserChatModal from '../../ui-components/SingleUserChatModal';
import { IconsPlaceholder } from '../../ui-components/Search';

import PreviewHeader from '../forms/component/PreviewHeader';
import logo from '../../assets/images/ic_logo.png';
import { USER_DETAIL, UserDetailResponse } from './details.graphql';
import UserDetails from './UserDetails';
import LocationDetails from './LocationDetails';
import Loader from '../Loader';
import { useMemberList } from './useMemberList';

const Wrapper = styled.div`
  .subtitle-067 {
    width: max-content;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: -0.15px;
    color: #9a9fa5;
  }

  .detail-warp-0676 {
  }
`;

const TypeData: Record<string, string> = {
  branch: 'header:location_details',
  user: 'header:userDetails',
};

interface AttrsData {
  label: string;
  eid: string;
  image?: string;
  color?: string;
}

export interface UserDetailsRef {
  openDetail: (type: 'user' | 'branch', data: AttrsData) => Promise<void>;
}

interface IProps {}

const UserDetailsComponent = forwardRef<UserDetailsRef, IProps>(
  (props, ref) => {
    const { t } = useTranslation(['common', 'training', 'header']);
    const apolloClient = useApolloClient();
    const memberList = useMemberList();
    const toast = useToast({
      position: 'top-right',
      duration: 2000,
    });

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<UserDetailResponse['userById']>();
    const [params, setParams] = useState<{ attrs: AttrsData; type: string }>();
    const [shouldClearData, setShouldClearData] = useState(true);

    const { isOpen, onClose, onOpen } = useDisclosure({
      defaultIsOpen: false,
      onClose() {
        setShouldClearData(true);
        // setData(undefined);
        // setParams(undefined);
        // setLoading(true);
      },
    });

    useEffect(() => {
      if (shouldClearData) {
        setData(undefined);
        setParams(undefined);
        setLoading(true);
      }
    }, [shouldClearData]);

    const {
      isOpen: showChat,
      onClose: closeChat,
      onOpen: openChat,
    } = useDisclosure({
      defaultIsOpen: false,
    });

    const openChatHandler = () => {
      setShouldClearData(false);
      openChat();
      onClose();
      setShouldClearData(false);
    };

    const fetchDetails = useCallback(
      async (type: string, attrs: AttrsData) => {
        setParams({
          type: type,
          attrs: attrs,
        });
        onOpen();
        try {
          setLoading(true);
          const response = await apolloClient.query<UserDetailResponse>({
            query: USER_DETAIL,
            fetchPolicy: 'network-only',
            variables: {
              eid: attrs.eid,
            },
          });

          if (response.data?.userById) {
            setData(response.data.userById);
            setLoading(false);
          }
        } catch (e: any) {
          toast({
            status: 'warning',
            title: 'Error',
            description: 'Unable to fetch data!',
            isClosable: true,
            onCloseComplete: onClose,
          });
        }
      },
      [apolloClient]
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          openDetail: fetchDetails,
        };
      },
      [fetchDetails]
    );

    const onChatClose = () => {
      onOpen();
      closeChat();
    };

    const profilePic = useMemo(() => {
      if (data?.profilePic) {
        return data.profilePic;
      }
      if (params?.attrs?.image) {
        return params.attrs.image;
      }
      switch (data?.type || params?.type) {
        case 'user':
          return IconsPlaceholder['user'];
        case 'branch':
          return IconsPlaceholder['branch'];
        default:
          return logo;
      }
    }, [params, data]);

    const onViewMember = useCallback(() => {
      if (data?.eid) {
        memberList({
          eid: data.eid,
          name: data.name,
        });
      }
    }, [data, memberList]);

    return (
      <Fragment>
        <Modal
          size='full'
          isOpen={isOpen}
          onClose={onClose}
          closeOnOverlayClick={false}
        >
          <ModalOverlay zIndex={2004} />
          <ModalContent
            bg='#fff'
            borderRadius='0'
            p='12px'
            containerProps={{
              maxW: '436px',
              left: 'unset',
              right: 0,
              zIndex: 2004,
            }}
          >
            <ModalHeader>
              <Flex align='center' justify='space-between'>
                <PreviewHeader
                  color='#B1E5FC'
                  // @ts-ignore
                  title={t(TypeData[params?.type as string])}
                />
                <ModalCloseButton
                  pos='relative'
                  top='initial'
                  right='initial'
                  borderRadius='full'
                />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <Wrapper>
                <HStack
                  spacing='15px'
                  marginBottom='8px'
                  className={'className'}
                >
                  <Box borderRadius='12px'>
                    <Image
                      width={80}
                      height={80}
                      className='img-card-user'
                      src={profilePic}
                    />
                  </Box>

                  <VStack align='stretch' flex='1' textTransform='capitalize'>
                    <Box fontSize='18px' fontWeight='600' color='#1a1d1f'>
                      {params?.attrs?.label}
                    </Box>
                    <Box mt='0 !important'>
                      <div className='subtitle-067'>
                        {params?.type === 'user'
                          ? data?.role || '- - -'
                          : t('common:location')}
                      </div>
                    </Box>
                  </VStack>
                </HStack>

                {loading ? (
                  <Center minH='150px'>
                    <Loader size='lg' />
                  </Center>
                ) : (
                  <Box>
                    <UserDetails data={data} />
                    <LocationDetails data={data} onViewMember={onViewMember} />
                  </Box>
                )}

                <MessageButton
                  w='full'
                  size='lg'
                  colorScheme='blue'
                  margin='12px 0 8px'
                  isDisabled={loading}
                  onClick={openChatHandler}
                />
              </Wrapper>
            </ModalBody>
          </ModalContent>
        </Modal>
        {showChat && (
          <SingleUserChatModal onClose={onChatClose} selectedUser={data?.eid} />
        )}
      </Fragment>
    );
  }
);

export default UserDetailsComponent;

UserDetailsComponent.displayName = 'User Details';

import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { toArray } from '../../../../utils/utils';
import ProgressItem from './ProgressItem';

interface IProps {
  trainingItems: any[];
  userProgress: any;
  name?: string;
  profilePic?: string;
  selectedTrainingId?: string;
}

const ProgressContainer: FC<IProps> = ({
  userProgress,
  trainingItems,
  profilePic,
  name,
  selectedTrainingId,
}) => {
  const itemList = useMemo(() => {
    const viewedContents = toArray(userProgress?.viewedContents);
    return toArray(trainingItems).map((item) => {
      const find = viewedContents.find((value) => value.uid === item.eid);
      return {
        ...item,
        isCompleted: !!find?.uid,
        currentlySelected: item?.uid === selectedTrainingId,
      };
    });
  }, [trainingItems, userProgress, selectedTrainingId]);

  return (
    <Flex flexDir='column'>
      {itemList.map((value, index, arr) => {
        return (
          <ProgressItem
            index={index}
            isLastItem={arr.length === index + 1}
            nextCompleted={arr[index + 1]?.isCompleted}
            data={value}
            key={value.eid}
            profilePic={profilePic}
            userName={name}
          />
        );
      })}
    </Flex>
  );
};

export default ProgressContainer;

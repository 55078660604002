import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormInput } from '../task.types';
import {
  BaseFormStepper,
  SelectOption,
  useStepperAction,
  useStepperIsCompleted,
  useStepperIsFilled,
} from '../common';
import SupervisorStepperDescription from './SupervisorStepperDescription';

import taskSupervisor from '../../../../assets/images/task-supervisor.svg';
import taskCheckCircle from '../../../../assets/images/task-check-circle.svg';

interface IProps {
  members: SelectOption[];
}

const SupervisorStepper: FC<IProps> = ({ members }) => {
  const { t } = useTranslation('task');
  const { handleSubmit, watch } = useFormContext<IFormInput>();
  const isCompleted = useStepperIsCompleted('supervisors');
  const isFilled = useStepperIsFilled('supervisors');

  const { onSuccess, onError } = useStepperAction('supervisors');

  const haveSuper = !!watch('supervisors')?.length;

  return (
    <BaseFormStepper
      imageUrl={isFilled ? taskCheckCircle : taskSupervisor}
      isCompleted={isCompleted}
      title={t(isCompleted ? 'supervisors' : 'selectSupervisors')}
      caption={
        isCompleted && haveSuper ? (
          <SupervisorStepperDescription members={members} />
        ) : (
          t('woWillManage')
        )
      }
      onClick={handleSubmit(onSuccess, onError)}
    />
  );
};

export default SupervisorStepper;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Center, Flex, Image, Progress, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { match, P } from 'ts-pattern';
import moment from 'moment';

import { Loader } from 'sub-components';
import { useUserData } from 'hooks';
import { generatePdfThumbnail } from 'utils/pdfThumbnail';
import { generateVideoThumbnail } from 'utils/videoThumbnail';

import { FileDataEntity } from '../create-chapter.graphql';
import { getFileType } from '../helper';

import PdfImg from '../../../../assets/images/pdf-img.png';
import DocImg from '../../../../assets/images/doc-img.png';
import XlsImg from '../../../../assets/images/xls-img.png';

const Excel = [
  'vnd.ms-excel',
  'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
const Word = [
  'msword',
  'vnd.openxmlformats-officedocument.wordprocessingml.document',
];

interface IProps {
  file: FileDataEntity;
  onRemove?: () => void;
  isLargerFile?: boolean;
  maxFileSize?: string;
  isUploading?: boolean;
  progress?: number;
}

const FileItem: FC<IProps> = ({
  file,
  onRemove,
  isLargerFile,
  maxFileSize = '5 MB',
  isUploading,
  progress = 0,
}) => {
  const { t, i18n } = useTranslation(['common', 'chapter']);
  const userName = useUserData()?.name;

  const [thumbLoading, setThumbLoading] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState<string>();

  const fileSize = useMemo(() => {
    const units = ['bytes', 'KB', 'MB', 'GB'];

    if (!file?.fileSize) {
      return 0;
    }

    let l = 0;
    let n = parseInt(file.fileSize + '', 10) || 0;
    while (n >= 1000 && ++l) {
      n = n / 1000;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }, [file]);

  const uploadDate = useMemo(() => {
    const _time = file?.url?.match(/\/[0-9]+/)?.[0]?.replace(/^\D+/g, '');

    if (_time) {
      return moment(+_time).format('DD MMM, hh:mm A');
    }

    return t('common:today');
  }, [t, i18n.language, file]);

  useEffect(() => {
    (async () => {
      setThumbLoading(true);
      const _image = await match(file?.mimetype?.split('/'))
        .with(
          ['application', P.when((value) => Word.includes(value))],
          () => DocImg
        )
        .with(
          ['application', P.when((value) => Excel.includes(value))],
          () => XlsImg
        )
        .with(
          ['video', P._],
          async () =>
            await generateVideoThumbnail(file?.file! || file?.url!, 0.5)
        )
        .with(
          ['application', 'pdf'],
          async () => await generatePdfThumbnail(file?.file! || file?.url!)
        )
        .otherwise(() => PdfImg);

      setThumbnail(_image!);
      setThumbLoading(false);
    })();
  }, [file]);

  const bgColor = useMemo(() => {
    return match(file?.mimetype?.split('/'))
      .with(
        ['application', P.when((value) => Word.includes(value))],
        () => '#E6F0FF'
      )
      .with(
        ['application', P.when((value) => Excel.includes(value))],
        () => '#E6F4E1'
      )
      .with(['video', P._], () => '#FFECEC')
      .with(['application', 'pdf'], () => '#FFECEC')
      .otherwise(() => '#FFECEC');
  }, [file]);

  if (!file) {
    return null;
  }

  return (
    <Flex
      flexDir='column'
      border='1px solid #DDDDDD'
      borderColor={isLargerFile ? '#FF6A55' : '#DDDDDD'}
      borderRadius='8px'
      bg={isLargerFile ? '#FFEBEB' : undefined}
    >
      <Flex gap={3} p='16px' align='center'>
        <Center
          boxSize='60px'
          bg={bgColor}
          borderRadius='6px'
          border='1px solid'
          borderColor={bgColor}
        >
          {thumbLoading ? (
            <Loader />
          ) : (
            <Image
              boxSize={
                ['video', 'pdf'].includes(getFileType(file.mimetype))
                  ? '58px'
                  : '34px'
              }
              borderRadius='6px'
              src={thumbnail}
            />
          )}
        </Center>
        <Flex
          flex={1}
          flexDir='column'
          color='#333B4F'
          fontWeight={400}
          fontSize='14px'
        >
          <Text fontWeight={600} fontSize='16px'>
            {file.name}
          </Text>

          {isLargerFile ? (
            <Text fontSize='14px' fontWeight='500' color='#FF6A55'>
              <Trans
                t={t}
                i18nKey='chapter:fileLimitUploadAnother'
                values={{
                  fileSize: maxFileSize,
                }}
              />
            </Text>
          ) : (
            <Flex gap={1}>
              <Text>{fileSize},</Text>
              <Text>
                <Trans
                  t={t}
                  i18nKey='chapter:uploadedBy'
                  values={{
                    name: userName,
                  }}
                  components={{
                    span: (
                      <Box
                        as='span'
                        bg='#CABDFF66'
                        w='fit-content'
                        px={1}
                        borderRadius='2px'
                      />
                    ),
                  }}
                />
              </Text>
              <Text>{uploadDate}</Text>
            </Flex>
          )}
        </Flex>

        {!isUploading && onRemove && (
          <CloseIcon
            cursor='pointer'
            boxSize='14px'
            color='#828282'
            onClick={onRemove}
          />
        )}
      </Flex>

      {isUploading && (
        <Progress
          height={1}
          mx='2px'
          borderRadius='full'
          value={progress}
          colorScheme={progress >= 99 ? 'green' : 'blue'}
          isIndeterminate={progress < 1}
          hasStripe={isUploading}
          isAnimated={isUploading}
        />
      )}
    </Flex>
  );
};

export default FileItem;

import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { QuestionType } from 'types';
import Section from './Section/section';
import Question from './question/Question';
import { OtherQuestion } from './OtherQuestion';

const ChangeTitle = [QuestionType.TEXT_SECTION, QuestionType.ACKNOWLEDGMENT];

interface IProps {
  isReadOnly?: boolean;
  currentIndex: number;
  handleRemove: (index: number) => void;
  handleDuplicate: (index: number) => void;
  isLast?: boolean;
  handleMoveUp: (index: number) => void;
  handleMoveDown: (index: number) => void;
}

const QuestionAndSection: FC<IProps> = ({
  currentIndex,
  handleRemove,
  handleDuplicate,
  handleMoveUp,
  handleMoveDown,
  isReadOnly,
  isLast,
}) => {
  const qType: QuestionType = useWatch({
    name: `questions.${currentIndex}.qType`,
  });
  const isArchieved: boolean = useWatch({
    name: `questions.${currentIndex}.isArchieved`,
  });

  if (isArchieved) {
    return null;
  }

  if (qType === QuestionType.SECTION) {
    return (
      <Section
        currentIndex={currentIndex}
        handleRemove={handleRemove}
        isReadOnly={isReadOnly}
      />
    );
  }

  if (ChangeTitle.includes(qType)) {
    return (
      <OtherQuestion
        currentIndex={currentIndex}
        handleRemove={handleRemove}
        handleDuplicate={handleDuplicate}
        isReadOnly={isReadOnly}
        isLast={isLast}
        handleMoveUp={handleMoveUp}
        handleMoveDown={handleMoveDown}
      />
    );
  }

  return (
    <Question
      currentIndex={currentIndex}
      handleRemove={handleRemove}
      handleDuplicate={handleDuplicate}
      isReadOnly={isReadOnly}
      isLast={isLast}
      handleMoveUp={handleMoveUp}
      handleMoveDown={handleMoveDown}
    />
  );
};

export default QuestionAndSection;

import moment from 'moment';
import { ILauncherContentsEntity } from 'sop-commons/src/client';

export const getPhaseTasksDuration = (
  phaseContents: ILauncherContentsEntity[]
): number => {
  return phaseContents.reduce(
    (totalSum, phase) =>
      totalSum +
      phase.tasks.reduce((phaseSum, task) => phaseSum + task.duration, 0),
    0
  );
};

export const getDateFromDuration = (duration: number): moment.Moment => {
  return moment.utc().add(duration, 'days').startOf('day');
};

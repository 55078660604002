export interface AddSubFolderResponse {
  addCategory: {
    eid: string;
  };
}

export interface AddSubFolderVariable {
  eid: string;
  category: string;
  icon?: string;
  color?: string;
  parent: string;
  visibility?: 'public' | 'private';
  roles?: string[];
  users?: string[];
  locations?: string[];
  condition?: 'or' | 'and';
}

export interface UpdateSubFolderResponse {
  updateCategory: {
    succeed: boolean;
  };
}

export interface UpdateSubFolderVariable {
  categoryId: string;
  category?: string;
  parent?: string;
  icon?: string;
  sopVisibilityMismatch?: boolean;
  visibility?: 'public' | 'private';
  roles?: string[];
  users?: string[];
  locations?: string[];
  condition?: 'or' | 'and';
}

import { gql } from '@apollo/client';

export const CREATE_SUBFOLDER = gql`
  mutation AddCategory(
    $eid: String!
    $category: String!
    $icon: String
    $color: String
    $parent: String
    $visibility: EnumEntityCategoryVisibility
    $roles: [String]
    $users: [String]
    $locations: [String]
    $condition: EnumEntityCategoryVisibleToCondition
  ) {
    addCategory(
      eid: $eid
      category: $category
      icon: $icon
      color: $color
      parent: $parent
      visibility: $visibility
      roles: $roles
      users: $users
      locations: $locations
      condition: $condition
    ) {
      eid
    }
  }
`;

export const UPDATE_SUBFOLDER = gql`
  mutation UpdateCategory(
    $categoryId: String!
    $parent: String
    $category: String
    $icon: String
  ) {
    updateCategory(
      categoryId: $categoryId
      parent: $parent
      category: $category
      icon: $icon
    ) {
      succeed
    }
  }
`;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { faMessageLines } from '@fortawesome/pro-regular-svg-icons';
import CommonContainer from 'ui-components/CommonContainer';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { faEllipsisStrokeVertical } from '@fortawesome/pro-solid-svg-icons';
import {
  Box,
  Flex,
  List,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import CommonLabel from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';
import { CheckIcon } from '@chakra-ui/icons';
import { IHelpQueryProps } from 'sub-components/LocationLaunchDetail/types/types';

/**
 * `HelpQueryCard` is a functional component that displays a query card with interactive options.
 * It includes a message icon, a popover for additional actions, and displays the provided query.
 *
 * @param {IHelpQueryProps} props The props object for the component, containing the query.
 * @returns {React.ReactElement} The rendered query card component.
 */
const HelpQueryCard: FC<IHelpQueryProps> = (props) => {
  const { query } = props;
  return (
    <CommonContainer bg='white' borderRadius='24px' w='200px' p='15px 19px'>
      <Flex flexDir='column'>
        <Flex justify='space-between' w='full'>
          <FontAwesomeIcon
            icon={faMessageLines as IconProp}
            flip='horizontal'
            color='#6F767E'
          />
          <Popover>
            {/*// @ts-ignore */}
            <PopoverTrigger>
              <Box>
                <FontAwesomeIcon
                  icon={faEllipsisStrokeVertical as IconProp}
                  color='#6F767E'
                  cursor='pointer'
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent p='16px' borderRadius='16px' w='280px'>
              <List>
                <ListItem display='flex' alignItems='center' gap={2}>
                  <CheckIcon />
                  <CommonLabel
                    label='Mark as resolved'
                    fontSize='15px'
                    fontWeight={500}
                    color='#1A1D1F'
                  />
                </ListItem>
                <ListItem display='flex' alignItems='center' gap={2}>
                  <FontAwesomeIcon
                    icon={faMessageLines as IconProp}
                    flip='horizontal'
                    color='#1A1D1F'
                  />
                  <CommonLabel
                    label='Open chat'
                    fontSize='15px'
                    fontWeight={500}
                    color='#1A1D1F'
                  />
                </ListItem>
              </List>
            </PopoverContent>
          </Popover>
        </Flex>
        <CommonLabel label={query} color={HeaderColors.DarkBlue} />
      </Flex>
    </CommonContainer>
  );
};

export default HelpQueryCard;

import { IFormDataContext } from 'pages/forms/FormStore/form-data-context';
import { IChartEntity, ISummary } from './form-summary.types';

export const formSummaryFunctions = (
  data: ISummary,
  colorsArray: string[],
  formCtx: IFormDataContext
) => {
  let _dropDownData: IChartEntity[] = [];
  let _multiChoiceData: IChartEntity[] = [];
  let _locationUserData: IChartEntity[] = [];
  let _memberData: IChartEntity[] = [];
  let _singleChoiceData: IChartEntity[] = [];
  let _checkBoxData: IChartEntity[] = [];
  data?.FormAnalytics?.map((analytics) => {
    if (analytics?.qType === 'dropDown') {
      let totalResponse = analytics?.totalResponse;
      _dropDownData?.push({
        questionName: analytics?.label,
        // questionResponseData: [...analytics?.options]?.map((option, opIndex) => {
        //     return {
        //         name: option?.label,
        //         value: option?.responseCount / totalResponse,
        //         color: colorsArray[opIndex % 35],
        //         originalValue: option?.responseCount
        //     };
        // }),
        questionResponseData: analytics?.options?.map((option, opIndex) => {
          return {
            name: option?.label,
            value: option?.responseCount / totalResponse,
            color: colorsArray[opIndex % 35],
            originalValue: option?.responseCount,
          };
        }),
        totalResponseCount: totalResponse,
      });
    } else if (analytics?.qType === 'multiChoice') {
      let totalResponse = analytics?.options?.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue?.responseCount,
        0
      );
      _multiChoiceData?.push({
        questionName: analytics?.label,
        questionResponseData: analytics?.options?.map((option, opIndex) => {
          return {
            name: option?.label,
            value: option?.responseCount,
            color: colorsArray[opIndex % 35],
            originalValue: option?.responseCount,
          };
        }),
        totalResponseCount: totalResponse,
      });
    } else if (analytics?.qType === 'location') {
      let _questionResponseData = [];
      for (const [index, [key, value]] of Object.entries(
        Object.entries(analytics?.responseCount!)
      )) {
        _questionResponseData.push({
          name: formCtx?.formResponseByIdData?.userList?.filter(
            (list) => list?.eid === key
          )?.[0]?.name,
          value: value,
          color: colorsArray[+index % 35],
          originalValue: value,
        });
      }
      let sum = 0;
      if (analytics?.responseCount) {
        for (const key in analytics?.responseCount) {
          if (analytics?.responseCount?.hasOwnProperty(key)) {
            sum += analytics?.responseCount?.[key];
          }
        }
      }
      let totalResponse = sum;
      _questionResponseData?.map((que) => {
        que.value = que.value / totalResponse;
      });
      _locationUserData?.push({
        questionName: analytics?.label,
        questionResponseData: _questionResponseData,
        totalResponseCount: totalResponse,
      });
    } else if (analytics?.qType === 'member') {
      let _questionResponseData = [];
      for (const [index, [key, value]] of Object.entries(
        Object.entries(analytics?.responseCount!)
      )) {
        _questionResponseData.push({
          name: formCtx?.formResponseByIdData?.userList?.filter(
            (list) => list?.eid === key
          )?.[0]?.name,
          value: value,
          color: colorsArray[+index % 35],
          originalValue: value,
        });
      }
      let totalResponse = analytics?.totalResponse;
      _questionResponseData?.map((que) => {
        que.value = que.value / totalResponse;
      });
      _memberData?.push({
        questionName: analytics?.label,
        questionResponseData: _questionResponseData,
        totalResponseCount: totalResponse,
      });
    } else if (analytics?.qType === 'singleChoice') {
      let totalResponse = analytics?.totalResponse;
      _singleChoiceData?.push({
        questionName: analytics?.label,
        questionResponseData: analytics?.options?.map((option, opIndex) => {
          return {
            name: option?.label,
            value: option?.responseCount / totalResponse,
            color: colorsArray[opIndex % 35],
            originalValue: option?.responseCount,
          };
        }),
        totalResponseCount: totalResponse,
      });
    } else if (analytics?.qType === 'checkBox') {
      let totalResponse = analytics?.totalResponse;
      _checkBoxData?.push({
        questionName: analytics?.label,
        questionResponseData: analytics?.options?.map((option, opIndex) => {
          return {
            name: option?.label,
            value: option?.responseCount / totalResponse,
            color: colorsArray[opIndex % 35],
            originalValue: option?.responseCount,
          };
        }),
        totalResponseCount: totalResponse,
      });
    }
  });
  return {
    _dropDownData,
    _multiChoiceData,
    _locationUserData,
    _memberData,
    _singleChoiceData,
    _checkBoxData,
  };
};

import React, { FC, useMemo } from 'react';
import { Box, Flex, Link } from '@chakra-ui/react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { LocationMember } from '../../../types';
import { toArray } from '../../../utils/utils';
import { MessageButton } from '../../../atoms';
import { Image, RoleAndAuthRole } from '../../../ui-components';
import { IconsPlaceholder } from '../../../ui-components/Search';

import { ContentItem } from '../../../sub-components/SearchModal';

import mailIcon from '../../../assets/images/UI icon_mail_light.svg';
import locationIcon from '../../../assets/images/location.svg';
import phoneIcon from '../../../assets/images/phoneIcon.svg';

const QUERY = gql`
  query UserDetailById($eid: String!) {
    userById(eid: $eid) {
      phone
      locations {
        eid
        name
      }
    }
  }
`;

interface Response {
  userById: {
    phone?: string;
    locations: Array<{
      eid: string;
      name: string;
    }>;
  };
}

interface IProps {
  data: LocationMember;
  onSendMessage?: () => void;
}

const ModalContent: FC<IProps> = ({ data, onSendMessage }) => {
  const { t } = useTranslation(['common', 'header']);

  const { data: response, loading } = useQuery<Response>(QUERY, {
    fetchPolicy: 'network-only',
    skip: !data?.eid,
    variables: {
      eid: data?.eid,
    },
  });

  const userData = response?.userById;

  const locationName = useMemo(() => {
    return toArray(userData?.locations)
      .map((it) => it.name)
      .join('\n');
  }, [userData?.locations]);

  return (
    <Box pt='8px'>
      <Flex gap='16px' marginBottom='8px'>
        <Image
          width={56}
          height={56}
          style={{
            borderRadius: 8,
            maxWidth: 56,
            maxHeight: 56,
          }}
          src={data?.profilePic || IconsPlaceholder['user']}
        />
        <Flex flexDir='column' flex='1'>
          <Box fontSize='18px' fontWeight='600' color='#1a1d1f'>
            {data?.name || 'N/A'}
          </Box>
          <RoleAndAuthRole
            role={data?.role}
            authRole={data?.authRole}
            defaultValue='-'
          />
        </Flex>
      </Flex>

      <div>
        <ContentItem
          leftIcon={mailIcon}
          title={data?.email || '- -'}
          contentProps={{
            ...(data?.email && {
              as: Link,
              color: '#2A85FF !important',
              // @ts-ignore
              href: 'mailto:'.concat(data.email),
            }),
          }}
        />
        {(userData?.phone?.length! > 5 || loading) && (
          <ContentItem
            leftIcon={phoneIcon}
            title={userData?.phone}
            isLoading={loading}
          />
        )}

        <ContentItem
          leftIcon={locationIcon}
          title={locationName || '- -'}
          isLoading={loading}
          contentProps={{
            whiteSpace: 'pre-line',
          }}
        />
      </div>

      <Box mt='12px' pb='8px'>
        <MessageButton
          width='full'
          colorScheme='blue'
          isLoading={loading}
          isDisabled={data?.status !== 'active' || loading}
          onClick={onSendMessage}
        />
      </Box>
    </Box>
  );
};

export default ModalContent;

import { useReactiveVar } from '@apollo/client';
import { useUserData } from 'hooks';
import moment from 'moment-timezone';

export const getDateInUserTimezone = (toBeConvertedDate: string) => {
  const userData = useUserData();
  const userTimezone = userData?.timezone;
  let convertedDate = moment(
    moment.utc(toBeConvertedDate),
    'DD-MMM-YYYY hh:mm a'
  )?.tz(userTimezone);
  return convertedDate;
};

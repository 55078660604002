import React, { CSSProperties, FC } from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Flex, ModalCloseButton as Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const TypeData: Record<string, string> = {
  branch: 'location_details',
  user: 'member_details',
};

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface IProps {
  onClick?: () => void;
  title?: string;
  type?: string;
  updatedTitle?: string;
}

const Header: FC<IProps> = ({ title, type, onClick, updatedTitle }) => {
  const { t } = useTranslation('header');

  return (
    <Flex align='center' pr='32px'>
      <Box mr={'12px'} display='flex'>
        {!type ? (
          <Box h='32px' w='15px' borderRadius='4px' bg='#b5e4ca' />
        ) : (
          <ArrowBackIcon
            color='#9a9fa5'
            onClick={onClick}
            style={{ cursor: 'pointer' }}
          />
        )}
      </Box>
      <div className='User-Details' style={wrapStyles}>
        {/*// @ts-ignore*/}
        {updatedTitle || t(TypeData[type as string]) || title}
      </div>
    </Flex>
  );
};

export const ModalCloseButton: FC = () => (
  <Button
    style={{
      marginTop: 30,
      marginRight: 20,
      outline: 'none',
      boxShadow: 'none',
    }}
  />
);

export default Header;

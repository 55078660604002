import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { NoteImageEntity } from '../../../../../types';
import { NoteImageTag } from '../ResponseCell';

interface IProps {
  shortAnswer: string;
  noteImages?: NoteImageEntity[];
}

const ShortAnswerComponent: FC<IProps> = ({ shortAnswer, noteImages }) => {
  return (
    <Flex flexDir='column' gap='5px' overflow='hidden'>
      {shortAnswer && (
        <div
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'break-spaces',
            overflow: 'hidden',
          }}
        >
          {shortAnswer}
        </div>
      )}
      {!shortAnswer && <div>-</div>}
      <NoteImageTag noteImages={noteImages} />
    </Flex>
  );
};

export default ShortAnswerComponent;

import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface IProps {
  icon: any;
  text: string;
  textColor?: string;
  bg?: string;
  padding?: string;
  borderRadius?: string;
}

const IconText: FC<IProps> = ({
  icon,
  text,
  textColor,
  bg,
  padding,
  borderRadius,
}) => {
  return (
    <Flex
      align='center'
      w='fit-content'
      gap='5px'
      bg={bg || 'none'}
      textColor={textColor || 'black'}
      p={padding || '5px 0px'}
      borderRadius={borderRadius || '8px'}
    >
      {icon}
      <Text textTransform='capitalize'>{text}</Text>
    </Flex>
  );
};

export default IconText;

import React, { FC } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import LocationShare from '../../../sub-components/Locations/LocationShareModal';
import { ILocation } from '../../../sub-components/Locations/locationTypes/location-types';
import SingleUserChatModal from '../../../ui-components/SingleUserChatModal';
import InviteUserModal from '../../../ui-components/InviteUserNew/InviteUserModal';
import { LocationResponse, LocationVariable } from './locations.graphql';

interface IProps {
  locationData: ILocation | undefined;
  isShareModalOpen: boolean;

  selectedUserChatId?: string;
  showInviteModal: boolean;
  setIsShareModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userChatModalHandler: () => void;
  setShowInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  refetch:
    | ((
        variables?: Partial<LocationVariable> | undefined
      ) => Promise<ApolloQueryResult<LocationResponse>>)
    | undefined;
  getVariables: () => LocationVariable;
}

const LocationModals: FC<IProps> = ({
  locationData,
  isShareModalOpen,
  selectedUserChatId,
  showInviteModal,
  setIsShareModalOpen,
  userChatModalHandler,
  setShowInviteModal,
  refetch,
  getVariables,
}) => {
  return (
    <>
      {isShareModalOpen && (
        <LocationShare
          data={locationData}
          locationOwnerSearchType
          onCloseClick={() => setIsShareModalOpen(false)}
        />
      )}
      {selectedUserChatId && (
        <SingleUserChatModal
          selectedUser={selectedUserChatId}
          onClose={userChatModalHandler}
        />
      )}
      {showInviteModal && (
        <InviteUserModal
          isOpen={showInviteModal}
          onClose={() => {
            setShowInviteModal(false);
            refetch && refetch(getVariables());
          }}
          onActionCompleted={() => refetch && refetch(getVariables())}
          shouldRefetch={() => {
            setTimeout(() => {
              refetch && refetch(getVariables());
            }, 5000);
          }}
        />
      )}
    </>
  );
};

export default LocationModals;

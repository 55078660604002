import React, {
  ChangeEventHandler,
  FC,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  AlertDialogCloseButton,
  Box,
  Flex,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { userObj } from 'sop-commons/src/client';

import CategorySelect from './CategorySelect';
import { SelectOption } from '../../../atoms';
import SearchInput from '../../../atoms/SearchInput';
import AddCategory from './AddCategory';
import { CategoryContext } from './Context';
import { useFolderSubFolder } from 'hooks';
import { FolderSubFolderSelect } from 'pages/Chapters/CreateEditSubFolder/components';
import { BULK_MOVE } from 'pages/Chapters/ChapterHeader/components/BulkMoveContainer';
import { getFoldersList } from 'shared/graphql/SharedGraphql';
import { useHistory } from 'react-router-dom';
import MoveFolderSubFolderSelect from 'pages/Chapters/CreateEditSubFolder/components/MoveFolderSubFolderSelect';
import { GET_CHAPTERS } from 'pages/Chapters/chapters.graphql';
import { eventBus } from 'shared/eventEmit/eventBus';
import { EventBusEmits } from 'shared/eventEmit/defaults/defaults';

interface HeaderProps {
  onBackClick: () => void;
  onClose: () => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const MoveToCategoryHeader: FC<HeaderProps> = ({
  onBackClick,
  onClose,
  onChange,
}) => {
  const { t } = useTranslation('category');

  return (
    <Box>
      <Flex gap='12px' align='center'>
        <Box cursor='pointer'>
          <FontAwesomeIcon
            icon={faArrowLeft as IconProp}
            fontSize='17px'
            onClick={onBackClick}
          />
        </Box>
        <Box
          flex={1}
          fontWeight='600'
          fontSize='20px'
          lineHeight='32px'
          color='#1a1d1f'
          userSelect='none'
        >
          {/* {t('moveChapters')} */}
          Move items to another folder
        </Box>
        <AlertDialogCloseButton
          pos='relative'
          borderRadius='full'
          top='0'
          right='0'
          onClick={(event) => {
            event.preventDefault();
            onClose?.();
          }}
        />
      </Flex>
      <Flex
        mt={3}
        fontSize='14px'
        fontWeight={400}
        color='#33383F'
        bg='rgba(177, 229, 252, 0.4)'
        py='12px'
        px='16px'
        borderRadius='10px'
        gap='12px'
        align='center'
      >
        <FontAwesomeIcon icon={faArrowUpRight as IconProp} color='#2A85FF' />
        {/* {t('movingToAnotherFolder')} */}
        <Text>Moving items will remove them from their current folder.</Text>
      </Flex>
      <Box mt={3}>
        <SearchInput
          size='md'
          width='100%'
          hideShortcuts
          // placeholder={t('searchChapter')}
          placeholder='Search chapters and subfolders'
          disabled
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};

export const MoveToCategoryFooter: FC<{
  onClose: () => void;
  redirectHandler?: (folderId: string) => void;
}> = ({ onClose, redirectHandler }) => {
  const { t } = useTranslation('category');
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });
  const [moveItems, { loading }] = useMutation(BULK_MOVE, {
    onCompleted: (data) => {
      toast({
        status: 'success',
        title: 'Items moved successfully',
      });
      fetchFoldersList();
    },
    onError: () => {
      toast({
        status: 'error',
        title: 'Items could not be moved',
      });
    },
  });
  const [value, setValue] = useState<SelectOption>();
  const categoryEntities = useReactiveVar(userObj)?.entity?.category || [];
  const { category, totalCount, itemsList } = useContext(CategoryContext);
  const onFetchFoldersListSuccess = () => {
    // eventBus.emit(EventBusEmits.sidebar.MOVE_TO_CATEGORY_HISTORY_PUSH, {
    //   selectedFolderId: value?.eid,
    // });
    redirectHandler?.(value?.eid);
    onClose();
  };
  const { execute: fetchFoldersList, loading: gettingFoldersList } =
    getFoldersList(onFetchFoldersListSuccess);
  const { getAllFolderSubFolderList } = useFolderSubFolder();

  const [newCategory, setNewCategory] = useState(false);

  const categoryList = useMemo(() => {
    return getAllFolderSubFolderList().reduce((previousValue, item) => {
      if (item.name !== category) {
        if (item?.type === 'folder') {
          previousValue.push({
            ...item,
            label: item?.name,
            value: item?.eid,
            eid: item?.eid,
            type: 'Folder',
          });
        }
      }
      return previousValue;
    }, [] as SelectOption[]);
  }, [categoryEntities, category]);

  if (totalCount === 0) {
    return <Box />;
  }

  const moveHandler = () => {
    let input = {
      categoryId: value?.eid,
      contents: itemsList
        ?.filter((list) => list?.checked)
        ?.map((item) => item?.eid),
    };
    console.log(input);
    moveItems({
      variables: {
        input: input,
      },
    });
  };

  return (
    <Box width='full'>
      <MoveFolderSubFolderSelect
        allFolderSubFolderList={categoryList}
        anySelectionMade={!!itemsList?.filter((list) => list?.checked)?.length}
        loading={gettingFoldersList || loading}
        value={value}
        onMove={moveHandler}
        setValue={setValue}
        showOnly='folder'
      />
      {/* <FolderSubFolderSelect
        options={categoryList}
        value={value}
        loading={gettingFoldersList || loading}
        onChange={(newValue) => setValue(newValue as SelectOption)}
        isDisabled={
          itemsList?.filter((list) => list?.checked)?.length === 0 ||
          gettingFoldersList ||
          loading
        }
        onMove={() => moveHandler()}
        placeholder={'Select folder or sub folder'}
        selectStyles={{
          container: {
            background: '#2A85FF',
            fontWeight: 700,
          },
          singleValue: {
            color: '#FCFCFC',
          },
          dropdownIndicator: {
            svg: {
              color: '#FCFCFC',
            },
          },
          inputContainer: {
            color: 'white',
          },
          placeholder: {
            color: 'white',
            fontWeight: 700,
          },
        }}
      /> */}
      <AddCategory
        open={newCategory}
        type='folder'
        onClose={() => setNewCategory(false)}
        zIndex={2002}
        onCategoryCreate={(cate) => {
          setValue({
            label: cate.name,
            value: cate.name,
            eid: cate?.eid,
          });
        }}
      />
    </Box>
  );
};

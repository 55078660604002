import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { NoteImageEntity } from '../../../../../types';
import { Image } from '../../../../../ui-components';

interface IProps {
  noteImages?: NoteImageEntity[];
}

const NoteImageTag: FC<IProps> = ({ noteImages }) => {
  if (!noteImages?.length) {
    return null;
  }

  return (
    <React.Fragment>
      {noteImages.map((noteImage) => (
        <Flex
          key={noteImage.eid}
          gap='5px'
          p='5px 10px'
          borderRadius='7px'
          align='center'
          justify='flex-start'
          w='fit-content'
          maxW='100%'
          cursor='pointer'
          border='1px solid #EFEFEF'
          onClick={() => window.open(noteImage!.url, '_blank')}
        >
          <Image
            src={noteImage.url}
            width={16}
            height={16}
            style={{
              minWidth: '16px',
              maxWidth: '16px',
              minHeight: '16px',
              maxHeight: '16px',
            }}
          />

          <Text
            m={0}
            p={0}
            color='#000000'
            isTruncated
            textDecoration='underline'
          >
            {noteImage.name}
          </Text>
        </Flex>
      ))}
    </React.Fragment>
  );
};

export default NoteImageTag;

import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IconImage } from '../../../../../../../ui-components';

import { ChapterItem, StatusIcon } from '../common';

interface IProps {
  data: ChapterItem;
}

const ChapterListItem: FC<IProps> = ({ data }) => {
  const { control } = useFormContext();
  const { t } = useTranslation(['common']);

  return (
    <Controller
      name={`taskStepSelection.${data.eid}`}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Flex px={3}>
            <Flex py={3} flex={1} gap={3}>
              {/*<Checkbox*/}
              {/*  size='lg'*/}
              {/*  value={data.eid}*/}
              {/*  isChecked={value?.eid === data.eid}*/}
              {/*  onChange={() => onChange(value ? undefined : data)}*/}
              {/*  onBlur={onBlur}*/}
              {/*/>*/}
              <Flex flex={1} overflow='hidden'>
                <Flex mr='1rem' maxWidth='100%' gap='16px'>
                  <IconImage
                    icon={data.icon}
                    thumbnail={data.thumbnail}
                    name={data.title}
                    boxSize={60}
                    borderRadius='6px'
                  />
                  <Flex flexDir='column' overflow='hidden' justify='center'>
                    <Box fontSize='12px' fontWeight='500' color='#6F767E'>
                      {data?.category}
                    </Box>
                    <Box fontWeight='700' whiteSpace='pre-wrap' noOfLines={1}>
                      {data?.title}
                    </Box>
                    <Flex
                      fontSize='12px'
                      fontWeight='500'
                      color='#6F767E'
                      align='center'
                      gap={1}
                    >
                      <StatusIcon status={data!.visibility} />{' '}
                      <span>
                        {t(
                          data!.visibility === 'private'
                            ? 'common:custom'
                            : `common:${data!.visibility}`
                        )}
                      </span>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              {/*<Flex gap={3} alignItems='center'>*/}
              {/*  {data!.visibility === 'private' && (*/}
              {/*    <Button*/}
              {/*      colorScheme='blue'*/}
              {/*      variant='link'*/}
              {/*      rightIcon={<ExternalLinkIcon />}*/}
              {/*      fontSize='14px'*/}
              {/*      isDisabled*/}
              {/*    >*/}
              {/*      Update visibility*/}
              {/*    </Button>*/}
              {/*  )}*/}
              {/*</Flex>*/}
            </Flex>
          </Flex>
        );
      }}
    />
  );
};

export default ChapterListItem;

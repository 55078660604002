import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import PreviewThumbnailList from './PreviewThumbnailList';
import PreviewComponent from './PreviewComponent';

interface IProps {}

const PreviewContainer: FC<IProps> = () => {
  return (
    <Flex width='full' gap={4} minH='60vh'>
      <Box flex={3} overflow='auto'>
        <PreviewComponent />
      </Box>
      <Box flex={1} maxH='calc(100vh - 7.5rem - 40px)'>
        <PreviewThumbnailList />
      </Box>
    </Flex>
  );
};

export default PreviewContainer;

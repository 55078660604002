import React, { useCallback, useRef } from 'react';

import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { BoxHeader, useConfirm, UseConfirm } from 'ui-components';
import { AlertBox } from 'atoms';

interface IProps {
  // onCancelPress: (...args: any[]) => any | PromiseLike<any>;
  onOkPress: (...args: any[]) => any | PromiseLike<any>;
}

type UseCollapseWarning = (props: IProps) => void;

export const useCollapseWarning = (): UseCollapseWarning => {
  const { t } = useTranslation(['common']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback(({ onOkPress }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      title: <BoxHeader title='Are you sure?' color='#B5E4CA' />,
      content: (
        <AlertBox fontSize='14px' icon={faCircleExclamation}>
          Collapsing the task will not save the changes
        </AlertBox>
      ),
      isCentered: true,
      contentProps: {
        py: '16px',
        px: '12px',
        minW: '600px',
      },
      okButtonProps: {
        // size: 'lg',
        // colorScheme: 'red',
        fontSize: '15px',
        minWidth: '100px',
      },
      cancelButtonProps: {
        // size: 'lg',
        // colorScheme: 'red',
        fontSize: '15px',
        minWidth: '100px',
      },
      // cancelText: t('common:no'),
      // onCancel: onCancelPress,
      // okText: t('common:yes'),
      onOK: onOkPress,
    });
  }, []);
};

import { gql } from '@apollo/client';

export const USER_DETAIL = gql`
  query UserDetail($eid: String!) {
    userById(eid: $eid) {
      profilePic
      phone
      name
      email
      locations {
        name
      }
    }
  }
`;

import { CardDetailModal } from '../../modal';
import { CardDetailEntity } from '../../types';

interface CardPreviewEntity extends CardDetailEntity {
  decks?: Array<{
    title: string;
    eid: string;
  }>;
}

export class CardPreviewModal extends CardDetailModal<CardPreviewEntity> {
  constructor(props: any) {
    super(props);
  }

  get decks() {
    return this.data.decks;
  }
}

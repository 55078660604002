import { useMemo } from 'react';
import { cloneDeep } from '@apollo/client/utilities';
import { useWatch } from 'react-hook-form';
import { IFormInput } from '../task.types';

export const useCanPublishTask = (): boolean => {
  const completed = useWatch<IFormInput, 'completed'>({
    name: 'completed',
  });

  return useMemo(() => {
    const cloned = cloneDeep(completed);

    // @ts-ignore
    delete cloned.supervisors;

    return Object.values(cloned).filter(Boolean).length < 4;
  }, [completed]);
};

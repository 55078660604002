import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { INameDetails } from 'sub-components/LocationLaunchDetail/types/types';

const NameDetails: FC<INameDetails> = (props) => {
  const { locName, startedOnDate } = props;
  return (
    <Flex flexDir='column'>
      <Text
        fontWeight={700}
        fontSize='15px'
        color='#000'
        m={0}
        p={0}
        noOfLines={1}
      >
        {locName}
      </Text>
      <Text
        fontWeight={500}
        fontSize='12px'
        color='#6F767E'
        m={0}
        p={0}
        lineHeight='unset !important'
      >
        {startedOnDate}
      </Text>
    </Flex>
  );
};

export default NameDetails;

import { useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import {
  LocPerformanceEntity,
  MemberVariable,
  PERFORMANCE_LOCATION,
  PerformanceResponse,
} from './location-performance.graphql';

import { toArray } from '../../../../../utils/utils';

interface ReturnedData {
  performanceEntities: LocPerformanceEntity[];
  loading: boolean;
}

export const useLocationOverviewData = (): ReturnedData => {
  const entityId = useReactiveVar(userObj)?.entityId;

  const { loading, data } = useQuery<PerformanceResponse, MemberVariable>(
    PERFORMANCE_LOCATION,
    {
      fetchPolicy: 'network-only',
      skip: !entityId,
      variables: {
        entityId: entityId,
        status: ['active', 'inactive'],
        type: ['branch'],
      },
    }
  );

  const performanceEntities = useMemo(() => {
    const entityUsers = toArray(data?.EntityUser);
    const userAnalytics = data?.TaskSupervisedLocationAnalytics || {};

    return entityUsers
      .reduce<LocPerformanceEntity[]>((acc, value) => {
        const analytic = userAnalytics[value.eid];
        if (analytic) {
          acc.push({
            ...value,
            ...analytic,
            completionRate: Math.round(
              (analytic.complete / analytic.total) * 100
            ),
          });
        }

        return acc;
      }, [])
      .sort((a, b) => b.completionRate - a.completionRate);
  }, [data]);

  return {
    performanceEntities,
    loading,
  };
};

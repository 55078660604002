import React, { FC } from 'react';
import { Box, Grid, Skeleton, SkeletonProps, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const SkeletonInput: FC<SkeletonProps> = (props) => {
  return <Skeleton w='full' h={10} borderRadius='md' {...props} />;
};

interface IProps {
  isLoading?: boolean;
}

const ProfileFormLoading: FC<IProps> = ({ isLoading, children }) => {
  const { t } = useTranslation(['profile']);

  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <>
      <Box>
        <Text fontSize='14px' fontWeight='600' textTransform='uppercase'>
          {t('profile:basicDetails')}
        </Text>
        <Grid templateColumns='repeat(2, 1fr)' gap={4} pt={3}>
          <SkeletonInput />
          <SkeletonInput />
          <SkeletonInput gridColumn='1/3' />

          <SkeletonInput />
          <SkeletonInput />
          <SkeletonInput gridColumn='1/3' />
        </Grid>
      </Box>

      <Box>
        <Text fontSize='14px' fontWeight='600' textTransform='uppercase'>
          {t('profile:accessDetails')}
        </Text>
        <SkeletonInput mt={3} />
      </Box>
    </>
  );
};

export default ProfileFormLoading;

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { CometChat } from '@cometchat-pro/chat';

import { CometChatAvatar, CometChatUserPresence } from '../../Shared';

import { CometChatContext } from '../../../util/CometChatContext';
import { theme } from '../../../resources/theme';
import Translator from '../../../resources/localization/translator';

import {
  listItem,
  itemThumbnailStyle,
  itemDetailStyle,
  itemNameStyle,
  itemDescStyle,
  itemRoleStyle,
} from './style';
import { useContext } from 'react';

const CometChatUserListItem = (props) => {
  const context = useContext(CometChatContext);

  let userPresence = <CometChatUserPresence status={props.user.status} />;

  const toggleTooltip = (event, flag) => {
    const elem = event.target;

    const scrollWidth = elem.scrollWidth;
    const clientWidth = elem.clientWidth;

    if (scrollWidth <= clientWidth) {
      return false;
    }

    if (flag) {
      elem.setAttribute('title', elem.textContent);
    } else {
      elem.removeAttribute('title');
    }
  };

  const metadata = props.user?.metadata;

  return (
    <div
      css={listItem(props, context)}
      onClick={() => props.clickHandler(props.user)}
      className='list__item'
    >
      <div css={itemThumbnailStyle()} className='list__item__thumbnail'>
        <CometChatAvatar user={props.user} />
        {userPresence}
      </div>
      <div
        css={itemDetailStyle()}
        className='list__item__details'
        dir={Translator.getDirection(context.language)}
      >
        <div
          css={itemNameStyle(context)}
          className='item__details__name'
          onMouseEnter={(event) => toggleTooltip(event, true)}
          onMouseLeave={(event) => toggleTooltip(event, false)}
        >
          {props.user.name}
        </div>
        <div css={itemRoleStyle(context)} className='item__details__role_con'>
          <span className='item__details_role' data-role={metadata?.role || ''}>
            {metadata?.role || props.user.role}
          </span>
          {(metadata?.role || props.user.role) && metadata?.parents && (
            <div className='item__detail__role_dot' />
          )}
          <span className='item__details_authrole'>{metadata?.parents}</span>
        </div>
      </div>
    </div>
  );
};

// Specifies the default values for props:
CometChatUserListItem.defaultProps = {
  theme: theme,
  user: {},
};

CometChatUserListItem.propTypes = {
  theme: PropTypes.object,
  user: PropTypes.shape(CometChat.User),
};

export { CometChatUserListItem };

import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Flex, Text } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { match } from 'ts-pattern';
import { ActionType } from 'react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faEye } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { AuthRole, userObj } from 'sop-commons/src/client';

import { TSortBy } from 'types';
import { toArray } from 'utils/utils';
import { MANAGER, WORKER } from 'utils/userRoles';
import { NullShorting } from 'utils/sorting';

import {
  CreatedByUser,
  DateCell,
} from '../../Training/Training/TrainingComponent';

import {
  Column,
  SortingTable,
} from '../../../sub-components/ChakraTable/SortingTable';

import { ChapterListModal, ChapterModal } from '../modal';
import ChapterName from '../ChapterName';
import { ListBaseProps } from '../chapert-view.types';

import { ActionMenu } from 'ui-components/ActionMenu';
import VisibilityBadge from '../VisibilityBadge';

import EmptyChapter from './EmptyChapter';
import ToolTipHeader from '../ToolTipHeader';
import { ChapterItem } from '../chapter.types';
import { ChapterSort } from '../chapters.graphql';
import useCombinedStore from 'zustandStore/store';
import {
  allCheckboxSelect,
  checkBoxCellDisableHandler,
} from '../ChapterContainer/helper';
import { SelectOption } from 'atoms';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

interface IProps extends ListBaseProps {
  checkboxSelectHandler: (
    type: 'single' | 'all',
    selectedValue: boolean,
    selectedId?: string,
    selectedType?: string
  ) => void;
  setDataList: React.Dispatch<React.SetStateAction<ChapterModal[]>>;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
  initialDataList: ChapterItem[];
  selectedItems: { eid: string; type: string }[];
  setInitialDataList: React.Dispatch<React.SetStateAction<ChapterItem[]>>;
  setSelectedUsersCount: React.Dispatch<React.SetStateAction<number>>;
  typeFilter: SelectOption<string> | null;
}

const ChapterListView: FC<IProps> = ({
  dataList,
  onPageChange,
  setHeight,
  loading,
  itemCount,
  perPage,
  currentPage,
  selectedItems,
  typeFilter,
  checkboxSelectHandler,
  onClickedHandler,
  getMenuData,
  onAddChapterClick,
}) => {
  console.log({
    selectedIdsLength: selectedItems?.length,
    dataListLength: dataList?.length,
  });
  const { updateFilterBy, updateSortBy: updateSortByFn } = useCombinedStore();
  const { t, i18n } = useTranslation([
    'common',
    'handbook',
    'card',
    'training',
    'chapter',
  ]);
  const userObject = useReactiveVar(userObj);
  const listRef = useRef<HTMLDivElement | null>(null);
  // const history = useHistory();
  const [allSelectedCheckbox, setAllSelectedCheckbox] = React.useState(false);

  // const onAddChapterClick = () => {
  //   history.push('/create-chapter');
  // };

  const columns = useMemo((): Column<ChapterModal>[] => {
    return [
      {
        accessor: 'eid',
        id: 'checkbox',
        Header: (props) => (
          <Checkbox
            colorScheme='green'
            mr='10px'
            isChecked={
              selectedItems?.length ===
                allCheckboxSelect(dataList, userObject)?.length &&
              allCheckboxSelect(dataList, userObject)?.length > 0
            }
            onChange={(e) => {
              if (e?.target?.checked) {
                deployEvent(
                  AmplitudeEventNames.TURN_ON_BULK_LIST_VIEW_ALL_SELECT
                );
              } else {
                deployEvent(
                  AmplitudeEventNames.TURN_OFF_BULK_LIST_VIEW_ALL_SELECT
                );
              }
              checkboxSelectHandler('all', e?.target?.checked);
            }}
          />
          // <Checkbox
          //   colorScheme='green'
          //   mr={'10px'}
          //   isChecked={allSelectedCheckbox}
          //   onChange={(e) => {
          //     e.stopPropagation();
          //     e.preventDefault();
          //     if (e.target.checked) {
          //       setAllSelectedCheckbox(true);
          //       let _data = cloneDeep(initialDataList);
          //       _data?.forEach((item) => {
          //         if (
          //           userObject?.eid === item?.createdBy ||
          //           userObject?.authRole === AuthRole.SUPER_ADMIN
          //         ) {
          //           item.isSelected = true;
          //         }
          //       });
          //       setInitialDataList(_data);
          //       setDataList(new ChapterListModal(_data).data);
          //       let selectedCount = _data?.filter((item) => {
          //         if (
          //           userObject?.eid === item?.createdBy ||
          //           userObject?.authRole === AuthRole.SUPER_ADMIN
          //         ) {
          //           return item.isSelected === true;
          //         }
          //       })?.length;
          //       setSelectedUsersCount(selectedCount);
          //     } else {
          //       setAllSelectedCheckbox(false);
          //       let _data = cloneDeep(initialDataList);
          //       _data?.forEach((item) => {
          //         item.isSelected = false;
          //       });
          //       setInitialDataList(_data);
          //       setDataList(new ChapterListModal(_data).data);
          //       let selectedCount = _data?.filter((item) => {
          //         if (
          //           userObject?.eid === item?.createdBy ||
          //           userObject?.authRole === AuthRole.SUPER_ADMIN
          //         ) {
          //           return item.isSelected === true;
          //         }
          //       })?.length;
          //       setSelectedUsersCount(selectedCount);
          //     }
          //   }}
          // />
        ),
        width: '6%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <Checkbox
              colorScheme='green'
              isChecked={
                !!selectedItems?.find(
                  (item) => item?.eid === row?.original?.eid
                )
              }
              isDisabled={checkBoxCellDisableHandler(
                userObject,
                row?.original?.createdBy?.eid
              )}
              onChange={(e) => {
                if (e?.target?.checked) {
                  deployEvent(
                    AmplitudeEventNames.TURN_ON_SINGLE_LIST_VIEW_SELECT
                  );
                } else {
                  deployEvent(
                    AmplitudeEventNames.TURN_OFF_SINGLE_LIST_VIEW_SELECT
                  );
                }
                checkboxSelectHandler(
                  'single',
                  e?.target?.checked,
                  row?.original?.eid,
                  row?.original?.type
                );
              }}
            />
            // <Checkbox
            //   colorScheme='green'
            //   isDisabled={checkBoxCellDisableHandler(value, row)}
            //   onChange={() => {
            //     console.log('Chapter List view change : : : : : ');
            //     let _data = cloneDeep(initialDataList);
            //     _data?.forEach((item) => {
            //       if (item?.eid === row?.original?.eid) {
            //         item.isSelected = !item.isSelected;
            //       }
            //     });
            //     console.log('Translated Data List : ', _data);
            //     setInitialDataList(_data);
            //     setDataList(new ChapterListModal(_data).data);
            //     let selectedCount = _data?.filter(
            //       (item) => item.isSelected === true
            //     )?.length;
            //     setSelectedUsersCount(selectedCount);
            //   }}
            //   isChecked={row?.original?.isSelected}
            // />
          );
        },
        disableSortBy: true,
      },
      {
        Header: (props) => (
          <ToolTipHeader {...props}>{t('chapter:name')}</ToolTipHeader>
        ),
        accessor: 'title',
        width: 'calc(39% - 100px)',
        Cell: ({ cell: { value, row } }) => {
          return (
            <ChapterName
              title={value}
              data={row?.original}
              chapterMediaDetails={row?.original?.mediaData?.[0]}
              chapterThumbnail={row?.original?.thumbnail}
              folderName={row.original.category}
              status={row?.original?.status}
              sopType={row?.original?.sopType}
              fileType={row?.original?.fileType}
              icon={row?.original?.icon}
              onClick={() => onClickedHandler?.('open', row.original)}
            />
          );
        },
        sortType: NullShorting,
      },
      {
        Header: (props) => (
          <ToolTipHeader {...props}>
            {t('handbook:table.number_views')}
          </ToolTipHeader>
        ),
        accessor: 'viewCount',
        width: '15%',
        Cell: ({ cell: { value, row } }) => {
          if (row?.original?.type === 'folder') {
            return <Text>-</Text>;
          }
          return (
            <Flex
              mb='25px'
              fontSize='15px'
              fontWeight='600'
              lineHeight='24px'
              textAlign='center'
              width='max-content'
              align='center'
              px='10px'
              color='#1A1D1F'
              gap='6px'
            >
              <FontAwesomeIcon icon={faEye as IconProp} color='#6F767E' />
              {value}
            </Flex>
          );
        },
        sortType: NullShorting,
      },
      {
        Header: (props) => (
          <ToolTipHeader {...props}>{t('training:visibility')}</ToolTipHeader>
        ),
        accessor: 'visibleTo',
        width: '20%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <VisibilityBadge
              visibleData={{
                visibleTo: row?.original?.visibleToDetails,
                visibility: row?.original?.visibility,
                status: row?.original?.status,
              }}
            />
            // <CreatedByUser
            //   status={row?.original?.status}
            //   name={value?.name as string}
            //   profilePic={value?.profilePic as string}
            //   role={value?.role as string}
            // />
          );
        },
        sortType: NullShorting,
      },
      // {
      //   Header: (props) => (
      //     <ToolTipHeader {...props}>{t('common:created_on')}</ToolTipHeader>
      //   ),
      //   accessor: 'createdAt',
      //   width: '13%',
      //   Cell: ({ cell: { value, row } }) => {
      //     return (
      //       <DateCell
      //         date={value}
      //         status={row?.original?.status}
      //         format='DD MMM'
      //       />
      //     );
      //   },
      //   sortType: NullShorting,
      // },
      {
        Header: (props) => (
          <ToolTipHeader {...props}>
            {t('handbook:table.last_updated')}
          </ToolTipHeader>
        ),
        accessor: 'updatedAt',
        width: '13%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <DateCell
              date={value}
              status={row?.original?.status}
              format='DD MMM'
            />
          );
        },
        sortType: NullShorting,
      },
      {
        Header: '',
        accessor: 'eid',
        width: '100px',
        Cell: ({ cell: { value, row } }) => {
          const status = row.original.status;
          return (
            <Box id={value} cursor='pointer' mb='25px'>
              {[WORKER, MANAGER].includes(userObject.authRole) &&
              status === 'inactive' ? (
                <Flex justify='center'>
                  <FontAwesomeIcon
                    icon={faEllipsis as IconProp}
                    size='2x'
                    color='#33383F'
                    style={{
                      opacity: '.5',
                      cursor: 'no-drop',
                    }}
                  />
                </Flex>
              ) : (
                <ActionMenu
                  menuData={getMenuData(row.original)}
                  arrowSize={10}
                  closeOnBlur={true}
                  offset={[5, 5]}
                  clickedItem={(_, clickedItem) => {
                    return onClickedHandler?.(clickedItem, row.original);
                  }}
                >
                  <Flex
                    justify='center'
                    onClick={() =>
                      deployEvent(
                        AmplitudeEventNames.LIST_VIEW_SINGLE_ITEM_ACTION_MENU_CLICK
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faEllipsis as IconProp}
                      size='2x'
                      color='#33383F'
                      style={{
                        opacity: ['active', 'draft', 'valid']?.includes(status)
                          ? '1'
                          : '.5',
                      }}
                    />
                  </Flex>
                </ActionMenu>
              )}
            </Box>
          );
        },
        disableSortBy: true,
      },
    ];
  }, [i18n.language, onClickedHandler]);

  const hiddenColumns = useMemo(() => {
    if (userObject?.type === 'branch') {
      return ['checkbox'];
    }
    if (
      userObject?.authRole === AuthRole.WORKER ||
      userObject?.authRole === AuthRole.LOCATION_OWNER
    ) {
      return ['checkbox'];
    }
    return [];
  }, [userObject]);

  /**
   * onSortByChange={(value) => {
        setFilterBy(null);
        setSortBy(value);
      }}
   */

  const updateSortBy = (values: TSortBy) => {
    const newValue = match<TSortBy, ChapterSort>(values)
      .with({ id: 'title' }, (sel) => (sel.desc ? 'TITLE_ASC' : 'TITLE_DESC'))
      .with({ id: 'viewCount' }, (sel) =>
        sel.desc ? 'VIEWCOUNT_ASC' : 'VIEWCOUNT_DESC'
      )
      .with({ id: 'createdBy' }, (sel) =>
        sel.desc ? 'CREATEDBY_ASC' : 'CREATEDBY_DESC'
      )
      .with({ id: 'createdAt' }, (sel) =>
        sel.desc ? 'CREATEDAT_ASC' : 'CREATEDAT_DESC'
      )
      .with({ id: 'updatedAt' }, (sel) =>
        sel.desc ? 'LASTUPDATED_ASC' : 'LASTUPDATED_DESC'
      )
      .otherwise(() => '_ID_DESC');
    // onSortByChange?.(newValue);
    updateSortByFn(newValue);
    updateFilterBy(null);
  };

  const stateReducer = <T extends unknown>(newState: T, action: ActionType) => {
    switch (action?.type) {
      case 'toggleSortBy':
        // @ts-ignore
        updateSortBy(toArray<TSortBy>(newState?.sortBy)[0]);
        break;
    }

    return newState;
  };

  useEffect(() => {
    if (listRef.current && dataList?.length > 0) {
      setHeight(listRef.current.scrollHeight - 100);
    }
  }, [dataList]);

  return (
    <>
      <Box w='full' h='fit-content' ref={listRef}>
        <SortingTable
          colorScheme='blue'
          customWrapperStyles={{ marginTop: '1rem' }}
          emptyData={{
            content: <EmptyChapter onAddChapterClick={onAddChapterClick} />,
          }}
          page={currentPage || 1}
          onPageChange={onPageChange}
          columns={columns}
          data={dataList}
          isLoading={loading}
          totalRegisters={itemCount}
          registersPerPage={perPage}
          stateReducer={stateReducer}
          isResponsive
          isHidePagination={
            !(currentPage > 1 || (itemCount || 0) > 10) ||
            typeFilter?.value === 'subFolders'
          }
          options={{
            autoResetSortBy: false,
            initialState: {
              hiddenColumns: hiddenColumns,
            },
          }}
        />
      </Box>
    </>
  );
};

export default ChapterListView;

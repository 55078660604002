import React, { FC, useState } from 'react';
import {
  IAllFilterSelection,
  INotRelevantUserDetails,
  ISupervisedListData,
  ISupervisedTasks,
  ISupervisedTasksEntity,
  ITaskListData,
  ITaskListDataEntity,
  ITaskNotRelevantSideDrawerDetails,
  ITaskOverview,
  ITaskSortEntity,
  IWeeklyMonthlySelection,
  TaskRoutes,
} from '../tasks.types';
import TaskDataContext from './task-context';

const TaskDataProvider: FC = (props) => {
  const [selectedRoute, setSelectedRoute] = useState<TaskRoutes>('my-tasks');
  const [taskOverviewData, setTaskOverviewData] = useState<ITaskOverview[]>([]);
  const [supervisedTaskOverviewData, setSupervisedTaskOverviewData] = useState<
    ITaskOverview[]
  >([]);
  const [supervisedTaskDetails, setSupervisedTaskDetails] = useState<
    ISupervisedListData[]
  >([]);
  const [suervisedTaskDetail, setSupervisedTaskDetail] =
    useState<ISupervisedTasksEntity>({} as ISupervisedTasksEntity);
  const [
    taskNotRelevantSideDrawerDetails,
    setTaskNotRelevantSideDrawerDetails,
  ] = useState<ITaskNotRelevantSideDrawerDetails>({
    open: false,
    searchString: '',
    userDetails: [],
    selectedUser: {} as INotRelevantUserDetails,
    selectedTask: {} as ITaskListDataEntity,
  });
  const [myTaskDetails, setMyTaskDetails] = useState<ITaskListData[]>([]);
  const [dashboardData, setDashboardData] = useState({
    performingLocationStats: [],
    jobPerformanceData: {
      sortBy: 'asc' as ITaskSortEntity,
      isExpand: false,
      filterSelection: 'all' as IAllFilterSelection,
      graphData: [],
    },
    locationPerformanceData: {
      sortBy: 'asc' as ITaskSortEntity,
      isExpand: false,
      filterSelection: 'all' as IAllFilterSelection,
      graphData: [],
    },
    performanceOverviewData: {
      sortBy: 'asc' as ITaskSortEntity,
      filterSelection: 'all' as IAllFilterSelection,
      userData: [],
      viewAll: false,
    },
    locationWiseTaskCompletionRate: {
      dropdownLocations: [],
      filterSelection: 'weekly' as IWeeklyMonthlySelection,
      selectedLocations: [],
      graphData: [],
    },
  });

  const selectedRouteHandler = (route: TaskRoutes) => {
    setSelectedRoute(route);
  };

  const taskOverviewDataHandler = (data: ITaskOverview[]) => {
    setTaskOverviewData(data);
  };

  const myTaskDetailsHandler = (data: ITaskListData[]) => {
    setMyTaskDetails(data);
  };

  const dashboardDataHandler = (data: any) => {
    setDashboardData(data);
  };

  const supervisedTaskOverviewDataHandler = (data: ITaskOverview[]) => {
    setSupervisedTaskOverviewData(data);
  };

  const supervisedTaskDetailsHandler = (data: ISupervisedListData[]) => {
    setSupervisedTaskDetails(data);
  };

  const taskSupervisedDetailsHandler = (data: ISupervisedTasksEntity) => {
    setSupervisedTaskDetail(data);
  };

  const taskContext = {
    selectedRoute: selectedRoute,
    myTasks: {
      taskOverviewData: taskOverviewData,
      myTaskDetails: myTaskDetails,
    },
    dashboardData: dashboardData,
    taskNotRelevantSideDrawerDetails: taskNotRelevantSideDrawerDetails,
    supervisedByMe: {
      landing: {
        taskOverviewData: supervisedTaskOverviewData,
        supervisedTaskDetails: supervisedTaskDetails,
      },
      detailsPage: {
        taskDetails: {} as ISupervisedTasksEntity,
      },
    },
    taskOverviewDataHandler: taskOverviewDataHandler,
    selectedRouteHandler: selectedRouteHandler,
    myTaskDetailsHandler: myTaskDetailsHandler,
    setTaskNotRelevantSideDrawerDetails: setTaskNotRelevantSideDrawerDetails,
    dashboardDataHandler: dashboardDataHandler,
    supervisedTaskDetailsHandler: supervisedTaskDetailsHandler,
    supervisedTaskOverviewDataHandler: supervisedTaskOverviewDataHandler,
    taskSupervisedDetailsHandler: taskSupervisedDetailsHandler,
  };

  return (
    <TaskDataContext.Provider value={taskContext}>
      {props.children}
    </TaskDataContext.Provider>
  );
};

export default TaskDataProvider;

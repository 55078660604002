import { v4 as uuidv4 } from 'uuid';
import { toArray } from '../../../../utils';

import { LauncherConfig, LauncherTaskStep } from '../common';

import { TemplateContent } from '../board-view/template.graphql'; // TODO

export const stepsMapWithId = (
  steps?: LauncherTaskStep[]
): LauncherTaskStep[] => {
  return toArray(steps).map((value) => {
    return {
      stepId: uuidv4(),
      title: value.title,
      type: value.type,
      formId: value.formId || undefined,
      sopId: value.sopId || undefined,
      trainingId: value.trainingId || undefined,
    };
  });
};

export const transformTemplate = (
  contents: Array<TemplateContent> = [],
  title: string = ''
): LauncherConfig => {
  return {
    eid: undefined!,
    published: undefined!,
    title: title,
    contents: contents.map((content) => ({
      eid: uuidv4(),
      category: content.category,
      selected: true,
      tasks: content.tasks.map((task) => ({
        selected: true,
        eid: undefined!,
        tempEid: uuidv4(),
        duration: task.duration,
        completed: !!(task.title?.trim() && task.dependency && task.duration),
        dependency: task.dependency,
        docRequired: task.docRequired,
        title: task.title,
        description: task.description,
        steps: stepsMapWithId(task.steps),
      })),
    })),
  };
};

import styled from '@emotion/styled';

export const StepperWrapper = styled.div`
  padding-top: 16px;

  .ant-steps {
    .ant-steps-item {
      .ant-steps-item-icon {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        line-height: 38px;
        margin: 0;
        font-family: Inter, sans-serif;

        border-width: 1px;
        border-style: solid;

        .ant-steps-icon {
          color: white;
          font-size: 15px;
          font-weight: 600;
          user-select: none;
        }
      }
    }

    .ant-steps-item-tail {
      top: 19px;
      margin-left: 20px;
      padding: 0 20px;

      :after {
        height: 2px;
      }
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background: transparent;
        border-color: #7eacfc;

        .ant-steps-icon {
          color: #7eacfc;
        }
      }

      .ant-steps-item-tail:after {
        background-color: #7eacfc;
      }
    }

    .ant-steps-item-wait {
      .ant-steps-item-icon {
        background: transparent;
        border-color: #7eacfc;

        .ant-steps-icon {
          color: #7eacfc;
        }
      }

      .ant-steps-item-content {
        .ant-steps-item-title {
          color: #ffffff;
          font-weight: 600;
        }
      }

      .ant-steps-item-tail:after {
        background-color: #7eacfc;
      }
    }

    .ant-steps-item-process {
      .ant-steps-item-icon {
        background: transparent;
        border-color: #ffffff;
      }

      .ant-steps-item-tail:after {
        background-color: #7eacfc;
      }
    }

    .add-section {
      align-self: flex-start;
      .ant-steps-item-content {
        width: unset;
        margin-top: unset;
      }
    }
  }

  .ant-steps-label-vertical {
    .ant-steps-item {
      max-width: 250px; // TODO
    }

    .ant-steps-item-icon {
      margin-left: 0;
    }

    .ant-steps-item-content {
      text-align: unset;

      .ant-steps-item-title {
        font-size: 15px;
        font-weight: 600;
        font-family: Inter, sans-serif;
        color: #111315;
      }
    }
  }

  .ant-steps-vertical {
    .ant-steps-item {
      .ant-steps-item-container > .ant-steps-item-tail {
        padding: 40px 0 0;
        margin-left: 19px;
        left: 0;

        ::after {
          width: 2px;
        }
      }

      .ant-steps-item-content {
        padding-left: 20px;
        padding-bottom: 16px;
        min-height: 76px;

        .ant-steps-item-title {
          color: #7eacfc;
          width: 100%;
          line-height: 40px;
          padding: initial;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .ant-steps-item-active {
      .ant-steps-item-content {
        .ant-steps-item-title {
          color: #ffffff;
          font-weight: 600;
        }
      }
    }
  }
`;

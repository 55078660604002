import React, { FC } from 'react';
import Layout from 'atoms/Layout';
import { MixedLayout } from 'sop-commons/src/utils/cardLayouts';
import LayoutSelect from '../../../ui-components/LayoutSelect/LayoutSelect';

import { ReactComponent as SingleImageLayoutIcon } from '../../../assets/images/singleImage.svg';
import { ReactComponent as VerticalSplitImageLayoutIcon } from '../../../assets/images/verticalSplitImage.svg';
import { ReactComponent as VerticalSecondRowSplitImageLayoutIcon } from '../../../assets/images/verticalSecondRowSplitImage.svg';
import { ReactComponent as VerticalFirstRowSplitImageLayoutIcon } from '../../../assets/images/verticalFirstRowSplitImage.svg';
import { ReactComponent as HorizontalSplitImageLayoutIcon } from '../../../assets/images/horizontalSplitImage.svg';
import { ReactComponent as HorizontalSecondColumnSplitImageLayoutIcon } from '../../../assets/images/horizontalSecondColumnSplitImage.svg';
import { ReactComponent as HorizontalFirstColumnSplitImageLayoutIcon } from '../../../assets/images/horizontalFirstColumnSplitImage.svg';
import { ReactComponent as QuadrantImageLayoutIcon } from '../../../assets/images/quadrantImage.svg';
import { useLayoutDisabled } from '../hook';

interface IProps {
  layout: MixedLayout;
  onSelectLayout: (layout: MixedLayout) => void;
}

//
// setCardData({
//   ...cardJsonFormat(
//     cardData?.cardType,
//     layout,
//     entityId,
//     null,
//     null,
//     cardData?.title,
//     cardData?.status
//   ),
// });

const ImageLayoutSelect: FC<IProps> = ({ layout, onSelectLayout }) => {
  const isDisabled = useLayoutDisabled();

  return (
    <LayoutSelect isDisabled={isDisabled}>
      <Layout
        isSelected={layout === MixedLayout.IMAGE_SINGLE}
        svgIcon={<SingleImageLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.IMAGE_SINGLE);
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.IMAGE_VERTICAL_SPLIT}
        svgIcon={<VerticalSplitImageLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.IMAGE_VERTICAL_SPLIT);
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.IMAGE_HORIZONTAL_SPLIT}
        svgIcon={<HorizontalSplitImageLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.IMAGE_HORIZONTAL_SPLIT);
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.IMAGE_HORIZONTAL_SPLIT_SECOND_COLUMN}
        svgIcon={<HorizontalSecondColumnSplitImageLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.IMAGE_HORIZONTAL_SPLIT_SECOND_COLUMN);
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.IMAGE_HORIZONTAL_SPLIT_FIRST_COLUMN}
        svgIcon={<HorizontalFirstColumnSplitImageLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.IMAGE_HORIZONTAL_SPLIT_FIRST_COLUMN);
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.IMAGE_VERTICAL_SPLIT_FIRST_ROW}
        svgIcon={<VerticalFirstRowSplitImageLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.IMAGE_VERTICAL_SPLIT_FIRST_ROW);
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.IMAGE_VERTICAL_SPLIT_SECOND_ROW}
        svgIcon={<VerticalSecondRowSplitImageLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.IMAGE_VERTICAL_SPLIT_SECOND_ROW);
        }}
      />
      <Layout
        isSelected={layout === MixedLayout.IMAGE_QUADRANT}
        svgIcon={<QuadrantImageLayoutIcon />}
        onSelect={() => {
          onSelectLayout(MixedLayout.IMAGE_QUADRANT);
        }}
      />
    </LayoutSelect>
  );
};

export default ImageLayoutSelect;

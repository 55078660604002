import { BaseCardModal, CardDetailModal, CardModal } from '../../../modal';
import { CardDetailEntity, DeckEntity } from '../../../types';
import { toArray } from '../../../utils/utils';

export class UpdateDeckCardModal extends CardDetailModal {
  private _added: boolean;
  constructor(props: CardDetailEntity) {
    super(props);
    this._added = false;
  }

  get added(): boolean {
    return this._added;
  }

  set added(value: boolean) {
    this._added = value;
  }
}

export class UpdateDeckModal extends BaseCardModal {
  private _added: boolean;

  protected _cards: CardModal[] = [];
  constructor(data: DeckEntity) {
    super(data);
    this._added = false;
    this._cards = toArray(data.cards).map(
      (card) => new CardDetailModal(card as any)
    );
  }

  get cards() {
    return this._cards;
  }

  get cardLength() {
    return this._cards.length;
  }

  get added(): boolean {
    return this._added;
  }

  set added(value: boolean) {
    this._added = value;
  }
}

export class AddDeckCardModal {
  protected _data: Array<UpdateDeckModal | UpdateDeckCardModal> = [];
  constructor(data: any[] = []) {
    this._data = data.map((value) => {
      if (value.type === 'deck') {
        return new UpdateDeckModal(value as DeckEntity);
      }
      return new UpdateDeckCardModal(value as CardDetailEntity);
    });
  }

  get data() {
    return this._data;
  }

  get length() {
    return this._data.length;
  }
}

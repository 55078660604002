import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import CommonContainer from 'ui-components/CommonContainer';

export type IPhaseCard = { phaseName: string; locCount: number };

const PhaseCard: FC<IPhaseCard> = (props) => {
  const { phaseName, locCount } = props;
  return (
    <CommonContainer
      bg='white'
      display='flex'
      gap={2}
      borderRadius='8px'
      p='20px'
      w='184px'
      flexDir='column'
    >
      <Text fontWeight={500} fontSize='14px' color='#6F767E'>
        {phaseName}
      </Text>
      <Text fontWeight={700} fontSize='20px'>
        {locCount} {locCount === 1 ? 'location' : 'locations'}
      </Text>
    </CommonContainer>
  );
};

export default PhaseCard;

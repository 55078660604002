import { CardModal, DeckModal } from 'modal';
import { ChapterModal } from 'modal/chapter-modal';
import { HandbookResponse } from 'pages/Handbook/handbook.graphql';
import { IPowerUpModalTypes } from 'pages/Training/PowerUpModal/power-up.types';
import React, { ReactNode } from 'react';
import { BaseCardEntity, CardEntity, PageInfo } from 'types';
import { IIconList } from '../../../../../sub-components/TrainingPathIconsModal';
import { HandbookData } from '../../../../Handbook/handbook.types';
import {
  IAssignEntity,
  ITrainingCategoryEntity,
  ITrainingType,
} from '../../training-path-types';

export interface ITrainingHandbook {
  handbookPagination?: {
    count?: number;
    items?: (DeckModal | CardModal<CardEntity>)[];
    pageInfo?: PageInfo;
  };
}

export interface IChapter {
  SopPagination?: {
    count?: number;
    items?: CardModal[];
    pageInfo?: PageInfo;
  };
}

export interface ITrainingPathContext {
  trainingPathType: ITrainingType;
  isCreate: boolean;
  stepCount: 1 | 2;
  isCounterMovedFromFirstStep: boolean;
  trainingPathName: string;
  trainingPathIcon: IIconList;
  trainingPathIconsList: IIconList[];
  addedItemsCount: number;
  decksCount: number;
  cardsCount: number;
  selectedCard: any;
  selectedDeck: any;
  addedItems: HandbookData[];
  shouldMoveToNextStep: boolean;
  isCardCheckboxSelected: boolean;
  isDeckCheckboxSelected: boolean;
  searchedHandbookString: string;
  handbookCategoryList: ITrainingCategoryEntity[];
  selectedHandbookFilterOption: string | undefined;
  selectedHandbookItem: HandbookData | undefined;
  handbookItemsList: ITrainingHandbook;
  chapterItemsList: IChapter;
  handbookListLoading: boolean;
  chapterListLoading: boolean;
  shouldShowHiddenItems: boolean;
  selectedRoles: string[];
  selectedSupervisors: string[];
  selectedMembers: string[];
  selectedLocations: string[];
  rolesList: IAssignEntity[];
  supervisorsList: IAssignEntity[];
  locationsList: IAssignEntity[];
  membersList: IAssignEntity[];
  selectedPowerupType: IPowerUpModalTypes['types'];
  returnedData: Object;
  setTrainingPathTypeHandler: (type: 'add' | 'edit') => void;
  setIsCreateHandler: (value: boolean) => void;
  setStepCountHandler: (value: 1 | 2) => void;
  setIsCounterMovedFromFirstStepHandler: (value: boolean) => void;
  setTrainingPathNameHandler: (name: string) => void;
  setTrainingPathIconHandler: (icon: IIconList) => void;
  setTrainingPathIconsListHandler: (icons: IIconList[]) => void;
  setCountsHandler: () => void;
  setSelectedCardHandler: (card: any) => void;
  setSelectedDeckHandler: (deck: any) => void;
  setAddedItemsHandler: (items: HandbookData[]) => void;
  setShouldMoveToNextStep: (value: boolean) => void;
  setIsCardCheckboxSelectedHandler: (value: boolean) => void;
  setIsDeckCheckboxSelectedHandler: (value: boolean) => void;
  setSearchedHandbookStringHandler: (value: string) => void;
  setHandbookCategoryListHandler: (items: ITrainingCategoryEntity[]) => void;
  setSelectedHandbookFilterOptionHandler: (id: string | undefined) => void;
  setSelectedHandbookItemHandler: (item: HandbookData) => void;
  setHandbookItemsListHandler: (items: ITrainingHandbook) => void;
  setChapterItemsListHandler: (items: IChapter) => void;
  setHandbookListLoadingHandler: (value: boolean) => void;
  setChapterListLoadingHandler: (value: boolean) => void;
  setShouldShowHiddenItemsHandler: (value: boolean) => void;
  setSelectedRolesHandler: (items: string[]) => void;
  setSelectedSupervisorsHandler: (item: string[]) => void;
  setSelectedMembersHandler: (item: string[]) => void;
  setSelectedLocationsHandler: (item: string[]) => void;
  setRolesListHandler: (items: IAssignEntity[]) => void;
  setSupervisorsListHandler: (items: IAssignEntity[]) => void;
  setLocationsListHandler: (items: IAssignEntity[]) => void;
  setMembersListHandler: (items: IAssignEntity[]) => void;
  setSelectedPowerupTypeHandler: (type: IPowerUpModalTypes['types']) => void;
  setReturnedDataHandler: (data: Object) => void;
}

const TrainingPathContext = React.createContext<ITrainingPathContext>({
  trainingPathType: 'add',
  isCreate: true,
  stepCount: 1,
  isCounterMovedFromFirstStep: false,
  trainingPathName: '',
  trainingPathIcon: {
    createdAt: '',
    description: '',
    backgroundColor: '',
    eid: '',
    label: '',
    tags: [],
    type: '',
    updatedAt: '',
    url: '',
  },
  trainingPathIconsList: [],
  addedItemsCount: 0,
  shouldMoveToNextStep: false,
  decksCount: 0,
  cardsCount: 0,
  selectedCard: null,
  selectedDeck: null,
  addedItems: [],
  isCardCheckboxSelected: false,
  isDeckCheckboxSelected: false,
  searchedHandbookString: '',
  handbookCategoryList: [],
  selectedHandbookFilterOption: undefined,
  selectedHandbookItem: undefined,
  handbookItemsList: {
    handbookPagination: {
      count: 0,
      items: [],
      pageInfo: {
        currentPage: 0,
        itemCount: 0,
        pageCount: 0,
        perPage: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      },
    },
  },
  chapterItemsList: {
    SopPagination: {
      count: 0,
      items: [],
      pageInfo: {
        currentPage: 0,
        itemCount: 0,
        pageCount: 0,
        perPage: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      },
    },
  },
  handbookListLoading: false,
  chapterListLoading: false,
  shouldShowHiddenItems: false,
  selectedRoles: [],
  selectedSupervisors: [],
  selectedMembers: [],
  selectedLocations: [],
  rolesList: [],
  supervisorsList: [],
  locationsList: [],
  membersList: [],
  selectedPowerupType: '',
  returnedData: {},
  setTrainingPathTypeHandler: (type: 'add' | 'edit') => {},
  setIsCreateHandler: (value: boolean) => {},
  setStepCountHandler: (value: 1 | 2) => {},
  setIsCounterMovedFromFirstStepHandler: (value: boolean) => {},
  setTrainingPathNameHandler: (name: string) => {},
  setTrainingPathIconHandler: (icon: IIconList) => {},
  setTrainingPathIconsListHandler: (icons: IIconList[]) => {},
  setCountsHandler: () => {},
  setSelectedCardHandler: (card: any) => {},
  setSelectedDeckHandler: (deck: any) => {},
  setShouldMoveToNextStep: (value: boolean) => {},
  setAddedItemsHandler: (items: HandbookData[]) => {},
  setIsCardCheckboxSelectedHandler: (value: boolean) => {},
  setIsDeckCheckboxSelectedHandler: (value: boolean) => {},
  setSearchedHandbookStringHandler: (value: string) => {},
  setHandbookCategoryListHandler: (items: ITrainingCategoryEntity[]) => {},
  setSelectedHandbookFilterOptionHandler: (id: string | undefined) => {},
  setSelectedHandbookItemHandler: (item: HandbookData) => {},
  setHandbookItemsListHandler: (items: ITrainingHandbook) => {},
  setChapterItemsListHandler: (items: IChapter) => {},
  setHandbookListLoadingHandler: (value: boolean) => {},
  setChapterListLoadingHandler: (value: boolean) => {},
  setShouldShowHiddenItemsHandler: (value: boolean) => {},
  setSelectedRolesHandler: (items: string[]) => {},
  setSelectedSupervisorsHandler: (items: string[]) => {},
  setSelectedMembersHandler: (items: string[]) => {},
  setSelectedLocationsHandler: (items: string[]) => {},
  setRolesListHandler: (items: IAssignEntity[]) => {},
  setSupervisorsListHandler: (items: IAssignEntity[]) => {},
  setLocationsListHandler: (items: IAssignEntity[]) => {},
  setMembersListHandler: (items: IAssignEntity[]) => {},
  setSelectedPowerupTypeHandler: (type: IPowerUpModalTypes['types']) => {},
  setReturnedDataHandler: (data: Object) => {},
});

export default TrainingPathContext as React.Context<ITrainingPathContext>;

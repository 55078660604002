import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import {
  Controller,
  ControllerProps,
  FieldError,
  FieldPath,
  useFormContext,
} from 'react-hook-form';
import PickerButton, { PickerButtonProps } from './PickerButton';
import CustomHeader from './CustomHeader';
import { IFormInput } from '../../task.types';
import { PickerWrapper } from './picker.styles';

interface VProps {
  hasFormControl?: boolean;
  error?: FieldError;
}

const ValidationWrapper: FC<VProps> = ({ error, hasFormControl, children }) => {
  if (!hasFormControl) {
    return <>{children}</>;
  }

  return (
    <FormControl isInvalid={!!error}>
      {children}
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};

type IDatePickerComponentProps = {
  readOnly?: boolean;
  selectedValue: Date | null;
  onChange: (date: Date | null) => void;
  onSelect?: (date: Date | null) => void;
  isSelected?: boolean;
  transKey?: 'task:customDateValue';
  placeholderText: string;
  dateFormat?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  customInput?: React.ReactElement;
};

export const DatePickerComponent: FC<IDatePickerComponentProps> = ({
  readOnly,
  selectedValue,
  onChange,
  onSelect,
  isSelected,
  transKey,
  placeholderText,
  dateFormat,
  minDate,
  maxDate,
  customInput,
}) => {
  const effectiveCustomInput = customInput ?? (
    <PickerButton
      isSelected={isSelected && !!selectedValue}
      transKey={transKey}
    />
  );

  return (
    <PickerWrapper>
      <DatePicker
        readOnly={readOnly}
        selected={selectedValue}
        onChange={onChange}
        onSelect={onSelect}
        customInput={effectiveCustomInput}
        renderCustomHeader={CustomHeader}
        formatWeekDay={(day) => day.substring(0, 3)}
        calendarStartDay={1}
        placeholderText={placeholderText}
        dateFormat={dateFormat || 'd MMMM yyyy'}
        minDate={minDate}
        maxDate={maxDate}
        disabledKeyboardNavigation // if disable it, then the current date is being shown for all the months and where last month and current month dates are in same page and date is selected, then both dates are highlighted
      />
    </PickerWrapper>
  );
};

interface IProps
  extends Pick<PickerButtonProps, 'transKey' | 'readOnly'>,
    Pick<ControllerProps, 'rules'> {
  isSelected?: boolean;
  placeholderText: string;
  onChange?: (date: Date | null) => void;
  name: FieldPath<IFormInput>;
  minDate?: Date | null;
  maxDate?: Date | null;
  showError?: boolean;
  dateFormat?: string;
}

const CustomDatePicker: FC<IProps> = ({
  readOnly,
  name,
  placeholderText,
  isSelected,
  onChange,
  transKey,
  rules,
  minDate,
  maxDate,
  showError,
  dateFormat,
}) => {
  const { control } = useFormContext<IFormInput>();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <ValidationWrapper
            hasFormControl={showError}
            error={fieldState?.error}
          >
            {/* <PickerWrapper>
              <DatePicker
                readOnly={readOnly}
                selected={field.value as never}
                onChange={(date) => field.onChange(date)}
                onSelect={(date) => onChange?.(date)}
                customInput={
                  <PickerButton
                    isSelected={isSelected && !!field.value}
                    transKey={transKey}
                  />
                }
                renderCustomHeader={CustomHeader}
                formatWeekDay={(day) => day.substring(0, 3)}
                calendarStartDay={1}
                placeholderText={placeholderText}
                dateFormat={dateFormat || 'd MMMM yyyy'}
                minDate={minDate}
                maxDate={maxDate}
              />
            </PickerWrapper> */}
            <DatePickerComponent
              readOnly={readOnly}
              selectedValue={field.value}
              onChange={(date) => field.onChange(date)}
              onSelect={(date) => onChange?.(date)}
              isSelected={isSelected}
              transKey={transKey}
              placeholderText={placeholderText}
              dateFormat={dateFormat}
              minDate={minDate}
              maxDate={maxDate}
            />
          </ValidationWrapper>
        );
      }}
    />
  );
};

export default CustomDatePicker;

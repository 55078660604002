import { IAmplitudeEventNamesEntity } from './amplitude-events-types';
import amplitude from 'amplitude-js';
import mixpanel from 'mixpanel-browser';
import { userObj } from 'sop-commons/src/client';
import { CommonEventType } from 'sop-commons/Events/CommonEvents';

// Define the structure for structured properties with an index signature
interface StructuredProperties {
  super?: Record<string, any>;
  event?: Record<string, any>;
  profile?: Record<string, any>;
  [key: string]: any;
}

// Flat properties can be any key-value pairs
type FlatProperties = Record<string, any>;

// Union type for event properties
type EventProperties = StructuredProperties | FlatProperties;

/**
 * Type guard to determine if the properties object is structured.
 * @param props - The properties object to check.
 * @returns True if the object has at least one of 'super', 'event', or 'profile' keys.
 */
const isStructuredProperties = (
  props: Record<string, any>
): props is StructuredProperties => {
  return (
    props.hasOwnProperty('super') ||
    props.hasOwnProperty('event') ||
    props.hasOwnProperty('profile')
  );
};

const getEventInfo = (
  event: IAmplitudeEventNamesEntity | CommonEventType,
  params?: EventProperties
) => {
  if (typeof event === 'object') {
    return {
      eventName: event.value,
      eventProperties: { ...event.additional_info, ...params },
    };
  }
  return {
    eventName: event,
    eventProperties: params,
  };
};

export const deployEvent = (
  event: IAmplitudeEventNamesEntity | CommonEventType,
  properties?: EventProperties
): void => {
  const loggedInUserDetails = userObj();

  const { eventName, eventProperties } = getEventInfo(event, properties);

  if (
    process?.env?.REACT_APP_DEPLOYMENT_TYPE === 'production' &&
    loggedInUserDetails?.entity?.analytics
  ) {
    // Amplitude Logging
    if (sessionStorage?.getItem('amplitudeInit') === '1') {
      let amplitudeProperties: Record<string, any> = {};
      if (eventProperties && isStructuredProperties(eventProperties)) {
        const {
          super: superProps,
          event: eventProps,
          profile: profileProps,
          ...otherProps
        } = eventProperties;

        // Merge super, event, and profile into a single flat object for Amplitude
        amplitudeProperties = {
          ...superProps,
          ...eventProps,
          ...profileProps,
          ...otherProps,
        };
      } else {
        // If properties are flat, use them directly
        amplitudeProperties = { ...eventProperties };
      }

      amplitude.getInstance().logEvent(eventName, amplitudeProperties);
    }

    // Mixpanel Logging
    if (sessionStorage?.getItem('mixPanelInit') === '1') {
      if (eventProperties && isStructuredProperties(eventProperties)) {
        const {
          super: superProps,
          event: eventProps,
          profile: profileProps,
        } = eventProperties;

        // Handle 'super' properties
        if (superProps && typeof mixpanel.register === 'function') {
          mixpanel.register(superProps);
        }

        // Handle 'event' properties
        if (eventProps) {
          mixpanel.track(eventName, eventProps);
        }

        // Handle 'profile' properties
        if (profileProps && typeof mixpanel.people.set === 'function') {
          mixpanel.people.set(profileProps);
        }
      } else {
        // Standard mixpanel.track with flat properties
        mixpanel.track(eventName, eventProperties);
      }
    }
  } else {
    // Development or Non-production Logging
    console.log({
      dep_type: process?.env?.REACT_APP_DEPLOYMENT_TYPE,
      node_env: process?.env?.NODE_ENV,
      eventName,
      REACT_APP_DEPLOYMENT_TYPE: process?.env?.REACT_APP_DEPLOYMENT_TYPE,
      NODE_ENV: process?.env?.NODE_ENV,
    });

    if (process?.env?.REACT_APP_DEPLOYMENT_TYPE === 'development') {
      console.log('deployEvent --> eventName = ', eventName, eventProperties);
    }
  }
};

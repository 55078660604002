import React, { useCallback, useRef } from 'react';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { BoxHeader, useConfirm, UseConfirm } from '../../../../ui-components';
import { AlertBox } from '../../../../atoms';

interface IProps {
  category: string;
  onDeletePress: (...args: any[]) => any | PromiseLike<any>;
}

type UsePhaseDelete = (props: IProps) => void;

export const usePhaseDelete = (): UsePhaseDelete => {
  const { t } = useTranslation(['common']);
  const confirmRef = useRef<ReturnType<UseConfirm>>();
  const confirm = useConfirm();

  return useCallback(({ category, onDeletePress }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex justify='space-between' align='center'>
          <BoxHeader title='Delete this phase' color='#B5E4CA' />

          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: (
        <AlertBox fontSize='14px' icon={faTrashCan}>
          Deleting the {`"${category}"`} phase will remove all tasks associated
          with it from the launcher and will impact the creation of future
          locations. However, ongoing tasks in existing locations will remain
          unaffected.
        </AlertBox>
      ),
      isCentered: true,
      contentProps: {
        py: '16px',
        px: '12px',
        minW: '600px',
      },
      okButtonProps: {
        size: 'lg',
        colorScheme: 'red',
        fontSize: '15px',
        minWidth: '150px',
      },
      cancelText: null,
      okText: t('common:delete'),
      onOK: onDeletePress,
    });
  }, []);
};

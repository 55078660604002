import { gql } from '@apollo/client';

export interface LocationLiveResp {
  MakeLauncherLocationLive: {
    succeed: boolean;
  };
}

export const MAKE_LOCATION_LIVE = gql`
  mutation MakeLauncherLocationLive($locationId: String!) {
    MakeLauncherLocationLive(locationId: $locationId) {
      succeed
    }
  }
`;

import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';

import TitleHeader from '../../../CardEditor/TitleHeader';
import { IFormInput } from '../task.types';
import CheckButton from './CheckButton';
import { CustomDatePicker } from './CustomDatePicker';
import { DayInputField } from './Components';
import { endDateValidate } from '../validation/endConditionValidate';

interface IProps {}

const EndDate: FC<IProps> = () => {
  const { t } = useTranslation(['task']);
  const { control, setValue, getValues } = useFormContext<IFormInput>();

  const resetField = (value: IFormInput['endCondition']['endType']) => {
    switch (value) {
      case 'never':
        setValue('endCondition.repeatCount', undefined);
        setValue('endCondition.endDate', undefined);
        break;
      case 'repeat':
        setValue('endCondition.endDate', undefined);
        break;
      case 'date':
        setValue('endCondition.repeatCount', undefined);
        break;
    }
  };

  return (
    <div>
      <TitleHeader title={t('task:endDate')} desc={t('task:endDateDesc')} />

      <Controller
        control={control}
        name='endCondition'
        rules={{
          required: t('task:validation.endTypeRequired'),
        }} // TODO Don't delete this controller
        render={() => <></>}
      />

      <Controller
        control={control}
        name='endCondition.endType'
        rules={{
          required: t('task:validation.endTypeRequired'),
        }}
        render={({ field, fieldState, formState: { errors } }) => {
          const error = fieldState.error || errors?.endCondition?.endDate;

          return (
            <FormControl my={3} isInvalid={!!error || !!errors?.endCondition}>
              <Flex gap={2}>
                <CheckButton
                  isSelected={field.value === 'never'}
                  onClick={() => callAll(field.onChange, resetField)('never')}
                >
                  <Trans t={t} i18nKey='task:never' />
                </CheckButton>

                <CheckButton
                  isSelected={field.value === 'repeat'}
                  onClick={() => callAll(field.onChange, resetField)('repeat')}
                >
                  <Trans t={t} i18nKey='task:repeatXTimes' />
                </CheckButton>

                <CustomDatePicker
                  name='endCondition.endDate'
                  isSelected={field.value === 'date'}
                  placeholderText={t('task:customChooseCal')}
                  transKey='task:customDateValue'
                  onChange={() => callAll(field.onChange, resetField)('date')}
                  minDate={new Date()}
                  rules={{
                    validate: (value) => endDateValidate(value!, getValues()),
                    deps: ['endCondition.endType'],
                  }}
                />
              </Flex>

              <FormErrorMessage>{error?.message}</FormErrorMessage>

              <DayInputField
                name='endCondition.repeatCount'
                visible={field.value === 'repeat'}
                suffixText={t('task:times')}
                placeholder={t('task:placeholder.numberOfTimes')}
                minValue={1}
              />
            </FormControl>
          );
        }}
      />
    </div>
  );
};

export default EndDate;

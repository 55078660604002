import { Center } from '@chakra-ui/react';
import React, { FC } from 'react';
import {
  useTable,
  useBlockLayout,
  Column as NativeColumn,
  UseSortByColumnOptions,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import EmptyState from 'sub-components/EmptyState';
import { FormResponseTableWrapper } from './FormResponseTable.styles';
// import FormResponseTableWrapper from './FormResponseTable.styles';

export type Column<D extends object = {}> = NativeColumn<D> &
  UseSortByColumnOptions<D>;

interface FormTableProps<T extends object = {}> {
  columns: Column<T>[];
  data: T[];
}

const FormResponseTableComponent: FC<FormTableProps> = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useBlockLayout,
      useSticky
    );

  return (
    <FormResponseTableWrapper>
      <div {...getTableProps()} className='table sticky'>
        <div className='header'>
          {headerGroups.map((headerGroup, indexI) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className='tr'
              key={indexI}
            >
              {headerGroup.headers.map((column, indexII) => (
                <div {...column.getHeaderProps()} className='th' key={indexII}>
                  {column.render('Header')}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()} className='body'>
          {rows?.length > 0 ? (
            rows.map((row, indexIII) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className='tr' key={indexIII}>
                  {row.cells.map((cell, indexIV) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className='td'
                        key={indexIV}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <Center>
              <EmptyState image='Search' title='No data found' />
            </Center>
          )}
        </div>
      </div>
    </FormResponseTableWrapper>
  );
};

export default FormResponseTableComponent;

import React, { FC, Fragment } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';

interface IProps {}

const ActivitySkeleton: FC<IProps> = () => {
  return (
    <Fragment>
      {Array.from({ length: 3 }).map((_, index) => {
        return (
          <Box py='20px' key={index}>
            <Skeleton height='16px' borderRadius='full' mb={2} />
            <Skeleton height='16px' borderRadius='full' />
          </Box>
        );
      })}
    </Fragment>
  );
};

export default ActivitySkeleton;

import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import GenericColorHeader from '../../../sub-components/GenericColorHeader';
import { ReactComponent as ProfileCloudUpload } from '../../../assets/images/profile-cloud-upload.svg';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import PrimaryButton from 'atoms/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFeatherPointed } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PdfImg from '../../../assets/images/pdf-img.png';
import { gql, useMutation, useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';
import axios from 'axios';

export const GET_SIGNED_URL = gql`
  mutation GetSignedUrlMutation($getSignedUrlInput: [FileDetailsInput]) {
    getSignedUrl(input: $getSignedUrlInput) {
      signedRequestUrl
      url
      mimetype
      s3_filename
      filename
    }
  }
`;

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateChapterModal: FC<IProps> = ({ isOpen, onClose }) => {
  const history = useHistory();
  const { t } = useTranslation(['common', 'sidebar', 'chapter']);
  const category = useRouteMatch<{ category?: string }>().params?.category;
  const [file, setFile] = useState<File | null>(null);
  const userData = useReactiveVar(userObj);
  const [fileUrlLoading, setFileUrlLoading] = useState(false);
  const [chapterTitle, setChapterTitle] = useState('');
  const [fileUrl, setFileUrl] = useState('');

  const [getFileUrl] = useMutation(GET_SIGNED_URL);

  const handleDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setFile(file);
  };

  const openFileDialog = () => {
    open();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleDrop,
    accept: { 'file/*': ['.pdf'] },
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  const getSize = (size: number) => {
    const units = ['bytes', 'KB', 'MB'];
    const niceBytes = (x: string) => {
      let l = 0,
        n = parseInt(x, 10) || 0;
      while (n >= 1000 && ++l) {
        n = n / 1000;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
    };
    return niceBytes(size + '');
  };

  const getFileUrlHandler = async () => {
    setFileUrlLoading(true);
    const response = await getFileUrl({
      variables: {
        getSignedUrlInput: [{ filename: file!.name, mimetype: file!.type }],
      },
    });
    if (response) {
      if (
        response &&
        response.data &&
        response.data.getSignedUrl &&
        response.data.getSignedUrl.length
      ) {
        const data = response.data.getSignedUrl[0];
        const signedRequestUrl = data.signedRequestUrl;
        const fileUrl = data.url;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        await (reader.onload = (event: any) => {
          let binaryString = event?.target!.result;
          let binary = atob((binaryString as string)!.split(',')[1]);
          let array = [];
          for (var j = 0; j < binary.length; j++) {
            array.push(binary.charCodeAt(j));
          }
          let blobData = new Blob([new Uint8Array(array)], {
            type: file?.type,
          });
          axios
            .put(signedRequestUrl, blobData)
            .then(() => {
              history.push('/create-chapter?fileUrl=' + fileUrl);
            })
            .catch(function (error: any) {
              console.error(error);
            })
            .finally(() => {
              setFileUrlLoading(false);
            });
        });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl'>
      <ModalOverlay />
      <ModalContent borderRadius='30px' p={4}>
        <ModalHeader>
          <GenericColorHeader title='Create chapter' color='#B5E4CA' />
          <ModalCloseButton m={4} />
        </ModalHeader>
        <ModalBody>
          <Tabs>
            <TabList>
              <Tab>Write your own</Tab>
              <Tab>Upload from device</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Flex gap={8} flexDirection='column'>
                  <Flex flexDirection='column'>
                    <TitleHeader title='Give your chapter a title' />
                    <Input
                      placeholder='Enter chapter title'
                      value={chapterTitle}
                      onChange={(e) => setChapterTitle(e?.target?.value)}
                    />
                  </Flex>
                  <PrimaryButton
                    title='Take me to the editor'
                    colorScheme='blue'
                    variant='solid'
                    disabled={!chapterTitle}
                    onClick={() => {
                      history.push(`/create-chapter?title=${chapterTitle}`, {
                        // @ts-ignore
                        backToTitle: t('common:backToTitle', {
                          value: category || t('common:chapters'),
                        }),
                      });
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faFeatherPointed as IconProp}
                    color='#B1E5FC'
                    fontSize='180px'
                  />
                </Flex>
              </TabPanel>
              <TabPanel>
                {!file && (
                  <Center flexDirection='column' gap={2}>
                    <Flex
                      direction='column'
                      gap={2}
                      p={4}
                      w='480px'
                      border='1px dashed #9A9FA5'
                      borderRadius='11px'
                    >
                      <Box {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Center h='full' flexDirection='column'>
                          <ProfileCloudUpload />
                          <Text fontWeight={400} fontSize='18px'>
                            Drag and drop document here
                          </Text>
                          <Text
                            fontWeight={400}
                            fontSize='14px'
                            color='#212121B2'
                          >
                            PDF · Max size 5 Mb
                          </Text>
                        </Center>
                      </Box>
                      <Center flexDirection='column'>
                        <Text fontWeight={700} fontSize='15px'>
                          or
                        </Text>
                        <Button
                          variant='solid'
                          colorScheme='blue'
                          onClick={openFileDialog}
                          my={2}
                        >
                          Upload from device
                        </Button>
                      </Center>
                    </Flex>
                    <Text fontWeight={500} fontSize='14px' color='#6F767E'>
                      Currently we support only PDFs.
                    </Text>
                  </Center>
                )}
                {file && (
                  <Flex flexDir='column' gap={4}>
                    <Flex gap={2} h='20vh'>
                      <Center boxSize={'60px'} bg='#FFECEC' borderRadius='6px'>
                        <Image src={PdfImg} />
                      </Center>
                      <Flex flexDir='column'>
                        <Text fontWeight={600} fontSize='16px'>
                          {file.name}
                        </Text>
                        <Flex>
                          <Text
                            fontWeight={400}
                            fontSize='14px'
                            color='#333B4F'
                          >
                            {getSize(file.size)}, Uploaded by&nbsp;
                          </Text>
                          <Text bg='#CABDFF66' w='fit-content'>
                            {userData?.name}
                          </Text>
                          <Text>, Today</Text>
                        </Flex>
                      </Flex>
                    </Flex>
                    <PrimaryButton
                      title='Create chapter'
                      variant='solid'
                      colorScheme='blue'
                      isLoading={fileUrlLoading}
                      disabled={fileUrlLoading}
                      onClick={getFileUrlHandler}
                    />
                  </Flex>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateChapterModal;

import React, { FC } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import noImage from '../../../../../assets/images/placeholder/empty-user-task.svg';

interface IProps {
  isEmpty?: boolean;
  name?: string;
}

const EmptyUserTasks: FC<IProps> = ({ isEmpty, name = 'member' }) => {
  const { t } = useTranslation('task');

  if (!isEmpty) {
    return null;
  }

  return (
    <Flex direction='column' gap='14px' py='24px'>
      <Image src={noImage} maxW='300px' mx='auto' />
      <Flex direction='column' textAlign='center' gap='4px'>
        <Box fontSize='15px' fontWeight='700' color='#303030'>
          <Trans
            t={t}
            i18nKey='empty.toTaskAssignedUser'
            values={{
              name: name,
            }}
          />
        </Box>

        <Box fontSize='11px' fontWeight='500' color='#9E9E9E' px='12px'>
          {t('empty.toTaskAssignedUserDesc')}
        </Box>
      </Flex>
    </Flex>
  );
};

export default EmptyUserTasks;

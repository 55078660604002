import React, { FC } from 'react';
import { Control, useFormState } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';
import { ActionButton } from '../../../../../ui-components';

import WhoCanPost from '../channel-config/WhoCanPost';
import { IFormInput } from '../create-channel.types';

interface IProps {
  control: Control<IFormInput>;
  onSaveClick: (key: keyof IFormInput) => Promise<void>;
}

const UpdatePostSetting: FC<IProps> = ({ control, onSaveClick }) => {
  const dirtyFields = useFormState({
    control,
    name: 'postMessage',
  }).dirtyFields;

  const isDirty = Object.keys(dirtyFields['postMessage'] || {}).length > 0;

  return (
    <Flex flexDir='column' gap={4}>
      <WhoCanPost control={control} />
      {isDirty && (
        <ActionButton
          width='fit-content'
          colorScheme='blue'
          px={8}
          actionFn={() => onSaveClick('postMessage')}
        >
          Save
        </ActionButton>
      )}
    </Flex>
  );
};

export default UpdatePostSetting;

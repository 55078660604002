import React, {
  ChangeEvent,
  FC,
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Center,
  Checkbox,
  Flex,
  HStack,
  Image as NativeImage,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ChaptersResponse } from './add-category.graphql';
import Loader from '../../Loader';
import emptyIcon from '../../../assets/images/placeholder/empty-member.svg';
import { ActionButton } from 'ui-components/Confirm';
import { CategoryContext, IItemList } from './Context';
import { CategoryEntity } from 'sop-commons/src/client';
import ChapterIconComponent from 'pages/Chapters/ChapterIconComponent';
import { SopType } from 'types';
import { ICON_TYPE } from 'delightree-editor';

type Item = IItemList[][number];

interface ItemProps {
  item: IItemList[][number];
  onCheckChange: (eid: string) => void;
}

const MoveToItem: FC<ItemProps> = ({ item, onCheckChange }) => {
  return (
    <Flex
      paddingX='24px'
      className='move-item'
      _selected={{
        backgroundColor: '#2A85FF1A',
      }}
      aria-selected={item?.checked}
      _notLast={{
        '> div ': {
          borderBottom: '1px solid #EFEFEF',
        },
      }}
    >
      <HStack w='full' spacing='12px' paddingY='12px'>
        <Box>
          <Checkbox
            isChecked={item?.checked}
            onChange={() => onCheckChange(item.eid)}
          />
        </Box>
        <HStack
          style={{
            flex: 1,
            paddingLeft: 6,
            paddingRight: 6,
            cursor: 'pointer',
            overflow: 'hidden',
            userSelect: 'none',
          }}
          onClick={() => onCheckChange(item.eid)}
        >
          <ChapterIconComponent
            chapterName={item?.title}
            sopType={(item?.sopType as SopType) || undefined}
            fileType={item?.files}
            chapterLink={item?.icon?.native}
            chapterMediaDetails={item?.media?.[0]}
            chapterFileThumbnail={
              item?.type === 'folder'
                ? (item.icon as unknown as string) || item?.thumbnail
                : item?.thumbnail
            }
            folderColor={item?.color}
            width='42px'
            boxSize='42px'
            height='42px'
            playIconSize='2x'
            fontSize='20px'
            iconBoxSize={{ height: '40px', width: '40px' }}
            folderVisibility={item?.visibility}
            type={
              item?.icon?.id === ICON_TYPE
                ? 'icon'
                : item?.type === 'folder'
                ? 'folder'
                : 'emoji'
            }
            imageStyle={{
              margin: 'auto',
              width: '42px',
              height: '100%',
              alignSelf: 'center',
              borderRadius: 10,
              objectFit: 'cover',
            }}
          />
          <VStack align='stretch' flex='1' overflow='hidden'>
            <Box w='max-content'>
              <Box
                noOfLines={1}
                fontSize='12px'
                fontWeight='500'
                color='#9A9FA5'
                marginBottom='4px'
              >
                {item?.category}
              </Box>
              <Box
                noOfLines={1}
                fontSize='15px'
                fontWeight='700'
                color='#1A1D1F'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
              >
                {item?.title ? item?.title : 'N/A'}
              </Box>
            </Box>
          </VStack>
        </HStack>
      </HStack>
    </Flex>
  );
};

export interface MoveToComponentRef {
  onChange: (searchValue: string) => void;
}

interface IProps {
  category: string;
  data: CategoryEntity;
  onDeletePress?: (...args: any[]) => any | PromiseLike<any>;
  close?: () => void;
}

const MoveToComponent = forwardRef<MoveToComponentRef, IProps>((props, ref) => {
  const { t } = useTranslation(['common', 'category']);

  const [allChecked, setAllChecked] = useState(false);

  const {
    itemsList,
    setItemList,
    updateSearchValue,
    fetchData,
    fetchingData: loading,
    totalCount: count,
  } = useContext(CategoryContext);

  useImperativeHandle(
    ref,
    () => {
      return {
        onChange: (searchValue: string) => {
          updateSearchValue?.(searchValue);
          return fetchData?.({
            variables: {
              perPage: 30,
              filter: {
                category: props.category,
                query: searchValue,
              },
            },
          });
        },
      };
    },
    [fetchData, props.category]
  );

  const onCheckChange = (itemId: string) => {
    setItemList?.((prevState) => {
      const newState = [...prevState]?.map((value) => {
        if (value?.eid === itemId) {
          return {
            ...value,
            checked: !value?.checked,
          };
        }
        return value;
      });
      return [...newState];
    });
  };

  const filteredItems = useMemo(() => {
    return itemsList;
  }, [itemsList]);

  const onAllCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked);
    const keys: string[] = filteredItems?.map((val) => val.eid) || [];
    let _setMembers: Item[] = JSON.parse(JSON.stringify(itemsList));
    _setMembers?.forEach((list) => {
      if (keys.includes(list.eid)) {
        list.checked = event.target.checked;
      }
    });
    setItemList?.(_setMembers);
  };

  const [allItemChecked, isIndeterminate] = useMemo(() => {
    const all = filteredItems?.every((val) => val.checked);
    const some = filteredItems?.some((val) => val.checked);
    return [all && !!filteredItems?.length, some && !all];
  }, [filteredItems]);

  if (count === 0 && !loading) {
    return (
      <Box maxH='calc(100vh - 280px)' overflow='auto'>
        <Flex direction='column' align='center' gap='12px' py='36px'>
          <NativeImage src={emptyIcon} />
          <Box fontSize='15px' fontWeight='700'>
            {/* {t('category:folderNoChapter')} */}
            There are no items in this folder
          </Box>
          <ActionButton
            colorScheme='red'
            px='32px'
            actionFn={props.onDeletePress}
            close={props.close}
          >
            {/*// @ts-ignore */}
            {t('category:delete_name', {
              value: `“${props.category}”`,
            })}
          </ActionButton>
        </Flex>
      </Box>
    );
  }

  return (
    <Box maxH='calc(100vh - 280px)' overflow='auto'>
      <Flex flexDir='column' gap='1px'>
        {loading ? (
          <Center minH='200px'>
            <Loader size='lg' />
          </Center>
        ) : (
          <>
            {filteredItems?.length > 0 && (
              <HStack spacing='12px' pl='24px' pb='8px'>
                <Checkbox
                  isChecked={allChecked && allItemChecked}
                  isIndeterminate={allChecked && isIndeterminate}
                  onChange={onAllCheckChange}
                />
                <Text as='b' pl='6px'>
                  {t('select_all')}
                </Text>
              </HStack>
            )}
            {filteredItems?.map((member) => {
              return (
                <MoveToItem
                  key={member.eid}
                  item={member}
                  onCheckChange={onCheckChange}
                />
              );
            })}
          </>
        )}
      </Flex>
    </Box>
  );
});

MoveToComponent.displayName = 'Move to component';

export default MoveToComponent;

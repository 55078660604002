import React, { FC, useCallback, useRef, useState } from 'react';
import {
  AlertDialogCloseButton,
  Box,
  Flex,
  Textarea,
  useModalContext,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  ActionButton,
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../ui-components';

interface IProps {
  note?: string;
  onNoteAdd: (newNote: string) => void;
}

const Content: FC<IProps> = ({ note, onNoteAdd }) => {
  const { t } = useTranslation(['common']);
  const { onClose } = useModalContext();

  const [value, setValue] = useState(note || '');

  return (
    <Flex flexDir='column' gap={4}>
      <Textarea
        placeholder='Enter notes here'
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />

      <Flex gap={2} justify='flex-end' py={4}>
        <ActionButton
          fontSize='15px'
          minW='160px'
          variant='outline'
          close={onClose}
        >
          {t('common:cancel')}
        </ActionButton>

        <ActionButton
          fontSize='15px'
          minW='160px'
          colorScheme='blue'
          // isDisabled={!value?.trim()}
          close={onClose}
          actionFn={() => onNoteAdd?.(value)}
        >
          {t('common:add')}
        </ActionButton>
      </Flex>
    </Flex>
  );
};

type IQuestionNotes = (props: IProps) => void;

export const useQuestionNotes = (): IQuestionNotes => {
  const { t } = useTranslation(['form']);
  const confirm = useConfirm();

  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      title: (
        <Flex gap='12px'>
          <Box flex={1}>
            <BoxHeader title={t('form:addingNote')} color='#CABDFF' />
          </Box>
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      content: <Content {...props} />,
      size: '3xl',
      contentProps: {
        py: '20px',
        px: '10px',
        borderRadius: '16px',
      },
      isCentered: true,
      footer: null,
    });
  }, []);
};

import { SelectOption } from 'atoms';
import { isCheckList, toArray } from 'utils/utils';

import { IFormInput } from './form.types';
import {
  FormVisibility,
  IVisibilityInput,
} from './visibility/visibility.types';

export const formDataFormat = (data: IFormInput): IFormInput => {
  data.questions = toArray(data.questions).map((ques) => {
    ques.hasOtherOption = toArray(ques.options).some((opt) => opt.isOther);

    ques.isRequired = ques.isRequired || isCheckList(data.formCategory?.name);

    return ques;
  });

  return data;
};

const mapEid = (data: SelectOption) => data.value;

export const formVisibilityFormat = (
  visibility: IVisibilityInput
): FormVisibility['visibleTo'] => {
  if (visibility.visibility === 'public') {
    return {
      roles: [],
      users: [],
      locations: [],
    };
  }

  const visibleTo: FormVisibility['visibleTo'] = {
    condition: visibility.operation,
    users: toArray(visibility.members).map((val) => val.value),
    roles: [],
    locations: [],
  };

  switch (visibility.filterBy?.value) {
    case 'location':
      visibleTo.locations = toArray(visibility.filterValue).map(mapEid);
      break;
    case 'job':
      visibleTo.roles = toArray(visibility.filterValue).map(mapEid);
      break;
  }

  switch (visibility.otherFilterBy?.value) {
    case 'location':
      visibleTo.locations = toArray(visibility.otherFilterValue).map(mapEid);
      break;
    case 'job':
      visibleTo.roles = toArray(visibility.otherFilterValue).map(mapEid);
      break;
  }

  return visibleTo;
};

import { gql } from '@apollo/client';
import { FileEntity, SopType, VisibilityConfig } from 'types';

export interface FileDataEntity extends Omit<FileEntity, 'url'> {
  file?: File;
  url?: string;
}

export interface ChapterVariable extends VisibilityConfig {
  // category: string;
  categoryId: string;
  raw_content: string;
  title: string;
  files?: FileEntity[];
  sopType: SopType;
  thumbnail?: string | null;
}

export const ADD_CHAPTER = gql`
  mutation AddChapter($input: SopAddInput) {
    addSop(input: $input) {
      eid
    }
  }
`;

export interface EditChapterInput extends VisibilityConfig {
  eid: string;
  // category: string;
  categoryId: string;
  raw_content: string;
  title: string;
  files?: FileEntity[];
  sopType: SopType;
  thumbnail?: string | null;
  languageCode: string;
  notifyUsers: boolean;
}

export const UPDATE_CHAPTER = gql`
  mutation EditChapter($input: SopUpdateInput) {
    updateSop(input: $input) {
      eid
    }
  }
`;

interface SopData extends VisibilityConfig {
  eid: string;
  title: string;
  category: string;
  categoryId: string;
  notifyUsers: boolean;
  files: FileEntity[];
}

export interface EditableResponse {
  SopById: SopData;
}

export const EDIT_CHAPTER_QUERY = gql`
  query ChapterForEdit($eid: String!) {
    SopById(eid: $eid) {
      eid
      title
      category
      categoryId
      notifyUsers
      visibility
      visibleTo {
        condition
        locations
        roles
        users
      }
      files {
        fileSize
        mimetype
        name
        type
        url
      }
    }
  }
`;

import React, { FC, useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Dropdown from '../../../../atoms/Dropdown';
import { getMembers, MembersResponse, NotAllowed } from './data.graphql';
import { FormPreviewInput } from '../form-preview.types';
import { userObj } from 'sop-commons/src/client/clientFactory';

interface IProps {
  isRequired?: boolean;
  currentIndex: number;
  isDisabled?: boolean;
}

type Location = MembersResponse['EntityUser'][number];

const LocationChoice: FC<IProps> = ({
  isRequired,
  currentIndex,
  isDisabled,
}) => {
  const { t } = useTranslation('form');
  const entityId = useReactiveVar(userObj).entityId;
  const { control } = useFormContext<FormPreviewInput>();
  const { data, loading } = useQuery<MembersResponse>(getMembers, {
    variables: {
      entityId: entityId,
    },
  });

  const items = data?.EntityUser || ([] as Location[]);

  const optionList = useMemo(() => {
    if (Array.isArray(items)) {
      return items
        .filter(
          (value) =>
            value.type === 'branch' && !NotAllowed.includes(value.status)
        )
        .map((value) => {
          return {
            label: value.name,
            value: value.name,
            eid: value.eid,
          };
        });
    } else {
      return [];
    }
  }, [items]);

  return (
    <div>
      <Controller
        name={`response.${currentIndex}.responseId`}
        control={control}
        rules={{
          required: {
            value: isRequired === true,
            message: t('validation.field_required'),
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl mt={3} isInvalid={!!fieldState.error}>
              <Dropdown
                isLoading={loading}
                options={optionList}
                size='lg'
                className='dropdown'
                placeholder=''
                inputStyle={{
                  border: 'none',
                  borderColor: 'transparent',
                  borderRadius: '12px',
                  fontSize: '14px',
                  outline: 'none',
                }}
                value={field.value as any}
                onChange={(newValue) => field.onChange([newValue])}
                isDisabled={isDisabled}
                getOptionValue={(option) => option?.eid ?? option}
                formatOptionLabel={(option) => {
                  if (option?.label) {
                    return option.label;
                  }
                  return optionList.find((value) => value.eid === option)
                    ?.label;
                }}
              />
              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </div>
  );
};

export default LocationChoice;

import React, { FC, useMemo } from 'react';
import {
  Center,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { UseFieldArrayMove, useFormContext } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisStroke, faPen } from '@fortawesome/pro-light-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MenuItemWrapper } from '../../../../ui-components';
import { ITitleEdit } from './base-launcher.types';

interface IPItem {
  menu: {
    name: string;
    icon: any;
    value: string;
    textColor?: string;
    isDisabled?: boolean;
  };
}

const ActionItem: FC<IPItem> = ({ menu }) => {
  return (
    <>
      <Center width='fit-content' boxSize='22px'>
        <FontAwesomeIcon
          icon={menu?.icon}
          fontSize='18px'
          color={menu?.textColor}
        />
      </Center>

      <span
        style={{
          fontSize: '15px',
          fontWeight: '500',
          lineHeight: '24px',
          color: menu?.textColor || '#1A1D1F',
        }}
      >
        {menu.name}
      </span>
    </>
  );
};

interface IProps {
  index: number;
  isLastSection?: boolean;
  onDeleteClick?: (index: number) => void;
  moveSectionTo: UseFieldArrayMove;
}

const SectionAction: FC<IProps> = ({
  index,
  isLastSection,
  moveSectionTo,
  onDeleteClick,
}) => {
  const { setValue } = useFormContext<ITitleEdit>();

  const menuData = useMemo(() => {
    return [
      {
        name: 'Edit phase name',
        icon: faPen,
        value: 'edit',
      },
      {
        name: 'Create new phase',
        icon: faPlus,
        value: 'create',
      },
      {
        name: 'Move to left',
        icon: faChevronLeft,
        value: 'move-left',
        isDisabled: index === 0,
      },
      {
        name: 'Move to right',
        icon: faChevronRight,
        value: 'move-right',
        isDisabled: isLastSection,
      },
      {
        name: 'Delete phase',
        icon: faTrashCan,
        value: 'delete',
        textColor: '#FF6A55',
      },
    ];
  }, [index, isLastSection]);

  const itemClickHandler = async (
    menu: IPItem['menu'],
    callback: () => void
  ) => {
    if (menu.isDisabled) {
      return;
    }
    switch (menu.value) {
      case 'edit':
        setValue('sectionTitle', index);
        break;
      case 'create':
        document.querySelector('#add-launcher-section-btn')?.scrollIntoView({
          block: 'nearest',
        });
        setValue('sectionTitle', -2);
        break;
      case 'move-left':
        moveSectionTo(index, index - 1);
        break;
      case 'move-right':
        moveSectionTo(index, index + 1);
        break;
      case 'delete':
        onDeleteClick?.(index);
        break;
    }
    // clickedItem?.(menu.name, menu.value);
    callback();
  };

  return (
    <React.Fragment>
      <Popover strategy='fixed' placement='bottom-start'>
        {({ onClose }) => (
          <>
            {/*// @ts-ignore */}
            <PopoverTrigger>
              <Flex pt={0.5} cursor='pointer'>
                <FontAwesomeIcon
                  fontSize='1.5rem'
                  icon={faEllipsisStroke as IconProp}
                  color='#6F767E'
                />
              </Flex>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody px={4}>
                <Flex flexDir='column' py='8px' gap={2}>
                  {menuData.map((menu, index) => (
                    <MenuItemWrapper
                      key={index}
                      menu={menu}
                      onClick={(e) => {
                        e.stopPropagation();
                        return itemClickHandler(menu, onClose);
                      }}
                      aria-disabled={!!menu.isDisabled}
                      _disabled={{
                        cursor: 'not-allowed',
                        opacity: 0.4,
                      }}
                    >
                      <ActionItem menu={menu} />
                    </MenuItemWrapper>
                  ))}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </React.Fragment>
  );
};

export default SectionAction;

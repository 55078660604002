import { gql } from '@apollo/client';
import { UserTrainingItem } from '../../../Training/TrainingList/Component/user-training.types';

export interface UserTrainingResponse {
  userTrainingDetails: Array<UserTrainingItem>;
}

export const USER_TRAINING_QUERY = gql`
  query UserTrainings($userId: String!) {
    userTrainingDetails(userId: $userId) {
      eid
      title
      thumbnail
      status
      contentLastUpdate
      trainingItems
      userProgress
    }
  }
`;

import React, { FC, ReactElement } from 'react';
import { Button, Flex, Tooltip, TooltipProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Authorize, Permission } from 'authorization';

const styles = {
  variant: 'ghost',
  color: '#6F7E7C',
  _hover: {
    bg: 'gray.50',
  },
};

const ToolTipWrapper: FC<TooltipProps> = ({ children, ...rest }) => {
  if (!rest?.label) {
    return <>{children}</>;
  }

  // @ts-ignore
  return (
    <Tooltip hasArrow px='16px' py='12px' {...rest}>
      {children}
    </Tooltip>
  );
};

interface IProps<T> {
  button1: T;
  button2: T;
  permission1?: Permission;
  permission2?: Permission;
  title1?: string;
  title2?: string;
  value: T;
  onClick: (newValue: T) => void;
}

function ToggleButton<T>({
  button1,
  button2,
  permission1 = {},
  permission2 = {},
  title1,
  title2,
  onClick,
  value,
}: IProps<T>): ReactElement {
  const { t } = useTranslation('training');

  return (
    <Flex justify='flex-end' gap='4px'>
      <Authorize {...permission1}>
        <ToolTipWrapper label={title1}>
          <Button
            {...(value === button1 ? {} : styles)}
            onClick={() => onClick(button1)}
          >
            {t(button1 as never)}
          </Button>
        </ToolTipWrapper>
      </Authorize>
      <Authorize {...permission2}>
        <ToolTipWrapper label={title2}>
          <Button
            {...(value === button2 ? {} : styles)}
            onClick={() => onClick(button2)}
          >
            {t(button2 as never)}
          </Button>
        </ToolTipWrapper>
      </Authorize>
    </Flex>
  );
}

export default ToggleButton;

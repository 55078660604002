import { Divider, Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMessageLines } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { IInProgressCardBottom } from 'sub-components/LocationLaunchDetail/types/types';
import Common from './Common';

const InProgressCardBottom: FC<IInProgressCardBottom> = (props) => {
  const { orientation = 'vertical' } = props;
  if (orientation === 'vertical') {
    return (
      <Flex flexDir='column'>
        <Text color='#6F767E' fontWeight={500} fontSize='12px'>
          Latest help query
        </Text>
        <Flex align='center' gap='4px'>
          <FontAwesomeIcon
            icon={faMessageLines as IconProp}
            color='#6F767E'
            flip='horizontal'
          />
          <Text color='#2A85FF' fontWeight={500} fontSize='12px'>
            Getting final document
          </Text>
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Flex>
        <Common />
        <Divider />
        <Common />
      </Flex>
    );
  }
};

export default InProgressCardBottom;

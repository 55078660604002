import React, { FC, useCallback, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import SearchInput from 'atoms/SearchInput';
import { AmplitudeEventNames, deployEvent } from 'shared/amplitudeEvents';

import { ToggleButton } from './Component';
import MyTrainingList from './MyTrainingList/MyTrainingList';

type IButtonValue = 'public' | 'assignedToMe';

interface HProps {
  value: IButtonValue;
  onClick: (newValue: IButtonValue) => void;
  query?: string;
  onChange?: (value: string) => void;
}

const Header: FC<HProps> = ({ value, onClick, query, onChange }) => {
  const { t } = useTranslation('training');

  const onTabClick = useCallback(
    (newValue: IButtonValue) => {
      onChange?.('');
      onClick(newValue);
      switch (newValue) {
        case 'public':
          deployEvent(AmplitudeEventNames.TRAINING_PATH_TAB_PUBLIC);
          break;
        case 'assignedToMe':
          deployEvent(AmplitudeEventNames.TRAINING_PATH_TAB_ASSIGNED_TO_ME);
          break;
      }
    },
    [onClick, onChange]
  );

  return (
    <Flex
      justify='space-between'
      align='center'
      paddingBlock='8px'
      pos='relative'
    >
      <ToggleButton<IButtonValue>
        button1='public'
        button2='assignedToMe'
        title1={t('publicToYou')}
        value={value}
        onClick={onTabClick}
      />
      <SearchInput
        disabled={true}
        size='md'
        width='320px'
        hideShortcuts={true}
        placeholder={t('searchTrainingStatusText')}
        onChange={(event) => onChange?.(event.target.value)}
        value={query}
        onFocus={() => {
          deployEvent(AmplitudeEventNames.TRAINING_PATH_MY_TRAINING_SEARCH);
        }}
      />
    </Flex>
  );
};
interface IProps {}

const MyTrainingContainer: FC<IProps> = () => {
  const [value, setValue] = useState<IButtonValue>('public');
  const [query, setQuery] = useState('');

  return (
    <div>
      <Header
        value={value}
        onClick={setValue}
        query={query}
        onChange={setQuery}
      />
      <MyTrainingList tabSelected={value} searchQuery={query} />
    </div>
  );
};

export default MyTrainingContainer;

import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import {
  FieldErrors,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';

import { AmplitudeEvent, deployEvent } from '../../../shared';

import TaskCreationHeader from './TaskCreationHeader';
import TaskCreationForm from './TaskCreationForm';
import LocationTimeZone from './LocationTimeZone';
import { IFormInput, ITaskAction } from './task.types';
import { usePublishConfirm } from './usePublishConfirm';
import TaskFormLoading from './TaskFormLoading';
import { handleValidationError } from './validation';

export interface TaskCreationRef {
  initEditTask: (values: IFormInput) => void;
  setError: UseFormSetError<IFormInput>;
  setValue: UseFormSetValue<IFormInput>;
}

interface IProps {
  title?: string;
  isEdit?: boolean;
  isLoading?: boolean;
  handleSubmit: SubmitHandler<IFormInput>;
  handleAction?: (type: ITaskAction) => void;
}

const TaskCreationBase = forwardRef<TaskCreationRef, IProps>(
  ({ title, isEdit, handleSubmit, isLoading, handleAction }, ref) => {
    const initialRef = useRef<IFormInput>();

    const publishConfirm = usePublishConfirm();

    const methods = useForm<IFormInput>({
      defaultValues: {
        completed: {
          details: false,
        },

        currentStep: 'details',
        taskRunFor: 'once',

        assignToType: [],

        contents: [],
      },
    });

    useImperativeHandle(
      ref,
      () => {
        return {
          initEditTask: (values) => {
            const timeOffsetDiff = methods.getValues('timeOffsetDiff');
            methods.reset({ ...values, timeOffsetDiff });
            initialRef.current = values;
          },
          setError: methods.setError,
          setValue: methods.setValue,
        };
      },
      [methods.reset, methods.setValue, methods.setError]
    );

    const onPublishClick = () => {
      deployEvent(AmplitudeEvent.TASK_PUBLISH);
      publishConfirm({
        isEdit: methods.getValues('isEditing'),
        startDateType: methods.getValues('startDateType'),
        startDate: methods.getValues('startDate'),
        startTime: methods.getValues('startTime'),
        onPublish: methods.handleSubmit(handleSubmit),
      });
    };

    const onError = (error: FieldErrors<IFormInput>) => {
      const nextStep = handleValidationError(error);
      if (nextStep) methods.setValue('currentStep', nextStep);
    };

    return (
      <FormProvider {...methods}>
        <TaskCreationHeader
          isLoading={isLoading}
          isEdit={isEdit}
          title={title}
          onCreatePress={methods.handleSubmit(onPublishClick, onError)}
          handleAction={handleAction}
        />
        <TaskFormLoading isLoading={isLoading}>
          <TaskCreationForm onPublishClick={onPublishClick} isEdit={isEdit} />
        </TaskFormLoading>
        <LocationTimeZone />
      </FormProvider>
    );
  }
);

TaskCreationBase.displayName = 'Task Creation Base';

export default TaskCreationBase;

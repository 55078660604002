import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery, useReactiveVar } from '@apollo/client';
import moment from 'moment-timezone';
import { userObj } from 'sop-commons/src/client';
import { toArray } from '../../../utils/utils';
import { IFormInput } from './task.types';
import { getTimeZone } from './validation/helper';
import { locationTimezoneQuery, TimezoneResponse } from './Scheduler';

interface IProps {}

const LocationTimeZone: React.FC<IProps> = () => {
  const userData = useReactiveVar(userObj);

  const { setValue } = useFormContext<IFormInput>();

  const updateOffset = (
    data: TimezoneResponse['fetchEntityLocations'] = []
  ) => {
    let res = toArray(data)
      .slice()
      .sort((a, b) => {
        const aTime = moment().tz(getTimeZone(a.timezone)).utcOffset();
        const bTime = moment().tz(getTimeZone(b.timezone)).utcOffset();

        return bTime - aTime;
      });

    const userOffset = moment().tz(getTimeZone(userData?.timezone)).utcOffset();

    const locOffset =
      moment().tz(getTimeZone(res[0]?.timezone)).utcOffset() - userOffset;

    const systemOffset = moment().utcOffset() - userOffset;

    setValue(
      'timeOffsetDiff',
      systemOffset > locOffset ? systemOffset : locOffset
    );
  };

  useQuery<TimezoneResponse>(locationTimezoneQuery, {
    fetchPolicy: 'network-only',
    variables: {
      eid: userData?.entityId,
    },
    skip: !userData?.entityId,
    onCompleted: (response) => {
      updateOffset(response.fetchEntityLocations);
    },
  });

  return null;
};

export default LocationTimeZone;

import { IFormInput } from '../create-task';
import { EditTaskInput } from './edit-task.graphql';
import { toArray } from '../../../utils/utils';
import { getRepeatString } from '../create-task/repeat-string';
import { getEndCondition } from '../create-task/end-condition';
import { getRepeatDetails } from '../create-task/dataTransform';

export const dataTransformToUpdate = (
  taskId: string,
  values: IFormInput
): EditTaskInput => {
  const result: EditTaskInput = {
    eid: taskId,
    title: values.title,
    description: values.description,

    thumbnail: undefined,

    repeatString: getRepeatString(values),

    contents: toArray(values.contents).map((val) => ({
      eid: val.eid,
      title: val.title,
      type: val.type,
      backgroundColor: val.backgroundColor,
      isMandatory: val.isMandatory,
    })),

    assignedLocationsType: values.assignedLocationsType,
    assignedToLocation: values.assignedToLocation || [],
    assignedToRole: values.assignedToRole || [],
    assignedToUsers: values.assignedToUsers || [],

    supervisors: values.supervisors || [],
  };

  switch (values.taskRunFor) {
    case 'repeat':
      result.endCondition = getEndCondition(values);
      break;
    case 'once':
      if (values.repeatString !== result.repeatString) {
        result.endCondition = getEndCondition(values);
      }
      break;
  }

  result.repeatDetails = getRepeatDetails(values);

  return result;
};

import {
  Center,
  ComponentWithAs,
  TextProps,
  Flex,
  Box,
} from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { TFunction } from 'i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';

import {
  IRoleData,
  IRoleMemberEntity,
} from 'sub-components/SettingDataCenter/RolesList/roles-list.types';
import moment from 'moment';
import JobMembersComponent, { IMembersDataEntity } from './JobMembersComponent';
import { AuthRole } from 'authorization';
import { ADMIN, SUPER_ADMIN } from 'utils/userRoles';
import { ActionMenu } from 'ui-components';

export const getColumns = (
  isTablet: boolean,
  Text: ComponentWithAs<'p', TextProps>,
  t: TFunction<
    ('training' | 'role' | 'setting')[],
    undefined,
    ('training' | 'role' | 'setting')[]
  >,
  jobMembersClickHandler: (
    members: IMembersDataEntity[],
    title: string
  ) => void,
  authRole: AuthRole
) => {
  let tableColumns: any = [
    {
      Header: 'Job',
      accessor: 'roleName' as const,
      width: '30%',
      Cell: ({ cell: { value, row } }: any) => {
        return (
          <Flex flexDirection='column'>
            <Text as='b'>{value?.name}</Text>
            <Text fontWeight='600' fontSize='12px' color='#6F767E'>
              Created on: {moment(value?.createdAt).format('DD MMM')}
            </Text>
          </Flex>
        );
      },
    },
    // {
    //   Header: t('training:last_updated'),
    //   accessor: 'lastUpdated' as const,
    //   width: '15%',
    //   Cell: ({ cell: { value, row } }: any) => {
    //     return <span>{moment(value)?.format('DD MMM YYYY')}</span>;
    //   },
    //   disableSortBy: true,
    // },
    {
      Header: t('setting:members_heading'),
      accessor: 'members' as const,
      width: '70%',
      Cell: ({ cell: { value, row } }: any) => {
        return (
          <JobMembersComponent
            membersData={value}
            title={row?.original?.roleName?.name}
            t={t}
            jobMembersClickHandler={jobMembersClickHandler}
          />
        );
      },
      disableSortBy: true,
    },
    // {
    //   Header: t('training:created_by'),
    //   accessor: 'createdBy' as const,
    //   // width: '10%',
    //   Cell: ({ cell: { value, row } }: any) => {
    //     return <CreatedByComponent createdBy={value} />;
    //   },
    //   disableSortBy: true,
    // },
    ...(authRole === SUPER_ADMIN || authRole === ADMIN
      ? [
          {
            Header: '',
            accessor: 'action' as const,
            // width: '5%',
            disableSortBy: true,
          },
        ]
      : []),
  ];
  return tableColumns;
};

export const getTableData = (
  isTablet: boolean,
  rolesListData: Array<IRoleData>,
  clickedItemsHandler: (clickedItemName: string, item: IRoleData) => void,
  FilledHorizontal: string,
  faTrash: IconDefinition,
  faPenToSquare: IconDefinition,
  t: TFunction<
    ('training' | 'role' | 'setting' | 'common' | 'category')[],
    undefined,
    ('training' | 'role' | 'setting' | 'common' | 'category')[]
  >,
  authRole: AuthRole
) => {
  const tableData = rolesListData?.map((item, index: number) => {
    let obj: {
      roleName: { name: string; createdAt: string };
      lastUpdated: string;
      members: IRoleMemberEntity[];
      createdBy: { image: string; name: string };
      action?: any;
    } = {
      roleName: { name: item?.name, createdAt: item?.createdAt },
      lastUpdated: item?.updatedAt,
      members: item?.members,
      createdBy: item?.createdBy,
    };
    if (authRole === AuthRole.SUPER_ADMIN || authRole === ADMIN) {
      obj.action = (
        <Center cursor='pointer'>
          <ActionMenu
            menuData={[
              {
                name: t('common:delete'),
                value: 'delete',
                normalIcon: 'fontAwesome',
                icon: faTrash,
              },
              {
                name: t('common:edit'),
                value: 'edit',
                normalIcon: 'fontAwesome',
                icon: faPenToSquare,
              },
            ]}
            arrowSize={10}
            closeOnBlur={true}
            offset={[0, 0]}
            clickedItem={(_, clickedItem) =>
              clickedItemsHandler(clickedItem, item)
            }
          >
            <Box cursor='pointer'>
              <FontAwesomeIcon
                icon={faEllipsis as IconProp}
                size='2x'
                color='#33383F'
              />
            </Box>
          </ActionMenu>
        </Center>
      );
    }
    return obj;
  });
  return [...tableData];
};

import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { CardFormInput } from '../card-editor.types';

export const useLayoutDisabled = (): boolean => {
  const [language, defaultLanguage] = useWatch<CardFormInput>({
    name: ['language', 'defaultLanguage'],
  });

  return useMemo(() => {
    if (!defaultLanguage || !language) {
      return false;
    }
    return language !== defaultLanguage;
  }, [language, defaultLanguage]);
};

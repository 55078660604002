import { gql } from '@apollo/client';

export const ADD_LAUNCHER_TASK_FILES = gql`
  mutation AddLauncherTaskFiles(
    $addLauncherTaskFilesInput2: AddLauncherTaskFilesInputInput
  ) {
    AddLauncherTaskFiles(input: $addLauncherTaskFilesInput2) {
      succeed
    }
  }
`;

export const COMPLETE_LAUNCHER_TASK = gql`
  mutation CompleteLauncherTask($input: CompleteLauncherTaskInputInput) {
    CompleteLauncherTask(input: $input) {
      succeed
    }
  }
`;

export const MARK_LAUNCHER_TASK_UNDONE = gql`
  mutation MarkLauncherTaskUndone(
    $launchId: String!
    $taskId: String!
    $stepId: String
  ) {
    MarkLauncherTaskUndone(
      launchId: $launchId
      taskId: $taskId
      stepId: $stepId
    ) {
      succeed
    }
  }
`;

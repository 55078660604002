import { useEffect, useState } from 'react';

const CarouselBarItem = ({ style, isSelected }) => {
  let backgroundStyle = {};
  if (isSelected) {
    backgroundStyle = {
      backgroundColor: '#2a85ff',
    };
  }
  return (
    <div
      className='carousel-bar-item-container'
      style={{ ...style, ...backgroundStyle }}
    />
  );
};

const CarouselBar = ({ selectedIndex, totalLength }) => {
  const [indicatorObject, setIndicatorObject] = useState({
    one: true,
    two: false,
    three: false,
    four: false,
    five: false,
  });

  useEffect(() => {
    if (selectedIndex === 1) {
      setIndicatorObject({
        one: true,
        two: false,
        three: false,
        four: false,
        five: false,
      });
    } else if (selectedIndex === 2) {
      setIndicatorObject({
        one: false,
        two: true,
        three: false,
        four: false,
        five: false,
      });
    } else if (selectedIndex === 3) {
      setIndicatorObject({
        one: false,
        two: false,
        three: true,
        four: false,
        five: false,
      });
    } else if (selectedIndex === 4) {
      setIndicatorObject({
        one: false,
        two: false,
        three: false,
        four: true,
        five: false,
      });
    } else {
      setIndicatorObject({
        one: false,
        two: false,
        three: false,
        four: false,
        five: true,
      });
    }
  }, [selectedIndex, totalLength]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {totalLength > 0 && (
        <CarouselBarItem
          style={{ marginLeft: '4px' }}
          isSelected={indicatorObject?.one}
        />
      )}
      {totalLength > 1 && (
        <CarouselBarItem
          style={{ marginLeft: '4px' }}
          isSelected={indicatorObject?.two}
        />
      )}
      {totalLength > 2 && (
        <CarouselBarItem
          style={{ marginLeft: '4px' }}
          isSelected={indicatorObject?.three}
        />
      )}
      {totalLength > 3 && (
        <CarouselBarItem
          style={{ marginLeft: '4px' }}
          isSelected={indicatorObject?.four}
        />
      )}
      {totalLength > 4 && (
        <CarouselBarItem
          style={{ marginLeft: '4px' }}
          isSelected={indicatorObject?.five}
        />
      )}
    </div>
  );

  // if (totalLength && totalLength > 0) {
  //    return <div style={{ display: 'flex' }}>
  //      {[...Array(totalLength < 6 ? totalLength : 5).keys()].map(i => {
  //        return <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
  //          {/* {<CarouselBarItem isSelected={selectedIndex < 6 ? selectedIndex % totalLength === i+1 : } />} */}
  //          {<CarouselBarItem isSelected={isSelectedHandler(i)} />}
  //          {/* <CarouselBarItem
  //          style={{ marginLeft: '4px' }}
  //          isSelected={selectedIndex % 5 === 2}
  //        />
  //        <CarouselBarItem
  //          style={{ marginLeft: '4px' }}
  //          isSelected={selectedIndex % 5 === 3}
  //        />
  //        <CarouselBarItem
  //          style={{ marginLeft: '4px' }}
  //          isSelected={selectedIndex % 5 === 4}
  //        />
  //        <CarouselBarItem
  //          style={{ marginLeft: '4px' }}
  //          isSelected={selectedIndex % 5 === 0}
  //        /> */}
  //        </div>
  //  })}
  //   </div>
  // } else {
  //   return null;
  // }
};

export default CarouselBar;

export const inviteWidth = {
  sm: '340px',
  md: '740px',
  base: '740px',
  xl: '740px',
  ['2xl']: '740px',
};

export const inviteMinWidth = {
  sm: '340px',
  md: '674px',
  base: '674px',
  xl: '674px',
  ['2xl']: '740px',
};

export const modalWidth = {
  sm: '327px',
  md: '642px',
  base: '642px',
  xl: '642px',
  ['2xl']: '642px',
};

export const modalMinWidth = {
  sm: '327px',
  md: '642px',
  base: '642px',
  xl: '642px',
  ['2xl']: '642px',
};

import { SelectOption } from '../../../../../atoms';

export const sortLocation = <T extends SelectOption>(
  valA: T,
  valB: T
): number => {
  if (valA.label < valB.label) {
    return -1;
  } else if (valA.label > valB.label) {
    return 1;
  } else {
    return 0;
  }
};

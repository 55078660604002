import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Flex } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import { BoxHeader } from 'ui-components';

import TrainingCard from '../../../../../TrainingCard';
import { CardType } from '../../../../../../types';

import { ReactComponent as CardPreviewEmpty } from 'sop-commons/src/card-components/assets/cardPreview.svg';

interface IProps {}

const FormPreview: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'task']);

  const previewData = useWatch({
    name: 'preview',
  });

  const content = useMemo(() => {
    return {
      cardType: CardType.CustomForm,
      content: [{ ...previewData }],
    };
  }, [previewData]);

  return (
    <Flex flexDir='column' width='300px' pt={1} gap={5}>
      <BoxHeader title={t('common:preview')} color='#CABDFF' />
      <Box flex={1} overflow='hidden'>
        {previewData?.eid ? (
          <TrainingCard content={content} isPreview />
        ) : (
          <Center
            flexDir='column'
            gap='8px'
            height='full'
            border='1px solid #d2d2d2'
            borderRadius='16px'
          >
            <CardPreviewEmpty />
            <Box fontSize='15px' fontWeight='700' color='#303030'>
              {t('task:noFormToPreview')}
            </Box>
            <Box
              maxW='200px'
              textAlign='center'
              fontSize='12px'
              fontWeight='400'
              color='#9E9E9E'
            >
              {t('task:noFormToPreviewDesc')}
            </Box>
          </Center>
        )}
      </Box>
    </Flex>
  );
};

export default FormPreview;

// @ts-ignore
import React, { FC } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faListUl, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faGrid2 } from '@fortawesome/pro-regular-svg-icons';
import PrimaryButton from '../../atoms/PrimaryButton';

import Dropdown, { SelectOption } from '../../atoms/Dropdown';
import { Authorize, AuthRole } from '../../authorization';

interface ToggleButtonProps {
  viewType?: 'list' | 'grid';
  onViewChange?: (value: 'list' | 'grid') => void;
}

const ToggleButton: FC<ToggleButtonProps> = ({ viewType, onViewChange }) => {
  const onPress = (
    value: ToggleButtonProps['viewType'],
    currentVal?: ToggleButtonProps['viewType']
  ) => {
    if (value === currentVal) {
      return;
    }
    onViewChange?.(value);
  };

  return (
    <Flex>
      <IconButton
        aria-label={'list'}
        icon={<FontAwesomeIcon icon={faListUl as IconProp} />}
        borderRightRadius='0'
        variant='outline'
        {...(viewType === 'list'
          ? {
              variant: 'solid',
              colorScheme: 'blue',
            }
          : {
              color: '#6F767E',
            })}
        onClick={() => onPress('list', viewType)}
      />
      <IconButton
        aria-label={'card'}
        icon={<FontAwesomeIcon icon={faGrid2 as IconProp} />}
        borderLeftRadius='0'
        variant='outline'
        {...(viewType === 'grid'
          ? {
              variant: 'solid',
              colorScheme: 'blue',
            }
          : {
              color: '#6F767E',
            })}
        onClick={() => onPress('grid', viewType)}
      />
    </Flex>
  );
};

interface IProps extends ToggleButtonProps {
  onCardPress: () => void;
  onDeckPress: () => void;
  setSelectedSortValue: (value: SelectOption) => void;
  selectedSortValue: SelectOption;
}

const AddCardDeckButton: FC<IProps> = ({
  onDeckPress,
  onCardPress,
  setSelectedSortValue,
  selectedSortValue,
  viewType,
  onViewChange,
}) => {
  const { t } = useTranslation('handbook');
  return (
    <Flex justify='flex-end' align='flex-end' gap='12px'>
      <Authorize
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        permittedFor='user'
      >
        <PrimaryButton
          width='auto'
          leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
          type='button'
          size='md'
          title={t('add_card')}
          variant='solid'
          colorScheme='blue'
          onClick={onCardPress}
        />
      </Authorize>

      <Authorize
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
        permittedFor='user'
      >
        <PrimaryButton
          width='auto'
          leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
          type='button'
          size='md'
          title={t('add_deck')}
          variant='solid'
          colorScheme='blue'
          onClick={onDeckPress}
        />
      </Authorize>

      <Box width='210px' zIndex='100'>
        <Dropdown
          placeholder={t('select_filter')}
          value={selectedSortValue}
          onChange={(e) => setSelectedSortValue(e)}
          options={[
            { label: t('filter.last_updated'), value: 'Last Updated' },
            { label: t('filter.last_created'), value: 'Last Created' },
            { label: t('filter.alphabetically'), value: 'Alphabetically' },
            { label: t('filter.active'), value: 'Active' },
            { label: t('filter.inactive'), value: 'Inactive' },
          ]}
          selectStyles={{
            singleValue: {
              color: '#6f767e',
              fontWeight: '600',
            },
          }}
          components={{
            SingleValue: (valProps) => {
              return (
                <chakraComponents.SingleValue {...valProps}>
                  {t('sort_by', {
                    value: valProps.children,
                  })}
                </chakraComponents.SingleValue>
              );
            },
          }}
        />
      </Box>
      <ToggleButton viewType={viewType} onViewChange={onViewChange} />
    </Flex>
  );
};

export default AddCardDeckButton;

import React, {
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
} from 'react';
import {
  Box,
  chakra,
  Flex,
  useCheckbox,
  UseCheckboxProps,
  Text,
} from '@chakra-ui/react';

interface IProps extends UseCheckboxProps {}

const CustomCheckBox = forwardRef<unknown, PropsWithChildren<IProps>>(
  (props, ref) => {
    const {
      state,
      getCheckboxProps,
      getInputProps,
      htmlProps,
      getLabelProps,
      getRootProps,
    } = useCheckbox(props);

    useImperativeHandle(ref, () => {}, []);

    return (
      <chakra.label
        display='inline-flex'
        flexDirection='row'
        gridColumnGap={2}
        cursor='pointer'
        alignItems='center'
        {...htmlProps}
        {...getRootProps()}
        _disabled={{
          cursor: 'not-allowed',
        }}
      >
        <input {...getInputProps()} hidden />
        <Flex
          alignItems='center'
          justifyContent='center'
          border='2px solid'
          borderColor='#999999'
          borderRadius='full'
          w={4}
          minW={4}
          h={4}
          {...getCheckboxProps()}
          _checked={{
            borderColor: 'blue.500',
          }}
          _invalid={{
            borderColor: 'red.500',
          }}
          _disabled={{
            background: 'gray.100',
            borderColor: 'gray.100',
          }}
        >
          {state.isChecked && (
            <Box w={2} h={2} borderRadius='full' bg='blue.500' />
          )}
        </Flex>

        {props.children && (
          <Text
            as='span'
            color='gray.700'
            {...getLabelProps()}
            _disabled={{
              opacity: 0.4,
            }}
          >
            {props.children}
          </Text>
        )}
      </chakra.label>
    );
  }
);

CustomCheckBox.displayName = 'Custom Checkbox';

export default CustomCheckBox;

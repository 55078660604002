import { gql } from "@apollo/client";

export const REQUEST_REPORT = gql`
    mutation RequestReport($input: NewReportTypeInput) {
        RequestReport(input: $input) {
            reportName
            reportType
            entityId
            requestedBy
            status
            reportUrl {
                fileName
                fileUrl
                _id
            }
            startDate
            endDate
            notes
            createdAt
            updatedAt
            eid
            requestedByUser {
            eid
            name
            }
        }
    }
`;

export const GET_REPORTS = gql`
    query ReportPagination($page: Int, $perPage: Int, $filter: FilterFindManyUserFormsReposonseLogInput) {
        ReportPagination(page: $page, perPage: $perPage, filter: $filter) {
            count
            pageInfo {
                currentPage
                perPage
                pageCount
                itemCount
                hasNextPage
                hasPreviousPage
            }
            items {
                reportName
                reportType
                entityId
                requestedBy
                status
                reportUrl {
                    fileName
                    fileUrl
                    _id
                }
                startDate
                endDate
                notes
                createdAt
                updatedAt
                eid
            }
        }
    }
`;
import React from 'react';
import { useMutation, gql } from '@apollo/client';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { iconObj } from '../../utils/iconHelper';

import { GET_NOTIFICATION } from './NotificationList';
import { GET_NOTIFICATION_COUNT } from 'ui-components/MainContainer/MainContainer';
interface Props {
  notificationItem: any;
  onNotificationCountRefetch?: any;
}

const SEEN_NOTIFICATION = gql`
  mutation ($eid: String!) {
    seenNotification(eid: $eid) {
      subject
      activityId
      eid
    }
  }
`;
const NotificationItem = ({ notificationItem }: Props) => {
  const [seenNotification] = useMutation(SEEN_NOTIFICATION, {
    refetchQueries: [
      {
        query: GET_NOTIFICATION,
        variables: {
          page: 1,
          perPage: 50,
        },
      },
      {
        query: GET_NOTIFICATION_COUNT,
      },
    ],
    awaitRefetchQueries: true,
  });
  const isSeen = notificationItem?.isSeen?.status;
  const onUpdateSeenStatus = () => {
    if (!isSeen) {
      seenNotification({
        variables: {
          eid: notificationItem?.eid,
        },
      });
    }
  };

  return (
    <Link
      onClick={onUpdateSeenStatus}
      to={`/sop/${notificationItem?.activityId}`}
    >
      <div className='notification-parent-item-container'>
        <div className='notification-item-container'>
          <div
            className='notification-item-icon-container'
            style={{
              backgroundColor: `${notificationItem?.sop?.thumbnail_color}95`,
              borderColor: notificationItem?.sop?.thumbnail_color,
            }}
          >
            {notificationItem?.sop?.thumbnail &&
              !iconObj[notificationItem?.sop?.thumbnail] && (
                <img
                  src={notificationItem?.sop?.thumbnail}
                  style={{ width: '15px' }}
                />
              )}
            {((notificationItem?.sop?.thumbnail &&
              iconObj[notificationItem?.sop?.thumbnail]) ||
              !notificationItem?.sop?.thumbnail) && (
              <img
                src={
                  iconObj[notificationItem?.sop?.thumbnail] ||
                  iconObj['checklist']
                }
                style={{ width: '40px' }}
              />
            )}
          </div>

          <div style={{ marginLeft: '15.6px' }}>
            <div>
              {/* {notificationItem?.form} */}
              {notificationItem?.formattedContent?.subject && (
                <>{parse(notificationItem?.formattedContent?.subject)} </>
              )}

              {notificationItem?.formattedContent?.body && (
                <>
                  {notificationItem?.type === 'UPDATE_SOP' && (
                    <span>
                      {' '}
                      Don&apos;t miss out,{' '}
                      <span style={{ color: '#4d8af0' }}>check it here</span>
                    </span>
                  )}
                  {notificationItem?.type === 'NEW_SOP' && (
                    <span>
                      , {parse(notificationItem?.formattedContent?.body)}
                    </span>
                  )}
                </>
              )}
            </div>

            {/* <div className='notification-item-time-container'>
              <TimeAgo date={notificationItem.createdAt} />
            </div> */}
          </div>
          {!isSeen && (
            <div className='notication-unread-parent-container'>
              <div className='notification-unread-container' />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default NotificationItem;

import { gql } from '@apollo/client';
import { IEmojiStructure, JSONContent } from 'delightree-editor';
import { SopType, VisibilityConfig } from '../../../types';
import { SopFile } from '../chapter.types';

export interface Content {
  text: string;
  type: 'tiptap';
  tiptap: JSONContent;
}

export interface SopExternalDetail
  extends Pick<VisibilityConfig, 'visibility'> {
  eid: string;
  title: string;
  content: Content[];
  createdByUser: {
    eid: string;
    name: string;
  };
  icon: IEmojiStructure;
  lastUpdated: string;
}

export const SOP_BY_ID_EXTERNAL = gql`
  query SopByIdExternal($eid: String!) {
    SopByIdExternal(eid: $eid) {
      eid
      title
      content

      createdByUser {
        eid
        name
      }
      icon
      visibility
      lastUpdated
    }
  }
`;

interface TrainingData {
  title: string;
}

export interface SopDetailData extends VisibilityConfig {
  eid: string;
  title: string;
  sopType: SopType;
  category: string;
  categoryId: string;
  content: Content[];
  createdBy: string;
  createdByUser: {
    eid: string;
    name: string;
  };
  files: SopFile[];
  icon: IEmojiStructure;
  lastUpdated: string;
  lastUpdatedByUser: {
    eid: string;
    name: string;
  };
  smartPageEnabled: boolean;
  trainings: TrainingData[];
  media?: {
    assetId: string;
    mediaUrl: string;
    meta: any;
    playbackId: string;
    rawUrl: string;
    status: string;
    thumbnail: string;
    type: string;
  }[];
}

export interface SopDetailResponse {
  SopById: SopDetailData;
}

export const SOP_ITEM_DETAILS = gql`
  query SopItems($eid: String!, $override: Boolean) {
    SopById(eid: $eid, override: $override) {
      eid
      title
      sopType
      category
      categoryId
      content
      raw_content
      createdBy
      createdByUser {
        eid
        name
      }
      files {
        fileSize
        mimetype
        name
        type
        url
      }
      icon
      media {
        assetId
        mediaUrl
        meta
        playbackId
        rawUrl
        status
        thumbnail
        type
      }
      visibility
      visibleTo {
        users
        locations
        roles
        condition
        _id
      }
      entityId
      status
      viewCount
      lastViewed
      lastUpdated
      lastUpdatedByUser {
        eid
        name
      }
      category
      thumbnail
      smartPageEnabled
      visibility
      defaultLanguage
      createdAt
      updatedAt
      trainings {
        title
      }
    }
  }
`;

export const UPDATE_VIEW = gql`
  mutation SetSopView($sopId: String!) {
    setSopView(sopId: $sopId) {
      eid
    }
  }
`;

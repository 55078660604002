import { FC } from 'react';
import DashboardContainer from 'sub-components/DashboardContainer';
import FormList from './form-list';
import FormDataProvider from './FormStore/FormDataProvider';

const FormListRootContainer: FC = () => {
  return (
    <DashboardContainer>
      <FormDataProvider>
        <FormList />
      </FormDataProvider>
    </DashboardContainer>
  );
};

export default FormListRootContainer;

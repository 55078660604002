import React, { Component } from 'react';
import { Box } from '@chakra-ui/react';
import { CometChat } from '@cometchat-pro/chat';

import { CometChatConversationListItem } from '../../../Chats';
import { CometChatContext } from '../../../../util/CometChatContext';
import * as enums from '../../../../util/enums';

class ArchivedList extends Component {
  loggedInUser = null;

  static contextType = CometChatContext;

  constructor(props) {
    super(props);

    CometChat.getLoggedinUser()
      .then((user) => (this.loggedInUser = user))
      .catch((error) => console.log(error));
  }

  handleClick = (conversation) => {
    if (!this.props.onItemClick) return;
    this.props.onItemClick(
      conversation.conversationWith,
      conversation.conversationType
    );
  };

  actionHandler = (action, conversation) => {
    switch (action) {
      case enums.ACTIONS['CONVERSATION_DELETED']:
        // this.conversationDeleted(conversation);
        break;
      case enums.ACTIONS['DELETE_CONVERSATION']:
        // this.deleteConversation(conversation);
        break;
      default:
        break;
    }
  };

  handleScroll = (e) => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) ===
      Math.round(e.currentTarget.clientHeight);
    if (bottom) this.props.getConversations();
  };

  render() {
    const archivedIds = this.props.archivedIds || [];

    const conversationList = this.props.conversations.map(
      (conversation, key) => {
        if (!archivedIds?.includes(conversation.conversationWith?.uid)) {
          return null;
        }

        return (
          <CometChatConversationListItem
            key={conversation.conversationId}
            conversation={conversation}
            loggedInUser={this.loggedInUser}
            handleClick={this.handleClick}
            actionGenerated={this.actionHandler}
            selectedConversation={this.context.item}
          />
        );
      }
    );

    return (
      <Box flex={1} overflowY='auto' onScroll={this.handleScroll}>
        {conversationList}
      </Box>
    );
  }
}

export default ArchivedList;

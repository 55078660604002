import React, { FC, ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';

import { ReactComponent as SingleTextLayoutIcon } from '../../../assets/images/singleTextLayout.svg';
import { ReactComponent as SingleTextLayoutSelectedIcon } from '../../../assets/images/singleTextLayout_selected.svg';

import { ReactComponent as RichTextLayoutIcon } from '../../../assets/images/richTextLayout.svg';
import { ReactComponent as RichTextLayoutSelectedIcon } from '../../../assets/images/richTextLayout_selected.svg';
import styled from '@emotion/styled';
import { TextLayout } from 'sop-commons/src/utils/cardLayouts';
import { CardDefaultValues, CardFormInput } from '../card-editor.types';
import { useFormContext } from 'react-hook-form';
import { cardJsonFormat } from '../../../utils';
import { useLayoutDisabled } from '../hook';

const StyledFlex = styled(Flex)<{
  isDisabled?: boolean;
}>`
  max-width: max-content;
  ${({ isDisabled }) => {
    if (isDisabled) {
      return {
        cursor: 'not-allowed',
        svg: {
          'pointer-events': 'none',
        },
      };
    }
    return {};
  }}

  svg {
    cursor: pointer;
  }
`;

interface LayoutProps {
  isSelected?: boolean;
  svgIcon?: ReactNode;
  selectedSvgIcon?: ReactNode;
  onSelect?: () => void;
}

const Layout: FC<LayoutProps> = ({
  svgIcon,
  isSelected,
  selectedSvgIcon,
  onSelect,
}) => {
  if (isSelected) {
    return <>{selectedSvgIcon}</>;
  }

  // @ts-ignore
  return React.cloneElement(svgIcon, {
    onClick: onSelect,
  });
};

interface IProps {
  layout: TextLayout;
  onSelectLayout: (layout: TextLayout) => void;
}

const TextLayoutSelect: FC<IProps> = ({ layout, onSelectLayout }) => {
  const { getValues, reset } = useFormContext<CardFormInput>();
  const isDisabled = useLayoutDisabled();

  function onSelect(value: TextLayout) {
    const defaultData: CardFormInput = {
      ...CardDefaultValues,
      entityId: getValues('entityId'),
      status: getValues('status'),
      cardType: getValues('cardType'),
      layout: value,
      backgroundColor: getValues('backgroundColor'),
      title: getValues('title'),
      category: getValues('category'),
    };

    if (getValues('language')) {
      defaultData.language = getValues('language');
    }

    if (getValues('defaultLanguage')) {
      defaultData.defaultLanguage = getValues('defaultLanguage');
      defaultData.translations = getValues('translations');
    }

    // switch (value) {
    //   case TextLayout.SIMPLE_TEXT:
    //     break;
    //   case TextLayout.RICH_TEXT:
    //     break;
    // }
    onSelectLayout(value);
    reset(defaultData);
  }

  return (
    <StyledFlex align='center' mt='10px' isDisabled={isDisabled}>
      <Layout
        isSelected={layout === TextLayout.SIMPLE_TEXT}
        svgIcon={<SingleTextLayoutIcon />}
        selectedSvgIcon={<SingleTextLayoutSelectedIcon />}
        onSelect={() => onSelect(TextLayout.SIMPLE_TEXT)}
      />
      <Layout
        isSelected={layout === TextLayout.RICH_TEXT}
        svgIcon={<RichTextLayoutIcon />}
        selectedSvgIcon={<RichTextLayoutSelectedIcon />}
        onSelect={() => onSelect(TextLayout.RICH_TEXT)}
      />
    </StyledFlex>
  );
};

export default TextLayoutSelect;

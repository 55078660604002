import { HStack } from '@chakra-ui/react';
import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import StatusBadge from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/Top/StatusBadge';
import React, { FC } from 'react';
import { faClockEight } from '@fortawesome/pro-regular-svg-icons';
import { HeaderColors } from 'shared/headerColors/header-colors';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  selectedTaskData: CustomLauncherTask;
}

const TaskDurationData: FC<IProps> = ({ selectedTaskData }) => {
  console.log('selected task data : ', selectedTaskData);

  const labelColorRender = () => {
    switch (selectedTaskData?.taskStatus) {
      case 'complete':
        return '#83BF6E';
      case 'inProgress':
        return '#2A85FF';
      case 'overdue':
        return '#FF6A55';
      default:
        return '#2A85FF';
    }
  };

  const bgRender = () => {
    switch (selectedTaskData?.taskStatus) {
      case 'complete':
        return '#B5E4CA33';
      case 'inProgress':
        return '#2A85FF1A';
      case 'overdue':
        return '#FF6A5533';
      default:
        return '#2A85FF1A';
    }
  };

  return (
    <HStack gap='12px' align='center' w='full'>
      <StatusBadge
        label={
          selectedTaskData?.taskStatus === 'complete'
            ? 'Completed'
            : selectedTaskData?.formattedDuration
        }
        labelProps={{
          color: labelColorRender(),
          fontWeight: 700,
          fontSize: '14px',
        }}
        boxProps={{
          bg: bgRender(),
          borderRadius: '5px',
        }}
        leftIcon={faClockEight as IconProp}
        leftIconProps={{
          color: labelColorRender(),
          fontSize: '16px',
        }}
      />
      <StatusBadge
        label={selectedTaskData?.categoryName!}
        labelProps={{ color: '#6F767E', fontWeight: 700, fontSize: '14px' }}
        boxProps={{
          bg: '#F4F4F4',
          borderRadius: '5px',
        }}
      />
    </HStack>
  );
};

export default TaskDurationData;

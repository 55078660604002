import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, FC } from 'react';
import { useState } from 'react';
import TrainingCard from 'sub-components/TrainingCard';
import { userObj } from 'sop-commons/src/client/clientFactory';
import CardEditor from '../../sub-components/CardEditor';
import DashboardContainer from '../../sub-components/DashboardContainer';
import { cardJsonFormat, useRouter } from '../../utils';

import './CreateTrainingCard.scss';
import { getCardById } from 'sop-commons/Query/Search';
import { cloneDeep } from 'lodash';
// const content = {
//   cardType: 'text',
//   layout: 'simple-text',
//   title: '',
//   backgroundColor: '#FFBC99',
//   content: [
//     {
//       type: 'text',
//       attrs: {
//         headingLevel: 4,
//         textAlign: 'center',
//       },
//       text: 'Must be kept maintained and clean, free of dirt or debris. Nail polish is limited to natural colors and may not be more than 1⁄2',
//     },
//   ],
// };

// const tiptapContent = {
//   cardType: 'text',
//   layout: 'rich-text',
//   title: '',
//   // backgroundColor: '#FFBC99',
//   content: [
//     {
//       type: 'tiptap',
//       attrs: {
//         headingLevel: 1,
//         textAlign: 'center',
//       },
//       tiptap: {
//         type: 'doc',
//         content: [
//           {
//             type: 'paragraph',
//             attrs: { textAlign: 'left' },
//             content: [
//               { type: 'text', marks: [{ type: 'bold' }], text: 'Jewellery' },
//             ],
//           },
//           {
//             type: 'paragraph',
//             attrs: { textAlign: 'left' },
//             content: [
//               {
//                 type: 'text',
//                 text: 'Team members are limited to wearing no more than two (2) earrings per ear and may not be large hoop earrings. Necklaces must be worn under shirts. Watches and a single ring on each hand may be worn. Bracelets should be removed before starting a shift. Facial jewelry and tongue rings must be removed before the start of a shift.',
//               },
//             ],
//           },
//           {
//             type: 'taskList',
//             content: [
//               {
//                 type: 'taskItem',
//                 attrs: { checked: false },
//                 content: [
//                   {
//                     type: 'paragraph',
//                     attrs: { textAlign: 'left' },
//                     content: [
//                       { type: 'text', text: 'Clean sinks and countertops' },
//                     ],
//                   },
//                 ],
//               },
//               {
//                 type: 'taskItem',
//                 attrs: { checked: false },
//                 content: [
//                   {
//                     type: 'paragraph',
//                     attrs: { textAlign: 'left' },
//                     content: [
//                       { type: 'text', text: 'Clean sinks and countertops' },
//                     ],
//                   },
//                 ],
//               },
//               {
//                 type: 'taskItem',
//                 attrs: { checked: false },
//                 content: [
//                   {
//                     type: 'paragraph',
//                     attrs: { textAlign: 'left' },
//                     content: [
//                       { type: 'text', text: 'Clean sinks and countertops' },
//                     ],
//                   },
//                 ],
//               },
//             ],
//           },
//         ],
//       },
//     },
//   ],
// };

// const imageContent = {
//   cardType: 'image',
//   layout: 'single-image',
//   title:
//     'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
//   content: [
//     {
//       type: 'image-list',
//       attrs: {
//         layout: 'single-image',
//       },
//       'image-list': [
//         {
//           type: 'image',
//           attrs: {},
//           image:
//             'https://images.unsplash.com/photo-1555939594-58d7cb561ad1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
//         },
//       ],
//     },
//   ],
// };

// const splitImageContent = {
//   cardType: 'image',
//   layout: 'horizontal-split-second-column',
//   title:
//     'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
//   raw_content:
//     'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
//   thumbnail: 'thumbnail',
//   content: [
//     {
//       type: 'image',
//       attrs: {
//         layout: 'quadrant',
//       },
//       image: [
//         'https://sop-uploads-staging.s3.amazonaws.com/mja98k5r6mlbc25nmaek5pom/1654172446238_151_0002.png',
//         'https://sop-uploads-staging.s3.amazonaws.com/mja98k5r6mlbc25nmaek5pom/1654172446238_151_0002.png',
//       ],
//     },
//   ],
// };

interface IProps {
  isEdit?: boolean;
}

const CreateTrainingCard: FC<IProps> = ({ isEdit }) => {
  const router: any = useRouter();
  const cardId = router?.match?.params?.cardId;
  const [cardData, setCardData] = useState(null);
  const [selectedCardType, setSelectedCardType] = useState({
    label: 'Text',
    value: 'text',
  });

  // const [selectedLayout, setSelectedLayout] = useState('simple-text');
  // const [selectedColor, setSelectedColor] = useState('#ffffff');

  const userData: any = useReactiveVar(userObj);

  const [findCardById, { data, error }] = useLazyQuery(getCardById, {
    onCompleted: (response) => {
      setSelectedCardType({
        label: response?.findCardById?.cardType,
        value: response?.findCardById?.cardType,
      });
      let cardData = cloneDeep(response?.findCardById);
      console.log('Find Card By id', cardData);
      setCardData({ ...cardData });
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (isEdit) {
      findCardById({
        variables: {
          eid: cardId,
        },
      });
    }
  }, [cardId]);

  useEffect(() => {
    if (!isEdit) {
      const entityId = userData?.entity?.eid;
      setCardData({
        ...cardJsonFormat(
          selectedCardType?.value,
          'simple-text',
          entityId,
          '#ffffff'
        ),
      });
    }
  }, []);

  return (
    <DashboardContainer>
      <div className='settings-title-wrapper'>
        {isEdit && <div className='settings-title-container'>Update card</div>}
        {!isEdit && <div className='settings-title-container'>Create card</div>}
      </div>
      <div style={{ backgroundColor: '#fff', borderRadius: '15px' }}>
        <div style={{ display: 'flex', padding: '40px' }}>
          <div
            style={{
              flex: '1 1 auto',
              marginRight: '74px',
              width: 'calc(100% - 366px)',
            }}
          >
            <CardEditor
              selectedCardType={selectedCardType}
              setSelectedCardType={setSelectedCardType}
              // selectedLayout={selectedLayout}
              // setSelectedLayout={setSelectedLayout}
              // selectedColor={selectedColor}
              // setSelectedColor={setSelectedColor}
              cardData={cardData}
              setCardData={setCardData}
              entityId={userData?.entity?.eid}
              isEdit={isEdit}
              cardId={cardId}
              // on={getImage}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='preview-tag-container'></div>
              <div className='preview-title-container'>Preview</div>
            </div>
            <div className='create-card-container'>
              <TrainingCard content={cardData} isPreview />
            </div>
          </div>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default CreateTrainingCard;

import React, { CSSProperties, FC } from 'react';
import {
  Box,
  Center,
  Checkbox,
  Flex,
  ListItem,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { UserTaskProgress } from 'types';
import { IconImage } from 'ui-components';

import { ITaskData } from './task-details.graphql';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface IProps {
  content: ITaskData['taskItems'][number];
  userProgress?: UserTaskProgress;
  onClick?: (data: ITaskData['taskItems'][number]) => void;
}

const TaskDetailItem: FC<IProps> = ({ content, userProgress, onClick }) => {
  const { t } = useTranslation(['task']);

  const status = userProgress?.completedTasks?.find(
    (task) => content?.eid === task?.contentId
  );

  const isForm = content.type === 'form' && onClick;

  const _onClick = () => {
    if (content.type === 'form' && status?.isCompleted) {
      onClick?.(content);
    }
  };

  return (
    <ListItem
      display='flex'
      gap='16px'
      alignItems='center'
      cursor={isForm ? 'pointer' : undefined}
      onClick={isForm ? _onClick : undefined}
    >
      <Checkbox
        isChecked={status?.isCompleted}
        colorScheme='green'
        isReadOnly
      />

      <Flex
        flex={1}
        overflow='hidden'
        flexDir='column'
        fontWeight='600'
        gap={1}
      >
        {status?.isCompleted ? (
          <Box fontSize='12px' color='#83BF6E'>
            {moment(status.completedAt).format('DD MMMM YYYY, hh:mm A')}
          </Box>
        ) : (
          <Box fontSize='12px' color='#979EA6' fontWeight='semibold'>
            {t('task:notStarted')}
          </Box>
        )}

        <Tooltip
          hasArrow
          borderRadius='4px'
          padding='6px 10px'
          label={content.title}
        >
          <Box fontSize='15px' color='#1A1D1F' maxW='fit-content' isTruncated>
            {content.title}
          </Box>
        </Tooltip>
      </Flex>

      {content.type === 'todo' ? (
        <Box boxSize='48px' />
      ) : (
        <IconImage
          boxSize={48}
          icon={content.icon}
          name={content.title}
          thumbnail={content.thumbnail}
          iconFontSize='30px'
        />
      )}
    </ListItem>
  );
};

export default TaskDetailItem;

import React, { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { QuizLayout } from 'sop-commons/src/utils/cardLayouts';

import FormInput from '../../../atoms/FormInput';
import TitleHeader from '../TitleHeader';
import '../CardEditor.scss';
import { CardFormInput } from '../card-editor.types';
import AnswerTypeSelect from './AnswerTypeSelect';
import QuizOptionList from './QuizOptionList';

export interface QuizOptionData {
  id: string;
  text: string;
  inputId: string;
}

const QuizCardEditor: FC = () => {
  const { t } = useTranslation('card');
  const { control, setValue, getValues } = useFormContext<CardFormInput>();
  const isReadOnly = useWatch<CardFormInput>({ name: 'isReadOnly' });

  // useEffect(() => {
  //   if (!isDirty && cardData?.content?.[0]?.choices && isEdit) {
  //     setAnswerOptionsList([...cardData?.content?.[0]?.choices]);
  //   }
  // }, [isDirty, isEdit]);

  function handleClickCorrect(optionId: string) {
    const layout = getValues('layout') as QuizLayout;
    if (layout === QuizLayout.SINGLE_CHOICE) {
      setValue('correctAnswer', [optionId]);
    } else {
      const newAnswer = getValues('correctAnswer') || [];
      const currentIndex = newAnswer.indexOf(optionId);
      if (currentIndex === -1) {
        newAnswer.push(optionId);
      } else {
        newAnswer.splice(currentIndex, 1);
      }
      setValue('correctAnswer', newAnswer);
    }
  }

  return (
    <div className='quizCardContainer'>
      <div>
        <TitleHeader
          title={t('question')}
          desc={t('question_desc')}
          titleStyle={{ marginTop: '30px' }}
          isRequired
        />

        <Controller
          control={control}
          name='title'
          rules={{
            required: t('validation.question_required'),
            validate: (value) => {
              if (value?.trim()?.length === 0) {
                return t('validation.question_enter');
              }
            },
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={3} isInvalid={!!fieldState.error}>
                <FormInput
                  id='title'
                  size='lg'
                  isDisabled={isReadOnly}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    setValue('raw_content', event.target.value);
                  }}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </div>
      <div style={{ marginTop: '38px' }}>
        <TitleHeader
          title={t('answer_type')}
          desc={t('answer_type_desc')}
          isRequired
        />
        <Controller
          name={'layout'}
          control={control}
          rules={{
            required: t('validation.answer_select'),
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={3} isInvalid={!!fieldState.error}>
                <AnswerTypeSelect
                  value={field.value as QuizLayout}
                  isReadOnly={isReadOnly}
                  onChange={field.onChange}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </div>

      <QuizOptionList
        handleClickCorrect={handleClickCorrect}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};

export default QuizCardEditor;

import React, { CSSProperties, FC, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { MessageButton } from '../../../../atoms';
import { IconsPlaceholder, Image } from '../../../../ui-components';

import { UserResponse } from './perfor-detail.graphql';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

interface IProps {
  data: UserResponse['userById'];
  openChat?: (userId: string) => void;
}

const UserDetail: FC<IProps> = ({ data, openChat }) => {
  const { t } = useTranslation(['common', 'task']);

  const calcAuthRole = useMemo(() => {
    return data?.authRole?.split(/(?=[A-Z])/).join(' ');
  }, [data?.authRole]);

  const location = useMemo(() => {
    return data?.locations?.[0]?.name;
  }, [data?.locations]);

  return (
    <Flex flex={1} maxW='360px' flexDir='column' gap='16px'>
      <Flex gap={4} align='center'>
        <Image
          src={data.profilePic || IconsPlaceholder[data.type]}
          width={60}
          height={60}
          style={{
            height: '60px',
            width: '60px',
            minWidth: '60px',
            borderRadius: '10px',
          }}
        />

        <Flex flexDirection='column' gap='6px' overflow='hidden'>
          <Box
            fontSize='16px'
            fontWeight='600'
            color='#1A1D1F'
            style={wrapStyles}
          >
            {data.name}
          </Box>
          <Flex
            gap='5px'
            align='center'
            color='#92929D'
            fontSize='12px'
            fontWeight='semibold'
            lineHeight='18px'
          >
            {data.type === 'branch' ? (
              <Box>{data.username}</Box>
            ) : (
              <>
                <Box textTransform='capitalize'>{calcAuthRole}</Box>

                <Box fontSize='16px' color='#D9D9D9'>
                  &bull;
                </Box>

                <Box>{data.role}</Box>

                <Box fontSize='16px' color='#D9D9D9'>
                  &bull;
                </Box>

                <Box>{location}</Box>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Flex align='center' gap={2}>
        <FontAwesomeIcon
          icon={(data.type === 'branch' ? faPhone : faEnvelope) as IconProp}
          color='#6F767E'
          size='lg'
        />

        <Box fontWeight='500' color='#6F767E' style={wrapStyles} pb='2px'>
          {data.type === 'branch' ? data.phone : data.email}
        </Box>
      </Flex>

      <MessageButton
        size='lg'
        colorScheme='blue'
        onClick={() => openChat?.(data?.eid)}
      />
    </Flex>
  );
};

export default UserDetail;

import React, { FC } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { forwardRef } from '@chakra-ui/system';

const SelectButton: FC<ButtonProps> = forwardRef<ButtonProps, 'button'>(
  (props, ref) => {
    return (
      <Button
        width='full'
        fontSize='14px'
        fontWeight='500'
        borderRadius='12px'
        justifyContent='space-between'
        rightIcon={<ChevronDownIcon fontSize='20px' />}
        variant='solid'
        colorScheme='blue'
        // onClick={onClick}
        ref={ref}
        // isDisabled={disabled}
        {...props}
      />
    );
  }
);

export default SelectButton;

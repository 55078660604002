import React, { FC } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import CardDeckInfoTooltip from '../../sub-components/CardDeckInfoTooltip';
import TrainingCard from '../../sub-components/TrainingCard';
import { ReactComponent as CardPreviewEmpty } from '../../assets/images/cardPreview.svg';
import { CardDetailModal } from '../../modal';
import { CardDetailEntity } from '../../types';

interface IProps {
  cardSelected: CardDetailModal<CardDetailEntity>;
  isLoading?: boolean;
  totalCard?: number;
}

export const HandbookDeckRight: FC<IProps> = ({
  cardSelected,
  totalCard,
  isLoading,
}) => {
  const { t } = useTranslation('handbook');

  if (!isLoading && totalCard === 0) {
    return null;
  }

  return (
    <Flex
      direction='column'
      maxW='292px'
      minW='292px'
      mt='20px'
      ml='12px'
      height='800px'
    >
      <Flex
        align='center'
        fontSize='18px'
        fontWeight='900'
        justify='space-between'
      >
        <Flex gap='20px' align='center'>
          <Box h='32px' w='16px' borderRadius='4px' bg='#B1E5FC' />
          {t('deck_section.card_preview_heading')}
        </Flex>
        {cardSelected && (
          <div>
            <CardDeckInfoTooltip
              details={cardSelected}
              placement='bottom-start'
            />
          </div>
        )}
      </Flex>
      {!cardSelected ? (
        <Center flexDir='column' textAlign='center' mt='30px'>
          <CardPreviewEmpty />
          <div style={{ fontSize: 16, fontWeight: '800' }}>
            {t('deck_section.no_card_to_preview_title')}
          </div>
          <div style={{ fontSize: 12, fontWeight: '400' }}>
            <Trans i18nKey='handbook:deck_section.select_any_card_description' />
          </div>
        </Center>
      ) : (
        <div
          style={{
            overflow: 'hidden',
            borderRadius: 10,
            marginTop: 30,
            height: 'auto',
            flex: '1 1 auto',
            marginBottom: '40px',
            boxShadow: '0px 4px 22px rgb(0 0 0 / 9%)',
          }}
        >
          <TrainingCard content={cardSelected} />
        </div>
      )}
    </Flex>
  );
};

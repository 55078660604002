import { gql } from '@apollo/client';
import * as yup from 'yup';

export const UPDATE_PHONE = gql`
  mutation UpdateUserPhone($input: UserUpdateInput) {
    updateUser(input: $input) {
      eid
      phone
    }
  }
`;

export const validationSchema = yup.object().shape({
  phoneNumber: yup.string().when('isReadOnly', {
    is: (val) => !val,
    then: () =>
      yup
        .string()
        .trim()
        .required('Mobile number is required')
        .matches(/^\+[^+]*$/, 'Please enter country code'),
    otherwise: () => yup.string(),
  }),
});

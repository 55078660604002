import React, { CSSProperties, FC, ReactChild } from 'react';
import { Box, Flex, FlexProps, IconButton, Spacer } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import Image from '../../../../ui-components/Image';
import Container from './Container';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export interface FormStepperProps
  extends Pick<FlexProps, 'paddingX' | 'paddingY'> {
  title?: ReactChild;
  caption?: ReactChild;
  actionButton?: ReactChild;
  imageUrl: string;
  isCompleted?: boolean;
  onClick?: () => void;
}

const BaseFormStepper: FC<FormStepperProps> = ({
  title,
  caption,
  imageUrl,
  isCompleted,
  onClick,
  actionButton,
  paddingX,
  paddingY,
}) => {
  return (
    <Container gap='16px' paddingX={paddingX} paddingY={paddingY}>
      <Image
        src={imageUrl}
        height={60}
        width={60}
        style={{
          borderRadius: 8,
        }}
      />
      <Flex flexDir='column' gap={0.5} justify='center' overflow='hidden'>
        <Box
          fontSize='16px'
          fontWeight='600'
          color='#000000'
          style={typeof title === 'string' ? wrapStyles : undefined}
        >
          {title}
        </Box>
        <Box
          fontSize='12px'
          fontWeight='400'
          color='#6F767E'
          style={typeof caption === 'string' ? wrapStyles : undefined}
        >
          {caption}
        </Box>
      </Flex>
      <Spacer />

      {actionButton || (
        <IconButton
          aria-label='action'
          borderRadius='full'
          bg='#BBBBBB'
          size='xs'
          alignSelf='center'
          icon={
            <FontAwesomeIcon
              size={isCompleted ? '1x' : 'lg'}
              icon={(isCompleted ? faPen : faPlus) as IconProp}
              color='white'
            />
          }
          onClick={onClick}
        />
      )}
    </Container>
  );
};

export default BaseFormStepper;

import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { cloneDeep } from '@apollo/client/utilities';
import { IFormInput } from './form.types';
import TrainingCard from '../../TrainingCard';
import { formDataFormat } from './formDataFormat';

interface IProps {}

const MobilePreview: FC<IProps> = () => {
  const [data, setData] = useState<IFormInput>();

  const { watch } = useFormContext<IFormInput>();

  const debouncedSetData = debounce((newValues) => {
    const _newValues = formDataFormat(cloneDeep(newValues));
    setData((prevState) => {
      return {
        ...prevState,
        ..._newValues,
        category: newValues?.formCategory?.name,
      };
    });
  }, 1);

  useEffect(() => {
    const subscribe = watch((value) => {
      debouncedSetData(value);
    });

    return () => subscribe.unsubscribe();
  }, [watch]);

  return (
    <Box
      width='300px'
      mt='30px'
      boxShadow='0px 4px 20px rgba(0, 0, 0, 0.1)'
      borderRadius='16px'
      minH='600px'
    >
      <TrainingCard
        content={{
          cardType: 'formCard',
          content: [{ ...data }],
        }}
        isPreview
      />
    </Box>
  );
};

export default MobilePreview;

import { gql } from '@apollo/client';

// export const ROLES_MUTATION = gql`
//   mutation MoveUsersToRole($input: moveUsersTypeInput) {
//     moveUsersToRole(input: $input) {
//       succeed
//     }
//   }
// `;

export const ROLES_MUTATION = gql`
  mutation MoveUsersToRole(
    $deleteRole: String!
    $moveToRole: String!
    $userIds: [String!]
  ) {
    moveUsersToRole(
      deleteRole: $deleteRole
      moveToRole: $moveToRole
      userIds: $userIds
    ) {
      succeed
    }
  }
`;

export const ADD_ROLE = gql`
  mutation AddRole($eid: String!, $name: String!, $color: String) {
    addRole(eid: $eid, name: $name, color: $color) {
      eid
    }
  }
`;

export const DELETE_ROLE_ANYWAY = gql`
  mutation DeleteRoleWithUsers($role: String!) {
    deleteRoleWithUsers(role: $role) {
      succeed
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole($oldRole: String!, $newRole: String!) {
    updateRole(oldRole: $oldRole, newRole: $newRole) {
      succeed
    }
  }
`;

import { Box, Divider, Flex, useOutsideClick } from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  IDateFilterOptions,
  IDateFilterProps,
  IFilterOptionState,
} from './types';
import {
  DateFilterPlaceholder,
  DateFilterOptions,
  DateFilterCustomRange,
} from './components';
import { v4 as uuidv4 } from 'uuid';

const DateFilter: FC<IDateFilterProps> = ({
  customCalendarContainerStyles,
  hideDropdown = false,
  placeholder = 'Filter by date',
  placeholderLeftIcon,
  placeholderRightIcon,
  isReadOnly = false,
  filterOptions,
  showCustom = true,
  showCalendar,
  selectedDateFilter,
  onFilterSelection,
  setSelectedDateFilter,
}) => {
  const [internalSelectedDateFilter, setInternalSelectedDateFilter] = useState<
    IDateFilterOptions | undefined
  >(undefined);
  const [filterOptionState, setFilterOptionState] =
    useState<IFilterOptionState>({
      showFilterOptions: false,
      showCustomRange: false,
    });
  const filterRef = useRef(null);

  useEffect(() => {
    setInternalSelectedDateFilter(selectedDateFilter);
  }, [selectedDateFilter]);

  useOutsideClick({
    ref: filterRef,
    handler: () =>
      setFilterOptionState({
        ...filterOptionState,
        showFilterOptions: false,
        showCustomRange: false,
      }),
  });

  const onClickHandler = () => {
    setFilterOptionState({
      ...filterOptionState,
      showFilterOptions: true,
      showCustomRange: false,
    });
  };

  const filterSelectionHandler = (filter: IDateFilterOptions | undefined) => {
    setInternalSelectedDateFilter(filter);
  };

  const customRangeSetHandler = (from: moment.Moment, to: moment.Moment) => {
    onFilterSelection({ from, to });
    setFilterOptionState({
      showFilterOptions: false,
      showCustomRange: false,
    });
    setSelectedDateFilter({
      ...internalSelectedDateFilter,
      description: `${from.format("DD MMM' YY")} - ${to.format("DD MMM' YY")}`,
      range: { from, to },
    });
  };

  const resetFilterHandler = () => {
    setFilterOptionState({
      showFilterOptions: false,
      showCustomRange: false,
    });
    setSelectedDateFilter(undefined);
    onFilterSelection(undefined);
  };

  return (
    <Flex position='relative' ref={filterRef} flexDir='column'>
      {!hideDropdown && (
        <DateFilterPlaceholder
          placeholder={placeholder}
          leftIcon={placeholderLeftIcon}
          rightIcon={placeholderRightIcon}
          selectedValue={selectedDateFilter?.description}
          onClick={onClickHandler}
          resetFilterHandler={resetFilterHandler}
        />
      )}
      {(filterOptionState?.showFilterOptions || showCalendar) && (
        <Flex
          id='date-filter-container'
          gap={2}
          bg='white'
          w='580px'
          top='45px'
          right='-120px'
          position='absolute'
          zIndex={2}
          p={4}
          h='450px'
          border='1px solid rgba(239, 239, 239, 1)'
          boxShadow='8.96px 3.36px 24.64px 11.2px rgba(150, 150, 150, 0.11)'
          borderRadius='20px'
          style={customCalendarContainerStyles}
        >
          <DateFilterOptions
            internalSelectedDateFilter={internalSelectedDateFilter}
            filterSelectionHandler={filterSelectionHandler}
          />
          <Box>
            <Divider orientation='vertical' />
          </Box>
          <DateFilterCustomRange
            customRangeSetHandler={customRangeSetHandler}
            selectedDateFilter={internalSelectedDateFilter}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default DateFilter;

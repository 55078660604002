import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  BaseFormStepper,
  useStepperAction,
  useStepperIsCompleted,
  useStepperIsFilled,
} from '../common';
import { IFormInput } from '../task.types';
import ScheduleStepperDescription from './ScheduleStepperDescription';

import taskSchedule from '../../../../assets/images/task-schedule.svg';
import taskCheckCircle from '../../../../assets/images/task-check-circle.svg';

const SchedulerStepper: FC = () => {
  const { t } = useTranslation('task');
  const { handleSubmit } = useFormContext<IFormInput>();
  const isCompleted = useStepperIsCompleted('scheduler');
  const isFilled = useStepperIsFilled('scheduler');

  const { onSuccess, onError } = useStepperAction('scheduler');

  return (
    <BaseFormStepper
      imageUrl={isFilled ? taskCheckCircle : taskSchedule}
      isCompleted={isCompleted}
      title={t('schedule')}
      caption={
        isCompleted ? <ScheduleStepperDescription /> : t('howOftenNeedThis')
      }
      onClick={handleSubmit(onSuccess, onError)}
    />
  );
};

export default SchedulerStepper;

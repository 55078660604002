import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import PreviewHeader from '../../../../forms/component/PreviewHeader';
import SearchAndFilter, { SearchFilterProps } from './SearchAndFilter';

interface IProps extends SearchFilterProps {}

const PerformanceListHeader: FC<IProps> = ({
  sortBy,
  onSortChange,
  searchQuery,
  onSearch,
}) => {
  const { t } = useTranslation(['common', 'task']);

  return (
    <Flex flexDir='column' gap='20px'>
      <Flex justify='space-between' align='center'>
        <PreviewHeader title={t('task:members')} color='#FFD88D' />
      </Flex>

      <SearchAndFilter
        onSearch={onSearch}
        searchQuery={searchQuery}
        onSortChange={onSortChange}
        sortBy={sortBy}
        placeholder={t('task:placeholder.searchAssignee')}
      />
    </Flex>
  );
};

export default PerformanceListHeader;

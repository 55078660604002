import React, { FC } from 'react';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Authorize, AuthRole } from '../../authorization';
import noImage from '../../assets/images/placeholder/empty-deck.svg';

interface IProps {
  title?: string;
  onAddClick?: () => void;
}

const NoCardInDeck: FC<IProps> = ({ title, onAddClick }) => {
  const { t } = useTranslation(['handbook']);
  return (
    <Flex direction='column' maxW='300px' gap='14px'>
      <Image src={noImage} maxW='300px' mx='auto' />
      <Flex direction='column' textAlign='center' gap='2px'>
        <Box fontSize='15px' fontWeight='700' color='#303030'>
          {t('handbook:deck_has_no_card', {
            value: title,
          })}
        </Box>
        <Authorize permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}>
          <Box fontSize='11px' fontWeight='500' color='#9E9E9E' px='12px'>
            {t('handbook:add_card_deck')}
          </Box>
        </Authorize>
      </Flex>
      <Authorize permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}>
        <Button
          w='max-content'
          px='16px'
          colorScheme='blue'
          mx='auto'
          maxW='full'
          fontSize='15px'
          onClick={onAddClick}
        >
          {t('handbook:add_new_card')}
        </Button>
      </Authorize>
    </Flex>
  );
};

export default NoCardInDeck;

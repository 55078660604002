import React, { FC } from 'react';
import { Box, Center, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import ChatGroupEmpty from '../../assets/images/empty-state/chat-group-empty-state.svg';

interface IProps {
  haveItems?: boolean;
}

const ChapGroupEmpty: FC<IProps> = ({ haveItems, children }) => {
  const { t } = useTranslation(['invite']);

  if (haveItems) {
    return <>{children}</>;
  }

  return (
    <Center
      flex={1}
      flexDir='column'
      maxW='300px'
      gap='8px'
      textAlign='center'
      alignSelf='center'
    >
      <Image src={ChatGroupEmpty} boxSize='150px' />

      <Box fontSize='15px' fontWeight='700' color='#303030'>
        {t('invite:noChatGroupPresent')}
      </Box>

      <Box fontSize='12px' fontWeight='500' color='#9E9E9E'>
        {t('invite:noChatGroupPresentDesc')}
      </Box>
    </Center>
  );
};

export default ChapGroupEmpty;

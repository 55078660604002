// @ts-ignore
import React, { FC, useState } from 'react';
import DashboardDataContext, {
  INavigationPersistData,
  ISelectedFolderData,
} from './dashboard-data-context';
import {
  ILanguageSetting,
  ISupportedLocalesEntity,
} from '../../../../ui-components/LanguageSetting/language-setting.types';

const initialNavigationPersistData: INavigationPersistData = {
  forms: {
    selectedPage: 1,
    selectedTab: 0,
  },
  knowledgeBase: {
    selectedPage: 1,
    selectedViewType: 'grid',
    selectedFolder: undefined,
  },
  members: {
    selectedPage: 1,
  },
  trainings: {
    selectedMainTab: 'tracking',
    selectedSubTab: 'paths',
  },
};

const DashboardDataProvider: FC = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [CloseIcon, setCloseIcon] = useState('');
  const [lineColor, setLineColor] = useState('');
  const [showHomePageEmptyState, setShowHomePageEmptyState] = useState(true);
  const [isLauncherInProgress, setIsLauncherInProgress] = useState(false);
  const [preferredLanguages, setPreferredLanguages] = useState<
    ILanguageSetting['SupportedLocales']
  >([]);
  const [selectedFolder, setSelectedFolder] =
    useState<ISelectedFolderData>(undefined);
  const [navigationPersistData, setNavigationPersistData] =
    useState<INavigationPersistData>(initialNavigationPersistData);

  const navigationPersistDataHandler = (data: INavigationPersistData) => {
    setNavigationPersistData(data);
  };

  const showErrorModalHandler = () => {
    setShowErrorModal(true);
  };

  const hideErrorModalHandler = () => {
    setShowErrorModal(false);
  };

  const errorMessageHandler = (data) => {
    setErrorMessage(data);
  };

  const modalTitleHandler = (data) => {
    setModalTitle(data);
  };

  const closeIconHandler = (data) => {
    setCloseIcon(data);
  };

  const lineColorHandler = (data) => {
    setLineColor(data);
  };

  const preferredLanguagesHandler = (
    data: ILanguageSetting['SupportedLocales']
  ) => {
    setPreferredLanguages(data);
  };

  const selectedFolderHandler = (data: ISelectedFolderData) => {
    setSelectedFolder(data);
  };

  const showHomePageEmptyStateHandler = (data: boolean) => {
    setShowHomePageEmptyState(data);
  };

  const resetNavigationPersistDataHandler = () => {
    setNavigationPersistData(initialNavigationPersistData);
  };

  const navigationPersistFormPageUpdateHandler = (page: number) => {
    let formData = navigationPersistData?.forms;
    setNavigationPersistData({
      ...navigationPersistData,
      forms: { ...formData, selectedPage: page },
    });
  };

  const navigationPersistFormTabUpdateHandler = (tabIndex: number) => {
    let formData = navigationPersistData?.forms;
    setNavigationPersistData({
      ...navigationPersistData,
      forms: { ...formData, selectedTab: tabIndex },
    });
  };

  const launcherInProgressHandler = (data: boolean) => {
    setIsLauncherInProgress(data);
  };

  const dashboardContext = {
    showErrorModal: showErrorModal,
    errorMessage: errorMessage,
    modalTitle: modalTitle,
    CloseIcon: CloseIcon,
    lineColor: lineColor,
    preferredLanguages: preferredLanguages,
    selectedFolder: selectedFolder,
    showHomePageEmptyState: showHomePageEmptyState,
    navigationPersistData: navigationPersistData,
    isLauncherInProgress: isLauncherInProgress,
    setIsLauncherInProgress: launcherInProgressHandler,
    navigationPersistDataHandler: navigationPersistDataHandler,
    showErrorModalHandler: showErrorModalHandler,
    hideErrorModalHandler: hideErrorModalHandler,
    errorMessageHandler: errorMessageHandler,
    modalTitleHandler: modalTitleHandler,
    closeIconHandler: closeIconHandler,
    lineColorHandler: lineColorHandler,
    preferredLanguagesHandler: preferredLanguagesHandler,
    selectedFolderHandler: selectedFolderHandler,
    showHomePageEmptyStateHandler: showHomePageEmptyStateHandler,
    resetNavigationPersistDataHandler: resetNavigationPersistDataHandler,
    navigationPersistFormPageUpdateHandler:
      navigationPersistFormPageUpdateHandler,
    navigationPersistFormTabUpdateHandler:
      navigationPersistFormTabUpdateHandler,
  };

  return (
    <DashboardDataContext.Provider value={dashboardContext}>
      {props.children}
    </DashboardDataContext.Provider>
  );
};

export default DashboardDataProvider;

import React, { FC } from 'react';
import { BaseSearchModal } from '../../modal';

interface IProps {
  type: string;
  item: BaseSearchModal;
}

const SearchTitle: FC<IProps> = ({ type, item }) => {
  if (['user', 'location', 'branch'].includes(type)) {
    return <>{item?.meta?.name || item?.value || 'N/A'}</>;
  }
  if (type === 'card') {
    return <>{item?.meta?.title || item?.value || 'N/A'}</>;
  }
  if (type === 'deck') {
    return <>{item?.meta?.title || item?.value || 'N/A'}</>;
  }
  if (type === 'training') {
    return <>{item?.meta?.title || item?.value || 'N/A'}</>;
  }
  if (type === 'form') {
    return <>{item?.meta?.title || item?.value || 'N/A'}</>;
  }
  if (type === 'sop') {
    return <>{item?.meta?.title || item?.value || 'N/A'}</>;
  }
  if (type === 'category') {
    return <>{item?.meta?.name}</>;
  }
  return <>{item?.value || 'N/A'}</>;
};

export default SearchTitle;
SearchTitle.displayName = 'UiComponent/Search/SearchTitle';

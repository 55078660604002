import React, { FC } from 'react';
import { Flex, List, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import AssigneeItem from './AssigneeItem';
import { UserProgressEntity } from '../../tasks.types';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Box,
} from '@chakra-ui/react';

interface IProps {
  assignees: UserProgressEntity[];
  redirectHandler: (type: 'open' | 'edit') => void;
}

const MoreTaskAssignees: FC<IProps> = (props) => {
  const { assignees, redirectHandler } = props;

  if (!assignees?.length) {
    return null;
  }

  return (
    <Box>
      <Popover placement='bottom-start'>
        <PopoverTrigger>
          <Flex
            borderRadius='50%'
            border='1px solid #E2E2EA'
            align='center'
            justify='center'
            boxSize='40px'
            cursor={'pointer'}
          >
            <Text fontSize='14px' fontWeight={500} color='#6f767e' m={0}>
              +{assignees.length}
            </Text>
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Text fontWeight={600} padding='5px' color='#9A9FA5'>
              ASSIGNEES
            </Text>
            <List spacing={4} styleType='none' maxH='40vh' overflow='scroll'>
              {assignees?.map((member, index) => {
                return (
                  <Flex key={index} gap='4px' alignItems={'center'}>
                    <AssigneeItem data={member} />
                  </Flex>
                );
              })}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      {/* <Menu arrowPadding={2}>
      <MenuButton onClick={() => redirectHandler('open')}>
        <Flex
          borderRadius='50%'
          border='1px solid #E2E2EA'
          align='center'
          justify='center'
          boxSize='40px'
        >
          <Text fontSize='14px' fontWeight={500} color='#6f767e' m={0}>
            +{assignees.length}
          </Text>
        </Flex>
      </MenuButton> */}
      {/* <MenuList width='350px'>
        <List overflowY='scroll' maxH='25vh' px={4}>
          {assignees?.map((value, index) => {
            return <AssigneeItem key={index} data={value} />;
          })}
        </List>
      </MenuList> */}
      {/* </Menu> */}
    </Box>
  );
};

export default MoreTaskAssignees;

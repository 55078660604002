import React, { FC } from 'react';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';

import { IFormInput } from '../../task.types';
import { PickerWrapper } from '../CustomDatePicker/picker.styles';
import PickerButton from '../CustomDatePicker/PickerButton';
import CheckButton from '../CheckButton';

interface IProps {
  isEdit?: boolean;
}

const DayOnlyPicker: FC<IProps> = ({ isEdit }) => {
  const { t } = useTranslation(['task']);
  const { control } = useFormContext<IFormInput>();

  return (
    <Controller
      control={control}
      name='repeatMonthly'
      rules={{
        required: t('task:validation.repeatMonthly'),
      }}
      render={({ field, fieldState }) => {
        return (
          <FormControl isInvalid={!!fieldState.error}>
            <Flex gap={2}>
              <PickerWrapper hideDays>
                <DatePicker
                  readOnly={isEdit}
                  selected={
                    field.value !== 'lastDay'
                      ? (field.value as never)
                      : undefined
                  }
                  onChange={(date) => field.onChange(date)}
                  // onSelect={(date) => onChange?.(date)}
                  customInput={
                    <PickerButton
                      isSelected={field.value !== 'lastDay' && !!field.value}
                    />
                  }
                  renderCustomHeader={() => null}
                  formatWeekDay={(day) => day.substring(0, 3)}
                  calendarStartDay={1}
                  placeholderText={t('task:placeholder.chooseDay')}
                  dateFormat='do'
                  minDate={new Date('2023/05/01')}
                  maxDate={new Date('2023/05/31')}
                />
              </PickerWrapper>

              <CheckButton
                isReadOnly={isEdit}
                isSelected={field.value === 'lastDay'}
                onClick={() => field.onChange('lastDay')}
              >
                <Trans t={t} i18nKey='task:placeholder.lastDay' />
              </CheckButton>
            </Flex>

            <FormErrorMessage>{fieldState?.error?.message}</FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
};

export default DayOnlyPicker;

import { Link } from 'react-router-dom';
// import css
import './BundleLabel.css';

interface Props {
  colorCode?: string;
  title: string;
  style?: object;
}

const BundleLabel = ({ colorCode, title, style }: Props) => {
  return (
    <Link to={`/dashboard?bundles=${title}`}>
      <div className='bundle-label-container' style={style}>
        <div
          style={{ backgroundColor: colorCode }}
          className='bundle-label-circle'
        />
        <div className='bundle-label-title'> {title}</div>
      </div>
    </Link>
  );
};

export default BundleLabel;

import { useQuery, useReactiveVar } from '@apollo/client';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToast,
  Text,
  Tooltip,
  Center,
} from '@chakra-ui/react';
import { callAll } from '@chakra-ui/utils';
import { FormInput } from 'atoms';
import Dropdown from 'atoms/Dropdown';
import { toArray } from 'authorization/authorization.utils';
import { useUserEntity } from 'hooks';
import { InfoFormValues } from 'pages/Profile/TabComponents';
import {
  UserDataResponse,
  USER_DATA_QUERY,
} from 'pages/Profile/TabComponents/InfoComponent/info.graphql';
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthRole } from 'sop-commons/src/client';
import { roleObj } from 'ui-components/DashboardMenu';
import InviteLocationSelect from 'ui-components/InviteUserNew/InviteLocationSelect';
import LocationChips from 'ui-components/InviteUserNew/LocationChips';
import PermissionLevelInput from 'ui-components/InviteUserNew/PermissionLevelInput';
import Loader from 'ui-components/Loader';
import ActionButton from './ActionButton';
import PendingUserInviteLocationSelect from './PendingUserInviteLocationSelect';
import PendingUserInviteLocationChips from './PendingUserInviteLocationChips';

interface IProps {
  children?: ReactNode;
  userId: string;
  onError?: () => void;
}

type IRootLoc = {
  eid: string;
  name: string;
};

const PendingUserInvite: FC<IProps> = ({ children, userId, onError }) => {
  const { t } = useTranslation(['common', 'invite', 'role']);
  const methods = useForm<InfoFormValues>({
    defaultValues: {},
  });
  const { control, setValue } = methods;
  const computedLocationList = useWatch<InfoFormValues, 'computedLocations'>({
    control: control,
    name: 'computedLocations',
  });
  const job = useWatch<InfoFormValues, 'job'>({
    control: control,
    name: 'job',
  });
  const memberName = useWatch<InfoFormValues, 'name'>({
    control: control,
    name: 'name',
  });
  const memberAccessDetails = useWatch<InfoFormValues, 'permissionLevel'>({
    control: control,
    name: 'permissionLevel',
  });
  const roleColorByName = useReactiveVar<Record<string, string>>(roleObj);
  const rootLocation: IRootLoc = useUserEntity((entity) => {
    return (
      entity?.locations?.find((loc) => loc.isRoot) || { eid: '', name: '' }
    );
  });

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  useEffect(() => {
    if (!memberName) return;
    const [firstName, ...rest] = memberName?.trim()?.split(' ');
    setValue('firstName', firstName);
    setValue('lastName', rest.join(' '));
  }, [memberName]);

  useEffect(() => {
    if (!job?.label) return;
    setValue('role', job?.label);
  }, [job]);

  useEffect(() => {
    if (!memberAccessDetails) return;
    setValue('accessDetails', memberAccessDetails);
  }, [memberAccessDetails]);

  useEffect(() => {
    if (!computedLocationList) return;
    if (
      (computedLocationList &&
        Array.isArray(computedLocationList) &&
        computedLocationList?.length > 0) ||
      computedLocationList
    ) {
      if (Array.isArray(computedLocationList)) {
        let ids = computedLocationList?.map((loc) => loc?.eid || loc?.id);
        setValue('location', ids);
      } else {
        setValue(
          'location',
          computedLocationList?.id || computedLocationList?.eid
        );
      }
    }
  }, [computedLocationList]);

  const roleList = useMemo(() => {
    return Object.keys(roleColorByName || {}).map((key) => {
      return { label: key, value: key };
    });
  }, [roleColorByName]);

  const onPermissionChange = useCallback(
    (nextValue: AuthRole) => {
      if (
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(nextValue) &&
        rootLocation
      ) {
        setValue(
          'computedLocations',
          {
            id: rootLocation.eid,
            label: rootLocation.name,
            value: rootLocation.name,
          },
          {
            shouldValidate: true,
          }
        );
      } else {
        setValue('computedLocations', []);
      }
    },
    [rootLocation]
  );

  const { loading } = useQuery<UserDataResponse>(USER_DATA_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      eid: userId,
    },
    onCompleted: (response) => {
      const data = response.userById;
      const [firstName, ...rest] = data.name?.trim()?.split(' ');
      methods.reset(
        {
          eid: data.eid,
          name: data?.name,
          firstName: firstName,
          lastName: rest.join(' '),
          email: data.email,
          profilePic: data.profilePic,
          role: data.role,
          job: { label: data?.role, value: data?.role },
          accessDetails: data.authRole,
          permissionLevel: data.authRole,
          phoneNumber: data.phone,
          // location: toArray(data.locations)[0]?.eid,
          location: toArray(data.locations)
            ?.map((loc) => loc?.eid)
            .filter(Boolean),
          computedLocations: data?.locations?.map((loc) => {
            return {
              id: loc?.eid,
              label: loc?.name,
              value: loc?.name,
            };
          }),
          username: data.username,
          type: data.type,
          status: data.status,
          isDeleted: data.isDeleted,
        },
        {
          keepValues: false,
        }
      );
    },
    onError: (error) => {
      if (error?.graphQLErrors?.[0]?.message) {
        toast({
          title: error?.graphQLErrors?.[0]?.message,
          status: 'error',
        });
        onError?.();
      }
    },
  });

  return (
    <>
      {loading ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <>
          <FormProvider {...methods}>
            <Box>
              <Controller
                name='name'
                control={control}
                defaultValue=''
                rules={{
                  required: t('invite:validation.name_required'),
                  pattern: {
                    value: /^\S.*\S$|^\S$/,
                    message: t('invite:validation.invalid_name'),
                  },
                }}
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={!!fieldState.error}>
                    <FormLabel fontSize='14px' fontWeight='600' htmlFor='name'>
                      {t('invite:inviteName')}
                    </FormLabel>
                    <FormInput
                      {...field}
                      size='lg'
                      id='name'
                      placeholder={t('invite:enter_name')}
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                name='job'
                control={control}
                rules={{ required: t('invite:validation.jobRequired') }}
                render={({ field, fieldState }) => (
                  <FormControl mt={4} isInvalid={!!fieldState.error}>
                    <FormLabel fontSize='14px' fontWeight='600' htmlFor='job'>
                      {t('invite:inviteJob')}
                    </FormLabel>
                    <Dropdown
                      size='lg'
                      id='job'
                      placeholder={t('invite:inviteSelectJob')}
                      {...field}
                      options={roleList}
                      isDisabled={roleList?.length === 0}
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                name='permissionLevel'
                control={control}
                defaultValue={undefined}
                rules={{
                  required: t('invite:validation.permissionLevelRequired'),
                }}
                render={({ field, fieldState }) => (
                  <FormControl
                    mt={4}
                    as='fieldset'
                    isInvalid={!!fieldState.error}
                  >
                    <FormLabel as='legend'>
                      <Flex gap={4} alignItems='center'>
                        <Text fontSize='14px' fontWeight='600'>
                          {t('invite:invitePermissionLevel')}
                        </Text>
                        <Tooltip
                          label={t('invite:invitePermissionLevelInfo')}
                          placement='bottom'
                          hasArrow
                          borderRadius='6px'
                          padding='5px 10px'
                        >
                          <InfoOutlineIcon cursor='pointer' />
                        </Tooltip>
                      </Flex>
                    </FormLabel>
                    <PermissionLevelInput
                      {...field}
                      onChange={(nextValue: AuthRole) =>
                        callAll(field.onChange, onPermissionChange)(nextValue)
                      }
                    />
                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />

              <Controller
                name='computedLocations'
                control={control}
                rules={{ required: t('invite:validation.locationRequired') }}
                render={({ field, fieldState }) => (
                  <FormControl mt={4} isInvalid={!!fieldState.error}>
                    <FormLabel
                      fontSize='14px'
                      fontWeight='600'
                      htmlFor='location'
                    >
                      {t('invite:inviteLocation')}
                    </FormLabel>

                    {/* <InviteLocationSelect
                      rootLocation={rootLocation}
                      {...field}
                    /> */}

                    <PendingUserInviteLocationSelect
                      rootLocation={rootLocation}
                      {...field}
                    />

                    <FormErrorMessage>
                      {fieldState.error?.message}
                    </FormErrorMessage>
                    <PendingUserInviteLocationChips />
                  </FormControl>
                )}
              />
              {children}
            </Box>
          </FormProvider>
        </>
      )}
    </>
  );
};

PendingUserInvite.displayName = 'pages/Teams/InvitedMember/PendingUserInvite';

export default PendingUserInvite;

import React, { FC, useState } from 'react';

import { useUploadImage, useUploadToAws } from 'hooks';
import { FileEntity } from 'types';
import { getFileType } from 'utils/utils';

import DocUpload from './DocUpload';
import { FileDataEntity } from '../../../Chapters/CreateChapterModal/create-chapter.graphql';
import FileItem from '../../../Chapters/CreateChapterModal/common/FileItem';

type Progress = (progress: number) => void;

interface IProps {
  onFileChange?: (file: FileEntity) => void;
}

const UploadDocContent: FC<IProps> = ({ onFileChange }) => {
  const [file, setFile] = useState<FileDataEntity>();
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploadImage = useUploadImage();
  const uploadToAws = useUploadToAws();

  const uploadFiles = async (fileData: File, uploadProgress?: Progress) => {
    if (!fileData) {
      return undefined;
    }
    if (fileData?.type?.includes('video/')) {
      return uploadToAws(fileData, uploadProgress);
    } else {
      return uploadImage(fileData, undefined, uploadProgress);
    }
  };

  const onFileDrop = async (fileLists: File[]) => {
    const newFile = fileLists?.[0];
    setProgress(0);
    setFile({
      name: newFile?.name,
      fileSize: newFile?.size,
      type: newFile?.type,
      mimetype: newFile?.type,
      file: newFile,
    });

    try {
      setIsUploading(true);
      const fileUrl = await uploadFiles(newFile!, (_value) =>
        setProgress(_value)
      );
      setIsUploading(false);
      onFileChange?.({
        name: newFile?.name,
        fileSize: newFile?.size,
        type: getFileType(newFile?.type),
        mimetype: newFile?.type,
        url: fileUrl!,
      });
    } catch (e) {
      setIsUploading(false);
    }
  };

  if (file) {
    return (
      <FileItem isUploading={isUploading} progress={progress} file={file} />
    );
  }

  return <DocUpload onFileDrop={onFileDrop} />;
};

export default UploadDocContent;

import React, { FC } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Image from '../../../ui-components/Image/Image';
import { Authorize, AuthRole } from '../../../authorization';
import { truncateString } from '../../../utils';
import noImage from '../../../assets/images/placeholder/empty-assignees.svg';

interface IProps {
  title?: string;
  onGoToClick?: () => void;
  createdBy?: any;
}

const NoAssignees: FC<IProps> = ({ title, onGoToClick, createdBy }) => {
  const { t } = useTranslation('training');
  return (
    <Flex direction='column' maxW='300px' gap='14px'>
      <Image src={noImage} />
      <Flex direction='column' textAlign='center' gap='2px'>
        <Box fontSize='15px' fontWeight='700' color='#303030'>
          {/*// @ts-ignore */}
          {t('is_not_assigned', {
            title: title,
          })}
        </Box>
        <Authorize permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}>
          <Box fontSize='11px' fontWeight='500' color='#9E9E9E' px='12px'>
            {t('click_on_add_assign', {
              title: title,
            })}
          </Box>
        </Authorize>
      </Flex>
      <Authorize
        permittedRoles={[AuthRole.SUPER_ADMIN]}
        permittedFor={['user']}
        canAccess={(authUser) => {
          return authUser.eid === createdBy?.eid;
        }}
      >
        <Button
          w='max-content'
          minW='200px'
          px='16px'
          colorScheme='blue'
          mx='auto'
          maxW='full'
          fontSize='15px'
          onClick={onGoToClick}
        >
          {t('training_details_section.go_to_training', {
            name: truncateString(title, 26)?.toLowerCase(),
          })}
        </Button>
      </Authorize>
    </Flex>
  );
};

export default NoAssignees;

import { ApolloProvider, useApolloClient } from '@apollo/client';
import { AlertDialogCloseButton, Flex, Text } from '@chakra-ui/react';
import { IFilesEntity } from 'pages/LocationDetails/utils/location-details.types';
import React, { useCallback, useRef } from 'react';
import {
  BoxHeader,
  UseConfirm,
  useConfirm,
} from '../../../../../../../ui-components';
import DocumentsUploadComponent from '../components/DocumentsUploadComponent';

interface IProps {
  uploadedFiles: IFilesEntity[];
  refetchHandler: () => void;
  locationId: string;
  type: 'add' | 'edit';
  index?: number;
}

type UseDocumentsUpload = (props: IProps) => void;

export type IDocUploadCloseType = 'close' | 'refetch';

export const useDocumentsUpload = (): UseDocumentsUpload => {
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  const apolloClient = useApolloClient();

  const onCloseHandler = (type: IDocUploadCloseType) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }
  };

  return useCallback((props) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      title: (
        <Flex gap='12px' justify='space-between'>
          <BoxHeader
            fontSize='18px'
            title={props.type === 'add' ? 'Upload documents' : 'Edit document'}
            color='#CABDFF'
          />
          <AlertDialogCloseButton
            pos='relative'
            borderRadius='full'
            top='0'
            right='0'
          />
        </Flex>
      ),
      size: 'xl',
      content: (
        <DocumentsUploadComponent
          uploadedFiles={props?.uploadedFiles}
          onCloseHandler={onCloseHandler}
          refetchHandler={props.refetchHandler}
          locationId={props?.locationId}
          type={props.type}
          fileIndex={props?.index}
        />
      ),
      contentProps: {
        pt: '12px',
        pb: '20px',
        px: '8px',
        borderRadius: '12px',
      },
      isCentered: true,
      footer: null,
    });
  }, []);
};

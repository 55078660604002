import { cloneDeep } from '@apollo/client/utilities';
import React, { FC, useContext } from 'react';
import { SortEnd } from 'react-sortable-hoc';
import { SortableTrainingList } from '../../CreateContainer/AddStructure/CreateTraining/SortableTraining';
import TrainingPathContext from '../TrainingPathStore/training-path-context';

interface IProps {
  onSortEnd: ({ oldIndex, newIndex }: SortEnd) => void;
  toggleSwitch: (id: string, toggleValue: boolean) => void;
  handleMilestoneHandler: (id: any) => void;
  handleMilestoneEdit: (mileId: string) => void;
  handleRemove: (id: string) => void;
  setCardSelectedHandler: (item: any) => void;
  hoverClickHandler: (item: any, type: 'milestone' | 'formResponse') => void;
  handleCardEdit: (cardId: string) => void;
  handleCardClick: (item: any) => void;
}

const SortableList: FC<IProps> = ({
  onSortEnd,
  toggleSwitch,
  handleMilestoneHandler,
  handleMilestoneEdit,
  handleRemove,
  setCardSelectedHandler,
  hoverClickHandler,
  handleCardEdit,
  handleCardClick,
}) => {
  const trainingPathCtx = useContext(TrainingPathContext);

  return (
    <SortableTrainingList
      useDragHandle
      lockAxis='y'
      items={trainingPathCtx?.addedItems || []}
      onSortEnd={onSortEnd}
      toggleSwitch={toggleSwitch}
      milestoneProps={{
        handleRemove: handleMilestoneHandler,
        handleMilestoneEdit: handleMilestoneEdit,
      }}
      cardProps={{
        handleRemove: handleRemove,
        setCardSelected: setCardSelectedHandler,
        hoverEffectFn: (data) => {
          let tempArr = cloneDeep(trainingPathCtx?.addedItems);
          tempArr?.map((arr: any) => {
            arr.showHover = arr?.eid === data?.eid;
          });
        },
        hoverClick: hoverClickHandler,
        handleCardEdit: handleCardEdit,
        handleCardClick: handleCardClick,
      }}
    />
  );
};

export default SortableList;

// import scss
import React from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ViewTrainingItem from './ViewTrainingItem';
import { List } from '@chakra-ui/react';
import closeBtn from '../../assets/images/closeIconWhite.png';

const TrainingPathListItemModal = ({
  open,
  onClose,
  getContentDetails,
  selectedTrainingItem,
  activePage,
  isQuizSelection,
  enablePreview
}: any) => {
  return (
    <>
      <div className='training-listing' style={{ padding: '1rem' }}>
        <div className='training-listing-close-icon-div'>
          <img
            src={closeBtn}
            className='training-listing-close-icon-img'
            onClick={onClose}
          />
        </div>
        <div className='training-listing-details'>
          {getContentDetails && (
            <List spacing={3}>
              {getContentDetails.map(
                (item: any, index: number, list: any[]) => {
                  return (
                    <ViewTrainingItem
                      item={item}
                      key={index}
                      index={index}
                      selectedTrainingItem={selectedTrainingItem}
                      activePage={activePage}
                      isQuizSelection={isQuizSelection}
                      prevCompleted={
                        index > 0 ? list[index - 1]?.completed : true
                      }
                      enablePreview={enablePreview}
                    />
                  );
                }
              )}
            </List>
          )}
        </div>
      </div>
    </>
  );
};

export default TrainingPathListItemModal;

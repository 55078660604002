import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IProps {
  innerProps: any;
  innerRef: any;
  data: any;
  isSelected: any;
  isFocused: any;
}

const CustomOption: FC<IProps> = ({
  innerProps,
  innerRef,
  data,
  isSelected,
  isFocused,
}) => {
  return (
    <Box
      {...innerProps}
      id='custom-option-box-1'
      ref={innerRef}
      bg={isFocused ? 'gray.100' : 'white'}
      cursor='pointer'
      _hover={{
        bg: 'gray.100',
      }}
    >
      <Box
        borderBottom='1px solid'
        borderColor='gray.200'
        p={3}
        mx={2}
        borderRadius='md'
      >
        {data.value === 'create' ? (
          <Text as='b' color={'#2a85ff'}>
            + Create new folder
          </Text>
        ) : (
          <Flex justifyContent='space-between' alignItems='center'>
            <span>{data.label}</span>
            {isSelected && <CheckIcon color='green.500' />}
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default CustomOption;

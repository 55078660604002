import moment from 'moment-timezone';

export function getTaskStatus(
  item: any,
  userTimezone: string,
  userData: any[]
) {
  const now = moment.utc(
    moment().tz(userTimezone).format('DD-MMM-YYYY hh:mm a'),
    'DD-MMM-YYYY hh:mm a'
  );
  let lastSession = item?.lastActiveSession;
  const taskDueDate = moment.utc(lastSession?.dueDate);
  const taskStartDate = moment.utc(lastSession?.startDate);
  const maxTaskDueDate = moment.utc(lastSession?.maxDueDate);
  const endOfWeek = moment().tz(userTimezone).endOf('week');

  const isLastSession = lastSession?.isLastSession;
  const isRepeating = item?.isRepeating;
  const completedTasks = lastSession?.assignees?.every(
    (_progress: any) => _progress?.completedPercent === 100
  );
  const totalTasks = lastSession?.itemCount || 0;
  if (taskDueDate.isSame(now, 'day') && !completedTasks && !isLastSession) {
    return 'dueToday';
  } else if (
    taskDueDate.isAfter(now) &&
    taskDueDate.isBefore(endOfWeek) &&
    !isLastSession &&
    !completedTasks
  ) {
    return 'thisWeek';
  } else {
    return 'upcoming';
  }
}
/**
 * else if (
    (!isLastSession && taskDueDate.isAfter(now) && !completedTasks) ||
    (now.isSameOrAfter(taskStartDate) && taskDueDate.diff(now, 'days') <= 28)
  ) {
    return 'upcoming';
  }
 */

import { cloneDeep } from '@apollo/client/utilities';
import { moveArray, SortEnd } from 'pages/CreateDeck/card';
import { SortableTrainingList } from 'pages/Training/CreateTrainingPath/CreateContainer/AddStructure/CreateTraining/SortableTraining';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { IFormInput } from 'sub-components/ChapterEditor/chaptor.types';
import { defaultContent } from 'sub-components/ChapterEditor/defaultData';

interface IProps {
  trainingData: any;
  setTrainingData: React.Dispatch<any>;
  setIsMilestoneModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMilestoneType: React.Dispatch<React.SetStateAction<string>>;
  setEditMilestoneData: React.Dispatch<React.SetStateAction<never[]>>;
  handleRemove: (id: string) => void;
  setSelectedMilestone: React.Dispatch<any>;
  handleCardEdit: (cardId: any) => void;
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  setEditCardData: React.Dispatch<any>;
  setOpenPowerupPreviewModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditTrainingSortableList: FC<IProps> = ({
  trainingData,
  setTrainingData,
  setIsMilestoneModalOpen,
  setMilestoneType,
  setEditMilestoneData,
  handleRemove,
  setSelectedMilestone,
  handleCardEdit,
  setShowPreview,
  setEditCardData,
  setOpenPowerupPreviewModal,
}) => {
  const history = useHistory();
  const methods = useForm<IFormInput>({
    defaultValues: {
      title: '',
      content: defaultContent,
      smartPage: false,
      chapterIcon: '',
    },
  });
  const toggleSwitch = (id: string, toggleValue: boolean) => {
    let _trainingData = cloneDeep(trainingData);
    _trainingData?.trainingItems?.map((item: any) => {
      if (item?.eid === id) {
        item.isMandatory = toggleValue;
      }
    });
    setTrainingData(_trainingData);
  };
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    const newList = moveArray(
      trainingData?.trainingItems || [],
      oldIndex,
      newIndex
    );
    setTrainingData((prevState: any) => {
      return {
        ...prevState,
        trainingItems: newList,
        contents: newList.map((content: any) => {
          if (content?.type !== 'milestone') {
            return {
              eid: content?.eid,
              type: content?.type,
              title: content?.title,
            };
          } else {
            return {
              eid: content?.eid,
              type: content?.type,
              title: content?.title,
              backgroundColor: content?.backgroundColor,
            };
          }
        }),
      };
    });
  };
  const handleMilestoneHandler = (id: any) => {
    let removeCardIndex = -1;
    let _trainingData = cloneDeep(trainingData);
    for (let i = 0; i < _trainingData?.trainingItems?.length; i++) {
      const card = _trainingData?.trainingItems[i];
      if (
        card?.eid === id ||
        card?.tempEid === id ||
        card?.tempId === id ||
        card?.data?.tempEid === id
      ) {
        removeCardIndex = i;
        break;
      }
    }
    _trainingData?.trainingItems.splice(removeCardIndex, 1);
    _trainingData?.contentDetails.splice(removeCardIndex, 1);

    // setSortingCardList([...selected]);
    setTrainingData(_trainingData);
  };
  const handleMilestoneEdit = (mileId: any) => {
    setIsMilestoneModalOpen(true);
    setMilestoneType('editMenu');
    let filteredData;
    filteredData = trainingData.trainingItems.filter(
      (data: any) =>
        (data.tempId === mileId || data.eid === mileId) &&
        data.type === 'milestone'
    );
    setEditMilestoneData(filteredData);
  };

  const hoverClickHandler = (item: any, type: 'milestone' | 'formResponse') => {
    if (type === 'milestone') {
      setIsMilestoneModalOpen(true);
      setMilestoneType('hoverMenu');
      setEditMilestoneData([]);
      setSelectedMilestone(item);
    } else {
      // history.push(`/forms/response/${item?.eid}`);
      if (
        trainingData?.trainingItems &&
        trainingData?.trainingItems?.length > 0
      ) {
        trainingData?.trainingItems?.map((expand: any) => {
          if (expand?.eid === item?.eid && expand?.form) {
            history.push(`/forms/response/${expand?.form?.eid}`);
          }
        });
      }
    }
  };
  return (
    <SortableTrainingList
      useDragHandle
      lockAxis='y'
      items={trainingData?.trainingItems}
      onSortEnd={onSortEnd}
      toggleSwitch={toggleSwitch}
      milestoneProps={{
        handleRemove: handleMilestoneHandler,
        handleMilestoneEdit: handleMilestoneEdit,
      }}
      cardProps={{
        handleRemove: handleRemove,
        hoverClick: hoverClickHandler,
        handleCardEdit: handleCardEdit,
        handleCardClick(cardData) {
          console.log(cardData);
          if (cardData.type === 'sop') {
            methods?.setValue('title', cardData?.title, {
              shouldDirty: false,
            });
            methods?.setValue('content', cardData?.content?.[0]?.tiptap, {
              shouldDirty: false,
            });
            methods?.setValue('smartPage', cardData?.smartPageEnabled, {
              shouldDirty: false,
            });
            methods?.setValue('chapterIcon', cardData?.icon?.native);
            setShowPreview(true);
          } else {
            setEditCardData(cardData);
            setOpenPowerupPreviewModal(true);
          }
        },
      }}
    />
  );
};

EditTrainingSortableList.displayName = 'EditTraining/EditTrainingSortableList';

export default EditTrainingSortableList;

import { FC } from 'react';
import { Flex, ListItem, Spacer } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { SearchItemContent } from '../../ui-components/Search';
import { BaseSearchModal } from 'modal';

interface IProps {
  item: BaseSearchModal;
  onSelectedItem: (item: BaseSearchModal) => void;
}

const SearchListItem: FC<IProps> = ({ item, onSelectedItem }) => {
  return (
    <ListItem
      py={3}
      px='32px'
      _hover={{
        bg: '#EFEFEFCC',
      }}
    >
      <Flex
        maxWidth='100%'
        columnGap='15px'
        align='center'
        onClick={() => onSelectedItem(item)}
      >
        <SearchItemContent item={item} />

        <Spacer />

        <div className='iconCss'>
          <ChevronRightIcon w={7} h={7} color='#9a9fa5' />
        </div>
      </Flex>
    </ListItem>
  );
};

export default SearchListItem;

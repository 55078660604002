import { gql } from '@apollo/client';

type Status = 'pending' | 'expired' | 'completed' | 'revoked';

export interface InviteResponse {
  FetchInviteByCode?: {
    name: string;
    entity: {
      name: string;
      profilePic?: string;
    };
    status: Status;
    inviteDetails: {
      status: Status;
    };
  };
}

export const GET_INVITE_DATA = gql`
  query RequestedBy($authCode: String!) {
    FetchInviteByCode(authCode: $authCode) {
      name
      entity {
        name
        profilePic
      }
      status
      inviteDetails {
        status
      }
    }
  }
`;

export interface JoinResponse {
  joinInvite: {
    accessToken: string;
    refreshToken?: string;
  };
}

export const JOIN_INVITE = gql`
  mutation JoinInvite($input: JoinInviteInput) {
    joinInvite(input: $input) {
      accessToken
    }
  }
`;

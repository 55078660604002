import { BaseModal } from './base';
import { CardDetailEntity, CardEntity, CardType } from '../types';
import { BaseCardEntity } from '../types/base-card.types';
import { CardTypeTrans } from '../sub-components/CardEditor/card-editor.data';
import { toArray } from '../utils/utils';

export class BaseCardModal<
  T extends BaseCardEntity = BaseCardEntity
> extends BaseModal {
  protected transData: T['translations'];
  protected data: Omit<T, 'translations'>;

  private _defaultTitle: string;
  _eid: string;

  constructor(data: BaseCardEntity) {
    super();
    this.transData = data.translations;
    // @ts-ignore
    this.data = data;
    this._defaultTitle = data.title;
    this._eid = data.eid;
  }

  get eid() {
    return this._eid;
  }

  get tempId() {
    return this.data.tempId || this.data.eid;
  }

  get title() {
    if (this.transData && this.transData[this.language])
      return this.transData[this.language].title;
    return this.data.title;
  }

  get subTitle() {
    if (this.transData && this.transData?.[this.language])
      return this.transData[this.language].subTitle;
    return this.data.subTitle;
  }

  get content() {
    if (this.transData && this.transData?.[this.language])
      return this.transData[this.language].content;
    return this.data.content;
  }

  get raw_content(): string | undefined {
    if (this.transData && this.transData?.[this.language])
      return this.transData[this.language].raw_content;
    // @ts-ignore
    return this.data.raw_content;
  }

  get thumbnail() {
    if (!this.data.thumbnail) {
      // eslint-disable-next-line no-console
      console.warn(
        `thumbnail not available for ${this.type} ${this.data.title}`
      );
      return 'https://sop-uploads-staging.s3.amazonaws.com/extras/1002.png';
    }
    return this.data.thumbnail;
  }

  get cardType() {
    return this.data.cardType;
  }

  get sopType() {
    return this.data.sopType;
  }

  get createdBy() {
    return this.data.createdBy;
  }

  get status() {
    return this.data.status;
  }

  get category() {
    return this.data.category;
  }

  // TODO translation and date format
  get updatedAt() {
    return this.data.updatedAt;
  }

  // TODO translation and date format
  get createdAt() {
    return this.data.createdAt;
  }

  get views() {
    return this.data.views ?? 0;
  }

  get form() {
    return this.data.form;
  }

  get trainings() {
    // @ts-ignore
    return this.data.trainings;
  }

  get type() {
    return this.data.type;
  }
}

export class CardModal<
  T extends CardEntity = CardEntity
> extends BaseCardModal<T> {
  constructor(data: CardEntity) {
    super(data);
  }

  get isMandatory() {
    return this.data.isMandatory || false;
  }

  set isMandatory(value: boolean) {
    this.data.isMandatory = value;
  }

  get files() {
    return this.data.files;
  }

  get smartPageEnabled(): boolean {
    // @ts-ignore
    return !!this.data?.smartPageEnabled;
  }

  get isMultiPage(): boolean {
    // @ts-ignore
    return !!this.data?.isMultiPage;
  }

  get cards() {
    // @ts-ignore
    return toArray(this.data?.cards);
  }

  get cardView() {
    // @ts-ignore
    return toArray(this.data?.cardView);
  }

  get icon() {
    // @ts-ignore
    return this.data?.icon;
  }

  // get milestoneIcon() {
  //   // @ts-ignore
  //   return this.data?.thumbnail;
  // }
}

export class CardDetailModal<
  T extends CardDetailEntity = CardDetailEntity
> extends CardModal<T> {
  constructor(data: CardDetailEntity) {
    super(data);
  }

  get backgroundColor() {
    return this.data.backgroundColor;
  }
  get layout() {
    return this.data.layout;
  }

  get media() {
    return this.data.media;
  }
}

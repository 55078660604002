import styled from '@emotion/styled';

export const CardPreviewWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  background-color: rgba(255, 255, 255, 1);
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding: 50px;
  box-shadow: -2px -3px 5px -4px rgba(0, 0, 0, 0.75);
  overflow-y: auto;
  z-index: 1401;

  .close-wrapper {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
  }

  .info-wrapper {
    position: absolute;
    top: 17px;
    right: 50px;
  }

  > .container {
    width: 300px;
    height: 800px;
    display: flex;
    flex-direction: column;

    .card-preview-container {
      border-radius: 10px;
      margin-top: 30px;
      overflow: hidden;
      flex: 1 1 auto;
      box-shadow: 0 4px 22px rgb(0 0 0 / 9%);
      margin-bottom: 40px;
    }
  }

  .preview-header {
    flex-direction: row;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 900;

    .box {
      height: 32px;
      width: 16px;
      border-radius: 4px;
      background-color: #b1e5fc;
      margin-right: 20px;
    }
  }
`;

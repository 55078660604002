import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { IconImage, RoleAndAuthRole } from '../../../../ui-components';
import { ILocationMember } from '../../../../sub-components/Locations/locationTypes/location-types';

interface IProps {
  member: ILocationMember;
  locationName?: string;
}

const MemberItem: FC<IProps> = ({ member, locationName }) => {
  return (
    <Box>
      <Flex gap='6px'>
        <IconImage
          name={member?.name}
          thumbnail={member?.thumbnail || member?.profilePic}
          boxSize={45}
          borderRadius='5px'
        />

        <Flex direction='column'>
          <Box fontWeight={500} color='#1A1D1F'>
            {member?.name}
          </Box>
          <RoleAndAuthRole
            role={member?.role}
            authRole={locationName}
            disableAuthRoleSplit
            defaultValue='-'
          />
        </Flex>
      </Flex>
      <hr style={{ marginTop: '1rem', backgroundColor: '#EFEFEF' }} />
    </Box>
  );
};

export default MemberItem;

import { ApolloProvider, useApolloClient } from '@apollo/client';
import { Flex, ModalCloseButton } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { UseConfirm, useConfirm } from 'ui-components';
import useCombinedStore from 'zustandStore/store';
import FolderSubFolderRestriction from './FolderSubFolderRestriction';

interface IProps {}

type FolderSubFolderRestrictionType = (props: IProps) => void;

export const useFolderSubFolderRestriction =
  (): FolderSubFolderRestrictionType => {
    const { isValidated, updateIsValidated } = useCombinedStore();
    const confirm = useConfirm();
    const apolloClient = useApolloClient();
    const { folderId, subFolderId } = useRouteMatch<{
      folderId?: string;
      subFolderId?: string;
    }>().params;

    const confirmRef = useRef<ReturnType<UseConfirm>>();

    return useCallback(() => {
      if (confirmRef.current) {
        confirmRef.current?.destroy();
      }

      confirmRef.current = confirm({
        Wrapper: ({ children }: any) => {
          return (
            <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
          );
        },
        size: '2xl',
        title: (
          <Flex>
            <ModalCloseButton m={6} onClick={() => updateIsValidated(false)} />
          </Flex>
        ),
        content: (
          <FolderSubFolderRestriction
            type={subFolderId ? 'subFolder' : folderId ? 'folder' : undefined}
          />
        ),
        contentProps: {
          p: '2rem',
          borderRadius: '16px',
        },
        isCentered: false,
        footer: null,
      });
    }, []);
  };

import React, { Component } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';

import { ReactComponent as ArchiveIcon } from './resources/archive.svg';
import { CometChat } from '@cometchat-pro/chat';

class ArchivedChats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      archivedIds: [],
      archivedChats: [],
      unreadMessage: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const archivedChats = (props.conversationlist || []).filter((it) =>
      props.archivedIds?.includes(it.conversationWith?.uid)
    );

    return {
      archivedIds: props.archivedIds,
      archivedChats: archivedChats,
      unreadMessage: archivedChats.reduce(
        (acc, value) => acc + value.unreadMessageCount,
        0
      ),
    };
  }

  render() {
    if (!this.state.archivedIds?.length) {
      return null;
    }

    return (
      <Flex
        gap={4}
        padding='8px 10px'
        align='center'
        cursor='pointer'
        marginBottom='6px'
        borderRadius='12px'
        _hover={{ bg: '#efefef' }}
        onClick={this.props.onClick}
      >
        <Center width='56px'>
          <Center boxSize='35px' bg='#F1F1F1' borderRadius='full'>
            <ArchiveIcon />
          </Center>
        </Center>

        <Box flex={1} fontSize='15px' fontWeight='500' color='#1A1D1F'>
          Archived chats
        </Box>
        {this.state.unreadMessage > 0 && (
          <Center
            fontSize='12px'
            lineHeight='10px'
            fontWeight='600'
            color='#33383F'
            borderRadius='full'
            bg='#DBDBDB'
            padding='6px 7px'
          >
            {this.state.unreadMessage}
          </Center>
        )}
      </Flex>
    );
  }
}

export default ArchivedChats;

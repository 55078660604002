import { useCallback, useRef } from 'react';
import { Box, Button, Center, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import TerminatedTaskState from '../../../../../assets/images/terminated-task.svg';

import { BoxHeader, UseConfirm, useConfirm } from 'ui-components';

interface IProps {
  callback?: () => void;
  btnTxt?: string;
}

type TerminatedTaskModal = (props: IProps) => void;

export const useTerminatedTaskModal = (): TerminatedTaskModal => {
  const { t } = useTranslation(['common', 'training']);
  const confirm = useConfirm();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ callback, btnTxt = 'Close' }) => {
    if (confirmRef.current) {
      confirmRef.current?.destroy();
    }

    confirmRef.current = confirm({
      content: (
        <Center flexDir='column'>
          <Image src={TerminatedTaskState} boxSize='200px' />
          <Text fontWeight={700} fontSize='16px'>
            You&apos;re off the hook!
          </Text>
          <Text fontWeight={700} fontSize='16px'>
            This task has been terminated
          </Text>
          <Button
            mt={6}
            variant='solid'
            colorScheme='blue'
            onClick={() => {
              confirmRef?.current?.destroy();
              callback && callback();
            }}
          >
            {btnTxt}
          </Button>
        </Center>
      ),
      isCentered: true,
      contentProps: {
        py: '16px',
        px: '24px',
        minW: '600px',
      },
      footer: null,
    });
  }, []);
};

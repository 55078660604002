import { TooltipProps } from 'recharts';
type ValueType = number | string | Array<number | string>;
type NameType = number | string;

interface IProps<TValue extends ValueType, TName extends NameType>
  extends Pick<
    TooltipProps<TValue, TName>,
    'labelStyle' | 'contentStyle' | 'wrapperStyle'
  > {}

export const getToolTipConfig = <
  TValue extends ValueType,
  TName extends NameType
>(
  props?: IProps<TValue, TName>
): IProps<TValue, TName> => {
  const { labelStyle, contentStyle, wrapperStyle, ...otherProps } = props || {};

  return {
    labelStyle: {
      color: '#2E2C34',
      fontWeight: '600',
      paddingBottom: 4,
      ...labelStyle,
    },
    contentStyle: {
      borderColor: 'rgba(0, 0, 0, 0.2)',
      boxShadow: '2px 3px 8px rgba(0, 0, 0, 0.4)',
      borderRadius: '8px',
      padding: '12px 16px',
      ...contentStyle,
    },
    wrapperStyle: {
      zIndex: 2,
      ...wrapperStyle,
    },
    ...otherProps,
  };
};

import React, { ChangeEventHandler, FC } from 'react';
import { Flex, IconButton, Spacer } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { SearchInput } from 'atoms';

interface IProps {
  searchQuery?: string;
  onSearch?: ChangeEventHandler<HTMLInputElement>;
  onCloseClick?: () => void;
}

const ChapterHeader: FC<IProps> = ({ searchQuery, onSearch, onCloseClick }) => {
  const { t } = useTranslation(['common']);

  return (
    <Flex align='center' justify='space-between' px='20px'>
      <SearchInput
        disabled={true}
        size='md'
        width='350px'
        hideShortcuts={true}
        placeholder={t('common:search')}
        onChange={onSearch}
        value={searchQuery}
      />

      <Spacer />
      <IconButton
        borderRadius='full'
        aria-label='more'
        icon={<FontAwesomeIcon icon={faX as IconProp} color='#5B5A64' />}
        onClick={onCloseClick}
      />
    </Flex>
  );
};

export default ChapterHeader;

import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FormInput from '../../../../atoms/FormInput';
import { ActionButton, BoxHeader } from '../../../../ui-components';
import TitleHeader from '../../../../sub-components/CardEditor/TitleHeader';
import { LAUNCHER_OPENING_TASKS } from '../../../../appRoutes';

import { transformTemplate } from './transform-template';

interface IFormInput {
  category: string;
}

interface IProps {}

const StartFromScratch: FC<IProps> = () => {
  const { t } = useTranslation(['launcher']);
  const history = useHistory();

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<IFormInput>();

  const onSubmit = async (values: IFormInput) => {
    const response = transformTemplate([
      {
        _id: '',
        category: values.category,
        tasks: [],
      },
    ]);

    history.push({
      pathname: LAUNCHER_OPENING_TASKS,
      search: '?viewMode=editor',
      state: {
        templatedData: response,
      },
    });
  };

  return (
    <Flex flexDir='column' gap='20px'>
      <BoxHeader
        fontSize='18px'
        title={t('launcher:addFirstPhase')}
        color='#FFBC99'
      />

      <Flex flexDir='column' mt={2}>
        <Box>
          <TitleHeader
            title='Phase title'
            desc='Phases are the groups in which tasks are organised in launcher'
            isRequired
          />
        </Box>

        <FormControl mt={3} isInvalid={!!errors?.category}>
          <FormInput
            placeholder='Enter phase title'
            size='lg'
            variant='outline'
            {...register('category', {
              required: 'Phase title is required',
              validate: (value) => {
                const len = value.trim().length;

                if (len === 0) {
                  return 'Please enter valid phase title';
                }
              },
            })}
          />
          <FormErrorMessage>{errors?.category?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <ActionButton
        w='full'
        fontSize='15px'
        fontWeight='700'
        colorScheme='blue'
        actionFn={handleSubmit(onSubmit)}
      >
        {t('launcher:saveAndAddFirstTask')}
      </ActionButton>
    </Flex>
  );
};

export default StartFromScratch;

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export interface ITaskDueDateFilterOptions {
  id?: string;
  uniqueValue?: string;
  title?: string;
  description?: string;
  date?: moment.Moment;
  isReadOnly?: boolean;
}

/**
 * Today
 */
const todayDate = (): ITaskDueDateFilterOptions => {
  const today = moment().startOf('day');
  return {
    id: uuidv4(),
    title: 'Today',
    description: today?.format('DD MMM'),
    date: today,
    isReadOnly: true,
    uniqueValue: 'today',
  };
};

/**
 * Tomorrow
 */
const tomorrowDate = (): ITaskDueDateFilterOptions => {
  const tomorrow = moment()?.add(1, 'day')?.startOf('day');
  return {
    id: uuidv4(),
    title: 'Tomorrow',
    description: tomorrow?.format('DD MMM'),
    date: tomorrow,
    isReadOnly: true,
    uniqueValue: 'tomorrow',
  };
};

/**
 * End of the week
 */
const endOfWeekDate = (): ITaskDueDateFilterOptions => {
  const endOfWeek = moment()?.endOf('week')?.startOf('day');
  return {
    id: uuidv4(),
    title: 'End of the week',
    description: endOfWeek?.format('DD MMM'),
    date: endOfWeek,
    isReadOnly: true,
    uniqueValue: 'endOfWeek',
  };
};

/**
 * Next week
 */
const nextWeekDate = (): ITaskDueDateFilterOptions => {
  const nextWeek = moment()?.add(1, 'week')?.endOf('week')?.startOf('day');
  return {
    id: uuidv4(),
    title: 'Next week',
    description: nextWeek?.format('DD MMM'),
    date: nextWeek,
    isReadOnly: true,
    uniqueValue: 'nextWeek',
  };
};

const getCustomRange = (): ITaskDueDateFilterOptions => {
  return {
    id: uuidv4(),
    title: 'Custom',
    description: 'Set date',
    date: undefined,
    isReadOnly: false,
    uniqueValue: 'custom',
  };
};

/**
 * Exporting all date filter options
 */
export const taskDueDateOptions: ITaskDueDateFilterOptions[] = [
  todayDate(),
  tomorrowDate(),
  endOfWeekDate(),
  nextWeekDate(),
  getCustomRange(),
];

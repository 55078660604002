import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Box, Center, Flex, Image, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Card } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { userObj } from 'sop-commons/src/client';

import { BoxHeader } from 'ui-components';
import Loader from 'ui-components/Loader';

import { GET_CONTINUE_TRAINING_DATA } from './continue-training.graphql';
import { getSingleTrainingData } from 'sop-commons/Query/Training';
import { TrainingData } from 'pages/Training/training.types';
import CustomPathCarousel from 'sub-components/AddNewPathModal/CustomPathCarousel';

import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';

const ContinueTraining = () => {
  const { t } = useTranslation(['common', 'training']);
  const dashboardCtx = useContext(DashboardDataContext);
  const history = useHistory();
  const userObject = useReactiveVar(userObj);
  const [continueTrainingList, setContinueTrainingList] = useState([]);
  const [selectedTrainingData, setSelectedTrainingData] = useState<any>(null);
  const [trainingProgressModal, setTrainingProgressModal] = useState(false);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    let returnedData = getContinueTrainingData();
    console.log('RETURNED DATA : ', returnedData);
  }, []);

  const [getContinueTrainingData, { loading }] = useLazyQuery(
    GET_CONTINUE_TRAINING_DATA,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setContinueTrainingList(data?.userHomepageNew?.resumeTrainings);
        if (data?.userHomepageNew?.resumeTrainings?.length > 0) {
          dashboardCtx?.showHomePageEmptyStateHandler(false);
        }
      },
      onError: (error) => {
        console.log('getContinueTrainingData => error => ', error);
      },
    }
  );

  const getTrainingByIdHandler = (id = userId) => {
    setUserId(id);
    singleTrainingHandler({
      variables: {
        eid: id,
      },
    });
  };

  const [singleTrainingHandler] = useLazyQuery(getSingleTrainingData, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      let isAnyQuizCard = false;
      let quizCardIds: any[] = [];
      let _item: TrainingData = JSON.parse(JSON.stringify(data?.trainingById));
      _item?.trainingContentExpanded?.forEach((it) => {
        if (it?.cardType === 'quiz') {
          isAnyQuizCard = true;
          quizCardIds.push(it?.eid);
        }
        it.completed = false;
        _item?.userProgress?.map((progress) => {
          if (progress?.userId === userObject?.eid) {
            progress?.viewedContents?.forEach((content: any) => {
              if (it?.uid === content?.uid) {
                it.completed = true;
              }
            });
          }
        });
      });
      _item?.trainingContentExpanded?.push({
        eid: '',
        uid: '',
        type: 'finish',
        title: _item?.title,
      });
      setSelectedTrainingData(_item);
      setTrainingProgressModal(true);
    },
    onError: (error) => {},
  });

  const titleHandler = (title: string) => {
    if (title?.length > 14) {
      return title?.substring(0, 14) + '...';
    } else {
      return title;
    }
  };

  const closeModalHandler = () => {
    setTrainingProgressModal(false);
  };

  return continueTrainingList?.length > 0 ? (
    <Card
      style={{
        borderRadius: '12px',
        borderColor: 'transparent',
      }}
    >
      <div
        style={{
          marginBottom: '24px',
          overflowX: 'scroll',
          overflowY: 'hidden',
          marginRight: '-37px',
        }}
      >
        <BoxHeader
          title={t('training:continue_training_count', {
            value: continueTrainingList?.length,
          })}
          fontSize='18px'
          color={'#FFBC99'}
        />
        <Flex gap={4} marginTop={4} overflow={'scroll'} maxWidth={'95%'}>
          {loading && (
            <Center width={'full'}>
              <Loader />
            </Center>
          )}
          {!loading &&
            continueTrainingList?.length > 0 &&
            continueTrainingList?.map((list) => (
              <Flex
                key={list?.eid}
                width={'fit-content'}
                position={'relative'}
                flexDirection={'column'}
                boxSize={'170px'}
              >
                <Box boxSize={'170px'} position={'relative'}>
                  <Image
                    src={
                      list?.thumbnail ||
                      'https://sop-uploads-staging.s3.amazonaws.com/extras/1002.png'
                    }
                  />
                </Box>
                <FontAwesomeIcon
                  icon={faPlay as IconProp}
                  color={'#2a85ff'}
                  style={{
                    position: 'absolute',
                    top: '100px',
                    zIndex: '1',
                    right: '12px',
                    padding: '8px 11px',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    list?.eid && history.push('/training-play/' + list?.eid);
                  }}
                />
                <Box
                  backgroundColor={list?.thumbnailColor || '#ffbc99'}
                  position={'absolute'}
                  bottom={'0px'}
                  width={'full'}
                  borderBottomRightRadius={'10px'}
                  borderBottomLeftRadius={'10px'}
                  filter={'brightness(95%)'}
                  padding={2}
                >
                  <Text
                    margin={'-5px 0'}
                    as={'b'}
                    isTruncated
                    maxWidth={'100px'}
                  >
                    {titleHandler(list?.title)}
                  </Text>
                  <Text margin={'-5px 0'} color={'#475056'} fontSize={'10px'}>
                    {moment(list?.createdAt)?.format('Do MMM, YYYY')}
                  </Text>
                </Box>
                {/* <Text backgroundColor={list?.thumbnailColor} bottom={'9px'} position={'absolute'}>{list?.title}</Text> */}
              </Flex>
            ))}
          {!loading && continueTrainingList?.length === 0 && (
            <Center width={'full'}>
              <Text>{t('common:no_data_found')}</Text>
            </Center>
          )}
        </Flex>
      </div>
      {trainingProgressModal && (
        <CustomPathCarousel
          isOpen={trainingProgressModal}
          onClose={closeModalHandler}
          selectedTrainingData={selectedTrainingData}
          getSingleTrainingHandler={getTrainingByIdHandler}
          userId={userId}
        />
      )}
    </Card>
  ) : null;
};

export default ContinueTraining;

import React, { FC, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'react-i18next';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import FormInput from 'atoms/FormInput';
import PrimaryButton from 'atoms/PrimaryButton';
import Dropdown, { SelectOption } from 'atoms/Dropdown';
import icon from '../../assets/images/delightreeIcon.svg';
import { EMAIL_REGEX } from '../../utils/constants';
import { INITIALIZE } from '../../appRoutes';
import { CreateDemo, Response, VariableInput } from './demo-business.graphql';

interface IFormInput {
  businessName: string;
  businessType: SelectOption;
  locationCount: SelectOption;
  phone: string;
  userEmail: string;
  userName: string;
}

const locations: SelectOption[] = [
  {
    label: '1 - 5',
    value: '1-5',
  },
  {
    label: '6 - 15',
    value: '6-15',
  },
  {
    label: '16 - 50',
    value: '16-50',
  },
  {
    label: 'more than 50',
    value: '50+',
  },
];

interface IProps {}

const NewBusinessStart: FC<IProps> = () => {
  const { t } = useTranslation(['common', 'auth']);
  const history = useHistory();
  const submitRef = useRef(false);
  const { boot, update, show } = useIntercom();
  const { control, handleSubmit } = useForm<IFormInput>();

  useEffect(() => {
    boot();
    update({
      hideDefaultLauncher: false,
      alignment: 'right',
      horizontalPadding: 20,
      verticalPadding: 20,
    });
    return () => {
      update({
        hideDefaultLauncher: true,
        alignment: 'left',
        horizontalPadding: 50,
        verticalPadding: 50,
      });
    };
  }, []);

  const [createBusinessDemo, { loading }] = useMutation<
    Response,
    VariableInput
  >(CreateDemo, {
    onCompleted: (response) => {
      update({
        hideDefaultLauncher: true,
        alignment: 'left',
        horizontalPadding: 50,
        verticalPadding: 50,
      });
      history.replace(INITIALIZE, {
        ...response.CreateDemoBusiness,
      });
    },
  });

  const restaurants = useMemo((): SelectOption[] => {
    return [
      {
        label: 'Restaurant',
        value: 'Restaurant',
      },
      {
        label: 'Spa, salon, or beauty',
        value: 'Spa, salon, or beauty',
      },
      {
        label: 'Retail',
        value: 'Retail',
      },
    ];
  }, []);

  const onFinish = async (values: IFormInput) => {
    if (submitRef.current) {
      return;
    }
    submitRef.current = true;
    try {
      await createBusinessDemo({
        variables: {
          input: {
            ...values,
            businessType: values.businessType.value,
            locationCount: values.locationCount.value,
          },
        },
      });
      submitRef.current = false;
    } catch (e) {
      submitRef.current = false;
    }
  };

  return (
    <Box height='100%' maxH='100%' bg='#E5E5E5' overflowY='auto' pos='relative'>
      <Flex align='center' w='full' h='70px' px='40px' bg='white'>
        <Image height='40px' width='46px' src={icon} />
      </Flex>

      <Flex justify='center' py='12px' my='40px'>
        <Box
          maxW='700px'
          width='100%'
          bg='white'
          padding='48px'
          pt='32px'
          borderRadius='20px'
          border='1px solid #CCCCCC'
        >
          <Box fontFamily='Inter' textAlign='center' mb={5}>
            <Box fontWeight='700' fontSize='30px' color='#1A1D1F'>
              {t('auth:get_started')} 🎉
            </Box>
            <Box
              fontWeight='400'
              fontSize='16px'
              lineHeight='30px'
              color='#555555'
            >
              {t('auth:onboard_desc')}
            </Box>
          </Box>
          <form onSubmit={handleSubmit(onFinish)}>
            <TitleHeader title={t('auth:full_name')} isRequired />
            <Controller
              name='userName'
              control={control}
              defaultValue=''
              rules={{
                required: t('validation.name_required'),
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={1} mb={3} isInvalid={!!fieldState.error}>
                    <FormInput type='text' placeholder='John Doe' {...field} />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <TitleHeader title={'Email'} isRequired />
            <Controller
              name='userEmail'
              control={control}
              defaultValue=''
              rules={{
                required: t('validation.email_required'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('validation.email_invalid'),
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={1} mb={3} isInvalid={!!fieldState.error}>
                    <FormInput
                      type='email'
                      placeholder='john@delightree.com'
                      {...field}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <TitleHeader
              title={t('auth:business_name')}
              isRequired
              desc={t('auth:business_name_desc')}
            />
            <Controller
              name='businessName'
              control={control}
              defaultValue=''
              rules={{
                required: t('auth:validation.business_required'),
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={1} mb={3} isInvalid={!!fieldState.error}>
                    <FormInput
                      type='text'
                      placeholder={t('auth:business_name_placeholder')}
                      {...field}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <TitleHeader
              title={t('auth:no_location')}
              desc={t('auth:no_location_desc')}
            />
            <Controller
              name='locationCount'
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={1} mb={3} isInvalid={!!fieldState.error}>
                    <Dropdown
                      options={locations}
                      className='dropdown'
                      placeholder='6-15'
                      inputStyle={{
                        border: 'none',
                        borderColor: 'transparent',
                        borderRadius: '12px',
                        fontSize: '15px',
                        outline: 'none',
                      }}
                      getOptionValue={(option) => option?.value ?? option}
                      formatOptionLabel={(option) => {
                        if (option?.label) {
                          return option.label;
                        }
                        return locations.find(
                          (value) => value.value === (option?.value ?? option)
                        )?.label;
                      }}
                      {...field}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <TitleHeader title={t('auth:business_type')} isRequired />
            <Controller
              name='businessType'
              control={control}
              rules={{
                required: t('auth:validation.business_type_required'),
              }}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={1} mb={3} isInvalid={!!fieldState.error}>
                    <Dropdown
                      options={restaurants}
                      className='dropdown'
                      placeholder={t('auth:restaurant')}
                      inputStyle={{
                        border: 'none',
                        borderColor: 'transparent',
                        borderRadius: '12px',
                        fontSize: '15px',
                        outline: 'none',
                      }}
                      getOptionValue={(option) => option?.value ?? option}
                      formatOptionLabel={(option) => {
                        if (option?.label) {
                          return option.label;
                        }
                        return restaurants.find(
                          (value) => value?.value === (option?.value ?? option)
                        )?.label;
                      }}
                      {...field}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <TitleHeader title={t('auth:phone')} />
            <Controller
              name='phone'
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <FormControl mt={1} mb={8} isInvalid={!!fieldState.error}>
                    <FormInput
                      type='text'
                      placeholder='+1 456 435 765'
                      {...field}
                    />
                    <FormErrorMessage>
                      {fieldState?.error?.message}
                    </FormErrorMessage>
                  </FormControl>
                );
              }}
            />

            <PrimaryButton
              type='submit'
              title={t('auth:try_delightree')}
              colorScheme='blue'
              isLoading={loading}
            />
          </form>

          <Flex justify='center' mt='20px'>
            <Button
              variant='link'
              fontSize='14px'
              color='#777777'
              fontWeight='500'
              onClick={() => show()}
            >
              {t('auth:need_help')}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default NewBusinessStart;

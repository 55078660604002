import { Divider, Flex, Image, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MessageButton } from 'atoms';
import { ILocationDetails } from 'pages/LocationDetails/utils/location-details.types';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';

interface IProps {
  details: ILocationDetails | undefined;
}

const InfoIconWithText: FC<{ icon: IconProp; color?: string }> = ({
  icon,
  children,
  color,
}) => (
  <Flex align='center' gap='10px'>
    <FontAwesomeIcon icon={icon} fontSize='20px' color={color || '#6F767E'} />
    <Text
      color='#2A85FF'
      fontWeight={500}
      fontSize='14px'
      isTruncated
      maxW='300px'
    >
      {children}
    </Text>
  </Flex>
);

const LocationContactDetails: FC<IProps> = ({ details }) => {
  const { t } = useTranslation(['common']);
  const { locationId } = useParams<{ locationId: string }>();
  const [showChatModal, setShowChatModal] = useState(false);
  return (
    <Flex direction='column' gap='20px'>
      <Flex gap='10px'>
        {details?.profilePic ? (
          <Image src={details?.profilePic} boxSize='30px' />
        ) : (
          <FontAwesomeIcon
            icon={faLocationDot as IconProp}
            color='white'
            fontSize='30px'
            style={{
              backgroundColor: '#FFD88D',
              borderRadius: '9px',
              padding: '15px 20px',
            }}
          />
        )}
        <Flex direction='column'>
          <Text fontWeight={600} fontSize='sm' m={0} isTruncated maxW='300px'>
            {details?.name}
          </Text>
          <Text color='#33383F'>{details?.address?.address}</Text>
          <Text color='#6F767E'>{details?.timezone}</Text>
        </Flex>
      </Flex>
      <Flex direction='column' gap='10px'>
        {details?.locationPhone?.map((phone, index) => (
          <InfoIconWithText key={index} icon={faPhone as IconProp}>
            {phone}
          </InfoIconWithText>
        ))}
        <Divider />
        {details?.locationEmail?.map((email, index) => (
          <InfoIconWithText key={index} icon={faEnvelope as IconProp}>
            {email}
          </InfoIconWithText>
        ))}
      </Flex>
      <MessageButton
        size='lg'
        colorScheme='blue'
        onClick={() => setShowChatModal(true)}
      />
      {showChatModal && (
        <SingleUserChatModal
          usePortal
          onClose={() => setShowChatModal(false)}
          selectedUser={locationId}
        />
      )}
    </Flex>
  );
};

export default LocationContactDetails;

import styled from '@emotion/styled';

export const CustomFilterAccordionWrapper = styled.div`
    .rmsc .dropdown-container {
        border: none;
    }

    .width-45 {
        width: 45%;
    }

    .flex {
        display: flex;
    }

    .flex-row {
        flex-direction: row;
    }

    .margin-top-1 {
        margin-top: 1rem;
    }

    .single-dropdown {
        background-color: white;
        border-color: white;
        width: 45%;
    }

    .custom-filter-accordion-container {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }

    .custom-filter-accordion-collapse-panel-container {
        display: flex;
        align-items: center;
    }

    .custom-filter-accordion-collapse-panel-container-img {
        margin-right: 10px;
        min-width: 20px;
        max-width: 20px;
        min-height: 20px;
        max-height: 20px;
    }

    .custom-filter-accordion-collapse-panel-container-span {
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
    }

    .custom-filter-accordion-collapse-panel-container-main {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #f7f7f7;
        padding: 2rem;
        border-radius: 12px;
    }

    .custom-filter-accordion-collapse-panel-container-main-loc {
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 1rem;
    }

    .custom-filter-accordion-collapse-panel-container-main-dropdowns {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
    }

    .custom-filter-accordion-collapse-panel-container-main-first-text-div {
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin: 0 34px 0 0;
        padding: 12px 12px 12px 16px;
        border-radius: 12px;
        background-color: #ffffff;
        width: 100%;
    }

    .custom-filter-accordion-collapse-panel-container-main-text-condition {
        margin: 0 1rem;
        font-weight: 600;
    }

    .custom-filter-accordion-collapse-panel-container-main-operators {
        padding: 12px 18px 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 15px;
        cursor: pointer;
    }

    .custom-filter-accordion-collapse-panel-container-main-button {
        width: 232px;
        height: 48px;
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 10px 409.9px 0 0;
        padding: 2px 0 2px 16px;
        border-radius: 7px;
        border: solid 1px #ccc;
        cursor: pointer;
    }
`;

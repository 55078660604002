import { Flex } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { HeaderColors } from 'shared/headerColors/header-colors';
import GenericColorHeader from 'sub-components/GenericColorHeader';
import CommonLabel from 'sub-components/LocationLaunchDashboard/InProgress/Singleton/CommonLabel';
import { ILauncherTaskDataByStatus } from 'sub-components/LocationLaunchDetail/types/types';
import TaskListItem from '../../Singleton/TaskListItem';
import {
  LauncherLocationByIdDetail,
  LauncherLocationDetailTask,
} from 'pages/launcher/details/types/location-launch-details.types';

interface IStatusSectionProps {
  data: LauncherLocationByIdDetail | undefined;
  launchId: string;
  taskClickHandler: (task: LauncherLocationDetailTask) => void;
}
const StatusSection: FC<IStatusSectionProps> = (props) => {
  const { data, launchId, taskClickHandler } = props;

  const convertedData = useMemo(() => {
    let overdueData =
      data?.tasks?.filter((d) => d.taskStatus === 'overdue') || [];
    let nextWeekData =
      data?.tasks?.filter((d) => d.taskStatus === 'inProgress') || [];
    let completedData =
      data?.tasks?.filter((d) => d.taskStatus === 'complete') || [];
    let lockedData =
      data?.tasks?.filter((d) => d.taskStatus === 'locked') || [];
    return [
      ...(overdueData?.length > 0
        ? [
            {
              title: `Overdue (${overdueData?.length})`,
              bandColor: HeaderColors.Red.Dark,
              tasks: overdueData,
            },
          ]
        : []),
      ...(nextWeekData?.length > 0
        ? [
            {
              title: `Due next (${nextWeekData?.length})`,
              bandColor: HeaderColors.Blue,
              tasks: nextWeekData,
            },
          ]
        : []),
      ...(completedData?.length > 0
        ? [
            {
              title: `Completed (${completedData?.length})`,
              bandColor: HeaderColors.Green,
              tasks: completedData,
            },
          ]
        : []),
      ...(lockedData?.length > 0
        ? [
            {
              title: `Locked (${lockedData?.length})`,
              bandColor: HeaderColors.Green,
              tasks: lockedData,
            },
          ]
        : []),
    ];
  }, [data]);

  return (
    <Flex flexDir='column' gap={4}>
      {convertedData.map((group, groupIndex) => (
        <Flex key={group.title + groupIndex} gap={4} flexDir='column'>
          <Flex align='center' justify='space-between'>
            <GenericColorHeader title={group.title} color={group.bandColor} />
          </Flex>
          {group.tasks.map((task, taskIndex) => (
            <TaskListItem
              key={taskIndex}
              selectedFilter='status'
              task={task}
              launchId={launchId}
              taskClickHandler={taskClickHandler}
            />
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

export default StatusSection;

import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Question } from '../../create-form/form.types';
import OptionInput from './OptionInput';
import { FormPreviewInput } from '../form-preview.types';
import OtherOptionInput from './OtherOptionInput';

interface IProps {
  question: Question;
  currentIndex: number;
  isDisabled?: boolean;
  isPreview?: boolean;
  isReadOnly?: boolean;
}

const Options: FC<IProps> = ({
  question,
  currentIndex,
  isDisabled,
  isPreview,
  isReadOnly,
}) => {
  const { t } = useTranslation('form');
  const { control } = useFormContext<FormPreviewInput>();

  return (
    <div>
      <Controller
        name={`response.${currentIndex}.responseId`}
        control={control}
        defaultValue={[]}
        rules={{
          required: {
            value: question?.isRequired === true,
            message: t('validation.field_required'),
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              display='flex'
              flexDir='column'
              gap={4}
              mt={3}
              isInvalid={!!fieldState.error}
              isDisabled={isDisabled}
            >
              {question?.options?.map((data, index) => {
                if (data.isOther) {
                  return (
                    <OtherOptionInput
                      key={index}
                      data={data}
                      qType={question.qType}
                      hasImageNote={question.hasImageNote}
                      field={field}
                      currentIndex={currentIndex}
                      isDisabled={isDisabled}
                      isReadOnly={isReadOnly}
                    />
                  );
                }

                return (
                  <OptionInput
                    key={index}
                    data={data}
                    qType={question.qType}
                    hasImageNote={question.hasImageNote}
                    field={field}
                    isPreview={isPreview}
                  />
                );
              })}

              {/*{question?.hasOtherOption ? (*/}
              {/*  <OtherOptionInput*/}
              {/*    data={*/}
              {/*      {*/}
              {/*        isOther: true,*/}
              {/*      } as Question['options'][number]*/}
              {/*    }*/}
              {/*    qType={question.qType}*/}
              {/*    field={field}*/}
              {/*    isPreview={isPreview}*/}

              {/*  />*/}
              {/*) : null}*/}

              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </div>
  );
};

export default Options;

import React, { FC } from 'react';
import ContentItem from '../SearchModal/component/ContentItem';
import { UserDetailResponse } from './details.graphql';

import locationIcon from '../../assets/images/location.svg';
import shieldIcon from '../../assets/images/shieldIcon.svg';
import mailIcon from '../../assets/images/UI icon_mail_light.svg';

interface IProps {
  data?: UserDetailResponse['userById'];
}

const UserDetails: FC<IProps> = ({ data }) => {
  if (data?.type !== 'user') {
    return null;
  }

  return (
    <>
      <ContentItem
        type={data.type}
        leftIcon={mailIcon}
        title={data.email || data.username}
      />
      <ContentItem
        type={data.type}
        leftIcon={shieldIcon}
        title={data.authRole || '- -'}
      />
      <ContentItem
        type={data.type}
        leftIcon={locationIcon}
        title={data?.locations?.[0]?.name || '- -'}
      />
    </>
  );
};

export default UserDetails;

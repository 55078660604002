import { useEffect } from 'react';

export function useHideIntercomButton(): void {
  useEffect(() => {
    const htmlElement = document
      .getElementsByName('intercom-launcher-frame')
      .item(0);
    const element = document
      .getElementsByClassName('intercom-lightweight-app')
      .item(0);

    htmlElement?.setAttribute?.('style', 'z-index: 0');
    element?.setAttribute?.('style', 'z-index: 0');
    return () => {
      htmlElement?.removeAttribute?.('style');
      element?.removeAttribute?.('style');
    };
  }, []);
}

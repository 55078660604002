import React, { FC } from 'react';
import SingleUserChatModal from 'ui-components/SingleUserChatModal';
import FormResponseModal from './FormResponseModal';
import { IUserResponseFromUrl } from './FormResponseTable';

interface IProps {
  userResponseFromUrl: IUserResponseFromUrl;
  formId: string;
  setUserResponseFromUrl: React.Dispatch<
    React.SetStateAction<IUserResponseFromUrl>
  >;
}

const UserResponseFromUrlModal: FC<IProps> = ({
  userResponseFromUrl,
  formId,
  setUserResponseFromUrl,
}) => {
  return (
    <>
      {userResponseFromUrl?.show && (
        <FormResponseModal
          isOpen={userResponseFromUrl?.show}
          formId={formId}
          onClose={(type?: 'close' | 'other') => {
            if (type === 'close' || !type) {
              setUserResponseFromUrl({
                ...userResponseFromUrl,
                show: false,
                showChat: false,
              });
            } else {
              setUserResponseFromUrl({
                ...userResponseFromUrl,
                show: true,
                showChat: true,
              });
            }
          }}
          details={userResponseFromUrl?.userData!}
          setShowChat={() => {}}
        />
      )}
      {userResponseFromUrl?.showChat && (
        <SingleUserChatModal
          selectedUser={userResponseFromUrl?.userData?.createdBy?.eid}
          onClose={() => {
            setUserResponseFromUrl({
              ...userResponseFromUrl,
              showChat: false,
            });
          }}
          usePortal
        />
      )}
    </>
  );
};

export default UserResponseFromUrlModal;

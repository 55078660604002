import styled from "@emotion/styled";

export const TrainingPathSubContainerWrapper = styled.div`
    .create-training-main-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
    }

    .create-training-heading {
        font-size: 32px;
        font-weight: 600;
        color: #272b30;
    }

    .create-training-breadcrumb {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: -0.15px;
        text-align: left;
        color: #6f767e;
        margin-bottom: 20px;
        margin-left: 1px;
        margin-top: 10px;
    }
    .create-training-breadcrumb:hover {
        color: #1a1d1f;
    }
`;
import React, { FC } from 'react';
import { Flex, IconButton, Tooltip } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteIcon } from '../../../assets/images/trash.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit-dark.svg';

import { Authorize, AuthRole } from '../../../authorization';

interface IProps {
  onClick?: (action: 'edit' | 'delete') => void;
}

const LocationSettingAction: FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation(['common', 'setting']);

  return (
    <Authorize
      permittedFor='user'
      permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
    >
      <Flex gap='4px' className='action-button'>
        <Tooltip
          label={t('setting:editField')}
          hasArrow
          borderRadius='4px'
          padding='4px 10px'
        >
          <IconButton
            borderRadius='full'
            aria-label='edit'
            variant='ghost'
            icon={<EditIcon />}
            onClick={() => onClick?.('edit')}
          />
        </Tooltip>

        <Tooltip
          label={t('common:delete')}
          hasArrow
          borderRadius='4px'
          padding='4px 8px'
        >
          <IconButton
            borderRadius='full'
            aria-label='delete'
            variant='ghost'
            icon={<DeleteIcon />}
            onClick={() => onClick?.('delete')}
          />
        </Tooltip>
      </Flex>
    </Authorize>
  );
};

export default LocationSettingAction;

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from '@apollo/client/utilities';
import {
  DemoActionCell,
  DemoProgressCell,
  DemoStatusCell,
} from '../../../../sub-components/TrainingPathTable/DemoComponent';

import PathName from '../../Training/PathName';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useUserDataSelector } from '../../../../hooks';
import { Column } from '../../../../sub-components/ChakraTable/SortingTable';

import { MyTrainingModal } from '../modal';
import { useRetakeTraining } from '../../../../ui-components/Training';
import { SortByName } from '../../../../utils/sorting';
import DownloadButton from '../Component/DownloadButton';
import { Row } from 'react-table';

export function SortByProgress(
  a: Row<MyTrainingModal>,
  b: Row<MyTrainingModal>
): number {
  const viewCountA = a.original.totalViewedCount;
  const maxCountA = a.original.maxCount;

  const valA = (viewCountA / maxCountA) * 100;

  const viewCountB = b.original.totalViewedCount;
  const maxCountB = b.original.maxCount;

  const valB = (viewCountB / maxCountB) * 100;

  return valB - valA;
}

export const useModelLayer = (
  backToTitle: string | undefined,
  shouldHideActionLabel: boolean | undefined,
  shouldHideAction: boolean | undefined,
  shouldHideCertificateDownload: boolean | undefined,
  reassignTrainingHandler: (trainingId: string) => void,
  setProgressData: React.Dispatch<
    React.SetStateAction<{
      allMilestoneData: any[];
      showEmptyState: boolean;
      userData: any;
      trainingName?: string | undefined;
    }>
  >,
  setProgressTimelineModal: React.Dispatch<React.SetStateAction<boolean>>,
  setPseudoTrainingData: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      item: MyTrainingModal;
    }>
  >,
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>
) => {
  const { t, i18n } = useTranslation(['common', 'training']);
  const history = useHistory();
  const retakeTraining = useRetakeTraining();
  const userObject = useUserDataSelector((state) => state);
  const retakeHandler = useCallback(
    (training: MyTrainingModal) => {
      if (training.isDemoAccount) {
        localStorage.setItem('retake-training', training.eid);
      }
      return reassignTrainingHandler(training.eid);
    },
    [userObject?.eid]
  );
  const onRetakeClick = useCallback(
    (training: MyTrainingModal) => {
      return new Promise((resolve) => {
        retakeTraining({
          title: t('training:retake_confirm'),
          onOk: async () => {
            await retakeHandler(training);
            resolve(t('common:success'));
          },
          onCancel: () => resolve(null),
        });
      });
    },
    [retakeHandler]
  );

  const columns = useMemo((): Column<MyTrainingModal>[] => {
    const actionObject = {
      Header: shouldHideActionLabel ? '' : t('common:action'),
      accessor: 'viewCount',
      id: 'action',
      width: '15%',
      Cell: ({ cell: { value, row } }) => {
        return (
          <DemoActionCell
            isDemoAccount={row?.original.isDemoAccount}
            index={row.index}
            viewCount={value}
            totalCount={row?.original.totalCount}
            onResumeClick={() => onResumeClick(row.original)}
            onRetakeClick={() => onRetakeClick(row.original)}
          />
        );
      },
      disableSortBy: true,
    };
    const certificateDownloadObject = {
      Header: '',
      accessor: 'certificateId',
      id: 'download',
      Cell: ({ cell: { value, row } }) => {
        return (
          <DownloadButton
            certificateId={value}
            flag={row.original.isDownloadable}
            totalViewedCount={row.original.totalViewedCount}
            maxCount={row.original.maxCount}
          />
        );
      },
      disableSortBy: true,
    };
    let tempObj: any[] = [
      {
        Header: t('common:name'),
        accessor: 'title',
        width: '41%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <PathName
              pathName={value}
              thumbnail={row?.original.thumbnail}
              status={row?.original.status}
              updatedAt={row?.original.updatedAt}
              index={row.index}
            />
          );
        },
        sortType: SortByName,
      },
      {
        Header: t('common:status'),
        accessor: 'viewCount',
        width: '22%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <DemoStatusCell
              viewCount={value}
              totalCount={row?.original.totalCount}
              isDemoAccount={row?.original.isDemoAccount}
              index={row.index}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('training:progress'),
        accessor: 'maxCount',
        width: '22%',
        Cell: ({ cell: { value, row } }) => {
          return (
            <DemoProgressCell
              onClick={progressDataHandler}
              item={row.original}
              maxLength={value}
              isDemoAccount={row?.original?.isDemoAccount}
              index={row?.index}
            />
          );
        },
        sortType: SortByProgress,
      },
    ];
    if (!shouldHideAction && !shouldHideCertificateDownload) {
      tempObj.push(actionObject);
      tempObj.push(certificateDownloadObject);
    } else if (!shouldHideCertificateDownload) {
      tempObj.push(certificateDownloadObject);
    } else if (!shouldHideAction) {
      tempObj.push(actionObject);
    }
    return tempObj;
  }, [i18n.language, onRetakeClick]);
  const onResumeClick = useCallback(
    async (training: MyTrainingModal) => {
      if (userObject?.type === 'user') {
        // await fetchTrainingData(training.eid, true);
        if (backToTitle) {
          return history.push('/training-play/' + training.eid, {
            backToTitle: backToTitle,
          });
        } else {
          return history.push('/training-play/' + training.eid);
        }
      } else {
        return setPseudoTrainingData({
          show: true,
          item: training,
        });
      }
    },
    [userObject?.type]
  );
  const onPageChangeHandler = (page: number) => {
    setSelectedPage(page);
  };

  const progressDataHandler = (userData: MyTrainingModal) => {
    let _userData = cloneDeep(userData);
    console.log('_userData : ', _userData);
    const totalViewRoot = _userData?.totalViewedCount || 0;
    const userId = userObject?.eid;
    const processedUserData: any[] = [];
    if (_userData.userProgress && _userData.userProgress.viewedContents) {
      _userData.trainingContentTiny.forEach((card) => {
        let userViewedDate = '';
        const viewedContent = _userData?.userProgress?.viewedContents?.find(
          (content) => {
            if (content.uid === card.eid) {
              userViewedDate = content?.lastViewedAt;
              return true;
            }
          }
        );
        const newCard = {
          ...card,
          completed: viewedContent ? true : false,
          status: viewedContent ? true : false,
          watchDetails: {
            contentLastUpdated: _userData?.updatedAt,
            userLastWatched: userViewedDate,
            didUserWatchedLatestContent: card?.updatedAt
              ? new Date(userViewedDate) >= new Date(card?.updatedAt)
              : true,
          },
        };
        processedUserData.push(newCard);
      });
    }
    console.log('Processed User Data : ', processedUserData);
    let moments = (_userData?.userProgress?.viewedContents &&
      _userData?.userProgress?.viewedContents.map((content: any) =>
        moment(content?.lastViewedAt)
      )) || [moment()];
    let maxDate = moment.max(moments).format('DD MMM YYYY, hh:mm A');
    processedUserData?.push({
      status:
        _userData?.userProgress?.viewedContents?.length ===
        _userData?.trainingContentTiny?.length
          ? true
          : false,
      date: maxDate,
      lastViewedAt: _userData?.userProgress?.lastViewed,
      contentLastUpdate: _userData?.updatedAt,
    });
    setProgressData({
      allMilestoneData: processedUserData,
      showEmptyState: !totalViewRoot,
      userData: _userData,
      trainingName: _userData?.title,
    });
    setProgressTimelineModal(true);
    console.log(processedUserData);
  };
  return { columns, onPageChangeHandler };
};

import React, { FC, useState } from 'react';
import {
  Box,
  Center,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { AccessibilityType } from 'types';
import { NotAccessibleContainer } from 'ui-components';

import SubmitForm from './SubmitForm';
import FormHeader from './FormHeader';
import Loader from '../../Loader';
import FormNotAccessible from '../component/FormNotAccessible';

interface IProps {
  formId: string;
}

const SubmitFormComponent: FC<IProps> = ({ formId }) => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState<any>();
  const [unAuthorised, setUnauthorised] = useState<AccessibilityType>();

  const onDataLoad = (data: any) => {
    if (data.eid) {
      setFormData(data);
    } else {
      setFormData(undefined);
    }
  };

  const onSubmitted = () => {
    onOpen();
    setTimeout(onClose, 7000);
  };

  const onCloseComplete = () => history.goBack();

  const onUnauthorised = (type: AccessibilityType) => setUnauthorised(type);

  if (unAuthorised) {
    return (
      <NotAccessibleContainer>
        <CloseButton ml='auto' onClick={onCloseComplete} />
        <FormNotAccessible type={unAuthorised} />
      </NotAccessibleContainer>
    );
  }

  return (
    <Box
      maxW='800px'
      width='full'
      mx='auto'
      p='24px'
      borderRadius='10px'
      backgroundColor='white'
      my='16px'
    >
      <FormHeader formData={formData} />
      <SubmitForm
        formId={formId}
        onDataLoad={onDataLoad}
        onSubmitted={onSubmitted}
        onUnauthorised={onUnauthorised}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        onCloseComplete={onCloseComplete}
        size='full'
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg='transparent'>
          <ModalBody>
            <Center height='100vh' gap={2}>
              <Loader size='xl' />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SubmitFormComponent;

import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import MOUNTAIN_GIF from '../../../../../../../assets/images/mountain_gif.gif';
import { useTranslation } from 'react-i18next';
import GenericColorHeader from 'sub-components/GenericColorHeader';

interface IProps {
  cardData: any;
}

const MilestoneModalPreview: FC<IProps> = ({ cardData }) => {
  const { t } = useTranslation(['card', 'common', 'training']);
  return (
    <Flex w='40%' flexDir='column'>
      <GenericColorHeader color='#b5e4ca' title={t('common:preview')} />
      <Flex h='600px' justify='center' align='center' mt='1rem' p='5px 10px'>
        <Flex
          h='600px'
          border='1px solid #DDDDDD'
          borderRadius='14px'
          flexDir='column'
          justify='center'
          align='center'
          w='full'
          overflowY='scroll'
          gap={'3rem'}
          p={4}
        >
          <Text fontWeight={700} textAlign='center' fontSize='20px'>
            {t('card:milestoneHeading')}
          </Text>
          <img
            src={cardData?.milestoneUrl || MOUNTAIN_GIF}
            alt='milestone-flag'
          />
          <Text fontSize='16px' fontWeight='bold' p='0 16px 16px'>
            {cardData?.mileStoneName}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MilestoneModalPreview;

import React, { FC } from 'react';
import {
  CircularProgress,
  CircularProgressLabel,
  Image,
} from '@chakra-ui/react';
import { IconImage } from '../../../../ui-components';

interface IProps {
  value: number;
  img?: string;
  label?: string;
  thickness: number;
  overDue?: boolean;
}

const RoundProgress: FC<IProps> = ({
  value,
  img,
  label,
  thickness,
  overDue,
}) => {
  return (
    <CircularProgress
      value={overDue ? value || 100 : value}
      color={value < 100 && overDue ? '#FF6A55' : 'green.400'}
      thickness={thickness}
    >
      <CircularProgressLabel
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        {img ? (
          <IconImage name='' thumbnail={img} boxSize={34} borderRadius='50%' />
        ) : label ? (
          label
        ) : (
          value + '%'
        )}
      </CircularProgressLabel>
    </CircularProgress>
  );
};

export default RoundProgress;

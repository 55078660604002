import { gql } from '@apollo/client';

export const GET_CONTINUE_TRAINING_DATA = gql`
  query UserHomepageNew {
    userHomepageNew {
      resumeTrainings {
        title
        status
        thumbnail
        thumbnailColor
        createdAt
        updatedAt
        eid
      }
    }
  }
`;

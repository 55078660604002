import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts-new';

import { BoxHeader } from '../../../../../ui-components';

import { AxisPercentageDomain, toPercent } from '../../common';
import { useLocationGraphData } from './useLocationGraphData';
import { getToolTipConfig, GraphLoader } from '../../common/graph';

const CustomizedAxisTick: React.FC<any> = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor='end'
        fill='#2E2C34'
        transform='rotate(-90)'
      >
        {payload.value.length > 9
          ? `${payload.value.substring(0, 9)}...`
          : payload.value}
      </text>
    </g>
  );
};

interface IProps {}

const LocationPerformanceGraph: FC<IProps> = () => {
  const { t } = useTranslation(['task']);

  const { graphData, loading } = useLocationGraphData();

  return (
    <Flex
      flex={1}
      flexDir='column'
      borderRadius='10px'
      padding='16px 20px'
      bg='white'
      gap='24px'
    >
      <Flex flexDir='column' gap='10px'>
        <BoxHeader
          title={t('task:locationPerformance')}
          fontSize='18px'
          color='#CABDFF'
        />

        <Flex align='center' gap='10px'>
          <Box boxSize='8px' borderRadius='full' bg='#5542F6' />
          <Box color='#2E2C34' fontSize='14px'>
            {t('task:taskCompletionRate')}
          </Box>
        </Flex>
      </Flex>

      <GraphLoader isLoading={loading} minHeight={400}>
        <ResponsiveContainer width='100%' height={400}>
          <BarChart
            data={graphData}
            margin={{
              bottom: 50,
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray='3 3' />
            <XAxis
              axisLine={false}
              dataKey='name'
              height={40}
              interval={0}
              tick={<CustomizedAxisTick />}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={toPercent}
              domain={AxisPercentageDomain}
            />
            <Tooltip
              cursor={false}
              {...getToolTipConfig()}
              formatter={(value) => {
                return [`${value}% completed`, [] as any];
              }}
            />
            <Bar dataKey='completeRate' radius={4} fill='#5542F6' />
          </BarChart>
        </ResponsiveContainer>
      </GraphLoader>
    </Flex>
  );
};

export default LocationPerformanceGraph;

import React, { FC, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { toArray } from '../../../../../../../utils';
import { TaskFormItem } from '../common';
import FormListItem from './FormListItem';

export interface FormResource {
  eid: string;
  formData?: TaskFormItem;
}

interface IProps {
  values: FormResource[];
}

const FormListContainer: FC<IProps> = ({ values }) => {
  const itemList = useMemo(
    () => toArray(values).map((it) => it.formData!),
    [values]
  );

  return (
    <Flex
      flex={1}
      flexDir='column'
      overflow='auto'
      position='relative'
      gap='1px'
    >
      {itemList.map((item) => (
        <FormListItem key={item.eid} data={item} />
      ))}
    </Flex>
  );
};

export default FormListContainer;

import { gql } from '@apollo/client';

export const TASK_PLAY = gql`
  query TaskById($eid: String!) {
    TaskById(eid: $eid) {
      lastSession {
        name
        title
        contents {
          type
          id
          title
          isMandatory
          backgroundColor
          dateAdded
          _id
        }
        taskId
        taskItems
        startDate
        dueDate
        assignees {
          userId
          locations
          role
          authRole
          completedTasks {
            contentId
            isCompleted
            completedAt
          }
          isCompleted
          notifications {
            type
            template
            timestamp
            jobId
            _id
          }
          completedAt
        }
        createdAt
        updatedAt
        eid
        progress
        currentUserProgress
        itemCount
      }
    }
  }
`;

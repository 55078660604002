import React, { FC } from 'react';
import { Flex, Text, Tooltip } from '@chakra-ui/react';

interface IProps {
  noteText?: string;
}

const NotesText: FC<IProps> = ({ noteText }) => {
  if (!noteText) {
    return null;
  }
  return (
    // <Tooltip label={noteText} hasArrow>
    <Flex
      align='center'
      w='fit-content'
      maxW='100%'
      // cursor='pointer'
    >
      <Text m={0} p={0} fontSize='12px' color='#6F767E'>
        {noteText}
      </Text>
    </Flex>
    // </Tooltip>
  );
};

export default NotesText;

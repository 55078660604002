import React, { FC } from 'react';
import { useQuery } from '@apollo/client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessageLines } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { GroupMemberEntity } from '../../../../../types';

import { useMemberData } from './useMemberData';
import BaseChannelMemberList, { CMLProps } from './BaseChannelMemberList';
import {
  GroupMembersResponse,
  VIEW_CHANNEL_MEMBER,
} from './channel-members.graphql';

interface IProps extends CMLProps {
  onActionClick: (data: GroupMemberEntity) => void;
}

const ChannelMemberList: FC<IProps> = ({ group, onActionClick }) => {
  const { data, loading } = useQuery<GroupMembersResponse>(
    VIEW_CHANNEL_MEMBER,
    {
      fetchPolicy: 'network-only',
      variables: {
        guid: group?.guid,
      },
    }
  );

  return (
    <BaseChannelMemberList
      group={group}
      members={data?.FetchChatGroupMembers || []}
      loading={loading}
      onActionClick={onActionClick}
      actionIcon={
        <FontAwesomeIcon icon={faMessageLines as IconProp} color='#6F767E' />
      }
    />
  );
};

export default ChannelMemberList;

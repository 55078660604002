import React from 'react';
import { Rectangle } from 'recharts-new';
import { match, P } from 'ts-pattern';

const valueIsZero = P.when((val) => Number(val) === 0);

const CustomLaunchBar = (props: any) => {
  const config = match(props)
    .with(
      { fill: '#FF6A55', active: valueIsZero, completed: valueIsZero },
      () => {
        return {
          radius: [4, 4, 0, 0],
        };
      }
    )
    .with({ fill: '#2A85FF', completed: valueIsZero }, () => {
      return {
        radius: [4, 4, 0, 0],
      };
    })
    .otherwise(() => ({}));

  return <Rectangle {...props} {...config} />;
};

export default CustomLaunchBar;

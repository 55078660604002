import styled from '@emotion/styled';

export const ContainerWrapper = styled.div`
  background: white;
  border-radius: 12px;
  padding-block: 32px;
  padding-inline: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;

  table {
    background: unset;
  }

  table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`;

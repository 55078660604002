import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import PrimaryButton from 'atoms/PrimaryButton';
import doubleTickSvg from '../../../../../assets/images/doubleTick.svg';

interface IProps {
  count: number;
  exportDataLoading: boolean;
  generageUserWiseReportHandler: () => void;
}

const FormReportExportFooter: FC<IProps> = ({
  count,
  exportDataLoading,
  generageUserWiseReportHandler,
}) => {
  const { t } = useTranslation(['common', 'form']);

  return count > 0 ? (
    <Flex align='center' justify='space-between' mt='1rem'>
      <Flex gap={1} align='center'>
        <img
          alt='tick-mark-icon'
          src={doubleTickSvg}
          style={{
            maxWidth: '20px',
            minWidth: '20px',
            maxHeight: '20px',
            minHeight: '20px',
          }}
        />
        <Trans t={t} i18nKey='form:responseCount' count={count} />
      </Flex>
      <PrimaryButton
        width='auto'
        size='sm'
        isLoading={exportDataLoading}
        onClick={() => generageUserWiseReportHandler()}
        title={t('common:export')}
        colorScheme='blue'
        style={{
          paddingInline: '18px',
        }}
      />
    </Flex>
  ) : null;
};

export default FormReportExportFooter;

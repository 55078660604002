import React, { FunctionComponent, SVGProps } from 'react';
import { Center } from '@chakra-ui/react';
import { Trans } from 'react-i18next';
import FinalFlagTraining from '../../assets/images/training-progress-complete.png';
import { ReactComponent as MedalIcon } from '../../assets/images/medal.svg';
import MilestoneSuccess from '../../assets/images/milestone_success.png';

interface TimelineDataRenderProps {
  timelineData: any[];
  userName: string;
  t: any;
  milestoneSuccessImg: string;
}

const cssCalculator = (timelineData: any, currentIndex: any, type: any) => {
  let currentData = timelineData[currentIndex];
  let previousData = timelineData[currentIndex - 1];
  let nextData = timelineData[currentIndex + 1];
  if (type === 'backgroundImage') {
    // dotted line
    if (timelineData.length < 2) {
      return 'none';
    }
    if (timelineData.length > 1) {
      if (currentData.status && nextData.status) {
        return 'none';
      } else {
        return 'linear-gradient(black 33%, rgba(255, 255, 255, 0) 0%)';
      }
    }
  } else {
    // success plain green line
    if (timelineData.length < 2) {
      return 'none';
    }
    if (timelineData.length > 1) {
      if (currentData.status && nextData.status) {
        return '#83bf6e';
      } else {
        return 'none';
      }
    }
  }
};

export const timelineDataRender = ({
  timelineData,
  userName,
  t,
  milestoneSuccessImg,
}: TimelineDataRenderProps) => {
  const iconRenderHandler = (progress: any, index: number) => {
    if (progress?.status) {
      return (
        <>
          {progress?.type === 'milestone' && (
            <img
              src={milestoneSuccessImg}
              style={{ height: '28px', width: '31.5px' }}
            />
          )}
          {progress?.type !== 'milestone' && (
            <MedalIcon
              style={{ height: '28px', width: '31.5px' }}
              fill={'#83bf6e'}
            />
          )}
        </>
        // <img
        //   src={progress?.type === 'milestone' ? milestoneSuccessImg : medalIcon}
        //   style={{ height: '28px', width: '31.5px' }}
        // />
      );
    }
    return (
      <span
        style={{
          fontSize: '24px',
          fontWeight: '600',
          color: '#ff6a55',
        }}
      >
        {index + 1}
      </span>
    );
  };
  const finalFlagHandler = (progress: any, index: number) => {
    return (
      <img
        src={FinalFlagTraining}
        style={{ height: '38px', width: '55.5px' }}
      />
    );
  };
  return timelineData?.map((progress: any, index: number) => {
    return (
      <div key={index} id={`timeline-item-${index}`}>
        <div
          id={`timeline-item-${index}-row`}
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <Center
            style={{
              backgroundColor: progress.status
                ? 'rgba(181, 228, 202, 0.49)'
                : '#f4f4f4',
              height: '64px',
              width: '64px',
              maxWidth: '64px',
              minWidth: '64px',
              minHeight: '64px',
              maxHeight: '64px',
              marginRight: '17px',
              borderRadius: '8px',
            }}
          >
            {index + 1 < timelineData?.length &&
              iconRenderHandler(progress, index)}
            {index + 1 === timelineData?.length &&
              finalFlagHandler(progress, index)}
          </Center>
          <div id='timeline-status' style={{ marginTop: '5px' }}>
            {index + 1 < timelineData?.length && (
              <>
                {progress.status && (
                  <div>
                    <Trans
                      t={t}
                      i18nKey='training:timelineStatusWithDate'
                      values={{
                        date: progress?.date,
                        userName: userName,
                        training: progress?.title,
                      }}
                      components={{
                        date: (
                          <div
                            style={{
                              fontWeight: '500',
                              fontSize: '12px',
                              color: '#6f767e',
                            }}
                          />
                        ),
                        main: <div />,
                        username: (
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: '600',
                            }}
                          />
                        ),
                        status: (
                          <span
                            style={{
                              color: '#777777',
                              fontSize: '14px',
                            }}
                          />
                        ),
                        title: (
                          <span
                            style={{
                              color: '#83bf6e',
                              fontSize: '14px',
                            }}
                          />
                        ),
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {index + 1 === timelineData?.length && (
              <>
                {progress.status && (
                  <div id='finish-congrats'>
                    <Trans
                      t={t}
                      i18nKey='training:finishCongrats'
                      values={{
                        date: progress?.date,
                      }}
                      components={{
                        date: (
                          <div
                            style={{
                              fontWeight: '500',
                              fontSize: '12px',
                              color: '#6f767e',
                            }}
                          />
                        ),
                        main: <div />,
                        cong: (
                          <span
                            style={{
                              color: '#83bf6e',
                              fontSize: '14px',
                            }}
                          />
                        ),
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {index + 1 < timelineData?.length && (
              <>
                {!progress?.status && (
                  <div>
                    <Trans
                      t={t}
                      i18nKey='training:notStarted'
                      values={{
                        training: progress?.title,
                      }}
                      components={{
                        status: (
                          <div
                            style={{
                              fontWeight: '500',
                              fontSize: '12px',
                              color: '#6f767e',
                            }}
                          />
                        ),
                        name: (
                          <div
                            style={{
                              fontWeight: '600',
                              fontSize: '14px',
                            }}
                          />
                        ),
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {index + 1 === timelineData?.length && (
              <>
                {!progress?.status && (
                  <div>
                    <Trans
                      t={t}
                      i18nKey='training:notFinished'
                      components={{
                        status: (
                          <div
                            style={{
                              fontWeight: '500',
                              fontSize: '12px',
                              color: '#6f767e',
                            }}
                          />
                        ),
                        name: (
                          <div
                            style={{
                              fontWeight: '600',
                              fontSize: '14px',
                            }}
                          />
                        ),
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {index + 1 < timelineData.length && (
          <div
            id={`timeline-item-${index}-connector`}
            style={{
              width: '64px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: `${cssCalculator(
                  timelineData,
                  index,
                  'backgroundColor'
                )}`,
                width: '2px',
                height: '65px',
                backgroundImage: `${cssCalculator(
                  timelineData,
                  index,
                  'backgroundImage'
                )}`,
                backgroundSize: '3px 10px',
              }}
            />
          </div>
        )}
      </div>
    );
  });
};

import React, { FC } from 'react';
import { Divider, Flex, Skeleton } from '@chakra-ui/react';
import AnimateItemContainer from './AnimateItemContainer';

const RenderItemSkel: FC<{ align: 'start' | 'end' }> = ({ align }) => {
  return (
    <Flex flex={1} flexDir='column' gap={2} align={align}>
      <Skeleton
        height='10px'
        width={align === 'start' ? '40%' : '30%'}
        borderRadius='full'
      />
      <Skeleton height='13px' width='65%' borderRadius='full' />
      <Skeleton
        height='10px'
        width={align === 'end' ? '40%' : '30%'}
        borderRadius='full'
      />
    </Flex>
  );
};

const ProgressItemSkeleton: FC = () => {
  return (
    <AnimateItemContainer>
      <Flex
        flexDir='column'
        borderRadius='12px'
        bg='white'
        padding={5}
        gap={3}
        _hover={{
          boxShadow: 'lg',
        }}
      >
        <Flex flexDir='column' gap={2}>
          <Flex gap={4} justify='space-between' align='center' height='43px'>
            <Flex flexDir='column' gap={2}>
              <Skeleton height='14px' width='100px' borderRadius='full' />
              <Skeleton height='10px' width='100px' borderRadius='full' />
            </Flex>
            <Skeleton height='26px' width='80px' borderRadius='6px' />
          </Flex>
          <Skeleton height='12px' width='100%' borderRadius='full' />
          <Skeleton height='10px' width='100px' borderRadius='full' />
        </Flex>

        <Divider />

        <Flex justify='space-between' gap={4}>
          <RenderItemSkel align='start' />
          <RenderItemSkel align='end' />
        </Flex>

        <Divider />

        <Flex flexDir='column' gap={2}>
          <Skeleton height='10px' width='100px' borderRadius='full' />

          <Flex align='center' gap='6px'>
            <Skeleton boxSize='11px' borderRadius='full' />
            <Skeleton height='10px' width='120px' borderRadius='full' />
          </Flex>
        </Flex>
      </Flex>
    </AnimateItemContainer>
  );
};

export default React.memo(ProgressItemSkeleton);

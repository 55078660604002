import React, { FC } from 'react';
import { AbsoluteCenter, Box, Flex } from '@chakra-ui/react';
import Image from '../../../../ui-components/Image';

import finishIcon from '../../../../assets/images/finishIcon.svg';
import { getAvatar } from '../../../../utils';

interface IProps {
  index: number;
  isLastItem: boolean;
  isCompleted: boolean;
  milestoneColor?: string;
  icon?: string;
  isPowerUp?: boolean;
  nextCompleted?: boolean;
  currentlyActive?: boolean;
  userName?: string;
  profilePic?: string;
}

const ProgressItemBar: FC<IProps> = ({
  isCompleted,
  nextCompleted,
  index,
  isLastItem,
  milestoneColor,
  icon,
  isPowerUp,
  currentlyActive,
  profilePic,
  userName,
}) => {
  return (
    <Flex
      position='relative'
      flexDir='column'
      align='center'
      width='48px'
      minH='48px'
      alignSelf='stretch'
    >
      {milestoneColor && (
        <Box
          pos='absolute'
          width='calc(50%)'
          height='60px'
          bg={milestoneColor}
          right={0}
          transform='translateY(12px)'
        />
      )}

      {index === 0 ? (
        <Box width='10px' flex={1} zIndex={0} />
      ) : (
        <Box
          width='10px'
          flex={1}
          zIndex={0}
          bg={isCompleted ? '#83BF6E' : '#EFEFEF'}
        />
      )}

      {isLastItem ? (
        <Box width='10px' flex={1} zIndex={0} />
      ) : (
        <Box
          width='10px'
          flex={1}
          zIndex={0}
          bg={nextCompleted ? '#83BF6E' : '#EFEFEF'}
        />
      )}

      {currentlyActive ? (
        <AbsoluteCenter
          boxSize='36px'
          borderRadius='full'
          border='2px solid #FFFFFF'
        >
          <Image
            src={getAvatar({
              name: 'auth-user-profile',
              url: profilePic,
            })}
            width={32}
            height={32}
            style={{
              borderRadius: '100%',
            }}
          />
        </AbsoluteCenter>
      ) : (
        <AbsoluteCenter
          display='flex'
          alignItems='center'
          justifyContent='center'
          boxSize={isLastItem ? '26px' : '22px'}
          borderRadius='full'
          border='2px solid #FFFFFF'
          borderColor={isLastItem ? '#DDDDDD' : '#FFFFFF'}
          bg={isCompleted ? '#83BF6E' : isPowerUp ? '#FFC700' : '#D3DAE2'}
        >
          {isLastItem ? (
            <Image
              src={finishIcon}
              width={20}
              height={20}
              style={{
                background: '#fff',
                borderRadius: '100%',
              }}
            />
          ) : (
            icon && <Image src={icon} width={10} height={10} />
          )}
        </AbsoluteCenter>
      )}
    </Flex>
  );
};

export default ProgressItemBar;

import styled from '@emotion/styled';
import DashboardContainer from 'sub-components/DashboardContainer';

export const ChapterViewWrapper = styled(DashboardContainer)`
  .desktop-content-container {
    ::-webkit-scrollbar {
      width: 6px;
      height: 0;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: #d5d5d5;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #dfdfdf;
    }
  }
`;

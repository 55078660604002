import moment from 'moment';
import {
  LauncherLocationDetail,
  LauncherLocationDetailTask,
} from '../types/location-launch-details.types';

export interface TaskStatusDetail {
  category: string;
  title: string;
  sinceWhen?: string;
  completedAt?: string;
}

export interface IGroupedLocationData {
  categoryId: string;
  category: string;
  tasks: LauncherLocationDetailTask[];
}

export const validateDate = (dateStr: string | null): boolean => {
  return dateStr ? moment(dateStr).isValid() : false;
};

export const groupLocationDetails = (
  detail: LauncherLocationDetail
): IGroupedLocationData[] => {
  const groupedData: Record<string, IGroupedLocationData> = {};

  detail?.tasks?.forEach((task) => {
    const key = `${task?.categoryId}-${task?.category}`;
    if (!groupedData?.[key]) {
      groupedData[key] = {
        categoryId: task?.categoryId,
        category: task?.category,
        tasks: [],
      };
    }
    groupedData?.[key]?.tasks?.push(task);
  });

  return Object.values(groupedData);
};

import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  BaseFormStepper,
  useStepperAction,
  useStepperIsCompleted,
  useStepperIsFilled,
} from '../common';
import { IFormInput } from '../task.types';

import taskCheckCircle from '../../../../assets/images/task-check-circle.svg';
import taskDetailsIcon from '../../../../assets/images/task-details.svg';

interface IProps {}

const TaskDetailsStepper: FC<IProps> = () => {
  const { t } = useTranslation('task');
  const { handleSubmit } = useFormContext<IFormInput>();
  const isCompleted = useStepperIsCompleted('details');
  const isFilled = useStepperIsFilled('details');

  const [title, description] = useWatch<IFormInput, ['title', 'description']>({
    name: ['title', 'description'],
  });

  const { onSuccess, onError } = useStepperAction('details');

  return (
    <BaseFormStepper
      imageUrl={isFilled ? taskCheckCircle : taskDetailsIcon}
      isCompleted={isCompleted}
      title={isCompleted ? title : t('taskTitle')}
      caption={isCompleted ? description : t('titleDesc')}
      onClick={handleSubmit(onSuccess, onError)}
    />
  );
};

export default TaskDetailsStepper;

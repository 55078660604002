import { cloneDeep } from '@apollo/client/utilities';
import { CardModal } from 'modal';
import moment from 'moment';
import React from 'react';
import { UserData } from 'sop-commons/src/client';
import { ITrainingPathContext } from '../TrainingPathStore/training-path-context';

export const milestoneModalCloseHandler = async (
  type: 'close' | 'save',
  data: any,
  setMilestoneModalOpened: React.Dispatch<React.SetStateAction<boolean>>,
  milestoneType: string,
  trainingPathCtx: ITrainingPathContext,
  userData: UserData,
  selectedMilestone: any
) => {
  if (type === 'close') {
    setMilestoneModalOpened(false);
  } else {
    console.log(milestoneType, data);
    if (milestoneType === 'actionMenu') {
      let tempEid = Date.now();
      setMilestoneModalOpened(false);
      let tempArr = cloneDeep(trainingPathCtx?.addedItems);
      tempArr.push({
        type: 'milestone',
        title: data?.mileStoneName,
        backgroundColor: data?.backgroundColor,
        tempEid: tempEid,
      });
      trainingPathCtx?.setAddedItemsHandler(tempArr);
    } else if (milestoneType === 'editMenu') {
      setMilestoneModalOpened(false);
      let tempArr = cloneDeep(trainingPathCtx?.addedItems);
      let _arr: any[] = [];
      let promise = tempArr?.map((item) => {
        if (item?.type === 'milestone') {
          if (!isNaN(data?.tempEid)) {
            if (item?.tempId === data?.tempEid) {
              let _initialData = {
                backgroundColor: '#ffffff',
                cardType: 'milestone',
                content: [
                  {
                    url: data?.milestoneUrl || '',
                    addedBy: data?.milestoneAddedBy || userData?.name,
                    dateAdded:
                      data?.milestoneDateAdded || moment()?.toISOString(),
                    fileSize: data?.milestoneFileSize || 0,
                  },
                ],
                entityId: userData?.entityId,
                handbookView: true,
                layout: 'simple-text',
                raw_content: data?.mileStoneName,
                subTitle: '',
                thumbnail: '',
                title: data?.mileStoneName,
                tempId: item?.tempId,
                type: 'milestone',
              };
              let translatedReturnedData = new CardModal(_initialData);
              // item = translatedReturnedData;
              _arr.push(translatedReturnedData);
            } else {
              _arr.push(item);
            }
          } else {
            if (item?.eid === data?.tempEid) {
              let _initialData = {
                backgroundColor: '#ffffff',
                cardType: 'milestone',
                content: [
                  {
                    url: data?.milestoneUrl || '',
                    addedBy: data?.milestoneAddedBy || userData?.name,
                    dateAdded:
                      data?.milestoneDateAdded || moment()?.toISOString(),
                    fileSize: data?.milestoneFileSize || 0,
                  },
                ],
                entityId: userData?.entityId,
                handbookView: true,
                layout: 'simple-text',
                raw_content: data?.mileStoneName,
                subTitle: '',
                thumbnail: '',
                title: data?.mileStoneName,
                tempId: item?.tempId,
                type: 'milestone',
              };
              let translatedReturnedData = new CardModal(_initialData);
              // item = translatedReturnedData;
              _arr.push(translatedReturnedData);
            } else {
              _arr.push(item);
            }
          }
        } else {
          _arr.push(item);
        }
        return item;
      });
      await Promise.all(promise);
      trainingPathCtx?.setAddedItemsHandler(_arr);
    } else {
      let tempEid = Date.now();
      setMilestoneModalOpened(false);
      let tempArr = cloneDeep(trainingPathCtx?.addedItems);
      let index = tempArr?.findIndex((arr: any) => {
        return arr?.eid === selectedMilestone?.eid;
      });
      tempArr.splice(index + 1, 0, {
        type: 'milestone',
        title: data?.mileStoneName,
        backgroundColor: data?.backgroundColor,
        tempEid: tempEid,
      });
      trainingPathCtx?.setAddedItemsHandler(tempArr);
    }
  }
};

import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Flex } from '@chakra-ui/react';
import { match } from 'ts-pattern';
import { useWatch } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { toArray } from '../../../../utils/utils';
import { SelectOption } from '../../../../atoms';
import { IVisibilityInput } from './visibility.types';

const mapLabel = (data: SelectOption | any): string => data.label;

interface IProps {}

const VisibilityInfoText: FC<IProps> = () => {
  const { t, i18n } = useTranslation(['form']);

  const values = useWatch<IVisibilityInput>();

  const texts = useMemo(() => {
    if (values.visibility === 'private') {
      const data = [];

      const res = match<
        string,
        {
          locations: string;
          jobs: string;
        }
      >(values.filterBy?.value as string)
        .with('location', () => {
          return {
            locations: toArray(values.filterValue).map(mapLabel).join(', '),
            jobs: toArray(values.otherFilterValue).map(mapLabel).join(', '),
          };
        })
        .with('job', () => {
          return {
            jobs: toArray(values.filterValue).map(mapLabel).join(', '),
            locations: toArray(values.otherFilterValue)
              .map(mapLabel)
              .join(', '),
          };
        })
        .otherwise(() => {
          return {
            locations: '',
            jobs: '',
          };
        });

      if (!values.operation) {
        switch (values.filterBy?.value) {
          case 'location':
            {
              if (res.locations.length) {
                data.push(
                  // @ts-ignore
                  t('form:visibleToLocPeople', { locations: res.locations })
                );
              }
            }
            break;
          case 'job':
            {
              if (res.jobs.length) {
                data.push(t('form:visibleToJobPeople', { jobs: res.jobs }));
              }
            }
            break;
        }
      } else {
        if (values.operation === 'and') {
          data.push(
            t('form:visibleToJobAndLocPeople', {
              jobs: res.jobs,
              locations: res.locations,
            })
          );
        } else {
          data.push(
            t('form:visibleToJobOrLocPeople', {
              jobs: res.jobs,
              locations: res.locations,
            })
          );
        }
      }

      if (values.members?.length) {
        const memberNames = values.members.map(mapLabel).join(', ');
        // @ts-ignore
        data.push(t('form:visibleToUsers', { users: memberNames }));
      }

      return data;
    }
    return [t('form:everyoneCanSeeForm')];
  }, [i18n.language, values]);

  if (!texts?.length) {
    return null;
  }

  return (
    <Flex
      mt='16px'
      bg='rgba(177, 229, 252, 0.4)'
      p='16px 20px'
      borderRadius='10px'
      gap='12px'
    >
      <Center height='26px'>
        <FontAwesomeIcon icon={faEye as IconProp} color='#2a85ff' />
      </Center>
      <Box>
        {texts.map((value, key) => {
          return (
            <Box key={key} lineHeight='26px' color='#33383F'>
              {texts.length > 1 ? `${key + 1}`.concat('. ', value) : value}
            </Box>
          );
        })}
      </Box>
    </Flex>
  );
};

export default VisibilityInfoText;

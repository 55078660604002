import { Box, BoxProps, Flex, Text, TextProps } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, FC, useCallback } from 'react';
import CommonLabel from '../../CommonLabel';
import { IInProgressCardTop } from '../InProgressCardTop';

/**
 * Represents the base properties for a status badge.
 */
interface BaseStatusBadgeProps {
  /** The text label to be displayed in the badge. */
  label: string;
  /** Optional box properties from Chakra UI to style the outer container. */
  boxProps?: BoxProps;
  /** Optional text properties to style the label. */
  labelProps?: TextProps;
  /** Optional left icon to be displayed, using FontAwesome icons. */
  leftIcon?: IconProp;
  /** Optional style properties for the left icon. */
  leftIconProps?: CSSProperties;
  /** Optional right icon to be displayed, using FontAwesome icons. */
  rightIcon?: IconProp;
  /** Optional style properties for the right icon. */
  rightIconProps?: CSSProperties;
}

/**
 * A functional component that renders a status badge with optional icons on either side of the label.
 * It utilizes Chakra UI for styling and layout.
 *
 * @param props - The properties for the status badge, including label, optional icons, and style props.
 * @returns A JSX element representing the status badge.
 */
const StatusBadge: FC<BaseStatusBadgeProps> = (props) => {
  // Destructuring props for easier access.
  const {
    label,
    leftIcon,
    leftIconProps,
    boxProps,
    labelProps,
    rightIcon,
    rightIconProps,
  } = props;
  // Combined box properties, applying default and passed-in styles.
  const combinedBoxProps: BoxProps = {
    p: '5px 10px',
    borderRadius: '5.6px',
    h: 'fit-content',
    w: 'fit-content',
    fontWeight: 600,
    fontSize: '12px',
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    ...boxProps,
  };
  // useCallback hook to memoize the component for performance optimization.
  const getStatus = useCallback(() => {
    return (
      <Box {...combinedBoxProps}>
        {leftIcon && (
          <FontAwesomeIcon icon={leftIcon} style={{ ...leftIconProps }} />
        )}
        <CommonLabel label={label} {...labelProps} />
        {rightIcon && (
          <FontAwesomeIcon icon={rightIcon} style={{ ...rightIconProps }} />
        )}
      </Box>
    );
  }, [props, combinedBoxProps]);
  return getStatus();
};

export default StatusBadge;

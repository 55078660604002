import { CSSProperties, FC } from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

// @ts-ignore
import logo from '../../assets/images/logo.webp';

// import scss
import './ErrorContainer.scss';

import PrimaryButton from '../../atoms/PrimaryButton';
import React from 'react';

interface IProps {
  contentStyle?: CSSProperties;
  animationData?: any;
  title?: string;
  description?: string;
  showButtons?: boolean;
}

const ErrorContainer: FC<IProps> = ({
  contentStyle,
  animationData,
  title,
  description,
  showButtons,
}) => {
  const { t } = useTranslation('auth');
  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div className='error-container'>
      <div>
        <img src={logo} />
      </div>
      <div className='error-content-container'>
        <div style={contentStyle}>
          <Lottie
            options={defaultUploadLottieOptions}
            height={300}
            width={300}
            // height={lottieDimension}
            // width={lottieDimension}
          />
          <Flex justify='center'>
            <div className='error-title'>{title}</div>
          </Flex>

          <Flex justify='center'>
            <div className='error-description'>{description}</div>
          </Flex>
          {showButtons && (
            <Flex
              justify='center'
              align='center'
              flexDir='column'
              mt='28px'
              gap='12px'
            >
              <Link to='/login'>
                <PrimaryButton
                  type='button'
                  size='lg'
                  title={t('logIn')}
                  style={{ maxWidth: '327px', width: '327px' }}
                  variant='solid'
                  colorScheme='blue'
                  className='profile-button-save-container'
                />
              </Link>
              <Link to='/forgot-password'>
                <PrimaryButton
                  type='button'
                  size='lg'
                  title={t('forgot_password')}
                  style={{ maxWidth: '327px', width: '327px' }}
                  variant='outline'
                  className='profile-button-update-container'
                />
              </Link>
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorContainer;

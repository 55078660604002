import { toArray } from '../../utils/utils';
import { CardDetailModal } from '../../modal';
import { CardDetailEntity } from '../../types';

export class CreatedDeckModal {
  protected _data: CardDetailModal[] = [];
  constructor(props: CardDetailEntity[] = []) {
    this._data = toArray(props).map((val) => new CardDetailModal(val));
  }

  get data() {
    return this._data;
  }

  get length() {
    return this._data.length;
  }
}

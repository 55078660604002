import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import { getPermissionText } from './CommonExpandAccordion';

const AnyoneSelection: FC<{ type: 'newChannel' | 'directMessage' }> = ({
  type,
}) => {
  return (
    <Box display='block'>
      <Box as='span' color='rgba(42, 133, 255, 1)' fontWeight={700}>
        Everyone
      </Box>
      <Box as='span'>&nbsp;</Box>
      <Box as='span' color='rgba(111, 118, 126, 1)'>
        in the org {getPermissionText(1)}{' '}
        {type === 'newChannel' ? 'create channel' : 'start direct message'}.
      </Box>
    </Box>
  );
};

export default AnyoneSelection;

import { v4 as uuidv4 } from 'uuid';
import {
  AllLayout,
  MixedLayout,
  OtherLayout,
  QuizLayout,
  TextLayout,
} from 'sop-commons/src/utils/cardLayouts';
import { SelectOption } from '../../atoms/Dropdown';
import { CardStatus, CardType, MayBe } from '../../types';
import { TranslatedData } from '../../types/base-card.types';
import { CardTypeList } from './card-editor.data';

export interface CardData {
  cardType: string;
  entityId: string;
  backgroundColor: string;
  layout: string;
  content?: ContentEntity[];
  raw_content: string;
  subTitle: string;
  thumbnail: string;
  title: string;
}

export interface ContentEntity {
  type: string;
  attrs: Attrs;
  choices?: ChoicesEntity[];
  correctAnswer: string;
  url?: string;
}

export interface Attrs {}

export interface ChoicesEntity {
  id: string;
  text: string;
  inputId: string;
}

export type CardTypeOption = SelectOption<CardType> & {
  transKey: string;
};

export interface QuizOptionData {
  id?: string;
  choiceId: string;
  text: string;
  inputId: string;
}

type Content = any;

export interface CardInputData {
  cardType: CardType;
  entityId: MayBe<string>;
  backgroundColor?: MayBe<string>;
  layout: AllLayout;
  content?: Content[];
  raw_content?: string;
  subTitle: string;
  thumbnail: string;
  title?: string;
  handbookView?: boolean;

  category?: string;

  status?: CardStatus;

  formId?: string;

  eid?: string; //  for patching the card
}

export interface CardFormInput {
  cardType: CardTypeOption;
  entityId?: string;
  backgroundColor?: string;
  layout: MixedLayout | TextLayout | QuizLayout | OtherLayout;
  content: Content[];
  raw_content?: string;
  subTitle?: string;
  thumbnail?: string;
  title?: string;
  handbookView?: boolean;
  category?: SelectOption;
  isReadOnly?: boolean;

  images?: string[];

  status?: CardStatus;

  bodyJson?: JSON | any; // rich-text and mixed card

  formLink?: string; // form-card

  videoUrl?: string; // video-card
  caption?: string; // video-card
  videoAddedBy?: string; // video-card
  videoDateAdded?: string; // video-card
  videoFileSize?: number; // video-card

  choices?: QuizOptionData[]; // quiz-card
  correctAnswer?: string[]; // quiz-card

  milestoneUrl?: string;
  milestoneAddedBy?: string;
  milestoneDateAdded?: string;
  milestoneFileSize?: number;

  form?: any;

  language?: string;

  translations: Record<string, TranslatedData>;

  defaultLanguage?: string;
  shouldShowFooter?: boolean;
}

export const CardDefaultValues: CardFormInput = {
  cardType: CardTypeList[0],
  // entityId: '',
  backgroundColor: '#ffffff',
  layout: TextLayout.SIMPLE_TEXT,
  content: [],
  // raw_content?: string;
  subTitle: '',
  // thumbnail?: string;
  title: '',
  caption: '',
  handbookView: false,
  isReadOnly: false,
  // category?: SelectOption;

  images: [],
  thumbnail: '',
  formLink: '',

  videoUrl: '',
  milestoneUrl: '',
  milestoneAddedBy: '',
  milestoneDateAdded: '',
  milestoneFileSize: 0,
  form: null,

  bodyJson: {
    type: 'doc',
    content: [],
  },

  translations: {},
  shouldShowFooter: true,
};

export const getDefaultQuizOption = (): QuizOptionData[] => {
  return [
    {
      choiceId: uuidv4(),
      text: '',
      inputId: `answerOption${uuidv4()}`,
    },
    {
      choiceId: uuidv4(),
      text: '',
      inputId: `answerOption${uuidv4()}`,
    },
  ];
};

import React, { FC } from 'react';
import { Question as QuestionData } from '../../create-form/form.types';
import TextSectionPreview from './TextSectionPreview';

interface IProps {
  question: QuestionData;
}

const TextSection: FC<IProps> = ({ question }) => {
  if (question.isArchieved) {
    return null;
  }
  return (
    <div className='custom-form-preview-question-con'>
      <div className='custom-form-preview-question-section-container'>
        <div className='custom-form-preview-question-label'>
          {question.label}
        </div>

        <TextSectionPreview value={question.subTitle} />
      </div>
    </div>
  );
};

export default TextSection;

import React, { FC } from 'react';
import TitleHeader from '../../CardEditor/TitleHeader';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import FormInput from '../../../atoms/FormInput';
import { IFormInput } from './form.types';
import ThumbnailUpload from './ThumbnailUpload';
import Textarea from '../../../atoms/Input/Textarea';
import { useTranslation } from 'react-i18next';

interface IProps {
  isReadOnly?: boolean;
}

const FormDetails: FC<IProps> = ({ isReadOnly }) => {
  const { t } = useTranslation('form');
  const { control } = useFormContext<IFormInput>();

  return (
    <div>
      <TitleHeader title={t('cover_image')} desc={t('cover_image_desc')} />

      <Controller
        control={control}
        name='thumbnail'
        render={({ field, fieldState }) => {
          return (
            <FormControl
              mt={3}
              isInvalid={!!fieldState.error}
              isReadOnly={isReadOnly}
            >
              <ThumbnailUpload
                value={field.value}
                onChange={field.onChange}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>
                <span>{fieldState.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <TitleHeader
        title={t('form_title')}
        desc={t('form_title_desc')}
        titleStyle={{ marginTop: '30px' }}
        isRequired
      />

      <Controller
        name={'title'}
        control={control}
        rules={{
          required: t('validation.form_title_required'),
          validate: (value) => {
            if (value?.trim()?.length === 0) {
              return t('validation.form_title_enter');
            }
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              mt={3}
              isInvalid={!!fieldState?.error}
              isReadOnly={isReadOnly}
            >
              <FormInput id='title' size='lg' {...field} />
              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />

      <TitleHeader
        title={t('form_desc')}
        desc={t('form_desc_desc')}
        titleStyle={{ marginTop: '30px' }}
      />

      <Controller
        name={'description'}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <FormControl
              mt={3}
              isInvalid={!!fieldState?.error}
              isReadOnly={isReadOnly}
            >
              <Textarea id='description' {...field} />
              <FormErrorMessage>
                <span>{fieldState?.error?.message}</span>
              </FormErrorMessage>
            </FormControl>
          );
        }}
      />
    </div>
  );
};

export default FormDetails;

import React, { FC, useContext } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import MobileHeader from '../../../../Chapters/ChapterPreview/MobileHeader';
import { CommonWrapper } from './common';
import { PreviewContext } from '../context';
import { CommonWrapperProps } from './common/CommonWrapper';

interface IProps extends CommonWrapperProps {
  title?: string;
}

const TrainingTablet: FC<IProps> = ({
  value,
  isPreview,
  title,
  isNotDesktop,
}) => {
  const { selectedIndex, trainingContents } = useContext(PreviewContext);

  const totalCards = trainingContents?.length || 0;

  return (
    <Box
      maxWidth='100%'
      borderRadius='48px'
      p='32px'
      bg='#151515'
      w='full'
      h='full'
      style={{
        aspectRatio: '3/4',
      }}
    >
      <Flex
        flexDir='column'
        pos='relative'
        borderRadius='16px'
        bg='#ffffff'
        overflow='auto'
        h='full'
      >
        <MobileHeader
          title={title}
          currentIndex={selectedIndex + 1}
          totalCards={totalCards}
        />
        <Box flex={1} p='16px' pt='10px'>
          <CommonWrapper
            value={value}
            isPreview={isPreview}
            isNotDesktop={isNotDesktop}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default TrainingTablet;

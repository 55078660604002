import { JSONContent } from 'delightree-editor';
import { toArray } from '../../../utils/utils';

const countTextLength = (data?: JSONContent): number => {
  if (Array.isArray(data)) {
    return data.reduce<number>((acc, value) => {
      if (value?.content) {
        return acc + countTextLength(value.content);
      } else {
        return acc + countTextLength(value);
      }
    }, 0);
  } else {
    if (data?.text) {
      return data.text.length;
    }
  }
  return 0;
};

const findSplitIndex = (contents: JSONContent[], smartPage?: boolean) => {
  if (!smartPage) {
    return contents.reduce<number[]>(
      (previousValue, currentValue, currentIndex) => {
        if (currentIndex === 0) {
          previousValue.push(0);
        } else if (
          currentValue.type &&
          ['horizontalRule', 'customHorizontalRule'].includes(currentValue.type)
        ) {
          previousValue.push(currentIndex);
          previousValue.push(currentIndex + 1);
        }
        return previousValue;
      },
      []
    );
  }
  return contents.reduce<number[]>(
    (previousValue, currentValue, currentIndex) => {
      if (currentIndex === 0) {
        previousValue.push(0);
      } else if (
        currentValue.type &&
        ['bulletList'].includes(currentValue.type)
      ) {
        if ('content' in currentValue) {
          const count = countTextLength(currentValue?.content);
          if (count > 300) {
            previousValue.push(currentIndex);
            previousValue.push(currentIndex + 1);
          }
        }
      } else if (
        currentValue.type &&
        ['video', 'image'].includes(currentValue.type)
      ) {
        previousValue.push(currentIndex);
        previousValue.push(currentIndex + 1);
      } else if (currentValue.type && ['heading'].includes(currentValue.type)) {
        previousValue.push(currentIndex);
      } else if (
        currentValue.type &&
        ['horizontalRule', 'customHorizontalRule'].includes(currentValue.type)
      ) {
        previousValue.push(currentIndex);
        previousValue.push(currentIndex + 1);
      }
      return previousValue;
    },
    []
  );
};

export function dataConverter(data: JSONContent, smartPage?: boolean) {
  if (data?.type === 'doc' && Array.isArray(data.content)) {
    let contents = [...data.content];
    // const newCards = contents.map(generateCard);

    const split = findSplitIndex(contents, smartPage);

    return split
      .map((val, index, arr) => contents.slice(val, arr[index + 1]))
      .filter((val) => !!toArray(val).length)
      .map((value, index, array) => {
        const lastItem = toArray(array[index - 1]);

        if (
          lastItem[0]?.type &&
          ['horizontalRule', 'customHorizontalRule'].includes(lastItem[0]?.type)
        ) {
          return lastItem.concat(value);
        }

        return value;
      })
      .filter((value) => {
        const items = toArray(value);
        const type = items[0]?.type as string;
        if (items.length > 1) {
          return true;
        }
        return !['horizontalRule', 'customHorizontalRule'].includes(type);
      });
  }
  return [];
}

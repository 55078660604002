import React, { forwardRef, useMemo } from 'react';
import { Select as ChakraSelect } from 'chakra-react-select';
import { RefCallBack, useWatch } from 'react-hook-form';
import { match } from 'ts-pattern';
import { useTranslation } from 'react-i18next';
import { getChakraSelectStyles } from 'atoms/Dropdown/dropdown.styles';

import { IFormInput } from './invite.types';
import { toArray } from '../../utils/utils';
import { useUserDataSelector, useUserEntity } from '../../hooks';
import { AuthRole } from 'authorization';

const sortLocation = <T extends SelectOption>(valA: T, valB: T) => {
  if (valA.label < valB.label) {
    return -1;
  } else if (valA.label > valB.label) {
    return 1;
  } else {
    return 0;
  }
};

export interface UserBranch {
  name: string;
  eid: string;
  type: string;
  role: string;
  authRole: string;
  email: string;
  createdAt: string;
  status: string;
  isRoot?: boolean;
}

interface SelectOption {
  id: string;
  label: string;
  value: string;
  url?: string;
}

interface IProps {
  rootLocation?: {
    eid: string;
    name: string;
  };
  /** Used to disable location select if passing default location value which cannot be changed. Example is inviting location owner for a specific location, there another location cannot be added */
  shouldDisableLocationSelect?: boolean;
  onChange?: (...event: any[]) => void;
  onBlur?: () => void;
  value?: any;
  name?: 'location';
  ref?: RefCallBack;
}

const InviteLocationSelect = forwardRef<never, IProps>(
  ({ rootLocation, shouldDisableLocationSelect, ...props }) => {
    const { t } = useTranslation(['common', 'invite', 'location']);

    const locations = useUserEntity((entity) => entity?.locations || []);
    const { loggedInUserAuthRole, loggedInUserLocations } = useUserDataSelector(
      (state) => ({
        loggedInUserLocations: state?.locations,
        loggedInUserAuthRole: state?.authRole,
      })
    );

    const authLocations = useMemo(() => {
      return toArray(loggedInUserLocations).map((it) => it.eid);
    }, [loggedInUserLocations]);

    const permissionLevel = useWatch<IFormInput, 'permissionLevel'>({
      name: 'permissionLevel',
    });

    const isMultiLocation = permissionLevel === AuthRole.LOCATION_OWNER;

    const _locationsList = useMemo(() => {
      let _locations =
        permissionLevel === AuthRole?.WORKER
          ? locations.filter((loc) => loc.locationStatus === 'open')
          : locations;
      const records = toArray(_locations).reduce<Record<string, SelectOption>>(
        (acc, branch) => {
          if (
            ![AuthRole.LOCATION_OWNER, AuthRole.WORKER].includes(
              permissionLevel!
            )
          ) {
            acc[branch.eid] = {
              id: branch.eid,
              label: branch.name,
              value: branch.name,
            };
          } else if (branch.eid !== rootLocation?.eid) {
            acc[branch.eid] = {
              id: branch.eid,
              label: branch.name,
              value: branch.name,
            };
          }
          return acc;
        },
        {}
      );

      return Object.values(records).sort(sortLocation);
    }, [locations, permissionLevel]);

    /**
     * List of locations based on auth role of logged in user.
     * If logged in user is superadmin or admin, then he can add any user to any location
     * but if he is location owner, then he can add user to only his locations.
     */
    const locationsList = useMemo(() => {
      return match(loggedInUserAuthRole)
        .with(AuthRole.SUPER_ADMIN, () => _locationsList)
        .with(AuthRole.ADMIN, () => _locationsList)
        .with(AuthRole.LOCATION_OWNER, () => {
          return _locationsList.filter((it) => authLocations.includes(it.id));
        })
        .otherwise(() => []);
    }, [_locationsList, loggedInUserAuthRole, authLocations]);

    const isDisabled = useMemo(() => {
      return (
        [AuthRole.SUPER_ADMIN, AuthRole.ADMIN].includes(permissionLevel!) ||
        !permissionLevel
      );
    }, [permissionLevel]);

    const values = useMemo(() => {
      const _values = toArray(props.value);

      if (isMultiLocation) {
        return _values;
      } else {
        return _values.slice(0, 1);
      }
    }, [props.value, isMultiLocation]);

    return (
      <ChakraSelect<SelectOption, true>
        onChange={props.onChange}
        size='lg'
        value={values}
        chakraStyles={getChakraSelectStyles()}
        hideSelectedOptions={false}
        closeMenuOnSelect={!isMultiLocation}
        controlShouldRenderValue={!isMultiLocation}
        inputId='location'
        placeholder={t(
          isMultiLocation ? 'invite:selectLocations' : 'location:selectLocation'
        )}
        isMulti={isMultiLocation ? true : undefined}
        options={locationsList}
        isDisabled={
          isDisabled || !locationsList.length || shouldDisableLocationSelect
        }
      />
    );
  }
);

InviteLocationSelect.displayName =
  'ui-components/InviteUserNew/InviteLocationSelect';

export default InviteLocationSelect;

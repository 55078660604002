import React, { useCallback, useRef } from 'react';
import { ApolloProvider, useApolloClient } from '@apollo/client';
import { useConfirm, UseConfirm } from '../../../../../../../ui-components';
import CreateLogsContent from './CreateLogsContent';

interface IProps {
  locationId: string;
  noteId?: string;
  onSuccess?: () => void;
}

type CreateLogsProps = (props: IProps) => void;

export const useCreateLogs = (): CreateLogsProps => {
  const confirm = useConfirm();
  const apolloClient = useApolloClient();
  const confirmRef = useRef<ReturnType<UseConfirm>>();

  return useCallback(({ locationId, noteId, onSuccess }) => {
    if (confirmRef.current) {
      confirmRef.current.destroy();
    }

    confirmRef.current = confirm({
      Wrapper: ({ children }: any) => {
        return (
          <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
        );
      },
      content: (
        <CreateLogsContent
          locationId={locationId}
          noteId={noteId}
          onSuccess={onSuccess}
        />
      ),
      isCentered: true,
      contentProps: {
        maxW: '1200px',
        minHeight: '640px',
        borderRadius: '12px',
      },
      bodyProps: {
        padding: 0,
        display: 'flex',
      },
      scrollBehavior: 'inside',
      footer: null,
    });
  }, []);
};

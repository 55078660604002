import { FC } from 'react';
import Lottie from 'react-lottie';
import { Center, Image } from '@chakra-ui/react';

// @ts-ignore
import appIcon from './assets/images/delightreeLogo.svg';

// @ts-ignore
import animationData from './assets/lotties/business-loading.json';

const SplashScreen: FC = () => {
  return (
    <Center w='full' h='full' flexDir='column' p={4} gap='32px'>
      <Image src={appIcon} w='320px' maxW='90%' />
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={120}
        width={120}
      />
    </Center>
  );
};

export default SplashScreen;

import React, { FC } from 'react';
import { TaskPlay } from '../../sub-components/tasks';
import DashboardContainer from '../../sub-components/DashboardContainer';

const CreateTask: FC = () => {
  return (
    <DashboardContainer>
      <TaskPlay />
    </DashboardContainer>
  );
};

export default CreateTask;

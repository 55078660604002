import React, { FC, useMemo } from 'react';
import {
  Center,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ActionMenuItem, IBaseMenuData, MenuItemWrapper } from 'ui-components';
import { IFormItemsEntity } from '../../forms-types';

interface IProps {
  menu: IBaseMenuData;
  appearsInSop: IFormItemsEntity['appearsInSop'];
}

const AppearInComp: FC<IProps> = ({ menu, appearsInSop }) => {
  const { t } = useTranslation('form');

  const trainings = useMemo(() => {
    return appearsInSop?.map((obj) => obj?.title).join(', ');
  }, [appearsInSop]);

  return (
    <MenuItemWrapper cursor='initial' menu={menu}>
      <Popover offset={[5, 10]} placement='left'>
        {/*// @ts-ignore*/}
        <PopoverTrigger>
          <Flex flex={1} cursor='pointer' gap='12px'>
            <ActionMenuItem menu={menu} />
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            {trainings ? (
              <div>
                <strong>Chapters: </strong>
                {trainings}
              </div>
            ) : (
              <Center fontSize='14px' fontWeight='600' w='full' p='8px 0'>
                {t('form_not_appear')}
              </Center>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </MenuItemWrapper>
  );
};

export default AppearInComp;

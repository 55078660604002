import React, { FC } from 'react';
import { callAll } from '@chakra-ui/utils';
import { CometChat } from '@cometchat-pro/chat';
import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ChatGroupEntity, GroupMemberEntity } from '../../../../../../types';

import { useMemberData } from '../../channel-members/useMemberData';
import {
  REMOVE_MEMBER,
  RemoveVariable,
} from '../../channel-members/channel-members.graphql';
import { BaseGroupResponse } from '../../create-channel.graphql';

import ChannelMemberList from './ChannelMemberList';

interface IProps {
  isLoading?: boolean;
  group: ChatGroupEntity;
  groupId: string;
  onMemberDeleted?: (id: string) => void;
  onMemberUpdated: (data: BaseGroupResponse) => void;
  onSendMessage: (data: GroupMemberEntity) => void;
}

const ChannelMemberListContainer: FC<IProps> = ({
  isLoading,
  groupId,
  group,
  onMemberUpdated,
  onSendMessage,
}) => {
  const { t } = useTranslation(['common', 'setting', 'invite', 'task']);

  const { members, memberLoading, setMembers, reFetchData } =
    useMemberData(groupId);

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  const [removeMember] = useMutation<never, RemoveVariable>(REMOVE_MEMBER);

  const onActionClick = async (action: string, data: GroupMemberEntity) => {
    if (action === 'message') {
      onSendMessage?.(data);
    }
    if (action === 'delete') {
      try {
        await CometChat.kickGroupMember(groupId, data.uid);
        await removeMember({
          variables: {
            guid: groupId,
            eid: data.uid,
          },
        });
        setTimeout(() => {
          setMembers((prevState) => {
            return [...prevState.filter((m) => m.uid !== data.uid)];
          });
        });
        toast({
          status: 'success',
          title: t('common:success'),
          // @ts-ignore
          description: t('setting:memberRemoveSuccess', {
            name: data.name,
          }),
        });
      } catch (e) {
        toast({
          status: 'error',
          title: t('common:error'),
          description: t('setting:memberRemoveError'),
        });
      }
    }
  };

  return (
    <ChannelMemberList
      group={group}
      members={members}
      loading={memberLoading || isLoading}
      onActionClick={onActionClick}
      onMemberUpdated={callAll(onMemberUpdated, reFetchData)}
    />
  );
};

export default ChannelMemberListContainer;

import { gql, MutationTuple, useMutation } from '@apollo/client';
import { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PayloadInput } from '../../../../types';
import { InvitedChatGroup } from '../../../../ui-components/InviteUserNew/invite.types';

export const INVITE_USER_REQUEST_UPDATE = gql`
  mutation InviteUserRequestUpdate($input: InviteUserUpdateInputInput) {
    InviteUserRequestUpdate(input: $input) {
      succeed
    }
  }
`;

interface Variable {
  chatGroups?: InvitedChatGroup[];
  trainings?: string[];
  userId: string;
}

type Input = PayloadInput<Variable>;

export const useUpdateData = (
  options?: MutationBaseOptions<never, Input>
): MutationTuple<never, Input> => {
  const { t } = useTranslation(['common', 'invite']);

  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
  });

  return useMutation<never, PayloadInput<Variable>>(
    INVITE_USER_REQUEST_UPDATE,
    {
      ...options,
      onCompleted: () => {
        toast({
          status: 'success',
          title: t('common:success'),
        });
      },
      onError: () => {
        toast({
          status: 'error',
          title: t('common:error'),
        });
      },
    }
  );
};

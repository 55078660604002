import { Flex, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faChevronLeft,
  faCloudArrowUp,
} from '@fortawesome/pro-solid-svg-icons';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from 'atoms';
import { CheckIcon, ChevronLeftIcon } from '@chakra-ui/icons';

interface IProps {
  taskId: string;
  updatingProgress: boolean;
  goBack: () => void;
  updateProgressHandler: () => void;
}

const Footer: FC<IProps> = ({
  taskId,
  updatingProgress,
  goBack,
  updateProgressHandler,
}) => {
  const history = useHistory();
  return (
    <Flex
      gap='10px'
      align='center'
      w='full'
      justify='flex-end'
      maxWidth='max(875px, 65%)'
      mx='auto'
    >
      <Flex
        id='chapter-render-footer-2'
        align='center'
        gap='10px'
        color='black'
        border='1px solid #a1a7b0'
        borderRadius='7px'
        p='11px 20px'
        cursor={updatingProgress ? 'no-drop' : 'pointer'}
        onClick={goBack}
      >
        <FontAwesomeIcon icon={faChevronLeft as IconProp} fontSize='17px' />
        <Text id='chapter-render-footer-3' fontWeight={600} fontSize='15px'>
          Go Back
        </Text>
      </Flex>
      <PrimaryButton
        size='lg'
        width='fit-content'
        disabled={updatingProgress}
        isLoading={updatingProgress}
        title='I have read this'
        variant='solid'
        colorScheme='blue'
        leftIcon={<CheckIcon />}
        onClick={updateProgressHandler}
      />
      {/* {updatingProgress && (
        <FontAwesomeIcon
          icon={faCloudArrowUp as IconProp}
          color='#2a85ff'
          size='lg'
        />
      )} */}
      {/* <Flex
        id='chapter-render-footer-4'
        bg='#2b85ff'
        align='center'
        gap='10px'
        borderRadius='7px'
        color='white'
        p='10px 15px'
        cursor='pointer'
        onClick={updateProgressHandler}
      >
        <FontAwesomeIcon icon={faCheck as IconProp} fontSize='15px' />
        <Text id='chapter-render-footer-5' as='b'>
          I have read this
        </Text>
      </Flex> */}
    </Flex>
  );
};

export default Footer;

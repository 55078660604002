// @ts-ignore
import React, { FC } from 'react';
import styled from '@emotion/styled';
import { AssignedToUsersEntity } from '../training.types';

import { getImage } from '../../../utils';

const Wrapper = styled.div<{ status: string }>`
  margin-bottom: 16px;

  ${({ status }) => ({
    backgroundColor:
      status === 'active' || status === 'draft' ? 'none' : 'white',
    opacity: status === 'active' || status === 'draft' ? '1' : '.5',
    cursor: status === 'active' || status === 'draft' ? 'default' : 'no-drop',
  })}

  .avatar-list {
    position: relative;
    display: flex;
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      cursor: ${({ status }) =>
        status === 'active' || status === 'draft' ? 'pointer' : 'no-drop'};

      :not(:first-child) {
        margin-left: -8px;
      }
    }
  }

  .more-button {
    font-size: 12px;
    font-weight: 500;
    color: #6f767e;
    padding-top: 2px;
  }

  .user-name {
    font-weight: 600;
    font-size: 14px;
    margin-left: 5px;
  }
`;

interface IProps {
  assignee: AssignedToUsersEntity;
  status: string;
}
const CreatedUpdatedBy: FC<IProps> = ({ assignee, status }) => {
  return (
    <Wrapper status={status}>
      <div className='avatar-list'>
        <img
          src={getImage(assignee.profilePic, assignee.name)}
          alt='avatar'
          key={assignee.eid}
        />

        <span
          className='user-name'
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginRight: '20px',
          }}
        >
          {assignee.name}
        </span>
      </div>
    </Wrapper>
  );
};

export default CreatedUpdatedBy;

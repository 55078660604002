import { gql, useQuery } from '@apollo/client';
import Lottie from 'react-lottie';

import recentEmpty from '../../assets/lotties/recentEmpty.json';

// import css
import './RecentSearchList.css';

// import components
import Loader from '../Loader';
import RecentSearchItem from '../../sub-components/MostUsedList/MostUsedItem';
import { useEffect } from 'react';

const GET_RECENT_RESULTS = gql`
  query RecentSearch($sort: SortFindManySearchInput) {
    RecentSearch(sort: $sort) {
      items {
        searchQuery
        viewedResult
        path
        type
        searchedBy {
          name
          profilePic
          role
          username
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      count
    }
  }
`;

const RecentSearchList = ({
  setSearchQuery,
  setSelectedType,
  onClose,
}: any) => {
  const { data, error, loading, refetch } = useQuery(GET_RECENT_RESULTS, {
    variables: {
      sort: '_ID_DESC',
    },
  });
  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, []);
  const defaultUploadLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: recentEmpty,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const recentSearchList = data?.RecentSearch?.items;
  return (
    <div className='eventclass-recent-search-list-container recent-search-list-container'>
      {loading && (
        <div className='sop-list-loader'>
          <Loader />
        </div>
      )}
      {!loading &&
        !!recentSearchList?.length &&
        recentSearchList.map((item: any, index: number) => {
          return (
            <RecentSearchItem
              key={index}
              item={item}
              setSearchQuery={setSearchQuery}
              setSelectedType={setSelectedType}
              onClose={onClose}
            />
          );
        })}
      {!loading && recentSearchList?.length === 0 && (
        <div>
          <div className='eventclass-search-empty search-empty-oh-crap-text'>
            No Recent Search
          </div>
          <Lottie
            options={defaultUploadLottieOptions}
            height={250}
            width={400}
            style={{ marginTop: '30px' }}
          />
        </div>
      )}
    </div>
  );
};

export default RecentSearchList;

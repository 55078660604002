import React, { FC } from 'react'
import DashboardContainer from 'sub-components/DashboardContainer'
import TrainingPathDataProvider from './TrainingPathStore/TrainingPathDataProvider'
import TrainingPathSubContainer from './TrainingPathSubContainer'

interface IProps {
    
}

const CreateTrainingPathNewContainer: FC<IProps> = () => {
  return (
    <DashboardContainer>
      <TrainingPathDataProvider>
        <TrainingPathSubContainer />
      </TrainingPathDataProvider>
    </DashboardContainer>
  )
}

export default CreateTrainingPathNewContainer
import React, { FC } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router';

import { FormResponse } from '../form.graphql';

interface IProps {
  formData?: FormResponse['FormById'];
}

const FormHeader: FC<IProps> = ({ formData }) => {
  const history = useHistory();
  if (!formData?.title) {
    return null;
  }

  return (
    <Flex mb='16px' align='center' gap={3}>
      <IconButton
        borderRadius='full'
        aria-label='back'
        size='xs'
        icon={
          <FontAwesomeIcon icon={faCircleChevronLeft as IconProp} size='lg' />
        }
        onClick={history.goBack}
      />
      <Box fontWeight='600' fontSize='20px' lineHeight='32px' color='#1a1d1f'>
        {formData?.title}
      </Box>
    </Flex>
  );
};

export default FormHeader;

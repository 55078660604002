import React, { FC } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

import { AssignedLocationsType } from '../../task.types';

interface ItemProps {
  title: string;
  desc: string;
  onClick?: () => void;
}

const Item: FC<ItemProps> = ({ title, desc, onClick }) => {
  return (
    <Flex
      px='28px'
      cursor='pointer'
      onClick={onClick}
      _hover={{
        bg: '#EFEFEFB2',
      }}
      _notLast={{
        '> div ': {
          borderBottom: '1px solid #EAEBED',
        },
      }}
    >
      <Flex flex={1} py={3} gap='28px' align='center'>
        <Box flex={1}>
          <Text fontSize='15px' fontWeight='semibold' color='#1A1D1F'>
            {title}
          </Text>
          <Text
            noOfLines={2}
            fontSize='13px'
            color='#6F767E'
            lineHeight='20px !important'
          >
            {desc}
          </Text>
        </Box>
        <ChevronRightIcon boxSize='24px' color='#6F767E' />
      </Flex>
    </Flex>
  );
};

interface IProps {
  onClick: (value: AssignedLocationsType) => void;
}

const LocationMemberType: FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation(['common', 'task']);

  return (
    <Box>
      <Item
        title={t('common:location')}
        desc={t('task:locationDesc')}
        onClick={() => onClick?.('locationUser')}
      />
      <Item
        title={t('task:locationOwner')}
        desc={t('task:locationOwnerDesc')}
        onClick={() => onClick?.('locationOwner')}
      />
      <Item
        title={t('task:onlyLocationWorker')}
        desc={t('task:onlyLocationWorkerDesc')}
        onClick={() => onClick?.('worker')}
      />
      <Item
        title={t('task:everyoneInLocation')}
        desc={t('task:everyoneInLocationDesc')}
        onClick={() => onClick?.('everyone')}
      />
    </Box>
  );
};

export default LocationMemberType;

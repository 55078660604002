import { Button, ButtonProps as IProps } from '@chakra-ui/react';
import { FC } from 'react';

interface ButtonProps
  extends Pick<
    IProps,
    | 'type'
    | 'size'
    | 'title'
    | 'onClick'
    | 'style'
    | 'variant'
    | 'className'
    | 'isLoading'
    | 'colorScheme'
    | 'disabled'
    | 'rightIcon'
    | 'leftIcon'
    | 'mt'
    | 'value'
    | 'width'
    | 'name'
    | 'id'
    | 'form'
    | 'borderWidth'
  > {}

const PrimaryButton: FC<ButtonProps> = ({
  mt,
  type,
  size,
  isLoading,
  disabled,
  title,
  leftIcon,
  rightIcon,
  onClick,
  style,
  variant,
  colorScheme,
  className,
  value,
  width,
  id,
  name,
  children,
  ...rest
}) => {
  return (
    <Button
      width={width}
      mt={mt}
      type={type}
      size={size}
      className={className}
      style={{
        // backgroundColor: '#2a85ff',
        // color: '#fff',
        borderRadius: '7px',
        fontSize: 15,
        ...style,
      }}
      value={value}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      isLoading={isLoading}
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      colorScheme={colorScheme}
      iconSpacing='8px'
      id={id}
      name={name}
      {...rest}
    >
      {children || title}
    </Button>
  );
};

PrimaryButton.defaultProps = {
  width: 'full',
};

// PrimaryButton.defaultProps = {
//   variant: 'solid',
//   colorScheme: 'blue',
// };

export default PrimaryButton;

import React, { FC, useMemo } from 'react';
import { Center, Flex } from '@chakra-ui/react';
import { SopDetailResponse } from './chapter.graphql';
import { NewPdfViewer, PdfViewer } from '../../../ui-components';
import { getFileType } from '../CreateChapterModal';
import { toArray } from '../../../utils/utils';
import ReactPlayer from 'react-player';

interface IProps {
  sopData?: SopDetailResponse['SopById'];
}

const ChapterFileView: FC<IProps> = ({ sopData }) => {
  const file = toArray(sopData?.files)[0];

  const fileUrl = useMemo(() => {
    if (file?.url) {
      if (!localStorage.getItem('OFFICE_VIEWER')) {
        return 'https://view.officeapps.live.com/op/embed.aspx?src=' + file.url;
      }

      return (
        'https://docs.google.com/viewer?url=' + file.url + '&embedded=true'
      );
    }
    return undefined;
  }, [file]);

  if (!fileUrl) {
    return null;
  }

  if (getFileType(file?.mimetype!) === 'pdf') {
    return (
      <Flex flex={1} px='20px'>
        <NewPdfViewer
          url={file?.url!}
          style={{
            borderRadius: '8px',
          }}
        />
      </Flex>
    );
  }

  if (getFileType(file?.mimetype!) === 'video') {
    return (
      <Center>
        <ReactPlayer
          url={sopData?.media?.[0]?.mediaUrl || sopData?.files?.[0]?.url}
          controls
          playing
          height='100%'
          width='100%'
        />
      </Center>
    );
  }

  return (
    <Flex flex={1} px='20px' overflow='auto'>
      <embed
        // type={file?.mimetype}
        src={fileUrl}
        title={file?.name}
        style={{
          height: '100%',
          width: '100%',
          border: 'none',
          borderRadius: '8px',
        }}
      />
    </Flex>
  );
};

export default ChapterFileView;

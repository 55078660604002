import styled from '@emotion/styled';

export const PickerWrapper = styled.div`
  .react-datepicker {
    border-color: transparent;
    box-shadow: 9px 3px 25px 11px rgba(150, 150, 150, 0.11);
    border-radius: 8px;
    min-width: 120px;
    overflow: hidden;
  }

  .react-datepicker__time-container {
    width: 100%;
  }

  .react-datepicker__header {
    background: #ffffff;
    border-bottom-color: #e4e5e7;
  }

  .react-datepicker__header--time {
  }

  .react-datepicker-time__header {
    color: #0f2552;
    font-size: 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
  }

  .react-datepicker-popper[data-placement^='top']
    .react-datepicker__triangle::before {
    border-top-color: #ffffff;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: #ffffff;
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::after {
    border-bottom-color: #ffffff;
  }

  .react-datepicker__time {
    .react-datepicker__time-box {
      width: unset;
      font-family: 'Inter', sans-serif;
      font-size: 15px;
      font-weight: 500;
    }

    .react-datepicker__time-box {
      ul.react-datepicker__time-list {
        li.react-datepicker__time-list-item--selected {
          background: #2a85ff;

          :hover {
            background: #2a85ff;
          }
        }
      }
    }
  }

  .react-datepicker__input-time-container {
    font-family: 'Inter', sans-serif;
    border-top: 1px solid #e4e5e7;

    margin: 5px 0 0;
    padding-inline: 12px;
    padding-block: 10px;
  }
`;

import { StateCreator } from 'zustand';

export interface CombinedLoadingState {
  usersEntityLoading: boolean;
  userLoading: boolean;
  updateUsersEntityLoading: (value: boolean) => void;
  updateUserLoading: (value: boolean) => void;
}

export const combinedLoadingStateSlice: StateCreator<CombinedLoadingState> = (set) => ({
  usersEntityLoading: false,
  userLoading: false,
  updateUsersEntityLoading: (value: boolean) =>
    set(() => ({ usersEntityLoading: value })),
  updateUserLoading: (value: boolean) => set(() => ({ userLoading: value })),
});

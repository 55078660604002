import React, { FC } from 'react';
import { Divider, Flex, Skeleton } from '@chakra-ui/react';

const _AboutSkeleton: FC = () => {
  return (
    <div>
      <Flex gap={2}>
        <Skeleton borderRadius='8px' boxSize='48px' />
        <Skeleton borderRadius='12px' height='48px' flex={1} />
        <Skeleton borderRadius='8px' boxSize='48px' />
      </Flex>

      <Flex mt={4} gap={2} pb={1}>
        <Skeleton borderRadius='2px' boxSize='16px' />
        <Skeleton borderRadius='2px' height='16px' flex={2} />
        <Flex flex={1} />
      </Flex>

      <Divider opacity={1} borderColor='#EFEFEF' my={4} />

      <Flex flexDir='column' pb={2} gap={2} pt={1}>
        <Skeleton height='16px' borderRadius='2px' width='100px' />
        <Skeleton height='12px' borderRadius='2px' width='200px' />
      </Flex>
    </div>
  );
};

interface IProps {
  isLoaded?: boolean;
}

const AboutChannelSkeleton: FC<IProps> = ({ isLoaded, children }) => {
  if (isLoaded) {
    return <>{children}</>;
  }

  return <_AboutSkeleton />;
};

export default AboutChannelSkeleton;

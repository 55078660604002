import React, { FC } from 'react';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import AlertBox from '../../../../atoms/AlertBox';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface IProps {
  children: React.ReactNode;
  icon?: IconDefinition | undefined;
}

const InfoText: FC<IProps> = ({ children, icon = faCircleExclamation }) => {
  return (
    <AlertBox mt={5} icon={icon}>
      {children}
    </AlertBox>
  );
};

export default InfoText;

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import TaskDetailBody from './TaskDetailBody';
import React, { FC, useEffect } from 'react';
import { CustomLauncherTask } from 'pages/Home/components/LOInProgress/types';
import { IModeType } from '../TaskDetailDrawer/Body/BodyComponent';
import { IRefetchType } from '../TaskDetailDrawer/task-detail-drawer.types';
import Header from '../TaskDetailDrawer/Header';
import useCombinedStore from 'zustandStore/store';

interface ITaskDetailModalProps {
  /**
   * Indicates whether the drawer is open.
   */
  isOpen: boolean;

  /**
   * Function to call when the drawer needs to be closed.
   */
  onClose: () => void;

  selectedTaskData: CustomLauncherTask;
  mode: IModeType;
  refetchHandler: (type: IRefetchType) => void;
}

const TaskDetailModal: FC<ITaskDetailModalProps> = ({
  isOpen,
  selectedTaskData,
  mode,
  onClose,
  refetchHandler,
}) => {
  const {
    taskDetails: { reset },
  } = useCombinedStore();
  useEffect(() => {
    reset();
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        p={4}
        borderRadius='20px'
        gap={2}
        minW='1400px'
        minH='600px'
      >
        <ModalHeader>
          <Header
            onClose={onClose}
            onEdit={() => {}}
            mode={mode}
            selectedTaskData={selectedTaskData}
          />
        </ModalHeader>
        <ModalBody>
          <TaskDetailBody
            mode={mode}
            selectedTaskData={selectedTaskData}
            refetchHandler={refetchHandler}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TaskDetailModal;

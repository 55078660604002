import styled from '@emotion/styled';

export const ChapterWrapper = styled.div`
  background: white;
  border-radius: 12px;
  padding-block: 32px;
  padding-inline: 30px;
  flex: 1;
  // max-height: 194vh;
  overflow: hidden;

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100% !important;
    border-radius: 5px;
    display: table;
    max-width: unset;
    // max-width: -moz-fit-content;
    // /* max-width: fit-content; */
    overflow-x: auto;
    white-space: nowrap;
  }

  table > tbody > tr:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
`;

import React, { useState } from 'react';
import { Layout, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;
export const Sopwait = () => {
  return (
    <Layout
      style={{
        backgroundColor: '#4D8AF0',
        display: 'flex',
        padding: 24,
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ClockCircleOutlined
        size={100}
        style={{ fontSize: 150, color: 'white' }}
      />
      <Text style={{ fontSize: 24, color: 'white', marginBlock: 25 }}>
        Please wait till our team works on the SOPs provided
      </Text>
    </Layout>
  );
};

import React, { FC } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './rich-text.css';
import { ReQuillValue } from './ReactQuill';

interface IProps {
  value?: ReQuillValue;
  onChange?: (value: ReQuillValue) => void;
  readOnly?: boolean;
  placeholder?: string;
}

const RichInput: FC<IProps> = ({ value, onChange, readOnly, placeholder }) => {
  return (
    <ReactQuill
      className='de-textarea'
      modules={{
        toolbar: [
          ['bold', 'underline', 'italic', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      }}
      readOnly={readOnly}
      value={value}
      placeholder={placeholder}
      onChange={(_value) => onChange?.(_value)}
    />
  );
};

export default RichInput;

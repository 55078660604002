import { SelectOption } from 'atoms';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'ui-components';
import { IShowState } from '../hooks/types';

interface IProps {
  category: SelectOption<string> | undefined;
  isSaveChapterTemp: boolean | undefined;
  saveTemporaryHandler?: () => void;
  updateShowStateHandler?: (data: IShowState) => void;
}

const CategoryActionButton: FC<IProps> = ({
  category,
  isSaveChapterTemp,
  saveTemporaryHandler,
  updateShowStateHandler,
}) => {
  const { t } = useTranslation(['training']);
  return (
    <ActionButton
      w='full'
      colorScheme='blue'
      mt={4}
      isDisabled={!category?.value}
      actionFn={() => {
        if (isSaveChapterTemp) {
          updateShowStateHandler?.({
            showFolder: false,
            showVisibility: false,
            showTrainingPathFlowModal: false,
          });
          // setShowState({
          //   showFolder: false,
          //   showVisibility: false,
          //   showTrainingPathFlowModal: false,
          // });
          saveTemporaryHandler?.();
        } else {
          updateShowStateHandler?.({
            showFolder: true,
            showVisibility: true,
            showTrainingPathFlowModal: false,
          });
          // setShowState({
          //   showFolder: true,
          //   showVisibility: true,
          //   showTrainingPathFlowModal: false,
          // });
        }
      }}
    >
      {isSaveChapterTemp ? 'Save' : t('training:save_and_next_button')}
    </ActionButton>
  );
};

export default CategoryActionButton;

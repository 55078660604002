import React, { FC } from 'react';
import { Box, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import { Authorize, AuthRole } from 'authorization';

import TitleHeader from '../../../CardEditor/TitleHeader';
import { IFormInput } from '../task.types';
import AssignToSelector from './AssignToSelector';
import MemberAssignee from './MemberAssignee';
import { LocationAssignee } from './Locations';
import JobAssignee from './JobAssignee';
import AssigneeHint from './AssigneeHint';
import { SelectOption } from '../common';

interface IProps {
  members: SelectOption[];
  loading: boolean;
}

const AssigneeForm: FC<IProps> = ({ members, loading }) => {
  const { t } = useTranslation(['task']);
  const { control } = useFormContext<IFormInput>();

  return (
    <Box pt='4px'>
      <Box pt={4} pb={5}>
        <TitleHeader title={t('task:woWillDoTask')} />
        <Controller
          control={control}
          name='assignToType'
          rules={{
            required: t('task:validation.thisFieldRequired'),
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl mt={3} isInvalid={!!fieldState?.error}>
                <AssignToSelector {...field} />
                <FormErrorMessage>
                  {fieldState?.error?.message}
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />
      </Box>

      <LocationAssignee />

      <MemberAssignee members={members} loading={loading} />

      <Authorize
        permittedFor='user'
        permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
      >
        <JobAssignee />
      </Authorize>

      <AssigneeHint members={members} />
    </Box>
  );
};

export default AssigneeForm;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface ReturnKeys {
  job: string;
  location: string;
}
export const useErrorKeys = (): ReturnKeys => {
  const { t, i18n } = useTranslation(['form']);

  return useMemo(() => {
    return {
      job: t('form:validation.selectJob'),
      location: t('form:validation.selectLocation'),
    };
  }, [i18n.language]);
};

export const usePlaceHolderKeys = (): ReturnKeys => {
  const { t, i18n } = useTranslation(['role', 'location']);

  return useMemo(() => {
    return {
      job: t('role:selectJob'),
      location: t('location:selectLocation'),
    };
  }, [i18n.language]);
};

import React, { FC, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FormInput, PrimaryButton, SelectOption } from 'atoms';
import { useUploadImage, useUserData } from 'hooks';
import { generatePdfThumbnailFile, getFileName } from 'utils/pdfThumbnail';
import TitleHeader from '../../../../sub-components/CardEditor/TitleHeader';
import { ChapterFileUpload, FileItem } from '../common';
import FileVisibility from '../FileVisibility';
import { FileDataEntity } from '../create-chapter.graphql';
import { getFileType } from '../helper';
import { gql, useQuery } from '@apollo/client';
import CategoryForm from 'sub-components/ChapterEditor/CategoryModal/CategoryForm';
import { CategoryRef } from 'sub-components/ChapterEditor/CategoryModal/CategoryModal';
import { getQueryParamValue } from 'utils/queryParams';
import useCombinedStore from 'zustandStore/store';
import { parentDataRender } from 'pages/Handbook/setVisibilityModal/SetSeeModal/utility';

const GET_USERS = gql`
  query BaseUploadFileUsers {
    userDescendants {
      authRole
      eid
      name
      profilePic
      role
      type
      username
      locations {
        eid
        name
      }
    }
  }
`;

interface IProps {
  buttonTitle?: string;
  isUploading?: boolean;
  file?: FileDataEntity;
  onFileChange?: (value?: FileDataEntity) => void;
  category?: SelectOption;
  onCategoryChange: (value: SelectOption) => void;
  title?: string;
  onTitleChange: (value: string) => void;
  onSubmitClick?: () => void;
  thumbnail?: string;
  onThumbnailChange?: (value?: string) => void;
  categoryModalRef: React.RefObject<CategoryRef>;
  onAddCategoryModalClose: () => void;
}

const BaseUploadFile: FC<IProps> = ({
  isUploading,
  file,
  onFileChange,
  onCategoryChange,
  category,
  onTitleChange,
  buttonTitle,
  title,
  onSubmitClick,
  thumbnail,
  onThumbnailChange,
  categoryModalRef,
  onAddCategoryModalClose,
}) => {
  const { hierarchyData, visibilityScope, visibilitySelection } =
    useCombinedStore();
  const statusValue = getQueryParamValue('status');
  const { t } = useTranslation(['chapter']);
  const [thumbUploading, setThumbUploading] = useState(false);

  const { data: _userData } = useQuery(GET_USERS);

  const uploadImage = useUploadImage();

  const _onFileChange = async (newFile: FileDataEntity) => {
    onFileChange?.(newFile);

    if (getFileType(newFile?.mimetype) === 'pdf') {
      setThumbUploading(true);
      const thumbFile = await generatePdfThumbnailFile(newFile.file!);
      uploadImage(thumbFile, getFileName(newFile.file!))
        .then(onThumbnailChange)
        .catch(console.log)
        .finally(() => {
          setThumbUploading(false);
        });
    } else {
      onThumbnailChange?.(undefined);
    }
  };

  const categoryFormRender = () => {
    if (statusValue === 'RESTRICT') {
      return null;
    }
    if (file || category) {
      return (
        <CategoryForm
          categoryId={category?.eid}
          ref={categoryModalRef}
          _visibilityData={category?.visibility}
          hideBtn
          onAddCategoryModalClose={onAddCategoryModalClose}
        />
      );
    }
  };

  return (
    <Flex flexDir='column' gap={4}>
      <Flex flexDir='column'>
        <TitleHeader title={t('chapter:giveChapterTitle')} />
        <FormInput
          mt={1}
          size='lg'
          placeholder={t('chapter:enterChapterTitle')}
          value={title}
          onChange={(e) => onTitleChange?.(e?.target?.value)}
        />
      </Flex>

      <Flex flexDir='column'>
        <TitleHeader title={t('chapter:uploadYourFile')} />
        <Flex h='8px' />
        {file ? (
          <FileItem
            isUploading={thumbUploading}
            file={file}
            onRemove={() => onFileChange?.(undefined)}
          />
        ) : (
          <ChapterFileUpload
            subTitle={t('chapter:uploadFileFormat')}
            accept={{
              'application/pdf': ['.pdf'],
              'video/*': ['.mpeg', '.mp4'],
              'video/x-m4v': [],
              'application/vnd.ms-excel': [],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                [],
              'application/msword': [],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                [],
            }}
            onFileChange={_onFileChange}
          />
        )}
      </Flex>

      {categoryFormRender()}

      {file && category && (
        <>
          <FileVisibility
            isEditMode={false}
            type='chapter'
            viewDirection='top-bottom'
          />
          {parentDataRender(
            visibilityScope,
            hierarchyData,
            visibilitySelection
          )}
        </>
      )}

      <PrimaryButton
        title={buttonTitle}
        variant='solid'
        colorScheme='blue'
        size='lg'
        mt={2}
        isLoading={isUploading}
        disabled={
          isUploading || !title || !file || !category?.value || thumbUploading
        }
        onClick={onSubmitClick}
      />
    </Flex>
  );
};

export default BaseUploadFile;

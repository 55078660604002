import Image from "../elements/Image";
import { FC, useMemo } from "react";
import NoContent from "../elements/NoContent";

interface IProps {
  title?: string;
  content: any[];
  isPreview?: boolean;
  layout?: string;
  shouldSetFixedHeight?: boolean;
}

const ImageType: FC<IProps> = ({ title, content, isPreview, shouldSetFixedHeight }) => {
  let style = {};
  if (isPreview) {
    style = {
      borderRadius: "15px",
    };
  }

  const imageLength = content[0]?.image?.length;

  const hasNoData = useMemo(() => {
    if (isPreview) {
      return imageLength <= 0;
    } else {
      return false;
    }
  }, [imageLength, isPreview]);

  if (hasNoData) {
    return <NoContent style={style} />;
  }

  return (
    <div style={{ minHeight: shouldSetFixedHeight ? '80vh' : 'none', height: shouldSetFixedHeight ? '80vh' : "inherit" }}>
      <Image content={content[0]} title={title} style={style} />
    </div>
  );
};

export default ImageType;

import React, { FC } from 'react';
import { Flex, useModalContext } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { TrainingPlayProvider } from '../../../pages/Training/TrainingPlay/context';
import {
  NextButton,
  PrevButton,
} from '../../../pages/Training/TrainingPlay/PrevNextButton';
import TrainingProgressBar from '../../../pages/Training/TrainingPlay/TrainingProgressBar';
import PlayComponent from '../../../pages/Training/TrainingPlay/PlayComponent';
import { InputVariable } from '../../../pages/Training/TrainingPlay/training-play.graphql';

import TrainingViewHeader from './TrainingViewHeader';

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
  padding-inline: 12px;
  justify-content: center;

  .training-desktop {
    padding: 0;
  }
`;

interface IProps {
  trainingId?: string;
  title?: string;
  inputVariable?: InputVariable;
  onFinish?: () => Promise<void> | void;
  formAccessOverride?: boolean;
}

const TrainingContent: FC<IProps> = ({
  trainingId,
  title,
  inputVariable,
  onFinish,
  formAccessOverride,
}) => {
  const { onClose } = useModalContext();

  const _onFinish = async () => {
    await onFinish?.();
    onClose();
  };

  return (
    <TrainingPlayProvider
      trainingId={trainingId}
      onFinish={_onFinish}
      inputVariable={inputVariable}
      formAccessOverride={formAccessOverride}
    >
      <Wrapper>
        <Flex
          position='fixed'
          left='calc(50% - 460px)'
          top='50%'
          align='center'
          minW='42px'
        >
          <PrevButton />
        </Flex>
        <Flex
          flexDir='column'
          gap={8}
          flex={5}
          overflow='hidden'
          borderRadius={8}
          maxW='962px'
        >
          <TrainingViewHeader title={title} />
          {/*<TrainingPlayHeader />*/}
          <TrainingProgressBar />
          <PlayComponent />
        </Flex>
        <Flex
          position='fixed'
          right='calc(50% - 460px)'
          top='50%'
          align='center'
          minW='42px'
        >
          <NextButton />
        </Flex>
      </Wrapper>
    </TrainingPlayProvider>
  );
};

export default TrainingContent;

import { Box, BoxProps } from '@chakra-ui/react';
import React, { FC } from 'react';

/**
 * Type for the props of CommonContainer.
 * It extends BoxProps from Chakra UI, allowing it to accept all the properties a Box component can.
 */
type ICommonContainerProps = BoxProps & {};

/**
 * `CommonContainer` is a functional React component wrapped around Chakra UI's `Box` component.
 * It serves as a common container element with flexible styling and layout options.
 *
 * @param {ICommonContainerProps} props - The props object, extending Chakra UI's BoxProps.
 * @returns {React.ReactElement} A Box component wrapping the children elements.
 */
const CommonContainer: FC<ICommonContainerProps> = (props) => {
  const { children } = props;
  return <Box {...props}>{children}</Box>;
};

export default CommonContainer;

export const colorsArray = [
    '#b5e4ca',
    '#2a85ff',
    '#d4c9fe',
    '#eb996e',
    '#b1e5fc',
    '#ffbc99',
    '#ffd88d',
    '#ffbb66',
    '#e4897b',
    '#90D6AF',
    '#0069F6',
    '#AC97FD',
    '#E57941',
    '#80D5FA',
    '#FF9B66',
    '#FFC75A',
    '#FFA433',
    '#DB6351',
    '#B5E4E2',
    '#392AFF',
    '#2AF0FF',
    '#EFC9FE',
    '#C9D8FE',
    '#EBD86E',
    '#EB6E82',
    '#B1C0FC',
    '#FF99A9',
    '#E4BD7B',
    '#E4CAB5',
    '#85FF2A',
    '#C9FED4',
    '#6EEB99',
    '#FCB1E5',
    '#E5FCB1',
    '#897BE4'
];
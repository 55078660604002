import React, { forwardRef, useMemo } from 'react';
import { Center, Flex, ListItem, Text, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faExpand } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ICON_TYPE, JSONContent } from 'delightree-editor';
import { CardModal } from '../../../../../modal';
import ChapterIconComponent from 'pages/Chapters/ChapterIconComponent';
import { IconImage } from 'ui-components';

interface IProps {
  item: CardModal;
  addItemHandler?: (item: CardModal) => void;
  getReadingTime?: (content: JSONContent) => string;
  onPreviewClick?: (content: JSONContent) => void;
  addPosition?: 'left' | 'right';
}

const TrainingHandbookListItem = forwardRef<never, IProps>((props, ref) => {
  const { item, addItemHandler, getReadingTime, onPreviewClick, addPosition } =
    props;

  const readingTime = useMemo(() => {
    if (getReadingTime) {
      return getReadingTime(item?.content?.[0]?.tiptap);
    }
    return undefined;
  }, [item?.content?.[0]?.tiptap]);

  const addIconElement = useMemo(() => {
    return (
      <Center id='box-1'>
        <FontAwesomeIcon
          icon={faCirclePlus as IconProp}
          fontSize='20px'
          color='#6f767e'
          cursor='pointer'
          onClick={() => addItemHandler?.(item)}
        />
      </Center>
    );
  }, [addItemHandler, item]);

  return (
    <ListItem ref={ref}>
      <Flex id='flex-1' alignItems={'center'} gap={2}>
        {addPosition === 'left' && addIconElement}
        <IconImage
          icon={item?.icon}
          name={item?.title}
          files={item?.files}
          thumbnail={item?.thumbnail}
          boxSize={50}
          iconFontSize='26px'
          borderRadius='8px'
        />
        {/* <ChapterIconComponent
          type={item?.icon?.id === ICON_TYPE ? 'icon' : 'emoji'}
          sopType={item?.sopType}
          fileType={item?.fileType}
          border={undefined}
          height='60px'
          padding='.75rem'
          width='60px'
          minWidth='60px'
          borderRadius='10px'
          chapterMediaDetails={item?.mediaData?.[0]}
          chapterFileThumbnail={item?.thumbnail}
          chapterName={item.title}
          fontSize='60px'
          chapterLink={item?.icon?.native}
          imageStyle={{
            margin: 'auto',
            width: '60px',
            height: '100%',
            alignSelf: 'center',
            borderRadius: 10,
            objectFit: 'cover',
          }}
        /> */}
        <Flex
          flex={1}
          id='flex-3'
          justifyContent='flex-start'
          alignItems='center'
          maxW='full'
          overflow='hidden'
        >
          <Flex
            id='flex-4'
            flexDir='column'
            flex={1}
            align='baseline'
            mr='10px'
            overflow='hidden'
          >
            <Text
              id='text-1'
              marginY='0'
              fontWeight={500}
              fontSize='12px'
              color='#6F767E'
            >
              {item?.category}
            </Text>
            <Tooltip
              id='tooltip-1'
              placement='top'
              borderRadius='4px'
              p='8px 12px'
              label={item?.title}
              hasArrow
            >
              <Text
                id='text-2'
                fontSize='15px'
                fontWeight='600'
                marginY='0'
                isTruncated
                maxW='full'
                cursor='pointer'
              >
                {item?.title}
              </Text>
            </Tooltip>
            <Text
              id='text-3'
              marginY='0'
              fontWeight={500}
              fontSize='12px'
              color='#9A9FA5'
            >
              {readingTime}
            </Text>
          </Flex>
          {onPreviewClick && (
            <Flex id='flex-5' w='30px' justifyContent='center'>
              <FontAwesomeIcon
                width='20px'
                icon={faExpand as IconProp}
                fontSize='20px'
                cursor='pointer'
                onClick={() => onPreviewClick?.(item)}
              />
            </Flex>
          )}
        </Flex>
        {addPosition === 'right' && addIconElement}
      </Flex>
    </ListItem>
  );
});

TrainingHandbookListItem.defaultProps = {
  addPosition: 'left',
};

TrainingHandbookListItem.displayName = 'Training Handbook List Item';

export default TrainingHandbookListItem;

import { AnyObject, ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { FieldErrors } from 'react-hook-form';

import { IFormInput, Stepper } from '../task.types';
import { TaskCreationRef } from '../TaskCreationBase';

import { SchemaKeys } from './schema';

const steps: Stepper[] = [
  'details',
  'scheduler',
  'taskSteps',
  'assignees',
  'supervisors',
];

export const validateTaskData = async (
  schema: ObjectSchema<IFormInput, AnyObject, any, ''>,
  values: IFormInput,
  methods: TaskCreationRef
) => {
  const result = await yupResolver<IFormInput>(schema)(values, {}, {} as never);

  if (!isEmpty(result?.errors)) {
    for (const step of steps) {
      let haveError = false;
      const keyList = SchemaKeys[step];
      for (const key of keyList) {
        if (result.errors?.[key as never]) {
          methods.setError(key as never, result.errors[key as never]);
          haveError = true;
        }
      }
      if (haveError) {
        methods.setValue('currentStep', step);
        return Promise.reject('Task validation failed');
      }
    }
  } else {
    return result.values;
  }
};

export const handleValidationError = (
  errors: FieldErrors<IFormInput>
): Stepper | undefined => {
  if (!isEmpty(errors)) {
    const keys = Object.keys(errors);

    for (const step of steps) {
      const keyList = SchemaKeys[step];

      for (const key of keys) {
        if (keyList.includes(key as keyof IFormInput)) {
          return step;
        }
      }
    }
  }

  return undefined;
};

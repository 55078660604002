// @ts-ignore
import React, {
  FC,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Box } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import AddCategory from '../../../sub-components/Sidebar/category/AddCategory';
import CustomSelect from './CustomSelect';
import CategoryActionButton from './CategoryActionButton';
import TitleHeader from 'sub-components/CardEditor/TitleHeader';
import { useTranslation } from 'react-i18next';
import { IFormInput } from '../chaptor.types';
import { useFolderSubFolder } from 'hooks';
import { SelectOption } from 'atoms';
import { useSubFoldersList } from './useCategoryList';
import DashboardDataContext from 'sub-components/DashboardContainer/DashboardStore/DashboardData/dashboard-data-context';
import { cloneDeep } from '@apollo/client/utilities';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { GET_USER } from 'pages/Login/login.graphql';
import { userObj } from 'sop-commons/src/client';
import { CategoryRef } from './CategoryModal';
import { IShowState } from '../hooks/types';
import { foldersListVar } from 'pages/Chapters/ChapterContainer/ChapterContainer';
import useCombinedStore from 'zustandStore/store';
import { getQueryParamValue } from 'utils/queryParams';

interface IProps {
  categoryId?: string;
  isSaveChapterTemp?: boolean;
  ref: React.ForwardedRef<CategoryRef>;
  _visibilityData: {
    locations: string[];
    roles: string[];
    users: string[];
    condition?: string | null | undefined;
    visibility?: string | undefined;
  };
  hideBtn?: boolean;
  saveTemporaryHandler?: () => void;
  updateShowStateHandler?: (data: IShowState) => void;
  onAddCategoryModalClose: () => void;
}

const CategoryForm: FC<IProps> = ({
  categoryId,
  isSaveChapterTemp,
  hideBtn,
  ref,
  _visibilityData,
  saveTemporaryHandler,
  updateShowStateHandler,
  onAddCategoryModalClose,
}) => {
  const statusValue = getQueryParamValue('status');
  const nameValue = getQueryParamValue('name');
  const typeValue = getQueryParamValue('type');
  const category = useWatch<IFormInput, 'category'>({
    name: 'category',
  });
  const { folderName, parentFolder, subFolders, subFoldersPresent } =
    useFolderSubFolder(category?.eid);
  const {
    operationType,
    visibilityScope,
    hierarchyData,
    updateIsInitialFetch, // <- This fn is used in 'EditChapterModal' to let that component know about change in the dropdown
    updateHierarchyData,
    updateVisibilityScope,
    updateOperationType,
  } = useCombinedStore();
  const [newCategory, setNewCategory] = useState(false);
  const [categoryList, setCategoryList] = useState<SelectOption<string>[]>([]);
  const dashboardCtx = useContext(DashboardDataContext);
  const initialCategoryList = useReactiveVar(foldersListVar);
  const initialSubFoldersList = useSubFoldersList();
  const {
    control,
    getValues,
    setValue,
    reset: formReset,
  } = useFormContext<IFormInput>();

  const subFoldersList = useMemo(() => {
    let _list: SelectOption<string>[] = subFolders?.map((sub) => {
      return {
        label: sub.name,
        value: sub.name,
        ...sub,
      };
    });
    return _list;
  }, [subFolders, statusValue, nameValue, typeValue, hierarchyData]);

  useImperativeHandle(ref, () => ({
    saveChapterHandler,
  }));

  const saveChapterHandler = () => {
    // return onPublishHandler();
  };

  useEffect(() => {
    let _initialCategoryList = cloneDeep(initialCategoryList);
    let _updatedList: SelectOption<string>[] = [];
    _initialCategoryList?.forEach((initial) => {
      _updatedList?.push({
        ...initial,
        label: initial.name,
        value: initial.name,
      });
    });
    setCategoryList(_updatedList || []);
  }, [initialCategoryList]);

  useEffect(() => {
    if (dashboardCtx.selectedFolder?.eid) {
      let categoryId = dashboardCtx.selectedFolder?.eid;
      let aggregatedList = [...categoryList, ...initialSubFoldersList];
      aggregatedList?.forEach((list) => {
        if (list?.eid === categoryId) {
          if (list?.parentId) {
            // The found item is sub folder
            let parentFolder = categoryList?.filter(
              (category) => category?.eid === list?.parentId
            )?.[0];
            const newObj = {
              category: {
                ...parentFolder,
                label: parentFolder?.name,
                value: parentFolder?.name,
              },
              subFolder: {
                ...list,
                label: list?.name,
                value: list?.name,
              },
            };
            // formReset(newObj);
            // setValue('category', {
            //   ...parentFolder,
            //   label: parentFolder?.name,
            //   value: parentFolder?.name,
            // });
            // setValue('subFolder', {
            //   ...list,
            //   label: list?.name,
            //   value: list?.name,
            // });
            // reset(newObj);
            const newValues = {
              ...getValues(),
              category: {
                ...parentFolder,
                label: parentFolder?.name,
                value: parentFolder?.name,
              },
              subFolder: {
                ...list,
                label: list?.name,
                value: list?.name,
              },
            };
            formReset(newValues);
          } else {
            // The found item is main folder
            const newValues = {
              ...getValues(),
              category: {
                ...list,
                label: list?.name,
                value: list?.name,
              },
              subFolder: undefined,
            };
            formReset(newValues);
            // setValue('category', {
            //   ...list,
            //   label: list?.value,
            //   value: list?.eid,
            // });
          }
        }
      });
    }
  }, [dashboardCtx.selectedFolder, initialSubFoldersList, categoryList]);

  useEffect(() => {
    if (categoryId && categoryList?.length > 0) {
      // let foundCategory = customCategoryList?.filter(
      //   (category) => category.value === categoryId
      // );
      let aggregatedList = [...categoryList, ...initialSubFoldersList];
      aggregatedList?.forEach((list) => {
        if (list?.eid === categoryId) {
          if (list?.parentId) {
            // The found item is sub folder
            let parentFolder = categoryList?.filter(
              (category) => category?.eid === list?.parentId
            )?.[0];
            const newValues = {
              ...getValues(),
              category: {
                ...parentFolder,
                label: parentFolder?.name,
                value: parentFolder?.name,
              },
              subFolder: {
                ...list,
                label: list?.name,
                value: list?.name,
              },
            };
            formReset(newValues);
            // setValue('category', {
            //   ...parentFolder,
            //   label: parentFolder?.value,
            //   value: parentFolder?.eid,
            // });
            // setValue('subFolder', {
            //   ...list,
            //   label: list?.value,
            //   value: list?.eid,
            // });
          } else {
            // The found item is main folder
            setValue('category', {
              ...list,
              label: list?.name,
              value: list?.name,
            });
          }
        }
      });
    }
  }, [categoryId, categoryList]);

  // useEffect(() => {
  //   setValue('subFolder', undefined);
  // }, [category]);

  const categoryCreateHandler = (cat: any) => {
    formReset({
      ...getValues(),
      category: {
        ...cat,
        label: cat?.name,
        value: cat?.name,
      },
    });
    updateHierarchyData({
      ...hierarchyData,
      parentFolder: {
        details: cat,
        visibilityData: {
          locations: cat?.visibleTo?.locations || [],
          roles: cat?.visibleTo?.roles || [],
          users: cat?.visibleTo?.users || [],
          condition:
            cat?.visibleTo?.locations?.length > 0 &&
            cat?.visibleTo?.roles?.length > 0
              ? cat?.visibleTo?.condition
              : undefined,
          visibility: cat?.visibility || 'public',
        },
      },
      subFolder: undefined,
    });
    dashboardCtx?.selectedFolderHandler(undefined);
    // setValue('category', {
    //   ...cat,
    //   label: cat?.name,
    //   value: cat?.name,
    // });
    // getUser({ variables: {} });
  };

  console.log({ dashboardCtx, value: getValues() });

  return (
    <>
      <Controller
        control={control}
        name={'category'}
        render={({ field }) => {
          return (
            <Box mt={2}>
              <TitleHeader title='Folder' />
              <CustomSelect
                options={categoryList}
                setNewCategory={setNewCategory}
                value={field.value}
                field={field}
                clickHandler={() => {
                  setNewCategory(true);
                  // reset();
                  updateOperationType('add');
                  updateVisibilityScope('folder');
                }}
                handleChange={(option: any) => {
                  field.onChange(option);
                  setValue('subFolder', {
                    label: '',
                    value: '',
                  });
                  updateIsInitialFetch(false); // <- This fn is used in 'EditChapterModal' to let that component know about change in the dropdown
                }}
              />
            </Box>
          );
        }}
      />
      {subFoldersPresent && (
        <Controller
          control={control}
          name={'subFolder'}
          render={({ field }) => {
            return (
              <Box mt={2}>
                <TitleHeader title='Subfolder (Optional)' />
                <CustomSelect
                  options={subFoldersList}
                  setNewCategory={setNewCategory}
                  value={field.value}
                  field={field}
                  hideCreateButton
                  handleChange={(option: any) => {
                    if (option.value === 'create') {
                      updateOperationType('add');
                      updateVisibilityScope('folder');
                      setNewCategory(true);
                    } else {
                      field.onChange(option);
                      updateIsInitialFetch(false); // <- This fn is used in 'EditChapterModal' to let that component know about change in the dropdown
                    }
                  }}
                />
              </Box>
            );
          }}
        />
      )}
      {!hideBtn && (
        <CategoryActionButton
          category={category}
          isSaveChapterTemp={isSaveChapterTemp}
          saveTemporaryHandler={saveTemporaryHandler}
          updateShowStateHandler={updateShowStateHandler}
        />
      )}
      <AddCategory
        open={newCategory}
        onClose={() => {
          setNewCategory(false);
          onAddCategoryModalClose();
        }}
        zIndex={2002}
        type='chapter'
        onCategoryCreate={(cate) => {
          categoryCreateHandler(cate);
          updateIsInitialFetch(false); // <- This fn is used in 'EditChapterModal' to let that component know about change in the dropdown
        }}
      />
    </>
  );
};

export default CategoryForm;

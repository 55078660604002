import React, { FC } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { SelectOption } from '../common';
import { useSupervisorNames } from './SupervisorHint';

interface IProps {
  members: SelectOption[];
}

const SupervisorStepperDescription: FC<IProps> = ({ members }) => {
  const { t } = useTranslation('task');

  const [selected, haveMore] = useSupervisorNames(members);

  if (!selected?.length) {
    return <>{t('woWillManage')}</>;
  }

  return (
    <Tooltip
      label={
        <Trans
          t={t}
          i18nKey='supervisorNames'
          count={haveMore}
          values={{
            names: haveMore > 1 ? selected.join(', ') : selected,
          }}
        />
      }
      hasArrow
      borderRadius='6px'
      padding='4px 8px'
    >
      <Box gap={1} isTruncated>
        <Trans
          t={t}
          i18nKey='supervisorNames'
          count={haveMore}
          values={{
            names: haveMore > 1 ? selected.join(', ') : selected,
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default SupervisorStepperDescription;

import React, { FC, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BoxHeader, SingleUserChatModal } from 'ui-components';
import ActivityFeed from 'ui-components/ActivityFeed';
import { useHasChatAccess } from 'hooks/useChatRestriction';

interface IProps {
  locationId?: string;
}

const Activity: FC<IProps> = ({ locationId }) => {
  const { t } = useTranslation(['activity']);
  const [showSingleUserChatModal, setShowSingleUserChatModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const setShowSingleUserChatModalHandler = (value: any) => {
    setShowSingleUserChatModal(value);
  };
  const chatAccess = useHasChatAccess();

  const setSelectedUserHandler = (user: any) => {
    setSelectedUser(user?.eid);
    chatAccess({
      eid: user?.eid,
      role: user?.role,
      authRole: user?.authRole,
    });
  };
  return (
    <Box id='location-asset-card-activity'>
      <Flex height='40px'>
        <BoxHeader
          fontSize='16px'
          title={t('activity:activity_feed')}
          color='#CABDFF'
        />
      </Flex>
      <ActivityFeed
        showSpecificLocation={locationId}
        hideHeader
        setShowSingleUserChatModalHandler={setShowSingleUserChatModalHandler}
        setSelectedUserHandler={setSelectedUserHandler}
      />
      {showSingleUserChatModal && (
        <SingleUserChatModal
          onClose={() => setShowSingleUserChatModal(false)}
          selectedUser={selectedUser}
        />
      )}
    </Box>
  );
};

export default Activity;

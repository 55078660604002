import React, { CSSProperties, FC, useMemo } from 'react';
import {
  Box,
  Flex,
  List,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Tooltip,
  Text,
} from '@chakra-ui/react';

import { toArray, truncateString } from '../../../../utils';
import { IconImage } from '../../../../ui-components';

import { PopoverTrigger } from '../location-helper-functions';

import { ILocationMember } from '../../../../sub-components/Locations/locationTypes/location-types';
import MemberItem from './MemberItem';

const wrapStyles: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '80px',
};

interface IProps {
  members: ILocationMember[];
  locationName?: string;
}

const LocationMembers: FC<IProps> = ({
  members: _members = [],
  locationName,
}) => {
  const [members, otherMembers] = useMemo(() => {
    const totalMembers = toArray(_members);

    return [totalMembers.slice(0, 1), totalMembers.slice(1)];
  }, [_members]);

  if (!_members?.length) {
    return null;
  }

  return (
    <Flex alignItems='center' gap='8px'>
      <Flex
        bg='rgba(202, 189, 255, 0.4)'
        borderRadius='7px'
        width='fit-content'
        p='5px 8px'
        alignItems='center'
        gap='4px'
        cursor='pointer'
      >
        <Tooltip
          label={`${members?.[0]?.name}, ${members?.[0]?.role}`}
          padding='6px 8px'
          hasArrow
        >
          <Flex fontSize='14px' fontWeight='600' gap='8px' align='center'>
            <IconImage
              name={members?.[0]?.name}
              thumbnail={members?.[0]?.thumbnail || members?.[0]?.profilePic}
              boxSize={20}
              borderRadius='5px'
            />
            <Flex gap='4px' width='max-content' maxWidth='100%'>
              <Box style={wrapStyles}>
                {truncateString(members?.[0]?.name, 16)}
              </Box>
            </Flex>
          </Flex>
        </Tooltip>
      </Flex>
      {otherMembers?.length > 0 && (
        <Popover placement='bottom-end'>
          <PopoverTrigger>
            <Box
              as='button'
              border='1px solid rgba(221, 221, 221, 1)'
              p='5px 8px'
              borderRadius='7px'
              cursor='pointer'
            >
              <Flex fontSize='14px' gap='3px' fontWeight='500'>
                <span>+</span>
                <span>{otherMembers.length}</span>
              </Flex>
            </Box>
          </PopoverTrigger>
          <PopoverContent borderRadius='12px'>
            <PopoverArrow />
            {/* <PopoverCloseButton /> */}
            <PopoverBody padding='12px 16px'>
              <List spacing={4} styleType='none' maxH='300px' overflow='scroll'>
                {otherMembers.map((member, index) => {
                  return (
                    <MemberItem
                      key={index}
                      member={member}
                      locationName={locationName}
                    />
                  );
                })}
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );
};

export default LocationMembers;

import React from 'react';
import { Box } from '@chakra-ui/react';
import { HeaderGroup, UseSortByColumnProps } from 'react-table';

import TableHeaderSort from './TableHeaderSort';

export interface TableHeaderCellProps<D extends object = {}> {
  column: HeaderGroup<D> & UseSortByColumnProps<D>;
}

function TableHeaderCell<D extends object = {}>({
  column,
}: TableHeaderCellProps<D>): React.ReactElement {
  return (
    <Box
      as='th'
      {...column.getHeaderProps(
        column.getSortByToggleProps?.({ title: undefined })
      )}
      style={{
        textTransform: 'capitalize',
        color: '#6f767e',
        borderBottom: 'none',
        textAlign: 'left',
        fontSize: '13px',
        width: column?.width,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: column.canSort ? 'pointer' : 'initial',
        }}
      >
        {column.render('Header')}
        <TableHeaderSort column={column} />
      </div>
    </Box>
  );
}

export default TableHeaderCell;

import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BoxHeader, useConfirm } from 'ui-components';
import { toArray } from 'utils/utils';

interface TrainingData {
  title: string;
}

interface IProps {
  trainings: TrainingData[];
  onDeletePress: (...args: any[]) => any | PromiseLike<any>;
}

type ChapterDelete = (props: IProps) => void;

export const useChapterDelete = (): ChapterDelete => {
  const confirm = useConfirm();
  const { t } = useTranslation(['common', 'chapter']);

  const getMessage = useCallback(
    (trainings: TrainingData[]) => {
      const TL = toArray(trainings).map((a) => a.title);

      if (TL.length > 0) {
        const TList = TL.slice();

        const tName = TList.splice(0, 2);
        const tLength = TList.length;

        if (tLength > 0) {
          // @ts-ignore
          return t('chapter:remove.chapterTrainings', {
            training: tName,
            tLength: tLength,
          });
        } else {
          return t('chapter:remove.chapterTraining', {
            training: tName,
            tLength: tLength,
          });
        }
      }

      return t('chapter:remove.chapter');
    },
    [t]
  );

  return useCallback(
    ({ onDeletePress, trainings }) => {
      confirm({
        title: (
          <BoxHeader title={t('chapter:deleteChapterQues')} color='#CABDFF' />
        ),
        content: (
          <Box color='#6F767E' fontSize='16px' fontWeight='500'>
            {getMessage(trainings)}
          </Box>
        ),
        isCentered: true,
        contentProps: {
          py: '16px',
          px: '24px',
          minW: '600px',
        },
        cancelButtonProps: {
          variant: 'outline',
          size: 'lg',
        },
        okButtonProps: {
          colorScheme: 'red',
          size: 'lg',
        },
        okText: t('common:delete'),
        onOK: onDeletePress,
      });
    },
    [confirm, getMessage]
  );
};

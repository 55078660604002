import React, { FC } from 'react';
import { Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface IProps {
  children: React.ReactNode;
}

const CameraTooltip: FC<IProps> = ({ children }) => {
  const { t } = useTranslation('card');

  return (
    <Tooltip
      label={t('capture_not_support')}
      hasArrow
      borderRadius='6px'
      padding='4px 8px'
    >
      {children as never}
    </Tooltip>
  );
};

export default CameraTooltip;

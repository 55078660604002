import React, { useCallback, useRef } from 'react';
import {
  AlertDialogCloseButton,
  Box,
  Center,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { useMutation } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';

import { BoxHeader, useConfirm } from 'ui-components';
import { useUserData, useUserDataQuery } from 'hooks';

import { useMoveMemberTo } from './useMoveMemberTo';

import {
  DELETE_LOCATION_QUERY,
  DeleteLocationResponse,
  DeleteLocationVariable,
} from './delete-location.graphql';

import { ReactComponent as ArchiveIcon } from '../../../assets/images/archive-solid.svg';
import { deployEvent } from 'shared';
import { AmplitudeEventNames } from 'shared/amplitudeEvents';

interface LocationData {
  eid: string;
  name: string;
}

type ILocationDelete = (data: LocationData) => void;

interface IProps {
  onMoveSuccess?: () => void;
  onDeleteSuccess?: () => void;
}

export const useLocationDelete = ({
  onMoveSuccess,
  onDeleteSuccess,
}: Partial<IProps> = {}): ILocationDelete => {
  const { t } = useTranslation(['common', 'category', 'location']);
  const toast = useToast({
    position: 'top-right',
  });
  const confirm = useConfirm();
  const moveMemberTo = useMoveMemberTo();
  const confirmRef = useRef<ReturnType<typeof confirm>>();
  const entityId = useUserData()?.entityId;

  const [fetchUserData] = useUserDataQuery();

  const [deleteCategory] = useMutation<
    DeleteLocationResponse,
    DeleteLocationVariable
  >(DELETE_LOCATION_QUERY, {
    onCompleted: (response) => {
      if (response?.deleteLocationWithUsers) {
        fetchUserData();
        onDeleteSuccess?.();
        toast({
          status: 'success',
          title: t('common:success'),
          description: t('location:locationDeletedSuccess'),
        });
      }
    },
  });

  const onCancelPress = useCallback(
    (data: LocationData) => {
      moveMemberTo({
        locationId: data.eid,
        locationName: data.name,
        onBackPress: () => onDeletePress(data),
        onDeletePress: () => {
          return deleteCategory({
            variables: {
              locationId: data.eid,
            },
          });
        },
        onMoveSuccess: onMoveSuccess,
      });
    },
    [deleteCategory, entityId, onMoveSuccess]
  );

  const onDeletePress = useCallback(
    (data: LocationData) => {
      confirmRef.current = confirm({
        title: (
          <Flex gap='12px'>
            <Box flex={1}>
              <BoxHeader
                // @ts-ignore
                title={t('location:deleteNameQues', {
                  value: data.name,
                })}
                color='#CABDFF'
              />
            </Box>
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
              onClick={(event) => {
                event.preventDefault();
                confirmRef.current?.destroy?.();
              }}
            />
          </Flex>
        ),
        content: (
          <Flex
            p='16px 20px'
            gap={2}
            borderRadius='10px'
            bg='rgba(177, 229, 252, 0.40)'
          >
            <Center height='18px' mt='1px'>
              <ArchiveIcon />
            </Center>

            <Box fontSize='14px' fontWeight='400' color='#33383F'>
              <Trans
                t={t}
                i18nKey='location:deleteLocationWarning'
                components={{
                  strong: <strong />,
                }}
              />
              {/* {t('location:deleteLocationWarning')} */}
            </Box>
          </Flex>
        ),
        size: '2xl',
        isCentered: true,
        contentProps: {
          paddingTop: '12px',
          paddingBottom: '12px',
        },
        okButtonProps: {
          size: 'lg',
          fontSize: '15px',
          colorScheme: 'red',
          borderRadius: '10px',
        },
        cancelButtonProps: {
          size: 'lg',
          fontSize: '15px',
          colorScheme: 'blue',
          borderRadius: '10px',
        },
        cancelText: t('location:moveToAnotherLocation'),
        okText: t('category:delete_anyway'),
        onOK: () => {
          deployEvent(AmplitudeEventNames.LOCATION_DELETE_ANYWAY_BUTTON);
          return deleteCategory({
            variables: {
              locationId: data.eid,
            },
          });
        },
        onCancel: () => onCancelPress(data),
      });
    },
    [deleteCategory, onCancelPress, entityId]
  );

  return onDeletePress;
};

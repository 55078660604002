import React, { FC } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../atoms/FormInput';
import PrimaryButton from '../../../atoms/PrimaryButton';
import TitleHeader from '../TitleHeader';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trashIcon.svg';
import '../CardEditor.scss';
import { CardFormInput } from '../card-editor.types';

interface IProps {
  isCorrect?: boolean;
  inputId: string;
  optionId: string;
  currentIndex: number;
  answerIndex: number;
  isReadOnly?: boolean;
  handleRemove: (optionId: string, index: number) => void;
  handleClickCorrect: (optionId: string) => void;
}

const AnswerInputCard: FC<IProps> = (props) => {
  const { t } = useTranslation('card');
  const { control } = useFormContext<CardFormInput>();

  const {
    inputId,
    optionId,
    answerIndex,
    handleRemove,
    handleClickCorrect,
    currentIndex,
    isCorrect,
  } = props;

  return (
    <div>
      <TitleHeader
        title={t('answer_option', { value: answerIndex })}
        titleStyle={{ marginTop: '30px' }}
        isRequired
      />
      <div className='quizAnswerCover'>
        <Controller
          name={`choices.${currentIndex}.text`}
          control={control}
          rules={{
            required: t('validation.option_required', {
              value: answerIndex,
            }),
          }}
          render={({ field, fieldState }) => {
            return (
              <FormControl isInvalid={!!fieldState.error}>
                <FormInput
                  id={inputId}
                  size='lg'
                  isDisabled={props.isReadOnly}
                  {...field}
                />
                <FormErrorMessage>
                  <span>{fieldState.error?.message}</span>
                </FormErrorMessage>
              </FormControl>
            );
          }}
        />

        <PrimaryButton
          type='button'
          size='lg'
          title={t('correct')}
          disabled={props.isReadOnly}
          onClick={() => handleClickCorrect(optionId)}
          variant={isCorrect ? 'solid' : 'outline'}
          colorScheme={isCorrect ? 'blue' : 'gray'}
          className='quizAnswerCorrectBtn'
        />
        {!props.isReadOnly && (
          <DeleteIcon
            className='deleteOptionIcon'
            onClick={() => handleRemove(optionId, currentIndex)}
          />
        )}
      </div>
    </div>
  );
};

export default AnswerInputCard;

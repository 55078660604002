import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';

import { ChatGroupEntity } from '../../../../types';
import { getImage } from '../../../../utils';
import { Image } from '../../../../ui-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 25px;

  .avatar-list {
    position: relative;
    display: flex;
    overflow: hidden;

    .training-item {
      border-radius: 7px;
      padding: 5px 10px;

      background: rgba(177, 229, 252, 0.5);
      max-width: 100%;
      width: max-content;
      overflow: hidden;
    }

    img {
      width: 25px;
      height: 25px;
      border-radius: 5px;
    }
  }

  .more-button {
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
    border-radius: 7px;
    border: 1px solid #dddddd;
    padding: 5px 10px;
    white-space: nowrap;
  }
`;

interface IProps {
  owner: ChatGroupEntity['owner'];
  membersCount?: number;
}

const GroupMember: FC<IProps> = ({ owner, membersCount }) => {
  const { t } = useTranslation('training');

  return (
    <Wrapper>
      <Tooltip
        label={owner.name.concat(', ', owner.role)}
        hasArrow
        p='8px 12px'
        borderRadius='8px'
      >
        <div className='avatar-list'>
          <Flex className='training-item' gap='7px' align='center'>
            <Image
              width={25}
              height={25}
              src={getImage(owner.profilePic, owner.name)}
            />
            <Box fontWeight='600' isTruncated>
              {owner.name},{' '}
              <Box as='span' fontWeight='400'>
                {owner.role}
              </Box>
            </Box>
          </Flex>
        </div>
      </Tooltip>
      {membersCount! > 1 && (
        <div className='more-button'>
          <Trans
            t={t}
            i18nKey='count_more'
            values={{
              value: membersCount! - 1,
            }}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default GroupMember;

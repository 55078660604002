import React, { FC } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { localizationEnabled } from '../../utils/constants';

interface IProps {
  children?: React.ReactNode;
  isEdit?: boolean;
}

const EditProvider: FC<IProps> = ({ children, isEdit }) => {
  const { i18n } = useTranslation();

  if (!isEdit || !localizationEnabled) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <I18nextProvider i18n={i18n.cloneInstance()}>
      <React.Fragment>{children}</React.Fragment>
    </I18nextProvider>
  );
};

export default EditProvider;

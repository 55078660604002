import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDialogCloseButton, Flex } from '@chakra-ui/react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import { BoxHeader, useConfirm } from '../../../ui-components';
import { AlertBox } from '../../../atoms';

interface IProps {
  name: string;
  onOkPress?: (...args: any[]) => any | PromiseLike<any>;
  onCancelPress?: (...args: any[]) => any | PromiseLike<any>;
}

type IDeleteConfirm = (props: IProps) => void;

export const useDeleteConfirm = (): IDeleteConfirm => {
  const { t } = useTranslation(['common', 'invite']);
  const confirm = useConfirm();

  return useCallback(
    ({ name, onOkPress, onCancelPress }) => {
      confirm({
        title: (
          <Flex gap='12px' justify='space-between'>
            <BoxHeader
              // @ts-ignore
              title={t('common:deleteNameQues', {
                name: name,
              })}
              color='#CABDFF'
            />
            <AlertDialogCloseButton
              pos='relative'
              borderRadius='full'
              top='0'
              right='0'
              onClick={() => onCancelPress?.()}
            />
          </Flex>
        ),
        content: (
          <AlertBox icon={faTrashCan} fontSize='14px'>
            {t('invite:memberDeleteHelpText')}
          </AlertBox>
        ),
        isCentered: true,
        contentProps: {
          py: '16px',
          px: '10px',
          minW: '760px',
        },
        okButtonProps: {
          colorScheme: 'red',
          size: 'lg',
          fontSize: '15px',
          borderRadius: '7px',
          minW: '160px',
        },
        okText: t('common:delete'),
        cancelText: null,
        onOK: onOkPress,
        onCancel: onCancelPress,
      });
    },
    [confirm]
  );
};

import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  IconButton,
  SystemProps,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, PrimaryButton } from 'atoms';

import { IFormInput } from '../../AddLocation/add-location.types';
import TitleHeader from '../../../../sub-components/CardEditor/TitleHeader';
import { OwnerOption, useLocationOwnerList } from '../LocationOwner';
import InviteUserModal from '../../../../ui-components/InviteUserNew/InviteUserModal';
import { AuthRole } from '../../../../authorization';
import { CustomLocationOwnerSelect } from 'sub-components/CustomDropdowns';
import { UserType } from 'types';
import { AddTempLO } from 'ui-components/InviteUserNew/InviteUserForm';

interface IProps {
  hideLOBtn?: boolean;
  isDisabled?: boolean;
  templateColumns?: SystemProps['gridTemplateColumns'];
}

const OwnerListField: FC<IProps> = ({
  hideLOBtn,
  isDisabled,
  templateColumns,
}) => {
  const { t } = useTranslation(['common', 'location']);
  const { control, getValues, setValue } = useFormContext<IFormInput>();
  const [openInvite, setOpenInvite] = useState<number>();
  const [tempLOList, setTempLOList] = useState<AddTempLO[]>([]);

  const { fields, append, remove } = useFieldArray<
    IFormInput,
    'locationOwners'
  >({
    name: 'locationOwners',
  });

  const isLocationEdit = useWatch<IFormInput, 'eid'>({
    name: 'eid',
  });
  // const isReviewAdd = /^\/locations\/review-add\/[^/]+$/.test(
  //   window?.location?.pathname
  // );

  const { locationOwners, loading, refetch } = useLocationOwnerList();

  const members = useMemo(() => {
    let list: OwnerOption[] = locationOwners.map((lo) => ({
      ...lo,
      label: lo.name,
      value: lo.eid,
    }));

    if (tempLOList?.length > 0) {
      list = [
        ...list,
        ...tempLOList?.map((temp) => ({
          label: temp.name,
          value: temp.eid,
          authRole: AuthRole.LOCATION_OWNER,
          eid: temp?.eid,
          locations: [],
          name: temp?.name,
          role: temp?.role,
          type: 'user' as UserType,
          profilePic: temp?.profilePic,
        })),
      ];
    }
    return list;
  }, [locationOwners, tempLOList]);

  const onInviteClick = (selectIndex: number) => {
    setOpenInvite(selectIndex);
  };

  const onActionCompleted = () => {
    console.log('onActionCompleted');
  };

  const addOwnerBtn = () => {
    return (
      <PrimaryButton
        width='fit-content'
        size='lg'
        variant='outline'
        borderWidth='2px'
        title={t('location:addAnotherOwner')}
        onClick={() => append({ owner: '' as never })}
      />
    );
  };

  const baseRender = (
    field: ControllerRenderProps<IFormInput, `locationOwners.${number}.owner`>,
    fieldState: ControllerFieldState,
    array: FieldArrayWithId<IFormInput, 'locationOwners', 'id'>[],
    index: number
  ) => {
    return (
      <FormControl isInvalid={!!fieldState.error}>
        <Flex pos='relative' gap='4px'>
          {/* <LocationOwnerSelect
                isDisabled={isDisabled}
                members={members}
                placeholder={t('location:placeholder.owner')}
                value={field.value as never}
                onChange={field.onChange}
                showInviteButton={!!isLocationEdit}
                onInviteClick={() => onInviteClick?.(index)}
              /> */}
          <CustomLocationOwnerSelect
            options={members}
            loading={loading}
            isDisabled={isDisabled}
            placeholder={t('location:placeholder.owner')}
            value={field.value as never}
            searchFieldPlaceholder={t('location:placeholder.ownerSelect')}
            showSearchField
            onChange={field.onChange}
            showInviteButton={!hideLOBtn}
            onInviteClick={() => onInviteClick?.(index)}
          />

          {array.length > 1 && !isDisabled && (
            <Center pos='absolute' right='0' height='48px' px='6px'>
              <IconButton
                alignSelf='center'
                size='xs'
                borderRadius='full'
                aria-label='remove'
                icon={<CloseIcon boxSize='10px' />}
                onClick={() => remove(index)}
              />
            </Center>
          )}
        </Flex>
        <FormErrorMessage>
          <span>{fieldState.error?.message}</span>
        </FormErrorMessage>
      </FormControl>
    );
  };

  const dropdownRender = (
    field: ControllerRenderProps<IFormInput, `locationOwners.${number}.owner`>,
    fieldState: ControllerFieldState,
    array: FieldArrayWithId<IFormInput, 'locationOwners', 'id'>[],
    index: number
  ) => {
    if (templateColumns) {
      return (
        <Flex justify='space-between'>
          <Flex w={fields?.length !== index + 1 ? 'full' : '70%'}>
            {baseRender(field, fieldState, array, index)}
          </Flex>
          {!isDisabled && fields?.length === index + 1 && addOwnerBtn()}
        </Flex>
      );
    }
    return baseRender(field, fieldState, array, index);
  };

  const tempMemberAddHandler = (tempMember: AddTempLO) => {
    if (openInvite != null && openInvite >= 0) {
      // using loose inequality '=' to check for both 'null' and 'undefined'
      setTempLOList([...tempLOList, tempMember]);
      setValue(`locationOwners.${openInvite}.owner`, tempMember);
    }
  };

  return (
    <Box>
      <TitleHeader title={t('common:owner')} isRequired />
      <Grid
        templateColumns={templateColumns || 'repeat(3, 1fr)'}
        gap={4}
        pt={2}
      >
        {fields?.map((value, index, array) => {
          return (
            <Controller
              key={value.id}
              control={control}
              name={`locationOwners.${index}.owner`}
              rules={{
                required: t('location:validation.locationOwnerRequired'),
              }}
              render={({ field, fieldState }) => {
                return dropdownRender(field, fieldState, array, index);
              }}
            />
          );
        })}
        {!isDisabled && !templateColumns && addOwnerBtn()}
      </Grid>
      {openInvite !== undefined && (
        <InviteUserModal
          isOpen={true}
          onClose={() => setOpenInvite(undefined)}
          onActionCompleted={onActionCompleted}
          tempMemberAdd={tempMemberAddHandler}
          shouldDisableInviteSetupNowBtn={!isLocationEdit}
          shouldDisableLocationSelect={!isLocationEdit}
          shouldPreventLocationRemoval={!isLocationEdit}
          disableAllPermissions
          customActionBtn={
            !isLocationEdit ? (
              <Flex>
                <Button variant='solid' colorScheme='blue' size='lg'>
                  Add Owner
                </Button>
              </Flex>
            ) : null
          }
          shouldRefetch={async (newUser) => {
            setValue(`locationOwners.${openInvite}.owner`, {
              label: newUser.name,
              value: newUser.eid,
            });
            await refetch();
          }}
          inviteOnlyFor={AuthRole.LOCATION_OWNER}
          defaultValues={{
            location: [
              {
                id: isLocationEdit,
                value: getValues('locationName'),
                label: getValues('locationName'),
              },
            ],
          }}
        />
      )}
    </Box>
  );
};

export default OwnerListField;

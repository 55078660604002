import React, { FC } from 'react';
import { Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Authorize, AuthRole } from 'authorization';
import { ChatGroupEntity } from 'types';
import { useUserDataSelector } from 'hooks';
import { shallowEqual } from 'utils';

import { ReactComponent as EditIcon } from '../../../../assets/images/edit-dark.svg';
import { ReactComponent as SettingIcon } from '../../../../assets/images/setting.svg';

interface IProps {
  channelData: ChatGroupEntity;
  onClick?: (action: 'edit' | 'delete' | 'setting') => void;
}

const ChannelListAction: FC<IProps> = ({ channelData, onClick }) => {
  const { t } = useTranslation(['common', 'setting', 'sidebar']);
  const authUser = useUserDataSelector(
    (state) => ({
      eid: state.eid,
      authRole: state.authRole,
    }),
    shallowEqual
  );

  if (
    !(
      channelData?.owner?.eid === authUser?.eid ||
      authUser?.authRole === AuthRole.SUPER_ADMIN
    )
  ) {
    return null;
  }

  return (
    <Authorize
      permittedFor='user'
      permittedRoles={[AuthRole.SUPER_ADMIN, AuthRole.ADMIN]}
    >
      <Flex gap='4px' className='action-button' justify='center'>
        <Tooltip
          label={t('setting:editChannel')}
          hasArrow
          borderRadius='4px'
          padding='4px 10px'
        >
          <IconButton
            borderRadius='full'
            aria-label='edit'
            variant='ghost'
            icon={<EditIcon />}
            onClick={() => onClick?.('edit')}
          />
        </Tooltip>

        <Tooltip
          label={t('sidebar:setting')}
          hasArrow
          borderRadius='4px'
          padding='4px 8px'
        >
          <IconButton
            borderRadius='full'
            aria-label='setting'
            variant='ghost'
            icon={<SettingIcon />}
            onClick={() => onClick?.('setting')}
          />
        </Tooltip>
      </Flex>
    </Authorize>
  );
};

export default ChannelListAction;

import React, { FC } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

/**
 * Interface defining the props for the SelectField component.
 *
 * @property placeholder - (Required). The placeholder text for the select field.
 * @property selectInputBg - (Optional). Background color for the select input.
 * @property showChips - (Required). Boolean to control the display of chips.
 * @property loading - (Optional). Boolean to indicate if the component is in a loading state.
 * @property setShowList - (Required). Function to control the visibility of the dropdown list.
 */
interface IProps {
  placeholder: string;
  placeholderColor?: string;
  selectInputBg?: string;
  showChips: boolean;
  loading?: boolean;
  customSelectField?: React.ReactNode;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  customStyles?: any;
  showList?: boolean;
}

/**
 * SelectField is a functional component for rendering the select field of the dropdown.
 * It displays the placeholder, handles loading states, and manages interactions like opening the dropdown.
 *
 * @param props - IProps interface elements to configure the component.
 * @returns React Functional Component.
 */
const SelectField: FC<IProps> = ({
  placeholder,
  placeholderColor,
  selectInputBg,
  showChips,
  loading,
  customSelectField,
  setShowList,
  customStyles,
  showList,
}) => {
  if (customSelectField) {
    return (
      // @ts-ignore
      <Flex
        align='center'
        pos='relative'
        cursor={!loading ? 'pointer' : 'no-drop'}
        onClick={() => !loading && setShowList(true)}
      >
        {customSelectField}
      </Flex>
    );
  }
  return (
    <Flex
      align='center'
      w='full'
      style={customStyles}
      justify='space-between'
      bg={selectInputBg ? selectInputBg : '#f4f4f4'}
      p='0.45rem 1rem'
      flex={1}
      pos='relative'
      overflow='hidden'
      flexWrap='wrap'
      h='40px'
      borderRadius='10px'
      cursor={!loading ? 'pointer' : 'no-drop'}
      onClick={() => !loading && setShowList(!showList)}
    >
      <Text
        color={placeholderColor || (showChips ? '#A0AEC0' : 'black')}
        marginInlineStart='0.125rem'
        marginInlineEnd='0.125rem'
        fontSize='16px'
        maxW='200px'
        isTruncated
      >
        {placeholder}
      </Text>
      {!loading ? (
        <FontAwesomeIcon icon={faChevronDown as IconProp} size='sm' />
      ) : (
        <Spinner size='sm' />
      )}
    </Flex>
  );
};

export default SelectField;

import React, { FC, useMemo } from 'react';
import { Center, Flex, ListItem, Progress, Text } from '@chakra-ui/react';
import { match } from 'ts-pattern';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarLines,
  faCheck,
  faRepeat,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SectionKeysType, UserTaskSession } from './user-task.graphql';
import TaskTimeLeft from './TaskTimeLeft';
import TaskStatusIcon from 'ui-components/TaskStatusIcon';
import { ITaskStatus } from 'ui-components/TaskStatusIcon/types';

interface IProps {
  task: UserTaskSession;
  status: SectionKeysType;
  userTimeZone?: string;
  onClick?: (data: UserTaskSession) => void;
}

const UserTaskItem: FC<IProps> = ({ task, status, userTimeZone, onClick }) => {
  const stepCompleted = task.currentUserProgress?.completedTasks?.length || 0;

  const getTaskStatus = useMemo((): ITaskStatus | undefined => {
    /**
     * 'overdue',
  'dueToday',
  'thisWeek',
  'upcoming',
  'hidden',
  'completed',
     */
    switch (status) {
      case 'completed':
        return 'completed';
      case 'dueToday':
        return 'inProgress';
      case 'overdue':
        return 'overdue';
      case 'upcoming':
        return 'scheduled';
      case 'thisWeek':
        return 'inProgress';
      default:
        return undefined;
    }
  }, [status]);

  const [bg, border, color] = useMemo(() => {
    return match(status)
      .with('overdue', () => ['rgba(255, 106, 85, 0.1)', '#FF6A55', '#FF6A55'])
      .with('dueToday', () => [
        'rgba(177, 229, 252, 0.2)',
        '#2A85FF',
        '#2A85FF',
      ])
      .with('completed', () => [
        'rgba(131, 191, 110, 0.1)',
        '#83BF6E',
        '#83BF6E',
      ])
      .with('upcoming', () => ['#EFEFEF', '#6F767E', '#2985FF'])
      .otherwise(() => ['#EFEFEF', '#6F767E', '#2985FF']);
  }, [status]);

  return (
    <ListItem
      bg='white'
      cursor='pointer'
      _hover={{
        bg: 'gray.50',
      }}
      borderRadius='10px'
      onClick={() => onClick?.(task)}
    >
      <Flex align='center' w='full' gap='20px' p='12px 18px'>
        <Flex gap='7px' flex={1} justify='space-between'>
          <Flex gap='10px'>
            {getTaskStatus ? (
              <TaskStatusIcon
                status={getTaskStatus}
                iconStyles={{ marginTop: '4px' }}
                imgSize='16px'
              />
            ) : (
              <Center
                boxSize='18px'
                bg={bg}
                mt='4px'
                borderRadius='full'
                border='1px solid'
                borderColor={border}
              >
                <FontAwesomeIcon
                  icon={faCheck as IconProp}
                  color={border}
                  fontSize='12px'
                  style={{
                    display: status === 'completed' ? 'block' : 'none',
                  }}
                />
              </Center>
            )}
            {/* <Center
              boxSize='18px'
              bg={bg}
              mt='4px'
              borderRadius='full'
              border='1px solid'
              borderColor={border}
            >
              <FontAwesomeIcon
                icon={faCheck as IconProp}
                color={border}
                fontSize='12px'
                style={{
                  display: status === 'completed' ? 'block' : 'none',
                }}
              />
            </Center> */}

            <Flex flexDirection='column' gap='2px'>
              <Text m={0} isTruncated maxW='600px' color='#272B30'>
                {task?.title}
              </Text>
              <Flex gap='8px' align='center'>
                <Flex gap='5px' align='center' h='fit-content' color={color}>
                  <Flex fontSize='12px' align='center' gap='8px'>
                    <FontAwesomeIcon icon={faCalendarLines as IconProp} />
                    <Text m={0}>
                      {moment.utc(task.dueDate).format('MMM DD')}
                    </Text>
                    {task.task.repeatCycle && (
                      <>
                        <Text m={0} color='#BFC6CE'>
                          &bull;
                        </Text>
                        <FontAwesomeIcon icon={faRepeat as IconProp} />
                        <Text textTransform='capitalize' m={0}>
                          {task.task.repeatCycle}
                        </Text>
                      </>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex align='center'>
            {userTimeZone && (
              <TaskTimeLeft dueDate={task.dueDate} timeZone={userTimeZone} />
            )}
          </Flex>
        </Flex>
        <Flex flexDir='column' gap='2px'>
          <Flex gap='5px' align='center'>
            <Center
              boxSize='16px'
              borderRadius='6px'
              border='1.25px solid #92929D'
            >
              <FontAwesomeIcon
                icon={faCheck as IconProp}
                color='#92929D'
                fontSize='10px'
              />
            </Center>

            <Text color='#92929D' fontSize='14px' m={0}>
              {stepCompleted}/{task.itemCount}
            </Text>
          </Flex>
          <Progress
            colorScheme='green'
            size='xs'
            value={+((stepCompleted / task.itemCount) * 100).toFixed(0)}
            w='100px'
            borderRadius='5px'
          />
        </Flex>
      </Flex>
    </ListItem>
  );
};

export default UserTaskItem;

import { userObj } from 'sop-commons/src/client';
import { toArray } from 'utils/utils';

import { LocationStatusOption } from './static-data';
import { IFormInput } from './add-location.types';

export const getDefaultFormData = (): Partial<IFormInput> => {
  return {
    // Basic details ==
    // locationType: undefined,
    locationName: '',
    locationStatus: LocationStatusOption[0],
    locationEmail: [
      {
        email: '',
      },
    ],
    locationPhone: [
      {
        phone: '',
      },
    ],
    locationOwners: [
      {
        owner: '' as never,
      },
    ],

    // Login Details ==
    username: '',
    password: '',
    confirmPassword: '',

    // Address ==
    address: '',
    city: '',
    state: '',
    zipCode: '',
    // timezone: '',

    // Entity details ==
    // entityType: '',
    taxPayerId: '',

    // Other details ==
    otherFields: toArray(userObj()?.entity?.locationFields).reduce<
      IFormInput['otherFields']
    >((previousValue, currentValue) => {
      if (currentValue?.validations?.isRequired) {
        previousValue.push({
          fieldId: currentValue.eid,
          options: [],
          value: '',
          files: [],
        });
      }
      return previousValue;
    }, []),
  };
};

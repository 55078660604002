import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Progress,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../atoms/FormInput';
import FileUploader from '../../../atoms/FileUploader';
import PrimaryButton from '../../../atoms/PrimaryButton';
import { truncateString } from '../../../utils';
import { generateVideoThumbnail } from '../../../utils/videoThumbnail';

import {
  DEFAULT_CHUNK_SIZE,
  EXT_RENAME_REGEX,
  VIMEO_REGEX,
  YOUTUBE_REGEX,
} from '../../../utils/constants';
import { useLayoutDisabled } from '../hook';
import { useLargeFileUpload, useUploadImage } from '../../../hooks';
import PowerUpUploadSkeleton from 'sub-components/PowerUpUploadSkeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faTrashCan,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { fileSize as getFileSize } from 'utils/fileSize';
import { getUrlFileSize, getVideoUrlFileSize } from 'utils/utils';
import moment from 'moment';
import { useReactiveVar } from '@apollo/client';
import { userObj } from 'sop-commons/src/client';

export type UploadPart = {
  ETag: string;
  PartNumber: number;
};

interface IProps {
  isInvalid?: boolean;
  fileSize?: number;
  videoAddedBy?: string;
  videoDateAdded?: string;
  thumbnail: string;
  errorMessage?: string;
  value?: string;
  isReadOnly?: boolean;
  onChange: (
    videoUrl: string,
    videoMetaData: { fileSize: number; dateAdded: string; addedBy: string }
  ) => void;
  onThumbnailChange?: (value?: string) => void;
}

const Uploader: FC<IProps> = ({
  isInvalid,
  fileSize,
  videoAddedBy,
  videoDateAdded,
  thumbnail,
  value,
  errorMessage,
  isReadOnly,
  onChange,
  onThumbnailChange,
}) => {
  const { t } = useTranslation('card');
  const userData = useReactiveVar(userObj);
  const [hasUploaded, setIsUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const toast = useToast({
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
  const isDisabled = useLayoutDisabled();

  const uploadImage = useUploadImage();
  const largeFileUpload = useLargeFileUpload({ chunkSize: DEFAULT_CHUNK_SIZE });

  const removeVideoHandler = () => {
    setIsUploaded(false);
    onChange?.('', { addedBy: '', dateAdded: '', fileSize: 0 });
  };

  async function getThumbnailFromUrl(videoUrl: string, video?: File | null) {
    try {
      const blobData = await generateVideoThumbnail(
        video || videoUrl,
        0.5,
        true
      );

      const videoName = videoUrl?.split(/\/[0-9]+_/).pop();

      // const imageName = videoName
      //   ?.split('.')
      //   .slice(0, -1)
      //   .join('.')
      //   .concat('.jpeg');

      return await uploadImage(
        blobData,
        videoName?.replace(EXT_RENAME_REGEX, '.jpeg')
      );
    } catch (e) {
      return undefined;
    }
  }

  const testUrl = (url: string) => {
    if (
      YOUTUBE_REGEX.test(url) ||
      VIMEO_REGEX.test(url) ||
      url?.includes('s3.amazonaws')
    ) {
      return true;
    }
    return false;
  };

  async function _onChange(event: ChangeEvent<HTMLInputElement>) {
    if (!testUrl(event.target.value)) return;
    const size = await getVideoUrlFileSize(event.target.value);
    onChange?.(event.target.value, {
      addedBy: userData?.name,
      dateAdded: moment()?.toISOString(),
      fileSize: size,
    });
  }

  async function _onBlur(event: ChangeEvent<HTMLInputElement>) {
    const videoUrl = event.target.value || '';
    if (YOUTUBE_REGEX.test(videoUrl)) {
      const thumbNail = videoUrl
        .split(videoUrl.includes('youtu.be') ? '/' : 'v=')
        .pop()
        ?.split('?')[0];
      onThumbnailChange?.(`https://img.youtube.com/vi/${thumbNail}/0.jpg`);
    } else {
      if (VIMEO_REGEX.test(videoUrl) || videoUrl?.includes('s3.amazonaws')) {
        const thumbnail = await getThumbnailFromUrl(videoUrl);
        onThumbnailChange?.(thumbnail);
      }
    }
  }

  async function _onfileChange(fileLists: File[]) {
    const file = fileLists?.[0];
    if (file?.size > 50 * 1024 * 1024) {
      alert('File size must be less than 50 MB');
      return;
    }
    setProgress(0);
    setIsUploading(true);
    try {
      const videoUrl = await largeFileUpload(file!, (_value) =>
        setProgress(_value - 1)
      );
      if (videoUrl) {
        toast({
          status: 'success',
          title: t('video_success'),
        });
        const thumbnail = await getThumbnailFromUrl(videoUrl, file);
        onThumbnailChange?.(thumbnail);
      }
      setProgress((prevState) => {
        return prevState + 1;
      });
      const size = await getVideoUrlFileSize(videoUrl as string);
      onChange?.(videoUrl as string, {
        addedBy: userData?.name,
        dateAdded: moment()?.toISOString(),
        fileSize: size,
      });
      setIsUploaded(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      // TODO log this error to logger
      toast({
        title: t('video_failed'),
        status: 'error',
      });
    } finally {
      setIsUploading(false);
    }
  }

  const videoName = useMemo(() => {
    try {
      const lastName = value?.split('/').pop();
      if (!lastName?.includes('.')) {
        return null;
      }
      return lastName?.split(/_[0-9]{3}_/).pop();
    } catch (e) {
      return null;
    }
  }, [value]);

  const isUploaded = !!value?.trim() && hasUploaded;

  const removeHandler = () => {
    onChange?.('', { addedBy: '', dateAdded: '', fileSize: 0 });
  };

  return (
    <div className='videoUploaderContainer'>
      <div className='inputVideoCover'>
        {!value && (
          <FormControl
            isInvalid={isInvalid}
            isRequired
            isDisabled={isDisabled || isUploading || isReadOnly}
          >
            <Box
              pointerEvents={
                isDisabled || isUploading || isReadOnly ? 'none' : 'auto'
              }
              opacity={isDisabled || isUploading || isReadOnly ? 0.5 : 1}
            >
              <PowerUpUploadSkeleton
                accept={{
                  'video/*': [],
                }}
                onBlur={_onBlur}
                onChange={_onChange}
                onFileDrop={_onfileChange}
                descType={'video'}
              />
            </Box>
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          </FormControl>
        )}
        {value && (
          <Flex
            justify='space-between'
            align='center'
            w='full'
            border='1px solid #DDDDDD'
            borderRadius='7px'
            p='10px 20px'
          >
            {/* <Image src={milestoneUrl} boxSize='30px' borderRadius='8px' /> */}
            <Flex gap='10px' align='center'>
              {thumbnail ? (
                <Image src={thumbnail} boxSize='50px' borderRadius='8px' />
              ) : (
                <FontAwesomeIcon
                  icon={faVideo as IconProp}
                  color='#6F767E'
                  size='2x'
                />
              )}
              <Flex flexDir='column'>
                <Text fontWeight={600} isTruncated maxW='350px'>
                  {value}
                </Text>
                <Flex gap='2px'>
                  {+getFileSize(fileSize || 0) > 0 && (
                    <Text>{getFileSize(fileSize || 0)},</Text>
                  )}
                  <Text>{t('uploadedBy')}</Text>
                  <Text bg='#CABDFF66'>{videoAddedBy}, </Text>
                  <Text>{moment(videoDateAdded)?.fromNow(false)}</Text>
                </Flex>
              </Flex>
            </Flex>
            {!isReadOnly && (
              <FontAwesomeIcon
                icon={faTrashCan as IconProp}
                color='#FF6A55'
                size='lg'
                cursor='pointer'
                onClick={removeHandler}
              />
            )}
          </Flex>
        )}
        {/* <FormControl
          isInvalid={isInvalid}
          style={{ alignSelf: 'flex-start' }}
          isDisabled={isDisabled}
        >
          <FormInput
            id='videoUrl'
            size='lg'
            placeholder={t('paste_link')}
            isDisabled={isReadOnly}
            value={value}
            onChange={_onChange}
            onBlur={_onBlur}
          />
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl> */}

        {/* <div className='btnCover'>
          <FormControl isInvalid={isInvalid} isRequired width='unset'>
            {!isUploaded && (
              <FileUploader
                accept={'video/mp4,video/x-m4v,video/*'}
                multiple
                onChange={_onfileChange}
              >
                <PrimaryButton
                  disabled={isDisabled || isUploading || isReadOnly}
                  size='lg'
                  title={t(isUploading ? 'uploading' : 'or_upload_video')}
                  style={{
                    maxWidth: '162px',
                    backgroundColor: isUploading ? '#2a85ff' : '#2a85ff',
                  }}
                  variant='solid'
                  colorScheme='blue'
                  className='uploadBtn'
                />
              </FileUploader>
            )}
            {!isUploading && isUploaded && (
              <Box overflow='hidden'>
                <PrimaryButton
                  width='auto'
                  size='lg'
                  title={t('remove_video')}
                  variant='solid'
                  style={{
                    backgroundColor: '#ff6a55',
                    color: 'white',
                  }}
                  onClick={removeVideoHandler}
                />
                {videoName && (
                  <Box
                    paddingTop='2px'
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                    overflow='hidden'
                    data-title={videoName}
                  >
                    {truncateString(videoName, 20)}
                  </Box>
                )}
              </Box>
            )}
          </FormControl>
        </div> */}
      </div>
      {isUploading && (
        <div className='progressBarCover'>
          <Progress
            height={2}
            borderRadius='4px'
            value={progress}
            colorScheme={progress >= 99 ? 'green' : 'blue'}
            isIndeterminate={progress < 1}
            hasStripe={isUploading}
            isAnimated={isUploading}
          />
        </div>
      )}
    </div>
  );
};

export default Uploader;
